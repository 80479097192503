import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Table, Modal, ModalBody, ModalFooter, ModalHeader, Badge } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classnames from 'classnames';
import { toast } from 'react-toastify';
import UserService from "../../../services/UserService";
import { isEmpty,handleErrorMessage } from "../../../services/CommonService";
import SalesQuoteService from "../../../services/sales/SalesQuoteService";
import queryString from "query-string";

let hashMap = new Map();

export default class QuoteConvertToPickingSlipModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedQuotes: this.props.selectedQuotes,
            loading: { convertToPickingSlip: false, activeIndex: -1 },
            selected: { username: "" },
            pending: { refreshQuotes: false }
        };

        this.userService = new UserService();
        this.salesQuoteService = new SalesQuoteService();
        this.setRequiredDate = this.setRequiredDate.bind(this);
        this.convertToPickingSlip = this.convertToPickingSlip.bind(this);
        this.getUsername = this.getUsername.bind(this);
        this.closeModel = this.closeModel.bind(this);

    }

    componentDidMount() {
        this.getUsername();
    }


    getUsername() {
        this.userService.getUser().then(user => {
            let { selected } = this.state;
            selected.username = user.username;
            this.setState({ selected });
        }, error => {
            console.log(error);
        });
    }

    setRequiredDate(date, index) {

        let selectedQuotes = this.state.selectedQuotes;
        selectedQuotes[index].required = date;
        this.setState({
            selectedQuotes: selectedQuotes
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedQuotes: nextProps.selectedQuotes
        })
    }

    convertToPickingSlip(index, ordNum) {
        let { selectedQuotes, selected, loading, pending } = this.state;
        if (!selectedQuotes.length > 0) {
            return;
        }

        let req = this.prepareRequestBody(index, selectedQuotes, selected);

        loading.convertToPickingSlip = true;
        loading.activeIndex = index;
        this.setState({ loading });

        let commonMsg = "Quote-" + selectedQuotes[0].ordNum;

        this.salesQuoteService.convertQuotesToOrder(req).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success(commonMsg + " converted to order", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                hashMap.set(ordNum, response.data);
                loading.convertToPickingSlip = false;
                loading.activeIndex = -1;
                //selectedQuotes.splice(index, 1);
                pending.refreshQuotes = true;

                if (selectedQuotes.length > 0) {
                    this.setState({ loading, selectedQuotes, pending });
                } else {
                    this.setState({ loading, selectedQuotes, pending }, () => {
                        this.closeModel();
                    });
                }
            } else {
                loading.convertToPickingSlip = false;
                this.setState({ loading });
            }
        }).catch(error => {
            console.log(error.data);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            alert(handleErrorMessage(error));

            loading.convertToPickingSlip = false;
            loading.activeIndex = -1;
            this.setState({ loading });
        });
    }


    prepareRequestBody(index, selectedQuotes, selected) {
        let req = [];
        if (index === -1) {
            for (let i in selectedQuotes) {
                req.push({
                    ordNum: selectedQuotes[i].ordNum,
                    required: selectedQuotes[i].required,
                    readyToPick: true,
                    username: selected.username
                });
            }
        } else {
            req.push({
                ordNum: selectedQuotes[index].ordNum,
                required: selectedQuotes[index].required,
                readyToPick: true,
                username: selected.username
            });
        }
        return req;
    }

    renderQuoteRow(index, record, loading) {
        if (record.required) {
            record.required = new Date(record.required);
        }
        let result = "";
        switch (record.statusID) {
            case 0:
                result = <Badge size="sm" color="primary">{record.status}</Badge>;
                break;
            case -1:
                result = <Badge size="sm" color="danger">{record.status}</Badge>;
                break;
            case 5:
                result = <Badge size="sm" color="success">{record.status}</Badge>;
                break;
            default:
                break;
        }


        return<tr key={index} className={classnames({ 'table-primary': record.selected })} >
            <td><Link
                className="btn btn-sm btn-primary" style={{ color: "white" }}
                title={"Click here to view order in detail"}
                to={"/sales/order/view?" + queryString.stringify({ OrdNum: record.ordNum })}>
                {record.ordNum}
            </Link></td>
            <td>{record.description ? record.description : <div className={"text-center"}>
                {result}
            </div>}
            </td>
            <td><Link
                className="btn btn-sm btn-primary"
                style={{ color: "white" }}
                title={"Click here to see account details"}
                to={"/customer/#" + record.accountID}
            >{record.accountID}</Link></td>
            <td>{record.company}</td>
            <td><DatePicker
                className="form-control"
                selected={record.required}
                onChange={date => this.setRequiredDate(date, index)}
                dateFormat="dd/MM/yyyy"
                isClearable
                placeholderText="dd/mm/yyyy"
                withPortal
            /></td>
            <td>
                <div className={"text-center"}>
                {
                    hashMap.has(record.ordNum) ?
                        <Link
                            className="btn btn-sm btn-primary" style={{ color: "white" }}
                            title={"Click here to view order in detail"}
                            to={"/sales/order/view?" + queryString.stringify({ OrdNum: hashMap.get(record.ordNum) })}>
                            {hashMap.get(record.ordNum)}
                        </Link>
                        :
                        (isEmpty(record.custOrdNum, "primitive")) ?
                            <Badge color="danger">Missing OrderNo.</Badge>
                            :
                        <Button size={"sm"} color={"primary"} className={"btn"}
                            onClick={() => this.convertToPickingSlip(index, record.ordNum)}
                            disabled={(loading.convertToPickingSlip && loading.activeIndex === index) || (isEmpty(record.custOrdNum, "primitive"))}>
                            {(loading.convertToPickingSlip && loading.activeIndex === index) ? "Converting" : "Convert"}
                        </Button>
                }
                </div>
            </td>
        </tr>;
    }

    closeModel() {
        let pending = this.state.pending;

        if (pending.refreshQuotes) {
            pending.refreshQuotes = false;
            this.setState({ pending });
            this.props.refresh();
        }

        this.props.toggleModel();
    }

    render() {

        let { isOpen } = this.props;
        let { selectedQuotes, loading, pending } = this.state;

        return (
            <Modal isOpen={isOpen} className="modal-lg" size="lg"
                scrollable={false}
                toggle={this.closeModel}>
                <ModalHeader toggle={this.closeModel}>
                    <h6>Convert to Order</h6>
                </ModalHeader>
                <ModalBody>
                    <Table responsive hover>
                        <thead>

                            <tr>
                                <th>Quote#</th>
                                <th>Status</th>
                                <th>AccountId</th>
                                <th>Customer</th>
                                <th>Required Date</th>
                                <th>Action {/*<Button size={"sm"} color={"primary"} className={"btn"}
                                        onClick={() => this.convertToPickingSlip(-1)}> Convert All</Button>*/}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(selectedQuotes) && selectedQuotes.map((record, index) => {

                                return (
                                    this.renderQuoteRow(index, record, loading)
                                );
                            })}
                        </tbody>
                    </Table>

                </ModalBody>
                {pending.refreshQuotes ? <ModalFooter>
                    <p><i className="fa fa-refresh" aria-hidden="true" /> &nbsp;
                        <small>Quotes will be auto-refreshed after
                        closing modal
                        </small>
                    </p>
                </ModalFooter> : null}
            </Modal>
        );
    }
}