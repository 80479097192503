import React,{Component} from "react";
import {
    Breadcrumb, BreadcrumbItem, Row, Col, FormGroup, Label, Input, Form,
    Button, FormText, Table, Modal, ModalBody, ModalHeader, ModalFooter, Card, CardBody, CardHeader,CardFooter, Spinner
} from 'reactstrap';
import {toast} from "react-toastify";
import FaqService from "../../services/FaqService";
export  default class AddDocumentLink extends Component{
    constructor(props) {
        super(props);
        this.state={
            isOpen: false,
            Id:this.props.FAQId,
            docAttachmentData:{
                faqId: 0,
                linkId: 0,
                title:"",
                description:"",
                docLink:"",
                linkContentType:""
            },
            formErrors: {},
            oldLinks:[],
            showForm:false,
            LinkContentTypes:[]

        }
        this.faqService = new FaqService();
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.showForm = this.showForm.bind(this);
    }
    componentDidMount() {
        this.getLinkContentType();
    }

    getLinkContentType(){
        this.faqService.getLinkContentTypes().then(response => {
           this.setState({LinkContentTypes:response.data})
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    validateForm() {
        let formErrors = {};
        formErrors.title = this.state.docAttachmentData.title.trim().length > 0 ? "" : "Add Title to the content";
        formErrors.description = this.state.docAttachmentData.description.trim().length > 0 ? "" : "Description Required";
        formErrors.docLink  = this.state.docAttachmentData.docLink.trim().length > 0 ? "" : "Add link for the content ";
        formErrors.linkContentType = this.state.docAttachmentData.linkContentType.trim().length > 0 ? "" : "Please select link content type";

        if (formErrors.title || formErrors.description || formErrors.docLink || formErrors.linkContentType) {
            return { valid: false, formErrors: formErrors };
        }
        return { valid: true, formErrors: formErrors };
    }

    onClickHandler() {
        let model = {
            faqId:this.state.Id,
            linkId:this.state.docAttachmentData.linkId,
            title: this.state.docAttachmentData.title,
            description: this.state.docAttachmentData.description,
            docLink: this.state.docAttachmentData.docLink,
            linkContentType: this.state.docAttachmentData.linkContentType
        }
        let result = this.validateForm();
        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            toast.info("See form error for invalid input ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        this.setState({ saving: true });
        this.faqService.AddLinkAttachment(model).then(response => {
            if (response.status === 200 || response.status === '200') {
                let docAttachmentData={
                        faqId:this.state.Id,
                        linkId:0,
                        title:"",
                        description:"",
                        docLink:"",
                        linkContentType:""
                    }
                let formErrors = {};
                this.getPreviousFAQsLinks(this.state.Id);
                if(model.linkId===0)
                {
                toast.success("Link added successfully !!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });}
                else{
                toast.success("Link updated successfully !!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });}
                this.setState({docAttachmentData, formErrors, saving: false }, () => this.showForm(false));
            }
        }).catch(error => {
            this.setState({ saving: false });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ Id: nextProps.FAQId }, () => this.getPreviousFAQsLinks(nextProps.FAQId));
    }

    getPreviousFAQsLinks(FAQId) {
        if (FAQId !== 0) {
            this.faqService.fetchPreviousFAQsLinks(FAQId).then(response => {
                if (response.data) {
                    this.setState({ oldLinks: response.data});
                }
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { docAttachmentData } = this.state;
        docAttachmentData[name] = value;
        this.setState({ docAttachmentData });
    }
    onDelete(item, index) {
        this.setState({ isDeleting: true, deleteIndex: index });
        this.faqService.DeleteFaqLinks(item.id, this.state.Id).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ isDeleting: false });
                toast.success("Link deleted successfully", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.getPreviousFAQsLinks(this.state.Id);
            }
        }).catch(error => {
            console.log(error.data)
            this.setState({ isDeleting: false });
        });
    }

    onEdit(item){
        this.showForm(true);
        let docAttachmentData={
            faqId:this.state.Id,
            linkId:item.id,
            title:item.title,
            description:item.description,
            docLink:item.link,
            linkContentType:item.linkContentType
        }
        this.setState({docAttachmentData});
    }
    handleCancel() {
        this.props.toggleOpen(false);
        this.setState({showForm:false})
    }
    showForm(flag) {
        this.setState({ showForm: flag ,formErrors: {}});
        if(!flag)
        {
            this.setState({ docAttachmentData :{}});
        }
    }
    renderOldLinks(data) {
        let { isDeleting, deleteIndex } = this.state;
        let baseUrl = window.location.origin;
        return (
            <div id="Old_links">
                <div className="btr-15 btlr-15 no-border-bottom bg-white">
                    Existing Links
                </div>
                <Table>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Link</th>
                        <th >Content Type</th>
                        <th style={{ minWidth: 120 }} className="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) =>
                        <tr key={index}>
                            <td>
                                {item.title}
                            </td>
                            <td><a href={item.link}>{item.link}</a></td>
                            <td>
                                {item.linkContentType}
                            </td>
                            <td className={"text-center"}>
                                <Button color={"primary"}  size="sm"  onClick={() => { this.onEdit(item) }}>
                                    <span><i className="fa fa-pencil-square-o" aria-hidden="true" /> Edit</span>

                                </Button>
                                &nbsp;&nbsp;
                                <Button color={"danger"}  size="sm"
                                        onClick={() => { this.onDelete(item, index) }}>
                                    {(isDeleting && index === deleteIndex) ? <span><i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" /> Deleting...  </span> :
                                        <span title="upload"><i className="fa fa-trash-o mr-2" aria-hidden="true" /> Delete</span>
                                    }
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        )
    }
    render() {
        let {isOpen, toggleOpen,FAQId} = this.props;
        let {formErrors,saving ,showForm,oldLinks,docAttachmentData,LinkContentTypes} =this.state;
        let oldlinks = this.renderOldLinks(oldLinks);
        return(
            <div>
                {
                    <Modal isOpen={isOpen} size="xl" scrollable={true}
                           toggle={() => toggleOpen(!isOpen)} >
                        <ModalHeader toggle={() => toggleOpen(!isOpen)}>
                            Media Links</ModalHeader>
                        <ModalBody>
                            {showForm ?
                                <Card>
                                    <CardHeader>{ docAttachmentData.linkId===0 ? <b>New Link</b>: <b>Edit Link</b>}</CardHeader>
                                    <CardBody>
                            <Form>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Title</Label>
                                            <Input
                                                type="text"
                                                name="title"
                                                value={docAttachmentData.title}
                                                onChange={this.handleChange}
                                                placeholder="Write title here..."/>
                                            <FormText
                                                color="danger">{formErrors.title}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                type="text"
                                                name="description"
                                                onChange={this.handleChange}
                                                value={docAttachmentData.description}
                                                placeholder="Write description here..."/>
                                            <FormText
                                                color="danger">{formErrors.description}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <FormGroup>
                                            <Label>Content Link</Label>
                                            <Input
                                                type="text"
                                                name="docLink"
                                                value={docAttachmentData.docLink}
                                                onChange={this.handleChange}
                                                placeholder="Paste Link here..."/>
                                            <FormText
                                                color="danger">{formErrors.docLink}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <a href={this.state.docAttachmentData.docLink} target="_blank"><i
                                            className="fa fa-external-link mt-5" aria-hidden="true"></i></a>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <FormGroup>
                                            <Label>Link Content Type </Label>
                                            <Input className="form-control"
                                                   type="select"
                                                   name="linkContentType"
                                                   value={docAttachmentData.linkContentType}
                                                   onChange={(e) => this.handleChange(e)}
                                                   placeholder="Select link content type...">
                                                <option value={0}>Select Type</option>
                                                {LinkContentTypes.map((type)=>
                                                    <option value={type}>{type}</option>
                                                )}
                                            </Input>
                                            <FormText
                                                color="danger">{formErrors.linkContentType}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                                    </CardBody>
                                    <CardFooter>
                                        <div className={"text-right"}>
                                        <Button color='primary' size="sm" onClick={this.onClickHandler} disabled={saving}>
                                        {saving ? <Spinner size="sm" className="mr-2"
                                                           style={{color: "white"}}/> : <i class="fa fa-floppy-o" aria-hidden="true"/>}&nbsp;
                                        Save</Button>{' '}
                                        <Button color="secondary"  size="sm" onClick={()=>this.showForm(false)}>Cancel</Button>
                                        </div>
                                    </CardFooter>
                            </Card>
                            :
                            <Card className={"hoverableItem"} onClick={() => this.showForm(true)}>
                                <CardBody>
                                    <div className="text-center">
                                        <h3><i className="fa fa-plus-circle mr-2" aria-hidden="true" /> New Link
                                        </h3>
                                    </div>
                                </CardBody>
                            </Card>}
                            <hr/>
                            {oldLinks.length > 0 ? oldlinks : null}
                        </ModalBody>
                        <ModalFooter>
                            <div className={"text-right"}>
                                <Button color="secondary"  size="sm"  onClick={this.handleCancel}>Close</Button>
                            </div>
                        </ModalFooter>
                    </Modal>

                }
            </div>
        )
    }

}