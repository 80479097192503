import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, Row, Col, FormGroup, Label, Input, FormText
} from 'reactstrap';
import classnames from 'classnames';

export default class CreditorDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditorObj: this.props.creditorObj,
            formErrors: this.props.formErrors,

        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.creditorObj != this.state.creditorObj) {
            this.setState({ creditorObj: nextProps.creditorObj });
        }

        if (nextProps.formErrors != this.state.formErrors) {
            this.setState({ formErrors: nextProps.formErrors });
        }
    }

    handleChange(change, key) {
        let { creditorObj } = this.state;
        let { creditorDetail } = creditorObj;
        creditorDetail[key] = change;

        this.setState({ creditorObj });
        this.props.handleCreditorDetailChange(creditorObj);
    }

    render() {
        let { creditorObj, formErrors } = this.state;
        return (
            <div>
                <strong>Supplier Details</strong>
                <br/>
                <Row>
                        <Col lg={2} md={4} sm={12} xs={12}>
                            <a href={"javascript:void(0)"}
                                onClick={() => this.handleChange(!creditorObj.creditorDetail.isLocal, "isLocal")}>
                                <i className={classnames("mt-2", "fa", "fa-lg", {
                                    "fa-check-square-o": creditorObj.creditorDetail.isLocal,
                                    "fa-square-o": !creditorObj.creditorDetail.isLocal,
                                }
                                )} />

                            </a>&nbsp;&nbsp;Is Local
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Currency</Label>
                                <Input
                                    invalid={formErrors.currency}
                                    name="currency"
                                    type="text"
                                    value={creditorObj.creditorDetail.currency || ''}
                                    onChange={(e) => this.handleChange(e.target.value, 'currency')}
                                    placeholder="write currency here..." />
                                <FormText color="danger">{formErrors.currency}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
            </div>
        )
    }




}