import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody, Badge, CardFooter, Input, InputGroup, InputGroupAddon, Button, InputGroupText, Label, ButtonGroup, Spinner, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import KeywayNonStockService from '../../services/KeywayNonStockService';
import * as FileSaver from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
import { handleErrorMessage } from '../../services/CommonService';
import ManageNonStockModal from '../../components/modal/ManageNonStockModal';
import queryString from 'query-string';

export default class NonStocksPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nonStockData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: true
                    },
                    filterRequest: {
                        prodCode: "",
                        description: "",
                        excludeService: null,
                        excludeInactive: null
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            downloading: false,
            searchText: "",
            isOpenModal: false,
            selectedProdCode: "",
            excludeService: -1,
            excludeInactive: -1
        };
        this.keywayNonStockService = new KeywayNonStockService();
        this.getNonStocks = this.getNonStocks.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.exportNonStocks = this.exportNonStocks.bind(this);
    }

    componentDidMount() {
        this.getNonStocks(this.state.nonStockData);
    }

    getNonStocks(nonStockData) {
        this.setState({ loading: true });
        this.keywayNonStockService.listAllNonStocks(nonStockData.request).then(response => {
            nonStockData.response = response.data;
            this.setState({ nonStockData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();
            flag = item.description ? item.description.toLowerCase().includes(searchText) : false;
            if (!flag && item.prodCode) {
                flag = item.prodCode.toLowerCase().includes(searchText);
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let { nonStockData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change, nonStockData });
                break;
            case "sortKey":
                if (nonStockData.request.sortRequest.key === change) {
                    nonStockData.request.sortRequest.direction = !nonStockData.request.sortRequest.direction;
                } else {
                    nonStockData.request.sortRequest.key = change;
                    nonStockData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "sNo")) {
                    this.getNonStocks(nonStockData);
                }
                break;
            case "pageSize":
                nonStockData.request.pageRequest[key] = change;
                nonStockData.request.pageRequest.currentPage = 1;
                this.getNonStocks(nonStockData);
                break;
            case "currentPage":
                nonStockData.request.pageRequest[key] = change;
                this.getNonStocks(nonStockData);
                break;
            default:
                nonStockData.request.filterRequest[key] = change;
                nonStockData.request.pageRequest.currentPage = 1;
                this.getNonStocks(nonStockData);
                this.setState({ nonStockData });
                break;
        }

    }

    handleFilterChange(change, key) {
        let { nonStockData } = this.state;
        switch (key) {
            case "excludeService":
                nonStockData.request.filterRequest[key] = parseInt(change);
                nonStockData.request.pageRequest.currentPage = 1;
                this.setState({ nonStockData, excludeService: change }, () => {
                    this.getNonStocks(nonStockData);
                });
                break;
            case "excludeInactive":
                nonStockData.request.filterRequest[key] = parseInt(change);
                nonStockData.request.pageRequest.currentPage = 1;
                this.setState({ nonStockData, excludeInactive: change }, () => {
                    this.getNonStocks(nonStockData);
                });
                break;
            default:
                nonStockData.request.pageRequest.currentPage = 1;
                this.setState({ nonStockData }, () => {
                    this.getNonStocks(nonStockData);
                });
                break;
        }
    }

    getStockHeading({ filterRequest }) {
        let stockHeading = [
            {
                key: "sNo",
                label: "S.No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "prodCode",
                label: "Prodcode",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <Input type="search" id="prodCode" name="prodCode" value={filterRequest.prodCode}
                    onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                    placeholder="Search... " />
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <Input type="search" id="description" name="description"
                    value={filterRequest.description}
                    onChange={(e) => this.handleChange(e.target.value, "description")}
                    placeholder="Search..." />
            },
            {
                key: "available",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "eachUnitPrice",
                label: "Price",
                type: "number",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "nonStockType",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null
            },
            {
                key: "stockGroup",
                label: "Stock Group",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null
            },
            {
                key: "notes",
                label: "Notes",
                type: "text",
                colSpan: 1,
                minWidth: 300,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                searchNode: <div>
                    <Button color='primary'
                        size='sm'
                        onClick={() => this.openModal("")}>
                        <i className="fa fa-plus mr-2"
                            aria-hidden="true" />
                        Add
                    </Button>
                </div>
            }
        ];
        return stockHeading;
    }

    openModal(prodCode) {
        this.setState({ isOpenModal: true, selectedProdCode: prodCode });
    }

    toggleModal(data) {
        this.setState({ isOpenModal: data });
    }

    exportNonStocks(key) {
        let { nonStockData } = this.state;
        let request = cloneDeep(nonStockData.request);
        request.filterRequest = {
            prodCode: "",
            description: "",
            excludeService: null,
            excludeInactive: null
        };
        this.setState({ downloading: true });
        if (key === "All") {
            this.keywayNonStockService.generateExcelReport(request).then(response => {
                this.setState({ downloading: false });
                FileSaver.saveAs(response.data, "Non-Stock-Report" + ".xlsx");
            }).catch(error => {
                this.setState({ downloading: false });
                toast.error(handleErrorMessage(error));
            });
        }
        else if (key === "Filter") {
            this.keywayNonStockService.generateExcelReport(nonStockData.request).then(response => {
                this.setState({ downloading: false });
                FileSaver.saveAs(response.data, "Non-Stocks" + ".xlsx");
            }).catch(error => {
                this.setState({ downloading: false });
                toast.error(handleErrorMessage(error));
            });
        }
    }

    render() {
        let { nonStockData, loading, searchText, downloading, isOpenModal, selectedProdCode, excludeService, excludeInactive } = this.state;
        let { pageRequest, sortRequest } = nonStockData.request;
        let stockHeading = this.getStockHeading(nonStockData.request);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Non-Stocks</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Non-Stocks</h5>
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color={"success"} size='sm' disabled={downloading} outline={true}>
                                            {downloading
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    style={{ color: "green" }} />
                                                : <i className="fa fa-download mr-2" aria-hidden="true" />}Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem title={'Click here to export all data'}
                                                onClick={() => this.exportNonStocks("All")}>
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />All Non-Stocks
                                            </DropdownItem>
                                            <DropdownItem title={'Click here to export filtered data'}
                                                onClick={() => this.exportNonStocks("Filter")}>
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />Filtered Non-Stocks
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown> &nbsp;
                                    <Link color='primary' style={{ textDecoration: "none" }}
                                        className="btn btn-sm btn btn-primary"
                                        to={'/inventory/keyway/nonStock/import'}>
                                        <i className="fa fa-upload mr-2"
                                            aria-hidden="true" />
                                        Import
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <Row>
                                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                                    <div>
                                        <Label>Non-Stock Type</Label>
                                        <div>
                                            <ButtonGroup>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleFilterChange(0, "excludeService")}
                                                    color={"primary"}

                                                    outline={excludeService !== 0}>
                                                    Service Code
                                                    {
                                                        excludeService === 0
                                                            ? <i className="fa fa-check ml-1"
                                                                aria-hidden="true" />
                                                            : null
                                                    }
                                                </Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(1, "excludeService")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeService !== 1}>
                                                    Option Code
                                                    {
                                                        excludeService === 1
                                                            ? <i className="fa fa-check ml-1" aria-hidden="true" />
                                                            : null
                                                    }
                                                </Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(-1, "excludeService")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeService !== -1}>
                                                    All
                                                    {
                                                        excludeService === -1
                                                            ? <i className="fa fa-check ml-1" aria-hidden="true" />
                                                            : null
                                                    }
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6} md={6} sm={12} xs={12} className={"mb-2"}>
                                    <div>
                                        <Label>Status</Label>
                                        <div>
                                            <ButtonGroup>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleFilterChange(1, "excludeInactive")}
                                                    color={"primary"}

                                                    outline={excludeInactive !== 1}>
                                                    Active
                                                    {
                                                        excludeInactive === 1
                                                            ? <i className="fa fa-check ml-1"
                                                                aria-hidden="true" />
                                                            : null
                                                    }
                                                </Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(0, "excludeInactive")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeInactive !== 0}>
                                                    Inactive
                                                    {
                                                        excludeInactive === 0
                                                            ? <i className="fa fa-check ml-1" aria-hidden="true" />
                                                            : null
                                                    }
                                                </Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(-1, "excludeInactive")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeInactive !== -1}>
                                                    All
                                                    {
                                                        excludeInactive === -1
                                                            ? <i className="fa fa-check ml-1" aria-hidden="true" />
                                                            : null
                                                    }
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {
                                        loading
                                            ? <p>Loading...</p>
                                            : <p>Showing
                                                {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {nonStockData.response.totalRecords}
                                                {' '}entries
                                            </p>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(stockHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : null}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable
                                                            ? <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            })} aria-hidden="true" />
                                                            : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(stockHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={item.valueClassName}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(nonStockData.response.records || []).filter((stock) => this.searchFunction(stock, searchText)).map((stock, index) => {
                                        return <tr key={index} className="align-middle text-center">
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td className="align-middle text-center">
                                                <h5>
                                                    <Link
                                                        className="btn btn-primary btn-sm"
                                                        style={{ color: "white" }}
                                                        title={"Click here to see stock enquiry"}
                                                        to={"/inventory/stock/enquiry?" + queryString.stringify({ prodCode: stock.prodCode })}>
                                                        {stock.prodCode}
                                                    </Link>
                                                </h5>
                                            </td>
                                            <td className="align-middle text-left">{stock.description}</td>
                                            <td className={"align-middle text-center"}>
                                                {
                                                    stock.available
                                                        ? <Badge color="success">Active</Badge>
                                                        : <Badge color="danger">Inactive</Badge>
                                                }
                                            </td>
                                            <td className="align-middle text-center">{"$" + stock.eachUnitPrice}</td>
                                            <td className="align-middle text-center">{stock.nonStockType}</td>
                                            <td className="align-middle text-center">{stock.stockGroup}</td>
                                            <td className="align-middle text-center">{stock.notes}</td>
                                            <td className="align-middle text-center">
                                                <Button color='primary'
                                                    size='sm'
                                                    onClick={() => this.openModal(stock.prodCode)}>
                                                    <i className="fa fa-pencil mr-2"
                                                        aria-hidden="true" />
                                                    Edit</Button>
                                            </td>
                                        </tr>
                                    }
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={nonStockData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                {
                    isOpenModal
                        ? <ManageNonStockModal
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            prodCode={selectedProdCode}
                            refreshAgain={() => this.getNonStocks(nonStockData)} />
                        : null
                }
                <ToastContainer />
            </div>
        );
    }
}