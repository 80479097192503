import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Label, Row, Col,
    Input, FormText,
    Card, CardBody, CardHeader,
    Spinner,
    Table
} from 'reactstrap';
import {toast} from 'react-toastify';
import classnames from 'classnames';
import freightService from '../../services/FreightService';
import {handleErrorMessage} from "../../services/CommonService";

export default class CopyFreightRuleModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: "default",
            rules: [],
            loading: {
                rulesList: false
            }
        }
    }

    componentDidMount() {
        this.getFreightRules(this.state.category);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.category !== nextProps.category) {
            this.getFreightRules(nextProps.category);
        }
    }

    getFreightRules(category) {
        let {rules, accountID, loading} = this.state;
        loading.rulesList = true;
        this.setState({loading, category});
        freightService.getFreightRules(category, accountID).then(response => {
            rules = response.data;
            loading.rulesList = false;
            this.setState({rules, loading, category});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            loading.rulesList = false;
            this.setState({loading, category});
        });
    }

    render() {
        let {isOpen, toggle, handleChange} = this.props;
        let {loading, rules} = this.state;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    To create new rule, select a existing rule as a template
                </ModalHeader>
                <ModalBody>
                    {loading.rulesList
                        ? <Spinner color={"primary"}/>
                        : <div>
                            <Table responsive={true} hover={true} striped={true}>
                                <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Method</th>
                                    <th>Rule Condition</th>
                                    <th>Attribute</th>
                                    <th className={"text-right"}>Min-Max</th>
                                    <th className={"text-right"}>Metro Charges</th>
                                    <th className={"text-right"}>Non-Metro Charges</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(rules || []).map((item, index) => {
                                    return <tr key={index}>
                                        <td><Button color={"primary"} size={"sm"}
                                                    onClick={() => handleChange("copy", item)}>Select</Button></td>
                                        <td>{item.methodLabel}</td>
                                        <td>{item.description}</td>
                                        <td>{item.attributeLabel}</td>
                                        <td className={"text-right"}>{item.attributeValueMin}-{item.attributeValueMax}</td>
                                        <td className={"text-right"}>${item.metroCharge}</td>
                                        <td className={"text-right"}>${item.nonMetroCharge}</td>
                                    </tr>
                                })}
                                </tbody>
                            </Table>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size={"sm"} onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2"/>Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}