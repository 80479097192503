import React, {Component} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';

import {cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import customUtil from './CustomUtil';
import {PRODUCT_BUILDER_CUSTOM_BUILD, PRODUCT_BUILDER_CUSTOM_PART} from "../../../../../../store/AppConstants";
import CustomPart from "../custom/CustomPart";
import CustomBuild from "../custom/CustomBuild";
import CustomBuildTemplate from "../custom/CustomBuildTemplate";
import UserService from '../../../../../../services/UserService';

export default class Custom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
            hasProductBuilderTemplateReadPrivilege: false,
            hasProductBuilderTemplateWritePrivilege: false
        };
        this.userService = new UserService();
        this.checkPrivilege = this.checkPrivilege.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.checkPrivilege();
    }

    checkPrivilege() {
        let currentUser = this.userService.getLoggedInUser();
        let hasProductBuilderTemplateWritePrivilege = this.userService.hasPrivilege(currentUser, "product_builder_template-write");
        let hasProductBuilderTemplateReadPrivilege = this.userService.hasPrivilege(currentUser, "product_builder_template-read");
        this.setState({ hasProductBuilderTemplateWritePrivilege, hasProductBuilderTemplateReadPrivilege });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    handleChange(key, change) {
        let {product} = this.props;
        switch (key) {
            case "product":
                product = change;
                break;
            case "product_init":
                if (change.init_part && change.init_build) {
                    change.init = true;
                    product.savedItems = [];
                }
                product = change;
                break;

        }
        this.props.handleChange("product", product);
    }

    render() {
        let { activeTab, isCustomPBVisible, hasProductBuilderTemplateReadPrivilege, hasProductBuilderTemplateWritePrivilege } = this.state;
        let { order, product, discountByProductCode, packagingAndHandling, currentUser, products } = this.props;

        let itemSummary = customUtil.getItemTypeSummary(product);
        return (
            <div>
                <Nav tabs>
                    <NavItem className={classnames({hoverableItem: activeTab !== '1'})}>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => this.toggle('1')}>
                            Parts {itemSummary[PRODUCT_BUILDER_CUSTOM_PART].count ?
                            <span>({itemSummary[PRODUCT_BUILDER_CUSTOM_PART].count})</span> : null}
                        </NavLink>
                    </NavItem>
                    <NavItem className={classnames({hoverableItem: activeTab !== '2'})}>
                        <NavLink
                            className={classnames({active: activeTab === '2'})}
                            onClick={() => this.toggle('2')}>
                            Build {itemSummary[PRODUCT_BUILDER_CUSTOM_BUILD].count ?
                            <span>({itemSummary[PRODUCT_BUILDER_CUSTOM_BUILD].count})</span> : null}
                        </NavLink>
                    </NavItem>
                    <NavItem className={classnames({ hoverableItem: activeTab !== '3' })}>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            disabled={!hasProductBuilderTemplateReadPrivilege}
                            onClick={() => this.toggle('3')}>
                            Templates
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="mt-2">
                            <CustomPart
                                order={order}
                                product={product}
                                currentUser={currentUser}
                                itemSummary={itemSummary[PRODUCT_BUILDER_CUSTOM_PART]}
                                packagingAndHandling={packagingAndHandling}
                                discountByProductCode={discountByProductCode}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="mt-2">
                            <CustomBuild
                                hasProductBuilderTemplateWritePrivilege={hasProductBuilderTemplateWritePrivilege}
                                hasProductBuilderTemplateReadPrivilege={hasProductBuilderTemplateReadPrivilege}
                                order={order}
                                product={product}
                                currentUser={currentUser}
                                itemSummary={itemSummary[PRODUCT_BUILDER_CUSTOM_BUILD]}
                                packagingAndHandling={packagingAndHandling}
                                discountByProductCode={discountByProductCode}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="3" >
                        <div className="mt-2">
                            {activeTab === "3" && <CustomBuildTemplate
                                hasProductBuilderTemplateWritePrivilege={hasProductBuilderTemplateWritePrivilege}
                                hasProductBuilderTemplateReadPrivilege={hasProductBuilderTemplateReadPrivilege}
                                order={order}
                                products={products}
                                product={product}
                                currentUser={currentUser}
                                itemSummary={itemSummary[PRODUCT_BUILDER_CUSTOM_BUILD]}
                                packagingAndHandling={packagingAndHandling}
                                discountByProductCode={discountByProductCode}
                                handleChange={this.handleChange}
                            />}
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        );
    }
};
