import React, {Component} from 'react';
import {Col, Row, Label} from 'reactstrap';

import SearchDebtorAcccount from '../search/SearchDebtorAcccount';
import CustomerService from "../../services/CustomerService";

export default class CustomerFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filter: this.props.filter};
        this.customerService = new CustomerService();
        this.updateFilter = this.updateFilter.bind(this);
    }


    updateFilter(accountId) {

        let {filter} = this.state;

        if (accountId) {
            this.customerService.searchCustomer(accountId).then(response => {
                filter.value = response.data.accountID;
                filter.displayValue = filter.value + " | " + response.data.company;
                this.setState({filter});
                this.props.onChange(filter, false);
            });
        } else {
            filter.value = "";
            filter.displayValue = filter.value;
            this.setState({filter});this.props.onChange(filter, false);

        }
    }


    render() {
        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Label>Search by Customer</Label>
                        <div>
                            <SearchDebtorAcccount
                                handleAccountChange={this.updateFilter}
                                defaultAccountID={this.state.filter.defaultValue}
                                includeChildren={true}
                                excludeClosedandInactive={false} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
