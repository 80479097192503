import axios from './axios';
import { authHeader } from '../helpers/auth-header';
import { config } from '../helpers/config';

class StockSupplierService {

    static Instance() {
        return new StockSupplierService();
    }
    getStockSuppliers(req) {
        return axios.post('api/stock-supplier/list/all',req)
    }
    addStockSupplier(req) {
        return axios.post('api/stock-supplier/save', req);
    }
    getOne(id) {
        return axios.post('api/stock-supplier/one?id=' + id);
    }

    deleteStockSupplier(id) {
        return axios.delete('api/stock-supplier/delete?id='+id);
    }

    updateStockSupplier(req) {
        return axios.post('api/stock-supplier/update', req);
    }

    getAllSuppliers() {
        return axios.post('api/stock-supplier/list/suppliers');
    }

    getAllSuppliersByStock(prodCode, req) {
        return axios.post('api/stock-supplier/list/stock?prodCode=' + prodCode, req);
    }

    getAllBySupplierID(supplierID, req) {
        return axios.post('api/stock-supplier/getBySupplierID?supplierID=' + supplierID, req);
    }


}
export default StockSupplierService.Instance();