import React, {Component} from 'react';
import NumberFormat from "react-number-format";
import {Link} from "react-router-dom";
import {Card, CardBody, CardHeader, Col, Spinner, Table} from "reactstrap";
import CustomerService from '../../../services/CustomerService';
import {toast, ToastContainer} from 'react-toastify';
import {getFinancialYear, handleErrorMessage} from '../../../services/CommonService';
import FinancialYearDropDown from '../../FinancialYearDropDown';
import moment from 'moment';

export default class CustomerLeaderBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerData: [],
            isLoading: false,
            selectedYear: getFinancialYear(moment(new Date())),
        };
        this.customerService = new CustomerService();
        this.getCustomerData = this.getCustomerData.bind(this);
        this.yearChange = this.yearChange.bind(this);
    }

    componentDidMount() {
        this.getCustomerData(this.state.selectedYear);
    }

    getCustomerData(year) {
        this.setState({isLoading: true});
        this.customerService.getCustomerLeaderBoardList(year).then(response => {
            let data = response.data;
            this.setState({customerData: data, isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    yearChange(selectedYear) {
        if (selectedYear && selectedYear !== this.state.selectedYear) {
            this.setState({selectedYear: selectedYear}, () => {
                this.getCustomerData(selectedYear);
            });
        }
    }

    render() {
        let {customerData, selectedYear, isLoading} = this.state;
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Customer Leaderboard</h6>
                    </CardHeader>
                    <div>
                        {isLoading ? <CardBody><Spinner className="primary"/></CardBody>
                            : <Table striped responsive size={"sm"}>
                                <thead>
                                <tr>
                                    <th className="text-center">S.No.</th>
                                    <td colSpan={2}>
                                        <FinancialYearDropDown handleChange={this.yearChange}
                                                               defaultSelectedYear={selectedYear}
                                                               selectedYear={selectedYear}
                                        />
                                    </td>
                                    <th className="text-right">Spend</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(customerData || []).map((data, index) =>
                                    <tr key={index}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>
                                            <Link className="btn btn-primary btn-sm"
                                                  style={{color: "white"}}
                                                  title={"Click here to see Details"}
                                                  to={"/customer/#" + data.accountID}>
                                                {data.accountID}</Link>
                                        </td>
                                        <td>{data.company}</td>
                                        <td className="text-right"><NumberFormat value={data.sumTotal}
                                                                                 decimalScale={0}
                                                                                 displayType={'text'}
                                                                                 thousandSeparator={true}
                                                                                 prefix={'$'}/></td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Card>
                <ToastContainer/>
            </Col>
        );
    }
} 

