import React, {Component} from 'react';
import {Badge, Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner, Table} from 'reactstrap';
import {Link as Link} from "react-router-dom";
import queryString from 'query-string';
import classnames from "classnames";
import Pagination from "react-js-pagination";
import * as FileSaver from 'file-saver';
import {toast, ToastContainer} from "react-toastify";
import warehouseService from '../../../services/WINOInventory/WarehouseService';
import PurchaseOrderService from '../../../services/purchase/PurchaseOrderService';
import {addDate, changeFormatOfDateString, getDateString, handleErrorMessage} from '../../../services/CommonService';
import NumberFormat from "react-number-format";
import cloneDeep from 'lodash/cloneDeep';
const now = new Date();
export default class StockReceivePurchaseOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseOrderData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "etaDate",
                        direction: true
                    },
                    filterRequest: {
                        ordNum: null,
                        supplierCode: '',
                        ref: '',
                        printedBy: '',
                        supplierName: '',
                        poFromDate: addDate(now, -1, "years", "dateObj"),
                        poEndDate: now,
                        toBeReceivedFromDate: props.filter.toBeReceivedFromDate,
                        toBeReceivedEndDate: props.filter.toBeReceivedEndDate,
                        receivedFromDate: props.filter.receivedFromDate,
                        receivedEndDate: props.filter.receivedEndDate,
                        statusIDs: props.filter.statusIDs,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                }
            },
            loading: false,
            downloading: false,
            purchaseOrderStatuses: [],
        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.getPurchaseOrders = this.getPurchaseOrders.bind(this);
        this.getPurchaseOrderStatuses = this.getPurchaseOrderStatuses.bind(this);
        this.allPurchaseOrderHeading = this.allPurchaseOrderHeading.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.selectOrdNum = this.selectOrdNum.bind(this);
        this.exportPurchaseOrders = this.exportPurchaseOrders.bind(this);
    }

    componentDidMount() {
        let { purchaseOrderData } = this.state;
        let { filterRequest, pageRequest, sortRequest } = purchaseOrderData.request;
        let searchParams = queryString.parse(window.location.search);
        if (searchParams) {
            filterRequest.supplierCode = searchParams.supplierCode ? searchParams.supplierCode : filterRequest.supplierCode;
            filterRequest.ordNum = searchParams.ordNum ? searchParams.ordNum : filterRequest.ordNum;
            filterRequest.supplierName = searchParams.supplierName ? searchParams.supplierName : filterRequest.supplierName;

            pageRequest.currentPage = searchParams.currentPage ? parseInt(searchParams.currentPage) : pageRequest.currentPage;
            pageRequest.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : pageRequest.pageSize;

            sortRequest.key = searchParams.sortRequestKey ? searchParams.sortRequestKey : sortRequest.key;
            sortRequest.direction = searchParams.sortRequestDirection ? searchParams.sortRequestDirection : sortRequest.direction;
        }
        this.setState({ purchaseOrderData }, () => {
            this.refresh();
            this.getPurchaseOrderStatuses();
        });
    }

    componentWillReceiveProps(nextProps) {
        let {purchaseOrderData} = this.state;

        let {
            toBeReceivedFromDate,
            toBeReceivedEndDate,
            receivedFromDate,
            receivedEndDate,
            statusIDs,
        } = purchaseOrderData.request.filterRequest;

        if (
            (
                toBeReceivedFromDate !== nextProps.filter.toBeReceivedFromDate
                || toBeReceivedEndDate !== nextProps.filter.toBeReceivedEndDate
                || receivedFromDate !== nextProps.filter.receivedFromDate
                || receivedEndDate !== nextProps.filter.receivedEndDate
                || statusIDs !== nextProps.filter.statusIDs
            )
        ) {

            purchaseOrderData.request.filterRequest.toBeReceivedFromDate = nextProps.filter.toBeReceivedFromDate;
            purchaseOrderData.request.filterRequest.toBeReceivedEndDate = nextProps.filter.toBeReceivedEndDate;
            purchaseOrderData.request.filterRequest.receivedFromDate = nextProps.filter.receivedFromDate;
            purchaseOrderData.request.filterRequest.receivedEndDate = nextProps.filter.receivedEndDate;
            purchaseOrderData.request.filterRequest.statusIDs = nextProps.filter.statusIDs;
            this.setState({purchaseOrderData}, () => {
                this.refresh();
            })
        }
    }

    getPurchaseOrderStatuses() {
        this.purchaseOrderService.getPurchaseOrderStatuses().then(res => {
            this.setState({purchaseOrderStatuses: res.data})
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getPurchaseOrders(purchaseOrderData) {
        this.setState({purchaseOrderData, loading: true});
        let request = cloneDeep(purchaseOrderData.request);

        request.filterRequest.toBeReceivedFromDate = request.filterRequest.toBeReceivedFromDate
            ? getDateString(request.filterRequest.toBeReceivedFromDate, "DD/MM/YYYY")
            : "";
        request.filterRequest.toBeReceivedEndDate = request.filterRequest.toBeReceivedEndDate
            ? getDateString(request.filterRequest.toBeReceivedEndDate, "DD/MM/YYYY")
            : "";
        request.filterRequest.receivedFromDate = request.filterRequest.receivedFromDate
            ? getDateString(request.filterRequest.receivedFromDate, "DD/MM/YYYY")
            : "";
        request.filterRequest.receivedEndDate = request.filterRequest.receivedEndDate
            ? getDateString(request.filterRequest.receivedEndDate, "DD/MM/YYYY")
            : "";

        request.filterRequest.poFromDate = getDateString(request.filterRequest.poFromDate, "DD/MM/YYYY");
        request.filterRequest.poEndDate = getDateString(request.filterRequest.poEndDate, "DD/MM/YYYY");

        request.filterRequest.statusIDs = (request.filterRequest.statusIDs || []).filter(s => s !== "").map(s => parseInt(s));
        this.purchaseOrderService.getPurchaseOrders(request).then(response => {
            purchaseOrderData.response = response.data;
            this.setState({purchaseOrderData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error));
        });
    }

    allPurchaseOrderHeading({filterRequest}) {
        let purchaseOrderHeading = [
            {
                key: "etaDate",
                label: "ETA Date",
                type: "text",
                colSpan: 1,
                minWidth: 102,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "date",
                label: "PO Date",
                type: "text",
                colSpan: 1,
                minWidth: 102,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "ordNum",
                label: "PO No.",
                type: "text",
                colSpan: 1,
                minWidth: 45,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="search" id="ordNum" name="ordNum"
                                        value={filterRequest.ordNum || ''}
                                        onChange={(e) => this.handleChange(e.target.value, "ordNum")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "supplierCode",
                label: "Supplier",
                type: "text",
                colSpan: 1,
                minWidth: 45,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="search" id="supplierCode" name="supplierCode"
                                        value={filterRequest.supplierCode}
                                        onChange={(e) => this.handleChange(e.target.value, "supplierCode")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "supplierName",
                label: "Company",
                type: "text",
                colSpan: 1,
                minWidth: 320,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="search" id="supplierName" name="supplierName"
                                        value={filterRequest.supplierName}
                                        onChange={(e) => this.handleChange(e.target.value, "supplierName")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "statusDescription",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-center",
                searchNode: <div>
                    <Input type={"select"}
                           style={{maxHeight: 55}}
                           name={"purchaseOrderStatusDescription"}
                           value={filterRequest.statusIDs}
                           onChange={(e) => this.handleChange(e.target, "statusIDs")}>
                        <option value="">All</option>
                        {this.state.purchaseOrderStatuses.map((purchaseOrderStatus, index) =>
                            <option key={index}
                                    value={purchaseOrderStatus.statusID}>{purchaseOrderStatus.description}</option>
                        )}
                    </Input>
                </div>
            },
            {
                key: "totalItems",
                label: "Total Lines",
                type: "text",
                colSpan: 1,
                minWidth: 92,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center"
            },
            {
                key: "totalCost",
                label: "Total Cost",
                type: "text",
                colSpan: 1,
                minWidth: 95,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-right"
            }
        ];
        return purchaseOrderHeading;
    }

    handleChange(change, key) {
        let {purchaseOrderData, showTable} = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        switch (key) {
            case "sortKey":
                if (purchaseOrderData.request.sortRequest.key === change) {
                    purchaseOrderData.request.sortRequest.direction = !purchaseOrderData.request.sortRequest.direction;
                } else {
                    purchaseOrderData.request.sortRequest.key = change;
                    purchaseOrderData.request.sortRequest.direction = false;
                }
                searchParams.set("sortRequestKey", change);
                searchParams.set("sortRequestDirection", purchaseOrderData.request.sortRequest.direction);
                if (!(change === "action" || change === "srNo")) {
                    this.getPurchaseOrders(purchaseOrderData);
                }
                break;
            case "pageSize":
                purchaseOrderData.request.pageRequest[key] = change;
                purchaseOrderData.request.pageRequest.currentPage = 1;
                searchParams.set("pageSize", change);
                this.getPurchaseOrders(purchaseOrderData);
                break;
            case "currentPage":
                purchaseOrderData.request.pageRequest[key] = change;
                searchParams.set("currentPage", change);
                this.getPurchaseOrders(purchaseOrderData);
                break;
            case "showOrderItem":
                showTable = change;
                this.setState({purchaseOrderData, showTable});
                break;
            default:
                switch (key) {
                    case "ordNum":
                        purchaseOrderData.request.filterRequest[key] = change ? change : null;
                        purchaseOrderData.request.pageRequest.currentPage = 1;
                        searchParams.set(key, change);
                        searchParams.set("currentPage", purchaseOrderData.request.pageRequest.currentPage);
                        this.getPurchaseOrders(purchaseOrderData);
                        break;
                    case "statusIDs":
                        let statusIDs = [];
                        for (let i = 0; i < change.selectedOptions.length; i++) {
                            statusIDs.push(change.selectedOptions[i].value);
                        }
                        purchaseOrderData.request.filterRequest[key] = statusIDs;
                        purchaseOrderData.request.pageRequest.currentPage = 1;
                        this.getPurchaseOrders(purchaseOrderData);
                        break;
                    default:
                        purchaseOrderData.request.filterRequest[key] = change;
                        purchaseOrderData.request.pageRequest.currentPage = 1;
                        searchParams.set(key, change);
                        searchParams.set("currentPage", purchaseOrderData.request.pageRequest.currentPage);
                        this.getPurchaseOrders(purchaseOrderData);
                }
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                this.setState({purchaseOrderData});
        }
    }

    refresh() {
        let {purchaseOrderData} = this.state;
        this.getPurchaseOrders(purchaseOrderData);
    }

    selectOrdNum(ordNum) {
        this.props.handleChange(ordNum, "ordNum");
    }

    exportPurchaseOrders(){
        let request = this.state.purchaseOrderData.request;
        this.setState({downloading: true});
        this.purchaseOrderService.generatePurchaseOrderInExcel(request).then(response => {
            this.setState({downloading: false});
            FileSaver.saveAs(response.data, "Purchase Order Report" + ".xlsx");
        }).catch(error => {
            this.setState({downloading: false});
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let {purchaseOrderData, loading, downloading} = this.state;
        let {filterRequest, sortRequest, pageRequest} = purchaseOrderData.request;
        let purchaseOrderHeading = this.allPurchaseOrderHeading(purchaseOrderData.request);
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p className={"mb-0"}>Loading...</p> :
                                        <p className={"mb-0"}>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {purchaseOrderData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <Button size={"sm"} color={"success"} outline={true}
                                            title={'Click here to export Purchase Orders'}
                                            onClick={this.exportPurchaseOrders} disabled={downloading}>
                                        {downloading
                                            ? <Spinner size="sm"
                                                       className={"mr-2"}
                                                       color={"success"}/>
                                            : <i className="fa fa-file-excel-o mr-2" aria-hidden="true"/>}
                                        Export</Button>
                                </div>
                            </Col>
                        </Row>
                        <Table responsive={true} striped bordered size={"sm"} className={"mb-1"}>
                            <thead>
                            <tr>
                                {(purchaseOrderHeading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => item.sorterApplicable ? this.handleChange(item.key, "sortKey") : null}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{minWidth: item.minWidth}}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true"/> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(purchaseOrderHeading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {(purchaseOrderData.response.records || []).map((item, index) =>
                                <tr key={index}>
                                    <td className={"text-left"}>{changeFormatOfDateString(item.etaDate, 'DD/MM/YYYY HH:mm:ss', 'DD MMM YYYY')}</td>
                                    <td className={"text-left"}>{changeFormatOfDateString(item.date, 'DD/MM/YYYY HH:mm:ss', 'DD MMM YYYY')}</td>
                                    <td className={"text-center"}>
                                        <Button
                                            size={"sm"}
                                            color={"primary"}
                                            onClick={() => this.selectOrdNum(item.ordNum)}>{item.ordNum}</Button>
                                    </td>
                                    <td className={"text-center"}>
                                        <Link className="btn btn-primary btn-sm"
                                              to={"/creditors-enquiry?accountID" + queryString.stringify({accountID: item.supplierCode})}>
                                            {item.supplierCode}
                                        </Link>
                                    </td>
                                    <td className={"text-left"}>
                                        <small>{item.supplierName}</small>
                                    </td>
                                    <td className={"text-center"}><Badge
                                        color={this.purchaseOrderService.getPurchaseOrderStatusColor(item.statusDescription)}>{item.statusDescription}</Badge>
                                    </td>
                                    <td className={"text-right"}>{item.itemCount}</td>
                                    <td className={"text-right"}>
                                        <NumberFormat value={item.cost}
                                                      displayType={'text'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={purchaseOrderData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination mb-0'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}