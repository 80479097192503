import React from 'react';
import { Component } from 'react';
import { Card, Row, Col, CardBody } from 'reactstrap';
import SalesByMonthChart from '../../debtor/MonthySales/SalesByMonthChart';
import SalesByMonthComparison from '../../debtor/MonthySales/SalesByMonthComparison';
import PurchaseHistoryStats from '../../debtor/purchaseHistory/PurchaseHistoryStats';
import SoldProductUnitsChart from '../../debtor/purchaseHistory/SoldProductUnitsChart';
import CustomerDashboardQuickLinks from './CustomerDashboardQuickLinks';
import queryString from 'query-string';
import PurchasedProductsByMonth from '../../debtor/MonthySales/PurchasedProductsByMonth';
import CustomerSalesMonthlyPerformanceByFY from '../../debtor/MonthySales/CustomerSalesMonthlyPerformanceByFY';


export default class CustomerDashboardStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: ""
        };
    }
    componentDidMount() {
        let { accountID } = this.state;
        if (this.props.location) {
            if (this.props.location.search) {
                let searchParams = queryString.parse(this.props.location.search);
                accountID = searchParams.accountID;
                this.setState({ accountID });
            }
        }
    }

    render() {
        let { accountID } = this.state;
        if (!accountID) {
            return null;
        }
        return (
            <div>
                <CustomerDashboardQuickLinks size={"sm"} />
                <hr />
                <div>
                    <div>
                        
                        <PurchaseHistoryStats accountID={accountID} />
                        <hr />
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <CustomerSalesMonthlyPerformanceByFY accountID={accountID} />
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-4"}>
                                <PurchasedProductsByMonth accountID={accountID} />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <div>
                                    <SalesByMonthChart accountID={accountID} />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <div className={"border-left pl-4"}>
                                    <SoldProductUnitsChart accountID={accountID} />
                                </div>
                            </Col>
                        </Row>

                        <hr />
                        <div className={"mt-4"}>
                            <div style={{ maxHeight: 400 }}>
                                <SalesByMonthComparison accountID={accountID} height={400} />
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}