import React, {Component} from 'react';

import {Nav, NavItem, NavLink, Card, CardHeader, CardBody, CardFooter, Row, Col, Button} from 'reactstrap';
import CreatedDateSorter from "./CreatedDateSorter";
import OrderNumSorter from "./OrderNumSorter";
import InvoiceAmountSorter from "./InvoiceAmountSorter";

import AppliedSorters from "./AppliedSorters";

import {findIndex} from '../../services/CommonService';
import ScheduledDateSorter from "./ScheduledDateSorter";


export default class Sorter extends Component {


    constructor(props) {
        super(props);
        let allSorters = [
            {
                name: "CreatedDate",
                displayName: "Created Date",
                selectedOptionName: "",
                valid: false,
                sortIndex: 10
            }, {
                name: "OrderNum",
                displayName: "Order Num",
                selectedOptionName: "",
                valid: false,
                sortIndex: 20
            },
            {
                name: "InvoiceAmount",
                displayName: "Invoice Amount",
                selectedOptionName: "",
                valid: false,
                sortIndex: 30
            },
            {
                name: "ScheduledDate",
                displayName: "Due Date",
                selectedOptionName: "",
                valid: false,
                sortIndex: 40
            }];

        let defaultSelected = {
            name: "",
            displayName: "",
            key: "",
            ascending: true,
            displayValue: ""
        };

        if (this.props.sorters) {
            for (let i in this.props.sorters) {
                let index = findIndex(allSorters, "name", this.props.sorters[i].name);
                if (index > -1) {
                    allSorters[index].displayName = this.props.sorters[i].displayName;
                    allSorters[index].valid = this.props.sorters[i].valid;
                    allSorters[index].sortIndex = this.props.sorters[i].sortIndex;
                    allSorters[index].key = this.props.sorters[i].key;
                    if (this.props.sorters[i].selectedOptionName) {
                        defaultSelected.name = this.props.sorters[i].selectedOptionName;
                        defaultSelected.displayName = this.props.sorters[i].displayName;
                        defaultSelected.key = this.props.sorters[i].name;
                        defaultSelected.ascending = this.props.sorters[i].ascending;
                        defaultSelected.displayValue = this.props.sorters[i].displayValue;
                    }
                }
            }
        }

        let sorters = allSorters.filter((s) => s.valid);

        this.state = {
            loading: true,
            activeSorterTabIndex: 0,
            sorters: sorters,
            selected: defaultSelected
        };
        this.toggleSorterTab = this.toggleSorterTab.bind(this);
        this.updateSorters = this.updateSorters.bind(this);
        this.clearSorters = this.clearSorters.bind(this);
    }


    toggleSorterTab(item) {
        this.setState({activeSorterTabIndex: item});
    }

    updateSorters(selected, reload) {

        this.setState({selected: selected});
        this.props.onChange(selected, reload);
    }

    clearSorters() {
        let selected = {
            name: "",
            displayName: "",
            key: "",
            ascending: true,
            displayValue: ""
        };
        let reload = true;
        this.updateSorters(selected, reload);
    }


    render() {

        let {sorters, activeSorterTabIndex, selected} = this.state;

        let activeSorterTab = {};
        if (sorters) {
            activeSorterTab = sorters[activeSorterTabIndex];
        }

        return (
            <Card className={"mb-2"}>
                <CardHeader><Nav className={"flex-column flex-sm-row"} tabs card>
                    {sorters.sort(function (f1, f2) {
                        return f1.sortIndex - f2.sortIndex;
                    }).map((sorter, index) =>
                        <NavItem key={index} className={"flex-sm-fill text-sm-center hoverableItem"}>
                            <NavLink
                                active={this.state.activeSorterTabIndex === index}
                                onClick={() => {
                                    this.toggleSorterTab(index);
                                }}>
                                {sorter.displayName}
                            </NavLink>
                        </NavItem>
                    )}
                </Nav></CardHeader>
                <CardBody>
                    <div>

                        <div>
                            {(() => {
                                if (activeSorterTab && activeSorterTab.name === "CreatedDate") {
                                    return (
                                        <CreatedDateSorter sorter={activeSorterTab}
                                                           selected={selected}
                                                           onChange={this.updateSorters}/>)
                                }
                                if (activeSorterTab && activeSorterTab.name === "OrderNum") {
                                    return (
                                        <OrderNumSorter sorter={activeSorterTab}
                                                        selected={selected}
                                                        onChange={this.updateSorters}/>)
                                }
                                if (activeSorterTab && activeSorterTab.name === "InvoiceAmount") {
                                    return (
                                        <InvoiceAmountSorter sorter={activeSorterTab}
                                                             selected={selected}
                                                             onChange={this.updateSorters}/>)
                                }
                                if (activeSorterTab && activeSorterTab.name === "ScheduledDate") {
                                    return (
                                        <ScheduledDateSorter sorter={activeSorterTab}
                                                             selected={selected}
                                                             onChange={this.updateSorters}/>)
                                }
                            })()}
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                            <div className={"mt-2"}>
                                <AppliedSorters selected={selected} onChange={this.updateSorters}/>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                            <div className={"text-right mt-2"}>
                                <Button color={"primary"} size={"sm"} onClick={() => this.props.applySorters(false)}
                                        disabled={this.props.loading}>
                                    {this.props.loading ? <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                        <i className="fa fa-search" aria-hidden="true"/>}&nbsp; &nbsp;Apply sorters
                                </Button>
                                &nbsp;&nbsp;
                                <Button color={"secondary"} size={"sm"} onClick={this.clearSorters}
                                        disabled={this.props.loading}>
                                    {this.props.loading ? <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                        <i className="fa fa-times" aria-hidden="true"/>}&nbsp; &nbsp;Clear sorters
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        );
    }
}
