import axios from '../axios';
import axiosFileDownloader from '../axiosFileDownloader';

class SalesOrderService {

    static Instance() {
        return new SalesOrderService();
    }

    getAllCustomerOrders(requestBody, type,isRetail) {
        return axios.post('/api/customer/order/list?type=' + type+'&IsRetail='+isRetail, requestBody)
    }

    GenerateOrderSummaryInExcelReport(requestBody, type) {
        return axiosFileDownloader.post('api/customer/order/GenerateOrderSummaryInExcel?type=' + type, requestBody);
    }

    getTopTenQuotes() {
        return axios.get('/api/customer/order/top/quotes');
    }
}

export default SalesOrderService.Instance();