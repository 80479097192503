import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalFooter, ModalBody,
    Button, Row, Col,
    Spinner,
    Input
} from 'reactstrap';
import DatePicker from "react-datepicker";
import classnames from "classnames";
import {getDateObj, getDateString} from '../../services/CommonService'

export default class DescheduleProductionScheduleModal extends Component {


    render() {
        let {isOpen, toggle, handleChange, handleSubmit, loading, workingDescheduleProductionSchedule, descheduleReasons} = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    De-Schedule
                </ModalHeader>
                <ModalBody>
                    <div className="mb-2">
                        Reason*
                        <Input name="Reason"
                               type={"select"}
                               invalid={!workingDescheduleProductionSchedule.deScheduleReasonId}
                               value={workingDescheduleProductionSchedule.deScheduleReasonId}
                               onChange={(e) => handleChange("workingDescheduleProductionSchedule_deScheduleReasonId", e.target.value, workingDescheduleProductionSchedule)}>
                            <option value={""}>Select</option>
                            {(descheduleReasons || []).map(reason => {
                                return <option value={reason.id}>{reason.name}</option>
                            })}
                        </Input>
                    </div>
                    <div className="mb-2">
                        Follow up date*
                        <DatePicker
                            className="form-control"
                            selected={getDateObj(workingDescheduleProductionSchedule.followUpDate, 'DD-MMM-YYYY')}
                            onChange={(date) => handleChange("workingDescheduleProductionSchedule_followUpDate", getDateString(date, 'DD-MMM-YYYY'), workingDescheduleProductionSchedule)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Enter follow up date"
                            minDate={new Date()}
                            withPortal
                        />
                    </div>

                    <div className="mb-2">
                        Notes
                        <Input name="notes"
                               type={"textarea"}
                               value={workingDescheduleProductionSchedule.notes}
                               placeholder={"Enter notes here"}
                               onChange={(e) => handleChange("workingDescheduleProductionSchedule_notes", e.target.value, workingDescheduleProductionSchedule)}>
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button
                            color={"link"}
                            onClick={() => handleChange("workingDescheduleProductionSchedule_refreshAfterSubmit", !workingDescheduleProductionSchedule.refreshAfterSubmit)}>
                            <i className={classnames("fa", "fa-lg", "mr-2", {
                                    "fa-check-square-o": workingDescheduleProductionSchedule.refreshAfterSubmit,
                                    "fa-square-o": !workingDescheduleProductionSchedule.refreshAfterSubmit,
                                }
                            )}/>
                            Refresh after De-Schedule
                        </Button>
                        <Button
                            className={"mr-2"}
                            color={"primary"}
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {
                                loading
                                    ? <Spinner className={"mr-2"}
                                               style={{color: "white"}}
                                               size={"sm"}/>
                                    : <i className="fa fa-floppy-o mr-2"
                                         aria-hidden="true"/>
                            }
                            {
                                loading
                                    ? "Descheduling"
                                    : "Deschedule"
                            }
                        </Button>
                        <Button color={"secondary"}
                                disabled={loading}
                                onClick={() => toggle(!isOpen)}>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}