export const productGroups = [
    {
        code: "COM",
        sortIndex: 1400,
        productionInstructions: [],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "CTR",
        sortIndex: 400,
        productionInstructions: [],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "FAA",
        sortIndex: 500,
        productionInstructions: [
            {label: "Mount: Inside/Reveal", value: "Mount: Inside/Reveal"},
            {label: "Mount: Outside/Face", value: "Mount: Outside/Face"},
            {label: "Valance: None", value: "Valance: None"},
            {label: "Valance: Straight", value: "Valance: Straight"},
            {label: "Valance: Scallop", value: "Valance: Scallop"}],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "PTR",
        sortIndex: 600,
        productionInstructions: [
            {label: "Mount: Inside", value: "Mount: Inside"},
            {label: "Mount: Outside", value: "Mount: Outside"},
            {label: "Opening: One Way Draw", value: "Opening: One Way Draw"},
            {label: "Opening: Centre Close", value: "Opening: Centre Close"},
            {label: "Opening: Off Centre Close", value: "Opening: Off Centre Close"},
            {label: "Opening: Front Panel Butting", value: "Opening: Front Panel Butting"},
            {label: "Stacking Direction: Left Hand Stack", value: "Stacking Direction: Left Hand Stack"},
            {label: "Stacking Direction: Right Hand Stack", value: "Stacking Direction: Right Hand Stack"},
            {label: "Fix: Face Fix", value: "Fix: Face Fix"},
            {label: "Fix: Ceiling Fix", value: "Fix: Ceiling Fix"}],
        productBuilderVisibleForAccountIDs: [
            '00024-001',
            '00200-328',
            '00200-509',
            '01003-026',
            '01004',
            '01005',
            '01004-00024',
            '01004-1097',
            '01004-999',
            '01097',
            '01297',
            '01374',
            '01430',
            '01500',
            '01604',
            '01604-016',
            '01604-021',
            '01604-033',
            '01604-040',
            '01604-044',
            '01604-047',
            '01696',
            '01719',
            '01731',
            '01731-001',
            '01731-002',
            '01740',
            '01740-001',
            '01770',
            '01819',
            '01820',
            '01829',
            '01836',
            '01850',
            '01857',
            '01857-001',
            '01862',
            '01864',
            '01882',
            '01887',
            '01896',
            '01898',
            '20007',
            '20007-001']
    },
    {
        code: "ROM",
        sortIndex: 300,
        productionInstructions: [
            {label: "Mount: Inside/Reveal", value: "Mount: Inside/Reveal"},
            {label: "Mount: Outside/Face", value: "Mount: Outside/Face"},
            {label: "Control Side: Left", value: "Control Side: Left"},
            {label: "Control Side: Right", value: "Control Side: Right"}],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "VEN",
        sortIndex: 700,
        productionInstructions: [
            {label: "Mount: Inside", value: "Mount: Inside"},
            {label: "Mount: Outside", value: "Mount: Outside"},
            {label: "Lift Control: LHS", value: "Lift Control: LHS"},
            {label: "Lift Control: RHS", value: "Lift Control: RHS"},
            {label: "Tilt Control: LHS", value: "Tilt Control: LHS"},
            {label: "Tilt Control: RHS", value: "Tilt Control: RHS"}],
        productBuilderVisibleForAccountIDs: ['01500','01004','01005']
    },
    {
        code: "VIN",
        sortIndex: 800,
        productionInstructions: [
            {label: "Mount: Inside/Reveal", value: "Mount: Inside/Reveal"},
            {label: "Mount: Outside/Face", value: "Mount: Outside/Face"},
            {label: "Stacking Direction: Left", value: "Stacking Direction: Left"},
            {label: "Stacking Direction: Right", value: "Stacking Direction: Right"},
            {label: "Stacking Direction: Centre Bunch", value: "Stacking Direction: Centre Bunch"},
            {label: "Stacking Direction: Centre Open", value: "Stacking Direction: Centre Open"}],
        productBuilderVisibleForAccountIDs: [
            '00024-001',
            '00200-328',
            '00200-509',
            '01003-026',
            '01004',
            '01005',
            '01004-00024',
            '01004-1097',
            '01004-999',
            '01097',
            '01297',
            '01374',
            '01430',
            '01500',
            '01604',
            '01604-016',
            '01604-021',
            '01604-033',
            '01604-040',
            '01604-044',
            '01604-047',
            '01696',
            '01719',
            '01731',
            '01731-001',
            '01731-002',
            '01740',
            '01740-001',
            '01770',
            '01819',
            '01820',
            '01829',
            '01836',
            '01850',
            '01857',
            '01857-001',
            '01862',
            '01864',
            '01882',
            '01887',
            '01896',
            '01898',
            '20007',
            '20007-001']
    },
    {
        code: "VOD",
        sortIndex: 900,
        productionInstructions: [
            {label: "Mount: Reveal Fix", value: "Mount: Reveal Fix"},
            {label: "Mount: Face Fix", value: "Mount: Face Fix"},
            {label: "Control Side: Left", value: "Control Side: Left"},
            {label: "Control Side: Right", value: "Control Side: Right"},
            {label: "Installation Type: Deck Surface Mount", value: "Installation Type: Deck Surface Mount"},
            {label: "Installation Type: Masonry Surface Mount", value: "Installation Type: Masonry Surface Mount"},
            {label: "Installation Type: Face/ Side Fit", value: "Installation Type: Face/ Side Fit"},
            {label: "Installation Type: Face Fit", value: "Installation Type: Face Fit"},
            {label: "Installation Type: Reveal Fit", value: "Installation Type: Reveal Fit"},
            {label: "Valance: None", value: "Valance: None"},
            {label: "Valance: Straight", value: "Valance: Straight"},
            {label: "Valance: Scallop", value: "Valance: Scallop"}],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "ZIP",
        sortIndex: 1000,
        productionInstructions: [
            {label: "Fix: Face Fix", value: "Fix: Face Fix"},
            {label: "Fix: Ceiling Fix", value: "Fix: Ceiling Fix"}],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "PWD",
        sortIndex: 1100,
        productionInstructions: [],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "CTS",
        sortIndex: 1200,
        productionInstructions: [],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "PPE",
        sortIndex: 1300,
        productionInstructions: [],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "ROL",
        sortIndex: 100,
        productionInstructions: [
            {label: "Mount: Inside/Reveal", value: "Mount: Inside/Reveal"},
            {label: "Mount: Outside/Face", value: "Mount: Outside/Face"},
            {label: "Roll Direction: Under/Back Roll", value: "Roll Direction: Under/Back Roll"},
            {label: "Roll Direction: Over/Front Roll", value: "Roll Direction: Over/Front Roll"},
            {label: "Control Side: Left", value: "Control Side: Left"},
            {label: "Control Side: Right", value: "Control Side: Right"}],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "ROLS",
        sortIndex: 200,
        productionInstructions: [
            {label: "Mount: Inside/Reveal", value: "Mount: Inside/Reveal"},
            {label: "Mount: Outside/Face", value: "Mount: Outside/Face"},
            {label: "Roll Direction: Under/Back Roll", value: "Roll Direction: Under/Back Roll"},
            {label: "Roll Direction: Over/Front Roll", value: "Roll Direction: Over/Front Roll"},
            {label: "Control Side: Left", value: "Control Side: Left"},
            {label: "Control Side: Right", value: "Control Side: Right"}],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "ROLD",
        sortIndex: 200,
        productionInstructions: [
            {label: "Mount: Inside/Reveal", value: "Mount: Inside/Reveal"},
            {label: "Mount: Outside/Face", value: "Mount: Outside/Face"},
            {label: "Roll Direction: Under/Back Roll", value: "Roll Direction: Under/Back Roll"},
            {label: "Roll Direction: Over/Front Roll", value: "Roll Direction: Over/Front Roll"},
            {label: "Control Side: Left", value: "Control Side: Left"},
            {label: "Control Side: Right", value: "Control Side: Right"}],
        productBuilderVisibleForAccountIDs: []
    },
    {
        code: "OTHER",
        sortIndex: 1500,
        productionInstructions: [],
        productBuilderVisibleForAccountIDs: []
    },
];

export const productConstant = {
        CURTAIN_TRACKS: {code: "CTR"},
        FOLDING_ARM_AWNINGS: {code: "FAA"},
        PANEL_TRACKS: {code: "PTR"},
        ROLLER_BLINDS_SINGLE: {code: "ROLS"},
        ROLLER_BLINDS_DOUBLE: {code: "ROLD"},
        ROMAN_BLINDS: {code: "ROM"},
        VENETIANS: {code: "VEN"},
        VERTICAL_INDOOR: {code: "VIN"},
        VERTICAL_OUTDOOR: {code: "VOD"},
        ZIPTRAK: {code: "ZIP"},
        ZIPTRAK_EXTERNAL: {code: "ZIPE"},
        ZIPTRAK_INTERNAL: {code: "ZIPI"},
        SHP_CTS: {code: "CTS"},
        HOSPITAL_RANGE: {code: "HOS"},
        WIDESCREEN: {code: "WID"},
        PROJECTION_AWNINGS: {code: "PRJ"},
        SKIN_ONLY: {code: "SKIN"},
        CUSTOM: {code: "CUST"},
    }
;

export const PRODUCT_GROUP_COMPONENT_ID = 2;
export const PRODUCT_GROUP_REWORK_ID = 100;
export const PRODUCT_CUSTOM_ID = 22;
