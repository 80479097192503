import React, {Component} from 'react';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import OrderService from "../../../services/OrderService";
import {handleErrorMessage} from '../../../services/CommonService';
import {toast} from 'react-toastify';
import PurchaseOrderService from '../../../services/purchase/PurchaseOrderService';

export default class UpdatePurchaseOrderNoteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ordNum: 0,
            notes: ""
        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ordNum: this.props.ordNum, notes: this.props.selected});
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            let notes = nextProps.selected;
            this.setState({ordNum: nextProps.ordNum, notes});
        }
    }

    handleChange(e) {
        this.setState({notes: e.target.value});
    }


    handleSubmit() {
        let { ordNum, notes } = this.state;
        let { isNote } = this.props;

        this.setState({ loading: true });
        if (isNote) {
            this.purchaseOrderService.updateNote(ordNum, notes).then(response => {
                this.setState({ loading: false });
                this.props.refreshOrder();
                toast.success("Notes updated!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.toggleModel();
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error.response);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        else {
            this.purchaseOrderService.updateInstruction(ordNum, notes).then(response => {
                this.setState({ loading: false });
                this.props.refreshOrder();
                toast.success("Notes updated!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.toggleModel();
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error.response);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        
    }

    render() {

        let {notes} = this.state;
        let { isNote } = this.props;
        return (
            <Modal isOpen={this.props.isOpen} size="md" scrollable={false} toggle={() => this.props.toggleModel(false)}>
                <ModalHeader toggle={() => this.props.toggleModel(false)}>
                    Update Order {isNote ? "Notes" : "Special Instruction"}
                </ModalHeader>
                <ModalBody>
                    <div className="mt-1 mb-2">
                        <Label>Enter notes</Label>
                        <Input className="form-control"
                               onChange={this.handleChange}
                               type="textarea"
                               name="notes"
                               value={notes}
                               placeholder="Write here"/>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"}
                                size={"sm"}
                                onClick={this.handleSubmit}
                                disabled={this.state.loading}>
                            {this.state.loading ? <Spinner size={"sm"} className={"mr-2"}/> : null}
                            {this.state.loading ? "Saving" : "Save"}</Button>
                        <Button color={"secondary"}
                            size={"sm"}
                            className={"ml-2"} onClick={() => this.props.toggleModel(false)}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}