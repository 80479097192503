import React, { Component } from 'react';
import OrderService from "../../services/OrderService";
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';

export default class SearchImportSourceOrderID extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            defaultImportSourceOrderID: props.defaultImportSourceOrderID,
            importSourceOrderID: props.selectedImportSourceOrderID,
            loading: false,
            allImportSourceOrderIDs: [],
            inputValue: "",
        };
        this.orderService = new OrderService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.fetchOptions(this.state.defaultImportSourceOrderID || this.state.importSourceOrderID);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultImportSourceOrderID !== nextProps.defaultImportSourceOrderID) {
            if (nextProps.defaultImportSourceOrderID) {
                this.setState({
                    defaultImportSourceOrderID: nextProps.defaultImportSourceOrderID,
                    importSourceOrderID: nextProps.defaultImportSourceOrderID
                }, () => {
                    this.fetchOptions(nextProps.defaultImportSourceOrderID);
                })
            }
        }

        if (this.state.importSourceOrderID !== nextProps.selectedImportSourceOrderID) {
            if (!nextProps.selectedImportSourceOrderID) {
                this.setState({ selected: null, importSourceOrderID: "" })
            }
            else {
                this.setState({ importSourceOrderID: nextProps.selectedImportSourceOrderID })
            }
            this.fetchOptions(nextProps.selectedImportSourceOrderID);
        }
        if (
            this.state.includeChildren !== nextProps.includeChildren
            || this.state.includeClosed !== nextProps.includeClosed
            || this.state.includeInactive !== nextProps.includeInactive
        ) {
            this.setState({ includeChildren: nextProps.includeChildren, includeClosed: nextProps.includeClosed, includeInactive: nextProps.includeInactive }
                , () => { this.fetchOptions(this.state.defaultImportSourceOrderID) });
        }
    }

    handleSelect(selectedImportSourceOrderID, event) {
        let { selected, importSourceOrderID } = this.state;
        selected = selectedImportSourceOrderID;
        importSourceOrderID = selectedImportSourceOrderID ? selectedImportSourceOrderID.importSourceOrderID : "";
        switch (event) {
            case "selected":
                this.setState({ selected, importSourceOrderID });
                break;
            case "onChange":
            case "default":
            default:
                this.setState({ selected, importSourceOrderID });
                this.props.handleImportSourceOrderIDChange(importSourceOrderID);
                break;
        }
        if (!importSourceOrderID) {
            this.fetchOptions("");
        }
    };

    fetchOptions(inputValue, callback) {
        let allImportSourceOrderIDs = [];
        this.orderService.searchImportSourceOrderID(inputValue).then(response => {
            let data = response.data;
            for (let i in data) {
                let importSourceOrderID = {
                    label: data[i],
                    value: data[i],
                    id: data[i],
                    importSourceOrderID: data[i],
                };
                allImportSourceOrderIDs.push(cloneDeep(importSourceOrderID));
                if (this.props.defaultImportSourceOrderID) {
                    if (data[i] === this.props.defaultImportSourceOrderID) {
                        this.handleSelect(importSourceOrderID, "default");
                    }
                }
                if (this.props.selectedImportSourceOrderID) {
                    if (data[i] === this.props.selectedImportSourceOrderID) {
                        this.handleSelect(importSourceOrderID, "selected");
                    }
                }
            }
            this.setState({ allImportSourceOrderIDs });
            if (callback) {
                return callback(allImportSourceOrderIDs)
            } else {
                return allImportSourceOrderIDs;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        inputValue = inputValue.trim();
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 0) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allImportSourceOrderIDs);
            }
        }
    };

    render() {
        return (
            <div>
                <AsyncSelect
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.allImportSourceOrderIDs}
                    loadOptions={this.loadOptions}
                    onChange={(selection) => this.handleSelect(selection, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                    placeholder={"Search..."}
                />
            </div>
        );
    }
}