import React, { Component } from 'react';

export let quiklokLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "model",
        label: "Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "width",
        label: "Width",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "drop",
        label: "Drop",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "keywayCutWidth",
        label: "Keyway Cut Width",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "colour",
        label: "Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
];