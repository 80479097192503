import React, {Component} from 'react';
import { Badge, Button, Input, Table, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';

import {findIndex, getDistinct, getSorted, scrollTo} from '../../../../../../../services/CommonService';
import {cloneDeep, isEmpty, camelCase} from 'lodash';
import classnames from 'classnames';

import ziptrakUtil from './ZiptrakExternalUtil';
import {pricingMatrixVariant} from '../../../../../../../store/sales/order/productBuilder/PricingMatrixConstants';
import NumberFormat from "react-number-format";
import ZiptrakExternalBOMModal from "../external/ZiptrakExternalBOMModal";
import External from "../external/ZiptrakExternalConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../../custom/CustomUtil";
import {
    PRODUCT_BUILDER_CUSTOM_BUILD, PRODUCT_BUILDER_ZIPTRAK_EXTERNAL_PRODCODE
} from "../../../../../../../store/AppConstants";

const quantityOptions = [1, 2, 3, 4, 5];

export default class ZiptrakExternal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = ziptrakUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = ziptrakUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_ZIPTRAK_EXTERNAL_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if (stocksByProdCode) {
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                        }
                        if (stock) {
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);

                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
            default:
                break;
        }
        product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemZiptrak.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemZiptrak.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 0;// default
        product.items[itemIndex].configuration.width.max = 0;// default
        product.items[itemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemZiptrak.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1500;
        }

        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //topWidth
        configurationIndex = findIndex(configurations, "attributeKey", "topWidth");
        product.items[itemIndex].configuration.topWidth = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.topWidth.min = 0;// default
        product.items[itemIndex].configuration.topWidth.max = 0;// default
        product.items[itemIndex].configuration.topWidth.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.topWidth.selected.value = parseInt(context.salesOrderItemZiptrak.topWidth);
        } else {
            product.items[itemIndex].configuration.topWidth.selected.value = 1500;
        }

        product.items[itemIndex].configuration.topWidth.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.min = 0;//default
        product.items[itemIndex].configuration.drop.max = 0;//default
        product.items[itemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemZiptrak.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }

        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };


        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemZiptrak.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {};
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemZiptrak.fabricType);
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;

        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true,
        };
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, context);
        if (product.items[itemIndex].configuration.fabricColour.finalOptions.length > 0) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemZiptrak.fabricColour);
                optionIndex = optionIndex > -1 ? optionIndex : 0;
            } else {
                optionIndex = 0;
            }
            product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};


        //fabricColourOffRange
        product.items[itemIndex].configuration.fabricColourOffRange = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = context.salesOrderItemZiptrak.fabricColourOffRange;
        } else {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
        }
        product.items[itemIndex].configuration.fabricColourOffRange.formError = {isValid: true, message: "", dom: null};


        //isSkinOnly
        configurationIndex = findIndex(configurations, "attributeKey", "isSkinOnly");
        product.items[itemIndex].configuration.isSkinOnly = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.isSkinOnly.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.isSkinOnly.selected.value = context.salesOrderItemZiptrak.isSkinOnly;
        } else {
            product.items[itemIndex].configuration.isSkinOnly.selected.value = false;
        }


        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.operation.selected = {};
        product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options;
        product.items[itemIndex].configuration.operation.finalOptions = getSorted(product.items[itemIndex].configuration.operation.finalOptions, "condition1", true);
        product.items[itemIndex].configuration.operation.finalOptions.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', context.salesOrderItemZiptrak.operation);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
        product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
        product.items[itemIndex].configuration.operation.formError = {isValid: true, message: "", dom: null,};


        //motorSide
        configurationIndex = findIndex(configurations, "attributeKey", "motorSide");
        product.items[itemIndex].configuration.motorSide = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.motorSide.selected = {};
        product.items[itemIndex].configuration.motorSide.finalOptions = product.items[itemIndex].configuration.motorSide.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.motorSide.finalOptions, 'optionKey', context.salesOrderItemZiptrak.motorSide);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.motorSide.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.motorSide.finalOptions[optionIndex] : {};
        product.items[itemIndex].configuration.motorSide.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.motorSide.selected.value.optionKey : "";
        product.items[itemIndex].configuration.motorSide.formError = {isValid: true, message: "", dom: null,};


        //customerSupplyMotorType
        product.items[itemIndex].configuration.customerSupplyMotorType = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.customerSupplyMotorType.selected.value = context.salesOrderItemZiptrak.customerSupplyMotorType;
        } else {
            product.items[itemIndex].configuration.customerSupplyMotorType.selected.value = "";
        }
        product.items[itemIndex].configuration.customerSupplyMotorType.formError = {
            isValid: true,
            message: "",
            dom: null
        };


        //keyway
        configurationIndex = findIndex(configurations, "attributeKey", "keyway");
        product.items[itemIndex].configuration.keyway = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.keyway.selected = {};
        product.items[itemIndex].configuration.keyway.finalOptions = product.items[itemIndex].configuration.keyway.options;
        optionIndex = 0;
        product.items[itemIndex].configuration.keyway.selected.value = product.items[itemIndex].configuration.keyway.finalOptions[optionIndex];
        product.items[itemIndex].configuration.keyway.selected.dropdownValue = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
        product.items[itemIndex].configuration.keyway.selected.value.sets = getSorted(product.items[itemIndex].configuration.keyway.selected.value.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        product = this.updateItemConfigurationOptions("keyway", product, itemIndex, context);
        product.items[itemIndex].configuration.keyway.formError = {isValid: true, message: "", dom: null,};

        //splineColour
        configurationIndex = findIndex(configurations, "attributeKey", "splineColour");
        product.items[itemIndex].configuration.splineColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.splineColour.selected = {};
        product.items[itemIndex].configuration.splineColour.finalOptions = product.items[itemIndex].configuration.splineColour.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.splineColour.finalOptions, 'optionKey', context.salesOrderItemZiptrak.splineColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.splineColour.selected.value = product.items[itemIndex].configuration.splineColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.splineColour.selected.dropdownValue = product.items[itemIndex].configuration.splineColour.selected.value.optionKey;
        product.items[itemIndex].configuration.splineColour.formError = {isValid: true, message: "", dom: null,};


        //hooding
        configurationIndex = findIndex(configurations, "attributeKey", "hooding");
        product.items[itemIndex].configuration.hooding = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hooding.selected = {};
        product.items[itemIndex].configuration.hooding.finalOptions = product.items[itemIndex].configuration.hooding.options;
        product.items[itemIndex].configuration.hooding.finalOptions = getSorted(product.items[itemIndex].configuration.hooding.finalOptions, 'primaryAttributeMin', true);
        product.items[itemIndex].configuration.hooding.finalOptions.forEach(o => {
            o.isSelectable = true;
            o.sets = getSorted(o.sets, "setNum", true, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, 'optionKey', context.salesOrderItemZiptrak.hooding);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.hooding.selected.isSelectable = true;
        product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
        product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
        product.items[itemIndex].configuration.hooding.formError = {isValid: true, message: "", dom: null,};


        //hoodingColour
        configurationIndex = findIndex(configurations, "attributeKey", "hoodingColour");
        product.items[itemIndex].configuration.hoodingColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hoodingColour.selected = {};
        product.items[itemIndex].configuration.hoodingColour.finalOptions = product.items[itemIndex].configuration.hoodingColour.options;
        product.items[itemIndex].configuration.hoodingColour.finalOptions = getSorted(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'primaryAttributeMin', true);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'optionKey', context.salesOrderItemZiptrak.hoodingColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = true;
        product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
        product.items[itemIndex].configuration.hoodingColour.formError = {isValid: true, message: "", dom: null,};


        //hoodingPowdercoatColour
        product.items[itemIndex].configuration.hoodingPowdercoatColour = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = context.salesOrderItemZiptrak.hoodingPowdercoatColour;
        } else {
            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = "";
        }
        product.items[itemIndex].configuration.hoodingPowdercoatColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //frameColour
        configurationIndex = findIndex(configurations, "attributeKey", "frameColour");
        product.items[itemIndex].configuration.frameColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frameColour.selected = {};
        product.items[itemIndex].configuration.frameColour.options = getSorted(product.items[itemIndex].configuration.frameColour.options, 'primaryAttributeMin', true);
        product.items[itemIndex].configuration.frameColour.finalOptions = product.items[itemIndex].configuration.frameColour.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, 'optionKey', context.salesOrderItemZiptrak.frameColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
        product.items[itemIndex].configuration.frameColour.formError = {isValid: true, message: "", dom: null,};

        //frameColourRight
        configurationIndex = findIndex(configurations, "attributeKey", "frameColour");
        product.items[itemIndex].configuration.frameColourRight = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frameColourRight.selected = {};
        product.items[itemIndex].configuration.frameColourRight.finalOptions = product.items[itemIndex].configuration.frameColourRight.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.frameColourRight.finalOptions, 'optionKey', context.salesOrderItemZiptrak.frameColourRight);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.frameColourRight.selected.value = product.items[itemIndex].configuration.frameColourRight.finalOptions[optionIndex];
        product.items[itemIndex].configuration.frameColourRight.selected.dropdownValue = product.items[itemIndex].configuration.frameColourRight.selected.value.optionKey;
        product.items[itemIndex].configuration.frameColourRight.formError = {isValid: true, message: "", dom: null,};

        //framePowdercoatColour
        product.items[itemIndex].configuration.framePowdercoatColour = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = context.salesOrderItemZiptrak.framePowdercoatColour;
        } else {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
        }
        product.items[itemIndex].configuration.framePowdercoatColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //framePowdercoatColourRight
        product.items[itemIndex].configuration.framePowdercoatColourRight = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.framePowdercoatColourRight.selected.value = context.salesOrderItemZiptrak.framePowdercoatColourRight;
        } else {
            product.items[itemIndex].configuration.framePowdercoatColourRight.selected.value = "";
        }
        product.items[itemIndex].configuration.framePowdercoatColourRight.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //bracketType
        configurationIndex = findIndex(configurations, "attributeKey", "bracketType");
        product.items[itemIndex].configuration.bracketType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracketType.selected = {};
        product.items[itemIndex].configuration.bracketType.finalOptions = product.items[itemIndex].configuration.bracketType.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bracketType.finalOptions, 'optionKey', context.salesOrderItemZiptrak.bracketType);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bracketType.selected.isSelectable = true;
        product.items[itemIndex].configuration.bracketType.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.bracketType.finalOptions[optionIndex] : {};
        product.items[itemIndex].configuration.bracketType.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.bracketType.selected.value.optionKey : "";
        product.items[itemIndex].configuration.bracketType.formError = {isValid: true, message: "", dom: null,};

        //bracketColour
        configurationIndex = findIndex(configurations, "attributeKey", "frameColour");
        product.items[itemIndex].configuration.bracketColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracketColour.selected = {};
        product.items[itemIndex].configuration.bracketColour.options = getSorted(product.items[itemIndex].configuration.bracketColour.options, 'primaryAttributeMin', true);
        product.items[itemIndex].configuration.bracketColour.finalOptions = product.items[itemIndex].configuration.bracketColour.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.bracketType.selected.dropdownValue));

        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bracketColour.finalOptions, 'optionKey', context.salesOrderItemZiptrak.bracketColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bracketColour.selected.isSelectable = true;
        product.items[itemIndex].configuration.bracketColour.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex] : {};
        product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.bracketColour.selected.value.optionKey : "";
        product.items[itemIndex].configuration.bracketColour.formError = {isValid: true, message: "", dom: null,};

        //bracketColourPowdercoat
        product.items[itemIndex].configuration.bracketColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = context.salesOrderItemZiptrak.bracketColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.bracketColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //channels
        configurationIndex = findIndex(configurations, "attributeKey", "channels");
        product.items[itemIndex].configuration.channels = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channels.selected = {};
        product.items[itemIndex].configuration.channels.options.forEach(o => {
            if (!isEmpty(o.sets)) {
                o.sets = getSorted(o.sets, "min", true, "max", true);
            }
        });
        product.items[itemIndex].configuration.channels.finalOptions = product.items[itemIndex].configuration.channels.options.filter(o => !o.parentOptionId);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channels.finalOptions, 'optionKey', context.salesOrderItemZiptrak.channels);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.channels.selected.value = product.items[itemIndex].configuration.channels.finalOptions[optionIndex];
        product.items[itemIndex].configuration.channels.selected.dropdownValue = product.items[itemIndex].configuration.channels.selected.value.optionKey;
        product.items[itemIndex].configuration.channels.formError = {isValid: true, message: "", dom: null,};

        //channelsRight
        configurationIndex = findIndex(configurations, "attributeKey", "channels");
        product.items[itemIndex].configuration.channelsRight = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channelsRight.selected = {};
        product.items[itemIndex].configuration.channelsRight.options.forEach(o => {
            if (!isEmpty(o.sets)) {
                o.sets = getSorted(o.sets, "min", true, "max", true);
            }
        });
        product.items[itemIndex].configuration.channelsRight.finalOptions = product.items[itemIndex].configuration.channelsRight.options.filter(o => !o.parentOptionId);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channelsRight.finalOptions, 'optionKey', context.salesOrderItemZiptrak.channelsRight);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.channelsRight.selected.value = product.items[itemIndex].configuration.channelsRight.finalOptions[optionIndex];
        product.items[itemIndex].configuration.channelsRight.selected.dropdownValue = product.items[itemIndex].configuration.channelsRight.selected.value.optionKey;
        product.items[itemIndex].configuration.channelsRight.formError = {isValid: true, message: "", dom: null,};

        //centralLock
        configurationIndex = findIndex(configurations, "attributeKey", "centralLock");
        product.items[itemIndex].configuration.centralLock = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.centralLock.selected = {};
        product.items[itemIndex].configuration.centralLock.options = getSorted(product.items[itemIndex].configuration.centralLock.options, 'primaryAttributeMin', true);
        product.items[itemIndex].configuration.centralLock.finalOptions = product.items[itemIndex].configuration.centralLock.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.centralLock.finalOptions, 'optionKey', context.salesOrderItemZiptrak.centralLock);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.centralLock.selected.isSelectable = true;
        product.items[itemIndex].configuration.centralLock.selected.value = product.items[itemIndex].configuration.centralLock.finalOptions[optionIndex];
        product.items[itemIndex].configuration.centralLock.selected.dropdownValue = product.items[itemIndex].configuration.centralLock.selected.value.optionKey;
        product.items[itemIndex].configuration.centralLock.formError = {isValid: true, message: "", dom: null,};


        //bottomRailFinish
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailFinish");
        product.items[itemIndex].configuration.bottomRailFinish = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRailFinish.selected = {};
        product.items[itemIndex].configuration.bottomRailFinish.finalOptions = product.items[itemIndex].configuration.bottomRailFinish.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailFinish.finalOptions, 'optionKey', context.salesOrderItemZiptrak.bottomRailFinish);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bottomRailFinish.selected.value = product.items[itemIndex].configuration.bottomRailFinish.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomRailFinish.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailFinish.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomRailFinish.formError = {isValid: true, message: "", dom: null,};


        //bottomRailDuty
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailDuty");
        product.items[itemIndex].configuration.bottomRailDuty = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRailDuty.selected = {};
        product.items[itemIndex].configuration.bottomRailDuty.finalOptions = product.items[itemIndex].configuration.bottomRailDuty.options.reverse(o => o.optionLabel);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailDuty.finalOptions, 'optionKey', context.salesOrderItemZiptrak.bottomRailDuty);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bottomRailDuty.selected.isSelectable = true;
        product.items[itemIndex].configuration.bottomRailDuty.selected.value = product.items[itemIndex].configuration.bottomRailDuty.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomRailDuty.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomRailDuty.formError = {isValid: true, message: "", dom: null,};

        //bottomRailColour
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailColour");
        product.items[itemIndex].configuration.bottomRailColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRailColour.selected = {};
        console.log(product.items[itemIndex].configuration.bottomRailColour.options)
        product.items[itemIndex].configuration.bottomRailColour.options = getSorted(product.items[itemIndex].configuration.bottomRailColour.options, 'primaryAttributeMin', true);
        product.items[itemIndex].configuration.bottomRailColour.finalOptions = product.items[itemIndex].configuration.bottomRailColour.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailColour.finalOptions, 'optionKey', context.salesOrderItemZiptrak.bottomRailColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bottomRailColour.selected.isSelectable = true;
        product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomRailColour.formError = {isValid: true, message: "", dom: null,};

        //bottomRailColourPowdercoat
        product.items[itemIndex].configuration.bottomRailColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = context.salesOrderItemZiptrak.bottomRailColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.bottomRailColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //windoware15ChannelRemote
        configurationIndex = findIndex(configurations, "attributeKey", "windoware15ChannelRemote");
        product.items[itemIndex].configuration.windoware15ChannelRemote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windoware15ChannelRemote.finalOptions = product.items[itemIndex].configuration.windoware15ChannelRemote.options;

        product.items[itemIndex].configuration.windoware15ChannelRemote.selected = {};
        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = context.salesOrderItemZiptrak.windoware15ChannelRemote;
        } else {
            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = false;
        }


        //windowareBridge
        configurationIndex = findIndex(configurations, "attributeKey", "windowareBridge");
        product.items[itemIndex].configuration.windowareBridge = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windowareBridge.finalOptions = product.items[itemIndex].configuration.windowareBridge.options;
        product.items[itemIndex].configuration.windowareBridge.selected = {};
        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.windowareBridge.selected.value = context.salesOrderItemZiptrak.windowareBridge;
        } else {
            product.items[itemIndex].configuration.windowareBridge.selected.value = false;
        }

        //chargingCable
        configurationIndex = findIndex(configurations, "attributeKey", "chargingCable");
        product.items[itemIndex].configuration.chargingCable = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.chargingCable.finalOptions = product.items[itemIndex].configuration.chargingCable.options;

        product.items[itemIndex].configuration.chargingCable.selected = {};
        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.chargingCable.selected.value = context.salesOrderItemZiptrak.chargingCable;
        } else {
            product.items[itemIndex].configuration.chargingCable.selected.value = false;
        }

        //somfy1ChannelSituoRemote
        configurationIndex = findIndex(configurations, "attributeKey", "somfyChannelSituoRemote");
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.finalOptions = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.options;
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected = {};
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = context.salesOrderItemZiptrak.somfy1ChannelSituoRemote;
        } else {
            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = false;
        }

        //pullDownStick
        configurationIndex = findIndex(configurations, "attributeKey", "pullDownStick");
        product.items[itemIndex].configuration.pullDownStick = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.pullDownStick.finalOptions = product.items[itemIndex].configuration.pullDownStick.options;
        product.items[itemIndex].configuration.pullDownStick.selected = {};
        product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.pullDownStick.selected.value = context.salesOrderItemZiptrak.pullDownStick;
        } else {
            product.items[itemIndex].configuration.pullDownStick.selected.value = false;
        }


        //highImpactStoppers
        configurationIndex = findIndex(configurations, "attributeKey", "highImpactStoppers");
        product.items[itemIndex].configuration.highImpactStoppers = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.highImpactStoppers.finalOptions = product.items[itemIndex].configuration.highImpactStoppers.options;
        product.items[itemIndex].configuration.highImpactStoppers.selected = {};
        product.items[itemIndex].configuration.highImpactStoppers.selected.isSelectable = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.highImpactStoppers.selected.value = context.salesOrderItemZiptrak.highImpactStoppers;
        } else {
            product.items[itemIndex].configuration.highImpactStoppers.selected.value = false;
        }


        //additionalBottomStoppers
        configurationIndex = findIndex(configurations, "attributeKey", "additionalBottomStoppers");
        product.items[itemIndex].configuration.additionalBottomStoppers = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.additionalBottomStoppers.finalOptions = product.items[itemIndex].configuration.additionalBottomStoppers.options;
        product.items[itemIndex].configuration.additionalBottomStoppers.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.additionalBottomStoppers.selected.value = context.salesOrderItemZiptrak.additionalBottomStoppers;
        } else {
            product.items[itemIndex].configuration.additionalBottomStoppers.selected.value = 0;
        }
        product.items[itemIndex].configuration.additionalBottomStoppers.min = 1;
        product.items[itemIndex].configuration.additionalBottomStoppers.max = 999;
        product.items[itemIndex].configuration.additionalBottomStoppers.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //centralLockFaceFixStoppers
        configurationIndex = findIndex(configurations, "attributeKey", "centralLockFaceFixStoppers");
        product.items[itemIndex].configuration.centralLockFaceFixStoppers = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.centralLockFaceFixStoppers.finalOptions = product.items[itemIndex].configuration.centralLockFaceFixStoppers.options;
        product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected = {};
        product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.isSelectable = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.value = context.salesOrderItemZiptrak.centralLockFaceFixStoppers;
        } else {
            product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.value = false;
        }

        //backFlashingCut
        configurationIndex = findIndex(configurations, "attributeKey", "backFlashingCut");
        product.items[itemIndex].configuration.backFlashingCut = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.backFlashingCut.finalOptions = product.items[itemIndex].configuration.backFlashingCut.options;
        product.items[itemIndex].configuration.backFlashingCut.selected = {};
        product.items[itemIndex].configuration.backFlashingCut.selected.isSelectable = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.backFlashingCut.selected.value = context.salesOrderItemZiptrak.flashBackCut;
        } else {
            product.items[itemIndex].configuration.backFlashingCut.selected.value = false;
        }

        //backFlashingCutColour
        configurationIndex = findIndex(configurations, "attributeKey", "backFlashingCutColour");
        product.items[itemIndex].configuration.backFlashingCutColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.backFlashingCutColour.selected = {};
        product.items[itemIndex].configuration.backFlashingCutColour.finalOptions = product.items[itemIndex].configuration.backFlashingCutColour.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.backFlashingCutColour.finalOptions, 'optionKey', context.salesOrderItemZiptrak.flashBackCutColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.backFlashingCutColour.selected.isSelectable = false;
        product.items[itemIndex].configuration.backFlashingCutColour.selected.value = product.items[itemIndex].configuration.backFlashingCutColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.backFlashingCutColour.selected.dropdownValue = product.items[itemIndex].configuration.backFlashingCutColour.selected.value.optionKey;
        product.items[itemIndex].configuration.backFlashingCutColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //removablePostKit
        configurationIndex = findIndex(configurations, "attributeKey", "removablePostKit");
        product.items[itemIndex].configuration.removablePostKit = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.removablePostKit.finalOptions = product.items[itemIndex].configuration.removablePostKit.options;
        product.items[itemIndex].configuration.removablePostKit.selected = {};
        product.items[itemIndex].configuration.removablePostKit.selected.isSelectable = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.removablePostKit.selected.value = context.salesOrderItemZiptrak.removablePostKit;
        } else {
            product.items[itemIndex].configuration.removablePostKit.selected.value = false;
        }


        //flatBar
        configurationIndex = findIndex(configurations, "attributeKey", "flatBar");
        product.items[itemIndex].configuration.flatBar = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.flatBar.selected = {};
        product.items[itemIndex].configuration.flatBar.finalOptions = product.items[itemIndex].configuration.flatBar.options;
        product.items[itemIndex].configuration.flatBar.selected.value = false;


        //weightBar
        configurationIndex = findIndex(configurations, "attributeKey", "weightBar");
        product.items[itemIndex].configuration.weightBar = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.weightBar.selected = {};
        product.items[itemIndex].configuration.weightBar.finalOptions = product.items[itemIndex].configuration.weightBar.options;
        product.items[itemIndex].configuration.weightBar.selected.value = false;


        //insectControlBrush
        configurationIndex = findIndex(configurations, "attributeKey", "insectControlBrush");
        product.items[itemIndex].configuration.insectControlBrush = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.insectControlBrush.finalOptions = product.items[itemIndex].configuration.insectControlBrush.options;

        product.items[itemIndex].configuration.insectControlBrush.selected = {};
        product.items[itemIndex].configuration.insectControlBrush.selected.isSelectable = false;
        product.items[itemIndex].configuration.insectControlBrush.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.insectControlBrush.selected.value = context.salesOrderItemZiptrak.insectControlBrush;
        } else {
            product.items[itemIndex].configuration.insectControlBrush.selected.value = false;
        }

        //deduction
        product.items[itemIndex].configuration.deduction = {
            deductionRules: [],
            selected: {
                value: {
                    deduction: 0
                }
            }
        };


        product = this.updateItemConfigurationOptions("operation", product, itemIndex, context);
        product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("hooding", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("splineColour", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("bottomRailDuty", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("centralLock", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("centralLockFaceFixStoppers", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, null);

        product = ziptrakUtil.updateRules("width", product, itemIndex, order);
        product = ziptrakUtil.updateRules("topWidth", product, itemIndex, order);
        product = ziptrakUtil.updateRules("drop", product, itemIndex, order);
        product = ziptrakUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
        product = ziptrakUtil.updateRules("hoodingColour", product, itemIndex, null);
        product = ziptrakUtil.updateRules("operation", product, itemIndex, null, order);
        product = ziptrakUtil.updateRules("hoodingPowdercoatColour", product, itemIndex, null);
        product = ziptrakUtil.updateRules("framePowdercoatColour", product, itemIndex, null);
        product = ziptrakUtil.updateRules("framePowdercoatColourRight", product, itemIndex, null);
        product = ziptrakUtil.updateRules("bracketColourPowdercoat", product, itemIndex, null);
        product = ziptrakUtil.updateRules("bracketType", product, itemIndex, null);
        product = ziptrakUtil.updateRules("backFlashingCut", product, itemIndex, null);
        product = ziptrakUtil.updateRules("backFlashingCutColour", product, itemIndex, null);
        product = ziptrakUtil.updateRules("motorAccessories", product, itemIndex, order);
        product = ziptrakUtil.updateRules("customerSupplyMotorType", product, itemIndex, order);
        product = ziptrakUtil.updateRules("pullDownStick", product, itemIndex, order);
        product = ziptrakUtil.updateRules("additionalBottomStoppers", product, itemIndex, order);
        product = ziptrakUtil.updateRules("centralLockFaceFixStoppers", product, itemIndex, order);
        product = ziptrakUtil.updateRules("insectControlBrush", product, itemIndex, null);
        product = ziptrakUtil.updateRules("bottomRailColourPowdercoat", product, itemIndex, order);

        product = ziptrakUtil.updateFormError("width", product, itemIndex);
        product = ziptrakUtil.updateFormError("drop", product, itemIndex);
        product = ziptrakUtil.updateFormError("fabricColourOffRange", product, itemIndex);
        product = ziptrakUtil.updateFormError("customerSupplyMotorType", product, itemIndex);
        product = ziptrakUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
        product = ziptrakUtil.updateFormError("framePowdercoatColour", product, itemIndex);
        product = ziptrakUtil.updateFormError("framePowdercoatColourRight", product, itemIndex);
        product = ziptrakUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
        product = ziptrakUtil.updateFormError("bottomRailDuty", product, itemIndex, order);
        product = ziptrakUtil.updateFormError("bottomRailColourPowdercoat", product, itemIndex);
        product = ziptrakUtil.updateFormError("backFlashingCutColour", product, itemIndex);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let optionIndex = -1,
            parentOptionIndex = -1,
            setIndex = -1,
            defaultOptionIndex = -1,
            temp = {},
            condition1 = "",
            sets = [],
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = product.items[itemIndex].configuration.drop.selected.value,
            isPVC = ziptrakUtil.isPVCItem(product, itemIndex, null),
            isMotorised = ziptrakUtil.isMotorised(product, itemIndex, null);

        let {order, currentUser} = this.props;
        if (key) {
            switch (key) {
                case "operation":
                    product = ziptrakUtil.updateRules("width", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("motorSide", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("motorAccessories", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("customerSupplyMotorType", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("pullDownStick", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("additionalBottomStoppers", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("centralLockFaceFixStoppers", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("motorSide", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("centralLock", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("width", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("drop", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("bottomRailDuty", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("customerSupplyMotorType", product, itemIndex, order);
                    break;
                case "set":
                    let variant = product.items[itemIndex].configuration.isSkinOnly.selected.value ? pricingMatrixVariant.SKIN_ONLY_VARIANT : pricingMatrixVariant.DEFAULT_VARIANT;
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                        return x.bmCode === product.items[itemIndex].configuration.fabricType.selected.value.primaryBMCode && x.variant === variant
                    });
                    break;
                case "width":
                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= product.items[itemIndex].configuration.width.selected.value);
                    }
                    break;
                case "drop":
                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= product.items[itemIndex].configuration.drop.selected.value);
                        }
                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                            product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price;
                        }
                        if (product.items[itemIndex].configuration.set.selected.value.widthSet && product.items[itemIndex].configuration.set.selected.value.dropSet) {
                            product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                        }
                    }
                    break;
                case "hooding":
                    // all isSelectable configuration is stored in sets of "No Hood" option, so finding that first
                    optionIndex = findIndex(product.items[itemIndex].configuration.hooding.options, "optionKey", "No Hood");
                    if (optionIndex > -1) {
                        // If "No Hood" found, then find correct set from stored matrix, that will act as default/suitable option
                        setIndex = product.items[itemIndex].configuration.hooding.options[optionIndex].sets.findIndex(s => ziptrakUtil.hoodingOptionSetCondition(s, width, drop, isPVC, ""));
                        defaultOptionIndex = -1;
                        if (setIndex > -1) {

                            defaultOptionIndex = product.items[itemIndex].configuration.hooding.options.findIndex(o => o.optionKey === product.items[itemIndex].configuration.hooding.options[optionIndex].sets[setIndex].description);
                        }
                        defaultOptionIndex = defaultOptionIndex > -1 ? defaultOptionIndex : 0;

                        // now based on default/suitable option, update isSelectable for each option
                        //temp = ["No Hood", "Flair Cassette", "165mm Pelmet", "175mm Pelmet","135mm Ziptrak Pelmet"];
                        temp = ["No Hood"];
                        switch (product.items[itemIndex].configuration.hooding.options[defaultOptionIndex].optionKey) {
                            case "No Hood":
                                temp = ["No Hood", "125mm Flair Pelmet", "148mm Flair Pelmet", "165mm Pelmet", "175mm Pelmet", "135mm Ziptrak Pelmet"];
                                break;
                            case "125mm Flair Pelmet":
                                temp = ["No Hood", "125mm Flair Pelmet", "148mm Flair Pelmet", "135mm Ziptrak Pelmet", "165mm Pelmet", "175mm Pelmet"];

                                setIndex = product.items[itemIndex].configuration.hooding.options[optionIndex].sets.findIndex(s => ziptrakUtil.hoodingOptionSetCondition(s, width, drop, isPVC, "125mm Flair Pelmet"));
                                if (setIndex > -1) {
                                    switch (product.items[itemIndex].configuration.hooding.options[optionIndex].sets[setIndex].description) {
                                        case "135mm Ziptrak Pelmet":
                                            temp = ["No Hood", "125mm Flair Pelmet", "148mm Flair Pelmet", "135mm Ziptrak Pelmet", "165mm Pelmet", "175mm Pelmet"];
                                            break;
                                        case "148mm Ziptrak Pelmet":
                                            temp = ["No Hood", "148mm Flair Pelmet", "135mm Ziptrak Pelmet", "165mm Pelmet", "175mm Pelmet"];
                                            break;
                                        case "165mm Pelmet":
                                            temp = ["No Hood", "148mm Flair Pelmet", "165mm Pelmet", "175mm Pelmet"];
                                            break;
                                        case "175mm Pelmet":
                                            temp = ["No Hood", "148mm Flair Pelmet", "175mm Pelmet"];
                                            break;
                                    }
                                }
                                break;
                            case "148mm Flair Pelmet":
                                temp = ["No Hood", "125mm Flair Pelmet", "148mm Flair Pelmet", "135mm Ziptrak Pelmet", "165mm Pelmet", "175mm Pelmet"];

                                setIndex = product.items[itemIndex].configuration.hooding.options[optionIndex].sets.findIndex(s => ziptrakUtil.hoodingOptionSetCondition(s, width, drop, isPVC, "148mm Flair Pelmet"));
                                if (setIndex > -1) {
                                    switch (product.items[itemIndex].configuration.hooding.options[optionIndex].sets[setIndex].description) {
                                        case "125mm Flair Pelmet":
                                            temp = ["No Hood", "125mm Flair Pelmet", "148mm Flair Pelmet", "135mm Ziptrak Pelmet", "165mm Pelmet", "175mm Pelmet"];
                                            break;
                                        case "135mm Ziptrak Pelmet":
                                            temp = ["No Hood", "148mm Flair Pelmet", "135mm Ziptrak Pelmet", "165mm Pelmet", "175mm Pelmet"];
                                            break;
                                        case "165mm Pelmet":
                                            temp = ["No Hood", "148mm Flair Pelmet", "165mm Pelmet", "175mm Pelmet"];
                                            break;
                                        case "175mm Pelmet":
                                            temp = ["No Hood", "148mm Flair Pelmet", "175mm Pelmet"];
                                            break;
                                    }
                                }
                                break;
                            case "175mm Pelmet":
                                temp = ["No Hood", "125mm Flair Pelmet", "148mm Flair Pelmet", "165mm Pelmet", "175mm Pelmet", "135mm Ziptrak Pelmet"];
                                break;
                            case "135mm Ziptrak Pelmet":
                                temp = ["No Hood", "165mm Pelmet", "175mm Pelmet", "135mm Ziptrak Pelmet", "148mm Flair Pelmet"];
                                break;
                            case "165mm Pelmet":
                                temp = ["No Hood", "165mm Pelmet", "175mm Pelmet"];
                                break;
                        }
                        product.items[itemIndex].configuration.hooding.options.forEach(o => {
                            o.isSelectable = temp.includes(o.optionKey);
                        });

                        if (!temp.includes(product.items[itemIndex].configuration.hooding.selected.value.optionKey)) {
                            // previously selected item is now not selectable so update with default
                            product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.options[defaultOptionIndex];
                            product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                            product = ziptrakUtil.updateRules("hoodingColour", product, itemIndex, null);
                            product = ziptrakUtil.updateRules("bracketType", product, itemIndex, null);
                            product = ziptrakUtil.updateRules("backFlashingCut", product, itemIndex, null);
                            product = ziptrakUtil.updateRules("backFlashingCutColour", product, itemIndex, null);
                            product = ziptrakUtil.updateRules("width", product, itemIndex, null);
                            product = ziptrakUtil.updateRules("drop", product, itemIndex, null);
                            product = ziptrakUtil.updateFormError("width", product, itemIndex);
                            product = ziptrakUtil.updateFormError("drop", product, itemIndex);
                            product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, null);
                        }
                    }
                    break;
                case "hoodingColour":
                    let parentOptionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.options, "optionKey", product.items[itemIndex].configuration.hooding.selected.value.optionKey);
                    if (parentOptionIndex > -1) {
                        product.items[itemIndex].configuration.hoodingColour.finalOptions = (product.items[itemIndex].configuration.hoodingColour.options || []).filter(x => product.items[itemIndex].configuration.hoodingColour.options[parentOptionIndex].id === x.parentOptionId);
                        product.items[itemIndex].configuration.hoodingColour.finalOptions = getSorted(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'primaryAttributeMin', true);
                        if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, "optionKey", product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                        if (product.items[itemIndex].configuration.hoodingPowdercoatColour)
                            product = ziptrakUtil.updateRules("hoodingPowdercoatColour", product, itemIndex, null);
                        product = ziptrakUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
                    }
                    break;
                case "motorSide":
                    if (product.items[itemIndex].configuration.motorSide.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.motorSide.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.motorSide.finalOptions, "optionKey", product.items[itemIndex].configuration.motorSide.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.motorSide.selected.value = product.items[itemIndex].configuration.motorSide.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.motorSide.selected.dropdownValue = product.items[itemIndex].configuration.motorSide.selected.value.optionKey;
                    }
                    break;
                case "keyway":
                    sets = product.items[itemIndex].configuration.keyway.selected.value.sets.filter(set => ziptrakUtil.keywaySetCondition(set, width, drop, isPVC, isMotorised, false));
                    optionIndex = -1;
                    if (!isEmpty(context)) {
                        optionIndex = findIndex(sets, 'description', context.salesOrderItemZiptrak.keyway);
                    }
                    if (!(optionIndex > -1)) {
                        optionIndex = sets.findIndex(set => ziptrakUtil.keywaySetCondition(set, width, drop, isPVC, isMotorised, true));
                    }
                    product.items[itemIndex].configuration.keyway.selected.value.selectedSet = sets[optionIndex];

                    if (isEmpty(product.items[itemIndex].configuration.keyway.selected.value.selectedSet)) {
                        product.items[itemIndex].configuration.keyway.selected.value.selectedSet = sets[sets.length - 1];
                    }
                    product.items[itemIndex].configuration.keyway.selected.value.finalSets = getDistinct(sets, 'description');
                    product.items[itemIndex].configuration.keyway.selected.value.finalSets.forEach(s => {
                        s.isSelectable = s.setNum >= product.items[itemIndex].configuration.keyway.selected.value.selectedSet.setNum;
                    });
                    break;
                case "fabricColour":
                    //react-select dropdown component options adding label and value ------------------------
                    product.items[itemIndex].configuration.fabricType.selected.value.colors = getSorted(product.items[itemIndex].configuration.fabricType.selected.value.colors, "color", true);
                    product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map((o, oIndex) => {
                        return {
                            key: oIndex,
                            optionKey: o.color,
                            optionLabel: salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                            value: o.color,
                            label: (<span>{o.color}</span>),
                            ...o
                        }
                    });
                    product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
                        return (
                            salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                        )
                    });
                    if (product.items[itemIndex].configuration.fabricColour.finalOptions.length > 0) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricColour.selected.value = {};
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                    }
                    break;
                case "centralLock":
                    condition1 = "";
                    if (isMotorised) {
                        condition1 = "motorised";
                    } else {
                        condition1 = "Manual/Spring";
                    }
                    if (!isMotorised) {
                        let isXHD = product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey === 'XHD';
                        product.items[itemIndex].configuration.centralLock.finalOptions = product.items[itemIndex].configuration.centralLock.options.filter(o => o.description === condition1);
                        if (isXHD) {
                            product.items[itemIndex].configuration.centralLock.finalOptions = product.items[itemIndex].configuration.centralLock.finalOptions.filter(o => o.condition1 != 'XHD');
                        }
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.centralLock.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.centralLock.finalOptions, "optionKey", product.items[itemIndex].configuration.centralLock.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.centralLock.selected.value = product.items[itemIndex].configuration.centralLock.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.centralLock.selected.dropdownValue = product.items[itemIndex].configuration.centralLock.selected.value.optionKey;
                    }
                    else {
                        product.items[itemIndex].configuration.centralLock.selected.value = {};
                        product.items[itemIndex].configuration.centralLock.selected.dropdownValue = "";
                    }
                    product = ziptrakUtil.updateRules("centralLock", product, itemIndex, order);
                    break;
                case "bottomRailDuty":
                    // default selection
                    defaultOptionIndex = product.items[itemIndex].configuration.bottomRailDuty.finalOptions.findIndex(o => {
                        return o.sets.some(set => {
                            return ziptrakUtil.bottomRailDutySetCondition(set, width, drop, isPVC, isMotorised)
                        })
                    });
                    if (defaultOptionIndex > -1) {
                        //update next options based on the default selection
                        switch (product.items[itemIndex].configuration.bottomRailDuty.finalOptions[defaultOptionIndex].optionKey) {
                            case "STD":
                                product.items[itemIndex].configuration.bottomRailDuty.finalOptions.forEach((o, oIndex) => {
                                    switch (o.optionKey) {
                                        case "STD":
                                        case "HD":
                                            o.isSelectable = true;
                                            break;
                                        case "XHD":
                                            //If Fabric is Mesh EHD cannot be selected for width less than 1500
                                            o.isSelectable = isPVC ? false : width > 1500; //If Fabric = PVC A/B, EHD cannot be selected
                                            break;
                                    }
                                });
                                break;
                            case "XHD":
                                product.items[itemIndex].configuration.bottomRailDuty.finalOptions.forEach((o, oIndex) => {
                                    switch (o.optionKey) {
                                        case "STD":
                                        case "HD":
                                            o.isSelectable = false;
                                            break;
                                        case "XHD":
                                            //If Fabric is Mesh EHD cannot be selected for width less than 1500
                                            o.isSelectable = isPVC ? false : width > 1500; //If Fabric = PVC A/B, EHD cannot be selected
                                            break;
                                    }
                                });
                                break;
                            case "HD":
                                product.items[itemIndex].configuration.bottomRailDuty.finalOptions.forEach((o, oIndex) => {
                                    o.isSelectable = true;
                                    switch (o.optionKey) {
                                        case "STD":
                                            o.isSelectable = false;
                                            break;
                                        case "HD":
                                            o.isSelectable = true;
                                            break;
                                        case "XHD":
                                            //If Fabric is Mesh EHD cannot be selected for width less than 1500
                                            o.isSelectable = isPVC ? false : width > 1500; //If Fabric = PVC A/B, EHD cannot be selected
                                            break;
                                    }
                                });
                                break;
                        }
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.bottomRailDuty.selected.value)) {
                        optionIndex = product.items[itemIndex].configuration.bottomRailDuty.finalOptions.findIndex(o => o.isSelectable && o.optionKey === product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey);
                    }
                    optionIndex = optionIndex > -1 ? optionIndex : product.items[itemIndex].configuration.bottomRailDuty.finalOptions.findIndex(o => o.isSelectable);
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.bottomRailDuty.selected.value = product.items[itemIndex].configuration.bottomRailDuty.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRailDuty.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey;
                    break;
                case "basePrice":
                    //for updating bmCode
                    product = this.updateItemConfigurationOptions("set", product, itemIndex, null);
                    //for updating width set
                    product = this.updateItemConfigurationOptions("width", product, itemIndex, null);
                    // for updating drop set and update price
                    product = this.updateItemConfigurationOptions("drop", product, itemIndex, null);
                    break;
                case "backFlashingCutColour":
                        product.items[itemIndex].configuration.backFlashingCutColour.finalOptions = product.items[itemIndex].configuration.backFlashingCutColour.options;
                        if (!isEmpty(product.items[itemIndex].configuration.backFlashingCutColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.backFlashingCutColour.finalOptions, "optionKey", product.items[itemIndex].configuration.backFlashingCutColour.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.backFlashingCutColour.selected.value = product.items[itemIndex].configuration.backFlashingCutColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.backFlashingCutColour.selected.dropdownValue = product.items[itemIndex].configuration.backFlashingCutColour.selected.value.optionKey;
                    break;
                case "bracketColour":
                    product.items[itemIndex].configuration.bracketColour.finalOptions = (product.items[itemIndex].configuration.bracketColour.options || []).filter(o => o.condition1.includes(product.items[itemIndex].configuration.bracketType.selected.dropdownValue));
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1, endIndex = null;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = ziptrakUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = ziptrakUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("width", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("drop", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("hooding", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("bottomRailDuty", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("centralLock", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, null);
                product = ziptrakUtil.updateRules("insectControlBrush", product, itemIndex, null);
                product = ziptrakUtil.updateRules("drop", product, itemIndex, null);
                product = ziptrakUtil.updateFormError("width", product, itemIndex);
                product = ziptrakUtil.updateFormError("drop", product, itemIndex);
                product = ziptrakUtil.updateFormError("bottomRailDuty", product, itemIndex, order);
                break;
            case "topWidth":
                product.items[itemIndex].configuration.topWidth.selected.value = parseInt(change);
                product = ziptrakUtil.updateRules("topWidth", product, itemIndex, null);
                this.handleChange(change, "width", itemIndex, true);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("drop", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("hooding", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("bottomRailDuty", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("centralLock", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, null);
                product = ziptrakUtil.updateRules("insectControlBrush", product, itemIndex, null);
                product = ziptrakUtil.updateRules("width", product, itemIndex, null);
                product = ziptrakUtil.updateFormError("drop", product, itemIndex);
                product = ziptrakUtil.updateFormError("width", product, itemIndex);
                product = ziptrakUtil.updateFormError("bottomRailDuty", product, itemIndex, order);
                break;
            case "splineColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.splineColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.splineColour.selected.value = product.items[itemIndex].configuration.splineColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.splineColour.selected.dropdownValue = product.items[itemIndex].configuration.splineColour.selected.value.optionKey;
                }
                break;
            case "hooding":
                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                    product = ziptrakUtil.updateRules("hoodingColour", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("bracketType", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("backFlashingCut", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("backFlashingCutColour", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("insectControlBrush", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("width", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("drop", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("width", product, itemIndex);
                    product = ziptrakUtil.updateFormError("drop", product, itemIndex);
                    product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, null);
                }
                break;
            case "hoodingColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    product = ziptrakUtil.updateRules("hoodingPowdercoatColour", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
                }
                break;
            case "hoodingPowdercoatColour":
                product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = change;
                product = ziptrakUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
                break;
            case "frameColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;

                    product = ziptrakUtil.updateRules("framePowdercoatColour", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("framePowdercoatColour", product, itemIndex);
                }

                optionIndex = findIndex(product.items[itemIndex].configuration.frameColourRight.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.frameColourRight.selected.value = product.items[itemIndex].configuration.frameColourRight.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColourRight.selected.dropdownValue = product.items[itemIndex].configuration.frameColourRight.selected.value.optionKey;

                    product = ziptrakUtil.updateRules("framePowdercoatColourRight", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("framePowdercoatColourRight", product, itemIndex);
                }
                break;
            case "frameColourRight":
                optionIndex = findIndex(product.items[itemIndex].configuration.frameColourRight.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.frameColourRight.selected.value = product.items[itemIndex].configuration.frameColourRight.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColourRight.selected.dropdownValue = product.items[itemIndex].configuration.frameColourRight.selected.value.optionKey;

                    product = ziptrakUtil.updateRules("framePowdercoatColourRight", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("framePowdercoatColourRight", product, itemIndex);
                }
                break;
            case "framePowdercoatColour":
                product.items[itemIndex].configuration.framePowdercoatColour.selected.value = change;
                product = ziptrakUtil.updateFormError("framePowdercoatColour", product, itemIndex);

                product.items[itemIndex].configuration.framePowdercoatColourRight.selected.value = change;
                product = ziptrakUtil.updateFormError("framePowdercoatColourRight", product, itemIndex);
                break;
            case "framePowdercoatColourRight":
                product.items[itemIndex].configuration.framePowdercoatColourRight.selected.value = change;
                product = ziptrakUtil.updateFormError("framePowdercoatColourRight", product, itemIndex);
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    product = ziptrakUtil.updateRules("width", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("drop", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("motorSide", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("motorAccessories", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("customerSupplyMotorType", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("pullDownStick", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("additionalBottomStoppers", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("centralLockFaceFixStoppers", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("centralLock", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("motorSide", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("centralLock", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("width", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("drop", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("bottomRailDuty", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("customerSupplyMotorType", product, itemIndex, order);
                }
                break;
            case "motorSide":
                optionIndex = findIndex(product.items[itemIndex].configuration.motorSide.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.motorSide.selected.value = product.items[itemIndex].configuration.motorSide.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.motorSide.selected.dropdownValue = product.items[itemIndex].configuration.motorSide.selected.value.optionKey;
                }
                break;
            case "customerSupplyMotorType":
                product.items[itemIndex].configuration.customerSupplyMotorType.selected.value = change;
                product = ziptrakUtil.updateFormError("customerSupplyMotorType", product, itemIndex);
                break;
            case "keyway":
                optionIndex = findIndex(product.items[itemIndex].configuration.keyway.selected.value.finalSets, "description", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.keyway.selected.value.selectedSet = product.items[itemIndex].configuration.keyway.selected.value.finalSets[optionIndex];
                }
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("hooding", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("splineColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("bottomRailDuty", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("centralLock", product, itemIndex, null);

                    product = ziptrakUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("insectControlBrush", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("drop", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                    product = ziptrakUtil.updateFormError("drop", product, itemIndex);
                }
                break;
            case "fabricColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.value = change;
                product = ziptrakUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                break;
            case "bracketType":
                optionIndex = findIndex(product.items[itemIndex].configuration.bracketType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bracketType.selected.value = product.items[itemIndex].configuration.bracketType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bracketType.selected.dropdownValue = product.items[itemIndex].configuration.bracketType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, null);
                    product = ziptrakUtil.updateRules("backFlashingCut", product, itemIndex, null);
                }
                break;
            case "bracketColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.bracketColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bracketColour.selected.value = product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;

                    product = ziptrakUtil.updateRules("bracketColourPowdercoat", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
                }
                if (change == 'Powdercoat') {
                    this.handleChange('Surf Mist', "backFlashingCutColour", itemIndex, true);
                }
                else {
                    this.handleChange(change, "backFlashingCutColour", itemIndex, true);
                }
                break;
            case "bracketColourPowdercoat":
                product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = change;
                product = ziptrakUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
                break;
            case "channels":
                optionIndex = findIndex(product.items[itemIndex].configuration.channels.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channels.selected.value = product.items[itemIndex].configuration.channels.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channels.selected.dropdownValue = product.items[itemIndex].configuration.channels.selected.value.optionKey;
                }
                optionIndex = findIndex(product.items[itemIndex].configuration.channelsRight.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channelsRight.selected.value = product.items[itemIndex].configuration.channelsRight.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channelsRight.selected.dropdownValue = product.items[itemIndex].configuration.channelsRight.selected.value.optionKey;
                }
                product = ziptrakUtil.updateRules("centralLockFaceFixStoppers", product, itemIndex, order);
                break;
            case "channelsRight":
                optionIndex = findIndex(product.items[itemIndex].configuration.channelsRight.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channelsRight.selected.value = product.items[itemIndex].configuration.channelsRight.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channelsRight.selected.dropdownValue = product.items[itemIndex].configuration.channelsRight.selected.value.optionKey;
                }
                product = ziptrakUtil.updateRules("centralLockFaceFixStoppers", product, itemIndex, order);
                break;
            case "centralLock":
                optionIndex = findIndex(product.items[itemIndex].configuration.centralLock.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.centralLock.selected.value = product.items[itemIndex].configuration.centralLock.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.centralLock.selected.dropdownValue = product.items[itemIndex].configuration.centralLock.selected.value.optionKey;
                }
                break;
            case "bottomRailFinish":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailFinish.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRailFinish.selected.value = product.items[itemIndex].configuration.bottomRailFinish.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRailFinish.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailFinish.selected.value.optionKey;
                }
                break;
            case "bottomRailDuty":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailDuty.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRailDuty.selected.value = product.items[itemIndex].configuration.bottomRailDuty.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRailDuty.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("centralLock", product, itemIndex, null);
                }
                break;
            case "bottomRailColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                    product = ziptrakUtil.updateRules("bottomRailColourPowdercoat", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("bottomRailColourPowdercoat", product, itemIndex);
                }
                break;
            case "bottomRailColourPowdercoat":
                product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = change;
                product = ziptrakUtil.updateFormError("bottomRailColourPowdercoat", product, itemIndex);
                break;
            case "windoware15ChannelRemote":
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = change;
                break;
            case "insectControlBrush":
                product.items[itemIndex].configuration.insectControlBrush.selected.value = change;
                break;
            case "windowareBridge":
                product.items[itemIndex].configuration.windowareBridge.selected.value = change;
                break;
            case "chargingCable":
                product.items[itemIndex].configuration.chargingCable.selected.value = change;
                break;
            case "somfy1ChannelSituoRemote":
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = change;
                break;
            case "pullDownStick":
                product.items[itemIndex].configuration.pullDownStick.selected.value = change;
                break;
            case "additionalBottomStoppers":
                product.items[itemIndex].configuration.additionalBottomStoppers.selected.value = change;
                break;
            case "centralLockFaceFixStoppers":
                product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.value = change;
                break;
            case "highImpactStoppers":
                product.items[itemIndex].configuration.highImpactStoppers.selected.value = change;
                break;
            case "removablePostKit":
                product.items[itemIndex].configuration.removablePostKit.selected.value = change;
                break;
            case "isSkinOnly":
                product.items[itemIndex].configuration.isSkinOnly.selected.value = change;
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, null);
                break;
            case "backFlashingCut":
                product.items[itemIndex].configuration.backFlashingCut.selected.value = change;
                product = this.updateItemConfigurationOptions("backFlashingCutColour", product, itemIndex, null);
                product = ziptrakUtil.updateRules("backFlashingCutColour", product, itemIndex, null);
                product = ziptrakUtil.updateFormError("backFlashingCutColour", product, itemIndex);
                if (change) {
                    this.handleChange(product.items[itemIndex].configuration.bracketColour.selected.dropdownValue, "backFlashingCutColour", itemIndex, true);
                }
                break;
            case "backFlashingCutColour":
                if (product.items[itemIndex].configuration.backFlashingCut.selected && product.items[itemIndex].configuration.backFlashingCut.selected.value) {
                    optionIndex = findIndex(product.items[itemIndex].configuration.backFlashingCutColour.finalOptions, "optionKey", change);
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.backFlashingCutColour.selected.value = product.items[itemIndex].configuration.backFlashingCutColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.backFlashingCutColour.selected.dropdownValue = product.items[itemIndex].configuration.backFlashingCutColour.selected.value.optionKey;
                    }
                    product = ziptrakUtil.updateFormError("backFlashingCutColour", product, itemIndex);
                }
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }


    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;
        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th rowSpan={2} style={{minWidth: 45}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div>#</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 135}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Location</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 100}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Quantity</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 275}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Operation</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 275}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Width(mm)</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 100}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Drop(mm)</div>
                                        </div>
                                    </th>
                                    {/* <th rowSpan=2 style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Keyway</div>
                                        </div>
                                    </th>*/}
                                    <th rowSpan={2} style={{minWidth: 230}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Fabric</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 190}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Spline Colour</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{ minWidth: 315 }}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Hooding</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 215}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Bracket Type</div>
                                        </div>
                                    </th>
                                    <th colSpan={ziptrakUtil.showSplittedChannelOptions(order) ? 2 : 1}
                                        rowSpan={ziptrakUtil.showSplittedChannelOptions(order) ? 1 : 2}
                                        style={{minWidth: ziptrakUtil.showSplittedChannelOptions(order) ? 350 : 175}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Channels</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 220}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Central Locking</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 225}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Bottom Rail</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 235}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Extras</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th rowSpan={2} style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th rowSpan={2} style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null
                                    }
                                </tr>
                                <tr>
                                    {
                                        ziptrakUtil.showSplittedChannelOptions(order)
                                            ? <th className={"text-center"}>
                                                Left
                                            </th>
                                            : null
                                    }

                                    {
                                        ziptrakUtil.showSplittedChannelOptions(order)
                                            ? <th className={"text-center"}>Right</th>
                                            : null
                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="text" name="location"
                                                                   value={item.configuration.location.selected.value}
                                                                   onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                                   placeholder="location"/>
                                                            {
                                                                item.configuration.location.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                            }
                                                            {
                                                                item.configuration.location.formError.isWarning
                                                                    ? <Badge color="warning">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="number" name="quantity"
                                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                                   value={item.configuration.quantity.selected.value}
                                                                   min={item.configuration.quantity.min}
                                                                   max={item.configuration.quantity.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="quantity"/>

                                                            {
                                                                item.configuration.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="operation"
                                                           value={item.configuration.operation.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "operation", itemIndex, true)}>
                                                        <option value={""} disabled>Select operation</option>

                                                        {
                                                            (item.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {ziptrakUtil.isMotorised(product, itemIndex)
                                                        ?
                                                        <div>
                                                            {
                                                                item.configuration.customerSupplyMotorType.selected.isSelectable
                                                                    ? <div>
                                                                        <Input className={"mt-2"}
                                                                               title={"Motor Type"}
                                                                               type="text" name="customerSupplyMotorType"
                                                                               value={item.configuration.customerSupplyMotorType.selected.value}
                                                                               onChange={(e) => this.handleChange(e.target.value, "customerSupplyMotorType", itemIndex, true)}
                                                                               placeholder="Motor Type"

                                                                        />
                                                                        {
                                                                            item.configuration.customerSupplyMotorType.formError.isValid
                                                                                ? null
                                                                                : <Badge color="danger">
                                                                                    {item.configuration.customerSupplyMotorType.formError.message}
                                                                                </Badge>
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }

                                                            <Input className={"mt-2"} type="select" name="motorSide"
                                                                   value={item.configuration.motorSide.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "motorSide", itemIndex, true)}>
                                                                <option value={""} disabled>Select motor side</option>
                                                                {
                                                                    (item.configuration.motorSide.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>

                                                            {
                                                                ziptrakUtil.isVisibleMotorAccessories("Windoware", product, itemIndex)
                                                                    ? <div className="mt-1">
                                                                        <strong>Windoware Accessories</strong>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.windoware15ChannelRemote.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                                                onClick={() => this.handleChange(!item.configuration.windoware15ChannelRemote.selected.value, "windoware15ChannelRemote", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.windoware15ChannelRemote.selected.value,
                                                                                        "fa-square-o": !item.configuration.windoware15ChannelRemote.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.windoware15ChannelRemote.attributeLabel}
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.windowareBridge.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                                                onClick={() => this.handleChange(!item.configuration.windowareBridge.selected.value, "windowareBridge", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.windowareBridge.selected.value,
                                                                                        "fa-square-o": !item.configuration.windowareBridge.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.windowareBridge.attributeLabel}
                                                                        </div>
                                                                        {
                                                                            item.configuration.chargingCable.selected.isSelectable
                                                                                ? <div>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        className={"mr-2 mb-1"}
                                                                                        color={"link"}
                                                                                        disabled={!item.configuration.chargingCable.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                                                        onClick={() => this.handleChange(!item.configuration.chargingCable.selected.value, "chargingCable", itemIndex, true)}>
                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                "fa-check-square-o": item.configuration.chargingCable.selected.value,
                                                                                                "fa-square-o": !item.configuration.chargingCable.selected.value,
                                                                                            }
                                                                                        )}/>
                                                                                    </Button>
                                                                                    {item.configuration.chargingCable.attributeLabel}
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                ziptrakUtil.isVisibleMotorAccessories("Somfy", product, itemIndex)
                                                                    ? <div className="mt-1">
                                                                        <strong>Somfy Accessories</strong>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.somfy1ChannelSituoRemote.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                                                onClick={() => this.handleChange(!item.configuration.somfy1ChannelSituoRemote.selected.value, "somfy1ChannelSituoRemote", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.somfy1ChannelSituoRemote.selected.value,
                                                                                        "fa-square-o": !item.configuration.somfy1ChannelSituoRemote.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.somfy1ChannelSituoRemote.attributeLabel}
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }


                                                        </div> : null
                                                    }


                                                </td>
                                                <td className="align-middle">
                                                    <InputGroup className={"mb-2"}>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                {item.configuration.topWidth.attributeLabel}
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="number" name="topWidth"
                                                            invalid={!item.configuration.topWidth.formError.isValid}
                                                            value={item.configuration.topWidth.selected.value}
                                                            min={item.configuration.width.min}
                                                            max={item.configuration.width.max}
                                                            onChange={(e) => this.handleChange(e.target.value, "topWidth", itemIndex, true)}
                                                            onFocus={(event) => event.target.select()}
                                                            placeholder="Hooding Width" />
                                                    </InputGroup>
                                                    {/*<div*/}
                                                    {/*    className={"d-flex justify-content-between align-items-center"}>*/}
                                                        
                                                    {/*</div>*/}
                                                    <InputGroup className={"mb-2 mt-2"}>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                Minimum Width
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="number" name="width"
                                                            invalid={!item.configuration.width.formError.isValid}
                                                            value={item.configuration.width.selected.value}
                                                            min={item.configuration.width.min}
                                                            max={item.configuration.width.max}
                                                            onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                            onFocus={(event) => event.target.select()}
                                                            placeholder="width" />
                                                    </InputGroup>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <div><Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                                <p>
                                                                    <i className="fa fa-exclamation-triangle mr-2"
                                                                       style={{"color": "#ffcc00"}} aria-hidden="true"/>
                                                                    <a target="_blank"
                                                                       href="https://winonline.blob.core.windows.net/winonline/static/img/oos/Ziptrak-External/Ziptrak-External-Bottom-Bar-Table.JPG">Click
                                                                        here to see standard size chart</a>
                                                                </p>
                                                            </div>
                                                    }
                                                    {
                                                        item.configuration.topWidth.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.topWidth.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="number" name="drop"
                                                               invalid={!item.configuration.drop.formError.isValid}
                                                               value={item.configuration.drop.selected.value}
                                                               min={item.configuration.drop.min}
                                                               max={item.configuration.drop.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="drop"/>
                                                    </div>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="select" name="fabricType"
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select type</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {
                                                        item.configuration.fabricColour.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="fabricColour"
                                                                       value={item.configuration.fabricColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select color</option>
                                                                    {
                                                                        (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                {/*<Select
                                                                    value={item.configuration.fabricColour.selected.value}
                                                                    onChange={(selectedOption) => this.handleChange(selectedOption.optionKey, "fabricColour", itemIndex, true)}
                                                                    options={[{
                                                                        key: "SelectColor",
                                                                        isDisabled: true,
                                                                        optionKey: "SelectColor",
                                                                        optionLabel: "Select color",
                                                                        value: "",
                                                                        label: (<div key={"SelectColor1"}>
                                                                            <span>Select color</span>
                                                                        </div>)
                                                                    }, ...item.configuration.fabricColour.finalOptions]}
                                                                />*/}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.fabricColourOffRange.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="fabricColourOffRange"
                                                                       value={item.configuration.fabricColourOffRange.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColourOffRange", itemIndex, true)}
                                                                       placeholder="Fabric name"
                                                                       title={"Enter fabric name"}
                                                                />
                                                                {
                                                                    item.configuration.fabricColourOffRange.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.fabricColourOffRange.formError.message}
                                                                        </Badge>
                                                                }
                                                                <div>
                                                                    <Badge color="info">
                                                                        <i className="fa fa-info-circle mr-2"
                                                                           aria-hidden="true"/>
                                                                        Price On Application
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="select" name="splineColour"
                                                               value={item.configuration.splineColour.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "splineColour", itemIndex, true)}>
                                                            <option value={""} disabled>Select</option>

                                                            {
                                                                (item.configuration.splineColour.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={"item" + itemIndex + "-option" + oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="hooding"
                                                        value={item.configuration.hooding.selected.dropdownValue}
                                                        onChange={(e) => this.handleChange(e.target.value, "hooding", itemIndex, true)}>
                                                        <option value={""} disabled>Select hooding type</option>
                                                        {
                                                            (item.configuration.hooding.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    disabled={!o.isSelectable}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <Input type="select"
                                                        className={"mt-2"}
                                                        name="hoodingColour"
                                                        disabled={!item.configuration.hoodingColour.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                        value={item.configuration.hoodingColour.selected.dropdownValue}
                                                        onChange={(e) => this.handleChange(e.target.value, "hoodingColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select hooding colour</option>

                                                        {
                                                            (item.configuration.hoodingColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    
                                                    {
                                                        item.configuration.hoodingPowdercoatColour.selected.isSelectable
                                                            ? <div>
                                                                <Input type="text" name="hoodingPowdercoatColour"
                                                                    value={item.configuration.hoodingPowdercoatColour.selected.value}
                                                                    onChange={(e) => this.handleChange(e.target.value, "hoodingPowdercoatColour", itemIndex, true)}
                                                                    placeholder="Powdercoat colour"
                                                                    className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.hoodingPowdercoatColour.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.hoodingPowdercoatColour.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        item.configuration.insectControlBrush.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    disabled={!item.configuration.insectControlBrush.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                                    onClick={() => this.handleChange(!item.configuration.insectControlBrush.selected.value, "insectControlBrush", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": item.configuration.insectControlBrush.selected.value,
                                                                        "fa-square-o": !item.configuration.insectControlBrush.selected.value,
                                                                    }
                                                                    )} />
                                                                </Button>
                                                                {item.configuration.insectControlBrush.attributeLabel}
                                                            </div>
                                                            : null
                                                    }
                                                    
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="bracketType"
                                                           disabled={!item.configuration.bracketType.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                           value={item.configuration.bracketType.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bracketType", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.bracketType.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <Input type="select" name="bracketColour"
                                                           className={"mt-2"}
                                                           disabled={!item.configuration.bracketType.selected.isSelectable}
                                                           value={item.configuration.bracketColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bracketColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.bracketColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.bracketColourPowdercoat.selected.isSelectable
                                                            ? <div>
                                                                <Input type="text" name="bracketColourPowdercoat"
                                                                       value={item.configuration.bracketColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "bracketColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.bracketColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.bracketColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.backFlashingCut.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    disabled={!item.configuration.backFlashingCut.selected.isSelectable}
                                                                    onClick={() => this.handleChange(!item.configuration.backFlashingCut.selected.value, "backFlashingCut", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": item.configuration.backFlashingCut.selected.value,
                                                                        "fa-square-o": !item.configuration.backFlashingCut.selected.value,
                                                                    }
                                                                    )} />
                                                                </Button>
                                                                {item.configuration.backFlashingCut.attributeLabel}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.backFlashingCut.selected.value
                                                            ? <Input type="select"
                                                                className={"mt-2"}
                                                                name="backFlashingCutColour"
                                                                disabled={!item.configuration.backFlashingCut.selected.value}
                                                                value={item.configuration.backFlashingCutColour.selected.dropdownValue}
                                                                onChange={(e) => this.handleChange(e.target.value, "backFlashingCutColour", itemIndex, true)}>
                                                                <option value={""} disabled>Select Back Flashing Colour</option>

                                                                {
                                                                    (item.configuration.backFlashingCutColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.backFlashingCutColour.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.backFlashingCutColour.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="channels"
                                                           value={item.configuration.channels.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "channels", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.channels.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <Input type="select" name="frameColour"
                                                           className={"mt-2"}
                                                           value={item.configuration.frameColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "frameColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select Colour</option>

                                                        {
                                                            (item.configuration.frameColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.framePowdercoatColour.selected.isSelectable
                                                            ? <div>
                                                                <Input type="text" name="framePowdercoatColour"
                                                                       value={item.configuration.framePowdercoatColour.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "framePowdercoatColour", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.framePowdercoatColour.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.framePowdercoatColour.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                {
                                                    ziptrakUtil.showSplittedChannelOptions(order)
                                                        ? <td className="align-middle">
                                                            <Input type="select" name="channelsRight"
                                                                   value={item.configuration.channelsRight.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "channelsRight", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.channelsRight.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            <Input type="select" name="frameColourRight"
                                                                   className={"mt-2"}
                                                                   value={item.configuration.frameColourRight.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "frameColourRight", itemIndex, true)}>
                                                                <option value={""} disabled>Select Colour
                                                                </option>
                                                                {
                                                                    (item.configuration.frameColourRight.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            {
                                                                item.configuration.framePowdercoatColourRight.selected.isSelectable
                                                                    ? <div>
                                                                        <Input type="text" name="framePowdercoatColourRight"
                                                                               value={item.configuration.framePowdercoatColourRight.selected.value}
                                                                               onChange={(e) => this.handleChange(e.target.value, "framePowdercoatColourRight", itemIndex, true)}
                                                                               placeholder="Powdercoat colour"
                                                                               className={"mt-2"}
                                                                        />
                                                                        {
                                                                            item.configuration.framePowdercoatColourRight.formError.isValid
                                                                                ? null
                                                                                : <Badge color="danger">
                                                                                    {item.configuration.framePowdercoatColourRight.formError.message}
                                                                                </Badge>
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                        </td>
                                                        : null
                                                }
                                                <td className="align-middle">
                                                    <Input type="select" name="centralLock"
                                                           disabled={!item.configuration.centralLock.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                           value={item.configuration.centralLock.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "centralLock", itemIndex, true)}>
                                                        <option value={""} disabled>Select Central Lock</option>
                                                        {
                                                            (item.configuration.centralLock.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="select" name="bottomRailDuty"
                                                               value={item.configuration.bottomRailDuty.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "bottomRailDuty", itemIndex, true)}>
                                                            <option value={""} disabled>Select</option>
                                                            {
                                                                (item.configuration.bottomRailDuty.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={"item" + itemIndex + "-option" + oIndex}
                                                                        disabled={!o.isSelectable}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                        {
                                                            item.configuration.bottomRailDuty.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.bottomRailDuty.formError.message}
                                                                </Badge>
                                                        }
                                                    </div>
                                                    <Input className={"mt-2"}
                                                           type="select" name="bottomRailColour"
                                                           value={item.configuration.bottomRailColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bottomRailColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.bottomRailColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.bottomRailColourPowdercoat.selected.isSelectable
                                                            ? <div>
                                                                <Input type="text" name="bottomRailColourPowdercoat"
                                                                       value={item.configuration.bottomRailColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "bottomRailColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.bottomRailColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.bottomRailColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    <Input className={"mt-2"}
                                                           type="select" name="bottomRailFinish"
                                                           value={item.configuration.bottomRailFinish.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bottomRailFinish", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.bottomRailFinish.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>

                                                <td className="align-middle">
                                                    <div>
                                                        <Button
                                                            size={"sm"}
                                                            className={"mr-2 mb-1"}
                                                            color={"link"}
                                                            disabled={!item.configuration.pullDownStick.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                            onClick={() => this.handleChange(!item.configuration.pullDownStick.selected.value, "pullDownStick", itemIndex, true)}>
                                                            <i className={classnames("fa", "fa-lg", {
                                                                    "fa-check-square-o": item.configuration.pullDownStick.selected.value,
                                                                    "fa-square-o": !item.configuration.pullDownStick.selected.value,
                                                                }
                                                            )}/>
                                                        </Button>
                                                        Pull Down Hook
                                                    </div>

                                                    <div>
                                                        <Button
                                                            size={"sm"}
                                                            className={"mr-2 mb-1"}
                                                            color={"link"}
                                                            disabled={!item.configuration.additionalBottomStoppers.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                            onClick={() => this.handleChange((item.configuration.additionalBottomStoppers.selected.value > 0 ? 0 : 1), "additionalBottomStoppers", itemIndex, true)}>
                                                            <i className={classnames("fa", "fa-lg", {
                                                                    "fa-check-square-o": item.configuration.additionalBottomStoppers.selected.value,
                                                                    "fa-square-o": !item.configuration.additionalBottomStoppers.selected.value,
                                                                }
                                                            )}/>
                                                        </Button>
                                                        Extra Bottom Stoppers
                                                    </div>

                                                    {
                                                        (item.configuration.additionalBottomStoppers.selected.value && item.configuration.additionalBottomStoppers.selected.value > 0)
                                                            ? <div className={"mt-1"}>
                                                                <Input type="select" name="additionalBottomStoppers"
                                                                       value={item.configuration.additionalBottomStoppers.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "additionalBottomStoppers", itemIndex, true)}
                                                                       placeholder="Extra Bottom Stoppers">
                                                                    <option value={0}>Select</option>
                                                                    {
                                                                        quantityOptions.map((o, oIndex) => {
                                                                            return <option key={oIndex} value={o}>{o}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    <div>
                                                        <Button
                                                            size={"sm"}
                                                            className={"mr-2 mb-1"}
                                                            color={"link"}
                                                            disabled={!item.configuration.highImpactStoppers.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                            onClick={() => this.handleChange(!item.configuration.highImpactStoppers.selected.value, "highImpactStoppers", itemIndex, true)}>
                                                            <i className={classnames("fa", "fa-lg", {
                                                                    "fa-check-square-o": item.configuration.highImpactStoppers.selected.value,
                                                                    "fa-square-o": !item.configuration.highImpactStoppers.selected.value,
                                                                }
                                                            )}/>
                                                        </Button>
                                                        High Impact Stoppers
                                                    </div>
                                                    <div>
                                                        <Button
                                                            size={"sm"}
                                                            className={"mr-2 mb-1"}
                                                            color={"link"}
                                                            disabled={!item.configuration.centralLockFaceFixStoppers.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                            onClick={() => this.handleChange(!item.configuration.centralLockFaceFixStoppers.selected.value, "centralLockFaceFixStoppers", itemIndex, true)}>
                                                            <i className={classnames("fa", "fa-lg", {
                                                                    "fa-check-square-o": item.configuration.centralLockFaceFixStoppers.selected.value,
                                                                    "fa-square-o": !item.configuration.centralLockFaceFixStoppers.selected.value,
                                                                }
                                                            )}/>
                                                        </Button>
                                                        Central Lock Stoppers
                                                    </div>
                                                    <div>
                                                        <Button
                                                            size={"sm"}
                                                            className={"mr-2 mb-1"}
                                                            color={"link"}
                                                            disabled={!item.configuration.removablePostKit.selected.isSelectable || item.configuration.isSkinOnly.selected.value}
                                                            onClick={() => this.handleChange(!item.configuration.removablePostKit.selected.value, "removablePostKit", itemIndex, true)}>
                                                            <i className={classnames("fa", "fa-lg", {
                                                                    "fa-check-square-o": item.configuration.removablePostKit.selected.value,
                                                                    "fa-square-o": !item.configuration.removablePostKit.selected.value,
                                                                }
                                                            )}/>
                                                        </Button>
                                                        Removable Post Kit
                                                    </div>
                                                </td>


                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={15}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={3}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <ZiptrakExternalBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <External
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
