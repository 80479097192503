import React, {Component} from 'react';
import {concatenateStrings} from "../../../../../../services/CommonService";

export let ziptrakInternalLabelStore = [
    {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key]
        }
    }, {
        key: "fabricType",
        label: "Fabric",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour);
        }
    }, {
        key: "frameColour",
        label: "Frame Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "channels",
        label: "Channels",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "pelmetSize",
        label: "Pelmet Size",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }
];