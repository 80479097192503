import axios from '../axios';
import cloneDeep from 'lodash/cloneDeep';

class ProductionOutstandingOrdersService {

    static Instance() {
        return new ProductionOutstandingOrdersService();
    }

    getProductionOutstandingOrders() {
        return axios.post('api/production/orders/outstanding');
    }

    createSummaryTableRows(ordersSummary) {
        let rows = [];

        let row;

        for (let i in ordersSummary.summaries) {

            for (let j in ordersSummary.summaries[i].products) {

                for (let k in ordersSummary.summaries[i].products[j].overdues) {

                    row = this.initRow();
                    if ((j === "0") && (k === "0")) {
                        row.isIndoorRowSpan = true;
                        row.indoorRowSpan = ordersSummary.summaries[i].totalStats.totalRows + 1;
                    }
                    if (k === "0") {
                        row.isProductRowSpan = true;
                        row.productRowSpan = ordersSummary.summaries[i].totalStats.totalRows + 1;
                    }

                    row.productLabel = ordersSummary.summaries[i].products[j].productName;
                    row.indoorLabel = ordersSummary.summaries[i].isIndoor ? "Indoor" : "Outdoor";
                    row.productRowSpan = ordersSummary.summaries[i].products[j].totalStats.totalRows + 1;


                    row.label = ordersSummary.summaries[i].products[j].overdues[k].overdueCategory;
                    row.totalOrders = ordersSummary.summaries[i].products[j].overdues[k].totalOrders;
                    row.totalUnits = ordersSummary.summaries[i].products[j].overdues[k].totalUnits;
                    row.totalValue = ordersSummary.summaries[i].products[j].overdues[k].totalValue;
                    row.backgroundColor = ordersSummary.summaries[i].products[j].overdues[k].backgroundColor;

                    rows.push(cloneDeep(row));
                }

                // overdues total
                row = this.initRow();
                row.label = "Total";
                row.indoorLabel = ordersSummary.summaries[i].isIndoor ? "Indoor" : "Outdoor";
                row.productLabel = ordersSummary.summaries[i].products[j].productName;
                row.totalOrders = ordersSummary.summaries[i].products[j].totalStats.totalOrders;
                row.totalUnits = ordersSummary.summaries[i].products[j].totalStats.totalUnits;
                row.totalValue = ordersSummary.summaries[i].products[j].totalStats.totalValue;
                row.totalRows = ordersSummary.summaries[i].products[j].totalStats.totalRows + 1;
                row.backgroundColor = "#eeeeee";
                rows.push(cloneDeep(row));
            }
            row = this.initRow();

            if (i === "totalStats") {
                row.label = "Total";
                row.totalOrders = ordersSummary.summaries[i].totalOrders;
                row.totalUnits = ordersSummary.summaries[i].totalUnits;
                row.totalValue = ordersSummary.summaries[i].totalValue;

                row.colSpan = 3;
            } else {
                row.label = "Total";
                row.indoorLabel = ordersSummary.summaries[i].isIndoor ? "Indoor" : "Outdoor";
                row.totalOrders = ordersSummary.summaries[i].totalStats.totalOrders;
                row.totalUnits = ordersSummary.summaries[i].totalStats.totalUnits;
                row.totalValue = ordersSummary.summaries[i].totalStats.totalValue;

                row.colSpan = 2;
            }
            row.backgroundColor = "#eeeeee";
            rows.push(cloneDeep(row));
        }

        return rows;

    }

    initRow() {
        return {
            productLabel: "",
            productRowSpan: 0,
            indoorLabel: "",
            indoorRowSpan: 0,
            totalOrders: 0,
            totalUnits: 0,
            totalValue: 0,
            isProductRowSpan: false,
            isIndoorRowSpan: false,
            colSpan: 0

        };
    }

    calculateTotalCounts(ordersSummary) {
        let temp;

        let totalStats = {
            totalOrders: 0,
            totalUnits: 0,
            totalValue: 0,
            totalRows: 0
        };

        ordersSummary.summaries.totalStats = cloneDeep(totalStats);
        for (let i in ordersSummary.summaries) {
            if (i !== "totalStats") {
                ordersSummary.summaries[i].totalStats = cloneDeep(totalStats);
            }

            for (let j in ordersSummary.summaries[i].products) {
                ordersSummary.summaries[i].products[j].totalStats = cloneDeep(totalStats);
                ordersSummary.summaries[i].products[j].orders = [];

                for (let k in ordersSummary.summaries[i].products[j].overdues) {
                    temp = ordersSummary.summaries[i].products[j].overdues[k];

                    //indoor level
                    ordersSummary.summaries.totalStats.totalOrders += temp.totalOrders;
                    ordersSummary.summaries.totalStats.totalUnits += temp.totalUnits;
                    ordersSummary.summaries.totalStats.totalValue += temp.totalValue;
                    ordersSummary.summaries.totalStats.totalRows += 1;

                    //product level
                    ordersSummary.summaries[i].totalStats.totalOrders += temp.totalOrders;
                    ordersSummary.summaries[i].totalStats.totalUnits += temp.totalUnits;
                    ordersSummary.summaries[i].totalStats.totalValue += temp.totalValue;
                    ordersSummary.summaries[i].totalStats.totalRows += 1;

                    // overdue level
                    ordersSummary.summaries[i].products[j].totalStats.totalOrders += temp.totalOrders;
                    ordersSummary.summaries[i].products[j].totalStats.totalUnits += temp.totalUnits;
                    ordersSummary.summaries[i].products[j].totalStats.totalValue += temp.totalValue;
                    ordersSummary.summaries[i].products[j].totalStats.totalRows += 1;

                    ordersSummary.summaries[i].products[j].orders = ordersSummary.summaries[i].products[j].orders.concat(temp.orders);
                }
                // for total row
                ordersSummary.summaries[i].totalStats.totalRows += 1;
            }
        }
        return ordersSummary;
    }
}

export default ProductionOutstandingOrdersService.Instance();