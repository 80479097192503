import React, {Component} from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    CardHeader,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';
import {getDateString, handleErrorMessage} from '../../services/CommonService';
import keywayStockService from '../../services/KeywayStockService';
import StockService from '../../services/StockService';
import UploadStockThumbnails from '../stock/UploadStockThumbnails';
import CreatableSelect from 'react-select/creatable';
import SearchCreditorAcccount from "../search/SearchCreditorAcccount";
import StockUtil from '../util/StockUtil';

const allTabs = [
    {
        label: "Stock ",
        key: "stockInfo"
    },
    {
        label: "Upload Attachments",
        key: "uploads"
    }
];

export default class ManageKeywayStockModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockGroups: [],
            stockUnits: [],
            partTypes: [],
            models: [],
            stock: StockUtil.initStockObj(this.props.prodCode, false),
            formError: {},
            isLoadingSave: false,
            isLoading: false,
            isNewStock: false,
            activeTab: 'stockInfo',
            isSubmitted: false
        };

        this.stockService = new StockService();
        this.getStock = this.getStock.bind(this);
        this.getKeywayStockUnits = this.getKeywayStockUnits.bind(this);
        this.getDistinctModels = this.getDistinctModels.bind(this);
        this.getDistinctPartTypes = this.getDistinctPartTypes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getStockGroups = this.getStockGroups.bind(this);
    }

    componentDidMount() {
        this.getKeywayStockUnits();
        this.getDistinctModels();
        this.getDistinctPartTypes();
        this.getStockGroups();
        this.getStock(this.state.stock.prodCode);
    }

    getStock(prodCode) {
        let {stock} = this.state;
        let isNewStock = true;
        if (prodCode) {
            if(stock.wwStock.prodCode !== prodCode){
                this.setState({isLoading: true});
                this.stockService.getStock(prodCode).then(response => {
                    stock = StockUtil.initStockObj(prodCode, false);
                    if (response.data) {
                        isNewStock = false;
                        stock = StockUtil.updateStock(stock, response.data);
                    }
                    this.setState({stock, isLoading: false, isNewStock});
                }).catch(error => {
                    isNewStock = true;
                    this.setState({isLoading: false, isNewStock});
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
        } else {
            this.setState({isLoading: false, isNewStock});
        }
    }

    getStockGroups() {
        this.stockService.getStockGroups().then(response => {
            this.setState({stockGroups: response.data});
        }).catch(error => {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getKeywayStockUnits() {
        keywayStockService.getKeywayStockUnits().then(response => {
            this.setState({stockUnits: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDistinctPartTypes() {
        let {partTypes} = this.state;
        partTypes = [];
        keywayStockService.getKeywayStockPartTypes().then(response => {
            for (let i = 0; i < response.data.length; i++) {
                partTypes.push({"label": response.data[i], "value": response.data[i]});
            }
            this.setState({partTypes});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getDistinctModels() {
        let {models} = this.state;
        models = [];
        keywayStockService.getKeywayStockModels().then(response => {
            for (let i = 0; i < response.data.length; i++) {
                models.push({"label": response.data[i], "value": response.data[i]});
            }
            this.setState({models});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    handleChange(value, key) {
        let {partTypes, models, stock, isSubmitted} = this.state;
        let found = false;
        let item = {};
        switch (key) {
            case "prodCode":
                stock[key] = value;
                /*  stock.keywayStock[key] = value;
                  stock.wwStock[key] = value;*/
                this.setState({stock}, () => {
                    /*let self = this;
                    setTimeout(function () {
                        self.getStock(value);
                    }, 5000);*/
                });
                break;
            case "description":
            case "length":
            case "weight":
            case "notes":
                stock[key] = value;
                stock.keywayStock[key] = value;
                stock.wwStock[key] = value;
                this.setState({stock});
                break;
            case "material":
            case "width":
            case "height":
            case "colour":
            case "finish":
            case "dimensionUnitName":
            case "weightUnitName":
            case "whUnitName":
            case "eachUnitName":
            case "sellUnitName":
            case "partType":
            case "model":
            case "sellQtyPrice":
            case "sellUnitQty":
            case "whUnitQty":
            case "eachUnitPrice":
            case "swishConversionFactorPrice":
            case "swishConversionFactorQty":
            case "isRange":
                stock.keywayStock[key] = value;
                this.setState({stock});
                break;
            case "PartType":
                for (let i = 0; i < partTypes.length; i++) {
                    if (partTypes[i].value === value) {
                        found = true;
                        item = partTypes[i];
                        break;
                    }
                }
                if (!found) {
                    let obj = {label: value, value: value};
                    partTypes.push(obj);
                    stock.keywayStock.partType = value;
                } else {
                    stock.keywayStock.partType = value;
                }
                this.setState({partTypes, stock});
                break;
            case "Model":
                for (let i = 0; i < models.length; i++) {
                    if (models[i].value === value) {
                        found = true;
                        item = models[i];
                        break;
                    }
                }
                if (!found) {
                    let obj = {label: value, value: value};
                    models.push(obj);
                    stock.keywayStock.model = value;
                } else {
                    stock.keywayStock.model = value;
                }
                this.setState({models, stock});
                break;
            case "available":
                stock.wwStock[key] = value;
                stock.wwStock.discontinued = !value;
                if (value) {
                    stock.wwStock.soldOut = !value;
                }
                this.setState({ stock });
                break;
            case "discontinued":
                stock.wwStock[key] = value;
                stock.wwStock.available = !value;
                if (!value) {
                    stock.wwStock.soldOut = value;
                }
                this.setState({ stock });
                break;
            case "soldOut":
                stock.wwStock[key] = value;
                if (value) {
                    stock.wwStock.available = !value;
                    stock.wwStock.discontinued = value;
                }
                this.setState({ stock });
                break;
            case "xRefCode":
            case "distribCode":
            case "supProdCode":
            case "stockGroupID":
            case "type":
            case "dateDiscontinued":
            case "dateSoldOut":
            case "cardNum":
            case "priceUnit":
            case "supplierCost":
            case "freightCost":
            case "packagingCost":
            case "dutyCost":
            case "otherCosts":
            case "avgCost":
            case "currencyType":
            case "currencyCost":
            case "importUnit":
            case "dutyRate":
            case "leadTime":
            case "freight":
            case "materialCost":
            case "labourCost":
            case "productionCost":
            case "overheads":
            case "packItem":
            case "packCode":
            case "minimum":
            case "maximum":
            case "qoh":
            case "cut":
            case "pick":
            case "qbo":
            case "qfo":
            case "scan":
            case "packSize":
            case "upc":
            case "rep":
            case "supplierCode":
            case "supplierCode1":
            case "supplierCode2":
            case "supplierCode3":
            case "priceVol1":
            case "priceVol2":
            case "priceVol3":
            case "price1":
            case "sP1_Descrip":
            case "price2":
            case "sP2_Descrip":
            case "price3":
            case "sP3_Descrip":
            case "price4":
            case "sP4_Descrip":
            case "price5":
            case "sP5_Descrip":
            case "price6":
            case "sP6_Descrip":
            case "taxRate: 10,":
            case "taxRatePreGST":
            case "mTD_UNITS":
            case "mTD_SALES":
            case "mTD_COST":
            case "yTD_UNITS":
            case "yTD_SALES":
            case "yTD_COST":
            case "lY_UNITS":
            case "lY_SALES":
            case "lY_COST":
            case "lY2_UNITS":
            case "lY2_SALES":
            case "lY2_COST":
            case "monthCount":
            case "avgLast15":
            case "avgLast6":
            case "avgLast3":
            case "suggOrd":
            case "accepted":
            case "suggOrdAv":
            case "acceptedAv":
            case "priority":
            case "tube":
            case "noAudit":
            case "p1Margin":
            case "p2Margin":
            case "p3Margin":
            case "p4Margin":
            case "p5Margin":
            case "p6Margin":
            case "bMNum":
            case "reStockMin":
            case "landedKey":
            case "fileNum":
            case "newPriceLocal":
            case "newPriceExport":
            case "lastExchRate":
            case "lY3_UNITS":
            case "lY3_SALES":
            case "lY3_COST":
            case "bM_UNITS":
            case "bM_COST":
            case "bM_YTD_UNITS":
            case "bM_YTD_COST":
            case "webAvailable":
            case "cardNum2":
            case "cardNum3":
            case "noCostUpdate":
            case "masterGroupID":
            case "groupID":
            case "subGroupID":
            case "keywordID":
            case "packedID":
            case "qtyID":
            case "sizeID":
            case "weightID":
            case "colourID":
            case "productDescription":
            case "dateCreated":
            case "sampleDate":
            case "userID":
            case "packTypeID":
            case "incoTermID":
            case "id":
            case "dateWrittenDown":
            case "isWrittenDown":
            case "allowDiscount":
            case "buyPackSize":
            case "bM_Flag":
            case "linearWidth":
            case "isFabricGroup":
            case "fabricGroupCode":
            case "bindingCode":
            case "threadCode":
            case "braidCode":
            case "discontinuedBy":
            case "soldOutBy":
            case "writtenDownBy":
            case "preferredSupplier":
            case "hasDirectionalPattern":
            case "wizardId":
            case "group":
            case "qtyInCartons":
            case "bMAccessoryPriceIndex":
            case "hSCode":
            case "pickingTypeID":
                stock.wwStock[key] = value;
                this.setState({stock});
                break;
        }
        if(isSubmitted){
            let formError = this.validateForm(stock);
            this.setState({formError});
        }
    }

    validateForm(stock) {
        let formError = {
            isValid: true,
            errors: []
        };
        let message = "";

        if (!stock.prodCode) {
            message = "Empty prodCode!";
            formError.prodCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.prodCode && stock.prodCode.length > 16) {
            message = "prodCode should be less than " + 16 + " characters!";
            formError.prodCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.description) {
            message = "Empty description!";
            formError.description = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.description && stock.description.length > 50) {
            message = "description should be less than " + 50 + " characters!";
            formError.description = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.keywayStock.colour && stock.keywayStock.colour.length > 50) {
            message = "colour should be less than " + 50 + " characters!";
            formError.colour = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.keywayStock.finish && stock.keywayStock.finish.length > 50) {
            message = "finish should be less than " + 50 + " characters!";
            formError.finish = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.keywayStock.material && stock.keywayStock.material.length > 50) {
            message = "material should be less than " + 50 + " characters!";
            formError.material = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.wwStock.xRefCode) {
            message = "Empty xRefCode!";
            formError.xRefCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.xRefCode && stock.wwStock.xRefCode.length > 30) {
            message = "XRefCode should be less than " + 30 + " characters!";
            formError.xRefCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.wwStock.stockGroupID) {
            message = "Empty Stock Group!";
            formError.stockGroupID = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.wwStock.supplierCode) {
            message = "Empty Supplier!";
            formError.supplierCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.wwStock.currencyType) {
            message = "Empty Currency Type!";
            formError.currencyType = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.currencyType && stock.wwStock.currencyType.length > 10) {
            message = "currencyType should be less than " + 10 + " characters!";
            formError.currencyType = message;
            formError.errors.push(message);
            formError.isValid = false;
        }

        if (stock.wwStock.sP1_Descrip && stock.wwStock.sP1_Descrip.length > 12) {
            message = "sP1_Descrip should be less than " + 12 + " characters!";
            formError.sP1_Descrip = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.sP2_Descrip && stock.wwStock.sP2_Descrip.length > 12) {
            message = "sP2_Descrip should be less than " + 12 + " characters!";
            formError.sP2_Descrip = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.sP3_Descrip && stock.wwStock.sP3_Descrip.length > 12) {
            message = "sP3_Descrip should be less than " + 12 + " characters!";
            formError.sP3_Descrip = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.sP4_Descrip && stock.wwStock.sP4_Descrip.length > 12) {
            message = "sP4_Descrip should be less than " + 12 + " characters!";
            formError.sP4_Descrip = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.sP5_Descrip && stock.wwStock.sP5_Descrip.length > 12) {
            message = "sP5_Descrip should be less than " + 12 + " characters!";
            formError.sP5_Descrip = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.sP6_Descrip && stock.wwStock.sP6_Descrip.length > 12) {
            message = "sP6_Descrip should be less than " + 12 + " characters!";
            formError.sP6_Descrip = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.priceUnit && stock.wwStock.priceUnit.length > 9) {
            message = "sP6_Descrip should be less than " + +9 + " characters!";
            formError.sP6_Descrip = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.wwStock.cardNum && stock.wwStock.cardNum.length > 10) {
            message = "cardNum should be less than " + +10 + " characters!";
            formError.cardNum = message;
            formError.errors.push(message);
            formError.isValid = false;
        }

        return formError;
    }

    handleSubmit(e) {
        e.preventDefault();
        let {stock} = this.state;
        let formError = this.validateForm(stock);

        this.setState({formError, isSubmitted: true});
        if (!formError.isValid) {
            formError.errors.forEach(error => {
                toast.info(error);
            });
            return;
        }

        let req = cloneDeep(stock);
        req.wwStock.prodCode = stock.prodCode;
        req.keywayStock.prodCode = stock.prodCode;
        req.keywayStock.isNonStock = false;
        this.setState({isLoadingSave: true});
        this.stockService.saveStock(req).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success("Saved!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });

                this.setState({isLoadingSave: false});
                this.props.refreshAgain(stock.prodCode);
                this.props.toggle(false);
            }
        }).catch(error => {
            this.setState({isLoadingSave: false});
            toast.error(handleErrorMessage(error));
        });
    }

    toggleTab(activeTab) {
        this.setState({activeTab});
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {stock, isNewStock, partTypes, models, formError, isLoading, isLoadingSave, activeTab, stockGroups} = this.state;
        return (
            <div>
                <Modal isOpen={isOpen} size="xl" scrollable={true} toggle={() => toggle(!isOpen)}>
                    <CardHeader>
                        <button type={"button"} className="close" onClick={() => toggle(!isOpen)}><span
                            aria-hidden="true">×</span></button>


                        <Nav tabs card>
                            {allTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>
                                            {tab.label}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <ModalBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="stockInfo">
                                {isLoading
                                    ? <Spinner color="primary"/>
                                    : <div>
                                        <h6>Basic Information
                                            {(activeTab === "stockInfo" && stock.prodCode)
                                                ? <Badge color={"primary"} size={"sm"}
                                                         className={"ml-2"}>{((isNewStock) ? "New" : "Edit")}</Badge>
                                                : ""}
                                        </h6>
                                        <Row>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Prodcode*</Label>
                                                    <Input
                                                        type="text"
                                                        value={stock.prodCode}
                                                        onBlur={() => this.getStock(stock.prodCode)}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        name="prodCode"
                                                        placeholder="ProdCode">
                                                    </Input>
                                                    <FormText color="danger">{formError.prodCode}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={7} lg={7} md={7} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Description*</Label>
                                                    <Input
                                                        type="text"
                                                        name="description"
                                                        value={stock.description}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="description">
                                                    </Input>
                                                    <FormText color="danger">{formError.description}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Stock Group</Label>
                                                    <Input
                                                        invalid={formError.stockGroupID}
                                                        type="select"
                                                        name="stockGroupID"
                                                        value={stock.wwStock.stockGroupID}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    >
                                                        <option value={""}>Select</option>
                                                        {(stockGroups || []).map(sg => {
                                                            return <option value={sg.stockGroupID}>{sg.name}</option>
                                                        })}
                                                    </Input>
                                                    <FormText
                                                        color="danger">{formError.stockGroupID}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <h6>Status</h6>
                                        <Row>
                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <Button color={"link"}
                                                    onClick={() => this.handleChange(!stock.wwStock.available, "available")}
                                                    disabled={stock.wwStock.discontinued}
                                                >
                                                    {stock.wwStock.available ?
                                                        <i className="fa fa-lg fa-check-square-o mr-2"
                                                            aria-hidden="true" /> :
                                                        <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true" />}
                                                </Button>
                                                <Label className="mb-0">Active</Label>
                                            </Col>
                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <Button color={"link"}
                                                    onClick={() => this.handleChange(!stock.wwStock.discontinued, "discontinued")}
                                                    disabled={stock.wwStock.soldOut}
                                                >
                                                    {stock.wwStock.discontinued ?
                                                        <i className="fa fa-lg fa-check-square-o mr-2"
                                                            aria-hidden="true" /> :
                                                        <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true" />}
                                                </Button>
                                                <Label className="mb-0">Discontinued</Label>
                                            </Col>
                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <Button color={"link"}
                                                    onClick={() => this.handleChange(!stock.wwStock.soldOut, "soldOut")}
                                                    disabled={!stock.wwStock.discontinued}
                                                >
                                                    {stock.wwStock.soldOut ?
                                                        <i className="fa fa-lg fa-check-square-o mr-2"
                                                            aria-hidden="true" /> :
                                                        <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true" />}
                                                </Button>
                                                <Label className="mb-0">Sold Out</Label>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <h6>Advance Information</h6>
                                        <Row>
                                            <Col xl={2} lg={2} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Colour</Label>
                                                    <Input
                                                        type="text"
                                                        name="colour"
                                                        value={stock.keywayStock.colour}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="colour">
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Finish</Label>
                                                    <Input
                                                        type="text"
                                                        name="finish"
                                                        value={stock.keywayStock.finish}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="finish">
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Material</Label>
                                                    <Input
                                                        type="text"
                                                        name="material"
                                                        value={stock.keywayStock.material}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="material">
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Part Type</Label>
                                                    <CreatableSelect
                                                        formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                        onChange={(value) => this.handleChange(value.value, "PartType")}
                                                        value={{
                                                            "label": stock.keywayStock.partType,
                                                            "value": stock.keywayStock.partType
                                                        }}
                                                        options={partTypes}
                                                    />
                                                    <FormText color="danger">{formError.partType}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Model</Label>
                                                    <CreatableSelect
                                                        formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                        onChange={(value) => this.handleChange(value.value, "Model")}
                                                        value={{
                                                            "label": stock.keywayStock.model,
                                                            "value": stock.keywayStock.model
                                                        }}
                                                        options={models}
                                                    />
                                                    <FormText color="danger">{formError.model}</FormText>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                                <h6>Each</h6>
                                                <FormGroup>
                                                    <Label>Each Unit</Label>
                                                    <Input
                                                        type="select"
                                                        value={stock.keywayStock.eachUnitName}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        name="eachUnitName"
                                                        placeholder="Each Unit">
                                                        <option key='0' value=''>Select Each Unit</option>
                                                        {this.state.stockUnits.map((item) => <option
                                                            key={item.name}
                                                            value={item.name}>{item.fullName}</option>)}
                                                    </Input>
                                                    <FormText color="danger">{formError.eachUnitName}</FormText>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Each Unit Qty</Label>
                                                    <Input
                                                        disabled={true}
                                                        type="text"
                                                        value={1}>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Each Unit Price</Label>
                                                    <Input
                                                        invalid={formError.eachUnitPrice}
                                                        type="number"
                                                        name="eachUnitPrice"
                                                        value={stock.keywayStock.eachUnitPrice}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="each Unit Price"/>
                                                    <FormText color="danger">{formError.eachUnitPrice}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                                <h6>Sell</h6>
                                                <FormGroup>
                                                    <Label>Sell Unit</Label>
                                                    <Input
                                                        type="select"
                                                        value={stock.keywayStock.sellUnitName}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        name="sellUnitName"
                                                        placeholder="Sell Unit">
                                                        <option key='0' value=''>Select Sell Unit</option>
                                                        {this.state.stockUnits.map((item) => <option
                                                            key={item.name}
                                                            value={item.name}>{item.fullName}</option>)}
                                                    </Input>
                                                    <FormText color="danger">{formError.sellUnitName}</FormText>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Sell Unit Qty</Label>
                                                    <Input
                                                        invalid={formError.sellUnitQty}
                                                        type="number"
                                                        name="sellUnitQty"
                                                        value={stock.keywayStock.sellUnitQty}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText color="danger">{formError.sellUnitQty}</FormText>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Sell Qty Price</Label>
                                                    <Input
                                                        invalid={formError.sellQtyPrice}
                                                        type="number"
                                                        name="sellQtyPrice"
                                                        value={stock.keywayStock.sellQtyPrice}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText color="danger">{formError.sellQtyPrice}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                                <h6>Warehouse</h6>
                                                <FormGroup>
                                                    <Label>WH Pack Unit</Label>
                                                    <Input
                                                        type="select"
                                                        value={stock.keywayStock.whUnitName}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        name="whUnitName"
                                                        placeholder="WH Pack Unit">
                                                        <option key='0' value=''>Select WH Pack Unit</option>
                                                        {this.state.stockUnits.map((item) => <option
                                                            key={item.name}
                                                            value={item.name}>{item.fullName}</option>)}
                                                    </Input>
                                                    <FormText color="danger">{formError.whUnitName}</FormText>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>WH Pack Content Qty</Label>
                                                    <Input
                                                        invalid={formError.whUnitQty}
                                                        type="number"
                                                        name="whUnitQty"
                                                        value={stock.keywayStock.whUnitQty}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText color="danger">{formError.whUnitQty}</FormText>
                                                </FormGroup>

                                            </Col>

                                        </Row>
                                        <hr/>
                                        <h6>Dimension</h6>
                                        <Row>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Dimension Unit</Label>
                                                    <Input
                                                        type="select"
                                                        value={stock.keywayStock.dimensionUnitName}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        name="dimensionUnitName"
                                                        placeholder="Dimension Unit">
                                                        <option key='0' value='0'>Select Dimension Unit</option>
                                                        {this.state.stockUnits.map((item) => <option
                                                            key={item.name}
                                                            value={item.name}>{item.fullName}</option>)}
                                                    </Input>
                                                    <FormText color="danger">{formError.dimensionUnitName}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Length</Label>
                                                    <Input
                                                        type="number"
                                                        name="length"
                                                        value={stock.keywayStock.length}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="length">
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Width</Label>
                                                    <Input
                                                        type="number"
                                                        name="width"
                                                        value={stock.keywayStock.width}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="width">
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Height</Label>
                                                    <Input
                                                        type="number"
                                                        name="height"
                                                        value={stock.keywayStock.height}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="height">
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Linear Width</Label>
                                                    <Input
                                                        invalid={formError.linearWidth}
                                                        type="number"
                                                        name="linearWidth"
                                                        value={stock.wwStock.linearWidth}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.linearWidth}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2} md={3} sm={3} xs={6}>
                                                <FormGroup>
                                                    <Label>Is Range</Label>
                                                    <div>
                                                        <ButtonGroup>
                                                            <Button
                                                                size={"sm"}
                                                                name={"isRange"}
                                                                onClick={(e) => this.handleChange(true, e.target.name)}
                                                                color={stock.keywayStock.isRange ? "success" : "primary"}

                                                                outline={!stock.keywayStock.isRange}>
                                                                Yes
                                                                {stock.keywayStock.isRange
                                                                    ? <i className="fa fa-check ml-2"
                                                                         aria-hidden="true"/>
                                                                    : null
                                                                }</Button>
                                                            <Button
                                                                onClick={(e) => this.handleChange(false, e.target.name)}
                                                                color={stock.keywayStock.isRange ? "primary" : "danger"}
                                                                size={"sm"} name={"isRange"}
                                                                outline={stock.keywayStock.isRange}>
                                                                No
                                                                {stock.keywayStock.isRange
                                                                    ? null
                                                                    :
                                                                    <i className="fa fa-check ml-2" aria-hidden="true"/>
                                                                }</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <h6>Weight</h6>
                                                <Row>
                                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                        <FormGroup>
                                                            <Label>Weight Unit</Label>
                                                            <Input
                                                                type="select"
                                                                value={stock.keywayStock.weightUnitName}
                                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                                name="weightUnitName"
                                                                placeholder="Weight Unit">
                                                                <option key='0' value='0'>Select Weight Unit</option>
                                                                {this.state.stockUnits.map((item) => <option
                                                                    key={item.name}
                                                                    value={item.name}>{item.fullName}</option>)}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                        <FormGroup>
                                                            <Label>Weight</Label>
                                                            <Input
                                                                type="number"
                                                                name="weight"
                                                                value={stock.keywayStock.weight}
                                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                                placeholder="weight">
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <h6>SWISH</h6>
                                        <Row>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Supplier*</Label>
                                                    <SearchCreditorAcccount
                                                        handleAccountChange={(selectedAccountID) => this.handleChange(selectedAccountID, "supplierCode")}
                                                        selectedAccountID={stock.wwStock.supplierCode}
                                                        defaultAccountID={""}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.supplierCode}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>XRefCode*</Label>
                                                    <Input
                                                        invalid={formError.xRefCode}
                                                        type="text"
                                                        name="xRefCode"
                                                        value={stock.wwStock.xRefCode}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.xRefCode}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Cost</Label>
                                                    <Input
                                                        invalid={formError.currencyCost}
                                                        type="number"
                                                        name="currencyCost"
                                                        value={stock.wwStock.currencyCost}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.currencyCost}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Currency Type*</Label>
                                                    <Input
                                                        invalid={formError.currencyType}
                                                        type="text"
                                                        name="currencyType"
                                                        value={stock.wwStock.currencyType}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.currencyType}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Freight Cost</Label>
                                                    <Input
                                                        invalid={formError.freightCost}
                                                        type="number"
                                                        name="freightCost"
                                                        value={stock.wwStock.freightCost}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.freightCost}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Packaging Cost</Label>
                                                    <Input
                                                        invalid={formError.packagingCost}
                                                        type="number"
                                                        name="packagingCost"
                                                        value={stock.wwStock.packagingCost}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.packagingCost}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Other Costs</Label>
                                                    <Input
                                                        invalid={formError.otherCosts}
                                                        type="number"
                                                        name="otherCosts"
                                                        value={stock.wwStock.otherCosts}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.otherCosts}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Duty Cost</Label>
                                                    <Input
                                                        invalid={formError.dutyCost}
                                                        type="number"
                                                        name="dutyCost"
                                                        value={stock.wwStock.dutyCost}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.dutyCost}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Duty Rate</Label>
                                                    <Input
                                                        invalid={formError.dutyRate}
                                                        type="number"
                                                        name="dutyRate"
                                                        value={stock.wwStock.dutyRate}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.dutyRate}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Landed Cost</Label>
                                                    <Input
                                                        invalid={formError.landedCost}
                                                        disabled={true}
                                                        type="number"
                                                        name="landedCost"
                                                        value={(stock.wwStock.supplierCost + stock.wwStock.freightCost + stock.wwStock.packagingCost + stock.wwStock.dutyCost + stock.wwStock.otherCosts)}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.landedCost}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <h6>Prices</h6>
                                        <Row>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price1</Label>
                                                    <Input
                                                        invalid={formError.price1}
                                                        type="number"
                                                        name="price1"
                                                        value={stock.wwStock.price1}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.price1}</FormText>
                                                </FormGroup>
                                            </Col>

                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price2</Label>
                                                    <Input
                                                        invalid={formError.price2}
                                                        type="number"
                                                        name="price2"
                                                        value={stock.wwStock.price2}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.price2}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price3</Label>
                                                    <Input
                                                        invalid={formError.price3}
                                                        type="number"
                                                        name="price3"
                                                        value={stock.wwStock.price3}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.price3}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price4</Label>
                                                    <Input
                                                        invalid={formError.price4}
                                                        type="number"
                                                        name="price4"
                                                        value={stock.wwStock.price4}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.price4}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price5</Label>
                                                    <Input
                                                        invalid={formError.price5}
                                                        type="number"
                                                        name="price5"
                                                        value={stock.wwStock.price5}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.price5}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>price6</Label>
                                                    <Input
                                                        invalid={formError.price6}
                                                        type="number"
                                                        name="price6"
                                                        value={stock.wwStock.price6}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.price6}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Sell1 Description</Label>
                                                    <Input
                                                        invalid={formError.sP1_Descrip}
                                                        type="text"
                                                        name="sP1_Descrip"
                                                        value={stock.wwStock.sP1_Descrip}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.sP1_Descrip}</FormText>
                                                </FormGroup>
                                            </Col>

                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Sell2 Description</Label>
                                                    <Input
                                                        invalid={formError.sP2_Descrip}
                                                        type="text"
                                                        name="sP2_Descrip"
                                                        value={stock.wwStock.sP2_Descrip}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.sP2_Descrip}</FormText>
                                                </FormGroup>
                                            </Col>

                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Sell3 Description</Label>
                                                    <Input
                                                        invalid={formError.sP3_Descrip}
                                                        type="text"
                                                        name="sP3_Descrip"
                                                        value={stock.wwStock.sP3_Descrip}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.sP3_Descrip}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Sell4 Description</Label>
                                                    <Input
                                                        invalid={formError.sP4_Descrip}
                                                        type="text"
                                                        name="sP4_Descrip"
                                                        value={stock.wwStock.sP4_Descrip}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.sP4_Descrip}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Sell5 Description</Label>
                                                    <Input
                                                        invalid={formError.sP5_Descrip}
                                                        type="text"
                                                        name="sP5_Descrip"
                                                        value={stock.wwStock.sP5_Descrip}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.sP5_Descrip}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Sell6 Description</Label>
                                                    <Input
                                                        invalid={formError.sP6_Descrip}
                                                        type="text"
                                                        name="sP6_Descrip"
                                                        value={stock.wwStock.sP6_Descrip}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.sP6_Descrip}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price Vol1</Label>
                                                    <Input
                                                        invalid={formError.priceVol1}
                                                        type="number"
                                                        name="priceVol1"
                                                        value={stock.wwStock.priceVol1}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.priceVol1}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>PriceVol2</Label>
                                                    <Input
                                                        invalid={formError.priceVol2}
                                                        type="number"
                                                        name="priceVol2"
                                                        value={stock.wwStock.priceVol2}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.priceVol2}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price Vol3</Label>
                                                    <Input
                                                        invalid={formError.priceVol3}
                                                        type="number"
                                                        name="priceVol3"
                                                        value={stock.wwStock.priceVol3}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.priceVol3}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Card Num</Label>
                                                    <Input
                                                        invalid={formError.cardNum}
                                                        type="text"
                                                        name="cardNum"
                                                        value={stock.wwStock.cardNum}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.cardNum}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price Unit</Label>
                                                    <Input
                                                        invalid={formError.priceUnit}
                                                        type="text"
                                                        name="priceUnit"
                                                        value={stock.wwStock.priceUnit}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.priceUnit}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Import Unit</Label>
                                                    <Input
                                                        invalid={formError.importUnit}
                                                        type="number"
                                                        name="importUnit"
                                                        value={stock.wwStock.importUnit}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.importUnit}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Pack Size</Label>
                                                    <Input
                                                        invalid={formError.packSize}
                                                        type="number"
                                                        name="packSize"
                                                        value={stock.wwStock.packSize}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.packSize}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Buy Pack Size</Label>
                                                    <Input
                                                        invalid={formError.buyPackSize}
                                                        type="number"
                                                        name="buyPackSize"
                                                        value={stock.wwStock.buyPackSize}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.buyPackSize}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>ReStockMin</Label>
                                                    <Input
                                                        invalid={formError.reStockMin}
                                                        type="number"
                                                        name="reStockMin"
                                                        value={stock.wwStock.reStockMin}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.reStockMin}</FormText>
                                                </FormGroup>
                                            </Col><Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Minimum</Label>
                                                <Input
                                                    invalid={formError.minimum}
                                                    type="number"
                                                    name="minimum"
                                                    value={stock.wwStock.minimum}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                />
                                                <FormText
                                                    color="danger">{formError.minimum}</FormText>
                                            </FormGroup>
                                        </Col><Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Maximum</Label>
                                                <Input
                                                    invalid={formError.maximum}
                                                    type="number"
                                                    name="maximum"
                                                    value={stock.wwStock.maximum}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                />
                                                <FormText
                                                    color="danger">{formError.maximum}</FormText>
                                            </FormGroup>
                                        </Col>


                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Notes</Label>
                                                    <Input
                                                        type="textarea"
                                                        name="notes"
                                                        rows={1}
                                                        value={stock.keywayStock.notes}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="notes"/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <div className={"text-right mt-2"}>
                                            <Button size={"sm"} color='primary' onClick={this.handleSubmit}
                                                    disabled={isLoadingSave}>
                                                {isLoadingSave ? <Spinner size="sm" className="mr-2"
                                                                   style={{color: "white"}}/> :
                                                    <i className="fa fa-floppy-o mr-2"
                                                       aria-hidden="true"/>}
                                                Save
                                            </Button>{' '}
                                            <Button color='secondary' size={"sm"}
                                                    onClick={() => toggle(!isOpen)}>
                                                <i className="fa fa-times mr-2"/>
                                                Cancel
                                            </Button>{' '}</div>
                                    </div>
                                }
                            </TabPane>
                            <TabPane tabId="uploads">
                                <UploadStockThumbnails
                                    toggleCloseModal={() => toggle(!isOpen)}
                                    prodCode={stock.keywayStock.prodCode}
                                    isNewStock={isNewStock}/>
                            </TabPane>
                        </TabContent>
                    </ModalBody>

                </Modal>
                <ToastContainer/>
            </div>
        );
    }
}
