import React, {Component} from 'react';
import UserService from "../../services/UserService";

export default class RoleList extends Component {
    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = {
            loading: true,
            dropdownOpen: false,
            selectedRole: this.props.selectedrole
        };

        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let {isExternal} = this.props;
        this.userService.listRoles().then(roles => {
            if (isExternal) {
                roles = roles.filter(x => x.isExtUser && x.name !== "Bunnings Admin");
            }
            this.setState({roles: roles, loading: false});
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedRole !== nextProps.selectedrole) {
            this.setState({selectedRole: nextProps.selectedrole});
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleChange(e) {
        this.setState({selectedRole: e.target.value});
        this.props.roleChangeHandler(e.target.value)
    }

    render() {

        if (this.state.loading) return (<div>please wait...</div>);
        let roles = this.state.roles;

        return (

            <select className="form-control" value={this.state.selectedRole} onChange={this.handleChange}>
                <option value="0">Choose...</option>
                {roles.map(role =>
                    <option key={role.roleID} value={role.roleID}>{role.name}</option>
                )}
            </select>

        )
    }
}