import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, Spinner, Table, Row, Col } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import productConfigurationService from "../../services/product/ProductConfigurationService";
import { handleErrorMessage } from "../../services/CommonService";
import NumberFormat from "react-number-format";

export default class PriceMatrixComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matrixData: {},
            isLoading: false,
            variant: props.variant
        };
    }

    componentDidMount() {
        this.printMatrix();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.variant !== nextProps.variant) {
            this.setState({ variant: nextProps.variant }, () => { this.printMatrix() });
        }
    }

    printMatrix() {
        let { productID, BomCode, accountID } = this.props;
        this.setState({ isLoading: true });
        productConfigurationService.getPriceMatrix(productID, BomCode, accountID, this.state.variant).then(response => {
            this.setState({ matrixData: response.data, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error));
        });
    }


    render() {
        let { matrixData, isLoading } = this.state;
        return (
            <div>
                <div>
                    {isLoading
                        ? <Spinner color="primary" />
                        : <div>
                            <Table bordered striped size="sm" responsive>
                                <tbody>
                                    <tr>
                                        <td className="text-center">Dimension</td>
                                        {
                                            (matrixData.columnHeaders || []).map(header => {
                                                return <th className="text-center">Set&nbsp;{header}</th>

                                            })
                                        }
                                    </tr>
                                    {
                                        (matrixData.rows || []).map((row, rowIndex) => {
                                            return <tr>
                                                <th className="text-right">{matrixData.rowHeaders[rowIndex]}</th>
                                                {

                                                    (row.columns || []).map(column => {
                                                        return <td className="text-right"><NumberFormat
                                                            prefix={'$'}
                                                            value={column.price}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true} /></td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    }
                    </div>
                <ToastContainer />
            </div>
        )
    }
}