import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {Link as Link} from "react-router-dom";
import queryString from 'query-string';
import NumberFormat from "react-number-format";
import OrderService from '../../services/OrderService';
import UserService from '../../services/UserService';
import {findIndex, handleErrorMessage} from '../../services/CommonService';
import {ORDER_GATEWAY_PENDING_TAB_STATUS} from "../../store/AppConstants";

export default class MultipleOrderStatusChangeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            salesOrderJobStatuses: this.props.salesOrderJobStatuses,
            orders: this.props.orders,
            salesOrderJobStatusId: null,
            orderDetails: [],
            loadingOrders: false,
            hasDebtorInvoiceCreditHoldReleasePrivilege: false,
            selectedCheckboxesCount: 0,
            salesOrderStatusDetail: [],
            filtered_status: []
        };

        this.userService = new UserService();
        this.orderService = new OrderService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getOrderDetails = this.getOrderDetails.bind(this);
    }

    componentDidMount() {
        let {hasDebtorInvoiceCreditHoldReleasePrivilege} = this.state;
        let {salesOrderJobStatuses} = this.props;
        let currentUser = this.userService.getLoggedInUser();

        //statuses includes Pending, Awaiting Furth Info, Awaiting production
        let filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
            return ORDER_GATEWAY_PENDING_TAB_STATUS.includes(stat.id);
        });

        hasDebtorInvoiceCreditHoldReleasePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-invoice-credit-hold-release');
        this.setState({hasDebtorInvoiceCreditHoldReleasePrivilege, filtered_status}, () => {
            this.getOrderDetails(this.state.orders)
        });


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedCheckboxesCount !== this.state.selectedCheckboxesCount) {
            this.setState({selectedCheckboxesCount: nextProps.selectedCheckboxesCount, salesOrderJobStatusId: null});
            this.getOrderDetails(nextProps.orders);
        }

    }

    getOrderDetails(orders) {
        let {hasDebtorInvoiceCreditHoldReleasePrivilege} = this.state;
        let orderNumbers = [];
        orders.map(order => {
            if (order.selected) {
                orderNumbers.push(order.ordNum);
            }
        });

        let req = {
            orderNumbers: orderNumbers,
        };

        this.setState({loadingOrders: true});
        this.orderService.getCustomerOrderDetails(req).then(res => {
            let orderDetails = res.data;
            orderDetails.forEach(order => {
                if (order.salesOrderJobStatusDescription === 'Credit Hold' && hasDebtorInvoiceCreditHoldReleasePrivilege) {
                    order.selected = true;
                }
                else if (order.salesOrderJobStatusDescription !== 'Credit Hold') {
                    order.selected = true;
                }
                else {
                    order.selected = false;
                }
            });
            this.setState({orderDetails: res.data, loadingOrders: false});
        }).catch(error => {
            this.setState({loadingOrders: false});
            toast.error(handleErrorMessage(error));
        });

    }

    handleChange(value, key, orderIndex) {
        let {orderDetails, salesOrderStatusDetail, salesOrderJobStatusId} = this.state;
        let tempOrderStatus = {};
        switch (key) {
            case "salesOrderJobStatusId":
                (orderDetails || []).filter(x => x.salesOrderJobStatusDescription !== 'Credit Hold').forEach((order, index) => {
                    let salesOrderStatusIndex = salesOrderStatusDetail.findIndex(x => x.ordNum === order.ordNum);
                    if (salesOrderStatusIndex === -1) {
                        tempOrderStatus = this.salesOrderStatusDetailTempObject(order);
                        tempOrderStatus.salesOrderJobStatusId = parseInt(value);
                        salesOrderStatusDetail.push(tempOrderStatus);
                    }
                    else {
                        salesOrderStatusDetail[salesOrderStatusIndex].salesOrderJobStatusId = parseInt(value);
                    }
                });
                salesOrderJobStatusId = parseInt(value);
        }
        this.setState({salesOrderStatusDetail, salesOrderJobStatusId, orderDetails});
    }

    salesOrderStatusDetailTempObject(order) {
        let tempOrderStatus = {
            overrideHold: order.overrideHold ? order.overrideHold : false,
            ordNum: order.ordNum,
            salesOrderJobStatusId: '',
            cancelReasonID: ''
        };
        return tempOrderStatus;
    }

    handleCancel() {
        //parameters- 1.to close modal, 2. refresh o.gateway page
        this.props.handlerCloseModal(false, false);
    }

    handleSubmit() {
        let {orderDetails, salesOrderJobStatusId, salesOrderStatusDetail} = this.state;
        let keepHoldOrderNos = '';
        let orderDetailsIndex = orderDetails.findIndex(x => x.salesOrderJobStatusDescription !== 'Credit Hold');
        if (!salesOrderJobStatusId && orderDetailsIndex > -1) {
            toast.info("Please select status");
            return;
        }
        keepHoldOrderNos = (orderDetails || []).filter(x => x.salesOrderJobStatusDescription === 'Credit Hold')
                        .map(x => x.ordNum).join();
        if (keepHoldOrderNos && orderDetailsIndex === -1) {
            toast.error(keepHoldOrderNos + " order status couldn't be updated due to order is on credit hold");
            return;
        }
       this.setState({ loading: true });
        this.orderService.changeOrderStatusForMultipleOrders(salesOrderStatusDetail).then(res => {
            if (res.status === 200 || res.status === '200') {
                this.setState({ loading: false });
                toast.success(keepHoldOrderNos !== "" ? "Order status changed successfully!, but " + keepHoldOrderNos + " order status couldn't be updated due to order is on credit hold." : "Order status changed successfully!");
                this.props.handlerCloseModal(false, true);
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    getAccountStatusDescriptionColor(accountStatusDescription) {
        let color = '';
        switch (accountStatusDescription) {
            case 'Active':
                color = 'green';
                break;
            case 'Inactive':
                color = 'orange';
                break;
            case 'Closed':
                color = 'red';
                break;
            default:
                color = 'green';
                break;
        }
        return color;
    }

    getAccountName(ordNum) {
        let OrdIndex = findIndex(this.state.orders, "ordNum", ordNum);
        return this.state.orders[OrdIndex].delCompany;
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {loading, salesOrderJobStatusId, orderDetails, loadingOrders, hasDebtorInvoiceCreditHoldReleasePrivilege, filtered_status} = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">Update Order Status</h5>
                </ModalHeader>
                <ModalBody>
                    {loadingOrders ?
                        <Spinner color='primary' size='sm'/> :
                        (
                            orderDetails.map((order, index) => (
                                <Card key={index}>
                                    <CardBody>
                                        <Row>
                                            <Col xl={3} lg={3} md={3} sm={3}>
                                                <div>
                                                    <Button color={"link"} disabled={true} className={"mr-2"}>
                                                        {order.selected ?
                                                            <i className="fa fa-lg fa-check-square-o"
                                                               aria-hidden="true"/> :
                                                            <i className="fa fa-lg fa-square-o" aria-hidden="true"/>
                                                        }
                                                    </Button>
                                                    <Link
                                                        className={"btn btn-sm btn-primary"}
                                                        title={"Click here to view order in detail"}
                                                        to={"/sales/order/view?" + queryString.stringify({OrdNum: order.ordNum})}>
                                                        {order.ordNum}
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col className={"text-left"}>
                                                <strong>
                                                    {this.getAccountName(order.ordNum)}
                                                </strong>
                                            </Col>
                                            <Col className={"text-right"}>
                                                <div>
                                                    <strong className="mr-2">Current status:</strong>
                                                    <Badge color={'warning'}
                                                           size={'sm'}>
                                                        {order.salesOrderJobStatusDescription}
                                                    </Badge>
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            order.salesOrderJobStatusDescription === 'Credit Hold'
                                                ? <div>
                                                    <hr/>
                                                    <Row>
                                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                            <div>
                                                            <span>
                                                                <strong className="mr-2">Account Status:</strong>
                                                                <span style={{
                                                                    color: this.getAccountStatusDescriptionColor(order.accountStatusDescription),
                                                                    fontWeight: 'bold'
                                                                }}>{order.accountStatusDescription}
                                                                </span>
                                                            </span>
                                                            </div>
                                                            <div>
                                                                <strong className="mr-2">Credit
                                                                    Terms:</strong>{order.creditTerms}
                                                            </div>
                                                            <div>
                                                                <strong className="mr-2">Credit Limit:</strong><NumberFormat
                                                                value={order.creditLimit}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                prefix={'$'}/>
                                                            </div>
                                                            <div>
                                                                <strong className="mr-2">Payment
                                                                    Terms:</strong>{order.paymentTerms}
                                                            </div>
                                                        </Col>
                                                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"text-right"}>
                                                            <div>
                                                                <strong className="mr-2">Current:</strong>
                                                                <NumberFormat value={order.current}
                                                                              displayType={'text'}
                                                                              decimalScale={2}
                                                                              thousandSeparator={true}
                                                                              fixedDecimalScale={true}
                                                                              prefix={'$'}/>
                                                            </div>
                                                            <div>
                                                                <strong className="mr-2">30 Days:</strong>
                                                                <NumberFormat value={order.thirtyDays}
                                                                              displayType={'text'}
                                                                              decimalScale={2}
                                                                              thousandSeparator={true}
                                                                              fixedDecimalScale={true}
                                                                              prefix={'$'}/>
                                                            </div>
                                                            <div>
                                                                <strong className="mr-2">60 Days:</strong>
                                                                <NumberFormat value={order.sixtyDays}
                                                                              displayType={'text'}
                                                                              decimalScale={2}
                                                                              thousandSeparator={true}
                                                                              fixedDecimalScale={true}
                                                                              prefix={'$'}/>
                                                            </div>
                                                            <div>
                                                                <strong className="mr-2">90 Days:</strong>
                                                                <NumberFormat value={order.ninetyDays}
                                                                              displayType={'text'}
                                                                              decimalScale={2}
                                                                              thousandSeparator={true}
                                                                              fixedDecimalScale={true}
                                                                              prefix={'$'}/>
                                                            </div>
                                                            <div>
                                                                <strong className="mr-2">Balance:</strong>
                                                                <NumberFormat value={order.balance}
                                                                              displayType={'text'}
                                                                              decimalScale={2}
                                                                              fixedDecimalScale={true}
                                                                              thousandSeparator={true}
                                                                              prefix={'$'}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : null
                                        }


                                    </CardBody>
                                </Card>
                            )))}


                </ModalBody>
                <ModalFooter>
                    <Input type={"select"}
                           placeholder={"Select new order status"}
                           name={"salesOrderJobStatusId"}
                           value={salesOrderJobStatusId ? salesOrderJobStatusId : ""}
                           onChange={(e) => this.handleChange(e.target.value, "salesOrderJobStatusId", null)}>
                        <option value={""} disabled>Select new order status</option>
                        {
                            (filtered_status || []).map((option, index) =>
                                <option key={index} value={option.id}>{option.statusDescription}</option>
                            )
                        }
                    </Input>
                    <div className={"text-right"}>
                        <Button color={"primary"} className={"mr-2"} size={"sm"} onClick={this.handleSubmit}
                                disabled={loading}>
                            {loading ? <Spinner size="sm"
                                                className={"mr-2"}
                                                color={"light"}/>
                                : <i className="fa fa-floppy-o mr-2"
                                     aria-hidden="true"/>
                            }
                            {loading ? "Updating " : "Update "}status</Button>
                        <Button color={"secondary"}
                                size={"sm"} onClick={() => this.handleCancel()}>
                            Cancel
                        </Button>
                    </div>


                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}