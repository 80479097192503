import React, { Component } from 'react';
import OrderService from "../../services/OrderService";
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';

export default class SearchJobRef extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            defaultJobRef: props.defaultJobRef,
            jobRef: props.selectedJobRef,
            loading: false,
            allJobRefs: [],
            inputValue: "",
        };
        this.orderService = new OrderService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.fetchOptions(this.state.defaultJobRef || this.state.jobRef);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultJobRef !== nextProps.defaultJobRef) {
            if (nextProps.defaultJobRef) {
                this.setState({
                    defaultJobRef: nextProps.defaultJobRef,
                    jobRef: nextProps.defaultJobRef
                }, () => {
                    this.fetchOptions(nextProps.defaultJobRef);
                })
            }
        }

        if (this.state.jobRef !== nextProps.selectedJobRef) {
            if (!nextProps.selectedJobRef) {
                this.setState({ selected: null, jobRef: "" })
            }
            else {
                this.setState({ jobRef: nextProps.selectedJobRef })
            }
            this.fetchOptions(nextProps.selectedJobRef);
        }
        if (
            this.state.includeChildren !== nextProps.includeChildren
            || this.state.includeClosed !== nextProps.includeClosed
            || this.state.includeInactive !== nextProps.includeInactive
        ) {
            this.setState({ includeChildren: nextProps.includeChildren, includeClosed: nextProps.includeClosed, includeInactive: nextProps.includeInactive }
                , () => { this.fetchOptions(this.state.defaultJobRef) });
        }
    }

    handleSelect(selectedJobRef, event) {
        let { selected, jobRef } = this.state;
        selected = selectedJobRef;
        jobRef = selectedJobRef ? selectedJobRef.jobRef : "";
        switch (event) {
            case "selected":
                this.setState({ selected, jobRef });
                break;
            case "onChange":
            case "default":
            default:
                this.setState({ selected, jobRef });
                this.props.handleJobRefChange(jobRef);
                break;
        }
        if (!jobRef) {
            this.fetchOptions("");
        }
    };

    fetchOptions(inputValue, callback) {
        let allJobRefs = [];
        this.orderService.searchJobRef(inputValue).then(response => {
            let data = response.data;
            for (let i in data) {
                let jobRef = {
                    label: data[i],
                    value: data[i],
                    id: data[i],
                    jobRef: data[i],
                };
                allJobRefs.push(cloneDeep(jobRef));
                if (this.props.defaultJobRef) {
                    if (data[i] === this.props.defaultJobRef) {
                        this.handleSelect(jobRef, "default");
                    }
                }
                if (this.props.selectedJobRef) {
                    if (data[i] === this.props.selectedJobRef) {
                        this.handleSelect(jobRef, "selected");
                    }
                }
            }
            this.setState({ allJobRefs });
            if (callback) {
                return callback(allJobRefs)
            } else {
                return allJobRefs;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        inputValue = inputValue.trim();
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 0) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allJobRefs);
            }
        }
    };

    render() {
        return (
            <div>
                <AsyncSelect
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.allJobRefs}
                    loadOptions={this.loadOptions}
                    onChange={(selection) => this.handleSelect(selection, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                    placeholder={"Search..."}
                />
            </div>
        );
    }
}