import React, {Component} from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner,
    Table, Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, Progress, Row, Col } from 'reactstrap';
import { changeFormatOfDateString,
    handleErrorMessage, CheckClaimWarranty, getDateObj, WarrantyStatus, findIndex } from '../../services/CommonService';
import OrderService from '../../services/OrderService';
import {WARRANTY_PERIOD_FOR_INDOOR_PRODUCT, WARRANTY_PERIOD_FOR_OUTDOOR_PRODUCT, WARRANTY_PERIOD_FOR_MOTOR} from "../../store/AppConstants";
import {toast, ToastContainer} from 'react-toastify';
import SalesOrderService from '../../services/WINOInventory/SalesOrderService';
import AttachmentService from '../../services/AttachmentService';
import FileSaver from 'file-saver';

export default class CheckWarrantyForReworkModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            approvingOrder: false,
            reworkOrderData: {productItems: []},
            ordNum: props.selectedData.ordNum,
            isMotorised: true,
            isLoading: {},
            selectedData: props.selectedData,
            indoorProductWarrantyStatus: 'Invalid',
            outdoorProductWarrantyStatus: 'Invalid',
            motorWarrantyStatus: 'Invalid',
            attachmentIDs: "",
            attachments: [],
            downloadProgress: 0,
            loadingAttachments: false,
        };
        this.orderService = new OrderService();
        this.getReworkOrderDetail = this.getReworkOrderDetail.bind(this);
        this.checkWarrantyWithDate = this.checkWarrantyWithDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.attachmentService = new AttachmentService();
        this.fetchAttachments = this.fetchAttachments.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleDownloadProgress = this.handleDownloadProgress.bind(this);
    }

    fetchAttachments(IDs) {
        this.setState({ loadingAttachments: true });
        this.attachmentService.getparticularAttachments(IDs).then(response => {
            if (response.data) {
                this.setState({ attachments: response.data, loadingAttachments: false });
            }
        }).catch(error => {
            console.log(error.data);
            this.setState({ loadingAttachments: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleDownloadProgress(progressEvent, attachmentId) {
        let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        let { attachments } = this.state;
        let index = findIndex(attachments, 'id', attachmentId);
        attachments[index].downloadProgress = percent;
        this.setState({ attachments });
    }

    handleFileDownload(item) {
        let options = {
            onDownloadProgress: (progressEvent) => this.handleDownloadProgress(progressEvent, item.id)
        };
        this.attachmentService.DownloadAttachment(item.id, options).then(response => {
            FileSaver.saveAs(response.data, item.description);
            let { attachments } = this.state;
            let index = findIndex(attachments, 'id', item.id);
            attachments[index].downloadProgress = 0;
            this.setState({ attachments });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            let { attachments } = this.state;
            let index = findIndex(attachments, 'id', item.id);
            attachments[index].downloadProgress = 0;
            this.setState({ attachments });
        });
    }

    componentDidMount() {
        let selectedData = this.props.selectedData;
        this.setState({selectedData}, () => {
            this.getReworkOrderDetail(selectedData.ordNum);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedData !== nextProps.selecctedData) {
            this.setState({selectedData: nextProps.selecctedData}, () => {
                this.getReworkOrderDetail(nextProps.selectedData.ordNum);
            });
        }
    }


    handleChange(key, change) {
        let {reworkOrderData} = this.state;
        reworkOrderData[key] = change;
        this.setState({reworkOrderData});
    }

    getReworkOrderDetail(ordNum) {
        let {reworkOrderData} = this.state;
        this.setState({loading: true});
        this.orderService.getReworkOrderDetail(ordNum).then(response => {
            reworkOrderData = response.data;
            let attachmentIDs = reworkOrderData.attachmentIDs;
            if (attachmentIDs) {
                this.setState({ attachmentIDs }, () => {
                    this.fetchAttachments(attachmentIDs);
                });
            } else {
                this.setState({ attachmentIDs: "", attachments: [] });
            }
            this.setState({reworkOrderData, loading: false}, () => {
                this.checkWarrantyWithDate(reworkOrderData);
            });
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    takeActionOnOrder(order, option) {
        let {isLoading} = this.state;
        isLoading[option] = true;
        this.setState({isLoading});
        SalesOrderService.updateKeywaySalesOrder(order.reworkOrdNum, option).then(response => {
            if (option === "Make") {
                toast.success("Rework order approved");
            }
            else {
                toast.success("Rework order rejected & Order has been sent to the cancel tab.");
            }
            isLoading[option] = false;
            this.setState({isLoading});
            this.props.toggle(false);
            this.props.refreshAgain();

        }).catch(error => {
            isLoading[option] = false;
            this.setState({isLoading});
            console.log(error);
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    checkWarrantyWithDate(reworkOrderData) {
        (reworkOrderData.productItems || []).forEach((item, index) => {
            item.status = CheckClaimWarranty(getDateObj(reworkOrderData.invoiceDate, "DD/MM/yyyy"), getDateObj(reworkOrderData.dateCreated, "DD/MM/yyyy"), item.product_Type);
        });
        if (CheckClaimWarranty(getDateObj(reworkOrderData.invoiceDate, "DD/MM/yyyy"), getDateObj(reworkOrderData.dateCreated, "DD/MM/yyyy"), "INDOOR")) {
            this.setState({ indoorProductWarrantyStatus: "Valid" });
        }
        if (CheckClaimWarranty(getDateObj(reworkOrderData.invoiceDate, "DD/MM/yyyy"), getDateObj(reworkOrderData.dateCreated, "DD/MM/yyyy"), "OUTDOOR")) {
            this.setState({ outdoorProductWarrantyStatus: "Valid" });
        }
        if (CheckClaimWarranty(getDateObj(reworkOrderData.invoiceDate, "DD/MM/yyyy"), getDateObj(reworkOrderData.dateCreated, "DD/MM/yyyy"), "MOTOR")) {
            this.setState({ motorWarrantyStatus: "Valid" });
        }
        this.setState({reworkOrderData: reworkOrderData});
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { reworkOrderData, loading, isLoading, indoorProductWarrantyStatus, outdoorProductWarrantyStatus, motorWarrantyStatus, attachments, loadingAttachments } = this.state;
        return (
            <div>
                <Modal isOpen={isOpen} size={"md"} scrollable={false} toggle={() => toggle(!isOpen)}>
                    <ModalHeader toggle={() => toggle(!isOpen)}>Order No. {reworkOrderData.reworkOrdNum}</ModalHeader>
                    <ModalBody>
                        {
                            loading
                                ? <Spinner size={"sm"} color="primary" className={"mr-2"}/>
                                : <div>
                                    <Table responsive size={"sm"} >
                                        <thead>
                                            <tr><th colSpan={2}>Original Order Details</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="align-middle">Original Order</td>
                                                <td className="align-middle text-right">
                                                    <a className="btn btn-sm btn-primary"
                                                        title={"Click Here to see the Details"}
                                                        href={"/sales/order/view?OrdNum=" + reworkOrderData.originalOrdNum}>
                                                        {reworkOrderData.originalOrdNum}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Invoice Date</td>
                                                <td className="align-middle text-right">
                                                    {changeFormatOfDateString(reworkOrderData.invoiceDate, "DD/MM/YYYY", 'DD MMM YYYY')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Ship Date</td>
                                                <td className="align-middle text-right">
                                                    {changeFormatOfDateString(reworkOrderData.orderShipDate, "DD/MM/YYYY", 'DD MMM YYYY')}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table responsive size={"sm"} >
                                        <thead>
                                            <tr><th colSpan={2}>Rework Order Details</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="align-middle">Rework Order</td>
                                                <td className="align-middle text-right">
                                                    <a className="btn btn-sm btn-primary"
                                                        title={"Click Here to see the Details"}
                                                        href={"/sales/order/view?OrdNum=" + reworkOrderData.reworkOrdNum}>
                                                        {reworkOrderData.reworkOrdNum}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Rework Reason</td>
                                                <td className="align-middle text-right">{reworkOrderData.reworkOrderType && reworkOrderData.reworkOrderType.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Reason Description</td>
                                                <td className="align-middle text-right">{reworkOrderData.reworkOrderType && reworkOrderData.reworkOrderType.description}</td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Rework Notes</td>
                                                <td className="align-middle text-right">{reworkOrderData.notes}</td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Claim Date</td>
                                                <td className="align-middle text-right">
                                                    {changeFormatOfDateString(reworkOrderData.dateCreated, "DD/MM/YYYY", 'DD MMM YYYY')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Warranty No.</td>
                                                <td className="align-middle text-right">
                                                    <Badge color={"warning"}>{reworkOrderData.warrantyNo}</Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Indoor Warranty ({WARRANTY_PERIOD_FOR_INDOOR_PRODUCT} Years)</td>
                                                <td className="align-middle text-right">
                                                    <Badge color={indoorProductWarrantyStatus === "Valid" ? "success" : "danger"}
                                                        className={"mr-2"}>{indoorProductWarrantyStatus}</Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Outdoor Warranty ({WARRANTY_PERIOD_FOR_OUTDOOR_PRODUCT} Years)</td>
                                                <td className="align-middle text-right">
                                                    <Badge color={outdoorProductWarrantyStatus === "Valid" ? "success" : "danger"}
                                                        className={"mr-2"}>{outdoorProductWarrantyStatus}</Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Motor Warranty ({WARRANTY_PERIOD_FOR_MOTOR} Years)</td>
                                                <td className="align-middle text-right">
                                                    <Badge color={motorWarrantyStatus === "Valid" ? "success" : "danger"}
                                                        className={"mr-2"}>{motorWarrantyStatus}</Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Price Waived %</td>
                                                <td className="align-middle text-right">{reworkOrderData.discount}%</td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle">Attachments</td>
                                                <td className="align-middle text-right">{loadingAttachments
                                                    ? <Spinner color={"primary"} />
                                                    : <Row className="mr-0 ml-0">
                                                        {(attachments || []).map((a, aIndex) => {
                                                            return <Col
                                                                className={"hoverableItem p-0 m-0"} key={aIndex}>
                                                                <Button color="link" size={"sm"}
                                                                    title={"Click here to download"}
                                                                    onClick={() => this.handleFileDownload(a)}>
                                                                    <i className="fa fa-paperclip mr-1" aria-hidden="true" /> {a.description}
                                                                </Button>
                                                                {
                                                                    (a.downloadProgress > 0 && a.downloadProgress < 100)
                                                                        ? <Progress animated value={a.downloadProgress} />
                                                                        : null
                                                                }
                                                            </Col>
                                                        })}
                                                    </Row>
                                                }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button color={"primary"} size={"sm"} disabled={isLoading.Make}
                                onClick={() => this.takeActionOnOrder(reworkOrderData, 'Make')}>
                            {isLoading.Make ? <Spinner size={"sm"} className="mr-2"/> :
                                <i className="fa fa-check mr-2" aria-hidden="true"/>}
                            Yes
                        </Button>

                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={"danger"}
                                            size={"sm"}
                                            disabled={isLoading.Rejected}>
                                {
                                    isLoading.Rejected
                                        ? <Spinner size={"sm"}
                                                   className={"mr-2"}/>
                                        : <i className="fa fa-times mr-2" aria-hidden="true"/>
                                }
                                No
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => this.takeActionOnOrder(reworkOrderData, "Rejected")}>
                                    <i className="fa fa-times mr-2" aria-hidden="true"/> Cancel Order
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => this.takeActionOnOrder(reworkOrderData, "AwaitingInfo")}>
                                    <i className="fa fa-times mr-2" aria-hidden="true"/> Awaiting Info
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <Button color={"secondary"} size={"sm"}
                                onClick={() => this.props.toggle(false)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/> Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}