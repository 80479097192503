import React, { Component } from 'react';
import { Row, Col, Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import FileSaver from 'file-saver';
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage } from "../../services/CommonService";
import ProductService from '../../services/product/ProductService';
import CreatableSelect from 'react-select/creatable';


export default class ProductConfigurationPricingMatrixImportExportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productID: props.productID,
            isDownloading: false,
            isImporting: false,
            isDryRun: false,
            importResponse: {},
            accountIDs: [],
            variants: [],
            accountID: '',
            product: {},
            variant: { label: "Default", value: "Default" }
        };
        this.handleDownload = this.handleDownload.bind(this);
        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.handleImport = this.handleImport.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.getDistinctVariants = this.getDistinctVariants.bind(this);
    };

    componentDidMount() {
        this.getDistinctAccountIDsFromPriceMatrix();
        this.getDistinctVariants(this.state.productID);
        ProductService.getProduct(this.state.productID).then(response => {
            this.setState({ product: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }


    getDistinctVariants(productID) {
        let { variants } = this.state;
        variants = [];
        ProductConfigurationService.getDistinctVariantsFromPriceMatrix(productID).then(response => {
            for (let i = 0; i < response.data.length; i++) {
                variants.push({ 'label': response.data[i], 'value': response.data[i] })
            }
            this.setState({ variants });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }


    getDistinctAccountIDsFromPriceMatrix() {
        ProductConfigurationService.getDistinctAccountIDsFromPriceMatrix(this.state.productID).then(response => {
            this.setState({ accountIDs: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    handleDownload() {
        this.setState({ isDownloading: true });
        ProductConfigurationService.downloadProductConfigurationPricingData(this.state.productID, this.state.accountID, this.state.variant.value).then(response => {
            this.setState({ isDownloading: false });
            FileSaver.saveAs(response.data, this.state.product.name + " Pricing Data" + ".xlsx");
        }).catch(error => {
            this.setState({ isDownloading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    handleImport() {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('isDryRun', this.state.isDryRun);
        data.append('variant', this.state.variant.value);
        this.setState({ isImporting: true });
        ProductConfigurationService.importPricingData(data, this.state.productID).then(response => {
            this.setState({ isImporting: false, importResponse: response.data });
            if (response.data.hasError) {
                toast.error("Error!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            } else {
                toast.success("Imported!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.refresh(this.state.productID);
            }
        }).catch(error => {
            this.setState({ isImporting: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }


    fileChangeHandler(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }


    handleChange(key, change) {
        this.setState({ [key]: change });
    }


    handleTypeChange(value, key, index) {
        let { variants } = this.state;
        var found = false;
        var item = {};
        for (var i = 0; i < variants.length; i++) {
            if (variants[i].value === value) {
                found = true;
                item = variants[i];
                break;
            }
        }
        if (!found) {
            let obj = { label: value, value: value }
            variants.push(obj);
            this.setState({ variants, variant: obj });
        } else {
            this.setState({ variant: item });
        }

    }


    render() {
        let { isOpen, toggle, type } = this.props;
        let { isDownloading, isImporting, accountIDs, variants, variant } = this.state;

        return (
            <div>
                <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                    <ModalHeader toggle={() => toggle(!isOpen)}>
                        <h5>{type} pricing</h5>
                    </ModalHeader>
                    <ModalBody>
                        {type === 'Import' ?
                            <div>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Label className="mb-0">Choose a file to import</Label>
                                        <Input type="file"
                                            name="file"
                                            id="file"
                                            onChange={this.fileChangeHandler}
                                            aria-label="Upload document" />
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className='mt-4'>
                                        <Label className="mb-0">Select Variant</Label>
                                        <CreatableSelect
                                            formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                            onChange={(value) => this.handleTypeChange(value.value, "label", true)}
                                            value={variant}
                                            options={variants}
                                        />
                                    </Col>
                                </Row>

                                <br />
                                <p className='text-info'>
                                    <i className="fa fa-info-circle"></i>  For uploading price matrix for specific customer, write the account id in sheet name after Bom code (seperated by a comma).
                                </p>
                            </div> :
                            <div>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Label className="mb-0">Select customer</Label>
                                        <Input type="select" name="accountID" value={this.state.accountID}
                                            onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                            <option disabled>Select</option>
                                            {(accountIDs || []).map((account, index) => (
                                                <option key={index}
                                                    value={account.accountID}>{account.accountID ? account.accountID.concat(' | ', account.customer) : 'Default'}</option>
                                            ))}
                                        </Input>
                                        <p className="text-info">Select customer for which you want to export pricing</p>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Label className="mb-0">Select Variant</Label>
                                        <CreatableSelect
                                            formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                            onChange={(value) => this.handleTypeChange(value.value, "label", true)}
                                            value={variant}
                                            options={variants}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        }

                    </ModalBody>
                    <ModalFooter>
                        <div className='text-right'>
                            {type === 'Import' ?
                                <Button color={"primary"} size={"sm"}
                                    outline={true}
                                    onClick={() => this.handleImport()}
                                    disabled={isImporting}>
                                    {isImporting
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            color={"primary"} />
                                        : <i className="fa fa-upload mr-2" />
                                    }Import</Button> :
                                <Button color={"primary"} size={"sm"}
                                    outline={true}
                                    onClick={() => this.handleDownload()}
                                    disabled={isDownloading}>
                                    {isDownloading
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            color={"primary"} />
                                        : <i className="fa fa-download mr-2" />
                                    }Download</Button>}
                            &nbsp;&nbsp;
                            <Button color={"secondary"}
                                size='sm'
                                onClick={() => toggle(!isOpen)}>
                                <i className='fa fa-times mr-2'></i> Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <br />
                <ToastContainer />
            </div>
        )
    }
}