import React, {Component} from 'react';
import {
    Card, CardHeader, CardBody, CardFooter,
    Table,
    Nav, NavItem, NavLink,
    Button, Badge,
    Row, Col,
    InputGroup, InputGroupText, InputGroupAddon, Input,
    Spinner,
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import {Link} from "react-router-dom";
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import {toast, ToastContainer} from 'react-toastify';
import NumberFormat from "react-number-format";
import packagingHandlingChargesService from '../../services/shipment/PackagingHandlingChargesService'
import productService from '../../services/product/ProductService'
import {findIndex, handleErrorMessage} from '../../services/CommonService'
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount'
import PackagingHandlingChargesRuleFormModal from '../../components/modal/PackagingHandlingChargesRuleFormModal'
import queryString from 'query-string';

const tabs = [
    {
        key: "default",
        label: "Default",
    }, {
        key: "customer",
        label: "Customer",
    }
];
export default class PackagingHandlingChargesRuleManagePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[0],
            workingRule: {},
            rules: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ID",
                        direction: false
                    },
                    filterRequest: {
                        id: 0,
                        accountID: "",
                        productID: null,
                        charge: null,
                        isActive: ""
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            products: [],
            loadingRules: false,
            loadingProducts: false,
            loadingWorkingRuleSave: false,
            isOpenWorkingRuleModal: false
        };
        this.getRules = this.getRules.bind(this);
        this.getProducts = this.getProducts.bind(this);

        this.toggleTab = this.toggleTab.bind(this);
        this.toggleWorkingRuleModal = this.toggleWorkingRuleModal.bind(this);

        this.handleAction = this.handleAction.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.handleWorkingRuleChange = this.handleWorkingRuleChange.bind(this);
        this.handleWorkingRuleSubmit = this.handleWorkingRuleSubmit.bind(this);

        this.getColValue = this.getColValue.bind(this);
    }

    componentDidMount() {
        let {rules, activeTab} = this.state;
        if (this.props.location) {
            if (this.props.location.search) {
                let searchParams = queryString.parse(this.props.location.search);
                rules.request.filterRequest.accountID = searchParams.accountId;
                activeTab = {
                    key: "customer",
                    label: "Customer",
                }
                this.setState({rules, activeTab}, () => {
                    this.getRules(rules)
                })
            }
            else {
                this.getRules(rules);
            }
        }
        else {
            this.getRules(rules);
        }
        this.getProducts();
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab}, () => {
                this.getRules(this.state.rules);
            });
        }
    }

    toggleWorkingRuleModal(change) {
        this.setState({isOpenWorkingRuleModal: change});
    }

    getProducts() {
        this.setState({loadingProducts: true});
        productService.getProducts().then(response => {
            this.setState({products: response.data, loadingProducts: false});
        }).catch(error => {
            console.log(error);
            this.setState({loadingProducts: false});
            toast.error(handleErrorMessage(error));
        })
    }

    getRules(rules) {
        this.setState({loadingRules: true});
        packagingHandlingChargesService.fetchRules(this.state.activeTab.key, cloneDeep(rules.request)).then(response => {
            rules.response = response.data;
            this.setState({rules, loadingRules: false});
        }).catch(error => {
            console.log(error);
            this.setState({loadingRules: false});
            toast.error(handleErrorMessage(error));
        })
    }

    handleStoreChange(change, key) {
        let {rules} = this.state;
        switch (key) {
            case "sortKey":
                if (rules.request.sortRequest.key === change) {
                    rules.request.sortRequest.direction = !rules.request.sortRequest.direction;
                } else {
                    rules.request.sortRequest.key = change;
                    rules.request.sortRequest.direction = false;
                }
                this.getRules(rules);
                break;
            case "pageSize":
                rules.request.pageRequest[key] = change;
                this.getRules(rules);
                break;
            case "currentPage":
                rules.request.pageRequest[key] = change;
                this.getRules(rules);
                break;
            default:
                rules.request.filterRequest[key] = change;
                this.setState({rules});
                this.getRules(rules);
        }

    }

    handleAction(change, key) {
        let {workingRule, rules} = this.state;
        let records = rules.response.records;
        switch (key) {
            case "new":
                workingRule = {
                    id: 0,
                    accountID: "",
                    productID: "",
                    charge: 0,
                    isActive: true
                };
                this.setState({workingRule, isOpenWorkingRuleModal: true});
                break;
            case "edit":
                let index = findIndex(records, 'id', change);
                if (index > -1) {
                    workingRule = cloneDeep(records[index]);
                    this.setState({workingRule, isOpenWorkingRuleModal: true});
                }
                break;
        }
    }

    handleWorkingRuleChange(change, key) {
        let {workingRule} = this.state;
        switch (key) {
            default:
                workingRule[key] = change;
                this.setState({workingRule});
        }
    }

    handleWorkingRuleSubmit(workingRule) {
        this.setState({loadingWorkingRuleSave: true});
        packagingHandlingChargesService.saveRule(workingRule).then(response => {
            toast.success("Saved!");
            this.setState({workingRule: {}, loadingWorkingRuleSave: false, isOpenWorkingRuleModal: false});
            this.getRules(this.state.rules);
        }).catch(error => {
            console.log(error);
            this.setState({loadingWorkingRuleSave: false});
            toast.error(handleErrorMessage(error));
        });
    }

    getStore() {
        let {products, rules} = this.state;
        let {filterRequest} = rules.request;

        return [
            {
                key: "accountID",
                label: "Customer AccountID",
                forDefaultTab: false,
                forCustomerTab: true,
                type: "accountID",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                style: {minWidth: 250},
                searchNode: <div>
                    <SearchDebtorAcccount
                        handleAccountChange={(accountID) => this.handleStoreChange(accountID, "accountID")}
                        selectedAccountID={filterRequest.accountID}
                        defaultAccountID={filterRequest.accountID}
                        includeChildren={true}
                        excludeClosedandInactive={false}/>
                </div>
            },
            {
                key: "productID",
                label: "Product",
                forDefaultTab: true,
                forCustomerTab: true,
                type: "productID",
                colSpan: 1,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"select"}
                           name={"productID"}
                           value={filterRequest.productID}
                           onChange={(e) => this.handleStoreChange(e.target.value, "productID")}>
                        <option value={""}>All</option>
                        {products.map((option, index) =>
                            <option key={index} value={option.id}>{option.name}</option>
                        )}
                    </Input>
                </div>
            },
            {
                key: "charge",
                label: "Charge for 1 Qty",
                forDefaultTab: true,
                forCustomerTab: true,
                type: "currency",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input type={"search"}
                               name={"charge"}
                               value={filterRequest.charge}
                               onChange={(e) => this.handleStoreChange(e.target.value, "charge")}>
                        </Input>
                    </InputGroup>
                </div>
            },
            {
                key: "isActive",
                label: "Is Active",
                forDefaultTab: true,
                forCustomerTab: true,
                type: "isActive",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "text-centre",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"select"}
                           name={"isActive"}
                           value={filterRequest.isActive}
                           onChange={(e) => this.handleStoreChange(e.target.value, "isActive")}>
                        <option value={""}>All</option>
                        <option value={true}>Active</option>
                        <option value={false}>Non-Active</option>
                    </Input>
                </div>
            },
            {
                key: "id",
                label: "Action",
                forDefaultTab: true,
                forCustomerTab: true,
                type: "action",
                colSpan: 1,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                style: {minWidth: 100},
                searchNode: <div className={"text-center"}>
                    <Button color={"primary"} size={"sm"}
                            onClick={() => this.handleAction(null, "new")}>
                        <i className="mr-2 fa fa-plus-circle" aria-hidden="true"/>New</Button>
                </div>
            }];
    }

    getColValue(value, storeItem) {
        switch (storeItem.type) {
            case "accountID":
                return <Link
                    className="btn btn-primary btn-sm"
                    style={{color: "white"}}
                    title={"Click here to see account details"}
                    to={"/customer/#" + value}
                >{value}</Link>;

            case "isActive":
                return <div className={"text-center"}>
                    <h5><Badge color={value ? "success" : "danger"}>{value ? "Active" : "Not-Active"}</Badge></h5>
                </div>;
            case "productID":
                return <Input type={"select"}
                              disabled={true}
                              placeholder={"Product"}
                              name={"productID"}
                              value={value}>
                    <option value={""}>Select</option>
                    {
                        this.state.products.map((option, index) =>
                            <option key={index} value={option.id}>{option.name}</option>
                        )
                    }
                </Input>;
            case "currency":
                return <NumberFormat value={value}
                                     displayType={'text'}
                                     decimalScale={2}
                                     fixedDecimalScale={true}
                                     thousandSeparator={true}
                                     prefix={'$'}/>;
            case "action":
                return <div><Button color={"primary"} size={"sm"}
                                    onClick={() => this.handleAction(value, "edit")}><i className="mr-2 fa fa-pencil"
                                                                                        aria-hidden="true"/>Edit</Button>
                </div>;
            default:
                return <span>{value}</span>
        }
    }


    render() {

        let {rules, products, loadingRules, activeTab} = this.state;
        let {pageRequest, sortRequest, filterRequest} = rules.request;
        let store = this.getStore();
        store = store.filter(storeItem => {
            if (activeTab.key === "default") {
                return storeItem.forDefaultTab;
            }
            if (activeTab.key === "customer") {
                return storeItem.forCustomerTab;
            }
            return false;
        });
        return (
            <div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Manage Packaging-Handling Charges Rules</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem
                                    className={"hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab.key === tab.key})}
                                        onClick={() => {
                                            this.toggleTab(tab);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"} color={"link"}>
                                            {tab.label}&nbsp;
                                            {
                                                (activeTab.key === tab.key && loadingRules)
                                                    ? <Spinner size="sm" color={"primary"} className={"ml-2"}/>
                                                    : <span className={"ml-2 p-2"}/>
                                            }
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loadingRules ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {rules.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>

                            </Col>


                        </Row>
                        <div>
                            <Table hover bordered responsive={true} size={"sm"} striped={true}>
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleStoreChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={item.style}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort text-muted": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    <td/>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>
                                </thead>
                                <tbody>

                                {(rules.response.records || []).filter(item => products.some(p => p.id === item.productID)).map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td key={index}>
                                                {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                            </td>
                                            {(store || []).map((storeItem, index) => {
                                                return (
                                                    <td key={index} className={storeItem.valueClassName}>
                                                        {this.getColValue(item[storeItem.key], storeItem)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    {rules.response.totalRecords > rules.response.pageSize
                        ? <CardFooter>
                            <Row>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"} style={{maxWidth: 200}}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{loadingRules ?
                                                    <Spinner size={"sm"}/> : "Show"}</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"select"}
                                                name={"pageSize"}
                                                value={sortRequest.pageSize}
                                                disabled={loadingRules}
                                                onChange={(e) => this.handleStoreChange(e.target.value, "pageSize")}>
                                                <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                                <option value={50}>50 Rows</option>
                                                <option value={100}>100 Rows</option>
                                                <option value={500}>500 Rows</option>
                                            </Input>
                                        </InputGroup>


                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"float-right"}>
                                        <Pagination
                                            activePage={pageRequest.currentPage}
                                            itemsCountPerPage={pageRequest.pageSize}
                                            totalItemsCount={rules.response.totalRecords}
                                            pageRangeDisplayed={3}
                                            onChange={(activePage) => this.handleStoreChange(activePage, "currentPage")}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                            innerClass='pagination'
                                            activeLinkClass='active'
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </CardFooter>
                        : null
                    }
                </Card>
                <PackagingHandlingChargesRuleFormModal
                    isOpen={this.state.isOpenWorkingRuleModal}
                    toggle={this.toggleWorkingRuleModal}
                    handleChange={this.handleWorkingRuleChange}
                    handleSubmit={this.handleWorkingRuleSubmit}
                    form={this.state.workingRule}
                    products={products}
                    loading={this.state.loadingWorkingRuleSave}
                    ruleType={activeTab.key}
                />
                <ToastContainer/>
            </div>
        );
    }
}