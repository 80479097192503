import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Label, FormGroup, Input, Spinner
} from 'reactstrap';
import quartzService from '../../services/QuartzService';
import {ToastContainer, toast} from 'react-toastify';
import {handleErrorMessage} from '../../services/CommonService';

export default class StatementScheduledDateUpdateModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cronExpression: '',
            saving: false,
        };
        this.updateTrigger = this.updateTrigger.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateCronExpression = this.validateCronExpression.bind(this);
    }

    handleChange(value, key) {
        this.setState({[key]: value});
    }

    validateCronExpression(cronExpression) {
        if (cronExpression.length === 0)
            return false;
        let regex = /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/;
        return regex.test(cronExpression);
    }

    updateTrigger() {
        let {cronExpression} = this.state;
        let {scheduledJobName} = this.props;

        let isValid = this.validateCronExpression(cronExpression);

        if (isValid) {
            this.setState({saving: true});
            quartzService.updateTrigger(scheduledJobName, cronExpression).then(res => {
                if (res.status === 200) {
                    this.setState({saving: false});
                    toast.success("Updated");
                    this.props.refresh();
                }
            }).catch(error => {
                this.setState({saving: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        else {
            toast.error("Please write a valid cron expression");
            return;
        }
    }

    render() {
        let {isOpen, toggle, pageLabel, scheduledJobName} = this.props;
        let {saving, cronExpression} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Update Next Scheduled Date {pageLabel ? ("For " + pageLabel ) : ""}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label className='mb-0'>Cron expression</Label>
                        <Input
                            className="form-control"
                            name="cronExpression"
                            type="text"
                            value={cronExpression}
                            placeholder='Enter cron expression here...'
                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}/>
                    </FormGroup>

                    <p className='text-info'>
                        <i className="fa fa-info-circle mr-2"/>
                        Please enter a valid cron expression and
                        <span>&nbsp;</span>
                        <a href='http://www.cronmaker.com/' target="_blank"
                           style={{textDecoration: 'underline'}}>
                            click here </a>
                        to know more about cron expressions.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"}
                                size='sm'
                                disabled={saving}
                                onClick={this.updateTrigger}>
                            {saving ? <Spinner size='sm' color='white' className='mr-2'/> :
                                <i className="fa fa-floppy-o mr-2"/>}
                            Update</Button>
                        <Button color={"secondary"}
                                className='ml-2'
                                size='sm'
                                onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2"/>
                            Cancel</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}