import React, { Component } from 'react';
import {
    Card,
    Modal,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import { handleErrorMessage } from '../../services/CommonService';

const formulaTypes = ["QTY", "PRICE", "WASTAGE"];
export default class ManageProductConfigurationStockFormulaModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            productGroupID: 0,
            productConfigurationStockFormulaID: this.props.selectedProductConfigurationStockFromulaID,
            productConfigurationStockFormulaObj: {
                id: 0,
                formula: '',
                postDeductionFormula: '',
                wastagePercent: 0,
                description: '',
                formulaType: '',
            },
            formErrors: {},
            saving: false,
            loading: false
        };
         
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() { 
         this.refresh();  
    }

    refresh() { 
        if (this.state.productConfigurationStockFormulaID) { 
            this.setState({ loading: true });

            ProductConfigurationService.getOneProductConfigurationStockFormula(this.state.productConfigurationStockFormulaID).then(response => {
                this.setState({ productConfigurationStockFormulaObj: response.data, loading: false }); 
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { productConfigurationStockFormulaObj } = this.state;

        productConfigurationStockFormulaObj[name] = value;
        this.setState({ productConfigurationStockFormulaObj });
    }
     

    validateForm(productConfigurationStockFormulaObj) {
        let formErrors = {};
        let valid = true;

        /* if (productConfigurationStockFormulaObj.formula === null || (productConfigurationStockFormulaObj.formula !== null && productConfigurationStockFormulaObj.formula.length === 0)) {
             formErrors.formula = "Please enter formula";
             valid = false;
         }
 */
        if (!productConfigurationStockFormulaObj.formulaType) {
            formErrors.formulaType = "Please select formula type";
            valid = false;
        }

        /*  if (productConfigurationStockFormulaObj.description === null || (productConfigurationStockFormulaObj.description !== null && productConfigurationStockFormulaObj.description.length === 0)) {
              formErrors.description = "Please enter description";
              valid = false;
          }
  */

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.productConfigurationStockFormulaObj };
        let result = this.validateForm(req);
        let { isOpen, refreshAgain } = this.props;

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });

            if (!this.state.productConfigurationStockFormulaID) {
                ProductConfigurationService.saveProductConfigurationStockFormula(req).then(response => {
                    if (response.status === 200 || response.status === "200") {
                         toast.success("Saved!", {
                            position: toast.POSITION.BOTTOM_CENTER
                         });
                        this.setState({ saving: false, productConfigurationStockFormulaObj: response.data });
                        this.props.refreshAgain();
                    }

                }).catch(error => {
                    this.setState({ saving: false });
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
            else {
                ProductConfigurationService.updateProductConfigurationStockFormula(req).then(response => {
                    if (response.status === 200 || response.status === "200") {
                        toast.success("Updated!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({ saving: false, productConfigurationStockFormulaObj: response.data });
                        this.props.refreshAgain();
                    }

                }).catch(error => {
                    this.setState({ saving: false });
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
        }
    }

    render() {
        let { productConfigurationStockFormulaObj, formErrors, loading } = this.state;
        let { isOpen, toggle, selectedProductConfigurationStockFromulaID } = this.props;
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)}>
                { selectedProductConfigurationStockFromulaID ? <CardHeader>
                    <h5>Edit Manage Product Configuration Stock Formula</h5>
                </CardHeader> : <CardHeader>
                    <h5> Add Manage Product Configuration Stock Formula</h5>
                </CardHeader> } 
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Formula Type</Label>
                                            <Input type={"select"}
                                                name={"formulaType"}
                                                invalid={formErrors.formulaType !== undefined}
                                                value={productConfigurationStockFormulaObj.formulaType || ''}
                                                onChange={this.handleChange}>
                                                <option value="" disabled>Select formula type</option>
                                                {
                                                    (formulaTypes || []).map(formulaType => {
                                                        return <option value={formulaType}>{formulaType}</option>
                                                    })
                                                }
                                            </Input>
                                            <FormText color="danger">{formErrors.formulaType}</FormText>
                                        </FormGroup>
                                    </Col>
                                    {
                                    productConfigurationStockFormulaObj.formulaType === 'WASTAGE' || selectedProductConfigurationStockFromulaID == null
                                            ? <Col lg={3} md={3} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Wastage percent</Label>
                                                    <Input type={"number"}
                                                        name={"wastagePercent"}
                                                        value={productConfigurationStockFormulaObj.wastagePercent || ''}
                                                        onChange={this.handleChange}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            : null
                                    }


                                </Row>
                                {
                                productConfigurationStockFormulaObj.formulaType === 'QTY' || selectedProductConfigurationStockFromulaID == null
                                        ? <Row>

                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Formula</Label>
                                                    <Input
                                                        name="formula"
                                                        type="text"
                                                        value={productConfigurationStockFormulaObj.formula || ''}
                                                        onChange={this.handleChange}
                                                        placeholder="write formula here..." />
                                                    <FormText color="danger">{formErrors.formula}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Post deduction formula</Label>
                                                    <Input
                                                        name="postDeductionFormula"
                                                        type="text"
                                                        value={productConfigurationStockFormulaObj.postDeductionFormula || ''}
                                                        onChange={this.handleChange}
                                                        placeholder="write post deduction formula here..." />
                                                    <FormText color="danger">{formErrors.postDeductionFormula}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        : null
                                }

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                value={productConfigurationStockFormulaObj.description || ''}
                                                onChange={this.handleChange}
                                                placeholder="write description here..." />
                                            <FormText color="danger">{formErrors.description}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving}>

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> :
                                    <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                        <Button color='secondary' onClick={() => toggle(!isOpen)}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter> 
            </Modal>
        );
    }
}
