import React, {Component} from 'react';
import {Button, Form, FormGroup, FormText, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import OrderService from "../../../services/OrderService";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getDistinctValues, handleErrorMessage, validateEmail} from "../../../services/CommonService";
import CustomerService from "../../../services/CustomerService";
import CreatableSelect from 'react-select/creatable';
import {colourStyles} from "../../../store/ComposeEmailReactSelectStyle";

export default class SimpleEmailModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            year: "",
            formErrors: {},
            emailId: this.props.emailId,
            AccountId: this.props.AccountId,
            month: "",
            monthsArray: [
                { 'text': 'Jan', 'label': 'January', 'value': "01" }, { 'text': 'Feb', 'label': 'February', 'value': "02" },
                { 'text': 'Mar', 'label': 'March', 'value': "03" }, { 'text': 'Apr', 'label': 'April', 'value': "04" },
                { 'text': 'May', 'label': 'May', 'value': "05" }, { 'text': 'Jun', 'label': 'June', 'value': "06" },
                { 'text': 'Jul', 'label': 'July', 'value': "07" }, { 'text': 'Aug', 'label': 'August', 'value': "08" },
                { 'text': 'Sep', 'label': 'September', 'value': "09" }, { 'text': 'Oct', 'label': 'October', 'value': "10" },
                { 'text': 'Nov', 'label': 'November', 'value': "11" }, { 'text': 'Dec', 'label': 'December', 'value': "12" }
            ],
            loading: {
                sendEmail: false
            },
            to: [],
            toOptions:[]
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.OrderService = new OrderService();
        this.customerService = new CustomerService();
        this.getDebtorStatementEmails = this.getDebtorStatementEmails.bind(this);
    }

    componentDidMount() {
        let { emailId, year, month, AccountId } = this.props;
        this.setState({
            emailId: emailId,
            year: year,
            month: month,
            AccountId: AccountId
        }, () => {
            this.getDebtorStatementEmails(AccountId)
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            emailId: nextProps.emailId,
            year: nextProps.year,
            month: nextProps.month,
            AccountId: nextProps.AccountId
        }, () => {
            this.getDebtorStatementEmails(nextProps.AccountId)
        });
    }

    handleChange(change) {
        this.setState({ to: change });
    }

    getDebtorStatementEmails(accountID) {
        let { emailId } = this.state;
        let toOptions=[];
        this.customerService.getDebtorStatementEmails(accountID).then(response => {
            if (response.data.length>0) {
                (response.data || []).forEach(item => {
                    toOptions.push({ label: item, value: item });
                });
            }
            else {
                toOptions.push({ label: emailId, value: emailId });
            }
            this.setState({ to: toOptions, toOptions });
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }

    getmonthNumber(month) {
        for (let i = 0; i < this.state.monthsArray.length; i++) {
            if (this.state.monthsArray[i].text === month)
                return this.state.monthsArray[i].value;
        }
        //return null;
    }

    validateForm() {
        let formErrors = {};
        if (this.state.to===null) {
            formErrors.email = "Email is required";
        }

        if (formErrors.email) {
            return { valid: false, formErrors: formErrors };
        }
        return { valid: true, formErrors: formErrors };
    }
    handleSubmit(e) {
        e.preventDefault();
        let result = this.validateForm();
        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            let loading = this.state.loading;
            loading.sendEmail = true;
            this.setState({ loading });
            let foundarray = this.getmonthNumber(this.state.month);
            let yearmonth = this.state.year.toString() + '.' + foundarray.toString();
            let requestBody = { YearMonth: yearmonth, EmailIds: getDistinctValues(this.state.to ? this.state.to : [], 'value'), AccountId: this.state.AccountId, month: this.state.month, year: this.state.year };
            this.OrderService.SendStatementInEmail(requestBody).then(response => {
                loading = this.state.loading;
                loading.sendEmail = false;
                if (response.data) {
                    this.setState({ loading });
                    toast.success("Email sent successfully.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.props.toggleModel();
                } else {
                    this.setState({ loading });
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            }).catch(error => {
                loading = this.state.loading;
                loading.sendEmail = false;

                this.setState({ loading });
                console.log(error.data);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });

        }
    }

    render() {
        let formErrors = this.state.formErrors;
        let { to, toOptions } = this.state;
        return (
            <Modal isOpen={this.props.isOpen} className="modal-lg" size="lg" scrollable={false} toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    <h6> STATEMENT FOR &nbsp;:&nbsp; {this.state.month} &nbsp;{this.state.year}</h6>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="username">Email</Label>
                            <CreatableSelect
                                isMulti
                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                placeholder={"Type complete email address then press enter"}
                                onChange={(value) => this.handleChange(value)}
                                value={to}
                                options={toOptions}
                                styles={colourStyles}
                            />
                            <FormText color="danger">{formErrors.email}</FormText>
                        </FormGroup>
                        <FormGroup>
                            <div className="text-right">
                                <Button size="sm" color="primary" disabled={this.state.loading.sendEmail} type="submit">
                                    {this.state.loading.sendEmail ?
                                        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" /> :
                                        <i className="fa fa-envelope" aria-hidden="true" />} &nbsp;
                                                       {this.state.loading.sendEmail ? "Sending " : "Send"}
                                </Button>&nbsp;&nbsp;
                                        <Button size="sm" color="secondary" onClick={this.props.toggleModel}>
                                        <i className="fa fa-times mr-2" aria-hidden="true"/>
                                                       Cancel
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ToastContainer />
            </Modal>
        )
    }
}