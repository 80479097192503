import React, {Component} from 'react';
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, Input, InputGroup, InputGroupAddon, InputGroupText, Table
} from 'reactstrap';
import {toast, ToastContainer} from "react-toastify";
import {handleErrorMessage} from '../../services/CommonService';
import retailService from '../../services/retail/RetailService';
import ProductDiscountService from "../../services/product/ProductDiscountService";
import {productToRemovedForRetail} from "../../store/ProductToRemovedForRetail";

export default class DebtorRetailAdminProductMarkupFormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: this.props.accountID,
            selectedProductMarkup: this.props.selectedProductMarkup,
            records: [],
            isLoadingRecords: false,
        };
        this.productDiscountService = new ProductDiscountService();

        this.getAllProductName = this.getAllProductName.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateRetailProductMarkup = this.updateRetailProductMarkup.bind(this);
    }

    componentDidMount() {
        this.getAllProductName();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.selectedProductMarkup !== nextProps.selectedProductMarkup) {
            this.setState({accountID: nextProps.accountID, selectedProductMarkup: nextProps.selectedProductMarkup},
                () => this.getAllProductName());
        }
    }

    getAllProductName() {
        let {accountID} = this.state;
        retailService.getProductMarkups(accountID).then(response => {
            let records = response.data;
            records = records.filter(x => !productToRemovedForRetail.filter(p => p.Code === x.product.code).length);
            this.setState({records, isLoadingRecords: false});
        }).catch(error => {
            toast.info(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({isLoadingRecords: false});
        });
    }

    handleChange(change, key, index) {
        let {records, accountID} = this.state;
        if (index > -1 && records) {
            if (change === null || change === '') {
                records[index][key] = 0;
            } else {
                records[index][key] = change;
            }
            records[index].accountID = accountID;
        }
        this.setState({records});
    }


    updateRetailProductMarkup() {
        let {records, accountID} = this.state;
        let {toggle, isOpen} = this.props;
        this.setState({isLoadingRecords: true});
        retailService.saveProductMarkups(records, accountID).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success('Saved!');
                this.setState({isLoadingRecords: false});
                toggle(!isOpen);
                this.props.refreshAgain();
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingRecords: false});
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {records, isLoadingRecords, selectedProductMarkup} = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {selectedProductMarkup.length > 0 ? "Edit" : "Add"} Product Markups
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <Table className={"table table-sm table-bordered table-striped"}>
                            <thead>
                            <tr>
                                <th>Products</th>
                                <th>Markup</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(records || []).map((item, index) =>
                                <tr key={index}>
                                    <td>
                                        <Input disabled
                                               type={"select"}
                                               id="productID" name="productID"
                                               placeholder={"Product"}
                                               value={item.productID}
                                               onChange={(e) => this.handleChange(e.target.value, "productID", index)}>
                                            <option value={""} disabled={true}>Select</option>
                                            {
                                                <option value={item.productId}>{item.product.displayName}</option>
                                            }
                                        </Input>
                                    </td>
                                    <td>
                                        <InputGroup>
                                            <Input type={"number"}
                                                   placeholder={"Markup"}
                                                   name={"discount"}
                                                   value={item.markup ? item.markup : null}
                                                   onChange={(e) => this.handleChange(e.target.value, "markup", index)}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size={"sm"} onClick={() => this.updateRetailProductMarkup()}
                                disabled={isLoadingRecords}>
                            {
                                isLoadingRecords ? <Spinner size="sm"
                                                            className={"mr-2"}
                                                            style={{color: "white"}}/>
                                    : <i className="fa fa-floppy-o mr-2"/>
                            }
                            {selectedProductMarkup.length > 0 ? "Update" : "Save"}
                        </Button>&nbsp;
                        <Button color={"secondary"} size="sm"
                                onClick={() => toggle(!isOpen)}> <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel</Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        );
    }
}