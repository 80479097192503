import { createBrowserHistory } from 'history';


class History {
    static Instance() {
        return createBrowserHistory();
    }

}
export default History.Instance();
