import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import StockEnquiryPageSalesHistoryTabInventoryInfo from "./StockEnquiryPageSalesHistoryTabInventoryInfo";
import StockEnquiryPageSalesHistoryTabLocation from "./StockEnquiryPageSalesHistoryTabLocation";
import StockEnquiryPageGeneralTabPricingCard from "./StockEnquiryPageGeneralTabPricingCard";
import StockEnquiryPageGeneralTabDimensionCard from "./StockEnquiryPageGeneralTabDimensionCard";
import StockEnquiryPageGeneralTabValuationCard from "./StockEnquiryPageGeneralTabValuationCard";
import StockEnquiryPageSalesHistoryTabSoldUnitsMonthly from './StockEnquiryPageSalesHistoryTabSoldUnitsMonthly';
import ShowNPurchaseOrderDetails from '../../purchase/ShowNPurchaseOrderDetails';

export default class StockEnquiryPageGeneralTab extends Component {
    render() {
        let { stock } = this.props;
        return (
            <>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <StockEnquiryPageSalesHistoryTabInventoryInfo stock={stock} />
                        <div className="mt-2">
                            <StockEnquiryPageSalesHistoryTabLocation stock={stock} />
                        </div>
                        <div className="mt-2">
                            <StockEnquiryPageGeneralTabDimensionCard stock={stock} />
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <StockEnquiryPageSalesHistoryTabSoldUnitsMonthly stock={stock} showAverage={true} />
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <StockEnquiryPageGeneralTabPricingCard stock={stock} />
                        {/*<div className="mt-2">
                        <StockEnquiryPageGeneralTabSafetyStockCard stock={stock}/>
                    </div>*/}
                        <div className="mt-2">
                            <StockEnquiryPageGeneralTabValuationCard stock={stock} />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <ShowNPurchaseOrderDetails prodCode={stock.prodCode} requiredRecords={3} sortKey={"dateAdded"} sortDirection={false} outstanding={false} cancelled={false} />
                    </Col>
                </Row>
            </>
        );
    }
}