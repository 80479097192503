import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import WarehouseLocationService from '../../../services/WINOInventory/WarehouseLocationService';
import WarehouseService from '../../../services/WINOInventory/WarehouseService';
import WarehouseLocationTypeService from '../../../services/WarehouseLocationTypeService';
import {handleErrorMessage} from "../../../services/CommonService";


export default class WINOManageWarehouseLocation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseLocationID: '',
            warehouseLocationObj: {
                id: 0,
                name: '',
                code: '',
                isActive: true,
                warehouseLocationTypeID:0,
                warehouseID:0,
            },
            warehouses: [],
            warehouseLocationTypes:[],
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getWarehouses = this.getWarehouses.bind(this);
        this.getWarehouseLocationTypes = this.getWarehouseLocationTypes.bind(this);
        
    }

    componentDidMount() {
        let { warehouseLocationID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            warehouseLocationID = searchParams.warehouseLocationID;
            this.setState({ warehouseLocationID }, () => { this.refresh() })
        }
        this.getWarehouses();
        this.getWarehouseLocationTypes();

    }

    getWarehouses() {
        WarehouseService.listAllWarehouse().then(response => {
            this.setState({ warehouses: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getWarehouseLocationTypes() {
        WarehouseLocationTypeService.listAllWarehouseLocationTypes().then(response => {
            this.setState({ warehouseLocationTypes: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    refresh() {
        if (this.state.warehouseLocationID) {
            this.setState({ loading: true })

            WarehouseLocationService.getOneWarehouseLocation(this.state.warehouseLocationID).then(response => {
                this.setState({ warehouseLocationObj: response.data, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { warehouseLocationObj } = this.state;

        warehouseLocationObj[name] = value;
        this.setState({ warehouseLocationObj });
    }

    handleCancel() {
        this.props.history.push('/keyway-inventory/warehouse-location');
    }

    validateForm(warehouseLocationObj) {
        let formErrors = {};
        let valid = true;

        if (warehouseLocationObj.name === null || (warehouseLocationObj.name !== null && warehouseLocationObj.name.length === 0)) {
            formErrors.name = "Please enter name";
            valid = false;
        }

        if (warehouseLocationObj.code === null || (warehouseLocationObj.code !== null && warehouseLocationObj.code.length === 0)) {
            formErrors.code = "Please enter code";
            valid = false;
        }

        if (warehouseLocationObj.warehouseID === 0 || warehouseLocationObj.warehouseID === '' || warehouseLocationObj.warehouseID === '0' || warehouseLocationObj.warehouseID === null) {
            formErrors.warehouseID = "Please select Warehouse";
            valid = false;
        }

        if (warehouseLocationObj.warehouseLocationTypeID === 0 || warehouseLocationObj.warehouseLocationTypeID === '' || warehouseLocationObj.warehouseLocationTypeID === '0' || warehouseLocationObj.warehouseLocationTypeID === null) {
            formErrors.warehouseLocationTypeID = "Please select warehouse location type";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.warehouseLocationObj };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });

            WarehouseLocationService.saveOrUpdateWarehouseLocation(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.warehouseLocationID) {
                        this.props.history.push('/keyway-inventory/warehouse-location');
                        toast.success("Warehouse Location saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Warehouse Location updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }

                    this.setState({ saving: false, warehouseLocationObj: response.data });
                }

            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            });
        }
    }

    

    render() {
        let { warehouseLocationObj, formErrors, loading, warehouseLocationID, warehouses, warehouseLocationTypes } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/keyway-inventory/warehouse-location')}>Warehouse Location</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!warehouseLocationID ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Warehouse Location</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input
                                                invalid={formErrors.name !== undefined}
                                                name="name"
                                                type="text"
                                                value={warehouseLocationObj.name || ''}
                                                onChange={this.handleChange}
                                                placeholder="write name here..." />
                                            <FormText color="danger">{formErrors.name}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Code</Label>
                                            <Input
                                                invalid={formErrors.code !== undefined}
                                                type="text"
                                                name="code"
                                                value={warehouseLocationObj.code || ''}
                                                onChange={(e) => this.handleChange(e)}
                                                placeholder="write code here..." />

                                            <FormText color="danger">{formErrors.code}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Warehouse</Label>
                                            <Input
                                                invalid={formErrors.warehouseID !== undefined}
                                                name="warehouseID"
                                                type="select"
                                                value={warehouseLocationObj.warehouseID}
                                                onChange={this.handleChange}>
                                                <option value="">Select Warehouse</option>
                                                {warehouses.map((warehouse, index) =>
                                                    <option key={index} value={warehouse.id}>{warehouse.name}</option>
                                                )}
                                            </Input>
                                            <FormText color="danger">{formErrors.warehouseID}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Warehouse Location Type</Label>
                                            <Input
                                                invalid={formErrors.warehouseLocationTypeID !== undefined}
                                                name="warehouseLocationTypeID"
                                                type="select"
                                                value={warehouseLocationObj.warehouseLocationTypeID}
                                                onChange={this.handleChange}>
                                                <option value="">Select Warehouse Location Type</option>
                                                {warehouseLocationTypes.map((warehouseLocationType, index) =>
                                                    <option key={index} value={warehouseLocationType.id}>{warehouseLocationType.name}</option>
                                                )}
                                            </Input>
                                            <FormText color="danger">{formErrors.warehouseLocationTypeID}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                               
                            </Form>

                        }
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving} >

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
