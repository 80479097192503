import React, { Component } from "react"
import { getFinancialYearList, findIndex } from "../services/CommonService";
import Select from 'react-select';
import { isEmpty } from 'lodash';

export default class FinancialYearMultipleDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYears:[],
            selected: [],
            options: [],
        };
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.fetchOptions();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedYears) {
            if (!this.state.selectedYears.every(x => nextProps.selectedYear.indexOf(x) > -1)) {
                this.setState({
                    selectedYears: nextProps.selectedYears
                }, () => {
                    this.fetchOptions();
                })
            }
        }
        
    }

    fetchOptions() {
        let options = [];
        let selectedOption = [];
        let option = {};
        let  selected =[];
        let financialYears = getFinancialYearList(new Date(), 10);
        (financialYears || []).forEach(financialYear => {
            option = { 'label': financialYear, 'value': financialYear };
           if (this.props.selectedYear) {
                let index = findIndex(this.props.selectedYear, 'value', option.value);
                if (index > -1) {
                    if (!selectedOption.find(x => x.value == option.value) && isEmpty(selected) || (selected && selected.find(x => x.value !== option.value))) {
                        selectedOption.push(option); 
                        
                    }
                }
            }
            options.push(option);
        });
        if (selectedOption) {
            this.handleSelect(selectedOption, "selected");
        }
       
  
        this.setState({ options });
    }

    handleSelect(selectedOption, event) {
        switch (event) {
            case "selected":
                this.setState({ selected: selectedOption, selectedYears: selectedOption });
                break;
            case "onChange":
            default:
                this.setState({ selected: selectedOption, selectedYears: selectedOption }, () => {
                    this.props.handleChange(selectedOption);
                });

        }
    };

    render() {
        let { selected, options } = this.state;
        return (
            <Select
                options={options}
                value={selected}
                placeholder={"Select FY Year"}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                closeMenuOnSelect={true}
                isSearchable={false}
                isMulti={true}
            />
        );
    }
}