import axios from './axios';

class StockInventoryPriceService {

    static Instance() {
        return new StockInventoryPriceService();
    }

    saveOrUpdateStockInventoryPrice(prodCode, req) {
        return axios.post('api/stock-inventory-price/save?prodCode=' + prodCode, req);
    }

    getOnestockInventoryPriceByStock(prodCode) {
        return axios.get('api/stock-inventory-price/one/by-stock/?prodCode=' + prodCode);
    }

}

export default StockInventoryPriceService.Instance();