import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Button, Spinner, Input, Label, Row, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from 'react-select/async';
import { getDateString } from '../../services/CommonService';
import moment from 'moment';

export default class ManageHolidayModal extends Component {
    constructor(props) {
        super(props);
        const { item, stateData } = this.props;
        this.state = {
            date: item?.date ? new Date(item.date) : null,
            description: item?.description ? item.description : "",
            state: item?.state ? item.state : "",
            stateData: stateData ? stateData : "",
            errors: {
                description: "",
                state: "",
                date: ""
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    loadOptions(inputValue, callback) {
        const { stateData } = this.props;

        if (!stateData) {
            callback([]);
            return;
        }

        const formattedOptions = stateData.map(stateItem => ({
            label: stateItem.label.toUpperCase(),
            value: stateItem.value
        }));

        const filteredOptions = formattedOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );

        callback(filteredOptions);
    }

    handleChange(key, value) {
        this.setState({ [key]: value });
    }

    handleSubmit() {
        const { handleSubmit, item, toggle } = this.props;
        const { description, state, date } = this.state;
        const errors = {};

        if (!description) {
            errors.description = "Please enter a description.";
        }
        if (!state) {
            errors.state = "Please select a state.";
        }
        if (!date) {
            errors.date = "Please select a date.";
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return;
        }

        const newItem = {
            ...item,
            description: description,
            state: state,
            date: getDateString(date, 'DD/MM/YYYY')
        };
        handleSubmit(newItem, item === null ? "Add" : "Edit");
        toggle(false);
    }

    render() {
        const { isOpen, toggle, loading, icon } = this.props;
        const { description, state, date, errors } = this.state;

        return (
            <Modal isOpen={isOpen} size="md" toggle={() => toggle(!isOpen)} modalClassName="modal-top">
                <ModalHeader>
                    {this.props.item ? (
                        <h4>Edit Holiday</h4>
                    ) : (
                        <h4>Add Holiday</h4>
                    )}
                </ModalHeader>
                <ModalBody>

                    <Col className="text-left pb-4">
                        {loading ? <Spinner color={"primary"} /> : (icon ? <i className={icon} aria-hidden="true" /> : null)}
                        <Row className="mb-2">
                            <div style={{ "width": "100%" }} className="text-left">
                                <Label htmlFor="state">State:</Label>
                                <AsyncSelect
                                    name="state"
                                    id="state"
                                    value={state ? { label: state, value: state } : null}
                                    onChange={(selectedOption) => this.handleChange("state", selectedOption.value)}
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    styles={{ "width": "100%" }}
                                />
                                <div className="text-left text-danger">{errors.state}</div>
                            </div>
                        </Row>
                        <Row className="mb-2">
                            <div style={{ "width": "100%" }} className="text-left">
                                <Label htmlFor="date">Date:</Label>
                                <DatePicker
                                    className="form-control"
                                    selected={date ? moment(date, 'DD/MM/YYYY').toDate() : null}
                                    onChange={(newDate) => this.handleChange("date", newDate)}
                                    selectedStart
                                    startDate={date}
                                    isClearable
                                    placeholderText="Start Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                    dateFormat="dd/MM/yyyy"
                                />
                                <div className="text-left text-danger">{errors.date}</div>
                            </div>
                        </Row>
                        <Row className="mb-2">
                            <div style={{ "width": "100%" }} className="text-left">
                                <Label htmlFor="description">Description:</Label>

                                <Input
                                    className="form-control"
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    value={description}
                                    onChange={(e) => this.handleChange("description", e.target.value)}
                                />
                                <div className="text-left text-danger">{errors.description}</div>
                            </div>
                        </Row>
                    </Col>

                    <div className={"text-right mt-2"}>
                        <Button size={"sm"} color='primary' onClick={this.handleSubmit}>
                            <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                            Save
                        </Button>{' '}
                        <Button color='secondary' size={"sm"} onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" />
                            Cancel
                        </Button>{' '}
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
