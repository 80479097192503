import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button, TextArea, Collapse, InputGroup, InputGroupAddon
} from 'reactstrap';

import {convertUtcDateToLocalDate,handleErrorMessage} from '../services/CommonService';
import CommonNoteService from "../services/CommonNoteService";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';


export default class CommonNote extends Component {


    constructor(props) {
        super(props);

        this.state = {

            workingNote: "",
            collapse: false
        };

        this.commonNoteService = new CommonNoteService();
        this.toggle = this.toggle.bind(this);
        this.handleWorkingNoteChange = this.handleWorkingNoteChange.bind(this);
        this.handleWorkingNoteSubmit = this.handleWorkingNoteSubmit.bind(this);

    }

    toggle() {
        this.setState(state => ({collapse: !state.collapse}));
    }

    handleWorkingNoteChange(e) {
        const {value} = e.target;
        this.setState({workingNote: value});
    }

    handleWorkingNoteSubmit(e) {
        e.preventDefault();

        let data = {
            entityID: this.props.entityID,
            entityName: this.props.entityName,
            note: this.state.workingNote
        };
        let self = this;
        this.commonNoteService.saveCommonNote(data).then(response => {
            let note = response.data;

            //refresh
            self.props.refresh(data.entityID);
            self.setState(state => ({workingNote: "", collapse: false}));

        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }


    render() {
        let {workingNote, collapse} = this.state;
        let {commonNotes} = this.props;

        return (
            <div>

                <Row className={"mt-3"}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={"mt-2 mb-3"}>
                            {(() => {
                                if (!collapse) {
                                    return (
                                        <div className="text-left">
                                            <Button className="btn btn-block"
                                                    color="primary"
                                                    onClick={this.toggle}>

                                                <i className="fa fa-plus"/>
                                                &nbsp;Add note
                                            </Button>
                                        </div>
                                    )
                                }
                            })()}

                            <Collapse isOpen={this.state.collapse}>
                                <Form>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input className="form-control"
                                                   onChange={this.handleWorkingNoteChange}
                                                   type="textarea"
                                                   name="workingNote"
                                                   value={workingNote}
                                                   placeholder="Write note here..."/>
                                            <InputGroupAddon addonType="append">
                                                <Button color='primary'
                                                        className="btn btn-sm"
                                                        onClick={this.handleWorkingNoteSubmit}><i className="fa fa-plus"
                                                                                                  aria-hidden="true"/>&nbsp;
                                                    Add</Button>
                                            </InputGroupAddon>

                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </Collapse>

                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ul style={{padding: "unset"}}>


                            {(commonNotes || []).sort(function (n1, n2) {
                                return n2.id - n1.id;
                            }).map((commonNote, index) => {
                                return (
                                    <li key={index}>
                                        <div className={"lead-remark-list-item"}>
                                            <div className={"lead-remark-list-item-text"}>
                                                <div>
                                            <span className="text-muted">
                                                <span>
                                                    <i className="fa fa-user" aria-hidden="true"/>
                                                    &nbsp; {commonNote.createdByUserName}
                                                </span>
                                                <small>
                                                    &nbsp;
                                                    |&nbsp;{moment(commonNote.createdDate).fromNow()}</small>
                                            </span>
                                                </div>
                                                <span>{commonNote.note}</span></div>
                                        </div>
                                    </li>
                                );
                            })}


                        </ul>
                    </Col>
                </Row>


                <ToastContainer/>
            </div>
        )
    }
}