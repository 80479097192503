import React, {Component} from 'react';

import {Link} from "react-router-dom";
import PurchaseHistoryService from '../../services/PurchaseHistoryService';
import {handleErrorMessage, isEmpty} from '../../services/CommonService';
import {Card, CardBody, CardHeader, Col, Table} from "reactstrap";
import NumberFormat from "react-number-format";

export default class QuoteCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            isLoading: true,
        };
        this.purchaseHistoryService = new PurchaseHistoryService();
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.purchaseHistoryService.getCustomerQuoteStats().then(data => {
            let stats = [
                {
                    label: "Active",
                    value: isEmpty(data["active"], "primitive") ? 0 : data["active"]
                },
                {
                    label: "Completed",
                    value: isEmpty(data["completed"], "primitive") ? 0 : data["completed"]
                },
                {
                    label: "Cancelled",
                    value: isEmpty(data["cancelled"], "primitive") ? 0 : data["cancelled"]
                }
            ];
            this.setState({stats, isLoading: false})
        }).catch(error=>{
            console.error(handleErrorMessage(error));
        })
    }

    render() {
        const {stats, isLoading} = this.state;
        if (isLoading || (stats && isEmpty(stats))) {
            return null;
        }
        return (
            <Col xl={3} lg={6} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Quotes
                            <span style={{float: "right"}}>
                            <Link to="/sales/quotes"><i className={"fa fa-pencil"}/></Link>
                        </span></h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} className={"mb-0"}>
                            <tbody>
                            {stats.map((stat, index) => {
                                    return <tr key={index}>
                                        <td>{stat.label}</td>
                                        <td className={"text-right"}>
                                            <strong>
                                                <NumberFormat value={stat.value}
                                                              displayType={'text'}
                                                              thousandSeparator={true}/>
                                            </strong>
                                        </td>
                                    </tr>
                                }
                            )}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>);
    }
}