import React, { Component } from 'react';
import NumberFormat from "react-number-format";
import Pluralize from 'react-pluralize'
import PropTypes from 'prop-types';
import {STOCK_QTY_FORMAT} from "../../store/AppConstants";

export default class StockQtyFormat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPoManage: false
        }
    }

    componentDidMount() {
        if (this.props.isPoManage) {
            this.setState({ isPoManage: this.props.isPoManage });
        }
    }

    render() {
        let { isPoManage } = this.state;
        let result = null;

        const MASTER_DATA = {
            eachUnitName: ((this.props.eachUnitName && this.props.eachUnitName !== "0") ? this.props.eachUnitName : "NA"),
            whUnitName: ((this.props.whUnitName && this.props.whUnitName !== "0") ? this.props.whUnitName : "NA"),
            whUnitQty: ((this.props.whUnitQty && this.props.whUnitQty !== 0) ? this.props.whUnitQty : 1),
        };
        let qty = this.props.qty;
        let isSecondaryUnitNeeded = (MASTER_DATA.whUnitQty !== 1 && MASTER_DATA.whUnitName !== MASTER_DATA.eachUnitName);

        if (qty || isPoManage) {
            switch (this.props.qtyType) {
                case "WH":
                    switch (this.props.format) {
                        case STOCK_QTY_FORMAT.each:
                            let value = qty * MASTER_DATA.whUnitQty;
                            result = <>
                                <NumberFormat className="font-weight-bold"
                                    value={value}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    thousandSeparator={true}
                                    allowNegative={true}
                                />
                                <Pluralize
                                    className="ml-1"
                                    showCount={false}
                                    count={value}
                                    singular={MASTER_DATA.eachUnitName} />
                            </>;
                            break;
                        case STOCK_QTY_FORMAT.packQty_of_PackSize:
                            result = <div>
                                <NumberFormat className="font-weight-bold"
                                    value={qty}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    thousandSeparator={true}
                                    allowNegative={true}
                                />
                                <Pluralize
                                    className="ml-1"
                                    showCount={false}
                                    count={qty}
                                    singular={MASTER_DATA.whUnitName} />
                                {
                                    isSecondaryUnitNeeded
                                        ? <div>
                                            <NumberFormat className="font-weight-bold"
                                                value={MASTER_DATA.whUnitQty}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={" of "}
                                            />
                                            <Pluralize
                                                className="ml-1"
                                                showCount={false}
                                                count={MASTER_DATA.whUnitQty}
                                                singular={MASTER_DATA.eachUnitName} />
                                        </div>
                                        : null
                                }
                            </div>;
                            break;
                    }
                    break;
                case "Each":
                    switch (this.props.format) {
                        case STOCK_QTY_FORMAT.each:
                            result = <>
                                <NumberFormat className="font-weight-bold"
                                    value={qty}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    thousandSeparator={true}
                                    allowNegative={true}
                                />
                                <Pluralize
                                    className="ml-1"
                                    showCount={false}
                                    count={qty}
                                    singular={MASTER_DATA.eachUnitName} />
                            </>;
                            break;
                        case STOCK_QTY_FORMAT.packQty_of_PackSize:
                            result = <div>
                                <NumberFormat className="font-weight-bold"
                                    value={(qty / MASTER_DATA.whUnitQty)}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    thousandSeparator={true}
                                    allowNegative={true}
                                />
                                <Pluralize
                                    className="ml-1"
                                    showCount={false}
                                    count={qty}
                                    singular={MASTER_DATA.whUnitName} />
                                {
                                    isSecondaryUnitNeeded
                                        ? <div>
                                            <NumberFormat className="font-weight-bold"
                                                value={MASTER_DATA.whUnitQty}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={" of "}
                                            />
                                            <Pluralize
                                                className="ml-1"
                                                showCount={false}
                                                count={MASTER_DATA.whUnitQty}
                                                singular={MASTER_DATA.eachUnitName} />
                                        </div>
                                        : null
                                }
                            </div>;
                            break;
                    }
                    break;
            }
        } else {
            if (this.props.emptyPlaceholderText) {
                result = this.props.emptyPlaceholderText;
            }
        }
        return result;
    }
}

StockQtyFormat.propTypes = {
    eachUnitName: PropTypes.string.isRequired,
    whUnitName: PropTypes.string.isRequired,
    whUnitQty: PropTypes.number.isRequired,
    qtyType: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    emptyPlaceholderText: PropTypes.string.isRequired,
    isPoManage: PropTypes.bool.isRequired
};




