import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row, Spinner} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import StockService from '../../../services/StockService';
import CreditorStockFormModal from "../../../components/stock/CreditorStockFormModal";
import {Link} from "react-router-dom";
import queryString from 'query-string';
import NumberFormat from "react-number-format";
import ConfirmModal from '../../../components/modal/ConfirmModal';
import { handleErrorMessage } from '../../../services/CommonService';
export default class StockEnquiryPageSupplierTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stock: {},
            suppliers: [],
            isLoading: false,
            isLoadingSubmitPreferredSupplier: false,
            isOpenModal: false,
            selectedSupplier: {},
            isOpenDeleteConfirmModal: false,
            deleteItem: null,
            loading: {
                isUploadingAttachment: false,
                isUploadingBasicInfo: false,
                deleting: false,
                list: false

            }
            
        };
        this.stockService = new StockService();
        this.getStockSuppliers = this.getStockSuppliers.bind(this);
        this.handleSubmitPreferredSupplier = this.handleSubmitPreferredSupplier.bind(this);
        this.refresh = this.refresh.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }


    refresh() {
        this.setState({isOpenModal: false});
        this.getStockSuppliers();
    }

    componentDidMount() {
        if (this.props.stock && this.props.stock.prodCode) {
            let {stock} = this.state;
            if (!stock || stock.prodCode !== this.props.stock.prodCode) {
                this.setState({stock: this.props.stock}, () => {
                    this.getStockSuppliers();
                })
            }
        }
    }


    getStockSuppliers() {
        let {suppliers, stock} = this.state;

        if (stock.prodCode) {
            this.setState({isLoading: true});
            this.stockService.getStockSuppliers(stock.prodCode).then(response => {
                suppliers = [];
                if (response.data.supplier) {
                    response.data.supplier.isPreferred = true;
                    response.data.supplier.stockVariable = "supplierCode";
                    suppliers.push(response.data.supplier);
                }
                if (response.data.supplier1) {
                    response.data.supplier1.isPreferred = false;
                    response.data.supplier1.stockVariable = "supplierCode1";
                    suppliers.push(response.data.supplier1);
                }
                if (response.data.supplier2) {
                    response.data.supplier2.isPreferred = false;
                    response.data.supplier2.stockVariable = "supplierCode2";
                    suppliers.push(response.data.supplier2);
                }
                this.setState({ isLoading: false, suppliers });
                for (let i = 0; i < suppliers.length; i++) {
                    this.getCreditorStock(stock.prodCode, suppliers[i].accountID, i);
                }
            }).catch(error => {
                this.setState({isLoading: false});
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleSubmitPreferredSupplier(prodCode, accountID) {
        this.setState({isLoadingSubmitPreferredSupplier: true});
        this.stockService.setAsPreferredSupplier(prodCode, accountID).then(response => {
            let {suppliers} = this.state;
            (suppliers || []).forEach(supplier => {
                if (supplier.accountID === accountID) {
                    supplier.isPreferred = true;
                } else {
                    supplier.isPreferred = false;
                }
            });
            toast.success("Updated!");
            this.setState({isLoadingSubmitPreferredSupplier: false, suppliers});
        }).catch(error => {
            this.setState({isLoadingSubmitPreferredSupplier: false});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCreditorStock(prodCode, accountID, index) {
        let { suppliers } = this.state;
        if (prodCode && accountID) { 
            this.stockService.getCreditorStock(prodCode, accountID).then(response => {
                if (response.data && suppliers[index]) {
                    suppliers[index].packQty = response.data.packQty;
                    this.setState({ suppliers });
                }
            })
        }
    }

    toggleModal(data) {
        this.setState({isOpenModal: data});
    }

    openModal(selectedSupplier) {
        this.setState({selectedSupplier, isOpenModal: true});
    }


    render() {
        let { stock, suppliers, isLoading, isLoadingSubmitPreferredSupplier, selectedSupplier, isOpenModal } = this.state;
        return (
            <div>
                {
                    isLoading
                        ? <Spinner color={"primary"}/>
                        : <Row>
                            {
                                (suppliers || []).map((supplier, supplierIndex) => {
                                    return <Col xl={4} lg={4} md={4} sm={12} xs={12} key={supplierIndex}>
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                                        <h6 className="mb-0">
                                                            {supplier.isPreferred
                                                                ? <span>Preferred Supplier
                                                                    <i className="fa fa-star ml-2" aria-hidden="true" />
                                                                </span>
                                                                : <Button color={"link"}
                                                                    size={"sm"}
                                                                    className={"p-0"}
                                                                    disabled={isLoadingSubmitPreferredSupplier}
                                                                    onClick={() => this.handleSubmitPreferredSupplier(stock.prodCode, supplier.accountID)}>
                                                                    {isLoadingSubmitPreferredSupplier
                                                                        ? <Spinner color={"primary"} size={"sm"} className={"mr-2"} />
                                                                        : null
                                                                    }Set as Preferred Supplier
                                                                </Button>
                                                            }
                                                        </h6>
                                                    </Col>

                                                    <Col xl={4} lg={4} md={4} sm={4} xs={4} className={"text-right"}> {/* Changed xl={2} to xl={4} */}
                                                        <Button color={"link"} size={"sm"} className={"pt-0 pb-0"} onClick={() => this.openModal(supplier)}>
                                                            <i className="fa fa-pencil" aria-hidden="true" />
                                                        </Button>
                                                    </Col>
                                                </Row>




                                            </CardHeader>
                                            <CardBody className={"p-2"}>
                                                <ListGroup flush>
                                                    <ListGroupItem className={"p-1"} style={{minHeight: 50}}>
                                                        <strong>{supplier.accountID}</strong>
                                                        <span className="mb-0 float-right">{supplier.areaCodeDescription}</span>
                                                        <p className="mb-0">{supplier.company}</p>
                                                    </ListGroupItem>
                                                    <ListGroupItem className={"p-1"}>
                                                        <Row>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                <small className="mb-0">Supplier Ref.</small>
                                                                <div>
                                                                    <strong>{supplier.xRefCode ? supplier.xRefCode : "-"}</strong>
                                                                </div>
                                                                    <div>
                                                                    <small className="mb-0">Known As</small>
                                                                    <div>
                                                                        {stock.keywayStock && stock.keywayStock.knownAs ?
                                                                        <Link
                                                                            to={"/inventory/stock/enquiry?" + queryString.stringify({prodCode: stock.keywayStock.knownAs})}
                                                                            className="btn btn-sm btn-primary mr-2"
                                                                            style={{cursor: "pointer"}}>
                                                                            {stock.keywayStock.knownAs}
                                                                            </Link> : "-"}
                                                                    </div>
                                                                    </div> 
                                                            </Col>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}
                                                                 className={"border-left"}>
                                                                <small>Lead Time</small>
                                                                <div>
                                                                    <strong>
                                                                        {supplier.leadTime
                                                                            ? supplier.leadTime + " Days"
                                                                            : "-"
                                                                        }
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                    <ListGroupItem className={"p-1"}>
                                                        <Row>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                <small>Last Cost</small>
                                                                {
                                                                    (supplier.areaCodeDescription !== "Local")
                                                                        ? <small
                                                                            style={{float: "right"}}>{supplier.areaCodeDescription}&nbsp;(<strong>{supplier.currencyType}</strong>)
                                                                        </small>
                                                                        : null
                                                                }

                                                                <div>
                                                                    <strong>
                                                                        <NumberFormat
                                                                            value={supplier.localCost}
                                                                            displayType={'text'}
                                                                            decimalScale={4}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            prefix={'$'}
                                                                        />
                                                                    </strong>
                                                                    {
                                                                        (supplier.areaCodeDescription !== "Local")
                                                                            ? <strong style={{float: "right"}}>
                                                                                <NumberFormat
                                                                                    value={supplier.cost}
                                                                                    displayType={'text'}
                                                                                    decimalScale={4}
                                                                                    fixedDecimalScale={true}
                                                                                    thousandSeparator={true}
                                                                                    prefix={supplier.currencySymbol}
                                                                                />
                                                                            </strong>
                                                                            : null
                                                                    }

                                                                </div>
                                                                <small>Pack Qty</small>
                                                                <strong className={"float-right"}>{supplier.packQty ? supplier.packQty : 0}</strong> 
                                                            </Col>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}
                                                                 className={"border-left"}>
                                                                <small>Quote</small>
                                                                <div>
                                                                    <small><strong>Price</strong></small>
                                                                    <small className={"float-right"}><strong>MOQ</strong></small>
                                                                    </div>
                                                                    <div>
                                                                    <small>
                                                                            <NumberFormat
                                                                                value={supplier.price1}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                thousandSeparator={true}
                                                                                prefix={"$"}
                                                                        /></small>
                                                                    <small className={"float-right"}>{supplier.moQ1}</small>
                                                                   </div>
                                                                <div>
                                                                    <small>
                                                                        <NumberFormat
                                                                            value={supplier.price2}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                        /></small>
                                                                    <small className={"float-right"}>{supplier.moQ2}</small>
                                                                </div>
                                                                <div>
                                                                    <small>
                                                                        <NumberFormat
                                                                            value={supplier.price3}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                        /></small>
                                                                    <small className={"float-right"}>{supplier.moQ3}</small>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                    <ListGroupItem className={"p-1"}>
                                                        <Row>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                <small>Last PO</small>
                                                                {
                                                                    supplier.lastPurchaseOrdNum
                                                                        ? <div>
                                                                            <Link
                                                                                to={"/purchase/order/enquiry?" + queryString.stringify({ordNum: supplier.lastPurchaseOrdNum})}
                                                                                className="btn btn-sm btn-primary mr-2"
                                                                                style={{cursor: "pointer"}}>
                                                                                {supplier.lastPurchaseOrdNum}
                                                                            </Link>
                                                                            <div><strong>
                                                                                <small>
                                                                                    {supplier.lastPurchaseOrderDate}</small>
                                                                            </strong></div>
                                                                        </div>
                                                                        : <p>-</p>
                                                                }

                                                            </Col>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}
                                                                 className={"border-left"}>
                                                                <small>Last Received</small>
                                                                {supplier.lastReceivedPurchaseOrdNum
                                                                    ? <div>
                                                                        <Link
                                                                            to={"/purchase/order/enquiry?" + queryString.stringify({ordNum: supplier.lastReceivedPurchaseOrdNum})}
                                                                            className="btn btn-sm btn-primary mr-2"
                                                                            style={{cursor: "pointer"}}>
                                                                            {supplier.lastReceivedPurchaseOrdNum}
                                                                        </Link>
                                                                        <div><strong>
                                                                            <small>{supplier.lastReceivedDate}</small>
                                                                        </strong></div>
                                                                    </div>
                                                                    : <p>-</p>}

                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                })
                            }
                            {
                                ((suppliers || []).length < 3)
                                    ? <Col xl={4} lg={4} md={4} sm={12} xs={12} className="align-self-center text-center">
                                        <Button color={"primary"} size={"sm"}
                                                onClick={() => this.openModal({})}>
                                            <i className="fa fa-plus mr-2"
                                               aria-hidden="true"/>New Supplier</Button>
                                    </Col>
                                    : null
                            }
                        </Row>
                }
                {
                    isOpenModal ?
                        <CreditorStockFormModal
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            refresh={this.refresh}
                            prodCode={this.props.stock.prodCode}
                            accountID={selectedSupplier ? selectedSupplier.accountID : ""}
                            loading={this.state.loading}
                            stockVariable={selectedSupplier ? selectedSupplier.stockVariable : ""}

                        /> : null
                }
                <ToastContainer/>
            </div>
        );
    }
}