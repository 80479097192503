import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

export default class ImportNotesModal extends Component {
    render() {
        let { isOpen, toggle, selected } = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={(e) => toggle('', !isOpen)}>
                <ModalHeader toggle={(e) => toggle('', !isOpen)}>Import Notes</ModalHeader>
                <ModalBody>
                    <div className="mb-2">
                        {ReactHtmlParser(selected)}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} onClick={(e) => toggle('', !isOpen)}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}