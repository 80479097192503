import React, {Component} from 'react';
import {
    Row, Col, Input, Button, Spinner, FormGroup, Label
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import FileSaver from 'file-saver';
import AttachmentService from '../../services/AttachmentService';
import {handleErrorMessage} from '../../services/CommonService';
import StockService from '../../services/StockService';
import keywayStockService from '../../services/KeywayStockService';
import {isEmpty} from 'lodash';

const baseUrl = window.location.origin;

export default class UploadStockThumbnails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNewStock: props.isNewStock,
            thumbnailImageFile: null,
            highQualityImageFile: null,
            keywayStock: null,
            downloading: false,
            loading: false,
            saving: false,
        };

        this.stockService = new StockService();
        this.attachmentService = new AttachmentService();
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getStock();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.prodCode) {
            if ((isEmpty(this.state.keywayStock) || (this.state.keywayStock.prodCode !== nextProps.prodCode))) {
                this.getStock(nextProps.prodCode);
            }
        }
    }

    getStock(prodCode) {
        let {isNewStock} = this.props;
        if (prodCode && !isNewStock) {
            let {keywayStock} = this.state;
            this.setState({loading: true});
            this.stockService.getStock(prodCode).then(response => {
                keywayStock = (response.data && response.data.keywayStock) ? response.data.keywayStock : {};
                this.setState({keywayStock, loading: false});
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(change, key) {
        this.setState({[key]: change});
    }


    validateRequest(thumbnailImageFile, highQualityImageFile) {
        return !(!thumbnailImageFile && !highQualityImageFile);
    }

    handleSubmit() {
        let {thumbnailImageFile, highQualityImageFile, keywayStock} = this.state;

        let isValid = this.validateRequest(thumbnailImageFile, highQualityImageFile);
        if (!isValid) {
            toast.error("Invalid request", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        let data = new FormData();
        data.append('prodCode', keywayStock.prodCode);
        data.append('thumbnailImageFile', thumbnailImageFile);
        data.append('highQualityImageFile', highQualityImageFile);

        this.setState({saving: true});
        keywayStockService.uploadkeywayStockDoc(data).then(response => {
            this.setState({saving: false, stockDoc: {}});
            toast.success("Updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.getStock(keywayStock.prodCode);
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({saving: false});
        });
    }

    onDownloadAttachment(stockAttachment) {
        this.attachmentService.DownloadAttachment(stockAttachment.id).then(response => {
            FileSaver.saveAs(response.data, stockAttachment.description);
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {loading, keywayStock, saving} = this.state;
        let {toggleCloseModal} = this.props;

        return (
            <div>
                <hr/>
                {loading
                    ? <Spinner color="primary"/>
                    : keywayStock
                        ? <div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormGroup>
                                        <Label>Thumbnail Image(png/jpg)</Label>
                                        <Input type="file" name="file"
                                               onChange={(e) => this.handleChange(e.target.files[0], "thumbnailImageFile")}
                                               aria-label="File browser example"/>
                                    </FormGroup>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormGroup>
                                        <Label>High Quality Image(png/jpg)</Label>
                                        <Input type="file" name="file"
                                               onChange={(e) => this.handleChange(e.target.files[0], "highQualityImageFile")}
                                               aria-label="File browser example"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    {(keywayStock.thumbnailImageAttachment)
                                        ? <div className="p-2 text-center">
                                            {keywayStock.thumbnailImageAttachment.fileName
                                                ?
                                                <img
                                                    style={{
                                                        width: '150px',
                                                        height: '150px'
                                                    }}
                                                    title="Click here to download thumbnail image"
                                                    onClick={() => this.onDownloadAttachment(keywayStock.thumbnailImageAttachment)}
                                                    src={baseUrl + "/api/file/generate/stock/keyway?FileName=" + keywayStock.thumbnailImageAttachment.fileName}/>
                                                : null}
                                        </div>
                                        : null}
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    {
                                        keywayStock.highQualityImageAttachment
                                            ? <div className="p-2 text-center">
                                                {keywayStock.highQualityImageAttachment.fileName
                                                    ?
                                                    <img
                                                        style={{
                                                            width: '150px',
                                                            height: '150px'
                                                        }}
                                                        title="Click here to download high quality image"
                                                        onClick={() => this.onDownloadAttachment(keywayStock.highQualityImageAttachment)}
                                                        src={baseUrl + "/api/file/generate/stock/keyway?FileName=" + keywayStock.highQualityImageAttachment.fileName}/>
                                                    : null}
                                            </div>
                                            : null}
                                </Col>

                            </Row>
                            <hr/>
                            <div className={"text-right mt-2"}>
                                <Button color={"primary"} size={"sm"}
                                        onClick={() => this.handleSubmit()}
                                        disabled={saving}>
                                    {saving
                                        ? <Spinner size="sm"
                                                   className={"mr-2"}
                                                   color={"light"}
                                        />
                                        : <i className="fa fa-floppy-o mr-2"/>}
                                    {saving ? "Saving" : "Save"}
                                </Button>
                                <Button color='secondary' className='ml-2' size={"sm"}
                                        onClick={() => toggleCloseModal()}>
                                    <i className="fa fa-times mr-2"/>
                                    Cancel</Button>
                            </div>
                        </div>
                        : null
                }
                <ToastContainer/>
            </div>
        );
    }
}
