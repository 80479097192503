import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody,
    Row, Col, Table,
    Button,
    Spinner, Input, Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';
import FileSaver from 'file-saver';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FabricService from '../../services/FabricService';
import { handleErrorMessage } from '../../services/CommonService';
import classnames from "classnames";


export default class FabricImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImport: false,
            downloading: false,
            importResponse: {},
            isDryRun: false,
        };


        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.downloadFormat = this.downloadFormat.bind(this);
    }
   

    fileChangeHandler(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    import() {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
       
        else {
            const data = new FormData();
            data.append('file', this.state.selectedFile);
            data.append('isDryRun', this.state.isDryRun);
            this.setState({ isImport: true });
               FabricService.importFabricData(data).then(response => {
                    if (response.status === 200 || response.status === "200") {
                        this.setState({ isImport: false, importResponse: response.data });
                        if (response.data.hasError) {
                            toast.error("Error!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        } else {
                            toast.success("Success!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                    }
                }).catch(error => {
                    this.setState({ isImport: false });
                    console.log(error);
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            

        }
    }

    downloadFormat() {
        this.setState({ downloading: true })
            FabricService.generateFabricDataImportFormat().then(response => {
                this.setState({ downloading: false })
                FileSaver.saveAs(response.data, "FABRIC-IMPORT-FORMAT" + ".xlsx");
            }).catch(error => {
                this.setState({ downloading: false })
                console.log(error);
            });
        
      
    }

    handleChange(key, change) {
        this.setState({ [key]: change });
    }

    render() {
        let { downloading, isImport, importResponse, isDryRun } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/tools/manage/fabrics/tab')}>Fabric List</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Import All Data</BreadcrumbItem>
                </Breadcrumb>

                <Row>
                    <Col xl={2} lg={2} md={1} sm={12} xs={12}>

                    </Col>
                    <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                <h6>Import Fabric</h6>
                            </CardHeader>
                            <CardBody>

                                <div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <h6>Select File to import: </h6>
                                            
                                            <Input type="file"
                                                name="file"
                                                id="file"
                                                onChange={this.fileChangeHandler}
                                                aria-label="Upload document" />
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button color={"link"} size={"sm"}
                                            onClick={() => this.handleChange("isDryRun", !isDryRun)}>
                                            <i className={classnames("mr-2", "fa", {
                                                "fa-check-square-o": isDryRun,
                                                "fa-square-o": !isDryRun
                                            })} />
                                            Dry run
                                        </Button>
                                        <Button color={"primary"} size={"sm"}
                                            onClick={() => this.import()}
                                            disabled={isImport}>
                                            {isImport
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"white"} />
                                                : <i className="fa fa-upload mr-2" />
                                            }
                                            Upload</Button>&nbsp;
                                        <Button color={"primary"} size={"sm"}
                                            outline={true}
                                            onClick={() => this.downloadFormat()}
                                            disabled={downloading}>{downloading
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"primary"} />
                                                : <i className="fa fa-download mr-2" />
                                            }Download template</Button>
                                    </div>

                                    {
                                        importResponse.hasError
                                            ? <div>
                                                <hr />
                                                <Table size={"sm"} hover={true} responsive>
                                                    <thead>
                                                        <tr>
                                                            <td>Row</td>
                                                            <td>Column</td>
                                                            <td>Title</td>
                                                            <td>Value</td>
                                                            <td>Validation Message</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {((importResponse.rows) || []).map(row => {
                                                            return <tr
                                                                className={row.hasError ? "table-danger" : "table-success"}>
                                                                <td>{row.rowIndex}</td>
                                                                <td>{row.columnIndex}</td>
                                                                <td>{row.inputTitle}</td>
                                                                <td>{row.inputValue}</td>
                                                                <td>{row.validationMessage}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : null
                                    }
                                </div>

                                <ToastContainer />

                            </CardBody>
                        </Card>


                    </Col>
                </Row>
            </div>
        )
    }

}