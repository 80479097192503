import React from 'react';
import {
    findIndex,
    findItemHavingMaxValue,
    getDistinctValues,
    getSorted,
    groupBy
} from "../../../../../../services/CommonService";
import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
    PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING,
    PRODUCT_BUILDER_HOSPITAL_RANGE_PART
} from "../../../../../../store/AppConstants";

const QUANTITY_MIN = 1;
const QUANTITY_MAX = 1000;
const PRICING_METHOD_CUT_OVER = 1200;
const PRICING_METHOD_MIN_PRICE_CUT_OVER = 1200;
const PRICING_METHOD_FULL_LENGTH = "FULL LENGTH";
const PRICING_METHOD_PER_MM = "PER MM";
const PRICING_METHOD_MIN_PRICE = "MIN PRICE";

class HospitalRangePartUtil {

    static Instance() {
        return new HospitalRangePartUtil()
    }

    prepareConfiguration(product) {
        let optionIndex;
        product.part = {configuration: {}};
        product.custom = {configuration: {}};

        //part
        let parts = getSorted(product.builder.stockKeyway, 'sortOrder', true);
        product.part.configuration.part = {
            options: parts,
            dropdown: {
                finalOptions: [],
                selected: {
                    value: []
                }
            },
            displayList: {
                finalOptions: [],
            }
        };
        //part colour
        product.part.configuration.colour = {
            dropdown: {
                options: getDistinctValues(product.part.configuration.part.options, "colour"),
                finalOptions: [],
                selected: {
                    value: []
                }
            }
        };
        product.part.configuration.colour.dropdown.finalOptions = product.part.configuration.colour.dropdown.options.map(o => {
            return {label: o, value: o}
        });
      /*  optionIndex = product.part.configuration.colour.dropdown.finalOptions.findIndex(o => o.value.toUpperCase() === "WHITE");
        if (optionIndex > -1) {
            product.part.configuration.colour.dropdown.selected.value = [product.part.configuration.colour.dropdown.finalOptions[optionIndex]];
        }*/

        //part Types
        product.part.configuration.partType = {
            options: [],
            finalOptions: [],
            isOpen: false
        };
        product.part.configuration.partType.options = (getDistinctValues(product.part.configuration.part.options, 'partType') || []).map(o => {
            return {label: o, value: o, isOpen: false}
        });

        //################################################

        //custom part
        let configurationIndex = findIndex(product.builder.configurations, "attributeKey", "custom");
        product.custom.configuration.partRange = {
            options: configurationIndex > -1 ? product.builder.configurations[configurationIndex].options : []
        };

        //custom colour
        configurationIndex = findIndex(product.builder.configurations, "attributeKey", "custom");
        product.custom.configuration.colour = {
            dropdown: {
                options: configurationIndex > -1 ? getDistinctValues(product.builder.configurations[configurationIndex].options, "condition1") : [],
                finalOptions: []
            }
        };
        product.custom.configuration.colour.dropdown.finalOptions = product.custom.configuration.colour.dropdown.options.map(o => {
            return {label: o, value: o}
        });

        configurationIndex = findIndex(product.builder.configurations, "attributeKey", "shape");
        product.custom.configuration.shape = {
            options: product.builder.configurations[configurationIndex].options,
            finalOptions: product.builder.configurations[configurationIndex].options,
        };

        return product;
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }
                if (!isEmpty(product.items[itemIndex].location.selected.value) &&
                    product.items[itemIndex].location.selected.value.length > 50) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than 50 characters",
                    };
                }
                product.items[itemIndex].location.formError = formError;
                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.location.selected.value === i.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.location.formError.isValid = true;
                        i.location.formError.isWarning = true;
                        i.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.location.formError.message === "Duplicate location!") {
                            i.location.formError.isValid = true;
                            i.location.formError.isWarning = false;
                            i.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].quantity.selected.value) || (product.items[itemIndex].quantity.selected.value < QUANTITY_MIN) || (product.items[itemIndex].quantity.selected.value > QUANTITY_MAX)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + QUANTITY_MIN + " and " + QUANTITY_MAX,
                    };
                }
                product.items[itemIndex].quantity.formError = formError;
                break;
            case "lengthABC":
                if ((product.items[itemIndex].lengthA.isSelectable) && ((!product.items[itemIndex].lengthA.selected.value) || (product.items[itemIndex].lengthA.selected.value < product.items[itemIndex].lengthA.min) || (product.items[itemIndex].lengthA.selected.value > product.items[itemIndex].lengthA.max))) {
                    formError = {
                        isValid: false,
                        message: "Expected A Length between " + product.items[itemIndex].lengthA.min + " and " + product.items[itemIndex].lengthA.max,
                    };
                }
                if (formError.isValid && product.items[itemIndex].lengthB.isSelectable && ((!product.items[itemIndex].lengthB.selected.value) || (product.items[itemIndex].lengthB.selected.value < product.items[itemIndex].lengthB.min) || (product.items[itemIndex].lengthB.selected.value > product.items[itemIndex].lengthB.max))) {
                    formError = {
                        isValid: false,
                        message: "Expected B Length between " + product.items[itemIndex].lengthB.min + " and " + product.items[itemIndex].lengthB.max,
                    };
                }
                if (formError.isValid && product.items[itemIndex].lengthC.isSelectable && ((!product.items[itemIndex].lengthC.selected.value) || (product.items[itemIndex].lengthC.selected.value < product.items[itemIndex].lengthC.min) || (product.items[itemIndex].lengthC.selected.value > product.items[itemIndex].lengthC.max))) {
                    formError = {
                        isValid: false,
                        message: "Expected C Length between " + product.items[itemIndex].lengthC.min + " and " + product.items[itemIndex].lengthC.max,
                    };
                }
                if (formError.isValid && (product.items[itemIndex].lengthA.isSelectable || product.items[itemIndex].lengthB.isSelectable || product.items[itemIndex].lengthC.isSelectable) && ((!product.items[itemIndex].lengthABC.selected.value) || (product.items[itemIndex].lengthABC.selected.value < product.items[itemIndex].lengthABC.min) || (product.items[itemIndex].lengthABC.selected.value > product.items[itemIndex].lengthABC.max))) {
                    let msg = "Expected";
                    msg = msg + (product.items[itemIndex].lengthA.isSelectable ? " A" : "");
                    msg = msg + (product.items[itemIndex].lengthB.isSelectable ? "+B" : "");
                    msg = msg + (product.items[itemIndex].lengthC.isSelectable ? "+C" : "");
                    msg = msg + (" Length between " + product.items[itemIndex].lengthABC.min + " and " + product.items[itemIndex].lengthABC.max);

                    formError = {
                        isValid: false,
                        message: msg
                    };
                }
                product.items[itemIndex].lengthABC.formError = formError;
                break;
            case "angle":
                if (product.items[itemIndex].angle.isSelectable && ((!product.items[itemIndex].angle.selected.value) || (product.items[itemIndex].angle.selected.value < product.items[itemIndex].angle.min) || (product.items[itemIndex].angle.selected.value > product.items[itemIndex].angle.max))) {
                    formError = {
                        isValid: false,
                        message: "Expected A-B angle between " + product.items[itemIndex].angle.min + " and " + product.items[itemIndex].angle.max,
                    };
                }
                product.items[itemIndex].angle.formError = formError;
                break;
            case "angle2":
                if (product.items[itemIndex].angle2.isSelectable && ((!product.items[itemIndex].angle2.selected.value) || (product.items[itemIndex].angle2.selected.value < product.items[itemIndex].angle2.min) || (product.items[itemIndex].angle2.selected.value > product.items[itemIndex].angle2.max))) {
                    formError = {
                        isValid: false,
                        message: "Expected B-C angle between " + product.items[itemIndex].angle2.min + " and " + product.items[itemIndex].angle2.max,
                    };
                }
                product.items[itemIndex].angle2.formError = formError;
                break;
            case "prodCode":
                if (!product.items[itemIndex].prodCode) {
                    formError = {
                        isValid: false,
                        message: "Please select item here",
                    };
                }
                product.items[itemIndex].formError.prodCode = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        let result = true;
        switch (product.items[itemIndex].itemType) {
            case  PRODUCT_BUILDER_HOSPITAL_RANGE_PART:
            case  PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING:
                result = product.items[itemIndex].quantity.formError.isValid
                    && product.items[itemIndex].formError.prodCode.isValid;
                break;
            case  PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM:
                result = product.items[itemIndex].quantity.formError.isValid
                    && product.items[itemIndex].lengthABC.formError.isValid
                    && product.items[itemIndex].angle.formError.isValid
                    && product.items[itemIndex].angle2.formError.isValid
                    && product.items[itemIndex].formError.prodCode.isValid;
        }
        return result;
    }


    updateRules(key, product, itemIndex, order) {

        switch (key) {
            case "lengthABC":
                product.items[itemIndex].lengthA.isSelectable = false;
                product.items[itemIndex].lengthB.isSelectable = false;
                product.items[itemIndex].lengthC.isSelectable = false;

                if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                    let maxLength = findItemHavingMaxValue((product.items[itemIndex].configuration.partRange.selected.parts || []), 'length');
                    if (product.items[itemIndex].configuration.shape) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                                product.items[itemIndex].lengthA.min = 800;
                                product.items[itemIndex].lengthB.min = 0;
                                product.items[itemIndex].lengthC.min = 0;

                                product.items[itemIndex].lengthA.max = maxLength;
                                product.items[itemIndex].lengthB.max = maxLength;
                                product.items[itemIndex].lengthC.max = maxLength;

                                product.items[itemIndex].lengthA.isSelectable = true;
                                product.items[itemIndex].lengthB.isSelectable = false;
                                product.items[itemIndex].lengthC.isSelectable = false;
                                break;
                            case "J Shape":
                            case "L Shape":
                                product.items[itemIndex].lengthA.min = 400;
                                product.items[itemIndex].lengthB.min = 400;
                                product.items[itemIndex].lengthC.min = 0;

                                product.items[itemIndex].lengthA.max = maxLength;
                                product.items[itemIndex].lengthB.max = maxLength;
                                product.items[itemIndex].lengthC.max = maxLength;

                                product.items[itemIndex].lengthA.isSelectable = true;
                                product.items[itemIndex].lengthB.isSelectable = true;
                                product.items[itemIndex].lengthC.isSelectable = false;
                                break;
                            case "U Shape":
                                product.items[itemIndex].lengthA.min = 400;  //800 (old value)
                                product.items[itemIndex].lengthB.min = 400;
                                product.items[itemIndex].lengthC.min = 400;

                                product.items[itemIndex].lengthA.max = maxLength;
                                product.items[itemIndex].lengthB.max = maxLength;
                                product.items[itemIndex].lengthC.max = maxLength;

                                product.items[itemIndex].lengthA.isSelectable = true;
                                product.items[itemIndex].lengthB.isSelectable = true;
                                product.items[itemIndex].lengthC.isSelectable = true;
                                break;
                        }
                    }
                    product.items[itemIndex].lengthABC.min = (product.items[itemIndex].lengthA.min + product.items[itemIndex].lengthB.min + product.items[itemIndex].lengthC.min);
                    product.items[itemIndex].lengthABC.max = maxLength;
                }
                product.items[itemIndex].lengthA.selected.value = product.items[itemIndex].lengthA.isSelectable ? product.items[itemIndex].lengthA.selected.value : 0;
                product.items[itemIndex].lengthB.selected.value = product.items[itemIndex].lengthB.isSelectable ? product.items[itemIndex].lengthB.selected.value : 0;
                product.items[itemIndex].lengthC.selected.value = product.items[itemIndex].lengthC.isSelectable ? product.items[itemIndex].lengthC.selected.value : 0;


                break;
            case "angle":
                product.items[itemIndex].angle.isSelectable = false;
                if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                    if (product.items[itemIndex].configuration.shape) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                                product.items[itemIndex].angle.isSelectable = false;
                                break;
                            case "J Shape":
                            case "L Shape":
                            case "U Shape":
                                product.items[itemIndex].angle.isSelectable = true;
                                break;
                        }
                        product.items[itemIndex].angle.selected.value = product.items[itemIndex].angle.isSelectable ? product.items[itemIndex].angle.selected.value : 0;
                    }
                }
                break;
            case "angle2":
                product.items[itemIndex].angle2.isSelectable = false;
                if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                    if (product.items[itemIndex].configuration.shape) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                            case "J Shape":
                            case "L Shape":
                                product.items[itemIndex].angle2.isSelectable = false;
                                break;
                            case "U Shape":
                                product.items[itemIndex].angle2.isSelectable = true;
                                break;
                        }
                        product.items[itemIndex].angle2.selected.value = product.items[itemIndex].angle2.isSelectable ? product.items[itemIndex].angle2.selected.value : 0;
                    }
                }
                break;
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks(product, itemIndex, order);
        }
        return product;
    }

    getStockItemInstance() {
        return cloneDeep({
            id: null,
            prodCode: "",
            price: 0,
            flatPrice: 0,
            quantityMultiplier: 1,
            keywayMeasurementUnit: "unit",
            swishMeasurementUnit: "unit",
            keywayConversionFactor: 1,
            swishConversionFactor: 1,
            isVisibleInPartList: true,
            isDeductionApplicable: false,
            description: "",
            width: 0,
            drop: 0,
            swishCalculatedQty: 1,
            keywayCalculatedQty: 1,
            wastagePercent: 0,
            attribute: "",
            label: "",
            attributeRowSpan: 0,
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
            isParentItem: false
        });
    }

    updateStocks(product, itemIndex, order) {
        let stocks = [];
        let stock = null, temp, optionIndex, deduction, condition1;

        switch (product.items[itemIndex].itemType) {
            case PRODUCT_BUILDER_HOSPITAL_RANGE_PART:
                //base stock
                stock = this.getStockItemInstance();
                stock.prodCode = product.items[itemIndex].prodCode;
                stock.price = product.items[itemIndex].sellQtyPrice;
                stock.keywayMeasurementUnit = product.items[itemIndex].sellUnitName;
                stock.swishMeasurementUnit = product.items[itemIndex].sellUnitName;
                stock.isParentItem = true;
                stocks.push(stock);
                break;
            case PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM:


                //1. parent item stock
                stock = this.getStockItemInstance();
                stock.prodCode = "KW-HOSPITAL";
                stock.isParentItem = true;
                stock.width = product.items[itemIndex].lengthABC.selected.value;
                stocks.push(stock);

                //2. part range stock
                stock = this.getStockItemInstance();
                temp = this.calculateCustomItemUnitPrice(product, itemIndex);

                product.items[itemIndex].prodCode = temp.prodCode;
                product.items[itemIndex].description = temp.description;
                product.items[itemIndex].partType = temp.partType;
                product.items[itemIndex].eachUnitName = temp.eachUnitName;
                product.items[itemIndex].sellUnitQty = temp.sellUnitQty;
                product.items[itemIndex].sellUnitName = temp.sellUnitName;

                deduction = this.updateDeduction("track", product, itemIndex);
                product.items[itemIndex].configuration.partRange.selected.deduction = deduction;

                stock.prodCode = temp.prodCode;
                stock.price = temp.price;
                stock.keywayMeasurementUnit = temp.sellUnitName;
                stock.swishMeasurementUnit = temp.sellUnitName;
                stock.isDeductionApplicable = true;
                stock.width = product.items[itemIndex].lengthABC.selected.value;
                stock.isParentItem = false;
                stocks.push(stock);


                //3. bends & cut fee
                optionIndex = product.items[itemIndex].configuration.shape.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.shape.selected.value.value);
                if (optionIndex > -1) {
                    switch (order.accountID)
                    {
                        case "06022":
                            condition1 = "06022";
                            break;
                        default:
                            condition1 = "default";
                            break;
                    }

                    (product.items[itemIndex].configuration.shape.finalOptions[optionIndex].sets || []).forEach(set => {
                        if (set.condition1 ? set.condition1 === condition1 : true){
                            (set.stocks || []).forEach(s => {
                                stock = this.getStockItemInstance();
                                stock.prodCode = s.prodCode;
                                stock.price = s.price;
                                stock.flatPrice = s.flatPrice;
                                stock.quantityMultiplier = s.quantityMultiplier;
                                stock.keywayMeasurementUnit = s.keywayMeasurementUnit;
                                stock.swishMeasurementUnit = s.keywayMeasurementUnit;
                                stock.keywayConversionFactor = s.keywayConversionFactor;
                                stock.swishConversionFactor = s.swishConversionFactor;
                                stock.isVisibleInPartList = s.isVisibleInPartList;
                                stock.isDeductionApplicable = s.isDeductionApplicable;
                                stock.description = s.description;
                                stock.isParentItem = false;
                                stocks.push(stock);
                            });
                        }
                    })
                }


                break;
            case PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING:
                //base stock
                stock = this.getStockItemInstance();
                stock.prodCode = product.items[itemIndex].prodCode;
                stock.price = product.items[itemIndex].sellQtyPrice;
                stock.keywayMeasurementUnit = product.items[itemIndex].sellUnitName;
                stock.swishMeasurementUnit = product.items[itemIndex].sellUnitName;
                stock.isParentItem = true;
                stocks.push(stock);
                break;
        }
        stocks.forEach((stockItem, stockIndex) => {
            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, deduction);
        });
        product.items[itemIndex].stocks = stocks;
        return product;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, deduction) {
        stockItem.swishCalculatedQty = stockItem.quantityMultiplier ? stockItem.quantityMultiplier : 1;
        stockItem.keywayCalculatedQty = stockItem.quantityMultiplier ? stockItem.quantityMultiplier : 1;
        stockItem.wastagePercent = 0;
        stockItem.attribute = product.items[itemIndex].itemType;
        stockItem.label = "";
        stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
        stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
        stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
        stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
        stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
        stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
    }

    updateDeduction(key, product, itemIndex) {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };

        let width = 0;

        switch (key) {
            case "track":
                if (product.items[itemIndex].angle.selected.value === 90) {
                    width = product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM ? product.items[itemIndex].lengthABC.selected.value : 0;
                    switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                        case "Straight":
                            //NIL
                            break;
                        case "J Shape":
                        case "L Shape":
                            deduction.deductionWidth = (product.items[itemIndex].description && product.items[itemIndex].description.includes("Mini Monotrack")) ? -100 : -148;
                            break;
                        case "U Shape":
                            deduction.deductionWidth = (product.items[itemIndex].description && product.items[itemIndex].description.includes("Mini Monotrack")) ? -200 : -296;
                            break;
                    }
                    deduction.cutWidth = width + (deduction.deductionWidth);
                }
                break;
        }
        return deduction;
    }

    calculateCustomItemUnitPrice(product, itemIndex) {
        let parts, selectedLength, partIndex, difference, pricingMethod, price;
        let result = {
            prodCode: "",
            description: "",
            price: 0,
            sellUnitName: "",
            sellUnitQty: "",
            partType: ""
        };
        parts = product.items[itemIndex].configuration.partRange.selected.parts;
        selectedLength = product.items[itemIndex].lengthABC.selected.value;

        partIndex = parts.findIndex(p => selectedLength <= p.length);
        partIndex = partIndex > -1 ? partIndex : parts.length - 1;
        result.prodCode = parts[partIndex].prodCode;
        result.description = parts[partIndex].description;
        result.eachUnitName = parts[partIndex].eachUnitName;
        result.sellUnitQty = parts[partIndex].sellUnitQty;
        result.sellUnitName = parts[partIndex].sellUnitName;
        result.partType = parts[partIndex].partType;

        if (selectedLength <= PRICING_METHOD_MIN_PRICE_CUT_OVER) {
            pricingMethod = PRICING_METHOD_MIN_PRICE;
        } else {
            difference = parts[partIndex].length - selectedLength;
            pricingMethod = difference > PRICING_METHOD_CUT_OVER ? PRICING_METHOD_PER_MM : PRICING_METHOD_FULL_LENGTH;
        }

        let priceFor1MM = parts[partIndex].sellQtyPrice / parts[partIndex].length;
        switch (pricingMethod) {
            case PRICING_METHOD_MIN_PRICE:
                result.price = priceFor1MM * PRICING_METHOD_MIN_PRICE_CUT_OVER;
                break;
            case PRICING_METHOD_PER_MM:
                result.price = priceFor1MM * selectedLength;
                break;
            case PRICING_METHOD_FULL_LENGTH:
                result.price = parts[partIndex].sellQtyPrice;
                break;
        }

        return result;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0, width;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, itemIndex, packagingAndHandling);

            width = 0;
            switch (product.items[itemIndex].itemType) {
                case PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM:
                    width = product.items[itemIndex].lengthABC.selected.value;
                    break;
                case PRODUCT_BUILDER_HOSPITAL_RANGE_PART:
                    if (product.items[itemIndex].dimensionUnitName === "mm") {
                        width = product.items[itemIndex].length ? product.items[itemIndex].length : 0;
                    }
                    break;
            }
            if (product.maxWidth < width) {
                product.maxWidth = width;
            }

        });
        return product;
    }

    isOpenAllPartType(product, enforce) {
        let result = false;
        if (!enforce) {
            if (!isEmpty(product.part.configuration.part.dropdown.selected.value)) {
                product.part.configuration.partType.finalOptions.forEach(o => {
                    o.isOpen = true;
                });
                result = true;
            }
        }

        if (!result) {
            result = product.part.configuration.partType.finalOptions.every(x => x.isOpen);
        }
        return result;
    }

    getDefaultValue(key, product, itemIndex, context) {
        let result;
        switch (key) {
            case "lengthA":
                if (context.lengthA) {
                    result = context.lengthA;
                } else {
                    if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                            case "J Shape":
                            case "L Shape":
                            case "U Shape":
                                result = 1000;
                                break;
                        }
                    } else {
                        result = 0;
                    }
                }
                break;
            case "lengthB":
                if (context.lengthB) {
                    result = context.lengthB;
                } else {
                    if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                                result = 0;
                                break;
                            case "J Shape":
                            case "L Shape":
                            case "U Shape":
                                result = 1000;
                                break;
                        }
                    } else {
                        result = 0;
                    }
                }
                break;
            case "lengthC":
                if (context.lengthC) {
                    result = context.lengthC;
                } else {
                    if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                            case "J Shape":
                            case "L Shape":
                                result = 0;
                                break;
                            case "U Shape":
                                result = 1000;
                                break;
                        }
                    } else {
                        result = 0;
                    }
                }
                break;
            case "angle":
                if (context.angle) {
                    result = context.angle;
                } else {
                    if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                                result = 0;
                                break;
                            case "J Shape":
                            case "L Shape":
                            case "U Shape":
                                result = 90;
                                break;
                        }
                    } else {
                        result = 0;
                    }
                }
                break;
            case "angle2":
                if (context.angle2) {
                    result = context.angle2;
                } else {
                    if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
                        switch (product.items[itemIndex].configuration.shape.selected.value.value) {
                            case "Straight":
                            case "J Shape":
                            case "L Shape":
                                result = 0;
                                break;
                            case "U Shape":
                                result = 90;
                                break;
                        }
                    } else {
                        result = 0;
                    }
                }
                break;
            case "location":
                if (context.location) {
                    result = context.location;
                } else {
                    result = "";
                }
                break;

        }
        return result;
    }

    findItemIndex(product, customID) {
        return product.items.findIndex(i => i.customID === customID);
    }

    getItemTypeSummary(product) {
        let itemsByItemType = groupBy((product.items || []), 'itemType');
        let itemSummary = {};

        [PRODUCT_BUILDER_HOSPITAL_RANGE_PART, PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM, PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING].forEach(itemType => {
            if (!itemsByItemType[itemType]) {
                itemsByItemType[itemType] = [];
            }

            itemSummary[itemType] = {
                items: itemsByItemType[itemType],
                count: itemsByItemType[itemType].length,
                price: 0,
                discVal: 0,
                priceWithoutDiscVal: 0,
            };

            itemSummary[itemType].items.forEach(item => {
                itemSummary[itemType].price += item.pricing.price;
                itemSummary[itemType].discVal += item.pricing.discVal;
                itemSummary[itemType].priceWithoutDiscVal += item.pricing.price - item.pricing.discVal;
            });
        });


        return itemSummary;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | " + pg.items[itemIndex].itemType + " | ";

        if (!pg.items[itemIndex].quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].quantity.formError.message);
        }
        if (!pg.items[itemIndex].lengthABC.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].lengthABC.formError.message);
        }
        if (!pg.items[itemIndex].angle.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].angle.formError.message);
        }
        if (!pg.items[itemIndex].angle2.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].angle2.formError.message);
        }
        if (!pg.items[itemIndex].prodCode) {
            errors.push(errorMessagePrefix + "Product must be selected");
        }
        return errors;
    }

    toSalesOrderItemHospitalRangePartRequest(pg, itemIndex, order, errors) {
        let salesOrderItemHospitalRangePartRequest = {
            ID: pg.items[itemIndex].ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,

            itemType: pg.items[itemIndex].itemType,
            customID: pg.items[itemIndex].customID,
            parentCustomID: pg.items[itemIndex].parentCustomID,
            prodCode: pg.items[itemIndex].prodCode,
            description: pg.items[itemIndex].description,
            colour: pg.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_PART ? pg.items[itemIndex].colour : (pg.items[itemIndex].configuration.colour.selected.value ? pg.items[itemIndex].configuration.colour.selected.value.value : ""),
            partType: pg.items[itemIndex].partType,
            eachUnitName: pg.items[itemIndex].eachUnitName,
            sellUnitName: pg.items[itemIndex].sellUnitName,
            sellUnitQty: pg.items[itemIndex].sellUnitQty,
            sellQtyPrice: pg.items[itemIndex].sellQtyPrice,
            dimensionUnitName: pg.items[itemIndex].dimensionUnitName,
            thumbnailImageAttachmentID: pg.items[itemIndex].thumbnailImageAttachmentID,
            highQualityImageAttachmentID: pg.items[itemIndex].highQualityImageAttachmentID,
            quantity: pg.items[itemIndex].quantity.selected.value,
            lengthA: pg.items[itemIndex].lengthA.selected.value,
            lengthB: pg.items[itemIndex].lengthB.selected.value,
            lengthC: pg.items[itemIndex].lengthC.selected.value,
            lengthABC: pg.items[itemIndex].lengthABC.selected.value,
            angle: pg.items[itemIndex].angle.selected.value,
            angle2: pg.items[itemIndex].angle2.selected.value,
            location: pg.items[itemIndex].location.selected.value,
            partRange: pg.items[itemIndex].configuration.partRange.selected.value ? pg.items[itemIndex].configuration.partRange.selected.value.value : "",
            shape: pg.items[itemIndex].configuration.shape.selected.value ? pg.items[itemIndex].configuration.shape.selected.value.value : "",
            deductionWidth: pg.items[itemIndex].configuration.partRange.selected.deduction ? pg.items[itemIndex].configuration.partRange.selected.deduction.deductionWidth : 0,
            cutWidth: pg.items[itemIndex].configuration.partRange.selected.deduction ? pg.items[itemIndex].configuration.partRange.selected.deduction.cutWidth : 0,

            stocks: pg.items[itemIndex].stocks
        };

        return salesOrderItemHospitalRangePartRequest;
    }

}

export default HospitalRangePartUtil.Instance();