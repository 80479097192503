import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Badge
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import StockUnitService from '../../services/StockUnitService';
import SearchWINOnlineStock from '../../components/search/SearchWINOnlineStock';
import SearchStockUnitType from '../../components/search/SearchStockUnitType';
import StockUnitMapping from '../../components/WINOInventory/StockUnitMapping';
import {handleErrorMessage} from "../../services/CommonService";

export default class StockUnitPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockUnitData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        stockProdCode: '',
                        stock: {},
                        stockID: 0,
                        stockUnitType: {},
                        stockUnitTypeID: 0,
                        stockUnitTypeName: '',
                        conversionFactorMainQty: null,
                        isMainUnitOrAlternative: null,
                        description: '',


                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',

        };

        this.refreshAgain = this.refreshAgain.bind(this);
    }

    componentDidMount() {
        let {stockUnitData} = this.state;
        let searchParams = queryString.parse(this.props.location.search);
        stockUnitData.request.filterRequest.stockProdCode = searchParams.prodCode ? searchParams.prodCode : "";
        this.setState({ stockUnitData}, () => {
            this.getStockUnits(stockUnitData);
        });
    }


    getStockUnits(stockUnitData) {
        this.setState({loading: true});
        let req = {...this.state.stockUnitData.request};
        req.filterRequest.stockUnitTypeID = req.filterRequest.stockUnitType ? req.filterRequest.stockUnitType.id : 0;
        StockUnitService.listStockUnits(req).then(response => {
            stockUnitData.response = response.data;
            this.setState({stockUnitData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.stock.prodCode.toLowerCase().includes(searchText);

            if (!flag && item.stockUnitType.name) {
                flag = item.stockUnitType.name.toLowerCase().includes(searchText)
            }

            if (!flag && item.conversionFactorMainQty) {
                flag = item.conversionFactorMainQty.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.description) {
                flag = item.description.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.dimension) {
                flag = item.dimension.toString().toLowerCase().includes(searchText)
            }


        }
        return flag;
    }

    handleChange(change, key) {
        let {stockUnitData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (stockUnitData.request.sortRequest.key === change) {
                    stockUnitData.request.sortRequest.direction = !stockUnitData.request.sortRequest.direction;
                } else {
                    stockUnitData.request.sortRequest.key = change;
                    stockUnitData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockUnits(stockUnitData);
                }
                break;
            case "pageSize":
                stockUnitData.request.pageRequest[key] = parseInt(change);
                stockUnitData.request.pageRequest.currentPage = 1;
                this.getStockUnits(stockUnitData);
                break;
            case "currentPage":
                stockUnitData.request.pageRequest[key] = change;
                this.getStockUnits(stockUnitData);
                break;


            default:
                stockUnitData.request.filterRequest[key] = change;

                if (key === 'conversionFactorMainQty' || key === 'description') {
                    if (change === "") {
                        stockUnitData.request.filterRequest[key] = 0;
                    }
                    else {
                        stockUnitData.request.filterRequest[key] = parseFloat(change);
                    }
                }

                stockUnitData.request.pageRequest.currentPage = 1;
                this.getStockUnits(stockUnitData);
                this.setState({stockUnitData});
        }

    }

    refreshAgain() {
        let { stockUnitData } = this.state;
        this.getStockUnits(stockUnitData);
    }

    getStockUnitHeading({filterRequest}) {
        let stockUnitHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "stockProdCode",
                label: "Stock ProdCode",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchWINOnlineStock
                        handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'stockProdCode')}
                        selectedProdCode={filterRequest.stockProdCode}
                        defaultProdCode={filterRequest.stockProdCode}/>
                </div>

            },
            {
                key: "stockUnitTypeName",
                label: "Stock Unit Type",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchStockUnitType
                        stockUnitType={filterRequest.stockUnitType}
                        selected={filterRequest.stockUnitType}
                        defaultSelected={filterRequest.stockUnitType}
                        handleSelection={(selectedStockUnitType) => this.handleChange(selectedStockUnitType, 'stockUnitType')}/>

                </div>

            },

            {
                key: "conversionFactorMainQty",
                label: "Conversion Factor",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"number"}
                           placeholder={"Search..."}
                           name={"conversionFactorMainQty"}
                           value={filterRequest.conversionFactorMainQty || ''}
                           onChange={(e) => this.handleChange(e.target.value, "conversionFactorMainQty")}/>
                </div>
            }, {
                key: "isMainUnitOrAlternative",
                label: "Is Main-Unit Or Alternative",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"description"}
                           value={filterRequest.description || ''}
                           onChange={(e) => this.handleChange(e.target.value, "description")}/>
                </div>
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                                                               to={{
                                                                   pathname: '/inventory/stock-unit/manage/one',
                                                                   state: {stockUnitID: ""}
                                                               }}><i className="fa fa-plus"/>&nbsp;
                    Add </Link></div>

            }];

        return stockUnitHeading;
    }


    render() {

        let {stockUnitData, loading, searchText} = this.state;
        let {pageRequest, sortRequest} = stockUnitData.request;
        let stockUnitHeading = this.getStockUnitHeading(stockUnitData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Unit</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Stock Units</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockUnitData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                   aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(stockUnitHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(stockUnitHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(stockUnitData.response.records || []).filter((stockUnit) => this.searchFunction(stockUnit, searchText)).map((stockUnit, index) =>

                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td><Link
                                            className="btn btn-sm btn-primary"
                                            style={{ color: "white" }}
                                            title={"Click here to see stock details"}
                                            to={"/keyway-inventory/stock/enquiry?prodCode=" +  stockUnit.stock.prodCode }>
                                            {stockUnit.stock.prodCode}</Link></td>
                                        <td>{stockUnit.stockUnitType.name}</td>
                                        <td className="text-right">{stockUnit.conversionFactorMainQty}</td>
                                        <td className="text-center"><Badge color={stockUnit.isMainUnitOrAlternative ? "primary" : "secondary"} >{stockUnit.isMainUnitOrAlternative ? "Main" : "Alternative"}</Badge></td>
                                        <td className="text-left">{stockUnit.description}</td>
                                        <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                                          to={"/inventory/stock-unit/manage/one?" + queryString.stringify({stockUnitID: stockUnit.id})}>
                                            <i className="fa fa-pencil mr-2"
                                               aria-hidden="true"/>
                                            Edit</Link></td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockUnitData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <br />
                <StockUnitMapping handleRefreshOnSubmit={this.refreshAgain}/>
                <ToastContainer/>
            </div>
        );
    }
}
