import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import StockService from '../../services/StockService';
import cloneDeep from 'lodash/cloneDeep';


export default class SearchStockGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            defaultStockGroupCode: props.defaultStockGroupCode,
            stockGroupCode: props.selectedStockGroupCode,
            loading: false,
            allStockGroups: [],
            inputValue: "",
            filter: {
                isKeywayAvailableOnly: props.filter.isKeywayAvailableOnly,
                stockMasterGroupCodes: props.filter.selectedStockMasterGroups,
                stockGroupCodes: props.filter.stockGroupCodes,
            }
        };

        this.stockService = new StockService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

    }


    componentDidMount() { 
        this.fetchOptions(this.state.defaultStockGroupCode || this.state.stockGroupCode);
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.defaultStockGroupCode !== nextProps.defaultStockGroupCode) {
            if (nextProps.defaultStockGroupCode) {
                this.setState({ defaultStockGroupCode: nextProps.defaultStockGroupCode }, () => {
                    this.fetchOptions(nextProps.defaultStockGroupCode);
                })
            }
        }

        if (this.state.stockGroupCode !== nextProps.selectedStockGroupCode) {
            if (!nextProps.selectedStockGroupCode) {
                this.setState({ selected: null }, ()=>{
                    this.fetchOptions("");
                })
            }
            else {
                this.setState({ stockGroupCode: nextProps.selectedStockGroupCode }, ()=>{
                    this.fetchOptions("");
                })
            }
        }else {
            if (nextProps.filter.isKeywayAvailableOnly !== this.state.filter.isKeywayAvailableOnly
                || JSON.stringify(nextProps.filter.stockMasterGroupCodes) !== JSON.stringify(this.state.filter.stockMasterGroupCodes)
            ) {
                let {filter} = this.state;
                filter.isKeywayAvailableOnly = nextProps.filter.isKeywayAvailableOnly;
                filter.stockMasterGroupCodes = nextProps.filter.stockMasterGroupCodes;
                filter.stockGroupCodes = nextProps.filter.stockGroupCodes;
                this.setState({filter}, () => {
                        this.fetchOptions("");
                    }
                );

            }
        }
    }


    handleSelect(selectedStockGroupCode, event) {
        let { selected, stockGroupCode } = this.state;
        selected = selectedStockGroupCode;
        stockGroupCode = selectedStockGroupCode ? selectedStockGroupCode.value : "";

        switch (event) {
            case "selected":
                this.setState({ selected, stockGroupCode });
                break;
            case "onChange":
            case "default":
            default:
                this.setState({ selected, stockGroupCode });
                this.props.handleSelection(stockGroupCode);
        }

        if (!stockGroupCode) {
            this.fetchOptions("");
        }

    };

    fetchOptions(inputValue, callback) {
        let allStockGroups = [];
        let {filter} = this.state;

        let requestBody = {
            searchText: inputValue,
            isKeywayAvailableOnly: filter.isKeywayAvailableOnly,
            stockMasterGroupCodes: filter.stockMasterGroupCodes,
            stockGroupCodes: filter.stockGroupCodes,
        };
        this.stockService.getStockGroupDropdownOptions(requestBody).then(response => {
            let data = response.data;
            for (let i in data) {
                let temp = {
                    label: data[i].name,
                    value: data[i].code,
                };
                allStockGroups.push(cloneDeep(temp));
                if (this.props.defaultStockGroupCode) {
                    if (data[i].code === this.props.defaultStockGroupCode) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.props.selectedStockGroupCode) {
                    if (data[i].code === this.props.selectedStockGroupCode) {
                        this.handleSelect(temp, "selected");
                    }
                }
            }
            this.setState({ allStockGroups });
            if (callback) {
                return callback(allStockGroups)
            } else {
                return allStockGroups;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allStockGroups);
            }
        }
    };
        

    render() { 
        return (
            <AsyncSelect
                cacheOptions
                isLoading={this.state.loading}
                isClearable={true}
                defaultOptions={this.state.allStockGroups}
                loadOptions={this.loadOptions}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                isDisabled={this.props.disabled}
                value={this.state.selected}
                placeholder={"Search..."}
            />
           );
    }
}

 


