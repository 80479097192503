import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_CAFE_CRANK_PRODCODE, PRODUCT_BUILDER_QUIKLOK_PRODCODE} from "../../../../../../store/AppConstants";

let quiklokWidthDrop = [{ width: 2400, drop: 2400 }, { width: 3000, drop: 2400 }];
let bistroWidthDrop = [{ key: 'clear', option: [{ width: 1500, drop: 2400 }, { width: 2400, drop: 2400 }, { width: 3000, drop: 2400 }] },
{ key: 'charcoal', option: [{ width: 2100, drop: 2400 }, { width: 3000, drop: 2400 }] }
];

class QuiklokUtil {

    static Instance() {
        return new QuiklokUtil();
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < product.items[itemIndex].configuration.quantity.min) || (product.items[itemIndex].configuration.quantity.selected.value > product.items[itemIndex].configuration.quantity.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
    }

    updateRules(key, product, itemIndex, order) {
        switch (key) {
            case "quantity":
                product.items[itemIndex].configuration.quantity.min = 1;
                product.items[itemIndex].configuration.quantity.max = 10000;
                break;
            case "width":
                product.items[itemIndex].configuration.width.min = 350;
                product.items[itemIndex].configuration.width.max = 3000;
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 100;
                product.items[itemIndex].configuration.drop.max = 2400;
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let comment, stocks, label, attribute, width, drop,condition1="",temp="",condition2="";
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;

        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Quiklok":
                        temp = PRODUCT_BUILDER_QUIKLOK_PRODCODE;
                        break;
                    case "BistroCrank":
                        temp =  PRODUCT_BUILDER_CAFE_CRANK_PRODCODE;
                        break;
                };
                stocks = [{
                    id: null,
                    prodCode: temp,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false,
                    description: "default"
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "productionCalculation":
                product.items[itemIndex].configuration.productionCalculation.selected.keyway = this.doCalculation("keyway", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.hooding =  this.doCalculation("hooding", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.hoodRailHole =  this.doCalculation("hoodRailHole", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.bottomRail =  this.doCalculation("bottomRail", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.sideChannel =  this.doCalculation("sideChannel", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.fabric =  this.doCalculation("fabric", product, itemIndex);
                break;
            case "model":
                attribute = "model";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    product.items[itemIndex].configuration.model.selected.value.sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks]
                    });
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.model.selected.stocks = stocks;
                break;
            case "bom":
                attribute = "Bom";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = product.items[itemIndex].configuration.colour.selected.value.optionKey;
                product.items[itemIndex].configuration.bom.options.forEach(o => {
                    o.sets.forEach(s => {
                        if ((width >= s.min && width <= s.max) && (drop >= s.secondaryMin && drop <= s.secondaryMax)
                            && ((s.condition1 ? s.condition1.includes(condition1) : true) && (s.condition2 ? s.condition2.includes(condition2):true))) {
                            stocks = [...stocks, ...s.stocks]
                        }
                    });

                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.bom.selected.stocks = stocks;
                break;
        }
        return product;
    }

    doCalculation(key, product, itemIndex) {
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let mount = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        let model = product.items[itemIndex].configuration.model.selected.value.optionKey;

        let deduction = salesOrderProductBuilderV1Service.initDeduction();
            switch (model) {
                case "BistroCrank":
                    switch (key) {
                        case "keyway":
                            deduction.deductionWidth = -55;
                            break;
                        case "bottomRail":
                            deduction.deductionWidth = -60;
                            break;
                        case "fabric":
                            deduction.deductionWidth = -70;
                            break;
                    }
                    break;
                case "Quiklok":
                    switch (key) {
                        case "keyway":
                            deduction.deductionWidth = -60;
                            break;
                        case "hooding":
                            deduction.deductionWidth = -35;
                            break;
                        case "hoodRailHole":
                            deduction.deductionWidth = -30;
                            break;
                        case "bottomRail":
                            deduction.deductionWidth = -45;
                            break;
                        case "sideChannel":
                            switch (mount) {
                                case "Top Mount":
                                    deduction.deductionDrop = -110;
                                    break;
                                case "Face Mount":
                                    deduction.deductionDrop = -105;
                                    break;
                            }
                            break;
                        case "fabric":
                            deduction.deductionWidth = -65;
                            break;
                    }
                    break;
            }
        deduction.cutWidth = width + (deduction.deductionWidth);
        deduction.cutDrop = drop + (deduction.deductionDrop);
        return deduction;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("productionCalculation", product, itemIndex, order);
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("model", product, itemIndex, order);
            product = this.updateStocks("bom", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.model.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.bom.selected.stocks];
            }
            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling, discountByProductCode);
        return product;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression);
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        let deduction = {};
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;
        stockItem.stockLinearWidth = (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1;

        let params = {
            width: width,
            drop: drop,
            stockLength: stockItem.length,
            stockLinearWidth: stockItem.stockLinearWidth,
            swishConversionFactor: stockItem.swishConversionFactor,
        };

        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            default:
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
        }
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        return errors;
    }



    toSalesOrderItemQuiklokRequest(product, itemIndex, order) {

        product.items[itemIndex].configuration.productionCalculation.selected.keyway = this.doCalculation("keyway", product, itemIndex);
        product.items[itemIndex].configuration.productionCalculation.selected.hooding =  this.doCalculation("hooding", product, itemIndex);
        product.items[itemIndex].configuration.productionCalculation.selected.hoodRailHole =  this.doCalculation("hoodRailHole", product, itemIndex);
        product.items[itemIndex].configuration.productionCalculation.selected.bottomRail =  this.doCalculation("bottomRail", product, itemIndex);
        product.items[itemIndex].configuration.productionCalculation.selected.sideChannel =  this.doCalculation("sideChannel", product, itemIndex);
        product.items[itemIndex].configuration.productionCalculation.selected.fabric =  this.doCalculation("fabric", product, itemIndex);


        let salesOrderItemQuiklok = {
            ID: product.items[itemIndex].configuration.ID ? product.items[itemIndex].ID : null,
            salesOrderItemID: product.items[itemIndex].configuration.salesOrderItemID ? product.items[itemIndex].salesOrderItemID : null,
            salesOrderID: product.items[itemIndex].configuration.salesOrderID ? product.items[itemIndex].salesOrderID : null,
            quantity: product.items[itemIndex].configuration.quantity.selected.value,
            width: product.items[itemIndex].configuration.width.selected.value,
            drop: product.items[itemIndex].configuration.drop.selected.value,
            model: product.items[itemIndex].configuration.model.selected.value.optionKey,
            mount: product.items[itemIndex].configuration.mount.selected.value.optionKey,
            keywayDeductionWidth: product.items[itemIndex].configuration.productionCalculation.selected.keyway.deductionWidth,
            hoodingDeductionWidth: product.items[itemIndex].configuration.productionCalculation.selected.hooding.deductionWidth,
            hoodRailHoleDeductionWidth: product.items[itemIndex].configuration.productionCalculation.selected.hoodRailHole.deductionWidth,
            bottomRailDeductionWidth: product.items[itemIndex].configuration.productionCalculation.selected.bottomRail.deductionWidth,
            sideChannelDeductionWidth: product.items[itemIndex].configuration.productionCalculation.selected.sideChannel.deductionWidth,
            sideChannelDeductionDrop: product.items[itemIndex].configuration.productionCalculation.selected.sideChannel.deductionDrop,
            fabricDeductionWidth: product.items[itemIndex].configuration.productionCalculation.selected.fabric.deductionWidth,
            fabricDeductionDrop: product.items[itemIndex].configuration.productionCalculation.selected.fabric.deductionDrop,
            keywayCutWidth: product.items[itemIndex].configuration.productionCalculation.selected.keyway.cutWidth,
            hoodingCutWidth: product.items[itemIndex].configuration.productionCalculation.selected.hooding.cutWidth,
            hoodRailHoleCutWidth: product.items[itemIndex].configuration.productionCalculation.selected.hoodRailHole.cutWidth,
            bottomRailCutWidth: product.items[itemIndex].configuration.productionCalculation.selected.bottomRail.cutWidth,
            sideChannelCutDrop: product.items[itemIndex].configuration.productionCalculation.selected.sideChannel.cutDrop,
            fabricCutWidth: product.items[itemIndex].configuration.productionCalculation.selected.fabric.cutWidth,
            fabricCutDrop: product.items[itemIndex].configuration.productionCalculation.selected.fabric.cutDrop,
            stocks: product.items[itemIndex].stocks,
            colour: product.items[itemIndex].configuration.colour.selected.value.optionKey
        };
        return salesOrderItemQuiklok;
    }

    getBasePriceBasedOnWidthAndDrop(product, itemIndex) {
       let width = product.items[itemIndex].configuration.width.selected.value;
       let drop = product.items[itemIndex].configuration.drop.selected.value;
        let model = product.items[itemIndex].configuration.model.selected.value.optionKey;
        let colour = "";
       switch (model) {
           case "Quiklok":
               return (quiklokWidthDrop.find(x => x.width === width && x.drop === drop));
               break;
           case "BistroCrank":
               colour = product.items[itemIndex].configuration.colour.selected.value.optionKey;
               return((bistroWidthDrop || []).filter(x => x.key === colour.toLowerCase())[0].option.find(x => x.width === width && x.drop === drop));

       }
      
    }
}

export default QuiklokUtil.Instance();