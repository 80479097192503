import { authHeader } from '../../helpers/auth-header';
import { config } from '../../helpers/config'
import axios from 'axios';
import axiosFileDownloader from '../axiosFileDownloader'; 
import axiosFileUploader from '../axiosFileUploader';

export default class CRMService {


    fetchAllLeads(type) {

        /* it will return
        * all the leads
        * */
    }

    getLead(id) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/leads/one?id=' + id
        };
        return axios(request)
    }


    saveLead(lead) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: lead,
            url: config.apiUrl + 'api/leads/save'

        };
        return axios(request)

    }

    saveActivity(lead) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: lead,
            url: config.apiUrl + 'api/leads/activity/save'

        };
        return axios(request)

    }

    saveRemark(remark) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: remark,
            url: config.apiUrl + 'api/leads/remarks/save'

        };
        return axios(request)

    }


    searchOwner(query) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ query: query }),
            url: config.apiUrl + 'api/admin/user/search'
        };
        return axios(request);
    }


    searchSalesPerson(query) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ query: query }),
            url: config.apiUrl + 'api/admin/user/sales/search'
        };
        return axios(request);
    }


    toggleActivityInDb(id) {
        const request = {
            method: 'PUT',
            headers: authHeader(),
            url: config.apiUrl + 'api/leads/activity/close-toggle?id=' + id
        };
        return axios(request)
    }

    getFullName(firstName, lastName) {
        let fullName = "";
        if (firstName) {
            fullName = fullName + firstName;
        }
        if (lastName) {
            fullName = fullName + " " + lastName;
        }
        return fullName;
    }

    getTempLeads() {
        const request = {
            method: 'POST',
            headers: authHeader(),
            url: config.apiUrl + 'api/leads/temp/getLeads'
        };
        return axios(request)
    }

    dropLead(id) {
        const request = {
            method: 'PUT',
            headers: authHeader(),
            url: config.apiUrl + 'api/leads/update/drop?Id=' + id
        };
        return axios(request)
    }

    fetchAssignedLeadActivitiesForMe(selectedPage, pageSize) {
        const requestBody = {
            selectedPage: selectedPage,
            pageSize: pageSize
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/leads/filter/tasks/assigned/forMe'
        };
        return axios(request)
    }


    fetchRecentlyCreatedTasks(selectedPage, pageSize) {
        const requestBody = {
            selectedPage: selectedPage,
            pageSize: pageSize
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/leads/filter/tasks/recentlyCreated'
        };
        return axios(request)
    }



    fetchRecentlyCreatedLeads(selectedPage, pageSize) {
        const requestBody = {
            selectedPage: selectedPage,
            pageSize: pageSize
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/leads/filter/lead/recentlyCreated'
        };
        return axios(request)
    }


    fetchMyLeads(selectedPage, pageSize) {
        const requestBody = {
            selectedPage: selectedPage,
            pageSize: pageSize
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/leads/filter/lead/my'
        };
        return axios(request)
    }

    getDebtorClassification() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getDebtorClassification'
        };
        return axios(request)
    }
    getDebtorCountries() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getDebtorCountries'
        };
        return axios(request)
    }

    getDebtorTypes() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getDebtorTypes'
        };
        return axios(request)
    }

    getDebtorPaymentTerms() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getDebtorPaymentTerms'
        };
        return axios(request)
    }

    getDebtorCurrencyTypes() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getCurrencyTypes'
        };
        return axios(request)
    }

    getDebtorGroups() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getDebtorGroups'
        };
        return axios(request)
    }

    getNextAvailableAcountId() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getNewAccountId'
        };
        return axios(request)
    }

    SaveLeadToCustomer(leadconversion) {
        var obj = {
            'leadId': leadconversion.leadId,
            'ABN': leadconversion.ABN,
            'AccountID': leadconversion.AccountID,
            'Company': leadconversion.Company,
            'StoreNum': leadconversion.StoreNum,
            'Address1': leadconversion.Address1,
            'Address2': leadconversion.Address2,
            'Address3': leadconversion.Address3,
            'City': leadconversion.City,
            'State': leadconversion.State,
            'PostCode': leadconversion.PostCode,
            'Country': leadconversion.Country,
            'Phone': leadconversion.Phone,
            'BO': leadconversion.BO,
            'FO': leadconversion.FO,
            'Industrial': leadconversion.Industrial,
            'International': leadconversion.International,
            'SDI': leadconversion.SDI, //ok
            'CurrencyType': leadconversion.CurrencyType,
            'TypeID': leadconversion.TypeID,
            'ClassificationID': leadconversion.ClassificationID,
            'SpecialReq': leadconversion.SpecialReq,
            'Fax': leadconversion.Fax,
            'Email': leadconversion.Email,
            'Website': leadconversion.Website,
            'DebtorGroupID': leadconversion.DebtorGroupID,
            'PaymentTermsID': leadconversion.PaymentTermsID,

        }
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: obj,
            url: config.apiUrl + 'api/customer/Convert'
        };
        return axios(request)
    }

    // financial
    getCustomerInformation(id) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getCustomerInformation?AccountId=' + id
        };
        return axios(request)
    }

    // Get Debtors_GetNotes
    getCustomerNotesInformation(id,req) { 
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: req,
            url: config.apiUrl + 'api/customer/CustomerNotesDetails?accountId=' + id
        };
        return axios(request)
    }


    // Add Debtors_Notes
    AddDebtors_Notes(AccountId, Notes, UserName) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/AddDebtorsNotes',
            params: {
                AccountId: AccountId,
                Notes: Notes,
                UserName: UserName,
                OID: 0
            }
        };
        return axios(request)
    }

    getCustomerBalacesWithYearMonth(id, yearmonth) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/CustomerBalacesWithYearMonth?AccountId=' + id + '&YearMonth=' + yearmonth
        };
        return axios(request)
    }



    getCustomerTrading_salesInformation(id) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getCustomerTradingandSales?AccountId=' + id
        };
        return axios(request)
    }

    getCustomerLastYearToCurrentDate_Data(id) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/accounts/lastyear/date?AccountId=' + id
        };
        return axios(request)
    }


    getCustomerTransactionInfo_byMonth(request) {
        const requestt = {
            method: 'POST',
            headers: authHeader(),
            data: request,
            url: config.apiUrl + 'api/customer/ByMonthCustomerTransactions'
        };
        return axios(requestt)
    }

    AllCustomerTransactions(request) {
        const requestt = {
            method: 'POST',
            headers: authHeader(),
            data: request,
            url: config.apiUrl + 'api/customer/AllTransactions'
        };
        return axios(requestt)
    }

    getCustomerTransactionInfo_byOutstanding(requestBody) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: requestBody,
            url: config.apiUrl + 'api/customer/OutstandingCustomerTransactions'
        };
        return axios(request)
    }

    getCustomerAttributes(id) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/getCustomerAttributes?AccountId=' + id
        };
        return axios(request)
    }

    generateCustomerNotesExcelReport(accountId , request) {
        return axiosFileDownloader.post('api/customer/notes/excel/report?accountId=' + accountId, request);
    }

}
