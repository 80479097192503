import React, {Component} from 'react';
import ManagePrintersService from "../../services/ManagePrintersService";
import { 
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    Nav,
    CardBody,
    CardHeader, 
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import PrinterList from "./PrintersPage";
import PrinterJobList from "./PrintersJobPage";
import {Link} from "react-router-dom";

const printerTaskTabs = [
    {
        label: "Printers",
        key: "printers"
    },
    {
        label: "Printer Jobs",
        key: "printerjob"
    }
];

export default class PrinterServerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'printers',
        };
        this.managePrintersService = new ManagePrintersService();
    }

    componentDidMount() {
    }

    toggleTab(tab) {
        this.setState({activeTab: tab});
    }

    render() {
        let {activeTab} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Printers</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {printerTaskTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="printers">
                                <PrinterList/>
                            </TabPane>
                            <TabPane tabId="printerjob">
                                <PrinterJobList/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        )
    }
}