import axios from '../axios'; 

class ProductionLineService {

    static Instance() {
        return new ProductionLineService();
    }

    getListProductionLines() { 
        return axios.post('api/production/lines/list');
    }

    updateProductionLine(request) {
        return axios.post('api/production/lines/update', request);
    }
}

export default ProductionLineService.Instance();
