import React, {Component} from 'react';
import {Button, Col, Input, Row, Spinner,} from "reactstrap";

import {toast, ToastContainer} from 'react-toastify';
import ProductionCapacityService from '../../services/production/ProductionCapacityService';

import {changeFormatOfDateString, handleErrorMessage} from '../../services/CommonService';

export default class ProductionConfiguration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productionConfiguration: {},
            isLoadingData: false,
            isLoadingSave: false,
        };
        this.productionCapacityService = new ProductionCapacityService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    handleChange(key, change) {
        let {productionConfiguration} = this.state;
        productionConfiguration[key] = change;
        this.setState({productionConfiguration});
    }


    handleSubmit() {

        this.setState({isLoadingSave: true});

        let request = {...this.state.productionConfiguration};

        this.productionCapacityService.updateProductionConfiguration(request).then(response => {
            this.setState({productionConfiguration: response.data, isLoadingSave: false});
            toast.success("Updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            this.setState({isLoadingSave: false});
            toast.success(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    refresh() {
        this.setState({isLoadingData: true});
        this.productionCapacityService.getProductionConfiguration().then(response => {
            this.setState({productionConfiguration: response.data, isLoadingData: false});
        }).catch(error => {
            this.setState({isLoadingData: false});
            toast.success(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {isLoadingSave, isLoadingData, productionConfiguration} = this.state;

        return (
            <div>
                {this.state.isLoadingData
                    ? <Spinner color="primary"/>
                    : <Row>
                        <Col xl={7} lg={7} md={9} sm={12}>

                            <h6>Powdercoating Lead Time In Days</h6>
                            <Input
                                type={"number"}
                                name={"powdercoatingLeadTimeInDays"}
                                value={productionConfiguration.powdercoatingLeadTimeInDays}
                                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                            >
                            </Input>
                            <Row>
                                <Col xl={9} lg={9} md={9} sm={12}>
                                    <p className="text-muted mt-2">Last updated
                                        on {changeFormatOfDateString(productionConfiguration.updatedDate, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY hh:mm a")}</p>
                                </Col>
                                <Col xl={3} lg={3} md={3} sm={12}>
                                    <div className="text-right mt-2 mb-2">
                                        <Button size={"sm"}
                                                color='primary'
                                                onClick={this.handleSubmit}
                                                disabled={isLoadingSave}>
                                            {isLoadingSave
                                                ? <Spinner color={"light"} size="sm" className={"mr-2"}/>
                                                : <i className="fa fa-floppy-o mr-2"
                                                     aria-hidden="true"/>
                                            }
                                            {isLoadingSave
                                                ? "Saving"
                                                : "Save"
                                            }
                                        </Button>
                                    </div>
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                }
                <ToastContainer/>
            </div>
        );
    }
}

