import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, BreadcrumbItem, Breadcrumb } from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import {
    addDate,
    getDistinctValues,
    getDataWeekWiseBetweenDatesFromMonToSun,
    handleErrorMessage
} from '../../services/CommonService';
import OrderService from '../../services/OrderService';
import { isEmpty, cloneDeep } from 'lodash';
import NumberFormat from "react-number-format";

export default class ReworkUnitPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsDataArray: [],
            products: [],
            totalCount: [],
            isLoadingProducts: false,
            isLoading: false,
        };
        this.getOrderData = this.getOrderData.bind(this);
        this.orderService = new OrderService();
    }
    componentDidMount() {
        this.getOrderData();
    }
    getOrderData() {
        let { productsDataArray } = this.state;
        this.setState({ isLoading: true });
        this.orderService.getPBReworkOrderData().then(response => {
            let data = response.data;
            productsDataArray = this.prepareResultStructure(data);
            productsDataArray = this.fillResultStructureWithData(data, productsDataArray);
            this.setState({ productsDataArray, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            console.error(error);
        })
    }
    prepareResultStructure(data) {
        let productTemp, weekYearDataTemp, now = new Date(), fromDate, productsDataArray = [];
        //get weeks >> columns
        fromDate = addDate(now, -11, "weeks", "dateObj");
        let weekYearsMasterData = getDataWeekWiseBetweenDatesFromMonToSun(fromDate, now);
        //get products >> rows
        let productNamesMasterData = getDistinctValues(data, 'productName');
        this.getTotal(weekYearsMasterData, data);
        productNamesMasterData.forEach(productName => {
            productTemp = {
                productName: productName,
                weekYearDataArray: [],
                units: 0,
                orders: 0,
            };
            weekYearsMasterData.forEach(weekYear => {
                weekYearDataTemp = {
                    year: weekYear.year,
                    displayWeekRange: weekYear.displayWeekRange,
                    units: 0,
                    orders: 0,
                    weekDates: weekYear.weekDates,
                };
                productTemp.weekYearDataArray.push(cloneDeep(weekYearDataTemp));
            });
            productsDataArray.push(cloneDeep(productTemp));
        });
        return productsDataArray;
    }
    fillResultStructureWithData(data, productsDataArray) {
        let itemIndex, units, orders;
        productsDataArray.forEach(productsData => {
            productsData.weekYearDataArray.forEach(weekYearData => {
                units = 0;
                orders = 0;
                weekYearData.weekDates.forEach(date => {
                    itemIndex = data.findIndex(d => productsData.productName === d.productName && d.ordDate === date);
                    if (itemIndex > -1) {
                        units += data[itemIndex].totalUnits;
                        orders += data[itemIndex].totalOrders;
                    }
                });
                weekYearData.units = units;
                weekYearData.orders = orders;
                productsData.units += weekYearData.units;
                productsData.orders += weekYearData.orders;
            });
        });
        return productsDataArray;
    }
    getTotal(weekYearsMasterData, productsDataArray) {
        let { totalCount } = this.state;
        let total = 0,totalOrder=0;
        weekYearsMasterData.forEach(weekData => {
            let subtotal = 0;
            weekData.weekDates.forEach(dates => {
                productsDataArray.forEach(product => {
                    if (dates === product.ordDate) {
                        subtotal += product.totalUnits;
                        totalOrder += product.totalOrders;
                    }
                })
            })
            totalCount.push(subtotal);
        });
        totalCount.forEach(caltotal => {
            total += caltotal;
        })
        this.setState({ totalCount, total, totalOrder });
    }
    render() {
        let { productsDataArray, isLoading, totalCount, total, totalOrder } = this.state;
        if (isLoading || isEmpty(productsDataArray)) {
            return null;
        }
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col><h6 className={"mb-0"}>PB Rework-Order Units</h6></Col>
                            <Col className={"text-right"}>In last 12 weeks</Col>
                        </Row>
                    </CardHeader>
                    <div>
                        <Table striped bordered responsive hover size='sm' className={"mb-0"}>
                            <thead>
                                <tr>
                                    <th className={"text-center align-middle"}>Product/Week</th>
                                    {(productsDataArray[0].weekYearDataArray || []).map((item, index) => {
                                        return (<th key={index} className={"text-center"}>
                                            {item.displayWeekRange}
                                        </th>
                                        )
                                    })}
                                    <th className={"text-center"}>Total Units</th>
                                    {/*<th className={"text-center"}>Rework % of product</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {(productsDataArray || []).map((productsData, index) =>
                                    <tr key={index}>
                                        <th>{productsData.productName}</th>
                                        {(productsData.weekYearDataArray || []).map((weekYearData, index) =>
                                            <td className="text-right" key={index}>{weekYearData.units === 0 ? '-' : weekYearData.units}</td>
                                        )}
                                        <th className="text-right">{productsData.units === 0 ? '-' : productsData.units}</th>
                                        {/*<th className="text-right">*/}
                                        {/*    <NumberFormat value={(productsData.orders * 100 / totalOrder).toFixed(0)}*/}
                                        {/*        decimalScale={0}*/}
                                        {/*        displayType={'text'}*/}
                                        {/*        thousandSeparator={true}*/}
                                        {/*        suffix={'%'} />*/}
                                        {/*    </th>*/}
                                    </tr>
                                )}
                                <tr>
                                    <th>Total</th>
                                    {(totalCount || []).map((total, index) =>
                                        <th key={index} className="text-right">{total === 0 ? '-' : total}</th>
                                    )}
                                    <th className="text-right">{total === 0 ? '-' : total}</th>
                                    {/*<th className="text-right"> <NumberFormat value={(total * 100 / total)}*/}
                                    {/*    decimalScale={0}*/}
                                    {/*    displayType={'text'}*/}
                                    {/*    thousandSeparator={true}*/}
                                    {/*    suffix={'%'} /></th>*/}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Card>
                <ToastContainer />
            </Col>
        );
    }
}