import React, {Component} from 'react';
import {Card, CardHeader, CardBody, Spinner, Table, Button, CardText} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import retailService from "../../services/retail/RetailService";
import {Link} from 'react-router-dom';
import {handleErrorMessage} from "../../services/CommonService";
import NumberFormat from "react-number-format";
import DebtorRetailPreApprovedDiscountModal from "../modal/DebtorRetailPreApprovedDiscountModal";

export default class DebtorsRetailDiscount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountID: this.props.accountID,
            records: [],
            isLoadingRecords: false,
            isOpenDebtorRetailPreDiscountModal: false
        };
        this.refresh = this.refresh.bind(this);
        this.openDebtorRetailPreDiscountModal = this.openDebtorRetailPreDiscountModal.bind(this);
        this.toggleDebtorRetailPreDiscountModal = this.toggleDebtorRetailPreDiscountModal.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, () => this.refresh());
        }
    }

    refresh() {
        let {accountID} = this.state;
        if (accountID) {
            this.setState({isLoadingRecords: true});
            retailService.getDebtorsRetailDiscount(accountID).then(response => {
                let records = response.data;
                this.setState({records, isLoadingRecords: false});
            }).catch(error => {
                toast.info(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({isLoadingRecords: false});
            });
        }
    }

    openDebtorRetailPreDiscountModal() {
        this.setState({isOpenDebtorRetailPreDiscountModal: true});
    }

    toggleDebtorRetailPreDiscountModal(data) {
        this.setState({isOpenDebtorRetailPreDiscountModal: data});
    }

    render() {
        let {isLoadingRecords, records, isOpenDebtorRetailPreDiscountModal, accountID} = this.state;
        return (
            <Card>
                <CardHeader>
                    <h6 className={"mb-0"}>
                        Pre-approved Discounts
                        <span className={"float-right"}>
                            <Link size="xs" onClick={this.openDebtorRetailPreDiscountModal}>
                                {records.length > 0 ? <i className={"fa fa-pencil"}/> : null}
                            </Link>
                        </span>
                    </h6>
                </CardHeader>

                {
                    isLoadingRecords
                        ? <CardBody><Spinner color={"primary"}/></CardBody>
                        : <div>
                            {records.length > 0
                                ? <Table size={"sm"} striped={true} hover={true} bordered responsive className={"mb-0"}>
                                    <thead>
                                    <tr>
                                        <th>Staff Member</th>
                                        <th className={"text-right"}>Discount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(records || []).map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.user ? item.user.name : ""}</td>
                                            <td className={"text-right"}>
                                                <NumberFormat
                                                    value={item.discount}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'}/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                : <CardBody>
                                    <div className="text-center">
                                        <img src={"/img/logo/productMarkup_logo.png"} className="img-fluid pl-4 pr-4"/>
                                        <CardText> No Discount to show!</CardText>
                                        <Button size={"sm"} color={"primary"}
                                                onClick={this.openDebtorRetailPreDiscountModal}>
                                            <i className="fa fa-plus mr-2" aria-hidden="true"/>Add now
                                        </Button>
                                    </div>
                                </CardBody>
                            }
                        </div>
                }

                {
                    isOpenDebtorRetailPreDiscountModal ?
                        <DebtorRetailPreApprovedDiscountModal
                            isOpen={isOpenDebtorRetailPreDiscountModal}
                            toggle={this.toggleDebtorRetailPreDiscountModal}
                            accountID={accountID}
                            refreshAgain={this.refresh}
                            selectedRetailPreDiscount={records}
                        /> : null
                }
                <ToastContainer/>
            </Card>
        );
    }
}