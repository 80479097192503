import React, {Component} from 'react';
import {Badge, Button, Spinner, Table} from 'reactstrap';
import {changeFormatOfDateString, findItemHavingMaxValue} from '../../services/CommonService';
import classnames from 'classnames';

export default class SalesOrderJobDetailModal extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.getStatusColor = this.getStatusColor.bind(this);
    }

    componentDidMount() {
        let {salesOrderJobs} = this.props;
        this.getStatusColor(salesOrderJobs);

    }

    handleChange(key, change, index) {
        let {salesOrderJobs} = this.props;

        switch (key) {
            case "isSelected_all":
                (salesOrderJobs || []).forEach(soj => {
                    soj.isSelected = change;
                });
                break;
            case "isSelected":
            default:
                salesOrderJobs[index][key] = change;
                break;
        }

        this.props.handleSalesOrderJob(salesOrderJobs);
    }

    getStatusColor(salesOrderJobs) {
        let highestStatusID = findItemHavingMaxValue(salesOrderJobs, 'statusId');
        (salesOrderJobs || []).forEach((item, index) => {
            if (item.statusId < highestStatusID) {
                item.colorName = 'danger';
            }
            else {
                switch (item.statusId) {
                    case 98:
                        item.colorName = 'success';
                        break;
                    case 95:
                    case 90:
                        item.colorName = 'danger';
                        break;
                }
            }
        });
        this.setState({salesOrderJobs});
    }

    render() {
        let {isLoading, isUpdateStatusAllowed, salesOrderJobs} = this.props;

        let isAllSelected = (salesOrderJobs || []).every(soj => soj.isSelected);
        let hasDangerColorItem = (salesOrderJobs || []).some(soj => soj.colorName === "danger");

        return (
            <div>
                <h6><strong>Production Jobs</strong></h6>
                <Table responsive bordered striped hover size={"sm"}>
                    <thead>
                    <tr>
                        {
                            isUpdateStatusAllowed
                                ? <th className={"text-left"}>
                                    {hasDangerColorItem ?
                                        <Button color={"link"} size={"sm"}
                                                onClick={() => this.handleChange("isSelected_all", !isAllSelected, -1)}>
                                            <i className={classnames("fa", "fa-lg", {
                                                    "fa-check-square-o": isAllSelected,
                                                    "fa-square-o": !isAllSelected,
                                                }
                                            )}/>
                                        </Button>
                                        : null
                                    }
                                </th>
                                : null
                        }

                        <th className={"text-left"}>Sr. No.</th>
                        <th>Production Line</th>
                        <th className={"text-center"}>Job Status</th>
                        <th className={"text-center"}>Status Updated On</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        (salesOrderJobs || []).map((item, index) =>
                            <tr key={index}>
                                {
                                    isUpdateStatusAllowed
                                        ? <td style={{width: '10px'}}>
                                            {item.colorName === 'danger' ?
                                                <Button color={"link"} size={"sm"}
                                                        onClick={() => this.handleChange("isSelected", !item.isSelected, index)}>
                                                    <i className={classnames("fa", "fa-lg", {
                                                            "fa-check-square-o": item.isSelected,
                                                            "fa-square-o": !item.isSelected,
                                                        }
                                                    )}/>
                                                </Button>
                                                : null
                                            }
                                        </td>
                                        : null
                                }
                                <td className={"text-left"}>Job {index + 1}</td>
                                <td>{item.productionLine}</td>
                                <td className={"text-center"}>
                                    <Badge color={item.colorName}>{item.status}</Badge>
                                </td>
                                <td className={"text-center"}>
                                    {changeFormatOfDateString(item.statusUpdateDate, "DD/MM/YYYY", 'DD MMM YYYY')}
                                </td>
                            </tr>
                        )}

                    </tbody>
                    {
                        isLoading
                            ? <tr>
                                <td colSpan={4}><Spinner size={"sm"} color={"primary"}/></td>
                            </tr>
                            : null
                    }
                </Table>
            </div>
        )
    }
}