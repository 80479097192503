import axios from './axios';

class InvitationService {

    static Instance() {
        return new InvitationService();
    }

    sendTokenEmail(emailAddress) {
        return axios.get('api/customer/invite/sign-up/otp/send?email=' + emailAddress);
    }

    verifyToken(requestBody) {
        return axios.post('api/customer/invite/sign-up/otp/verify', requestBody);
    }

    createUserUponVerification(requestBody) {
        return axios.post('api/customer/invite/sign-up/user/create', requestBody);
    }

}

export default InvitationService.Instance();