import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Spinner} from "reactstrap";
import NumberFormat from "react-number-format";
import {findIndex, handleErrorMessage} from '../../../services/CommonService';
import {toast} from 'react-toastify';
import BranchService from '../../../services/BranchService';
import dispatchOrderService from '../../../services/DispatchOrderService';


const type = "TO BE SHIPPED";
export default class OrderCountToBeShipped extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderCount: 0,
            isLoading: false,
            branches: [],
            branch: 0,
            activeTab: props.activeTab,
            orderCardDetail: {}
        };

        this.branchService = new BranchService();
        this.getBranches = this.getBranches.bind(this);
        this.getOrderCount = this.getOrderCount.bind(this);
    }

    componentDidMount() {
        this.getBranches();
    }

    getOrderCount() {
        this.setState({isLoading: true});
        dispatchOrderService.getOrderCount(type, this.state.branch, this.state.activeTab).then(response => {
            this.setState({ orderCardDetail: response.data, isLoading: false})
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error));
        });

    }

    getBranches() {
        let {branch} = this.state;
        this.branchService.fetchBranches().then(response => {
            let branches = response.data;

            // default branch set to be BRISBANE
            let index = findIndex(branches, "shortName", "BRISBANE");
            if (index !== -1) {
                branch = branches[index].branchNum;
            }
            this.setState({branches, branch}, () => {
                // fetch immediately
                this.getOrderCount();

                setInterval(() => {
                    this.getOrderCount();
                }, 5 * 60 * 1000);
                //auto-refresh after 5 min cycle
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        const { isLoading, orderCardDetail } = this.state;

        return (
            <div>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>To be WW Delivered/ Customer Collected</h6>
                    </CardHeader>
                    <CardBody>
                        {
                            isLoading
                                ? <Spinner color={"primary"}/>
                                : <div>
                                    <h5>
                                        <NumberFormat value={orderCardDetail.orderCount}
                                            displayType={'text'}
                                            thousandSeparator={true} />
                                        <small className={"ml-1"}>Orders</small>
                                    </h5>
                                    <hr />
                                    <h5>
                                        <NumberFormat value={orderCardDetail.orderSum}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={0}
                                        />
                                    </h5>
                                </div>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }
}