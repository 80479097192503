import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody, Button, Label, Input, ModalFooter, Spinner} from 'reactstrap';
import apiLogService from '../../services/ApiLogService';
import {ToastContainer, toast} from 'react-toastify';
import {handleErrorMessage} from "../../services/CommonService";

export default class UpdateApiLogStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            statusList: [],
            log: this.props.log
        };
        this.getApiStatusList = this.getApiStatusList.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getApiStatusList();
    }

    getApiStatusList() {
        this.setState({isLoadingStatusList: true});
        apiLogService.getStatusList().then(response => {
            let statusList = response.data;
            this.setState({statusList, isLoadingStatusList: false});
        }).catch(error => {
            this.setState({isLoadingStatusList: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    handleChange(change) {
        let {log} = this.state;
        log.status = change;
        this.setState({log})
    }

    handleSubmit() {
        this.setState({ isLoading: true });
        let {log} = this.state;
        let logIDs = [];
        logIDs.push(log.id);
        let request = { logIDs: logIDs, status: log.status };
        apiLogService.updateApiLogStatus(request).then(response => {
            toast.success("Updated!");
            this.setState({isLoading: false});
            this.props.toggleModal(false);
            this.props.refreshAgain();
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggleModal} = this.props;
        let {isLoading, log, statusList} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={(e) => toggleModal(!isOpen)}>
                <ModalHeader toggle={(e) => toggleModal(!isOpen)}>
                    Update status
                </ModalHeader>
                <ModalBody>
                    <div className="mb-2">
                        <Label className='mb-0'>Select status</Label>
                        <Input
                            className="form-control"
                            onChange={(e) => this.handleChange(e.target.value)}
                            type="select"
                            name="logStatus"
                            value={log.status}
                            placeholder="Select Status">
                            <option value={""}>Select</option>
                            {(statusList || []).map((option, index) =>
                                <option key={index} value={option}>{option}</option>
                            )}
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} onClick={this.handleSubmit}
                                className={"mr-2"}
                                disabled={isLoading}>
                            {isLoading ? <Spinner className={"mr-2"} size={"sm"}/> : <i className="fa fa-floppy-o mr-2"/>}
                            {isLoading ? "Updating" : "Update"} status </Button>
                        <Button color={"secondary"} onClick={(e) => toggleModal(!isOpen)}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}