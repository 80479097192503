import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../services/CommonService';
import {cloneDeep, isEmpty, camelCase} from 'lodash';
import classnames from 'classnames';

import foldingArmAwningUtil from './util/FoldingArmAwningUtil';
import NumberFormat from "react-number-format";
import FoldingArmAwningBOMModal from "./util/FoldingArmAwningBOMModal";
import FoldingArmAwningConsolidatedBOMModal from "./util/FoldingArmAwningConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE,
    PRODUCT_BUILDER_FOLDING_ARM_AWNING_PRODCODE
} from "../../../../../store/AppConstants";
import customUtil from "./custom/CustomUtil";
import {PRODUCT_BUILDER_CUSTOM_BUILD} from "../../../../../store/AppConstants";

const quantityOptions = [1, 2, 3, 4, 5, 6, 7, 8];
const fabricRangeCoolumSemiCassetteOptions = [
    "Docril",
    "Docril Stripe Designs"
];
const fabricColourCoolumSemiCassetteOptions = [
    "Black & White",
    "014 MIDNIGHT/SNOW",
    "Charcoal",
    "Dark Grey Tweed",
    "Natural",
    "Light Grey Tweed"
];
export default class FoldingArmAwning extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                    product = foldingArmAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = "";
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = parseInt(product.items[itemIndex].configuration.drop.selected.value.optionKey);
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_FOLDING_ARM_AWNING_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if (stocksByProdCode) {
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                        }
                        if (stock) {
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = foldingArmAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = foldingArmAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;
        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemFoldingArmAwning.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.options.forEach(o => {
            o.sets = getSorted(o.sets, "optionKey", true);
        });
        product.items[itemIndex].configuration.width.finalOptions = product.items[itemIndex].configuration.width.options;

        product.items[itemIndex].configuration.width.min = 2100;// default
        product.items[itemIndex].configuration.width.max = 8500;// default
        product.items[itemIndex].configuration.width.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemFoldingArmAwning.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 3000;
        }
        product.items[itemIndex].configuration.width.selected.dropdownValue = product.items[itemIndex].configuration.width.selected.value + "";
        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };


        //type
        configurationIndex = findIndex(configurations, "attributeKey", "type");
        product.items[itemIndex].configuration.type = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.type.selected = {};
        product.items[itemIndex].configuration.type.finalOptions = product.items[itemIndex].configuration.type.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.type.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.type);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.type.selected.value = product.items[itemIndex].configuration.type.finalOptions[optionIndex];
        product.items[itemIndex].configuration.type.selected.dropdownValue = product.items[itemIndex].configuration.type.selected.value.optionKey;
        product.items[itemIndex].configuration.type.formError = {isValid: true, message: "", dom: null,};


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.drop.finalOptions = product.items[itemIndex].configuration.drop.options;
        product.items[itemIndex].configuration.drop.selected = {};
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.drop.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.drop.toString());
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.drop.selected.value = product.items[itemIndex].configuration.drop.finalOptions[optionIndex];
        product.items[itemIndex].configuration.drop.selected.dropdownValue = product.items[itemIndex].configuration.drop.selected.value.optionKey;
        product.items[itemIndex].configuration.drop.formError = {isValid: true, message: "", dom: null,};


        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.operation.selected = {};
        product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.operation);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
        product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
        product.items[itemIndex].configuration.operation.formError = {isValid: true, message: "", dom: null,};


        //controlSide
        configurationIndex = findIndex(configurations, "attributeKey", "controlSide");
        product.items[itemIndex].configuration.controlSide = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.controlSide.selected = {};
        product.items[itemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].configuration.controlSide.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.controlSide);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
        product.items[itemIndex].configuration.controlSide.formError = {isValid: true, message: "", dom: null,};


        //isFrameOnly
        configurationIndex = findIndex(configurations, "attributeKey", "isFrameOnly");
        product.items[itemIndex].configuration.isFrameOnly = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.isFrameOnly = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.isFrameOnly.selected.value = context.salesOrderItemFoldingArmAwning.isFrameOnly;
        } else {
            product.items[itemIndex].configuration.isFrameOnly.selected.value = false;
        }


        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemFoldingArmAwning.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.fabricType.selected.isSelectable = true;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemFoldingArmAwning.fabricType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
        }


        //fabricColourOffRange
        product.items[itemIndex].configuration.fabricColourOffRange = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = context.salesOrderItemFoldingArmAwning.fabricColourOffRange;
        } else {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
        }
        product.items[itemIndex].configuration.fabricColourOffRange.formError = {isValid: true, message: "", dom: null};


        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true
        };
        product.items[itemIndex].configuration.fabricColour.options = [];
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
            product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                return {
                    optionKey: o.color,
                    optionLabel: salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                    ...o
                }
            });
        }
        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
            )
        });
        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
            optionIndex = -1;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.fabricColour);
            }
            if (optionIndex === -1 && !product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                    optionIndex = 0;
                }
            }
            if (optionIndex > -1) {
                product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
            }
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};


        //valance
        configurationIndex = findIndex(configurations, "attributeKey", "valance");
        product.items[itemIndex].configuration.valance = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.valance.selected = {};
        product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.valance);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
        product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
        product.items[itemIndex].configuration.valance.formError = {isValid: true, message: "", dom: null,};


        //frameColour
        configurationIndex = findIndex(configurations, "attributeKey", "frameColour");
        product.items[itemIndex].configuration.frameColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frameColour.selected = {};
        product.items[itemIndex].configuration.frameColour.options = getSorted(product.items[itemIndex].configuration.frameColour.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.frameColour.finalOptions = product.items[itemIndex].configuration.frameColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.frameColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
        product.items[itemIndex].configuration.frameColour.formError = {isValid: true, message: "", dom: null,};


        //framePowdercoatColour
        product.items[itemIndex].configuration.framePowdercoatColour = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = context.salesOrderItemFoldingArmAwning.framePowdercoatColour;
        } else {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
        }
        product.items[itemIndex].configuration.framePowdercoatColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //hooding
        configurationIndex = findIndex(configurations, "attributeKey", "hooding");
        product.items[itemIndex].configuration.hooding = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hooding.options = getSorted(product.items[itemIndex].configuration.hooding.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.hooding.finalOptions = product.items[itemIndex].configuration.hooding.options;
        product.items[itemIndex].configuration.hooding.selected = {};
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.hooding);
            if (optionIndex === -1) {
                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, 'optionKey', "Mitjavila Hood (Alum)");
            }
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (product.items[itemIndex].configuration.hooding.finalOptions[optionIndex]) {
            product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
            product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.hooding.formError = {isValid: true, message: "", dom: null,};


        //hoodingColour
        configurationIndex = findIndex(configurations, "attributeKey", "hoodingColour");
        product.items[itemIndex].configuration.hoodingColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hoodingColour.options = getSorted(product.items[itemIndex].configuration.hoodingColour.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.hoodingColour.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.hoodingColour.finalOptions = product.items[itemIndex].configuration.hoodingColour.options;
        product.items[itemIndex].configuration.hoodingColour.selected = {};

        optionIndex = -1;
        if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.finalOptions)) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.hoodingColour);
                if (optionIndex === -1) {
                    optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.hooding);
                }
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        }
        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = true;
        product.items[itemIndex].configuration.hoodingColour.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex] : "";
        product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey : "";
        product.items[itemIndex].configuration.hoodingColour.formError = {isValid: true, message: "", dom: null,};


        //hoodingPowdercoatColour
        product.items[itemIndex].configuration.hoodingPowdercoatColour = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = context.salesOrderItemFoldingArmAwning.hoodingPowdercoatColour;
        } else {
            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = "";
        }
        product.items[itemIndex].configuration.hoodingPowdercoatColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {};
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.mount);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};


        //crankHandle
        configurationIndex = findIndex(configurations, "attributeKey", "crankHandle");
        product.items[itemIndex].configuration.crankHandle = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.crankHandle.selected = {};
        product.items[itemIndex].configuration.crankHandle.options = getSorted(product.items[itemIndex].configuration.crankHandle.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.crankHandle.finalOptions = product.items[itemIndex].configuration.crankHandle.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.crankHandle);
        }
        optionIndex = optionIndex > -1 ? optionIndex : findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', "1600mm White");
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
        product.items[itemIndex].configuration.crankHandle.formError = {isValid: true, message: "", dom: null,};


        //rafterBrackets10Degree
        configurationIndex = findIndex(configurations, "attributeKey", "rafterBrackets10Degree");
        product.items[itemIndex].configuration.rafterBrackets10Degree = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.rafterBrackets10Degree.finalOptions = product.items[itemIndex].configuration.rafterBrackets10Degree.options;
        product.items[itemIndex].configuration.rafterBrackets10Degree.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.rafterBrackets10Degree.selected.value = context.salesOrderItemFoldingArmAwning.rafterBrackets10Degree;
        } else {
            product.items[itemIndex].configuration.rafterBrackets10Degree.selected.value = 0;
        }
        product.items[itemIndex].configuration.rafterBrackets10Degree.min = 1;
        product.items[itemIndex].configuration.rafterBrackets10Degree.max = 999;
        product.items[itemIndex].configuration.rafterBrackets10Degree.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //rafterBrackets22Degree
        configurationIndex = findIndex(configurations, "attributeKey", "rafterBrackets22Degree");
        product.items[itemIndex].configuration.rafterBrackets22Degree = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.rafterBrackets22Degree.finalOptions = product.items[itemIndex].configuration.rafterBrackets22Degree.options;
        product.items[itemIndex].configuration.rafterBrackets22Degree.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.rafterBrackets22Degree.selected.value = context.salesOrderItemFoldingArmAwning.rafterBrackets22Degree;
        } else {
            product.items[itemIndex].configuration.rafterBrackets22Degree.selected.value = 0;
        }
        product.items[itemIndex].configuration.rafterBrackets22Degree.min = 1;
        product.items[itemIndex].configuration.rafterBrackets22Degree.max = 999;
        product.items[itemIndex].configuration.rafterBrackets22Degree.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //rafterBrackets30Degree
        configurationIndex = findIndex(configurations, "attributeKey", "rafterBrackets30Degree");
        product.items[itemIndex].configuration.rafterBrackets30Degree = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.rafterBrackets30Degree.finalOptions = product.items[itemIndex].configuration.rafterBrackets30Degree.options;
        product.items[itemIndex].configuration.rafterBrackets30Degree.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.rafterBrackets30Degree.selected.value = context.salesOrderItemFoldingArmAwning.rafterBrackets30Degree;
        } else {
            product.items[itemIndex].configuration.rafterBrackets30Degree.selected.value = 0;

        }
        product.items[itemIndex].configuration.rafterBrackets30Degree.min = 1;
        product.items[itemIndex].configuration.rafterBrackets30Degree.max = 999;
        product.items[itemIndex].configuration.rafterBrackets30Degree.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //ceilingMountBrackets
        configurationIndex = findIndex(configurations, "attributeKey", "ceilingMountBrackets");
        product.items[itemIndex].configuration.ceilingMountBrackets = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.ceilingMountBrackets.options = getSorted(product.items[itemIndex].configuration.ceilingMountBrackets.options, "primaryAttributeMin", true, "primaryAttributeMax", true, "secondaryAttributeMin", true, "secondaryAttributeMax", true);
        product.items[itemIndex].configuration.ceilingMountBrackets.finalOptions = product.items[itemIndex].configuration.ceilingMountBrackets.options;
        product.items[itemIndex].configuration.ceilingMountBrackets.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.ceilingMountBrackets.selected.value = context.salesOrderItemFoldingArmAwning.ceilingMountBrackets;
        } else {
            product.items[itemIndex].configuration.ceilingMountBrackets.selected.value = 0;
        }
        product.items[itemIndex].configuration.ceilingMountBrackets.min = 1;
        product.items[itemIndex].configuration.ceilingMountBrackets.max = 999;
        product.items[itemIndex].configuration.ceilingMountBrackets.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //topFixBrackets
        configurationIndex = findIndex(configurations, "attributeKey", "topFixBrackets");
        product.items[itemIndex].configuration.topFixBrackets = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.topFixBrackets.options = getSorted(product.items[itemIndex].configuration.topFixBrackets.options, "primaryAttributeMin", true, "primaryAttributeMax", true, "secondaryAttributeMin", true, "secondaryAttributeMax", true);
        product.items[itemIndex].configuration.topFixBrackets.finalOptions = product.items[itemIndex].configuration.topFixBrackets.options;
        product.items[itemIndex].configuration.topFixBrackets.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.topFixBrackets.selected.value = context.salesOrderItemFoldingArmAwning.topFixBrackets;
        } else {
            product.items[itemIndex].configuration.topFixBrackets.selected.value = 0;
        }
        product.items[itemIndex].configuration.topFixBrackets.min = 1;
        product.items[itemIndex].configuration.topFixBrackets.max = 999;
        product.items[itemIndex].configuration.topFixBrackets.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //cassetteBackBarBrackets
        configurationIndex = findIndex(configurations, "attributeKey", "cassetteBackBarBrackets");
        product.items[itemIndex].configuration.cassetteBackBarBrackets = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.cassetteBackBarBrackets.options = getSorted(product.items[itemIndex].configuration.cassetteBackBarBrackets.options, "primaryAttributeMin", true, "primaryAttributeMax", true, "secondaryAttributeMin", true, "secondaryAttributeMax", true);
        product.items[itemIndex].configuration.cassetteBackBarBrackets.finalOptions = product.items[itemIndex].configuration.cassetteBackBarBrackets.options;
        product.items[itemIndex].configuration.cassetteBackBarBrackets.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value = context.salesOrderItemFoldingArmAwning.cassetteBackBarBrackets;
        } else {
            product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value = 0;
        }
        product.items[itemIndex].configuration.cassetteBackBarBrackets.min = 1;
        product.items[itemIndex].configuration.cassetteBackBarBrackets.max = 999;
        product.items[itemIndex].configuration.cassetteBackBarBrackets.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //remoteChannel15
        configurationIndex = findIndex(configurations, "attributeKey", "remoteChannel15");
        product.items[itemIndex].configuration.remoteChannel15 = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.remoteChannel15.finalOptions = product.items[itemIndex].configuration.remoteChannel15.options;
        product.items[itemIndex].configuration.remoteChannel15.selected = {
            value: false,
            dropdownValue: "",
            isSelectable: false
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.remoteChannel15.selected.value = !!context.salesOrderItemFoldingArmAwning.remoteChannel15;
            product.items[itemIndex].configuration.remoteChannel15.selected.dropdownValue = context.salesOrderItemFoldingArmAwning.remoteChannel15;
        }


        //vibrationSensor
        configurationIndex = findIndex(configurations, "attributeKey", "vibrationSensor");
        product.items[itemIndex].configuration.vibrationSensor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.vibrationSensor.finalOptions = product.items[itemIndex].configuration.vibrationSensor.options;
        product.items[itemIndex].configuration.vibrationSensor.selected = {
            value: false,
            dropdownValue: "",
            isSelectable: false
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.vibrationSensor.selected.value = !!context.salesOrderItemFoldingArmAwning.vibrationSensor;
            product.items[itemIndex].configuration.vibrationSensor.selected.dropdownValue = context.salesOrderItemFoldingArmAwning.vibrationSensor;
        }


        //windSensor
        configurationIndex = findIndex(configurations, "attributeKey", "windSensor");
        product.items[itemIndex].configuration.windSensor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windSensor.finalOptions = product.items[itemIndex].configuration.windSensor.options;
        product.items[itemIndex].configuration.windSensor.selected = {
            value: {},
            dropdownValue: "",
            isSelectable: false
        };
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.windSensor.finalOptions, 'optionKey', context.salesOrderItemFoldingArmAwning.windSensor);
            if (optionIndex > -1) {
                product.items[itemIndex].configuration.windSensor.selected.value = product.items[itemIndex].configuration.windSensor.finalOptions[optionIndex];
                product.items[itemIndex].configuration.windSensor.selected.dropdownValue = product.items[itemIndex].configuration.windSensor.selected.value.optionKey;
            }
        }

        //backBar
        configurationIndex = findIndex(configurations, "attributeKey", "backBar");
        product.items[itemIndex].configuration.backBar = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.backBar.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.backBar.finalOptions = product.items[itemIndex].configuration.backBar.options;
        product.items[itemIndex].configuration.backBar.selected = {};


        //frontBar
        configurationIndex = findIndex(configurations, "attributeKey", "frontBar");
        product.items[itemIndex].configuration.frontBar = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frontBar.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.frontBar.finalOptions = product.items[itemIndex].configuration.frontBar.options;
        product.items[itemIndex].configuration.frontBar.selected = {};

        //keyway
        configurationIndex = findIndex(configurations, "attributeKey", "keyway");
        product.items[itemIndex].configuration.keyway = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.keyway.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.keyway.finalOptions = product.items[itemIndex].configuration.keyway.options;
        product.items[itemIndex].configuration.keyway.selected = {};


        //frame
        configurationIndex = findIndex(configurations, "attributeKey", "frame");
        product.items[itemIndex].configuration.frame = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frame.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.frame.finalOptions = product.items[itemIndex].configuration.frame.options;
        product.items[itemIndex].configuration.frame.selected = {};


        //brackets
        configurationIndex = findIndex(configurations, "attributeKey", "brackets");
        product.items[itemIndex].configuration.brackets = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.brackets.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.brackets.finalOptions = product.items[itemIndex].configuration.brackets.options;
        product.items[itemIndex].configuration.brackets.selected = {};

        //casetteBody
        configurationIndex = findIndex(configurations, "attributeKey", "casetteBody");
        product.items[itemIndex].configuration.casetteBody = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.casetteBody.selected = {};
        //pvcLinear
        configurationIndex = findIndex(configurations, "attributeKey", "pvcLinear");
        product.items[itemIndex].configuration.pvcLinear = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.pvcLinear.selected = {};
        //casetteBottomCover
        configurationIndex = findIndex(configurations, "attributeKey", "casetteBottomCover");
        product.items[itemIndex].configuration.casetteBottomCover = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.casetteBottomCover.selected = {};

        product.items[itemIndex].configuration.accessories = {
            options: [],
            finalOptions: [],
        };
        product.items[itemIndex].configuration.accessories.options =
            [
                {key: "rafterBrackets10Degree", label: "10 Degree Rafter Brackets"},
                {key: "rafterBrackets22Degree", label: "22 Degree Rafter Brackets"},
                {key: "rafterBrackets30Degree", label: "30 Degree Rafter Brackets"},
                {key: "ceilingMountBrackets", label: "Ceiling Mount Brackets"},
                {key: "topFixBrackets", label: "Top Fix Brackets"},
                {key: "cassetteBackBarBrackets", label: "Cassette Back Bar Bracket"}
            ];
        product.items[itemIndex].configuration.accessories.finalOptions = product.items[itemIndex].configuration.accessories.options;


        product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("width", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("drop", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("frameColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("hooding", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("mount", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("windSensor", product, itemIndex, order, true);


        product = foldingArmAwningUtil.updateRules("isFrameOnly", product, itemIndex, order);
        product = foldingArmAwningUtil.updateRules("fabric", product, itemIndex, order);
        product = foldingArmAwningUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
        product = foldingArmAwningUtil.updateRules("hoodingColour", product, itemIndex, order);
        product = foldingArmAwningUtil.updateRules("hoodingPowdercoatColour", product, itemIndex, order);
        product = foldingArmAwningUtil.updateRules("crankHandle", product, itemIndex, order);
        product = foldingArmAwningUtil.updateRules("frameColour", product, itemIndex, null);
        product = foldingArmAwningUtil.updateRules("accessories", product, itemIndex, order);
        product = foldingArmAwningUtil.updateRules("motor-accessories", product, itemIndex, order);


        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);

        product = foldingArmAwningUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
        // dont want to update fabric form error immediately
        //product = foldingArmAwningUtil.updateFormError("fabric", product, itemIndex);
        product = foldingArmAwningUtil.updateFormError("frameColour", product, itemIndex);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let {order, currentUser} = this.props;

        let optionIndex = -1,
            bOMCode = "",
            condition1 = "",
            condition2 = "",
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = parseInt(product.items[itemIndex].configuration.drop.selected.value.optionKey),
            isMotorised = foldingArmAwningUtil.isMotorised(product, itemIndex, order);


        if (key) {
            switch (key) {
                case "hooding":
                    optionIndex = -1;
                    product.items[itemIndex].configuration.hooding.finalOptions = product.items[itemIndex].configuration.hooding.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey));
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, "optionKey", product.items[itemIndex].configuration.hooding.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    product.items[itemIndex].configuration.hooding.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.hooding.finalOptions[optionIndex] : null;
                    product.items[itemIndex].configuration.hooding.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.hooding.selected.value.optionKey : "";
                    break;
                case "hoodingColour":
                    optionIndex = -1;
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                        product.items[itemIndex].configuration.hoodingColour.finalOptions = product.items[itemIndex].configuration.hoodingColour.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.hooding.selected.value.optionKey));
                        if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, "optionKey", product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey);
                            }
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    product.items[itemIndex].configuration.hoodingColour.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex] : null;
                    product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey : "";
                    break;
                case "operation":
                    product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey));
                    //update selection, getting previous selection
                    optionIndex = product.items[itemIndex].configuration.operation.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.operation.selected.value.optionKey);
                    //if not found, getting ideal selection
                    if (optionIndex === -1) {
                        if (isMotorised) {
                            optionIndex = product.items[itemIndex].configuration.operation.finalOptions.findIndex(o => o.optionKey.includes("Motor"));
                        }
                    }
                    //if couldn't get first item
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    break;
                case "width":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    product.items[itemIndex].configuration.width.finalOptions = product.items[itemIndex].configuration.width.options.filter(o => (
                        (o.condition2 === "dropdown")
                        && (o.condition1 ? o.condition1 === condition1 : true)
                    ));
                    if (!isEmpty(product.items[itemIndex].configuration.width.finalOptions)) {
                        if (product.items[itemIndex].configuration.width.selected.value) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.width.finalOptions, 'optionKey', product.items[itemIndex].configuration.width.selected.value.toString());
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.width.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.width.selected.value = parseInt(product.items[itemIndex].configuration.width.finalOptions[optionIndex].optionKey);
                        product.items[itemIndex].configuration.width.selected.dropdownValue = product.items[itemIndex].configuration.width.selected.value + "";
                    }
                    break;
                case "drop":
                    if (width && width > 0) {
                        condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                        product.items[itemIndex].configuration.drop.finalOptions = product.items[itemIndex].configuration.drop.options.filter(o =>
                            (o.condition1.includes(condition1) )
                            && (
                                (o.condition1 === PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE)
                                    ? true
                                    : ((o.primaryAttributeMin <= width && o.primaryAttributeMax >= width) || (o.primaryAttributeMax <= width))
                            )
                        );

                        //update selection, getting previous selection
                        optionIndex = product.items[itemIndex].configuration.drop.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.drop.selected.value.optionKey);
                        //if not found, getting ideal selection
                        optionIndex = optionIndex > -1 ? optionIndex : product.items[itemIndex].configuration.drop.finalOptions.findIndex(o => (o.primaryAttributeMin <= width && o.primaryAttributeMax >= width));
                        //if not found, getting first option
                        optionIndex = optionIndex > -1 ? optionIndex : 0;

                        if (!isEmpty(product.items[itemIndex].configuration.drop.finalOptions[optionIndex])) {
                            product.items[itemIndex].configuration.drop.selected.value = product.items[itemIndex].configuration.drop.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.drop.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.drop.selected.value.optionKey : "";
                        }
                        //no need to update price here as price is being updated from where it is called
                    }
                    break;
                case "frameColour":
                    product.items[itemIndex].configuration.frameColour.finalOptions = product.items[itemIndex].configuration.frameColour.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey));

                    //update selection, getting ideal selection
                    optionIndex = product.items[itemIndex].configuration.frameColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.frameColour.selected.value.optionKey);

                    //if couldn't get first item
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColour.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.frameColour.selected.value.optionKey : "";
                    break;
                case "controlSide":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    product.items[itemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].configuration.controlSide.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                    if (!isEmpty(product.items[itemIndex].configuration.controlSide.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.controlSide.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, 'optionKey', product.items[itemIndex].configuration.controlSide.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.controlSide.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.controlSide.selected.value = {};
                        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = "";
                    }
                    break;
                case "fabricType":
                    optionIndex = -1;
                    condition1 = PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE;
                    condition2 = fabricRangeCoolumSemiCassetteOptions;
                    product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options.filter(o =>
                        ((product.items[itemIndex].configuration.type.selected.value.optionKey === condition1)
                                ? condition2.includes(o.optionKey)
                                : true
                        )
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricType.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricType.selected.value = {};
                        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = "";
                    }

                    break;
                case "fabricColour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricType.selected.value.colors
                            .filter(o => (condition1 === PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE
                                    ? fabricColourCoolumSemiCassetteOptions.includes(o.color)
                                    : true
                                )
                                && salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                            )
                            .map(o => {
                                return {
                                    optionKey: o.color,
                                    optionLabel: salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                                    ...o
                                }
                            });
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricColour.selected.value = {};
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                    }
                    break;
                case "valance":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options.filter(o => (o.condition1 ? o.condition1.includes(condition1) : true) && ((o.optionKey === "Square") ? (product.items[itemIndex].configuration.valance.selected.value.optionKey === "Square") : true));
                    if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, 'optionKey', product.items[itemIndex].configuration.valance.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.valance.selected.value = {};
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = "";
                    }
                    break;
                case "crankHandle":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    product.items[itemIndex].configuration.crankHandle.finalOptions = product.items[itemIndex].configuration.crankHandle.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                    if (!isEmpty(product.items[itemIndex].configuration.crankHandle.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', product.items[itemIndex].configuration.crankHandle.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.crankHandle.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.crankHandle.selected.value = {};
                        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = "";
                    }
                    break;
                case "mount":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                    if (!isEmpty(product.items[itemIndex].configuration.mount.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', product.items[itemIndex].configuration.mount.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.mount.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.mount.selected.value = {};
                        product.items[itemIndex].configuration.mount.selected.dropdownValue = "";
                    }
                    break;
                case "basePrice":
                    bOMCode = foldingArmAwningUtil.generatePriceMatrixBOMCode(product, itemIndex);
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                        return x.bmCode === bOMCode;
                    });

                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= width);

                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= drop);

                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price;

                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                case "windSensor":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product.items[itemIndex].configuration.windSensor.finalOptions = product.items[itemIndex].configuration.windSensor.options.filter(o=> o.condition1.includes(condition1));
                    if (!isEmpty(product.items[itemIndex].configuration.windSensor.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.windSensor.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.windSensor.finalOptions, 'optionKey', product.items[itemIndex].configuration.windSensor.selected.value.optionKey);
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.windSensor.selected.value = product.items[itemIndex].configuration.windSensor.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.windSensor.selected.dropdownValue = product.items[itemIndex].configuration.windSensor.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.windSensor.selected.value = {};
                        product.items[itemIndex].configuration.windSensor.selected.dropdownValue = "";
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = foldingArmAwningUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product.items[itemIndex].configuration.width.selected.dropdownValue = product.items[itemIndex].configuration.width.selected.value + "";
                product = this.updateItemConfigurationOptions("drop", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = foldingArmAwningUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                optionIndex = findIndex(product.items[itemIndex].configuration.drop.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.drop.selected.value = product.items[itemIndex].configuration.drop.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.drop.selected.dropdownValue = product.items[itemIndex].configuration.drop.selected.value.optionKey;
                }
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = foldingArmAwningUtil.updateFormError("drop", product, itemIndex);
                break;
            case "type":
                optionIndex = findIndex(product.items[itemIndex].configuration.type.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.type.selected.value = product.items[itemIndex].configuration.type.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.type.selected.dropdownValue = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("width", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("drop", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("frameColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("hooding", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("mount", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("windSensor", product, itemIndex, order, true);

                    product = foldingArmAwningUtil.updateRules("isFrameOnly", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("fabric", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("accessories", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("motor-accessories", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("hoodingColour", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("hoodingPowdercoatColour", product, itemIndex, order);

                    product = foldingArmAwningUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
                }
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product = foldingArmAwningUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("accessories", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("motor-accessories", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("windSensor", product, itemIndex, order, true);
                }
                break;
            case "controlSide":
                optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                }
                break;
            case "isFrameOnly":
                product.items[itemIndex].configuration.isFrameOnly.selected.value = change;
                product = foldingArmAwningUtil.updateRules("fabric", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = foldingArmAwningUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateFormError("fabric", product, itemIndex);
                }
                break;
            case "fabricColour":
                product.items[itemIndex].configuration.fabricColour.selected.value = {};
                product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";

                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                product = foldingArmAwningUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                product = foldingArmAwningUtil.updateFormError("fabric", product, itemIndex);
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.value = change;
                product = foldingArmAwningUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                product = foldingArmAwningUtil.updateFormError("fabric", product, itemIndex);
                break;
            case "valance":
                optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                }
                break;
            case "hooding":
                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
                    product = foldingArmAwningUtil.updateRules("hoodingColour", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateRules("hoodingPowdercoatColour", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
                }
                break;
            case "hoodingColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    product = foldingArmAwningUtil.updateRules("hoodingPowdercoatColour", product, itemIndex, order);
                    product = foldingArmAwningUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
                }
                break;
            case "hoodingPowdercoatColour":
                product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = change;
                product = foldingArmAwningUtil.updateFormError("hoodingPowdercoatColour", product, itemIndex);
                break;
            case "frameColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;

                    product = foldingArmAwningUtil.updateRules("frameColour", product, itemIndex, null);
                    product = foldingArmAwningUtil.updateFormError("frameColour", product, itemIndex);
                }
                break;
            case "framePowdercoatColour":
                product.items[itemIndex].configuration.framePowdercoatColour.selected.value = change;
                product = foldingArmAwningUtil.updateFormError("frameColour", product, itemIndex);
                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                }
                break;
            case "crankHandle":
                optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                }
                break;
            case "windSensor":
                optionIndex = findIndex(product.items[itemIndex].configuration.windSensor.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.windSensor.selected.value = product.items[itemIndex].configuration.windSensor.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.windSensor.selected.dropdownValue = product.items[itemIndex].configuration.windSensor.selected.value.optionKey;
                }else{
                    product.items[itemIndex].configuration.windSensor.selected.value = {};
                    product.items[itemIndex].configuration.windSensor.selected.dropdownValue = "";
                }
                break;
            case "vibrationSensor":
            case "remoteChannel15":
            case "rafterBrackets10Degree":
            case "rafterBrackets22Degree":
            case "rafterBrackets30Degree":
            case "ceilingMountBrackets":
            case "topFixBrackets":
            case "cassetteBackBarBrackets":
                product.items[itemIndex].configuration[key].selected.value = change;
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = foldingArmAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }


    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;
        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}} className={"text-center"}>
                                        #
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Quantity
                                    </th>
                                    <th style={{minWidth: 245}} className={"text-center"}>
                                        Model
                                    </th>
                                    <th style={{minWidth: 325}} className={"text-center"}>
                                        Operation
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"text-center"}>
                                            Control/Motor
                                        </div>
                                        <div className={"text-center"}>
                                            Side
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Width(mm)
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Projection(mm)
                                    </th>
                                    <th style={{minWidth: 250}} className={"text-center"}>
                                        Fabric
                                    </th>
                                    <th style={{minWidth: 130}} className={"text-center"}>
                                        Valance
                                    </th>
                                    <th style={{minWidth: 175}} className={"text-center"}>
                                        Frame Colour
                                    </th>
                                    <th style={{minWidth: 250}} className={"text-center"}>
                                        Hooding
                                    </th>

                                    <th style={{minWidth: 170}} className={"text-center"}>
                                        Mount
                                    </th>
                                    <th style={{minWidth: 190}} className={"text-center"}>
                                        Crank Handle
                                    </th>
                                    <th style={{minWidth: 310}} className={"text-center"}>
                                        Extras
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null

                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="number" name="quantity"
                                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                                   value={item.configuration.quantity.selected.value}
                                                                   min={item.configuration.quantity.min}
                                                                   max={item.configuration.quantity.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="quantity"/>

                                                            {
                                                                item.configuration.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="type"
                                                           value={item.configuration.type.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "type", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.type.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="operation"
                                                           value={item.configuration.operation.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "operation", itemIndex, true)}>
                                                        <option value={""} disabled>Select operation</option>

                                                        {
                                                            (item.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {foldingArmAwningUtil.isMotorised(product, itemIndex)
                                                        ? <div className="mt-2">
                                                            {
                                                                item.configuration.remoteChannel15.selected.isSelectable
                                                                    ?
                                                                    <div>
                                                                        <Button
                                                                            size={"sm"}
                                                                            className={"mr-2 mb-1"}
                                                                            color={"link"}
                                                                            onClick={() => this.handleChange(!item.configuration.remoteChannel15.selected.value, "remoteChannel15", itemIndex, true)}>
                                                                            <i className={classnames("fa", "fa-lg", {
                                                                                    "fa-check-square-o": item.configuration.remoteChannel15.selected.value,
                                                                                    "fa-square-o": !item.configuration.remoteChannel15.selected.value,
                                                                                }
                                                                            )}/>
                                                                        </Button>
                                                                        {item.configuration.remoteChannel15.selected.dropdownValue}
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                item.configuration.vibrationSensor.selected.isSelectable
                                                                    ? <div>
                                                                        <Button
                                                                            size={"sm"}
                                                                            className={"mr-2 mb-1"}
                                                                            color={"link"}
                                                                            onClick={() => this.handleChange(!item.configuration.vibrationSensor.selected.value, "vibrationSensor", itemIndex, true)}>
                                                                            <i className={classnames("fa", "fa-lg", {
                                                                                    "fa-check-square-o": item.configuration.vibrationSensor.selected.value,
                                                                                    "fa-square-o": !item.configuration.vibrationSensor.selected.value,
                                                                                }
                                                                            )}/>
                                                                        </Button>
                                                                        {item.configuration.vibrationSensor.selected.dropdownValue}
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                item.configuration.windSensor.selected.isSelectable
                                                                    ? <div>
                                                                        {
                                                                            item.configuration.windSensor.finalOptions.map(o => {
                                                                                return <div>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        className={"mr-2 mb-1"}
                                                                                        color={"link"}
                                                                                        onClick={() => this.handleChange((o.optionKey === item.configuration.windSensor.selected.dropdownValue ? "" : o.optionKey), "windSensor", itemIndex, true)}>
                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                "fa-check-square-o": o.optionKey === item.configuration.windSensor.selected.dropdownValue,
                                                                                                "fa-square-o": o.optionKey !== item.configuration.windSensor.selected.dropdownValue,
                                                                                            }
                                                                                        )}/>
                                                                                    </Button>
                                                                                    {o.optionKey}
                                                                                </div>
                                                                            })
                                                                        }

                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        : null
                                                    }
                                                    {item.configuration.isFrameOnly.selected.isSelectable
                                                        ? <div>
                                                            <hr/>
                                                            <Button
                                                                size={"sm"}
                                                                className={"mr-2"}
                                                                color={"link"}
                                                                onClick={() => this.handleChange(!item.configuration.isFrameOnly.selected.value, "isFrameOnly", itemIndex, true)}>
                                                                <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": item.configuration.isFrameOnly.selected.value,
                                                                        "fa-square-o": !item.configuration.isFrameOnly.selected.value,
                                                                    }
                                                                )}/>
                                                            </Button>
                                                            Frame Only
                                                        </div>
                                                        : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input className={"mt-2"} type="select" name="controlSide"
                                                           value={item.configuration.controlSide.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "controlSide", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.controlSide.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <small>Left/Right side should be viewed from outside toward the
                                                        Awning.
                                                    </small>
                                                </td>
                                                <td className="align-middle">
                                                    {
                                                        isEmpty(item.configuration.width.finalOptions)
                                                            ? <Input type="number" name="width"
                                                                     invalid={!item.configuration.width.formError.isValid}
                                                                     value={item.configuration.width.selected.value}
                                                                     min={item.configuration.width.min}
                                                                     max={item.configuration.width.max}
                                                                     onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                                     onFocus={(event) => event.target.select()}
                                                                     placeholder="width"/>
                                                            : <Input type="select" name="width"
                                                                     value={item.configuration.width.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}>
                                                                <option value={""} disabled>Select width</option>
                                                                {
                                                                    (item.configuration.width.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                    }

                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="drop"
                                                           value={item.configuration.drop.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}>
                                                        <option value={""} disabled>Select Projection(Arm length)</option>
                                                        {
                                                            (item.configuration.drop.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="select" name="fabricType"
                                                               disabled={!item.configuration.fabricType.selected.isSelectable}
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select Range</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {isEmpty(item.configuration.fabricColour.finalOptions)
                                                        ? null
                                                        : <div className={"mt-2"}>
                                                            <Input type="select" name="fabricColour"
                                                                   disabled={!item.configuration.fabricColour.selected.isSelectable}
                                                                   value={item.configuration.fabricColour.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                <option value={""}>Select Colour</option>
                                                                {
                                                                    (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                    }

                                                    {
                                                        item.configuration.fabricColourOffRange.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="fabricColourOffRange"
                                                                       disabled={!item.configuration.fabricType.selected.isSelectable}
                                                                       value={item.configuration.fabricColourOffRange.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColourOffRange", itemIndex, true)}
                                                                       placeholder="Off Range Colour"
                                                                       title={"Enter fabric off range colour"}
                                                                />

                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.fabricColourOffRange.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.fabricColourOffRange.formError.message}
                                                            </Badge>
                                                    }
                                                    {
                                                        ((!isEmpty(item.configuration.fabricColour.finalOptions)) && isEmpty(item.configuration.fabricColour.selected.value) && item.configuration.fabricColourOffRange.selected.value)
                                                            ? <small className="text-info text-justify">
                                                                <i className="fa fa-info-circle mr-2" aria-hidden="true"/>
                                                                You won't be able to select
                                                                Colour from the Colour dropdown list if you have entered Off
                                                                Range
                                                                Colour
                                                            </small>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="valance"
                                                           value={item.configuration.valance.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "valance", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.valance.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}
                                                                    disabled={o.optionKey === "Square"}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="frameColour"
                                                           value={item.configuration.frameColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "frameColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.frameColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.framePowdercoatColour.selected.isSelectable
                                                            ? <div>
                                                                <Input type="text" name="framePowdercoatColour"
                                                                       value={item.configuration.framePowdercoatColour.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "framePowdercoatColour", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.framePowdercoatColour.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.framePowdercoatColour.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        isEmpty(item.configuration.hooding.finalOptions)
                                                            ? "N/A"
                                                            : <div>
                                                                <Input type="select" name="hooding"
                                                                       value={item.configuration.hooding.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "hooding", itemIndex, true)}>
                                                                    <option value={""} disabled>Select hooding</option>
                                                                    {
                                                                        (item.configuration.hooding.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                {
                                                                    product.items[itemIndex].configuration.hoodingColour.selected.isSelectable ?
                                                                        <div className={"mt-2"}>
                                                                            <Input type="select" name="hoodingColour"
                                                                                   value={item.configuration.hoodingColour.selected.dropdownValue}
                                                                                   onChange={(e) => this.handleChange(e.target.value, "hoodingColour", itemIndex, true)}>
                                                                                <option value={""} disabled>Select hoodingColour
                                                                                </option>
                                                                                {
                                                                                    (item.configuration.hoodingColour.finalOptions || []).map((o, oIndex) => {
                                                                                        return <option
                                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                        </div>
                                                                        : null
                                                                }

                                                                {
                                                                    item.configuration.hoodingPowdercoatColour.selected.isSelectable
                                                                        ? <div className={"mt-2"}>
                                                                            <Input type="text" name="hoodingPowdercoatColour"
                                                                                   value={item.configuration.hoodingPowdercoatColour.selected.value}
                                                                                   onChange={(e) => this.handleChange(e.target.value, "hoodingPowdercoatColour", itemIndex, true)}
                                                                                   placeholder="Powdercoat colour"
                                                                                   className={"mt-2"}
                                                                            />
                                                                            {
                                                                                item.configuration.hoodingPowdercoatColour.formError.isValid
                                                                                    ? null
                                                                                    : <Badge color="danger">
                                                                                        {item.configuration.hoodingPowdercoatColour.formError.message}
                                                                                    </Badge>
                                                                            }
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                    }


                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        isEmpty(item.configuration.mount.finalOptions)
                                                            ? "N/A"
                                                            : <Input type="select" name="mount"
                                                                     value={item.configuration.mount.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                    }

                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="crankHandle"
                                                           disabled={!product.items[itemIndex].configuration.crankHandle.selected.isSelectable}
                                                           value={item.configuration.crankHandle.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "crankHandle", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.crankHandle.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.type.selected.dropdownValue === "Monobloc 520"
                                                            ?
                                                            <small className="text-info text-justify">
                                                                A crank handle must be
                                                                selected for manual or
                                                                motorised build for
                                                                pitch control adjustment.
                                                            </small>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        {item.configuration.accessories.finalOptions.some(o => (!item.configuration[o.key].selected.value || item.configuration[o.key].selected.value < 1))
                                                            ? <div>
                                                                <Input type="select" name="extras"
                                                                       value={""}
                                                                       onChange={(e) => this.handleChange(1, e.target.value, itemIndex, true)}>
                                                                    <option value={""} disabled>Select</option>
                                                                    {
                                                                        (item.configuration.accessories.finalOptions || []).filter(o => (!item.configuration[o.key].selected.value || item.configuration[o.key].selected.value < 1)).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.key}>{o.label}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                {
                                                                    item.configuration.accessories.finalOptions.some(o => (item.configuration[o.key].selected.value && item.configuration[o.key].selected.value > 0))
                                                                        ? <hr/>
                                                                        : null
                                                                }
                                                            </div>
                                                            : null
                                                        }
                                                    </div>

                                                    {
                                                        (item.configuration.rafterBrackets10Degree.selected.value && item.configuration.rafterBrackets10Degree.selected.value > 0)
                                                            ? <div className={"mt-1"}>
                                                                <a href="javascript:void(0)"
                                                                   className={"mr-2 mb-1"}
                                                                   onClick={() => this.handleChange(0, "rafterBrackets10Degree", itemIndex, true)}>
                                                                    <i className={"fa fa-lg fa-check-square-o"}/>
                                                                </a> 10 Degree Rafter Brackets Quantity
                                                                <Input type="select" name="rafterBrackets10Degree"
                                                                       value={item.configuration.rafterBrackets10Degree.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "rafterBrackets10Degree", itemIndex, true)}
                                                                       placeholder="10 Degree Rafter Brackets Quantity">
                                                                    {
                                                                        quantityOptions.map((o, oIndex) => {
                                                                            return <option key={oIndex} value={o}>{o}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        (item.configuration.rafterBrackets22Degree.selected.value && item.configuration.rafterBrackets22Degree.selected.value > 0)
                                                            ? <div className={"mt-3"}>
                                                                <a href="javascript:void(0)"
                                                                   className={"mr-2 mb-1"}
                                                                   onClick={() => this.handleChange(0, "rafterBrackets22Degree", itemIndex, true)}>
                                                                    <i className={"fa fa-lg fa-check-square-o"}/>
                                                                </a>22 Degree Rafter Brackets Quantity
                                                                <Input type="select" name="rafterBrackets22Degree"
                                                                       value={item.configuration.rafterBrackets22Degree.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "rafterBrackets22Degree", itemIndex, true)}
                                                                       placeholder="22 Degree Rafter Brackets Quantity">
                                                                    {
                                                                        quantityOptions.map((o, oIndex) => {
                                                                            return <option key={oIndex} value={o}>{o}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        (item.configuration.rafterBrackets30Degree.selected.value && item.configuration.rafterBrackets30Degree.selected.value > 0)
                                                            ? <div className={"mt-3"}>
                                                                <a href="javascript:void(0)"
                                                                   className={"mr-2 mb-1"}
                                                                   onClick={() => this.handleChange(0, "rafterBrackets30Degree", itemIndex, true)}>
                                                                    <i className={"fa fa-lg fa-check-square-o"}/>
                                                                </a>30 Degree Rafter Brackets Quantity
                                                                <Input type="select" name="rafterBrackets30Degree"
                                                                       value={item.configuration.rafterBrackets30Degree.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "rafterBrackets30Degree", itemIndex, true)}
                                                                       placeholder="30 Degree Rafter Brackets Quantity">
                                                                    {
                                                                        quantityOptions.map((o, oIndex) => {
                                                                            return <option key={oIndex} value={o}>{o}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        (item.configuration.ceilingMountBrackets.selected.value && item.configuration.ceilingMountBrackets.selected.value > 0)
                                                            ? <div className={"mt-3"}>
                                                                <a href="javascript:void(0)"
                                                                   className={"mr-2 mb-1"}
                                                                   onClick={() => this.handleChange(0, "ceilingMountBrackets", itemIndex, true)}>
                                                                    <i className={"fa fa-lg fa-check-square-o"}/>
                                                                </a>Ceiling Mount Brackets Quantity
                                                                <Input type="select" name="ceilingMountBrackets"
                                                                       value={item.configuration.ceilingMountBrackets.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "ceilingMountBrackets", itemIndex, true)}
                                                                       placeholder="Ceiling Mount Brackets Quantity">
                                                                    {
                                                                        quantityOptions.map((o, oIndex) => {
                                                                            return <option key={oIndex} value={o}>{o}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (item.configuration.topFixBrackets.selected.value && item.configuration.topFixBrackets.selected.value > 0)
                                                            ? <div className={"mt-3"}>
                                                                <a href="javascript:void(0)"
                                                                   className={"mr-2 mb-1"}
                                                                   onClick={() => this.handleChange(0, "topFixBrackets", itemIndex, true)}>
                                                                    <i className={"fa fa-lg fa-check-square-o"}/>
                                                                </a>
                                                                Top Fix Brackets
                                                                <Input type="select" name="topFixBrackets"
                                                                       value={item.configuration.topFixBrackets.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "topFixBrackets", itemIndex, true)}
                                                                       placeholder="Top Fix Brackets Quantity">
                                                                    {
                                                                        quantityOptions.map((o, oIndex) => {
                                                                            return <option key={oIndex} value={o}>{o}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (item.configuration.cassetteBackBarBrackets.selected.value && item.configuration.cassetteBackBarBrackets.selected.value > 0)
                                                            ? <div className={"mt-3"}>
                                                                <a href="javascript:void(0)"
                                                                   className={"mr-2 mb-1"}
                                                                   onClick={() => this.handleChange(0, "cassetteBackBarBrackets", itemIndex, true)}>
                                                                    <i className={"fa fa-lg fa-check-square-o"}/>
                                                                </a>
                                                                Cassette Back Bar Brackets
                                                                <Input type="select" name="cassetteBackBarBrackets"
                                                                       value={item.configuration.cassetteBackBarBrackets.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "cassetteBackBarBrackets", itemIndex, true)}
                                                                       placeholder="Cassette Back Bar Brackets Quantity">
                                                                    {
                                                                        quantityOptions.map((o, oIndex) => {
                                                                            return <option key={oIndex} value={o}>{o}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={15}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <FoldingArmAwningBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <FoldingArmAwningConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
