import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../services/CommonService';
import {cloneDeep, isEmpty, camelCase} from 'lodash';
import classnames from 'classnames';

import verticalIndoorUtil from './util/VerticalIndoorUtil';
import NumberFormat from "react-number-format";
import VerticalIndoorBOMModal from "./util/VerticalIndoorBOMModal";
import VerticalIndoorConsolidatedBOMModal from "./util/VerticalIndoorConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "./custom/CustomUtil";
import {
    PRODUCT_BUILDER_CUSTOM_BUILD,
    PRODUCT_BUILDER_VERTICAL_INDOOR_PRODCODE
} from "../../../../../store/AppConstants";

export default class VerticalIndoor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }

    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = verticalIndoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = verticalIndoorUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = verticalIndoorUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    product = verticalIndoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_VERTICAL_INDOOR_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if(stocksByProdCode){
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o=> o.prodCode === s.prodCode);
                        }
                        if(stock){
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);

                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
            default:
                break;
        }
        product = verticalIndoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemVerticalIndoor.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.selected = {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemVerticalIndoor.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemVerticalIndoor.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1500;
        }

        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemVerticalIndoor.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }

        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };


        //bladeSize
        configurationIndex = findIndex(configurations, "attributeKey", "bladeSize");
        product.items[itemIndex].configuration.bladeSize = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bladeSize.selected = {};
        product.items[itemIndex].configuration.bladeSize.finalOptions = product.items[itemIndex].configuration.bladeSize.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bladeSize.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.bladeSize);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bladeSize.selected.value = product.items[itemIndex].configuration.bladeSize.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bladeSize.selected.dropdownValue = product.items[itemIndex].configuration.bladeSize.selected.value.optionKey;
        product.items[itemIndex].configuration.bladeSize.formError = {isValid: true, message: "", dom: null,};


        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemVerticalIndoor.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = [];
        product.items[itemIndex].configuration.fabricType.selected = {};
        //for updating fabric types based on blade size
        product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, null);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemVerticalIndoor.fabricType);
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;

        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true,
        };
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, context);
        if (product.items[itemIndex].configuration.fabricColour.finalOptions.length > 0) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.fabricColour);
                optionIndex = optionIndex > -1 ? optionIndex : 0;
            } else {
                optionIndex = 0;
            }
            product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};

        //headRailColour
        configurationIndex = findIndex(configurations, "attributeKey", "headRailColour");
        product.items[itemIndex].configuration.headRailColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.headRailColour.selected = {};
        product.items[itemIndex].configuration.headRailColour.finalOptions = product.items[itemIndex].configuration.headRailColour.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.headRailColour.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.headRailColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.headRailColour.selected.value = product.items[itemIndex].configuration.headRailColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.headRailColour.selected.dropdownValue = product.items[itemIndex].configuration.headRailColour.selected.value.optionKey;
        product.items[itemIndex].configuration.headRailColour.formError = {isValid: true, message: "", dom: null,};


        //bottomWeightType
        configurationIndex = findIndex(configurations, "attributeKey", "bottomWeightType");
        product.items[itemIndex].configuration.bottomWeightType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomWeightType.selected = {};
        product.items[itemIndex].configuration.bottomWeightType.finalOptions = product.items[itemIndex].configuration.bottomWeightType.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomWeightType.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.bottomWeightType);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bottomWeightType.selected.value = product.items[itemIndex].configuration.bottomWeightType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomWeightType.selected.dropdownValue = product.items[itemIndex].configuration.bottomWeightType.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomWeightType.formError = {isValid: true, message: "", dom: null,};


        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {};
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.mount);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};


        //extensionBrackets
        configurationIndex = findIndex(configurations, "attributeKey", "extensionBrackets");
        product.items[itemIndex].configuration.extensionBrackets = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.extensionBrackets.finalOptions = product.items[itemIndex].configuration.extensionBrackets.options;
        product.items[itemIndex].configuration.extensionBrackets.selected = {};
        product.items[itemIndex].configuration.extensionBrackets.selected.isSelectable = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.extensionBrackets.selected.value = context.salesOrderItemVerticalIndoor.extensionBrackets;
        } else {
            product.items[itemIndex].configuration.extensionBrackets.selected.value = false;
        }


        //stackingDirection
        configurationIndex = findIndex(configurations, "attributeKey", "stackingDirection");
        product.items[itemIndex].configuration.stackingDirection = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.stackingDirection.selected = {};
        product.items[itemIndex].configuration.stackingDirection.finalOptions = product.items[itemIndex].configuration.stackingDirection.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.stackingDirection.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.stackingDirection);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.stackingDirection.selected.value = product.items[itemIndex].configuration.stackingDirection.finalOptions[optionIndex];
        product.items[itemIndex].configuration.stackingDirection.selected.dropdownValue = product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey;
        product.items[itemIndex].configuration.stackingDirection.formError = {isValid: true, message: "", dom: null,};//stackingDirection


        //wand
        configurationIndex = findIndex(configurations, "attributeKey", "wand");
        product.items[itemIndex].configuration.wand = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.wand.selected = {};
        product.items[itemIndex].configuration.wand.options = getSorted(product.items[itemIndex].configuration.wand.options, 'primaryAttributeMin', true, 'primaryAttributeMax', true);
        product.items[itemIndex].configuration.wand.finalOptions = product.items[itemIndex].configuration.wand.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.wand.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.wand);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product.items[itemIndex].configuration.wand.selected.value = product.items[itemIndex].configuration.wand.finalOptions[optionIndex];
            product.items[itemIndex].configuration.wand.selected.dropdownValue = product.items[itemIndex].configuration.wand.selected.value.optionKey;
        } else {
            product = this.updateItemConfigurationOptions("wand", product, itemIndex, null);
        }


        //brackets
        configurationIndex = findIndex(configurations, "attributeKey", "brackets");
        product.items[itemIndex].configuration.brackets = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.brackets.selected = {};
        product.items[itemIndex].configuration.brackets.finalOptions = product.items[itemIndex].configuration.brackets.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.brackets.finalOptions, 'optionKey', context.salesOrderItemVerticalIndoor.brackets);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.brackets.selected.value = product.items[itemIndex].configuration.brackets.finalOptions[optionIndex];

        //magnet
        configurationIndex = findIndex(configurations, "attributeKey", "magnet");
        product.items[itemIndex].configuration.magnet = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.magnet.selected = {};
        product.items[itemIndex].configuration.magnet.options = getSorted(product.items[itemIndex].configuration.magnet.options, 'primaryAttributeMin', true, 'primaryAttributeMax', true);
        product.items[itemIndex].configuration.magnet.finalOptions = product.items[itemIndex].configuration.magnet.options;


        //carrier
        configurationIndex = findIndex(configurations, "attributeKey", "carrier");
        product.items[itemIndex].configuration.carrier = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.carrier.selected = {};
        product.items[itemIndex].configuration.carrier.finalOptions = product.items[itemIndex].configuration.carrier.options;


        //lockNuts
        configurationIndex = findIndex(configurations, "attributeKey", "lockNuts");
        product.items[itemIndex].configuration.lockNuts = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.lockNuts.selected = {};
        product.items[itemIndex].configuration.lockNuts.finalOptions = product.items[itemIndex].configuration.lockNuts.options;

        //distanceTube
        configurationIndex = findIndex(configurations, "attributeKey", "distanceTube");
        product.items[itemIndex].configuration.distanceTube = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.distanceTube.selected = {};
        product.items[itemIndex].configuration.distanceTube.finalOptions = product.items[itemIndex].configuration.distanceTube.options;

        //deduction
        product.items[itemIndex].configuration.deduction = {
            deductionRules: [],
            selected: {
                value: {
                    deduction: 0
                }
            }
        };

        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);

        product = verticalIndoorUtil.updateRules("quantity", product, itemIndex, order);
        product = verticalIndoorUtil.updateRules("location", product, itemIndex, order);
        product = verticalIndoorUtil.updateRules("width", product, itemIndex, order);
        product = verticalIndoorUtil.updateRules("drop", product, itemIndex, order);

        product = verticalIndoorUtil.updateFormError("quantity", product, itemIndex, order);
        product = verticalIndoorUtil.updateFormError("location", product, itemIndex, order);
        product = verticalIndoorUtil.updateFormError("width", product, itemIndex, order);
        product = verticalIndoorUtil.updateFormError("drop", product, itemIndex, order);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let optionIndex = -1;
        let {currentUser} = this.props;
        if (key) {
            switch (key) {
                case "basePrice":
                    //bmCode
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => x.bmCode === product.items[itemIndex].configuration.fabricType.selected.value.primaryBMCode);
                    //widthSet
                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= product.items[itemIndex].configuration.width.selected.value);
                        //dropSet
                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= product.items[itemIndex].configuration.drop.selected.value);
                            //price
                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price;
                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                case "fabricType":
                    product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options.filter(o => o.width + "mm" === product.items[itemIndex].configuration.bladeSize.selected.value.optionKey);
                    if (product.items[itemIndex].configuration.fabricType.finalOptions.length > 0) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", product.items[itemIndex].configuration.fabricType.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    }
                    break;
                case "fabricColour":
                    product.items[itemIndex].configuration.fabricType.selected.value.colors = getSorted(product.items[itemIndex].configuration.fabricType.selected.value.colors, "color", true);
                    product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map((o, oIndex) => {
                        return {
                            key: oIndex,
                            optionKey: o.color,
                            optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                            ...o
                        }
                    });
                    product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
                        return (
                            salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                        )
                    });

                    if (product.items[itemIndex].configuration.fabricColour.finalOptions.length > 0) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                    }
                    break;
                case "wand":
                    /*
                    * Every wand size is selectable
                    product.items[itemIndex].configuration.wand.finalOptions.forEach(o => {
                        o.isSelectable = product.items[itemIndex].configuration.drop.selected.value <= o.primaryAttributeMax;
                    });*/
                    optionIndex = product.items[itemIndex].configuration.wand.finalOptions.findIndex(o => (product.items[itemIndex].configuration.drop.selected.value >= o.primaryAttributeMin) && (product.items[itemIndex].configuration.drop.selected.value <= o.primaryAttributeMax));
                    optionIndex = optionIndex > -1 ? optionIndex : product.items[itemIndex].configuration.wand.finalOptions.length - 1;
                    product.items[itemIndex].configuration.wand.selected.value = product.items[itemIndex].configuration.wand.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.wand.selected.dropdownValue = product.items[itemIndex].configuration.wand.selected.value.optionKey;
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1, endIndex = null;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = verticalIndoorUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = verticalIndoorUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                product = verticalIndoorUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("wand", product, itemIndex, null);
                product = verticalIndoorUtil.updateFormError("drop", product, itemIndex);
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                }
                break;
            case "fabricColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                break;
            case "headRailColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.headRailColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.headRailColour.selected.value = product.items[itemIndex].configuration.headRailColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.headRailColour.selected.dropdownValue = product.items[itemIndex].configuration.headRailColour.selected.value.optionKey;
                }
                break;
            case "bottomWeightType":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomWeightType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomWeightType.selected.value = product.items[itemIndex].configuration.bottomWeightType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomWeightType.selected.dropdownValue = product.items[itemIndex].configuration.bottomWeightType.selected.value.optionKey;
                }
                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                }
                break;
            case "extensionBrackets":
                product.items[itemIndex].configuration.extensionBrackets.selected.value = change;
                break;
            case "bladeSize":
                optionIndex = findIndex(product.items[itemIndex].configuration.bladeSize.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bladeSize.selected.value = product.items[itemIndex].configuration.bladeSize.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bladeSize.selected.dropdownValue = product.items[itemIndex].configuration.bladeSize.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);

                }
                break;
            case "stackingDirection":
                optionIndex = findIndex(product.items[itemIndex].configuration.stackingDirection.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.stackingDirection.selected.value = product.items[itemIndex].configuration.stackingDirection.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.stackingDirection.selected.dropdownValue = product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey;
                }
                break;
            case "wand":
                optionIndex = findIndex(product.items[itemIndex].configuration.wand.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.wand.selected.value = product.items[itemIndex].configuration.wand.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.wand.selected.dropdownValue = product.items[itemIndex].configuration.wand.selected.value.optionKey;
                }
                break;
            default:
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = verticalIndoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{minWidth: 45}}>
                                        #
                                    </th>
                                    <th className="text-center" style={{minWidth: 135}}>
                                        Location
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Quantity
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Width(mm)
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Drop(mm)
                                    </th>
                                    <th className="text-center" style={{minWidth: 230}}>
                                        Blade/Fabric
                                    </th>
                                    <th className="text-center" style={{minWidth: 150}}>
                                        Head Rail
                                    </th>
                                    <th className="text-center" style={{minWidth: 150}}>
                                        <div>
                                            <div>Bottom</div>
                                            <div>Weight Type</div>
                                        </div>
                                    </th>

                                    <th className="text-center" style={{minWidth: 200}}>
                                        Mount
                                    </th>

                                    <th className="text-center" style={{minWidth: 175}}>
                                        <div>
                                            <div>Stacking</div>
                                            <div>Direction</div>
                                        </div>
                                    </th>
                                    <th className="text-center" style={{minWidth: 140}}>
                                        Wand(mm)
                                    </th>

                                    <th className="text-center" style={{minWidth: 50}}>
                                        Unit Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 50}}>
                                        Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 120}}>
                                        Action
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 90}}>
                                                <Button color={"link"}
                                                        onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                            </th>
                                            : null
                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="text" name="location"
                                                                   value={item.configuration.location.selected.value}
                                                                   onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                                   placeholder="location"/>
                                                            {
                                                                item.configuration.location.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                            }
                                                            {
                                                                item.configuration.location.formError.isWarning
                                                                    ? <Badge color="warning">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="number" name="quantity"
                                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                                   value={item.configuration.quantity.selected.value}
                                                                   min={item.configuration.quantity.min}
                                                                   max={item.configuration.quantity.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="quantity"/>

                                                            {
                                                                item.configuration.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="number" name="width"
                                                               invalid={!item.configuration.width.formError.isValid}
                                                               value={item.configuration.width.selected.value}
                                                               min={item.configuration.width.min}
                                                               max={item.configuration.width.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="width"/>
                                                    </div>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="number" name="drop"
                                                               invalid={!item.configuration.drop.formError.isValid}
                                                               value={item.configuration.drop.selected.value}
                                                               min={item.configuration.drop.min}
                                                               max={item.configuration.drop.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="drop"/>
                                                    </div>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="bladeSize"
                                                           value={item.configuration.bladeSize.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bladeSize", itemIndex, true)}>
                                                        <option value={""} disabled>Select size</option>

                                                        {
                                                            (item.configuration.bladeSize.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <div className={"mt-2"}>
                                                        <Input type="select" name="fabricType"
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select type</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <Input type="select" name="fabricColour"
                                                               value={item.configuration.fabricColour.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                            <option value={""} disabled>Select color</option>
                                                            {
                                                                (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="headRailColour"
                                                           value={item.configuration.headRailColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "headRailColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.headRailColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="bottomWeightType"
                                                           value={item.configuration.bottomWeightType.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bottomWeightType", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.bottomWeightType.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="mount"
                                                           value={item.configuration.mount.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>

                                                    <div className="mt-2">
                                                        <Button
                                                            size={"sm"}
                                                            className={"mr-2 mb-1"}
                                                            color={"link"}
                                                            disabled={!item.configuration.extensionBrackets.selected.isSelectable}
                                                            onClick={() => this.handleChange(!item.configuration.extensionBrackets.selected.value, "extensionBrackets", itemIndex, true)}>
                                                            <i className={classnames("fa", "fa-lg", {
                                                                    "fa-check-square-o": item.configuration.extensionBrackets.selected.value,
                                                                    "fa-square-o": !item.configuration.extensionBrackets.selected.value,
                                                                }
                                                            )}/>
                                                        </Button>
                                                        {item.configuration.extensionBrackets.attributeLabel}
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="stackingDirection"
                                                           value={item.configuration.stackingDirection.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "stackingDirection", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.stackingDirection.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="wand"
                                                           value={item.configuration.wand.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "wand", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.wand.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null

                                                }

                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={12}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <VerticalIndoorBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <VerticalIndoorConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        :
                        null

                    }

                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
