import axios from './axios';

class StockInventoryTransactionTypeService {
    static Instance() {
        return new StockInventoryTransactionTypeService();
    }

    getAllStockInventoryTransactionType() {
        return axios.get('api/stockInventoryTransactionType/list/all');
    }

    getOneStockInventoryTransactionType(name) {
        return axios.get('api/stockInventoryTransactionType/one/?name='+name);
    }

    addStockInventoryTransactionType(req) {
        return axios.post('api/stockInventoryTransactionType/save',req);
    } 

    updateStockInventoryTransactionType(name,req) {
        return axios.put('api/stockInventoryTransactionType/update/?name='+name,req);
    }

    deleteStockInventoryTransactionType(name) {
        return axios.delete('api/stockInventoryTransactionType/delete/?name='+name);
    }
}
export default StockInventoryTransactionTypeService.Instance();