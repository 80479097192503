import React, {Component} from 'react';

import SearchDebtorAcccount from '../components/search/SearchDebtorAcccount';


import PurchaseHistoryStats from '../components/debtor/purchaseHistory/PurchaseHistoryStats';
import PurchaseHistoryCharts from '../components/debtor/purchaseHistory/SoldProductUnitsChart';
import PurchaseHistoryOrders from '../components/debtor/purchaseHistory/PurchaseHistoryOrders';

import CustomerService from "../services/CustomerService";


import {

    Row,
    Col,
} from 'reactstrap';

export default class PurchaseHistoryPage extends Component {

    constructor(props) {
        super(props);

        let accountId = "";
        if (props.location.state) {
            if (props.location.state.accountId) {
                accountId = props.location.state.accountId;
            }
        }

        this.state = {
            accountId: accountId
        };
        this.handleAccountChange = this.handleAccountChange.bind(this);
    }

    handleAccountChange(accountId) {

        /*
        * this is a callback for account changes from SearchDebtorAcccount Component
        * */

        this.setState({accountId: accountId});
    }


    render() {

        let accountId = this.state.accountId;
        return (
            <div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SearchDebtorAcccount handleAccountChange={this.handleAccountChange}
                            defaultAccountID={accountId}
                            includeChildren={true}
                            excludeClosedandInactive={false} />
                    </Col>
                </Row>
                
                <h2>Purchase History</h2>
                <PurchaseHistoryStats accountId={accountId}/>

                <PurchaseHistoryCharts accountId={accountId}/>
            </div>
        );
    }
}
