import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row, Table,
    Col, Badge,
    Form, InputGroup, InputGroupAddon,
    FormGroup, InputGroupText,
    Label, ButtonGroup,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText
} from 'reactstrap';
import { toast } from 'react-toastify';
import classnames from "classnames";
import DatePicker from "react-datepicker";
import queryString from 'query-string';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom"
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import StockReceiptService from '../../services/stock/StockReceiptService';
import SearchCreditorAccount from '../../components/search/SearchCreditorAcccount';
import { changeFormatOfDateString, getDateString, handleErrorMessage } from '../../services/CommonService';

export const maxDate = moment().add(2, 'years').toDate();
export const minDate = moment().subtract(20, 'years').toDate();
const date = new Date();


export default class StockReceiptsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockReceiptData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: 'completedDate',
                        direction: false
                    },
                    filterRequest: {
                        ordNum: '',
                        supplierCode: '',
                        company: '',
                        status: '',
                        startDate: moment().subtract(3, 'months').toDate(),
                        endDate: date,
                        stockReceiptID: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            isLoading: false,
            selectedWarehouseLocation: {},
            warehouseOptions: [],
            candeleteWarehouseLocation: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.getStockReceiptList = this.getStockReceiptList.bind(this);
        this.getStore = this.getStore.bind(this);
    }

    componentDidMount() {
        let { stockReceiptData } = this.state;
        let { filterRequest, pageRequest, sortRequest } = stockReceiptData.request;
        let searchParams = queryString.parse(window.location.search);
        if (searchParams) {
            filterRequest.ordNum = searchParams.ordNum ? searchParams.ordNum : filterRequest.ordNum;
            filterRequest.supplierCode = searchParams.supplierCode ? searchParams.supplierCode : filterRequest.supplierCode;
            filterRequest.company = searchParams.company ? searchParams.company : filterRequest.company;
            filterRequest.stockReceiptID = searchParams.stockReceiptID ? searchParams.stockReceiptID : filterRequest.stockReceiptID;

            pageRequest.currentPage = searchParams.currentPage ? parseInt(searchParams.currentPage) : pageRequest.currentPage;
            pageRequest.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : pageRequest.pageSize;

            sortRequest.key = searchParams.sortRequestKey ? searchParams.sortRequestKey : sortRequest.key;
            sortRequest.direction = searchParams.sortRequestDirection ? searchParams.sortRequestDirection : sortRequest.direction;
        }
        this.setState({ stockReceiptData }, () => this.getStockReceiptList());
    }

    getStockReceiptList() {
        let { stockReceiptData } = this.state;
        let request = cloneDeep(stockReceiptData.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.setState({ isLoading: true });
        StockReceiptService.getStockReceiptList(request).then(response => {
            stockReceiptData.response = response.data;
            this.setState({ stockReceiptData, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key) {
        let { stockReceiptData } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        switch (key) {
            case "sortKey":
                if (stockReceiptData.request.sortRequest.key === change) {
                    stockReceiptData.request.sortRequest.direction = !stockReceiptData.request.sortRequest.direction;
                } else {
                    stockReceiptData.request.sortRequest.key = change;
                    stockReceiptData.request.sortRequest.direction = false;
                }
                searchParams.set("sortRequestKey", change);
                searchParams.set("sortRequestDirection", stockReceiptData.request.sortRequest.direction);
                this.setState({ stockReceiptData }, () => this.getStockReceiptList());
                break;
            case "pageSize":
                stockReceiptData.request.pageRequest[key] = parseInt(change);
                stockReceiptData.request.pageRequest.currentPage = 1;
                searchParams.set("pageSize", change);
                this.setState({ stockReceiptData }, () => this.getStockReceiptList());
                break;
            case "currentPage":
                stockReceiptData.request.pageRequest[key] = change;
                searchParams.set("currentPage", change);
                this.setState({ stockReceiptData }, () => this.getStockReceiptList());
                break;
            default:
                stockReceiptData.request.filterRequest[key] = change;
                stockReceiptData.request.pageRequest.currentPage = 1;
                searchParams.set(key, change);
                searchParams.set("currentPage", stockReceiptData.request.pageRequest.currentPage);
                this.setState({ stockReceiptData }, () => this.getStockReceiptList());
                break;
        }
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }

    getStore() {
        let store = [
            {
                key: "dateAdded",
                label: "PO Date",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY')}</span>;
                }
            },
            {
                key: "ordNum",
                label: "PO No",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link to={"/purchase/order/enquiry?" + queryString.stringify({ ordNum: value })}
                        className="btn btn-sm btn-primary"
                        title={"Click Here to see the Order Details"}
                        style={{ cursor: "pointer" }}>
                        {value}
                    </Link>;
                }
            },
            {
                key: "stockReceiptID",
                label: "Receipt No",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link to={"/stock/receipt/enquiry?" + queryString.stringify({ stockReceiptID: value })}
                        className="btn btn-sm btn-primary"
                        title={"Click Here to see the Receipt Details"}
                        style={{ cursor: "pointer" }}>
                        {value}
                    </Link>;
                }
            },
            {
                key: "supplierCode",
                label: "Supplier Code",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle  text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link className="btn btn-primary btn-sm"
                        to={"/creditor/enquiry?" + queryString.stringify({ accountID: value })}>
                        {value}
                    </Link>;
                }
            },
            {
                key: "company",
                label: "Company",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <small>{value}</small>;
                }
            },
            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    let color = "";
                    switch (value) {
                        case "Cancelled":
                            color = "danger";
                            break;
                        case "In Progress":
                            color = "secondary";
                            break;
                        case "On Hold":
                            color = "primary";
                            break;
                        case "Approved":
                            color = "warning";
                            break;
                        case "Sent":
                            color = "info";
                            break;
                        case "Completed":
                            color = "success";
                            break;
                    }
                    return <Badge color={color}> {value}</Badge>;
                }
            },
            {
                key: "completedDate",
                label: "Received Date",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span>{value ? changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY') : null}</span>;
                }
            },
            {
                key: "receivedBy",
                label: "Received By",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <small>{value}</small>;
                }
            },
        ];

        return store;
    }

    render() {
        let { stockReceiptData, isLoading } = this.state;
        let { pageRequest, sortRequest, filterRequest } = stockReceiptData.request;
        let store = this.getStore();
        return (
            <div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Stock Receipts</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Stock Receipts</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className={"mb-2"}>
                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                                <div>
                                    <DatePicker
                                        className="form-control form-control"
                                        selected={filterRequest.startDate}
                                        onChange={date => this.handleChange(date, "startDate")}
                                        selectsStart
                                        startDate={filterRequest.startDate}
                                        endDate={filterRequest.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="Start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        withPortal
                                    />
                                </div>
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                                <div className={"text-right"}>
                                    <DatePicker
                                        className="form-control form-control"
                                        selected={filterRequest.endDate}
                                        onChange={date => this.handleChange(date, "endDate")}
                                        selectsEnd
                                        startDate={filterRequest.startDate}
                                        endDate={filterRequest.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="End Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        withPortal
                                    />
                                </div>
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label>PO No</Label>
                                <div className={"text-center"}>
                                    <Input type="search" id="ordNum" name="ordNum"
                                        value={filterRequest.ordNum || ''}
                                        onChange={(e) => this.handleChange(e.target.value, "ordNum")}
                                        placeholder="Search">
                                    </Input>
                                </div>
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label>Supplier</Label>
                                <div style={{ zIndex: '2', position: 'relative' }}>
                                    <SearchCreditorAccount
                                        handleAccountChange={(selected) => this.handleChange(selected, "supplierCode")}
                                        defaultAccountID={filterRequest.supplierCode ? filterRequest.supplierCode : ""}
                                        selectedAccountID={filterRequest.supplierCode ? filterRequest.supplierCode : ""} />
                                </div>
                            </Col>
{/*                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label>Company</Label>
                                <div className={"text-center"}>
                                    <Input type="search" id="company" name="company"
                                        value={filterRequest.company || ''}
                                        onChange={(e) => this.handleChange(e.target.value, "company")}
                                        placeholder="Search">
                                    </Input>
                                </div>
                            </Col>*/}
                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label>Receipt ID</Label>
                                <div className={"text-center"}>
                                    <Input type="search" id="stockReceiptID" name="stockReceiptID"
                                        value={filterRequest.stockReceiptID || ''}
                                        onChange={(e) => this.handleChange(e.target.value, "stockReceiptID")}
                                        placeholder="Search">
                                    </Input>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                {
                                    isLoading
                                        ? <Spinner color='primary' size='sm' />
                                        :
                                        <Row>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <p className={"mb-0 mt-2"}>Showing{' '}
                                                    {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                    {' '}of {stockReceiptData.response.totalRecords}
                                                    {' '}entries
                                                </p>
                                            </Col>
                                        </Row>
                                }
                            </Col>
                        </Row>
                        <Table striped bordered responsive hover size='sm' className={"mb-1"}>
                            <thead>
                                <tr>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{ minWidth: item.minWidth }}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                        )} aria-hidden="true" /> : null
                                                }
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>

                                {(stockReceiptData.response.records || []).map((record, i) => {
                                    return (
                                        <tr key={i}>
                                            {(store || []).map((storeItem, index) => {
                                                return (
                                                    <td key={index} className={storeItem.valueClassName}>
                                                        {storeItem.render(record[storeItem.key], record, i, stockReceiptData.response.records, this)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <CardFooter>
                            <Row>
                                <Col xl={4} lg={4} md={6} sm={12}>
                                    <div className={"text-left"} style={{ maxWidth: 200 }}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Show</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"select"}
                                                name={"pageSize"}
                                                value={pageRequest.pageSize}
                                                disabled={isLoading}
                                                onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                                <option value={50}>50 Rows</option>
                                                <option value={100}>100 Rows</option>
                                                <option value={500}>500 Rows</option>
                                            </Input>
                                        </InputGroup>


                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"float-right"}>
                                        <Pagination
                                            activePage={pageRequest.currentPage}
                                            itemsCountPerPage={pageRequest.pageSize}
                                            totalItemsCount={stockReceiptData.response.totalRecords}
                                            pageRangeDisplayed={3}
                                            onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                            innerClass='pagination mb-0'
                                            activeLinkClass='active'
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </CardFooter>
                    </CardBody>
                </Card>
            </div>
        );
    }
}
