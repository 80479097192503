import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalFooter, ModalBody,
    Button, Row, Col,
    Spinner,
    Input
} from 'reactstrap';
import DatePicker from "react-datepicker";
import {getDateObj, getDateString} from '../../services/CommonService'

export default class UpdateCustomProductionCapacityModal extends Component {


    render() {
        let {isOpen, toggle, handleChange, handleSubmit, loading, workingProductionCapacity} = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Update Production Capacity
                </ModalHeader>
                <ModalBody>
                    <div className="mb-2">
                        Production date
                        <DatePicker
                            className="form-control"
                            selected={getDateObj(workingProductionCapacity.productionDate, 'DD/MM/YYYY')}
                            onChange={(date) => handleChange("workingProductionCapacity_productionDate", getDateString(date, 'DD/MM/YYYY'), workingProductionCapacity)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Enter production date"
                            minDate={new Date()}
                            withPortal
                        />
                    </div>
                    <div className="mb-2">
                        Production capacity
                        <Input name="capacity"
                               type={"number"}
                               value={workingProductionCapacity.capacity}
                               placeholder={"Enter capacity here"}
                               onChange={(e) => handleChange("workingProductionCapacity_capacity", e.target.value, workingProductionCapacity)}>
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button
                            className={"mr-2"}
                            color={"primary"}
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading
                                ? <Spinner className={"mr-2"}
                                           style={{color: "white"}}
                                           size={"sm"}/>
                                : <i className="fa fa-floppy-o mr-2"
                                     aria-hidden="true"/>
                            }
                            Save
                        </Button>
                        <Button color={"secondary"}
                                disabled={loading}
                                onClick={() => toggle(!isOpen)}>
                            Cancel
                        </Button>

                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}