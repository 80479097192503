import React, {Component} from 'react';
import {concatenateStrings} from "../../../../../../services/CommonService";

export let panelTrackLabelStore = [
    {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "fabric",
        label: "Fabric",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour, data.productLabel.fabricColourOffRange);
        }
    },{
        key: "opening",
        label: "Opening",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "trackColour",
        label: "Track Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "stackingDirection",
        label: "Stacking Direction",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },{
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },{
        key: "bracket",
        label: "Bracket",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },{
        key: "bottomRail",
        label: "Bottom Rail",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.bottomRail, data.productLabel.bottomRailColour);
        }
    },
];