import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../../services/CommonService';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import widescreenUtil from './WidescreenUtil';
import NumberFormat from "react-number-format";
import WidescreenBOMModal from "../widescreen/WidescreenBOMModal";
import WidescreenConsolidatedBOMModal from "../widescreen/WidescreenConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../custom/CustomUtil";
import {PRODUCT_BUILDER_CUSTOM_BUILD, PRODUCT_BUILDER_WIDESCREEN_PRODCODE} from "../../../../../../store/AppConstants";

const quantityOptions = [1, 2, 3, 4, 5, 6, 7, 8];

export default class Widescreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = widescreenUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = widescreenUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                    product = widescreenUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_WIDESCREEN_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if(stocksByProdCode){
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o=> o.prodCode === s.prodCode);
                        }
                        if(stock){
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = widescreenUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = widescreenUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemWidescreen.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};


        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemWidescreen.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 2100;// default
        product.items[itemIndex].configuration.width.max = 8500;// default
        product.items[itemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemWidescreen.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 3000;
        }

        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemWidescreen.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }

        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {};
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemWidescreen.model);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};

        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.operation.selected = {};
        product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', context.salesOrderItemWidescreen.operation);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
        product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
        product.items[itemIndex].configuration.operation.formError = {isValid: true, message: "", dom: null,};

        //crankGear
        configurationIndex = findIndex(configurations, "attributeKey", "crankGear");
        product.items[itemIndex].configuration.crankGear = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.crankGear.selected = {};
        product.items[itemIndex].configuration.crankGear.finalOptions = product.items[itemIndex].configuration.crankGear.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.crankGear.finalOptions, 'optionKey', context.salesOrderItemWidescreen.crankGear);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.crankGear.selected.value = product.items[itemIndex].configuration.crankGear.finalOptions[optionIndex];
        product.items[itemIndex].configuration.crankGear.selected.dropdownValue = product.items[itemIndex].configuration.crankGear.selected.value.optionKey;
        product.items[itemIndex].configuration.crankGear.formError = {isValid: true, message: "", dom: null,};

        //controlSide
        configurationIndex = findIndex(configurations, "attributeKey", "controlSide");
        product.items[itemIndex].configuration.controlSide = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.controlSide.selected = {};
        product.items[itemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].configuration.controlSide.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, 'optionKey', context.salesOrderItemWidescreen.controlSide);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
        product.items[itemIndex].configuration.controlSide.formError = {isValid: true, message: "", dom: null,};

        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemWidescreen.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.fabricType.selected.isSelectable = true;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemWidescreen.fabricType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
        }

        //fabricColourOffRange
        product.items[itemIndex].configuration.fabricColourOffRange = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = context.salesOrderItemWidescreen.fabricColourOffRange;
        } else {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
        }
        product.items[itemIndex].configuration.fabricColourOffRange.formError = {isValid: true, message: "", dom: null};


        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value : {},
            dropdownValue : "",
            isSelectable : true
        };
        product.items[itemIndex].configuration.fabricColour.options = [];
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
            product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                return {
                    optionKey: o.color,
                    optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                    ...o
                }
            });
        }
        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
            )
        });
        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
            optionIndex = -1;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemWidescreen.fabricColour);
            }
            if (optionIndex === -1 && !product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                    optionIndex = 0;
                }
            }
            if (optionIndex > -1) {
                product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
            }
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};

        //frameColour
        configurationIndex = findIndex(configurations, "attributeKey", "frameColour");
        product.items[itemIndex].configuration.frameColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frameColour.selected = {};
        product.items[itemIndex].configuration.frameColour.options = getSorted(product.items[itemIndex].configuration.frameColour.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.frameColour.finalOptions = product.items[itemIndex].configuration.frameColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, 'optionKey', context.salesOrderItemWidescreen.frameColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
        product.items[itemIndex].configuration.frameColour.formError = {isValid: true, message: "", dom: null,};

        //framePowdercoatColour
        product.items[itemIndex].configuration.framePowdercoatColour = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = context.salesOrderItemWidescreen.framePowdercoatColour;
        } else {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
        }
        product.items[itemIndex].configuration.framePowdercoatColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //keyway
        configurationIndex = findIndex(configurations, "attributeKey", "keyway");
        product.items[itemIndex].configuration.keyway = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.keyway.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.keyway.finalOptions = product.items[itemIndex].configuration.keyway.options;
        product.items[itemIndex].configuration.keyway.selected = {
            value: {},
            dropdownValue: ""
        };

        //headbox
        configurationIndex = findIndex(configurations, "attributeKey", "headbox");
        product.items[itemIndex].configuration.headbox = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.headbox.selected = {};
        product.items[itemIndex].configuration.headbox.finalOptions = product.items[itemIndex].configuration.headbox.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.headbox.finalOptions, 'optionKey', context.salesOrderItemWidescreen.headbox);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.headbox.selected.value = product.items[itemIndex].configuration.headbox.finalOptions[optionIndex];
        product.items[itemIndex].configuration.headbox.selected.dropdownValue = product.items[itemIndex].configuration.headbox.selected.value.optionKey;
        product.items[itemIndex].configuration.headbox.formError = {isValid: true, message: "", dom: null,};

        //headboxSize
        configurationIndex = findIndex(configurations, "attributeKey", "headboxSize");
        product.items[itemIndex].configuration.headboxSize = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.headboxSize.selected = {
            isSelectable: true,
            isVisible: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.headboxSize.finalOptions = product.items[itemIndex].configuration.headboxSize.options.filter(o => o.condition1 === "Dropdown");
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.headboxSize.finalOptions, 'optionKey', context.salesOrderItemWidescreen.headboxSize);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.headboxSize.selected.value = product.items[itemIndex].configuration.headboxSize.finalOptions[optionIndex];
        product.items[itemIndex].configuration.headboxSize.selected.dropdownValue = product.items[itemIndex].configuration.headboxSize.selected.value.optionKey;
        product.items[itemIndex].configuration.headboxSize.formError = {isValid: true, message: "", dom: null,};

        //headboxColour
        configurationIndex = findIndex(configurations, "attributeKey", "headboxColour");
        product.items[itemIndex].configuration.headboxColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.headboxColour.selected = {};
        product.items[itemIndex].configuration.headboxColour.finalOptions = product.items[itemIndex].configuration.headboxColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.headboxColour.finalOptions, 'optionKey', context.salesOrderItemWidescreen.headboxColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.headboxColour.selected.value = product.items[itemIndex].configuration.headboxColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.headboxColour.selected.dropdownValue = product.items[itemIndex].configuration.headboxColour.selected.value.optionKey;
        product.items[itemIndex].configuration.headboxColour.formError = {isValid: true, message: "", dom: null,};

        //headboxColourPowdercoat
        product.items[itemIndex].configuration.headboxColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.headboxColourPowdercoat.selected.value = context.salesOrderItemWidescreen.headboxColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.headboxColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.headboxColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //bracketCover
        configurationIndex = findIndex(configurations, "attributeKey", "bracketCover");
        product.items[itemIndex].configuration.bracketCover = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracketCover.finalOptions = product.items[itemIndex].configuration.bracketCover.options;
        product.items[itemIndex].configuration.bracketCover.selected = {};
        product.items[itemIndex].configuration.bracketCover.selected.isSelectable = false;
        product.items[itemIndex].configuration.bracketCover.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bracketCover.selected.value = context.salesOrderItemWidescreen.bracketCover;
        } else {
            product.items[itemIndex].configuration.bracketCover.selected.value = false;
        }

        //headboxEndCapCover
        configurationIndex = findIndex(configurations, "attributeKey", "headboxEndCapCover");
        product.items[itemIndex].configuration.headboxEndCapCover = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.headboxEndCapCover.finalOptions = product.items[itemIndex].configuration.headboxEndCapCover.options;
        product.items[itemIndex].configuration.headboxEndCapCover.selected = {};
        product.items[itemIndex].configuration.headboxEndCapCover.selected.isSelectable = false;
        product.items[itemIndex].configuration.headboxEndCapCover.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.headboxEndCapCover.selected.value = context.salesOrderItemWidescreen.headboxEndCapCover;
        } else {
            product.items[itemIndex].configuration.headboxEndCapCover.selected.value = false;
        }


        //track
        configurationIndex = findIndex(configurations, "attributeKey", "track");
        product.items[itemIndex].configuration.track = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.track.selected = {};
        product.items[itemIndex].configuration.track.finalOptions = product.items[itemIndex].configuration.track.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.track.finalOptions, 'optionKey', context.salesOrderItemWidescreen.track);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.track.selected.value = product.items[itemIndex].configuration.track.finalOptions[optionIndex];
        product.items[itemIndex].configuration.track.selected.dropdownValue = product.items[itemIndex].configuration.track.selected.value.optionKey;
        product.items[itemIndex].configuration.track.formError = {isValid: true, message: "", dom: null,};

        product.items[itemIndex].configuration.track.selected.innerChannel = {
            deduction: widescreenUtil.initDeduction()
        };
        product.items[itemIndex].configuration.track.selected.innerCover = {
            deduction: widescreenUtil.initDeduction()
        };


        //holdDownKit
        configurationIndex = findIndex(configurations, "attributeKey", "holdDownKit");
        product.items[itemIndex].configuration.holdDownKit = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.holdDownKit.finalOptions = product.items[itemIndex].configuration.holdDownKit.options;
        product.items[itemIndex].configuration.holdDownKit.selected = {};
        product.items[itemIndex].configuration.holdDownKit.selected.isSelectable = false;
        product.items[itemIndex].configuration.holdDownKit.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.holdDownKit.selected.value = context.salesOrderItemWidescreen.holdDownKit;
        } else {
            product.items[itemIndex].configuration.holdDownKit.selected.value = false;
        }


        //baseRail
        configurationIndex = findIndex(configurations, "attributeKey", "baseRail");
        product.items[itemIndex].configuration.baseRail = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.baseRail.selected = {};
        product.items[itemIndex].configuration.baseRail.finalOptions = product.items[itemIndex].configuration.baseRail.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.baseRail.finalOptions, 'optionKey', context.salesOrderItemWidescreen.baseRail);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.baseRail.selected.value = product.items[itemIndex].configuration.baseRail.finalOptions[optionIndex];
        product.items[itemIndex].configuration.baseRail.selected.dropdownValue = product.items[itemIndex].configuration.baseRail.selected.value.optionKey;
        product.items[itemIndex].configuration.baseRail.formError = {isValid: true, message: "", dom: null,};


        //baseRailEndCap
        configurationIndex = findIndex(configurations, "attributeKey", "baseRailEndCap");
        product.items[itemIndex].configuration.baseRailEndCap = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.baseRailEndCap.selected = {};
        product.items[itemIndex].configuration.baseRailEndCap.finalOptions = product.items[itemIndex].configuration.baseRailEndCap.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.baseRailEndCap.finalOptions, 'optionKey', context.salesOrderItemWidescreen.baseRailEndCap);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.baseRailEndCap.selected.value = product.items[itemIndex].configuration.baseRailEndCap.finalOptions[optionIndex];
        product.items[itemIndex].configuration.baseRailEndCap.selected.dropdownValue = product.items[itemIndex].configuration.baseRailEndCap.selected.value.optionKey;
        product.items[itemIndex].configuration.baseRailEndCap.formError = {isValid: true, message: "", dom: null,};


        //baseRailSeal
        configurationIndex = findIndex(configurations, "attributeKey", "baseRailSeal");
        product.items[itemIndex].configuration.baseRailSeal = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.baseRailSeal.selected = {};
        product.items[itemIndex].configuration.baseRailSeal.finalOptions = product.items[itemIndex].configuration.baseRailSeal.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.baseRailSeal.finalOptions, 'optionKey', context.salesOrderItemWidescreen.baseRailSeal);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.baseRailSeal.selected.value = product.items[itemIndex].configuration.baseRailSeal.finalOptions[optionIndex];
        product.items[itemIndex].configuration.baseRailSeal.selected.dropdownValue = product.items[itemIndex].configuration.baseRailSeal.selected.value.optionKey;
        product.items[itemIndex].configuration.baseRailSeal.formError = {isValid: true, message: "", dom: null,};


        //crankHandle
        configurationIndex = findIndex(configurations, "attributeKey", "crankHandle");
        product.items[itemIndex].configuration.crankHandle = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.crankHandle.selected = {};
        product.items[itemIndex].configuration.crankHandle.options = getSorted(product.items[itemIndex].configuration.crankHandle.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.crankHandle.finalOptions = product.items[itemIndex].configuration.crankHandle.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', context.salesOrderItemWidescreen.crankHandle);
        }
        optionIndex = optionIndex > -1 ? optionIndex : findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', "1600mm White");
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
        product.items[itemIndex].configuration.crankHandle.formError = {isValid: true, message: "", dom: null,};


        //windoware15ChannelRemote
        configurationIndex = findIndex(configurations, "attributeKey", "windoware15ChannelRemote");
        product.items[itemIndex].configuration.windoware15ChannelRemote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windoware15ChannelRemote.finalOptions = product.items[itemIndex].configuration.windoware15ChannelRemote.options;

        product.items[itemIndex].configuration.windoware15ChannelRemote.selected = {};
        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = context.salesOrderItemWidescreen.windoware15ChannelRemote;
        } else {
            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = false;
        }


        //windowareBridge
        configurationIndex = findIndex(configurations, "attributeKey", "windowareBridge");
        product.items[itemIndex].configuration.windowareBridge = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windowareBridge.finalOptions = product.items[itemIndex].configuration.windowareBridge.options;
        product.items[itemIndex].configuration.windowareBridge.selected = {};
        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.windowareBridge.selected.value = context.salesOrderItemWidescreen.windowareBridge;
        } else {
            product.items[itemIndex].configuration.windowareBridge.selected.value = false;
        }

        //chargingCable
        configurationIndex = findIndex(configurations, "attributeKey", "chargingCable");
        product.items[itemIndex].configuration.chargingCable = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.chargingCable.finalOptions = product.items[itemIndex].configuration.chargingCable.options;
        product.items[itemIndex].configuration.chargingCable.selected = {};
        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.chargingCable.selected.value = context.salesOrderItemWidescreen.chargingCable;
        } else {
            product.items[itemIndex].configuration.chargingCable.selected.value = false;
        }

        //somfy1ChannelSituoRemote
        configurationIndex = findIndex(configurations, "attributeKey", "somfy1ChannelSituoRemote");
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.finalOptions = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.options;
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected = {};
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;
        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = context.salesOrderItemWidescreen.somfy1ChannelSituoRemote;
        } else {
            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = false;
        }


        //outerChannel
        configurationIndex = findIndex(configurations, "attributeKey", "outerChannel");
        product.items[itemIndex].configuration.outerChannel = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.outerChannel.selected = {};
        product.items[itemIndex].configuration.outerChannel.finalOptions = product.items[itemIndex].configuration.outerChannel.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.outerChannel.finalOptions, 'optionKey', context.salesOrderItemWidescreen.outerChannel);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.outerChannel.selected.value = product.items[itemIndex].configuration.outerChannel.finalOptions[optionIndex];
        product.items[itemIndex].configuration.outerChannel.selected.dropdownValue = product.items[itemIndex].configuration.outerChannel.selected.value.optionKey;
        product.items[itemIndex].configuration.outerChannel.formError = {isValid: true, message: "", dom: null,};
        product.items[itemIndex].configuration.outerChannel.selected.outerUChannel = {
            deduction: widescreenUtil.initDeduction()
        };
        product.items[itemIndex].configuration.outerChannel.selected.outerLChannel = {
            deduction: widescreenUtil.initDeduction()
        };

        //channelColour
        configurationIndex = findIndex(configurations, "attributeKey", "channelColour");
        product.items[itemIndex].configuration.channelColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channelColour.selected = {};
        product.items[itemIndex].configuration.channelColour.finalOptions = product.items[itemIndex].configuration.channelColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channelColour.finalOptions, 'optionKey', context.salesOrderItemWidescreen.channelColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
        product.items[itemIndex].configuration.channelColour.formError = {isValid: true, message: "", dom: null,};

        //channelColourPowdercoat
        product.items[itemIndex].configuration.channelColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = context.salesOrderItemWidescreen.channelColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.channelColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //consumable
        configurationIndex = findIndex(configurations, "attributeKey", "consumable");
        product.items[itemIndex].configuration.consumable = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.consumable.finalOptions = product.items[itemIndex].configuration.consumable.options;
        product.items[itemIndex].configuration.consumable.selected = {};

        product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("headboxSize", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("headboxColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("track", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("baseRailSeal", product, itemIndex, order, false);
        product = this.updateItemConfigurationOptions("baseRailEndCap", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("outerChannel", product, itemIndex, order, true);


        product = widescreenUtil.updateRules("location", product, itemIndex, order);
        product = widescreenUtil.updateRules("width", product, itemIndex, order);
        product = widescreenUtil.updateRules("drop", product, itemIndex, order);
        product = widescreenUtil.updateRules("fabric", product, itemIndex, order);
        product = widescreenUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
        product = widescreenUtil.updateRules("crankGear", product, itemIndex, order);
        product = widescreenUtil.updateRules("crankHandle", product, itemIndex, order);
        product = widescreenUtil.updateRules("frameColour", product, itemIndex, null);
        product = widescreenUtil.updateRules("headboxColourPowdercoat", product, itemIndex, null);
        product = widescreenUtil.updateRules("motorAccessories", product, itemIndex, order);
        product = widescreenUtil.updateRules("headboxAccessories", product, itemIndex, order);

        product = widescreenUtil.updateRules("bracketCover", product, itemIndex, order);
        product = widescreenUtil.updateRules("headboxEndCapCover", product, itemIndex, order);
        product = widescreenUtil.updateRules("holdDownKit", product, itemIndex, order);

        product = widescreenUtil.updateRules("channelColour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
        product = widescreenUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);
        product = widescreenUtil.updateFormError("channelColourPowdercoat", product, itemIndex);

        product = widescreenUtil.updateFormError("operation", product, itemIndex);
        product = widescreenUtil.updateFormError("fabricColourOffRange", product, itemIndex);
        product = widescreenUtil.updateFormError("headboxColourPowdercoat", product, itemIndex);

        product = widescreenUtil.updateFormError("width", product, itemIndex, order);
        product = widescreenUtil.updateFormError("drop", product, itemIndex, order);
        product = widescreenUtil.updateFormError("frameColour", product, itemIndex);
        product = widescreenUtil.updateFormError("location", product, itemIndex, order);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let {order, currentUser} = this.props;

        let optionIndex = -1, setIndex = -1, sets = [],
            bOMCode = "", condition1 = "", condition2 = "",
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = product.items[itemIndex].configuration.drop.selected.value,
            isMotorised = widescreenUtil.isMotorised(product, itemIndex, order);


        if (key) {
            switch (key) {
                case "operation":
                    optionIndex = -1;
                    product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options.filter(o =>
                        o.condition1.includes(product.items[itemIndex].configuration.model.selected.value.optionKey)
                        && (width >= o.primaryAttributeMin)
                        && (width <= o.primaryAttributeMax)
                        && (drop >= o.secondaryAttributeMin)
                        && (drop <= o.secondaryAttributeMax)
                    );

                    //update selection, getting previous selection
                    if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                        optionIndex = product.items[itemIndex].configuration.operation.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.operation.selected.value.optionKey);
                    }

                    //if not found, getting ideal selection
                    if (optionIndex === -1) {
                        if (isMotorised) {
                            optionIndex = product.items[itemIndex].configuration.operation.finalOptions.findIndex(o => o.optionKey.includes("Motor"));
                        }
                    }
                    //if couldn't get first item
                    if (!isEmpty(product.items[itemIndex].configuration.operation.finalOptions)) {
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    product.items[itemIndex].configuration.operation.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.operation.finalOptions[optionIndex] : null;
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    break;
                case "frameColour":
                    product.items[itemIndex].configuration.frameColour.finalOptions = product.items[itemIndex].configuration.frameColour.options;

                    //update selection, getting ideal selection
                    optionIndex = product.items[itemIndex].configuration.frameColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.frameColour.selected.value.optionKey);

                    //if couldn't get first item
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColour.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.frameColour.selected.value.optionKey : "";
                    break;
                case "keyway":
                    optionIndex = product.items[itemIndex].configuration.keyway.options.findIndex(o => o.condition1 === "Matrix");
                    if (optionIndex > -1) {
                        condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                        setIndex = product.items[itemIndex].configuration.keyway.options[optionIndex].sets.findIndex(s => s.condition1 === condition1 && width >= s.min && width <= s.max && drop >= s.secondaryMin && drop <= s.secondaryMax);
                        setIndex = setIndex > -1 ? setIndex : 0;
                        optionIndex = product.items[itemIndex].configuration.keyway.options.findIndex(o => o.optionKey === product.items[itemIndex].configuration.keyway.options[optionIndex].sets[setIndex].setKey);
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.keyway.selected.value = product.items[itemIndex].configuration.keyway.options[optionIndex];
                        product.items[itemIndex].configuration.keyway.selected.dropdownValue = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
                    }
                    break;
                case "headboxSize":
                    if (product.items[itemIndex].configuration.headboxSize.selected.isSelectable) {
                        optionIndex = product.items[itemIndex].configuration.headboxSize.options.findIndex(o => o.condition1 === "Matrix");
                        if (optionIndex > -1) {
                            condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                            condition2 = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
                            sets = product.items[itemIndex].configuration.headboxSize.options[optionIndex].sets.filter(s => s.condition1.includes(condition1) && s.condition2.includes(condition2));
                            if (!isEmpty(sets)) {
                                product.items[itemIndex].configuration.headboxSize.finalOptions = product.items[itemIndex].configuration.headboxSize.options.filter(o => o.condition1 === "Dropdown" && sets.some(set => set.setKey === o.optionKey));
                            }
                            if (!isEmpty(product.items[itemIndex].configuration.headboxSize.finalOptions)) {
                                optionIndex = -1;
                                if (!isEmpty(product.items[itemIndex].configuration.headboxSize.selected.value)) {
                                    optionIndex = product.items[itemIndex].configuration.headboxSize.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.headboxSize.selected.value.optionKey);
                                }
                                optionIndex = optionIndex > -1 ? optionIndex : 0;
                                product.items[itemIndex].configuration.headboxSize.selected.value = product.items[itemIndex].configuration.headboxSize.finalOptions[optionIndex];
                                product.items[itemIndex].configuration.headboxSize.selected.dropdownValue = product.items[itemIndex].configuration.headboxSize.selected.value.optionKey;

                            }
                        }
                    }
                    break;
                case "headboxColour":
                    if (product.items[itemIndex].configuration.headboxColour.selected.isSelectable) {
                        if (isEmpty(product.items[itemIndex].configuration.headboxColour.selected.value)) {
                            optionIndex = 0;
                            product.items[itemIndex].configuration.headboxColour.selected.value = product.items[itemIndex].configuration.headboxColour.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.headboxColour.selected.dropdownValue = product.items[itemIndex].configuration.headboxColour.selected.value.optionKey;
                        }
                    }
                    break;
                case "channelColour":
                    if (product.items[itemIndex].configuration.channelColour.selected.isSelectable) {
                        if (isEmpty(product.items[itemIndex].configuration.channelColour.selected.value)) {
                            optionIndex = 0;
                            product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                        }
                    }
                    break;
                case "track":
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.track.finalOptions = product.items[itemIndex].configuration.track.options.filter(o => o.condition1 === condition1);
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.track.selected.value)) {
                        optionIndex = product.items[itemIndex].configuration.track.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.track.selected.value.optionKey)
                    }
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.track.selected.value = product.items[itemIndex].configuration.track.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.track.selected.dropdownValue = product.items[itemIndex].configuration.track.selected.value.optionKey;
                    break;
                case "baseRailSeal":
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.baseRailSeal.finalOptions = product.items[itemIndex].configuration.baseRailSeal.options.filter(o => o.condition1.includes(condition1));
                    optionIndex = -1;


                    if (isResetSelectionRequired) {
                        optionIndex = product.items[itemIndex].configuration.baseRailSeal.finalOptions.findIndex(o => o.optionKey === "10mm Bubble Seal");
                    }
                    if (optionIndex === -1) {
                        if (!isEmpty(product.items[itemIndex].configuration.baseRailSeal.selected.value)) {
                            optionIndex = product.items[itemIndex].configuration.baseRailSeal.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.baseRailSeal.selected.value.optionKey)
                        }
                    }
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.baseRailSeal.selected.value = product.items[itemIndex].configuration.baseRailSeal.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.baseRailSeal.selected.dropdownValue = product.items[itemIndex].configuration.baseRailSeal.selected.value.optionKey;
                    break;
                case "baseRailEndCap":
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Cable":
                            product.items[itemIndex].configuration.baseRailEndCap.selected.isSelectable = true;
                            product.items[itemIndex].configuration.baseRailEndCap.selected.isVisible = true;
                            break;
                        case "Zip":
                            product.items[itemIndex].configuration.baseRailEndCap.selected.isSelectable = false;
                            product.items[itemIndex].configuration.baseRailEndCap.selected.isVisible = false;
                            break;
                    }

                    if (product.items[itemIndex].configuration.baseRailEndCap.selected.isSelectable) {
                        if (isEmpty(product.items[itemIndex].configuration.baseRailEndCap.selected.value)) {
                            product.items[itemIndex].configuration.baseRailEndCap.selected.value = product.items[itemIndex].configuration.baseRailEndCap.finalOptions[0];
                            product.items[itemIndex].configuration.baseRailEndCap.selected.dropdownValue = product.items[itemIndex].configuration.baseRailEndCap.selected.value.optionKey;
                        }
                    } else {
                        product.items[itemIndex].configuration.baseRailEndCap.selected.value = {};
                        product.items[itemIndex].configuration.baseRailEndCap.selected.dropdownValue = {};
                    }
                    break;
                case "fabricType":
                    product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options.filter(o => o.primaryBMCode.includes(product.items[itemIndex].configuration.model.selected.value.optionKey));
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                            optionIndex = product.items[itemIndex].configuration.fabricType.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.fabricType.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    }
                    break;
                case "fabricColour":
                    product.items[itemIndex].configuration.fabricColour.options = [];
                    product.items[itemIndex].configuration.fabricColour.selected.value = {};
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";

                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                            return {
                                optionKey: o.color,
                                optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                                ...o
                            }
                        });
                    }
                    product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
                        return (
                            salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                        )
                    });
                    if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                        if (optionIndex > -1) {
                            product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                        }
                    }
                    break;
                case "outerChannel":
                    if (product.items[itemIndex].configuration.track.selected.value.optionKey === "Reveal Fit") {
                        product.items[itemIndex].configuration.outerChannel.selected.isSelectable = true;
                        product.items[itemIndex].configuration.outerChannel.selected.isVisible = true;
                    } else {
                        product.items[itemIndex].configuration.outerChannel.selected.isSelectable = false;
                        product.items[itemIndex].configuration.outerChannel.selected.isVisible = false;
                    }

                    if (product.items[itemIndex].configuration.outerChannel.selected.isSelectable) {
                        if (isEmpty(product.items[itemIndex].configuration.outerChannel.selected.value)) {
                            product.items[itemIndex].configuration.outerChannel.selected.value = product.items[itemIndex].configuration.outerChannel.finalOptions[0];
                            product.items[itemIndex].configuration.outerChannel.selected.dropdownValue = product.items[itemIndex].configuration.outerChannel.selected.value.optionKey;
                        }
                    } else {
                        product.items[itemIndex].configuration.outerChannel.selected.value = {};
                        product.items[itemIndex].configuration.outerChannel.selected.dropdownValue = {};
                    }
                    break;
                case "basePrice":
                    bOMCode = product.items[itemIndex].configuration.model.selected.value.optionKey + product.items[itemIndex].configuration.fabricType.selected.value.category;
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                        return x.bmCode === bOMCode;
                    });
                    if (isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                            return x.bmCode.includes(product.items[itemIndex].configuration.model.selected.value.optionKey);
                        });
                    }

                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= width);

                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= drop);

                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price;

                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = widescreenUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = widescreenUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("headboxSize", product, itemIndex, order, true);

                product = widescreenUtil.updateRules("crankGear", product, itemIndex, order);
                product = widescreenUtil.updateRules("crankHandle", product, itemIndex, order);
                product = widescreenUtil.updateRules("motorAccessories", product, itemIndex, order);
                product = widescreenUtil.updateRules("width", product, itemIndex, order);
                product = widescreenUtil.updateRules("drop", product, itemIndex, order);

                product = widescreenUtil.updateFormError("width", product, itemIndex, order);
                product = widescreenUtil.updateFormError("drop", product, itemIndex, order);
                product = widescreenUtil.updateFormError("operation", product, itemIndex, order);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("headboxSize", product, itemIndex, order, true);

                product = widescreenUtil.updateRules("crankGear", product, itemIndex, order);
                product = widescreenUtil.updateRules("crankHandle", product, itemIndex, order);
                product = widescreenUtil.updateRules("motorAccessories", product, itemIndex, order);
                product = widescreenUtil.updateRules("width", product, itemIndex, order);
                product = widescreenUtil.updateRules("drop", product, itemIndex, order);

                product = widescreenUtil.updateFormError("drop", product, itemIndex, order);
                product = widescreenUtil.updateFormError("operation", product, itemIndex, order);
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = widescreenUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("headboxSize", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("track", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("baseRailSeal", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("baseRailEndCap", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("outerChannel", product, itemIndex, order, true);

                    product = widescreenUtil.updateRules("channelColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
                    product = widescreenUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);
                    product = widescreenUtil.updateFormError("channelColourPowdercoat", product, itemIndex);

                    product = widescreenUtil.updateRules("headboxEndCapCover", product, itemIndex, order);
                    product = widescreenUtil.updateRules("holdDownKit", product, itemIndex, order);
                    product = widescreenUtil.updateRules("crankGear", product, itemIndex, order);
                    product = widescreenUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = widescreenUtil.updateRules("motorAccessories", product, itemIndex, order);
                    product = widescreenUtil.updateRules("width", product, itemIndex, order);
                    product = widescreenUtil.updateRules("drop", product, itemIndex, order);

                    product = widescreenUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                    product = widescreenUtil.updateFormError("width", product, itemIndex, order);
                    product = widescreenUtil.updateFormError("drop", product, itemIndex, order);
                    product = widescreenUtil.updateFormError("operation", product, itemIndex, order);
                }
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("headboxSize", product, itemIndex, order, true);

                    product = widescreenUtil.updateRules("width", product, itemIndex, order);
                    product = widescreenUtil.updateRules("drop", product, itemIndex, order);
                    product = widescreenUtil.updateRules("crankGear", product, itemIndex, order);
                    product = widescreenUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = widescreenUtil.updateRules("motorAccessories", product, itemIndex, order);

                    product = widescreenUtil.updateFormError("width", product, itemIndex, order);
                    product = widescreenUtil.updateFormError("drop", product, itemIndex, order);
                    product = widescreenUtil.updateFormError("operation", product, itemIndex, order);
                }
                break;
            case "controlSide":
                optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                }
                break;
            case "crankGear":
                optionIndex = findIndex(product.items[itemIndex].configuration.crankGear.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.crankGear.selected.value = product.items[itemIndex].configuration.crankGear.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.crankGear.selected.dropdownValue = product.items[itemIndex].configuration.crankGear.selected.value.optionKey;
                }
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = widescreenUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = widescreenUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                }
                break;
            case "fabricColour":
                product.items[itemIndex].configuration.fabricColour.selected.value = {};
                product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";

                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                product = widescreenUtil.updateFormError("fabric", product, itemIndex);
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.value = change;
                product = widescreenUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                break;
            case "frameColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;

                    product = widescreenUtil.updateRules("frameColour", product, itemIndex, null);
                    product = widescreenUtil.updateFormError("frameColour", product, itemIndex);
                }
                break;
            case "framePowdercoatColour":
                product.items[itemIndex].configuration.framePowdercoatColour.selected.value = change;
                product = widescreenUtil.updateFormError("frameColour", product, itemIndex);
                break;

            case "track":
                optionIndex = findIndex(product.items[itemIndex].configuration.track.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.track.selected.value = product.items[itemIndex].configuration.track.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.track.selected.dropdownValue = product.items[itemIndex].configuration.track.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("outerChannel", product, itemIndex, order, true);
                }
                break;
            case "headbox":
                optionIndex = findIndex(product.items[itemIndex].configuration.headbox.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.headbox.selected.value = product.items[itemIndex].configuration.headbox.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.headbox.selected.dropdownValue = product.items[itemIndex].configuration.headbox.selected.value.optionKey;
                }
                product = widescreenUtil.updateRules("headboxAccessories", product, itemIndex, order);
                product = widescreenUtil.updateRules("bracketCover", product, itemIndex, order);
                product = widescreenUtil.updateRules("headboxEndCapCover", product, itemIndex, order);

                product = this.updateItemConfigurationOptions("headboxSize", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("headboxColour", product, itemIndex, order, true);

                product = widescreenUtil.updateRules("headboxEndCapCover", product, itemIndex, order);
                break;
            case "headboxSize":
                optionIndex = findIndex(product.items[itemIndex].configuration.headboxSize.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.headboxSize.selected.value = product.items[itemIndex].configuration.headboxSize.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.headboxSize.selected.dropdownValue = product.items[itemIndex].configuration.headboxSize.selected.value.optionKey;
                    product = widescreenUtil.updateRules("headboxEndCapCover", product, itemIndex, order);
                }
                break;
            case "headboxColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.headboxColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.headboxColour.selected.value = product.items[itemIndex].configuration.headboxColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.headboxColour.selected.dropdownValue = product.items[itemIndex].configuration.headboxColour.selected.value.optionKey;
                    product = widescreenUtil.updateRules("headboxColourPowdercoat", product, itemIndex, null);
                    product = widescreenUtil.updateFormError("headboxColourPowdercoat", product, itemIndex);
                }
                break;
            case "headboxColourPowdercoat":
                product.items[itemIndex].configuration.headboxColourPowdercoat.selected.value = change;
                product = widescreenUtil.updateFormError("headboxColourPowdercoat", product, itemIndex);
                break;
            case "channelColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.channelColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                    product = widescreenUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);
                    product = widescreenUtil.updateFormError("channelColourPowdercoat", product, itemIndex);
                }
                break;
            case "channelColourPowdercoat":
                product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = change;
                product = widescreenUtil.updateFormError("channelColourPowdercoat", product, itemIndex);
                break;
            case "holdDownKit":
                product.items[itemIndex].configuration.holdDownKit.selected.value = change;
                break;
            case "baseRailEndCap":
                optionIndex = findIndex(product.items[itemIndex].configuration.baseRailEndCap.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.baseRailEndCap.selected.value = product.items[itemIndex].configuration.baseRailEndCap.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.baseRailEndCap.selected.dropdownValue = product.items[itemIndex].configuration.baseRailEndCap.selected.value.optionKey;
                }
                break;
            case "baseRailSeal":
                optionIndex = findIndex(product.items[itemIndex].configuration.baseRailSeal.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.baseRailSeal.selected.value = product.items[itemIndex].configuration.baseRailSeal.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.baseRailSeal.selected.dropdownValue = product.items[itemIndex].configuration.baseRailSeal.selected.value.optionKey;
                }
                break;
            case "crankHandle":
                optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                }
                break;
            case "outerChannel":
                optionIndex = findIndex(product.items[itemIndex].configuration.outerChannel.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.outerChannel.selected.value = product.items[itemIndex].configuration.outerChannel.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.outerChannel.selected.dropdownValue = product.items[itemIndex].configuration.outerChannel.selected.value.optionKey;
                }
                break;
            case "windoware15ChannelRemote":
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = change;
                break;
            case "windowareBridge":
                product.items[itemIndex].configuration.windowareBridge.selected.value = change;
                break;
            case "chargingCable":
                product.items[itemIndex].configuration.chargingCable.selected.value = change;
                break;
            case "somfy1ChannelSituoRemote":
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = change;
                break;
            case "bracketCover":
                product.items[itemIndex].configuration.bracketCover.selected.value = change;
                break;
            case "headboxEndCapCover":
                product.items[itemIndex].configuration.headboxEndCapCover.selected.value = change;
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = widescreenUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }


    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}} className={"text-center"}>
                                        #
                                    </th>
                                    <th style={{minWidth: 150}} className={"text-center"}>
                                        Location
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Quantity
                                    </th>
                                    <th style={{minWidth: 170}} className={"text-center"}>
                                        Model
                                    </th>
                                    <th style={{minWidth: 300}} className={"text-center"}>
                                        Operation
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"text-center"}>
                                            Control/Motor
                                        </div>
                                        <div className={"text-center"}>
                                            Side
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Width(mm)
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Drop(mm)
                                    </th>
                                    <th style={{minWidth: 250}} className={"text-center"}>
                                        Fabric
                                    </th>
                                    <th style={{minWidth: 190}} className={"text-center"}>
                                        Headbox
                                    </th>
                                    <th style={{minWidth: 245}} className={"text-center"}>
                                        Installation Type
                                    </th>
                                    <th style={{minWidth: 215}} className={"text-center"}>
                                        Channel Colour
                                    </th>
                                    <th style={{minWidth: 215}} className={"text-center"}>
                                        Base Rail Colour/Finish
                                    </th>
                                    <th style={{minWidth: 190}} className={"text-center"}>
                                        Crank Handle
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null

                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="text" name="location"
                                                               value={item.configuration.location.selected.value}
                                                               onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                               placeholder="location"/>
                                                        {
                                                            item.configuration.location.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            item.configuration.location.formError.isWarning
                                                                ? <Badge color="warning">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                                : null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="number" name="quantity"
                                                               invalid={!item.configuration.quantity.formError.isValid}
                                                               value={item.configuration.quantity.selected.value}
                                                               min={item.configuration.quantity.min}
                                                               max={item.configuration.quantity.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="quantity"/>

                                                        {
                                                            item.configuration.quantity.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.quantity.formError.message}
                                                                </Badge>
                                                        }
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="model"
                                                           value={item.configuration.model.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "model", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="operation"
                                                           value={item.configuration.operation.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "operation", itemIndex, true)}>
                                                        <option value={""} disabled>Select operation</option>

                                                        {
                                                            (item.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {widescreenUtil.isMotorised(product, itemIndex)
                                                        ? <div className="mt-2">
                                                            {
                                                                widescreenUtil.isVisibleMotorAccessories("Windoware", product, itemIndex)
                                                                    ? <div className="mt-1">
                                                                        <strong>Windoware Accessories</strong>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.windoware15ChannelRemote.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.windoware15ChannelRemote.selected.value, "windoware15ChannelRemote", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.windoware15ChannelRemote.selected.value,
                                                                                        "fa-square-o": !item.configuration.windoware15ChannelRemote.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.windoware15ChannelRemote.attributeLabel}
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.windowareBridge.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.windowareBridge.selected.value, "windowareBridge", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.windowareBridge.selected.value,
                                                                                        "fa-square-o": !item.configuration.windowareBridge.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.windowareBridge.attributeLabel}
                                                                        </div>
                                                                        {
                                                                            item.configuration.chargingCable.selected.isSelectable
                                                                                ? <div>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        className={"mr-2 mb-1"}
                                                                                        color={"link"}
                                                                                        disabled={!item.configuration.chargingCable.selected.isSelectable}
                                                                                        onClick={() => this.handleChange(!item.configuration.chargingCable.selected.value, "chargingCable", itemIndex, true)}>
                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                "fa-check-square-o": item.configuration.chargingCable.selected.value,
                                                                                                "fa-square-o": !item.configuration.chargingCable.selected.value,
                                                                                            }
                                                                                        )}/>
                                                                                    </Button>
                                                                                    {item.configuration.chargingCable.attributeLabel}
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                widescreenUtil.isVisibleMotorAccessories("Somfy", product, itemIndex)
                                                                    ? <div className="mt-1">
                                                                        <strong>Somfy Accessories</strong>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.somfy1ChannelSituoRemote.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.somfy1ChannelSituoRemote.selected.value, "somfy1ChannelSituoRemote", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.somfy1ChannelSituoRemote.selected.value,
                                                                                        "fa-square-o": !item.configuration.somfy1ChannelSituoRemote.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.somfy1ChannelSituoRemote.attributeLabel}
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        : <div>
                                                            {
                                                                item.configuration.crankGear.selected.isSelectable
                                                                    ?
                                                                    <div className="mt-1">
                                                                        <Input className={"mt-2"} type="select"
                                                                               name="crankGear"
                                                                               value={item.configuration.crankGear.selected.dropdownValue}
                                                                               onChange={(e) => this.handleChange(e.target.value, "crankGear", itemIndex, true)}>
                                                                            <option value={""} disabled>Select Gear Box
                                                                                Colour
                                                                            </option>
                                                                            {
                                                                                (item.configuration.crankGear.finalOptions || []).map((o, oIndex) => {
                                                                                    return <option
                                                                                        key={"item" + itemIndex + "-option" + oIndex}
                                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input className={"mt-2"} type="select" name="controlSide"
                                                           value={item.configuration.controlSide.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "controlSide", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.controlSide.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="width"
                                                           invalid={!item.configuration.width.formError.isValid}
                                                           value={item.configuration.width.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="width"/>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="drop"
                                                           invalid={!item.configuration.drop.formError.isValid}
                                                           value={item.configuration.drop.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="drop"/>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="select" name="fabricType"
                                                               disabled={!item.configuration.fabricType.selected.isSelectable}
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select range</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {isEmpty(item.configuration.fabricColour.finalOptions)
                                                        ? null
                                                        : <div className={"mt-2"}>
                                                            <Input type="select" name="fabricColour"
                                                                   disabled={!item.configuration.fabricColour.selected.isSelectable}
                                                                   value={item.configuration.fabricColour.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                <option value={""}>Select colour</option>
                                                                {
                                                                    (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                    }

                                                    {
                                                        item.configuration.fabricColourOffRange.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="fabricColourOffRange"
                                                                       value={item.configuration.fabricColourOffRange.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColourOffRange", itemIndex, true)}
                                                                       placeholder="Fabric name"
                                                                       title={"Enter fabric name"}
                                                                />
                                                                {
                                                                    item.configuration.fabricColourOffRange.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.fabricColourOffRange.formError.message}
                                                                        </Badge>
                                                                }
                                                                <div>
                                                                    <Badge color="info">
                                                                        <i className="fa fa-info-circle mr-2"
                                                                           aria-hidden="true"/>
                                                                        Price On Application
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="headbox"
                                                           value={item.configuration.headbox.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "headbox", itemIndex, true)}>
                                                        <option value={""} disabled>Select type</option>
                                                        {
                                                            (item.configuration.headbox.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.headboxSize.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="headboxSize"
                                                                       value={item.configuration.headboxSize.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "headboxSize", itemIndex, true)}>
                                                                    <option value={""} disabled>Select size</option>
                                                                    {
                                                                        (item.configuration.headboxSize.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null

                                                    }
                                                    {
                                                        item.configuration.headboxColour.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="headboxColour"
                                                                       value={item.configuration.headboxColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "headboxColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select colour</option>
                                                                    {
                                                                        (item.configuration.headboxColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        item.configuration.headboxColourPowdercoat.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="headboxColourPowdercoat"
                                                                       value={item.configuration.headboxColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "headboxColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.headboxColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.headboxColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bracketCover.selected.isSelectable
                                                            ? <div className="mt-1">
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    disabled={!item.configuration.bracketCover.selected.isSelectable}
                                                                    onClick={() => this.handleChange(!item.configuration.bracketCover.selected.value, "bracketCover", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.bracketCover.selected.value,
                                                                            "fa-square-o": !item.configuration.bracketCover.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                Bracket Cover
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.headboxEndCapCover.selected.isSelectable
                                                            ? <div className="mt-1">
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    disabled={!item.configuration.headboxEndCapCover.selected.isSelectable}
                                                                    onClick={() => this.handleChange(!item.configuration.headboxEndCapCover.selected.value, "headboxEndCapCover", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.headboxEndCapCover.selected.value,
                                                                            "fa-square-o": !item.configuration.headboxEndCapCover.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                End Cap Cover
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="track"
                                                           value={item.configuration.track.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "track", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.track.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {item.configuration.holdDownKit.selected.isVisible
                                                        ?
                                                        <div className="mt-1">
                                                            <Button
                                                                size={"sm"}
                                                                className={"mr-2 mb-1"}
                                                                color={"link"}
                                                                disabled={!item.configuration.holdDownKit.selected.isSelectable}
                                                                onClick={() => this.handleChange(!item.configuration.holdDownKit.selected.value, "holdDownKit", itemIndex, true)}>
                                                                <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": item.configuration.holdDownKit.selected.value,
                                                                        "fa-square-o": !item.configuration.holdDownKit.selected.value,
                                                                    }
                                                                )}/>
                                                            </Button>
                                                            Hold Down Kit
                                                        </div>
                                                        : null
                                                    }
                                                    {
                                                        item.configuration.outerChannel.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="outerChannel"
                                                                       value={item.configuration.outerChannel.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "outerChannel", itemIndex, true)}>
                                                                    <option value={""} disabled>Select</option>

                                                                    {
                                                                        (item.configuration.outerChannel.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="channelColour"
                                                           disabled={!item.configuration.channelColour.selected.isSelectable}
                                                           value={item.configuration.channelColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "channelColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select colour</option>
                                                        {
                                                            (item.configuration.channelColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.channelColourPowdercoat.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="channelColourPowdercoat"
                                                                       value={item.configuration.channelColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "channelColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.channelColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.channelColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="frameColour"
                                                           value={item.configuration.frameColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "frameColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.frameColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.framePowdercoatColour.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="framePowdercoatColour"
                                                                       value={item.configuration.framePowdercoatColour.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "framePowdercoatColour", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.framePowdercoatColour.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.framePowdercoatColour.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.baseRailEndCap.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="baseRailEndCap"
                                                                       value={item.configuration.baseRailEndCap.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "baseRailEndCap", itemIndex, true)}>
                                                                    <option value={""} disabled>Select</option>

                                                                    {
                                                                        (item.configuration.baseRailEndCap.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }

                                                    <div className={"mt-2"}>

                                                        <Input type="select" name="baseRailSeal"
                                                               value={item.configuration.baseRailSeal.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "baseRailSeal", itemIndex, true)}>
                                                            <option value={""} disabled>Select</option>

                                                            {
                                                                (item.configuration.baseRailSeal.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={"item" + itemIndex + "-option" + oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="crankHandle"
                                                           disabled={!product.items[itemIndex].configuration.crankHandle.selected.isSelectable}
                                                           value={item.configuration.crankHandle.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "crankHandle", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.crankHandle.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={15}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <WidescreenBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <WidescreenConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
