import React, {Component} from "react"
import {getFinancialYearList} from "../services/CommonService";
import Select from 'react-select';
import {isEmpty} from 'lodash';

export default class FinancialYearDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultSelectedYear: props.defaultSelectedYear,
            selectedYear: "",
            selected: null,
            options: [],
        };
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.fetchOptions();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultSelectedYear !== nextProps.defaultSelectedYear || this.state.selectedYear !== nextProps.selectedYear) {
            this.setState({
                defaultSelectedYear: nextProps.defaultSelectedYear,
                selectedYear: nextProps.selectedYear
            }, () => {
                this.fetchOptions();
            })
        }
    }

    fetchOptions() {
        let options = [];
        let option = {};
        let {selected} = this.state;
        let financialYears = getFinancialYearList(new Date(), 10);
        (financialYears || []).forEach(financialYear => {
            option = {'label': financialYear, 'value': financialYear};
            if (this.props.defaultSelectedYear) {
                if (option.value === this.props.defaultSelectedYear) {
                    if (isEmpty(selected) || (selected && selected.value !== option.value)) {
                        this.handleSelect(option, "default");
                    }
                }
            }
            if (this.props.selectedYear) {
                if (option.value === this.props.selectedYear) {
                    this.handleSelect(option, "selected");
                }
            }
            options.push(option);
        });
        this.setState({options});
    }


    handleSelect(selectedOption, event) {
        switch (event) {
            case "selected":
                this.setState({selected: selectedOption, selectedYear: selectedOption.value});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected: selectedOption, selectedYear: selectedOption.value}, () => {
                    this.props.handleChange(selectedOption.value);
                });
        }
    };

    render() {
        let {selected, options} = this.state;
        return (
            <Select
                options={options}
                value={selected}
                placeholder={"Select FY Year"}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                closeMenuOnSelect={true}
                isSearchable={false}
            />
        );
    }
}