import React, { Component } from "react";
import warehouseLocationService from "../../services/WINOInventory/WarehouseService";
import AsyncSelect from "react-select/async";
import cloneDeep from "lodash/cloneDeep";

export default class SearchWarehouseLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: {},
            options: [],
            locationID: props.locationID,
            defaultLocationID: props.defaultLocationID,
            filter: {
                name: this.props.name,
                locationTypeID: props.locationTypeID,
            },
        };

        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        this.fetchOptions(this.state.name);
    }

    componentWillReceiveProps(nextProps) {
        let { filter, locationID, selected } = this.state;
        if (
            filter.locationTypeID !== nextProps.locationTypeID ||
            filter.name !== nextProps.name ||
            locationID !== nextProps.locationID
        ) {
            filter.locationTypeID = nextProps.locationTypeID;
            filter.name = nextProps.name;
            locationID = nextProps.locationID;
            selected = null;
            this.setState({ filter, locationID, selected }, () => {
                this.fetchOptions(filter.name ? filter.name : "");
            });
        }
    }

    handleSelect = (selected, key) => {
        let locationID = selected ? selected.value : null;
        this.setState({ selected: selected, locationID });

        if (selected) {
            switch (key) {
                case "selected":
                    break;
                case "onChange":
                case "default":
                default:
                    this.props.handleSelection(selected);
                    break;
            }
        } else {
            this.fetchOptions("");
        }
    };

    fetchOptions(inputValue, callback) {
        let { selected, locationID } = this.state;
        let options = [];
        if (typeof inputValue === "string" && !inputValue) {
            inputValue = "";
        }
        let request = {
            name: inputValue,
            locationID: locationID,
            locationTypeID: this.state.filter.locationTypeID,
        };
        warehouseLocationService
            .getWarehouseLocationDropdownOptions(request)
            .then((response) => {
                for (let i in response.data) {
                    let temp = {
                        label: response.data[i].name,
                        value: response.data[i].locationID,
                    };
                    options.push(cloneDeep(temp));

                    if (
                        this.state.defaultLocationID &&
                        (!this.state.selected ||
                            this.state.defaultLocationID !==
                                this.state.selected.value)
                    ) {
                        if (
                            response.data[i].locationID ===
                            this.state.defaultLocationID
                        ) {
                            this.handleSelect(temp, "default");
                        }
                    }
                    if (this.state.locationID) {
                        if (
                            response.data[i].locationID ===
                            this.state.locationID
                        ) {
                            this.handleSelect(temp, "selected");
                        }
                    }
                }
                this.setState({ options });
                if (callback) {
                    return callback(options);
                } else {
                    return options;
                }
            })
            .catch((error) => {
                console.log(error.data);
            });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback);
            } else {
                callback(this.state.options);
            }
        }
    }

    render() {
        return (
            <div>
                <AsyncSelect
                    menuPortalTarget={document.body}
                    closeMenuOnScroll={(e) => {
                        if (e) {
                            try {
                                let classes = [...e.srcElement.classList];
                                return !classes.some((i) =>
                                    i.includes("MenuList")
                                );
                            } catch (error) {}
                        }
                    }}
                    styles={{ zIndex: 0 }}
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.options}
                    loadOptions={this.loadOptions}
                    onChange={(e) => this.handleSelect(e, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
