import React, {Component} from "react";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Input} from 'reactstrap';
import {toast} from "react-toastify";

export default class DispatchWindowareDeliveryDetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driverName: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(key, value) {
        this.setState({[key]: value});
    }

    handleSubmit() {
        let {driverName} = this.state;
        if (!driverName) {
            toast.info("please enter driver name");
            return false;
        }
        this.props.toggle(false);
        this.props.downloadSheet(driverName);
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {driverName} = this.state;
        return (
                <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                    <ModalHeader toggle={() => toggle(!isOpen)}>
                        Generate Delivery Sheet
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="driverName"> Driver Name *</Label>
                                    <Input type="text" name="driverName"
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                        value={driverName}
                                        placeholder="Enter driver name here" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="text-right">
                            <Button color={"primary"} size="sm" onClick={() => this.handleSubmit()} >
                                <i className="fa fa-download mr-2" />
                                Generate Delivery Sheet</Button>
                            <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                                <i className="fa fa-times" />&nbsp;
                                Cancel</Button>

                        </div>
                    </ModalFooter>

                </Modal>
                );
        }
}