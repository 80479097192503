import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {Link as Link} from "react-router-dom";
import StockService from '../../services/StockService';
import {findIndex, handleErrorMessage, getSorted} from '../../services/CommonService';
import productService from '../../services/product/ProductService';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import StockPopover from '../../components/stock/StockPopover';
import SearchFabric from '../../components/search/SearchFabric';
import classnames from 'classnames';
import queryString from 'query-string';
import {v4 as uuidv4} from 'uuid';
import SearchProductConfigurationStockQtyFormula from "../../components/search/SearchProductConfigurationStockQtyFormula";
import SearchProductConfigurationStockWastageFormula from "../../components/search/SearchProductConfigurationStockWastageFormula";
import SearchDebtorAcccount from "../../components/search/SearchDebtorAcccount";
import {isEmpty} from 'lodash';
import DeleteModal from '../../components/modal/DeleteModal';
import UserService from '../../services/UserService';


export default class ProductConfigurationFabricNewPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newItem: {},
            product: {
                id: 0
            },
            fabricCombinations: [],
            loading: {
                save: false,
                fabricCombinations: false,
                product: false
            },
            search: {
                prodCode: "",
                name: "",
                primaryBMCode: "",
                secondaryBMCode: "",
                fabricKey: ""
            },
            stockPopoverIsOpen: "",
            activeItem: {},
            canDeleteProductionConfiguration: false,


        };
        this.stockService = new StockService();
        this.userService = new UserService();

        this.getProductFabricCombinations = this.getProductFabricCombinations.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.saveProductFabricCombination = this.saveProductFabricCombination.bind(this);
        this.toggleStockPopover = this.toggleStockPopover.bind(this);
        this.toggleDeleteProductConfigurationModal = this.toggleDeleteProductConfigurationModal.bind(this);
        this.handleDeleteProductConfigurationFabric = this.handleDeleteProductConfigurationFabric.bind(this);
    }

    componentDidMount() {
        let {product} = this.state;
        let currentUser = this.userService.getLoggedInUser();
        let canDeleteProductionConfiguration = this.userService.hasPrivilege(currentUser, 'product-configuration-delete');
        this.setState({canDeleteProductionConfiguration});

        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            product.id = searchParams.productID;
            this.getProduct(product);
        } else {
            this.props.history.push('/inventory/product/list');
        }
    }



    getProduct(product) {
        let {loading} = this.state;
        loading.product = true;
        this.setState({loading});

        productService.getProduct(product.id).then(response => {
            loading.product = false;
            product = response.data;
            this.setState({product, loading});
            this.getProductFabricCombinations(product);
        }).catch(error => {
            loading.product = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        })
    }

    getProductFabricCombinations(product) {
        let {loading, fabricCombinations} = this.state;
        loading.fabricCombinations = true;
        this.setState({loading});
        ProductConfigurationService.getProductFabricCombinationsV1(product.id).then(response => {
            loading.fabricCombinations = false;
            fabricCombinations = response.data;
            fabricCombinations = getSorted(fabricCombinations, 'sortIndex', true);
            for (let i in fabricCombinations) {
                fabricCombinations[i].uuid = 'fab' + uuidv4();
            }
            this.setState({fabricCombinations: response.data, loading});
        }).catch(error => {
            loading.fabricCombinations = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        })
    }

    addNewItem() {
        let { fabricCombinations, product } = this.state;
        let item = {
            id: 0,
            productId: product.id,
            fabricID: "",
            primaryBMCode: "",
            secondaryBMCode: "",
            isActive: true,
            uuid: 'fab' + uuidv4(),
            sortIndex: Math.max(...(fabricCombinations || []).map(f => f.sortIndex), 0) + 10  // default SortIndex = MaxSortIndex + 10

        };

        fabricCombinations.push(item);
        this.setState({fabricCombinations});
    }

    searchFunction(f, search) {
        let flag = true;
        if (flag && search.primaryBMCode) {
            flag = f.primaryBMCode.toLowerCase().includes(search.primaryBMCode.toLowerCase())
        }
        if (flag && search.secondaryBMCode) {
            flag = f.secondaryBMCode.toLowerCase().includes(search.secondaryBMCode.toLowerCase())
        }

        return flag;

    }

    handleChange(value, key, uuid) {
        let index = -1;
        let {fabricCombinations, search} = this.state;
        switch (uuid) {
            case "search":
                search[key] = value;
                this.setState({search});
                break;
            default:
                index = findIndex(fabricCombinations, "uuid", uuid);
                if (index > -1) {
                    fabricCombinations[index][key] = value;
                    this.setState({fabricCombinations, activeItem: fabricCombinations[index]});

                }
        }
    }

    toggleStockPopover(change) {
        let {fabricCombinations, stockPopoverIsOpen, activeItem} = this.state;
        activeItem = {};
        if (change && change !== stockPopoverIsOpen) {
            let index = findIndex(fabricCombinations, "uuid", change);
            if (index > -1) {
                activeItem = fabricCombinations[index];
            }
        } else {
            change = "";
        }
        this.setState({stockPopoverIsOpen: change, activeItem});

    }

    saveProductFabricCombination(item) {
        let {fabricCombinations, loading} = this.state;
        loading.save = item.uuid;
        this.setState({ loading });

        if (!item.id && !item.sortIndex) {
            item.sortIndex = Math.max(...(fabricCombinations||[]).map(f => f.sortIndex), 0) + 10; // default SortIndex = MaxSortIndex + 10
        }

        ProductConfigurationService.saveProductFabricCombinationV1(item).then(response => {
            let index = findIndex(fabricCombinations, "uuid", item.uuid);
            if (index > -1) {
                fabricCombinations[index] = response.data;
                fabricCombinations[index].uuid = item.uuid;
            }
            loading.save = '';
            fabricCombinations = getSorted(fabricCombinations, 'sortIndex', true);
            this.setState({fabricCombinations, loading});
        }).catch(error => {
            loading.save = '';
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        })
    }

    openDeleteConfirmationModal(item) {
        this.setState({isOpenDeleteProductConfigurationModal: true, selectedItemProductConfigurationFabric: item});
    }

    toggleDeleteProductConfigurationModal(data) {
        this.setState({isOpenDeleteProductConfigurationModal: data});
    }

    handleDeleteProductConfigurationFabric() {
        let {selectedItemProductConfigurationFabric, fabricCombinations} = this.state;
        let index = findIndex(fabricCombinations, 'id', selectedItemProductConfigurationFabric.id);

        this.setState({deletingProductConfiguration: true});
        ProductConfigurationService.deleteProductConfigurationFabric(selectedItemProductConfigurationFabric.id).then(response => {
            if (response.status === 200) {
                toast.success("Deleted");
                fabricCombinations.splice(index, 1);
                this.setState({
                    fabricCombinations,
                    deletingProductConfiguration: false,
                    isOpenDeleteProductConfigurationModal: false
                });
            }
        }).catch(error => {
            this.setState({deletingProductConfiguration: false});
            toast.error(handleErrorMessage(error));
        })
    }


    render() {
        let {product, loading, fabricCombinations, search, activeItem, stockPopoverIsOpen, isOpenDeleteProductConfigurationModal, canDeleteProductionConfiguration} = this.state;

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/products/all/list?activeTab=product')}>Products</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push("/inventory/product/configuration?" + queryString.stringify({productID: product.id}))}>Configuration</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Fabrics</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <h5>{loading.product ?
                                    <Spinner color="primary"/> : product.name + ' FABRICS'}</h5>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <Link color='primary' style={{textDecoration: "none"}}
                                          className="btn btn-sm ml-1 btn btn-primary" id={"importfabric"}
                                          to={{
                                              pathname: '/inventory/product/configuration/fabric/import'
                                          }}><i className="fa fa-upload mr-2"
                                                aria-hidden="true"/>&nbsp;Import</Link>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {loading.fabricCombinations
                            ? <Spinner color="primary"/>
                            : <div>
                                <Table responsive={true} striped bordered size={"sm"}>
                                    <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th style={{minWidth: '180px'}}>Fabric
                                            <small>
                                                <div>It should be unique in fabric dropdown</div>
                                            </small>
                                        </th>

                                        <th style={{minWidth: 80}}>BMCode(default)
                                            <Input type="text" id="primaryBMCode" name="primaryBMCode"
                                                   value={search.primaryBMCode}
                                                   onChange={(e) => this.handleChange(e.target.value, "primaryBMCode", "search")}
                                                   placeholder="Search..."/>
                                        </th>
                                        <th style={{minWidth: 80}}>BMCode(motor)
                                            <Input type="text" id="secondaryBMCode" name="secondaryBMCode"
                                                   value={search.secondaryBMCode}
                                                   onChange={(e) => this.handleChange(e.target.value, "secondaryBMCode", "search")}
                                                   placeholder="Search..."/>
                                        </th>
                                        <th style={{minWidth: 200}}>AccountID
                                        </th>
                                        <th style={{minWidth: 30}} className={"text-center"}>Is default</th>
                                        <th style={{minWidth: 150}} className={"text-center"}>Qty Formula</th>
                                        <th style={{minWidth: 150}} className={"text-center"}>Wastage Formula</th>
                                        <th style={{minWidth: 30}} className={"text-center"}>Active</th>
                                        <th style={{minWidth: 100}}>Sort Index</th>
                                        <th style={{minWidth: 150}} className={"text-center"}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(fabricCombinations || []).filter((item) => this.searchFunction(item, search)).map((item, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>

                                            <td>
                                                <SearchFabric
                                                    selectedFabricID={item.fabricID}
                                                    handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "fabricID", item.uuid)}
                                                />
                                            </td>

                                            <td>
                                                <Input
                                                    type={"text"}
                                                    style={{height: 38}}
                                                    name={"primaryBMCode"}
                                                    placeholder={"write primary BMCode here"}
                                                    value={item.primaryBMCode}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, item.uuid)}
                                                />

                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    style={{height: 38}}
                                                    name={"secondaryBMCode"}
                                                    placeholder={"write secondary BMCode here"}
                                                    value={item.secondaryBMCode}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, item.uuid)}
                                                />
                                            </td>
                                            <td>
                                                <SearchDebtorAcccount
                                                    handleAccountChange={(selectedAccountID) => this.handleChange(selectedAccountID, "accountID", item.uuid)}
                                                    selectedAccountID={item ? item.accountID : ""}
                                                    defaultAccountID={item ? item.accountID : ""}
                                                    includeChildren={true}
                                                    excludeClosedandInactive={true}/>
                                            </td>
                                            <td className={"text-center"}>
                                                <i className={classnames("mt-2", "fa", "fa-lg", {
                                                        "fa-check-square-o": isEmpty(item.accountID),
                                                        "fa-square-o": !isEmpty(item.accountID),
                                                    }
                                                )}/>
                                            </td>
                                            <td>
                                                <SearchProductConfigurationStockQtyFormula
                                                    selectedFormulaID={item.qtyFormulaId ? item.qtyFormulaId : ""}
                                                    handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "qtyFormulaId", item.uuid)}
                                                />

                                            </td>
                                            <td>
                                                <SearchProductConfigurationStockWastageFormula
                                                    selectedFormulaID={item.wastageFormulaId ? item.wastageFormulaId : ""}
                                                    handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "wastageFormulaId", item.uuid)}
                                                />
                                            </td>
                                            <td className={"text-center"}>
                                                <Button size={"sm"} color={"link"}
                                                        onClick={() => this.handleChange(!item.isActive, "isActive", item.uuid)}>
                                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": item.isActive,
                                                            "fa-square-o": !item.isActive,
                                                        }
                                                    )}/>
                                                </Button>
                                            </td>
                                            <td>
                                                <Input type='number' name='sortIndex' value={item.sortIndex}
                                                    onChange={(e) => this.handleChange(e.target.value, "sortIndex", item.uuid)} />
                                            </td>

                                            <td className={"text-center"}>
                                                <Button color={"primary"}
                                                        size={"sm"}
                                                        className={"mr-2"}
                                                        disabled={loading.save === item.uuid}
                                                        onClick={() => this.saveProductFabricCombination(item)}>
                                                    <i className="fa fa-floppy-o mr-2"
                                                       aria-hidden="true"/>{loading.save === item.uuid ?
                                                    <Spinner size="sm"
                                                             style={{color: "white"}}/> : (item.id ? "Update" : "Save")}
                                                </Button>
                                                {(item.id && canDeleteProductionConfiguration) ? <Button color='danger'
                                                                                                         size={"sm"}
                                                                                                         onClick={() => this.openDeleteConfirmationModal(item)}><i
                                                        className='fa fa-trash'/></Button>
                                                    : null}

                                            </td>

                                        </tr>
                                    })}
                                    </tbody>
                                </Table>
                                <Button color={"primary"} size={"sm"} onClick={this.addNewItem}><i
                                    className="fa fa-plus mr-2" aria-hidden="true"/>Add</Button>
                            </div>
                        }

                    </CardBody>
                </Card>

                {stockPopoverIsOpen ?
                    <StockPopover isOpen={stockPopoverIsOpen}
                                  toggle={this.toggleStockPopover}
                                  target={activeItem.uuid}
                                  prodCode={activeItem.prodCode}/>
                    : null
                }

                {isOpenDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete it?'}
                        isOpen={isOpenDeleteProductConfigurationModal}
                        toggle={this.toggleDeleteProductConfigurationModal}
                        handleDelete={this.handleDeleteProductConfigurationFabric}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}
                <ToastContainer/>
            </div>
        );
    }
}