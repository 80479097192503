import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import OrderService from '../../services/OrderService';
import cloneDeep from 'lodash/cloneDeep';

export default class SearchImportSalesOrderStatusMulti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            defaultImportSalesOrderStatuses: props.defaultImportSalesOrderStatuses,
            selectedImportSalesOrderStatuses: props.selectedImportSalesOrderStatuses,
            loading: false,
            allImportSalesOrderStatuses: [],
            inputValue: "",
            filter: {
                importSalesOrderStatuses: props.filter.importSalesOrderStatuses,
            }
        };
        this.orderService = new OrderService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.fetchOptions(this.state.defaultImportSalesOrderStatuses || this.state.selectedImportSalesOrderStatuses);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultImportSalesOrderStatuses !== nextProps.defaultImportSalesOrderStatuses) {
            if (nextProps.defaultImportSalesOrderStatuses) {
                this.setState({ defaultImportSalesOrderStatuses: nextProps.defaultImportSalesOrderStatuses }, () => {
                    this.fetchOptions("");
                });
            }
        }
        if (this.state.selectedImportSalesOrderStatuses !== nextProps.selectedImportSalesOrderStatuses) {
            if (!nextProps.selectedImportSalesOrderStatuses) {
                this.setState({ selected: null }, () => {
                    this.fetchOptions("");
                });
            }
            else {
                this.setState({ selectedImportSalesOrderStatuses: nextProps.selectedImportSalesOrderStatuses }, () => {
                    this.fetchOptions("");
                });
            }
        } else {
            if (JSON.stringify(nextProps.filter.importSalesOrderStatuses) !== JSON.stringify(this.state.filter.importSalesOrderStatuses)) {
                let { filter } = this.state;
                filter.importSalesOrderStatuses = nextProps.filter.importSalesOrderStatuses;
                this.setState({ filter }, () => {
                    this.fetchOptions("");
                });
            }
        }
    }

    handleSelect(selected, event) {
        let { selectedImportSalesOrderStatuses } = this.state;
        selectedImportSalesOrderStatuses = (selected || []).map(s => s.value);
        switch (event) {
            case "selected":
                this.setState({ selected, selectedImportSalesOrderStatuses });
                break;
            case "onChange":
            case "default":
            default:
                this.setState({ selected, selectedImportSalesOrderStatuses });
                this.props.handleSelection(selectedImportSalesOrderStatuses);
                break;
        }
        if (!selectedImportSalesOrderStatuses) {
            this.fetchOptions("");
        }
    };

    fetchOptions(inputValue, callback) {
        let allImportSalesOrderStatuses = [];
        let { filter } = this.state;
        let request = {
            searchText: inputValue,
            importSalesOrderStatuses: filter.importSalesOrderStatuses
        };
        this.orderService.getImportSalesOrderStatuses(request).then(response => {
            let data = response.data;
            for (let i in data) {
                let temp = {
                    label: data[i].statusCode,
                    value: data[i].id,
                };
                allImportSalesOrderStatuses.push(cloneDeep(temp));
                if (this.props.defaultImportSalesOrderStatuses) {
                    if (this.props.defaultImportSalesOrderStatuses.includes(data[i].id)) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.props.selectedImportSalesOrderStatuses) {
                    if (this.props.selectedImportSalesOrderStatuses.includes(data[i].id)) {
                        this.handleSelect((allImportSalesOrderStatuses || []).filter(x => this.props.selectedImportSalesOrderStatuses.includes(x.value)), "selected")
                    }
                }
            }
            this.setState({ allImportSalesOrderStatuses });
            if (callback) {
                return callback(allImportSalesOrderStatuses)
            } else {
                return allImportSalesOrderStatuses;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 0) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allImportSalesOrderStatuses);
            }
        }
    };

    render() {
        return (
            <AsyncSelect
                cacheOptions
                isLoading={this.state.loading}
                isClearable={true}
                defaultOptions={this.state.allImportSalesOrderStatuses}
                loadOptions={this.loadOptions}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                isDisabled={this.props.disabled}
                isMulti={true}
                value={this.state.selected}
                placeholder={"Search..."}
            />
        );
    }
}