import React, {Component} from "react";
import {Button, ButtonGroup, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import stockReceiptService from '../../../services/stock/StockReceiptService';
import {handleErrorMessage} from '../../../services/CommonService';

export default class SearchStockReceiveNavigator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockReceiptID: 0,
            isLoadingStockReceiptID: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.firstStockReceiptID = this.firstStockReceiptID.bind(this);
        this.lastStockReceiptID = this.lastStockReceiptID.bind(this);
        this.currentLastStockReceiptID = this.currentLastStockReceiptID.bind(this);
        this.currentNextStockReceiptID = this.currentNextStockReceiptID.bind(this);
        this.getStockReceiptIDByNavigation = this.getStockReceiptIDByNavigation.bind(this);
    }

    handleChange(e) {
        this.setState({stockReceiptID: e.target.value});
    }

    enterPressed(e) {
        let keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            this.props.getStockReceipt(this.state.stockReceiptID);
        }
    }

    componentDidMount() {
        if (this.props.stockReceiptID) {
            this.setState({stockReceiptID: this.props.stockReceiptID}, () => {
                this.props.getStockReceipt(this.state.stockReceiptID);
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.stockReceiptID !== nextProps.stockReceiptID) {
            this.setState({stockReceiptID: nextProps.stockReceiptID}, () => {
                this.props.getStockReceipt(this.state.stockReceiptID);
            })
        }
    }

    getStockReceiptIDByNavigation(navigation) {
        this.setState({isLoadingStockReceiptID: true});
        stockReceiptService.getStockReceiptIDByNavigation(navigation, this.state.stockReceiptID).then(response => {
            this.setState({isLoadingStockReceiptID: false, stockReceiptID: response.data}, () => {
                this.props.getStockReceipt(this.state.stockReceiptID)
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
        })
    }

    firstStockReceiptID() {
        this.getStockReceiptIDByNavigation('first');
    }

    lastStockReceiptID() {
        this.getStockReceiptIDByNavigation('last');
    }

    currentNextStockReceiptID() {
        this.getStockReceiptIDByNavigation('next');
    }

    currentLastStockReceiptID() {
        this.getStockReceiptIDByNavigation('previous');
    }

    render() {
        let {stockReceiptID} = this.state;
        let isLoadingStockReceiptID = this.state.isLoadingStockReceiptID || this.props.isLoadingStockReceiptID;
        let {getStockReceipt} = this.props;
        return (
            <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <Button size={"sm"} color={"secondary"} className={"mr-2"}
                                onClick={this.props.history.goBack}>
                            <i className="fa fa-fw fa-chevron-left mr-2" aria-hidden="true"/>
                            Back
                        </Button>
                    </InputGroupAddon>
                    <Input type={"number"} name="stockReceiptID" id="stockReceiptID"
                           onChange={event => this.handleChange(event)} onKeyPress={this.enterPressed}
                           value={stockReceiptID ? stockReceiptID : ''}
                           placeholder={isLoadingStockReceiptID ? "Searching..." : "Enter stock receipt id."}/>
                    <InputGroupAddon addonType="append">
                        <ButtonGroup>
                            <Button
                                title={"click here to search"}
                                color={"primary"}
                                disabled={isLoadingStockReceiptID || isLoadingStockReceiptID}
                                onClick={() => getStockReceipt(stockReceiptID)}>
                                {isLoadingStockReceiptID || isLoadingStockReceiptID
                                    ? <i className="fa fa-fw fa-circle-o-notch fa-spin mr-2" aria-hidden="true"/>
                                    : <i className="fa fa-fw fa-search mr-2" aria-hidden="true"/>
                                }
                                Search Stock Receipt
                            </Button>
                            <Button
                                title={"First"}
                                color={"secondary"}
                                disabled={isLoadingStockReceiptID || isLoadingStockReceiptID}
                                onClick={this.firstStockReceiptID}>
                                <i className="fa fa-fw fa-angle-double-left" aria-hidden="true"/>
                            </Button>
                            <Button
                                title={"Previous"}
                                color={"secondary"}
                                disabled={stockReceiptID === '' || isLoadingStockReceiptID || isLoadingStockReceiptID}
                                onClick={this.currentLastStockReceiptID}>
                                <i className="fa fa-fw fa-angle-left" aria-hidden="true"/>
                            </Button>
                            <Button
                                title={"Next"}
                                color={"secondary"}
                                disabled={stockReceiptID === '' || isLoadingStockReceiptID || isLoadingStockReceiptID}
                                onClick={this.currentNextStockReceiptID}>
                                <i className="fa fa-fw fa-angle-right" aria-hidden="true"/>
                            </Button>
                            <Button
                                title={"Last"}
                                color={"secondary"}
                                disabled={isLoadingStockReceiptID || isLoadingStockReceiptID}
                                onClick={this.lastStockReceiptID}>
                                <i className="fa fa-fw fa-angle-double-right" aria-hidden="true"/>
                            </Button>

                        </ButtonGroup>
                    </InputGroupAddon>
                </InputGroup>
                <ToastContainer/>
            </div>
        )
    }
}