import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent, TabPane,
    FormGroup, InputGroup, Table
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {handleErrorMessage} from "../../../services/CommonService";
import ImportExportService from "../../../services/ImportExportService";
import FileSaver from 'file-saver';
import classnames from "classnames";
import SwishExportService from '../../../services/SwishExportService';

const keywayImportOptions = [
    {
        key: "StockAndStockDetails",
        label: "Stock Items"
    },
    {
        key: "StockUnit",
        label: "StockUnit"
    }, {
        key: "StockInventory",
        label: "Stock Inventory"
    }, {
        key: "StockPricing",
        label: "Stock Pricing"
    },{
        key: "StockSupplier",
        label: "Stock Supplier"
    }, {
        key: "WarehouseLocation",
        label: "Warehouse Locations"
    }, {
        key: "WarehouseLocationStockMapping",
        label: "Stock & Warehouse Location"
    }, {
        key: "UsersImport",
        label: "Users"
    }

];
export default class ImportComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            importType: '',
            exportDataType: '',
            swishExportType: '',
            allTabs: ["Import", "Export", "Swish Export"],
            activeTab: "Import",
            prodCodes: '',
            isDryRun: false,
            importResponse: {}
        };
        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.downloadFormat = this.downloadFormat.bind(this);
        this.import = this.import.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChange(key, change) {
        this.setState({[key]: change});
    }

    fileChangeHandler(event) {
        if (this.state.importType === "") {
            toast.error("Can't upload file without selecting type of data", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
        this.setState({selectedFile: event.target.files[0]});
    }

    import(importType) {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        if (importType === "") {
            toast.error("Please select type of data first ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } else {
            let endPoint;
            switch (importType) {
                case "StockUnit":
                    endPoint = 'api/keyway/inventory/import-export/stock-unit/data/import';
                    break;
                case "StockAndStockDetails":
                    endPoint = 'api/keyway/inventory/import-export/stock/details/data/import';
                    break;
                case "StockPricing":
                    endPoint = 'api/keyway/inventory/import-export/stock/pricing/data/import';
                    break;
                case "StockSupplier":
                    endPoint = 'api/keyway/inventory/import-export/stock-supplier/data/import';
                    break;
                case "WarehouseLocation":
                    endPoint = 'api/warehouse-location/import';
                    break;
                case "WarehouseLocationStockMapping":
                    endPoint = 'api/stock-warehouse-location/import';
                    break;
                case "StockInventory":
                    endPoint = 'api/keyway/inventory/import-export/stock-inventory/data/import';
                    break;
                case "UsersImport":
                    endPoint = 'api/admin/user/import';
                    break;
                default:
                    break;
            }
            const data = new FormData();
            data.append('file', this.state.selectedFile);
            data.append('isDryRun', this.state.isDryRun);
            this.setState({isImport: true});
            ImportExportService.importData(endPoint, data).then(response => {
                this.setState({isImport: false, importResponse: response.data});
               if(response.data.hasError){
                   toast.error("Error!", {
                       position: toast.POSITION.BOTTOM_CENTER
                   });
               }else{
                   toast.success("Success!", {
                       position: toast.POSITION.BOTTOM_CENTER
                   });
               }
            }).catch(error => {
                this.setState({isImport: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
        }
    }

    downloadFormat(importType) {
        this.setState({downloading: true});
        let endPoint = '';
        let filename = '';
        if (importType === "") {
            toast.error("Please select type of data first ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({downloading: false})
        } else {
            switch (importType) {
                case "StockUnit":
                    endPoint = 'api/stock-unit/download/format';
                    filename = 'Stock Unit Format';
                    break;
                case "StockAndStockDetails":
                    endPoint = 'api/keyway/inventory/import-export/GenerateStockImportFile';
                    filename = 'Stock-Items';
                    break;
                case "StockPricing":
                    endPoint = 'api/keyway/inventory/import-export/stock/pricing/template/export';
                    filename = 'Stock Pricing Template';
                    break;
                case "StockSupplier":
                    endPoint = 'api/stock-supplier/export';
                    filename = 'stock-supplier';
                    break;
                case "WarehouseLocation":
                    endPoint = 'api/warehouse-location/export';
                    filename = 'Warehouse-Location-Format';
                    break;
                case "WarehouseLocationStockMapping":
                    endPoint = 'api/stock-warehouse-location/export';
                    filename = 'Stock-Warehouse-Location-Format';
                    break;
                case "StockInventory":
                    endPoint = 'api/stock-inventory/export';
                    filename = 'stock-inventory';
                    break;
                case "UsersImport":
                    endPoint = 'api/admin/user/GenerateUsersImportFile';
                    filename = 'user-import';
                    break;
            }
            ImportExportService.generateImportFormat(endPoint).then(response => {
                this.setState({downloading: false});
                FileSaver.saveAs(response.data, filename + ".xlsx");
            }).catch(error => {
                this.setState({downloading: false});
                toast.error(handleErrorMessage(error));
            });
        }
    }

    downloadData(exportDataType) {
        this.setState({downloadingData: true});
        let endPoint = '';
        let filename = '';
        if (exportDataType === "") {
            toast.error("Please select type of data first ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({downloadingData: false})
        } else {
            switch (exportDataType) {
                case "StockPricingData":
                    endPoint = 'api/keyway/inventory/import-export/stock/pricing/export';
                    filename = 'Price and COST (Landed + Prod)';
                    break;

            }
            ImportExportService.EmailStockPricingSheet(endPoint).then(response => {
                this.setState({downloadingData: false})

                if (response.status === 200 || response.status === '200') {
                    toast.info(" Data Excel file will be emailed to you shortly , please check your UserInbox in few minutes. ", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            }).catch(error => {
                this.setState({downloadingData: false})
                toast.error(handleErrorMessage(error));
            });

        }
    }


    exportSwishData(swishExportType) {
        let endPoint = '';
        let filename = '';

        if (swishExportType === "") {
            toast.error("Please select type of data first ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }

        else {

            switch (swishExportType) {
                case "StockAndStockDetails":
                    endPoint = 'api/swish-export/stock-and-stockdetails';
                    filename = 'Stock-Items';
                    break;

                case "StockInventory":
                    endPoint = 'api/swish-export/stock-inventory';
                    filename = 'Stock-inventory';
                    break;

                case "StockPricing":
                    endPoint = 'api/keyway/inventory/import-export/Stock/Pricing';
                    filename = 'Stock-Inv-Pricing';
                    break;
                case "WarehouseLocation":
                    endPoint='api/swish-export/warehouseLocation';
                    filename='Swish-Warehouse-Location';
                    break;
                case "WarehouseLocationStockMapping":
                    endPoint='api/swish-export/StockWarehouseLocation';
                    filename='Swish-Stock-Warehouse-Location';
                    break;

                case "StockSupplier":
                    endPoint = 'api/swish-export/stock-supplier';
                    filename = 'Stock-supplier';
                    break;
            }

            let req = {
                prodCodes: this.state.prodCodes,
            };

            this.setState({downloadingExportData: true});
            SwishExportService.exportSwishData(endPoint, req).then(response => {
                this.setState({downloadingExportData: false});
                FileSaver.saveAs(response.data, filename + ".xlsx");

            }).catch(error => {
                this.setState({downloadingExportData: false});
                toast.error(handleErrorMessage(error));
            });

        }
    }

    render() {


        let {downloading, isImport, downloadingData, allTabs, activeTab, downloadingExportData, isDryRun, importResponse} = this.state;
        return (<div>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {allTabs.map((Tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === Tab})}
                                        onClick={() => {
                                            this.toggle(Tab);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"} color={"link"}>{Tab}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="Import">
                                <div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <h6>Select type of data to import: </h6>
                                            <Input type={"select"}
                                                   className={"mb-2"}
                                                   onChange={(e) => this.setState({importType: e.target.value})}>
                                                <option value="">Select</option>
                                                {(keywayImportOptions || []).map(option => {
                                                    return <option value={option.key}>{option.label}</option>
                                                })}
                                            </Input>
                                            <Input type="file"
                                                   name="file"
                                                   id="file"
                                                   onChange={this.fileChangeHandler}
                                                   aria-label="Upload document"/>
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button color={"link"} size={"sm"}
                                                onClick={() => this.handleChange("isDryRun", !isDryRun)}>
                                            <i className={classnames("mr-2", "fa", {
                                                "fa-check-square-o": isDryRun,
                                                "fa-square-o": !isDryRun
                                            })}/>
                                            Dry run
                                        </Button>
                                        <Button color={"primary"} size={"sm"}
                                                onClick={() => this.import(this.state.importType)}
                                                disabled={isImport}>
                                            {isImport
                                                ? <Spinner size="sm"
                                                           className={"mr-2"}
                                                           color={"white"}/>
                                                : <i className="fa fa-upload mr-2"/>
                                            }
                                            Upload</Button>&nbsp;
                                        <Button color={"primary"} size={"sm"}
                                                outline={true}
                                                onClick={() => this.downloadFormat(this.state.importType)}
                                                disabled={downloading}>{downloading
                                            ? <Spinner size="sm"
                                                       className={"mr-2"}
                                                       color={"primary"}/>
                                            : <i className="fa fa-download mr-2"/>
                                        }Download template</Button>
                                    </div>

                                    {
                                        importResponse.hasError
                                            ? <div>
                                                <hr/>
                                                <Table size={"sm"} hover={true} responsive>
                                                    <thead>
                                                    <tr>
                                                        <td>Row</td>
                                                        <td>Column</td>
                                                        <td>Title</td>
                                                        <td>Value</td>
                                                        <td>Validation Message</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {((importResponse.rows) || []).map(row => {
                                                        return <tr
                                                            className={row.hasError ? "table-danger" : "table-success"}>
                                                            <td>{row.rowIndex}</td>
                                                            <td>{row.columnIndex}</td>
                                                            <td>{row.inputTitle}</td>
                                                            <td>{row.inputValue}</td>
                                                            <td>{row.validationMessage}</td>
                                                        </tr>
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : null
                                    }

                                </div>
                            </TabPane>
                            <TabPane tabId={"Export"}>
                                <div>
                                    <Row>
                                        &nbsp;&nbsp;&nbsp;Data to export :
                                    </Row>
                                    <Row>
                                        <Col xl={6} lg={4} md={4} sm={12} xs={12}>
                                            <Input type={"select"}
                                                   onChange={(e) => this.setState({exportDataType: e.target.value})}>
                                                <option value="">Select</option>
                                                <option value="StockPricingData">Stock Pricing</option>
                                            </Input>
                                        </Col>

                                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <div className="text-right">
                                                <Button color={"primary"} size={"sm"}
                                                        onClick={() => this.downloadData(this.state.exportDataType)}
                                                        disabled={downloadingData}>{downloadingData
                                                    ? <Spinner size="sm"
                                                               className={"mr-2"}
                                                               color={"white"}/>
                                                    : <i className="fa fa-download mr-2"/>
                                                }Download data</Button>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </TabPane>
                            <TabPane tabId="Swish Export">
                                <div>
                                    <Row>
                                        &nbsp;&nbsp;&nbsp; Stock codes :
                                    </Row>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input type="textarea"
                                                   onChange={(e) => this.setState({prodCodes: e.target.value})}
                                                   value={this.state.prodCodes}
                                                   placeholder="Write comma seperated stock codes here ,for example:- 00001,00041"/>
                                        </InputGroup>

                                    </FormGroup>

                                    <Row>
                                        &nbsp;&nbsp;&nbsp; Select type of data to export :
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <Input type={"select"}
                                                   onChange={(e) => this.setState({swishExportType: e.target.value})}>
                                                <option value="">Select</option>
                                                <option value="StockAndStockDetails">Stock Items</option>
                                                <option value="StockInventory">Stock Inventory</option>
                                                <option value="StockPricing">Stock Pricing</option>
                                                <option value="StockSupplier">Stock Supplier</option>
                                                <option value="WarehouseLocation">Warehouse Location</option>
                                                <option value="WarehouseLocationStockMapping">Stock & Warehouse Location
                                                </option>
                                            </Input>
                                        </Col>
                                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                            <div className="text-left">
                                                <Button color={"primary"} size={"sm"}
                                                        onClick={() => this.exportSwishData(this.state.swishExportType)}
                                                        disabled={downloadingExportData}>{downloadingExportData
                                                    ? <Spinner size="sm"
                                                               className={"mr-2"}
                                                               color={"white"}/>
                                                    : <i className="fa fa-download mr-2"/>
                                                }Download data</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                <br/>
                <ToastContainer/>
            </div>
        )
    }
}