import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Spinner,
    Progress
} from 'reactstrap';
import { toast } from 'react-toastify';
import warehouseService from '../../services/WINOInventory/WarehouseService';
import { handleErrorMessage } from "../../services/CommonService";
export default class DeleteStockWarehouseLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.deleteStockWarehouseLocation = this.deleteStockWarehouseLocation.bind(this);
    }
    deleteStockWarehouseLocation() {
        this.setState({ loading: true })
        warehouseService.deleteStockWarehouseLocation(this.props.stockWarehouseLocationID).then(response => {
            if (response.status === '200' || response.status === 200) {
                this.setState({ loading: false });
                toast.success('Location deleted successfully', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.toggleDeleteModal(false);
                this.props.refreshAgain();
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }
    render() {
        let { isOpenDeleteModal, toggleDeleteModal, stockWarehouseLocationID } = this.props;
        return (
            <Modal isOpen={isOpenDeleteModal} size="md" scrollable={false} toggle={() => toggleDeleteModal(!isOpenDeleteModal)}>
                <ModalHeader toggle={() => toggleDeleteModal(!isOpenDeleteModal)}>
                    Are you sure want to delete this Location
                </ModalHeader>
                <ModalBody>
                    <div className="text-right">
                        <Button color={"primary"} onClick={this.deleteStockWarehouseLocation} disabled={this.state.loading}>
                            {this.state.loading
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            Delete</Button>
                        <Button color={"secondary"} className="ml-2" onClick={() => toggleDeleteModal(!isOpenDeleteModal)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}