import axiosFileDownloader from './axiosFileDownloader';
import axios from './axios';
import axiosFileUploader from './axiosFileUploader';

class KeywayStockService {

    static Instance() {
        return new KeywayStockService();
    }

    getKeywayStockUnits() {
        return axios.get('api/Keyway/stock/units/list');
    }

    listAllStocks(req) {
        return axios.post('api/Keyway/stock/list', req);
    }

    getKeywayAllExistingProdCodes() {
        return axios.get('api/Keyway/stock/unique/prodCodes');
    }

    getKeywayStockPartTypes() {
        return axios.get('api/Keyway/stock/attribute/parttype');
    }

    getKeywayStockModels() {
        return axios.get('api/Keyway/stock/attribute/model');
    }

    saveStock(req) {
        return axios.post('api/Keyway/stock/save', req);
    }

    uploadkeywayStockDoc(requestBody) {
        return axiosFileUploader.post('api/Keyway/stock/attachment/upload', requestBody);
    }

    generateKeywayStockImportFormat() {
        return axiosFileDownloader.get('api/Keyway/stock/template/export');
    }

    importKeywayStockData(request) {
        return axiosFileUploader.post('api/Keyway/stock/import', request, {
            timeout: 600000
        });
    }

    checkImportedDataProdCodes(request) {
        return axiosFileUploader.post('api/Keyway/stock/check/prodcode/import', request, {
            timeout: 600000
        });
    }

    generateExcelReport(request) {
        return axiosFileDownloader.post('api/Keyway/stock/generate/summary/excel', request);
    }

    getStockUnitList(request) {
        return axios.post('api/keyway/stockunit/list', request);
    }

    saveStockUnit(request) {
        return axios.post('api/keyway/stockunit/save', request);
    }

    getStock(prodCode) {
        return axios.get('api/Keyway/stock/one/?prodCode=' + prodCode);
    }

    deleteStockUnit(name, fullName) {
        return axios.delete('api/keyway/stockunit/delete?name=' + name + '&fullname=' + fullName);
    }

    deleteStockAttribute(type, value) {
        return axios.delete('api/keyway/stock/attribute/delete?type=' + type + '&value=' + value);
    }


    getStocks(request) {
        return axios.post('api/Keyway/stock/dropdown', request);
    }

    getStockColours(request) {
        return axios.post('api/Keyway/stock/colour/dropdown', request);
    }


    getStockAttributeList(req) {
        return axios.post('api/Keyway/stock/attribute/all', req);
    }

    saveStockAttribute(req) {
        return axios.post('api/Keyway/stock/save/attribute', req);
    }

    generateExcelBOMReport() {
        return axiosFileDownloader.get('api/Keyway/stock/export/BOM');
    }

    GetStockDetailsByProdCode(supplierCode, prodCode) {
        return axios.get("api/Keyway/stock/get/stock/detail/by/prodCode?prodCode=" + prodCode + "&supplierCode=" + supplierCode);
    }

    getStockTakeList(request) {
        return axios.post("api/Keyway/stock/take/list", request);
    }

    getStockTakeApprovalList(request) {
        return axios.post("api/Keyway/stock/take/approval/list", request);
    }

    updatedStockTake(request) {
        return axios.post('api/Keyway/stock/take/update', request);
    }

    updateStockOnLocation(request) {
        return axios.post('api/Keyway/stock/take/approval/status', request);
    }

}

export default KeywayStockService.Instance();