import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
// Individual dashboard chart components
import ProductionDashboardProductionDifot from './ProductionDashboardProductionDifot';
import ProductionDashboardProductionInvoicing from './ProductionDashboardProductionInvoicing';
import ProductionDashboardProductionLinePerformance from './ProductionDashboardProductionLinePerformance';
import ProductionDashboardProductionPipeline from './ProductionDashboardProductionPipeline';

export default class ProductionDashboardSummaryCard extends Component {

    render() {
        return (
            <Container fluid="true" style={{backgroundColor: 'black'}}>
                <Row>
                    <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                        <ProductionDashboardProductionLinePerformance/>
                    </Col>

                    <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                        <ProductionDashboardProductionInvoicing/>
                    </Col>
                </Row>
                <Row>
                    <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                        <ProductionDashboardProductionDifot/>
                    </Col>
                    <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                        <ProductionDashboardProductionPipeline/>
                    </Col>
                </Row>
            </Container>
        );
    }
}