import axios from "./axios";
class StockCostingMethodService{
    static Instance(){
        return new StockCostingMethodService();
    }

    getAllStockCostingMethods(){
        return axios.get('api/stock-costing-method/get/all')
    }
    setActive(newActive){
        return axios.post('api/stock-costing-method/set/active?newActive='+newActive)
    }
}
export default StockCostingMethodService.Instance();