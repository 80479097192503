import React, { Component } from 'react';
import { Badge, Button, Card, Col, Row, Spinner } from 'reactstrap';
import OrderService from '../../services/OrderService';
import { SummaryType } from './../../store/OrderGatewayTabs';
import { getDateString } from './../../services/CommonService';
import classnames from 'classnames';
import { handleErrorMessage } from "../../services/CommonService";

export default class OrderProductGrpDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categorySummary: [],
            totalRecords: 0,
            orderDetails: {},
            All: false,
            isloading: false,
        };

        this.refresh = this.refresh.bind(this);
        this.getParticularOrdersOfProduct = this.getParticularOrdersOfProduct.bind(this);
        this.emptySelections = this.emptySelections.bind(this);
        this.clearSelections = this.clearSelections.bind(this);
        this.orderService = new OrderService();
        this.renderProductsButtons = this.renderProductsButtons.bind(this);
    }

    emptySelections(item) {
        let { categorySummary } = this.state;
        (categorySummary || []).forEach(category => {
            category.isFilter = true;
        });
        this.setState({
            categorySummary,
            All: true
        }, () => this.getParticularOrdersOfProduct(item));
    }

    clearSelections() {
        this.emptySelections('');
        this.props.clearChange();
    }

    componentDidMount() {
        let { type, startDate, endDate } = this.props;
        this.setState({ type, startDate, endDate }, () => {
            this.refresh();
        });
    
    }

    componentWillReceiveProps(nextprops) {
        let { type, startDate, endDate } = this.state;
        if (type !== nextprops.type || startDate !== nextprops.startDate || endDate !== nextprops.endDate) {
            this.setState({ type: nextprops.type, startDate: nextprops.startDate, endDate: nextprops.endDate }, () => {
                this.refresh();
            });
        }

    }

    getParticularOrdersOfProduct(item) {
        if (item) {
            if (item.productId) {
                let { categorySummary } = this.state;
                (categorySummary || []).filter(category => category.productId == item.productId).forEach(category => {
                    category.isFilter = false;
                });
                this.setState({ categorySummary });
                this.props.handleChange(item,'productSearched');
            }
        }
    }

    renderProductsButtons(item) {
        return (<Card
            className={classnames("p-1", "  hoverableItem", { "border border-primary": !item.isFilter })}>
            <Button size={"sm"} color="link"
                onClick={() => this.emptySelections(item)} title="Click to filter">
                {item.code} <Badge color="danger" className={"ml-1"}>{item.totalCount}</Badge>
            </Button>
        </Card>);
    }

    refresh() {
        //get details here
        this.setState({ isloading: true });
        let request = {
            'orderType': this.state.type,
            'option': SummaryType[0].label,
            'startDate': getDateString(this.state.startDate, "DD/MM/YYYY"),
            'endDate': getDateString(this.state.endDate, "DD/MM/YYYY")
        };
        this.orderService.getSubmittedOrdersSummary(request).then(response => {
            let categorySummary = response.data.submittedOrdersCountSummaries;
            let totalRecords = response.data.totalRecords;
            (categorySummary || []).forEach(category => {
                category.isFilter = true;
            });
            this.setState({ categorySummary, totalRecords, isloading: false });
        }).catch(error => {
            this.setState({ isloading: false });
            console.log(error);
            alert(handleErrorMessage(error));
        });
    }

    render() {
        let { isloading, categorySummary, totalRecords } = this.state;
        if (isloading) {
            return <Spinner color={"primary"} size={"sm"} />;
        }
        return (
            <div>
                <Row>
                    <Col key={'20'} xl={2} lg={2} md={2} sm={4} xs={6} className="p-1">
                        <Card
                            className={classnames("p-1", "hoverableItem", { "border border-primary": !this.state.All })}>
                            <Button size={"sm"} color="link" onClick={this.clearSelections}>
                                All <Badge color="danger" className={"ml-1"}>{totalRecords}</Badge>
                            </Button>
                        </Card>
                    </Col>
                    {(categorySummary || []).map((item, key) =>
                        this.renderProductsButtons(item).length != 0 ?
                            <Col key={key} xl={2} lg={2} md={2} sm={4} xs={6} className={"p-1"}>
                                {this.renderProductsButtons(item)}
                            </Col>
                            : null
                    )}
                </Row>
            </div>
        );
    }
}