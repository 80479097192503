import axiosFileDownloader from './axiosFileDownloader';
import axios from './axios';
import { config } from '../helpers/config';
import axiosFileUploader from './axiosFileUploader';

class FabricService {

    static Instance() {
        return new FabricService();
    }

    GetFabrics(request) {
        return axios.post('api/fabric/list', request);
    }

    GetParticularFabric(Id) {
        return axios.get('api/fabric/one?FabricId=' + Id);
    }

    GetFabricColorList(request) {
        return axios.post('api/fabric/color/list', request);
    }

    GetDuplicateFabricColorList() {
        return axios.post('api/fabric/color/duplicate/list');
    }

    saveFabric(fabric) {
        return axios.post('api/fabric/save', fabric);
    }

    fetchFabricColors(Id) {
        return axios.get('api/fabric/colors/one?FabricId=' + Id);
    }

    getFabricColorByName(name) {
        return axiosFileDownloader.get('api/file/Generate/CatalogueFabricColor?FileName=' + name);
    }

    DeleteFabric(Id) {
        return axios.delete('api/fabric/delete?FabricId=' + Id);
    }

    UploadFabricBrochure(requestBody) {
        let user = JSON.parse(localStorage.getItem('user'));
        const request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + "api/fabric/brochure/upload",
            data: requestBody,
        };
        return axios(request);
    }

    GetFabricsByFabricType(fabricType) {
        return axios.get('api/fabric/type/list?FabricType=' + fabricType);
    }

    ListDistinctFabricColorByFabric(fabricId) {
        return axios.get('api/fabric/colors/name/one?FabricId=' + fabricId);
    }

    importFabricData(request) {
        let user = JSON.parse(localStorage.getItem('user'));
        const sending_request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + "api/fabric/import",
            data: request,
        };
        return axios(sending_request);
    }

    generateFabricDataImportFormat() {
        return axiosFileDownloader.get('api/fabric/import/format/generate');
    }

    GetFabricColorByStock(stockID) {
        return axios.get('api/fabric/colors/stock?StockId=' + stockID);
    }

    generateFabricSummaryInExcel(req) {
        return axiosFileDownloader.post('api/fabric/export', req);
    }

    importFabricColorsData(request) {
        return axiosFileUploader.post('api/fabric/colors/import', request);
    }

   

    fetchFabricDropdownOptions(req) {
        return axios.post('api/fabric/dropdown', req);
    }

    fetchFabricsForStackTake(request) {
        return axios.post('api/fabric/stocktake/list', request);
    }

    fetchFabricColorsforStockTakeModal(Id) {
        return axios.get('api/fabric/stocktake/fabriccolors/one?FabricId=' + Id);
    }

    updateStockTake(stockIntakeList) {
        return axios.post('api/fabric/stocktake/save', stockIntakeList);
    }

    deleteFabricColor(fabricColorID) {
        return axios.delete('api/fabric/color/delete?fabricColorID=' + fabricColorID);
    }
    generateFabricColorTemplateDownload() {
        return axiosFileDownloader.get('api/fabric/color/template/exportDownload');
    }

    generateFabricColorExcelReport(request) {
        return axiosFileDownloader.post('api/fabric/color/excel/report', request);
    }   
}
export default FabricService.Instance();