import React, {Component} from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Row,
    Col,
    FormText,
    FormGroup,
    Label
} from 'reactstrap';
import CustomerService from '../../services/CustomerService';
import {toast, ToastContainer} from 'react-toastify';
import {handleErrorMessage} from '../../services/CommonService';

export default class DebtorPreviewEditModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountID: props.accountID,
            reps: props.reps,
            debtorAccountStatuses: props.debtorAccountStatuses,
            debtorCategories: props.debtorCategories,
            debtorPaymentTerms: props.debtorPaymentTerms,
            debtorClassifications: props.debtorClassifications,
            isLoadingSave: false,
            loading: false,
            formErrors: {},
            debtor: {},
        };

        this.customerService = new CustomerService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.accountID !== this.state.accountID) {
            this.setState({accountID: nextProps.accountID}, () => this.refresh());
        }
    }

    refresh() {
        if (this.state.accountID && this.state.accountID !== '') {
            this.setState({loading: true});

            this.customerService.searchCustomer(this.state.accountID).then(response => {
                this.setState({debtor: response.data, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }


    handleChange(value, key) {
        let {debtor} = this.state;
        debtor[key] = value;
        this.setState({debtor});
    }

    validateForm(debtor) {
        let formErrors = {};
        let valid = true;


        if (!debtor.paymentTermsID) {
            formErrors.paymentTermsID = "Please select payment terms";
            valid = false;
        }

        if (!debtor.debtorCategoryID) {
            formErrors.debtorCategoryID = "Please select category";
            valid = false;
        }

        if (!debtor.classificationID) {
            formErrors.classificationID = "Please select classification";
            valid = false;
        }

        if (!debtor.accountStatusID && debtor.accountStatusID !==0) {
            formErrors.accountStatusID = "Please select account status";
            valid = false;
        }

        if (!debtor.repArea1) {
            formErrors.repArea1 = "Please select rep";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.debtor};
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            this.setState({isLoadingSave: true, formErrors: {}});

            this.customerService.updateDebtor(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Customer updated!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.props.refresh();
                    this.setState({isLoadingSave: false});
                }

            }).catch(error => {
                this.setState({isLoadingSave: false});
                toast.error(handleErrorMessage(error));
            });
        }
    }


    render() {
        let {isLoadingSave, loading, debtor, formErrors, debtorCategories, debtorClassifications, debtorAccountStatuses, reps, debtorPaymentTerms} = this.state;
        let {isOpen, toggleModal} = this.props;
        return (
            <Modal isOpen={isOpen}
                   size="md"
                   scrollable={false}
                   toggle={() => toggleModal(!isOpen)}>
                <ModalHeader toggle={() => toggleModal(!isOpen)}>
                    Edit preview
                </ModalHeader>
                <ModalBody>
                    {loading ? <Spinner color='primary' size='sm'/> :
                        <div>
                            <div>
                                <FormGroup>
                                    <Label>Category</Label>
                                    <Input
                                        invalid={formErrors.debtorCategoryID}
                                        name="debtorCategoryID"
                                        type="select"
                                        value={debtor.debtorCategoryID}
                                        onChange={(e) => this.handleChange(e.target.value, 'debtorCategoryID')}>
                                        <option value="">Select Category</option>
                                        {debtorCategories.map((category, index) =>
                                            <option key={index}
                                                    value={category.debtorCategoryID}>{category.name}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.debtorCategoryID}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Account Status</Label>
                                    <Input
                                        invalid={formErrors.accountStatusID}
                                        name="accountStatusID"
                                        type="select"
                                        value={debtor.accountStatusID}
                                        onChange={(e) => this.handleChange(e.target.value, 'accountStatusID')}>
                                        <option value="">Select Status</option>
                                        {debtorAccountStatuses.map((status, index) =>
                                            <option key={index} value={status.accountStatusID}>{status.description}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.accountStatusID}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Rep</Label>
                                    <Input
                                        invalid={formErrors.repArea1}
                                        name="repArea1"
                                        type="select"
                                        value={debtor.repArea1}
                                        onChange={(e) => this.handleChange(e.target.value, 'repArea1')}>
                                        <option value="">Select Rep</option>
                                        {reps.map((rep, index) =>
                                            <option key={index} value={rep.repCode}>{rep.name}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.repArea1}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Classification</Label>
                                    <Input
                                        invalid={formErrors.classificationID}
                                        name="classificationID"
                                        type="select"
                                        value={debtor.classificationID}
                                        onChange={(e) => this.handleChange(e.target.value, 'classificationID')}>
                                        <option value="">Select Classification</option>
                                        {debtorClassifications.map((classification, index) =>
                                            <option key={index}
                                                    value={classification.classificationID}>{classification.name}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.classificationID}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Payment Terms</Label>
                                    <Input
                                        invalid={formErrors.paymentTermsID}
                                        name="paymentTermsID"
                                        type="select"
                                        value={debtor.paymentTermsID}
                                        onChange={(e) => this.handleChange(e.target.value, 'paymentTermsID')}>
                                        <option value="">Select Status</option>
                                        {debtorPaymentTerms.map((term, index) =>
                                            <option key={index} value={term.paymentTermsID}>{term.description}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.paymentTermsID}</FormText>
                                </FormGroup>
                            </div>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button size={"sm"}
                                color='primary'
                                onClick={this.handleSubmit}
                                disabled={isLoadingSave}>
                            {
                                isLoadingSave
                                    ? <Spinner size="sm" className="mr-2" color={"light"}/>
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>
                            }
                            {
                                isLoadingSave
                                    ? "Saving"
                                    : "Save"
                            }
                        </Button>
                        <Button size={"sm"}
                                color={"secondary"}
                                className={"ml-2"}
                                onClick={() => toggleModal(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}