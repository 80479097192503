import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner, ButtonGroup
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import fabricService from "../../services/FabricService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Editor from '../../store/ReactQuillEditorConfig';
import queryString from 'query-string';
import FabricAttachmentUpload from './FabricAttachmentUpload';
import { handleErrorMessage } from "../../services/CommonService";

const fabricTypes = [
    {
        key: "OUTDOOR",
        value: "Outdoor"
    },
    {
        key: "INDOOR",
        value: "Indoor"
    }
];
const RailRoadedType = [

    { label: "Yes", value: "Yes" }, { label: "No", value: "No" }
];
class FabricForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fabricObj: this.initFabricObj(),
            formErrors: {},
            saving: false,
            isLoadingFabric: false
        };

        this.getFabric = this.getFabric.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            let { fabricObj } = this.state;
            fabricObj.id = searchParams.fabricId ? searchParams.fabricId : 0;
            this.setState({ fabricObj }, () => {
                if (fabricObj.id) {
                    this.getFabric();
                }
            })
        }
    }

    getFabric() {
        if (this.state.fabricObj.id) {
            this.setState({ isLoadingFabric: true });
            fabricService.GetParticularFabric(this.state.fabricObj.id).then(response => {
                if (response.data) {
                    this.setState({ fabricObj: response.data, isLoadingFabric: false });
                }
            }).catch(error => {
                this.setState({ isLoadingFabric: false });
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { fabricObj } = this.state;
        fabricObj[name] = value;
        this.setState({ fabricObj });
    }

    handleTextAreaChange(change, key) {
        let { fabricObj } = this.state;
        switch (key) {
            case "careInstructions":
            case "keyFeatures":
            case "suitableFor":
            case "description":
            case "canBeRailRoaded":
            case "isDiscontinued":
                fabricObj[key] = change;
                this.setState({ fabricObj });
                break;
            case "soldOut":
                fabricObj[key] = change;
                this.setState({ fabricObj });
                break;
            default:
                break;

        }
    }

    validateForm(fabricObj) {
        // true means invalid
        return {
            fabricName: fabricObj.fabricName.length === 0,
            width: fabricObj.width.length === 0,
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        let { fabricObj } = this.state;
        let errors = this.validateForm(fabricObj);
        const isAnyFieldPending = Object.keys(errors).some(x => errors[x]);
        this.setState({ formErrors: errors });
        if (!isAnyFieldPending) {
            this.setState({ saving: true });
            fabricService.saveFabric(fabricObj).then(response => {
                if (response.status === 200 || response.status === "200") {
                    this.setState({ fabricObj: response.data });
                    toast.success("Saved!");
                }
                this.setState({ saving: false });
            }).catch(error => {
                this.setState({ saving: false });
                console.error(error);
                toast.error(handleErrorMessage(error));
            });
        } else {
            this.setState({ saving: false });
            toast.info("Please fill required details!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    initFabricObj() {
        return {
            id: 0,
            fabricName: "",
            fabricType: "",
            category: "",
            description: "",
            keyFeatures: "",
            width: 3200,
            composition: "",
            weight: "",
            openness: "",
            weave: "",
            canBeRailRoaded: "",
            careInstructions: "",
            suitableFor: "",
            isDiscontinued: false,
            fabricImageAttachment: null,
            fabricBrochureAttachment: null,
            soldOut: false

        };
    }

    handleCancel() {
        this.props.history.push({ pathname: '/tools/manage/fabrics/tab', state: this.props.location.state });
    }

    render() {
        let { fabricObj, formErrors, isLoadingFabric } = this.state;
        const errors = formErrors;
        let err = Object.keys(errors).filter(x => errors[x]);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push({pathname:'/tools/manage/fabrics/tab', state:this.props.location.state })}>Fabric List</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{this.state.fabricObj.id ? 'Edit' : 'New'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Fabric</h5>
                    </CardHeader>
                    <CardBody>
                        {isLoadingFabric
                            ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Fabric Name</Label>
                                            <Input
                                                invalid={err.find(x => x === 'fabricName')}
                                                name="fabricName"
                                                type="text"
                                                value={fabricObj.fabricName}
                                                onChange={this.handleChange}
                                                placeholder="write fabric name name here" />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6} md={6} sm={6} xs={12}>
                                        <FormGroup>
                                            <Label>Fabric Type</Label>
                                            <Input
                                                type="select"
                                                value={this.state.fabricObj.fabricType}
                                                onChange={(e) => this.handleChange(e)}
                                                name="fabricType"
                                                placeholder="Select Fabric Type here">
                                                <option key='0' value='' disabled={true}>Select Fabric Type</option>
                                                {fabricTypes.map((item) => <option
                                                    key={item.key}
                                                    value={item.key}>{item.value}</option>)}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={12}>
                                        <FormGroup>
                                            <Label>Fabric Category</Label>
                                            <Input
                                                name="category"
                                                type="text"
                                                value={fabricObj.category}
                                                onChange={this.handleChange}
                                                placeholder="write category name here" />
                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col lg={3} md={3} sm={3} xs={12}>
                                        <FormGroup>
                                            <Label>Fabric Composition</Label>
                                            <Input
                                                name="composition"
                                                type="text"
                                                value={fabricObj.composition}
                                                onChange={this.handleChange}
                                                placeholder="write composition name here" />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={6}>
                                        <FormGroup>
                                            <Label>Fabric Width(mm)</Label>
                                            <Input
                                                name="width"
                                                type="text"
                                                value={fabricObj.width}
                                                onChange={this.handleChange}
                                                placeholder="write width here" />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={5}>
                                        <FormGroup>
                                            <Label>Fabric Weight</Label>
                                            <Input
                                                name="weight"
                                                type="text"
                                                value={fabricObj.weight}
                                                onChange={this.handleChange}
                                                placeholder="write weight here" />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={5}>
                                        <FormGroup>
                                            <Label>Is Discontinued</Label>
                                            <div>
                                                <ButtonGroup>
                                                    <Button
                                                        size={"sm"}
                                                        onClick={() => this.handleTextAreaChange(true, "isDiscontinued")}
                                                        color={fabricObj.isDiscontinued ? "danger" : "primary"}

                                                        outline={!fabricObj.isDiscontinued}>
                                                        Yes
                                                        {fabricObj.isDiscontinued
                                                            ? <i className="fa fa-check ml-2"
                                                                aria-hidden="true" />
                                                            : null
                                                        }</Button>
                                                    <Button
                                                        onClick={() => this.handleTextAreaChange(false, "isDiscontinued")}
                                                        color={fabricObj.isDiscontinued ? "primary" : "success"}
                                                        size={"sm"}
                                                        outline={fabricObj.isDiscontinued}>
                                                        No
                                                        {fabricObj.isDiscontinued
                                                            ? null
                                                            : <i className="fa fa-check ml-2" aria-hidden="true" />
                                                        }</Button>
                                                </ButtonGroup>
                                            </div> 
                                        </FormGroup>  
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={6}>
                                        <FormGroup>
                                            <Label>Sold Out</Label>
                                            <div>
                                                <ButtonGroup>
                                                    <Button
                                                        size={"sm"}
                                                        onClick={() => this.handleTextAreaChange(true, "soldOut")}
                                                        color={fabricObj.soldOut ? "danger" : "primary"}

                                                        outline={!fabricObj.soldOut}>
                                                        Yes
                                                        {fabricObj.soldOut
                                                            ? <i className="fa fa-check ml-2"
                                                                aria-hidden="true" />
                                                            : null
                                                        }</Button>
                                                    <Button
                                                        onClick={() => this.handleTextAreaChange(false, "soldOut")}
                                                        color={fabricObj.soldOut ? "primary" : "success"}
                                                        size={"sm"}
                                                        outline={fabricObj.soldOut}>
                                                        No
                                                        {fabricObj.soldOut
                                                            ? null
                                                            : <i className="fa fa-check ml-2" aria-hidden="true" />
                                                        }</Button>
                                                </ButtonGroup>
                                            </div> 
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={4} xs={4}>
                                        <FormGroup>
                                            <Label>Openness</Label>
                                            <Input
                                                name="openness"
                                                type="text"
                                                value={fabricObj.openness}
                                                onChange={this.handleChange}
                                                placeholder="write openness here" />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={4} xs={4}>
                                        <FormGroup>
                                            <Label>Fabric Weave</Label>
                                            <Input
                                                name="weave"
                                                type="text"
                                                value={fabricObj.weave}
                                                onChange={this.handleChange}
                                                placeholder="write weave here" />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={4} xs={4}>
                                        <FormGroup>
                                            <Label>Can Be Rail Roaded</Label>
                                            <Input type={"select"}
                                                name={"canBeRailRoaded"}
                                                value={fabricObj.canBeRailRoaded}
                                                onChange={(e) => this.handleTextAreaChange(e.target.value, "canBeRailRoaded")}>
                                                {RailRoadedType.map((option, index) =>
                                                    <option key={index} value={option.value}>{option.label}</option>
                                                )}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <ReactQuill value={fabricObj.description}
                                                modules={Editor.modules}
                                                formats={Editor.formats}
                                                onChange={(change) => this.handleTextAreaChange(change, "description")}
                                                bounds={'.quil-editor-root'} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Key Features</Label>
                                            <ReactQuill value={fabricObj.keyFeatures}
                                                modules={Editor.modules}
                                                formats={Editor.formats}
                                                onChange={(change) => this.handleTextAreaChange(change, "keyFeatures")}
                                                bounds={'.quil-editor-root'} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Care Instructions</Label>
                                            <ReactQuill value={fabricObj.careInstructions}
                                                modules={Editor.modules}
                                                formats={Editor.formats}
                                                onChange={(change) => this.handleTextAreaChange(change, "careInstructions")}
                                                bounds={'.quil-editor-root'} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Suitable For</Label>
                                            <ReactQuill value={fabricObj.suitableFor}
                                                modules={Editor.modules}
                                                formats={Editor.formats}
                                                onChange={(change) => this.handleTextAreaChange(change, "suitableFor")}
                                                bounds={'.quil-editor-root'} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving}>
                                {this.state.saving
                                    ? <Spinner size="sm" className="mr-2"
                                        style={{ color: "white" }} />
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <br />
                {this.state.fabricObj.id
                    ? <FabricAttachmentUpload fabricId={this.state.fabricObj.id} />
                    : null
                }
                <ToastContainer />
            </div>
        );
    }
}

export default withRouter(FabricForm)