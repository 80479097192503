import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../../services/CommonService';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';

import verticalOutdoorUtil from './VerticalOutdoorUtil';
import NumberFormat from "react-number-format";
import VerticalOutdoorBOMModal from "../verticalOutdoor/VerticalOutdoorBOMModal";
import VerticalOutdoorConsolidatedBOMModal from "../verticalOutdoor/VerticalOutdoorConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../custom/CustomUtil";
import {
    PRODUCT_BUILDER_CUSTOM_BUILD,
    PRODUCT_BUILDER_VERTICAL_OUTDOOR_PRODCODE
} from "../../../../../../store/AppConstants";

export default class VerticalOutdoor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = verticalOutdoorUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = verticalOutdoorUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                    product = verticalOutdoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_VERTICAL_OUTDOOR_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if(stocksByProdCode){
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o=> o.prodCode === s.prodCode);
                        }
                        if(stock){
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);

                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
            default:
                break;
        }
        product = verticalOutdoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = verticalOutdoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    updateConfiguration(product, itemIndex, context, order) {
        let {currentUser} = this.props;
        let {configurations, fabrics} = product.builder;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemVerticalOutdoor.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};


        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemVerticalOutdoor.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 2100;// default
        product.items[itemIndex].configuration.width.max = 8500;// default
        product.items[itemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemVerticalOutdoor.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 3000;
        }

        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemVerticalOutdoor.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }

        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {};
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.model);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};

        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.operation.selected = {};
        product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.operation);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
        product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
        product.items[itemIndex].configuration.operation.formError = {isValid: true, message: "", dom: null,};


        //controlSide
        configurationIndex = findIndex(configurations, "attributeKey", "controlSide");
        product.items[itemIndex].configuration.controlSide = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.controlSide.selected = {};
        product.items[itemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].configuration.controlSide.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.controlSide);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
        product.items[itemIndex].configuration.controlSide.formError = {isValid: true, message: "", dom: null,};


        //fabricType
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemVerticalOutdoor.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.fabricType.selected.isSelectable = true;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemVerticalOutdoor.fabricType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
        }

        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true
        };
        product.items[itemIndex].configuration.fabricColour.options = [];
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
            product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                return {optionKey: o.color,
                    optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                    ...o}
            });
        }
        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
            )
        });
        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
            optionIndex = -1;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.fabricColour);
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            if (optionIndex > -1) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex])) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                }
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};

        //fabricColourOffRange
        product.items[itemIndex].configuration.fabricColourOffRange = { selected: { isSelectable: false } };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = context.salesOrderItemVerticalOutdoor.fabricColourOffRange;
        } else {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
        }
        product.items[itemIndex].configuration.fabricColourOffRange.formError = { isValid: true, message: "", dom: null };

        //valance
        configurationIndex = findIndex(configurations, "attributeKey", "valance");
        product.items[itemIndex].configuration.valance = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.valance.selected = {};
        product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.valance);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
        product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
        product.items[itemIndex].configuration.valance.formError = {isValid: true, message: "", dom: null,};


        //hooding
        configurationIndex = findIndex(configurations, "attributeKey", "hooding");
        product.items[itemIndex].configuration.hooding = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hooding.selected = {};
        product.items[itemIndex].configuration.hooding.finalOptions = product.items[itemIndex].configuration.hooding.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.hooding);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
        product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
        product.items[itemIndex].configuration.hooding.formError = {isValid: true, message: "", dom: null,};


        //hoodingColour
        configurationIndex = findIndex(configurations, "attributeKey", "hoodingColour");
        product.items[itemIndex].configuration.hoodingColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hoodingColour.selected = {};
        product.items[itemIndex].configuration.hoodingColour.finalOptions = product.items[itemIndex].configuration.hoodingColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.hoodingColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
        product.items[itemIndex].configuration.hoodingColour.formError = {isValid: true, message: "", dom: null,};

        //hoodingColourPowdercoat
        product.items[itemIndex].configuration.hoodingColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = context.salesOrderItemVerticalOutdoor.hoodingColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.hoodingColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //channel
        configurationIndex = findIndex(configurations, "attributeKey", "channel");
        product.items[itemIndex].configuration.channel = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channel.selected = {};
        product.items[itemIndex].configuration.channel.finalOptions = product.items[itemIndex].configuration.channel.options;
        product.items[itemIndex].configuration.channel.finalOptions = product.items[itemIndex].configuration.channel.finalOptions.map(key => ({...key, isSelectable: true}));
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channel.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.channel);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.channel.selected.value = product.items[itemIndex].configuration.channel.finalOptions[optionIndex];
        product.items[itemIndex].configuration.channel.selected.dropdownValue = product.items[itemIndex].configuration.channel.selected.value.optionKey;
        product.items[itemIndex].configuration.channel.formError = {isValid: true, message: "", dom: null,};


        //channelColour
        configurationIndex = findIndex(configurations, "attributeKey", "channelColour");
        product.items[itemIndex].configuration.channelColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channelColour.selected = {};
        product.items[itemIndex].configuration.channelColour.finalOptions = product.items[itemIndex].configuration.channelColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channelColour.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.channelColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
        product.items[itemIndex].configuration.channelColour.formError = {isValid: true, message: "", dom: null,};

        //channelColourPowdercoat
        product.items[itemIndex].configuration.channelColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = context.salesOrderItemVerticalOutdoor.channelColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.channelColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //bottomRail
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRail");
        product.items[itemIndex].configuration.bottomRail = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRail.selected = {};
        product.items[itemIndex].configuration.bottomRail.finalOptions = product.items[itemIndex].configuration.bottomRail.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRail.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.bottomRail);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.bottomRail.selected.value = product.items[itemIndex].configuration.bottomRail.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomRail.selected.dropdownValue = product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomRail.formError = {isValid: true, message: "", dom: null,};


        //frameColour
        configurationIndex = findIndex(configurations, "attributeKey", "frameColour");
        product.items[itemIndex].configuration.frameColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frameColour.selected = {};
        product.items[itemIndex].configuration.frameColour.options = getSorted(product.items[itemIndex].configuration.frameColour.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.frameColour.finalOptions = product.items[itemIndex].configuration.frameColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.frameColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
        product.items[itemIndex].configuration.frameColour.formError = {isValid: true, message: "", dom: null,};

        //framePowdercoatColour
        product.items[itemIndex].configuration.framePowdercoatColour = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = context.salesOrderItemVerticalOutdoor.framePowdercoatColour;
        } else {
            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
        }
        product.items[itemIndex].configuration.framePowdercoatColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //keyway
        configurationIndex = findIndex(configurations, "attributeKey", "keyway");
        product.items[itemIndex].configuration.keyway = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.keyway.options = getSorted(product.items[itemIndex].configuration.keyway.options, "min", true, "max", true);
        product.items[itemIndex].configuration.keyway.finalOptions = product.items[itemIndex].configuration.keyway.options;
        product.items[itemIndex].configuration.keyway.selected = {
            value: {},
            dropdownValue: "",
            isSelected: true,
            isVisible: true
        };


        //bottomFinish
        configurationIndex = findIndex(configurations, "attributeKey", "bottomFinish");
        product.items[itemIndex].configuration.bottomFinish = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomFinish.selected = {};
        product.items[itemIndex].configuration.bottomFinish.finalOptions = product.items[itemIndex].configuration.bottomFinish.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomFinish.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.bottomFinish);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.bottomFinish.selected.value = product.items[itemIndex].configuration.bottomFinish.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomFinish.selected.dropdownValue = product.items[itemIndex].configuration.bottomFinish.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomFinish.formError = {isValid: true, message: "", dom: null,};

        //crankHandle
        configurationIndex = findIndex(configurations, "attributeKey", "crankHandle");
        product.items[itemIndex].configuration.crankHandle = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.crankHandle.selected = {};
        product.items[itemIndex].configuration.crankHandle.options = getSorted(product.items[itemIndex].configuration.crankHandle.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.crankHandle.finalOptions = product.items[itemIndex].configuration.crankHandle.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.crankHandle);
        }
        optionIndex = optionIndex > -1 ? optionIndex : findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', "1600mm White");
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
        product.items[itemIndex].configuration.crankHandle.formError = {isValid: true, message: "", dom: null,};


        //remote
        configurationIndex = findIndex(configurations, "attributeKey", "remote");
        product.items[itemIndex].configuration.remote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.remote.finalOptions = product.items[itemIndex].configuration.remote.options;

        product.items[itemIndex].configuration.remote.selected = {};
        product.items[itemIndex].configuration.remote.selected.isSelectable = false;
        product.items[itemIndex].configuration.remote.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.remote.selected.value = context.salesOrderItemVerticalOutdoor.remote;
        } else {
            product.items[itemIndex].configuration.remote.selected.value = false;
        }


        //bridge
        configurationIndex = findIndex(configurations, "attributeKey", "bridge");
        product.items[itemIndex].configuration.bridge = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bridge.finalOptions = product.items[itemIndex].configuration.bridge.options;
        product.items[itemIndex].configuration.bridge.selected = {};
        product.items[itemIndex].configuration.bridge.selected.isSelectable = false;
        product.items[itemIndex].configuration.bridge.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bridge.selected.value = context.salesOrderItemVerticalOutdoor.bridge;
        } else {
            product.items[itemIndex].configuration.bridge.selected.value = false;
        }

        //chargingCable
        configurationIndex = findIndex(configurations, "attributeKey", "chargingCable");
        product.items[itemIndex].configuration.chargingCable = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.chargingCable.finalOptions = product.items[itemIndex].configuration.chargingCable.options;
        product.items[itemIndex].configuration.chargingCable.selected = {};
        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.chargingCable.selected.value = context.salesOrderItemVerticalOutdoor.chargingCable;
        } else {
            product.items[itemIndex].configuration.chargingCable.selected.value = false;
        }

        //bracket
        configurationIndex = findIndex(configurations, "attributeKey", "bracket");
        product.items[itemIndex].configuration.bracket = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracket.finalOptions = product.items[itemIndex].configuration.bracket.options;
        product.items[itemIndex].configuration.bracket.selected = {};

        //consumable
        configurationIndex = findIndex(configurations, "attributeKey", "consumable");
        product.items[itemIndex].configuration.consumable = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.consumable.finalOptions = product.items[itemIndex].configuration.consumable.options;
        product.items[itemIndex].configuration.consumable.selected = {};

        //cordColour
        configurationIndex = findIndex(configurations, "attributeKey", "cordColour");
        product.items[itemIndex].configuration.cordColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.cordColour.selected = {};
        product.items[itemIndex].configuration.cordColour.finalOptions = product.items[itemIndex].configuration.cordColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.cordColour.finalOptions, 'optionKey', context.salesOrderItemVerticalOutdoor.cordColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.cordColour.selected.value = product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.cordColour.selected.dropdownValue = product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
        product.items[itemIndex].configuration.cordColour.formError = { isValid: true, message: "", dom: null, };

        product = verticalOutdoorUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);

        product = this.updateItemConfigurationOptions("hooding", product, itemIndex, order, true);
        product = verticalOutdoorUtil.updateRules("hoodingColour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
        product = verticalOutdoorUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, null);


        product = verticalOutdoorUtil.updateRules("location", product, itemIndex, order);
        product = verticalOutdoorUtil.updateRules("width", product, itemIndex, order);
        product = verticalOutdoorUtil.updateRules("drop", product, itemIndex, order);
        
        product = verticalOutdoorUtil.updateRules("crankHandle", product, itemIndex, order);
        product = verticalOutdoorUtil.updateRules("motorAccessories", product, itemIndex, order);

        product = verticalOutdoorUtil.updateRules("channel", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("channel", product, itemIndex, order, true);
        product = verticalOutdoorUtil.updateRules("channelColour", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
        product = verticalOutdoorUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);


        product = verticalOutdoorUtil.updateRules("controlSide", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, order, true);

        product = verticalOutdoorUtil.updateRules("bottomRail", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("bottomRail", product, itemIndex, order, true);

        product = verticalOutdoorUtil.updateRules("bottomFinish", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("bottomFinish", product, itemIndex, order, true);

        product = verticalOutdoorUtil.updateRules("valance", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);

        product = verticalOutdoorUtil.updateRules("crankHandle", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);

        product = verticalOutdoorUtil.updateRules("frameColour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("frameColour", product, itemIndex, order, true);

        product = verticalOutdoorUtil.updateRules("cordColour", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("cordColour", product, itemIndex, order, true);

        product = verticalOutdoorUtil.updateFormError("operation", product, itemIndex);
        product = verticalOutdoorUtil.updateFormError("fabricColourOffRange", product, itemIndex);
        product = verticalOutdoorUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
        product = verticalOutdoorUtil.updateFormError("channelColourPowdercoat", product, itemIndex);
        product = verticalOutdoorUtil.updateFormError("width", product, itemIndex, order);
        product = verticalOutdoorUtil.updateFormError("drop", product, itemIndex, order);
        product = verticalOutdoorUtil.updateFormError("frameColour", product, itemIndex);
        product = verticalOutdoorUtil.updateFormError("location", product, itemIndex, order);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let {order, currentUser} = this.props;

        let optionIndex = -1, setIndex = -1, sets = [],
            bOMCode = "", condition1 = "", condition2 = "",
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = product.items[itemIndex].configuration.drop.selected.value,
            isMotorised = verticalOutdoorUtil.isMotorised(product, itemIndex, order);


        if (key) {
            switch (key) {
                case "operation":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options.filter(o => o.condition1.includes(condition1));

                    //update selection, getting previous selection
                    if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                        optionIndex = product.items[itemIndex].configuration.operation.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.operation.selected.value.optionKey);
                    }

                    //if not found, getting ideal selection
                    if (optionIndex === -1) {
                        if (isMotorised) {
                            optionIndex = product.items[itemIndex].configuration.operation.finalOptions.findIndex(o => o.optionKey.includes("Motor"));
                        }
                    }
                    //if couldn't get first item
                    if (!isEmpty(product.items[itemIndex].configuration.operation.finalOptions)) {
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    product.items[itemIndex].configuration.operation.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.operation.finalOptions[optionIndex] : null;
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    product = verticalOutdoorUtil.updateRules("motorAccessories", product, itemIndex, order);


                    product = verticalOutdoorUtil.updateFormError("width", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateFormError("drop", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateFormError("operation", product, itemIndex, order);
                    break;
                case "keyway":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.keyway.selected.isSelectable) {
                        condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                        condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                        product.items[itemIndex].configuration.keyway.finalOptions = product.items[itemIndex].configuration.keyway.options.filter(o => o.condition1.includes(condition1) && o.condition2.includes(condition2)).map(key => ({ ...key, isSelected: true, isVisible: true }));

                        if (!isEmpty(product.items[itemIndex].configuration.keyway.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.keyway.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.keyway.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.keyway.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.keyway.selected.value = product.items[itemIndex].configuration.keyway.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.keyway.selected.dropdownValue = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.keyway.selected.value = null;
                        product.items[itemIndex].configuration.keyway.selected.dropdownValue = "";
                    }
                    break;
                case "fabricType":
                    if (product.items[itemIndex].configuration.model.selected.value.optionKey === "Patio Blind") {
                        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options.filter(f => ["Vistaweave 95", "Vistaweave 99", "PVC 0.75mm", "PVC 1.00mm"].includes(f.fabricName));
                    } else {
                        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricType.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricType.selected.value = null;
                        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = "";
                    }
                    break;
                case "fabricColour":
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                            return {
                                optionKey: o.color,
                                optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                                ...o
                            }
                        });
                        if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                            // no fabric color options if no fabric range
                            product.items[itemIndex].configuration.fabricColour.finalOptions = [];
                        } else {
                            product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
                                return (
                                    salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                                )
                            });
                        }
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricColour.selected.value = null;
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                    }
                    break;

                case "hooding":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product.items[itemIndex].configuration.hooding.finalOptions = product.items[itemIndex].configuration.hooding.options.filter(o => o.condition1.includes(condition1) && (isEmpty(o.condition2) ? true : o.condition2.includes(condition2)));

                    //update selection, getting previous selection
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                        optionIndex = product.items[itemIndex].configuration.hooding.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.hooding.selected.value.optionKey);
                    }

                    //if couldn't get first item
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.finalOptions)) {
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    product.items[itemIndex].configuration.hooding.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.hooding.finalOptions[optionIndex] : null;
                    product.items[itemIndex].configuration.hooding.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.hooding.selected.value) ? "" : product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                    break;
                case "hoodingColour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                    product.items[itemIndex].configuration.hoodingColour.finalOptions = product.items[itemIndex].configuration.hoodingColour.options.filter(o => o.condition1.includes(condition1));

                    //update selection, getting previous selection
                    if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                        optionIndex = product.items[itemIndex].configuration.hoodingColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey);
                    }

                    //if couldn't get first item
                    if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.finalOptions)) {
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    product.items[itemIndex].configuration.hoodingColour.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex] : null;
                    product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    break;
                case "channel":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.channel.selected.isSelectable) {
                        condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                        condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                        product.items[itemIndex].configuration.channel.finalOptions = product.items[itemIndex].configuration.channel.options.filter(o => o.condition1.includes(condition1) && o.condition2.includes(condition2)).map(key => ({ ...key, isSelectable: true }));

                        if (!isEmpty(product.items[itemIndex].configuration.channel.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.channel.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.channel.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.channel.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.channel.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.channel.selected.value = product.items[itemIndex].configuration.channel.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.channel.selected.dropdownValue = product.items[itemIndex].configuration.channel.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.channel.selected.value = null;
                        product.items[itemIndex].configuration.channel.selected.dropdownValue = "";
                    }
                    break;
                case "channelColour":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.channelColour.selected.isSelectable) {
                        condition1 = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "N/A" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                        product.items[itemIndex].configuration.channelColour.finalOptions = product.items[itemIndex].configuration.channelColour.options.filter(o => o.condition1.includes(condition1));

                        if (!isEmpty(product.items[itemIndex].configuration.channelColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.channelColour.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.channelColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.channelColour.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.channelColour.selected.value = null;
                        product.items[itemIndex].configuration.channelColour.selected.dropdownValue = "";
                    }
                    break;
                case "bottomRail":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.bottomRail.selected.isSelectable) {
                        condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                        condition2 = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "N/A" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                        product.items[itemIndex].configuration.bottomRail.finalOptions = product.items[itemIndex].configuration.bottomRail.options.filter(o => o.condition1.includes(condition1) && o.condition2.includes(condition2));

                        if (!isEmpty(product.items[itemIndex].configuration.bottomRail.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.bottomRail.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bottomRail.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.bottomRail.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.bottomRail.selected.value = product.items[itemIndex].configuration.bottomRail.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bottomRail.selected.dropdownValue = product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.bottomRail.selected.value = null;
                        product.items[itemIndex].configuration.bottomRail.selected.dropdownValue = "";
                    }
                    break;
                case "bottomFinish":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.bottomFinish.selected.isSelectable) {
                        condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                        condition2 = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "N/A" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                        product.items[itemIndex].configuration.bottomFinish.finalOptions = product.items[itemIndex].configuration.bottomFinish.options.filter(o => o.condition1.includes(condition1) && o.condition2.includes(condition2));

                        if (!isEmpty(product.items[itemIndex].configuration.bottomFinish.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.bottomFinish.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.bottomFinish.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bottomFinish.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.bottomFinish.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.bottomFinish.selected.value = product.items[itemIndex].configuration.bottomFinish.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bottomFinish.selected.dropdownValue = product.items[itemIndex].configuration.bottomFinish.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.bottomFinish.selected.value = null;
                        product.items[itemIndex].configuration.bottomFinish.selected.dropdownValue = "";
                    }
                    break;
                case "valance":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options.filter(o => o.condition1.includes(condition1));
                    if (product.items[itemIndex].configuration.valance.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.valance.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.valance.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.valance.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.valance.selected.value = null;
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = "";
                    }
                    break;
                case "crankHandle":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.crankHandle.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.crankHandle.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.crankHandle.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.crankHandle.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.crankHandle.selected.value = null;
                        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = "";
                    }
                    break;
                case "controlSide":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.controlSide.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.controlSide.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.controlSide.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.controlSide.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.controlSide.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.controlSide.selected.value = null;
                        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = "";
                    }
                    break;
                case "frameColour":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.frameColour.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.frameColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.frameColour.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.frameColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.frameColour.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.frameColour.selected.value = null;
                        product.items[itemIndex].configuration.frameColour.selected.dropdownValue = "";
                    }
                    break;
                case "cordColour":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.cordColour.selected.isSelectable) {
                        condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "N/A" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                        product.items[itemIndex].configuration.cordColour.finalOptions = product.items[itemIndex].configuration.cordColour.options.filter(o => o.condition1.includes(condition1));

                        if (!isEmpty(product.items[itemIndex].configuration.cordColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.cordColour.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.cordColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.cordColour.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.cordColour.selected.value = product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.cordColour.selected.dropdownValue = product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.cordColour.selected.value = null;
                        product.items[itemIndex].configuration.cordColour.selected.dropdownValue = "";
                    }
                    break;
                case "basePrice":
                    bOMCode = verticalOutdoorUtil.generateBOMCode(product, itemIndex);
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                        return x.bmCode === bOMCode;
                    });
                    if (isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                            return x.bmCode.includes(product.items[itemIndex].configuration.model.selected.value.optionKey);
                        });
                    }

                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= width);

                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= drop);

                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price * verticalOutdoorUtil.getPriceMatrixMultiplier(product, itemIndex);

                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = verticalOutdoorUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = verticalOutdoorUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = verticalOutdoorUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = verticalOutdoorUtil.updateFormError("drop", product, itemIndex);
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;

                    product = verticalOutdoorUtil.updateRules("width", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateRules("drop", product, itemIndex, order);

                    product = verticalOutdoorUtil.updateRules("fabricColourOffRange", product, itemIndex, order);

                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);

                    product = this.updateItemConfigurationOptions("hooding", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("hoodingColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, null);


                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("motorAccessories", product, itemIndex, order);

                    product = verticalOutdoorUtil.updateRules("channel", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("channel", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("channelColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);

                    product = verticalOutdoorUtil.updateRules("controlSide", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("bottomRail", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("bottomRail", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("bottomFinish", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("bottomFinish", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("valance", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("frameColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("frameColour", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("cordColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("cordColour", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateFormError("width", product, itemIndex);
                    product = verticalOutdoorUtil.updateFormError("drop", product, itemIndex);
                    product = verticalOutdoorUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                    product = verticalOutdoorUtil.updateFormError("operation", product, itemIndex);
                    product = verticalOutdoorUtil.updateFormError("channelColourPowdercoat", product, itemIndex);
                }
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    product = verticalOutdoorUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateRules("motorAccessories", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("hooding", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("hoodingColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, null);
                    product = verticalOutdoorUtil.updateRules("channel", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("channel", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("channelColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);

                    product = verticalOutdoorUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateFormError("width", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateFormError("drop", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateFormError("operation", product, itemIndex, order);
                }
                break;
            case "controlSide":
                optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                }
                break;
            case "bottomRail":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRail.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRail.selected.value = product.items[itemIndex].configuration.bottomRail.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRail.selected.dropdownValue = product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;

                    product = verticalOutdoorUtil.updateRules("frameColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("frameColour", product, itemIndex, order, true);

                }
                break;
            case "bottomFinish":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomFinish.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomFinish.selected.value = product.items[itemIndex].configuration.bottomFinish.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomFinish.selected.dropdownValue = product.items[itemIndex].configuration.bottomFinish.selected.value.optionKey;
                }
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("width", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateRules("drop", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = verticalOutdoorUtil.updateFormError("width", product, itemIndex);
                    product = verticalOutdoorUtil.updateFormError("drop", product, itemIndex);
                    product = verticalOutdoorUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                }
                break;
            case "fabricColour":
                product.items[itemIndex].configuration.fabricColour.selected.value = {};
                product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";

                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                product = verticalOutdoorUtil.updateFormError("fabric", product, itemIndex);
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.value = change;
                product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                product = verticalOutdoorUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                break;
            case "valance":
                optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                }
                break;
            case "frameColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;

                    product = verticalOutdoorUtil.updateRules("frameColour", product, itemIndex, null);
                    product = verticalOutdoorUtil.updateFormError("frameColour", product, itemIndex);
                }
                break;
            case "framePowdercoatColour":
                product.items[itemIndex].configuration.framePowdercoatColour.selected.value = change;
                product = verticalOutdoorUtil.updateFormError("frameColour", product, itemIndex);
                break;
            case "hooding":
                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                }
                product = verticalOutdoorUtil.updateRules("width", product, itemIndex, order);
                product = verticalOutdoorUtil.updateRules("drop", product, itemIndex, order);
                product = verticalOutdoorUtil.updateRules("hoodingColour", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
                product = verticalOutdoorUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, null);
                product = verticalOutdoorUtil.updateFormError("width", product, itemIndex);
                product = verticalOutdoorUtil.updateFormError("drop", product, itemIndex);
                product = verticalOutdoorUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
                break;
            case "hoodingColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    product = verticalOutdoorUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, null);
                    product = verticalOutdoorUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
                }
                break;
            case "hoodingColourPowdercoat":
                product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = change;
                product = verticalOutdoorUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
                break;
            case "channel":
                optionIndex = findIndex(product.items[itemIndex].configuration.channel.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channel.selected.value = product.items[itemIndex].configuration.channel.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channel.selected.dropdownValue = product.items[itemIndex].configuration.channel.selected.value.optionKey;

                    product = verticalOutdoorUtil.updateRules("channelColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
                    product = verticalOutdoorUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);
                    product = verticalOutdoorUtil.updateFormError("channelColourPowdercoat", product, itemIndex);

                    product = verticalOutdoorUtil.updateRules("bottomRail", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("bottomRail", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("bottomFinish", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("bottomFinish", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("valance", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);

                    product = verticalOutdoorUtil.updateRules("frameColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("frameColour", product, itemIndex, order, true);

                }
                break;
            case "channelColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.channelColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                    product = verticalOutdoorUtil.updateRules("channelColourPowdercoat", product, itemIndex, null);
                    product = verticalOutdoorUtil.updateFormError("channelColourPowdercoat", product, itemIndex);
                }
                break;
            case "channelColourPowdercoat":
                product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = change;
                product = verticalOutdoorUtil.updateFormError("channelColourPowdercoat", product, itemIndex);
                break;


            case "crankHandle":
                optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                }
                break;
            case "remote":
                product.items[itemIndex].configuration.remote.selected.value = change;
                break;
            case "bridge":
                product.items[itemIndex].configuration.bridge.selected.value = change;
                break;
            case "chargingCable":
                product.items[itemIndex].configuration.chargingCable.selected.value = change;
                break;
            case "cordColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.cordColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.cordColour.selected.value = product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.cordColour.selected.dropdownValue = product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
                }
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = verticalOutdoorUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }


    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}} className={"text-center"}>
                                        #
                                    </th>
                                    <th style={{minWidth: 150}} className={"text-center"}>
                                        Location
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Quantity
                                    </th>
                                    <th style={{minWidth: 275}} className={"text-center"}>
                                        Model
                                    </th>
                                    <th style={{minWidth: 270}} className={"text-center"}>
                                        Operation
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"text-center"}>
                                            Control/Motor
                                        </div>
                                        <div className={"text-center"}>
                                            Side
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Width(mm)
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Drop(mm)
                                    </th>
                                    <th style={{minWidth: 250}} className={"text-center"}>
                                        Fabric
                                    </th>
                                    <th style={{minWidth: 370}} className={"text-center"}>
                                        Hooding/Headrail
                                    </th>
                                    <th style={{minWidth: 275}} className={"text-center"}>
                                        Channel
                                    </th>
                                    <th style={{minWidth: 210}} className={"text-center"}>
                                        Bottom Rail/Finish
                                    </th>
                                    <th style={{minWidth: 175}} className={"text-center"}>
                                        Valance
                                    </th>
                                    <th style={{minWidth: 190}} className={"text-center"}>
                                        Crank Handle
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null

                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="text" name="location"
                                                               value={item.configuration.location.selected.value}
                                                               onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                               placeholder="location"/>
                                                        {
                                                            item.configuration.location.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            item.configuration.location.formError.isWarning
                                                                ? <Badge color="warning">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                                : null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="number" name="quantity"
                                                               invalid={!item.configuration.quantity.formError.isValid}
                                                               value={item.configuration.quantity.selected.value}
                                                               min={item.configuration.quantity.min}
                                                               max={item.configuration.quantity.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="quantity"/>

                                                        {
                                                            item.configuration.quantity.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.quantity.formError.message}
                                                                </Badge>
                                                        }
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="model"
                                                           value={item.configuration.model.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "model", itemIndex, true)}>
                                                        <option value={""} disabled>Select Model</option>
                                                        {
                                                            (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="operation"
                                                        value={item.configuration.operation.selected.dropdownValue}
                                                        onChange={(e) => this.handleChange(e.target.value, "operation", itemIndex, true)}
                                                        disabled={item.configuration.operation.finalOptions.length === 1}>
                                                        <option value={""} disabled>Select operation</option>
                                                        {
                                                            (item.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>

                                                    {
                                                        item.configuration.cordColour.selected.isSelectable
                                                            ? <Input type="select" name="cordColour"
                                                                className="mt-2"
                                                                value={item.configuration.cordColour.selected.dropdownValue}
                                                                onChange={(e) => this.handleChange(e.target.value, "cordColour", itemIndex, true)}>
                                                                <option value={""} disabled>Select Cord Colour
                                                                </option>
                                                                {
                                                                    (item.configuration.cordColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : null
                                                    }

                                                    {verticalOutdoorUtil.isMotorised(product, itemIndex)
                                                        ? <div className="mt-2">
                                                            {
                                                                verticalOutdoorUtil.isVisibleMotorAccessories("Windoware", product, itemIndex)
                                                                    ? <div className="mt-1">
                                                                        <strong>Windoware Accessories</strong>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.remote.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.remote.selected.value, "remote", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.remote.selected.value,
                                                                                        "fa-square-o": !item.configuration.remote.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.remote.attributeLabel}
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.bridge.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.bridge.selected.value, "bridge", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.bridge.selected.value,
                                                                                        "fa-square-o": !item.configuration.bridge.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.bridge.attributeLabel}
                                                                        </div>
                                                                        {item.configuration.chargingCable.selected.isSelectable
                                                                            ? <div>
                                                                                <Button
                                                                                    size={"sm"}
                                                                                    className={"mr-2 mb-1"}
                                                                                    color={"link"}
                                                                                    disabled={!item.configuration.chargingCable.selected.isSelectable}
                                                                                    onClick={() => this.handleChange(!item.configuration.chargingCable.selected.value, "chargingCable", itemIndex, true)}>
                                                                                    <i className={classnames("fa", "fa-lg", {
                                                                                            "fa-check-square-o": item.configuration.chargingCable.selected.value,
                                                                                            "fa-square-o": !item.configuration.chargingCable.selected.value,
                                                                                        }
                                                                                    )}/>
                                                                                </Button>
                                                                                {item.configuration.chargingCable.attributeLabel}
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }

                                                        </div>
                                                        : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.controlSide.selected.isVisible
                                                            ? <Input type="select" name="controlSide"
                                                                     value={item.configuration.controlSide.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "controlSide", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.controlSide.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : <span>N/A</span>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="width"
                                                           invalid={!item.configuration.width.formError.isValid}
                                                           value={item.configuration.width.selected.value}
                                                           min={item.configuration.width.min}
                                                           max={item.configuration.width.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="width"/>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="drop"
                                                           invalid={!item.configuration.drop.formError.isValid}
                                                           value={item.configuration.drop.selected.value}
                                                           min={item.configuration.drop.min}
                                                           max={item.configuration.drop.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="drop"/>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="select" name="fabricType"
                                                               disabled={!item.configuration.fabricType.selected.isSelectable}
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select range</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {isEmpty(item.configuration.fabricColour.finalOptions)
                                                        ? null
                                                        : <div className={"mt-2"}>
                                                            <Input type="select" name="fabricColour"
                                                                   disabled={!item.configuration.fabricColour.selected.isSelectable}
                                                                   value={item.configuration.fabricColour.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                <option value={""}>Select colour</option>
                                                                {
                                                                    (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                    }

                                                    {
                                                        item.configuration.fabricColourOffRange.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="fabricColourOffRange"
                                                                       value={item.configuration.fabricColourOffRange.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColourOffRange", itemIndex, true)}
                                                                       placeholder="Off range"
                                                                       title={"Enter Off range fabric name"}
                                                                />
                                                                {
                                                                    item.configuration.fabricColourOffRange.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.fabricColourOffRange.formError.message}
                                                                        </Badge>
                                                                }
                                                                {/*<div>
                                                                    <Badge color="info">
                                                                        <i className="fa fa-info-circle mr-2"
                                                                           aria-hidden="true"/>
                                                                        Price On Application
                                                                    </Badge>
                                                                </div>*/}
                                                            </div>
                                                            : null
                                                    }
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="hooding"
                                                           value={item.configuration.hooding.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "hooding", itemIndex, true)}>
                                                        <option value={""} disabled>Select type</option>
                                                        {
                                                            (item.configuration.hooding.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.hoodingColour.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="hoodingColour"
                                                                       value={item.configuration.hoodingColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "hoodingColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select colour</option>
                                                                    {
                                                                        (item.configuration.hoodingColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.hoodingColourPowdercoat.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="hoodingColourPowdercoat"
                                                                       value={item.configuration.hoodingColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "hoodingColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.hoodingColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.hoodingColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.channel.selected.isSelectable
                                                            ? <Input type="select" name="channel"
                                                                value={item.configuration.channel.selected.dropdownValue}
                                                                onChange={(e) => this.handleChange(e.target.value, "channel", itemIndex, true)}
                                                                disabled={item.configuration.channel.finalOptions.length === 1}>
                                                                <option value={""} disabled>Select Channel</option>
                                                                {
                                                                    (item.configuration.channel.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            disabled={!o.isSelectable}
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : null
                                                    }

                                                    {
                                                        item.configuration.channelColour.selected.isSelectable
                                                            ? <Input type="select" name="channelColour"
                                                                     className={classnames({"mt-2": item.configuration.channel.selected.isSelectable})}
                                                                     value={item.configuration.channelColour.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "channelColour", itemIndex, true)}>
                                                                <option value={""} disabled>Select Channel Colour
                                                                </option>
                                                                {
                                                                    (item.configuration.channelColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.channelColourPowdercoat.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="channelColourPowdercoat"
                                                                       value={item.configuration.channelColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "channelColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                />
                                                                {
                                                                    item.configuration.channelColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.channelColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {(!item.configuration.channel.selected.isSelectable
                                                        && !item.configuration.channelColour.selected.isSelectable
                                                        && !item.configuration.channelColourPowdercoat.selected.isSelectable
                                                    ) ? 'N/A'
                                                        : null}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {(!item.configuration.bottomRail.selected.isSelectable
                                                        && !item.configuration.bottomFinish.selected.isSelectable
                                                        && !item.configuration.frameColour.selected.isSelectable
                                                    ) ? 'N/A'
                                                        : null}
                                                    {
                                                        item.configuration.bottomRail.selected.isSelectable
                                                            ? <Input type="select"
                                                                name="bottomRail"
                                                                disabled={!item.configuration.bottomRail.selected.isSelectable || item.configuration.bottomRail.finalOptions.length === 1}
                                                                value={item.configuration.bottomRail.selected.dropdownValue}
                                                                onChange={(e) => this.handleChange(e.target.value, "bottomRail", itemIndex, true)}
                                                               >
                                                                <option value={""} disabled>Select Bottom Rail</option>
                                                                {
                                                                    (item.configuration.bottomRail.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bottomFinish.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select"
                                                                    name="bottomFinish"
                                                                    disabled={!item.configuration.bottomFinish.selected.isSelectable || item.configuration.bottomFinish.finalOptions.length === 1}
                                                                    value={item.configuration.bottomFinish.selected.dropdownValue}
                                                                    onChange={(e) => this.handleChange(e.target.value, "bottomFinish", itemIndex, true)}>
                                                                    <option value={""} disabled>Select Bottom Finish</option>
                                                                    {
                                                                        (item.configuration.bottomFinish.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.frameColour.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="frameColour"
                                                                       value={item.configuration.frameColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "frameColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select Bottom Rail Colour
                                                                    </option>

                                                                    {
                                                                        (item.configuration.frameColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                {
                                                                    item.configuration.framePowdercoatColour.selected.isSelectable
                                                                        ? <div className={"mt-2"}>
                                                                            <Input type="text" name="framePowdercoatColour"
                                                                                   value={item.configuration.framePowdercoatColour.selected.value}
                                                                                   onChange={(e) => this.handleChange(e.target.value, "framePowdercoatColour", itemIndex, true)}
                                                                                   placeholder="Powdercoat colour"
                                                                                   className={"mt-2"}
                                                                            />
                                                                            {
                                                                                item.configuration.framePowdercoatColour.formError.isValid
                                                                                    ? null
                                                                                    : <Badge color="danger">
                                                                                        {item.configuration.framePowdercoatColour.formError.message}
                                                                                    </Badge>
                                                                            }
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.valance.selected.isSelectable
                                                            ? <Input type="select"
                                                                     name="valance"
                                                                     disabled={!item.configuration.valance.selected.isSelectable}
                                                                     value={item.configuration.valance.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "valance", itemIndex, true)}>
                                                                <option value={""} disabled>Select Valance</option>
                                                                {
                                                                    (item.configuration.valance.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : 'N/A'}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.crankHandle.selected.isSelectable
                                                            ? <Input type="select" name="crankHandle"
                                                                     disabled={!product.items[itemIndex].configuration.crankHandle.selected.isSelectable}
                                                                     value={item.configuration.crankHandle.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "crankHandle", itemIndex, true)}>
                                                                <option value={""} disabled>Select Crank Handle</option>
                                                                {
                                                                    (item.configuration.crankHandle.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : 'N/A'
                                                    }

                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={15}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <VerticalOutdoorBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <VerticalOutdoorConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
