import React from 'react';
import {groupBy, roundUpToNearestEvenNumber} from "../../../../../../services/CommonService";
import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM,
    PRODUCT_BUILDER_CURTAIN_TRACK_PART, PRODUCT_BUILDER_CURTAIN_TRACK_PRODCODE
} from "../../../../../../store/AppConstants";
import curtainTrackCustomUtil from "./CurtainTrackCustomUtil";
import curtainTrackPartUtil from "./CurtainTrackPartUtil";

class CurtainTrackUtil {

    static Instance() {
        return new CurtainTrackUtil()
    }

    isFormErrorItemValid(product, itemIndex) {
        let result = true;
        switch (product.items[itemIndex].itemType) {
            case PRODUCT_BUILDER_CURTAIN_TRACK_PART:
                result = product.items[itemIndex].quantity.formError.isValid;
                break;
        }
        return result;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex, order);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            switch (product.items[itemIndex].itemType) {
                case PRODUCT_BUILDER_CURTAIN_TRACK_PART:
                    product = this.updateStocks("part", product, itemIndex, -1);
                    break;
                case PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM:

                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        product = this.updateStocks("productionCalculation", product, itemIndex, subItemIndex);
                    });

                    product = this.updateStocks("productionCalculation", product, itemIndex, -1);
                    product = this.updateStocks("model", product, itemIndex, -1);
                    product = this.updateStocks("split", product, itemIndex, -1);
                    product = this.updateStocks("operation-item", product, itemIndex, -1);
                    product = this.updateStocks("bendShape", product, itemIndex, -1, order);
                    product = this.updateStocks("numberOfBends", product, itemIndex, -1, order);
                    product = this.updateStocks("bracket", product, itemIndex, -1);
                    product = this.updateStocks("projection", product, itemIndex, -1);
                    product = this.updateStocks("returnOption", product, itemIndex, -1);
                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        product = this.updateStocks("productionCalculation", product, itemIndex, subItemIndex);
                        product = this.updateStocks("set", product, itemIndex, subItemIndex);
                        product = this.updateStocks("runner", product, itemIndex, subItemIndex);
                        product = this.updateStocks("operation-subItem", product, itemIndex, subItemIndex);
                        product = this.updateStocks("cutFee", product, itemIndex, subItemIndex);
                    });
                    product = this.updateStocks("remote", product, itemIndex, -1);
                    product = this.updateStocks("charger", product, itemIndex, -1);
                    product = this.updateStocks("bridge", product, itemIndex, -1);
                    break;
            }
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            let subItemStocks = [];

            switch (product.items[itemIndex].itemType) {
                case PRODUCT_BUILDER_CURTAIN_TRACK_PART:
                    stocks = product.items[itemIndex].stocks;
                    break;
                case PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM:

                    if (!isEmpty(product.items[itemIndex].configuration.split.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.split.selected.stocks];
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.operation.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.stocks];
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.remote.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.remote.selected.stocks];
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.charger.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.charger.selected.stocks];
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.bridge.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.bridge.selected.stocks];
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.bendShape.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.bendShape.selected.stocks];
                    }if (!isEmpty(product.items[itemIndex].configuration.numberOfBends.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.numberOfBends.selected.stocks];
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.returnOption.selected.stocks)) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.returnOption.selected.stocks];
                    }

                    // per sub-item
                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        subItemStocks = [];
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.stocks)) {
                            subItemStocks = [...subItemStocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.stocks];
                        }

                        //item stocks required twice in each subitem starts
                        if (!isEmpty(product.items[itemIndex].configuration.model.selected.stocks)) {
                            subItemStocks = [...subItemStocks, ...product.items[itemIndex].configuration.model.selected.stocks];
                        }
                        if (!isEmpty(product.items[itemIndex].configuration.bracket.selected.stocks)) {
                            subItemStocks = [...subItemStocks, ...product.items[itemIndex].configuration.bracket.selected.stocks];
                        }
                        if (!isEmpty(product.items[itemIndex].configuration.projection.selected.stocks)) {
                            subItemStocks = [...subItemStocks, ...product.items[itemIndex].configuration.projection.selected.stocks];
                        }
                        //item stocks required twice in each subitem ends

                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.bom.runner.selected.stocks)) {
                            subItemStocks = [...subItemStocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.bom.runner.selected.stocks];
                        }
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.bom.operation.selected.stocks)) {
                            subItemStocks = [...subItemStocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.bom.operation.selected.stocks];
                        }
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cutFee.selected.stocks)) {
                            subItemStocks = [...subItemStocks, ...product.items[itemIndex].subItems[subItemIndex].configuration.cutFee.selected.stocks];
                        }

                        product.items[itemIndex].subItems[subItemIndex].stocks = subItemStocks
                    });
                    break;
            }

            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }


    updateStocks(key, product, itemIndex, subItemIndex, order) {
        let stocks, stock, label, attribute,
            width, drop, comment, options,
            deduction, optionIndex, condition1, condition2, condition3, condition4, condition5, condition6, condition7,
            sets, cutLengthFee, result, temp;

        switch (key) {
            case "part":
                //base stock
                attribute = product.items[itemIndex].itemType;
                label = "";
                comment = "";
                stocks = [];
                stock = this.getStockItemInstance();
                stock.prodCode = product.items[itemIndex].prodCode;
                stock.price = product.items[itemIndex].sellQtyPrice;
                stock.keywayMeasurementUnit = product.items[itemIndex].sellUnitName;
                stock.swishMeasurementUnit = product.items[itemIndex].sellUnitName;
                stock.isParentItem = true;
                stocks.push(stock);

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].stocks = stocks;
                break;
            case "productionCalculation":
                if (subItemIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth = this.doCalculation("trackCutWidth", product, itemIndex, subItemIndex);
                }
                product.items[itemIndex].configuration.productionCalculation.selected.trackCutWidth = Math.max(...product.items[itemIndex].subItems.map(subItem => subItem.configuration.productionCalculation.selected.trackCutWidth));
                product.items[itemIndex].configuration.productionCalculation.selected.bracketQty = this.doCalculation("bracketQty", product, itemIndex, subItemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.ironRunnerNylon = this.doCalculation("ironRunnerNylon", product, itemIndex, subItemIndex);
                if (subItemIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.gliderQty = this.doCalculation("gliderQty", product, itemIndex, subItemIndex);
                    product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.cordLength = this.doCalculation("cordLength", product, itemIndex, subItemIndex);
                }
                break;
            case "set":
                attribute = "SET";
                label = "";
                result = 0;
                comment = "";
                stocks = [];


                stock = salesOrderProductBuilderV1Service.getStockFactoryInstance();
                stock.description = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.max;
                stock.prodCode = PRODUCT_BUILDER_CURTAIN_TRACK_PRODCODE;
                stock.price = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value) ? 0 : parseFloat(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.setKey);
                stocks.push(cloneDeep(stock));


                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "model":
                attribute = "Model";
                label = product.items[itemIndex].configuration.model.selected.value.optionKey;
                comment = "";
                stocks = [];
                condition1 = isEmpty(product.items[itemIndex].configuration.trackColour.selected.value) ? "" : product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                sets = product.items[itemIndex].configuration.model.selected.value.sets.filter(set => set.condition1.includes(condition1));
                sets.forEach(set => {
                    stocks = [...stocks, ...set.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.model.selected.stocks = stocks;
                break;
            case "split":
                attribute = "Split";
                label = product.items[itemIndex].configuration.split.selected.value.optionKey;
                comment = "";
                stocks = [];
                condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.trackType.selected.value) ? "" : product.items[itemIndex].configuration.trackType.selected.value.optionKey;
                sets = product.items[itemIndex].configuration.split.selected.value.sets.filter(set => (set.condition1 ? set.condition1.includes(condition1) : true) && (set.condition2 ? set.condition2.includes(condition2) : true));
                sets.forEach(set => {
                    stocks = [...stocks, ...set.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.split.selected.stocks = stocks;
                break;
            case "operation-item":
                attribute = "Operation";
                comment = "";
                stocks = [];

                //item specific
                if (!product.items[itemIndex].configuration.trackOnly.selected.value) {
                    if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                        label = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                        sets = product.items[itemIndex].configuration.operation.selected.value.sets;
                        sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks];
                        });
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.operation.selected.stocks = stocks;
                break;
            case "remote":
                attribute = "Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.remote.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.remote.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.remote.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.remote.selected.stocks = stocks;
                break;
            case "charger":
                attribute = "Charger";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.charger.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.charger.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.charger.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.charger.selected.stocks = stocks;
                break;
            case "bridge":
                attribute = "Bridge";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.bridge.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.bridge.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.bridge.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bridge.selected.stocks = stocks;
                break;
            case "bendShape":
                attribute = "Bend";
                comment = "";
                stocks = [];
                if (subItemIndex > -1) {
                    width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                } else {
                    temp = product.items[itemIndex].subItems.map((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
                    width = Math.max(...temp);
                }

                switch (order.accountID)
                {
                    case "06022":
                        condition3 = "06022";
                        break;
                    default:
                        condition3 = "default";
                        break;
                }

                if (!isEmpty(product.items[itemIndex].configuration.bendShape.selected.value)) {
                    label = product.items[itemIndex].configuration.bendShape.selected.value.optionKey;
                    condition1 = isEmpty(product.items[itemIndex].configuration.bendType.selected.value) ? "" : product.items[itemIndex].configuration.bendType.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.trackType.selected.value) ? "" : product.items[itemIndex].configuration.trackType.selected.value.optionKey;
                    sets = product.items[itemIndex].configuration.bendShape.selected.value.sets.filter(set =>
                        (set.condition1 ? set.condition1.includes(condition1) : true)
                        && (set.condition2 ? set.condition2.includes(condition2) : true)
                        && (set.condition3 ? set.condition3.includes(condition3) : true)
                        && (set.min <= width && set.max >= width)
                    );
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bendShape.selected.stocks = stocks;
                break;
            case "numberOfBends":
                attribute = "Bend";
                comment = "";
                stocks = [];

                switch (order.accountID)
                {
                    case "06022":
                        condition1 = "06022";
                        break;
                    default:
                        condition1 = "default";
                        break;
                }
                if (!isEmpty(product.items[itemIndex].configuration.numberOfBends.selected.value)) {
                    product.items[itemIndex].configuration.numberOfBends.selected.value.sets.forEach(set => {
                        if(set.condition1 ? set.condition1 === condition1 : true){
                            stocks = [...stocks, ...set.stocks];
                        }
                    });
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.numberOfBends.selected.stocks = stocks;
                break;
            case "runner":
                attribute = "Runner";
                label = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey;
                comment = "";
                stocks = [];
                if (curtainTrackCustomUtil.isApplicable(product, itemIndex, subItemIndex, "runner", true)) {
                    condition1 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                    sets = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.sets.filter(set => (set.condition1 ? set.condition1.includes(condition1) : true) && (set.condition2 ? set.condition2.includes(condition2) : true));
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                }
                product.items[itemIndex].subItems[subItemIndex].configuration.bom.runner.selected.stocks = stocks;
                break;
            case "operation-subItem":
                attribute = "Operation";
                label = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                comment = "";
                stocks = [];

                //subItem specific
                if (!product.items[itemIndex].configuration.trackOnly.selected.value) {
                    options = (product.items[itemIndex].configuration.operation.options || []).filter(o => o.condition1.includes(product.items[itemIndex].configuration.model.selected.value.optionKey) && o.condition2 === "bom");
                    options.forEach(o => {
                        switch (o.optionKey) {
                            case "Styleline Track":
                                condition1 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                                condition2 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey;
                                condition3 = isEmpty(product.items[itemIndex].configuration.trackColour.selected.value) ? "" : product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                                condition4 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey;
                                condition5 = product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value ? "underlap" : "N/A-underlap";
                                condition6 = product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value ? "overlap" : "N/A-overlap";

                                sets = o.sets.filter(set =>
                                    (set.condition1 ? set.condition1.includes(condition1) : true)
                                    && (set.condition2 ? set.condition2.includes(condition2) : true)
                                    && (set.condition3 ? set.condition3.includes(condition3) : true)
                                    && (set.description ? set.description.includes(condition4) : true)
                                    && (set.attribute ? (set.attribute.includes(condition5) || set.attribute.includes(condition6)) : true)
                                );
                                sets.forEach(set => {
                                    stocks = [...stocks, ...set.stocks];
                                });
                                break;
                            case "S2000 Track":
                                condition1 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                                condition2 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey;
                                condition3 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey;
                                condition4 = product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value ? "underlap" : "N/A-underlap";
                                condition5 = product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value ? "overlap" : "N/A-overlap";

                                sets = o.sets.filter(set =>
                                    (set.condition1 ? set.condition1.includes(condition1) : true)
                                    && (set.condition2 ? set.condition2.includes(condition2) : true)
                                    && (set.condition3 ? set.condition3.includes(condition3) : true)
                                    && (set.attribute ? (set.attribute.includes(condition4) || set.attribute.includes(condition5)) : true)
                                );
                                sets.forEach(set => {
                                    stocks = [...stocks, ...set.stocks];
                                });
                                break;
                            case "Motorised Track":
                                condition1 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                                condition2 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey;
                                condition3 = isEmpty(product.items[itemIndex].configuration.bendShape.selected.value) ? "" : product.items[itemIndex].configuration.bendShape.selected.value.optionKey;

                                sets = o.sets.filter(set =>
                                    (set.condition1 ? set.condition1.includes(condition1) : true)
                                    && (set.condition2 ? set.condition2.includes(condition2) : true)
                                    && (set.condition3 ? set.condition3.includes(condition3) : true)
                                );
                                sets.forEach(set => {
                                    stocks = [...stocks, ...set.stocks];
                                });
                        }
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.bom.operation.selected.stocks = stocks;
                break;
            case "bracket":
                attribute = "Bracket";
                comment = "";
                stocks = [];
                if (curtainTrackCustomUtil.isApplicable(product, itemIndex, -1, "bracket", true)) {
                    if (!isEmpty(product.items[itemIndex].configuration.bracket.selected.value)) {
                        label = product.items[itemIndex].configuration.bracket.selected.value.optionKey;
                        condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                        condition2 = isEmpty(product.items[itemIndex].configuration.trackType.selected.value) ? "" : product.items[itemIndex].configuration.trackType.selected.value.optionKey;

                        sets = product.items[itemIndex].configuration.bracket.selected.value.sets.filter(set =>
                            (set.condition1 ? set.condition1.includes(condition1) : true)
                            && (set.condition2 ? set.condition2.includes(condition2) : true)
                        );
                        sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks];
                        });
                        stocks.forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                        });
                    }
                }
                product.items[itemIndex].configuration.bracket.selected.stocks = stocks;
                break;
            case "projection":
                attribute = "Bracket";
                comment = "";
                stocks = [];
                if (curtainTrackCustomUtil.isApplicable(product, itemIndex, -1, "projection", true)) {
                    if (!isEmpty(product.items[itemIndex].configuration.projection.selected.value)) {
                        label = product.items[itemIndex].configuration.projection.selected.value.optionKey;
                        condition1 = isEmpty(product.items[itemIndex].configuration.trackColour.selected.value) ? "" : product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                        sets = product.items[itemIndex].configuration.projection.selected.value.sets.filter(set => (set.condition1 ? set.condition1.includes(condition1) : true));
                        sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks];
                        });
                        stocks.forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                        });
                    }
                }
                product.items[itemIndex].configuration.projection.selected.stocks = stocks;
                break;
            case "returnOption":
                attribute = "Return Option";
                comment = "";
                stocks = [];
                if (curtainTrackCustomUtil.isApplicable(product, itemIndex, -1, "returnOption", true)) {
                    optionIndex = product.items[itemIndex].configuration.returnOption.options.findIndex(o => o.optionKey === "bom");
                    if (optionIndex > -1 && (!isEmpty(product.items[itemIndex].configuration.returnOption.selected.value))) {

                        label = product.items[itemIndex].configuration.returnOption.selected.value.optionKey;
                        condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                        condition2 = isEmpty(product.items[itemIndex].configuration.trackType.selected.value) ? "" : product.items[itemIndex].configuration.trackType.selected.value.optionKey;
                        condition3 = isEmpty(product.items[itemIndex].configuration.trackColour.selected.value) ? "" : product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                        condition4 = isEmpty(product.items[itemIndex].configuration.returnOption.selected.value) ? "" : product.items[itemIndex].configuration.returnOption.selected.value.optionKey;
                        sets = product.items[itemIndex].configuration.returnOption.options[optionIndex].sets.filter(set =>
                            (set.condition1 ? set.condition1.includes(condition1) : true)
                            && (set.condition2 ? set.condition2.includes(condition2) : true)
                            && (set.condition3 ? set.condition3.includes(condition3) : true)
                            && (set.description ? set.description.includes(condition4) : true)
                        );
                        sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks];
                        });
                        stocks.forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                        });
                    }
                }
                product.items[itemIndex].configuration.returnOption.selected.stocks = stocks;
                break;
            case "cutFee":
                attribute = "Cut Fee";
                comment = "";
                stocks = [];
                (product.items[itemIndex].configuration.model.selected.stocks || []).forEach(s => {
                    if ((!isEmpty(product.builder.stockByProdCode[s.prodCode])) && (product.builder.stockByProdCode[s.prodCode].length > 0)) {
                        if (product.builder.stockByProdCode[s.prodCode].length > product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value) {
                            //it means asked width is smaller than the product length and requires cut
                            (product.items[itemIndex].subItems[subItemIndex].configuration.cutFee.finalOptions || []).forEach(o => {
                                stocks = [...stocks, ...o.stocks];
                            });
                        }
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].subItems[subItemIndex].configuration.cutFee.selected.stocks = stocks;
                break;
        }
        return product;
    }

    isItemTypeIsPart(product, itemIndex) {
        return this.isItemTypeMatching(product, itemIndex, PRODUCT_BUILDER_CURTAIN_TRACK_PART)
    }

    isItemTypeIsCustom(product, itemIndex) {
        return this.isItemTypeMatching(product, itemIndex, PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM)
    }

    isItemTypeMatching(product, itemIndex, targetItemType) {
        return targetItemType === product.items[itemIndex].itemType;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, subItemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1, width = 0, drop = 0, stockInventoryItem, isItemTypeCustom, temp, maxWidth;

        isItemTypeCustom = this.isItemTypeIsCustom(product, itemIndex);

        stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        if (isItemTypeCustom) {
            if (itemIndex !== null && itemIndex > -1) {
                if (subItemIndex > -1) {
                    width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                } else {
                    temp = product.items[itemIndex].subItems.map((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
                    width = Math.max(...temp);
                }
                drop = product.items[itemIndex].configuration.drop.selected.value;
            }
        }

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor,
        };

        if (isItemTypeCustom) {
            let customParams = {
                trackCutWidth: (subItemIndex > -1 ) ? product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth : product.items[itemIndex].configuration.productionCalculation.selected.trackCutWidth,
                bracketQty: product.items[itemIndex].configuration.productionCalculation.selected.bracketQty,
                gliderQty: subItemIndex > -1 ? product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.gliderQty : 0,
                cordLength: subItemIndex > -1 ? product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.cordLength : 0,
                isOneWayStack: curtainTrackCustomUtil.isOneWayStack(product, itemIndex, subItemIndex),
            };
            params = {...params, ...customParams};
        }

        switch (attribute) {
            case "Model":
            case "Split":
            case "Remote":
            case "Charger":
            case "Bridge":
            case "Bend":
            case "Operation":
            case "Bracket":
            case "Projection":
            case "Return Option":
            default:
                deduction = this.updateDeduction(attribute, product, itemIndex, subItemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.quantityMultiplier = stockItem.quantityMultiplier ? stockItem.quantityMultiplier : 1;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.comment = comment;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? (stockItem.cutWidthFormula ? this.resolveFormulaExpression(result, stockItem.cutWidthFormula.formula, params) : deduction.cutWidth) : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? (stockItem.cutDropFormula ? this.resolveFormulaExpression(result, stockItem.cutDropFormula.formula, params) : deduction.cutDrop) : 0;
                break;
        }
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        stockQtyExpression = stockQtyExpression.replaceAll("@TrackCutWidth", params.trackCutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@BracketQty", params.bracketQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@GliderQty", params.gliderQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@CordLength", params.cordLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@IsOneWayStack", params.isOneWayStack);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    updateDeduction(key, product, itemIndex, subItemIndex, stockInventoryItem) {
        let deduction, isItemTypeCustom, width, drop, model, operation, stacking, control, cordType, temp, hasBend;
        deduction = this.initDeduction();
        isItemTypeCustom = this.isItemTypeIsCustom(product, itemIndex);
        if (isItemTypeCustom) {
            if (subItemIndex > -1) {
                width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
            } else {
                temp = product.items[itemIndex].subItems.map((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
                width = Math.max(...temp);
            }
            drop = product.items[itemIndex].configuration.drop.selected.value;
            model = product.items[itemIndex].configuration.model.selected.value.optionKey;
            operation = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
            hasBend = curtainTrackCustomUtil.hasBend(product, itemIndex);
        }

        switch (key) {
            case "trackCutWidth":
                if (!hasBend) {
                    if (subItemIndex > -1) {
                        stacking = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey;
                        control = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                        cordType = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey;
                        switch (model) {
                            case "Styleline Track":
                                switch (control) {
                                    case "Hand Drawn":
                                        switch (stacking) {
                                            case "Left":
                                            case "Right":
                                                deduction.deductionWidth = -8;
                                                break;
                                            case "Centre Close":
                                                deduction.deductionWidth = -12;
                                                break;
                                            case "Centre Bunch":
                                            case "Freehand":
                                                deduction.deductionWidth = -4;
                                                break;
                                        }
                                        break;
                                    case "Cord Drawn":
                                        switch (stacking) {
                                            case "Left":
                                                switch (cordType) {
                                                    case "Right":
                                                        deduction.deductionWidth = -115;
                                                        break;
                                                    case "Left":
                                                    case "Dual Cord":
                                                    default:
                                                        deduction.deductionWidth = -58;
                                                        break;
                                                }
                                                break;
                                            case "Right":
                                                switch (cordType) {
                                                    case "Left":
                                                        deduction.deductionWidth = -115;
                                                        break;
                                                    case "Right":
                                                    case "Dual Cord":
                                                    default:
                                                        deduction.deductionWidth = -58;
                                                        break;
                                                }
                                                break;
                                            case "Centre Close":
                                            case "Centre Bunch":
                                            case "Freehand":
                                                switch (cordType) {
                                                    case "Left":
                                                    case "Right":
                                                    case "Dual Cord":
                                                    default:
                                                        deduction.deductionWidth = -115;
                                                        break;
                                                }
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case "S2000 Track":
                                switch (control) {
                                    case "Hand Drawn":
                                        switch (stacking) {
                                            case "Left":
                                            case "Right":
                                            case "Centre Close":
                                            case "Centre Bunch":
                                            case "Freehand":
                                                deduction.deductionWidth = -5;
                                                break;
                                        }
                                        break;
                                    case "Cord Drawn":
                                        switch (stacking) {
                                            case "Left":
                                                switch (cordType) {
                                                    case "Right":
                                                        deduction.deductionWidth = -85;
                                                        break;
                                                    case "Left":
                                                    case "Dual Cord":
                                                    default:
                                                        deduction.deductionWidth = -45;
                                                        break;
                                                }
                                                break;
                                            case "Right":
                                                switch (cordType) {
                                                    case "Left":
                                                        deduction.deductionWidth = -85;
                                                        break;
                                                    case "Right":
                                                    case "Dual Cord":
                                                    default:
                                                        deduction.deductionWidth = -45;
                                                        break;
                                                }
                                                break;
                                            case "Centre Close":
                                            case "Centre Bunch":
                                            case "Freehand":
                                                switch (cordType) {
                                                    case "Left":
                                                    case "Right":
                                                    case "Dual Cord":
                                                    default:
                                                        deduction.deductionWidth = -85;
                                                        break;
                                                }
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case "Motorised Track":
                                switch (operation) {
                                    case "4-Core AC Motor":
                                    case "Remote AC Motor":
                                        deduction.deductionWidth = -148;
                                        break;
                                    case "Remote DC Battery Motor":
                                        deduction.deductionWidth = -161;
                                        break;
                                    case "No Motor":
                                    case "Manual":
                                    default:
                                        deduction.deductionWidth = 0;
                                        break;
                                }
                                break;
                        }
                    }
                }

                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.model.selected.deduction = deduction;
                break;
        }

        return deduction;
    }

    doCalculation(key, product, itemIndex, subItemIndex) {
        let result = 0, optionIndex = -1, setIndex = -1, width, condition1, condition2, deduction, isOneWayStack, temp;
        if (subItemIndex > -1) {
            width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
        } else {
            temp = product.items[itemIndex].subItems.map((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
            width = Math.max(...temp);
        }

        switch (key) {
            case "trackCutWidth":
                deduction = this.updateDeduction(key, product, itemIndex, subItemIndex, null);
                result = deduction.cutWidth;
                break;
            case "bracketQty":
                result = 0; //default
                optionIndex = product.items[itemIndex].configuration.bracket.options.findIndex(o => o.optionKey === "Bracket and Glides Table");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = "Bracket";
                    setIndex = product.items[itemIndex].configuration.bracket.options[optionIndex].sets.findIndex(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                        && (s.min <= width && s.max >= width)
                    );
                    if (setIndex > -1) {
                        result = product.items[itemIndex].configuration.bracket.options[optionIndex].sets[setIndex].setNum;
                    }
                }
                break;
            case "gliderQty":
                result = 0; //default
                optionIndex = product.items[itemIndex].configuration.bracket.options.findIndex(o => o.optionKey === "Bracket and Glides Table");
                if (optionIndex > -1) {
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value)) {
                        switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                            case "Styleline Track":
                                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                                condition2 = "Glides";
                                setIndex = product.items[itemIndex].configuration.bracket.options[optionIndex].sets.findIndex(s =>
                                    (s.condition1 ? s.condition1.includes(condition1) : true)
                                    && (s.condition2 ? s.condition2.includes(condition2) : true)
                                    && (s.min <= width && s.max >= width)
                                );
                                if (setIndex > -1) {
                                    result = product.items[itemIndex].configuration.bracket.options[optionIndex].sets[setIndex].setNum;
                                }
                                break;
                            case "S2000 Track":
                                switch (product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey) {
                                    case "Plain":
                                        condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                                        condition2 = "Glides";
                                        setIndex = product.items[itemIndex].configuration.bracket.options[optionIndex].sets.findIndex(s =>
                                            (s.condition1 ? s.condition1.includes(condition1) : true)
                                            && (s.condition2 ? s.condition2.includes(condition2) : true)
                                            && (s.min <= width && s.max >= width)
                                        );
                                        if (setIndex > -1) {
                                            result = product.items[itemIndex].configuration.bracket.options[optionIndex].sets[setIndex].setNum;
                                        }
                                        break;
                                    case "100% Wavefold":
                                    case "Easy Fit":
                                        isOneWayStack = curtainTrackCustomUtil.isOneWayStack(product, itemIndex, subItemIndex);
                                        if (isOneWayStack) {
                                            //Cut Size / 58, then round up nearest even number
                                            result = product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth / 58;
                                            result = roundUpToNearestEvenNumber(result);
                                        } else {
                                            //Cut Size / 58 / 2, Then round up nearest even number , then x 2
                                            result = product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth / 58;
                                            result = result / 2;
                                            result = roundUpToNearestEvenNumber(result);
                                            result = result * 2;
                                        }
                                        break;
                                }
                                break;
                            case "Motorised Track":
                                switch (product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey) {
                                    case "Track Runner":
                                        condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                                        condition2 = "Glides";
                                        setIndex = product.items[itemIndex].configuration.bracket.options[optionIndex].sets.findIndex(s =>
                                            (s.condition1 ? s.condition1.includes(condition1) : true)
                                            && (s.condition2 ? s.condition2.includes(condition2) : true)
                                            && (s.min <= width && s.max >= width)
                                        );
                                        if (setIndex > -1) {
                                            result = product.items[itemIndex].configuration.bracket.options[optionIndex].sets[setIndex].setNum;
                                        }
                                        break;
                                    case "100% Wavefold":
                                    case "Easy Fit":
                                        if (subItemIndex > -1) {
                                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value)) {
                                                isOneWayStack = curtainTrackCustomUtil.isOneWayStack(product, itemIndex, subItemIndex);
                                                if (isOneWayStack) {
                                                    //Cut Size / 53, then round up nearest even number
                                                    result = product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth / 53;
                                                    result = roundUpToNearestEvenNumber(result);
                                                } else {
                                                    //Cut Size / 53 / 2, Then round up nearest even number , then x 2
                                                    result = product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth / 53;
                                                    result = result / 2;
                                                    result = roundUpToNearestEvenNumber(result);
                                                    result = result * 2;
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                        }
                    }
                }
                break;
            case "cordLength":
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value)) {
                    switch (product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value.optionKey) {
                        case "Standard":
                            result = (width * 2) + 3150;
                            break;
                        case "Custom":
                            result = (width * 2) + ((parseInt(product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value)) * 2);
                            break;
                    }
                }
                break;
        }
        return result;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = 0, isItemTypeIsPart, isItemTypeIsCustom;

            switch (product.items[itemIndex].itemType) {
                case PRODUCT_BUILDER_CURTAIN_TRACK_PART:
                    isItemTypeIsPart = true;
                    isItemTypeIsCustom = false;
                    qty = product.items[itemIndex].quantity.selected.value;
                    break;
                case PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM:
                    isItemTypeIsPart = false;
                    isItemTypeIsCustom = true;
                    qty = product.items[itemIndex].configuration.quantity.selected.value;
                    break;
                default:
            }

            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);

            if (isItemTypeIsCustom) {
                // per sub-item
                product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                    subItem.pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, subItem.stocks, subItem.pricing, discount);

                    product.items[itemIndex].pricing.unitPrice += subItem.pricing.unitPrice;
                    product.items[itemIndex].pricing.unitDiscVal += subItem.pricing.unitDiscVal;
                    product.items[itemIndex].pricing.price += subItem.pricing.price;
                    product.items[itemIndex].pricing.discVal += subItem.pricing.discVal;
                    product.items[itemIndex].pricing.discount = subItem.pricing.discount;
                });
            }
        }
        return product;
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }

    validateItem(pg, itemIndex, order, errors) {
        switch (pg.items[itemIndex].itemType) {
            case PRODUCT_BUILDER_CURTAIN_TRACK_PART:
                errors = curtainTrackPartUtil.validateItem(pg, itemIndex, order, errors);
                break;
            case PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM:
                errors = curtainTrackCustomUtil.validateItem(pg, itemIndex, order, errors);
                break;
            default:
        }
        return errors;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0, width;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;

            width = 0;
            switch (product.items[itemIndex].itemType) {
                case PRODUCT_BUILDER_CURTAIN_TRACK_PART:
                    //quantity =  product.items[itemIndex].quantity.selected.value;
                    // no need to include packaging & handling charges here
                    if (product.items[itemIndex].dimensionUnitName === "mm") {
                        width = product.items[itemIndex].length ? product.items[itemIndex].length : 0;
                    }
                    break;
                case PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM:
                    quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);

                    const widths = product.items[itemIndex].subItems.map((subItem, subItemIndex) => {
                        return product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                    });
                    width = Math.max(...widths);
                    break;
            }
            if (product.maxWidth < width) {
                product.maxWidth = width;
            }
        });
        return product;
    }

    getStockItemInstance() {
        return cloneDeep({
            id: null,
            prodCode: "",
            price: 0,
            flatPrice: 0,
            keywayMeasurementUnit: "unit",
            swishMeasurementUnit: "unit",
            keywayConversionFactor: 1,
            swishConversionFactor: 1,
            isVisibleInPartList: true,
            isDeductionApplicable: false,
            description: "",
            width: 0,
            drop: 0,
            swishCalculatedQty: 1,
            keywayCalculatedQty: 1,
            wastagePercent: 0,
            attribute: "",
            label: "",
            attributeRowSpan: 0,
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
            isParentItem: false
        });
    }

    findItemIndex(product, customID) {
        return product.items.findIndex(i => i.customID === customID);
    }

    getItemTypeSummary(product) {
        let itemsByItemType = groupBy((product.items || []), 'itemType');
        let itemSummary = {};

        [PRODUCT_BUILDER_CURTAIN_TRACK_PART, PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM].forEach(itemType => {
            if (!itemsByItemType[itemType]) {
                itemsByItemType[itemType] = [];
            }

            itemSummary[itemType] = {
                items: itemsByItemType[itemType],
                count: itemsByItemType[itemType].length,
                price: 0,
                discVal: 0,
                priceWithoutDiscVal: 0,
            };

            itemSummary[itemType].items.forEach(item => {
                itemSummary[itemType].price += item.pricing.price;
                itemSummary[itemType].discVal += item.pricing.discVal;
                itemSummary[itemType].priceWithoutDiscVal += item.pricing.price - item.pricing.discVal;
            });
        });

        return itemSummary;
    }
}

export default CurtainTrackUtil.Instance();