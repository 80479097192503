import React, {Component} from 'react';
import {
    Badge, Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Spinner,
    Table, ButtonGroup
} from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';
import {Link} from "react-router-dom";
import moment from 'moment';
import {changeFormatOfDateString, getDateString, handleErrorMessage} from '../../services/CommonService';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import Pagination from "react-js-pagination";
import SearchCreditorAcccount from "../../components/search/SearchCreditorAcccount";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import * as FileSaver from 'file-saver';
import SearchStock from "../../components/search/SearchStock";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../store/AppConstants";
import StockQtyFormat from "../../components/stock/StockQtyFormat";

export const maxDate = moment().add(2, 'years').toDate();
export const minDate = moment().subtract(20, 'years').toDate();
const date = new Date();

export default class PurchaseOrderPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTable: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "itemEtaDate",
                        direction: true
                    },
                    filterRequest: {
                        ordNum: null,
                        statusID: null,
                        supplierCode: '',
                        outstanding: true,
                        cancelled: false,
                        isPurchaseHistory: null,
                        startDate: moment().subtract(6, 'months').toDate(),
                        endDate: date,
                        orderStatusDescription: 'Sent'
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            stockReceived: {
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: false,
            loadingExport: false,
            prodCode: this.props.prodCode,
            statuses: [],
            pageType: props.pageType,
            purchaseOrderType: props.purchaseOrderType,
            addAndUpdateOrder: props.addAndUpdateOrder,
            supplierAccountID: props.supplierAccountID,
            isOpenModal: false,
            selectedOrderData: {}

        };

        this.purchaseOrderService = new PurchaseOrderService();
        this.getPurchaseOrderItemData = this.getPurchaseOrderItemData.bind(this);
        this.getPurchaseOrderItemReceivedData = this.getPurchaseOrderItemReceivedData.bind(this);
        this.getPurchaseOrderStatuses = this.getPurchaseOrderStatuses.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getStore = this.getStore.bind(this);
        this.getStoreStockReceived = this.getStoreStockReceived.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.exportData = this.exportData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        let { dataTable } = this.state;
        let { filterRequest, pageRequest, sortRequest } = dataTable.request;
        let searchParams = queryString.parse(window.location.search);
        if (searchParams) {
            filterRequest.orderStatusDescription = searchParams.orderStatusDescription ? searchParams.orderStatusDescription : filterRequest.orderStatusDescription;
            filterRequest.supplierCode = searchParams.supplierCode ? searchParams.supplierCode : filterRequest.supplierCode;
            filterRequest.ordNum = searchParams.ordNum ? searchParams.ordNum : filterRequest.ordNum;
            filterRequest.prodCode = searchParams.prodCode ? searchParams.prodCode : filterRequest.prodCode;
            filterRequest.outstanding = searchParams.outstanding ? searchParams.outstanding : filterRequest.outstanding;

            pageRequest.currentPage = searchParams.currentPage ? parseInt(searchParams.currentPage) : pageRequest.currentPage;
            pageRequest.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : pageRequest.pageSize;

            sortRequest.key = searchParams.sortRequestKey ? searchParams.sortRequestKey : sortRequest.key;
            sortRequest.direction = searchParams.sortRequestDirection ? searchParams.sortRequestDirection : sortRequest.direction;
        }
        if (this.props.pageType === "creditor") {
            sortRequest.key = "dateAdded";
        }
        this.getPurchaseOrderStatuses();
        this.setState({dataTable}, () => {
            this.refresh(dataTable);
        });
    }

    componentWillReceiveProps(nextprops) {
        let {dataTable, pageType} = this.state;
        let {sortRequest} = dataTable.request;
        if (this.state.prodCode !== nextprops.prodCode || this.state.supplierAccountID !== nextprops.supplierAccountID || pageType !== nextprops.pageType) {
            sortRequest.key = nextprops.pageType === "creditor" ? "dateAdded" : "ordNum";
            this.setState({
                prodCode: nextprops.prodCode,
                supplierAccountID: nextprops.supplierAccountID,
                pageType: nextprops.pageType,
                dataTable
            }, function () {
                this.refresh(this.state.dataTable);
            });
        }
    }

    refresh(dataTable) {
        this.getPurchaseOrderItemData(dataTable, this.state.prodCode);
        if (this.props.showReceived) {
            this.getPurchaseOrderItemReceivedData(dataTable, this.state.prodCode);
        }
    }

    getPurchaseOrderItemData(dataTable, prodCode) {
        this.setState({loading: true});
        let request = cloneDeep(dataTable.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        if (this.state.supplierAccountID) {
            request.filterRequest.supplierCode = this.state.supplierAccountID;
        }
        if (prodCode) {
            request.filterRequest.prodCode = prodCode;
        }
        this.purchaseOrderService.getPurchaseOrderItemByProdCode(request).then(response => {
            dataTable.response = response.data;
            this.setState({dataTable, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getPurchaseOrderItemReceivedData(dataTable, prodCode) {
        let {stockReceived} = this.state;
        this.setState({loading: true});
        if (prodCode) {
            let request = cloneDeep(dataTable.request);
            if (request.filterRequest.startDate) {
                request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
            }
            if (request.filterRequest.endDate) {
                request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
            }
            this.purchaseOrderService.getPurchaseOrderItemReceivedByProdCode(request, prodCode).then(response => {
                stockReceived.response = response.data;
                this.setState({stockReceived, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    exportData() {
        let {dataTable, prodCode} = this.state;
        let request = cloneDeep(dataTable.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.setState({loadingExport: true});
        this.purchaseOrderService.exportPurchaseOrderItemByProdCode(request, prodCode).then(response => {
            this.setState({loadingExport: false});
            FileSaver.saveAs(response.data, "Purchase Orders" + ".xlsx");
        }).catch(error => {
            this.setState({loadingExport: false});
            toast.error(handleErrorMessage(error));
        });
    };

    getPurchaseOrderStatuses() {
        this.purchaseOrderService.getPurchaseOrderStatuses().then(response => {
            let statuses = response.data;
            this.setState({statuses})
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStore() {
        let openModal = this.openModal;
        let {pageType, addAndUpdateOrder, purchaseOrderType} = this.state;
        let store = [
            {
                key: "dateAdded",
                label: "PO Date",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY')}</span>;
                }
            },
            {
                key: "ordNum",
                label: "PO No",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link to={"/purchase/order/enquiry?" + queryString.stringify({ordNum: value})}
                                 className="btn btn-sm btn-primary"
                                 title={"Click Here to see the Order Details"}
                                 style={{cursor: "pointer"}}>
                        {value}
                    </Link>;
                }

            },
            {
                key: "prodCode",
                label: "ProdCode",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link className="btn btn-sm btn-primary" style={{color: "white"}}
                                 title={"Click here to see stock details"}
                                 to={"/inventory/stock/enquiry?prodCode=" + value}>
                        {value}
                    </Link>;
                }
            }, {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: pageType !== "stock",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <small> {value}</small>;
                }
            }, {
                key: "supplierCode",
                label: "Supplier Code",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: pageType === "stock",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link className="btn btn-primary btn-sm"
                                 to={"/creditor/enquiry?" + queryString.stringify({accountID: value})}>
                        {value}
                    </Link>;
                }
            },
            {
                key: "supplier",
                label: "Supplier",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: pageType === "stock",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <small>{value}</small>;
                }
            },
            {
                key: "cost",
                label: "PO Cost",//Cost in Supplier Currency",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle",
                showColumn: pageType === "creditor",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <NumberFormat value={value}
                                         displayType={'text'}
                                         decimalScale={2}
                                         thousandSeparator={true}
                                         fixedDecimalScale={true}
                                         prefix={record.currencySymbol}/>;
                }
            },
            {
                key: "landedCost",
                label: "Landed Cost",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    if (record.orderStatusDescription === "Completed") {
                        return <NumberFormat value={value}
                                             displayType={'text'}
                                             decimalScale={2}
                                             thousandSeparator={true}
                                             fixedDecimalScale={true}
                                             prefix={'$'}/>;
                    }
                    return "-"
                }
            }, {
                key: "qty",
                label: "Qty",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            },
            {
                key: "orderStatusDescription",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    let color = "";
                    switch (value) {
                        case "Cancelled":
                            color = "danger";
                            break;
                        case "In Progress":
                            color = "secondary";
                            break;
                        case "On Hold":
                            color = "primary";
                            break;
                        case "Approved":
                            color = "warning";
                            break;
                        case "Sent":
                            color = "info";
                            break;
                        case "Completed":
                            color = "success";
                            break;
                    }
                    return <Badge color={color}> {value}</Badge>;
                }
            }, {
                key: "itemEtaDate",
                label: "ETA",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span>{value ? changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY') : null}</span>;
                }
            }, {
                key: "received",
                label: "Rec'd",
                type: "text",
                colSpan: 1,
                minWidth: 65,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            }, {
                key: "lastStockReceivedDate",
                label: "Rec'd On",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY')}</span>;
                }
            }, {
                key: "qtyOutstanding",
                label: "Outstanding",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />;
                }
            }, {
                key: "location",
                label: "Location",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span>{value}</span>;
                }
            }
        ];

        return store;
    }

    getStoreStockReceived() {
        let store = [
            {
                key: "dateAdded",
                label: "Date",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>;
                }
            },
            {
                key: "ordNum",
                label: "Ordnum",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link to={"/purchase/order/enquiry?" + queryString.stringify({ordNum: value})}
                                 className="btn btn-sm btn-primary"
                                 title={"Click Here to see the Order Details"}
                                 style={{cursor: "pointer"}}>
                        {value}
                    </Link>;
                }

            },

            {
                key: "supplierCode",
                label: "Supplier Code",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link className="btn btn-primary btn-sm"
                                 to={"/creditor/enquiry?" + queryString.stringify({accountID: value})}>
                        {value}
                    </Link>;
                }
            }, {
                key: "qty",
                label: "Qty",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            }
        ];

        return store;
    }

    handleChange(change, key) {
        let { dataTable } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        switch (key) {
            case "sortKey":
                if (change.sorterApplicable) {
                    if (dataTable.request.sortRequest.key === change.key) {
                        dataTable.request.sortRequest.direction = !dataTable.request.sortRequest.direction;
                    } else {
                        dataTable.request.sortRequest.key = change.key;
                        dataTable.request.sortRequest.direction = false;
                    }
                    searchParams.set("sortRequestKey", change);
                    searchParams.set("sortRequestDirection", dataTable.request.sortRequest.direction);
                    if (!(change === "srNo")) {

                        this.refresh(dataTable);
                    }
                }
                break;
            case "pageSize":
                dataTable.request.pageRequest[key] = change;
                dataTable.request.pageRequest.currentPage = 1;
                searchParams.set("pageSize", change);
                this.refresh(dataTable);
                break;
            case "currentPage":
                dataTable.request.pageRequest[key] = change;
                searchParams.set("currentPage", change);
                this.refresh(dataTable);
                break;
            case "ordNum":
            default:
                dataTable.request.filterRequest[key] = change;
                switch (key) {
                    case 'ordNum':
                        if (change === "") {
                            dataTable.request.filterRequest[key] = null;
                        }
                        break;
                }
                searchParams.set(key, change);
                searchParams.set("currentPage", dataTable.request.pageRequest.currentPage);
                dataTable.request.pageRequest.currentPage = 1;
                this.refresh(dataTable);
        }
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }

    openModal(item) {
        this.setState({isOpenModal: true, selectedOrderData: item})
    }

    toggleModal(data) {
        this.setState({isOpenModal: data});
    }


    render() {
        let {dataTable, loading, stockReceived, loadingExport, pageType} = this.state;
        let {showReceived} = this.props;
        let {pageRequest, sortRequest, filterRequest} = dataTable.request;
        let store = this.getStore();
        let storeStockReceived = this.getStoreStockReceived();
        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true"/>&nbsp;Start Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.startDate}
                                onChange={date => this.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true"/>&nbsp;End Date</Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.endDate}
                                onChange={date => this.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label>PO No</Label>
                        <div className={"text-center"}>
                            <Input type="search" id="ordNum" name="ordNum"
                                   value={filterRequest.ordNum || ''}
                                   onChange={(e) => this.handleChange(e.target.value, "ordNum")}
                                   placeholder="Search...">

                            </Input>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label>ProdCode</Label>
                        <SearchStock
                            handleSelection={(selectedStock) => this.handleChange(selectedStock ? selectedStock.prodCode : "", "prodCode")}
                            selected={filterRequest.prodCode}
                            defaultProdCode={""}
                            filter={{
                                bmFlag: null,
                                discontinued: null,
                                soldOut: null,
                                stockGroupCodes: [],
                                stockMasterGroupCodes: [],
                                colour: "",
                                includeOffRangePart: null,
                                includeFabric: null,
                            }}
                        />
                    </Col>
                    {pageType === "stock" ?
                        <Col xl={3} lg={2} md={4} sm={12} xs={12}>
                            <Label>Supplier</Label>
                            <div style={{zIndex: '2', position: 'relative'}}>
                                <SearchCreditorAcccount
                                    handleAccountChange={(selected) => this.handleChange(selected, "supplierCode")}
                                    defaultAccountID={filterRequest.supplierCode ? filterRequest.supplierCode : ""}
                                    selectedAccountID={filterRequest.supplierCode ? filterRequest.supplierCode : ""}/>
                            </div>
                        </Col>
                        : null}
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <div className={"mt-4"}>
                            <Label className={"mt-1"}>
                                <Button color={"link"}
                                        size={"sm"}
                                        onClick={() => this.handleChange(!filterRequest.outstanding, "outstanding")}>
                                    <i className={classnames("fa", "fa-lg", {
                                            "fa-check-square-o": filterRequest.outstanding,
                                            "fa-square-o": !filterRequest.outstanding,
                                        }
                                    )}/>
                                </Button>
                                Outstanding PO Item</Label>
                        </div>
                    </Col>
                    <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div>
                            <Label>Purchase Order Status</Label>
                            <div>
                                <ButtonGroup>
                                    <Button
                                        size={"sm"}
                                        onClick={() => this.handleChange('In Progress', "orderStatusDescription")}
                                        color={"primary"}

                                        outline={filterRequest.orderStatusDescription !== 'In Progress'}>
                                        In Progress
                                        {filterRequest.orderStatusDescription == 'In Progress'
                                            ? <i className="fa fa-check ml-1"
                                                aria-hidden="true" />
                                            : null
                                        }
                                    </Button>
                                    <Button
                                        size={"sm"}
                                        onClick={() => this.handleChange('Sent', "orderStatusDescription")}
                                        color={"primary"}

                                        outline={filterRequest.orderStatusDescription !== 'Sent'}>
                                        Sent
                                        {filterRequest.orderStatusDescription == 'Sent'
                                            ? <i className="fa fa-check ml-1"
                                                aria-hidden="true" />
                                            : null
                                        }
                                    </Button>
                                    <Button
                                        size={"sm"}
                                        onClick={() => this.handleChange('Completed', "orderStatusDescription")}
                                        color={"primary"}

                                        outline={filterRequest.orderStatusDescription !== 'Completed'}>
                                        Completed
                                        {filterRequest.orderStatusDescription == 'Completed'
                                            ? <i className="fa fa-check ml-1"
                                                aria-hidden="true" />
                                            : null
                                        }
                                    </Button>
                                    <Button
                                        size={"sm"}
                                        onClick={() => this.handleChange('Cancelled', "orderStatusDescription")}
                                        color={"primary"}

                                        outline={filterRequest.orderStatusDescription !== 'Cancelled'}>
                                        Cancelled
                                        {filterRequest.orderStatusDescription == 'Cancelled'
                                            ? <i className="fa fa-check ml-1"
                                                aria-hidden="true" />
                                            : null
                                        }
                                    </Button>
                                    <Button
                                        size={"sm"}
                                        onClick={() => this.handleChange('', "orderStatusDescription")}
                                        color={"primary"}

                                        outline={filterRequest.orderStatusDescription !== ''}>
                                        All
                                        {filterRequest.orderStatusDescription == ''
                                            ? <i className="fa fa-check ml-1"
                                                aria-hidden="true" />
                                            : null
                                        }
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xl={showReceived ? 8 : 12} lg={showReceived ? 8 : 12} md={showReceived ? 8 : 12} sm={12}
                         xs={12}>
                        {loading
                            ? <Spinner color='primary' size='sm'/>
                            :
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p className={"mb-0 mt-2"}>Showing{' '}
                                        {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                        {' '}of {dataTable.response.totalRecords}
                                        {' '}entries
                                    </p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"text-right mb-2"}>
                                    {pageType === "stock" ?
                                        <Button size={"sm"} color={"success"} onClick={this.exportData}
                                                disabled={loadingExport}
                                                className={"ml-2"}>
                                            {
                                                loadingExport ?
                                                    <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                                    : <i className={"fa fa-file-excel-o mr-2"}/>
                                            }
                                            Export</Button>
                                        : null}
                                </Col>
                            </Row>
                        }

                        <Table striped bordered responsive hover size='sm' className={"mb-1"}>
                            <thead>
                            <tr>
                                {(store || []).filter(x => x.showColumn).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{minWidth: item.minWidth}}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true"/> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>

                            {(dataTable.response.records || []).map((record, i) => {
                                return (
                                    <tr key={i}>
                                        {(store || []).filter(x => x.showColumn).map((storeItem, index) => {
                                            return (
                                                <td key={index} className={storeItem.valueClassName}>
                                                    {storeItem.render(record[storeItem.key], record, i, dataTable.response.records, this)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {
                                ((dataTable.response.records || []).length === 0)
                                    ? <tr>
                                        <td colSpan={13}>No orders found...</td>
                                    </tr>
                                    : null
                            }
                            </tbody>
                        </Table>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={dataTable.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination mb-0'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </Col>
                    {
                        showReceived
                            ? <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                <h6 className={"mb-1 mt-3"}>Stock Received</h6>
                                <Table striped bordered responsive hover size='sm' className={"mb-1"}>
                                    <thead>
                                    <tr>
                                        {(storeStockReceived || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{minWidth: item.minWidth}}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                            )} aria-hidden="true"/> : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {(stockReceived.response.records || []).map((record, i) => {
                                        return (
                                            <tr key={i}>
                                                {(storeStockReceived || []).map((storeItem, index) => {
                                                    return (
                                                        <td key={index} className={storeItem.valueClassName}>
                                                            {storeItem.render(record[storeItem.key], record, i, dataTable.response.records, this)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                    {
                                        ((stockReceived.response.records || []).length === 0)
                                            ? <tr>
                                                <td colSpan={12}>No orders found...</td>
                                            </tr>
                                            : null
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                            : null

                    }


                </Row>
            </div>
        );
    }
}