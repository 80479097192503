import React from 'react';

import {isEmpty, cloneDeep} from 'lodash';
import {findIndex, findItemHavingMaxValue, round} from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_WIDESCREEN_PRODCODE} from "../../../../../../store/AppConstants";

const keyway80mmProdCode = "12609";
const keyway105mmProdCode = "12608";

class WidescreenUtil {

    static Instance() {
        return new WidescreenUtil()
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "operation":
                if (isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Manual only allow up to 4000mm wide/3000mm drop. Anything over 4000mm width /3000mm drop needed to be motorised",
                    };
                }
                product.items[itemIndex].configuration.operation.formError = formError;
                break;
            case "fabricColourOffRange":
                if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.fabricColourOffRange.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Fabric name can't be empty",
                    };
                }
                product.items[itemIndex].configuration.fabricColourOffRange.formError = formError;
                break;
            case "frameColour":
                if (product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.framePowdercoatColour.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Frame powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.framePowdercoatColour.formError = formError;
                break;
            case "headboxColourPowdercoat":
                if (product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isSelectable && (!product.items[itemIndex].configuration.headboxColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Headbox powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.headboxColourPowdercoat.formError = formError;
                break;
            case "channelColourPowdercoat":
                if (product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable && (!product.items[itemIndex].configuration.channelColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Channel powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.channelColourPowdercoat.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.fabricColourOffRange.formError.isValid
            && product.items[itemIndex].configuration.framePowdercoatColour.formError.isValid
            && product.items[itemIndex].configuration.headboxColourPowdercoat.formError.isValid
            && product.items[itemIndex].configuration.channelColourPowdercoat.formError.isValid;
    }


    updateRules(key, product, itemIndex, order) {
        let optionIndex, isMotorised;
        isMotorised = this.isMotorised(product, itemIndex, order);

        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Cable":
                        if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                            switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                                case "Manual":
                                    product.items[itemIndex].configuration.width.min = 800;
                                    product.items[itemIndex].configuration.width.max = 4000;
                                    break;
                                case "Windoware RTS A/C Motor":
                                case "Windoware 4-Core A/C Motor":
                                case "Windoware DC Solar Motor":
                                case "Somfy RTS Altus Motor":
                                case "Somfy RTSMotor - Star Head":
                                    product.items[itemIndex].configuration.width.min = 800;
                                    product.items[itemIndex].configuration.width.max = 6500;
                                    break;
                            }
                        }
                        break;
                    case "Zip":
                        if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                            switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                                case "Manual":
                                    product.items[itemIndex].configuration.width.min = 800;
                                    product.items[itemIndex].configuration.width.max = 4000;
                                    break;
                                case "Windoware RTS A/C Motor":
                                case "Windoware 4-Core A/C Motor":
                                case "Windoware DC Solar Motor":
                                case "Somfy RTS Altus Motor":
                                case "Somfy RTSMotor - Star Head":
                                    product.items[itemIndex].configuration.width.min = 800;
                                    product.items[itemIndex].configuration.width.max = 6500;
                                    break;
                            }
                        }
                        break;
                    default:
                        break;
                }

                break;
            case "drop":
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Manual":
                            product.items[itemIndex].configuration.drop.min = 900;
                            product.items[itemIndex].configuration.drop.max = 3000;
                            break;
                        case "Windoware RTS A/C Motor":
                        case "Windoware 4-Core A/C Motor":
                        case "Windoware DC Solar Motor":
                        case "Somfy RTS Altus Motor":
                        case "Somfy RTSMotor - Star Head":
                            product.items[itemIndex].configuration.drop.min = 900;
                            product.items[itemIndex].configuration.drop.max = 3400;
                            break;
                        default:
                            break;
                    }
                }

                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = product.items[itemIndex].configuration.fabricType.selected.value.optionKey === "Off Range";
                product.items[itemIndex].configuration.fabricColour.selected.isSelectable = !product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable;
                break;
            case "frameColour":
                product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.frameColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.frameColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
                    }
                }
                break;
            case "headboxColourPowdercoat":
                product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.headboxColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.headboxColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.headboxColourPowdercoat.selected.value = "";
                    }
                }
                break;

            case "crankGear":
                product.items[itemIndex].configuration.crankGear.selected.isSelectable = !isMotorised;
                if (product.items[itemIndex].configuration.crankGear.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].configuration.crankGear.selected.value)) {
                        optionIndex = findIndex(product.items[itemIndex].configuration.crankGear.finalOptions, "optionKey", product.items[itemIndex].configuration.crankGear.selected.value.optionKey);
                    }
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.crankGear.selected.value = product.items[itemIndex].configuration.crankGear.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.crankGear.selected.dropdownValue = product.items[itemIndex].configuration.crankGear.selected.value.optionKey;

                } else {
                    product.items[itemIndex].configuration.crankGear.selected.value = null;
                    product.items[itemIndex].configuration.crankGear.selected.dropdownValue = "";
                }
                break;
            case "crankHandle":
                // disable if motorised
                if (isMotorised) {
                    product.items[itemIndex].configuration.crankHandle.selected.isSelectable = false;
                    product.items[itemIndex].configuration.crankHandle.selected.value = {};
                    product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = "";
                } else {
                    product.items[itemIndex].configuration.crankHandle.selected.isSelectable = true;
                    optionIndex = 0;
                    if (isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                        product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    }
                }
                break;
            case "motorAccessories":
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
                product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
                product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;

                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Manual":
                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                            product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
                            product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
                            product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                            break;
                        case "Windoware RTS A/C Motor":
                        case "Windoware 4-Core A/C Motor":
                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = true;
                            product.items[itemIndex].configuration.windowareBridge.selected.isVisible = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                            break;
                        case "Windoware DC Solar Motor":
                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = true;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = true;
                            product.items[itemIndex].configuration.windowareBridge.selected.isVisible = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isVisible = true;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                            break;
                        case "Somfy RTS Altus Motor":
                        case "Somfy RTSMotor - Star Head":
                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                            product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = true;

                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
                            product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
                            product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                            product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
                            product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
                            product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                            product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                    }
                }
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable ? product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value : false;
                product.items[itemIndex].configuration.windowareBridge.selected.value = product.items[itemIndex].configuration.windowareBridge.selected.isSelectable ? product.items[itemIndex].configuration.windowareBridge.selected.value : false;
                product.items[itemIndex].configuration.chargingCable.selected.value = product.items[itemIndex].configuration.chargingCable.selected.isSelectable ? product.items[itemIndex].configuration.chargingCable.selected.value : false;
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable ? product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value : false;
                break;
            case "headboxAccessories":
                switch (product.items[itemIndex].configuration.headbox.selected.value.optionKey) {
                    case "Open":
                        product.items[itemIndex].configuration.headboxSize.selected.isSelectable = false;
                        product.items[itemIndex].configuration.headboxSize.selected.isVisible = false;
                        product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isVisible = false;

                        product.items[itemIndex].configuration.headboxColour.selected.isSelectable = false;
                        product.items[itemIndex].configuration.headboxColour.selected.isVisible = false;
                        product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isVisible = false;
                        break;
                    case "Headbox":
                        product.items[itemIndex].configuration.headboxSize.selected.isSelectable = true;
                        product.items[itemIndex].configuration.headboxSize.selected.isVisible = true;
                        product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isVisible = true;

                        product.items[itemIndex].configuration.headboxColour.selected.isSelectable = true;
                        product.items[itemIndex].configuration.headboxColour.selected.isVisible = true;
                        product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isVisible = true;
                        break;
                }
                product.items[itemIndex].configuration.headboxSize.selected.value = product.items[itemIndex].configuration.headboxSize.selected.isSelectable ? product.items[itemIndex].configuration.headboxSize.selected.value : {};
                product.items[itemIndex].configuration.headboxColour.selected.value = product.items[itemIndex].configuration.headboxColour.selected.isSelectable ? product.items[itemIndex].configuration.headboxColour.selected.value : {};
                product.items[itemIndex].configuration.headboxColourPowdercoat.selected.value = product.items[itemIndex].configuration.headboxColourPowdercoat.selected.isSelectable ? product.items[itemIndex].configuration.headboxColourPowdercoat.selected.value : "";

                product.items[itemIndex].configuration.headboxSize.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.headboxSize.selected.value) ? "" : product.items[itemIndex].configuration.headboxSize.selected.value.optionKey;
                product.items[itemIndex].configuration.headboxColour.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.headboxColour.selected.value) ? "" : product.items[itemIndex].configuration.headboxColour.selected.value.optionKey;
                break;
            case "bracketCover":
                switch (product.items[itemIndex].configuration.headbox.selected.value.optionKey) {
                    case "Open":
                        product.items[itemIndex].configuration.bracketCover.selected.isSelectable = true;
                        product.items[itemIndex].configuration.bracketCover.selected.isVisible = true;
                        break;
                    case "Headbox":
                        product.items[itemIndex].configuration.bracketCover.selected.isSelectable = false;
                        product.items[itemIndex].configuration.bracketCover.selected.isVisible = false;

                        break;
                }
                product.items[itemIndex].configuration.bracketCover.selected.value = product.items[itemIndex].configuration.bracketCover.selected.isSelectable ? product.items[itemIndex].configuration.bracketCover.selected.value : false;
                break;
            case "headboxEndCapCover":
                switch (product.items[itemIndex].configuration.headbox.selected.value.optionKey) {
                    case "Open":
                        product.items[itemIndex].configuration.headboxEndCapCover.selected.isSelectable = false;
                        product.items[itemIndex].configuration.headboxEndCapCover.selected.isVisible = false;
                        break;
                    case "Headbox":
                        switch (product.items[itemIndex].configuration.headboxSize.selected.value.optionKey) {
                            case "115mm":
                                product.items[itemIndex].configuration.headboxEndCapCover.selected.isSelectable = true;
                                product.items[itemIndex].configuration.headboxEndCapCover.selected.isVisible = true;
                                break;
                            case "150mm":
                                product.items[itemIndex].configuration.headboxEndCapCover.selected.isSelectable = false;
                                product.items[itemIndex].configuration.headboxEndCapCover.selected.isVisible = false;
                        }
                        break;
                }
                product.items[itemIndex].configuration.headboxEndCapCover.selected.value = product.items[itemIndex].configuration.headboxEndCapCover.selected.isSelectable ? product.items[itemIndex].configuration.headboxEndCapCover.selected.value : false;
                break;
            case "holdDownKit":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Cable":
                        product.items[itemIndex].configuration.holdDownKit.selected.isSelectable = true;
                        product.items[itemIndex].configuration.holdDownKit.selected.isVisible = true;
                        break;
                    case "Zip":
                        product.items[itemIndex].configuration.holdDownKit.selected.isSelectable = false;
                        product.items[itemIndex].configuration.holdDownKit.selected.isVisible = false;
                        break;
                }
                product.items[itemIndex].configuration.holdDownKit.selected.value = product.items[itemIndex].configuration.holdDownKit.selected.isSelectable ? product.items[itemIndex].configuration.holdDownKit.selected.value : false;
                break;
            case "channelColour":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Cable":
                        product.items[itemIndex].configuration.channelColour.selected.isSelectable = false;
                        product.items[itemIndex].configuration.channelColour.selected.isVisible = false;
                        break;
                    case "Zip":
                        product.items[itemIndex].configuration.channelColour.selected.isSelectable = true;
                        product.items[itemIndex].configuration.channelColour.selected.isVisible = true;
                        break;
                }
                product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.selected.isSelectable ? product.items[itemIndex].configuration.channelColour.selected.value : null;
                product.items[itemIndex].configuration.channelColour.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.channelColour.selected.value) ? "" : product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                break;
            case "channelColourPowdercoat":
                product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.channelColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.channelColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = "";
                    }
                }
                break;
            default:
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let setIndex, stocks, label, attribute,
            width, drop, comment,
            deduction, optionIndex, condition1, condition2, condition3, sets, isMotorised;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        isMotorised = this.isMotorised(product, itemIndex, order);
        switch (key) {
            case "set":
                attribute = "SET";
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: PRODUCT_BUILDER_WIDESCREEN_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "operation":
                attribute = "Operation";//Need to change the operation exact value with options.
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    label = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.operation.selected.value.stocks;
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                }
                product.items[itemIndex].configuration.operation.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [{
                    id: null,
                    description: "",
                    prodCode: isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? "09770" : product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                    keywayQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                    wastageFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1000,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true
                }];

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "consumable":
                attribute = "Consumables";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.consumable.selected.stocks = [];
                product.items[itemIndex].configuration.consumable.finalOptions.forEach(o => {
                    label = o.optionKey;
                    stocks = [];
                    switch (o.optionKey) {
                        case"Standard Operation":
                            condition1 = isMotorised ? "Motorised" : "Manual";
                            condition2 = product.items[itemIndex].configuration.headbox.selected.value.optionKey;
                            condition3 = isEmpty(product.items[itemIndex].configuration.crankGear.selected.value) ? "" : product.items[itemIndex].configuration.crankGear.selected.value.optionKey;
                            sets = o.sets.filter(s => s.condition1.includes(condition1) && s.condition2.includes(condition2) && s.condition3.includes(condition3));
                            break;
                        case "Spline":
                            sets = o.sets;
                            break;
                    }
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });

                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                    product.items[itemIndex].configuration.consumable.selected.stocks = [...product.items[itemIndex].configuration.consumable.selected.stocks, ...stocks];
                });
                break;
            case "keyway":
                attribute = "Keyway";
                label = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
                comment = "";
                stocks = product.items[itemIndex].configuration.keyway.selected.value.stocks;
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.keyway.selected.stocks = stocks;
                break;
            case "headbox":
                attribute = "Headbox";
                label = product.items[itemIndex].configuration.headbox.selected.value.optionKey;
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.headbox.selected.value)) {
                    sets = product.items[itemIndex].configuration.headbox.selected.value.sets.filter(set => (set.condition1 === product.items[itemIndex].configuration.model.selected.value.optionKey) && (width >= set.min) && (width <= set.max));
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.headbox.selected.stocks = stocks;
                break;
            case "headboxSize":
                attribute = "Headbox Size";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.headboxSize.selected.value)) {
                    label = product.items[itemIndex].configuration.headboxSize.selected.value.optionKey;
                    comment = product.items[itemIndex].configuration.headboxColour.selected.value.optionKey;
                    sets = product.items[itemIndex].configuration.headboxSize.selected.value.sets.filter(s => s.condition1 === product.items[itemIndex].configuration.headboxColour.selected.value.optionKey);
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });

                    if (product.items[itemIndex].configuration.headboxSize.selected.value.optionKey !== "115mm") {
                        if (product.items[itemIndex].configuration.headboxSize.finalOptions.some(o => o.optionKey === "115mm")) {
                            // it is an upgrade
                            optionIndex = product.items[itemIndex].configuration.headboxSize.options.findIndex(o => o.condition1 === "Upgrade" && o.optionKey === "150mm Upgrade");
                            if (optionIndex > -1) {
                                sets = product.items[itemIndex].configuration.headboxSize.options[optionIndex].sets;
                                sets.forEach(s => {
                                    stocks = [...stocks, ...s.stocks];
                                });
                            }
                        }
                    }
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                }
                product.items[itemIndex].configuration.headboxSize.selected.stocks = stocks;
                break;
            case "bracketCover":
                attribute = "Bracket Cover";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.bracketCover.selected.value) {
                    product.items[itemIndex].configuration.bracketCover.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    })
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bracketCover.selected.stocks = stocks;
                break;
            case "headboxEndCapCover":
                attribute = "End Cap Cover";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.headboxEndCapCover.selected.value) {
                    product.items[itemIndex].configuration.headboxEndCapCover.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    })
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.headboxEndCapCover.selected.stocks = stocks;
                break;
            case "track":
                attribute = "Track";
                label = product.items[itemIndex].configuration.track.selected.value.optionKey;
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.track.selected.innerChannel.deduction = this.initDeduction();
                product.items[itemIndex].configuration.track.selected.innerCover.deduction = this.initDeduction();

                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Cable":
                        sets = product.items[itemIndex].configuration.track.selected.value.sets;
                        break;
                    case "Zip":
                        sets = product.items[itemIndex].configuration.track.selected.value.sets.filter(set => set.condition1.includes(product.items[itemIndex].configuration.channelColour.selected.value.optionKey));
                        break;
                }
                sets.forEach(set => {
                    stocks = [...stocks, ...set.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.track.selected.stocks = stocks;
                break;
            case "outerChannel":
                attribute = "Outer Channel";
                label = isEmpty(product.items[itemIndex].configuration.outerChannel.selected.value) ? "" : product.items[itemIndex].configuration.outerChannel.selected.value.optionKey;
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.outerChannel.selected.outerUChannel.deduction = this.initDeduction();
                product.items[itemIndex].configuration.outerChannel.selected.outerLChannel.deduction = this.initDeduction();
                if (!isEmpty(product.items[itemIndex].configuration.outerChannel.selected.value)) {
                    sets = product.items[itemIndex].configuration.outerChannel.selected.value.sets.filter(set => set.condition1.includes(product.items[itemIndex].configuration.channelColour.selected.value.optionKey));
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.outerChannel.selected.stocks = stocks;
                break;
            case "holdDownKit":
                attribute = "Hold Down Kit";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.holdDownKit.selected.value) {
                    sets = product.items[itemIndex].configuration.holdDownKit.finalOptions.filter(o => o.condition1.includes(product.items[itemIndex].configuration.track.selected.value.optionKey));
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.holdDownKit.selected.stocks = stocks;
                break;
            case "baseRail":
                attribute = "Base Rail";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.baseRail.finalOptions.forEach(o => {
                    sets = o.sets.filter(set => set.condition1.includes(product.items[itemIndex].configuration.frameColour.selected.value.optionKey) && set.condition2.includes(product.items[itemIndex].configuration.model.selected.value.optionKey) && (width >= set.min) && (width <= set.max));
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.baseRail.selected.stocks = stocks;
                break;
            case "baseRailEndCap":
                attribute = "Base Rail EndCap";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.baseRailEndCap.selected.value)) {
                    stocks = product.items[itemIndex].configuration.baseRailEndCap.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.baseRailEndCap.selected.stocks = stocks;
                break;
            case "baseRailSeal":
                attribute = "Base Rail Seal";
                label = product.items[itemIndex].configuration.baseRailSeal.selected.value.optionKey;
                comment = "";
                stocks = [];
                sets = product.items[itemIndex].configuration.baseRailSeal.selected.value.sets;
                sets.forEach(set => {
                    stocks = [...stocks, ...set.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.baseRailSeal.selected.stocks = stocks;
                break;

            case "crankHandle":
                attribute = "Crank Handle";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                    label = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.crankHandle.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.crankHandle.selected.stocks = stocks;
                break;
            case "windoware15ChannelRemote":
                attribute = "Windoware 15 Channel Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.windoware15ChannelRemote.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks = stocks;
                break;
            case "windowareBridge":
                attribute = "Windoware Bridge";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.windowareBridge.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.windowareBridge.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windowareBridge.selected.stocks = stocks;
                break;
            case "chargingCable":
                attribute = "Charging Cable";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.chargingCable.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.chargingCable.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.chargingCable.selected.stocks = stocks;
                break;
            case "somfy1ChannelSituoRemote":
                attribute = "Somfy 1 Channel Situo Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, order);
            product = this.updateStocks("consumable", product, itemIndex, order);
            product = this.updateStocks("keyway", product, itemIndex, order);
            product = this.updateStocks("headbox", product, itemIndex, order);
            product = this.updateStocks("headboxSize", product, itemIndex, order);
            product = this.updateStocks("bracketCover", product, itemIndex, order);
            product = this.updateStocks("headboxEndCapCover", product, itemIndex, order);
            product = this.updateStocks("track", product, itemIndex, order);
            product = this.updateStocks("holdDownKit", product, itemIndex, order);
            product = this.updateStocks("outerChannel", product, itemIndex, order);
            product = this.updateStocks("baseRail", product, itemIndex, order);
            product = this.updateStocks("baseRailEndCap", product, itemIndex, order);
            product = this.updateStocks("baseRailSeal", product, itemIndex, order);
            product = this.updateStocks("crankHandle", product, itemIndex, order);
            product = this.updateStocks("windoware15ChannelRemote", product, itemIndex, order);
            product = this.updateStocks("windowareBridge", product, itemIndex, order);
            product = this.updateStocks("chargingCable", product, itemIndex, order);
            product = this.updateStocks("somfy1ChannelSituoRemote", product, itemIndex, order);
        }
        return product;
    }


    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.keyway.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.headbox.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.headbox.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.headboxSize.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.headboxSize.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bracketCover.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bracketCover.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.headboxEndCapCover.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.headboxEndCapCover.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.track.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.track.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.holdDownKit.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.holdDownKit.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.outerChannel.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.outerChannel.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.baseRail.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.baseRail.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.baseRailEndCap.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.baseRailEndCap.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.baseRailSeal.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.baseRailSeal.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.crankHandle.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.windowareBridge.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.windowareBridge.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.chargingCable.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.chargingCable.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.consumable.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.consumable.selected.stocks];
            }


            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor
        };


        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                /*
                * width(mm) drop(mm) fabricWidth(m)
                *
                * PANEL >> panel
                * FABRIC_DROP(M) >> fabricDrop
                * USAGE(LM) >> usage
                * */
                let panel = 0, fabricDrop = 0, usage = 0;

                //Calculating PANEL
                try {
                    panel = Math.ceil((width / 1000) / fabricWidth);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }

                //Calculating FABRIC_DROP
                fabricDrop = (drop + 400) / 1000;

                //Calculating FABRIC_DROP
                if (fabricDrop > fabricWidth) {
                    usage = round((panel * fabricDrop), 1);
                } else {
                    usage = width / 1000;
                }
                deduction = this.updateDeduction("fabric", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Operation":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Keyway":
                deduction = this.updateDeduction("keyway", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
            case "Headbox Size":
                deduction = this.updateDeduction("headbox", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.headbox.selected.deduction = deduction;
                break;
            case "Base Rail":
            case "Base Rail EndCap":
            case "Base Rail Seal":
                deduction = this.updateDeduction("baseRail", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.baseRail.selected.deduction = deduction;
                break;
            case "Track":
                deduction = this.updateDeduction("Track", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
            case "Outer Channel":
                deduction = this.updateDeduction("outerChannel", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
            case "Charging Cable":
            default:
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
        }
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }

    updateDeduction(key, product, itemIndex, stockInventoryItem) {
        let deduction = this.initDeduction();
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let model = product.items[itemIndex].configuration.model.selected.value.optionKey;
        let headbox = product.items[itemIndex].configuration.headbox.selected.value.optionKey;
        let keyway = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
        let isFaceFit = product.items[itemIndex].configuration.track.selected.value.optionKey.includes("Face");
        switch (key) {
            case "fabric":
                switch (model) {
                    case "Cable":
                        switch (headbox) {
                            case "Open":
                                deduction.deductionWidth = -90;
                                deduction.deductionDrop = 400;
                                break;
                            case "Headbox":
                                deduction.deductionWidth = isFaceFit ? -90 : -87;
                                deduction.deductionDrop = isFaceFit ? 400 : 100;
                                break;
                        }
                        break;
                    case "Zip":
                        switch (headbox) {
                            case "Open":
                                deduction.deductionWidth = isFaceFit ? -72 : -87;
                                deduction.deductionDrop = isFaceFit ? 400 : 400;
                                break;
                            case "Headbox":
                                deduction.deductionWidth = isFaceFit ? -73 : -87;
                                deduction.deductionDrop = isFaceFit ? 100 : 100;
                                break;
                        }
                        break;
                }

                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "keyway":
                switch (model) {
                    case "Cable":
                        switch (headbox) {
                            case "Open":
                            case "Headbox":
                                switch (keyway) {
                                    case "80mm":
                                        deduction.deductionWidth = -85;
                                        deduction.cutWidth = width + (deduction.deductionWidth);
                                        product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                                        break;
                                    case "105mm":
                                        switch (stockInventoryItem.prodCode) {
                                            case keyway80mmProdCode:
                                                //N/A
                                                break;
                                            case keyway105mmProdCode:
                                                deduction.deductionWidth = -85;
                                                deduction.cutWidth = width + (deduction.deductionWidth);
                                                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                                                break;
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                    case "Zip":
                        switch (headbox) {
                            case "Open":
                                switch (keyway) {
                                    case "80mm":
                                        //N/A
                                        break;
                                    case "105mm":
                                        switch (stockInventoryItem.prodCode) {
                                            case keyway80mmProdCode:
                                                //N/A
                                                break;
                                            case keyway105mmProdCode:
                                                deduction.deductionWidth = isFaceFit ? -140 : -140;
                                                deduction.cutWidth = width + (deduction.deductionWidth);
                                                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case "Headbox":
                                switch (keyway) {
                                    case "80mm":
                                        //N/A
                                        break;
                                    case "105mm":
                                        switch (stockInventoryItem.prodCode) {
                                            case keyway80mmProdCode:
                                                //N/A
                                                break;
                                            case keyway105mmProdCode:
                                                deduction.deductionWidth = isFaceFit ? -145 : -145;
                                                deduction.cutWidth = width + (deduction.deductionWidth);
                                                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                                                break;
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                }
                break;
            case "headbox":
                deduction.deductionWidth = -12;
                deduction.cutWidth = width + (deduction.deductionWidth);
                break;
            case "baseRail":
                switch (model) {
                    case "Cable":
                        switch (headbox) {
                            case "Open":
                            case "Headbox":
                                deduction.deductionWidth = -80;
                                break;
                        }
                        break;
                    case "Zip":
                        switch (headbox) {
                            case "Open":
                            case "Headbox":
                                deduction.deductionWidth = isFaceFit ? -113 : -128;
                                break;
                        }
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                break;
            case "Track":
                //innerChannel and innerCover
                switch (model) {
                    case "Cable":
                        //N/A
                        break;
                    case "Zip":
                        switch (headbox) {
                            case "Open":
                                deduction.deductionDrop = -162;
                                break;
                            case "Headbox":
                                deduction.deductionDrop = -140;
                                break;
                        }
                        deduction.cutDrop = drop + (deduction.deductionDrop);
                        break;
                }
                product.items[itemIndex].configuration.track.selected.innerChannel.deduction = deduction;
                product.items[itemIndex].configuration.track.selected.innerCover.deduction = deduction;

                break;
            case "outerChannel":
                product.items[itemIndex].configuration.outerChannel.selected.outerUChannel.deduction = this.initDeduction();
                product.items[itemIndex].configuration.outerChannel.selected.outerLChannel.deduction = this.initDeduction();

                switch (product.items[itemIndex].configuration.outerChannel.selected.value.optionKey) {
                    case "Adjustment Channel":
                        if (model === "Zip") {
                            switch (headbox) {
                                case "Open":
                                    deduction.deductionDrop = isFaceFit ? 0 : -152;
                                    break;
                                case "Headbox":
                                    deduction.deductionDrop = isFaceFit ? 0 : -117;
                                    break;
                            }
                            deduction.cutDrop = drop + (deduction.deductionDrop);
                        }
                        product.items[itemIndex].configuration.outerChannel.selected.outerUChannel.deduction = deduction;
                        break;
                    case "40 x 40 L Channel":
                        if (model === "Zip") {
                            switch (headbox) {
                                case "Open":
                                    deduction.deductionDrop = isFaceFit ? 0 : -152;
                                    break;
                                case "Headbox":
                                    deduction.deductionDrop = isFaceFit ? 0 : -117;
                                    break;
                            }
                            deduction.cutDrop = drop + (deduction.deductionDrop);
                        }
                        product.items[itemIndex].configuration.outerChannel.selected.outerLChannel.deduction = deduction;
                        break;
                }
        }
        return deduction;
    }


    isMotorised(product, itemIndex, order) {
        let isMotorised = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation)) {
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                isMotorised = product.items[itemIndex].configuration.operation.selected.value.optionKey.includes("Motor");
            }
        }
        return isMotorised;
    }

    isVisibleMotorAccessories(key, product, itemIndex) {
        let result = false;
        switch (key) {
            case "Windoware":
                result = product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible
                    || product.items[itemIndex].configuration.windowareBridge.selected.isVisible
                    || product.items[itemIndex].configuration.chargingCable.selected.isVisible
                ;
                break;
            case "Somfy":
                result = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible;
                break;
            default:
                result = false;
        }
        return result;
    }


    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.operation.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.operation.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fabricColourOffRange.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fabricColourOffRange.formError.message);
        }
        if (!pg.items[itemIndex].configuration.framePowdercoatColour.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.framePowdercoatColour.formError.message);
        }
        if (!pg.items[itemIndex].configuration.headboxColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.headboxColourPowdercoat.formError.message);
        }
        if (!pg.items[itemIndex].configuration.channelColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.channelColourPowdercoat.formError.message);
        }
        return errors;
    }

    toSalesOrderItemWidescreenRequest(pg, itemIndex, order) {
        let defaultDeduction = this.initDeduction();
        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.keyway.selected.deduction)) {
            pg.items[itemIndex].configuration.keyway.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.headbox.selected.deduction)) {
            pg.items[itemIndex].configuration.headbox.selected.deduction = defaultDeduction;
        }

        let salesOrderItemWidescreen = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: isEmpty(pg.items[itemIndex].configuration.set.selected.value.priceMatrix) ? "" : pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: isEmpty(pg.items[itemIndex].configuration.set.selected.value.widthSet) ? "" : pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: isEmpty(pg.items[itemIndex].configuration.set.selected.value.dropSet) ? "" : pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: pg.items[itemIndex].configuration.location.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: pg.items[itemIndex].configuration.width.selected.value,
            drop: pg.items[itemIndex].configuration.drop.selected.value,
            model: pg.items[itemIndex].configuration.model.selected.value.optionKey,
            operation: pg.items[itemIndex].configuration.operation.selected.value.optionKey,
            crankGear: isEmpty(pg.items[itemIndex].configuration.crankGear.selected.value) ? "" : pg.items[itemIndex].configuration.crankGear.selected.value.optionKey,
            controlSide: pg.items[itemIndex].configuration.controlSide.selected.value.optionKey,
            keyway: pg.items[itemIndex].configuration.keyway.selected.value.optionKey,

            fabricType: pg.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.value) ? "" : pg.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            fabricColourOffRange: pg.items[itemIndex].configuration.fabricColourOffRange.selected.value,
            frameColour: pg.items[itemIndex].configuration.frameColour.selected.value.optionKey,
            framePowdercoatColour: pg.items[itemIndex].configuration.framePowdercoatColour.selected.value,

            headbox: pg.items[itemIndex].configuration.headbox.selected.value.optionKey,
            headboxSize: isEmpty(pg.items[itemIndex].configuration.headboxSize.selected.value) ? "" : pg.items[itemIndex].configuration.headboxSize.selected.value.optionKey,
            headboxColour: isEmpty(pg.items[itemIndex].configuration.headboxColour.selected.value) ? "" : pg.items[itemIndex].configuration.headboxColour.selected.value.optionKey,
            headboxColourPowdercoat: pg.items[itemIndex].configuration.headboxColourPowdercoat.selected.value,

            channelColour: isEmpty(pg.items[itemIndex].configuration.channelColour.selected.value) ? "" : pg.items[itemIndex].configuration.channelColour.selected.value.optionKey,
            channelColourPowdercoat: pg.items[itemIndex].configuration.channelColourPowdercoat.selected.value,

            track: pg.items[itemIndex].configuration.track.selected.value.optionKey,
            outerChannel: isEmpty(pg.items[itemIndex].configuration.outerChannel.selected.value) ? "" : pg.items[itemIndex].configuration.outerChannel.selected.value.optionKey,
            bracketCover: pg.items[itemIndex].configuration.bracketCover.selected.value,
            headboxEndCapCover: pg.items[itemIndex].configuration.headboxEndCapCover.selected.value,
            holdDownKit: pg.items[itemIndex].configuration.holdDownKit.selected.value,
            baseRailEndCap: isEmpty(pg.items[itemIndex].configuration.baseRailEndCap.selected.value) ? "" : pg.items[itemIndex].configuration.baseRailEndCap.selected.value.optionKey,
            baseRailSeal: isEmpty(pg.items[itemIndex].configuration.baseRailSeal.selected.value) ? "" : pg.items[itemIndex].configuration.baseRailSeal.selected.value.optionKey,
            crankHandle: pg.items[itemIndex].configuration.crankHandle.selected.value.optionKey,
            windoware15ChannelRemote: pg.items[itemIndex].configuration.windoware15ChannelRemote.selected.value,
            windowareBridge: pg.items[itemIndex].configuration.windowareBridge.selected.value,
            chargingCable: pg.items[itemIndex].configuration.chargingCable.selected.value,
            somfy1ChannelSituoRemote: pg.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value,

            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,
            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,

            keywayDeductionWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.deductionWidth,
            keywayCutWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.cutWidth,

            headboxDeductionWidth: pg.items[itemIndex].configuration.headbox.selected.deduction.deductionWidth,
            headboxCutWidth: pg.items[itemIndex].configuration.headbox.selected.deduction.cutWidth,

            baseRailDeductionWidth: pg.items[itemIndex].configuration.baseRail.selected.deduction.deductionWidth,
            baseRailCutWidth: pg.items[itemIndex].configuration.baseRail.selected.deduction.cutWidth,

            innerChannelDeductionDrop: pg.items[itemIndex].configuration.track.selected.innerChannel.deduction.deductionDrop,
            innerChannelCutDrop: pg.items[itemIndex].configuration.track.selected.innerChannel.deduction.cutDrop,

            innerCoverDeductionDrop: pg.items[itemIndex].configuration.track.selected.innerCover.deduction.deductionDrop,
            innerCoverCutDrop: pg.items[itemIndex].configuration.track.selected.innerCover.deduction.cutDrop,

            outerUChannelDeductionDrop: pg.items[itemIndex].configuration.outerChannel.selected.outerUChannel.deduction.deductionDrop,
            outerUChannelCutDrop: pg.items[itemIndex].configuration.outerChannel.selected.outerUChannel.deduction.cutDrop,

            outerLChannelDeductionDrop: pg.items[itemIndex].configuration.outerChannel.selected.outerLChannel.deduction.deductionDrop,
            outerLChannelCutDrop: pg.items[itemIndex].configuration.outerChannel.selected.outerLChannel.deduction.cutDrop,

            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemWidescreen;
    }
}

export default WidescreenUtil.Instance();