import React, {Component} from 'react';
import {Card, CardBody, Row, Col, CardHeader, Table, Spinner} from 'reactstrap';
import CRMService from "../../services/crm/CRMService";
import CustomerService from "../../services/CustomerService";
import NumberFormat from "react-number-format";
import ProductDiscountService from '../../services/product/ProductDiscountService';
import DebtorOutstandingBalance from '../../components/dashboard/CustomerDashboard/DebtorOutstandingBalance';

import {ToastContainer, toast} from 'react-toastify';
import {handleErrorMessage} from "../../services/CommonService";

export default class AccountOverviewCustomerCredit extends Component {
    constructor(props) {
        super(props);
        const previousYears = ((new Date()).getFullYear()) - 20;
        this.state = {
            previousYears: previousYears,
            Year_months_arr: [],
            selectedYear: {'label': (new Date()).getFullYear(), 'value': (new Date()).getFullYear()},
            accountId: this.props.accountId,
            credit_Limit: 0.00,
            DateAdded: '',
            credit_Terms: '',
            discountData: [],
            openCloseOtherEmailModal: false,
            PrintStatement: false,
            EmailStatement: false,
            EmailInvoice: false,
            PrintInvoice: false,
            bubbleHeaderPrintInvoice: 'Print Invoice',
            bubbleHeaderPrintStatement: 'Print Statement',
            paymentTerms: '',
            selectedMonth: {
                label: "May",
                value: "5"
            },
            SalesbyMonth: {
                Jan: 0.00,
                Feb: 0.00,
                Mar: 0.00,
                Apr: 0.00,
                May: 0.00,
                Jun: 0.00,
                Jul: 0.00,
                Aug: 0.00,
                Sept: 0.00,
                Oct: 0.00,
                Nov: 0.00,
                Dec: 0.00
            },
            TradingTerms: '',
            specialReq: '',
            debtorEmail: '',
            setPopupText: '',
            loadingPurchasesByMonth: false,
            loadingCustomerInformation: false
        };
        this.crmService = new CRMService();
        this.customerService = new CustomerService();
        this.productDiscountService = new ProductDiscountService();

        this.convertToDecimalplaces = this.convertToDecimalplaces.bind(this);
        this.GetTradingAndSalesInfo = this.GetTradingAndSalesInfo.bind(this);
        this.getCustomerInformation = this.getCustomerInformation.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({accountId: nextProps.accountId}, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        if (this.state.accountId) {
            this.GetTradingAndSalesInfo(this.state.accountId);
            this.getCustomerInformation(this.state.accountId);
            // this.getDiscount(this.state.accountId);
            //this.getDebtorEmail(this.state.accountId);
        }
    }

    SetYear_months_arr() {
        let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        let d = new Date();
        let Year_months_arr = [];
        d.setDate(1);
        for (let i = 1; i <= 13; i++) {
            let obj = {year: 0, month: '', id: 0};
            if (i === 1 || i === '1') {
            }
            else {
                obj.year = d.getFullYear();
                obj.month = monthName[d.getMonth()];
                obj.id = this.getMonthIndex(obj.month);
                Year_months_arr.push(obj);
            }
            d.setMonth(d.getMonth() - 1);
        }
        // sort first by year i..e (2020 --> 2019) adn then by month
        Year_months_arr = Year_months_arr.sort(function (a, b) {
            return b["year"] - a["year"] || a["id"] - b["id"];
        });
        return Year_months_arr;
    }

    getMonthIndex(monthname) {
        switch (monthname) {
            case "Jan":
                return 1;
            case "Feb":
                return 2;
            case "Mar":
                return 3;
            case "Apr":
                return 4;
            case "May":
                return 5;
            case "Jun":
                return 6;
            case "Jul":
                return 7;
            case "Aug":
                return 8;
            case "Sept":
                return 9;
            case "Oct":
                return 10;
            case "Nov":
                return 11;
            case "Dec":
                return 12;
            default:
                return 0;
        }
    }

    getCustomerInformation(accountId) {
        this.setState({loadingCustomerInformation: true});
        this.crmService.getCustomerInformation(accountId).then(response => {
            let data = response.data;
            this.setState({
                credit_Limit: data.credit_Limit,
                credit_Terms: data.credit_Terms,
                paymentTerms: data.paymentTerm?.description,
                loadingCustomerInformation: false
            });
        }).catch(error => {
            console.log(error.data);
            this.setState({loadingCustomerInformation: false});
            toast.error(handleErrorMessage(error));
        });
    }

    GetTradingAndSalesInfo(accountId) {
        this.setState({loadingPurchasesByMonth: true});
        this.crmService.getCustomerTrading_salesInformation(accountId).then(response => {
            let data = response.data.item1;
            let SalesbyMonth = {...this.state.SalesbyMonth};
            SalesbyMonth.Jan = data.jan;
            SalesbyMonth.Feb = data.feb;
            SalesbyMonth.Mar = data.mar;
            SalesbyMonth.Apr = data.apr;
            SalesbyMonth.May = data.may;
            SalesbyMonth.Jun = data.jun;
            SalesbyMonth.Jul = data.jul;
            SalesbyMonth.Aug = data.aug;
            SalesbyMonth.Sept = data.sep;
            SalesbyMonth.Oct = data.oct;
            SalesbyMonth.Nov = data.nov;
            SalesbyMonth.Dec = data.dec;

            let Year_months_arr = this.SetYear_months_arr();

            this.setState({
                Year_months_arr,
                SalesbyMonth: SalesbyMonth,
                loadingPurchasesByMonth: false,
            });
        }).catch(error => {
            console.log(error.data);
            this.setState({loadingPurchasesByMonth: false});
            toast.error(handleErrorMessage(error));
        });
    }

    convertToDecimalplaces(num) {
        if (num !== undefined && num !== null) {
            return (<NumberFormat value={num}
                                  displayType={'text'}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  prefix={'$'}/>);
        } else {
            return num;
        }
    }

    render() {
        const {previousYears, Year_months_arr, loadingPurchasesByMonth, loadingCustomerInformation} = this.state;
        const options = [];
        for (let i = 0; i <= 15; i++) {
            const year = previousYears + i;
            options.push({'label': year, 'value': year});
        }

        return (
            <div>
                <Row>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                Outstanding Balance
                            </CardHeader>
                            <CardBody>
                                <DebtorOutstandingBalance isLoading={this.props.isLoading}
                                                          outstandingBalance={this.props.debtor}/>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                Purchase by Month
                            </CardHeader>
                            <CardBody>
                                {
                                    loadingPurchasesByMonth
                                        ? <Spinner color={"primary"}/>
                                        : <Table size={"sm"} responsive>
                                            <tbody>
                                            {(Year_months_arr && Year_months_arr.length > 0) ?
                                                (Year_months_arr || []).map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{item.year}</td>
                                                        <td>{item.month}</td>
                                                        <td>{this.convertToDecimalplaces(this.state.SalesbyMonth[item.month])}</td>
                                                    </tr>
                                                ) :
                                                <tr>
                                                    <td colSpan="4" className="text-center">No Sales By Month.</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                Credit Status
                            </CardHeader>
                            <CardBody>
                                {
                                    loadingCustomerInformation
                                        ? <Spinner color={"primary"}/>
                                        : <Table size={"sm"} responsive className={"mb-0"}>
                                            <tbody>
                                            <tr>
                                                <th>Credit limit</th>
                                                <td>{this.convertToDecimalplaces(this.state.credit_Limit)}</td>
                                            </tr>
                                            <tr>
                                                <th>Credit terms</th>
                                                <td>{this.state.credit_Terms}</td>
                                            </tr>
                                            <tr>
                                                <th>Payment Terms</th>
                                                <td>{this.state.paymentTerms}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ToastContainer/>
            </div>
        );
    }
}