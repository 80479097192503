import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../../services/CommonService';
import {cloneDeep, isEmpty, camelCase} from 'lodash';
import classnames from 'classnames';

import skinOnlyUtil from './SkinOnlyUtil';
import NumberFormat from "react-number-format";
import SkinOnlyBOMModal from "./SkinOnlyBOMModal";
import SkinOnlyConsolidatedBOMModal from "./SkinOnlyConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../custom/CustomUtil";
import {PRODUCT_BUILDER_CUSTOM_BUILD} from "../../../../../../store/AppConstants";

export default class SkinOnly extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = skinOnlyUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = skinOnlyUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                    product = skinOnlyUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = "";
                    context.salesOrderItemCustom.productCode = "";
                    context.salesOrderItemCustom.productGroupCode = "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if (stocksByProdCode) {
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                        }
                        if (stock) {
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                        if (s.isParentItem) {
                            context.salesOrderItemCustom.prodCode = s.prodCode;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = skinOnlyUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = skinOnlyUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;


        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemSkinOnly.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};


        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {
            value: {},
            dropdownValue: ""
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemSkinOnly.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 1200;// default
        product.items[itemIndex].configuration.width.max = 3960;// default
        product.items[itemIndex].configuration.width.selected = {
            value: {},
            dropdownValue: "",
            isSelectable: true
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = context.salesOrderItemSkinOnly.width;
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1500;
        }
        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.min = 900;// default
        product.items[itemIndex].configuration.drop.max = 2400;// default
        product.items[itemIndex].configuration.drop.selected = {
            value: {},
            dropdownValue: "",
            isSelectable: true
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = context.salesOrderItemSkinOnly.drop;
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }
        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //widthMeasurement
        configurationIndex = findIndex(configurations, "attributeKey", "widthMeasurement");
        product.items[itemIndex].configuration.widthMeasurement = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.widthMeasurement.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.widthMeasurement.finalOptions = product.items[itemIndex].configuration.widthMeasurement.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.widthMeasurement.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.widthMeasurement);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.widthMeasurement.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.widthMeasurement.selected.value = product.items[itemIndex].configuration.widthMeasurement.finalOptions[optionIndex];
            product.items[itemIndex].configuration.widthMeasurement.selected.dropdownValue = product.items[itemIndex].configuration.widthMeasurement.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.widthMeasurement.formError = {isValid: true, message: "", dom: null,};


        //dropMeasurement
        configurationIndex = findIndex(configurations, "attributeKey", "dropMeasurement");
        product.items[itemIndex].configuration.dropMeasurement = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.dropMeasurement.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.dropMeasurement.finalOptions = product.items[itemIndex].configuration.dropMeasurement.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.dropMeasurement.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.dropMeasurement);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.dropMeasurement.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.dropMeasurement.selected.value = product.items[itemIndex].configuration.dropMeasurement.finalOptions[optionIndex];
            product.items[itemIndex].configuration.dropMeasurement.selected.dropdownValue = product.items[itemIndex].configuration.dropMeasurement.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.dropMeasurement.formError = {isValid: true, message: "", dom: null,};


        //category
        configurationIndex = findIndex(configurations, "attributeKey", "category");
        product.items[itemIndex].configuration.category = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.category.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.category.options = getSorted(product.items[itemIndex].configuration.category.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.category.finalOptions = product.items[itemIndex].configuration.category.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.category.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.category);
        }
        optionIndex = optionIndex > -1 ? optionIndex : findIndex(product.items[itemIndex].configuration.category.finalOptions, 'optionKey', "1600mm White");
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.category.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.category.selected.value = product.items[itemIndex].configuration.category.finalOptions[optionIndex];
            product.items[itemIndex].configuration.category.selected.dropdownValue = product.items[itemIndex].configuration.category.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.category.formError = {isValid: true, message: "", dom: null,};


        //product
        configurationIndex = findIndex(configurations, "attributeKey", "product");
        product.items[itemIndex].configuration.product = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.product.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.product.options = currentUser.isExternalUser ? product.items[itemIndex].configuration.product.options.filter(o => !["ROLS", "PTR"].includes(o.optionKey)) : product.items[itemIndex].configuration.product.options;
        product.items[itemIndex].configuration.product.finalOptions = product.items[itemIndex].configuration.product.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.product.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.product);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.product.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.product.selected.value = product.items[itemIndex].configuration.product.finalOptions[optionIndex];
            product.items[itemIndex].configuration.product.selected.dropdownValue = product.items[itemIndex].configuration.product.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.product.formError = {isValid: true, message: "", dom: null,};


        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.model);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
            product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};


        //purpose
        configurationIndex = findIndex(configurations, "attributeKey", "purpose");
        product.items[itemIndex].configuration.purpose = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.purpose.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.purpose.finalOptions = product.items[itemIndex].configuration.purpose.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.purpose.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.purpose);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.purpose.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.purpose.selected.value = product.items[itemIndex].configuration.purpose.finalOptions[optionIndex];
            product.items[itemIndex].configuration.purpose.selected.dropdownValue = product.items[itemIndex].configuration.purpose.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.purpose.formError = {isValid: true, message: "", dom: null,};

        //previousOrdNum
        configurationIndex = findIndex(configurations, "attributeKey", "previousOrdNum");
        product.items[itemIndex].configuration.previousOrdNum = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.previousOrdNum.selected = {
            value: {}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.previousOrdNum.selected.value = context.salesOrderItemSkinOnly.previousOrdNum;
        } else {
            product.items[itemIndex].configuration.previousOrdNum.selected.value = "";
        }
        product.items[itemIndex].configuration.previousOrdNum.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemSkinOnly.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.fabricType.selected.isSelectable = true;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemSkinOnly.fabricType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
        }

        //fabricColourOffRange
        product.items[itemIndex].configuration.fabricColourOffRange = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = context.salesOrderItemSkinOnly.fabricColourOffRange;
        } else {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
        }
        product.items[itemIndex].configuration.fabricColourOffRange.formError = {isValid: true, message: "", dom: null};


        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true
        };
        product.items[itemIndex].configuration.fabricColour.options = [];
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
            product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                return {
                    optionKey: o.color,
                    optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                }

            });
        }
        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
            product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
                return (
                    salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                )
            });
        } else {
            product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options;
       }
        
        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
            optionIndex = -1;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.fabricColour);
            }
            if (optionIndex === -1 && !product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                    optionIndex = 0;
                }
            }
            if (optionIndex > -1) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex])) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
            }
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};


        //valance
        configurationIndex = findIndex(configurations, "attributeKey", "valance");
        product.items[itemIndex].configuration.valance = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.valance.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.valance);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
            product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.valance.formError = {isValid: true, message: "", dom: null,};


        //splineTapeColour
        configurationIndex = findIndex(configurations, "attributeKey", "splineTapeColour");
        product.items[itemIndex].configuration.splineTapeColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.splineTapeColour.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.splineTapeColour.finalOptions = product.items[itemIndex].configuration.splineTapeColour.options.filter(o => o.description === "dropdown");
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.splineTapeColour.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.splineTapeColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.splineTapeColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.splineTapeColour.selected.value = product.items[itemIndex].configuration.splineTapeColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.splineTapeColour.selected.dropdownValue = product.items[itemIndex].configuration.splineTapeColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.splineTapeColour.formError = {isValid: true, message: "", dom: null,};


        //splineType
        configurationIndex = findIndex(configurations, "attributeKey", "splineType");
        product.items[itemIndex].configuration.splineType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.splineType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.splineType.finalOptions = product.items[itemIndex].configuration.splineType.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.splineType.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.splineType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.splineType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.splineType.selected.value = product.items[itemIndex].configuration.splineType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.splineType.selected.dropdownValue = product.items[itemIndex].configuration.splineType.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.splineType.formError = {isValid: true, message: "", dom: null,};

        //bottomSpline
        configurationIndex = findIndex(configurations, "attributeKey", "bottomSpline");
        product.items[itemIndex].configuration.bottomSpline = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomSpline.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bottomSpline.finalOptions = product.items[itemIndex].configuration.bottomSpline.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomSpline.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.bottomSpline);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.bottomSpline.selected.value = product.items[itemIndex].configuration.bottomSpline.finalOptions[optionIndex];
            product.items[itemIndex].configuration.bottomSpline.selected.dropdownValue = product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.bottomSpline.formError = {isValid: true, message: "", dom: null,};

        //bottomSplineColour
        configurationIndex = findIndex(configurations, "attributeKey", "bottomSplineColour");
        product.items[itemIndex].configuration.bottomSplineColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomSplineColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bottomSplineColour.finalOptions = product.items[itemIndex].configuration.bottomSplineColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomSplineColour.finalOptions, 'optionKey', context.salesOrderItemSkinOnly.bottomSplineColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.bottomSplineColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.bottomSplineColour.selected.value = product.items[itemIndex].configuration.bottomSplineColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.bottomSplineColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomSplineColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.bottomSplineColour.formError = { isValid: true, message: "", dom: null, };

        //bottomPocketTubeDiameter
        product.items[itemIndex].configuration.bottomPocketTubeDiameter = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.value = context.salesOrderItemSkinOnly.bottomPocketTubeDiameter;
        } else {
            product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.value = "";
        }
        product.items[itemIndex].configuration.bottomPocketTubeDiameter.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //spline
        configurationIndex = findIndex(configurations, "attributeKey", "spline");
        product.items[itemIndex].configuration.spline = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.spline.finalOptions = product.items[itemIndex].configuration.spline.options;
        product.items[itemIndex].configuration.spline.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };

        //bladeSize
        configurationIndex = findIndex(configurations, "attributeKey", "bladeSize");
        product.items[itemIndex].configuration.bladeSize = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bladeSize.finalOptions = product.items[itemIndex].configuration.bladeSize.options;
        product.items[itemIndex].configuration.bladeSize.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };

        //bladeNumber
        configurationIndex = findIndex(configurations, "attributeKey", "bladeNumber");
        product.items[itemIndex].configuration.bladeNumber = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bladeNumber.finalOptions = product.items[itemIndex].configuration.bladeNumber.options;
        product.items[itemIndex].configuration.bladeNumber.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };

        //bom
        configurationIndex = findIndex(configurations, "attributeKey", "bom");
        product.items[itemIndex].configuration.bom = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bom.finalOptions = product.items[itemIndex].configuration.bom.options;
        product.items[itemIndex].configuration.bom.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };

        product = this.updateItemConfigurationOptions("product", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("model", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("purpose", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("widthMeasurement", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("dropMeasurement", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("splineTapeColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("splineType", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("bottomSpline", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("bottomSplineColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);

        product = skinOnlyUtil.updateRules("location", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("width", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("drop", product, itemIndex, order);

        product = skinOnlyUtil.updateRules("previousOrdNum", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("model", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("purpose", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("widthMeasurement", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("dropMeasurement", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("valance", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("splineTapeColour", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("splineType", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("bottomSpline", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("bottomSplineColour", product, itemIndex, order);
        product = skinOnlyUtil.updateRules("bottomPocketTubeDiameter", product, itemIndex, order);

        product = skinOnlyUtil.updateFormError("previousOrdNum", product, itemIndex);
        product = skinOnlyUtil.updateFormError("width", product, itemIndex);
        product = skinOnlyUtil.updateFormError("drop", product, itemIndex);
        product = skinOnlyUtil.updateFormError("fabricColourOffRange", product, itemIndex);
        product = skinOnlyUtil.updateFormError("bottomPocketTubeDiameter", product, itemIndex);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let {order, currentUser} = this.props;

        let optionIndex = -1,
            quantity = product.items[itemIndex].configuration.quantity.selected.value,
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = product.items[itemIndex].configuration.drop.selected.value,
            condition1, condition2, productCode, condition3;

        if (key) {
            switch (key) {
                case "fabricType":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    if (condition1 === "PTR") {
                        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options.filter(o => o.product.code === "ROLS");
                    } else {
                        condition2 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options.filter(o =>
                            (o.product.code === condition1)
                            && ((o.product.code === "WID" && condition2 === "Cable") ? !o.optionKey.includes("PVC") : true)
                        );
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions)) {

                        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricType.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions)) {
                                optionIndex = 0;
                            }
                        }

                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    }
                    break;
                case "fabricColour":
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                            return {
                                optionKey: o.color,
                                optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                                ...o
                            }
                        });
                        if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                            // no fabric color options if no fabric range
                            product.items[itemIndex].configuration.fabricColour.finalOptions = [];
                        } else {
                            product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
                                return (
                                    salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                                )
                            });
                        }
                    }
                    optionIndex = -1;
                    if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {

                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1 && !product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricColour.selected.value = null;
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                    }
                    break;
                case "product":
                    condition1 = product.items[itemIndex].configuration.category.selected.value.optionKey;
                    product.items[itemIndex].configuration.product.finalOptions = product.items[itemIndex].configuration.product.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.product.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.product.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.product.finalOptions, 'optionKey', product.items[itemIndex].configuration.product.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.product.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.product.selected.value = product.items[itemIndex].configuration.product.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.product.selected.dropdownValue = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.product.selected.value = {};
                        product.items[itemIndex].configuration.product.selected.dropdownValue = "";
                    }
                    break;
                case "model":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;

                    //use for saved order
                    if (product.items[itemIndex].configuration.model.selected.value.optionKey.includes("Order by Blind Dimension")) {
                        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options.filter(o =>
                            (o.condition1 ? o.condition1.includes(condition1) : true)
                        );
                    }
                    //remove "Order by Blind Dimension" from model for new orders
                    else {
                        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options.filter(o =>
                            (o.condition1 ? o.condition1.includes(condition1) : true) && !o.optionKey.includes("Order by Blind Dimension")
                        );
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', product.items[itemIndex].configuration.model.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.model.selected.value = {};
                        product.items[itemIndex].configuration.model.selected.dropdownValue = "";
                    }
                    break;
                case "purpose":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    product.items[itemIndex].configuration.purpose.finalOptions = product.items[itemIndex].configuration.purpose.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.purpose.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.purpose.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.purpose.finalOptions, 'optionKey', product.items[itemIndex].configuration.purpose.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.purpose.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.purpose.selected.value = product.items[itemIndex].configuration.purpose.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.purpose.selected.dropdownValue = product.items[itemIndex].configuration.purpose.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.purpose.selected.value = {};
                        product.items[itemIndex].configuration.purpose.selected.dropdownValue = "";
                    }
                    break;
                case "widthMeasurement":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.widthMeasurement.finalOptions = product.items[itemIndex].configuration.widthMeasurement.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.widthMeasurement.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.widthMeasurement.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.widthMeasurement.finalOptions, 'optionKey', product.items[itemIndex].configuration.widthMeasurement.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.widthMeasurement.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.widthMeasurement.selected.value = product.items[itemIndex].configuration.widthMeasurement.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.widthMeasurement.selected.dropdownValue = product.items[itemIndex].configuration.widthMeasurement.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.widthMeasurement.selected.value = {};
                        product.items[itemIndex].configuration.widthMeasurement.selected.dropdownValue = "";
                    }
                    break;
                case "dropMeasurement":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.dropMeasurement.finalOptions = product.items[itemIndex].configuration.dropMeasurement.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.dropMeasurement.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.dropMeasurement.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.dropMeasurement.finalOptions, 'optionKey', product.items[itemIndex].configuration.dropMeasurement.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.dropMeasurement.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.dropMeasurement.selected.value = product.items[itemIndex].configuration.dropMeasurement.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.dropMeasurement.selected.dropdownValue = product.items[itemIndex].configuration.dropMeasurement.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.dropMeasurement.selected.value = null;
                        product.items[itemIndex].configuration.dropMeasurement.selected.dropdownValue = "";
                    }
                    break;
                case "valance":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, 'optionKey', product.items[itemIndex].configuration.valance.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.valance.selected.value = {};
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = "";
                    }
                    break;
                case "splineTapeColour":
                    optionIndex = -1;
                    condition1 = isEmpty(product.items[itemIndex].configuration.product.selected.value) ? "" : product.items[itemIndex].configuration.product.selected.value.optionKey;
                    product.items[itemIndex].configuration.splineTapeColour.finalOptions = product.items[itemIndex].configuration.splineTapeColour.options.filter(o =>
                        (o.description === "dropdown")
                        && (o.condition1 ? o.condition1.includes(condition1) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.splineTapeColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.splineTapeColour.selected.value)) {
                            optionIndex = product.items[itemIndex].configuration.splineTapeColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.splineTapeColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.splineTapeColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.splineTapeColour.selected.value = product.items[itemIndex].configuration.splineTapeColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.splineTapeColour.selected.dropdownValue = product.items[itemIndex].configuration.splineTapeColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.splineTapeColour.selected.value = null;
                        product.items[itemIndex].configuration.splineTapeColour.selected.dropdownValue = "";
                    }
                    break;
                case "splineType":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.purpose.selected.value.optionKey;
                    condition3 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.splineType.finalOptions = product.items[itemIndex].configuration.splineType.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                        && (o.condition3 ? o.condition3.includes(condition3) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.splineType.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.splineType.selected.value)) {
                            optionIndex = product.items[itemIndex].configuration.splineType.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.splineType.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.splineType.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.splineType.selected.value = product.items[itemIndex].configuration.splineType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.splineType.selected.dropdownValue = product.items[itemIndex].configuration.splineType.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.splineType.selected.value = null;
                        product.items[itemIndex].configuration.splineType.selected.dropdownValue = "";
                    }
                    break;
                case "bottomSpline":
                    optionIndex = -1;
                    condition1 = isEmpty(product.items[itemIndex].configuration.product.selected.value) ? "" : product.items[itemIndex].configuration.product.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.purpose.selected.value) ? "" : product.items[itemIndex].configuration.purpose.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.bottomSpline.finalOptions = product.items[itemIndex].configuration.bottomSpline.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                        && (o.condition3 ? o.condition3.includes(condition3) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.value)) {
                            optionIndex = product.items[itemIndex].configuration.bottomSpline.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.bottomSpline.selected.value = product.items[itemIndex].configuration.bottomSpline.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bottomSpline.selected.dropdownValue = product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.bottomSpline.selected.value = null;
                        product.items[itemIndex].configuration.bottomSpline.selected.dropdownValue = "";
                    }
                    break;
                case "bottomSplineColour":
                    optionIndex = -1;
                    condition1 = isEmpty(product.items[itemIndex].configuration.product.selected.value) ? "" : product.items[itemIndex].configuration.product.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.value) ? "" : product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
                    product.items[itemIndex].configuration.bottomSplineColour.finalOptions = product.items[itemIndex].configuration.bottomSplineColour.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.bottomSplineColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.bottomSplineColour.selected.value)) {
                            optionIndex = product.items[itemIndex].configuration.bottomSplineColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bottomSplineColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.bottomSplineColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.bottomSplineColour.selected.value = product.items[itemIndex].configuration.bottomSplineColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bottomSplineColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomSplineColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.bottomSplineColour.selected.value = null;
                        product.items[itemIndex].configuration.bottomSplineColour.selected.dropdownValue = "";
                    }
                    break;
                case "blades":
                    let bladeSize = 0;
                    let carrierQty = 0;

                    let bladeRailSpace = 78;
                    let mount = "Outside";
                    let stackingDirection = "Centre Open";
                    let trackLength = width;

                    switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                        case "ZIPE":
                        case "WID":
                        case "VOD":
                        case "FAA":
                            break;
                        case "VIN":
                            if (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                                switch (true) {
                                    case product.items[itemIndex].configuration.fabricType.selected.value.optionKey.includes("89mm"):
                                        bladeRailSpace = 78;
                                        bladeSize = 89;
                                        break;
                                    case product.items[itemIndex].configuration.fabricType.selected.value.optionKey.includes("127mm"):
                                        bladeRailSpace = 114.5;
                                        bladeSize = 127;
                                        break;
                                }

                                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                                    case "Order by Blind Dimension":
                                        switch (mount) {
                                            case "Inside":
                                                trackLength = width - 14;
                                                break;
                                            case "Outside":
                                                trackLength = width - 4;
                                                break;
                                        }

                                        let W = trackLength;// trackLength
                                        let B = bladeRailSpace;//Blade rail space
                                        let S = bladeSize;// Blade size
                                        let C = carrierQty; //Carriers Quantity | Output

                                        switch (stackingDirection) {
                                            case "Left":
                                            case "Right":
                                            case "Centre Bunch":
                                                C = Math.ceil((W - (S - B)) / B);
                                                break;
                                            case "Centre Open":
                                                C = Math.ceil(((W - (S - B)) / 2) / B) * 2;
                                                break;
                                            default:
                                                C = -1;
                                        }
                                        carrierQty = C;
                                        break;
                                    case "Order by Blade Qty":
                                        carrierQty = product.items[itemIndex].configuration.quantity.selected.value;
                                        break;
                                }
                            }
                            break;
                    }
                    product.items[itemIndex].configuration.bladeSize.selected.value = bladeSize;
                    product.items[itemIndex].configuration.bladeNumber.selected.value = carrierQty;
                    break;
                case "basePrice":
                    //productCode
                    let productCode = product.items[itemIndex].configuration.product.selected.value.optionKey === "PTR" ? "ROLS" : product.items[itemIndex].configuration.product.selected.value.optionKey;
                    //bmCode
                    let bmCode = skinOnlyUtil.generateBMCode(product, itemIndex, order);

                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x =>
                        (
                            (x.productCode === productCode)
                            && (x.bmCode === bmCode)
                            && (x.variant === "Default")
                        )
                    );
                    //widthSet
                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode = bmCode;
                        switch (bmCode) {
                            case "Blade Only Bunnings 89mm":
                            case "Blade Only Bunnings 127mm":
                                product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find((x, xIndex) => (x.width >= quantity) || (xIndex === (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths.length - 1)));
                                break;
                            default:
                                product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find((x, xIndex) => (x.width >= product.items[itemIndex].configuration.width.selected.value) || (xIndex === (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths.length - 1)));
                        }
                        //dropSet
                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find((x, xIndex) => (x.drop >= product.items[itemIndex].configuration.drop.selected.value) || (xIndex === (product.items[itemIndex].configuration.set.selected.value.widthSet.drops.length - 1)));
                            //price
                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                let price = product.items[itemIndex].configuration.set.selected.value.dropSet.price;

                                let multiplier = skinOnlyUtil.getPriceMatrixMultiplierByFabricCategory(product, itemIndex);
                                price = price * multiplier;

                                multiplier = skinOnlyUtil.getPriceMatrixMultiplier(product, itemIndex);
                                price = price * multiplier;

                                product.items[itemIndex].configuration.set.selected.value.price = price;
                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = skinOnlyUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = parseInt(change);
                }
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
                product = skinOnlyUtil.updateRules("width", product, itemIndex, order);
                product = skinOnlyUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = skinOnlyUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = skinOnlyUtil.updateFormError("drop", product, itemIndex);
                break;
            case "category":
                optionIndex = findIndex(product.items[itemIndex].configuration.category.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.category.selected.value = product.items[itemIndex].configuration.category.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.category.selected.dropdownValue = product.items[itemIndex].configuration.category.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("product", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("model", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("purpose", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("widthMeasurement", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("dropMeasurement", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("splineTapeColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("splineType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSpline", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSplineColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);

                    product = skinOnlyUtil.updateRules("width", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("drop", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("previousOrdNum", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("model", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("purpose", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("widthMeasurement", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("dropMeasurement", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("valance", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("splineTapeColour", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("splineType", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSpline", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSplineColour", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomPocketTubeDiameter", product, itemIndex, order);

                    product = skinOnlyUtil.updateFormError("width", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("drop", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("previousOrdNum", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("bottomPocketTubeDiameter", product, itemIndex);

                }
                break;
            case "product":
                optionIndex = findIndex(product.items[itemIndex].configuration.product.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.product.selected.value = product.items[itemIndex].configuration.product.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.product.selected.dropdownValue = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("model", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("purpose", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("widthMeasurement", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("dropMeasurement", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("splineTapeColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("splineType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSpline", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSplineColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);

                    //update min & max width & drop
                    product = skinOnlyUtil.updateRules("width", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("drop", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("previousOrdNum", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("model", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("purpose", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("widthMeasurement", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("dropMeasurement", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("valance", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("splineTapeColour", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("splineType", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSpline", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSplineColour", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomPocketTubeDiameter", product, itemIndex, order);

                    product = skinOnlyUtil.updateFormError("width", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("drop", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("previousOrdNum", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("bottomPocketTubeDiameter", product, itemIndex);
                }
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("fabricType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("widthMeasurement", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("dropMeasurement", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("splineType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSpline", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSplineColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);

                    product = skinOnlyUtil.updateRules("width", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("drop", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("widthMeasurement", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("dropMeasurement", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("valance", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSpline", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSplineColour", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomPocketTubeDiameter", product, itemIndex, order);

                    product = skinOnlyUtil.updateFormError("width", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("drop", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                    product = skinOnlyUtil.updateFormError("bottomPocketTubeDiameter", product, itemIndex);

                }
                break;
            case "purpose":
                optionIndex = findIndex(product.items[itemIndex].configuration.purpose.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.purpose.selected.value = product.items[itemIndex].configuration.purpose.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.purpose.selected.dropdownValue = product.items[itemIndex].configuration.purpose.selected.value.optionKey;

                    product = skinOnlyUtil.updateRules("previousOrdNum", product, itemIndex, order);
                    product = skinOnlyUtil.updateFormError("previousOrdNum", product, itemIndex);

                    product = this.updateItemConfigurationOptions("splineType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSpline", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomSplineColour", product, itemIndex, order, true);

                    product = skinOnlyUtil.updateRules("splineType", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSpline", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomSplineColour", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomPocketTubeDiameter", product, itemIndex, order);

                    product = skinOnlyUtil.updateFormError("bottomPocketTubeDiameter", product, itemIndex);
                }
                break;
            case "previousOrdNum":
                product.items[itemIndex].configuration.previousOrdNum.selected.value = change;
                product = skinOnlyUtil.updateFormError("previousOrdNum", product, itemIndex);
                break;
            case "widthMeasurement":
                optionIndex = findIndex(product.items[itemIndex].configuration.widthMeasurement.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.widthMeasurement.selected.value = product.items[itemIndex].configuration.widthMeasurement.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.widthMeasurement.selected.dropdownValue = product.items[itemIndex].configuration.widthMeasurement.selected.value.optionKey;
                }
                break;
            case "dropMeasurement":
                optionIndex = findIndex(product.items[itemIndex].configuration.dropMeasurement.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.dropMeasurement.selected.value = product.items[itemIndex].configuration.dropMeasurement.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.dropMeasurement.selected.dropdownValue = product.items[itemIndex].configuration.dropMeasurement.selected.value.optionKey;
                }
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = skinOnlyUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("width", product, itemIndex, order);
                    product = skinOnlyUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                    product = this.updateItemConfigurationOptions("blades", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                }
                break;
            case "fabricColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.fabricColour.selected.value = {};
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                }
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.value = change;
                product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                product = skinOnlyUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                break;
            case "valance":
                optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                }
                break;
            case "splineTapeColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.splineTapeColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.splineTapeColour.selected.value = product.items[itemIndex].configuration.splineTapeColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.splineTapeColour.selected.dropdownValue = product.items[itemIndex].configuration.splineTapeColour.selected.value.optionKey;
                }
                break;
            case "splineType":
                optionIndex = findIndex(product.items[itemIndex].configuration.splineType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.splineType.selected.value = product.items[itemIndex].configuration.splineType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.splineType.selected.dropdownValue = product.items[itemIndex].configuration.splineType.selected.value.optionKey;
                }
                break;
            case "bottomSpline":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomSpline.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomSpline.selected.value = product.items[itemIndex].configuration.bottomSpline.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomSpline.selected.dropdownValue = product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("bottomSplineColour", product, itemIndex, order, true);
                    product = skinOnlyUtil.updateRules("bottomSplineColour", product, itemIndex, order);
                    product = skinOnlyUtil.updateRules("bottomPocketTubeDiameter", product, itemIndex, order);
                    product = skinOnlyUtil.updateFormError("bottomPocketTubeDiameter", product, itemIndex);
                }
                break;
            case "bottomSplineColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomSplineColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomSplineColour.selected.value = product.items[itemIndex].configuration.bottomSplineColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomSplineColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomSplineColour.selected.value.optionKey;
                }
                break;
            case "bottomPocketTubeDiameter":
                if (change >= 0) {
                    product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.value = parseFloat(change);
                    product = skinOnlyUtil.updateFormError("bottomPocketTubeDiameter", product, itemIndex);
                }
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = skinOnlyUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser} = this.props;
        let items = product.items;
        let isOutdoorSelected = items?.some(item => item.configuration?.category?.selected?.dropdownValue === 'Outdoor');

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}} className={"text-center"}>
                                        #
                                    </th>
                                    <th style={{minWidth: 140}} className={"text-center"}>
                                        Category
                                    </th>
                                    <th style={{minWidth: 225}} className={"text-center"}>
                                        Product
                                    </th>
                                    <th style={{minWidth: 270}} className={"text-center"}>
                                        Model
                                    </th>
                                    <th style={{minWidth: 168}} className={"text-center"}>
                                        Purpose
                                    </th>
                                    <th style={{minWidth: 150}} className={"text-center"}>
                                        Location
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Quantity
                                    </th>
                                    <th style={{minWidth: 200}} className={"text-center"}>
                                        Width(mm)
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Drop(mm)
                                    </th>
                                    <th style={{minWidth: 260}} className={"text-center"}>
                                        Fabric
                                    </th>
                                    <th style={{minWidth: 135}} className={"text-center"}>
                                        Valance
                                    </th>
                                    <th style={{minWidth: 185}} className={"text-center"}>
                                        Spline Tape Colour
                                    </th>
                                    <th style={{ minWidth: 170 }} className="text-center">
                                        {isOutdoorSelected ? 'Top Spline Type (Thickness)' : 'Spline Type (Thickness)'}
                                    </th>
                                    <th style={{minWidth: 170}} className={"text-center"}>
                                        Bottom Finish
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null

                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="category"
                                                           value={item.configuration.category.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "category", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.category.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="product"
                                                           value={item.configuration.product.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "product", itemIndex, true)}>
                                                        <option value={""} disabled>Select product</option>

                                                        {
                                                            (item.configuration.product.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="model"
                                                           value={item.configuration.model.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "model", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="purpose"
                                                           value={item.configuration.purpose.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "purpose", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.purpose.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.previousOrdNum.selected.isSelectable
                                                            ? <div className="mt-2">

                                                                <Input type="number" name="previousOrdNum"
                                                                       value={item.configuration.previousOrdNum.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "previousOrdNum", itemIndex, true)}
                                                                       onFocus={(event) => event.target.select()}
                                                                       placeholder="Previous Order No"/>
                                                                {
                                                                    item.configuration.previousOrdNum.formError.isValid
                                                                        ? null
                                                                        : <Badge color={"danger"}>
                                                                            {item.configuration.previousOrdNum.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div>
                                                        <Input type="text" name="location"
                                                               value={item.configuration.location.selected.value}
                                                               onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                               placeholder="location"/>
                                                        {
                                                            item.configuration.location.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            item.configuration.location.formError.isWarning
                                                                ? <Badge color="warning">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                                : null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="number" name="quantity"
                                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                                   value={item.configuration.quantity.selected.value}
                                                                   min={item.configuration.quantity.min}
                                                                   max={item.configuration.quantity.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="quantity"/>

                                                            {
                                                                item.configuration.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="align-middle text-center">
                                                    {item.configuration.width.selected.isSelectable
                                                        ? <div>
                                                            <Input type="number" name="width"
                                                                   invalid={!item.configuration.width.formError.isValid}
                                                                   value={item.configuration.width.selected.value}
                                                                   min={item.configuration.width.min}
                                                                   max={item.configuration.width.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="width"/>
                                                            {
                                                                item.configuration.width.formError.isValid
                                                                    ? null
                                                                    : <Badge color={"danger"}>
                                                                        {item.configuration.width.formError.message}
                                                                    </Badge>
                                                            }
                                                            {
                                                                item.configuration.widthMeasurement.selected.isSelectable
                                                                    ? <div>
                                                                        {
                                                                            ((item.configuration.widthMeasurement.finalOptions || []).length > 1)
                                                                                ? <Input type="select" name="widthMeasurement"
                                                                                         className={"mt-2"}
                                                                                         disabled={!item.configuration.widthMeasurement.selected.isSelectable}
                                                                                         value={item.configuration.widthMeasurement.selected.dropdownValue}
                                                                                         onChange={(e) => this.handleChange(e.target.value, "widthMeasurement", itemIndex, true)}>
                                                                                    <option value={""} disabled>Select</option>
                                                                                    {
                                                                                        (item.configuration.widthMeasurement.finalOptions || []).map((o, oIndex) => {
                                                                                            return <option
                                                                                                key={oIndex}
                                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                                        })
                                                                                    }
                                                                                </Input>
                                                                                : <Badge
                                                                                    color={"info"}>{item.configuration.widthMeasurement.selected.dropdownValue}</Badge>
                                                                        }

                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        : <div>N/A</div>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {item.configuration.drop.selected.isSelectable
                                                        ? <div>
                                                            <Input type="number" name="drop"
                                                                   invalid={!item.configuration.drop.formError.isValid}
                                                                   value={item.configuration.drop.selected.value}
                                                                   min={item.configuration.drop.min}
                                                                   max={item.configuration.drop.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="drop"/>
                                                            {
                                                                item.configuration.drop.formError.isValid
                                                                    ? null
                                                                    : <Badge color={"danger"}>
                                                                        {item.configuration.drop.formError.message}
                                                                    </Badge>
                                                            }
                                                            {
                                                                item.configuration.dropMeasurement.selected.isSelectable
                                                                    ? <div>
                                                                        <Badge
                                                                            color={"info"}>{item.configuration.dropMeasurement.selected.dropdownValue}</Badge>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        : <div>N/A</div>
                                                    }

                                                </td>

                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="select" name="fabricType"
                                                            disabled={!item.configuration.fabricType.selected.isSelectable}
                                                            value={item.configuration.fabricType.selected.dropdownValue}
                                                            onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select Range</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {isEmpty(item.configuration.fabricColour.finalOptions) || item.configuration.fabricColourOffRange.selected.value
                                                        ? null
                                                        : <div className={"mt-2"}>
                                                            <Input type="select" name="fabricColour"
                                                                disabled={!item.configuration.fabricColour.selected.isSelectable}
                                                                value={item.configuration.fabricColour.selected.dropdownValue}
                                                                onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                <option value={""}>Select Colour</option>
                                                                {
                                                                    (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            disabled={o.isDiscontinued}
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                    }
                                                    {
                                                        item.configuration.fabricColourOffRange.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="fabricColourOffRange"
                                                                    value={item.configuration.fabricColourOffRange.selected.value}
                                                                    onChange={(e) => this.handleChange(e.target.value, "fabricColourOffRange", itemIndex, true)}
                                                                    placeholder="Fabric offrange"
                                                                    title={"Enter fabric offrange"}
                                                                />
                                                                {
                                                                    item.configuration.fabricColourOffRange.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.fabricColourOffRange.formError.message}
                                                                        </Badge>
                                                                }
                                                                <div>
                                                                    <Badge color="info">
                                                                        <i className="fa fa-info-circle mr-2"
                                                                            aria-hidden="true"/>
                                                                        Price On Application
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.valance.selected.isSelectable
                                                            ? <Input type="select" name="valance"
                                                                     value={item.configuration.valance.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "valance", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.valance.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : "N/A"
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.splineTapeColour.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="splineTapeColour"
                                                                       value={item.configuration.splineTapeColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "splineTapeColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select bracket colour</option>

                                                                    {
                                                                        (item.configuration.splineTapeColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : "N/A"
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.splineType.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="splineType"
                                                                       value={item.configuration.splineType.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "splineType", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select variant</option>
                                                                    {
                                                                        (item.configuration.splineType.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        !item.configuration.splineType.selected.isSelectable
                                                            ? <span>N/A</span>
                                                            : null
                                                    }
                                                </td>

                                                <td className="align-middle">
                                                    {
                                                        item.configuration.bottomSpline.selected.isSelectable
                                                            ? <div>
                                                                <Badge
                                                                    color={"info"}>{item.configuration.product.selected.value.optionKey === "VOD" ? "Bottom Spline" : (item.configuration.product.selected.value.optionKey === "PTR" ? "Bottom Rail" : "Bottom Finish")}</Badge>
                                                                <div className="text-center">
                                                                    <Input type="select" name="bottomSpline"
                                                                           value={item.configuration.bottomSpline.selected.dropdownValue}
                                                                           onChange={(e) => this.handleChange(e.target.value, "bottomSpline", itemIndex, true)}>
                                                                        <option value={""} disabled={true}>
                                                                            Select{item.configuration.product.selected.value.optionKey === "VOD" ? "Bottom Spline" : (item.configuration.product.selected.value.optionKey === "PTR" ? "Bottom Rail" : "Bottom Finish")}</option>
                                                                        {
                                                                            (item.configuration.bottomSpline.finalOptions || []).map((o, oIndex) => {
                                                                                return <option
                                                                                    key={oIndex}
                                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bottomSplineColour.selected.isSelectable
                                                            ? <div className="text-center mt-2">
                                                                <Input type="select" name="bottomSplineColour"
                                                                    value={item.configuration.bottomSplineColour.selected.dropdownValue}
                                                                    onChange={(e) => this.handleChange(e.target.value, "bottomSplineColour", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select Bottom Rail Colour</option>
                                                                    {
                                                                        (item.configuration.bottomSplineColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bottomPocketTubeDiameter.selected.isSelectable
                                                            ? <div className="text-center">
                                                                <Input type="number" name="bottomPocketTubeDiameter"
                                                                       value={item.configuration.bottomPocketTubeDiameter.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "bottomPocketTubeDiameter", itemIndex, true)}
                                                                    placeholder={item.configuration.bottomSpline.selected.value.optionKey === "Pocket Only" ? "Pocket Size(mm)" : "Tube Diameter(mm)"}
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.bottomPocketTubeDiameter.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.bottomPocketTubeDiameter.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (
                                                            !item.configuration.bottomSpline.selected.isSelectable
                                                            && !item.configuration.bottomPocketTubeDiameter.selected.isSelectable
                                                        )
                                                            ? <div className="text-center"><span>N/A</span></div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={15}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                        </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <SkinOnlyBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <SkinOnlyConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
