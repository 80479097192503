import React, {Component} from 'react';
import RoutesConfig from './config/routes.config';
import Layout from './common/Layout';
import {ClearCacheProvider} from 'react-clear-cache';

export default class App extends Component {


    render() {
        return (
            <ClearCacheProvider duration={30000} auto={true}>
                <div className="App">
                    <Layout history={this.props.history}>
                        <RoutesConfig/>
                    </Layout>
                </div>
            </ClearCacheProvider>
        );
    }
}