//Tabs re-ordered
export const Tabs = [
    {
        index: "0",
        key: "QUOTE",
        label: "Quotes"
    },
    {
        index: "1",
        key: "IMP",
        label: "Import Orders"
    },
    {
        index: "2",
        key: "SUB",
        label: "Submitted Orders"
    },
    {
        index: "3",
        key: "PEN",
        label: "Pending Orders"
    },
    {
        index: "4",
        key: "PROD",
        label: "Orders in Production"
    },
    {
        index: "5",
        key: "DISP",
        label: "Dispatched Orders"
    },
    {
        index: "6",
        key: "CAN",
        label: "Cancelled Orders"
    },
    //{
    //    index: "7",
    //    key: "REW",
    //    label: "Rework Orders"
    //},
];

export const SummaryType = [
    {
        label: "Product"
    },
    {
        label: "Debtor"
    }
];