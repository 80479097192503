import React, {Component} from 'react';
import ProductDiscountService from '../../services/product/ProductDiscountService';
import {Button, Card, CardBody, Col, Input, Row, Spinner, Table} from 'reactstrap';
import productService from "../../services/product/ProductService";
import {handleErrorMessage} from '../../services/CommonService';
import FileSaver from 'file-saver';
import {toast} from 'react-toastify';


export default class ImportProductDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            isLoadingProducts: false,
            isLoadingImport: false,
            isLoadingDownload: false
        };
        this.productDiscountService = new ProductDiscountService();

        this.getProductGroups = this.getProductGroups.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.importProductDiscount = this.importProductDiscount.bind(this);
        this.downloadFormat = this.downloadFormat.bind(this);
    }

    componentDidMount() {
        this.getProductGroups();
    }

    getProductGroups() {
        this.setState({isLoadingProducts: true});
        productService.getProductGroups().then(response => {
            this.setState({products: response.data, isLoadingProducts: false});
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingProducts: false});
        })
    }

    toggle(tab) {
        this.setState({activeTab: tab});
    }

    handleFileChange(event) {
        this.setState({selectedFile: event.target.files[0]});
    }

    importProductDiscount() {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        this.setState({isLoadingImport: true});
        this.productDiscountService.importProductDiscount(data).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.setState({isLoadingImport: false});
                toast.success("Discounts Imported successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            this.setState({isLoadingImport: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    downloadFormat() {
        this.setState({isLoadingDownload: true});
        this.productDiscountService.generateProductDiscountImportFormat().then(response => {
            this.setState({isLoadingDownload: false});
            FileSaver.saveAs(response.data, "IMPORT-FORMAT" + ".xlsx");
        }).catch(error => {
            this.setState({isLoadingDownload: false});
            console.log(error);
        });
    }


    render() {
        let {isLoadingDownload, isLoadingImport, products} = this.state;
        return (
            <div>
                <h6>Import Discount</h6>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Input type="file"
                               name="file"
                               id="file"
                               onChange={this.handleFileChange}
                               aria-label="Upload document"/>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="text-right">
                            <Button color={"primary"} size={"sm"} onClick={this.importProductDiscount}
                                    disabled={isLoadingImport}>
                                {
                                    isLoadingImport
                                        ? <Spinner size="sm"
                                                   className={"mr-2"}
                                                   color={"white"}/>
                                        : <i className="fa fa-upload mr-2"/>
                                }
                                {isLoadingImport ? "Importing" : "Import"}</Button>&nbsp;
                            <Button color={"primary"} size={"sm"} onClick={this.downloadFormat}
                                    disabled={isLoadingDownload}>
                                {
                                    isLoadingDownload
                                        ? <Spinner size="sm"
                                                   className={"mr-2"}
                                                   color={"white"}/>
                                        : <i className="fa fa-download mr-2"/>
                                }Download Import Template</Button>
                        </div>
                    </Col>
                </Row>
                <hr />
                <h5>Products</h5>
                <div>
                    <Table hover bordered striped size={"sm"}>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (products || []).map((product, index) => (
                                <tr key={index}>
                                    <td>{product.name}</td>
                                    <td>{product.code}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}