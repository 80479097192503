import React, {Component} from 'react';
import {
    Table,
    Button,
    Spinner,
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    Input,
    InputGroupAddon,
    InputGroup
} from 'reactstrap';
import StockService from '../../services/StockService';
import NumberFormat from "react-number-format";

export default class StockRecommendation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stocks: [],
            loading: false,
            pageSize: 50,
            selectedPage: 1,
            totalRecords: 0
        }
        ;
        this.stockService = new StockService();

        this.handleChange = this.handleChange.bind(this);
        this.getStockItems = this.getStockItems.bind(this);
    }

    componentDidMount() {
        this.getStockItems(1, "");
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});

    }


    getStockItems(currentPage, searchText) {
        let {loading, stocks, selectedPage, pageSize, totalRecords} = this.state;
        if (!loading) {
            loading = true;
            this.setState({loading});
            this.stockService.getStockItems(currentPage, pageSize, searchText).then(response => {

                selectedPage = response.data.currentPage;
                pageSize = response.data.pageSize;
                totalRecords = response.data.totalRecords;
                if (selectedPage === 1) {
                    stocks = [];
                }
                for (let i in response.data.records) {


                    if (response.data.records[i].qoh > response.data.records[i].mtD_UNITS) {

                        let diff = response.data.records[i].qoh - response.data.records[i].mtD_UNITS;

                        response.data.records[i].per = (diff / response.data.records[i].qoh ) * 100;


                        switch (true) {
                            case (response.data.records[i].per > 0 && response.data.records[i].per < 20):
                                response.data.records[i].color = "green";
                                break;
                            case (response.data.records[i].per > 20 && response.data.records[i].per < 40):
                                response.data.records[i].color = "yellow";
                                break;
                            case (response.data.records[i].per > 40 && response.data.records[i].per < 60):
                                response.data.records[i].color = "orange";
                                break;
                            case (response.data.records[i].per > 60):
                                response.data.records[i].color = "lightcoral";
                                break;
                            default:
                                break;

                        }
                    }
                    stocks.push(response.data.records[i]);

                }

                this.setState({loading: false, stocks, selectedPage, pageSize, totalRecords});

            }).catch(error => {
                    loading = false;
                    this.setState({loading});
                    console.log(error.data)
                }
            );
        }

    }

    render() {
        let {loading, stocks, selectedPage, pageSize, totalRecords} = this.state;


        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stocks</BreadcrumbItem>
                </Breadcrumb>

                {

                    <div>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col><h5>Stock</h5></Col>
                                    <Col>
                                        <InputGroup>
                                            <Input onChange={this.handleChange} type="search" name="searchText"
                                                   id="searchText" placeholder="Search..."/>
                                            <InputGroupAddon addonType="append">
                                                <Button color="primary"
                                                        onClick={() => this.getStockItems(1, this.state.searchText)}><i className="fa fa-search" aria-hidden="true" /></Button>
                                            </InputGroupAddon>
                                        </InputGroup>

                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Prodcode</th>
                                        <th>Name</th>
                                        <th>Recommended QOH</th>
                                        <th>Available QOH</th>
                                        <th>Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(stocks || []).map((item, index) => {
                                        return (
                                            <tr style={{backgroundColor: item.color}}>
                                                <td>{index + 1}</td>
                                                <td>{item.prodCode}</td>
                                                <td>{item.description}</td>
                                                <td><NumberFormat value={item.mtD_UNITS}
                                                                  displayType={'text'}
                                                                  decimalScale={0}
                                                                  thousandSeparator={true}/></td>
                                                <td><NumberFormat value={item.qoh}
                                                                  displayType={'text'}
                                                                  decimalScale={0}
                                                                  thousandSeparator={true}/></td>
                                                <td><NumberFormat value={item.price1}
                                                                  displayType={'text'}
                                                                  decimalScale={2}
                                                                  fixedDecimalScale={true}
                                                                  thousandSeparator={true}
                                                                  prefix={'$'}/></td>
                                            </tr>);
                                    })}

                                    </tbody>

                                </Table>
                                <div>
                                    {loading ? <Spinner color={"primary"}/> :
                                        <Button color={"primary"}
                                                hidden={!(totalRecords > (selectedPage * pageSize))}
                                                onClick={() => this.getStockItems(++selectedPage, this.state.searchText)}>
                                            Load
                                            more</Button>}
                                </div>
                            </CardBody>
                        </Card>

                    </div>
                }
            </div>
        )
    }
}