import React, { Component } from 'react';
import {
    Button,
    Col,
    Collapse,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner, Card, CardBody, Nav, TabContent, CardHeader, NavItem, NavLink
} from 'reactstrap';
import { isEmpty } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { getDistinctValues, validateEmail, handleErrorMessage } from "../../services/CommonService";
import { colourStyles } from "../../store/ComposeEmailReactSelectStyle";
import { toast, ToastContainer } from 'react-toastify';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import classnames from 'classnames';
import UploadFiles from "../attachment/UploadFiles";

const emailTemplateTab = [{ label: 'Template1', key: 'Template1' }, { label: 'Template2', key: 'Template2' }];

export default class PurchaseOrderSendEmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: {
                id: "",
                //from: [{ label: "Windoware KEYWAY(keyway@windoware.com.au)", value: "keyway@windoware.com.au" }],
                //fromOptions: [{ label: "Windoware KEYWAY(keyway@windoware.com.au)", value: "keyway@windoware.com.au" }],
                from: [],
                fromOptions: [],
                to: [],
                toOptions: [],
                cc: [],
                ccOptions: [],
                bcc: [],
                bccOptions: [],
                subject: "",
                body: "",
                attachmentIDs: ""
            },
            isCcOpen: true,
            isBccOpen: false,
            isReplyToOpen: false,
            activeTab: emailTemplateTab[0].key,
            isSendingEmail: false
        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ message: this.props.message });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.message.id !== nextProps.message.id) {
            this.setState({ message: nextProps.message });
        }
    }

    toggle(key, change) {
        this.setState({ [key]: change });
    }

    handleMessageChange(key, change) {
        let { message } = this.state;
        message[key] = change;
        this.setState({ message });
    }


    handleSubmit() {
        let { message, activeTab } = this.state;
        let { toggle, isOpen } = this.props;
        let errors = [];

        this.validateMessage(message, errors);
        if (!isEmpty(errors)) {
            errors.forEach(errorMessage => {
                toast.info(errorMessage);
            });
            return;
        }
        this.setState({ isSendingEmail: true });

        let request = {
            tos: getDistinctValues(message.to ? message.to : [], 'value'),
            ccs: getDistinctValues(message.cc ? message.cc : [], 'value'),
            bccs: getDistinctValues(message.bcc ? message.bcc : [], 'value'),
            replyTos: getDistinctValues(message.replyTo ? message.replyTo : [], 'value'),
            subject: message.subject,
            body: (activeTab === 'Template1' ? message.body1 : message.body2) + (message.bodyFooter ? message.bodyFooter  :""),
            ordNum: this.props.selectedData.ordNum,
            attachmentIDs: message.attachmentIDs
        };

        request.body = request.body.replaceAll('\n', '<br/>');
        this.purchaseOrderService.sendPurchaseOrderEmail(request).then(response => {
            toast.success("Email sent!");
            this.setState({ isSendingEmail: false }, () => toggle(!isOpen));
        }).catch(error => {
            console.error(error);
            toast.error(handleErrorMessage(error));
            this.setState({ isSendingEmail: false });
        })
    }

    validateMessage(message, errors) {
        let isValidEmailAddress = true;

        let tos = getDistinctValues(message.to ? message.to : [], 'value');
        let ccs = getDistinctValues(message.cc ? message.cc : [], 'value');
        let bccs = getDistinctValues(message.bcc ? message.bcc : [], 'value');

        if (isEmpty(tos)) {
            errors.push("No 'To' recipients!");
        }
        (tos || []).forEach(toEmailAddress => {
            isValidEmailAddress = validateEmail(toEmailAddress);
            if (!isValidEmailAddress) {
                errors.push("Not a valid email address!");
            }
        });
        (ccs || []).forEach(toEmailAddress => {
            isValidEmailAddress = validateEmail(toEmailAddress);
            if (!isValidEmailAddress) {
                errors.push("Not a valid email address!");
            }
        });
        (bccs || []).forEach(toEmailAddress => {
            isValidEmailAddress = validateEmail(toEmailAddress);
            if (!isValidEmailAddress) {
                errors.push("Not a valid email address!");
            }
        });
        return errors;
    }

    toggleTab(tab) {
        this.setState({ activeTab: tab });
    }

    render() {
        let { message, isBccOpen, isCcOpen,isReplyToOpen, isSendingEmail, activeTab } = this.state;
        let { isOpen, toggle, attachmentDetails } = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Compose Email
                </ModalHeader>
                <ModalBody style={{ minHeight: 300 }}>
                    <Card>
                        <CardHeader>
                            <Nav tabs card>
                                {emailTemplateTab.map((tab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink onClick={() => { this.toggleTab(tab.key) }}
                                            className={classnames({ active: activeTab === tab.key })}>
                                            <Button style={{ textDecoration: "none" }}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                            </Button>
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </CardHeader>

                        <CardBody>
                            <TabContent activeTab={activeTab}>
                                {(activeTab === "Template1") ?
                                    <Form>
                                        <FormGroup>
                                            <Row>
                                                <Col xl={6} lg={6} md={6} sm={12} xs={12}><Label>From Email</Label></Col>
                                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <div className="text-right">
                                                        {
                                                            isCcOpen
                                                                ? null
                                                                :
                                                                <Button onClick={() => this.toggle("isCcOpen", true)} color={"link"}
                                                                    size={"sm"} className={"mr-2"}>
                                                                    Cc
                                                                </Button>
                                                        }

                                                        {
                                                            isBccOpen
                                                                ? null
                                                                :
                                                                <Button onClick={() => this.toggle("isBccOpen", true)} color={"link"}
                                                                    size={"sm"} className={"mr-2"}>
                                                                    Bcc
                                                                </Button>
                                                        }
                                                        {
                                                            isReplyToOpen
                                                                ? null
                                                                :
                                                                <Button onClick={() => this.toggle("isReplyToOpen", true)} color={"link"}
                                                                    size={"sm"} className={"mr-2"}>
                                                                    Reply To
                                                                </Button>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>

                                            <CreatableSelect
                                                isMulti
                                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                placeholder={"Type complete email address then press enter"}
                                                onChange={(value) => this.handleMessageChange("from", value)}
                                                value={message.from}
                                                options={message.fromOptions}
                                                styles={colourStyles}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>To Email</Label>
                                            <CreatableSelect
                                                isMulti
                                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                placeholder={"Type complete email address then press enter"}
                                                onChange={(value) => this.handleMessageChange("to", value)}
                                                value={message.to}
                                                options={message.toOptions}
                                                styles={colourStyles}
                                            />
                                        </FormGroup>
                                        <Collapse isOpen={isCcOpen}>
                                            <FormGroup>
                                                <Label>Cc Email</Label>
                                                <CreatableSelect
                                                    isMulti
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                    placeholder={"Type complete email address then press enter"}
                                                    onChange={(value) => this.handleMessageChange("cc", value)}
                                                    value={message.cc}
                                                    options={message.ccOptions}
                                                />
                                            </FormGroup>
                                        </Collapse>
                                        <Collapse isOpen={isBccOpen}>
                                            <FormGroup>
                                                <Label>Bcc Email</Label>
                                                <CreatableSelect
                                                    isMulti
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                    placeholder={"Type complete email address then press enter"}
                                                    onChange={(value) => this.handleMessageChange("bcc", value)}
                                                    value={message.bcc}
                                                    options={message.bccOptions}
                                                />
                                            </FormGroup>
                                        </Collapse>
                                        <Collapse isOpen={isReplyToOpen}>
                                            <FormGroup>
                                                <Label>Reply To Email</Label>
                                                <CreatableSelect
                                                    isMulti
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                    placeholder={"Type complete email address then press enter"}
                                                    onChange={(value) => this.handleMessageChange("replyTo", value)}
                                                    value={message.replyTo}
                                                    options={message.replyToOptions}
                                                />
                                            </FormGroup>
                                        </Collapse>
                                        <FormGroup>
                                            <Label>Subject</Label>
                                            <Input
                                                type="text"
                                                name="subject"
                                                value={message.subject}
                                                onChange={(e) => this.handleMessageChange(e.target.name, e.target.value)}
                                                placeholder="Type subject here..." />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{this.props.parentType === "Share" ? "Content" : "Body"}</Label>
                                            <Input
                                                type="textarea"
                                                name="body1"
                                                value={message.body1}
                                                rows="15"
                                                onChange={(e) => this.handleMessageChange(e.target.name, e.target.value)}
                                                placeholder="Type body here..." />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Attachments</Label>
                                            <div className="border rounded px-2 py-1">
                                                <div className="mx-1 my-1">
                                                    <UploadFiles
                                                        handleChange={(change) => this.handleMessageChange("attachmentIDs", change)}
                                                        attachmentIDs={message.attachmentIDs}
                                                        multiple={true}
                                                        maxLength={12}
                                                        category={"PURCHASE_ORDER"}
                                                    />
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                    :
                                    null}
                                {(activeTab === "Template2") ?
                                    <Form>
                                        <FormGroup>
                                            <Row>
                                                <Col xl={6} lg={6} md={6} sm={12} xs={12}><Label>From Email</Label></Col>
                                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <div className="text-right">
                                                        {
                                                            isCcOpen
                                                                ? null
                                                                :
                                                                <Button onClick={() => this.toggle("isCcOpen", true)} color={"link"}
                                                                    size={"sm"} className={"mr-2"}>
                                                                    Cc
                                                                </Button>
                                                        }
                                                        {
                                                            isBccOpen
                                                                ? null
                                                                :
                                                                <Button onClick={() => this.toggle("isBccOpen", true)} color={"link"}
                                                                    size={"sm"} className={"mr-2"}>
                                                                    Bcc
                                                                </Button>
                                                        }
                                                        {
                                                            isReplyToOpen
                                                                ? null
                                                                :
                                                                <Button onClick={() => this.toggle("isReplyToOpen", true)} color={"link"}
                                                                    size={"sm"} className={"mr-2"}>
                                                                    Reply To
                                                                </Button>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <CreatableSelect
                                                isMulti
                                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                placeholder={"Type complete email address then press enter"}
                                                onChange={(value) => this.handleMessageChange("from", value)}
                                                value={message.from}
                                                options={message.fromOptions}
                                                styles={colourStyles} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>To Email</Label>
                                            <CreatableSelect
                                                isMulti
                                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                placeholder={"Type complete email address then press enter"}
                                                onChange={(value) => this.handleMessageChange("to", value)}
                                                value={message.to}
                                                options={message.toOptions}
                                                styles={colourStyles}
                                            />
                                        </FormGroup>
                                        <Collapse isOpen={isCcOpen}>
                                            <FormGroup>
                                                <Label>Cc Email</Label>
                                                <CreatableSelect
                                                    isMulti
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                    placeholder={"Type complete email address then press enter"}
                                                    onChange={(value) => this.handleMessageChange("cc", value)}
                                                    value={message.cc}
                                                    options={message.ccOptions}
                                                />
                                            </FormGroup>
                                        </Collapse>
                                        <Collapse isOpen={isBccOpen}>
                                            <FormGroup>
                                                <Label>Bcc Email</Label>
                                                <CreatableSelect
                                                    isMulti
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                    placeholder={"Type complete email address then press enter"}
                                                    onChange={(value) => this.handleMessageChange("bcc", value)}
                                                    value={message.bcc}
                                                    options={message.bccOptions}
                                                />
                                            </FormGroup>
                                        </Collapse>
                                        <Collapse isOpen={isReplyToOpen}>
                                            <FormGroup>
                                                <Label>Reply To Email</Label>
                                                <CreatableSelect
                                                    isMulti
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                                    placeholder={"Type complete email address then press enter"}
                                                    onChange={(value) => this.handleMessageChange("replyTo", value)}
                                                    value={message.replyTo}
                                                    options={message.replyToOptions}
                                                />
                                            </FormGroup>
                                        </Collapse>
                                        <FormGroup>
                                            <Label>Subject</Label>
                                            <Input
                                                type="text"
                                                name="subject"
                                                value={message.subject}
                                                onChange={(e) => this.handleMessageChange(e.target.name, e.target.value)}
                                                placeholder="Type subject here..." />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{this.props.parentType === "Share" ? "Content" : "Body"}</Label>
                                            <Input
                                                type="textarea"
                                                name="body2"
                                                rows="15"
                                                value={message.body2}
                                                onChange={(e) => this.handleMessageChange(e.target.name, e.target.value)}
                                                placeholder="Type body here..." />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Attachments</Label>
                                            <div className="border rounded px-2 py-1">
                                                <div className="mx-1 my-1">
                                                    <UploadFiles
                                                        handleChange={(change) => this.handleMessageChange("attachmentIDs", change)}
                                                        attachmentIDs={message.attachmentIDs}
                                                        multiple={true}
                                                        maxLength={12}
                                                        category={"PURCHASE_ORDER"}
                                                    />
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                    : null}
                            </TabContent>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color='primary'
                            disabled={isSendingEmail}
                            onClick={this.handleSubmit}>
                            {isSendingEmail
                                ? <Spinner size="sm" className="mr-2" color={"light"} />
                                : <i className="fa fa-paper-plane-o mr-2"
                                    aria-hidden="true" />
                            }
                            {isSendingEmail
                                ? "Sending"
                                : "Send"
                            }
                        </Button>
                        {' '}
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}