import React, {Component} from 'react';
import {Badge, Button, ButtonGroup, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner, Table} from 'reactstrap';
import queryString from 'query-string';
import classnames from 'classnames';
import salesCustomerOrderService from './../../services/sales/SalesCustomerOrderService';
import OrderService from './../../services/OrderService'
import {Link} from "react-router-dom";
import {changeFormatOfDateString, displayECD, getDateString, handleErrorMessage} from '../../services/CommonService';
import NumberFormat from "react-number-format";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import {DebtorInvoiceStatusByID} from '../../store/DebtorInvoiceStatus';
import FileSaver from 'file-saver';
import UserService from '../../services/UserService';
import {toast} from 'react-toastify';
import SalesAllCustomerOrdersFilter from '../../components/filters/SalesAllCustomerOrdersFilter';

const maxDate = moment().toDate();
const minDate = moment(maxDate).subtract(12, 'months').toDate();

export default class SalesAllCustomerOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountId: '',
            orders: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ordNum",
                        direction: false
                    },
                    filterRequest: {
                        orderNum: null,
                        custOrdNum: "",
                        accountID: this.props.accountId,
                        startDate: minDate,
                        endDate: maxDate,
                        statusID: null,
                        consignNum: "",
                        salesOrderJobStatusId: null,
                        isExcludeCancelledOrder: true,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}

            },
            loading: false,
            downloading: false,
            searchText: "",
            isCreateNewOrder: false,
            hasDebtorWritePrivilege: false
        };

        this.userService = new UserService();
        this.orderService = new OrderService();
        this.refresh = this.refresh.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.printOrderSummaryInExcel = this.printOrderSummaryInExcel.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.getColValue = this.getColValue.bind(this);


    }

    componentDidMount() {
        this.refresh();
        this.getSalesOrderJobStatuses();
        let currentUser = this.userService.getLoggedInUser();
        let isCreateNewOrder = this.userService.hasPrivilege(currentUser, "debtor-any-read");
        this.setState({ isCreateNewOrder })
    }

    componentWillReceiveProps(nextProps) {
        let { orders } = this.state;
        if (this.state.accountId !== nextProps.accountId) {
            orders.request.filterRequest.accountID = nextProps.accountId;
            let currentUser = this.userService.getLoggedInUser();
            let isCreateNewOrder = this.userService.hasPrivilege(currentUser, "debtor-any-read");
            this.setState({ isCreateNewOrder });
            this.setState({ accountId: nextProps.accountId, orders }, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        let { orders } = this.state;
        this.getOrders(orders);
        let currentUser = this.userService.getLoggedInUser();
        let hasDebtorWritePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-write');
        this.setState({ hasDebtorWritePrivilege });
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let { salesOrderJobStatuses } = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({ salesOrderJobStatuses })
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getOrders(orders) {
        if (orders.request.filterRequest.accountID) {
            this.setState({ loading: true });

            let request = cloneDeep(orders.request);
            if (request.filterRequest.startDate) {
                request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
            }
            if (request.filterRequest.endDate) {
                request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
            }

            salesCustomerOrderService.getAllCustomerOrders(request, "ALL",false).then(response => {
                orders.response = response.data;
                this.setState({ orders, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
        }
    }


    getColValue(value, storeItem, order) {

        switch (storeItem.type) {
            case "link":
                return <div className="text-left">
                    <ButtonGroup>
                        <Link
                            className="btn btn-sm btn-primary" style={{ color: "white" }}
                            title={"Click here to view order in detail"}
                            to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}>
                            {value}
                        </Link>
                        {
                            order.isRework ?
                                <Button size={"sm"} title={"Rework"}
                                    style={{ 'background-color': '#ffa500', 'color': 'white', 'border-color': '#ffa500' }}>
                                    R
                                </Button>
                                : null
                        }
                        {
                            order && order.isCustom ?
                                <Button size={"sm"} title={"Custom Order"}
                                    color={"warning"} disabled style={{ opacity: 1 }}>
                                    <i className="fa fa-wrench" />
                                </Button>
                                : null
                        }
                    </ButtonGroup>&nbsp;
                </div>;

            case "date":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            //case "ECD":
            //    return displayECD(value, "DD MMM YYYY");
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return <NumberFormat value={value}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                                     decimalScale={2}
                    prefix={'$'} />;
            case "statusByID":
                let result = "";
                switch (value) {
                    case 0:
                        result = <Badge color="primary">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 1:
                        result = <Badge color="warning">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 2:
                        result = <Badge color="warning">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 3:
                        result = <Badge color="info">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case 4:
                        result = <Badge color="success">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                    case -1:
                        result = <Badge color="danger">{DebtorInvoiceStatusByID[value]}</Badge>;
                        break;
                }
                return <div className={"text-center"}>
                    <h5>{result}</h5>
                </div>;
            case "salesOrderJobStatus":
                let color = "info";
                if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId]) {
                    if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].isOnHold) {
                        color = "warning";
                    }
                    if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].isCompleted) {
                        color = "success";
                    }
                    if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].statusDescription === "Cancelled") {
                        color = "danger";
                    }
                    if (this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].statusDescription === "Submitted"
                        || this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].statusDescription === "Review") {
                        color = "primary";
                    }
                    return <div className={"text-center"}><Badge
                        color={color}>{this.state.salesOrderJobStatuses.statusesById[order.salesOrderJobStatusId].statusDescription}</Badge>
                    </div>;
                }

                return "";
            case "productDetails":
                let stockGroups = [];
                value.map((item) => {
                    stockGroups.push(item.product + " (" + item.qty + ") ");
                });
                return stockGroups.toString();
            default:
                return <span>{value}</span>
        }
    }

    handleChange(change, key) {
        let { orders } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (orders.request.sortRequest.key === change) {
                    orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                } else {
                    orders.request.sortRequest.key = change;
                    orders.request.sortRequest.direction = false;
                }
                this.getOrders(orders);
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
            case "consignNum":
                orders.request.filterRequest[key] = change;
                this.getOrders(orders);
                break;
            case "salesOrderJobStatusId":
                orders.request.filterRequest[key] = parseInt(change);
                this.getOrders(orders);
                break;
            default:
                orders.request.filterRequest[key] = change;
                this.setState({ orders });
                this.getOrders(orders);
        }

    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.ordNum.toString().includes(searchText);

            if (!flag && item.ordTotal) {
                flag = item.ordTotal.toString().includes(searchText)
            }
            if (!flag && item.custOrdNum) {
                flag = item.custOrdNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.consignNum) {
                flag = item.consignNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.couriers) {
                flag = item.couriers.toLowerCase().includes(searchText)
            }
            if (!flag && item.ordDate) {
                flag = changeFormatOfDateString(item.ordDate, "DD/MM/YYYY", 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.shipDate) {
                flag = changeFormatOfDateString(item.shipDate, 'DD/MM/YYYY', 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    getStore(request, salesOrderJobStatuses) {
        let { pageRequest, sortRequest, filterRequest } = request;
        let store = [
            {
                key: "sNo",
                label: "S.No.",
                type: "text",
                colSpan: 1,
                minWidth: "25px",
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "ordNum",
                label: "Order No.",
                type: "link",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "ordDate",
                label: "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "custOrdNum",
                label: "Customer Order No.",
                type: "default",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "orderDetails",
                label: "Stock Group(s)",
                type: "productDetails",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "ordTotal",
                label: "Order Total",
                type: "currency",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "salesOrderJobStatusId",
                label: "Order Status",
                type: "salesOrderJobStatus",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "productionReadyDate",
                label: "ECD",
                type: "date",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "shipDate",
                label: "Shipped Date",
                type: "date",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            }
        ];

        return store;
    }

    printOrderSummaryInExcel = () => {
        let request = cloneDeep(this.state.orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }

        this.setState({ downloading: true });
        salesCustomerOrderService.GenerateOrderSummaryInExcelReport(request, "ALL").then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "ORDER-SUMMARY " + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            console.log(error.data)
        });
    };

    toggleCancelledOrder(isExcludeCancelledOrder) {
        let { orders } = this.state;
        orders.request.filterRequest.isExcludeCancelledOrder = isExcludeCancelledOrder;
        this.setState({ orders });
        this.getOrders(orders);
    }


    render() {
        let { orders, loading, searchText, downloading, isCreateNewOrder, salesOrderJobStatuses, hasDebtorWritePrivilege } = this.state;
        let { pageRequest, sortRequest, filterRequest } = orders.request;
        let store = this.getStore(orders.request, salesOrderJobStatuses);
        return (
            <div>
                <div>
                    <SalesAllCustomerOrdersFilter
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                        salesOrderJobStatuses={salesOrderJobStatuses}
                        toggleCancelledOrder={(isExcludeCancelledOrder) => this.toggleCancelledOrder(isExcludeCancelledOrder)}
                    />
                </div>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading ? <span>Loading... &nbsp;&nbsp;</span> :
                                <span>Showing
                                    {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {orders.response.totalRecords}
                                    {' '}entries &nbsp;&nbsp;
                                                        </span>
                            }
                            <span>
                                <Button className={'btn btn-outline-success btn-sm mb-2'} outline={true}
                                    onClick={this.printOrderSummaryInExcel} disabled={downloading}>Export &nbsp;
                                    {downloading
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            style={{ color: "green" }} />
                                        : <i className="fa fa-file-excel-o" aria-hidden="true" />}
                                </Button>&nbsp;&nbsp;
                                {isCreateNewOrder && hasDebtorWritePrivilege ? <Link className={"btn btn-sm btn-primary mb-2"}
                                    to={"/sales/order/create/product-builder/v1?" + queryString.stringify({ accountID: this.props.accountId })}><i
                                    className="fa fa-plus"
                                    aria-hidden="true" />&nbsp;New
                                    order</Link> : null}

                            </span>
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <InputGroup className={"mb-2"}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-search"
                                            aria-hidden="true" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"text"}
                                    name={"searchText"}
                                    placeholder={"Search..."}
                                    value={searchText}
                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                />

                            </InputGroup>
                        </div>

                    </Col>


                </Row>
                <div >

                    <Table hover bordered size={"sm"} responsive>
                        <thead>
                            <tr>
                                {(store || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                            {
                                /*<tr>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>*/
                            }
                        </thead>
                        <tbody>

                            {loading ? <Spinner className="primary"/>:
                                (orders.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, index) => {
                                return (
                                    <tr key={index}>
                                        <td key={index} className="align-middle text-center">
                                            {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                        </td>
                                        {(store || []).filter(x => x.key != "sNo").map((storeItem, index) => {
                                            return (
                                                <td key={index} className={storeItem.valueClassName}>
                                                    {this.getColValue(order[storeItem.key], storeItem, order)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>

                    </Table>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-left"} style={{ maxWidth: 200 }}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Show</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"select"}
                                        name={"pageSize"}
                                        value={pageRequest.pageSize}
                                        disabled={loading}
                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                        <option value={10}>10 Rows</option>
                                        <option value={25}>25 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>


                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"float-right"}>
                                <Pagination
                                    activePage={pageRequest.currentPage}
                                    itemsCountPerPage={pageRequest.pageSize}
                                    totalItemsCount={orders.response.totalRecords}
                                    pageRangeDisplayed={3}
                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'
                                />
                            </div>

                        </Col>
                    </Row>
                </div>


                {/* <Nav className={"flex-column flex-sm-row"} tabs>
                            {tabs.map((tab, index) =>
                                <NavItem
                                    className={"flex-sm-fill text-sm-center hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab.key === tab.key })}
                                        onClick={() => {
                                            this.toggle(tab);
                                        }}>
                                        {tab.label}&nbsp;
                                        {
                                            (activeTab.key === tab.key && loading)
                                                ? <Spinner size="sm" color={"primary"} className={"ml-2"} />
                                                : <span className={"ml-2 p-2"} />
                                        }
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>*/}


            </div>
        );
    }
}