import React, {Component} from 'react';
import {Card, Col, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row} from 'reactstrap';
import {NavLink as RouterNavLink} from "react-router-dom";
import UserService from './../../../services/UserService';
import CustomerService from './../../../services/CustomerService';
import {toast} from 'react-toastify';
import queryString from 'query-string';
import {handleErrorMessage} from "../../../services/CommonService";
import {isRetailBoardVisible} from '../../../services/CommonFunctions';


export default class CustomerDashboardQuickLinks extends Component {
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            accountId: "",
            company: "",
            design: "4",
            role: "",
            retailVisible: false
        };
        this.userService = new UserService();
        this.customerService = new CustomerService();

    }


    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    componentDidMount() {
        let accountId = this.userService.getLoggedInUserAccountID();
        this.userService.getUser().then(user => {
            let role = user.menu.role;
            this.setState({role, accountId}, () => {
                this.refresh(accountId);
            });
        })
    }

    refresh(accountId) {
        this.handleDebtorAccountDetails(accountId);
        this.isRetailBoardVisible();
    }

    isRetailBoardVisible() {
        isRetailBoardVisible(result => {
            if (result.status !== 200) {
                toast.error(handleErrorMessage(result));
            } else {
                this.setState({retailVisible: result.data});
            }
        });
    }

    handleDebtorAccountDetails(accountId) {
        if (accountId) {
            this.customerService.getCompanyName(accountId).then(response => {
                if (response.data) {
                    this.setState({company: response.data});
                }
            }).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        }
    }

    render() {
        if (this.state.design === "1") {
            return (
                <Row>
                    <Col xl={2} lg={2} md={2} sm={3} xs={6}>
                        <RouterNavLink
                            exact
                            style={{textDecoration: "none"}}
                            to={"/company/myorders"}
                        >
                            <Card className={"ds-9"}>
                                <div className={"p-2 text-center"}>
                                    <div>
                                        <i className="fa fa-lg fa-file-text-o" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <h6 className="mt-1" aria-hidden="true">Orders</h6>
                                    </div>
                                </div>
                            </Card>
                        </RouterNavLink>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={3} xs={6}>
                        <RouterNavLink
                            exact
                            style={{textDecoration: "none"}}
                            to={"/sales/order/create"}
                        >
                            <Card className={"ds-9"}>
                                <div className={"p-2 text-center"}>
                                    <div>
                                        <i className="fa fa-lg fa-cart-plus mr-1" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <h6 className="mt-1" aria-hidden="true">New order/quote</h6>
                                    </div>
                                </div>
                            </Card>
                        </RouterNavLink>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={3} xs={6}>

                        <RouterNavLink
                            exact
                            style={{textDecoration: "none"}}
                            to={'/company/stats?' + queryString.stringify({accountID: this.state.accountId})}>
                            <Card className={"ds-9"}>
                                <div className={"p-2 text-center"}>
                                    <div>
                                        <i className="fa fa-lg fa-pie-chart mr-1" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <h6 className="mt-1" aria-hidden="true">Dashboard</h6>
                                    </div>
                                </div>
                            </Card>
                        </RouterNavLink>

                    </Col>
                </Row>
            )
        }

        if (this.state.design === "2") {
            return (
                <div>
                    <Navbar color={"white"} light expand="md">
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                        <Collapse isOpen={!this.state.collapsed} navbar>
                            <Nav navbar>
                                <NavItem className={"hoverableItem mr-2"}>
                                    <NavLink>
                                        <h6 className={"mb-0"}>

                                            <RouterNavLink
                                                exact
                                                activeClassName="navbar__link--active"
                                                style={{textDecoration: "none"}}
                                                to={"/company/myorders"}
                                            >
                                                <i className="fa fa-file-text-o mr-1" aria-hidden="true"/>
                                                Orders
                                            </RouterNavLink>
                                        </h6>
                                    </NavLink>
                                </NavItem>
                                <NavItem className={"hoverableItem mr-2"}>
                                    <NavLink>
                                        <h6>
                                            <RouterNavLink
                                                exact
                                                activeClassName="navbar__link--active"
                                                style={{textDecoration: "none"}}
                                                to={"/sales/order/create"}
                                            >
                                                <i className="fa  fa-cart-plus mr-1" aria-hidden="true"/>
                                                New order/quote

                                            </RouterNavLink></h6>
                                    </NavLink>
                                </NavItem>
                                <NavItem className={"hoverableItem"}>
                                    <NavLink>
                                        <h6>
                                            <RouterNavLink
                                                exact
                                                activeClassName="navbar__link--active"
                                                style={{textDecoration: "none"}}
                                                to={'/company/stats?' + queryString.stringify({accountID: this.state.accountId})}
                                            >

                                                <i className="fa fa-pie-chart mr-1" aria-hidden="true"/>
                                                Dashboard
                                            </RouterNavLink></h6>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                        <NavbarBrand tag={"span"} className={"mb-0 h2"}>{this.state.company}</NavbarBrand>
                    </Navbar>
                </div>)
        }

        if (this.state.design === "3") {
            return (
                <Nav className={"justify-content-center"}>
                    <NavItem>
                        <RouterNavLink
                            exact
                            style={{textDecoration: "none"}}
                            to={"/company/myorders"}
                        >
                            <Card className={"ds-9"} style={{minWidth: 150, maxWidth: 200}}>
                                <div className={"p-2 text-center"}>
                                    <div>
                                        <i className="fa fa-lg fa-file-text-o" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <h6 className="mt-1" aria-hidden="true">Orders</h6>
                                    </div>
                                </div>
                            </Card>
                        </RouterNavLink>
                    </NavItem>
                    <NavItem className={"ml-2"}>
                        <RouterNavLink
                            exact
                            style={{textDecoration: "none"}}
                            to={"/sales/order/create"}
                        >
                            <Card className={"ds-9"} style={{minWidth: 150, maxWidth: 200}}>
                                <div className={"p-2 text-center"}>
                                    <div>
                                        <i className="fa fa-lg fa-cart-plus mr-1" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <h6 className="mt-1" aria-hidden="true">New order/quote</h6>
                                    </div>
                                </div>
                            </Card>
                        </RouterNavLink>
                    </NavItem>
                    <NavItem className={"ml-2"}>
                        <RouterNavLink
                            exact
                            style={{textDecoration: "none"}}
                            to={'/company/stats?' + queryString.stringify({accountID: this.state.accountId})}
                        >
                            <Card className={"ds-9"} style={{minWidth: 150, maxWidth: 200}}>
                                <div className={"p-2 text-center"}>
                                    <div>
                                        <i className="fa fa-lg fa-pie-chart mr-1" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <h6 className="mt-1" aria-hidden="true">Dashboard</h6>
                                    </div>
                                </div>
                            </Card>
                        </RouterNavLink>
                    </NavItem>
                </Nav>
            )
        }

        if (this.state.design === "4") {

            return (
                <Navbar light expand="md" className={"rounded p-0"}>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav navbar>

                            <NavItem className={"col-auto mx-auto pl-0"}>
                                <RouterNavLink
                                    exact
                                    style={{textDecoration: "none"}}
                                    to={"/company/myorders"}
                                >
                                    <Card className={"ds-9 hoverableItem"} style={{minWidth: 150, maxWidth: 200}}>
                                        <div className={"p-2 text-center"}>
                                            <div>
                                                <i className="fa fa-lg fa-file-text-o" aria-hidden="true"/>
                                            </div>
                                            <div>
                                                <h6 className="mt-1" aria-hidden="true">Orders</h6>
                                            </div>
                                        </div>
                                    </Card>
                                </RouterNavLink>
                            </NavItem>
                            <NavItem className={"col-auto mx-auto pl-0"}>
                                <RouterNavLink
                                    exact
                                    style={{textDecoration: "none"}}
                                    to={"/sales/order/create/product-builder/v1"}
                                >
                                    <Card className={"ds-9 hoverableItem"} style={{minWidth: 150, maxWidth: 200}}>
                                        <div className={"p-2 text-center"}>
                                            <div>
                                                <i className="fa fa-lg fa-cart-plus mr-1" aria-hidden="true"/>
                                            </div>
                                            <div>
                                                <h6 className="mt-1" aria-hidden="true">New order/quote</h6>
                                            </div>
                                        </div>
                                    </Card>
                                </RouterNavLink>
                            </NavItem>
                            {
                                (this.state.role === "ExternalAdmin") ?
                                    <NavItem className={"col-auto mx-auto pl-0"}>
                                        <RouterNavLink
                                            exact
                                            style={{textDecoration: "none"}}
                                            to={'/company/stats?' + queryString.stringify({accountID: this.state.accountId})}
                                        >
                                            <Card className={"ds-9 hoverableItem"}
                                                  style={{minWidth: 150, maxWidth: 200}}>
                                                <div className={"p-2 text-center"}>
                                                    <div>
                                                        <i className="fa fa-lg fa-pie-chart mr-1" aria-hidden="true"/>
                                                    </div>
                                                    <div>
                                                        <h6 className="mt-1" aria-hidden="true">Dashboard</h6>
                                                    </div>
                                                </div>
                                            </Card>
                                        </RouterNavLink>
                                    </NavItem>
                                    : null}
                            {/*// need to check for IsRetail... in json*/}
                            {(this.state.retailVisible && this.state.role!=='ExternalSalesRep') ?
                                <NavItem className={"col-auto mx-auto pl-0"}>
                                    <RouterNavLink
                                        exact
                                        style={{textDecoration: "none"}}
                                        to={"/company/retail"}>
                                        <Card className={"ds-9 hoverableItem"} style={{minWidth: 150, maxWidth: 200}}>
                                            <div className={"p-2 text-center"}>
                                                <div>
                                                    <i className="fa fa-lg fa-shopping-bag" aria-hidden="true"/>
                                                </div>
                                                <div>
                                                    <h6 className="mt-1" aria-hidden="true">Retail</h6>
                                                </div>
                                            </div>
                                        </Card>
                                    </RouterNavLink>
                                </NavItem>
                                : null
                            }
                        </Nav>
                    </Collapse>
                    <NavbarBrand tag={"span"} className={"mb-0 h2"}>{this.state.company}</NavbarBrand>
                </Navbar>
            )
        }


        return null;

    }
}