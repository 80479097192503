import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import UserService from '../../services/UserService';
import { handleErrorMessage, DebtorEmailContent, getDistinctValues } from '../../services/CommonService';
import ComposeEmail from "../modal/ComposeEmail";
import OrderService from '../../services/OrderService';
import SalesOrderService from '../../services/sales/SalesOrderService';

export default class SalesOrderShareWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
            emailMessage: {},
            isLoading: false,
            currentUser: {}
        };
        this.userService = new UserService();
        this.orderService = new OrderService();
        this.salesOrderService = new SalesOrderService();

        this.getCustomerOrderDetail = this.getCustomerOrderDetail.bind(this);
        this.sendEmailModal = this.sendEmailModal.bind(this);
        this.setEmailMessageBody = this.setEmailMessageBody.bind(this);
        this.getSalesOrderAttachmentID = this.getSalesOrderAttachmentID.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        let { ordNum } = this.props;
        this.setState({ currentUser });
        if (ordNum) {
            this.getCustomerOrderDetail(ordNum);
            this.getSalesOrderAttachmentID(ordNum);
        }
    }

    getCustomerOrderDetail(ordNum) {
            this.setState({ isLoading: true });
            this.salesOrderService.getSalesOrderKeywayByDebtorInvoiceOrderNum(ordNum).then(response => {
                let order = response.data;
                this.setState({ order }, () => {
                    if (!order) {
                        toast.success("Order doesn't exists!");
                    }
                });
            }).catch(error => {
                toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            })
    }

    getSalesOrderAttachmentID(ordNum) {
        this.salesOrderService.getSalesOrderAttachmentID(ordNum).then(response => {
            let attachmentID = response.data;
            this.setEmailMessageBody(attachmentID);
            this.setState({ attachmentID,isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    setEmailMessageBody(attachmentID) {
        let { documentType, ordNum} = this.props;
        let { currentUser,emailMessage, order } = this.state;
        let fullName = currentUser.firstName;
        if (currentUser.lastName) {
            fullName = fullName + " " + currentUser.lastName;
        }
        emailMessage = DebtorEmailContent(documentType, order);
        emailMessage.senderName = fullName;
        //emailMessage.body = emailMessage.body.replace('@FullName', fullName);
        emailMessage.subject = emailMessage.subject.replace('@OrdNum', ordNum);
        emailMessage.attachmentIDs = attachmentID;
        let toEmail = order.isRetail ? order.retailEmail : order.email;
        let fromEmail = order.isRetail ? order.email : "keyway@windoware.com.au";

        emailMessage.to = [{ label: toEmail, value: toEmail }];
        emailMessage.toOptions = [{ label: toEmail, value: toEmail }];
        emailMessage.from = [{ label: fromEmail, value: fromEmail }];
        emailMessage.fromOptions = [{ label: fromEmail, value: fromEmail }];
        emailMessage.isLoadingEmailSend = false;
        emailMessage.id = ordNum;
        this.setState({ emailMessage });
    }

    sendEmailModal(emailMessage) {
        let { ordNum } = this.props;
        emailMessage.isLoadingEmailSend = true;
        this.setState({ emailMessage });
        if (emailMessage) {
            emailMessage.toEmails = getDistinctValues(emailMessage.to ? emailMessage.to : [], 'value');
            emailMessage.fromEmail = emailMessage.from[0].value;
            emailMessage.ccs = getDistinctValues(emailMessage.cc ? emailMessage.cc : [], 'value');
            emailMessage.bccs = getDistinctValues(emailMessage.bcc ? emailMessage.bcc : [], 'value');
            emailMessage.body = emailMessage.body.replaceAll('\n', '<br/>');
            this.orderService.shareDebtorInvoiceOnEmail(ordNum, emailMessage).then(response => {
                emailMessage.isLoadingEmailSend = false;
                this.setState({ emailMessage });
                toast.success("Email Send!");
                this.props.toggle(false);
            }).catch(error => {
                emailMessage.isLoadingEmailSend = false;
                this.setState({ emailMessage });
                toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            });
        }
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { emailMessage, isLoading } = this.state;
        return (
                    <ComposeEmail
                        isOpen={isOpen}
                        isSendingEmail={emailMessage.isLoadingEmailSend}
                        toggle={toggle}
                        message={emailMessage}
                        handleSubmit={this.sendEmailModal}
                        parentType={"Share"}
                        isLoadingData={isLoading}
                    />
        );
    }
}