import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Row, Table, Input, Label, Spinner } from "reactstrap";
import SearchDebtorAcccount from '../../../components/search/SearchDebtorAcccount';
import SearchState from '../../../components/search/SearchState';
import SearchPostcode from '../../../components/search/SearchPostcode';
import SearchCity from '../../../components/search/SearchCity';

//
export default class CustomerAccountsDetailsFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        let { filterRequest, isHiddenExtendedColumn, isIncludeChildren, excludeClosedOrInactive } = this.props;

        return (
            <div>
                <Row>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Label>Account</Label>
                        <div> <SearchDebtorAcccount
                            handleAccountChange={this.props.handleAccountChange}
                            excludeClosedandInactive={excludeClosedOrInactive}
                            includeChildren={isIncludeChildren}
                            selectedAccountID={filterRequest.accountId} />
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={3} sm={6} xs={12}>
                        <Label>Customer Category</Label>
                        <div>
                            <Input type={"select"}
                                placeholder={"Search Category..."}
                                name={"debtorCategoryID"}
                                value={filterRequest.debtorCategoryID || ''}
                                onChange={(e) => this.props.handleChange(e.target.value, "debtorCategoryID")}>
                                <option value="ALL">All</option>
                                {this.props.debtorCategory.map((category, index) =>
                                    <option key={index} value={category.debtorCategoryID}>{category.name}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={3} sm={6} xs={12}>
                        <Label>State</Label>
                        <div>
                            <Input type={"select"}
                                   placeholder={"Search state..."}
                                   name={"state"}
                                   value={filterRequest.state || ''}
                                   onChange={(e) => this.props.handleChange(e.target.value, "state")}>
                                <option value="">All</option>
                                {this.props.stateOption.map((state, index) =>
                                    <option key={index} value={state}>{state}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Label>RepCode </Label>
                        <div>
                            <Input type={"select"}
                                placeholder={"Search RepCode..."}
                                name={"repCode"}
                                value={filterRequest.repCode || ''}
                                onChange={(e) => this.props.handleChange(e.target.value, "repCode")} >
                                <option value="ALL">All</option>
                                {this.props.reps.map((rep, index) =>
                                    <option key={index} value={rep.repCode}>{rep.name}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={3} sm={6} xs={12}>
                        <Label>Account Status</Label>
                        <div>
                            <Input type={"select"}
                                placeholder={"Search..."}
                                name={"accountStatus"}
                                value={filterRequest.accountStatus || ''}
                                onChange={(e) => this.props.handleChange(e.target.value, "accountStatus")} >
                                
                                <option value="ALL">All</option>
                                {this.props.debtorAccountStatus.map((status, index) =>
                                    <option key={index} value={status.description}> {status.description} </option>
                                )}
                                
                            </Input>
                        </div>
                    </Col>
                </Row>

                {/*<Row>
                    <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Label>Company</Label>
                        <div> <Input type="text" id="company" name="company" value={filterRequest.company}
                            onChange={(e) => this.props.handleChange(e.target.value, "company")}
                            placeholder="Search Company..." /></div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Label>State</Label>
                        <div>
                            <SearchState
                                handleSelection={(selectedState) => this.props.handleChange(selectedState, "state")}
                                selected={filterRequest.state || ''}
                                defaultSelected={''}
                                filters={{ city: filterRequest.city || '', postcode: filterRequest.postCode || '' }}
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Label>Payment Terms</Label>
                        <div>
                            <Input type={"select"}
                                placeholder={"Search Payment Terms..."}
                                name={"paymentTermsID"}
                                value={filterRequest.paymentTermsID || ''}
                                onChange={(e) => this.props.handleChange(e.target.value, "paymentTermsID")} >
                                <option value="ALL">All</option>
                                {this.props.debtorPaymentTerms.map((term, index) =>
                                    <option key={index} value={term.paymentTermsID}>{term.description}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                      <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Label>Address</Label>
                          <div> <Input type="text" id="address" name="address"
                              value={filterRequest.address}
                              onChange={(e) => this.props.handleChange(e.target.value, "address")}
                              placeholder="Search Address..." /></div>
                      </Col>
                      <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Label>City</Label>
                          <div>
                              <SearchCity
                                  handleSelection={(selectedCity) => this.props.handleChange(selectedCity, "city")}
                                  selected={filterRequest.city || ''}
                                  defaultSelected={''}
                                  filters={{ state: filterRequest.state || '', postcode: filterRequest.postCode || '' }}
                              />
                          </div>
                      </Col>
                      
                      <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Label>Postcode</Label>
                          <div>
                              <SearchPostcode
                                  handleSelection={(selectedPostcode) => this.props.handleChange(selectedPostcode, "postCode")}
                                  selected={filterRequest.postCode || ''}
                                  defaultSelected={''}
                                  filters={{ city: filterRequest.city || '', state: filterRequest.state || '' }}
                              />
                          </div>
                      </Col>
                      <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Label>Phone</Label>
                          <div>
                              <Input type={"text"}
                                  placeholder={"Search Phone..."}
                                  name={"phone"}
                                  value={filterRequest.phone || ''}
                                  onChange={(e) => this.props.handleChange(e.target.value, "phone")} />
                          </div>
                      </Col>
                      <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Label>ABN</Label>
                          <div>
                              <Input type={"text"}
                                  placeholder={"Search ABN..."}
                                  name={"aBN"}
                                  value={filterRequest.aBN || ''}
                                  onChange={(e) => this.props.handleChange(e.target.value, "aBN")} />
                          </div>
                      </Col>
                </Row>*/}

            </div>
        );
    }
}
