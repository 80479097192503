import React, {Component} from 'react';
import {Col, Row, Card, CardHeader, CardBody, Table, Badge} from "reactstrap";

import {Link} from "react-router-dom";
import CustomerService from "../../services/CustomerService";
import NumberFormat from "react-number-format";
import {isEmpty} from '../../services/CommonService';

export default class AccountCard extends Component {
    constructor(props) {
        super(props);
        this.customerService = new CustomerService();
        this.state = {
            loading: true,
            stats: {
                gold: 0,
                silver: 0,
                blue: 0
            }
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.customerService.getCustomersByCategoryStat().then(response => {
            let {stats} = this.state;
            stats.gold = isEmpty(response.data["gold"], "primitive") ? 0 : response.data["gold"];
            stats.silver = isEmpty(response.data[""], "primitive") ? 0 : response.data["gold"];
            stats.blue = isEmpty(response.data["blue"], "primitive") ? 0 : response.data["gold"];


            this.setState({loading: false, stats: response.data})
        }).catch(error => {
            console.log(error);
            this.setState({loading: false})
        });
    }


    render() {

        const {stats, loading} = this.state;

        if (loading) {
            return null;
        }

        return (
            <Col xl={3} lg={5} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Customers
                            <span style={{float: "right"}}>
                            <Link to="/customer">
                        <i className={"fa fa-pencil"}/>
                            </Link>
                        </span></h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} className={"mb-0"}>
                            <tbody>
                            <tr>
                                <td><Badge color={"warning"}>Gold</Badge></td>
                                <td className={"text-right"}><h5><NumberFormat value={stats.gold}
                                                                               displayType={'text'}
                                                                               thousandSeparator={true}/></h5></td>
                            </tr>
                            <tr>
                                <td><Badge color={"secondary"}>Silver</Badge></td>
                                <td className={"text-right"}><h5><NumberFormat value={stats.silver}
                                                                               displayType={'text'}
                                                                               thousandSeparator={true}/></h5></td>
                            </tr>
                            <tr>
                                <td><Badge color={"primary"}>Blue</Badge></td>
                                <td className={"text-right"}><h5><NumberFormat value={stats.blue}
                                                                               displayType={'text'}
                                                                               thousandSeparator={true}/></h5></td>
                            </tr>
                            <tr className="bg-light">
                                <td>Total</td>
                                <td className={"text-right"}><h5><NumberFormat
                                    value={stats.gold + stats.silver + stats.blue}
                                    displayType={'text'}
                                    thousandSeparator={true}/></h5></td>
                            </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        );

    }
}