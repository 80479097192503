import React, {Component} from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardBody,
    CardHeader,
    Badge,
} from 'reactstrap';
import classnames from 'classnames';

import SalesOrderReviewService from "../../../services/sales/SalesOrderReviewService";
import {findIndex, handleErrorMessage} from '../../../services/CommonService';

import ReviewOrderTable from "../review/ReviewOrderTable";

export default class ReviewOrders extends Component {

    constructor(props) {
        super(props);
        let tabs = [
            {
                key: "Credit-Hold",
                label: "Orders On Credit Hold",
                listItemsCount: 0
            },
            {
                key: "Pick-Hold",
                label: "Orders On Pick Hold",
                listItemsCount: 0
            }];

        this.state = {
            accountID: props.accountID,
            activeTab: "Credit-Hold",
            ordersOnCreditHold: {
                isLoading: false,
                count: 0,
                value: 0,
                list: []
            },
            ordersOnPickHold: {
                isLoading: false,
                count: 0,
                value: 0,
                list: []
            },
            tabs: tabs
        };

        this.salesOrderReviewService = new SalesOrderReviewService();
        this.getOrdersOnCreditHold = this.getOrdersOnCreditHold.bind(this);
        this.getOrdersOnPickHold = this.getOrdersOnPickHold.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, function () {
                this.refresh();
            });
        }
    }


    refresh() {
        this.getOrdersOnCreditHold();
        this.getOrdersOnPickHold();
    }

    getOrdersOnCreditHold() {
        let {ordersOnCreditHold, accountID, tabs} = this.state;
        ordersOnCreditHold.isLoading = true;
        this.setState({ordersOnCreditHold});
        this.salesOrderReviewService.fetchSalesOrdersOnCreditHold(accountID).then(response => {
            ordersOnCreditHold.list = response.data;
            ordersOnCreditHold.isLoading = false;
            let index = findIndex(tabs, "key", "Credit-Hold");
            if (index > -1) {
                tabs[index].listItemsCount = ordersOnCreditHold.list.length;
            }
            this.setState({ordersOnCreditHold});
        }).catch(error => {
            alert(handleErrorMessage(error));
            ordersOnCreditHold.isLoading = false;
            this.setState({ordersOnCreditHold});
        });
    }

    getOrdersOnPickHold() {
        let {ordersOnPickHold, accountID, tabs} = this.state;
        ordersOnPickHold.isLoading = true;
        this.setState({ordersOnPickHold});
        this.salesOrderReviewService.fetchSalesOrdersOnPickHold(accountID).then(response => {
            ordersOnPickHold.list = response.data;
            ordersOnPickHold.isLoading = false;
            let index = findIndex(tabs, "key", "Pick-Hold");
            if (index > -1) {
                tabs[index].listItemsCount = ordersOnPickHold.list.length;
            }
            this.setState({ordersOnPickHold});
        }).catch(error => {
            alert(handleErrorMessage(error));
            ordersOnPickHold.isLoading = false;
            this.setState({ordersOnPickHold});
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        let {ordersOnCreditHold, ordersOnPickHold, activeTab, tabs} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <Nav className={"flex-column flex-sm-row"} tabs>
                            {tabs.map((tab, index) =>
                                <NavItem
                                    className={"flex-sm-fill text-sm-center hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        {tab.label}&nbsp;
                                        <Badge
                                            hidden={tab.listItemsCount === 0}>{tab.listItemsCount}</Badge>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <br/>
                            <TabPane tabId="Credit-Hold">
                                <div>
                                    <ReviewOrderTable orders={ordersOnCreditHold.list}
                                                      isLoading={ordersOnCreditHold.isLoading}/>
                                </div>
                            </TabPane>
                            <TabPane tabId="Pick-Hold">
                                <div>
                                    <ReviewOrderTable orders={ordersOnPickHold.list}
                                                      isLoading={ordersOnPickHold.isLoading}/>
                                </div>
                            </TabPane>
                        </TabContent>

                    </CardBody>
                </Card>
            </div>);

    }
}
