import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import WarehouseService from '../../../services/WINOInventory/WarehouseService';
import WarehouseAddress from '../../../components/WINOInventory/Warehouse/WarehouseAddress';
import WarehouseConatct from '../../../components/WINOInventory/Warehouse/WarehouseContact';
import {handleErrorMessage} from "../../../services/CommonService";


export default class WINOManageWarehouse extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseID: '',
            warehouseObj: {
                id: 0,
                name: '',
                code: '',
                isActive: true,
                address: {
                    addressID: 0,
                    address1: '',
                    address2: '',
                    address3: '',
                    address4: '',
                    city: '',
                    state: '',
                    postCode: '',
                    phone: '',
                    fax: '',
                    country: 'AUSTRALIA',
                    contactName: '',
                },
                contact: {
                    contactID: 0,
                    contactTypeID: 0,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    fax: '',
                    mobile: '',
                    email: '',
                    isPrimary: true
                }
            },
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);

    }

    componentDidMount() {
        let { warehouseID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            warehouseID = searchParams.warehouseID;
            this.setState({ warehouseID }, () => { this.refresh() })
        }

    }

    refresh() {
        if (this.state.warehouseID) {
            this.setState({ loading: true })

            WarehouseService.getOneWarehouse(this.state.warehouseID).then(response => {
                this.setState({ warehouseObj: response.data, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { warehouseObj } = this.state;

        warehouseObj[name] = value;
        this.setState({ warehouseObj });
    }

    handleCancel() {
        this.props.history.push('/keyway-inventory/warehouses');
    }

    validateEmail(email) {
        if (email.length === 0)
            return true;
        let regex = /^([a-zA-Z0-9_\.\-\+])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    validateForm(warehouseObj) {
        let formErrors = {};
        let valid = true;

        if (!warehouseObj.name) {
            formErrors.name = "Please enter name";
            valid = false;
        }

        if (!warehouseObj.code) {
            formErrors.code = "Please enter code";
            valid = false;
        }

        if (!warehouseObj.address.contactName) {
            formErrors.contactName = "Please enter contact name";
            valid = false;
        }

        if ((!warehouseObj.address.contactName) && warehouseObj.address.contactName.length < 3) {
            formErrors.contactName = "Contact Person Name must be 3 character long";
            valid = false;
        }

        if ((!warehouseObj.address.phone) && warehouseObj.address.phone.length < 8) {
            formErrors.phone = "The Phone number must be of at least 8 characters";
            valid = false;
        }

        if (!warehouseObj.address.address1) {
            formErrors.address1 = "Please enter address1";
            valid = false;
        }

        if (!warehouseObj.contact.contactTypeID) {
            formErrors.contactTypeID = "Please select contact type";
            valid = false;
        }

        if (!warehouseObj.contact.firstName) {
            formErrors.firstName = "Please enter first name";
            valid = false;
        }

        if (!warehouseObj.contact.email) {
            let isValidEmail = this.validateEmail(warehouseObj.contact.email);
            if (!isValidEmail) {
                formErrors.email = "Please enter valid email";
                valid = false;
            }
        }

        if (!warehouseObj.contact.mobile) {
            if (warehouseObj.contact.mobile.length < 8) {
                formErrors.mobile = "Please enter valid mobile no"
                valid = false;
            }
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.warehouseObj };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });

            WarehouseService.saveOrUpdateWarehouse(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.warehouseID) {
                        this.props.history.push('/keyway-inventory/warehouses');
                        toast.success("Warehouse saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Warehouse updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }

                    this.setState({ saving: false, warehouseObj: response.data });
                }

            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            });
        }
    }

    handleAddressChange(data) {
        this.setState({ warehouseObj: data });
    }

    handleContactChange(data) {
        this.setState({ warehouseObj: data });
    }

    render() {
        let { warehouseObj, formErrors, loading, warehouseID } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/keyway-inventory/warehouses')}>Warehouse</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!warehouseID ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Warehouse</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input
                                                invalid={formErrors.name !== undefined}
                                                name="name"
                                                type="text"
                                                value={warehouseObj.name || ''}
                                                onChange={this.handleChange}
                                                placeholder="write name here..." />
                                            <FormText color="danger">{formErrors.name}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Code</Label>
                                            <Input
                                                invalid={formErrors.code !== undefined}
                                                type="text"
                                                name="code"
                                                value={this.state.warehouseObj.code || ''}
                                                onChange={(e) => this.handleChange(e)}
                                                placeholder="write code here..." />

                                            <FormText color="danger">{formErrors.code}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <hr />
                                <h6>Warehouse Address</h6>
                                <WarehouseAddress warehouseObj={warehouseObj} formErrors={formErrors} handleAddressChange={this.handleAddressChange} />

                                <hr />
                                <h6>Warehouse Contact</h6>
                                <WarehouseConatct warehouseObj={warehouseObj} formErrors={formErrors} handleContactChange={this.handleContactChange} />


                            </Form>

                        }
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving} >

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
