import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter, FormText,
    Button,
    Row, Col, Label, FormGroup, Input, Spinner
} from 'reactstrap';
import {
    toast, ToastContainer
} from "react-toastify";
import { handleErrorMessage,  getDateString } from "../../services/CommonService";
import SignaturePad from 'react-signature-canvas'
import DatePicker from "react-datepicker";
import dispatchOrderService from '../../services/DispatchOrderService';
import { cloneDeep } from 'lodash';

export default class CustomerCollectPickupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingSubmit: false,
            order: {},
            trimmedDataURL: null,
            formErrors: {},
            isShowSignPad:false
        };
        this.sigPad = { };
        this.handleChange = this.handleChange.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.trim = this.trim.bind(this);
        this.clear =this.clear.bind(this);
        this.openSignPadBlock = this.openSignPadBlock.bind(this);
        this.getDefaultPickupinfo = this.getDefaultPickupinfo.bind(this);
    }

    componentDidMount() {
        if (this.props.selectedOrder) {
            let order = cloneDeep(this.props.selectedOrder);
            this.setState({ order });
            this.getDefaultPickupinfo(order);
        }
    }

    getDefaultPickupinfo(order) {
        order.pickupDate = new Date();
        order.contactName = "";
        this.setState({ order });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.order !== nextProps.selectedOrder) {
            let order = cloneDeep(nextProps.selectedOrder);
            this.setState({ order });
            this.getDefaultPickupinfo(order);
        }
    }

    handleChange(key, change) {
        let { order } = this.state;
        switch (key) {
            default:
                order[key] = change;
        }
        this.setState({ order });
    }

     createFileObj(trimmedDataURL) {
      const file=  fetch(trimmedDataURL).then((res) => res.blob()).then((myBlob) => {
          myBlob.name = 'image.png';
          myBlob.lastModified = new Date();
         return new File([myBlob], 'image.png', {
                type: myBlob.type,
          });
            });
        return file;
    }

    async submitOrder() {
        let { trimmedDataURL, order } = this.state;
        if (trimmedDataURL === null) {
            toast.info("Please add signature");
            return false;
        }
        if (!order.contactName) {
            toast.info("please enter contact name");
            return false;
        }
        this.setState({ isLoadingSubmit: true });
        let data = new FormData();
        let signatureImageFile = await this.createFileObj(trimmedDataURL);
        data.append('shipmentID', order.shipmentID);
        data.append('signatureImage', signatureImageFile);
        data.append('ordNum', order.ordNum);
        data.append('receivedByName', order.contactName);
        data.append('customerPhone', order.phone);
        data.append('receivedDate', getDateString(order.pickupDate, "DD/MM/YYYY HH:mm:ss"));
        dispatchOrderService.saveCustomerCollectPickupDetail(data).then(response => {
            this.setState({ isLoadingSubmit: false });
            toast.success('Shipped!');
            this.props.toggle(false);
            this.props.refreshAgain();
        }).catch(error => {
            this.setState({ isLoadingSubmit: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

     clear(){
         this.sigPad.clear();
    }

    trim() {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png'), isShowSignPad: false});
    }

    openSignPadBlock() {
        this.setState({ isShowSignPad: true });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { isLoadingSubmit, order, formErrors, isShowSignPad, trimmedDataURL } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    #{order.ordNum}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="contactName">Contact Name*</Label>
                                <Input type="text" name="contactName"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={order.contactName}
                                    placeholder="Enter contact name here" />
                                <FormText color="danger">{formErrors.contactName}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input type="search" name="phone"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={order.phone}
                                    placeholder="Enter phone here" />
                                <FormText color="danger">{formErrors.phone}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="pickupDate">Pickup Date</Label>
                                <DatePicker 
                                    className="form-control form-control"
                                    selected={order.pickupDate}
                                    onChange={date => this.handleChange("pickupDate",date)}
                                    selectsStart
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="pickupDate"
                                    showMonthDropdown
                                    showYearDropdown
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </FormGroup>
                        </Col>
                    {isShowSignPad ?

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="sigCanvas">Signature</Label>
                                    <SignaturePad canvasProps={{ width: 450, height: 200, className: 'sigCanvas' }} style= {{border:'1px solid black'}}
                                        ref={(ref) => { this.sigPad = ref }} />
                                </FormGroup>
                            </Col>
                        : 
                            trimmedDataURL
                            ?
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                        <Label for="sigCanvas">Signature</Label>
                                        <img src={trimmedDataURL} style={{ width: '100px', height: '100px', display:'block' }} />
                                </FormGroup>
                            </Col>
                            : null
                        }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        {isShowSignPad ?
                                       <span>
                                           <Button color={"primary"} onClick={this.clear} size="sm">
                                            <i className="fa fa-eraser mr-2" /> Clear
                                           </Button>
                                            <Button color={"primary"} onClick={this.trim} className={"ml-2"} size="sm">
                                            <i className="fa fa-floppy-o mr-2" />Trim
                                            </Button>
                                       </span> :
                                            <Button color={"primary"} size="sm" onClick={this.openSignPadBlock}>
                                            {trimmedDataURL ? <i className="fa fa-pencil mr-2" /> : <i className="fa fa-plus mr-2" />}
                                            {trimmedDataURL ? 'Update' : 'Add'} Signature</Button>
                        }
                        {trimmedDataURL && !isShowSignPad ? <Button color={"primary"} size="sm" onClick={this.submitOrder} disabled={isLoadingSubmit} className={"ml-2"} >
                            {isLoadingSubmit
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            Save</Button> : null}
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}