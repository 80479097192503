import axiosFileDownloader from './axiosFileDownloader';
import axios from './axios';

export default class FaqService {

    getFaqByName(name) {
        return axiosFileDownloader.get('api/file/Generate/FAQFile?FileName=' + name);
    }

    fetchPreviosFAQsUploads(Id) {
        return axios.get('api/faq/getSnaps?FaqId=' + Id);
    }

    fetchPreviousFAQsLinks(Id)
    {
        return axios.get('api/faq/getLinks?FaqId=' +Id);
    }

    fetchCustomerViewFaqs(Id) {
        return axios.get('api/faq/CustomerView');
    }

    AddFAQInformation(data) {
        return axios.post('api/faq/Add/FaqInfo', data);
    }

    AddLinkAttachment(data){
        return axios.post('api/faq/Add/docLink',data);
    }

    getLinkContentTypes(){
        return axios.get('api/faq/get/LinkContentTypes')
    }

    DeleteFaqFiles(attachmentID, FaqId) {
        return axios.get('api/faq/Delete/FaqDoc?AttachmentId=' + attachmentID + '&FaqId=' + FaqId)
    }

    DeleteFaqLinks(attachmentLinkID, FaqId) {
        return axios.get('api/faq/Delete/FaqLink?AttachmentLinkId=' + attachmentLinkID + '&FaqId=' + FaqId)
    }
    getFaqs() {
        return axios.get('api/Faq/get');
    }

    DeleteWholeFaq(FaqId) {
        return axios.get('api/faq/Delete?FaqId=' + FaqId)
    }

    getFaqSuggestions() {
        return axios.get('api/faq/suggestions/get');
    }

    confirmFaqSuggestion(FaqId) {
        return axios.put('api/faq/confirm/faqSuggestion?FaqId=' + FaqId);
    }

    rejectFaqSuggestion(FaqId) {
        return axios.put('api/faq/reject/faqSuggestion?FaqId=' + FaqId);
    }

    addFAQSuggestion(data) {
        return axios.post('api/faq/add/faqSuggestion', data);
    }

}