import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import axiosFileUploader from './axiosFileUploader';



class HolidayService {

    static Instance() {
        return new HolidayService();
    }

    listAllHolidays(request) {
        return axios.post('api/holiday/list', request);
    }

    delete(request) {
        return axios.post('api/holiday/delete', request);
    }

    editHoliday(request) {
        return axios.put('api/holiday/edit', request);
    }

    addHoliday(request) {
        return axios.post('api/holiday/add', request);
    }

    generateImportFormat() {
            return axiosFileDownloader.get('api/holiday/template/export');
    }

    importHolidayData(request) {
        return axiosFileUploader.post('api/holiday/import', request, {
            timeout: 600000
        });
    }
}

export default HolidayService.Instance();