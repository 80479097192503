import React from 'react';
import {
    Card, CardBody, Table, Spinner, Button, Badge
} from 'reactstrap';
import {
    displayECD,
    changeFormatOfDateString
} from './../../services/CommonService';
import queryString from 'query-string';
import { Link } from "react-router-dom";

export default function CustomerCurrentOrder(props) {
    let { loadingRecentlySubmittedOrders, LastTenRecentlySubmittedOrders, openProductGroupModal, renderStatuswithBadge, getProductString } = props;

    return (<Card className={"my-2"}>
        <CardBody>
            <div>
                <h5>Current Orders</h5>
                {loadingRecentlySubmittedOrders ?
                    <Spinner color={"primary"} />
                    :
                    <div>
                        <Table responsive hover size={"sm"} striped bordered>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Order No.</th>
                                    <th>Order Reference</th>
                                    <th>Products</th>
                                    <th className={"text-center"}>Status</th>
                                    <th>ECD</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(LastTenRecentlySubmittedOrders || []).map((item, index) =>
                                    <tr key={index}>
                                        <td>{changeFormatOfDateString(item.ordDate, "DD/MM/YYYY", 'DD MMM YYYY')}</td>
                                        <td>
                                            <Link
                                                className="btn btn-sm btn-primary"
                                                style={{ color: "white" }}
                                                title={"Click here to view order in detail"}
                                                to={"/sales/order/view?" + queryString.stringify({ OrdNum: item.ordNum })}>{item.ordNum}</Link>
                                            {
                                                item.isCustom ?
                                                    <Button size={"sm"} title={"Custom Order"}
                                                        color={"warning"} className={"ml-2"} disabled style={{ opacity: 1 }}>
                                                        <i className="fa fa-wrench" />
                                                    </Button>
                                                    : null
                                            }
                                        </td>
                                        <td>{item.custOrdNum}&nbsp;&nbsp;
                                            {item.isRetail ?
                                                <Badge color={"danger"}>Retail</Badge> : null}
                                        </td>
                                        <td>
                                            <Button className="btn btn-sm" color="link"
                                                onClick={openProductGroupModal.bind(this, item)}>{getProductString(item.orderDetails)}
                                            </Button>
                                        </td>
                                        <td>{renderStatuswithBadge(item.salesOrderJobStatusId)}</td>
                                        <td>{displayECD(item.productionReadyDate, "DD MMM YYYY")}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </CardBody>
    </Card>)
}