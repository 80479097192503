import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import {toast, ToastContainer} from 'react-toastify';
import AccountOverviewCustomerCredit from './AccountOverviewCustomerCredit';
import {getCommonAddressString, getDateObj,handleErrorMessage} from './../../services/CommonService';
import Transaction from '../../components/debtor/Transactions';
import RecentStatements from '../../components/debtor/RecentStatements';
import CustomerService from "../../services/CustomerService";
import UserService from "../../services/UserService";
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';
import MakePayment from '../../components/dashboard/CustomerDashboard/MakePayment';
import queryString from 'query-string';

const tabs = [
    {
        key: "overview",
        label: "Overview"
    },
    {
        key: "transactions",
        label: "Transactions"
    },
    {
        key: "statements",
        label: "Statements"
    },
    {
        key: "payment",
        label: "Make Payment"
    },
];
export default class MyAccountsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountId: "",
            debtor: {},
            activeTab: 'overview',
            TransactionsLoading: false,
            isDebtorLoading: false,
            searchParams: {},
            processedTabs:[],
        };
        this.customerService = new CustomerService();
        this.userService = new UserService();
        this.toggle = this.toggle.bind(this);
        this.toggleUpload = this.toggleUpload.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
    }


    componentDidMount() {
        let accountId = this.userService.getLoggedInUserAccountID();
        this.setState({accountId}, () => {
            this.refresh(accountId);
        });

        let { activeTab } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            activeTab = searchParams.activeTab ? searchParams.activeTab : 'overview';
            

            this.setState({ activeTab, searchParams });
        }
    }


    refresh(accountId) {
        if (accountId) {
            this.handleDebtorAccountDetails(accountId)
        }
        else {
            // Default, trying to get first accountId from db and then refreshing with the same
            this.customerService.searchFirstDebtorAccountCompany("").then(response => {
                if (response.data) {
                    for (let i in response.data) {
                        this.refresh(response.data[i].accountId);
                    }
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleDebtorAccountDetails(accountId) {
        if (accountId) {
            this.setState({isDebtorLoading: true});
            this.customerService.searchCustomer(accountId).then(response => {
                if (response.data) {
                    let debtor = response.data;
                    debtor.debtorAddress = getCommonAddressString(debtor);
                    
                    let processedTabs = tabs;
                    let { activeTab } = this.state;;

                    // statement tab to be hide from child accounts
                    if (debtor.isChild) {
                        processedTabs = processedTabs.filter(t => t.key !== 'statements');
                    }

                    if (activeTab === 'statements' && debtor.isChild) {
                        activeTab = processedTabs[0] ? processedTabs[0].key : '';
                    }

                    this.setState({
                        accountId: debtor.accountID,
                        debtor: debtor,
                        isDebtorLoading: false,
                        processedTabs, activeTab
                    });
                }
            }).catch(error=>{
                console.log(error);
                toast.error(handleErrorMessage(error));
                this.setState({isDebtorLoading: false});
            });
        } else {
            this.setState({
                accountID: "",
                debtor: {accountID: ""},
            });
        }
    }

    toggleUpload(value) {
        this.setState({TransactionsLoading: value});
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });

            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("activeTab", tab);
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    };

    render() {

        const { debtor, accountId, activeTab, processedTabs, TransactionsLoading, isDebtorLoading, searchParams} = this.state;
        return (
            <div>
                <div className="text-center">
                    <CustomerDashboardQuickLinks size={"sm"}/>
                    <hr/>
                </div>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {
                                processedTabs.map((tab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink
                                            className={classnames({active: activeTab === tab.key})}
                                            onClick={() => {
                                                this.toggle(tab.key);
                                            }}>
                                            <Button style={{textDecoration: "none"}}
                                                    size={"sm"}
                                                    color={"link"}>{tab.label}&nbsp;</Button>

                                        </NavLink>
                                    </NavItem>
                                )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='overview'>
                                <AccountOverviewCustomerCredit accountId={accountId} debtor={debtor} isLoading={isDebtorLoading}/>
                            </TabPane>
                            <TabPane tabId='transactions'>
                                <Transaction accountId={accountId} toggleModel={this.toggleUpload} searchParams={searchParams}/>
                            </TabPane>
                            <TabPane tabId='statements'>
                                <RecentStatements accountId={accountId}/>
                            </TabPane>
                            <TabPane tabId='payment'>
                                <MakePayment/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        )
    };
}





