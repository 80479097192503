        import {authHeader} from '../helpers/auth-header';
import {config} from '../helpers/config'
import axios from './axios';


export default class RoleService {


    fetchRoleList() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/admin/role/list'

        };
        return axios(request)
    }

    fetchAllPrivilegeList() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/admin/privilege/list'

        };
        return axios(request)
    }

    saveRole(requestBody) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/admin/role/save'

        };
        return axios(request)
    }
    getUserRoleList(request) {
        return axios.post("api/admin/user/roleList", request);
    }
    getUserList(roleId, request) {
        return axios.post("api/admin/user/list/byRoleId?roleId=" + roleId, request);
    }

}
