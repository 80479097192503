import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Row, Col} from 'reactstrap';
import OrderService from "../../../services/OrderService";
import {getDateObj, handleErrorMessage} from '../../../services/CommonService';
import {ToastContainer, toast} from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getDateString} from '../../../services/CommonService';

export default class UpdateProductionReadyDateModal extends Component {
    constructor(props) {
        super(props);
        let ordNum = props.ordNum;
        let productionReadyDate = getDateObj(props.selected, "DD/MM/YYYY");
        if (!productionReadyDate) {
            productionReadyDate = new Date();
        }
        this.state = {
            loading: false,
            ordNum: ordNum,
            productionReadyDate: productionReadyDate
        };
        this.orderService = new OrderService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            let productionReadyDate = getDateObj(nextProps.selected, "DD/MM/YYYY");
            this.setState({ordNum: nextProps.ordNum, productionReadyDate: productionReadyDate});
        }
    }

    handleChange(changedDate) {
        this.setState({productionReadyDate: changedDate});
    }


    handleSubmit() {
        this.setState({loading: true});
        let productionReadyDateString = getDateString(this.state.productionReadyDate, "DD/MM/YYYY");
        this.orderService.updateProductionReadyDate(this.props.ordNum, productionReadyDateString).then(response => {
            this.setState({loading: false});
            this.props.refreshOrder();
            toast.success("Production ECD updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.props.toggleModel();
        }).catch(error => {
            this.setState({loading: false});
            console.log(error.response);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {

        return (
            <Modal isOpen={this.props.isOpen} size="lg" scrollable={false} toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    Update Production ECD
                </ModalHeader>
                <ModalBody>


                    <div className="mt-4 mb-2">
                        <Label>Production ECD
                        </Label>:&nbsp;&nbsp;<i className="fa fa-calendar" aria-hidden="true"/>&nbsp;<DatePicker
                        className="form-control"
                        selected={this.state.productionReadyDate}
                        onChange={date => this.handleChange(date)}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        placeholderText="dd/mm/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        dropdownMode="select"
                    />
                    </div>
                    <div className="text-right">
                        <Button color={"primary"} onClick={this.handleSubmit}
                                disabled={this.state.loading}>{this.state.loading ? "Saving" : "Save"}</Button>&nbsp;&nbsp;
                        <Button color={"secondary"} onClick={this.props.toggleModel}>Cancel</Button>
                    </div>
                </ModalBody>

            </Modal>
        )
    }
}