import React, {Component} from 'react';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import {toast} from 'react-toastify';
import {findItemHavingMaxValue, handleErrorMessage} from '../../services/CommonService';
import dispatchOrderService from '../../services/DispatchOrderService';

export default class ProductionOrderStatusBulkChangeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            salesOrderJobStatuses: this.props.salesOrderJobStatuses,
            salesJobOrder: this.props.salesJobOrder,
            ordNum: this.props.ordNum
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getMaxStatusID = this.getMaxStatusID.bind(this);
    }

    componentDidMount() {
        this.getMaxStatusID(this.state.salesJobOrder);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.salesJobOrder !== this.state.salesJobOrder) {
            this.setState({salesJobOrder: nextProps.salesJobOrder}, () => {
                this.getMaxStatusID(nextProps.salesJobOrder);
            });
        }
    }

    getMaxStatusID(salesJobOrder) {
        let salesOrderJobStatusId = findItemHavingMaxValue(salesJobOrder, 'statusId');
        this.setState({salesOrderJobStatusId});
    }

    handleChange(value) {
        this.setState({salesOrderJobStatusId: parseInt(value)});
    }

    handleSubmit() {
        let {salesJobOrder, salesOrderJobStatusId, ordNum} = this.state;
        if (!salesOrderJobStatusId) {
            toast.info("Please select status");
            return;
        }

        let salesJobID = [];
        (salesJobOrder || []).forEach(item => {
            salesJobID.push(item.id);
        });

        this.setState({loading: true});
        dispatchOrderService.bulkUpdateProductionSalesOrderJob(salesJobID, salesOrderJobStatusId, ordNum).then(res => {
            if (res.status === 200 || res.status === '200') {
                this.setState({loading: false});
                toast.success("status changed!");
                this.props.refreshAgain();
                this.props.toggle(false);

            }
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {loading, salesOrderJobStatusId, salesOrderJobStatuses} = this.state;

        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    <h5 className="mb-0">Update Job Order Status</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="mt-2 mb-2">
                        <Label>Select Staus</Label>
                        <Input type={"select"}
                               name={"salesOrderJobStatusId"}
                               value={salesOrderJobStatusId ? salesOrderJobStatusId : ""}
                               onChange={(e) => this.handleChange(e.target.value)}>
                            <option value={""} disabled>Select Status</option>
                            {(salesOrderJobStatuses || []).map((option, index) =>
                                <option key={index} value={option.id}>{option.statusDescription}</option>
                            )}
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"} size={"sm"} onClick={this.handleSubmit}
                                disabled={loading}>
                            {loading ? <Spinner size="sm"
                                                className={"mr-2"}
                                                style={{color: "white"}}/> : <i className="fa fa-floppy-o mr-2"
                                                                                aria-hidden="true"/>}
                            {loading ? "Updating" : "Update"} Status</Button>&nbsp;&nbsp;
                        <Button color={"secondary"}
                                size={"sm"} onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}