import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Badge, Col, Card, CardBody } from 'reactstrap';
import { toast, ToastContainer } from "react-toastify";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import PropTypes from 'prop-types';

export default class CameraBarCodeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barcodeData: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.searchData = this.searchData.bind(this);
    }

    handleChange(change, key) {
        switch (key) {
            case "barcodeData":
                var scannedData = change.text.slice(0, 6);
                this.setState({ barcodeData: scannedData }, () =>
                    this.searchData());
                toast.success("Scanned location is: " + scannedData, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                break;
        }
    }

    searchData() {
        let { barcodeData } = this.state;
        this.props.updateBarcodeData(barcodeData);
        this.props.toggle(false);
    }

    render() {
        let { barcodeData } = this.state;
        let { isOpen, toggle } = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true} backdrop={"static"}>
                <ModalHeader toggle={() => toggle(!isOpen)}>Scan Location
                </ModalHeader>
                <ModalBody>
                    <div className={"text-center"} style={{
                        maxHeight: "700px"
                    }}>
                        <Row className={"justify-content-center"}>
                            <Card>
                                <CardBody>
                                    <div className={"justify-content-center"}>
                                        <BarcodeScannerComponent
                                            width={500}
                                            height={500}
                                            onUpdate={(err, result) => {
                                                if (result) {
                                                    this.handleChange(result, 'barcodeData');
                                                }
                                            }}
                                        />
                                        {barcodeData ?
                                            <Badge color="primary">{barcodeData}</Badge> : null}
                                    </div>
                                </CardBody>
                            </Card>
                        </Row>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" />
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        );
    }
}

CameraBarCodeModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateBarcodeData: PropTypes.func.isRequired
};