import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Breadcrumb,
    BreadcrumbItem,
    Button
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import PurchaseInvoicePage from './PurchaseInvoicePage';
import PurchaseInvoiceByStock from './PurchaseInvoiceByStock';
import { ToastContainer } from 'react-toastify';
import queryString from 'query-string';

const allTabs = [
    {
        label: "By Invoice",
        key: "purchaseInvoice"
    },
    {
        label: "By Stock",
        key: "stock"
    }
];

export default class PurchaseInvoiceTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeTab: 'purchaseInvoice',
        }
    }

    componentDidMount() {
        let { prodCode } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            prodCode = searchParams.prodCode;
            this.setState({ prodCode, activeTab: 'stock' });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        let { activeTab, prodCode } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Purchase Invoice</BreadcrumbItem>
                </Breadcrumb>

                <div className="text-left"><Link className="btn btn-primary btn-sm"
                    to={{
                        pathname: '/keyway-inventory/purchase-invoice/manage/one',
                        state: { purchaseInvoiceID: "" }
                    }}><i className="fa fa-plus" />&nbsp;
                    New Invoice </Link></div>

                <Card className="mt-2">
                    <CardHeader>
                        <Nav tabs card>
                            {allTabs.map((tab, index) =>
                                <NavItem
                                    className={"hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{ textDecoration: "none" }}
                                            size={"sm"}
                                            color={"link"}> {tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        {/*
                        <TabContent activeTab={activeTab}>
                            <br />
                            <TabPane tabId="purchaseInvoice">
                                <div>
                                    <PurchaseInvoicePage />
                                </div>
                            </TabPane>
                            <TabPane tabId="stock">
                                <PurchaseInvoiceByStock />
                            </TabPane>
                        </TabContent>
                        */}
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='purchaseInvoice'>
                                <PurchaseInvoicePage />
                            </TabPane>
                            <TabPane tabId='stock'>
                                <PurchaseInvoiceByStock prodCode={prodCode} />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}