import React, { Component } from 'react';
import Dashboard from "../components/dashboard/Dashboard";

export default class HomePage extends Component {
  static displayName = HomePage.name;

  render () {
    return (
       <Dashboard {...this.props} />
    );
  }
}
