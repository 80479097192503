import React, {Component} from 'react';
import FileSaver from 'file-saver';
import {getLocalDateStringFromUtcDateString, getDateString, handleErrorMessage} from '../../services/CommonService';
import {Table, Card, CardBody, Button, Breadcrumb, BreadcrumbItem, Spinner} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import NewsLetterService from "../../services/NewsLetterService";
import queryString from 'query-string';
import classnames from 'classnames';
import NewsletterItemModal from '../../components/modal/NewsletterItemModal';
import {EventBus} from "../../components/events/event"
import cloneDeep from 'lodash/cloneDeep';
import ServiceToPostFiles from '../../services/ServiceToPostFiles/ServiceToPostFiles';

export default class NewsLetterItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newsletters: [],

            highlightId: 0,
            workingItem: {},
            isOpenNewsLetterItemModal: false,
            loading: {
                isUploading: false,
                publishing: false,
                deleting: false,
                newsletterList: false

            }
        };
        this.newsLetterService = new NewsLetterService();
        this.serviceToPostFiles = new ServiceToPostFiles();
        this.onDocumentClickSuccess = this.onDocumentClickSuccess.bind(this);
        this.publishNewsLetter = this.publishNewsLetter.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleNewsLetterItemModal = this.toggleNewsLetterItemModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onDocumentClickSuccess(item) {
        this.newsLetterService.getNewsLetterByName(item.fileName).then(response => {
            FileSaver.saveAs(response.data, item.description);
        }).catch(error => {
            console.log(error);
            toast.info("Timeout... !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    componentDidMount() {
        this.refresh(false);
    }

    publishNewsLetter(item) {
        let {loading} = this.state;
        loading.publishing = item.id;
        this.setState({loading});
        this.newsLetterService.publishNewsLetter(item.id).then(response => {
            this.Broadcast(item.id);
            loading.publishing = false;
            this.setState({loading});
            toast.success("Newsletter published succesfully!", {
                position: toast.POSITION.BOTTOM_CENTER
            });

        }).catch(error => {
            loading.publishing = false;
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({loading});
        });
    }

    deleteNewsLetter(item) {
        let {loading} = this.state;
        loading.deleting = item.id;
        this.setState({loading});
        this.newsLetterService.deleteNewsLetter(item.id).then(response => {
            loading.deleting = false;
            this.setState({loading});
            toast.success("Newsletter Deleted!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.refresh(true);
        }).catch(error => {
            loading.deleting = false;
            toast.success(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({loading});
        });
    }

    Broadcast(Id) {
        EventBus.publish('update-notification', {});
        this.refresh(true);
    }

    refresh(silentLoading) {
        let {loading, newsletters} = this.state;
        if (!silentLoading) {
            loading.newsletterList = true;
            this.setState({loading});
        }
        this.newsLetterService.showNewsLetters().then(response => {
            loading.newsletterList = false;
            if (response.data) {
                newsletters = response.data;
            }
            this.setState({newsletters, loading});
        }).catch(error => {
            loading.newsletterList = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    selectItem(item) {
        this.setState({workingItem: cloneDeep(item), isOpenNewsLetterItemModal: true});

    }

    toggleNewsLetterItemModal(change) {
        this.setState({isOpenNewsLetterItemModal: change});
    }

    handleChange(change, key) {
        let {workingItem} = this.state;
        switch (key) {
            default:
                workingItem[key] = change;
                this.setState({workingItem});
                break;
        }
    }

    validateRequest(workingItem) {
        if (!(workingItem.file)) {
            toast.info("Newsletter file is missing!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        if (!(workingItem.snapshotFile)) {
            toast.info("Newsletter snapshot file", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        if (!(workingItem.title)) {
            toast.info("Newsletter's name/description is missing", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        return true;
    }

    handleSubmit() {
        let {workingItem, loading} = this.state;

        let isValid = this.validateRequest(workingItem);

        if (!isValid) {
            return;
        }
        loading.isUploading = true;
        this.setState({loading});

        let data = new FormData();
        data.append('id', workingItem.id ? workingItem.id : 0);
        data.append('file', workingItem.file);
        data.append('ScreenShotFile', workingItem.snapshotFile);
        data.append('description', workingItem.title);

        this.serviceToPostFiles.saveNewsLetter(data).then(response => {
            loading.isUploading = false;
            this.setState({loading, isOpenNewsLetterItemModal: false});
            toast.success("Success", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.refresh(true);
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({isUploading: false});
        });

    }

    render() {

        let {newsletters, isOpenNewsLetterItemModal, workingItem, loading} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Manage Newsletters</BreadcrumbItem>
                </Breadcrumb>

                <div>
                    {loading.newsletterList
                        ? <Spinner color={"primary"}/>
                        : <Card>
                            <CardBody>
                                <Table responsive={true} striped={true} bordered={true} hover={true}>
                                    <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th>Created on</th>
                                        <th>Name</th>
                                        <th className="text-center">
                                            Publish
                                            <div>
                                                <small>
                                                    By publishing, you will send the newsletter to each user's inbox
                                                </small>
                                            </div>
                                        </th>
                                        <th className="text-center">Action</th>
                                        <th className="text-center">
                                            <Button onClick={() => this.selectItem({})}
                                                    color="primary"
                                                    size={"sm"}>
                                                <i className={"fa fa-plus mr-2"}/>New
                                            </Button></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (newsletters || []).map((item, index) => {
                                            return <tr>
                                                <td>{index + 1}</td>
                                                <td>{getDateString(item.createdOn, 'DD MMM YYYY')}</td>
                                                <td>{item.title}</td>
                                                <td className="text-center">
                                                    <Button onClick={() => this.publishNewsLetter(item)}
                                                            title={item.isPublished ? "This newsletter is already published, but by clicking you can re-publish it also." : "click here to publish"}
                                                            color={item.isPublished ? "success" : "primary"}
                                                            size={"sm"}>
                                                        {item.isPublished ? "Published" : "Publish"}
                                                        <i className={classnames("ml-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": (loading.publishing !== item.id) && item.isPublished,
                                                            "fa-square-o": (loading.publishing !== item.id) && !item.isPublished,
                                                            "fa-circle-o-notch fa-spin": (loading.publishing === item.id)
                                                        })}

                                                        />
                                                    </Button>

                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => this.deleteNewsLetter(item)}
                                                            color="danger"
                                                            size={"sm"}>
                                                        <i className={classnames("mr-2", "fa", {
                                                                "fa fa-trash-o mr-2": (loading.deleting !== item.id) && item.isActive,
                                                                "fa-square-o": (loading.deleting !== item.id) && !item.isActive,
                                                                "fa-circle-o-notch fa-spin": (loading.deleting === item.id)
                                                            }
                                                        )}/>
                                                        Delete
                                                    </Button>
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => this.selectItem(item)}
                                                            color="primary"
                                                            size={"sm"}>
                                                        <i className={"fa fa-pencil mr-2"}/>Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    }

                    <NewsletterItemModal
                        isOpen={isOpenNewsLetterItemModal}
                        selected={workingItem}
                        toggle={this.toggleNewsLetterItemModal}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        loading={loading.isUploading}
                    />
                </div>


                <ToastContainer/>
            </div>
        );
    }
}