import React, {Component} from "react";
import {Spinner, Table} from 'reactstrap';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/app/experiment.css';
import NumberFormat from "react-number-format";
import classnames from 'classnames';
import {getSorted} from "../../services/CommonService";
import {purchaseOrderEnquiryOrderItemStore} from "../../store/PurchaseOrderEnquiryOrderItemStore";

export default class PurchaseOrderItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortRequest: {
                key: "",
                direction: false
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(type, param, change) {
        let {sortRequest} = this.state;
        switch (type) {
            case "sortRequest":
                if (change.sorterApplicable) {
                    if (sortRequest.key === change.key) {
                        sortRequest.direction = !sortRequest.direction;
                    } else {
                        sortRequest.key = change.key;
                        sortRequest.direction = false;
                    }
                    this.setState({sortRequest});
                }
        }
    }

    render() {
        let {isLoadingOrderItems, order, visibleColumnsInOrderItems} = this.props;
        let {sortRequest} = this.state;

        let orderItems = getSorted(((order && order.orderItems) || []), sortRequest.key, sortRequest.direction);
        let store = purchaseOrderEnquiryOrderItemStore.filter(x => visibleColumnsInOrderItems.includes(x.key));
        if (isLoadingOrderItems) {
            return <Spinner color={"primary"}/>
        }

        return (
            <div>
                <Table responsive striped bordered size={"sm"} hover={true}>
                    <thead className="thead-light">
                    <tr>
                        {(store || []).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={() => this.handleChange("sortRequest", "key", item)}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}>
                                    {item.renderLabel((orderItems || []), this)}
                                    {
                                        item.sorterApplicable ?
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (sortRequest.key !== item.key),
                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                }
                                            )} aria-hidden="true"/> : null
                                    }

                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    {
                        (orderItems || []).map((orderItem, orderItemIndex) => {
                            return <tbody key={orderItemIndex}>
                            <tr>
                                {(store || []).map((column, columnIndex) => {
                                    return (
                                        <td key={columnIndex}
                                            className={column.getValueClassName(orderItemIndex, orderItems, this)}>
                                            {column.render(orderItemIndex, orderItems, this)}
                                        </td>
                                    );
                                })}
                            </tr>
                            </tbody>
                        })
                    }
                    <tbody>

                    <tr className="bg-white">
                        <th colSpan={store.length - 1}>Order Total</th>
                        <th className={"text-right"}>
                            <NumberFormat
                                value={order.totalPoItemCost}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                prefix={'$'}/>
                        </th>
                    </tr>
                    </tbody>
                </Table>
                <ToastContainer/>
            </div>
        );
    }
}