import React, {Component} from 'react';
import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import {getDateObj, handleErrorMessage} from "../../services/CommonService";
import OrderService from "../../services/OrderService";
import CustomerService from "../../services/CustomerService";
import {toast, ToastContainer} from 'react-toastify';
import DatePicker from "react-datepicker";
import UploadFiles from "../../components/attachment/UploadFiles";
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import queryString from 'query-string';
import {Link as Link} from "react-router-dom";
import SalesOrderCopyModal from "../../components/modal/SalesOrderCopyModal";
import classnames from 'classnames';

export default class ReworkFormPageForCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            showCardBodyAndCardFooter: false,
            allProductOption: {productsByID: {}, products: []},
            reworkOrderTypes: [],
            submitTried: false,
            reworkForm: {
                id: "",
                originalOrdNum: "",
                notes: "",
                reworkOrderTypeID: "",
                reworkOrdNum: "",
                attachmentIDs: "",
                discount: "",
            },
            reworkOrders: [],
            isOpenSalesOrderCopyModal: false,
            isLoadingSaveReworkFormPre: false,
            isLoadingSaveReworkFormPost: false,
            isLoadingReworkOrders: false,
            activeStep: "Next"
        };


        this.customerService = new CustomerService();
        this.orderService = new OrderService();
        this.isValidate = this.isValidate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveReworkOrderDetailsPre = this.saveReworkOrderDetailsPre.bind(this);
        this.saveReworkOrderDetailsPost = this.saveReworkOrderDetailsPost.bind(this);
        this.toggleCopyOrderModal = this.toggleCopyOrderModal.bind(this);
        this.getReworkDetailListFromOriginalOrdNum = this.getReworkDetailListFromOriginalOrdNum.bind(this);
        this.getReworkOrderTypes = this.getReworkOrderTypes.bind(this);
    }

    componentDidMount() {
        let {reworkForm} = this.state;
        this.getReworkOrderTypes();
        if (this.props.order) {
            reworkForm = {
                originalOrdNum: this.props.order.ordNum,
                notes: "",
                reworkOrderTypeID: "",
                reworkOrdNum: "",
                attachmentIDs: "",
                discount: "",
            };
            this.setState({reworkForm});
            this.getReworkDetailListFromOriginalOrdNum(this.props.order.ordNum);
        }
    }

    getReworkOrderTypes() {
        let {reworkOrderTypes} = this.state;
        this.orderService.getReworkOrderTypes().then(response => {
            reworkOrderTypes = response.data;
            this.setState({reworkOrderTypes});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    getReworkDetailListFromOriginalOrdNum(originalOrdNum) {
        this.setState({isLoadingReworkOrders: true});
        this.orderService.getReworkDetailListFromOriginalOrdNum(originalOrdNum).then(response => {
            this.setState({reworkOrders: response.data, isLoadingReworkOrders: false});
        }).catch(error => {
            this.setState({isLoadingReworkOrders: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }


    handleChange(key, change) {
        let {reworkForm, submitTried, reworkOrderTypes, isOpenSalesOrderCopyModal} = this.state;
        let formErrors = {}, index;
        switch (key) {
            case "reworkOrdNum":
                reworkForm[key] = change;
                isOpenSalesOrderCopyModal = false;
                this.setState({reworkForm, isOpenSalesOrderCopyModal}, () => {
                    this.saveReworkOrderDetailsPost();
                });
                break;
            case "reworkOrderTypeID":
                reworkForm[key] = change;
                index = reworkOrderTypes.findIndex(x => x.id == change);
                if (index > -1) {
                    reworkForm.discount = reworkOrderTypes[index].defaultDiscount;
                }
                this.setState({reworkForm});
                break;
            default:
                reworkForm[key] = change;
                if (submitTried) {
                    let result = this.isValidate(reworkForm);
                    formErrors = result.formErrors;
                }
                this.setState({reworkForm, formErrors});
                break;
        }


    }

    /* checkWarrantyClaim() {
         let { order, allProductOption, warrantyClaimReasons } = this.state;
         let checkClaimWarranty=CheckClaimWarranty(order.shipDate, allProductOption.productsByID[order.productID].product_Type);

         //Too old ship date
         if (!checkClaimWarranty) {
             warrantyClaimReasons = warrantyClaimReasons.filter(x => OUT_OF_WARRANTY_REASONS.includes(x.reasoonTypeID));
         }
         this.setState({ warrantyClaimReasons });
     }*/

    isValidate(reworkForm) {
        let formErrors = {};
        let valid = true;
        if (!reworkForm.originalOrdNum) {
            formErrors.originalOrdNum = "Order No. is required";
            valid = false;
        }
        if (!reworkForm.reworkOrderTypeID) {
            formErrors.reworkOrderTypeID = "Reason is required";
            valid = false;
        }
        if (!reworkForm.notes) {
            formErrors.notes = "Enter Claim Details";
            valid = false;
        }
        if (reworkForm.discount === "" || reworkForm.discount < 0 || reworkForm.discount > 100) {
            formErrors.discount = "Price Waived % must be between 0 and 100";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    saveReworkOrderDetailsPre() {
        let {reworkForm} = this.state;

        let result = this.isValidate(reworkForm);
        this.setState({reworkForm, formErrors: result.formErrors, submitTried: true});
        if (!result.valid) {
            return;
        }
        let request = {
            originalOrdNum: reworkForm.originalOrdNum,
            notes: reworkForm.notes,
            reworkOrderTypeID: reworkForm.reworkOrderTypeID,
            reworkOrdNum: reworkForm.reworkOrdNum,
            attachmentIDs: reworkForm.attachmentIDs,
            discount: reworkForm.discount,
        };
        this.setState({isLoadingSaveReworkFormPre: true, activeStep: "Initialising"});
        this.orderService.saveReworkOrderDetailsPre(request).then(response => {
            if (response.data) {
                reworkForm = response.data;
                this.setState({
                    reworkForm,
                    isLoadingSaveReworkFormPre: false,
                    isOpenSalesOrderCopyModal: true,
                    activeStep: "Initialized"
                });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingSaveReworkFormPre: false, activeStep: "Next"});
        });
    }

    saveReworkOrderDetailsPost() {
        let {reworkForm} = this.state;

        let request = {
            reworkID: reworkForm.id,
            reworkOrdNum: reworkForm.reworkOrdNum,
        };
        this.setState({isLoadingSaveReworkFormPost: true, activeStep: "Finishing"});
        this.orderService.saveReworkOrderDetailsPost(request).then(response => {
            if (response.data) {
                toast.success("Rework request submitted, opening created rework order in product builder...");
                reworkForm = response.data;
                this.setState({reworkForm, isLoadingSaveReworkFormPost: false, activeStep: "Finished"}, () => {
                    this.props.toggle(!this.props.isOpen);
                    setTimeout(() => {
                        this.props.history.push("/sales/order/create/product-builder/v1?orderNum=" + reworkForm.reworkOrdNum);
                    }, 5000);
                });
            }

        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingSaveReworkFormPost: false, activeStep: "Finish"});
        });
    }


    toggleCopyOrderModal(change) {
        this.setState({isOpenSalesOrderCopyModal: change});
    }

    render() {
        let {formErrors, reworkForm, reworkOrderTypes, isOpenSalesOrderCopyModal, isLoadingSaveReworkFormPre, activeStep, isLoadingSaveReworkFormPost, reworkOrders, isLoadingReworkOrders} = this.state;
        let {isOpen, toggle, getOrder} = this.props;
        return (
            <div>
                <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)}>
                    <ModalHeader toggle={() => toggle(!isOpen)}>
                        Warranty Claim
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="company">
                                        Company *
                                    </Label>
                                    <SearchDebtorAcccount
                                        disabled={true}
                                        selectedAccountID={this.props.order.accountID}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="originalOrdNum">
                                        Order Number *
                                    </Label>
                                    <Input type="number" name="originalOrdNum"
                                           disabled
                                           value={reworkForm.originalOrdNum}
                                           placeholder="order no. "/>
                                    <FormText color="danger"> {formErrors.originalOrdNum}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="invoiceDate">
                                        Invoice Date *
                                    </Label>
                                    <br/>
                                    <DatePicker
                                        disabled={true}
                                        className="form-control form-control"
                                        selected={getDateObj(this.props.order.invPrinted, "DD/MM/yyyy HH:mm:ss")}
                                        selectsStart
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Invoice Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        withPortal/>
                                    <FormText color="danger"> {formErrors.invoiceDate}</FormText>
                                </FormGroup>
                            </Col>

                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                {
                                    isLoadingReworkOrders
                                        ? <Spinner color={"primary"} className={"mt-4"}/>
                                        : <FormGroup>
                                            {
                                                ((reworkOrders || []).length > 0)
                                                    ? <p className={"text-info mb-1"}>
                                                        <i className="fa fa-info-circle mr-2"
                                                           aria-hidden="true"/>
                                                        Rework order(s) already exist!
                                                    </p>
                                                    : null
                                            }
                                            {
                                                (reworkOrders || []).map((ro, index) => {
                                                    return <Link
                                                        key={index}
                                                        style={{color: "white"}}
                                                        title={"Click here to view order in detail"}
                                                        className={"btn btn-sm btn-primary m-1"}
                                                        onClick={() => getOrder(ro.reworkOrdNum, "current")}
                                                        to={"/sales/order/view?" + queryString.stringify({OrdNum: ro.reworkOrdNum})}>
                                                        {ro.reworkOrdNum}
                                                    </Link>
                                                })
                                            }
                                        </FormGroup>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="reworkOrderTypeID">
                                        Reason of Warranty Claim *
                                    </Label>
                                    <Input type={"select"} name={"reworkOrderTypeID"}
                                           value={reworkForm.reworkOrderTypeID}
                                           className={"mb-1"}
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                        <option value="" selected disabled>Select Reason</option>
                                        {(reworkOrderTypes || []).map((reworkOrderType, rIndex) =>
                                            <option value={reworkOrderType.id}
                                                    key={rIndex}>{reworkOrderType.name}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger"> {formErrors.reworkOrderTypeID}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="claimDetails">
                                        Please Explain the Issue *
                                    </Label>
                                    <Input type="text" name="notes"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={reworkForm.notes}
                                           placeholder="Issue"/>
                                    <FormText color="danger"> {formErrors.notes}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="discount">
                                        Price Waived % *
                                    </Label>
                                    <Input type="number" name="discount"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={reworkForm.discount}
                                           min={0}
                                           max={100}
                                           placeholder="Price Waived %"/>
                                    <FormText color="danger"> {formErrors.discount}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="pictures">
                                        Please upload photos of the blind/awning clearly showing at least:<br/>
                                        -&nbsp;When Open<br/>
                                        -&nbsp;When Closed<br/>
                                        -&nbsp;Focus on Damaged/Defected Area (from different angles)
                                    </Label>
                                    <UploadFiles
                                        handleChange={(change) => this.handleChange("attachmentIDs", change)}
                                        attachmentIDs={reworkForm.attachmentIDs}
                                        multiple={true}
                                        maxLength={12}
                                    />
                                    <FormText color="warning"
                                              style={{fontSize: '16px'}}>{reworkForm.attachmentIDs ? null : "Please ensure all necessary images are uploaded before submitting this rework."}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="text-right">
                            <Button color={"primary"}
                                    size="sm"
                                    className="ml-2"
                                    disabled={isLoadingSaveReworkFormPre || isLoadingSaveReworkFormPost}
                                    onClick={this.saveReworkOrderDetailsPre}>
                                {
                                    (isLoadingSaveReworkFormPre || isLoadingSaveReworkFormPost)
                                        ? <Spinner size="sm"
                                                   className={"mr-2"}
                                                   color={"light"}/>
                                        : <i className={classnames("fa", "mr-2", {
                                            "fa-chevron-right": ["Next"].includes(activeStep),
                                            "fa-check-circle": ["Initialised", "Finished"].includes(activeStep),
                                        })}
                                             aria-hidden="true"
                                        />
                                }
                                {activeStep}
                            </Button>
                            <Button color={"secondary"} size="sm" className="ml-2"
                                    disabled={isLoadingSaveReworkFormPre || isLoadingSaveReworkFormPost}
                                    onClick={() => this.props.toggle(false)}>
                                <i className="fa fa-times mr-2"/>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                {
                    isOpenSalesOrderCopyModal ?
                        <SalesOrderCopyModal
                            heading={"Select original order #" + reworkForm.originalOrdNum + " items for Rework"}
                            history={this.props.history}
                            isOpen={isOpenSalesOrderCopyModal}
                            toggle={this.toggleCopyOrderModal}
                            ordNum={reworkForm.originalOrdNum}
                            purpose={"Rework"}
                            enforceSubmit={true}
                            handleSubmit={(newOrdNum) => this.handleChange("reworkOrdNum", newOrdNum)}
                            reworkID={reworkForm.id}
                        />
                        : null
                }
                <ToastContainer/>
            </div>
        );
    }
}