import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {BUNNINGS_ACCOUNT_ID, PRODUCT_BUILDER_ROMAN_BLIND_PRODCODE} from "../../../../../../store/AppConstants";
import {roundUp, roundUpToNearestOddNumber} from "../../../../../../services/CommonService";

class RomanBlindUtil {

    static Instance() {
        return new RomanBlindUtil()
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < product.items[itemIndex].configuration.quantity.min) || (product.items[itemIndex].configuration.quantity.selected.value > product.items[itemIndex].configuration.quantity.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;


            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
    }

    updateRules(key, product, itemIndex, order) {
        switch (key) {
            case "quantity":
                product.items[itemIndex].configuration.quantity.min = 1;
                product.items[itemIndex].configuration.quantity.max = 10000;
                break;
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                product.items[itemIndex].configuration.width.min = 300;
                product.items[itemIndex].configuration.width.max = 2400;
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 500;
                product.items[itemIndex].configuration.drop.max = 3000;
                break;
            case "cordColour":
            case "chainColour":
            case "chainLength":
                product.items[itemIndex].configuration.cordColour.selected.isSelectable = false;
                product.items[itemIndex].configuration.chainColour.selected.isSelectable = false;
                product.items[itemIndex].configuration.chainLength.selected.isSelectable = false;

                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Chained":
                            product.items[itemIndex].configuration.cordColour.selected.isSelectable = false;
                            product.items[itemIndex].configuration.chainColour.selected.isSelectable = true;
                            product.items[itemIndex].configuration.chainLength.selected.isSelectable = true;
                            break;
                        case "Corded":
                            product.items[itemIndex].configuration.cordColour.selected.isSelectable = true;
                            product.items[itemIndex].configuration.chainColour.selected.isSelectable = false;
                            product.items[itemIndex].configuration.chainLength.selected.isSelectable = false;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.cordColour.selected.isSelectable) {
                    product.items[itemIndex].configuration.cordColour.selected.value = null;
                    product.items[itemIndex].configuration.cordColour.selected.dropdownValue = "";
                }
                if (!product.items[itemIndex].configuration.chainColour.selected.isSelectable) {
                    product.items[itemIndex].configuration.chainColour.selected.value = null;
                    product.items[itemIndex].configuration.chainColour.selected.dropdownValue = "";
                }
                if (!product.items[itemIndex].configuration.chainLength.selected.isSelectable) {
                    product.items[itemIndex].configuration.chainLength.selected.value = null;
                    product.items[itemIndex].configuration.chainLength.selected.dropdownValue = "";
                }
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let comment, stocks, label, attribute, deduction, condition1, condition2, condition3, optionIndex, sets;

        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    prodCode: PRODUCT_BUILDER_ROMAN_BLIND_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false,
                    description: "default"
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "productionCalculation":
                product.items[itemIndex].configuration.productionCalculation.selected.finishWidth = this.doCalculation("finishWidth", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.finishDrop = this.doCalculation("finishDrop", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.bottomPocketOffset = this.doCalculation("bottomPocketOffset", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.mechSize = this.doCalculation("mechSize", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.maxDrawSpace = this.doCalculation("maxDrawSpace", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.drawQty = this.doCalculation("drawQty", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.drawSpace = this.doCalculation("drawSpace", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.foldSpace = this.doCalculation("foldSpace", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.topPocketAllowance = this.doCalculation("topPocketAllowance", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.topPocket = this.doCalculation("topPocket", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.otherPocket = this.doCalculation("otherPocket", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.maxPocketSpace = this.doCalculation("maxPocketSpace", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.pocketQty = this.doCalculation("pocketQty", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.pocketSpace = this.doCalculation("pocketSpace", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.bottomPocket = this.doCalculation("bottomPocket", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.fabricCutDrop = this.doCalculation("fabricCutDrop", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.fabricCutWidth = this.doCalculation("fabricCutWidth", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.headRailCutLength = this.doCalculation("headRailCutLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.driveShaftCutLength = this.doCalculation("driveShaftCutLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.sewLoopCutLength = this.doCalculation("sewLoopCutLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.liftCordLength = this.doCalculation("liftCordLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.rearSupportBatten = this.doCalculation("rearSupportBatten", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.rearBattenClip = this.doCalculation("rearBattenClip", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.bottomRailSplineCutLength = this.doCalculation("bottomRailSplineCutLength", product, itemIndex);
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [{
                    id: null,
                    description: "",
                    prodCode: product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                    keywayQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                    wastageFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 0.001,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true
                }];

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "headRail":
                attribute = "Head Rail";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.headRail.selected.value)) {
                    product.items[itemIndex].configuration.headRail.selected.value.sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks]
                    });
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.headRail.selected.stocks = stocks;
                break;
            case "chainColour":
                attribute = "Chain";
                label = "";
                comment = "";
                stocks = [];
                condition1 = isEmpty(product.items[itemIndex].configuration.chainColour.selected.value) ? "" : product.items[itemIndex].configuration.chainColour.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.chainLength.selected.value) ? "" : product.items[itemIndex].configuration.chainLength.selected.value.optionKey;

                optionIndex = product.items[itemIndex].configuration.chainColour.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.chainColour.options[optionIndex].sets.forEach(set => {
                        if ((set.condition1 ? set.condition1.includes(condition1) : true) && (set.condition2 ? set.condition2 === condition2 : true)) {
                            stocks = [...stocks, ...set.stocks]
                        }
                    })
                }
                stocks.forEach((stockItem, stockIndex) => {
                    //add logic to set stock price for bunning account
                    if (condition1 === "Stainless Steel" && order.accountID.includes(BUNNINGS_ACCOUNT_ID)) {
                            switch (true) {
                                case (product.items[itemIndex].configuration.drop.selected.value >= 100 && product.items[itemIndex].configuration.drop.selected.value <= 1600):
                                    stockItem.price = 12.24;
                                    break;
                                case (product.items[itemIndex].configuration.drop.selected.value >= 1601 && product.items[itemIndex].configuration.drop.selected.value <= 2100):
                                    stockItem.price = 14.58;
                                    break;
                                case (product.items[itemIndex].configuration.drop.selected.value >= 2101 && product.items[itemIndex].configuration.drop.selected.value <= 2500):
                                    stockItem.price = 17.47;
                                    break;
                                case (product.items[itemIndex].configuration.drop.selected.value >= 2501 && product.items[itemIndex].configuration.drop.selected.value <= 3000):
                                    stockItem.price = 19.5;
                                    break;
                            }
                    }
                    //end logic
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.chainColour.selected.stocks = stocks;
                break;
            case "rearBattenColour":
                attribute = "Rear Batten";
                label = "";
                comment = "";
                stocks = [];
                condition2 = product.items[itemIndex].configuration.rearBattenColour.selected.value.optionKey;
                product.items[itemIndex].configuration.rearBattenColour.options.forEach(o => {
                    if (( o.condition1 === "bom" ) || (o.condition1 === "dropdown" && o.optionKey.includes(condition2))) {
                        o.sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks]
                        });
                    }
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.rearBattenColour.selected.stocks = stocks;
                break;
            case "bottomRailColour":
                attribute = "Bottom Rail";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey;
                condition2 = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                optionIndex = product.items[itemIndex].configuration.bottomRailColour.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRailColour.options[optionIndex].sets.forEach(set => {
                        if ((set.condition1 ? set.condition1.includes(condition1) : true) && (set.condition2 ? set.condition2 === condition2 : true)) {
                            stocks = [...stocks, ...set.stocks]
                        }
                    })
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.bottomRailColour.selected.stocks = stocks;
                break;
        }
        return product;
    }

    doCalculation(key, product, itemIndex) {
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let result = 0;
        switch (key) {
            case "finishWidth":
                result = width;
                break;
            case "finishDrop":
                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                    case "Inside/Reveal":
                        result = drop - 10;
                        break;
                    case "Outside/Face":
                    default:
                        result = drop;
                        break;
                }
                break;
            case "bottomPocketOffset":
                if (!isEmpty(product.items[itemIndex].configuration.bottomRailType.selected.value)) {
                    switch (product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey) {
                        case "Round":
                            result = 15;
                            break;
                        case "Oval":
                            result = -15;
                            break;
                        case "Silent":
                            result = -15;
                            break;
                        case "Flat":
                            result = 10;
                            break;
                        case "Sewn In Lathe":
                            result = 40;
                            break;
                    }
                }
                break;
            case "mechSize":
                /*
                * Draw Mechanism Size
                  MechSize = 100 mm
                  Eg; Drum & Drum Cord holder (01598)
                */
                result = 100;
                break;
            case "maxDrawSpace":
                /*
                * Draw Max Spacing
                  MaxDrawSpace = 300 + MechSize
                  Centre to centre mech -mech. Ie max 300mm between mechs.
                */
                result = 300 + product.items[itemIndex].configuration.productionCalculation.selected.mechSize;
                break;
            case "drawQty":
                /*
                * Draw Item Qty
                  DrawQty = roundCeiling( (Width - MechSize)
                   / MaxDrawSpace ) + 1
                  Width is window opening
                */
                result = Math.ceil((width - product.items[itemIndex].configuration.productionCalculation.selected.mechSize) / product.items[itemIndex].configuration.productionCalculation.selected.maxDrawSpace) + 1;
                break;
            case "drawSpace":
                /*
                * Draw Item Spacing
                  DrawSpace = (Width - MechSize) / DrawQty
                  Width is window opening. Centre to centre mech -mech. Assumes mech’s on edges of blind.
                */
                result = (width - product.items[itemIndex].configuration.productionCalculation.selected.mechSize) / product.items[itemIndex].configuration.productionCalculation.selected.drawQty;
                break;
            case "foldSpace":
                /*
                * Pocket Fold Overlap
                  FoldSpace = 8 + 8  = 16 mm
                  On each batten/fold. Needed for fabric drop.
                */
                result = 8 + 8;
                break;
            case "topPocketAllowance":
                /*
                * Top Pocket Space
                  topPocketAllowance = 60 mm
                  First fold is on this pocket.
                */
                result = 60;
                break;
            case "maxPocketSpace":
                /*
                * Pocket Max Space
                  MaxPocketSpace = 350 mm
                */
                result = 350;
                break;
            case "pocketQty":
                /*
                * Pocket Qty
                  PocketQty =  2 * roundCeiling( ( (Drop - topPocketAllowance) / MaxSpace) ) / 2  ) + 1
                  Every 2nd pocket folds up (odd). Rounds up to the nearest odd number (2*x/2+1). No need to account for top pocket (no +1).
                */
                result = ((drop - product.items[itemIndex].configuration.productionCalculation.selected.topPocketAllowance) / product.items[itemIndex].configuration.productionCalculation.selected.maxPocketSpace);
                result = roundUpToNearestOddNumber(result, 0); //Rounds up to the nearest odd number
                break;
            case "pocketSpace":
                /*
                * Pocket Space Between
                  PocketSpace = (Drop - topPocketAllowance) / PocketQty
                  Excludes top pocket. Batten to batten.
                */
                result = (drop - product.items[itemIndex].configuration.productionCalculation.selected.topPocketAllowance) / product.items[itemIndex].configuration.productionCalculation.selected.pocketQty;
                result = roundUp(result, 0);
                result = result > 200 ? result : 200; /*Femma told me that there should be a minimum pocket space , which the pocket space shouldn’t be smaller then 200mm.*/
                break;
            case "topPocket":
                /*
                * TopPocket=PocketSpace+TopPocketallowance+8
                */
                result = product.items[itemIndex].configuration.productionCalculation.selected.pocketSpace + product.items[itemIndex].configuration.productionCalculation.selected.topPocketAllowance + 8;
                break;
            case "otherPocket":
                /*
                * OtherPocket = PocketSpace + FoldSpace
                */
                result = product.items[itemIndex].configuration.productionCalculation.selected.pocketSpace + product.items[itemIndex].configuration.productionCalculation.selected.foldSpace;
                break;
            case "bottomPocket":
                /*
                * Bottom Pocket Fabric
                  BottomPocket = PocketSpace + BottomPocketOffset
                  For fabric only - doesn’t affect spacing.
                */
                result = product.items[itemIndex].configuration.productionCalculation.selected.pocketSpace + product.items[itemIndex].configuration.productionCalculation.selected.bottomPocketOffset;
                break;
            case "fabricCutDrop":
                /*
                *
                 FabDrop = WindowOpeningDrop (If Alum +300mm) (If Timber+400mm)
                */
                result = drop;
                if (!isEmpty(product.items[itemIndex].configuration.headRail.selected.value)) {
                    switch (product.items[itemIndex].configuration.headRail.selected.value.optionKey) {
                        case "Alum - Chained":
                            result = result + 300;
                            break;
                        case "Timber - Corded":
                            result = result + 400;
                            break;
                    }
                }
                break;
            case "fabricCutWidth":
                /*
                *  Fabric Width
                   FabWidth = WindowOpeningWidth  (If Face -0, if reveal -5)
                */
                if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                    switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                        case "Inside/Reveal":
                            /*
                             *  HRCutLen = Window opening
                             *  -5mm for reveal
                             */
                            result = width - 5;
                            break;
                        case "Outside/Face":
                            result = width;
                            break;
                    }
                }
                break;
            case "headRailCutLength":
                if (!isEmpty(product.items[itemIndex].configuration.headRail.selected.value)) {
                    switch (product.items[itemIndex].configuration.headRail.selected.value.optionKey) {
                        case "Alum - Chained":
                            /*HRCutLen = WindowOpeningWidth  (If Face -14, if reveal -19)*/
                            if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                                    case "Inside/Reveal":
                                        result = width - 19;
                                        break;
                                    case "Outside/Face":
                                        result = width - 14;
                                        break;
                                }
                            }
                            break;
                        case "Timber - Corded":
                            /*HRCutLen = WindowOpeningWidth  (If Face -0, if reveal -5)*/
                            if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                                    case "Inside/Reveal":
                                        result = width - 5;
                                        break;
                                    case "Outside/Face":
                                        result = width;
                                        break;
                                }
                            }
                            break;
                    }
                }
                break;
            case "driveShaftCutLength":
                /*DSCutLen  = WindowOpeningWidth  (If Face -40, if reveal -45)*/
                if (!isEmpty(product.items[itemIndex].configuration.headRail.selected.value)) {
                    switch (product.items[itemIndex].configuration.headRail.selected.value.optionKey) {
                        case "Alum - Chained":
                            if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                                    case "Inside/Reveal":
                                        result = width - 45;
                                        break;
                                    case "Outside/Face":
                                        result = width - 40;
                                        break;
                                }
                            }
                            break;
                        case "Timber - Corded":
                            result = 0;
                            break;
                    }
                }
                break;
            case "sewLoopCutLength":
                if (!isEmpty(product.items[itemIndex].configuration.headRail.selected.value)) {
                    switch (product.items[itemIndex].configuration.headRail.selected.value.optionKey) {
                        case "Alum - Chained":
                            /*
                            * SewLoopLen =
                                IF (Outside/Face)
                                      THEN (Window opening -14mm)
                                ELSE IF (Inside/Reveal)
                                     THEN (Window opening  - 19mm)
                                */
                            if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                                    case "Inside/Reveal":
                                        result = width - 19;
                                        break;
                                    case "Outside/Face":
                                        result = width - 14;
                                        break;
                                }
                            }
                            break;
                        case "Timber - Corded":
                            result = 0;
                            break;
                    }
                }
                break;
            case "liftCordLength":
                /*
                * CordLen = (WindowOpeningWidth+WindowOpeningDrop)x Draw Qty
                * */
                if (!isEmpty(product.items[itemIndex].configuration.headRail.selected.value)) {
                    switch (product.items[itemIndex].configuration.headRail.selected.value.optionKey) {
                        case "Alum - Chained":
                            result = 0;
                            break;
                        case "Timber - Corded":
                            result = (width + drop) * product.items[itemIndex].configuration.productionCalculation.selected.drawQty;
                            break;
                    }
                }

                break;
            case "rearSupportBatten":
                /*
                * Window Opening Width (- 9mm for reveal, -4mm for face) * PocketQty -1
                */
                if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                    switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                        case "Inside/Reveal":
                            result = width - 9;
                            break;
                        case "Outside/Face":
                            result = width - 4;
                            break;
                    }
                }
                result = result * (product.items[itemIndex].configuration.productionCalculation.selected.pocketQty - 1);
                break;
            case "rearBattenClip":
                /*
                DrawQty * (((PocketQty-1)/2)-1)
                */
                result = product.items[itemIndex].configuration.productionCalculation.selected.drawQty * (((product.items[itemIndex].configuration.productionCalculation.selected.pocketQty - 1) / 2) - 1);
                break;
            case "bottomRailSplineCutLength":
                if (!isEmpty(product.items[itemIndex].configuration.bottomRailType.selected.value)) {
                    switch (product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey) {
                        case "Round":
                        case "Oval":
                        case "Silent":
                        case "Flat":
                            if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                                    case "Inside/Reveal":
                                        result = width - 5;
                                        break;
                                    case "Outside/Face":
                                        result = width;
                                        break;
                                }
                            }
                            break;
                        case "Sewn In Lathe":
                            if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                                    case "Inside/Reveal":
                                        result = width - 10;
                                        break;
                                    case "Outside/Face":
                                        result = width - 5;
                                        break;
                                }
                            }
                            break;
                    }
                }
                break;
        }
        return result;
    }

    generateBMCode(product, itemIndex, order) {
        let bmCode = "", fabricType = "", operation = "";

        if (order.accountID.includes(BUNNINGS_ACCOUNT_ID)) {
            if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                fabricType = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
            }
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                operation = product.items[itemIndex].configuration.operation.selected.value.optionKey;
            }
            switch (fabricType) {
                case "Le Reve BO":
                    switch (operation) {
                        case "Chained":
                            bmCode = "07547";
                            break;
                        case "Corded":
                            bmCode = "07548";
                            break;
                    }
                    break;
                case "Le Reve LF":
                    switch (operation) {
                        case "Chained":
                            bmCode = "07549";
                            break;
                        case "Corded":
                            bmCode = "07550";
                            break;
                    }
                    break;
                case "Oxford BO":
                case "Linesque BO":
                    switch (operation) {
                        case "Chained":
                            bmCode = "07556";
                            break;
                        case "Corded":
                            bmCode = "07557";
                            break;
                    }
                    break;
                case "Oxford LF":
                case "Linesque LF":
                    switch (operation) {
                        case "Chained":
                            bmCode = "07558";
                            break;
                        case "Corded":
                            bmCode = "07559";
                            break;
                    }
                    break;
                case "Palm Beach BO":
                case "Palm Beach LF":
                    switch (operation) {
                        case "Chained":
                            bmCode = "07541";
                            break;
                        case "Corded":
                            bmCode = "07542";
                            break;
                    }
                    break;
                case "Solar Filter 97":
                    switch (operation) {
                        case "Chained":
                            bmCode = "07504";
                            break;
                        case "Corded":
                            bmCode = "07505";
                            break;
                    }
                    break;
            }
        } else {
            if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                bmCode = product.items[itemIndex].configuration.fabricType.selected.value.primaryBMCode;
            }
        }
        return bmCode;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("productionCalculation", product, itemIndex, order);
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("headRail", product, itemIndex, order);
            product = this.updateStocks("chainColour", product, itemIndex, order);
            product = this.updateStocks("bottomRailColour", product, itemIndex, order);
            product = this.updateStocks("rearBattenColour", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.headRail.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.chainColour.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRailColour.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.rearBattenColour.selected.stocks];
            }
            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling, discountByProductCode);
        return product;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        stockQtyExpression = stockQtyExpression.replaceAll("@FinishWidth", params.finishWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@FinishDrop", params.finishDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@BottomPocketOffset", params.bottomPocketOffset);
        stockQtyExpression = stockQtyExpression.replaceAll("@MechSize", params.mechSize);
        stockQtyExpression = stockQtyExpression.replaceAll("@MaxDrawSpace", params.maxDrawSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@DrawQty", params.drawQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@DrawSpace", params.drawSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@FoldSpace", params.foldSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@TopPocketAllowance", params.topPocketAllowance);
        stockQtyExpression = stockQtyExpression.replaceAll("@TopPocket", params.topPocket);
        stockQtyExpression = stockQtyExpression.replaceAll("@OtherPocket", params.otherPocket);
        stockQtyExpression = stockQtyExpression.replaceAll("@MaxPocketSpace", params.maxPocketSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@PocketQty", params.pocketQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@PocketSpace", params.pocketSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@BottomPocket", params.bottomPocket);
        stockQtyExpression = stockQtyExpression.replaceAll("@FabricCutDrop", params.fabricCutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@FabricCutWidth", params.fabricCutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@HeadRailCutLength", params.headRailCutLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@DriveShaftCutLength", params.driveShaftCutLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@SewLoopCutLength", params.sewLoopCutLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@LiftCordLength", params.liftCordLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@RearSupportBatten", params.rearSupportBatten);
        stockQtyExpression = stockQtyExpression.replaceAll("@RearBattenClip", params.rearBattenClip);
        stockQtyExpression = stockQtyExpression.replaceAll("@BottomRailSplineCutLength", params.bottomRailSplineCutLength);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        let deduction = {};
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;
        stockItem.stockLinearWidth = (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1;

        let params = {
            width: width,
            drop: drop,
            stockLength: stockItem.length,
            stockLinearWidth: stockItem.stockLinearWidth,
            swishConversionFactor: stockItem.swishConversionFactor,
            finishWidth: product.items[itemIndex].configuration.productionCalculation.selected.finishWidth,
            finishDrop: product.items[itemIndex].configuration.productionCalculation.selected.finishDrop,
            bottomPocketOffset: product.items[itemIndex].configuration.productionCalculation.selected.bottomPocketOffset,
            mechSize: product.items[itemIndex].configuration.productionCalculation.selected.mechSize,
            maxDrawSpace: product.items[itemIndex].configuration.productionCalculation.selected.maxDrawSpace,
            drawQty: product.items[itemIndex].configuration.productionCalculation.selected.drawQty,
            drawSpace: product.items[itemIndex].configuration.productionCalculation.selected.drawSpace,
            foldSpace: product.items[itemIndex].configuration.productionCalculation.selected.foldSpace,
            topPocketAllowance: product.items[itemIndex].configuration.productionCalculation.selected.topPocketAllowance,
            topPocket: product.items[itemIndex].configuration.productionCalculation.selected.topPocket,
            otherPocket: product.items[itemIndex].configuration.productionCalculation.selected.otherPocket,
            maxPocketSpace: product.items[itemIndex].configuration.productionCalculation.selected.maxPocketSpace,
            pocketQty: product.items[itemIndex].configuration.productionCalculation.selected.pocketQty,
            pocketSpace: product.items[itemIndex].configuration.productionCalculation.selected.pocketSpace,
            bottomPocket: product.items[itemIndex].configuration.productionCalculation.selected.bottomPocket,
            fabricCutDrop: product.items[itemIndex].configuration.productionCalculation.selected.fabricCutDrop,
            fabricCutWidth: product.items[itemIndex].configuration.productionCalculation.selected.fabricCutWidth,
            headRailCutLength: product.items[itemIndex].configuration.productionCalculation.selected.headRailCutLength,
            driveShaftCutLength: product.items[itemIndex].configuration.productionCalculation.selected.driveShaftCutLength,
            sewLoopCutLength: product.items[itemIndex].configuration.productionCalculation.selected.sewLoopCutLength,
            liftCordLength: product.items[itemIndex].configuration.productionCalculation.selected.liftCordLength,
            rearSupportBatten: product.items[itemIndex].configuration.productionCalculation.selected.rearSupportBatten,
            rearBattenClip: product.items[itemIndex].configuration.productionCalculation.selected.rearBattenClip,
            bottomRailSplineCutLength: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailSplineCutLength,
        };

        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                //todo: cross check
                let fabricWidth = params.stockLinearWidth;
                let usage = (( width + 100) * ((drop + 100) + (drop / 250) * 12)) / fabricWidth;

                deduction = this.updateDeduction("fabric", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            default:
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
        }
    }

    updateDeduction(key, product, itemIndex, order) {
        return {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        return errors;
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }

    toSalesOrderItemRomanBlindRequest(product, itemIndex, order) {
        let defaultDeduction = this.initDeduction();
        if (isEmpty(product.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            product.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }

        let salesOrderItemRomanBlind = {
            ID: product.items[itemIndex].configuration.ID ? product.items[itemIndex].ID : null,
            salesOrderItemID: product.items[itemIndex].configuration.salesOrderItemID ? product.items[itemIndex].salesOrderItemID : null,
            salesOrderID: product.items[itemIndex].configuration.salesOrderID ? product.items[itemIndex].salesOrderID : null,
            bmCode: product.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: product.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: product.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: product.items[itemIndex].configuration.location.selected.value,
            quantity: product.items[itemIndex].configuration.quantity.selected.value,
            width: product.items[itemIndex].configuration.width.selected.value,
            drop: product.items[itemIndex].configuration.drop.selected.value,
            fabricCategory: isEmpty(product.items[itemIndex].configuration.fabricCategory.selected.value) ? "" : product.items[itemIndex].configuration.fabricCategory.selected.value.optionKey,
            fabricType: isEmpty(product.items[itemIndex].configuration.fabricType.selected.value) ? "" : product.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? "" : product.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            headRail: isEmpty(product.items[itemIndex].configuration.headRail.selected.value) ? "" : product.items[itemIndex].configuration.headRail.selected.value.optionKey,
            operation: isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey,
            controlSide: isEmpty(product.items[itemIndex].configuration.controlSide.selected.value) ? "" : product.items[itemIndex].configuration.controlSide.selected.value.optionKey,
            chainColour: isEmpty(product.items[itemIndex].configuration.chainColour.selected.value) ? "" : product.items[itemIndex].configuration.chainColour.selected.value.optionKey,
            chainLength: isEmpty(product.items[itemIndex].configuration.chainLength.selected.value) ? "" : product.items[itemIndex].configuration.chainLength.selected.value.optionKey,
            cordColour: isEmpty(product.items[itemIndex].configuration.cordColour.selected.value) ? "" : product.items[itemIndex].configuration.cordColour.selected.value.optionKey,
            mount: isEmpty(product.items[itemIndex].configuration.mount.selected.value) ? "" : product.items[itemIndex].configuration.mount.selected.value.optionKey,
            bottomRailType: isEmpty(product.items[itemIndex].configuration.bottomRailType.selected.value) ? "" : product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey,
            bottomRailColour: isEmpty(product.items[itemIndex].configuration.bottomRailColour.selected.value) ? "" : product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey,
            rearBattenColour: isEmpty(product.items[itemIndex].configuration.rearBattenColour.selected.value) ? "" : product.items[itemIndex].configuration.rearBattenColour.selected.value.optionKey,
            isMatchingBatten: product.items[itemIndex].configuration.isMatchingBatten.selected.value,

            finishWidth: product.items[itemIndex].configuration.productionCalculation.selected.finishWidth,
            finishDrop: product.items[itemIndex].configuration.productionCalculation.selected.finishDrop,
            bottomPocketOffset: product.items[itemIndex].configuration.productionCalculation.selected.bottomPocketOffset,
            mechSize: product.items[itemIndex].configuration.productionCalculation.selected.mechSize,
            maxDrawSpace: product.items[itemIndex].configuration.productionCalculation.selected.maxDrawSpace,
            drawQty: product.items[itemIndex].configuration.productionCalculation.selected.drawQty,
            drawSpace: product.items[itemIndex].configuration.productionCalculation.selected.drawSpace,
            foldSpace: product.items[itemIndex].configuration.productionCalculation.selected.foldSpace,
            topPocketAllowance: product.items[itemIndex].configuration.productionCalculation.selected.topPocketAllowance,
            topPocket: product.items[itemIndex].configuration.productionCalculation.selected.topPocket,
            otherPocket: product.items[itemIndex].configuration.productionCalculation.selected.otherPocket,
            maxPocketSpace: product.items[itemIndex].configuration.productionCalculation.selected.maxPocketSpace,
            pocketQty: product.items[itemIndex].configuration.productionCalculation.selected.pocketQty,
            pocketSpace: product.items[itemIndex].configuration.productionCalculation.selected.pocketSpace,
            bottomPocket: product.items[itemIndex].configuration.productionCalculation.selected.bottomPocket,
            fabricCutDrop: product.items[itemIndex].configuration.productionCalculation.selected.fabricCutDrop,
            fabricCutWidth: product.items[itemIndex].configuration.productionCalculation.selected.fabricCutWidth,
            headRailCutLength: product.items[itemIndex].configuration.productionCalculation.selected.headRailCutLength,
            driveShaftCutLength: product.items[itemIndex].configuration.productionCalculation.selected.driveShaftCutLength,
            sewLoopCutLength: product.items[itemIndex].configuration.productionCalculation.selected.sewLoopCutLength,
            liftCordLength: product.items[itemIndex].configuration.productionCalculation.selected.liftCordLength,
            rearSupportBatten: product.items[itemIndex].configuration.productionCalculation.selected.rearSupportBatten,
            rearBattenClip: product.items[itemIndex].configuration.productionCalculation.selected.rearBattenClip,
            bottomRailSplineCutLength: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailSplineCutLength,

            stocks: product.items[itemIndex].stocks
        };
        return salesOrderItemRomanBlind;
    }
}

export default RomanBlindUtil.Instance();