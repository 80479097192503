import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody, CardFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import { findIndex, getLocalDateStringFromUtcDateString,handleErrorMessage } from '../../services/CommonService';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import StockInventoryService from '../../services/StockInventoryService';

export default class StockInventoryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockInventoryData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        isSoldOut: null,
                        isAvailable: null,
                        minQOH: 0,
                        maxQOH: 0,
                        qoh: 0,
                        prodCode: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
        };

    }

    componentDidMount() {
        this.getStockInventory(this.state.stockInventoryData);
    }

    getStockInventory(stockInventoryData) {
        this.setState({ loading: true });

        StockInventoryService.listStockInventory(stockInventoryData.request).then(response => {
            stockInventoryData.response = response.data;
            this.setState({ stockInventoryData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.minQOH.toLowerCase().includes(searchText);

            if (!flag && item.maxQOH) {
                flag = item.maxQOH.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let { stockInventoryData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (stockInventoryData.request.sortRequest.key === change) {
                    stockInventoryData.request.sortRequest.direction = !stockInventoryData.request.sortRequest.direction;
                } else {
                    stockInventoryData.request.sortRequest.key = change;
                    stockInventoryData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockInventory(stockInventoryData);
                }
                break;
            case "pageSize":
                stockInventoryData.request.pageRequest[key] = parseInt(change);
                stockInventoryData.request.pageRequest.currentPage = 1;
                this.getStockInventory(stockInventoryData);
                break;
            case "currentPage":
                stockInventoryData.request.pageRequest[key] = change;
                this.getStockInventory(stockInventoryData);
                break;

            case "maxQOH":
            case "minQOH":
                if (change == null || change == '' || change == undefined) {
                    stockInventoryData.request.filterRequest[key] = 0;
                } else {
                    stockInventoryData.request.filterRequest[key] = change;
                }
                stockInventoryData.request.pageRequest.currentPage = 1;
                this.getStockInventory(stockInventoryData);
                this.setState({ stockInventoryData });
                break;

            default:
                stockInventoryData.request.filterRequest[key] = change;
                stockInventoryData.request.pageRequest.currentPage = 1;
                this.getStockInventory(stockInventoryData);
                this.setState({ stockInventoryData });
        }

    }

    getstockInventoryHeading({ filterRequest }) {
        let stockInventoryHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
            {
                key: "prodCode",
                label: "Stock Code",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="prodCode" name="prodCode"
                    value={filterRequest.prodCode}
                    onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                    placeholder="Search..." /></div>
            },
            {
                key: "minQOH",
                label: "MinQOH",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="minQOH" name="minQOH"
                    value={filterRequest.minQOH || ''}
                    onChange={(e) => this.handleChange(e.target.value, "minQOH")}
                    placeholder="Search..." /></div>

            },
            {
                key: "maxQOH",
                label: "MaxQOH",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="maxQOH" name="maxQOH"
                    value={filterRequest.maxQOH || ''}
                    onChange={(e) => this.handleChange(e.target.value, "maxQOH")}
                    placeholder="Search..." /></div>

            },
            {
                key: "qoh",
                label: "Qty On Hand",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "allocated",
                label: "Qty Allocated",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "free",
                label: "Free",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },

            {
                key: "dateSoldOut",
                label: "Date Sold Out",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "isSoldOut",
                label: "Is SoldOut",
                type: "text",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "isAvailable",
                label: "Is Available",
                type: "text",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: null
            }];
        return stockInventoryHeading;
    }


    render() {

        let { stockInventoryData, loading, searchText } = this.state;
        let { pageRequest, sortRequest } = stockInventoryData.request;
        let stockInventoryHeading = this.getstockInventoryHeading(stockInventoryData.request);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Inventory</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Stock Inventory</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <Spinner color="primary" /> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockInventoryData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(stockInventoryHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(stockInventoryHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(stockInventoryData.response.records || []).filter((stockUnitType) => this.searchFunction(stockUnitType, searchText)).map((stockInventory, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td className={"text-left"}>{stockInventory.prodCode}</td>
                                            <td className={"text-right"}>{stockInventory.minQOH}</td>
                                            <td className={"text-right"}>{stockInventory.maxQOH}</td>
                                            <td className={"text-right"}>{stockInventory.qoh}</td>
                                            <td className={"text-right"}>{stockInventory.allocated}</td>
                                            <td className={"text-right"}>{stockInventory.free}</td>
                                            <td className={"text-center"}>{getLocalDateStringFromUtcDateString(stockInventory.dateSoldOut, 'DD/MM/YYYY')}</td>
                                            <td className={"text-center"}>
                                                <i className={classnames("ml-2", "fa", "fa-lg", {
                                                    "fa-check-square-o": stockInventory.isSoldOut,
                                                    "fa-square-o": !stockInventory.isSoldOut
                                                })} />
                                            </td>
                                            <td className={"text-center"}>
                                                <i className={classnames("ml-2", "fa", "fa-lg", {
                                                    "fa-check-square-o": stockInventory.isAvailable,
                                                    "fa-square-o": !stockInventory.isAvailable
                                                })} />
                                            </td>
                                            <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                to={"/inventory/stock-inventory/manage/one?" + queryString.stringify({ prodCode: stockInventory.prodCode })}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                                Edit</Link></td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockInventoryData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
