import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import StockEnquiryPageSalesHistoryTabSoldUnitsByMonthComparison from "./StockEnquiryPageSalesHistoryTabSoldUnitsByMonthComparison";
import StockEnquiryPageSalesHistoryTabSoldUnitsMonthly from "./StockEnquiryPageSalesHistoryTabSoldUnitsMonthly";

export default class StockEnquiryPageSalesHistoryTab extends Component {


    render() {
        let {stock} = this.props;
        return (
            <div>
                <Row>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <StockEnquiryPageSalesHistoryTabSoldUnitsMonthly stock={stock} showAverage={false} />
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                        <div>
                            <StockEnquiryPageSalesHistoryTabSoldUnitsByMonthComparison prodCode={stock.prodCode}/>
                        </div>
                    </Col>

                </Row>
            </div>
        );
    }
}