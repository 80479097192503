import React, {Component} from "react";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from 'reactstrap';
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dispatchOrderService from '../../services/DispatchOrderService';
import {getDateObj, getDateString, handleErrorMessage} from "../../services/CommonService";

export default class ManageDispatchOrderPickupDateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrder: this.props.selectedOrder,
            isLoading: false,
            pickupDate: ''
        }
        this.getPickUpObj = this.getPickUpObj.bind(this);
    }

    componentDidMount() {
        this.getPickUpObj();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedOrder !== nextProps.selectedOrder) {
            this.setState({selectedOrder: nextProps.selectedOrder}, () => {
                this.getPickUpObj();
            });
        }
    }

    getPickUpObj() {
        let {selectedOrder} = this.state;
        selectedOrder.pickupDateObj = getDateObj(selectedOrder.pickupDate, 'DD/MM/YYYY HH:mm:ss');
        this.setState({selectedOrder});
    }

    handleChange(value, key) {
        let {selectedOrder} = this.state;
        selectedOrder[key] = value;
        this.setState({selectedOrder});
    }

    handleSubmit() {
        let {selectedOrder} = this.state;
        this.setState({isLoading: true});
        let pickupDate = getDateString(selectedOrder.pickupDateObj, "DD/MM/YYYY HH:mm:ss");
        dispatchOrderService.updateDispatchOrderPickupDate(selectedOrder.ordNum, pickupDate).then(response => {
            this.setState({isLoading: false});
            toast.success("Successfully updated!");
            this.props.toggle(false);
            this.props.refreshAgain();
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {selectedOrder, isLoading} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    #{selectedOrder.ordNum}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="pickupDate">Pickup Date</Label>
                                <DatePicker
                                    className="form-control form-control"
                                    selected={selectedOrder.pickupDateObj}
                                    onChange={date => this.handleChange(date, "pickupDateObj")}
                                    selectsStart
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="pickupDate"
                                    showMonthDropdown
                                    showYearDropdown
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.handleSubmit()} disabled={isLoading}>
                            {isLoading
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           style={{color: "white"}}/>
                                : <i className="fa fa-floppy-o mr-2"/>}
                            Save</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times"/>&nbsp;
                            Cancel</Button>

                    </div>
                </ModalFooter>

            </Modal>
        );
    }
}