import React, { Component } from 'react';
import { Button, Input, Row, Col, Label, Spinner } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { maxDate, minDate } from './OrderGatewayCommon';
import SearchDebtorAcccount from '../search/SearchDebtorAcccount';

export default class SearchBannerKeywayGatewayOrder extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let { filterRequest, salesOrderJobStatuses, branches, downloading } = this.props;
        let filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
            return stat.id < 30;
        })
        return (
            <div>
                <Row>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Account</Label>
                        <SearchDebtorAcccount
                            handleAccountChange={(selectedAccountID) => this.props.handleChange(selectedAccountID, "accountID")}
                            selectedAccountID={filterRequest.accountID}
                            defaultAccountID={filterRequest.accountID}
                        />
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.startDate}
                                onChange={date => this.props.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.endDate}
                                onChange={date => this.props.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Status</Label>
                        <div>
                            <Input type={"select"}
                                name={"salesOrderJobStatusId"}
                                value={filterRequest.salesOrderJobStatusId ? filterRequest.salesOrderJobStatusId : ""}
                                onChange={(e) => this.props.handleChange(e.target.value, "salesOrderJobStatusId")}>
                                <option value={""}>All</option>
                                {(filtered_status || []).map((option, index) =>
                                    <option key={index} value={option.id}>{option.statusDescription}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    {/*<Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Quote/Order</Label>
                        <div>
                            <Input type={"select"}
                                name={"isQuote"}
                                value={filterRequest.isQuote}
                                onChange={(e) => this.props.handleChange(e.target.value, "isQuote")}>
                                <option value={null}>All</option>
                                <option value={false}>Order</option>
                                <option value={true}>Quote</option>
                            </Input>
                        </div>
                    </Col>*/}
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Branch</Label>
                        <div>
                            <Input type={"select"}
                                name={"branch"}
                                value={filterRequest.branch}
                                onChange={(e) => this.props.handleChange(e.target.value, "branch")}>
                                <option value={"All"}>All</option>
                                {(branches || []).map((option, index) =>
                                    <option key={index} value={option.branchNum}>{option.shortName}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Rep</Label>
                        <div>
                            <Input type={"select"}
                                placeholder={"Search RepCode..."}
                                name={"repCode"}
                                value={filterRequest.repCode || ''}
                                onChange={(e) => this.props.handleChange(e.target.value, "repCode")} >
                                <option value="ALL">All</option>
                                {this.props.reps.map((rep, index) =>
                                    <option key={index} value={rep.repCode}>{rep.name}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}