import React, {Component} from "react";
import {Button, Spinner} from 'reactstrap';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import {handleErrorMessage, getCommonAddressString, findIndex} from '../../services/CommonService';
import {ToastContainer, toast} from "react-toastify";
import SearchPurchaseOrder from '../../components/search/SearchPurchaseOrder'
import queryString from 'query-string'
import OrderEnquiryReadMode from './OrderEnquiryReadMode'

export default class PurchaseOrderEnquiryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordNum: 0,
            isLoadingOrder: false,
            isLoadingOrdNum: false,
            isLoadingOrderItems: false,
            order: {}

        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.updateUrl = this.updateUrl.bind(this);
        this.getPurchaseOrder = this.getPurchaseOrder.bind(this);
    }

    componentDidMount() {
        let ordNum = 0;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            ordNum = searchParams.ordNum;
        }

        this.setState({ordNum}, () => {
            this.refresh()
        });
    }

    refresh() {
        if (!this.state.ordNum) {
            this.getLastOrdNum();
        }

    }

    getLastOrdNum() {
        this.setState({isLoadingOrdNum: true});
        this.purchaseOrderService.getOrderNoByStatus('last').then(response => {
            this.setState({isLoadingOrdNum: false, ordNum: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
        })
    }

    updateUrl(ordNum) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("ordNum", ordNum);

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    getPurchaseOrder(ordNum) {
        this.updateUrl(ordNum);
        if (ordNum) {
            this.setState({ordNum, isLoadingOrder: true});
            this.purchaseOrderService.getOrder(ordNum).then(response => {
                this.setState({isLoadingOrder: false});
                let order = response.data;
                order.shippingAdrress = getCommonAddressString(order);
                this.setState({ order });
            }).catch(error => {
                this.setState({isLoadingOrder: false});
                toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
            })
        }
    }

    render() {
        let {ordNum, isLoadingOrder, isLoadingOrdNum, order, isLoadingOrderItems} = this.state;
        let result = null;
        if (isLoadingOrder) {
            result = <Spinner color={"primary"}/>;
        }
        else {
            result = <OrderEnquiryReadMode
                isLoadingOrder={isLoadingOrder}
                isLoadingOrderItems={isLoadingOrderItems}
                order={order}
                getPurchaseOrder={this.getPurchaseOrder}
            />
        }
        return (
            <div>
                <div>
                    <SearchPurchaseOrder ordNum={ordNum}
                                         history={this.props.history}
                                         isLoadingOrder={isLoadingOrder}
                                         isLoadingOrdNum={isLoadingOrdNum}
                                         getPurchaseOrder={this.getPurchaseOrder}/>
                </div>
                <hr/>
                {result}
                <ToastContainer />
            </div>

        );
    }
}