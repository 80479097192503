import React, { Component } from 'react';
import { concatenateStrings } from "../../../../../../services/CommonService";

export let verticalOutdoorLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "quantity",
        label: <div>
            Qty
            <div>(unit)</div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "width",
        label: <div>
            Width
            <div>
                (mm)
            </div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "drop",
        label: <div>
            Drop
            <div>
                (mm)
            </div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "model",
        label: "Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "fabric",
        label: "Fabric Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour, data.productLabel.fabricColourOffRange);
        }
    },
    {
        key: "fabricCutDrop",
        label: "Fabric Cut Drop",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "fabricCutWidth",
        label: "Fabric Cut Width",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },


    {
        key: "keyway",
        label: "Keyway",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "hooding",
        label: "Hooding/Headrail",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.hooding, data.productLabel.hoodingColour, data.productLabel.hoodingColourPowdercoat);
        }
    },

    {
        key: "bottomRail",
        label: "Bottom Rail",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.bottomRail, data.productLabel.bottomFinish, data.productLabel.frameColour);
        }
    },

    {
        key: "valance",
        label: "Valance",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }

];