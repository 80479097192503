import axios from './axios';
export default class ManagePrintersService{

    getAllPrintersList(){
        return axios.get('api/printer/get/list');
    }
    getPrinterStatus(){
        return axios.get('api/printer/statuses/get/all');
    }
    savePrinterChange(request)
    {
        return axios.post('api/printer/save',request);
    }
    getPrinterJobTypes(){
        return axios.get('api/printer/job/types/get/all');
    }
    getAllPrinterJobsList(req){
        return axios.post('api/printer/jobs/list',req);
    }
    getPrintingStatuses(){
        return axios.get('api/printer/printing/statuses')
    }
    saveUpdatedJob(id,printingStatus){
        return axios.post('api/printer/job/update?ID=' +id + '&printingStatus='+printingStatus);
    }

}