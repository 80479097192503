import {authHeader} from "../helpers/auth-header";
import axios from "./axios";
import {config} from "../helpers/config";

export default class StockManageService {
    saveStock(req) {
        return axios.post('api/stock-manage/save', req);
    }

    getStock(prodCode) {
        return axios.get('api/stock-manage/one/?prodCode=' + prodCode);
    }

    getStocks(searchText) {

        let requestBody = {
            soldOut: false,
            discontinued: false,
            description: searchText
        };
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
        };
        return axios(config.apiUrl + 'api/stock-manage/summary/list', requestOptions);
    }

    UploadStockImage(requestBody) {
        let user = JSON.parse(localStorage.getItem('user'));
        const request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + "api/stock-manage/image/upload",
            data: requestBody,
        };
        return axios(request)
    }

}