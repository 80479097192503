import React, {Component} from 'react';

import {Button, Modal, ModalBody, ModalHeader, Spinner} from 'reactstrap';
import verticalIndoorUtil from './CustomUtil'
import CustomBOMModalPrint from './CustomBOMModalPrint'
import StockService from '../../../../../../services/StockService'
import ReactToPrint from "react-to-print";
import {cloneDeep, isEmpty} from 'lodash';
import CustomUtil from './CustomUtil';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import { toast } from 'react-toastify';
import { handleErrorMessage } from '../../../../../../services/CommonService';
import CustomSaveTemplateModal from '../../../../../../components/modal/CustomSaveTemplateModal';

export default class CustomBOMModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: {},
            itemIndex: -1,
            stocksByProdCode: {},
            templateData: {
                templateName: '',
                description: '',
                isActive: true,
                customTemplateID: 0
            },
            formError: {},
            isSavingTemplate: false,
            isOpenTemplateModal: false,
            isLoading: false
        };
        this.stockService = new StockService();
        this.addStockInStocksByProdCode = this.addStockInStocksByProdCode.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.toggleTemplateModal = this.toggleTemplateModal.bind(this);
        this.populateTemplateData = this.populateTemplateData.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        let { product, itemIndex, stocksByProdCode } = this.props;
        product = verticalIndoorUtil.getItemStocks(product, itemIndex);
        let prodCodes = product.items[itemIndex].stocks.map(stock => stock.prodCode);
        this.populateTemplateData();
        this.setState({product, itemIndex, isLoading: true});
        this.stockService.getStocksByProdCode(prodCodes.join()).then(response => {
            stocksByProdCode = {};
            if (!isEmpty(response.data)) {
                stocksByProdCode = response.data;
            }
            this.setState({stocksByProdCode, isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
            console.error(error);
        })
    }

    populateTemplateData() {
        let { templateData } = this.state;
        let { product, itemIndex } = this.props;
        if (product.items[itemIndex]["templateData"]) {
            templateData = product.items[itemIndex].templateData;
            this.setState({ templateData });
        }
    }

    addStockInStocksByProdCode(stock) {
        let {stocksByProdCode} = this.state;
        if (!stocksByProdCode) {
            stocksByProdCode = {};
        }
        if (stock && stock.prodCode) {
            stocksByProdCode[stock.prodCode] = stock;
            this.setState({stocksByProdCode});
        }
    }
    
    saveTemplate() {
        let { product, itemIndex, templateData } = this.state;
        let formError = this.validateForm(templateData);
        this.setState({ formError });
        if (!formError.isValid) {
            formError.errors.forEach(error => {
                toast.info(error);
            });
            return;
        }
        let customTemplate = CustomUtil.toProductTemplateCustomRequest(product, itemIndex, templateData);
        let customItemTemplates = CustomUtil.toProductTemplateCustomItemRequest(product, itemIndex, templateData);
        let request = {
            customTemplate: customTemplate,
            customItemTemplates: customItemTemplates
        }
        this.setState({ isSavingTemplate: true });
        salesOrderProductBuilderV1Service.saveCustomTemplates(request).then(response => {
            if (response.status === 200) {
                this.setState({ isSavingTemplate: false, isOpenTemplateModal: false });
                toast.success("Template saved successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            this.setState({ isSavingTemplate: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    validateForm(templateData) {
        let formError = {
            isValid: true,
            errors: []
        };
        let message = "";

        if (!templateData.templateName) {
            message = "Empty template name!";
            formError.templateName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        return formError;
    }

    handleTemplateChange(change, key) {
        let { templateData } = this.state;
        if (key) {
            templateData[key] = change;
            this.setState({ templateData });
        }
    }

    toggleTemplateModal(change) {
        this.setState({ isOpenTemplateModal: change });
    }

    render() {
        let { product, itemIndex, stocksByProdCode, isLoading, formError,
            templateData, isSavingTemplate, isOpenTemplateModal } = this.state;
        let { isOpen, toggle, order, handleChange, hasProductBuilderTemplateWritePrivilege } = this.props;
        if (!(itemIndex > -1)) {
            return null;
        }
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    <ReactToPrint
                        trigger={() => {
                            return <Button color={"primary"} size={"sm"}>
                                <i className="fa fa-print mr-2"
                                   aria-hidden="true"/>Print
                            </Button>;
                        }}
                        content={() => this.componentRef}
                    />
                </ModalHeader>
                <ModalBody>
                    {
                        isLoading
                            ? <Spinner color={"primary"}/>
                            : <div>

                                <CustomBOMModalPrint product={product}
                                    order={order}
                                    itemIndex={itemIndex}
                                    stocksByProdCode={stocksByProdCode}
                                    ref={el => (this.componentRef = el)}
                                    handleChange={handleChange}
                                    addStockInStocksByProdCode={this.addStockInStocksByProdCode}
                                    templateData={templateData}
                                    toggleTemplateModal={this.toggleTemplateModal}
                                    saveTemplate={this.saveTemplate}
                                    isSavingTemplate={isSavingTemplate}
                                    toggle={this.props.toggle}
                                    hasProductBuilderTemplateWritePrivilege={hasProductBuilderTemplateWritePrivilege}
                                />
                            </div>
                    }
                </ModalBody>
                {
                    isOpenTemplateModal ?
                        <CustomSaveTemplateModal handleChange={this.handleTemplateChange}
                            templateData={templateData}
                            isOpen={isOpenTemplateModal}
                            toggle={this.toggleTemplateModal}
                            saveTemplate={this.saveTemplate}
                            isSavingTemplate={isSavingTemplate}
                            formError={formError}
                        />
                        : null
                }
            </Modal>
        )
    }
}