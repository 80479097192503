import React, { Component } from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Row,
    Spinner, Table
} from "reactstrap";
import StockInventoryService from "../../services/StockInventoryService";
import {toast, ToastContainer} from "react-toastify";
import CurrencyService from "../../services/CurrencyService";
import classnames from "classnames";
import {getLocalDateStringFromUtcDateString, getDateString,handleErrorMessage} from "../../services/CommonService";
import {Link} from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-js-pagination";
export default class CurrencyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currencyData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
        };
    }
    componentDidMount() {
        this.getCurrency(this.state.currencyData);
    }
    getCurrency(currencyData) {
        this.setState({ loading: true });

        CurrencyService.getCurrencyList(currencyData.request).then(response => {
            currencyData.response = response.data;
            this.setState({ currencyData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.currencyType) {
                flag = item.currencyType.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let { currencyData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (currencyData.request.sortRequest.key === change) {
                    currencyData.request.sortRequest.direction = !currencyData.request.sortRequest.direction;
                } else {
                    currencyData.request.sortRequest.key = change;
                    currencyData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getCurrency(currencyData);
                }
                break;
            case "pageSize":
                currencyData.request.pageRequest[key] = parseInt(change);
                currencyData.request.pageRequest.currentPage = 1;
                this.getCurrency(currencyData);
                break;
            case "currentPage":
                currencyData.request.pageRequest[key] = change;
                this.getCurrency(currencyData);
                break;

            default:
                currencyData.request.filterRequest[key] = change;
                currencyData.request.pageRequest.currentPage = 1;
                this.getCurrency(currencyData);
                this.setState({ currencyData });
        }

    }

    getCurrencyHeading (filterRequest)
    {
        let currencyHeading=[
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "currencyType",
                label: "Currency Type",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="currencyType" name="currencyType"
                                        value={filterRequest.currencyType}
                                        onChange={(e) => this.handleChange(e.target.value, "currencyType")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="description" name="description"
                                        value={filterRequest.description}
                                        onChange={(e) => this.handleChange(e.target.value, "description")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "accountingExchangeRate",
                label: "Accounting Exchange Rate",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "costingExchangeRate",
                label: "Costing Exchange Rate",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "accountingRateUpdated",
                label: "Accounting Rate Updated",
                type: "text",
                colSpan: 1,
                minWidth: 150,

            },
            {
                key: "costingRateUpdated",
                label: "Costing Rate Updated",
                type: "text",
                colSpan: 1,
                minWidth: 150,

            },
            {
                key: "dateCreated",
                label: "Date Created",
                type: "text",
                colSpan: 1,
                minWidth: 150,

            },
            {
                key: "isLocalCurrency",
                label: "IsLocalCurrency",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                maxWidth: 50,
            },
            {
                key: "currencySymbol",
                label: "Currency Symbol",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                maxWidth: 50,
            },

        ];
        return currencyHeading;
    }
render()
{
    let { currencyData, loading, searchText } = this.state;
    let { pageRequest, sortRequest } = currencyData.request;
    let currencyHeading = this.getCurrencyHeading(currencyData.request);
    return (
        <div>
        <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
            <BreadcrumbItem active tag="span">Currency</BreadcrumbItem>
        </Breadcrumb>
            <Card>
                <CardHeader><h5>Currency</h5></CardHeader>
                <CardBody>
                    <Row>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"text-left"}>
                                {loading ? <Spinner color="primary" /> :
                                    <p>Showing
                                        {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                        {' '}of {currencyData.response.totalRecords}
                                        {' '}entries</p>
                                }
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-right"}>
                                <InputGroup className={"mb-2"}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fa fa-search"
                                               aria-hidden="true" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"text"}
                                        name={"searchText"}
                                        placeholder={"Search..."}
                                        value={searchText}
                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                    />
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <Table striped bordered responsive hover size='sm'>
                            <thead>
                            <tr>
                                {(currencyHeading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true" /> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(currencyHeading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>

                            </thead>

                            <tbody>
                            {(currencyData.response.records || []).filter((currencyType) => this.searchFunction(currencyType, searchText)).map((currency, index) =>

                                <tr key={index}>
                                    <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                    <td>{currency.currencyType}</td>
                                    <td>{currency.description}</td>
                                    <td className={"text-right"}>{currency.accountingExchangeRate}</td>
                                    <td className={"text-right"}>{currency.costingExchangeRate}</td>
                                    <td className={"text-center"}>{getDateString(currency.accountingRateUpdated, 'DD/MM/YYYY')}</td>
                                    <td className={"text-center"}>{getDateString(currency.costingRateUpdated, 'DD/MM/YYYY')}</td>
                                    <td className={"text-center"}>{getDateString(currency.dateCreated, 'DD/MM/YYYY')}</td>
                                    <td className={"text-center"}>
                                        <i className={classnames("ml-2", "fa", "fa-lg", {
                                            "fa-check-square-o": currency.isLocalCurrency,
                                            "fa-square-o": !currency.isLocalCurrency
                                        })} /></td>
                                    <td className={"text-center"}>{currency.currencySymbol}</td>
                                </tr>
                            )}

                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-left"} style={{ maxWidth: 200 }}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Show</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"select"}
                                        name={"pageSize"}
                                        value={pageRequest.pageSize}
                                        disabled={loading}
                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                        <option value={10}>10 Rows</option>
                                        <option value={25}>25 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>


                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"float-right"}>
                                <Pagination
                                    activePage={pageRequest.currentPage}
                                    itemsCountPerPage={pageRequest.pageSize}
                                    totalItemsCount={currencyData.response.totalRecords}
                                    pageRangeDisplayed={3}
                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'
                                />
                            </div>

                        </Col>
                    </Row>
                </CardFooter>
            </Card>
            <ToastContainer />
        </div>
    );
}
}