import React, { Component } from 'react';
import { concatenateStrings } from "../../../../../../services/CommonService";

export let curtainTrackCustomLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "model",
        label: "Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.operation, data.productLabel.remote ? "Remote" : "", data.productLabel.charger ? "Charger" : "",);
        }
    },
    {
        key: "bendType",
        label: "Bend",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.bendType, data.productLabel.bendShape);
        }
    }
    , {
        key: "trackType",
        label: "Track Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(",", data.productLabel.trackType, data.productLabel.trackColour, data.productLabel.trackOnly ? 'Track Only' : '');
        }
    },
    {
        key: "trackCutWidth",
        label: "Track Cut Width",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "control",
        label: "Control/Motor",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.control, data.productLabel.cordType, data.productLabel.cordSizeType, data.productLabel.cordSize);

        }
    }, {
        key: "bracket",
        label: "Bracket",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.bracket, data.productLabel.projection, data.productLabel.returnOption);

        }
    }, {
        key: "runner",
        label: "Runner",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.runner, data.productLabel.runnerColour);

        }
    },
    {
        key: "stacking",
        label: "Stacking",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }

];