import React, { Component } from 'react';
import { Col, Row, Input, Label } from "reactstrap";
import classnames from 'classnames';
//import SearchStock from "../../components/search/SearchStock";
import SearchFabricProdCodes from "../../components/search/SearchFabricProdCodes";

const fabricTypes = [
    {
        label: "All", value: "ALL"
    },
    {
        label: "Outdoor", value: "OUTDOOR"
    },
    {
        label: "Indoor", value: "INDOOR"
    }
]

export default class StockTakeComponentSearchBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stock: {}
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selected) {
        if (selected) {
            this.setState({ stock: selected }, () => this.props.handleStockChange(selected));
        }
    }

    render() {
        let { filterRequest } = this.props;
        return (
            <div>
                <Row>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Label>Fabric</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search Fabric..."}
                                name={"fabricName"}
                                value={filterRequest.fabricName}
                                onChange={(e) => this.props.handleChange(e.target.value, "fabricName")} />
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Label>Fabric</Label>
                        <div className={"text-center"}>
                            <Input type={"select"}
                                name={"fabricType"}
                                value={filterRequest.fabricType}
                                onChange={(e) => this.props.handleChange(e.target.value, "fabricType")}>
                                {fabricTypes.map((option, index) =>
                                    <option key={index} value={option.value}>{option.label}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Label>Fabric Category</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search Category..."}
                                name={"category"}
                                value={filterRequest.category}
                                onChange={(e) => this.props.handleChange(e.target.value, "category")} />
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Label>Search Stock</Label>
                        <SearchFabricProdCodes handleSelection={this.handleChange} selected={this.state.stock}
                            defaultSelected={this.state.stock} />
                    </Col>
                </Row>
            </div>
        );
    }
}
