import React, {Component} from "react";
import WarehouseLocationTypeService from "../../services/WarehouseLocationTypeService";
import {toast, ToastContainer} from "react-toastify";
import StockPriceHistoryService from "../../services/StockPriceHistoryService";
import {
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Row, Table
} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import queryString from "query-string";
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import {getLocalDateStringFromUtcDateString,getDateString} from "../../services/CommonService";
import SearchWINOnlineStock from '../../components/search/SearchWINOnlineStock';

export default class StockInventoryPriceHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockPriceHistoryData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        ProdCode:'',
                        IsCurrent:false,
                        MarginRate:0,
                        MarginValue:0,
                        Price:0,
                        updatedBy:''

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',

        };
    }

    componentDidMount() {
        this.getStockPriceHistory(this.state.stockPriceHistoryData);
    }

    getStockPriceHistory(stockPriceHistoryData) {
        this.setState({loading: true});
        StockPriceHistoryService.getStockPriceHistory(stockPriceHistoryData.request).then(response => {
            stockPriceHistoryData.response = response.data;
            this.setState({stockPriceHistoryData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.name) {
                flag = item.name.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {stockPriceHistoryData: stockPriceHistoryData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (stockPriceHistoryData.request.sortRequest.key === change) {
                    stockPriceHistoryData.request.sortRequest.direction = !stockPriceHistoryData.request.sortRequest.direction;
                } else {
                    stockPriceHistoryData.request.sortRequest.key = change;
                    stockPriceHistoryData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockPriceHistory(stockPriceHistoryData);
                }
                break;
            case "pageSize":
                stockPriceHistoryData.request.pageRequest[key] = parseInt(change);
                stockPriceHistoryData.request.pageRequest.currentPage = 1;
                this.getStockPriceHistory(stockPriceHistoryData);
                break;
            case "currentPage":
                stockPriceHistoryData.request.pageRequest[key] = change;
                this.getStockPriceHistory(stockPriceHistoryData);
                break;


            default:
                stockPriceHistoryData.request.filterRequest[key] = change;
                stockPriceHistoryData.request.pageRequest.currentPage = 1;
                this.getStockPriceHistory(stockPriceHistoryData);
                this.setState({stockPriceHistoryData: stockPriceHistoryData});
        }

    }

    getStockPriceHistoryHeading({filterRequest}) {
        let stockPriceHistoryHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",

            },
            {
                key: "name",
                label: "ProdCode",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div>{/*<Input type="text" id="prodCode" name="prodCode"
                                        value={filterRequest.prodCode}
                                        onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                                        placeholder="Search..."/>*/}
                    <SearchWINOnlineStock
                        handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'ProdCode')}
                        selectedProdCode={filterRequest.prodCode}
                        defaultProdCode={filterRequest.prodCode} /></div>

            },
            {
                key: "marginRate",
                label: "Margin Rate",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "marginValue",
                label: "Margin Value",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "price",
                label: "Price",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "iscurrent",
                label: "IsCurrent",
                type: "text",
                colSpan: 1,
                minWidth: 80,
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 200,

            },
            {
                key: "updatedDate",
                label: "Last Updated Date",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "updatedBy",
                label: "Last Updated By",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                searchNode: <div><Input type="text" id="updatedBy" name="updatedBy"
                                        value={filterRequest.updatedBy}
                                        onChange={(e) => this.handleChange(e.target.value, "updatedBy")}
                                        placeholder="Search..."/></div>
            }
            ];

        return stockPriceHistoryHeading;
    }

    render() {

        let {stockPriceHistoryData, loading, searchText} = this.state;
        let {pageRequest, sortRequest} = stockPriceHistoryData.request;
        let stockPriceHistoryHeading = this.getStockPriceHistoryHeading(stockPriceHistoryData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Price History</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Stock Price History </h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockPriceHistoryData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>

                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(stockPriceHistoryHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(stockPriceHistoryHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(stockPriceHistoryData.response.records || []).filter((stockPriceHistoryType) => this.searchFunction(stockPriceHistoryType, searchText)).map((stockPriceHistoryType, index) =>

                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td><Link
                                            className="btn btn-sm btn-primary"
                                            style={{ color: "white" }}
                                            title={"Click here to see stock details"}
                                            to={"/keyway-inventory/stock/enquiry?prodCode=" +  stockPriceHistoryType.prodCode }>
                                            {stockPriceHistoryType.prodCode}</Link></td>
                                        <td className={"text-right"}>{stockPriceHistoryType.marginRate+" %"}</td>
                                        <td className={"text-right"}><NumberFormat
                                            prefix={'$'}
                                            value={stockPriceHistoryType.marginValue}
                                            displayType={'text'}
                                            decimalScale={0}
                                            thousandSeparator={true}/></td>
                                        <td className={"text-right"}><NumberFormat
                                            prefix={'$'}
                                            value={stockPriceHistoryType.price}
                                            displayType={'text'}
                                            decimalScale={0}
                                            thousandSeparator={true}/></td>
                                        <td className={"text-center"}>
                                            <i className={classnames("ml-2", "fa", "fa-lg", {
                                                "fa-check-square-o": stockPriceHistoryType.isCurrent,
                                                "fa-square-o": !stockPriceHistoryType.isCurrent
                                            })} />
                                        </td>
                                        <td>{stockPriceHistoryType.description}</td>
                                        <td>{getDateString(stockPriceHistoryType.updatedDate, 'DD/MM/YYYY')}</td>
                                        <td>{stockPriceHistoryType.updatedBy}</td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockPriceHistoryData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}