import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col,
    Button, Badge,
    Label, Input, FormText,
    Card, CardBody,
    Spinner
} from 'reactstrap';
import {getLocalDateStringFromUtcDateString} from '../../services/CommonService';
import SearchUser from '../../components/search/SearchUserEmail';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Editor from '../../store/ReactQuillEditorConfig';

function FormError(props) {
    let form = props.item;
    switch (props.attribute) {
        case "toEmail":
            if (!form.toEmail) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
        case "fromEmail":
            if (!form.fromEmail) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
        case "subject":
            if (!form.subject) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
        case "shortMessage":
            if (!form.shortMessage) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
        default:
            break;
    }
    return null;
}

export default class ComposeUserInboxMessageModal extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    }

    handleChange(change, key) {
        this.props.onChange(change, key);

    };

    validateAndSubmit() {
        let {message} = this.props;
        let isValid = this.validateForm(message);
        if (isValid) {
            this.props.onSubmit(message);
        } else {
            this.props.onChange(false, "isValidated");

        }
    };

    validateForm(message) {
        let isValid = true;
        if (!message.fromEmail) {
            isValid = false;
        }
        if (!message.toEmail) {
            isValid = false;
        }
        if (!message.subject) {
            isValid = false;
        }
        if (!message.shortMessage) {
            isValid = false;
        }
        return isValid;
    }


    render() {
        let {isOpen, toggle, message, loading} = this.props;


        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Message
                </ModalHeader>
                <ModalBody style={{minHeight: 300}}>

                    <div>
                        <div className="mb-3">
                            <Row>
                                <Col>
                                    <Label for="fromEmail">From*</Label>
                                </Col>
                                <Col className={"text-right"}>
                                    <FormError attribute={"fromEmail"} item={message}
                                               color={message.isValidated ? "muted" : "danger"}/>
                                </Col>
                            </Row>

                            <div id={"fromEmail"}>
                                <Input plaintext value={message.fromEmail} />
                                {/*<SearchUser
                                    role={""}
                                    onChange={(change) => this.handleChange(change, "fromEmail")}
                                    />*/}
                            </div>

                        </div>
                        <div className="mt-3 mb-3">
                            <Row>
                                <Col>
                                    <Label for="toEmail">To*</Label>
                                </Col>
                                <Col className={"text-right"}>
                                    <FormError attribute={"toEmail"} item={message}
                                               color={message.isValidated ? "muted" : "danger"}/>
                                </Col>
                            </Row>

                            <div id={"toEmail"}>
                                <SearchUser
                                    role={""}
                                    onChange={(change) => this.handleChange(change, "toEmail")}
                                    defaultValue={message.toEmail}/>
                            </div>
                        </div>
                        <div className="mt-3">
                            <Label for="ccEmail">CC*</Label>
                            <div id={"ccEmail"}>
                                <SearchUser
                                    role={""}
                                    onChange={(change) => this.handleChange(change, "ccEmail")}
                                    defaultValue={message.ccEmail}/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div className="mb-3">
                            <Row>
                                <Col>
                                    <Label for="subject">Subject*</Label>
                                </Col>
                                <Col className={"text-right"}>
                                    <FormError attribute={"subject"} item={message}
                                               color={message.isValidated ? "muted" : "danger"}/>
                                </Col>
                            </Row>

                            <Input id={"subject"}
                                   onChange={(e) => this.handleChange(e.target.value, "subject")}
                                   type="text"
                                   name="subject"
                                   value={message.subject}
                                   placeholder="write subject here">
                            </Input>

                        </div>
                        <div className="mt-3">
                            <Row>
                                <Col>
                                    <Label for="shortMessage">Short message
                                        <small> (will be display in notifications tray)</small>
                                        *</Label>
                                </Col>
                                <Col className={"text-right"}>
                                    <FormError attribute={"shortMessage"} item={message}
                                               color={message.isValidated ? "muted" : "danger"}/>
                                </Col>
                            </Row>

                            <Input id={"shortMessage"}
                                   onChange={(e) => this.handleChange(e.target.value, "shortMessage")}
                                   type="text"
                                   name="shortMessage"
                                   value={message.shortMessage}
                                   placeholder="write a short message here">
                            </Input>

                        </div>
                    </div>
                    <hr/>
                    <div>

                        <div className="quil-editor-root ">
                            <ReactQuill value={message.body}
                                        modules={Editor.modules}
                                        formats={Editor.formats}
                                        onChange={(change) => this.handleChange(change, "body")}
                                        bounds={'.quil-editor-root'}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onClick={() => this.validateAndSubmit()} disabled={loading.sendMessage}>
                        {loading.sendMessage
                            ? <Spinner size="sm"
                                       className={"mr-2"}
                                       style={{color: "white"}}/>
                            : <i className="fa fa-paper-plane mr-2"/>}
                        {loading.sendMessage ? "Sending" : "Send"}</Button>
                    <Button color={"secondary"} onClick={() => toggle(!isOpen)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }


}