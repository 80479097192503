import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, Button, Spinner, Table, FormText, Input, Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import StockWarehouseLocationService from '../../services/WINOInventory/StockWarehouseLocationService';
import SearchWINOWarehouseLocation from '../search/SearchWINOWarehouseLocation';
import classnames from 'classnames';
import { Link } from "react-router-dom";
import StockEnquiryService from '../../services/WINOInventory/StockEnquiryService';
import queryString from 'query-string';
import SearchWINOnlineStock from '../search/SearchWINOnlineStock';
import {handleErrorMessage} from "../../services/CommonService";

export default class ManageStockStockWarehouseLocation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockProdCode: this.props.prodCode,
            stock: {},
            stockWarehouseLocations: {
                request: {
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        warehouseLocationName: "",
                        stockProdCode: this.props.prodCode,
                    },
                    pageRequest:null,
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loadingstockWarehouseLocations: false,
            savingstockWarehouseLocation: false,
            formErrors: [],
        };

        this.addNewItem = this.addNewItem.bind(this);
        this.handleStockChange = this.handleStockChange.bind(this);
        this.getStock = this.getStock.bind(this);
        this.getStockWarehouseLocationByStock = this.getStockWarehouseLocationByStock.bind(this);

    }

    componentDidMount() {
        let { stockProdCode, stockWarehouseLocations } = this.state;

        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            stockProdCode = searchParams.prodCode;
            stockWarehouseLocations.request.filterRequest.stockProdCode = stockProdCode;
            this.setState({ stockProdCode }, () => {
                this.getStock(stockProdCode);
            })
            this.getStockWarehouseLocationByStock(this.state.stockWarehouseLocations);
        }
    }

    getStock(prodCode) {
        this.setState({ loadingStock: true });
        StockEnquiryService.getStock(prodCode).then(response => {
            this.setState({ stock: response.data, loadingStock: false });
        }).catch(error => {
            this.setState({ loadingStock: false });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }


    getStockWarehouseLocationByStock(stockWarehouseLocations) {
        if (stockWarehouseLocations.request.filterRequest.stockProdCode) {
            this.setState({ loadingstockWarehouseLocations: true });
            StockWarehouseLocationService.getStockWarehouseLocationsBystock(stockWarehouseLocations.request).then(response => {
                stockWarehouseLocations.response = response.data;
                this.setState({ stockWarehouseLocations, loadingstockWarehouseLocations:false});
            }).catch(error => {
                this.setState({ loadingstockWarehouseLocations: false });
                toast.error(handleErrorMessage(error));
                console.log(error.data)
            });
        }
    }

    handleFilterChange(change, key) {
        let { stockWarehouseLocations } = this.state;
        switch (key) {
            case "sortKey":
                if (stockWarehouseLocations.request.sortRequest.key === change) {
                    stockWarehouseLocations.request.sortRequest.direction = !stockWarehouseLocations.request.sortRequest.direction;
                } else {
                    stockWarehouseLocations.request.sortRequest.key = change;
                    stockWarehouseLocations.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockWarehouseLocationByStock(stockWarehouseLocations);
                }
                break;
            default:
                stockWarehouseLocations.request.filterRequest[key] = change;
                this.getStockWarehouseLocationByStock(stockWarehouseLocations);
                this.setState({ stockWarehouseLocations });
        }
       
    }


    handleChange(value, key, index) {
        let { stockWarehouseLocations } = this.state;
        let { records } = stockWarehouseLocations.response;
        records[index][key] = value;
        this.setState({ stockWarehouseLocations });
        

    }

    handleStockChange(selectedProdCode) {
        let { stockWarehouseLocations } = this.state;
        this.setState({ stockProdCode: selectedProdCode });
        if (selectedProdCode) {
            this.getStock(selectedProdCode);
            stockWarehouseLocations.request.filterRequest.stockProdCode = selectedProdCode;
            this.getStockWarehouseLocationByStock(stockWarehouseLocations);
            this.setState({ stockWarehouseLocations });
        }
    }

    addNewItem() {
        let { stockWarehouseLocations, stockProdCode } = this.state;
        let { records } = stockWarehouseLocations.response;
        for (var i in records) {
           records[i].isEditing = false;
        }
        let item = {
            id: 0,
            stockProdCode: stockProdCode,
            warehouseLocation: {},
            warehouseLocationID: 0,
            isActive: true,
            isEditing:true,
            qoh: '',
            minQtyAllocated: '',
            maxQtyAllocated: '',
            
        };
        records.push(item);
        this.setState({ stockWarehouseLocations });
    }

    handleWarehouseLocationChange(change, key,index) {
        let { stockWarehouseLocations } = this.state;
        let { records } = stockWarehouseLocations.response;
        if (change) {
            records[index][key] = change;
            records[index].warehouseLocationID = change.id;
        }
        else {
            records[index][key] = null;
            records[index].warehouseLocationID = null;
        }
        this.setState({ stockWarehouseLocations });
    }
    
    validateForm(stockWarehouseLocationObj) {
        let formErrors = {};
        let valid = true;

        if (stockWarehouseLocationObj.warehouseLocationID === 0 || stockWarehouseLocationObj.warehouseLocationID === '' || stockWarehouseLocationObj.warehouseLocationID === '0' || stockWarehouseLocationObj.warehouseLocationID === null) {
            formErrors.warehouseLocationID = "Please select Warehouse location";
            valid = false;
        }

        if (stockWarehouseLocationObj.qoh === null || stockWarehouseLocationObj.qoh === '') {
            formErrors.qoh = "Please enter QOH";
            valid = false;
        }

        if (stockWarehouseLocationObj.minQtyAllocated === null || stockWarehouseLocationObj.minQtyAllocated === undefined || stockWarehouseLocationObj.minQtyAllocated === '') {
            formErrors.minQtyAllocated = "Please enter Min Qty Allocated";
            valid = false;
        }

        if (stockWarehouseLocationObj.maxQtyAllocated === null || stockWarehouseLocationObj.maxQtyAllocated === undefined || stockWarehouseLocationObj.maxQtyAllocated === '') {
            formErrors.maxQtyAllocated = "Please enter Max Qty Allocated";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    saveStockWarehouseLocation(item, index) {
        let { stockWarehouseLocations, formErrors } = this.state;
        let { records } = stockWarehouseLocations.response;
        let result = this.validateForm(item);

        if (!result.valid) {
            formErrors[index] = result.formErrors;
            this.setState({ formErrors });
            return;
        }
        records[index].saving = true;
        this.setState({ stockWarehouseLocations, formErrors: [] });
        item.stockProdCode = this.state.stockProdCode;
        StockWarehouseLocationService.saveOrUpdateStockWarehouseLocation(item).then(response => {
            records[index].saving = false;
            
            if (records[index].id == 0) {
                toast.success("Stock Warehouse Location saved successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
            else {
                toast.success("Stock Warehouse Location updated successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }

            records[index] = response.data;
            this.setState({ stockWarehouseLocations });

        }).catch(error => {
            records[index].saving = false;
            this.setState({ stockWarehouseLocations });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    deleteStockWarehouseLocation(stockWarehouseLocation,index) {
        let { stockWarehouseLocations } = this.state;
        let { records } = stockWarehouseLocations.response;
        records[index].isDeleting = true;
        this.setState({ stockWarehouseLocations });
        StockWarehouseLocationService.deleteStockWarehouseLocation(stockWarehouseLocation.id).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("Stock Warehouse location deleted succesfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                records[index].isDeleting = false;
                this.setState({ stockWarehouseLocations });
                this.getStockWarehouseLocationByStock(stockWarehouseLocations);
            }
        }).catch(error => {
            records[index].isDeleting = false;
            this.setState({ stockWarehouseLocations });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    editStockWarehouseLocation(index) {
        let { stockWarehouseLocations } = this.state;
        let { records } = stockWarehouseLocations.response;
        for (var i in records) {
            if (i == index) {
                records[i].isEditing = true;
            }
            else {
                records[i].isEditing = false;
            }
        }
        this.setState({ stockWarehouseLocations });
    }

    getStockWarehouseLocationHeading({ filterRequest }) {
        let stockWarehouseLocationHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "warehouseLocationName",
                label: "Warehouse Location",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <div>
                        <Input type={"text"}
                            placeholder={"Search..."}
                            name={"warehouseLocationName"}
                            value={filterRequest.warehouseLocationName || ''}
                            onChange={(e) => this.handleFilterChange(e.target.value, "warehouseLocationName")} />
                    </div>
                </div>

            },
            {
                key: "qoh",
                label: "QOH",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null

            },
            {
                key: "minQtyAllocated",
                label: "Min Qty Allocated",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null

            },
            {
                key: "maxQtyAllocated",
                label: "Max Qty Allocated",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null

            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "250px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                                                               to={{
                                                                   pathname: '/keyway-inventory/warehouse/stock/transfer',
                                                                   state:{ records:this.state.stockWarehouseLocations.response.records , prodCode:this.state.prodCode , description:this.props.description}
                                                               }}
                ><i className="fa fa-exchange " />&nbsp;
                    Transfer </Link></div>

            }];

        return stockWarehouseLocationHeading;
    }
    
    render() {
        let { stockWarehouseLocations, loadingstockWarehouseLocations, formErrors, stockProdCode,stock} = this.state;
        let { sortRequest } = stockWarehouseLocations.request;
        let stockWarehouseLocationHeading = this.getStockWarehouseLocationHeading(stockWarehouseLocations.request);

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/stocks')}>Stock</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">WarehouseLocation-Manage</BreadcrumbItem>
                </Breadcrumb>

                <h5 className="mb-0">Search Stock <i className="fa fa-search" aria-hidden="true" /></h5>
                <SearchWINOnlineStock
                    handleSelection={(selectedProdCode) => this.handleStockChange(selectedProdCode, 'stockProdCode')}
                    selectedProdCode={stockProdCode}
                    defaultProdCode={stockProdCode}/>
                <br />

                <Card>
                    <CardBody>
                        {loadingstockWarehouseLocations ? <p>Loading...</p> : null}
                         <Table responsive={true} striped bordered size={'sm'}>
                                
                                    <thead>
                                        <tr>
                                            {(stockWarehouseLocationHeading || []).map((item, index) => {
                                                return (
                                                    <th key={index}
                                                        onClick={() => this.handleFilterChange(item.key, "sortKey")}
                                                        colSpan={item.colSpan}
                                                        className={item.labelClassName}
                                                        style={{ minWidth: item.minWidth }}>
                                                        {item.label}
                                                        {
                                                            item.sorterApplicable ?
                                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                                )} aria-hidden="true" /> : null
                                                        }

                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            {(stockWarehouseLocationHeading || []).map((item, index) => {
                                                return (
                                                    <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                        {item.searchNode}
                                                    </td>
                                                );
                                            })}
                                        </tr>

                                    </thead>
                                <tbody>
                                    {(stockWarehouseLocations.response.records || []).map((item, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {item.isEditing ?
                                                    <SearchWINOWarehouseLocation
                                                        warehouseLocation={item.warehouseLocation}
                                                        selected={item.warehouseLocation}
                                                        defaultSelected={item.warehouseLocation}
                                                        handleSelection={(selectedWarehouseLocation) => this.handleWarehouseLocationChange(selectedWarehouseLocation, 'warehouseLocation', index)} />
                                                    : <Input
                                                        value={item.warehouseLocation.name || ''}
                                                        placeholder="write warehouse location here"
                                                        disabled />}
                                                <FormText color="danger">{formErrors[index] ? formErrors[index].warehouseLocationID : null}</FormText>
                                            </td>
                                            <td>
                                                <Input
                                                    invalid={formErrors[index] ? formErrors[index].qoh !== undefined : false}
                                                    name="qoh"
                                                    disabled={!item.isEditing}
                                                    type="number"
                                                    value={item.qoh || ''}
                                                    placeholder={"write qoh here"}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name,index)} />
                                                <FormText color="danger">{formErrors[index] ? formErrors[index].qoh : null}</FormText>
                                                
                                            </td>
                                            <td>
                                                <Input
                                                    invalid={formErrors[index] ? formErrors[index].minQtyAllocated !== undefined : false}
                                                    name="minQtyAllocated"
                                                    type="number"
                                                    disabled={!item.isEditing}
                                                    value={item.minQtyAllocated || ''}
                                                    placeholder={"write min Qty Allocated here"}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)} />
                                                <FormText color="danger">{formErrors[index] ? formErrors[index].minQtyAllocated : null}</FormText>
                                            </td>
                                            <td>
                                                <Input
                                                    invalid={formErrors[index] ? formErrors[index].maxQtyAllocated !== undefined : false}
                                                    name="maxQtyAllocated"
                                                    type="number"
                                                    disabled={!item.isEditing}
                                                    value={item.maxQtyAllocated || ''}
                                                    placeholder={"write max Qty Allocated here"}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)} />
                                                <FormText color="danger">{formErrors[index] ? formErrors[index].maxQtyAllocated : null}</FormText>
                                            </td>
                                            <td className={"text-center"}>
                                                {!item.isEditing ?
                                                    <Button color={"primary"}
                                                        size={"sm"}
                                                        className={"mr-2"}
                                                        onClick={() => this.editStockWarehouseLocation(index)}>
                                                        <i className="fa fa-pencil mr-2" aria-hidden="true" />
                                                        Edit
                                                </Button> :
                                                    <Button color={"primary"}
                                                        size={"sm"}
                                                        className={"mr-2"}
                                                        disabled={item.saving}
                                                        onClick={() => this.saveStockWarehouseLocation(item, index)}>
                                                        {item.saving ?

                                                            <Spinner size="sm" className="mr-2"
                                                                style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />}
                                                        {item.id ? "Update" : "Save"}
                                                    </Button>}
                                                &nbsp;
                                                {item.id ?
                                                    <Button className="btn btn-sm btn-danger" title="Click here to make stock warehouse location inactive"
                                                        onClick={() => this.deleteStockWarehouseLocation(item,index)}
                                                        disabled={item.isDeleting}>
                                                        {item.isDeleting ?
                                                            <Spinner size="sm"
                                                                className={"mr-2"}
                                                                style={{ color: "white" }} /> :
                                                            <i className="fa fa-trash mr-2" aria-hidden="true"></i>} Delete
                                                </Button> : null}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>

                            </Table>
                        <br />
                        <Button onClick={this.addNewItem}>
                            <i className="fa fa-plus mr-2" />
                            Add</Button>
                    </CardBody>
                </Card>
            </div>
        );
    }
}