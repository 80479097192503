import React, { Component } from 'react';
import { Button, Table } from "reactstrap";
import classnames from "classnames";
import { cloneDeep, isEmpty } from 'lodash';
import { getSalesOrderItemProductLabelStore } from "../../../../../store/sales/order/item/labels/production/SalesOrderItemProductLabelStoreHandler";
import OrderEnquiryProductionModeOrderItemsProductPartList from "./OrderEnquiryProductionModeOrderItemsProductPartList";
import OrderEnquiryProductionModeOrderItemsProductSubPartList from "./OrderEnquiryProductionModeOrderItemsProductSubPartList";

export default function OrderEnquiryProductionModeOrderItemsProductLabelWrapper(props) {
    let { order, productIndex, toggleProductItemCollapse, bomModal } = props;
    let store = getSalesOrderItemProductLabelStore(order.products[productIndex]);
    let isOpenAll = (order.products[productIndex].items || []).every(item => item.isOpen);

    if (store) {
        return <Table responsive={true} size={"sm"} bordered={true} className={"mb-0"}>
            <thead>
                <tr>
                    <th className="hoverableItem align-middle text-center d-print-none"
                        onClick={() => toggleProductItemCollapse(productIndex, -1, !isOpenAll)}>
                        <Button color={"primary"}
                            size={"sm"}
                            outline={isOpenAll}>
                            <i className={classnames("fa", {
                                "fa-plus-circle": !isOpenAll,
                                "fa-minus-circle": isOpenAll,
                            }
                            )} />
                        </Button>
                    </th>

                    {(store || []).map((item, index) => {
                        return (
                            <th key={index}
                                colSpan={item.colSpan}
                                className={item.labelClassName}
                                style={{ minWidth: item.minWidth }}>
                                {item.label}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            {
                (order.products[productIndex].items || []).map((item, itemIndex) => {
                    return <tbody key={itemIndex}>
                        <tr key={itemIndex} className="table-primary">
                            <td className="hoverableItem align-middle text-center d-print-none"
                                onClick={() => toggleProductItemCollapse(productIndex, itemIndex, !item.isOpen)}>
                                <Button color={"primary"}
                                    size={"sm"}
                                    outline={order.products[productIndex].items[itemIndex].isOpen}
                                >
                                    <i className={classnames("fa", {
                                        "fa-plus-circle": !order.products[productIndex].items[itemIndex].isOpen,
                                        "fa-minus-circle": order.products[productIndex].items[itemIndex].isOpen,
                                    })} />
                                </Button>
                            </td>

                            {(store || []).map((column, columnIndex) => {
                                return (
                                    <td key={columnIndex} className={column.valueClassName}>
                                        {column.render(item, this)}
                                    </td>
                                );
                            })}
                        </tr>
                        {
                            item.isOpen
                                ? <tr>
                                    <td colSpan={store.length + 1}>
                                        <div className="p-2">
                                            <OrderEnquiryProductionModeOrderItemsProductSubPartList
                                                order={order}
                                                rows={order.products[productIndex].items[itemIndex].parts}
                                                bom={bomModal} />
                                        </div>
                                    </td>
                                </tr>
                                : null
                        }
                    </tbody>
                })
            }
        </Table>;
    }
}


