import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardText,
    Col,
    Collapse,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import classnames from 'classnames';
import {handleErrorMessage} from '../../services/CommonService';
import LeadService from '../../services/crm/LeadService';
import {Link} from "react-router-dom";
//import { any } from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';
import CRMService from "../../services/crm/CRMService";
import InfiniteScroll from 'react-infinite-scroller';
import CustomerService from "../../services/CustomerService";
import SearchDebtorAcccount from '../search/SearchDebtorAcccount';
import moment from 'moment';

export default class Lead extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
            loaders: {
                openleadsLoading: true
            },
            openLeads: {
                leads: [],
                selectedPage: 1,
                pageSize: 100,
                totalRecords: 0
            },
            selectedUser: {
                "emailAddress": "",
                "firstName": "",
                "id": "",
                "label": "",
                "lastName": "",
                "userId": "",
                "value": "",
            },
            users: [],
            toggle: false,
            isMyLeads: true,
            filterableTextBoxes: {
                Email: '',
                AccountId: ''
            },
            FilerConditions: [],
            multiSelect: [
                { 'label': 'New', 'value': "NEW", 'id': '1', 'ischecked': false },
                { 'label': 'Follow', 'value': "FOLLOW", 'id': '2', 'ischecked': false },
                { 'label': 'Converted', 'value': "CONVERTED", 'id': '3', 'ischecked': false },
                { 'label': 'Drop', 'value': "DROP", 'id': '4', 'ischecked': false }
            ],
            multiSelect_Priority: [
                { 'label': 'LOW', 'value': "LOW", 'id': '1', 'ischecked': false },
                { 'label': 'MEDIUM', 'value': "MEDIUM", 'id': '2', 'ischecked': false },
                { 'label': 'HIGH', 'value': "HIGH", 'id': '3', 'ischecked': false },
            ],
            startDate: null,
            endDate: null,
            activeFilterTab: "3"
        };
        this.LeadService = new LeadService();
        this.crmService = new CRMService();
        this.customerService = new CustomerService();
        this.FilterLeads = this.FilterLeads.bind(this);
        this.handlesearchinputChange = this.handlesearchinputChange.bind(this);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.toggleMyleadcheckbox = this.toggleMyleadcheckbox.bind(this);
        this.getStyleOfPriority = this.getStyleOfPriority.bind(this);
        this.ShowBadges = this.ShowBadges.bind(this);
        this.getColorOfPills = this.getColorOfPills.bind(this);
        this.clearParticularBadge = this.clearParticularBadge.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.optionClicked = this.optionClicked.bind(this);
        this.priority_optionClicked = this.priority_optionClicked.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.toggleFilterTab = this.toggleFilterTab.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
    }

    componentDidMount() {
        let stateloaders = this.state.loaders;
        stateloaders.openleadsLoading = true;
        this.setState({
            loaders: stateloaders
        });
        this.refresh();
    }

    refresh() {
        let selectted = this.state.selectedUser;
        let Loggeduser = localStorage.getItem('user');
        Loggeduser = JSON.parse(Loggeduser);

        selectted.emailAddress = Loggeduser.emailAddress;
        selectted.firstName = Loggeduser.firstName;
        selectted.id = Loggeduser.id;
        selectted.userId = Loggeduser.userId;
        selectted.username = Loggeduser.username;
        selectted.label = Loggeduser.username + " | " + Loggeduser.emailAddress;
        this.setState({ selectedUser: selectted }, () => this.FilterLeads(false));
        this.searchOwner("");
    }

    handlesearchinputChange(event) {
        const value = event.target.value;
        let changedtext = value;
        this.setState({ search: changedtext });
    }

    handleFilterInputChange(event) {
        const { name, value } = event.target;
        let { filterableTextBoxes } = this.state;
        filterableTextBoxes[name] = value;
        this.setState({ filterableTextBoxes });
    }

    handleSearch() {
        let stateloaders = this.state.loaders;
        stateloaders.openleadsLoading = true;
        this.setState({
            loaders: stateloaders
        });
    }

    searchOwner(inputValue, callback) {
        let self = this;
        let users = [];
        this.crmService.searchOwner(inputValue).then(response => {
            let data = response.data;
            for (let i in data) {
                let user = {
                    label: i,
                    value: data[i].userId,
                    id: data[i].userId,
                    userId: data[i].userId,
                    firstName: data[i].firstName,
                    lastName: data[i].lastName,
                    emailAddress: data[i].emailAddress
                };
                users.push(cloneDeep(user));
            }
            self.setState({ users });
            if (callback) {
                return callback(users)
            } else {
                return users;
            }
        }).catch(error => {
            alert(handleErrorMessage(error));
        });
    };

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.searchOwner(inputValue, callback)
            } else {
                callback(this.state.users);
            }
        }
    };

    ownerChanged = (selectedUser) => {
        if (selectedUser) {
            this.setState({
                selectedUser: selectedUser
            });
        } else {
            this.setState({ isMyLeads: false }, () => this.SetUserInptbox());
        }

    };

    getFilterClassName(active) {
        if (active) {
            return "btn btn-block btn-sm btn-primary";
        } else {
            return "btn btn-block btn-sm btn-outline-primary";
        }
    }

    optionClicked(value, { action, removedValue }) {
        switch (action) {
            case 'select-option':
                if (value) {
                    if (value.length > 0) {
                        let multiSelect = [...this.state.multiSelect];
                        value.map(item => {
                            let item2 = this.state.multiSelect.find(x => x.label === item.label);
                            if (item2 !== null && item2 !== undefined) {
                                multiSelect.find(x => x.id === item2.id).ischecked = true;
                            }
                        });
                        this.setState({ multiSelect });
                    }
                }
                console.log('select option');
                break;
            case 'remove-value':
                //removedValue
                if (removedValue) {
                    let multiSelect = [...this.state.multiSelect];
                    let item2 = this.state.multiSelect.find(x => x.label === removedValue.label);
                    if (item2 !== null && item2 !== undefined) {
                        multiSelect.find(x => x.id === item2.id).ischecked = false;
                    }
                    this.setState({ multiSelect });
                }
                console.log('remove-value');
                break;
            case 'pop-value':
                console.log('pop-value');
                break;
            case 'clear':
                console.log('clear');
                let multiSelect = [...this.state.multiSelect_Priority];
                multiSelect.forEach(function (el) {
                    el.ischecked = false;
                })
                this.setState({ multiSelect });
                break;
        }
    }

    priority_optionClicked(value, { action, removedValue }) {
        switch (action) {
            case 'select-option':
                if (value) {
                    if (value.length > 0) {
                        let multiSelect_Priority = [...this.state.multiSelect_Priority];
                        value.map(item => {
                            let item2 = this.state.multiSelect_Priority.find(x => x.label === item.label);
                            if (item2 !== null && item2 !== undefined) {
                                multiSelect_Priority.find(x => x.id === item2.id).ischecked = true;
                            }
                        });
                        this.setState({ multiSelect_Priority });
                    }
                }
                console.log('select option');
                break;
            case 'remove-value':
                //removedValue
                if (removedValue) {
                    let multiSelect_Priority = [...this.state.multiSelect_Priority];
                    let item2 = this.state.multiSelect_Priority.find(x => x.label === removedValue.label);
                    if (item2 !== null && item2 !== undefined) {
                        multiSelect_Priority.find(x => x.id === item2.id).ischecked = false;
                    }
                    this.setState({ multiSelect_Priority });
                }
                console.log('remove-value');
                break;
            case 'pop-value':
                console.log('pop-value');
                break;
            case 'clear':
                //console.log('clear');
                let multiSelect_Priority = [...this.state.multiSelect_Priority];
                multiSelect_Priority.forEach(function (el) {
                    el.ischecked = false;
                })
                this.setState({ multiSelect_Priority });
                break;
        }

    }

    getColorOfPills() {
        var css_array = [' badge-primary', ' badge-secondary', ' badge-info', ' badge-light', ' badge-warning', 'badge-success'];
        var randomNumber = Math.floor(Math.random() * css_array.length);
        return css_array[randomNumber];
    }

    FilterLeads(incrementPage) {
        let openLeads = this.state.openLeads;
        if (incrementPage) {
            openLeads.selectedPage = openLeads.selectedPage + 1;
        } else {
            openLeads.selectedPage = 1;
            openLeads.pageSize = 10;
            openLeads.leads = [];
        }
        let FilerConditions = [];
        let loaders = this.state.loaders;
        loaders.openleadsLoading = true;
        FilerConditions = this.ShowBadges();
        this.setState({
            loaders: loaders
        });
        if (FilerConditions.length > 0) {

            let status = FilerConditions.find(x => x.Name === "Status");
            if (status !== undefined) {
                status = status.Value;
            }
            let priority = FilerConditions.find(x => x.Name === "Priority");
            if (priority !== undefined) {
                priority = priority.Value;
            }
            let LeadCreated = FilerConditions.find(x => x.Name === "Created Between");
            if (LeadCreated !== undefined) {
                LeadCreated = LeadCreated.Value;
            }
            let email = FilerConditions.find(x => x.Name === "Keyword");
            if (email !== undefined) {
                email = email.Value;
            }
            let accountId = FilerConditions.find(x => x.Name === "AccountId");
            if (accountId !== undefined) {
                accountId = accountId.Value;
            }
            this.LeadService.filterleads(openLeads.selectedPage, openLeads.pageSize, status, priority, LeadCreated, email, accountId, this.state.selectedUser)
                .then(res => {
                    let data = res.data;
                    openLeads.selectedPage = data.selectedPage;
                    openLeads.pageSize = data.pageSize;
                    openLeads.totalRecords = data.totalRecords;
                    if (data.orders && data.orders.length > 0) {
                        for (let i in data.orders) {
                            openLeads.leads.push(data.orders[i]);
                        }
                    }
                    loaders.openleadsLoading = false;
                    this.setState({
                        loaders: loaders,
                        openLeads: openLeads
                    });
                }).catch(error => {
                    alert(handleErrorMessage(error));
                });
        }
        else {
            this.LeadService.filterleads(openLeads.selectedPage, openLeads.pageSize, "", "", "", "", "", this.state.selectedUser)
                .then(res => {
                    let data = res.data;
                    openLeads.selectedPage = data.selectedPage;
                    openLeads.pageSize = data.pageSize;
                    openLeads.totalRecords = data.totalRecords;
                    if (data.orders && data.orders.length > 0) {
                        for (let i in data.orders) {
                            openLeads.leads.push(data.orders[i]);
                        }
                    }
                    loaders.openleadsLoading = false;
                    this.setState({
                        loaders: loaders,
                        openLeads: openLeads
                    });
                }).catch(error => {
                    alert(handleErrorMessage(error));
                });
        }
    }

    ShowBadges() {
        let status_options = [...this.state.multiSelect];
        let priority_options = [...this.state.multiSelect_Priority];
        status_options = status_options.filter(x => x.ischecked);
        priority_options = priority_options.filter(x => x.ischecked);
        let filterconditions = [];
        var str = '';
        var p_str = '';
        status_options.forEach(function (item, index) {
            let comma = '';
            if (index !== (status_options.length - 1)) {
                comma = ' , ';
            }
            str = str + item.label + comma;
        });
        priority_options.forEach(function (item, index) {
            let comma = '';
            if (index !== (priority_options.length - 1)) {
                comma = ' , ';
            }
            p_str = p_str + item.label + comma;
        });
        if (str !== '') {
            filterconditions.push({ 'Name': 'Status', 'Value': str });
        }
        if (p_str !== '') {
            filterconditions.push({ 'Name': 'Priority', 'Value': p_str });
        }
        if (this.state.startDate !== null && this.state.startDate !== undefined) {
            let start = this.state.startDate;
            start = (new Date(start)).toDateString();
            let end = this.state.endDate;
            end = (new Date(end)).toDateString();
            if (start !== "Invalid Date" || end !== "Invalid Date") {
                filterconditions.push({ 'Name': 'Created Between', 'Value': start + ' - ' + end });
            }
        }

        if (this.state.filterableTextBoxes.Email !== "" && this.state.filterableTextBoxes.Email !== null) {
            filterconditions.push({ 'Name': 'Keyword', 'Value': this.state.filterableTextBoxes.Email });
        }
        if (this.state.filterableTextBoxes.AccountId !== "" && this.state.filterableTextBoxes.AccountId !== null) {
            filterconditions.push({ 'Name': 'AccountId', 'Value': this.state.filterableTextBoxes.AccountId });
        }
        if (Object.entries(this.state.selectedUser).length !== 0 && this.state.selectedUser !== null) {
            filterconditions.push({ 'Name': 'User', 'Value': this.state.selectedUser.label });
        }
        //this.setState({ FilerConditions: filterconditions }, () => this.FilterLeads());
        //this.setState({ FilerConditions: filterconditions });
        return filterconditions;
    }

    clearParticularBadge(index, stateName) {
        switch (stateName) {
            case 'Status':
                let multiSelects = [
                    { 'label': 'New', 'value': "NEW", 'id': '1', 'ischecked': false },
                    { 'label': 'Follow', 'value': "FOLLOW", 'id': '2', 'ischecked': false },
                    { 'label': 'Converted', 'value': "CONVERTED", 'id': '3', 'ischecked': false },
                    { 'label': 'Drop', 'value': "DROP", 'id': '4', 'ischecked': false }
                ];
                this.setState({ multiSelect: multiSelects }, () => this.FilterLeads(false));
                break;
            case 'Priority':
                let multiSelect_Prioritys = [
                    { 'label': 'LOW', 'value': "LOW", 'id': '1', 'ischecked': false },
                    { 'label': 'MEDIUM', 'value': "MEDIUM", 'id': '2', 'ischecked': false },
                    { 'label': 'HIGH', 'value': "HIGH", 'id': '3', 'ischecked': false },
                ];
                this.setState({ multiSelect_Priority: multiSelect_Prioritys }, () => this.FilterLeads(false));
                break;
            case 'Keyword':
                let filterableTextBoxes = { ...this.state.filterableTextBoxes };
                filterableTextBoxes.Email = "";
                this.setState({ filterableTextBoxes }, () => this.FilterLeads(false));
                break;
            case 'AccountId':
                let filterableTextBoxess = { ...this.state.filterableTextBoxes };
                filterableTextBoxess.AccountId = "";
                this.setState({ filterableTextBoxes: filterableTextBoxess });
                break;
            case 'Created Between':
                this.setState({ startDate: null, endDate: null }, () => this.FilterLeads(false));
                break;
            case 'User':
                this.setState({ selectedUser: {} }, () => this.FilterLeads(false));
                break;
            case 'All Clear':
                let filterableTextBox = { ...this.state.filterableTextBoxes };
                let Prioritys = [
                    { 'label': 'LOW', 'value': "LOW", 'id': '1', 'ischecked': false },
                    { 'label': 'MEDIUM', 'value': "MEDIUM", 'id': '2', 'ischecked': false },
                    { 'label': 'HIGH', 'value': "HIGH", 'id': '3', 'ischecked': false },
                ];
                let status = [
                    { 'label': 'New', 'value': "NEW", 'id': '1', 'ischecked': false },
                    { 'label': 'Follow', 'value': "FOLLOW", 'id': '2', 'ischecked': false },
                    { 'label': 'Converted', 'value': "CONVERTED", 'id': '3', 'ischecked': false },
                    { 'label': 'Drop', 'value': "DROP", 'id': '4', 'ischecked': false }
                ];
                filterableTextBox.AccountId = "";
                filterableTextBox.Email = "";
                this.setState({
                    selectedUser: {}, startDate: null, endDate: null, filterableTextBoxes: filterableTextBox,
                    multiSelect: status, multiSelect_Priority: Prioritys, isMyLeads: !this.state.isMyLeads
                }, () => this.FilterLeads(false));
                break;
            default:
                break;
        }
    }

    toggleMyleadcheckbox() {
        this.setState({ isMyLeads: !this.state.isMyLeads }, () => this.SetUserInptbox());
    }

    SetUserInptbox() {
        if (this.state.isMyLeads) {
            let selectted = this.state.selectedUser;
            let Loggeduser = localStorage.getItem('user');
            Loggeduser = JSON.parse(Loggeduser);
            selectted.emailAddress = Loggeduser.emailAddress;
            selectted.firstName = Loggeduser.firstName;
            selectted.id = Loggeduser.id;
            selectted.userId = Loggeduser.userId;
            selectted.username = Loggeduser.username;
            selectted.label = Loggeduser.username + " | " + Loggeduser.emailAddress;
            this.setState({ selectedUser: selectted });
        } else {
            this.setState({ selectedUser: {} });
            this.searchOwner("")
        }
    }

    toggleCollapse(item) {
        this.setState({ toggle: !this.state.toggle });
    }

    toggleFilterTab(item) {
        this.setState({ activeFilterTab: item });
    }

    getStyleOfPriority(priority) {
        priority = priority.toLowerCase();
        if (priority === 'low') {
            return '#448aff'
        }
        if (priority === 'medium') {
            return '#ffc107'
        }
        if (priority === 'high') {
            return '#ff1212d1'
        }
    }

    renderBadgeComponent() {
        let FilerConditions = this.ShowBadges();
        return (
            <div className={"mt-2"}>
                {(FilerConditions.length === 0 || FilerConditions.length === '0') ?
                    null
                    :
                    <div>
                        Selected Filters &nbsp; : &nbsp;
                        {FilerConditions.map((item, index) =>
                        <span key={index}>
                            <Button className={"btn btn-sm"} color="primary"
                                onClick={() => this.clearParticularBadge(index, item.Name)}>{item.Name}&nbsp;
                                    : &nbsp;{item.Value}
                                    &nbsp;&nbsp;<Badge pill color={"secondary"}> <i className="fa fa-times"
                                    aria-hidden="true"
                                /></Badge></Button>&nbsp;</span>
                    )}
                    </div>
                }
            </div>
        );
    }

    renderLeadsView(openLeads, loaders) {

        let { leads, selectedPage, pageSize, totalRecords } = openLeads;

        return (
            <div>
                <div>
                    {(this.state.openLeads.totalRecords === 0 || this.state.openLeads.totalRecords === '0') ?
                        <div className="card shadow-0 border" style={{ marginBottom: "0px" }}>
                            <div className="card-body">
                                <CardText>No items...</CardText>
                            </div>
                        </div> :
                        <div>
                            <p>Leads found<Badge pill
                                color="light">
                                {this.state.openLeads.totalRecords}</Badge></p>

                            <InfiniteScroll
                                threshold={50}
                                loader={<div className={"text-center"}><Button
                                    size={"md"}
                                    block={true}
                                    disabled={loaders.openleadsLoading}
                                    color={"primary"}
                                    onClick={() => this.FilterLeads(true)}>{loaders.openleadsLoading ? "Loading next " : "Load next "}{pageSize}&nbsp;
                                    leads...</Button></div>}
                                pageStart={1}
                                loadMore={() => this.FilterLeads(true)}
                                hasMore={(pageSize * selectedPage) < totalRecords}
                                useWindow={true}
                            >
                                <ul style={{ padding: "unset" }}>
                                    {
                                        leads.map((lead, index) =>
                                            <li key={index}>
                                                <div className="card shadow-0 border" style={{ marginBottom: "0px" }}>
                                                    <div className="card-body">
                                                        <Row>
                                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                <h6><Link
                                                                    to={{
                                                                        pathname: "/crm/manageLead",
                                                                        state: { lead: lead }
                                                                    }}><i
                                                                        className="fa fa-pencil-square-o fa-lg"
                                                                        aria-hidden="true" />&nbsp;{lead.personName}&nbsp;&nbsp;
                                                                </Link>
                                                                    {(() => {
                                                                        if (lead.accountID) {
                                                                            return (
                                                                                <Link
                                                                                    className="btn btn-sm btn-primary"
                                                                                    style={{ color: "white" }}
                                                                                    title={"Click here to see account details"}
                                                                                    to={"/customer/#" + lead.accountID}>{lead.accountID}</Link>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    &nbsp;<Badge color="light"
                                                                        pill>{lead.status}</Badge>
                                                                    <Badge
                                                                        style={{ background: this.getStyleOfPriority(lead.priority) }}
                                                                        pill>{lead.priority}
                                                                    </Badge>
                                                                    {(() => {
                                                                        if (lead.createdDate) {
                                                                            return (
                                                                                <small
                                                                                    className={"text-muted"}>&nbsp;
                                                                                    | &nbsp;
                                                                                    Created &nbsp;{moment(lead.createdDate).fromNow()}</small>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                                {
                                                                    (lead.email !== null && lead.email !== "" && lead.email !== undefined) ?
                                                                        (<p><i className="fa fa-envelope-o"
                                                                            aria-hidden="true" />&nbsp;
                                                                            <a href={"mailto:" + lead.email}> {lead.email}</a>
                                                                        </p>) :
                                                                        null
                                                                }
                                                            </Col>
                                                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                                {
                                                                    (lead.phone !== null && lead.phone !== "" && lead.phone !== undefined) ?
                                                                        (
                                                                            <p><i className="fa fa-phone"
                                                                                aria-hidden="true" />&nbsp;
                                                                                <a href={"tel:" + lead.phone}> {lead.phone}</a>
                                                                            </p>
                                                                        ) :
                                                                        null
                                                                }
                                                            </Col>
                                                            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                                                {
                                                                    (lead.website !== null && lead.website !== "" && lead.website !== undefined) ?
                                                                        (<p title="Website"><i
                                                                            className=" text-muted fa fa-internet-explorer"
                                                                            aria-hidden="true" />&nbsp;
                                                                            <a target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={"//" + lead.website}> {lead.website}</a>
                                                                        </p>
                                                                        )
                                                                        :
                                                                        null
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                                {
                                                                    (lead.ownerName !== null && lead.ownerName !== "" && lead.ownerName !== undefined) ?
                                                                        (
                                                                            <p>Owner : &nbsp;{lead.ownerName}</p>) :
                                                                        null
                                                                }
                                                            </Col>
                                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                                {
                                                                    (lead.company !== null && lead.company !== "" && lead.company !== undefined) ?
                                                                        (<p><i className="fa fa-building-o"
                                                                            aria-hidden="true"></i>&nbsp;{lead.company}
                                                                        </p>) :
                                                                        null
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <div>
                                                            {
                                                                (lead.note !== null && lead.note !== "" && lead.note !== undefined) ?
                                                                    (<CardText key={index}>
                                                                        <span><i className="fa fa-sticky-note-o"
                                                                            aria-hidden="true" />&nbsp;</span>{lead.note}
                                                                    </CardText>)
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    }
                </div>
            </div>
        );
    }

    setStartDate(startDate) {
        this.setState({ startDate: startDate });
    }

    setEndDate(endDate) {
        this.setState({ endDate: endDate });
    }


    handleDebtorAccountDetails(accountId) {

        let filterableTextBoxes = this.state.filterableTextBoxes;
        if (accountId) {
            this.customerService.searchCustomer(accountId).then(response => {

                let data = response.data;
                filterableTextBoxes.AccountId = data.accountID;

                this.setState({
                    filterableTextBoxes
                });
            });
        } else {
            this.clearParticularBadge(0, 'AccountId');
        }

    }


    render() {


        let openLeadsView = this.renderLeadsView(this.state.openLeads, this.state.loaders);
        // to render the leads list

        let Badgescomponent = this.renderBadgeComponent();
        let filterclass = this.getFilterClassName(this.state.toggle);

        return (
            <div>
                <Row>
                    <Col sm={6} xs={6} md={3} lg={3} xl={3} className="mb-1">
                        <Link className="btn btn-block btn-sm btn-primary" to={{ pathname: "/crm/manageLead" }}><i
                            className="fa fa-plus" aria-hidden="true" />&nbsp; Create new lead</Link>
                    </Col>
                    <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                        <a className={filterclass} href="javascript:void(0)"
                            onClick={() => this.toggleCollapse("toggle1")}>
                            <i className="fa fa-filter" aria-hidden="true" /> Lead filter options
                        </a>
                    </Col>
                </Row>


                <Collapse isOpen={this.state.toggle}>
                    <Card className={"mt-1 shadow-0 border"}>
                        <CardBody>

                            <Nav tabs className={"nav-material"}>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeFilterTab === '3' })}
                                        onClick={() => {
                                            this.toggleFilterTab('3');
                                        }}
                                    >
                                        Customer
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeFilterTab === '2' })}
                                        onClick={() => {
                                            this.toggleFilterTab('2');
                                        }}
                                    >
                                        Keyword
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeFilterTab === '4' })}
                                        onClick={() => {
                                            this.toggleFilterTab('4');
                                        }}
                                    >
                                        Status
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeFilterTab === '5' })}
                                        onClick={() => {
                                            this.toggleFilterTab('5');
                                        }}
                                    >
                                        Priority
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeFilterTab === '6' })}
                                        onClick={() => {
                                            this.toggleFilterTab('6');
                                        }}
                                    >
                                        Created By
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeFilterTab === '1' })}
                                        onClick={() => {
                                            this.toggleFilterTab('1');
                                        }}
                                    >
                                        Created Between
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeFilterTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}
                                            style={{ 'verticalAlign': 'middle' }}>

                                            <Label onClick={this.onToggle}>Start date</Label>
                                            <div>
                                                <i className="fa fa-calendar" aria-hidden="true" />&nbsp;&nbsp;
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.startDate}
                                                    onChange={date => this.setStartDate(date)}
                                                    selectsStart
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    isClearable
                                                    placeholderText="Select start date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    scrollableYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>

                                            <Label onClick={this.onToggle}>End date</Label>
                                            <div>
                                                <i className="fa fa-calendar" aria-hidden="true" />&nbsp;&nbsp;
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.endDate}
                                                    onChange={date => this.setEndDate(date)}
                                                    selectsEnd
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    isClearable
                                                    placeholderText="Select end date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    scrollableYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                </TabPane>
                                <TabPane tabId="2">
                                    <Label>Search by keyword</Label>

                                    <Input type="text" name="Email"
                                        value={this.state.filterableTextBoxes.Email || ''}
                                        onChange={this.handleFilterInputChange} id="Email"
                                        placeholder="write any keyword here"
                                        autoComplete="off" />

                                </TabPane>

                                <TabPane tabId="3">
                                    <Label>Search by customer</Label>

                                    <SearchDebtorAcccount
                                        handleAccountChange={this.handleDebtorAccountDetails}
                                        defaultAccountID={this.state.filterableTextBoxes.AccountId}
                                        includeChildren={true}
                                        excludeClosedandInactive={false} />
                                </TabPane>

                                <TabPane tabId="4">
                                    <Label>Search by status</Label>
                                    <Select
                                        isMulti
                                        name="colors"
                                        options={this.state.multiSelect}
                                        className="basic-multi-select"
                                        value={this.state.multiSelect.filter(x => x.ischecked)}
                                        classNamePrefix="select"
                                        onChange={this.optionClicked}
                                        closeMenuOnSelect={false}
                                    />
                                </TabPane>

                                <TabPane tabId="5">
                                    <Label>Search by priority</Label>
                                    <Select
                                        isMulti
                                        name="colors"
                                        options={this.state.multiSelect_Priority}
                                        value={this.state.multiSelect_Priority.filter(x => x.ischecked)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.priority_optionClicked}
                                        closeMenuOnSelect={false}
                                    />
                                </TabPane>

                                <TabPane tabId="6">
                                    <Label>Search by users</Label>&nbsp;
                                    <button type="button" className="btn btn-sm btn-outline-primary mr-1" style={{
                                        backgroundColor: (!this.state.isMyLeads ? '#00AEEF' : ''),
                                        'color': (!this.state.isMyLeads ? 'white' : '')
                                    }} onClick={this.toggleMyleadcheckbox}>All Leads
                                    </button>
                                    <button type="button" className="btn btn-sm btn-outline-primary mr-1" style={{
                                        backgroundColor: (this.state.isMyLeads ? '#00AEEF' : ''),
                                        'color': (this.state.isMyLeads ? 'white' : '')
                                    }} onClick={this.toggleMyleadcheckbox}>My Leads
                                    </button>

                                    <AsyncSelect
                                        isClearable={true}
                                        cacheOptions
                                        defaultOptions={this.state.users}
                                        loadOptions={this.loadOptions}
                                        onChange={this.ownerChanged}
                                        isSearchable={true}
                                        value={this.state.selectedUser}
                                        placeholder={"write user's username, firstname, lastname, email, phone"}
                                    />
                                </TabPane>

                            </TabContent>
                        </CardBody>
                    </Card>


                </Collapse>
                {Badgescomponent}
                <div className={"mt-2 text-right"}>
                    <Button className="btn btn-sm btn-primary"
                        color={"primary"}
                        onClick={() => this.FilterLeads(false)}>
                        <i className="fa fa-search" aria-hidden="true" />
                        &nbsp;Apply filters
                    </Button>
                    <Button className="btn btn-sm btn-secondary ml-2"
                        color={"secondary"}
                        onClick={() => this.clearParticularBadge(0, 'All Clear')}>
                        Clear
                    </Button>

                </div>


                {openLeadsView}


            </div>
        );
    }

}
