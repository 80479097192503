import React, {Component} from 'react';

import {Button, Modal, ModalBody, ModalHeader, Spinner,} from 'reactstrap';
import rollerBlindSingleUtil from '../util/RollerBlindSingleUtil'
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service'
import RollerBlindSingleBOMModalPrint from './RollerBlindSingleBOMModalPrint'
import StockService from '../../../../../../services/StockService'
import {handleErrorMessage} from "../../../../../../services/CommonService";
import ReactToPrint from "react-to-print";


export default class RollerBlindSingleBOMModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: {},
            itemIndex: -1,
            stocksByProdCode: {}
        };
        this.stockService = new StockService();
    }

    componentDidMount() {
        let {product, itemIndex} = this.props;
        product = rollerBlindSingleUtil.getStocks(product, itemIndex);

        this.setState({product, itemIndex, loading: true});
        let prodCodes = [];

        product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
            prodCodes = [...prodCodes, ...subItem.stocks.map(stock => stock.prodCode)];
        });

        this.stockService.getStocksByProdCode(prodCodes.join()).then(response => {
            this.setState({stocksByProdCode: response.data, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            alert(handleErrorMessage(error));
        })
    }


    render() {
        let {product, itemIndex, stocksByProdCode, loading} = this.state;
        let {isOpen, toggle, order, convertToCustom} = this.props;
        if (!(itemIndex > -1)) {
            return null;
        }
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    <ReactToPrint
                        trigger={() => {
                            return <Button color={"primary"} size={"sm"}>
                                <i className="fa fa-print mr-2"
                                   aria-hidden="true"/>Print
                            </Button>
                        }}
                        content={() => this.componentRef}
                    />
                    <Button color={"primary"} size={"sm"} className={"ml-2"} onClick={()=> convertToCustom(stocksByProdCode)}>
                        <i className="fa fa-sliders mr-2"
                           aria-hidden="true"/>Convert to Custom
                    </Button>
                </ModalHeader>
                <ModalBody>
                    {
                        loading
                            ? <Spinner color={"primary"}/>
                            : <div>
                                <RollerBlindSingleBOMModalPrint product={product}
                                                                order={order}
                                                                itemIndex={itemIndex}
                                                                stocksByProdCode={stocksByProdCode}
                                                                ref={el => (this.componentRef = el)}/>
                            </div>
                    }
                </ModalBody>
            </Modal>
        )
    }
}