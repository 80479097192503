import React, {Component} from 'react';
import ProductDiscountService from '../../services/product/ProductDiscountService';
import {
    Button, ButtonGroup, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner,
    Table
} from 'reactstrap';
import classnames from 'classnames';
import CustomerService from "../../services/CustomerService";
import productService from "../../services/product/ProductService";
import {cloneDeep, isEmpty} from 'lodash';
import {toast, ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import ProductDiscountFormModal from './ProductDiscountFormModal';
import SearchDebtorAcccount from '../search/SearchDebtorAcccount';
import {findIndex,handleErrorMessage} from "../../services/CommonService";
import * as FileSaver from 'file-saver';

const productsToHide = ['SHP CUTDOWN', 'MASKS', 'POWDER COATING'];
export default class ProductAllDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "discount-list",
            discount: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "accountID",
                        direction: true
                    },
                    filterRequest: {
                        accountID: "",
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loadingDiscounts: false,
            loadingDiscountSave: false,
            accountID: this.props.accountID,
            searchText: "",
            products: [],
            workingDiscount: {},
            defaultDiscounts:[],
            isOpenWorkingDiscountModal: false,
            downloading: false
        };
        this.customerService = new CustomerService();
        this.productDiscountService = new ProductDiscountService();
        this.getStore = this.getStore.bind(this);
        this.getDiscounts = this.getDiscounts.bind(this);
        this.getProductGroups = this.getProductGroups.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.toggleWorkingDiscountModal = this.toggleWorkingDiscountModal.bind(this);
        this.handleWorkingDiscountChange = this.handleWorkingDiscountChange.bind(this);
        this.handleWorkingDiscountSubmit = this.handleWorkingDiscountSubmit.bind(this);
    }

    componentDidMount() {
        this.getProductGroups();
        let {accountID, discount} = this.state;
        discount.request.filterRequest.accountID = accountID;
        this.setState({ discount }, () => this.getDefaultDiscounts());
    }

    getDefaultDiscounts(){
        this.productDiscountService.fetchDefaultDiscount().then(response => {
            if (response && response.data) {
                let { defaultDiscounts } = this.state;
                defaultDiscounts = response.data;
                this.setState({ defaultDiscounts });
                this.getDiscounts(this.state.discount);
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getDiscounts(discount) {
        this.setState({loadingDiscounts: true});
        this.productDiscountService.fetchDiscount(discount.request).then(response => {
            if (response && response.data) {
                let { discount, defaultDiscounts, products } = this.state;
                discount.response = response.data; 

                if (discount.request.filterRequest.accountID) {
                    // getting distinct accounts
                    let accountIDWithCompany = [];
                    let accountIDs = [];
                    discount.response.records.forEach(d => {
                        if (d.accountID && !accountIDs.includes(d.accountID)) {
                            accountIDs.push(d.accountID);
                            accountIDWithCompany.push({ account: d.accountID, company: d.company });
                        }
                    });

                    if (discount.request.filterRequest.productGroupID) {
                        products=  products.filter(p => p.productGroupId === parseInt(discount.request.filterRequest.productGroupID));
                    }

                    // now loop for each account
                    accountIDWithCompany.forEach(a => {
                        let discounts = discount.response.records.filter(d => d.accountID === a.account);

                        products.forEach(p => {
                            // if discount not available then add default discount
                            if (discounts.filter(d => d.productGroupID === p.productGroupId).length === 0) {
                                let defDiscount = {
                                    accountID: a.account,
                                    company: a.company,
                                    productGroupID: p.productGroupId,
                                    displayName: p.displayName,
                                    discount: defaultDiscounts.filter(d => d.productGroupID === p.productGroupId)[0] ? defaultDiscounts.filter(d => d.productGroupID === p.productGroupId)[0].discount : 0
                                }

                                discount.response.records.push(defDiscount);
                            }
                        })
                    });
                }


                this.setState({discount, loadingDiscounts: false});
            }
        }).catch(error => {
            console.log(error);
            this.setState({loadingDiscounts: false});
            toast.error(handleErrorMessage(error));
        });
    }

    getProductGroups() {
        productService.getProductGroups().then(response => {
            let products = response.data;
            products = products.filter(p => !productsToHide.includes(p.displayName));
            this.setState({ products });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    handleAccountChange(accountId) {
        let {discount} = this.state;
        discount.request.filterRequest.accountID = accountId;
        this.setState({discount}, () => {
            this.getDiscounts(discount);
        });
    }

    handleChange(change, key) {
        let {discount} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (change.sorterApplicable) {
                    if (discount.request.sortRequest.key === change.key) {
                        discount.request.sortRequest.direction = !discount.request.sortRequest.direction;
                    } else {
                        discount.request.sortRequest.key = change.key;
                        discount.request.sortRequest.direction = false;
                    }
                    this.getDiscounts(discount);
                }
                break;
            case "pageSize":
                discount.request.pageRequest[key] = change;
                this.getDiscounts(discount);
                break;
            case "currentPage":
                discount.request.pageRequest[key] = change;
                this.getDiscounts(discount);
                break;
            default:
                discount.request.filterRequest[key] = change;
                discount.request.pageRequest.currentPage = 1;
                this.setState({discount}, () => {
                    this.getDiscounts(discount);
                });
        }
    }

    handleAction(key, change) {
        switch (key) {
            case "new":
                let {workingDiscount} = this.state;
                workingDiscount = {
                    id: null,
                    accountID: "",
                    productGroupID: this.state.products[0].productGroupId,
                    discount: 0,
                    submitted: false
                };
                this.setState({workingDiscount, isOpenWorkingDiscountModal: true});
                break;
            case "edit":
                change.submitted = false;
                this.setState({workingDiscount: change, isOpenWorkingDiscountModal: true});
                break;
            default:
                break;
        }
    }

    toggleWorkingDiscountModal(change) {
        this.setState({isOpenWorkingDiscountModal: change});
    }

    handleWorkingDiscountChange(change, key) {
        let {workingDiscount} = this.state;
        workingDiscount[key] = change;
        this.setState({workingDiscount});
    }

    handleWorkingDiscountSubmit() {
        this.getDiscounts(this.state.discount);
    }

    handleWorkingDiscountDelete(workingDiscount) {
        let {discount} = this.state;
        let index = findIndex(discount.response.records, "id", workingDiscount.id);
        if (index > -1) {
            discount.response.records[index].loadingDiscountDelete = true;
        }
        this.setState({discount});
        this.productDiscountService.deleteDiscount(workingDiscount.id).then(response => {
            this.getDiscounts(this.state.discount);
            toast.success("Deleted");
        }).catch(error => {
            this.getDiscounts(this.state.discount);
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.accountID.toString().includes(searchText);

            if (!flag && item.displayName) {
                flag = item.displayName.includes(searchText)
            }

        }
        return flag;
    }

    getStore() {
        let { products, discount } = this.state;
        let {pageRequest, sortRequest, filterRequest} = discount.request;
        return [
            {
                key: "S.No",
                label: "S.No",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return ((pageRequest.currentPage - 1) * pageRequest.pageSize) + (recordIndex + 1)
                }
            },
            {
                key: "accountID",
                label: "Customer",
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchDebtorAcccount
                        handleAccountChange={this.handleAccountChange}
                        defaultAccountID={filterRequest.accountID}
                        selectedAccountID={filterRequest.accountID}
                        includeChildren={true}
                        excludeClosedandInactive={false}/>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    if (value) {
                        return <div>
                            <Link
                                className="btn btn-primary btn-sm"
                                style={{ color: "white" }}
                                title={"Click here to see account details"}
                                to={"/customer/#" + value}>{value}</Link>
                            <span className={"ml-1 mr-1 text-muted"}>|</span>
                            <span className={"ml-1"}>{record.company}</span>
                        </div>
                    } else {
                        return null;
                    }
                }
            }, {
                key: "displayName",
                label: "Product",
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div>
                    <Input type={"select"}
                           name={"productGroupID"}
                           value={filterRequest.productGroupID ? filterRequest.productGroupID : ""}
                           onChange={(e) => this.handleChange(e.target.value, "productGroupID")}>
                        <option value={""}>Select</option>
                        {
                            products.map((option, index) =>
                                <option key={index} value={option.productGroupId}>{option.displayName}</option>
                            )
                        }
                    </Input>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return value
                }
            }, {
                key: "discount",
                label: "Discount",
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <NumberFormat
                        value={value}
                        displayType={'text'}
                        decimalScale={2}
                        thousandSeparator={true}
                        suffix={'%'}/>
                }
            },
            {
                key: "updatedOn",
                label: "Last updated on",
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{record.updatedDate ? record.updatedDate : record.createdDate} </span>
                }
            },
            {
                key: "action",
                label: <Button color={"primary"} size={"sm"}
                               onClick={() => this.handleAction("new", null)}>
                    <i className="fa fa-plus-circle mr-2"/> Add
                </Button>,
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <ButtonGroup>
                        <Button size={"sm"}
                            color={"primary"}
                                onClick={() => ref.handleAction("edit", record)}>
                            <i className="fa fa-pencil mr-2"
                               aria-hidden="true"/>
                            Edit
                        </Button>
                        &nbsp;&nbsp;
                        <Button size={"sm"}
                            color={"danger"}
                            disabled={record.loadingDiscountDelete}
                                onClick={() => ref.handleWorkingDiscountDelete(record)}>
                            {
                                record.loadingDiscountDelete
                                    ? <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                    : <i className="fa fa-trash-o mr-2" aria-hidden="true"/>
                            }
                            Delete
                        </Button>
                    </ButtonGroup>
                }
            }
        ];

    }

    printProductDiscountInExcel = () => {
        let request = cloneDeep(this.state.discount.request);
        this.setState({ downloading: true });
        this.productDiscountService.generateProdcutDiscountExcelReport(request).then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "ProductDiscount" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            toast.error(handleErrorMessage(error));
        }); 
    }

    render() {
        let {products, discount, loadingDiscounts, searchText, isOpenWorkingDiscountModal, loadingDiscountSave, workingDiscount, downloading} = this.state;
        let {pageRequest, sortRequest} = discount.request;
        let store = this.getStore();
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loadingDiscounts ? <Spinner color={"primary"} size={"sm"}/> :
                                        <span>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {discount.response.totalRecords}
                                            {' '}saved discounts &nbsp;&nbsp;
                                                        </span>
                                    }
                                    <Button color='success'
                                        outline={true} size='sm'
                                        title={'Click here to export data'}
                                        onClick={this.printProductDiscountInExcel} disabled={downloading}>
                                        {downloading
                                            ? <Spinner size="sm"
                                                className={"mr-2"}
                                                style={{ color: "green" }} />
                                            : <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />}Export
                                    </Button>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                   aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <Table hover bordered size={"sm"} responsive>
                            <thead>
                            <tr>
                                {(store || []).map((item, index) => {
                                    return ( 
                                        <th key={index}
                                            onClick={() => this.handleChange(item, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{minWidth: item.minWidth}}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true"/> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(store || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>

                            {(discount.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((row, rowIndex) => {
                                return (
                                    <tr key={rowIndex}>
                                        {(store || []).map((column, columnIndex) => {
                                            return (
                                                <td key={columnIndex} className={column.valueClassName}>
                                                    {column.render(row[column.key], row, rowIndex, discount, this)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>

                        </Table>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loadingDiscounts}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={discount.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
                <ProductDiscountFormModal
                    isOpen={isOpenWorkingDiscountModal}
                    toggle={this.toggleWorkingDiscountModal}
                    handleChange={this.handleWorkingDiscountChange}
                    handleSubmit={this.handleWorkingDiscountSubmit}
                    form={workingDiscount}
                    products={products}
                />
                <ToastContainer/>
            </div>
        )
    }
}