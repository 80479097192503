import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_VENETIAN_PRODCODE} from "../../../../../../store/AppConstants";

class VenetianUtil {

    static Instance() {
        return new VenetianUtil();
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < product.items[itemIndex].configuration.quantity.min) || (product.items[itemIndex].configuration.quantity.selected.value > product.items[itemIndex].configuration.quantity.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;


            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
    }

    updateRules(key, product, itemIndex, order) {
        switch (key) {
            case "quantity":
                product.items[itemIndex].configuration.quantity.min = 1;
                product.items[itemIndex].configuration.quantity.max = 10000;
                break;
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        product.items[itemIndex].configuration.width.min = 400;
                        product.items[itemIndex].configuration.width.max = 2400;
                        break;
                    case "Aluminium":
                        product.items[itemIndex].configuration.width.min = 350;
                        product.items[itemIndex].configuration.width.max = 2950;
                        break;
                }

                break;
            case "drop":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        product.items[itemIndex].configuration.drop.min = 100;
                        product.items[itemIndex].configuration.drop.max = 2500;
                        break;
                    case "Aluminium":
                        product.items[itemIndex].configuration.drop.min = 100;
                        product.items[itemIndex].configuration.drop.max = 2950;
                        break;
                }
                break;
            case "holdDownBracket":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        product.items[itemIndex].configuration.holdDownBracket.selected.isSelectable = true;
                        product.items[itemIndex].configuration.holdDownBracket.selected.isVisible = true;
                        break;
                    case "Aluminium":
                        product.items[itemIndex].configuration.holdDownBracket.selected.isSelectable = false;
                        product.items[itemIndex].configuration.holdDownBracket.selected.isVisible = false;
                        break;
                }
                product.items[itemIndex].configuration.holdDownBracket.selected.value = product.items[itemIndex].configuration.holdDownBracket.selected.isSelectable ? product.items[itemIndex].configuration.holdDownBracket.selected.value : false;

        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let comment, stocks, label, attribute, condition1, condition2, condition3;

        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    prodCode: PRODUCT_BUILDER_VENETIAN_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false,
                    description: "default"
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "productionCalculation":
                product.items[itemIndex].configuration.productionCalculation.selected.valanceReturnLength = this.doCalculation("valanceReturnLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.valanceLength = this.doCalculation("valanceLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.valanceHangerQty = this.doCalculation("valanceHangerQty", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.valanceMaterialUsage = this.doCalculation("valanceMaterialUsage", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.slatWidth = this.doCalculation("slatWidth", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.slatSpace = this.doCalculation("slatSpace", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.slatQty = this.doCalculation("slatQty", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.ladderQty = this.doCalculation("ladderQty", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.ladderLength = this.doCalculation("ladderLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.ladderTotalLengthUsage = this.doCalculation("ladderTotalLengthUsage", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.slatMaterialUsage = this.doCalculation("slatMaterialUsage", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.ladderSideSpace = this.doCalculation("ladderSideSpace", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.ladderSpaceBetween = this.doCalculation("ladderSpaceBetween", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.ladderLocationWithLiftCordIndication = this.doCalculation("ladderLocationWithLiftCordIndication", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.liftCordIndexPositionOnLadderLocation = this.doCalculation("liftCordIndexPositionOnLadderLocation", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.controlLength = this.doCalculation("controlLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.liftCordQty = this.doCalculation("liftCordQty", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.liftCordLength = this.doCalculation("liftCordLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.liftCordLengthOnLadderLocation = this.doCalculation("liftCordLengthOnLadderLocation", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.liftCordTotalLength = this.doCalculation("liftCordTotalLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.tiltCordLength = this.doCalculation("tiltCordLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.tasselQty = this.doCalculation("tasselQty", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.bottomRailAluminiumBottomRailLength = this.doCalculation("bottomRailAluminiumBottomRailLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.bottomRailRodCutLength = this.doCalculation("bottomRailRodCutLength", product, itemIndex);
                product.items[itemIndex].configuration.productionCalculation.selected.bottomRailCenterSupportQty = this.doCalculation("bottomRailCenterSupportQty", product, itemIndex);
                break;
            case "valance":
                attribute = "Valance";
                label = "";
                comment = "";
                stocks = [];

                condition1 = isEmpty(product.items[itemIndex].configuration.slatColour.selected.value) ? "" : product.items[itemIndex].configuration.slatColour.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.slatType.selected.value) ? "" : product.items[itemIndex].configuration.slatType.selected.value.optionKey;
                condition3 = isEmpty(product.items[itemIndex].configuration.mount.selected.value) ? "" : product.items[itemIndex].configuration.mount.selected.value.optionKey;
                product.items[itemIndex].configuration.valance.options.forEach(o => {
                    o.sets.forEach(set => {
                        if (
                            (set.condition1 ? set.condition1.includes(condition1) : true)
                            && (set.condition2 ? set.condition2.includes(condition2) : true)
                            && (set.condition3 ? set.condition3.includes(condition3) : true)
                        ) {
                            stocks = [...stocks, ...set.stocks]
                        }

                    })
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.valance.selected.stocks = stocks.filter(s => s.swishCalculatedQty || s.keywayCalculatedQty);
                break;
            case "tiltControl":
                attribute = "Tilt Control";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.tiltControl.options.forEach(o => {
                    if (o.optionKey === "bom") {
                        o.sets.forEach(set => {
                            if (set.condition1.includes(product.items[itemIndex].configuration.slatColour.selected.value.optionKey)
                                && set.condition2.includes(product.items[itemIndex].configuration.slatType.selected.value.optionKey)
                            ) {
                                stocks = [...stocks, ...set.stocks]
                            }
                        })
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.tiltControl.selected.stocks = stocks.filter(s => s.swishCalculatedQty || s.keywayCalculatedQty);
                break;
            case "holdDownBracket":
                attribute = "Hold Down Brackets";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.holdDownBracket.selected.value) {
                    product.items[itemIndex].configuration.holdDownBracket.options.forEach(o => {
                        stocks = [...stocks, ...o.stocks]
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.holdDownBracket.selected.stocks = stocks.filter(s => s.swishCalculatedQty || s.keywayCalculatedQty);
                break;
            case "bottomRail":
                attribute = "Bottom Rail";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.bottomRail.options.forEach(o => {
                    if (o.optionKey === "bom") {
                        o.sets.forEach(set => {
                            if (set.condition1.includes(product.items[itemIndex].configuration.slatColour.selected.value.optionKey)
                                && set.condition2.includes(product.items[itemIndex].configuration.slatType.selected.value.optionKey)
                            ) {
                                stocks = [...stocks, ...set.stocks]
                            }
                        })
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.bottomRail.selected.stocks = stocks.filter(s => s.swishCalculatedQty || s.keywayCalculatedQty);
                break;
            case "slat":
                attribute = "Slat";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.slat.options.forEach(o => {
                    if (o.condition1.includes(product.items[itemIndex].configuration.slatColour.selected.value.optionKey)
                        && o.condition2.includes(product.items[itemIndex].configuration.slatType.selected.value.optionKey)
                    ) {
                        stocks = [...stocks, ...o.stocks]
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.slat.selected.stocks = stocks.filter(s => s.swishCalculatedQty || s.keywayCalculatedQty);
                break;
        }
        return product;
    }

    doCalculation(key, product, itemIndex) {
        let result = 0, cordLengths = [], ladLocs = [];
        switch (key) {
            case "valanceReturnLength":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        result = 90; //90mm;
                        break;
                    case "Aluminium":
                        result = 0;
                        break;
                }
                break;
            case "valanceLength":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        result = 0;
                        switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                            case "Inside":
                                result = product.items[itemIndex].configuration.width.selected.value;
                                break;
                            case "Outside":
                                result = product.items[itemIndex].configuration.width.selected.value + 25;
                                break;
                        }
                        break;
                    case "Aluminium":
                        result = 0;
                        break;
                }
                break;
            case "valanceHangerQty":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        result = Math.round(product.items[itemIndex].configuration.productionCalculation.selected.valanceLength / 600) + 1;
                        break;
                    case "Aluminium":
                        result = Math.ceil(product.items[itemIndex].configuration.width.selected.value / 600);
                        result = result < 2 ? 2 : result;
                        break;
                }
                break;
            case "valanceMaterialUsage":
                result = 2410 / (product.items[itemIndex].configuration.width.selected.value <= 1000 ? 1 : 2);
                break;
            case "slatWidth":
                result = product.items[itemIndex].configuration.width.selected.finishWidth - 10;
                break;
            case "slatSpace":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Aluminium":
                        result = 20;
                        break;
                    case "Basswood":
                    case "Fauxwood":
                        result = 45;
                        break;
                }
                break;
            case "slatQty":
                result = Math.ceil(product.items[itemIndex].configuration.drop.selected.finishDrop / product.items[itemIndex].configuration.productionCalculation.selected.slatSpace);
                break;
            case "ladderQty":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Aluminium":
                        switch (true) {
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 750:
                                result = 2;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 1200:
                                result = 3;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 1650:
                                result = 4;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 2100:
                                result = 5;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 3000:
                                result = 7;
                                break;
                        }
                        break;
                    case "Basswood":
                    case "Fauxwood":
                        switch (true) {
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 600 :
                                result = 2;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 900 :
                                result = 3;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 1200:
                                result = 4;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 1500:
                                result = 5;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 1800:
                                result = 6;
                                break;
                            case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 2410:
                                result = 7;
                                break;
                        }
                        break;
                }
                break;
            case "ladderLength":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Aluminium":
                        result = product.items[itemIndex].configuration.drop.selected.finishDrop + 400;
                        break;
                    case "Basswood":
                    case "Fauxwood":
                        result = product.items[itemIndex].configuration.drop.selected.finishDrop + 300;
                        break;
                }
                break;
            case "ladderTotalLengthUsage":
                result = product.items[itemIndex].configuration.productionCalculation.selected.ladderQty * product.items[itemIndex].configuration.productionCalculation.selected.ladderLength;
                break;
            case "slatMaterialUsage":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Aluminium":
                        result = product.items[itemIndex].configuration.productionCalculation.selected.slatQty * product.items[itemIndex].configuration.productionCalculation.selected.slatWidth;
                        break;
                    case "Basswood":
                    case "Fauxwood":
                        result = product.items[itemIndex].configuration.productionCalculation.selected.slatQty / (product.items[itemIndex].configuration.width.selected.value <= 1100 ? 2 : 1);
                        break;
                }
                break;
            case "ladderSideSpace":
                let threshold = 0;
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Aluminium":
                        threshold = 500;
                        break;
                    case "Basswood":
                    case "Fauxwood":
                        threshold = 400;
                        break;
                }
                result = product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= threshold ? 100 : 150;
                break;
            case "ladderSpaceBetween":
                result = Math.round((product.items[itemIndex].configuration.productionCalculation.selected.slatWidth - ( 2 * product.items[itemIndex].configuration.productionCalculation.selected.ladderSideSpace)) / (product.items[itemIndex].configuration.productionCalculation.selected.ladderQty - 1));
                break;
            case "ladderLocationWithLiftCordIndication":

                ladLocs[0] = product.items[itemIndex].configuration.productionCalculation.selected.ladderSideSpace; // Initial starting loc from LHS
                for (let i = 1; i < product.items[itemIndex].configuration.productionCalculation.selected.ladderQty; i++) {
                    // Add ladder space to each previous location
                    ladLocs[i] = ladLocs[i - 1] + product.items[itemIndex].configuration.productionCalculation.selected.ladderSpaceBetween;
                }
                result = ladLocs.toString();
                break;
            case "liftCordIndexPositionOnLadderLocation":
                switch (true) {
                    case product.items[itemIndex].configuration.productionCalculation.selected.ladderQty === 2:
                        result = "0, 1";
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.ladderQty === 3:
                        result = "0, 2";
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.ladderQty === 4:
                        result = "0, 1, 2, 3";
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.ladderQty === 5:
                        result = "0, 2, 4";
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.ladderQty === 6:
                        result = "0, 2, 3, 5";
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.ladderQty === 7:
                        result = "0, 2, 4, 6";
                        break;
                }
                break;
            case "controlLength":
                switch (true) {
                    case product.items[itemIndex].configuration.drop.selected.value >= 0 && product.items[itemIndex].configuration.drop.selected.value <= 150 :
                        result = 150;
                        break;
                    case product.items[itemIndex].configuration.drop.selected.value >= 151 && product.items[itemIndex].configuration.drop.selected.value <= 500 :
                        result = product.items[itemIndex].configuration.drop.selected.finishDrop;
                        break;
                    case product.items[itemIndex].configuration.drop.selected.value >= 501 && product.items[itemIndex].configuration.drop.selected.value <= 1000 :
                        result = 500;
                        break;
                    case product.items[itemIndex].configuration.drop.selected.value >= 1001:
                        result = product.items[itemIndex].configuration.drop.selected.finishDrop / 2;
                        break;
                }
                break;
            case "liftCordQty":
                result = product.items[itemIndex].configuration.productionCalculation.selected.liftCordIndexPositionOnLadderLocation.split(',').length;
                break;
            case "liftCordLength":
                result = product.items[itemIndex].configuration.productionCalculation.selected.ladderSpaceBetween + 200 + (product.items[itemIndex].configuration.productionCalculation.selected.controlLength * 2);
                break;
            case "liftCordLengthOnLadderLocation":
                cordLengths = [];
                let liftPositions = product.items[itemIndex].configuration.productionCalculation.selected.liftCordIndexPositionOnLadderLocation.split(',');
                for (let i = 0; i < liftPositions.length; i++) {
                    let cordLen = parseInt(liftPositions[i]) * product.items[itemIndex].configuration.productionCalculation.selected.liftCordLength;
                    cordLengths.push(cordLen);
                }
                result = cordLengths.toString();
                break;
            case "liftCordTotalLength":
                result = 0;
                cordLengths = product.items[itemIndex].configuration.productionCalculation.selected.liftCordLengthOnLadderLocation.split(',');
                cordLengths.forEach(cordLength => {
                    result = result + parseFloat(cordLength);
                });
                break;
            case "tiltCordLength":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        result = (product.items[itemIndex].configuration.drop.selected.value * 2) + 500;
                        break;
                    case "Aluminium":
                        result = 0;
                        break;
                }
                break;
            case "tasselQty":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        result = product.items[itemIndex].configuration.productionCalculation.selected.liftCordQty + 2;
                        break;
                    case "Aluminium":
                        result = product.items[itemIndex].configuration.productionCalculation.selected.liftCordQty;
                        break;
                }
                break;
            case "bottomRailAluminiumBottomRailLength":
                switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
                    case "Basswood":
                    case "Fauxwood":
                        result = 0;
                        break;
                    case "Aluminium":
                        result = product.items[itemIndex].configuration.productionCalculation.selected.slatWidth - 20;
                        break;
                }
                break;
            case "bottomRailRodCutLength":
                result = product.items[itemIndex].configuration.productionCalculation.selected.slatWidth - 100;
                break;
            case "bottomRailCenterSupportQty":
                switch (true) {
                    case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 1000:
                        result = 0;
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 1800:
                        result = 1;
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth <= 2400:
                        result = 2;
                        break;
                    case product.items[itemIndex].configuration.productionCalculation.selected.slatWidth > 2400:
                        result = 3;
                        break;
                }
                break;

        }
        return result;
    }

    generateBMCode(product, itemIndex, order) {
        let bmCode = "";
        switch (product.items[itemIndex].configuration.slatType.selected.value.optionKey) {
            case "Aluminium":
                bmCode = "25mm Alum";
                break;
            case "Basswood":
                bmCode = "50mm Bass";
                break;
            case "Fauxwood":
                bmCode = "50mm Faux";
                break;
        }
        return bmCode;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("productionCalculation", product, itemIndex, order);
            product = this.updateStocks("valance", product, itemIndex, order);
            product = this.updateStocks("tiltControl", product, itemIndex, order);
            product = this.updateStocks("holdDownBracket", product, itemIndex, order);
            product = this.updateStocks("bottomRail", product, itemIndex, order);
            product = this.updateStocks("slat", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.valance.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.tiltControl.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.holdDownBracket.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRail.selected.stocks];
                stocks = [...stocks, ...product.items[itemIndex].configuration.slat.selected.stocks];
            }
            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling, discountByProductCode);
        return product;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        stockQtyExpression = stockQtyExpression.replaceAll("@ValanceLength", params.valanceLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@ValanceReturnLength", params.valanceReturnLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@ValanceHangerQty", params.valanceHangerQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@ValanceMaterialUsage", params.valanceMaterialUsage);
        stockQtyExpression = stockQtyExpression.replaceAll("@SlatWidth", params.slatWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SlatSpace", params.slatSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@SlatQty", params.slatQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@LadderQty", params.ladderQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@LadderLength", params.ladderLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@LadderTotalLengthUsage", params.ladderTotalLengthUsage);
        stockQtyExpression = stockQtyExpression.replaceAll("@SlatMaterialUsage", params.slatMaterialUsage);
        stockQtyExpression = stockQtyExpression.replaceAll("@LadderSideSpace", params.ladderSideSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@LadderSpaceBetween", params.ladderSpaceBetween);
        stockQtyExpression = stockQtyExpression.replaceAll("@LadderLocationWithLiftCordIndication", params.ladderLocationWithLiftCordIndication);
        stockQtyExpression = stockQtyExpression.replaceAll("@LiftCordIndexPositionOnLadderLocation", params.liftCordIndexPositionOnLadderLocation);
        stockQtyExpression = stockQtyExpression.replaceAll("@ControlLength", params.controlLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@LiftCordQty", params.liftCordQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@LiftCordLength", params.liftCordLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@liftCordLengthOnLadderLocation", params.liftCordLengthOnLadderLocation);
        stockQtyExpression = stockQtyExpression.replaceAll("@LiftCordTotalLength", params.liftCordTotalLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@TiltCordLength", params.tiltCordLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@TasselQty", params.tasselQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@BottomRailAluminiumBottomRailLength", params.bottomRailAluminiumBottomRailLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@BottomRailRodCutLength", params.bottomRailRodCutLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@BottomRailCenterSupportQty", params.bottomRailCenterSupportQty);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        let deduction = {};
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;

        let params = {
            width: width,
            drop: drop,
            stockLength: stockItem.length,
            swishConversionFactor: stockItem.swishConversionFactor,
            valanceReturnLength: product.items[itemIndex].configuration.productionCalculation.selected.valanceReturnLength,
            valanceLength: product.items[itemIndex].configuration.productionCalculation.selected.valanceLength,
            valanceHangerQty: product.items[itemIndex].configuration.productionCalculation.selected.valanceHangerQty,
            valanceMaterialUsage: product.items[itemIndex].configuration.productionCalculation.selected.valanceMaterialUsage,
            slatWidth: product.items[itemIndex].configuration.productionCalculation.selected.slatWidth,
            slatSpace: product.items[itemIndex].configuration.productionCalculation.selected.slatSpace,
            slatQty: product.items[itemIndex].configuration.productionCalculation.selected.slatQty,
            ladderQty: product.items[itemIndex].configuration.productionCalculation.selected.ladderQty,
            ladderLength: product.items[itemIndex].configuration.productionCalculation.selected.ladderLength,
            ladderTotalLengthUsage: product.items[itemIndex].configuration.productionCalculation.selected.ladderTotalLengthUsage,
            slatMaterialUsage: product.items[itemIndex].configuration.productionCalculation.selected.slatMaterialUsage,
            ladderSideSpace: product.items[itemIndex].configuration.productionCalculation.selected.ladderSideSpace,
            ladderSpaceBetween: product.items[itemIndex].configuration.productionCalculation.selected.ladderSpaceBetween,
            ladderLocationWithLiftCordIndication: product.items[itemIndex].configuration.productionCalculation.selected.ladderLocationWithLiftCordIndication,
            liftCordIndexPositionOnLadderLocation: product.items[itemIndex].configuration.productionCalculation.selected.liftCordIndexPositionOnLadderLocation,
            controlLength: product.items[itemIndex].configuration.productionCalculation.selected.controlLength,
            liftCordQty: product.items[itemIndex].configuration.productionCalculation.selected.liftCordQty,
            liftCordLength: product.items[itemIndex].configuration.productionCalculation.selected.liftCordLength,
            liftCordLengthOnLadderLocation: product.items[itemIndex].configuration.productionCalculation.selected.liftCordLengthOnLadderLocation,
            liftCordTotalLength: product.items[itemIndex].configuration.productionCalculation.selected.liftCordTotalLength,
            tiltCordLength: product.items[itemIndex].configuration.productionCalculation.selected.tiltCordLength,
            tasselQty: product.items[itemIndex].configuration.productionCalculation.selected.tasselQty,
            bottomRailAluminiumBottomRailLength: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailAluminiumBottomRailLength,
            bottomRailRodCutLength: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailRodCutLength,
            bottomRailCenterSupportQty: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailCenterSupportQty,
        };

        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Valance":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : (stockItem.cutWidthFormula ? this.resolveFormulaExpression(result, stockItem.cutWidthFormula.formula, params) : 0);
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : (stockItem.cutDropFormula ? this.resolveFormulaExpression(result, stockItem.cutDropFormula.formula, params) : 0);
                break;
            case "Tilt Control":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : (stockItem.cutWidthFormula ? this.resolveFormulaExpression(result, stockItem.cutWidthFormula.formula, params) : 0);
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : (stockItem.cutDropFormula ? this.resolveFormulaExpression(result, stockItem.cutDropFormula.formula, params) : 0);
                break;
            case "Bottom Rail":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : (stockItem.cutWidthFormula ? this.resolveFormulaExpression(result, stockItem.cutWidthFormula.formula, params) : 0);
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : (stockItem.cutDropFormula ? this.resolveFormulaExpression(result, stockItem.cutDropFormula.formula, params) : 0);
                break;
            case "Hold Down Brackets":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Slat":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : (stockItem.cutWidthFormula ? this.resolveFormulaExpression(result, stockItem.cutWidthFormula.formula, params) : 0);
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : (stockItem.cutDropFormula ? this.resolveFormulaExpression(result, stockItem.cutDropFormula.formula, params) : 0);
                break;
            default:
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
        }
    }

    updateDeduction(key, product, itemIndex, order) {
        return {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        return errors;
    }

    toSalesOrderItemVenetianRequest(product, itemIndex, order) {
        let salesOrderItemVenetian = {
            ID: product.items[itemIndex].configuration.ID ? product.items[itemIndex].ID : null,
            salesOrderItemID: product.items[itemIndex].configuration.salesOrderItemID ? product.items[itemIndex].salesOrderItemID : null,
            salesOrderID: product.items[itemIndex].configuration.salesOrderID ? product.items[itemIndex].salesOrderID : null,
            bmCode: product.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: product.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: product.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: product.items[itemIndex].configuration.location.selected.value,
            quantity: product.items[itemIndex].configuration.quantity.selected.value,
            width: product.items[itemIndex].configuration.width.selected.value,
            drop: product.items[itemIndex].configuration.drop.selected.value,
            finishWidth: product.items[itemIndex].configuration.width.selected.finishWidth,
            finishDrop: product.items[itemIndex].configuration.drop.selected.finishDrop,
            slatType: product.items[itemIndex].configuration.slatType.selected.value.optionKey,
            slatColour: product.items[itemIndex].configuration.slatColour.selected.value.optionKey,
            liftControl: product.items[itemIndex].configuration.liftControl.selected.value.optionKey,
            tiltControl: product.items[itemIndex].configuration.tiltControl.selected.value.optionKey,
            mount: product.items[itemIndex].configuration.mount.selected.value.optionKey,
            holdDownBracket: product.items[itemIndex].configuration.holdDownBracket.selected.value,

            valanceReturnLength: product.items[itemIndex].configuration.productionCalculation.selected.valanceReturnLength,
            valanceLength: product.items[itemIndex].configuration.productionCalculation.selected.valanceLength,
            valanceHangerQty: product.items[itemIndex].configuration.productionCalculation.selected.valanceHangerQty,
            valanceMaterialUsage: product.items[itemIndex].configuration.productionCalculation.selected.valanceMaterialUsage,
            slatWidth: product.items[itemIndex].configuration.productionCalculation.selected.slatWidth,
            slatSpace: product.items[itemIndex].configuration.productionCalculation.selected.slatSpace,
            slatQty: product.items[itemIndex].configuration.productionCalculation.selected.slatQty,
            ladderQty: product.items[itemIndex].configuration.productionCalculation.selected.ladderQty,
            ladderLength: product.items[itemIndex].configuration.productionCalculation.selected.ladderLength,
            ladderTotalLengthUsage: product.items[itemIndex].configuration.productionCalculation.selected.ladderTotalLengthUsage,
            slatMaterialUsage: product.items[itemIndex].configuration.productionCalculation.selected.slatMaterialUsage,
            ladderSideSpace: product.items[itemIndex].configuration.productionCalculation.selected.ladderSideSpace,
            ladderSpaceBetween: product.items[itemIndex].configuration.productionCalculation.selected.ladderSpaceBetween,
            ladderLocationWithLiftCordIndication: product.items[itemIndex].configuration.productionCalculation.selected.ladderLocationWithLiftCordIndication,
            liftCordIndexPositionOnLadderLocation: product.items[itemIndex].configuration.productionCalculation.selected.liftCordIndexPositionOnLadderLocation,
            controlLength: product.items[itemIndex].configuration.productionCalculation.selected.controlLength,
            liftCordQty: product.items[itemIndex].configuration.productionCalculation.selected.liftCordQty,
            liftCordLength: product.items[itemIndex].configuration.productionCalculation.selected.liftCordLength,
            liftCordLengthOnLadderLocation: product.items[itemIndex].configuration.productionCalculation.selected.liftCordLengthOnLadderLocation,
            liftCordTotalLength: product.items[itemIndex].configuration.productionCalculation.selected.liftCordTotalLength,
            tiltCordLength: product.items[itemIndex].configuration.productionCalculation.selected.tiltCordLength,
            tasselQty: product.items[itemIndex].configuration.productionCalculation.selected.tasselQty,
            bottomRailAluminiumBottomRailLength: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailAluminiumBottomRailLength,
            bottomRailRodCutLength: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailRodCutLength,
            bottomRailCenterSupportQty: product.items[itemIndex].configuration.productionCalculation.selected.bottomRailCenterSupportQty,


            stocks: product.items[itemIndex].stocks
        };
        return salesOrderItemVenetian;
    }
}

export default VenetianUtil.Instance();