import React, { Component } from 'react';
import { Button, Card, CardBody, Col, FormText, Input, Row, Spinner, ButtonGroup, Label, FormGroup } from 'reactstrap';
import Select from 'react-select'
import { changeFormatOfDateString, getDeliveryAddressString, getValueOrDefault, handleErrorMessage } from '../../services/CommonService';
import AsyncSelect from 'react-select/async';
import { isRetailBoardVisible } from '../../services/CommonFunctions';
import CustomerService from '../../services/CustomerService';
import UserService from '../../services/UserService';
import salesOrderProductBuilderV1Service from '../../services/sales/SalesOrderProductBuilderV1Service';
import freightService from '../../services/FreightService';
import addressService from '../../services/AddressService';
import OrderService from '../../services/OrderService';
import courierService from '../../services/shipment/CourierService';
import packagingHandlingChargesService from '../../services/shipment/PackagingHandlingChargesService';
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';
import DebtorAddressModal from '../../components/address/DebtorAddressModal';
import CustomerDetails from '../../components/sales/create/productBuilderKeywayVersion/CustomerDetails';
import queryString from 'query-string';
import { toast, ToastContainer } from 'react-toastify';
import { cloneDeep, isEmpty } from 'lodash';
import retailService from '../../services/retail/RetailService'
import productService from '../../services/product/ProductService'
import SalesOrderProductBuilderCustomerRetailDetail from '../../components/modal/SalesOrderProductBuilderCustomerRetailDetail';
import SalesOrderProductBuilderOrderSummaryDetail from '../../components/modal/SalesOrderProductBuilderOrderSummaryDetail';
import { FREIGHT_RULE_FREIGHT_OVERIDE, PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS, PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM, PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES, taxRate } from "../../store/AppConstants";
import SalesOrderProductBuilderAdditionalPreApprovedRetailDiscount from '../../components/modal/SalesOrderProductBuilderAdditionalPreApprovedRetailDiscount';
import RetailCustomerDetail from '../../components/sales/create/productBuilderKeywayVersion/RetailCustomerDetail';
import SalesOrderProductBuilderOrderSummaryTableDefault from '../sales/order/create/productBuilderKeywayVersion/SalesOrderProductBuilderOrderSummaryTableDefault';
import SalesOrderProductBuilderOrderSummaryTableRetail from '../sales/order/create/productBuilderKeywayVersion/SalesOrderProductBuilderOrderSummaryTableRetail';
import BulkOrderService from '../../services/BulkOrderService';

const addressFields = ["contactName", "phone", "email", "delCompany", "delAddr1", "delAddr2", "delCity", "delState", "delPostCode"];

export default class BulkOrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultAccountID: "",
            order: {},
            products: [],
            isOpenSavedAddressModal: false,
            loadingOrder: false,
            loadingProducts: false,
            loadingSubmitOrder: false,
            loadingSubmitQuote: false,
            discountByProductCode: {},
            isLoadingDiscountByProductCode: {},
            freight: salesOrderProductBuilderV1Service.initFreightObj(),
            packagingAndHandling: {
                rules: [],
                chargeByProductCode: {}
            },
            user: {},
            navigation: {
                tab: {
                    list: [],
                    active: {}
                }, subTab: {
                    all: [],
                    list: [],
                    active: {}
                }
            },
            isOpenRetailCustomerDetail: false,
            productMarkupByProductCode: {},
            retailVisible: false,
            isOpenOrderSummaryDetail: false,
            isOpenRetailPreApprovedDiscountModal: false,
            formError: {
                isValid: true,
                errors: []
            },
            isUnCancelOrder: false,
            isOrderSubmitDisabled: false,
            isQuoteSubmitDisabled: false,
            selectedProduct: {
                value: null,
                label: ""
            }
        };
        this.userService = new UserService();
        this.orderService = new OrderService();
        this.customerService = new CustomerService();
        this.getOrder = this.getOrder.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.getCouriers = this.getCouriers.bind(this);
        this.toggleProductTab = this.toggleProductTab.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.toggleSavedAddressModal = this.toggleSavedAddressModal.bind(this);
        this.fetchCustomer = this.fetchCustomer.bind(this);
        this.fetchDebtorDiscount = this.fetchDebtorDiscount.bind(this);
        this.fetchPackagingAndHandlingRules = this.fetchPackagingAndHandlingRules.bind(this);
        this.fetchFreightRules = this.fetchFreightRules.bind(this);
        this.checkMetroPostcode = this.checkMetroPostcode.bind(this);
        this.toggleRetailCustomerModal = this.toggleRetailCustomerModal.bind(this);
        this.getProductMarkup = this.getProductMarkup.bind(this);
        this.toggleOrderSummaryModal = this.toggleOrderSummaryModal.bind(this);
        this.toggleRetailPreApprovedDiscountModal = this.toggleRetailPreApprovedDiscountModal.bind(this);
        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.bulkOrderService = new BulkOrderService();
    }

    componentDidMount() {
        let user = this.userService.getLoggedInUser();
        let hasBulkOrderWritePrivilege = this.userService.hasPrivilege(user, "bulk-order-write");
        if (!hasBulkOrderWritePrivilege) {
            this.props.history.push('/');
        }
        let { order } = this.state;
        let defaultAccountID = "";
        let isUnCancelOrder = false;
        order.id = 0;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            if (!isNaN(searchParams.orderNum)) {
                order.id = parseInt(searchParams.orderNum);
            }
            if (searchParams.accountID) {
                defaultAccountID = searchParams.accountID;
            }
            if (searchParams && searchParams.isUnCancelOrder) {
                isUnCancelOrder = true;
            }
        }
        this.setState({ user, defaultAccountID, isUnCancelOrder });
        if (user.isExternalUser) {
            this.setState({ defaultAccountID: user.menu.accountID });
        }
        this.getOrder(order.id);
        if (this.userService.hasRole(user, "ExternalSalesRep")) {
            this.retailBoardVisible(user.menu.accountID);
        }
        this.getProducts(order.accountID);
    }

    handleContinueClick() {
        let { selectedProduct, order, freight, retailVisible } = this.state;
        let request = {
            id: order.id,
            accountID: order.accountID,
            debtorInvoiceOrdNum: order.debtorInvoiceOrdNum ? order.debtorInvoiceOrdNum : null,
            isQuote: true,
            contactName: order.contactName,
            fax: order.fax,
            phone: order.phone,
            email: order.email,
            delCompany: order.delCompany,
            delAddr1: order.delAddr1,
            delAddr2: order.delAddr2,
            delAddr3: order.delAddr3,
            delAddr4: order.delAddr4,
            delCity: order.delCity,
            delState: order.delState,
            delPostCode: order.delPostCode,
            requiredDateString: order.requiredDateString ? order.requiredDateString : "",
            notes: order.notes,
            custOrdNum: order.custOrdNum,
            jobRef: order.jobRef ? order.jobRef : "",
            freightMethod: freight.selectedMethodOption,
            freightRuleID: order.pricing.freight.ruleId,
            freightOverrideCourierName: freight.freightOverrideCourierName,
            freightOverrideCourierCharge: freight.freightOverrideCourierCharge,
            salesOrderStatus: { ID: (order.salesOrderStatus && order.salesOrderStatus.ID) ? order.salesOrderStatus.ID : 0 },
            salesOrderItems: [],
            attachmentIDs: order.attachmentIDs,
            enteredByUser: null,
            isRetail: order.isRetail,
            retailFirstName: order.retailFirstName,
            retailLastName: order.retailLastName,
            retailEmail: order.retailEmail,
            retailPhone: order.retailPhone,
            retailMarkupDiscount: order.retailMarkupDiscount,
            retailMarkupDiscountValue: !isNaN(order.pricing.retailMarkupValueDiscVal) ? order.pricing.retailMarkupValueDiscVal : 0,
            retailAddressLine1: order.retailAddressLine1,
            retailAddressLine2: order.retailAddressLine2,
            retailAddressLine3: order.retailAddressLine3,
            retailAddressLine4: order.retailAddressLine4,
            retailCity: order.retailCity,
            retailState: order.retailState,
            retailPostCode: order.retailPostCode,
            courierInstructions: order.courierInstructions,
            isAuthorityToLeave: order.isAuthorityToLeave,
            isUnCancelOrder: false,
            isBulkOrder: true,
            selectedProduct: selectedProduct
        };
        let formError = {
            isValid: true,
            errors: []
        };
        formError = salesOrderProductBuilderV1Service.validateForm(formError, request, retailVisible);
        if (!formError.isValid) {
            if (!isEmpty(formError.errors)) {
                this.setState({ formError });
                toast.dismiss();
                formError.errors.forEach(error => {
                    toast.info(error);
                });
                return;
            }
        }
        this.bulkOrderService.createBulkOrder(request)
            .then(response => {
                const ordNum = response.data;
                this.props.history.push({
                    pathname: '/bulk-order/import',
                    state: { selectedProduct, ordNum }
                });
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    toggleRetailPreApprovedDiscountModal(data) {
        this.setState({ isOpenRetailPreApprovedDiscountModal: data });
    }

    getProductMarkup(accountID) {
        if (accountID) {
            retailService.getProductMarkups(accountID).then(response => {
                let productMarkup = response.data;
                if (productMarkup) {
                    let { productMarkupByProductCode, products } = this.state;
                    products.forEach(product => {
                        let productMarkupIndex = (productMarkup || []).findIndex(x => x.productID === product.id);
                        if (productMarkupIndex > -1) {
                            productMarkupByProductCode[product.code] = productMarkup[productMarkupIndex];
                        }
                    });
                    this.setState({ productMarkupByProductCode });
                }
            }).catch(error => {
                toast.error(error.message, { position: toast.POSITION.BOTTOM_CENTER });
            });
        }
    }

    toggleRetailCustomerModal(isOpenRetailCustomerDetail) {
        this.setState({ isOpenRetailCustomerDetail: isOpenRetailCustomerDetail });
    }

    toggleOrderSummaryModal(isOpenOrderSummaryDetail) {
        this.setState({ isOpenOrderSummaryDetail: isOpenOrderSummaryDetail });
    }

    retailBoardVisible(accountID) {
        isRetailBoardVisible(result => {
            if (result.status !== 200) {
                toast.error(handleErrorMessage(result));
            } else {
                this.setState({ retailVisible: result.data }, () => {
                    if (result.data) {
                        this.handleOrderChange("customer", accountID);
                        this.state.order.isRetail = true;
                    }
                });
            }
        });
    }

    getProducts(accountID) {
        this.setState({ loading: true });
        productService.getProducts()
            .then(response => {
                this.setState({
                    products: response.data.filter(product => product.id === 15).map(product => ({
                        value: product.id,
                        label: product.name
                    })),
                    loading: false
                });
            })
            .catch(error => {
                console.error('Error fetching products:', error);
                this.setState({ loading: false });
            });
    };

    getOrder(id) {
        let { order, freight, isOrderSubmitDisabled, isQuoteSubmitDisabled } = this.state;
        if (id) {
            this.setState({ loadingOrder: true });
            salesOrderProductBuilderV1Service.getSalesOrder(id).then(response => {
                order = response.data;
                order.pricing = {
                    price: 0,
                    discVal: 0,
                    tax: 0,
                    total: 0,
                    freight: {
                        charge: 0,
                        ruleId: 0
                    }, packagingAndHandlingCharges: 0
                };
                freight.selectedMethodOption = order.freightMethod;
                freight.freightOverrideCourierName = order.courier;
                freight.freightOverrideCourierCharge = order.freightCharge;
                isOrderSubmitDisabled = salesOrderProductBuilderV1Service.shouldOrderSubmitDisabled(order, this.userService.getLoggedInUser());
                isQuoteSubmitDisabled = salesOrderProductBuilderV1Service.shouldQuoteSubmitDisabled(order, this.userService.getLoggedInUser());
                this.setState({
                    order,
                    loadingOrder: false,
                    freight,
                    isOrderSubmitDisabled,
                    isQuoteSubmitDisabled
                }, () => {
                    //this.getProducts(order.accountID);
                });
            }).catch(error => {
                console.log(error);
                this.setState({ loadingOrder: false });
                toast.error(handleErrorMessage(error));
            })
        }
        else {
            order = {
                pricing: {
                    price: 0,
                    discVal: 0,
                    tax: 0,
                    total: 0,
                    freight: {
                        charge: 0,
                        ruleId: 0
                    }, packagingAndHandlingCharges: 0
                },
                retailMarkupDiscount: 0
            };
            this.setState({ order }, () => {
                if (!this.state.defaultAccountID) {
                }
            });
        }
    }

    fetchCustomer(accountID) {
        this.customerService.searchCustomer(accountID).then(response => {
            if (response.data) {
                this.handleOrderChange("debtor", response.data);
                this.handleOrderChange("address", response.data);
                this.setState({
                    selectedProduct: {
                        value: null,
                        label: ""
                    }
                });
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    fetchDebtorDiscount(accountID) {
        this.setState({ isLoadingDiscountByProduct: true });
        this.customerService.getDiscount(accountID).then(response => {
            let productGroupDiscount = response.data;
            if (productGroupDiscount) {
                let { discountByProductCode, order, products } = this.state;
                let customDiscount = 0, productGroupDiscountIndex = -1;

                products.forEach(product => {
                    productGroupDiscountIndex = (productGroupDiscount || []).findIndex(pgd => pgd.productGroupID === product.productGroupID);
                    if (productGroupDiscountIndex > -1) {
                        //extract customer discount
                        discountByProductCode[product.code] = productGroupDiscount[productGroupDiscountIndex];
                        customDiscount = 0;
                        //extract order discount
                        if (!isEmpty(order) && !isEmpty(order.productGroupDiscounts) && order.accountID === accountID) {
                            productGroupDiscountIndex = (order.productGroupDiscounts || []).findIndex(pgd => pgd.productGroupID === product.productGroupID);
                            if (productGroupDiscountIndex > -1) {
                                customDiscount = order.productGroupDiscounts[productGroupDiscountIndex].discount;
                                if (customDiscount > 0) {
                                    discountByProductCode[product.code].discount = customDiscount;
                                }
                            }
                        }
                    }
                });

                this.setState({ discountByProductCode, isLoadingDiscountByProduct: false });
            }
        }).catch(error => {
            this.setState({ isLoadingDiscountByProduct: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }


    fetchFreightRules(accountID, isFreightResetRequired) {
        let freight = this.state.freight;
        if (accountID) {
            freight.isLoadingAllRules = true;
            this.setState({ freight });
            freightService.getCustomerFreightOptions(accountID).then(response => {
                let { freight, order } = this.state;
                freight.allRules = response.data;
                freight.isLoadingAllRules = false;
                freight = salesOrderProductBuilderV1Service.updateFreightMethodOptions(freight, order.delPostCode, isFreightResetRequired);
                this.setState({ freight }, () => {
                    this.checkMetroPostcode(this.state.order.delPostCode);
                    this.getCouriers();
                });
            }).catch(error => {
                freight.isLoadingAllRules = false;
                this.setState({ freight });
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        } else {
            let freight = salesOrderProductBuilderV1Service.initFreightObj();
            this.setState({ freight });
        }
    }

    getCouriers() {
        let { freight } = this.state;
        courierService.getCouriers().then(response => {
            freight.couriers = response.data;
            this.setState({ freight });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }


    fetchPackagingAndHandlingRules(accountID) {
        let { packagingAndHandling, products } = this.state;
        let productIndex = -1;
        packagingHandlingChargesService.fetchSpecialCustomerOrDefaultRules(accountID).then(response => {
            packagingAndHandling.rules = response.data;
            products.forEach(product => {
                productIndex = (packagingAndHandling.rules || []).findIndex(r => r.productID === product.id);
                if (productIndex > -1) {
                    packagingAndHandling.chargeByProductCode[product.code] = packagingAndHandling.rules[productIndex].charge;
                }
            });
            this.setState({ packagingAndHandling });
        }).catch(error => {
            console.error(error);
            toast.error(handleErrorMessage(error));
        });
    }

    checkMetroPostcode(postcode) {
        let { freight, order } = this.state;
        if (postcode && postcode.length > 0) {

            freight.isLoadingMetroPostcodeCheck = true;
            this.setState({ freight });
            addressService.getAddressStandardByPostcode(postcode).then(response => {
                order = this.state.order;
                freight = this.state.freight;
                freight.isMetroPostcode = response.data && response.data.isMetro;
                freight.isLoadingMetroPostcodeCheck = false;
                order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({ order, freight });
            }).catch(error => {
                freight = this.state.freight;
                freight.isLoadingMetroPostcodeCheck = false;
                this.setState({ freight });
                toast.error(handleErrorMessage(error));
            })
        } else {
            order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
            order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
            this.setState({ order, freight });
        }
    }

    handleOrderChange(key, change) {
        let { order, products, freight, productMarkupByProductCode } = this.state;
        let price, discVal, discountedPrice, tax, wwCost;
        let formError = {
            isValid: true,
            errors: []
        };
        switch (key) {
            case "customer":
                if (order.accountID !== change) {
                    order.accountID = change;
                    order.custOrdNum = "";
                    order.notes = "";
                    order.jobRef = "";
                    order.debtorInvoiceOrdNum = 0;
                    order.salesOrderItems = [];
                    order.salesOrderStatus = [];
                    order.maxWidth = 0;
                    order.pricing = {
                        price: 0,
                        discVal: 0,
                        tax: 0,
                        total: 0,
                        freight: {
                            charge: 0,
                            ruleId: 0
                        }
                    };
                    order.retailMarkupDiscount = 0;
                    this.setState({ order }, () => {
                        if (order.accountID) {
                            this.fetchCustomer(order.accountID);
                        } else {
                            this.setState({
                                navigation: {
                                    tab: {
                                        list: [],
                                        active: {}
                                    },
                                    subTab: {
                                        all: [],
                                        list: [],
                                        active: {}
                                    }
                                }
                            });
                        }
                    });
                }
                break;
            case "address":
                order.contactName = change.contactName ? change.contactName : (change.accountID?.includes("01500") ? "Special Orders" : "Store Manager");
                order.phone = change.phone;
                order.email = change.email;
                order.fax = change.fax;
                order.delCompany = change.company;
                order.delAddr1 = change.address1;
                order.delAddr2 = change.address2;
                order.delAddr3 = change.address3;
                order.delAddr4 = change.address4;
                order.delCity = change.city;
                order.delState = change.state;
                order.delPostCode = change.postCode;
                formError = salesOrderProductBuilderV1Service.validateForm(formError, order, false);
                this.setState({ order, isOpenSavedAddressModal: false, formError }, () => {
                    this.fetchFreightRules(order.accountID, false);
                });
                break;
            case "debtor":
                order.debtor = change;
                this.setState({ order });
                break;
            case "custOrdNum":
            case "notes":
            case "attachmentIDs":
                order[key] = change;
                this.setState({ order });
                break;
            case "product":
                order.pricing = {
                    price: 0,
                    discVal: 0,
                    tax: 0,
                    total: 0,
                    freight: {
                        charge: 0,
                        ruleId: 0
                    }, packagingAndHandlingCharges: 0,
                    retailMarkupValue: 0,
                    retailMarkupValueDiscVal: 0,
                    retailMarkupValueTax: 0,
                    retailMarkupValueTotal: 0,
                };
                order.maxWidth = 0;
                products.forEach((p, productIndex) => {
                    if (p.pricing && p.pricing.price) {
                        price = p.pricing.price ? p.pricing.price : 0;
                        discVal = p.pricing.discVal ? p.pricing.discVal : 0;
                        if (order.isRetail) {
                            p.pricing.retailMarkup = salesOrderProductBuilderV1Service.getRetailMarkupByProduct(p, productMarkupByProductCode);
                            discountedPrice = price - discVal;
                            tax = discountedPrice * taxRate;
                            wwCost = discountedPrice + tax;

                            p.pricing.retailMarkupValue = salesOrderProductBuilderV1Service.calculateValueWithPercentage(wwCost, p.pricing.retailMarkup);
                        } else {
                            p.pricing.retailMarkup = 0;
                            p.pricing.retailMarkupValue = 0;
                        }
                        order.pricing.price += price;
                        order.pricing.discVal += discVal;
                        order.pricing.retailMarkupValue += p.pricing.retailMarkupValue;
                        order.pricing.packagingAndHandlingCharges += p.pricing.packagingAndHandlingCharges ? p.pricing.packagingAndHandlingCharges : 0;
                    }
                    if (p.maxWidth) {
                        if (order.maxWidth < p.maxWidth) {
                            order.maxWidth = p.maxWidth;
                        }
                    }
                    p = salesOrderProductBuilderV1Service.updateProductItemCount(products, productIndex, "ITEMS");
                });
                order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({ order, products });
                break;
            case "freightMethod":
                if (change) {
                    freight.selectedMethodOption = change;
                    if (freight.selectedMethodOption !== FREIGHT_RULE_FREIGHT_OVERIDE) {
                        freight.freightOverrideCourierName = "";
                        freight.freightOverrideCourierCharge = 0;
                    }
                    order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                    order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                    this.setState({ freight, order });
                }
                break;
            case "freightOverrideCourierName":
                freight[key] = change;
                order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({ freight, order });
                break;
            case "freightOverrideCourierCharge":
                freight[key] = parseFloat(getValueOrDefault(change, 0));
                order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({ freight, order });
                break;
            case "retailMarkupDiscount":
                order.retailMarkupDiscount = change;
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({ order, isOpenRetailPreApprovedDiscountModal: false });
                break;
            case "changeProduct":
                this.setState({ selectedProduct: change });
                break;
            default:
                if (key) {
                    order[key] = change;
                    this.setState({ order });
                }
                else {
                    toast.error("key not found");
                }
        }
    }

    toggleSavedAddressModal(change) {
        this.setState({ isOpenSavedAddressModal: change });
    }

    toggleProductTab(change) {
        let { products, navigation } = this.state;
        let tabIndex = -1, subTabIndex = -1, productIndex;

        if (change === "ORDER_FORM_TAB") {
            navigation.tab.active = {
                id: "ORDER_FORM_TAB",
                parentProductID: null,
                code: "ORDER_FORM_TAB",
                name: "ORDER_FORM_TAB"
            }
        } else {
            productIndex = products.findIndex(p => p.code === change);
            if (productIndex > -1) {
                tabIndex = navigation.tab.list.findIndex(i => i.code === products[productIndex].code);
                if (tabIndex > -1) {
                    navigation.tab.active = navigation.tab.list[tabIndex];

                    navigation.subTab.list = products.filter(p => p.parentProductID === navigation.tab.active.id);
                    subTabIndex = 0;
                } else {
                    subTabIndex = navigation.subTab.list.findIndex(i => i.code === products[productIndex].code);
                    subTabIndex = subTabIndex > -1 ? subTabIndex : 0;
                }
                if (!isEmpty(navigation.subTab.list)) {
                    navigation.subTab.active = navigation.subTab.list[subTabIndex];
                }
            }
        }

        this.setState({ navigation });
    }

    submitOrder(orderType) {
        let { order, products, freight, packagingAndHandling, discountByProductCode, isUnCancelOrder, productMarkupByProductCode, retailVisible } = this.state;
        let request = {
            id: order.id,
            accountID: order.accountID,
            debtorInvoiceOrdNum: order.debtorInvoiceOrdNum ? order.debtorInvoiceOrdNum : null,
            isQuote: orderType !== "Order",
            contactName: order.contactName,
            fax: order.fax,
            phone: order.phone,
            email: order.email,
            delCompany: order.delCompany,
            delAddr1: order.delAddr1,
            delAddr2: order.delAddr2,
            delAddr3: order.delAddr3,
            delAddr4: order.delAddr4,
            delCity: order.delCity,
            delState: order.delState,
            delPostCode: order.delPostCode,
            requiredDateString: order.requiredDateString ? order.requiredDateString : "",
            notes: order.notes,
            custOrdNum: order.custOrdNum,
            jobRef: order.jobRef ? order.jobRef : "",
            freightMethod: freight.selectedMethodOption,
            freightRuleID: order.pricing.freight.ruleId,
            freightOverrideCourierName: freight.freightOverrideCourierName,
            freightOverrideCourierCharge: freight.freightOverrideCourierCharge,
            salesOrderStatus: { ID: (order.salesOrderStatus && order.salesOrderStatus.ID) ? order.salesOrderStatus.ID : 0 },
            salesOrderItems: [],
            attachmentIDs: order.attachmentIDs,
            enteredByUser: null,
            isRetail: order.isRetail,
            retailFirstName: order.retailFirstName,
            retailLastName: order.retailLastName,
            retailEmail: order.retailEmail,
            retailPhone: order.retailPhone,
            retailMarkupDiscount: order.retailMarkupDiscount,
            retailMarkupDiscountValue: !isNaN(order.pricing.retailMarkupValueDiscVal) ? order.pricing.retailMarkupValueDiscVal : 0,
            retailAddressLine1: order.retailAddressLine1,
            retailAddressLine2: order.retailAddressLine2,
            retailAddressLine3: order.retailAddressLine3,
            retailAddressLine4: order.retailAddressLine4,
            retailCity: order.retailCity,
            retailState: order.retailState,
            retailPostCode: order.retailPostCode,
            courierInstructions: order.courierInstructions,
            isAuthorityToLeave: order.isAuthorityToLeave,
            isUnCancelOrder: isUnCancelOrder
        };
        let formError = {
            isValid: true,
            errors: []
        };
        (products || []).forEach(product => {
            salesOrderProductBuilderV1Service.prepareSalesOrderItem(order, product, packagingAndHandling, discountByProductCode, request, formError.errors, productMarkupByProductCode);

            if (!isEmpty(formError.errors)) {
                this.handleOrderChange("product", product);
            }
        });
        formError = salesOrderProductBuilderV1Service.validateForm(formError, request, retailVisible);
        if (!formError.isValid) {
            if (!isEmpty(formError.errors)) {
                this.setState({ formError });
                formError.errors.forEach(error => {
                    toast.info(error);
                });
                return;
            }
        }
        this.setState({ loadingSubmitOrder: (!request.isQuote), loadingSubmitQuote: request.isQuote, order });
        salesOrderProductBuilderV1Service.saveSalesOrderKeyway(request).then(response => {
            let order = response.data;
            this.setState({ order, loadingSubmitOrder: (!request.isQuote), loadingSubmitQuote: request.isQuote, });
            toast.success("Order submitted!");
            this.props.history.push("/sales/order/submitted?" + queryString.stringify({
                ordNum: order.ordNum,
                submitStrategy: order.submitStrategy,
                isQuote: order.isQuote,
                documentType: order.documentType
            }))
        }).catch(error => {
            console.error(error);
            this.setState({ loadingSubmitOrder: false, loadingSubmitQuote: false });
            toast.error(handleErrorMessage(error));
        })
    }

    render() {
        let { order, user, formError, isOpenRetailCustomerDetail, isOpenOrderSummaryDetail, isOpenRetailPreApprovedDiscountModal, retailVisible, loading, loadingOrder, products, loadingProducts, discountByProductCode, isOpenSavedAddressModal, selectedProduct, freight } = this.state;
        return (<div>
            {user.isExternalUser ?
                <div>
                    <CustomerDashboardQuickLinks size={"sm"} />
                    <hr />
                </div> : null}
            <div>
                {
                    loadingOrder
                        ? <Spinner color={"primary"} />
                        : <div>
                            {order.id
                                ? <Row>
                                    <Col><h5>#{order.debtorInvoiceOrdNum}
                                    </h5>
                                    </Col>
                                    <Col>
                                        <div className="text-right">
                                            <h6>
                                                <small className="mr-2">Created on</small>
                                                {changeFormatOfDateString(order.createdDateString, "DD/MM/YYYY", "ddd, Do MMM YYYY")}
                                            </h6>
                                        </div>

                                    </Col>
                                </Row>
                                : null
                            }
                            {
                                retailVisible ?
                                    <RetailCustomerDetail order={order} formError={formError}
                                        handleChange={this.handleOrderChange}
                                    />
                                    : <CustomerDetails
                                        defaultAccountID={this.state.defaultAccountID}
                                        order={order}
                                        handleOrderChange={this.handleOrderChange}
                                        toggleRetailCustomerModal={this.toggleRetailCustomerModal}
                                        isExternalUser={user.isExternalUser}
                                    />
                            }

                            {
                                order.accountID &&
                                <Card className={"mt-3"}>
                                    <CardBody>
                                        <div><strong>Product</strong></div>
                                        <div>
                                            <Select
                                                isLoading={loading}
                                                isClearable={false}
                                                options={products}
                                                onChange={(selection) => this.handleOrderChange("changeProduct", selection)}
                                                value={selectedProduct}
                                            />
                                        </div>
                                        {loadingProducts ? <Spinner color={"primary"} className={"mt-2"} /> : null}
                                        <Row className={"mt-3"}>
                                            <Col xl={17} lg={17} md={16} sm={12} xs={12}>
                                                <Card>
                                                    <CardBody>
                                                        <div>
                                                            <p><strong>Delivery</strong></p>
                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                    Delivery Address
                                                                </Col>
                                                                <Col xl={7} lg={7} md={7} sm={12} xs={12}>
                                                                    <div>
                                                                        {order.contactName ? (
                                                                            <div title={"Contact Person"}>
                                                                                <i className="text-muted fa fa-user fa-fw mr-1" aria-hidden="true" />
                                                                                {order.contactName}
                                                                            </div>
                                                                        ) : null}
                                                                        {order.phone ? (
                                                                            <div title={"Phone"}>
                                                                                <a href={"tel:" + order.phone}>
                                                                                    <i className="text-muted fa fa-phone fa-fw mr-1" aria-hidden="true" />
                                                                                    {order.phone}
                                                                                </a>
                                                                            </div>
                                                                        ) : null}
                                                                        {order.email ? (
                                                                            <div title={"Email"}>
                                                                                <a href={"mailto:" + order.email}>
                                                                                    <i className="text-muted fa fa-envelope fa-fw mr-1" aria-hidden="true" />
                                                                                    {order.email}
                                                                                </a>
                                                                            </div>
                                                                        ) : null}
                                                                        {order.delCompany ? (
                                                                            <div>
                                                                                <i className="text-muted fa fa-building fa-fw mr-1" aria-hidden="true" />
                                                                                {order.delCompany}
                                                                            </div>
                                                                        ) : null}
                                                                        <div>
                                                                            <a href={"http://maps.google.com/?q=" + getDeliveryAddressString(order)} target="_blank">
                                                                                <i className="text-muted fa fa-map-marker fa-fw mr-1" aria-hidden="true" />
                                                                                {getDeliveryAddressString(order)}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                                                    <div className="text-right">
                                                                        {addressFields.map((f, i) => {
                                                                            return formError[f] ? (
                                                                                <FormText key={i} color="danger" className={"mb-1"}>
                                                                                    <i className="fa fa-exclamation-triangle mr-2" aria-hidden="true" />
                                                                                    {formError[f]}
                                                                                </FormText>
                                                                            ) : null;
                                                                        })}
                                                                        <Button color={"primary"} size={"sm"} onClick={() => this.toggleSavedAddressModal(true)}>
                                                                            <i className="fa fa-pencil mr-2" aria-hidden="true" /> Edit delivery address
                                                                        </Button>
                                                                        <hr />
                                                                        <FormGroup>
                                                                            <Label>Authority to Leave</Label>
                                                                            <div>
                                                                                <ButtonGroup>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        onClick={() => this.handleOrderChange("isAuthorityToLeave", true)}
                                                                                        color={order.isAuthorityToLeave ? "success" : "primary"}
                                                                                        outline={!order.isAuthorityToLeave}
                                                                                    >
                                                                                        Yes
                                                                                        {order.isAuthorityToLeave ? (
                                                                                            <i className="fa fa-check ml-2" aria-hidden="true" />
                                                                                        ) : null}
                                                                                    </Button>
                                                                                    <Button
                                                                                        onClick={() => this.handleOrderChange("isAuthorityToLeave", false)}
                                                                                        color={order.isAuthorityToLeave ? "primary" : "danger"}
                                                                                        size={"sm"}
                                                                                        outline={order.isAuthorityToLeave}
                                                                                    >
                                                                                        No
                                                                                        {!order.isAuthorityToLeave ? (
                                                                                            <i className="fa fa-check ml-2" aria-hidden="true" />
                                                                                        ) : null}
                                                                                    </Button>
                                                                                </ButtonGroup>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                    <div>Delivery Instructions</div>
                                                                    <Input
                                                                        onChange={(e) => this.handleOrderChange("courierInstructions", e.target.value)}
                                                                        type="text"
                                                                        name="courierInstructions"
                                                                        value={order.courierInstructions ? order.courierInstructions : ""}
                                                                        invalid={order.courierInstructions && order.courierInstructions.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS}
                                                                        placeholder="Write delivery instructions here"
                                                                    />
                                                                    {order.courierInstructions && order.courierInstructions.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS ? (
                                                                        <FormText color="danger">
                                                                            <i className="fa fa-exclamation-triangle mr-2" aria-hidden="true" />
                                                                            {"Max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS + " characters allowed!"}
                                                                        </FormText>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <hr />
                                                        <div>
                                                            <p><strong>Order Options</strong></p>
                                                            <div className="mb-2">
                                                                <div className={"mt-2"}>
                                                                    <div>Order ref.</div>
                                                                    <Input
                                                                        onChange={(e) => this.handleOrderChange("custOrdNum", e.target.value)}
                                                                        type="text"
                                                                        name="custOrdNum"
                                                                        value={order.custOrdNum}
                                                                        invalid={order.custOrdNum && order.custOrdNum.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM}
                                                                        placeholder="Write order ref. here"
                                                                    />
                                                                    {order.custOrdNum && order.custOrdNum.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM ? (
                                                                        <FormText color="danger">
                                                                            <i className="fa fa-exclamation-triangle mr-2" aria-hidden="true" />
                                                                            {"Max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM + " characters allowed!"}
                                                                        </FormText>
                                                                    ) : null}
                                                                </div>
                                                                <div className={"mt-2"}>
                                                                    <div>Notes</div>
                                                                    <Input
                                                                        style={{ minHeight: 75 }}
                                                                        onChange={(e) => this.handleOrderChange("notes", e.target.value)}
                                                                        type="textarea"
                                                                        name="notes"
                                                                        value={order.notes ? order.notes : ""}
                                                                        invalid={order.notes && order.notes.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES}
                                                                        placeholder="Write notes here"
                                                                    />
                                                                    {order.notes && order.notes.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES ? (
                                                                        <FormText color="danger">
                                                                            <i className="fa fa-exclamation-triangle mr-2" aria-hidden="true" />
                                                                            {"Max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES + " characters allowed!"}
                                                                        </FormText>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xl={15} lg={15} md={16} sm={12} xs={12}>
                                                <Card>
                                                    <CardBody>
                                                        <div>
                                                            {salesOrderProductBuilderV1Service.isVisibleOrderPricingTable(order) ? (
                                                                !retailVisible ? (
                                                                    <SalesOrderProductBuilderOrderSummaryTableDefault
                                                                        order={order}
                                                                        products={products}
                                                                        discountByProductCode={discountByProductCode}
                                                                        freight={freight}
                                                                        handleOrderChange={this.handleOrderChange}
                                                                    />
                                                                ) : (
                                                                    <SalesOrderProductBuilderOrderSummaryTableRetail
                                                                        order={order}
                                                                        products={products}
                                                                        discountByProductCode={discountByProductCode}
                                                                        freight={freight}
                                                                        toggleOrderSummaryModal={this.toggleOrderSummaryModal}
                                                                        handleOrderChange={this.handleOrderChange}
                                                                        toggleRetailPreApprovedDiscountModal={this.toggleRetailPreApprovedDiscountModal}
                                                                    />
                                                                )
                                                            ) : null}
                                                        </div>
                                                        <div className="text-center">
                                                            <Button color={"primary"} size={"md"} block onClick={this.handleContinueClick}>
                                                                <i className="" aria-hidden="true" /> Continue
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }

                            {
                                isOpenSavedAddressModal
                                    ? <DebtorAddressModal
                                        selected={{
                                            company: order.delCompany,
                                            contactName: order.contactName,
                                            phone: order.phone,
                                            email: order.email,
                                            fax: order.fax,
                                            address1: order.delAddr1,
                                            address2: order.delAddr2,
                                            address3: order.delAddr3,
                                            address4: order.delAddr4,
                                            city: order.delCity,
                                            state: order.delState,
                                            postCode: order.delPostCode,
                                            country: 'AUSTRALIA',
                                        }}
                                        isOpen={isOpenSavedAddressModal}
                                        toggleModel={this.toggleSavedAddressModal}
                                        handleChange={(selectedAddress) => this.handleOrderChange("address", selectedAddress)}
                                        accountID={order.accountID}
                                    />
                                    : null
                            }


                        </div>
                }
            </div>
            {isOpenRetailCustomerDetail ?
                <SalesOrderProductBuilderCustomerRetailDetail
                    isOpen={isOpenRetailCustomerDetail}
                    toggle={this.toggleRetailCustomerModal}
                    order={order}
                    handleChange={this.handleOrderChange}
                />
                : null}
            {isOpenOrderSummaryDetail ?
                <SalesOrderProductBuilderOrderSummaryDetail
                    order={order}
                    products={products}
                    discountByProductCode={discountByProductCode}
                    isOpen={isOpenOrderSummaryDetail}
                    toggle={this.toggleOrderSummaryModal} />
                : null
            }
            {isOpenRetailPreApprovedDiscountModal ?
                <SalesOrderProductBuilderAdditionalPreApprovedRetailDiscount
                    order={order}
                    isOpen={isOpenRetailPreApprovedDiscountModal}
                    toggle={this.toggleRetailPreApprovedDiscountModal}
                    handleOrderChange={this.handleOrderChange}
                />
                : null
            }
            <ToastContainer />
        </div>)
    }
}