import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';

 class CatalogueService {

     static Instance(){
         return new CatalogueService();
     }

     list(type) {
         let url = 'api/catalogue/list';
         switch (type) {
             case "productBrochure":
                 url = 'api/catalogue/list';
                 break;
             case "productOrderForms":
                 url = 'api/catalogue/list/order-forms'
                 break;
         }
        return axios.get(url);
     }

     downloadAttachment(name,options,type) {
         if (type === 'productBrochure') {
            return this.downloadCatalogue(name, options);
         }
         else if (type === 'productOrderForms') {
             return this.downloadCatalogueOrderForm(name, options);
         }
     }

     downloadCatalogue(name,options) {
         if (options && options.onDownloadProgress) {
             return axiosFileDownloader.get('api/file/Generate/Catalogue?FileName=' + name, options);
         } else {
             return axiosFileDownloader.get('api/file/Generate/Catalogue?FileName=' + name);
         }
     }

     downloadCatalogueOrderForm(name, options) {
         if (options && options.onDownloadProgress) {
             return axiosFileDownloader.get('api/file/Generate/CatalogueOrderFormFile?FileName=' + name, options);
         } else {
             return axiosFileDownloader.get('api/file/Generate/CatalogueOrderFormFile?FileName=' + name);
         }
     }


    delete(id) {
        return axios.delete('api/catalogue/delete?attachmentID=' + id);
    }

    saveCatalogueBasicInfo(req) {
        return axios.post('api/catalogue/basic-info/save', req);
    }

 }
export default CatalogueService.Instance();