import React, { Component } from "react";
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import NumberFormat from "react-number-format";
import { changeFormatOfDateString } from '../../services/CommonService';

export default class OOSDetailsModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { isOpen, toggle, ordNum, oosDetails } = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    OOS Details for Order No.&nbsp;{ordNum}
                </ModalHeader>
                <ModalBody>
                    {oosDetails.length ?
                        <Table size={"sm"} striped responsive bordered>
                            <thead>
                                <tr>
                                    <th className="text-center align-middle">ProdCode</th>
                                    <th className="text-center align-middle">Description</th>
                                    <th className="text-center align-middle">PO No.</th>
                                    <th className="text-center align-middle">ETA</th>
                                    <th className="text-center align-middle">Qty Required</th>
                                    <th className="text-center align-middle">QOH</th>
                                    <th className="text-center align-middle">Allocated</th>
                                    <th className="text-center align-middle">Free</th>
                                    <th className="text-center align-middle">Can Make</th>
                                </tr>
                            </thead>
                            <tbody>
                                {oosDetails.map((data, index) =>
                                    <tr key={index}>
                                        <td className="text-center align-middle">
                                            {data.prodCode ?
                                                <a href={"/inventory/stock/enquiry?prodCode=" + data.prodCode}
                                                    target={"_blank"}
                                                    className={"btn btn-sm btn-primary"}
                                                    title={"Click here to see stock enquiry"}>
                                                    {data.prodCode}
                                                </a>
                                                : null}
                                        </td>
                                        <td className="align-middle">{data.description}</td>
                                        <td className="text-center align-middle">
                                            {data.purchaseOrderOrdNum ?
                                                <a href={"/purchase/order/enquiry?ordNum=" + data.purchaseOrderOrdNum}
                                                    target="_blank"
                                                    className={"btn btn-sm btn-" + (data.statusID === 4 ? "success" : "primary")}
                                                    title="Click here to open this PO">
                                                    {data.purchaseOrderOrdNum}
                                                </a>
                                                : null}
                                        </td>
                                        <td className="text-center align-middle">{changeFormatOfDateString(data.eta, "YYYY-MM-DD", "DD MMM YYYY")}</td>
                                        <td className="text-center align-middle">
                                            <NumberFormat
                                                value={data.qtyRequired}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                fixedDecimalScale={false} />
                                        </td>
                                        <td className="text-center align-middle">
                                            <NumberFormat
                                                value={data.qoh}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                fixedDecimalScale={false} />
                                        </td>
                                        <td className="text-center align-middle">
                                            <NumberFormat
                                                value={data.allocated}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                fixedDecimalScale={false} />
                                        </td>
                                        <td className="text-center align-middle">
                                            <NumberFormat
                                                value={data.free}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                fixedDecimalScale={false} />
                                        </td>
                                        <td className="text-center align-middle">
                                            <Badge style={{ fontSize: "100%" }} color={data.isCanMake ? "success" : "danger"}>
                                                <i className={"fa fa-" + (data.isCanMake ? "check" : "times")} />
                                            </Badge>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        :
                        <h5 className={"text-center text-danger"}>No detail is available for this order.</h5>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            Close
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}