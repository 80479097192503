import React, { Component } from 'react';
import NumberFormat from "react-number-format";
import { concatenateStrings } from "../../../../../../services/CommonService";

export let rollerBlindLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "room",
        label: "Location",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "qty",
        label: "Qty",
        colSpan: 1,
        minWidth: 20,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data[this.key];
        }
    },
    {
        key: "width",
        label: "Width",
        colSpan: 1,
        minWidth: 20,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "drop",
        label: "Drop",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "operation",
        label: "Operations",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-left",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "fabric",
        label: "Fabric",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "layout",
        label: "Layout",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "layoutDiagramUrl",
        label: "Diagram",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            if (data.productLabel[this.key]) {
                return <img src={data.productLabel[this.key]}
                    alt={""}
                    className="img-fluid rounded d-print-inline"
                    style={{ maxHeight: 100 }} />
            }
            return data.productLabel[this.key];
        }
    },
    {
        key: "blindPosition",
        label: "Blind Position",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "rollDirection",
        label: "Roll Direction",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "controlSide",
        label: "Control Side",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "bracketColor",
        label: "Bracket",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "clutch",
        label: "Clutch",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "chain",
        label: "Chain",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "operation",
        label: "Motor Options",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        valueClassName: "align-middle text-left",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            let descriptions = [];
            for (let key in data.parts) {
                if (data.parts[key].attribute === "Accessories") {
                    descriptions.push(data.parts[key].label)
                }
            }
            descriptions = concatenateStrings(", ", descriptions);
            return descriptions;
        }
    },
    {
        key: "bottomRail",
        label: "Bottom Rail",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "extra",
        label: "Extras",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel.accessoryBoxColour;
        }
    }
];