import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TextAream
} from 'reactstrap';
import {Link as Link} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import SearchUser from '../../components/search/SearchUser';
import CustomerService from "../../services/CustomerService";

import {handleErrorMessage} from '../../services/CommonService';
import TaskService from "../../services/crm/TaskService";


import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import 'react-toastify/dist/ReactToastify.css';
import CommonNote from "../../components/CommonNote";
import {EventBus} from "../../components/events/event"

export default class TaskManagePage extends Component {

    constructor(props) {
        super(props);


        this.state = {


            workingTask: {
                id: 0,
                description: "",
                followUpType: "PHONE",
                priority: 0,
                scheduledOn: new Date(),
                accountID: "",
                assignedUser: "",
                ownedBy: "",
                isClosed: false
            },
            debtor: {},
            user: {},
            showCancelButton: false,
            loaders: {
                getWorkingTask: false,
                saveWorkingTask: false
            }
        }
        ;

        this.customerService = new CustomerService();
        this.taskService = new TaskService();
        this.createNewTask = this.createNewTask.bind(this);
        this.cancelNewTask = this.cancelNewTask.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
        this.handleAssignedUser = this.handleAssignedUser.bind(this);
        this.handleOwnerUser = this.handleOwnerUser.bind(this);
        this.handleCallbackOn = this.handleCallbackOn.bind(this);
        this.toggleCloseTask = this.toggleCloseTask.bind(this);
        this.getTask = this.getTask.bind(this);
        this.getWorkingTask = this.getWorkingTask.bind(this);
        this.submitTaskForm = this.submitTaskForm.bind(this);

    }

    componentDidMount() {
        const {match: {params}} = this.props;


        this.getWorkingTask(params.id);
    }

    getWorkingTask(id) {
        if (id === "0" || id === 0) {

            this.createNewTask();
        } else {
            this.getTask(id);
        }
    }

    getTask(id) {
        let {loaders} = this.state;
        loaders.getWorkingTask = true;

        this.setState({loaders});
        this.taskService.getTask(id).then(response => {
            let workingTask = response.data;
            loaders.getWorkingTask = false;
            workingTask.scheduledOn = moment(workingTask.scheduledOn).toDate();
            this.setState({workingTask, loaders, showCancelButton: true});
        }).catch(error => {
            alert(handleErrorMessage(error));
                loaders.getWorkingTask = false;
                this.setState({loaders});
            }
        );
    }


    createNewTask() {
        this.setState({
            workingTask: {
                id: 0,
                description: "",
                followUpType: "PHONE",
                priority: 0,
                scheduledOn: new Date(),
                accountID: "",
                assignedUser: "",
                ownedBy: "",
                isClosed: false
            },
            showCancelButton: true
        });
    }

    cancelNewTask() {
        this.setState({
            workingTask: {
                id: 0,
                description: "",
                followUpType: "PHONE",
                priority: 0,
                scheduledOn: new Date(),
                accountID: "",
                assignedUser: "",
                ownedBy: "",
                isClosed: false
            },
            showCancelButton: false
        });
    }

    handleDebtorAccountDetails(accountId) {
        this.customerService.searchCustomer(accountId).then(response => {
            if (response.data && response.data.accountID) {
                let workingTask = this.state.workingTask;
                workingTask.accountID = response.data.accountID;
                let debtor = response.data;
                if (debtor) {
                    debtor.debtorAddress = (debtor.address1 ? debtor.address1 + ", " : "")
                        + (debtor.address2 ? debtor.address2 + ", " : "")
                        + (debtor.address3 ? debtor.address3 + ", " : "")
                        + (debtor.address4 ? debtor.address4 + ", " : "")
                        + (debtor.city ? debtor.city + ", " : "")
                        + (debtor.state ? debtor.state + ", " : "")
                        + (debtor.postCode ? debtor.postCode : "");
                }
                this.setState({workingTask, debtor});
            }
        });
    }

    handleAssignedUser(username) {
        let workingTask = this.state.workingTask;
        workingTask.assignedUser = username;
        this.setState({workingTask});
    }

    handleOwnerUser(username) {
        let workingTask = this.state.workingTask;
        workingTask.ownedBy = username;
        this.setState({workingTask});
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {workingTask} = this.state;
        workingTask[name] = value;
        this.setState({workingTask});
    }

    handleCallbackOn(changedDate) {
        let workingTask = this.state.workingTask;
        workingTask.scheduledOn = changedDate;
        this.setState({workingTask: workingTask});
    }

    toggleCloseTask() {
        let workingTask = this.state.workingTask;
        workingTask.isClosed = !workingTask.isClosed;
        this.setState({workingTask});
    }

    submitTaskForm() {

        let {workingTask} = this.state;
        if (workingTask.id) {
            this.updateTask(workingTask);
        } else {
            this.saveTask(workingTask);
        }
    }


    saveTask(workingTask) {
        let {loaders} = this.state;
        loaders.saveWorkingTask = true;
        this.setState({loaders});

        this.taskService.saveTask(workingTask).then(response => {
            loaders.saveWorkingTask = false;
            let workingTask = response.data;
            loaders.getWorkingTask = false;
            workingTask.scheduledOn = moment(workingTask.scheduledOn).toDate();
            this.setState({workingTask, loaders, showCancelButton: true});
            toast.success("Task saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            EventBus.publish('update-notification', {});
        }).catch(error => {
            alert(handleErrorMessage(error));
            loaders.saveWorkingTask = false;
            this.setState({loaders});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    updateTask(workingTask) {
        let {loaders} = this.state;
        loaders.saveWorkingTask = true;
        this.setState({loaders});

        this.taskService.updateTask(workingTask).then(response => {
            loaders.saveWorkingTask = false;
            let workingTask = response.data;
            loaders.getWorkingTask = false;
            workingTask.scheduledOn = moment(workingTask.scheduledOn).toDate();
            this.setState({workingTask, loaders, showCancelButton: true});
            toast.success("Task updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            EventBus.publish('update-notification', {});
        }).catch(error => {
            alert(handleErrorMessage(error));
            loaders.saveWorkingTask = false;
            this.setState({loaders});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    render() {

        let {showCancelButton, workingTask, debtor, loaders} = this.state;
        return (<div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/crm/tasks">Tasks</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{workingTask.id > 0 ? "Manage" : "New"}</BreadcrumbItem>
                </Breadcrumb>

                <Button color='primary'
                        hidden={showCancelButton}
                        onClick={this.createNewTask}>
                    <i className="fa fa-plus"/>&nbsp;
                    New Task
                </Button>

                <div>
                    <Card hidden={!showCancelButton}>
                        <CardHeader>{workingTask.id > 0 ? "Manage " : "New "} Task
                        </CardHeader>
                        <CardBody>
                            <Form>

                                <Row form>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Select customer</Label>

                                            <SearchDebtorAcccount
                                                handleAccountChange={this.handleDebtorAccountDetails}
                                                defaultAccountID={workingTask.accountID}
                                                selectedAccountID={workingTask.accountID}
                                                includeChildren={true} 
                                                excludeClosedandInactive={false} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="examplePassword">Title</Label>

                                            <Input className="form-control"
                                                   onChange={this.handleChange}
                                                   type="text"
                                                   name="description"
                                                   value={workingTask.description}
                                                   placeholder="Describe this task">
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}
                                         hidden={(!debtor.phone) && (!debtor.email) && (!debtor.debtorAddress)}>
                                        <FormGroup className={"text-left"}>
                                            <Label for={"Contact"}>Contact Info.</Label>
                                            <div>
                                                <p className={"lead"} hidden={!debtor.phone}>
                                                    <a
                                                        href={"tel:" + debtor.phone}>
                                                        <i className="text-muted  fa fa-phone"
                                                           aria-hidden="true"/>
                                                        &nbsp;{debtor.phone}
                                                    </a>
                                                </p>
                                                <p className={"lead"} hidden={!debtor.email}>
                                                    <a href={"mailto:" + debtor.email}>
                                                        <i className="text-muted fa fa-envelope-o"
                                                           aria-hidden="true"/>
                                                        &nbsp;{debtor.email}
                                                    </a>
                                                </p><p className={"lead"} hidden={!debtor.debtorAddress}>
                                                <a
                                                    href={"http://maps.google.com/?q=" + debtor.debtorAddress}
                                                    target="_blank">
                                                    <i className="text-muted fa fa-map-marker"
                                                       aria-hidden="true"/>
                                                    &nbsp;{debtor.debtorAddress}
                                                </a>
                                            </p>
                                            </div>
                                        </FormGroup>


                                    </Col>
                                </Row>
                                <Row form>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for={"duedate"}>Due on</Label>
                                            <div className="cal-datepicker">
                                                <div className="datepicker-here" data-language='en'>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={workingTask.scheduledOn}
                                                        onChange={this.handleCallbackOn}
                                                        dateFormat="dd/MM/yyyy HH:mm:ss"
                                                        timeFormat="HH:mm"
                                                        showTimeSelect
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        placeholderText="select date"
                                                        withPortal
                                                    />
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleZip">Follow-up type</Label>

                                            <Input className="form-control"
                                                   onChange={this.handleChange}
                                                   type="select"
                                                   name="followUpType"
                                                   value={workingTask.followUpType}
                                                   placeholder="write State here...">
                                                <option value={"PHONE"}>PHONE</option>
                                                <option value={"EMAIL"}>EMAIL</option>
                                                <option value={"VISIT"}>VISIT</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleState">Priority</Label>

                                            <Input
                                                className="form-control"
                                                onChange={this.handleChange}
                                                type="select"
                                                name="priority"

                                                value={workingTask.priority}
                                                placeholder="write state here...">
                                                <option value={0}>Low</option>
                                                <option value={1}>Medium
                                                </option>
                                                <option value={2}>High</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row form>

                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleCity"> Assigned to
                                            </Label>
                                            <SearchUser
                                                role={""}
                                                onChange={this.handleAssignedUser}
                                                defaultValue={workingTask.assignedUser}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleCity"> Owned by</Label>
                                            <SearchUser
                                                onChange={this.handleOwnerUser}
                                                defaultValue={workingTask.ownedBy}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleCity"> Status</Label>
                                            <div>
                                                <ButtonGroup className={"btn-group-justified"}>
                                                    <Button
                                                        color={"primary"}
                                                        outline={workingTask.isClosed}
                                                        onClick={this.toggleCloseTask}>
                                                        <i className="fa fa-exclamation-circle"
                                                           aria-hidden="true"/>&nbsp;
                                                        Pending
                                                    </Button>
                                                    <Button
                                                        color={"success"}
                                                        outline={!workingTask.isClosed}
                                                        onClick={this.toggleCloseTask}><i
                                                        className="fa fa-check" aria-hidden="true"/>&nbsp;
                                                        Completed
                                                    </Button>

                                                </ButtonGroup>
                                            </div>

                                        </FormGroup>
                                    </Col>

                                </Row>
                                <div className={"text-right"}>

                                    <Button color='primary'
                                            disabled={loaders.saveWorkingTask}
                                            onClick={this.submitTaskForm}>
                                        {loaders.saveWorkingTask ?
                                            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                            <i className="fa fa-floppy-o"/>} &nbsp;
                                        {loaders.saveWorkingTask ? "Saving " : "Save "}this task
                                    </Button>

                                    &nbsp;
                                    <Link className={"btn btn-secondary"} onClick={this.cancelNewTask}
                                          to="/crm/tasks"><i
                                        className="fa fa-times"/>&nbsp;Cancel</Link>
                                </div>

                            </Form>


                        </CardBody>
                    </Card>

                    {workingTask.id > 0 ? <div>
                        <CommonNote commonNotes={workingTask.notes}
                                    entityID={workingTask.id}
                                    entityName={"task"}
                                    refresh={this.getTask}/>
                    </div> : null}


                </div>
                <ToastContainer/>
            </div>
        );
    }

}
