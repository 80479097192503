import React, { Component } from "react";
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import { toast } from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';
import KeywayStockService from '../../services/KeywayStockService';
import CreatableSelect from 'react-select/creatable';

export default class ManageStockUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStockAttribute: this.props.selectedStockAttribute,
            formErrors: {},
            saving: false,
        }
    }

    handleChange(key, change) {
        let { selectedStockAttribute } = this.state;
        selectedStockAttribute[key] = change;
        this.setState({ selectedStockAttribute });
    }

    validateForm(item) {
        let formErrors = {}, validValue = true;
        if (!item.type) {
            formErrors.type = "Please enter type";
            validValue = false;
        }
        if (!item.value) {
            formErrors.value = "Please enter value";
            validValue = false;
        }
        return ({
            validValue: validValue,
            formErrors: formErrors
        })
    }

    saveStockAttribute() {
        let { selectedStockAttribute } = this.state;
        let validateResult = this.validateForm(selectedStockAttribute);
        if (!validateResult.validValue) {
            this.setState({ formErrors: validateResult.formErrors });
            return;
        }
        this.setState({ saving: true, formErrors: {} })
        KeywayStockService.saveStockAttribute(selectedStockAttribute).then(response => {
            if (response.status === 200 || response.status === "200" || response.status === 204 || response.status === "204") {
                this.setState({ saving: false })
                toast.success("Saved Successfully", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.refreshAgain();
            }
        }).catch(error => {
            this.setState({ saving: false })
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        })
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { saving, formErrors, selectedStockAttribute } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {selectedStockAttribute && selectedStockAttribute.id ? 'Edit' : 'Add'} Stock Attribute
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Type*</Label>
                                <Input type="text" name="type"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedStockAttribute.type}
                                    placeholder="Enter type here" />
                                <FormText color="danger">{formErrors.type}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="fullName">Value*</Label>
                                <Input type="text" name="value"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedStockAttribute.value}
                                    placeholder="Enter value here" />
                                <FormText color="danger">{formErrors.value}</FormText>
                            </FormGroup>
                        </Col> 
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.saveStockAttribute()} disabled={saving}>
                            {saving
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            Save</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button>

                    </div>
                </ModalFooter>

            </Modal>
            );
    }
}