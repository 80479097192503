import React from 'react';
import {Component} from 'react';
import {
    Card,
    Row,
    Col,
    CardBody,
    CardFooter
} from 'reactstrap';
import {Link} from "react-router-dom";

const officeNo = '07 3299 3788';
export default class MakePayment extends Component {

    render() {
        return (

            <div>

                <div className="text-center">
                    <div>
                        <h5>Payments can be made via the following methods:</h5>
                    </div>
                    <Row className={"mt-4"}>
                        <Col xl={{size: 5, offset: 1}} lg={{size: 5, offset: 1}} md={{size: 6, offset: 0}}
                             sm={{size: 12}}>
                            <div className="text-center">
                                <Card>
                                    <CardBody>
                                        <h5><u>Bank Transfer</u></h5>
                                        <div className="mt-4 mb-4">
                                            <Row>
                                                <Col>
                                                    <div className="text-right"><strong>BSB:</strong></div>
                                                </Col>
                                                <Col>
                                                    <div className="text-left">084-209</div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="text-right"><strong>Account Number:</strong></div>
                                                </Col>
                                                <Col>
                                                    <div className="text-left">36&nbsp;923&nbsp;0914</div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </CardBody>
                                    <CardFooter>
                                        <div>
                                            <p><em>Please ensure your transfer reference includes your account#.</em>
                                            </p>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </div>
                        </Col>
                        <Col xl={{size: 5}} lg={{size: 5}} md={{size: 6}} sm={{size: 12}}>
                            <div className="text-center">
                                <Card>
                                    <CardBody>
                                        <h5><u>Credit Card</u></h5>
                                        <div className="mt-4 mb-4">
                                            <Row>
                                                <Col>
                                                    <div className="text-center">Please call our office on <strong>
                                                        <a
                                                            href={"tel:" + officeNo}>
                                                            <i className="text-muted  fa fa-phone"
                                                               aria-hidden="true"/>
                                                            &nbsp;{officeNo}
                                                        </a>
                                                    </strong></div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="text-center">to make payment.</div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <div>
                                            <p><em>Please note only <img
                                                title={"VISA and MASTERCARD"}
                                                src={"/img/logo/visa-mastercard-logo.png"}
                                                style={{maxWidth: 100, maxHeight: 20}}/>
                                                accepted.</em>
                                            </p>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>)
    }
}