import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Spinner,
    Button
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import creditorService from '../../services/purchase/CreditorService';
import {findIndex, handleErrorMessage} from '../../services/CommonService';
import SearchWINOCreditor from '../../components/search/SearchCreditorAcccount';

export default class CreditorPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creditorData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        accountID: "",
                        company: "",
                        accountName: "",
                        bankName: "",
                        bankSuburb: "",

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            supplierIds: [],
            loading: true,
            searchText: '',

        };

        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.getCreditors = this.getCreditors.bind(this);
        this.getSuppliers = this.getSuppliers.bind(this);
    }

    componentDidMount() {
        this.getCreditors(this.state.creditorData);
        this.getSuppliers();
    }

    getCreditors(creditorData) {
        this.setState({loading: true});
        let req = {...this.state.creditorData.request};
        creditorService.listCreditor(req).then(response => {
            creditorData.response = response.data;
            this.setState({creditorData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getSuppliers() {
        this.setState({loading: true});
        creditorService.getSuppliers().then(response => {
            this.setState({supplierIds: response.data, loading: false})
        }).catch(error => {
            this.setState({loading: false});
            console.log(error);
        })
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.accountID.toLowerCase().includes(searchText);

            if (!flag && item.company) {
                flag = item.company.toLowerCase().includes(searchText)
            }

            if (!flag && item.accountName) {
                flag = item.accountName.toLowerCase().includes(searchText)
            }

            if (!flag && item.bankDetail.bankName) {
                flag = item.bankDetail.bankName.toLowerCase().includes(searchText)
            }

            if (!flag && item.bankDetail.bankSuburb) {
                flag = item.bankDetail.bankSuburb.toLowerCase().includes(searchText)
            }


        }
        return flag;
    }

    handleAccountChange(supplier) {
        let {creditorData} = this.state;
        creditorData.request.filterRequest.accountID = supplier.accountID === null ? '' : supplier.accountID;
        creditorData.request.pageRequest.currentPage = 1;
        this.getCreditors(creditorData);
        this.setState({creditorData});
    }

    handleChange(change, key) {
        console.log(change, key);
        let {creditorData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (creditorData.request.sortRequest.key === change) {
                    creditorData.request.sortRequest.direction = !creditorData.request.sortRequest.direction;
                } else {
                    creditorData.request.sortRequest.key = change;
                    creditorData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getCreditors(creditorData);
                }
                break;
            case "pageSize":
                creditorData.request.pageRequest[key] = parseInt(change);
                creditorData.request.pageRequest.currentPage = 1;
                this.getCreditors(creditorData);
                break;
            case "currentPage":
                creditorData.request.pageRequest[key] = change;
                this.getCreditors(creditorData);
                break;


            default:
                creditorData.request.filterRequest[key] = change;
                creditorData.request.pageRequest.currentPage = 1;
                this.getCreditors(creditorData);
                this.setState({creditorData});
        }

    }

    deleteCreditor(creditor) {
        let {creditorData} = this.state;
        let {records} = creditorData.response;
        let index = findIndex(records, 'id', creditor.id);
        records[index].isDeleting = true;
        this.setState({creditorData});
        creditorService.deleteCreditor(creditor.id).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("Supplier deleted succesfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                records[index].isDeleting = false;
                this.setState({creditorData});
                this.getCreditors(creditorData);
            }
        }).catch(error => {
            records[index].isDeleting = false;
            this.setState({creditorData});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCreditorsHeading({filterRequest}) {
        let creditorsHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "accountID",
                label: "Account #",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchWINOCreditor
                        handleAccountChange={(selectedSupplier) => this.handleChange(selectedSupplier ? selectedSupplier.accountID : '', 'accountID')}
                        selectedAccountID={filterRequest.accountID}
                        defaultAccountID={filterRequest.accountID}
                        includeChildren={true}
                        excludeClosedandInactive={false}/>
                </div>

            },
            //{
            //    key: "company",
            //    label: "Company",
            //    type: "text",
            //    colSpan: 1,
            //    minWidth: 200,
            //    sorterApplicable: true,
            //    valueClassName: "",
            //    labelClassName: "hoverableItem align-middle",
            //    searchNode: <div>
            //        <Input type={"text"}
            //               placeholder={"Search..."}
            //               name={"company"}
            //               value={filterRequest.company || ''}
            //               onChange={(e) => this.handleChange(e.target.value, "company")}/>
            //    </div>

            //},

            {
                key: "accountName",
                label: "Account Name",
                type: "text",
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"accountName"}
                           value={filterRequest.accountName || ''}
                           onChange={(e) => this.handleChange(e.target.value, "accountName")}/>
                </div>
            },
            {
                key: "bankName",
                label: "Bank Name",
                type: "text",
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"bankName"}
                           value={filterRequest.bankName || ''}
                           onChange={(e) => this.handleChange(e.target.value, "bankName")}/>
                </div>
            },
            {
                key: "bankSuburb",
                label: "Bank Suburb",
                type: "text",
                colSpan: 1,
                minWidth: 0,
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"bankSuburb"}
                           value={filterRequest.bankSuburb || ''}
                           onChange={(e) => this.handleChange(e.target.value, "bankSuburb")}/>
                </div>
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "200px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                                                               to={{
                                                                   pathname: '/inventory/keyway/creditors/manage/one',
                                                                   state: {creditorID: ""}
                                                               }}><i className="fa fa-plus"/>&nbsp;
                    Add </Link></div>

            }];

        return creditorsHeading;
    }


    render() {

        let {creditorData, loading, searchText, supplierIds} = this.state;
        let {pageRequest, sortRequest} = creditorData.request;
        let creditorsHeading = this.getCreditorsHeading(creditorData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Suppliers</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Suppliers</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {creditorData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                   aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(creditorsHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(creditorsHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(creditorData.response.records || []).filter((creditor) => this.searchFunction(creditor, searchText)).map((creditor, index) =>

                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td className="text-left">
                                            <Link className="btn btn-primary btn-sm"
                                                  to={
                                                      "/creditor/enquiry?" + queryString.stringify({accountID: creditor.accountID})}>
                                                {creditor.accountID}
                                            </Link>
                                        </td>
                                        {/*<td>{creditor.company}</td>*/}
                                        <td>{creditor.bankDetail.accountName}</td>
                                        <td>{creditor.bankDetail.bankName}</td>
                                        <td>{creditor.bankDetail.bankSuburb}</td>
                                        <td className="text-center">
                                            <Button className="btn btn-sm btn-danger"
                                                    title="Click here to make creditor inactive"
                                                    onClick={this.deleteCreditor.bind(this, creditor)}
                                                    disabled={creditor.isDeleting}>
                                                {creditor.isDeleting ?
                                                    <Spinner size="sm"
                                                             className={"mr-2"}
                                                             style={{color: "white"}}/> :
                                                    <i className="fa fa-trash mr-2" aria-hidden="true"/>} Delete
                                            </Button>&nbsp;
                                            {supplierIds.includes(creditor.id) ?
                                                <Link className="btn btn-sm btn-primary"
                                                      to={
                                                          "/keyway-inventory/stock-supplier/supplier?" + queryString.stringify({supplierID: creditor.id})}
                                                >
                                                    <i className="fa fa-pencil mr-2"
                                                       aria-hidden="true"/>
                                                    Supplies</Link> : null}

                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={creditorData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}
