import React, {Component} from 'react';
import {Nav, NavItem, NavLink, Spinner, TabContent, TabPane} from 'reactstrap';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import hospitalRangePartUtil from "./HospitalRangePartUtil";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM, PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING,
    PRODUCT_BUILDER_HOSPITAL_RANGE_PART
} from "../../../../../../store/AppConstants";
import HospitalRangeCustom from "./HospitalRangeCustom";
import {
    concatenateStrings, findIndex, getDistinct, getDistinctValues, getSorted,
    groupBy
} from "../../../../../../services/CommonService";
import HospitalRangePart from "./HospitalRangePart";
import classnames from 'classnames';
import {v4 as uuidv4} from 'uuid';
import customUtil from "../custom/CustomUtil";
import {PRODUCT_BUILDER_CUSTOM_BUILD} from "../../../../../../store/AppConstants";

export default class HospitalRange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1"
        };
        this.init = this.init.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.init();
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab});
        }
    }


    init() {
        let {product} = this.props;
        if (!product.init) {
            product = hospitalRangePartUtil.prepareConfiguration(product);
            product = this.updateItemConfigurationOptions("product_part_part_dropdown", product, -1, null, false);
            product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
            product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);

            product.init = true;
            this.props.handleChange("product", product);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                item.newItem = true;//new item
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, context, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                break;
            case "delete":
                item = product.items[itemIndex];
                product.items = product.items.filter(i => i.customID !== item.customID && i.parentCustomID !== item.customID);

                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si.salesOrderItemHospitalRangePart, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                    product = hospitalRangePartUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = "";
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].lengthABC.selected.value;
                    context.salesOrderItemCustom.drop = 0;
                    context.salesOrderItemCustom.prodCode = "";
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if (stocksByProdCode) {
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                        }
                        if (stock) {
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                        if (s.isParentItem) {
                            context.salesOrderItemCustom.prodCode = s.prodCode;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = hospitalRangePartUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }


    updateConfiguration(product, itemIndex, context, order) {
        let part, optionIndex;
        product.items[itemIndex].customID = context.customID ? context.customID : uuidv4();
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].itemType = context.itemType;
        product.items[itemIndex].prodCode = context ? context.prodCode : "";
        product.items[itemIndex].parentCustomID = context.parentCustomID ? context.parentCustomID : "";

        product.items[itemIndex].formError = {
            prodCode: {isValid: true, message: ""}
        };
        product.items[itemIndex].quantity = {
            selected: {value: context.quantity ? context.quantity : 1},
            isSelectable: true,
            min: 0,
            max: 10000,
            formError: {isValid: true, message: ""}
        };

        part = (context.prodCode && product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_PART)
            ? product.part.configuration.part.options.find(o => o.prodCode === context.prodCode)
            : null;
        product.items[itemIndex].description = context.description ? context.description : (part ? part.description : "");
        product.items[itemIndex].colour = context.colour ? context.colour : (part ? part.colour : "");
        product.items[itemIndex].partType = context.partType ? context.partType : (part ? part.partType : "");
        product.items[itemIndex].length = context.length ? context.length : (part ? part.length : 0);
        product.items[itemIndex].eachUnitName = context.eachUnitName ? context.eachUnitName : (part ? part.eachUnitName : "");
        product.items[itemIndex].dimensionUnitName = context.dimensionUnitName ? context.dimensionUnitName : (part ? part.dimensionUnitName : "");
        product.items[itemIndex].sellUnitName = context.sellUnitName ? context.sellUnitName : (part ? part.sellUnitName : "");
        product.items[itemIndex].sellUnitQty = context.sellUnitQty ? context.sellUnitQty : (part ? part.sellUnitQty : 0);
        product.items[itemIndex].sellQtyPrice = context.sellQtyPrice ? context.sellQtyPrice : (part ? part.sellQtyPrice : 0);
        product.items[itemIndex].unitPrice = context.sellQtyPrice ? context.sellQtyPrice : (part ? part.sellQtyPrice : 0);
        product.items[itemIndex].thumbnailImageAttachmentID = context.thumbnailImageAttachmentID ? context.thumbnailImageAttachmentID : (part ? part.thumbnailImageAttachmentID : "");
        product.items[itemIndex].highQualityImageAttachmentID = context.highQualityImageAttachmentID ? context.highQualityImageAttachmentID : (part ? part.highQualityImageAttachmentID : "");


        //shape
        product.items[itemIndex].configuration.shape = {
            options: product.custom.configuration.shape.options,
            finalOptions: product.custom.configuration.shape.options,
            selected: {
                value: {}
            },
            isSelectable: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM
        };
        if (product.items[itemIndex].configuration.shape.isSelectable) {
            (product.items[itemIndex].configuration.shape.finalOptions || []).forEach((option, oIndex) => {
                option.value = option.optionKey;
                option.label = <div className="text-center" key={oIndex} style={{maxHeight: 150}}>
                    {option.description
                        ? <img className="img-fluid rounded d-print-inline mr-2" src={option.description}
                               style={{maxHeight: 125}}/>
                        : null
                    }
                    <div>{option.optionLabel}</div>
                </div>;
            });
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.shape.finalOptions, 'optionKey', context.shape);
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product.items[itemIndex].configuration.shape.selected.value = product.items[itemIndex].configuration.shape.finalOptions[optionIndex];
        }


        product.items[itemIndex].lengthA = {
            selected: {value: hospitalRangePartUtil.getDefaultValue("lengthA", product, itemIndex, context)},
            isSelectable: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
            min: 0,
            max: 0
        };
        product.items[itemIndex].lengthB = {
            selected: {value: hospitalRangePartUtil.getDefaultValue("lengthB", product, itemIndex, context)},
            isSelectable: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
            min: 0,
            max: 0
        };
        product.items[itemIndex].lengthC = {
            selected: {value: hospitalRangePartUtil.getDefaultValue("lengthC", product, itemIndex, context)},
            isSelectable: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
            min: 0,
            max: 0
        };
        product.items[itemIndex].lengthABC = {
            selected: {value: 0},
            min: 0,
            max: 0,
            formError: {
                isValid: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
                message: ""
            }
        };
        product.items[itemIndex].angle = {
            selected: {value: hospitalRangePartUtil.getDefaultValue("angle", product, itemIndex, context)},
            isSelectable: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
            min: 40,
            max: 160,
            formError: {isValid: true, message: ""}
        };

        product.items[itemIndex].angle2 = {
            selected: {value: hospitalRangePartUtil.getDefaultValue("angle2", product, itemIndex, context)},
            isSelectable: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM && product.items[itemIndex].configuration.shape.selected.value.optionKey === "U Shape",
            min: 40,
            max: 160,
            formError: {isValid: true, message: ""}
        };

        product.items[itemIndex].location = {
            selected: {value: hospitalRangePartUtil.getDefaultValue("location", product, itemIndex, context)},
            isSelectable: product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM && product.items[itemIndex].configuration.shape.selected.value.optionKey === "U Shape",
            formError: {isValid: true, message: ""}
        };

        //colour
        product.items[itemIndex].configuration.colour = {
            options: [],
            finalOptions: [],
            selected: {
                value: {}
            }
        };
        if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
            product.items[itemIndex].configuration.colour.options = product.custom.configuration.colour.dropdown.finalOptions;
            product.items[itemIndex].configuration.colour.finalOptions = product.items[itemIndex].configuration.colour.options;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.colour.finalOptions, 'value', context.colour);
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product.items[itemIndex].configuration.colour.selected.value = product.items[itemIndex].configuration.colour.finalOptions[optionIndex];

        }

        //part
        product.items[itemIndex].configuration.partRange = {
            options: [],
            finalOptions: [],
            selected: {
                value: []
            }
        };
        if (product.items[itemIndex].itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM) {
            product.items[itemIndex].configuration.partRange.options = product.custom.configuration.partRange.options;
            product.items[itemIndex].configuration.partRange.finalOptions = product.items[itemIndex].configuration.partRange.options;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.partRange.finalOptions, 'optionKey', context.partRange);
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product.items[itemIndex].configuration.partRange.selected.value = product.items[itemIndex].configuration.partRange.finalOptions[optionIndex];
            product = this.updateItemConfigurationOptions("product_item_part_range_options", product, itemIndex, context, false);
            product = this.updateItemConfigurationOptions("product_item_part_range_selected_parts", product, itemIndex, context, false);
        }


        product = this.updateItemConfigurationOptions("product_item_lengthABC", product, itemIndex, null, false);
        product = hospitalRangePartUtil.updateRules("lengthABC", product, itemIndex, order);
        product = hospitalRangePartUtil.updateRules("angle", product, itemIndex, order);
        product = hospitalRangePartUtil.updateRules("angle2", product, itemIndex, order);

        product = hospitalRangePartUtil.updateFormError("lengthABC", product, itemIndex);
        product = hospitalRangePartUtil.updateFormError("angle", product, itemIndex);
        product = hospitalRangePartUtil.updateFormError("angle2", product, itemIndex);
        product = hospitalRangePartUtil.updateFormError("quantity", product, itemIndex);
        product = hospitalRangePartUtil.updateFormError("prodCode", product, itemIndex);
        return product;
    }


    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let optionIndex = -1;
        switch (key) {
            case "product_part_part_dropdown":
                product.part.configuration.part.dropdown.finalOptions = (product.part.configuration.part.options || []).filter(x => {
                    if (!isEmpty(product.part.configuration.colour.dropdown.selected.value)) {
                        return product.part.configuration.colour.dropdown.selected.value.some(s => s.value === x.colour);
                    }
                    return true
                });
                product.part.configuration.part.dropdown.finalOptions = (product.part.configuration.part.dropdown.finalOptions || []).map(o => {
                    return {label: concatenateStrings(' | ', o.prodCode, o.description), value: o.prodCode, ...o}
                });
                product.part.configuration.part.dropdown.selected.value = [];
                break;
            case "product_part_part_displayList":
                product.part.configuration.part.displayList.finalOptions = (product.part.configuration.part.options || []).filter(item => {
                    let result = true;
                    if (result && !isEmpty(product.part.configuration.colour.dropdown.selected.value)) {
                        result = (product.part.configuration.colour.dropdown.selected.value || []).some(x => x.value === item.colour)
                    }
                    if (result && !isEmpty(product.part.configuration.part.dropdown.selected.value)) {
                        result = (product.part.configuration.part.dropdown.selected.value || []).some(x => x.value === item.prodCode)
                    }
                    if (result && !isEmpty(product.items)) {
                        result = !((product.items || []).some(x => x.itemType === PRODUCT_BUILDER_HOSPITAL_RANGE_PART && x.prodCode === item.prodCode));
                    }
                    return result
                });
                break;
            case "product_part_type_options":
                let partsByPartType = groupBy(product.part.configuration.part.displayList.finalOptions, 'partType');
                product.part.configuration.partType.finalOptions = [];
                product.part.configuration.partType.options.forEach(o => {
                    if (partsByPartType[o.value]) {
                        o.parts = partsByPartType[o.value] ? partsByPartType[o.value] : [];
                        product.part.configuration.partType.finalOptions.push(o);
                    }
                });

                break;
            case "product_item_part_range_options":
                product.items[itemIndex].configuration.partRange.finalOptions = (product.items[itemIndex].configuration.partRange.options).filter(x => {
                    let result = true;
                    if (!isEmpty(product.items[itemIndex].configuration.colour.selected.value)) {
                        result = product.items[itemIndex].configuration.colour.selected.value.value === x.condition1;
                    }
                    return result
                });
                product.items[itemIndex].configuration.partRange.finalOptions = getDistinct(product.items[itemIndex].configuration.partRange.finalOptions, 'optionKey');
                (product.items[itemIndex].configuration.partRange.finalOptions || []).forEach(o => {
                    o.label = o.optionKey;
                    o.value = o.optionKey;
                });

                //trying to make ideal selection as it was before
                optionIndex = product.items[itemIndex].configuration.partRange.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.partRange.selected.value.optionKey);
                optionIndex = optionIndex > -1 ? optionIndex : 0;
                //falling back to first item as selected
                product.items[itemIndex].configuration.partRange.selected.value = product.items[itemIndex].configuration.partRange.finalOptions[optionIndex];
                product = this.updateItemConfigurationOptions("product_item_part_range_selected_parts", product, itemIndex, context, false);
                break;
            case "product_item_part_range_selected_parts":
                product.items[itemIndex].configuration.partRange.selected.parts = [];
                //find selected part range with combination of selected part name and colour
                optionIndex = product.items[itemIndex].configuration.partRange.options.findIndex(o => o.optionKey === product.items[itemIndex].configuration.partRange.selected.value.value && o.condition1 === product.items[itemIndex].configuration.colour.selected.value.value);
                if (optionIndex > -1) {
                    // find available lengths(stock items) for selection
                    let prodCodes = (product.items[itemIndex].configuration.partRange.options[optionIndex].stocks || []).map(s => s.prodCode);
                    product.items[itemIndex].configuration.partRange.selected.parts = product.part.configuration.part.options.filter(o => prodCodes.includes(o.prodCode));
                    product.items[itemIndex].configuration.partRange.selected.parts = getSorted(product.items[itemIndex].configuration.partRange.selected.parts, 'length', true);
                }
                product = hospitalRangePartUtil.updateRules("lengthABC", product, itemIndex, null);
                product = this.updateItemConfigurationOptions("product_item_lengthABC", product, itemIndex, null, false);
                product = hospitalRangePartUtil.updateFormError("lengthABC", product, itemIndex);
                break;
            case "product_item_lengthABC":
                product.items[itemIndex].lengthABC.selected.value =
                    parseInt(product.items[itemIndex].lengthA.selected.value)
                    + parseInt(product.items[itemIndex].lengthB.selected.value)
                    + parseInt(product.items[itemIndex].lengthC.selected.value)
                ;
                break;
        }
        return product;
    }

    handleChange(change, key, itemIndex) {
        let {product, packagingAndHandling, discountByProductCode, order} = this.props;
        switch (key) {
            case "product":
                product = change;
                break;
            case "product_colour_part_search":
                product.part.configuration.colour.dropdown.selected.value = change;
                product = this.updateItemConfigurationOptions("product_part_part_dropdown", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                break;
            case "product_part_part_dropdown_search":
                product.part.configuration.part.dropdown.selected.value = change;
                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                product.part.configuration.partType.isOpen = hospitalRangePartUtil.isOpenAllPartType(product, false);
                break;
            case "quantity":
                product.items[itemIndex].quantity.selected.value = change;
                product = hospitalRangePartUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "item_prodCode":
                product.items[itemIndex].prodCode = change ? change.prodCode : "";
                product.items[itemIndex].description = change ? change.description : "";
                product.items[itemIndex].length = change ? change.length : 0;
                product.items[itemIndex].eachUnitName = "unit";
                product.items[itemIndex].dimensionUnitName = "unit";
                product.items[itemIndex].sellUnitName = "unit";
                product.items[itemIndex].sellUnitQty = 1;
                product.items[itemIndex].sellQtyPrice = change ? change.price1 : 0;
                product.items[itemIndex].unitPrice = change ? change.price1 : 0;
                product.items[itemIndex].whUnitName = "unit";
                product.items[itemIndex].whUnitQty = 1;
                product = hospitalRangePartUtil.updateFormError("prodCode", product, itemIndex);
                break;
            case "item_colour":
                product.items[itemIndex].configuration.colour.selected.value = change;
                product = this.updateItemConfigurationOptions("product_item_part_range_options", product, itemIndex, null, false);
                break;
            case "item_part":
                product.items[itemIndex].configuration.partRange.selected.value = change;
                product = this.updateItemConfigurationOptions("product_item_part_range_selected_parts", product, itemIndex, null, false);
                break;
            case "item_shape":
                product.items[itemIndex].configuration.shape.selected.value = change;
                product = hospitalRangePartUtil.updateRules("lengthABC", product, itemIndex, order);
                product = hospitalRangePartUtil.updateRules("angle", product, itemIndex, order);
                product = hospitalRangePartUtil.updateRules("angle2", product, itemIndex, order);

                product = this.updateItemConfigurationOptions("product_item_lengthABC", product, itemIndex, null, false);
                product = hospitalRangePartUtil.updateFormError("lengthABC", product, itemIndex);
                break;
            case "lengthA":
            case "lengthB":
            case "lengthC":
                product.items[itemIndex][key].selected.value = change ? parseInt(change) : change;
                product = this.updateItemConfigurationOptions("product_item_lengthABC", product, itemIndex, null, false);
                product = hospitalRangePartUtil.updateFormError("lengthABC", product, itemIndex);
                break;
            case "angle":
                product.items[itemIndex][key].selected.value = change ? parseInt(change) : change;
                product = hospitalRangePartUtil.updateFormError("angle", product, itemIndex);
                break;
            case "angle2":
                product.items[itemIndex][key].selected.value = change ? parseInt(change) : change;
                product = hospitalRangePartUtil.updateFormError("angle2", product, itemIndex);
                break;
            case "location":
                product.items[itemIndex][key].selected.value = change;
                product = hospitalRangePartUtil.updateFormError("location", product, itemIndex);
                break;
        }
        product = hospitalRangePartUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

        this.props.handleChange("product", product);
    }

    render() {
        let {activeTab} = this.state;
        let {product, discountByProductCode, currentUser, order} = this.props;
        let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;

        if (!product.init) {
            return <Spinner color={"primary"}/>;
        }

        let itemSummary = hospitalRangePartUtil.getItemTypeSummary(product);
        return (
            <div>
                <Nav tabs>
                    <NavItem className={classnames({hoverableItem: activeTab !== '1'})}>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => this.toggle('1')}
                        >
                            Parts {itemSummary[PRODUCT_BUILDER_HOSPITAL_RANGE_PART].count ?
                            <span>({itemSummary[PRODUCT_BUILDER_HOSPITAL_RANGE_PART].count})</span> : null}
                        </NavLink>
                    </NavItem>
                    <NavItem className={classnames({hoverableItem: activeTab !== '2'})}>
                        <NavLink
                            className={classnames({active: activeTab === '2'})}
                            onClick={() => this.toggle('2')}
                        >
                            Build {itemSummary[PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM].count ?
                            <span>({itemSummary[PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM].count + itemSummary[PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING].count})</span> : null}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="mt-2">
                            <HospitalRangePart
                                product={product}
                                order={order}
                                itemSummaryByItemType={itemSummary}
                                discount={discount}
                                currentUser={currentUser}
                                handleChange={this.handleChange}
                                handleItemAction={this.handleItemAction}
                            />
                        </div>

                    </TabPane>
                    <TabPane tabId="2">
                        <div className="mt-2">
                            <HospitalRangeCustom
                                product={product}
                                order={order}
                                itemSummaryByItemType={itemSummary}
                                discount={discount}
                                currentUser={currentUser}
                                handleChange={this.handleChange}
                                handleItemAction={this.handleItemAction}
                            />
                        </div>

                    </TabPane>
                </TabContent>


            </div>
        );
    }
}