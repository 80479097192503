import React, { Component } from 'react';
import {
    Breadcrumb, BreadcrumbItem, Input,
    Button, Table, Card, CardBody, CardHeader, CardFooter, Badge, Spinner
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import FaqService from "../../services/FaqService";
import { Link } from 'react-router-dom';
import { findIndex } from '../../services/CommonService';

export default class ViewFaqSuggestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Faqdata: [],
            search: {
                primaryText: "",
                category: "",
                subCategory: ""
            },
            loading: false,

        };

        this.faqService = new FaqService();
        this.refresh = this.refresh.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({ loading: true });
        this.faqService.getFaqSuggestions().then(response => {
            if (response.data) {
                this.setState({ Faqdata: response.data, loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    searchFunction(f, search) {
        let flag = true;
        if (flag) {
            flag = f.primaryText.toLowerCase().includes(search.primaryText.toLowerCase())
        }
        if (flag && search.category) {
            flag = f.category.toLowerCase().includes(search.category.toLowerCase())
        }
        if (flag && search.subCategory) {
            flag = f.subCategory.toLowerCase().includes(search.subCategory.toLowerCase())
        }

        return flag;

    }


    handleSearch(value, key, index) {
        let { search } = this.state;
        search[key] = value;
        this.setState({ search });
    }

    confirmFAQSuggestion(item) {
        let { Faqdata } = this.state;
        let index = findIndex(Faqdata, "id", item.id);
        Faqdata[index].confirming = true;
        this.setState({ Faqdata: Faqdata });

        this.faqService.confirmFaqSuggestion(parseInt(item.id)).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("FAQ suggestion confirmed successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                Faqdata[index].confirming = false;
                this.setState({ Faqdata: Faqdata });
                this.refresh();
            }
        }).catch(error => {
            Faqdata[index].confirming = false;
            this.setState({ Faqdata: Faqdata });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    rejectFAQSuggestion(item) {
        let { Faqdata } = this.state;
        let index = findIndex(Faqdata, "id", item.id);
        Faqdata[index].rejecting = true;
        this.setState({ Faqdata: Faqdata });

        this.faqService.rejectFaqSuggestion(parseInt(item.id)).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("FAQ suggestion rejected successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                Faqdata[index].rejecting = true;
                this.setState({ Faqdata: Faqdata });
                this.refresh();
            }
        }).catch(error => {
            Faqdata[index].rejecting = true;
            this.setState({ Faqdata: Faqdata });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { search, Faqdata, loading } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/tools/faq/manage')}>FAQ</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Suggestions</BreadcrumbItem>
                </Breadcrumb>



                <Card>
                    <CardHeader><h5>FAQ Suggestions</h5></CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Table striped bordered responsive hover>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Primary Text / Question
                                            <Input type="text" id="primaryText" name="primaryText"
                                                value={search.primaryText}
                                                onChange={(e) => this.handleSearch(e.target.value, "primaryText", -1)}
                                                placeholder="Search..." />
                                        </th>
                                        <th>Category
                                            <Input type="text" id="category" name="category"
                                                value={search.category}
                                                onChange={(e) => this.handleSearch(e.target.value, "category", -1)}
                                                placeholder="Search..." />
                                        </th>
                                        <th>Sub Category
                                            <Input type="text" id="subCategory" name="subCategory"
                                                value={search.subCategory}
                                                onChange={(e) => this.handleSearch(e.target.value, "subCategory", -1)}
                                                placeholder="Search..." />
                                        </th>
                                        <th style={{ minWidth: '120px' }} className="text-center" colSpan={2}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(Faqdata || []).filter((item) => this.searchFunction(item, search)).map((item, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.primaryText}</td>
                                            <td>{item.category}</td>
                                            <td>{item.subCategory}</td>
                                            <td className="text-center" colSpan={2} style={{ width: '200px' }}>
                                                {item.faqSuggestionStatus == "PENDING" ?

                                                    <div className="text-center" >
                                                        <Button color='primary' size="sm" onClick={this.confirmFAQSuggestion.bind(this, item)} disabled={item.confirming}>
                                                            {item.confirming ? <Spinner size="sm" className="mr-2"
                                                                style={{ color: "white" }} /> : null}  Confirm
                                                    </Button>&nbsp;
                                                    <Button color='danger' className="mr-2" size="sm" onClick={this.rejectFAQSuggestion.bind(this, item)} disabled={item.rejecting}>
                                                            {item.rejecting ? <Spinner size="sm" className="mr-2"
                                                                style={{ color: "white" }} /> : null}  Reject
                                                    </Button>
                                                    </div>
                                                    : null}

                                                {
                                                    item.faqSuggestionStatus == "CONFIRMED" ? <div className="text-center"><Badge color="success">CONFIRMED</Badge></div> : item.faqSuggestionStatus == "REJECTED" ? <div className="text-center"> <Badge color="danger">REJECTED</Badge></div> : null
                                                }

                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>}
                    </CardBody>
                    <CardFooter>
                        <div className="text-right">
                            <Link color='secondary' style={{ textDecoration: "none" }} className="btn btn-sm ml-1 btn btn-secondary"
                                to={{
                                    pathname: '/tools/faq/manage'
                                }}>
                                <i className="fa fa-times mr-2"
                                    aria-hidden="true" />Cancel</Link>
                        </div>
                    </CardFooter>
                </Card>
                <ToastContainer />


            </div>
        );
    }
}