import React, { Component } from 'react';
import { concatenateStrings } from "../../../../../../services/CommonService";
import NumberFormat from "react-number-format";

export let widescreenLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 50,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "quantity",
        label: <div>
            Qty
            <div>(unit)</div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "width",
        label: <div>
            Width
            <div>
                (mm)
            </div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "drop",
        label: <div>
            Drop
            <div>
                (mm)
            </div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "model",
        label: "Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "fabricType",
        label: "Fabric",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour, data.productLabel.fabricColourOffRange);
        }
    },
    {
        key: "crankGear",
        label: "Crank Gear",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "controlSide",
        label: "Control Side",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "keyway",
        label: "Keyway",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "headbox",
        label: "Headbox",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.headbox, data.productLabel.headboxSize, data.productLabel.headboxColour, data.productLabel.headboxColourPowdercoat);
        }
    }, {
        key: "frameColour",
        label: "Frame Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.frameColour, data.productLabel.framePowdercoatColour);
        }
    }, {
        key: "track",
        label: "Track",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.track, data.productLabel.outerChannel);
        }
    }, {
        key: "baseRail",
        label: "Base Rail",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.baseRailEndCap, data.productLabel.baseRailSeal);
        }
    }, {
        key: "extras",
        label: "Extras",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            let bracketCover = data.productLabel.bracketCover ? "Bracket Cover" : "";
            let headboxEndCapCover = data.productLabel.headboxEndCapCover ? "Headbox End Cap Cover" : "";
            let holdDownKit = data.productLabel.holdDownKit ? "HoldDownKit" : "";
            let windoware15ChannelRemote = data.productLabel.windoware15ChannelRemote ? "Windoware 15 Channel Remote" : "";
            let windowareBridge = data.productLabel.windowareBridge ? "Windoware Bridge" : "";
            let somfy1ChannelSituoRemote = data.productLabel.somfy1ChannelSituoRemote ? "Somfy 1 Channel Situo Remote" : "";
            return concatenateStrings(", ", bracketCover, headboxEndCapCover, holdDownKit, windoware15ChannelRemote, windowareBridge, somfy1ChannelSituoRemote);
        }
    }
];