import axios from "../axios";
import { authHeader } from "../../helpers/auth-header";
import { config } from "../../helpers/config";

class WarehouseLocationService {

    static Instance() {
        return new WarehouseLocationService();
    }

    listWarehouseLocation(req) {
        return axios.post('api/warehouse-location/list', req);
    }

    getOneWarehouseLocation(warehouseLocationID) {
        return axios.get('api/warehouse-location/one/?WarehouseLocationID=' + warehouseLocationID);
    }

    saveOrUpdateWarehouseLocation(req) {
        return axios.post('api/warehouse-location/save', req);
    }

    deleteWarehouseLocation(warehouseLocationID) {
        return axios.delete('api/warehouse-location/delete/?WarehouseLocationID=' + warehouseLocationID);
    }

    listAllWarehouseLocations() {
        return axios.get('api/warehouse-location/list/all');
    }

    getWarehouseLocationSummary(searchText) {

        let requestBody = {
            name: searchText
        };
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
        };
        return axios(config.apiUrl + 'api/warehouse-location/summary/list', requestOptions);
    }
}

export default WarehouseLocationService.Instance();