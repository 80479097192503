import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Table,} from 'reactstrap';
import NumberFormat from "react-number-format";
import '../../../../../../assets/css/app/Print.css';

export default class RollerBlindDoubleBOMModalPrint extends Component {

    constructor(props) {
        super(props);
    }

    getDescription(stocksByProdCode, prodCode) {
        let description = '';
        Object.keys(stocksByProdCode).map(key => {
            if (stocksByProdCode[key].prodCode === prodCode) {
                description = stocksByProdCode[key].description;
                return description;
            }
        });

        return description;
    }

    render() {

        let {product, itemIndex, stocksByProdCode, order} = this.props;
        return (
            <div>
                <h5>{order.debtorInvoiceOrdNum ? <span className="mr-2">#{order.debtorInvoiceOrdNum}</span> : null}Double
                    Linked Roller Blind</h5>
                <div>
                    {
                        (product.items[itemIndex].subItems || []).map((subItem, subItemIndex) => {
                            return <div key={subItemIndex}>
                                <Card className={"mb-2"}>
                                    <CardHeader>Blind {product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value}/{product.items[itemIndex].subItems[subItemIndex].configuration.blindSequenceMax.selected.value}'s
                                        parts list</CardHeader>
                                    <CardBody>
                                        <Table responsive={true} bordered={true} striped={true} size={"sm"}>
                                            <thead>
                                            <tr>
                                                <th>Location</th>
                                                <th>Qty</th>
                                                <th>Mount</th>
                                                <th>Layout</th>
                                                <th>Diagram</th>
                                                <th>
                                                    <div>Blind</div>
                                                    <div>Position</div>
                                                </th>
                                                <th>
                                                    <div>Roll</div>
                                                    <div>Direction</div>
                                                </th>
                                                <th>
                                                    <div>Control</div>
                                                    <div>Side</div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className="align-middle">{product.items[itemIndex].configuration.room.selected.value}</td>
                                                <td className="align-middle text-center">{product.items[itemIndex].configuration.quantity.selected.value}</td>
                                                <td className="align-middle">{product.items[itemIndex].configuration.mount.selected.dropdownValue}</td>
                                                <td className="align-middle">
                                                    {product.items[itemIndex].configuration.layout.selected.dropdownValue}&nbsp;
                                                    {product.items[itemIndex].configuration.subLayout.selected.dropdownValue}
                                                </td>
                                                <td className="align-middle">{product.items[itemIndex].configuration.diagram.selected.value.label}</td>
                                                <td className="align-middle">{product.items[itemIndex].subItems[subItemIndex].configuration.blindPosition.selected.value}</td>
                                                <td className="align-middle">{product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.dropdownValue}</td>
                                                <td className="align-middle">{product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        <Table responsive={true} bordered={true} striped={true} size={"sm"}>
                                            <thead>
                                            <tr>
                                                <th>{subItem.configuration.width.selected.value + " x " + subItem.configuration.drop.selected.value}</th>
                                                <th>Item code</th>
                                                <th>Description</th>
                                                <th>Qty with
                                                    <div>deduction calculated</div>
                                                </th>
                                                <th>KW measurement unit</th>
                                                <th>Qty with
                                                    <div>wastage calculated</div>
                                                </th>
                                                <th>SWISH measurement unit</th>
                                                <th>Charges</th>
                                                <th>Disc.</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                (subItem.stocks || []).filter(s => s.isVisibleInPartList).map((stock, stockIndex) => {
                                                    return <tr key={"subItem" + subItemIndex + stockIndex}>
                                                        {stock.attributeRowSpan > 0
                                                            ? <td rowSpan={stock.attributeRowSpan}
                                                                  className="align-middle">
                                                                <div>
                                                                    <strong>{stock.attribute}</strong>
                                                                </div>
                                                                <div>
                                                                    {stock.label}
                                                                </div>
                                                            </td>
                                                            : null
                                                        }
                                                        <td className="align-middle">{stock.prodCode}</td>
                                                        <td className="align-middle">{this.getDescription(stocksByProdCode, stock.prodCode)}</td>
                                                        <td className="align-middle text-right">
                                                            <NumberFormat
                                                                value={(stock.keywayCalculatedQty + stock.deductionQty)}
                                                                displayType={'text'}
                                                                decimalScale={4}
                                                                thousandSeparator={false}/>
                                                        </td>
                                                        <td className="align-middle text-center">{stock.keywayMeasurementUnit}</td>
                                                        <td className="align-middle text-right">
                                                            <NumberFormat
                                                                value={stock.finalQty}
                                                                displayType={'text'}
                                                                decimalScale={4}
                                                                thousandSeparator={false}/>
                                                        </td>
                                                        <td className="align-middle text-center">{stock.swishMeasurementUnit}</td>
                                                        <td className="align-middle text-right">
                                                            <NumberFormat
                                                                prefix={'$'}
                                                                value={stock.finalPrice}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={false}/></td>
                                                        <td className="align-middle text-right">
                                                            {
                                                                stock.allowDiscount
                                                                    ? <NumberFormat
                                                                        prefix={'$'}
                                                                        value={stock.discVal}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        thousandSeparator={true}/>
                                                                    : 'N/A'
                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                                <td><strong>Sub-total</strong></td>
                                                <td className="text-right" colSpan={7}>
                                                    <strong><NumberFormat
                                                        prefix={'$'}
                                                        value={subItem.pricing.unitPrice}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/></strong></td>
                                                <td className="text-right" colSpan={1}>
                                                    <strong><NumberFormat
                                                        prefix={'$'}
                                                        value={subItem.pricing.unitDiscVal}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/></strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Price(ex.GST)</strong></td>
                                                <td className="text-right" colSpan={8}><strong><NumberFormat
                                                    prefix={'$'}
                                                    value={subItem.pricing.unitPrice - subItem.pricing.unitDiscVal}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/></strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Deduction</strong></td>
                                                <td><strong><NumberFormat
                                                    suffix={'mm'}
                                                    value={subItem.configuration.deduction.selected.value.deduction}
                                                    displayType={'text'}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}/></strong></td>
                                                <td colSpan={7}>(applicable for fabric, keyway and bottom rail)</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </div>
                        })}
                </div>
            </div>
        )
    }
}