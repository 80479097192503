import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormFeedback,
    Input,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import {
    addBusinessDays,
    changeFormatOfDateString,
    getDateString,
    getDistinctValues,
    getPowdercoatingPurchaseOrderStatusColorName,
    handleErrorMessage
} from '../../services/CommonService'
import CreatableSelect from 'react-select/creatable';
import creditorService from '../../services/purchase/CreditorService'
import cloneDeep from 'lodash/cloneDeep';
import {toast, ToastContainer} from 'react-toastify';
import SearchStock from "../../components/search/SearchStock";
import SearchCreditorAcccount from "../../components/search/SearchCreditorAcccount";
import ApplySelectionToMultipleItems from "../../components/util/ApplySelectionToMultipleItems";
import UploadFiles from "../../components/attachment/UploadFiles";
import {PowdercoatColorOptions} from '../../store/PowdercoatingColorOptions';
import productionScheduleService from '../../services/production/ProductionScheduleService'
import ComposeEmail from "../../components/modal/ComposeEmail";
import moment from 'moment';
import powdercoatingService from "../../services/PowdercoatingService";
import queryString from 'query-string';
import ManagePowdercoatingPurchaseOrderStatus from '../../components/modal/ManagePowdercoatingPurchaseOrderStatus';

export default class PowdercoatingPurchaseOrderForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRequestValidated: false,
            powdercoatPurchaseOrderForm: {},
            isLoadingPowdercoatingItemsSave: false,
            isOpenPowdercoatingPurchaseOrderStatusModal: false,
            emailPurchaseOrderModal: {
                isOpen: false,
                isLoadingEmailSend: false,
                mail: {
                    id: "",
                    from: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    fromOptions: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    to: [],
                    toOptions: [],
                    cc: [{
                        label: "Windoware Accounts(accountspayable@windoware.com.au)",
                        value: "accountspayable@windoware.com.au"
                    }, {
                        label: "Carmella(production@windoware.com.au)",
                        value: "production@windoware.com.au"
                    }, {
                        label: "Dispatch(dispatch@windoware.com.au)",
                        value: "dispatch@windoware.com.au"
                    }],
                    ccOptions: [{
                        label: "Windoware Accounts(accountspayable@windoware.com.au)",
                        value: "accountspayable@windoware.com.au"
                    }, {
                        label: "Carmella(production@windoware.com.au)",
                        value: "production@windoware.com.au"
                    }, {
                        label: "Dispatch(dispatch@windoware.com.au)",
                        value: "dispatch@windoware.com.au"
                    }],
                    bcc: [],
                    bccOptions: [],
                    subject: "",
                    body: "",
                    attachmentIDs: ""
                }
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleApplyToAll = this.handleApplyToAll.bind(this);
        this.toggleEmailPurchaseOrderModal = this.toggleEmailPurchaseOrderModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createPurchaseOrderDocument = this.createPurchaseOrderDocument.bind(this);
        this.emailFormatDetail = this.emailFormatDetail.bind(this);
        this.sendEmailPurchaseOrderModal = this.sendEmailPurchaseOrderModal.bind(this);
        this.togglePowdercoatingPurchaseOrderStatusModal = this.togglePowdercoatingPurchaseOrderStatusModal.bind(this);
        this.openPowdercoatingPurchaseOrderStatusModal = this.openPowdercoatingPurchaseOrderStatusModal.bind(this);
        this.fetchPowdercoatingOrderStatus = this.fetchPowdercoatingOrderStatus.bind(this);
        this.salesOrderScheduleNow = this.salesOrderScheduleNow.bind(this);
    }

    componentDidMount() {
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            let purchaseOrdNum = searchParams.purchaseOrdNum;
            this.setState({purchaseOrdNum});
            this.getPowdercoatingOrder(purchaseOrdNum);
            this.fetchPowdercoatingOrderStatus(purchaseOrdNum);
        }
    }

    getPowdercoatingOrder(purchaseOrdNum) {
        powdercoatingService.getPowdercoatingOrderByPurchaseOrdNum(purchaseOrdNum).then(response => {
            let powdercoatPurchaseOrderForm = response.data;
            this.emailFormatDetail(powdercoatPurchaseOrderForm);
            this.setState({powdercoatPurchaseOrderForm});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    componentWillReceiveProps(nextProps) {
        let {purchaseOrdNum} = this.state;
        if (nextProps.location.search) {
            let searchParams = queryString.parse(nextProps.location.search);
            if (this.state.purchaseOrdNum !== searchParams.purchaseOrdNum) {
                purchaseOrdNum = searchParams.purchaseOrdNum;
                this.setState({purchaseOrdNum});
                this.getPowdercoatingOrder(purchaseOrdNum);
            }
        }
    }

    fetchPowdercoatingOrderStatus(purchaseOrdNum) {
        powdercoatingService.fetchPowdercoatingOrderStatus(purchaseOrdNum).then(response => {
            let powdercoatPurchaseOrderStatus = response.data;
            this.setState({powdercoatPurchaseOrderStatus});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    handleChange(key, change, index, isUpdateStateRequired) {
        let {powdercoatPurchaseOrderForm} = this.state;

        switch (key) {
            case "supplier":
                powdercoatPurchaseOrderForm.supplierAccountID = change ? change : "";
                if (powdercoatPurchaseOrderForm.supplierAccountID) {
                    creditorService.getOneCreditor(powdercoatPurchaseOrderForm.supplierAccountID).then(response => {
                        let {emailPurchaseOrderModal} = this.state;
                        let companyName = response.data.company;
                        let emailAddress = response.data.email ? response.data.email : "No email address added with this supplier!";

                        emailPurchaseOrderModal.mail.to = [{
                            label: companyName + "(" + emailAddress + ")",
                            value: emailAddress
                        }];
                        emailPurchaseOrderModal.mail.toOptions = [{
                            label: companyName + "(" + emailAddress + ")",
                            value: emailAddress
                        }];
                        this.setState({emailPurchaseOrderModal});
                    }).catch(error => {
                        console.log(error);
                        toast.error(handleErrorMessage(error));
                    })
                }

                break;
            case "pickedBy":
                powdercoatPurchaseOrderForm.pickedBy = change;
                break;

            case "item-add":
                if (!powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems) {
                    powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems = [];
                }
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems.push({
                    id: 0,
                    prodCode: "",
                    description: "",
                    qty: 0,
                    length: 0,
                    powdercoatColor: "",
                    instructions: "",
                });
                break;
            case "item-delete":
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems.splice(index, 1);
                break;
            case "item-copy":
                let x = cloneDeep(change);
                x.id = 0;
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems.splice(index, 0, x);
                break;
            case "item-prodCode":
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems[index].prodCode = change ? change.prodCode : '';
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems[index].description = change ? change.description : '';
                break;
            case "item-returnProdCode":
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems[index].returnProdCode = change ? change.prodCode : '';
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems[index].returnProdCodeDescription = change ? change.description : '';
                break;
            default:
                powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems[index][key] = change;
        }

        if (isUpdateStateRequired) {
            this.setState({powdercoatPurchaseOrderForm});
        } else {
            return powdercoatPurchaseOrderForm;
        }
    }

    handleApplyToAll(change, key, index, applyTo) {
        let {powdercoatPurchaseOrderForm} = this.state;
        let cursor, endIndex;

        switch (applyTo) {
            case "above":
                cursor = 0;
                endIndex = index;
                break;
            case "below":
                cursor = index + 1;
                endIndex = powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems.length;
                break;
            case "all":
                cursor = 0;
                endIndex = powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems.length;
                break;
        }
        while (cursor < endIndex) {
            powdercoatPurchaseOrderForm = this.handleChange(key, change, cursor, false);
            cursor++;
        }
        this.setState({powdercoatPurchaseOrderForm})
    }

    toggleEmailPurchaseOrderModal(change) {
        let {emailPurchaseOrderModal} = this.state;
        emailPurchaseOrderModal.isOpen = change;
        this.setState({emailPurchaseOrderModal});
    }

    validateRequest(powdercoatPurchaseOrderForm) {
        let errors = [];
        if (!powdercoatPurchaseOrderForm.supplierAccountID) {
            errors.push("Supplier must be selected!");
        }

        if (!powdercoatPurchaseOrderForm.pickedBy) {
            errors.push("Picked by must be entered!");
        }

        if (!powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems || (powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems && (!powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems.length > 0))) {
            errors.push("At least one item should be entered!");
        }
        return errors;
    }

    handleSubmit() {
        let request = this.state.powdercoatPurchaseOrderForm;
        request.salesOrderJobID = 0;
        let errors = this.validateRequest(request);
        if (errors && errors.length > 0) {
            errors.forEach(error => {
                toast.error(error, {position: toast.POSITION.TOP_RIGHT});
            });
            this.setState({isRequestValidated: true});
            return;
        }

        this.setState({isLoadingPowdercoatingItemsSave: true});

        productionScheduleService.saveSalesOrderJobBulkPowdercoatingPurchaseOrder(request).then(response => {
            let {powdercoatPurchaseOrderForm} = this.state;
            powdercoatPurchaseOrderForm.id = response.data.id ? response.data.id : null;
            powdercoatPurchaseOrderForm.isEmailed = response.data.isEmailed ? response.data.isEmailed : "";
            powdercoatPurchaseOrderForm.emailedDate = response.data.emailedDate ? response.data.emailedDate : "";
            powdercoatPurchaseOrderForm.attachmentIDs = response.data.attachmentIDs ? response.data.attachmentIDs : "";
            powdercoatPurchaseOrderForm.purchaseOrderNum = response.data.purchaseOrderNum ? response.data.purchaseOrderNum : "";
            powdercoatPurchaseOrderForm.createdDate = response.data.createdDate ? response.data.createdDate : getDateString(new Date(), "DD/MM/YYYY HH:mm:ss");
            powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems = response.data.salesOrderJobPowdercoatPurchaseOrderItems ? response.data.salesOrderJobPowdercoatPurchaseOrderItems : [];
            powdercoatPurchaseOrderForm.debtorInvoiceOrdNum = response.data.debtorInvoiceOrdNum ? response.data.debtorInvoiceOrdNum : "";
            this.emailFormatDetail(powdercoatPurchaseOrderForm);
            this.setState({
                isLoadingPowdercoatingItemsSave: false,
                powdercoatPurchaseOrderForm,
            }, () => {
                this.createPurchaseOrderDocument();
                this.fetchPowdercoatingOrderStatus(powdercoatPurchaseOrderForm.purchaseOrderNum);
            });
            toast.success("Purchase order created!");
        }).catch(error => {
            console.error(error);
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingPowdercoatingItemsSave: false});
        })
    }

    createPurchaseOrderDocument() {
        let {powdercoatPurchaseOrderForm, emailPurchaseOrderModal} = this.state;
        this.setState({isLoadingPurchaseOrderDocument: true});

        productionScheduleService.createPurchaseOrderDocument(powdercoatPurchaseOrderForm.purchaseOrderNum).then(response => {
            if (powdercoatPurchaseOrderForm.attachmentIDs) {
                toast.success(response.data.description + " updated!");
            }

            powdercoatPurchaseOrderForm.attachmentIDs = response.data ? response.data.id : "";
            emailPurchaseOrderModal.mail.attachmentIDs = powdercoatPurchaseOrderForm.attachmentIDs;
            toast.success("Scheduling Sales Order!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
                isLoadingPurchaseOrderDocument: false,
                powdercoatPurchaseOrderForm,
                emailPurchaseOrderModal
            }, () => this.salesOrderScheduleNow());
        }).catch(error => {
            console.error(error);
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingPurchaseOrderDocument: false});
        })
    }

    salesOrderScheduleNow() {
        let { powdercoatPurchaseOrderForm } = this.state;
        let request = {
            scheduleOn: getDateString(new Date(), "DD/MM/YYYY"),
            ordNum: powdercoatPurchaseOrderForm.debtorInvoiceOrdNum,
            isAutoScheduling: true
        };
        powdercoatingService.getDateAfterScheduleOrderQuick(request).then(response => {
            powdercoatPurchaseOrderForm.scheduleOn = response.data;
            toast.success("Order Scheduled!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ powdercoatPurchaseOrderForm });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    emailFormatDetail(powdercoatPurchaseOrderForm) {
        let {emailPurchaseOrderModal} = this.state;
        emailPurchaseOrderModal.mail.attachmentIDs = powdercoatPurchaseOrderForm.attachmentIDs;
        emailPurchaseOrderModal.mail.subject = "Windoware PO# " + powdercoatPurchaseOrderForm.purchaseOrderNum + "....for Ace Powdercoating";
        emailPurchaseOrderModal.mail.body = "Hi There, \n";
        emailPurchaseOrderModal.mail.body += "Please find attached PO# " + powdercoatPurchaseOrderForm.purchaseOrderNum + "\n";
        let sentDate;
        if (powdercoatPurchaseOrderForm.createdDate) {
            sentDate = moment(powdercoatPurchaseOrderForm.createdDate, "DD-MM-YYYY").startOf('day');
            sentDate = addBusinessDays(sentDate, 5);
            sentDate = moment(sentDate).format('ddd, DD-MM-YYYY');
        }
        else {
            sentDate = moment(new Date(), "DD-MM-YYYY").startOf('day');
            sentDate = addBusinessDays(sentDate, 5);
            sentDate = moment(sentDate).format('ddd, DD-MM-YYYY');
        }
        emailPurchaseOrderModal.mail.body += "Required for collection: " + sentDate;
        this.setState({emailPurchaseOrderModal});
    }

    sendEmailPurchaseOrderModal(mail) {
        let {emailPurchaseOrderModal, powdercoatPurchaseOrderForm} = this.state;
        emailPurchaseOrderModal.isLoadingEmailSend = true;
        emailPurchaseOrderModal.mail = mail;
        this.setState({emailPurchaseOrderModal});

        let request = {
            salesOrderJobPowdercoatPurchaseOrderID: powdercoatPurchaseOrderForm.id,
            tos: getDistinctValues(emailPurchaseOrderModal.mail.to ? emailPurchaseOrderModal.mail.to : [], 'value'),
            ccs: getDistinctValues(emailPurchaseOrderModal.mail.cc ? emailPurchaseOrderModal.mail.cc : [], 'value'),
            bccs: getDistinctValues(emailPurchaseOrderModal.mail.bcc ? emailPurchaseOrderModal.mail.bcc : [], 'value'),
            subject: emailPurchaseOrderModal.mail.subject,
            body: emailPurchaseOrderModal.mail.body,
            attachmentIDs: emailPurchaseOrderModal.mail.attachmentIDs
        };


        productionScheduleService.emailSalesOrderJobPowdercoatPurchaseOrder(request).then(response => {
            emailPurchaseOrderModal = this.state.emailPurchaseOrderModal;
            emailPurchaseOrderModal.isLoadingEmailSend = false;
            emailPurchaseOrderModal.isOpen = false;
            this.setState({emailPurchaseOrderModal});
            toast.success("Email sent!");
        }).catch(error => {
            console.error(error);
            toast.error(handleErrorMessage(error));
            emailPurchaseOrderModal = this.state.emailPurchaseOrderModal;
            emailPurchaseOrderModal.isLoadingEmailSend = false;
            this.setState({emailPurchaseOrderModal});
        })
    }

    openPowdercoatingPurchaseOrderStatusModal(selectedRecord) {
        this.setState({isOpenPowdercoatingPurchaseOrderStatusModal: true, selectedRecord: selectedRecord});
    }

    togglePowdercoatingPurchaseOrderStatusModal(data) {
        this.setState({isOpenPowdercoatingPurchaseOrderStatusModal: data});
    }

    render() {
        let {purchaseOrdNum, powdercoatPurchaseOrderForm, isRequestValidated, powdercoatPurchaseOrderStatus, isLoadingPowdercoatingItemsSave, isOpenPowdercoatingPurchaseOrderStatusModal, emailPurchaseOrderModal, isLoadingPurchaseOrderDocument} = this.state;
        let totalQtySent = 0;
        return (
            <div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/tools/powdercoating/order">Powdercoating</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Powdercoating Form</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader className={"font-weight-bold"}> Powdercoating Form
                        {powdercoatPurchaseOrderStatus ?
                            <Badge
                                color={getPowdercoatingPurchaseOrderStatusColorName(powdercoatPurchaseOrderStatus.id)}
                                href="javascript:void(0)"
                                className={"ml-2"}
                                onClick={(e) => this.togglePowdercoatingPurchaseOrderStatusModal(true)}>
                                <i className="fa fa-pencil mr-1"
                                   aria-hidden="true"/> {powdercoatPurchaseOrderStatus.description}</Badge>
                            : null}
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div><strong>Supplier</strong></div>
                                <SearchCreditorAcccount
                                    handleAccountChange={(selected) => this.handleChange("supplier", selected, null, true)}
                                    defaultAccountID={powdercoatPurchaseOrderForm.supplierAccountID ? powdercoatPurchaseOrderForm.supplierAccountID : "40368"}
                                    selectedAccountID={powdercoatPurchaseOrderForm.supplierAccountID ? powdercoatPurchaseOrderForm.supplierAccountID : ""}/>

                                <div className="mt-2">
                                    <div><strong>Picked By</strong></div>
                                    <Input type="text" name="pickedBy"
                                           invalid={isRequestValidated && !powdercoatPurchaseOrderForm.pickedBy}
                                           value={powdercoatPurchaseOrderForm.pickedBy ? powdercoatPurchaseOrderForm.pickedBy : ""}
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value, null, true)}
                                           placeholder="Picked By"/>
                                    {
                                        (isRequestValidated) && (!powdercoatPurchaseOrderForm.pickedBy)
                                            ? <FormFeedback>Can't be empty</FormFeedback>
                                            : null

                                    }

                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="text-right">
                                    <h6><strong className="mr-2">Sales Order
                                        #:</strong>{powdercoatPurchaseOrderForm.debtorInvoiceOrdNum}</h6>
                                    <h6><strong className="mr-2">Scheduled On
                                        Date:</strong>{changeFormatOfDateString(powdercoatPurchaseOrderForm.scheduleOn, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY")}
                                    </h6>
                                    <h6><strong className="mr-2">Purchase Order
                                        #:</strong>{powdercoatPurchaseOrderForm.purchaseOrderNum}</h6>
                                    <h6><strong className="mr-2">Purchase Order
                                        Date:</strong>{changeFormatOfDateString(powdercoatPurchaseOrderForm.createdDate ? powdercoatPurchaseOrderForm.createdDate : new Date(), "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY")}
                                    </h6>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <div>
                            <Table responsive={true} striped={true} bordered={true} hover={true}
                                   style={{minHeight: 250}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 300}}>Prod Code</th>
                                    <th style={{minWidth: 100}}>
                                        Qty Sent
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        Length(mm)
                                    </th>
                                    <th style={{minWidth: 250}}>
                                        Powdercoat Color
                                    </th>
                                    <th style={{minWidth: 250}}>Return Product Code</th>
                                    <th style={{minWidth: 250}}>
                                        Special Instructions
                                    </th>
                                    <th className="text-center" style={{minWidth: 200}}>
                                        <Button color={"primary"}
                                                size={"sm"}
                                                title={"add new item"}
                                                onClick={() => this.handleChange("item-add", null, -1, true)}>
                                            <i className="fa fa-plus mr-2" aria-hidden="true"/>Add item
                                        </Button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems || []).map((item, index) => {
                                        totalQtySent = totalQtySent + item.qty;
                                        return <tr key={index}>
                                            <td>
                                                <div title={item.prodCode} className="mb-2">
                                                    <SearchStock
                                                        disabled={false}
                                                        handleSelection={(selected) => this.handleChange('item-prodCode', selected, index, true)}
                                                        selected={item.prodCode}
                                                        filter={{ bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                                                    />
                                                </div>
                                                <Input type="text" name="description"
                                                       title={item.description}
                                                       value={item.description}
                                                       onChange={(e) => this.handleChange(e.target.name, e.target.value, index, true)}
                                                       placeholder="Description"/>
                                            </td>
                                            <td>
                                                <div className={"d-flex justify-content-between"}>
                                                    <Input type="number" name="qty"
                                                           value={item.qty}
                                                           onChange={(e) => this.handleChange(e.target.name, parseInt(e.target.value), index, true)}
                                                           placeholder="Qty Sent"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={"d-flex justify-content-between"}>
                                                    <Input type="number" name="length"
                                                           value={item.length}
                                                           onChange={(e) => this.handleChange(e.target.name, e.target.value, index, true)}
                                                           placeholder="Length"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={"d-flex justify-content-between"}>
                                                    <div className={"flex-fill"}>
                                                        <CreatableSelect
                                                            formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                            onChange={(value) => this.handleChange("powdercoatColor", value.value, index, true)}
                                                            value={{
                                                                label: item.powdercoatColor,
                                                                value: item.powdercoatColor
                                                            }}
                                                            options={PowdercoatColorOptions}
                                                        />
                                                    </div>
                                                    <div className={"text-right"}>

                                                        <ApplySelectionToMultipleItems
                                                            handleApplyToAll={this.handleApplyToAll}
                                                            change={item.powdercoatColor}
                                                            variableKey={"powdercoatColor"}
                                                            itemsIndex={index}
                                                            isDisabled={false}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div title={item.returnProdCode} className="mb-2">
                                                    <SearchStock
                                                        disabled={false}
                                                        handleSelection={(selected) => this.handleChange('item-returnProdCode', selected, index, true)}
                                                        selected={item.returnProdCode}
                                                        filter={{ bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className={"d-flex justify-content-between"}>
                                                    <Input type="textarea" name="instructions"
                                                           value={item.instructions}
                                                           onChange={(e) => this.handleChange(e.target.name, e.target.value, index, true)}
                                                           placeholder="Instructions"/>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                    <span>
                                                        <Button color={"link"}
                                                                title={"copy item"}
                                                                onClick={() => this.handleChange("item-copy", item, index, true)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"delete item"}
                                                                onClick={() => this.handleChange("item-delete", item, index, true)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </span>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    (powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems && powdercoatPurchaseOrderForm.salesOrderJobPowdercoatPurchaseOrderItems.length > 0)
                                        ? <tr>
                                            <th className="align-middle text-left">Total Qty Sent</th>
                                            <th className="align-middle text-center">{totalQtySent}</th>
                                            <th className="align-middle" colSpan={6}>
                                                {
                                                    (powdercoatPurchaseOrderForm && powdercoatPurchaseOrderForm.attachmentIDs)
                                                        ?
                                                        <div>
                                                            <UploadFiles
                                                                isReadOnly={true}
                                                                    attachmentIDs={powdercoatPurchaseOrderForm.attachmentIDs ? powdercoatPurchaseOrderForm.attachmentIDs : ""}
                                                                    multiple={false}
                                                                    maxLength={1} />

                                                        </div>
                                                        : null
                                                }
                                                {
                                                    powdercoatPurchaseOrderForm.purchaseOrderNum
                                                        ? <Button color={"primary"}
                                                                  className={"mt-2"}
                                                                  size={"sm"}
                                                                  disabled={isLoadingPurchaseOrderDocument}
                                                                  onClick={this.createPurchaseOrderDocument}>
                                                            {
                                                                isLoadingPurchaseOrderDocument
                                                                    ? <Spinner size="sm" className="mr-2"
                                                                               color={"light"}/>
                                                                    : <i className="fa fa-refresh mr-2"
                                                                         aria-hidden="true"/>
                                                            }

                                                            {
                                                                (powdercoatPurchaseOrderForm.attachmentIDs
                                                                    ? (isLoadingPurchaseOrderDocument ? "Updating purchase order pdf file" : "Update purchase order pdf file")
                                                                    : (isLoadingPurchaseOrderDocument ? "Creating purchase order pdf file" : "Create purchase order pdf file"))
                                                            }
                                                        </Button>
                                                        : null
                                                }
                                            </th>
                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                        </div>
                        <div className={"text-right mt-2"}>
                            {powdercoatPurchaseOrderForm.purchaseOrderNum ?
                                <Button color={"primary"} className={"mr-2"}
                                        size={"sm"}
                                        disabled={emailPurchaseOrderModal.isLoadingEmailSend}
                                        onClick={() => this.toggleEmailPurchaseOrderModal(!emailPurchaseOrderModal.isOpen)}>
                                    <i className="fa fa-share mr-2" aria-hidden="true"/>
                                    Send email
                                </Button>
                                : null}
                            <Button color={"primary"}
                                    className={"mr-2"}
                                    size={"sm"}
                                    disabled={isLoadingPowdercoatingItemsSave}
                                    onClick={this.handleSubmit}>
                                {isLoadingPowdercoatingItemsSave
                                    ? <Spinner size="sm" className="mr-2" color={"light"}/>
                                    : <i className="fa fa-floppy-o mr-2"
                                         aria-hidden="true"/>
                                }
                                {isLoadingPowdercoatingItemsSave
                                    ? (powdercoatPurchaseOrderForm.purchaseOrderNum ? "Updating " : "Creating ")
                                    : (powdercoatPurchaseOrderForm.purchaseOrderNum ? "Update " : "Create ")
                                }Purchase Order
                            </Button>
                            <Link
                                to={"/tools/powdercoating/order"}
                                className="btn btn-sm ml-1 btn btn-secondary">
                                <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel
                            </Link>
                        </div>
                    </CardBody>
                </Card>
                <ComposeEmail isOpen={emailPurchaseOrderModal.isOpen}
                              isSendingEmail={emailPurchaseOrderModal.isLoadingEmailSend}
                              toggle={this.toggleEmailPurchaseOrderModal}
                              message={emailPurchaseOrderModal.mail}
                              handleSubmit={this.sendEmailPurchaseOrderModal}
                              parentType={"PowderCoating"}
                />
                {isOpenPowdercoatingPurchaseOrderStatusModal
                    ?
                    <ManagePowdercoatingPurchaseOrderStatus
                        isOpen={isOpenPowdercoatingPurchaseOrderStatusModal}
                        toggle={this.togglePowdercoatingPurchaseOrderStatusModal}
                        selectedPurchaseOrderNum={powdercoatPurchaseOrderForm.purchaseOrderNum}
                        selectedPurchaseStatus={powdercoatPurchaseOrderStatus.id}
                        refreshAgain={(e) => this.fetchPowdercoatingOrderStatus(purchaseOrdNum)}/>
                    : null
                }
                <ToastContainer/>
            </div>
        )
    }
}