import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    Collapse,
    Row,
    Col,
    Input, Label, InputGroup
} from 'reactstrap';
import classnames from 'classnames'
import PriceMatrixComponent from './PriceMatrixComponent'
import {toast, ToastContainer} from 'react-toastify';
import productConfigurationService from "../../services/product/ProductConfigurationService";
import {handleErrorMessage, findIndex} from "../../services/CommonService";
import queryString from 'query-string';
import ProductConfigurationPricingMatrixImportExportModal from './ProductConfigurationPricingMatrixImportExportModal';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import UserService from '../../services/UserService';
import DeleteModal from '../../components/modal/DeleteModal';

export default class ProductConfigurationPricingMatrixPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bomcodes: [],
            bomCodesWithAccount: [],
            variants: [],
            productID: 0,
            activeTab: "",
            isCollapsing: false,
            canDeleteProductionConfiguration: false,
            variantType: "Default"
        };
        this.userService = new UserService();

        this.getBomcodes = this.getBomcodes.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleImportExportModal = this.toggleImportExportModal.bind(this);
        this.handleDeleteProductConfigurationFabric = this.handleDeleteProductConfigurationFabric.bind(this);
        this.toggleDeleteProductConfigurationModal = this.toggleDeleteProductConfigurationModal.bind(this);
        this.handleVariantChange = this.handleVariantChange.bind(this);
        this.getDistinctVariants = this.getDistinctVariants.bind(this);
        this.toggleType = this.toggleType.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        let canDeleteProductionConfiguration = this.userService.hasPrivilege(currentUser, 'product-configuration-delete');
        this.setState({canDeleteProductionConfiguration});

        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            let productID = searchParams.productID;
            this.setState({productID});
            this.getBomcodes(productID);
            this.getDistinctVariants(productID);
        }
    }

    getBomcodes(productID) {
        productConfigurationService.getDistinctBomCodesBasedOnProductId(productID, this.state.variantType).then(response => {
            this.setState({bomcodes: response.data}, () => {
                this.toggle(response.data[0]);
            })
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getDistinctVariants(productID) {
        let {variants} = this.state;
        variants = [];
        productConfigurationService.getDistinctVariantsFromPriceMatrix(productID).then(response => {
            for (var i = 0; i < response.data.length; i++) {
                variants.push({'label': response.data[i], 'value': response.data[i]})
            }
            this.setState({variants});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }


    handleVariantChange(e) {
        const {name, value} = e.target;
        let {variantType} = this.state;
        variantType = value;
        this.setState({variantType}, () => this.getBomcodes(this.state.productID));
    }

    toggleType(val) {
        this.setState({variantType: val}, () => this.getBomcodes(this.state.productID));
    }

    toggleCollapse(index) {
        let {bomCodesWithAccount} = this.state;
        bomCodesWithAccount[index].isOpen = !bomCodesWithAccount[index].isOpen;
        this.setState({bomCodesWithAccount});
    }

    toggle(tab) {
        if (tab) {
            if (this.state.activeTab !== tab) {
                this.setState({activeTab: tab});
                this.createCollapses(tab);
            }
        }
    }

    createCollapses(BomCode) {
        this.setState({isCollapsing: true});
        productConfigurationService.getDistinctBomCodesBasedOnProductIdWithAccount(this.state.productID, BomCode, this.state.variantType).then(response => {
            if (response.data) {
                let data = response.data;
                if (data.length > 0) {
                    if (["07500", "07501", "07539", "07545", "07546", "07554", "07555"].includes(BomCode)) {
                        data = data.filter(d => d.accountID === "01500");
                    } else {
                        data = data.filter(d => d.accountID !== "01500");
                    }
                    data[0].isOpen = true;
                }
                this.setState({bomCodesWithAccount: data, isCollapsing: false});
            }

        }).catch(error => {
            this.setState({isCollapsing: false});
            toast.error(handleErrorMessage(error));
        });
    }

    openImportExportModal(type) {
        this.setState({isOpenImportExportModal: true, importExportModalType: type});
    }

    toggleImportExportModal(data) {
        this.setState({isOpenImportExportModal: false});
    }

    openDeleteConfirmationModal(item) {
        this.setState({isOpenDeleteProductConfigurationModal: true, selectedItemProductConfigurationPrice: item});
    }

    toggleDeleteProductConfigurationModal(data) {
        this.setState({isOpenDeleteProductConfigurationModal: data});
    }

    handleDeleteProductConfigurationFabric() {
        let { selectedItemProductConfigurationPrice, bomCodesWithAccount, variantType } = this.state;
        let index = findIndex(bomCodesWithAccount, 'accountID', selectedItemProductConfigurationPrice.accountID);

        this.setState({ deletingProductConfiguration: true });
        ProductConfigurationService.deleteProductConfigurationPriceMatrix(selectedItemProductConfigurationPrice.bomCode, selectedItemProductConfigurationPrice.accountID, variantType).then(response => {
            if (response.status === 200) {
                toast.success("Deleted");
                bomCodesWithAccount.splice(index, 1);
                this.setState({
                    bomCodesWithAccount,
                    deletingProductConfiguration: false,
                    isOpenDeleteProductConfigurationModal: false
                });
            }
        }).catch(error => {
            this.setState({deletingProductConfiguration: false});
            toast.error(handleErrorMessage(error));
        })
    }

    render() {
        let {bomcodes, bomCodesWithAccount, activeTab, isCollapsing, productID, isOpenDeleteProductConfigurationModal, canDeleteProductionConfiguration, variantType, variants} = this.state;

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/products/all/list?activeTab=product')}>Products</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push("/inventory/product/configuration?" + queryString.stringify({productID: productID}))}>Configuration</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Pricing</BreadcrumbItem>
                </Breadcrumb>
                <Row className={"mb-2"}>
                    <Col>
                        <h5>Pricing matrix</h5>
                    </Col>
                    <Col>

                        <div className={"text-right"}>
                            <InputGroup>
                                <Input
                                    className="mr-2"
                                    type="select"
                                    value={variantType}
                                    onChange={(e) => this.handleVariantChange(e)}
                                    name="variantType"
                                    placeholder="select type here...">
                                    {(variants || []).map((item, ind) => <option key={ind}
                                                                                 value={item.value}>{item.label}</option>)
                                    }
                                </Input>

                                <Button color='primary'
                                        size='sm'
                                        onClick={() => this.openImportExportModal('Import')}>
                                    <i className="fa fa-upload mr-2"/> Import
                                </Button>
                                <Button
                                    color='success'
                                    size='sm'
                                    className={"ml-2"}
                                    onClick={() => this.openImportExportModal('Export')}>
                                    <i className="fa fa-download mr-2"/> Export
                                </Button>
                            </InputGroup>

                        </div>
                    </Col>
                </Row>
                {bomcodes.length !== 0 ?
                    <Card>
                        <CardHeader>
                            <Nav tabs card>
                                {(bomcodes || []).map((tab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink className={classnames({active: activeTab === tab})}
                                                 onClick={() => {
                                                     this.toggle(tab);
                                                 }}>
                                            <Button size={"sm"} color={"link"} style={{textDecoration: "none"}}>
                                                {tab}&nbsp;
                                            </Button>
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            {isCollapsing
                                ? <Spinner color="primary"/>
                                : <div>
                                    {(bomCodesWithAccount || []).map((item, index) =>
                                        <Card key={index} className="mb-2">
                                            <CardHeader>
                                                <Row>
                                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                        <Button color="link"
                                                                onClick={() => this.toggleCollapse(index)}
                                                                size="sm">{(item.accountID) ? (item.accountID + " | " + item.customer) : "Default"}
                                                        </Button>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                        <div className='text-right'>
                                                            {
                                                                canDeleteProductionConfiguration
                                                                    ? <Button color='danger'
                                                                              size={"sm"}
                                                                              onClick={() => this.openDeleteConfirmationModal(item)}><i
                                                                        className='fa fa-trash'/></Button>
                                                                    : null
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <Collapse isOpen={item.isOpen}>
                                                <CardBody>
                                                    {
                                                        item.isOpen ?
                                                            <PriceMatrixComponent
                                                                productID={productID}
                                                                BomCode={item.bomCode}
                                                                accountID={item.accountID}
                                                                variant={ variantType}
                                                            />
                                                            : null
                                                    }
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    )}
                                </div>
                            }
                        </CardBody>
                    </Card>
                    : null
                }

                {this.state.isOpenImportExportModal
                    ? <ProductConfigurationPricingMatrixImportExportModal
                        refresh={this.getBomcodes}
                        isOpen={this.state.isOpenImportExportModal}
                        toggle={this.toggleImportExportModal}
                        type={this.state.importExportModalType}
                        productID={this.state.productID}
                    />
                    : null
                }

                {isOpenDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete product configuration price matrix'}
                        isOpen={isOpenDeleteProductConfigurationModal}
                        toggle={this.toggleDeleteProductConfigurationModal}
                        handleDelete={this.handleDeleteProductConfigurationFabric}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}

                <ToastContainer/>
            </div>
        );
    }
}