import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {toast} from 'react-toastify';
import '../../assets/css/app/experiment.css'

export default class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageFile: {
                fileName: null,
                showImage: false
            },
            loading: false,
            imageMaxSize: this.props.imageMaxSize,
            imageMinSize: this.props.imageMinSize
        };
        this.onDrop = this.onDrop.bind(this);
    }


    onDrop(files, rejectedFiles) {
        let { imageFile, imageMinSize, imageMaxSize } = this.state;
        this.setState({ showImage: true });
        if (files[0].size < imageMinSize || files[0].size > imageMaxSize) {
            toast.error('Choose Logo Image between ' + imageMinSize / 1000 + ' to ' + imageMaxSize / 1000 + ' Kb');
            this.setState({showImage: false});
        } else {
            imageFile.fileName = files[0].name;
            this.setState({imageFile, showImage: true});
            this.props.handleSelection(files[0]);
        }
    }

    render() {
        let {imageFile, showImage} = this.state;
        return (
            <Dropzone onDrop={this.onDrop} className="dropzone" accept='image/*'>
                {({getRootProps, getInputProps}) => (
                    <section className="container">
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            {showImage ? <p>{imageFile.fileName}</p>
                                : <div>
                                    <p className={"dropHeading"}>Drag 'n' drop file here, or click to select files</p>
                                </div>}
                        </div>
                    </section>
                )}
            </Dropzone>
        );
    }
}