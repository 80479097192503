import React, {Component} from 'react';
import FileSaver from 'file-saver';
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Button,
    ButtonGroup
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import CustomerService from "../../services/CustomerService";
import FaqService from "../../services/FaqService";
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';
import ReactPlayer from 'react-player'
import AddFAQSuggestion from './AddFAQSuggestion';

const baseUrl = window.location.origin;

export default class FAQPageList extends Component {
    constructor(props) {
        super(props);
        let tabs = [
            {
                key: "1",
                type: "Indoor",
                label: "Roller",
            },
            {
                key: "2",
                type: "Outdoor",
                label: "Ziptrack",
            },
            {
                key: "3",
                type: "Indoor",
                label: "Tracks",
            },
            {
                key: "4",
                type: "Indoor",
                label: "Venetians",
            },
            {
                key: "5",
                type: "Indoor",
                label: "Warranty",
            },
            {
                key: "6",
                type: "Outdoor",
                label: "Folding Arm Awnings",
            },
            {
                key: "7",
                type: "Outdoor",
                label: "Warranty",
            },
            {
                key: "8",
                type: "Indoor",
                label: "Motorisation",
            },
            {
                key: "9",
                type: "Outdoor",
                label: "Motorisation",
            }
        ];

        this.state = {
            records: [],
            activeFilterTab: "1",
            searchText: "",
            IsOutdoor: false,
            collapse: -1,
            tabs: tabs,
            isOpenSuggestModal: false,
        };

        this.CustomerService = new CustomerService();
        this.faqService = new FaqService();
        this.handleChange = this.handleChange.bind(this);
        this.toogleCollapse = this.toogleCollapse.bind(this);
        this.toggleOutdoorIndoor = this.toggleOutdoorIndoor.bind(this);
        this.toggleFilterTab = this.toggleFilterTab.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    toggleFilterTab(item) {
        this.setState({activeFilterTab: item});
    }

    downloadFile(item) {
        /*console.log(item, 'attachment');*/
        this.faqService.getFaqByName(item.fileName).then(response => {
            FileSaver.saveAs(response.data, item.description);
        }).catch(error => {
            console.log(error.data)
        });
    }

    componentDidMount() {
        this.refresh();
    }

    toggleOutdoorIndoor() {
        this.setState({IsOutdoor: !this.state.IsOutdoor});
    }

    refresh() {
        this.faqService.fetchCustomerViewFaqs().then(response => {
            if (response.data) {
                this.setState({records: response.data});
            }
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    renderTabwise(f, category, mode, searchText) {
        let flag = true;
        if (searchText) {
            if (flag) {
                if (f.primaryText) {
                    flag = (f.primaryText.toLowerCase().includes(searchText.toLowerCase()))
                }
                if (f.primaryText && f.secondaryText) {
                    flag = (f.primaryText.toLowerCase().includes(searchText.toLowerCase()) || f.secondaryText.toLowerCase().includes(searchText.toLowerCase()))
                }
            }
            return flag;
        } else {

            if (flag && f.subCategory && category) {
                flag = (f.subCategory.toLowerCase().includes(category.toLowerCase()) && f.category.toLowerCase().includes(mode.toLowerCase()))
            }
            return flag;
        }
    }


    handleChange(value) {
        this.setState({searchText: value});
    }

    toogleCollapse(value) {
        if (this.state.collapse === value) {
            value = -1;
        }
        this.setState({collapse: value});
    }

    openModal() {
        this.setState({isOpenSuggestModal: true});
    }

    toggleModal(data) {
        this.setState({isOpenSuggestModal: data});
    }
    contentTypeCase(attachment)
    {
        switch (attachment.linkContentType) {
            case "Video" :return (<ReactPlayer url={attachment.link} playing={false} controls={true} stopOnUnmount={true} light={true} width='300px' height='210px'/>);break;
            case "Image" :return (<img src={attachment.link} />);break;
            default   :   return (<a href={attachment.link}> {attachment.title}</a>);break;
        }
    }

    renderLinks(attachment,indexx){

        return(
            <Col xl={3} lg={3} md={4} sm={6} xs={12}
                 className="mt-1 mb-1" key={indexx}>
                <div className={"text-center"}>
                    <span>
                {this.contentTypeCase(attachment)}
                <br/>
                {attachment.title}
                    </span>
                </div>
            </Col>
        )
    }


    renderTabsData(tabName, type, searchText) {
        let data = this.state.records;
        let tabtext = tabName;
        const isCollapse = this.state.collapse;
        return (
            <div>
                {(data || []).filter((item) => this.renderTabwise(item, tabtext, type, searchText)).map((item, index) =>
                    <div key={index}>
                        <ListGroup>
                            <ListGroupItem tag="button" action
                                           onClick={() => this.toogleCollapse(index, "collapse")}>
                                <div>
                                    <Row>
                                        <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                            <h6>{item.primaryText}</h6>
                                        </Col>
                                        <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                            <div className="text-right">

                                                <i className={classnames("fa", {
                                                        "fa-chevron-up": isCollapse !== index,
                                                        "fa-chevron-down": isCollapse === index,
                                                    }
                                                )} aria-hidden="true"/>
                                            </div>
                                        </Col>

                                    </Row>

                                </div>
                            </ListGroupItem>
                            <Collapse isOpen={isCollapse === index}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <p>{item.secondaryText}</p>
                                            <Row>
                                                {(item.attachments || []).map((attachment, indexx) => {
                                                    return (
                                                        attachment.snapFileName ?
                                                            <Col xl={3} lg={3} md={4} sm={6} xs={12}
                                                                 className="mt-1 mb-1" key={indexx}>
                                                                <div className={"text-center"}>
                                                                <span className="relative pdf-border">
                                                                    <img title="Click to download"
                                                                         src={baseUrl + "/api/file/Generate/FAQFile?FileName=" + attachment.snapFileName}
                                                                         style={{'height': '250px', 'width': '211px'}}
                                                                         onClick={() => this.downloadFile(attachment)}/>
                                                                    <img src="/img/logo/download-icon.svg"
                                                                         alt="download" width="15" height="15"
                                                                         className="download-icon"/>
                                                                    <p>{attachment.title}</p>
                                                                </span>
                                                                </div>
                                                            </Col>

                                                            : this.renderLinks(attachment,indexx)
                                                    )
                                                })}
                                                {
                                                    item.youtubeLink
                                                        ?
                                                        <Col xl={3} lg={3} md={4} sm={12} xs={12} className="mt-1 mb-1">
                                                            <div>
                                                                <ReactPlayer
                                                                    url={item.youtubeLink}
                                                                    playing={false}
                                                                    controls={true}
                                                                    stopOnUnmount={true}
                                                                    light={true}
                                                                    width='300px'
                                                                    height='210px'
                                                                />
                                                            </div>
                                                        </Col>
                                                        : null
                                                }
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Collapse>
                        </ListGroup>
                    </div>
                )}
            </div>
        );
    }

    render() {
        let {tabs, activeFilterTab, searchText} = this.state;
        let Indoortabs = tabs.filter((val, indexx, arr) => {
            return val.type === "Indoor"
        });
        let Outdoortabs = tabs.filter((val, indexx, arr) => {
            return val.type === "Outdoor"
        });
        return (
            <div>

                {
                    /*search area div starts*/
                }

                <div className="text-center">
                    <h4>How can we help you ?</h4>
                </div>

                <Row className={"mt-2 mb-4"}>
                    <Col xl={2} lg={2} md={2} sm={0} xs={0}/>
                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search"
                                       aria-hidden="true"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                size={"lg"}
                                type={"text"}
                                name={"searchText"}
                                placeholder={"Describe your issue"}
                                value={searchText}
                                onChange={(e) => this.handleChange(e.target.value)}
                            />
                            <InputGroupAddon addonType="append" style={{cursor: 'pointer'}}
                                             onClick={e => this.setState({searchText: ''})}>
                                <InputGroupText>
                                    <i className="fa fa-times"
                                       aria-hidden="true"/>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>

                    </Col>
                </Row>
                {
                    /*search area div starts*/
                }


                {(() => {
                    if (!searchText) {
                        return (
                            <div>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <ButtonGroup>
                                            <Button outline={this.state.IsOutdoor} color="primary"
                                                    onClick={this.toggleOutdoorIndoor}>
                                                Indoor
                                            </Button>
                                            <Button outline={!this.state.IsOutdoor} color="primary"
                                                    onClick={this.toggleOutdoorIndoor}>
                                                Outdoor
                                            </Button>
                                        </ButtonGroup>
                                    </Col>

                                </Row>

                                {
                                    /*<button type="button" className="btn btn-sm btn-outline-primary mr-1" style={{
                                    backgroundColor: (!this.state.IsOutdoor ? '#00AEEF' : ''),
                                    'color': (!this.state.IsOutdoor ? 'white' : '')
                                }} onClick={this.toggleOutdoorIndoor}>Indoor
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-primary mr-1" style={{
                                    backgroundColor: (this.state.IsOutdoor ? '#00AEEF' : ''),
                                    'color': (this.state.IsOutdoor ? 'white' : '')
                                }} onClick={this.toggleOutdoorIndoor}>Outdoor
                                </button>*/
                                }
                            </div>
                        )
                    }
                })()}


                <Card className={"mt-1 shadow-0 border"}>
                    {(() => {
                        if (!searchText) {
                            return (
                                <CardHeader>
                                    <Nav tabs card>
                                        {!(this.state.IsOutdoor) ? Indoortabs.map((tab, index) =>
                                                <NavItem
                                                    className={"hoverableItem"}
                                                    key={index}>
                                                    <NavLink
                                                        className={classnames({active: activeFilterTab === tab.key})}
                                                        onClick={() => {
                                                            this.toggleFilterTab(tab.key);
                                                        }}>
                                                        <Button style={{textDecoration: "none"}}
                                                                size={"sm"} color={"link"}>{tab.label}&nbsp;</Button>
                                                    </NavLink>
                                                </NavItem>
                                            ) :
                                            Outdoortabs.map((tab, index) =>
                                                <NavItem
                                                    className={"hoverableItem"}
                                                    key={index}>
                                                    <NavLink
                                                        className={classnames({active: activeFilterTab === tab.key})}
                                                        onClick={() => {
                                                            this.toggleFilterTab(tab.key);
                                                        }}>
                                                        <Button style={{textDecoration: "none"}}
                                                                size={"sm"} color={"link"}>{tab.label}&nbsp;</Button>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        }
                                    </Nav>
                                </CardHeader>
                            )
                        }
                    })()}

                    <CardBody>
                        <TabContent activeTab={this.state.activeFilterTab}>
                            {tabs.map((tab, index) =>
                                <TabPane tabId={tab.key} key={index}>
                                    {this.renderTabsData(tab.label, tab.type, searchText)}
                                </TabPane>
                            )}
                        </TabContent>
                    </CardBody>
                </Card>
                <br/>
                <div className="text-right">
                    <Button color="primary" size="sm" onClick={this.openModal}> Any suggestions<i
                        className="fa fa-question-circle ml-2" aria-hidden="true"/></Button>
                </div>
                <AddFAQSuggestion
                    isOpen={this.state.isOpenSuggestModal}
                    toggleModal={this.toggleModal}/>
                <ToastContainer/>
            </div>
        );
    }
}