import {authHeader} from '../../helpers/auth-header';
import {config} from '../../helpers/config'
import axios from '../axios';
import axiosFileDownloader from "../axiosFileDownloader";

export default class SalesOrderService {


    fetchSalesOrder(req, loadMore) {

        const requestBody = {
            createdDateBetween: req.filter.createdDateBetween,
            accountId: req.filter.accountId,
            selectedPage: loadMore ? req.filter.selectedPage + 1 : req.filter.selectedPage,
            pageSize: req.filter.pageSize,
            query: req.filter.query,
            statusID: req.filter.statusID,
            orderNum: req.filter.orderNum,
            repCode: req.filter.repCode,
            branchNum: req.filter.branchNum,
            sortingKey: req.sort.key,
            sortingAscending: req.sort.ascending
        };
        return axios.post('api/sales/orders/orders', requestBody)
    }

    fetchSalesOrderCancelReasons() {
        return axios.get('api/sales/orders/quotes/cancel/reasons')
    }

    cancelQuotes(requestBody) {
        return axios.post('api/sales/orders/quotes/cancel', requestBody)
    }

    convertQuotesToPickingSlips(requestBody) {
        return axios('api/sales/orders/quotes/convert/to/pickingSlip', requestBody)
    }

    exportOrders(req) {

        const requestBody = {
            createdDateBetween: req.filter.createdDateBetween,
            accountId: req.filter.accountId,
            pageSize: req.filter.pageSize,
            query: req.filter.query,
            statusID: req.filter.statusID,
            orderNum: req.filter.orderNum,
            repCode: req.filter.repCode,
            branchNum: req.filter.branchNum
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            responseType: 'blob',
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/sales/orders/orders/export'
        };
        return axios(request)
    }


    fetchLastTenRecentlySubmittedOrders() {
        return axios.get('api/sales/orders/orders/recentlysubmitted')
    }

    getProductGroups() {
        return axios.get('api/product/group/list');
    }

    getSalesOrderKeywayByDebtorInvoiceOrderNum(ordNum) {
        return axios.get('api/keyway/sales-order/one/by/debtor-invoice-order-number?ordNum=' + ordNum)
    }

    getSalesOrderAttachmentID(ordNum) {
        return axios.get('api/keyway/sales-order/order/attachmentID?OrdNum=' + ordNum);
    }

    getInvoiceAttachmentID(ordNum) {
        return axios.get('api/keyway/sales-order/invoice/attachmentID?OrdNum=' + ordNum);
    }

    getOutstandingOrderSummary() {
        return axios.get('api/dashboard/outstanding/order/summary');
    }

    getRecentSalesByStock(prodCode) {
        return axios.get('api/sales/orders/getRecents?Prodcode=' + prodCode);
    }

    StockSalesbyYearLineChart(prodCode, years) {
        return axios.get('api/keyway/sales-order/monthly-sales/lineChart?ProdCode=' + prodCode + '&years=' + years);
    }

    StockSalesbyValueLineChart(prodCode, years) {
        return axios.get('api/keyway/sales-order/monthly-sales/byValue/lineChart?ProdCode=' + prodCode + '&years=' + years);
    }

    getSalesOrderItemPartData(requestBody, prodCode) {
        return axios.post('api/sales/orders/item/part/list?prodCode=' + prodCode, requestBody);
    }

    getDebtorInvoiceItemData(requestBody, prodCode) {
        return axios.post('api/sales/orders/swish/item/part/list?prodCode=' + prodCode, requestBody);
    }

    updateConsumerDetail(request) {
        return axios.put('api/keyway/sales-order/update/consumer/detail', request);
    }

    generatePBOrderEnteredBy() {
        return axiosFileDownloader.get('api/keyway/sales-order/product/builder/order/entered/by/export');
    }

    getAccountIdFromSalesOrder(ordNum) {
        return axios.get("api/sales/orders/getAccountId?ordNum=" + ordNum);
    }

}