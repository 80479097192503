import React, {Component} from 'react';
import {
    Col,
    Row, Label, Button, ButtonGroup
} from 'reactstrap';


export default class OrderNumSorter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: [
                {
                    name: "OrderNum-asc",
                    displayName: "Order No",
                    key: "OrderNum",
                    ascending: false,
                    displayValue: "High to Low",

                }, {
                    name: "OrderNum-desc",
                    displayName: "Order No",
                    key: "OrderNum",
                    ascending: true,
                    displayValue: "Low to High"
                }
            ]
        };

        this.updateSorter = this.updateSorter.bind(this);
    }


    updateSorter(selectedOption) {
        let reload = false;
        this.props.onChange(selectedOption, reload);
    }


    render() {

        let {options} = this.state;
        const selected = this.props.selected;
        return (
            <div>
                <ButtonGroup>
                    {options.map((option, index) =>
                        <Button key={index} outline={option.name !== selected.name}
                                color={"primary"}
                                className={"btn"}
                                onClick={() => this.updateSorter(option)}>{option.displayValue}</Button>
                    )}
                </ButtonGroup>

            </div>
        );
    }
}
