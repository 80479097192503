import React, {Component} from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Button,
    Label,
    Input,
    Form,
    FormGroup,
    Spinner
} from 'reactstrap';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Editor from '../../store/ReactQuillEditorConfig';

const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

export default class EmailFollowUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: {
                fromEmail: "",
                senderName: "",
                toEmail: this.props.emails,
                ccEmail: "",
                subject: "",
                body: "",
                orderNumbers: this.props.ordernumbers
            },
            emailSending: this.props.emailSending,
            formErrors: {},
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.messageBodyChanged = this.messageBodyChanged.bind(this);
    }

    componentDidMount() {
        let loggedInUser = JSON.parse(localStorage.getItem('user'));
        var fullName = loggedInUser.firstName;
        if (loggedInUser.lastName) {
            fullName = fullName + " " + loggedInUser.lastName;
        }
        //this.setState({fromEmail:loggedInUser.emailAddress});
        let {message} = this.state;
        message.subject = "Windoware | Quote- @OrderNum Follow-up";
        //message.fromEmail=loggedInUser.emailAddress;
        message.senderName = fullName;
        message.body = "Dear @Company," +'\n'+
            "Thank you for giving us the opportunity to quote on your job." + '\n'
            + "I have noticed that it is still pending in our system and I was wondering If I could offer you further assistance with the quote." + '\n'
            + "I have attached a copy for your reference and will hold it in the system until I hear back from you." + '\n'
            + "I look forward to hearing from you soon." + '\n'
            + "Have a great day!" + '\n\n'
            + "Kind Regards," + '\n'
            +  fullName + '\n'
            + "Phone: 61 7 3299 3788" + '\n'
            + "Email: sales@windoware.com.au"

        this.setState({message});
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.emailSending !== nextProps.emailSending) {
            this.setState({emailSending: nextProps.emailSending});
        }
    }

    messageBodyChanged(change) {
        let {message} = this.state;
        message.body = change;
        this.setState({message});
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {message} = this.state;
        message[name] = value;
        this.setState({message});
    }


    validateEmail(messg) {
        let formErrors = {};
        let valid = true;

        if (messg.fromEmail.length === 0) {
            formErrors.fromEmail = "Please enter from email";
            valid = false;
        }

        if (messg.toEmail.length === 0) {
            formErrors.toEmail = "Please enter to email";
            valid = false;
        }

        if (messg.subject.length === 0) {
            formErrors.subject = "Please enter subject";
            valid = false;
        }

        if (messg.body.length === 0) {
            formErrors.body = "Please enter body";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let result = this.validateEmail(this.state.message);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            let message = { ...this.state.message };
            message.body = message.body.replaceAll('\n', '<br/>');
            this.props.handleChange(message);
        }
    }

    render() {
        let {message, emailSending, formErrors} = this.state;
        message.fromEmail = "keyway@windoware.com.au";
        let {isOpen, toggle} = this.props;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Message
                </ModalHeader>
                <ModalBody style={{minHeight: 300}}>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label>From Email</Label>
                                    <Input
                                        className="form-control"
                                        name="fromEmail"
                                        type="text"
                                        disabled
                                        value={message.fromEmail}
                                        onChange={this.handleChange}
                                        size={"sm"}/>
                                </FormGroup>
                            </Col>

                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label>To Email</Label>
                                    <Input
                                        invalid={formErrors.toEmail !== undefined}
                                        className="form-control"
                                        name="toEmail"
                                        type="text"
                                        value={message.toEmail}
                                        onChange={this.handleChange}
                                        size={"sm"}
                                        placeholder="Write to email here..."/>
                                </FormGroup>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label>CC</Label>
                                    <Input
                                        className="form-control"
                                        name="ccEmail"
                                        type="text"
                                        value={message.ccEmail}
                                        onChange={this.handleChange}
                                        size={"sm"}
                                        placeholder="write cc email here..."/>
                                </FormGroup>
                            </Col>

                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label>Subject*</Label>
                                    <Input
                                        type="text"
                                        invalid={formErrors.subject !== undefined}
                                        className="form-control"
                                        name="subject"
                                        value={message.subject}
                                        onChange={this.handleChange}
                                        size={"sm"}
                                        placeholder="Write subject here..."/>
                                </FormGroup>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label>Body*</Label>
                                    <Input type="textarea"
                                        name="body"
                                        rows='12'
                                        invalid={formErrors.body !== undefined}
                                        placeholder="Write body here..."
                                        value={message.body} onChange={this.handleChange} />

                                    {/*<div className="quil-editor-root ">
                                        <ReactQuill value={message.body}
                                                    modules={Editor.modules}
                                                    formats={Editor.formats}
                                                    name="body"
                                                    onChange={(change) => this.messageBodyChanged(change)}
                                                    bounds={'.quil-editor-root'}/>
                                    </div>*/}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color='primary' onClick={this.handleSubmit} disabled={emailSending}>
                            {emailSending ? <Spinner size="sm"
                                                     className={"mr-2"}
                                                     style={{color: "white"}}/> : null}
                            Send
                        </Button>
                        {' '}
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}