import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import keywayStockService from '../../services/KeywayStockService';
import cloneDeep from 'lodash/cloneDeep';

export default class SearchStockKeyway extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultProdCode: props.defaultProdCode,
            prodCode: props.selected,
            stocks: [],
            selected: props.selected ? {label: props.selected, value: props.selected} : null,
            isLoadingOptions: false,
            isLoadingSelected: false,
            filter: {
                bmFlag: props.filter.bmFlag,
                excludeDiscontinued: props.filter.excludeDiscontinued,
                excludeSoldOut: props.filter.excludeSoldOut,
                stockMasterGroupCodes: props.filter.stockMasterGroupCodes,
                stockGroupCodes: props.filter.stockGroupCodes,
                colour: props.filter.colour,
                excludeOffRange: props.filter.excludeOffRange,
                includeFabric: props.filter.includeFabric,
                excludeArchived: props.filter.excludeArchived,
                excludeOutOfStock: props.filter.excludeOutOfStock,
                isNonStock: props.filter.isNonStock,
            }
        }

        this.loadOptions = this.loadOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.defaultProdCode || this.state.prodCode);
    }

    componentWillReceiveProps(nextProps) {
        if(!this.state.isLoadingSelected){
            if (this.state.defaultProdCode !== nextProps.defaultProdCode) {
                if (nextProps.defaultProdCode) {
                    this.setState({defaultProdCode: nextProps.defaultProdCode}, () => {
                        this.fetchOptions(nextProps.defaultProdCode);
                    })
                }
            } else {
                if (this.state.prodCode !== nextProps.selected) {
                    this.setState({
                        prodCode: nextProps.selected,
                        selected: nextProps.selected
                            ? {label: nextProps.selected, value: nextProps.selected}
                            : null
                    }, () => {
                        //load externally selectedItem
                        this.fetchOptions(nextProps.prodCode);
                    })
                } else {
                    let {filter} = this.state;
                    if (
                        filter.bmFlag !== nextProps.filter.bmFlag
                        || filter.excludeDiscontinued !== nextProps.filter.excludeDiscontinued
                        || filter.excludeSoldOut !== nextProps.filter.excludeSoldOut
                        || JSON.stringify(filter.stockMasterGroupCodes) !== JSON.stringify(nextProps.filter.stockMasterGroupCodes)
                        || JSON.stringify(filter.stockGroupCodes) !== JSON.stringify(nextProps.filter.stockGroupCodes)
                        || filter.colour !== nextProps.filter.colour
                        || filter.excludeOffRange !== nextProps.filter.excludeOffRange
                        || filter.includeFabric !== nextProps.filter.includeFabric
                        || filter.excludeArchived !== nextProps.filter.excludeArchived
                        || filter.excludeOutOfStock !== nextProps.filter.excludeOutOfStock
                        || filter.isNonStock !== nextProps.filter.isNonStock
                    ) {

                        filter.bmFlag = nextProps.filter.bmFlag;
                        filter.excludeDiscontinued = nextProps.filter.excludeDiscontinued;
                        filter.excludeSoldOut = nextProps.filter.excludeSoldOut;
                        filter.stockMasterGroupCodes = nextProps.filter.stockMasterGroupCodes;
                        filter.stockGroupCodes = nextProps.filter.stockGroupCodes;
                        filter.colour = nextProps.filter.colour;
                        filter.excludeOffRange = nextProps.filter.excludeOffRange;
                        filter.includeFabric = nextProps.filter.includeFabric;
                        filter.excludeArchived = nextProps.filter.excludeArchived;
                        filter.excludeOutOfStock = nextProps.filter.excludeOutOfStock;
                        filter.isNonStock = nextProps.filter.isNonStock;
                        this.setState({filter}, () => {
                            this.fetchOptions("");
                        });

                    }
                }
            }
        }
    }


    handleSelect(selectedStock, event) {
        let selected = selectedStock;
        let prodCode = selectedStock ? selectedStock.prodCode : "";

        switch (event) {
            case "selected":
                this.setState({selected, prodCode});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, prodCode});
                if (prodCode) {
                    this.setState({isLoadingSelected: true});
                    keywayStockService.getStock(prodCode).then(response => {
                        let res = response.data.wwStock;
                        res.value = response.data.value;
                        res.label = response.data.label;
                        delete response.data.value;
                        delete response.data.label;
                        delete response.data.wwStock;
                        res.keywayStock = response.data;
                        res.keywayStock.wwStock = null;

                        let selected = res;
                        selected.value = selected.prodCode;
                        selected.label = selected.prodCode + (selected.keywayStock ? (selected.keywayStock.description ? " | " + selected.keywayStock.description + (selected.keywayStock.colour ? " | " + selected.keywayStock.colour : "") : "") : "");
                        this.setState({ selected: selected, isLoadingSelected: false }, () => {
                            this.props.handleSelection(res);
                        });
                    }).catch(error => {
                        this.setState({isLoadingSelected: false});
                        console.error(error);
                    })
                } else {
                    this.props.handleSelection(null);
                }
        }
        if (!prodCode) {
            this.fetchOptions("");
        }
    };


    fetchOptions(inputValue, callback) {
        let stocks = [];
        let {filter} = this.state;
        let requestBody = {
            searchText: inputValue,
            bm_Flag: filter.bmFlag,
            excludeDiscontinued: filter.excludeDiscontinued,
            excludeSoldOut: filter.excludeSoldOut,
            stockMasterGroupCodes: filter.stockMasterGroupCodes,
            stockGroupCodes: filter.stockGroupCodes,
            colour: filter.colour,
            excludeOffRange: filter.excludeOffRange,
            includeFabric: filter.includeFabric,
            excludeArchived: filter.excludeArchived,
            excludeOutOfStock: filter.excludeOutOfStock,
            isNonStock: filter.isNonStock,
        };

        keywayStockService.getStocks(requestBody).then(response => {
            let data = response.data;
            for (let i in data) {
                let temp = {
                    label: data[i].prodCode + (data[i].description ? " | " + data[i].description : "") + (data[i].colour ? " | " + data[i].colour : "") + (data[i].code?" | "+data[i].code:""),
                    value: data[i].prodCode,
                    id: data[i].prodCode,
                    prodCode: data[i].prodCode,
                };
                stocks.push(cloneDeep(temp));
                if (this.props.defaultProdCode && ( (!this.state.selected) || (this.props.defaultProdCode !== this.state.selected.value))) {
                    if (data[i].prodCode === this.props.defaultProdCode) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.state.prodCode) {
                    if (data[i].prodCode === this.state.prodCode) {
                        this.handleSelect(temp, "selected");
                    }
                }
            }
            this.setState({stocks});
            if (callback) {
                return callback(stocks)
            } else {
                return stocks;
            }
        }).catch(error => {
            console.error(error)
        });
    }

    loadOptions(inputValue, callback) {
        inputValue = inputValue.trim();
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 0) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.stocks);
            }
        }
    };


    render() {
        let {selected, isLoadingOptions, stocks} = this.state;
        let {disabled} = this.props;
        return (
            <AsyncSelect
                isDisabled={disabled}
                isLoading={isLoadingOptions}
                isClearable={true}
                defaultOptions={stocks}
                loadOptions={this.loadOptions}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                value={selected}
                placeholder={"Search..."}
            />
        )
    }
}