import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter,
    Table,
    Button, Badge,
    Row, Col,
    InputGroup, InputGroupText, InputGroupAddon, Input,
    Spinner,
} from 'reactstrap';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import NumberFormat from "react-number-format";
import packagingHandlingChargesService from '../../services/shipment/PackagingHandlingChargesService'
import productService from '../../services/product/ProductService'
import { findIndex,handleErrorMessage } from '../../services/CommonService'
import queryString from 'query-string';

export default class CustomerPackageHandling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rules: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 10
                    },
                    sortRequest: {
                        key: "ID",
                        direction: false
                    },
                    filterRequest: {
                        id: 0,
                        accountID: "",
                        productGroupId: undefined,
                        charge: undefined,
                        isActive: undefined
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            accountId:'',
            products: [],
            loadingRules: false,
            loadingProducts: false,
        };

        this.getRules = this.getRules.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        
        this.getColValue = this.getColValue.bind(this);
    }

    componentDidMount() {
        let { rules, accountId } = this.state;
        accountId = this.props.accountId;
        rules.request.filterRequest.accountID = this.props.accountId;
        this.setState({ accountId, rules });
        this.getRules(rules);
        this.getProducts();
        
    }

    componentWillReceiveProps(nextProps) {
        let { rules, accountId } = this.state;
        if (accountId !== nextProps.accountId) {
            accountId = nextProps.accountId;
            rules.request.filterRequest.accountID = nextProps.accountId;
            this.getRules(rules);
            this.setState({ accountId, rules });
        }
    }

    

    getProducts() {
        this.setState({ loadingProducts: true });
        productService.getProductGroups().then(response => {
            this.setState({ products: response.data, loadingProducts: false });
        }).catch(error => {
            this.setState({ loadingProducts: false });
            toast.error(handleErrorMessage(error));
        })
    }

    getRules(rules) {
        if (rules.request.filterRequest.accountID) {
            this.setState({ loadingRules: true });
            packagingHandlingChargesService.fetchRules('customer', cloneDeep(rules.request)).then(response => {
                rules.response = response.data;
                this.setState({ rules, loadingRules: false });
            }).catch(error => {
                this.setState({ loadingRules: false });
                toast.error(handleErrorMessage(error));
            })
        }
    }

    handleStoreChange(change, key) {
        let { rules } = this.state;
        switch (key) {
            case "sortKey":
                if (rules.request.sortRequest.key === change) {
                    rules.request.sortRequest.direction = !rules.request.sortRequest.direction;
                } else {
                    rules.request.sortRequest.key = change;
                    rules.request.sortRequest.direction = false;
                }
                this.getRules(rules);
                break;
            case "pageSize":
                rules.request.pageRequest[key] = change;
                this.getRules(rules);
                break;
            case "currentPage":
                rules.request.pageRequest[key] = change;
                this.getRules(rules);
                break;
            default:
                rules.request.filterRequest[key] = change;
                this.setState({ rules });
                this.getRules(rules);
        }

    }

    getStore() {
        let { products, rules } = this.state;
        let { filterRequest } = rules.request;

        return [
            {
                key: "productGroupId",
                label: "Product",
                type: "productGroupId",
                colSpan: 1,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"select"}
                        name={"productGroupId"}
                        value={filterRequest.productGroupId}
                        onChange={(e) => this.handleStoreChange(e.target.value, "productGroupId")}>
                        <option value={""}>All</option>
                        {products.map((option, index) =>
                            <option key={index} value={option.productGroupId}>{option.name}</option>
                        )}
                    </Input>
                </div>
            }, {
                key: "charge",
                label: "Charge for 1 Qty",
                type: "currency",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input type={"search"}
                            name={"charge"}
                            value={filterRequest.charge}
                            onChange={(e) => this.handleStoreChange(e.target.value, "charge")}>
                        </Input>
                    </InputGroup>
                </div>
            },
            {
                key: "isActive",
                label: "Is Active",
                type: "isActive",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "text-centre",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"select"}
                        name={"isActive"}
                        value={filterRequest.isActive}
                        onChange={(e) => this.handleStoreChange(e.target.value, "isActive")}>
                        <option value={null}>All</option>
                        <option value={true}>Active</option>
                        <option value={false}>Non-Active</option>
                    </Input>
                </div>
            }];
    }

    getColValue(value, storeItem) {
        switch (storeItem.type) {
            case "isActive":
                return <div className={"text-center"}>
                    <h5><Badge color={value ? "success" : "danger"}>{value ? "Active" : "Not-Active"}</Badge></h5>
                </div>;
            case "productGroupId":
                return <Input type={"select"}
                    disabled={true}
                    placeholder={"Product"}
                    name={"productGroupId"}
                    value={value}>
                    <option value={""}>Select</option>
                    {
                        this.state.products.map((option, index) =>
                            <option key={index} value={option.productGroupId}>{option.name}</option>
                        )
                    }
                </Input>;
            case "currency":
                return <NumberFormat value={value}
                    displayType={'text'}
                    decimalScale={2}
                    thousandSeparator={true}
                    prefix={'$'} />;
            case "action":
                return <div><Button color={"primary"} size={"sm"}
                    onClick={() => this.handleAction(value, "edit")}><i className="mr-2 fa fa-pencil"
                        aria-hidden="true" />Edit</Button>
                </div>;
            default:
                return <span>{value}</span>
        }
    }


    render() {

        let { rules, products, loadingRules, accountId } = this.state;
        let { pageRequest, sortRequest, filterRequest } = rules.request;
        let store = this.getStore();
        
        return (
            <div>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card>
                    <CardHeader>
                        Packaging And Handling
                        <span style={{ float: "right" }}> <Link to={"/tools/manage/shipment/packaging-handling/charges/rule/list?" + queryString.stringify({ accountId: accountId })}
                            size="xs"><i
                                className={"fa fa-pencil"} /></Link></span>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loadingRules ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {rules.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>

                            </Col>


                        </Row>
                        <div>
                            <Table hover bordered responsive={true} size={"sm"} striped={true}>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        {(store || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleStoreChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={item.style}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort text-muted": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td />
                                        {(store || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(rules.response.records && rules.response.records.length > 0) ?
                                    (rules.response.records || []).map((order, index) => 
                                        
                                            <tr key={index}>
                                                <td key={index}>
                                                    {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                                </td>
                                                {(store || []).map((storeItem, index) => {
                                                    return (
                                                        <td key={index} className={storeItem.valueClassName}>
                                                            {this.getColValue(order[storeItem.key], storeItem)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ) :<tr>
                                            <td colSpan="4" className="text-center">No Items.</td>
                                        </tr>
                                       }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    {rules.response.totalRecords > rules.response.pageSize
                        ? <CardFooter>
                            <Row>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"} style={{ maxWidth: 200 }}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{loadingRules ?
                                                    <Spinner size={"sm"} /> : "Show"}</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"select"}
                                                name={"pageSize"}
                                                value={sortRequest.pageSize}
                                                disabled={loadingRules}
                                                onChange={(e) => this.handleStoreChange(e.target.value, "pageSize")}>
                                                <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                                <option value={50}>50 Rows</option>
                                                <option value={100}>100 Rows</option>
                                                <option value={500}>500 Rows</option>
                                            </Input>
                                        </InputGroup>


                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"float-right"}>
                                        <Pagination
                                            activePage={pageRequest.currentPage}
                                            itemsCountPerPage={pageRequest.pageSize}
                                            totalItemsCount={rules.response.totalRecords}
                                            pageRangeDisplayed={3}
                                            onChange={(activePage) => this.handleStoreChange(activePage, "currentPage")}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                            innerClass='pagination'
                                            activeLinkClass='active'
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </CardFooter>
                        : null
                    }
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}