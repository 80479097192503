import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Col,
    Row,
    Table,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    InputGroupText,
    Spinner,
    Label,
    ButtonGroup
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import keywayStockService from '../../../services/KeywayStockService';
import cloneDeep from 'lodash/cloneDeep';
import { handleErrorMessage, changeFormatOfDateString, getDateString } from '../../../services/CommonService';
import queryString from 'query-string';
import { STOCK_QTY_FORMAT, STOCK_QTY_TYPE } from "../../../store/AppConstants";
import StockQtyFormat from "../../../components/stock/StockQtyFormat";
import DatePicker from "react-datepicker";
import moment from 'moment';
import UserService from '../../../services/UserService';

export const maxDate = moment().add(2, 'years').toDate();
export const minDate = moment().subtract(20, 'years').toDate();
const dateObj = new Date();

export default class StockTakeApprovalPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "lastCountedOn",
                        direction: true
                    },
                    filterRequest: {
                        prodCode: '',
                        description: '',
                        colour: '',
                        location: '',
                        excludeApproved: 0,
                        startDate: moment().subtract(3, 'months').toDate(),
                        endDate: new Date()
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            isOpenModal: false,
            isUpdating: false,
            isHasRole:false
        };
        this.userService = new UserService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getStocks = this.getStocks.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        if (this.userService.hasRole(currentUser, "Finance") || this.userService.hasRole(currentUser, "Admin")) {
            this.setState({ isHasRole: true });
        } 

        this.getStocks(this.state.stockData);
    }

    getStocks(stockData) {
        let request = cloneDeep(stockData.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "YYYY-MM-DD");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "YYYY-MM-DD");
        }
        this.setState({ loading: true });
        keywayStockService.getStockTakeApprovalList(request).then(response => {
            let data = response.data;
            (data.records || []).forEach(x => {
                x.difference = x.lastCountedQty - x.qolAtCount;
                x.balance = x.transactionStatus == "Approved" ? 0 : x.qol + x.difference;
                x.qol = x.transactionStatus == "Approved" ? 0 : x.qol;
                x.approvedBy = x.approvedBy ? x.approvedBy.concat(" on ", x.approvedDate) : "";
            });
            stockData.response.records = data.records;
            stockData.response.totalRecords = data.totalRecords;
            stockData.request.pageRequest.currentPage = data.currentPage;
            stockData.request.pageRequest.pageSize = data.pageSize;
            this.setState({ stockData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key) {
        let { stockData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change, stockData });
                break;
            case "sortKey":
                if (stockData.request.sortRequest.key === change) {
                    stockData.request.sortRequest.direction = !stockData.request.sortRequest.direction;
                } else {
                    stockData.request.sortRequest.key = change;
                    stockData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStocks(stockData);
                }
                break;
            case "pageSize":
                stockData.request.pageRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                break;
            case "currentPage":
                stockData.request.pageRequest[key] = change;
                this.getStocks(stockData);
                break;
            case "isAllSelected":
                stockData.response.records.forEach(item => {
                    item.isSelected = change;
                })
                this.setState({ stockData });
                break;
            case "isSelected":
                if (!stockData.response.records[change].isSelected) {
                    stockData.response.records[change].isSelected = true;
                } else {
                    stockData.response.records[change].isSelected = false;
                }
                this.setState({ stockData });
                break;
            default:
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                this.setState({ stockData });
        }

    }

    handleSubmit(data, status) {
        let { stockData } = this.state;
        let requestBody = [];
        let request = {
            locationID: data.locationID,
            prodCode: data.prodCode,
            qty: data.balance,
            differenceQty: data.difference,
            status: status
        };
        requestBody.push(request);
        this.setState({ isUpdating: true });
        keywayStockService.updateStockOnLocation(requestBody).then(response => {
            toast.success("Updated!");
            this.setState({ isUpdating: false }, () => this.getStocks(stockData));
        }).catch(error => {
            this.setState({ isUpdating: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleBulkSubmit(status) {
        let { stockData } = this.state;
        let requestBody = [];
        stockData.response.records.forEach(x => {
            if (x.isSelected == true) {
                let request = {
                    locationID: x.locationID,
                    prodCode: x.prodCode,
                    qty: x.lastCountedQty,
                    differenceQty: x.difference,
                    status: status
                };
                requestBody.push(request);
            }
        });
        this.setState({ isUpdating: true });
        keywayStockService.updateStockOnLocation(requestBody).then(response => {
            toast.success("Updated!");
            this.setState({ isUpdating: false }, () => this.getStocks(this.state.stockData));
        }).catch(error => {
            this.setState({ isUpdating: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleFilterChange(change, key) {
        let { stockData } = this.state;

        switch (key) {
            case "excludeApproved":
                stockData.request.filterRequest.excludeApproved = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
            default:
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
        }
    }

    getStockHeading(filterRequest) {
        let handleChange = this.handleChange;
        let isAllSelected = this.state.stockData.response.records.every(x => x.isSelected);
        let stockHeading = [
            {
                key: "multiSelect",
                label: <a href={"javascript:void(0)"}
                    onClick={() => handleChange(!isAllSelected, "isAllSelected")}>
                    <i className={classnames("mt-2", "fa", "fa-lg", {
                        "fa-check-square-o": isAllSelected,
                        "fa-square-o": !isAllSelected,
                    }
                    )} />
                </a>,
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "prodCode",
                label: "Prodcode",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="prodCode" name="prodCode" value={filterRequest.prodCode}
                    onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                    placeholder="Search... " />
                </div>

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="description" name="description"
                    value={filterRequest.description}
                    onChange={(e) => this.handleChange(e.target.value, "description")}
                    placeholder="Search..." /></div>

            },
            {
                key: "colour",
                label: "Colour",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="colour" name="colour"
                    value={filterRequest.colour}
                    onChange={(e) => this.handleChange(e.target.value, "colour")}
                    placeholder="Search..." /></div>
            },
            {
                key: "size",
                label: "Size",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "location",
                label: "Location",
                type: "text",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="location" name="location"
                    value={filterRequest.location}
                    onChange={(e) => this.handleChange(e.target.value, "location")}
                    placeholder="Search..." /></div>
            },
            {
                key: "qolAtCount",
                label: "QOL - At Count",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
            },
            {
                key: "difference",
                label: "Difference",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
            },
            {
                key: "qol",
                label: "QOL - Live",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
            },
            {
                key: "balance",
                label: "QOL - On Approval",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
            },
            {
                key: "lastCountedOn",
                label: "Submitted On",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null
            },
            {
                key: "requestedBy",
                label: "Submitted By",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "approvedBy",
                label: "Actioned By",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                searchNode: null
            }
        ];

        return stockHeading;
    }


    render() {
        let { stockData, loading, isUpdating, isHasRole } = this.state;
        let { pageRequest, sortRequest, filterRequest } = stockData.request;
        let stockHeading = this.getStockHeading(stockData.request);
        return (
            <div>
                <div>
                    <Row className={"mb-2 align-items-center"}>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                            <div>
                                <DatePicker
                                    className="form-control form-control"
                                    selected={filterRequest.startDate}
                                    onChange={date => this.handleChange(date, "startDate")}
                                    selectsStart
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="Start Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                            <div className={"text-right"}>
                                <DatePicker
                                    className="form-control form-control"
                                    selected={filterRequest.endDate}
                                    onChange={date => this.handleChange(date, "endDate")}
                                    selectsEnd
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="End Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <Label>Approval Status</Label>
                                <div>
                                    <ButtonGroup>
                                        <Button
                                            size={"sm"}
                                            onClick={() => this.handleFilterChange(1, "excludeApproved")}
                                            color={"primary"}

                                            outline={filterRequest.excludeApproved !== 1}>
                                            Approved
                                            {filterRequest.excludeApproved === 1
                                                ? <i className="fa fa-check ml-1"
                                                    aria-hidden="true" />
                                                : null
                                            }</Button>

                                        <Button
                                            onClick={() => this.handleFilterChange(0, "excludeApproved")}
                                            color={"primary"}
                                            size={"sm"}
                                            outline={filterRequest.excludeApproved !== 0}>
                                            UnApproved
                                            {filterRequest.excludeApproved === 0
                                                ?
                                                <i className="fa fa-check ml-1" aria-hidden="true" />
                                                : null
                                            }</Button>
                                        <Button
                                            onClick={() => this.handleFilterChange(-1, "excludeApproved")}
                                            color={"primary"}
                                            size={"sm"}
                                            outline={filterRequest.excludeApproved !== -1}>
                                            All
                                            {filterRequest.excludeApproved === -1
                                                ?
                                                <i className="fa fa-check ml-1" aria-hidden="true" />
                                                : null
                                            }</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading ? <p>Loading...</p> :
                                <p>Showing
                                    {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {stockData.response.totalRecords}
                                    {' '}entries&nbsp;&nbsp;
                                </p>
                            }
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            {
                                stockData.response.records.some(x => x.isSelected) ?
                                    <>
                                        <Button color='primary'
                                            outline={true}
                                            size='sm' className={'mr-2'}
                                            onClick={() => this.handleBulkSubmit('Approved')}>
                                            {isUpdating ?
                                                <Spinner size='sm' className='mr-2' color="primary" />
                                                : null}
                                            Approve
                                        </Button>
                                        <Button color='danger'
                                            size='sm'
                                            outline={true}
                                            onClick={() => this.handleBulkSubmit('Rejected')}>
                                            {isUpdating ?
                                                <Spinner size='sm' className='mr-2' color="danger" />
                                                : null}
                                            Reject
                                        </Button>
                                    </>
                                    : null}
                        </div>
                    </Col>
                </div>
                <div>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                            <tr>
                                {(stockHeading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(stockHeading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {(stockData.response.records || []).map((stock, index) => {
                                return <tr key={index} className="text-center align-middle">
                                    <td className="text-center align-middle">
                                        <a href={"javascript:void(0)"}
                                            onClick={(e) => this.handleChange(index, "isSelected")}>
                                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                                "fa-check-square-o": stock.isSelected,
                                                "fa-square-o": !stock.isSelected,
                                            }
                                            )} /> </a>
                                    </td>
                                    <td className="text-center align-middle">
                                        <h5>
                                            <Link
                                                className="btn btn-primary btn-sm"
                                                style={{ color: "white" }}
                                                title={"Click here to see stock enquiry"}
                                                to={"/inventory/stock/enquiry?" + queryString.stringify({ prodCode: stock.prodCode })}
                                            >{stock.prodCode}</Link>
                                        </h5>
                                    </td>
                                    <td className="text-left align-middle">{stock.description}</td>
                                    <td className="text-center align-middle">{stock.colour}</td>
                                    <td className="text-center align-middle">
                                        {stock.length ? <div>Length: {stock.length} { stock.dimensionUnitName}</div> : null}
                                        {stock.width ? <div>Width: {stock.width} {stock.dimensionUnitName} </div> : null}
                                        {stock.height ? <div>Height: {stock.height} {stock.dimensionUnitName} </div> : null}
                                    </td>
                                    <td className="text-center align-middle">{stock.location}</td>
                                    <td className={"text-center align-middle"}>
                                        <StockQtyFormat
                                            eachUnitName={stock.eachUnitName}
                                            whUnitName={stock.whUnitName}
                                            whUnitQty={stock.whUnitQty}
                                            qty={stock.qolAtCount}
                                            qtyType={STOCK_QTY_TYPE.each}
                                            format={STOCK_QTY_FORMAT.each}
                                            emptyPlaceholderText={"-"}
                                            isPoManage={false}
                                        />
                                    </td>
                                    <td className={"text-center align-middle"}>
                                        <StockQtyFormat
                                            eachUnitName={stock.eachUnitName}
                                            whUnitName={stock.whUnitName}
                                            whUnitQty={stock.whUnitQty}
                                            qty={stock.difference}
                                            qtyType={STOCK_QTY_TYPE.each}
                                            format={STOCK_QTY_FORMAT.each}
                                            emptyPlaceholderText={"-"}
                                            isPoManage={false}
                                        />
                                    </td>
                                    <td className={"text-center align-middle"}>
                                        <StockQtyFormat
                                            eachUnitName={stock.eachUnitName}
                                            whUnitName={stock.whUnitName}
                                            whUnitQty={stock.whUnitQty}
                                            qty={stock.qol}
                                            qtyType={STOCK_QTY_TYPE.each}
                                            format={STOCK_QTY_FORMAT.each}
                                            emptyPlaceholderText={"-"}
                                            isPoManage={false}
                                        />
                                    </td>
                                    <td className={"text-center align-middle"}>
                                        <StockQtyFormat
                                            eachUnitName={stock.eachUnitName}
                                            whUnitName={stock.whUnitName}
                                            whUnitQty={stock.whUnitQty}
                                            qty={stock.balance}
                                            qtyType={STOCK_QTY_TYPE.each}
                                            format={STOCK_QTY_FORMAT.each}
                                            emptyPlaceholderText={"-"}
                                            isPoManage={false}
                                        />
                                    </td>
                                    <td className="text-center align-middle">
                                        {changeFormatOfDateString(stock.requestedDate, "DD/MM/YYYY", "DD MMM YYYY")}
                                    </td>
                                    <td className="text-center align-middle">{stock.requestedBy}</td>
                                    <td className="text-center align-middle">{stock.approvedBy}</td>
                                    <td className="text-center align-middle">
                                        {stock.isCompleted && stock.transactionStatus === 'Approved' ?
                                            <Button color='success'
                                                size='sm'
                                                disabled={true}>
                                                Approved
                                            </Button>
                                            : null
                                        }
                                        {stock.isCompleted && stock.transactionStatus === 'Rejected' ?
                                            <Button color='danger'
                                                size='sm'
                                                disabled={true}>
                                                Rejected
                                            </Button>
                                            : null
                                        }
                                        {!stock.isCompleted && stock.transactionStatus === 'Pending' ?
                                            <>
                                                <Button color='primary'
                                                    size='sm' className={"mr-2"}
                                                    disabled={!isHasRole}
                                                    onClick={() => this.handleSubmit(stock, 'Approved')}>
                                                    {isUpdating ?
                                                        <Spinner size='sm' className='mr-2' color="primary" />
                                                        : null} 
                                                    Approve
                                                </Button>
                                                <Button color='danger'
                                                    size='sm'
                                                    disabled={!isHasRole}
                                                    onClick={() => this.handleSubmit(stock, 'Rejected')}>
                                                    {isUpdating ?
                                                        <Spinner size = 'sm' className='mr-2' color="danger" />
                                                        : null}
                                                    Reject
                                                </Button>
                                            </>
                                            : null
                                        }
                                    </td>
                                </tr>
                            }
                            )}

                        </tbody>
                    </Table>
                </div>
                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{ maxWidth: 200 }}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>

                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={stockData.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
            </div>
        );
    }
}
