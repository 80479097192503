import React, {Component} from 'react';
import CustomerService from "../../services/CustomerService";
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';


export default class SearchDebtorAcccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            defaultAccountID: props.defaultAccountID,
            accountId: props.selectedAccountID,
            loading: false,
            allAccounts: [],
            inputValue: "",
            includeChildren: props.includeChildren,
            includeClosed: props.includeClosed,
            includeInactive: props.includeInactive
        };

        this.customerService = new CustomerService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

    }


    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.defaultAccountID || this.state.accountId);
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.defaultAccountID !== nextProps.defaultAccountID) {
            if (nextProps.defaultAccountID) {
                this.setState({
                    defaultAccountID: nextProps.defaultAccountID,
                    accountId: nextProps.defaultAccountID
                }, () => {
                    this.fetchOptions(nextProps.defaultAccountID);
                })
            }

        }

        if (this.state.accountId !== nextProps.selectedAccountID) {
            if (!nextProps.selectedAccountID) {
                this.setState({selected: null, accountId: ""})
            }
            else {
                this.setState({accountId: nextProps.selectedAccountID})
            }
            //load externally selectedItem
            this.fetchOptions(nextProps.selectedAccountID);
        }

        if (
            this.state.includeChildren !== nextProps.includeChildren
            || this.state.includeClosed !== nextProps.includeClosed
            || this.state.includeInactive !== nextProps.includeInactive
        ) {
            this.setState({ includeChildren: nextProps.includeChildren, includeClosed: nextProps.includeClosed, includeInactive: nextProps.includeInactive }
                , () => { this.fetchOptions(this.state.defaultAccountID) });
        }
    }


    handleSelect(selectedAccount, event) {

        let {selected, accountId} = this.state;
        selected = selectedAccount;
        accountId = selectedAccount ? selectedAccount.accountId : "";

        switch (event) {
            case "selected":
                this.setState({selected, accountId});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, accountId});
                this.props.handleAccountChange(accountId);
        }

        if (!accountId) {
            this.fetchOptions("");
        }

    };


    fetchOptions(inputValue, callback) {
        let allAccounts = [];
        let requestBody = {
            AccountId: inputValue,
            includeChildren: !!this.props.includeChildren,
            includeClosed: !!this.props.includeClosed,
            includeInactive: !!this.props.includeInactive
        };

        this.customerService.searchDebtorAccountCompany(requestBody).then(response => {
            let data = response.data;
            for (let i in data) {
                let account = {
                    label: i,
                    value: data[i].accountId,
                    id: data[i].accountId,
                    accountId: data[i].accountId,
                };
                allAccounts.push(cloneDeep(account));
                if (this.props.defaultAccountID) {
                    if (data[i].accountId === this.props.defaultAccountID) {
                        this.handleSelect(account, "default");
                    }
                }
                if (this.props.selectedAccountID) {
                    if (data[i].accountId === this.props.selectedAccountID) {
                        this.handleSelect(account, "selected");
                    }
                }
            }
            this.setState({allAccounts});
            if (callback) {
                return callback(allAccounts)
            } else {
                return allAccounts;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allAccounts);
            }
        }
    };

    render() {

        return (
            <div>
                <AsyncSelect
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.allAccounts}
                    loadOptions={this.loadOptions}
                    onChange={(selection) => this.handleSelect(selection, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
