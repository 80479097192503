import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';
import FabricService from '../../services/FabricService';
import queryString from 'query-string';

const fabricTypes = [
    {
        label: "All", value: "ALL"
    },
    {
        label: "Outdoor", value: "OUTDOOR"
    },
    {
        label: "Indoor", value: "INDOOR"
    }
]
export default class FabricBrochure extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            fabrics: [],
            fabricType: "ALL",
        }

    }

    componentDidMount() {
        this.getFabrics();
    }

    getFabrics() {
        let { fabricType } = this.state;

        this.setState({ loading: true });
        FabricService.GetFabricsByFabricType(fabricType).then(response => {
            this.setState({ fabrics: response.data, loading: false });

        }).catch(error => {
            toast.info(JSON.stringify(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ loading: false });
        });
    }

    handleChange(change) {
        this.setState({ fabricType: change }, () => { this.getFabrics() });
    }


    render() {
        let baseUrl = window.location.origin;
        let { fabrics, loading } = this.state;

        return (
            <div>
                <div className="text-center">
                    <CustomerDashboardQuickLinks size={"sm"} />
                    <hr />
                </div>

                <div className="mt-4">

                    <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Card className="mt-1">
                                <CardHeader>
                                    <h6> Filter by</h6></CardHeader>
                                <CardBody>
                                    <FormGroup className="ml-2 mr-2">
                                        <Label for="status">Fabric Type</Label>
                                        <Input type={"select"}
                                            name={"fabricType"}
                                            value={this.state.fabricType}
                                            onChange={(e) => this.handleChange(e.target.value)}>
                                            {fabricTypes.map((option, index) =>
                                                <option key={index} value={option.value}>{option.label}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={8} lg={8} md={8} sm={12} xs={12}>

                            {loading ? <Spinner color={"primary"} /> :

                                <Row>
                                    {fabrics.length > 0 ?
                                        fabrics.map((fabric, index) =>
                                            <Col xl={3} lg={3} md={6} sm={12} xs={12} key={index}>
                                                <Link style={{ textDecoration: "none" }}
                                                    to={"/fabrics/one/color/info?" + queryString.stringify({ fabricId: fabric.id })}>
                                                    <Card className={"hoverableItem ds-7 mt-1"}>
                                                        {fabric.fabricImageAttachment ?
                                                            <CardImg style={{
                                                                width: '100%',
                                                                height: '150px'
                                                            }}
                                                                title={fabric.fabricName}
                                                                src={baseUrl + "/api/file/Generate/Fabric?FileName=" + fabric.fabricImageAttachment.fileName}
                                                                alt="Card image cap" /> : null}
                                                        <CardBody>
                                                            <CardTitle>{fabric.fabricName}</CardTitle>
                                                        </CardBody>
                                                    </Card></Link>
                                            </Col>


                                        ) : <Badge color="light">
                                            No fabrics found! </Badge>}
                                </Row>}

                        </Col>
                    </Row>

                </div>


                <ToastContainer />
            </div>
        );

    }
}