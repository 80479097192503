import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col,
    Button, Badge,
    Label, Input, FormText,
    Card, CardBody,
    Spinner
} from 'reactstrap';
import {getLocalDateStringFromUtcDateString} from '../../services/CommonService';
import SearchUser from '../../components/search/SearchUserEmail';
import {userInboxMessageCategoryOptions} from '../../store/UserInboxMessageCategory';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Editor from '../../store/ReactQuillEditorConfig';

function FormError(props) {
    let form = props.item;
    switch (props.attribute) {
        case "category":
            if (!form.category) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
        case "fromEmail":
            if (!form.fromEmail) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
        case "subject":
            if (!form.subject) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
        case "shortMessage":
            if (!form.shortMessage) {
                return <FormText color={props.color}>Required</FormText>
            }
            break;
         default:
            break;
    }
    return null;
}

export default class ManageUserInboxTemplateModal extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    }

    handleChange(change, key) {
        this.props.onChange(change, key);

    };

    validateAndSubmit() {
        let {template} = this.props;
        let isValid = this.validateForm(template);
        if (isValid) {
            this.props.onSubmit(template);
        } else {
            this.props.onChange(false, "isValidated");

        }
    };

    validateForm(template) {
        let isValid = true;
        if (!template.fromEmail) {
            isValid = false;
        }
        if (!template.category) {
            isValid = false;
        }
        if (!template.subject) {
            isValid = false;
        }
        if (!template.shortMessage) {
            isValid = false;
        }
        return isValid;
    }


    render() {
        let {isOpen, toggle, template, loading} = this.props;


        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Create Template
                </ModalHeader>
                <ModalBody style={{minHeight: 300}}>
                    <div>
                        <p><strong>CATEGORY</strong></p>
                        <div>
                            <Label for="fromEmail">Template for*</Label>
                            <Input type="select" name="category" id="category"
                                   value={template.category}
                                   onChange={(change) => this.handleChange(change.target.value, "category")}>
                                <option value={""}>Select</option>
                                {
                                    userInboxMessageCategoryOptions.map((option, index) => {
                                        return <option key={index}
                                                       value={option.value}>{option.label}</option>
                                    })
                                }
                            </Input>
                            <FormError attribute={"category"} item={template}
                                       color={template.isValidated ? "muted" : "danger"}/>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <p><strong>RECIPIENTS</strong></p>
                        <div className="mb-3">
                            <Label for="fromEmail">From*</Label>
                            <div id={"fromEmail"}>
                                <SearchUser
                                    role={""}
                                    onChange={(change) => this.handleChange(change, "fromEmail")}
                                    defaultValue={template.fromEmail}/>
                            </div>
                            <FormError attribute={"fromEmail"} item={template}
                                       color={template.isValidated ? "muted" : "danger"}/>
                        </div>
                        <div className="mt-3">
                            <Label for="ccEmail">CC*<FormError attribute={"ccEmail"} item={template} color=""/></Label>
                            <div id={"ccEmail"}>
                                <SearchUser
                                    role={""}
                                    onChange={(change) => this.handleChange(change, "ccEmail")}
                                    defaultValue={template.ccEmail}/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <p><strong>GLIMPSE</strong></p>
                        <div className="mb-3">
                            <Label for="subject">Subject*</Label>
                            <Input id={"subject"}
                                   onChange={(e) => this.handleChange(e.target.value, "subject")}
                                   type="text"
                                   name="subject"
                                   value={template.subject}
                                   placeholder="write subject here">
                            </Input>
                            <FormError attribute={"subject"} item={template}
                                       color={template.isValidated ? "muted" : "danger"}/>
                        </div>
                        <div className="mt-3">
                            <Label for="shortMessage">Short message
                                <small> (will be display in notifications tray)</small>
                                *</Label>
                            <Input id={"shortMessage"}
                                   onChange={(e) => this.handleChange(e.target.value, "shortMessage")}
                                   type="text"
                                   name="shortMessage"
                                   value={template.shortMessage}
                                   placeholder="write a short message here">
                            </Input>
                            <FormError attribute={"shortMessage"} item={template}
                                       color={template.isValidated ? "muted" : "danger"}/>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <p><strong>MESSAGE</strong></p>

                        <div className="quil-editor-root ">
                            <ReactQuill value={template.body}
                                        modules={Editor.modules}
                                        formats={Editor.formats}
                                        onChange={(change) => this.handleChange(change, "body")}
                                        bounds={'.quil-editor-root'}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onClick={() => this.validateAndSubmit()} disabled={loading}>
                        {loading
                            ? <Spinner size="sm"
                                       className={"mr-2"}
                                       style={{color: "white"}}/>
                            : <i className="fa fa-floppy-o mr-2"/>}
                        {loading
                            ? (template.id > 0 ? "Updating" : "Saving")
                            : (template.id > 0 ? "Update" : "Save")}</Button>
                    <Button color={"secondary"} onClick={() => toggle(!isOpen)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }


}