import React, {Component} from 'react';
import {
    Card, CardHeader, CardBody, Badge,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import NumberFormat from "react-number-format";
import {findIndex} from '../../../services/CommonService';
import PurchaseHistoryService from '../../../services/PurchaseHistoryService';
import PurchaseHistoryOutstandingOrders from './PurchaseHistoryOutstandingOrders';
import PurchaseHistoryCompletedOrders from './PurchaseHistoryCompletedOrders';
import PurchaseHistoryOutstandingQuotes from './PurchaseHistoryOutstandingQuotes';

const allTabs = [
    {
        label: "Outstanding Quotes",
        key: "OutstandingQuotes",
        privilege: "debtor-invoice-order-read",
        extra: {
            count: 0,
            value: 0
        }
    },
    {
        label: "Outstanding Orders",
        key: "OutstandingOrders",
        privilege: "debtor-invoice-order-read",
        extra: {
            count: 0,
            value: 0
        }
    },
    {
        label: "Completed Orders",
        key: "CompletedOrders",
        privilege: "debtor-invoice-order-read",
        extra: {
            count: 0,
            value: 0
        }
    }
];

export default class PurchaseHistoryOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'OutstandingQuotes',
            allTabs: allTabs
        };

        this.purchaseHistoryService = new PurchaseHistoryService();
        this.toggle = this.toggle.bind(this);
        this.updateTabs = this.updateTabs.bind(this);
    }


    updateTabs(tabKey, paramName, paramValue) {
        let alltabs = this.state.allTabs;
        let index = findIndex(alltabs, 'key', tabKey);
        if (index > -1) {
            alltabs[index].extra[paramName] = paramValue;
        }
        this.setState(alltabs);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    render() {
        let {activeTab, allTabs} = this.state;

        return (
            <Card>
                <CardHeader>
                    <Nav className={"flex-column flex-sm-row"} tabs>
                        {allTabs.map((tab, index) =>
                            <NavItem
                                className={"flex-sm-fill text-sm-center hoverableItem"}
                                key={index}>
                                <NavLink
                                    className={classnames({active: activeTab === tab.key})}
                                    onClick={() => {
                                        this.toggle(tab.key);
                                    }}>
                                    <span>
                                        <span>{tab.label}</span> &nbsp;
                                        <Badge hidden={tab.extra.count === 0}>{tab.extra.count}</Badge>&nbsp;
                                        <span className={"lead"}
                                              hidden={tab.extra.value === 0}>
                                            <NumberFormat
                                                value={tab.extra.value}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}
                                                prefix={'$'}/>
                                        </span>

                                    </span>

                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </CardHeader>
                <CardBody>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="OutstandingQuotes">
                            <PurchaseHistoryOutstandingQuotes tabId={"OutstandingQuotes"}
                                                              accountId={this.props.accountId}
                                                              updateTabs={this.updateTabs}/>
                        </TabPane>
                        <TabPane tabId="OutstandingOrders">
                            <PurchaseHistoryOutstandingOrders tabId="OutstandingOrders"
                                                              accountId={this.props.accountId}
                                                              updateTabs={this.updateTabs}/>
                        </TabPane>
                        <TabPane tabId="CompletedOrders">
                            <PurchaseHistoryCompletedOrders tabId="CompletedOrders"
                                                            accountId={this.props.accountId}
                                                            updateTabs={this.updateTabs}/>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        );
    }
}
