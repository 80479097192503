import React from 'react';
import {Component} from 'react';
import {Link} from "react-router-dom";
import queryString from 'query-string';

export default class StockInventoryQuickLinks extends Component {

    render() {
        return (
            <div>
                {
                    this.props.showInventoryStatus
                        ? <Link className={"btn btn-sm btn-outline-primary mr-2"}
                                to={"/inventory/stock-inventory-status/manage/one?" + queryString.stringify({prodCode: this.props.prodCode})}>
                            <i className="fa fa-pencil mr-2" aria-hidden="true"/> Manage Stock Status
                        </Link>
                        : null
                }

                {
                    this.props.showInventoryPrice
                        ? <Link className={"btn btn-sm btn-outline-primary mr-2"}
                                to={"/inventory/stock-inventory-price/manage/one?" + queryString.stringify({prodCode: this.props.prodCode})}>
                            <i className="fa fa-pencil mr-2" aria-hidden="true"/> Manage Stock Price
                        </Link>
                        : null
                }

                {
                    this.props.showInventoryCost
                        ? <Link className={"btn btn-sm btn-outline-primary mr-2"}
                                to={"/inventory/stock-inventory-cost/manage/one?" + queryString.stringify({prodCode: this.props.prodCode})}>
                            <i className="fa fa-pencil mr-2" aria-hidden="true"/> Manage Stock Cost
                        </Link>
                        : null
                }

            </div>
        )
    }
}