import React, { Component } from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
    FormText
} from 'reactstrap';

import { Link as Link } from "react-router-dom";
import StockService from '../../services/StockService';
import productService from '../../services/product/ProductService';
import productionService from '../../services/production/ProductionLineService';
import queryString from 'query-string';
import classnames from "classnames";
import cloneDeep from 'lodash/cloneDeep';
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';

export default class ProductGroupPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            loading: false,
            modalIsOpen: false,
            currentProduct: {},
            saving: false,
            formErrors: {}
        };
        this.stockService = new StockService();
        this.refreshAgain = this.refreshAgain.bind(this);
        this.getProductionLine = this.getProductionLine.bind(this);
        this.setModalActive = this.setModalActive.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.refreshAgain();
    }

    handleChange(change, key) {
        let { currentProduct } = this.state;
        if (key === 'isVisibleOnSalesOrderScreen') {
            currentProduct.isVisibleOnSalesOrderScreen = !change;
        }
        currentProduct[key] = change;
        this.setState({ currentProduct });
    }

    validateForm(item) {
        let formErrors = {}, valid = true;
        if (!item.displayName) {
            formErrors.displayName = "Enter Display Name";
            valid = false;
        }
        if (!item.name) {
            formErrors.name = "Enter Name";
            valid = false;
        }
        if (!item.shortName) {
            formErrors.shortName = "Enter Short Name";
            valid = false;
        }
        if (!item.code) {
            formErrors.code = "Enter Code";
            valid = false;
        }
        return ({
            valid: valid,
            formErrors: formErrors
        })
    }

    getProductGroups() {
        this.setState({ loading: true });
        productService.getProductGroups().then(response => {
            this.setState({ products: response.data, loading: false }, () => {
                this.getProductionLine();
            });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    getProductionLine() {
        productionService.getListProductionLines().then(response => {
            let productionLines = response.data;
            let { products } = this.state;
            products.forEach(product => {
                product.productionLine = productionLines.find(pl => pl.productionLineId === product.productionLineId);
            });
            this.setState({ products });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    setModalActive(item) {
        if (this.state.modalIsOpen) {
            this.setState({ modalIsOpen: false, currentProduct: {} });
        }
        else {
            this.setState({ modalIsOpen: true, currentProduct: item })
        }
    }

    saveProduct(product) {
        let validateResult = this.validateForm(product);
        if (!validateResult.valid) {
            this.setState({ formErrors: validateResult.formErrors });
            return;
        }
        this.setState({ formErrors: {} })
        this.setState({ saving: true });
        productService.updateProductGroup(product).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ modalIsOpen: false, saving: false });
                toast.success("Saved successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.refreshAgain();
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    refreshAgain() {
        this.setState({ modalIsOpen: false });
        this.getProductGroups();
    }

    render() {
        let { formErrors, products, loading, currentProduct, saving } = this.state;
        return (
            <div> 
                    {loading
                        ? <Spinner color="primary" />
                        : <Table responsive={true} striped bordered size={"sm"}>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Short-name</th>
                                    <th>Display-name</th>
                                    <th>Code</th>
                                    <th>Production Line</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(products || []).map((item, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.shortName}</td>
                                        <td>{item.displayName}</td>
                                        <td>{item.code}</td>
                                        <td>
                                            {
                                                item.productionLine
                                                    ? item.productionLine.name
                                                    : null
                                            }
                                        </td>
                                        <td>

                                            &nbsp;&nbsp;
                                            <Button size={"sm"} color={"primary"}
                                                onClick={() => this.setModalActive(cloneDeep(item))}>
                                                <i
                                                    className="fa fa-edit mr-2"
                                                    aria-hidden="true" />Edit</Button>
                                            &nbsp;&nbsp;

                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>}

                <Modal isOpen={this.state.modalIsOpen}
                    size="md"
                    scrollable={false}>
                    <ModalHeader toggle={this.setModalActive}>
                        Edit Product
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input autoComplete="off" type="text" name="name"
                                    onChange={(e) => this.handleChange(e.target.value, 'name')}
                                    value={currentProduct.name}
                                    placeholder="Enter name here" id="name" />
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="shortName">Short Name</Label>
                                <Input autoComplete="off" type="text" name="shortName"
                                    onChange={(e) => this.handleChange(e.target.value, 'shortName')}
                                    value={currentProduct.shortName}
                                    placeholder="Enter short name here" id="shortName" />
                                <FormText color="danger">{formErrors.shortName}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="displayName">Display Name</Label>
                                <Input autoComplete="off" type="text" name="displayName"
                                    onChange={(e) => this.handleChange(e.target.value, 'displayName')}
                                    value={currentProduct.displayName}
                                    placeholder="Enter display name here" id="displayName" />
                                <FormText color="danger">{formErrors.displayName}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="code">Code</Label>
                                <Input autoComplete="off" type="text" name="code"
                                    onChange={(e) => this.handleChange(e.target.value, 'code')}
                                    value={currentProduct.code}
                                    placeholder="Enter code here" id="code" />
                                <FormText color="danger">{formErrors.code}</FormText>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.saveProduct(currentProduct)}>
                            {saving
                                ? <Spinner size={"sm"} color={"light"} className={"mr-2"} />
                                : <i className={"fa fa-floppy-o mr-2"} />
                            }
                            Save</Button>
                        <Button color="secondary" onClick={this.setModalActive}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}