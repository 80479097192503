import React, {Component} from 'react';

import {Link} from "react-router-dom";
import {
    Col,
    Row,
    Card,
    CardHeader,
    CardBody,
    Badge,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText
} from "reactstrap";


import CRMService from "../../../services/crm/CRMService";
import {convertUtcDateToLocalDate} from '../../../services/CommonService';
import InfiniteScroll from 'react-infinite-scroller';

export default class AssignedLeadActivitiesForMe extends Component {

    constructor(props) {
        super(props);
        this.crmService = new CRMService();
        this.state = {
            loading: true,
            leads: [],
            selectedPage: 1,
            pageSize: 25,
            totalRecords: 0
        };

        this.getPendingTasks = this.getPendingTasks.bind(this);
    }

    componentDidMount() {
        this.getPendingTasks(false);
    }


    getPendingTasks(incrementPage) {
        let {loading, leads, selectedPage, pageSize, totalRecords} = this.state;
        if (incrementPage) {
            selectedPage = selectedPage + 1;
        }
        this.crmService.fetchAssignedLeadActivitiesForMe(selectedPage, pageSize).then(response => {

            if (response.data) {
                let data = response.data;

                loading = false;
                selectedPage = data.selectedPage;
                pageSize = data.pageSize;
                totalRecords = data.totalRecords;
                if (data.items && data.items.length > 0) {
                    for (let i in data.items) {
                        data.items[i].id = data.items[i].leadID;
                        leads.push(data.items[i]);
                    }
                }

                this.setState({loading, leads, selectedPage, pageSize, totalRecords});
            }
        }).catch(error => {
            console.log(error);
        });
    }

    getAvatar(task) {

        let icon = "fa fa-phone";

        switch (task.followUpType) {
            case "PHONE":
                icon = "fa fa-phone";
                break;
            case "SMS":
                icon = "fa fa-commenting-o";
                break;
            case "EMAIL":
                icon = "fa fa-envelope-o";
                break;
            case "VISIT":
                icon = "fa fa-map-marker";
                break;

        }

        return <i className={icon} aria-hidden="true"/>

    }

    render() {

        const {leads, totalRecords, pageSize, selectedPage} = this.state;

        if (totalRecords === 0) {
            return null;
        }

        return (
            <Col xl={3} lg={4} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>My Lead's Activities
                        &nbsp;<Badge pill
                                     title={'Your total pending tasks'}>{totalRecords}</Badge>
                        <span style={{float: "right"}}>
                            <Link to="/crm/leads"><i className={"fa fa-pencil"}/></Link>
                        </span>
                    </CardHeader>
                    <CardBody>

                        <div>
                            <div style={{height: "auto", maxHeight: "250px", overflow: "auto"}}>
                                <InfiniteScroll
                                    threshold={50}
                                    pageStart={1}
                                    loadMore={() => this.getPendingTasks(true)}
                                    hasMore={(pageSize * selectedPage) < totalRecords}
                                    useWindow={false}
                                >
                                    <ListGroup> {leads.map((lead, index) => {
                                            return (

                                                <Link
                                                    key={index}
                                                    style={{textDecoration: "none"}}
                                                    to={{
                                                        pathname: "/crm/manageLead",
                                                        state: {lead: lead}
                                                    }}

                                                >
                                                    <ListGroupItem
                                                        title={lead.personName} className={"hoverableItem"}
                                                    >
                                                        <div
                                                            className={"font-weight-normal"}>{this.getAvatar(lead)} &nbsp;
                                                            <span className={"customOverflowText"}>{lead.personName}</span>
                                                        </div>
                                                        <span>
                                                            {(() => {
                                                                if (lead.dueOn) {
                                                                    return (

                                                                        <small
                                                                            className="text-muted">Due
                                                                            date
                                                                            &nbsp;{convertUtcDateToLocalDate(lead.dueOn).fromNow()}</small>
                                                                    )
                                                                }
                                                                if (lead.activitylastModifiedDate) {
                                                                    return (
                                                                        <small
                                                                            className="text-muted">Last
                                                                            updated
                                                                            &nbsp;{convertUtcDateToLocalDate(lead.activitylastModifiedDate).fromNow()}</small>
                                                                    )
                                                                }
                                                            })()}
                                                        </span>
                                                    </ListGroupItem>

                                                </Link>)
                                        }
                                    )}
                                    </ListGroup>
                                </InfiniteScroll>
                            </div>
                            <div className="text-right text-muted mt-2">
                                <small>-Assigned to you(Pending)</small>
                            </div>
                        </div>
                    </CardBody>
                </Card>


            </Col>
        );

    }
}