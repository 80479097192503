import axios from './axios';

class RepService {

    static Instance() {
        return new RepService();
    }

    fetchReps() {
        return axios.get('api/rep/list')
    }

    fetchRepByRepCode(repCode) {
        return axios.get('api/rep/one/by/rep-code?repCode='+repCode)
    }


    getRepList(req) {
        return axios.post('api/rep/list', req);
    }

    saveRep(req) {
        return axios.post('api/rep/save', req);
    }



}

export default RepService.Instance();