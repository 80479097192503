import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import StockService from '../../services/StockService';
import cloneDeep from 'lodash/cloneDeep';

export default class SearchStockGroupMulti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            defaultStockGroupCodes: props.defaultStockGroupCodes,
            selectedStockGroupCodes: props.selectedStockGroupCodes,
            loading: false,
            allStockGroups: [],
            inputValue: "",
            filter: {
                isKeywayAvailableOnly: props.filter.isKeywayAvailableOnly,
                stockMasterGroupCodes: props.filter.selectedStockMasterGroups,
                stockGroupCodes: props.filter.stockGroupCodes,
                excludeArchived: false
            }
        };

        this.stockService = new StockService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

    }


    componentDidMount() {
        //let selectedStockGroupCodes = this.props.selectedStockGroupCodes;
        //this.setState({ selectedStockGroupCodes }, () => {
            this.fetchOptions(this.state.defaultStockGroupCodes || this.state.selectedStockGroupCodes);
        //});
     }

    componentWillReceiveProps(nextProps) {

        if (this.state.defaultStockGroupCodes !== nextProps.defaultStockGroupCodes) {
            if (nextProps.defaultStockGroupCodes) {
                this.setState({defaultStockGroupCodes: nextProps.defaultStockGroupCodes}, () => {
                    this.fetchOptions("");
                });
            }
        }

        if (this.state.excludeArchived !== nextProps.excludeArchived) {
            if (nextProps.excludeArchived !== null) {
                this.setState({ excludeArchived: nextProps.excludeArchived }, () => {
                    this.fetchOptions("");
                });
            }
        }

        if (this.state.selectedStockGroupCodes !== nextProps.selectedStockGroupCodes) {
            if (!nextProps.selectedStockGroupCodes) {
                this.setState({selected: null}, () => {
                    this.fetchOptions("");
                });
            }
            else {
                this.setState({ selectedStockGroupCodes: nextProps.selectedStockGroupCodes }, () => {
                    this.fetchOptions("");
                });
            }
        } else {
            if (nextProps.filter.isKeywayAvailableOnly !== this.state.filter.isKeywayAvailableOnly
                || JSON.stringify(nextProps.filter.stockMasterGroupCodes) !== JSON.stringify(this.state.filter.stockMasterGroupCodes)
                || JSON.stringify(nextProps.filter.stockGroupCodes) !== JSON.stringify(this.state.filter.stockGroupCodes)
            ) {
                let {filter} = this.state;
                filter.isKeywayAvailableOnly = nextProps.filter.isKeywayAvailableOnly;
                filter.stockMasterGroupCodes = nextProps.filter.stockMasterGroupCodes;
                filter.stockGroupCodes = nextProps.filter.stockGroupCodes;

                this.setState({ filter }, () => {
                    this.fetchOptions("");
                });
            }
        }
    }


    handleSelect(selected, event) {
        let { selectedStockGroupCodes } = this.state;
        selectedStockGroupCodes = (selected || []).map(s => s.value);

        switch (event) {
            case "selected":
                this.setState({selected, selectedStockGroupCodes});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, selectedStockGroupCodes});
                this.props.handleSelection(selectedStockGroupCodes);
        }

        if (!selectedStockGroupCodes) {
            this.fetchOptions("");
        }

    };

    fetchOptions(inputValue, callback) {
        let allStockGroups = [];
        let {filter} = this.state;
        let requestBody = {
            searchText: inputValue,
            isKeywayAvailableOnly: filter.isKeywayAvailableOnly,
            stockMasterGroupCodes: filter.stockMasterGroupCodes,
            stockGroupCodes: filter.stockGroupCodes,
            excludeArchived: filter.excludeArchived
        };
        /*if (inputValue && inputValue.includes(',')) {
            let sgc = inputValue.split(',');
            //requestBody.stockGroupCodes = [...requestBody.stockGroupCodes, ...sgc];
            requestBody.stockGroupCodes = "";
            requestBody.searchText = "";
        }*/
        this.stockService.getStockGroupDropdownOptions(requestBody).then(response => {
            let data = response.data;
            for (let i in data) {
                let temp = {
                    label: data[i].name,
                    value: data[i].code,
                };
                allStockGroups.push(cloneDeep(temp));
                if (this.props.defaultStockGroupCodes) {
                    if (this.props.defaultStockGroupCodes.includes(data[i].code)) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.props.selectedStockGroupCodes) {
                    if (this.props.selectedStockGroupCodes.includes(data[i].code)) {
                        //this.handleSelect(temp, "selected");
                        this.handleSelect((allStockGroups || []).filter(x => this.props.selectedStockGroupCodes.includes(x.value)),"selected")
                    }
                }
            }
            this.setState({allStockGroups});
            if (callback) {
                return callback(allStockGroups)
            } else {
                return allStockGroups;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allStockGroups);
            }
        }
    };


    render() {
        return (
            <AsyncSelect
                cacheOptions
                isLoading={this.state.loading}
                isClearable={true}
                defaultOptions={this.state.allStockGroups}
                loadOptions={this.loadOptions}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                isDisabled={this.props.disabled}
                isMulti={true}
                value={this.state.selected}
                placeholder={"Search..."}
            />
        );
    }
}

 


