import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import OrdersTable from "./ProductionProductOrdersTable"


export default class ProductionProducts extends Component {

    constructor(props) {
        super(props);
        this.getKeys = this.getKeys.bind(this);

    }

    getKeys(productName) {

        let dataModal = {
            headerKeys: [],
            dataKeys: [
                {}
            ]
        };


        switch (productName) {
            case 'CURTAIN TRACKS':
                dataModal.headerKeys = ["CustOrd Date", "Ord Num", "Req Date", "Days OD", "Customer", "Qty", "Cust Ord Num", "Order Total", "Notes", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "curtaiN_TRACKS",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            case 'ALUM & VENETIAN':
                dataModal.headerKeys = ["CustOrd Date", "Ord Num", "Req Date", "Days OD", "Customer", "Alum", "Ven", "Cust Ord Num", "Order Total", "Notes", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "alum",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "venetian",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            case 'VERTOUT & ZIPTRAK':
                dataModal.headerKeys = ["Ord Date", "Ord Num", "Req Date", "Days OD", "Customer", "VertO", "Zip", "Cust Ord Num", "Order Total", "Notes", "Fabric", "PwdrC?", "Pelmet?", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "verticaL_OUT",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "ziptrak",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "fabric",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "hasPowderCoating",
                        type: "boolean",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "hasPelmet",
                        type: "boolean",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            case 'VERTICAL INDOOR':
                dataModal.headerKeys = ["CustOrd Date", "Ord Num", "Req Date", "Days OD", "Customer", "Qty", "Cust Ord Num", "Order Total", "Notes", "Fabric", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "verticaL_IN",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "fabric",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            case 'ROLLER & ROMAN':
                dataModal.headerKeys = ["CustOrd Date", "Ord Num", "Req Date", "Days OD", "Customer", "Roll", "Rom", "Cust Ord Num", "Order Total", "Notes", "Fabric", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "roller",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "roman",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "fabric",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            case 'FOLDING ARM AWNINGS':
                dataModal.headerKeys = ["Ord Date", "Ord Num", "Req Date", "Days OD", "Customer", "Qty", "Cust Ord Num", "Order Total", "Notes", "Fabric", "PwdrC?", "Pelmet?", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "faa",
                        type: "sum",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "fabric",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "hasPowderCoating",
                        type: "boolean",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "hasPelmet",
                        type: "boolean",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            case 'PANEL TRACKS':
                dataModal.headerKeys = ["CustOrd Date", "Ord Num", "Req Date", "Days OD", "Customer", "Qty", "Cust Ord Num", "Order Total", "Notes", "Fabric", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "pt",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "fabric",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            case 'BSAMP':
                dataModal.headerKeys = ["Ord Date", "Ord Num", "Req Date", "Days OD", "Customer", "Qty", "Cust Ord Num", "Order Total", "Notes", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "bsamp",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
            default:
                dataModal.headerKeys = ["Ord Date", "Ord Num", "Req Date", "Days OD", "Customer", "Qty", "Cust Ord Num", "Order Total", "Notes", "WH Status"];
                dataModal.dataKeys = [
                    {
                        key: "custOrdDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "ordnum",
                        type: "link",
                        operation: "count",
                        operationResult: 0
                    }, {
                        key: "custRequiredDate",
                        type: "date",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "daysOverDue",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "delCompany",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "other",
                        type: "default",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "custOrdNum",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "categoryOrderTotal",
                        type: "currency",
                        operation: "sum",
                        operationResult: 0
                    }, {
                        key: "notes",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }, {
                        key: "department",
                        type: "default",
                        operation: "none",
                        operationResult: ""
                    }];
                break;
        }

        return dataModal;
    }


    render() {
        let {ordersByProduct, summaryOn} = this.props;

        return (
            <div>{
                (ordersByProduct || []).map((product, index) => {
                    return <div key={index} id={'#' + product.productName} className={"pt-4"}>
                        <Card>
                            <CardHeader>
                                <h5>{product.productName} at {summaryOn} </h5>
                            </CardHeader>

                            <CardBody>
                                <OrdersTable key={index} orders={product.orders}
                                             dataModal={this.getKeys(product.productName)}/>
                            </CardBody>
                        </Card>
                    </div>

                })
            }
            </div>
        );
    }
}

