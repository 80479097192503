import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    BreadcrumbItem,
    Breadcrumb
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerService from "../../services/CustomerService";
import RecentStatements from '../../components/debtor/RecentStatements';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import {handleErrorMessage} from "../../services/CommonService";


export default class ViewDetailedStatemnet extends Component {
    constructor(props) {
        super(props);

        let accountID = "";
        if (props.location.hash) {
            accountID = props.location.hash.replace('#', '');
        }
        this.state = {
            accountId: accountID,
            
        };
        this.customerService = new CustomerService();
        this.handleAccountChange = this.handleAccountChange.bind(this);
        
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.location.hash) {
            //received accountId in props
            if (nextProps.location.hash) {
                let accountID = nextProps.location.hash.replace('#', '');
                this.refresh(accountID);
            }
        }
    }


    componentDidMount() {
        if (this.state.accountId) {
            //received accountId in props
            this.refresh(this.state.accountId);
        } else {
            // Default, trying to get first accountId from db and then refreshing with the same
            this.customerService.searchFirstDebtorAccountCompany("").then(response => {
                if (response.data) {
                    for (let i in response.data) {
                        this.refresh(response.data[i].accountId);
                        break;
                    }
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleAccountChange(accountId) {
        this.setState({ accountId: accountId });
    }

    refresh(accountId) {
        this.handleAccountChange(accountId);
    }

   
    render() {
        const { accountId } = this.state;


        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/customer')}>Customer</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">View Statement</BreadcrumbItem>
                </Breadcrumb>

                <Row>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        <h6>Search Customer:</h6>
                        <SearchDebtorAcccount handleAccountChange={this.handleAccountChange}
                            selectedAccountID={accountId}
                            defaultAccountID={accountId}
                            includeChildren={true}
                            excludeClosedandInactive={false} />
                        <br />
                    </Col>
                </Row>

                <br />
                <Card>
                    <CardHeader><h6>View Statements</h6></CardHeader>
                    <CardBody>
                        <RecentStatements accountId={accountId} />
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        )
    };
}
