import React, {Component} from 'react';
import dispatchOrderService from '../../services/DispatchOrderService';
import AsyncSelect from 'react-select/async';
import {concatenateStrings} from "../../services/CommonService";


export default class SearchDespatchReceiver extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: {},
            options: [],
        };

        this.loadOptions = this.loadOptions.bind(this);

    }


    componentDidMount() {

        let id = this.props.defaultReceiverID ? this.props.defaultReceiverID : this.props.selectedReceiverID;

        this.setState({selected: {value: id ? id : null, label: ""}}, () => {
            //defaultOptions on load
            this.fetchOptions({ID: id, searchText: ""});
        });

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selected && this.state.selected.value !== nextProps.selectedReceiverID) {
            //load externally selectedItem
            let id = nextProps.selectedReceiverID ? nextProps.selectedReceiverID : null;
            this.setState({selected: {value: id, label: ""}}, () => {
                //defaultOptions on load
                this.fetchOptions({ID: id, searchText: ""});
            });
        }
    }


    handleSuccess = (selectedReceiver) => {
        this.setState({selected: selectedReceiver}, () => {
            this.props.handleSelection(selectedReceiver);
            if (!selectedReceiver) {
                this.fetchOptions({ID: null, searchText: ""}); //defaultOptions on load
            }
        });
    };

    handleSuccessForDefault = (selectedReceiver) => {
        this.setState({selected: selectedReceiver});
        if (selectedReceiver) {
            this.props.handleSelection(selectedReceiver);
        }
    };

    fetchOptions(req, callback) {
        let options = [];
        this.setState({loading: true});
        dispatchOrderService.getShipmentReceiverList(req).then(response => {
            for (let i in response.data) {
                let option = {
                    label: concatenateStrings(" ", response.data[i].addressDescription, response.data[i].addressContactName),
                    value: response.data[i].id,
                    ...response.data[i]
                };
                options.push(option);
                if (this.state.selected) {
                    if (option.id === this.state.selected.value) {
                        this.handleSuccess(option);
                    }
                }
                if (this.props.defaultReceiverID) {
                    if (option.id === this.props.defaultReceiverID) {
                        this.handleSuccessForDefault(option);
                    }
                }

            }
            this.setState({options, loading: false});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                this.setState({loading: false});
                console.log(error.data)
            }
        );
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions({ID: null, searchText: inputValue}, callback)
            } else {
                callback(this.state.options);
            }
        }
    };

    render() {

        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.options}
                    loadOptions={this.loadOptions}
                    onChange={this.handleSuccess}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
