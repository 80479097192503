import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button, Spinner, Input, } from 'reactstrap';
import ImportComponent from "../components/WINOInventory/ImportExportComponents/ImportComponent";

export default class ImportExportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
        render() {
            return (
                <div>
                    <ImportComponent />
                </div>
            )
        }
    }