import axios from "../axios";
import axiosFileDownloader from "../axiosFileDownloader";


class SalesOrderService {

    static Instance() {
        return new SalesOrderService();
    }


    getAllKeywayOrders(requestBody, type) {
        return axios.post('api/keyway/sales-order/gateway?type=' + type, requestBody)
    }

    getKeywaySalesOrder(OrderID) {
        return axios.get('api/keyway/sales-order/getallwithattachments?Id=' + OrderID)
    }

    updateKeywaySalesOrder(OrdNum, option) {
        return axios.get('api/keyway/sales-order/update/status?OrdNum=' + OrdNum + '&Option=' + option)
    }

    getCountOfSubmittedOrder(branch) {
        return axios.get('api/dashboard/submitted/order/count?branch=' + branch);
    }

    getKeywaySalesOrderByDebtorInvoiceOrdNum(DebtorInvoiceOrdNum) {
        return axios.get('api/keyway/sales-order/getByDebtorInvoiceOrdNum?DebtorInvoiceOrdNum=' + DebtorInvoiceOrdNum)
    }

    exportAllKeywayGatewayOrders(requestBody, type) {
        return axiosFileDownloader.post('api/keyway/sales-order/gateway/export?type=' + type, requestBody)
    }

    getSaleOrderJobInfoProductLink(ordNums) {
        return axios.post('/api/order/items/product/details?ordNums='+ordNums);
    }

    getSaleOrderJobInfo(ordNum) {
        return axios.get('api/keyway/sales-order/job/info?OrdNum=' + ordNum);
    }



}

export default SalesOrderService.Instance();