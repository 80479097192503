import React, { useState } from 'react';
import { Component } from 'react';
import CustomerDashboardQuickLinks from './CustomerDashboard/CustomerDashboardQuickLinks';
import SalesCustomerOrders from '../../pages/sales/SalesCustomerOrders';
import queryString from 'query-string';

export default class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "",       // could be --> "submitted","completed","quote","cancelled-ord/quot"
            searchParams: {},
        };
    }

    componentDidMount() {
        let { activeTab } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            activeTab = searchParams.activeTab ? searchParams.activeTab : '';
            this.setState({ activeTab, searchParams });
        } else {
            this.setState({ activeTab: "submitted" });
        }
    }


    render() {
        let { activeTab } = this.state;

        return (
            <div>
                <div className="text-center">
                    <CustomerDashboardQuickLinks size={"sm"} />
                    <hr />
                </div>
                {
                    (activeTab !== null && activeTab !== undefined && activeTab !== "")
                        ?
                        <div className="my-order-layout">
                            <SalesCustomerOrders activeTab={this.state.activeTab} />
                        </div>
                        :
                        null
                }
            </div>
        );
    }
}