import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import queryString from 'query-string';

export default class CreditorIntroduction extends Component {


    render() {
        const {supplier, hasSupplierWritePrivilege} = this.props;
        return (
            <div>
                <ListGroup>
                    <ListGroupItem>
                        <strong>
                            {supplier.company}
                        </strong>
                        {hasSupplierWritePrivilege ?
                            <Link className="btn btn-sm btn-primary ml-2"
                                  to={"/creditors/manage/one?" + queryString.stringify({creditorID: supplier.accountID})}>
                                <i className="fa fa-pencil mr-2"
                                   aria-hidden="true"/>
                                Edit
                            </Link>
                            : null}
                    </ListGroupItem>
                    {(() => {
                        if (supplier && (supplier.phone || supplier.email)) {
                            return (
                                <ListGroupItem>
                                    <strong>General Contact: </strong>
                                    {(() => {
                                        if (supplier.phone || supplier.email) {
                                            return (
                                                <span>
                                                    <a
                                                        href={"tel:" + supplier.phone}>
                                                        <i className="text-muted  fa fa-phone"
                                                           aria-hidden="true"/>
                                                        &nbsp;{supplier.phone}
                                                    </a>&nbsp;&nbsp; &nbsp;&nbsp;</span>)
                                        }
                                    })()}
                                    {(() => {
                                        if (supplier && supplier.email) {
                                            return (
                                                <span>
                                                    <a href={"mailto:" + supplier.email}>
                                                        <i className="text-muted fa fa-envelope-o"
                                                           aria-hidden="true"/>
                                                        &nbsp;{supplier.email}</a>
                                                </span>
                                            )
                                        }
                                    })()}
                                </ListGroupItem>)
                        }
                    })()}

                    {(() => {
                        if (supplier && supplier.supplierAddress) {
                            return <ListGroupItem>
                                <strong>Address: </strong>
                                <a href={"http://maps.google.com/?q=" + supplier.supplierAddress}
                                   target="_blank">
                                    <i className="text-muted fa fa-map-marker"
                                       aria-hidden="true"/>
                                    &nbsp;{supplier.supplierAddress}
                                </a>
                            </ListGroupItem>
                        }
                    })()}


                </ListGroup>
            </div>
        )
    };
}
