import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import axiosFileUploader from './axiosFileUploader';

class FreightService {

    static Instance() {
        return new FreightService();
    }

    getFreightRules(category, accountID) {
        switch (category) {
            case "core":
                return this.getCoreFreightRules();
            case "default":
                return this.getDefaultFreightRules();
            case "customer":
                return this.getCustomerFreightOptions(accountID);
            default:
                return this.getDefaultFreightRules();
                break;
        }
    }

    saveFreightRule(category, request) {
        switch (category) {
            case "core":
                return this.saveCoreFreightRules(request);
            case "default":
                return this.saveDefaultFreightRules(request);
            case "customer":
                return this.saveCustomerFreightRules(request);
            default:
        }
    }

    getCoreFreightRules() {
        return axios.get('api/freight/rule/core/list');
    }

    getDefaultFreightRules() {
        return axios.get('api/freight/rule/default/list');
    }

    getCustomerFreightRules(accountID) {
        return axios.get('api/freight/rule/customer/list?accountID=' + accountID);
    }


    saveCoreFreightRules(request) {
        if (request.id && request.id > 0) {
            return this.updateCoreFreightRule(request);
        } else {
            return this.createCoreFreightRule(request);
        }
    }

    saveDefaultFreightRules(request) {
        if (request.id && request.id > 0) {
            return this.updateDefaultFreightRule(request);
        } else {
            return this.createDefaultFreightRule(request);
        }
    }

    saveCustomerFreightRules(request) {
        if (request.id && request.id > 0) {
            return this.updateCustomerFreightRule(request);
        } else {
            return this.createCustomerFreightRule(request);
        }
    }


    createCoreFreightRule(request) {
        return axios.post('api/freight/rule/core/save', request);
    }

    updateCoreFreightRule(request) {
        return axios.put('api/freight/rule/core/update', request);
    }

    createDefaultFreightRule(request) {
        return axios.post('api/freight/rule/default/save', request);
    }

    updateDefaultFreightRule(request) {
        return axios.put('api/freight/rule/default/update', request);
    }

    createCustomerFreightRule(request) {
        return axios.post('api/freight/rule/customer/save', request);
    }

    updateCustomerFreightRule(request) {
        return axios.put('api/freight/rule/customer/update', request);
    }

    deleteFreightRule(id) {
        return axios.delete('api/freight/rule/delete?ID=' + id);
    }

    getCustomerFreightOptions(accountID) {
        return axios.get('api/freight/rule/method/customer-options?accountID=' + accountID);
    }

    generateFreightRuleTemplate() {
        return axiosFileDownloader.get('api/freight/rule/generate/template');
    }

    importFrieghtRule(req) {
        return axiosFileUploader.post('api/freight/rule/import', req);
    }

}

export default FreightService.Instance();