import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';

export default class CustomerService {

    searchCustomer(accountId) {
        return axios.get('api/customer/details?accountId=' + accountId);
    }

    getCustomerOutstandingBalance(accountID) {
        return axios.post('api/customer/outstanding/balance?accountID=' + accountID);
    }

    searchDebtorAccountCompany(requestBody) {
        return axios.post('api/customer/account/company', requestBody);
    }

    searchFirstDebtorAccountCompany(accountId) {
        const request = JSON.stringify({accountId: accountId});
        return axios.post('api/customer/account/company/first', request);
    }

    getCustomersStat() {
        return axios.get('api/dashboard/customer/stats');
    }

    getCustomersByCategoryStat() {
        return axios.get('api/dashboard/customer/by-category/stats');
    }

    getDiscountForMasterGroups(accountId) {
        return axios.post('api/customer/getDiscountForMasterGroups', accountId);
    }

    createDiscount(discount) {
        return axios.post('api/customer/saveDiscount', discount);
    }

    getCustomerChildAccounts(accountId) {
        return axios.post('api/customer/childs', accountId);
    }

    getOutstandingOrderCount(accountID) {
        return axios.post('api/order/purchase/open/count?accountID=', accountID)
    }

    getOutstandingOrderValue(accountID) {
        return axios.post('api/order/purchase/open/value?accountID=', accountID)
    }

    getOutstandingQuotesCount(accountID) {
        return axios.post('api/sales/orders/quotes/count?accountID=', accountID)
    }

    getOutstandingQuotesValue(accountID) {
        return axios.post('api/sales/orders/quotes/value?accountID=', accountID)
    }

    getOutstandingOrdersCount() {
        return axios.post('api/dashboard/outstanding/sales/count')
    }

    getOutstandingQuotesCount() {
        return axios.post('api/dashboard/outstanding/quotes/count')
    }

    getOutstandingTotalAmount() {
        return axios.post('api/dashboard/outstanding/amount');
    }

    getDiscount(accountID) {
        return axios.get('api/customer/discount/product/all/self?accountID=' + accountID);
    }

    /* addFAQ(FAQ) {
         return axios.post('api/faq/Add', FAQ);
     }
    */

    getCustomer_recentStatements(requestBody) {
        return axios.post('api/customer/RecentStatements/Customer', requestBody);
    }

    updateDebtorSalesRep(accountID, repCode) {
        return axios.put('api/customer/update/sales-rep?accountID=' + accountID + '&repCode=' + repCode);
    }

    /*fetchDebtorOtherEmails(accountID) {
        return axios.get('api/customer/Get/OtherEmails?AccountId=' + accountID);
    }
    
    AddDebtorOtherEmails(requestBody) {
        return axios.post('api/customer/OtherEmails', requestBody);
    }*/

    /*NO LONGER USED*/

    CustomerMonthlySalesbyYear(AccountId, year, includeChildAccount) {
        return axios.get('api/customer/monthly-sales/barChart?AccoundId=' + AccountId + '&year=' + year + '&IncludeChildAccount=' + includeChildAccount);
    }

    CustomerMonthlyPurchasedProducts(AccountID, year, includeChildAccount) {
        return axios.get('api/customer/monthly/purchased/products?AccoundID=' + AccountID + '&Year=' + year + '&IncludeChildAccount=' + includeChildAccount);
    }

    CustomerMonthlySalesbyYearLineChart(AccountId, years, includeChildAccount) {
        return axios.get('api/customer/monthly-sales/lineChart?AccoundId=' + AccountId + '&years=' + years + '&IncludeChildAccount=' + includeChildAccount);
    }

    getCustomerAccounts(req) {
        return axios.post('api/customer/accounts/list', req);
    }

    fetchAccountIDRegisteredAsUser(accountIDs) {
        return axios.post('api/admin/user/list/by/accountID/registered', accountIDs);
    }

    getDebtorPaymentTerms() {
        return axios.get('api/customer/getDebtorPaymentTerms');
    }

    getDebtorCategory() {
        return axios.get('api/customer/getDebtorCategory');
    }

    getDebtorAccountStatus() {
        return axios.get('api/customer/getDebtorAccountStatus');
    }

    updateDebtor(req) {
        return axios.post('api/customer/update', req);
    }

    generateDebtorSummaryInExcelReport(requestBody) {
        return axiosFileDownloader.post('api/customer/GenerateDebtorAccountSummaryInExcel', requestBody);
    }

    getDebtorReceipts(req) {
        return axios.post('api/customer/receipts', req);
    }

    getCompanyName(AccoundID) {
        return axios.get('api/customer/account/name?AccoundId=' + AccoundID);
    }

    fetchAlreadyRegisteredEmails(emails) {
        return axios.get('api/customer/IsInvitable');
    }

    updateEmailAndSendSignupInvite(accountID, email) {
        return axios.post('api/customer/invite/sign-up/invitation/send?accountID=' + accountID + '&email=' + email);
    }

    downloadTransactionStatement(reqeustBody, options) {
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.post('api/customer/transaction/statement/download', reqeustBody, options);
        } else {
            return axiosFileDownloader.post('api/customer/transaction/statement/download', reqeustBody);
        }
    }

    getDebtorCreditTerms() {
        return axios.get('api/customer/getDebtorCreditTerms');
    }

    updateDebtorDeliveryPreference(request) {
        return axios.post('api/customer/preference/courier/update', request);
    }

    updateDebtorCreditTerm(type, accountID, CreditTermID, CreditLimit) {
        if (type === "CreditTerm") {
            return axios.post('api/customer/creditTerm/update?accountID=' + accountID + '&CreditTermID=' + CreditTermID + '&Type=' + type);
        }
        else {
            return axios.post('api/customer/creditLimit/update?accountID=' + accountID + '&CreditLimit=' + CreditLimit + '&Type=' + type);
        }
    }

    getDebtorPreference(accountID) {
        return axios.get('api/customer/preference?accountID=' + accountID);
    }

    listDebtorPreferences(req) {
        return axios.post('api/customer/preference/list', req);
    }

    saveOrUpdateDebtorPreference(req) {
        return axios.post('api/customer/preference/save', req);
    }

    listChildAccountsPreferences(accountID) {
        return axios.get('api/customer/preference/child-accounts/list?AccountID=' + accountID);
    }

    getCustomerInviteList(request) {
        return axios.post('api/customer/accounts/signup-invitation/list', request);
    }

    getCustomerSalesYearly(accountID) {
        return axios.get('api/customer/sales/yearly?AccountID=' + accountID);
    }

    getCustomerLeaderBoardList(year) {
        return axios.get('api/customer/leaderboard/list?year=' + year);
    }

    getCustomerSalesByFY(accountID, yearNum, includeChildAccount) {
        return axios.get('api/customer/sales/performance/financial-year?AccountID=' + accountID + '&UptoPreviousYear=' + yearNum + '&IncludeChildAccount=' + includeChildAccount);
    }

    checkAccountIDIsParent(accountID) {
        return axios.get('api/customer/check/account/isParent?AccountID=' + accountID);
    }

    getDebtorStatementEmails(accountID) {
        return axios.get('api/customer/statement/email?AccountID=' + accountID);
    }

    generateNewAccountID(type, accountID) {
        return axios.get('api/customer/generate/new/accountID?type=' + type + "&accountID=" + accountID);
    }

    sendOutstandingPaymentEmail(request) {
        return axios.post('api/customer/outstandingPayment/sendEmail', request);
    }

    getRecipientEmailIDs(accountID) {
        return axios.get('api/customer/outstandingPayment/getRecipientEmailIDs?accountID=' + accountID);
    }
}