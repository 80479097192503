import {rollerBlindLabelStore} from "../default/RollerBlindLabelStore";
import {ziptrakExternalLabelStore} from "../default/ZiptrakExternalLabelStore";
import {ziptrakInternalLabelStore} from "../default/ZiptrakInternalLabelStore";
import {panelTrackLabelStore} from "../default/PanelTrackLabelStore";
import {venetianLabelStore} from "../default/VenetianLabelStore";
import {verticalIndoorLabelStore} from "../default/VerticalIndoorLabelStore";
import {verticalOutdoorLabelStore} from "../default/VerticalOutdoorLabelStore";
import {genericLabelStore} from "../default/GenericLabelStore";
import {foldingArmAwningLabelStore} from "../default/FoldingArmAwningLabelStore";
import {hospitalRangeLabelStore} from "../default/HospitalRangeLabelStore";
import {widescreenLabelStore} from "../default/WidescreenLabelStore";
import {curtainTrackCustomLabelStore} from "../default/CurtainTrackCustomLabelStore";
import {romanBlindLabelStore} from "../default/RomanBlindLabelStore";
import {projectionAwningLabelStore} from "../default/ProjectionAwningLabelStore";
import {skinOnlyLabelStore} from "../default/SkinOnlyLabelStore";
import {customLabelStore} from "../default/CustomLabelStore";
import {quiklokLabelStore} from "../default/QuiklokStore";
import {productConstant} from "../../../../../ProductGroups";

export function getSalesOrderItemProductLabelStore(productCode) {
    let store = null;
    switch (productCode) {
        case productConstant.ROLLER_BLINDS_SINGLE.code:
        case productConstant.ROLLER_BLINDS_DOUBLE.code:
        case "ROL":
            store = rollerBlindLabelStore;
            break;
        case productConstant.ROMAN_BLINDS.code:
            store = romanBlindLabelStore;
            break;
        case productConstant.ZIPTRAK_EXTERNAL.code:
            store = ziptrakExternalLabelStore;
            break;
        case productConstant.ZIPTRAK_INTERNAL.code:
            store = ziptrakInternalLabelStore;
            break;
        case productConstant.VERTICAL_INDOOR.code:
            store = verticalIndoorLabelStore;
            break;
        case productConstant.VERTICAL_OUTDOOR.code:
            store = verticalOutdoorLabelStore;
            break;
        case productConstant.FOLDING_ARM_AWNINGS.code:
            store = foldingArmAwningLabelStore;
            break;
        case productConstant.VENETIANS.code:
            store = venetianLabelStore;
            break;
        case productConstant.PANEL_TRACKS.code:
            store = panelTrackLabelStore;
            break;
        case productConstant.HOSPITAL_RANGE.code:
            store = hospitalRangeLabelStore;
            break;
        case productConstant.WIDESCREEN.code:
            store = widescreenLabelStore;
            break;
        case productConstant.CURTAIN_TRACKS.code:
            store = curtainTrackCustomLabelStore;
            break;
        case productConstant.PROJECTION_AWNINGS.code:
            store = projectionAwningLabelStore;
            break;
        case productConstant.SKIN_ONLY.code:
            store = skinOnlyLabelStore;
            break;
        case productConstant.CUSTOM.code:
            store = customLabelStore;
            break;
        case productConstant.SHP_CTS.code:
            store = quiklokLabelStore;
            break;
        default:
            store = genericLabelStore;
            break;
    }
    return store;
}