export const MONTHS = [
    {
        shortName: "Jan",
        fullName: "January",
        monthNumber: 1,
        momentMonthNumber: 0,
        financialYearMonthNumber: 7,
        financialYearPart: "B"

    }, {
        shortName: "Feb",
        fullName: "February",
        monthNumber: 2,
        momentMonthNumber: 1,
        financialYearMonthNumber: 8,
        financialYearPart: "B"

    }, {
        shortName: "Mar",
        fullName: "March",
        monthNumber: 3,
        momentMonthNumber: 2,
        financialYearMonthNumber: 9,
        financialYearPart: "B"

    }, {
        shortName: "Apr",
        fullName: "April",
        monthNumber: 4,
        momentMonthNumber: 3,
        financialYearMonthNumber: 10
        , financialYearPart: "B"

    }, {
        shortName: "May",
        fullName: "May",
        monthNumber: 5,
        momentMonthNumber: 4,
        financialYearMonthNumber: 11,
        financialYearPart: "B"

    }, {
        shortName: "Jun",
        fullName: "June",
        monthNumber: 6,
        momentMonthNumber: 5,
        financialYearMonthNumber: 12,
        financialYearPart: "B"

    }, {
        shortName: "Jul",
        fullName: "July",
        monthNumber: 7,
        momentMonthNumber: 6,
        financialYearMonthNumber: 1,
        financialYearPart: "A"

    }, {
        shortName: "Aug",
        fullName: "August",
        monthNumber: 8,
        momentMonthNumber: 7,
        financialYearMonthNumber: 2,
        financialYearPart: "A"

    }, {
        shortName: "Sep",
        fullName: "September",
        monthNumber: 9,
        momentMonthNumber: 8,
        financialYearMonthNumber: 3,
        financialYearPart: "A"

    }, {
        shortName: "Oct",
        fullName: "October",
        monthNumber: 10,
        momentMonthNumber: 9,
        financialYearMonthNumber: 4,
        financialYearPart: "A"

    }, {
        shortName: "Nov",
        fullName: "November",
        monthNumber: 11,
        momentMonthNumber: 10,
        financialYearMonthNumber: 5,
        financialYearPart: "A"


    }, {
        shortName: "Dec",
        fullName: "December",
        monthNumber: 12,
        momentMonthNumber: 11,
        financialYearMonthNumber: 6,
        financialYearPart: "A"

    },
];

export const FINANCIAL_YEAR = {
    startMonth: MONTHS.find(m => m.financialYearMonthNumber === 1),
    endMonth: MONTHS.find(m => m.financialYearMonthNumber === 12),
};