import React, { Component } from 'react';
import {
    Row, Col, FormGroup, Label, Input, FormText, Form
} from 'reactstrap';
import SearchCity from '../../search/SearchCity';
import SearchState from '../../search/SearchState';
import SearchPostcode from '../../search/SearchPostcode';

export default class WarehouseAddress extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseObj: this.props.warehouseObj,
            formErrors: this.props.formErrors,

        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.warehouseObj != this.state.warehouseObj) {
            this.setState({ warehouseObj: nextProps.warehouseObj });
        }

        if (nextProps.formErrors != this.state.formErrors) {
            this.setState({ formErrors: nextProps.formErrors });
        }
    }

    handleChange(change, key) {
        let { warehouseObj } = this.state;
        let { address } = warehouseObj;
        address[key] = change;

        this.setState({ warehouseObj });
        this.props.handleAddressChange(warehouseObj);
    }

    render() {
        let { warehouseObj, formErrors } = this.state;
        return (
            <div>
                <Form>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="contactName">Contact Person</Label>
                                <Input autoComplete="off" type="text" name="contactName"
                                    invalid={formErrors.contactName !== undefined}
                                    onChange={(e) => this.handleChange(e.target.value, 'contactName')}
                                    value={warehouseObj.address.contactName}
                                    placeholder="Enter Contact Person here." />
                                {!formErrors.contactName &&
                                    <FormText
                                        color="danger"> {formErrors.contactName}</FormText>}
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input autoComplete="off" type="number" name="phone"
                                    onChange={(e) => this.handleChange(e.target.value, 'phone')}
                                    value={warehouseObj.address.phone}
                                    placeholder="Enter phone number here." id="Phone" />

                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="fax">Fax</Label>
                                <Input autoComplete="off" type="number" name="fax"
                                    onChange={(e) => this.handleChange(e.target.value, 'fax')}
                                    value={warehouseObj.address.fax}
                                    placeholder="Enter Fax no here." id="Fax" />
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="address1">Address 1</Label>
                                <Input autoComplete="off"
                                    invalid={formErrors.address1 !== undefined}
                                    type="text"
                                    name="address1"
                                    onChange={(e) => this.handleChange(e.target.value, 'address1')}
                                    value={warehouseObj.address.address1}
                                    placeholder="Address1" id="Address1" />
                                {formErrors.address1 != null > 0 &&
                                    <FormText
                                        color={"danger"}> {formErrors.address1}</FormText>}
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>

                            <FormGroup>
                                <Label for="address2">Address 2</Label>
                                <Input autoComplete="off" type="text" name="address2"
                                    onChange={(e) => this.handleChange(e.target.value, 'address2')}
                                    value={warehouseObj.address.address2}
                                    placeholder="Address2" id="Address2" />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="address3">Address 3</Label>
                                <Input autoComplete="off" type="text" name="address3"
                                    onChange={(e) => this.handleChange(e.target.value, 'address3')}
                                    value={warehouseObj.address.address3}
                                    placeholder="Address3" id="Address3" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>

                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="address4">Address 4</Label>
                                <Input autoComplete="off" type="text" name="address4"
                                    onChange={(e) => this.handleChange(e.target.value, 'address4')}
                                    value={warehouseObj.address.address4}
                                    placeholder="Address4" id="Address4" />
                            </FormGroup>
                        </Col>

                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="country">Country</Label>
                                <Input autoComplete="off" type="text" name="country"
                                    onChange={(e) => this.handleChange(e.target.value, 'country')}
                                    value={warehouseObj.address.country}
                                    placeholder="Country" id="Country" disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="City">City</Label>
                                <SearchCity
                                    handleSelection={(selectedCity) => this.handleChange(selectedCity, "city")}
                                    selected={warehouseObj.address.city || ''}
                                    defaultSelected={''}
                                    filters={{ state: warehouseObj.address.state || '', postcode: warehouseObj.address.postCode || '' }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="State">State</Label>
                                <SearchState
                                    handleSelection={(selectedState) => this.handleChange(selectedState, "state")}
                                    selected={warehouseObj.address.state || ''}
                                    defaultSelected={''}
                                    filters={{ city: warehouseObj.address.city || '', postcode: warehouseObj.address.postCode || '' }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>

                            <FormGroup>
                                <Label for="Postcode">Postcode</Label>
                                <SearchPostcode
                                    handleSelection={(selectedPostcode) => this.handleChange(selectedPostcode, "postCode")}
                                    selected={warehouseObj.address.postCode || ''}
                                    defaultSelected={''}
                                    filters={{ city: warehouseObj.address.city || '', state: warehouseObj.address.state || '' }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                </Form>
            </div>
        )
    }




}