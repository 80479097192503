import React, { Component } from 'react';
import { Col, Card, CardBody, CardText, CardHeader } from "reactstrap";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import OrderService from '../../services/OrderService';
import { findIndex, getDateString,handleErrorMessage } from '../../services/CommonService';
import { ToastContainer, toast } from 'react-toastify';
import BranchService from '../../services/BranchService';

const type = "PEN";
export default class PendingOrderCountCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingOrderCount: 0,
            loading: true,
            branches: [],
            branch: 0
        };

        this.orderService = new OrderService();
        this.branchService = new BranchService();
        this.getBranches = this.getBranches.bind(this);
        this.getOrderCount = this.getOrderCount.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getBranches();
    }

    getOrderCount() {
        this.orderService.getCountOfOrder(type, this.state.branch).then(response => {
            this.setState({ pendingOrderCount: response.data, loading: false })
        });
    }

    getBranches() {
        let { branch } = this.state;
        this.branchService.fetchBranches().then(response => {
            let branches = response.data;

            // default branch set to be BRISBANE
            let index = findIndex(branches, "shortName", "BRISBANE");
            if (index !== -1) {
                branch = branches[index].branchNum;
            }

            this.setState({ branches, branch }, () => this.getOrderCount());
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        const { pendingOrderCount, loading } = this.state;

        if (loading) {
            return null;
        }

        return (
            <Col xl={3} lg={3} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Pending Orders
                            <span style={{ float: "right" }}>
                                <Link to={"/sales/ordergateway?orderType=" + type}>
                                    <i className={"fa fa-pencil"} />
                                </Link>
                            </span></h6>
                    </CardHeader>
                    <CardBody>
                        <h5>
                            <NumberFormat value={pendingOrderCount}
                                displayType={'text'}
                                thousandSeparator={true} />
                            <small className={"ml-1"}>Orders</small>
                        </h5>
                    </CardBody>
                </Card>
                <ToastContainer />
            </Col>
        );
    }
}