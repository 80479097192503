import React, {Component} from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Table, Badge
} from 'reactstrap';

export default class DuplicateFabricProdCodeModal extends Component {
    render() {
        let {isOpen, toggle, records} = this.props;
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Duplicate Fabric ProdCode
                </ModalHeader>
                <ModalBody>
                    <Table responsive={true} striped bordered style={{minHeight: '250px'}}>
                        <thead>

                        <tr>
                            <th style={{minWidth: '80px'}}> Sr.No.</th>

                            <th style={{minWidth: '200px'}}>Fabric</th>
                            <th style={{minWidth: '200px'}}>Color name</th>
                            <th style={{minWidth: '200px'}} className={"text-center"}>Prod code</th>
                            <th style={{minWidth: '120px'}} className={"text-center"}>Width</th>
                            <th className="text-center" style={{minWidth: 100}}>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(records || []).map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.fabricName}</td>
                                <td>{item.color}</td>
                                <td>{item.stock ? item.stock.prodCode : ""}</td>
                                <td>{item.width}</td>
                                <td className="text-center">
                                    <div><span>{item.stock && item.stock.soldOut ?
                                        <Badge color={"danger"}>Sold Out</Badge> : ""}</span>
                                        <span>{item.stock && item.stock.discontinued ?
                                            <Badge color={"danger"}>Discontinued</Badge> : ""}</span>
                                    </div>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}><i
                            className="fa fa-times"/>&nbsp;
                            Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}