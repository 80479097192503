import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import WarehouseLocationService from '../../../services/WINOInventory/WarehouseLocationService';
import StockWarehouseLocationService from '../../../services/WINOInventory/StockWarehouseLocationService';
import SearchWINOnlineStock from '../../../components/search/SearchWINOnlineStock';
import {handleErrorMessage} from "../../../services/CommonService";


export default class WINOManageStockWarehouseLocation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockWarehouseLocationID: '',
            stockWarehouseLocationObj: {
                id: 0,
                stock: {},
                warehouseLocation: {},
                stockID:0,
                warehouseLocationID: 0,
                isActive: true,
                qoh: 0,
                minQtyAllocated: 0,
                maxQtyAllocated: 0,
                stockProdCode:''
            },
            warehouseLocations: [],
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getWarehouseLocations = this.getWarehouseLocations.bind(this);

    }

    componentDidMount() {
        let { stockWarehouseLocationID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            stockWarehouseLocationID = searchParams.stockWarehouseLocationID;
            this.setState({ stockWarehouseLocationID }, () => { this.refresh() })
        }
        this.getWarehouseLocations();

    }

    getWarehouseLocations() {
        WarehouseLocationService.listAllWarehouseLocations().then(response => {
            this.setState({ warehouseLocations: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    refresh() {
        if (this.state.stockWarehouseLocationID) {
            this.setState({ loading: true })

            StockWarehouseLocationService.getOneStockWarehouseLocation(this.state.stockWarehouseLocationID).then(response => {
                this.setState({ stockWarehouseLocationObj: response.data, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(change, key) {
       let { stockWarehouseLocationObj } = this.state;

        stockWarehouseLocationObj[key] = change;
        this.setState({ stockWarehouseLocationObj });
    }

    handleCancel() {
        this.props.history.push('/keyway-inventory/stock-warehouse-location');
    }

    validateForm(stockWarehouseLocationObj) {
        let formErrors = {};
        let valid = true;

        if ((stockWarehouseLocationObj.stockProdCode == undefined) || (stockWarehouseLocationObj.stockProdCode == null) || stockWarehouseLocationObj.stockProdCode === '' ) {
            formErrors.stockProdCode = "Please select stock";
            valid = false;
        }

        if (stockWarehouseLocationObj.warehouseLocationID === 0 || stockWarehouseLocationObj.warehouseLocationID === '' || stockWarehouseLocationObj.warehouseLocationID === '0' || stockWarehouseLocationObj.warehouseLocationID === null) {
            formErrors.warehouseLocationID = "Please select Warehouse location";
            valid = false;
        }

        if (stockWarehouseLocationObj.qoh == null || stockWarehouseLocationObj.qoh == '') {
            formErrors.qoh = "Please enter qoh";
            valid = false;
        }

        if (stockWarehouseLocationObj.minQtyAllocated == null || stockWarehouseLocationObj.minQtyAllocated == undefined || stockWarehouseLocationObj.minQtyAllocated == '') {
            formErrors.minQtyAllocated = "Please enter Min Qty Allocated";
            valid = false;
        }

        if (stockWarehouseLocationObj.maxQtyAllocated == null || stockWarehouseLocationObj.maxQtyAllocated == undefined || stockWarehouseLocationObj.maxQtyAllocated == '') {
            formErrors.maxQtyAllocated = "Please enter Max Qty Allocated";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.stockWarehouseLocationObj };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });
            StockWarehouseLocationService.saveOrUpdateStockWarehouseLocation(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.stockWarehouseLocationID) {
                        this.props.history.push('/keyway-inventory/stock-warehouse-location');
                        toast.success("Stock Warehouse Location saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Stock Warehouse Location updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }

                    this.setState({ saving: false, stockWarehouseLocationObj: response.data });
                }

            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            });
        }
    }



    render() {
        let { stockWarehouseLocationObj, formErrors, loading, stockWarehouseLocationID, warehouseLocations } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/keyway-inventory/stock-warehouse-location')}>Stock Warehouse Location</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!stockWarehouseLocationID ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Stock Warehouse Location</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Stock</Label>
                                            <SearchWINOnlineStock
                                                handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'stockProdCode')}
                                                selectedProdCode={stockWarehouseLocationObj.stockProdCode}
                                                defaultProdCode={stockWarehouseLocationObj.stockProdCode} />

                                            <FormText color="danger">{formErrors.stockProdCode}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Warehouse Location</Label>
                                            <Input
                                                invalid={formErrors.warehouseLocationID !== undefined}
                                                type="select"
                                                name="warehouseLocationID"
                                                value={stockWarehouseLocationObj.warehouseLocationID || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'warehouseLocationID')}>
                                                <option value="">Select Warehouse Location</option>
                                                {warehouseLocations.map((warehouseLocation, index) =>
                                                    <option key={index} value={warehouseLocation.id}>{warehouseLocation.name}</option>
                                                )}
                                            </Input>

                                            <FormText color="danger">{formErrors.warehouseLocationID}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>QOH</Label>
                                            <Input
                                                invalid={formErrors.qoh !== undefined}
                                                name="qoh"
                                                type="number"
                                                value={stockWarehouseLocationObj.qoh||''}
                                                onChange={(e) => this.handleChange(e.target.value, 'qoh')}/>
                                            <FormText color="danger">{formErrors.qoh}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Min Qty Allocated</Label>
                                            <Input
                                                invalid={formErrors.minQtyAllocated !== undefined}
                                                name="minQtyAllocated"
                                                type="number"
                                                value={stockWarehouseLocationObj.minQtyAllocated || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'minQtyAllocated')} />
                                            <FormText color="danger">{formErrors.minQtyAllocated}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Max Qty Allocated</Label>
                                            <Input
                                                invalid={formErrors.maxQtyAllocated !== undefined}
                                                name="maxQtyAllocated"
                                                type="number"
                                                value={stockWarehouseLocationObj.maxQtyAllocated || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'maxQtyAllocated')} />
                                            <FormText color="danger">{formErrors.maxQtyAllocated}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Form>

                        }
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving} >

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
