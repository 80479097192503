export const productToRemovedForRetail = [{
    Code: 'CTS',
    DisplayName:'SHP CTS'
},
    {
        Code: 'PPE',
        DisplayName:'SHP CTS'
    },
    {
        Code: 'PWD',
        DisplayName:'POWDER COATING'
    },
    {
        Code: 'ROLD',
        DisplayName:'ROLLER BLINDS (DOUBLE)'
    },
    {
        Code: 'ROLS',
        DisplayName:'ROLLER BLINDS'
    },
    {
        Code: 'ZIPI',
        DisplayName:'ZIPTRAK INTERIOR'
    },
    {
        Code: 'WID',
        DisplayName:'WIDESCREEN'
    },
    {
        Code: 'PRJ',
        DisplayName:'PRJ'
    }
];