import React, {Component} from 'react';
import {Col, Row, Card, CardHeader, CardBody} from "reactstrap";

import {Link} from "react-router-dom";
import PurchaseHistoryService from '../../services/PurchaseHistoryService';
import {isEmpty} from '../../services/CommonService';
import NumberFormat from "react-number-format";


export default class OrderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderStats: {},
            loading: true
        };
        this.purchaseHistoryService = new PurchaseHistoryService();
    }

    componentDidMount() {
        this.setState({loading: true});
        this.purchaseHistoryService.getCustomerOrderStats().then(data => {
            this.setState({orderStats: data, loading: false})
        });
    }

    render() {
        const {orderStats, loading} = this.state;

        if (loading) {
            return null;
        }

        let stats1 = [
            {
                label: "Ready-to-Pick",
                value: isEmpty(orderStats["ready To Pick"], "primitive") ? 0 : orderStats["ready To Pick"]
            },
            {
                label: "Active",
                value: isEmpty(orderStats["active"], "primitive") ? 0 : orderStats["active"]
            },
            {
                label: "Invoiced",
                value: isEmpty(orderStats["invoiced"], "primitive") ? 0 : orderStats["invoiced"]
            }
        ];

        let stats2 = [
            {
                label: "Being-Picked",
                value: isEmpty(orderStats["being Picked"], "primitive") ? 0 : orderStats["being Picked"]
            },
            {
                label: "Being-Dispatched",
                value: isEmpty(orderStats["being Despatched"], "primitive") ? 0 : orderStats["being Despatched"]
            }, {
                label: "Cancelled",
                value: isEmpty(orderStats["cancelled"], "primitive") ? 0 : orderStats["cancelled"]
            }
        ];


        return (
            <Col xl={4} lg={4} md={6} sm={12} xs={12} className={"p-2"}>

                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Orders
                        <span style={{float: "right"}}>
                            <Link to="/purchaseHistory"><i className={"fa fa-pencil"}/></Link>
                        </span></h6>
                    </CardHeader>
                    <CardBody>

                        <Row noGutters={false}>

                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Row noGutters={false}>
                                    {stats1.map((stat, index) => {
                                            return <Col key={index} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <p className="lead">
                                                    <small>{stat.label}</small>
                                                    &nbsp;<NumberFormat value={stat.value}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}/>
                                                </p>

                                            </Col>
                                        }
                                    )}
                                </Row>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Row noGutters={false}>
                                    {stats2.map((stat, index) => {
                                            return <Col key={index} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <p className="lead">
                                                    <small>{stat.label}</small>
                                                    &nbsp;<NumberFormat value={stat.value}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}/>

                                                </p>
                                            </Col>
                                        }
                                    )}</Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>


            </Col>);

    }
}