import React, {Component} from 'react';
import {Table,} from 'reactstrap';
import NumberFormat from "react-number-format";
import '../../../../../../assets/css/app/Print.css';

export default class FoldingArmAwningBOMModalPrint extends Component {

    getDescription(stocksByProdCode, prodCode) {
        let description = '';
        Object.keys(stocksByProdCode).map(key => {
            if (stocksByProdCode[key].prodCode === prodCode) {
                description = stocksByProdCode[key].description;
                return description;
            }
        });

        return description;
    }

    render() {

        let {product, itemIndex, stocksByProdCode, order} = this.props;
        return (
            <div>
                <h5>{order.debtorInvoiceOrdNum ? <span className="mr-2">#{order.debtorInvoiceOrdNum}</span> : null}Folding
                    Arm Awning</h5>
                <div>
                    <Table responsive={true} bordered={true} striped={true} size={"sm"}>
                        <thead>
                        <tr>
                            <th className="text-center">Qty</th>
                            <th>Mount</th>
                            <th>Frame colour</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="align-middle text-center">{product.items[itemIndex].configuration.quantity.selected.value}</td>
                            <td className="align-middle">{product.items[itemIndex].configuration.mount.selected.value.optionKey}</td>
                            <td className="align-middle">{product.items[itemIndex].configuration.frameColour.selected.value.optionKey}&nbsp;{product.items[itemIndex].configuration.framePowdercoatColour.selected.value}</td>
                        </tr>
                        </tbody>
                    </Table>
                    <Table responsive={true} bordered={true} striped={true} size={"sm"}>
                        <thead>
                        <tr>
                            <th>{product.items[itemIndex].configuration.width.selected.value + " x " + product.items[itemIndex].configuration.drop.selected.value.optionKey}</th>
                            <th>Item code</th>
                            <th>Description</th>
                            <th>
                                <div>Qty</div>
                                <div>with</div>
                                <div>deduction</div>
                                <div>calculated</div>
                            </th>
                            <th style={{minWidth: 150}}>Deduction</th>
                            <th>
                                <div>KW</div>
                                <div>measure</div>
                                <div>unit</div>
                            </th>
                            <th>
                                <div>Qty</div>
                                <div>with</div>
                                <div>wastage</div>
                                <div>calculated</div>
                            </th>
                            <th>
                                <div>SWISH</div>
                                <div>measure</div>
                                <div>unit</div>
                            </th>
                            <th>Charges</th>
                            <th>Disc.</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (product.items[itemIndex].stocks || []).filter(s => s.isVisibleInPartList).map((stock, stockIndex) => {
                                return <tr
                                    key={"" + product.items[itemIndex] + stockIndex}>
                                    {stock.attributeRowSpan > 0
                                        ? <td rowSpan={stock.attributeRowSpan}
                                              className="align-middle">
                                            <div>
                                                <strong>{stock.attribute}</strong>
                                            </div>
                                            <div>
                                                {stock.label}
                                            </div>
                                        </td>
                                        : null
                                    }
                                    <td className="align-middle">{stock.prodCode}</td>
                                    <td className="align-middle">{this.getDescription(stocksByProdCode, stock.prodCode)}</td>
                                    <td className="align-middle text-right">
                                        <NumberFormat
                                            value={(stock.keywayCalculatedQty + stock.deductionQty)}
                                            displayType={'text'}
                                            decimalScale={4}
                                            thousandSeparator={false}/>
                                    </td>
                                    <td className="align-middle text-right">
                                        {stock.cutWidth
                                            ? <div>
                                                <u className="mr-2">Cut Width</u>
                                                <NumberFormat
                                                    value={(stock.cutWidth)}
                                                    displayType={'text'}
                                                    decimalScale={4}
                                                    thousandSeparator={false}/>
                                            </div>
                                            : null
                                        }
                                        {
                                            stock.cutDrop
                                                ? <div>
                                                    <u className="mr-2">Cut Drop</u>
                                                    <NumberFormat
                                                        value={(stock.cutDrop)}
                                                        displayType={'text'}
                                                        decimalScale={4}
                                                        thousandSeparator={false}/>
                                                </div>
                                                : null
                                        }
                                    </td>
                                    <td className="align-middle text-center">{stock.keywayMeasurementUnit}</td>
                                    <td className="align-middle text-right">
                                        <NumberFormat
                                            value={stock.finalQty}
                                            displayType={'text'}
                                            decimalScale={4}
                                            thousandSeparator={false}/>
                                    </td>
                                    <td className="align-middle text-center">{stock.swishMeasurementUnit}</td>
                                    <td className="align-middle text-right">
                                        <NumberFormat
                                            prefix={'$'}
                                            value={stock.finalPrice}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={false}/></td>
                                    <td className="align-middle text-right">
                                        {
                                            stock.allowDiscount
                                                ? <NumberFormat
                                                    prefix={'$'}
                                                    value={stock.discVal}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={false}/>
                                                : 'N/A'

                                        }
                                    </td>
                                </tr>
                            })
                        }
                        <tr>
                            <td><strong>Sub-total</strong></td>
                            <td className="text-right" colSpan={8}>
                                <strong><NumberFormat
                                    prefix={'$'}
                                    value={product.items[itemIndex].pricing.unitPrice}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/></strong></td>
                            <td className="text-right" colSpan={1}>
                                <strong><NumberFormat
                                    prefix={'$'}
                                    value={product.items[itemIndex].pricing.unitDiscVal}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/></strong></td>
                        </tr>
                        <tr>
                            <td><strong>Price(ex.GST)</strong></td>
                            <td className="text-right" colSpan={9}><strong><NumberFormat
                                prefix={'$'}
                                value={product.items[itemIndex].pricing.unitPrice - product.items[itemIndex].pricing.unitDiscVal}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/></strong></td>
                        </tr>

                        </tbody>

                    </Table>
                </div>
            </div>
        )
    }
}