import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import {toast} from 'react-toastify';
import searchAddressService from '../../services/SearchAddressService';
import {handleErrorMessage} from "../../services/CommonService";

export default class SearchCity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: {
                label: "",
                value: "",
            },
            options: [],
            filters: props.filters,
            loadingOptions: false
        };

        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        let {selectedOption} = this.state;
        selectedOption.value = this.props.selected ? this.props.selected : this.props.defaultSelected;
        this.fetchOptions(selectedOption.value);
    }

    componentWillReceiveProps(nextProps) {
        let {selectedOption} = this.state;

        if ((nextProps.selected !== (selectedOption ? selectedOption.value : ""))
            || (nextProps.filters.state !== this.state.filters.state)
            || (nextProps.filters.postcode !== this.state.filters.postcode)) {
            //load externally selectedItem
            let selectedOption = {
                label: nextProps.selected,
                value: nextProps.selected,
            };
            this.setState({selectedOption, filters: nextProps.filters}, () => {
                this.fetchOptions(nextProps.selected);
            });

        }
    }

    fetchOptions(inputValue, callback) {
        let options = [];
        let {selectedOption, filters} = this.state;
        this.setState({loadingOptions: true});
        searchAddressService.searchCities((inputValue ? inputValue.trim() : ""), ((filters && filters.state) ? filters.state.trim() : ""), ((filters && filters.postcode) ? filters.postcode.trim() : "")).then(response => {

            let data = response.data;
            options = [];

            for (let i = 0; i < data.length; i++) {
                options[i] = {label: data[i], value: data[i]};
            }

            this.setState({options, loadingOptions: false}, () => {
                selectedOption = this.state.options.find(c => c.value.toUpperCase() === ((selectedOption && selectedOption.value) ? selectedOption.value.trim().toUpperCase() : ""));
                if (selectedOption) {
                    this.handleChange(selectedOption);
                }
            });
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                this.setState({loadingOptions: false});
                console.log(error);
                toast.error(handleErrorMessage(error));
            }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            if (inputValue && inputValue.length <= 1) {
                return this.fetchOptions("", callback)
            }
            callback(this.state.options);
        }
    };

    handleChange(change) {
        this.setState({selectedOption: change}, () => {
            this.props.handleSelection(change ? change.value : "");
        });
    };


    render() {
        let {selectedOption, loadingOptions, options} = this.state;
        return (
            <AsyncSelect
                isLoading={loadingOptions}
                isClearable={true}
                defaultOptions={options}
                loadOptions={this.prepareFetchOptions}
                onChange={this.handleChange}
                value={selectedOption}
                placeholder={"Search..."}
            />
        )
    }
}