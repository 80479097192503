import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import {
    Badge,
    CardBody,
    CardFooter,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table
} from "reactstrap";
import {changeFormatOfDateString, getDebtorInvoiceStatusColour, handleErrorMessage} from "../../services/CommonService";
import powdercoatingService from "../../services/PowdercoatingService";
import Pagination from 'react-js-pagination';
import classnames from 'classnames';
import {Link as Link} from "react-router-dom";
import queryString from 'query-string';
import ManagePowdercoatingPurchaseOrderStatus from '../../components/modal/ManagePowdercoatingPurchaseOrderStatus';
import {PRODUCTION_SCHEDULER_POWDERCOATING_ORDER_STATUS} from "../../store/AppConstants";
import LinesEllipsis from 'react-lines-ellipsis';

export default class PowdercoatingOrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            powdercoatingOrders: {
                request: {
                    pageRequest: {pageSize: 50, currentPage: 1},
                    sortRequest: {key: '', direction: false},
                    filterRequest: {purchaseOrderNum: '', supplierAccountID: ''}
                },
                response: {
                    records: [],
                    totalRecord: 0
                }
            },
            isLoading: false,
            statusType: this.props.statusType,
            isOpenPowdercoatingPurchaseOrderStatusModal: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.getPowdercoatingOrders = this.getPowdercoatingOrders.bind(this);
        this.togglePowdercoatingPurchaseOrderStatusModal = this.togglePowdercoatingPurchaseOrderStatusModal.bind(this);
        this.openPowdercoatingPurchaseOrderStatusModal = this.openPowdercoatingPurchaseOrderStatusModal.bind(this);
    }

    componentDidMount() {
        this.getPowdercoatingOrders();
    }

    getPowdercoatingOrders() {
        let {powdercoatingOrders, statusType} = this.state;
        this.setState({isLoading: true});
        powdercoatingService.getPowdercoatingOrders(powdercoatingOrders.request, statusType).then(response => {
            powdercoatingOrders.response = response.data;
            this.setState({isLoading: false, powdercoatingOrders});
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    handleChange(change, key) {
        let {powdercoatingOrders} = this.state;
        switch (key) {
            case "pageSize":
                powdercoatingOrders.request.pageRequest[key] = parseInt(change);
                powdercoatingOrders.request.pageRequest.currentPage = 1;
                this.getPowdercoatingOrders(powdercoatingOrders);
                break;
            case "currentPage":
                powdercoatingOrders.request.pageRequest[key] = change;
                this.getPowdercoatingOrders(powdercoatingOrders);
                break;
            case "sortKey":
                if (change.sortApplicable) {
                    if (powdercoatingOrders.request.sortRequest.key === change.key) {
                        powdercoatingOrders.request.sortRequest.direction = !powdercoatingOrders.request.sortRequest.direction;
                    }
                    else {
                        powdercoatingOrders.request.sortRequest.direction = false;
                        powdercoatingOrders.request.sortRequest.key = change.key;
                    }
                    this.getPowdercoatingOrders(powdercoatingOrders);
                }
                break;
            default:
                powdercoatingOrders.request.filterRequest[key] = change;
                powdercoatingOrders.request.pageRequest.currentPage = 1;
                this.setState({powdercoatingOrders}, () => {
                    this.getPowdercoatingOrders();
                });
        }
    }

    getHeading(filterRequest) {
        let {powdercoatingOrders} = this.state;
        let {request} = powdercoatingOrders;
        let heading = [
            {
                key: "sno",
                label: "Sr. No.",
                type: 'default',
                colSpan: 1,
                minWidth: 40,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 2,
                showColumn: true,
                width: 40,
                searchNode:
                    <div className="text-center">
                        {this.state.statusType === 'pending' ?
                            <Link
                                to={"/tools/powdercoating/purchase/order/manage"}
                                className="btn btn-sm ml-1 btn btn-primary">
                                <i className="fa fa-plus mr-2" aria-hidden="true"/>New Order
                            </Link> : null}
                    </div>,
                render: function (value, record, recordIndex, ref) {
                    return <div
                        class={"text-center align-middle"}>{((request.pageRequest.currentPage - 1) * request.pageRequest.pageSize) + (recordIndex + 1)}</div>;
                }
            },
            {
                key: "createdDate",
                label: "Created Date",
                type: 'text',
                colSpan: 1,
                minWidth: 85,
                sortApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                showColumn: true,
                width: 85,
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{changeFormatOfDateString(value, 'DD/MM/yyyy hh:mm:ss', 'DD MMM yyyy')}</span>;
                }
            },
            {
                key: "purchaseOrderNum",
                label: "Purchase Order No",
                type: 'ordNum',
                colSpan: 1,
                minWidth: 90,
                sortApplicable: true,
                valueClassName: "hoverableItem align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 90,
                searchNode: <Input type={"text"} name={"purchaseOrderNum"}
                                   placeholder={"Search"} value={filterRequest.purchaseOrderNum}
                                   onChange={(e) => this.handleChange(e.target.value, e.target.name)}/>,
                render: function (value, record, recordIndex, ref) {
                    return <div className="text-center">
                        <Link
                            to={"/tools/powdercoating/purchase/order/manage?" + queryString.stringify({purchaseOrdNum: value})}
                            className="btn btn-sm ml-1 btn btn-primary">
                            {value}
                        </Link>
                    </div>;
                }
            },
            {
                key: "ordNum",
                label: "Order No",
                type: 'ordNum',
                colSpan: 1,
                minWidth: 90,
                sortApplicable: true,
                valueClassName: "hoverableItem align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 90,
                searchNode: <Input type={"text"} name={"ordNum"}
                                   placeholder={"Search"} value={filterRequest.ordNum}
                                   onChange={(e) => this.handleChange(e.target.value, e.target.name)}/>,
                render: function (value, record, recordIndex, ref) {
                    if (value) {
                        return <div className="text-center">
                            <Link
                                to={"/sales/order/view?" + queryString.stringify({OrdNum: value})}
                                className="btn btn-sm ml-1 btn btn-primary">
                                {value}
                            </Link>
                        </div>;
                    }
                }
            },
            {
                key: "orderType",
                label: "Order Type",
                type: 'ordNum',
                colSpan: 1,
                minWidth: 70,
                sortApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 70,
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div className="text-center">
                        <span>{value}</span>
                    </div>;
                }
            },
            {
                key: "pickedBy",
                label: "Picked By",
                type: 'text',
                colSpan: 1,
                minWidth: 60,
                sortApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 60,
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "status",
                label: "PO Status",
                type: 'text',
                colSpan: 1,
                minWidth: 60,
                sortApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 60,
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    let color = "primary";
                    if (value === "Completed") {
                        color = "success";
                    }
                    else if (value === "Cancelled") {
                        color = "danger";
                    }
                    return <div className={"text-center align-middle"}> 
                        <Badge color={color}>{value}</Badge>
                    </div>;
                }
            },
            {
                key: "createdBy",
                label: "Created By",
                type: 'text',
                colSpan: 1,
                minWidth: 120,
                sortApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: '120px',
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "qty",
                label: "Qty",
                type: 'text',
                colSpan: 1,
                minWidth: 50,
                sortApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 50,
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div className="text-center">
                        <span>{value}</span>
                    </div>;
                }
            },
            {
                key: "description",
                label: "Description",
                type: 'text',
                colSpan: 1,
                minWidth: 150,
                sortApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: '120px',
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div className={"align-middle"}>
                        <LinesEllipsis text={value} maxLine='3' ellipsis='...' trimRight basedOn='letters'/>
                    </div>;
                }
            },
            {
                key: "productionJobStatus",
                label: "PC Status",
                type: 'text',
                colSpan: 1,
                minWidth: 50,
                sortApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 50,
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div className={"text-center align-middle"}>
                        {PRODUCTION_SCHEDULER_POWDERCOATING_ORDER_STATUS.includes(record.productionJobStatusID) ?
                            <Badge
                                color={getDebtorInvoiceStatusColour(record.productionJobStatusID)}>{value}</Badge> : null}
                    </div>;
                }
            },
            {
                key: "scheduleOn",
                label: "Schedule on",
                type: 'text',
                colSpan: 1,
                minWidth: 85,
                sortApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                showColumn: true,
                width: 85,
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{changeFormatOfDateString(value, 'DD/MM/yyyy hh:mm:ss', 'DD MMM yyyy')}</span>;
                }
            },
        ];
        return heading;
    }

    openPowdercoatingPurchaseOrderStatusModal(selectedRecord) {
        this.setState({isOpenPowdercoatingPurchaseOrderStatusModal: true, selectedRecord: selectedRecord});
    }

    togglePowdercoatingPurchaseOrderStatusModal(data) {
        this.setState({isOpenPowdercoatingPurchaseOrderStatusModal: data});
    }

    render() {
        let {isLoading, powdercoatingOrders, isOpenPowdercoatingPurchaseOrderStatusModal} = this.state;
        let {request, response} = powdercoatingOrders;
        let heading = this.getHeading(request.filterRequest);
        return (
            <div>
                <CardBody>
                    <Table bordered responsive size={"sm"} striped={true}>
                        <thead>
                        <tr>
                            {(heading || []).filter(x => x.showColumn).map((column, index) => {
                                return (
                                    <th key={index}
                                        onClick={(e) => this.handleChange(column, "sortKey")}
                                        colSpan={column.colSpan}
                                        className={column.labelClassName}
                                        style={{minWidth: column.minWidth, width: column.width}}>
                                        {column.label}
                                        {
                                            column.sortApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (request.sortRequest.key !== column.key),
                                                    "fa-sort-amount-asc": (request.sortRequest.key === column.key && request.sortRequest.direction),
                                                    "fa-sort-amount-desc": (request.sortRequest.key === column.key && !request.sortRequest.direction)
                                                })} aria-hidden="true"/> : null
                                        }

                                    </th>
                                )
                            })}
                        </tr>
                        <tr>
                            {(heading || []).filter(x => x.showColumn).map((column, index) => {
                                if (column.searchNodeColSpan > 0) {
                                    return (
                                        <td key={index} colSpan={column.searchNodeColSpan}
                                            className={"align-middle"}>{column.searchNode}</td>
                                    )
                                } else {
                                    return null;
                                }

                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {isLoading ? <Spinner color={"primary"}/> :
                            (response.records || []).map((item, itemIndex) =>
                                <tr key={itemIndex}>
                                    {(heading || []).filter(x => x.showColumn).map((column, indrx) => {
                                        return (
                                            <td key={indrx} className={column.valueClassName}>
                                                {column.render(item[column.key], item, itemIndex, this)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12}>
                            <div className={"text-left"} style={{maxWidth: 200}}>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>Show</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type={"select"}
                                           name={"pageSize"}
                                           value={request.pageRequest.pageSize}
                                           disabled={isLoading}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                                        <option value={10}>10 Rows</option>
                                        <option value={20}>20 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={12} sm={12}>
                            <div className={"float-right"}>
                                <Pagination
                                    activePage={request.pageRequest.currentPage}
                                    itemsCountPerPage={request.pageRequest.pageSize}
                                    totalItemsCount={response.totalRecords}
                                    pageRangeDisplayed={3}
                                    onChange={(e) => this.handleChange(e, "currentPage")}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'/>
                            </div>
                        </Col>
                    </Row>
                </CardFooter>
                <ToastContainer/>
                {isOpenPowdercoatingPurchaseOrderStatusModal
                    ?
                    <ManagePowdercoatingPurchaseOrderStatus
                        isOpen={isOpenPowdercoatingPurchaseOrderStatusModal}
                        toggle={this.togglePowdercoatingPurchaseOrderStatusModal}
                        selectedPurchaseOrderNum={this.state.selectedRecord.purchaseOrderNum}
                        selectedPurchaseStatus={this.state.selectedRecord.statusID}
                        refreshAgain={this.getPowdercoatingOrders}/>
                    : null
                }
            </div>
        )
    }
}