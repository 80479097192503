import {authHeader} from '../../helpers/auth-header';
import {config} from '../../helpers/config'
import axios from 'axios';

const dataModal = [
    {key: "id", label: "", type: "link", isValid: true},
    {key: "description", label: "Description", type: "", isValid: true},
    {key: "priority", label: "Priority", type: "priority", isValid: true},
    {key: "followUpType", label: "FollowUp Type", type: "followUpType", isValid: true},
    {key: "scheduledOn", label: "Due On", type: "date", isValid: true},
    {key: "accountID", label: "AccountID", type: "link", isValid: true},
    {key: "parentAccountID", label: "Parent AccountID", type: "link", isValid: false},
    {key: "assignedUser", label: "Assigned User", type: "", isValid: true},
    {key: "assignedBy", label: "Assigned By", type: "", isValid: false},
    {key: "assignedDate", label: "Assigned Date", type: "date", isValid: false},
    {key: "isClosed", label: "Is Closed", type: "boolean", isValid: false},
    {key: "closedBy", label: "Closed By", type: "", isValid: false},
    {key: "overdueDays", label: "Overdue Days", type: "overdueDays", isValid: false},
    {key: "closedDate", label: "Closed Date", type: "date", isValid: false},
    {key: "createdBy", label: "Created By", type: "", isValid: false},
    {key: "createdDate", label: "Created Date", type: "date", isValid: true},
    {key: "lastModifiedBy", label: "Last Modified By", type: "", isValid: false},
    {key: "lastModifiedDate", label: "Last Modified Date", type: "date", isValid: false}];

export default class TaskService {


    getTasks(req) {

        let requestBody = {
            filter: {
                accountID: req.filter.accountID,
                assignedUser: req.filter.assignedUser,
                isClosed: req.filter.isClosed,
                createdDateBetween: req.filter.createdDateBetween,
                dueDateBetween: req.filter.dueDateBetween
            },
            sort: {
                key: req.sorter.key,
                direction: req.sorter.ascending
            },
            page: {
                currentPage: req.page.currentPage,
                pageSize: req.page.pageSize,
            }

        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/crm/task/list'
        };
        return axios(request)
    }


    getTask(id) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/crm/task/one?id=' + id
        };
        return axios(request)
    }


    saveTask(task) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: task,
            url: config.apiUrl + 'api/crm/task/save'

        };
        return axios(request)

    }


    updateTask(task) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: task,
            url: config.apiUrl + 'api/crm/task/update'

        };
        return axios(request)

    }

    getDataModal() {
        return dataModal;
    }

    getTasksFromPreparedRequest(requestBody) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/crm/task/list'
        };
        return axios(request)
    }

}
