import React, {Component} from 'react';
import {Badge, Col, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {changeFormatOfDateString} from '../../../services/CommonService';
import stockReceiptService from '../../../services/stock/StockReceiptService';
import StockReceiptItems from "./StockReceiptItems";
import {Link} from "react-router-dom";

export default class StockReceiptEnquiryReadOnlyMode extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {
            stockReceipt
        } = this.props;
        let creditor = stockReceipt.creditor;
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                <h4>#{stockReceipt.stockReceiptID}
                                    <small>
                                        <Badge color={stockReceiptService.getStatusColor(stockReceipt.statusID)}
                                               className={"ml-2"}>
                                            {stockReceipt.status}
                                        </Badge>
                                    </small>
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {stockReceipt.ammendedBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(stockReceipt.dateCreated, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr/>
                    <div>
                        <div>

                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    creditor
                                        ? <>
                                        <ListGroupItem>
                                            <div style={{minWidth: 150}}>
                                                <p className={"pb-1 mb-1"}><strong>Supplier</strong></p>
                                                <div>
                                                    <Link
                                                        className="btn btn-primary btn-sm"
                                                        style={{color: "white"}}
                                                        title={"Click here to see account details"}
                                                        to={"/supplier/#" + creditor.accountID}
                                                    >{creditor.accountID}</Link>
                                                </div>
                                                <div><h6>{creditor.company}</h6></div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <div style={{minWidth: 150}}>
                                                <p className={"pb-1 mb-1"}>
                                                    <strong>Address</strong>
                                                </p>
                                                <a
                                                    href={"http://maps.google.com/?q=" + creditor.shippingAdrress}
                                                    target="_blank">
                                                    <div>
                                                        {creditor.address1}
                                                    </div>
                                                    <div>
                                                        {creditor.address2}
                                                    </div>
                                                    <div>
                                                        {creditor.city}
                                                    </div>
                                                    <div>
                                                        {creditor.state}{' '}{creditor.postCode}
                                                    </div>
                                                </a>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <div style={{minWidth: 150}}>
                                                <p className={"pb-1 mb-1"}>
                                                    <strong>Contact</strong>
                                                </p>
                                                {creditor.contactName
                                                    ? <p className={"pb-0"}>{creditor.contactName}</p>
                                                    : null
                                                }
                                                <div title={"Phone"}>
                                                    {
                                                        creditor.phone
                                                            ?
                                                            <a href={"tel:" + creditor.phone}> <i
                                                                className="text-muted fa fa-phone fa-fw mr-1"
                                                                aria-hidden="true"/>{creditor.phone}</a>
                                                            : null
                                                    }
                                                </div>
                                                <div title={"Fax"}>
                                                    {
                                                        creditor.fax
                                                            ?
                                                            <a href={"fax:" + creditor.fax}> <i
                                                                className="text-muted fa fa-fax fa-fw mr-1"
                                                                aria-hidden="true"/>{creditor.fax}</a>
                                                            : null
                                                    }
                                                </div>
                                                <div title={"Email"}>
                                                    {
                                                        creditor.email
                                                            ?
                                                            <a href={"mailto:" + creditor.email}> <i
                                                                className="text-muted fa fa-envelope fa-fw mr-1"
                                                                aria-hidden="true"/>{creditor.email}</a>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                        </>
                                        : null
                                }

                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250}}>
                                        <div>
                                            <p className={"pb-1 mb-1"}><strong>Completed on</strong></p>
                                            <div>
                                                {changeFormatOfDateString(stockReceipt.completedDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                            </div>
                                        </div>
                                        {
                                            stockReceipt.completedBy ?
                                                <div className="mt-2">
                                                    <p className={"pb-1 mb-1"}>
                                                        <strong>Completed By</strong>
                                                    </p>
                                                    <div>{stockReceipt.completedBy}</div>
                                                </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (stockReceipt.cancelledBy || stockReceipt.cancelledDate )
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {stockReceipt.cancelledBy ?
                                                    <div className="mt-2">
                                                        <p className={"pb-1 mb-1"}><strong>Cancelled By
                                                        </strong>
                                                        </p>
                                                        <div>{stockReceipt.cancelledBy}</div>
                                                    </div> : null
                                                }
                                                {
                                                    stockReceipt.cancelledDate
                                                        ? <div className="mt-2">
                                                            <p className={"pb-1 mb-1"}><strong>Cancelled date</strong></p>
                                                            <div>{changeFormatOfDateString(stockReceipt.cancelledDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }

                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250, overflowY: 'auto'}}>
                                        <p className={"pb-1 mb-1"}><strong>Notes</strong></p>
                                        {stockReceipt.notes}
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <hr/>
                        <div>
                            <StockReceiptItems
                                stockReceipt={stockReceipt}
                            />
                        </div>
                    </div>
                </div>

            </div>
        );

    }
}
