import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';


export default class SearchProductConfigurationStockWastageFormula extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: {},
            options: [],
            formulaID: ""
        };

        this.loadOptions = this.loadOptions.bind(this);

    }


    componentDidMount() {

        let formulaID = this.props.defaultFormulaID ? this.props.defaultFormulaID : this.props.selectedFormulaID;

        this.setState({selected: {value: formulaID, label: ""}}, () => {
            //defaultOptions on load
            this.fetchOptions({formulaID: this.state.selected.value, searchText: ""});
        });

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selected && this.state.selected.value !== nextProps.selectedFormulaID) {
            //load externally selectedItem
            this.fetchOptions({formulaID: nextProps.selectedFormulaID, searchText: ""});
        }
    }


    handleSuccess = (selectedFormula) => {
        let {selected, formulaID} = this.state;

        selected = selectedFormula;
        formulaID = selectedFormula ? selectedFormula.id : "";

        this.setState({selected, formulaID}, () => {
            this.props.handleSelection(selectedFormula);
            if (!selectedFormula) {
                this.fetchOptions({formulaID: null, searchText: ""}); //defaultOptions on load
            }
        });
    };

    handleSuccessForDefault = (selectedFormula) => {
        this.setState({selected: selectedFormula});
        if (selectedFormula) {
            this.props.handleSelection(selectedFormula);
            this.setState({
                selected: selectedFormula,
                formulaID: selectedFormula.id,
            });
        }
    };


    fetchOptions(req, callback) {
        let options = [];
        this.setState({loading: true});
        ProductConfigurationService.getProductConfigurationStockWastageFormulaDropdown(req).then(response => {
            let data = response.data;
            for (let i in data) {
                let option = {
                    label: data[i].description,
                    value: data[i].id,
                    id: data[i].id,
                    data: data[i]
                };
                options.push(option);
                if (this.props.defaultFormulaID) {
                    if (option.value === this.props.defaultFormulaID) {
                        this.handleSuccessForDefault(option);
                    }
                }
                if (this.state.selected) {
                    if (option.value === this.state.selected.value) {
                        this.handleSuccess(option);
                    }
                }
            }
            this.setState({options, loading: false});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                this.setState({loading: false});
                console.log(error.data)
            }
        );
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions({formulaID: null, searchText: inputValue}, callback)
            } else {
                callback(this.state.options);
            }
        }
    };

    render() {

        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.options}
                    loadOptions={this.loadOptions}
                    onChange={this.handleSuccess}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
