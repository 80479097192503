import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Spinner, Table} from "reactstrap";


const now = new Date();

export default function CustomerSalesByMonth(props) {
    let {isLoading, salesbyMonth, convertToDecimalplaces} = props;
    let currentMonth = now.getMonth() + 1; //january gives zero
    let currentYear = now.getFullYear();
    let previousYear = now.getFullYear()- 1;
    return <Card>
        <CardHeader><h6 className={"mb-0"}>
            Sales by Month
        </h6></CardHeader>
        <CardBody>
            {isLoading ? <Spinner color="primary"/> :
                <Table size={"sm"} responsive className={"mb-0"}>
                    <tbody>
                    <tr>
                        <td>Jan</td>
                        <td>{currentMonth > 1 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.jan)}</th>
                    </tr>
                    <tr>
                        <td>Feb</td>
                        <td>{currentMonth > 2 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.feb)}</th>
                    </tr>
                    <tr>
                        <td>Mar</td>
                        <td>{currentMonth > 3 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.mar)}</th>
                    </tr>
                    <tr>
                        <td>Apr</td>
                        <td>{currentMonth > 4 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.apr)}</th>
                    </tr>
                    <tr>
                        <td>May</td>
                        <td>{currentMonth > 5 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.may)}</th>
                    </tr>
                    <tr>
                        <td>June</td>
                        <td>{currentMonth > 6 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.jun)}</th>
                    </tr>
                    <tr>
                        <td>July</td>
                        <td>{currentMonth > 7 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.jul)}</th>
                    </tr>
                    <tr>
                        <td>Aug</td>
                        <td>{currentMonth > 8 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.aug)}</th>
                    </tr>
                    <tr>
                        <td>Sep</td>
                        <td>{currentMonth > 9 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.sep)}</th>
                    </tr>
                    <tr>
                        <td>Oct</td>
                        <td>{currentMonth > 10 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.oct)}</th>
                    </tr>
                    <tr>
                        <td>Nov</td>
                        <td>{currentMonth > 11 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.nov)}</th>
                    </tr>
                    <tr>
                        <td>Dec</td>
                        <td>{currentMonth > 12 ? currentYear : previousYear}</td>
                        <th>{convertToDecimalplaces(salesbyMonth.dec)}</th>
                    </tr>
                    </tbody>
                </Table>
            }
        </CardBody>
    </Card>;
}