import React, {Component} from 'react';
import {Col, Row, Card, CardHeader, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import axios from '../../services/axios'

const productNames = {
    "VENETIANS": "Venetians ",
    "COMPONENTS": "Components",
    "CURTAIN TRACKS": "Curtain Tracks",
    "FOLDING ARM AWNINGS": "FAA",
    "PANEL TRACKS": "Panel Tracks",
    "ROLLER & ROMAN": "Roller & Roman",
    "VERTOUT & ZIPTRAK": "Vertout & Ziptrak",
    "VERTICAL INDOOR": "Vertical Indoor",
};

export default class OutStandingProductOrderCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderStats: {},
            loading: true
        };
    }

    getOutstandingProductOrderCountByProductName() {
        return axios.post('api/dashboard/outstanding/product/count');
    }


    componentDidMount() {
        this.setState({loading: true});
        this.getOutstandingProductOrderCountByProductName().then(response => {
            this.setState({orderStats: response.data, loading: false})
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        const {orderStats, loading} = this.state;

        if (loading) {
            return null;
        }

        if ((!orderStats) || (!orderStats.length)) {
            return null;
        }

        let stats1 = [];
        let stats2 = [];

        let bucket =1 ;
        for (let i = 0; i < orderStats.length; i++) {
            if(orderStats[i].totalOrderQty){
                switch (bucket){
                    case 1:
                        stats1.push(orderStats[i]);
                        bucket = 2;
                        break;
                    case 2:
                        stats2.push(orderStats[i]);
                        bucket = 1;
                        break;
                }
            }
        }
        return (
            <Col xl={4} lg={4} md={6} sm={12} xs={12} className={"p-2"}>

                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Outstanding Orders Units
                        <span style={{float: "right"}}>
                            <Link to="/sales/orders/outstanding"><i className={"fa fa-pencil"}/></Link>
                        </span></h6>
                    </CardHeader>
                    <CardBody>
                        <Row noGutters={false}>

                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Row noGutters={false}>
                                    {stats1.map((stat, index) => {
                                            return <Col key={index} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <p className="lead">
                                                    <small>{productNames[stat.categoryGroupName]}</small>
                                                    &nbsp;<NumberFormat value={stat.totalOrderQty}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}/>
                                                </p>

                                            </Col>
                                        }
                                    )}
                                </Row>
                            </Col>

                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Row noGutters={false}>
                                    {stats2.map((stat, index) => {
                                            return <Col key={index} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <p className="lead">
                                                    <small>{productNames[stat.categoryGroupName]}</small>
                                                    &nbsp;<NumberFormat value={stat.totalOrderQty}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}/>
                                                </p>
                                            </Col>
                                        }
                                    )}</Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>);
    }

}