import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify';
import StockService from '../../services/StockService';
import {handleErrorMessage} from "../../services/CommonService";

export default class SearchFabricProdCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stock: props.selected ? props.selected : props.defaultSelected,
            stocks: [],
            loading: { stocks: false }
        };

        this.stockService = new StockService();
        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        let stock = this.props.selected ? this.props.selected : this.props.defaultSelected;
        let fabricName = "";
        if (stock) {
            fabricName = stock.fabricName;
        }
        this.fetchOptions(fabricName);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stock && this.state.stock) {
            if (nextProps.stock.fabricName !== this.state.stock.fabricName) {
                //load externally selectedItem
                this.fetchOptions(nextProps.stock.fabricName);
            }
        }
    }


    fetchOptions(inputValue, callback) {
        let stocks = [];
        let { stock, loading } = this.state;

        loading.stocks = true;
        this.setState({ loading });
        this.stockService.getFabricProdCodes(inputValue).then(response => {

            let data = response.data;
            for (let i in data) {
                let option = {
                    label: data[i].prodCode + (data[i].fabricName ? " | " + data[i].fabricName : ""),
                    value: data[i].prodCode,
                    id: data[i].prodCode,
                    data: data[i]
                };
                stocks.push(option);
                if (stock && stock.prodCode) {
                    if (option.value === stock.prodCode) {
                        this.handleChange(option); // for default purpose
                    }
                }
            }
            loading.stocks = false;
            this.setState({ stocks, loading });
            if (callback) {
                return callback(stocks)
            } else {
                return stocks;
            }
        }).catch(error => {
            loading.stocks = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error));
        }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            if (inputValue && inputValue.length <= 1) {
                return this.fetchOptions("", callback)
            }
            callback(this.state.stocks);
        }
    };

    handleChange(change) {
        if (change) {
            this.stockService.getStock(change.value).then(response => {
                let selected = response.data;
                selected.value = selected.prodCode;
                selected.label = selected.prodCode + (selected.fabricName ? " | " + selected.fabricName : "");
                this.setState({ stock: selected });
                this.props.handleSelection(response.data);
            }).catch(error => {
                toast.error(handleErrorMessage(error));
            })
        } else {
            this.setState({ stock: change });
            this.props.handleSelection(change);
            this.fetchOptions();
        }

    };


    render() {
        let { stock, loading, stocks } = this.state;
        return (
            <AsyncSelect
                cacheOptions
                isLoading={loading.stocks}
                isClearable={true}
                defaultOptions={stocks}
                loadOptions={this.prepareFetchOptions}
                onChange={this.handleChange}
                value={stock}
                placeholder={"Search..."}
            />
        )
    }
}