import React, { Component } from 'react';
import { Col, Row, Label, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { maxDate, minDate } from './../../components/OrderGatewayComponents/OrderGatewayCommon';

export default class TransactionsFilter extends Component {

    render() {
        let { transactionRequest } = this.props;
        return (
            <div>
                <Row className={"pb-3"}>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                        <div>
                            <DatePicker
                                className={"form-control"}
                                selected={transactionRequest.StartDate}
                                onChange={date => this.props.handleChange(date, "StartDate")}
                                selectsStart
                                startDate={transactionRequest.StartDate}
                                endDate={transactionRequest.EndDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                        <div>
                            <DatePicker
                                className={"form-control"}
                                selected={transactionRequest.EndDate}
                                onChange={date => this.props.handleChange(date, "EndDate")}
                                selectsEnd
                                startDate={transactionRequest.StartDate}
                                endDate={transactionRequest.EndDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Order No</Label>
                        <div>
                            <Input type={"search"}
                                placeholder={"search..."}
                                name={"TransID"}
                                value={transactionRequest.TransID}
                                onChange={(e) => this.props.handleChange(e.target.value, "TransID")} />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Type</Label>
                        <div>
                            <Input type={"text"}
                                placeholder={"search..."}
                                name={"Type"}
                                value={transactionRequest.Type}
                                onChange={(e) => this.props.handleChange(e.target.value, "Type")} />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Customer Order No</Label>
                        <div>
                            <Input type={"text"}
                                placeholder={"search..."}
                                name={"CustOrder"}
                                value={transactionRequest.CustOrder}
                                onChange={(e) => this.props.handleChange(e.target.value, "CustOrder")} />
                        </div>

                    </Col>

                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Ref</Label>
                        <div>
                            <Input type={"text"}
                                placeholder={"search..."}
                                name={"Ref"}
                                value={transactionRequest.jobRef}
                                onChange={(e) => this.props.handleChange(e.target.value, "jobRef")} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}