import React, {Component} from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Label, Input, Row, Col
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProductionCapacityService from '../../services/production/ProductionCapacityService';
import {addMonths, handleErrorMessage} from '../../services/CommonService';

const minDate = new Date();
const maxDate = addMonths(new Date(), 3, "momentObj");
export default class ProductionCapacitySaveModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            startDate: props.startDate,
            endDate: null,
            capacity: props.capacity,
            notes: props.notes,
            selectedProductionLineIds:this.props.selectedProductionLineIds,
            allSelected:this.props.allSelected
        };

        this.productionCapacityService = new ProductionCapacityService();
        this.setDate = this.setDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedProductionLineIds !== this.state.selectedProductionLineIds) {
            this.setState({selectedProductionLineIds:nextProps.selectedProductionLineIds});
        }

        if (nextProps.startDate !== this.state.startDate) {
            this.setState({startDate: nextProps.startDate, endDate: null});
        }

        if (nextProps.capacity !== this.state.capacity) {
            this.setState({capacity: nextProps.capacity});
        }

        if (nextProps.notes !== this.state.notes) {
            this.setState({notes: nextProps.notes});
        }
        if (nextProps.allSelected !== this.state.allSelected) {
            this.setState({allSelected: nextProps.allSelected});
        }
    }

    handleChange(value, key) {
        if (key === 'capacity') {
            value = parseInt(value);
        }
        this.setState({[key]: value});
    }

    handleCancel() {
        //parameters- 1.to close modal, 2 to refresh
        this.props.handlerCloseModal(false, false);
        this.setState({selectedProductionLineIds:[],allSelected:false});
    }

    setDate(date, key) {
        this.setState({[key]: date});
    }

    handleSubmit() {
        if (!this.state.startDate) {
            toast.info("Plase select start date");
            return;
        }

        if (isNaN(this.state.capacity)) {
            toast.info("Please enter capacity");
            return;
        }

        if(this.state.selectedProductionLineIds.length === 0 )
        {
            toast.info("Please choose Production lines");
            return;
        }
        /*if (!this.state.productionLineId) {
            toast.info("Please select production line");
            return;
        }*/

        var req = {
            startDate: moment(this.state.startDate).format('DD/MM/YYYY'),
            endDate: moment(this.state.endDate).format('DD/MM/YYYY'),
            capacity: this.state.capacity,
            ProductionLineIds: this.state.selectedProductionLineIds,
            notes: this.state.notes,
        }

        this.setState({saving: true});
        this.productionCapacityService.saveProductionCapacityBetweenDateRange(req).then(res => {
            if (res.status === 200 || res.status === '200') {
                toast.success("Saved");
                this.setState({saving: false});
                this.props.handlerCloseModal(false, true);
                this.setState({selectedProductionLineIds:[],allSelected:false});
            }
        }).catch(error => {
            this.setState({saving: false});
            toast.error(handleErrorMessage(error));
        });
    }

    selectProductionLine(Id){
        if(this.state.selectedProductionLineIds.includes(Id))
        { var array = [...this.state.selectedProductionLineIds];
            var index = array.indexOf(Id)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({selectedProductionLineIds: array,allSelected:false});
            }
        }
        else {
            this.state.selectedProductionLineIds.push(Id);
            this.setState({
                selectedProductionLineIds: this.state.selectedProductionLineIds
            });
        }
    }

    selectAllProductionLine(){
        if(this.state.allSelected)
        {
            this.setState({selectedProductionLineIds:[],allSelected:false});
        }
        else{
            {(this.props.productionLinesList || []).map((option,index)=>{
                    if(!this.state.selectedProductionLineIds.includes(option.productionLineId)) {
                        this.state.selectedProductionLineIds.push(option.productionLineId);
                        this.setState({
                            selectedProductionLineIds: this.state.selectedProductionLineIds
                        })
                    }
            }
            )}
            this.setState({allSelected:true});
        }
    }

    render() {
        let {isOpen, toggle, productionLinesList} = this.props;
        let {saving, startDate, endDate, capacity, notes, productionLineId,selectedProductionLineIds,allSelected} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">Production Capacity</h5>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Label>
                                <i className="fa fa-calendar mr-2" aria-hidden="true"/>Start-date
                            </Label>
                            <DatePicker
                                className="form-control"
                                selected={startDate}
                                onChange={date => this.setDate(date, "startDate")}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="dd/mm/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                minDate={minDate}
                                scrollableYearDropdown
                                withPortal
                            />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Label>
                                <i className="fa fa-calendar mr-2" aria-hidden="true"/>End-date
                            </Label>
                            <DatePicker
                                className="form-control"
                                selected={endDate}
                                onChange={date => this.setDate(date, "endDate")}
                                selectsEnd
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="dd/mm/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                minDate={startDate}
                                scrollableYearDropdown
                                withPortal
                            />
                        </Col>
                    </Row>
                    <p className="text-info"> In case of changing capacity for a single day, keep end date input empty</p>
                    <div className="mt-2 mb-2">
                        <Label>Capacity</Label>
                        <Input type={"number"}
                               name={"capacity"}
                               value={capacity}
                               placeholder={'Enter Capacity here'}
                               onChange={(e) => this.handleChange(e.target.value, 'capacity')}/>

                    </div>

                    <div className="mt-2 mb-2">
                        <Label>Notes</Label>
                        <Input type={"textarea"}
                               name={"notes"}
                               value={notes || ''}
                               placeholder={'Enter notes here'}
                               onChange={(e) => this.handleChange(e.target.value, 'notes')}/>

                    </div>
                    <div className="mt-2 mb-2">
                        <Label>Production Line</Label>
                        <br/>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <div>
                                    <Button color={"link"}
                                            className={"mr-2"}
                                            onClick={() => this.selectAllProductionLine()}>
                                        {allSelected?
                                            <i className="fa fa-lg fa-check-square-o" aria-hidden="true"/> :
                                            <i className="fa fa-lg fa-square-o" aria-hidden="true"/>}
                                    </Button>Select All
                                </div>
                                {(productionLinesList || []).map((option,index)=>
                                    index<productionLinesList.length/2 ?
                                        <div>
                                            <Button color={"link"}
                                                    className={"mr-2"}
                                                    onClick={() => this.selectProductionLine(option.productionLineId)}
                                            >
                                                {selectedProductionLineIds.includes(option.productionLineId)?
                                                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true"/> :
                                                    <i className="fa fa-lg fa-square-o" aria-hidden="true"/>}
                                            </Button>
                                            {option.shortName}
                                        </div>
                                        :null

                                )}
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                {(productionLinesList || []).map((option,index)=>
                                    index >productionLinesList.length/2 ?
                                        <div>
                                            <Button color={"link"}
                                                    className={"mr-2"}
                                                    onClick={() => this.selectProductionLine(option.productionLineId)}
                                            >
                                                {selectedProductionLineIds.includes(option.productionLineId)?
                                                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true"/> :
                                                    <i className="fa fa-lg fa-square-o" aria-hidden="true"/>}
                                            </Button>
                                            {option.shortName}
                                        </div>
                                        :null

                                )}
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"} size={"sm"} onClick={this.handleSubmit} className={"mr-2"}
                                disabled={saving}>
                            {saving ? <Spinner size="sm"
                                               className={"mr-2"}
                                               style={{color: "white"}}/> : <i className="fa fa-floppy-o mr-2"/>}
                            {saving ? "Saving" : "Save"} </Button>
                        <Button color={"secondary"}
                                size={"sm"} onClick={() => this.handleCancel()}>
                            <i className="fa fa-times mr-2"/> Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}