import React, {Component} from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Input,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    Table,
    UncontrolledTooltip
} from 'reactstrap';
import {Link as Link} from "react-router-dom";
import classnames from 'classnames';
import {cloneDeep, isEmpty} from 'lodash';
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import {toast, ToastContainer} from 'react-toastify';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {
    changeFormatOfDateString,
    findIndex,
    findItems,
    getDateString,
    getOrderColorName,
    getSorted,
    groupBy,
    handleErrorMessage,
    scrollTo,
    getDebtorInvoiceStatusColour,
    getDeliveryAddressString,
    getParentCompanyAddress,
    getRetailDeliveryAddressString
} from '../../services/CommonService';
import UserService from '../../services/UserService';
import productionDescheduleService from '../../services/production/ProductionDescheduleService';
import productionScheduleService from '../../services/production/ProductionScheduleService';
import ProductionCapacityService from '../../services/production/ProductionCapacityService';
import UpdateCustomProductionCapacityModal from '../../components/production/UpdateCustomProductionCapacityModal';
import DescheduleProductionScheduleModal from '../../components/production/DescheduleProductionScheduleModal';
import ProductionScheduleRebuildConfirmModal from '../../components/modal/ProductionScheduleRebuildConfirmModal';
import ProductionScheduleSameOrderItemsModal from '../../components/modal/ProductionScheduleSameOrderItemsModal';
import PowdercoatingFormModal from '../../components/modal/PowdercoatingFormModal';
import ConfirmModal from '../../components/modal/ConfirmModal';
import queryString from 'query-string';
import ProductionScheduleScheduleJobsModal from "../../components/modal/ProductionScheduleScheduleJobsModal";
import OrderService from '../../services/OrderService';
import ProductionOrderStatusChangeModal from '../../components/production/ProductionOrderStatusChangeModal';
import ProductionDescheduleNotesUpdateModal from '../../components/modal/ProductionDescheduleNotesUpdateModal';
import ManageProductionScheduleJobPriorityModal from '../../components/modal/ManageProductionScheduleJobPriorityModal';
import ProductionScheduleOrderProdStatusChangeModal from '../../components/modal/ProductionScheduleOrderProdStatusChangeModal';
import orderEnquiryUtil from '../../util/OrderEnquiryUtil';
import ReworkOrderSummary from "../Rework/ReworkOrderSummary";
import OrderInformationModal from '../../components/OrderGatewayComponents/OrderInformationModal';
import OOSDetailsModal from '../stock/OOSDetailsModal';
import ConfirmDescheduledOrderModal from '../../components/modal/ConfirmDescheduledOrderModal';
import FileSaver from 'file-saver';

const minDate = new Date();
const canRescheduleProductionPrivilege = "production-reschedule-write";
const canUpdateProductionCapacityPrivilege = "production-capacity-write";
const productGroupOrder = ['ROL & ROM', 'VERT IN', 'VENETIANS', 'CURTAIN TR', 'PANEL TR', 'HOSPITAL',
    'VERT OUT', 'FOLD ARM', 'SHP CUTDWN', 'PWDR COAT', 'CUT IN', 'CUT OUT', 'COMPONENTS'];

function ChangeScheduledDate(props) {

    return (
        <div>
            <DatePicker
                className={props.cssClassName}
                selected={props.selected}
                onChange={date => props.handleChange(date)}
                dateFormat="ddMMMyy"
                placeholderText="Select"
                minDate={minDate}
                withPortal
                disabled={props.isDisabled}
            />
        </div>
    );
}


export default class ProductionScheduleDeschedulePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productionLinesSchedule: [],
            activeProductionLineId: '',
            loadingDefaultProductionLineSchedule: false,
            updatingActiveProductionLineSchedule: false,
            loadingDeschedule: false,
            customProductionCapacities: [],
            loadingCustomProductionCapacities: false,

            workingProductionCapacity: {},
            isOpenUpdateCustomProductionCapacityModal: false,
            savingCustomProductionCapacity: false,

            descheduleReasons: [],

            workingDescheduleProductionSchedule: {},
            isOpenDescheduleProductionScheduleModal: false,
            loadingDescheduleProductionScheduleSubmit: false,

            isOpenProductionScheduleRebuildConfirmModal: false,
            isRebuildToday: false,
            loadingProductionScheduleRebuild: false,

            collapseTriggers: {},
            isHiddenExtendedColumn: true,
            isHiddenOOSDetailsColumn: true,

            isOpenSameOrderItemsModal: false,
            searchText: "",
            triggeredBySameOrderItemsModal: "", //searchText || isMultiProductOrder
            highlightedOrder: {},
            loadingLabels: "",
            loadingIsProductionDateClosed: "",

            currentUser: {},
            canRescheduleProduction: false,
            canUpdateProductionCapacity: false,
            isOpenConfirmCompleteStatusModal: false,
            hasProductionScheduleReadonlyPrivilege: false,
            hasProductionScheduleOnWriteonlyPrivilege: false,
            hasProductionDeScheduleWriteonlyPrivilege: false,
            workingConfirmCompleteStatusOrder: {},
            selectedOrderNumber: 0,

            powdercoatingForm: {
                isOpen: false,
                salesOrderJob: {},
            },

            workingScheduleJob: {},
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            descheduledMessage: "",
            selectedSalesOrderForProductionDescheduleNoteUpdate: {},
            isOpenProductionDescheduleNoteUpdateModal: false,
            isOpenProductionScheduleOrderProdStatusChangeModal: false,
            isOpenReworkOrderSummaryModal: false,
            isOpenOOSDetailsModal: false,
            allRecordsSelected: false,
            fabricCutLoading: false,
            isOpenJobInfoModal: false,
            isRescheduleFromDeschedule: false,
            isOpenDescheduledOrderModal: false
        };
        this.userService = new UserService();
        this.orderService = new OrderService();
        this.refresh = this.refresh.bind(this);
        this.getSchedule = this.getSchedule.bind(this);
        this.getProductionDescheduleReasons = this.getProductionDescheduleReasons.bind(this);
        this.getCustomProductionCapacity = this.getCustomProductionCapacity.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.toggleProductionLineTab = this.toggleProductionLineTab.bind(this);
        this.toggleScheduleCollapse = this.toggleScheduleCollapse.bind(this);
        this.toggleUpdateCustomProductionCapacityModal = this.toggleUpdateCustomProductionCapacityModal.bind(this);
        this.toggleProductionScheduleRebuildConfirmModal = this.toggleProductionScheduleRebuildConfirmModal.bind(this);
        this.toggleExtendedColumn = this.toggleExtendedColumn.bind(this);
        this.toggleDescheduleProductionScheduleModal = this.toggleDescheduleProductionScheduleModal.bind(this);
        this.toggleSameOrderItemsModal = this.toggleSameOrderItemsModal.bind(this);
        this.toggleConfirmCompleteStatusModal = this.toggleConfirmCompleteStatusModal.bind(this);
        this.togglePowdercoatingFormModal = this.togglePowdercoatingFormModal.bind(this);
        this.toggleWorkingScheduleJobModal = this.toggleWorkingScheduleJobModal.bind(this);

        this.scheduleProductionSchedule = this.scheduleProductionSchedule.bind(this);
        this.updateProductionDeschedule = this.updateProductionDeschedule.bind(this);
        this.scheduleProductionDeschedule = this.scheduleProductionDeschedule.bind(this);
        this.updateCustomProductionCapacitySubmit = this.updateCustomProductionCapacitySubmit.bind(this);
        this.rebuildProductionSchedule = this.rebuildProductionSchedule.bind(this);
        this.descheduleProductionSchedule = this.descheduleProductionSchedule.bind(this);

        this.downloadLabels = this.downloadLabels.bind(this);
        this.updateProductionCalendarClose = this.updateProductionCalendarClose.bind(this);
        this.updateLabelPrintDate = this.updateLabelPrintDate.bind(this);
        this.updateProductionSalesOrderJobComplete = this.updateProductionSalesOrderJobComplete.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.getConfirmModal = this.getConfirmModal.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.toggleOrderStatusChangeModal = this.toggleOrderStatusChangeModal.bind(this);
        this.handleCloseOrderStatusChangeModal = this.handleCloseOrderStatusChangeModal.bind(this);
        this.CompleteCutFabricJob = this.CompleteCutFabricJob.bind(this);
        this.handleProductionDescheduleNotesData = this.handleProductionDescheduleNotesData.bind(this);
        this.toggleProductionDescheduleNoteUpdateModal = this.toggleProductionDescheduleNoteUpdateModal.bind(this);
        this.setActiveProductionLineId = this.setActiveProductionLineId.bind(this);
        this.toggleScheduleJobPriorityModal = this.toggleScheduleJobPriorityModal.bind(this);
        this.productionCapacityService = new ProductionCapacityService();
        this.updateDefaultTab = this.updateDefaultTab.bind(this);
        this.openProductionScheduleOrderProdStatusChangeModal = this.openProductionScheduleOrderProdStatusChangeModal.bind(this);
        this.toggleProductionScheduleOrderProdStatusChangeModal = this.toggleProductionScheduleOrderProdStatusChangeModal.bind(this);
        this.handleProductScheduleProdNotes = this.handleProductScheduleProdNotes.bind(this);
        this.selectAllRecords = this.selectAllRecords.bind(this);
        this.toggleReworkOrderSummaryModal = this.toggleReworkOrderSummaryModal.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.getReworkOrder = this.getReworkOrder.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.getOOSDetails = this.getOOSDetails.bind(this);
        this.toggleOOSDetailsColumn = this.toggleOOSDetailsColumn.bind(this);
        this.toggleOOSDetailsModal = this.toggleOOSDetailsModal.bind(this);
        this.checkDescheduledStatus = this.checkDescheduledStatus.bind(this);
        this.handleDescheduledOrderSubmit = this.handleDescheduledOrderSubmit.bind(this);
    }

    componentDidMount() {
        let {activeProductionLineId, selectedOrderNumber} = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            activeProductionLineId = parseInt(searchParams.productionLineID);
            selectedOrderNumber = searchParams.orderNum;

            this.setState({activeProductionLineId, selectedOrderNumber});
        }

        this.getSalesOrderJobStatuses();
        this.refresh();
        let currentUser = this.userService.getLoggedInUser();
        let canRescheduleProduction = this.userService.hasPrivilege(currentUser, canRescheduleProductionPrivilege);
        let canUpdateProductionCapacity = this.userService.hasPrivilege(currentUser, canUpdateProductionCapacityPrivilege);
        let hasProductionScheduleReadonlyPrivilege = this.userService.hasPrivilege(currentUser, 'production-schedule-readonly');

        let hasProductionScheduleOnWriteonlyPrivilege = this.userService.hasPrivilege(currentUser, 'production-schedule-write');
        let hasProductionDeScheduleWriteonlyPrivilege = this.userService.hasPrivilege(currentUser, 'production-deschedule-write');
        this.setState({
            currentUser,
            canRescheduleProduction,
            canUpdateProductionCapacity,
            hasProductionScheduleReadonlyPrivilege,
            hasProductionScheduleOnWriteonlyPrivilege,
            hasProductionDeScheduleWriteonlyPrivilege
        });
    }

    updateDefaultTab() {
        let {productionLinesSchedule, activeProductionLineId} = this.state;
        if (activeProductionLineId !== null) {
            this.setState({activeProductionLineId});
        }
        ////On load -- open the ROL/ROM tab
        else {
            let arr = productionLinesSchedule.filter(x => x.productionLine.schedulerOrder === 1);
            if (arr) {
                this.setActiveProductionLineId(arr[0].productionLine.productionLineId);
            }
        }
    }

    refresh() {
        this.getCustomProductionCapacity();
        this.getProductionDescheduleReasons();
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let {salesOrderJobStatuses} = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({salesOrderJobStatuses})
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleProductionLineTab(tab) {
        let {activeProductionLineId, productionLinesSchedule, selectedOrderNumber} = this.state;
        let index = findIndex(productionLinesSchedule, 'id', activeProductionLineId);

        if (productionLinesSchedule[index].id !== tab.id) {
            // update productionLines
            for (let i = 0; i < productionLinesSchedule.length; i += 1) {
                if (productionLinesSchedule[i].id === productionLinesSchedule[index].id) {
                    productionLinesSchedule[i] = productionLinesSchedule[index];
                }
            }
            this.setState({activeProductionLineId: tab.id, productionLinesSchedule});

            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("productionLineID", tab.id);

                if (selectedOrderNumber) {
                    searchParams.set("orderNum", selectedOrderNumber);
                }
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({path: newurl}, '', newurl);
            }
        }
    }

    toggleScheduleCollapse(scheduleBucketIndex, change) {
        let {activeProductionLineId, productionLinesSchedule, collapseTriggers} = this.state;
        let index = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
        let identityKey = productionScheduleService.generateScheduleBucketKey(productionLinesSchedule[index].productionLine.productionLineId, productionLinesSchedule[index].scheduleBuckets[scheduleBucketIndex].scheduleOn ? productionLinesSchedule[index].scheduleBuckets[scheduleBucketIndex].scheduleOn : "DeScheduled");

        productionLinesSchedule[index].scheduleBuckets[scheduleBucketIndex].isOpen = change;
        collapseTriggers[identityKey] = change;
        this.setState({productionLinesSchedule, collapseTriggers});
    }

    getSchedule() {
        let {
            productionLinesSchedule,
            activeProductionLineId,
            loadingDefaultProductionLineSchedule,
            customProductionCapacities,
            collapseTriggers
        } = this.state;

        loadingDefaultProductionLineSchedule = true;
        this.setState({loadingDefaultProductionLineSchedule});
        productionScheduleService.getAutoProductionSchedule().then(response => {
            let data = response.data;
            data.productionLines = getSorted(data.productionLines, 'schedulerOrder', true);
            productionLinesSchedule = productionScheduleService.prepareProductionLinesResponseData(data);

            // hiding 'masks' production group
            productionLinesSchedule = productionLinesSchedule.filter((productionLineSchedule) => productionLineSchedule.productionLine.code !== "PPE");

            productionLinesSchedule.map((productionLineSchedule, index) => {

                productionLineSchedule.store_schedule = productionScheduleService.getStore(productionLineSchedule.productionLine.shortName).filter(s => s.isScheduleColumn);
                productionLineSchedule.store_deschedule = productionScheduleService.getStore(productionLineSchedule.productionLine.shortName).filter(s => s.isDescheduleColumn);

                productionLineSchedule.csv = {store: [], headers: []};
                productionLineSchedule.csv.store = productionLineSchedule.store_schedule.filter((storeItem) => storeItem.isCSVColumn);
                productionLineSchedule.csv.headers = productionLineSchedule.csv.store.map((storeItem) => storeItem.label);

                productionLineSchedule.customProductionCapacities = findItems(customProductionCapacities, "productionLineId", productionLineSchedule.productionLine.productionLineId);
                productionLineSchedule = productionScheduleService.prepareProductionLineSchedule(productionLineSchedule, collapseTriggers);

            });

            loadingDefaultProductionLineSchedule = false;
            let index = 0;

            if (activeProductionLineId !== null) {
                index = findIndex(productionLinesSchedule, 'id', parseInt(activeProductionLineId));
                index = index > -1 ? index : 0;
            }
            activeProductionLineId = productionLinesSchedule[index].id;
            this.setState({
                productionLinesSchedule: productionLinesSchedule,
                activeProductionLineId,
                loadingDefaultProductionLineSchedule,
                loadingDeschedule: true
            }, () => {
                this.getProductionDeschedule();
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
            loadingDefaultProductionLineSchedule = false;
            this.setState({loadingDefaultProductionLineSchedule});
        })
    }

    getProductionDeschedule() {
        let {
            productionLinesSchedule,
            activeProductionLineId,
            collapseTriggers,
            highlightedOrder,
            selectedOrderNumber
        } = this.state;

        productionDescheduleService.getProductionDeschedule().then(response => {
            let orders = response.data;

            orders.forEach((order, index) => productionScheduleService.convertOrder(order));
            let ordersByProductionLineId = groupBy(orders, 'productionLineID');
            productionLinesSchedule.map(pls => {
                if (!isEmpty(ordersByProductionLineId[pls.productionLine.productionLineId])) {
                    pls.orders.push(...ordersByProductionLineId[pls.productionLine.productionLineId]);
                }
                pls = productionScheduleService.prepareProductionLineSchedule(pls, collapseTriggers);
            });

            if (activeProductionLineId !== null && selectedOrderNumber) {

                let orderIndex = -1;
                for (let index = 0; index < productionLinesSchedule.length; index++) {
                    orderIndex = findIndex(productionLinesSchedule[index].orders, 'ordNum', parseInt(selectedOrderNumber));
                    if (orderIndex > -1 && productionLinesSchedule[index].productionLine.productionLineId === activeProductionLineId) {

                        // open tab
                        activeProductionLineId = productionLinesSchedule[index].id;

                        // open bucket
                        productionLinesSchedule[index].scheduleBuckets.forEach(bucket => {
                            bucket.isOpen = productionLinesSchedule[index].orders[orderIndex].bucketIdentityKey === bucket.identityKey;
                            collapseTriggers[bucket.identityKey] = bucket.isOpen;
                        });

                        // highlight order
                        highlightedOrder = productionLinesSchedule[index].orders[orderIndex];

                        break;
                    }
                }

                this.setState({
                    activeProductionLineId,
                    productionLinesSchedule,
                    highlightedOrder,
                    selectedOrderNumber,
                    isOpenSameOrderItemsModal: false
                }, () => {
                    setTimeout(() => {
                        scrollTo("Order" + highlightedOrder.guid)
                    }, 1000);

                });
            }

            productionLinesSchedule.sort((a, b) => (a.productionLine.schedulerOrder > b.productionLine.schedulerOrder) ? 1 : -1);


            this.setState({productionLinesSchedule, loadingDeschedule: false}
                , () => {
                    this.updateDefaultTab();
                });

        }).catch(error => {
            this.setState({loadingDeschedule: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    setActiveProductionLineId(Id) {
        this.setState({activeProductionLineId: Id});
    }

    getProductionDescheduleReasons() {
        productionDescheduleService.getProductionDescheduleReasons().then(response => {
            let descheduleReasons = getSorted(response.data, 'name', true);
            this.setState({descheduleReasons});
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    rebuildProductionSchedule(isRebuildToday) {
        this.setState({loadingProductionScheduleRebuild: true});
        productionScheduleService.rebuildProductionSchedule(isRebuildToday).then(response => {
            toast.success("Rescheduled!");
            this.setState({
                loadingProductionScheduleRebuild: false,
                isOpenProductionScheduleRebuildConfirmModal: false
            });
            this.refresh();
        }).catch(error => {
            this.setState({
                loadingProductionScheduleRebuild: false
            });
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    toggleUpdateCustomProductionCapacityModal(isOpenUpdateCustomProductionCapacityModal) {
        let {workingProductionCapacity} = this.state;
        if (!isOpenUpdateCustomProductionCapacityModal) {
            workingProductionCapacity = null;
        }
        this.setState({isOpenUpdateCustomProductionCapacityModal, workingProductionCapacity});
    }

    toggleDescheduleProductionScheduleModal(isOpenDescheduleProductionScheduleModal) {
        let {workingDescheduleProductionSchedule} = this.state;
        if (!isOpenDescheduleProductionScheduleModal) {
            workingDescheduleProductionSchedule = null;
        }
        this.setState({isOpenDescheduleProductionScheduleModal, workingDescheduleProductionSchedule});
    }

    toggleProductionScheduleRebuildConfirmModal(isOpenProductionScheduleRebuildConfirmModal) {
        this.setState({isOpenProductionScheduleRebuildConfirmModal});
    }

    toggleExtendedColumn(isHiddenExtendedColumn) {
        this.setState({isHiddenExtendedColumn});
    }

    toggleOOSDetailsColumn(isHiddenOOSDetailsColumn) {
        this.setState({ isHiddenOOSDetailsColumn });
    }

    toggleSameOrderItemsModal(isOpenSameOrderItemsModal) {
        this.setState({isOpenSameOrderItemsModal});
    }

    toggleProductionDescheduleNoteUpdateModal(isOpenProductionDescheduleNoteUpdateModal) {
        this.setState({isOpenProductionDescheduleNoteUpdateModal});
    }

    toggleProductionScheduleOrderProdStatusChangeModal(change) {
        this.setState({isOpenProductionScheduleOrderProdStatusChangeModal: change});
    }

    toggleWorkingScheduleJobModal(change) {
        let {workingScheduleJob} = this.state;
        workingScheduleJob.isOpen = change;
        this.setState({workingScheduleJob});
    }

    checkDescheduledStatus(order) {
        this.orderService.isOrderDescheduled(order.ordNum).then(response => {
            if (response.data) {
                this.setState({ descheduledMessage: "This job cannot be completed as its Parent Job has been De-Scheduled.", isOpenDescheduledOrderModal: true });
            } else {
                if (order.parentSalesOrderJobId && order.isFabricChoppedButJobIncomplete) {
                    this.CheckIfCutJobFinished(order.ordNum, order.prodCode, order.salesOrderJobID).then(response => {
                        if (response.data?.exists) {
                            toast.info("This job has already been completed");
                            this.refresh();
                        } else {
                            this.CompleteCutFabricJob(order);
                        }
                    }).catch(error => {
                        console.log(error);
                        toast.error(handleErrorMessage(error));
                    });
                } else {
                    this.CheckIfCutJobFinished(order.ordNum, order.prodCode, order.salesOrderJobID).then(response => {
                        if (response.data?.exists) {
                            toast.info("This job has already been completed");
                            this.refresh();
                        } else {
                            this.toggleConfirmCompleteStatusModal(true, order);
                        }
                    }).catch(error => {
                        console.log(error);
                        toast.error(handleErrorMessage(error));
                    });
                }
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    handleDescheduledOrderSubmit() {
        this.setState({ isOpenDescheduledOrderModal: false });
        this.refresh();
    }

    toggleConfirmCompleteStatusModal(change, order) {
        if (order && order.qtyUsed && order.qtyUsed < 0) {
            toast.error("User is not allowed to input -ve values");
        }
        else {
            this.setState({isOpenConfirmCompleteStatusModal: change, workingConfirmCompleteStatusOrder: order});
        }
    }

    togglePowdercoatingFormModal(change) {
        let {powdercoatingForm} = this.state;
        powdercoatingForm.isOpen = change;
        if (!powdercoatingForm.isOpen) {
            powdercoatingForm.salesOrderJob = {};
        }
        this.setState({powdercoatingForm});
    }

    getCustomProductionCapacity() {
        this.setState({loadingCustomProductionCapacities: true});
        this.productionCapacityService.getCustomProductionCapacityForTodayOnwards().then(response => {
            this.setState({customProductionCapacities: response.data, loadingCustomProductionCapacities: false});
            this.getSchedule();
        }).catch(error => {
            this.setState({loadingCustomProductionCapacities: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    updateCustomProductionCapacitySubmit() {
        let {workingProductionCapacity, activeProductionLineId, productionLinesSchedule, collapseTriggers} = this.state;
        if (!workingProductionCapacity.productionDate) {
            toast.info("Select production date");
            return;
        }
        this.setState({savingCustomProductionCapacity: true});
        this.productionCapacityService.saveDatedProductionCapacity(workingProductionCapacity).then(response => {
            let index = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
            let customProductionCapacityIndex = findIndex(productionLinesSchedule[index].customProductionCapacities, 'productionDate', response.data.productionDate);
            if (customProductionCapacityIndex > -1) {
                productionLinesSchedule[index].customProductionCapacities[customProductionCapacityIndex] = response.data;
            } else {
                productionLinesSchedule[index].customProductionCapacities.push(response.data);
            }
            productionLinesSchedule[index] = productionScheduleService.prepareProductionLineSchedule(productionLinesSchedule[index], collapseTriggers);
            toast.success("Capacity updated!");
            this.setState({
                productionLinesSchedule,
                savingCustomProductionCapacity: false,
                isOpenUpdateCustomProductionCapacityModal: false
            });
        }).catch(error => {
            this.setState({savingCustomProductionCapacity: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    handleChange(key, change, context) {
        let {
            activeProductionLineId,
            productionLinesSchedule,
            workingProductionCapacity,
            workingDescheduleProductionSchedule,
            descheduleReasons,
            collapseTriggers,
            highlightedOrder,
            workingScheduleJob,
            hasProductionScheduleOnWriteonlyPrivilege,
            hasProductionDeScheduleWriteonlyPrivilege
        } = this.state;
        let request = {}, index = -1, orderIndex = -1, scheduleBucketIndex = -1;
        switch (key) {
            case "isRebuildToday":
                this.setState({isRebuildToday: change});
                break;
            case "isProductionDateClosed":
                //context is scheduleBucket
                request = {
                    productionLineID: context.productionLine.productionLineId,
                    productionDate: context.scheduleOn,
                    isClosed: change
                };
                this.updateProductionCalendarClose(request, context);
                break;
            case "followUpDate_productionDeschedule":
                //context is order here
                request = {
                    id: context.productionDescheduleID,
                    followUpDate: change
                };
                this.updateProductionDeschedule(request, context);
                break;
            case "productionSchedule_reschedule_scheduleJob_openModal":
                //context is order here
                workingScheduleJob = {
                    isUserSchedule: change.isUserSchedule,
                    scheduleOn: change.scheduleOn,
                    order: change,
                    salesOrderJobs: [],
                    isOpen: true,
                    isLoadingSave: false
                };
                productionLinesSchedule.forEach(pls => {
                    let salesOrderJobs = findItems(pls.orders, 'ordNum', change.ordNum);
                    if (salesOrderJobs && salesOrderJobs.length > 0) {
                        salesOrderJobs.forEach(soj => {
                            let temp = cloneDeep(soj);
                            temp.isSelected = true;
                            workingScheduleJob.salesOrderJobs.push(temp);
                        })
                    }
                });
                workingScheduleJob.isOpen = true;
                this.setState({ workingScheduleJob, isRescheduleFromDeschedule: false});

                break;
            case "productionSchedule_deschedule_scheduleJob_openModal":
                //context is order here
                workingScheduleJob = {
                    isUserSchedule: change.isUserSchedule,
                    scheduleOn: change.scheduleOn,
                    order: change,
                    salesOrderJobs: [],
                    isOpen: true,
                    isLoadingSave: false
                };
                productionLinesSchedule.forEach(pls => {
                    let salesOrderJobs = findItems(pls.orders, 'ordNum', change.ordNum);
                    if (salesOrderJobs && salesOrderJobs.length > 0) {
                        salesOrderJobs.forEach(soj => {
                            let temp = cloneDeep(soj);
                            temp.isSelected = true;
                            workingScheduleJob.salesOrderJobs.push(temp);
                        })
                    }
                });
                workingScheduleJob.isOpen = true;
                this.setState({ workingScheduleJob, isRescheduleFromDeschedule: true });

                break;
            case "scheduleOn_scheduleProductionDeschedule":
                //context is order here
                var salesOrderJobIds = [];
                salesOrderJobIds.push(context.salesOrderJobID);
                if (hasProductionScheduleOnWriteonlyPrivilege) {
                    request = {
                        salesOrderJobIDs: salesOrderJobIds,
                        scheduleOn: change,
                        ordNum: context.ordNum,
                        isUserSchedule: true
                    };
                    this.scheduleProductionDeschedule(request, context);
                } else {
                    toast.error("Access denied.");
                }
                break;
            case "deschedule_open_model":
                //context is order here
                if (hasProductionDeScheduleWriteonlyPrivilege) {
                    this.orderService.isCutJobStarted(context.ordNum).then(response => {
                        if (response.data) {
                            this.setState({ descheduledMessage: "This job cannot be De-Scheduled as its related Cut Job has been started/completed.", isOpenDescheduledOrderModal: true });
                        } else {
                            workingDescheduleProductionSchedule = {
                                salesOrderJobID: context.salesOrderJobID,
                                productionScheduleID: context.productionScheduleID,
                                deScheduleReasonId: descheduleReasons[0].id,
                                followUpDate: moment().add(3, 'days').toDate(),
                                notes: "",
                                ordNum: context.ordNum
                            };
                            this.setState({ workingDescheduleProductionSchedule, isOpenDescheduleProductionScheduleModal: true });
                        }
                    }).catch(error => {
                        console.log(error);
                        toast.error(handleErrorMessage(error));
                    });
                }
                else {
                    toast.error("Access denied.");
                }
                break;
            case "workingProductionCapacity_edit":
                index = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
                workingProductionCapacity = {
                    productionDate: change.scheduleOn,
                    capacity: change.productionCapacity,
                    productionLineId: productionLinesSchedule[index].productionLine.productionLineId,
                    productionLineShortName: productionLinesSchedule[index].productionLine.shortName,
                    refreshAfterSubmit: false
                };
                this.setState({workingProductionCapacity, isOpenUpdateCustomProductionCapacityModal: true});
                break;
            case "workingProductionCapacity_productionDate":
                workingProductionCapacity.productionDate = change;
                this.setState({workingProductionCapacity});
                break;
            case "workingProductionCapacity_capacity":
                workingProductionCapacity.capacity = change;
                this.setState({workingProductionCapacity});
                break;
            case "workingDescheduleProductionSchedule_deScheduleReasonId":
                workingDescheduleProductionSchedule.deScheduleReasonId = change;
                this.setState({workingDescheduleProductionSchedule});
                break;
            case "workingDescheduleProductionSchedule_notes":
                workingDescheduleProductionSchedule.notes = change;
                this.setState({workingDescheduleProductionSchedule});
                break;
            case "workingDescheduleProductionSchedule_followUpDate":
                workingDescheduleProductionSchedule.followUpDate = change;
                this.setState({workingDescheduleProductionSchedule});
                break;
            case "workingDescheduleProductionSchedule_refreshAfterSubmit":
                workingDescheduleProductionSchedule.refreshAfterSubmit = change;
                this.setState({workingDescheduleProductionSchedule});
                break;
            case "sameOrderItemsModal_trigger":
                this.setState({
                    triggeredBySameOrderItemsModal: change,
                    searchText: context.ordNum + "",
                    isOpenSameOrderItemsModal: true
                });
                break;
            case "sameOrderItemsModal_searchText":
                this.setState({searchText: change});
                break;
            case "sameOrderItemsModal_highlightOrder":
                for (let index = 0; index < productionLinesSchedule.length; index++) {
                    let orderIndex = findIndex(productionLinesSchedule[index].orders, 'guid', change.guid);
                    if (orderIndex > -1) {

                        // open tab
                        activeProductionLineId = productionLinesSchedule[index].id;

                        // open bucket
                        productionLinesSchedule[index].scheduleBuckets.forEach(bucket => {
                            bucket.isOpen = change.bucketIdentityKey === bucket.identityKey;
                            collapseTriggers[bucket.identityKey] = bucket.isOpen;
                        });

                        // highlight order
                        highlightedOrder = change;

                        break;
                    }
                }
                this.setState({
                    activeProductionLineId,
                    productionLinesSchedule,
                    highlightedOrder,
                    isOpenSameOrderItemsModal: false
                }, () => {
                    setTimeout(() => {
                        scrollTo("Order" + highlightedOrder.guid)
                    }, 1000);

                });
                break;
            case "powdercoating_form_open_modal":
                let {powdercoatingForm} = this.state;
                powdercoatingForm.salesOrderJob = change;
                powdercoatingForm.isOpen = true;
                this.setState({powdercoatingForm});
                break;
            case "powdercoating_form_updatePurchaseOrderNum":
                let salesOrderJob = change.salesOrderJob;
                let orderIndex = -1;

                productionLinesSchedule.forEach(pls => {
                    orderIndex = findIndex(pls.orders, 'salesOrderJobID', salesOrderJob.id);
                    if (orderIndex > -1) {
                        pls.orders[orderIndex].salesOrderJobStatusID = salesOrderJob.status.id;
                        pls.orders[orderIndex].salesOrderJobStatusCode = salesOrderJob.status.statusCode;
                        pls.orders[orderIndex].salesOrderJobStatusDescription = salesOrderJob.status.statusDescription;
                        pls.orders[orderIndex].salesOrderJobIsOnHold = salesOrderJob.status.isOnHold;
                        pls.orders[orderIndex].salesOrderJobIsCompleted = salesOrderJob.status.isCompleted;
                        pls.orders[orderIndex].salesOrderJobPowdercoatPurchaseOrderEmailedDate = change.emailedDate;
                        pls.orders[orderIndex].salesOrderJobPowdercoatPurchaseOrderNum = change.purchaseOrderNum;
                    }
                    pls = productionScheduleService.prepareProductionLineSchedule(pls, collapseTriggers);
                });

                this.setState({
                    productionLinesSchedule,
                    updatingActiveProductionLineSchedule: false,
                    isOpenConfirmCompleteStatusModal: false,
                    workingConfirmCompleteStatusOrder: null
                });

                break;
            case "productionSchedule_salesOrderJob_qtyUsed":
                productionLinesSchedule.forEach(pls => {
                    let orderIndex = findIndex(pls.orders, 'salesOrderJobID', context.salesOrderJobID);
                    if (orderIndex > -1) {
                        pls.orders[orderIndex].qtyUsed = change;
                    }
                    let scheduleBucketIndex = findIndex(pls.scheduleBuckets, 'scheduleOn', context.scheduleOn);
                    if (scheduleBucketIndex > -1) {
                        orderIndex = findIndex(pls.scheduleBuckets[scheduleBucketIndex].orders, 'salesOrderJobID', context.salesOrderJobID);
                        if (orderIndex > -1) {
                            pls.scheduleBuckets[scheduleBucketIndex].orders[orderIndex].qtyUsed = change;
                        }
                    }
                });
                this.setState({productionLinesSchedule});
                break;
            case "Navigate":
                let isSalesOrderJobNotFound = true;
                for (let index = 0; index < productionLinesSchedule.length; index++) {
                    let orderIndex = findIndex(productionLinesSchedule[index].orders, 'salesOrderJobID', context);
                    if (orderIndex > -1) {
                        let changedOrder = cloneDeep(productionLinesSchedule[index].orders[orderIndex]);
                        change = changedOrder;
                        isSalesOrderJobNotFound = false;

                        // open tab
                        activeProductionLineId = productionLinesSchedule[index].id;

                        // open bucket
                        productionLinesSchedule[index].scheduleBuckets.forEach(bucket => {
                            if (change.bucketIdentityKey === bucket.identityKey) {
                                if (!bucket.isOpen) {
                                    bucket.isOpen = change.bucketIdentityKey === bucket.identityKey;
                                    collapseTriggers[bucket.identityKey] = bucket.isOpen;
                                }
                            }
                        });

                        // highlight order
                        highlightedOrder = change;

                        break;
                    }
                }

                if (isSalesOrderJobNotFound) {
                    toast.info("May be this job is completed, as currently it is neither scheduled nor descheduled.");
                }
                this.setState({
                    activeProductionLineId,
                    productionLinesSchedule,
                    highlightedOrder,
                    isOpenSameOrderItemsModal: false
                }, () => {
                    setTimeout(() => {
                        scrollTo("Order" + highlightedOrder.guid)
                    }, 1000);
                    this.onOrderClick(change.ordNum);
                });
                break;
            default:
                break;
        }
    }

    handleProductionDescheduleNotesData(context) {
        let {productionLinesSchedule} = this.state;
        productionLinesSchedule.forEach(pls => {
            let orderIndex = findIndex(pls.orders, 'salesOrderJobID', context.salesOrderJobID);
            if (orderIndex > -1) {
                pls.orders[orderIndex].productionDescheduleNotes = context.productionDescheduleNotes;
            }
        });

        this.setState({
            productionLinesSchedule, isOpenProductionDescheduleNoteUpdateModal: false
        });
    }

    handleProductScheduleProdNotes(data) {
        let {productionLinesSchedule} = this.state;
        productionLinesSchedule.forEach(pls => {
            (pls.orders || []).filter(x => x.ordNum === data.ordNum).forEach((order, index) => {
                order.whStatus = data.whStatus;
            });
        });
        this.setState({
            productionLinesSchedule, isOpenProductionScheduleOrderProdStatusChangeModal: false
        });
    }

    openProductionDescheduleNotesModal(salesOrder) {
        this.setState({
            selectedSalesOrderForProductionDescheduleNoteUpdate: salesOrder,
            isOpenProductionDescheduleNoteUpdateModal: true
        });
    }

    openProductionScheduleOrderProdStatusChangeModal(salesOrder) {
        this.setState({
            selectedSalesOrderForProductionScheduleOrderProdStatusChange: salesOrder,
            isOpenProductionScheduleOrderProdStatusChangeModal: true
        });
    }

    handleSubmit(key) {
        let {workingDescheduleProductionSchedule} = this.state;
        let request = {};
        switch (key) {
            case "descheduleProductionSchedule":
                request = {
                    salesOrderJobID: workingDescheduleProductionSchedule.salesOrderJobID,
                    productionScheduleID: workingDescheduleProductionSchedule.productionScheduleID,
                    deScheduleReasonId: workingDescheduleProductionSchedule.deScheduleReasonId,
                    followUpDate: getDateString(workingDescheduleProductionSchedule.followUpDate, "DD-MMM-YYYY"),
                    notes: workingDescheduleProductionSchedule.notes,
                    ordNum: workingDescheduleProductionSchedule.ordNum
                };

                if (!(request.productionScheduleID && request.deScheduleReasonId && request.followUpDate)) {
                    toast.info("Please fill details as required");
                    return;
                }
                this.descheduleProductionSchedule(request, workingDescheduleProductionSchedule.refreshAfterSubmit);
                break;
            default:
                break;
        }
    }

    updateProductionCalendarClose(request, context) {
        this.setState({loadingIsProductionDateClosed: context.identityKey});
        productionScheduleService.updateProductionCalendarClose(request).then(response => {
            let {productionLinesSchedule, collapseTriggers} = this.state;

            let plIndex = findIndex(productionLinesSchedule, 'id', response.data.productionLineId);
            productionLinesSchedule[plIndex].orders.forEach(order => {
                if (order.scheduleOn === response.data.productionDate) {
                    order.isProductionDateClosed = response.data.isClosed;
                }
            });
            productionLinesSchedule[plIndex] = productionScheduleService.prepareProductionLineSchedule(productionLinesSchedule[plIndex], collapseTriggers);

            this.setState({productionLinesSchedule, loadingIsProductionDateClosed: ""});

            let msg = <div>
                <i className={classnames("fa", "fa-fw", {
                        "fa-lock": response.data.isClosed,
                        "fa-unlock": !response.data.isClosed,
                    }
                )} aria-hidden="true"/>&nbsp;&nbsp;{response.data.isClosed ? "Closed" : "Opened"}
            </div>;
            toast.success(msg);
        }).catch(error => {
            this.setState({loadingIsProductionDateClosed: ""});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    descheduleProductionSchedule(request, refreshAfterSubmit) {
        this.setState({loadingDescheduleProductionScheduleSubmit: true});
        productionDescheduleService.descheduleOrder(request).then(response => {
            toast.success("Descheduled!");
            this.setState({
                loadingDescheduleProductionScheduleSubmit: false,
                isOpenDescheduleProductionScheduleModal: false
            });
            if (refreshAfterSubmit) {
                this.getSchedule();
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({loadingDescheduleProductionScheduleSubmit: false});
        })
    }

    scheduleProductionSchedule(request) {
        let {workingScheduleJob} = this.state;
        workingScheduleJob.isLoadingSave = true;
        this.setState({workingScheduleJob});
        productionScheduleService.updateProductionSchedule(request).then(response => {
            let {productionLinesSchedule, collapseTriggers, workingScheduleJob} = this.state;
            workingScheduleJob.isLoadingSave = false;
            workingScheduleJob.isOpen = false;
            let orderIndex = -1;
            response.data.forEach(ps => {
                productionLinesSchedule.forEach(pls => {
                    orderIndex = findIndex(pls.orders, 'productionScheduleID', ps.id);
                    if (orderIndex > -1) {
                        pls.orders[orderIndex].scheduleOn = ps.scheduleOn;
                        pls.orders[orderIndex].isUserSchedule = ps.isUserSchedule;
                    }
                    pls = productionScheduleService.prepareProductionLineSchedule(pls, collapseTriggers);
                });
            });

            this.setState({productionLinesSchedule, workingScheduleJob});
            toast.success("Saved!");
        }).catch(error => {
            workingScheduleJob.isLoadingSave = false;
            this.setState({workingScheduleJob});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    updateProductionDeschedule(request, context) {
        this.setState({updatingActiveProductionLineSchedule: true});
        productionScheduleService.updateProductionDeschedule(request).then(response => {
            let {productionLinesSchedule, collapseTriggers} = this.state;
            let orderIndex = -1;
            response.data.forEach(pd => {
                productionLinesSchedule.forEach(pls => {
                    orderIndex = findIndex(pls.orders, 'productionDescheduleID', pd.id);
                    if (orderIndex > -1) {
                        pls.orders[orderIndex].followUpDate = getDateString(pd.followUpDate, "DD/MM/YYYY");
                    }
                    pls = productionScheduleService.prepareProductionLineSchedule(pls, collapseTriggers);
                });
            });

            this.setState({productionLinesSchedule, updatingActiveProductionLineSchedule: false});
            toast.success("Saved!");
        }).catch(error => {
            this.setState({updatingActiveProductionLineSchedule: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    updateProductionSalesOrderJobComplete() {
        let {workingConfirmCompleteStatusOrder} = this.state;

        workingConfirmCompleteStatusOrder.qtyUsed = workingConfirmCompleteStatusOrder.qtyUsed ? workingConfirmCompleteStatusOrder.qtyUsed : 0;

        if (workingConfirmCompleteStatusOrder.qtyUsed < 0) {
            toast.error("Qty used cannot be smaller than zero");
            return;
        }

        let request = {
            salesOrderJobID: workingConfirmCompleteStatusOrder.salesOrderJobID,
            qtyUsed: workingConfirmCompleteStatusOrder.qtyUsed,
            IsFabricChoppedButJobIncomplete: workingConfirmCompleteStatusOrder.isFabricChoppedButJobIncomplete
        };
        this.setState({updatingActiveProductionLineSchedule: true});
        productionScheduleService.updateProductionSalesOrderJobComplete(request).then(response => {
            let salesOrderJob = response.data;
            let {productionLinesSchedule, collapseTriggers} = this.state;
            let orderIndex = -1;

            productionLinesSchedule.forEach(pls => {
                orderIndex = findIndex(pls.orders, 'salesOrderJobID', salesOrderJob.id);
                if (orderIndex > -1) {
                    pls.orders[orderIndex].salesOrderJobStatusID = salesOrderJob.status.id;
                    pls.orders[orderIndex].salesOrderJobStatusCode = salesOrderJob.status.statusCode;
                    pls.orders[orderIndex].salesOrderJobStatusDescription = salesOrderJob.status.statusDescription;
                    pls.orders[orderIndex].salesOrderJobIsOnHold = salesOrderJob.status.isOnHold;
                    pls.orders[orderIndex].salesOrderJobIsCompleted = salesOrderJob.status.isCompleted;
                    pls.orders[orderIndex].isFabricChoppedButJobIncomplete = (salesOrderJob.qtyUsed >= 0 && salesOrderJob.status.id < 90) ? true : false;
                }

                if (pls.productionLine.code === "CIN" || pls.productionLine.code === "COD") {
                    for (let index = 0; index < productionLinesSchedule.length; index++) {
                        let orderIndex = findIndex(productionLinesSchedule[index].orders, 'salesOrderJobID', salesOrderJob.parentSalesOrderJobId);
                        if (orderIndex > -1) {
                            if (productionLinesSchedule[index].orders[orderIndex].cutJobID) {
                                productionLinesSchedule[index].orders[orderIndex].cutJobSalesOrderJobStatusID = salesOrderJob.status.id;
                                break;
                            }
                        }
                    }
                }

                pls = productionScheduleService.prepareProductionLineSchedule(pls, collapseTriggers);
            });

            this.setState({
                productionLinesSchedule,
                updatingActiveProductionLineSchedule: false,
                isOpenConfirmCompleteStatusModal: false,
                workingConfirmCompleteStatusOrder: null
            });
            toast.success("Status updated!");
        }).catch(error => {
            this.setState({updatingActiveProductionLineSchedule: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    async CheckIfCutJobFinished(ordNum, prodCode, salesOrderJobID) {
        try {
            const response = await productionScheduleService.checkIfCutJobFinished(ordNum,prodCode,salesOrderJobID);
            return response;
        } catch (error) {
            toast.error('Error checking if cut job is finished');
            return false;
        }
    }

    CompleteCutFabricJob(SalesOrderJob) {
        let request = {
            salesOrderJobID: SalesOrderJob.salesOrderJobID,
            qtyUsed: SalesOrderJob.qtyUsed,
            IsFabricChoppedButJobIncomplete: SalesOrderJob.isFabricChoppedButJobIncomplete
        };
        this.setState({updatingActiveProductionLineSchedule: true});
        productionScheduleService.updateProductionSalesOrderJobComplete(request).then(response => {
            let salesOrderJob = response.data;
            let {productionLinesSchedule, collapseTriggers} = this.state;
            let orderIndex = -1;

            productionLinesSchedule.forEach(pls => {
                orderIndex = findIndex(pls.orders, 'salesOrderJobID', salesOrderJob.id);
                if (orderIndex > -1) {
                    pls.orders[orderIndex].salesOrderJobStatusID = salesOrderJob.status.id;
                    pls.orders[orderIndex].salesOrderJobStatusCode = salesOrderJob.status.statusCode;
                    pls.orders[orderIndex].salesOrderJobStatusDescription = salesOrderJob.status.statusDescription;
                    pls.orders[orderIndex].salesOrderJobIsOnHold = salesOrderJob.status.isOnHold;
                    pls.orders[orderIndex].salesOrderJobIsCompleted = salesOrderJob.status.isCompleted;
                    pls.orders[orderIndex].isFabricChoppedButJobIncomplete = (salesOrderJob.qtyUsed >= 0 && salesOrderJob.status.id < 90) ? true : false;
                }

                pls = productionScheduleService.prepareProductionLineSchedule(pls, collapseTriggers);
            });

            this.setState({
                productionLinesSchedule,
                updatingActiveProductionLineSchedule: false,
                workingConfirmCompleteStatusOrder: null
            });

            toast.success("Job Completed!");
        }).catch(error => {
            this.setState({updatingActiveProductionLineSchedule: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    scheduleProductionDeschedule(request) {
        this.setState({updatingActiveProductionLineSchedule: true});
        productionDescheduleService.scheduleOrder(request).then(response => {
            toast.success("Scheduled");
            this.setState({ updatingActiveProductionLineSchedule: false });
            this.getSchedule();
            this.toggleWorkingScheduleJobModal(false);
        }).catch(error => {
            this.setState({ updatingActiveProductionLineSchedule: false });
            this.toggleWorkingScheduleJobModal(false);
            toast.error(handleErrorMessage(error));
        });
    }

    onOrderClick(orderNum) {
        this.setState({selectedOrderNumber: orderNum});
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("orderNum", orderNum);
            searchParams.set("productionLineID", this.state.activeProductionLineId);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    getScheduleOnDatePickerCSSClassName(isUserSchedule) {
        let cssClassName = "";
        switch (isUserSchedule) {
            case 0:
                cssClassName = "";
                break;
            case 1:
                cssClassName = "text-danger";
                break;
            case 2:
                cssClassName = "text-warning";
                break;
            case 3:
                cssClassName = "text-success";
                break;
            case 4:
                cssClassName = "text-danger";
                break;
            default:
                // for more than 3
                cssClassName = "text-success";
                break;
        }
        return cssClassName;
    }

    getColValue(scheduleBucketIndex, storeItem, order) {
        let {activeProductionLineId} = this.state;
        let value = order[storeItem.key];
        switch (storeItem.type) {
            case "link_order_enquiry_view_mode_production":
                let powdercoatButton = null;

                let cutIn_cutOut_Button = null;
                let link_icon = null;
                let ac_dc_icon = null; // motor type
                let sewn_icon = null; //isSewn

                if (order.productGroupCode === "PWD") {
                    let powdercoatButtonColor = "primary";
                    if (order.salesOrderJobPowdercoatPurchaseOrderNum) {
                        powdercoatButtonColor = "warning";
                    }
                    if (order.salesOrderJobPowdercoatPurchaseOrderEmailedDate) {
                        powdercoatButtonColor = "success";
                    }
                    powdercoatButton = <Button size={"sm"}
                                               color={powdercoatButtonColor}
                                               disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                                               onClick={() => this.handleChange("powdercoating_form_open_modal", order, null)}>
                        PC
                    </Button>
                }

                // if we have cutJob Id and its status also
                if (order.cutJobID) {
                    let color = "secondary";
                    switch (true) {
                        case order.cutJobSalesOrderJobStatusID < 90:
                            color = "warning";  // means fabric is cutt but not welded
                            break;
                        case order.cutJobSalesOrderJobStatusID >= 90:
                            color = "success"; // (means fabric is cut-ed and welded also)
                            break;
                        default:
                            color = "secondary";
                    }
                    // to make the "CF" (Orange||blue|| green) we need to check if the cutjob of this order has qty or not
                    let {productionLinesSchedule} = this.state;
                    for (let index = 0; index < productionLinesSchedule.length; index++) {
                        let orderIndex = findIndex(productionLinesSchedule[index].orders, 'salesOrderJobID', order.cutJobID);
                        if (orderIndex > -1) {
                            // -------------------cut fabric job (object)
                            let cf_order = productionLinesSchedule[index].orders[orderIndex];
                            /*if (cf_order.qtyUsed && cf_order.salesOrderJobStatusID < 90) {
                                color = "warning";  // means fabric is cutt but not welded
                            }
                            else if (cf_order.salesOrderJobStatusID >= 90) {
                                color = "success" //success  (means fabric is cut-ed and welded also)
                            }
                            else*/
                            if ((cf_order) && (cf_order.qtyUsed === null || cf_order.qtyUsed === undefined || cf_order.qtyUsed === "") && (cf_order.salesOrderJobStatusID < 90)) {
                                color = "primary"; // blue
                            }
                            break;
                        }
                    }
                    cutIn_cutOut_Button = <Button size={"sm"}
                                                  key={order.cutJobID}
                                                  color={color}
                                                  onClick={() => this.handleChange("Navigate", order, order.cutJobID)}>
                        {order.cutJobProductionLineLabel}
                    </Button>
                }

                if (order.parentSalesOrderJobId) {
                    link_icon = <Button size={"sm"}
                                        color={"warning"}
                                        onClick={() => this.handleChange("Navigate", order, order.parentSalesOrderJobId)}>
                        <i className={"fa fa-link ml-1 mr-1"} aria-hidden="true"/>
                    </Button>
                }

                if (order.motorType) {
                    ac_dc_icon = <Button size={"sm"}
                                         color={"primary"} title={order.motorType}>
                        <i className={classnames("fa", "mr-1", "ml-1", {
                            "fa-plug": (order.motorType.toLowerCase() === "ac motor"),
                            "fa-battery-three-quarters": (order.motorType.toLowerCase() !== "ac motor") //DC MOTOR
                        })} aria-hidden='true'/>
                    </Button>
                }

                if (order.isSewn) {
                    sewn_icon = <Button size={"sm"}
                        className={"text-light"}
                        style={{ backgroundColor: "#009688", border: "none" }}
                        title="Sewing job(s) exist.">
                        <strong>&#10650;</strong>
                    </Button>
                }
                return <ButtonGroup>
                    <Link
                        className={"btn btn-sm btn-" + getOrderColorName(order, this.state.highlightedOrder.ordNum) + ""}
                        style={{color: "white"}}
                        title={"Click here to view order in detail"}
                        onClick={() => this.onOrderClick(value)}
                        to={"/sales/order/enquiry?" + queryString.stringify({
                            OrdNum: value,
                            viewMode: "production",
                            productGroupCode: order.productGroupCode,
                            activeTab: order.productGroupCode === "COM" ? "allParts" : ""
                        })}>{value}</Link>
                    {
                        order.isMultiProductOrder
                            ? <Button size={"sm"}
                                      color={"warning"}
                                      onClick={() => this.handleChange("sameOrderItemsModal_trigger", "isMultiProductOrder", order)}>
                                <i className={"fa fa-flag ml-1 mr-1"} aria-hidden="true"/>
                            </Button>
                            : null
                    }
                    {powdercoatButton}
                    {cutIn_cutOut_Button}
                    {link_icon}
                    {ac_dc_icon}
                    {sewn_icon}
                    {
                        order && order.isRework ?
                            <Button style={{ 'background-color': '#ffa500', 'color': 'white', 'border-color': '#ffa500' }} title={"Rework"} size={"sm"} onClick={() => this.handleClick(order, "rework")}>
                                R
                            </Button>
                            : null
                    }
                    {
                        order && order.isCustom ?
                            <Button size={"sm"} title={"Custom Order"}
                                color={"warning"} disabled style={{ opacity: 1 }}>
                                <i className="fa fa-wrench" />
                            </Button>
                            : null
                    }
                </ButtonGroup>;

            case "utcDateString":
                if (storeItem.key === "custRequiredDate") {
                    return order.custRequiredDateLocalDateString;
                }
                if (storeItem.key === "custOrdDate") {
                    return order.custOrdDateLocalDateString;
                }
                return getDateString(value, 'DDMMMYY');
            case "boolean":
                if (storeItem.key === "hasPowderCoating") {
                    if (value) {
                        let pcSalesOrderJob = {};
                        this.state.productionLinesSchedule.filter(x => x.productionLine.code === "PWD").forEach((pls, index) => {
                            pcSalesOrderJob = (pls.orders || []).find(o => o.ordNum === order.ordNum && o.productGroupCode === "PWD");
                        });
                        if (pcSalesOrderJob) {
                            return <Button title={'Click here to view Powdercoating order details'} size={"sm"}
                                           color={getDebtorInvoiceStatusColour(pcSalesOrderJob.salesOrderJobStatusID)}
                                           onClick={() => this.handleChange("powdercoating_form_open_modal", pcSalesOrderJob, null)}>{pcSalesOrderJob.salesOrderJobStatusCode}</Button>;
                        }
                        return "Yes";
                    }

                }
                if (value === 1 || value) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                return "No";
            case "currency":
                return <NumberFormat value={Math.floor(value)}
                                     displayType={'text'}
                                     thousandSeparator={true}
                                     prefix={'$'}/>;
            case "number":
                return <NumberFormat value={value}
                                     displayType={'text'}
                                     thousandSeparator={true}/>;
            case "scheduleOn":
                let cssClassName = this.getScheduleOnDatePickerCSSClassName(order.isUserSchedule);
                return <Button color={"link"} size={"sm"} className={cssClassName}
                               disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                    onClick={() => this.handleChange("productionSchedule_reschedule_scheduleJob_openModal", order, null)}>{changeFormatOfDateString(order.scheduleOn, "DD/MM/YYYY", "DDMMMYY")}</Button>;

            /*return <ChangeScheduledDate selected={order.scheduleOnDateObject}
                                        cssClassName={cssClassName + ' schedule-datepicker'}
                                        handleChange={(selectedDateObj) => this.handleChange("productionSchedule_scheduleJobs_scheduledOn", getDateString(selectedDateObj, "DD/MM/YYYY"), order)}
            />;*/
            case "scheduleButton":
                return <div className={"align-middle text-center"} style={{minWidth: 75}}>
                    {/*<ChangeScheduledDate selected={order.scheduleOnDateObject}
                                         cssClassName={"form-control form-control-sm schedule-datepicker"}
                                         isDisabled={storeItem.isDisabled || this.state.hasProductionScheduleReadonlyPrivilege}
                                         handleChange={(selectedDateObj) => this.handleChange("scheduleOn_scheduleProductionDeschedule", getDateString(selectedDateObj, "DD/MM/YYYY"), order)}
                    />*/}
                    <Button color={"link"} size={"sm"} className={"primary"}
                        disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                        onClick={() => this.handleChange("productionSchedule_deschedule_scheduleJob_openModal", order, null)}><i className="fa fa-pencil mr-2"
                            aria-hidden="true" /></Button>
                </div>;
            case "followUpDatePicker":
                return <div style={{minWidth: 75}}>
                    <ChangeScheduledDate selected={order.followUpDateObject}
                                         cssClassName={"form-control form-control-sm schedule-datepicker"}
                                         isDisabled={this.state.hasProductionScheduleReadonlyPrivilege}
                                         handleChange={(selectedDateObj) => this.handleChange("followUpDate_productionDeschedule", getDateString(selectedDateObj, "DD/MM/YYYY"), order)}
                    />
                </div>;
            case "descheduleButton":
                return <div className="text-center">
                    <Button
                        size={"sm"}
                        color={"primary"}
                        disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                        onClick={() => this.handleChange("deschedule_open_model", !value, order)}>
                        <i className={classnames("fa ", "ml-2", "mr-2", {"fa-calendar": value, "fa-trash": !value,})}
                           aria-hidden="true"/>
                    </Button>
                </div>;
                break;
            case "status":
                if (activeProductionLineId === 8) {
                    return <Button title={'Click here to update status'} size={"sm"}
                                   color={getDebtorInvoiceStatusColour(order.salesOrderJobStatusID)}
                                   onClick={() => this.openOrderStatusChangeModal(order)}>{value}</Button>;
                }
                return <Badge title={'Click here to update status'} className={'hand-cursor'}
                              color={getDebtorInvoiceStatusColour(order.salesOrderJobStatusID)}
                              onClick={() => this.openOrderStatusChangeModal(order)}>{value}</Badge>;
                break;
            case "completeStatusButton":
                return <div className="text-center" style={{minWidth: 125}}>
                    <ButtonGroup>
                        {(order.parentSalesOrderJobId && order.isFabricChoppedButJobIncomplete) ?
                            <Button
                                size={"sm"}
                                disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                                color={"warning"}
                                onClick={() => this.checkDescheduledStatus(order)}>
                                <i className={classnames("fa ", "mr-2", {
                                    "fa-check-circle": order.salesOrderJobIsCompleted,
                                    "fa-check-circle-o": !order.salesOrderJobIsCompleted,
                                })}
                                   aria-hidden="true"/>
                                Complete
                            </Button> :
                            <Button
                                size={"sm"}
                                disabled={this.state.hasProductionScheduleReadonlyPrivilege || order.salesOrderJobIsCompleted}
                                color={order.salesOrderJobIsCompleted ? "success" : "primary"}
                                onClick={() => this.checkDescheduledStatus(order)}>
                                <i className={classnames("fa ", "mr-2", {
                                    "fa-check-circle": order.salesOrderJobIsCompleted,
                                    "fa-check-circle-o": !order.salesOrderJobIsCompleted,
                                })}
                                   aria-hidden="true"/>
                                {order.salesOrderJobIsCompleted ? "Completed" : "Complete"}
                            </Button>}
                        {/*
                        {(order.productionLineID === 11 && order.productionScheduleID) ?
                            <Button
                                size={"sm"} className={"ml-2"}
                                disabled={order.exportFabricCut}
                                color={"primary"}
                                onClick={() => this.exportFabricCutData(scheduleBucketIndex, 'singleSelected', order.productionScheduleID)}>
                                {(order.exportFabricCut)
                                    ? <Spinner color="light" size={"sm"}
                                               className={"mr-2"}/>
                                    : <i className="fa fa-file-excel-o mr-2"
                                         aria-hidden="true"/>}
                                Export Cut
                            </Button>
                            : null
                        }
                        */}
                    </ButtonGroup>
                </div>;
                break;

            case "qtyUsed":
                return <Input
                    key={order.salesOrderJobID}
                    type={"number"}
                    title={"Enter actual qty used(should not be negative value & should not be greater than fabric QOH)"}
                    name={"qtyUsed"}
                    disabled={order.salesOrderJobIsCompleted || this.state.hasProductionScheduleReadonlyPrivilege}
                    invalid={order.qtyUsed < 0 || order.qtyUsed > order.keywayQOH}
                    placeholder={"Qty used"}
                    value={order.qtyUsed}
                    onChange={(e) => this.handleChange("productionSchedule_salesOrderJob_qtyUsed", e.target.value, order)}
                />;

                break;
            case "keywayQOH":
                let displayToolTip = "Keyway QOH - " + order.keywayQOH;
                if (order.keywayQOH === null || order.keywayQOH === 0) {
                    displayToolTip = "Keyway does not have any Qty in hand for this fabric."
                }
                return <div className="text-center">
                    <a href={"javascript:void(0)"} id={"QOH_soj__" + order.salesOrderJobID}
                       style={{color: order.keywayQOH ? '#007bff' : 'red'}}>
                        <i className="fa fa-info-circle" aria-hidden="true"/>
                    </a>
                    <UncontrolledTooltip placement="right" target={"QOH_soj__" + order.salesOrderJobID}>
                        {displayToolTip}
                    </UncontrolledTooltip>
                </div>;

                break;

            case "productionDescheduleNotes":
                return <div>
                    {order.productionDescheduleNotes}
                    {
                        this.state.hasProductionScheduleReadonlyPrivilege
                            ? null
                            : <a href="javascript:void(0)"
                                 className={"ml-2"}
                                 title={'Click here to update notes'}
                                 onClick={() => this.openProductionDescheduleNotesModal(order)}>
                                <i className="fa fa-pencil" aria-hidden="true"/>
                            </a>
                    }
                </div>;
            case "whStatus":
                order.whStatus = order.whStatus.replace("-", "");
                return <div>
                    {value}
                    <a href="javascript:void(0)"
                       className={"ml-2"}
                       title={'Click here to update production notes'}
                       onClick={() => this.openProductionScheduleOrderProdStatusChangeModal(order)}>
                        <i className="fa fa-pencil" aria-hidden="true"/>
                    </a>
                </div>;
            case "checkbox":
                return <div className={"text-center"}>
                    <a href={"javascript:void(0)"} onClick={() => this.selectRecord(value, order.ordNum)}>
                        <i className={classnames("fa", "fa-lg", {
                                "fa-check-square-o": value,
                                "fa-square-o": !value,
                            }
                        )}/>

                    </a>
                </div>;
            case "date":
                return <span> {changeFormatOfDateString(value, 'YYYY-MM-DD HH:mm:ss', 'DD MMM HH:mm')}</span>;
            case "stockGroupName":
                return <a href="javascript:void(0)" onClick={() => this.setState({ order, isOpenJobInfoModal: true })}>{value}</a>;
            case "oosDetails":
                return value && value.length ?
                    <Table size={"sm"} striped responsive bordered className="m-0 p-0">
                        <thead>
                            <tr>
                                <th className="m-0 p-0">Prod Code</th>
                                <th className="m-0 p-0">PO No.</th>
                                <th className="m-0 p-0">PO ETA Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.map((data, index) =>
                                <tr key={index}>
                                    <td className="m-0 p-0">
                                        {data.prodCode ?
                                            <ButtonGroup>
                                                <a href={"/inventory/stock/enquiry?prodCode=" + data.prodCode}
                                                    target="_blank"
                                                    className={"btn btn-sm btn-" + (data.purchaseOrderOrdNum ? "primary" : "danger")}
                                                    title="Click here to see stock enquiry">
                                                    {data.prodCode}
                                                </a>
                                                <Button size={"sm"} title={"OOS Detail"} color={data.isCanMake ? "success" : "danger"} onClick={() => this.handleClick(order, "oosDetail")}>
                                                    <i className={"fa fa-" + (data.isCanMake ? "check" : "times")} />
                                                </Button>
                                            </ButtonGroup>
                                            : null}
                                    </td>
                                    <td className="m-0 p-0">
                                        {data.purchaseOrderOrdNum ?
                                            <a href={"/purchase/order/enquiry?ordNum=" + data.purchaseOrderOrdNum}
                                                target="_blank"
                                                className={"btn btn-sm btn-" + (data.statusID === 4 ? "success" : "primary")}
                                                title="Click here to open this PO">
                                                {data.purchaseOrderOrdNum}
                                            </a>
                                            : null}
                                    </td>
                                    <td className="align-middle m-0 p-0">{changeFormatOfDateString(data.eta, "YYYY-MM-DD", "DD MMM YYYY")}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    : null;
                break;
            default:
                return <span>{value}</span>
        }
    }

    updateUrl(ordNum) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("OrdNum", ordNum);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    getReworkOrder(ordNum) {
        if (ordNum) {
            this.orderService.getReworkOrderDetail(ordNum).then(response => {
                this.setState({ reworkOrder: response.data });
                this.toggleReworkOrderSummaryModal();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    getOOSDetails(ordNum) {
        if (ordNum) {
            this.orderService.getOOSDetails(ordNum).then(response => {
                this.setState({ oosDetails: response.data });
                this.toggleOOSDetailsModal();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    getOrder(ordNum, navigation) {
        this.setState({ isLoadingOrder: true });
        this.orderService.getOrder(ordNum, true, true, true, navigation).then(response => {
            let order = response.data;
            if (order) {
                order.shippingAdrress = getDeliveryAddressString(order);
                order.parentCompanyAdrress = getParentCompanyAddress(order);
                order.products = orderEnquiryUtil.initOrderProducts(order);
                let salesOrder = order.salesOrder;
                if (salesOrder) {
                    if (salesOrder.attachmentIDs) {
                        let attachmentIDs = salesOrder.attachmentIDs;
                        salesOrder.attachmentIDs = [];
                        salesOrder.attachmentIDs.push(attachmentIDs);
                    }
                    else if (!salesOrder.attachmentIDs) {
                        salesOrder.attachmentIDs = [];
                    }

                    if (salesOrder.salesOrderItems && salesOrder.salesOrderItems.length > 0) {
                        salesOrder.salesOrderItems.forEach(soi => {
                            if (soi.attachmentIDs) {
                                salesOrder.attachmentIDs.push(soi.attachmentIDs);
                            }
                        });
                    }

                    if (salesOrder.attachmentIDs && salesOrder.attachmentIDs.length > 0) {
                        salesOrder.attachmentIDs = salesOrder.attachmentIDs.join();
                    }
                    order.createdFrom = salesOrder.createdFrom;
                    salesOrder.retailAddress = getRetailDeliveryAddressString(salesOrder);
                }
                //Default open Product Table
                if (order.products[0]) {
                    order.products[0].isOpen = true;
                }
                this.setState({
                    order: order,
                    isLoadingOrder: false,
                    salesOrder: salesOrder,
                    ordNum: order.ordNum
                }, () => {
                    this.updateUrl(order.ordNum);
                });
            } else {
                this.setState({ isLoadingOrder: false });
            }
        }).catch(error => {
            console.error(error);
            this.setState({ isLoadingOrder: false });
            toast.error(handleErrorMessage(error));
        });
    }

    handleClick(order, type) {
        if (order) {
            this.setState({ order });
            switch (type) {
                case "rework": this.getReworkOrder(order.ordNum);
                    break;
                case "oosDetail": this.getOOSDetails(order.ordNum);
                    break;
                default:
                    break;
            }
            if (this.props.location) {
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?orderNum=' + order.ordNum;
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    }

    toggleReworkOrderSummaryModal() {
        this.setState((prevState) => ({ isOpenReworkOrderSummaryModal: !prevState.isOpenReworkOrderSummaryModal }));
    }

    toggleOOSDetailsModal() {
        this.setState((prevState) => ({ isOpenOOSDetailsModal: !prevState.isOpenOOSDetailsModal }));
    }

    openOrderStatusChangeModal(order) {
        this.setState({isOpenOrderStatusChangeModal: true, selectedOrderForStatusChange: order});
    }

    toggleOrderStatusChangeModal(value) {
        this.setState({isOpenOrderStatusChangeModal: value});
    }

    handleCloseOrderStatusChangeModal(data, salesOrderJobStatusId) {

        let {activeProductionLineId, productionLinesSchedule, collapseTriggers, selectedOrderForStatusChange, salesOrderJobStatuses} = this.state;

        //updating status on UI
        if (salesOrderJobStatusId) {
            let index = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
            let orderIndex = findIndex(productionLinesSchedule[index].orders, 'salesOrderJobID', selectedOrderForStatusChange.salesOrderJobID);

            let salesOrderJobStatusIndex = findIndex(salesOrderJobStatuses.statuses, 'id', parseInt(salesOrderJobStatusId));

            if (orderIndex > -1) {
                productionLinesSchedule[index].orders[orderIndex].salesOrderJobStatusID = salesOrderJobStatuses.statuses[salesOrderJobStatusIndex].id;
                productionLinesSchedule[index].orders[orderIndex].salesOrderJobStatusCode = salesOrderJobStatuses.statuses[salesOrderJobStatusIndex].statusCode;
                productionLinesSchedule[index].orders[orderIndex].salesOrderJobStatusDescription = salesOrderJobStatuses.statuses[salesOrderJobStatusIndex].statusDescription;
                productionLinesSchedule[index].orders[orderIndex].salesOrderJobIsOnHold = salesOrderJobStatuses.statuses[salesOrderJobStatusIndex].isOnHold;
                productionLinesSchedule[index].orders[orderIndex].salesOrderJobIsCompleted = salesOrderJobStatuses.statuses[salesOrderJobStatusIndex].isCompleted;
            }

            productionLinesSchedule[index] = productionScheduleService.prepareProductionLineSchedule(productionLinesSchedule[index], collapseTriggers);
        }

        this.setState({isOpenOrderStatusChangeModal: data, productionLinesSchedule});
    }

    getConfirmModal() {
        let {activeProductionLineId, workingConfirmCompleteStatusOrder} = this.state;
        let primaryMessage = '', secondaryMessage = '';
        if (workingConfirmCompleteStatusOrder) {
            switch (activeProductionLineId) {
                case 11:
                case 12:
                    if (isEmpty(workingConfirmCompleteStatusOrder.qtyUsed)) {
                        workingConfirmCompleteStatusOrder.qtyUsed = 0;
                    }
                    return {
                        primaryMessage: '<h5>Complete Order ' + workingConfirmCompleteStatusOrder.ordNum + ' Cut Fabric Job </h5>',
                        secondaryMessage: '<p className={"m-2"}>' + "Total Fabric Quantity left is <b>" + workingConfirmCompleteStatusOrder.keywayQOH + "</b> mtr" + "'s " + " , Are you sure you want to complete this job by using quantity of <b>" + workingConfirmCompleteStatusOrder.qtyUsed + "</b> mtr? </p>"
                    };
                default:
                    primaryMessage = '<h5>Confirm Status Complete</h5>';
                    secondaryMessage = '<p className={"m-2"}>Are you sure you want to mark the status of Order No.' + workingConfirmCompleteStatusOrder.ordNum + ' as Completed?</p>';
                    return {
                        primaryMessage,
                        secondaryMessage

                    };
            }
        }
        else {
            return {
                primaryMessage,
                secondaryMessage
            };
        }
    }

    updateLabelPrintDate(scheduleBucket, scheduleBucketIndex) {
        let { activeProductionLineId, productionLinesSchedule } = this.state;
        let isCut = false;
        let activeProductionLineIndex = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
        let request = [];
        if (scheduleBucketIndex != null) {
            isCut = true;
            (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.selected).map((order) => {
                request.push({
                    ordNum: order.ordNum,
                    jobID: order.salesOrderJobID,
                    productionLineId: activeProductionLineId
                });
            });
        }
        else {
            scheduleBucket.orders.map(order => {
                request.push({
                    ordNum: order.ordNum,
                    jobID: order.salesOrderJobID,
                    productionLineId: activeProductionLineId
                });
            });
        }
        if (request.length > 0) {
            productionScheduleService.updateLabelPrintDate(request, isCut).then(response => {
                this.refresh();
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    downloadLabels(scheduleBucket) {

        let request = {
            productionLineShortName: scheduleBucket.productionLine.shortName,
            orders: scheduleBucket.orders.map(order => {
                return {ordNum: order.ordNum, productGroupCode: order.productGroupCode};
            })
        };
        this.setState({loadingLabels: scheduleBucket.identityKey});

        productionScheduleService.downloadLabels(request).then(response => {
            let fileName = scheduleBucket.productionLine.shortName + "_" + scheduleBucket.label + "_Labels.pdf";

            // download response as file
            //FileSaver.saveAs(response.data, fileName);

            //open response as file in new tab
            window.open(URL.createObjectURL(response.data));
            this.setState({ loadingLabels: "" }, () => this.updateLabelPrintDate(scheduleBucket, null));
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    openScheduleJobPriorityModal(scheduleBucket) {
        this.setState({selectedScheduleBucket: scheduleBucket, isOpenScheduleJobPriorityModal: true});
    }

    toggleScheduleJobPriorityModal(data) {
        this.setState({isOpenScheduleJobPriorityModal: data})
    }

    selectAllRecords(scheduleBucketIndex, change, key, value) {
        let {activeProductionLineId, productionLinesSchedule} = this.state;
        let activeProductionLineIndex = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
        let counter = 0;
        switch (key) {
            case "multipleSelected":
                (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).forEach((order, orderIndex) => {
                    order.selected = change;
                });
                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected = change;
                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].isShowFabricCutButton = change;
                break;
            case "singleSelected":
                (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).forEach((order, orderIndex) => {
                    if (order.productionScheduleID === value) {
                        order.selected = change;
                    }
                    if (order.selected) {
                        counter++;
                    }
                });

                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected =
                    (counter === productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders.length) ? true : false;
                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].isShowFabricCutButton = counter > 0 ? true : false;
        }

        this.setState({productionLinesSchedule});
    }

    exportFabricCutData(scheduleBucketIndex, key, value, action) {
        let {activeProductionLineId, productionLinesSchedule, fabricCutLoading} = this.state;
        let activeProductionLineIndex = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
        let productionScheduleIds = [];
        switch (key) {
            case 'multipleSelected':
                // this.setState({ fabricCutLoading: true });
                (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.selected).forEach((order, orderIndex) => {
                    productionScheduleIds.push(order.productionScheduleID);
                });
                if (action === 'fabric cut data download') {
                    productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].fabricCutDownloadLoading = true;
                }
                else {
                    productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].fabricCutLoading = true;
                }
                break;
            case 'singleSelected':
                (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || [])
                    .filter(x => x.productionScheduleID === value).forEach((order, orderIndex) => {
                    order.exportFabricCutLoading = true;
                });
                productionScheduleIds.push(value);
                break;
        }
        this.setState({productionLinesSchedule});
        let request = {productionScheduleIds: productionScheduleIds, action: action};
        productionScheduleService.exportFabricCutData(request).then(response => {
            // download response as file

            (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.selected).forEach((order, orderIndex) => {
                order.selected = false;
                order.backgroundColor = "#5de453";
            });
            if (value) {
                (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.productionScheduleID === value).forEach((order, orderIndex) => {
                    order.exportFabricCut = false;
                });
            }
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].isShowFabricCutButton = false;
            if (action === 'fabric cut data download') {
                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].fabricCutDownloadLoading = false;
                FileSaver.saveAs(response.data, "Fabric_Cut_Job.txt");
            }
            else {
                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].fabricCutLoading = false;
                toast.success("File is saved");
            }
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected = false;
            this.setState({productionLinesSchedule});
        }).catch(error => {

            //stop loading 
            if (value) {
                (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.productionScheduleID === value).forEach((order, orderIndex) => {
                    order.exportFabricCut = false;
                });
            }
            if (action === 'fabric cut data download') {
                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].fabricCutDownloadLoading = false;
            }
            else {
                productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].fabricCutLoading = false;
            }
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected = false;
            this.setState({productionLinesSchedule});
            toast.error(handleErrorMessage(error));
        })

    }

    downloadAndCutSelectedOrderData(scheduleBucketIndex) {
        let {activeProductionLineId, productionLinesSchedule} = this.state;
        let activeProductionLineIndex = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
        productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].printSelectedLabelLoading = true;
        this.setState({productionLinesSchedule});
        let orders = [], productionScheduleIds = [];

        (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.selected).map((order, orderIndex) => {
            productionScheduleIds.push(order.productionScheduleID);
            orders.push({ordNum: order.ordNum, productGroupCode: order.productGroupCode});
        });
        //get request
        let printLabelRequest = {
            productionLineShortName: productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].productionLine.shortName,
            orders: orders
        };

        let machineDataRequest = {
            productionScheduleIds: productionScheduleIds,
            action: 'fabric cut data save at shared location'
        };

        let request = {
            machineDataExportRequest: machineDataRequest,
            productionScehduleDownloadLabelRequest: printLabelRequest
        };
        productionScheduleService.downloadAndCut(request).then(response => {
            //open response as file in new tab
            window.open(URL.createObjectURL(response.data));
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].isShowFabricCutButton = false;
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].printSelectedLabelLoading = false;
            this.updateLabelPrintDate(null, scheduleBucketIndex);
            (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.selected).forEach((order, orderIndex) => {
                order.selected = false;
                order.backgroundColor = "#5de453";
            });
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected = false;
            toast.success("File is saved");
            this.setState({productionLinesSchedule});
        }).catch(error => {
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].printSelectedLabelLoading = false;
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected = false;
            this.setState({productionLinesSchedule});
            toast.error(handleErrorMessage(error));
        });

    }

    downloadSelectedLabel(scheduleBucketIndex) {
        let {activeProductionLineId, productionLinesSchedule} = this.state;
        let activeProductionLineIndex = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
        productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].selectedLabelLoading = true;
        this.setState({productionLinesSchedule});

        //get request
        let request = {
            productionLineShortName: productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].productionLine.shortName,
            orders: (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.selected).map((order, orderIndex) => {
                return {ordNum: order.ordNum, productGroupCode: order.productGroupCode};
            })
        };

        productionScheduleService.downloadLabels(request).then(response => {
            //open response as file in new tab
            window.open(URL.createObjectURL(response.data));
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].isShowFabricCutButton = false;
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].selectedLabelLoading = false;
            this.updateLabelPrintDate(null, scheduleBucketIndex);
            (productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].orders || []).filter(x => x.selected).forEach((order, orderIndex) => {
                order.selected = false;
                order.backgroundColor = "#5de453";
            });
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected = false;
            this.setState({productionLinesSchedule});
        }).catch(error => {
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].selectedLabelLoading = false;
            productionLinesSchedule[activeProductionLineIndex].scheduleBuckets[scheduleBucketIndex].allRecordsSelected = false;
            this.setState({productionLinesSchedule});
            toast.error(handleErrorMessage(error));
        });

    }

    render() {
        let {
            productionLinesSchedule,
            activeProductionLineId,
            loadingDefaultProductionLineSchedule,
            updatingActiveProductionLineSchedule,

            isOpenUpdateCustomProductionCapacityModal,
            savingCustomProductionCapacity,
            workingProductionCapacity,

            isOpenProductionScheduleRebuildConfirmModal,
            isRebuildToday,
            loadingProductionScheduleRebuild,

            descheduleReasons,

            workingDescheduleProductionSchedule,
            isOpenDescheduleProductionScheduleModal,
            loadingDescheduleProductionScheduleSubmit,

            isOpenSameOrderItemsModal,
            triggeredBySameOrderItemsModal,
            searchText,
            highlightedOrder,

            isHiddenExtendedColumn,
            isHiddenOOSDetailsColumn,
            loadingLabels,
            loadingIsProductionDateClosed,

            canRescheduleProduction,
            canUpdateProductionCapacity,

            isOpenConfirmCompleteStatusModal,
            workingConfirmCompleteStatusOrder,

            powdercoatingForm,
            workingScheduleJob,
            isOpenOrderStatusChangeModal,
            selectedOrderForStatusChange,
            salesOrderJobStatuses,
            isOpenProductionDescheduleNoteUpdateModal,
            selectedSalesOrderForProductionDescheduleNoteUpdate,
            isOpenScheduleJobPriorityModal,
            isOpenProductionScheduleOrderProdStatusChangeModal,
            isOpenReworkOrderSummaryModal,
            isOpenJobInfoModal,
            isOpenOOSDetailsModal,
            isOpenDescheduledOrderModal,
            allRecordsSelected,
            isShowFabricCutButton,
            fabricCutLoading,
            order,
            reworkOrder,
            oosDetails,
            currentUser, 
            isRescheduleFromDeschedule,
            descheduledMessage
        } = this.state;
        let activeProductionLineIndex = findIndex(productionLinesSchedule, 'id', activeProductionLineId);
        let {primaryMessage, secondaryMessage} = this.getConfirmModal();
        return <div>
            <Row className={"mb-2"}>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <h4>Production Schedule</h4>
                    {
                        canRescheduleProduction
                            ? <Button color={"primary"}
                                      size={"sm"}
                                      className={"mr-2"}
                                      disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                                      onClick={() => this.toggleProductionScheduleRebuildConfirmModal(!isOpenProductionScheduleRebuildConfirmModal)}>
                                <i className="fa fa-calendar mr-1" aria-hidden="true"/>Reschedule
                            </Button>
                            : null
                    }
                </Col>

                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="text-right">
                        <Link className="btn btn-primary btn-sm mr-2" to={"/production/productionDashboard"}>
                            <i className="fa fa-line-chart mr-1" aria-hidden="true"/>Dashboard
                        </Link>
                        <Button color={"primary"}
                            size={"sm"}
                            className={"mr-2"}
                            outline={isHiddenOOSDetailsColumn}
                            onClick={() => this.toggleOOSDetailsColumn(!isHiddenOOSDetailsColumn)}>
                            <i className={classnames("fa", "mr-1", {
                                "fa-eye": isHiddenOOSDetailsColumn,
                                "fa-eye-slash": !isHiddenOOSDetailsColumn,
                            })} aria-hidden="true" />
                            {isHiddenOOSDetailsColumn ? "Show" : "Hide"}&nbsp;OOS Details
                        </Button>
                        <Button color={"primary"}
                                size={"sm"}
                                className={"mr-2"}
                                outline={isHiddenExtendedColumn}
                                onClick={() => this.toggleExtendedColumn(!isHiddenExtendedColumn)}>
                            <i className={classnames("fa", "mr-1", {
                                "fa-eye": isHiddenExtendedColumn,
                                "fa-eye-slash": !isHiddenExtendedColumn,
                            })} aria-hidden="true"/>
                            {isHiddenExtendedColumn ? "Show extended columns" : "Hide extended columns"}
                        </Button>
                        <Button color={"primary"}
                                size={"sm"}
                                onClick={() => this.toggleSameOrderItemsModal(true)}>
                            <i className="fa fa-search mr-1 ml-1" aria-hidden="true"/>
                        </Button>
                    </div>
                </Col>
            </Row>
            {
                loadingDefaultProductionLineSchedule
                    ? <Spinner color={"primary"}/>
                    : <div>
                        {
                            isEmpty(productionLinesSchedule)
                                ? null
                                : <Card>
                                    <CardHeader>
                                        <Nav tabs card>
                                            {(productionLinesSchedule) && productionLinesSchedule.map((productionLineSchedule, productionLineScheduleIndex) => {
                                                return <NavItem className={"hoverableItem"} key={productionLineScheduleIndex}>
                                                    <NavLink
                                                        tag={"span"}
                                                        active={productionLinesSchedule[activeProductionLineIndex].productionLine.productionLineId === productionLineSchedule.productionLine.productionLineId}
                                                        onClick={() => {
                                                            this.toggleProductionLineTab(productionLineSchedule);
                                                        }}
                                                    >
                                                        <Button style={{textDecoration: "none"}}
                                                                size={"sm"}
                                                                color={"link"}>{productionLineSchedule.productionLine.shortName === 'COMPONENTS' ? 'W/H PICKS' : productionLineSchedule.productionLine.shortName}</Button>
                                                    </NavLink>
                                                </NavItem>
                                            })}
                                        </Nav>
                                    </CardHeader>
                                    <CardBody>
                                        {
                                            (productionLinesSchedule[activeProductionLineIndex].productionLine.code === "CIN" || productionLinesSchedule[activeProductionLineIndex].productionLine.code === "COD")
                                                ?
                                                <div className={'text-right'}>
                                                    <CSVLink
                                                        className={'btn btn-primary btn-sm mr-2'}
                                                        headers={productionLinesSchedule[activeProductionLineIndex].exportFirstFiveBucketCSV.headers}
                                                        data={productionLinesSchedule[activeProductionLineIndex].exportFirstFiveBucketCSV.data}
                                                        filename={productionLinesSchedule[activeProductionLineIndex].exportFirstFiveBucketCSV.filename}>
                                                        <i className="fa fa-file-excel-o mr-2" aria-hidden="true"/>
                                                        Export Buckets
                                                    </CSVLink>
                                                </div>
                                                : null
                                        }

                                        {
                                            updatingActiveProductionLineSchedule
                                                ? <Spinner color={"primary"}/>
                                                : <div>
                                                    {productionLinesSchedule[activeProductionLineIndex].scheduleBuckets.map((scheduleBucket, scheduleBucketIndex) => {
                                                        return <Card className={"mt-3"} key={scheduleBucketIndex}>
                                                            <CardHeader>
                                                                <Row className="align-middle">
                                                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                                        <Button color={"link"} className={"mb-2 align-middle mt-2"}
                                                                                size={"sm"}
                                                                                onClick={() => this.toggleScheduleCollapse(scheduleBucketIndex, !scheduleBucket.isOpen)}>
                                                                            <i className={classnames("fa", "fa-lg", "fw", "mr-3", {
                                                                                    "fa-angle-up": scheduleBucket.isOpen,
                                                                                    "fa-angle-down": !scheduleBucket.isOpen,
                                                                                }
                                                                            )} aria-hidden="true"/>{scheduleBucket.label}
                                                                        </Button>
                                                                        {
                                                                            scheduleBucket.isShowFabricCutButton ?
                                                                                <>
                                                                                <Button
                                                                                    color={"primary"} className={"mb-2 mr-2"}
                                                                                    size={"sm"}
                                                                                    disabled={scheduleBucket.fabricCutDownloadLoading}
                                                                                    onClick={() => this.exportFabricCutData(scheduleBucketIndex, 'multipleSelected', null, 'fabric cut data download')}
                                                                                >{(scheduleBucket.fabricCutDownloadLoading)
                                                                                    ? <Spinner color="light" size={"sm"}
                                                                                               className={"mr-2"}/>
                                                                                    : <i className="fa fa-print mr-2"
                                                                                         aria-hidden="true"/>}
                                                                                    Download Cuts
                                                                                </Button>
                                                                                {/*
                                                                                <Button
                                                                                    color={"primary"} className={"mr-2 mb-2"}
                                                                                    size={"sm"}
                                                                                    disabled={scheduleBucket.fabricCutLoading}
                                                                                    onClick={() => this.exportFabricCutData(scheduleBucketIndex, 'multipleSelected', null, 'fabric cut data save at shared location')}
                                                                                >{(scheduleBucket.fabricCutLoading)
                                                                                    ? <Spinner color="light" size={"sm"}
                                                                                               className={"mr-2"}/>
                                                                                    : <i className="fa fa-file-excel-o mr-2"
                                                                                         aria-hidden="true"/>}
                                                                                    Export Cuts
                                                                                </Button>
                                                                                <Button
                                                                                    color={"primary"} className={"mr-2 mb-2"}
                                                                                    size={"sm"}
                                                                                    disabled={scheduleBucket.printSelectedLabelLoading}
                                                                                    onClick={() => this.downloadAndCutSelectedOrderData(scheduleBucketIndex)}
                                                                                >{(scheduleBucket.printSelectedLabelLoading)
                                                                                    ? <Spinner color="light" size={"sm"}
                                                                                               className={"mr-2"}/>
                                                                                    : <i className="fa fa-print mr-2"
                                                                                         aria-hidden="true"/>}
                                                                                    Label & Cuts
                                                                                </Button>
                                                                                */}
                                                                                <Button
                                                                                    color={"primary"} className={"mr-2 mb-2"}
                                                                                    size={"sm"}
                                                                                    disabled={scheduleBucket.selectedLabelLoading}
                                                                                    onClick={() => this.downloadSelectedLabel(scheduleBucketIndex)}
                                                                                >{(scheduleBucket.selectedLabelLoading)
                                                                                    ? <Spinner color="light" size={"sm"}
                                                                                               className={"mr-2"}/>
                                                                                    : <i className="fa fa-print mr-2"
                                                                                         aria-hidden="true"/>}
                                                                                    Label
                                                                                </Button>
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </Col>
                                                                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                                                        <div className="text-right align-middle">
                                                                            {
                                                                                scheduleBucket.productionLine.shortName === "COMPONENTS"
                                                                                    ? <span className="align-middle">
                                                                                        <span className={"lead mr-2 align-middle"}>
                                                                                            {scheduleBucket.itemCount}
                                                                                        </span>
                                                                                        <span className={"mr-2 align-middle"}>
                                                                                            {scheduleBucket.scheduleOn ? "Lines scheduled" : "Lines not scheduled"}
                                                                                        </span>
                                                                                    </span>

                                                                                    : <span className="align-middle">
                                                                                        <span className={"lead mr-2 align-middle"}>
                                                                                            <NumberFormat
                                                                                                value={Math.floor(scheduleBucket.scheduleQty)}
                                                                                                displayType={'text'}
                                                                                                thousandSeparator={true}/>
                                                                                        </span>
                                                                                        <span className={"mr-2 align-middle"}>
                                                                                            {scheduleBucket.scheduleOn ? "Qty scheduled" : "Qty not scheduled"}
                                                                                        </span>
                                                                                        {
                                                                                            scheduleBucket.scheduleOn
                                                                                                ? <Button
                                                                                                    disabled={!canUpdateProductionCapacity}
                                                                                                    className={"mr-2 mt-2"}
                                                                                                    outline={true}
                                                                                                    size={"sm"}
                                                                                                    color={"primary"}
                                                                                                    onClick={() => this.handleChange("workingProductionCapacity_edit", scheduleBucket, null)}>
                                                                                                    <i className="fa fa-pencil mr-2"/>
                                                                                                    {scheduleBucket.productionCapacity}
                                                                                                    &nbsp;Capacity
                                                                                                </Button>
                                                                                                : null
                                                                                        }
                                                                                    </span>
                                                                            }


                                                                            <CSVLink
                                                                                className={'btn btn-success btn-sm mr-2 mt-2'}
                                                                                headers={productionLinesSchedule[activeProductionLineIndex].csv.headers}
                                                                                data={scheduleBucket.csv.data}
                                                                                filename={scheduleBucket.csv.filename}>
                                                                                <i className="fa fa-file-excel-o mr-2"
                                                                                   aria-hidden="true"/>
                                                                                Export
                                                                            </CSVLink>
                                                                            <Button
                                                                                className={"mt-2"}
                                                                                color={"primary"}
                                                                                size={"sm"}
                                                                                disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                                                                                onClick={() => this.downloadLabels(scheduleBucket)}
                                                                            >{(loadingLabels === scheduleBucket.identityKey)
                                                                                ? <Spinner color="light" size={"sm"}
                                                                                           className={"mr-2"}/>
                                                                                : <i className="fa fa-print mr-2"
                                                                                     aria-hidden="true"/>}
                                                                                Labels
                                                                            </Button>
                                                                            {
                                                                                scheduleBucket.scheduleOn
                                                                        ? <>
                                                                            <Button
                                                                                        title={scheduleBucket.isProductionDateClosed ? "Click here to open the day" : "Click here to close the day"}
                                                                                        color={scheduleBucket.isProductionDateClosed ? "success" : "danger"}
                                                                                        size={"sm"}
                                                                                        className={"ml-2 mt-2"}
                                                                                        disabled={this.state.hasProductionScheduleReadonlyPrivilege}
                                                                                        onClick={() => this.handleChange("isProductionDateClosed", !scheduleBucket.isProductionDateClosed, scheduleBucket)}
                                                                                    >
                                                                                        {(loadingIsProductionDateClosed === scheduleBucket.identityKey)
                                                                                            ? <Spinner color={"light"} size={"sm"}
                                                                                                       className={"mr-2"}/>
                                                                                            :
                                                                                            <i className={classnames("fa", "fa-fw", "mr-2", {
                                                                                                    "fa-unlock": !scheduleBucket.isProductionDateClosed,
                                                                                                    "fa-lock": scheduleBucket.isProductionDateClosed,

                                                                                                }
                                                                                            )} aria-hidden="true"/>}
                                                                                        {scheduleBucket.isProductionDateClosed ? "Closed" : "Open"}
                                                                                    </Button>
                                                                                    <Button className={"ml-2 mt-2"}
                                                                                            color={"primary"}
                                                                                            size={"sm"}
                                                                                            onClick={() => this.openScheduleJobPriorityModal(scheduleBucket)}>
                                                                                        <i className="fa fa-stack-exchange mr-2"
                                                                                           aria-hidden="true"/>
                                                                                        Change Job's Priority
                                                                                    </Button>
                                                                            </>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                        
                                                            </CardHeader>
                                                            <Collapse isOpen={scheduleBucket.isOpen}>
                                                                <div>
                                                                    <Card style={{overflow: "auto", overflowY: "hidden"}}
                                                                          className="table-responsive ">
                                                                        <Table hover bordered size="sm">
                                                                            <thead>
                                                                            <tr>
                                                                                <th style={{ minWidth: '20px' }} className={"align-middle"}>
                                                                                    S.No
                                                                                </th>
                                                                                {scheduleBucket.label !== "DeScheduled" && (productionLinesSchedule[activeProductionLineIndex].productionLine.shortName === 'CUT OUT' || productionLinesSchedule[activeProductionLineIndex].productionLine.shortName === 'CUT IN') ?
                                                                                        <th style={{ minWidth: '50px' }} className={"align-middle"}>
                                                                                        <div className={"text-center"}><a
                                                                                            href="javascript:void(0)"
                                                                                            onClick={() => this.selectAllRecords(scheduleBucketIndex, !scheduleBucket.allRecordsSelected, "multipleSelected", null)}>
                                                                                            {scheduleBucket.allRecordsSelected ?
                                                                                                <i className="fa fa-lg fa-check-square-o"
                                                                                                   aria-hidden="true"/> :
                                                                                                <i className="fa fa-lg fa-square-o"
                                                                                                   aria-hidden="true"/>
                                                                                            }
                                                                                        </a></div>
                                                                                    </th> : null}
                                                                                    {(scheduleBucket.store || []).filter(storeItem => {
                                                                                        if (isHiddenExtendedColumn && isHiddenOOSDetailsColumn) {
                                                                                            return !storeItem.extendedColumn && !storeItem.oosDetailsColumn;
                                                                                        } else if (isHiddenExtendedColumn) {
                                                                                            return !storeItem.extendedColumn && !["custOrdDate", "labelPrintedDate", "whStatus"].includes(storeItem.key);
                                                                                        } else if (isHiddenOOSDetailsColumn) {
                                                                                            return !storeItem.oosDetailsColumn;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    }).map((storeItem, storeItemIndex) => {
                                                                                    return (
                                                                                        <th key={storeItemIndex}
                                                                                            className={["OOS Detail", "Is Completed"].includes(storeItem.label) ? "align-middle text-center" : "align-middle text-left"}
                                                                                            style={{minWidth: storeItem.minWidth}}>
                                                                                            {storeItem.label}
                                                                                        </th>
                                                                                    );
                                                                                })}
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {(scheduleBucket.orders || []).map((order, orderIndex) => {
                                                                                return (
                                                                                    <tr key={orderIndex}
                                                                                        id={highlightedOrder.guid === order.guid ? "Order" + highlightedOrder.guid : null}>
                                                                                        <td key={orderIndex} className={"align-middle"}
                                                                                            style={{backgroundColor: order.isUserSchedule === 4 ? "#f3d060" : ""}}
                                                                                        >
                                                                                            {(orderIndex + 1)}
                                                                                        </td>
                                                                                        {scheduleBucket.label !== "DeScheduled" && (productionLinesSchedule[activeProductionLineIndex].productionLine.shortName === 'CUT OUT' || productionLinesSchedule[activeProductionLineIndex].productionLine.shortName === 'CUT IN') ?
                                                                                            <td style={{
                                                                                                minWidth: '50px',
                                                                                                backgroundColor: order.backgroundColor
                                                                                            }} className={"align-middle"}>
                                                                                                <div className={"text-center"}>
                                                                                                    <a href={"javascript:void(0)"}
                                                                                                       onClick={() => this.selectAllRecords(scheduleBucketIndex, !order.selected, "singleSelected", order.productionScheduleID, 'fabric cut data save at shared location')}>
                                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                                "fa-check-square-o": order.selected,
                                                                                                                "fa-square-o": !order.selected,
                                                                                                            }
                                                                                                        )}/>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </td> : null}
                                                                                        {(scheduleBucket.store || []).filter(storeItem => {
                                                                                            if (isHiddenExtendedColumn && isHiddenOOSDetailsColumn) {
                                                                                                return !storeItem.extendedColumn && !storeItem.oosDetailsColumn;
                                                                                            } else if (isHiddenExtendedColumn) {
                                                                                                return !storeItem.extendedColumn && !["custOrdDate", "labelPrintedDate", "whStatus"].includes(storeItem.key);
                                                                                            } else if (isHiddenOOSDetailsColumn) {
                                                                                                return !storeItem.oosDetailsColumn;
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).map((storeItem, storeItemIndex) => {
                                                                                            return (
                                                                                                <td key={storeItemIndex}
                                                                                                    className={storeItem.label === "OOS Detail" ? "m-0 p-0" : "align-middle"}>
                                                                                                    {this.getColValue(scheduleBucketIndex, storeItem, order)}
                                                                                                </td>
                                                                                            );
                                                                                        })}
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                            </tbody>
                                                                        </Table>
                                                                    </Card>
                                                                </div>
                                                            </Collapse>
                                                        </Card>
                                                    })}
                                                </div>
                                        }
                                    </CardBody>
                                </Card>
                        }
                    </div>
            }
            {
                isOpenUpdateCustomProductionCapacityModal
                    ? <UpdateCustomProductionCapacityModal
                        workingProductionCapacity={workingProductionCapacity}
                        isOpen={isOpenUpdateCustomProductionCapacityModal}
                        toggle={this.toggleUpdateCustomProductionCapacityModal}
                        handleChange={this.handleChange}
                        handleSubmit={this.updateCustomProductionCapacitySubmit}
                        loading={savingCustomProductionCapacity}
                    />
                    : null
            }
            {
                isOpenDescheduleProductionScheduleModal
                    ? <DescheduleProductionScheduleModal
                        descheduleReasons={descheduleReasons}
                        workingDescheduleProductionSchedule={workingDescheduleProductionSchedule}
                        isOpen={isOpenDescheduleProductionScheduleModal}
                        toggle={this.toggleDescheduleProductionScheduleModal}
                        handleChange={this.handleChange}
                        handleSubmit={() => this.handleSubmit("descheduleProductionSchedule")}
                        loading={loadingDescheduleProductionScheduleSubmit}
                    />
                    : null
            }
            {
                isOpenProductionScheduleRebuildConfirmModal
                    ? <ProductionScheduleRebuildConfirmModal
                        isOpen={isOpenProductionScheduleRebuildConfirmModal}
                        toggle={this.toggleProductionScheduleRebuildConfirmModal}
                        handleChange={this.handleChange}
                        handleSubmit={this.rebuildProductionSchedule}
                        loading={loadingProductionScheduleRebuild}
                        isRebuildToday={isRebuildToday}
                    />
                    : null
            }
            {
                isOpenSameOrderItemsModal
                    ? <ProductionScheduleSameOrderItemsModal
                        isOpen={isOpenSameOrderItemsModal}
                        toggle={this.toggleSameOrderItemsModal}
                        handleChange={this.handleChange}
                        handleSubmit={(selectedOrder) => this.handleChange("sameOrderItemsModal_highlightOrder", selectedOrder, null)}
                        triggeredBy={triggeredBySameOrderItemsModal}
                        searchText={searchText}
                        productionLinesSchedule={productionLinesSchedule}
                    />
                    : null
            }
            {
                isOpenConfirmCompleteStatusModal
                    ? <ConfirmModal
                        isOpen={isOpenConfirmCompleteStatusModal}
                        toggle={this.toggleConfirmCompleteStatusModal}
                        handleCancel={() => this.toggleConfirmCompleteStatusModal(false, null)}
                        handleSubmit={this.updateProductionSalesOrderJobComplete}
                        primaryMessage={primaryMessage}
                        secondaryMessage={secondaryMessage}
                        submitColor={"success"}
                        cancelColor={"secondary"}
                        icon={"fa fa-check-circle fa-2x"}
                        loading={updatingActiveProductionLineSchedule}
                        type="Updating..."
                    />
                    : null
            }

            {
                powdercoatingForm.isOpen
                    ? <PowdercoatingFormModal
                        isOpen={powdercoatingForm.isOpen}
                        toggle={this.togglePowdercoatingFormModal}
                        updateProductionSalesOrderJob={(purchaseOrder) => this.handleChange("powdercoating_form_updatePurchaseOrderNum", purchaseOrder, null)}
                        salesOrderJob={powdercoatingForm.salesOrderJob}
                    />
                    : null
            }

            {
                workingScheduleJob.isOpen
                    ? <ProductionScheduleScheduleJobsModal
                        isOpen={workingScheduleJob.isOpen}
                        toggle={this.toggleWorkingScheduleJobModal}
                        handleDescheduleSubmit={this.scheduleProductionDeschedule}
                        handleRescheduleSubmit={this.scheduleProductionSchedule}
                        isRescheduleFromDeschedule={isRescheduleFromDeschedule}
                        form={workingScheduleJob}
                        getScheduleOnDatePickerCSSClassName={this.getScheduleOnDatePickerCSSClassName}
                        isLoadingSave={workingScheduleJob.isLoadingSave}
                    />
                    : null
            }

            {isOpenOrderStatusChangeModal ?
                <ProductionOrderStatusChangeModal
                    isOpen={isOpenOrderStatusChangeModal}
                    toggle={this.toggleOrderStatusChangeModal}
                    order={selectedOrderForStatusChange}
                    salesOrderJobStatuses={salesOrderJobStatuses}
                    handlerCloseModal={this.handleCloseOrderStatusChangeModal}
                />

                : null}

            {isOpenProductionDescheduleNoteUpdateModal ?
                <ProductionDescheduleNotesUpdateModal
                    isOpen={isOpenProductionDescheduleNoteUpdateModal}
                    toggle={this.toggleProductionDescheduleNoteUpdateModal}
                    salesOrder={selectedSalesOrderForProductionDescheduleNoteUpdate}
                    handleProductionDescheduleNotes={this.handleProductionDescheduleNotesData}
                />

                : null}
            {isOpenScheduleJobPriorityModal ?
                <ManageProductionScheduleJobPriorityModal
                    isOpen={isOpenScheduleJobPriorityModal}
                    toggle={this.toggleScheduleJobPriorityModal}
                    jobs={this.state.selectedScheduleBucket.orders}
                    handleAfterSubmit={this.refresh}
                />
                : null}
            {isOpenProductionScheduleOrderProdStatusChangeModal ?
                <ProductionScheduleOrderProdStatusChangeModal
                    isOpen={isOpenProductionScheduleOrderProdStatusChangeModal}
                    toggle={this.toggleProductionScheduleOrderProdStatusChangeModal}
                    salesOrder={this.state.selectedSalesOrderForProductionScheduleOrderProdStatusChange}
                    handleProductScheduleProdNotes={this.handleProductScheduleProdNotes}/>
                : null}
            {
                isOpenReworkOrderSummaryModal ?
                    <ReworkOrderSummary
                        isOpen={isOpenReworkOrderSummaryModal}
                        toggle={this.toggleReworkOrderSummaryModal}
                        reworkOrder={reworkOrder}
                        getOrder={this.getOrder}
                        order={order}
                        currentUser={currentUser}
                    />
                    : null
            }
            {
                isOpenJobInfoModal ?
                    <OrderInformationModal ordNum={order.ordNum}
                        isOpen={isOpenJobInfoModal}
                        toggle={() => this.setState({ isOpenJobInfoModal: !isOpenJobInfoModal })}
                        handlerCloseModal={() => this.setState({ isOpenJobInfoModal: false })}
                        clickType={"productLink"}
                        type={""}
                        order={order}
                        salesOrderJobStatuses={""}
                        userRole={currentUser.menu.role}
                    />
                    : null
            }
            {
                isOpenOOSDetailsModal ?
                    <OOSDetailsModal
                        isOpen={isOpenOOSDetailsModal}
                        toggle={this.toggleOOSDetailsModal}
                        oosDetails={oosDetails}
                        ordNum={order.ordNum}
                    />
                    : null
            }
            {
                isOpenDescheduledOrderModal
                    ? <ConfirmDescheduledOrderModal
                        isOpen={isOpenDescheduledOrderModal}
                        toggle={() => this.setState({ isOpenDescheduledOrderModal: !isOpenDescheduledOrderModal })}
                        handleSubmit={this.handleDescheduledOrderSubmit}
                        primaryMessage={descheduledMessage}
                        submitColor={"success"}
                        icon={"fa fa-exclamation-triangle fa-2x"}
                        loading={null}
                    />
                    : null
            }

            <ToastContainer/>
        </div>
    }
}
