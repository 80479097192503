import axios from './axios';
import { authHeader } from '../helpers/auth-header';
import { config } from '../helpers/config';

class StockUnitTypeService {

    static Instance() {
        return new StockUnitTypeService();
    }

    listStockUnitType(req) {
        return axios.post('api/stock-unit-type/list', req);
    }

    getOneStockUnitType(stockUnitTypeID) {
        return axios.get('api/stock-unit-type/one/?StockUnitTypeID=' + stockUnitTypeID);
    }

    saveOrUpdateStockUnitType(req) {
        return axios.post('api/stock-unit-type/save', req);
    }

    getStockUnitTypeSummary(searchText) {

        let requestBody= {
            description: searchText
        }

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
        };
        return axios(config.apiUrl + 'api/stock-unit-type/summary/list', requestOptions);
    }

    getDistinctMeasureUnitType() {
        return axios.get('api/stock-unit-type/measureUnitType');
    }
}

export default StockUnitTypeService.Instance();