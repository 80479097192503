import React, { Component } from 'react';
import OrderService from './../../../services/OrderService';
import { getDateString } from './../../../services/CommonService';
import { toast, ToastContainer } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Spinner } from "reactstrap";
import FileSaver from 'file-saver';
import { addDate, getStartOrEndOfDate, handleErrorMessage } from "../../../services/CommonService";


export default class DailySaleSummaryReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDownloadingReportRealTimeReport: false,
            isDownloadingReportPreviousWorkingDayReport: false
        };
        this.orderService = new OrderService();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(reportType) {

        let { isDownloadingReportRealTimeReport, isDownloadingReportPreviousWorkingDayReport } = this.state;
        let fromDate;
        let toDate;
        let fromDateString;
        let toDateString;

        let now = new Date();

        fromDate = addDate(now, -3, "months", "dateObj");
        fromDate = getStartOrEndOfDate(fromDate, 'day', "start", "dateObj");

        switch (reportType) {
            case "Real time report":
                isDownloadingReportRealTimeReport = true;
                toDate = now;
                break;
            case "Previous working day report":
                isDownloadingReportPreviousWorkingDayReport = true;
                toDate = addDate(now, -1, "days", "dateObj");
                toDate.setHours(19);
                toDate.setMinutes(0);
                break;
        }
        if (fromDate) {
            fromDateString = getDateString(fromDate, "YYYY-MM-DD HH:mm:ss");
        }
        if (toDate) {
            toDateString = getDateString(toDate, "YYYY-MM-DD HH:mm:ss");
        }

        this.setState({ isDownloadingReportRealTimeReport, isDownloadingReportPreviousWorkingDayReport });
        this.orderService.downloadDailySummaryReport(fromDateString, toDateString).then(response => {
            let fileName = "Daily Sales Order Summary Report " + getDateString(new Date(), "DD MMM YYYY") + ".xlsx";
            // download response as file
            FileSaver.saveAs(response.data, fileName);

            switch (reportType) {
                case "Real time report":
                    isDownloadingReportRealTimeReport = false;
                    break;
                case "Previous working day report":
                    isDownloadingReportPreviousWorkingDayReport = false;
                    break;
            }

            this.setState({ isDownloadingReportRealTimeReport, isDownloadingReportPreviousWorkingDayReport });
        }).catch(error => {
            this.setState({ isDownloadingReportRealTimeReport, isDownloadingReportPreviousWorkingDayReport });
            toast.error(handleErrorMessage(error));
            console.log(error);
        })
    }

    render() {
        let { isDownloadingReportRealTimeReport, isDownloadingReportPreviousWorkingDayReport } = this.state;
        return (
            <Col xl={3} lg={3} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className="mb-0">Daily Sales Order</h6>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <a href={"javascript:void(0)"} onClick={() => this.handleClick("Real time report")}>
                                {
                                    isDownloadingReportRealTimeReport
                                        ? <Spinner className={"mr-2"} size={"sm"} />
                                        : <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />
                                }
                                Real time report
                            </a>
                        </div>
                        <div>

                            <a href={"javascript:void(0)"}
                                onClick={() => this.handleClick("Previous working day report")}>
                                {
                                    isDownloadingReportPreviousWorkingDayReport
                                        ? <Spinner className={"mr-2"} size={"sm"} />
                                        : <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />
                                }
                                Previous working day report
                            </a>
                        </div>
                    </CardBody>
                </Card>
                <ToastContainer />
            </Col>
        );
    }
}