import {authHeader} from '../../helpers/auth-header';
import {config} from '../../helpers/config'
import axios from 'axios';

export default class SalesOrderReviewService {


    fetchSalesOrdersOnCreditHold(accountID) {

        let reqParams = "?";
        if (accountID) {
            reqParams = reqParams + 'accountID=' + accountID
        }
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/sales/orders/review/hold/credit' + reqParams
        };
        return axios(request)
    }

    fetchSalesOrdersOnPickHold(accountID) {

        let reqParams = "?";
        if (accountID) {
            reqParams = reqParams + 'accountID=' + accountID
        }
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/sales/orders/review/hold/pick' + reqParams
        };
        return axios(request)
    }


}