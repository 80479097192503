import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardHeader, Col, Input, Label, Row, Spinner, Table} from 'reactstrap';

import {scrollTo, handleErrorMessage} from '../../../../../../services/CommonService';
import {cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import {v4 as uuidv4} from 'uuid';
import customUtil from './CustomUtil';
import NumberFormat from "react-number-format";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import SearchStock from "../../../../../search/SearchStockKeyway";
import keywayStockService from '../../../../../../services/KeywayStockService';
import ModalImage from "react-modal-image";
import {
    DEFAULT_IMAGE_THUMBNAIL_500X250,
    DEFAULT_IMAGE_THUMBNAIL_50X50
} from "../../../../../../store/ImagePathConstants";
import {PRODUCT_BUILDER_CUSTOM_PART} from "../../../../../../store/AppConstants";
import SearchStockGroupMulti from "../../../../../search/SearchStockGroupMulti";
import SearchStockMasterGroupMulti from "../../../../../search/SearchStockMatserGroupMulti";
import SearchStockColourKeyway from "../../../../../search/SearchStockColourKeyway";

export default class CustomPart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            selectedStock: null,
            selectedService: null,
            selectedStockGroups: null,
            selectedStockMasterGroups: null,
            selectedColour: null,
            excludeOffRange: true,
            excludeDiscontinued: true,
            excludeSoldOut: true,
            excludeArchived: true,
            excludeOutOfStock: true,
            isOpenConsolidatedBOMModal: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        } else {
            this.init();
        }
    }

    init() {
        let {product} = this.props;
        if (!product.init_part) {
            product.init_part = true;
            this.props.handleChange("product_init", product);
        }
    }


    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, context) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, stock, change, parentStock;

        switch (key) {
            case "newStock":
            case "newService":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                change = context;
                //prepare context
                context = {
                    salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_PART)
                };
                context.salesOrderItemCustom.prodCode = change.prodCode;
                context.salesOrderItemCustom.colour = change.keywayStock.colour;
                context.salesOrderItemCustom.productCode = ( change && change.product) ? change.product.code : "";
                context.salesOrderItemCustom.productGroupCode = ( change && change.productGroup) ? change.productGroup.code : "";
                context.salesOrderItemCustom.thumbnailImageAttachmentID = change.keywayStock.thumbnailImageAttachmentID;
                context.salesOrderItemCustom.highQualityImageAttachmentID = change.keywayStock.highQualityImageAttachmentID;
                context.salesOrderItemCustom.description = change.keywayStock.description || change.description;
                context.salesOrderItemCustom.length = change.keywayStock.length;
                context.salesOrderItemCustom.dimensionUnitName = change.keywayStock.dimensionUnitName;
                context.salesOrderItemCustom.eachUnitName = change.keywayStock && (["", "0", 0, null, undefined].includes(change.keywayStock.eachUnitName) ? "N/A" : change.keywayStock.eachUnitName);
                context.salesOrderItemCustom.sellUnitName = change.keywayStock && (["", "0", 0, null, undefined].includes(change.keywayStock.whUnitName) ? "N/A" : change.keywayStock.whUnitName);
                context.salesOrderItemCustom.sellUnitQty = change.keywayStock && (["", "0", 0, 1, null, undefined].includes(change.keywayStock.whUnitQty) ? 0 : change.keywayStock.whUnitQty);
                context.salesOrderItemCustom.price1 = change.keywayStock.eachUnitPrice;
                context.salesOrderItemCustom.sellQtyPrice = change.keywayStock.eachUnitPrice;
                context.salesOrderItemCustom.stocks = [];

                //add as stock
                stock = salesOrderProductBuilderV1Service.getStockFactoryInstance();
                stock.prodCode = change.prodCode;
                stock.unitPrice = change.keywayStock.eachUnitPrice;
                stock.price = stock.unitPrice;
                stock.isParentItem = true;
                stock.discontinued = change.discontinued;
                stock.soldOut = change.soldOut;
                stock.qoh = change.qoh;
                stock.isNonStock = change.keywayStock.isNonStock;
                context.salesOrderItemCustom.stocks.push(stock);

                product = this.updateConfiguration(product, itemIndex, context, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                if (key === "newStock") {
                    this.setState({ selectedStock: null });
                } else {
                    this.setState({ selectedService: null });
                }
                if (itemIndex === 0 && change.prodCode !== "2F") {
                    keywayStockService.getStock("2F").then(response => {
                        let res = response.data.wwStock;
                        res.value = response.data.value;
                        res.label = response.data.label;
                        delete response.data.value;
                        delete response.data.label;
                        delete response.data.wwStock;
                        res.keywayStock = response.data;
                        res.keywayStock.wwStock = null;
                        this.handleItemAction("newService", 1, res);
                    }).catch(error => {
                        console.error(error);
                    });
                }
                break;
            case "clone":
                item = cloneDeep(product.items[itemIndex]);
                item.customID = uuidv4();
                product.items.push(item);
                product.items.forEach((item, itemIndex) => {
                    product = customUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = customUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    if (si.salesOrderItemCustom.itemType === "Part") {
                        item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                        product.items.push(item); //insert item
                        itemIndex = product.items.length - 1; // get item index

                        si.salesOrderItemCustom.stocks = [];
                        (si.salesOrderItemCustom.salesOrderItemParts || []).forEach(s => {
                            parentStock = si.salesOrderItemCustom.salesOrderItemParts.find(x => x.debtorInvoiceItemNum === s.debtorInvoiceParentItemNum);
                            stock = salesOrderProductBuilderV1Service.getStockFactoryInstance();
                            stock.id = s.id;
                            stock.prodCode = s.prodCode;
                            stock.price = s.debtorInvoiceParentItemNum === s.debtorInvoiceItemNum ? s.price : (s.price / parentStock.swishQty);
                            stock.swishCalculatedQty = s.swishQty;
                            stock.keywayCalculatedQty = s.keywayQty;
                            stock.keywayMeasurementUnit = s.keywayMeasurementUnit;
                            stock.swishMeasurementUnit = s.swishMeasurementUnit;
                            stock.keywayConversionFactor = s.keywayConversionFactor;
                            stock.swishConversionFactor = s.swishConversionFactor;
                            stock.isVisibleInPartList = s.isVisibleInPartList;
                            stock.isDeductionApplicable = s.isDeductionApplicable;
                            stock.description = s.description;
                            stock.attribute = s.attribute;
                            stock.label = s.label;
                            stock.attributeRowSpan = 1;
                            stock.unitPrice = stock.price;
                            stock.qoh = s.qoh;
                            stock.discontinued = s.discontinued;
                            stock.soldOut = s.soldOut;
                            si.salesOrderItemCustom.stocks.push(stock);
                        });
                        product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                        product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                        product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                    }
                });
                this.init();
        }
        product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {
        let {packagingAndHandling, discountByProductCode} = this.props;
        customUtil.initItem(product, itemIndex, context, order, packagingAndHandling, discountByProductCode);
        return product;
    }


    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;

        switch (key) {
            case "quantity":
                if (change > -1) {
                    product.items[itemIndex].configuration.quantity.selected.value = parseFloat(change.toFixed(4));
                }
                product = customUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "sellQtyPrice":
                product.items[itemIndex].configuration.sellQtyPrice.selected.value = change;

                product.items[itemIndex].stocks.forEach((s, stockIndex) => {
                    if (s.prodCode === product.items[itemIndex].configuration.prodCode.selected.value) {
                        product.items[itemIndex].stocks[stockIndex].unitPrice = change;
                        product.items[itemIndex].stocks[stockIndex].price = change;
                    }
                });
                break;
            case "selectedStock":
                this.setState({selectedStock: change});
                break;
            case "selectedService":
                this.setState({ selectedService: change });
                break;
            case "selectedStockGroups":
                this.setState({selectedStockGroups: change});
                break;
            case "selectedStockMasterGroups":
                this.setState({selectedStockMasterGroups: change});
                break;
            case "selectedColour":
                this.setState({selectedColour: change});
                break;
            case "excludeOffRange":
                this.setState({ excludeOffRange: change });
                break;
            case "excludeDiscontinued":
                this.setState({ excludeDiscontinued: change });
                break;
            case "excludeSoldOut":
                this.setState({ excludeSoldOut: change });
                break;
            case "excludeArchived":
                this.setState({ excludeArchived: change });
                break;
            case "excludeOutOfStock":
                this.setState({ excludeOutOfStock: change });
                break;
            default:
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let {product, itemSummary, currentUser, order, discountByProductCode} = this.props;
        let { selectedStock, selectedService, selectedStockMasterGroups, selectedStockGroups, selectedColour, excludeOffRange, excludeDiscontinued, excludeSoldOut, excludeArchived, excludeOutOfStock } = this.state;
        let items = itemSummary.items;
        if (!product.init_part) {
            return <Spinner color={"primary"}/>;
        }

        return (<div>
            <Card className={"mt-2"}>
                <CardHeader>
                    <i className="fa fa-filter mr-2" aria-hidden="true" />Filter By
                </CardHeader>
                <CardBody>
                <Row className={"mb-2 align-items-center"}>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        Select Stock Master Group
                        <SearchStockMasterGroupMulti
                            handleSelection={(selectedStockMasterGroups) => this.handleChange(selectedStockMasterGroups, "selectedStockMasterGroups", "", true)}
                            selectedStockMasterGroupCodes={selectedStockMasterGroups}
                            defaultStockMasterGroupCodes={""}
                            filter={{isKeywayAvailableOnly: true, stockMasterGroupCodes: []}}
                        />
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        Select Stock Group
                        <SearchStockGroupMulti
                            handleSelection={(selectedStockGroups) => this.handleChange(selectedStockGroups, "selectedStockGroups", "", true)}
                            selectedStockGroupCodes={selectedStockGroups}
                            defaultStockGroupCodes={""}
                            filter={{
                                isKeywayAvailableOnly: true,
                                stockMasterGroupCodes: selectedStockMasterGroups,
                                stockGroupCodes: [],
                                excludeArchived: false
                            }}
                        />
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        Select Colour
                        <SearchStockColourKeyway
                            handleSelection={(selectedColour) => this.handleChange(selectedColour, "selectedColour", "", true)}
                            selectedColour={selectedColour}
                            defaultColour={""}
                            filter={{
                                bmFlag: 1,
                                discontinued: null,
                                soldOut: null,
                                stockGroupCodes: selectedStockGroups,
                                stockMasterGroupCodes: selectedStockMasterGroups
                            }}
                        />
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12} className="align-self-end">
                        <div>
                            <Label className="mt-2">
                                <Button color={"link"}
                                        size={"sm"}
                                        onClick={() => this.handleChange(!excludeOffRange, "excludeOffRange", "", true)}>
                                    <i className={classnames("fa", "fa-lg", "fa-fw", {
                                            "fa-square-o": !excludeOffRange,
                                            "fa-check-square-o": excludeOffRange,
                                        }
                                    )} aria-hidden="true"/>
                                </Button>
                                Exclude Off Range
                            </Label>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Label className="mt-2">
                                <Button color={"link"}
                                    size={"sm"}
                                    onClick={() => this.handleChange(!excludeDiscontinued, "excludeDiscontinued", "", true)}>
                                    <i className={classnames("fa", "fa-lg", "fa-fw", {
                                        "fa-square-o": !excludeDiscontinued,
                                        "fa-check-square-o": excludeDiscontinued,
                                    }
                                    )} aria-hidden="true" />
                                </Button>
                                Exclude Discontinued
                            </Label>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Label className="mt-2">
                                <Button color={"link"}
                                    size={"sm"}
                                    onClick={() => this.handleChange(!excludeSoldOut, "excludeSoldOut", "", true)}>
                                    <i className={classnames("fa", "fa-lg", "fa-fw", {
                                        "fa-square-o": !excludeSoldOut,
                                        "fa-check-square-o": excludeSoldOut,
                                    }
                                    )} aria-hidden="true" />
                                </Button>
                                Exclude Sold Out
                            </Label>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Label className="mt-2">
                                <Button color={"link"}
                                    size={"sm"}
                                    onClick={() => this.handleChange(!excludeArchived, "excludeArchived", "", true)}>
                                    <i className={classnames("fa", "fa-lg", "fa-fw", {
                                        "fa-square-o": !excludeArchived,
                                        "fa-check-square-o": excludeArchived,
                                    }
                                    )} aria-hidden="true" />
                                </Button>
                                Exclude Archived Master
                            </Label>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Label className="mt-2">
                                <Button color={"link"}
                                    size={"sm"}
                                        onClick={() => this.handleChange(!excludeOutOfStock, "excludeOutOfStock", "", true)}>
                                    <i className={classnames("fa", "fa-lg", "fa-fw", {
                                        "fa-square-o": !excludeOutOfStock,
                                        "fa-check-square-o": excludeOutOfStock,
                                    }
                                    )} aria-hidden="true" />
                                </Button>
                                Exclude Out of Stock
                            </Label>
                        </div>
                    </Col>
                </Row>
                    <Row>
                        <Col>
                            <div>
                                Find Stock Items
                                <SearchStock
                                    handleSelection={(selectedStock) => this.handleChange(selectedStock, "selectedStock", "", true)}
                                    selected={selectedStock ? selectedStock.prodCode : ""}
                                    defaultProdCode={""}
                                    filter={{
                                        bmFlag: 0,
                                        excludeDiscontinued: excludeDiscontinued,
                                        excludeSoldOut: excludeSoldOut,
                                        stockGroupCodes: selectedStockGroups,
                                        stockMasterGroupCodes: selectedStockMasterGroups,
                                        colour: selectedColour,
                                        excludeOffRange: excludeOffRange,
                                        includeFabric: false,
                                        excludeArchived: excludeArchived,
                                        excludeOutOfStock: excludeOutOfStock,
                                        isNonStock: 0,
                                    }}
                                />
                            </div>
                        </Col>
                        {
                            selectedStock
                                ? <Col className={"col-auto mx-auto align-self-end"}>
                                    <div>
                                        <Button
                                            color={"primary"}
                                            onClick={() => this.handleItemAction("newStock", items.length, selectedStock)}>
                                            <i className="fa fa-cart-plus mr-2" aria-hidden="true" />
                                            Add selected item
                                        </Button>
                                    </div>
                                </Col>
                                : null
                        }
                        <Col>
                            <div>
                                Find Services or Other Items
                                <SearchStock
                                    handleSelection={(selectedService) => this.handleChange(selectedService, "selectedService", "", true)}
                                    selected={selectedService ? selectedService.prodCode : ""}
                                    defaultProdCode={""}
                                    filter={{
                                        bmFlag: null,
                                        excludeDiscontinued: excludeDiscontinued,
                                        excludeSoldOut: excludeSoldOut,
                                        stockGroupCodes: null,
                                        stockMasterGroupCodes: null,
                                        colour: null,
                                        excludeOffRange: null,
                                        includeFabric: null,
                                        excludeArchived: null,
                                        excludeOutOfStock: null,
                                        isNonStock: 1,
                                    }}
                                />
                            </div>
                        </Col>
                        {
                            selectedService
                                ? <Col className={"col-auto mx-auto align-self-end"}>
                                    <div>
                                        <Button
                                            color={"primary"}
                                            onClick={() => this.handleItemAction("newService", items.length, selectedService)}>
                                            <i className="fa fa-cart-plus mr-2" aria-hidden="true" />
                                            Add selected item
                                        </Button>
                                    </div>
                                </Col>
                                : null
                        }
                    </Row>
                </CardBody>
            </Card>


                <Card className={"mt-2"}>
                    <CardHeader>
                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/> Added Items
                    </CardHeader>

                    {(items && items.length > 0) ?
                        <div className="table-responsive">
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{minWidth: 125}}>Product Code</th>
                                    <th className="text-center">Photo</th>
                                    <th className="text-center">Description</th>
                                    <th className="text-center">Length</th>
                                    <th className="text-center" style={{minWidth: 120}}>Each Qty</th>
                                    <th className="text-center" style={{minWidth: 100}}>WH Pack Qty</th>
                                    <th className="text-center" style={{minWidth: 120}}>Each Price</th>
                                    <th className="text-center">Total Price</th>
                                    <th className="text-center">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(items || []).map((item, itemIndex) => {
                                    return <tr key={itemIndex}
                                               className={classnames({"table-danger": !item.isValid})}>
                                        <td className="align-middle text-center">
                                            <h5><Badge
                                                color={"primary"}>{item.configuration.prodCode.selected.value}</Badge>
                                            </h5>
                                        </td>
                                        <td className="align-middle text-center">
                                            <ModalImage
                                                className="img-thumbnail"
                                                small={item.configuration.thumbnailImageAttachmentID.selected.value ? "api/file/Generate?AttachmentID=" + item.configuration.thumbnailImageAttachmentID.selected.value : DEFAULT_IMAGE_THUMBNAIL_50X50}
                                                large={item.configuration.highQualityImageAttachmentID.selected.value ? "api/file/Generate?AttachmentID=" + item.configuration.highQualityImageAttachmentID.selected.value : DEFAULT_IMAGE_THUMBNAIL_500X250}
                                                alt="..."
                                                hideDownload={true}
                                                hideZoom={true}
                                                imageBackgroundColor={"#FFFFFF"}
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <div>{item.configuration.description.selected.value}</div>
                                            {item.configuration.colour.selected.value && <div>Colour: {item.configuration.colour.selected.value}</div>}
                                            <div>
                                                {
                                                    (item.stocks[0].discontinued)
                                                        ? <Badge color={"warning"}
                                                                 className={"mr-2"}>Discontinued</Badge>
                                                        : null
                                                }
                                                {
                                                    (item.stocks[0].soldOut)
                                                        ? <Badge color={"warning"}
                                                                 className={"mr-2"}>Sold Out</Badge>
                                                        : null
                                                }
                                            </div>
                                        </td>
                                        <td className='align-middle text-center'>
                                            {
                                                (item.configuration.eachUnitName.selected.value === "length" || (item.configuration.length.selected.value))
                                                    ?
                                                    <span>{item.configuration.length.selected.value}{item.configuration.dimensionUnitName.selected.value}</span>
                                                    : null
                                            }
                                        </td>
                                        <td className="align-middle text-center">
                                            <Input type='number'
                                                   value={item.configuration.quantity.selected.value}
                                                   name='quantity'
                                                   onChange={(e) => this.handleChange(parseFloat(e.target.value), e.target.name, customUtil.findItemIndex(product, item.customID), true)}
                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                   min={item.configuration.quantity.min}
                                                   onFocus={(event) => event.target.select()}
                                            />
                                            {
                                                item.configuration.eachUnitName.selected.value !== "N/A" && item.configuration.eachUnitName.selected.value + "(s)"
                                            }
                                            {
                                                (item.configuration.quantity.formError.isValid)
                                                    ? null
                                                    : <Badge color="danger">
                                                        {item.configuration.quantity.formError.message}
                                                    </Badge>
                                            }
                                            {
                                                (!item.stocks[0].isNonStock && item.stocks[0].qoh && item.stocks[0].qoh <= item.configuration.quantity.selected.value)
                                                ? <Badge color={"warning"}
                                                    className={"ml-1"}>QOH&nbsp;{item.stocks[0].qoh}</Badge>
                                                : null
                                            }
                                        </td>
                                        <td className="align-middle text-center">
                                            {(item.configuration.sellUnitQty.selected.value && item.configuration.sellUnitName.selected.value !== "N/A") ?
                                                parseFloat((item.configuration.quantity.selected.value / item.configuration.sellUnitQty.selected.value).toFixed(8)) + " " + item.configuration.sellUnitName.selected.value + "(s) of " + item.configuration.sellUnitQty.selected.value + " " + item.configuration.eachUnitName.selected.value + "(s)"
                                                : "N/A"}
                                        </td>
                                        <td className="align-middle text-center">
                                            <Input type='number'
                                                value={item.configuration.sellQtyPrice.selected.value}
                                                name='sellQtyPrice'
                                                onChange={(e) => this.handleChange(parseFloat(e.target.value), e.target.name, customUtil.findItemIndex(product, item.customID), true)}
                                                onFocus={(event) => event.target.select()}
                                            />
                                            <div className="text-right">
                                                <small>
                                                    <NumberFormat
                                                        prefix={'OSP. @ $'}
                                                        value={item.configuration.price1.value}
                                                        displayType={'text'}
                                                        decimalScale={4} />
                                                </small>
                                            </div>
                                        </td>
                                        <td className="align-middle text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-center">
                                            <Button color={"link"}
                                                    title={"click here to delete"}
                                                    onClick={() => this.handleItemAction("delete", customUtil.findItemIndex(product, item.customID), null)}>
                                                <i className="fa fa-trash-o fa-lg text-danger"
                                                   aria-hidden="true"/>
                                            </Button>
                                        </td>
                                    </tr>

                                })}
                                {
                                    itemSummary.price
                                        ? <tr>
                                            <td className="align-middle" colSpan={8}>
                                                Total
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.price, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                        </tr>
                                        : null
                                }

                                </tbody>
                            </Table>
                        </div>
                        : <CardBody>
                            <p className={"text-info"}>
                                <i className="fa fa-meh-o mr-2" aria-hidden="true"/> No items added yet.
                            </p>
                        </CardBody>
                    }
                </Card>
            </div>
        )
    }
};
