import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';
import {
    findIndex,
    findItems,
    getSorted,
    isHaymanAndNoosaCustomer,
    scrollTo
} from '../../../../../services/CommonService';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';

import rollerBlindSingleUtil from './util/RollerBlindSingleUtil';
import ApplySelectionToMultipleItems from "./ApplySelectionToMultipleItems";
import Select from 'react-select';
import NumberFormat from "react-number-format";
import RollerBlindSingleBOMModal from "./util/RollerBlindSingleBOMModal";
import customUtil from "./custom/CustomUtil";
import RollerBlindSingleConsolidatedBOMModal from "./util/RollerBlindSingleConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    HAYMAN_AND_NOOSA_FABRIC,
    HAYMAN_AND_NOOSA_FABRIC_COLOR,
    HAYMAN_AND_NOOSA_ROLLER_BLIND_BOTTOM_RAIL_COLOUR,
    HAYMAN_AND_NOOSA_ROLLER_BLIND_BOTTOM_RAIL_TYPE,
    HAYMAN_AND_NOOSA_ROLLER_BLIND_LAYOUT,
    PRODUCT_BUILDER_CUSTOM_BUILD,
    PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE
} from "../../../../../store/AppConstants";

const baseUrl = window.location.origin;
const customReactSelectStyleDiagram = {

    valueContainer: (provided, state) => ({
        ...provided,
        height: 130,
        minHeight: 35
    }),
};

export default class RollerBlindSingle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.handleApplyToAll = this.handleApplyToAll.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateSubItemConfiguration = this.updateSubItemConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, -1, null);
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, subItemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                //sub-items
                product.items[itemIndex].subItems = [];
                product = this.updateItemConfigurationOptions("subItems", product, itemIndex, null, null);

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = rollerBlindSingleUtil.updateFormError("room", product, itemIndex, -1);
                });

                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = rollerBlindSingleUtil.updateFormError("room", product, itemIndex, -1);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    //sub-items
                    si.salesOrderItemRollerBlindSingle.subItems = getSorted(si.salesOrderItemRollerBlindSingle.subItems, "blindSequence", true);
                    product.items[itemIndex].subItems = [];
                    product = this.updateItemConfigurationOptions("subItems", product, itemIndex, null, si);

                    product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                        products[productIndex].items.push(item);

                        //prepare context
                        context = {
                            salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                        };
                        context.salesOrderItemCustom.location = product.items[itemIndex].configuration.room.selected.value;
                        context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                        context.salesOrderItemCustom.width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                        context.salesOrderItemCustom.drop = product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value;
                        context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE;
                        context.salesOrderItemCustom.productCode = product.code;
                        context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                        context.salesOrderItemCustom.productionInstructions = "";
                        context.salesOrderItemCustom.productionNotes = "";
                        context.salesOrderItemCustom.stocks = product.items[itemIndex].subItems[subItemIndex].stocks;
                        context.salesOrderItemCustom.stocks.forEach(s => {
                            s.isCustom = false;
                            property = camelCase(s.prodCode);
                            stock = null;
                            if (stocksByProdCode) {
                                stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                            }
                            if (stock) {
                                s.description = stock.description;
                                s.unitPrice = stock.price1;
                            }
                        });
                        products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                    });
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
            default:
                break;
        }
        product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    handleApplyToAll(change, key, itemIndex, subItemIndex, applyTo) {
        let itemCursor, endItemIndex, subItemCursor, endSubItemIndex;
        let { product } = this.props;
        switch (applyTo) {
            case "item-above":
                itemCursor = 0;
                endItemIndex = itemIndex;
                while (itemCursor <= endItemIndex) {
                    subItemCursor = 0;
                    endSubItemIndex = itemIndex === itemCursor ? subItemIndex : product.items[itemCursor].subItems.length - 1;
                    while (subItemCursor <= endSubItemIndex) {
                        this.handleChange(change, key, itemCursor, subItemCursor, true);
                        subItemCursor++;
                    }
                    itemCursor++;
                }
                break;
            case "item-below":
                itemCursor = itemIndex;
                endItemIndex = product.items.length - 1;
                while (itemCursor <= endItemIndex) {
                    subItemCursor = itemIndex === itemCursor ? subItemIndex : 0;
                    endSubItemIndex = product.items[itemCursor].subItems.length - 1;
                    while (subItemCursor <= endSubItemIndex) {
                        this.handleChange(change, key, itemCursor, subItemCursor, true);
                        subItemCursor++;
                    }
                    itemCursor++;
                }
                break;
            case "item-all":
                itemCursor = 0;
                endItemIndex = product.items.length - 1;
                while (itemCursor <= endItemIndex) {
                    subItemCursor = 0;
                    endSubItemIndex = product.items[itemCursor].subItems.length - 1;
                    while (subItemCursor <= endSubItemIndex) {
                        this.handleChange(change, key, itemCursor, subItemCursor, true);
                        subItemCursor++;
                    }
                    itemCursor++;
                }
                break;
            /*case "subItem-above":
                itemCursor = itemIndex;
                subItemCursor = 0;
                endSubItemIndex = subItemIndex;
                while (subItemCursor < endSubItemIndex) {
                    product = this.handleChange(change, key, itemCursor, subItemCursor, false);
                    subItemCursor++;
                }
                break;
            case "subItem-below":
                itemCursor = itemIndex;
                subItemCursor = subItemIndex + 1;
                endSubItemIndex = product.items[itemCursor].subItems.length;
                while (subItemCursor < endSubItemIndex) {
                    product.items[itemCursor] = this.handleChange(change, key, itemCursor, subItemCursor, false);
                    subItemCursor++;
                }
                break;
            case "subItem-all":
                itemCursor = itemIndex;
                subItemCursor = 0;
                endSubItemIndex = product.items[itemCursor].subItems.length;
                while (subItemCursor < endSubItemIndex) {
                    product.items[itemCursor] = this.handleChange(change, key, itemCursor, subItemCursor, false);
                    subItemCursor++;
                }
                break;*/
            default:
                break;
        }
    }

    updateConfiguration(product, itemIndex, context, order) {

        let configurations = product.builder.configurations;
        let {currentUser} = this.props;

        let optionIndex, configurationIndex, isHaymanAndNoosa;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;
        isHaymanAndNoosa = isHaymanAndNoosaCustomer(order.accountID);

        //room
        configurationIndex = findIndex(configurations, "attributeKey", "room");
        product.items[itemIndex].configuration.room = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.room.selected = {value: ""};
        product.items[itemIndex].configuration.room.selected.isSelectable = !isHaymanAndNoosa;
        if (product.items[itemIndex].configuration.room.selected.isSelectable) {
            if (!isEmpty(context)) {
                product.items[itemIndex].configuration.room.selected.value = context.salesOrderItemRollerBlindSingle.room;
            } else {
                product.items[itemIndex].configuration.room.selected.value = "Room " + (itemIndex + 1);
            }
        }
        product.items[itemIndex].configuration.room.formError = {isValid: true, message: "", dom: null,};

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemRollerBlindSingle.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {value: "", dropdownValue: ""};
        product.items[itemIndex].configuration.mount.selected.isSelectable = !isHaymanAndNoosa;
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        if (product.items[itemIndex].configuration.mount.selected.isSelectable) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.mount);
            } else {
                optionIndex = 0;
            }
            product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
            product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;

            product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};
        }


        //bracketCovers
        configurationIndex = findIndex(configurations, "attributeKey", "bracketCovers");
        product.items[itemIndex].configuration.bracketCovers = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracketCovers.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bracketCovers.selected.value = context.salesOrderItemRollerBlindSingle.bracketCovers;
        } else {
            product.items[itemIndex].configuration.bracketCovers.selected.value = (salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, -1, "optionKey", "", "mount") === "Outside/Face");
        }
        product.items[itemIndex].configuration.bracketCovers.formError = {isValid: true, message: "", dom: null,};

        //heavyDuty
        configurationIndex = findIndex(configurations, "attributeKey", "heavyDuty");
        product.items[itemIndex].configuration.heavyDuty = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.heavyDuty.finalOptions = product.items[itemIndex].configuration.heavyDuty.options;

        product.items[itemIndex].configuration.heavyDuty.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.heavyDuty.selected.value = context.salesOrderItemRollerBlindSingle.heavyDuty;
            product.items[itemIndex].configuration.heavyDuty.selected.isSelectable = false;
            product.items[itemIndex].configuration.heavyDuty.selected.isManuallySelected = product.items[itemIndex].configuration.heavyDuty.selected.value;
        } else {
            product.items[itemIndex].configuration.heavyDuty.selected.value = false;
            product.items[itemIndex].configuration.heavyDuty.selected.isSelectable = true;
            product.items[itemIndex].configuration.heavyDuty.selected.isManuallySelected = false;
        }
        product.items[itemIndex].configuration.heavyDuty.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //layout
        configurationIndex = findIndex(configurations, "attributeKey", "layout");
        product.items[itemIndex].configuration.layout = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.layout.selected = {};
        product.items[itemIndex].configuration.layout.finalOptions = product.items[itemIndex].configuration.layout.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.layout.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.layout)
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.layout.selected.value = product.items[itemIndex].configuration.layout.finalOptions[optionIndex];
        product.items[itemIndex].configuration.layout.selected.dropdownValue = product.items[itemIndex].configuration.layout.selected.value.optionKey;
        product.items[itemIndex].configuration.layout.formError = {isValid: true, message: "", dom: null,};

        //diagram
        configurationIndex = findIndex(configurations, "attributeKey", "diagram");
        product.items[itemIndex].configuration.diagram = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.diagram.selected = {};
        product = this.updateItemConfigurationOptions("diagram", product, itemIndex, null, context);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.diagram.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.diagram)
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.diagram.selected.value = product.items[itemIndex].configuration.diagram.finalOptions[optionIndex];
        product.items[itemIndex].configuration.diagram.selected.dropdownValue = product.items[itemIndex].configuration.diagram.selected.value.optionKey;
        product.items[itemIndex].configuration.diagram.formError = {isValid: true, message: "", dom: null,};

        //bracketColor
        configurationIndex = findIndex(configurations, "attributeKey", "bracketColor");
        product.items[itemIndex].configuration.bracketColor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracketColor.selected = {
            value: "",
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bracketColor.selected.isSelectable = !isHaymanAndNoosa;
        product.items[itemIndex].configuration.bracketColor.finalOptions = product.items[itemIndex].configuration.bracketColor.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bracketColor.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.bracketColor)
        } else {
            optionIndex = 0;
        }
        if (product.items[itemIndex].configuration.bracketColor.selected.isSelectable) {
            product.items[itemIndex].configuration.bracketColor.selected.value = product.items[itemIndex].configuration.bracketColor.finalOptions[optionIndex];
            product.items[itemIndex].configuration.bracketColor.selected.dropdownValue = product.items[itemIndex].configuration.bracketColor.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.bracketColor.formError = {isValid: true, message: "", dom: null,};


        //totalWidth
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.totalWidth = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.totalWidth.min = 625;// default
        product.items[itemIndex].configuration.totalWidth.max = 6000;// default
        product.items[itemIndex].configuration.totalWidth.selected = {
            value: 0
        };
        product.items[itemIndex].configuration.totalWidth.formError = {isValid: true, message: "", dom: null,};

        //chargingCable
        configurationIndex = findIndex(configurations, "attributeKey", "chargingCable");
        product.items[itemIndex].configuration.chargingCable = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.chargingCable.finalOptions = product.items[itemIndex].configuration.chargingCable.options;
        product.items[itemIndex].configuration.chargingCable.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.chargingCable.selected.value = context.salesOrderItemRollerBlindSingle.chargingCable;
        } else {
            product.items[itemIndex].configuration.chargingCable.selected.value = false
        }
        product.items[itemIndex].configuration.chargingCable.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //remote15Channels
        configurationIndex = findIndex(configurations, "attributeKey", "remote15Channels");
        product.items[itemIndex].configuration.remote15Channels = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.remote15Channels.finalOptions = product.items[itemIndex].configuration.remote15Channels.options;
        product.items[itemIndex].configuration.remote15Channels.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.remote15Channels.selected.value = context.salesOrderItemRollerBlindSingle.remote15Channels;
        } else {
            product.items[itemIndex].configuration.remote15Channels.selected.value = false
        }
        product.items[itemIndex].configuration.remote15Channels.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //wifiBridge
        configurationIndex = findIndex(configurations, "attributeKey", "wifiBridge");
        product.items[itemIndex].configuration.wifiBridge = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.wifiBridge.finalOptions = product.items[itemIndex].configuration.wifiBridge.options;
        product.items[itemIndex].configuration.wifiBridge.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.wifiBridge.selected.value = context.salesOrderItemRollerBlindSingle.wifiBridge;
        } else {
            product.items[itemIndex].configuration.wifiBridge.selected.value = false
        }
        product.items[itemIndex].configuration.wifiBridge.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        product = rollerBlindSingleUtil.updateRules("bracketCovers", product, itemIndex, null, order);
        product = rollerBlindSingleUtil.updateRules("totalWidth", product, itemIndex, null, order);
        product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);

        product = rollerBlindSingleUtil.updateFormError("room", product, itemIndex, -1);
        product = rollerBlindSingleUtil.updateFormError("quantity", product, itemIndex, -1);

        return product;
    }

    updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order) {
        let {currentUser} = this.props;
        let {configurations, fabrics, deductionRules} = product.builder;
        let optionIndex, configurationIndex, isHaymanAndNoosa;
        isHaymanAndNoosa = isHaymanAndNoosaCustomer(order.accountID);

        product.items[itemIndex].subItems[subItemIndex].configuration = {};
        product.items[itemIndex].subItems[subItemIndex].isValid = true;

        //blindPositionType
        product.items[itemIndex].subItems[subItemIndex].configuration.blindPositionType = {
            selected: {value: ""}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindPositionType.selected.value = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPositionType;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindPositionType.selected.value = "";
        }


        //blindPosition
        product.items[itemIndex].subItems[subItemIndex].configuration.blindPosition = {
            selected: {value: ""}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindPosition.selected.value = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindPosition.selected.value = "";
        }

        //blindSequence
        product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence = {
            selected: {value: ""}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value = subItemIndex + 1;
        }

        //blindSequenceMax
        product.items[itemIndex].subItems[subItemIndex].configuration.blindSequenceMax = {
            selected: {value: ""}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindSequenceMax.selected.value = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.blindSequenceMax.selected.value = subItemIndex + 1;
        }

        //drivenByBlindSequence
        product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence = {
            selected: {value: ""}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].drivenByBlindSequence;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value = subItemIndex + 1;
        }

        product.items[itemIndex].subItems[subItemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //mount
        product.items[itemIndex].subItems[subItemIndex].configuration.mount = product.items[itemIndex].configuration.mount;

        //heavyDuty
        product.items[itemIndex].subItems[subItemIndex].configuration.heavyDuty = product.items[itemIndex].configuration.heavyDuty;

        //bracketColor
        product.items[itemIndex].subItems[subItemIndex].configuration.bracketColor = cloneDeep(product.items[itemIndex].configuration.bracketColor);

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].subItems[subItemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 625;// default
        product.items[itemIndex].subItems[subItemIndex].configuration.width.max = 6000;// default
        product.items[itemIndex].subItems[subItemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].width);
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value = 900;
        }

        product.items[itemIndex].subItems[subItemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].subItems[subItemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.drop.min = 400;//default
        product.items[itemIndex].subItems[subItemIndex].configuration.drop.max = 3000;//default
        product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].drop);
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value = isHaymanAndNoosa ? 2400 : 900;
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].subItems[subItemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.operation.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected = {
            value: null,
            dropdownValue: "",
            isSelectable: true
        };
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].operation);
        } else {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions, "optionKey", "Chain");
        }
        if (optionIndex > -1) {
            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions[optionIndex];
            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey;
        }


        //operationGroupCombination
        product.items[itemIndex].configuration.operationGroupCombination = {
            options: [],
            finalOptions: [],
            selected: {
                value: {},
                dropdownValue: ""
            }
        };
        product = this.updateItemConfigurationOptions("operationGroupCombination", product, itemIndex, null, null);

        //operationGroup
        configurationIndex = findIndex(configurations, "attributeKey", "operationGroup");
        product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.operationGroup.selected = {
            value: "",
            stocks: []
        };

        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (
                (isHaymanAndNoosa ? HAYMAN_AND_NOOSA_FABRIC.includes(o.fabricName) : true)
                && salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected = {};
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].fabricType);
        } else {
            optionIndex = 0;
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.optionKey;


        //fabric color
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColor");
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.options = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.colors.map((o, oIndex) => {
            return {
                optionKey: o.color,
                optionLabel: o.color,
                value: o.color,
                label: o.color,
                ...o
            }
        });
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value ? product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.optionKey : "", o, currentUser)
            )
        });
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].fabricColor);
        } else {
            optionIndex = 0;
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.optionKey;
        product = this.updateItemConfigurationOptions("fabricColor", product, itemIndex, subItemIndex, context);


        //fabric roll direction
        configurationIndex = findIndex(configurations, "attributeKey", "rollDirection");
        product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected = {
            value: "",
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.isSelectable = !isHaymanAndNoosa;
        if (product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.isSelectable) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].rollDirection);
            } else {
                optionIndex = 0;
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.finalOptions[optionIndex];
            product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.value.optionKey;
        }

        //control side
        configurationIndex = findIndex(configurations, "attributeKey", "controlSide");
        product.items[itemIndex].subItems[subItemIndex].configuration.controlSide = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected = {
            value: "",
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable = !isHaymanAndNoosa;
        if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].controlSide);
            } else {
                optionIndex = 0;
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
            product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
        }


        //chain-color
        configurationIndex = findIndex(configurations, "attributeKey", "chainColor");
        product.items[itemIndex].subItems[subItemIndex].configuration.chainColor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.options.forEach((o, oIndex) => {
            if (o.optionKey === "stainlessSteel") {
                if (order.accountID.includes("01500")) {
                    o.optionLabel = o.optionLabel.replace("6", "12");// bunnings $12, suggested by @Kali
                }
                if (order.accountID.includes("01604")) {
                    o.optionLabel = o.optionLabel.replace("6", "3");// andersens $3, suggested by @Kali
                }
            }
            o.sets.forEach((s, sIndex) => {
                s.optionKey = s.setNum + "cm";
                s.optionLabel = s.setNum + "cm";
            })
        });
        product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected = {
            value: "",
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable = !isHaymanAndNoosa;
        if (product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].chainColor);
            } else {
                optionIndex = 0;
            }
            if (optionIndex > -1) {
                product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.finalOptions[optionIndex];
                product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value.optionKey;
            }
        }


        //chainLength
        configurationIndex = findIndex(configurations, "attributeKey", "chainLength");
        product.items[itemIndex].subItems[subItemIndex].configuration.chainLength = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.options = [];
        product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable = !isHaymanAndNoosa;
        product = this.updateItemConfigurationOptions("chainLength", product, itemIndex, subItemIndex, context);


        //bottom rail type
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailType");
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.options;
        if (isHaymanAndNoosa) {
            product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.finalOptions.filter(o => HAYMAN_AND_NOOSA_ROLLER_BLIND_BOTTOM_RAIL_TYPE.includes(o.optionKey));
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected = {};
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].bottomRailType);
        } else {
            optionIndex = 0;
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value.optionKey;


        //bottom rail color
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailColor");
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.options = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.options.filter(o => o.isActive);
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected = {
            value: {},
            dropdownValue: ""
        };
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product = this.updateItemConfigurationOptions("bottomRailColor", product, itemIndex, subItemIndex, context);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].bottomRailColor);
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions[optionIndex].optionKey;


        //consumables
        configurationIndex = findIndex(configurations, "attributeKey", "consumables");
        product.items[itemIndex].subItems[subItemIndex].configuration.consumables = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.consumables.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.consumables.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.consumables.selected = {
            stocks: []
        };

        //accessoryBoxColour
        configurationIndex = findIndex(configurations, "attributeKey", "accessoryBoxColour");
        product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.options = product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.options.filter(o => o.isActive);
        product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.isSelectable = isHaymanAndNoosa;
        if (product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.isSelectable) {
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product = this.updateItemConfigurationOptions("accessoryBoxColour", product, itemIndex, subItemIndex, context);
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].accessoryBoxColour);
            } else {
                optionIndex = 0;
            }
            product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.finalOptions[optionIndex];
            product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.finalOptions[optionIndex].optionKey;
        }

        //keyway
        configurationIndex = findIndex(configurations, "attributeKey", "keyway");
        product.items[itemIndex].subItems[subItemIndex].configuration.keyway = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.keyway.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.keyway.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.keyway.selected = {
            stocks: []
        };

        //deduction
        product.items[itemIndex].subItems[subItemIndex].configuration.deduction = {
            deductionRules: deductionRules,
            selected: {
                value: {
                    deduction: 0
                }
            }
        };

        product.items[itemIndex].subItems[subItemIndex].configuration.consumables.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //price
        product.items[itemIndex].subItems[subItemIndex].pricing = {
            unitPrice: 0,
        };
        product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, null);


        product = rollerBlindSingleUtil.updateRules("drop", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);
        product = this.updateItemConfigurationOptions("operation", product, itemIndex, null, null);
        product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("bottomRailColor", product, itemIndex, subItemIndex, order);

        product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, null, context);
        product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

        product = rollerBlindSingleUtil.updateRules("chargingCable", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("remote15Channels", product, itemIndex, subItemIndex, order);
        product = rollerBlindSingleUtil.updateRules("wifiBridge", product, itemIndex, subItemIndex, order);

        product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
        product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);
        product = rollerBlindSingleUtil.updateFormError("drop", product, itemIndex, subItemIndex);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, subItemIndex, context) {
        let optionIndex = -1,
            blindPositionType = "",
            blindPosition = "",
            blindSequence = 0,
            blindSequenceMax = 0,
            drivenByBlindSequence = "",
            condition1 = "",
            condition2 = "",
            sets = [],
            stocks = [],
            cutFromSubItemIndex, pasteToSubItemIndex, isHaymanAndNoosa;
        let {order, currentUser} = this.props;
        isHaymanAndNoosa = isHaymanAndNoosaCustomer(order.accountID);

        if (key) {
            switch (key) {
                case "diagram":
                    optionIndex = findIndex(product.items[itemIndex].configuration.diagram.options, "optionKey", product.items[itemIndex].configuration.layout.selected.value.optionKey);
                    product.items[itemIndex].configuration.diagram.finalOptions = (product.items[itemIndex].configuration.diagram.options || []).filter(x => (x.parentOptionId && (isHaymanAndNoosa ? HAYMAN_AND_NOOSA_ROLLER_BLIND_LAYOUT.includes(x.optionKey) : true)));
                    //react-select dropdown component options adding label and value ------------------------
                    (product.items[itemIndex].configuration.diagram.finalOptions || []).forEach((option, oIndex) => {
                        //  option.id = option.optionKey;
                        option.value = option.optionKey;
                        option.label = <div className="text-center" key={option.id} style={{maxHeight: 125}}>
                            {option.description
                                ? <img className="img-fluid rounded d-print-inline mr-2" src={option.description}
                                       style={{maxHeight: 100}}/>
                                : null
                            }
                            <div>{option.optionLabel}</div>
                        </div>;
                    });
                    optionIndex = 0;
                    product.items[itemIndex].configuration.diagram.selected.value = product.items[itemIndex].configuration.diagram.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.diagram.selected.dropdownValue = product.items[itemIndex].configuration.diagram.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, null, null);
                    product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, null, null);
                    //for operation start
                    (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                        product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chargingCable", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("remote15Channels", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("wifiBridge", product, itemIndex, subItemIndex, order);

                        product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

                        product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                        product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);

                        //for operation ends
                    });
                    break;
                case "heavyDuty":
                    if (product.items[itemIndex].configuration.heavyDuty.selected.value) {
                        condition1 = product.items[itemIndex].configuration.heavyDuty.selected.isSelectable ? "width<=2110" : "width>2110";
                        product.items[itemIndex].configuration.heavyDuty.finalOptions = product.items[itemIndex].configuration.heavyDuty.options.filter(o => o.description === condition1);
                    } else {
                        product.items[itemIndex].configuration.heavyDuty.finalOptions = [];
                    }
                    break;
                case "subItems":
                    switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                        case "Single":

                            // first--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);

                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "";
                                blindSequence = 1;
                            }

                            blindSequenceMax = 1;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            break;
                        case "Single 2Link Driven":
                            // first--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);
                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "Left";
                                blindSequence = 1;

                            }

                            blindSequenceMax = 2;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            // second--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);
                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "Right";
                                blindSequence = 2;

                            }

                            blindSequenceMax = 2;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            break;
                        case "Single 2Link Non Driven":
                            // first--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);
                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "Left";
                                blindSequence = 1;

                            }

                            blindSequenceMax = 2;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            // second--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);
                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "Right";
                                blindSequence = 2;
                            }

                            blindSequenceMax = 2;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            break;
                        case "Single 3Link":
                            // first--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);
                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "Left";
                                blindSequence = 1;
                            }

                            blindSequenceMax = 3;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            // second--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);
                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "Centre";
                                blindSequence = 2;
                            }

                            blindSequenceMax = 3;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            // third--------
                            subItemIndex = product.items[itemIndex].subItems.length; // get item index
                            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex]); //create obj
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].subItems)) {
                                delete product.items[itemIndex].subItems[subItemIndex].subItems;
                            }
                            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order);
                            if (!isEmpty(context)) {
                                blindPosition = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindPosition;
                                blindSequence = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].blindSequence;
                            } else {
                                blindPosition = "Right";
                                blindSequence = 3;
                            }

                            blindSequenceMax = 3;
                            drivenByBlindSequence = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                            blindPositionType = blindSequence === drivenByBlindSequence ? "DRIVE" : "LINKED";
                            product = this.handleChange(blindPosition, "blindPosition", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequence, "blindSequence", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindSequenceMax, "blindSequenceMax", itemIndex, subItemIndex, false);
                            product = this.handleChange(blindPositionType, "blindPositionType", itemIndex, subItemIndex, false);
                            product = this.handleChange(drivenByBlindSequence, "drivenByBlindSequence", itemIndex, subItemIndex, false);

                            break;
                    }

                    product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, null, null);
                    //for operation start
                    (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                        product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chargingCable", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("remote15Channels", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("wifiBridge", product, itemIndex, subItemIndex, order);

                        product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

                        product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                        product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);
                        //for operation ends
                    });
                    product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, null, order);
                    product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, null, order);
                    break;
                case "set":
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value)) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => x.bmCode === product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.primaryBMCode);
                    }
                    break;
                case "width":
                    product.items[itemIndex].configuration.totalWidth.selected.value = product.items[itemIndex].subItems.reduce((accumulator, subItem) => accumulator + (parseInt(subItem.configuration.width.selected.value) || 0), 0);

                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
                    }
                    break;
                case "drop":
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.priceMatrix)) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value);
                        }
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.dropSet)) {
                            product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.price = product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.dropSet.price;
                        }
                        if (product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.widthSet && product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.dropSet) {
                            product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value.dropSet.drop;
                        }
                    }
                    break;
                case "fabricColor":
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.colors = getSorted(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.colors, "color", true);
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.options = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.colors;
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.options.map((o, oIndex) => {
                        return {
                            optionKey: o.color,
                            optionLabel: salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                            value: o.color,
                            label: o.color,
                            ...o
                        }
                    }).filter(o => {
                        return (
                            (isHaymanAndNoosa ? HAYMAN_AND_NOOSA_FABRIC_COLOR.includes(o.color) : true)
                            && salesOrderProductBuilderV1Service.shouldFabricColorIncluded( product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value ? product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.optionKey : "", o, currentUser)
                        )
                    });


                    //update selection, getting previous selection
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value)) {
                        optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.optionKey);
                    }

                    //if couldn't get first item
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions)) {
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value = optionIndex > -1 ? product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions[optionIndex] : null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.dropdownValue = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.optionKey;
                    break;
                case "chainLength":
                    if (product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value)) {
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.options = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value.sets;
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.options;
                            product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions.forEach((o, oIndex) => {
                                if (!isEmpty(context)) {
                                    optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions, "optionKey", context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].chainLength);
                                } else {
                                    if (product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value >= o.min && product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value <= o.max) {
                                        optionIndex = oIndex;
                                    }
                                }
                            });
                            if (optionIndex > -1) {
                                product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions[optionIndex];
                                product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions[optionIndex].optionKey;
                            }
                        }
                    }
                    break;
                case "bottomRailColor":
                    optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.options, "optionKey", product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value.optionKey);
                    if (optionIndex > -1) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions = findItems(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.options, "parentOptionId", product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.options[optionIndex].id);
                    }
                    if (isHaymanAndNoosa) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions.filter(o => HAYMAN_AND_NOOSA_ROLLER_BLIND_BOTTOM_RAIL_COLOUR.includes(o.optionKey));
                    }
                    optionIndex = 0;
                    product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value.optionKey;
                    break;
                case "accessoryBoxColour":
                    if (isHaymanAndNoosa) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value)) {
                            switch (product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.optionKey) {
                                case "Hayman Cloud":
                                case "White":
                                    condition1 = "White";
                                    break;
                                case "Hayman Flint":
                                case "Peppercorn":
                                    condition1 = "Black";
                                    break;
                            }
                            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.finalOptions, "optionKey", condition1);
                            if (optionIndex > -1) {
                                product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.finalOptions[optionIndex];
                                product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.value.optionKey;
                            }
                        }
                    }
                    break;
                case "operation":
                    condition1 = product.items[itemIndex].configuration.layout.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.diagram.selected.value.optionKey;

                    (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                        product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.operation.options.filter(o =>
                            (
                                (o.condition1 ? o.condition1.includes(condition1) : true)
                                && (o.condition2 ? o.condition2.includes(condition2) : true)
                            )
                        );
                        optionIndex = -1;
                        if ((!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions)) && (product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.isSelectable)) {
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value)) {
                                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions, 'optionKey', product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey);
                            }
                            if (optionIndex === -1) {
                                optionIndex = 0;
                            }
                        }
                        if (optionIndex > -1) {
                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions[optionIndex];
                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey;
                        } else {
                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value = {};
                            product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.dropdownValue = "";
                        }
                    });
                    break;
                case "operationGroupCombination":
                    product.items[itemIndex].configuration.operationGroupCombination.options = product.items[itemIndex].configuration.layout.selected.value.sets;
                    product.items[itemIndex].configuration.operationGroupCombination.finalOptions = product.items[itemIndex].configuration.operationGroupCombination.options;
                    break;
                case "controlSide":
                    optionIndex = 0; //defaults
                    condition1 = "Left";//defaults

                    switch (product.items[itemIndex].configuration.diagram.selected.value.optionKey) {
                        case "Single":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                if (context) {
                                    condition1 = context.salesOrderItemRollerBlindSingle.subItems[subItemIndex].controlSide;
                                    optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options, 'optionKey', condition1);
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options;

                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                            break;
                        case "Single 2Link Driven LHC":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                switch (subItemIndex) {
                                    case 0:
                                    case 1:
                                        condition1 = "Left";
                                        break;
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options.filter(o => o.optionKey === condition1);
                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                            break;
                        case "Single 2Link Driven RHC":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                switch (subItemIndex) {
                                    case 0:
                                    case 1:
                                        condition1 = "Right";
                                        break;
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options.filter(o => o.optionKey === condition1);
                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                            break;
                        case "Single 2Link Non Driven":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                switch (subItemIndex) {
                                    case 0:
                                        condition1 = "Left";
                                        break;
                                    case 1:
                                        condition1 = "Right";
                                        break;
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options.filter(o => o.optionKey === condition1);
                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                            break;
                        case "Single 3Link Left Linked":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                switch (subItemIndex) {
                                    case 0:
                                    case 1:
                                        condition1 = "Left";
                                        break;
                                    case 2:
                                        condition1 = "Right";
                                        break;
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options.filter(o => o.optionKey === condition1);
                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                            break;
                        case "Single 3Link Right Linked":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                switch (subItemIndex) {
                                    case 0:
                                        condition1 = "Left";
                                        break;
                                    case 1:
                                    case 2:
                                        condition1 = "Right";
                                        break;
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options.filter(o => o.optionKey === condition1);
                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                            break;
                        case "Single 3Link All Linked LHC":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                switch (subItemIndex) {
                                    case 0:
                                    case 1:
                                    case 2:
                                        condition1 = "Left";
                                        break;
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options.filter(o => o.optionKey === condition1);
                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                            break;
                        case "Single 3Link All Linked RHC":
                            (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                                switch (subItemIndex) {
                                    case 0:
                                    case 1:
                                    case 2:
                                        condition1 = "Right";
                                        break;
                                }
                                product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.options.filter(o => o.optionKey === condition1);
                                if (product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.isSelectable) {
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                                }
                                product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                            });
                    }
                    break;
                case "drivenByBlindSequence":
                    product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value = rollerBlindSingleUtil.findDrivenByBindSequence(product, itemIndex, subItemIndex);
                    drivenByBlindSequence = product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBlindSequence.selected.value;
                    blindSequence = product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value;
                    product.items[itemIndex].subItems[subItemIndex].configuration.blindPositionType.selected.value = drivenByBlindSequence === blindSequence ? "DRIVE" : "LINKED";
                    break;
                case "swap-operation":
                    switch (product.items[itemIndex].configuration.layout.selected.value.optionKey) {
                        case "Single 2Link Driven":
                            switch (product.items[itemIndex].configuration.diagram.selected.value.optionKey) {
                                case "Single 2Link Driven LHC":
                                    cutFromSubItemIndex = 1;
                                    pasteToSubItemIndex = 0;
                                    if (product.items[itemIndex].subItems[cutFromSubItemIndex] && product.items[itemIndex].subItems[pasteToSubItemIndex]) {
                                        if (!isEmpty(product.items[itemIndex].subItems[cutFromSubItemIndex].configuration.operation.selected.value)) {
                                            condition1 = product.items[itemIndex].subItems[cutFromSubItemIndex].configuration.operation.selected.value.operation.selected.value.optionKey;
                                        } else {
                                            condition1 = "Chain"
                                        }
                                        optionIndex = findIndex(product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.finalOptions, "optionKey", condition1);
                                        if (optionIndex > -1) {
                                            product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.selected.value = product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.finalOptions[optionIndex];
                                            product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.selected.value.optionKey;
                                        }
                                    }
                                    break;
                                case "Single 2Link Driven RHC":
                                    cutFromSubItemIndex = 0;
                                    pasteToSubItemIndex = 1;
                                    if (product.items[itemIndex].subItems[cutFromSubItemIndex] && product.items[itemIndex].subItems[pasteToSubItemIndex]) {
                                        if (!isEmpty(product.items[itemIndex].subItems[cutFromSubItemIndex].configuration.operation.selected.value)) {
                                            condition1 = product.items[itemIndex].subItems[cutFromSubItemIndex].configuration.operation.selected.value.operation.selected.value.optionKey;
                                        } else {
                                            condition1 = "Chain"
                                        }
                                        optionIndex = findIndex(product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.finalOptions, "optionKey", condition1);
                                        if (optionIndex > -1) {
                                            product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.selected.value = product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.finalOptions[optionIndex];
                                            product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].subItems[pasteToSubItemIndex].configuration.operation.selected.value.optionKey;
                                        }
                                    }
                                    break;
                            }

                            product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, null, null);
                            product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);
                            product = this.updateItemConfigurationOptions("operation", product, itemIndex, null, null);
                            product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {


                                product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("chargingCable", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("remote15Channels", product, itemIndex, subItemIndex, order);
                                product = rollerBlindSingleUtil.updateRules("wifiBridge", product, itemIndex, subItemIndex, order);

                                product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

                                product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                            });
                            break;
                        case "Single":
                        case "Single 2Link Non Driven":
                        case "Single 3Link":
                            break;
                    }
                    break;
                case "basePrice":
                    //for updating bmCode
                    product = this.updateItemConfigurationOptions("set", product, itemIndex, subItemIndex, null);
                    //for updating width set
                    product = this.updateItemConfigurationOptions("width", product, itemIndex, subItemIndex, null);
                    // for updating drop set and update price
                    product = this.updateItemConfigurationOptions("drop", product, itemIndex, subItemIndex, null);
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, subItemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1, endIndex = null;
        switch (key) {
            case "room":
                product.items[itemIndex].configuration.room.selected.value = change;
                product = rollerBlindSingleUtil.updateFormError("room", product, itemIndex, subItemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = rollerBlindSingleUtil.updateFormError("quantity", product, itemIndex, subItemIndex);
                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;

                    product = rollerBlindSingleUtil.updateRules("bracketCovers", product, itemIndex, subItemIndex, order);
                }
                break;
            case "bracketCovers":
                product.items[itemIndex].configuration.bracketCovers.selected.value = change;
                break;
            case "layout":
                optionIndex = findIndex(product.items[itemIndex].configuration.layout.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.layout.selected.value = product.items[itemIndex].configuration.layout.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.layout.selected.dropdownValue = product.items[itemIndex].configuration.layout.selected.value.optionKey;
                    //product = this.updateItemConfigurationOptions("diagram", product, itemIndex, null, null);

                    //sub-items
                    product.items[itemIndex].subItems = [];
                    product = this.updateItemConfigurationOptions("subItems", product, itemIndex, null, null);
                    product = this.updateItemConfigurationOptions("operationGroupCombination", product, itemIndex, null, null);

                    product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, null, null);
                    product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, null, order);
                    product = rollerBlindSingleUtil.updateRules("totalWidth", product, itemIndex, null, order);
                    product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, null, order);
                    product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);

                    product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);
                    product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                    product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);
                }
                break;
            case "diagram":
                optionIndex = findIndex(product.items[itemIndex].configuration.diagram.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.diagram.selected.value = product.items[itemIndex].configuration.diagram.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.diagram.selected.dropdownValue = product.items[itemIndex].configuration.diagram.selected.value.optionKey;

                    //extract layout from the selection and update
                    change = product.items[itemIndex].configuration.diagram.options.find(x => x.id === product.items[itemIndex].configuration.diagram.selected.value.parentOptionId);
                    product = this.handleChange(change.optionKey, "layout", itemIndex, subItemIndex, false);

                    //product = this.updateItemConfigurationOptions("swap-operation", product, itemIndex, null, null);
                    product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, null, null);
                    product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, null, null);

                    //for operation start
                    (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                        product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chargingCable", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("remote15Channels", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("wifiBridge", product, itemIndex, subItemIndex, order);

                        product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

                        product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                        product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);
                        //for operation ends
                    });

                    product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, null, order);
                    product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, null, order);
                }
                break;
            case "blindPosition":
                product.items[itemIndex].subItems[subItemIndex].configuration.blindPosition.selected.value = change;
                break;
            case "blindPositionType":
                product.items[itemIndex].subItems[subItemIndex].configuration.blindPositionType.selected.value = change;
                break;
            case "blindSequence":
                product.items[itemIndex].subItems[subItemIndex].configuration.blindSequence.selected.value = change;
                break;
            case "blindSequenceMax":
                product.items[itemIndex].subItems[subItemIndex].configuration.blindSequenceMax.selected.value = change;
                break;
            case "drivenByBindSequence":
                product.items[itemIndex].subItems[subItemIndex].configuration.drivenByBindSequence.selected.value = change;
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    //-	Customer can only choose one operation type for all blind positions.
                    (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                        product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.operation.finalOptions[optionIndex];
                        product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.operation.selected.value.optionKey;

                        product = rollerBlindSingleUtil.updateRules("operation", product, itemIndex, null, order);
                        product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainColor", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chainLength", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("chargingCable", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("remote15Channels", product, itemIndex, subItemIndex, order);
                        product = rollerBlindSingleUtil.updateRules("wifiBridge", product, itemIndex, subItemIndex, order);

                        product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

                        product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                        product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);

                    })

                }
                break;
            case "width":
                endIndex = subItemIndex === 0 ? product.items[itemIndex].subItems.length - 1 : subItemIndex;
                while (subItemIndex <= endIndex) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value = parseInt(change);

                    product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);

                    product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

                    product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                    product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);

                    subItemIndex++;
                }
                break;
            case "drop":
                endIndex = subItemIndex === 0 ? product.items[itemIndex].subItems.length - 1 : subItemIndex;
                while (subItemIndex <= endIndex) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.drop.selected.value = parseInt(change);

                    product = this.updateItemConfigurationOptions("chainLength", product, itemIndex, subItemIndex, null);

                    product = rollerBlindSingleUtil.updateRules("heavyDuty", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);
                    product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);

                    product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);

                    product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                    product = rollerBlindSingleUtil.updateFormError("totalWidth", product, itemIndex, subItemIndex);
                    product = rollerBlindSingleUtil.updateFormError("drop", product, itemIndex, subItemIndex);

                    subItemIndex++;
                }
                break;
            case "heavyDuty":
                product.items[itemIndex].configuration.heavyDuty.selected.value = change;
                product.items[itemIndex].configuration.heavyDuty.selected.isManuallySelected = change;
                product = rollerBlindSingleUtil.updateRules("motor", product, itemIndex, subItemIndex, order);
                product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, subItemIndex, order);
                product = rollerBlindSingleUtil.updateRules("operationGroupCombination", product, itemIndex, subItemIndex, order);

                product = this.updateItemConfigurationOptions("heavyDuty", product, itemIndex, subItemIndex, null);
                product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, subItemIndex);
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricColor", product, itemIndex, subItemIndex, null);
                    product = this.updateItemConfigurationOptions("accessoryBoxColour", product, itemIndex, subItemIndex, null);
                }
                break;
            case "fabricColor":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColor.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("accessoryBoxColour", product, itemIndex, subItemIndex, null);
                }
                break;
            case "rollDirection":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.rollDirection.selected.value.optionKey;
                }
                break;
            case "controlSide":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.controlSide.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("swap-operation", product, itemIndex, null, null);
                    (product.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
                        product = this.updateItemConfigurationOptions("drivenByBlindSequence", product, itemIndex, subItemIndex, null);
                    });
                }
                break;
            case "chainColor":
                if (product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable) {
                    optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.finalOptions, "optionKey", change);
                    if (optionIndex > -1) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.finalOptions[optionIndex];
                        product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.value.optionKey;
                        product = this.updateItemConfigurationOptions("chainLength", product, itemIndex, subItemIndex, null);
                    }
                }
                break;
            case "chainLength":
                if (product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable) {
                    optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions, "optionKey", change);
                    if (optionIndex > -1) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.finalOptions[optionIndex];
                        product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.value.optionKey;
                    }
                }
                break;
            case "bracketColor":
                optionIndex = findIndex(product.items[itemIndex].configuration.bracketColor.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bracketColor.selected.value = product.items[itemIndex].configuration.bracketColor.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bracketColor.selected.dropdownValue = product.items[itemIndex].configuration.bracketColor.selected.value.optionKey;
                }
                break;
            case "bottomRailType":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("bottomRailColor", product, itemIndex, subItemIndex, null);
                    product = rollerBlindSingleUtil.updateRules("bottomRailColor", product, itemIndex, subItemIndex, order);
                }
                break;
            case "bottomRailColor":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value.optionKey;
                }
                break;
            case "accessoryBoxColour":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.accessoryBoxColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.bottomRailColor.selected.value.optionKey;
                }
                break;
            case "chargingCable":
                if (product.items[itemIndex].configuration.chargingCable.selected.isSelectable) {
                    product.items[itemIndex].configuration.chargingCable.selected.value = change;
                }
                break;
            case "remote15Channels":
                if (product.items[itemIndex].configuration.remote15Channels.selected.isSelectable) {
                    product.items[itemIndex].configuration.remote15Channels.selected.value = change;
                }
                break;
            case "wifiBridge":
                if (product.items[itemIndex].configuration.wifiBridge.selected.isSelectable) {
                    product.items[itemIndex].configuration.wifiBridge.selected.value = change;
                }
                break;
        }

        if (isComponentUpdateRequired) {
            /*immediately update component*/

            product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, null);
            });

            product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;
        let isHaymanAndNoosa = isHaymanAndNoosaCustomer(order.accountID);
        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div>#</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 135}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Room</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Quantity</div>
                                        </div>
                                    </th>

                                    {/*<th style={{minWidth: 250}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Layout</div>
                                        </div>
                                    </th>*/}
                                    <th style={{minWidth: 250}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Blind Layout</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 215}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Operation</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}} colSpan={2}>
                                        <div className={"text-center"}>
                                            <div>Blind</div>
                                            <div>Position</div>
                                        </div>
                                    </th>

                                    <th style={{minWidth: 75}}>
                                        <div>
                                            <div>Total</div>
                                            <div>Width</div>
                                            <div>(mm)</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 140}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Width(mm)</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 130}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Drop(mm)</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 250}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Fabric Range</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 190}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Roll Direction</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Control Side</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 170}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Chain</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 170}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Mount</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 130}}>
                                        <div className={"text-center"}>
                                            <div>Bracket</div>
                                            <div>Colour</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 155}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Bottom Rail</div>
                                        </div>
                                    </th>
                                    {
                                        isHaymanAndNoosa
                                            ? <th style={{minWidth: 155}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>Accessory Box</div>
                                                </div>
                                            </th>
                                            : null
                                    }

                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {!currentUser.isExternalUser ? <th style={{minWidth: 90}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>
                                                <Button color={"link"}
                                                        onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                            </div>
                                        </div>
                                    </th> : null}

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            return (item.subItems || []).map((subItem, subItemIndex) => {
                                                let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex + "-subItem-" + subItemIndex;
                                                return <tr key={uniqueId}
                                                           id={uniqueId}
                                                           className={classnames({"table-danger": !item.isValid})}>
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle text-center"
                                                              rowSpan={item.subItems.length}>
                                                            {itemIndex + 1}
                                                        </td>
                                                        : null
                                                    }
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div
                                                                className={"d-flex justify-content-between align-items-center"}>
                                                                <div>
                                                                    <Input type="text" name="room"
                                                                           value={item.configuration.room.selected.value}
                                                                           onChange={(e) => this.handleChange(e.target.value, "room", itemIndex, subItemIndex, true)}
                                                                           placeholder="Room"/>
                                                                    {
                                                                        item.configuration.room.formError.isValid
                                                                            ? null
                                                                            : <Badge color="danger">
                                                                                {item.configuration.room.formError.message}
                                                                            </Badge>
                                                                    }
                                                                    {
                                                                        item.configuration.room.formError.isWarning
                                                                            ? <Badge color="warning">
                                                                                {item.configuration.room.formError.message}
                                                                            </Badge>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div
                                                                className={"d-flex justify-content-between align-items-center"}>
                                                                <div>
                                                                    <Input type="number" name="quantity"
                                                                           invalid={!item.configuration.quantity.formError.isValid}
                                                                           value={item.configuration.quantity.selected.value}
                                                                           min={item.configuration.quantity.min}
                                                                           max={item.configuration.quantity.max}
                                                                           onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, subItemIndex, true)}
                                                                           onFocus={(event) => event.target.select()}
                                                                           placeholder="quantity"/>

                                                                    {
                                                                        item.configuration.quantity.formError.isValid
                                                                            ? null
                                                                            : <Badge color="danger">
                                                                                {item.configuration.quantity.formError.message}
                                                                            </Badge>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div
                                                                className={"d-flex justify-content-between align-items-center"}>
                                                                <div className={"flex-fill"}>
                                                                    <Select
                                                                        isClearable={false}
                                                                        isSearchable={false}
                                                                        value={item.configuration.diagram.selected.value}
                                                                        onChange={(selectedOption) => this.handleChange(selectedOption.optionKey, "diagram", itemIndex, subItemIndex, true)}
                                                                        options={item.configuration.diagram.finalOptions}
                                                                        getOptionLabel={option => option.label}
                                                                        getOptionValue={option => option.value}
                                                                        styles={customReactSelectStyleDiagram}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    <td className="align-middle">
                                                        {
                                                            (subItem.configuration.drivenByBlindSequence.selected.value === subItem.configuration.blindSequence.selected.value)
                                                                ? <div>
                                                                    <div
                                                                        className={"d-flex justify-content-between align-items-center"}>
                                                                        {subItem.configuration.operation.selected.isSelectable}
                                                                        <Input type="select" name="operation"
                                                                               disabled={!subItem.configuration.operation.selected.isSelectable}
                                                                               value={subItem.configuration.operation.selected.dropdownValue}
                                                                               onChange={(e) => this.handleChange(e.target.value, "operation", itemIndex, subItemIndex, true)}>
                                                                            <option value={""} disabled>Select operation
                                                                            </option>

                                                                            {
                                                                                (subItem.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                                    return <option
                                                                                        key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </div>
                                                                    {
                                                                        rollerBlindSingleUtil.isVisibleExtraOptions(product, itemIndex, subItemIndex, "motor-accessories")
                                                                            ? <div className="mt-2">
                                                                                <strong className={"mb-1"}>Motor Options</strong>
                                                                                <div>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        className={"mr-2"}
                                                                                        color={"link"}
                                                                                        disabled={!item.configuration.chargingCable.selected.isSelectable}
                                                                                        onClick={() => this.handleChange(!item.configuration.chargingCable.selected.value, "chargingCable", itemIndex, subItemIndex, true)}>
                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                "fa-check-square-o": item.configuration.chargingCable.selected.value,
                                                                                                "fa-square-o": !item.configuration.chargingCable.selected.value,
                                                                                            }
                                                                                        )}/>
                                                                                    </Button>
                                                                                    {item.configuration.chargingCable.attributeLabel}
                                                                                </div>
                                                                                <div>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        className={"mr-2"}
                                                                                        color={"link"}
                                                                                        disabled={!item.configuration.remote15Channels.selected.isSelectable}
                                                                                        onClick={() => this.handleChange(!item.configuration.remote15Channels.selected.value, "remote15Channels", itemIndex, subItemIndex, true)}>
                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                "fa-check-square-o": item.configuration.remote15Channels.selected.value,
                                                                                                "fa-square-o": !item.configuration.remote15Channels.selected.value,
                                                                                            }
                                                                                        )}/>
                                                                                    </Button>
                                                                                    {item.configuration.remote15Channels.attributeLabel}
                                                                                </div>
                                                                                <div>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        className={"mr-2"}
                                                                                        color={"link"}
                                                                                        disabled={!item.configuration.wifiBridge.selected.isSelectable}
                                                                                        onClick={() => this.handleChange(!item.configuration.wifiBridge.selected.value, "wifiBridge", itemIndex, subItemIndex, true)}>
                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                "fa-check-square-o": item.configuration.wifiBridge.selected.value,
                                                                                                "fa-square-o": !item.configuration.wifiBridge.selected.value,
                                                                                            }
                                                                                        )}/>
                                                                                    </Button>
                                                                                    {item.configuration.wifiBridge.attributeLabel}
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                : <div
                                                                    className={"d-flex justify-content-center align-self-center"}>
                                                                    N/A
                                                                </div>
                                                        }

                                                    </td>

                                                    <td className="align-middle"
                                                        colSpan={item.configuration.diagram.selected.value.optionKey === "Single" ? 2 : 1}>
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            {subItem.configuration.blindPosition.selected.value ? subItem.configuration.blindSequence.selected.value : null}
                                                        </div>
                                                    </td>
                                                    {
                                                        item.configuration.diagram.selected.value.optionKey !== "Single"
                                                            ? <td className="align-middle">
                                                                <div
                                                                    className={"d-flex justify-content-between align-items-center"}>
                                                                    {subItem.configuration.blindPosition.selected.value}
                                                                </div>
                                                            </td>
                                                            : null
                                                    }


                                                    {subItemIndex === 0

                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div
                                                                className={"text-center"}>
                                                                {product.items[itemIndex].configuration.totalWidth.selected.value}
                                                            </div>
                                                            {
                                                                item.configuration.totalWidth.formError.isValid
                                                                    ? null
                                                                    : <Badge color={"danger"}>
                                                                        {item.configuration.totalWidth.formError.message}
                                                                    </Badge>
                                                            }

                                                        </td>
                                                        : null
                                                    }

                                                    <td className="align-middle">
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            <Input type="number" name="width"
                                                                   invalid={!subItem.configuration.width.formError.isValid}
                                                                   value={subItem.configuration.width.selected.value}
                                                                   min={subItem.configuration.width.min}
                                                                   max={subItem.configuration.width.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, subItemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="width"/>
                                                            {/*<ApplySelectionToMultipleItems
                                                                handleApplyToAll={this.handleApplyToAll}
                                                                change={subItem.configuration.width.selected.value}
                                                                variableKey={"width"}
                                                                itemIndex={itemIndex}
                                                                subItemIndex={subItemIndex}
                                                                includeItem={true}
                                                                includeSubItem={false}
                                                                isDisabled={false}
                                                            />*/}
                                                        </div>
                                                        {
                                                            subItem.configuration.width.formError.isValid
                                                                ? null
                                                                : <Badge color={"danger"}>
                                                                    {subItem.configuration.width.formError.message}
                                                                </Badge>
                                                        }
                                                        {subItemIndex === 0
                                                            ? <div
                                                                className={"mt-2"}>
                                                                <Button
                                                                    title={"Heavy duty keyway"}
                                                                    size={"sm"}
                                                                    color={"link"}
                                                                    disabled={!item.configuration.heavyDuty.selected.isSelectable}
                                                                    onClick={() => this.handleChange(!item.configuration.heavyDuty.selected.value, "heavyDuty", itemIndex, subItemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.heavyDuty.selected.value,
                                                                            "fa-square-o": !item.configuration.heavyDuty.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                <span className={"mt-1"}>HD Keyway</span>
                                                                {/*<ApplySelectionToMultipleItems
                                                                    handleApplyToAll={this.handleApplyToAll}
                                                                    change={item.configuration.heavyDuty.selected.value}
                                                                    variableKey={"heavyDuty"}
                                                                    itemIndex={itemIndex}
                                                                    subItemIndex={subItemIndex}
                                                                    includeItem={true}
                                                                    includeSubItem={false}
                                                                    isDisabled={false}
                                                                />*/}
                                                            </div>
                                                            : null
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                        <div
                                                            className={classnames("d-flex justify-content-between align-items-center", {"mb-4 pb-3": subItemIndex === 0})}>
                                                            <Input type="number" name="drop"
                                                                   invalid={!subItem.configuration.drop.formError.isValid}
                                                                   value={subItem.configuration.drop.selected.value}
                                                                   min={subItem.configuration.drop.min}
                                                                   max={subItem.configuration.drop.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, subItemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="drop"/>
                                                            {/*<ApplySelectionToMultipleItems
                                                                handleApplyToAll={this.handleApplyToAll}
                                                                change={subItem.configuration.drop.selected.value}
                                                                variableKey={"drop"}
                                                                itemIndex={itemIndex}
                                                                subItemIndex={subItemIndex}
                                                                includeItem={true}
                                                                includeSubItem={false}
                                                                isDisabled={false}
                                                            />*/}
                                                        </div>
                                                        {
                                                            subItem.configuration.drop.formError.isValid
                                                                ? null
                                                                : <Badge color={"danger"}>
                                                                    {subItem.configuration.drop.formError.message}
                                                                </Badge>
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            <Input type="select" name="fabricType"
                                                                   value={subItem.configuration.fabricType.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, subItemIndex, true)}>
                                                                <option value={""} disabled>Select type</option>
                                                                {
                                                                    (subItem.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            <ApplySelectionToMultipleItems
                                                                handleApplyToAll={this.handleApplyToAll}
                                                                change={subItem.configuration.fabricType.selected.dropdownValue}
                                                                variableKey={"fabricType"}
                                                                itemIndex={itemIndex}
                                                                subItemIndex={subItemIndex}
                                                                includeItem={true}
                                                                includeSubItem={false}
                                                                isDisabled={false}
                                                            />
                                                        </div>
                                                        <div
                                                            className={"mt-2 d-flex justify-content-between align-items-center"}>
                                                            <Input type="select" name="fabricColor"
                                                                   value={subItem.configuration.fabricColor.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "fabricColor", itemIndex, subItemIndex, true)}>
                                                                <option value={""} disabled>Select color</option>
                                                                {
                                                                    (subItem.configuration.fabricColor.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            {/*<div className={"flex-fill"}>
                                                                <Select
                                                                    value={subItem.configuration.fabricColor.selected.value}
                                                                    onChange={(selectedOption) => this.handleChange(selectedOption.optionKey, "fabricColor", itemIndex, subItemIndex, true)}
                                                                    options={[{
                                                                        isDisabled: true,
                                                                        optionKey: "SelectColor",
                                                                        optionLabel: "Select color",
                                                                        value: "",
                                                                        label: (<div key={"SelectColor"}>
                                                                            <span>Select color</span>
                                                                        </div>)
                                                                    }, ...subItem.configuration.fabricColor.finalOptions]}
                                                                />
                                                            </div>*/}
                                                            <ApplySelectionToMultipleItems
                                                                handleApplyToAll={this.handleApplyToAll}
                                                                change={subItem.configuration.fabricColor.selected.dropdownValue}
                                                                variableKey={"fabricColor"}
                                                                itemIndex={itemIndex}
                                                                subItemIndex={subItemIndex}
                                                                includeItem={true}
                                                                includeSubItem={false}
                                                                isDisabled={false}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            <Input type="select" name="rollDirection"
                                                                   disabled={!subItem.configuration.rollDirection.selected.isSelectable}
                                                                   value={subItem.configuration.rollDirection.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "rollDirection", itemIndex, subItemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (subItem.configuration.rollDirection.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                    </td>

                                                    <td className="align-middle">
                                                        <div
                                                            className={"d-flex justify-content-center align-items-center"}>
                                                            {
                                                                (subItem.configuration.drivenByBlindSequence.selected.value === subItem.configuration.blindSequence.selected.value)
                                                                    ? <Input type="select" name="controlSide"
                                                                             disabled={!subItem.configuration.controlSide.selected.isSelectable}
                                                                             value={subItem.configuration.controlSide.selected.dropdownValue}
                                                                             onChange={(e) => this.handleChange(e.target.value, "controlSide", itemIndex, subItemIndex, true)}>
                                                                        <option value={""} disabled>Select</option>
                                                                        {
                                                                            (subItem.configuration.controlSide.finalOptions || []).map((o, oIndex) => {
                                                                                return <option
                                                                                    key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                    : <div>N/A</div>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        {
                                                            (subItem.configuration.drivenByBlindSequence.selected.value === subItem.configuration.blindSequence.selected.value)
                                                                ? <div>
                                                                    <div
                                                                        className={"d-flex justify-content-between align-items-center"}>
                                                                        <Input type="select" name="chainColor"
                                                                               disabled={!product.items[itemIndex].subItems[subItemIndex].configuration.chainColor.selected.isSelectable}
                                                                               value={subItem.configuration.chainColor.selected.dropdownValue}
                                                                               onChange={(e) => this.handleChange(e.target.value, "chainColor", itemIndex, subItemIndex, true)}>
                                                                            <option value={""} disabled>Select color</option>
                                                                            {
                                                                                (subItem.configuration.chainColor.finalOptions || []).map((o, oIndex) => {
                                                                                    return <option
                                                                                        key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </div>
                                                                    <div
                                                                        className={"mt-2 d-flex justify-content-between align-items-center"}>
                                                                        <Input type="select" name="chainLength"
                                                                               disabled={!product.items[itemIndex].subItems[subItemIndex].configuration.chainLength.selected.isSelectable}
                                                                               value={subItem.configuration.chainLength.selected.dropdownValue}
                                                                               onChange={(e) => this.handleChange(e.target.value, "chainLength", itemIndex, subItemIndex, true)}>
                                                                            <option value={""} disabled>Select length</option>
                                                                            {
                                                                                (subItem.configuration.chainLength.finalOptions || []).map((o, oIndex) => {
                                                                                    return <option
                                                                                        key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </div>
                                                                </div>
                                                                : <div
                                                                    className={"d-flex justify-content-center align-self-center"}>
                                                                    N/A
                                                                </div>
                                                        }

                                                    </td>
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div
                                                                className={"d-flex justify-content-between align-items-center"}>
                                                                <div>
                                                                    <Input type="select" name="mount"
                                                                           disabled={!item.configuration.mount.selected.isSelectable}
                                                                           value={item.configuration.mount.selected.dropdownValue}
                                                                           onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, subItemIndex, true)}>
                                                                        <option value={""} disabled>Select</option>
                                                                        {
                                                                            (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                                return <option
                                                                                    key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                    {/*<div>
                                                                        <Button color={"link"}
                                                                                title={"Bracket covers"}
                                                                                disabled={!item.configuration.bracketCovers.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.bracketCovers.selected.value, "bracketCovers", itemIndex, subItemIndex, true)}>
                                                                            <i className={classnames("fa", "fa-lg", "fa-fw", {
                                                                                    "fa-square-o": !item.configuration.bracketCovers.selected.value,
                                                                                    "fa-check-square-o": item.configuration.bracketCovers.selected.value,
                                                                                }
                                                                            )}/>
                                                                        </Button>
                                                                        Bracket covers
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div>
                                                                <div>
                                                                    <Input type="select" name="bracketColor"
                                                                           disabled={!item.configuration.bracketColor.selected.isSelectable}
                                                                           value={item.configuration.bracketColor.selected.dropdownValue}
                                                                           onChange={(e) => this.handleChange(e.target.value, "bracketColor", itemIndex, subItemIndex, true)}>
                                                                        <option value="">Select</option>
                                                                        {
                                                                            (item.configuration.bracketColor.finalOptions || []).map((o, oIndex) => {
                                                                                return <option
                                                                                    key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    <td className="align-middle">
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            <Input type="select" name="bottomRailType"
                                                                   value={subItem.configuration.bottomRailType.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "bottomRailType", itemIndex, subItemIndex, true)}>
                                                                <option value={""} disabled={true}>Select type</option>
                                                                {
                                                                    (subItem.configuration.bottomRailType.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                        <div
                                                            className={"mt-2 d-flex justify-content-between align-items-center"}>
                                                            <Input type="select" name="bottomRailColor"
                                                                   value={subItem.configuration.bottomRailColor.selected.dropdownValue}
                                                                   disabled={!subItem.configuration.bottomRailColor.selected.isSelectable}
                                                                   onChange={(e) => this.handleChange(e.target.value, "bottomRailColor", itemIndex, subItemIndex, true)}>
                                                                <option value={""} disabled={true}>Select color</option>
                                                                {
                                                                    (subItem.configuration.bottomRailColor.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                    </td>
                                                    {
                                                        isHaymanAndNoosa
                                                            ? <td className="align-middle">
                                                                <Input type="select" name="accessoryBoxColour"
                                                                       value={subItem.configuration.accessoryBoxColour.selected.dropdownValue}
                                                                       disabled={!subItem.configuration.accessoryBoxColour.selected.isSelectable}
                                                                       onChange={(e) => this.handleChange(e.target.value, "accessoryBoxColour", itemIndex, subItemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select color</option>
                                                                    {
                                                                        (subItem.configuration.accessoryBoxColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </td>
                                                            : null

                                                    }


                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div className="text-center">
                                                                <NumberFormat
                                                                    prefix={'$'}
                                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}/>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div className="text-center">
                                                                <NumberFormat
                                                                    prefix={'$'}
                                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}/>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    {subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div className="text-center">
                                                                <Button color={"link"}
                                                                        title={"click here to copy item"}
                                                                        onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                                    <i className="fa fa-clone fa-lg"
                                                                       aria-hidden="true"/>
                                                                </Button>
                                                                <span className={"text-muted"}>|</span>
                                                                <Button color={"link"}
                                                                        title={"click here to delete"}
                                                                        onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                                    <i className="fa fa-trash-o fa-lg text-danger"
                                                                       aria-hidden="true"/>
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                    {subItemIndex === 0 && !currentUser.isExternalUser
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }
                                                </tr>
                                            });
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={18}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={3}/>
                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                        </div>

                        :
                        null

                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
                {
                    workingBOMModal.isOpen
                        ? <RollerBlindSingleBOMModal
                            order={order}
                            itemIndex={workingBOMModal.itemIndex}
                            product={product}
                            isOpen={workingBOMModal.isOpen}
                            toggle={this.toggleBOMModal}
                            convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                        />
                        : null
                }
                {
                    isOpenConsolidatedBOMModal
                        ? <RollerBlindSingleConsolidatedBOMModal
                            order={order}
                            product={product}
                            isOpen={isOpenConsolidatedBOMModal}
                            toggle={this.toggleConsolidatedBOMModal}/>
                        : null
                }
            </div>
        )
    }
};
