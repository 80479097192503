import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string';
import StockInventoryTransactionTypeService from '../../services/StockInventoryTransactionTypeService';
import {handleErrorMessage} from "../../services/CommonService";

class ManageStockInventoryTransactionType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            stockInventoryTransactionTypeObj: {
                name: '',
                description: '',
            },
            FormErrors: {},
            stockInventoryTransactionTypes: [],
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        let { name } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            name = searchParams.name;
            this.setState({ name }, () => { this.refresh() })
        }
    }

    refresh() {
        if (this.state.name && this.state.name !== '') {
            this.setState({ loading: true })
            StockInventoryTransactionTypeService.getOneStockInventoryTransactionType(this.state.name).then(response => {
                let stockInventoryTransactionTypeObj = response.data;
                this.setState({
                    stockInventoryTransactionTypeObj: stockInventoryTransactionTypeObj,
                    loading: false
                });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }


    handleChange(e) {
        const { name, value } = e.target;
        let { stockInventoryTransactionTypeObj } = this.state;

        const type = e.target.type;
        if (type === "checkbox") {
            stockInventoryTransactionTypeObj[name] = e.target.checked;
        }
        else {
            stockInventoryTransactionTypeObj[name] = value;
        }
        this.setState({ stockInventoryTransactionTypeObj });
    }

    handleCancel() {
        this.props.history.push('/inventory/StockInventoryTransactionType');
    }

    validateForm(stockInventoryTransactionTypeObj) {
        // true means invalid
        let formErrors = {};
        let valid = true;
        
            if(stockInventoryTransactionTypeObj.name.length === 0) {
                formErrors.name = 'Please enter name';
                valid = false;
            }

        if (stockInventoryTransactionTypeObj.description.length === 0) {
            formErrors.description = 'Please enter description';
            valid = false;
        }
        return { formErrors: formErrors,valid: valid };
            
        
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.stockInventoryTransactionTypeObj };
      
        let result = this.validateForm(req);
        if (!result.valid) {
            this.setState({ FormErrors: result.formErrors });
            return;
        }


        if (result.valid) {
            if (this.state.name && this.state.name !== '') {
                this.setState({ saving: true });
                StockInventoryTransactionTypeService.updateStockInventoryTransactionType(this.state.name,req).then(response => {
                    this.setState({ saving: false });
                    this.props.history.push('/inventory/stockInventoryTransactionType');
                    toast.success("Stock inventory transaction type updated successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }).catch(error => {
                    this.setState({ saving: false });
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
            else {
                this.setState({ saving: true });
                StockInventoryTransactionTypeService.addStockInventoryTransactionType(req).then(response => {
                    if (response.status === 200 || response.status === "200") {
                        this.props.history.push('/inventory/stockInventoryTransactionType');
                        toast.success("Stock inventory transaction type saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({ saving: false });
                    }
                }).catch(error => {
                    this.setState({ saving: false });
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
        }
        else {
            this.setState({ saving: false });
            toast.info("Please fill required details.. !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }

    }

    render() {
        let { stockInventoryTransactionTypeObj, FormErrors, loading, name } = this.state;
        const errors = FormErrors;
        let err = Object.keys(errors).filter(x => errors[x]);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/stockInventoryTransactionType')}>Stock Inventory Transaction Type</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{name == '' ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Stock Inventory Transaction Type</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input
                                                invalid={FormErrors.name}
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                value={stockInventoryTransactionTypeObj.name}
                                                onChange={this.handleChange}
                                                placeholder="write stock inventory transaction type name here..." />
                                            <FormText color="danger">{FormErrors.name}</FormText>
                                        </FormGroup>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                invalid={FormErrors.description}
                                                className="form-control"
                                                type="textarea"
                                                name="description"
                                                value={stockInventoryTransactionTypeObj.description}
                                                onChange={(e) => this.handleChange(e)}
                                                placeholder="write stock inventory transaction type description here..." />
                                            <FormText color="danger">{FormErrors.description}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving} >

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}

export default withRouter(ManageStockInventoryTransactionType)