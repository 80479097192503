import React, { Component } from 'react';
import SalesQuoteService from "../../../services/sales/SalesQuoteService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from "react-number-format";
import { Card, CardBody, Table, Row, Spinner } from 'reactstrap';
import {handleErrorMessage} from "../../../services/CommonService";


export default class QuotesOverviewComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quotesByProductGroup_list: [],
            quotesByRepArea_list: [],
            isloading: false
        };

        this.salesQuoteService = new SalesQuoteService();
        this.getSalesOrderQuotesOverview = this.getSalesOrderQuotesOverview.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.getSalesOrderQuotesOverview();
    }

    getSalesOrderQuotesOverview() {
        this.setState({ isloading: true });
        this.salesQuoteService.fetchQuotesOverview().then(response => {
            //console.log(response, 'response');
            if (response.status === 200 || response.status === "200") {
                if (response.data) {
                    let data = response.data;
                    response.data.quotesByRepArea_list = response.data.quotesByRepArea_list.sort((x, y) => { return x.repId - y.repId });

                    this.setState({
                        quotesByProductGroup_list: data.quotesByProductGroup_list, quotesByRepArea_list: data.quotesByRepArea_list,
                        isloading: false
                    });
                }
            }
        }).catch(error => {
            this.setState({ isloading: false });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }

    render() {
        let { isloading, quotesByProductGroup_list, quotesByRepArea_list } = this.state;
        return (
            <div>
                <div>
                    <h5>By Rep Area</h5>
                    <div className="">
                        {isloading
                            ? <Spinner color={"primary"} />
                            : <div>
                                <Table responsive={true} hover={true} striped={true} bordered={true}>
                                    <thead>
                                        <tr>
                                            <th>Rep #</th>
                                            <th>Rep Name</th>
                                            <th>Rep Area Description</th>
                                            <th>Quote Qty</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(quotesByRepArea_list || []).map((item, index) => {
                                            return <tr key={index} className={"hand-cursor"} className={(index % 2 === 0) ? "table_Stripping" : ""}>
                                                <td>{item.repCode}</td>
                                                <td>{item.repName}</td>
                                                <td>{item.rep_Area_Description}</td>
                                                <td className={"text-right"}>{item.quoteCount}</td>
                                                <td className={"text-right"}>
                                                    <span><NumberFormat value={Math.floor(item.netInvoice)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} /></span>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </div>
                </div>
                <hr />

                <div>
                    <h5>By Product</h5>
                    <div className="">
                        {isloading
                            ? <Spinner color={"primary"} />
                            : <div>
                                <Table responsive={true} hover={true} striped={true} bordered={true} >
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Quote Qty</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(quotesByProductGroup_list || []).map((item, index) => {
                                            return <tr key={index} className={"hand-cursor"} className={(index % 2 === 0) ? "table_Stripping" : ""}>
                                                <td>{item.productName}</td>
                                                <td className={"text-right"}>{item.quoteQty}</td>
                                                <td className={"text-right"}>
                                                    <span><NumberFormat value={Math.floor(item.netInvoice)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} /></span>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}