import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Card, CardBody, CardFooter, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Badge,
    Table, Breadcrumb, BreadcrumbItem, CardHeader
} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import CustomerService from '../../services/CustomerService';
import { handleErrorMessage, validateEmail, getDateString } from '../../services/CommonService';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';

export default class CustomerInvitePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerInviteData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    filterRequest: {
                        email: '',
                        status: '',
                        invitedBy: '',
                        accountId: '',
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                    ,
                }
            },
            loading: true,

        }
        this.customerService = new CustomerService();
        this.handleChange = this.handleChange.bind(this);
        this.getCustomerInvitationHeading = this.getCustomerInvitationHeading.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);

    }


    handleAccountChange(accountId) {
        let { customerInviteData } = this.state;
        customerInviteData.request.filterRequest.accountId = accountId;
        customerInviteData.request.pageRequest.currentPage = 1;
        this.getCustomerInvitationList(customerInviteData);
        this.setState({ customerInviteData });
    }
    componentDidMount() {
        this.getCustomerInvitationList(this.state.customerInviteData);
    }

    getCustomerInvitationList(customerInviteData) {
        this.setState({ loading: true });
        this.customerService.getCustomerInviteList(customerInviteData.request).then(response => {
            customerInviteData.response = response.data;
            this.setState({ customerInviteData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });

        })
    }

    getCustomerInvitationHeading({ filterRequest }) {
        let customerInvitationHeading = [
            {
                key: "accountID",
                label: "Account Id",
                type: "link",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <SearchDebtorAcccount handleAccountChange={this.handleAccountChange}
                    selectedAccountID={filterRequest.accountId}
                    defaultAccountID={filterRequest.accountId}
                    includeChildren={true}
                    excludeClosedandInactive={false} />

            },
            {
                key: "companyName",
                label: "Company Name",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
            },
            {
                key: "email",
                label: "Email",
                type: "email",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="email" name="email"
                    value={filterRequest.email}
                    onChange={(e) => this.handleChange(e.target.value, "email")}
                    placeholder="Search..." /></div>
            },
            {
                key: "status",
                label: "Status",
                type: "status",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type={"select"} id="status" name={"status"}
                    value={filterRequest.status}
                    onChange={(e) => this.handleChange(e.target.value, "status")}>
                    <option value={""}>All</option>
                    <option key="INVITED" value="INVITED">INVITED</option>
                    <option key="REGISTERED" value="REGISTERED">REGISTERED</option>

                </Input>
                </div>
            },
            {
                key: "invitedDate",
                label: "Invited Date",
                type: "date",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "invitedBy",
                label: "Invited By",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="invitedBy" name="invitedBy"
                    value={filterRequest.invitedBy}
                    onChange={(e) => this.handleChange(e.target.value, "invitedBy")}
                    placeholder="Search..." /></div>
            },

        ]
        return customerInvitationHeading;
    }

    handleChange(change, key) {
        let { customerInviteData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (customerInviteData.request.sortRequest.key === change) {
                    customerInviteData.request.sortRequest.direction = !customerInviteData.request.sortRequest.direction;
                } else {
                    customerInviteData.request.sortRequest.key = change;
                    customerInviteData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getCustomerInvitationList(customerInviteData);
                }
                break;
            case "pageSize":
                customerInviteData.request.pageRequest[key] = change;
                customerInviteData.request.pageRequest.currentPage = 1;
                this.getCustomerInvitationList(customerInviteData);
                break;
            case "currentPage":
                customerInviteData.request.pageRequest[key] = change;
                this.getCustomerInvitationList(customerInviteData);
                break;


            default:
                customerInviteData.request.filterRequest[key] = change;


                customerInviteData.request.pageRequest.currentPage = 1;
                this.getCustomerInvitationList(customerInviteData);
                this.setState({ customerInviteData });
        }

    }

    getColValue(item, heading) {
        let value = item[heading.key];
        switch (heading.type) {
            case "link":
                return <div className="text-center"><Link
                    className="btn btn-sm btn-primary"
                    style={{ color: "white" }}
                    title={"Click here to see account details"}
                    to={"/customer/#" + value}>{value}</Link></div>
                break;
            case "email":
                if (item.email) {
                    if (validateEmail(item.email)) {
                        return <a href={"mailto:" + item.email}>{item.email}</a>;
                    }
                    return <div className="text-center"> <span className="text-center">{value}</span></div>
                }
                break;
            case "status":
                return <div className="text-center">
                    {item.status === 'INVITED' ?
                        <Badge color="success">
                            <i className="fa fa-check mr-2" aria-hidden="true" />
                            {value}
                        </Badge>
                        :
                        <Badge color="warning">
                            <i className="fa fa-user-circle-o mr-2" aria-hidden="true" />
                            {value}
                        </Badge>
                    }
                </div>
                break;
            case "date":
                return <div><span className="text-center">{getDateString(value, "DD/MM/YYYY")}</span></div>;
            default:
                return <div><span className="text-center">{value}</span></div>;
        }
    }
    render() {
        let { customerInviteData, loading } = this.state;
        let { pageRequest, sortRequest } = customerInviteData.request;
        let customerInvitationHeadling = this.getCustomerInvitationHeading(customerInviteData.request);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Customer Invite</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Customer Invite</h5>
                                </div>
                            </Col>

                        </Row>
                    </CardHeader>
                    <CardBody>

                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        <th className="align-middle">S.No</th>
                                        {(customerInvitationHeadling || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td></td>
                                        {(customerInvitationHeadling || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(customerInviteData.response.records || []).map((item, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            {(customerInvitationHeadling || []).map((heading, headingindex) => {
                                                return (<td key={headingindex}>
                                                    {this.getColValue(item, heading)}
                                                </td>)
                                            })}

                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={customerInviteData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}