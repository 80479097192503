import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import creditorService from '../../services/purchase/CreditorService';
import AddressService from '../../services/AddressService';
import BankDetail from '../../components/Creditor/BankDetail';
import CreditorDetail from '../../components/Creditor/CreditorDetail';
import SearchCity from '../../components/search/SearchCity';
import SearchState from '../../components/search/SearchState';
import SearchPostcode from '../../components/search/SearchPostcode';
import { validateEmail, handleErrorMessage } from '../../services/CommonService';


export default class ManageCreditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditorID: '',
            creditorObj: {
                creditorID: 0,
                accountID: '',
                company: "",
                addressID: null,
                contactID: null,
                IsActive: true,
                bankDetail: {
                    id: 0,
                    accountName: "",
                    bankName: "",
                    bankSuburb: "",
                    accountNumber: "",
                },
                creditorDetail: {
                    id: 0,
                    isLocal: false,
                    currency: "",
                },
                address: {
                    addressID: 0,
                    address1: '',
                    address2: '',
                    address3: '',
                    address4: '',
                    city: '',
                    state: '',
                    postCode: '',
                    phone: '',
                    fax: '',
                    country: 'AUSTRALIA',
                    contactName: '',
                },
                contact: {
                    contactID: 0,
                    contactTypeID: 0,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    fax: '',
                    mobile: '',
                    email: '',
                    isPrimary: true
                }

            },
            contactTypes:[],
            formErrors: {},
            saving: false,
            loading: false,
            loadingAddress: false,
            loadingContacts: false,
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getCreditor = this.getCreditor.bind(this);
        this.handleBankDetailChange = this.handleBankDetailChange.bind(this);
        this.handleCreditorDetailChange = this.handleCreditorDetailChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.fetchContactTypes = this.fetchContactTypes.bind(this);



    }

    componentDidMount() {
        let { creditorID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            creditorID = searchParams.creditorID;
            this.setState({ creditorID }, () => { this.refresh(creditorID) })
        }
        this.fetchContactTypes();
    }

    refresh(creditorID) {
        this.getCreditor(creditorID);
    }

    fetchContactTypes() {
        AddressService.getContactTypes().then(response => {
            this.setState({ contactTypes: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        });
    }

    getCreditor(creditorID) {
        if (creditorID) {
            this.setState({ loading: true });
            creditorService.getOneCreditor(creditorID).then(response => {
                this.setState({ creditorObj: response.data, loading: false })
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }

    }

    handleChange(change, key) {
        let { creditorObj } = this.state;
        creditorObj[key] = change;

        this.setState({ creditorObj });
    }

    handleCancel() {
        this.props.history.push('/inventory/keyway/creditors');
    }

    validateForm(creditorObj) {
        let formErrors = {};
        let valid = true;

        if (creditorObj.company === null || creditorObj.company === '') {
            formErrors.company = "Please enter company name";
            valid = false;
        }

        if (creditorObj.accountID === null || creditorObj.accountID === '') {
            formErrors.accountID = "Please select accountID";
            valid = false;
        }

        if (creditorObj.bankDetail.accountName === null || creditorObj.bankDetail.accountName === '') {
            formErrors.accountName = "Please enter account name";
            valid = false;
        }

        if (creditorObj.bankDetail.accountNumber === null || creditorObj.bankDetail.accountNumber === '') {
            formErrors.accountNumber = "Please enter account number";
            valid = false;
        }

        if (creditorObj.bankDetail.bankName === null || creditorObj.bankDetail.bankName === '') {
            formErrors.bankName = "Please enter bank name";
            valid = false;
        }

        if (creditorObj.bankDetail.bankSuburb === null || creditorObj.bankDetail.bankSuburb === '') {
            formErrors.bankSuburb = "Please enter bank suburb";
            valid = false;
        }

        if (creditorObj.creditorDetail.currency === null || creditorObj.creditorDetail.currency === '') {
            formErrors.currency = "Please enter currency";
            valid = false;
        }

        if (creditorObj.address.contactName === null || (creditorObj.address.contactName !== null && creditorObj.address.contactName.length === 0)) {
            formErrors.contactName = "Please enter contact name";
            valid = false;
        }

        if ((creditorObj.address.contactName !== null && creditorObj.address.contactName !== "") && creditorObj.address.contactName.length < 3) {
            formErrors.contactName = "Contact Person Name must be 3 character long";
            valid = false;
        }

        if ((creditorObj.address.phone !== null && creditorObj.address.phone !== "") && creditorObj.address.phone.length < 8) {
            formErrors.phone = "The Phone number must be of at least 8 characters";
            valid = false;
        }

        if (creditorObj.address.address1 === null || (creditorObj.address.address1 !== null && creditorObj.address.address1.length === 0)) {
            formErrors.address1 = "Please enter address1";
            valid = false;
        }

        if (creditorObj.contact.contactTypeID == 0 || creditorObj.contact.contactTypeID == '0' || creditorObj.contact.contactTypeID == null) {
            formErrors.contactTypeID = "Please select contact type";
            valid = false;
        }

        if (creditorObj.contact.firstName == null || creditorObj.contact.firstName == "" || creditorObj.contact.firstName == undefined) {
            formErrors.firstName = "Please enter first name";
            valid = false;
        }

        if (creditorObj.contact.email != null && creditorObj.contact.email != "" && creditorObj.contact.email != undefined) {
            let isValidEmail = validateEmail(creditorObj.contact.email);
            if (!isValidEmail) {
                formErrors.email = "Please enter valid email";
                valid = false;
            }
        }

        if (creditorObj.contact.mobile != null && creditorObj.contact.mobile != "" && creditorObj.contact.mobile != undefined) {
            if (creditorObj.contact.mobile.length < 8) {
                formErrors.mobile = "Please enter valid mobile no"
                valid = false;
            }
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.creditorObj };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });

            creditorService.saveOrUpdateCreditor(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (req.id) {
                        toast.success("Supplier updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        this.props.history.push('/inventory/keyway/creditors');
                        toast.success("Supplier saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    this.setState({ saving: false, creditorObj: response.data });
                }

            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleCreditorDetailChange(data) {
        this.setState({ creditorObj: data });
    }

    handleBankDetailChange(data) {
        this.setState({ creditorObj: data });
    }

    handleAddressChange(change, key) {
        let { creditorObj } = this.state;
        let { address } = creditorObj;
        address[key] = change;

        this.setState({ creditorObj });
    }

    handleContactChange(change, key) {
        let { creditorObj } = this.state;
        let { contact } = creditorObj;
        contact[key] = change;

        this.setState({ creditorObj });
    }

    getName(item) {
        var fullName = '';
        fullName = item.firstName;

        if (item.middleInitial) {
            fullName = fullName + " " + item.middleInitial;
        }

        if (item.lastName) {
            fullName = fullName + " " + item.lastName;
        }

        return fullName;
    }

    getAddress(address) {
        var fullAddress = "";
        fullAddress = (address.address1 ? address.address1 + ", " : "")
            + (address.address2 ? address.address2 + ", " : "")
            + (address.address3 ? address.address3 + ", " : "")
            + (address.address4 ? address.address4 + ", " : "")
            + (address.city ? address.city + ", " : "")
            + (address.state ? address.state + ", " : "")
            + (address.postCode ? address.postCode : "");

        return fullAddress;
    }

    render() {
        let { creditorID, creditorObj, formErrors, loading, contacts, addresses, loadingContacts, loadingAddress, contactTypes } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/keyway/creditors')}>Suppliers</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{creditorID === '' ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Supplier</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Account #</Label>
                                            {creditorID ?<Input
                                                    disabled
                                                    name="accountID"
                                                    type="text"
                                                    value={(creditorObj.accountID+"  |  "+ creditorObj.company )|| ''}
                                                />
                                                :
                                                <Input
                                                    invalid={formErrors.accountID !== undefined}
                                                    name="accountID"
                                                    type="text"
                                                    value={creditorObj.accountID || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, 'accountID')}
                                                    placeholder="write account id here..." />
                                            }
                                            <FormText color="danger">{formErrors.accountID}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Company</Label>
                                            <Input
                                                invalid={formErrors.company}
                                                name="company"
                                                type="text"
                                                value={creditorObj.company || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'company')}
                                                placeholder="write company here..." />
                                            <FormText color="danger">{formErrors.company}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <strong>Address</strong>
                                <br />
                                <Row>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="contactName">Contact Name</Label>
                                                <Input autoComplete="off" type="text" name="contactName"
                                                    invalid={formErrors.contactName !== undefined}
                                                    onChange={(e) => this.handleAddressChange(e.target.value, 'contactName')}
                                                    value={creditorObj.address.contactName}
                                                    placeholder="Enter Contact Person here." />
                                                {formErrors.contactName &&
                                                    <FormText
                                                        color="danger"> {formErrors.contactName}</FormText>}
                                            </FormGroup>
                                        </Col>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="phone">Phone</Label>
                                                <Input autoComplete="off" type="number" name="phone"
                                                onChange={(e) => this.handleAddressChange(e.target.value, 'phone')}
                                                    value={creditorObj.address.phone}
                                                    placeholder="Enter phone number here." id="Phone" />

                                            </FormGroup>
                                        </Col>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="fax">Fax</Label>
                                                <Input autoComplete="off" type="number" name="fax"
                                                onChange={(e) => this.handleAddressChange(e.target.value, 'fax')}
                                                    value={creditorObj.address.fax}
                                                    placeholder="Enter Fax no here." id="Fax" />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="address1">Address 1</Label>
                                                <Input autoComplete="off"
                                                    invalid={formErrors.address1 !== undefined}
                                                    type="text"
                                                    name="address1"
                                                onChange={(e) => this.handleAddressChange(e.target.value, 'address1')}
                                                    value={creditorObj.address.address1}
                                                    placeholder="Address1" id="Address1" />
                                                {formErrors.address1 != null > 0 &&
                                                    <FormText
                                                        color={"danger"}> {formErrors.address1}</FormText>}
                                            </FormGroup>
                                        </Col>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>

                                            <FormGroup>
                                                <Label for="address2">Address 2</Label>
                                                <Input autoComplete="off" type="text" name="address2"
                                                onChange={(e) => this.handleAddressChange(e.target.value, 'address2')}
                                                    value={creditorObj.address.address2}
                                                    placeholder="Address2" id="Address2" />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="address3">Address 3</Label>
                                                <Input autoComplete="off" type="text" name="address3"
                                                onChange={(e) => this.handleAddressChange(e.target.value, 'address3')}
                                                    value={creditorObj.address.address3}
                                                    placeholder="Address3" id="Address3" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="address4">Address 4</Label>
                                                <Input autoComplete="off" type="text" name="address4"
                                                onChange={(e) => this.handleAddressChange(e.target.value, 'address4')}
                                                    value={creditorObj.address.address4}
                                                    placeholder="Address4" id="Address4" />
                                            </FormGroup>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="country">Country</Label>
                                                <Input autoComplete="off" type="text" name="country"
                                                onChange={(e) => this.handleAddressChange(e.target.value, 'country')}
                                                    value={creditorObj.address.country}
                                                    placeholder="Country" id="Country" disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <FormGroup>
                                            <Label for="City">City</Label>
                                            <SearchCity
                                                handleSelection={(selectedCity) => this.handleAddressChange(selectedCity, "city")}
                                                    selected={creditorObj.address.city || ''}
                                                    defaultSelected={''}
                                                    filters={{ state: creditorObj.address.state || '', postcode: creditorObj.address.postCode || '' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <FormGroup>
                                            <Label for="State">State</Label>
                                            <SearchState
                                                handleSelection={(selectedState) => this.handleAddressChange(selectedState, "state")}
                                                    selected={creditorObj.address.state || ''}
                                                    defaultSelected={''}
                                                    filters={{ city: creditorObj.address.city || '', postcode: creditorObj.address.postCode || '' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>

                                            <FormGroup>
                                            <Label for="Postcode">Postcode</Label>
                                            <SearchPostcode
                                                handleSelection={(selectedPostcode) => this.handleAddressChange(selectedPostcode, "postCode")}
                                                    selected={creditorObj.address.postCode || ''}
                                                    defaultSelected={''}
                                                    filters={{ city: creditorObj.address.city || '', state: creditorObj.address.state || '' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                <hr />
                                <strong>Contact</strong>
                                <br />
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="firstName">First name</Label>
                                            <Input autoComplete="off"
                                                invalid={formErrors.firstName !== undefined}
                                                type="text"
                                                name="firstName"
                                                onChange={(e) => this.handleContactChange(e.target.value, 'firstName')}
                                                value={creditorObj.contact.firstName} placeholder="Enter first name here."
                                                id="FirstName" />
                                            {formErrors.firstName != null &&
                                                <FormText color="danger"> {formErrors.firstName}</FormText>}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="lastName">Last name</Label>
                                            <Input autoComplete="off" type="text" name="lastName"
                                                onChange={(e) => this.handleContactChange(e.target.value, 'lastName')}
                                                value={creditorObj.contact.lastName} placeholder="Enter last name here."
                                                id="LastName" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Contact Type</Label>
                                            <Input className="form-control"
                                                invalid={formErrors.contactTypeID !== undefined}
                                                type="select"
                                                value={creditorObj.contact.contactTypeID}
                                                onChange={(e) => this.handleContactChange(e.target.value, 'contactTypeID')}
                                                name="contactTypeID"
                                                placeholder="Select Contact Type...">
                                                <option key={100} value={0}>Select Contact Type</option>
                                                {(contactTypes || []).map((item, indexx) => <option key={indexx}
                                                    value={item.contactTypeID}>{item.name}</option>)}
                                            </Input>
                                            {formErrors.contactTypeID != null &&
                                                <FormText color="danger"> {formErrors.contactTypeID}</FormText>}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="jobTitle">Position</Label>
                                            <Input autoComplete="off" type="text" name="jobTitle"
                                                onChange={(e) => this.handleContactChange(e.target.value, 'jobTitle')}
                                                value={creditorObj.contact.jobTitle} placeholder="Enter position here."
                                                id="JobTitle" />
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="phone">Phone</Label>
                                            <Input autoComplete="off" type="number" name="phone"
                                                onChange={(e) => this.handleContactChange(e.target.value, 'phone')}
                                                value={creditorObj.contact.phone} placeholder="Enter phone number here."
                                                id="Phone" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="mobile">Mobile</Label>
                                            <Input autoComplete="off"
                                                invalid={formErrors.mobile !== undefined}
                                                type="number"
                                                name="mobile"
                                                onChange={(e) => this.handleContactChange(e.target.value, 'mobile')}
                                                value={creditorObj.contact.mobile} placeholder="Enter mobile number here."
                                                id="Mobile" />
                                            {formErrors.mobile != null > 0 &&
                                                <FormText color="danger"> {formErrors.mobile}</FormText>}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input autoComplete="off"
                                                invalid={formErrors.email !== undefined}
                                                type="text"
                                                name="email"
                                                onChange={(e) => this.handleContactChange(e.target.value, 'email')}
                                                value={creditorObj.contact.email} placeholder="Enter email here." id="Email" />
                                            {formErrors.email != null > 0 &&
                                                <FormText color="danger"> {formErrors.email}</FormText>}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="fax">Fax</Label>
                                            <Input autoComplete="off" type="number" name="fax"
                                                onChange={(e) => this.handleContactChange(e.target.value, 'fax')}
                                                value={creditorObj.contact.fax} placeholder="Enter fax no here." id="Fax" />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <br />
                                <hr />
                                <CreditorDetail creditorObj={creditorObj} formErrors={formErrors} handleCreditorDetailChange={this.handleCreditorDetailChange} />

                                <br />
                                <hr />
                                <BankDetail creditorObj={creditorObj} formErrors={formErrors} handleBankDetailChange={this.handleBankDetailChange} />


                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' size="sm" onClick={this.handleSubmit} disabled={this.state.saving} >

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' size="sm" onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
