import React, {Component} from 'react';
import {
    Col,
    Row, Label, FormGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown
} from 'reactstrap';

import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const dateRangesThis = ["This week", "This month", "This quarter", "This year"];
const dateRangesLast = ["Last week", "Last month", "Last quarter", "Last year"];
export default class CreatedDateBetweenFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCustomDateRange: "",
            loading: true,
            startDate: undefined,
            endDate: undefined,
            filter: this.props.filter
        };

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.selectDateRange = this.selectDateRange.bind(this);
        this.updateFilterForDateRange = this.updateFilterForDateRange.bind(this);
    }

    setStartDate(startDate) {
        this.updateFilter(startDate, "startDate", this);
    }

    setEndDate(endDate) {
        this.updateFilter(endDate, "endDate", this);
    }

    updateFilter(changedDate, type, self) {
        let {startDate, endDate, filter} = self.state;

        switch (type) {
            case "startDate":
                startDate = changedDate;
                break;
            case "endDate":
                endDate = changedDate;
                break;
            default:
                break;
        }

        if (!endDate) {
            endDate = new Date();
        }

        /*Comma separated StartDateString & EndDateString*/

        let dateString = "";
        let startDateString = "";
        let endDateString = "";


        if (startDate) {
            startDateString = moment(startDate).format('DD/MM/YYYY');
            dateString = startDateString + ",";
        } else {
            dateString = "" + ",";
        }
        endDateString = moment(endDate).format('DD/MM/YYYY');

        filter.value = dateString + endDateString;
        filter.displayValue = filter.value;
        this.setState({filter, startDate, endDate,selectedCustomDateRange: ""}, () => {
            this.props.onChange(filter, false);
        });
    }

    updateFilterForDateRange(startDate, endDate) {
        let {filter} = this.state;

        let startDateString = moment(startDate).format('DD/MM/YYYY');
        let endDateString = moment(endDate).format('DD/MM/YYYY');

        filter.value = startDateString + "," + endDateString;
        filter.displayValue = filter.value;

        this.setState({filter, startDate, endDate}, () => {
            this.props.onChange(filter, false);
        });
    }


    selectDateRange(selectedCustomDateRange) {
        //  let selectedCustomDateRange = e.target.value;
        this.setState({selectedCustomDateRange});

        let baseDate;
        let from_date;
        let to_date;
        switch (selectedCustomDateRange) {
            case  "This week":
                baseDate = moment();
                from_date = baseDate.startOf('week').toDate();
                to_date = baseDate.endOf('week').toDate();
                break;
            case  "This month":
                baseDate = moment();
                from_date = baseDate.startOf('month').toDate();
                to_date = baseDate.endOf('month').toDate();
                break;
            case  "This quarter":
                baseDate = moment();
                from_date = baseDate.startOf('quarter').toDate();
                to_date = baseDate.endOf('quarter').toDate();
                break;
            case  "This year":
                baseDate = moment();
                from_date = baseDate.startOf('year').toDate();
                to_date = baseDate.endOf('year').toDate();
                break;
            case  "Last week":
                baseDate = moment().subtract(1, 'weeks');
                from_date = baseDate.startOf('week').toDate();
                to_date = baseDate.endOf('week').toDate();
                break;
            case  "Last month":
                baseDate = moment().subtract(1, 'months');
                from_date = baseDate.startOf('month').toDate();
                to_date = baseDate.endOf('month').toDate();
                break;
            case  "Last quarter":
                baseDate = moment().subtract(1, 'quarters');
                from_date = baseDate.startOf('quarter').toDate();
                to_date = baseDate.endOf('quarter').toDate();
                break;
            case  "Last year":
                baseDate = moment().subtract(1, 'years');
                from_date = baseDate.startOf('year').toDate();
                to_date = baseDate.endOf('year').toDate();
                break;
            default:
                break;
        }
        this.updateFilterForDateRange(from_date, to_date);
    }

    render() {
        let maxDate = moment().add(2, 'years').toDate();
        let minDate = moment().subtract(20, 'years').toDate();
        let selectedCustomDateRange = this.state.selectedCustomDateRange;
        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className={"pb-3 d-inline-block"}>
                            <Label><i className="fa fa-calendar" aria-hidden="true"/>&nbsp;&nbsp;Start
                                date</Label>
                            <div>

                                <DatePicker
                                    className="form-control"
                                    selected={this.state.startDate}
                                    onChange={date => this.setStartDate(date)}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                        <div className={"pb-3 d-inline-block"}>
                            <Label><i className="fa fa-calendar" aria-hidden="true"/>&nbsp;&nbsp;End
                                date</Label>
                            <div>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.endDate}
                                    onChange={date => this.setEndDate(date)}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>

                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={"pb-3 d-inline-block"}>
                            <div className="mb-1"><i className="fa fa-calendar" aria-hidden="true"/>{' - '}<i
                                className="fa fa-calendar"
                                aria-hidden="true"/>&nbsp;&nbsp;
                                Date Range
                            </div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle caret color="primary">
                                    {selectedCustomDateRange ? selectedCustomDateRange : 'Select  '}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>This</DropdownItem>
                                    {
                                        dateRangesThis.map(option =>
                                            <DropdownItem
                                                style={{cursor: 'pointer'}}
                                                key={option}
                                                onClick={() => this.selectDateRange(option)}>
                                                {option}&nbsp;{(selectedCustomDateRange === option) ?
                                                <i className="fa fa-check text-success"/> : null}
                                            </DropdownItem>
                                        )
                                    }
                                    <DropdownItem divider/>
                                    <DropdownItem header>Last</DropdownItem>
                                    {
                                        dateRangesLast.map(option =>
                                            <DropdownItem
                                                style={{cursor: 'pointer'}}
                                                key={option}
                                                onClick={() => this.selectDateRange(option)}>
                                                {option} &nbsp;{(selectedCustomDateRange === option) ?
                                                <i className="fa fa-check text-success"/> : null}
                                            </DropdownItem>
                                        )
                                    }
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
