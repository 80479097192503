import React, {Component} from 'react';
import {Col, Input, Row, Spinner, Table} from 'reactstrap';
import NumberFormat from "react-number-format";
import {taxRate} from "../../../../../store/AppConstants";
import salesOrderProductBuilderV1Service from '../../../../../services/sales/SalesOrderProductBuilderV1Service';

export default class SalesOrderProductBuilderOrderSummaryTableRetail extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {order, freight, handleOrderChange, toggleRetailPreApprovedDiscountModal} = this.props;
        let subTotal, totalDiscountVal, total, tax;

        subTotal = order.pricing.retailMarkupValue;
        totalDiscountVal = order.pricing.retailMarkupValueDiscVal;
        tax = (subTotal + order.pricing.freight.charge + order.pricing.packagingAndHandlingCharges - totalDiscountVal) * taxRate;
        total = (subTotal + order.pricing.freight.charge + order.pricing.packagingAndHandlingCharges - totalDiscountVal) + tax;
        return (
            <div>
                <p><strong>Order Summary</strong></p>
                <Table>
                    <tbody>
                    <tr>
                        <td>Sub-total</td>
                        <th className="text-right">
                            <NumberFormat
                                prefix={'$'}
                                value={subTotal}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div>
                                <Row className={"mt-1"}>
                                    <Col xl={9} lg={9} md={9} sm={9} xs={9}
                                         className="text-left">
                                        <a
                                            href="javascript:void(0)"
                                            onClick={() => toggleRetailPreApprovedDiscountModal(true)}
                                        >
                                            Discount {order.retailMarkupDiscount ? "@" + (order.retailMarkupDiscount) + "%" : "?"}
                                        </a>
                                    </Col>
                                    <Col xl={3} lg={3} md={3} sm={3} xs={3}
                                         className="text-right">
                                        <NumberFormat
                                            prefix={'$'}
                                            value={totalDiscountVal}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </Col>
                                </Row>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Shipping method
                            {
                                (freight.isLoadingMetroPostcodeCheck || freight.isLoadingAllRules)
                                    ? <Spinner className={"ml-2"} size={"sm"}
                                               color={"primary"}/>
                                    : null
                            }
                            <div className="mt-1">
                                <Input name="freightMethod"
                                       onChange={(e) => handleOrderChange("freightMethod", e.target.value,)}
                                       value={freight.selectedMethodOption}
                                       type={"select"}>
                                    <option value={""}>Select method
                                    </option>
                                    {(freight.methodOptions || []).map((option, index) => {
                                        return <option
                                            value={option.methodKey}
                                            key={index}>{option.methodLabel}</option>
                                    })}
                                </Input>
                            </div>
                        </td>
                        <th className="text-right align-middle">
                            <NumberFormat
                                prefix={'$'}
                                value={order.pricing.freight ? order.pricing.freight.charge : 0}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    {
                        order.pricing.packagingAndHandlingCharges
                            ? <tr>
                                <td>Packaging & Handling Charges</td>
                                <th className="text-right">
                                    <NumberFormat
                                        prefix={'$'}
                                        value={order.pricing.packagingAndHandlingCharges}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}/>
                                </th>
                            </tr>
                            : null
                    }

                    <tr>
                        <td>GST</td>
                        <th className="text-right">
                            <NumberFormat
                                prefix={'$'}
                                value={tax}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    <tr className="table-secondary">
                        <th>Order total</th>
                        <th className="text-right">
                            <NumberFormat
                                prefix={'$'}
                                value={total}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}