import axios from './axios';

class DashboardService {

    static Instance() {
        return new DashboardService();
    }


    getDailySale(request) {
        return axios.post('api/dashboard/admin/sales/daily', request);
    }

    getProductBuilderSummary() {
        return axios.get('api/dashboard/product-builder/order/summary');
    }
    getProductBuilderOrderEnteredBy() {
        return axios.get('api/dashboard/product-builder/order/enteredby');
    }
    getProductBuilderSummaryforChart() {
        return axios.get('api/dashboard/product-builder/order/summary/chart');
    }
    getPBOrderData() {
        return axios.get('api/dashboard/product-builder/order/summary/weekly/data');
    }

    getPBOrderCurrentWeekData(request) {
        return axios.post('api/dashboard/product-builder/order/summary/current/week/data', request, {
            timeout: 120 * 1000
        });
    }
}

export default DashboardService.Instance();