import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';

class WarehouseLocationTypeService{

    static Instance() {
        return new WarehouseLocationTypeService();
    }
    getAllWarehouseLocationTypes(req)
    {
        return axios.post('api/warehouse-loc-type/get/all',req)
    }

    getOneWarehouseLocationType(warehouseLocationTypeID)
    {
        return axios.get('api/warehouse-loc-type/get/one/?warehouseLocationTypeID='+ warehouseLocationTypeID)
    }

    saveWarehouseLocationType(req)
    {
        return axios.post('api/warehouse-loc-type/save',req)
    }

    listAllWarehouseLocationTypes() {
        return axios.get('api/warehouse-loc-type/list/all')
    }

    getAllWarehouseOptions() {
        return axios.get('api/warehouse/all/warehouse/option');
    }

    getWarehouseLocation(id) {
        return axios.get('api/warehouse/location/one?id='+id);
    }

    getListWarehouseLocations(request) {
        return axios.post('api/warehouse/location/list', request);
    }

    saveWarehouseLocation(request) {
        return axios.post('api/warehouse/location/save', request);
    }
    deleteWarehouseLocation(request) {
        return axios.post('api/warehouse/location/delete', request);
    }

    addStockToLocation(request) {
        return axios.post('api/warehouse/stock/location/add', request);
    }

    transferStockFromLocation(request, toLocationID) {
        return axios.post('api/warehouse/stock/location/transfer?toLocationID=' + toLocationID, request);
    }

    removeStockFromLocation(request) {
        return axios.post('api/warehouse/stock/location/remove', request);
    }

    exportWarehouseLocations() {
        return axiosFileDownloader.get("api/warehouse/stock/location/export");
    }
}
export default WarehouseLocationTypeService.Instance();