import React, {Component} from 'react';
import moment from 'moment';

import simpleProductionService from '../../services/production/ProductionOutstandingOrdersService'
import SummaryTable from '../../components/production/ProductionSummaryTable'
import Products from '../../components/production/ProductionProducts'
import {scrollTo} from "../../services/CommonService";

export default class ProductionSalesOrdersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ordersSummary: {},
            loading: true,
            scrollToElement: ""
        };

        this.refresh = this.refresh.bind(this);
        this.getSummary = this.getSummary.bind(this);
        this.checkAndScroll = this.checkAndScroll.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        this.checkAndScroll(nextProps.location.hash)
    }

    checkAndScroll(targetHash) {
        if (this.state.scrollToElement !== targetHash && (!this.state.loading)) {
            if (targetHash) {
                scrollTo(targetHash)
            } else {
                scrollTo("top")
            }
            this.setState({scrollToElement: targetHash})
        }
    }

    refresh() {
        this.getSummary();
    }

    getSummary() {
        this.setState({loading: true});
        simpleProductionService.getProductionOutstandingOrders().then(response => {

            let ordersSummary = {
                summaries: response.data,
                summaryTableRows: [],
                summaryOn: moment(new Date()).format('DD MMM YYYY hh:mm A')
            };

            ordersSummary = simpleProductionService.calculateTotalCounts(ordersSummary);
            ordersSummary.summaryTableRows = simpleProductionService.createSummaryTableRows(ordersSummary);

            this.setState({ordersSummary: ordersSummary, loading: false}, () => {
                this.checkAndScroll(this.props.location.hash);
            });

        }).catch(error => {
            console.log(error);
        });
    }


    render() {

        if (this.state.loading) {
            return <p>loading... </p>
        }


        let ordersSummary = this.state.ordersSummary;
        let indoorProducts = ordersSummary.summaries[0].products;
        let outdoorProducts = ordersSummary.summaries[1].products;


        return (
            <div>
                <SummaryTable rows={ordersSummary.summaryTableRows}
                              summaryOn={ordersSummary.summaryOn}
                />

                <Products ordersByProduct={indoorProducts} summaryOn={ordersSummary.summaryOn}/>
                <Products ordersByProduct={outdoorProducts} summaryOn={ordersSummary.summaryOn}/>

            </div>
        );
    }
}

