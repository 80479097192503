    import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody, Label,
    CardFooter, Input, Button, InputGroup, InputGroupAddon, InputGroupText, Spinner, Badge,
    UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import StockService from '../../services/StockService';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import * as FileSaver from 'file-saver';
import { handleErrorMessage } from '../../services/CommonService';
    import SearchStock from "../../components/search/SearchStock";
    import SearchStockGroup from "../../components/search/SearchStockGroup";

export default class StockPage extends Component {

    constructor(props) {
        super(props); 
        this.state = {
            stockData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        description: '',
                        prodCode: '',
                        stockGroupCode: '',
                        isNotInKeyway: false,
                        status: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            }, 
            loading: true,
            downloading: false,
            searchText: '',
            isGeneratingImportFile: false
        }; 
        this.stockService = new StockService(); 
        this.handlePropsChange = this.handlePropsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getStocks = this.getStocks.bind(this);
        this.generateExcelForSelectedStocks = this.generateExcelForSelectedStocks.bind(this);
        this.generateExcelForAllFilteredStocks = this.generateExcelForAllFilteredStocks.bind(this);
    }

    componentDidMount() {
        this.getStocks(this.state.stockData);
    }
      
    getStocks(stockData) {
        this.setState({ loading: true });

        this.stockService.getStockList(stockData.request).then(response => {
            stockData.response = response.data;
            this.setState({ stockData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        
    }


    generateExcelForSelectedStocks() {
        let { stockData } = this.state;
        this.setState({ isGeneratingImportFile: true });
        var selectedStockList = [];
        selectedStockList = stockData.response.records.filter(item => item.isSelected).map(value => value.prodCode);
        this.stockService.generateKeywayStockImportFile(selectedStockList).then(response => {
            this.setState({ isGeneratingImportFile: false });
            FileSaver.saveAs(response.data, "SwishToKeywayImportFile" + ".xlsx");
            this.props.history.push("/inventory/keyway/stock/import");
        }).catch(error => {
            this.setState({ isGeneratingImportFile: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    generateExcelForAllFilteredStocks() {
        let { stockData } = this.state;
        var request = cloneDeep(stockData.request);
        this.setState({ isGeneratingImportFile: true });
        this.stockService.generateAllFilteredKeywayStockImportFile(request).then(response => {
            this.setState({ isGeneratingImportFile: false });
            FileSaver.saveAs(response.data, "SwishToKeywayFilteredImportFile" + ".xlsx");
            this.props.history.push("/inventory/keyway/stock/import");
        }).catch(error => {
            this.setState({ isGeneratingImportFile: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }
     
    searchFunction(item, searchText) { 
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();
            flag = item.description ? item.description.toLowerCase().includes(searchText) : false;

            if (!flag && item.prodCode) { 
                flag = item.prodCode.toLowerCase().includes(searchText);
            }

            if (!flag && item.stockGroupName) {
                flag = item.stockGroupName.toLowerCase().includes(searchText);
            } 
        }
        return flag;
    }

    handleChange(change, key) { 
        let { stockData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (stockData.request.sortRequest.key === change) {
                    stockData.request.sortRequest.direction = !stockData.request.sortRequest.direction;
                } else {
                    stockData.request.sortRequest.key = change;
                    stockData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStocks(stockData);
                }
                break;
            case "isStockSelected":
                if (!stockData.response.records[change].isSelected) {
                    stockData.response.records[change].isSelected = true;
                } else {
                    stockData.response.records[change].isSelected = false;
                }
                this.setState({ stockData });
                break;
            case "isAllSelected":
                stockData.response.records.forEach(item => {
                    item.isSelected = change;
                })
                this.setState({ stockData });
                break;
            case "pageSize":
                stockData.request.pageRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                break;
            case "currentPage":
                stockData.request.pageRequest[key] = change;
                this.getStocks(stockData);
                break;
            default:
                stockData.request.filterRequest[key] = change;

                if (key === 'price' || key === 'qoh') {
                    if (change === "") {
                        stockData.request.filterRequest[key] = 0;
                    }
                    else {
                        stockData.request.filterRequest[key] = parseFloat(change);
                    }
                }
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                this.setState({ stockData });
        }

    }

    getStockHeading({ filterRequest }) {
        let isAllSelected = this.state.stockData.response.records.every(x => x.isSelected);
        let stockHeading = [
            {
                key: "multiSelect",
                label: "",
                type: 'multiSelect',
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                isVisible: filterRequest.isNotInKeyway,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNode: <a href={"javascript:void(0)"}
                    onClick={() => this.handleChange(!isAllSelected, "isAllSelected")}>
                    <i className={classnames("mt-2", "fa", "fa-lg", {
                        "fa-check-square-o": isAllSelected,
                        "fa-square-o": !isAllSelected,
                    }
                    )} />
                </a>
            },
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 40,
                sorterApplicable: false,
                isVisible: true,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "prodCode",
                label: "Prodcode",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                isVisible: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div> <Input type="text" id="prodCode" name="prodCode"
                    value={filterRequest.prodCode}
                    onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                    placeholder="Search..." /></div>

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                isVisible: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div> <Input type="text" id="description" name="description"
                    value={filterRequest.description}
                    onChange={(e) => this.handleChange(e.target.value, "description")}
                    placeholder="Search..." /></div>

            },
            {
                key: "stockGroupName",
                label: "Stock Group",
                type: "text",
                colSPan: 1,
                minWidth: 200,
                sorterApplicable: true,
                isVisible: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><SearchStockGroup
                    handleSelection={(selection) => this.handleChange(selection, "stockGroupCode")}
                    selectedStockGroupCode={filterRequest.stockGroupCode}
                    defaultStockGroupCode={""}
                    filter={{
                        isKeywayAvailableOnly: false,
                        stockMasterGroupCodes: [],
                        stockGroupCodes: []
                    }}
                />
                </div>
                 
            }, 
            {
                key: "qoh",
                label: "QOH",
                type: "text",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                isVisible: true,
                valueClassName: "table-success text-right align-middle",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div>
                    <Input type={"number"}
                        placeholder={"Search..."}
                        name={"qoh"}
                        value={filterRequest.qoh || ''}
                        onChange={(e) => this.handleChange(e.target.value, "qoh")} />
                </div>
            },
            {
                key: "price",
                label: "Price",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                isVisible: true,
                valueClassName: "text-right align-middle",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                    <Input type={"number"}
                        placeholder={"Search..."}
                        name={"price"}
                        value={filterRequest.price || ''}
                            onChange={(e) => this.handleChange(e.target.value, "price")} />
                    </InputGroup>
                </div>
            },
            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: false,
                isVisible: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <Input type="select" name="status" value={filterRequest.status}
                    onChange={(e) => this.handleChange(e.target.value, "status")}>
                    <option select value="" disabled>Select Status</option>
                    <option value="All">All</option>
                    <option value="Available">Available</option>
                    <option value="Discontinued">Discontinued</option>
                    <option value="SoldOut">Sold Out</option>
                    <option value="Discontinued&SoldOut">Discontinued & Sold Out</option>
                </Input>
            }];

        return stockHeading;
    }

    handlePropsChange(selected) { 
        if (selected) { 
            this.setState({ selectedValue: true });  
        }  
    }
 
    printStockInExcel = (isFilter) => {
        let request = cloneDeep(this.state.stockData.request);
        this.setState({ downloading: true });
        this.stockService.generateStockExcelReport(request, isFilter).then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "StockReport" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            toast.error(handleErrorMessage(error));
        });
    }
     
    render() {

        let { stockData, loading, searchText, downloading, isGeneratingImportFile } = this.state;
        let { pageRequest, sortRequest } = stockData.request;
        let stockHeading = this.getStockHeading(stockData.request);

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Swish Stock List</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Swish Stocks</h5>
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color={"success"} size='sm' disabled={downloading}
                                            outline={true}>
                                            {downloading
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    style={{ color: "success" }} />
                                                : <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />}
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem title={'Import All Filtered Entries'}
                                                onClick={() => this.printStockInExcel(false)}>
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> All Enteries
                                            </DropdownItem>
                                            <DropdownItem title={'Import Selected Enteries Only'}
                                                onClick={() => this.printStockInExcel(true)}>
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> Filtered Enteries
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                    <Link color='primary' style={{ textDecoration: "none" }}
                                        className="btn btn-sm btn btn-primary ml-2"
                                        to={'/tools/manage/stock/bulk/update'}>
                                        <i className="fa fa-floppy-o mr-2"
                                            aria-hidden="true" />
                                        Bulk Update
                                    </Link>
                                    {stockData.response.records.some(x => x.isSelected) && stockData.request.filterRequest.isNotInKeyway ?
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle caret color={"primary"} size='sm' disabled={isGeneratingImportFile}
                                                outline={true} className="ml-2">
                                                {isGeneratingImportFile
                                                    ? <Spinner size="sm"
                                                        className={"mr-2"}
                                                        style={{ color: "primary" }} />
                                                    : <i className="fa fa-upload mr-2" aria-hidden="true" />}
                                                Generate Keyway Stock Import File
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem title={'Import All Filtered Entries'}
                                                    onClick={() => this.generateExcelForAllFilteredStocks()}>
                                                    <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> All Filtered Entries
                                                </DropdownItem>
                                                <DropdownItem title={'Import Selected Enteries Only'}
                                                    onClick={() => this.generateExcelForSelectedStocks()}>
                                                    <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> Selected Enteries Only
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        : null}
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className={"align-middle"}>
                            <Col xl={5} lg={5} md={4} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                                <div className={"mr-1"}>
                                    <Label class={"mb-2"}>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange(!stockData.request.filterRequest.isNotInKeyway, "isNotInKeyway")}>
                                            <i className={classnames("fa", "fa-lg", "fa-fw", {
                                                "fa-square-o": !stockData.request.filterRequest.isNotInKeyway,
                                                "fa-check-square-o": stockData.request.filterRequest.isNotInKeyway,
                                            }
                                            )} aria-hidden="true" />
                                        </Button>
                                        Not In Keyway
                                    </Label>
                                </div>
                            </Col>
                            <Col xl={5} lg={5} md={4} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(stockHeading || []).map((item, index) => {
                                            return (
                                                item.isVisible && <th key={index}
                                                    onClick={() => item.sorterApplicable ? this.handleChange(item.key, "sortKey") : null}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(stockHeading || []).map((item, index) => {
                                            return (
                                                item.isVisible && <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(stockData.response.records || []).filter((stock) => this.searchFunction(stock, searchText)).map((stock, index) =>

                                        <tr key={index}>
                                            {stockData.request.filterRequest.isNotInKeyway ?
                                                   <td className={"text-center align-middle"}><span>
                                                   <a href={"javascript:void(0)"}
                                                       onClick={(e) => this.handleChange(index, "isStockSelected")}>
                                                       <i className={classnames("mt-2", "fa", "fa-lg", {
                                                           "fa-check-square-o": stockData.response.records[index].isSelected,
                                                           "fa-square-o": !stockData.response.records[index].isSelected,
                                                       }
                                                        )} /> </a></span></td>
                                                : null}
                                            <td className={"text-left align-middle"}>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td className={"text-center align-middle"}>
                                                    <Link className="btn btn-primary btn-sm"
                                                        style={{ color: "white" }}
                                                        title={"Click here to see stock enquiry"}
                                                        to={"/inventory/stock/enquiry?" + queryString.stringify({ prodCode: stock.prodCode })}
                                                    >{stock.prodCode}</Link>
                                            </td>
                                            <td className={"text-left align-middle"}>{stock.description}</td>
                                            <td className={"text-left align-middle"}>{stock.stockGroupName}</td>
                                            <td className="text-right align-middle"><NumberFormat value={stock.qoh}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true} /></td>
                                            <td className="text-right align-middle"><NumberFormat value={stock.price1}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                prefix={'$'} /></td>
                                            <td className={"text-center align-middle"}>
                                                <div>
                                                    {stock.discontinued ? <Badge color="danger" className={"mr-2"}>Discontinued</Badge>
                                                        : null
                                                    }
                                                    {stock.soldOut ? <Badge color="warning">Sold Out</Badge>
                                                        : null
                                                    }
                                                    </div>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                    <ToastContainer /></div>
        );
    }
}
