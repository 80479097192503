import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {isEmpty} from 'lodash';
import OrderService from '../../services/OrderService';
import {handleErrorMessage} from "../../services/CommonService";
import OrderEnquiryProductionModeOrderItems from "../../pages/sales/order/enquiry/production/OrderEnquiryProductionModeOrderItems";
import orderEnquiryUtil from '../../util/OrderEnquiryUtil';

export default class OrderGatewayProductBuilderBOMModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: {},
            activeTab: "partsByProductItem",
        };

        this.orderService = new OrderService();
        this.toggleTab = this.toggleTab.bind(this);
        this.toggleProductCollapse = this.toggleProductCollapse.bind(this);
        this.toggleProductItemCollapse = this.toggleProductItemCollapse.bind(this);
        this.getDebtorInvoice = this.getDebtorInvoice.bind(this);
        this.getPartList = this.getPartList.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
    }


    componentDidMount() {
        this.getDebtorInvoice(this.props.ordNum)
    }

    toggleTab(tab) {
        this.setState({activeTab: tab});
    }

    toggleProductCollapse(productIndex, change) {
        this.updateOrder("toggleProductCollapse", {productIndex, isOpen: change});
    }

    toggleProductItemCollapse(productIndex, itemIndex, change) {
        this.updateOrder("toggleProductItemCollapse", {productIndex, itemIndex, isOpen: change});
    }

    getDebtorInvoice(ordNum) {
        this.setState({ordNum, isLoadingOrder: true});
        this.orderService.getOrder(ordNum, true, true, true,"current").then(response => {
            let order = response.data;
            if (order) {
                order.products = orderEnquiryUtil.initOrderProducts(order);
                this.setState({order: order, isLoadingOrder: false}, () => {
                    this.getPartList(order.ordNum);
                });
            } else {
                this.setState({isLoadingOrder: false});
            }
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingOrder: false});
            toast.error(handleErrorMessage(error));
        });
    }

    getPartList(ordNum) {
        this.setState({isLoadingPartList: true});
        this.orderService.getOrderPartList(ordNum).then(response => {
            let {order} = this.state;
            order.parts = response.data.parts;
            order.partsConsolidated = orderEnquiryUtil.initOrderItemConsolidatedParts(order);
            order.products = orderEnquiryUtil.initOrderItemProductParts(order);

            this.updateOrder("order_parts", {
                parts: order.parts,
                partsConsolidated: order.partsConsolidated,
                products: order.products
            });
            this.setState({isLoadingPartList: false});
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingPartList: false});
            toast.error(handleErrorMessage(error));
        });
    }

    updateOrder(key, change) {
        let {order} = this.state;
        let productIndex, products, itemIndex, isOpen, parts, partsConsolidated;
        switch (key) {
            case "order_parts":
                ({products, parts, partsConsolidated} = change);
                order.products = products;
                order.parts = parts;
                order.partsConsolidated = partsConsolidated;
                break;
            case "toggleProductCollapse":
                ({productIndex, isOpen} = change);
                order.products[productIndex].isOpen = isOpen;
                break;
            case "toggleProductItemCollapse":
                ({productIndex, itemIndex, isOpen} = change);
                if (itemIndex > -1) {
                    order.products[productIndex].items[itemIndex].isOpen = isOpen;
                }
                if (itemIndex === -1) {
                    order.products[productIndex].items.forEach(productItem => {
                        productItem.isOpen = isOpen;
                    })
                }
        }
        this.setState({order});
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {order, activeTab, isLoadingOrder} = this.state;


        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">#{order.ordNum}</h5>
                </ModalHeader>
                <ModalBody>
                    {isLoadingOrder
                        ? <Spinner color={"primary"}/>
                        : <OrderEnquiryProductionModeOrderItems
                            toggleProductCollapse={this.toggleProductCollapse}
                            toggleProductItemCollapse={this.toggleProductItemCollapse}
                            prepareOrderModel={this.toggleProductItemCollapse}
                            activeTab={activeTab}
                            toggleTab={this.toggleTab}
                            isPrinting={false}
                            order={order}
                            bomModal={"bomModal"}
                        />
                    }
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"secondary"}
                                size={"sm"} onClick={() => toggle(false)}>
                            Close
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}