import React, {Component} from 'react';
import {concatenateStrings} from "../../../../../../services/CommonService";

export let ziptrakExternalLabelStore = [
    {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            let result = data.productLabel.isSkinOnly ? "Skin Only" : "";
            result = result + (data.productLabel[this.key] ? " " + data.productLabel[this.key] : "");
            return result;
        }
    }, {
        key: "fabricType",
        label: "Fabric",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour, data.productLabel.fabricColourOffRange);
        }
    }, {
        key: "splineColour",
        label: "Spline",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "motorised",
        label: "Motorised",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "hooding",
        label: "Hooding",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.hooding, data.productLabel.hoodingColour, data.productLabel.hoodingPowdercoatColour);
        }
    }, {
        key: "frameColour",
        label: "Frame Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.frameColour, data.productLabel.framePowdercoatColour);
        }
    }, {
        key: "bracketType",
        label: "Bracket Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key] ? data.productLabel[this.key] : "N/A";  // NULL evaluates to false, displays N/A
        }
    }, {
        key: "channels",
        label: "Channels",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "centralLock",
        label: "Central Locking",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "bottomRailDuty",
        label: "Bottom Rail",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.bottomRailDuty, data.productLabel.bottomRailFinish);
        }
    }, {
        key: "pullDownStick",
        label: "Extras",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            let pullDownHook = data.productLabel[this.key] ? "Pull Down Hook" : "";
            let extraBottomStoppers = data.productLabel.additionalBottomStoppers ? "Extra Bottom Stoppers" : "";
            let highImpactStoppers = data.productLabel.highImpactStoppers ? "High Impact Stoppers" : "";
            let removablePostKit = data.productLabel.removablePostKit ? "Removable Post Kit" : "";
            return concatenateStrings(", ", pullDownHook, extraBottomStoppers, highImpactStoppers, removablePostKit);
        }
    }
];