import React, { Component } from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Spinner
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import queryString from 'query-string';
import creditorService from '../../services/purchase/CreditorService';
import SearchCity from '../../components/search/SearchCity';
import SearchState from '../../components/search/SearchState';
import SearchPostcode from '../../components/search/SearchPostcode';
import { handleErrorMessage, validateEmail } from '../../services/CommonService';
import SearchCountry from "../../components/search/SearchCountry";
import CurrencyService from '../../services/CurrencyService';


export default class ManageSwishCreditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditorID: '',
            creditorObj: {
                accountID: '',
                company: "",
                email: '',
                IsActive: true,
                currency: "",
                address1: '',
                address2: '',
                address3: '',
                address4: '',
                city: '',
                state: '',
                postCode: '',
                phone: null,
                fax: '',
                country: '',
            },
            contactTypes: [],
            formErrors: {},
            saving: false,
            loading: false,
            loadingAddress: false,
            loadingContacts: false,
            isInternational: false,
            currencyList: []
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getCreditor = this.getCreditor.bind(this);
        this.generateNewAccountID = this.generateNewAccountID.bind(this);
        this.getCurrencyList = this.getCurrencyList.bind(this);

    }

    componentDidMount() {
        let { creditorID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            creditorID = searchParams.creditorID;
            this.setState({ creditorID }, () => { this.refresh(creditorID) })
        }
        else {
            this.generateNewAccountID();
        }
        this.getCurrencyList();
    }

    refresh(creditorID) {
        this.getCreditor(creditorID);
    }


    getCreditor(creditorID) {
        if (creditorID) {
            this.setState({ loading: true });
            creditorService.getCreditor(creditorID).then(response => {
                this.setState({ creditorObj: response.data, loading: false })
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }

    }

    handleChange(change, key) {
        let { creditorObj } = this.state;
        creditorObj[key] = change;
        if (key == 'country' && change != 'AUSTRALIA') {
            this.setState({ isInternational: true });
        } else if (key == 'country' && change == 'AUSTRALIA') {
            this.setState({ isInternational: false });
        }
        this.setState({ creditorObj });
    }

    handleCancel() {
        this.props.history.push('/creditor/list');
    }

    validateForm(creditorObj) {
        let formErrors = {};
        let valid = true;

        if (creditorObj.company === null || creditorObj.company === '') {
            formErrors.company = "Please enter company name";
            valid = false;
        }
        if (creditorObj.accountID === null || creditorObj.accountID === '') {
            formErrors.accountID = "Please enter accountID";
            valid = false;
        }
        if ((creditorObj.phone !== null && creditorObj.phone !== "") && creditorObj.phone.length < 8) {
            formErrors.phone = "The Phone number must be of at least 8 characters";
            valid = false;
        }
        if (creditorObj.address1 === null || (creditorObj.address1 !== null && creditorObj.address1.length === 0)) {
            formErrors.address1 = "Please enter address1";
            valid = false;
        }
        if (creditorObj.phone === null || creditorObj.phone === '') {
            formErrors.phone = "Please enter Phone";
            valid = false;
        }
        if (creditorObj.email === null || creditorObj.email === '') {
            formErrors.email = "Please enter Email";
            valid = false;
        }
        if (creditorObj.email != null && creditorObj.email != "" && creditorObj.email != undefined) {
            let isValidEmail = validateEmail(creditorObj.email);
            if (!isValidEmail) {
                formErrors.email = "Please enter valid email";
                valid = false;
            }
        }
        if (creditorObj.state.length > 3) {
            formErrors.state = "Please enter maximum of 3 characters for State"
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let { creditorID } = this.state;
        let req = { ...this.state.creditorObj };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });

            creditorService.saveCreditor(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (creditorID != '') {
                        toast.success("Supplier updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        this.props.history.push('/creditor/list');
                        toast.success("Saved!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    this.setState({ saving: false, creditorObj: response.data });
                }

            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    generateNewAccountID() {
        let { creditorObj } = this.state;
        creditorService.generateNewAccountID().then(response => {
            creditorObj.accountID = response.data;
            this.setState({ creditorObj });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCurrencyList() {
        let { currencyList } = this.state;
        CurrencyService.getCurrencyDropdown().then(response => {
            currencyList = response.data;
            this.setState({ currencyList });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { creditorID, creditorObj, formErrors, loading, saving, currencyList, isInternational } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/creditor/list')}>Suppliers</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{creditorID === '' ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Supplier</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Account ID*</Label>
                                            <Input name="accountID" type="text"
                                                value={creditorObj.accountID}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                disabled={true}
                                                placeholder="write accountID here..." />
                                            <FormText color="danger">{formErrors.accountID}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <Label>Currency</Label>
                                        <div>
                                            <Input type={"select"}
                                                placeholder={"Select Currency..."}
                                                name={"currency"}
                                                value={creditorObj.currency || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'currency')}>
                                                <option value="">Select</option>
                                                {currencyList.map((currency, index) =>
                                                    <option key={index} value={currency}>{currency}</option>
                                                )}
                                            </Input>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Company*</Label>
                                            <Input
                                                invalid={formErrors.company}
                                                name="company"
                                                type="text"
                                                value={creditorObj.company || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'company')}
                                                placeholder="write company here..." />
                                            <FormText color="danger">{formErrors.company}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="address1">Address 1*</Label>
                                            <Input autoComplete="off"
                                                invalid={formErrors.address1 !== undefined}
                                                type="text"
                                                name="address1"
                                                onChange={(e) => this.handleChange(e.target.value, 'address1')}
                                                value={creditorObj.address1}
                                                placeholder="Address1" id="Address1" />
                                            {formErrors.address1 !== null > 0 &&
                                                <FormText
                                                    color={"danger"}> {formErrors.address1}</FormText>}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>

                                        <FormGroup>
                                            <Label for="address2">Address 2</Label>
                                            <Input autoComplete="off"
                                                type="text"
                                                name="address2"
                                                onChange={(e) => this.handleChange(e.target.value, 'address2')}
                                                value={creditorObj.address2}
                                                placeholder="Address2" id="Address2" />
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="address3">Address 3</Label>
                                            <Input autoComplete="off"
                                                type="text"
                                                name="address3"
                                                onChange={(e) => this.handleChange(e.target.value, 'address3')}
                                                value={creditorObj.address3}
                                                placeholder="Address3" id="Address3" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="City">City</Label>
                                            {isInternational ?
                                                <Input
                                                    onChange={(e) => this.handleChange(e.target.value, 'city')}
                                                    name="city"
                                                    type="text"
                                                    value={creditorObj.city}
                                                    placeholder="City" /> :
                                                <SearchCity
                                                    handleSelection={(selectedCity) => this.handleChange(selectedCity, "city")}
                                                    selected={creditorObj.city || ''}
                                                    defaultSelected={''}
                                                    filters={{ state: creditorObj.state || '', postcode: creditorObj.postCode || '' }}
                                                />
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="State">State</Label>
                                            {isInternational ?
                                                <Input
                                                    invalid={formErrors.state}
                                                    onChange={(e) => this.handleChange(e.target.value, 'state')}
                                                    name="state"
                                                    type="text"
                                                    value={creditorObj.state}
                                                    placeholder="State" />
                                                :
                                                <SearchState
                                                    handleSelection={(selectedState) => this.handleChange(selectedState, "state")}
                                                    selected={creditorObj.state || ''}
                                                    defaultSelected={''}
                                                    filters={{ city: creditorObj.city || '', postcode: creditorObj.postCode || '' }}
                                                />
                                            }
                                            {formErrors.state !== null > 0 &&
                                                <FormText
                                                    color={"danger"}> {formErrors.state}</FormText>}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="Postcode">Postcode</Label>
                                            {isInternational ?
                                                <Input
                                                    onChange={(e) => this.handleChange(e.target.value, 'postCode')}
                                                    name="postCode"
                                                    type="text"
                                                    value={creditorObj.postCode}
                                                    placeholder="Post Code" />
                                                :
                                                <SearchPostcode
                                                    handleSelection={(selectedPostcode) => this.handleChange(selectedPostcode, "postCode")}
                                                    selected={creditorObj.postCode || ''}
                                                    defaultSelected={''}
                                                    filters={{ city: creditorObj.city || '', state: creditorObj.state || '' }}
                                                />
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="country">Country</Label>
                                            <SearchCountry
                                                handleSelection={(selectedCountry) => this.handleChange(selectedCountry, "country")}
                                                selected={creditorObj.country || ''}
                                                defaultSelected={''} />
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="phone">Phone*</Label>
                                            <Input autoComplete="off"
                                                invalid={formErrors.phone}
                                                type="text"
                                                name="phone"
                                                onChange={(e) => this.handleChange(e.target.value, 'phone')}
                                                value={creditorObj.phone}
                                                placeholder="Enter phone number here." id="Phone" />
                                            {formErrors.phone !== null > 0 &&
                                                <FormText
                                                    color={"danger"}> {formErrors.phone}</FormText>}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="email">Email*</Label>
                                            <Input autoComplete="off"
                                                invalid={formErrors.email}
                                                type="text"
                                                name="email"
                                                onChange={(e) => this.handleChange(e.target.value, 'email')}
                                                value={creditorObj.email}
                                                placeholder="Enter email here." id="email" />
                                            {formErrors.email !== null > 0 &&
                                                <FormText
                                                    color={"danger"}> {formErrors.email}</FormText>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/*<Row>*/}
                                {/*    <Col lg={6} md={6} sm={12} xs={12}>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Label for="fax">Fax</Label>*/}
                                {/*            <Input autoComplete="off" type="text" name="fax"*/}
                                {/*                   onChange={(e) => this.handleChange(e.target.value, 'fax')}*/}
                                {/*                   value={creditorObj.fax}*/}
                                {/*                   placeholder="Enter Fax no here." id="Fax" />*/}
                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={6} md={6} sm={12} xs={12}>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Label for="webSite">Website</Label>*/}
                                {/*            <Input autoComplete="off" type="text" name="webSite"*/}
                                {/*                   onChange={(e) => this.handleChange(e.target.value, 'webSite')}*/}
                                {/*                   value={creditorObj.webSite}*/}
                                {/*                   placeholder="Enter company website here." id="webSite" />*/}

                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}

                                {/*<hr/>*/}
                                {/*<strong>Bank Details</strong>*/}
                                {/*<br />*/}
                                {/*<Row>*/}
                                {/*    <Col lg={6} md={6} sm={12} xs={12}>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Label>Account Name</Label>*/}
                                {/*            <Input*/}
                                {/*                invalid={formErrors.bankAcctName}*/}
                                {/*                name="accountName"*/}
                                {/*                type="text"*/}
                                {/*                value={creditorObj.bankAcctName || ''}*/}
                                {/*                onChange={(e) => this.handleChange(e.target.value, 'bankAcctName')}*/}
                                {/*                placeholder="write account name here..." />*/}
                                {/*            <FormText color="danger">{formErrors.bankAcctName}</FormText>*/}
                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}

                                {/*    <Col lg={6} md={6} sm={12} xs={12}>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Label>Account Number</Label>*/}
                                {/*            <Input*/}
                                {/*                invalid={formErrors.bankAcctNum}*/}
                                {/*                name="accountNumber"*/}
                                {/*                type="text"*/}
                                {/*                value={creditorObj.bankAcctNum || ''}*/}
                                {/*                onChange={(e) => this.handleChange(e.target.value, 'bankAcctNum')}*/}
                                {/*                placeholder="write account number here..." />*/}
                                {/*            <FormText color="danger">{formErrors.bankAcctNum}</FormText>*/}
                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}

                                {/*<Row>*/}
                                {/*    <Col lg={6} md={6} sm={12} xs={12}>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Label>Bank Name</Label>*/}
                                {/*            <Input*/}
                                {/*                invalid={formErrors.bankName}*/}
                                {/*                name="bankName"*/}
                                {/*                type="text"*/}
                                {/*                value={creditorObj.bankName || ''}*/}
                                {/*                onChange={(e) => this.handleChange(e.target.value, 'bankName')}*/}
                                {/*                placeholder="write bank name here..." />*/}
                                {/*            <FormText color="danger">{formErrors.bankName}</FormText>*/}
                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}

                                {/*    <Col lg={6} md={6} sm={12} xs={12}>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Label>Bank Suburb</Label>*/}
                                {/*            <Input*/}
                                {/*                invalid={formErrors.bankSuburb}*/}
                                {/*                name="bankSuburb"*/}
                                {/*                type="text"*/}
                                {/*                value={creditorObj.bankSuburb || ''}*/}
                                {/*                onChange={(e) => this.handleChange(e.target.value, 'bankSuburb')}*/}
                                {/*                placeholder="write bank suburb here..." />*/}
                                {/*            <FormText color="danger">{formErrors.bankSuburb}</FormText>*/}
                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>

                            <Button color='primary' size="sm" onClick={this.handleSubmit} disabled={saving} className={"mr-2"} >

                                {saving ?
                                    <Spinner size="sm" className="mr-2" style={{ color: "white" }} /> :
                                    <i className="fa fa-floppy-o mr-2" aria-hidden="true" />}
                                {saving ? 'Saving' : 'Save'}</Button>

                            <Button color='secondary' size="sm" onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
