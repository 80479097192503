import React, { Component } from "react";
import {Button, Col,Row, Spinner, Table} from 'reactstrap';
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { changeFormatOfDateString, handleErrorMessage } from '../../services/CommonService';
import creditorService from "../../services/purchase/CreditorService";
import queryString from 'query-string';
import { toast } from 'react-toastify';
import NumberFormat from "react-number-format";

export default class SupplierQuotesTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            quotes:[],
            accountID: props.accountID,
            isOpenAll:false
        };
        this.getQuoteList = this.getQuoteList.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getQuoteList(this.state.accountID);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({ accountID: nextProps.accountID }, () => {
                this.getQuoteList(nextProps.accountID);
            });
        }
    }

    getQuoteList(accountID) {
        let { quotes } = this.state;
        this.setState({ isLoading: true });
        creditorService.getSupplierQuotesList(accountID).then(response => {
            quotes = response.data;
            this.setState({ quotes, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStore() {
        let store = [
            {
                key: "creditorQuoteID",
                label: "Quote Num",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                render: function (value, quote, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "userID",
                label: "Entered By",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                render: function (value, quote, data, index, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "startDate",
                label: "Date",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                render: function (value, quote, data, index, ref) {
                    return <span>{changeFormatOfDateString(value, "YYYY-MM-DD hh:mm:ss", 'DD  MMM YYYY')}</span>
                }

            },
           {
               key: "endDate",
                label: "Expire",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, quote, data, index, ref) {
                    return <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD  MMM YYYY')}</span>
                }
            },
            {
                key: "currencyType",
                label: "Currency",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                render: function (value, quote, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "notes",
                label: "Note",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                render: function (value, quote, data, index, ref) {
                    return <span>{value}</span>
                }
            },
        ];

        return store;
    }

    getQuoteItemsStore() {
        let store = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "align-middle text-center",
                render: function (value, quoteItem, data, index, ref) {
                    return <span>{index + 1}</span>;
                }

            },
            {
                key: "prodCode",
                label: "Prodcode",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                render: function (value, quoteItem, data, index, ref) {
                    return <h5 className={"text-center"}>
                        <Link
                            className="btn btn-primary btn-sm"
                            style={{ color: "white" }}
                            title={"Click here to see stock enquiry"}
                            to={"/inventory/stock/enquiry?" + queryString.stringify({ prodCode: value })}
                        >{value}</Link>
                        </h5>
                }
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                render: function (value, quoteItem, data, index, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "price",
                label: "Price",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-center",
                render: function (value, quoteItem, data, index, ref) {
                    return <NumberFormat value={value}
                        displayType={'text'}
                        decimalScale={2}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        prefix={"$"} />;
                }

            },
            {
                key: "moq1",
                label: "MOQ",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, quoteItem, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "leadTime",
                label: "Lead Time",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                render: function (value, quoteItem, data, index, ref) {
                    return <span>{value}</span>
                }
            },
        ];

        return store;
    }

    handleChange(key, change, itemIndex, context) {
        let { quotes, isOpenAll } = this.state;
        switch (key) {
            case "toggleAllOrderCollapse":
                (quotes || []).forEach(item => {
                    item.isOpen = change;
                });
                isOpenAll = change;
                break;
            case "toggleOrderCollapse":
                quotes[itemIndex].isOpen = change;
                break;
        }
        this.setState({ quotes, isOpenAll })
    }

    render() {
        let { isLoading, quotes, isOpenAll } = this.state;
        let store = this.getStore();
        let quoteItemStore = this.getQuoteItemsStore();
        return (
            <div>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Table striped bordered responsive hover size='sm' className={"mb-1"}>
                            <thead>
                                <tr>
                                    <th className="hoverableItem align-middle text-center d-print-none"
                                        onClick={() => this.handleChange("toggleAllOrderCollapse", !isOpenAll, -1, null)}>
                                        <Button color={"primary"}
                                            size={"sm"}
                                            outline={isOpenAll}>
                                            <i className={classnames("fa", {
                                                "fa-plus-circle": !isOpenAll,
                                                "fa-minus-circle": isOpenAll,
                                            }
                                            )} />
                                        </Button>
                                    </th>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{ minWidth: item.minWidth }}>
                                                {item.label}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                                {isLoading ? <Spinner color={"primary"}/>:
                                (quotes || []).map((record, i) => {
                                    return (
                                        <tbody>
                                        <tr key={i}>
                                            <td className="hoverableItem align-middle text-center d-print-none"
                                                    onClick={() => this.handleChange("toggleOrderCollapse", !record.isOpen, i, record)}>
                                                <Button color={"primary"}
                                                    size={"sm"}
                                                        outline={record.isOpen}>
                                                    <i className={classnames("fa", {
                                                        "fa-plus-circle": !record.isOpen,
                                                        "fa-minus-circle": record.isOpen,
                                                    })} />
                                                </Button>
                                            </td>

                                            {(store || []).map((storeItem, index) => {
                                                return (
                                                    <td key={index} className={storeItem.valueClassName}>
                                                        {storeItem.render(record[storeItem.key], record, quotes, i, this)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {
                                                record.isOpen
                                                    ? <tr>
                                                        {(record.items || []).length === 0 ?
                                                            <td colSpan={(store || []).length+1}>No quote Items found...</td>:
                                                 <td colSpan={store.length + 1}>
                                                    <Table striped bordered responsive hover size='sm'>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                        {(quoteItemStore || []).map((item, index) => {
                                                                    return (
                                                                        <th key={index}
                                                                            colSpan={item.colSpan}
                                                                            className={item.labelClassName}
                                                                            style={{ minWidth: item.minWidth }}>
                                                                            {item.label}
                                                                        </th>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </thead>
                                                                <tbody>
                                                                    {(record.items || []).map((item, itemIndex) =>
                                                                <tr key={itemIndex} className={"bg-white"}>
                                                                            {(quoteItemStore || []).map((column, indrx) => {
                                                                        return (
                                                                            <td key={indrx}
                                                                                className={column.valueClassName}>
                                                                                {column.render(item[column.key], item, record.items,itemIndex, this)}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                                            }
                                            </tr>
                                            : null
                                        }
                                    </tbody>
                                    )})
                            }
                                {
                                    (!isLoading && (quotes || []).length === 0)
                                        ? <tr>
                                            <td colSpan={(store || []).length +1}>No quotes found...</td>
                                        </tr>
                                        : null
                                }
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }
}