import React, {Component} from 'react';
import fabricService from "../../services/FabricService";
import AsyncSelect from 'react-select/async';


export default class SearchFabric extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: {},
            options: [],
        };

        this.loadOptions = this.loadOptions.bind(this);

    }


    componentDidMount() {

        let fabricID = this.props.defaultFabricID ? this.props.defaultFabricID : this.props.selectedFabricID;

        this.setState({ selected: { value: fabricID, label: "" }}, () => {
            //defaultOptions on load
            this.fetchOptions({ID: fabricID, searchText: ""});
        });

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selected && this.state.selected.value !== nextProps.selectedFabricID) {
            //load externally selectedItem
            this.setState({ selected: { value: nextProps.selectedFabricID, label: "" } }, () => {
                //defaultOptions on load
                this.fetchOptions({ID: nextProps.selectedFabricID, searchText: ""});
            });
        }
    }


    handleSuccess = (selectedFabric) => {
        this.setState({selected: selectedFabric}, () => {
            this.props.handleSelection(selectedFabric);
            if (!selectedFabric) {
                this.fetchOptions({ID: null, searchText: ""}); //defaultOptions on load
            }
        });
    };

    handleSuccessForDefault = (selectedFabric) => {
        this.setState({selected: selectedFabric});
        if (selectedFabric) {
            this.props.handleSelection(selectedFabric);
        }
    };


    fetchOptions(req, callback) {
        let options = [];
        this.setState({loading: true});
        fabricService.fetchFabricDropdownOptions(req).then(response => {
            for (let i in response.data) {
                options.push(response.data[i]);
                if (this.state.selected) {
                    if (response.data[i].id === this.state.selected.value) {
                        this.handleSuccess(response.data[i]);
                    }
                }
                if (this.props.defaultFabricID) {
                    if (response.data[i].id === this.props.defaultFabricID) {
                        this.handleSuccessForDefault(response.data[i]);
                    }
                }

            }
            this.setState({options, loading: false});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                this.setState({loading: false});
                console.log(error.data)
            }
        );
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions({ID: null, searchText: inputValue}, callback)
            } else {
                callback(this.state.options);
            }
        }
    };

    render() {

        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.options}
                    loadOptions={this.loadOptions}
                    onChange={this.handleSuccess}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
