import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Table,
} from "reactstrap";
import {getLocalDateStringFromUtcDateString, getDateString} from '../../services/CommonService';
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import queryString from 'query-string';

const operationalRowStyle = {

    fontWeight: "bold",
    borderTop: "solid",
    backgroundColor: "#eeeeee",
};
export default class ProductionProductOrdersTable extends Component {

    constructor(props) {
        super(props);

    }


    render() {
        let {orders, dataModal} = this.props;
        //
        return (
            <div style={{overflow: "auto", overflowY: "hidden"}} className="table-responsive ">
                <Table hover size={"sm"} bordered>
                    <thead>
                    <tr>
                        {(dataModal.headerKeys || []).map((headerKey, index) => {
                            return (
                                <th key={index}>{headerKey}</th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>

                    {(orders || []).map((order, index) => {
                        return (
                            <tr key={index} style={{backgroundColor: order.backgroundColor}}>
                                {(dataModal.dataKeys || []).map((dataKey, index) => {
                                    this.doColumnOperation(order, dataKey);
                                    return (
                                        <td key={index}>
                                            {this.getColValue(order[dataKey.key], dataKey)}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}


                    <tr key={"total"} style={operationalRowStyle}>
                        {(dataModal.dataKeys || []).map((dataKey, index) => {
                            return (
                                <td key={index}>

                                    {
                                        (() => {
                                            if (dataKey.key === "custOrdDate") {
                                                return "Total";
                                            }
                                            switch (dataKey.key) {
                                                case "custOrdDate":
                                                    return "Total";
                                                case "ordnum":
                                                    return <span>{dataKey.operationResult}</span>;
                                                default :
                                                    this.getColValue(dataKey.operationResult, dataKey)
                                            }
                                        })()
                                    }
                                </td>
                            );
                        })}
                    </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    getColValue(value, dataKey) {

        switch (dataKey.type) {
            case "link":
                return <Link
                    className="btn btn-sm btn-primary"
                    style={{color: "white"}}
                    title={"Click here to view order in detail"}
                    to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}>{value}</Link>;

            case "date":
                return getDateString(value, 'DD MMM YYYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return <NumberFormat value={Math.floor(value)}
                                     displayType={'text'}
                                     thousandSeparator={true}
                                     decimalScale={2}
                                     fixedDecimalScale={true}
                                     prefix={'$'}/>;
            default :
                return <span>{value}</span>
        }
    }

    doColumnOperation(order, dataKey) {
        switch (dataKey.operation) {
            case "none":
                return;
            case "count":
                dataKey.operationResult++;
                break;
            case "sum":
                dataKey.operationResult += Math.floor(order[dataKey.key]);
                break;
            default :
                return;
        }
    }
}

