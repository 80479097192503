import React, {Component} from "react";
import {Spinner} from 'reactstrap';
import stockReceiptService from '../../../services/stock/StockReceiptService';
import {getCommonAddressString, handleErrorMessage} from '../../../services/CommonService';
import {toast, ToastContainer} from "react-toastify";
import SearchStockReceiveNavigator from './SearchStockReceiveNavigator'
import queryString from 'query-string'
import StockReceiptEnquiryReadOnlyMode from './StockReceiptEnquiryReadOnlyMode'

export default class StockReceiptEnquiryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockReceiptID: 0,
            isLoadingStockReceipt: false,
            isLoadingStockReceiptID: false,
            stockReceipt: {}
        };
        this.updateUrl = this.updateUrl.bind(this);
        this.getStockReceipt = this.getStockReceipt.bind(this);
    }

    componentDidMount() {
        let stockReceiptID = 0;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            stockReceiptID = searchParams.stockReceiptID;
        }

        this.setState({stockReceiptID}, () => {
            this.refresh()
        });
    }

    refresh() {
        if (!this.state.stockReceiptID) {
            this.getLastStockReceiptID();
        }
    }

    getLastStockReceiptID() {
        this.setState({isLoadingStockReceiptID: true});
        stockReceiptService.getStockReceiptIDByNavigation('last', null).then(response => {
            this.setState({isLoadingStockReceiptID: false, stockReceiptID: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
        })
    }

    updateUrl(stockReceiptID) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("stockReceiptID", stockReceiptID);

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    getStockReceipt(stockReceiptID) {
        this.updateUrl(stockReceiptID);
        if (stockReceiptID) {
            this.setState({stockReceiptID, isLoadingStockReceipt: true});
            stockReceiptService.getStockReceipt(stockReceiptID).then(response => {
                this.setState({isLoadingStockReceipt: false});
                let stockReceipt = response.data;
                stockReceipt.shippingAdrress = getCommonAddressString(stockReceipt);
                this.setState({stockReceipt});
            }).catch(error => {
                this.setState({isLoadingStockReceipt: false});
                toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
            })
        }
    }

    render() {
        let {stockReceiptID, isLoadingStockReceipt, isLoadingStockReceiptID, stockReceipt} = this.state;

        return (
            <div>
                <div>
                    <SearchStockReceiveNavigator stockReceiptID={stockReceiptID}
                                         history={this.props.history}
                                         isLoadingStockReceipt={isLoadingStockReceipt}
                                         isLoadingStockReceiptID={isLoadingStockReceiptID}
                                         getStockReceipt={this.getStockReceipt}/>
                </div>
                <hr/>
                {
                    isLoadingStockReceipt
                        ? <Spinner color={"primary"}/>
                        : <StockReceiptEnquiryReadOnlyMode
                            isLoadingStockReceipt={isLoadingStockReceipt}
                            stockReceipt={stockReceipt}
                            getStockReceipt={this.getStockReceipt}
                        />
                }

                <ToastContainer/>
            </div>

        );
    }
}