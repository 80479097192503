import React, { Component } from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    Nav,
    CardBody,
    CardHeader,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import ProductPage from "../product/ProductPage";
import ProductGroupPage from "../product/ProductGroupPage";
import ProductionLinePage from "../production/ProductionLinePage";
import StockGroupsPage from "../stock/StockGroupsPage";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';

const productsAllTabs = [
    {
        label: "Stock Group",
        key: "stockGroup"
    },
    {
        label: "Products",
        key: "product"
    },
    {
        label: "Product Groups",
        key: "product-group-page"
    },
    {
        label: "Production Lines",
        key: "production-line"
    }
];

export default class ProductsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "product"
        }
    }

    componentDidMount() {
        let { activeTab } = this.state;
        if (this.props.location.search) {
            let searchParam = queryString.parse(this.props.location.search);
            activeTab = searchParam.activeTab;
            this.setState({ activeTab });
        } 
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("activeTab", activeTab);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        /*let { activeTab } = this.state; 
        if (this.props.location.search) { 
            let searchParams = queryString.parse(this.props.location.search); 
            activeTab = searchParams.tab; 
        } */
        /*this.setState({ activeTab }); */
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("activeTab", tab);
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    }

    render() {
        let { activeTab } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Products</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {productsAllTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{ textDecoration: "none" }}
                                            size={"sm"}
                                            color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>

                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            {(activeTab == "stockGroup") ? <StockGroupsPage /> : null}
                            {(activeTab == "product") ? <ProductPage /> : null}
                            {(activeTab == "product-group-page") ? <ProductGroupPage /> : null}
                            {(activeTab == "production-line") ? <ProductionLinePage /> : null}
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer />
            </div>
        )
    }
}