import React, { Component } from 'react';
import { Badge, Button, ButtonGroup, CardHeader, Col, FormGroup, FormText, Input, Label, Modal, ModalBody, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';
import { handleErrorMessage } from '../../services/CommonService';
import StockService from '../../services/StockService';
import UploadStockThumbnails from '../stock/UploadStockThumbnails';
import StockUtil from '../util/StockUtil';

const allTabs = [
    {
        label: "Non-Stock ",
        key: "nonStockInfo"
    },
    {
        label: "Upload Attachments",
        key: "uploads"
    }
];

export default class ManageNonStockModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockGroups: [],
            stock: StockUtil.initStockObj(this.props.prodCode, true),
            formError: {},
            isLoadingSave: false,
            isLoading: false,
            isNewStock: false,
            activeTab: 'nonStockInfo',
            isSubmitted: false
        };

        this.stockService = new StockService();
        this.getStock = this.getStock.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getStockGroups = this.getStockGroups.bind(this);
    }

    componentDidMount() {
        this.getStockGroups();
        this.getStock(this.state.stock.prodCode);
    }

    getStock(prodCode) {
        let { stock } = this.state;
        let isNewStock = true;
        if (prodCode) {
            if (stock.wwStock.prodCode !== prodCode) {
                this.setState({ isLoading: true });
                this.stockService.getStock(prodCode).then(response => {
                    stock = StockUtil.initStockObj(prodCode, true);
                    if (response.data) {
                        isNewStock = false;
                        stock = StockUtil.updateStock(stock, response.data);
                    }
                    this.setState({ stock, isLoading: false, isNewStock });
                }).catch(error => {
                    isNewStock = true;
                    this.setState({ isLoading: false, isNewStock });
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
        } else {
            this.setState({ isLoading: false, isNewStock });
        }
    }

    getStockGroups() {
        this.stockService.getStockGroups().then(response => {
            this.setState({ stockGroups: response.data });
        }).catch(error => {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(value, key) {
        let { stock, isSubmitted } = this.state;
        switch (key) {
            case "prodCode":
                stock[key] = value;
                this.setState({ stock });
                break;
            case "description":
                stock[key] = value;
                stock.keywayStock[key] = value;
                stock.wwStock[key] = value;
                this.setState({ stock });
                break;
            case "notes":
                stock.keywayStock[key] = value;
                stock.wwStock[key] = value;
                this.setState({ stock });
                break;
            case "eachUnitPrice":
                stock.keywayStock[key] = value;
                stock.keywayStock.sellQtyPrice = value;
                stock.wwStock.price1 = value;
                this.setState({ stock });
                break;
            case "nonStockType":
                stock.keywayStock[key] = value;
                this.setState({ stock });
                break;
            case "available":
                stock.wwStock[key] = value;
                stock.wwStock.discontinued = !value;
                stock.wwStock.soldOut = !value;
                this.setState({ stock });
                break;
            case "stockGroupID":
                stock.wwStock[key] = value;
                this.setState({ stock });
                break;
        }
        if (isSubmitted) {
            let formError = this.validateForm(stock);
            this.setState({ formError });
        }
    }

    validateForm(stock) {
        let formError = {
            isValid: true,
            errors: []
        };
        let message = "";
        if (!stock.prodCode) {
            message = "Empty ProdCode!";
            formError.prodCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.prodCode && stock.prodCode.length > 16) {
            message = "ProdCode should be less than " + 16 + " characters!";
            formError.prodCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.description) {
            message = "Empty Description!";
            formError.description = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.description && stock.description.length > 50) {
            message = "Description should be less than " + 50 + " characters!";
            formError.description = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (["", null, undefined].includes(stock.keywayStock.eachUnitPrice)) {
            message = "Empty Price!";
            formError.eachUnitPrice = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.keywayStock.nonStockType) {
            message = "Empty Type!";
            formError.nonStockType = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (stock.keywayStock.nonStockType && stock.keywayStock.nonStockType.length > 50) {
            message = "Type should be less than " + 50 + " characters!";
            formError.nonStockType = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!stock.wwStock.stockGroupID) {
            message = "Empty Stock Group!";
            formError.stockGroupID = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        return formError;
    }

    handleSubmit(e) {
        e.preventDefault();
        let { stock } = this.state;
        let formError = this.validateForm(stock);
        this.setState({ formError, isSubmitted: true });
        if (!formError.isValid) {
            formError.errors.forEach(error => {
                toast.info(error);
            });
            return;
        }
        let req = cloneDeep(stock);
        req.wwStock.prodCode = stock.prodCode;
        req.keywayStock.prodCode = stock.prodCode;
        req.keywayStock.isNonStock = true;
        this.setState({ isLoadingSave: true });
        this.stockService.saveStock(req).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success("Saved!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({ isLoadingSave: false });
                this.props.refreshAgain(stock.prodCode);
                this.props.toggle(false);
            }
        }).catch(error => {
            this.setState({ isLoadingSave: false });
            toast.error(handleErrorMessage(error));
        });
    }

    toggleTab(activeTab) {
        this.setState({ activeTab });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { stock, isNewStock, formError, isLoading, isLoadingSave, activeTab, stockGroups } = this.state;
        return (
            <div>
                <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                    <CardHeader>
                        <button type={"button"} className="close" onClick={() => toggle(!isOpen)}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <Nav tabs card>
                            {allTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => this.toggleTab(tab.key)}>
                                        <Button style={{ textDecoration: "none" }}
                                            size={"sm"}
                                            color={"link"}>
                                            {tab.label}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <ModalBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="nonStockInfo">
                                {isLoading
                                    ? <Spinner color="primary" />
                                    : <div>
                                        <Row>
                                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>
                                                        ProdCode
                                                        {(activeTab === "nonStockInfo" && stock.prodCode)
                                                            ? <Badge color={"primary"} size={"sm"}
                                                                className={"ml-2"}>{((isNewStock) ? "New" : "Edit")}</Badge>
                                                            : ""}
                                                    </Label>
                                                    <Input
                                                        invalid={formError.prodCode}
                                                        type="text"
                                                        value={stock.prodCode}
                                                        onBlur={() => this.getStock(stock.prodCode)}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        name="prodCode"
                                                        placeholder="prodCode">
                                                    </Input>
                                                    <FormText color="danger">{formError.prodCode}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Description</Label>
                                                    <Input
                                                        invalid={formError.description}
                                                        type="text"
                                                        name="description"
                                                        value={stock.description}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="description">
                                                    </Input>
                                                    <FormText color="danger">{formError.description}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3} md={3} sm={3} xs={6}>
                                                <FormGroup>
                                                    <Label>Status</Label>
                                                    <div>
                                                        <ButtonGroup>
                                                            <Button
                                                                size={"sm"}
                                                                name={"available"}
                                                                onClick={(e) => this.handleChange(true, e.target.name)}
                                                                color={stock.wwStock.available ? "success" : "primary"}

                                                                outline={!stock.wwStock.available}>
                                                                Active
                                                                {stock.wwStock.available
                                                                    ? <i className="fa fa-check ml-2"
                                                                        aria-hidden="true" />
                                                                    : null
                                                                }</Button>
                                                            <Button
                                                                onClick={(e) => this.handleChange(false, e.target.name)}
                                                                color={stock.wwStock.available ? "primary" : "danger"}
                                                                size={"sm"} name={"available"}
                                                                outline={stock.wwStock.available}>
                                                                Inactive
                                                                {stock.wwStock.available
                                                                    ? null
                                                                    :
                                                                    <i className="fa fa-check ml-2" aria-hidden="true" />
                                                                }</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Price</Label>
                                                    <Input
                                                        invalid={formError.eachUnitPrice}
                                                        type="number"
                                                        name="eachUnitPrice"
                                                        placeholder="Price"
                                                        min={0}
                                                        value={stock.keywayStock.eachUnitPrice}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />
                                                    <FormText
                                                        color="danger">{formError.eachUnitPrice}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Type</Label>
                                                    <Input
                                                        invalid={formError.nonStockType}
                                                        type="select"
                                                        name="nonStockType"
                                                        value={stock.keywayStock.nonStockType}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    >
                                                        <option value="" disabled>Select</option>
                                                        <option value="Service">Service</option>
                                                        <option value="Option">Option</option>
                                                    </Input>
                                                    <FormText color="danger">{formError.nonStockType}</FormText>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Stock Group</Label>
                                                    <Input
                                                        invalid={formError.stockGroupID}
                                                        type="select"
                                                        name="stockGroupID"
                                                        value={stock.wwStock.stockGroupID}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    >
                                                        <option value="" disabled>Select</option>
                                                        {(stockGroups || []).map(sg => {
                                                            return <option value={sg.stockGroupID}>{sg.name}</option>
                                                        })}
                                                    </Input>
                                                    <FormText
                                                        color="danger">{formError.stockGroupID}</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label>Notes</Label>
                                                    <Input
                                                        type="textarea"
                                                        name="notes"
                                                        rows={4}
                                                        value={stock.keywayStock.notes}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                        placeholder="notes" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <div className={"text-right mt-2"}>
                                            <Button size={"sm"} color='primary' onClick={this.handleSubmit}
                                                disabled={isLoadingSave}>
                                                {isLoadingSave ? <Spinner size="sm" className="mr-2"
                                                    style={{ color: "white" }} /> :
                                                    <i className="fa fa-floppy-o mr-2"
                                                        aria-hidden="true" />}
                                                Save
                                            </Button>{' '}
                                            <Button color='secondary' size={"sm"}
                                                onClick={() => toggle(!isOpen)}>
                                                <i className="fa fa-times mr-2" />
                                                Cancel
                                            </Button>{' '}</div>
                                    </div>
                                }
                            </TabPane>
                            <TabPane tabId="uploads">
                                <UploadStockThumbnails
                                    toggleCloseModal={() => toggle(!isOpen)}
                                    prodCode={stock.keywayStock.prodCode}
                                    isNewStock={isNewStock} />
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal>
                <ToastContainer />
            </div>
        );
    }
}