import React, {Component} from 'react';
import {Button, Col, Input, ListGroup, ListGroupItem, Progress, Row, Spinner} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import AttachmentService from '../../services/AttachmentService';
import "../../assets/css/app/FilesUpload.css";
import {findIndex, handleErrorMessage} from "../../services/CommonService";
import FileSaver from 'file-saver';


export default class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachmentIDs: "",
            attachments: [],
            uploadProgress: 0,
            downloadProgress: 0,
            loadingAttachments: false

        };

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleDownloadProgress = this.handleDownloadProgress.bind(this);
        this.handleUploadProgress = this.handleUploadProgress.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.fetchAttachments = this.fetchAttachments.bind(this);
        this.attachmentService = new AttachmentService();
    }

    componentDidMount() {
        if (this.props.attachmentIDs) {
            this.setState({attachmentIDs: this.props.attachmentIDs}, () => {
                if (this.props.attachmentIDs) {
                    this.fetchAttachments(this.props.attachmentIDs);
                }
            });
        } else {
            this.setState({attachmentIDs: "", attachments: []});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.attachmentIDs !== nextProps.attachmentIDs) {
            if (nextProps.attachmentIDs) {
                this.setState({attachmentIDs: nextProps.attachmentIDs}, () => {
                    if (nextProps.attachmentIDs) {
                        this.fetchAttachments(nextProps.attachmentIDs);
                    }
                });
            } else {
                this.setState({attachmentIDs: "", attachments: []});
            }
        }
    }

    fetchAttachments(IDs) {
        this.setState({loadingAttachments: true});
        this.attachmentService.getparticularAttachments(IDs).then(response => {
            if (response.data) {
                this.setState({attachments: response.data, loadingAttachments: false});
            }
        }).catch(error => {
            console.log(error.data);
            this.setState({loadingAttachments: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleUploadProgress(progressEvent) {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
            this.setState({uploadProgress: percent});
        }
    }

    handleFileUpload(file) {
        this.setState({isUploading: true});
        let formData = new FormData();
        formData.append('files', file);
        formData.append('category', this.props.category ?  this.props.category : "");
        this.attachmentService.uploadAttachments(formData, this.handleUploadProgress).then(response => {
            if (response.data) {
                let element = document.getElementById("fileInputId");
                if (element) {
                    element.value = "";
                }
                let {attachments, attachmentIDs} = this.state;
                let index = -1;
                response.data.forEach(uf => {
                    index = findIndex(attachments, 'id', uf.id);
                    if (index === -1) { // duplicity check
                        attachments.push(uf);
                    }
                });
                let attachmentsIDsArr = attachments.map(a => a.id);
                attachmentIDs = attachmentsIDsArr.join();
                this.setState({attachments, attachmentIDs, uploadProgress: 0}, () => {
                    this.props.handleChange(attachmentIDs);
                });
            }
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({uploadProgress: 0});
        });
    }

    handleDownloadProgress(progressEvent, attachmentId) {
        let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        let {attachments} = this.state;
        let index = findIndex(attachments, 'id', attachmentId);
        attachments[index].downloadProgress = percent;
        this.setState({attachments});
    }

    handleFileDownload(item) {
        let options = {
            onDownloadProgress: (progressEvent) => this.handleDownloadProgress(progressEvent, item.id)
        };
        this.attachmentService.DownloadAttachment(item.id, options).then(response => {
            FileSaver.saveAs(response.data, item.description);
            let {attachments} = this.state;
            let index = findIndex(attachments, 'id', item.id);
            attachments[index].downloadProgress = 0;
            this.setState({attachments});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            let {attachments} = this.state;
            let index = findIndex(attachments, 'id', item.id);
            attachments[index].downloadProgress = 0;
            this.setState({attachments});
        });
    }

    handleFileChange(event) {
        let files = event.target.files;
        let { attachments } = this.state;
        const { maxLength } = this.props;
        if ((attachments.length + files.length) > maxLength) {
            toast.error(`Cannot upload more than ${maxLength} photos!`, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        for (let i = 0; i < files.length; i++) {
            this.handleFileUpload(files[i], event);
        }
    }


    deleteHandler(attachmentID) {
        let {attachments, attachmentIDs} = this.state;
        let index = findIndex(attachments, 'id', attachmentID);
        if (index > -1) {
            attachments[index].loadingDelete = true;
        }
        this.setState({attachments});
        this.attachmentService.deleteAttachment(attachmentID).then(response => {
            if (response.data) {

                index = findIndex(attachments, 'id', response.data.id);
                if (index > -1) {
                    attachments.splice(index, 1);
                }
                let attachmentsIDsArr = attachments.map(a => a.id);
                attachmentIDs = attachmentsIDsArr.join();
                this.setState({ attachments, attachmentIDs }, () => {
                    this.props.handleChange(attachmentIDs);
                });
                toast.success("Deleted", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            let index = findIndex(attachments, 'id', attachmentID);
            if (index > -1) {
                attachments[index].loadingDelete = false;
            }
            this.setState({attachments});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {
            attachments,
            uploadProgress,
            loadingAttachments
        } = this.state;
        let { isReadOnly, multiple } = this.props;
        return (
            <div>
                {
                    isReadOnly
                        ? null
                        : <div>
                            <Row className={"m-0"}>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    {/*<p>Choose file to upload</p>*/}
                                    <div className="text-center">
                                        <Input type="file"
                                            name="attachmentFile"
                                            id={"fileInputId"}
                                            onChange={this.handleFileChange}
                                            aria-label="choose file"
                                            multiple={multiple}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-1">
                                {
                                    uploadProgress > 0
                                        ? <div className={"mt-1"}>
                                            {uploadProgress}%&nbsp;Uploaded
                                            <Progress animated value={uploadProgress}/>
                                        </div>

                                        : null
                                }
                            </div>
                        </div>
                }

                {
                    loadingAttachments
                        ? <Spinner color={"primary"}/>
                        : <ListGroup flush>
                            {(attachments || []).map((a, aIndex) => {
                                return <ListGroupItem key={aIndex} className={"hoverableItem"}>
                                    <Row className={"align-items-center"}>
                                        <Col xl={{size: 'auto'}}
                                             lg={{size: 'auto'}}
                                             md={{size: 'auto'}}
                                             sm={{size: 'auto'}}
                                             xs={{size: 'auto'}}
                                             className={"pl-0"}>
                                            <Button color="link" size={"sm"}
                                                    title={"Click here to download"}
                                                    onClick={() => this.handleFileDownload(a)}>
                                                <i className="fa fa-paperclip mr-1" aria-hidden="true"/> {a.description}
                                                {
                                                    isReadOnly
                                                        ? null
                                                        : <i className="fa fa-check ml-1 text-success" title={"uploaded"}
                                                             aria-hidden="true"/>
                                                }

                                            </Button>

                                            {
                                                isReadOnly
                                                    ? null
                                                    : <Button color="link"
                                                              size={"sm"}
                                                              className={"ml-2"}
                                                              title={"Click here to remove this file"}
                                                              disabled={a.loadingDelete}
                                                              onClick={() => this.deleteHandler(a.id)}>
                                                        {
                                                            a.loadingDelete
                                                                ? <Spinner color={"danger"} size={"sm"}/>
                                                                :
                                                                <i className="fa fa-trash text-danger" aria-hidden="true"/>
                                                        }
                                                    </Button>
                                            }
                                        </Col>
                                        {
                                            (a.downloadProgress > 0 && a.downloadProgress < 100)
                                                ? <Col>
                                                    <Progress animated value={a.downloadProgress}/>
                                                </Col>
                                                : null
                                        }

                                    </Row>

                                </ListGroupItem>
                            })}
                        </ListGroup>
                }
                <ToastContainer/>
            </div>
        )
    }
}