import moment from 'moment';
import queryString from 'query-string';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row, Spinner } from 'reactstrap';
import SearchWINOCreditor from '../../components/search/SearchCreditorAcccount';
import SearchWINOnlineStock from '../../components/search/SearchWINOnlineStock';
import { getDateObj ,handleErrorMessage} from '../../services/CommonService';
import StockSupplierService from '../../services/StockSupplierService';

class ManageStockSupplierPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: null,
            stockSupplierObj: {
                prodCode: '',
                isPreferred: false,
                stock: {},
                supplierId: 0,
                moq1: '',
                leadTimeInDays: '',
                lastPurchasedDate: null,
                preferenceRating: '',
                note: '',
                supplier: {}
            },
            suppliers: [],
            creditorID: null,//used when coming from suppliers page
            FormErrors: {},
            fromStockManage: false,//used when coming from manage stock page
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectCheckbox = this.selectCheckbox.bind(this);
        this.handleStockChange = this.handleStockChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSupplierChange = this.handleSupplierChange.bind(this);
    }

    componentDidMount() {
        
        let { id,fromStockManage,creditorID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            id = searchParams.id;
            creditorID = searchParams.creditorID;
            fromStockManage = searchParams.fromStockManage ? true : false;
            this.setState({ id,fromStockManage,creditorID } ,() => {
                this.refresh()
            })
        }
        else if (this.props.location.stockObj) {
            let { stockSupplierObj } = this.state;
            stockSupplierObj.stock = this.props.location.stockObj.stock;
            this.setState({
                stockSupplierObj: stockSupplierObj, fromStockManage: this.props.location.stockObj.fromStockManage
            })
        }
        else if(this.props.location.state.creditorID) {
            let { stockSupplierObj } = this.state;
            creditorID = parseInt(this.props.location.state.creditorID);
            stockSupplierObj.supplierId = creditorID;
            this.setState({ stockSupplierObj,creditorID });
        }
        StockSupplierService.getAllSuppliers().then(response => {
            this.setState({ suppliers: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    refresh() {
        if (this.state.Id !== null) {
            this.setState({ loading: true })
            StockSupplierService.getOne(this.state.id).then(response => {
                let stockSupplierObj = response.data;
                if (response.data.lastPurchasedDate) {
                    let lastPurchasedDate = getDateObj(response.data.lastPurchasedDate, "DD/MM/YYYY");
                    stockSupplierObj.lastPurchasedDate = lastPurchasedDate;
                }
                this.setState({
                    id: response.data.id,
                    stockSupplierObj: {
                        isPreferred: stockSupplierObj.isPreferred, supplierId: stockSupplierObj.supplier.id,
                        prodCode: stockSupplierObj.prodCode, stock: stockSupplierObj.stock, moq1: stockSupplierObj.moq1,
                        note: stockSupplierObj.note, preferenceRating: stockSupplierObj.preferenceRating, lastPurchasedDate:
                            stockSupplierObj.lastPurchasedDate, leadTimeInDays: stockSupplierObj.leadTimeInDays
                    },
                    loading: false
                });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        else if (this.state.creditorID !== null) {
            let { stockSupplierObj,creditorID } = this.state;
            stockSupplierObj.supplierId = creditorID;
            this.setState({ stockSupplierObj });
        }
    }


    handleChange(e) {
        const { name, value } = e.target;
        let { stockSupplierObj } = this.state;
        stockSupplierObj[name] = value;
        this.setState({ stockSupplierObj });
    }

    handleSupplierChange(stockSupplier) {
        let { stockSupplierObj } = this.state;
        stockSupplierObj.supplierId = stockSupplier ? stockSupplier.id:0;
        stockSupplierObj.supplier= stockSupplier;
        this.setState({ stockSupplier });
    }

    handleDateChange(date, key) {
        let { stockSupplierObj } = this.state;

        stockSupplierObj[key] = date;
        this.setState({ stockSupplierObj });
    };

    handleStockChange(change, key) {
        let { stockSupplierObj } = this.state;
        stockSupplierObj.prodCode = change;
        this.setState({ stockSupplierObj: stockSupplierObj})
    }


    handleCancel() {
        let { stockSupplierObj, fromStockManage,creditorID } = this.state;
        fromStockManage ? this.props.history.push('/keyway-inventory/stock/enquiry')
            : creditorID ? this.props.history.push('/keyway-inventory/stock-supplier/supplier?supplierID=' + creditorID)
                : this.props.history.push('/keyway-inventory/stock-supplier');
    }

    validateForm(stockSupplierObj) {
        // true means invalid
        let FormErrors = {};
        let valid = true;
        if (stockSupplierObj.preferenceRating === null || stockSupplierObj.preferenceRating === '') {
            FormErrors.preferenceRating = "Please enter valid Preference rating";
            valid = false;
        }
        if (stockSupplierObj.moq1 === null || stockSupplierObj.moq1 === '') {
            FormErrors.moq = "Please enter valid moq";
            valid = false;
        }
        if (stockSupplierObj.leadTimeInDays === null || stockSupplierObj.leadTimeInDays === '') {
            FormErrors.leadTimeInDays = "Please enter valid lead time";
            valid = false;
        }
        if (stockSupplierObj.note.length === 0) {
            FormErrors.note = "Please enter note";
            valid = false;
        }
        if (stockSupplierObj.prodCode === null || stockSupplierObj.prodCode === undefined || stockSupplierObj.prodCode ==='') {
            FormErrors.prodCode = "Please select a stock";
            valid = false;
        }
        
        if (stockSupplierObj.lastPurchasedDate === null || stockSupplierObj.lastPurchasedDate === undefined) {
            FormErrors.lastPurchasedDate = "Please select last purchased date";
            valid = false;
        }
        if (stockSupplierObj.supplierId === 0 || stockSupplierObj.supplierId === '') {
            FormErrors.supplierId = "Please select a supplier";
            valid = false;
        }

        return { FormErrors: FormErrors, valid: valid }
    }

    selectCheckbox(value, name) {
        let { stockSupplierObj } = this.state;
        stockSupplierObj[name] = !value;
        this.setState({ stockSupplierObj });
    }

    handleSubmit(e) {
        e.preventDefault();
        let { id, stockSupplierObj,fromStockManage } = this.state;
        if (id !== null) {
            stockSupplierObj.prodCode = stockSupplierObj.stock.prodCode;
        }
        
        
        let req = { ...this.state.stockSupplierObj };
        if (req.lastPurchasedDate) {
            req.lastPurchasedDate = moment(req.lastPurchasedDate).format('DD/MM/YYYY');
        }
        let result = this.validateForm(req);
        if (!result.valid) {
            this.setState({ FormErrors: result.FormErrors });

            return;
        }


        if (result.valid) {
            if (this.state.id !== null) {
                this.setState({ saving: true });
                let request = {
                    prodCode: stockSupplierObj.prodCode, isPreferred: stockSupplierObj.isPreferred,
                    lastPurchasedDate: req.lastPurchasedDate, leadTimeInDays: stockSupplierObj.leadTimeInDays,
                    moq1: stockSupplierObj.moq1, preferenceRating: stockSupplierObj.preferenceRating, note: stockSupplierObj.note,
                    supplierId: stockSupplierObj.supplierId,id:this.state.id
                }
                StockSupplierService.updateStockSupplier(request).then(response => {
                    this.setState({ saving: false });
                    fromStockManage ? this.props.history.push('/inventory/stocks-manage?' + queryString.stringify({ prodCode: req.prodCode }))
                    : this.props.history.push('/keyway-inventory/stock-supplier');
                    toast.success("Stock supplier updated successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }).catch(error => {
                    this.setState({ saving: false });
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
            else {
                this.setState({ saving: true });
                let request = {
                    prodCode: stockSupplierObj.prodCode, isPreferred: stockSupplierObj.isPreferred,
                    lastPurchasedDate: req.lastPurchasedDate, leadTimeInDays: stockSupplierObj.leadTimeInDays,
                    moq1: stockSupplierObj.moq1, preferenceRating: stockSupplierObj.preferenceRating, note: stockSupplierObj.note,
                    supplierId: stockSupplierObj.supplierId
                }
                StockSupplierService.addStockSupplier(request).then(response => {
                    if (response.status == 200 || response.status == "200") {
                        fromStockManage ?
                            this.props.history.push('/keyway-inventory/stock/enquiry')

                            :
                            this.props.history.push('/keyway-inventory/stock-supplier');
                        
                        toast.success("Stock supplier saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({ saving: false });
                    }
                }).catch(error => {
                    this.setState({ saving: false });
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
        }
        else {
            this.setState({ saving: false });
            toast.info("Please fill required details.. !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }

    }

    render() {
        let { stockSupplierObj, FormErrors, loading, id, suppliers, creditorID, fromStockManage } = this.state;
        const errors = FormErrors;
        let err = Object.keys(errors).filter(x => errors[x]);
        return (
            <div>
                {fromStockManage ? <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/keyway-inventory/stock/enquiry')}>Stocks</BreadcrumbItem>
                    {/*<BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/stock/enquiry?' + queryString.stringify({ prodCode: stockSupplierObj.stock.prodCode }))}>Edit</BreadcrumbItem>*/}
                    <BreadcrumbItem active tag="span">{id === null ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>
                    :
                    creditorID !== null ? <Breadcrumb tag="nav" listTag="div">
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/creditor/list')}>Suppliers</BreadcrumbItem>
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/keyway-inventory/stock-supplier/supplier?supplierID='+creditorID)}>Stock Suppliers</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">{id === null ? 'Add' : 'Edit' }</BreadcrumbItem>
                    </Breadcrumb> 
                        :
                    <Breadcrumb tag="nav" listTag="div">
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/keyway-inventory/stock-supplier')}>Stock
                        Suppliers</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">{id === null ? 'Add' : 'Edit'}</BreadcrumbItem>
                    </Breadcrumb>
                }
                

                <Row>
                    <Col xl={12} lg={12} md={12} sm={18} xs={18}>
                        {
                            loading
                                ? <Spinner color="primary" />
                                : <Card>
                                    <CardHeader>
                                        <h5>Manage Stock Supplier</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <Row>

                                                <Col lg={4} md={4} sm={8} xs={8}>
                                                    <FormGroup>
                                                        <Label>Supplier</Label>
                                                        {id !== null || creditorID !== null ?
                                                            <Input
                                                                invalid={FormErrors.supplierId === ''}
                                                                className="form-control"
                                                                name="supplierId"
                                                                type="select"
                                                                disabled={id !== null || creditorID !== null}
                                                                value={stockSupplierObj.supplierId}
                                                                onChange={this.handleChange}>
                                                                {suppliers.filter((item) => item.id ===  stockSupplierObj.supplierId).map((item) =>
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.accountId + ' | ' + item.company}</option>
                                                                )}
                                                            </Input> :
                                                            <SearchWINOCreditor
                                                                handleAccountChange={(selectedSupplier) => this.handleSupplierChange(selectedSupplier, 'supplier')}
                                                                selectedAccountID={stockSupplierObj.supplier ? stockSupplierObj.supplier.accountID : null}
                                                                defaultAccountID={stockSupplierObj.supplier ? stockSupplierObj.supplier.accountID : null} />
                                                            
                                                        }
                                                        <FormText color="danger">{FormErrors.supplierId}</FormText>
                                                    </FormGroup>
                                                </Col>

                                                <Col lg={4} md={4} sm={8} xs={8}>
                                                    {id === null ?
                                                        <FormGroup>
                                                            <Label>Stock</Label>
                                                            <SearchWINOnlineStock
                                                                handleSelection={(selectedProdCode) => this.handleStockChange(selectedProdCode, 'prodCode')}
                                                                selectedProdCode={stockSupplierObj.prodCode}
                                                                defaultProdCode={stockSupplierObj.prodCode} />

                                                            <FormText color="danger">{FormErrors.prodCode}</FormText>
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Label>Stock</Label>
                                                            <Input
                                                                className="form-control"
                                                                name="stock"
                                                                type="select"
                                                                disabled={id !== null}
                                                                value={stockSupplierObj.stock.id}
                                                                onChange={this.handleChange}>
                                                                <option key={stockSupplierObj.stock.id} value={stockSupplierObj.stock.prodCode}>
                                                                    {stockSupplierObj.stock.prodCode + ' | ' + stockSupplierObj.stock.description}
                                                                </option>

                                                            </Input>
                                                            <FormText color="danger">{FormErrors.prodCode}</FormText>
                                                        </FormGroup>
                                                    }
                                                </Col>

                                                <Col lg={4} md={4} sm={12} xs={12}>
                                                    <FormGroup>
                                                        <Label>Last Purchased Date</Label>
                                                        <DatePicker
                                                            name="lastPurchasedDate"
                                                            className="form-control"
                                                            selected={stockSupplierObj.lastPurchasedDate}
                                                            onChange={date => this.handleDateChange(date, "lastPurchasedDate")}
                                                            dateFormat="dd/MM/yyyy"
                                                            isClearable
                                                            placeholderText="DD/MM/YYYY"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            maxDate={new Date()}
                                                            scrollableYearDropdown
                                                            dropdownMode="select"
                                                            withPortal
                                                        />
                                                        <FormText color="danger">{FormErrors.lastPurchasedDate}</FormText>
                                                    </FormGroup>
                                                </Col>

                                                

                                            </Row>

                                            <Row>

                                                <Col lg={3} md={3} sm={12} xs={12}>
                                                    <FormGroup>
                                                        <Label>Preference Rating</Label>
                                                        <Input
                                                            invalid={FormErrors.preferenceRating !== undefined}
                                                            className="form-control"
                                                            name="preferenceRating"
                                                            type="number"
                                                            min="0"
                                                            value={stockSupplierObj.preferenceRating || ''}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter preference rating..." />
                                                        <FormText color="danger">{FormErrors.preferenceRating}</FormText>
                                                    </FormGroup>
                                                </Col>

                                                <Col lg={3} md={3} sm={12} xs={12}>
                                                    <FormGroup>
                                                        <Label>Lead time (in days)</Label>
                                                        <Input
                                                            invalid={FormErrors.leadTimeInDays !== undefined}
                                                            className="form-control"
                                                            name="leadTimeInDays"
                                                            type="number"
                                                            min="0"
                                                            value={stockSupplierObj.leadTimeInDays || ''}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter lead time in days here..." />
                                                        <FormText color="danger">{FormErrors.leadTimeInDays}</FormText>
                                                    </FormGroup>
                                                </Col>

                                                <Col lg={3} md={3} sm={12} xs={12}>
                                                    <FormGroup>

                                                        <Label>Minimum Order Quantity (MOQ)</Label>
                                                        <Input
                                                            invalid={FormErrors.moq !== undefined}
                                                            name="moq1"
                                                            type="number"
                                                            value={stockSupplierObj.moq1 || ''}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter MOQ here..." />
                                                        <FormText color="danger">{FormErrors.moq}</FormText>

                                                    </FormGroup>
                                                </Col>

                                                <Col lg={2} md={2} sm={12} xs={12}>
                                                    <FormGroup className="mt-4">
                                                        <div className="text-center">
                                                            <a href={"javascript:void(0)"}
                                                                onClick={() => this.selectCheckbox(stockSupplierObj.isPreferred, "isPreferred")}>
                                                                {!stockSupplierObj.isPreferred ?
                                                                    <i className="fa fa-lg fa-square-o" aria-hidden="true" /> :
                                                                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true" />}
                                                            </a>&nbsp;&nbsp;Is Preferred
                                                        </div>
                                                    </FormGroup>
                                                </Col>

                                                </Row>

                                                <Row>
                                                
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <FormGroup>
                                                        <Label>Note</Label>
                                                        <Input
                                                            invalid={FormErrors.note !== undefined}
                                                            className="form-control"
                                                            type="textarea"
                                                            name="note"
                                                            value={stockSupplierObj.note}
                                                            onChange={(e) => this.handleChange(e)}
                                                            placeholder="Enter note here..." />
                                                        <FormText color="danger">{FormErrors.note}</FormText>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </CardBody>
                                    <CardFooter>
                                        <div className={"text-right"}>
                                            <Button color='primary' onClick={this.handleSubmit}
                                                disabled={this.state.saving}>

                                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                    style={{ color: "white" }} /> :
                                                    <i className="fa fa-floppy-o mr-2"
                                                        aria-hidden="true" />}
                                                Save</Button>{' '}
                                            <Button color='secondary' onClick={this.handleCancel}>
                                                <i className="fa fa-times mr-2" />
                                                Cancel</Button>{' '}</div>
                                    </CardFooter>
                                </Card>
                        }
                    </Col>

                </Row>


                <ToastContainer />
            </div>
        );
    }
}

export default withRouter(ManageStockSupplierPage)