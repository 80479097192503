import React, {Component} from 'react';
import {Col, Card, CardBody, CardText, CardHeader} from "reactstrap";
import NumberFormat from "react-number-format";
import SalesOrderService from '../../services/WINOInventory/SalesOrderService';
import {Link} from "react-router-dom";
import BranchService from '../../services/BranchService';
import { findIndex,handleErrorMessage } from '../../services/CommonService';
import { ToastContainer, toast } from 'react-toastify';

export default class SubmittedOrderCountCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submittedOrderCount: 0,
            loading: true,
            branches: [],
            branch: 0
        };

        this.branchService = new BranchService();
        this.getBranches = this.getBranches.bind(this);
        this.getOrderCount = this.getOrderCount.bind(this);
    }

    componentDidMount() {
        this.getBranches();
    }

    getOrderCount() {
        this.setState({ loading: true });
        SalesOrderService.getCountOfSubmittedOrder(this.state.branch).then(response => {
            this.setState({ submittedOrderCount: response.data, loading: false })
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });;
    }

    getBranches() {
        let { branch } = this.state;
        this.branchService.fetchBranches().then(response => {
            let branches = response.data;

            // default branch set to be BRISBANE
            let index = findIndex(branches, "shortName", "BRISBANE");
            if (index !== -1) {
                branch = branches[index].branchNum;
            }

            this.setState({ branches, branch }, () => this.getOrderCount());
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        const {submittedOrderCount, loading} = this.state;

        if (loading) {
            return null;
        }

        return (
            <Col xl={3} lg={3} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Submitted Orders
                            <span style={{float: "right"}}>
                            <Link to="/sales/ordergateway">
                        <i className={"fa fa-pencil"}/>
                            </Link>
                        </span></h6>
                    </CardHeader>
                    <CardBody>
                        <h5>
                            <NumberFormat value={submittedOrderCount}
                                          displayType={'text'}
                                          thousandSeparator={true}/>
                            <small className={"ml-1"}>Orders</small>
                        </h5>
                    </CardBody>
                </Card>
                <ToastContainer />
            </Col>
        );
    }
}