import React, {Component} from "react";
import {toast, ToastContainer} from 'react-toastify';
import {Badge, Card, CardBody, CardHeader, Col, Row, Spinner, Table} from 'reactstrap';
import {handleErrorMessage} from "../../services/CommonService";
import creditorService from "../../services/purchase/CreditorService";
import currencyService from "../../services/CurrencyService";
import ManageSupplierOverviewModal from '../modal/ManageSupplierOverviewModal';
import ManageSupplierPaymentDetailModal from '../modal/ManageSupplierPaymentDetailModal';
import ManageSupplierPaymentTermsModal from '../modal/ManageSupplierPaymentTermsModal';
import PurchaseByMonth from '../stock/PurchaseByMonth';
import StockGroupSKUComponent from '../stock/StockGroupSKUComponent';

const gstTypes = [{key: 'I', label: 'Included'}, {key: 'E', label: 'Excluded'}, {key: 'F', label: 'GST Free'}];

export default class SupplierAccountTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: props.accountID,
            hasSupplierWritePrivilege: props.hasSupplierWritePrivilege,
            isLoadingSupplier: false,
            supplier: {},
            supplierAreaCode: [],
            supplierCurrencies: [],
            supplierPaymentMethod: [],
            supplierTermsMethod: [],
            isOpenSupplierOverviewModal: false,
            isOpenSupplierPaymentDetailModal: false,
            isOpenSupplierPaymentTermsModal: false
        };
        this.handleSupplierAccountDetails = this.handleSupplierAccountDetails.bind(this);
        this.getCreditorAreaCode = this.getCreditorAreaCode.bind(this);
        this.getCurrencyList = this.getCurrencyList.bind(this);
        this.toggleSupplierOverviewModal = this.toggleSupplierOverviewModal.bind(this);
        this.getSupplierPaymentMethodList = this.getSupplierPaymentMethodList.bind(this);
        this.toggleSupplierPaymentDetailModal = this.toggleSupplierPaymentDetailModal.bind(this);
        this.toggleSupplierPaymentTermsModal = this.toggleSupplierPaymentTermsModal.bind(this);
        this.getPaymentTermsMethod = this.getPaymentTermsMethod.bind(this);
        this.refreshAgainModal = this.refreshAgainModal.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.hasSupplierWritePrivilege !== nextProps.hasSupplierWritePrivilege) {
            this.setState({
                accountID: nextProps.accountID,
                hasSupplierWritePrivilege: nextProps.hasSupplierWritePrivilege
            }, () => {
                this.refresh();
            })
        }
    }

    refresh() {
        this.getCreditorAreaCode();
        this.getCurrencyList();
        this.getSupplierPaymentMethodList();
        this.getPaymentTermsMethod();
        this.handleSupplierAccountDetails(this.state.accountID);
    }

    handleSupplierAccountDetails(accountID) {
        this.setState({isLoadingSupplier: true});
        creditorService.getSupplierByAccount(accountID).then(response => {
            let supplier = response.data;
            if (supplier) {
                supplier.gstTypeName = gstTypes.filter(x => x.key === supplier.gsT_Type)[0].label;
                this.setState({ supplier, isLoadingSupplier: false });
            }
        }).catch(error => {
            this.setState({ isLoadingSupplier: false });
            toast.error(handleErrorMessage(error));
        });
    }

    getCreditorAreaCode() {
        creditorService.getCreditorAreaCode().then(response => {
            let supplierAreaCode = response.data;
            this.setState({supplierAreaCode});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    getCurrencyList() {
        currencyService.getCurrency().then(response => {
            let supplierCurrencies = response.data;
            this.setState({supplierCurrencies});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    getSupplierPaymentMethodList() {
        creditorService.getSupplierPaymentMethodList().then(response => {
            let supplierPaymentMethod = response.data;
            this.setState({supplierPaymentMethod});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    getPaymentTermsMethod() {
        creditorService.getSupplierTermsMethodList().then(response => {
            let supplierTermsMethod = response.data;
            this.setState({supplierTermsMethod});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    toggleSupplierOverviewModal(change) {
        this.setState({isOpenSupplierOverviewModal: change});
    }

    refreshAgainModal() {
        this.handleSupplierAccountDetails(this.state.accountID);
        this.setState({isOpenSupplierOverviewModal: false});
        this.props.refresh(this.state.accountID);
    }

    toggleSupplierPaymentDetailModal(change) {
        this.setState({isOpenSupplierPaymentDetailModal: change});
    }

    toggleSupplierPaymentTermsModal(change) {
        this.setState({isOpenSupplierPaymentTermsModal: change});
    }

    render() {
        let { supplier, accountID, isLoadingSupplier, supplierTermsMethod,
            isOpenSupplierPaymentTermsModal, hasSupplierWritePrivilege,
            supplierPaymentMethod, isOpenSupplierPaymentDetailModal,
            isOpenSupplierOverviewModal, supplierAreaCode, supplierCurrencies } = this.state;
        return (
            <div>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mt-2"}>
                        <Card>
                            <CardHeader><h6 className={"mb-0"}>
                                Overview
                                {
                                    hasSupplierWritePrivilege ? <span style={{float: "right"}}>
                                    <a href={"javascript:void(0)"}
                                       onClick={(e) => this.toggleSupplierOverviewModal(true)}>
                                        <i className={"fa fa-pencil"}/>
                                    </a>
                                </span> : null}
                            </h6></CardHeader>
                            <CardBody>
                                {isLoadingSupplier
                                    ? <Spinner color="primary"/>
                                    : <Table size={"sm"} responsive className={"mb-0"}>
                                        <tbody>
                                            <tr>
                                                <th>AreaCode</th>
                                                <td className={"text-right"}>{supplier.creditorAreaCode ? supplier.creditorAreaCode.description : ""}</td>
                                            </tr>
                                            <tr>
                                                <th>Currency</th>
                                                <td className={"text-right"}>{supplier.currency}</td>
                                            </tr>
                                            <tr>
                                                <th>Charges GST</th>
                                                <td className={"text-right"}><Badge
                                                    color={supplier.chargesGST ? 'success' : 'danger'}>{supplier.chargesGST ? 'YES' : 'NO'}</Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td className={"text-right"}><Badge
                                                    color={supplier.inActive ? 'danger' : 'success'}>{supplier.inActive ? 'InActive' : 'Active'}</Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Category</th>
                                                <td className={"text-right"}><Badge
                                                    color={'secondary'}>{supplier.accountClass ? supplier.accountClass : ''}</Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Dissection</th>
                                                <td className={"text-right"}>{supplier.purchAccGL}</td>
                                            </tr>
                                            <tr>
                                                <th>Control</th>
                                                <td className={"text-right"}>{supplier.purchCtrlAccGL}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            </CardBody>
                        </Card>

                        <Card className={"mt-2"}>
                            <CardHeader><h6 className={"mb-0"}>
                                Payment Terms
                                {
                                    hasSupplierWritePrivilege ? <span style={{float: "right"}}>
                                        <a href={"javascript:void(0)"}
                                           onClick={(e) => this.toggleSupplierPaymentTermsModal(true)}>
                                            <i className={"fa fa-pencil"}/>
                                        </a>
                                    </span> : null}
                            </h6></CardHeader>
                            <CardBody>
                                {isLoadingSupplier
                                    ? <Spinner color="primary"/>
                                    : <Table size={"sm"} responsive className={"mb-0"}>
                                        <tbody>
                                        <tr>
                                            <th>Days</th>
                                            <td className={"text-right"}>{supplier.numDaysCredit}</td>
                                        </tr>
                                        <tr>
                                            <th>Method</th>
                                            <td className={"text-right"}>{supplier.creditorTermsMethod ? supplier.creditorTermsMethod.description : ""}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mt-2"}>
                        <Card>
                            <CardHeader><h6 className={"mb-0"}>
                                Payment Details
                                {
                                    hasSupplierWritePrivilege ? <span style={{float: "right"}}>
                                        <a href={"javascript:void(0)"}
                                           onClick={(e) => this.toggleSupplierPaymentDetailModal(true)}>
                                            <i className={"fa fa-pencil"}/>
                                        </a>
                                    </span> : null}
                            </h6></CardHeader>
                            <CardBody>
                                {isLoadingSupplier
                                    ? <Spinner color="primary"/>
                                    : <Table size={"sm"} responsive className={"mb-0"}>
                                        <tbody>
                                        <tr>
                                            <th>Payment Method</th>
                                            <td className={"text-right"}>{supplier.creditorPaymentMethod ? supplier.creditorPaymentMethod.description : ""}</td>
                                        </tr>
                                        <tr>
                                            <th>Account Name</th>
                                            <td className={"text-right"}>{supplier.bankAcctName}</td>
                                        </tr>
                                        <tr>
                                            <th>Bank Name</th>
                                            <td className={"text-right"}>{supplier.bankName}</td>
                                        </tr>
                                        <tr>
                                            <th>Suburb/City</th>
                                            <td className={"text-right"}>{supplier.bankSuburb}</td>
                                        </tr>
                                        <tr>
                                            <th>BSB</th>
                                            <td className={"text-right"}>{supplier.bankBSB}</td>
                                        </tr>
                                        <tr>
                                            <th>Account No.</th>
                                            <td className={"text-right"}>{supplier.bankAcctNum}</td>
                                        </tr>
                                        <tr>
                                            <th>GST Type</th>
                                            <td className={"text-right"}>{supplier.gstTypeName}</td>
                                        </tr>
                                        <tr>
                                            <th>ABN</th>
                                            <td className={"text-right"}>{supplier.abn}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                }
                            </CardBody>
                        </Card>

                        <div className={"mt-2"}>
                            <StockGroupSKUComponent accountID={accountID}/>
                        </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mt-2"}>
                        <PurchaseByMonth accountID={accountID}/>
                    </Col>
                </Row>

                {
                    isOpenSupplierOverviewModal ?
                        <ManageSupplierOverviewModal
                            accountID={accountID}
                            supplierAreaCodes={supplierAreaCode}
                            currencies={supplierCurrencies}
                            isOpen={isOpenSupplierOverviewModal}
                            toggle={this.toggleSupplierOverviewModal}
                            refresh={this.refreshAgainModal}/>
                        : null
                }
                {
                    isOpenSupplierPaymentDetailModal ?
                        <ManageSupplierPaymentDetailModal
                            accountID={accountID}
                            supplierPaymentMethod={supplierPaymentMethod}
                            isOpen={isOpenSupplierPaymentDetailModal}
                            toggle={this.toggleSupplierPaymentDetailModal}
                            gstTypes={gstTypes}
                            refresh={this.refreshAgainModal}/>
                        : null
                }
                {
                    isOpenSupplierPaymentTermsModal ?
                        <ManageSupplierPaymentTermsModal
                            accountID={accountID}
                            supplierTermsMethod={supplierTermsMethod}
                            isOpen={isOpenSupplierPaymentTermsModal}
                            toggle={this.toggleSupplierPaymentTermsModal}
                            refresh={this.refreshAgainModal}/>
                        : null
                }

            </div>
        )
    }

}