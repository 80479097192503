import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';

export default class NewsLetterService {

    showNewsLetters() {
        return axios.get('api/newsLetter/list');
    }

    showNewsLettersForCustomer() {
        return axios.get('api/newsLetter/DisplayToCustomer');
    }

    getNewsLetterByName(name,options) {
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.get('api/file/Generate/NewsLetter?FileName=' + name, options);
        } else {
            return axiosFileDownloader.get('api/file/Generate/NewsLetter?FileName=' + name);
        }
    }

    publishNewsLetter(id) {
        return axios.put('api/newsLetter/publish?attachmentID=' + id);
    }

    deleteNewsLetter(id) {
        return axios.delete('api/newsLetter/delete?attachmentID=' + id);
    }
}