import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Nav,
    NavItem,
    NavLink,
    Label
} from 'reactstrap';
import classnames from 'classnames';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import CustomerCredit from '../../components/debtor/CustomerCredit';
import Transaction from '../../components/debtor/Transactions';
import RecentStatements from '../../components/debtor/RecentStatements';
import DebtorIntroduction from '../../components/debtor/DebtorIntroduction';
import CustomerService from "../../services/CustomerService";
import Notes from "../../components/debtor/Notes";
import UserService from "../../services/UserService";
import contactService from '../../services/ContactService';
import {toast, ToastContainer} from 'react-toastify';
import SalesAllCustomerOrders from '../sales/SalesAllCustomerOrders';
import SalesQuotesPage from '../sales/SalesQuotesPage';
import FreightAndDiscountTab from '../../components/debtor/FreightView';
import CustomerSalesStats from '../../components/debtor/CustomerSalesStats';
import TeamsComponent from "../../components/dashboard/TeamsComponent";
import queryString from 'query-string';
import {handleErrorMessage} from "../../services/CommonService";
import CustomerSignupInviteModal from '../../components/modal/CustomerSignupInviteModal';
import cloneDeep from 'lodash/cloneDeep';
import CRMService from "../../services/crm/CRMService";

const allTabs = [
    {
        label: "Account",
        key: "Credit",
        privilege: "debtor-read"
    },
    {
        label: "Statements",
        key: "Statements",
        privilege: "debtor-statement-read"
    },
    {
        label: "Transactions",
        key: "Transactions",
        privilege: "debtor-statement-read"
    },

    {
        label: "Orders",
        key: "Orders",
        privilege: "debtor-invoice-order-read"
    },
    {
        label: "Quotes",
        key: "Quotes",
        privilege: "debtor-invoice-quote-read"
    },
    {
        label: "Freight",
        key: "Freight",
        privilege: "freight-rule-write"
    },
    {
        label: "Notes",
        key: "Notes",
        privilege: "debtor-read"
    },
    {
        label: "Sales Stats",
        key: "SalesStats",
        privilege: "debtor-finance-read"
    }];


export default class DebtorEnquiryPage extends Component {
    constructor(props) {
        super(props);

        let accountID = "";
        if (props.location.hash) {
            accountID = props.location.hash.replace('#', '');
        }
        this.state = {
            accountId: accountID,
            activeTab: "",
            TransactionsLoading: false,
            debtorTabs: [],
            debtor: {},
            debtorContacts: [],
            loadingContacts: true,
            hasDebtorWritePrivilege: false,
            searchParams: {},
            user: {},
            usersByEmail: {},
            alreadyInvited: [],
            alreadyRegisteredAccountID: [],
            workingCustomer: null,
            includeInactive: false,
            includeClosed: false
        }

        this.crmService = new CRMService();
        this.customerService = new CustomerService();
        this.userService = new UserService();
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleUpload = this.toggleUpload.bind(this);
        this.getContacts = this.getContacts.bind(this);
        this.sendInvitationEmail = this.sendInvitationEmail.bind(this);
        this.toggleCustomerSignupInviteModal = this.toggleCustomerSignupInviteModal.bind(this);
        this.fetchAlreadyRegisteredEmails = this.fetchAlreadyRegisteredEmails.bind(this);
        this.handleCustomerInvitationChange = this.handleCustomerInvitationChange.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.location.hash) {
            //received accountId in props
            if (nextProps.location.hash) {
                let accountID = nextProps.location.hash.replace('#', '');
                this.refresh(accountID);
            }
        }
    }

    componentDidMount() {
        let {activeTab, accountId} = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            activeTab = searchParams.activeTab ? searchParams.activeTab : '';
            accountId = searchParams.accountId ? searchParams.accountId : '';
            this.setState({activeTab, searchParams, accountId});
        }

        if (accountId) {
            //received accountId in props
            this.refresh(accountId);
        } else {
            // Default, trying to get first accountId from db and then refreshing with the same
            this.customerService.searchFirstDebtorAccountCompany("").then(response => {
                if (response.data) {
                    for (let i in response.data) {
                        this.refresh(response.data[i].accountId);
                        break;
                    }
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleAccountChange(accountId) {
        this.setState({accountId: accountId}, () => {
            this.handleDebtorAccountDetails(accountId);
            this.getContacts(accountId);
        });

        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("accountId", accountId);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    refresh(accountId) {
        this.getContacts(accountId);
        this.userService.getLoggedInUserFromDb(false).then(userResponse => {
            let user = userResponse.data;
            if (user) {
                let {hasDebtorWritePrivilege} = this.state;
                user.menu.privileges.includes('debtor-write') ? hasDebtorWritePrivilege = true
                    : hasDebtorWritePrivilege = false;
                this.setState({user, hasDebtorWritePrivilege}, () => this.handleAccountChange(accountId));
            }
        }, error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    fetchAlreadyInvitedEmails(emails) {
        this.userService.findAlreadyInvited(emails).then(response => {
            if (response.data) {
                let {alreadyInvited} = this.state;
                alreadyInvited = response.data;
                this.setState({alreadyInvited});
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    fetchAccountIDRegisteredAsUser(accountIDs) {
        this.customerService.fetchAccountIDRegisteredAsUser(accountIDs).then(response => {
            if (response.data) {
                let {alreadyRegisteredAccountID} = this.state;
                alreadyRegisteredAccountID = response.data;
                this.setState({alreadyRegisteredAccountID});
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }


    fetchAlreadyRegisteredEmails(emails) {
        this.userService.findUsers([], [], emails).then(response => {
            if (response.data) {
                let users = response.data;
                let {usersByEmail} = this.state;
                (users || []).forEach(user => {
                    if (!usersByEmail[user.emailAddress]) {
                        usersByEmail[user.emailAddress] = user;
                    }
                });
                this.setState({usersByEmail});
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getContacts(accountID) {
        if (accountID) {
            this.setState({loadingContacts: true});
            contactService.getContacts(accountID, "Debtor").then(response => {
                this.setState({debtorContacts: response.data, loadingContacts: false});
            }).catch(error => {
                this.setState({loadingContacts: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });

            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("activeTab", tab);
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({path: newurl}, '', newurl);
            }
        }
    }

    toggleUpload(value) {
        this.setState({TransactionsLoading: value});
    }

    handleDebtorAccountDetails(accountId) {
        let debtor = {accountID: ""};
        if (accountId) {
            this.customerService.searchCustomer(accountId).then(response => {
                debtor = response.data;
                if (debtor) {
                    let email = [debtor.email];
                    let accountIDs = [debtor.accountID];
                    this.fetchAlreadyRegisteredEmails(email);
                    this.fetchAlreadyInvitedEmails(email);
                    this.fetchAccountIDRegisteredAsUser(accountIDs);

                    debtor.debtorAddress = (debtor.address1 ? debtor.address1 + ", " : "")
                        + (debtor.address2 ? debtor.address2 + ", " : "")
                        + (debtor.address3 ? debtor.address3 + ", " : "")
                        + (debtor.address4 ? debtor.address4 + ", " : "")
                        + (debtor.city ? debtor.city + ", " : "")
                        + (debtor.state ? debtor.state + ", " : "")
                        + (debtor.postCode ? debtor.postCode + ", " : "")
                        + (debtor.country ? debtor.country : "");

                    this.setState({
                        debtor: debtor
                    });

                    // statement tab to be hide from child accounts
                    let {user} = this.state;
                    if (user) {
                        let processedTabs = [];
                        let flag = false;
                        let {activeTab} = this.state;

                        for (let i in allTabs) {
                            if ((allTabs[i].key !== 'Statements') || (allTabs[i].key === 'Statements' && debtor.isParent)) {
                                flag = this.userService.hasPrivilege(user, allTabs[i].privilege);
                                if (flag) {
                                    processedTabs.push(allTabs[i]);
                                    if (!activeTab) {
                                        activeTab = allTabs[i].key;
                                    }
                                }
                            }
                        }

                        if (activeTab === 'Statements' && debtor.isChild) {
                            activeTab = processedTabs[0] ? processedTabs[0].key : '';
                        }

                        this.setState({debtorTabs: processedTabs, activeTab});
                    }
                }
            });
        } else {
            this.setState({
                accountID: debtor.accountID,
                debtor: debtor,
            });
        }
    }

    getName(item) {
        let fullName = item.firstName;

        if (item.middleInitial) {
            fullName = fullName + " " + item.middleInitial;
        }

        if (item.lastName) {
            fullName = fullName + " " + item.lastName;
        }

        return fullName;
    }

    handleCustomerInvitationChange(change, key) {
        let {workingCustomer} = this.state;

        switch (key) {
            case "customer-signup-invite-email":
                workingCustomer.newEmail = change;
                this.setState({workingCustomer});
                break;
            case "customer-signup-invite-workingCustomer":
                workingCustomer = cloneDeep(change);
                workingCustomer.newEmail = workingCustomer.email;
                this.setState({workingCustomer, isOpenCustomerSignupInviteModal: true});
                break;
        }
    }

    toggleCustomerSignupInviteModal(isOpenCustomerSignupInviteModal) {
        let {workingCustomer} = this.state;
        if (!isOpenCustomerSignupInviteModal) {
            workingCustomer = null;
        }
        this.setState({isOpenCustomerSignupInviteModal, workingCustomer});
    }

    sendInvitationEmail({accountID, newEmail}) {
        let {debtor} = this.state;
        debtor.loadingInvite = true;
        this.setState({debtor, isLoadingInvitation: true});

        this.customerService.updateEmailAndSendSignupInvite(accountID, newEmail).then(res => {
            if (res.status === 200 || res === '200') {
                debtor.loadingInvite = false;
                debtor.email = res.data.email;
                this.setState({
                    debtor,
                    isLoadingInvitation: false,
                    isOpenCustomerSignupInviteModal: false
                });
                this.handleDebtorAccountDetails(accountID);
                toast.success("Invitation sent!");
            }
        }).catch(error => {
            debtor.loadingInvite = false;
            this.setState({debtor, isLoadingInvitation: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    renderSwitch(activeKey) {
        let { accountId, searchParams, debtor, hasDebtorWritePrivilege } = this.state;
        switch (activeKey) {
            case "Credit":
                return (<div>
                    <CustomerCredit accountId={accountId} hasDebtorWritePrivilege={hasDebtorWritePrivilege} refresh={this.handleDebtorAccountDetails} />
                    <div className="mb-2"/>
                    <TeamsComponent accountID={accountId} consumerType={"Debtor"}/>
                </div>);
                break;
            case "Statements":
                return (<div>
                    <RecentStatements accountId={accountId}/>
                </div>);
                break;
            case "Orders":
                return (<div>
                    <SalesAllCustomerOrders accountId={accountId}/>
                </div>);
                break;
            case "Quotes":
                return (<div>
                    <SalesQuotesPage accountId={accountId}/>
                </div>);
                break;
            case "Freight":
                return (<div>
                    <FreightAndDiscountTab accountId={accountId} hasDebtorWritePrivilege={hasDebtorWritePrivilege} />
                </div>);
                break;
            case "Notes":
                return (<div>
                    <Notes accountId={accountId}/>
                </div>);
                break;
            case "SalesStats":
                return (<div>
                    <CustomerSalesStats accountID={accountId}/>
                </div>);
                break;
            case "Transactions":
                return (<div>
                    <Transaction accountId={accountId} toggleModel={this.toggleUpload} searchParams={searchParams}
                                 debtor={debtor}/>
                </div>);
                break;
        }
    }

    handleFilterChange(change, key) {
        let { accountId } = this.state;
        switch (key) {
            case "includeInactive":
                this.setState({ includeInactive: change});
                break;
            case "includeClosed":
                this.setState({ includeClosed: change});
                break;
        }
        this.refresh(accountId);
    }

    render() {
        const {
            debtor, accountId, activeTab, debtorTabs, TransactionsLoading,
            debtorContacts, loadingContacts, hasDebtorWritePrivilege, searchParams,
            alreadyInvited, usersByEmail, workingCustomer, isOpenCustomerSignupInviteModal,
            isLoadingInvitation, alreadyRegisteredAccountID, includeClosed, includeInactive
        } = this.state;
        return (
            <div>
                <div className="mb-2">
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText><span>Search Customer</span></InputGroupText>
                        </InputGroupAddon>
                        <span style={{width: '50%'}}>
                            <SearchDebtorAcccount handleAccountChange={this.handleAccountChange}
                                selectedAccountID={accountId}
                                defaultAccountID={accountId}
                                includeChildren={true}
                                includeClosed={includeClosed}
                                includeInactive={includeInactive}
                                excludeClosedandInactive={false}
                            />
                        </span>
                        &nbsp;&nbsp;
                        <div>
                            <Label class={"mt-5"}>
                                <Button color={"link"}
                                    size={"sm"}
                                    onClick={() => this.handleFilterChange(!includeInactive, "includeInactive")}>
                                    <i className={classnames("fa", "fa-lg", "fa-fw", {
                                        "fa-square-o": !includeInactive,
                                        "fa-check-square-o": includeInactive,
                                    }
                                    )} aria-hidden="true" />
                                </Button>
                                Include Inactive
                            </Label>
                        </div>&nbsp;
                        <div>
                            <Label class={"mt-5"}>
                                <Button color={"link"}
                                    size={"sm"}
                                    onClick={() => this.handleFilterChange(!includeClosed, "includeClosed")}>
                                    <i className={classnames("fa", "fa-lg", "fa-fw", {
                                        "fa-square-o": !includeClosed,
                                        "fa-check-square-o": includeClosed,
                                    }
                                    )} aria-hidden="true" />
                                </Button>
                                Include Closed
                            </Label>
                        </div>
                    </InputGroup>
                </div>

                {(() => {
                    if (accountId && debtorTabs.length > 0) {
                        return (
                            <div>
                                <div className="mb-2">
                                    <DebtorIntroduction debtor={debtor}
                                                        alreadyInvited={alreadyInvited}
                                                        usersByEmail={usersByEmail}
                                                        alreadyRegisteredAccountID={alreadyRegisteredAccountID}
                                                        hasDebtorWritePrivilege={hasDebtorWritePrivilege}
                                                        handleChange={this.handleCustomerInvitationChange}
                                    />
                                </div>
                                <Card>
                                    <CardHeader>
                                        <Nav tabs card>
                                            {debtorTabs.map((debtorTab, index) =>
                                                <NavItem className={"hoverableItem"} key={index}>
                                                    <NavLink
                                                        className={classnames({active: activeTab === debtorTab.key})}
                                                        onClick={() => {
                                                            this.toggle(debtorTab.key);
                                                        }}>
                                                        <Button style={{textDecoration: "none"}}
                                                                size={"sm"} color={"link"}>{debtorTab.label}
                                                        </Button>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </Nav>
                                    </CardHeader>
                                    <CardBody>
                                        {this.renderSwitch(activeTab)}
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    } else {
                        return (
                            <Card>
                                <CardBody>
                                    <CardText>Select/ Search Customer first...</CardText>
                                </CardBody>
                            </Card>
                        )
                    }
                })()}
                {
                    isOpenCustomerSignupInviteModal
                        ? <CustomerSignupInviteModal
                            form={workingCustomer}
                            isOpen={isOpenCustomerSignupInviteModal}
                            toggle={this.toggleCustomerSignupInviteModal}
                            handleChange={this.handleCustomerInvitationChange}
                            handleSubmit={this.sendInvitationEmail}
                            loading={isLoadingInvitation}
                        />
                        : null
                }
                <ToastContainer/>
            </div>
        )
    };
}
