import React, { Component } from 'react';
import {
    Badge,
    Button,
    Spinner,
    Input, InputGroup,
    Card, CardHeader, CardBody,
    Table, FormGroup, FormText,
    Breadcrumb, BreadcrumbItem, Row, Col, InputGroupAddon, InputGroupText, Label, CardFooter
} from 'reactstrap';
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from 'react-toastify';
import WarehouseTransferDataModal from '../../../components/modal/WarehouseTransferDataModal';
import DeleteStockWarehouseLocationModal from '../../../components/modal/DeleteStockWarehouseLocationModal';
import { handleErrorMessage, changeFormatOfDateString } from '../../../services/CommonService';
import SearchStock from '../../../components/search/SearchStock';
import StockService from '../../../services/StockService';
import classnames from 'classnames';
import moment from 'moment'
import { Link } from "react-router-dom";
import queryString from 'query-string';
export default class WarehouseTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stock: {},
            isLoading: {},
            locationDetails: [],
            loading: false,
            isOpenModal: false,
            isOpenDeleteModal: false,
            selectedItem: {},
            prodCode: '',
            savingValue: {}
        };
        this.stockService = new StockService();
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.checkReceivedValue = this.checkReceivedValue.bind(this);
    }
    handleChange(selected) {
        if (selected) {
            this.setState({ prodCode: selected.prodCode })
            this.getStock(selected.prodCode);
        }
        else {
            this.getStock(null);
        }
    }
    getStock(prodCode) {
        this.setState({ isLoading: true });
        this.stockService.getStock(prodCode).then(response => {
            this.setState({ isLoading: false, stock: response.data });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    openModal(item) {
        this.setState({ selectedItem: item, isOpenModal: true });
    }
    toggleModal(data) {
        this.setState({ isOpenModal: data });
    }
    openDeleteModal(item) {
        this.setState({ selectedItem: item, isOpenDeleteModal: true });
    }
    toggleDeleteModal(data) {
        this.setState({ isOpenDeleteModal: data });
    }
    handleAccountChange(key, value) {
        let { accountID } = this.state;
        accountID = value;
        this.setState({ accountID })
        this.getpurchaseOrderDetails(accountID);
    }
    refreshAgain() {
        let { prodCode } = this.state;
        this.setState({ isOpenModal: false })
        this.getStock(prodCode);
    }
    checkReceivedValue(value) {
        this.setState({ savingValue: value });
    }
    render() {
        let { loading, selectedItem, isOpenModal, stock, isOpenDeleteModal, savingValue } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Warehouse Transfer</BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                        <h7>Search With ProdCode</h7>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                        <SearchStock
                            handleSelection={this.handleChange}
                            selected={stock.prodCode}
                            filter={{ bmFlag: null, discontinued: null, soldOut: null, stockGroups: [] }}
                        />
                    </Col>
                </Row>
                <br />
                {stock.prodCode ?
                    <Card>
                        <CardBody>
                            <Row className={"d-flex align-center"}>
                                <Col xl={4} lg={4} md={6} sm={8} xs={8}>
                                    <Label className="font-weight-bold">ProdCode :</Label>
                                    <Label className="fw-normal px-2">{stock.prodCode}</Label>
                                </Col>
                                <Col xl={8} lg={8} md={10} sm={14} xs={14}>
                                    <Label className="font-weight-bold">Description :</Label>
                                    <Label className="fw-normal px-2">{stock.description}</Label>
                                </Col>
                            </Row>
                            <Row className={"d-flex align-center"}>
                                <Col xl={4} lg={4} md={6} sm={8} xs={8}>
                                    <Label className="font-weight-bold">QOH :</Label>
                                    <Label className="fw-normal px-2">{stock.qoh}</Label>
                                </Col>
                                <Col xl={4} lg={4} md={6} sm={8} xs={8}>
                                    <Label className="font-weight-bold">Free :</Label>
                                    <Label className="fw-normal px-2">{stock.supplierCost}</Label>
                                </Col>
                                <Col xl={4} lg={4} md={6} sm={8} xs={8}>
                                    <Label className="font-weight-bold">Allocated :</Label>
                                    <Label className="fw-normal px-2">{stock.currencyCost}</Label>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    : null
                }
                <br />
                {
                    stock.prodCode ?
                        <Card>
                            <CardBody>
                                <Table striped>
                                    <thead>
                                        <th>Location</th>
                                        <th>Warehouse Location Types</th>
                                        <th>Last Updated Date</th>
                                        <th>Qty</th>
                                        <th className={"text-center"}><Button color='primary'
                                            className="btn btn-sm"
                                            title={"Click here to Add New Location"}
                                            onClick={() => this.openModal("")}><i className="fa fa-plus"
                                                aria-hidden="true" />&nbsp;
                                            Add</Button></th>
                                    </thead>
                                    <tbody>
                                        {(stock.stockWarehouseLocations || []).map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.locationName}</td>
                                                    <td>{item.locationType}</td>
                                                    <td>{moment(item.dateUpdated).format('DD  MMM YYYY')}</td>
                                                    <td>{item.qoh}</td>
                                                    <td className={"text-center"}>
                                                        <Button class={"btn"}
                                                            size={"sm"} color={"primary"}
                                                            title={"Click here to Start New Transfer"}
                                                            onClick={() => this.openModal(item)}>
                                                            Transfer
                                                        </Button>
                                                        {
                                                            item.qoh < 0 ?
                                                                <Button class={"btn"}
                                                                    className={"ml-1"}
                                                                    size={"sm"} color={"danger"}
                                                                    title={"Delete this Location From Stock"}
                                                                    onClick={() => this.openDeleteModal(item)}>
                                                                    Delete
                                                                </Button>
                                                                : null
                                                        }
                                                        <Button class={"btn"}
                                                            className={"ml-1"}
                                                            size={"sm"} color={item.locationID === parseInt(savingValue.warehouseLocation) ? "success" : "secondary"}
                                                            title={"Delete this Location From Stock"}>
                                                            Received
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        : null
                }
                {
                    isOpenModal ?
                        <WarehouseTransferDataModal
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            selectedItem={selectedItem}
                            prodCode={stock.prodCode}
                            warehouseLocation={stock.stockWarehouseLocations}
                            refreshAgain={this.refreshAgain}
                            checkReceivedValue={this.checkReceivedValue}
                        />
                        : null
                }
                {
                    isOpenDeleteModal ?
                        <DeleteStockWarehouseLocationModal
                            stockWarehouseLocationID={selectedItem.stockWarehouseLocationID}
                            isOpenDeleteModal={isOpenDeleteModal}
                            toggleDeleteModal={this.toggleDeleteModal}
                            refreshAgain={this.refreshAgain}
                        />
                        : null
                }
                <ToastContainer />
            </div>
        );
    }
}