import React, {Component} from 'react';

export let rollerBlindLabelStore = [
    {
        key: "prodCode",
        label: "Build",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "description",
        label: "Description",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "layout",
        label: "Layout",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "fabric",
        label: "Fabric",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel.fabric;
        }
    },
    {
        key: "controlSide",
        label: "Control Side",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "clutch",
        label: "Clutch",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "chain",
        label: "Chain",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "bottomRail",
        label: "B-Rail",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "rollDirection",
        label: "Roll Dir.",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "bracketColor",
        label: "Bracket Color",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "extra",
        label: "Extras",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel.accessoryBoxColour;
        }
    }
];