import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import {validateEmail} from "../services/CommonService";
import {ADDRESS_VALIDATION_MAX_LENGTH_EMAIL, ADDRESS_VALIDATION_MAX_LENGTH_PHONE} from "../store/AppConstants";

class ContactService {

    static Instance() {
        return new ContactService();
    }


    getContactTypes() {
        return axios.get('api/contact/contact-types/list');
    }

    getContacts(accountID, consumerType) {
        return axios.get('api/contact/list/?accountID=' + accountID + "&consumerType=" + consumerType);
    }

    getContact(contactID) {
        return axios.get('api/contact/one/?contactID=' + contactID);
    }

    saveContact(contact) {
        return axios.post('api/contact/save', contact)
    }

    deleteContact(contactID) {
        return axios.delete('api/contact/delete?contactID=' + contactID);
    }

    getAllcontacts(requestBody) {
        return axios.post('/api/contact/list/master', requestBody)
    }

    generateContactSummaryExcelReport(req) {
        return axiosFileDownloader.post('/api/contact/list/master/export', req)
    }

    fetchDebtorStatementEmails(accountID) {
        return axios.get('api/address/get/statementEmails?AccountId=' + accountID);
    }

    AddDebtorOtherEmails(requestBody) {
        return axios.post('api/address/OtherEmails', requestBody);
    }

    validateForm(request) {
        let formError = {
            isValid: true,
            errors: []
        };
        let message = "";
        let regexp = "";
        if (!request.accountID) {
            message = "accountID can't be empty!";
            formError.accountID = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!request.contactTypeID) {
            message = "contact type ID can't be empty!";
            formError.contactTypeID = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        /*if (!request.jobTitle) {
            message = "job title can't be empty!";
            formError.jobTitle = message;
            formError.errors.push(message);
            formError.isValid = false;
        }*/
        if (request.jobTitle && request.jobTitle.length > 50) {
            message = "job title should be less than " + 50 + " characters!";
            formError.jobTitle = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!request.firstName) {
            message = "first name can't be empty!";
            formError.firstName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (request.firstName && request.firstName.length > 50) {
            message = "first name should be less than " + 50 + " characters!";
            formError.firstName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (request.lastName && request.lastName.length > 50) {
            message = "last name should be less than " + 50 + " characters!";
            formError.lastName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        /*if (!request.phone) {
            message = "phone can't be empty!";
            formError.phone = message;
            formError.errors.push(message);
            formError.isValid = false;
        }*/
        if (request.phone && request.phone.length > ADDRESS_VALIDATION_MAX_LENGTH_PHONE) {
            message = "phone should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_PHONE + " characters!";
            formError.phone = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        regexp = /[- +()0-9]*$/;
        if (request.phone && !regexp.test(request.phone)) {
            message = "only numbers are allowed in mobile/phone";
            formError.phone = message;
            formError.errors.push(message);
            formError.isValid = false;
        }

        /*if (!request.mobile) {
            message = "mobile can't be empty!";
            formError.mobile = message;
            formError.errors.push(message);
            formError.isValid = false;
        }*/
        if (request.mobile && request.mobile.length > ADDRESS_VALIDATION_MAX_LENGTH_PHONE) {
            message = "mobile should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_PHONE + " characters!";
            formError.mobile = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        regexp = /[- +()0-9]*$/;
        if (request.mobile && !regexp.test(request.mobile)) {
            message = "only numbers are allowed in mobile/mobile";
            formError.mobile = message;
            formError.errors.push(message);
            formError.isValid = false;
        }

        /*if (!request.email) {
            message = "email can't be empty!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }*/
        if (request.email && !validateEmail(request.email)) {
            message = "email isn't valid!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (request.email && request.email.length > ADDRESS_VALIDATION_MAX_LENGTH_EMAIL) {
            message = "email should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_EMAIL + " characters!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }

        return formError;
    }
}

export default ContactService.Instance();