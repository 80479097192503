import axios from './axios';

class StockUnitService {

    static Instance() {
        return new StockUnitService();
    }

    listStockUnits(req) {
        return axios.post('api/stock-unit/list', req);
    }

    getOneStockUnit(stockUnitID) {
        return axios.get('api/stock-unit/one/?StockUnitID=' + stockUnitID);
    }

    saveOrUpdateStockUnit(req) {
        return axios.post('api/stock-unit/save', req);
    }

    getMainStockUnit(prodCode) {
        return axios.get('api/stock-unit/one/main/by-stock?prodCode=' + prodCode);
    }
    getAllUnitsOf(prodCode){
        return axios.get('api/stock-unit/list/by-stock?prodCode=' + prodCode)
    }

    saveStockUnitMappings(req) {
        return axios.post('api/stock-unit/mapping/save', req);
    }
}

export default StockUnitService.Instance();