import React, {Component} from 'react';
import NumberFormat from "react-number-format";
import {DatePicker} from "react-datepicker";
import {getDateObj} from "../services/CommonService";
import StockQtyFormat from "../components/stock/StockQtyFormat";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "./AppConstants";
import {Link as Link} from "react-router-dom";


export let purchaseOrderEnquiryOrderItemStore = [

    {
        key: "prodCode",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return <div>
                Item
                <div>code</div>
            </div>;
        },
        render: function (rowIndex, rows, componentRef) {
            return <Link className="btn btn-sm btn-primary"
                         style={{color: "white"}}
                         title={"Click here to see stock details"}
                         to={"/inventory/stock/enquiry?prodCode=" + rows[rowIndex][this.key]}>
                {rows[rowIndex][this.key]}
            </Link>;
        }
    }, {
        key: "description",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return "Description";
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex][this.key];
        }
    }, {
        key: "color",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: false,
        labelClassName: "align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return "Colour";
        },
        render: function (rowIndex, rows, componentRef) {
            return (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.colour : "";
        }
    }, {
        key: "size",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: false,
        labelClassName: "align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return "Size";
        },
        render: function (rowIndex, rows, componentRef) {
            let size = "";
            let dimensionUnitName = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock && rows[rowIndex].stock.keywayStock.dimensionUnitName) ? rows[rowIndex].stock.keywayStock.dimensionUnitName : "NA";
            if ((rows[rowIndex].stock && rows[rowIndex].stock.keywayStock && rows[rowIndex].stock.keywayStock.length )) {
                size = size + " Length: " + rows[rowIndex].stock.keywayStock.length + " " + dimensionUnitName;
            }
            if ((rows[rowIndex].stock && rows[rowIndex].stock.keywayStock && rows[rowIndex].stock.keywayStock.width)) {
                size = size + " Width: " + rows[rowIndex].stock.keywayStock.width + " " + dimensionUnitName;
            }
            if ((rows[rowIndex].stock && rows[rowIndex].stock.keywayStock && rows[rowIndex].stock.keywayStock.height)) {
                size = size + " Height: " + rows[rowIndex].stock.keywayStock.height + " " + dimensionUnitName;
            }
            return size ? size.trim() : "";
        }
    },
    {
        key: "supplierRef",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: false,
        labelClassName: " align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return <div>
                Supplier
                <div>Ref</div>
            </div>;
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex][this.key];
        }
    }, {
        key: "eta",
        renderLabel: function (rows, componentRef) {
            return "ETA";
        },
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-center";
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex][this.key];
        }
    },
    {
        key: "qty",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-right";
        },
        renderLabel: function (rows, componentRef) {
            return <div>
                Each
                <div>Qty</div>
            </div>;
        },
        render: function (rowIndex, rows, componentRef) {
            let eachUnitName = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.eachUnitName : "";
            let whUnitName = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.whUnitName : "";
            let whUnitQty = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.whUnitQty : 1;

            return <StockQtyFormat
                eachUnitName={eachUnitName}
                whUnitName={whUnitName}
                whUnitQty={whUnitQty}
                qty={rows[rowIndex].qty}
                qtyType={STOCK_QTY_TYPE.each}
                format={STOCK_QTY_FORMAT.each}
                emptyPlaceholderText={"-"}
                isPoManage={false}
            />
        }
    }, {
        key: "qty",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-center";
        },
        renderLabel: function (rows, componentRef) {
            return <div>
                WH Pack
                <div>Qty</div>
            </div>;
        },
        render: function (rowIndex, rows, componentRef) {
            let eachUnitName = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.eachUnitName : "";
            let whUnitName = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.whUnitName : "";
            let whUnitQty = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.whUnitQty : 1;

            return <StockQtyFormat
                eachUnitName={eachUnitName}
                whUnitName={whUnitName}
                whUnitQty={whUnitQty}
                qty={rows[rowIndex].qty}
                qtyType={STOCK_QTY_TYPE.each}
                format={STOCK_QTY_FORMAT.packQty_of_PackSize}
                emptyPlaceholderText={"-"}
                isPoManage={false}
            />
        }
    },
    {
        key: "received",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-right";
        },
        renderLabel: function (rows, componentRef) {
            return <div>
                Received
                <div>Each Qty</div>
            </div>;
        },
        render: function (rowIndex, rows, componentRef) {
            let eachUnitName = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.eachUnitName : "";
            let whUnitName = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.whUnitName : "";
            let whUnitQty = (rows[rowIndex].stock && rows[rowIndex].stock.keywayStock) ? rows[rowIndex].stock.keywayStock.whUnitQty : 1;

            return <StockQtyFormat
                eachUnitName={eachUnitName}
                whUnitName={whUnitName}
                whUnitQty={whUnitQty}
                qty={rows[rowIndex].received}
                qtyType={STOCK_QTY_TYPE.each}
                format={STOCK_QTY_FORMAT.each}
                emptyPlaceholderText={"-"}
                isPoManage={false}
            />
        }
    },
    {
        key: "cost",
        renderLabel: function (rows, componentRef) {
            return "Unit Price";
        },
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-right";
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex][this.key]}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={false}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    }, {
        key: "total",
        renderLabel: function (rows, componentRef) {
            return "Amount";
        },
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: false,
        searchNode: null,
        labelClassName: "align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-right";
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex].cost * rows[rowIndex].qty}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={false}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    }
];