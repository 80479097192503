import React, { Component } from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage } from "../../services/CommonService";
import StockService from "../../services/StockService";
import { isEmpty } from "lodash";

export default class StockGroupSKUComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockGroupDataArray: [],
            isLoading: false,
            accountID: "",
        };
        this.stockService = new StockService();
        this.getStockGroupSKUdata = this.getStockGroupSKUdata.bind(this);
    }

    componentDidMount() {
        let { accountID } = this.state;
        accountID = this.props.accountID;
        this.setState({ accountID }, () =>
            this.getStockGroupSKUdata(accountID)
        );
    }

    getStockGroupSKUdata(accountID) {
        this.setState({ isLoading: true });
        this.stockService
            .getStockGroupSKUdata(accountID)
            .then((response) => {
                let stockGroupDataArray = response.data;
                this.setState({ stockGroupDataArray, isLoading: false });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                console.error(error);
            });
    }

    render() {
        let { stockGroupDataArray, isLoading } = this.state;
        if (isLoading || isEmpty(stockGroupDataArray)) {
            return null;
        }

        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Stock Group</h6>
                    </CardHeader>
                    <CardBody>
                        <Table
                            responsive
                            size="sm"
                            className={"mb-0"}
                        >
                            <thead>
                                <tr>
                                    <th>Stock Group</th>
                                    <th className={"text-right"}>No. of SKU</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stockGroupDataArray &&
                                stockGroupDataArray.length > 0 ? (
                                    (stockGroupDataArray || []).map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.stockGroup}</td>
                                                <td className={"text-right"}>
                                                    {item.sku}
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            No SKU
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <ToastContainer />
            </Col>
        );
    }
}
