import {config} from '../helpers/config'
import {authHeader} from '../helpers/auth-header';
import axios from "axios";
import {LOGIN_PATH, PUBLIC_PATHS, UNAUTHORIZED} from "../store/Auth";


class HttpService {

    static Instance() {

        let ax = new axios.create({
            baseURL: config.apiUrl,
            timeout: 30000,
            headers: authHeader()
        });

        ax.interceptors.request.use(config => {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user && user.token) {
                config.headers.Authorization = "Bearer " + user.token
            }
            return config
        });

        ax.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error && error.response && error.response.status === UNAUTHORIZED) {
                if (window.location.pathname && !window.location.pathname.includes(LOGIN_PATH) && !PUBLIC_PATHS.includes(window.location.pathname)) {
                    localStorage.clear();
                    window.location = '/login';
                    console.log("Your session has expired, redirecting to the login page");
                }
            } else {
                return Promise.reject(error);
            } 
        });

        return ax;
    }
}

//https://gist.github.com/alfonmga/96474f6adb6ed8dee8bc8bf8627c0ae1
export default HttpService.Instance()