import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody,
    Row, Col, Table,
    Button,
    Spinner, Input, Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';
import FileSaver from 'file-saver';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrorMessage } from '../../services/CommonService';
import classnames from "classnames";
import keywayStockService from '../../services/KeywayStockService';
import ConfirmModal from '../../components/modal/ConfirmModal';
import ImportProdCodeCheckModal from '../../components/modal/ImportProdCodeCheckModal';

export default class ImportKeywayStockPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImport: false,
            downloading: false,
            importResponse: {},
            isDryRun: false,
            isOpenProdcodeCheckModal: false,
            isOpenConfirmationModal: false,
            existingProdCodes: {
                count: 0,
                data: []
            },
            newProdCodes: {
                count: 0,
                data: []
            }
        };


        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.downloadFormat = this.downloadFormat.bind(this);
        this.checkImportedDataProdCodes = this.checkImportedDataProdCodes.bind(this);
        this.import = this.import.bind(this);
        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.toggleProdCodeCheckModal = this.toggleProdCodeCheckModal.bind(this);
    }

    fileChangeHandler(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    checkImportedDataProdCodes() {
        let { existingProdCodes, newProdCodes } = this.state;
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        this.setState({ isImport: true });

        keywayStockService.checkImportedDataProdCodes(data).then(response => {
            existingProdCodes.data = response.data.filter(x => x.isExist == true);
            newProdCodes.data = response.data.filter(x => x.isExist == false);
            existingProdCodes.count = existingProdCodes.data.length;
            newProdCodes.count = newProdCodes.data.length;
            this.setState({ isImport: false, existingProdCodes, newProdCodes, isOpenProdcodeCheckModal: true });
        }).catch(error => {
            this.setState({ isImport: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    import() {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('isDryRun', this.state.isDryRun);
        this.setState({ isImport: true });

        keywayStockService.importKeywayStockData(data).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ isImport: false, importResponse: response.data, isOpenConfirmationModal: false, isOpenProdcodeCheckModal: false, isDryRun: false });
                if (response.data.hasError) {
                    toast.error("Error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.success("Success!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            }
        }).catch(error => {
            this.setState({ isImport: false, isDryRun: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    downloadFormat() {
        this.setState({ downloading: true });
        keywayStockService.generateKeywayStockImportFormat().then(response => {
            this.setState({ downloading: false })
            FileSaver.saveAs(response.data, "KEYWAY-STOCK-IMPORT-FORMAT" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false })
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleProdCodeCheckModal(isOpen) {
        this.setState({ isOpenProdcodeCheckModal: isOpen });
    }

    toggleConfirmationModal(isOpen) {
        this.setState({ isOpenConfirmationModal: isOpen });
    }

    checkFileData() {
        this.setState({ isDryRun: true }, () => this.import());
    }

    render() {
        let { downloading, isImport, importResponse, isOpenProdcodeCheckModal,
            isOpenConfirmationModal, newProdCodes, existingProdCodes } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/stocks')}>Keyway Stocks </BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Import</BreadcrumbItem>
                </Breadcrumb>

                <Row>
                    <Col xl={2} lg={2} md={1} sm={12} xs={12}>

                    </Col>
                    <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                <h6>Import Stock </h6>
                            </CardHeader>
                            <CardBody>

                                <div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Input type="file"
                                                name="file"
                                                id="file"
                                                onChange={this.fileChangeHandler}
                                                aria-label="Upload document" />
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button color={"info"} size={"sm"}
                                            onClick={() => this.checkFileData()}
                                            disabled={isImport}>
                                            {isImport
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"white"} />
                                                : null
                                            }
                                            Check Data</Button>&nbsp;
                                        <Button color={"primary"} size={"sm"}
                                            onClick={() => this.checkImportedDataProdCodes()}
                                            disabled={isImport}>
                                            {isImport
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"white"} />
                                                : <i className="fa fa-upload mr-2" />
                                            }
                                            Upload</Button>&nbsp;
                                        <Button color={"primary"} size={"sm"}
                                            outline={true}
                                            onClick={() => this.downloadFormat()}
                                            disabled={downloading}>{downloading
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"primary"} />
                                                : <i className="fa fa-download mr-2" />
                                            }Download template</Button>
                                    </div>

                                    {
                                        importResponse.hasError
                                            ? <div>
                                                <hr />
                                                <Table size={"sm"} hover={true} responsive>
                                                    <thead>
                                                        <tr>
                                                            <td>Row</td>
                                                            <td>Column</td>
                                                            <td>Title</td>
                                                            <td>Value</td>
                                                            <td>Validation Message</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {((importResponse.rows) || []).map(row => {
                                                            return <tr
                                                                className={row.hasError ? "table-danger" : "table-success"}>
                                                                <td>{row.rowIndex}</td>
                                                                <td>{row.columnIndex}</td>
                                                                <td>{row.inputTitle}</td>
                                                                <td>{row.inputValue}</td>
                                                                <td>{row.validationMessage}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : null
                                    }
                                </div>

                                <ToastContainer />

                            </CardBody>
                        </Card>
                        <ImportProdCodeCheckModal
                            existingProdCodes={existingProdCodes}
                            newProdCodes={newProdCodes}
                            isOpen={isOpenProdcodeCheckModal}
                            toggle={this.toggleProdCodeCheckModal}
                            isImport={isImport}
                            toggleConfirmationModal={this.toggleConfirmationModal}
                            isOpenConfirmModal={isOpenConfirmationModal}
                            importFunction={this.import}
                        />

                    </Col>
                </Row>
            </div>
        )
    }

}