import axios from './axios';
import { authHeader } from '../helpers/auth-header';
import { config } from '../helpers/config';

class StockInventoryService {

    static Instance() {
        return new StockInventoryService();
    }

    listStockInventory(req) {
        return axios.post('api/stock-inventory/list', req);
    }

    getOnestockInventoryByStock(prodCode) {
        return axios.get('api/stock-inventory/one/by-stock/?prodCode=' + prodCode);
    }

    getOnestockInventory(stockInventoryID) {
        return axios.get('api/stock-inventory/one/?stockInventoryId=' + stockInventoryID);
    }

    saveOrUpdateStockInventory(prodCode, req) {
        return axios.post('api/stock-inventory/save?prodCode=' + prodCode, req);
    }



}

export default StockInventoryService.Instance();