import React, {Component} from 'react';
import {
    Col,
    Row, Label
} from 'reactstrap';

import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class DueDateBetweenFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, startDate: undefined, endDate: undefined, filter: this.props.filter}
    }

    setStartDate(startDate) {
        this.updateFilter(startDate, "startDate", this);
    }

    setEndDate(endDate) {
        this.updateFilter(endDate, "endDate", this);
    }

    updateFilter(changedDate, type, self) {
        let {startDate, endDate, filter} = self.state;

        switch (type) {
            case "startDate":
                startDate = changedDate;
                break;
            case "endDate":
                endDate = changedDate;
                break;
        }

        if (!endDate) {
            endDate = new Date();
        }

        /*Comma separated StartDateString & EndDateString*/

        let dateString = "";
        let startDateString = "";
        let endDateString = "";


        if (startDate) {
            startDateString = moment(startDate).format('DD/MM/YYYY');
            dateString = startDateString + ",";
        } else {
            dateString = "" + ",";
        }
        endDateString = moment(endDate).format('DD/MM/YYYY');

        filter.value = dateString + endDateString;
        filter.displayValue = filter.value;

        this.setState({filter, startDate, endDate}, () => {
            this.props.onChange(filter, false);
        });
    }


    render() {
        let maxDate = moment().add(2, 'years').toDate();
        let minDate = moment().subtract(20, 'years').toDate();
        return (
            <div>
                <Row className={"pb-3"}>
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className={"pb-3"}>
                            <Label><i className="fa fa-calendar" aria-hidden="true"/>&nbsp;&nbsp;Search by Start
                                date</Label>
                            <div>

                                <DatePicker
                                    className="form-control"
                                    selected={this.state.startDate}
                                    onChange={date => this.setStartDate(date)}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>

                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={"pb-3"}>
                            <Label><i className="fa fa-calendar" aria-hidden="true"/>&nbsp;&nbsp;Search by End
                                date</Label>
                            <div>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.endDate}
                                    onChange={date => this.setEndDate(date)}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
