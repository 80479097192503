import React from 'react';
import {
    Col, Row, Card, CardBody, FormGroup, Label, Input, FormText
} from 'reactstrap';
import SearchCity from "../../../search/SearchCity";
import SearchState from "../../../search/SearchState";
import SearchPostcode from "../../../search/SearchPostcode";

export default function RetailCustomerDetail(props) {

    let {order, formError, handleChange} = props;
    return (
        <Card>
            <CardBody>
                <p><strong>Customer Details</strong></p>
                <Row>
                    <Col xl={'6'} lg={'6'} md={'12'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="name">First Name*</Label>
                            <Input type="text" name="retailFirstName"
                                   value={order.retailFirstName ? order.retailFirstName : ''}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}
                                   placeholder="Enter first name here"/>
                            <FormText color="danger">{formError.retailFirstName}</FormText>
                        </FormGroup>
                    </Col>
                    <Col xl={'6'} lg={'6'} md={'12'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="name">Last Name*</Label>
                            <Input type="text" name="retailLastName"
                                   value={order.retailLastName ? order.retailLastName : ''}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}
                                   placeholder="Enter name here"/>
                            <FormText color="danger">{formError.retailLastName}</FormText>
                        </FormGroup>
                    </Col>
                    <Col xl={'6'} lg={'6'} md={'12'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="name">Contact Number*</Label>
                            <Input type="text" name="retailPhone"
                                   value={order.retailPhone ? order.retailPhone : ''}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}
                                   placeholder="Enter  contact number here"/>
                            <FormText color="danger">{formError.retailPhone}</FormText>
                        </FormGroup>
                    </Col>
                    <Col xl={'6'} lg={'6'} md={'12'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="email">Email*</Label>
                            <Input type="text" name="retailEmail"
                                   value={order.retailEmail ? order.retailEmail : ''}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}
                                   placeholder="Enter email here"/>
                            <FormText color="danger">{formError.retailEmail}</FormText>
                        </FormGroup>
                    </Col>
                    <Col xl={'6'} lg={'6'} md={'12'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="address1">Address Line 1*</Label>
                            <Input autoComplete="off" type="text" name="retailAddressLine1"
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}
                                   value={order.retailAddressLine1 ? order.retailAddressLine1 : ''}
                                   placeholder="Enter company name here" id="retailAddressLine1"/>
                            <FormText color="danger">{formError.retailAddressLine1}</FormText>
                        </FormGroup>
                    </Col>
                    <Col xl={'6'} lg={'6'} md={'12'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="address2">Address Line 2</Label>
                            <Input autoComplete="off" type="text" name="retailAddressLine2"
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}
                                   value={order.retailAddressLine2 ? order.retailAddressLine2 : ''}
                                   placeholder="Enter address line 2 here" id="retailAddressLine2"/>
                        </FormGroup>
                    </Col>
                    <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="City">City*</Label>
                            <SearchCity
                                handleSelection={(selectedCity) => handleChange("retailCity", selectedCity)}
                                selected={order.retailCity || ''}
                                defaultSelected={''}
                                filters={{
                                    state: order.retailState || '',
                                    deliveryData: order.retailPostCode || ''
                                }}
                            />
                            <FormText color="danger">{formError.retailCity}</FormText>
                        </FormGroup>
                    </Col>
                    <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="State">State*</Label>
                            <SearchState
                                handleSelection={(selectedState) => handleChange("retailState", selectedState)}
                                selected={order.retailState || ''}
                                defaultSelected={''}
                                filters={{city: order.retailCity || '', postcode: order.retailPostCode || ''}}
                            />
                            <FormText color="danger">{formError.retailState}</FormText>
                        </FormGroup>
                    </Col>
                    <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                        <FormGroup>
                            <Label for="PostCode">Postcode*</Label>
                            <SearchPostcode
                                handleSelection={(selectedPostCode) => handleChange("retailPostCode", selectedPostCode)}
                                selected={order.retailPostCode || ''}
                                defaultSelected={''}
                                filters={{city: order.retailCity || '', state: order.retailState || ''}}
                            />
                            <FormText color="danger">{formError.retailPostCode}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    );
}