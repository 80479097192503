import React, {Component} from 'react';


import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Input, InputGroup, InputGroupAddon, FormText, Label
} from 'reactstrap';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserService from "../../services/UserService";
import { changeFormatOfDateString } from '../../services/CommonService';

function ResetPasswordHeaderContent(props) {

    if (props.targetUser && props.loggedInUser && props.targetUser.userId && props.loggedInUser.userId) {
        if (props.targetUser.userId === props.loggedInUser.userId) {
            return <p className={"lead"}> You are about to change
                your password.</p>
        }
    }

    return <p className={"lead"}> You are about to change
        <strong className={"text-primary"}>&nbsp;{props.targetUser.firstname}</strong>'s password.</p>;
}

export default class ResetPasswordModal extends Component {


    constructor(props) {
        super(props);

        this.state = {
            form: {
                password: "",
                repassword: "",
                errors: {
                    password: "",
                    repassword: ""
                }
            },
            loggedInUser: {}
        };

        this.userService = new UserService();
        this.updatePassword = this.updatePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cleanModal = this.cleanModal.bind(this);
    }

    componentDidMount() {
        let loggedInUser = this.userService.getLoggedInUser();
        this.setState({loggedInUser: loggedInUser})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.clear) {
            this.cleanModal();
        }
    }

    updatePassword() {
        let {form} = this.state;
        form = this.validateForm(form);
        this.setState({form: form});
        if (!form.errors.hasError) {
            this.props.updatePassword(form.password, form.repassword);
        }
    }

    validateForm(form) {
        form.errors.hasError = false;
        form.errors.password = "";
        form.errors.repassword = "";

        if (!form.password) {
            form.errors.password = "password required!";
            form.errors.hasError = true;
            return form;
        }
        if (form.password.length < 4) {
            form.errors.password = "password is too short!";
            form.errors.hasError = true;
            return form;
        }
        if (!form.repassword) {
            form.errors.repassword = "password required!";
            form.errors.hasError = true;
            return form;
        }
        if (form.repassword.length < 4) {
            form.errors.repassword = "password is too short!";
            form.errors.hasError = true;
            return form;
        }
        if (form.password !== form.repassword) {
            form.errors.password = "Doesn't match with confirm paasword input";
            form.errors.repassword = "Doesn't match with new paasword input";
            form.errors.hasError = true;
            return form;
        }

        return form;

    }

    handleChange(e) {
        const {name, value} = e.target;
        let {form} = this.state;
        form[name] = value.trim();

        if (form.errors.hasError) {
            form = this.validateForm(form);
        }
        this.setState({form});
    }

    cleanModal() {
        let form = {
            password: "",
            repassword: "",
            errors: {
                password: "",
                repassword: ""
            }
        };
        this.setState({form});
        this.props.toggleClear(false);
    }


    render() {

        let {user, isOpen, loading} = this.props;
        let {form, loggedInUser} = this.state;

        return (


            <Modal isOpen={isOpen}
                   scrollable={true}
                   toggle={() => this.props.toggleModel(false)}>
                <ModalHeader toggle={() => this.props.toggleModel(false)}>
                    <ResetPasswordHeaderContent targetUser={user} loggedInUser={loggedInUser}/>
                </ModalHeader>
                <ModalBody>
                    <div>
                        {user.lastPasswordUpdatedDate ?
                            <p>Last password updated on : {changeFormatOfDateString(user.lastPasswordUpdatedDate,"DD/MM/YYYY","DD MMM YYYY")}</p>
                            : null}

                        <FormGroup>
                            <Label for="password">New password*</Label>

                            <Input className="form-control"
                                   onChange={this.handleChange}
                                   type="password"
                                   name="password"
                                   value={form.password}
                                   placeholder="Enter your new password">
                            </Input>
                            <FormText color="danger">{form.errors.password}</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label for="repassword">Confirm new password*</Label>

                            <Input className="form-control"
                                   onChange={this.handleChange}
                                   type="password"
                                   name="repassword"
                                   value={form.repassword}
                                   placeholder="Confirm your new password">
                            </Input>
                            <FormText color="danger">{form.errors.repassword}</FormText>
                        </FormGroup>
                        <FormGroup className={"text-right"}>
                            <Button color='primary'
                                    disabled={loading || form.errors.hasError}
                                    onClick={this.updatePassword}>
                                {loading ?
                                    <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                    <i className="fa fa-floppy-o"/>} &nbsp;
                                {loading ? "Updating " : "Update "}password
                            </Button>

                            &nbsp;
                            <Button className={"btn btn-secondary"} onClick={() => this.props.toggleModel(false)}><i
                                className="fa fa-times"/>&nbsp;Cancel</Button>
                        </FormGroup>
                    </div>
                </ModalBody>

            </Modal>
        );
    }
}