import React, {Component} from 'react';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import OrderService from "../../../services/OrderService";
import {handleErrorMessage} from '../../../services/CommonService';
import {toast} from 'react-toastify';

export default class UpdateDebtorInvoiceNoteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ordNum: 0,
            notes: ""
        };
        this.orderService = new OrderService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ordNum: this.props.ordNum, notes: this.props.selected});
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            let notes = nextProps.selected;
            this.setState({ordNum: nextProps.ordNum, notes});
        }
    }

    handleChange(e) {
        this.setState({notes: e.target.value});
    }


    handleSubmit() {
        let {ordNum, notes} = this.state;
        let request = {
            ordNum: ordNum,
            notes: notes
        }
        this.setState({loading: true});
        this.orderService.updateNote(request).then(response => {
            this.setState({loading: false});
            this.props.refreshOrder();
            toast.success("Notes updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.props.toggleModel();
        }).catch(error => {
            this.setState({loading: false});
            console.log(error.response);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {

        let {notes} = this.state;

        return (
            <Modal isOpen={this.props.isOpen} size="md" scrollable={false} toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    Update Order Notes
                </ModalHeader>
                <ModalBody>
                    <div className="mt-1 mb-2">
                        <Label>Enter notes</Label>
                        <Input className="form-control"
                               onChange={this.handleChange}
                               type="textarea"
                               name="notes"
                               value={notes}
                               placeholder="Write notes here"/>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"}
                                size={"sm"}
                                onClick={this.handleSubmit}
                                disabled={this.state.loading}>
                            {this.state.loading ? <Spinner size={"sm"} className={"mr-2"}/> : null}
                            {this.state.loading ? "Saving" : "Save"}</Button>
                        <Button color={"secondary"}
                                size={"sm"}
                                className={"ml-2"} onClick={this.props.toggleModel}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}