import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody,
    Row, Col, Table,
    Button,
    Spinner, Input, Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrorMessage } from '../../services/CommonService';
import productConfigurationService from '../../services/product/ProductConfigurationService';


export default class ProductConfigurationMatrixConverterImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImport: false,
            downloading: false,
            importResponse: [],
            isDryRun: false,
            xAxisLabel: '',
            yAxisLabel: ''
        };


        this.fileChangeHandler = this.fileChangeHandler.bind(this);
    }

    fileChangeHandler(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    import() {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('isDryRun', false);
        this.setState({ isImport: true });
        productConfigurationService.importMatrixConverterData(data, this.state.xAxisLabel, this.state.yAxisLabel).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ isImport: false, importResponse: response.data });
                toast.success("Success!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            this.setState({ isImport: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });


    }



    handleChange(key, change) {
        this.setState({ [key]: change });
    }

    render() {
        let { isImport, importResponse } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Matrix converter</BreadcrumbItem>
                </Breadcrumb>

                <Row>
                    <Col xl={2} lg={2} md={1} sm={12} xs={12}>

                    </Col>
                    <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                <h6>Import Matrix converter</h6>
                            </CardHeader>
                            <CardBody>

                                <div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <h6>X Axis Label: </h6>
                                            <Input type={"text"}
                                                className={"mb-2"}
                                                onChange={(e) => this.setState({ xAxisLabel: e.target.value })} />

                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <h6>Y Axis Label: </h6>
                                            <Input type={"text"}
                                                className={"mb-2"}
                                                onChange={(e) => this.setState({ yAxisLabel: e.target.value })} />

                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            
                                            
                                            <Input type="file"
                                                name="file"
                                                id="file"
                                                onChange={this.fileChangeHandler}
                                                aria-label="Upload document" />
                                        </Col>
                                    </Row>
                                    <div className="text-right">

                                        <Button color={"primary"} size={"sm"}
                                            onClick={() => this.import()}
                                            disabled={isImport}>
                                            {isImport
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"white"} />
                                                : <i className="fa fa-upload mr-2" />
                                            }
                                            Upload</Button>
                                    </div>

                                    {
                                        importResponse.length > 0
                                            ? <div>
                                                <hr />
                                                <Table size={"sm"} hover={true} responsive>
                                                    <thead>
                                                        <tr>
                                                            <td>X-Axis Min Limit</td>
                                                            <td>X-Axis Max Limit</td>
                                                            <td>X-Axis Label</td>
                                                            <td>Y-Axis Min Limit</td>
                                                            <td>Y-Axis Max Limit</td>
                                                            <td>Y-Axis Label</td>
                                                            <td>Value</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {((importResponse) || []).map(row => {
                                                            return <tr
                                                                className={"table-success"}>
                                                                <td>{row.xAxisMinLimit}</td>
                                                                <td>{row.xAxisMaxLimit}</td>
                                                                <td>{row.xAxisLabel}</td>
                                                                <td>{row.yAxisMinLimit}</td>
                                                                <td>{row.yAxisMaxLimit}</td>
                                                                <td>{row.yAxisLabel}</td>
                                                                <td>{row.value}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : null
                                    }
                                </div>

                                <ToastContainer />

                            </CardBody>
                        </Card>


                    </Col>
                </Row>
            </div>
        )
    }

}