import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col,
    Button,Badge

} from 'reactstrap';
import { getLocalDateStringFromUtcDateString,getDateString} from '../../services/CommonService';

export default class UserInboxMessageModal extends Component {

    render() {
        let {isOpen, toggle, message} = this.props;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {message.subject}
                    {message.isImportant ? <Badge><i className={"fa star-selected fa-star mr-2 ml-2"}
                                              aria-hidden="true"/>Important&nbsp;&nbsp;</Badge> : null}

                </ModalHeader>
                <ModalBody style={{minHeight: 300}}>
                    <Row>
                        <Col xl={10} lg={9} md={8} sm={12} xs={12}><h6>{message.displayMessage}</h6></Col>
                        <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                            <div className={"text-right"}>
                                <div>{getDateString(message.createdAt, 'DD MMM YYYY')}</div>
                                <small>{getDateString(message.createdAt, 'hh:mm A')}</small>
                            </div>

                        </Col>
                    </Row>

                    <br/>
                    <p>{message.body}</p>

                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onClick={() => toggle(!isOpen)}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}