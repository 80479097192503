import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Row,
    Spinner,
    FormGroup, CardFooter, FormText
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import StockUnitService from '../../services/StockUnitService';
import StockUnitTypeService from '../../services/StockUnitTypeService';
import { handleErrorMessage } from '../../services/CommonService';

export default class StockUnitMapping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prodCodes: "",
            saving: false,
            measureUnitType:'',
            measureUnitTypeList: [],
            formErrors: {},
        }

        this.getMeasureUnitTypes = this.getMeasureUnitTypes.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        this.getMeasureUnitTypes();
    }

    getMeasureUnitTypes() {
        StockUnitTypeService.getDistinctMeasureUnitType().then((response) => {
            this.setState({ measureUnitTypeList: response.data });
        }).catch(error => {
            toast.error(error);
        })
    }

    validateForm() {
        let formErrors = {};
        let valid = true;

        if (this.state.prodCodes === "" || this.state.prodCodes === null || this.state.prodCodes === undefined) {
            formErrors.prodCodes = "Please enter prodcodes";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let result = this.validateForm();

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }

        else {
            var req = {
                prodCodes: this.state.prodCodes,
                measureUnitType: this.state.measureUnitType,
            }

            this.setState({ saving: true, formErrors: {} });
            StockUnitService.saveStockUnitMappings(req).then(response => {
                if (response.status === 200 || response.status === '200') {
                    toast.success("Saved");
                    this.setState({ saving: false });
                    this.props.handleRefreshOnSubmit();
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error));
                this.setState({ saving: false });
            })
        }
    }

    render() {
        let { prodCodes, measureUnitTypeList, saving, formErrors } = this.state;
        return (
            <div>
                <Card>
                    <CardHeader><h5>Stock Unit Mapping</h5></CardHeader>
                    <CardBody>
                        <Row>
                            &nbsp;&nbsp;&nbsp; Stock codes :
                                    </Row>
                        <FormGroup>
                            <Input type="textarea"
                                    invalid={formErrors.prodCodes !== undefined}
                                    onChange={(e) => this.setState({ prodCodes: e.target.value })}
                                    value={prodCodes}
                                    placeholder="Write comma seperated stock codes here ,for example:- 00001,00041" />
                            
                            <FormText color="danger">{formErrors.prodCodes}</FormText>
                        </FormGroup>

                        <Row>
                            &nbsp;&nbsp;&nbsp; Select Measure Unit type :
                        </Row>
                        <Row className="align-items-center">
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Input
                                    name="measureUnitType"
                                    type="select"
                                    value={this.state.measureUnitType || ''}
                                    onChange={(e) => this.setState({ measureUnitType: e.target.value })}>
                                    <option key='' value=''>Select</option>
                                    {measureUnitTypeList.map((item, index) =>
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    )}
                                </Input>
                            </Col>
                        </Row>
                        <FormText className="text-muted">** If measure unit type not selected then individual stock's measurement type will get automatically selected.</FormText>
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button size={"sm"} color='primary' onClick={this.handleSubmit}
                                disabled={saving}>

                                {saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> :
                                    <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                        </div>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
            );
    }
}