import React, {Component} from 'react';
import {Button, ButtonGroup, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {Link as Link, Redirect} from "react-router-dom";
import OrderService from "../../services/OrderService";
import queryString from 'query-string';

export default class SearchSalesOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ordNum: 0,
        };
        this.orderService = new OrderService();

        this.handleOrderNo = this.handleOrderNo.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.firstOrder = this.firstOrder.bind(this);
        this.lastOrder = this.lastOrder.bind(this);
        this.currentLastOrder = this.currentLastOrder.bind(this);
        this.currentNextOrder = this.currentNextOrder.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        if (this.props.ordNum) {
            this.setState({ordNum: this.props.ordNum})
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            this.setState({ordNum: nextProps.ordNum})
        }
    }

    handleOrderNo(e) {
        this.setState({ordNum: e.target.value});
    }

    goBack() {
        this.props.history.goBack();
        let search = window.location.search ? window.location.search.split("&") : null;
        if (window.location.pathname.includes("/sales/order/enquiry") && (search && search.length === 1)) {
            let self = this;
            setTimeout(function () {
                if (window.location.pathname && window.location.pathname.includes("/sales/order/enquiry") && window.location.search) {
                    let searchParams = queryString.parse(window.location.search);
                    let ordNum = searchParams.OrdNum ? parseInt(searchParams.OrdNum) : 0;
                    self.setState({ordNum: ordNum}, () => {
                        self.props.getOrder(ordNum, "current");
                    });
                }
                else {
                    window.history.go(-1);
                }
            }, 500);

        }
        else {
            this.props.history.go(-1);
        }

    }

    enterPressed(e) {
        let code = e.keyCode || e.which;
        if (code === 13) { //13 is the enter keycode
            this.props.getOrder(this.state.ordNum, "current");
        }
    }

    firstOrder() {
        this.props.getOrder(this.state.ordNum, "first");
    }

    lastOrder() {
        this.props.getOrder(this.state.ordNum, "last");
    }

    currentNextOrder() {
        this.props.getOrder(this.state.ordNum, "next");
    }

    currentLastOrder() {
        this.props.getOrder(this.state.ordNum, "previous");
    }

    render() {
        let {ordNum, isLoadingOrdNum} = this.state;
        let {getOrder, isLoadingOrder} = this.props;
        isLoadingOrdNum = isLoadingOrdNum || this.props.isLoadingOrdNum;
        return (
            <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <Button size={"sm"} color={"secondary"} className={"mr-2"}
                                onClick={this.goBack}>
                            <i className="fa fa-fw fa-chevron-left mr-2" aria-hidden="true"/>
                            Back
                        </Button>
                    </InputGroupAddon>
                    <Input type={"number"} name="orderNumber" id="orderNumber"
                           onChange={event => this.handleOrderNo(event)} onKeyPress={this.enterPressed}
                           value={ordNum ? ordNum : ''}
                           placeholder={isLoadingOrder ? "Searching..." : "Enter order number."}
                           disabled={isLoadingOrder}
                    />
                    <InputGroupAddon addonType="append">
                        <ButtonGroup>

                            <Button
                                title={"click here to search"}
                                color={"primary"}
                                disabled={isLoadingOrder || isLoadingOrdNum}
                                onClick={() => getOrder(ordNum, "current")}>
                                {isLoadingOrder || isLoadingOrdNum
                                    ? <i className="fa fa-fw fa-circle-o-notch fa-spin mr-2" aria-hidden="true"/>
                                    : <i className="fa fa-fw fa-search mr-2" aria-hidden="true"/>
                                }
                                Search
                            </Button>
                            <Button
                                title={"First"}
                                color={"secondary"}
                                disabled={isLoadingOrder || isLoadingOrdNum}
                                onClick={this.firstOrder}>
                                <i className="fa fa-fw fa-angle-double-left" aria-hidden="true"/>
                            </Button>
                            <Button
                                title={"Previous"}
                                color={"secondary"}
                                disabled={ordNum === '' || isLoadingOrder || isLoadingOrdNum}
                                onClick={this.currentLastOrder}>
                                <i className="fa fa-fw fa-angle-left" aria-hidden="true"/>
                            </Button>
                            <Button
                                title={"Next"}
                                color={"secondary"}
                                disabled={ordNum === '' || isLoadingOrder || isLoadingOrdNum}
                                onClick={this.currentNextOrder}>
                                <i className="fa fa-fw fa-angle-right" aria-hidden="true"/>
                            </Button>
                            <Button
                                title={"Last"}
                                color={"secondary"}
                                disabled={isLoadingOrder || isLoadingOrdNum}
                                onClick={this.lastOrder}>
                                <i className="fa fa-fw fa-angle-double-right" aria-hidden="true"/>
                            </Button>
                            <Link className={"btn btn-primary"}
                                  to={"/sales/order/create/product-builder/v1"}>
                                <i className="fa fa-fw fa-plus mr-2"
                                   aria-hidden="true"/>
                                New
                            </Link>
                        </ButtonGroup>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        )
    }
}