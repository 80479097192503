import React, {Component} from 'react';
import {Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner, Table} from 'reactstrap';
import NumberFormat from "react-number-format";
import {findIndex, getSorted} from '../../../../../../services/CommonService';
import {cloneDeep, isEmpty, camelCase} from 'lodash';
import classnames from 'classnames';
import curtainTrackUtil from "./CurtainTrackUtil";
import curtainTrackCustomUtil from "./CurtainTrackCustomUtil";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {v4 as uuidv4} from 'uuid';
import Select from 'react-select';
import CurtainTrackCustomConsolidatedBOMModal from "./CurtainTrackCustomConsolidatedBOMModal";
import CurtainTrackCustomBOMModal from "./CurtainTrackCustomBOMModal";
import {
    PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM,
    PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents, PRODUCT_BUILDER_CURTAIN_TRACK_PRODCODE,
    PRODUCT_BUILDER_ROLLER_BLIND_PRODCODE
} from "../../../../../../store/AppConstants";
import UploadFiles from "../../../../../attachment/UploadFiles";
import {roundDown} from "../../../../../../services/CommonService";
import customUtil from "../custom/CustomUtil";
import {PRODUCT_BUILDER_CUSTOM_BUILD} from "../../../../../../store/AppConstants";

const customReactSelectStyleDiagram = {
    valueContainer: (provided, state) => ({
        ...provided,
        height: 130,
        minHeight: 35
    }),
};

export default class CurtainTrackCustom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };
        this.init = this.init.bind(this);
        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateSubItemWrapper = this.updateSubItemWrapper.bind(this);
        this.updateSubItemConfiguration = this.updateSubItemConfiguration.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        this.init();
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    init() {
        let {product} = this.props;
        if (!product.init_custom) {
            product.init_custom = true;
            this.props.handleChange("product_init", product);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, subItemIndex, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                item.customID = uuidv4();
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index\
                product = this.updateConfiguration(product, itemIndex, subItemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                break;
            case "clone":
                item = cloneDeep(product.items[itemIndex]);
                item.customID = uuidv4();
                product.items.push(item);
                product.items.forEach((item, itemIndex) => {
                    if (curtainTrackUtil.isItemTypeIsCustom(product, itemIndex)) {
                        product = curtainTrackCustomUtil.updateFormError("location", product, itemIndex);
                    }
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    if (curtainTrackUtil.isItemTypeIsCustom(product, itemIndex)) {
                        product = curtainTrackCustomUtil.updateFormError("location", product, itemIndex);
                    }
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    if (si.salesOrderItemCurtainTrackCustom) {
                        item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                        item.customID = uuidv4();
                        product.items.push(item); //insert item
                        itemIndex = product.items.length - 1; // get item index
                        product = this.updateConfiguration(product, itemIndex, subItemIndex, si, order); //update config for item
                        product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                        product = curtainTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                    }
                });
                product.savedItems = product.savedItems.filter(si => isEmpty(si.salesOrderItemCurtainTrackCustom));
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                        products[productIndex].items.push(item);

                        //prepare context
                        context = {
                            salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                        };
                        context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                        context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                        context.salesOrderItemCustom.width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                        context.salesOrderItemCustom.drop = 0;
                        context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_CURTAIN_TRACK_PRODCODE;
                        context.salesOrderItemCustom.productCode = product.code;
                        context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                        context.salesOrderItemCustom.productionInstructions = "";
                        context.salesOrderItemCustom.productionNotes = "";
                        context.salesOrderItemCustom.stocks = product.items[itemIndex].subItems[subItemIndex].stocks;
                        context.salesOrderItemCustom.stocks.forEach(s => {
                            s.isCustom = false;
                            property = camelCase(s.prodCode);
                            stock = null;
                            if (stocksByProdCode) {
                                stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                            }
                            if (stock) {
                                s.description = stock.description;
                                s.unitPrice = stock.price1;
                            }
                        });
                        products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                    });
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = curtainTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, subItemIndex, context, order) {
        let {configurations} = product.builder;
        let {currentUser} = this.props;
        let optionIndex, configurationIndex;

        product.items[itemIndex].itemType = PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM;
        product.items[itemIndex].configuration = {};


        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemCurtainTrackCustom.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + product.items.filter(i => i.itemType === PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM).length;
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemCurtainTrackCustom.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemCurtainTrackCustom.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 0;
        }

        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.model);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};

        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.operation.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options.filter(o => o.condition2.includes("dropdown"));
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.operation);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
        product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
        product.items[itemIndex].configuration.operation.formError = {isValid: true, message: "", dom: null,};

        //trackType
        configurationIndex = findIndex(configurations, "attributeKey", "trackType");
        product.items[itemIndex].configuration.trackType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.trackType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.trackType.finalOptions = product.items[itemIndex].configuration.trackType.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.trackType.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.trackType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.trackType.selected.value = product.items[itemIndex].configuration.trackType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.trackType.selected.dropdownValue = product.items[itemIndex].configuration.trackType.selected.value.optionKey;
        product.items[itemIndex].configuration.trackType.formError = {isValid: true, message: "", dom: null,};

        //trackOnly
        configurationIndex = findIndex(configurations, "attributeKey", "trackOnly");
        product.items[itemIndex].configuration.trackOnly = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.trackOnly.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.trackOnly.finalOptions = product.items[itemIndex].configuration.trackOnly.options;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.trackOnly.selected.value = context.salesOrderItemCurtainTrackCustom.trackOnly;
        } else {
            product.items[itemIndex].configuration.trackOnly.selected.value = false;
        }
        product.items[itemIndex].configuration.trackOnly.selected.dropdownValue = product.items[itemIndex].configuration.trackOnly.selected.value.optionKey;
        product.items[itemIndex].configuration.trackOnly.formError = {isValid: true, message: "", dom: null,};

        //trackColour
        configurationIndex = findIndex(configurations, "attributeKey", "trackColour");
        product.items[itemIndex].configuration.trackColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.trackColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.trackColour.finalOptions = product.items[itemIndex].configuration.trackColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.trackColour.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.trackColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.trackColour.selected.value = product.items[itemIndex].configuration.trackColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.trackColour.selected.dropdownValue = product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
        product.items[itemIndex].configuration.trackColour.formError = {isValid: true, message: "", dom: null,};

        //bendType
        configurationIndex = findIndex(configurations, "attributeKey", "bendType");
        product.items[itemIndex].configuration.bendType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bendType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bendType.options = getSorted(product.items[itemIndex].configuration.bendType.options, 'primaryAttributeMin', true);
        product.items[itemIndex].configuration.bendType.finalOptions = product.items[itemIndex].configuration.bendType.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bendType.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.bendType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.bendType.selected.value = product.items[itemIndex].configuration.bendType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bendType.selected.dropdownValue = product.items[itemIndex].configuration.bendType.selected.value.optionKey;
        product.items[itemIndex].configuration.bendType.formError = {isValid: true, message: "", dom: null,};

        //bendShape
        configurationIndex = findIndex(configurations, "attributeKey", "bendShape");
        product.items[itemIndex].configuration.bendShape = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bendShape.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        (product.items[itemIndex].configuration.bendShape.options || []).forEach((option, oIndex) => {
            option.id = option.optionKey + oIndex;
            option.value = option.optionKey;
            option.label = <div className="text-center" key={oIndex} style={{maxHeight: 125}}>
                {option.description
                    ? <img className="img-fluid rounded d-print-inline mr-2" src={option.description}
                           style={{maxHeight: 100}}/>
                    : null
                }
                <div>{option.optionLabel}</div>
            </div>;
        });
        product.items[itemIndex].configuration.bendShape.finalOptions = product.items[itemIndex].configuration.bendShape.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.bendType.selected.value.optionKey));
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bendShape.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.bendShape);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.bendShape.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.bendShape.selected.value = product.items[itemIndex].configuration.bendShape.finalOptions[optionIndex];
            product.items[itemIndex].configuration.bendShape.selected.dropdownValue = product.items[itemIndex].configuration.bendShape.selected.value.optionKey;
            product.items[itemIndex].configuration.bendShape.formError = {isValid: true, message: "", dom: null,};
        }


        //numberOfBends
        configurationIndex = findIndex(configurations, "attributeKey", "numberOfBends");
        product.items[itemIndex].configuration.numberOfBends = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.numberOfBends.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.numberOfBends.finalOptions = product.items[itemIndex].configuration.numberOfBends.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.numberOfBends.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.numberOfBends + "");
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.numberOfBends.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.numberOfBends.selected.value = product.items[itemIndex].configuration.numberOfBends.finalOptions[optionIndex];
            product.items[itemIndex].configuration.numberOfBends.selected.dropdownValue = product.items[itemIndex].configuration.numberOfBends.selected.value.optionKey;
            product.items[itemIndex].configuration.numberOfBends.formError = {isValid: true, message: "", dom: null,};
        }


        //lengthA
        configurationIndex = findIndex(configurations, "attributeKey", "lengthA");
        product.items[itemIndex].configuration.lengthA = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.lengthA.min = 0;// default
        product.items[itemIndex].configuration.lengthA.max = 1000000;// default
        product.items[itemIndex].configuration.lengthA.selected = {isSelectable: true, value: ""};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.lengthA.selected.value = parseInt(context.salesOrderItemCurtainTrackCustom.lengthA);
        } else {
            product.items[itemIndex].configuration.lengthA.selected.value = 0;
        }
        product.items[itemIndex].configuration.lengthA.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //lengthB
        configurationIndex = findIndex(configurations, "attributeKey", "lengthB");
        product.items[itemIndex].configuration.lengthB = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.lengthB.min = 0;// default
        product.items[itemIndex].configuration.lengthB.max = 1000000;// default
        product.items[itemIndex].configuration.lengthB.selected = {isSelectable: true, value: ""};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.lengthB.selected.value = parseInt(context.salesOrderItemCurtainTrackCustom.lengthB);
        } else {
            product.items[itemIndex].configuration.lengthB.selected.value = 0;
        }
        product.items[itemIndex].configuration.lengthB.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //lengthC
        configurationIndex = findIndex(configurations, "attributeKey", "lengthC");
        product.items[itemIndex].configuration.lengthC = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.lengthC.min = 0;// default
        product.items[itemIndex].configuration.lengthC.max = 1000000;// default
        product.items[itemIndex].configuration.lengthC.selected = {isSelectable: true, value: ""};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.lengthC.selected.value = parseInt(context.salesOrderItemCurtainTrackCustom.lengthC);
        } else {
            product.items[itemIndex].configuration.lengthC.selected.value = 0;
        }
        product.items[itemIndex].configuration.lengthC.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //bendAttachment
        configurationIndex = findIndex(configurations, "attributeKey", "bendAttachment");
        product.items[itemIndex].configuration.bendAttachment = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bendAttachment.selected = {isSelectable: false, value: "",};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bendAttachment.selected.value = context.salesOrderItemCurtainTrackCustom.bendAttachment;
        } else {
            product.items[itemIndex].configuration.bendAttachment.selected.value = "";
        }
        product.items[itemIndex].configuration.bendAttachment.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //split
        configurationIndex = findIndex(configurations, "attributeKey", "split");
        product.items[itemIndex].configuration.split = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.split.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.split.finalOptions = product.items[itemIndex].configuration.split.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.split.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.split);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.split.selected.value = product.items[itemIndex].configuration.split.finalOptions[optionIndex];
        product.items[itemIndex].configuration.split.selected.dropdownValue = product.items[itemIndex].configuration.split.selected.value.optionKey;
        product.items[itemIndex].configuration.split.formError = {isValid: true, message: "", dom: null,};


        //splitLength
        configurationIndex = findIndex(configurations, "attributeKey", "splitLength");
        product.items[itemIndex].configuration.splitLength = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.splitLength.min = 100;// default
        product.items[itemIndex].configuration.splitLength.max = 10000;// default
        product.items[itemIndex].configuration.splitLength.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.splitLength.selected.value = parseInt(context.salesOrderItemCurtainTrackCustom.splitLength);
        } else {
            product.items[itemIndex].configuration.splitLength.selected.value = 0;
        }

        product.items[itemIndex].configuration.splitLength.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //bracket
        configurationIndex = findIndex(configurations, "attributeKey", "bracket");
        product.items[itemIndex].configuration.bracket = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracket.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bracket.options.forEach(o => {
            if (!isEmpty(o.sets)) {
                o.sets = getSorted(o.sets, "min", true, "max", true);
            }
        });
        product.items[itemIndex].configuration.bracket.finalOptions = product.items[itemIndex].configuration.bracket.options.filter(o => o.condition1.includes("dropdown"));
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bracket.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.bracket);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.bracket.selected.value = product.items[itemIndex].configuration.bracket.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bracket.selected.dropdownValue = product.items[itemIndex].configuration.bracket.selected.value.optionKey;
        product.items[itemIndex].configuration.bracket.formError = {isValid: true, message: "", dom: null,};


        //projection
        configurationIndex = findIndex(configurations, "attributeKey", "projection");
        product.items[itemIndex].configuration.projection = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.projection.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.projection.finalOptions = product.items[itemIndex].configuration.projection.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.projection.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.projection);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.projection.selected.value = product.items[itemIndex].configuration.projection.finalOptions[optionIndex];
        product.items[itemIndex].configuration.projection.selected.dropdownValue = product.items[itemIndex].configuration.projection.selected.value.optionKey;
        product.items[itemIndex].configuration.projection.formError = {isValid: true, message: "", dom: null,};


        //returnOption
        configurationIndex = findIndex(configurations, "attributeKey", "returnOption");
        product.items[itemIndex].configuration.returnOption = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.returnOption.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.returnOption.finalOptions = product.items[itemIndex].configuration.returnOption.options.filter(o => o.condition1.includes("dropdown"));
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.returnOption.finalOptions, 'optionKey', context.salesOrderItemCurtainTrackCustom.returnOption);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.returnOption.selected.value = product.items[itemIndex].configuration.returnOption.finalOptions[optionIndex];
        product.items[itemIndex].configuration.returnOption.selected.dropdownValue = product.items[itemIndex].configuration.returnOption.selected.value.optionKey;
        product.items[itemIndex].configuration.returnOption.formError = {isValid: true, message: "", dom: null,};

        //remote
        configurationIndex = findIndex(configurations, "attributeKey", "remote");
        product.items[itemIndex].configuration.remote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.remote.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.remote.finalOptions = product.items[itemIndex].configuration.remote.options;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.remote.selected.value = context.salesOrderItemCurtainTrackCustom.remote;
        } else {
            product.items[itemIndex].configuration.remote.selected.value = false;
        }
        product.items[itemIndex].configuration.remote.selected.dropdownValue = product.items[itemIndex].configuration.remote.selected.value.optionKey;
        product.items[itemIndex].configuration.remote.formError = {isValid: true, message: "", dom: null,};

        //charger
        configurationIndex = findIndex(configurations, "attributeKey", "charger");
        product.items[itemIndex].configuration.charger = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.charger.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.charger.finalOptions = product.items[itemIndex].configuration.charger.options;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.charger.selected.value = context.salesOrderItemCurtainTrackCustom.charger;
        } else {
            product.items[itemIndex].configuration.charger.selected.value = false;
        }
        product.items[itemIndex].configuration.charger.selected.dropdownValue = product.items[itemIndex].configuration.charger.selected.value.optionKey;
        product.items[itemIndex].configuration.charger.formError = {isValid: true, message: "", dom: null,};

        //bridge
        configurationIndex = findIndex(configurations, "attributeKey", "bridge");
        product.items[itemIndex].configuration.bridge = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bridge.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bridge.finalOptions = product.items[itemIndex].configuration.bridge.options;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bridge.selected.value = context.salesOrderItemCurtainTrackCustom.bridge;
        } else {
            product.items[itemIndex].configuration.bridge.selected.value = false;
        }
        product.items[itemIndex].configuration.bridge.selected.dropdownValue = product.items[itemIndex].configuration.bridge.selected.value.optionKey;
        product.items[itemIndex].configuration.bridge.formError = {isValid: true, message: "", dom: null,};


        //spaceBetweenTracks
        configurationIndex = findIndex(configurations, "attributeKey", "spaceBetweenTracks");
        product.items[itemIndex].configuration.spaceBetweenTracks = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.spaceBetweenTracks.min = 40;// default
        product.items[itemIndex].configuration.spaceBetweenTracks.max = 160;// default
        product.items[itemIndex].configuration.spaceBetweenTracks.selected = {isSelectable: false};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.spaceBetweenTracks.selected.value = parseInt(context.salesOrderItemCurtainTrackCustom.spaceBetweenTracks);
        } else {
            product.items[itemIndex].configuration.spaceBetweenTracks.selected.value = 0;
        }
        product.items[itemIndex].configuration.spaceBetweenTracks.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //trackSequenceMax
        product.items[itemIndex].configuration.trackSequenceMax = {
            selected: {value: 1}
        };
        if (!isEmpty(product.items[itemIndex].configuration.trackType.selected.value)) {
            switch (product.items[itemIndex].configuration.trackType.selected.value.optionKey) {
                case "Single":
                    product.items[itemIndex].configuration.trackSequenceMax.selected.value = 1;
                    break;
                case "Double":
                    product.items[itemIndex].configuration.trackSequenceMax.selected.value = 2;
                    break;
            }
        }

        //productionCalculation
        configurationIndex = findIndex(configurations, "attributeKey", "productionCalculation");
        product.items[itemIndex].configuration.productionCalculation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.productionCalculation.selected = {
            trackCutWidth: 0,
            bracketQty: 0,
            gliderQty: 0,
            ironRunnerNylon: 0,
        };


        product = curtainTrackCustomUtil.updateRules("location", product, itemIndex, subItemIndex, order);

        product = curtainTrackCustomUtil.updateRules("drop", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("bendShape", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("numberOfBends", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("operation", product, itemIndex, subItemIndex, order);

        product = this.updateItemConfigurationOptions("bendShape", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("numberOfBends", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("operation", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("trackColour", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("projection", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("projection", product, itemIndex, subItemIndex, order);

        product = curtainTrackCustomUtil.updateRules("remote", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("charger", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("bridge", product, itemIndex, subItemIndex, order);

        product = curtainTrackCustomUtil.updateRules("spaceBetweenTracks", product, itemIndex, subItemIndex, order);

        product = curtainTrackCustomUtil.updateRules("returnOption", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("returnOption", product, itemIndex, subItemIndex, order);

        product = this.updateItemConfigurationOptions("trackSequenceMax", product, itemIndex, subItemIndex, order);

        product = this.updateSubItemWrapper(product, itemIndex, subItemIndex, order, context);
        product = curtainTrackCustomUtil.updateRules("length", product, itemIndex, subItemIndex, order);
        //product = this.updateItemConfigurationOptions("length", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateFormError("length", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("splitLength", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateFormError("location", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateFormError("drop", product, itemIndex, subItemIndex, order);

        product = curtainTrackCustomUtil.updateFormError("splitLength", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateFormError("spaceBetweenTracks", product, itemIndex, subItemIndex);

        return product;
    }

    updateSubItemWrapper(product, itemIndex, subItemIndex, order, context) {
        let temp;
        product.items[itemIndex].subItems = [];

        for (let subItemIndex = 0; subItemIndex < product.items[itemIndex].configuration.trackSequenceMax.selected.value; subItemIndex++) {
            product.items[itemIndex].subItems[subItemIndex] = {};
            temp = null;
            if (!isEmpty(context) && !isEmpty(context.salesOrderItemCurtainTrackCustom) && !isEmpty(context.salesOrderItemCurtainTrackCustom.subItems) && context.salesOrderItemCurtainTrackCustom.subItems[subItemIndex]) {
                temp = context.salesOrderItemCurtainTrackCustom.subItems[subItemIndex];
            }
            product = this.updateSubItemConfiguration(product, itemIndex, subItemIndex, temp, order);

            product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, order);

            product.items[itemIndex].subItems[subItemIndex] = cloneDeep(product.items[itemIndex].subItems[subItemIndex]);
        }
        return product;
    }

    updateSubItemConfiguration(product, itemIndex, subItemIndex, context, order) {
        let {configurations, fabrics} = product.builder;
        let optionIndex, configurationIndex;
        let {currentUser} = this.props;

        product.items[itemIndex].subItems[subItemIndex].configuration = {};
        product.items[itemIndex].subItems[subItemIndex].isValid = true;

        //trackSequence
        product.items[itemIndex].subItems[subItemIndex].configuration.trackSequence = {
            selected: {value: ""}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.trackSequence.selected.value = context.trackSequence;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.trackSequence.selected.value = subItemIndex + 1;
        }


        //trackPosition
        product.items[itemIndex].subItems[subItemIndex].configuration.trackPosition = {
            selected: {value: ""}
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.trackPosition.selected.value = context.trackPosition;
        } else {
            switch (product.items[itemIndex].subItems[subItemIndex].configuration.trackSequence.selected.value) {
                case 1:
                    product.items[itemIndex].subItems[subItemIndex].configuration.trackPosition.selected.value = "Back";
                    break;
                case 2:
                    product.items[itemIndex].subItems[subItemIndex].configuration.trackPosition.selected.value = "Front";
                    break;
            }

        }


        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].subItems[subItemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 200;// default
        product.items[itemIndex].subItems[subItemIndex].configuration.width.max = 6000;// default
        product.items[itemIndex].subItems[subItemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value = parseInt(context.width);
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value = 1000;
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected = {};
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions, "optionKey", context.fabricType);
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.optionKey;


        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true,
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.options = [];
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.options = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.colors.map(o => {
                return {
                    optionKey: o.color,
                    optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                    ...o
                }
            });
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
            )
        });
        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions)) {
            optionIndex = -1;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.fabricColour);
            }
            if (optionIndex === -1) {
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions)) {
                    optionIndex = 0;
                }
            }
            if (optionIndex > -1) {
                product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions[optionIndex];
                product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value.optionKey;
            }
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //control
        configurationIndex = findIndex(configurations, "attributeKey", "control");
        product.items[itemIndex].subItems[subItemIndex].configuration.control = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.control.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.control.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions, 'optionKey', context.control);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.control.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //runner
        configurationIndex = findIndex(configurations, "attributeKey", "runner");
        product.items[itemIndex].subItems[subItemIndex].configuration.runner = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.runner.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions, 'optionKey', context.runner);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.runner.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //runnerColour
        configurationIndex = findIndex(configurations, "attributeKey", "runnerColour");
        product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions, 'optionKey', context.runnerColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //cordType
        configurationIndex = findIndex(configurations, "attributeKey", "cordType");
        product.items[itemIndex].subItems[subItemIndex].configuration.cordType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions, 'optionKey', context.cordType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.cordType.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //cordSizeType
        configurationIndex = findIndex(configurations, "attributeKey", "cordSizeType");
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions, 'optionKey', context.cordSizeType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //cordSize
        configurationIndex = findIndex(configurations, "attributeKey", "cordSize");
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSize = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.min = 10;// default
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.max = 10000;// default
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value = context.cordSize;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value = 0;
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //stacking
        configurationIndex = findIndex(configurations, "attributeKey", "stacking");
        product.items[itemIndex].subItems[subItemIndex].configuration.stacking = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions, 'optionKey', context.stacking);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions[optionIndex];
        product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.stacking.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //overlapArms
        configurationIndex = findIndex(configurations, "attributeKey", "overlapArms");
        product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.options;
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value = context.overlapArms;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value = false;
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //underlapArms
        configurationIndex = findIndex(configurations, "attributeKey", "underlapArms");
        product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.options;
        if (!isEmpty(context)) {
            product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value = context.underlapArms;
        } else {
            product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value = false;
        }
        product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value.optionKey;
        product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //bom----only for stocks per subItem
        product.items[itemIndex].subItems[subItemIndex].configuration.bom = {
            runner: {
                selected: {
                    stocks: []
                }
            }, operation: {
                selected: {
                    stocks: []
                }
            }
        };

        //productionCalculation
        configurationIndex = findIndex(configurations, "attributeKey", "productionCalculation");
        product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected = {
            trackCutWidth: 0
        };


        //set
        configurationIndex = findIndex(configurations, "attributeKey", "basePrice");
        product.items[itemIndex].subItems[subItemIndex].configuration.set = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.set.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].subItems[subItemIndex].configuration.set.options.forEach(o => {
            if (!isEmpty(o.sets)) {
                o.sets = getSorted(o.sets, "min", true, "max", true);
            }
        });
        product.items[itemIndex].subItems[subItemIndex].configuration.set.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.set.options;


        //cutFee
        configurationIndex = findIndex(configurations, "attributeKey", "cutFee");
        product.items[itemIndex].subItems[subItemIndex].configuration.cutFee = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].subItems[subItemIndex].configuration.cutFee.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.cutFee.options;
        product.items[itemIndex].subItems[subItemIndex].configuration.cutFee.selected = {
            value: {},
        };

        //price
        product.items[itemIndex].subItems[subItemIndex].pricing = {
            unitPrice: 0,
        };

        product = curtainTrackCustomUtil.updateRules("width", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("width", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateFormError("width", product, itemIndex, subItemIndex, order);

        product = curtainTrackCustomUtil.updateRules("control", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("control", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("cordType", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("cordType", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("cordSizeType", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("cordSizeType", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("cordSize", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("cordSize", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateFormError("cordSize", product, itemIndex, subItemIndex);

        product = this.updateItemConfigurationOptions("stacking", product, itemIndex, subItemIndex, order);


        product = curtainTrackCustomUtil.updateRules("overlapArms", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("underlapArms", product, itemIndex, subItemIndex, order);

        product = this.updateItemConfigurationOptions("runner", product, itemIndex, subItemIndex, order);
        product = this.updateItemConfigurationOptions("runnerColour", product, itemIndex, subItemIndex, order);
        product = curtainTrackCustomUtil.updateRules("runnerColour", product, itemIndex, subItemIndex, order);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, subItemIndex, order, context) {
        let optionIndex, condition1, condition2, condition3, setIndex, width, temp;
        let {currentUser} = this.props;
        switch (key) {
            case "trackSequenceMax":
                if (!isEmpty(product.items[itemIndex].configuration.trackType.selected.value)) {
                    switch (product.items[itemIndex].configuration.trackType.selected.value.optionKey) {
                        case "Single":
                            product.items[itemIndex].configuration.trackSequenceMax.selected.value = 1;
                            break;
                        case "Double":
                            product.items[itemIndex].configuration.trackSequenceMax.selected.value = 2;
                            break;
                    }
                }
                break;
            case "fabricColour":
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value)) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.options = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.colors.map(o => {
                        return {
                            optionKey: o.color,
                            optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                            ...o
                        }
                    });
                }
                product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.options.filter(o => {
                    return (
                        salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                    )
                });
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions)) {
                    optionIndex = -1;
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value)) {
                        optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions, 'optionKey', product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value.optionKey);
                    }
                    if (optionIndex === -1) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions)) {
                            optionIndex = 0;
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value.optionKey;
                    }
                }
                break;
            case "operation":
                optionIndex = -1;
                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = "dropdown";
                if (product.items[itemIndex].configuration.operation.selected.isSelectable) {
                    product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options.filter(o => o.condition1.includes(condition1) && o.condition2.includes(condition2));
                    if (!isEmpty(product.items[itemIndex].configuration.operation.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.operation.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.operation.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].configuration.operation.finalOptions[optionIndex]) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.operation.selected.value = null;
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = "";
                }
                break;
            case "trackColour":
                optionIndex = -1;
                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                product.items[itemIndex].configuration.trackColour.finalOptions = product.items[itemIndex].configuration.trackColour.options.filter(o => o.condition1.includes(condition1));
                if (product.items[itemIndex].configuration.trackColour.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].configuration.trackColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.trackColour.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.trackColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.trackColour.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].configuration.trackColour.finalOptions[optionIndex]) {
                    product.items[itemIndex].configuration.trackColour.selected.value = product.items[itemIndex].configuration.trackColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.trackColour.selected.dropdownValue = product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.trackColour.selected.value = null;
                    product.items[itemIndex].configuration.trackColour.selected.dropdownValue = "";
                }
                break;
            case "bendShape":
                optionIndex = -1;
                condition1 = product.items[itemIndex].configuration.bendType.selected.value.optionKey;
                product.items[itemIndex].configuration.bendShape.finalOptions = product.items[itemIndex].configuration.bendShape.options.filter(o => o.condition1.includes(condition1));
                if (product.items[itemIndex].configuration.bendShape.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].configuration.bendShape.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.bendShape.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.bendShape.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bendShape.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (!isEmpty(product.items[itemIndex].configuration.bendShape.finalOptions[optionIndex])) {
                    product.items[itemIndex].configuration.bendShape.selected.value = product.items[itemIndex].configuration.bendShape.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bendShape.selected.dropdownValue = product.items[itemIndex].configuration.bendShape.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.bendShape.selected.value = null;
                    product.items[itemIndex].configuration.bendShape.selected.dropdownValue = "";
                }
                break;
            case "numberOfBends":
                optionIndex = -1;
                condition1 = product.items[itemIndex].configuration.bendType.selected.value.optionKey;
                condition2 = !isEmpty(product.items[itemIndex].configuration.bendShape.selected.value) ? product.items[itemIndex].configuration.bendShape.selected.value.optionKey : "";
                product.items[itemIndex].configuration.numberOfBends.finalOptions = product.items[itemIndex].configuration.numberOfBends.options.filter(o =>
                    (
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    )
                );
                if (product.items[itemIndex].configuration.numberOfBends.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].configuration.numberOfBends.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.bendType.selected.value)) {
                            switch (product.items[itemIndex].configuration.bendType.selected.value.optionKey) {
                                case "Custom Bend":
                                case "Radial Bend":
                                    if (!isEmpty(product.items[itemIndex].configuration.numberOfBends.selected.value)) {
                                        condition1 = product.items[itemIndex].configuration.numberOfBends.selected.value.optionKey;
                                        optionIndex = product.items[itemIndex].configuration.numberOfBends.finalOptions.findIndex(o => o.optionKey === condition1);
                                    }
                                    if (!(optionIndex > -1 )) {
                                        condition1 = "1"; //default
                                        optionIndex = product.items[itemIndex].configuration.numberOfBends.finalOptions.findIndex(o => o.optionKey === condition1);
                                    }
                                    //if couldn't get first item
                                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                                    break;
                                case "90 Degree Bend":
                                    if (!isEmpty(product.items[itemIndex].configuration.bendShape.selected.value)) {
                                        switch (product.items[itemIndex].configuration.bendShape.selected.value.optionKey) {
                                            case "Straight":
                                                condition1 = "0";
                                                break;
                                            case "L Shape":
                                                condition1 = "1";
                                                break;
                                            case "J Shape":
                                                condition1 = "1";
                                                break;
                                            case "U Shape":
                                                condition1 = "2";
                                                break;
                                            case "S Shape":
                                                condition1 = "3";
                                                break;
                                        }
                                        optionIndex = product.items[itemIndex].configuration.numberOfBends.finalOptions.findIndex(o => o.optionKey === condition1);
                                    }
                                    break;
                                case "None":
                                    condition1 = "0";
                                    optionIndex = product.items[itemIndex].configuration.numberOfBends.finalOptions.findIndex(o => o.optionKey === condition1);
                                    break;
                            }
                        }
                    }
                }
                if ((optionIndex > -1 && !isEmpty(product.items[itemIndex].configuration.numberOfBends.finalOptions[optionIndex]))) {
                    product.items[itemIndex].configuration.numberOfBends.selected.value = product.items[itemIndex].configuration.numberOfBends.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.numberOfBends.selected.dropdownValue = product.items[itemIndex].configuration.numberOfBends.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.numberOfBends.selected.value = null;
                    product.items[itemIndex].configuration.numberOfBends.selected.dropdownValue = "";
                }
                break;
            case "length":
                let numberOfBends = 0;
                if (product.items[itemIndex].configuration.lengthA.selected.isSelectable) {
                    numberOfBends++;
                }
                if (product.items[itemIndex].configuration.lengthB.selected.isSelectable) {
                    numberOfBends++;
                }
                if (product.items[itemIndex].configuration.lengthC.selected.isSelectable) {
                    numberOfBends++;
                }

                temp = product.items[itemIndex].subItems.map((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
                width = Math.max(...temp);

                product.items[itemIndex].configuration.lengthA.selected.value = product.items[itemIndex].configuration.lengthA.selected.isSelectable ? roundDown(width / numberOfBends, 0) : 0;
                product.items[itemIndex].configuration.lengthB.selected.value = product.items[itemIndex].configuration.lengthB.selected.isSelectable ? roundDown(width / numberOfBends, 0) : 0;
                product.items[itemIndex].configuration.lengthC.selected.value = product.items[itemIndex].configuration.lengthC.selected.isSelectable ? roundDown(width / numberOfBends, 0) : 0;
                break;
            case "runner":
                optionIndex = -1;
                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                if (product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.runner.options.filter(o => o.condition1.includes(condition1));
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions[optionIndex]) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey;
                } else {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.dropdownValue = "";
                }
                break;
            case "runnerColour":
                optionIndex = -1;
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value)) {
                    condition1 = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey;
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.options.filter(o => o.condition1.includes(condition1));
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions[optionIndex]) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value.optionKey;
                } else {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.dropdownValue = "";
                }
                break;
            case "control":
                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.control.options.filter(item => item.condition1.includes(condition1));
                optionIndex = -1;
                if (product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions[optionIndex]) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                } else {
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.dropdownValue = "";
                }
                break;
            case "cordType":
                optionIndex = -1;
                if (product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions[optionIndex]) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey;
                } else {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.dropdownValue = "";
                }
                break;
            case "cordSizeType":
                optionIndex = -1;
                if (product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions[optionIndex]) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value.optionKey;
                } else {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.dropdownValue = "";
                }
                break;
            case "stacking":
                optionIndex = -1;
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value)) {
                    condition1 = product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                    switch (condition1) {
                        case "Hand Drawn":
                            condition2 = "";
                            break;
                        case "Cord Drawn":
                            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value)) {
                                condition2 = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey;
                            }
                            break;
                    }
                }
                condition3 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                if (product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.options.filter(o => (o.condition1 ? o.condition1.includes(condition1) : true) && (o.condition2 ? o.condition2.includes(condition2) : true) && (o.condition3 ? o.condition3.includes(condition3) : true));
                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions[optionIndex]) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey;
                } else {
                    product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.dropdownValue = "";
                }
                break;
            case "bracket":
                optionIndex = -1;
                if (product.items[itemIndex].configuration.bracket.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].configuration.bracket.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.bracket.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.bracket.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bracket.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].configuration.bracket.finalOptions[optionIndex]) {
                    product.items[itemIndex].configuration.bracket.selected.value = product.items[itemIndex].configuration.bracket.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bracket.selected.dropdownValue = product.items[itemIndex].configuration.bracket.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.bracket.selected.value = null;
                    product.items[itemIndex].configuration.bracket.selected.dropdownValue = "";
                }
                break;
            case "projection":
                optionIndex = -1;
                condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.bracket.selected.value) ? "" : product.items[itemIndex].configuration.bracket.selected.value.optionKey;
                condition3 = isEmpty(product.items[itemIndex].configuration.trackType.selected.value) ? "" : product.items[itemIndex].configuration.trackType.selected.value.optionKey;
                product.items[itemIndex].configuration.projection.finalOptions = product.items[itemIndex].configuration.projection.options.filter(o =>
                    (o.condition1 ? o.condition1.includes(condition1) : true)
                    && (o.condition2 ? o.condition2.includes(condition2) : true)
                    && (o.condition3 ? o.condition3.includes(condition3) : true)
                );
                if (!isEmpty(product.items[itemIndex].configuration.projection.finalOptions)) {
                    if (!isEmpty(product.items[itemIndex].configuration.projection.selected.value)) {
                        //update selection, getting ideal selection
                        optionIndex = product.items[itemIndex].configuration.projection.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.projection.selected.value.optionKey);
                    }
                    //if couldn't get first item
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                }
                if (product.items[itemIndex].configuration.projection.finalOptions[optionIndex]) {
                    product.items[itemIndex].configuration.projection.selected.value = product.items[itemIndex].configuration.projection.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.projection.selected.dropdownValue = product.items[itemIndex].configuration.projection.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.projection.selected.value = null;
                    product.items[itemIndex].configuration.projection.selected.dropdownValue = "";
                }
                break;
            case "returnOption":
                optionIndex = -1;
                if (product.items[itemIndex].configuration.returnOption.selected.isSelectable) {
                    if (!isEmpty(product.items[itemIndex].configuration.returnOption.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.returnOption.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.returnOption.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.returnOption.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                }
                if (product.items[itemIndex].configuration.returnOption.finalOptions[optionIndex]) {
                    product.items[itemIndex].configuration.returnOption.selected.value = product.items[itemIndex].configuration.returnOption.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.returnOption.selected.dropdownValue = product.items[itemIndex].configuration.returnOption.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.returnOption.selected.value = null;
                    product.items[itemIndex].configuration.returnOption.selected.dropdownValue = "";
                }
                break;
            case "basePrice":
                optionIndex = product.items[itemIndex].subItems[subItemIndex].configuration.set.options.findIndex(o => o.optionKey === "default");
                if (optionIndex > -1) {
                    width = product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = "";
                    setIndex = -1;
                    switch (condition1) {
                        case "Styleline Track":
                            if (product.items[itemIndex].configuration.trackOnly.selected.value) {
                                condition2 = "Track Only";
                            } else {
                                condition2 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                            }
                            setIndex = product.items[itemIndex].subItems[subItemIndex].configuration.set.options[optionIndex].sets.findIndex(s =>
                                (s.condition1 ? s.condition1.includes(condition1) : true)
                                && (s.condition2 ? s.condition2.includes(condition2) : true)
                                && (s.min <= width && s.max >= width)
                            );
                            break;
                        case "S2000 Track":
                            if (product.items[itemIndex].configuration.trackOnly.selected.value) {
                                condition2 = "Track Only";
                                condition3 = "";
                            } else {
                                condition2 = isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value) ? "" : product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;
                                condition3 = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey
                            }

                            setIndex = product.items[itemIndex].subItems[subItemIndex].configuration.set.options[optionIndex].sets.findIndex(s =>
                                (s.condition1 ? s.condition1.includes(condition1) : true)
                                && (s.condition2 ? s.condition2.includes(condition2) : true)
                                && (s.condition3 ? s.condition3.includes(condition3) : true)
                                && (s.min <= width && s.max >= width)
                            );
                            break;
                        case "Motorised Track":
                            if (product.items[itemIndex].configuration.trackOnly.selected.value) {
                                condition2 = "Track Only";
                                condition3 = "";
                            } else {
                                condition2 = "";
                                condition3 = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey
                            }
                            setIndex = product.items[itemIndex].subItems[subItemIndex].configuration.set.options[optionIndex].sets.findIndex(s =>
                                (s.condition1 ? s.condition1.includes(condition1) : true)
                                && (s.condition2 ? s.condition2.includes(condition2) : true)
                                && (s.condition3 ? s.condition3.includes(condition3) : true)
                                && (s.min <= width && s.max >= width)
                            );
                            break;
                    }
                    if (setIndex > -1) {
                        product.items[itemIndex].subItems[subItemIndex].configuration.set.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.set.options[optionIndex].sets[setIndex];
                    }
                    break;
                }
        }
        return product;
    }

    handleChange(change, key, itemIndex, subItemIndex, isComponentUpdateRequired) {
        let {product, packagingAndHandling, discountByProductCode, order} = this.props;
        let optionIndex;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = curtainTrackCustomUtil.updateFormError("location", product, itemIndex, subItemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = curtainTrackCustomUtil.updateFormError("quantity", product, itemIndex, subItemIndex);
                break;
            case "width":
                product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("width", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("width", product, itemIndex, subItemIndex);
                product = this.updateItemConfigurationOptions("length", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("length", product, itemIndex, subItemIndex, order);
                product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, order);
                });
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = curtainTrackCustomUtil.updateFormError("drop", product, itemIndex, subItemIndex);
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, subItemIndex, order);
                }
                break;
            case "fabricColour":
                product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value = {};
                product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.dropdownValue = "";

                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                product = curtainTrackCustomUtil.updateFormError("fabric", product, itemIndex, subItemIndex);
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;

                    product = curtainTrackCustomUtil.updateRules("operation", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("remote", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("charger", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("bridge", product, itemIndex, subItemIndex, order);

                    product = this.updateItemConfigurationOptions("trackColour", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("runner", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("runnerColour", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("runnerColour", product, itemIndex, subItemIndex, order);

                    product = this.updateItemConfigurationOptions("projection", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("projection", product, itemIndex, subItemIndex, order);

                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        product = curtainTrackCustomUtil.updateRules("control", product, itemIndex, subItemIndex, order);
                        product = this.updateItemConfigurationOptions("control", product, itemIndex, subItemIndex, order);
                        product = curtainTrackCustomUtil.updateRules("cordType", product, itemIndex, subItemIndex, order);
                        product = this.updateItemConfigurationOptions("cordType", product, itemIndex, subItemIndex, order);
                        product = curtainTrackCustomUtil.updateRules("cordSizeType", product, itemIndex, subItemIndex, order);
                        product = this.updateItemConfigurationOptions("cordSizeType", product, itemIndex, subItemIndex, order);
                        product = curtainTrackCustomUtil.updateRules("cordSize", product, itemIndex, subItemIndex, order);
                        product = curtainTrackCustomUtil.updateFormError("cordSize", product, itemIndex, subItemIndex);

                        product = this.updateItemConfigurationOptions("stacking", product, itemIndex, subItemIndex, order);
                        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, order);
                        product = curtainTrackCustomUtil.updateRules("overlapArms", product, itemIndex, subItemIndex, order);
                        product = curtainTrackCustomUtil.updateRules("underlapArms", product, itemIndex, subItemIndex, order);
                    });

                    product = curtainTrackCustomUtil.updateRules("returnOption", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("returnOption", product, itemIndex, subItemIndex, order);
                }
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product = curtainTrackCustomUtil.updateRules("remote", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("charger", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("bridge", product, itemIndex, subItemIndex, order);

                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        product = curtainTrackCustomUtil.updateRules("control", product, itemIndex, subItemIndex, order);
                        product = this.updateItemConfigurationOptions("control", product, itemIndex, subItemIndex, order);
                    });
                }
                break;
            case "trackType":
                optionIndex = findIndex(product.items[itemIndex].configuration.trackType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.trackType.selected.value = product.items[itemIndex].configuration.trackType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.trackType.selected.dropdownValue = product.items[itemIndex].configuration.trackType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("trackSequenceMax", product, itemIndex, subItemIndex, order);
                    product = this.updateSubItemWrapper(product, itemIndex, subItemIndex, order, null);
                    product = this.updateItemConfigurationOptions("projection", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("projection", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("spaceBetweenTracks", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("spaceBetweenTracks", product, itemIndex, subItemIndex);
                }
                break;
            case "trackOnly":
                product.items[itemIndex].configuration.trackOnly.selected.value = change;
                product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, order);
                });
                break;
            case "remote":
                product.items[itemIndex].configuration.remote.selected.value = change;
                break;
            case "charger":
                product.items[itemIndex].configuration.charger.selected.value = change;
                break;
            case "bridge":
                product.items[itemIndex].configuration.bridge.selected.value = change;
                break;
            case "trackColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.trackColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.trackColour.selected.value = product.items[itemIndex].configuration.trackColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.trackColour.selected.dropdownValue = product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                }
                break;
            case "bendType":
                optionIndex = findIndex(product.items[itemIndex].configuration.bendType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bendType.selected.value = product.items[itemIndex].configuration.bendType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bendType.selected.dropdownValue = product.items[itemIndex].configuration.bendType.selected.value.optionKey;
                    product = curtainTrackCustomUtil.updateRules("bendShape", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("bendShape", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("numberOfBends", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("numberOfBends", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("length", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("length", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("length", product, itemIndex, subItemIndex);
                    product = curtainTrackCustomUtil.updateRules("spaceBetweenTracks", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("spaceBetweenTracks", product, itemIndex, subItemIndex);
                }
                break;
            case "bendShape":
                optionIndex = findIndex(product.items[itemIndex].configuration.bendShape.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bendShape.selected.value = product.items[itemIndex].configuration.bendShape.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bendShape.selected.dropdownValue = product.items[itemIndex].configuration.bendShape.selected.value.optionKey;
                    product = curtainTrackCustomUtil.updateRules("numberOfBends", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("numberOfBends", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("length", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("length", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("length", product, itemIndex, subItemIndex);
                    product = curtainTrackCustomUtil.updateRules("spaceBetweenTracks", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("spaceBetweenTracks", product, itemIndex, subItemIndex);
                }
                break;
            case "numberOfBends":
                optionIndex = findIndex(product.items[itemIndex].configuration.numberOfBends.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.numberOfBends.selected.value = product.items[itemIndex].configuration.numberOfBends.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.numberOfBends.selected.dropdownValue = product.items[itemIndex].configuration.numberOfBends.selected.value.optionKey;
                }
                break;
            case "bendAttachment":
                product.items[itemIndex].configuration.bendAttachment.selected.value = change;
                break;
            case "lengthA":
                product.items[itemIndex].configuration.lengthA.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("width", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateRules("splitLength", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("splitLength", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("length", product, itemIndex, subItemIndex);
                break;
            case "lengthB":
                product.items[itemIndex].configuration.lengthB.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("width", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateRules("splitLength", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("splitLength", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("length", product, itemIndex, subItemIndex);
                break;
            case "lengthC":
                product.items[itemIndex].configuration.lengthC.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("width", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateRules("splitLength", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("splitLength", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("length", product, itemIndex, subItemIndex);
                break;
            case "split":
                optionIndex = findIndex(product.items[itemIndex].configuration.split.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.split.selected.value = product.items[itemIndex].configuration.split.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.split.selected.dropdownValue = product.items[itemIndex].configuration.split.selected.value.optionKey;
                }
                product = curtainTrackCustomUtil.updateRules("splitLength", product, itemIndex, subItemIndex, order);
                product = curtainTrackCustomUtil.updateFormError("splitLength", product, itemIndex, subItemIndex);
                break;
            case "splitLength":
                product.items[itemIndex].configuration.splitLength.selected.value = parseInt(change);
                product = curtainTrackCustomUtil.updateFormError("splitLength", product, itemIndex, subItemIndex);
                break;
            case "runner":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("runnerColour", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("runnerColour", product, itemIndex, subItemIndex, order);

                    product.items[itemIndex].subItems.forEach((subItem, subItemIndex) => {
                        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, order);
                    });
                }
                break;
            case "runnerColour":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value.optionKey;
                }
                break;
            case "control":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey;

                    product = curtainTrackCustomUtil.updateRules("cordType", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("cordType", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("cordSizeType", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("cordSizeType", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("cordSize", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("cordSize", product, itemIndex, subItemIndex);

                    product = this.updateItemConfigurationOptions("stacking", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("overlapArms", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("underlapArms", product, itemIndex, subItemIndex, order);
                }
                break;
            case "cordType":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey;
                    product = curtainTrackCustomUtil.updateRules("cordSizeType", product, itemIndex, subItemIndex, order);
                    product = this.updateItemConfigurationOptions("cordSizeType", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("cordSize", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("cordSize", product, itemIndex, subItemIndex);

                    product = this.updateItemConfigurationOptions("stacking", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("overlapArms", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("underlapArms", product, itemIndex, subItemIndex, order);
                }
                break;
            case "cordSizeType":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value.optionKey;

                    product = curtainTrackCustomUtil.updateRules("cordSize", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateFormError("cordSize", product, itemIndex, subItemIndex);
                }
                break;
            case "cordSize":
                product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value = change;
                product = curtainTrackCustomUtil.updateFormError("cordSize", product, itemIndex, subItemIndex);
                break;
            case "stacking":
                optionIndex = findIndex(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.finalOptions[optionIndex];
                    product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.dropdownValue = product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey;
                    product = curtainTrackCustomUtil.updateRules("overlapArms", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("underlapArms", product, itemIndex, subItemIndex, order);
                }
                break;
            case "bracket":
                optionIndex = findIndex(product.items[itemIndex].configuration.bracket.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bracket.selected.value = product.items[itemIndex].configuration.bracket.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bracket.selected.dropdownValue = product.items[itemIndex].configuration.bracket.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("projection", product, itemIndex, subItemIndex, order);
                    product = curtainTrackCustomUtil.updateRules("projection", product, itemIndex, subItemIndex, order);
                }
                break;
            case "projection":
                optionIndex = findIndex(product.items[itemIndex].configuration.projection.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.projection.selected.value = product.items[itemIndex].configuration.projection.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.projection.selected.dropdownValue = product.items[itemIndex].configuration.projection.selected.value.optionKey;
                }
                break;
            case "returnOption":
                optionIndex = findIndex(product.items[itemIndex].configuration.returnOption.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.returnOption.selected.value = product.items[itemIndex].configuration.returnOption.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.returnOption.selected.dropdownValue = product.items[itemIndex].configuration.returnOption.selected.value.optionKey;
                }
                break;
            case "spaceBetweenTracks":
                product.items[itemIndex].configuration.spaceBetweenTracks.selected.value = parseInt(change);
                product = curtainTrackCustomUtil.updateFormError("spaceBetweenTracks", product, itemIndex, subItemIndex);
                break;
            case "overlapArms":
                product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value = change;
                break;
            case "underlapArms":
                product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value = change;
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = curtainTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = curtainTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }


    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, itemSummary, currentUser, order, discountByProductCode} = this.props;
        let items = itemSummary.items;

        if (!product.init_custom) {
            return <Spinner color={"primary"}/>;
        }

        return (
            <div>
                {(items && items.length > 0)
                    ? <div className="table-responsive"
                           id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                        <Table bordered style={{minHeight: 400}}>
                            <thead>
                            <tr>
                                <th style={{minWidth: 45}} className={"text-center"}>
                                    #
                                </th>
                                <th style={{minWidth: 150}} className={"text-center"}>
                                    Location
                                </th>
                                <th style={{minWidth: 100}} className={"text-center"}>
                                    Quantity
                                </th>
                                <th style={{minWidth: 260}} className={"text-center"}>
                                    Model
                                </th>
                                <th style={{minWidth: 150}} className={"text-center"}>
                                    Track
                                </th>

                                <th style={{minWidth: 250}} className={"text-center"}>
                                    Bend
                                </th>
                                <th style={{minWidth: 100}} className={"text-center"} colSpan={2}>
                                    <div>Track</div>
                                    <div>Position</div>
                                </th>
                                <th style={{minWidth: 215}} className={"text-center"}>
                                    Width(mm)
                                </th>
                                <th style={{minWidth: 215}} className={"text-center"}>
                                    Bend length
                                </th>
                                {/*<th style={{minWidth: 100}} className={"text-center"}>
                                    Drop(mm)
                                </th>
                                <th style={{minWidth: 250}} className={"text-center"}>
                                    Fabric
                                </th>*/}
                                <th style={{minWidth: 175}} className={"text-center"}>
                                    <div>Space</div>
                                    <div>Between</div>
                                    <div>Tracks</div>
                                </th>
                                <th style={{minWidth: 165}} className={"text-center"}>
                                    Cut for Joint {/*change from split*/}
                                </th>
                                <th style={{minWidth: 270}} className={"text-center"}>
                                    Runner/Hooks
                                </th>
                                <th style={{minWidth: 165}} className={"text-center"}>
                                    Control/Motor
                                </th>
                                <th style={{minWidth: 180}} className={"text-center"}>
                                    Stacking
                                </th>
                                <th style={{minWidth: 235}} className={"text-center"}>
                                    Brackets
                                </th>
                                <th style={{minWidth: 50}}>
                                    <div className={"d-flex justify-content-center"}>
                                        <div className={"align-self-center"}>Unit Price</div>
                                    </div>
                                </th>
                                <th style={{minWidth: 50}}>
                                    <div className={"d-flex justify-content-center"}>
                                        <div className={"align-self-center"}>Price</div>
                                    </div>
                                </th>
                                <th style={{minWidth: 125}}>
                                    <div className={"d-flex justify-content-center"}>
                                        <div className={"align-self-center"}>Action</div>
                                    </div>
                                </th>
                                {
                                    !currentUser.isExternalUser
                                        ? <th style={{minWidth: 90}}>
                                            <div className={"d-flex justify-content-center"}>
                                                <div className={"align-self-center"}>
                                                    <Button color={"link"}
                                                            onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                </div>
                                            </div>
                                        </th>
                                        : null

                                }

                            </tr>
                            </thead>
                            <tbody>
                            {
                                (items || []).map((item, itemIndex) => {
                                        return (item.subItems || []).map((subItem, subItemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex + "-subItem-" + subItemIndex;
                                            return <tr
                                                key={uniqueId}
                                                id={uniqueId}
                                                className={classnames({"table-danger": !item.isValid})}>
                                                {subItemIndex === 0
                                                    ? <td className="align-middle text-center"
                                                          rowSpan={item.subItems.length}>
                                                        {itemIndex + 1}
                                                    </td>
                                                    : null
                                                }
                                                {subItemIndex === 0
                                                    ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            <div>
                                                                <Input type="text" name="location"
                                                                       value={item.configuration.location.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "location", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                       placeholder="location"/>
                                                                {
                                                                    item.configuration.location.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.location.formError.message}
                                                                        </Badge>
                                                                }
                                                                {
                                                                    item.configuration.location.formError.isWarning
                                                                        ? <Badge color="warning">
                                                                            {item.configuration.location.formError.message}
                                                                        </Badge>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    : null
                                                }
                                                {subItemIndex === 0
                                                    ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            <div>
                                                                <Input type="number" name="quantity"
                                                                       invalid={!item.configuration.quantity.formError.isValid}
                                                                       value={item.configuration.quantity.selected.value}
                                                                       min={item.configuration.quantity.min}
                                                                       max={item.configuration.quantity.max}
                                                                       onChange={(e) => this.handleChange(e.target.value, "quantity", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                       onFocus={(event) => event.target.select()}
                                                                       placeholder="quantity"/>

                                                                {
                                                                    item.configuration.quantity.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.quantity.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    : null
                                                }
                                                {subItemIndex === 0
                                                    ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                        <Input type="select" name="model"
                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("model")}
                                                               value={item.configuration.model.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "model", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                            <option value={""} disabled={true}>Select Model</option>
                                                            {
                                                                (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                        {
                                                            (item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("operation"))
                                                                ? null
                                                                : <div>
                                                                    {
                                                                        item.configuration.operation.selected.isSelectable
                                                                            ? <Input type="select" name="operation"
                                                                                     className={"mt-2"}
                                                                                     value={item.configuration.operation.selected.dropdownValue}
                                                                                     onChange={(e) => this.handleChange(e.target.value, "operation", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                                <option value={""} disabled={true}>Select
                                                                                    Operation
                                                                                </option>
                                                                                {
                                                                                    (item.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                                        return <option
                                                                                            key={oIndex}
                                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                            : null
                                                                    }
                                                                </div>
                                                        }

                                                        {
                                                            item.configuration.remote.selected.isSelectable
                                                                ? <div className="mt-1">
                                                                    <Button
                                                                        size={"sm"}
                                                                        className={"mr-2 mb-1"}
                                                                        color={"link"}
                                                                        disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("remote")}
                                                                        onClick={() => this.handleChange(!item.configuration.remote.selected.value, "remote", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                "fa-check-square-o": item.configuration.remote.selected.value,
                                                                                "fa-square-o": !item.configuration.remote.selected.value,
                                                                            }
                                                                        )}/>
                                                                    </Button>
                                                                    {item.configuration.remote.attributeLabel}
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.charger.selected.isSelectable
                                                                ? <div className="mt-1">
                                                                    <Button
                                                                        size={"sm"}
                                                                        className={"mr-2 mb-1"}
                                                                        color={"link"}
                                                                        disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("charger")}
                                                                        onClick={() => this.handleChange(!item.configuration.charger.selected.value, "charger", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                "fa-check-square-o": item.configuration.charger.selected.value,
                                                                                "fa-square-o": !item.configuration.charger.selected.value,
                                                                            }
                                                                        )}/>
                                                                    </Button>
                                                                    {item.configuration.charger.attributeLabel}
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.bridge.selected.isSelectable
                                                                ? <div className="mt-1">
                                                                    <Button
                                                                        size={"sm"}
                                                                        className={"mr-2 mb-1"}
                                                                        color={"link"}
                                                                        disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("bridge")}
                                                                        onClick={() => this.handleChange(!item.configuration.bridge.selected.value, "bridge", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                "fa-check-square-o": item.configuration.bridge.selected.value,
                                                                                "fa-square-o": !item.configuration.bridge.selected.value,
                                                                            }
                                                                        )}/>
                                                                    </Button>
                                                                    {item.configuration.bridge.attributeLabel}
                                                                </div>
                                                                : null
                                                        }
                                                    </td>
                                                    : null
                                                }
                                                {subItemIndex === 0
                                                    ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                        <Input type="select" name="trackType"
                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("trackType")}
                                                               value={item.configuration.trackType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "trackType", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                            <option value={""} disabled={true}>Select Track Type</option>
                                                            {
                                                                (item.configuration.trackType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                        <Input type="select" name="trackColour"
                                                               className="mt-2"
                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("trackColour")}
                                                               value={item.configuration.trackColour.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "trackColour", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                            <option value={""} disabled={true}>Select Track Colour</option>
                                                            {
                                                                (item.configuration.trackColour.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                        <div className="mt-1">
                                                            <Button
                                                                size={"sm"}
                                                                className={"mr-2 mb-1"}
                                                                color={"link"}
                                                                disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("trackOnly")}
                                                                onClick={() => this.handleChange(!item.configuration.trackOnly.selected.value, "trackOnly", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": item.configuration.trackOnly.selected.value,
                                                                        "fa-square-o": !item.configuration.trackOnly.selected.value,
                                                                    }
                                                                )}/>
                                                            </Button>
                                                            {item.configuration.trackOnly.attributeLabel}
                                                        </div>
                                                    </td>
                                                    : null}

                                                {
                                                    subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <Input type="select" name="bendType"
                                                                   disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("bendType")}
                                                                   value={item.configuration.bendType.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "bendType", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                <option value={""} disabled={true}>Select Bend Type</option>
                                                                {
                                                                    (item.configuration.bendType.finalOptions || []).map((o, oIndex) => {
                                                                        return <option key={oIndex}
                                                                                       value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            {
                                                                item.configuration.bendShape.selected.isSelectable
                                                                    ? <div className="mt-2">
                                                                        <Select
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            value={item.configuration.bendShape.selected.value}
                                                                            onChange={(selectedOption) => this.handleChange(selectedOption.optionKey, "bendShape", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                            options={item.configuration.bendShape.finalOptions}
                                                                            getOptionLabel={option => option.label}
                                                                            getOptionValue={option => option.value}
                                                                            styles={customReactSelectStyleDiagram}
                                                                        />

                                                                    </div>
                                                                    : null
                                                            }{
                                                            item.configuration.numberOfBends.selected.isSelectable
                                                                ? <div className="mt-2">
                                                                    <Input type="select" name="numberOfBends"
                                                                           disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("numberOfBends")}
                                                                           value={item.configuration.numberOfBends.selected.dropdownValue}
                                                                           onChange={(e) => this.handleChange(e.target.value, "numberOfBends", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                        <option value={""} disabled={true}>Select Number of Bends</option>
                                                                        {
                                                                            (item.configuration.numberOfBends.finalOptions || []).map((o, oIndex) => {
                                                                                return <option key={oIndex}
                                                                                               value={o.optionKey}>{o.optionLabel}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                </div>
                                                                : null
                                                        }
                                                            {
                                                                items[itemIndex].configuration.bendAttachment.selected.isSelectable
                                                                    ? <div>
                                                                        <UploadFiles
                                                                            handleChange={(change) => this.handleChange(change, "bendAttachment", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                            attachmentIDs={items[itemIndex].configuration.bendAttachment.selected.value}
                                                                            multiple={false}
                                                                            maxLength={1} />
                                                                        <small>(Upload custom document)</small>
                                                                        <div>
                                                                            <Badge color="info">
                                                                                <i className="fa fa-info-circle mr-2"
                                                                                   aria-hidden="true"/>
                                                                                Price On Application
                                                                            </Badge>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </td>
                                                        : null
                                                }
                                                <td className="align-middle text-center">
                                                    {items[itemIndex].subItems[subItemIndex].configuration.trackSequence.selected.value}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {items[itemIndex].subItems[subItemIndex].configuration.trackPosition.selected.value}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="number" name="width"
                                                           invalid={!items[itemIndex].subItems[subItemIndex].configuration.width.formError.isValid}
                                                           value={items[itemIndex].subItems[subItemIndex].configuration.width.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="Width"/>
                                                    {
                                                        items[itemIndex].subItems[subItemIndex].configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {items[itemIndex].subItems[subItemIndex].configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                {subItemIndex === 0
                                                    ?
                                                    <td className="align-middle text-center" rowSpan={item.subItems.length}>
                                                        {
                                                            item.configuration.lengthA.selected.isSelectable
                                                                ? <div>
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>A</InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input type="number" name="lengthA"
                                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("lengthA")}
                                                                               value={item.configuration.lengthA.selected.value}
                                                                               onChange={(e) => this.handleChange(e.target.value, "lengthA", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                               onFocus={(event) => event.target.select()}
                                                                               placeholder="length A"/>
                                                                    </InputGroup>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.lengthB.selected.isSelectable
                                                                ? <div className="mt-2">
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>B</InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input type="number" name="lengthB"
                                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("lengthB")}
                                                                               value={item.configuration.lengthB.selected.value}
                                                                               onChange={(e) => this.handleChange(e.target.value, "lengthB", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                               onFocus={(event) => event.target.select()}
                                                                               placeholder="length B"/>

                                                                    </InputGroup>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.lengthC.selected.isSelectable
                                                                ? <div className="mt-2">
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>C</InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input type="number" name="lengthC"
                                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("lengthC")}
                                                                               value={item.configuration.lengthC.selected.value}
                                                                               onChange={(e) => this.handleChange(e.target.value, "lengthC", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                               onFocus={(event) => event.target.select()}
                                                                               placeholder="length C"/>

                                                                    </InputGroup>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.lengthA.formError.isValid
                                                                ? null
                                                                : <Badge color={"danger"}>
                                                                    {item.configuration.lengthA.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            item.configuration.lengthB.formError.isValid
                                                                ? null
                                                                : <Badge color={"danger"}>
                                                                    {item.configuration.lengthB.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            item.configuration.lengthC.formError.isValid
                                                                ? null
                                                                : <Badge color={"danger"}>
                                                                    {item.configuration.lengthC.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            (
                                                                !item.configuration.lengthA.selected.isSelectable
                                                                && !item.configuration.lengthB.selected.isSelectable
                                                                && !item.configuration.lengthC.selected.isSelectable
                                                            )
                                                                ? <span>N/A</span>
                                                                : null
                                                        }
                                                        {/*<div>
                                                            <InputGroup className="mt-2">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>Width</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type="number" name="width"
                                                                       disabled={true}
                                                                       invalid={!item.configuration.width.formError.isValid}
                                                                       value={item.configuration.width.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "width", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                       onFocus={(event) => event.target.select()}
                                                                       placeholder="Width"/>
                                                                {
                                                                    item.configuration.width.formError.isValid
                                                                        ? null
                                                                        : <Badge color={"danger"}>
                                                                            {item.configuration.width.formError.message}
                                                                        </Badge>
                                                                }
                                                            </InputGroup>
                                                        </div>*/}

                                                    </td>
                                                    : null
                                                }
                                                {/*{
                                                    subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <Input type="number" name="drop"
                                                                   disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("drop")}
                                                                   invalid={!item.configuration.drop.formError.isValid}
                                                                   value={item.configuration.drop.selected.value}
                                                                   onChange={(e) => this.handleChange(e.target.value, "drop", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="Drop"/>
                                                            {
                                                                item.configuration.drop.formError.isValid
                                                                    ? null
                                                                    : <Badge color={"danger"}>
                                                                        {item.configuration.drop.formError.message}
                                                                    </Badge>
                                                            }
                                                        </td>
                                                        : null
                                                }*/}
                                                {/*<td className="align-middle">
                                                    <Input type="select" name="fabricType"
                                                           disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("fabricType")}
                                                           value={subItem.configuration.fabricType.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "fabricType", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                        <option value={""} disabled={true}>Select type</option>
                                                        {
                                                            (subItem.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    disabled={o.isDiscontinued}
                                                                    key={"item" + itemIndex + "-subItem" + subItemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <div className={"mt-2"}>
                                                        <Input type="select" name="fabricColour"
                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("fabricColour")}
                                                               value={subItem.configuration.fabricColour.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricColour", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                            <option value={""} disabled>Select color</option>
                                                            {
                                                                (subItem.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </td>*/}
                                                {
                                                    subItemIndex === 0
                                                        ? <td className="align-middle text-center"
                                                              rowSpan={item.subItems.length}>
                                                            {
                                                                item.configuration.spaceBetweenTracks.selected.isSelectable
                                                                    ? <div>
                                                                        <Input type="number" name="spaceBetweenTracks"
                                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("spaceBetweenTracks")}
                                                                               invalid={!item.configuration.spaceBetweenTracks.formError.isValid}
                                                                               value={item.configuration.spaceBetweenTracks.selected.value}
                                                                               onChange={(e) => this.handleChange(e.target.value, "spaceBetweenTracks", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                               onFocus={(event) => event.target.select()}
                                                                               placeholder="Space"/>
                                                                        {
                                                                            item.configuration.spaceBetweenTracks.formError.isValid
                                                                                ? null
                                                                                : <Badge color={"danger"}>
                                                                                    {item.configuration.spaceBetweenTracks.formError.message}
                                                                                </Badge>
                                                                        }
                                                                    </div>
                                                                    : 'N/A'
                                                            }
                                                        </td>
                                                        : null
                                                }
                                                {
                                                    subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <Input type="select" name="split"
                                                                   disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("split")}
                                                                   value={item.configuration.split.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "split", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                <option value={""} disabled={true}>Select Split</option>
                                                                {
                                                                    (item.configuration.split.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            {
                                                                item.configuration.splitLength.selected.isSelectable
                                                                    ? <div>
                                                                        <InputGroup className="mt-2">
                                                                            <Input type="number" name="splitLength"
                                                                                   disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("splitLength")}
                                                                                   invalid={!item.configuration.splitLength.formError.isValid}
                                                                                   value={item.configuration.splitLength.selected.value}
                                                                                   onChange={(e) => this.handleChange(e.target.value, "splitLength", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                                   onFocus={(event) => event.target.select()}
                                                                                   placeholder="Split Length"/>
                                                                            <InputGroupAddon addonType="append">
                                                                                <InputGroupText>mm</InputGroupText>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                        {
                                                                            item.configuration.splitLength.formError.isValid
                                                                                ? null
                                                                                : <Badge color={"danger"}>
                                                                                    {item.configuration.splitLength.formError.message}
                                                                                </Badge>
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                        </td>
                                                        : null
                                                }
                                                <td className="align-middle">
                                                    {
                                                        (!(item.configuration.trackOnly.selected.value && items[itemIndex].subItems[subItemIndex].configuration.runner.selected.isSelectable && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("runner")))
                                                            ? <div>
                                                                <Input type="select" name="runner"
                                                                       disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("runner")}
                                                                       value={items[itemIndex].subItems[subItemIndex].configuration.runner.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "runner", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select Runner
                                                                    </option>
                                                                    {
                                                                        (items[itemIndex].subItems[subItemIndex].configuration.runner.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                {
                                                                    items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.isSelectable
                                                                        ? <Input type="select" name="runnerColour"
                                                                                 className="mt-2"
                                                                                 disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("runnerColour")}
                                                                                 value={items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.dropdownValue}
                                                                                 onChange={(e) => this.handleChange(e.target.value, "runnerColour", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                            <option value={""} disabled={true}>Select Colour
                                                                            </option>
                                                                            {
                                                                                (items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions || []).map((o, oIndex) => {
                                                                                    return <option
                                                                                        key={oIndex}
                                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                        : null
                                                                }
                                                            </div>
                                                            : <div className="text-center">N/A</div>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    {
                                                        ((!(item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("control"))) && items[itemIndex].subItems[subItemIndex].configuration.control.selected.isSelectable)
                                                            ? <div>
                                                                <Input type="select" name="control"
                                                                       value={items[itemIndex].subItems[subItemIndex].configuration.control.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "control", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select Control
                                                                    </option>
                                                                    {
                                                                        (items[itemIndex].subItems[subItemIndex].configuration.control.finalOptions || [])
                                                                            .map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>

                                                                {
                                                                    items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.isSelectable
                                                                        ? <div>
                                                                            <Input type="select" name="cordType" className="mt-2"
                                                                                   disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("cordType")}
                                                                                   value={items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.dropdownValue}
                                                                                   onChange={(e) => this.handleChange(e.target.value, "cordType", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                                <option value={""} disabled={true}>Select Type
                                                                                </option>
                                                                                {
                                                                                    (items[itemIndex].subItems[subItemIndex].configuration.cordType.finalOptions || []).map((o, oIndex) => {
                                                                                        return <option
                                                                                            key={oIndex}
                                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                            <Input type="select" name="cordSizeType"
                                                                                   className="mt-2"
                                                                                   disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("cordSizeType")}
                                                                                   value={items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.dropdownValue}
                                                                                   onChange={(e) => this.handleChange(e.target.value, "cordSizeType", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                                <option value={""} disabled={true}>Select cord size
                                                                                </option>
                                                                                {
                                                                                    (items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.finalOptions || []).map((o, oIndex) => {
                                                                                        return <option
                                                                                            key={oIndex}
                                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.isSelectable
                                                                        ? <div>
                                                                            <InputGroup className="mt-2">
                                                                                <Input type="number" name="cordSize"
                                                                                       disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("cordSize")}
                                                                                       invalid={!items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.isValid}
                                                                                       value={items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value}
                                                                                       onChange={(e) => this.handleChange(e.target.value, "cordSize", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}
                                                                                       onFocus={(event) => event.target.select()}
                                                                                       placeholder="cord length"/>
                                                                                <InputGroupAddon addonType="append">
                                                                                    <InputGroupText>mm</InputGroupText>
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                            {
                                                                                items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.isValid
                                                                                    ? null
                                                                                    : <Badge color={"danger"}>
                                                                                        {items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.message}
                                                                                    </Badge>
                                                                            }
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            : <div className="text-center">N/A</div>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    {
                                                        (item.subItems[subItemIndex].configuration.stacking.selected.isSelectable && (!(item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("stacking"))))
                                                            ? <Input type="select" name="stacking"
                                                                     disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("stacking")}
                                                                     value={items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "stacking", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                <option value={""} disabled={true}>Select</option>
                                                                {
                                                                    (item.subItems[subItemIndex].configuration.stacking.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : <div className="text-center">N/A</div>
                                                    }
                                                    {
                                                        (item.subItems[subItemIndex].configuration.overlapArms.selected.isSelectable && (!(item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("overlapArms"))))
                                                            ? <div className="mt-1">
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    onClick={() => this.handleChange(!item.subItems[subItemIndex].configuration.overlapArms.selected.value, "overlapArms", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.subItems[subItemIndex].configuration.overlapArms.selected.value,
                                                                            "fa-square-o": !item.subItems[subItemIndex].configuration.overlapArms.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                {item.subItems[subItemIndex].configuration.overlapArms.attributeLabel}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (item.subItems[subItemIndex].configuration.underlapArms.selected.isSelectable && (!(item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("underlapArms"))))
                                                            ? <div className="mt-1">
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    onClick={() => this.handleChange(!item.subItems[subItemIndex].configuration.underlapArms.selected.value, "underlapArms", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.subItems[subItemIndex].configuration.underlapArms.selected.value,
                                                                            "fa-square-o": !item.subItems[subItemIndex].configuration.underlapArms.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                {item.subItems[subItemIndex].configuration.underlapArms.attributeLabel}
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                {
                                                    subItemIndex === 0
                                                        ? <td className="align-middle text-center"
                                                              rowSpan={item.subItems.length}>
                                                            {
                                                                (
                                                                    item.configuration.trackOnly.selected.value
                                                                    && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("bracket")
                                                                    && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("projection")
                                                                    && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("returnOption")
                                                                ) ? "N/A" : null
                                                            }
                                                            {
                                                                (!(item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("bracket")))
                                                                    ? <Input type="select" name="bracket"
                                                                             disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("bracket")}
                                                                             value={item.configuration.bracket.selected.dropdownValue}
                                                                             onChange={(e) => this.handleChange(e.target.value, "bracket", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                        <option value={""} disabled={true}>Select Bracket Type
                                                                        </option>
                                                                        {
                                                                            (item.configuration.bracket.finalOptions || []).map((o, oIndex) => {
                                                                                return <option
                                                                                    key={oIndex}
                                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                    : null
                                                            }
                                                            {
                                                                (item.configuration.projection.selected.isSelectable && (!(item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("projection"))))
                                                                    ? <Input type="select" name="projection" className="mt-2"
                                                                             disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("projection")}
                                                                             value={item.configuration.projection.selected.dropdownValue}
                                                                             onChange={(e) => this.handleChange(e.target.value, "projection", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                        <option value={""} disabled={true}>Select Projection
                                                                        </option>
                                                                        {
                                                                            (item.configuration.projection.finalOptions || []).map((o, oIndex) => {
                                                                                return <option
                                                                                    key={oIndex}
                                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                    : null
                                                            }
                                                            {
                                                                (item.configuration.returnOption.selected.isSelectable && (!(item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("returnOption"))))
                                                                    ? <div className="mt-2">
                                                                        <Input type="select" name="returnOption"
                                                                               disabled={item.configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes("returnOption")}
                                                                               value={item.configuration.returnOption.selected.dropdownValue}
                                                                               onChange={(e) => this.handleChange(e.target.value, "returnOption", curtainTrackUtil.findItemIndex(product, item.customID), subItemIndex, true)}>
                                                                            <option value={""} disabled={true}>Select Return Option
                                                                            </option>
                                                                            {
                                                                                (item.configuration.returnOption.finalOptions || []).map((o, oIndex) => {
                                                                                    return <option
                                                                                        key={oIndex}
                                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </td>
                                                        : null
                                                }
                                                {
                                                    subItemIndex === 0
                                                        ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                            <div className="text-center">
                                                                <NumberFormat
                                                                    prefix={'$'}
                                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}/>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                                {subItemIndex === 0
                                                    ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                        <div className="text-center">
                                                            <NumberFormat
                                                                prefix={'$'}
                                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}/>
                                                        </div>
                                                    </td>
                                                    : null
                                                }
                                                {subItemIndex === 0
                                                    ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                        <div className="text-center">
                                                            <Button color={"link"}
                                                                    title={"click here to copy item"}
                                                                    onClick={() => this.handleItemAction("clone", curtainTrackUtil.findItemIndex(product, item.customID))}>
                                                                <i className="fa fa-clone fa-lg"
                                                                   aria-hidden="true"/>
                                                            </Button>
                                                            <span className={"text-muted"}>|</span>
                                                            <Button color={"link"}
                                                                    title={"click here to delete"}
                                                                    onClick={() => this.handleItemAction("delete", curtainTrackUtil.findItemIndex(product, item.customID))}>
                                                                <i className="fa fa-trash-o fa-lg text-danger"
                                                                   aria-hidden="true"/>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                    : null
                                                }
                                                {subItemIndex === 0 && !currentUser.isExternalUser
                                                    ? <td className="align-middle" rowSpan={item.subItems.length}>
                                                        <div className="text-center">

                                                            <Button color={"link"}
                                                                    title={"click here to open BOM"}
                                                                    onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                BOM
                                                            </Button>
                                                        </div>
                                                    </td>
                                                    : null
                                                }

                                            </tr>
                                        });
                                    }
                                )
                            }
                            {
                                items.length > 0
                                    ? <tr>
                                        <td className="align-middle" colSpan={17}>Total price</td>
                                        <td className="align-middle">
                                            <div className="text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.price, discountByProductCode)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </div>
                                        </td>
                                        <td className="align-middle" colSpan={2}/>
                                    </tr>
                                    : null
                            }
                            </tbody>
                        </Table>
                        {
                            workingBOMModal.isOpen
                                ? <CurtainTrackCustomBOMModal
                                    order={order}
                                    itemIndex={workingBOMModal.itemIndex}
                                    product={product}
                                    isOpen={workingBOMModal.isOpen}
                                    toggle={this.toggleBOMModal}
                                    convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                />
                                : null
                        }
                        {
                            isOpenConsolidatedBOMModal
                                ? <CurtainTrackCustomConsolidatedBOMModal
                                    order={order}
                                    product={product}
                                    isOpen={isOpenConsolidatedBOMModal}
                                    toggle={this.toggleConsolidatedBOMModal}/>
                                : null
                        }
                    </div>
                    : null
                }
                <Button
                    color={"primary"}
                    className={"mb-2 ml-1"}
                    onClick={() => this.handleItemAction("new", items.length)}>
                    <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                    Add new item
                </Button>
            </div>
        );
    }
}