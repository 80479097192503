import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody, CardFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';

import { findIndex,handleErrorMessage } from '../../../services/CommonService';
import WarehouseLocationService from '../../../services/WINOInventory/WarehouseLocationService';

export default class WINOWarehouseLocationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            warehouseLocationData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        name: "",
                        code: "",
                        warehouseName: "",
                        warehouseLocationTypeName:"",
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',

        };

        this.getWarehouseLocations = this.getWarehouseLocations.bind(this);

    }

    componentDidMount() {
        this.getWarehouseLocations(this.state.warehouseLocationData);
    }

    getWarehouseLocations(warehouseLocationData) {
        this.setState({ loading: true });
        let req = { ...this.state.warehouseLocationData.request };
        WarehouseLocationService.listWarehouseLocation(req).then(response => {
            warehouseLocationData.response = response.data;
            this.setState({ warehouseLocationData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.name.toLowerCase().includes(searchText);

            if (!flag && item.code) {
                flag = item.code.toLowerCase().includes(searchText)
            }

            if (!flag && item.warehouse.name) {
                flag = item.warehouse.name.toLowerCase().includes(searchText)
            }

            if (!flag && item.warehouseLocationType.name) {
                flag = item.warehouseLocationType.name.toLowerCase().includes(searchText)
            }


        }
        return flag;
    }

    handleChange(change, key) {
        let { warehouseLocationData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (warehouseLocationData.request.sortRequest.key === change) {
                    warehouseLocationData.request.sortRequest.direction = !warehouseLocationData.request.sortRequest.direction;
                } else {
                    warehouseLocationData.request.sortRequest.key = change;
                    warehouseLocationData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getWarehouseLocations(warehouseLocationData);
                }
                break;
            case "pageSize":
                warehouseLocationData.request.pageRequest[key] = parseInt(change);
                warehouseLocationData.request.pageRequest.currentPage = 1;
                this.getWarehouseLocations(warehouseLocationData);
                break;
            case "currentPage":
                warehouseLocationData.request.pageRequest[key] = change;
                this.getWarehouseLocations(warehouseLocationData);
                break;


            default:
                warehouseLocationData.request.filterRequest[key] = change;
                warehouseLocationData.request.pageRequest.currentPage = 1;
                this.getWarehouseLocations(warehouseLocationData);
                this.setState({ warehouseLocationData });
        }

    }

    deleteWarehouseLocation(warehouseLocation) {
        let { warehouseLocationData } = this.state;
        let { records } = warehouseLocationData.response;
        let index = findIndex(records, 'id', warehouseLocation.id);
        records[index].isDeleting = true;
        this.setState({ warehouseLocationData });
        WarehouseLocationService.deleteWarehouseLocation(warehouseLocation.id).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("Warehouse location deleted succesfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                records[index].isDeleting = false;
                this.setState({ warehouseLocationData });
                this.getWarehouseLocations(warehouseLocationData);
            }
        }).catch(error => {
            records[index].isDeleting = false;
            this.setState({ warehouseLocationData });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getWarehouseLocationHeading({ filterRequest }) {
        let warehouseLocationHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"name"}
                        value={filterRequest.name || ''}
                        onChange={(e) => this.handleChange(e.target.value, "name")} />
                </div>

            },
            {
                key: "code",
                label: "Code",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"code"}
                        value={filterRequest.code || ''}
                        onChange={(e) => this.handleChange(e.target.value, "code")} />
                </div>

            },
            {
                key: "warehouseName",
                label: "WareHouse",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"warehouseName"}
                        value={filterRequest.warehouseName || ''}
                        onChange={(e) => this.handleChange(e.target.value, "warehouseName")} />
                </div>

            },
            {
                key: "warehouseLocationTypeName",
                label: "Warehouse Location Type",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"warehouseLocationTypeName"}
                        value={filterRequest.warehouseLocationTypeName || ''}
                        onChange={(e) => this.handleChange(e.target.value, "warehouseLocationTypeName")} />
                </div>

            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "250px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                    to={{
                        pathname: '/keyway-inventory/warehouse-location/manage/one', state: { warehouseLocationID: "" }
                    }}><i className="fa fa-plus" />&nbsp;
                            Add </Link></div>

            }];

        return warehouseLocationHeading;
    }


    render() {

        let { warehouseLocationData, loading, searchText } = this.state;
        let { pageRequest, sortRequest } = warehouseLocationData.request;
        let warehouseLocationHeading = this.getWarehouseLocationHeading(warehouseLocationData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Warehouse Location</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Warehouse Location</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {warehouseLocationData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(warehouseLocationHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(warehouseLocationHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(warehouseLocationData.response.records || []).filter((warehouseLocation) => this.searchFunction(warehouseLocation, searchText)).map((warehouseLocation, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>{warehouseLocation.name}</td>
                                            <td>{warehouseLocation.code}</td>
                                            <td>{warehouseLocation.warehouse.name}</td>
                                            <td>{warehouseLocation.warehouseLocationType.name}</td>
                                            <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                to={"/keyway-inventory/warehouse-location/manage/one?" + queryString.stringify({ warehouseLocationID: warehouseLocation.id })}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                                Edit</Link>&nbsp;
                                                <Button className="btn btn-sm btn-danger" title="Click here to make warehouse location inactive"
                                                    onClick={this.deleteWarehouseLocation.bind(this, warehouseLocation)}
                                                    disabled={warehouseLocation.isDeleting}>
                                                    {warehouseLocation.isDeleting ?
                                                        <Spinner size="sm"
                                                            className={"mr-2"}
                                                            style={{ color: "white" }} /> :
                                                        <i className="fa fa-trash mr-2" aria-hidden="true"></i>} Delete
                                                </Button>

                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={warehouseLocationData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
