import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Table
} from "reactstrap";
import classnames from "classnames";
import {getLocalDateStringFromUtcDateString,getDateString,handleErrorMessage} from "../../../services/CommonService";
import Pagination from "react-js-pagination";
import StockWarehouseLocationService from "../../../services/WINOInventory/StockWarehouseLocationService";

export default class StockTransferHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockTransferHistoryData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 10
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        toWarehouseLocation: '',
                        fromWarehouseLocation: '',
                        description: '',
                        referenceNumber: '',
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',

        };
    }

    componentDidMount() {
        this.getStockTransferHistory(this.state.stockTransferHistoryData);
    }

    getStockTransferHistory(stockTransferHistoryData) {
        this.setState({loading: true});
        StockWarehouseLocationService.getStockTransferHistory(stockTransferHistoryData.request).then(response => {
            stockTransferHistoryData.response = response.data;
            this.setState({stockTransferHistoryData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.name) {
                flag = item.name.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {stockTransferHistoryData: stockTransferHistoryData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (stockTransferHistoryData.request.sortRequest.key === change) {
                    stockTransferHistoryData.request.sortRequest.direction = !stockTransferHistoryData.request.sortRequest.direction;
                } else {
                    stockTransferHistoryData.request.sortRequest.key = change;
                    stockTransferHistoryData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockTransferHistory(stockTransferHistoryData);
                }
                break;
            case "pageSize":
                stockTransferHistoryData.request.pageRequest[key] = parseInt(change);
                stockTransferHistoryData.request.pageRequest.currentPage = 1;
                this.getStockTransferHistory(stockTransferHistoryData);
                break;
            case "currentPage":
                stockTransferHistoryData.request.pageRequest[key] = change;
                this.getStockTransferHistory(stockTransferHistoryData);
                break;


            default:
                stockTransferHistoryData.request.filterRequest[key] = change;
                stockTransferHistoryData.request.pageRequest.currentPage = 1;
                this.getStockTransferHistory(stockTransferHistoryData);
                this.setState({stockTransferHistoryData: stockTransferHistoryData});
        }

    }

    getStockTransferHistoryHeading({filterRequest}) {
        let stockTransferHistoryHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",

            },
            {
                key: "from",
                label: "From Location",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                valueClassName: "",
                labelClassName: "align-middle",

            },
            {
                key: "to",
                label: "To Location",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                valueClassName: "",
                labelClassName: "align-middle",

            },
            {
                key: "refNo",
                label: "Ref.",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div><Input type="text" id="refNo" name="refNo"
                                        value={filterRequest.referenceNumber}
                                        onChange={(e) => this.handleChange(e.target.value, "referenceNumber")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div><Input type="text" id="description" name="description"
                                        value={filterRequest.description}
                                        onChange={(e) => this.handleChange(e.target.value, "description")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                maxWidth: 100,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div><Input type="text" id="status" name="status"
                                        value={filterRequest.stockWarehouseLocationTransferStatus}
                                        onChange={(e) => this.handleChange(e.target.value, "stockWarehouseLocationTransferStatus")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "createdDate",
                label: "Transferred Date",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle",
            },
            {
                key: "createdBy",
                label: "Transferred By",
                type: "text",
                colSpan: 1,
                minWidth: 150,
            }
        ];

        return stockTransferHistoryHeading;
    }

    render() {

        let {stockTransferHistoryData, loading, searchText} = this.state;
        let {pageRequest, sortRequest} = stockTransferHistoryData.request;
        let stockTransferHistoryHeading = this.getStockTransferHistoryHeading(stockTransferHistoryData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Transfer History</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Stock Transfer History </h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockTransferHistoryData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>

                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(stockTransferHistoryHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(stockTransferHistoryHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(stockTransferHistoryData.response.records || []).filter((stockTransferHistoryType) => this.searchFunction(stockTransferHistoryType, searchText)).map((stockTransferHistoryType, index) =>

                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td>{stockTransferHistoryType.fromWarehouseLocation}</td>
                                        <td>{stockTransferHistoryType.toWarehouseLocation}</td>
                                        <td className={"text-center"}>{stockTransferHistoryType.referenceNumber}</td>
                                        <td>{stockTransferHistoryType.description}</td>
                                        <td className={"text-center"}><Badge color="success"
                                                                             pill>{stockTransferHistoryType.stockWarehouseLocationTransferStatus}</Badge>
                                        </td>
                                        <td className={"text-center"}>{getDateString(stockTransferHistoryType.createdDate, 'DD MMM YYYY')}</td>
                                        <td>{stockTransferHistoryType.createdBy}</td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockTransferHistoryData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}