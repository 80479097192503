import React, {Component} from "react";
import ReworkSummaryPage from './ReworkSummaryPage';
import ReworkUnitPage from './ReworkUnitPage';
import {Link} from "react-router-dom";
import {
    Row, Breadcrumb, BreadcrumbItem, Card, CardHeader, Nav, NavItem, Col,
    NavLink, Button, CardBody,
    TabContent
} from "reactstrap";
import ReworkOrderReasonWiseDataPage from "./ReworkOrderReasonWiseDataPage";
import ReworkOrderPage from "./ReworkOrderPage";
import classnames from "classnames";
import queryString from 'query-string';

const reworkAllTabs = [
    {
        label: "Dashboard",
        key: "dashboard"
    },
    {
        label: "Rework Orders",
        key: "reworkOrders"
    }
];
export default class ReworkPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "dashboard"
        };
    }

    componentDidMount() {
        let { activeTab } = this.state;
        if (this.props.location.search) {
            let searchParam = queryString.parse(this.props.location.search);
            activeTab = searchParam.activeTab;
            this.setState({ activeTab });
        }
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("activeTab", activeTab);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("activeTab", tab);
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({path: newurl}, '', newurl);
            }
        }
    }

    render() {
        let {activeTab} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Rework</BreadcrumbItem>
                </Breadcrumb>
                <Row>

                    <Card>
                        <CardHeader>
                            <Nav tabs card>
                                {reworkAllTabs.map((tab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink
                                            className={classnames({active: activeTab === tab.key})}
                                            onClick={() => {
                                                this.toggleTab(tab.key);
                                            }}>
                                            <Button style={{textDecoration: "none"}}
                                                    size={"sm"}
                                                    color={"link"}>{tab.label}&nbsp;
                                            </Button>
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </CardHeader>

                        <CardBody>
                            <TabContent activeTab={activeTab}>
                                {(activeTab === "dashboard") ?
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <ReworkUnitPage/>
                                            <ReworkOrderReasonWiseDataPage/>
                                            <ReworkSummaryPage/>
                                        </Col>
                                    </Row> : null}
                                {(activeTab === "reworkOrders") ?
                                    <ReworkOrderPage/> : null}
                            </TabContent>
                        </CardBody>
                    </Card>
                </Row>
            </div>
        )
    }
}