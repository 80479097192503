import React, {Component} from 'react';
import {concatenateStrings} from "../../../../../../services/CommonService";

export let curtainTrackCustomLabelStore = [
    {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "model",
        label: "Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.operation, data.productLabel.remote ? "Remote" : "",data.productLabel.charger ? "Charger" : "", );
        }
    },
    {
        key: "bendType",
        label: "Bend",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.bendType, data.productLabel.bendShape);
        }
    }
    ,{
        key: "trackType",
        label: "Track Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.trackType, data.productLabel.trackColour, data.productLabel.trackOnly ? 'Track Only' : '');
        }
    }
    ,
    {
        key: "control",
        label: "Control/Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.control, data.productLabel.cordType, data.productLabel.cordSizeType, data.productLabel.cordSize);

        }
    },{
        key: "bracket",
        label: "Bracket",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(", ", data.productLabel.bracket, data.productLabel.projection, data.productLabel.returnOption);

        }
    },{
        key: "runner",
        label: "Runner",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.runner, data.productLabel.runnerColour);

        }
    },
    {
        key: "stacking",
        label: "Stacking",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return  data.productLabel[this.key];
        }
    }

];