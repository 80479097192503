import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Row, Col, Input, FormText } from 'reactstrap';
import classnames from 'classnames';

function FormError(props) {
    let item = props.item;
    switch (props.attribute) {
        case "stateID":
            if (!item.stateID) {
                return (<FormText color="danger">Please select state</FormText>)
            }
            break;
        case "postcode":
            if (!item.postcode) {
                return (<FormText color="danger">Please enter postcode</FormText>)
            }
            break;
        case "city":
            if (!item.city) {
                return (<FormText color="danger">Please enter city</FormText>)
            }
            break;
        case "area_code":
            if (!item.area_code) {
                return (<FormText color="danger">Please enter Area Code</FormText>)
            }
            break;
    }
    return null;
}

export default class CreateAddressStandardModal extends Component {
    constructor(props) {
        super(props);

    }

    validateAndSubmit(form) {
        console.log('form', form);
        let flag = true;
        if (!form.postcode) {
            flag = false;
        }
        if (!form.stateID) {
            flag = false;
        }
        if (!form.city) {
            flag = false;
        }
        if (!form.area_code) {
            flag = false;
        }
        if (flag) {
            this.props.handleSubmit(form);
        }
        ///console.log('form', form);
    }


    render() {
        let { isOpen, toggle, loading, handleChange, states, form } = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Create
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div>Postcode*
                            <Input type={"text"}
                                placeholder={"Postcode"}
                                name={"postcode"}
                                value={form.postcode}
                                onChange={(e) => handleChange(e.target.value, "postcode")} />
                            <FormError attribute={"postcode"} item={form} />
                        </div>

                        <div className={"mt-2"}>
                            State*
                            <Input type={"select"}
                                   placeholder={"State"}
                                name={"stateID"}
                                value={form.stateID}
                                onChange={(e) => handleChange(e.target.value, "stateID")}>
                                <option value={""}>Select</option>
                                {states.map((option, index) =>
                                    <option key={index} value={option.stateID}>{option.name}</option>
                                )}
                            </Input>
                            <FormError attribute={"stateID"} item={form} />
                        </div>

                        <div className={"mt-2"}>
                            City*
                            <Input type={"text"}
                                placeholder={"City"}
                                name={"city"}
                                value={form.city}
                                onChange={(e) => handleChange(e.target.value, "city")} />
                            <FormError attribute={"city"} item={form} />
                        </div>

                        <div className={"mt-2"}>
                            Area Code*
                            <Input type={"text"}
                                placeholder={"Area code"}
                                name={"area_code"}
                                value={form.area_code}
                                onChange={(e) => handleChange(e.target.value, "area_code")} />
                            <FormError attribute={"area_code"} item={form} />
                        </div>

                        <div className={"mt-3"}>
                            <div>
                                <span>
                                    <a href={"javascript:void(0)"}
                                        onClick={() => handleChange(!form.isMetro, "isMetro")}>
                                        <i className={classnames("fa", "fa-lg", {
                                            "fa-check-square-o": form.isMetro,
                                            "fa-square-o": !form.isMetro,
                                        }
                                        )} />
                                    </a><span className={"ml-2"}>Metro</span>
                                </span>
                                <span className={"ml-4"}>
                                    <a href={"javascript:void(0)"}
                                        onClick={() => handleChange(!form.isMetro, "isMetro")}>
                                        <i className={classnames("fa", "fa-lg", {
                                            "fa-check-square-o": !form.isMetro,
                                            "fa-square-o": form.isMetro,
                                        }
                                        )} />
                                    </a><span className={"ml-2"}>Non-Metro</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} onClick={() => this.validateAndSubmit(form)}
                            disabled={loading}>{loading ? "Saving" : "Save"}</Button>&nbsp;&nbsp;
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}