import React, {Component} from 'react';
import {Nav, NavItem, NavLink, Card, CardHeader, CardBody, CardFooter, Button, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import CreatedDateBetweenFilter from "./CreatedDateBetweenFilter";
import DueDateBetweenFilter from "./DueDateBetweenFilter";
import CustomerFilter from "./CustomerFilter";
import OrderNoFilter from "./OrderNoFilter";
import KeywordFilter from "./KeywordFilter";
import RepFilter from "./RepFilter";
import SalesOrderQuoteStatusFilter from "./SalesOrderQuoteStatusFilter";
import SalesOrderStatusFilter from "./SalesOrderStatusFilter";
import BranchFilter from "./BranchFilter";
import AppliedFilters from "./AppliedFilters";
import {findIndex} from '../../services/CommonService';
import AssignedUserFilter from "./AssignedUserFilter";
import IsTaskClosedFilter from "./IsTaskClosedFilter";

export default class Filter extends Component {


    constructor(props) {
        super(props);

        let allFilters = [
            {
                name: "CreatedDateBetween",
                displayName: "Created Date Between",
                value: "",
                defaultValue: "",
                displayValue: "",
                valid: false,
                sortIndex: 10
            },
            {
                name: "DueDateBetween",
                displayName: "Due Date Between",
                value: "",
                defaultValue: "",
                displayValue: "",
                valid: false,
                sortIndex: 15
            },
            {
                name: "Customer",
                displayName: "Customer",
                value: "",
                defaultValue: "",
                displayValue: "",
                valid: false,
                sortIndex: 20
            }, {
                name: "OrderNo",
                displayName: "Order No",
                value: "",
                defaultValue: "",
                displayValue: "",
                valid: false,
                sortIndex: 30
            }, {
                name: "Keyword",
                displayName: "Search by keyword",
                value: "",
                defaultValue: "",
                displayValue: "",
                valid: false,
                sortIndex: 40
            }, {
                name: "SalesOrderQuoteStatus",
                displayName: "Quote Status",
                value: "0",
                defaultValue: "",
                displayValue: "Active",
                valid: false,
                sortIndex: 50
            }, {
                name: "SalesOrderStatus",
                displayName: "Order Status",
                value: "0",
                defaultValue: "",
                displayValue: "Active",
                valid: false,
                sortIndex: 50
            }, {
                name: "Rep",
                displayName: "Rep",
                value: "",
                defaultValue: "",
                displayValue: "All",
                valid: false,
                sortIndex: 60
            }, {
                name: "Branch",
                displayName: "Branch",
                value: "",
                defaultValue: "",
                displayValue: "All",
                valid: false,
                sortIndex: 70
            },
            {
                name: "AssignedUser",
                displayName: "Assigned User",
                value: "",
                defaultValue: "",
                displayValue: "",
                valid: false,
                sortIndex: 25
            },
            {
                name: "IsTaskClosed",
                displayName: "Status",
                value: "false",
                defaultValue: "",
                displayValue: "Pending",
                valid: false,
                sortIndex: 25
            }];


        if (this.props.filters) {
            for (let i in this.props.filters) {
                let index = findIndex(allFilters, "name", this.props.filters[i].name);
                if (index > -1) {
                    allFilters[index].displayName = this.props.filters[i].displayName;
                    if (this.props.filters[i].value) {
                        allFilters[index].value = this.props.filters[i].value;
                    } else {
                        allFilters[index].value = this.props.filters[i].defaultValue;
                    }
                    allFilters[index].defaultValue = this.props.filters[i].defaultValue;
                    allFilters[index].displayValue = this.props.filters[i].displayValue;
                    allFilters[index].valid = this.props.filters[i].valid;
                    allFilters[index].sortIndex = this.props.filters[i].sortIndex;
                }
            }
        }
        let filters = allFilters.filter((filter) => filter.valid);

        this.state = {loading: true, activeFilterTabIndex: 0, filters: filters};
        this.toggleFilterTab = this.toggleFilterTab.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);


    }


    toggleFilterTab(item) {
        this.setState({activeFilterTabIndex: item});
    }

    updateFilters(filter, applyFilterFlag) {
        let {filters} = this.state;
        let index = findIndex(filters, "name", filter.name);
        if (index > -1) {
            filters[index].value = filter.value;
            if (filters[index].displayValue) {
                filters[index].displayValue = filter.displayValue;
            } else {
                filters[index].displayValue = filter.value;
            }
        }
        this.setState({filters});
        this.props.onChange(filters, applyFilterFlag);

    }

    clearFilters() {
        let {filters} = this.state;

        for (let i in filters) {
            filters[i].value = filters[i].defaultValue;
            filters[i].displayValue = "";
        }

        this.setState({filters: filters}, () => {
            this.props.onChange(filters, true);
        });
    }


    render() {

        let {filters, activeFilterTabIndex} = this.state;

        let activeFilterTab = {};
        if (filters) {
            activeFilterTab = filters[activeFilterTabIndex];
        }

        return (
            <Card className={"mb-2"}>
                <CardHeader>
                    <Nav className={"flex-column flex-sm-row"} tabs card>
                        {filters.sort(function (f1, f2) {
                            return f1.sortIndex - f2.sortIndex;
                        }).map((filter, index) =>
                            <NavItem key={index} className={"flex-sm-fill text-sm-center hoverableItem"}>
                                <NavLink
                                    className={classnames({active: this.state.activeFilterTabIndex === index})}
                                    onClick={() => {
                                        this.toggleFilterTab(index);
                                    }}>
                                    {filter.displayName}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </CardHeader>
                <CardBody>
                    <div>

                        <div>
                            {(() => {
                                if (activeFilterTab && activeFilterTab.name === "CreatedDateBetween") {
                                    return (
                                        <CreatedDateBetweenFilter filter={activeFilterTab}
                                                                  onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "DueDateBetween") {
                                    return (
                                        <DueDateBetweenFilter filter={activeFilterTab}
                                                              onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "Customer") {
                                    return (<CustomerFilter filter={activeFilterTab}
                                                            onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "OrderNo") {
                                    return (<OrderNoFilter filter={activeFilterTab}
                                                           onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "Keyword") {
                                    return (<KeywordFilter filter={activeFilterTab}
                                                           onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "SalesOrderQuoteStatus") {
                                    return (<SalesOrderQuoteStatusFilter filter={activeFilterTab}
                                                                         onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "SalesOrderStatus") {
                                    return (<SalesOrderStatusFilter filter={activeFilterTab}
                                                                         onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "Rep") {
                                    return (<RepFilter filter={activeFilterTab}
                                                       onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "Branch") {
                                    return (<BranchFilter filter={activeFilterTab}
                                                          onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "AssignedUser") {
                                    return (<AssignedUserFilter filter={activeFilterTab}
                                                                onChange={this.updateFilters}/>)
                                }
                                if (activeFilterTab && activeFilterTab.name === "IsTaskClosed") {
                                    return (<IsTaskClosedFilter filter={activeFilterTab}
                                                                onChange={this.updateFilters}/>)
                                }
                            })()}
                        </div>


                    </div>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                            <div className={"mt-2"}>
                                <AppliedFilters filters={filters} onChange={this.updateFilters}/>
                            </div>
                        </Col>
                        <Col  xl={4} lg={6} md={6} sm={12} xs={12}>
                            <div className={"text-right mt-2"}>
                                <Button color={"primary"} size={"sm"} onClick={() => this.props.applyFilters(false)}
                                        disabled={this.props.loading}>
                                    {this.props.loading ? <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                        <i className="fa fa-search" aria-hidden="true"/>}&nbsp; &nbsp;Apply filters
                                </Button>
                                &nbsp;&nbsp;
                                <Button color={"secondary"} size={"sm"} onClick={this.clearFilters}
                                        disabled={this.props.loading}>
                                    {this.props.loading ? <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                        <i className="fa fa-times" aria-hidden="true"/>}&nbsp; &nbsp;Clear filters
                                </Button>
                            </div>

                        </Col>
                    </Row>


                </CardFooter>
            </Card>
        );
    }
}
