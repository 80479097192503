import React, {Component} from 'react';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    Button
} from 'reactstrap';
import PropTypes from 'prop-types';
export default class InputSearchComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { name, value, placeholder } = this.props;
        return (
            <InputGroup>
                <Input
                    style={{
                        borderRight:"none !important"
                    }}
                    type="text"                    
                    name={name}
                    value={value}
                    onChange={(e) => this.props.handleChange(e.target.value, e.target.name)}
                    placeholder={placeholder} />
                <InputGroupAddon addonType="append" style={{
                    zIndex: 0,
                    border: "1px solid #ced4da",
                    height:"38px"
                }}>
                    <Button
                        color={"white"}
                        outline={true}
                        onClick={() => this.props.handleChange('', name)}>
                        <i className="fa fa-thin fa-times text-muted" aria-hidden="true" />
                    </Button>
                    </InputGroupAddon>
            </InputGroup>
        );
    }

}

InputSearchComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired
};