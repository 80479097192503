import React, {Component} from 'react';
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

export default function ApplySelectionToMultipleItems(props) {

    if (props.isDisabled) {
        return <div />;
    }
    return (
        <UncontrolledDropdown>
            <DropdownToggle color={"link"} disabled={
                props.disabled}>
                <i className="fa fa-ellipsis-v fa-lg fa-fw" aria-hidden="true"/>
            </DropdownToggle>


            <DropdownMenu>
                {
                    props.includeItem
                        ? <DropdownItem header>Apply this selection on items(including sub-items)?</DropdownItem>
                        : null
                }
                {
                    props.includeItem
                        ? <DropdownItem
                            onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemIndex, props.subItemIndex, "item-above")}>
                            <i className="fa fa-long-arrow-up fa-fw mr-2" aria-hidden="true"/>
                            Above all items
                        </DropdownItem>
                        : null
                }
                {
                    props.includeItem
                        ? <DropdownItem
                            onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemIndex, props.subItemIndex, "item-below")}>
                            <i className="fa fa-long-arrow-down fa-fw mr-2" aria-hidden="true"/>
                            Below all items
                        </DropdownItem>
                        : null
                }
                {
                    props.includeItem
                        ? <DropdownItem
                            onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemIndex, props.subItemIndex, "item-all")}>
                            <i className="fa fa-arrows-v fa-fw mr-2" aria-hidden="true"/>
                            All items
                        </DropdownItem>
                        : null
                }
                {
                    props.includeSubItem
                        ? <DropdownItem header>Apply this selection on sub-items only?</DropdownItem>
                        : null
                }
                {
                    props.includeSubItem
                        ? <DropdownItem
                            onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemIndex, props.subItemIndex, "subItem-above")}>
                            <i className="fa fa-long-arrow-up fa-fw mr-2" aria-hidden="true"/>
                            Above all items
                        </DropdownItem>
                        : null
                }
                {
                    props.includeSubItem
                        ? <DropdownItem
                            onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemIndex, props.subItemIndex, "subItem-below")}>
                            <i className="fa fa-long-arrow-down fa-fw mr-2" aria-hidden="true"/>
                            Below all items
                        </DropdownItem>
                        : null
                }
                {
                    props.includeSubItem
                        ? <DropdownItem
                            onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemIndex, props.subItemIndex, "subItem-all")}>
                            <i className="fa fa-arrows-v fa-fw mr-2" aria-hidden="true"/>
                            All items
                        </DropdownItem>
                        : null
                }


            </DropdownMenu>
        </UncontrolledDropdown>
    );

}