import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Col,
    Row,
    Table,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import { getDateString,handleErrorMessage } from '../../../services/CommonService';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchWINOCreditor from '../../../components/search/SearchCreditorAcccount';
import cloneDeep from 'lodash/cloneDeep';
import PurchaseInvoiceService from '../../../services/WINOInventory/PurchaseInvoiceService';

const maxDate = moment().add(2, 'years').toDate();
const minDate = moment().subtract(20, 'years').toDate();

export default class PurchaseInvoicePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            purchaseInvoiceData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        invStartDate: null,
                        invEndDate: null,
                        description: '',
                        supplierID: 0,
                        currency: '',
                        accountID: '',
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
        };

        this.getPurchaseInvoices = this.getPurchaseInvoices.bind(this);
    }

    componentDidMount() {
        this.getPurchaseInvoices(this.state.purchaseInvoiceData);
    }

    getPurchaseInvoices(purchaseInvoiceData) {
        this.setState({ loading: true });
        let request = cloneDeep(purchaseInvoiceData.request);
        if (request.filterRequest.invStartDate) {
            request.filterRequest.invStartDate = getDateString(request.filterRequest.invStartDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.invEndDate) {
            request.filterRequest.invEndDate = getDateString(request.filterRequest.invEndDate, "DD/MM/YYYY");
        }

        PurchaseInvoiceService.listPurchaseInvoice(request).then(response => {
            purchaseInvoiceData.response = response.data;
            this.setState({ purchaseInvoiceData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.id) {
                flag = item.id.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.invDate) {
                flag = item.invDate.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.supplier) {
                flag = item.supplier.company.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.currency) {
                flag = item.currency.toString().toLowerCase().includes(searchText)
            }

        }
        return flag;
    }

    handleChange(change, key) {
        let { purchaseInvoiceData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (purchaseInvoiceData.request.sortRequest.key === change) {
                    purchaseInvoiceData.request.sortRequest.direction = !purchaseInvoiceData.request.sortRequest.direction;
                } else {
                    purchaseInvoiceData.request.sortRequest.key = change;
                    purchaseInvoiceData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getPurchaseInvoices(purchaseInvoiceData);
                }
                break;
            case "pageSize":
                purchaseInvoiceData.request.pageRequest[key] = parseInt(change);
                purchaseInvoiceData.request.pageRequest.currentPage = 1;
                this.getPurchaseInvoices(purchaseInvoiceData);
                break;
            case "currentPage":
                purchaseInvoiceData.request.pageRequest[key] = change;
                this.getPurchaseInvoices(purchaseInvoiceData);
                break;


            default:
                purchaseInvoiceData.request.filterRequest[key] = change;
                purchaseInvoiceData.request.pageRequest.currentPage = 1;
                this.getPurchaseInvoices(purchaseInvoiceData);
                this.setState({ purchaseInvoiceData });
        }

    }

    getPurchaseInvoiceHeading(filterRequest) {
        let purchaseInvoiceHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
            {
                key: "invNumber",
                label: "Invoice No.",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"orderNumber"}
                        value={filterRequest.invNumber || ''}
                        onChange={(e) => this.handleChange(e.target.value, "invNumber")} />
                </div>
            },
            {
                key: "invDate",
                label: "Invoice Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>

                    <div className={"text-right mb-1"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.invStartDate}
                            onChange={date => this.handleChange(date, "invStartDate")}
                            selectsStart
                            startDate={filterRequest.invStartDate}
                            endDate={filterRequest.invEndDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="Start date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                    <div className={"text-right"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.invEndDate}
                            onChange={date => this.handleChange(date, "invEndDate")}
                            selectsEnd
                            startDate={filterRequest.invStartDate}
                            endDate={filterRequest.invEndDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="End date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                </div>
            },
            {
                key: "supplier",
                label: "Supplier",
                type: "text",
                colSpan: 1,
                minWidth: 250,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchWINOCreditor
                        handleAccountChange={(selectedSupplier) => this.handleChange(selectedSupplier ? selectedSupplier.accountID : '', 'accountID')}
                        selectedAccountID={filterRequest.accountID}
                        defaultAccountID={filterRequest.accountID} />

                </div>
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: "150px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"description"}
                        value={filterRequest.description || ''}
                        onChange={(e) => this.handleChange(e.target.value, "description")} />
                </div>
            },
            {
                key: "currency",
                label: "Currency",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"currency"}
                        value={filterRequest.currency || ''}
                        onChange={(e) => this.handleChange(e.target.value, "currency")} />
                </div>
            }];

        return purchaseInvoiceHeading;
    }


    render() {

        let { purchaseInvoiceData, loading, searchText } = this.state;
        let { pageRequest, sortRequest } = purchaseInvoiceData.request;
        let purchaseInvoiceHeading = this.getPurchaseInvoiceHeading(purchaseInvoiceData.request);
        return (
            <div>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading ? <p>Loading...</p> :
                                <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {purchaseInvoiceData.response.totalRecords}
                                    {' '}entries</p>
                            }
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <InputGroup className={"mb-2"}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-search"
                                            aria-hidden="true" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"text"}
                                    name={"searchText"}
                                    placeholder={"Search..."}
                                    value={searchText}
                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                />

                            </InputGroup>
                        </div>

                    </Col>


                </Row>
                <div>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                            <tr>
                                {(purchaseInvoiceHeading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(purchaseInvoiceHeading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>

                        </thead>

                        <tbody>
                            {(purchaseInvoiceData.response.records || []).filter((purchaseInvoice) => this.searchFunction(purchaseInvoice, searchText)).map((purchaseInvoice, index) =>

                                <tr key={index}>
                                    <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                    <td>
                                        <Link className="btn btn-sm btn-primary" title={"Click here to view invoice in detail"}
                                            to={"/keyway-inventory/purchase-invoice/manage/one?" + queryString.stringify({ purchaseInvoiceID: purchaseInvoice.id })}>{purchaseInvoice.id}</Link></td>
                                    <td>{purchaseInvoice.invDate}</td>
                                    <td><small>{purchaseInvoice.supplier.company}</small></td>
                                    <td>{purchaseInvoice.description}</td>
                                    <td>{purchaseInvoice.currency}</td>
                                </tr>
                            )}

                        </tbody>
                    </Table>
                </div>
                <br />
                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{ maxWidth: 200 }}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>


                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={purchaseInvoiceData.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>

            </div>
        );
    }
}
