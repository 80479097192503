import React, {Component} from 'react';
import {Link} from "react-router-dom";

import classnames from 'classnames';
import NumberFormat from "react-number-format";
import InfiniteScroll from 'react-infinite-scroller';
import {getLocalDateStringFromUtcDateString, convertUtcDateToLocalDate, getDateString} from '../../../services/CommonService';
import moment from 'moment';

import {
    Table, Card, CardBody, Badge, Button
} from 'reactstrap';


export default class TasksTable extends Component {

    getColValue(record, modal) {

        switch (modal.type) {

            case "link":
                if (modal.key === "id") {
                    return <Link
                        className={classnames("btn", {
                            "btn-success": record.isClosed,
                            "btn-primary": !record.isClosed,
                        })}
                        to={{
                            pathname: "/crm/manageTask/" + record[modal.key],
                        }}><i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"/>&nbsp;Select&nbsp;&nbsp;
                    </Link>
                }
                if (modal.key === "accountID") {
                    if (record[modal.key]) {
                        return <span>
                            <Link
                                className="btn btn-sm btn-primary"
                                style={{color: "white"}}
                                title={"Click here to see account details"}
                                to={"/customer/#"+ record[modal.key]}
                                >{record[modal.key]}</Link>
                            <div>
                                <small>{record["company"]}</small>
                            </div>

                        </span>
                    }
                }
                break;
            case "date":


                return <span>
                     <div>{getDateString(record[modal.key], 'DD MMM YYYY hh:mm A')}</div>
                     <Badge pill>{moment(record[modal.key]).fromNow()}</Badge>
                </span>;
            case "boolean":
                if (record[modal.key] === 1) {
                    return "Yes";
                }
                if (record[modal.key] === 0) {
                    return "No";
                }
                if (record[modal.key] === "") {
                    return "";
                }
                if (record[modal.key]) {
                    return "Yes";
                }
                return "No";

            case "priority":
                switch (record[modal.key]) {
                    case 0:
                        return <Badge color={"secondary"}> Low</Badge>;
                    case 1:
                        return <Badge color={"warning"}> Medium</Badge>;
                    case 2:
                        return <Badge color={"danger"}> High</Badge>;

                }
                break;
            case "followUpType":
                return this.getAvatar(record[modal.key]);
            case "currency":
                return <NumberFormat value={Math.floor(record[modal.key])}
                                     displayType={'text'}
                                     thousandSeparator={true}
                                     prefix={'$'}/>
                    ;
            case "overdueDays":
                return <NumberFormat value={Math.floor(record[modal.key])}
                                     displayType={'text'}
                                     allowNegative={true}/>
                    ;
            default :
                return <span>{record[modal.key]}</span>
        }
    }

    getAvatar(followUpType) {

        let icon = "fa fa-phone";

        switch (followUpType) {
            case "PHONE":
                icon = "fa fa-phone";
                break;
            case "SMS":
                icon = "fa fa-commenting-o";
                break;
            case "EMAIL":
                icon = "fa fa-envelope-o";
                break;
            case "VISIT":
                icon = "fa fa-map-marker";
                break;
            default:
                break;

        }

        return <span><i className={icon} aria-hidden="true"/>&nbsp;{followUpType}</span>

    }


    render() {

        let {records, dataModal, pagination, isLoading} = this.props;

        dataModal = dataModal.filter((modal) => modal.isValid);

        if (records && records.length > 0) {
            return (
                <div>
                    <Card>
                        <CardBody>

                            <Table hover={true} responsive={true}>
                                <thead>

                                <tr>
                                    {(dataModal || []).map((modal, index) => {
                                        return (
                                            <th key={index}>{modal.label}</th>
                                        );
                                    })}
                                </tr>
                                </thead>
                                <InfiniteScroll
                                    element={"tbody"}
                                    initialLoad={false}
                                    loader={<tr key={"loadingItem"}>
                                        <td colSpan={12} className={"text-center"}><Button
                                            block={true}
                                            disabled={isLoading}
                                            color={"primary"}
                                            onClick={() => this.props.loadMore(true)}>{isLoading ? "Loading next " : "Load next "}{pagination.pageSize}&nbsp;
                                            records...</Button></td>
                                    </tr>}
                                    pageStart={1}
                                    loadMore={() => this.props.loadMore(true)}
                                    hasMore={(pagination.pageSize * pagination.currentPage) < pagination.totalRecords}
                                    useWindow={true}
                                >


                                    {(records || []).map((record, index) => {
                                        return (
                                            <tr key={index}
                                                title={record.title}>
                                                {(dataModal || []).map((modal, index) => {

                                                    return (
                                                        <td key={index}
                                                            style={{backgroundColor: modal.key === "scheduledOn" ? record.backgroundColor : ""}}>
                                                            {this.getColValue(record, modal)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}


                                </InfiniteScroll>


                            </Table>
                        </CardBody>
                    </Card>


                </div>
            );
        }

        return null;


    }
}