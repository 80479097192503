import React, {Component} from "react";
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input,
    Row, Col, Label
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import {toast} from "react-toastify";
import PrivilegeService from '../../services/PrivilegeService';
import { handleErrorMessage, getDistinct } from '../../services/CommonService';
import { cloneDeep } from 'lodash';

export default class ManagePrivilegeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: cloneDeep(this.props.selectedItem),
            updating: false,
            privilegeGroup: {
                privilegeGroupOptions: [],
                selectedOption: []
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.privilegeService = new PrivilegeService();
    }

    componentDidMount() {
        this.getPrivilegeGroup();
    }

    handleChange(key, change) {
        let { selectedItem, privilegeGroup } = this.state;
        switch (key) {
            case "selectedOption":
                if (change) {
                    privilegeGroup[key] = change;
                    selectedItem.groupName = change.value;
                    this.setState({ privilegeGroup, selectedItem });
                } else {
                    privilegeGroup[key] = change;
                    this.setState({ privilegeGroup, selectedItem });
                }
                break;
            case "default":
                selectedItem[key] = change;
                this.setState({ selectedItem });
                break;
            default:
                break;
        }
        
    }

    updatePrivilege() {
        this.setState({updating: true});
        this.privilegeService.updatePrivilegeData(this.state.selectedItem).then(response => {
            this.setState({updating: false});
            toast.success("Updated Successfully", {position: toast.POSITION.BOTTOM_CENTER});
            this.props.refreshAgain();
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    getPrivilegeGroup() {
        let { privilegeGroup, selectedItem } = this.state;
        this.privilegeService.getPrivilege().then(response => {
            response.data.forEach(privilege => {
                if (privilege.groupName) {
                    let option = {
                        value: privilege.groupName,
                        label: privilege.groupName
                    }
                    privilegeGroup.privilegeGroupOptions.push(option);
                }
            });
            privilegeGroup.privilegeGroupOptions = getDistinct(privilegeGroup.privilegeGroupOptions, "value");
            if (selectedItem) {
                let index = privilegeGroup.privilegeGroupOptions.findIndex(x => x.value == selectedItem.groupName);
                if (index > -1) {
                    privilegeGroup.selectedOption.push(privilegeGroup.privilegeGroupOptions[index]);
                }
            };
            this.setState({ privilegeGroup, selectedItem});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {selectedItem, updating, privilegeGroup} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Edit Privilege
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name"
                                       value={selectedItem.name}
                                       placeholder="Enter name here" disabled/>
                            </FormGroup>
                        </Col>
                        <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="groupName">Group Name</Label>
                                <CreatableSelect
                                    isMulti={false} name="groupName"
                                    placeholder={"Select privilege group"}
                                    onChange={(value) => this.handleChange("selectedOption", value)}
                                    value={privilegeGroup.selectedOption}
                                    options={privilegeGroup.privilegeGroupOptions} />
                            </FormGroup>
                        </Col>
                        <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input type="textarea" name="description"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem.description}
                                       placeholder="Enter description here"/>
                            </FormGroup>
                        </Col>
                        <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="usage">Usage</Label>
                                <Input type="textarea" name="usage"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem.usage}
                                       placeholder="Enter usage here"/>
                            </FormGroup>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.updatePrivilege()} disabled={updating}>
                            {updating
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           style={{color: "white"}}/>
                                : <i className="fa fa-floppy-o mr-2"/>}
                            Update</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times"/>&nbsp;
                            Cancel</Button>

                    </div>
                </ModalFooter>

            </Modal>
        );
    }
}