import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {scrollTo} from '../../../../../../services/CommonService';
import {cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import {v4 as uuidv4} from 'uuid';
import customUtil from './CustomUtil';
import CustomBuildProductionInstructionDropdown from './CustomBuildProductionInstructionDropdown';
import NumberFormat from "react-number-format";
import CustomBOMModal from "./CustomBOMModal";
import CustomConsolidatedBOMModal from "./CustomConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import SearchStock from "../../../../../search/SearchStockKeyway";
import {PRODUCT_BUILDER_CUSTOM_BUILD} from "../../../../../../store/AppConstants";
import CreatableSelect from 'react-select/creatable';

export default class CustomBuild extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {

        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        } else {
            this.init();
        }
    }

    init() {
        let {product} = this.props;
        if (!product.init_build) {
            product.init_build = true;
            this.props.handleChange("product_init", product);
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, context) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, stock, parentStock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index

                //prepare context
                context = {
                    salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                };
                product = this.updateConfiguration(product, itemIndex, context, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                item = cloneDeep(product.items[itemIndex]);
                if (item.hasOwnProperty('templateData')) {
                    item.templateData = {
                        templateName: '',
                        description: '',
                        isActive: true,
                        customTemplateID: 0
                    };
                }
                item.customID = uuidv4();
                product.items.push(item);
                product.items.forEach((item, itemIndex) => {
                    product = customUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = customUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    if (si.salesOrderItemCustom.itemType === "Build") {
                        item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                        product.items.push(item); //insert item
                        itemIndex = product.items.length - 1; // get item index

                        si.salesOrderItemCustom.stocks = [];
                        (si.salesOrderItemCustom.salesOrderItemParts || []).forEach(s => {
                            parentStock = si.salesOrderItemCustom.salesOrderItemParts.find(x => x.debtorInvoiceItemNum === s.debtorInvoiceParentItemNum);
                            stock = salesOrderProductBuilderV1Service.getStockFactoryInstance();
                            stock.id = s.id;
                            stock.prodCode = s.prodCode;
                            stock.price = s.debtorInvoiceParentItemNum === s.debtorInvoiceItemNum ? s.price : (s.price / parentStock.swishQty);
                            stock.calculatedQty = s.swishQty / s.keywayQty;
                            stock.swishCalculatedQty = s.swishQty;
                            stock.keywayCalculatedQty = s.keywayQty;
                            stock.keywayMeasurementUnit = s.keywayMeasurementUnit;
                            stock.swishMeasurementUnit = s.swishMeasurementUnit;
                            stock.keywayConversionFactor = s.keywayConversionFactor;
                            stock.swishConversionFactor = s.swishConversionFactor;
                            stock.isVisibleInPartList = s.isVisibleInPartList;
                            stock.isDeductionApplicable = s.isDeductionApplicable;
                            stock.description = s.description;
                            stock.attribute = s.attribute;
                            stock.label = s.label;
                            stock.attributeRowSpan = 1;
                            stock.unitPrice = stock.price;
                            stock.isCustom = s.isCustom;
                            stock.qoh = s.qoh;
                            stock.discontinued = s.discontinued;
                            stock.soldOut = s.soldOut;
                            si.salesOrderItemCustom.stocks.push(stock);
                        });

                        product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                        product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                        product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

                    }
                });
                this.init();

        }
        product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {
        let {packagingAndHandling, discountByProductCode} = this.props;

        customUtil.initItem(product, itemIndex, context, order, packagingAndHandling, discountByProductCode);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let condition1;
        if (key) {
            switch (key) {
                case "model":
                    condition1 = product.items[itemIndex].configuration.productCode.selected.value;
                    product.items[itemIndex].configuration.model.finalOptions = (product.items[itemIndex].configuration.model.options || [])
                        .filter(o => o.condition1 === condition1)
                        .map(o => {
                            return {value: o.optionKey, label: o.optionKey}
                        });
                    product.items[itemIndex].configuration.model.selected.value = "";
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1, stock;

        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = customUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = customUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = customUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = customUtil.updateFormError("drop", product, itemIndex);
                break;
            case "prodCode":
                if (isEmpty(change)) {
                    if (!isEmpty(product.items[itemIndex].configuration.prodCode.selected.value)) {
                        optionIndex = product.items[itemIndex].stocks.findIndex(s => s.prodCode === product.items[itemIndex].configuration.prodCode.selected.value);
                        if (optionIndex > -1) {
                            product.items[itemIndex].stocks.splice(optionIndex, 1);
                        }
                    }
                    product.items[itemIndex].configuration.prodCode.selected.value = "";
                } else {
                    optionIndex = product.items[itemIndex].stocks.findIndex(s => s.prodCode === change.prodCode);
                    if (optionIndex === -1) {
                        stock = salesOrderProductBuilderV1Service.getStockFactoryInstance();
                        stock.prodCode = change.prodCode;
                        stock.description = change.keywayStock ? change.keywayStock.description : change.description;
                        stock.unitPrice = change.keywayStock ? change.keywayStock.sellQtyPrice : change.sellQtyPrice;
                        stock.price = change.keywayStock ? change.keywayStock.sellQtyPrice : change.sellQtyPrice;
                        stock.discontinued = change.discontinued ;
                        stock.soldOut = change.soldOut;
                        stock.qoh = change.qoh;
                        stock.isCustom = true;

                        product.items[itemIndex].stocks.push(stock);
                    }
                    product.items[itemIndex].configuration.prodCode.selected.value = change.prodCode;
                    product.items[itemIndex].configuration.productCode.selected.value = change.product ? change.product.code : "";
                    product.items[itemIndex].configuration.productGroupCode.selected.value = change.productGroup ? change.productGroup.code : "";
                }
                product = this.updateItemConfigurationOptions("model", product, itemIndex, null);
                product = customUtil.updateFormError("prodCode", product, itemIndex);
                break;
            case "model":
                product.items[itemIndex].configuration.model.selected.value = change;
                break;
            case "productionInstructions":
                product.items[itemIndex].configuration.productionInstructions.selected.value = change;
                product = customUtil.updateFormError("productionInstructions", product, itemIndex);
                break;
            case "productionNotes":
                product.items[itemIndex].configuration.productionNotes.selected.value = change;
                product = customUtil.updateFormError("productionNotes", product, itemIndex);
                break;
            case "stock":
                switch (change.key) {
                    case "new-stock":
                        product.items[itemIndex].stocks.push(salesOrderProductBuilderV1Service.getStockFactoryInstance());
                        change.stockIndex = product.items[itemIndex].stocks.length - 1;
                        break;
                    case "clone-stock":
                        product.items[itemIndex].stocks.push(cloneDeep(product.items[itemIndex].stocks[change.stockIndex]));
                        change.stockIndex = product.items[itemIndex].stocks.length - 1;
                        break;
                    case "delete-stock":
                        product.items[itemIndex].stocks.splice(change.stockIndex, 1);
                        change.stockIndex = -1;
                        break;
                    case "stock":
                    default:
                        product.items[itemIndex].stocks[change.stockIndex] = change.value;
                        break;
                }
                if (change.stockIndex > -1) {
                    product.items[itemIndex].stocks[change.stockIndex].isCustom = true;
                }
                break;
            case "save-stock":
                product.items[itemIndex].stocks = change;
                break;
            default:
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = customUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let { workingBOMModal, isOpenConsolidatedBOMModal } = this.state;
        let { product, itemSummary, currentUser, order, discountByProductCode, hasProductBuilderTemplateWritePrivilege } = this.props;
        let items = itemSummary.items;
        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{minWidth: 45}}>
                                        #
                                    </th>
                                    <th className="text-center" style={{minWidth: 225}}>
                                        ProdCode
                                    </th>
                                    <th className="text-center" style={{minWidth: 225}}>
                                        Model
                                    </th>

                                    <th className="text-center" style={{minWidth: 135}}>
                                        Location
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Quantity
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Width(mm)
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Drop(mm)
                                    </th>
                                    <th className="text-center" style={{minWidth: 250}}>
                                        <div>Prod</div>
                                        <div>Instructions</div>
                                    </th>
                                    <th className="text-center" style={{minWidth: 150}}>
                                        <div>Prod</div>
                                        <div>Notes</div>
                                    </th>
                                    <th className="text-center" style={{minWidth: 50}}>
                                        Unit Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 50}}>
                                        Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 120}}>
                                        Action
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 90}}>
                                                <Button color={"link"}
                                                        onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                            </th>
                                            : null
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;

                                            return <tr key={itemIndex}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <SearchStock
                                                            handleSelection={(selectedStock) => this.handleChange(selectedStock, "prodCode", customUtil.findItemIndex(product, item.customID), true)}
                                                            selected={item.configuration.prodCode.selected.value}
                                                            defaultProdCode={item.configuration.prodCode.selected.value}
                                                            filter={{
                                                                bmFlag: 1,
                                                                excludeDiscontinued: true,
                                                                excludeSoldOut: true,
                                                                stockGroupCodes: [],
                                                                stockMasterGroupCodes: [],
                                                                colour: "",
                                                                excludeOffRange: null,
                                                                includeFabric: false,
                                                                excludeArchived: null,
                                                                excludeOutOfStock: null,
                                                                isNonStock: 0,
                                                            }}
                                                        />
                                                        {
                                                            item.configuration.prodCode.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.prodCode.formError.message}
                                                                </Badge>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <CreatableSelect
                                                        formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                        onChange={(selected) => this.handleChange(selected ? selected.value : "", "model", itemIndex, true)}
                                                        value={{
                                                            label: item.configuration.model.selected.value,
                                                            value: item.configuration.model.selected.value
                                                        }}
                                                        options={item.configuration.model.finalOptions}
                                                        isClearable={true}
                                                    />
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="text" name="location"
                                                           value={item.configuration.location.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "location", customUtil.findItemIndex(product, item.customID), true)}
                                                           placeholder="location"/>
                                                    {
                                                        item.configuration.location.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.location.formError.message}
                                                            </Badge>
                                                    }
                                                    {
                                                        item.configuration.location.formError.isWarning
                                                            ? <Badge color="warning">
                                                                {item.configuration.location.formError.message}
                                                            </Badge>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="quantity"
                                                           invalid={!item.configuration.quantity.formError.isValid}
                                                           value={item.configuration.quantity.selected.value}
                                                           min={item.configuration.quantity.min}
                                                           max={item.configuration.quantity.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "quantity", customUtil.findItemIndex(product, item.customID), true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="quantity"/>

                                                    {
                                                        item.configuration.quantity.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.quantity.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="width"
                                                           invalid={!item.configuration.width.formError.isValid}
                                                           value={item.configuration.width.selected.value}
                                                           min={item.configuration.width.min}
                                                           max={item.configuration.width.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", customUtil.findItemIndex(product, item.customID), true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="width"/>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="drop"
                                                           invalid={!item.configuration.drop.formError.isValid}
                                                           value={item.configuration.drop.selected.value}
                                                           min={item.configuration.drop.min}
                                                           max={item.configuration.drop.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "drop", customUtil.findItemIndex(product, item.customID), true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="drop"/>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <CustomBuildProductionInstructionDropdown
                                                        handleChange={(selected) => this.handleChange(selected, "productionInstructions", customUtil.findItemIndex(product, item.customID), true)}
                                                        productGroupCode={product.items[itemIndex].configuration.productGroupCode.selected.value}
                                                        selected={item.configuration.productionInstructions.selected.value}
                                                    />

                                                    {/*<Input type="textarea" name="productionInstructions"
                                                           value={item.configuration.productionInstructions.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "productionInstructions", customUtil.findItemIndex(product, item.customID), true)}
                                                           placeholder="Instructions"/>
                                                    {
                                                        item.configuration.productionInstructions.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.productionInstructions.formError.message}
                                                            </Badge>
                                                    }
                                                     {
                                                        item.configuration.productionInstructions.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.productionInstructions.formError.message}
                                                            </Badge>
                                                    }
                                                    */}
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="textarea" name="productionNotes"
                                                           value={item.configuration.productionNotes.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "productionNotes", customUtil.findItemIndex(product, item.customID), true)}
                                                           placeholder="Notes"/>
                                                    {
                                                        item.configuration.productionNotes.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.productionNotes.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", customUtil.findItemIndex(product, item.customID))}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", customUtil.findItemIndex(product, item.customID))}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, customUtil.findItemIndex(product, item.customID))}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null

                                                }

                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={10}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.priceWithoutDiscVal)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                ? <CustomBOMModal
                                        hasProductBuilderTemplateWritePrivilege={hasProductBuilderTemplateWritePrivilege}
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        handleChange={this.handleChange}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <CustomConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}

                                    />
                                    : null
                            }
                        </div>
                        :
                        null

                    }

                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
