import axios from './axios';

class StockInventoryCostService {

    static Instance() {
        return new StockInventoryCostService();
    }

    saveOrUpdateStockInventoryCost(prodCode, req) {
        return axios.post('api/stock-inventory-cost/save?prodCode=' + prodCode, req);
    }

    getOnestockInventoryCostByStock(prodCode) {
        return axios.get('api/stock-inventory-cost/one/by-stock/?prodCode=' + prodCode);
    }

}

export default StockInventoryCostService.Instance();