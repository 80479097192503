import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    Table
} from 'reactstrap';
import classnames from "classnames";
import {toast} from 'react-toastify';
import warehouseService from '../../../services/WINOInventory/WarehouseService';
import {getStartEndDateFromRangeText, handleErrorMessage} from '../../../services/CommonService';
import NumberFormat from "react-number-format";
import StockReceivePurchaseOrderEnquiry from "./StockReceivePurchaseOrderEnquiry";
import StockReceivePurchaseOrders from "./StockReceivePurchaseOrders";
import * as queryString from "query-string";

const purchaseOrderTabs = [
    {
        label: "Stock Receive",
        key: "stockReceive"
    },
    {
        label: "Purchase Orders",
        key: "purchaseOrders"
    }
];


export default class StockReceiveDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "stockReceive",
            stats: {
                purchaseOrderOutstandingToBeReceivedThisWeekCount: 0,
                purchaseOrderOutstandingToBeReceivedTodayCount: 0,
                purchaseOrderOutstandingCount: 0,
                purchaseOrderReceivedTodayCount: 0,
                purchaseOrderReceivedThisWeekCount: 0,
                purchaseOrderDueToBeReceived: 0
            },
            ordNum: null,
            filter: {
                toBeReceivedFromDate: "",
                toBeReceivedEndDate: "",
                receivedFromDate: "",
                receivedEndDate: "",
                statusIDs: [3],
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.getStockReceiveStats = this.getStockReceiveStats.bind(this);
    }

    componentDidMount() {
        let {ordNum, activeTab} = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            ordNum = searchParams.ordNum ? searchParams.ordNum : ordNum;
            activeTab = searchParams.activeTab ? searchParams.activeTab : activeTab;
        }

        this.setState({ordNum, activeTab}, () => {
            this.getStockReceiveStats();
        });
    }

    getStockReceiveStats() {
        let {stats} = this.state;
        warehouseService.getStockReceiveStats().then(response => {
            stats = response.data;
            this.setState({stats});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleTab(activeTab) {
        if (this.state.activeTab !== activeTab) {
            this.setState({activeTab},()=>{
                this.updateUrl(this.state.ordNum, activeTab);
            });
        }
    }

    handleChange(change, key) {
        let {filter, activeTab, ordNum} = this.state;
        let result;
        switch (key) {
            case "filter":
                switch (change) {
                    case "to_be_received_this_week":
                        result = getStartEndDateFromRangeText("This week");
                        filter.toBeReceivedFromDate = result.startDate;
                        filter.toBeReceivedEndDate = result.endDate;
                        filter.receivedFromDate = null;
                        filter.receivedEndDate = null;
                        filter.statusIDs = [3];
                        break;
                    case "to_be_received_today":
                        result = getStartEndDateFromRangeText("Today");
                        filter.toBeReceivedFromDate = result.startDate;
                        filter.toBeReceivedEndDate = result.endDate;
                        filter.receivedFromDate = null;
                        filter.receivedEndDate = null;
                        filter.statusIDs = [3];
                        break;
                    case "due_to_be_received":
                        filter.toBeReceivedFromDate = null;
                        var yesterday = new Date();
                        yesterday.setDate(yesterday.getDate() - 1);
                        filter.toBeReceivedEndDate = yesterday;
                        filter.receivedFromDate = null;
                        filter.receivedEndDate = null;
                        filter.statusIDs = [3];
                        break;
                    case "to_be_received_total":
                        filter.toBeReceivedFromDate = null;
                        filter.toBeReceivedEndDate = null;
                        filter.receivedFromDate = null;
                        filter.receivedEndDate = null;
                        filter.statusIDs = [3];
                        break;
                    case "received_today":
                        result = getStartEndDateFromRangeText("Today");
                        filter.toBeReceivedFromDate = null;
                        filter.toBeReceivedEndDate = null;
                        filter.receivedFromDate = result.startDate;
                        filter.receivedEndDate = result.endDate;
                        filter.statusIDs = [];
                        break;
                    case "received_this_week":
                        result = getStartEndDateFromRangeText("This week");
                        filter.toBeReceivedFromDate = null;
                        filter.toBeReceivedEndDate = null;
                        filter.receivedFromDate = result.startDate;
                        filter.receivedEndDate = result.endDate;
                        filter.statusIDs = [];
                        break;
                }
                activeTab = "purchaseOrders";
                this.setState({filter, activeTab});
                break;
            case "ordNum":
                if (ordNum !== change) {
                    ordNum = change;
                    activeTab = "stockReceive";
                    this.setState({ordNum, activeTab}, () => {
                        this.updateUrl(ordNum, activeTab);
                    });
                }
                break;
        }
    }

    updateUrl(ordNum, activeTab) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);

            if (ordNum) {
                searchParams.set("ordNum", ordNum);
            }
            if (activeTab) {
                searchParams.set("activeTab", activeTab);
            }

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    render() {
        let {
            stats, activeTab, ordNum, filter
        } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Receive</BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                <h6 className={"mb-0"}>Outstanding Purchase Orders</h6>
                            </CardHeader>
                            <CardBody>
                                <Table size={"sm"} className={"mb-0"}>
                                    <tbody>
                                        <tr>
                                            <td>Due to be received</td>
                                            <td className="text-right">
                                                <Button
                                                    onClick={(e) => this.handleChange("due_to_be_received", "filter")}
                                                    color={"link"}
                                                    size={"sm"}
                                                    className={"p-0"}
                                                ><NumberFormat
                                                        value={stats.purchaseOrderDueToBeReceived}
                                                        displayType={'text'}
                                                        thousandSeparator={true} />
                                                </Button>
                                            </td>
                                        </tr>
                                    <tr>
                                        <td>To Be Received Today</td>
                                        <td className="text-right">
                                            <Button
                                                onClick={(e) => this.handleChange("to_be_received_today", "filter")}
                                                color={"link"}
                                                size={"sm"}
                                                className={"p-0"}
                                            ><NumberFormat
                                                value={stats.purchaseOrderOutstandingToBeReceivedTodayCount}
                                                displayType={'text'}
                                                thousandSeparator={true}/>
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>To Be Received This Week</td>
                                        <td className="text-right">
                                            <Button
                                                onClick={(e) => this.handleChange("to_be_received_this_week", "filter")}
                                                color={"link"}
                                                size={"sm"}
                                                className={"p-0"}
                                            ><NumberFormat
                                                value={stats.purchaseOrderOutstandingToBeReceivedThisWeekCount}
                                                displayType={'text'}
                                                thousandSeparator={true}/>
                                            </Button>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Total Outstanding PO</td>
                                        <td className="text-right">
                                            <Button
                                                onClick={(e) => this.handleChange("to_be_received_total", "filter")}
                                                color={"link"}
                                                size={"sm"}
                                                className={"p-0"}
                                            ><NumberFormat value={stats.purchaseOrderOutstandingCount}
                                                           displayType={'text'}
                                                           thousandSeparator={true}/>
                                            </Button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                <h6 className={"mb-0"}>Received Purchase Orders</h6>
                            </CardHeader>
                            <CardBody>
                                <Table size={"sm"} className={"mb-0"}>
                                    <tbody>
                                    <tr>
                                        <td>Received Today</td>
                                        <td className="text-right">
                                            <Button
                                                onClick={(e) => this.handleChange("received_today", "filter")}
                                                color={"link"}
                                                size={"sm"}
                                                className={"p-0"}
                                            ><NumberFormat value={stats.purchaseOrderReceivedTodayCount}
                                                           displayType={'text'}
                                                           thousandSeparator={true}/>
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Received This Week</td>
                                        <td className="text-right">
                                            <Button
                                                onClick={(e) => this.handleChange("received_this_week", "filter")}
                                                color={"link"}
                                                size={"sm"}
                                                className={"p-0"}
                                            ><NumberFormat value={stats.purchaseOrderReceivedThisWeekCount}
                                                           displayType={'text'}
                                                           thousandSeparator={true}/>
                                            </Button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card className={"mt-2"}>
                    <CardHeader>
                        <Nav tabs card>
                            {purchaseOrderTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => this.toggleTab(tab.key)}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}
                                                className={"p-0"}
                                        >{tab.label}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        {(() => {
                            switch (activeTab) {
                                case "stockReceive":
                                    return <StockReceivePurchaseOrderEnquiry ordNum={ordNum}
                                                                             handleChange={(change) => this.handleChange(change, "ordNum")}
                                    />;
                                case "purchaseOrders":
                                    return <StockReceivePurchaseOrders
                                        handleChange={this.handleChange}
                                        filter={filter}
                                    />;
                            }
                        })
                        ()}

                    </CardBody>
                </Card>

            </div>
        );
    }
}