import {authHeader} from '../helpers/auth-header';
import {config} from '../helpers/config'
import axios from 'axios';

export default class BranchService {


    fetchBranches() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/branch/list'
        };
        return axios(request)
    }


}