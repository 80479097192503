import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardHeader, Col, Input, Row, Table} from 'reactstrap';
import NumberFormat from "react-number-format";
import {cloneDeep, isEmpty} from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import ModalImage from "react-modal-image";
import {
    DEFAULT_IMAGE_THUMBNAIL_500X250,
    DEFAULT_IMAGE_THUMBNAIL_50X50
} from "../../../../../../store/ImagePathConstants";
import {PRODUCT_BUILDER_HOSPITAL_RANGE_PART} from "../../../../../../store/AppConstants";
import hospitalRangePartUtil from "./HospitalRangePartUtil";
import {concatenateStrings} from "../../../../../../services/CommonService";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';

export default class HospitalRangePart extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(change, key, itemIndex) {
        let {product} = this.props;
        switch (key) {
            case "toggle_partType":
                product.part.configuration.partType.finalOptions[itemIndex].isOpen = change;
                product.part.configuration.partType.isOpen = hospitalRangePartUtil.isOpenAllPartType(product, true);
                break;
            case "toggle_partType_all":
                product.part.configuration.partType.finalOptions.forEach(o => {
                    o.isOpen = change;
                });
                product.part.configuration.partType.isOpen = hospitalRangePartUtil.isOpenAllPartType(product, true);
                break;
            default:
                break;
        }
        this.props.handleChange(product, "product");
    }

    render() {
        let {product, itemSummaryByItemType, handleChange, handleItemAction, discount, currentUser, order} = this.props;
        let itemSummary = itemSummaryByItemType[PRODUCT_BUILDER_HOSPITAL_RANGE_PART];

        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={4} lg={5} md={5} sm={12} xs={12}>
                        Select Colours
                        <Select
                            isMulti
                            onChange={(selected) => handleChange(selected, "product_colour_part_search", -1)}
                            value={product.part.configuration.colour.dropdown.selected.value}
                            options={product.part.configuration.colour.dropdown.finalOptions}
                        />
                    </Col>
                    <Col xl={4} lg={5} md={5} sm={12} xs={12}>
                        Select Part
                        <Select
                            isMulti
                            onChange={(selected) => handleChange(selected, "product_part_part_dropdown_search")}
                            value={product.part.configuration.part.dropdown.selected.value}
                            options={product.part.configuration.part.dropdown.finalOptions}
                        />

                    </Col>
                </Row>


                <Card>
                    <CardHeader className={"hoverableItem"}>
                        <i className="fa fa-th mr-2" aria-hidden="true"/>Preview Items
                    </CardHeader>
                    <div className="table-responsive">
                        <Table bordered>
                            <thead>
                            <tr>
                                <th className="text-center" style={{width: 50}}>
                                    <Button color={"primary"}
                                            size={"sm"}
                                            onClick={() => this.handleChange(!product.part.configuration.partType.isOpen, "toggle_partType_all", -1)}>
                                        <i className={classnames("fa", {
                                                "fa-plus-circle": !product.part.configuration.partType.isOpen,
                                                "fa-minus-circle": product.part.configuration.partType.isOpen,
                                            }
                                        )}/>
                                    </Button>
                                </th>
                                <th className="text-center" style={{width: 125}}>Product Code</th>
                                <th className="text-center">Photo</th>
                                <th className="text-center">Description</th>
                                <th className="text-center">Length</th>
                                <th className="text-center">Pack Qty</th>
                                <th className="text-center">Pack Qty Price</th>
                                <th className="text-center">Action</th>
                            </tr>
                            </thead>
                            {
                                ((product.part.configuration.partType.finalOptions) || []).map((o, oIndex) => {
                                    return <tbody key={oIndex}>
                                    <tr>
                                        <td className="align-middle text-center" style={{width: 50}}>
                                            <Button color={"primary"}
                                                    size={"sm"}
                                                    onClick={() => this.handleChange(!o.isOpen, "toggle_partType", oIndex)}>
                                                <i className={classnames("fa", {
                                                        "fa-plus-circle": !o.isOpen,
                                                        "fa-minus-circle": o.isOpen,
                                                    }
                                                )}/>
                                            </Button>
                                        </td>
                                        <td className="align-middle" colSpan={7}>
                                            <h6>{o.label}</h6>
                                        </td>
                                    </tr>

                                    {(o.isOpen && o.parts || []).map((p, pIndex) => {
                                        return <tr key={pIndex} id={p.prodCode}>
                                            <td className="align-middle text-center" colSpan={2}>
                                                <h5>
                                                    <Badge
                                                        color={"primary"}>{p.prodCode}</Badge>
                                                </h5>
                                            </td>
                                            <td className="align-middle text-center">
                                                <ModalImage
                                                    className="img-thumbnail"
                                                    small={p.thumbnailImageAttachmentID ? "api/file/Generate?AttachmentID=" + p.thumbnailImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_50X50}
                                                    large={p.highQualityImageAttachmentID ? "api/file/Generate?AttachmentID=" + p.highQualityImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_500X250}
                                                    alt="..."
                                                    hideDownload={true}
                                                    hideZoom={true}
                                                    imageBackgroundColor={"#FFFFFF"}
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <div>{p.description}</div>
                                                <div>Colour: {p.colour}</div>
                                            </td>
                                            <td className='align-middle text-center'>{(p.eachUnitName && p.eachUnitName.toLowerCase() === "length") ? concatenateStrings(" ", p.length, p.dimensionUnitName) : ""}</td>
                                            <td className='align-middle text-center'>{p.sellUnitQty}&nbsp;{p.eachUnitName}</td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, p.sellQtyPrice, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <Button
                                                    color={"primary"}
                                                    className={"mb-2 ml-1"}
                                                    onClick={() => handleItemAction("new", -1, {
                                                        ...p,
                                                        itemType: PRODUCT_BUILDER_HOSPITAL_RANGE_PART
                                                    })}>
                                                    <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                                                    Add
                                                </Button>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                })
                            }

                        </Table>
                    </div>
                </Card>

                <Card className={"mt-2"}>
                    <CardHeader>
                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/> Added Items
                    </CardHeader>

                    {(itemSummary.items && itemSummary.items.length > 0) ?
                        <div className="table-responsive">
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{width: 125}}>Product Code</th>
                                    <th className="text-center">Photo</th>
                                    <th className="text-center">Description</th>
                                    <th className="text-center">Length</th>
                                    <th className="text-center">Pack Qty</th>
                                    <th className="text-center">Pack Price</th>
                                    <th className="text-center" style={{width: 125}}>Qty
                                        <div>(of Packs)</div>
                                    </th>
                                    <th className="text-center" style={{width: 95}}>Total Each Qty</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Discount</th>
                                    <th className="text-center">Total</th>
                                    <th className="text-center">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(itemSummary.items || []).map((item, itemIndex) => {
                                    return <tr key={itemIndex}
                                               className={classnames({"table-danger": !item.isValid})}>
                                        <td className="align-middle text-center">
                                            <h5><Badge
                                                color={"primary"}>{item.prodCode}</Badge>
                                            </h5>
                                        </td>
                                        <td className="align-middle text-center">
                                            <ModalImage
                                                className="img-thumbnail"
                                                small={item.thumbnailImageAttachmentID ? "api/file/Generate?AttachmentID=" + item.thumbnailImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_50X50}
                                                large={item.highQualityImageAttachmentID ? "api/file/Generate?AttachmentID=" + item.highQualityImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_500X250}
                                                alt="..."
                                                hideDownload={true}
                                                hideZoom={true}
                                                imageBackgroundColor={"#FFFFFF"}
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <div>{item.description}</div>
                                            <div>Colour: {item.colour}</div>
                                        </td>
                                        <td className='align-middle text-center'>
                                            {item.eachUnitName === "length"
                                                ? <span>{item.length}{item.dimensionUnitName}</span>
                                                : null}
                                        </td>
                                        <td className="align-middle text-center">{item.sellUnitQty}&nbsp;{item.eachUnitName}</td>
                                        <td className="align-middle text-center">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.sellQtyPrice, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-center">
                                            <Input type='number'
                                                   value={item.quantity.selected.value}
                                                   name='quantity'
                                                   onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                   invalid={!item.quantity.formError.isValid}
                                                   min={item.quantity.min}
                                                   max={item.quantity.max}
                                                   onFocus={(event) => event.target.select()}
                                            />
                                            {
                                                (item.quantity.formError.isValid)
                                                    ? null
                                                    : <Badge color="danger">
                                                        {item.quantity.formError.message}
                                                    </Badge>
                                            }
                                        </td>
                                        <td className="align-middle text-center">
                                            <NumberFormat
                                                suffix={" " + item.eachUnitName}
                                                value={item.sellUnitQty * item.quantity.selected.value}
                                                displayType={'text'}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.discVal, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price - item.pricing.discVal, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-center">
                                            <Button color={"link"}
                                                    title={"click here to delete"}
                                                    onClick={() => handleItemAction("delete", hospitalRangePartUtil.findItemIndex(product, item.customID), null)}>
                                                <i className="fa fa-trash-o fa-lg text-danger"
                                                   aria-hidden="true"/>
                                            </Button>
                                        </td>
                                    </tr>

                                })}
                                {
                                    itemSummary.price
                                        ? <tr>
                                            <td className="align-middle" colSpan={8}>
                                                Total
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.price, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.discVal, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.price - itemSummary.discVal, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td/>
                                        </tr>
                                        : null
                                }

                                </tbody>
                            </Table>
                        </div>
                        : <CardBody>
                            <p className={"text-info"}>
                                <i className="fa fa-meh-o mr-2" aria-hidden="true"/> No items added yet.
                            </p>
                        </CardBody>
                    }
                </Card>

            </div>
        );
    }
}