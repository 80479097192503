import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody, Button, Label, Input, ModalFooter, Spinner} from 'reactstrap';
import apiLogService from '../../services/ApiLogService';
import {ToastContainer, toast} from 'react-toastify';
import {handleErrorMessage} from "../../services/CommonService";

export default class UpdateApiLogCommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            log: this.props.log,
            comment: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value) {
        this.setState({comment: value})
    }

    handleSubmit() {
        this.setState({loading: true});
        let { comment, log } = this.state;
        let logIDs = [];
        logIDs.push(log.id);
        let request = { logIDs: logIDs, comment: comment };
        apiLogService.updateApiLogComment(request).then(response => {
            toast.success("Updated!");
            this.setState({loading: false});
            this.props.toggleModal(false);
            this.props.refreshAgain();
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggleModal} = this.props;
        let {loading, comment} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={(e) => toggleModal(!isOpen)}>
                <ModalHeader toggle={(e) => toggleModal(!isOpen)}>
                    Update Comment
                </ModalHeader>
                <ModalBody>
                    <div className="mb-2">
                        <div className="mt-1 mb-2">
                            <Label>Comment</Label>
                            <Input className="form-control"
                                   onChange={(e) => this.handleChange(e.target.value)}
                                   type="textarea"
                                   name="comment"
                                   value={comment}
                                   placeholder="Write comment here"/>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} onClick={this.handleSubmit}
                                className={"mr-2"}
                                disabled={loading}>
                            {loading ? <Spinner className={"mr-2"} size={"sm"}/> : <i className="fa fa-floppy-o mr-2"/>}
                            {loading ? "Updating" : "Update"} Comment </Button>
                        <Button color={"secondary"} onClick={(e) => toggleModal(!isOpen)}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}