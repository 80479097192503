import React, {Component} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Spinner} from 'reactstrap';
import {toast, ToastContainer} from "react-toastify";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {IMAGE_TYPES, FACING_MODES} from "../camera/CameraConstants";


import ModalImage from "react-modal-image";
import {b64toBlob, concatenateStrings, getDateString} from "../../services/CommonService";
import AttachmentService from '../../services/AttachmentService';

export default class CameraModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snapshots: [],
            currentFacingMode: FACING_MODES.USER,
            isUploading: false,
            uploadProgress: 0,
        };
        this.attachmentService = new AttachmentService();
        this.handleChange = this.handleChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleUploadProgress = this.handleUploadProgress.bind(this);
    }

    handleChange(change, key) {
        let {snapshots} = this.state;
        switch (key) {
            case "capture_image":
                snapshots.push(change);
                this.setState({snapshots});
                break;
            case "remove_image":
                snapshots.splice(change, 1);
                this.setState({snapshots});
                break;
            case "currentFacingMode":
                this.setState({currentFacingMode: change});
                break;
        }
    }

    handleUploadProgress(progressEvent) {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
            this.setState({uploadProgress: percent});
        }
    }

    handleFileUpload() {
        let {snapshots} = this.state;

        let files = [];
        let formData = new FormData();

        (snapshots || []).forEach((ImageURL, ImageURLIndex) => {
            let block = ImageURL.split(";");
            let contentType = block[0].split(":")[1];
            let realData = block[1].split(",")[1];
            let blob = b64toBlob(realData, contentType);
            let filename = concatenateStrings("",
                "Snapshot ",
                getDateString(new Date(), "DD-MM-YYYY HH-mm-ss"),
                "(",
                ImageURLIndex + 1,
                ")",
                ".jpg"
            );
            let fileOfBlob = new File([blob], filename);
            formData.append("files", fileOfBlob);

        });
        formData.append('category', this.props.category);

        this.setState({isUploading: true});
        this.attachmentService.uploadAttachments(formData, this.handleUploadProgress).then(response => {
            this.setState({isUploading: false, uploadProgress: 0, snapshots: []}, () => {
                this.props.handleChange(response.data);
            });
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({isUploading: false, uploadProgress: 0});
        });
    }


    render() {
        let {snapshots, currentFacingMode, uploadProgress, isUploading} = this.state;
        let {isOpen, toggle} = this.props;
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={true} backdrop={"static"}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    <Button color={"link"} style={{display: "inline-block"}}
                            onClick={() => this.handleChange(currentFacingMode === FACING_MODES.USER ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER, "currentFacingMode")}>
                        <img src="/img/camera-flip.png" style={{width: 55, height: "auto"}}/>
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <div className={"text-center"} style={{
                        maxHeight: "700px"
                    }}>
                        <Camera onTakePhotoAnimationDone={(dataUri) => {
                            this.handleChange(dataUri, "capture_image")
                        }}
                                isFullscreen={false}
                                imageCompression={0}
                                idealFacingMode={currentFacingMode}
                                isMaxResolution={true}
                                imageType={IMAGE_TYPES.JPG}
                        />
                    </div>
                    {
                        ((snapshots || []).length > 0)
                            ? <div>
                                <hr/>
                                <Row>
                                    {
                                        (snapshots || []).map((img, imgIndex) => {
                                            return <Col xl={2} lg={3} md={4} sm={6} xs={12} className={"text-center"}
                                                        key={imgIndex}>
                                                <ModalImage
                                                    className="img-thumbnail"
                                                    small={img}
                                                    large={img}
                                                    alt="..."
                                                    hideDownload={true}
                                                    hideZoom={true}
                                                    imageBackgroundColor={"#FFFFFF"}
                                                />
                                                <Button color={"link"} size="sm"
                                                        onClick={() => this.handleChange(imgIndex, "remove_image")}>
                                                    <i className="fa fa-times text-danger mr-2"/>
                                                    <span className="text-danger">Remove</span>
                                                </Button>
                                            </Col>
                                        })

                                    }
                                </Row>
                            </div>
                            : null
                    }
                    {
                        (isUploading && uploadProgress > 0)
                            ? <div className="mt-1">
                                <div className={"mt-1"}>
                                    {uploadProgress}%&nbsp;Uploaded
                                    <Progress animated value={uploadProgress}/>
                                </div>
                            </div>
                            : null
                    }
                </ModalBody>
                <ModalFooter>
                    {
                        ((snapshots || []).length > 0)
                            ? <Button color={"primary"} size="sm"
                                      className={"mr-2"}
                                      disabled={isUploading}
                                      onClick={this.handleFileUpload}>
                                {
                                    isUploading
                                        ? <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                        : <i className="fa fa-upload mr-2"/>
                                }
                                {
                                    isUploading
                                        ? "Uploading"
                                        : "Upload"
                                }
                            </Button>
                            : null
                    }

                    <Button color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>
                        <i className="fa fa-times mr-2"/>
                        Cancel
                    </Button>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        );
    }
}