import React, {Component} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {cloneDeep, isEmpty} from 'lodash';
import curtainTrackUtil from "./CurtainTrackUtil";
import {
    PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM, PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_EXTERNAL_LIVE,
    PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_INTERNAL_LIVE, PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_EXTERNAL_LIVE,
    PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_INTERNAL_LIVE,
    PRODUCT_BUILDER_CURTAIN_TRACK_PART
} from "../../../../../../store/AppConstants";

import CurtainTrackPart from "./CurtainTrackPart";
import classnames from 'classnames';
import CurtainTrackCustom from "./CurtainTrackCustom";


export default class CurtainTrack extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
            isCustomPBVisible: false
        };

        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkIfCustomPBApplicable = this.checkIfCustomPBApplicable.bind(this);
    }

    componentDidMount() {
        this.checkIfCustomPBApplicable(this.props.currentUser);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }


    handleChange(change, key) {
        let {product} = this.props;
        switch (key) {
            case "product":
                product = change;
                break;
            case "product_init":
                if (change.init_part && change.init_custom) {
                    change.init = true;
                }
                product = change;
                break;

        }
        this.props.handleChange("product", product);
    }

    checkIfCustomPBApplicable(user) {
        let {isCustomPBVisible} = this.state;

        //PROD
        if (!user.isTestEnviroment && !user.isExternalUser && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_INTERNAL_LIVE) {
            isCustomPBVisible = true;
        }
        if (!user.isTestEnviroment && user.isExternalUser && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_EXTERNAL_LIVE) {
            isCustomPBVisible = true;
        }

        //TEST
        if (user.isTestEnviroment && !user.isExternalUser && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_INTERNAL_LIVE) {
            isCustomPBVisible = true;
        }
        if (user.isTestEnviroment && user.isExternalUser && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_EXTERNAL_LIVE) {
            isCustomPBVisible = true;
        }
        this.setState({isCustomPBVisible});
    }


    render() {
        let {activeTab, isCustomPBVisible} = this.state;
        let {order, product, products, discountByProductCode, packagingAndHandling, currentUser} = this.props;


        let itemSummary = curtainTrackUtil.getItemTypeSummary(product);
        return (
            <div>
                <Nav tabs>
                    <NavItem className={classnames({hoverableItem: activeTab !== '1'})}>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => this.toggle('1')}>
                            Parts {itemSummary[PRODUCT_BUILDER_CURTAIN_TRACK_PART].count ?
                            <span>({itemSummary[PRODUCT_BUILDER_CURTAIN_TRACK_PART].count})</span> : null}
                        </NavLink>
                    </NavItem>
                    {
                        isCustomPBVisible
                            ? <NavItem className={classnames({hoverableItem: activeTab !== '2'})}>
                                <NavLink
                                    className={classnames({active: activeTab === '2'})}
                                    onClick={() => this.toggle('2')}>
                                    Build {itemSummary[PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM].count ?
                                    <span>({itemSummary[PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM].count})</span> : null}
                                </NavLink>
                            </NavItem>
                            : null
                    }

                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="mt-2">
                            <CurtainTrackPart
                                order={order}
                                product={product}
                                products={products}
                                currentUser={currentUser}
                                itemSummary={itemSummary[PRODUCT_BUILDER_CURTAIN_TRACK_PART]}
                                packagingAndHandling={packagingAndHandling}
                                discountByProductCode={discountByProductCode}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </TabPane>
                    {
                        isCustomPBVisible
                            ? <TabPane tabId="2">
                                <div className="mt-2">
                                    <CurtainTrackCustom
                                        order={order}
                                        product={product}
                                        products={products}
                                        currentUser={currentUser}
                                        itemSummary={itemSummary[PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM]}
                                        packagingAndHandling={packagingAndHandling}
                                        discountByProductCode={discountByProductCode}
                                        handleChange={this.handleChange}
                                    />
                                </div>
                            </TabPane>
                            : null
                    }

                </TabContent>
            </div>
        );
    }
}