import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button
} from 'reactstrap';
import CRMService from "../../services/crm/CRMService";
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'


class ConvertLead extends Component {

    constructor(props) {
        super(props);
        let leadId = 0;
        let lead = {};
        if (props.location.state) {
            if (props.location.state.lead) {
                if (props.location.state.lead.id) {
                    leadId = props.location.state.lead.id;
                    lead = props.location.state.lead;
                }
            }
        }
        this.state = {
            leadconversion: {
                leadId: leadId,
                AccountID: "",
                Company: lead.company,
                ABN: "",
                StoreNum: "",
                Address1: lead.address1,
                Address2: lead.address2,
                Address3: lead.address3,
                City: lead.city,
                State: lead.state,
                PostCode: lead.postCode,
                Country: "",
                Phone: lead.phone,
                PhoneAfterHrs: "",
                Fax: "",
                Email: lead.email,
                Website: lead.website,
                Terms: "",
                SpecialReq: "Pre paid prior to despatching",
                ClassificationID: 1,
                TypeID: 0,
                PaymentTermsID: 2,
                Industrial: false,
                SDI: false,
                BO: false,
                FO: false,
                RepArea1: 0,
                RepArea2: 0,
                International: false,
                CurrencyType: "",
                IncoTermID: 0,
                UserID: "",
                DebtorGroupID: 0
            },
            LeadId: leadId,
            DebtorClassification: [],
            DebtorTypes: [],
            CurrencyTypes: [],
            Countries: [],
            PaymentTerms: [],
            Debtorgroups: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.crmService = new CRMService();
        this.fetchClassification = this.fetchClassification.bind(this);
        this.fetchCountries = this.fetchCountries.bind(this);
        this.fetchDebtorTypes = this.fetchDebtorTypes.bind(this);
        this.fetchDebtorPaymentTerms = this.fetchDebtorPaymentTerms.bind(this);
        this.fetchCurrencyTypes = this.fetchCurrencyTypes.bind(this);
        this.fetchNextAvailableAcountId = this.fetchNextAvailableAcountId.bind(this);
        this.fetchDebtorgroups = this.fetchDebtorgroups.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        this.fetchClassification();
        this.fetchCountries();
        this.fetchDebtorTypes();
        this.fetchDebtorPaymentTerms();
        this.fetchCurrencyTypes();
        this.fetchDebtorgroups();
        this.fetchNextAvailableAcountId();
    }

    fetchNextAvailableAcountId() {
        this.crmService.getNextAvailableAcountId().then(response => {
            let { leadconversion } = this.state;
            leadconversion.AccountID = response.data;
            this.setState({ leadconversion });
        }).catch(error => {
            console.log(error.data)
        });
    }

    fetchClassification() {
        this.crmService.getDebtorClassification().then(response => {
            this.setState({ DebtorClassification: response.data });
        }).catch(error => {
            console.log(error.data)
        });
    }

    fetchCountries() {
        this.crmService.getDebtorCountries().then(response => {
            this.setState({ Countries: response.data });
            if (response.data.length > 0) {
                let leadconversion = this.state.leadconversion;
                leadconversion.Country = response.data[0].country;
                this.setState({ leadconversion: leadconversion });

            }
        }).catch(error => {
            console.log(error.data)
        });
    }

    fetchDebtorgroups() {
        this.crmService.getDebtorGroups().then(response => {
            this.setState({ Debtorgroups: response.data });
        }).catch(error => {
            console.log(error.data);
        });
    }

    fetchDebtorTypes() {
        this.crmService.getDebtorTypes().then(response => {
            this.setState({ DebtorTypes: response.data });
            if (response.data.length > 0) {
                let leadconversion = this.state.leadconversion;
                leadconversion.TypeID = response.data[0].debtorTypeID;
                this.setState({ leadconversion: leadconversion });
            }
        }).catch(error => {
            console.log(error.data)
        });
    }

    fetchDebtorPaymentTerms() {
        this.crmService.getDebtorPaymentTerms().then(response => {
            this.setState({ PaymentTerms: response.data });
        }).catch(error => {
            console.log(error.data)
        });
    }

    fetchCurrencyTypes() {
        this.crmService.getDebtorCurrencyTypes().then(response => {
            this.setState({ CurrencyTypes: response.data });
            if (response.data.length > 0) {
                let leadconversion = this.state.leadconversion;
                leadconversion.CurrencyType = response.data[0].currencyType;
                this.setState({ leadconversion: leadconversion });
            }
        }).catch(error => {
            console.log(error.data)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { leadconversion } = this.state;
        leadconversion[name] = value;
        this.setState({ leadconversion });
    }

    handleCheckboxChange(e) {
        const { name, checked } = e.target;
        let { leadconversion } = this.state;
        leadconversion[name] = checked;
        this.setState({ leadconversion });

    }

    validateForm(Company, PaymentTermsID, DebtorGroupID, ClassificationID) {
        // true means invalid, so our conditions got reversed
        if (this.state.LeadId > 0) {
            return {
                Company: Company.length === 0,
                PaymentTermsID: parseInt(PaymentTermsID) === 0,
                DebtorGroupID: parseInt(DebtorGroupID) === 0,
                ClassificationID: parseInt(ClassificationID) === 0,
            };
        }
        else {
            return { // returns no red boxes
                Company: false,
                PaymentTermsID: false,
                DebtorGroupID: false,
                ClassificationID: false
            };
        }
    }


    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        //let lead = { 'id': this.state.leadconversion.leadId };
        _self.crmService.SaveLeadToCustomer(_self.state.leadconversion).then(response => {
            console.log(response.data, 'response.data');
            if (parseInt(response.data) > 0) {
                toast.success("Lead Converted !", {
                    position: toast.POSITION.BOTTOM_CEnter
                });
            }
            let id = window.btoa(this.state.leadconversion.leadId);
            this.props.history.push({ pathname: '/crm/manageLead/' + id });
        }).catch(error => {
            console.log(error.data)
        });
    }

    handleCancel() {
        if (this.state.leadconversion.leadId === 0) {
            this.props.history.push('/crm/leads');
        } else {
            //let lead = { 'id': this.state.leadconversion.leadId };
            let id = window.btoa(this.state.leadconversion.leadId);
            this.props.history.push({ pathname: '/crm/manageLead/' + id });
        }
    }

    render() {
        const errors = this.validateForm(this.state.leadconversion.Company, this.state.leadconversion.PaymentTermsID, this.state.leadconversion.DebtorGroupID, this.state.leadconversion.ClassificationID);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        let err = Object.keys(errors).filter(x => errors[x]);
        return (
            <div>
                <Row>
                    <Col lg={12} md={12}>
                        <div>
                            <Card>
                                <CardHeader>
                                    <h4>{this.state.leadconversion.Company} &nbsp; <Label className="col-form-label">(Account
                                        Id - {this.state.leadconversion.AccountID})</Label></h4>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Company</Label>
                                                    <Input
                                                        style={err.find(x => x === 'Company') ? { border: '1px solid red' } : { border: '1px solid #ced4da' }}
                                                        className="form-control"
                                                        name="Company"
                                                        type="text"
                                                        value={this.state.leadconversion.Company}
                                                        onChange={this.handleChange}
                                                        placeholder="write company name here..." />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={4} sm={4} xs={8}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Customer
                                                        Classification</Label>
                                                    <Input
                                                        style={err.find(x => x === 'ClassificationID') ? { border: '1px solid red' } : { border: '1px solid #ced4da' }}
                                                        className="form-control"
                                                        type="select"
                                                        value={this.state.leadconversion.ClassificationID}
                                                        onChange={(e) => this.handleChange(e)}
                                                        name="ClassificationID"
                                                        placeholder="Classification here...">
                                                        <option key='0' value='0'>Select Classification</option>
                                                        {this.state.DebtorClassification.map((item) => <option
                                                            key={item.classificationID}
                                                            value={item.classificationID}>{item.name}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2} md={2} sm={2} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">&nbsp;</Label>
                                                    <div className="checkbox checkbox-primary">
                                                        <Input name="International" id='4' type="checkbox"
                                                            onChange={(e) => this.handleCheckboxChange(e)}
                                                            defaultChecked={this.state.leadconversion.International} />
                                                        <Label className="mb-0" htmlFor="4">International</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">ABN</Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="ABN"
                                                        value={this.state.leadconversion.ABN}
                                                        onChange={this.handleChange}
                                                        placeholder="Enter ABN here..." />
                                                </FormGroup>
                                            </Col>


                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Customer
                                                        Type</Label>
                                                    <Input className="form-control"
                                                        type="select"
                                                        value={this.state.leadconversion.TypeID}
                                                        onChange={(e) => this.handleChange(e)}
                                                        name="TypeID"
                                                        placeholder="DebtorType here...">
                                                        {this.state.DebtorTypes.map((item) => <option
                                                            key={item.debtorTypeID}
                                                            value={item.debtorTypeID}>{item.name}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12}>

                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Store
                                                        Number</Label>
                                                    <Input className="form-control"
                                                        name="StoreNum"
                                                        type="text"
                                                        value={this.state.leadconversion.StoreNum}
                                                        onChange={this.handleChange}
                                                        placeholder="Enter Store Number here..." />
                                                </FormGroup>
                                            </Col>


                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Payment
                                                        Terms</Label>
                                                    <Input
                                                        style={err.find(x => x === 'PaymentTermsID') ? { border: '1px solid red' } : { border: '1px solid #ced4da' }}
                                                        className="form-control"
                                                        type="select"
                                                        value={this.state.leadconversion.PaymentTermsID}
                                                        onChange={(e) => this.handleChange(e)}
                                                        name="PaymentTermsID">
                                                        <option key='0' value='0'>Select Payment Terms</option>
                                                        {this.state.PaymentTerms.map((item) => <option
                                                            key={item.paymentTermsID}
                                                            value={item.paymentTermsID}>{item.description}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Special
                                                        Requirements </Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="SpecialReq"
                                                        value={this.state.leadconversion.SpecialReq}
                                                        onChange={this.handleChange}
                                                        placeholder="Enter Special Requirements here..." />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Customer
                                                        group</Label>
                                                    <Input
                                                        style={err.find(x => x === 'DebtorGroupID') ? { border: '1px solid red' } : { border: '1px solid #ced4da' }}
                                                        className="form-control"
                                                        type="select"
                                                        value={this.state.leadconversion.DebtorGroupID}
                                                        onChange={(e) => this.handleChange(e)}
                                                        name="DebtorGroupID"
                                                        placeholder="CurrencyType here...">
                                                        <option key='0' value='0'>Select Customer group</option>
                                                        {this.state.Debtorgroups.map((item) => <option
                                                            key={item.debtorGroupID}
                                                            value={item.debtorGroupID}>{item.name}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Currency
                                                        Type</Label>
                                                    <Input className="form-control"
                                                        type="select"
                                                        value={this.state.leadconversion.CurrencyType}
                                                        onChange={(e) => this.handleChange(e)}
                                                        name="CurrencyType"
                                                        placeholder="CurrencyType here...">
                                                        {this.state.CurrencyTypes.map((item) => <option
                                                            key={item.currencyType}
                                                            value={item.currencyType}>{item.description}
                                                            - {item.currencyType}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={2} md={3} sm={6} xs={6}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">&nbsp;</Label>

                                                    <div className="checkbox checkbox-primary">
                                                        <Input name="SDI" id='1' type="checkbox"
                                                            onChange={(e) => this.handleCheckboxChange(e)}
                                                            defaultChecked={this.state.leadconversion.SDI} />
                                                        <Label className="mb-0" htmlFor="1">Special
                                                            Delivery</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2} md={3} sm={6} xs={6}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">&nbsp;</Label>

                                                    <div className="checkbox checkbox-primary">
                                                        <Input name="BO" id='2' type="checkbox"
                                                            onChange={(e) => this.handleCheckboxChange(e)}
                                                            defaultChecked={this.state.leadconversion.BO} />
                                                        <Label className="mb-0" htmlFor="2">Allow B/Ord</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2} md={3} sm={6} xs={6}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">&nbsp;</Label>
                                                    <div className="checkbox checkbox-primary">
                                                        <Input name="FO" id='3' type="checkbox"
                                                            onChange={(e) => this.handleCheckboxChange(e)}
                                                            defaultChecked={this.state.leadconversion.FO} />
                                                        <Label className="mb-0" htmlFor="3">Allow F/Ord</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col lg={2} md={3} sm={6} xs={6}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">&nbsp;</Label>
                                                    <div className="checkbox checkbox-primary">
                                                        <Input name="Industrial" id='5' type="checkbox"
                                                            onChange={(e) => this.handleCheckboxChange(e)}
                                                            defaultChecked={this.state.leadconversion.Industrial} />
                                                        <Label className="mb-0" htmlFor="5">Industrial</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <div className={"text-right"}>
                                        <Button color='primary' onClick={this.handleSubmit}
                                            disabled={isDisabled}>Save</Button>{' '}
                                        <Button color='secondary' onClick={this.handleCancel}>Cancel</Button>{' '}</div>
                                </CardFooter>
                            </Card>
                            <br />
                            <Card className="shadow-0 border" id="test2">
                                <CardHeader>
                                    <h4>Basic Information</h4>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Country</Label>
                                                    <Input className="form-control"
                                                        type="select"
                                                        value={this.state.leadconversion.Country}
                                                        onChange={(e) => this.handleChange(e)}
                                                        name="Country"
                                                        placeholder="Country here...">
                                                        {this.state.Countries.map((item) => <option key={item.countryID}
                                                            value={item.country}>{item.country}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Address Line
                                                        1</Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="Address1"
                                                        value={this.state.leadconversion.Address1}
                                                        onChange={this.handleChange}
                                                        placeholder="write address line 1 here..." />
                                                </FormGroup></Col>

                                        </Row>

                                        <Row>

                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Address Line
                                                        2</Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="Address2"
                                                        value={this.state.leadconversion.Address2}
                                                        onChange={this.handleChange}
                                                        placeholder="write address line 2 here..." />
                                                </FormGroup></Col>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Address Line
                                                        3</Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="Address3"
                                                        value={this.state.leadconversion.Address3}
                                                        onChange={this.handleChange}
                                                        placeholder="write address line 3 here..." />
                                                </FormGroup></Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} md={4} sm={4} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">City</Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="City"
                                                        value={this.state.leadconversion.City}
                                                        onChange={this.handleChange}
                                                        placeholder="write city here..." />
                                                </FormGroup></Col>
                                            <Col lg={4} md={4} sm={4} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">State</Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="State"
                                                        value={this.state.leadconversion.State}
                                                        onChange={this.handleChange}
                                                        placeholder="write state here..." />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={4} sm={4} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">PostCode</Label>
                                                    <Input className="form-control"
                                                        type="text"
                                                        name="PostCode"
                                                        value={this.state.leadconversion.PostCode}
                                                        onChange={this.handleChange}
                                                        placeholder="write postCode here..." />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Phone</Label>
                                                    <Input className="form-control"
                                                        type="number"
                                                        name="Phone"
                                                        value={this.state.leadconversion.Phone}
                                                        onChange={this.handleChange}
                                                        placeholder="write Phone here..." />
                                                </FormGroup>
                                            </Col>
                                            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Email
                                                        Address</Label>
                                                    <Input className="form-control"
                                                        type="email"
                                                        name="Email"
                                                        value={this.state.leadconversion.Email}
                                                        onChange={this.handleChange}
                                                        placeholder="Enter email here..." />
                                                </FormGroup>
                                            </Col>
                                            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Website</Label>
                                                    <Input className="form-control"
                                                        type="url"
                                                        name="Website"
                                                        value={this.state.leadconversion.Website}
                                                        onChange={this.handleChange}
                                                        placeholder="write website here..." />
                                                </FormGroup>
                                            </Col>
                                            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label font-weight-bold">Fax</Label>
                                                    <Input className="form-control"
                                                        type="Number"
                                                        name="Fax"
                                                        value={this.state.leadconversion.Fax}
                                                        onChange={this.handleChange}
                                                        placeholder="Enter Fax here..." />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <div className={"text-right"}>
                                        <Button color='primary' onClick={this.handleSubmit}
                                            disabled={isDisabled}>Save</Button>{' '}
                                        <Button color='secondary' onClick={this.handleCancel}>Cancel</Button>{' '}</div>
                                </CardFooter>
                            </Card>
                            <br />

                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(ConvertLead)