import axios from '../axios';

class StockEnquiryService {

    static Instance() {
        return new StockEnquiryService();
    }

    getStock(prodCode) {
        return axios.get('api/stock-manage/one/?prodCode=' + prodCode);
    }

    getStockUnits(prodCode) {
        return axios.get('api/stock-unit/list/by-stock?prodCode=' + prodCode);
    }

    getStockInventory(prodCode) {
        return axios.get('api/stock-inventory/one/by-stock?prodCode=' + prodCode);
    }

    getStockInventoryCost(prodCode) {
        return axios.get('api/stock-inventory-cost/one/by-stock?prodCode=' + prodCode);
    }

    getStockGroupsByParent(stockGroupID) {
        return axios.get('api/stockGroup/list/by-parent?StockGroupID=' + stockGroupID);
    }


}

export default StockEnquiryService.Instance();