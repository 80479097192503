import {rollerBlindLabelStore} from "../production/RollerBlindLabelStore";
import {ziptrakExternalLabelStore} from "../production/ZiptrakExternalLabelStore";
import {ziptrakInternalLabelStore} from "../production/ZiptrakInternalLabelStore";
import {panelTrackLabelStore} from "../production/PanelTrackLabelStore";
import {venetianLabelStore} from "../production/VenetianLabelStore";
import {verticalIndoorLabelStore} from "../production/VerticalIndoorLabelStore";
import {verticalOutdoorLabelStore} from "../production/VerticalOutdoorLabelStore";
import {romanBlindLabelStore} from "../production/RomanBlindLabelStore";
import {hospitalRangeLabelStore} from "./HospitalRangeLabelStore";
import {defaultLabelStore} from "../production/DefautLabelStore";
import {otherLabelStore} from "../production/OtherLabelStore";
import {foldingArmAwningLabelStore} from "../production/FoldingArmAwningLabelStore";
import {widescreenLabelStore} from "../production/WidescreenLabelStore";
import {curtainTrackCustomLabelStore} from "../production/CurtainTrackCustomLabelStore";
import {projectionAwningLabelStore} from "../production/ProjectionAwningLabelStore";
import {skinOnlyLabelStore} from "../production/SkinOnlyLabelStore";
import {quiklokLabelStore} from "../production/QuiklokStore";
import {productConstant} from "../../../../../ProductGroups";

export function getSalesOrderItemProductLabelStore(product) {
    let store = null;
    switch (product.code) {
        case productConstant.ROLLER_BLINDS_SINGLE.code:
        case productConstant.ROLLER_BLINDS_DOUBLE.code:
        case "ROL":
            store = rollerBlindLabelStore;
            break;
        case productConstant.ROMAN_BLINDS.code:
            store = romanBlindLabelStore;
            break;
        case productConstant.ZIPTRAK_EXTERNAL.code:
            store = ziptrakExternalLabelStore;
            break;
        case productConstant.ZIPTRAK_INTERNAL.code:
            store = ziptrakInternalLabelStore;
            break;
        case productConstant.VERTICAL_INDOOR.code:
            store = verticalIndoorLabelStore;
            break;
        case productConstant.VERTICAL_OUTDOOR.code:
            store = verticalOutdoorLabelStore;
            break;
        case productConstant.FOLDING_ARM_AWNINGS.code:
            store = foldingArmAwningLabelStore;
            break;
        case productConstant.VENETIANS.code:
            store = venetianLabelStore;
            break;
        case productConstant.PANEL_TRACKS.code:
            store = panelTrackLabelStore;
            break;
        case productConstant.HOSPITAL_RANGE.code:
            store = hospitalRangeLabelStore;
            break;
        case productConstant.WIDESCREEN.code:
            store = widescreenLabelStore;
            break;
        case productConstant.CURTAIN_TRACKS.code:
            store = curtainTrackCustomLabelStore;
            break;
        case productConstant.PROJECTION_AWNINGS.code:
            store = projectionAwningLabelStore;
            break;
        case productConstant.SKIN_ONLY.code:
            store = skinOnlyLabelStore;
            break;
        case productConstant.SHP_CTS.code:
            store = quiklokLabelStore;
            break;
        case "OTHER":
            store = otherLabelStore;
            break;
        case "COM":
        default:
            store = defaultLabelStore;
            break;
    }
    return store;
}