import React, { Component } from "react";
import { Card, CardBody, CardHeader, Spinner, Table } from "reactstrap";
import { isEmpty } from "../../services/CommonService";
import StockService from "../../services/StockService";
import NumberFormat from "react-number-format";

const now = new Date();
const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
];
export default class PurchasesByMonth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: props.accountID,
            records: [],
            totalPurchases: 0,
            isLoading: false,
        };
        this.stockService = new StockService();
        this.getPurchasesByMonth = this.getPurchasesByMonth.bind(this);
    }

    componentDidMount() {
        this.getPurchasesByMonth();
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountID !== prevProps.accountID) {
            this.setState({ accountID: this.props.accountID }, () => {
                this.getPurchasesByMonth();
            });
        }
    }

    getPurchasesByMonth() {
        let { accountID, records, totalPurchases } = this.state;
        this.setState({ isLoading: true });
        this.stockService.getPurchaseHistory(accountID).then((response) => {
            records = this.dataFix(response.data);
            totalPurchases = records.reduce((acc, item) => {
                return acc + item.purchases;
            }, 0);
            this.setState({ isLoading: false, records, totalPurchases });
        }).catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
        });
    }

    dataFix = (data) => {
        let result = [];
        let currentMonth = now.getMonth() + 1; //january gives zero
        let currentYear = now.getFullYear();
        let previousYear = now.getFullYear() - 1;
        let previousYearData = data.filter(item => item.year === previousYear);
        let currentYearData = data.filter(item => item.year === currentYear);
        if (previousYearData.length > 0) {
            let previousMonths = previousYearData.map(item => item.month);
            for (let i = Math.min(...previousMonths); i <= 12; i++) {
                if (previousMonths.includes(i)) {
                    result.push(previousYearData.find(item => item.month === i));
                } else {
                    result.push({
                        month: i,
                        year: previousYear,
                        purchases: 0
                    });
                }
            }
        } else {
            for (let i = currentMonth; i <= 12; i++) {
                result.push({
                    month: i,
                    year: previousYear,
                    purchases: 0
                });
            }
        }
        if (currentYearData.length > 0) {
            let currentMonths = currentYearData.map(item => item.month);
            for (let i = 1; i < currentMonth; i++) {
                if (currentMonths.includes(i)) {
                    result.push(currentYearData.find(item => item.month === i));
                } else {
                    result.push({
                        month: i,
                        year: currentYear,
                        purchases: 0
                    });
                }
            }
        } else {
            for (let i = 1; i < currentMonth; i++) {
                result.push({
                    month: i,
                    year: currentYear,
                    purchases: 0
                });
            }
        }
        return result;
    };

    render() {
        let { isLoading, records, totalPurchases } = this.state;
        if (isLoading || isEmpty(records)) {
            return null;
        }
        return (
            <Card>
                <CardHeader>
                    <h6 className={"mb-0"}>Purchase in 12 months</h6>
                </CardHeader>
                <CardBody>
                    <Table size={"sm"} responsive className={"mb-0"}>
                        <tbody>
                            {(records || []).map((item, index) => (
                                <tr key={index}>
                                    <td>{months[item.month - 1]}</td>
                                    <td>{item.year}</td>
                                    <th className={"text-right"}>
                                        <NumberFormat value={item.purchases}
                                            displayType={'text'}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                            prefix={'$'} />
                                    </th>
                                </tr>
                            ))}
                            <tr>
                                <th colSpan={2}>Total Purchase</th>
                                <th className={"text-right"}>
                                    <NumberFormat value={totalPurchases}
                                        displayType={'text'}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        prefix={'$'} />
                                </th>
                            </tr>
                            <tr>
                                <th colSpan={2}>Average Purchase</th>
                                <th className={"text-right"}>
                                    <NumberFormat value={totalPurchases / records.length}
                                        displayType={'text'}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        prefix={'$'} />
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );
    }
}