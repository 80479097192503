import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, Row, Col, FormGroup, Label, Input, FormText
} from 'reactstrap';

export default class BankDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditorObj: this.props.creditorObj,
            formErrors: this.props.formErrors,

        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.creditorObj != this.state.creditorObj) {
            this.setState({ creditorObj: nextProps.creditorObj });
        }

        if (nextProps.formErrors != this.state.formErrors) {
            this.setState({ formErrors: nextProps.formErrors });
        }
    }

    handleChange(change, key) {
        let { creditorObj } = this.state;
        let { bankDetail } = creditorObj;
        bankDetail[key] = change;

        this.setState({ creditorObj });
        this.props.handleBankDetailChange(creditorObj);
    }

    render() {
        let { creditorObj, formErrors } = this.state;
        return (
            <div>
                <strong>Bank Details</strong>
                <br/>
                 <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Account Name</Label>
                                <Input
                                    invalid={formErrors.accountName}
                                    name="accountName"
                                    type="text"
                                    value={creditorObj.bankDetail.accountName || ''}
                                    onChange={(e) => this.handleChange(e.target.value, 'accountName')}
                                    placeholder="write account name here..." />
                                <FormText color="danger">{formErrors.accountName}</FormText>
                            </FormGroup>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Account Number</Label>
                                <Input
                                    invalid={formErrors.accountNumber}
                                    name="accountNumber"
                                    type="text"
                                    value={creditorObj.bankDetail.accountNumber || ''}
                                    onChange={(e) => this.handleChange(e.target.value, 'accountNumber')}
                                    placeholder="write account number here..." />
                                <FormText color="danger">{formErrors.accountNumber}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>

                <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Bank Name</Label>
                                <Input
                                    invalid={formErrors.bankName}
                                    name="bankName"
                                    type="text"
                                    value={creditorObj.bankDetail.bankName || ''}
                                    onChange={(e) => this.handleChange(e.target.value, 'bankName')}
                                    placeholder="write bank name here..." />
                                <FormText color="danger">{formErrors.bankName}</FormText>
                            </FormGroup>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Bank Suburb</Label>
                                <Input
                                    invalid={formErrors.bankSuburb}
                                    name="bankSuburb"
                                    type="text"
                                    value={creditorObj.bankDetail.bankSuburb || ''}
                                    onChange={(e) => this.handleChange(e.target.value, 'bankSuburb')}
                                    placeholder="write bank suburb here..." />
                                <FormText color="danger">{formErrors.bankSuburb}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
             
            </div>
        )
    }




}