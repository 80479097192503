import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import StockService from '../../services/StockService';
import cloneDeep from 'lodash/cloneDeep';


export default class SearchStockMasterGroupMulti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            defaultStockMasterGroupCodes: props.defaultStockMasterGroupCodes,
            selectedStockMasterGroupCodes: props.selectedStockMasterGroupCodes,
            loading: false,
            options: [],
            inputValue: "",
            filter: {
                isKeywayAvailableOnly: this.props.filter.isKeywayAvailableOnly,
                stockMasterGroupCodes: this.props.filter.stockMasterGroupCodes
            }
        };

        this.stockService = new StockService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

    }


    componentDidMount() {
        this.fetchOptions("");
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.defaultStockMasterGroupCodes !== nextProps.defaultStockMasterGroupCodes) {
            if (nextProps.defaultStockMasterGroupCodes) {
                this.setState({defaultStockMasterGroupCodes: nextProps.defaultStockMasterGroupCodes}, () => {
                    this.fetchOptions("");
                })
            }
        }

        if (this.state.selectedStockMasterGroupCodes !== nextProps.selectedStockMasterGroupCodes) {
            if (!nextProps.selectedStockMasterGroupCodes) {
                this.setState({selected: null})
            }
            else {
                this.setState({selectedStockMasterGroupCodes: nextProps.selectedStockMasterGroupCodes})
            }

            this.fetchOptions("");
        } else {
            if (nextProps.filter.isKeywayAvailableOnly !== this.state.filter.isKeywayAvailableOnly
                || JSON.stringify(nextProps.filter.stockMasterGroupCodes) !== JSON.stringify(this.state.filter.stockMasterGroupCodes)
            ) {
                let {filter} = this.state;
                filter.isKeywayAvailableOnly = this.props.filter.isKeywayAvailableOnly;
                filter.stockMasterGroupCodes = this.props.filter.stockMasterGroupCodes;
                this.setState({filter}, () => {
                    this.fetchOptions("");
                });

            }
        }
    }


    handleSelect(selected, event) {
        let {selectedStockMasterGroupCodes} = this.state;
        selectedStockMasterGroupCodes = (selected || []).map(s => s.value);

        switch (event) {
            case "selected":
                this.setState({selected, selectedStockMasterGroupCodes});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, selectedStockMasterGroupCodes});
                this.props.handleSelection(selectedStockMasterGroupCodes);
        }

        if (!selectedStockMasterGroupCodes) {
            this.fetchOptions("");
        }

    };

    fetchOptions(inputValue, callback) {
        let options = [];
        let {filter} = this.state;
        let requestBody = {
            searchText: inputValue,
            isKeywayAvailableOnly: filter.isKeywayAvailableOnly,
            stockMasterGroupCodes: filter.stockMasterGroupCodes,
        };
        if (inputValue && inputValue.includes(',')) {

            //  requestBody.stockMasterGroupCodes = inputValue.split(',');
            requestBody.stockMasterGroupCodes = "";
            requestBody.searchText = "";
        }

        this.stockService.getStockMasterGroupDropdownOptions(requestBody).then(response => {
            let data = response.data;
            for (let i in data) {
                let temp = {
                    label: data[i].name,
                    value: data[i].code,
                };
                options.push(cloneDeep(temp));
                if (this.props.defaultStockMasterGroupCodes) {
                    if (this.props.defaultStockMasterGroupCodes.includes(data[i].code)) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.props.selectedStockMasterGroupCodes) {
                    if (this.props.selectedStockMasterGroupCodes.includes(data[i].code)) {
                        this.handleSelect(temp, "selected");
                    }
                }
            }
            this.setState({options});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.options);
            }
        }
    };


    render() {
        return (
            <AsyncSelect
                cacheOptions
                isLoading={this.state.loading}
                isClearable={true}
                defaultOptions={this.state.options}
                loadOptions={this.loadOptions}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                isDisabled={this.props.disabled}
                isMulti={true}
                value={this.state.selected}
                placeholder={"Search..."}
            />
        );
    }
}

 


