import React, {Component} from 'react';
import FileSaver from 'file-saver';
import {Button, Card, CardBody, Progress, Spinner, Table} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import catalogueService from "../../services/CatalogueService";
import {findIndex, getSorted,handleErrorMessage} from "../../services/CommonService";
import {Link} from 'react-router-dom';

const recordType = "productBrochure";
export default class ProductBrochures extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            isLoadingRecords: false
        };
        this.onDocumentClickSuccess = this.onDocumentClickSuccess.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({isLoadingRecords: true});
        catalogueService.list(recordType).then(response => {
            let records = getSorted(response.data, 'title', true);
            this.setState({records, isLoadingRecords: false});
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({isLoadingRecords: false});
        });
    }

    onDocumentClickSuccess(item) {
        let {records} = this.state;
        let index = findIndex(records, 'id', item.id);
        records[index].isDownloading = true;
        this.setState({records});

        const options = {
            onDownloadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    records[index].requestProgressPercentage = percent;
                    this.setState({records});
                }
            }
        };

        records[index].requestProgressPercentage = 0;
        this.setState({records});
        catalogueService.downloadAttachment(item.fileName, options, recordType).then(response => {
            FileSaver.saveAs(response.data, item.description);
            records[index].isDownloading = false;
            records[index].requestProgressPercentage = 0;
            this.setState({records});
        }).catch(error => {
            console.log(error);
            records[index].isDownloading = false;
            records[index].requestProgressPercentage = 0;
            this.setState({records});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    render() {
        let {isLoadingRecords, records} = this.state;
        return (
            <div>
                <div>
                    <Card>
                        <CardBody>
                            <h5>Product Brochures</h5>
                            {
                                isLoadingRecords
                                    ? <Spinner color={"primary"}/>
                                    : <Table size={"sm"} striped={true} hover={true} bordered responsive>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th className={"text-center"} style={{width: 200}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(records || []).map((product, index) =>
                                            <tr>
                                                <td>{product.title}</td>
                                                <td className={"text-center"}>
                                                    {product.link ?
                                                        <Link to={product.link}><Button
                                                            color={"primary"}
                                                            size={"sm"}>View &nbsp;
                                                            <i className="fa fa-angle-double-right ml-2"
                                                               aria-hidden="true"/>
                                                        </Button></Link> :
                                                        <div>
                                                            <Button
                                                                onClick={() => this.onDocumentClickSuccess(product)}
                                                                color={"primary"}
                                                                size={"sm"}
                                                                disabled={product.isDownloading}

                                                            >
                                                                {product.isDownloading
                                                                    ? <Spinner size="sm"
                                                                               className={"mr-2"}
                                                                               style={{color: "white"}}/>
                                                                    : <i className="fa fa-arrow-circle-down mr-2"
                                                                         aria-hidden="true"/>}
                                                                {product.isDownloading
                                                                    ? "Downloading"
                                                                    : "Download"}
                                                            </Button>
                                                            &nbsp;
                                                            {product.requestProgressPercentage > 0
                                                                ? <Progress striped color="primary"
                                                                            value={product.requestProgressPercentage}
                                                                            className={"mt-2"}/>
                                                                : null
                                                            }
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                            }
                        </CardBody>
                    </Card>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}