import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Spinner,
    Card, CardBody, CardText,
    Row, Col,
    Input, CardImg
} from 'reactstrap';

const baseUrl = window.location.origin;
export default class NewsletterItemModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: {},
        };

    }


    render() {

        let {isOpen, toggle, selected, handleChange, handleSubmit, loading} = this.props;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {(selected && selected.id) ? "Edit" : "Create"}
                </ModalHeader>
                <ModalBody>
                    <div className="p-4">
                        <Row>
                            <Col>

                                <div>
                                    <div>Snapshot(png/jpg)</div>
                                    <Input type="file" name="snapshotFile"
                                           onChange={(e) => handleChange(e.target.files[0], "snapshotFile")}
                                           aria-label="File browser example"/>
                                </div>
                                <hr/>
                                <div>
                                    <div>Main File</div>
                                    <Input type="file" name="file"
                                           onChange={(e) => handleChange(e.target.files[0], "file")}
                                           aria-label="File browser example"/>
                                </div>
                                <hr/>
                                <div>
                                    <div>
                                        Name/Description
                                    </div>
                                    <Input
                                        type="textarea"
                                        name="title"
                                        value={this.state.title}
                                        onChange={(e) => handleChange(e.target.value, "title")}
                                        placeholder="Write here..."/>
                                </div>
                                <hr/>
                                <div className="text-center">
                                    <Button color={"primary"}
                                            onClick={() => handleSubmit()}
                                            disabled={loading}>
                                        {loading
                                            ? <Spinner size="sm"
                                                       className={"mr-2"}
                                                       style={{color: "white"}}/>
                                            : <i className="fa fa-floppy-o mr-2"/>}
                                        {loading ? "Saving" : "Save"}
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button disabled={loading}
                                            color={"secondary"}
                                            onClick={() => toggle(!isOpen)}>
                                        Cancel
                                    </Button>
                                </div>

                            </Col>
                            {
                                (selected.id)
                                    ? <Col className={"border-left"}>
                                        <div className="p-2">
                                            <Card>
                                                {
                                                    selected.snapFileName
                                                        ?
                                                        <CardImg
                                                            style={{
                                                                width: '100%',
                                                                height: 'auto'
                                                            }}

                                                            src={baseUrl + "/api/file/Generate/NewsLetter?FileName=" + selected.snapFileName}
                                                        />
                                                        : null
                                                }
                                                <CardBody>
                                                    <CardText>{selected.title}</CardText>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Col>
                                    : null
                            }
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}