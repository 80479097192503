import axios from './axios';

class StockInventoryStatusService {

    static Instance() {
        return new StockInventoryStatusService();
    }

    saveOrUpdateStockInventoryStatus(prodCode, req) {
        return axios.post('api/stock-inventory-status/save?prodCode=' + prodCode, req);
    }

    getOnestockInventoryStatusByStock(prodCode) {
        return axios.get('api/stock-inventory-status/one/by-stock/?prodCode=' + prodCode);
    }
}

export default StockInventoryStatusService.Instance();