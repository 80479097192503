import React, {Component} from 'react';
import {Col, Row, Label, Input, InputGroup, InputGroupAddon, Button} from 'reactstrap';

import {findIndex} from '../../services/CommonService';

const SalesOrderQuoteStatusOptions = [
    {label: "All", value: ""},
    {label: "Active", value: "0"},
    {label: "Completed", value: "5"},
    {label: "Cancelled", value: "-1"}
];

export default class SalesOrderQuoteStatusFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filter: this.props.filter};
        this.updateFilter = this.updateFilter.bind(this);
    }

    updateFilter(e) {

        let {filter} = this.state;
        filter.value = e.target.value;

        let index = findIndex(SalesOrderQuoteStatusOptions, "value", filter.value + "");
        if (index > -1) {
            filter.displayValue = SalesOrderQuoteStatusOptions[index].label;
        }

        this.setState({filter}, () => {
            this.props.onChange(filter, false);
        });

    }


    render() {
        let statusID = this.state.filter.value;

        return (
            <div>
                <Row className={"pb-3"}>
                    <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                        <Label>Search by Quote Status</Label>
                        <Input
                            className="form-control"
                            onChange={this.updateFilter}
                            type="select"
                            name="statusID"
                            value={statusID}
                            placeholder="Select Quote Status">
                            {SalesOrderQuoteStatusOptions.map((option, index) =>
                                <option key={index} value={option.value}>{option.label}</option>
                            )}
                        </Input>

                    </Col>
                </Row>
            </div>
        );
    }
}
