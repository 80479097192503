import React, {Component} from 'react';
import CustomerPackageHandling from './CustomerPackageHandling';
import CustomerFreights from './CustomerFreights';
import DebtorFreightPreferenceModal from './DebtorFreightPreferenceModal';
import {Row, Col} from 'reactstrap';

export default class FreightView extends Component {

    render() {
        const { accountId, hasDebtorWritePrivilege} = this.props;
        return (
            <div>
                <CustomerFreights accountId={accountId} />
                <br />
                <Row>
                    <Col xl={3} lg={4} md={4} sm={12} xs={12} className={"mt-2"}>
                        <DebtorFreightPreferenceModal accountID={accountId} hasDebtorWritePrivilege={hasDebtorWritePrivilege} />
                    </Col>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12} className={"mt-2"}>
                        <CustomerPackageHandling accountId={accountId} />
                    </Col>
                </Row>
            </div>
        )
    }
}