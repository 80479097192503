import {authHeader} from '../../helpers/auth-header';
import {config} from '../../helpers/config'
import axios from '../axios';
import {handleError, handleResponse} from "../UserService";

export default class ProductionCapacityService {

    getCapacityByDateFilter(filterDate) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({filteredDate: filterDate})
        };

        return fetch(config.apiUrl + 'api/productionCapacity/byDate', requestOptions).then(handleResponse, handleError);
    }

    getDefaultProductionCapacity() {
        return axios.get('api/productionCapacity/getProductionCapacityForDefault')
    }

    saveDefaultProductionCapacity(productionCapacity) {
        return axios.post('api/productionCapacity/default',productionCapacity )
    }

    getProductionLineDropDown() {
        return axios.get('api/productionCapacity/productionLine/dropdown')
    }

    isWeekday(date) {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    }

    getTotalCapacity(productCapacityByDate) {
        let total = 0;
        for (let key in productCapacityByDate) {
            let value = productCapacityByDate[key];
            total = value + total;
        }
        return total;
    }

    getTotalOrdersCount(ordersByProductGroup, filterBy, selectedDate) {

        let readyForProduction = false;
        switch (filterBy) {
            case "ALL_NOT_READY_FOR_PRODUCTION":
                readyForProduction = false;
                break;

            case "ALL_READY_FOR_PRODUCTION":
            case "CURRENT_DATE":
            default:
                readyForProduction = true;
                break;
        }
        let qty = 0;
        for (let productGroupName in ordersByProductGroup) {
            if (ordersByProductGroup[productGroupName]) {
                let tempOrders = ordersByProductGroup[productGroupName].filter(function (order) {
                    return order.readyForProduction === readyForProduction;
                });

                if (tempOrders) {
                    for (let i in tempOrders) {
                        if (tempOrders[i].qtyByProductionLineNameByScheduledDate) {
                            if (selectedDate) {
                                if (tempOrders[i].qtyByProductionLineNameByScheduledDate[selectedDate]) {
                                    for (let productGroup in tempOrders[i].qtyByProductionLineNameByScheduledDate[selectedDate]) {
                                        if (tempOrders[i].qtyByProductionLineNameByScheduledDate[selectedDate][productGroup]) {
                                            qty += tempOrders[i].qtyByProductionLineNameByScheduledDate[selectedDate][productGroup];
                                        }
                                    }

                                }
                            } else {
                                for (let productionDate in tempOrders[i].qtyByProductionLineNameByScheduledDate) {
                                    for (let productGroup in tempOrders[i].qtyByProductionLineNameByScheduledDate[productionDate]) {
                                        if (tempOrders[i].qtyByProductionLineNameByScheduledDate[productionDate][productGroup]) {
                                            qty += tempOrders[i].qtyByProductionLineNameByScheduledDate[productionDate][productGroup];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return qty;
    }

    countQtyInOrders(orders, selectedDate, productGroup, readyForProductionFilter) {
        let qty = 0;
        if (orders) {
            let tempOrders = orders.filter(function (order) {
                return order.readyForProduction === readyForProductionFilter;
            });
            if (tempOrders) {
                for (let i in tempOrders) {
                    if (tempOrders[i].qtyByProductionLineNameByScheduledDate) {
                        if (selectedDate) {
                            if (tempOrders[i].qtyByProductionLineNameByScheduledDate[selectedDate]) {
                                if (tempOrders[i].qtyByProductionLineNameByScheduledDate[selectedDate][productGroup]) {
                                    qty += tempOrders[i].qtyByProductionLineNameByScheduledDate[selectedDate][productGroup];
                                }
                            }
                        } else {
                            for (let productionDate in tempOrders[i].qtyByProductionLineNameByScheduledDate) {
                                if (tempOrders[i].qtyByProductionLineNameByScheduledDate[productionDate][productGroup]) {
                                    qty += tempOrders[i].qtyByProductionLineNameByScheduledDate[productionDate][productGroup];
                                }
                            }
                        }

                    }

                }
            }
        }
        return qty;
    }

    getCustomProductionCapacityForTodayOnwards(){
        return axios('api/productionCapacity/custom/today-onwards')
    }

    saveDatedProductionCapacity(productionCapacity) {
        return axios.post('api/productionCapacity/dated', productionCapacity);
    }

    getProductionLineList() {
        return axios.get('api/productionCapacity/productionLine/list')
    }

    getProductionCapacityWithDateAndProductionLineName() {
        return axios.get('api/productionCapacity/by-dates');
    }

    getDefaultProductionCapacityForEachProductionLines() {
        return axios.get('api/productionCapacity/getProductionCapacityForDefault/productionLines')
    }

    saveProductionCapacityBetweenDateRange(req) {
        return axios.post('api/productionCapacity/save', req);
    }

    saveProductionCapacityDayWise(req) {
        return axios.post('api/productionCapacity/daywise/save', req);
    }

    getProductionConfiguration() {
        return axios.get('api/production/configuration/get')
    }

    updateProductionConfiguration(request) {
        return axios.post('api/production/configuration/update',request )
    }
}