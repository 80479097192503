import React, {Component} from "react"
import {productGroups} from "../../../../../../store/ProductGroups";
import CreatableSelect from 'react-select/creatable';
import {isEmpty} from 'lodash';
import {concatenateStrings} from "../../../../../../services/CommonService";

const DEMILLTER = ",";

export default class CustomBuildProductionInstructionDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productGroupCode: "",
            selected: this.getArrayFromCommaSeparatedString(props.selected),
            options: [],
        };


        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.getCommaSeparatedStringFromArray = this.getCommaSeparatedStringFromArray.bind(this);
        this.getArrayFromCommaSeparatedString = this.getArrayFromCommaSeparatedString.bind(this);
    }

    componentDidMount() {
        this.fetchOptions();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.productGroupCode !== nextProps.productGroupCode) {
            this.setState({productGroupCode: nextProps.productGroupCode}, () => {
                this.fetchOptions();
            })
        }
        if (nextProps.selected !== this.getCommaSeparatedStringFromArray(this.state.selected)) {
            this.setState({selected: this.getArrayFromCommaSeparatedString(nextProps.selected)});
        }
    }

    getArrayFromCommaSeparatedString(commaSeparatedString) {
        let result = [];
        if (commaSeparatedString) {
            let arr = commaSeparatedString.split(DEMILLTER);
            (arr || []).map(item => {
                result.push({label: item, value: item});
            });
        }
        return result;
    }

    getCommaSeparatedStringFromArray(array) {
        let result = "";
        if (!isEmpty(array)) {
            (array || []).forEach((chunk, chunkIndex) => {
                if (chunk && chunk.value) {
                    result = result + chunk.value;
                }
                if (array.length > chunkIndex + 1 && array[chunkIndex + 1] && array[chunkIndex + 1].value) {
                    result = result + DEMILLTER;
                }
            });
            if (result) {
                result = result.trim();
            }
        }
        return result;
    }

    fetchOptions() {
        let options = [];
        if (this.state.productGroupCode) {
            (productGroups || []).forEach(pg => {
                if (pg.code === this.state.productGroupCode) {
                    options = pg.productionInstructions;
                }
            });
        }
        this.setState({options});
    }

    handleSelect(selected, event) {
        switch (event) {
            case "selected":
                this.setState({selected});
                break;
            case "onChange":
            default:
                this.setState({selected}, () => {
                    this.props.handleChange(this.getCommaSeparatedStringFromArray(selected));
                });
        }
    };

    render() {
        let {selected, options} = this.state;
        return (
            <CreatableSelect
                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                options={options}
                value={selected}
                onChange={(selected) => this.handleSelect(selected, "onChange")}
                closeMenuOnSelect={true}
                isSearchable={true}
                isMulti={true}
            />
        );
    }
}