export const dashboardCards = [
    {
        'label': 'Sales Order Submitted Count Card', 'value': 'SubmittedOrderCount'
    },
    {
        'label': 'Sales Order Pending Count Card', 'value': 'PendingOrderCount'
    },
    {
        'label': 'Daily Sale Summary Report', 'value': 'DailySaleSummaryReport'
    },
    {
        'label': 'Outstanding Order Summary Card', 'value': 'OutstandingOrderSummary'
    },
    {
        'label': 'Account Card', 'value': 'Accounts'
    },
    {
        'label': 'Keyway User Status Card', 'value': 'Users'
    },
    {
        'label': 'Order Card', 'value': 'Order'
    },
    {
        'label': 'Quote Card', 'value': 'Quote'
    },
    {
        'label': 'Outstanding Product Order Count', 'value': 'OutstandingProductOrderCount'
    },
    {
        'label': 'Assigned Lead Activities', 'value': 'AssignedLeadActivitiesForMe'
    },
    {
        'label': 'Assigned Tasks', 'value': 'AssignedTasksForMe'
    },
    {
        'label': 'MyLeads', 'value': 'MyLeads'
    },
    {
        'label': 'Recently Created Leads', 'value': 'RecentlyCreatedLeads'
    },
    {
        'label': 'Outstanding Amount And Order Counts', 'value': 'OutstandingAmountAndOrderCounts'
    },
    {
        'label': 'Customer Dashboard', 'value': 'CustomerDashboard'
    },
    {
        'label': 'Daily Sale Summary', 'value': 'DailySale'
    },
    //{
    //    'label': 'Product Builder Order Summary', 'value': 'ProductBuilderOrderSummary'
    //},
    {
        'label': 'Production Dashboard ProductionDifot', 'value': 'ProductionDashboardProductionDifot'
    },
    {
        'label': 'Production Dashboard Production Invoicing', 'value': 'ProductionDashboardProductionInvoicing'
    },
    {
        'label': 'Production Dashboard Production Line Performance', 'value': 'ProductionDashboardProductionLinePerformance'
    },
    {
        'label': 'Production Dashboard Production Pipeline', 'value': 'ProductionDashboardProductionPipeline'
    },
    {
        'label': 'Production Dashboard Summary Card', 'value': 'ProductionDashboardSummaryCard'
    },
    {
        'label': 'Product Builder Order', 'value': 'ProductBuilderOrderCount'
    },
    { 'label': 'Product Builder Order Weekly Table', 'value': 'ProductBuilderOrderWeeklyTable' },
    { 'label': 'Customer Leaderboard', 'value': 'CustomerLeaderboard' },
    { 'label': 'Product Builder Order Entered By', 'value': 'ProductBuilderOrderEnteredBy' },
    { 'label': 'Product Builder Unit Price By Current Week', 'value': 'ProductBuilderUnitPriceByCurrentWeek' },
    { 'label': 'Rework Unit', 'value':'ReworkUnitPage' }

];