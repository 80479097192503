import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    Table,
    Spinner
} from "reactstrap";
import {toast, ToastContainer} from 'react-toastify';
import classnames from 'classnames';
import queryString from 'query-string';

import {getSorted, handleErrorMessage} from "../../services/CommonService";
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import ProductConfigurationStockDeductionModal from '../../components/modal/ProductConfigurationStockDeductionModal';


export default class ProductConfigurationStockDeductionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layoutTypes: [],
            deductionResponse: [],
            productID: 0,
            searchText: '',
            stockObj: {
                layout: '',
                operationGroup: '',
                blindPosition: '',
                deduction: 0,
                controlSide: '',
                header: '',
                isEdit: false
            },
            activeLayoutType: '',
            isOpenStockDeductionModal: false,
            FormErrors: {},
            saving: false,
            loading: false,
            controlSideRowSpan: [],
            blindPositionRowSpan: [],
            isDeletingID: false

        };

        this.getStockDeduction = this.getStockDeduction.bind(this);
        this.getProductConfigurationStockDeductionHeading = this.getProductConfigurationStockDeductionHeading.bind(this);
        this.getLayoutTypes = this.getLayoutTypes.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getRowSpan = this.getRowSpan.bind(this);
        this.toggleStockDeductionModal = this.toggleStockDeductionModal.bind(this);
    }

    componentDidMount() {
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            let productID = searchParams.productID;
            this.setState({productID});
            this.getLayoutTypes(productID);
        }
    }


    getLayoutTypes(productID) {
        ProductConfigurationService.getLayoutTypes(productID).then(response => {
            this.setState({layoutTypes: response.data, activeLayoutType: response.data[0]});
            this.getStockDeduction(productID, response.data[0]);
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getRowSpan(deductionResponse, key) {
        let count = 1;
        let arr = Array.apply(null, new Array(deductionResponse.length)).map(function (x, i) {
            return 0;
        })
        let n = deductionResponse.length;
        let j = 0;
        if (key === 'controlSide') {
            for (let i = 1; i < n; i++) {
                if (deductionResponse[j].controlSide === deductionResponse[i].controlSide) {
                    count++;
                } else {
                    arr[j] = count;
                    j = i;
                    i--;
                    count = 1;
                }
            }
            arr[j] = count;
        }
        if (key === 'blindPosition') {
            for (let i = 1; i < n; i++) {
                if (deductionResponse[j].blindPosition === deductionResponse[i].blindPosition) {
                    count++;
                } else {
                    arr[j] = count;
                    j = i;
                    i--;
                    count = 0;
                }
            }
            arr[j] = count;
        }

        return arr;
    }

    getStockDeduction(productID, activeLayoutType) {
        this.setState({isLoadingList: true});
        ProductConfigurationService.getProductConfigurationStockDeductionList(productID, activeLayoutType).then(response => {
            let deductionResponse = getSorted(response.data, 'controlSide', true, 'blindPosition', true, 'operationGroup', true);
            let controlSideRowSpan = this.getRowSpan(deductionResponse, 'controlSide');
            let blindPositionRowSpan = this.getRowSpan(deductionResponse, 'blindPosition');
            this.setState({deductionResponse, isLoadingList: false, controlSideRowSpan, blindPositionRowSpan})
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({deductionResponse: [], isLoadingList: false});
        });
    }


    getProductConfigurationStockDeductionHeading() {
        let productConfigurationStockDeductionHeading = [

            {

                key: "controlSide",
                label: "Control Side",
                type: "text",
                colSpan: 1,
                minWidth: 250,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "text-center"
            },
            {
                key: "blindPosition",
                label: "Blind Position",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "text-center"
            },
            {
                key: "operationGroup",
                label: "Operation Group",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: null
            },

            {
                key: "deduction",
                label: "Deduction",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "text-center",
            },

            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center"
            }];

        return productConfigurationStockDeductionHeading;
    }


    toggleStockDeductionModal(change) {
        this.setState({isOpenStockDeductionModal: change});
    }

    toggle(tab) {
        if (this.state.activeLayoutType !== tab) {
            let {deductionResponse} = this.state;

            this.setState({
                activeLayoutType: tab,
                deductionResponse
            }, () => {
                this.getStockDeduction(this.state.productID, tab);
            });
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {stockObj} = this.state;
        stockObj[name] = value;
        this.setState({stockObj});
    }

    handleAction(productID, productId, key) {
        let {stockObj} = this.state;

        switch (key) {
            case "new":
                stockObj = {
                    layout: '',
                    operationGroup: '',
                    blindPosition: '',
                    deduction: 0,
                    controlSide: '',
                    header: 'Add'
                };
                this.setState({stockObj, productID, isOpenStockDeductionModal: true});
                break;
            case "edit":
                if (productId !== 0) {
                    ProductConfigurationService.getDeduction(productId).then(response => {
                        stockObj = response.data;
                        stockObj.header = 'Edit';
                        stockObj.isEdit = true;
                        this.setState({stockObj, isOpenStockDeductionModal: true});
                    }).catch(error => {
                        this.setState({loading: false});
                        toast.error(handleErrorMessage(error), {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    });
                }
                break;
            case "delete":
                if (productId !== 0) {
                    this.setState({isDeletingID: productId});
                    ProductConfigurationService.deleteDeduction(productId).then(response => {
                        this.setState({isDeletingID: false});
                        this.getStockDeduction(this.state.productID, this.state.activeLayoutType);
                        toast.success("Deleted!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }).catch(error => {
                        this.setState({isDeletingID: false});
                        toast.error(handleErrorMessage(error), {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({isDeletingID: productId});
                    });
                }
                break;
            default:
                break;
        }
    }

    validateForm(stockObj) {
        // true means invalid
        return {
            productID: stockObj.productID === 0,
            operationGroup: stockObj.operationGroup === '',
            deduction: stockObj.deduction === 0,
            layout: stockObj.layout === '',
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        let req = {...this.state.stockObj};
        let errors = _self.validateForm(req);
        const isAnyFieldPending = Object.keys(errors).some(x => errors[x]);
        let err = Object.keys(errors).filter(x => errors[x]);
        this.setState({FormErrors: errors});
        if (!isAnyFieldPending) {
            this.setState({saving: true});

            ProductConfigurationService.saveStockDeduction(req, this.state.productID).then(response => {
                if (response.status === 200 || response.status === "200") {
                    this.props.history.push('/inventory/product/configuration/stock/deduction?' + queryString.stringify({productID: this.state.productID}));
                    toast.success("Saved!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });

                    this.getLayoutTypes(this.state.productID);
                    this.getStockDeduction(this.state.productID, this.state.activeLayoutType);
                    this.setState({saving: false, isOpenStockDeductionModal: false});
                }

            }).catch(error => {
                this.setState({saving: false});
                toast.info(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        } else {
            this.setState({saving: false});
            toast.info("Please fill required details.. !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }


    render() {

        let {deductionResponse, isDeletingID, productID, activeLayoutType, layoutTypes, stockObj, isOpenStockDeductionModal, loading, isLoadingList, controlSideRowSpan, blindPositionRowSpan} = this.state;

        let productConfigurationStockDeductionHeading = this.getProductConfigurationStockDeductionHeading();
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/products/all/list?activeTab=product')}>Products</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push("/inventory/product/configuration?" + queryString.stringify({productID: productID}))}>Configuration</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Deductions</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Deductions</h5>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>

                                <div className={"text-right"}>

                                    <Button color={"primary"} size={"sm"}
                                            onClick={() => this.handleAction(productID, 0, "new")}>
                                        <i className="mr-2 fa fa-plus-circle" aria-hidden="true"/>New</Button>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Nav tabs card>
                                    {(layoutTypes || []).map((layoutType, index) =>
                                        <NavItem
                                            className={"hoverableItem"}
                                            key={index}>
                                            <NavLink
                                                className={classnames({active: activeLayoutType === layoutType})}
                                                onClick={() => {
                                                    this.toggle(layoutType);
                                                }}>
                                                <Button size={"sm"} color={"link"} style={{textDecoration: "none"}}>
                                                    <h6>{layoutType}</h6>
                                                </Button>
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {isLoadingList
                            ? <Spinner color={"primary"}/>
                            : <Table bordered size={"sm"} responsive>
                                <thead>
                                {
                                    (productConfigurationStockDeductionHeading || []).map((item, index) => {
                                        if (activeLayoutType === 'Single' && (item.label === 'Control Side' || item.label === 'Blind Position')) {
                                        } else {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{minWidth: item.minWidth}}>
                                                    {item.label}
                                                </th>

                                            );
                                        }
                                    })
                                }

                                </thead>
                                <tbody>

                                {
                                    (deductionResponse || []).map((res, index) => {

                                        return (
                                            activeLayoutType === 'Single' ? <tr className={"text-center"}>
                                                < td> {res.operationGroup}</td>
                                                <td>{res.deduction} </td>
                                                <td>

                                                    <Button color={"primary"} size={"sm"}
                                                            onClick={() => this.handleAction(productID, res.id, "edit")}>
                                                        <i className="fa fa-pencil mr-2"
                                                           aria-hidden="true"/>Edit</Button>
                                                </td>
                                            </tr> : <tr className={"text-center"}>
                                                {controlSideRowSpan[index] === 0 ? null :
                                                    <td rowspan={controlSideRowSpan[index]}
                                                        className="align-middle">{res.controlSide} </td>}
                                                {blindPositionRowSpan[index] === 0 ? null :
                                                    <td rowspan={blindPositionRowSpan[index]}
                                                        className="align-middle">{res.blindPosition} </td>}

                                                <td>{res.operationGroup}</td>
                                                <td>{res.deduction} </td>
                                                <td>

                                                    <Button color={"primary"} size={"sm"}
                                                            className={"mr-2"}
                                                            onClick={() => this.handleAction(productID, res.id, "edit")}>
                                                        <i className="fa fa-pencil mr-2"
                                                           aria-hidden="true"/>Edit</Button>
                                                    <Button color={"danger"} size={"sm"}
                                                            disabled={isDeletingID === res.id}
                                                            onClick={() => this.handleAction(productID, res.id, "delete")}>
                                                        {(isDeletingID === res.id)
                                                            ? <Spinner color={"sm"} size={"sm"}/>
                                                            : <i className="fa fa-trash mr-2" aria-hidden="true"/>
                                                        }
                                                        Delete</Button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </Table>
                        }


                    </CardBody>
                </Card>

                <ProductConfigurationStockDeductionModal
                    isOpen={isOpenStockDeductionModal}
                    toggle={this.toggleStockDeductionModal}
                    stockObj={stockObj}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    loading={loading}
                    productID={this.state.productID}
                    layoutTypes={this.state.layoutTypes}
                />
                <ToastContainer/>
            </div>
        );
    }

}

