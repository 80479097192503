import React, { Component } from 'react';
import StockInventoryTransactionTypeService from "../../services/StockInventoryTransactionTypeService";
import {toast, ToastContainer} from "react-toastify";
import StockCostingMethodService from "../../services/StockCostingMethodService";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, Spinner, Table} from "reactstrap";
import queryString from "query-string";
import { getLocalDateStringFromUtcDateString, getDateString,handleErrorMessage} from "../../services/CommonService";
import classnames from "classnames";
export default class StockCostingMethodPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            StockCostingMethods: [],
            loading: true,
            saving: false
        }
        this.refresh = this.refresh.bind(this);
    }
    componentDidMount() {
        this.refresh();
    }
    setActive(newActive)
    {
        StockCostingMethodService.setActive(newActive).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.refresh();
                toast.success("Activated", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }
        });
    }
    refresh() {
        StockCostingMethodService.getAllStockCostingMethods().then(response => {
            this.setState({StockCostingMethods : response.data })
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }
    getHeadings() {
        let headings = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                labelClassName: "align-middle",
            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                labelClassName: "align-middle",
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                labelClassName: "align-middle",
            },
            {
                key: "lastActivatedOn",
                label: "Last Activated",
                type: "text",
                colSpan: 1,
                minWidth:100
            },
            {
                key: "updatedDate",
                label: "Last Updated Date",
                type: "text",
                colSpan: 1,
                minWidth: 100
            },
            {
                key: "updatedBy",
                label: "Last Updated By",
                type: "text",
                colSpan: 1,
                minWidth: 100,
            },
            {
                key: "activity",
                label: "Change Activity",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                maxWidth: 50,
            },

        ]
        return headings;
    }

    render() {
        let { StockCostingMethods } = this.state;
        let headings = this.getHeadings();
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Costing Methods</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader><h5>Stock Costing Methods</h5></CardHeader>
                    <CardBody>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(headings || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{ minWidth: item.minWidth }}>
                                                {item.label}
                                            </th>
                                        );
                                    })}
                                </tr>

                                </thead>
                                <tbody>
                                {(StockCostingMethods || []).map((item, index) =>
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.description}</td>
                                        <td className={"text-center"}>{getDateString(item.lastActivatedOn, 'DD/MM/YYYY')}</td>
                                        <td className={"text-center"}>{getDateString(item.updatedDate, 'DD/MM/YYYY')}</td>
                                        <td>{item.updatedBy}</td>
                                        <td className={"text-center"}>

                                                {item.isActive
                                                ?
                                                    <Button className="btn btn-sm btn-success" title="Click here to set Active"
                                                            onClick={()=>this.setActive(item.id)}>
                                                        <i className={"fa fa-check"}></i>&nbsp;
                                                        Active</Button>
                                                    :
                                                    <Button className="btn btn-sm " title="Click here to set Active"
                                                            onClick={()=>this.setActive(item.id)}
                                                    >Set Active</Button>
                                                }
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
                <ToastContainer />
            </div>
        )
    }
}