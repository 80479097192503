class StockUtil {
    static Instance() {
        return new StockUtil()
    }

    initStockObj(prodCode, isNonStock) {
        return {
            prodCode: prodCode,
            description: "",
            keywayStock: {
                prodCode: "",
                description: "",
                material: "",
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                colour: "",
                finish: "",
                dimensionUnitName: "mm",
                weightUnitName: "gm",
                whUnitName: "each",
                eachUnitName: "each",
                sellUnitName: "each",
                partType: "",
                model: "",
                sellQtyPrice: 0,
                sellUnitQty: 1,
                whUnitQty: 1,
                eachUnitPrice: 0,
                notes: "",
                swishConversionFactorPrice: 1,
                swishConversionFactorQty: 1,
                isRange: false,
                isNonStock: isNonStock,
                nonStockType: "",
            },
            wwStock: {
                prodCode: "",
                xRefCode: "",
                distribCode: "",
                supProdCode: "",
                stockGroupID: isNonStock ? "" : 1,
                type: "C",
                available: true,
                discontinued: false,
                dateDiscontinued: "",
                soldOut: false,
                dateSoldOut: "",
                description: "",
                cardNum: "N/S",
                priceUnit: "",
                supplierCost: 0,
                freightCost: 0,
                packagingCost: 0,
                dutyCost: 0,
                otherCosts: 0,
                avgCost: 0,
                currencyType: "AUD",
                currencyCost: 0,
                importUnit: 1,
                dutyRate: 0,
                leadTime: "",
                freight: 0,
                materialCost: 0,
                labourCost: 0,
                productionCost: 0,
                overheads: 0,
                packItem: false,
                packCode: "",
                minimum: 0,
                maximum: 0,
                qoh: 0,
                cut: 0,
                pick: 0,
                qbo: 0,
                qfo: 0,
                scan: 0,
                packSize: 0,
                upc: "",
                rep: "",
                supplierCode: isNonStock ? "00055" : "",
                supplierCode1: "",
                supplierCode2: "",
                supplierCode3: "",
                priceVol1: 0,
                priceVol2: 0,
                priceVol3: 0,
                price1: 0,
                sP1_Descrip: "",
                price2: 0,
                sP2_Descrip: "",
                price3: 0,
                sP3_Descrip: "",
                price4: 0,
                sP4_Descrip: "",
                price5: 0,
                sP5_Descrip: "",
                price6: 0,
                sP6_Descrip: "",
                taxRate: 10,
                taxRatePreGST: 0,
                mTD_UNITS: 0,
                mTD_SALES: 0,
                mTD_COST: 0,
                yTD_UNITS: 0,
                yTD_SALES: 0,
                yTD_COST: 0,
                lY_UNITS: 0,
                lY_SALES: 0,
                lY_COST: 0,
                lY2_UNITS: 0,
                lY2_SALES: 0,
                lY2_COST: 0,
                monthCount: 0,
                avgLast15: 0,
                avgLast6: 0,
                avgLast3: 0,
                suggOrd: 0,
                accepted: false,
                suggOrdAv: 0,
                acceptedAv: false,
                priority: false,
                tube: "",
                noAudit: false,
                p1Margin: 0,
                p2Margin: 0,
                p3Margin: 0,
                p4Margin: 0,
                p5Margin: 0,
                p6Margin: 0,
                bMNum: 0,
                reStockMin: 0,
                weight: 0,
                landedKey: 0,
                fileNum: "",
                newPriceLocal: 0,
                newPriceExport: 0,
                lastExchRate: 0,
                lY3_UNITS: 0,
                lY3_SALES: 0,
                lY3_COST: 0,
                bM_UNITS: 0,
                bM_COST: 0,
                bM_YTD_UNITS: 0,
                bM_YTD_COST: 0,
                notes: "",
                webAvailable: false,
                cardNum2: "",
                cardNum3: "",
                noCostUpdate: false,
                masterGroupID: 0,
                groupID: 0,
                subGroupID: 0,
                keywordID: 0,
                packedID: 0,
                qtyID: 0,
                sizeID: 0,
                weightID: 0,
                colourID: 0,
                productDescription: "",
                dateCreated: new Date(),
                sampleDate: "",
                userID: "CS",
                packTypeID: 0,
                incoTermID: 0,
                id: "",
                dateWrittenDown: "",
                isWrittenDown: false,
                allowDiscount: 0,
                buyPackSize: 0,
                bM_Flag: 0,
                length: 0,
                linearWidth: 0,
                isFabricGroup: false,
                fabricGroupCode: "",
                bindingCode: "",
                threadCode: "",
                braidCode: "",
                discontinuedBy: "",
                soldOutBy: "",
                writtenDownBy: "",
                preferredSupplier: null,
                hasDirectionalPattern: false,
                wizardId: null,
                group: "",
                qtyInCartons: 0,
                bMAccessoryPriceIndex: 1,
                hSCode: "",
                pickingTypeID: 0,
            },
        };
    }

    updateStock(stock, data) {
        stock.prodCode = data.prodCode;
        stock.description = data.description;

        stock.wwStock.prodCode = data.prodCode;
        stock.wwStock.xRefCode = data.xRefCode;
        stock.wwStock.distribCode = data.distribCode;
        stock.wwStock.supProdCode = data.supProdCode;
        stock.wwStock.stockGroupID = data.stockGroupID;
        stock.wwStock.type = data.type;
        stock.wwStock.available = data.available;
        stock.wwStock.discontinued = data.discontinued;
        stock.wwStock.dateDiscontinued = data.dateDiscontinued;
        stock.wwStock.soldOut = data.soldOut;
        stock.wwStock.dateSoldOut = data.dateSoldOut;
        stock.wwStock.description = data.description;
        stock.wwStock.cardNum = data.cardNum;
        stock.wwStock.priceUnit = data.priceUnit;
        stock.wwStock.supplierCost = data.supplierCost;
        stock.wwStock.freightCost = data.freightCost;
        stock.wwStock.packagingCost = data.packagingCost;
        stock.wwStock.dutyCost = data.dutyCost;
        stock.wwStock.otherCosts = data.otherCosts;
        stock.wwStock.avgCost = data.avgCost;
        stock.wwStock.currencyType = data.currencyType;
        stock.wwStock.currencyCost = data.currencyCost;
        stock.wwStock.importUnit = data.importUnit;
        stock.wwStock.dutyRate = data.dutyRate;
        stock.wwStock.leadTime = data.leadTime;
        stock.wwStock.freight = data.freight;
        stock.wwStock.materialCost = data.materialCost;
        stock.wwStock.labourCost = data.labourCost;
        stock.wwStock.productionCost = data.productionCost;
        stock.wwStock.overheads = data.overheads;
        stock.wwStock.packItem = data.packItem;
        stock.wwStock.packCode = data.packCode;
        stock.wwStock.minimum = data.minimum;
        stock.wwStock.maximum = data.maximum;
        stock.wwStock.qoh = data.qoh;
        stock.wwStock.cut = data.cut;
        stock.wwStock.pick = data.pick;
        stock.wwStock.qbo = data.qbo;
        stock.wwStock.qfo = data.qfo;
        stock.wwStock.scan = data.scan;
        stock.wwStock.packSize = data.packSize;
        stock.wwStock.upc = data.upc;
        stock.wwStock.rep = data.rep;
        stock.wwStock.supplierCode = data.supplierCode;
        stock.wwStock.supplierCode1 = data.supplierCode1;
        stock.wwStock.supplierCode2 = data.supplierCode2;
        stock.wwStock.supplierCode3 = data.supplierCode3;
        stock.wwStock.priceVol1 = data.priceVol1;
        stock.wwStock.priceVol2 = data.priceVol2;
        stock.wwStock.priceVol3 = data.priceVol3;
        stock.wwStock.price1 = data.price1;
        stock.wwStock.sP1_Descrip = data.sP1_Descrip;
        stock.wwStock.price2 = data.price2;
        stock.wwStock.sP2_Descrip = data.sP2_Descrip;
        stock.wwStock.price3 = data.price3;
        stock.wwStock.sP3_Descrip = data.sP3_Descrip;
        stock.wwStock.price4 = data.price4;
        stock.wwStock.sP4_Descrip = data.sP4_Descrip;
        stock.wwStock.price5 = data.price5;
        stock.wwStock.sP5_Descrip = data.sP5_Descrip;
        stock.wwStock.price6 = data.price6;
        stock.wwStock.sP6_Descrip = data.sP6_Descrip;
        stock.wwStock.taxRate = data.taxRate;
        stock.wwStock.taxRatePreGST = data.taxRatePreGST;
        stock.wwStock.mTD_UNITS = data.mTD_UNITS;
        stock.wwStock.mTD_SALES = data.mTD_SALES;
        stock.wwStock.mTD_COST = data.mTD_COST;
        stock.wwStock.yTD_UNITS = data.yTD_UNITS;
        stock.wwStock.yTD_SALES = data.yTD_SALES;
        stock.wwStock.yTD_COST = data.yTD_COST;
        stock.wwStock.lY_UNITS = data.lY_UNITS;
        stock.wwStock.lY_SALES = data.lY_SALES;
        stock.wwStock.lY_COST = data.lY_COST;
        stock.wwStock.lY2_UNITS = data.lY2_UNITS;
        stock.wwStock.lY2_SALES = data.lY2_SALES;
        stock.wwStock.lY2_COST = data.lY2_COST;
        stock.wwStock.monthCount = data.monthCount;
        stock.wwStock.avgLast15 = data.avgLast15;
        stock.wwStock.avgLast6 = data.avgLast6;
        stock.wwStock.avgLast3 = data.avgLast3;
        stock.wwStock.suggOrd = data.suggOrd;
        stock.wwStock.accepted = data.accepted;
        stock.wwStock.suggOrdAv = data.suggOrdAv;
        stock.wwStock.acceptedAv = data.acceptedAv;
        stock.wwStock.priority = data.priority;
        stock.wwStock.tube = data.tube;
        stock.wwStock.noAudit = data.noAudit;
        stock.wwStock.p1Margin = data.p1Margin;
        stock.wwStock.p2Margin = data.p2Margin;
        stock.wwStock.p3Margin = data.p3Margin;
        stock.wwStock.p4Margin = data.p4Margin;
        stock.wwStock.p5Margin = data.p5Margin;
        stock.wwStock.p6Margin = data.p6Margin;
        stock.wwStock.bMNum = data.bMNum;
        stock.wwStock.reStockMin = data.reStockMin;
        stock.wwStock.weight = data.weight;
        stock.wwStock.landedKey = data.landedKey;
        stock.wwStock.fileNum = data.fileNum;
        stock.wwStock.newPriceLocal = data.newPriceLocal;
        stock.wwStock.newPriceExport = data.newPriceExport;
        stock.wwStock.lastExchRate = data.lastExchRate;
        stock.wwStock.lY3_UNITS = data.lY3_UNITS;
        stock.wwStock.lY3_SALES = data.lY3_SALES;
        stock.wwStock.lY3_COST = data.lY3_COST;
        stock.wwStock.bM_UNITS = data.bM_UNITS;
        stock.wwStock.bM_COST = data.bM_COST;
        stock.wwStock.bM_YTD_UNITS = data.bM_YTD_UNITS;
        stock.wwStock.bM_YTD_COST = data.bM_YTD_COST;
        stock.wwStock.notes = data.notes;
        stock.wwStock.webAvailable = data.webAvailable;
        stock.wwStock.cardNum2 = data.cardNum2;
        stock.wwStock.cardNum3 = data.cardNum3;
        stock.wwStock.noCostUpdate = data.noCostUpdate;
        stock.wwStock.masterGroupID = data.masterGroupID;
        stock.wwStock.groupID = data.groupID;
        stock.wwStock.subGroupID = data.subGroupID;
        stock.wwStock.keywordID = data.keywordID;
        stock.wwStock.packedID = data.packedID;
        stock.wwStock.qtyID = data.qtyID;
        stock.wwStock.sizeID = data.sizeID;
        stock.wwStock.weightID = data.weightID;
        stock.wwStock.colourID = data.colourID;
        stock.wwStock.productDescription = data.productDescription;
        stock.wwStock.dateCreated = data.dateCreated;
        stock.wwStock.sampleDate = data.sampleDate;
        stock.wwStock.userID = data.userID;
        stock.wwStock.packTypeID = data.packTypeID;
        stock.wwStock.incoTermID = data.incoTermID;
        stock.wwStock.id = data.id;
        stock.wwStock.dateWrittenDown = data.dateWrittenDown;
        stock.wwStock.isWrittenDown = data.isWrittenDown;
        stock.wwStock.allowDiscount = data.allowDiscount;
        stock.wwStock.buyPackSize = data.buyPackSize;
        stock.wwStock.bM_Flag = data.bM_Flag;
        stock.wwStock.length = data.length;
        stock.wwStock.linearWidth = data.linearWidth;
        stock.wwStock.isFabricGroup = data.isFabricGroup;
        stock.wwStock.fabricGroupCode = data.fabricGroupCode;
        stock.wwStock.bindingCode = data.bindingCode;
        stock.wwStock.threadCode = data.threadCode;
        stock.wwStock.braidCode = data.braidCode;
        stock.wwStock.discontinuedBy = data.discontinuedBy;
        stock.wwStock.soldOutBy = data.soldOutBy;
        stock.wwStock.writtenDownBy = data.writtenDownBy;
        stock.wwStock.preferredSupplier = data.preferredSupplier;
        stock.wwStock.hasDirectionalPattern = data.hasDirectionalPattern;
        stock.wwStock.wizardId = data.wizardId;
        stock.wwStock.group = data.group;
        stock.wwStock.qtyInCartons = data.qtyInCartons;
        stock.wwStock.bMAccessoryPriceIndex = data.bMAccessoryPriceIndex;
        stock.wwStock.hSCode = data.hSCode;
        stock.wwStock.pickingTypeID = data.pickingTypeID;

        stock.keywayStock.prodCode = data.prodCode;
        if (data.keywayStock) {
            stock.keywayStock.prodCode = data.keywayStock.prodCode;
            stock.keywayStock.description = data.keywayStock.description ? data.keywayStock.description : data.description;
            stock.description = stock.keywayStock.description;
            stock.keywayStock.material = data.keywayStock.material;
            stock.keywayStock.length = data.keywayStock.length;
            stock.keywayStock.width = data.keywayStock.width;
            stock.keywayStock.height = data.keywayStock.height;
            stock.keywayStock.weight = data.keywayStock.weight;
            stock.keywayStock.colour = data.keywayStock.colour;
            stock.keywayStock.finish = data.keywayStock.finish;
            stock.keywayStock.dimensionUnitName = data.keywayStock.dimensionUnitName;
            stock.keywayStock.weightUnitName = data.keywayStock.weightUnitName;
            stock.keywayStock.whUnitName = data.keywayStock.whUnitName;
            stock.keywayStock.eachUnitName = data.keywayStock.eachUnitName;
            stock.keywayStock.sellUnitName = data.keywayStock.sellUnitName;
            stock.keywayStock.partType = data.keywayStock.partType;
            stock.keywayStock.model = data.keywayStock.model;
            stock.keywayStock.sellQtyPrice = data.keywayStock.sellQtyPrice;
            stock.keywayStock.sellUnitQty = data.keywayStock.sellUnitQty;
            stock.keywayStock.whUnitQty = data.keywayStock.whUnitQty;
            stock.keywayStock.eachUnitPrice = data.keywayStock.eachUnitPrice;
            stock.keywayStock.notes = data.keywayStock.notes ? data.keywayStock.notes : data.notes;
            stock.keywayStock.swishConversionFactorPrice = data.keywayStock.swishConversionFactorPrice;
            stock.keywayStock.swishConversionFactorQty = data.keywayStock.swishConversionFactorQty;
            stock.keywayStock.isRange = data.keywayStock.isRange;
            stock.keywayStock.isNonStock = data.keywayStock.isNonStock;
            stock.keywayStock.nonStockType = stock.keywayStock.isNonStock ? data.keywayStock.nonStockType : "";
        }
        return stock;
    }
}

export default StockUtil.Instance();