import axios from './axios';
import queryString from 'query-string';

class SearchAddressService {

    static Instance() {
        return new SearchAddressService();
    }

    searchCities(citySearchText, stateSearchText, postcodeSearchText) {
        let params = queryString.stringify({citySearchText, stateSearchText, postcodeSearchText});
        return axios.get('api/address/search/cities?' + params);
    }

    searchStates(citySearchText, stateSearchText, postcodeSearchText) {
        let params = queryString.stringify({citySearchText, stateSearchText, postcodeSearchText});
        return axios.get('api/address/search/states?' + params);
    }

    searchPostcodes(citySearchText, stateSearchText, postcodeSearchText) {
        let params = queryString.stringify({citySearchText, stateSearchText, postcodeSearchText});
        return axios.get('api/address/search/postcodes?' + params);
    }

    searchCountry(countrySearchText) {
        return axios.get('api/address/search/country?countrySearchText=' +countrySearchText);
    }

}

export default SearchAddressService.Instance();