import React, { useState } from 'react';
import { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardHeader, CardBody, Badge, Input } from 'reactstrap';
import { Tabs } from './../../../store/OrderGatewayTabs';
import classnames from 'classnames';
import GatewayOrders from '../../../components/OrderGatewayComponents/GatewayOrders';
import KeywaySalesOrdersGateway from '../../../components/OrderGatewayComponents/KeywaySalesOrdersGateway';
import OrderGatewaySearchOrderNumModal from '../../../components/modal/OrderGatewaySearchOrderNumModal'
import BranchService from '../../../services/BranchService';
import RepService from '../../../services/RepService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { findIndex, getDateString, getMomentObj, getDateObj, handleErrorMessage } from '../../../services/CommonService';
import OrderService from '../../../services/OrderService';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import UserService from '../../../services/UserService';

const date = new Date();
const tabs = Tabs;

export default class OrderGatewayPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[2].key,
            branches: [],
            reps: [],
            request: {
                pageRequest: {
                    currentPage: 1,
                    pageSize: 50
                },
                sortRequest: {
                    key: "ordNum",
                    direction: false
                },
                filterRequest: {
                    statusID: null,
                    salesOrderJobStatusId: null,
                    courier: "",
                    consignNum: "",
                    ordNum: null,
                    branch: null,
                    custOrdNum: "",
                    accountID: "",
                    startDate: moment(date).subtract(90, 'day').toDate(),
                    endDate: date,
                    JobRef: "",
                    delCompany: "",
                    OrderRef: "",
                    isQuote: null,
                    isProductSummarySearched: false,
                    productName: '',
                    productId: null,
                    debtorCategoryId: 0,
                    debtorCategoryLabel: '',
                    repCode: 0,
                    importSalesOrderSourceID: null,
                    importSourceOrderID: '',
                    importSalesOrderStatuses: null,
                }
            },
            orderCount: {
                sub: 0,
                pen: 0,
                quote: 0,
                can: 0,
                prod: 0,
                disp: 0,
                imp: 0,
            },
            selectedOrderNum: 0,
            isOpenOrderItemsModal: false,
            hasOrderGatewayReadonlyPrivilege: false,
            hasDebtorInvoiceCreditHoldReleasePrivilege: false,
            ordNum: '',
            userRole: ''
        };

        this.userService = new UserService();
        this.orderService = new OrderService();
        this.branchService = new BranchService();
        this.toggle = this.toggle.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleOrderCount = this.handleOrderCount.bind(this);
        this.getCountOfOrderByStatus = this.getCountOfOrderByStatus.bind(this);
        this.handleOrderNumber = this.handleOrderNumber.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);
        this.toggleOrderItemsModal = this.toggleOrderItemsModal.bind(this);
        this.clearTabSelection = this.clearTabSelection.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        let hasOrderGatewayReadonlyPrivilege = this.userService.hasPrivilege(currentUser, 'order-gateway-readonly');
        let hasDebtorInvoiceCreditHoldReleasePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-invoice-credit-hold-release');
        let userRole = currentUser.menu.role;
        this.setState({ hasOrderGatewayReadonlyPrivilege, hasDebtorInvoiceCreditHoldReleasePrivilege, userRole });

        let accountId = this.userService.getLoggedInUserAccountID();

        let { activeTab, request, selectedOrderNum } = this.state;
        let { filterRequest, pageRequest, sortRequest } = request;

        if (accountId) {
            filterRequest.accountID = accountId;
            this.setState({ request });
        }

        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            activeTab = searchParams.orderType ? searchParams.orderType : tabs[2].key;
            filterRequest.accountID = searchParams.accountID ? searchParams.accountID : filterRequest.accountID;
            filterRequest.branch = searchParams.branch ? parseInt(searchParams.branch) : filterRequest.branch;
            filterRequest.consignNum = searchParams.consignNum ? searchParams.consignNum : filterRequest.consignNum;
            filterRequest.ordNum = searchParams.ordNum ? searchParams.ordNum : filterRequest.ordNum;
            selectedOrderNum = parseInt(searchParams.selectedOrderNum);
            filterRequest.salesOrderJobStatusId = searchParams.salesOrderJobStatusId ? parseInt(searchParams.salesOrderJobStatusId) : filterRequest.salesOrderJobStatusId;
            filterRequest.startDate = searchParams.startDate ? getDateObj(searchParams.startDate, "DD/MM/YYYY") : filterRequest.startDate;
            filterRequest.endDate = searchParams.endDate ? getDateObj(searchParams.endDate, "DD/MM/YYYY") : filterRequest.endDate;
            filterRequest.debtorCategoryId = searchParams.debtorCategoryId ? parseInt(searchParams.debtorCategoryId) : filterRequest.debtorCategoryId;
            filterRequest.productName = searchParams.productName ? searchParams.productName : filterRequest.productName;
            filterRequest.isProductSummarySearched = searchParams.isProductSummarySearched ? searchParams.isProductSummarySearched : filterRequest.isProductSummarySearched;
            filterRequest.importSalesOrderSourceID = searchParams.importSalesOrderSourceID ? parseInt(searchParams.importSalesOrderSourceID) : filterRequest.importSalesOrderSourceID;
            filterRequest.importSourceOrderID = searchParams.importSourceOrderID ? searchParams.importSourceOrderID : filterRequest.importSourceOrderID;
            filterRequest.importSalesOrderStatuses = searchParams.importSalesOrderStatuses ? searchParams.importSalesOrderStatuses.split(",") : filterRequest.importSalesOrderStatuses;

            pageRequest.currentPage = searchParams.currentPage ? parseInt(searchParams.currentPage) : pageRequest.currentPage;
            pageRequest.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : pageRequest.pageSize;

            sortRequest.key = searchParams.sortRequestKey ? searchParams.sortRequestKey : sortRequest.key;
            sortRequest.direction = searchParams.sortRequestDirection ? searchParams.sortRequestDirection : sortRequest.direction;
            this.setState({ activeTab, request, selectedOrderNum });

        }
        this.getBranches();
        this.getReps();
    }

    handleOrderNumber(data) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("selectedOrderNum", data);
            searchParams.set("orderType", this.state.activeTab);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    getCountOfOrderByStatus() {
        let { request } = this.state;

        let filterRequest = cloneDeep(request.filterRequest);
        if (filterRequest.startDate) {
            filterRequest.startDate = getDateString(filterRequest.startDate, "DD/MM/YYYY");
        }
        if (filterRequest.endDate) {
            filterRequest.endDate = getDateString(filterRequest.endDate, "DD/MM/YYYY");
        }

        this.orderService.getCountOfOrderByStatus(filterRequest).then(res => {
            this.setState({ orderCount: res.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getBranches() {
        let { request } = this.state;
        let { filterRequest } = request;
        this.branchService.fetchBranches().then(response => {
            let branches = response.data;

            // default branch set to be BRISBANE
            let index = findIndex(branches, "shortName", "BRISBANE");
            if (index !== -1) {
                filterRequest.branch = branches[index].branchNum;
            }

            this.setState({ branches, filterRequest }, () => this.getCountOfOrderByStatus());
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getReps() {
        RepService.fetchReps().then(res => {
            this.setState({ reps: res.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            let { request } = this.state;
            let { filterRequest, pageRequest } = request;
            filterRequest.salesOrderJobStatusId = null;
            filterRequest.debtorCategoryId = 0;
            filterRequest.productName = "";
            filterRequest.productId = null;
            filterRequest.isProductSummarySearched = false;
            filterRequest.importSalesOrderSourceID = null;
            filterRequest.importSourceOrderID = '';
            filterRequest.importSalesOrderStatuses = null;
            pageRequest.currentPage = 1;
            this.setState({ activeTab: tab, request });

            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("orderType", tab);
                searchParams.delete("currentPage");
                searchParams.delete('salesOrderJobStatusId');
                searchParams.delete('orderNum');
                searchParams.delete('selectedOrderNum');
                searchParams.delete("isProductSummarySearched");
                searchParams.delete("productName");
                searchParams.delete("debtorCategoryId");
                searchParams.delete("debtorCategoryLabel");
                searchParams.delete("importSalesOrderSourceID");
                searchParams.delete("importSourceOrderID");
                searchParams.delete("importSalesOrderStatuses");

                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    };

    handleFilter(data) {
        let { request } = this.state;
        request.filterRequest = data;
        this.setState({ request });
    }

    handleOrderCount(type, count) {
        let { orderCount } = this.state;
        orderCount[type.toLowerCase()] = count;
        this.setState({ orderCount });
    }

    toggleOrderItemsModal(isOpenOrderItemsModal) {
        this.setState({ isOpenOrderItemsModal });
    }

    clearTabSelection() {
        this.setState({ activeTab: "" });
    }

    handleOrderClick(data) {
        let { activeTab, request } = this.state;
        let { filterRequest, pageRequest, sortRequest } = request;
        this.clearTabSelection();
        activeTab = data.orderType;
        filterRequest.salesOrderJobStatusId = data.salesOrderJobStatusId;
        filterRequest.branch = null;
        filterRequest.productName = '';
        filterRequest.productId = null;
        filterRequest.isProductSummarySearched = false;
        filterRequest.debtorCategoryId = 0;
        filterRequest.startDate = undefined;
        filterRequest.endDate = undefined;
        filterRequest.importSalesOrderSourceID = null;
        filterRequest.importSourceOrderID = '';
        filterRequest.importSalesOrderStatuses = null;
        pageRequest.currentPage = data.selectedPage;
        pageRequest.pageSize = data.pageSize;
        sortRequest.key = sortRequest.key;
        sortRequest.direction = sortRequest.direction;
        this.setState({ activeTab, request, selectedOrderNum: parseInt(data.ordnum), ordNum: data.ordnum });

        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("orderType", activeTab);
            searchParams.set("currentPage", data.selectedPage);
            searchParams.set('salesOrderJobStatusId', data.salesOrderJobStatusId);
            searchParams.set('selectedOrderNum', data.ordnum);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }

        this.toggleOrderItemsModal(false);

    }

    render() {
        let { activeTab, branches, request, orderCount, selectedOrderNum, isOpenOrderItemsModal, ordNum, reps, hasOrderGatewayReadonlyPrivilege, hasDebtorInvoiceCreditHoldReleasePrivilege, userRole } = this.state;
        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <h5>Order Gateway</h5>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <Button color={"primary"}
                                size={"sm"}
                                onClick={() => this.toggleOrderItemsModal(true)}>
                                <i className="fa fa-search mr-1 ml-1" aria-hidden="true" />
                                Search by Order No
                            </Button>

                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{ textDecoration: "none" }}
                                            size={"sm"}
                                            color={"link"}>{tab.label}&nbsp;
                                            <Badge color={"primary"}>{orderCount[tab.key.toLowerCase()]}</Badge>
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='QUOTE'>
                                {
                                    activeTab === "QUOTE" ?
                                        <GatewayOrders type={"QUOTE"} branches={branches} reps={reps}
                                            handleFilterFromParent={this.handleFilter} request={request}
                                            handleOrderCountFromParent={this.handleOrderCount}
                                            handleOrderNumberFromParent={this.handleOrderNumber}
                                            selectedOrderNumber={selectedOrderNum}
                                            hasOrderGatewayReadonlyPrivilege={hasOrderGatewayReadonlyPrivilege}
                                            hasDebtorInvoiceCreditHoldReleasePrivilege={hasDebtorInvoiceCreditHoldReleasePrivilege}
                                        />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='IMP'>
                                {
                                    activeTab === "IMP" ?
                                        <GatewayOrders type={"IMP"} branches={branches} reps={reps}
                                            handleFilterFromParent={this.handleFilter} request={request}
                                            handleOrderCountFromParent={this.handleOrderCount}
                                            handleOrderNumberFromParent={this.handleOrderNumber}
                                            selectedOrderNumber={selectedOrderNum}
                                            hasOrderGatewayReadonlyPrivilege={hasOrderGatewayReadonlyPrivilege}
                                            hasDebtorInvoiceCreditHoldReleasePrivilege={hasDebtorInvoiceCreditHoldReleasePrivilege}
                                            userRole={userRole}
                                        />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='SUB'>
                                {
                                    activeTab === "SUB" ?
                                        <KeywaySalesOrdersGateway type={"SUB"} branches={branches} reps={reps}
                                            handleFilterFromParent={this.handleFilter} request={request}
                                            handleOrderNumberFromParent={this.handleOrderNumber}
                                            handleOrderCountFromParent={this.handleOrderCount} selectedOrderNumber={selectedOrderNum}
                                            hasOrderGatewayReadonlyPrivilege={hasOrderGatewayReadonlyPrivilege}
                                            hasDebtorInvoiceCreditHoldReleasePrivilege={hasDebtorInvoiceCreditHoldReleasePrivilege}
                                        />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='PEN'>
                                {
                                    activeTab === "PEN" ?
                                        <GatewayOrders type={"PEN"} branches={branches} reps={reps}
                                            handleFilterFromParent={this.handleFilter} request={request}
                                            handleOrderCountFromParent={this.handleOrderCount}
                                            handleOrderNumberFromParent={this.handleOrderNumber}
                                            selectedOrderNumber={selectedOrderNum}
                                            hasOrderGatewayReadonlyPrivilege={hasOrderGatewayReadonlyPrivilege}
                                            hasDebtorInvoiceCreditHoldReleasePrivilege={hasDebtorInvoiceCreditHoldReleasePrivilege}
                                            userRole={userRole}
                                        />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='PROD'>
                                {
                                    activeTab === "PROD" ?
                                        <GatewayOrders type={"PROD"} branches={branches} reps={reps}
                                            handleFilterFromParent={this.handleFilter} request={request}
                                            handleOrderCountFromParent={this.handleOrderCount}
                                            handleOrderNumberFromParent={this.handleOrderNumber}
                                            selectedOrderNumber={selectedOrderNum}
                                            hasOrderGatewayReadonlyPrivilege={hasOrderGatewayReadonlyPrivilege}
                                            hasDebtorInvoiceCreditHoldReleasePrivilege={hasDebtorInvoiceCreditHoldReleasePrivilege}
                                            userRole={userRole}
                                        />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='DISP'>
                                {
                                    activeTab === "DISP" ?
                                        <GatewayOrders type={"DISP"} branches={branches} reps={reps}
                                            handleFilterFromParent={this.handleFilter} request={request}
                                            handleOrderCountFromParent={this.handleOrderCount}
                                            handleOrderNumberFromParent={this.handleOrderNumber}
                                            selectedOrderNumber={selectedOrderNum}
                                            hasOrderGatewayReadonlyPrivilege={hasOrderGatewayReadonlyPrivilege}
                                            hasDebtorInvoiceCreditHoldReleasePrivilege={hasDebtorInvoiceCreditHoldReleasePrivilege}
                                        />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='CAN'>
                                {
                                    activeTab === "CAN" ?
                                        <GatewayOrders type={"CAN"} branches={branches} reps={reps}
                                            handleFilterFromParent={this.handleFilter} request={request}
                                            handleOrderCountFromParent={this.handleOrderCount}
                                            handleOrderNumberFromParent={this.handleOrderNumber}
                                            selectedOrderNumber={selectedOrderNum}
                                            hasOrderGatewayReadonlyPrivilege={hasOrderGatewayReadonlyPrivilege}
                                            hasDebtorInvoiceCreditHoldReleasePrivilege={hasDebtorInvoiceCreditHoldReleasePrivilege}
                                        />
                                        : null
                                }
                            </TabPane>
                            {/*<TabPane tabId='REW'>*/}
                            {/*    {*/}
                            {/*        activeTab === "REW" ?*/}
                            {/*            <ReworkPage type={"REW"}*/}
                            {/*                handleOrderCountFromParent={this.handleOrderCount}                                        />*/}
                            {/*            : null*/}
                            {/*    }*/}
                            {/*</TabPane>*/}
                        </TabContent>
                    </CardBody>
                </Card>
                { /*tabs content are rendered statically bcoz child component was being called multiple times.*/}

                {
                    isOpenOrderItemsModal
                        ? <OrderGatewaySearchOrderNumModal
                            isOpen={isOpenOrderItemsModal}
                            toggle={this.toggleOrderItemsModal}
                            handleOrdernumClick={this.handleOrderClick}
                        />
                        : null
                }
            </div>
        );
    }
}