import React, {Component} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Input,
    Row,
    Spinner, Table
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import FileSaver from 'file-saver';
import classnames from "classnames";
import {handleErrorMessage} from '../../services/CommonService';
import importExportService from '../../services/ImportExportService';

export default class ProductConfigurationOptionImportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDryRun: false,
            isLoadingImport: false,
            isLoadingDownload: false,
            importResponse: {},
            configurationId: this.props.configurationId
        };

        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.importProductConfigurationOption = this.importProductConfigurationOption.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(key, change) {
        this.setState({[key]: change});
    }

    fileChangeHandler(event) {
        this.setState({selectedFile: event.target.files[0]});
    }

    importProductConfigurationOption() {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('isDryRun', this.state.isDryRun);
        this.setState({isLoadingImport: true});
        importExportService.importData('api/product/configuration/option/list/import?productConfigurationId=' + this.state.configurationId, data).then(response => {
            this.setState({isLoadingImport: false, importResponse: response.data});
            if (response.data.hasError) {
                toast.error("Error!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            } else {
                if(!this.state.isDryRun){
                    this.props.toggle(false, true);
                }
                toast.success("Success!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            this.setState({isLoadingImport: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    downloadTemplate() {
        this.setState({isLoadingDownload: true});

        importExportService.generateImportFormat('api/product/configuration/option/template/download').then(response => {
            this.setState({isLoadingDownload: false});
            FileSaver.saveAs(response.data, "ProductConfigurationOptionImportTemplate.xlsx");
        }).catch(error => {
            this.setState({isLoadingDownload: false});
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let {isLoadingDownload, isDryRun, isLoadingImport, importResponse} = this.state;
        let {isOpen, toggle} = this.props;
        return (
            <div>
                <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                    <ModalHeader toggle={() => toggle(!isOpen, false)}>
                        <h5 className="mb-0">Import options</h5>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Input type="file"
                                           name="file"
                                           id="file"
                                           onChange={this.fileChangeHandler}
                                           aria-label="Upload document"/>
                                </Col>
                            </Row>
                            <br/>
                            <Button color={"link"} size={"sm"}
                                    onClick={() => this.handleChange("isDryRun", !isDryRun)}>
                                <i className={classnames("mr-2", "fa", {
                                    "fa-check-square-o": isDryRun,
                                    "fa-square-o": !isDryRun
                                })}/>
                                Dry run
                            </Button>
                        </div>
                        <br/>
                        {
                            importResponse.hasError
                                ? <div>
                                    <hr/>
                                    <Table size={"sm"} hover={true} responsive>
                                        <thead>
                                        <tr>
                                            <td>Row</td>
                                            <td>Column</td>
                                            <td>Title</td>
                                            <td>Value</td>
                                            <td>Validation Message</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {((importResponse.rows) || []).map(row => {
                                            return <tr
                                                className={row.hasError ? "table-danger" : "table-success"}>
                                                <td>{row.rowIndex}</td>
                                                <td>{row.columnIndex}</td>
                                                <td>{row.inputTitle}</td>
                                                <td>{row.inputValue}</td>
                                                <td>{row.validationMessage}</td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                                : null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <div className={"text-right"}>
                            <Button color={"primary"} size={"sm"}
                                    className={"mr-2"}
                                    onClick={this.importProductConfigurationOption}
                                    disabled={isLoadingImport}>
                                {isLoadingImport
                                    ? <Spinner size="sm"
                                               className={"mr-2"}
                                               color={"white"}/>
                                    : <i className="fa fa-upload mr-2"/>
                                }
                                Upload</Button>
                            <Button color={"primary"} size={"sm"}
                                    outline={true}
                                    onClick={this.downloadTemplate}
                                    disabled={isLoadingDownload}>{isLoadingDownload
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           color={"primary"}/>
                                : <i className="fa fa-download mr-2"/>
                            }Download template</Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <br/>
                <ToastContainer/>
            </div>
        )
    }
}