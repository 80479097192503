import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import {round} from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_PANEL_TRACK_PRODCODE} from "../../../../../../store/AppConstants";


class PanelTrackUtil {

    static Instance() {
        return new PanelTrackUtil();
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "fabricColourOffRange":
                if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.fabricColourOffRange.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Fabric name can't be empty",
                    };
                }
                product.items[itemIndex].configuration.fabricColourOffRange.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.fabricColourOffRange.formError.isValid;
    }

    updateRules(key, product, itemIndex, order) {

        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                product.items[itemIndex].configuration.width.min = 1200;
                product.items[itemIndex].configuration.width.max = 6000;
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 1000;
                product.items[itemIndex].configuration.drop.max = 3000;
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = product.items[itemIndex].configuration.fabricType.selected.value.optionKey === "Off Range";
                product.items[itemIndex].configuration.fabricColour.selected.isSelectable = !product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable;
                break;
            case "stackingDirection":
                product.items[itemIndex].configuration.stackingDirection.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.opening.selected.value)) {
                    switch (product.items[itemIndex].configuration.opening.selected.value.optionKey) {
                        case "One Way Draw":
                            product.items[itemIndex].configuration.stackingDirection.selected.isSelectable = true;
                            break;
                        case "Centre Close":
                        case "Off Centre Close":
                        case "Front Panel Butting":
                            product.items[itemIndex].configuration.stackingDirection.selected.isSelectable = false;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.stackingDirection.selected.isSelectable) {
                    product.items[itemIndex].configuration.stackingDirection.selected.value = null;
                    product.items[itemIndex].configuration.stackingDirection.selected.dropdownValue = "";
                }
                break;

        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let stocks, label, attribute,
            width, drop, comment,
            deduction, condition1, condition2, condition3,
            sets;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        switch (key) {
            case "set":
                attribute = "SET";
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: PRODUCT_BUILDER_PANEL_TRACK_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [{
                    id: null,
                    description: "",
                    prodCode: isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? "09770" : product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                    keywayQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                    wastageFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1000,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true
                }];

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "opening":
                attribute = "Opening";
                label = "";
                comment = "";
                stocks = [];
                let trackProfile = product.items[itemIndex].configuration.trackProfile.selected.value.optionKey;
                let panel = product.items[itemIndex].configuration.panel.selected.value.optionKey;
                if (!isEmpty(product.items[itemIndex].configuration.opening.selected.value)) {
                    sets = product.items[itemIndex].configuration.opening.selected.value.sets.filter(s => {
                        return (width >= s.min && width <= s.max);
                    });
                    sets.forEach(s => {
                        //for trackProfile
                        switch (trackProfile){
                            case "3":
                            case "4":
                            case "5":
                            case "6":
                                product.items[itemIndex].configuration.opening.selected.numberOfTrack = parseInt(trackProfile);
                                break;
                            case "auto":
                            default:
                                product.items[itemIndex].configuration.opening.selected.numberOfTrack = parseInt(s.condition1);
                        }
                        //for panel
                        switch (panel) {
                            case "1":
                            case "2":
                            case "3":
                            case "4":
                            case "5":
                            case "6":
                            case "7":
                            case "8":
                            case "9":
                            case "10":
                            case "11":
                            case "12":
                                product.items[itemIndex].configuration.opening.selected.numberOfPanel = parseInt(panel);
                                break;
                            case "auto":
                            default:
                                product.items[itemIndex].configuration.opening.selected.numberOfPanel = parseInt(s.condition2);
                        }
                        
                        product.items[itemIndex].configuration.opening.selected.numberOfSpace = parseInt(s.condition3);

                        stocks = [...stocks, ...s.stocks];
                    });


                    product.items[itemIndex].configuration.opening.selected.panelWidth = ((product.items[itemIndex].configuration.opening.selected.numberOfSpace * 80) + width) / product.items[itemIndex].configuration.opening.selected.numberOfPanel;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.opening.selected.stocks = stocks;
                break;
            case "bracket":
                attribute = "Bracket";
                label = "";
                comment = "";
                stocks = [];

                condition1 = isEmpty(product.items[itemIndex].configuration.trackColour.selected.value) ? "" : product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                sets = product.items[itemIndex].configuration.bracket.selected.value.sets.filter(s => {
                    return (
                        (width >= s.min && width <= s.max)
                        && (s.condition1 ? s.condition1.includes(condition1) : true)
                    );
                });

                sets.forEach(s => {
                    stocks = [...stocks, ...s.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    product.items[itemIndex].configuration.bracket.selected.bracketQty = stockItem.keywayCalculatedQty;
                });
                product.items[itemIndex].configuration.bracket.selected.stocks = stocks;
                break;
            case "trackColour":
                attribute = "Track";
                label = "";
                comment = "";
                stocks = [];
                condition1 = isEmpty(product.items[itemIndex].configuration.opening.selected.value) ? "" : product.items[itemIndex].configuration.opening.selected.value.optionKey;
                condition2 = product.items[itemIndex].configuration.opening.selected.numberOfTrack;
                condition3 = product.items[itemIndex].configuration.trackColour.selected.value.optionKey;

                product.items[itemIndex].configuration.trackColour.options.forEach(o => {
                    if (o.optionKey === "BOM") {
                        sets = o.sets.filter(s => {
                            return (s.condition1 ? s.condition1.includes(condition1) : true)
                                && (s.condition2 ? parseInt(s.condition2) === condition2 : true)
                                && (s.condition3 ? s.condition3 === condition3 : true);
                        });
                    }
                });

                sets.forEach(s => {
                    stocks = [...stocks, ...s.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.trackColour.selected.stocks = stocks;
                break;
            case "bottomRail":
                attribute = "Bottom Rail";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                sets = product.items[itemIndex].configuration.bottomRail.selected.value.sets.filter(s => {
                    return (s.condition1 ? s.condition1.includes(condition1) : true);
                });
                sets.forEach(set => {
                    stocks = [...stocks, ...set.stocks];
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bottomRail.selected.stocks = stocks;
                break;
            case "consumables":
                attribute = "";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.consumables.selected.stocks = [];
                product.items[itemIndex].configuration.consumables.options.forEach(o => {
                    stocks = [];
                    switch (o.optionKey) {
                        case "Flick Stick":
                            condition1 = isEmpty(product.items[itemIndex].configuration.opening.selected.value) ? "" : product.items[itemIndex].configuration.opening.selected.value.optionKey;
                            sets = o.sets.filter(s => {
                                return (
                                    drop >= s.secondaryMin
                                    && drop <= s.secondaryMax
                                    && s.condition1.includes(condition1)
                                );
                            });
                            attribute = o.optionKey;
                            break;
                        case "SPLINE FLAT 15mm FOR ROUND B/RAIL":
                        case "SPLINE FLAT 10mm  FOR OVAL B/RAIL":
                            sets = o.sets.filter(set => set.condition1.includes(product.items[itemIndex].configuration.bottomRail.selected.value.optionKey));
                            attribute = "Consumables";
                            break;
                    }
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                    product.items[itemIndex].configuration.consumables.selected.stocks = [...product.items[itemIndex].configuration.consumables.selected.stocks, ...stocks];
                });
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("opening", product, itemIndex, order);
            product = this.updateStocks("bracket", product, itemIndex, order);
            product = this.updateStocks("trackColour", product, itemIndex, order);
            product = this.updateStocks("bottomRail", product, itemIndex, order);
            product = this.updateStocks("consumables", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.opening.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.opening.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bracket.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bracket.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.trackColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.trackColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRail.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.consumables.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.consumables.selected.stocks];
            }

            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor,
            numberOfTrack: product.items[itemIndex].configuration.opening.selected.numberOfTrack,
            numberOfPanel: product.items[itemIndex].configuration.opening.selected.numberOfPanel,
            numberOfSpace: product.items[itemIndex].configuration.opening.selected.numberOfSpace,
            panelWidth: product.items[itemIndex].configuration.opening.selected.panelWidth,
            bracketQty: product.items[itemIndex].configuration.bracket.selected.bracketQty,
        };


        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                /*
                * width(mm) drop(mm) fabricWidth(m)
                *
                * PANEL >> panel
                * FABRIC_DROP(M) >> fabricDrop
                * USAGE(LM) >> usage
                * */
                let panel = 0, fabricDrop = 0, usage = 0;

                //Calculating PANEL
                try {
                    panel = Math.ceil((width / 1000) / fabricWidth);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }

                //Calculating FABRIC_DROP
                fabricDrop = (drop + 400) / 1000;

                //Calculating FABRIC_DROP
                if (fabricDrop > fabricWidth) {
                    usage = round((panel * fabricDrop), 1);
                } else {
                    usage = width / 1000;
                }
                deduction = this.updateDeduction("fabric", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Opening":
            case "Track":
            case "Bottom Rail":
            case "Bracket":
            case "Consumables":
            default:
                deduction = this.updateDeduction(attribute, product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
        }
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }

    updateDeduction(key, product, itemIndex, stockInventoryItem) {
        let deduction = this.initDeduction();
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        switch (key) {
            case "fabric":
                deduction.deductionWidth = -75;

                switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                    case "Flat":
                        deduction.deductionDrop = 25;
                        break;
                    case "Oval":
                        deduction.deductionDrop = 15;
                        break;
                    case "Silent":
                        deduction.deductionDrop = 25;
                        break;
                    case "Sewn In Lathe":
                        deduction.deductionDrop = 40;
                        break;
                    case "Pocket":
                        deduction.deductionDrop = 30;
                        break;
                }

                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;

                break;
            case "Track":
                deduction.deductionWidth = -6;

                deduction.cutWidth = width + (deduction.deductionWidth);
                product.items[itemIndex].configuration.trackColour.selected.deduction = deduction;
                break;
            case "Bottom Rail":
                deduction.deductionWidth = -75;

                deduction.cutWidth = width + (deduction.deductionWidth);
                product.items[itemIndex].configuration.bottomRail.selected.deduction = deduction;
                break;

        }
        return deduction;
    }

    generateBOMCode(product, itemIndex) {
        let result = "";
        if (!isEmpty(product.items[itemIndex].configuration.opening.selected.value)) {
            switch (product.items[itemIndex].configuration.opening.selected.value.optionKey) {
                case "One Way Draw":
                    result = "OWD";
                    break;
                case "Centre Close":
                case "Off Centre Close":
                case "Front Panel Butting":
                    result = "CC";
                    break;
            }
        }
        result = result + "_" + product.items[itemIndex].configuration.fabricType.selected.value.category;
        return result;
    }


    replaceFormulaParamsWithValues(stockQtyExpression, params) {

        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        stockQtyExpression = stockQtyExpression.replaceAll("@NumberOfTrack", params.numberOfTrack);
        stockQtyExpression = stockQtyExpression.replaceAll("@NumberOfPanel", params.numberOfPanel);
        stockQtyExpression = stockQtyExpression.replaceAll("@NumberOfSpace", params.numberOfSpace);
        stockQtyExpression = stockQtyExpression.replaceAll("@PanelWidth", params.panelWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@BracketQty", params.bracketQty);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fabricColourOffRange.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fabricColourOffRange.formError.message);
        }

        return errors;
    }

    toSalesOrderItemPanelTrackRequest(pg, itemIndex, order) {
        let defaultDeduction = this.initDeduction();
        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.bottomRail.selected.deduction)) {
            pg.items[itemIndex].configuration.bottomRail.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.trackColour.selected.deduction)) {
            pg.items[itemIndex].configuration.trackColour.selected.deduction = defaultDeduction;
        }


        let salesOrderItemPanelTrack = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: isEmpty(pg.items[itemIndex].configuration.set.selected.value.priceMatrix) ? "" : pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: isEmpty(pg.items[itemIndex].configuration.set.selected.value.widthSet) ? "" : pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: isEmpty(pg.items[itemIndex].configuration.set.selected.value.dropSet) ? "" : pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: pg.items[itemIndex].configuration.location.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: pg.items[itemIndex].configuration.width.selected.value,
            drop: pg.items[itemIndex].configuration.drop.selected.value,

            fabricType: pg.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.value) ? "" : pg.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            fabricColourOffRange: pg.items[itemIndex].configuration.fabricColourOffRange.selected.value,
            opening: isEmpty(pg.items[itemIndex].configuration.opening.selected.value) ? "" : pg.items[itemIndex].configuration.opening.selected.value.optionKey,
            trackColour: isEmpty(pg.items[itemIndex].configuration.trackColour.selected.value) ? "" : pg.items[itemIndex].configuration.trackColour.selected.value.optionKey,
            stackingDirection: isEmpty(pg.items[itemIndex].configuration.stackingDirection.selected.value) ? "" : pg.items[itemIndex].configuration.stackingDirection.selected.value.optionKey,
            mount: isEmpty(pg.items[itemIndex].configuration.mount.selected.value) ? "" : pg.items[itemIndex].configuration.mount.selected.value.optionKey,
            bracket: isEmpty(pg.items[itemIndex].configuration.bracket.selected.value) ? "" : pg.items[itemIndex].configuration.bracket.selected.value.optionKey,
            bottomRail: isEmpty(pg.items[itemIndex].configuration.bottomRail.selected.value) ? "" : pg.items[itemIndex].configuration.bottomRail.selected.value.optionKey,
            bottomRailColour: isEmpty(pg.items[itemIndex].configuration.bottomRailColour.selected.value) ? "" : pg.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey,
            numberOfTrack: pg.items[itemIndex].configuration.opening.selected.numberOfTrack,
            numberOfPanel: pg.items[itemIndex].configuration.opening.selected.numberOfPanel,
            numberOfSpace: pg.items[itemIndex].configuration.opening.selected.numberOfSpace,
            panelWidth: pg.items[itemIndex].configuration.opening.selected.panelWidth,
            bracketQty: pg.items[itemIndex].configuration.bracket.selected.bracketQty,

            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,
            bottomRailDeductionWidth: pg.items[itemIndex].configuration.bottomRail.selected.deduction.deductionWidth,
            trackDeductionWidth: pg.items[itemIndex].configuration.trackColour.selected.deduction.deductionWidth,

            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,
            bottomRailCutWidth: pg.items[itemIndex].configuration.bottomRail.selected.deduction.cutWidth,
            trackCutWidth: pg.items[itemIndex].configuration.trackColour.selected.deduction.cutWidth,
            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemPanelTrack;
    }
}

export default PanelTrackUtil.Instance();