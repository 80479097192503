import React, { Component } from 'react';
import SalesOrderService from "../../services/sales/SalesOrderService";
import { isAllSelected, convertUtcDateToLocalDate, findIndex ,handleErrorMessage} from '../../services/CommonService';
import OrderTable from "../../components/sales/orders/OrderTable";
import Sorter from "../../components/sorters/Sorter";
import Filter from "../../components/filters/Filter";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileSaver from 'file-saver';
import {Link as Link} from "react-router-dom";
import {Card, CardBody,ButtonGroup, Button,Badge,Collapse, Row, Col
} from 'reactstrap';
import moment from 'moment';
let startDateString = moment().startOf('month').format('DD/MM/YYYY');
let endDateString = moment().endOf('month').format('DD/MM/YYYY');
let startEndDate = startDateString + "," + endDateString;


let allFilters = [{
    name: "OrderNo",
    displayName: "Order No",
    value: "",
    defaultValue: "",
    displayValue: "",
    valid: true,
    sortIndex: 10
}, {
    name: "Customer",
    displayName: "Customer",
    value: "",
    defaultValue: "",
    displayValue: "",
    valid: true,
    sortIndex: 20
}, {
    name: "CreatedDateBetween",
    displayName: "Created Date Between",
    value: startEndDate,
    defaultValue: "",
    displayValue: startEndDate,
    valid: true,
    sortIndex: 30
}, /*{
    name: "SalesOrderStatus",
    displayName: "Order Status",
    value: "0",
    defaultValue: "",
    displayValue: "Active",
    valid: true,
    sortIndex: 40
}, */{
    name: "Rep",
    displayName: "Rep",
    value: "",
    defaultValue: "",
    displayValue: "All",
    valid: true,
    sortIndex: 60
}, {
    name: "Branch",
    displayName: "Branch",
    value: "",
    defaultValue: "",
    displayValue: "All",
    valid: true,
    sortIndex: 70
}];

const allSorters = [
    {
        name: "CreatedDate",
        displayName: "Created Date",
        valid: true,
        sortIndex: 10
    },
    {
        name: "OrderNum",
        displayName: "Order No",
        valid: true,
        sortIndex: 20
    },
    {
        name: "InvoiceAmount",
        displayName: "Invoice Amount",
        valid: true,
        sortIndex: 30
    }
];

export default class SalesOrdersPage extends Component {
    constructor(props) {
        super(props);
        let defaultAccountId = "";
        if (props.location.state) {
            if (props.location.state.accountId) {
                defaultAccountId = props.location.state.accountId;
            }
        }
        let index = findIndex(allFilters, "name", "Customer");
        if (index > -1) {
            allFilters[index].defaultValue = defaultAccountId;
            allFilters[index].value = defaultAccountId;
            allFilters[index].displayValue = defaultAccountId;
        }

        

        this.state = {
            filters: allFilters,
            sorter: allSorters,
            openFollowUpOrderModal: false,
            openCancellationOrderModal: false,
            openConvertToPickingSlipOrderModal: false,
            orders: {
                filter: {
                    accountId: defaultAccountId,
                    statusID: 0,
                    orderNum: "",
                    query: "",
                    createdDateBetween: startEndDate,
                    repCode: "",
                    branchNum: "",
                    selectedPage: 1,
                    pageSize: 25,
                    totalRecords: 0,
                    toggle: false
                },
                sort: {
                    key: "",
                    ascending: true,
                    toggle: false
                },
                records: [],
                loading: false,
            },
            downloading: false
        };
       
        this.SalesOrderService = new SalesOrderService();
        this.exportOrders = this.exportOrders.bind(this);
        this.getSalesOrders = this.getSalesOrders.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateSorters = this.updateSorters.bind(this);
        this.toggleFilterCollapse = this.toggleFilterCollapse.bind(this);
        this.toggleSorterCollapse = this.toggleSorterCollapse.bind(this);
        this.selectOrder = this.selectOrder.bind(this);
       
    }
    componentDidMount() {
        this.refresh();
    }
    refresh() {
        this.getSalesOrders(false);
    }
    toggleFilterCollapse() {
        let orders = this.state.orders;
        orders.sort.toggle = false;

        orders.filter.toggle = !orders.filter.toggle;
        this.setState({ orders});
    }
    toggleSorterCollapse() {
        let orders = this.state.orders;
        orders.filter.toggle = false;
        orders.sort.toggle = !orders.sort.toggle;
        this.setState({ orders});
    }

    getSelectedOrdersStatus(filters){
        let index = findIndex(filters, "name", "SalesOrderStatus");
        let result = "";
        if (index > -1) {
            result = filters[index].displayValue;
        }
        return result;
    }

    getSalesOrders(loadMore) {
        let { orders, filters} = this.state;

        if (orders.loading) {
            let self = this;
            setTimeout(function () {
                self.getSalesOrders(loadMore);
            }, 2000);
        } else {
            orders.loading = true;
            this.setState({ orders});

            orders = this.extractFilterValues(filters, orders);
            let selectedOrderStatus = this.getSelectedOrdersStatus(filters);

            this.SalesOrderService.fetchSalesOrder(orders, loadMore).then(response => {
                orders.loading = false;

                let data = response.data;
                orders.filter.selectedPage = data.selectedPage;
                orders.filter.pageSize = data.pageSize;
                orders.filter.totalRecords = data.totalRecords;

              

                if (!loadMore) {
                    orders.records = [];
                }

                if (data.records) {
                    for (let i in data.records) {
                        data.records[i].selected = false;
                        if (data.records[i].required) {
                            data.records[i].required = convertUtcDateToLocalDate(data.records[i].required);
                            if (data.records[i].required) {
                                data.records[i].required = data.records[i].required.toDate();
                            }
                        }
                        orders.records.push(data.records[i]);
                    }
                }

                this.setState({ orders});
            }).catch(error => {
                alert(handleErrorMessage(error));
            });
        }

    }

    extractFilterValues(filters, orders) {
        for (let i  in filters) {
            switch (filters[i].name) {
                case "CreatedDateBetween":
                    orders.filter.createdDateBetween = filters[i].value;
                    break;
                case "Customer":
                    orders.filter.accountId = filters[i].value;
                    break;
                case "OrderNo":
                    orders.filter.orderNum = filters[i].value;
                    break;
                case "Keyword":
                    orders.filter.query = filters[i].value;
                    break;
                case "SalesOrderStatus":
                    orders.filter.statusID = filters[i].value;
                    break;
                case "Rep":
                    orders.filter.repCode = filters[i].value;
                    break;
                case "Branch":
                    orders.filter.branchNum = filters[i].value;
                    break;
            }
        }

        return orders;
    }

    updateFilters(filters, applyFilterFlag) {
        if (applyFilterFlag) {
            this.setState({filters}, () => {
                this.getSalesOrders(false);
            });
        } else {
            this.setState({filters});
        }
    }

    updateSorters(selectedSorter, reload) {
        let orders = this.state.orders;
        orders.sort.key = selectedSorter.key;
        orders.sort.ascending = selectedSorter.ascending;
        if (reload) {
            this.setState({ orders}, () => {
                this.getSalesOrders(false);
            });
        } else {
            this.setState({ orders});
        }
    }

    selectOrder(index, flag) {
        let { orders} = this.state;
        if (index > -1) {
            orders.records[index].selected = !orders.records[index].selected;
        } else {
            for (let i in orders.records) {
                orders.records[i].selected = flag;
            }
        }
        this.setState({ orders});
    }

    exportOrders() {
        let { orders, filters, downloading} = this.state;
        if (!downloading) {
            orders = this.extractFilterValues(filters, orders);

            this.setState({ downloading: true });
            this.SalesOrderService.exportOrders(orders).then(response => {
                this.setState({downloading: false});
                FileSaver.saveAs(response.data, "sales-orders.csv");

            }).catch(error => {
                this.setState({downloading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    render() {
        let { orders, filters, downloading} = this.state;

        let selectedOrders = [];
        let allQuotesSelectedFlag = false;
        if (orders.records) {
            allQuotesSelectedFlag = isAllSelected(orders.records, "selected");
            selectedOrders = orders.records.filter((r) => {
                return r.selected
            });
        }
        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h5>Sales Orders&nbsp;
                            <Link className={"btn btn-primary"} to="/coming-soon"><i
                                className="fa fa-plus"
                                aria-hidden="true"/>&nbsp;New
                                order</Link>
                        </h5>
                    </Col>


                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={" text-right mb-1"}>
                            <div><Badge color="light"
                                pill>{orders.loading ? <i className="fa fa-circle-o-notch fa-spin"
                                    aria-hidden="true" /> : orders.filter.totalRecords}</Badge>Items
                                found&nbsp;
                                <ButtonGroup>

                                    <Button
                                        outline={!orders.filter.toggle}
                                        color={"primary"}
                                        className={"btn"}
                                        title={"Filter"}
                                        onClick={this.toggleFilterCollapse}>
                                        <i className="fa fa-filter"/>
                                    </Button>

                                    <Button outline={!orders.sort.toggle}
                                            color={"primary"}
                                            className={"btn"}
                                            title={"Sort"}
                                            onClick={this.toggleSorterCollapse}>
                                        <i className="fa fa-sort"/>
                                    </Button>
                                    <Button title={"Export"} outline={!downloading} className={"btn"}
                                            color={"success"}
                                            onClick={this.exportOrders}>
                                        {downloading ? <i className="fa fa-circle-o-notch fa-spin"
                                                          aria-hidden="true"/> :
                                            <i className="fa fa-file-excel-o" aria-hidden="true"/>}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>



                </Row>

                <Collapse isOpen={orders.filter.toggle}>
                    <Filter filters={filters}
                        loading={orders.loading}
                            onChange={this.updateFilters}
                            applyFilters={this.getSalesOrders}/>
                </Collapse>

                <Collapse isOpen={orders.sort.toggle}>
                    <Sorter sorters={allSorters}
                        loading={orders.loading}
                            onChange={this.updateSorters}
                            applySorters={this.getSalesOrders}
                    />
                </Collapse>

               

                {orders.filter.totalRecords > 0 ?
                    <Card>
                        <CardBody>
                            {selectedOrders.length > 0 ?

                                <Row>
                                    <Col>
                                        <div className={"text-left"}> {selectedOrders.length} selected</div>
                                    </Col>
                                    <Col>
                                        <div className={"text-right"}>
                                            <Button color={"secondary"}
                                                    className={"d-inline "}
                                                    onClick={() => this.selectOrder(-1, false)}><i
                                                className="fa fa-times"
                                                aria-hidden="true"/>&nbsp;
                                                clear
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                : null
                            }

                            <OrderTable orders={orders}
                                        selectOrder={this.selectOrder}
                                        loadMore={this.getSalesOrders}/>
                        </CardBody>
                    </Card>
                    :null}
                <ToastContainer/>
            </div>
        );
    }

}