import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import {toast, ToastContainer} from 'react-toastify';
import StockService from '../../services/StockService';
import {handleErrorMessage} from "../../services/CommonService";

export default class SearchDebtorInvoiceItemStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderNum:props.orderNum,
            stock: props.selected,
            stocks: [],
            loading: {stocks: false}
        }
        ;
        this.stockService = new StockService();

        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.stock ? this.state.stock.description : "");
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.orderNum !== this.state.orderNum){
            if (nextProps.stock && this.state.stock && nextProps.stock.description !== this.state.stock.description) {
                //load externally selectedItem
                this.setState({orderNum: nextProps.orderNum}, ()=>{
                    this.fetchOptions(nextProps.stock.description);
                });
            }else{
                this.fetchOptions(this.state.stock ? this.state.stock.description : "");
            }
        }
    }


    fetchOptions(inputValue, callback) {
        let stocks = [];
        let {stock, loading} = this.state;

        loading.stocks = true;
        this.setState({loading});
        this.stockService.getStockDropdownByDebtorInvoice(inputValue, this.state.orderNum).then(response => {

            let data = response.data;
            for (let i in data) {
                let option = {
                    label: data[i].prodCode + (data[i].description ? " | " + data[i].description : ""),
                    value: data[i].prodCode,
                    id: data[i].prodCode,
                    data: data[i]
                };
                stocks.push(option);
                if (stock && stock.prodCode) {
                    if (option.value === stock.prodCode) {
                        this.handleChange(option); // for default purpose
                    }
                }
            }
            loading.stocks = false;
            this.setState({stocks, loading});
            if (callback) {
                return callback(stocks)
            } else {
                return stocks;
            }
        }).catch(error => {
                loading.stocks = false;
                this.setState({loading});
                toast.error(handleErrorMessage(error));
            }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            if (inputValue && inputValue.length <= 1) {
                return this.fetchOptions("", callback)
            }
            callback(this.state.stocks);

        }
    };

    handleChange(change) {
        if (change) {
            this.stockService.getStock(change.value).then(response => {
                let selected = response.data;
                selected.value = selected.prodCode;
                selected.label = selected.prodCode + (selected.description ? " | " + selected.description : "");
                this.setState({stock: selected});
                this.props.handleSelection(response.data);
            }).catch(error => {
                toast.error(handleErrorMessage(error));
            })
        } else {
            this.setState({stock: change});
        }

    };


    render() {
        let {stock, loading, stocks} = this.state;
        return (
            <AsyncSelect
                cacheOptions
                isLoading={loading.stocks}
                isClearable={true}
                defaultOptions={stocks}
                loadOptions={this.prepareFetchOptions}
                onChange={this.handleChange}
                value={stock}
                placeholder={"Search..."}
            />
        )
    }
}