import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import PurchaseHistoryService from '../../../services/PurchaseHistoryService';
import CRMService from "../../../services/crm/CRMService";
import NumberFormat from "react-number-format";

export default class PurchaseHistoryStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountID: props.accountID,
            growth: 0,
            growthInPercentage: 0,
            mtd: 0,
            ytd: 0,
            ltd: 0,
            ly: 0,
            loading: false
        };
        this.crmService = new CRMService();
        this.purchaseHistoryService = new PurchaseHistoryService();
        this.numberWithCommas = this.numberWithCommas.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, function () {
                this.refresh();
            });
        }
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    refresh() {
        if (this.props.accountID) {
            this.setState({loading: true});
            this.crmService.getCustomerTrading_salesInformation(this.props.accountID).then(response => {
                let responseData = response.data.item1;

                //let growth = responseData.ytd - responseData.ly;
                //let growthInPercentage = (growth * 100) / responseData.ly;
                //growthInPercentage = Math.round(growthInPercentage);

                this.setState({
                    mtd: responseData.mtd,
                    ytd: responseData.ytd,
                    ltd: responseData.ly,
                    ly: responseData.ly,
                    //growth: growth,
                    //growthInPercentage: growthInPercentage,

                }, () => { this.getCustomerLastYearToCurrentDate_Record() });
            }).catch(error => {
                console.log(error.data);
                this.setState({loading: false});
            });
        }
    }


    getCustomerLastYearToCurrentDate_Record() {
        let {ytd, ltd} = this.state;
        this.crmService.getCustomerLastYearToCurrentDate_Data(this.props.accountID).then(response => {
            let responseData = response.data;
            let sum = 0;
            for (let i = 0; i < responseData.length; i++) {
                sum += responseData[i].sumTotal;
            }
            ltd = sum;
            let growth = ytd - ltd;
            let growthInPercentage = (growth * 100) / ytd;
            growthInPercentage = Math.round(growthInPercentage);

            this.setState({
                ltd,
                growth: growth,
                growthInPercentage: growthInPercentage,
                loading: false
            })
        }).catch(error => {
            console.log(error.data);
            this.setState({ loading: false });
        });

    }

    render() {
        let {loading} = this.state;
        if (loading) {
            return null;
        }
        return (
            <Row>
                <Col sm="3">
                    <Card className={"ds-9"}>
                        <CardBody>
                            <div className="text-center">
                                <h4>
                                    <small>Month to Date</small>
                                    <div>
                                        <NumberFormat value={this.state.mtd}
                                                      displayType={'text'}
                                                      decimalScale={0}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </div>
                                </h4>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="3">
                    <Card className={"ds-9"}>
                        <CardBody>
                            <div className="text-center">
                                <h4>
                                    <small>FY to Date</small>
                                    <div>
                                        <NumberFormat value={this.state.ytd}
                                                      displayType={'text'}
                                                      decimalScale={0}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </div>
                                </h4>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="3">
                    <Card className={"ds-9"}>
                        <CardBody>
                            <div className="text-center">
                                <h4>
                                    <small>Last FY to Date</small>
                                    <div>
                                        <NumberFormat value={this.state.ltd}
                                                      displayType={'text'}
                                                      decimalScale={0}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </div>
                                </h4>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="3">
                    <Card className={"ds-9"}>
                        <CardBody>
                            <div className="text-center">
                                <h4>
                                    <small>Growth</small>
                                    <div>
                                        <span className="mr-1" style={this.state.growth > 0 ? {
                                            color: 'green'
                                        } : {display: 'none'}}>&#8593;  ({this.state.growthInPercentage})%</span>
                                        ${this.numberWithCommas(this.state.growth.toFixed(0))}
                                        <span className="ml-1" style={this.state.growth < 0 ? {
                                            color: 'red'
                                        } : {display: 'none'}}>&#8595; ({this.state.growthInPercentage}%)</span>
                                    </div>
                                </h4>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>);

    }
}
