import React, {Component} from 'react';
import {Input, Row, Col, Label} from 'reactstrap';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import classnames from 'classnames';
import SearchPostcode from '../../components/search/SearchPostcode';
import SearchCity from '../../components/search/SearchCity';
import SearchState from '../../components/search/SearchState';


const filterConditions =
    [
        {'label': 'Search Duplicate Emails', 'value': 'Duplicate Emails'},
        {'label': 'Search Empty Customers', 'value': 'Empty Customers'},
        {'label': 'Search Empty Emails', 'value': 'Empty Emails'},
        {'label': 'Search Empty Names', 'value': 'Empty Names'},
        {'label': 'Search Empty Phones', 'value': 'Empty Phones'},
        {'label': 'Search Empty Address1', 'value': 'Empty Address1'},
        {'label': 'Search Empty Address2', 'value': 'Empty Address2'},
        {'label': 'Search Empty Address3', 'value': 'Empty Address3'},
        {'label': 'Search Empty Address4', 'value': 'Empty Address4'},
        {'label': 'Search Empty City', 'value': 'Empty City'},
        {'label': 'Search Empty State', 'value': 'Empty State'},
        {'label': 'Search Empty PostCode', 'value': 'Empty PostCode'},
        {'label': 'Search Empty Country', 'value': 'Empty Country'}
    ];
const countryOption = [
    {
        label: "Australia",
        value: "Australia"
    },
    {
        label: "New Zealand",
        value: "New Zealand"
    },
    {
        label: "Others",
        value: "Others"
    }
];
export default class SearchBannerAddressMaster extends Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(debtorAccountID) {
        this.props.handleChange(debtorAccountID, 'debtorAccountID');
    }

    render() {
        let {filterRequest} = this.props;
        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Label>Customer</Label>
                        <div style={{zIndex: '2', position: 'relative'}}>
                            <SearchDebtorAcccount
                                handleAccountChange={this.handleChange}
                                selectedAccountID={filterRequest.debtorAccountID}
                                defaultAccountID={filterRequest.debtorAccountID}
                            />
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Label>Account Status</Label>
                        <div>
                            <Input type={"select"}
                                   placeholder={"Search..."}
                                   name={"accountStatus"}
                                   value={filterRequest.accountStatus}
                                   onChange={(e) => this.props.handleChange(e.target.value, "accountStatus")}>

                                <option value="">All</option>
                                {this.props.debtorAccountStatus.map((status, index) =>
                                    <option key={index} value={status.description}> {status.description} </option>
                                )}

                            </Input>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Label>Filter By Conditions</Label>
                        <div>
                            <Input type={"select"}
                                   name={"condition"}
                                   value={filterRequest.condition ? filterRequest.condition : ""}
                                   onChange={(e) => this.props.handleChange(e.target.value, "condition")}>
                                <option value={null}>All</option>
                                {(filterConditions || []).map((option, index) =>
                                    <option key={index} value={option.value}>{option.label}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label>Company</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                   placeholder={"Search..."}
                                   name={"company"}
                                   value={filterRequest.company}
                                   onChange={(e) => this.props.handleChange(e.target.value, "company")}/>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                    <Label>Contact Name</Label>
                    <div className={"text-center"}>
                        <Input type={"text"}
                               placeholder={"Search..."}
                               name={"contactName"}
                               value={filterRequest.contactName}
                               onChange={(e) => this.props.handleChange(e.target.value, "contactName")}/>
                    </div>
                </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label>Email</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                   placeholder={"Search..."}
                                   name={"email"}
                                   value={filterRequest.email}
                                   onChange={(e) => this.props.handleChange(e.target.value, "email")}/>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label>Phone</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                   placeholder={"Search..."}
                                   name={"phone"}
                                   value={filterRequest.phone}
                                   onChange={(e) => this.props.handleChange(e.target.value, "phone")}/>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>Address1</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                   placeholder={"Search..."}
                                   name={"address1"}
                                   value={filterRequest.address1}
                                   onChange={(e) => this.props.handleChange(e.target.value, "address1")}/>
                        </div>
                    </Col>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>Address2</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                   placeholder={"Search..."}
                                   name={"address2"}
                                   value={filterRequest.address2}
                                   onChange={(e) => this.props.handleChange(e.target.value, "address2")}/>
                        </div>
                    </Col>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>Address3</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                   placeholder={"Search..."}
                                   name={"address3"}
                                   value={filterRequest.address3}
                                   onChange={(e) => this.props.handleChange(e.target.value, "address3")}/>
                        </div>
                    </Col>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>Address4</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                   placeholder={"Search..."}
                                   name={"address4"}
                                   value={filterRequest.address4}
                                   onChange={(e) => this.props.handleChange(e.target.value, "address4")}/>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>City</Label>
                        <div>
                            <SearchCity
                                handleSelection={(selectedCity) => this.props.handleChange(selectedCity, "city")}
                                selected={filterRequest.city || ''}
                                defaultSelected={'All'}
                                filters={{state: filterRequest.state || '', postcode: filterRequest.postCode || ''}}
                            />
                        </div>

                    </Col>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>State</Label>
                        <div>
                            <SearchState
                                handleSelection={(selectedState) => this.props.handleChange(selectedState, "state")}
                                selected={filterRequest.state || ''}
                                defaultSelected={'All'}
                                filters={{city: filterRequest.city || '', postcode: filterRequest.postCode || ''}}
                            />
                        </div>
                    </Col>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>PostCode</Label>
                        <div>
                            <SearchPostcode
                                handleSelection={(selectedPostCode) => this.props.handleChange(selectedPostCode, "postCode")}
                                selected={filterRequest.postCode || ''}
                                defaultSelected={'All'}
                                filters={{city: filterRequest.city || '', state: filterRequest.state || ''}}
                            />
                        </div>
                    </Col>
                    <Col xl={3} lg={4} md={5} sm={13} xs={13}>
                        <Label>Country</Label>
                        <div>
                            <Input type={"select"}
                                   name={"country"}
                                   value={filterRequest.country}
                                   onChange={(e) => this.props.handleChange(e.target.value, "country")}>

                                <option value="">All</option>
                                {(countryOption || []).map((option, index) =>
                                    <option key={index} value={option.value}>{option.label}</option>
                                )}

                            </Input>
                        </div>
                    </Col>

                </Row>

            </div>
        );
    }
}