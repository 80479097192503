import React, {Component} from 'react';

import UserService from "../../services/UserService";

import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';
import {handleErrorMessage} from "../../services/CommonService";


export default class SearchUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedUser: {},
            formError: "",
            username: props.defaultValue,
            name: "",
            loading: false,
            allUsers: [],
            inputValue: "",
            role: props.role

        };

        this.userService = new UserService();

        this.loadOptions = this.loadOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);

    }


    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.username);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.username !== nextProps.defaultValue) {
            //load externally selectedItem
            if (nextProps.defaultValue) {
                this.setState({username: nextProps.defaultValue}, () => {
                    this.fetchOptions(nextProps.defaultValue);
                });
            } else {

                this.resetComponent();
            }
        }
    }


    getFullName(firstName, lastName) {
        let fullName = "";
        if (firstName) {
            fullName = fullName + firstName;
        }
        if (lastName) {
            fullName = fullName + " " + lastName;
        }
        return fullName;
    }


    handleSuccess = (selectedUser) => {
        if (selectedUser) {
            this.setState({
                selectedUser: selectedUser,
                username: selectedUser.username,
                name: this.getFullName(selectedUser.firstName, selectedUser.lastName)
            });
            this.props.onChange(selectedUser.username);
        } else {
            this.resetComponent();
        }
    };

    resetComponent() {
        this.setState({
            selectedUser: {},
            username: "",
            name: "",
            formError: "no user found"
        });
        this.props.onChange("");
        //defaultOptions on load
        this.fetchOptions("");
    }

    handleSuccessForDefault = (selectedUser) => {
        this.setState({selectedUser: selectedUser});
        if (selectedUser) {
            this.setState({
                selectedUser: selectedUser,
                username: selectedUser.username,
                name: this.getFullName(selectedUser.firstName, selectedUser.lastName)
            });
        }
    };


    fetchOptions(inputValue, callback) {
        let allUsers = [];
        this.userService.searchUser(inputValue, this.state.role).then(response => {

            let data = response.data;
            for (let i in data) {
                let user = {
                    label: i,
                    value: data[i].username,
                    id: data[i].username,
                    username: data[i].username,
                    name: this.getFullName(data[i].firstName, data[i].lastName)
                };
                allUsers.push(cloneDeep(user));
                if (this.state.username) {
                    if (data[i].username === this.state.username) {
                        this.handleSuccessForDefault(user);
                    }
                }
            }
            this.setState({allUsers});
            if (callback) {
                return callback(allUsers)
            } else {
                return allUsers;
            }
        }).catch(error => {
            alert(handleErrorMessage(error));
            }
        );
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allUsers);
            }
        }
    };

    render() {

        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.allUsers}
                    loadOptions={this.loadOptions}
                    onChange={this.handleSuccess}
                    value={this.state.selectedUser}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
