import axios from "./axios";
import {v4 as uuidv4} from 'uuid';
import {
    GetPrefilledPackageDetail,
    GetPrefilledWidthHeightPackageDetail
} from '../store/EstimatedProductWeightDetail';
import { findItemHavingMaxValue } from '../services/CommonService';
import axiosFileDownloader from './axiosFileDownloader';

class DispatchOrderService {
    static Instance() {
        return new DispatchOrderService();
    }

    getDispatchOrders(type, requestBody) {
        return axios.post('/api/dispatch-order/list?type=' + type, requestBody);
    }

    checkOrderCreditStatus(orderNo) {
        return axios.get('/api/dispatch-order/Determine/Account/CreditStatus?OrdNum=' + orderNo);
    }

    releaseCreditHold(orderNo) {
        return axios.get('api/dispatch-order/release/credit/hold?OrderNum=' + orderNo);
    }

    getOrderCount(type, branch, activeTab) {
        return axios.get('/api/dispatch-order/count?type=' + type + '&branch=' + branch + '&tab=' + activeTab);
    }

    getOrderCountShippedCategory() {
        return axios.get('/api/dispatch-order/shipped/order/category-wise');
    }

    getOrderSum(type, branch) {
        return axios.get('/api/dispatch-order/orderSum?type=' + type + '&branch=' + branch);
    }

    getDispatchLogs(ordNum) {
        return axios.post('api/dispatch-order/process/logs?ordNum=' + ordNum);
    }

    processDebtorInvoiceInitiate(ordNum,courier) {
        return axios.post('api/dispatch-order/process/initiate?ordNum=' + ordNum, courier, {
            timeout: 600000
        });
    }

    processDebtorInvoiceInvoicing(request) {
        return axios.post('api/dispatch-order/process/invoicing', request, {
            timeout: 600000
        });
    }


    processDebtorInvoiceShipmentConsignmentCreate(request) {
        return axios.post('api/dispatch-order/process/shimpent/consignment/create', request, {
            timeout: 600000
        });
    }

    processDebtorInvoiceShipping(request) {
        return axios.post('api/dispatch-order/process/shipping', request, {
            timeout: 600000
        });
    }

    processDebtorInvoiceOrderStatusUpdate(ordNum) {
        return axios.post('api/dispatch-order/process/order/status/update?ordNum=' + ordNum,{}, {
            timeout: 600000
        });
    }

    processDebtorInvoiceDispatchCommunication(ordNum) {
        return axios.post('api/dispatch-order/process/after-dispatch-communication?ordNum=' + ordNum,{}, {
            timeout: 600000
        });
    }

    processDebtorInvoiceReadyPickupCommunication(ordNum) {
        return axios.post('api/dispatch-order/process/after-dispatch-ready-for-pickup-communication?ordNum=' + ordNum, {}, {
            timeout: 600000
        });
    }

    getDebtorInvoiceInvoicingPreferences(ordNum) {
        return axios.get('api/dispatch-order/process/invoice/preference?ordNum=' + ordNum,{}, {
            timeout: 600000
        });
    }

    getDebtorInvoiceShippingPreferences(ordNum) {
        return axios.get('api/dispatch-order/process/shipping/preference?ordNum=' + ordNum,{}, {
            timeout: 600000
        });
    }

    getOrderJobDetails(OrdNum) {
        return axios.get('/api/dispatch-order/sales/order/job/list?OrdNum=' + OrdNum);
    }

    bulkUpdateProductionSalesOrderJob(salesJobIDs, salesOrderJobStatusID, ordNum) {
        return axios.post('api/dispatch-order/job/status/update?StatusID=' + salesOrderJobStatusID + '&OrdNum=' + ordNum, salesJobIDs);
    }

    getDebtorInvoiceItemsDetails(ordNum) {
        return axios.get("api/dispatch-order/shipped/order/invoice/item?ordNum=" + ordNum);
    }

    getShipmentSenderList(req) {
        return axios.post("/api/dispatch-order/shipment/sender/list", req);
    }

    getShipmentReceiverList(req) {
        return axios.post("/api/dispatch-order/shipment/receiver/list", req);
    }

    saveShipmentReceiverAddress(request) {
        return axios.post("/api/dispatch-order/save/shipment/receiver", request);
    }

    validateShipmentReceiverAddress(request) {
        return axios.post("/api/dispatch-order/validate/shipment/receiver", request);
    }

    getDefaultReceiverAddressRequest(order) {
        let request = {
            id: null,
            courierReceiverID: "",
            addressDescription: order.delCompany,
            addressContactName: order.contactName,
            addressCountryCd: 'AU',
            addressState: order.delState,
            addressPostcode: order.delPostCode,
            addressSuburb: order.delCity,
            addressEmail: 'vikas.kumar@synlabs.io', //order doesn't contains email
            addressPhone: order.phone,
            addressLine1: order.delAddr1,
            addressLine2: order.delAddr2
        };
        return request;
    }

    updateShipping(request) {
        return axios.post("/api/dispatch-order/shipment/update", request);
    }


    getShipmentLineInstance() {
        return {
            id: 0, uuid: uuidv4(), itemType: 'Carton', qty: 1, widthCm: 21, heightCm: 21, weightKg: 0, lengthCm: 1
        };
    }

    getDispatchActionType(isLoadingShipping, actionType) {
        let result = "Save Shipment";
        if (isLoadingShipping) {
            result = "Saving Shipment";
        } else {
            switch (actionType) {
                case "Start":
                    result = "Save Shipment";
                    break;
                case "Resume":
                    result = "Resume Shipment";
                    break;
                case "Completed":
                    result = "Shipment Completed";
                    break;
            }
        }
        return result;
    }

    constructShipmentLines(data) {
        let shipmentLines = [];
        (data.products || []).forEach(product => {
            let shipmentLine = this.getShipmentLineInstance();
            let shipmentLineWidthHeightData = GetPrefilledWidthHeightPackageDetail(product);
            let temp = data.invoiceItemResponse.filter(x => x.product && (x.product.code === product.code));
            temp.forEach((ii, index) => {
                shipmentLine.weightKg += GetPrefilledPackageDetail(ii);
            });
            shipmentLine.widthCm = shipmentLineWidthHeightData.width;
            shipmentLine.heightCm = shipmentLineWidthHeightData.height;
            shipmentLine.lengthCm = Math.round((findItemHavingMaxValue(temp, 'width') + 100) / 10); //convert into cm
            shipmentLine.weightKg = shipmentLine.weightKg > 0 ? shipmentLine.weightKg : 1;
            shipmentLines.push(shipmentLine);
        });
        return shipmentLines;
    }

    updateDispatchOrder(ordNum, optionType) {
        return axios.get("/api/dispatch-order/update/Status?OrdNum=" + ordNum + "&type=" + optionType);
    }
    updateDispatchOrderPickupDate(ordNum, pickupDate) {
        return axios.get("/api/dispatch-order/update/pickupDate?OrdNum=" + ordNum + "&pickupDate=" + pickupDate);
    }
    saveCustomerCollectPickupDetail(request) {
        return axios.post('/api/dispatch-order/save/customer-collect/pickup/detail', request);
    }
    fetchPickupDetail(shipmentID) {
        return axios.get('/api/dispatch-order/fetch/customer/collect/pickup/info?shipmentID=' + shipmentID);
    }
    downloadCustomrCollectSignFile(fileName) {
        return axiosFileDownloader.get('api/file/generate/pickup/sign?FileName=' + fileName);
    }
    getShipmentStatusLog(shipmentID) {
        return axios.get('api/dispatch-order/shipment-status/log/list?shipmentID=' + shipmentID);
    }
    updateLogs(request) {
        return axios.post('api/common/note/logger', request);
    }

    getShippingDetails(ordNum) {
        return axios.get('/api/dispatch-order/shipping/details?ordNum=' + ordNum);
    }

    updateDispatchOrderShippingDetails(request) {
        return axios.post('/api/dispatch-order/shipping/courier/update', request);
    }
}

export default DispatchOrderService.Instance()