import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import queryString from 'query-string';
import StockUnitService from '../../services/StockUnitService';
import SearchWINOnlineStock from '../../components/search/SearchWINOnlineStock';
import classnames from 'classnames';
import SearchStockUnitType from '../../components/search/SearchStockUnitType';
import {handleErrorMessage} from '../../services/CommonService';

export default class ManageStockUnit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockUnitID: 0,
            stockUnitObj: {
                iD: 0,
                stock: {},
                stockProdCode: '',
                stockUnitType: {},
                stockUnitTypeID: '',
                stockID: '',
                qty: 0,
                conversionFactorMainQty: 0,
                dimension: 0,
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                description: '',
                isMainUnitOrAlternative: false
            },
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        let {stockUnitID} = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            stockUnitID = searchParams.stockUnitID;
            this.setState({stockUnitID}, () => {
                this.refresh()
            })
        }

    }

    refresh() {
        if (this.state.stockUnitID) {
            this.setState({loading: true})

            StockUnitService.getOneStockUnit(this.state.stockUnitID).then(response => {
                this.setState({stockUnitObj: response.data, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(change, key) {
        let {stockUnitObj} = this.state;
        stockUnitObj[key] = change;

        if (key == "isMainUnitOrAlternative" && change === true) {
            stockUnitObj.conversionFactorMainQty = 1;
        }

        this.setState({stockUnitObj});
    }

    handleCancel() {
        this.props.history.push('/inventory/stock-unit');
    }

    validateForm(stockUnitObj) {
        let formErrors = {};
        let valid = true;

        if (!stockUnitObj.stockProdCode) {
            formErrors.stockProdCode = "Please select stock";
            valid = false;
        }

        if ((!stockUnitObj.stockUnitType && !stockUnitObj.stockUnitType.id)) {
            formErrors.stockUnitTypeID = "Please select stock unit type";
            valid = false;
        }

        if (!stockUnitObj.conversionFactorMainQty) {
            formErrors.conversionFactorMainQty = "Please enter conversionFactorMainQty";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.stockUnitObj};
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            this.setState({saving: true, formErrors: {}});
            req.stockUnitTypeID = req.stockUnitType ? req.stockUnitType.id : 0;
            StockUnitService.saveOrUpdateStockUnit(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.stockUnitID) {
                        this.props.history.push('/inventory/stock-unit');
                        toast.success("Stock unit saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Stock unit updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.refresh();

                    }

                    this.setState({saving: false});
                }

            }).catch(error => {
                this.setState({saving: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    render() {
        let {stockUnitObj, formErrors, loading, stockUnitID} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/inventory/stock-unit')}>Stock
                        Unit</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!stockUnitID ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Stock Unit</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary"/> :
                            <Form>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Stock</Label>
                                            <SearchWINOnlineStock
                                                isDisabled={stockUnitID!==0}
                                                handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'stockProdCode')}
                                                selectedProdCode={stockUnitObj.stockProdCode}
                                                defaultProdCode={stockUnitObj.stockProdCode} />

                                            <FormText color="danger">{formErrors.stockProdCode}</FormText>
                                        </FormGroup>
                                    </Col>


                                </Row>
                                <Row>

                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <div>
                                                <a href={"javascript:void(0)"}
                                                   onClick={() => this.handleChange(!stockUnitObj.isMainUnitOrAlternative, "isMainUnitOrAlternative")}>
                                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": stockUnitObj.isMainUnitOrAlternative,
                                                            "fa-square-o": !stockUnitObj.isMainUnitOrAlternative,
                                                        }
                                                    )}/>

                                                </a>&nbsp;&nbsp;Main Unit
                                            </div>
                                            <div className="mt-1">
                                                <a href={"javascript:void(0)"}

                                                   onClick={() => this.handleChange(!stockUnitObj.isMainUnitOrAlternative, "isMainUnitOrAlternative")}>
                                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": !stockUnitObj.isMainUnitOrAlternative,
                                                            "fa-square-o": stockUnitObj.isMainUnitOrAlternative,
                                                        }
                                                    )}/>

                                                </a>&nbsp;&nbsp;Alternate Unit
                                            </div>

                                        </FormGroup>
                                    </Col>
                                    <Col lg={5} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Stock Unit Type</Label>
                                            <SearchStockUnitType
                                                isDisabled={stockUnitID !== 0}
                                                stockUnitType={stockUnitObj.stockUnitType}
                                                selected={stockUnitObj.stockUnitType}
                                                defaultSelected={stockUnitObj.stockUnitType}
                                                handleSelection={(selectedStockUnitType) => this.handleChange(selectedStockUnitType, 'stockUnitType')}/>

                                            <FormText color="danger">{formErrors.stockUnitTypeID}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Conversion Factor Main Qty</Label>
                                            <Input
                                                invalid={formErrors.conversionFactorMainQty !== undefined}
                                                name="conversionFactorMainQty"
                                                type="number"
                                                disabled={stockUnitObj.isMainUnitOrAlternative}
                                                value={stockUnitObj.conversionFactorMainQty || ''}
                                                placeholder="write conversion factor main Qty here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'conversionFactorMainQty')}/>
                                            <FormText color="danger">{formErrors.conversionFactorMainQty}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <h6>Extras</h6>

                                <Row>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Dimension</Label>
                                            <Input
                                                name="dimension"
                                                type="text"
                                                value={stockUnitObj.dimension || ''}
                                                placeholder="write dimension here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'dimension')}/>

                                        </FormGroup>
                                    </Col>


                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Length</Label>
                                            <Input
                                                name="length"
                                                type="number"
                                                value={stockUnitObj.length || ''}
                                                placeholder="write length here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'length')}/>

                                        </FormGroup>
                                    </Col>

                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Width</Label>
                                            <Input
                                                name="width"
                                                type="number"
                                                value={stockUnitObj.width || ''}
                                                placeholder="write width here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'width')}/>

                                        </FormGroup>
                                    </Col>

                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Height</Label>
                                            <Input
                                                name="height"
                                                type="number"
                                                value={stockUnitObj.height || ''}
                                                placeholder="write height here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'height')}/>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Quantity</Label>
                                            <Input
                                                name="qty"
                                                type="number"
                                                value={stockUnitObj.qty || ''}
                                                placeholder="write quantity here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'qty')}/>

                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Weight</Label>
                                            <Input
                                                name="weight"
                                                type="number"
                                                value={stockUnitObj.weight || ''}
                                                placeholder="write weight here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'weight')}/>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                value={stockUnitObj.description || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'description')}
                                                placeholder="write description here...">
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </Row>


                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving}>

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                              style={{color: "white"}}/> :
                                    <i className="fa fa-floppy-o mr-2"
                                       aria-hidden="true"/>}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2"/>
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}
