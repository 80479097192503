import axios from '../axios';

class PackagingHandlingChargesService {

    static Instance() {
        return new PackagingHandlingChargesService();
    }

    fetchRules(category, request) {
        let url = "";
        switch (category) {
            case "customer":
                url = 'api/packaging-and-handling/charges/rule/customer/list';
                break;
            default:
                url = 'api/packaging-and-handling/charges/rule/default/list';
        }
        return axios.post(url, request);
    }

    saveRule(request) {
        if (request.id && request.id > 0) {
            return this.updateRule(request);
        } else {
            return this.createRule(request);
        }
    }


    createRule(request) {
        return axios.post('api/packaging-and-handling/charges/rule/create', request);
    }

    updateRule(request) {
        return axios.put('api/packaging-and-handling/charges/rule/update', request);
    }

    deleteFreightRule(request) {
        return axios.put('api/packaging-and-handling/charges/rule/delete');
    }

    fetchSpecialCustomerOrDefaultRules(accountID) {
        return axios.get('api/packaging-and-handling/charges/rule/customer/customer-or-default/list?accountID='+accountID);
    }


}

export default PackagingHandlingChargesService.Instance();


