import React, { Component } from 'react';
import {
     Spinner
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import stockUnitService from '../../services/StockUnitService';
import {handleErrorMessage} from "../../services/CommonService";

export default class StockInventoryMainUnitView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prodCode:"",
            mainStockUnit: {},
            loadingStockUnit: false
        };
        this.refresh = this.refresh.bind(this);
        this.getMainStockUnit = this.getMainStockUnit.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.prodCode)) {
            this.setState({ stock: this.props.prodCode }, () => {
                this.refresh(this.props.prodCode);
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.prodCode !== nextProps.prodCode) {
            this.setState({ prodCode: nextProps.prodCode }, function () {
                this.refresh(nextProps.prodCode);
            });
        }
    }

    refresh(prodCode) {
        this.getMainStockUnit(prodCode);
    }

    getMainStockUnit(prodCode) {
        this.setState({loadingStockUnit:true});
        stockUnitService.getMainStockUnit(prodCode).then(response => {
            this.setState({mainStockUnit: response.data, loadingStockUnit:false});
        }).catch(error => {
            this.setState({loadingStockUnit:false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }



    render() {
        let {prodCode, mainStockUnit,loadingStockUnit } = this.state;

        return (
            <div>
                {loadingStockUnit
                    ? <Spinner color={"primary"} size={"sm"} />
                    : <div>
                        <h6>
                            <small>Stock item code: </small>
                            {prodCode}</h6>
                        <h6>
                            <small>Amount per </small>
                            {isEmpty(mainStockUnit) ? "" : mainStockUnit.stockUnitType.name}</h6>
                    </div>
                }
                <ToastContainer />
            </div>
        );
    }
}