import React, {Component} from 'react';
// Image fetch URL from backend REST API
const baseUrl = window.location.origin;
const prefix = baseUrl + "/api/file/generate/production/dashboard/images?FileName=";
const productionInvoicingImg = prefix + 'ProductionInvoicing.png';

export default class ProductionDashboardProductionInvoicing extends Component {

    componentDidMount() {
        setInterval(function () {
            let imageElement = document.getElementById("productionInvoicingImg");
            if(imageElement){
                imageElement.src = productionInvoicingImg + '&rand=' + Math.random();
            }
        }, 300000);
    }

    render() {
        return (
            <img id={"productionInvoicingImg"}
                 alt="Production Invoicing Chart"
                 src={productionInvoicingImg}
                 width="100%"
                 resizeMode={'cover'}/>

        );
    }

}




