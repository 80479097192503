import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Label, Input, Badge
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { handleErrorMessage,getDebtorInvoiceStatusColour } from '../../services/CommonService';
import ProductionScheduleService from '../../services/production/ProductionScheduleService';
import { PRODUCTION_SCHEDULER_ORDER_STATUS, PRODUCTION_SCHEDULER_POWDERCOATING_ORDER_STATUS } from '../../store/AppConstants';

export default class ProductionOrderStatusChangeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            salesOrderJobStatuses: this.props.salesOrderJobStatuses,
            order: this.props.order,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let { order } = this.state;
        if (order) {
            this.setState({ salesOrderJobStatusId: order.salesOrderJobStatusID });
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.order !== this.state.order && nextProps.order) {
            this.setState({ order: nextProps.order, salesOrderJobStatusId: nextProps.order.salesOrderJobStatusID });
        }
    }

    handleChange(value) {
        this.setState({ salesOrderJobStatusId: parseInt(value) });
    }

    handleCancel() {
        //parameter- to close modal only
        this.props.handlerCloseModal(false);
    }

    handleSubmit() {
        let { order, salesOrderJobStatusId } = this.state;
        if (!salesOrderJobStatusId) {
            toast.info("Please select status");
            return;
        }

        this.setState({ loading: true });
        ProductionScheduleService.updateProductionSalesOrderJob(order.salesOrderJobID, salesOrderJobStatusId).then(res => {
            if (res.status === 200 || res.status === '200') {
                this.setState({ loading: false });
                toast.success("Order status changed successfully");

                //parameters: 1- to close modal, 2. to update status on UI
                this.props.handlerCloseModal(false,salesOrderJobStatusId);
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let { isOpen, toggle, salesOrderJobStatuses  } = this.props;
        let { loading, order, salesOrderJobStatusId } = this.state;
        let filtered_status = [];
        //statuses includes Scheduled, Waiting on Stock, Waiting on Fabric,Waiting on Pelmet
        if (order.product === "PWD" || order.productGroupCode==="PWD") {
            filtered_status = salesOrderJobStatuses.statuses.filter(stat => PRODUCTION_SCHEDULER_POWDERCOATING_ORDER_STATUS.includes(stat.id) && stat.id <= order.salesOrderJobStatusID);
        }
        else {
            filtered_status = salesOrderJobStatuses.statuses.filter(stat => PRODUCTION_SCHEDULER_ORDER_STATUS.includes(stat.id));
        }
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">#{order.ordNum}</h5>
                </ModalHeader>
                <ModalBody>

                    Current order status: <Badge color={getDebtorInvoiceStatusColour(order.salesOrderJobStatusID)} size="sm">{order.salesOrderJobStatusDescription}</Badge>
                    <div className="mt-2 mb-2">
                        <Label>Select new order staus</Label>
                        <Input type={"select"}
                               name={"salesOrderJobStatusId"}
                               value={salesOrderJobStatusId ? salesOrderJobStatusId : ""}
                               onChange={(e) => this.handleChange(e.target.value)}>
                            <option value={""} disabled>Select Status</option>
                            {(filtered_status || []).map((option, index) =>
                                <option key={index} value={option.id}>{option.statusDescription}</option>
                            )}
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"} size={"sm"} onClick={this.handleSubmit}
                                disabled={loading}>
                            {loading ? <Spinner size="sm"
                                                className={"mr-2"}
                                style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                    aria-hidden="true" />}
                            {loading ? "Updating" : "Update"} Status</Button>&nbsp;&nbsp;
                        <Button color={"secondary"}
                                size={"sm"} onClick={() => this.handleCancel()}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}