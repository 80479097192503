import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import UserService from './../../services/UserService';
import ProductMarkups from '../../components/retail/ProductMarkups';
import DebtorsRetailDiscount from '../../components/retail/DebtorsRetailDiscount';
import DebtorsRetailOtherCharges from '../../components/retail/DebtorsRetailOtherCharges';
import UploadCompanyLogo from '../../components/retail/UploadCompanyLogo';
import RetailOrders from '../../components/retail/RetailOrders'
import classnames from 'classnames';
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';


const allTabs = [
    {
        label: "Admin",
        key: "Admin",
        privilege: "admin"
    },
    {
        label: "Sales",
        key: "sales",
        privilege: "default"
    }
];


export default class RetailPageLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "",
            accountID: "",
            tabs: []
        };
        this.userService = new UserService();

    }


    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        let accountID = this.userService.getLoggedInUserAccountID();
        let tabs = [], tabIndex;
        if (this.userService.hasRole(currentUser, "ExternalAdmin")) {
            tabIndex = allTabs.findIndex(t => t.privilege === "admin");
            if (tabIndex > -1) {
                tabs.push(allTabs[tabIndex]);
            }
        }
        tabs = [...tabs, ...allTabs.filter(t => t.privilege === "default")];

        this.setState({accountID, tabs, activeTab: tabs[0].key});
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        let {activeTab, accountID, tabs} = this.state;
        return (
            <div>
                <div>
                    <CustomerDashboardQuickLinks size={"sm"}/>
                    <hr/>
                </div>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"} color={"link"}>{tab.label}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="Admin">
                                <Row>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <UploadCompanyLogo accountID={accountID}/>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <ProductMarkups accountID={accountID}/>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <DebtorsRetailDiscount accountID={accountID}/>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <DebtorsRetailOtherCharges accountID={accountID}/>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="sales">
                                <RetailOrders/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        )
    }
}