import React, { Component } from 'react';
import {
    Modal, ModalBody, ModalHeader, ModalFooter,
    Button,
    Table,
} from 'reactstrap';


export default class SalesOrderItemProductDetailModal extends Component {

    render() {
        let { isOpen, toggle, order } = this.props;
        let { orderDetails } = order;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Order No. {order.ordNum}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Table responsive striped hover size={"sm"}>
                            <thead>
                                <tr>
                                    <th>Products</th>
                                    <th className="text-right">Quantity</th>
                                </tr>
                            </thead>
                            {order.orderDetails ?
                                <tbody>
                                    {
                                        orderDetails.map((data, index) =>
                                            <tr key={index}>
                                                <td>{data.product}</td>
                                                <td className="text-right">{data.qty}</td>
                                            </tr>
                                        )}
                                </tbody> : null}
                        </Table>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}