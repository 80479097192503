import React, {Component} from 'react';
import {
    Badge,
    Button,
    Col,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import {createDropdownOptions, createStringFromDropdownOptions} from "../../services/CommonService";

const userTypeOptions = [{label: "Internal", value: "Internal"}, {label: "External", value: "External"}];

function FormError(props) {
    let form = props.item;
    switch (props.attribute) {
        case "name":
            if (!form.name) {
                return <FormText color="danger">Required</FormText>
            }
            break;
        case "description":
            if (!form.description) {
                return <FormText color="danger">Required</FormText>
            }
            break;
        case "methodKey":
            if (!form.methodKey) {
                return <FormText color="danger">Required</FormText>
            }
            break;
        case "methodLabel":
            if (!form.methodLabel) {
                return <FormText color="danger">Required</FormText>
            }
            break;
        case "attributeKey":
            if (!form.attributeKey) {
                return <FormText color="danger">Required</FormText>
            }
            break;
        case "attributeLabel":
            if (!form.attributeLabel) {
                return <FormText color="danger">Required</FormText>
            }
            break;
        case "note":
            if (!form.note) {
                return <FormText color="danger">Required</FormText>
            }
            break;
        case "priority":
            if (!form.priority) {
                return <FormText color="danger">Required</FormText>
            }
            break;
    }
    return null;
}

export default class ManageFreightRuleModal extends Component {

    validateAndSubmit(form) {
        let flag = true;
        if (!form.name) {
            flag = false;
        }
        if (!form.description) {
            flag = false;
        }
        if (!form.methodKey) {
            flag = false;
        }
        if (!form.methodLabel) {
            flag = false;
        }
        if (!form.attributeKey) {
            flag = false;
        }
        if (!form.attributeLabel) {
            flag = false;
        }
        if (!form.note) {
            flag = false;
        }
        if (!form.priority) {
            flag = false;
        }
        if (flag) {
            this.props.handleSubmit(form);
        }
    }


    render() {


        let {isOpen, toggle, loading, handleChange, form} = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {form.id > 0 ? "Edit" : "Create"}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div>
                            Freight rule's name*
                            <Input type={"text"}
                                   placeholder={"Rule name"}
                                   name={"name"}
                                   value={form.name}
                                   onChange={(e) => handleChange(e.target.value, "name")}/>
                            <FormError attribute={"name"} item={form}/>
                            <small className="text-info">Entered value will be used for identifying specific rule among
                                others
                            </small>
                        </div>

                        <div className={"mt-3"}>
                            Rule's description*
                            <Input type={"textarea"}
                                   placeholder={"Rule description"}
                                   name={"description"}
                                   value={form.description}
                                   onChange={(e) => handleChange(e.target.value, "description")}/>
                            <FormError attribute={"description"} item={form}/>
                            <small className="text-info">Entered value will be used for describing rule</small>

                        </div>

                        <div className="mt-2">
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    Freight rule's method display name*
                                    <Input type={"text"}
                                           placeholder={"Method display name"}
                                           name={"methodLabel"}
                                           value={form.methodLabel}
                                           onChange={(e) => handleChange(e.target.value, "methodLabel")}/>
                                    <FormError attribute={"methodLabel"} item={form}/>
                                    <small className="text-info">Entered value represents rule's categorization</small>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    Freight rule's method code*
                                    <Input type={"text"}
                                           placeholder={"Method code"}
                                           name={"methodKey"}
                                           value={form.methodKey}
                                           onChange={(e) => handleChange(e.target.value, "methodKey")}/>
                                    <FormError attribute={"methodKey"} item={form}/>
                                </Col>
                            </Row>

                        </div>

                        <div  className="mt-2">

                                    </div>
                        {/* <Input type={"text"}
                                   disabled
                                   placeholder={"Attribute key"}
                                   name={"attributeKey"}
                                   value={form.attributeKey}
                                   onChange={(e) => handleChange(e.target.value, "attributeKey")}/>
                            <FormError attribute={"attributeKey"} item={form}/>*/}

                        {
                            form.attributeKey === "n/a"
                                ? null
                                : <div className={"mt-3"}>
                                    <Row>
                                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <div>
                                                Freight rule's parameter
                                                <Input type={"text"}
                                                       disabled
                                                       placeholder={"Attribute Label"}
                                                       name={"attributeLabel"}
                                                       value={form.attributeLabel}
                                                       onChange={(e) => handleChange(e.target.value, "attributeLabel")}/>
                                                <FormError attribute={"attributeLabel"} item={form}/>
                                                <small className="text-info">This rule is based on this parameter of the
                                                    sales's order
                                                </small>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <div>
                                                Parameter's minimum value*
                                                <Input type={"text"}
                                                       placeholder={"Attribute min value"}
                                                       name={"attributeValueMin"}
                                                       value={form.attributeValueMin}
                                                       onChange={(e) => handleChange(e.target.value, "attributeValueMin")}/>
                                                <FormError attribute={"attributeValueMin"} item={form}/>
                                                <small className="text-info">Expected minimum value of parameter in sales
                                                    order
                                                </small>
                                            </div>

                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <div>
                                                Parameter's maximum value*
                                                <Input type={"text"}
                                                       placeholder={"Attribute max value"}
                                                       name={"attributeValueMax"}
                                                       value={form.attributeValueMax}
                                                       onChange={(e) => handleChange(e.target.value, "attributeValueMax")}/>
                                                <FormError attribute={"attributeValueMax"} item={form}/>
                                                <small className="text-info">Expected minimum value of parameter in sales
                                                    order
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                        }

                        <div className={"mt-3"}>
                            <div>
                                Rule applicable for postcode
                                <Input type={"text"}
                                       placeholder={"Rule for specific postcode"}
                                       name={"postcode"}
                                       value={form.postcode}
                                       onChange={(e) => handleChange(e.target.value, "postcode")}/>
                                <FormError attribute={"postcode"} item={form}/>
                                <small className="text-info">This rule is applicable for entered postcode only.
                                    To make
                                    this rule applicable
                                    across all postcodes keep this blank.
                                </small>
                            </div>
                        </div>

                        <div className={"mt-3"}>
                            <div>
                                Rule can be used by user type
                                <Select
                                    options={userTypeOptions}
                                    value={createDropdownOptions("ReactSelect", form.userType)}
                                    placeholder={"Select"}
                                    onChange={(selection) => handleChange(createStringFromDropdownOptions("ReactSelect", selection), "userType")}
                                    closeMenuOnSelect={true}
                                    isSearchable={false}
                                    isMulti={true}
                                />
                                <FormError attribute={"userType"} item={form}/>
                                <small className="text-info">This rule can be used by entered user type only.
                                    To make
                                    this rule available
                                    across all user types keep this blank.
                                </small>
                            </div>
                        </div>
                        <div className={"mt-3"}>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div>
                                        Freight charges for metro city*
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type={"number"}
                                                   placeholder={"metroCharge"}
                                                   name={"Metro charges"}
                                                   value={form.metroCharge}
                                                   onChange={(e) => handleChange(e.target.value, "metroCharge")}/>
                                        </InputGroup>
                                        <FormError attribute={"metroCharge"} item={form}/>
                                        <small className="text-info">Applicable if delivery address's postcode is
                                            metro city one
                                        </small>
                                    </div>

                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div>
                                        Freight charges for non-metro city*
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type={"number"}
                                                   placeholder={"nonMetroCharge"}
                                                   name={"Metro charges"}
                                                   value={form.nonMetroCharge}
                                                   onChange={(e) => handleChange(e.target.value, "nonMetroCharge")}/>
                                        </InputGroup>
                                        <FormError attribute={"nonMetroCharge"} item={form}/>
                                        <small className="text-info">Applicable if delivery address's postcode is
                                            not metro city one
                                        </small>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {
                            form.accountID
                                ? null
                                : <div className={"mt-3"}>
                                    <div>
                                        Rule's can be applied to any customers while ordering?
                                        <div>
                                            <Button
                                                className={"ml-2"}
                                                color={"link"}
                                                size={"sm"}
                                                onClick={() => handleChange(!form.isPublic, "isPublic")}>
                                                <i className={classnames("fa", "fa-lg", {
                                                        "fa-check-square-o": form.isPublic,
                                                        "fa-square-o": !form.isPublic,
                                                    }
                                                )}/>
                                            </Button>
                                            {
                                                form.isPublic
                                                    ? <Badge color={"success"} className={"mr-2"}>Public</Badge>
                                                    : <Badge color={"primary"} className={"mr-2"}>Selective</Badge>
                                            }
                                            {
                                                form.isPublic
                                                    ? <div>It means this rule can be applied to any customer.</div>
                                                    : <div>It means this rule can be applied to only selected customers
                                                        only if, when it will get assigned to them.</div>
                                            }
                                        </div>
                                    </div>
                                    <small className="text-info">
                                        {
                                            form.isPublic
                                                ? "To restrict availability, unselect the option"
                                                : "To make it available to all customers, select this option "
                                        }
                                    </small>
                                </div>
                        }
                        <div className={"mt-3"}>
                            Rule's priority*
                            <Input type={"number"}
                                   placeholder={"Rule priority"}
                                   name={"priority"}
                                   value={form.priority}
                                   onChange={(e) => handleChange(e.target.value, "priority")}/>
                            <FormError attribute={"priority"} item={form}/>
                            <small className="text-info">
                                Higher value represents more high priority over other rules available for specific
                                customer
                            </small>
                        </div>
                        <div className={"mt-3"}>
                            Notes*
                            <Input type={"textarea"}
                                   placeholder={"note"}
                                   name={"Write note here"}
                                   value={form.note}
                                   onChange={(e) => handleChange(e.target.value, "note")}/>
                            <FormError attribute={"note"} item={form}/>
                            <small className="text-info">
                                Some notes, i.e. why this rule has been created.
                            </small>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"}
                                className={"mr-2"}
                                size={"sm"}
                                onClick={() => this.validateAndSubmit(form)}
                                disabled={loading}>
                            {loading
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           style={{color: "white"}}/>
                                : <i className="fa fa-floppy-o mr-2"/>}
                            {loading
                                ? (form.id > 0 ? "Updating" : "Saving")
                                : (form.id > 0 ? "Update" : "Save")}
                        </Button>
                        <Button disabled={loading} color={"secondary"} size={"sm"} onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2"/>Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}