import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import StockInventoryCostLandedService from '../../services/StockInventoryCostLandedService';
import StockInventoryMainUnitView from '../../components/WINOInventory/StockInventoryMainUnitView';
import {ToastContainer, toast} from 'react-toastify';
import queryString from 'query-string';
import {isEmpty} from 'lodash';
import ManageStockInventoryCostLandedTab from "./ManageStockTnventoryCostLandedTab";

export default class ManageStockInventoryCostLanded extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prodCode: '',
        };
    }

    componentDidMount() {
        let prodCode = '';
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            prodCode = searchParams.prodCode;
            this.setState({prodCode}, () => {
            })
        }
    }

    render() {
        let {prodCode}=this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={this.props.history.goBack}>Stock Inventory Cost</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!prodCode ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>
            <ManageStockInventoryCostLandedTab prodCode={prodCode} history={this.props.history}/>
            </div>
            );
    }
}
