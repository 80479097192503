import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody, CardFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';

import { findIndex, handleErrorMessage } from '../../../services/CommonService';
import StockWarehouseLocationService from '../../../services/WINOInventory/StockWarehouseLocationService';
import SearchWINOnlineStock from '../../../components/search/SearchWINOnlineStock';

export default class WINOStockWarehouseLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockWarehouseLocationData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        stock: {},
                        stockID: 0,
                        stockProdCode:'',
                        warehouseLocationName: "",
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',

        };

        this.getStockWarehouseLocations = this.getStockWarehouseLocations.bind(this);

    }

    componentDidMount() {
        this.getStockWarehouseLocations(this.state.stockWarehouseLocationData);
    }

    getStockWarehouseLocations(stockWarehouseLocationData) {
        this.setState({ loading: true });
        let req = { ...this.state.stockWarehouseLocationData.request };
        StockWarehouseLocationService.listStockWarehouseLocation(req).then(response => {
            stockWarehouseLocationData.response = response.data;
            this.setState({ stockWarehouseLocationData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.stock.prodCode.toLowerCase().includes(searchText);

            if (!flag && item.warehouseLocation.name) {
                flag = item.warehouseLocation.name.toLowerCase().includes(searchText)
            }

            if (!flag && item.qoh) {
                flag = item.qoh.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.minQtyAllocated) {
                flag = item.minQtyAllocated.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.maxQtyAllocated) {
                flag = item.maxQtyAllocated.toString().toLowerCase().includes(searchText)
            }

        }
        return flag;
    }

    handleChange(change, key) {
        let { stockWarehouseLocationData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (stockWarehouseLocationData.request.sortRequest.key === change) {
                    stockWarehouseLocationData.request.sortRequest.direction = !stockWarehouseLocationData.request.sortRequest.direction;
                } else {
                    stockWarehouseLocationData.request.sortRequest.key = change;
                    stockWarehouseLocationData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockWarehouseLocations(stockWarehouseLocationData);
                }
                break;
            case "pageSize":
                stockWarehouseLocationData.request.pageRequest[key] = parseInt(change);
                stockWarehouseLocationData.request.pageRequest.currentPage = 1;
                this.getStockWarehouseLocations(stockWarehouseLocationData);
                break;
            case "currentPage":
                stockWarehouseLocationData.request.pageRequest[key] = change;
                this.getStockWarehouseLocations(stockWarehouseLocationData);
                break;


            default:
                stockWarehouseLocationData.request.filterRequest[key] = change;
                stockWarehouseLocationData.request.pageRequest.currentPage = 1;
                this.getStockWarehouseLocations(stockWarehouseLocationData);
                this.setState({ stockWarehouseLocationData });
        }

    }

    deleteStockWarehouseLocation(stockWarehouseLocation) {
        let { stockWarehouseLocationData } = this.state;
        let { records } = stockWarehouseLocationData.response;
        let index = findIndex(records, 'id', stockWarehouseLocation.id);
        records[index].isDeleting = true;
        this.setState({ stockWarehouseLocationData });
        StockWarehouseLocationService.deleteStockWarehouseLocation(stockWarehouseLocation.id).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("Stock Warehouse location deleted succesfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                records[index].isDeleting = false;
                this.setState({ stockWarehouseLocationData });
                this.getStockWarehouseLocations(stockWarehouseLocationData);
            }
        }).catch(error => {
            records[index].isDeleting = false;
            this.setState({ stockWarehouseLocationData });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStockWarehouseLocationHeading({ filterRequest }) {
        let stockWarehouseLocationHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "stockProdCode",
                label: "Stock ProdCode",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchWINOnlineStock
                        handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'stockProdCode')}
                        selectedProdCode={filterRequest.stockProdCode}
                        defaultProdCode={filterRequest.stockProdCode}/>
                </div>

            },
            {
                key: "warehouseLocationName",
                label: "Warehouse Location",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"warehouseLocationName"}
                        value={filterRequest.warehouseLocationName || ''}
                        onChange={(e) => this.handleChange(e.target.value, "warehouseLocationName")} />
                </div>

            },
            {
                key: "qoh",
                label: "QOH",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null

            },
            {
                key: "minQtyAllocated",
                label: "Min Qty Allocated",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null

            },
            {
                key: "maxQtyAllocated",
                label: "Max Qty Allocated",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null

            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "250px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                    to={{
                        pathname: '/keyway-inventory/stock-warehouse-location/manage/one', state: { stockWarehouseLocationID: "" }
                    }}><i className="fa fa-plus" />&nbsp;
                            Add </Link></div>

            }];

        return stockWarehouseLocationHeading;
    }


    render() {

        let { stockWarehouseLocationData, loading, searchText } = this.state;
        let { pageRequest, sortRequest } = stockWarehouseLocationData.request;
        let stockWarehouseLocationHeading = this.getStockWarehouseLocationHeading(stockWarehouseLocationData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Warehouse Location</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Stock Warehouse Location</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockWarehouseLocationData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(stockWarehouseLocationHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(stockWarehouseLocationHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(stockWarehouseLocationData.response.records || []).filter((stockWarehouseLocation) => this.searchFunction(stockWarehouseLocation, searchText)).map((stockWarehouseLocation, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>{stockWarehouseLocation.stock.prodCode}</td>
                                            <td>{stockWarehouseLocation.warehouseLocation.name}</td>
                                            <td className={"text-right"}>{stockWarehouseLocation.qoh}</td>
                                            <td className={"text-right"}>{stockWarehouseLocation.minQtyAllocated}</td>
                                            <td className={"text-right"}>{stockWarehouseLocation.maxQtyAllocated}</td>
                                            <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                to={"/keyway-inventory/stock-warehouse-location/manage/one?" + queryString.stringify({ stockWarehouseLocationID: stockWarehouseLocation.id })}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                                Edit</Link>&nbsp;
                                                <Button className="btn btn-sm btn-danger" title="Click here to make stock warehouse location inactive"
                                                    onClick={this.deleteStockWarehouseLocation.bind(this, stockWarehouseLocation)}
                                                    disabled={stockWarehouseLocation.isDeleting}>
                                                    {stockWarehouseLocation.isDeleting ?
                                                        <Spinner size="sm"
                                                            className={"mr-2"}
                                                            style={{ color: "white" }} /> :
                                                        <i className="fa fa-trash mr-2" aria-hidden="true"></i>} Delete
                                                </Button>

                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            *<option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockWarehouseLocationData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
