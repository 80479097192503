import React, {Component} from 'react';
import {Pie} from 'react-chartjs-2';
import PurchaseHistoryService from '../../../services/PurchaseHistoryService';
import {toast, ToastContainer} from 'react-toastify';
import {
    colorConvertHexToRgb,
    getColors,
    getFinancialYear,
    numberWithThousandSeparator
} from "../../../services/CommonService";
import FinancialYearDropDown from '../../FinancialYearDropDown';
import moment from 'moment';

export default class SoldProductUnitsChart extends Component {

    constructor(props) {
        super(props);
        let currentFY = getFinancialYear(moment(new Date()));
        this.state = {
            accountID: this.props.accountID,
            selectedYear: currentFY,
            soldUnits: {
                loading: true,
                chartData: {}
            },
            includeChildAccount: this.props.includeChildAccount ? this.props.includeChildAccount : false
        };
        this.purchaseHistoryService = new PurchaseHistoryService();
        this.fetchSoldUnits = this.fetchSoldUnits.bind(this);
        this.yearChange = this.yearChange.bind(this);
    }

    componentDidMount() {
        if (this.state.accountID) {
            this.fetchSoldUnits();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.includeChildAccount !== nextProps.includeChildAccount) {
            this.setState({ accountID: nextProps.accountID, includeChildAccount: nextProps.includeChildAccount }, function () {
                this.fetchSoldUnits();
            });
        }
    }


    fetchSoldUnits() {
        let soldUnits = this.state.soldUnits;
        soldUnits.loading = true;
        this.setState({soldUnits: soldUnits});
        if (this.state.accountID) {
            this.purchaseHistoryService.getSoldUnits(this.state.accountID, this.state.selectedYear, this.state.includeChildAccount).then(response => {

                let colors = getColors();
                let soldUnitsCount = [];
                let labels = [];
                let backgroundColor = [];
                let totalValue = 0;
                let index = 0;
                for (let key in response.data) {

                    labels.push(key.toUpperCase()); // stockMasterGroupNames

                    let value = Math.round(response.data[key]);
                    soldUnitsCount.push(value); // value
                    totalValue += value;

                    backgroundColor.push(colorConvertHexToRgb(colors[index]));
                    index++;
                }


                soldUnits = {
                    loading: false,
                    chartData: {
                        labels: labels,
                        datasets: [{
                            label: labels,
                            data: soldUnitsCount,
                            backgroundColor: backgroundColor,

                        }]
                    }
                };
                this.setState({soldUnits: soldUnits});
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        else {
            toast.info("Account not found...!!!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    yearChange(selectedYear) {
        if (selectedYear && selectedYear !== this.state.selectedYear) {
            this.setState({selectedYear: selectedYear}, () => {
                this.fetchSoldUnits();
            });
        }
    };

    render() {
        let {selectedYear, options} = this.state;
        return (
            <div>
                <div>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"flex-fill align-self-center"}><h6>Purchased Products</h6></div>
                        <div className={"flex-fill align-self-center"}>
                            <FinancialYearDropDown handleChange={this.yearChange}
                                                   defaultSelectedYear={selectedYear}
                                                   selectedYear={selectedYear}
                            />
                        </div>
                    </div>
                    <br/>
                    <Pie data={this.state.soldUnits.chartData} options={
                        {
                            layout: {
                                padding: {
                                    left: 0,
                                    right: 0,
                                    top: 5,
                                    bottom: 25
                                }
                            },
                            title: {
                                display: true,
                                text: ""
                            },
                            legend: {
                                display: window.innerWidth > 768,
                                position: 'right',
                                fullWidth: false
                            },
                            tooltips: {
                                callbacks: {
                                    title: function (tooltipItem, chartData) {
                                        return "Sold Units"
                                    },
                                    label: function (tooltipItems, data) {
                                        return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + " : " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                                    }
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: 'auto',
                                    anchor: 'end',
                                    clamp: true,
                                    align: 'end',
                                    offset: 2,
                                    backgroundColor: function (context) {
                                        return context.dataset.backgroundColor;
                                    },
                                    color: 'white',
                                    font: {
                                        weight: 'bold'
                                    },
                                    formatter: function (value, data) {
                                        // return data.dataset.label[data.dataIndex] + " : $" + value;
                                        value = numberWithThousandSeparator(value);
                                        return value;
                                    }
                                }
                            }
                        }
                    }/>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}
