import React, {Component} from 'react';
import creditorService from "../../services/purchase/CreditorService";
import AsyncSelect from 'react-select/async';


export default class SearchCreditorAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: {},
            defaultAccountID: props.defaultAccountID,
            accountID: props.selectedAccountID,
            options: [],
        };

        this.loadOptions = this.loadOptions.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
    }


    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.accountID || this.state.defaultAccountID);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultAccountID !== nextProps.defaultAccountID) {
            if (nextProps.defaultAccountID) {
                this.setState({
                    defaultAccountID: nextProps.defaultAccountID,
                    accountID: nextProps.defaultAccountID
                }, () => {
                    this.fetchOptions(nextProps.defaultAccountID);
                })
            }

        }

        if (this.state.accountID !== nextProps.selectedAccountID) {
            if (!nextProps.selectedAccountID) {
                this.setState({ selected: null, accountID: "" })
            }
            else {
                this.setState({ accountID: nextProps.selectedAccountID })
            }
            //load externally selectedItem
            this.fetchOptions(nextProps.selectedAccountID);
        }
    }


    handleSuccess(selectedAccount, key) {
        let { selected, accountID } = this.state;
        selected = selectedAccount;
        accountID = selectedAccount ? selectedAccount.accountID : "";
        switch (key) {
            case "selected":
                this.setState({ selected, accountID });
                break;
            case "default":
            case "onChange":
                this.setState({ selected, accountID });
                this.props.handleAccountChange(accountID);
                break;
        }
    };


    fetchOptions(inputValue, callback) {
        let options = [];
        creditorService.fetchCreditorDropdownOptions({ accountID: inputValue }).then(response => {
            for (let i in response.data) {
                options.push(response.data[i]);
                if (this.props.defaultAccountID) {
                    if (response.data[i].accountID === this.props.defaultAccountID) {
                        this.handleSuccess(response.data[i],"default");
                    }
                }
                if (this.props.selectedAccountID) {
                    if (response.data[i].accountID === this.props.selectedAccountID) {
                        this.handleSuccess(response.data[i], "selected");
                    }
                }
            }
            this.setState({options});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                console.log(error.data)
            }
        );
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.options);
            }
        }
    };

    render() {

        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.options}
                    loadOptions={this.loadOptions}
                    onChange={(selectedAccountID) => this.handleSuccess(selectedAccountID,"onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
