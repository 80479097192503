import axios from '../axios';
import axiosFileDownloader from '../axiosFileDownloader';
import axiosFileUploader from '../axiosFileUploader';

export default class PurchaseOrderService {
    getOrder(ordNum) {
        return axios.post('api/purchase/order/one?OrdNum=' + ordNum);
    }

    getOrderNoByStatus(status) {
        return axios.get('api/purchase/order/nextByStatus?Status=' + status);
    }

    getCurrentNextOrder(ordNum) {
        return axios.get('api/purchase/order/current/nextOrder?ordNum=' + ordNum);
    }

    getCurrentLastOrder(ordNum) {
        return axios.get('api/purchase/order/current/lastOrder?ordNum=' + ordNum);
    }

    getPurchaseOrderItemByProdCode(request) {
        return axios.post("api/purchase/order/item", request);
    }

    exportPurchaseOrderItemByProdCode(request, prodCode) {
        request.filterRequest.prodCode = prodCode;
        return axiosFileDownloader.post("api/purchase/order/item/export/excel", request);
    }

    getPurchaseOrderItemReceivedByProdCode(request, prodCode) {
        request.filterRequest.prodCode = prodCode;
        return axios.post("api/purchase/order/item/received", request);
    }

    getPurchaseOrderItemHistoryByProdCode(request, prodCode) {
        request.filterRequest.prodCode = prodCode;
        return axios.post("api/purchase/order/item/history", request);
    }

    exportPurchaseOrderItemHistoryByProdCode(request, prodCode) {
        request.filterRequest.prodCode = prodCode;
        return axiosFileDownloader.post("api/purchase/order/item/history/export/excel", request);
    }

    getPurchaseOrderStatuses() {
        return axios.get('api/purchase/order/statuses');
    }

    generateNewPurchaseOrder() {
        return axios.get('api/purchase/order/new/order');
    }

    savePurchaseOrder(request) {
        return axios.post('api/purchase/order/save', request);
    }

    createPurchaseOrderDocument(purchaseOrdNum) {
        return axios.post('api/purchase/order/purchase-order/attachment?purchaseOrdNum=' + purchaseOrdNum);
    }

    sendPurchaseOrderEmail(request) {
        return axios.post('api/purchase/order/share/email', request);
    }

    getStockDetailWithPriceBySupplierCode(supplierCode, prodCode) {
        return axios.get("api/purchase/order/get/stock/detail/with/quote/price/by/supplierCode?prodCode=" + prodCode + "&supplierCode=" + supplierCode);
    }

    getPurchaseOrderAllOption(searchText) {
        return axios.post('api/purchase/order/dropdown?searchText=' + searchText);
    }

    getPurchaseOrders(request) {
        return axios.post('api/purchase/order/list', request);
    }

    getPurchaseOrderStatusColor(status) {
        let result = "";
        switch (status) {
            case "Cancelled":
                result = "danger";
                break;
            case "In Progress":
                result = "primary";
                break;
            case "On Hold":
                result = "danger";
                break;
            case "Approved":
                result = "warning";
                break;
            case "Sent":
                result = "info";
                break;
            case "Completed":
                result = "success";
                break;
        }
        return result;
    }

    stockReceive(request) {
        return axios.post('api/warehouse/stock/receive', request);
    }

    downloadPurchaseOrderItemImportTemplate() {
        return axiosFileDownloader.get('api/purchase/order/template/export');
    }

    importPurchaseOrderItemData(request) {
        return axiosFileUploader.post('api/purchase/order/import', request);
    }

    generateStockDescription(item) {
        let result = item.description;

       /* if (item.colour) {
            result = result + " | Colour: " + item.colour ;
        }*/
       /* if (item.length || item.width || item.height) {
            result = result + " (";
            if (item.length) {
                result = result + " Length: " + item.length + (item.dimensionUnitName ? item.dimensionUnitName : "");
            }
            if (item.width) {
                result = result + " Width: " + item.width + (item.dimensionUnitName ? item.dimensionUnitName : "");
            }
            if (item.height) {
                result = result + " Height: " + item.height + (item.dimensionUnitName ? item.dimensionUnitName : "");
            }
            result = result + ")";
        }*/
        return result
    }

    getNotes(ordNum) {
        return axios.post('api/purchase/order/notes/list?ordNum=' + ordNum);
    }

    addPurchaseOrderNotes(notes, ordNum, userName) {
        return axios.post('api/purchase/order/notes/add?notes=' + notes + '&ordNum=' + ordNum + '&userName=' + userName);
    }

    updateNote(ordNum, note) {
        return axios.post('api/purchase/order/notes/update?ordNum=' + ordNum + '&note=' + note);
    }

    updateInstruction(ordNum, instruction) {
        return axios.post('api/purchase/order/instruction/update?ordNum=' + ordNum + '&instruction=' + instruction);
    }

    getStockDetailListBySupplierCode(prodCodes) {
        return axios.post('api/purchase/order/enquiry/stock/list', prodCodes);
    }

    updatePurchaseOrderStatus(ordNum, statusID) {
        return axios.post('api/purchase/order/status/update?ordNum=' + ordNum + '&statusID=' + statusID);
    }

    generatePurchaseOrderInExcel(request) {
        return axiosFileDownloader.post('api/purchase/order/excel/report', request);
    }

    downloadPurchaseOrderDocument(ordNum) {
        return axiosFileDownloader.get('api/purchase/order/download/document?ordNum=' + ordNum);
    }

}
