import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table
} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import CustomerService from '../../services/CustomerService';
import {findIndex, handleErrorMessage, validateEmail, getDistinctValues} from '../../services/CommonService';
import UserService from '../../services/UserService';
import CustomerAccountsDetailsFilter from '../../components/debtor/CustomerAccounts/CustomerAccountsDetailsFilter';
import RepService from '../../services/RepService';
import CustomerSignupInviteModal from '../../components/modal/CustomerSignupInviteModal';
import * as FileSaver from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import AddressService from "../../services/AddressService";

export default class CustomerAccountDetailsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerAccountsData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        company: '',
                        address: '',
                        state: '',
                        postCode: '',
                        city: '',
                        accountId: '',
                        debtorCategoryID: 0,
                        paymentTermsID: 0,
                        accountStatus: 'ALL',
                        repCode: 0,
                        aBN: '',
                        isIncludeChildren: false,
                        excludeClosedOrInactive:true
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            debtorPaymentTerms: [],
            debtorAccountStatus: [],
            debtorCategory: [],
            reps: [],
            isHiddenExtendedColumn: true,
            usersByEmail: {},
            alreadyInvited: [],
            alreadyRegisteredAccountID:[],
            isOpenCustomerSignupInviteModal: false,
            workingCustomer: null,
            isLoadingInvitation: false,
            stateOption: [],
            hasDebtorWritePrivilege: false
        };

        this.customerService = new CustomerService();
        this.userService = new UserService();
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getDebtorAccountStatus = this.getDebtorAccountStatus.bind(this);
        this.getDebtorCategory = this.getDebtorCategory.bind(this);
        this.getDebtorPaymentTerms = this.getDebtorPaymentTerms.bind(this);
        this.getReps = this.getReps.bind(this);
        this.getCustomerAccountsHeading = this.getCustomerAccountsHeading.bind(this);
        this.toggleExtendedColumn = this.toggleExtendedColumn.bind(this);
        this.sendInvitationEmail = this.sendInvitationEmail.bind(this);
        this.toggleCustomerSignupInviteModal = this.toggleCustomerSignupInviteModal.bind(this);
        this.fetchAlreadyRegisteredEmails = this.fetchAlreadyRegisteredEmails.bind(this);
    }

    componentDidMount() {
        this.getCustomerAccounts(this.state.customerAccountsData);
        this.getStates();
    }

    refresh() {
        this.getDebtorAccountStatus();
        this.getDebtorCategory();
        this.getDebtorPaymentTerms();
        this.getReps();
        let currentUser = this.userService.getLoggedInUser();
        let hasDebtorWritePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-write');
        this.setState({ hasDebtorWritePrivilege });
    }

    

    toggleCustomerSignupInviteModal(isOpenCustomerSignupInviteModal) {
        let {workingCustomer} = this.state;
        if (!isOpenCustomerSignupInviteModal) {
            workingCustomer = null;
        }
        this.setState({isOpenCustomerSignupInviteModal, workingCustomer});
    }

    getReps() {
        RepService.fetchReps().then(res => {
            this.setState({ reps: res.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorAccountStatus() {
        this.customerService.getDebtorAccountStatus().then(response => {
            this.setState({ debtorAccountStatus: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorCategory() {
        this.customerService.getDebtorCategory().then(response => {
            this.setState({ debtorCategory: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStates(){
        AddressService.getStates().then(response => {
            response.data.map(state=> {
                this.setState(previousState => ({
                    stateOption: [...previousState.stateOption, state.abbreviation]
                }));
            })
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorPaymentTerms() {
        this.customerService.getDebtorPaymentTerms().then(response => {
            this.setState({ debtorPaymentTerms: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCustomerAccounts(customerAccountsData) {
        this.setState({ loading: true});
        this.customerService.getCustomerAccounts(customerAccountsData.request).then(response => {
            customerAccountsData.response = response.data;
            if (customerAccountsData.response && customerAccountsData.response.records) {
                let customerEmails = getDistinctValues(customerAccountsData.response.records, 'email');
                let customerAccountIDs = getDistinctValues(customerAccountsData.response.records, 'accountID');
                this.fetchAlreadyRegisteredEmails(customerEmails);
                this.fetchAlreadyInvitedEmails(customerEmails);
                this.fetchAccountIDRegisteredAsUser(customerAccountIDs);
            }


            this.setState({ customerAccountsData, loading: false }, () => this.refresh());
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    fetchAlreadyInvitedEmails(emails)
    {
        this.userService.findAlreadyInvited(emails).then(response => {
            if(response.data)
            {
                let { alreadyInvited }=this.state;
                alreadyInvited=response.data;
                this.setState({alreadyInvited});
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    fetchAccountIDRegisteredAsUser(accountIDs) {
        this.customerService.fetchAccountIDRegisteredAsUser(accountIDs).then(response => {
            if (response.data) {
                let { alreadyRegisteredAccountID } = this.state;
                alreadyRegisteredAccountID = response.data;
                this.setState({ alreadyRegisteredAccountID });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }

    fetchAlreadyRegisteredEmails(emails) {
        this.userService.findUsers([], [], emails).then(response => {
            if ( response.data){
                let users = response.data;
                let {usersByEmail} = this.state;
                (users || []).forEach(user => {
                    if (!usersByEmail[user.emailAddress]) {
                        usersByEmail[user.emailAddress] = user;
                    }
                });
                this.setState({usersByEmail});
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.company.toLowerCase().includes(searchText);

            if (!flag && item.state) {
                flag = item.state.toLowerCase().includes(searchText)
            }

            if (!flag && item.city) {
                flag = item.city.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.postCode) {
                flag = item.postCode.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.accountID) {
                flag = item.accountID.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.repArea1) {
                flag = item.repArea1.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.accountStatusDescription) {
                flag = item.accountStatusDescription.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.debtorCategoryName) {
                flag = item.debtorCategoryName.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.repName) {
                flag = item.repName.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.paymentTerm) {
                flag = item.paymentTerm.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.debtorCategoryName) {
                flag = item.debtorCategoryName.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.abn) {
                flag = item.abn.toString().toLowerCase().includes(searchText)
            }


        }
        return flag;
    }

    handleAccountChange(accountId) {
        let { customerAccountsData } = this.state;
        customerAccountsData.request.filterRequest.accountId = accountId;
        customerAccountsData.request.pageRequest.currentPage = 1;
        this.getCustomerAccounts(customerAccountsData);
        this.setState({ customerAccountsData });
    }

    handleChange(change, key) {
        let { customerAccountsData, workingCustomer } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (customerAccountsData.request.sortRequest.key === change) {
                    customerAccountsData.request.sortRequest.direction = !customerAccountsData.request.sortRequest.direction;
                } else {
                    customerAccountsData.request.sortRequest.key = change;
                    customerAccountsData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getCustomerAccounts(customerAccountsData);
                }
                break;
            case "pageSize":
                customerAccountsData.request.pageRequest[key] = change;
                customerAccountsData.request.pageRequest.currentPage = 1;
                this.getCustomerAccounts(customerAccountsData);
                break;
            case "currentPage":
                customerAccountsData.request.pageRequest[key] = change;
                this.getCustomerAccounts(customerAccountsData);
                break;
            case "customer-signup-invite-workingCustomer":
                workingCustomer = cloneDeep(change);
                workingCustomer.newEmail = workingCustomer.email;
                this.setState({workingCustomer, isOpenCustomerSignupInviteModal: true});
                break;
            case "customer-signup-invite-email":
                workingCustomer.newEmail = change;
                this.setState({workingCustomer});
                break;
            case "nothing":
                //just for skip
                break;
            default:
                customerAccountsData.request.filterRequest[key] = change;
                if (key === "accountStatus") {
                    customerAccountsData.request.filterRequest.excludeClosedOrInactive = false;
                }
                if (key !== "accountStatus") {
                    if (change === 'ALL') {
                        customerAccountsData.request.filterRequest[key] = 0
                    }
                    else {
                        customerAccountsData.request.filterRequest[key] = change;
                    }
                }
                customerAccountsData.request.pageRequest.currentPage = 1;
                this.getCustomerAccounts(customerAccountsData);
                this.setState({ customerAccountsData });
        }

    }

    handleCheckBoxChange(value, name) {
        let { customerAccountsData } = this.state;
        customerAccountsData.request.filterRequest[name] = value;
        customerAccountsData.request.pageRequest.currentPage = 1;
        this.getCustomerAccounts(customerAccountsData);
        this.setState({ customerAccountsData });
    }

    getCustomerAccountsHeading({ filterRequest }) {
        let customerAccountsHeading = [
            {
                key: "accountID",
                label: "Account",
                type: "link",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "company",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: false,
                searchNode: null

            },
            {
                key: "address1",
                label: "Address",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: true,
                searchNode: null

            },
            {
                key: "city",
                label: "Suburb",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "state",
                label: "State",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "postCode",
                label: "Post code",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "phone",
                label: "Phone No",
                type: "phone",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "debtorCategoryName",
                label: "Category",
                type: "category",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "paymentTerm",
                label: "Payment Terms",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "accountStatusDescription",
                label: "Status",
                type: "status",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "repName",
                label: "Rep",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "deliveryPreference",
                label: "Delivery Preference",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                extendedColumn: true,
                searchNode: null
            },
            {
                key: "abn",
                label: "ABN",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: true,
                searchNode: null
            }, {
                key: "email",
                label: "Email",
                type: "email",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "accountID",
                label: "Invite",
                type: "invite",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                extendedColumn: false,
                searchNode: null
            },
            {
                key: "orderDetails",
                label: "Stock Group(s)",
                type: "productDetails",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                extendedColumn: false,
                searchNode: null
            },
            /*,
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                extendedColumn: false,
                searchNode: null
            }*/
        ];

        return customerAccountsHeading;
    }

    getAccountStatusDescription(accountStatusDescription) {
        let color = '';
        switch (accountStatusDescription) {
            case 'Active':
                color = 'success';
                break;
            case 'Inactive':
                color = 'warning';
                break;
            case 'Closed':
                color = 'danger';
                break;
            default:
                color = 'primary';
                break;
        }
        return color;
    }

    getCategoryBadgeColor(category) {
        let color = '';
        switch (category) {
            case 'Blue':
                color = 'primary';
                break;
            case 'Gold':
                color = 'warning';
                break;
            case 'Silver':
                color = 'secondary';
                break;
        }

        return color;
    }

    toggleExtendedColumn(isHiddenExtendedColumn) {
        this.setState({ isHiddenExtendedColumn });
    }

    getColValue(customerHeading, customer) {
        let { hasDebtorWritePrivilege } = this.state;
        let value = customer[customerHeading.key];
        switch (customerHeading.type) {
            case "link":
                return <div className="text-left"><Link
                    className="btn btn-sm btn-primary"
                    style={{ color: "white" }}
                    title={"Click here to see account details"}
                    to={"/customer/#" + value}>{value}</Link></div>
                break;
            case "email":
                if (customer.email) {
                    if (validateEmail(customer.email)) {
                        return <a href={"mailto:" + customer.email}> { customer.email }</a >;
                    }
                    return <span>{customer.email}</span>
                }
                break;
            case "phone":
                if (customer.phone) {
                    return <a href={"tel:" + customer.phone}>{customer.phone}</a>;
                }
                break;
            case "status":
                return <div className="text-center"><Badge
                    color={this.getAccountStatusDescription(value)}>{value}</Badge></div>
                break;
            case "category":
                return <div className="text-center"><Badge color={this.getCategoryBadgeColor(value)}>{value}</Badge>
                </div>;
                break;
           /* case "action":
                return <div className="text-center">
                    <Link className="btn btn-sm btn-primary"
                        to={"/customers/manage/account/one?" + queryString.stringify({ accountID: customer.accountID })}>
                        <i className="fa fa-pencil mr-2"
                            aria-hidden="true" />
                        Edit</Link>&nbsp;
                    <Link className="btn btn-sm btn-outline-primary"
                        to={"/customers/manage/account/one/contacts?" + queryString.stringify({ accountID: customer.accountID })}>
                        <i className="fa fa-pencil mr-2"
                            aria-hidden="true" />
                        Contacts</Link>
                </div>;*/
            case "invite":
                if (!customer.email || (customer.email && !this.state.usersByEmail[customer.email])) {
                    if (this.state.alreadyRegisteredAccountID.includes(customer.accountID) && customer.email) {
                        return <div className="text-center">
                            <Button size={"sm"} color="warning" disabled={true} title={"already registered email"}>
                                <i className="fa fa-user-circle-o" aria-hidden="true" />&nbsp;
                                Registered
                            </Button>
                        </div>
                    }
                    else if (customer.email && this.state.alreadyInvited.includes(customer.email)) {
                        return <div className="text-center">
                            <Button size={"sm"} color="success" title={"Click to re-invite"}
                                disabled={hasDebtorWritePrivilege ? false : true}
                                onClick={() => this.handleChange(customer, "customer-signup-invite-workingCustomer")}>
                                <i className="fa fa-check" aria-hidden="true" />&nbsp;
                                Invited
                            </Button>
                        </div>
                    }
                    else
                    {
                        return <div className="text-center">
                            <Button size={"sm"} color="info" title={"Click to invite"}
                                disabled={hasDebtorWritePrivilege ? false : true}
                                onClick={() => this.handleChange(customer, "customer-signup-invite-workingCustomer")}>
                                {customer.loadingInvite ? <Spinner className={"mr-2"} color={"light"} size="sm"/>
                                    : <i className="fa fa-user-plus mr-2" aria-hidden="true"/>}
                                {customer.loadingInvite ? "Inviting" : "Invite"}
                            </Button>
                        </div>
                    }

                }
                else if ((customer.email && this.state.usersByEmail[customer.email])) {
                        return <div className="text-center">
                            <Button size={"sm"} color="warning" disabled={true} title={"already registered email"}>
                                <i className="fa fa-user-circle-o" aria-hidden="true"/>&nbsp;
                                Registered
                            </Button>
                        </div>
                }

            default:
                return <span>{value}</span>;
        }
    }

    printDebtorSummaryInExcel = () => {
        let request = cloneDeep(this.state.customerAccountsData.request);

        this.setState({ downloading: true });
        this.customerService.generateDebtorSummaryInExcelReport(request).then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "DEBTOR-SUMMARY " + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            console.log(error.data)
        });
    };

    sendInvitationEmail({accountID, newEmail}) {
        let { customerAccountsData } = this.state;
        let index = findIndex(customerAccountsData.response.records, 'accountID', accountID);
        if (index > -1) {
            customerAccountsData.response.records[index].loadingInvite = true;
            this.setState({customerAccountsData, isLoadingInvitation: true});
        }
        this.customerService.updateEmailAndSendSignupInvite(accountID, newEmail).then(res => {
            if (res.status === 200 || res === '200') {
                index = findIndex(customerAccountsData.response.records, 'accountID', res.data.accountID);
                if (index > -1) {
                    customerAccountsData.response.records[index].loadingInvite = false;
                    customerAccountsData.response.records[index].email = res.data.email;
                }
                this.setState({
                    customerAccountsData,
                    isLoadingInvitation: false,
                    isOpenCustomerSignupInviteModal: false
                });
                this.getCustomerAccounts(this.state.customerAccountsData);
                toast.success("Invitation sent!");
            }
        }).catch(error => {
            index = findIndex(customerAccountsData.response.records, 'accountID', accountID);
            if (index > -1) {
                customerAccountsData.response.records[index].loadingInvite = false;
            }
            this.setState({customerAccountsData, isLoadingInvitation: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {
            customerAccountsData, loading, searchText, isHiddenExtendedColumn, downloading, hasDebtorWritePrivilege,
            reps, debtorCategory, debtorPaymentTerms, debtorAccountStatus, isOpenCustomerSignupInviteModal, workingCustomer, isLoadingInvitation
            ,stateOption} = this.state;
        let { pageRequest, sortRequest, filterRequest } = customerAccountsData.request;
        let customerAccountsHeading = this.getCustomerAccountsHeading(customerAccountsData.request);

        return (
            <div>
                <h5 className="mb-2">Customer List</h5>
                <div>{
                    <CustomerAccountsDetailsFilter
                        handleChange={this.handleChange}
                        handleAccountChange={this.handleAccountChange}
                        filterRequest={filterRequest}
                        reps={reps}
                        debtorPaymentTerms={debtorPaymentTerms}
                        debtorAccountStatus={debtorAccountStatus}
                        debtorCategory={debtorCategory}
                        stateOption={stateOption}
                        isHiddenExtendedColumn={isHiddenExtendedColumn}
                        isIncludeChildren={filterRequest.isIncludeChildren}
                        excludeClosedOrInactive={filterRequest.excludeClosedOrInactive}
       
                    />
                }
                </div>

                <br />
                <Card>
                    <CardBody>
                        <Row className={"align-items-center"}>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {
                                        loading
                                            ? <Spinner size={"sm"} className="mr-2" color="primary" />
                                            : <div>
                                                <span className="mr-2">
                                                    <a href={"javascript:void(0)"}
                                                        onClick={() => this.handleCheckBoxChange(!filterRequest.excludeClosedOrInactive, "excludeClosedOrInactive")}>
                                                        <i className={classnames("mt-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": filterRequest.excludeClosedOrInactive,
                                                            "fa-square-o": !filterRequest.excludeClosedOrInactive,
                                                        }
                                                        )} />
                                                    </a>&nbsp;Exclude Closed and Inactive
                                                </span>
                                                <span>
                                                    <a href={"javascript:void(0)"}
                                                        onClick={() => this.handleCheckBoxChange(!filterRequest.isIncludeChildren, "isIncludeChildren")}>
                                                        <i className={classnames("mt-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": filterRequest.isIncludeChildren,
                                                            "fa-square-o": !filterRequest.isIncludeChildren,
                                                        }
                                                        )} />
                                                    </a>&nbsp;Include Children
                                                 </span>
                                            </div>
                                    }
                                </div>
                            </Col> 
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    {hasDebtorWritePrivilege ? <Link size={"sm"}
                                        className={"btn btn-sm btn-primary mr-2"}
                                        to={"/customer/new-account"}
                                    >
                                        <i class="fa fa-user-plus mr-2" aria-hidden="true"></i>
                                        Create New Account
                                    </Link> : null}
                                    <Button color={"primary"}
                                        size={"sm"}
                                        outline={isHiddenExtendedColumn}
                                        title={isHiddenExtendedColumn ? 'Show Extended columns' : 'Hide Extended columns'}
                                        onClick={() => this.toggleExtendedColumn(!isHiddenExtendedColumn)}>
                                        <i className={classnames("fa", "mr-2", {
                                            "fa-eye": isHiddenExtendedColumn,
                                            "fa-eye-slash": !isHiddenExtendedColumn,
                                        })} aria-hidden="true" />
                                        {isHiddenExtendedColumn ? "Extended Columns" : "Extended Columns"}
                                    </Button>
                                    {/*<InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)} />
                                    </InputGroup>*/}
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        <th className="align-middle">S.No</th>
                                        {(customerAccountsHeading || []).filter(item => isHiddenExtendedColumn ? (!item.extendedColumn) : true).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, item.sorterApplicable ? "sortKey" : "nothing")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    {
                                        /*<tr>
                                            <td></td>
                                            {(customerAccountsHeading || []).filter(item => isHiddenExtendedColumn ? (!item.extendedColumn) : true).map((item, index) => {
                                                return (
                                                    <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                        {item.searchNode}
                                                    </td>
                                                );
                                            })}
                                        </tr>*/
                                    }
                                </thead>

                                <tbody>
                                    {(customerAccountsData.response.records || []).filter((customer) => this.searchFunction(customer, searchText)).map((customer, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            {(customerAccountsHeading || []).filter(item => isHiddenExtendedColumn ? (!item.extendedColumn) : true).map((item, headingIndex) => {
                                                return (
                                                    <td key={headingIndex}>
                                                        {this.getColValue(item, customer)}
                                                    </td>
                                                );
                                            })}

                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 440 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                Showing
                                                {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {customerAccountsData.response.totalRecords}
                                                {' '}entries
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                        &nbsp;&nbsp;
                                        <Button className={'btn btn-outline-success btn-sm '} outline={true}
                                            title={'Click here to export data'}
                                            onClick={this.printDebtorSummaryInExcel} disabled={downloading}>Export
                                            {downloading
                                                ? <Spinner size="sm"
                                                    className={"ml-2"}
                                                    style={{ color: "green" }} />
                                                : <i className="fa fa-file-excel-o ml-2" aria-hidden="true" />}
                                        </Button>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={customerAccountsData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                {
                    isOpenCustomerSignupInviteModal
                        ? <CustomerSignupInviteModal
                            form={workingCustomer}
                            isOpen={isOpenCustomerSignupInviteModal}
                            toggle={this.toggleCustomerSignupInviteModal}
                            handleChange={this.handleChange}
                            handleSubmit={this.sendInvitationEmail}
                            loading={isLoadingInvitation}
                        />
                        : null
                }
                <ToastContainer />
            </div>
        );
    }
}
