import React, {Component} from 'react';
import { CardBody, Card } from 'reactstrap';
import {Link} from 'react-router-dom';
import {toast} from "react-toastify";
import {handleErrorMessage} from '../../services/CommonService';
import retailService from '../../services/retail/RetailService';
import DebtorRetailUploadCompanyLogoModal from '../modal/DebtorRetailUploadCompanyLogoModal';

const baseUrl = window.location.origin;

export default class UploadCompanyLogo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpenModal: false,
            loading: false,
            accountID: this.props.accountID,
            debtorDetail: {}
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.getCompanyLogo = this.getCompanyLogo.bind(this);
        this.setModalActive = this.setModalActive.bind(this);
    }

    componentDidMount() {
        this.getCompanyLogo();
    }

    componentWillReceiveProps(nextProps) {
        let {accountID} = this.state;
        if (nextProps.accountID != accountID) {
            accountID = nextProps.accountID;
            this.setState({accountID}, () => {
                this.getCompanyLogo()
            })
        }
    }

    getCompanyLogo() {
        let {accountID, debtorDetail} = this.state;
        if (accountID) {
            retailService.getDebtorsBasicDetail(accountID).then(response => {
                if (response.data) {
                    debtorDetail = response.data;
                }
                console.log(debtorDetail);
                this.setState({debtorDetail});
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    setModalActive() {
        this.setState({isOpenModal: true});
    }

    toggleModal(data) {
        this.setState({ isOpenModal: data });
        this.getCompanyLogo();
    }

    render() {
        let {isOpenModal, accountID, debtorDetail} = this.state;
        return (
            <div>
                <Card>
                    <CardBody className={"p-0"}>
                        <div className={"text-center"}>

                            {debtorDetail.imageFile ? <img
                                style={{
                                    width: '130px',
                                    height: '130px', 
                                }}
                                title="Click here to download logo."
                                className={" m-2"}
                                src={baseUrl + "/api/file/generate/retail/logo?FileName=" + debtorDetail.imageFile.fileName} />
                                : 
                                <Link size="xs" style={{ "fontSize": "36px" }}>
                                    <span class="fa-stack fa-2x">
                                        <i class="fa fa-circle fa-stack-2x"></i>
                                        <i class="fa fa-user fa-stack-1x fa-inverse"></i>
                                    </span>
                                    </Link>
                            }
                            <span className={"fa-stack-1x"} style={{ "left": "53px","top": "100px", "fontSize":"23px" }}>
                                <Link size="xs"
                                    onClick={this.setModalActive}
                                    title="Click here to add Company Logo">
                                    <i className={"fa fa-pencil fa-stack-1x"} />
                                </Link>
                            </span>
                            <h6>{debtorDetail.companyName}</h6>
                            <p>{debtorDetail.email}</p>
                        </div>
                    </CardBody>
                </Card>
                {
                    isOpenModal ?
                        <DebtorRetailUploadCompanyLogoModal
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            accountID={accountID}
                            debtorDetail={debtorDetail}
                        /> : null
                }
            </div>

        );
    }
}
