import React, {Component} from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Row, Col, Label, FormGroup, Input, Spinner, Table,
} from 'reactstrap';
import {
    toast, ToastContainer
} from "react-toastify";
import ApiLogService from "../../services/ApiLogService";
import {handleErrorMessage} from "../../services/CommonService";
import axios from 'axios';
import {authHeader} from '../../helpers/auth-header';
import {cloneDeep} from 'lodash';
import {requestMethodOptions} from '../../store/AppConstants';


export default class ApiRetryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingSubmit: false,
            request: {
                method: "",
                endpoint: "",
                payload: {},
                timeout: 3600
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.recallApi = this.recallApi.bind(this);
    }

    componentDidMount() {
        let {request} = this.state;
        let {log} = this.props;
        if (log) {
            request.headers = authHeader();
            request.method = log.requestType;
            request.endpoint = log.url;
            request.payload = log.payload;
            this.setState({request});
        }
    }

    handleChange(key, change) {
        let {request} = this.state;
        switch (key) {
            default:
                request[key] = change;
        }
        this.setState({request});
    }

    recallApi() {
        let {log} = this.props;
        let {request} = this.state;


        let apiRequest = {
            headers: request.headers,
            method: request.method,
            url: request.endpoint,
            data: request.payload,
            timeout: request.timeout
        };

        this.setState({isLoadingSubmit: true});

        return axios(apiRequest).then(res => {
            this.setState({isLoadingSubmit: false});
            this.props.toggle(false);
            toast.success("Request completed");
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingSubmit: false});
        });
    }


    render() {
        let {isOpen, toggle, log} = this.props;
        let {isLoadingSubmit, request} = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Retry
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <tbody>
                        <tr>
                            <th className="align-middle">
                                Request Method
                            </th>
                            <td className="align-middle">
                                <Input type="select" name="method" value={request.method}
                                       onChange={(e) => this.handleChange("method", e.target.value)}>
                                    {
                                        (requestMethodOptions).map((option, index) =>
                                            <option key={index} value={option}>{option}</option>
                                        )
                                    }
                                </Input>
                            </td>
                        </tr>
                        <tr>
                            <th className="align-middle">
                                End point
                            </th>
                            <td className="align-middle">
                                <Input type="textarea" name="endpoint" value={request.endpoint}
                                       onChange={(e) => this.handleChange("endpoint", e.target.value)}>
                                </Input>
                            </td>
                        </tr>
                        <tr>
                            <th className="align-middle">
                                Payload
                            </th>
                            <td className="align-middle">
                                <Input
                                    type="textarea"
                                    name="payload"
                                    value={request.payload}
                                    rows="10"
                                    onChange={(e) => this.handleChange("payload", e.target.value)}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <div className="text-right">
                            <Button color={"primary"} size='sm' className={"mr-2"}
                                    disabled={isLoadingSubmit}
                                    onClick={this.recallApi}>
                                {isLoadingSubmit
                                    ? <Spinner className={"mr-2"} size={"sm"}/>
                                    : <i className="fa fa-paper-plane mr-2"/>
                                }
                                {isLoadingSubmit ? "Sending" : "Send"}
                            </Button>
                            <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}>
                                <i className="fa fa-times mr-2"/>
                                Cancel
                            </Button>
                        </div>
                    </Row>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}