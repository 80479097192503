import {authHeader} from '../helpers/auth-header';
import {config} from '../helpers/config'
import axios from 'axios';


export default class CommonNoteService {


    saveCommonNote(commonNote) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: commonNote,
            url: config.apiUrl + 'api/common/note/save'

        };
        return axios(request)

    }


}
