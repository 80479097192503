import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    Table,
    FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import { handleErrorMessage, getDateString, getDateObj, findIndex } from '../../../services/CommonService';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchWINOCreditor from '../../../components/search/SearchCreditorAcccount';
import UserService from '../../../services/UserService';
import SearchWINOnlineStock from '../../../components/search/SearchWINOnlineStock';
import NumberFormat from "react-number-format";
import PurchaseInvoiceService from '../../../services/WINOInventory/PurchaseInvoiceService';
import PurchaseOrderItemModal from '../../../components/WINOInventory/Modal/PurchaseOrderItemsModal';

export default class ManagePurchaseInvoice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            purchaseInvoiceID: 0,
            purchaseInvoiceObj: {
                id: 0,
                invDate: null,
                supplierAccountID: 0,
                supplier: {},
                refNumber: '',
                other: '',
                freight: '',
                discount: '',
                exchangeRate: '',
                gst: '',
                subTotal: '',
                description: "",
                currency: "",
                createdByUser: {},
                purchaseInvoiceItems: [],
            },
            formErrors: {
                invoiceItems: [],
            },
            saving: false,
            loading: false,
            currentUser: {},
            latestInvoiceNum: 0,
            loadingInvoiceNum: false,
        };

        this.userService = new UserService();
        this.refresh = this.refresh.bind(this);
        this.submitInvoice = this.submitInvoice.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleItemModal = this.toggleItemModal.bind(this);
        this.onSelectItems = this.onSelectItems.bind(this);

    }

    componentDidMount() {
        let { purchaseInvoiceID } = this.state;
        let currentUser = this.userService.getLoggedInUser();
        this.setState({ currentUser });
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            purchaseInvoiceID = searchParams.purchaseInvoiceID;
            this.setState({ purchaseInvoiceID }, () => {
                this.refresh()
            })
        }
        else {
            this.setState({ loadingInvoiceNum: true });
            PurchaseInvoiceService.getLastInvNum().then(response => {
                this.setState({ latestInvoiceNum: response.data, loadingInvoiceNum: false });
            }).catch(error => {
                this.setState({ loadingInvoiceNum: false });
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    refresh() {
        if (this.state.purchaseInvoiceID) {
            this.setState({ loading: true })
            PurchaseInvoiceService.getOnePurchaseInvoice(this.state.purchaseInvoiceID).then(response => {
                let purchaseInvoiceObj = response.data;
                this.convertDateStringToDate(purchaseInvoiceObj);
                this.setState({ purchaseInvoiceObj, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    convertDateStringToDate(purchaseInvoiceObj) {
        if (purchaseInvoiceObj.invDate) {
            purchaseInvoiceObj.invDate = getDateObj(purchaseInvoiceObj.invDate, 'DD/MM/YYYY');
        }
        return purchaseInvoiceObj;
    }

    handleChange(change, key, type) {
        let { purchaseInvoiceObj, formErrors } = this.state;
        purchaseInvoiceObj[key] = change;
        formErrors[key] = undefined;

        if (key === 'supplier') {
            if (change && change.isLocalCurrency) {
                purchaseInvoiceObj.isLocalCurrency = true;
                purchaseInvoiceObj['exchangeRate'] = 1;
                formErrors['exchangeRate'] = undefined;
            }
            else {
                purchaseInvoiceObj.isLocalCurrency = false;
            }
        }
        this.setState({ purchaseInvoiceObj, formErrors });
    }

    handleInvoiceItemChange(value, key, index) {
        let { purchaseInvoiceObj, formErrors } = this.state;
        let { purchaseInvoiceItems } = purchaseInvoiceObj;
        purchaseInvoiceItems[index][key] = value
        if (formErrors.invoiceItems[index]) {
            formErrors.invoiceItems[index][key] = undefined;
        }

        purchaseInvoiceObj = this.calculateSubTotal(purchaseInvoiceObj);
        this.setState({ purchaseInvoiceObj, formErrors });

    }


    validateForm(purchaseInvoiceObj) {
        let formErrors = { invoiceItems: [] };
        let valid = true;

        if ((purchaseInvoiceObj.supplier === undefined) || (purchaseInvoiceObj.supplier === null) || (purchaseInvoiceObj.supplier !== null && !purchaseInvoiceObj.supplier.id)) {
            formErrors.supplier = "Please select supplier";
            valid = false;
        }

        if (purchaseInvoiceObj.discount === null || purchaseInvoiceObj.discount === undefined || purchaseInvoiceObj.discount === '') {
            formErrors.discount = "Please enter discount";
            valid = false;
        }

        if (purchaseInvoiceObj.gst === null || purchaseInvoiceObj.gst === undefined || purchaseInvoiceObj.gst === '') {
            formErrors.gst = "Please enter gst";
            valid = false;
        }

        if (purchaseInvoiceObj.freight === null || purchaseInvoiceObj.freight === undefined || purchaseInvoiceObj.freight === '') {
            formErrors.freight = "Please enter freight";
            valid = false;
        }

        if (purchaseInvoiceObj.other === null || purchaseInvoiceObj.other === undefined || purchaseInvoiceObj.other === '') {
            formErrors.other = "Please enter other";
            valid = false;
        }

        if (purchaseInvoiceObj.exchangeRate === null || purchaseInvoiceObj.exchangeRate === undefined || purchaseInvoiceObj.exchangeRate === '') {
            formErrors.exchangeRate = "Please enter exchangeRate";
            valid = false;
        }


        (purchaseInvoiceObj.purchaseInvoiceItems || []).forEach((item, index) => {
            formErrors.invoiceItems[index] = {};
            if (item.prodCode === null || item.prodCode === undefined || item.prodCode === '') {
                formErrors.invoiceItems[index].stock = "Please select stock";
                valid = false;
            }
            if (item.qty === null || item.qty === undefined || item.qty === '' || item.qty === 0 || item.qty === '0') {
                formErrors.invoiceItems[index].qty = "Please enter qty";
                valid = false;
            }

            if (item.cost === null || item.cost === undefined || item.cost === '' || item.cost === 0 || item.cost === '0') {
                formErrors.invoiceItems[index].cost = "Please enter cost";
                valid = false;
            }
        });


        return { valid: valid, formErrors: formErrors };
    }

    submitInvoice(e) {
        e.preventDefault();
        let req = cloneDeep(this.state.purchaseInvoiceObj);
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            toast.info("Please fill required details!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        else {
            req.supplierAccountID = req.supplier ? req.supplier.id : 0;
            if (req.invDate) {
                req.invDate = moment(req.invDate).format('DD/MM/YYYY');
            }

            this.setState({ saving: true, formErrors: { invoiceItems: [] } });
            PurchaseInvoiceService.saveOrUpdatePurchaseInvoice(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.purchaseInvoiceID) {
                        this.props.history.push('/keyway-inventory/purchase-invoice');
                        toast.success("Purchase invoice saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Purchase invoice updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    let purchaseInvoiceObj = response.data;
                    purchaseInvoiceObj = this.convertDateStringToDate(purchaseInvoiceObj);
                    this.setState({ saving: false, purchaseInvoiceObj });
                }

            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    addNewItem() {
        let { purchaseInvoiceObj } = this.state;
        let { purchaseInvoiceItems } = purchaseInvoiceObj;

        for (var i in purchaseInvoiceItems) {
            purchaseInvoiceItems[i].isEditing = false;
        }

        let item = {
            id: 0,
            isEditing: true,
            stock: {},
            prodCode: '',
            description: '',
            qty: '',
            cost: '',
        };

        purchaseInvoiceItems.push(item);
        purchaseInvoiceObj = this.calculateSubTotal(purchaseInvoiceObj);
        this.setState({ purchaseInvoiceObj });
    }

    handleItemAction(operation, index) {
        let { purchaseInvoiceObj } = this.state;
        let { purchaseInvoiceItems } = purchaseInvoiceObj;

        switch (operation) {
            case "delete":
                purchaseInvoiceItems.splice(index, 1);
                break;
            case "edit":
                for (var i in purchaseInvoiceItems) {
                    if (parseInt(i) === index) {
                        purchaseInvoiceItems[i].isEditing = true;
                    }
                    else {
                        purchaseInvoiceItems[i].isEditing  = false;
                    }
                }
            default:
                break;
        }
        purchaseInvoiceObj = this.calculateSubTotal(purchaseInvoiceObj);
        this.setState({ purchaseInvoiceObj });
    }

    calculateSubTotal(purchaseInvoiceObj) {
        let { purchaseInvoiceItems } = purchaseInvoiceObj;
        let subTotal = 0;
        purchaseInvoiceItems.map((item) => {
            subTotal = subTotal + (item.cost * item.qty);
        });

        purchaseInvoiceObj.subTotal = subTotal;
        return purchaseInvoiceObj;
    }

    openModal() {
        this.setState({ isOpenPurchaseOrderModal: true });
    }

    toggleItemModal(change) {
        this.setState({ isOpenPurchaseOrderModal: change });
    }

    checkIfItemExist(item) {
        let { purchaseInvoiceObj } = this.state;
        let { purchaseInvoiceItems } = purchaseInvoiceObj;
        let flag = false;
        purchaseInvoiceItems.forEach(p => {
            if (p.purchaseOrderItemID === item.id) {
                flag = true;
            }
        });
        return flag;
    }

    onSelectItems(data) {
        let { purchaseInvoiceObj } = this.state;
        let { purchaseInvoiceItems } = purchaseInvoiceObj;

        data.forEach(d => {
            d.purchaseOrderItemID = d.id;
            if (d.checked) {
                var isExist = this.checkIfItemExist(d);
                if (!isExist) {
                    d.id = 0;
                    d.prodCode = d.stock.prodCode;
                    purchaseInvoiceItems.push(d);
                }
            }
            else {
                let i = findIndex(purchaseInvoiceItems, 'purchaseOrderItemID', d.purchaseOrderItemID);
                if (i !== -1) {
                    purchaseInvoiceItems.splice(i, 1);
                }
            }
        });

        purchaseInvoiceObj = this.calculateSubTotal(purchaseInvoiceObj);
        this.setState({ isOpenPurchaseOrderModal: false, purchaseInvoiceObj });
    }

    render() {
        let { purchaseInvoiceObj, formErrors, loading, purchaseInvoiceID,
            loadingInvoiceNum, latestInvoiceNum, saving, isOpenPurchaseOrderModal } = this.state;
        let totalItemsCost = 0;
        let totalAmount = (parseFloat(purchaseInvoiceObj.subTotal) +
            parseFloat(purchaseInvoiceObj.freight) +
            parseFloat(purchaseInvoiceObj.gst)) - parseFloat(purchaseInvoiceObj.discount);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/keyway-inventory/purchase-invoice')}>Purchase
                        Invoice
                    </BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!purchaseInvoiceID ? 'Create' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>

                <div>
                    <h4>#{purchaseInvoiceID ? purchaseInvoiceID : !loadingInvoiceNum ? latestInvoiceNum + 1 :
                        <small>Loading...</small>}</h4>
                    <p><span className="text-muted">Entered by- </span>
                        {!purchaseInvoiceID ? this.state.currentUser.firstName : purchaseInvoiceObj.createdByUser.firstName}
                        <span className="text-muted">{' | '}</span>
                        {!purchaseInvoiceID ? getDateString(new Date(), 'ddd, DD MMM YYYY') : purchaseInvoiceObj.createdDate}
                        <span className="text-muted">{' from '}</span>
                        <strong>Purchase Invoice</strong>
                    </p>
                </div>

                {loading
                    ? <Spinner color={"primary"} />
                    :
                    <div>
                        <div>
                            <Card>
                                <CardBody>
                                    <p><strong>SUPPLIER DETAILS</strong></p>
                                    <div>
                                        <span className={"mb-1"}>Account</span>
                                        <SearchWINOCreditor
                                            handleAccountChange={(selectedSupplier) => this.handleChange(selectedSupplier, 'supplier')}
                                            selectedAccountID={purchaseInvoiceObj.supplier ? purchaseInvoiceObj.supplier.accountID:null}
                                            defaultAccountID={purchaseInvoiceObj.supplier ? purchaseInvoiceObj.supplier.accountID:null} />

                                        <FormText color="danger">{formErrors.supplier}</FormText>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <br />
                        <div>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Card>
                                        <CardBody>
                                            <p><strong>INVOICE OPTIONS</strong></p>
                                            <Row>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Label>Description</Label>
                                                    <Input
                                                        name="description"
                                                        type="text"
                                                        value={purchaseInvoiceObj.description || ''}
                                                        placeholder="write description here..."
                                                        onChange={(e) => this.handleChange(e.target.value, 'description')} />
                                                </Col>

                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-3"}>
                                                    <Label>Ref. no</Label>
                                                    <Input
                                                        name="refNumber"
                                                        type="text"
                                                        value={purchaseInvoiceObj.refNumber || ''}
                                                        placeholder="write ref number here..."
                                                        onChange={(e) => this.handleChange(e.target.value, 'refNumber')} />
                                                </Col>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                            <Card>
                                <CardBody>
                                    <p><strong>INVOICE ITEMS</strong>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                       {(purchaseInvoiceObj.supplier && purchaseInvoiceObj.supplier.id) ?
                                            <Button className="btn btn-sm"
                                                color="primary"
                                                title={'Click here to extract invoice items from purchase order'}
                                                onClick={this.openModal}>By Purchase Orders</Button>
                                            : null}
                                    </p>
                                    <div style={{ overflow: "auto", overflowY: "hidden" }} className="table-responsive">
                                        {(purchaseInvoiceObj.purchaseInvoiceItems && purchaseInvoiceObj.purchaseInvoiceItems.length > 0)
                                            ?
                                            <Table striped bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Sr.No</th>
                                                        <th colSpan={'2'} style={{ minWidth: '200px' }}>Stock</th>
                                                        <th className={"text-center"} style={{ minWidth: '150px' }}>Quantity</th>
                                                        <th className={"text-center"} style={{ minWidth: '150px' }}>Cost</th>
                                                        <th style={{ minWidth: '200px' }}>Description</th>
                                                        <th className={"text-center"} style={{ minWidth: '100px' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(purchaseInvoiceObj.purchaseInvoiceItems || []).map((item, index) => {
                                                        totalItemsCost += item.cost ? parseInt(item.cost) : 0;
                                                        return <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td colSpan={'2'}>
                                                                {item.isEditing ?
                                                                    <SearchWINOnlineStock
                                                                        handleSelection={(selectedProdCode) => this.handleInvoiceItemChange(selectedProdCode, 'prodCode', index)}
                                                                        selectedProdCode={item.prodCode}
                                                                        defaultProdCode={item.prodCode} />
                                                                    :
                                                                    <Input disabled type="text" value={item.prodCode} />}

                                                                <FormText
                                                                    color="danger">{formErrors.invoiceItems[index] ? formErrors.invoiceItems[index].stock : null}</FormText>

                                                            </td>
                                                            <td>
                                                                <Input
                                                                    invalid={formErrors.invoiceItems[index] ? formErrors.invoiceItems[index].qty !== undefined : false}
                                                                    name="qty"
                                                                    type="number"
                                                                    disabled={!item.isEditing}
                                                                    value={item.qty || ''}
                                                                    placeholder={"write qty here"}
                                                                    onChange={(e) => this.handleInvoiceItemChange(e.target.value, e.target.name, index)} />
                                                                <FormText
                                                                    color="danger">{formErrors.invoiceItems[index] ? formErrors.invoiceItems[index].qty : null}</FormText>

                                                            </td>
                                                            <td>
                                                                <Input
                                                                    invalid={formErrors.invoiceItems[index] ? formErrors.invoiceItems[index].cost !== undefined : false}
                                                                    name="cost"
                                                                    type="number"
                                                                    disabled={!item.isEditing}
                                                                    value={item.cost || ''}
                                                                    placeholder={"write cost here"}
                                                                    onChange={(e) => this.handleInvoiceItemChange(e.target.value, e.target.name, index)} />
                                                                <FormText
                                                                    color="danger">{formErrors.invoiceItems[index] ? formErrors.invoiceItems[index].cost : null}</FormText>
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    name="description"
                                                                    type="text"
                                                                    disabled={!item.isEditing}
                                                                    value={item.description || ''}
                                                                    placeholder={"write description here"}
                                                                    onChange={(e) => this.handleInvoiceItemChange(e.target.value, e.target.name, index)} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Button color={'primary'} size="sm" onClick={() => this.handleItemAction("edit", index)}>
                                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                </Button>&nbsp;&nbsp;
                                                                <Button color={"danger"}
                                                                    size="sm"
                                                                    title={"click here to delete"}
                                                                    onClick={() => this.handleItemAction("delete", index)}>
                                                                    <i className="fa fa-trash-o fa-lg" aria-hidden="true" />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                    <tr>
                                                        <td colSpan={'4'} className={"text-right"}><b>Items
                                                        Total</b></td>
                                                        <td className={"text-right"}>
                                                            <b>
                                                                <NumberFormat value={totalItemsCost}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    thousandSeparator={true}
                                                                    prefix={'$'} />
                                                            </b>
                                                        </td>
                                                        <td colSpan={'2'}></td>
                                                    </tr>
                                                </tbody>
                                            </Table> : null}
                                        
                                        <Button
                                            color={"primary"}
                                            size={"sm"}
                                            className={"ml-1"}
                                            onClick={this.addNewItem}>
                                            <i className="fa fa-cart-plus mr-2" aria-hidden="true" />
                                            Add new item
                                        </Button>
                                    </div>

                                </CardBody>
                            </Card>

                            <br />

                            {
                                (purchaseInvoiceObj.purchaseInvoiceItems && purchaseInvoiceObj.purchaseInvoiceItems.length > 0)
                                    ? <Card>
                                        <CardBody>
                                            <p><strong>INVOICE SUMMARY</strong></p>
                                            <Row>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Table bordered>
                                                        <tbody>
                                                            <tr>
                                                                <td>Items({purchaseInvoiceObj.purchaseInvoiceItems.length}){' '}Sub Total
                                                                </td>
                                                                <td>
                                                                    <div className="text-right">
                                                                        <NumberFormat
                                                                            value={purchaseInvoiceObj.subTotal}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            thousandSeparator={true}
                                                                            prefix={'$'} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Currency
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        invalid={formErrors.currency !== undefined}
                                                                        name="currency"
                                                                        type="text"
                                                                        value={purchaseInvoiceObj.currency || ''}
                                                                        placeholder="write currency here..."
                                                                        onChange={(e) => this.handleChange(e.target.value, 'currency')} />
                                                                    <FormText color="danger">{formErrors.currency}</FormText>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Freight
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        invalid={formErrors.freight !== undefined}
                                                                        name="freight"
                                                                        type="number"
                                                                        value={purchaseInvoiceObj.freight || ''}
                                                                        placeholder="write freight here..."
                                                                        onChange={(e) => this.handleChange(e.target.value, 'freight')} />
                                                                    <FormText color="danger">{formErrors.freight}</FormText>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Other
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        invalid={formErrors.other !== undefined}
                                                                        name="other"
                                                                        type="number"
                                                                        value={purchaseInvoiceObj.other || ''}
                                                                        placeholder="write other here..."
                                                                        onChange={(e) => this.handleChange(e.target.value, 'other')} />
                                                                    <FormText color="danger">{formErrors.other}</FormText>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    GST
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        invalid={formErrors.gst !== undefined}
                                                                        name="gst"
                                                                        type="number"
                                                                        value={purchaseInvoiceObj.gst || ''}
                                                                        placeholder="write gst here..."
                                                                        onChange={(e) => this.handleChange(e.target.value, 'gst')} />
                                                                    <FormText color="danger">{formErrors.gst}</FormText>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Discount
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        invalid={formErrors.discount !== undefined}
                                                                        name="discount"
                                                                        type="number"
                                                                        value={purchaseInvoiceObj.discount || ''}
                                                                        placeholder="write discount here..."
                                                                        onChange={(e) => this.handleChange(e.target.value, 'discount')} />
                                                                    <FormText color="danger">{formErrors.discount}</FormText>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Exchange Rate
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        invalid={formErrors.exchangeRate !== undefined}
                                                                        name="exchangeRate"
                                                                        type="number"
                                                                        disabled={purchaseInvoiceObj.isLocalCurrency}
                                                                        value={purchaseInvoiceObj.exchangeRate || ''}
                                                                        placeholder="write exchange rate here..."
                                                                        onChange={(e) => this.handleChange(e.target.value, 'exchangeRate')} />
                                                                    <FormText color="danger">{formErrors.exchangeRate}</FormText>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Amount</td>
                                                                <td>
                                                                    <div className="text-right">
                                                                        <NumberFormat
                                                                            value={totalAmount}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            thousandSeparator={true}
                                                                            prefix={'$'} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <br />
                                            <div className="text-center">
                                                <Button color={"primary"}
                                                    disabled={saving}
                                                    size={"lg"}
                                                    onClick={this.submitInvoice}>
                                                    {saving
                                                        ? <Spinner size="sm"
                                                            className={"mr-2"}
                                                            style={{ color: "white" }} />
                                                        : <i className="fa fa-floppy-o mr-2"
                                                            aria-hidden="true" />
                                                    }
                                                    {saving
                                                        ? "Submitting "
                                                        : "Submit "
                                                    }

                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    : null
                            }
                        </div>
                    </div>}

                {purchaseInvoiceObj.supplier ?
                    <PurchaseOrderItemModal
                        isOpen={isOpenPurchaseOrderModal}
                        supplierID={purchaseInvoiceObj.supplier.id}
                        toggle={this.toggleItemModal}
                        handleSubmit={this.onSelectItems}
                    />
                    : null}

                <ToastContainer />
            </div>
        );
    }
}
