import { authHeader } from '../../helpers/auth-header';
import { config } from '../../helpers/config'
import axios from 'axios';

export default class LeadService {

    getleads(selectedPage, pageSize, search, columns, selectedUser) {
        const requestBody = {
            selectedPage: selectedPage,
            pageSize: pageSize,
            SearchedInput: search,
            filterableColumns: columns,
            OwnerName: ((selectedUser !== null && selectedUser !== undefined) ? selectedUser.userId : "")
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/leads/filter'
        };
        return axios(request)
    }


    filterleads(selectedPage, pageSize, status, priority, LeadCreated, email, accountId, selectedUser) {
        const requestBody = {
            selectedPage: selectedPage,
            pageSize: pageSize,
            Status: status,
            Priority: priority,
            Email: email,
            AccountId: accountId,
            CreatedDate: LeadCreated,
            OwnerName: ((selectedUser !== null && selectedUser !== undefined) ? selectedUser.userId : "")
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/leads/filterby'
        };
        return axios(request)
    }


    searchSales(query) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ query: query }),
            url: config.apiUrl + 'api/admin/user/search'
            //leads/sales/search
        };
        return axios(request);
    }

}