import React, { Component } from 'react';
import OrderService from "../../services/OrderService";
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';

export default class SearchCustOrdNum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            defaultCustOrdNum: props.defaultCustOrdNum,
            custOrdNum: props.selectedCustOrdNum,
            loading: false,
            allCustOrdNums: [],
            inputValue: "",
        };
        this.orderService = new OrderService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.fetchOptions(this.state.defaultCustOrdNum || this.state.custOrdNum);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultCustOrdNum !== nextProps.defaultCustOrdNum) {
            if (nextProps.defaultCustOrdNum) {
                this.setState({
                    defaultCustOrdNum: nextProps.defaultCustOrdNum,
                    custOrdNum: nextProps.defaultCustOrdNum
                }, () => {
                    this.fetchOptions(nextProps.defaultCustOrdNum);
                })
            }
        }

        if (this.state.custOrdNum !== nextProps.selectedCustOrdNum) {
            if (!nextProps.selectedCustOrdNum) {
                this.setState({ selected: null, custOrdNum: "" })
            }
            else {
                this.setState({ custOrdNum: nextProps.selectedCustOrdNum })
            }
            this.fetchOptions(nextProps.selectedCustOrdNum);
        }
        if (
            this.state.includeChildren !== nextProps.includeChildren
            || this.state.includeClosed !== nextProps.includeClosed
            || this.state.includeInactive !== nextProps.includeInactive
        ) {
            this.setState({ includeChildren: nextProps.includeChildren, includeClosed: nextProps.includeClosed, includeInactive: nextProps.includeInactive }
                , () => { this.fetchOptions(this.state.defaultCustOrdNum) });
        }
    }

    handleSelect(selectedCustOrdNum, event) {
        let { selected, custOrdNum } = this.state;
        selected = selectedCustOrdNum;
        custOrdNum = selectedCustOrdNum ? selectedCustOrdNum.custOrdNum : "";
        switch (event) {
            case "selected":
                this.setState({ selected, custOrdNum });
                break;
            case "onChange":
            case "default":
            default:
                this.setState({ selected, custOrdNum });
                this.props.handleCustOrdNumChange(custOrdNum);
                break;
        }
        if (!custOrdNum) {
            this.fetchOptions("");
        }
    };

    fetchOptions(inputValue, callback) {
        let allCustOrdNums = [];
        this.orderService.searchCustOrdNum(inputValue).then(response => {
            let data = response.data;
            for (let i in data) {
                let custOrdNum = {
                    label: data[i],
                    value: data[i],
                    id: data[i],
                    custOrdNum: data[i],
                };
                allCustOrdNums.push(cloneDeep(custOrdNum));
                if (this.props.defaultCustOrdNum) {
                    if (data[i] === this.props.defaultCustOrdNum) {
                        this.handleSelect(custOrdNum, "default");
                    }
                }
                if (this.props.selectedCustOrdNum) {
                    if (data[i] === this.props.selectedCustOrdNum) {
                        this.handleSelect(custOrdNum, "selected");
                    }
                }
            }
            this.setState({ allCustOrdNums });
            if (callback) {
                return callback(allCustOrdNums)
            } else {
                return allCustOrdNums;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        inputValue = inputValue.trim();
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 0) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allCustOrdNums);
            }
        }
    };

    render() {
        return (
            <div>
                <AsyncSelect
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.allCustOrdNums}
                    loadOptions={this.loadOptions}
                    onChange={(selection) => this.handleSelect(selection, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                    placeholder={"Search..."}
                />
            </div>
        );
    }
}