import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Row, Table
} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import Pagination from "react-js-pagination";
import {getDateString, handleErrorMessage} from "../../services/CommonService";
import StockService from "../../services/StockService";
import cloneDeep from 'lodash/cloneDeep';
import SearchStock from "../search/SearchStock";
import * as queryString from "query-string";

export const maxDate = new Date();
export const minDate = moment().subtract(3, 'months').toDate();

export default class StockTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockTransactions: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "dateAdded",
                        direction: false
                    },
                    filterRequest: {
                        prodCode: '',
                        transactionType: "",
                        referenceNumber: "",
                        startDate: minDate,
                        endDate: maxDate,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            transTypes: [],
        };

        this.stockService = new StockService();
    }

    componentDidMount() {
        let {stockTransactions} = this.state;
        this.getStockTransaction(stockTransactions);
        this.getStockTransactionType();

    }

    getStockTransactionType() {
        this.stockService.getStockTransactionType().then(res => {
            this.setState({transTypes: res.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStockTransaction(stockTransactions) {
        this.setState({loading: true});
        let request = cloneDeep(stockTransactions.request);

        if (!request.filterRequest.transactionType) {
            request.filterRequest.transactionType = "";
        }
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }

        this.stockService.getStockTransactions(request).then(response => {
            stockTransactions.response = response.data;
            this.setState({stockTransactions, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.name) {
                flag = item.name.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {stockTransactions} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (stockTransactions.request.sortRequest.key === change) {
                    stockTransactions.request.sortRequest.direction = !stockTransactions.request.sortRequest.direction;
                } else {
                    stockTransactions.request.sortRequest.key = change;
                    stockTransactions.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockTransaction(stockTransactions);
                }
                break;
            case "pageSize":
                stockTransactions.request.pageRequest[key] = parseInt(change);
                stockTransactions.request.pageRequest.currentPage = 1;
                this.getStockTransaction(stockTransactions);
                break;
            case "currentPage":
                stockTransactions.request.pageRequest[key] = change;
                this.getStockTransaction(stockTransactions);
                break;


            default:
                if (key === 'prodCode') {
                    if (stockTransactions.request.filterRequest[key] !== change) {
                        stockTransactions.request.filterRequest[key] = change;
                        stockTransactions.request.pageRequest.currentPage = 1;
                        this.getStockTransaction(stockTransactions);
                        this.setState({stockTransactions: stockTransactions});
                    }
                }
                else {
                    stockTransactions.request.filterRequest[key] = change;
                    stockTransactions.request.pageRequest.currentPage = 1;
                    this.getStockTransaction(stockTransactions);
                    this.setState({stockTransactions: stockTransactions});
                }
        }

    }

    getStockTransactionHeading({filterRequest}) {
        let stockTransactionHeadings = [
            {
                key: "srNo",
                label: "Sr. No.",
                type: "text",
                colSpan: 1,
                width: 30,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,

            },
            {
                key: "prodCode",
                label: "Stock Code",
                type: "text",
                colSpan: 1,
                width: 60,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 2,
                searchNode: <div>
                    <SearchStock
                        handleSelection={(selectedStock) => this.handleChange(selectedStock ? selectedStock.prodCode : "", "prodCode")}
                        selected={filterRequest.prodCode}
                        defaultProdCode={filterRequest.prodCode}
                        filter={{bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                    />
                </div>

            },
            {
                key: "stockDescription",
                label: "Description",
                type: "text",
                colSpan: 1,
                width: 300,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 0,
                searchNode: null
            },
            {
                key: "stockTransactionType",
                label: "Type",
                type: "text",
                colSpan: 1,
                width: 55,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="select"
                                        name="transactionType"
                                        value={filterRequest.transactionType}
                                        onChange={(e) => this.handleChange(e.target.value, "transactionType")}
                                        placeholder="Search...">
                    <option value='0'>All</option>
                    {(this.state.transTypes || []).map((type, i) => (
                        <option key={i} value={type.description}>{type.description}</option>
                    ))}
                </Input>
                </div>

            },
            {
                key: "referenceNumber",
                label: "Ref. No.",
                type: "text",
                colSpan: 1,
                width: 90,
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="referenceNumber" name="referenceNumber"
                                        value={filterRequest.referenceNumber || ''}
                                        onChange={(e) => this.handleChange(e.target.value, "referenceNumber")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "qty",
                label: "Qty",
                type: "text",
                colSpan: 1,
                width: 35,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
            },
            /*{
                key: "price",
                label: "Price",
                type: "text",
                colSpan: 1,
                width: 60,
                maxWidth: 60,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "cost",
                label: "Cost",
                type: "text",
                colSpan: 1,
                width: 60,
                maxWidth: 60,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },*/
            {
                key: "balance",
                label: "Balance",
                type: "text",
                colSpan: 1,
                width: 40,
                sorterApplicable: true,
                searchNodeColSpan: 1,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "dateAdded",
                label: "Date",
                type: "text",
                colSpan: 1,
                width: 100,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <div className={"text-right mb-1"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.startDate}
                            onChange={date => this.handleChange(date, "startDate")}
                            selectsStart
                            startDate={filterRequest.startDate}
                            endDate={filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="Start date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                    <div className={"text-right"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.endDate}
                            onChange={date => this.handleChange(date, "endDate")}
                            selectsEnd
                            startDate={filterRequest.startDate}
                            endDate={filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="End date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>

                </div>
            }
        ];

        return stockTransactionHeadings;
    }

    render() {
        let {stockTransactions, loading, searchText} = this.state;
        let {pageRequest, sortRequest} = stockTransactions.request;
        let stockTransactionHeadings = this.getStockTransactionHeading(stockTransactions.request);
        return (
            <div>

                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading ? <p>Loading...</p> :
                                <p>Showing
                                    {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {stockTransactions.response.totalRecords}
                                    {' '}entries</p>
                            }
                        </div>
                    </Col>

                </Row>
                <div>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                        <tr>
                            {(stockTransactionHeadings || []).map((item, index) => {
                                return (
                                    <th key={index}
                                        onClick={() => this.handleChange(item.key, "sortKey")}
                                        colSpan={item.colSpan}
                                        className={item.labelClassName}
                                        style={{width: item.width, maxWidth: item.maxWidth}}>
                                        {item.label}
                                        {
                                            item.sorterApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                )} aria-hidden="true"/> : null
                                        }

                                    </th>
                                );
                            })}
                        </tr>
                        <tr>
                            {(stockTransactionHeadings || []).map((item, index) => {
                                if (item.searchNodeColSpan > 0) {
                                    return (
                                        <td key={index} colSpan={item.searchNodeColSpan}
                                            className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                }
                                return null;
                            })}
                        </tr>

                        </thead>

                        <tbody>
                        {(stockTransactions.response.records || []).filter((stockTransaction) => this.searchFunction(stockTransaction, searchText)).map((stockTransaction, index) =>

                            <tr key={index}>
                                <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                <td>
                                    <Link
                                        className="btn btn-sm btn-primary"
                                        style={{color: "white"}}
                                        title={"Click here to see stock details"}
                                        to={"/inventory/stock/enquiry?prodCode=" + stockTransaction.prodCode}>
                                        {stockTransaction.prodCode}</Link>
                                </td>
                                <td>{stockTransaction.stockDescription}</td>
                                <td>{stockTransaction.transactionType}</td>
                                <td className="text-center">
                                    {(stockTransaction.transactionType === "Invoice" && stockTransaction.referenceNumber > 0)
                                        ? <Link
                                            className="btn btn-primary btn-sm"
                                            style={{color: "white"}}
                                            title={"Click here to view orders in detail"}
                                            to={"/sales/order/view?" + queryString.stringify({OrdNum: stockTransaction.referenceNumber})}>{stockTransaction.referenceNumber}</Link>
                                        : <span>{stockTransaction.referenceNumber}</span>
                                    }
                                </td>
                                <td className={"text-right"}>{stockTransaction.qty}</td>
                                <td className={"text-right"}>
                                    <NumberFormat
                                        value={stockTransaction.balance}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}/>
                                </td>
                                <td>{stockTransaction.dateAdded}</td>
                            </tr>
                        )}

                        </tbody>
                    </Table>
                </div>
                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{maxWidth: 200}}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>


                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={stockTransactions.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>
                <ToastContainer/>
            </div>
        );
    }
}