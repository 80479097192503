import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import {
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Row, Table
} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import Pagination from "react-js-pagination";
import { getLocalDateStringFromUtcDateString, getDateString ,handleErrorMessage} from "../../services/CommonService";
import StockInventoryTransactionService from "../../services/StockInventoryTransactionService";
import SearchWINOnlineStock from '../../components/search/SearchWINOnlineStock';

export default class StockInventoryTransactionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockInventoryTransaction: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        prodCode: '',
                        stockInventoryTransactionType: '',
                        referenceNumber: null,
                        updatedBy: '',
                        createdBy: ''

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
        };
    }

    componentDidMount() {
        let {stockInventoryTransaction} = this.state;
        stockInventoryTransaction.request.filterRequest.prodCode = this.props.prodCode ? this.props.prodCode : '';
        this.setState({stockInventoryTransaction}, () => {
            this.getStockInventoryTransaction(stockInventoryTransaction);
        });

    }

    componentWillReceiveProps(nextProps) {
        let {stockInventoryTransaction} = this.state;
        if (stockInventoryTransaction.request.filterRequest.prodCode !== nextProps.prodCode) {
            stockInventoryTransaction.request.filterRequest.prodCode = this.props.prodCode ? this.props.prodCode : '';
            this.setState({stockInventoryTransaction}, () => {
                this.getStockInventoryTransaction(stockInventoryTransaction);
            });
        }
    }

    getStockInventoryTransaction(stockInventoryTransaction) {

        this.setState({loading: true});
        StockInventoryTransactionService.getStockInventoryTransaction(stockInventoryTransaction.request).then(response => {
            stockInventoryTransaction.response = response.data;
            this.setState({stockInventoryTransaction, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.name) {
                flag = item.name.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {stockInventoryTransaction: stockInventoryTransaction} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (stockInventoryTransaction.request.sortRequest.key === change) {
                    stockInventoryTransaction.request.sortRequest.direction = !stockInventoryTransaction.request.sortRequest.direction;
                } else {
                    stockInventoryTransaction.request.sortRequest.key = change;
                    stockInventoryTransaction.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockInventoryTransaction(stockInventoryTransaction);
                }
                break;
            case "pageSize":
                stockInventoryTransaction.request.pageRequest[key] = parseInt(change);
                stockInventoryTransaction.request.pageRequest.currentPage = 1;
                this.getStockInventoryTransaction(stockInventoryTransaction);
                break;
            case "currentPage":
                stockInventoryTransaction.request.pageRequest[key] = change;
                this.getStockInventoryTransaction(stockInventoryTransaction);
                break;


            default:
                if (key === 'prodCode') {
                    if (stockInventoryTransaction.request.filterRequest[key] !== change) {
                        stockInventoryTransaction.request.filterRequest[key] = change;
                        stockInventoryTransaction.request.pageRequest.currentPage = 1;
                        this.getStockInventoryTransaction(stockInventoryTransaction);
                        this.setState({ stockInventoryTransaction: stockInventoryTransaction });
                    }
                }
                else {
                    stockInventoryTransaction.request.filterRequest[key] = change;
                    stockInventoryTransaction.request.pageRequest.currentPage = 1;
                    this.getStockInventoryTransaction(stockInventoryTransaction);
                    this.setState({ stockInventoryTransaction: stockInventoryTransaction });
                }
        }

    }

    getStockInventoryTransactionHeading({filterRequest}) {
        let stockInventoryTransactionHeadings = [
            {
                key: "srNo",
                label: "Sr. No.",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",

            },
            {
                key: "prodCode",
                label: "Stock Code",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div><SearchWINOnlineStock
                    handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'prodCode')}
                    selectedProdCode={filterRequest.prodCode}
                    defaultProdCode={filterRequest.prodCode}/></div>

            },
            {
                key: "stockInventoryTransactionType",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div><Input type="text" id="stockInventoryTransactionType"
                                        name="stockInventoryTransactionType"
                                        value={filterRequest.stockInventoryTransactionType}
                                        onChange={(e) => this.handleChange(e.target.value, "stockInventoryTransactionType")}
                                        placeholder="Search..."/></div>

            },

            {
                key: "referenceNo",
                label: "Ref. No.",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                labelClassName: "align-middle",
                searchNode: <div><Input type="text" id="referenceNo" name="referenceNo"
                                        value={filterRequest.referenceNumber}
                                        onChange={(e) => this.handleChange(e.target.value, "referenceNumber")}
                                        placeholder="Search..."/></div>
            },
            /*{
                key: "qty",
                label: "Quantity",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },*/
            {
                key: "price",
                label: "Price",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                maxWidth: 60,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "cost",
                label: "Cost",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                maxWidth: 60,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "opening",
                label: "Opening",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "in",
                label: "In Qty",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                maxWidth: 50,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },

            {
                key: "out",
                label: "Out Qty",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                maxWidth: 50,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "balance",
                label: "Balance",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            /* {
                key: "year",
                label: "Year",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "month",
                label: "Month",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },

           {
                key: "updatedDate",
                label: "Last Updated Date",
                type: "text",
                colSpan: 1,
                minWidth: 180,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "updatedBy",
                label: "Last Updated By",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                searchNode: <div><Input type="text" id="updatedBy" name="updatedBy"
                    value={filterRequest.updatedBy}
                    onChange={(e) => this.handleChange(e.target.value, "updatedBy")}
                    placeholder="Search..." /></div>
            },*/
            {
                key: "createdDate",
                label: "Date",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "createdBy",
                label: "User",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                searchNode: <div><Input type="text" id="createdBy" name="createdBy"
                                        value={filterRequest.createdBy}
                                        onChange={(e) => this.handleChange(e.target.value, "createdBy")}
                                        placeholder="Search..."/></div>
            },
            /*{
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                maxWidth: 100,
            },*/

        ];

        return stockInventoryTransactionHeadings;
    }

    render() {
        let hideHeaders = this.props.hideHeaders;
        let {stockInventoryTransaction: stockInventoryTransaction, loading, searchText} = this.state;
        let {pageRequest, sortRequest} = stockInventoryTransaction.request;
        let stockInventoryTransactionHeadings = this.getStockInventoryTransactionHeading(stockInventoryTransaction.request);
        return (
            <div>
                {
                    hideHeaders
                        ? null
                        : <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href="javascript:void(0)"
                                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Stock Inventory Transaction</BreadcrumbItem>
                        </Breadcrumb>
                }


                <Card>
                    {
                        hideHeaders
                            ? null
                            : <CardHeader><h5>Stock Inventory Transaction </h5></CardHeader>
                    }


                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockInventoryTransaction.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>

                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(stockInventoryTransactionHeadings || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(stockInventoryTransactionHeadings || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(stockInventoryTransaction.response.records || []).filter((stockInventoryTransaction) => this.searchFunction(stockInventoryTransaction, searchText)).map((stockInventoryTransaction, index) =>

                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td><Link
                                            className="btn btn-sm btn-primary"
                                            style={{color: "white"}}
                                            title={"Click here to see stock details"}
                                            to={"/keyway-inventory/stock/enquiry?prodCode=" + stockInventoryTransaction.prodCode}>
                                            {stockInventoryTransaction.prodCode}</Link></td>
                                        <td>{stockInventoryTransaction.stockInventoryTransactionType}</td>
                                        <td>{stockInventoryTransaction.referenceNumber}</td>
                                        {/*  <td>{stockInventoryTransaction.qty}</td>*/}

                                        <td className={"text-right"}>
                                            <NumberFormat
                                                prefix={'$'}
                                                value={stockInventoryTransaction.price}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className={"text-right"}>
                                            <NumberFormat
                                                prefix={'$'}
                                                value={stockInventoryTransaction.cost}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className={"text-right"}>
                                            <NumberFormat
                                                value={stockInventoryTransaction.opening}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className={"text-right"}>
                                            <NumberFormat
                                                value={stockInventoryTransaction.in}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className={"text-right"}>
                                            <NumberFormat
                                                value={stockInventoryTransaction.out}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className={"text-right"}>
                                            <NumberFormat
                                                value={stockInventoryTransaction.balance}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={true}/>
                                        </td>
                                        {/*<td>{stockInventoryTransaction.year}</td>
                                            <td>{stockInventoryTransaction.month}</td>*/}
                                        <td>{getDateString(stockInventoryTransaction.createdDate, 'DD MMM YYYY')}</td>
                                        <td>{stockInventoryTransaction.createdBy}</td>
                                        {/*<td>{getDateString(stockInventoryTransaction.updatedDate, 'DD/MM/YYYY')}</td>
                                            <td>{stockInventoryTransaction.updatedBy}</td>
                                            <td>{stockInventoryTransaction.description}</td>*/}
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockInventoryTransaction.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}