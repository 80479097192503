import React, {Component} from 'react';
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

export default function ApplySelectionToMultipleItems(props) {

    if (props.isDisabled) {
        return null;
    }
    return (
        <UncontrolledDropdown>
            <DropdownToggle color={"link"} disabled={props.disabled}>
                <i className="fa fa-ellipsis-v fa-lg fa-fw" aria-hidden="true"/>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem header>Apply this selection on?</DropdownItem>
                <DropdownItem
                    onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemsIndex, "above")}>
                    <i className="fa fa-long-arrow-up fa-fw mr-2" aria-hidden="true"/>
                    Above all items
                </DropdownItem>
                <DropdownItem
                    onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemsIndex, "below")}>
                    <i className="fa fa-long-arrow-down fa-fw mr-2" aria-hidden="true"/>
                    Below all items
                </DropdownItem>
                <DropdownItem
                    onClick={() => props.handleApplyToAll(props.change, props.variableKey, props.itemsIndex, "all")}>
                    <i className="fa fa-arrows-v fa-fw mr-2" aria-hidden="true"/>
                    All items
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );

}