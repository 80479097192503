/**
 * @return {number}
 */
export function GetPrefilledPackageDetail(item) {
    let weight = 0;
    if (item.product && item.productLabel) {
        switch (item.product.code) {
            case "PTR":
                weight = 3;
                break;
            case "ZIPI":
                weight = 15;
                break;
            case "ROM":
                weight = 3;
                break;
            case "CTR":
                weight = 5;

                if (item.productLabel.model === "Motorised") {
                    weight += 3;
                }
                break;
            case "FAA":
                if (item.productLabel.hooding === "No Hood") {
                    weight = 35;//without hooding
                }
                else {
                    weight = 40; //hooding
                }
                //check motorised order
                if (item.productLabel.operation && item.productLabel.operation.includes('Motor')) {
                    weight += 3;
                }
                break;
            case "VEN":
                switch (item.productLabel.slatType) {
                    case "Basswood":
                        weight = 8;
                        break;
                    case "Fauxwood":
                        weight = 8;
                        break;
                    case "Aluminium":
                        weight = 3;
                        break;
                }
                break;
            case "VIN":
                weight = 3;
                break;
            case "VOD":
                if (item.productLabel.hooding === "No Hood") {
                    weight = 10;//without hooding
                }
                else {
                    weight = 15;// hooding
                }
                //check motorised order
                if (item.productLabel.operation && item.productLabel.operation.includes('Motor')) {
                    weight += 3;
                }
                break;
            case "ZIPE":
                if (item.productLabel.hooding === "No Hood") {
                    weight = 20;//without hooding
                }
                else {
                    weight = 30;// hooding
                }
                //check motorised order
                if (item.productLabel.operation && item.productLabel.operation.includes('Motor')) {
                    weight += 3;
                }
                break;
            case "ROL":
            case "ROLS":
                weight = 3;
                if (item.productLabel.operation && item.productLabel.operation.includes('Motor')) {
                    weight += 0.5;
                }
                break;
            case "ROLD":
                weight = 3;
                if (item.productLabel.operation && item.productLabel.operation.includes('Motor')) {
                    weight += 1;
                }
                break;
            case "HOS":
                if (item.productLabel.partType === "Tracks") {
                    weight = 5;
                }
                break;
            case "WID":
                if (item.productLabel.headBox === "HeadBox") {
                    weight = 40; //with headbox
                }
                else {
                    weight = 35; //without headbox;
                }
                //add motorised 
                if (item.productLabel.operation && item.productLabel.operation.includes('Motor')) {
                    weight += 5;
                }
                break;
            case "PRJ":
                if (item.productLabel.hooding === "No Hood") {
                    weight = 10;//without hooding
                }
                else {
                    weight = 15;// hooding
                }
                //check motorised order
                if (item.productLabel.operation && item.productLabel.operation.includes('Motor')) {
                    weight += 3;
                }
                break;
            case "CTS":
                weight = 10;
                break;

        }
    }
    return weight;
}

export function GetPrefilledWidthHeightPackageDetail(item) {
    let req = {
        width: 21,
        height: 21
    };
    if (item) {
        switch (item.code) {
            case "PTR":
            case "ZIPI":
            case "ROM":
            case "CTR":
            case "FAA":
            case "VEN":
            case "VIN":
            case "VOD":
            case "ZIPE":
            case "ROL":
            case "ROLS":
            case "ROLD":
            case "HOS":
            case "WID":
            case "PRJ":
                req.width = 21;
                req.height = 21;
                break;
            case "CTS":
                req.width = 21;
                req.height = 13;
                break;

        }
        return req;
    }
}

export const shipmentItemTypes = [{ label: 'Carton', value: 'Carton' }, { label: 'Bend', value: 'Bend' },
    { label: 'Tube', value: 'Tube' }, { label: 'Pallet', value: 'Pallet' }, {label:'Crate',value:'Crate'}];
export const shipmentLinekeys = ["qty", "lengthCm", "widthCm", "weightKg", "heightCm"];