import React, { Component } from 'react';
import FileSaver from 'file-saver';
import { Card, CardImg, CardBody, CardImgOverlay, CardTitle, Col, Row, Button, Spinner, CardSubtitle, CardText, Progress } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import NewsLetterService from "../../services/NewsLetterService";
import { findIndex } from "../../services/CommonService";
import queryString from 'query-string';
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';

export default class NewsLetterPageList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newsletters: [],
            isLoadingNewslettersList: false
        };
        this.newsLetterService = new NewsLetterService();
        this.onDocumentClickSuccess = this.onDocumentClickSuccess.bind(this);
    }

    onDocumentClickSuccess(item) {
        let { newsletters } = this.state;
        let index = findIndex(newsletters, 'id', item.id);
        newsletters[index].isDownloading = true;
        this.setState({ newsletters });
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    newsletters[index].requestProgressPercentage = percent;
                    this.setState({ newsletters });
                }
            }
        };
        newsletters[index].requestProgressPercentage = 0;
        this.setState({ newsletters });

        this.newsLetterService.getNewsLetterByName(item.fileName, options).then(response => {

            FileSaver.saveAs(response.data, item.description);
            newsletters[index].isDownloading = false;
            newsletters[index].requestProgressPercentage = 0;
            this.setState({ newsletters });
        }).catch(error => {
            console.log(error);
            newsletters[index].isDownloading = false;
            newsletters[index].requestProgressPercentage = 0;
            this.setState({ newsletters });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({ isLoadingNewslettersList: true });
        this.newsLetterService.showNewsLettersForCustomer().then(response => {
            this.setState({ newsletters: response.data, isLoadingNewslettersList: false });
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isLoadingNewslettersList: false });
        });
    }


    render() {
        let baseUrl = window.location.origin;
        let { isLoadingNewslettersList, newsletters } = this.state;
        return (
            <div>
                <div className="text-center">
                    <CustomerDashboardQuickLinks size={"sm"} />
                    <hr />
                </div>
                <div>
                    {isLoadingNewslettersList
                        ? <Spinner color={"primary"} />
                        : <div className="card-columns">
                            {
                                ((newsletters || []).length > 0) ?
                                    (newsletters).map((item, index) => {
                                        return <Card key={index}>
                                            {item.snapFileName
                                                ? <CardImg width="100%"
                                                    src={baseUrl + "/api/file/Generate/NewsLetter?FileName=" + item.snapFileName}
                                                    alt="Card image cap" />
                                                : null}
                                            <CardBody>
                                                <CardTitle>{item.title}</CardTitle>
                                                <Button onClick={() => {
                                                    this.onDocumentClickSuccess(item)
                                                }}
                                                    color={"primary"}
                                                    size={"sm"}
                                                >
                                                    {item.isDownloading
                                                        ? <Spinner size="sm"
                                                            className={"mr-2"}
                                                            style={{ color: "white" }} />
                                                        : <i className="fa fa-arrow-circle-down mr-2"
                                                            aria-hidden="true" />}
                                                    {item.isDownloading
                                                        ? "Downloading"
                                                        : "Download"}
                                                </Button>
                                                <div>
                                                    {item.requestProgressPercentage > 0
                                                        ? <Progress striped color="primary" value={item.requestProgressPercentage}
                                                            className={"mt-2"} />
                                                        : null
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    }) :
                                    <Card>
                                        <CardImg src="/img/NewsLetter/Coming_soon.png" alt="download" top width="100%" height="110" />
                                        <CardBody>
                                            <CardText>Coming Soon.</CardText>
                                        </CardBody>
                                    </Card>
                            }
                        </div>
                    }


                </div>
                <ToastContainer />
            </div>
        );
    }
}

{
    /*<Card
        title="Click here to download"
        className={classnames("radius-15", "hand-cursor", {
                "ds-7 mx-auto d-block jumbotron": highlightId == item.id,
                "ds-8": highlightId != item.id,
            }
        )}
        onClick={() => {
            this.onDocumentClickSuccess(item)
        }}>
        <CardBody>
            <div className="text-center"
                 style={{'height': '250px', 'width': '200px'}}>
                {
                    item.snapFileName
                        ?
                        <img
                            src={baseUrl + "/api/file/Generate/NewsLetter?FileName=" + item.snapFileName}
                        />
                        : null
                }
            </div>
            <div className="card-bottom">
                {item.fileName.replace(/.pdf|.PNG|.jepg|.jpg|.png|.JPEG|.JPG|.gif/g, '.pdf')}
            </div>
        </CardBody>
    </Card>*/
}