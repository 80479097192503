import React, {Component} from 'react';
import MaterialTitlePanel from "../header/header.component";
import {Link} from "react-router-dom";

import UserService from "../../services/UserService";
import {EventBus} from "../../components/events/event";
import Menu, {SubMenu, MenuItem} from 'rc-menu';
import '../../assets/css/app/rc-menu.css';
import {MOBILE_SCREEN_WIDTH_IN_PIXEL} from "../../store/AppConstants";

const styles = {
    sidebar: {
        width: 200,
    }
};

export default class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            selectedMenuItem: "",
            selectedSubMenuItem: ""
        };
        this.userService = new UserService();
        this.logout = this.logout.bind(this);
        this.reset = this.reset.bind(this);
        this.toggle = this.toggle.bind(this);
        this.selectMenuItem = this.selectMenuItem.bind(this);
    }

    componentDidMount() {
        this.setState({
            selectedMenuItem: "",
            selectedSubMenuItem: ""
        });
    }

    logout() {
        this.reset();
        EventBus.publish('login-logout', {});
    }

    reset() {
        localStorage.clear();
        this.setState({
            selectedMenuItem: "",
            selectedSubMenuItem: ""
        });
    }


    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    selectMenuItem(selectedMenuItem, selectedSubMenuItem) {
        //if screen is in Mobile View then close sidebar
        if (window.screen.width < MOBILE_SCREEN_WIDTH_IN_PIXEL) {
            this.props.toggleClose(false);
        }
        this.setState({
            selectedMenuItem: selectedMenuItem,
            selectedSubMenuItem: selectedSubMenuItem
        });

    }

    getMenuItem(menuItem, isRootMenu, title) {
        if (menuItem && menuItem.type !== 'link' && menuItem.children) {
            menuItem.children.sort(function (x, y) {
                return (x.sortIndex - y.sortIndex);
            })
        }

        menuItem.className = ""
        if (menuItem.children) {
            menuItem.children.forEach((item) => {
                item.className = ""
                if (item.children.children) {
                    item.children.children.forEach((item) => {
                        item.className = ""
                    })
                }
            })
        }

        return ((menuItem.type === 'link') ?
            <MenuItem key={menuItem.key} className={`p-0 ${menuItem.className}`} >
                <Link style={{ textDecoration: "none", padding: "10px 8px 10px 24px" }}
                    className={`d-inline-block h-100 w-100 ${menuItem.className}`}
                      onClick={() => this.selectMenuItem(menuItem.title, "")}
                      to={menuItem.path}>
                    {menuItem.icon
                        ? <span className="mr-2"><i className={menuItem.icon} aria-hidden="true"/></span>
                        : null
                    }
                    {menuItem.title}
                </Link>
            </MenuItem>

            : <SubMenu
                className={menuItem.className}
                title={menuItem.path ?
                    <Link to={menuItem.path} style={{ textDecoration: "none" }} className={`${menuItem.className}`}>{menuItem.title}</Link> :
                    <a href={"javascript:void(0)"} style={{ textDecoration: "none" }} className={`${menuItem.className}`}>
                        {menuItem.icon
                            ? <span className="mr-2"><i className={menuItem.icon} aria-hidden="true"/></span>
                            : null
                        }
                        {menuItem.title}
                    </a>}
                key={menuItem.key}>
                {menuItem.children.map((childrenItem) => { 
                    return this.getMenuItem(childrenItem, false, title)
                    }
                )}
            </SubMenu>);
    }


    render() {
        if (!this.props.isLoggedIn) {
            return null;
        }

        if (this.props.user && this.props.user.menu && this.props.user.menu.items && this.props.user.menu.items.length > 0) {

            this.props.user.menu.items.sort(function (x, y) {
                return (x.sortIndex - y.sortIndex);
            });

            return (<MaterialTitlePanel isLoggedIn={this.props.isLoggedIn} style={styles.sidebar} type={"sidebar"}>
                <Menu mode={this.props.docked ? "vertical-left" : "inline"}
                      style={{borderBottom: "none", boxShadow: "none"}}>
                    {this.props.user.menu.items.map((menuItem, index) => {
                            return this.getMenuItem(menuItem, true, menuItem.title)
                        }
                    )}
                </Menu>
            </MaterialTitlePanel>)
        }
        return null;
    }
};



