import React, {Component} from 'react';
import NumberFormat from "react-number-format";
import SearchWarehouseLocation from "../components/search/SearchWarehouseLocation";
import {Link} from "react-router-dom";
import {changeFormatOfDateString} from "../services/CommonService";
import * as queryString from "query-string";


export let stockReceiptItemStore = [

    {
        key: "prodCode",
        colSpan: 1,
        minWidth: 105,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return "Item code";
        },
        render: function (rowIndex, rows, componentRef) {
            return <Link className="btn btn-sm btn-primary" style={{color: "white"}}
                         title={"Click here to see stock details"}
                         to={"/inventory/stock/enquiry?prodCode=" + rows[rowIndex][this.key]}>
                {rows[rowIndex][this.key]}
            </Link>;
        }
    },  {
        key: "ordNum",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return "Purchase OrdNum";
        },
        render: function (rowIndex, rows, componentRef) {
            return <Link to={"/purchase/order/enquiry?" + queryString.stringify({ordNum: rows[rowIndex][this.key]})}
                         className="btn btn-sm btn-primary"
                         title={"Click Here to see the Order Details"}
                         style={{cursor: "pointer"}}>
                {rows[rowIndex][this.key]}
            </Link>;
        }
    },
    {
        key: "orderedQty",
        colSpan: 1,
        minWidth: 55,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-center";
        },
        renderLabel: function (rows, componentRef) {
            return "Ordered Qty"
                ;
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex][this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={true}
            />;
        }
    },
    {
        key: "receivedQty",
        colSpan: 1,
        minWidth: 45,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-center";
        },
        renderLabel: function (rows, componentRef) {
            return "Supplied Qty";
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex][this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={true}
            />;
        }
    },
    {
        key: "dateCreated",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return "Date Created";
        },
        render: function (rowIndex, rows, componentRef) {
            return changeFormatOfDateString(rows[rowIndex][this.key], "DD/MM/YYYY HH:mm:ss", 'DD MMM YYYY');
        }
    }, {
        key: "ammendedBy",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle";
        },
        renderLabel: function (rows, componentRef) {
            return "Added By"
                ;
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex][this.key];
        }
    },
    {
        key: "locationId",
        renderLabel: function (rows, componentRef) {
            return "Location";
        },
        colSpan: 1,
        minWidth: 125,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "align-middle text-center";
        },
        render: function (rowIndex, rows, componentRef) {
            if (rows[rowIndex][this.key]) {
                return <SearchWarehouseLocation
                    handleSelection={null}
                    locationID={rows[rowIndex][this.key]}
                    locationTypeID={null}
                    disabled={true}
                />
            }
            return null;
        }
    }
];