import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader, Spinner,} from 'reactstrap';
import skinOnlyUtil from '../skinOnly/SkinOnlyUtil'
import SkinOnlyBOMModalPrint from './SkinOnlyBOMModalPrint'
import StockService from '../../../../../../services/StockService'
import ReactToPrint from "react-to-print";

export default class SkinOnlyBOMModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: {},
            itemIndex: -1,
            stocksByProdCode: {},
            isLoading: false
        };
        this.stockService = new StockService();
    }

    componentDidMount() {
        let {product, itemIndex} = this.props;
        product = skinOnlyUtil.getItemStocks(product, itemIndex);
        let prodCodes = product.items[itemIndex].stocks.map(stock => stock.prodCode);

        this.setState({product, itemIndex, isLoading: true});
        this.stockService.getStocksByProdCode(prodCodes.join()).then(response => {
            this.setState({stocksByProdCode: response.data, isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
            console.error(error)
        })
    }


    render() {
        let {product, itemIndex, stocksByProdCode, isLoading} = this.state;
        let {isOpen, toggle, order, convertToCustom} = this.props;
        if (!(itemIndex > -1)) {
            return null;
        }
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    <ReactToPrint
                        trigger={() => {
                            return <Button color={"primary"} size={"sm"}>
                                <i className="fa fa-print mr-2"
                                   aria-hidden="true"/>Print
                            </Button>;
                        }}
                        content={() => this.componentRef}
                    />
                    <Button color={"primary"} size={"sm"} className={"ml-2"} onClick={()=> convertToCustom(stocksByProdCode)}>
                        <i className="fa fa-sliders mr-2"
                           aria-hidden="true"/>Convert to Custom
                    </Button>
                </ModalHeader>
                <ModalBody>
                    {
                        isLoading
                            ? <Spinner color={"primary"}/>
                            : <div>

                                <SkinOnlyBOMModalPrint product={product}
                                                      order={order}
                                                      itemIndex={itemIndex}
                                                      stocksByProdCode={stocksByProdCode}
                                                      ref={el => (this.componentRef = el)}/>
                            </div>
                    }
                </ModalBody>
            </Modal>
        )
    }
}