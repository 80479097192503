import React, { Component } from 'react';
import { Col, Row, Card, CardHeader, Breadcrumb, CardBody } from "reactstrap";
import { getColors, getYears, numberWithThousandSeparator, getDistinctValues, handleErrorMessage } from './../../services/CommonService';
import { Line } from 'react-chartjs-2';
import { ToastContainer, toast } from 'react-toastify';
import dashboardService from '../../services/DashboardService';


const now = new Date();
const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        duration: 0
    },
    hover: {
        animationDuration: 0
    },
    responsiveAnimationDuration: 0,
    legend: {
        position: 'top' 
    },
    scales: {
        xAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true
            }, scaleLabel: {
                display: true,
                labelString: "Weekly Orders"
            }
        }],
        yAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true,
                callback(value) {
                    //return <NumberFormat value={value} displayType={'text'} decimalScale={0} thousandSeparator={true} />
                    return Number(value).toLocaleString('en')
                }
            }, scaleLabel: {
                display: true,
                labelString: "Orders"
            }
        }]
    },

    plugins: {
        datalabels: {
            display: 'auto',
            anchor: 'end',
            clamp: true,
            align: 'end',
            offset: 3,
            backgroundColor: function (context) {
                return context.dataset.backgroundColor;
            },
            color: 'white',
            font: {
                weight: 'bold'
            },
            formatter: function (value, data) { 
                value = value;
                return "$" + value;
            }
        }
    }
};


export default class OrderCountChart extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.getOrderCounts = this.getOrderCounts.bind(this);
    }

    componentDidMount() {
        this.getOrderCounts();
    } 

    getOrderCounts() { 
        let colors = getColors();
        let indexOrder = 0;
        dashboardService.getProductBuilderSummaryforChart().then(response => {
            if (response.data) {
                let colors = getColors();
                let chartData = {
                    labels: [], 
                    datasets: []
                };
                 
                for (let index in response.data) {
                    response.data[index].week = "Week " + response.data[index].week;
                    chartData.labels.push(response.data[index].week);
                    let temp = {
                        label: response.data[index].week,
                        data: [],
                        fill: false,
                        borderColor: colors[indexOrder],
                        backgroundColor: colors[indexOrder]
                    };
                    for (let i in response.data) {
                        temp.data.push(response.data[i].totalOrders);
                    } 
                    chartData.datasets.push(temp);
                    indexOrder++;
                }
                this.setState({ chartData });
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        }); 
    }
     
    render() {

        let { chartData } = this.state; 
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col><h6 className={"mb-0"}>No. of Orders</h6></Col>
                            <Col className={"text-right"}>In last 2 months</Col>
                        </Row> 
                    </CardHeader>
                    <CardBody>
                            <div className="p-3">
                                <Line height={"500px"} data={chartData} options={chartOptions} />
                            </div> 
                    </CardBody> 
                </Card>
            </Col>
        );
    }
}