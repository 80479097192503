import axios from "../axios";
import axiosFileDownloader from '../axiosFileDownloader';

class WarehouseService {

    static Instance() {
        return new WarehouseService();
    }

    listWarehouse(req) {
        return axios.post('api/warehouse/list', req);
    }

    getOneWarehouse(warehouseID) {
        return axios.get('api/warehouse/one/?WarehouseID=' + warehouseID);
    }

    saveOrUpdateWarehouse(req) {
        return axios.post('api/warehouse/save', req);
    }

    deleteWarehouse(warehouseID) {
        return axios.delete('api/warehouse/delete/?WarehouseID=' + warehouseID);
    }

    listAllWarehouse() {
        return axios.get('api/warehouse/list/all');
    }

    getStockReceiveStats() {
        return axios.get('api/stock/receive/dashboard/stats');
    }

    getWarehouseLocationDropdownOptions(request) {
        return axios.post('api/warehouse/location/dropdown', request);
    }

    saveTransferQuantity(request) {
        return axios.post('api/warehouse/transfer/qunatity', request);
    }

    deleteStockWarehouseLocation(Id) {
        return axios.delete('api/warehouse/stock/location/delete?stockWarehouseLocationID=' + Id);
    }

    getWarehousePurchaseorderItem(request) {
        return axios.post('api/warehouse/purchaseOrder/item/list', request);
    }

    generatePurchaseOrderItemInExcel(request) {
        return axiosFileDownloader.post('api/warehouse/purchaseOrder/item/report', request);
    }

    getWarehouseLocationDetailsList(prodCode) {
        return axios.get('api/warehouse/locationDetails/list?prodCode=' + prodCode);
    }

    
}

export default WarehouseService.Instance();