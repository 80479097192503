import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Col,
    FormText,
    Input,
    Label,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import PurchaseOrderService from '../../../services/WINOInventory/PurchaseOrderService';
import {getDateObj, getDateString, handleErrorMessage} from '../../../services/CommonService';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchWINOCreditor from '../../../components/search/SearchCreditorAcccount';
import UserService from '../../../services/UserService';
import SearchWINOnlineStock from '../../../components/search/SearchWINOnlineStock';
import NumberFormat from "react-number-format";

export default class ManagePurchaseOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            purchaseOrderID: 0,
            prodCode: '',
            purchaseOrderObj: {
                id: 0,
                orderDate: null,
                etaDate: null,
                etdDate: null,
                crdDate: null,
                purchaseOrderStatusDescription: 'In Progress',
                supplierID: 0,
                supplier: {},
                purchaseOrderStatus: {},
                ref: '',
                note: '',
                currency: '',
                createdByUser: {},
                purchaseOrderItems: [],
                orderTotal: 0,
            },
            formErrors: {
                orderItems: [],
            },
            saving: false,
            loading: false,
            purchaseOrderStatuses: [],
            currentUser: {},
            latestOrderNum: 0,
            loadingOrdNum: false,
        };

        this.userService = new UserService();
        this.refresh = this.refresh.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.getPurchaseOrderStatuses = this.getPurchaseOrderStatuses.bind(this);
        this.addNewItem = this.addNewItem.bind(this);

    }

    componentDidMount() {
        let {purchaseOrderID,purchaseOrderObj,prodCode} = this.state;
        let currentUser = this.userService.getLoggedInUser();
        this.setState({ currentUser });
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            purchaseOrderID = searchParams.purchaseOrderID;
            purchaseOrderObj.supplier.accountID = searchParams.accountID;
            prodCode = searchParams.prodCode;
            this.setState({ purchaseOrderID,purchaseOrderObj,prodCode },() => {
                this.refresh()
            })
        }
        else {
            this.setState({loadingOrdNum: true});
            PurchaseOrderService.getLastOrderNum().then(response => {
                this.setState({latestOrderNum: response.data, loadingOrdNum: false});
            }).catch(error => {
                this.setState({loadingOrdNum: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        this.getPurchaseOrderStatuses();
    }

    getPurchaseOrderStatuses() {
        PurchaseOrderService.listPurchaseOrderStatus().then(res => {
            this.setState({purchaseOrderStatuses: res.data})
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    refresh() {
        if (this.state.purchaseOrderID) {
            this.setState({loading: true})
            PurchaseOrderService.getOnePurchaseOrder(this.state.purchaseOrderID).then(response => {
                let purchaseOrderObj = response.data;
                this.convertDateStringToDate(purchaseOrderObj);
                this.setState({purchaseOrderObj, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    convertDateStringToDate(purchaseOrderObj) {
        if (purchaseOrderObj.orderDate) {
            purchaseOrderObj.orderDate = getDateObj(purchaseOrderObj.orderDate, 'DD/MM/YYYY');
        }
        if (purchaseOrderObj.etaDate) {
            purchaseOrderObj.etaDate = getDateObj(purchaseOrderObj.etaDate, 'DD/MM/YYYY');
        }
        if (purchaseOrderObj.etdDate) {
            purchaseOrderObj.etdDate = getDateObj(purchaseOrderObj.etdDate, 'DD/MM/YYYY');
        }
        if (purchaseOrderObj.crdDate) {
            purchaseOrderObj.crdDate = getDateObj(purchaseOrderObj.crdDate, 'DD/MM/YYYY')
        }

        for (var i in purchaseOrderObj.purchaseOrderItems) {
            purchaseOrderObj.totalCost += parseInt(purchaseOrderObj.purchaseOrderItems[i].cost);
            if (purchaseOrderObj.purchaseOrderItems[i].etaDate) {
                purchaseOrderObj.purchaseOrderItems[i].etaDate = getDateObj(purchaseOrderObj.purchaseOrderItems[i].etaDate, 'DD/MM/YYYY')
            }

            if (purchaseOrderObj.purchaseOrderItems[i].etdDate) {
                purchaseOrderObj.purchaseOrderItems[i].etdDate = getDateObj(purchaseOrderObj.purchaseOrderItems[i].etdDate, 'DD/MM/YYYY')
            }

        }
        return purchaseOrderObj;
    }

    handleChange(change, key) {
        let {purchaseOrderObj, formErrors} = this.state;
        purchaseOrderObj[key] = change;
        formErrors[key] = undefined;
        this.setState({purchaseOrderObj, formErrors});
    }

    handleOrderItemChange(value, key, index) {
        let {purchaseOrderObj, formErrors} = this.state;
        let {purchaseOrderItems} = purchaseOrderObj;

        if (formErrors.orderItems[index]) {
            formErrors.orderItems[index][key] = undefined;
        }

        // if qty received and qty cancelled less than quantity, then only reflect the change
        if (key === 'qty' || key === 'qtyReceived' || key === 'qtyCancelled') {
            if (value === '') {
                value = 0;
            }
            if (key === 'qty') {
                purchaseOrderItems[index][key] = value;

                if (parseInt(purchaseOrderItems[index]['qtyReceived']) > value) {
                    purchaseOrderItems[index]['qtyReceived'] = 0;
                }

                if (parseInt(purchaseOrderItems[index]['qtyCancelled']) > value) {
                    purchaseOrderItems[index]['qtyCancelled'] = 0;
                }
            }
            purchaseOrderItems[index]['qty'] = purchaseOrderItems[index]['qty'] ? purchaseOrderItems[index]['qty'] : 0;

            if (key === 'qtyReceived') {
                if (value <= parseInt(purchaseOrderItems[index]['qty'])) {
                    purchaseOrderItems[index]['qtyReceived'] = value;
                }
            }

            if (key == 'qtyCancelled') {
                if (value <= parseInt(purchaseOrderItems[index]['qty'])) {
                    purchaseOrderItems[index]['qtyCancelled'] = value;
                }
            }
        }

        else {
            purchaseOrderItems[index][key] = value;
        }

        purchaseOrderObj = this.calculateItemTotal(purchaseOrderObj);
        this.setState({purchaseOrderObj, formErrors});


    }

    calculateItemTotal(purchaseOrderObj) {
        let {purchaseOrderItems} = purchaseOrderObj;
        let orderTotal = 0;
        purchaseOrderItems.map((item) => {
            orderTotal = orderTotal + (item.cost * item.qty);
        });

        purchaseOrderObj.orderTotal = orderTotal;
        return purchaseOrderObj;
    }


    validateForm(purchaseOrderObj) {
        let formErrors = {orderItems: []};
        let valid = true;

        if ((purchaseOrderObj.supplier === undefined) || (purchaseOrderObj.supplier === null) || (purchaseOrderObj.supplier !== null && !purchaseOrderObj.supplier.id)) {
            formErrors.supplier = "Please select supplier";
            valid = false;
        }

        if (purchaseOrderObj.id) {
            if ((purchaseOrderObj.purchaseOrderStatusDescription === undefined) || (purchaseOrderObj.purchaseOrderStatusDescription === null) || purchaseOrderObj.purchaseOrderStatusDescription === '') {
                formErrors.purchaseOrderStatusDescription = "Please select purchase order status";
                valid = false;
            }
        }

        (purchaseOrderObj.purchaseOrderItems || []).forEach((item, index) => {
            formErrors.orderItems[index] = {};
            if (item.stockProdCode === null || item.stockProdCode === undefined || item.stockProdCode === '') {
                formErrors.orderItems[index].stock = "Please select stock";
                valid = false;
            }
            if (item.qty === null || item.qty === undefined || item.qty === '' || item.qty === 0 || item.qty === '0') {
                formErrors.orderItems[index].qty = "Please enter qty";
                valid = false;
            }

            if (item.cost === null || item.cost === undefined || item.cost === '' || item.cost === 0 || item.cost === '0') {
                formErrors.orderItems[index].cost = "Please enter cost";
                valid = false;
            }
        });


        return {valid: valid, formErrors: formErrors};
    }

    submitOrder(e) {
        e.preventDefault();
        let req = cloneDeep(this.state.purchaseOrderObj);
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            toast.info("Please fill required details!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        else {
            req.supplierID = req.supplier ? req.supplier.id : 0;
            if (req.orderDate) {
                req.orderDate = moment(req.orderDate).format('DD/MM/YYYY');
            }
            if (req.etaDate) {
                req.etaDate = moment(req.etaDate).format('DD/MM/YYYY');
            }
            if (req.etdDate) {
                req.etdDate = moment(req.etdDate).format('DD/MM/YYYY');
            }
            if (req.crdDate) {
                req.crdDate = moment(req.crdDate).format('DD/MM/YYYY');
            }

            if (req.purchaseOrderItems !== null) {
                for (var i in req.purchaseOrderItems) {
                    if (req.purchaseOrderItems[i].etaDate) {
                        req.purchaseOrderItems[i].etaDate = moment(req.purchaseOrderItems[i].etaDate).format('DD/MM/YYYY');
                    }

                    if (req.purchaseOrderItems[i].etdDate) {
                        req.purchaseOrderItems[i].etdDate = moment(req.purchaseOrderItems[i].etdDate).format('DD/MM/YYYY');
                    }
                }
            }

            this.setState({saving: true, formErrors: {orderItems: []}});
            PurchaseOrderService.saveOrUpdatePurchaseOrder(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.purchaseOrderID) {
                        this.props.history.push('/keyway-inventory/purchase-order');
                        toast.success("Purchase order saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Purchase order updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    let purchaseOrderObj = response.data;
                    purchaseOrderObj = this.convertDateStringToDate(purchaseOrderObj);
                    this.setState({saving: false, purchaseOrderObj});
                }

            }).catch(error => {
                this.setState({saving: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    addNewItem() {
        let {purchaseOrderObj} = this.state;
        let {purchaseOrderItems} = purchaseOrderObj;

        let item = {
            id: 0,
            isEditing: true,
            stock: {},
            stockProdCode: '',
            supplierRef: '',
            description: '',
            etaDate: null,
            etdDate: null,
            qty: '',
            qtyReceived: 0,
            qtyCancelled: 0,
            cost: '',
            remarks: '',
            isComplete: false,

        };
        purchaseOrderItems.push(item);
        purchaseOrderObj = this.calculateItemTotal(purchaseOrderObj);
        this.setState({purchaseOrderObj});
    }

    selectCheckbox(value, name, index) {
        let {purchaseOrderObj} = this.state;
        let {purchaseOrderItems} = purchaseOrderObj;
        purchaseOrderItems[index][name] = !value;
        this.setState({purchaseOrderObj});
    }

    handleItemAction(operation, index) {
        let {purchaseOrderObj} = this.state;
        let {purchaseOrderItems} = purchaseOrderObj;

        switch (operation) {
            case "delete":
                purchaseOrderItems.splice(index, 1);
                break;
        }

        purchaseOrderObj = this.calculateItemTotal(purchaseOrderObj);
        this.setState({purchaseOrderObj});
    }


    render() {
        let { purchaseOrderObj, formErrors, loading, purchaseOrderID, loadingOrdNum, saving, prodCode } = this.state;
        let totalItemsCost = 0;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/keyway-inventory/purchase-order')}>Purchase
                        Order
                    </BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!purchaseOrderID ? 'Create' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>

                <div>
                    <h4>#{purchaseOrderID ? purchaseOrderID : !loadingOrdNum ? this.state.latestOrderNum + 1 :
                        <small>Loading...</small>}</h4>
                    <p><span className="text-muted">Entered by- </span>
                        {!purchaseOrderID ? this.state.currentUser.firstName : purchaseOrderObj.createdByUser.firstName}
                        <span className="text-muted">{' | '}</span>
                        {!purchaseOrderID ? getDateString(new Date(), 'ddd, DD MMM YYYY') : purchaseOrderObj.createdDate}
                        <span className="text-muted">{' from '}</span>
                        <strong>Purchase Order</strong>
                    </p>
                </div>

                {loading
                    ? <Spinner color={"primary"}/>
                    :
                    <div>
                        <div>
                            <Card>
                                <CardBody>
                                    <p><strong>SUPPLIER DETAILS</strong></p>
                                    <div>
                                        <span className={"mb-1"}>Account</span>
                                        <SearchWINOCreditor
                                            handleAccountChange={(selectedSupplier) => this.handleChange(selectedSupplier, 'supplier')}
                                            selectedAccountID={purchaseOrderObj.supplier ? purchaseOrderObj.supplier.accountID : null}
                                            defaultAccountID={purchaseOrderObj.supplier ? purchaseOrderObj.supplier.accountID : null} />

                                        <FormText color="danger">{formErrors.supplier}</FormText>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <br/>
                        <div>
                            <Row>

                                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                    <Card>
                                        <CardBody>
                                            <p><strong>ORDER OPTIONS</strong></p>
                                            <Row>

                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>

                                                    <Label>Purchase order status</Label>
                                                    <Input type={"select"}
                                                           disabled={purchaseOrderID === 0}
                                                           invalid={formErrors.purchaseOrderStatusDescription !== undefined}
                                                           name={"purchaseOrderStatusDescription"}
                                                           value={purchaseOrderObj.purchaseOrderStatusDescription || ''}
                                                           onChange={(e) => this.handleChange(e.target.value, "purchaseOrderStatusDescription")}>
                                                        <option value="" disabled>Select Purchase Order Status</option>
                                                        {this.state.purchaseOrderStatuses.map((purchaseOrderStatus, index) =>
                                                            <option key={index}
                                                                    value={purchaseOrderStatus.description}>{purchaseOrderStatus.description}</option>
                                                        )}
                                                    </Input>
                                                    <FormText
                                                        color="danger">{formErrors.purchaseOrderStatusDescription}</FormText>

                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-3"}>
                                                    <Label>Ref. no</Label>
                                                    <Input
                                                        name="ref"
                                                        type="text"
                                                        value={purchaseOrderObj.ref || ''}
                                                        placeholder="write ref here..."
                                                        onChange={(e) => this.handleChange(e.target.value, 'ref')} />
                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-3"}>
                                                    <Label>Currency</Label>
                                                    <Input
                                                        name="currency"
                                                        type="text"
                                                        value={purchaseOrderObj.currency || ''}
                                                        placeholder="write currency here..."
                                                        onChange={(e) => this.handleChange(e.target.value, 'currency')} />
                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-3"}>
                                                    <Label> Note</Label>
                                                    <Input className="form-control"
                                                        onChange={(e) => this.handleChange(e.target.value, "note")}
                                                        type="textarea"
                                                        name="note"
                                                        value={purchaseOrderObj.note}
                                                        placeholder="Write note here" />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                    <Card>
                                        <CardBody>
                                            <p><strong>ORDER DATES</strong></p>
                                            <Row>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    ETD Date
                                                    <div>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={purchaseOrderObj.etdDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            onChange={(date) => this.handleChange(date, "etdDate")}
                                                            placeholderText="ETD Date"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            isClearable
                                                            withPortal/>
                                                    </div>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-3"}>
                                                    ETA Date
                                                    <div>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={purchaseOrderObj.etaDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            onChange={(date) => this.handleChange(date, "etaDate")}
                                                            placeholderText="ETA Date"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            isClearable
                                                            withPortal/>
                                                    </div>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-3"}>
                                                    CRD Date
                                                    <div>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={purchaseOrderObj.crdDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            onChange={(date) => this.handleChange(date, "crdDate")}
                                                            placeholderText="CRD Date"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            isClearable
                                                            withPortal/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <br/>
                            <Card>
                                <CardBody>
                                    <p><strong>ORDER ITEMS</strong></p>
                                    <div style={{overflow: "auto", overflowY: "hidden"}} className="table-responsive">
                                        {(purchaseOrderObj.purchaseOrderItems && purchaseOrderObj.purchaseOrderItems.length > 0)
                                            ?
                                            <Table striped bordered>
                                                <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th colSpan={'2'} style={{minWidth: '200px'}}>Stock</th>
                                                    <th className={"text-center"} style={{minWidth: '150px'}}>Quantity
                                                    </th>
                                                    {purchaseOrderID ?
                                                        <th className={"text-center"} style={{minWidth: '150px'}}>
                                                            Quantity Received</th> : null}
                                                    {purchaseOrderID ?
                                                        <th className={"text-center"} style={{minWidth: '150px'}}>
                                                            Quantity Cancelled</th> : null}
                                                    <th className={"text-center"} style={{minWidth: '150px'}}>Cost</th>
                                                    <th style={{minWidth: '200px'}}>Description</th>
                                                    <th style={{minWidth: '150px'}}>Supplier
                                                        Ref
                                                    </th>
                                                    <th style={{minWidth: '150px'}}>ETA Date</th>
                                                    <th style={{minWidth: '150px'}}>ETD Date</th>
                                                    <th style={{minWidth: '150px'}}>Remarks</th>
                                                    <th className={"text-center"} style={{minWidth: '100px'}}>Complete
                                                    </th>
                                                    <th className={"text-center"} style={{minWidth: '100px'}}>Action
                                                    </th>


                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(purchaseOrderObj.purchaseOrderItems || []).map((item, index) => {
                                                    totalItemsCost += item.cost ? parseInt(item.cost) : 0;
                                                    return <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td colSpan={'2'}>
                                                            <SearchWINOnlineStock
                                                                handleSelection={(selectedProdCode) => this.handleOrderItemChange(selectedProdCode, 'stockProdCode', index)}
                                                                selectedProdCode={prodCode !== '' ? prodCode : item.stockProdCode}
                                                                defaultProdCode={prodCode !== '' ? prodCode : item.stockProdCode}/>

                                                            <FormText
                                                                color="danger">{formErrors.orderItems[index] ? formErrors.orderItems[index].stock : null}</FormText>

                                                        </td>
                                                        <td>
                                                            <Input
                                                                invalid={formErrors.orderItems[index] ? formErrors.orderItems[index].qty !== undefined : false}
                                                                name="qty"
                                                                type="number"
                                                                value={item.qty || ''}
                                                                placeholder={"write qty here"}
                                                                onChange={(e) => this.handleOrderItemChange(e.target.value, e.target.name, index)}/>
                                                            <FormText
                                                                color="danger">{formErrors.orderItems[index] ? formErrors.orderItems[index].qty : null}</FormText>

                                                        </td>
                                                        {purchaseOrderID ? <td>
                                                            <Input
                                                                invalid={formErrors.orderItems[index] ? formErrors.orderItems[index].qtyReceived !== undefined : false}
                                                                name="qtyReceived"
                                                                type="number"
                                                                value={item.qtyReceived || ''}
                                                                placeholder={"write qty received here"}
                                                                onChange={(e) => this.handleOrderItemChange(e.target.value, e.target.name, index)}/>
                                                            <FormText
                                                                color="danger">{formErrors.orderItems[index] ? formErrors.orderItems[index].qtyReceived : null}</FormText>
                                                        </td> : null}
                                                        {purchaseOrderID ? <td>
                                                            <Input
                                                                invalid={formErrors.orderItems[index] ? formErrors.orderItems[index].qtyCancelled !== undefined : false}
                                                                name="qtyCancelled"
                                                                type="number"
                                                                value={item.qtyCancelled || ''}
                                                                placeholder={"write qty cancelled here"}
                                                                onChange={(e) => this.handleOrderItemChange(e.target.value, e.target.name, index)}/>
                                                            <FormText
                                                                color="danger">{formErrors.orderItems[index] ? formErrors.orderItems[index].qtyCancelled : null}</FormText>
                                                        </td> : null}
                                                        <td>
                                                            <Input
                                                                invalid={formErrors.orderItems[index] ? formErrors.orderItems[index].cost !== undefined : false}
                                                                name="cost"
                                                                type="number"
                                                                value={item.cost || ''}
                                                                placeholder={"write cost here"}
                                                                onChange={(e) => this.handleOrderItemChange(e.target.value, e.target.name, index)}/>
                                                            <FormText
                                                                color="danger">{formErrors.orderItems[index] ? formErrors.orderItems[index].cost : null}</FormText>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name="description"
                                                                type="text"
                                                                value={item.description || ''}
                                                                placeholder={"write description here"}
                                                                onChange={(e) => this.handleOrderItemChange(e.target.value, e.target.name, index)}/>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name="supplierRef"
                                                                type="text"
                                                                value={item.supplierRef || ''}
                                                                placeholder={"write supplier ref here"}
                                                                onChange={(e) => this.handleOrderItemChange(e.target.value, e.target.name, index)}/>

                                                        </td>
                                                        <td>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={item.etaDate}
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={(date) => this.handleOrderItemChange(date, "etaDate", index)}
                                                                placeholderText="ETA date"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                scrollableYearDropdown
                                                                isClearable/>
                                                        </td>
                                                        <td>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={item.etdDate}
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={(date) => this.handleOrderItemChange(date, "etdDate", index)}
                                                                placeholderText="ETD date"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                scrollableYearDropdown
                                                                isClearable/>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name="remarks"
                                                                type="text"
                                                                value={item.remarks || ''}
                                                                placeholder={"write remarks here"}
                                                                onChange={(e) => this.handleOrderItemChange(e.target.value, e.target.name, index)}/>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className="checkbox checkbox-secondary">
                                                                <a href={"javascript:void(0)"}
                                                                   onClick={() => this.selectCheckbox(item.isComplete, "isComplete", index)}>
                                                                    {item.isComplete ?
                                                                        <i className="fa fa-lg fa-check-square-o mr-2"
                                                                           aria-hidden="true"/> :
                                                                        <i className="fa fa-lg fa-square-o mr-2"
                                                                           aria-hidden="true"/>}
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <Button color={"danger"}
                                                                    size="sm"
                                                                    title={"click here to delete"}
                                                                    onClick={() => this.handleItemAction("delete", index)}>
                                                                <i className="fa fa-trash-o fa-lg" aria-hidden="true"/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                })}
                                                <tr>
                                                    <td colSpan={purchaseOrderID ? 6 : 4} className={"text-right"}><b>Order
                                                        Total</b></td>
                                                    <td className={"text-right"}>
                                                        <b>
                                                            <NumberFormat value={purchaseOrderObj.orderTotal}
                                                                          displayType={'text'}
                                                                          decimalScale={2}
                                                                          fixedDecimalScale={true}
                                                                          thousandSeparator={true}
                                                                          prefix={'$'}/>
                                                        </b>
                                                    </td>
                                                    <td colSpan={'7'}></td>
                                                </tr>
                                                </tbody>
                                            </Table> : null}

                                        <Button
                                            color={"primary"}
                                            size={"sm"}
                                            className={"ml-1"}
                                            onClick={this.addNewItem}>
                                            <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                                            Add new item
                                        </Button>
                                    </div>

                                </CardBody>
                            </Card>

                            <br/>

                            {
                                (purchaseOrderObj.purchaseOrderItems && purchaseOrderObj.purchaseOrderItems.length > 0)
                                    ? <div className="text-center">
                                        <Button color={"primary"}
                                                block={true}
                                                disabled={saving}
                                                onClick={this.submitOrder}>
                                            {saving
                                                ? <Spinner size="sm"
                                                           className={"mr-2"}
                                                           style={{color: "white"}}/>
                                                : <i className="fa fa-shopping-cart mr-2"
                                                     aria-hidden="true"/>
                                            }
                                            {saving
                                                ? "Placing "
                                                : "Place "
                                            }
                                            your order
                                        </Button>

                                    </div>
                                    : null
                            }
                        </div>
                    </div>}
                <ToastContainer/>
            </div>
        );
    }
}
