import React, {Component} from 'react';
import {Badge, Button} from 'reactstrap';

export default class AppliedFilters extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filters: this.props.filters};
        this.updateFilter = this.updateFilter.bind(this);
    }

    updateFilter(filter) {

        filter.value = filter.defaultValue;
        filter.displayValue = "";

        this.setState({filter});
        this.props.onChange(filter, false);

    }


    render() {
        let filters = this.state.filters;

        filters = filters.filter((filter) => {
            if ((filter.value !== null) && (filter.value !== undefined) && (filter.value !== "")) {
                return true;
            }
        });


        return (
            <div>
                {filters.map((filter, index) =>
                    <span key={index}>
                        <Button color="primary" className={"btn btn-sm"}
                                onClick={() => this.updateFilter(filter)}>
                            <strong>{filter.displayName}</strong>:&nbsp;{filter.displayValue} &nbsp;&nbsp;
                            <i className="fa fa-times" aria-hidden="true"/>
                        </Button>&nbsp;&nbsp;
                    </span>
                )}

            </div>
        );
    }
}
