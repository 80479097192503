import axios from './axios';
import {authHeader} from '../helpers/auth-header';
import {config} from '../helpers/config';
import axiosFileDownloader from './axiosFileDownloader';
import {validateEmail} from "../services/CommonService";
import {
    ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME,
    ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1,
    ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2,
    ADDRESS_VALIDATION_MAX_LENGTH_COMPANY,
    ADDRESS_VALIDATION_MAX_LENGTH_EMAIL,
    ADDRESS_VALIDATION_MAX_LENGTH_PHONE,
    ADDRESS_VALIDATION_MAX_LENGTH_STATE
} from "../store/AppConstants";

class AddressService {

    static Instance() {
        return new AddressService();
    }

    getStates() {
        return axios.get('api/address/state/list');
    }

    getDebtorAddresses(accountId) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
            timeout: 180000,
            url: config.apiUrl + 'api/address/debtor/list?accountID=' + accountId
        };
        return axios(requestOptions);
    }

    saveDebtorAddress(deliveryAddress) {
        return axios.post('api/address/debtor/save', deliveryAddress)
    }

    getAddressStandards(request) {
        return axios.post('api/address/standard/list', request);
    }

    saveAddressStandard(request) {
        if (request.id && request.id > 0) {
            return this.updateAddressStandard(request);
        } else {
            return this.createAddressStandard(request);
        }
    }

    fetchDebtorOtherEmails(accountID) {
        return axios.get('api/address/Get/OtherEmails?AccountId=' + accountID);
    }



    AddDebtorOtherEmails(requestBody) {
        return axios.post('api/address/OtherEmails', requestBody);
    }

    createAddressStandard(request) {
        return axios.post('api/address/standard/save', request);
    }

    updateAddressStandard(request) {
        return axios.put('api/address/standard/update', request);
    }

    getAddressStandardByPostcode(postcode) {
        return axios.get('api/address/standard/one/by/postcode?postcode=' + postcode);
    }

    getCities(value) {
        return axios.get('api/address/Get/cities?inputValue=' + value);
    }

    getStatesWithFilter(value) {
        return axios.get('api/address/Get/States?inputValue=' + value);
    }

    getPostcodes(value) {
        return axios.get('api/address/Get/Postcodes?inputValue=' + value);
    }

    deleteDebtorAddress(addressIDs) {
        return axios.post('api/address/delete', addressIDs);
    }


    getAllAddresses(requestBody) {
        return axios.post('/api/address/address/master', requestBody);
    }

    generateAddressSummaryExcelReport(req) {
        return axiosFileDownloader.post('/api/address/address/master/summary/report/generate', req)
    }

    getCities() {
        return axios.get('api/address/city/list');
    }

    removeAddress(addressID) {
        return axios.delete('api/address/remove?addressID=' + addressID);
    }

    validateForm(address) {
        let formError = {
            isValid: false,
            errors: []
        };
        let message = "";

        if (!address.debtorAccountID) {
            message = "Customer not selected!";
            formError.debtorAccountID = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.contactName) {
            message = "contact name can't be empty!";
            formError.contactName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.contactName && address.contactName.length > ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME) {
            message = "contact name should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME + " characters!";
            formError.contactName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.phone) {
            message = "phone can't be empty!";
            formError.phone = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.phone && address.phone.length > ADDRESS_VALIDATION_MAX_LENGTH_PHONE) {
            message = "phone should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_PHONE + " characters!";
            formError.phone = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.email) {
            message = "email can't be empty!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.email && !validateEmail(address.email)) {
            message = "email isn't valid!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.email && address.email.length > ADDRESS_VALIDATION_MAX_LENGTH_EMAIL) {
            message = "email should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_EMAIL + " characters!";
            formError.email = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.company) {
            message = "company name can't be empty!";
            formError.company = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.company && address.company.length > ADDRESS_VALIDATION_MAX_LENGTH_COMPANY) {
            message = "company name should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_COMPANY + " characters!";
            formError.company = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.address1) {
            message = "address line 1 can't be empty!";
            formError.address1 = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.address1 && address.address1.length > ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1) {
            message = "address line 1 should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1 + " characters!";
            formError.address1 = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.address2 && address.address2.length > ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2) {
            message = "address line 2 should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2 + " characters!";
            formError.address2 = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.city) {
            message = "city can't be empty!";
            formError.city = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.state && address.country == "AUSTRALIA") {
            message = "state can't be empty!";
            formError.state = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!address.postCode && address.country == "AUSTRALIA") {
            message = "postCode can't be empty!";
            formError.postCode = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (address.state.length > 3) {
            message = "Max " + ADDRESS_VALIDATION_MAX_LENGTH_STATE + " characters allowed!";
            formError.state = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        return formError;
    }
}

export default AddressService.Instance();