import React, {Component} from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Label,
    Input,
    ModalFooter,
    Spinner,
    Col,
    FormGroup,
    Badge
} from 'reactstrap';
import Select from 'react-select';
import apiLogService from '../../services/ApiLogService';
import {ToastContainer, toast} from 'react-toastify';
import {handleErrorMessage} from "../../services/CommonService";
import ApiLogService from "../../services/ApiLogService";
import {cloneDeep} from 'lodash';

export default class UpdateApiLogBulkStatusComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isLoadingStatusList: false,
            comment: '',
            selectedStatus: '',
            selectedLogs: [],
            statusList: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleComponentChange = this.handleComponentChange.bind(this);
        this.getApiStatusList = this.getApiStatusList.bind(this);
    }

    componentDidMount() {
        this.getApiStatusList();
        let { selectedLogs } = this.state;
        selectedLogs = (this.props.logs || []).filter(x => x.isSelected).map(set => set.id);
        this.setState({ selectedLogs });
    }

    handleComponentChange(key, value) {
        switch (key) {
            case "status":
                this.setState({selectedStatus: value});
                break;
            case "comment":
                this.setState({comment: value})
                break;
        }
    }

    getApiStatusList() {
        this.setState({isLoadingStatusList: true});
        apiLogService.getStatusList().then(response => {
            let statusList = response.data;
            this.setState({statusList: statusList, isLoadingStatusList: false});
        }).catch(error => {
            this.setState({isLoadingStatusList: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    handleSubmit(key) {
        this.setState({loading: true});
        let { comment, selectedStatus, selectedLogs } = this.state;
        switch (key) {
            case "status":
                let requestStatus = { logIDs: selectedLogs, status: selectedStatus };
                apiLogService.updateApiLogStatus(requestStatus).then(response => {
                    toast.success("Updated!");
                    this.setState({loading: false});
                    this.props.toggleModal(false);
                    this.props.refreshAgain();
                }).catch(error => {
                    this.setState({loading: false});
                    toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
                });
                break;
            case "comment":
                let requestComment = { logIDs: selectedLogs, comment: comment };
                apiLogService.updateApiLogComment(requestComment).then(response => {
                    toast.success("Updated!");
                    this.setState({loading: false});
                    this.props.toggleModal(false);
                    this.props.refreshAgain();
                }).catch(error => {
                    this.setState({loading: false});
                    toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
                });
                break;
        }
    }

    render() {
        let {isOpen, toggleModal, isUpdateStatus} = this.props;
        let { loading, comment, selectedStatus, statusList } = this.state;

        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={(e) => toggleModal(!isOpen)}>
                <ModalHeader toggle={(e) => toggleModal(!isOpen)}>
                    {isUpdateStatus ? "Status" : "Comment"} Bulk Update
                </ModalHeader>
                <ModalBody>
                    {/*<div className="mb-2">*/}
                    {/*    <FormGroup>*/}
                    {/*        <Label className='mb-0' for="order">Logs to be updated:</Label>*/}
                    {/*        <div>*/}
                    {/*            {(selectedLogs || []).map((item, index) =>*/}
                    {/*                <Badge key={index} color={"primary"} className={"ml-2"}>*/}
                    {/*                    {item.id} - {item.ordNum}*/}
                    {/*                </Badge>*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*    </FormGroup>*/}
                    {/*</div>*/}
                    {isUpdateStatus ?
                        <div className="mb-2">
                            <Label className='mb-0'>Select status</Label>
                            <Input
                                className="form-control"
                                onChange={(e) => this.handleComponentChange("status", e.target.value)}
                                type="select"
                                name="logStatus"
                                value={selectedStatus}
                                placeholder="Select Status">
                                <option value={""}>Select</option>
                                {(statusList || []).map((option, index) =>
                                    <option key={index} value={option}>{option}</option>
                                )}
                            </Input>
                        </div>
                        :
                        <div className="mb-2">
                            <div className="mt-1 mb-2">
                                <Label>Comment</Label>
                                <Input className="form-control"
                                       onChange={(e) => this.handleComponentChange("comment", e.target.value)}
                                       type="textarea"
                                       name="comment"
                                       value={comment}
                                       placeholder="Write comment here"/>
                            </div>

                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"}
                                onClick={() => this.handleSubmit(isUpdateStatus ? "status" : "comment")}
                                className={"mr-2"}
                                disabled={loading}>
                            {loading ? <Spinner className={"mr-2"} size={"sm"}/> : <i className="fa fa-floppy-o mr-2"/>}
                            {loading ? "Updating" : "Update"} {isUpdateStatus ? " Status" : " Comment"} </Button>
                        <Button color={"secondary"} onClick={(e) => toggleModal(!isOpen)}>Cancel</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}