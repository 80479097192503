import React, { Component } from 'react';

export let venetianLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "quantity",
        label: "Qty(unit)",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "slatType",
        label: "Slat Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "slatColour",
        label: "Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "drop",
        label: "Drop(mm)",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "width",
        label: "Width(mm)",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "liftControl",
        label: "Control",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }

];