import axios from "../axios";

class PurchaseOrderService {

    static Instance() {
        return new PurchaseOrderService();
    }

    listPurchaseOrders(req) {
        return axios.post('api/purchase-order/list', req);
    }

    listPurchaseOrderItems(req) {
        return axios.post('api/purchase-order/item/list', req);
    }

    getOnePurchaseOrder(purchaseOrderID) {
        return axios.get('api/purchase-order/one/?PurchaseOrderID=' + purchaseOrderID);
    }

    saveOrUpdatePurchaseOrder(req) {
        return axios.post('api/purchase-order/save', req);
    }



    getLastOrderNum() {
        return axios.get('api/purchase-order/ordNum/last')
    }

    listPurchaseOrderItemsByStock(prodCode) {
        return axios.get('api/purchase-order/stock/list?prodCode=' + prodCode)
    }

    listPurchaseOrderItemsBySupplierId(supplierID) {
        return axios.get('api/purchase-order/items/by-supplier/list?SupplierID=' + supplierID);
    }

}

export default PurchaseOrderService.Instance();