import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, FormGroup, FormText } from 'reactstrap';
import { handleErrorMessage } from "../../services/CommonService";
import cloneDeep from 'lodash/cloneDeep';
import courierService from '../../services/shipment/CourierService';

export default class ManageCourierModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            selectedCourier: {},
            formErrors: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.saveCourier = this.saveCourier.bind(this);
    }

    componentDidMount() {
        let { selectedCourier } = this.state;
        if (this.props.selectedCourier) {
            selectedCourier = cloneDeep(this.props.selectedCourier);
        }
        this.setState({ selectedCourier });
    }

    validateForm(item) {
        let formErrors = {};
        let valid = true;
        if (!item.courier) {
            formErrors.courier = "Please enter courier name";
            valid = false;
        }
        return { valid: valid, formErrors: formErrors };
    }

    handleChange(key, value) {
        let { selectedCourier } = this.state;
        selectedCourier[key] = value;
        this.setState({ selectedCourier });
    }

    saveCourier() {
        let { selectedCourier } = this.state;
        let { toggle, isOpen, refreshAgain } = this.props;
        selectedCourier.courier = selectedCourier.courier.trim();
        let result = this.validateForm(selectedCourier);
        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        this.setState({ saving: true, formErrors: {} });
        courierService.saveCourier(selectedCourier).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ saving: false });
                toggle(!isOpen);
                toast.success("Saved Successfully");
                refreshAgain();
            }
        }).catch(error => {
            this.setState({ saving: false });
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { selectedCourier, saving, formErrors } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    {selectedCourier && selectedCourier.courierID ? 'Edit' : 'Add'} Courier
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="courier">Courier Name*</Label>
                        <Input type="text" name="courier"
                            onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                            value={selectedCourier ? selectedCourier.courier : ""}
                            placeholder="Enter Courier Name here" />
                        <FormText color="danger">{formErrors.courier}</FormText>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.saveCourier()} disabled={saving}>
                            {
                                saving
                                    ? <Spinner size="sm" className="mr-2" style={{ color: "white" }} />
                                    : <i className="fa fa-floppy-o mr-2" />
                            }
                            {selectedCourier && selectedCourier.courierID ? 'Update' : 'Add'}</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" />Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}