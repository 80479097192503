import React, { Component } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Card, Badge, CardBody, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import OrderService from '../../services/OrderService';
import { Debtors_Order_types } from './../../store/DebtorInvoiceStatus';
import { changeFormatOfDateString, displayECD,handleErrorMessage } from '../../services/CommonService';

const Order_types = Debtors_Order_types;
export default class OrderGatewaySearchOrderNumModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            filteredOrders: [],
            ordersLoading: false,
        };

        this.orderService = new OrderService();
        this.SearchRelativeOrderNumbers = this.SearchRelativeOrderNumbers.bind(this);
        this.getOrderTabDetails = this.getOrderTabDetails.bind(this);
        this.getBadgeColor = this.getBadgeColor.bind(this);
    }

    getBadgeColor(salesjobstatusId) {
        if (salesjobstatusId >= 30 && salesjobstatusId < 44) {
            return "warning";
        }
        if (salesjobstatusId === 10) {
            return "light";
        }
        if (salesjobstatusId === 1) {
            return "danger";
        }
        if (salesjobstatusId === 4) {
            return "warning";
        }
        if (salesjobstatusId >= 5 && salesjobstatusId < 6) {
            return "warning";
        }
        if (salesjobstatusId >= 45 && salesjobstatusId < 89) {
            return "dark";
        }
        if (salesjobstatusId >= 90) {
            return "success";
        }
    }


    getOrderTabDetails(Ordnum) {
        this.orderService.getOrderSummaryForOpeningSpecificTab(Ordnum).then(res => {
            if (res.status === 200 || res.status === '200') {
                let data = res.data;
                //console.log(data);
                this.setState({ ordersLoading: true });
                this.props.handleOrdernumClick(data);
                //used in OrderGatewayPage
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    SearchRelativeOrderNumbers(value) {
        this.setState({ searchText: value });
            if (value.length > 2) {
                this.setState({ ordersLoading: true });
                this.orderService.getOrderRelatedDetails(value).then(res => {
                    if (res.status === 200 || res.status === '200') {
                        let data = res.data;
                        //console.log(data, 'SearchRelativeOrderNumbers');
                        this.setState({ filteredOrders: data, ordersLoading: false });
                    }
                }).catch(error => {
                    console.log(error);
                    this.setState({ ordersLoading: false });
                });
            }
        
    }

    renderCard(order) {
        switch (order.orderType) {
            case "QUOTE":
                return <span>
                <Card className={"m-2"}>
                    <CardBody>
                        <Button color={"primary"}
                            size={"sm"}
                            onClick={() => this.getOrderTabDetails(order.ordnum)}>
                            {order.ordnum}
                        </Button>

                        {order.orderDate
                            ? <div>
                                <strong className="mr-2">
                                        Order Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.orderDate,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.custOrdNum
                            ? <div>
                                <strong className="mr-2">
                                    Cust. Ord Ref.
                                            </strong>
                                            :&nbsp;{order.custOrdNum}
                            </div>
                            : null
                        }

                        <div>
                            <strong className="mr-2">
                                Status
                                        </strong>
                                        :&nbsp;
                                                <Badge
                                color={order.salesOrderJobStatusColor}>
                                    {order.salesOrderJobStatusDescription}
                            </Badge>
                                                {
                                (order.documentTypeID === 3 && order.salesOrderJobStatusCode !== "QUOTE") ?
                                    <Badge color={"light"}>
                                        {Order_types[order.documentTypeID]}
                                    </Badge>
                                    : null
                            }
                        </div>
                        <div>
                            <strong className="mr-2">
                                Stage
                            </strong>
                            :&nbsp;Quotes
                        </div>
                        </CardBody>
                    </Card></span>;
                break;
            case "IMP":
                return (<span>
                    <Card className={"m-2"}>
                        <CardBody>
                            <Button color={"primary"}
                                size={"sm"}
                                onClick={() => this.getOrderTabDetails(order.ordnum)}>
                                {order.ordnum}
                            </Button>
                            {
                                order.orderDate ?
                                    <div>
                                        <strong className="mr-2">
                                            Order Date
                                        </strong>
                                        :&nbsp;{changeFormatOfDateString(order.orderDate, "DD/MM/YYYY", 'DD MMM YYYY')}
                                    </div>
                                    : null
                            }
                            {
                                order.custOrdNum ?
                                    <div>
                                        <strong className="mr-2">Cust. Ord Ref.</strong>:&nbsp;{order.custOrdNum}
                                    </div>
                                    : null
                            }
                            <div>
                                <strong className="mr-2">Status</strong>:&nbsp;
                                <Badge color={order.salesOrderJobStatusColor}>
                                    {order.salesOrderJobStatusDescription}
                                </Badge>
                                {
                                    (order.documentTypeID === 3 && order.salesOrderJobStatusCode !== "QUOTE") ?
                                        <Badge color={"light"}>
                                            {Order_types[order.documentTypeID]}
                                        </Badge>
                                        : null
                                }
                            </div>
                            <div>
                                <strong className="mr-2">Stage</strong>:&nbsp;Import Orders
                            </div>
                        </CardBody>
                    </Card>
                </span>);
                break;
            case "SUB":
                return <span><Card className={"m-2"}>
                    <CardBody>
                        <Button color={"primary"}
                            size={"sm"}
                            onClick={() => this.getOrderTabDetails(order.ordnum)}>
                            {order.ordnum}
                        </Button>

                        {order.orderDate
                            ? <div>
                                <strong className="mr-2">
                                    Order Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.orderDate, "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.custOrdNum
                            ? <div>
                                <strong className="mr-2">
                                    Cust. Ord Ref.
                                            </strong>
                                            :&nbsp;{order.custOrdNum}
                            </div>
                            : null
                        }

                        <div>
                            <strong className="mr-2">
                                Status
                                        </strong>
                                        :&nbsp;
                                                <Badge
                                color={order.salesOrderJobStatusColor}>
                                {order.salesOrderJobStatusDescription}
                            </Badge>
                                                {
                                (order.documentTypeID === 3 && order.salesOrderJobStatusCode !== "QUOTE") ?
                                    <Badge color={"light"}>
                                        {Order_types[order.documentTypeID]}
                                    </Badge>
                                    : null
                            }
                        </div>
                        <div>
                            <strong className="mr-2">
                                Stage
                            </strong>
                            :&nbsp;Submitted Orders
                        </div>
                    </CardBody>
                </Card></span>;
                break;
            case "PEN":
                return <span><Card className={"m-2"}>
                    <CardBody>
                        <Button color={"primary"}
                            size={"sm"}
                            onClick={() => this.getOrderTabDetails(order.ordnum)}>
                            {order.ordnum}
                        </Button>

                        {order.orderDate
                            ? <div>
                                <strong className="mr-2">
                                    Order Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.orderDate,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }
                        {order.required
                            ? <div>
                                <strong className="mr-2">
                                    Required Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.required,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.custOrdNum
                            ? <div>
                                <strong className="mr-2">
                                    Cust. Ord Ref.
                                            </strong>
                                            :&nbsp;{order.custOrdNum}
                            </div>
                            : null
                        }

                        <div>
                            <strong className="mr-2">
                                Status
                                        </strong>
                                        :&nbsp;
                                                <Badge
                                color={order.salesOrderJobStatusColor}>
                                {order.salesOrderJobStatusDescription}
                            </Badge>
                                                {
                                (order.documentTypeID === 3 && order.salesOrderJobStatusCode !== "QUOTE") ?
                                    <Badge color={"light"}>
                                        {Order_types[order.documentTypeID]}
                                    </Badge>
                                    : null
                            }
                        </div>
                        <div>
                            <strong className="mr-2">
                                Stage
                            </strong>
                            :&nbsp;Pending Orders
                        </div>
                    </CardBody>
                </Card></span>;
                break;
            case "PROD":
                let ecdDate = displayECD(order.productionReadyDate, 'DD MMM YYYY');
                return <span><Card className={"m-2"}>
                    <CardBody>
                        <Button color={"primary"}
                            size={"sm"}
                            onClick={() => this.getOrderTabDetails(order.ordnum)}>
                            {order.ordnum}
                        </Button>

                        {order.orderDate
                            ? <div>
                                <strong className="mr-2">
                                    Order Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.orderDate,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.required
                            ? <div>
                                <strong className="mr-2">
                                    Required Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.required,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {ecdDate
                            ? <div>
                                <strong className="mr-2">
                                    ECD Date
                                </strong>
                                :&nbsp;{ecdDate}
                            </div>
                            : null
                        }

                        {order.custOrdNum
                            ? <div>
                                <strong className="mr-2">
                                    Cust. Ord Ref.
                                            </strong>
                                            :&nbsp;{order.custOrdNum}
                            </div>
                            : null
                        }

                        <div>
                            <strong className="mr-2">
                                Status
                                        </strong>
                                        :&nbsp;
                                                <Badge
                                color={order.salesOrderJobStatusColor}>
                                {order.salesOrderJobStatusDescription}
                            </Badge>
                                                {
                                (order.documentTypeID === 3 && order.salesOrderJobStatusCode !== "QUOTE") ?
                                    <Badge color={"light"}>
                                        {Order_types[order.documentTypeID]}
                                    </Badge>
                                    : null
                            }
                        </div>
                        <div>
                            <strong className="mr-2">
                                Stage
                            </strong>
                            :&nbsp;Order in production
                        </div>
                    </CardBody>
                </Card></span>;
                break;
            case "DISP":
                return <span><Card className={"m-2"}>
                    <CardBody>
                        <Button color={"primary"}
                            size={"sm"}
                            onClick={() => this.getOrderTabDetails(order.ordnum)}>
                            {order.ordnum}
                        </Button>

                        {order.orderDate
                            ? <div>
                                <strong className="mr-2">
                                    Order Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.orderDate,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.required
                            ? <div>
                                <strong className="mr-2">
                                    Required Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.required,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.invPrinted
                            ? <div>
                                <strong className="mr-2">
                                    Invoiced Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.invPrinted,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.shipDate
                            ? <div>
                                <strong className="mr-2">
                                    Shipping Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.shipDate,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.couriers
                            ? <div>
                                <strong className="mr-2">
                                    Shipment
                                            </strong>
                                            :&nbsp;{order.couriers}
                            </div>
                            : null
                        }

                        {order.consignNum
                            ? <div>
                                <strong className="mr-2">
                                    Connote#
                                            </strong>
                                            :&nbsp;{order.consignNum}
                            </div>
                            : null
                        }

                        {order.custOrdNum
                            ? <div>
                                <strong className="mr-2">
                                    Cust. Ord Ref.
                                            </strong>
                                            :&nbsp;{order.custOrdNum}
                            </div>
                            : null
                        }

                        <div>
                            <strong className="mr-2">
                                Status
                                        </strong>
                                        :&nbsp;
                                                <Badge
                                color={order.salesOrderJobStatusColor}>
                                {order.salesOrderJobStatusDescription}
                            </Badge>
                                                {
                                (order.documentTypeID === 3 && order.salesOrderJobStatusCode !== "QUOTE") ?
                                    <Badge color={"light"}>
                                        {Order_types[order.documentTypeID]}
                                    </Badge>
                                    : null
                            }
                        </div>
                        <div>
                            <strong className="mr-2">
                                Stage
                            </strong>
                            :&nbsp;Dispatched Orders
                        </div>

                    </CardBody>
                </Card></span>;
                break;
            case "CAN":
                return <span><Card className={"m-2"}>
                    <CardBody>
                        <Button color={"primary"}
                            size={"sm"}
                            onClick={() => this.getOrderTabDetails(order.ordnum)}>
                            {order.ordnum}
                        </Button>

                        {order.orderDate
                            ? <div>
                                <strong className="mr-2">
                                    Order Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.orderDate,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.required
                            ? <div>
                                <strong className="mr-2">
                                    Required Date
                                            </strong>
                                            :&nbsp;{changeFormatOfDateString(order.required,  "DD/MM/YYYY", 'DD MMM YYYY')}
                            </div>
                            : null
                        }

                        {order.cancelledDate
                            ? <div>
                                <strong className="mr-2">
                                    Cancelled Date
                                            </strong>
                                            :&nbsp;{order.cancelledDate}
                            </div>
                            : null
                        }

                        {order.cancelReason
                            ? <div>
                                <strong className="mr-2">
                                    Cancelled Reason
                                            </strong>
                                            :&nbsp;{order.cancelReason}
                            </div>
                            : null
                        }

                        {order.custOrdNum
                            ? <div>
                                <strong className="mr-2">
                                    Cust. Ord Ref.
                                            </strong>
                                            :&nbsp;{order.custOrdNum}
                            </div>
                            : null
                        }

                        <div>
                            <strong className="mr-2">
                                Status
                                        </strong>
                                        :&nbsp;
                                                <Badge
                                color={order.salesOrderJobStatusColor}>
                                {order.salesOrderJobStatusDescription}
                            </Badge>
                                                {
                                (order.documentTypeID === 3 && order.salesOrderJobStatusCode !== "QUOTE") ?
                                    <Badge color={"light"}>
                                        {Order_types[order.documentTypeID]}
                                    </Badge>
                                    : null
                            }
                        </div>
                        <div>
                            <strong className="mr-2">
                                Stage
                            </strong>
                            :&nbsp;Cancelled Orders
                        </div>
                    </CardBody>
                </Card></span>;
                break;
            default:
                break;
        }
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { filteredOrders, searchText, ordersLoading } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Search
                </ModalHeader>
                <ModalBody>
                    <div>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search"
                                        aria-hidden="true" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                onChange={e => this.SearchRelativeOrderNumbers(e.target.value )}
                                type="search"
                                name="searchText"
                                value={searchText}
                                placeholder="Search by Order no." />
                        </InputGroup>
                    </div>
                    <hr />
                    <div>
                        {
                            ordersLoading ?
                                <Spinner size={'sm'} color="primary" />
                                :
                                (filteredOrders || []).map((order, orderIndex) => {
                                    order.salesOrderJobStatusColor = this.getBadgeColor(order.salesOrderJobStatusId);

                                    return (
                                        <div key={orderIndex}>
                                            {this.renderCard(order)}
                                        </div>
                                    )
                                })
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}> Close </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}