import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    FormText,
    Input,
    InputGroup,
    InputGroupText,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import {
    addDate,
    changeFormatOfDateString,
    getDateObj,
    getDateString,
    getMaxDate,
    handleErrorMessage,
    removeDecimalNumber
} from "../../services/CommonService";
import SearchCreditorAcccount from '../../components/search/SearchCreditorAcccount';
import UploadFiles from '../../components/attachment/UploadFiles';
import SearchStock from '../../components/search/SearchStockKeyway';
import PurchaseOrderSendEmailModal from '../../components/modal/PurchaseOrderSendEmailModal';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import creditorSevice from "../../services/purchase/CreditorService";
import AttachmentService from '../../services/AttachmentService';
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import {Link as Link} from "react-router-dom";
import UserService from '../../services/UserService';
import ImportPurchaseOrderItemModal from '../../components/modal/ImportPurchaseOrderItemModal';
import StockQtyFormat from "../../components/stock/StockQtyFormat";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../store/AppConstants";
import Pluralize from 'react-pluralize'
const maxDate = addDate(new Date(), 1, "years", "dateObj");
const minDate = addDate(new Date(), -1, "years", "dateObj");


export default class ManagePurchaseOrderFormpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseOrder: {orderItems: []},
            isRequestValidated: false,
            emailPurchaseOrderModal: {
                isOpen: false,
                isLoadingEmailSend: false,
                mail: {
                    id: "",
                    companyName: "",
                    from: [],
                    fromOptions: [],
                    to: [],
                    toOptions: [],
                    cc: [{
                        label: "Windoware Accounts(accountspayable@windoware.com.au)",
                        value: "accountspayable@windoware.com.au"
                    }],
                    ccOptions: [{
                        label: "Windoware Accounts(accountspayable@windoware.com.au)",
                        value: "accountspayable@windoware.com.au"
                    }],
                    bcc: [],
                    bccOptions: [],
                    replyTo: [{
                        label: "Windoware Accounts(accountspayable@windoware.com.au)",
                        value: "accountspayable@windoware.com.au"
                    }],
                    replyToOptions: [{
                        label: "Windoware Accounts(accountspayable@windoware.com.au)",
                        value: "accountspayable@windoware.com.au"
                    }],
                    subject: "",//Change Later
                    body: "",
                    attachmentIDs: "",
                }
            },
            attachmentDetails: {},
            formErrors: {orderItems: []},
            isLoadingOrder: false,
            currentUser: {},
            isOpenImportModal: false
        };

        this.userService = new UserService();
        this.purchaseOrderService = new PurchaseOrderService();
        this.attachmentService = new AttachmentService();
        this.handleChange = this.handleChange.bind(this);
        this.createPurchaseOrderDocument = this.createPurchaseOrderDocument.bind(this);
        this.toggleEmailPurchaseOrderModal = this.toggleEmailPurchaseOrderModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getStockDetails = this.getStockDetails.bind(this);
        this.gettingAttachmentIDWithDescription = this.gettingAttachmentIDWithDescription.bind(this);
        this.emailFormatDetail = this.emailFormatDetail.bind(this);
        this.getSupplierDetail = this.getSupplierDetail.bind(this);
        this.getUserDetails = this.getUserDetails.bind(this);
        this.addImportedItems = this.addImportedItems.bind(this);
        this.toggleImportModal = this.toggleImportModal.bind(this);
        this.getDefaultUserDetails = this.getDefaultUserDetails.bind(this);
    }

    componentDidMount() {
        let ordNum = null;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            ordNum = searchParams.ordNum;
            if (ordNum) {
                this.getPurchaseOrderDetails(searchParams.ordNum);
            }
        }

        if (!ordNum) {
            this.getDefaultUserDetails();
        }
    }

    getDefaultUserDetails() {
        let { purchaseOrder } = this.state;
        let currentUser = this.userService.getLoggedInUser();
        purchaseOrder.preparedBy = currentUser.firstName + " " + currentUser.lastName;
        purchaseOrder.userEmail = currentUser.emailAddress;
        purchaseOrder.etaDate = getDateString(addDate(new Date(), 7, "days", "dateObj"), "DD/MM/YYYY HH:mm:ss");
        this.setState({ purchaseOrder, currentUser });
    }

    getUserDetails(userName) {
        let {purchaseOrder} = this.state;
        this.userService.findUserByUserName(userName).then(response => {
            purchaseOrder.userEmail = response.data.emailAddress;
            this.setState({purchaseOrder});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getPurchaseOrderDetails(ordNum) {
        let {purchaseOrder, formErrors} = this.state;
        this.setState({isLoadingOrder: true});
        this.purchaseOrderService.getOrder(ordNum).then(response => {
            purchaseOrder = response.data;
            purchaseOrder.instructions = response.data.ref;
            purchaseOrder.internalNote = response.data.notes;
            purchaseOrder.preparedBy = response.data.orderItems[0] ? response.data.orderItems[0].createdBy : "";

            (purchaseOrder.orderItems || []).forEach((item, index) => {
                if (!formErrors.orderItems[index]) {
                    formErrors.orderItems[index] = {error: {}};
                }
                item.colour = (item.stock && item.stock.keywayStock) ? item.stock.keywayStock.colour : '';
                item.length = (item.stock && item.stock.keywayStock) ? item.stock.keywayStock.length : '';
                item.width = (item.stock && item.stock.keywayStock) ? item.stock.keywayStock.width : '';
                item.height = (item.stock && item.stock.keywayStock) ? item.stock.keywayStock.height : '';
                item.dimensionUnitName = (item.stock && item.stock.keywayStock && item.stock.keywayStock.dimensionUnitName) ? item.stock.keywayStock.dimensionUnitName : '';
                item.eachUnitName = (item.stock && item.stock.keywayStock && item.stock.keywayStock.eachUnitName) ? item.stock.keywayStock.eachUnitName : "unit";
                item.whUnitName = (item.stock && item.stock.keywayStock && item.stock.keywayStock.whUnitName && item.stock.keywayStock.whUnitName !== "0") ? item.stock.keywayStock.whUnitName : "N/A";
                item.whUnitQty = (item.stock && item.stock.keywayStock && item.stock.keywayStock.whUnitQty) ? item.stock.keywayStock.whUnitQty : 1;
                item.etaDate = changeFormatOfDateString(purchaseOrder.orderItems[index].etaDate, "DD/MM/YYYY", "DD/MM/YYYY HH:mm:ss");
            });

            this.setState({purchaseOrder, isLoadingOrder: false, formErrors}, () => {
                this.getUserDetails(purchaseOrder.preparedBy);
                this.getSupplierDetail(purchaseOrder.supplierCode);
                this.gettingAttachmentIDWithDescription(purchaseOrder.ordNum);
            });
        }).catch(error => {
            this.setState({isLoadingOrder: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    getSupplierDetail(accountID) {
        let {emailPurchaseOrderModal, purchaseOrder} = this.state;
        let temp = [];
        let tempOptions = [];
        creditorSevice.getSupplierByAccount(accountID).then(response => {
            let supplier = response.data;
            if (supplier) {
                //check for sales contact
                if (supplier.contacts && supplier.contacts.filter(x => x.contactType === "Sales").length > 0) {
                    supplier.contacts.filter(x => x.contactType === "Sales").map(item => {
                        if (item.email) {
                            temp.push({label: item.email, value: item.email});
                            tempOptions.push(temp);
                        }
                    });
                }
                else {
                    if (supplier.email) {
                        temp.push({label: supplier.email, value: supplier.email});
                        tempOptions.push(temp);
                    }
                }
                supplier.contacts.forEach(contact => {
                    if (contact.email) {
                        tempOptions.push({label: contact.email, value: contact.email});
                    }
                });
                emailPurchaseOrderModal.mail.to = temp;
                emailPurchaseOrderModal.mail.toOptions = tempOptions;
                emailPurchaseOrderModal.mail.companyName = supplier.company;
                purchaseOrder.currency = supplier.currency;
                purchaseOrder.areaCode = supplier.creditorAreaCode ? supplier.creditorAreaCode.description : "";
                this.setState({emailPurchaseOrderModal, purchaseOrder});
                if (!purchaseOrder.ordNum) {
                    let cloneOrderItems = cloneDeep(purchaseOrder.orderItems);
                    (cloneOrderItems || []).map((item, index) => {
                        this.handleChange(item, 'prodCode', index, true);
                    });
                    this.getDefaultUserDetails();
                }
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    handleChange(change, key, index, isUpdateStateRequired) {
        let {purchaseOrder, formErrors, isRequestValidated, attachmentDetails} = this.state;
        let itemIndex = -1, isDuplicateProdcode;

        switch (key) {
            case "supplierCode":
                purchaseOrder.supplierCode = change;
                this.setState({purchaseOrder}, () => {
                    this.getSupplierDetail(change);
                });
                break;
            case "item-add":
                if (!purchaseOrder.orderItems) {
                    purchaseOrder.orderItems = [];
                }
                purchaseOrder.orderItems.push({
                    itemNum: 0,
                    prodCode: "",
                    supplierRef: "",
                    qty: 0.000,
                    cost: 0
                });
                this.setState({purchaseOrder});
                break;
            case "item-copy":
                let x = cloneDeep(change);
                x.ItemNum = 0;
                purchaseOrder.orderItems.splice(index, 0, x);
                this.setState({purchaseOrder});
                break;
            case "item-delete":
                purchaseOrder.orderItems.splice(index, 1);
                break;
            case "preparedBy":
                purchaseOrder[key] = change;
                this.setState({purchaseOrder});
                break;
            case "prodCode":
                isDuplicateProdcode = false;
                if (change && change.prodCode) {
                    itemIndex = purchaseOrder.orderItems.findIndex(orderItem => orderItem.prodCode === change.prodCode);
                    isDuplicateProdcode = itemIndex > -1;
                }
                if (isDuplicateProdcode) {
                    purchaseOrder.orderItems[index].prodCode = "";
                    toast.info("Selected prodcode is already added at " + (itemIndex + 1) + "sequence");
                } else {
                    purchaseOrder.orderItems[index].prodCode = change ? change.prodCode : '';
                    purchaseOrder.orderItems[index].supplierCode = purchaseOrder.supplierCode;
                    purchaseOrder.orderItems[index].description = (change && change.keywayStock && change.keywayStock.description) ? change.keywayStock.description : ((change && change.description ? change.description : ""));
                    purchaseOrder.orderItems[index].colour = (change && change.keywayStock) ? change.keywayStock.colour : '';
                    purchaseOrder.orderItems[index].length = (change && change.keywayStock) ? change.keywayStock.length : '';
                    purchaseOrder.orderItems[index].width = (change && change.keywayStock) ? change.keywayStock.width : '';
                    purchaseOrder.orderItems[index].height = (change && change.keywayStock) ? change.keywayStock.height : '';
                    purchaseOrder.orderItems[index].dimensionUnitName = (change && change.keywayStock && change.keywayStock.dimensionUnitName) ? change.keywayStock.dimensionUnitName : '';
                    purchaseOrder.orderItems[index].eachUnitName = (change && change.keywayStock && change.keywayStock.eachUnitName) ? change.keywayStock.eachUnitName : "unit";
                    purchaseOrder.orderItems[index].whUnitName = (change && change.keywayStock && change.keywayStock.whUnitName) ? change.keywayStock.whUnitName : "unit";
                    purchaseOrder.orderItems[index].whUnitQty = (change && change.keywayStock && change.keywayStock.whUnitQty) ? change.keywayStock.whUnitQty : 1;
                    purchaseOrder.orderItems[index].stockSupplierCode = (change && change.keywayStock) ? change.keywayStock.supplierCode : "";
                    purchaseOrder.orderItems[index].etaDate = getDateString(addDate(new Date(), 7, "days", "dateObj"), "DD/MM/YYYY HH:mm:ss");
                    purchaseOrder.orderItems[index].cost = 0;
                    purchaseOrder.orderItems[index].costType = '';
                    purchaseOrder.orderItems[index].costTypeOrdNum = null;
                    purchaseOrder.orderItems[index].supplierRef = "";
                    purchaseOrder.orderItems[index].isStockLoading = !!(change && change.prodCode);

                    if (!formErrors.orderItems[index]) {
                        formErrors.orderItems[index] = {error: {}};
                    }
                    formErrors.orderItems[index].error.prodCode = '';
                }
                this.setState({purchaseOrder, formErrors}, () => {
                    if (purchaseOrder.orderItems[index].prodCode) {
                        this.getStockDetails(purchaseOrder.orderItems[index].prodCode, purchaseOrder.orderItems[index].supplierCode);
                    }
                });
                break;
            case "instructions":
            case "internalNote":
                purchaseOrder[key] = change;
                this.setState({purchaseOrder});
                break;
            case "purchaseOrder.etaDate":
                purchaseOrder.etaDate = change ? getDateString(change, "DD/MM/YYYY HH:mm:ss") : "";
                purchaseOrder.orderItems.forEach(orderItem => {
                    orderItem.etaDate = purchaseOrder.etaDate;
                });
                this.setState({purchaseOrder});
                break;
            case "etaDate":
                purchaseOrder.orderItems[index][key] = change ? getDateString(change, "DD/MM/YYYY HH:mm:ss") : "";
                this.setState({purchaseOrder});
                break;
            case "qty":
                purchaseOrder.orderItems[index][key] = change;
                this.setState({purchaseOrder});
                break;
            case "newOrder":
                purchaseOrder = {orderItems: []};
                isRequestValidated = false;
                attachmentDetails = {};
                formErrors = {orderItems: []};
                this.setState({purchaseOrder, isRequestValidated, attachmentDetails, formErrors});
                this.updateUrl();
                break;
            default:
                purchaseOrder.orderItems[index][key] = change;
                this.setState(purchaseOrder);
        }
        if (isUpdateStateRequired) {
            this.setState({purchaseOrder});
        } else {
            return purchaseOrder.orderItems;
        }
    }

    updateUrl() {
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("ordNum")) {
            searchParams.delete("ordNum");
        }
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }

    addImportedItems(items) {
        let index;
        let {purchaseOrder, formErrors} = this.state;
        if (items && items.length > 0) {

            if (!purchaseOrder.orderItems) {
                purchaseOrder.orderItems = [];
            }
            let etaDate = getDateString(addDate(new Date(), 7, "days", "dateObj"), "DD/MM/YYYY HH:mm:ss");
            items.forEach(item => {
                let orderItem = {
                    itemNum: 0,
                    prodCode: item.prodCode,
                    supplierCode: purchaseOrder.supplierCode,
                    qty: item.qty,
                    etaDate: etaDate,
                    cost: 0,
                    costType: '',
                    costTypeOrdNum: null,
                    supplierRef: "",
                };
                purchaseOrder.orderItems.push(orderItem);
                index = purchaseOrder.orderItems.length - 1;
                if (!formErrors.orderItems[index]) {
                    formErrors.orderItems[index] = {error: {}};
                }
                formErrors.orderItems[index].error.prodCode = '';
                if (item.stockDetail) {
                    purchaseOrder.orderItems[index].description = (item.stockDetail.keywayStockResponse && item.stockDetail.keywayStockResponse.description) ? item.stockDetail.keywayStockResponse.description : (item.stockDetail.wwStockResponse && item.stockDetail.wwStockResponse.description ? item.stockDetail.wwStockResponse.description : "");
                    purchaseOrder.orderItems[index].colour = item.stockDetail.keywayStockResponse ? item.stockDetail.keywayStockResponse.colour : "";
                    purchaseOrder.orderItems[index].length = item.stockDetail.keywayStockResponse ? item.stockDetail.keywayStockResponse.length : "";
                    purchaseOrder.orderItems[index].width = item.stockDetail.keywayStockResponse ? item.stockDetail.keywayStockResponse.width : "";
                    purchaseOrder.orderItems[index].height = item.stockDetail.keywayStockResponse ? item.stockDetail.keywayStockResponse.height : "";
                    purchaseOrder.orderItems[index].dimensionUnitName = (item.stockDetail.keywayStockResponse && item.stockDetail.keywayStockResponse.dimensionUnitName) ? item.stockDetail.keywayStockResponse.dimensionUnitName : "";
                    purchaseOrder.orderItems[index].eachUnitName = (item.stockDetail.keywayStockResponse && item.stockDetail.keywayStockResponse.eachUnitName ) ? item.stockDetail.keywayStockResponse.eachUnitName : "";
                    purchaseOrder.orderItems[index].whUnitName = (item.stockDetail.keywayStockResponse && item.stockDetail.keywayStockResponse.whUnitName && item.stockDetail.keywayStockResponse.whUnitName !== "0") ? item.stockDetail.keywayStockResponse.whUnitName : "N/A";
                    purchaseOrder.orderItems[index].whUnitQty = (item.stockDetail.keywayStockResponse && item.stockDetail.keywayStockResponse.whUnitQty ) ? item.stockDetail.keywayStockResponse.whUnitQty : 1;
                    purchaseOrder.orderItems[index].stockSupplierCode = item.stockDetail.keywayStockResponse ? item.stockDetail.keywayStockResponse.supplierCode : "";
                    purchaseOrder.orderItems[index].cost = removeDecimalNumber(item.stockDetail.stockUnitPrice, 2);
                    purchaseOrder.orderItems[index].costType = item.stockDetail.unitPriceType;
                    purchaseOrder.orderItems[index].costTypeOrdNum = item.stockDetail.unitPriceTypeOrdNum;
                    purchaseOrder.orderItems[index].supplierRef = item.stockDetail.creditorStockResponse ? item.stockDetail.creditorStockResponse.xRefCode : "";
                    formErrors.orderItems[index].error.prodCodeUnmatchSupplier = item.stockDetail.isProdCodeUnmatch ? "Selected ProdCode does not match with the selected Supplier." : "";
                }
            });
            this.setState({purchaseOrder, formErrors});
        }
    }


    /* getEtaMaxDate(purchaseOrder) {
         let dateArray = [], maxDate = null, maxDateString;
         purchaseOrder.orderItems.forEach(orderItem => {
             if (orderItem.etaDate) {
                 dateArray.push(getDateObj(orderItem.etaDate, "DD/MM/YYYY HH:mm:ss"));
             }
         });
         if (dateArray.length > 0) {
             maxDate = getMaxDate(dateArray);
         } else {
             maxDate = addDate(new Date(), 7, "days", "dateObj");
         }
         maxDateString = getDateString(maxDate, "DD/MM/YYYY HH:mm:ss");
         return maxDateString;
     }*/

    isValid(purchaseOrder) {
        let errors = [], formErrors = {orderItems: []}, isValid = true, msg = "";
        if (!purchaseOrder.supplierCode) {
            msg = "Select supplier code!";
            formErrors.supplierCode = msg;
            errors.push(msg);
            isValid = false;
        }
        if (!purchaseOrder.preparedBy) {
            msg = "Enter the Prepared By!";
            formErrors.preparedBy = msg;
            errors.push(msg);
            isValid = false;
        }
        if (!purchaseOrder.etaDate) {
            msg = "Select ETA date";
            formErrors.etaDate = msg;
            errors.push(msg);
            isValid = false;
        }
        (purchaseOrder.orderItems || []).forEach((item, index) => {
            formErrors.orderItems[index] = {
                error: {}
            };
            if (!item.prodCode) {
                msg = "Select prodCode";
                formErrors.orderItems[index].error.prodCode = msg;
                errors.push(msg);
                isValid = false;
            }
            if (!item.qty) {
                msg = "Enter qty";
                formErrors.orderItems[index].error.qty = msg;
                errors.push(msg);
                isValid = false;
            }

        });
        return {formErrors: formErrors, isValid: isValid, errors: errors};
    }

    getStockDetails(prodCode, supplierCode) {
        let {purchaseOrder, formErrors} = this.state;
        let index = -1;
        this.purchaseOrderService.getStockDetailWithPriceBySupplierCode(supplierCode, prodCode).then(response => {
            index = purchaseOrder.orderItems.findIndex(orderItem => orderItem.prodCode === response.data.wwStockResponse.prodCode);
            if (index > -1) {
                purchaseOrder.orderItems[index].cost = removeDecimalNumber(response.data.stockUnitPrice, 2);
                purchaseOrder.orderItems[index].costType = response.data.unitPriceType;
                purchaseOrder.orderItems[index].costTypeOrdNum = response.data.unitPriceTypeOrdNum;
                purchaseOrder.orderItems[index].supplierRef = response.data.creditorStockResponse ? response.data.creditorStockResponse.xRefCode : "";
                purchaseOrder.orderItems[index].isStockLoading = false;
                formErrors.orderItems[index].error.prodCodeUnmatchSupplier = response.data.isProdCodeUnmatch ? "Selected ProdCode does not match with the selected Supplier." : "";
            }
            this.setState({purchaseOrder, formErrors});
        }).catch(error => {
            index = purchaseOrder.orderItems.findIndex(orderItem => orderItem.prodCode === prodCode);
            if (index > -1) {
                purchaseOrder.orderItems[index].isStockLoading = false;
                this.setState({purchaseOrder});
            }
            console.error(handleErrorMessage(error));
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    handleSubmit() {
        let {purchaseOrder} = this.state;

        let result = this.isValid(purchaseOrder);
        this.setState({formErrors: result.formErrors});
        if (!result.isValid) {
            result.errors.forEach(error => {
                toast.error(error, {position: toast.POSITION.TOP_RIGHT});
            });
            return false;
        }
        this.setState({isLoading: true});
        this.purchaseOrderService.savePurchaseOrder(purchaseOrder).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("Saved!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                purchaseOrder.ordNum = response.data.ordNum;
                purchaseOrder.dateAdded = response.data.dateAdded;
                this.setState({purchaseOrder, isLoading: false}, () => {
                    this.createPurchaseOrderDocument();
                });
            }
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    createPurchaseOrderDocument() {
        let {purchaseOrder} = this.state;
        this.setState({isLoadingPurchaseOrderDocument: true});
        this.purchaseOrderService.createPurchaseOrderDocument(purchaseOrder.ordNum).then(response => {
            let attachmentDetails = response.data;
            this.setState({isLoadingPurchaseOrderDocument: false, attachmentDetails}, () => {
                this.emailFormatDetail(purchaseOrder);
            });
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleEmailPurchaseOrderModal(change) {
        let {emailPurchaseOrderModal} = this.state;
        emailPurchaseOrderModal.isOpen = change;
        this.setState({emailPurchaseOrderModal});
    }

    gettingAttachmentIDWithDescription(ordNum) {
        let description = 'Purchase Order ' + ordNum + '.pdf';
        let {attachmentDetails, purchaseOrder} = this.state;
        this.attachmentService.getAttachmentWithDescription(description).then(response => {
            attachmentDetails = response.data;
            this.setState({attachmentDetails}, () => {
                this.emailFormatDetail(purchaseOrder);
            });
        }).catch(error => {
            console.error(error);
            toast.error(handleErrorMessage(error));
        })
    }

    emailFormatDetail(purchaseOrder) {
        let {emailPurchaseOrderModal, attachmentDetails} = this.state;
        let currentUser = this.userService.getLoggedInUser();
        let currentUserFullName = currentUser.firstName + " " + currentUser.lastName;
        let sentByUserFullName = '';
        let sentByUserEmail = '';
        if (currentUserFullName === this.state.purchaseOrder.preparedBy) {
            sentByUserFullName = currentUserFullName;
            sentByUserEmail = currentUser.emailAddress;
        }
        else {
            sentByUserFullName = currentUserFullName;
            sentByUserEmail = currentUser.emailAddress;
        }
        emailPurchaseOrderModal.mail.attachmentIDs = attachmentDetails.id;
        emailPurchaseOrderModal.mail.companyName = purchaseOrder.company ? purchaseOrder.company : emailPurchaseOrderModal.mail.companyName;
        emailPurchaseOrderModal.mail.subject = "Windoware PO# " + purchaseOrder.ordNum + " (" + emailPurchaseOrderModal.mail.companyName + " ) ";
        emailPurchaseOrderModal.mail.from = [{
            label: sentByUserEmail ? sentByUserFullName + "(" + sentByUserEmail + ")" : "Email Not Found",
            value: sentByUserEmail ? sentByUserEmail : "Email Not Found"
        }];
        emailPurchaseOrderModal.mail.fromOptions = [{
            label: sentByUserEmail ? sentByUserFullName + "(" + sentByUserEmail + ")" : "Email Not Found",
            value: sentByUserEmail ? sentByUserEmail : "Email Not Found"
        }];
        //template1
        emailPurchaseOrderModal.mail.body1 = "Hi " + emailPurchaseOrderModal.mail.companyName + ",\n\n";
        emailPurchaseOrderModal.mail.body1 += "Please find attached the new Purchase Order #" + purchaseOrder.ordNum + ".\nWe need this order by/before " + changeFormatOfDateString(purchaseOrder.etaDate, "DD/MM/YYYY HH:mm:ss", "ddd MMM DD, YYYY") + ".\n";
        emailPurchaseOrderModal.mail.body1 += "Please arrange delivery to our Berrinba warehouse and confirm your ETA by returning this email.\n";
        emailPurchaseOrderModal.mail.body1 += "For any backorder, please inform us on the same day as we have to inform our customer.\n\n";
        emailPurchaseOrderModal.mail.body1 += "Please be aware our warehouse stock receiving time is as below -\n";
        emailPurchaseOrderModal.mail.body1 += "Monday to Thursday:  8 am – 2 pm\n";
        emailPurchaseOrderModal.mail.body1 += "Friday: 8 am - 12 pm\n";
        emailPurchaseOrderModal.mail.body1 += "Thank you.\n\n\n";
        emailPurchaseOrderModal.mail.body1 += "Kind Regards,\n";
        emailPurchaseOrderModal.mail.body1 += sentByUserFullName + "\n";
        emailPurchaseOrderModal.mail.body1 += sentByUserEmail ? sentByUserEmail + "\n" : "Email Not Found\n";
        emailPurchaseOrderModal.mail.body1 += "Windoware Pty Ltd\n";
        emailPurchaseOrderModal.mail.body1 += "Warehouse 2, 30-44 Ironbark Close, Berrinba 4117, QLD, Australia\n";
        emailPurchaseOrderModal.mail.body1 += "+61 7 3299 3788\n";

        //template 2
        emailPurchaseOrderModal.mail.body2 = "Hi " + emailPurchaseOrderModal.mail.companyName + ",\n\n";
        emailPurchaseOrderModal.mail.body2 += "Please find attached the new Purchase Order #" + purchaseOrder.ordNum + ".\nWe would like to pick up this order by " + changeFormatOfDateString(purchaseOrder.etaDate, "DD/MM/YYYY HH:mm:ss", "ddd MMM DD, YYYY") + ".\n\n";
        emailPurchaseOrderModal.mail.body2 += "Can you please advise when it can be ready? Thanks!\n\n\n";
        emailPurchaseOrderModal.mail.body2 += "Kind Regards,\n";
        emailPurchaseOrderModal.mail.body2 += sentByUserFullName + "\n";
        emailPurchaseOrderModal.mail.body2 += sentByUserEmail ? sentByUserEmail + "\n" : "Email Not Found\n";
        emailPurchaseOrderModal.mail.body2 += "Windoware Pty Ltd\n";
        emailPurchaseOrderModal.mail.body2 += "Warehouse 2, 30-44 Ironbark Close, Berrinba 4117, QLD, Australia\n";
        emailPurchaseOrderModal.mail.body2 += "+61 7 3299 3788\n";

        this.setState({emailPurchaseOrderModal});
    }


    toggleImportModal(isOpen) {
        this.setState({isOpenImportModal: isOpen});
    }

    render() {
        let {
            isLoading, purchaseOrder, isRequestValidated, isLoadingOrder,
            emailPurchaseOrderModal, attachmentDetails, isLoadingPurchaseOrderDocument, formErrors, isOpenImportModal
        } = this.state;
        let totalQtySent = 0;
        let totalAmount = 0;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem href="javascript:void(0)">Purchase Order</BreadcrumbItem>
                </Breadcrumb>
                {
                    isLoadingOrder
                        ? <Spinner color={"primary"}/>
                        : <div>
                            {purchaseOrder.ordNum
                                ? <Row>
                                    <Col><h5>#{purchaseOrder.ordNum}</h5></Col>
                                    <Col>
                                        <div className="text-right">
                                            <h6>
                                                <small className="mr-2">Created on</small>
                                                {changeFormatOfDateString(purchaseOrder.dateAdded, "DD/MM/YYYY hh:mm:ss", "ddd, Do MMM YYYY")}
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>
                                : null
                            }
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <div className={"mb-2"}>
                                                <strong>Supplier Details</strong>
                                                <Button color={"primary"}
                                                        size={"sm"}
                                                        className={"float-right"}
                                                        onClick={() => this.handleChange(null, "newOrder")}>
                                                    <i className="fa fa-plus mr-2" aria-hidden="true"/>
                                                    Create New PO
                                                </Button>
                                            </div>
                                            <div className={"mb-1"}>Account
                                                {
                                                    purchaseOrder.supplierCode
                                                        ? <span className={"float-right"}>{purchaseOrder.areaCode}
                                                            ({purchaseOrder.currency})</span>
                                                        : null
                                                }
                                            </div>
                                            <SearchCreditorAcccount
                                                handleAccountChange={(selected) => this.handleChange(selected, "supplierCode")}
                                                defaultAccountID={purchaseOrder ? purchaseOrder.supplierCode : ""}
                                                selectedAccountID={purchaseOrder ? purchaseOrder.supplierCode : ""}/>
                                            <FormText color="danger">{formErrors.supplierCode}</FormText>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {
                                purchaseOrder.supplierCode ?
                                    <div>
                                        <Card className={"mt-3"}>
                                            <CardBody>
                                                <div className={"mt-2"}>
                                                    <Table responsive={true} striped={true} bordered={true} hover={true}
                                                           size={"sm"}>
                                                        <thead>
                                                        <tr>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 380}}>Item
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 135}}>
                                                                Size
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 75}}>
                                                                <div>Supplier</div>
                                                                <div>Ref.</div>
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 135}}>
                                                                Each Qty
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 135}}>
                                                                WH Pack Qty
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 100}}>
                                                                <div>Unit</div>
                                                                <div>Price($)</div>
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 80}}>Amount
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 135}}>Received By / ETA
                                                                <div>
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        selected={getDateObj(purchaseOrder.etaDate, "DD/MM/YYYY HH:mm:ss")}
                                                                        onChange={date => this.handleChange(date, "purchaseOrder.etaDate", -1, true)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        isClearable
                                                                        placeholderText="dd/MM/yyyy"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        maxDate={maxDate}
                                                                        minDate={minDate}
                                                                        scrollableYearDropdown
                                                                        dropdownMode="select"
                                                                        withPortal
                                                                    />
                                                                </div>
                                                            </th>
                                                            <th className="align-middle text-center"
                                                                style={{minWidth: 100}}>
                                                                Action
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {(purchaseOrder.orderItems || []).map((item, index) => {
                                                            totalQtySent += parseFloat(item.qty);
                                                            totalAmount += (parseFloat(item.qty) * item.cost);
                                                            return <tr key={index}>
                                                                <td className={"align-middle"}>
                                                                    <div>
                                                                        <SearchStock
                                                                            disabled={false}
                                                                            invalid={isRequestValidated && !item.prodCode}
                                                                            handleSelection={(selectedStock) => this.handleChange(selectedStock, "prodCode", index, true)}
                                                                            selected={item.prodCode ? item.prodCode : ''}
                                                                            defaultProdCode={""}
                                                                            filter={{
                                                                                bmFlag: null,
                                                                                excludeDiscontinued: null,
                                                                                excludeSoldOut: null,
                                                                                stockGroupCodes: [],
                                                                                stockMasterGroupCodes: [],
                                                                                colour: "",
                                                                                excludeOffRange: null,
                                                                                includeFabric: null,
                                                                                excludeArchived: null,
                                                                                excludeOutOfStock: null,
                                                                                isNonStock: 0,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        item.description
                                                                            ?
                                                                            <p className={"mt-1 mb-0"}>{this.purchaseOrderService.generateStockDescription(item)}</p>
                                                                            : null
                                                                    }
                                                                    {
                                                                        item.colour
                                                                            ?
                                                                            <p className={"mb-0"}>
                                                                                Colour: {item.colour}</p>
                                                                            : null
                                                                    }
                                                                    {item.isStockLoading
                                                                        ? <Spinner size={"sm"} color={"primary"}/>
                                                                        : null
                                                                    }
                                                                    <FormText
                                                                        color="danger">
                                                                        {(formErrors.orderItems && formErrors.orderItems[index]) ? formErrors.orderItems[index].error.prodCode : ""}
                                                                        {(formErrors.orderItems && formErrors.orderItems[index]) ? formErrors.orderItems[index].error.prodCodeUnmatchSupplier : ""}
                                                                    </FormText>
                                                                </td>
                                                                <td className={"align-middle text-center"}>
                                                                    <div>{item.length ? ("Length: " + item.length + " " + item.dimensionUnitName) : ""}</div>
                                                                    <div>{item.width ? ("Width: " + item.width + " " + item.dimensionUnitName) : ""}</div>
                                                                    <div>{item.height ? ("Height: " + item.height + " " + item.dimensionUnitName) : ""}</div>
                                                                </td>
                                                                <td className={"align-middle"}>
                                                                    <Input type={"text"}
                                                                           className="align-middle"
                                                                           value={item.supplierRef ? item.supplierRef : "-"}
                                                                           onChange={(e) => this.handleChange(e.target.value, "supplierRef", index, true)}/>
                                                                </td>
                                                                <td className={"align-middle"}>
                                                                    <InputGroup>
                                                                        <Input type="number"
                                                                               name="qty"
                                                                               step="any"
                                                                               value={item.qty}
                                                                               onFocus={(event) => event.target.select()}
                                                                               onChange={(e) => this.handleChange(e.target.value, e.target.name, index, true)}
                                                                               placeholder="0.000"/>
                                                                        <InputGroupText>
                                                                            <Pluralize
                                                                                showCount={false}
                                                                                count={item.qty}
                                                                                singular={item.eachUnitName ? item.eachUnitName : "unit"}/>
                                                                            </InputGroupText>
                                                                    </InputGroup>
                                                                    <FormText
                                                                        color="danger">{formErrors.orderItems && formErrors.orderItems[index] ? formErrors.orderItems[index].error.qty : ""}</FormText>
                                                                </td>
                                                                <td className={"align-middle text-center"}>
                                                                    <StockQtyFormat
                                                                        eachUnitName={item.eachUnitName}
                                                                        whUnitName={item.whUnitName}
                                                                        whUnitQty={item.whUnitQty}
                                                                        qty={item.qty}
                                                                        qtyType={STOCK_QTY_TYPE.each}
                                                                        format={STOCK_QTY_FORMAT.packQty_of_PackSize}
                                                                        emptyPlaceholderText={"-"}
                                                                        isPoManage={true}
                                                                    />
                                                                </td>
                                                                <td className={"align-middle"}>
                                                                    <Input type="number" name="cost" step="any"
                                                                           value={item.cost} min="0"
                                                                           onFocus={(event) => event.target.select()}
                                                                           onChange={(e) => this.handleChange(e.target.value, e.target.name, index, true)}
                                                                           placeholder="cost"/>
                                                                </td>
                                                                <td className={"align-middle text-right"}>
                                                                    <NumberFormat
                                                                        value={item.cost * parseFloat(item.qty)}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={false}
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}/>
                                                                </td>
                                                                <td className="text-center align-middle">
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        selected={getDateObj(item.etaDate, "DD/MM/YYYY HH:mm:ss")}
                                                                        onChange={date => this.handleChange(date, "etaDate", index, true)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        isClearable
                                                                        placeholderText="dd/MM/yyyy"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        maxDate={maxDate}
                                                                        minDate={minDate}
                                                                        scrollableYearDropdown
                                                                        dropdownMode="select"
                                                                        withPortal
                                                                    />
                                                                </td>
                                                                <td className="text-center align-middle">
                                                                        <span>
                                                                            {item.prodCode ?
                                                                                <Link style={{cursor: "pointer"}}
                                                                                      title={"Click here to see stock details"}
                                                                                      target="_blank"
                                                                                      to={"/inventory/stock/enquiry?prodCode=" + item.prodCode}>
                                                                                    <i className="fa fa-external-link fa-lg mr-2"
                                                                                       aria-hidden="true"/>
                                                                                </Link>
                                                                                : null
                                                                            }
                                                                            {item.prodCode ?
                                                                                <span className={"text-muted"}>|</span>
                                                                                : null
                                                                            }
                                                                            <Button color={"link"}
                                                                                    title={"delete item"}
                                                                                    className={"mb-1"}
                                                                                    onClick={() => this.handleChange(item, "item-delete", index, true)}>
                                                                                <i className="fa fa-trash-o fa-lg text-danger"
                                                                                   aria-hidden="true"/>
                                                                            </Button>
                                                                        </span>
                                                                </td>
                                                            </tr>

                                                        })}

                                                        {(purchaseOrder.orderItems && purchaseOrder.orderItems.length > 0)
                                                            ? <tr>
                                                                <td className="align-middle text-left"
                                                                    colSpan={3}><strong>Total</strong>
                                                                </td>
                                                                <td className="align-middle text-right">
                                                                    <NumberFormat value={totalQtySent}
                                                                                  displayType={'text'}
                                                                                  fixedDecimalScale={false}
                                                                                  thousandSeparator={true}
                                                                                  decimalScale={3}/>
                                                                </td>
                                                                <td/>
                                                                <td/>
                                                                <td className="align-middle text-right">
                                                                    <NumberFormat value={totalAmount}
                                                                                  displayType={'text'}
                                                                                  decimalScale={2}
                                                                                  fixedDecimalScale={false}
                                                                                  thousandSeparator={true}
                                                                                  prefix={"$"}/>
                                                                </td>
                                                                <td colSpan={2}></td>
                                                            </tr> : null
                                                        }

                                                        </tbody>
                                                    </Table>
                                                    <Row>
                                                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"mt-2"}>
                                                            <Button color={"primary"}
                                                                    size={"sm"}
                                                                    title={"add new item"}
                                                                    onClick={() => this.handleChange(null, "item-add", -1, true)}>
                                                                <i className="fa fa-plus mr-2" aria-hidden="true"/>Add
                                                                new
                                                                item
                                                            </Button>
                                                            <Button color={"primary"}
                                                                    size={"sm"}
                                                                    className={"ml-2"}
                                                                    title={"Import items"}
                                                                    onClick={() => this.toggleImportModal(!isOpenImportModal)}>
                                                                <i className="fa fa-file-excel-o mr-2"
                                                                   aria-hidden="true"/>Import
                                                                items
                                                            </Button>
                                                        </Col>

                                                    </Row>
                                                </div>

                                            </CardBody>
                                        </Card>
                                        {
                                            purchaseOrder && purchaseOrder.orderItems.length > 0
                                                ? <Row className={"mt-3"}>
                                                    <Col xl={7} lg={7} md={7} sm={12} xs={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <div>
                                                                    <p><strong>Other Options</strong></p>
                                                                    <Row>
                                                                        {/*<Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                            <Label> Prepared By</Label>
                                                                            <div>
                                                                                <Input type="text" name="preparedBy"
                                                                                       invalid={isRequestValidated && !purchaseOrder.preparedBy}
                                                                                       value={purchaseOrder.preparedBy}
                                                                                       disabled
                                                                                       onChange={(e) => this.handleChange(e.target.value, e.target.name, 0, true)}
                                                                                       placeholder="Prepared By"/>
                                                                                <FormText
                                                                                    color="danger">{formErrors.preparedBy}</FormText>
                                                                            </div>
                                                                        </Col>*/}
                                                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                            <div>Internal Notes</div>
                                                                            <Input
                                                                                rows={2}
                                                                                value={purchaseOrder.internalNote}
                                                                                onChange={(e) => this.handleChange(e.target.value, "internalNote", -1, true)}
                                                                                type="textarea"
                                                                                name="internalNote"
                                                                                placeholder="Write notes here"/>
                                                                        </Col>
                                                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}
                                                                             className={"mt-2"}>
                                                                            <div>Special Instructions</div>
                                                                            <Input rows={2}
                                                                                   onChange={(e) => this.handleChange(e.target.value, "instructions", -1, true)}
                                                                                   type="textarea"
                                                                                   name="instructions"
                                                                                   value={purchaseOrder.instructions}
                                                                                   placeholder="Write special instructions here"/>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <div>
                                                                    <p><strong>Summary</strong></p>
                                                                    <p>
                                                                        Prepared by
                                                                        <span className={"float-right"}>
                                                                                {purchaseOrder.preparedBy}
                                                                            </span>
                                                                    </p>
                                                                    <hr/>
                                                                    <p className={"mt-2 mb-0"}>
                                                                        Total Qty
                                                                        <span className={"float-right"}>
                                                                                <strong>
                                                                                    <NumberFormat
                                                                                        value={totalQtySent}
                                                                                        displayType={'text'}
                                                                                        decimalScale={3}
                                                                                        fixedDecimalScale={false}
                                                                                        thousandSeparator={true}/>
                                                                                </strong>
                                                                            </span>
                                                                    </p>
                                                                    <p className={"mt-1  mb-2"}>
                                                                        Total Amount
                                                                        <span className={"float-right"}>
                                                                            <strong>
                                                                                <NumberFormat value={totalAmount}
                                                                                              displayType={'text'}
                                                                                              decimalScale={2}
                                                                                              fixedDecimalScale={false}
                                                                                              thousandSeparator={true}
                                                                                              prefix={"$"}/>
                                                                            </strong>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </CardBody>
                                                            <CardFooter>
                                                                <div className="text-right">
                                                                    <Button className="btn mr-2" color="primary" size={"sm"}
                                                                            onClick={this.handleSubmit}
                                                                            disabled={isLoading}>
                                                                        {isLoading ? <Spinner size={"sm"} className={"mr-1"}/> :
                                                                            <i className="fa fa-floppy-o mr-1"
                                                                               aria-hidden="true"/>}
                                                                        {purchaseOrder.ordNum ? " Update " : " Submit "}

                                                                    </Button>

                                                                    <Button className="btn" color="secondary" size={"sm"}>
                                                                        <Link to="/purchase-orders" className={"text-white"}>
                                                                            <i className="fa fa-times mr-1" aria-hidden="true"/>
                                                                            Cancel
                                                                        </Link>
                                                                    </Button>
                                                                </div>
                                                            </CardFooter>
                                                        </Card>

                                                    </Col>
                                                </Row>
                                                : null
                                        }
                                    </div>
                                    : null
                            }

                            {purchaseOrder.ordNum ?
                                <Card className={"mt-3"}>
                                    <CardBody>
                                        <p className={"mb-1"}><strong>Document Details</strong></p>
                                        <Row>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}
                                                 className={"pr-0"}>
                                                {!isLoadingPurchaseOrderDocument ?
                                                    <UploadFiles
                                                        isReadOnly={true}
                                                        attachmentIDs={attachmentDetails.id ? attachmentDetails.id : ""}
                                                        multiple={false}
                                                        maxLength={1}/>
                                                    : null
                                                }
                                            </Col>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}
                                                 className={"text-right"}>
                                                <div className={"mt-2"}>
                                                    {( purchaseOrder.orderItems.length > 0) ?
                                                        <Button color={"primary"}
                                                                size={"sm"}
                                                                disabled={isLoadingPurchaseOrderDocument}
                                                                onClick={this.createPurchaseOrderDocument}>
                                                            {
                                                                isLoadingPurchaseOrderDocument
                                                                    ?
                                                                    <Spinner size="sm" className="mr-2"
                                                                             color={"light"}/>
                                                                    : <i className="fa fa-refresh mr-2"
                                                                         aria-hidden="true"/>
                                                            }
                                                            {
                                                                (attachmentDetails && attachmentDetails.id
                                                                    ? (isLoadingPurchaseOrderDocument ? "Updating purchase order pdf file" : "Update purchase order pdf file")
                                                                    : (isLoadingPurchaseOrderDocument ? "Creating purchase order pdf file" : "Create purchase order pdf file"))
                                                            }
                                                        </Button>
                                                        : null
                                                    }
                                                    <Button className="btn ml-2"
                                                            color="primary"
                                                            size={"sm"}
                                                            disabled={emailPurchaseOrderModal.isLoadingEmailSend}
                                                            onClick={() => this.toggleEmailPurchaseOrderModal(!emailPurchaseOrderModal.isOpen)}>
                                                        <i className="fa fa-share mr-2"
                                                           aria-hidden="true"/>
                                                        Send Email
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                : null}
                        </div>
                }

                {
                    emailPurchaseOrderModal.isOpen
                        ? <PurchaseOrderSendEmailModal isOpen={emailPurchaseOrderModal.isOpen}
                                                       toggle={this.toggleEmailPurchaseOrderModal}
                                                       message={emailPurchaseOrderModal.mail}
                                                       attachmentDetails={attachmentDetails}
                                                       selectedData={purchaseOrder}/>
                        : null
                }


                <ImportPurchaseOrderItemModal isOpen={isOpenImportModal}
                                              toggle={this.toggleImportModal}
                                              addImportedItems={this.addImportedItems}
                                              supplierCode={purchaseOrder.supplierCode}
                />
                <ToastContainer/>
            </div>
        )
    }
}




