import React, {Component} from 'react';
import {
    Col, Row,
} from 'reactstrap';
import SoldProductUnitsChart from '../debtor/purchaseHistory/SoldProductUnitsChart';
import SalesByMonthChart from '../debtor/MonthySales/SalesByMonthChart';
import SalesByMonthComparison from '../debtor/MonthySales/SalesByMonthComparison';
import PurchasedProductsByMonth from '../debtor/MonthySales/PurchasedProductsByMonth';
import CustomerSalesMonthlyPerformanceByFY from '../debtor/MonthySales/CustomerSalesMonthlyPerformanceByFY';
import CustomerService from '../../services/CustomerService';
import classnames from 'classnames';
import {toast} from 'react-toastify';
import {handleErrorMessage} from '../../services/CommonService';

export default class CustomerSalesStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountID: this.props.accountID,
            debtor: {},
            includeChildAccount: false
        };
        this.customerService = new CustomerService();
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.checkAccountIDIsParent = this.checkAccountIDIsParent.bind(this);
    }

    componentDidMount() {
        this.checkAccountIDIsParent(this.state.accountID);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, () => {
                this.checkAccountIDIsParent(nextProps.accountID);
            });
        }
    }


    checkAccountIDIsParent(accountID) {
        this.customerService.searchCustomer(accountID).then(response => {
            this.setState({debtor: response.data});
        }).catch(error => {
            this.setState({debtor: {}});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    isVisibleIncludeChildAccountCheckbox(debtor) {
        return (debtor && debtor.isParent && debtor.hasMultipleChildAccounts)
    }

    handleCheckBoxChange(value) {
        this.setState({includeChildAccount: value});
    }

    render() {
        let {accountID, includeChildAccount, debtor} = this.state;

        return (
            <div>
                {this.isVisibleIncludeChildAccountCheckbox(debtor) ?
                    <div className="mb-2">
                        <a href={"javascript:void(0)"}
                           className="mr-2"
                           onClick={() => this.handleCheckBoxChange(!includeChildAccount)}>
                            <i className={classnames("fa", "fa-lg", {
                                    "fa-check-square-o": includeChildAccount,
                                    "fa-square-o": !includeChildAccount,
                                }
                            )}/>
                        </a>Include {debtor.childAccountCount}
                        &nbsp;Child {(debtor.childAccountCount > 1) ? "Accounts" : "Account"} Data?
                    </div> : null}
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <CustomerSalesMonthlyPerformanceByFY accountID={accountID}
                                                             includeChildAccount={includeChildAccount}/>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"mt-4"}>
                        <PurchasedProductsByMonth accountID={accountID} includeChildAccount={includeChildAccount}/>
                    </Col>
                </Row>
                <hr/>
                <Row>

                    <Col xl={6} lg={6} md={12}
                         sm={12} xs={12} className={"mt-4"}>
                        <div>
                            <SalesByMonthChart
                                accountID={accountID} includeChildAccount={includeChildAccount}/>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12}
                         sm={12} xs={12} className={"mt-4"}>
                        <div className={"border-left pl-4"}>
                            <SoldProductUnitsChart
                                accountID={accountID} includeChildAccount={includeChildAccount}/>
                        </div>
                    </Col>
                </Row>

                <hr/>
                <div className={"mt-4"}>
                    <div style={{maxHeight: 250}}>
                        <SalesByMonthComparison accountID={accountID} height={250}
                                                includeChildAccount={includeChildAccount}/>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

            </div>

        );

    }
}