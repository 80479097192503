import React, {Component} from 'react';
import {
    Modal, ModalBody, ModalHeader,
     Spinner,
    Button,
    Row, Col
} from 'reactstrap';
import {toast} from "react-toastify";
import FileSaver from 'file-saver';
import AttachmentService from '../../services/AttachmentService';
import {handleErrorMessage} from '../../services/CommonService';
import retailService from '../../services/retail/RetailService';
import FileUploader from '../attachment/FileUploader';

const imageMaxSize = 50000;
const imageMinSize = 10000;
export default class DebtorRetailUploadCompanyLogoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyLogoObj:this.props.debtorDetail,
            accountID: this.props.accountID,
            fileName:null,
            downloading: false,
            loading: false,
            saving: false,
        };

        this.attachmentService = new AttachmentService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(change, key) {
        let { companyLogoObj, fileName } = this.state;
        switch (key) {
            case "imageFile": companyLogoObj[key] = change;
                fileName = URL.createObjectURL(change);
                break;
            default:
                companyLogoObj[key] = change;
        }
        this.setState({ companyLogoObj, fileName });
    }

    validateRequest(companyLogoObj) {
        if (!(companyLogoObj.imageFile) && !(companyLogoObj.imageFile.fileName)) {
            toast.info("Logo is missing!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        return true;
    }

    handleSubmit() {
        let { isOpen, toggle } = this.props;
        let { companyLogoObj, accountID } = this.state;
        if (accountID && companyLogoObj) {
            let isValid = this.validateRequest(companyLogoObj);

            if (!isValid) {
                return;
            }
            let data = new FormData();
            data.append('accountID', companyLogoObj.accountID);
            data.append('imageFile', companyLogoObj.imageFile);

            this.setState({saving: true});
            retailService.uploadCompanyLogo(data).then(response => {
                this.setState({saving: false});
                toast.success("Logo saved successfully", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                toggle(!isOpen);
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({saving: false});
            });
        } else {
            toast.error("Account not found, please re-login again", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
    }

    onDownloadAttachment(attachment) {
        this.attachmentService.DownloadAttachment(attachment.id).then(response => {
            FileSaver.saveAs(response.data, attachment.description);
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { loading, companyLogoObj, saving, fileName } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Upload Logo(png/jpg)
                </ModalHeader>
                <ModalBody>
                    {loading
                        ? <Spinner color="primary"/> :
                        <div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FileUploader
                                        handleSelection={(selection) => this.handleChange(selection, 'imageFile')}
                                        imageMaxSize={imageMaxSize} imageMinSize={imageMinSize}
                                    />
                                </Col>
                            </Row>

                            <hr/>

                            <div className={"text-right mt-2"}>
                                <Button color={"primary"} size={"sm"}
                                        onClick={() => this.handleSubmit()}
                                        disabled={saving}>
                                    {saving
                                        ? <Spinner size="sm"
                                                   className={"mr-2"}
                                                   style={{color: "white"}}/>
                                        : <i className="fa fa-floppy-o mr-2"/>}
                                    {saving ? "Saving" : "Save"}
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    className={"btn"}
                                    color={"secondary"}
                                    size={"sm"}
                                    onClick={() => toggle(!isOpen)}>
                                    <i className="fa fa-times mr-2" aria-hidden="true"></i>
                                    Cancel</Button>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        );
    }
}