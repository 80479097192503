import React, {Component} from 'react';
import {concatenateStrings} from "../../../../../../services/CommonService";

export let projectionAwningLabelStore = [
    {
        key: "model",
        label: "Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "",
        label: "Fabric",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour, data.productLabel.fabricColourOffRange);
        }
    },
    {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "hooding",
        label: "Hood/Bracket",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.hooding, data.productLabel.bracketColour, data.productLabel.hoodingColour, data.productLabel.hoodingPowdercoatColour);
        }
    },
    {
        key: "controlSide",
        label: "Control Side",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "valance",
        label: "Valance",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "extras",
        label: "Extras",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.winchAndCord, data.productLabel.remote, data.productLabel.charger, data.productLabel.bridge, data.productLabel.windSensor);
        }
    }
];