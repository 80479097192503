import axios from '../axios';
import axiosFileDownloader from '../axiosFileDownloader';

export default class SalesQuoteService {

    fetchSalesOrderQuotes(req, loadMore) {

        const requestBody = {
            createdDateBetween: req.filter.createdDateBetween,
            accountId: req.filter.accountId,
            selectedPage: loadMore ? req.filter.selectedPage + 1 : req.filter.selectedPage,
            pageSize: req.filter.pageSize,
            query: req.filter.query,
            statusID: req.filter.statusID,
            orderNum: req.filter.orderNum,
            repCode: req.filter.repCode,
            branchNum: req.filter.branchNum,
            sortingKey: req.sort.key,
            sortingAscending: req.sort.ascending
        };

        return axios.post("api/sales/orders/quotes", requestBody);
    }

    fetchSalesOrderCancelReasons() {
        return axios.get("api/sales/orders/quotes/cancel/reasons");
    }

    cancelQuotes(requestBody) {
        return axios.post("api/sales/orders/quotes", requestBody);
    }

    convertQuotesToOrder(requestBody) {
        return axios.post("api/sales/orders/quotes/convert/to/pickingSlip", requestBody);
    }

    convertSelfQuoteToOrder(OrdNum) {
        return axios.get("api/sales/orders/convert/Self/Quote/to/Order?OrdNum=" + OrdNum);
    }

    exportQuotes(req) {

        const requestBody = {
            createdDateBetween: req.filter.createdDateBetween,
            accountId: req.filter.accountId,
            pageSize: req.filter.pageSize,
            query: req.filter.query,
            statusID: req.filter.statusID,
            orderNum: req.filter.orderNum,
            repCode: req.filter.repCode,
            branchNum: req.filter.branchNum
        };

        /*const request = {
            method: 'POST',
            headers: authHeader(),
            responseType: 'blob',
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/sales/orders/quotes/export'
        };
        return axios(request);*/
        return axios.post("api/sales/orders/quotes/export", req);
    }

    fetchLastTenUnsubmittedQuotes() {
        /*const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/sales/orders/quotes/UnsubmittedQuotes'
        };
        return axios(request)*/
        return axios.get("api/sales/orders/quotes/UnsubmittedQuotes");
    }

    fetchQuotesOverview() {
        /*const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/sales/orders/quotes/overview'
        };
        return axios(request);*/
        return axios.get("api/sales/orders/quotes/overview");

    }

    fetchOutstandingsQuotes(reqBody, type) {
        return axios.post("api/sales/orders/quotes/outstandings?Type=" + type, reqBody);
    }

    SendEmailFollowUpsForOutstandingsQuotes(requestBody) {
        return axios.post("api/sales/orders/quotes/outstandings/SendFollowUps", requestBody);
    }

    cancelOutstandingsQuotes(cancelReasonId, ordNums) {
        return axios.post("api/sales/orders/quotes/outstandings/cancel?cancelReasonId=" + cancelReasonId + '&ordNums=' + ordNums);
    }

    cancelSelfOutstandingsQuote(cancelReasonId, ordNum) {
        return axios.post("api/sales/orders/quotes/outstandings/selfcancel/one?cancelReasonId=" + cancelReasonId + '&OrdNum=' + ordNum);
    }

    fetchQuotesStatuses() {
        return axios.get("api/order/status/quotes/get");
    }

    generateDebtorOutstandingQuotesSummaryInExcel(req, type) {
        return axiosFileDownloader.post("api/sales/orders/generateDebtorOutstandingQuotesSummaryInExcel?Type=" + type, req);
    }

    saveQuoteDetails(req) {
        return axios.post("api/sales/orders/quote/details/save", req);
    }
}