import React, { Component } from 'react';
import { concatenateStrings } from "../../../../../../services/CommonService";

export let panelTrackLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "quantity",
        label: "Qty(unit)",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "drop",
        label: "Drop",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "width",
        label: "Width",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "fabric",
        label: "Fabric",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour, data.productLabel.fabricColourOffRange);
        }
    },

    {
        key: "fabricCutWidth",
        label: "Fabric Cut Width",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "fabricCutDrop",
        label: "Fabric Cut Drop",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "opening",
        label: "Opening",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "trackColour",
        label: "Track Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },


    {
        key: "panelWidth",
        label: "Panel Width",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },

    {
        key: "numberOfPanel",
        label: "Number Of Panel",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "stackingDirection",
        label: "Stacking Direction",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "bracket",
        label: "Bracket",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "bottomRail",
        label: "Bottom Rail",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.bottomRail, data.productLabel.bottomRailColour);
        }
    }

];