import { config } from '../../helpers/config'
import axios from 'axios';

export default class ServiceToPostFiles {

    saveNewsLetter(requestBody) {
        let user = JSON.parse(localStorage.getItem('user'));
        const request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + "api/newsLetter/upload",
            data: requestBody,
        };
        return axios(request)
    }

    saveCatalogue(requestBody,type) {
        let url = 'api/catalogue/upload';
        switch (type) {
            case "productBrochure":
                url = 'api/catalogue/upload';
                break;
            case "productOrderForms":
                url = 'api/catalogue/upload/order-forms'
                break;
        }

        let user = JSON.parse(localStorage.getItem('user'));
        const request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + url,
            data: requestBody,
        };
        return axios(request)
    }

    addFAQ(requestBody) {
        let user = JSON.parse(localStorage.getItem('user'));
        const request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + "api/faq/Add",
            data: requestBody,
        };
        return axios(request)
    }

    SaveFabricColors(requestBody) {
        let user = JSON.parse(localStorage.getItem('user'));
        const request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + "api/fabric/color/save",
            data: requestBody,
        };
        return axios(request)
    }
}