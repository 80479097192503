import React, { Component } from 'react';
import { Button,  Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import SearchProductConfigurationStockQtyFormula from '../search/SearchProductConfigurationStockQtyFormula';
import SearchProductConfigurationStockWastageFormula from '../search/SearchProductConfigurationStockWastageFormula';
import CreatableSelect from 'react-select/creatable';
import { measurementUnits } from '../../store/MeasurementUnits';
import classnames from 'classnames';

export default class ProductConfigurationStockDefaultValuesModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            item: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(value, key) {
        let { item } = this.state;

        item[key] = value;
        this.setState({ item });
    }

    handleSubmit() {
        this.props.handleStockDefaultValue(this.state.item);
    }


    render() {
        let { isOpen, toggle } = this.props;
        let { item } = this.state;

        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">Product configuration stock default value</h5>
                </ModalHeader>
                <ModalBody>
                    <div>

                        <Table size={"sm"} striped responsive bordered style={{ minHeight: 400 }}>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: 90 }}>Price</th>
                                    <th style={{ minWidth: 90 }}>Flat Price</th>
                                    <th style={{ minWidth: 90 }}>Qty Multiplier</th>
                                    <th style={{ minWidth: 170 }}>KW Qty formula</th>
                                    <th style={{ minWidth: 100 }}>
                                        <div>
                                            KW Measurement
                                    </div>
                                        <div>
                                            Unit
                                    </div>
                                    </th>
                                    <th style={{ minWidth: 170 }}>SWISH Qty formula</th>
                                    <th style={{ minWidth: 100 }}>
                                        <div>
                                            SWISH Measurement
                                    </div>
                                        <div>
                                            Unit
                                    </div>
                                    </th>
                                    <th style={{ minWidth: 170 }}>Wastage formula</th>
                                    <th style={{ minWidth: 50 }}>Is Deduction
                                    <div>
                                            Applicable
                                    </div>
                                    </th>
                                    <th style={{ minWidth: 50 }}>Is Visible
                                    <div>
                                            In Part-List
                                    </div>
                                    </th>
                                    <th style={{ minWidth: 150 }}>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >

                                        <td>
                                            <Input
                                                type={"number"}
                                                name={"price"}
                                                placeholder={"write price here"}
                                                value={item.price}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input
                                                type={"number"}
                                                name={"flatPrice"}
                                                placeholder={"write flat price here"}
                                                value={item.flatPrice}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)} />
                                        </td>
                                        <td><Input
                                            type={"number"}
                                            name={"quantityMultiplier"}
                                            placeholder={"write qty multiplier"}
                                            value={item.quantityMultiplier}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                        </td>
                                        <td>
                                            <SearchProductConfigurationStockQtyFormula
                                                selectedFormulaID={item.keywayQuantityFormulaId ? item.keywayQuantityFormulaId : ""}
                                                handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "keywayQuantityFormulaId")}
                                            />
                                            
                                        </td>
                                        <td>
                                            <CreatableSelect
                                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                onChange={(value) => this.handleChange(value.value, "keywayMeasurementUnit")}
                                                value={{
                                                    label: item.keywayMeasurementUnit,
                                                    value: item.keywayMeasurementUnit
                                                }}
                                                options={measurementUnits}
                                            />
                                        </td>
                                        
                                        <td>
                                            <SearchProductConfigurationStockQtyFormula
                                                selectedFormulaID={item.swishQuantityFormulaId ? item.swishQuantityFormulaId : ""}
                                                handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "swishQuantityFormulaId")}
                                            />
                                            
                                        </td>
                                        <td>
                                            <CreatableSelect
                                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                onChange={(value) => this.handleChange(value.value, "swishMeasurementUnit")}
                                                value={{
                                                    label: item.swishMeasurementUnit,
                                                    value: item.swishMeasurementUnit
                                                }}
                                                options={measurementUnits}
                                            />
                                        </td>
                                        
                                        <td>
                                            <SearchProductConfigurationStockWastageFormula
                                                selectedFormulaID={item.wastageFormulaId ? item.wastageFormulaId : ""}
                                                handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "wastageFormulaId")}
                                            />


                                        </td>


                                        <td className="text-center">
                                            <Button color={"link"}
                                                onClick={() => this.handleChange(!item.isDeductionApplicable, "isDeductionApplicable")}>
                                                <i className={classnames("fa", {
                                                    "fa-check-square-o": item.isDeductionApplicable,
                                                    "fa-square-o": !item.isDeductionApplicable,
                                                }
                                                )} />
                                            </Button>
                                        </td>
                                        <td className="text-center">
                                            <Button color={"link"}
                                                onClick={() => this.handleChange(!item.isVisibleInPartList, "isVisibleInPartList")}>
                                                <i className={classnames("fa", {
                                                    "fa-check-square-o": item.isVisibleInPartList,
                                                    "fa-square-o": !item.isVisibleInPartList,
                                                }
                                                )} />
                                            </Button>
                                        </td>
                                        <td>
                                            <Input
                                                type={"textarea"}
                                                name={"description"}
                                                placeholder={"write description"}
                                                value={item.description}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                            />
                                        </td>
                                    </tr>
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"}
                            size={"sm"} onClick={this.handleSubmit}>
                             Save
                        </Button>
                        &nbsp;
                        <Button color={"secondary"}
                            size={"sm"} onClick={() => toggle(!isOpen, false)}>
                            <i className="fa fa-times mr-2" /> Close
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}