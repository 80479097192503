import axios from '../axios';

class ProductService {

    static Instance() {
        return new ProductService();
    }

    getProductGroup(id) {
        return axios.get('api/product/group/one/?id=' + id)
    }

    getProductGroups() {
        return axios.get('api/product/group/list')
    }

    updateProductGroup(request) {
        return axios.post('api/product/group/update', request)
    }

    getProduct(id) {
        return axios.get('api/product/one/?id=' + id)
    }

    getProducts() {
        return axios.get('api/product/list');
    }

    getAllProducts(request) {
        return axios.post('api/product/list', request);
    }

    saveProduct(request) {
        return axios.post('api/product/save', request)
    }

    getProductConfigurationData(prodcode) {
        return axios.get('api/product/configuration/stock/search/usages?Prodcode=' + prodcode);
    }

    cleanCache() {
        return axios.get('api/product/configuration/kwy/cache/flush');
    }
    cleanProductConfigurationCache(productId) {
        return axios.get('api/product/configuration/kwy/cache/flush/byProductId?productId=' + productId);
    }
}

export default ProductService.Instance();