import React, {Component} from 'react';
import {Link} from "react-router-dom";

import classnames from 'classnames';
import NumberFormat from "react-number-format";


import {Modal, ModalBody, ModalHeader, Table} from 'reactstrap';


export default class QuoteFollowUpEmailList extends Component {


    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {

        let {selectedQuotes, isOpen} = this.props;

        return (


            <Modal isOpen={isOpen} className="modal-lg" size="lg"
                   scrollable={false}
                   toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    <h6>Follow-up&nbsp;({selectedQuotes.length}&nbsp;selected)</h6>
                </ModalHeader>
                <ModalBody>
                    <Table responsive hover>
                        <thead>

                        <tr>
                            <th>Quote#</th>
                            <th>AccountId</th>
                            <th>Customer</th>
                            <th>Amount</th>
                            <th>Email</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(selectedQuotes) && selectedQuotes.map((record, index) => {

                            return (
                                this.renderQuoteRow(index, record)
                            );
                        })}
                        </tbody>
                    </Table>
                </ModalBody>
                {/*<ModalFooter>
                    <div className={"text-center"}>
                        <Button color="primary" size={"sm"} onClick={this.props.toggleModel}>Close</Button>
                    </div>

                </ModalFooter>*/}
            </Modal>
        );
    }

    renderQuoteRow(index, record) {

        let emailString = "mailto:"
            + record.customerEmail
            + "?subject=Windoware | Quote-" + record.ordNum + " Follow-up" +
            "&body=AccountId-" + record.accountID + "%0A" + record.company + "%0A%0A%0A"
            + "Thanks,%0A"
            + "Team Windoware%0A"
            + "© 2019 Windoware Pty Ltd";


        return <tr key={index} className={classnames({'table-primary': record.selected})}>
            <td>{record.ordNum}</td>
            <td><Link
                className="btn btn-sm btn-primary"
                style={{color: "white"}}
                title={"Click here to see account details"}
                to={"/customer/#"+ record.accountID}
                >{record.accountID}</Link></td>
            <td>{record.company}</td>
            <td className={"table-success"}><NumberFormat value={record.invTotal}
                                                          displayType={'text'}
                                                          decimalScale={2}
                                                          thousandSeparator={true}
                                                          fixedDecimalScale={true}
                                                          prefix={'$'}/></td>
            <td>{(() => {
                if (record.customerEmail) {
                    return ( <span>

                            <a href={emailString}>   <i className="fa fa-envelope-o"
                                                        aria-hidden="true"/>&nbsp;</a>
                                                    </span>
                    );
                }
            })
            ()}
            </td>
        </tr>;
    }
}