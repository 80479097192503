import React, { Component } from 'react';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from "react-router-dom";
import moment from 'moment'; 
import queryString from 'query-string';
import { toast } from 'react-toastify';
import PurchaseOrderPage from '../purchase/PurchaseOrderPage';

export default class PurchaseOrdersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span" href="javascript:void(0)" >Purchase Orders</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardBody>
                        <PurchaseOrderPage showReceived={false} pageType={"stock"} purchaseOrderType={"orderType"} addAndUpdateOrder={"addAndUpdate"} />
                    </CardBody>
                </Card>
            </div>
            );
    }
}