import React, { Component } from 'react';
import { Link as Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";

import ProductionDashboardSummaryCard from '../../components/dashboard/production/ProductionDashboardSummaryCard';


export default class ProductionDashboard extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        // Back Button
        return (
            <div>
                <Row>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1} >
                        <Link className="mr-2 btn btn-secondary btn-sm mb-2" to={"/production/schedule/auto"}>
                            <i className="fa fa-fw fa-chevron-left mr-2" aria-hidden="true"></i>Back
                        </Link>
                    </Col>
                    <Col xl={11} lg={11} md={11} sm={11} xs={11} >
                        <h2 className="text-center"> Production Dashboard </h2>
                    </Col>
                </Row>
                {/*   Main Card with combined individual components */}
                <ProductionDashboardSummaryCard />
            </div>
        );
    }
}