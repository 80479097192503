import React, {Component} from "react";
import {
    Badge, Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Spinner,
    Table
} from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';
import {Link} from "react-router-dom";
import {changeFormatOfDateString, getDateString, handleErrorMessage} from '../../services/CommonService';
import keywayStockService from '../../services/KeywayStockService';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import SearchStock from "../search/SearchStock";

export default class SupplierStockItemsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            stockItems: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        supplierCode: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            hasSupplierWritePrivilege: false,
            isExtendedColumn: false,
            isOpenPurchaseOrderModal: false
        };
        this.getStockItemsList = this.getStockItemsList.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleExtendedColumn = this.toggleExtendedColumn.bind(this);
    }

    componentDidMount() {
        let {stockItems} = this.state;
        stockItems.request.filterRequest.supplierCode = this.props.accountID;
        this.setState({
            stockItems,
            hasSupplierWritePrivilege: this.props.hasSupplierWritePrivilege
        }, () => {
            this.getStockItemsList();
        });
    }

    componentWillReceiveProps(nextProps) {
        let {stockItems} = this.state;
        if (stockItems.request.filterRequest.supplierCode !== nextProps.accountID || this.state.hasSupplierWritePrivilege !== nextProps.hasSupplierWritePrivilege) {
            stockItems.request.filterRequest.supplierCode = nextProps.accountID;
            this.setState({
                stockItems,
                hasSupplierWritePrivilege: nextProps.hasSupplierWritePrivilege
            }, () => {
                this.getStockItemsList();
            });
        }
    }

    getStockItemsList() {
        let {stockItems} = this.state;
        this.setState({isLoading: true});
        keywayStockService.listAllStocks(stockItems.request).then(response => {
            stockItems.response = response.data;
            this.setState({stockItems, isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStore() {
        let {stockItems, isExtendedColumn} = this.state;
        let {filterRequest, sortRequest, pageRequest} = stockItems.request;
        let isAllSelected = stockItems.response.records.every(x => x.isSelected);
        let store = [
            {
                key: "isSelected",
                label: <div className={"text-center"}><a href="javascript:void(0)"
                                                         onClick={() => this.handleChange(!isAllSelected, "isAllSelected")}>
                    <i className={classnames("mt-2", "fa", "fa-lg", {
                            "fa-check-square-o": isAllSelected,
                            "fa-square-o": !isAllSelected,
                        }
                    )}/>
                </a></div>,
                labelHeader: "Basic",
                labelHeaderColSpan: isExtendedColumn ? 17 : 10,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 40,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null,
                render: function (value, storeItem, data, index, ref) {
                    return <div className={"text-center"}>
                        <a href={"javascript:void(0)"} onClick={() => ref.handleChange({
                            itemIndex: index,
                            isSelected: !value
                        }, "isSelected")}>
                            <i className={classnames("fa", "fa-lg", {
                                    "fa-check-square-o": value,
                                    "fa-square-o": !value,
                                }
                            )}/>

                        </a>
                    </div>;
                }
            },
            {
                key: "srNo",
                label: <div>Sr.
                    <div>No</div>
                </div>,
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 30,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, storeItem, data, index, ref) {
                    return <span>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</span>;
                }

            },
            {
                key: "prodCode",
                label: "Prodcode",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: true,
                searchNode: <div><Input type="text" id="prodCode" name="prodCode" value={filterRequest.prodCode}
                                        onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                                        placeholder="Search... "/>
                </div>,
                render: function (value, row, data, index, ref) {
                    return <h5 className={"text-center"}>
                        <Link
                            className="btn btn-primary btn-sm"
                            style={{color: "white"}}
                            title={"Click here to see stock enquiry"}
                            to={"/inventory/stock/enquiry?" + queryString.stringify({prodCode: value})}
                        >{value}</Link>
                    </h5>
                }
            },
            {
                key: "description",
                label: "Description",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: true,
                searchNode: <div><Input type="text" id="description" name="description"
                                        value={filterRequest.description}
                                        onChange={(e) => this.handleChange(e.target.value, "description")}
                                        placeholder="Search..."/></div>,
                render: function (value, row, data, index, ref) {
                    return <small>{value}</small>
                }

            },
            {
                key: "colour",
                label: "Colour",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 65,
                sorterApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "finish",
                label: "Finish",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 65,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "material",
                label: "Material",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 65,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "length",
                label: "Length",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 65,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "width",
                label: "Width",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "height",
                label: "Height",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "dimensionUnitName",
                label: "Dimension Unit",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "weight",
                label: "Weight",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span className={"text-right"}>
                        <NumberFormat value={value}
                                      displayType={'text'}
                                      decimalScale={2}
                                      fixedDecimalScale={false}
                                      thousandSeparator={true}
                                      suffix={row.weightUnitName}
                        />
                    </span>
                }
            },
            {
                key: "eachUnitName",
                label: <div>Each
                    <div>Unit</div>
                </div>,
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 45,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: <div><Input type="text" id="eachUnitName" name="eachUnitName"
                                        value={filterRequest.eachUnitName}
                                        onChange={(e) => this.handleChange(e.target.value, "eachUnitName")}
                                        placeholder="Search..."/>
                </div>,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "moq1price1",
                label: "Cost",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-right align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span className={"text-right"}>
                        <NumberFormat value={value}
                                      displayType={'text'}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={true}
                                      prefix={'$'}/>
                    </span>
                }
            }, {
                key: "moq1",
                label: "MOQ",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-right align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "packQty",
                label: <div>Pack
                    <div>Qty</div>
                </div>,
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-right align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span className={"text-right"}>
                        <NumberFormat value={value}
                                      displayType={'text'}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={true}/>
                    </span>
                }
            },
            {
                key: "leadTime",
                label: <div>Lead
                    <div>Time</div>
                </div>,
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-right align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "minimum",
                label: "Min",
                labelHeader: "Safety",
                labelHeaderColSpan: 2,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 40,
                sorterApplicable: false,
                valueClassName: "text-right align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "maximum",
                label: "Max",
                labelHeader: "",
                labelHeaderColSpan: 0,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 40,
                sorterApplicable: false,
                valueClassName: "text-right align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>
                        {value}
                    </span>
                }
            },
            {
                key: "qoh",
                label: "QOH",
                labelHeader: "",
                labelHeaderColSpan: 1,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "location",
                label: "Location",
                labelHeader: "",
                labelHeaderColSpan: 1,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                showColumn: isExtendedColumn,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "stockGroupCode",
                label: "Stock Group",
                labelHeader: "",
                labelHeaderColSpan: 1,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "status",
                label: "Status",
                labelHeader: "",
                labelHeaderColSpan: 1,
                colSpan: 1,
                rowSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, row, data, index, ref) {
                    return <div>
                        {row.discontinued ? <Badge color={"danger"}>Discontinued</Badge> : null}
                        {row.soldOut ? <Badge color={"danger"}>Sold Out</Badge> : null}
                    </div>
                }
            }
        ];

        return store;
    }

    handleChange(change, key) {
        let {stockItems} = this.state;
        switch (key) {
            case "sortKey":
                if (change.sorterApplicable) {
                    if (stockItems.request.sortRequest.key === change.key) {
                        stockItems.request.sortRequest.direction = !stockItems.request.sortRequest.direction;
                    } else {
                        stockItems.request.sortRequest.key = change.key;
                        stockItems.request.sortRequest.direction = false;
                    }
                    if (!(change === "srNo")) {
                        this.getStockItemsList();
                    }
                }
                break;
            case "pageSize":
                stockItems.request.pageRequest[key] = change;
                stockItems.request.pageRequest.currentPage = 1;
                this.getStockItemsList();
                break;
            case "currentPage":
                stockItems.request.pageRequest[key] = change;
                this.getStockItemsList();
                break;
            case "description":
            case "discontinued":
            case "soldOut":
            case "prodCode":
                stockItems.request.filterRequest[key] = change;
                this.getStockItemsList();
                break;
            case "isAllSelected":
                stockItems.response.records.forEach(function (item) {
                    item.isSelected = change;
                });
                this.setState({stockItems});
                break;
            case "isSelected":
                stockItems.response.records[change.itemIndex].isSelected = change.isSelected;
                this.setState({stockItems});
                break;
        }
    }

    toggleExtendedColumn(isExtendedColumn) {
        this.setState({isExtendedColumn});
    }

    render() {
        let {isLoading, stockItems, isExtendedColumn, isOpenPurchaseOrderModal} = this.state;
        let {pageRequest, sortRequest, filterRequest} = stockItems.request;
        let store = this.getStore();

        return (
            <div>
                <Row>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label>ProdCode</Label>
                        <SearchStock
                            handleSelection={(selectedStock) => this.handleChange(selectedStock ? selectedStock.prodCode : "", "prodCode")}
                            selected={filterRequest.prodCode}
                            defaultProdCode={filterRequest.prodCode}
                            filter={{bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                        />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                        <Label>Description</Label>
                        <div className={"text-right"}>
                            <Input type="text" id="description" name="description"
                                   value={filterRequest.description}
                                   placeholder={"Search..."}
                                   onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                        <Label>Discontinued</Label>
                        <div className={"text-right"}>
                            <Input type="select" id="discontinued" name="discontinued"
                                   value={filterRequest.discontinued}
                                   onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                                <option value={""}>All</option>
                                <option value={true}>Discontinued</option>
                                <option value={false}>Not Discontinued</option>
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                        <Label>Sold Out</Label>
                        <div className={"text-right"}>
                            <Input type="select" id="soldOut" name="soldOut"
                                   value={filterRequest.soldOut}
                                   onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                                <option value={""}>All</option>
                                <option value={true}>Sold Out</option>
                                <option value={false}>Not Sold Out</option>
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        {isLoading
                            ? <Spinner color='primary' size='sm'/>
                            : <p className={"mt-2 mb-0"}>Showing{' '}
                                {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                {' '}of {stockItems.response.totalRecords}
                                {' '}entries
                            </p>
                        }
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"text-right"}>
                        <div className={"mb-1"}>
                            <Button color={"primary"}
                                    size={"sm"}
                                    outline={isExtendedColumn}
                                    title={isExtendedColumn ? 'Show Extended columns' : 'Hide Extended columns'}
                                    onClick={() => this.toggleExtendedColumn(!isExtendedColumn)}>
                                <i className={classnames("fa", "mr-2", {
                                    "fa-eye": !isExtendedColumn,
                                    "fa-eye-slash": isExtendedColumn,
                                })} aria-hidden="true"/>
                                Extended Columns
                            </Button>
                            {isOpenPurchaseOrderModal ?
                                <Button color={"primary"}
                                        size={"sm"}
                                        title={'add purchase order'}
                                        className={"ml-2"}
                                        onClick={() => this.toggleExtendedColumn(!isExtendedColumn)}>
                                    <i className={"fa fa-plus mr-2"} aria-hidden="true"/>
                                    Purchase Order
                                </Button>
                                : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Table striped bordered responsive hover size='sm' className={"mb-1"}>
                            <thead>
                            <tr>
                                {(store || []).filter(x => x.showColumn && x.labelHeaderColSpan > 0).map((item, index) => {
                                    return (
                                        <th key={index}
                                            colSpan={item.labelHeaderColSpan}
                                            className={"text-center"}
                                        >
                                            {item.labelHeader}
                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(store || []).filter(x => (x.showColumn &&  x.colSpan > 0)).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{minWidth: item.minWidth}}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true"/> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>

                            {(stockItems.response.records || []).map((record, i) => {
                                return (
                                    <tr key={i}>
                                        {(store || []).filter(x => x.showColumn).map((storeItem, index) => {
                                            return (
                                                <td key={index} className={storeItem.valueClassName}>
                                                    {storeItem.render(record[storeItem.key], record, stockItems.response.records, i, this)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {
                                ((stockItems.response.records || []).length === 0)
                                    ? <tr>
                                        <td colSpan={(store || []).filter(x => x.showColumn).length}>No orders found...</td>
                                    </tr>
                                    : null
                            }
                            </tbody>
                        </Table>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={isLoading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockItems.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination mb-0'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}