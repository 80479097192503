import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button, TextArea, Collapse, UncontrolledTooltip
} from 'reactstrap';

import CRMService from "../../services/crm/CRMService";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import {convertUtcDateToLocalDate,handleErrorMessage} from '../../services/CommonService';


export default class LeadRemark extends Component {


    constructor(props) {
        super(props);

        let lead = props.lead;
        let activity = props.activity;
        let leadId = 0;
        let activityId = 0;
        if (lead) {
            if (lead.id) {
                leadId = lead.id;
            }
        }

        if (activity) {
            if (activity.id) {
                activityId = activity.id;
            }
        }

        this.state = {
            lead: lead,
            activity: activity,
            remark: {
                id: 0,
                remark: "",
                leadID: leadId,
                leadActivityID: activityId
            }, collapse: false
        };
        this.toggle = this.toggle.bind(this);
        this.handleRemarksChange = this.handleRemarksChange.bind(this);
        this.handleRemarkSubmit = this.handleRemarkSubmit.bind(this);
        this.crmService = new CRMService();

    }


    componentWillReceiveProps(nextProps) {
        let oldLead = this.state.lead;
        let newLead = nextProps.lead;
        let newActivity = nextProps.activity;


        this.setState({lead: newLead, activity: newActivity}, function () {
            // console.log("componentWillReceiveProps remarks, new leadId = " + newLead.id + "new ActivityId=" + newActivity.id);
        });

    }

    toggle() {
        this.setState(state => ({collapse: !state.collapse}));
    }

    handleRemarksChange(e) {
        const {name, value} = e.target;
        let {remark} = this.state;
        remark[name] = value;
        this.setState({remark});
    }

    handleRemarkSubmit(e) {
        e.preventDefault();

        let {remark, lead, activity, collapse} = this.state;
        this.crmService.saveRemark(remark).then(response => {

            let remark = response.data;

            if (remark.leadActivityID) {
                // update activity
                activity.remarks.push(remark);
            } else {
                // update lead
                lead.remarks.push(remark);
            }

            this.setState({
                remark: {
                    id: 0,
                    remark: "",
                    leadID: remark.leadID,
                    leadActivityID: remark.leadActivityID
                },
                lead: lead,
                activity: activity,
                collapse: !collapse
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });

        });

    }


    render() {
        let {lead, activity, remark, collapse} = this.state;
        let remarks = [];
        if (activity) {
            if (activity.remarks && activity.remarks.length > 0) {
                remarks = activity.remarks;
            }
        } else {
            if (lead) {
                if (lead.remarks && lead.remarks.length > 0) {
                    remarks = lead.remarks;
                }
            }
        }


        return (
            <div>

                <ul style={{padding: "unset"}}>
                    {(remarks) && remarks.map((item, index) => {
                        return (
                            <li key={index}>

                                <div className={"lead-remark-list-item"}>
                                    <div className={"lead-remark-list-item-text"}>
                                        <div>
                                            <span className="text-muted">
                                        <span id={"remarkCreatedByUser" + item.id}>
                                            <i className="fa fa-user" aria-hidden="true"/>
                                            &nbsp; {item.createdByUserName}
                                        </span>
                                                <small>&nbsp;
                                                    |&nbsp;{moment(item.createdDate).fromNow()}</small>
                                            </span>
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={"remarkCreatedByUser" + item.id}>
                                                <i className="fa fa-envelope-o "
                                                   aria-hidden="true"/> &nbsp;{item.createdByUserEmail}
                                            </UncontrolledTooltip>
                                        </div>
                                        <span>{item.remark}</span></div>
                                </div>
                            </li>
                        );
                    })}


                    <li>
                        <div className={"mt-2"}>
                            {(() => {
                                if (!collapse) {
                                    return (
                                        <div className="text-left">
                                            <Button className="btn btn-sm"
                                                    color="primary"
                                                    onClick={this.toggle}>

                                                <i className="fa fa-plus"/>
                                                &nbsp;Add remark
                                            </Button>
                                        </div>
                                    )
                                }
                            })()}

                            <Collapse isOpen={this.state.collapse}>
                                <Form>

                                    <FormGroup>
                                        <Row>
                                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                                <div className="text-left">
                                                    <Input className="form-control"
                                                           onChange={this.handleRemarksChange}
                                                           type="textarea"
                                                           name="remark"
                                                           value={remark.remark}
                                                           placeholder="Write remarks here..."/>
                                                </div>
                                            </Col>
                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <div className="text-center mt-2">
                                                    <Button color='primary'
                                                            className="btn btn-sm"
                                                            onClick={this.handleRemarkSubmit}>Save</Button>&nbsp;&nbsp;
                                                    <Button className="btn btn-sm"
                                                            color='secondary'
                                                            onClick={this.toggle}>Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>
                            </Collapse>

                        </div>

                    </li>
                </ul>

            </div>
        )
    }
}