import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import stockService from '../../services/KeywayStockService';
import cloneDeep from 'lodash/cloneDeep';

export default class SearchStockColourKeyway extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultColour: props.defaultColour,
            colour: props.selectedColour,
            options: [],
            selected: props.selectedColour ? {label: props.selectedColour, value: props.selectedColour} : null,
            isLoadingOptions: false,
            filter: {
                bmFlag: props.filter.bmFlag,
                discontinued: props.filter.discontinued,
                soldOut: props.filter.soldOut,
                stockMasterGroupCodes: props.filter.stockMasterGroupCodes,
                stockGroupCodes: props.filter.stockGroupCodes
            }
        }
        ;

        this.loadOptions = this.loadOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.defaultColour || this.state.colour);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultColour !== nextProps.defaultColour) {
            if (nextProps.defaultColour) {
                this.setState({defaultColour: nextProps.defaultColour}, () => {
                    this.fetchOptions(nextProps.defaultColour);
                })
            }
        } else {
            if (this.state.colour !== nextProps.selectedColour) {
                this.setState({
                    colour: nextProps.selectedColour,
                    selected: nextProps.selectedColour
                        ? {label: nextProps.selectedColour, value: nextProps.selectedColour}
                        : null
                }, () => {
                    //load externally selectedItem
                    this.fetchOptions(nextProps.selectedColour);
                })
            } else {
                let {filter} = this.state;
                if (
                    filter.bmFlag !== nextProps.filter.bmFlag
                    || filter.discontinued !== nextProps.filter.discontinued
                    || filter.soldOut !== nextProps.filter.soldOut
                    || JSON.stringify(filter.stockMasterGroupCodes) !== JSON.stringify(nextProps.filter.stockMasterGroupCodes)
                    || JSON.stringify(filter.stockGroupCodes) !== JSON.stringify(nextProps.filter.stockGroupCodes)
                ) {

                    filter.bmFlag = nextProps.filter.bmFlag;
                    filter.discontinued = nextProps.filter.discontinued;
                    filter.soldOut = nextProps.filter.soldOut;
                    filter.stockMasterGroupCodes = nextProps.filter.stockMasterGroupCodes;
                    filter.stockGroupCodes = nextProps.filter.stockGroupCodes;

                    this.setState({filter},()=>{
                        this.fetchOptions("");
                    });
                }
            }
        }
    }


    handleSelect(selected, event) {
        let colour = selected ? selected.value : "";

        switch (event) {
            case "selected":
                this.setState({selected, colour});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, colour});
                if (colour) {
                    this.props.handleSelection(colour);
                } else {
                    this.props.handleSelection(null);
                }
        }

        if (!colour) {
            this.fetchOptions("");
        }
    };


    fetchOptions(inputValue, callback) {
        let options = [];
        let {filter} = this.state;
        let requestBody = {
            searchText: inputValue,
            bmFlag: filter.bmFlag,
            discontinued: filter.discontinued,
            soldOut: filter.soldOut,
            stockMasterGroupCodes: filter.stockMasterGroupCodes,
            stockGroupCodes: filter.stockGroupCodes,
        };

        stockService.getStockColours(requestBody).then(response => {
            let data = response.data;
            for (let i in data) {
                let temp = {
                    label: data[i].colour,
                    value: data[i].colour,
                };
                options.push(cloneDeep(temp));
                if (this.props.defaultColour && ( (!this.state.selected) || (this.props.defaultColour !== this.state.selected.value))) {
                    if (data[i].colour === this.props.defaultColour) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.state.colour) {
                    if (data[i].colour === this.state.colour) {
                        this.handleSelect(temp, "selected");
                    }
                }
            }
            this.setState({options});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
            console.error(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.options);
            }
        }
    };


    render() {
        let {selected, isLoadingOptions, options} = this.state;
        let {disabled} = this.props;
        return (
            <AsyncSelect
                isDisabled={disabled}
                cacheOptions
                isLoading={isLoadingOptions}
                isClearable={true}
                defaultOptions={options}
                loadOptions={this.loadOptions}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                value={selected}
                placeholder={"Search..."}
            />
        )
    }
}