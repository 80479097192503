import React, { Component } from 'react';
import { concatenateStrings } from "../../../../../../services/CommonService";

export let ziptrakExternalLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "quantity",
        label: <div>
            Qty
            <div>(unit)</div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "width",
        label: <div>
            Width
            <div>(mm)</div>
        </div>,
        colSpan: 1,
        minWidth: 20,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "drop",
        label: <div>
            Drop
            <div>(mm)</div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "cutSize",
        label: <div>
            Cut size
            <div>(mm)</div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "operation",
        label: "Operation",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            let result = data.productLabel.isSkinOnly ? "Skin Only" : "";
            result = result + (data.productLabel[this.key] ? " " + data.productLabel[this.key] : "");
            return result
        }
    },
    {
        key: "fabricType",
        label: "Fabric",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.fabricType, data.productLabel.fabricColour, data.productLabel.fabricColourOffRange);
        }
    }, {
        key: "splineColour",
        label: "Spline Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "frameColour",
        label: "Frame Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.frameColour, data.productLabel.framePowdercoatColour);
        }
    },
    {
        key: "hooding",
        label: "Hooding",
        colSpan: 1,
        minWidth: 20,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return concatenateStrings(" ", data.productLabel.hooding, data.productLabel.hoodingColour, data.productLabel.hoodingPowdercoatColour);
        }
    }, {
        key: "motorised",
        label: "Motorised",
        colSpan: 1,
        minWidth: 20,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "centralLock",
        label: "Central Locking",
        colSpan: 1,
        minWidth: 20,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }
];