import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalFooter, ModalBody,
    Button,
    Spinner,
    Input, Label
} from 'reactstrap';
import classnames from 'classnames';
import {getDateObj, getDateString} from '../../services/CommonService'

export default class ProductionScheduleRebuildConfirmModal extends Component {


    render() {
        let {isOpen, toggle, handleChange, handleSubmit, loading, isRebuildToday} = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Production Reschedule
                </ModalHeader>
                <ModalBody>
                    <div>
                        <a href={"javascript:void(0)"}
                           onClick={() => handleChange("isRebuildToday", !isRebuildToday)}>
                            <i className={classnames("fa", "fa-lg", "mr-2", {
                                    "fa-check-square-o": isRebuildToday,
                                    "fa-square-o": !isRebuildToday,
                                }
                            )}/>
                            Include today?
                        </a>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button
                            className={"mr-2"}
                            color={"primary"}
                            onClick={()=> handleSubmit(isRebuildToday)}
                            disabled={loading}
                        >
                            {loading
                                ? <Spinner className={"mr-2"}
                                           style={{color: "white"}}
                                           size={"sm"}/>
                                : <i className="fa fa-calendar-check-o mr-2"
                                     aria-hidden="true"/>
                            }
                            {loading ? "Rescheduling" : "Reschedule"}
                        </Button>
                        <Button color={"secondary"}
                                disabled={loading}
                                onClick={() => toggle(!isOpen)}>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}