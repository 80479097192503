import React, { Component } from 'react';
import { Table, Button, Input } from "reactstrap";
import { warehousePickLabelStore } from "./WarehousePickLabelStore";
import { getSorted } from '../../../../../../../src/services/CommonService';
import classnames from 'classnames';
import { toast } from 'react-toastify';

export default class OrderEnquiryProductionModeOrderWarehousePickItems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items,
            sortRequest: {
                key: "",
                direction: false
            },
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClickAll = this.handleClickAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    handleSort(key) {
        let { sortRequest, items } = this.state;
        if (sortRequest.key === key) {
            sortRequest.direction = !sortRequest.direction;
        }
        else {
            sortRequest.key = key;
            sortRequest.direction = true;
        }
        if (items.length > 0) {
            items = getSorted(items, key, sortRequest.direction);
        }
        this.setState({ items, sortRequest });
    }

    handleClick(item) {
        let { items } = this.state;
        let { handlePickList } = this.props;
        if (item.picked) {
            handlePickList(item, "remove");
        } else {
            handlePickList(item, "add");
        }
        this.setState({
            items: items.map(currentItem => (currentItem.itemNum === item.itemNum) ? { ...currentItem, picked: !currentItem.picked } : currentItem)
        });
    }

    handleClickAll() {
        let { items } = this.state;
        let { pickedList, handlePickList } = this.props;
        if (pickedList.length > 0) {
            if (pickedList.length === items.length) {
                handlePickList([], "removeAll");
                this.setState({
                    items: items.map(item => ({ ...item, picked: false }))
                });
            } else {
                handlePickList(items, "addAll");
                this.setState({
                    items: items.map(item => ({ ...item, picked: true }))
                });
            }
        } else {
            handlePickList(items, "addAll");
            this.setState({
                items: items.map(item => ({ ...item, picked: true }))
            });
        }
    }

    handleChange(value, item) {
        if (value >= 0) {
            if (value > item.qty) {
                toast.info("Pick Qty can't be greater than Qty.");
            } else {
                this.props.handlePickList({ ...item, qtyAudited: value }, "updatePickQty");
            }
        }
    }

    render() {
        let { items, sortRequest } = this.state;
        let { tabId, pickedList, salesOrderJobStatus } = this.props;
        let store = warehousePickLabelStore;
        if (store) {
            return <Table responsive={true} size={"sm"} bordered={true} className={"mb-0"}>
                <thead>
                    <tr>
                        {(salesOrderJobStatus && salesOrderJobStatus >= 45) ?
                            <>
                                <th className={tabId === "allParts" ? "align-middle" : "d-none"}>
                                    <Button color={"link"}
                                        size={"sm"}
                                        onClick={() => this.handleClickAll()}>
                                        {
                                            (pickedList && pickedList.length > 0 && pickedList.length === items.length)
                                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                    aria-hidden="true" />
                                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                        }
                                    </Button>
                                    Pick
                                </th>
                                <th className={tabId === "allParts" ? "align-middle" : "d-none"}>
                                    Pick Qty
                                </th>
                            </>
                            : null}
                        {(store || []).filter(item => (item.showColumn === tabId || item.showColumn === "")).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={(item.sorterApplicable && tabId === "allParts") ? (() => this.handleSort(item.key)) : null}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{ minWidth: item.minWidth }}>
                                    {item.label}
                                    {
                                        (item.sorterApplicable && tabId === "allParts") ?
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== item.key),
                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                            : null
                                    }
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {(items || []).map((item, itemIndex) => {
                        return (<tr key={itemIndex} className="table" bgcolor={tabId === "allParts" ? classnames({
                            "#CCEEBC": (item.qtyAudited === item.qty),
                            "#F1C27B": (item.qtyAudited !== null && item.qtyAudited < item.qty),
                            "WHITE": (item.qtyAudited === null && !item.picked),
                            "#C8C8C8": (item.qtyAudited === null && item.picked)
                        }) : null}>
                            {(salesOrderJobStatus && salesOrderJobStatus >= 45) ?
                                <>
                                    <td className={tabId === "allParts" ? "align-middle" : "d-none"}>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleClick(item)}>
                                            {
                                                item.picked
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                    </td>
                                    <td className={tabId === "allParts" ? "align-middle" : "d-none"}>
                                        <Input
                                            key="pickQty"
                                            type="number"
                                            title="Enter pick qty"
                                            name="pickQty"
                                            disabled={!item.picked}
                                            invalid={pickedList && (pickedList.find(i => i.itemNum === item.itemNum)?.qtyAudited <= 0 || pickedList.find(i => i.itemNum === item.itemNum)?.qtyAudited > item.qty)}
                                            placeholder="Pick Qty"
                                            min={0}
                                            max={item.qty}
                                            value={(pickedList && pickedList.length > 0) ? pickedList.find(i => i.itemNum === item.itemNum)?.qtyAudited : item.qtyAudited}
                                            onChange={(e) => this.handleChange(e.target.value, item)}
                                        />
                                    </td>
                                </>
                                : null}
                            {(store || []).filter(item => (item.showColumn === tabId || item.showColumn === "")).map((column, columnIndex) => {
                                return (
                                    <td key={columnIndex} className={column.valueClassName}>
                                        {column.render(item, this)}
                                    </td>
                                );
                            })}
                        </tr>);
                    })}
                </tbody>
            </Table>;
        }
    }
}