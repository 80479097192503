import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Table, Button, Spinner, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import FileSaver from 'file-saver';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrorMessage } from '../../services/CommonService';
import UserService from '../../services/UserService';
import BulkOrderService from '../../services/BulkOrderService';

export default class ImportBulkOrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordNum: props.location?.state?.ordNum,
            isImport: false,
            downloading: false,
            importResponse: {},
            isDryRun: false
        };
        this.userService = new UserService();
        this.bulkOrderService = new BulkOrderService();
        this.import = this.import.bind(this);
        this.fileHandler = this.fileHandler.bind(this);
        this.downloadFormat = this.downloadFormat.bind(this);
    }

    componentDidMount() {
        let user = this.userService.getLoggedInUser();
        let hasBulkOrderWritePrivilege = this.userService.hasPrivilege(user, "bulk-order-write");
        if (!hasBulkOrderWritePrivilege) {
            this.props.history.push('/');
        }
    }

    fileHandler(event, type) {
        const { target } = event;
        switch (type) {
            case "click":
                target.value = null;
                this.setState({ importResponse: {} });
                break;
            case "change":
                this.setState({ selectedFile: target.files[0] });
                break;
            default:
                break;
        }
    }

    import() {
        const { selectedFile, ordNum, isDryRun } = this.state;
        toast.dismiss();
        if (!selectedFile) {
            toast.error("Please choose a file to import!");
            return;
        }
        if (ordNum) {
            const data = new FormData();
            data.append('file', selectedFile);
            data.append('isDryRun', isDryRun);
            data.append('ordNum', ordNum);
            this.setState({ isImport: true });
            this.bulkOrderService.importBulkOrderData(data).then(response => {
                if (response.status === 200 || response.status === "200") {
                    this.setState({ isImport: false, importResponse: response.data, isDryRun: false });
                    if (response.data?.item2?.hasError) {
                        toast.error("Error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        if (response.data?.item1?.debtorInvoiceOrdNum) {
                            let bulkOrderData = response.data?.item1;
                            this.props.history.push({
                                pathname: '/sales/order/create/product-builder/v1',
                                state: { bulkOrderData }
                            });
                        } else {
                            toast.success("Success!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                    }
                }
            }).catch(error => {
                this.setState({ isImport: false, isDryRun: false });
                toast.error(handleErrorMessage(error));
            });
        } else {
            toast.error("Something went wrong!");
        }
    }

    downloadFormat() {
        this.setState({ downloading: true });
        this.bulkOrderService.generateBulkOrderImportFormat().then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "BULK-ORDER-IMPORT-FORMAT" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    checkFileData() {
        this.setState({ isDryRun: true }, () => this.import());
    }

    render() {
        let { downloading, isImport, importResponse } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/bulk-order')}>Bulk Order</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Import</BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col xl={2} lg={2} md={1} sm={12} xs={12}>
                    </Col>
                    <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                <h6>Import Bulk Order</h6>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Input type="file"
                                                name="file"
                                                id="file"
                                                onClick={(event) => this.fileHandler(event, "click")}
                                                onChange={(event) => this.fileHandler(event, "change")}
                                                aria-label="Upload document" />
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button color="info"
                                            size="sm"
                                            onClick={() => this.checkFileData()}
                                            disabled={isImport}>
                                            {isImport
                                                ? <Spinner size="sm"
                                                    className="mr-2"
                                                    color="white" />
                                                : null
                                            }
                                            Check Data
                                        </Button>
                                        <Button className="ml-1"
                                            color="primary"
                                            size="sm"
                                            onClick={() => this.import()}
                                            disabled={isImport}>
                                            {isImport
                                                ? <Spinner size="sm"
                                                    className="mr-2"
                                                    color="white" />
                                                : <i className="fa fa-upload mr-2" />
                                            }
                                            Upload
                                        </Button>
                                        <Button className="ml-1"
                                            color="primary"
                                            size="sm"
                                            outline={true}
                                            onClick={() => this.downloadFormat()}
                                            disabled={downloading}>
                                            {downloading
                                                ? <Spinner size="sm"
                                                    className="mr-2"
                                                    color="primary" />
                                                : <i className="fa fa-download mr-2" />
                                            }
                                            Download Template
                                        </Button>
                                    </div>
                                    {
                                        importResponse?.item2?.hasError
                                            ? <div>
                                                <hr />
                                                <Table size={"sm"} hover={true} responsive>
                                                    <thead>
                                                        <tr>
                                                            <td>Row</td>
                                                            <td>Column</td>
                                                            <td>Title</td>
                                                            <td>Value</td>
                                                            <td>Validation Message</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {((importResponse?.item2?.rows) || []).map((row, index) => {
                                                            return <tr key={index}
                                                                className={row.hasError ? "table-danger" : "table-success"}>
                                                                <td>{row.rowIndex}</td>
                                                                <td>{row.columnIndex}</td>
                                                                <td>{row.inputTitle}</td>
                                                                <td>{row.inputValue}</td>
                                                                <td>{row.validationMessage}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : null
                                    }
                                </div>
                                <ToastContainer />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}