import React, {Component} from 'react';
import {Card, CardHeader, Col, Row, Table} from "reactstrap";
import {toast, ToastContainer} from 'react-toastify';
import {
    getDistinctValues,
    handleErrorMessage
} from '../../services/CommonService';
import OrderService from '../../services/OrderService';
import {isEmpty, cloneDeep} from 'lodash';
import NumberFormat from "react-number-format";

export default class ReworkOrderReasonWiseDataPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsDataArray: [],
            products: [],
            isLoadingProducts: false,
            isLoading: false,
            totalReworkOrderCount: [],
            totalReworkUnitCount: [],
            totalOrderCount: [],
            totalUnitCount: [],
            totalReworkOrders: 0,
            totalReworkUnits: 0,
            totalOrders: 0,
            totalUnits: 0
        };
        this.getOrderData = this.getOrderData.bind(this);
        this.orderService = new OrderService();
    }
    componentDidMount() {
        this.getReworkOrderTypes();
    }

    getReworkOrderTypes() {
        this.orderService.getReworkOrderTypes().then(response => {
            response.data.push({
                id: 0,
                name: '',
                type: '',
                code: '',
                description: '',
                defaultDiscount: 0
            });
            this.setState({reworkTypes: response.data}, () => {
                this.getOrderData();
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }


    getOrderData() {
        let {productsDataArray} = this.state;
        this.setState({isLoading: true});
        this.orderService.getReworkOrderReasonWiseData().then(response => {
            let data = response.data;
            productsDataArray = this.prepareResultStructure(data);
            productsDataArray = this.fillResultStructureWithData(data, productsDataArray);
            this.setState({productsDataArray, isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
            console.error(error);
        })
    }
    prepareResultStructure(data) {
        let productTemp, productsDataArray = [], reworkReasonTemp;
        let {reworkTypes} = this.state;
        //get products >> rows
        let productNamesMasterData = getDistinctValues(data, 'productName');
        this.getTotal(reworkTypes, data);
        productNamesMasterData.forEach(productName => {
            productTemp = {
                productName: productName,
                reasonDataArray: [],
                reworkOrders: 0,
                reworkUnits: 0,
                orders: 0,
                units: 0
            };
            reworkTypes.forEach(type => {
                reworkReasonTemp = {
                    reworkReasonID: type.id,
                    reworkReasonName: type.name,
                    orders: 0,
                    units: 0
                };
                productTemp.reasonDataArray.push(cloneDeep(reworkReasonTemp));
            });
            productsDataArray.push(cloneDeep(productTemp));
        });
        return productsDataArray;
    }
    fillResultStructureWithData(data, productsDataArray) {
        let itemIndex, reworkOrders = 0, reworkUnits = 0, orders = 0, units = 0;
        productsDataArray.forEach(productsData => {
            productsData.reasonDataArray.forEach(reason => {
                reworkOrders = 0;
                reworkUnits = 0;
                orders = 0;
                units = 0;
                itemIndex = data.findIndex(d => reason.reworkReasonID === d.reworkOrderTypeID && productsData.productName === d.productName);
                if (itemIndex > -1) {
                    reworkOrders += data[itemIndex].totalReworkOrders;
                    reworkUnits += data[itemIndex].totalReworkUnits;
                    orders += data[itemIndex].totalOrders;
                    units += data[itemIndex].totalUnits;
                }
                reason.reworkOrders = reworkOrders;
                reason.reworkUnits = reworkUnits;
                reason.orders = orders;
                reason.units = units;
                productsData.reworkOrders += reason.reworkOrders;
                productsData.reworkUnits += reason.reworkUnits;
                productsData.orders += reason.orders;
                productsData.units += reason.units;
            });
        });
        return productsDataArray;
    }

    getTotal(reworkTypes, data) {
        let {totalReworkOrderCount, totalReworkUnitCount, totalUnitCount, totalOrderCount, totalReworkOrders, totalReworkUnits, totalUnits, totalOrders} = this.state;
        reworkTypes.forEach(reason => {
            let totalReworkOrders = 0, totalReworkUnits = 0, totalUnits = 0, totalOrders = 0;
            data.forEach(product => {
                if (reason.id === product.reworkOrderTypeID) {
                    totalReworkOrders += product.totalReworkOrders;
                    totalReworkUnits += product.totalReworkUnits;
                    totalOrders += product.totalOrders;
                    totalUnits += product.totalUnits;
                }
            });
            totalReworkOrderCount.push(totalReworkOrders);
            totalReworkUnitCount.push(totalReworkUnits);
            totalOrderCount.push(totalOrders);
            totalUnitCount.push(totalUnits);
        });
        totalReworkOrderCount.forEach(caltotal => {
            totalReworkOrders += caltotal;
        });
        totalReworkUnitCount.forEach(caltotal => {
            totalReworkUnits += caltotal;
        });
        totalOrderCount.forEach(caltotal => {
            totalOrders += caltotal;
        });
        totalUnitCount.forEach(caltotal => {
            totalUnits += caltotal;
        });
        this.setState({ totalReworkOrderCount, totalReworkOrders, totalReworkUnits, totalOrders, totalUnits });
    }

    render() {
        let { productsDataArray, isLoading, totalReworkOrderCount, totalReworkUnits, totalReworkOrders, totalOrders, totalUnits } = this.state;
        if (isLoading || isEmpty(productsDataArray)) {
            return null;
        }
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col><h6 className={"mb-0"}>Rework Reasons</h6></Col>
                            <Col className={"text-right"}>In last 12 weeks</Col>
                        </Row>
                    </CardHeader>
                    <div>
                        <Table striped bordered responsive hover size='sm' className={"mb-0"}>
                            <thead>
                            <tr>
                                <th className={"text-center align-middle"}>Product/Reason</th>
                                {(productsDataArray[0].reasonDataArray || []).filter(data => data.reworkReasonID !== 0).map((item, index) => {
                                    return (<th key={index} className={"text-center"}>
                                            {item.reworkReasonName}
                                        </th>
                                    )
                                })}
                                <th className={"text-center"}>Total Rework Orders</th>
                                <th className={"text-center"}>Total Rework Units</th>
                                <th className={"text-center"}>Rework Orders as % of Total</th>
                                <th className={"text-center"}>Rework Units as % of Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(productsDataArray || []).filter(data => data.reworkOrders !== 0).map((productsData, index) =>
                                <tr key={index}>
                                    <th>{productsData.productName}</th>
                                    {(productsData.reasonDataArray || []).filter(data => data.reworkReasonID !== 0).map((reason, index) =>
                                        <td className="text-right"
                                            key={index}>{reason.reworkOrders === 0 ? '-' : reason.reworkOrders}</td>
                                    )}
                                    <th className="text-right">{productsData.reworkOrders === 0 ? '-' : productsData.reworkOrders}</th>
                                    <th className="text-right">{productsData.reworkUnits === 0 ? '-' : productsData.reworkUnits}</th>
                                    <th className="text-right">
                                        <NumberFormat value={(productsData.reworkOrders * 100 / productsData.orders)}
                                                      decimalScale={0}
                                                      displayType={'text'}
                                                      thousandSeparator={true}
                                                      suffix={'%'}/>
                                    </th>
                                    <th className="text-right">
                                        <NumberFormat value={(productsData.reworkUnits * 100 / productsData.units)}
                                                      decimalScale={0}
                                                      displayType={'text'}
                                                      thousandSeparator={true}
                                                      suffix={'%'}/>
                                    </th>
                                </tr>
                            )}
                            <tr>
                                <th>Total</th>
                                {(totalReworkOrderCount || []).slice(0, -1).map((total, index) =>
                                    <th key={index} className="text-right">{total === 0 ? '-' : total}</th>
                                )}
                                <th className="text-right">{totalReworkOrders === 0 ? '-' : totalReworkOrders}</th>
                                <th className="text-right">{totalReworkUnits === 0 ? '-' : totalReworkUnits}</th>
                                <th className="text-right"><NumberFormat value={(totalReworkOrders * 100 / totalOrders)}
                                                                         decimalScale={0}
                                                                         displayType={'text'}
                                                                         thousandSeparator={true}
                                                                         suffix={'%'}/></th>
                                <th className="text-right"><NumberFormat value={(totalReworkUnits * 100 / totalUnits)}
                                                                         decimalScale={0}
                                                                         displayType={'text'}
                                                                         thousandSeparator={true}
                                                                         suffix={'%'}/></th>
                            </tr>
                            <tr>
                                <th>Reason % of Reworks</th>
                                {(totalReworkOrderCount || []).slice(0, -1).map((total, index) =>
                                    <th key={index} className="text-right">{total === 0 ? '-' : <NumberFormat value={(total * 100 / totalReworkOrders)}
                                        decimalScale={0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        suffix={'%'} />}</th>
                                )}
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </Card>
                <ToastContainer/>
            </Col>
        );
    }
}