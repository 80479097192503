import React, { Component } from "react";
import CustomerPreferencePreview from './CustomerPreferencePreview';
import { Badge, Card, CardBody, CardHeader, Col, Row, Spinner, Table, Button } from 'reactstrap';
import CustomerService from '../../services/CustomerService';
import courierService from '../../services/shipment/CourierService';
import { toast, ToastContainer } from 'react-toastify';
import {
    getDateString, handleErrorMessage, getDebtorCategoryColor,
    changeFormatOfDateString
} from "../../services/CommonService";
import CRMService from "../../services/crm/CRMService";

export default class DebtorFreightPreferenceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: props.accountID,
            isLoadingDebtorCourier: false,
            customerTrading_salesInformation: props.customerTrading_salesInformation,
            courier: {},
            debtor: {}
        }
        this.customerService = new CustomerService();
        this.crmService = new CRMService();
        this.getDebtorCourier = this.getDebtorCourier.bind(this);
        this.GetTradingAndSalesInfo = this.GetTradingAndSalesInfo.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({ accountID: nextProps.accountID }, function () {
                this.refresh();
            });
        }
    }

    getDebtorCourier(accountID) {
        this.setState({ isLoadingDebtorCourier: true });
        courierService.getDebtorCourier(accountID).then(response => {
            let courier = response.data;
            this.setState({ courier: courier, isLoadingDebtorCourier: false });
        }).catch(error => {
            this.setState({ isLoadingDebtorCourier: false, courier: {} });
            toast.error(handleErrorMessage(error));
        });
    }

    GetTradingAndSalesInfo() {
        if (this.state.accountID) {
            this.setState({ loadingSalesByMonth: true });
            this.crmService.getCustomerTrading_salesInformation(this.state.accountID).then(response => {
                let data = response.data.item1;
                this.setState({
                    emailStatement: data.statementEmail,
                    printStatement: data.statementPrint
                });
            }).catch(error => {
                this.setState({ loadingSalesByMonth: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    refresh() {
        this.handleDebtorAccountDetails(this.state.accountID);
        this.getDebtorCourier(this.state.accountID);
        this.GetTradingAndSalesInfo();
        this.getDebtorPreference(this.state.accountID);
    }

    handleDebtorAccountDetails(accountID) {
        this.setState({ loadingDebtor: true });
        this.customerService.searchCustomer(accountID).then(response => {
            let debtor = response.data;
            if (debtor) {
                this.setState({ debtor: debtor, loadingDebtor: false });
            }
        }).catch(error => {
            this.setState({ loadingDebtor: false });
            toast.error(handleErrorMessage(error));
        });
    }

    getDebtorPreference(accountID) {
        if (accountID) {
            this.customerService.getDebtorPreference(accountID).then(response => {
                if (response.data) {
                    this.setState({
                        printDispatchLabel: response.data.printDispatchLabel,
                        emailInvoice: response.data.isEmailInvoice,
                        emailDispatchNotice: response.data.isEmailDispatch
                    });
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error));
            });
        }
    }

    render() {
        let { accountID, isLoadingDebtorCourier, debtor, courier, printDispatchLabel,
            printStatement, emailStatement, emailInvoice, emailDispatchNotice } = this.state;
        let { hasDebtorWritePrivilege } = this.props;
        return (
            <div>
                <CustomerPreferencePreview
                    accountID={accountID}
                    isLoading={isLoadingDebtorCourier}
                    printStatement={printStatement}
                    emailStatement={emailStatement}
                    printPackSlip={debtor.printPackSlip}
                    printInvoice={debtor.printInvoice}
                    printDispatchLabel={printDispatchLabel}
                    courier={courier}
                    hasDebtorWritePrivilege={hasDebtorWritePrivilege}
                    emailInvoice={emailInvoice}
                    emailDispatchNotice={emailDispatchNotice}
                    refreshDebtorCourier={() => {
                        this.getDebtorCourier(accountID);
                        this.getDebtorPreference(accountID);
                        this.handleDebtorAccountDetails(accountID);
                    }}
                />
            </div>
        );
    }
}