import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    Spinner
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import StockSupplierService from '../../services/StockSupplierService';
import moment from 'moment';
import SearchWINOCreditor from '../../components/search/SearchCreditorAcccount';
import SearchWINOnlineStock from '../../components/search/SearchWINOnlineStock';
import {handleErrorMessage} from "../../services/CommonService";


export default class StockSupplierPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockSupplier: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        ProdCode: '',
                        Note: '',
                        Company: '',
                        AccountId: '',
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            saving: false,
            supplierID: null,
            searchText: '',
        }
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        let searchParams = queryString.parse(this.props.location.search);
        searchParams.supplierID ? this.getStockSuppliersBySupplierID(searchParams.supplierID)
            : this.getStockSuppliers(this.state.stockSupplier);
    }

    getStockSuppliersBySupplierID(supplierID) {
        let { stockSupplier } = this.state;
        this.setState({ supplierID: supplierID, loading: true });
        StockSupplierService.getAllBySupplierID(supplierID, stockSupplier.request).then(response => {
            stockSupplier.response = response.data;
            this.setState({ stockSupplier, loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    getStockSuppliers(stockSupplier) {
        if (this.state.supplierID !== null) {
            this.getStockSuppliersBySupplierID(this.state.supplierID);
            return;
        }
        this.setState({ loading: true });
        StockSupplierService.getStockSuppliers(stockSupplier.request).then(response => {
            stockSupplier.response = response.data;
            this.setState({ stockSupplier, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStockSupplierHeading({ filterRequest }, supplierID) {
        let stockSupplierHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
            {
                key: "ProdCode",
                label: "Stock Code",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div><SearchWINOnlineStock
                    handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'prodCode')}
                    selectedProdCode={filterRequest.prodCode}
                    defaultProdCode={filterRequest.prodCode} /></div>

            },
            {
                key: "AccountId",
                label: "Account ID",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "table-success text-right",
                labelClassName: "align-middle",
                searchNode: <div>
                    <SearchWINOCreditor
                        handleAccountChange={(selectedSupplier) => this.handleChange(selectedSupplier ? selectedSupplier.accountID:'', 'accountId')}
                        selectedAccountID={filterRequest.accountId}
                        defaultAccountID={filterRequest.accountId} />
                </div>
            },
            //{
            //    key: "Company",
            //    label: "Company",
            //    type: "text",
            //    colSpan: 1,
            //    minWidth: 100,
            //    sorterApplicable: false,
            //    valueClassName: "",
            //    labelClassName: " align-middle",
            //    searchNode: <div><Input type="text" id="Company" name="Company"
            //        value={filterRequest.Company}
            //        onChange={(e) => this.handleChange(e.target.value, "Company")}
            //        placeholder="Search..." /></div>
            //},
            {
                key: "lastPurchasedDate",
                label: "Last Purchased",
                type: "text",
                colSpan: 1,
                minWidth: 180,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
            },
            {
                key: "preferenceRating",
                label: "Rating",
                type: "text",
                colSpan: 1,
                minWidth: 170,
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
            },
            {
                key: "leadTimeInDays",
                label: "Lead Time",
                type: "text",
                colSpan: 1,
                minWidth: 160,
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
            },
            {
                key: "isPreferred",
                label: "Preferred",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "table-success text-right",
                labelClassName: "align-middle",
            },

            {
                key: "moq1",
                label: "MOQ",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
            },

            //{
            //    key: "Note",
            //    label: "Note",
            //    type: "text",
            //    colSpan: 1,
            //    minWidth: 100,
            //    sorterApplicable: false,
            //    valueClassName: "table-success text-right",
            //    labelClassName: "align-middle",
            //    searchNode: <div>
            //        <InputGroup>
            //            <Input type={"text"}
            //                placeholder={"Search..."}
            //                name={"Note"}
            //                value={filterRequest.Note || ''}
            //                onChange={(e) => this.handleChange(e.target.value, "Note")} />
            //        </InputGroup>
            //    </div>
            //},

            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 2,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                    to={{
                        pathname: '/keyway-inventory/stock-supplier/manage',
                        state: { Id: '', creditorID: supplierID }
                    }}><i className="fa fa-plus" />&nbsp;
                    Add </Link></div>

            }];

        return stockSupplierHeading;
    }

    handleDelete(ID) {
        if (window.confirm('Are you sure you want to delete it ?')) {
            StockSupplierService.deleteStockSupplier(ID).then(response => {
                this.setState({ saving: false });
                toast.success("Stock supplier deleted successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.getStockSuppliers(this.state.stockSupplier);
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
        }

    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.supplier.accountId.toLowerCase().includes(searchText);

            if (!flag && item.stock.prodCode) {
                flag = item.stock.prodCode.toLowerCase().includes(searchText)
            }

            if (!flag && item.note) {
                flag = item.note.toLowerCase().includes(searchText)
            }

            if (!flag && item.supplier.company) {
                flag = item.supplier.company.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let { stockSupplier } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (stockSupplier.request.sortRequest.key === change) {
                    stockSupplier.request.sortRequest.direction = !stockSupplier.request.sortRequest.direction;
                } else {
                    stockSupplier.request.sortRequest.key = change;
                    stockSupplier.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockSuppliers(stockSupplier);
                }
                break;
            case "pageSize":
                stockSupplier.request.pageRequest[key] = change;
                stockSupplier.request.pageRequest.currentPage = 1;
                this.getStockSuppliers(stockSupplier);
                break;
            case "currentPage":
                stockSupplier.request.pageRequest[key] = change;
                this.getStockSuppliers(stockSupplier);
                break;


            default:
                stockSupplier.request.filterRequest[key] = change;
                stockSupplier.request.pageRequest.currentPage = 1;
                this.getStockSuppliers(stockSupplier);
                this.setState({ stockSupplier });
        }

    }

    handleStockSupplierChange(change, key) {
        let { stockSupplier } = this.state;
        if (change) {
            stockSupplier.request.filterRequest[key] = change.accountID;
        }
        else {
            stockSupplier.request.filterRequest[key] = null;
        }
        this.getStockSuppliers(stockSupplier);
        this.setState({ stockSupplier });
    }

    render() {
        let { stockSupplier, loading, searchText, supplierID } = this.state;
        let { pageRequest, sortRequest } = stockSupplier.request;
        let stockSupplierHeading = this.getStockSupplierHeading(stockSupplier.request, supplierID);
        return (
            <div>
                {supplierID ?
                    <Breadcrumb tag="nav" listTag="div">
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/inventory/keyway/creditors')}>Suppliers</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">Stock Suppliers</BreadcrumbItem>
                    </Breadcrumb>
                    : <Breadcrumb tag="nav" listTag="div">
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">Stock Suppliers</BreadcrumbItem>
                    </Breadcrumb>
                }

                <Card>
                    <CardHeader><h5>Stock Suppliers</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockSupplier.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(stockSupplierHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(stockSupplierHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(stockSupplier.response.records || []).filter((stockSupplier) => this.searchFunction(stockSupplier, searchText)).map((stockSupplier, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td><Link
                                                className="btn btn-sm btn-primary"
                                                style={{ color: "white" }}
                                                title={"Click here to see stock details"}
                                                to={"/keyway-inventory/stock/enquiry?prodCode=" + stockSupplier.stock.prodCode}>
                                                {stockSupplier.stock.prodCode}</Link>
                                            </td>
                                            <td>{stockSupplier.supplier.accountId}</td>
                                            {/*<td className={"text-left"}>{stockSupplier.supplier.company}</td>*/}
                                            <td>{stockSupplier.lastPurchasedDate !== null ? moment(stockSupplier.lastPurchasedDate).format('DD MMM YYYY') : ""}</td>
                                            <td className={"text-right"}>{stockSupplier.preferenceRating}</td>
                                            <td>{stockSupplier.leadTimeInDays > 1 ?
                                                <span>{stockSupplier.leadTimeInDays}&nbsp;days</span> : <span>{stockSupplier.leadTimeInDays}&nbsp;day</span>}</td>
                                            <td className={"text-center"}>
                                                <i className={classnames("ml-2", "fa", "fa-lg", {
                                                    "fa-check-square-o": stockSupplier.isPreferred,
                                                    "fa-square-o": !stockSupplier.isPreferred
                                                })} />
                                            </td>
                                            <td className={"text-right"}>{stockSupplier.moq1}</td>
                                            {/*<td className={"text-left"}>{stockSupplier.note}</td>*/}
                                            <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                to={"/keyway-inventory/stock-supplier/manage?" + queryString.stringify({ id: stockSupplier.id, creditorID: supplierID })}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                            Edit</Link>
                                                <Button className="btn btn-sm ml-2" color="danger" onClick={() => { this.handleDelete(stockSupplier.id) }}>
                                                    {this.state.saving ? <Spinner size="sm"
                                                        style={{ color: "white" }} /> : <i className="fa fa-trash"
                                                            aria-hidden="true" />}
                                                &nbsp;&nbsp;Delete</Button>

                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockSupplier.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer />

            </div>
        )
    }

}