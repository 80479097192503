import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Card,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ListGroup,
    ListGroupItem,
    Row,
    Spinner,
    Table,
    Tooltip,
    UncontrolledDropdown,
} from 'reactstrap';
import Pagination from "react-js-pagination";
import {toast, ToastContainer} from 'react-toastify';
import {
    findIndex,
    getFullName,
    getLocalDateStringFromUtcDateString,
    removeDuplicates,

    getDateString,handleErrorMessage
} from '../../services/CommonService';
import userInboxService from "../../services/user/UserInboxService";
import UserService from "../../services/UserService";
import UserInboxMessageModal from "../../components/user/UserInboxMessageModal";
import ComposeUserInboxMessageModal from "../../components/user/ComposeUserInboxMessageModal";
import ViewUserInboxMessageModal from "../../components/user/ViewUserInboxMessageModal";
import {EventBus} from "../../components/events/event"
import classnames from "classnames";
import cloneDeep from 'lodash/cloneDeep';

const tabs = [
    {
        key: "default",
        label: "Inbox",
        icon: "fa-inbox"
    },
    {
        key: "important",
        label: "Starred",
        icon: "fa-star"
    },
    {
        key: "sent",
        label: "Sent",
        icon: "fa-paper-plane"
    },
    {
        key: "archive",
        label: "Archived",
        icon: "fa-archive"
    },
    {
        key: "all",
        label: "All",
        icon: "fa-envelope"
    },
    {
        key: "trash",
        label: "Trash",
        icon: "fa-trash"
    }
];


const newMessage = {
    toEmail: "",
    fromEmail: "",
    ccEmail: "",
    subject: "",
    body: "",
    shortMessage: "",
    isRead: false,
    isActive: true,
    isArchived: false,
    isImportant: false,
    isTemplate: false,
    isValidated: true

};

export default class UserInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[0],
            currentUser: {},
            messages: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {},
                },
                response: {
                    records: [],
                    totalRecords: 0
                }
            },
            loading: {
                messages: false,
                markImportant: false,
                markArchive: false,
                markTrash: false,
                sendMessage: false,
                markArchiveButton: false,
                markTrashButton: false,
                markReadButton: false,
                markImportantButton: false,
                markUnReadButton: false,
                markNotImportantButton: false,
            },
            searchText: "",
            isOpenMessageModal: false,
            isOpenComposeMessageModal: false,
            isOpenViewMessageModal: false,
            workingMessage: {},
            usersByEmail: {},
            checkedMessages: [],
            showButtons: false,
            tooltip: {
                iconRead: false,
                iconUnRead: false,
                iconImportant: false,
                iconNotImportant: false,
                iconArchive: false,
                iconTrash: false,
            }
        };

        this.refresh = this.refresh.bind(this);
        this.getMessages = this.getMessages.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.markMessage = this.markMessage.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.toggleComposeMessageModal = this.toggleComposeMessageModal.bind(this);
        this.toggleViewMessageModal = this.toggleViewMessageModal.bind(this);
        this.findUsers = this.findUsers.bind(this);

        this.userService = new UserService();
    }

    componentDidMount() {
        this.refresh(false);
    }

    refresh(silent) {
        let user = this.userService.getLoggedInUser();
        this.setState({currentUser: user});
        this.getMessages(silent, this.state.activeTab, this.state.messages, this.state.loading);
    }

    toggleMessageModal(change) {
        this.setState({isOpenMessageModal: change});
    }

    toggleComposeMessageModal(change) {
        this.setState({isOpenComposeMessageModal: change});
    }

    toggleViewMessageModal(change) {
        this.setState({isOpenViewMessageModal: change});
    }

    toggleTab(tab) {
        let {messages, activeTab, loading} = this.state;
        if (activeTab.key !== tab.key) {
            activeTab = tab;
            messages.request.pageRequest.currentPage = 1;
            this.getMessages(false, activeTab, messages, loading);
        }

    }

    getMessages(silent, activeTab, messages, loading) {
        let {usersByEmail, currentUser} = this.state;
        if (!silent) {
            loading.messages = true;
            this.setState({loading, activeTab, messages});
        } else {
            this.setState({activeTab, messages});
        }

        let tooltip = {
            iconRead: false,
            iconUnRead: false,
            iconImportant: false,
            iconNotImportant: false,
            iconArchive: false,
            iconTrash: false,
        }

        userInboxService.getUserInbox(activeTab.key, messages.request).then(response => {
            let emails = [];
            if (response.data) {
                messages.response = response.data;
                for (let i = 0; i < messages.response.records.length; i++) {
                    messages.response.records[i].dateString = getDateString(messages.response.records[i].createdOn, 'DD MMM YYYY');
                    messages.response.records[i].timeString = getDateString(messages.response.records[i].createdOn, 'hh:mm A');
                    messages.response.records[i].receipentDisplayName = this.getReceipentDisplayName(messages.response.records[i], usersByEmail, currentUser);
                    emails.push(messages.response.records[i].fromEmail);
                    emails.push(messages.response.records[i].toEmail);
                    emails.push(messages.response.records[i].ccEmail);
                }
            }
            loading.messages = false;
            this.setState({messages, loading, checkedMessages: [], showButtons: false, tooltip});
            this.findUsers(emails, messages);
        }).catch(error => {
            this.setState({loading, tooltip});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    findUsers(emails, messages) {
        let {usersByEmail, currentUser} = this.state;
        emails = removeDuplicates(emails);
        this.userService.findUsers([], [], emails).then(response => {
            if (response.data) {
                for (let i = 0; i < response.data.length; i++) {
                    response.data[i].fullName = getFullName(response.data[i].firstName, response.data[i].lastName);
                    usersByEmail[response.data[i].emailAddress] = response.data[i];
                }
                for (let i = 0; i < messages.response.records.length; i++) {
                    messages.response.records[i].receipentDisplayName = this.getReceipentDisplayName(messages.response.records[i], usersByEmail, currentUser);
                    messages.response.records[i].from = usersByEmail[messages.response.records[i].fromEmail];
                    messages.response.records[i].to = usersByEmail[messages.response.records[i].toEmail];
                    messages.response.records[i].cc = usersByEmail[messages.response.records[i].ccEmail];
                }
                this.setState({usersByEmail, messages});
            }
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    handleItemAction(operation, message) {
        let {workingMessage, currentUser} = this.state;

        switch (operation) {
            case "new":
                workingMessage = cloneDeep(message);
                if (!workingMessage.fromEmail) {
                    workingMessage.fromEmail = currentUser.emailAddress;
                }
                this.setState({workingMessage, isOpenComposeMessageModal: true});
                break;
            case "view":
                workingMessage = cloneDeep(message);
                if (!workingMessage.isRead && workingMessage.toEmail === currentUser.emailAddress) {
                    this.markMessage(message, "read");
                }
                this.setState({workingMessage, isOpenViewMessageModal: true});
                break;
            case "important":
                //workingMessage = cloneDeep(message);
                this.markMessage(message, "important");
                break;
            case "trash":
                //workingMessage = cloneDeep(message);
                this.markMessage(message, "trash");
                break;
            case "archive":
                //workingMessage = cloneDeep(message);
                this.markMessage(message, "archive");
                break;
        }
    }


    handleChange(change, key) {
        let {workingMessage, messages, activeTab, loading} = this.state;
        switch (key) {
            case "fromEmail":
                workingMessage[key] = change;
                this.setState({workingMessage});
                break;
            case "toEmail":
                workingMessage[key] = change;
                this.setState({workingMessage});
                break;
            case "ccEmail":
                workingMessage[key] = change;
                this.setState({workingMessage});
                break;
            case "subject":
                workingMessage[key] = change;
                this.setState({workingMessage});
                break;
            case "shortMessage":
                workingMessage[key] = change;
                this.setState({workingMessage});
                break;
            case "body":
                workingMessage[key] = change;
                this.setState({workingMessage});
                break;
            case "isValidated":
                if (!change) {
                    toast.info("Please match the requested inputs", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }

                workingMessage[key] = change;
                this.setState({workingMessage});
                break;
            case "searchText":
                this.setState({searchText: change});
                break;
            case  "sortKey":
                if (messages.request.sortRequest.key === change) {
                    messages.request.sortRequest.direction = !messages.request.sortRequest.direction;
                } else {
                    messages.request.sortRequest.key = change;
                    messages.request.sortRequest.direction = false;
                }
                this.getMessages(false, activeTab, messages, loading);
                break;
            case  "pageSize":
                messages.request.pageRequest[key] = change;
                this.getMessages(false, activeTab, messages, loading);
                break;
            case  "currentPage":
                messages.request.pageRequest[key] = change;
                this.getMessages(false, activeTab, messages, loading);
                break;

        }
    }

    markMessage(item, action) {
        if (item.id) {
            let {messages} = this.state;
            let loading = cloneDeep(this.state.loading);
            loading = userInboxService.handleMessageMarkLoadingChange(action, loading, item, true);

            this.setState({loading});
            userInboxService.markMessage(item.id, action).then(response => {
                loading = userInboxService.handleMessageMarkLoadingChange(action, loading, item, false);

                let index = findIndex(messages.response.records, 'id', response.data.id);

                switch (action) {
                    case "read":
                        if (index > -1) {
                            messages.response.records[index].isRead = response.data.isRead;
                        }
                        this.setState({loading, messages});
                        EventBus.publish('update-notification', {});
                        break;
                    case "important":
                        if (index > -1) {
                            messages.response.records[index].isImportant = response.data.isImportant;
                        }
                        this.setState({loading, messages});
                        break;
                    default:
                        this.getMessages(true, this.state.activeTab, messages, loading);
                        break;
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                loading = userInboxService.handleMessageMarkLoadingChange(action, loading, item, false);
                this.setState({loading});
            });
        }
    }

    handleSubmit(message) {
        let {loading, messages, currentUser} = this.state;
        loading.sendMessage = true;
        this.setState({loading});
        userInboxService.saveMessage(message).then(response => {

            toast.success("Sent!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            loading.sendMessage = false;
            this.setState({messages, loading, isOpenComposeMessageModal: false, workingMessage: {}});
            this.getMessages(true, this.state.activeTab, messages, loading);
            if (message.toEmail === currentUser.emailAddress || message.ccEmail === currentUser.emailAddress) {
                EventBus.publish('update-notification', {});
            }
        }).catch(error => {
            loading.sendMessage = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (flag && item.fromEmail && searchText) {
            flag = item.fromEmail.includes(searchText)
        }
        if (!flag && item.toEmail && searchText) {
            flag = item.toEmail.includes(searchText)
        }
        if (!flag && item.ccEmail && searchText) {
            flag = item.ccEmail.toLowerCase().includes(searchText.toLowerCase())
        }
        if (!flag && item.subject && searchText) {
            flag = item.subject.toLowerCase().includes(searchText.toLowerCase())
        }
        if (!flag && item.shortMessage && searchText) {
            flag = item.shortMessage.toLowerCase().includes(searchText.toLowerCase())
        }
        if (!flag && item.dateString && searchText) {
            flag = item.dateString.toLowerCase().includes(searchText)
        }
        if (!flag && item.timeString && searchText) {
            flag = item.timeString.toLowerCase().includes(searchText)
        }

        return flag;
    }

    getReceipentDisplayName(message, usersByEmail, currentUser) {

        let displayName = "";
        if (currentUser.emailAddress === message.fromEmail) {
            if (message.toEmail) {
                displayName = message.toEmail;
                if (usersByEmail && usersByEmail[message.toEmail] && usersByEmail[message.toEmail].firstName) {
                    displayName = usersByEmail[message.toEmail].firstName;
                }
            }
        } else {
            if (message.fromEmail) {
                displayName = message.fromEmail;
                if (usersByEmail && usersByEmail[message.fromEmail] && usersByEmail[message.fromEmail].firstName) {
                    displayName = usersByEmail[message.fromEmail].firstName;
                }
            }
        }
        return displayName;
    }

    handleChecked(item, e) {
        let {checkedMessages, messages, showButtons} = this.state;
        let index = -1;

        if (e.target.checked) {
            checkedMessages.push(parseInt(e.target.value));
        } else {
            index = checkedMessages.indexOf(parseInt(e.target.value));
            checkedMessages.splice(index, 1);
        }

        messages.response.records.forEach(message => {
            if (message === item) {
                message.checked = e.target.checked;
            }
        });

        showButtons =(checkedMessages.length > 0) ? true: false;
        this.setState({checkedMessages, messages, showButtons});
    }

    handleAllChecked = (event) => {
        let {messages, showButtons, checkedMessages} = this.state;
        messages.response.records.forEach(message => {
            message.checked = event.target.checked;
            if (message.checked) {
                checkedMessages.push(message.id);
            }else {
                checkedMessages.splice(checkedMessages.indexOf(parseInt(message.value)), 1);
            }
        });

        showButtons =(checkedMessages.length > 0) ? true: false;
        this.setState({checkedMessages, messages, showButtons});
    };

    markBulkMessages(action) {
        let {checkedMessages, messages, activeTab, loading} = this.state;

        let tooltip = {
            iconRead: false,
            iconUnRead: false,
            iconImportant: false,
            iconNotImportant: false,
            iconArchive: false,
            iconTrash: false,
        };

        loading = userInboxService.handleMessagesMarkLoadingChange(action, loading, true);

        this.setState({loading});

        let req = {
            Ids: checkedMessages,
        };

        userInboxService.markMessages(req, action).then(response => {
            loading = userInboxService.handleMessagesMarkLoadingChange(action, loading, false);

            this.setState({loading, tooltip});
            this.getMessages(false, activeTab, messages, loading);

            let message = "success";
            switch (action) {
                case 'trash':
                    message = "Conversations moved to trash";
                    break;
                case 'archive':
                    message = "Conversations archived";
                    break;
                case 'read':
                    message = "Conversations marked as read";
                    break;
                case 'unread':
                    message = "Conversations marked as unread";
                    break;
                case 'important':
                    message = "Conversations marked as important";
                    break;
                case 'notimportant':
                    message = "Conversation marked as not important";
                    break;
            }
            toast.success(message, {
                position: toast.POSITION.BOTTOM_CENTER
            });

            EventBus.publish('update-notification', {});

        }).catch(error => {
            loading = userInboxService.handleMessagesMarkLoadingChange(action, loading, false);

            this.setState({loading, tooltip});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggle = targetName => {
        if (this.state.showButtons) {
            let {tooltip} = this.state;
            tooltip[targetName] = !tooltip[targetName];
            this.setState({tooltip});
        }
    };


    render() {
        let {messages, tooltip, currentUser, loading, workingMessage, isOpenMessageModal, isOpenComposeMessageModal, isOpenViewMessageModal, activeTab, searchText, showButtons} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Inbox</BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <Row>
                        <Col xl={2} lg={3} md={4} sm={5} xs={12}>
                            <Card>
                                <Button color={"primary"}
                                        block={true}
                                        outline={true}
                                        onClick={() => this.handleItemAction("new", newMessage)}>
                                    New message
                                </Button>
                            </Card>
                            <ListGroup className={"mt-2"}>
                                {(tabs || []).map((tab, index) => {
                                    return <ListGroupItem key={index} active={tab.key === activeTab.key} tag="button"
                                                          action onClick={() => this.toggleTab(tab)}>
                                        <i className={classnames("fa", tab.icon, "fa-fw", "mr-3")} aria-hidden="true"/>
                                        {tab.label}
                                    </ListGroupItem>
                                })}

                            </ListGroup>
                        </Col>
                        <Col xl={10} lg={9} md={8} sm={7} xs={12}>
                            {
                                loading.messages
                                    ? <Spinner color={"primary"}/>
                                    : <div>
                                        <div>
                                            <div>
                                                <InputGroup className={"mb-2"}>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fa fa-search"
                                                               aria-hidden="true"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type={"text"}
                                                        name={"searchText"}
                                                        placeholder={"Search..."}
                                                        value={searchText}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                    />

                                                </InputGroup>
                                            </div>


                                            <br/>
                                            {(messages.response.records && messages.response.records.length > 0)


                                                ? <div>
                                                    <Row className={"mb-1"}>
                                                        <Col xl={1} lg={1} md={6} sm={12} xs={12}>
                                                            <Input type="checkbox" className="ml-3 inbox-checkboxes"
                                                                   title="Select" onChange={this.handleAllChecked}
                                                                   style={{cursor: 'pointer'}}/>

                                                        </Col>

                                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                            {showButtons ?
                                                                <ButtonGroup>

                                                                    <Button color={"link"} size={"sm"}
                                                                            disabled={loading.markReadButton}
                                                                            onClick={this.markBulkMessages.bind(this, 'read')}>
                                                                        <i id="iconRead"
                                                                           className={classnames("fa fa-fw fa-lg", {
                                                                                   "fa-circle-o-notch fa-spin": loading.markReadButton,
                                                                                   "fa-envelope-open": !loading.markReadButton,
                                                                               }
                                                                           )} aria-hidden="true"/>

                                                                        <Tooltip placement="bottom"
                                                                                 isOpen={tooltip.iconRead}
                                                                                 target="iconRead"
                                                                                 toggle={() => this.toggle(`iconRead`)}>
                                                                            Mark as read
                                                                        </Tooltip>
                                                                    </Button>


                                                                    <Button color={"link"} size={"sm"}
                                                                            disabled={loading.markUnReadButton}
                                                                            onClick={this.markBulkMessages.bind(this, 'unread')}>
                                                                        <i id="iconUnRead"
                                                                           className={classnames("fa fa-fw fa-lg", {
                                                                                   "fa-circle-o-notch fa-spin": loading.markUnReadButton,
                                                                                   "fa-envelope": !loading.markUnReadButton,
                                                                               }
                                                                           )} aria-hidden="true"/>
                                                                        <Tooltip placement="bottom"
                                                                                 isOpen={tooltip.iconUnRead}
                                                                                 target="iconUnRead"
                                                                                 toggle={() => this.toggle(`iconUnRead`)}>
                                                                            Mark as unread
                                                                        </Tooltip>
                                                                    </Button>


                                                                    <Button color={"link"} size={"sm"}
                                                                            disabled={loading.markImportantButton}
                                                                            onClick={this.markBulkMessages.bind(this, 'important')}>
                                                                        <i id="iconImportant"
                                                                           className={classnames("fa fa-fw fa-lg", {
                                                                                   "fa-circle-o-notch fa-spin": loading.markImportantButton,
                                                                                   "fa-star star-selected": !loading.markImportantButton,
                                                                               }
                                                                           )} aria-hidden="true"/>
                                                                        <Tooltip placement="bottom"
                                                                                 isOpen={tooltip.iconImportant}
                                                                                 target="iconImportant"
                                                                                 toggle={() => this.toggle(`iconImportant`)}>
                                                                            Mark as important
                                                                        </Tooltip>
                                                                    </Button>


                                                                    <Button color={"link"} size={"sm"}
                                                                            disabled={loading.markNotImportantButton}
                                                                            onClick={this.markBulkMessages.bind(this, 'notimportant')}>
                                                                        <i id="iconNotImportant"
                                                                           className={classnames("fa fa-fw fa-lg", {
                                                                                   "fa-circle-o-notch fa-spin": loading.markNotImportantButton,
                                                                                   "fa-star-o": !loading.markNotImportantButton,
                                                                               }
                                                                           )} aria-hidden="true"/>
                                                                        <Tooltip placement="bottom"
                                                                                 isOpen={tooltip.iconNotImportant}
                                                                                 target="iconNotImportant"
                                                                                 toggle={() => this.toggle(`iconNotImportant`)}>
                                                                            Mark as not important
                                                                        </Tooltip>
                                                                    </Button>


                                                                    <Button color={"link"} size={"sm"}
                                                                            disabled={loading.markArchiveButton}
                                                                            onClick={this.markBulkMessages.bind(this, 'archive')}>
                                                                        <i id="iconArchive"
                                                                           className={classnames("fa fa-fw fa-lg", {
                                                                                   "fa-circle-o-notch fa-spin": loading.markArchiveButton,
                                                                                   "fa-archive": !loading.markArchiveButton,
                                                                               }
                                                                           )} aria-hidden="true"/>
                                                                        <Tooltip placement="bottom"
                                                                                 isOpen={tooltip.iconArchive}
                                                                                 target="iconArchive"
                                                                                 toggle={() => this.toggle(`iconArchive`)}>
                                                                            Archive
                                                                        </Tooltip>
                                                                    </Button>


                                                                    <Button color={"link"} size={"sm"}
                                                                            disabled={loading.markTrashButton}
                                                                            onClick={this.markBulkMessages.bind(this, 'trash')}>
                                                                        <i id="iconTrash"
                                                                           className={classnames("fa fa-fw fa-lg text-danger", {
                                                                                   "fa-circle-o-notch fa-spin": loading.markTrashButton,
                                                                                   "fa-trash-o": !loading.markTrashButton,
                                                                               }
                                                                           )} aria-hidden="true"/>
                                                                        <Tooltip placement="bottom"
                                                                                 isOpen={tooltip.iconTrash}
                                                                                 target="iconTrash"
                                                                                 toggle={() => this.toggle(`iconTrash`)}>
                                                                            Delete
                                                                        </Tooltip>
                                                                    </Button>
                                                                </ButtonGroup>
                                                                : null
                                                            }
                                                        </Col>

                                                        <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                                                            <div className={"text-right mt-1"}>
                                                                {loading.messages ? <p>Loading...</p> :
                                                                    <span className="align-middle">
                                                                        {' '}{((messages.request.pageRequest.currentPage - 1) * messages.request.pageRequest.pageSize) + 1}
                                                                        {' '}
                                                                        - {((messages.request.pageRequest.currentPage) * messages.request.pageRequest.pageSize)}
                                                                        {' '}of {messages.response.totalRecords}
                                                                        {' '}messages</span>
                                                                }
                                                            </div>
                                                        </Col>

                                                    </Row>

                                                    <Card>
                                                        <div>
                                                            <Table responsive hover className={"mb-0"}>
                                                                <tbody>

                                                                {(messages.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((item, index) =>
                                                                    <tr key={index}
                                                                        className={classnames("hand-cursor", {
                                                                            "bd-callout bd-callout-primary": !item.isRead && item.toEmail === currentUser.emailAddress,
                                                                            "bd-callout bd-callout-warning": !item.isRead && item.toEmail !== currentUser.emailAddress,
                                                                        })}
                                                                    >
                                                                        <td className={"text-center align-middle hoverableItem"}
                                                                            style={{width: 55}}>
                                                                            <Input type="checkbox"
                                                                                   className="inbox-checkboxes"
                                                                                   title="Select" value={item.id || ''}
                                                                                   checked={item.checked ? item.checked : false}
                                                                                   onChange={this.handleChecked.bind(this, item)}
                                                                                   style={{cursor: 'pointer'}}/>
                                                                        </td>
                                                                        <td className={"text-center align-middle hoverableItem"}
                                                                            style={{width: 50}}>
                                                                            <Button
                                                                                size={"sm"}
                                                                                color={"link"}
                                                                                onClick={() => this.handleItemAction("important", item)}
                                                                                title={"Click here to mark it important"}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                    "star-selected fa-star": item.isImportant,
                                                                                    "fa-star-o": !item.isImportant,
                                                                                    "text-primary fa-circle-o-notch fa-spin": item.id === loading.markImportant,
                                                                                })}
                                                                                   aria-hidden="true"/>
                                                                            </Button>
                                                                        </td>
                                                                        <td className={"align-middle"}
                                                                            onClick={() => this.handleItemAction("view", item)}>

                                                                            <div>{item.receipentDisplayName}</div>
                                                                        </td>
                                                                        <td className={"align-middle"}
                                                                            onClick={() => this.handleItemAction("view", item)}>
                                                                            <div>{item.subject ? item.subject : "subject"}</div>
                                                                        </td>

                                                                        <td className={"align-middle"}
                                                                            onClick={() => this.handleItemAction("view", item)}>
                                                                            <div className={"text-muted text-right"}>
                                                                                <small>{item.dateString}&nbsp;{item.timeString}</small>
                                                                            </div>
                                                                        </td>
                                                                        <td className={"text-center align-middle hoverableItem"}>
                                                                            <UncontrolledDropdown>
                                                                                <DropdownToggle caret
                                                                                                tag={"div"}
                                                                                                disabled={loading.save === index}
                                                                                                color={"primary"}
                                                                                                className={"text-primary"}
                                                                                                size={"sm"}>
                                                                                    {loading.save === index ?
                                                                                        <Spinner size="sm"
                                                                                                 style={{color: "white"}}/> :
                                                                                        <i className="fa fa-ellipsis-h fa-lg"
                                                                                           aria-hidden="true"/>}
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem
                                                                                        onClick={() => this.handleItemAction("archive", item)}
                                                                                        className={"text-primary"}
                                                                                    >
                                                                                        <i className={classnames("fa", "fa-fw", "mr-2", {
                                                                                            "fa-archive": item.id !== loading.markArchive,
                                                                                            "fa-circle-o-notch fa-spin": item.id === loading.markArchive,
                                                                                        })} aria-hidden="true"/> Archive
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() => this.handleItemAction("trash", item)}
                                                                                        className={"text-danger"}
                                                                                    >
                                                                                        <i className={classnames("fa", "fa-fw", "mr-2", {
                                                                                            "fa-trash-o": item.id !== loading.markTrash,
                                                                                            "fa-circle-o-notch fa-spin": item.id === loading.markTrash,
                                                                                        })} aria-hidden="true"/> Delete
                                                                                    </DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>

                                                                    </tr>
                                                                )}
                                                                </tbody>
                                                            </Table>

                                                        </div>
                                                    </Card>
                                                    <Row className={"mt-2"}>
                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                            <div className={"text-left"} style={{maxWidth: 200}}>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>{loading.messages ?
                                                                            <Spinner
                                                                                size={"sm"}/> : "Show"}</InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        type={"select"}
                                                                        name={"pageSize"}
                                                                        value={messages.request.pageRequest.pageSize}
                                                                        disabled={loading.messages}
                                                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                                        <option value={10}>10 Rows</option>
                                                                        <option value={25}>25 Rows</option>
                                                                        <option value={50}>50 Rows</option>
                                                                        <option value={100}>100 Rows</option>
                                                                        <option value={500}>500 Rows</option>
                                                                    </Input>
                                                                </InputGroup>


                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                                            {messages.response.totalRecords > messages.request.pageRequest.pageSize ?
                                                                <div className={"float-right"}>
                                                                    <Pagination
                                                                        activePage={messages.request.pageRequest.currentPage}
                                                                        itemsCountPerPage={messages.request.pageRequest.pageSize}
                                                                        totalItemsCount={messages.response.totalRecords}
                                                                        pageRangeDisplayed={3}
                                                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                                                        itemClass='page-item'
                                                                        linkClass='page-link'
                                                                        activeClass='active'
                                                                        innerClass='pagination'
                                                                        activeLinkClass='active'
                                                                    />
                                                                </div>
                                                                : null
                                                            }


                                                        </Col>
                                                    </Row>
                                                </div>
                                                : <div className={"center-window"}>
                                                    <h1 className="display-4">No messages yet
                                                        <i className="ml-3 fa fa-comments-o" aria-hidden="true"/>
                                                    </h1>
                                                    <p>When you have messages you'll see them here</p>
                                                    <Button color={"primary"}
                                                            onClick={() => this.handleItemAction("new", newMessage)}>Start
                                                        conversation</Button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                            }
                        </Col>
                    </Row>

                </div>
                <UserInboxMessageModal
                    isOpen={isOpenMessageModal}
                    toggle={this.toggleMessageModal}
                    message={workingMessage}
                />

                {isOpenComposeMessageModal ?
                    <ComposeUserInboxMessageModal
                        isOpen={isOpenComposeMessageModal}
                        toggle={this.toggleComposeMessageModal}
                        message={workingMessage}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                        loading={loading}
                    />
                    : null
                }

                {isOpenViewMessageModal ?
                    <ViewUserInboxMessageModal
                        isOpen={isOpenViewMessageModal}
                        toggle={this.toggleViewMessageModal}
                        message={workingMessage}
                    />
                    : null
                }


                <ToastContainer/>
            </div>
        );
    }
}