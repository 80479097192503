import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import ManagePrintersService from "../../services/ManagePrintersService";
import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
    Spinner,
    Table
} from "reactstrap";
import classnames from "classnames";
import {changeFormatOfDateString, handleErrorMessage} from "../../services/CommonService";
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import queryString from "query-string";
import cloneDeep from 'lodash/cloneDeep';

export default class PrintersJobPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printerJobsList: [],
            selectedPrinter: {},
            loading: false,
            saving: false,
            isOpenModal: false,
            printingStatuses: [],
            allPrinterJobTypes: [],
            formErrors: {},
            isOpenEditStatusModal: false,
            statusUpdating: false,
            selectedJob: {},
            jobs: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "createdDate",
                        direction: false
                    },
                    filterRequest: {
                        ordNum: 0,
                        PrintJobType: "",
                        printingStatus: ""

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
        };
        this.managePrintersService = new ManagePrintersService();
        this.toggle = this.toggle.bind(this);
        this.selectJobToUpdateStatus = this.selectJobToUpdateStatus.bind(this);
        this.updateJobStatus = this.updateJobStatus.bind(this);
        this.saveUpdatedJob = this.saveUpdatedJob.bind(this);
    }

    componentDidMount() {
        this.refresh(this.state.jobs);
        this.getPrintingStatuses();
        this.getPrinterJobTypes();
    }

    toggle(isOpen) {
        let {selectedJob} = this.state;
        if (!isOpen) {
            selectedJob = {};
        }
        this.setState({isOpenEditStatusModal: isOpen, selectedJob});
    }

    getPrintingStatuses() {
        this.managePrintersService.getPrintingStatuses().then(response => {
            this.setState({
                printingStatuses: response.data
            })
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        })
    }

    refresh(jobs) {
        this.setState({loading: true});
        this.managePrintersService.getAllPrinterJobsList(jobs.request).then(response => {
                jobs.response = response.data;
                this.setState({jobs, loading: false});
            }
        ).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({loading: false});
            }
        )
    }


    getColumnHeaders() {
        return [
            {
                key: "ordNum",
                label: "Order No",
                sorterApplicable: false,
                labelClassName: "align-middle",
                valueClassName: "",
                minWidth: '50px'
            },
            {
                key: "printJobType",
                label: "Document Print Type",
                sorterApplicable: false,
                labelClassName: "align-middle",
                valueClassName: "text-center",
                minWidth: '50px'
            },
            {
                key: "createdDate",
                label: "Print Requested On",
                sorterApplicable: true,
                labelClassName: "align-middle hoverableItem",
                valueClassName: "text-center",
                minWidth: '180px'
            },
            {
                key: "printingStatus",
                label: "Status",
                sorterApplicable: false,
                labelClassName: "text-center align-middle",
                valueClassName: "text-center",
                minWidth: '125px'
            },
            {
                key: "printCopies",
                label: "Print Copies",
                sorterApplicable: false,
                labelClassName: "align-middle",
                valueClassName: "",
                minWidth: '50px'
            },
            {
                key: "blobUrl",
                label: "Document",
                sorterApplicable: false,
                labelClassName: "text-center align-middle",
                valueClassName: "text-center",
                minWidth: '40px'
            },

            {
                key: "printerName",
                label: "Printer Alloted",
                sorterApplicable: false,
                labelClassName: "align-middle",
                valueClassName: "",
                minWidth: '200px'
            },
            {
                key: "printAttemptedTimes",
                label: "Attempts",
                sorterApplicable: false,
                labelClassName: "align-middle",
                valueClassName: "text-center",
                minWidth: '40px'
            },
            {
                key: "lastPrintAttemptDate",
                label: "Last Attempted On",
                sorterApplicable: true,
                labelClassName: "align-middle hoverableItem",
                valueClassName: "text-center",
                minWidth: '170px'
            }, 
            {
                key: "reason",
                label: "Reason",
                sorterApplicable: false,
                labelClassName: "align-middle",
                valueClassName: "",
                minWidth: '200px'
            },
             
        ]
    }

    getPrinterJobTypes() {
        this.managePrintersService.getPrinterJobTypes().then(response => {
            this.setState({allPrinterJobTypes: response.data});
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        })
    }

    handleChange(change, key) {
        let {jobs} = this.state;
        switch (key) {
            case "sortKey":
                if (jobs.request.sortRequest.key === change) {
                    jobs.request.sortRequest.direction = !jobs.request.sortRequest.direction;
                } else {
                    jobs.request.sortRequest.key = change;
                    jobs.request.sortRequest.direction = false;
                }
                this.refresh(jobs);
                break;
            case "pageSize":
                jobs.request.pageRequest[key] = change;
                this.refresh(jobs);
                break;
            case "currentPage":
                jobs.request.pageRequest[key] = change;
                this.refresh(jobs);
                break;
            default:
                jobs.request.filterRequest[key] = change;
                jobs.request.pageRequest.currentPage = 1;
                this.setState({jobs});
                this.refresh(jobs);
        }
    }

    renderJobPrintingStatus(status) {
        let badgeColor;
        switch (status) {
            case "SUBMITTED":
                badgeColor = "warning";
                break;
            case "FAILED":
                badgeColor = "danger";
                break;
            case "QUEUED":
                badgeColor = "primary";
                break;
            case "PRINTING":
                badgeColor = "info";
                break;
            case "PRINTED":
                badgeColor = "success";
                break;
            default:
                badgeColor = "secondary";
                break;
        }
        return badgeColor;
    }

    selectJobToUpdateStatus(printerJob) {
        this.setState({selectedJob: cloneDeep(printerJob), isOpenEditStatusModal: true});
    }

    updateJobStatus(value, prop) {
        let {selectedJob} = this.state;
        selectedJob[prop] = value;
        this.setState({selectedJob});
    }

    saveUpdatedJob() {
        let {selectedJob} = this.state;
        this.setState({statusUpdating: true});
        this.managePrintersService.saveUpdatedJob(selectedJob.id,  selectedJob.printingStatus).then(response => {
            this.setState({selectedJob: {}, statusUpdating: false,isOpenEditStatusModal: false});
            this.refresh(this.state.jobs);
            toast.success("Updated!");
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({statusUpdating: false});
        })
    }

    render() {
        let {jobs, loading, isOpenEditStatusModal, statusUpdating, selectedJob} = this.state;
        let {pageRequest, sortRequest, filterRequest} = jobs.request;
        let columnHeaders = this.getColumnHeaders();
        return (
            <div>
                <div>
                    {loading
                        ? <Spinner size={"sm"} color={"primary"}/>
                        :
                        <div className={"text-left"}>
                                 <span>
                                    Showing{' '}
                                     {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                     {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                     {' '}of {jobs.response.totalRecords}
                                     {' '}entries
                                </span>

                        </div>
                    }
                    <Table bordered responsive size={"sm"} striped={true}>
                        <thead>
                        {(columnHeaders || []).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}
                                >
                                    {item.label}
                                    {
                                        item.sorterApplicable ?
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (sortRequest.key !== item.key),
                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                }
                                            )} aria-hidden="true"/> : null
                                    }
                                </th>
                            );
                        })}
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <Input type={"text"}
                                       placeholder={"Search..."}
                                       name={"OrdNum"}
                                       onChange={(e) => this.handleChange(e.target.value, "OrdNum")}>
                                </Input>
                            </td>
                            <td><Input type={"select"}
                                       placeholder={"Select Type"}
                                       name={"printType"}
                                       onChange={(e) => this.handleChange(e.target.value, "PrintJobType")}>
                                <option key={""} value={""}>All</option>
                                {this.state.allPrinterJobTypes.map((status) =>
                                    <option key={status} value={status}>{status}</option>
                                )}
                            </Input></td>
                            <td></td>
                            <td>
                                <Input type={"select"}
                                       placeholder={"Select Status..."}
                                       name={"printingStatus"}
                                       onChange={(e) => this.handleChange(e.target.value, "printingStatus")}>
                                    <option key={""} value={""}>All</option>
                                    {this.state.printingStatuses.map((status) =>
                                        <option key={status} value={status}>{status}</option>
                                    )}
                                </Input>
                            </td>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                        {
                            (jobs.response.records || []).map(printerJob => (
                                <tr>
                                    <td>
                                        <Link
                                            className="btn btn-sm btn-primary"
                                            style={{color: "white"}}
                                            title={"Click here to view order in detail"}
                                            to={"/sales/order/view?" + queryString.stringify({OrdNum: printerJob.ordNum})}>{printerJob.ordNum}</Link>
                                    </td>
                                    <td>{printerJob.printJobType}</td>
                                    <td>{changeFormatOfDateString(printerJob.createdDate, "YYYY/MM/DD hh:mm:ss", 'DD MMM YYYY hh:mm A')}</td>
                                    <td className={"text-center"}>
                                        <Button size="sm"
                                                color={this.renderJobPrintingStatus(printerJob.printingStatus)}
                                                onClick={() => this.selectJobToUpdateStatus(printerJob)}>
                                            {printerJob.printingStatus}<i className="ml-2 fa fa-pencil"
                                                                          aria-hidden="true"/>
                                        </Button>
                                    </td>
                                    <td className={"text-center"}>{printerJob.printCopies}</td>
                                    <td className={"text-center"}>
                                        <a href={printerJob.blobUrl} target="_blank" rel="noopener noreferrer"
                                           download>
                                            <i className="fa fa-download" aria-hidden="true" />
                                        </a>
                                    </td>
                                    <td>{printerJob.printerName}</td>
                                    <td className={"text-center"}>{printerJob.printAttemptedTimes}</td>
                                    <td>{changeFormatOfDateString(printerJob.lastPrintAttemptDate, "YYYY/MM/DD hh:mm:ss", 'DD MMM YYYY hh:mm A')}</td>

                                    <td>{printerJob.reason}</td>

                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>

                    {!loading ?
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={jobs.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </div>

                <Modal isOpen={isOpenEditStatusModal} size="md" scrollable={false}
                       toggle={() => this.toggle(!isOpenEditStatusModal)}>
                    <ModalHeader toggle={() => this.toggle(!isOpenEditStatusModal)}>
                        Update print job status
                    </ModalHeader>
                    <ModalBody>
                        <Input type={"select"}
                               placeholder={"Select Status..."}
                               value={selectedJob.printingStatus}
                               name={"printingStatus"}
                               onChange={(e) => this.updateJobStatus(e.target.value, "printingStatus")}>
                            {this.state.printingStatuses.map((status) =>
                                <option key={status} value={status}>{status}</option>
                            )}
                        </Input>
                    </ModalBody>
                    <ModalFooter>
                        <div className={"text-right"}>
                            <Button color={"primary"}
                                    size="sm"
                                    className={"mr-2"}
                                    onClick={this.saveUpdatedJob}>
                                {statusUpdating ? <Spinner size={"sm"} className={"mr-2"}/> :
                                    <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>}
                                {statusUpdating ? "Updating" : "Update"}
                            </Button>

                            <Button color={"secondary"}
                                    size="sm"
                                    onClick={() => this.toggle(!isOpenEditStatusModal)}>
                                <i class="fa fa-times mr-2" aria-hidden="true"/> Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <ToastContainer/>

            </div>
        )
    }

}