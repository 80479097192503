import axios from './axios';
class StockInventoryTransactionService {

    static Instance() {
        return new StockInventoryTransactionService();
    }
    getStockInventoryTransaction(req) {
        return axios.post('api/stock-inventory-transaction/list/all',req)
    }
}
export default StockInventoryTransactionService.Instance();