import React, {Component} from 'react';
import PurchaseOrderEnquiry from "../../services/purchase/PurchaseOrderService";
import AsyncSelect from 'react-select/async';

export default class SearchPurchaseOrderOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: {
                label: "",
                value: ""
            },
            ordNum: "",
            options: [],
        };
        this.loadOptions = this.loadOptions.bind(this);
        this.purchaseOrderEnquiry = new PurchaseOrderEnquiry();
    }

    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.ordNum);
    }

    componentWillReceiveProps(nextProps) {
        let {ordNum, selected} = this.state;

        if (ordNum !== nextProps.defaultOrdNum) {
            //load externally selectedItem
            if (nextProps.defaultOrdNum) {
                ordNum = nextProps.defaultOrdNum;
                selected = {
                    label: nextProps.defaultOrdNum + "",
                    value: nextProps.defaultOrdNum
                };
                this.setState({selected, ordNum}, () => {
                    this.fetchOptions(ordNum);
                });
            }
        }

        if (ordNum !== nextProps.selectedOrdNum) {
            if (!nextProps.selectedOrdNum) {
                ordNum = "";
                selected = null;
            } else {
                ordNum = nextProps.selectedOrdNum;
                selected = {
                    label: nextProps.selectedOrdNum + "",
                    value: nextProps.selectedOrdNum
                };
            }
            this.setState({selected, ordNum}, () => {
                this.fetchOptions(ordNum);
            });
        }
    }

    handleSelect(selected, event) {
        let {ordNum} = this.state;
        ordNum = selected ? selected.value : "";

        switch (event) {
            case "selected":
                this.setState({selected, ordNum});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, ordNum}, () => {
                    this.props.handleChange(ordNum);
                });
        }
        if (!ordNum) {
            this.fetchOptions("");
        }
    };

    fetchOptions(inputValue, callback) {
        let {ordNum} = this.state;
        let options = [];
        this.purchaseOrderEnquiry.getPurchaseOrderAllOption(inputValue).then(response => {
            for (let i in response.data) {
                let temp = {
                    label: <>{response.data[i].ordNum}<span
                        className={"text-muted"}>{(response.data[i].supplierCode ? "    (" + response.data[i].supplierCode + " | " + response.data[i].supplierName + ")" : "")}</span>
                    </>,
                    value: response.data[i].ordNum
                };
                options.push(temp);

                if (this.props.defaultOrdNum) {
                    if (response.data[i].ordNum === this.props.defaultOrdNum) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.props.selectedOrdNum) {
                    if (response.data[i].ordNum === this.props.selectedOrdNum) {
                        this.handleSelect(temp, "selected");
                    }
                }

            }
            this.setState({options});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                console.log(error.data)
            }
        );
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.options);
            }
        }
    };

    render() {
        return (
            <div>
                <AsyncSelect
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.options}
                    loadOptions={this.loadOptions}
                    onChange={(selection) => this.handleSelect(selection, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                    placeholder={"Search: Ordnum (AccountID | Supplier Name)"}
                />
            </div>
        );
    }
}