import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import RepService from '../../services/RepService';
import { handleErrorMessage } from '../../services/CommonService';


export default class ManageRep extends Component {

    constructor(props) {
        super(props);

        this.state = {
            repCode: '',
            repObj: {
                repCode: '',
                code: '',
                area_Descrip: '',
                state: '',
                name: '',
                phone: '',
                mobile: '',
                email: '',
                isInternal: false,
                excludeFromCommission: false,
            },
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        let { repCode } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            repCode = searchParams.repCode;
            this.setState({ repCode }, () => { this.refresh() })
        }

    }

    refresh() {
        if (this.state.repCode && this.state.repCode !== '') {
            this.setState({ loading: true })

            RepService.fetchRepByRepCode(this.state.repCode).then(response => {
                this.setState({ repObj: response.data, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { repObj } = this.state;

        //max length for state is 3
        if (name === 'state') {
            if (value.length > 3) {
                return;
            }
        }

        const type = e.target.type;
        if (type === "checkbox") {
            repObj[name] = e.target.checked;
        }
        else {
            repObj[name] = value;
        }
        this.setState({ repObj });
    }

    handleCancel() {
        this.props.history.push('/reps');
    }

    validateForm(repObj) {
        let formErrors = {};
        let valid = true;

        if (repObj.code.length === 0) {
            formErrors.code = "Please enter code";
            valid = false;
        }

        if (repObj.name === null || (repObj.name !== null && repObj.name.length === 0)) {
            formErrors.name = "Please enter name";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.repObj };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {}});

            RepService.saveRep(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (this.state.repCode === '') {
                        this.props.history.push('/reps');
                        toast.success("Rep saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Rep updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.refresh();

                    }

                    this.setState({ saving: false });
                }

            }).catch(error => {
                this.setState({ saving: false });
                console.log(error.data)
            });
        }
    }

    render() {
        let { repObj, formErrors, loading, repCode } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/reps')}>Reps</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{repCode === '' ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Reps</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Code</Label>
                                            <Input
                                                invalid={formErrors.code!=null}
                                                name="code"
                                                type="text"
                                                value={repObj.code || ''}
                                                placeholder="write code here..."
                                                onChange={this.handleChange}>
                                            </Input>
                                            <FormText color="danger">{formErrors.code}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input
                                                invalid={formErrors.name!=null}
                                                name="name"
                                                type="text"
                                                value={repObj.name || ''}
                                                onChange={this.handleChange}
                                                placeholder="write name here..." />
                                            <FormText color="danger">{formErrors.name}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input
                                                name="email"
                                                type="text"
                                                value={repObj.email || ''}
                                                placeholder="write email here..."
                                                onChange={this.handleChange}>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>State</Label>
                                            <Input
                                                name="state"
                                                type="state"
                                                value={repObj.state || ''}
                                                placeholder="write state code here..."
                                                onChange={this.handleChange}>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Phone</Label>
                                            <Input
                                                name="phone"
                                                type="text"
                                                value={repObj.phone || ''}
                                                onChange={this.handleChange}
                                                placeholder="write phone no here..." />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Mobile</Label>
                                            <Input
                                                name="mobile"
                                                type="text"
                                                value={repObj.mobile || ''}
                                                placeholder="write mobile no here..."
                                                onChange={this.handleChange}>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                type="textarea"
                                                name="area_Descrip"
                                                value={this.state.repObj.area_Descrip || ''}
                                                onChange={(e) => this.handleChange(e)}
                                                placeholder="write description here...">

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col lg={2} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label className="ml-3">
                                            <Input
                                                type="checkbox"
                                                name="isInternal"
                                                value={this.state.repObj.isInternal}
                                                checked={this.state.repObj.isInternal}
                                                onChange={(e) => this.handleChange(e)}/>

                                             Internal</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label className="ml-2">
                                            <Input
                                                type="checkbox"
                                                name="excludeFromCommission"
                                                value={this.state.repObj.excludeFromCommission}
                                                checked={this.state.repObj.excludeFromCommission}
                                                    onChange={(e) => this.handleChange(e)} />
                                                Exclude From Commission
                                            </Label>
                                            
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving} >

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
