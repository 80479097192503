import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Spinner,
    Card, CardBody, CardText,
    Row, Col,
    Input, CardImg, Progress,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    CardHeader,
} from 'reactstrap';
import classnames from 'classnames';

const baseUrl = window.location.origin;
const allTabs = [
    {
        label: "Basic Info",
        key: "basicInfo"
    },
    {
        label: "Upload Attachments",
        key: "uploads"
    }
];

export default class ProductCatalogueUploadModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: {},
        };

    }

    getUrl(type) {
        let url = '/api/file/Generate/Catalogue?FileName=';
        switch (type) {
            case "productBrochure":
                url = '/api/file/Generate/Catalogue?FileName=';
                break;
            case "productOrderForms":
                url = '/api/file/Generate/CatalogueOrderFormFile?FileName='
                break;
        }

        return url;
    }

    render() {
        let { isOpen, toggle, selected, handleChange, handleSubmit, loading, type, onDocumentClickSuccess, handleSubmitBasicInfo, toggleModalTab, activeTab } = this.props;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                <CardHeader>
                    <h5>{(selected && selected.id) ? "Edit" : "Create"}</h5>
                    <Nav tabs card>
                        {allTabs.map((tab, index) =>
                            <NavItem className={"hoverableItem"} key={index}>
                                <NavLink
                                    className={classnames({ active: activeTab === tab.key })}
                                    onClick={() => {
                                        toggleModalTab(tab.key);
                                    }}>
                                    <Button style={{ textDecoration: "none" }}
                                        size={"sm"}
                                        color={"link"}>{tab.label}&nbsp;</Button>

                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </CardHeader>
                <ModalBody>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="basicInfo">
                            <div>
                                <div>
                                    Name/Description
                                </div>
                                <Input
                                    type="textarea"
                                    name="title"
                                    value={selected.title || ''}
                                    onChange={(e) => handleChange(e.target.value, "title")}
                                    placeholder="Write here..." />
                            </div>
                            <div className="text-right mt-2">
                                {(selected && selected.id)?
                                <Button color={"primary"}
                                    onClick={() => handleSubmitBasicInfo()}
                                    disabled={loading.isUploadingBasicInfo}>
                                    {loading.isUploadingBasicInfo
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            style={{ color: "white" }} />
                                        : <i className="fa fa-floppy-o mr-2" />}
                                    {loading.isUploadingBasicInfo ? "Saving" : "Save"}
                                </Button>:
                                <Button color={"primary"}
                                    onClick={() => handleSubmit()}
                                    disabled={loading.isUploadingAttachment}>
                                    {loading.isUploadingAttachment
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            style={{ color: "white" }} />
                                        : <i className="fa fa-floppy-o mr-2" />}
                                    {loading.isUploadingAttachment ? "Saving" : "Save"}
                                    </Button>}

                                    &nbsp;&nbsp;
                                    <Button disabled={loading.isUploadingBasicInfo}
                                    color={"secondary"}
                                    onClick={() => toggle(!isOpen)}>
                                    Cancel
                                    </Button>
                            </div>
                        </TabPane>
                        <TabPane tabId="uploads">
                            <Row>
                                <Col>

                                    {/*type !== 'productOrderForms' ? <div>
                                        <div>Snapshot(png/jpg)</div>
                                        <Input type="file" name="snapshotFile"
                                            onChange={(e) => handleChange(e.target.files[0], "snapshotFile")}
                                            aria-label="File browser example" />
                                        <hr />
                                    </div> : null}*/}
                                   
                                    <div>
                                        <div>Main File</div>
                                        <Input type="file" name="file"
                                            onChange={(e) => handleChange(e.target.files[0], "file")}
                                            aria-label="File browser example" />
                                    </div>
                                    <hr />
                                    
                                    <div className="text-right">
                                        <Button color={"primary"}
                                            onClick={() => handleSubmit()}
                                            disabled={loading.isUploadingAttachment}>
                                            {loading.isUploadingAttachment
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    style={{ color: "white" }} />
                                                : <i className="fa fa-floppy-o mr-2" />}
                                            {loading.isUploadingAttachment ? "Saving" : "Save"}
                                        </Button>
                                    &nbsp;&nbsp;
                                    <Button disabled={loading.isUploadingAttachment}
                                            color={"secondary"}
                                            onClick={() => toggle(!isOpen)}>
                                            Cancel
                                    </Button>
                                    </div>

                                </Col>
                                {
                                    (selected.id)
                                        ? <Col className={"border-left"}>
                                            <div className="p-2">
                                                {/*{type !== 'productOrderForms' ? <Card>
                                                    {
                                                        selected.snapFileName
                                                            ?
                                                            <CardImg
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'auto'
                                                                }}

                                                                src={baseUrl + this.getUrl(type) + selected.snapFileName}
                                                            />
                                                            : null
                                                    }
                                                    <CardBody>
                                                        <CardText>{selected.title}</CardText>
                                                    </CardBody>
                                                </Card> : null}*/}

                                                {
                                                    selected.fileName
                                                        ? <Button color="primary"
                                                            block={true}
                                                            outline={true}
                                                            title="Click here to download"
                                                            className="mt-2"
                                                            disabled={this.state.downloading}
                                                            onClick={() => onDocumentClickSuccess(selected)}>
                                                            {selected.downloading
                                                                ? <Spinner size="sm"
                                                                    className={"mr-2"}
                                                                    color={"primary"} />
                                                                : <i className="fa fa-download mr-2" />
                                                            }
                                                        Download
                                            </Button> : null}
                                                <div>
                                                    {selected.requestProgressPercentage > 0
                                                        ? <Progress striped color="primary" value={selected.requestProgressPercentage}
                                                            className={"mt-2"} />
                                                        : null
                                                    }
                                                </div>

                                            </div>
                                        </Col>
                                        : null
                                }
                            </Row>
                        </TabPane>
                    </TabContent>
                   
                </ModalBody>
            </Modal>
        )
    }
}