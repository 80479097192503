import React, {Component} from "react";
import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import {toast, ToastContainer} from "react-toastify";
import {handleErrorMessage, validateEmail} from '../../services/CommonService';
import OrderService from '../../services/OrderService';
import dispatchOrderService from '../../services/DispatchOrderService';
import SearchState from '../search/SearchState';
import SearchPostcode from '../search/SearchPostcode';
import SearchCity from "../search/SearchCity";
import SearchCountry from "../search/SearchCountry";
import {
    ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1, ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2,
    ADDRESS_VALIDATION_MAX_LENGTH_COMPANY,
    ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME,
    ADDRESS_VALIDATION_MAX_LENGTH_EMAIL, ADDRESS_VALIDATION_MAX_LENGTH_PHONE, ADDRESS_VALIDATION_MAX_LENGTH_STATE
} from "../../store/AppConstants";

export default class UpdateOrderDeliveryAddressModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordNum: props.ordNum,
            address: {
                addressDescription: "",
                addressContactName: "",
                addressCountryCd: "",
                addressState: "",
                addressPostcode: "",
                addressSuburb: "",
                addressEmail: "",
                addressPhone: "",
                addressLine1: "",
                addressLine2: "",
                addressCountry: ""
            },
            originalAddress: {
                addressDescription: "",
                addressContactName: "",
                addressCountryCd: "",
                addressState: "",
                addressPostcode: "",
                addressSuburb: "",
                addressEmail: "",
                addressPhone: "",
                addressLine1: "",
                addressLine2: "",
                addressCountry: ""
            },
            isLoadingValidateAddress: false,
            isLoadingUpdateAddress: false,
            isLoadingUpdateReceiverAddress: false,
            isInternational: false,
            formErrors: {},
        };

        this.orderService = new OrderService();
        this.validateOrderDeliveryAddress = this.validateOrderDeliveryAddress.bind(this);
        this.updateOrderDeliveryAddress = this.updateOrderDeliveryAddress.bind(this);
        this.saveShipmentReceiverAddress = this.saveShipmentReceiverAddress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        let { ordNum, address, originalAddress, formErrors } = this.state;
        ordNum = this.props.ordNum;
        if (this.props.originalAddress) {
            address = {
                addressDescription: this.props.originalAddress.addressDescription,
                addressContactName: this.props.originalAddress.addressContactName,
                addressCountryCd: this.props.originalAddress.addressCountryCd,
                addressState: this.props.originalAddress.addressState,
                addressPostcode: this.props.originalAddress.addressPostcode,
                addressSuburb: this.props.originalAddress.addressSuburb,
                addressEmail: this.props.originalAddress.addressEmail,
                addressPhone: this.props.originalAddress.addressPhone,
                addressLine1: this.props.originalAddress.addressLine1,
                addressLine2: this.props.originalAddress.addressLine2,
                addressCountry: this.props.originalAddress.addressCountry
            };
            originalAddress = {
                addressDescription: this.props.originalAddress.addressDescription,
                addressContactName: this.props.originalAddress.addressContactName,
                addressCountryCd: this.props.originalAddress.addressCountryCd,
                addressState: this.props.originalAddress.addressState,
                addressPostcode: this.props.originalAddress.addressPostcode,
                addressSuburb: this.props.originalAddress.addressSuburb,
                addressEmail: this.props.originalAddress.addressEmail,
                addressPhone: this.props.originalAddress.addressPhone,
                addressLine1: this.props.originalAddress.addressLine1,
                addressLine2: this.props.originalAddress.addressLine2,
                addressCountry: this.props.originalAddress.addressCountry
            };

            let validate = this.validate(address);
            formErrors = validate.formErrors;
        }

        if (originalAddress.addressCountry != 'AUSTRALIA') {
            this.setState({ isInternational: true });
        } else {
            this.setState({ isInternational: false });
        }

        this.setState({ordNum, originalAddress, address, formErrors});
    }

    handleChange(key, value) {
        let { address, formErrors, isInternational } = this.state;
        address[key] = value;
        if (key == "addressCountry") {
            isInternational = value == 'AUSTRALIA' ? false : true;
            this.setState({ isInternational });
        }

        let validate = this.validate(address);
        formErrors = validate.formErrors;

        this.setState({address, formErrors});
    }

    validate(address) {
        let { isInternational } = this.state;
        let formErrors = {}, isValid = true;

        if (!address.addressContactName) {
            formErrors.addressContactName = "Please enter contact name!";
            isValid = false;
        }
        if (address.addressContactName && address.addressContactName.length > ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME) {
            formErrors.addressContactName = "Contact name should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME + " characters!";
            isValid = false;
        }
        if (!address.addressPhone) {
            formErrors.addressPhone = "Please enter phone!";
            isValid = false;
        }
        if (address.addressPhone && address.addressPhone.length > ADDRESS_VALIDATION_MAX_LENGTH_PHONE) {
            formErrors.addressPhone = "Phone should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_PHONE + " characters!";
            isValid = false;
        }

        if (!address.addressEmail) {
            formErrors.addressEmail = "Please enter email!";
            isValid = false;
        }
        if (address.addressEmail && address.addressEmail.length > ADDRESS_VALIDATION_MAX_LENGTH_EMAIL) {
            formErrors.addressEmail = "Delivery email should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_EMAIL + " characters!";
            isValid = false;
        }
        if (address.addressEmail && !validateEmail(address.addressEmail)) {
            formErrors.addressEmail = "Please enter valid email";
            isValid = false;
        }
        if (!address.addressDescription) {
            formErrors.addressDescription = "Please enter company name";
            isValid = false;
        }
        if (address.addressDescription && address.addressDescription.length > ADDRESS_VALIDATION_MAX_LENGTH_COMPANY) {
            formErrors.addressDescription = "Company name should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_COMPANY + " characters!";
            isValid = false;
        }

        if (!address.addressLine1) {
            formErrors.addressLine1 = "Please enter address line 1";
            isValid = false;
        }
        if (address.addressLine1 && address.addressLine1.length > ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1) {
            formErrors.addressLine1 = "Address line 1 should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1 + " characters!";
            isValid = false;
        }
        if (address.addressLine2 && address.addressLine2.length > ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2) {
            formErrors.addressLine2 = "Address line 1 should be less than " + ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2 + " characters!";
            isValid = false;
        }
        if (!address.addressSuburb) {
            formErrors.addressSuburb = "Please enter suburb!";
            isValid = false;
        }
        if (!address.addressState && !isInternational) {
            formErrors.addressState = "Please enter state!";
            isValid = false;
        }
        if (address.addressState.length > 3) {
            formErrors.addressState = "Max " + ADDRESS_VALIDATION_MAX_LENGTH_STATE + " characters allowed!";
            isValid = false;
        }
        if (!address.addressPostcode && !isInternational) {
            formErrors.addressPostcode = "Please enter postcode";
            isValid = false;
        }
        if (!address.addressCountry) {
            formErrors.addressCountry = "Please enter country!";
            isValid = false;
        }
        return {isValid: isValid, formErrors: formErrors};

    }

    validateOrderDeliveryAddress() {
        let { address, formErrors, isInternational } = this.state;
        let validate = this.validate(address);
        if (!validate.isValid) {
            this.setState({formErrors: validate.formErrors});
            toast.info("Please fill all the details properly");
            return false;
        }
        if (!isInternational) {
            this.setState({ isLoadingValidateAddress: true });

            dispatchOrderService.validateShipmentReceiverAddress(address).then(response => {
                this.setState({ isLoadingValidateAddress: false });
                if (response.data) {
                    this.updateOrderDeliveryAddress();
                } else {
                    formErrors.addressSuburb = "Please enter correct city!";
                    formErrors.addressState = "Please select correct state!";
                    formErrors.addressPostcode = "Please select correct postcode!";
                    this.setState({ formErrors });
                    toast.error("Address not valid!");
                }
            }).catch(error => {
                this.setState({ isLoadingValidateAddress: false });
                toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            });
        } else {
            this.updateOrderDeliveryAddress();
        }
    }

    updateOrderDeliveryAddress() {
        let {address, ordNum} = this.state;
        this.setState({isLoadingUpdateAddress: true});
        let request = {
            contactName: address.addressContactName,
            company: address.addressDescription,
            address1: address.addressLine1,
            address2: address.addressLine2,
            address3: "",
            address4: "",
            city: address.addressSuburb,
            state: address.addressState,
            postCode: address.addressPostcode,
            phone: address.addressPhone,
            email: address.addressEmail,
            fax: '',
            country: address.addressCountry
        };
        this.orderService.updateOrderDeliveryAddress(request, ordNum).then(response => {
            this.setState({isLoadingUpdateAddress: false});
            this.saveShipmentReceiverAddress();

        }).catch(error => {
            this.setState({isLoadingUpdateAddress: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    saveShipmentReceiverAddress() {
        let {address} = this.state;
        this.setState({isLoadingUpdateReceiverAddress: true});
        let request = {
            courierReceiverID: "",
            ...address
        };
        dispatchOrderService.saveShipmentReceiverAddress(request).then(response => {
            if (response.data) {
                this.setState({isLoadingUpdateReceiverAddress: false});
                this.props.handleOrderDeliveryAddressUpdate(response.data);

                toast.success("Address updated!");
            }
        }).catch(error => {
            this.setState({isLoadingUpdateReceiverAddress: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let { address, originalAddress, isLoadingUpdateAddress, isLoadingValidateAddress,
            isInternational, isLoadingUpdateReceiverAddress, formErrors
        } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Modify delivery address
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"border-right"}>
                            <h6>Original saved address</h6>
                            <div>
                                <FormGroup>
                                    <Label for="name">Contact Name</Label>
                                    <Input type="text" name="addressContactName"
                                           disabled={true}
                                           value={originalAddress.addressContactName}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressPhone">Phone</Label>
                                    <Input type="text" name="addressPhone"
                                           disabled={true}
                                           value={originalAddress.addressPhone}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressEmail">Email</Label>
                                    <Input type="text" name="addressEmail"
                                           disabled={true}
                                           value={originalAddress.addressEmail}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressDescription">Company</Label>
                                    <Input type="text" name="addressDescription"
                                           disabled={true}
                                           value={originalAddress.addressDescription}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressLine1">Address Line1</Label>
                                    <Input type="text" name="addressLine1"
                                           disabled={true}
                                           value={originalAddress.addressLine1}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressLine2">Address Line2</Label>
                                    <Input type="text" name="addressLine2"
                                           disabled={true}
                                           value={originalAddress.addressLine2}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="roleType">Address Suburb</Label>
                                    <Input type="text" name="addressSuburb"
                                           disabled={true}
                                           value={originalAddress.addressSuburb}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressState">State</Label>
                                    <Input type="text" name="addressState"
                                           disabled={true}
                                           value={originalAddress.addressState}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressPostcode">Post Code</Label>
                                    <Input type="text" name="addressPostcode"
                                           disabled={true}
                                           value={originalAddress.addressPostcode}
                                           placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressCountry">Country</Label>
                                    <Input type="text" name="addressCountry"
                                        disabled={true}
                                        value={originalAddress.addressCountry}
                                        placeholder="" />
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <h6>Address to be modified</h6>
                            <div>
                                <FormGroup>
                                    <Label for="name">Contact Name*</Label>
                                    <Input type="text" name="addressContactName"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={address.addressContactName}
                                           placeholder="Enter contact name here"
                                           invalid={!!formErrors.addressContactName}
                                    />
                                    <FormText color="danger">{formErrors.addressContactName}</FormText>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="addressPhone">Phone*</Label>
                                    <Input type="text" name="addressPhone"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={address.addressPhone}
                                           placeholder="Enter phone here"
                                           invalid={!!formErrors.addressPhone}
                                    />
                                    <FormText color="danger">{formErrors.addressPhone}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressEmail">Email*</Label>
                                    <Input type="text" name="addressEmail"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={address.addressEmail}
                                           placeholder="Enter email here"
                                           invalid={!!formErrors.addressEmail}
                                    />
                                    <FormText color="danger">{formErrors.addressEmail}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressDescription">Company*</Label>
                                    <Input type="text" name="addressDescription"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={address.addressDescription}
                                           placeholder="Enter description here"
                                           invalid={!!formErrors.description}
                                    />
                                    <FormText color="danger">{formErrors.description}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressLine1">Address Line1*</Label>
                                    <Input type="text" name="addressLine1"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={address.addressLine1}
                                           placeholder="Enter address line 1 here"
                                           invalid={!!formErrors.addressLine1}
                                    />
                                    <FormText color="danger">{formErrors.addressLine1}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressLine2">Address Line2</Label>
                                    <Input type="text" name="addressLine2"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={address.addressLine2}
                                           placeholder="Enter address line 2 here"
                                           invalid={!!formErrors.addressLine2}
                                    />
                                    <FormText color="danger">{formErrors.addressLine2}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="roleType">Address Suburb*</Label>
                                    {isInternational ?
                                        <Input
                                            onChange={(e) => this.handleChange('addressSuburb', e.target.value)}
                                            name="addressSuburb"
                                            type="text"
                                            value={address.addressSuburb}
                                            placeholder="Suburb" />
                                        :
                                        <SearchCity
                                            handleSelection={(selectedCity) => this.handleChange("addressSuburb", selectedCity)}
                                            selected={address.addressSuburb || ''}
                                            defaultSelected={''}
                                            filters={{ state: '', postcode: '' }}
                                        />
                                    }
                                    <FormText color="danger">{formErrors.addressSuburb}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressState">State*</Label>
                                    {isInternational ?
                                        <Input
                                            onChange={(e) => this.handleChange('addressState', e.target.value)}
                                            name="addressState"
                                            type="text"
                                            value={address.addressState}
                                            placeholder="State" />
                                        :
                                        <SearchState
                                            handleSelection={(selectedState) => this.handleChange("addressState", selectedState)}
                                            selected={address.addressState}
                                            defaultSelected={''}
                                            filters={{ city: address.addressSuburb || '' }}
                                        />
                                    }
                                    <FormText color="danger">{formErrors.addressState}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressPostcode">Post Code</Label>
                                    {isInternational ?
                                        <Input
                                            onChange={(e) => this.handleChange('addressPostcode', e.target.value)}
                                            name="addressPostcode"
                                            type="text"
                                            value={address.addressPostcode}
                                            placeholder="Post Code" />
                                        :
                                        <SearchPostcode
                                            handleSelection={(selectedPostcode) => this.handleChange("addressPostcode", selectedPostcode)}
                                            selected={address.addressPostcode}
                                            defaultSelected={''}
                                            filters={{ state: address.addressState || '', city: address.addressSuburb || '' }}
                                        />
                                    }
                                    <FormText color="danger">{formErrors.addressPostcode}</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressCountry">Country</Label>
                                    <SearchCountry
                                        handleSelection={(selectedCountry) => this.handleChange("addressCountry", selectedCountry)}
                                        selected={address.addressCountry}
                                        defaultSelected={''} />
                                    <FormText color="danger">{formErrors.addressCountry}</FormText>
                                </FormGroup>
                            </div>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" className={"mr-2"}
                                onClick={this.validateOrderDeliveryAddress}
                                disabled={isLoadingValidateAddress || isLoadingUpdateAddress}>
                            {
                                (isLoadingValidateAddress || isLoadingUpdateAddress)
                                    ? <Spinner size="sm"
                                               className={"mr-2"}
                                               color={"light"}/>
                                    : <i className="fa fa-floppy-o mr-2"/>
                            }
                            {
                                isLoadingValidateAddress ? "Validating delivery address" : ""
                            }
                            {
                                isLoadingUpdateAddress ? "Updating delivery address" : ""
                            }
                            {
                                isLoadingUpdateReceiverAddress ? "Preparing receiver address" : ""
                            }
                            {
                                (!isLoadingValidateAddress && !isLoadingUpdateAddress && !isLoadingUpdateReceiverAddress) ? "Validate and Update Delivery Address" : ""
                            }
                        </Button>
                        <Button color={"secondary"}
                                size="sm"
                                className="ml-2"
                                onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2"/>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        );
    }
}