import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import UserService from "../../services/UserService";
import {toast, ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import {handleErrorMessage, getSupplierAddressString} from "../../services/CommonService";
import SearchCreditorAcccount from '../../components/search/SearchCreditorAcccount';
import creditorService from "../../services/purchase/CreditorService";
import CreditorIntroduction from '../../components/Creditor/CreditorIntroduction';
import SupplierAccountTab from '../../components/Creditor/SupplierAccountTab';
import TeamsComponent from '../../components/dashboard/TeamsComponent';
import PurchaseOrderPage from '../purchase/PurchaseOrderPage';
import SupplierStockItemsTab from '../../components/Creditor/SupplierStockItemsTab';
import SupplierQuotesTab from '../../components/Creditor/SupplierQuotesTab';

const allTabs = [
    {
        label: "Account",
        key: "credit",
        privilege: "creditors-read"
    },
    {
        label: "Purchase Order",
        key: "purchaseOrder",
        privilege: "creditors-read"
    },
    {
        label: "Stock Items",
        key: "stockItems",
        privilege: "creditors-read"
    },
    {
        label: "Quotes",
        key: "quotes",
        privilege: "creditors-read"
    }
];


export default class CreditorEnquiryPage extends Component {
    constructor(props) {
        super(props);

        let accountID = "";
        if (props.location.hash) {
            accountID = props.location.hash.replace('#', '');
        }
        this.state = {
            accountID: accountID,
            activeTab: allTabs[0].key,
            TransactionsLoading: false,
            supplierTabs: allTabs,
            supplier: {},
            supplierContacts: [],
            loadingContacts: true,
            hasSupplierWritePrivilege: false,
            searchParams: {},
            user: {},
            usersByEmail: {},
            alreadyInvited: [],
            alreadyRegisteredAccountID: [],
            workingCustomer: null,
            includeInactive: false,
            includeClosed: false
        };
        this.userService = new UserService();
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getCreditor = this.getCreditor.bind(this);
        this.getFirstSupplierAccountCompany = this.getFirstSupplierAccountCompany.bind(this);
    }


    componentDidMount() {
        let {activeTab, accountID} = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            activeTab = searchParams.activeTab ? searchParams.activeTab : '';
            accountID = searchParams.accountID ? searchParams.accountID : '';
            if (!activeTab) {
                activeTab = allTabs[0].key;
            }
            this.setState({activeTab, searchParams, accountID});
        }

        if (accountID) {
            this.refresh(accountID);
        } else {
            // Default, trying to get first accountID from db and then refreshing with the same
            this.getFirstSupplierAccountCompany();
        }
    }

    getFirstSupplierAccountCompany() {
        creditorService.searchFirstSupplierAccountCompany().then(response => {
            if (response.data) {
                for (let index in response.data) {
                    this.refresh(response.data[index].accountId ? response.data[index].accountId : "");
                }
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleAccountChange(accountID) {
        this.setState({accountID}, () => {
            if (accountID) {
                this.getCreditor(accountID);
            }
            this.updateUrl(accountID);
        });

    }

    updateUrl(accountID) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            if (accountID) {
                searchParams.set("accountID", accountID);
            } else {
                if (searchParams.has("accountID")) {
                    searchParams.delete("accountID");
                }
            }
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }


    refresh(accountID) {
        this.userService.getLoggedInUserFromDb(false).then(userResponse => {
            let user = userResponse.data;
            if (user) {
                let hasSupplierWritePrivilege = user.menu.privileges.includes('creditors-write');
                this.setState({user, hasSupplierWritePrivilege}, () => this.handleAccountChange(accountID));
            }
        }, error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });

            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("activeTab", tab);
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({path: newurl}, '', newurl);
            }
        }
    }

    getCreditor(accountID) {
        let {supplier} = this.state;
        if (accountID) {
            creditorService.getSupplierByAccount(accountID).then(response => {
                supplier = response.data;
                if (supplier) {
                    supplier.supplierAddress = getSupplierAddressString(supplier);
                    this.setState({supplier});
                }
            });
        } else {
            this.setState({accountID: supplier.accountID, supplier});
        }
    }

    renderSwitch(activeKey) {
        let {accountID, hasSupplierWritePrivilege} = this.state;
        switch (activeKey) {
            case "credit":
                return (<div>
                    <SupplierAccountTab accountID={accountID} hasSupplierWritePrivilege={hasSupplierWritePrivilege}
                                        refresh={this.getCreditor}/>
                    <br/>
                    <TeamsComponent accountID={accountID} consumerType={"Creditor"}/>
                </div>);
                break;
            case "purchaseOrder":
                return (<PurchaseOrderPage supplierAccountID={accountID} pageType={"creditor"} showReceived={false}/>);
                break;
            case "stockItems":
                return (<SupplierStockItemsTab accountID={accountID}
                                               hasSupplierWritePrivilege={hasSupplierWritePrivilege}/>)
                break;
            case "quotes":
                return (<SupplierQuotesTab accountID={accountID}/>)
                break;
        }
    }

    renderCreditorBody() {
        let {supplier, accountID, activeTab, supplierTabs, hasSupplierWritePrivilege} = this.state;
        if (accountID) {
            return (
                <div>
                    <div className="mb-2">
                        <CreditorIntroduction supplier={supplier}
                                              hasSupplierWritePrivilege={hasSupplierWritePrivilege}
                        />
                    </div>
                    <Card>
                        <CardHeader>
                            <Nav tabs card>
                                {supplierTabs.map((supplierTab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink
                                            className={classnames({active: activeTab === supplierTab.key})}
                                            onClick={() => {
                                                this.toggle(supplierTab.key);
                                            }}>
                                            <Button style={{textDecoration: "none"}}
                                                    size={"sm"} color={"link"}>{supplierTab.label}
                                            </Button>
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            {this.renderSwitch(activeTab)}
                        </CardBody>
                    </Card>
                </div>
            )
        } else {
            return (
                <Card>
                    <CardBody>
                        <CardText>Select/ Search Supplier first...</CardText>
                    </CardBody>
                </Card>
            )
        }
    }

    render() {
        const {accountID} = this.state;
        return (
            <div>
                <div className="mb-2">
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText><span>Search Supplier</span></InputGroupText>
                        </InputGroupAddon>
                        <span style={{width: '50%'}}>
                            <SearchCreditorAcccount handleAccountChange={this.handleAccountChange}
                                                    selectedAccountID={accountID}
                                                    defaultAccountID={accountID}
                            />
                        </span>
                    </InputGroup>
                </div>
                {this.renderCreditorBody()}
                <ToastContainer/>
            </div>
        )
    };
}
