import React, { Component } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Label, Spinner } from 'reactstrap';
import CustomerService from '../../services/CustomerService';
import { toast, ToastContainer } from 'react-toastify';
import { handleErrorMessage } from '../../services/CommonService';

export default class DebtorCreditTermsAndLimit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditterm: 0,
            creditlimit: 0,
            type: this.props.creditStatusEditType,
            creditTerms: [],
            isLoadingSave: false
        };

        this.customerService = new CustomerService();
        this.getCreditTerms = this.getCreditTerms.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.setState({ creditterm: this.props.creditTermsID, creditlimit: this.props.creditLimit });
    }

    refresh() {
        this.getCreditTerms();
    }

    getCreditTerms() {
        this.customerService.getDebtorCreditTerms().then(response => {
            if (response.status === 200 || response.status === "200") {
                let creditTerms = response.data;
                this.setState({ creditTerms });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }


    handleChange(key, value) {
        this.setState({ [key]: value });
    }

    handleSubmit(e) {
        this.setState({ isLoadingSave: true });
        let { creditterm, creditlimit, type } = this.state;
        let { accountID } = this.props;
        this.customerService.updateDebtorCreditTerm(type, accountID, creditterm, creditlimit).then(response => {
            if (response.status === 200 || response.status === "200") {
                if (type === "CreditLimit") {
                    toast.success("Credit Limit updated!");
                }
                else {
                    toast.success("Credit Terms updated!");
                }
                this.setState({ isLoadingSave: false });
                this.props.refresh();
                this.props.toggleModal(false);
            }
        }).catch(error => {
            this.setState({ isLoadingSave: false });
            console.log(error.data);
            toast.error(handleErrorMessage(error));
        });
    }


    render() {
        let { isLoadingSave, creditTerms, creditterm, creditlimit, type } = this.state;
        let { isOpen, toggleModal } = this.props;
        return (
            <Modal isOpen={isOpen}
                size="md"
                scrollable={false}
                toggle={() => toggleModal(!isOpen)}>
                <ModalHeader toggle={() => toggleModal(!isOpen)}>
                    Credit Status Edit
                </ModalHeader>
                <ModalBody>
                    {
                        type === "CreditLimit" ?
                            <div>
                                <Label className="col-form-label font-weight-bold">Credit Limit</Label>
                                <Input className="form-control"
                                    type="Number"
                                    name="creditlimit"
                                    value={creditlimit}
                                    onChange={(e) => this.handleChange("creditlimit", e.target.value)}
                                    placeholder="Enter Credit Limit here..." />
                            </div>
                            :
                            <div>
                                Credit Terms
                                <Input type="select" name="courier"
                                    value={creditterm} onChange={(e) => this.handleChange("creditterm", e.target.value)}>
                                    <option value={-1}>Select Credit Terms</option>
                                    {(creditTerms || []).map((creditTerm, index) =>
                                        <option key={index} value={creditTerm.creditTermsID}> {creditTerm.description}</option>
                                    )}
                                </Input>
                            </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button size={"sm"}
                            color='primary'
                            onClick={this.handleSubmit}
                            disabled={isLoadingSave}>
                            {
                                isLoadingSave
                                    ? <Spinner size="sm" className="mr-2" color={"light"} />
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                            }
                            {
                                isLoadingSave
                                    ? "Saving"
                                    : "Save"
                            }
                        </Button>
                        <Button size={"sm"}
                            color={"secondary"}
                            className={"ml-2"}
                            onClick={() => toggleModal(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}