import React, { Component } from 'react';
import { Link } from "react-router-dom";
import queryString from 'query-string';
import classnames from 'classnames';
import NumberFormat from "react-number-format";
import InfiniteScroll from 'react-infinite-scroller';
import { isAllSelected, changeFormatOfDateString, handleErrorMessage, getDistinctValues } from '../../../services/CommonService';
import { Table, Badge, Button, UncontrolledPopover, PopoverHeader, PopoverBody, ButtonGroup, Spinner } from 'reactstrap';
import { downloadInvoice, getquotesBody } from '../../../services/CommonFunctions';
import OrderService from '../../../services/OrderService';
import UserService from '../../../services/UserService';
import ComposeEmail from "../../../components/modal/ComposeEmail";
import { toast } from 'react-toastify';


export default class QuoteTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadingQuote: false,
            selectedQuote: {},
            emailModal: {
                isOpen: false,
                isLoadingEmailSend: false,
                mail: {
                    id: "",
                    from: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    fromOptions: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    to: [],
                    toOptions: [],
                    cc: [],
                    ccOptions: [],
                    bcc: [],
                    bccOptions: [],
                    subject: "",
                    body: "",
                    OrderNo: ""
                }
            },
            user: {}
        };

        this.downloadQuote = this.downloadQuote.bind(this);
        this.emailQuote = this.emailQuote.bind(this);
        this.sendEmailModal = this.sendEmailModal.bind(this);
        this.orderService = new OrderService();
        this.userService = new UserService();
    }

    componentDidMount() {
        let user = this.userService.getLoggedInUser();
        this.setState({ user });
    }

    downloadQuote(Order) {
        this.setState({ selectedQuote: Order, downloadingQuote: true });
        downloadInvoice(Order.ordNum, "quote", result => {     // here result is the callback value returned from common funct.
            if (result.status !== 200) {
                toast.error(handleErrorMessage(result));
                this.setState({ selectedQuote: Order, downloadingQuote: false });
            } else {
                this.setState({ selectedQuote: Order, downloadingQuote: false });
            }
        });
    }

    emailQuote(change, OrderNo) {
        let { emailModal } = this.state;
        emailModal.isOpen = change;
        emailModal.mail.subject = "Widoware | Quote # " + OrderNo;
        emailModal.mail.body = getquotesBody();
        emailModal.mail.OrderNo = OrderNo;
        this.setState({ emailModal });
    }

    sendEmailModal(mail) {
        let { emailModal } = this.state;
        emailModal.isLoadingEmailSend = true;
        emailModal.mail = mail;
        this.setState({ emailModal });

        let request = {
            tos: getDistinctValues(emailModal.mail.to ? emailModal.mail.to : [], 'value'),
            ccs: getDistinctValues(emailModal.mail.cc ? emailModal.mail.cc : [], 'value'),
            bccs: getDistinctValues(emailModal.mail.bcc ? emailModal.mail.bcc : [], 'value'),
            subject: emailModal.mail.subject,
            body: emailModal.mail.body,
            OrderNo: emailModal.mail.OrderNo
        };

        this.orderService.SendInvoiceInEmail(request).then(response => {
            if (response.data) {
                toast.success("Email sent successfully.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.emailQuote(false);
                emailModal.isLoadingEmailSend = false;
                this.setState({ emailModal })
            } else {
                toast.error("Something went wrong!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                emailModal.isLoadingEmailSend = false;
                this.setState({ emailModal });
            }
        }).catch(error => {
            console.log(error.data);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            emailModal.isLoadingEmailSend = false;
            this.setState({ emailModal })
        });
    }

    getCSSClass(item, type, value) {
        let returnValue = "";
        switch (item) {
            case "status": {
                switch (type) {
                    case "text":
                        switch (value) {
                            case "Active":
                                returnValue = "txt-primary";
                                break;
                            case "Cancelled":
                                returnValue = "txt-danger";
                                break;
                            case "Completed":
                                returnValue = "txt-success";
                                break;
                        }
                        break;
                    case "color":
                        switch (value) {
                            case "Active":
                                returnValue = "light";
                                break;
                            case "Cancelled":
                                returnValue = "dark";
                                break;
                            case "Completed":
                                returnValue = "success";
                                break;
                        }
                        break;
                }
                break;
            }
        }
        return returnValue;
    }

    render() {
        let { emailModal, user } = this.state;
        let { quotes } = this.props;
        const allQuotesSelected = isAllSelected(quotes.records, "selected");

        return (
            <div>

                <Table size={'sm'} hover={true} responsive={true}>
                    <thead>

                        <tr>
                            {/*<th style={{ cursor: 'pointer' }}
                                onClick={() => this.props.selectQuote(-1, !allQuotesSelected)}>
                                <a>{allQuotesSelected ?
                                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                                    <i className="fa fa-lg fa-square-o" aria-hidden="true" />}
                                </a>
                            </th>*/}
                            <th>Quote#</th>
                            <th>Customer</th>
                            <th>Date</th>
                            <th>Customer Order#</th>
                            <th>Amount</th>
                            <th>Rep</th>
                            <th>Email</th>
                            <th>Entered By</th>
                            <th>Status</th>
                            <th>Internal Notes</th>
                            <th>Download</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <InfiniteScroll
                        element={"tbody"}
                        initialLoad={false}
                        loader={<tr key={"loadingItem"}>
                            <td colSpan={12} className={"text-center"}><Button
                                size={"sm"}
                                block={true}
                                disabled={quotes.loading}
                                color={"primary"}
                                onClick={() => this.props.loadMore(true)}>{quotes.loading ? "Loading next " : "Load next "}{quotes.filter.pageSize}&nbsp;
                                orders...</Button></td>
                        </tr>}
                        pageStart={1}
                        loadMore={() => this.props.loadMore(true)}
                        hasMore={(quotes.filter.pageSize * quotes.filter.selectedPage) < quotes.filter.totalRecords}
                        useWindow={true}
                    >

                        {(quotes.records) && quotes.records.map((record, index) => {
                            return (
                                this.renderQuoteRow(index, record, emailModal, user)
                            );
                        })}

                    </InfiniteScroll>


                </Table>

                {
                    emailModal.isOpen ?
                        <ComposeEmail isOpen={emailModal.isOpen}
                            isSendingEmail={emailModal.isLoadingEmailSend}
                            toggle={this.emailQuote}
                            message={emailModal.mail}
                            handleSubmit={this.sendEmailModal}
                            parentType={"Quotes"}
                        />
                        : null
                }
            </div>
        );
    }

    renderQuoteRow(index, record, Email_Modal, CurrentUser) {
        let user = CurrentUser;
        let emailModal = Email_Modal;
        let statusClass = this.getCSSClass("status", "color", record.description);
        let emailString = "mailto:"
            + record.customerEmail
            + "?subject=Windoware | Quote-" + record.ordNum + " Follow-up" +
            "&body=AccountId-" + record.accountID + "%0A" + record.company + "%0A%0A%0A"
            + "Thanks,%0A"
            + "Team Windoware%0A"
            + "© 2019 Windoware Pty Ltd";

        return <tr key={index} className={classnames({ 'table-primary': record.selected })}>
            {/*<td style={{ cursor: 'pointer' }} onClick={() => this.props.selectQuote(index)}>
                <a>{record.selected ?
                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                    <i className="fa fa-lg fa-square-o" aria-hidden="true" />}
                </a>
            </td>*/}
            <td><Link
                className="btn btn-primary btn-sm"
                style={{ color: "white" }}
                title={"Click here to view orders in detail"}
                to={"/sales/order/view?" + queryString.stringify({ OrdNum: record.ordNum })}>{record.ordNum}</Link></td>
            <td>{record.company}</td>
            <td>{changeFormatOfDateString(record.ordDate, "DD/MM/YYYY", 'DD MMM YYYY')}</td>
            <td>{record.custOrdNum}</td>
            <td className="text-right"><NumberFormat value={record.invTotal}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'} /></td>
            <td>{record.rep}</td>
            <td>{(() => {
                if (record.customerEmail) {
                    return (<span>
                        <i className="fa fa-envelope-o"
                            aria-hidden="true" />&nbsp;
                        <a href={emailString}> {record.customerEmail}</a>
                    </span>
                    );
                }
            })
                ()}
            </td>
            <td>{record.enteredByUser ? record.enteredByUser.firstName : record.enteredBy}</td>
            <td><Badge color={statusClass}>{record.description}</Badge></td>
            <td>
                {(() => {
                    if (record.notes && record.notes.length > 0) {
                        return (<div>
                            <a title={"click here to see notes"}
                                style={{ cursor: 'pointer' }}
                                id={"notes-" + index}>
                                <i className="fa fa-ellipsis-h fa-lg" aria-hidden="true" />
                            </a>
                            <UncontrolledPopover target={"notes-" + index}>
                                <PopoverHeader>Quote No.{record.ordNum}&nbsp;-notes</PopoverHeader>
                                <PopoverBody>
                                    <ol>
                                        {(record.notes) && record.notes.map((note, index) => {
                                            return (<li key={index}>{note}<hr /></li>);
                                        })}
                                    </ol>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                        );
                    }
                })
                    ()}


            </td>
            <td className={"text-center"}>
                <Button title="Download Quote" color='primary' size="sm" id={`btn-${record.ordNum}`}
                    onClick={() => this.downloadQuote(record)}>
                    {(this.state.downloadingQuote && (record.ordNum === this.state.selectedQuote.ordNum))
                        ? <Spinner size={"sm"} className={"mr-2"} />
                        : <i className="fa fa-file-pdf-o" aria-hidden="true" />
                    }
                </Button>
            </td>

            <td className={"text-center"}>
                <Button title="Email Quote" color='primary' size="sm" id={`email_btn-${record.ordNum}`}
                    onClick={() => this.emailQuote(!emailModal.isOpen, record.ordNum)}>
                    <i className="fa fa-envelope" aria-hidden="true" />
                </Button>
            </td>
            <td className={"text-center"}>
                <ButtonGroup>
                    {record.createdFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                        ? <Link className="btn btn-sm btn-info"
                            style={(!(record.isValidQuote) && user.isExternalUser) ? { pointerEvents: "none", opacity: '0.6' } : null}
                            to={"/sales/order/create/product-builder/v1?orderNum=" + record.ordNum}>
                            Edit
                              </Link>
                        : null
                    }
                    <Button color={"danger"}
                        outline={true}
                        disabled={(!(record.isValidQuote) && user.isExternalUser)}
                        size={"sm"} onClick={() => this.props.toggleCancel(record)}>
                        {"Cancel"}
                    </Button>
                </ButtonGroup>
            </td>
        </tr>;
    }
}