import React, {Component} from 'react';
import CustomerDashboardQuickLinks from "./CustomerDashboard/CustomerDashboardQuickLinks";
import {Card, CardBody, Col, ListGroup, ListGroupItem, Row, Spinner, Table} from "reactstrap";
import {isEmpty,handleErrorMessage} from "../../services/CommonService";
import {toast, ToastContainer} from "react-toastify";
import UserService from "../../services/UserService";
import CustomerService from "../../services/CustomerService";
import ProductDiscountService from "../../services/product/ProductDiscountService";
import TeamsComponent from "./TeamsComponent";
import DeliveryAddressCard from "../debtor/DeliveryAddressCard";
import NotesCard from "./NotesCard";

const productCodesToRemoved = ['CTS', 'PWD','PPE','ROLS','ROLD'];
export default class AdminLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                roles: false
            },
            roles: [],
            accountId: "",
            debtor: {},
            discountData: [],
            loadingDebtor: false,
            loadingDiscount: false
        };
        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.productDiscountService = new ProductDiscountService();
    }

    componentDidMount() {
        let accountId = this.userService.getLoggedInUserAccountID();
        this.setState({accountId}, () => {
            this.refresh(accountId);
        });
    }

    refresh(accountId) {
        this.getDiscount(accountId);
        this.handleDebtorAccountDetails(accountId);
    }

    getDiscount(accountId) {
        this.setState({ loadingDiscount: true });
        this.customerService.getDiscount(accountId).then(response => {
            let discountData = response.data;
            discountData = discountData.filter(d => !productCodesToRemoved.includes(d.productCode));
            this.setState({ discountData, loadingDiscount: false })
        }).catch(error => {
            this.setState({ loadingDiscount: false });
            toast.error(handleErrorMessage(error));
            console.log(error.data)
        });
    }

    handleDebtorAccountDetails(accountId) {
        this.setState({loadingDebtor: true});
        this.customerService.searchCustomer(accountId).then(response => {
            this.setState({debtor: response.data, loadingDebtor: false})
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error.data);
            this.setState({loadingDebtor: false});
        });
    }

    render() {
        let { accountId, debtor, discountData, loadingDebtor, loadingDiscount } = this.state;
        return (
            <div>
                <div className="text-center">
                    <CustomerDashboardQuickLinks size={"sm"} debtor={debtor}/>
                    <hr/>
                </div>
                <Row>
                    <Col xl={4}
                         lg={4}
                         md={4}
                         sm={12}
                         xs={12}>
                        <Card>
                            <div>
                                {loadingDebtor
                                    ? <CardBody><Spinner color={"primary"}/></CardBody>
                                    : <ListGroup flush>
                                        <ListGroupItem>
                                            <div>
                                                <small>Account No.</small>
                                                <div><strong>{accountId}</strong></div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <div>
                                                <small>Company Name</small>
                                                <div><strong>{debtor.company}</strong></div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem>

                                            <div>
                                                <small>ABN</small>
                                                <div><strong>{debtor.abn}</strong></div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <div>
                                                <small>Category</small>
                                                <div><strong>{debtor.debtorCategoryName}</strong></div>
                                            </div>
                                        </ListGroupItem>
                                    </ListGroup>
                                }
                            </div>
                        </Card>
                    </Col>
                    <Col xl={4}
                         lg={4}
                         md={4}
                         sm={12}
                         xs={12}>
                        <Card>
                            <CardBody>
                                <p><strong>Discounts</strong></p>
                                {
                                    loadingDiscount
                                        ? <Spinner color={"primary"}/>
                                        : <div>
                                            <Table size={"sm"} striped={true} hover={true} bordered responsive className={"mb-0"}>
                                                <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th className={"text-right"}>Discount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    (discountData && discountData.length > 0) ?
                                                        (discountData || []).map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{item.displayName}</td>
                                                                <td className={"text-right"}>{item.discount}%</td>
                                                            </tr>
                                                        ) :
                                                        <tr>
                                                            <td colSpan="4" className="text-center">No Discount.</td>
                                                        </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <hr/>
                <div>
                    <TeamsComponent accountID={accountId} consumerType={"Debtor"} layout={"Admin"}/>
                </div>
                <hr/>
                <Card>
                    <Row className={"pt-4"}>
                        <Col xl={3}
                             lg={3}
                             md={3}
                             sm={12}
                             xs={12}>
                            <p className={"ml-3 mb-0"}><strong>Primary Address</strong></p>

                            <ListGroup flush>
                                <ListGroupItem>
                                    <small>Street</small>
                                    <div><strong>{debtor.address1}</strong></div>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <small>City</small>
                                    <div><strong>{debtor.city}</strong></div>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <small>State</small>
                                    <div><strong>{debtor.state}</strong></div>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <small>PostCode</small>
                                    <div><strong>{debtor.postCode}</strong></div>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <small>Phone</small>
                                    <div>
                                        <strong>
                                            <a href={"tel:" + debtor.phone}>
                                                {debtor.phone}
                                            </a>
                                        </strong>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col xl={9}
                             lg={9}
                             md={9}
                             sm={12}
                             xs={12}>
                            <div>
                                <p><strong>Delivery Addresses</strong></p>
                                <DeliveryAddressCard accountId={accountId}/>
                            </div>
                        </Col>
                    </Row>


                </Card>
                <ToastContainer/>
            </div>
        )
    }
}