import React, {Component} from 'react';
import {

    Row,
    Col,

    Button, TextArea, Collapse, Badge, ButtonGroup, Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import {Link as Link} from "react-router-dom";


import Sorter from "../../components/sorters/Sorter";
import Filter from "../../components/filters/Filter";
import TasksTable from "../../components/crm/task/TasksTable";

import UserService from "../../services/UserService";
import CustomerService from "../../services/CustomerService";
import TaskService from "../../services/crm/TaskService";

import moment from 'moment';
import {convertUtcDateToLocalDate, findIndex, handleErrorMessage} from '../../services/CommonService';

let allFilters = [
    {
        name: "Customer",
        displayName: "Customer",
        value: "",
        defaultValue: "",
        displayValue: "",
        valid: true,
        sortIndex: 10
    },
    {
        name: "AssignedUser",
        displayName: "Assigned User",
        value: "",
        defaultValue: "",
        displayValue: "",
        valid: true,
        sortIndex: 30
    },
    {
        name: "IsTaskClosed",
        displayName: "Status",
        value: "false",
        defaultValue: "",
        displayValue: "Pending",
        valid: true,
        sortIndex: 40
    },
    {
        name: "DueDateBetween",
        displayName: "Due Date Between",
        value: "",
        defaultValue: "",
        displayValue: "",
        valid: true,
        sortIndex: 20
    }, {
        name: "CreatedDateBetween",
        displayName: "Created Date Between",
        value: "",
        defaultValue: "",
        displayValue: "",
        valid: true,
        sortIndex: 25
    }
];
const allSorters = [
    {
        name: "CreatedDate",
        displayName: "Created Date",
        selectedOptionName: "",
        ascending:true,
        displayValue: "",
        valid: true,
        sortIndex: 20
    },
    {
        name: "ScheduledDate",
        displayName: "Due Date",
        selectedOptionName: "ScheduledDate-asc",
        ascending:false,
        displayValue: "Most Overdue",
        valid: true,
        sortIndex: 10
    }
];


export default class TasksPage extends Component {

    constructor(props) {
        super(props);

        let filters = allFilters;
        this.customerService = new CustomerService();
        this.userService = new UserService();
        this.taskService = new TaskService();

        let taskFilter = {
            accountID: "",
            assignedUser: "",
            isClosed: "false",
            dueDate: "",
            toggle: false
        };

        let index = findIndex(filters, "name", "AssignedUser");

        let currentUser = this.userService.getLoggedInUser();
        taskFilter.username = currentUser.username;
        if (index > -1) {
            filters[index].value = currentUser.username;
            filters[index].displayValue = currentUser.username;
        }

        this.state = {
            filters: filters,
            sorter: allSorters,
            tasks: {
                loading: false,
                page: {
                    currentPage: 1,
                    pageSize: 50,
                    totalRecords: 0
                },
                filter: taskFilter,
                sorter: {
                    toggle: false,
                    key: "ScheduledDate",
                    ascending: false
                },
                records: [],
            }
        }
        ;


        this.getTasks = this.getTasks.bind(this);
        this.toggleFilterCollapse = this.toggleFilterCollapse.bind(this);
        this.toggleSorterCollapse = this.toggleSorterCollapse.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateSorters = this.updateSorters.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.getTasks(false);
    }

    toggleFilterCollapse() {
        let tasks = this.state.tasks;
        tasks.sorter.toggle = false;

        tasks.filter.toggle = !tasks.filter.toggle;
        this.setState({tasks});
    }

    toggleSorterCollapse() {
        let tasks = this.state.tasks;
        tasks.filter.toggle = false;

        tasks.sorter.toggle = !tasks.sorter.toggle;
        this.setState({tasks});
    }


    updateFilters(filters, applyFilterFlag) {
        if (applyFilterFlag) {
            this.setState({filters}, () => {
                this.getTasks(false);
            });
        } else {
            this.setState({filters});
        }
    }

    updateSorters(selectedSorter, reload) {
        let tasks = this.state.tasks;
        tasks.sorter.key = selectedSorter.key;
        tasks.sorter.ascending = selectedSorter.ascending;
        if (reload) {
            this.setState({tasks}, () => {
                this.getTasks(false);
            });
        } else {
            this.setState({tasks});
        }
    }

    extractFilterValues(filters, tasks) {

        for (let i  in filters) {
            switch (filters[i].name) {
                case "CreatedDateBetween":
                    tasks.filter.createdDateBetween = filters[i].value;
                    break;
                case "DueDateBetween":
                    tasks.filter.dueDateBetween = filters[i].value;
                    break;
                case "Customer":
                    tasks.filter.accountID = filters[i].value;
                    break;
                case "AssignedUser":
                    tasks.filter.assignedUser = filters[i].value;
                    break;
                case "IsTaskClosed":
                    tasks.filter.isClosed = filters[i].value;
                    break;
                default:
                    break;
            }
        }

        return tasks;
    }


    getTasks(loadMore) {
        let {tasks, filters} = this.state;


        if (tasks.loading) {
            let self = this;
            setTimeout(function () {
                self.getTasks(loadMore);
            }, 2000);
        } else {
            tasks.loading = true;
            this.setState({tasks});

            tasks = this.extractFilterValues(filters, tasks);


            this.taskService.getTasks(tasks, loadMore).then(response => {
                tasks.loading = false;
                let currentMoment = moment();
                let tempDate = {};
                let data = response.data;
                tasks.page.selectedPage = data.selectedPage;
                tasks.page.pageSize = data.pageSize;
                tasks.page.totalRecords = data.totalRecords;
                if (!loadMore) {
                    tasks.records = [];
                }

                if (data.records) {
                    for (let i in data.records) {
                        tempDate = {};
                        if (data.records[i].scheduledOn && (!data.records[i].isClosed)) {
                            tempDate = convertUtcDateToLocalDate(data.records[i].scheduledOn);
                            data.records[i].overdueDays = moment.duration(currentMoment.diff(tempDate)).asDays();
                            switch (true) {
                                case data.records[i].overdueDays >= 1:
                                    data.records[i].backgroundColor = "lightcoral";
                                    data.records[i].title = "OVERDUE";
                                    break;
                                case (data.records[i].overdueDays >= 0):
                                    data.records[i].backgroundColor = "sandybrown";
                                    data.records[i].title = "OVERDUE TODAY";
                                    break;
                                case (data.records[i].overdueDays >= -1):
                                    data.records[i].backgroundColor = "powderblue";
                                    data.records[i].title = "DUE SOON";
                                    break;
                                default:
                                    data.records[i].backgroundColor = "white";
                            }
                        }
                        tasks.records.push(data.records[i]);
                    }
                }

                this.setState({tasks});
            }).catch(error => {
                alert(handleErrorMessage(error));
                tasks.loading = false;
                this.setState({tasks});
            });
        }
    }


    render() {

        let {tasks, filters} = this.state;
        const dataModal = this.taskService.getDataModal();

        return (<div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Tasks</BreadcrumbItem>
                </Breadcrumb>
                <Row>

                    <Col>
                        <Link
                            className={"btn btn-primary"}
                            to={{
                                pathname: "/crm/manageTask/" + 0,
                            }}> <i className="fa fa-plus"/>&nbsp;New Task&nbsp;&nbsp;
                        </Link>
                    </Col>
                    <Col>
                        <div className={" text-right mb-1"}>
                            <div><Badge color="light"
                                        pill>{tasks.loading ? <i className="fa fa-circle-o-notch fa-spin"
                                                                 aria-hidden="true"/> : tasks.page.totalRecords}</Badge>records
                                found&nbsp;
                                <ButtonGroup>
                                    <Button
                                        outline={!tasks.filter.toggle}
                                        color={"primary"}
                                        title={"Filter"}
                                        onClick={this.toggleFilterCollapse}>
                                        <i className="fa fa-filter"/>
                                    </Button>
                                    <Button outline={!tasks.sorter.toggle}
                                            color={"primary"}
                                            title={"sorter"}
                                            onClick={this.toggleSorterCollapse}>
                                        <i className="fa fa-sort"/>
                                    </Button>

                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Collapse isOpen={tasks.filter.toggle}>
                    <Filter filters={filters}
                            loading={tasks.loading}
                            onChange={this.updateFilters}
                            applyFilters={this.getTasks}/>
                </Collapse>

                <Collapse isOpen={tasks.sorter.toggle}>
                    <Sorter sorters={allSorters}
                            loading={tasks.loading}
                            onChange={this.updateSorters}
                            applySorters={this.getTasks}
                    />
                </Collapse>


                <TasksTable records={tasks.records}
                            dataModal={dataModal} pagination={tasks.page} isLoading={tasks.loading}/>

            </div>
        );
    }

}
