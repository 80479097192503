import React, { Component } from 'react';
import { Input, Row, Col, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { maxDate, minDate } from './../OrderGatewayComponents/OrderGatewayCommon';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';

export default class SearchBannerOutstandingQuotes extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(accountID) {
        this.props.handleChange(accountID, 'accountID');
    }

    render() {
        let { filterRequest, salesquotesStatuses, salesOrderJobStatuses, branches } = this.props;
        return (
            <div>
                <Row>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Quote No</Label>
                        <div className={"text-center"}>
                            <Input type={"number"}
                                placeholder={"Search..."}
                                name={"ordNum"}
                                value={filterRequest.ordNum}
                                onChange={(e) => this.props.handleChange(e.target.value, "ordNum")} />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={8} sm={12} xs={12}>
                        <Label>Account</Label>
                        <div style={{ zIndex: '2', position: 'relative' }}>
                            <SearchDebtorAcccount
                                handleAccountChange={this.handleChange}
                                selectedAccountID={filterRequest.accountID}
                                defaultAccountID={filterRequest.accountID}
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Branch</Label>
                        <div>
                            <Input type={"select"}
                                name={"branch"}
                                value={filterRequest.branch}
                                onChange={(e) => this.props.handleChange(e.target.value, "branch")}>
                                <option value={"All"}>All</option>
                                {(branches || []).map((option, index) =>
                                    <option key={index} value={option.branchNum}>{option.shortName}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Rep </Label>
                        <div>
                            <Input type={"select"}
                                placeholder={"Search RepCode..."}
                                name={"repCode"}
                                value={filterRequest.repCode || ''}
                                onChange={(e) => this.props.handleChange(e.target.value, "repCode")} >
                                <option value="ALL">All</option>
                                {this.props.reps.map((rep, index) =>
                                    <option key={index} value={rep.repCode}>{rep.name}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.startDate}
                                onChange={date => this.props.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.endDate}
                                onChange={date => this.props.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                </Row>
               
            </div>
        );
    }
}