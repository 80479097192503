import React, { Component } from "react";
import {
    Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import { toast } from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';
import ProductionLineService from '../../services/production/ProductionLineService';
import { cloneDeep } from 'lodash';

export default class ProductionLineModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            selectedproductionLine: cloneDeep(this.props.selectedproductionLine)
        }
    }

    handleChange(key, change) {
        let { selectedproductionLine } = this.state;
        selectedproductionLine[key] = change;
        this.setState({ selectedproductionLine });
    }

    validateForm(item) {
        let formErrors = {}, valid = true;
        if (!item.name) {
            formErrors.name = "Enter Production Line Name";
            valid = false;
        }
        if (!item.shortName) {
            formErrors.shortName = "Enter Production Line ShortName";
            valid = false;
        }
        if (item.shortName.length > 11) { 
            formErrors.shortName = "ShortName Max 10 Characters allowed";
            valid = false;
        }
        if (item.schedulerOrder.length == 0) {
            formErrors.schedulerOrder = "Enter Production Line Scheduler Order";
            valid = false;
        }
        if (item.schedulerOrder < 0) {
            formErrors.schedulerOrder = "Scheduler Order Should be greater than 0";
            valid = false;
        }
        return ({
            valid: valid,
            formErrors: formErrors
        })
    }

    saveProductionLine() {
            let { selectedproductionLine } = this.state;
            let validateResult = this.validateForm(selectedproductionLine);
            if (!validateResult.valid) {
                this.setState({ formErrors: validateResult.formErrors });
                return;
            }
            this.setState({ formErrors: {} })
        ProductionLineService.updateProductionLine(selectedproductionLine).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Saved Successfully", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.props.refreshAgain();
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    render() {
        let { isOpen, toggle, productionLine } = this.props;
        let { formErrors, selectedproductionLine } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}> 
                    Edit Production Line
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Name*</Label>
                                <Input type="text" name="name"
                                    value={selectedproductionLine.name}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    placeholder="Enter name here" />
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Short-Name*</Label>
                                <Input type="text" name="shortName"
                                    value={selectedproductionLine.shortName}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    placeholder="Enter Short-name here" />
                                <FormText color="danger">{formErrors.shortName}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="code">Code*</Label>
                                <Input type="select" disabled={true} name="code"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedproductionLine.code}>
                                    <option value=''>Select</option>
                                    {
                                        (productionLine || []).map((item, itemIndex) => (
                                            <option key={itemIndex} value={item.code}>{item.code}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="schedulerOrder">SchedulerOrder*</Label>
                                <Input type="number" name="schedulerOrder"
                                    value={selectedproductionLine.schedulerOrder}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    placeholder="Enter SchedulerOrder here" />
                                <FormText color="danger">{formErrors.schedulerOrder}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="isActive">Is Active</Label>
                                <div className='ml-2'>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("isActive", !selectedproductionLine.isActive)}>
                                            {
                                                selectedproductionLine.isActive
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="isVisibleProductionPerformance">Is Visible Production Performance</Label>
                                <div className='ml-2'>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("isVisibleProductionPerformance", !selectedproductionLine.isVisibleProductionPerformance)}>
                                            {
                                                selectedproductionLine.isVisibleProductionPerformance
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter> 
                    <div className="text-right"> 
                        <Button color={"primary"} size="sm" onClick={() => this.saveProductionLine()}>
                            <i className="fa fa-floppy-o mr-2" /> 
                            Save</Button> 
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}> 
                            <i className="fa fa-times mr-2" /> 
                            Cancel</Button> 
                    </div> 
                </ModalFooter>
            </Modal>
            );
    }
}