import axios from './axios';
class StockPriceHistoryService {

    static Instance() {
        return new StockPriceHistoryService();
    }
    getStockPriceHistory(req)
    {
        return axios.post('api/stock-price-history/list',req)
    }
}
export default StockPriceHistoryService.Instance();