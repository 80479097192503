import React, {Component} from "react";
import { Button, CardText, Spinner, Table } from 'reactstrap';
import PurchaseOrderNotesModal from "../../components/purchaseOrder/modals/PurchaseOrderNotesModal";
import PurchaseOrderService from "../../services/purchase/PurchaseOrderService";
import { handleErrorMessage, getSorted, changeFormatOfDateString } from "../../services/CommonService";
import {toast} from 'react-toastify';
import {isEmpty} from 'lodash';

export default class PurchaseOrderNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingNotes: false,
            openPurchaseOrderNotesModal: false,
            ordNum: this.props.ordNum,
            notes: [],
        };

        this.toggleOpenPurchaseOrderNotesModal = this.toggleOpenPurchaseOrderNotesModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.purchaseOrderService = new PurchaseOrderService();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        if (this.state.ordNum) {
            this.setState({isLoadingNotes: true});
            this.purchaseOrderService.getNotes(this.state.ordNum).then(response => {
                let notes = response.data;
                notes = getSorted(notes, 'purchaseOrderNoteID', false);
                this.setState({notes, isLoadingNotes: false});
            }).catch(error => {
                this.setState({isLoadingNotes: false});
                console.error(error.data);
                toast.error(handleErrorMessage(error));

            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            this.setState({ordNum: nextProps.ordNum,}, function () {
                this.refresh();
            });
        }
    }

    toggleOpenPurchaseOrderNotesModal() {
        this.setState((prevState) => ({openPurchaseOrderNotesModal: !prevState.openPurchaseOrderNotesModal}));
    }

    render() {
        let {ordNum, openPurchaseOrderNotesModal, notes, isLoadingNotes} = this.state;
        let {isReadOnly} = this.props;
        return (
            <div>
                <p className={"pb-1 mb-1"}><strong>Internal Notes</strong>
                    {
                        isReadOnly
                            ? null
                            : <Button color="link" size={"sm"}
                                      onClick={() => this.toggleOpenPurchaseOrderNotesModal()}
                                      className="float-right ml-2">
                                <i className="fa fa-plus mr-2" aria-hidden="true"/>
                                Add
                            </Button>
                    }
                </p>
                <div style={{
                    maxHeight: '200px',
                    overflowY: 'auto'
                }}>
                    {isLoadingNotes
                        ? <Spinner color={"primary"} size={"sm"}/>
                        :
                        <div>
                            {isEmpty(notes)
                                ? null
                                : <ul style={{padding: "unset", marginLeft: 1}}>
                                    {(notes || []).map((item, index) =>
                                        <li key={index}>
                                            <div className={"lead-remark-list-item"}>
                                                <div className={"lead-remark-list-item-text"}>
                                                    <small className="text-muted">
                                                        <i className="fa fa-user text-muted mr-2" aria-hidden="true"/>
                                                        {item.addedBy}
                                                    </small>
                                                    <p className="mb-1">{item.notes}</p>
                                                    <div className="text-right">
                                                        <small className="text-muted">
                                                            <i className="fa fa-clock-o mr-2" aria-hidden="true"/>
                                                            {changeFormatOfDateString(item.dateAdded, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY hh:mm a")}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            }
                        </div>
                    }
                </div>
                <PurchaseOrderNotesModal ordNum={ordNum}
                                         notes={notes}
                                         isOpen={openPurchaseOrderNotesModal}
                                         toggleModel={this.toggleOpenPurchaseOrderNotesModal}
                                         refresh={this.refresh}
                />
            </div>
        )
    }
}