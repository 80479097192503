import React, { Component } from 'react';
import { Button, FormText, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table, Row, Col, FormGroup, Label } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { handleErrorMessage, findIndex } from "../../services/CommonService";
import SearchProductConfigurationStockQtyFormula from '../search/SearchProductConfigurationStockQtyFormula';
import SearchProductConfigurationStockWastageFormula from '../search/SearchProductConfigurationStockWastageFormula';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import CreatableSelect from 'react-select/creatable';
import { measurementUnits } from '../../store/MeasurementUnits';
import classnames from 'classnames';
import DeleteModal from './DeleteModal';
import { cloneDeep, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import SearchStock from "../search/SearchStock";

export default class ProductConfigurationStockModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            saving: false,
            formErrors: [],
            defaultItem: this.props.defaultItem,
            multipleProdCode: '',
            isOpenCommaSeparatedProdCodeModal: false,
            isAddPrefix: false
        };

        this.addNewItem = this.addNewItem.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeleteProductConfiguration = this.handleDeleteProductConfiguration.bind(this);
        this.toggleDeleteProductConfigurationModal = this.toggleDeleteProductConfigurationModal.bind(this);
        this.handleSubmitProdCode = this.handleSubmitProdCode.bind(this);
        this.toggleCommaSeparatedProdCodeModal = this.toggleCommaSeparatedProdCodeModal.bind(this);
    }

    componentDidMount() {
        let items = cloneDeep(this.props.items);
        (items || []).forEach(item => {
            item.uuid = 'stock' + uuidv4();
        });
        this.setState({ items, formErrors: [] });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.state.items) {
            let items = cloneDeep(nextProps.items);
            (items || []).forEach(item => {
                item.uuid = 'stock' + uuidv4();
            });
            this.setState({ items, formErrors: [] });
        }
        if (nextProps.defaultItem !== this.state.defaultItem) {
            this.setState({ defaultItem: nextProps.defaultItem });
        }
    }

    defaultStockConfigurationDetail() {
        let { defaultItem } = this.state;
        let item = {
            id: 0,
            description: defaultItem.description,
            prodCode: "",
            price: defaultItem.price ? defaultItem.price : 0,
            flatPrice: defaultItem.flatPrice ? defaultItem.flatPrice : 0,
            quantityMultiplier: defaultItem.quantityMultiplier ? defaultItem.quantityMultiplier : 1,
            wastageFormulaId: defaultItem.wastageFormulaId ? defaultItem.wastageFormulaId : 1,
            keywayMeasurementUnit: defaultItem.keywayMeasurementUnit ? defaultItem.keywayMeasurementUnit : "unit",
            swishMeasurementUnit: defaultItem.swishMeasurementUnit ? defaultItem.swishMeasurementUnit : "unit",
            keywayConversionFactor: 1,
            swishConversionFactor: 1,
            isDeductionApplicable: defaultItem.isDeductionApplicable ? defaultItem.isDeductionApplicable : false,
            isVisibleInPartList: true,
            keywayQuantityFormulaId: defaultItem.keywayQuantityFormulaId ? defaultItem.keywayQuantityFormulaId : 1,
            swishQuantityFormulaId: defaultItem.swishQuantityFormulaId ? defaultItem.swishQuantityFormulaId : 1,
            productConfigurationOptionId: this.props.productConfigurationOptionID,
            productConfigurationOptionSetId: this.props.productConfigurationOptionSetId,
            cutWidthFormulaId: null,
            cutDropFormulaId: null,
            condition1: null,
            condition2: null,
            condition3: null,
            uuid: 'stock' + uuidv4()
        };
        return item;
    }

    addNewItem() {
        let { items } = this.state;
        let item = this.defaultStockConfigurationDetail();
        if (!items) {
            items = [];
        }
        items.push(item);
        this.setState({ items });
    }

    addMultipleNewItem() {
        this.setState({ isOpenMultipleInputArea: true });
    }

    handleChange(value, key, index) {
        let { items } = this.state;
        switch (key) {
            case "multipleProdCode":
                this.setState({ multipleProdCode: value })
                break;
            case "isAddPrefix":
                this.setState({ isAddPrefix: value })
                break;
            default:
                items[index][key] = value;
                this.setState({ items });
                break;
        }

    }

    validateForm(items) {
        let formErrors = [];
        let valid = true;

        (items || []).forEach((item, index) => {
            formErrors[index] = {};
            if (item.prodCode === null || item.prodCode === undefined || item.prodCode === '') {
                formErrors[index].prodCode = "Please select stock";
                valid = false;
            }
            if (item.quantityMultiplier === null || item.quantityMultiplier === undefined || item.quantityMultiplier === '' || item.quantityMultiplier === 0 || item.quantityMultiplier === '0') {
                formErrors[index].quantityMultiplier = "Please enter quantity multiplier";
                valid = false;
            }

            if (!item.swishQuantityFormulaId) {
                formErrors[index].swishQuantityFormulaId = "Please select stock formula";
                valid = false;
            }

            if (!item.keywayQuantityFormulaId) {
                formErrors[index].keywayQuantityFormulaId = "Please select stock formula";
                valid = false;
            }

        });
        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit() {
        let result = this.validateForm(this.state.items);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            toast.info("Please fill required details!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: [] });

            ProductConfigurationService.saveProductConfigurationStockForOptions(this.state.items, this.props.fabricColorID).then(response => {
                if (response.status === 200 || response.status === '200') {
                    this.setState({ saving: false });
                    toast.success("Saved!");
                    this.props.handleRefresh();
                    this.props.toggle(false, false);
                }
            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error));
            })
        }
    }

    handleSubmitProdCode() {
        let { multipleProdCode, items, isAddPrefix } = this.state;
        let prodCodes = [];
        if (multipleProdCode) {
            multipleProdCode = multipleProdCode.trim();
            prodCodes = multipleProdCode.split(/[\s,]+/);
        }
        if (!items) {
            items = [];
        }
        if (isAddPrefix) {
            for (let i = 0; i < prodCodes.length; i++) {
                prodCodes[i] = prodCodes[i].padStart(5, 0);
            }
        }
        (prodCodes || []).forEach(prodCode => {
            prodCode = prodCode.trim();
            if (prodCode) {
                let item = this.defaultStockConfigurationDetail();
                item.prodCode = prodCode;
                items.push(item);
            }
        })

        this.setState({ items, isOpenCommaSeparatedProdCodeModal: false, multipleProdCode: '' });
    }

    openDeleteConfirmationModal(item) {
        if (item.id) {
            this.setState({ isOpenDeleteProductConfigurationModal: true, selectedItemProductConfigurationStock: item });
        }
        else {
            let { items } = this.state;
            let index = findIndex(items, "uuid", item.uuid);
            items.splice(index, 1);
            this.setState({ items });
        }
    }

    toggleDeleteProductConfigurationModal(data) {
        this.setState({ isOpenDeleteProductConfigurationModal: data });
    }

    handleDeleteProductConfiguration() {
        let { selectedItemProductConfigurationStock, items } = this.state;
        let index = findIndex(items, 'id', selectedItemProductConfigurationStock.id);

        this.setState({ deletingProductConfiguration: true });
        ProductConfigurationService.deleteProductConfigurationStock(selectedItemProductConfigurationStock.id).then(response => {
            if (response.status === 200) {
                toast.success("Deleted");
                items.splice(index, 1);
                this.setState({
                    items,
                    deletingProductConfiguration: false,
                    isOpenDeleteProductConfigurationModal: false
                });
                this.props.handlerData(selectedItemProductConfigurationStock.prodCode);
            }
        }).catch(error => {
            this.setState({ deletingProductConfiguration: false });
            toast.error(handleErrorMessage(error));
        })
    }

    handleItemAction(key, itemIndex) {
        let { items } = this.state;

        switch (key) {
            case "clone":
                let copiedItem = cloneDeep(items[itemIndex]);
                copiedItem.uuid = 'stock' + uuidv4();
                copiedItem.id = 0;
                items.push(copiedItem);
                break;
        }

        this.setState({ items });
    }

    toggleCommaSeparatedProdCodeModal(data) {
        this.setState({ isOpenCommaSeparatedProdCodeModal: data });
    }

    render() {
        let { isOpen, toggle, header, canDeleteProductionConfiguration } = this.props;
        let { isAddPrefix, items, saving, formErrors, isOpenDeleteProductConfigurationModal, isOpenCommaSeparatedProdCodeModal, multipleProdCode } = this.state;
        let size = isOpenCommaSeparatedProdCodeModal ? "md" : "xl";
        return (
            <Modal isOpen={isOpen} size={size} scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">{header}</h5>
                </ModalHeader>
                <ModalBody>
                    <div>
                        {isOpenCommaSeparatedProdCodeModal ?
                            <div>
                                <Row className="mb-2">
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <label>ProdCode</label>
                                        <Input
                                            type={"textarea"}
                                            name={"multipleProdCode"}
                                            placeholder={"write comma separated prodcode here"}
                                            value={multipleProdCode}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)} />

                                        <a href={"javascript:void(0)"}
                                            onClick={() => this.handleChange(!isAddPrefix, "isAddPrefix")}>
                                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                                "fa-check-square-o": isAddPrefix,
                                                "fa-square-o": !isAddPrefix,
                                            }
                                            )} />
                                        </a> Add prefix to make prodcode atleast 5 digit.
                                    </Col>
                                </Row>
                            </div> :
                            <div>
                                <Table size={"sm"} striped responsive bordered style={{ minHeight: 400 }}>
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th style={{ minWidth: 200 }}>Prodcode</th>
                                            <th style={{ minWidth: 90 }}>Price</th>
                                            <th style={{ minWidth: 90 }}>Flat Price</th>
                                            <th style={{ minWidth: 90 }}>Qty Multiplier</th>
                                            <th style={{ minWidth: 170 }}>KW Qty formula</th>
                                            <th style={{ minWidth: 100 }}>
                                                <div>
                                                    KW Measurement
                                    </div>
                                                <div>
                                                    Unit
                                    </div>
                                            </th>
                                            <th style={{ minWidth: 100 }}>
                                                <div>
                                                    KW Measurement
                                    </div>
                                                <div>
                                                    Conversion Factor
                                    </div>
                                            </th>
                                            <th style={{ minWidth: 170 }}>SWISH Qty formula</th>
                                            <th style={{ minWidth: 100 }}>
                                                <div>
                                                    SWISH Measurement
                                    </div>
                                                <div>
                                                    Unit
                                    </div>
                                            </th>
                                            <th style={{ minWidth: 100 }}>
                                                <div>
                                                    SWISH Measurement
                                    </div>
                                                <div>
                                                    Conversion Factor
                                    </div>
                                            </th>
                                            <th style={{ minWidth: 170 }}>Wastage formula</th>
                                            <th style={{ minWidth: 170 }}>CutWidth formula</th>
                                            <th style={{ minWidth: 170 }}>CutDrop formula</th>
                                            <th style={{ minWidth: 50 }}>Is Deduction
                                    <div>
                                                    Applicable
                                    </div>
                                            </th>
                                            <th style={{ minWidth: 50 }}>Is Visible
                                    <div>
                                                    In Part-List
                                    </div>
                                </th>
                                <th style={{minWidth: 150}}>Description</th>
                                <th style={{minWidth: 150}}>Conditon1</th>
                                <th style={{minWidth: 150}}>Conditon2</th>
                                <th style={{minWidth: 150}}>Conditon3</th>
                                <th style={{minWidth: 150}} className='text-center'>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(items || []).map((item, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <SearchStock
                                            handleSelection={(selectedStock) => this.handleChange(selectedStock ? selectedStock.prodCode : "", "prodCode", index)}
                                            selected={item.prodCode}
                                            defaultProdCode={item.prodCode}
                                            filter={{ bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                                        />

                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].prodCode : null}</FormText>

                                                </td>

                                                <td>
                                                    <Input
                                                        type={"number"}
                                                        name={"price"}
                                                        placeholder={"write price here"}
                                                        value={item.price}
                                                        invalid={formErrors[index] ? formErrors[index].price !== undefined : false}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name, index)} />
                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].price : null}</FormText>
                                                </td>

                                                <td>
                                                    <Input
                                                        type={"number"}
                                                        name={"flatPrice"}
                                                        placeholder={"write flat price here"}
                                                        value={item.flatPrice}
                                                        invalid={formErrors[index] ? formErrors[index].flatPrice !== undefined : false}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name, index)} />
                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].flatPrice : null}</FormText>
                                                </td>
                                                <td><Input
                                                    type={"number"}
                                                    name={"quantityMultiplier"}
                                                    placeholder={"write qty multiplier"}
                                                    value={item.quantityMultiplier}
                                                    invalid={formErrors[index] ? formErrors[index].quantityMultiplier !== undefined : false}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].quantityMultiplier : null}</FormText>

                                                </td>
                                                <td>
                                                    <SearchProductConfigurationStockQtyFormula
                                                        selectedFormulaID={item.keywayQuantityFormulaId ? item.keywayQuantityFormulaId : ""}
                                                        handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "keywayQuantityFormulaId", index)}
                                                    />
                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].keywayQuantityFormulaId : null}</FormText>

                                                </td>
                                                <td>
                                                    <CreatableSelect
                                                        formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                        onChange={(value) => this.handleChange(value.value, "keywayMeasurementUnit", index)}
                                                        value={{
                                                            label: item.keywayMeasurementUnit,
                                                            value: item.keywayMeasurementUnit
                                                        }}
                                                        options={measurementUnits}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type={"number"}
                                                        name={"keywayConversionFactor"}
                                                        placeholder={"write here"}
                                                        value={item.keywayConversionFactor}
                                                        invalid={formErrors[index] ? formErrors[index].keywayConversionFactor !== undefined : false}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name, index)} />
                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].keywayConversionFactor : null}</FormText>
                                                </td>

                                                <td>
                                                    <SearchProductConfigurationStockQtyFormula
                                                        selectedFormulaID={item.swishQuantityFormulaId ? item.swishQuantityFormulaId : ""}
                                                        handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "swishQuantityFormulaId", index)}
                                                    />
                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].swishQuantityFormulaId : null}</FormText>

                                                </td>
                                                <td>
                                                    <CreatableSelect
                                                        formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                        onChange={(value) => this.handleChange(value.value, "swishMeasurementUnit", index)}
                                                        value={{
                                                            label: item.swishMeasurementUnit,
                                                            value: item.swishMeasurementUnit
                                                        }}
                                                        options={measurementUnits}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type={"number"}
                                                        name={"swishConversionFactor"}
                                                        placeholder={"write here"}
                                                        value={item.swishConversionFactor}
                                                        invalid={formErrors[index] ? formErrors[index].keywayConversionFactor !== undefined : false}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name, index)} />
                                                    <FormText
                                                        color="danger">{formErrors[index] ? formErrors[index].swishConversionFactor : null}</FormText>
                                                </td>
                                                <td>
                                                    <SearchProductConfigurationStockWastageFormula
                                                        selectedFormulaID={item.wastageFormulaId ? item.wastageFormulaId : ""}
                                                        handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "wastageFormulaId", index)}
                                                    />
                                                </td>
                                                <td>
                                                    <SearchProductConfigurationStockQtyFormula
                                                        selectedFormulaID={item.cutWidthFormulaId ? item.cutWidthFormulaId : ""}
                                                        handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "cutWidthFormulaId", index)}
                                                    />
                                                </td>
                                                <td>
                                                    <SearchProductConfigurationStockQtyFormula
                                                        selectedFormulaID={item.cutDropFormulaId ? item.cutDropFormulaId : ""}
                                                        handleSelection={(selection) => this.handleChange(selection ? selection.id : "", "cutDropFormulaId", index)}
                                                    />
                                                </td>


                                    <td className="text-center">
                                        <Button color={"link"}
                                                onClick={() => this.handleChange(!item.isDeductionApplicable, "isDeductionApplicable", index)}>
                                            <i className={classnames("fa", {
                                                    "fa-check-square-o": item.isDeductionApplicable,
                                                    "fa-square-o": !item.isDeductionApplicable,
                                                }
                                            )}/>
                                        </Button>
                                    </td>
                                    <td className="text-center">
                                        <Button color={"link"}
                                                onClick={() => this.handleChange(!item.isVisibleInPartList, "isVisibleInPartList", index)}>
                                            <i className={classnames("fa", {
                                                    "fa-check-square-o": item.isVisibleInPartList,
                                                    "fa-square-o": !item.isVisibleInPartList,
                                                }
                                            )}/>
                                        </Button>
                                    </td>
                                    <td>
                                        <Input
                                            type={"textarea"}
                                            name={"description"}
                                            placeholder={"write description"}
                                            value={item.description}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                        />
                                    </td><td>
                                        <Input
                                            type={"textarea"}
                                            name={"condition1"}
                                            placeholder={"write condition1"}
                                            value={item.condition1}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                        />
                                    </td><td>
                                        <Input
                                            type={"textarea"}
                                            name={"condition2"}
                                            placeholder={"write condition2"}
                                            value={item.condition2}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                        />
                                    </td><td>
                                        <Input
                                            type={"textarea"}
                                            name={"condition3"}
                                            placeholder={"write condition3"}
                                            value={item.condition3}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Button color={"primary"}
                                                className={"mr-2"}
                                                size={"sm"}
                                                title={"click here to copy item"}
                                                onClick={() => this.handleItemAction("clone", index)}>
                                            <i className="fa fa-clone fa-lg"
                                               aria-hidden="true"/>
                                        </Button>
                                        {(canDeleteProductionConfiguration) ? <Button color='danger'
                                                                                      size={"sm"}
                                                                                      onClick={() => this.openDeleteConfirmationModal(item)}><i
                                                className='fa fa-trash'/></Button>
                                            : null}
                                    </td>
                                </tr>
                            )}

                                    </tbody>
                                </Table>
                                <div className={"mt-2"}>
                                    {this.props.maxStocksAllowed > (items || []).length ?

                                        <Button onClick={this.addNewItem}><i className="fa fa-plus mr-2" aria-hidden="true" />Add</Button>
                                        : null
                                    }
                                    {this.props.maxStocksAllowed > 1 ?
                                        <Button onClick={() => this.toggleCommaSeparatedProdCodeModal(true)} className="ml-2"><i className="fa fa-plus mr-2" aria-hidden="true" />
                                                Add Comma Separated ProdCode</Button>
                                        : null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    {isOpenCommaSeparatedProdCodeModal ?
                        <div className={"text-right"}>
                            <Button color={"primary"}
                                size={"sm"} onClick={this.handleSubmitProdCode}>
                                <i className="fa fa-plus mr-2" aria-hidden="true" />Add Rows
                        </Button>
                        &nbsp;
                        <Button color={"secondary"}
                                size={"sm"} onClick={() => this.toggleCommaSeparatedProdCodeModal(false)}>
                                <i className="fa fa-times mr-2" /> Cancel
                        </Button>
                        </div> :
                        <div className={"text-right"}>
                            <Button color={"primary"}
                                size={"sm"} onClick={this.handleSubmit} disabled={saving}>
                                {saving ? <Spinner size="sm"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />} Save
                        </Button>
                        &nbsp;
                        <Button color={"secondary"}
                                size={"sm"} onClick={() => toggle(!isOpen, false)}>
                                <i className="fa fa-times mr-2" /> Close
                        </Button>
                        </div>
                    }
                </ModalFooter>

                {isOpenDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete product configuration stock'}
                        isOpen={isOpenDeleteProductConfigurationModal}
                        toggle={this.toggleDeleteProductConfigurationModal}
                        handleDelete={this.handleDeleteProductConfiguration}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}
                <ToastContainer />
            </Modal>
        )
    }
}