import React, { Component } from 'react';
import { Badge, Card, CardHeader, CardBody, Spinner, Table } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from "react-router-dom";
import { changeFormatOfDateString, handleErrorMessage } from '../../services/CommonService';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { STOCK_QTY_FORMAT, STOCK_QTY_TYPE } from "../../store/AppConstants";
import StockQtyFormat from "../../components/stock/StockQtyFormat";

export default class ShowNPurchaseOrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTable: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: props.requiredRecords
                    },
                    sortRequest: {
                        key: props.sortKey,
                        direction: props.sortDirection
                    },
                    filterRequest: {
                        ordNum: null,
                        statusID: null,
                        supplierCode: '',
                        outstanding: props.outstanding,
                        cancelled: props.cancelled,
                        isPurchaseHistory: null,
                        startDate: null,
                        endDate: null,
                        orderStatusDescription: '',
                        prodCode: props.prodCode
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: false
        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.getPurchaseOrderItemData = this.getPurchaseOrderItemData.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getStore = this.getStore.bind(this);
    }

    componentDidMount() {
        this.refresh(this.state.dataTable);
    }

    componentWillReceiveProps(nextprops) {
        let { dataTable } = this.state;
        let { pageRequest, sortRequest, filterRequest } = dataTable.request;
        if (filterRequest.prodCode !== nextprops.prodCode) {
            filterRequest.prodCode = nextprops.prodCode;
        }
        if (sortRequest.key !== nextprops.sortKey || sortRequest.direction !== nextprops.sortDirection) {
            sortRequest = {
                key: nextprops.sortKey,
                direction: nextprops.sortDirection
            };
        }
        if (pageRequest.pageSize !== nextprops.requiredRecords) {
            pageRequest.pageSize = nextprops.requiredRecords;
        }
        if (filterRequest.outstanding !== nextprops.outstanding) {
            filterRequest.outstanding = nextprops.outstanding;
        }
        if (filterRequest.cancelled !== nextprops.cancelled) {
            filterRequest.cancelled = nextprops.cancelled;
        }
        this.setState({ dataTable }, function () {
            this.refresh(this.state.dataTable);
        });
    }

    refresh(dataTable) {
        this.getPurchaseOrderItemData(dataTable);
    }

    getPurchaseOrderItemData(dataTable) {
        this.setState({ loading: true });
        let request = cloneDeep(dataTable.request);
        this.purchaseOrderService.getPurchaseOrderItemByProdCode(request).then(response => {
            dataTable.response = response.data;
            this.setState({ dataTable, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStore() {
        let store = [
            {
                key: "dateAdded",
                label: "PO Date",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY')}</span>;
                }
            },
            {
                key: "ordNum",
                label: "PO No",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <Link to={"/purchase/order/enquiry?" + queryString.stringify({ ordNum: value })}
                        className="btn btn-sm btn-primary"
                        title={"Click Here to see the Order Details"}
                        style={{ cursor: "pointer" }}>
                        {value}
                    </Link>;
                }

            },
            {
                key: "supplierCode",
                label: "Supplier Code",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <Link className="btn btn-primary btn-sm"
                        to={"/creditor/enquiry?" + queryString.stringify({ accountID: value })}>
                        {value}
                    </Link>;
                }
            },
            {
                key: "supplier",
                label: "Supplier",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                valueClassName: "align-middle",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <small>{value}</small>;
                }
            },
            {
                key: "qty",
                label: "Qty",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            },
            {
                key: "orderStatusDescription",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    let color = "";
                    switch (value) {
                        case "Cancelled":
                            color = "danger";
                            break;
                        case "In Progress":
                            color = "secondary";
                            break;
                        case "On Hold":
                            color = "primary";
                            break;
                        case "Approved":
                            color = "warning";
                            break;
                        case "Sent":
                            color = "info";
                            break;
                        case "Completed":
                            color = "success";
                            break;
                    }
                    return <Badge color={color}> {value}</Badge>;
                }
            },
            {
                key: "itemEtaDate",
                label: "ETA",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <span>{value ? changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY') : null}</span>;
                }
            },
            {
                key: "received",
                label: "Rec'd",
                type: "text",
                colSpan: 1,
                minWidth: 65,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            },
            {
                key: "lastStockReceivedDate",
                label: "Rec'd On",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "YYYY/MM/DD HH:mm:ss", 'DD MMM YYYY')}</span>;
                }
            },
            {
                key: "qtyOutstanding",
                label: "Outstanding",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />;
                }
            },
            {
                key: "location",
                label: "Location",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <span>{value}</span>;
                }
            }
        ];
        return store;
    }

    render() {
        let { dataTable, loading } = this.state;
        let store = this.getStore();
        return (
            <Card>
                <CardHeader>
                    <h6 className="mb-0">Last 3 Purchase Orders</h6>
                </CardHeader>
                <CardBody>
                    {
                        loading ?
                            <Spinner color='primary' size='sm' />
                            :
                            <Table striped bordered responsive hover size='sm' className={"mb-1"}>
                                <thead>
                                    <tr>
                                        {(store || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>

                                    {(dataTable.response.records || []).map((record, i) => {
                                        return (
                                            <tr key={i}>
                                                {(store || []).map((storeItem, index) => {
                                                    return (
                                                        <td key={index} className={storeItem.valueClassName}>
                                                            {storeItem.render(record[storeItem.key], record, i, dataTable.response.records, this)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                    {
                                        ((dataTable.response.records || []).length === 0)
                                            ? <tr>
                                                <td colSpan={11}>No Orders Found!</td>
                                            </tr>
                                            : null
                                    }
                                </tbody>
                            </Table>
                    }
                </CardBody>
            </Card>
        );
    }
}