import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Spinner, Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody, CardFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import StockService from '../../services/StockService';
import ProductService from '../../services/product/ProductService';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import { handleErrorMessage } from '../../services/CommonService';
import * as FileSaver from 'file-saver';
import StockGroupManageModal from '../../components/modal/StockGroupManageModal';

export default class StockGroupsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        name: '',
                        code: '',
                        masterGroupID: 0,
                        productGroupId: 0,
                        productId: 0,

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            stockMasterGroupCode: [],
            productGroupCode: [],
            productCode: [],
            exportLoading: false,
            isOpenModal: false,
            selectedStockGroup: {}
        };

        this.stockService = new StockService();
        this.refreshAgain = this.refreshAgain.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

    }

    componentDidMount() {
        this.refreshAgain();
    }

    getStocksGroupList(stockData) {
        this.setState({ loading: true });

        this.stockService.getStockGroupList(stockData.request).then(response => {
            stockData.response = response.data;
            this.setState({ stockData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStockMasterGroupCode() {
        let { stockMasterGroupCode } = this.state;
        this.stockService.getStockMasterGroup().then(response => {
            if (response.data) {
                stockMasterGroupCode = response.data;
                this.setState({ stockMasterGroupCode });

            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getStockProductGroupCode() {
        let { productGroupCode } = this.state;
        ProductService.getProductGroups().then(response => {
            if (response.data) {
                productGroupCode = response.data;
                this.setState({ productGroupCode });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    getStockProductCode() {
        let { productCode } = this.state;
        ProductService.getProducts().then(response => {
            if (response.data) {
                productCode = response.data;
                this.setState({ productCode });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.name) {
                flag = item.prodCode.toLowerCase().includes(searchText)
            }

            if (!flag && item.code) {
                flag = item.qoh.toString().toLowerCase().includes(searchText)
            }


        }
        return flag;
    }

    handleChange(change, key) {
        let { stockData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (stockData.request.sortRequest.key === change) {
                    stockData.request.sortRequest.direction = !stockData.request.sortRequest.direction;
                } else {
                    stockData.request.sortRequest.key = change;
                    stockData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStocksGroupList(stockData);
                }
                break;
            case "pageSize":
                stockData.request.pageRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.getStocksGroupList(stockData);
                break;
            case "currentPage":
                stockData.request.pageRequest[key] = change;
                this.getStocksGroupList(stockData);
                break;


            default:
                stockData.request.filterRequest[key] = change;

                if (key === 'masterGroupID') {
                    if (change === "") {
                        stockData.request.filterRequest[key] = 0;
                    }
                    else {
                        stockData.request.filterRequest[key] = parseFloat(change);
                    }
                }
                stockData.request.pageRequest.currentPage = 1;
                this.getStocksGroupList(stockData);
                this.setState({ stockData });
        }

    }

    openModal(selectedStockGroup) {
        this.setState({
            isOpenModal: true,
            selectedStockGroup: selectedStockGroup
        });
    }

    toggleModal(modaldata) {
        this.setState({ isOpenModal: modaldata });
    }

    getStockGroupHeading({ filterRequest }) {
        let { stockMasterGroupCode, productGroupCode, productCode } = this.state;
        let stockGroupHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="name" name="name"
                    value={filterRequest.name}
                    onChange={(e) => this.handleChange(e.target.value, "name")}
                    placeholder="Search..." /></div>

            },
            {
                key: "code",
                label: "Code",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="code" name="code"
                    value={filterRequest.code}
                    onChange={(e) => this.handleChange(e.target.value, "code")}
                    placeholder="Search..." /></div>

            },
            {
                key: "stockMasterGroupCode",
                label: "Stock Master Group Code",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div> <Input type={"select"} id="stockMasterGroupCode" name={"masterGroupID"}
                    value={filterRequest.masterGroupID}
                    onChange={(e) => this.handleChange(e.target.value, "masterGroupID")}>
                    <option value={""}>All</option>
                    {stockMasterGroupCode.map((option, index) =>
                        <option key={index} value={option.masterGroupID}>{option.code}</option>
                    )}
                </Input>
                </div>
            },
            {
                key: "productGroupCode",
                label: "Product Group Code",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div> <Input type={"select"} id="productGroupCode" name={"productGroupId"}
                    value={filterRequest.productGroupId}
                    onChange={(e) => this.handleChange(e.target.value, "productGroupId")}>
                    <option value={""}>All</option>
                    {productGroupCode.map((option, index) =>
                        <option key={index} value={option.productGroupId}>{option.code}</option>
                    )}
                </Input>
                </div>
            },
            {
                key: "productCode",
                label: "Product Code",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div> <Input type={"select"} id="productCode" name={"productId"}
                    value={filterRequest.productId}
                    onChange={(e) => this.handleChange(e.target.value, "productId")}>
                    <option value={""}>All</option>
                    {productCode.map((option, index) =>
                        <option key={index} value={option.id}>{option.code}</option>
                    )}
                </Input>
                </div>
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center"  
            }

        ];

        return stockGroupHeading;
    }

    exportStockGroupInExcel() {
        let { exportLoading } = this.state;
        exportLoading = true;
        this.setState({ exportLoading });
        this.stockService.exportStockGroupInExcel().then(response => {
            FileSaver.saveAs(response.data, "StockGroupReport.xlsx");
            exportLoading = false;
            this.setState({ exportLoading });
        }).catch(error => {
            exportLoading = false;
            this.setState({ exportLoading });
            toast.error(handleErrorMessage(error));
        })
    }

    refreshAgain() {
        let { stockData } = this.state;
        this.setState({ isOpenModal: false });
        this.getStocksGroupList(stockData);
        this.getStockMasterGroupCode();
        this.getStockProductGroupCode();
        this.getStockProductCode();
    }

    render() {

        let { stockData, loading, searchText, exportLoading, isOpenModal, selectedStockGroup, stockMasterGroupCode, productGroupCode, productCode } = this.state;
        let { pageRequest, sortRequest } = stockData.request;
        let stockGroupHeading = this.getStockGroupHeading(stockData.request);
        return (
            <div>
                <div className={"text-right"}>
                    <Button color={"success"} size={"sm"} className='mb-2' outline={true}
                        onClick={() => this.exportStockGroupInExcel()} disabled={exportLoading}>
                        {
                            exportLoading ?
                                <Spinner size="sm"
                                    className={"mr-2"}
                                    color={"success"} /> :
                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />
                        }Export
                    </Button>
                </div>
                <div>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                            <tr>
                                {(stockGroupHeading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(stockGroupHeading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>

                        </thead>
                        {
                            loading ? <Spinner size="lg"
                                className={"m-2"}
                                color={"primary"} /> :
                                <tbody>
                                    {(stockData.response.records || []).filter((stock) => this.searchFunction(stock, searchText)).map((stock, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>{stock.name}</td>
                                            <td>{stock.code}</td>
                                            <td>{stock.stockMasterGroupCode}</td>
                                            <td>{stock.productGroupCode}</td>
                                            <td>{stock.productCode}</td>
                                            <td className="text-center">
                                                <Button size={"sm"} color={"primary"}
                                                    onClick={() => this.openModal(stock)}>
                                                    <i className="fa fa-pencil mr-2"
                                                        aria-hidden="true" />
                                                    Edit</Button></td>
                                        </tr>
                                    )}

                                </tbody>
                        }
                         
                    </Table>
                </div>

                <CardFooter>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-left"} style={{ maxWidth: 200 }}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Show</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"select"}
                                        name={"pageSize"}
                                        value={pageRequest.pageSize}
                                        disabled={loading}
                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                        <option value={25}>25 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>


                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"float-right"}>
                                <Pagination
                                    activePage={pageRequest.currentPage}
                                    itemsCountPerPage={pageRequest.pageSize}
                                    totalItemsCount={stockData.response.totalRecords}
                                    pageRangeDisplayed={3}
                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'
                                />
                            </div>

                        </Col>
                    </Row>
                </CardFooter>
                {this.state.isOpenModal ?
                    <StockGroupManageModal
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        refreshAgain={this.refreshAgain}
                        selectedStockGroup={selectedStockGroup}
                        stockMasterGroupCode={stockMasterGroupCode}
                        productGroupCode={productGroupCode}
                        productCode={productCode}
                    /> : null
}
            </div>
        );
    }
}
