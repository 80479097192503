import axios from './axios';
import axiosFileDownloader from "./axiosFileDownloader";

class SwishExportService {

    static Instance() {
        return new SwishExportService();
    }

    exportSwishData(url, req) {
        return axiosFileDownloader.post(url, req);
    }
}

export default SwishExportService.Instance();