import React, {Component} from 'react';
import UserService from "../../services/UserService";
import {Col, Card, CardHeader, CardBody,Table } from "reactstrap";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";

export default class KeywayUserStatsCard extends Component {

    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = {loading: true}
    }

    componentDidMount() {
        this.setState({loading: true});
        this.userService.getKeywayUserStats().then(response => {
            this.setState({loading: false, stats: response.data})
        })
    }


    render() {

        const {stats, loading} = this.state;

        if (loading) {
            return null;
        }

        if (!(stats.totalUsers > 0)) {
            return null;
        }

        return (
            <Col xl={4} lg={5} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Keyway Users
                            <span style={{float: "right"}}>
                            <Link to="/users">
                                <i className={"fa fa-pencil"}/>
                            </Link>
                        </span></h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} className={"mb-0"}>
                            <tbody>
                                <tr>
                                    <td>Staff</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.wwUsers}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                                <tr>
                                    <td>Customers</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.customerUsers}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                                <tr>
                                    <td>New signups in last month</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.totalLastMonthSignups}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                                <tr>
                                    <td>Customer logins in last month</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.totalCustomerLogins}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                                <tr>
                                    <td>Customers using PB</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.customerUsingPB}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}