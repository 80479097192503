import React, { Component } from 'react';
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner, Card, CardHeader, CardBody,
    Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import { toast } from "react-toastify";
import { handleErrorMessage, getSorted } from '../../services/CommonService';
import warehouseService from '../../services/WINOInventory/WarehouseService';
import SearchWarehouseLocation from '../../components/search/SearchWarehouseLocation';
import { cloneDeep } from 'lodash';
const warehouseLocationTypesOptions = [
    { locationTypeId: 1, name: "Pick" },
    { locationTypeId: 2, name: "Bulk" }
]
export default class WarehouseTransferDataModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            selectedItem: this.props.selectedItem ? cloneDeep(this.props.selectedItem) : {},
            warehouseLocation: this.props.warehouseLocation,
            formErrors: {},
            warehouseLocationTypes: [],
            savingItem: {},
            prodCode: this.props.prodCode,
            warehouseQuantity: 0,
            selectedBulkLocation: '',
            selectedWarehouseLocation: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.saveTransferQuantity = this.saveTransferQuantity.bind(this);
        this.handleWarehouseLocation = this.handleWarehouseLocation.bind(this);
    }

    componentDidMount() {
        let { selectedItem } = this.state;
        this.handleWarehouseLocationTypes();
        this.setState({ selectedBulkLocation: selectedItem.warehouseLocation ? selectedItem.warehouseLocation.locationID : null });
    }

    handleWarehouseLocationTypes() {
        let { warehouseLocation, warehouseLocationTypes, selectedItem } = this.state;
        console.log('This is Warehouse Type', warehouseLocationTypes, warehouseLocation, selectedItem);
        for (let key in warehouseLocation) {
            if (selectedItem.locationName) {
                if (warehouseLocation[key].locationName != selectedItem.locationName) {
                    warehouseLocationTypes.push({ label: warehouseLocation[key].locationName, value: warehouseLocation[key].locationID });
                }
                this.setState({ warehouseLocationTypes });
            }
        }
    }

    handleChange(change, key) {
        let { warehouseQuantity, selectedWarehouseLocation, selectedItem } = this.state;
        switch (key) {
            case "transferQuantity":
                selectedItem.qoh = change;
                this.setState({ selectedItem });
                break;
            case "warehouseLocation":
                selectedWarehouseLocation = change;
                selectedItem.selectedWarehouseLocation = change;
                break;
            case "warehouseTypes":
                selectedItem.warehouseLocationType = change;
        }
        this.setState({ warehouseQuantity, selectedWarehouseLocation, selectedItem });
    }

    handleWarehouseLocation(change, key) {
        let { warehouseQuantity, selectedWarehouseLocation, selectedItem, prodCode } = this.state;
        selectedItem.locationID = change.value;
        selectedItem.prodCode = prodCode;
        this.setState({ selectedItem });
    }

    validateForm(item) {
        let formErrors = {};
        let valid = true;
        if (!item.warehouseQuantity) {
            formErrors.warehouseQuantity = 'Please enter Warehouse Quantity';
            valid = false;
        }
        if (!item.warehouseLocation) {
            formErrors.warehouseLocation = 'Please Select WarehouseLocation';
            valid = false;
        }
        return { valid: valid, formErrors: formErrors };
    }

    saveTransferQuantity() {
        let { warehouseQuantity, selectedBulkLocation, selectedWarehouseLocation, saving, savingItem, selectedItem } = this.state;
        savingItem = {
            'warehouseQuantity': selectedItem.qoh,
            'warehouseLocation': selectedWarehouseLocation ? selectedWarehouseLocation : "0",
            'prodCode': selectedItem.prodCode,
            'locationID': selectedItem.locationID,
            'stockWarehouseLocationID': selectedItem.stockWarehouseLocationID ? selectedItem.stockWarehouseLocationID : 0,
            'lastUpdatedBy': selectedItem.stockWarehouseLocationID ? selectedItem.lastUpdatedBy : JSON.parse(window.localStorage.getItem('user')).firstName,
            'locationTypeID': selectedItem ? selectedItem.warehouseLocationType : null
        };
        if (selectedItem.stockWarehouseLocationID) {
            let result = this.validateForm(savingItem);
            if (!result.valid) {
                this.setState({ formErrors: result.formErrors });
                return;
            }
        }
        this.setState({ saving: true, formErrors: {} });
        warehouseService.saveTransferQuantity(savingItem).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ saving: false });
                toast.success("Saved successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.refreshAgain();
                this.props.checkReceivedValue(savingItem);
            }
        }).catch(error => {
            this.setState({ saving: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    render() {
        let { isOpen, toggle, warehouseLocation } = this.props;
        let { warehouseLocationTypes, selectedWarehouseLocation, saving, formErrors, selectedItem, prodCode } = this.state;
        console.log('Warehouse Location Type', warehouseLocationTypes);
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {selectedItem.prodCode ? " Edit " : " Add "}  Transfer Quantity
                </ModalHeader>
                <ModalBody className={"m-2"}>
                    <Row>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <Label>Product Code :</Label>
                            <p><input type="text"
                                disabled="true"
                                value={prodCode} /></p>
                        </Col>
                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                            <Label>Location</Label>
                            <SearchWarehouseLocation
                                handleSelection={(e) => this.handleWarehouseLocation(e, "warehouseLocationSearch")}
                                locationID={selectedItem ? selectedItem.locationID : null}
                                defaultSelected={""} />
                        </Col>
                    </Row>
                    {
                        selectedItem.locationID
                            ?
                            <Row className={"d-flex align-center justify-content-between"}>
                                        <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                                            <Label>Transfer Quantity :</Label>
                                            <Input type={"number"}
                                                placeholder="Transfer Quantity"
                                                value={selectedItem ? selectedItem.qoh : null}
                                                onChange={(e) => this.handleChange(e.target.value, "transferQuantity")}
                                            />
                                        </Col>
                                <Col xl={'6'} lg={'6'} md={'8'} sm={'12'} xs={'12'}>
                                    <Label>To :</Label>
                                    <Input type={"select"} id="warehouseLocation" name={"warehouseLocation"}
                                        value={selectedWarehouseLocation}
                                        onChange={(e) => this.handleChange(e.target.value, "warehouseLocation")}>
                                        <option value={''}>Select Location</option>
                                        {warehouseLocationTypes.map((option, index) =>
                                            <option key={index} value={option.value}>{option.label}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.warehouseLocation}</FormText>
                                </Col>
                            </Row>
                            : null
                    } 
                    <br />
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button class={"btn"} size={"sm"} color={"primary"}
                            onClick={this.saveTransferQuantity} disabled={saving}>
                            {saving
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            Save
                        </Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}