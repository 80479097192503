import React, {Component} from 'react';
import {
    Badge,
    Button,
    Spinner,
    Input, InputGroup,
    Card, CardHeader, CardBody,
    Table, FormGroup, FormText,
    Breadcrumb, BreadcrumbItem, Row, Col, InputGroupAddon, InputGroupText, Label,
    UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import Pagination from "react-js-pagination";
import {ToastContainer, toast} from 'react-toastify';
import {findIndex, handleErrorMessage, getColors, stringToColour, materialColor} from '../../services/CommonService';
import queryString from 'query-string';
import {v4 as uuidv4} from 'uuid';
import classnames from 'classnames';
import FabricService from '../../services/FabricService';
import FileSaver from 'file-saver';
import ServiceToPostFiles from '../../services/ServiceToPostFiles/ServiceToPostFiles';
import SearchStock from '../../components/search/SearchStock';
import ProductConfigurationStockModal from '../../components/modal/ProductConfigurationStockModal';
import {Link} from 'react-router-dom';
import SearchFabric from '../../components/search/SearchFabric';
import DuplicateFabricProdCodeModal from '../../components/modal/DuplicateFabricProdCodeModal';
import { cloneDeep, isEmpty } from 'lodash';

const baseUrl = window.location.origin;

export default class FabricColor extends Component {

    constructor(props) {
        super(props);


        this.state = {
            fabricId: 0,
            isOpenStockModal: false,
            loading: {
                save: false,
                fabricColors: false,
                fabric: false,
            },
            selectedStocks: null,
            selectedColor: {},
            defaultStockValue: {},
            fabricColorsObj: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        fabricID: 0,
                        color: '',
                        prodCode: '',
                        status: '',
                        rangeStatus: '',
                        colorDuplicate: false,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            formErrors: {},
            isOpenDuplicateProdCodeModal: false,
            downloading: false
        };

        this.serviceToPostFiles = new ServiceToPostFiles();
        this.addNewItem = this.addNewItem.bind(this);
        this.getFabricColors = this.getFabricColors.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveFabricColors = this.saveFabricColors.bind(this);
        this.onDocumentClickSuccess = this.onDocumentClickSuccess.bind(this);
        this.toggleStockModal = this.toggleStockModal.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.getFabricColorsHeading = this.getFabricColorsHeading.bind(this);
        this.toggleDuplicateProdCodeModal = this.toggleDuplicateProdCodeModal.bind(this);
    }

    componentDidMount() {
        let searchParams = queryString.parse(window.location.search);
        let { fabricColorsObj, fabricId } = this.state;
        fabricColorsObj.request.filterRequest = { ...fabricColorsObj.request.filterRequest, ...searchParams };
        fabricColorsObj.request.pageRequest = { ...fabricColorsObj.request.pageRequest, ...searchParams };
        fabricColorsObj.request.sortRequest = { ...fabricColorsObj.request.sortRequest, ...searchParams };
        this.setState({ fabricColorsObj }, () => this.getFabricColors(fabricId));
    }

    toggleDuplicateProdCodeModal(change) {
        this.setState({isOpenDuplicateProdCodeModal: change});
    }

    getFabricColors(fabricId) {
        let {fabricColorsObj, loading} = this.state;
        loading.fabricColors = true;
        this.setState({loading});
        if (fabricId) {
            fabricColorsObj.request.filterRequest.fabricID = fabricId;
        }
        FabricService.GetFabricColorList(fabricColorsObj.request).then(response => {
            if (response.data) {
                fabricColorsObj.response = response.data;
                loading.fabricColors = false;
                (fabricColorsObj.response.records || []).map((item) => {
                    item.uuid = 'fab' + uuidv4();
                });
                if (fabricColorsObj.request.filterRequest.colorDuplicate) {
                    let colorByProdCode = {};
                    (fabricColorsObj.response.records || []).forEach(fc => {
                        if (!colorByProdCode[fc.prodCode]) {
                            colorByProdCode[fc.prodCode] = materialColor();
                        }
                        fc.rowColor = colorByProdCode[fc.prodCode];
                    });
                    this.setState({fabricColorsObj});
                }
                this.setState({loading, fabricColorsObj});
            }
        }).catch(error => {
            loading.fabricColors = false;
            this.setState({loading});
            toast.info(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleSearchChange(value, key) {
        this.props.setSearchParam(key, value);
        let {fabricColorsObj} = this.state;
        switch (key) {
            case "pageSize":
                fabricColorsObj.request.pageRequest[key] = parseInt(value);
                fabricColorsObj.request.pageRequest.currentPage = 1;
                this.setState({fabricColorsObj}, () => {
                    this.getFabricColors();
                });
                break;
            case "currentPage":
                fabricColorsObj.request.pageRequest[key] = value;
                this.setState({fabricColorsObj}, () => {
                    this.getFabricColors();
                });
                break;
            case "sortKey":
                if (fabricColorsObj.request.sortRequest.key === value) {
                    fabricColorsObj.request.sortRequest.direction = !fabricColorsObj.request.sortRequest.direction;
                    this.props.setSearchParam("direction", fabricColorsObj.request.sortRequest.direction);
                }
                else {
                    fabricColorsObj.request.sortRequest.direction = false;
                    fabricColorsObj.request.sortRequest.key = value;
                }
                this.setState({fabricColorsObj}, () => {
                    this.getFabricColors();
                });
                break;
            case "fabricID":
                if (!value) {
                    fabricColorsObj.request.filterRequest[key] = 0;
                }
                else
                {
                    fabricColorsObj.request.filterRequest[key] = value;

                }

                this.setState({fabricColorsObj}, () => {
                    this.getFabricColors();
                });
                break;
            case "colorDuplicate":
                if (value) {
                    fabricColorsObj.request.sortRequest.direction = true;
                    fabricColorsObj.request.sortRequest.key = "prodCode";
                }
                fabricColorsObj.request.filterRequest[key] = value;
                this.setState({fabricColorsObj}, () => {
                    this.getFabricColors();
                });
                break;
            default:
                fabricColorsObj.request.filterRequest[key] = value;
                this.setState({fabricColorsObj}, () => {
                    this.getFabricColors();
                });
        }
    }

    handleChange(value, key, uniqueUUID) {
        let { fabricColorsObj } = this.state;
        let index = -1;
        index = findIndex(fabricColorsObj.response.records, 'uuid', uniqueUUID);
        switch (key) {
            default:
                if (index > -1) {
                    fabricColorsObj.response.records[index][key] = value;
                    if (key === 'stock') {
                        fabricColorsObj.response.records[index]['prodCode'] = value ? value.prodCode : '';
                    }
                    this.setState({ activeItem: fabricColorsObj.response.records[index], fabricColorsObj });
                }
                break;
        }
    }

    deleteFabricColor(item) {
        let { fabricColorsObj } = this.state;
        let index = findIndex(fabricColorsObj.response.records, 'uuid', item.uuid);
        if (index > -1) {
            fabricColorsObj.response.records[index].delete = true;
            this.setState({ fabricColorsObj });
        }
        FabricService.deleteFabricColor(item.id).then(res => {
            if (res.status === 200 || res.status === '200') {
                toast.success("Deleted");
                this.refreshAgain();
            }
        }).catch(error => {
            fabricColorsObj.response.records[index].delete = false;
            this.setState({ fabricColorsObj });
            toast.info(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    validate(item) {
        let formErrors = {}, isValid = true;
        formErrors.uuid = item.uuid;
        if (!item.color) {
            formErrors.color = "please enter color name";
            isValid = false;
        }
        if (!item.prodCode) {
            formErrors.prodCode = "please enter prodCode";
            isValid = false;
        }
        return {isValid: isValid, formErrors: formErrors};
    }

    saveFabricColors(item) {
        let {fabricColors, fabricColorsObj} = this.state;
        let result = this.validate(item);
        if (!result.isValid) {
            this.setState({formErrors: result.formErrors, saving: false});
            return false;
        }
        else {
            let index = findIndex(fabricColorsObj.response.records, 'uuid', item.uuid);
            if (index > -1) {
                fabricColorsObj.response.records[index].saving = true;
            }

            this.setState({fabricColorsObj});
            const data = new FormData();
            data.append('ID', item.id);
            data.append('FabricID', item.fabricID);
            data.append('Color', item.color);
            data.append('file', item.fabricImageFile);
            if (item.prodCode) {
                data.append('ProdCode', item.prodCode);
            }

            this.serviceToPostFiles.SaveFabricColors(data).then(response => {
                if (response.status === 200 || response.status === '200') {
                    if (item.id === 0) {
                        toast.success("Saved!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        toast.success("Updated!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    fabricColorsObj.response.records[index].saving = false;
                    fabricColorsObj.response.records[index] = response.data;
                    this.setState({fabricColors, fabricColorsObj});
                }
            }).catch(error => {
                fabricColorsObj.response.records[index].saving = false;
                this.setState({fabricColorsObj});
                toast.info(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
        }
    }


    onDocumentClickSuccess(item) {
        FabricService.getFabricColorByName(item.fabricColorImageAttachment.fileName).then(response => {
            FileSaver.saveAs(response.data, item.fabricColorImageAttachment.description);
        }).catch(error => {
            console.log(error.data)
        });
    }

    addNewItem() {
        let {fabricColorsObj, fabricId} = this.state;

        let item = {
            id: 0,
            stock: {},
            stockID: 0,
            prodCode: '',
            fabricID: fabricId ? fabricId : 0,
            color: "",
            uuid: 'fab' + uuidv4()
        };
        fabricColorsObj.response.records.push(item);
        this.setState({fabricColorsObj});
    }

    toggleStockModal(data, item) {
        this.state.selectedStocks = [];
        if (item.productConfigurationStock) {
            this.state.selectedStocks.push(item.productConfigurationStock);
            this.setState({selectedStocks: this.state.selectedStocks})
        }
        this.setState({isOpenStockModal: data, selectedColor: item});
    }

    refreshAgain() {
        let searchParams = queryString.parse(this.props.location?.search);
        this.getFabricColors(searchParams.fabricId);
    }

    getFabricColorsHeading({filterRequest}) {
        let {isOpenStockModal, formErrors} = this.state;
        let heading = [
            {
                key: "fabricName",
                label: "Fabric Range",
                type: "text",
                colSpan: 1,
                minWidth: 145,
                sortApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    if (value) {
                        return <span>{value}</span>;
                    }
                    return <SearchFabric
                        selectedFabricID={record.fabricID}
                        handleSelection={(selection) => ref.handleChange(selection ? selection.id : "", "fabricID", record.uuid)}
                    />
                }

            },
            {
                key: "color",
                label: "Colour",
                type: "text",
                colSpan: 1,
                minWidth: 250,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div>
                        <Input
                            type={"text"}
                            name={"color"}
                            placeholder={"write fabric-color here"}
                            value={value}
                            onChange={(e) => ref.handleChange(e.target.value, e.target.name, record.uuid)}
                        />
                        <FormText color="danger">{formErrors.uuid === record.uuid ? formErrors.color : ""}</FormText>
                    </div>
                }
            },
            {
                key: "prodCode",
                label: "Prodcode",
                type: "text",
                colSpan: 1,
                minWidth: 175,
                sortApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div>
                        <SearchStock
                            disabled={false}
                            handleSelection={(selection) => ref.handleChange(selection, "stock", record.uuid)}
                            selected={record.stock ? record.stock.prodCode : ""}
                            defaultSelected={""}
                            filter={{bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                        />
                        <FormText color="danger">{formErrors.uuid === record.uuid ? formErrors.prodCode : ""}</FormText>
                    </div>
                }

            },
            {
                key: "linearWidth",
                label: "Linear Width",
                type: "text",
                colSpan: 1,
                minWidth: 90,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{record.stock && record.stock.linearWidth}</span>;
                }

            },
            {
                key: "canBeRailRoaded",
                label: "Rail Road",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    if (value) {
                        return <span>{value}</span>;
                    }
                }

            },
            {
                key: "qoh",
                label: "QOH",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{record.stock && record.stock.qoh + " " + (record.stock.qoh > 1 ? "metres" : "metre")}</span>;
                }
            },

            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{record.stock && record.stock.soldOut ?
                        <Badge color="warning">Sold Out</Badge> : (record.stock && record.stock.discontinued ?
                            <Badge color="danger">Discontinued</Badge> : <Badge color="success">Active</Badge>)}</span>
                }

            },
            {
                key: "rangeStatus",
                label: "Range Status",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <span>{record.stock && record.stock.keywayStock ? (record.stock.keywayStock.isRange ? "In Range" : "Off Range") : "N/A"}</span>;
                }

            },
            {
                key: "action",
                label: "Action",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div><Button color={"primary"}
                                        size={"sm"}
                                        className={"mr-2"}
                                        disabled={record.saving}
                                        onClick={() => ref.saveFabricColors(record)}>
                        {record.saving ?
                            <Spinner size="sm" className="mr-2"
                                     style={{color: "white"}}/> :
                            <i className="fa fa-floppy-o"
                               aria-hidden="true"/>}
                    </Button>
                        <Button color={'danger'} size='sm'
                                onClick={() => ref.deleteFabricColor(record)}
                                disabled={record.delete}>
                            {record.delete ?
                                <Spinner size="sm" style={{color: "white"}}/> :
                                <i className='fa fa-trash' aria-hidden="true"/>}
                        </Button>
                    </div>
                }

            },
            {
                key: "formula",
                label: "Config.",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-center",
                searchNode: null,
                render: function (value, record, recordIndex, ref) {
                    return <div><Button
                        color="link"
                        size={"sm"}
                        className={"m-1"}
                        onClick={() => ref.toggleStockModal(!isOpenStockModal, record)}
                    ><i className="fa fa-cogs mr-2" aria-hidden="true"/>
                    </Button>

                        {(record.id && !record.productConfigurationStock) ?
                            <i className="fa fa-exclamation-triangle mr-2"
                               style={{color: '#dc3545'}}
                               title={'No configuration formula added'}
                               aria-hidden="true"/> : null}
                    </div>
                }

            },
        ];

        return heading;
    }

    printNotesInExcel(key) {
        let { fabricColorsObj } = this.state;
        let request = cloneDeep(this.state.fabricColorsObj.request);
        request.filterRequest = {
            fabricID: 0,
            color: '',
            prodCode: '',
            status: '',
            rangeStatus: '',
            colorDuplicate: false,

        };
        if (key === "All") {
            this.setState({ downloading: true });
            FabricService.generateFabricColorExcelReport(request).then(response => {
                this.setState({ downloading: false });
                FileSaver.saveAs(response.data, "Fabric Report" + ".xlsx");
            }).catch(error => {
                this.setState({ downloading: false });
                toast.error(handleErrorMessage(error));
            });
        }
        if (key === "Filter") {
            this.setState({ downloading: true });
            FabricService.generateFabricColorExcelReport(fabricColorsObj.request).then(response => {
                this.setState({ downloading: false });
                FileSaver.saveAs(response.data, "Fabric Report" + ".xlsx");
            }).catch(error => {
                this.setState({ downloading: false });
                toast.error(handleErrorMessage(error));
            });
        }
    }

    render() {
        let { loading, isOpenStockModal, fabricColorsObj, duplicateFabricProdCodes, isOpenDuplicateProdCodeModal, downloading} = this.state;
        let {request, response} = fabricColorsObj;
        let {pageRequest} = request;
        let store = this.getFabricColorsHeading(request.filterRequest);
        return (
            <div>
                <div>
                   <div className="mt-2">
                       <Row>
                           <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                               <Label>Fabric Range</Label>
                               <SearchFabric
                                   selectedFabricID={request.filterRequest.fabricID}
                                   handleSelection={(selection) => this.handleSearchChange(selection ? selection.id : "", "fabricID")}
                               />
                           </Col>
                           <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                               <Label> Colour </Label>
                               <div className={"text-right"}>
                                   <Input type="search" id="color" name="color"
                                          value={request.filterRequest.color}
                                          onChange={(e) => this.handleSearchChange(e.target.value, e.target.name)}
                                          placeholder="Search..."/>
                               </div>
                           </Col>
                           <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                               <Label> ProdCode</Label>
                               <div className={"text-right"}>
                                   <Input type="search" id="prodCode" name="prodCode"
                                          value={request.filterRequest.prodCode}
                                          onChange={(e) => this.handleSearchChange(e.target.value, e.target.name)}
                                          placeholder="Search..."/>
                               </div>
                           </Col>
                           <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                               <Label>Status</Label>
                               <div className={"text-right"}>
                                   <Input type="select" id="status" name="status"
                                          value={request.filterRequest.status}
                                          onChange={(e) => this.handleSearchChange(e.target.value, e.target.name)}
                                          placeholder="Search...">
                                       <option value={""}>All</option>
                                       <option value={"active"}>Active</option>
                                       <option value={"discontinued"}>Discontinued</option>
                                       <option value={"soldOut"}>Sold Out</option>
                                   </Input>
                               </div>
                           </Col>
                       </Row>
                       <Row className={"mt-2"}>
                           <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                               <Label>Range Status</Label>
                               <div className={"text-right"}>
                                   <Input type="select" id="rangeStatus" name="rangeStatus"
                                          value={request.filterRequest.rangeStatus}
                                          onChange={(e) => this.handleSearchChange(e.target.value, e.target.name)}>
                                       <option value={""}>All</option>
                                       <option value={"inRange"}>In Range</option>
                                       <option value={"offRange"}>Off Range</option>
                                   </Input>
                               </div>
                           </Col>
                           <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                               <div className='mt-3'>
                                   <Label className='mt-1'>
                                       <Button color={"link"}
                                               size={"sm"}
                                               onClick={() => this.handleSearchChange(!request.filterRequest.colorDuplicate, "colorDuplicate")}>
                                           {
                                               request.filterRequest.colorDuplicate
                                                   ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true"/>
                                                   : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                                           }
                                       </Button>
                                       Duplicate Fabric Colours
                                   </Label>
                               </div>
                           </Col>
                       </Row>
                   </div>

                    {loading.fabricColors
                        ? <Spinner color="primary"/>
                        : <div>
                            <div className="d-flex align-items-center justify-content-between">
                                <Col xl='4' lg='4' md='6' sm='12' xs='12' className="px-0">
                                    <div className="text-left">
                                        Showing{' '}
                                        {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                        {' '}of {response.totalRecords}
                                        {' '}entries
                                    </div>
                                </Col>
                                <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                                    <div className="text-right p-2">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle caret color={"primary"} size='sm'
                                                            outline={true}>
                                                <i className="fa fa-upload mr-2" aria-hidden="true" />
                                                Import
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <Link to={{
                                                    pathname: '/tools/manage/fabrics/import'}}>
                                                    <DropdownItem title={'Click here to import all data'}>
                                                        <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> All Data
                                                    </DropdownItem>
                                                </Link>
                                                <Link to={{
                                                    pathname: '/tools/manage/fabrics/one/colors/import'}}>
                                                    <DropdownItem title={'Click here to import colour'}>
                                                        <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> Colour
                                                    </DropdownItem>
                                                </Link>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className={'ml-1'}>
                                            <DropdownToggle caret color={"success"} size='sm' disabled={downloading}
                                                            outline={true}>
                                                {downloading
                                                    ? <Spinner size="sm"
                                                               className={"mr-2"}
                                                               style={{ color: "green" }} />
                                                    : <i className="fa fa-download mr-2" aria-hidden="true" />}
                                                Export
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem title={'Click here to export all data'}
                                                              onClick={() => this.printNotesInExcel("All")}>
                                                    <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> All Fabric
                                                </DropdownItem>
                                                <DropdownItem title={'Click here to export filtered data'}
                                                              onClick={() => this.printNotesInExcel("Filter")}>
                                                    <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> Filtered
                                                    Fabric
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>

                            </div>
                            <Table responsive={true} striped bordered style={{minHeight: '250px'}}>
                                <thead>
                                <tr>
                                    <th className={"align-middle"} style={{minWidth: 25}}> S.No.</th>
                                    {(store || []).map((column, index) => {
                                        return (
                                            <th key={index}
                                                onClick={(e) => column.sortApplicable && this.handleSearchChange(column.key, "sortKey", '')}
                                                colSpan={column.colSpan}
                                                className={column.labelClassName}
                                                style={{minWidth: column.minWidth, width: column.width}}>
                                                {column.label}
                                                {
                                                    column.sortApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (request.sortRequest.key !== column.key),
                                                            "fa-sort-amount-asc": (request.sortRequest.key === column.key && request.sortRequest.direction),
                                                            "fa-sort-amount-desc": (request.sortRequest.key === column.key && !request.sortRequest.direction)
                                                        })} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {(response.records || []).map((row, rowIndex) => {
                                    return (<tr key={rowIndex} className={"align-middle"}
                                                style={{background: row.rowColor}}>
                                        <td className={"text-center align-middle"}>
                                            {((request.pageRequest.currentPage - 1) * request.pageRequest.pageSize) + (rowIndex + 1)}
                                        </td>
                                        {(store || []).map((column, columnIndex) => {
                                            return (
                                                <td key={columnIndex} className={column.valueClassName}>
                                                    {column.render(row[column.key], row, rowIndex, this)}
                                                </td>
                                            );
                                        })}
                                    </tr>);
                                })}
                                </tbody>
                            </Table>
                            <Row>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"} style={{maxWidth: 200}}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Show</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"select"}
                                                name={"pageSize"}
                                                value={pageRequest.pageSize}
                                                disabled={loading.fabricColors}
                                                onChange={(e) => this.handleSearchChange(e.target.value, "pageSize", '')}>
                                                <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                                <option value={50}>50 Rows</option>
                                                <option value={100}>100 Rows</option>
                                                <option value={500}>500 Rows</option>
                                            </Input>
                                        </InputGroup>


                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"float-right"}>
                                        <Pagination
                                            activePage={pageRequest.currentPage}
                                            itemsCountPerPage={pageRequest.pageSize}
                                            totalItemsCount={response.totalRecords}
                                            pageRangeDisplayed={3}
                                            onChange={(activePage) => this.handleSearchChange(activePage, "currentPage", '')}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                            innerClass='pagination'
                                            activeLinkClass='active'
                                        />
                                    </div>

                                </Col>
                            </Row>
                            <Button color={"primary"} onClick={this.addNewItem} size='sm'>
                                <i className='fa fa-plus mr-2'/> Add</Button>
                        </div>
                    }
                </div>
                <ProductConfigurationStockModal
                    isOpen={isOpenStockModal}
                    toggle={this.toggleStockModal}
                    items={this.state.selectedStocks !== null ? this.state.selectedStocks : [{}]}
                    handleRefresh={this.refreshAgain}
                    maxStocksAllowed={1}
                    fabricColorID={this.state.selectedColor.id}
                    defaultItem={this.state.defaultStockValue}
                />

                {isOpenDuplicateProdCodeModal ?
                    <DuplicateFabricProdCodeModal
                        isOpen={isOpenDuplicateProdCodeModal}
                        toggle={this.toggleDuplicateProdCodeModal}
                        records={duplicateFabricProdCodes}/>
                    : null}
                <ToastContainer/>
            </div>
        );
    }
}