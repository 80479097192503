import React, {Component} from 'react';
import {Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col} from 'reactstrap';
import dispatchOrderService from '../../services/DispatchOrderService';
import classnames from 'classnames';
import {handleErrorMessage, changeFormatOfDateString} from '../../services/CommonService';
import {toast} from 'react-toastify';

export default class ShowShipmentStatusLogDetailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shipment: this.props.shipment,
            shipmentStatusLog: []
        };
        this.getShipmentStatusLog = this.getShipmentStatusLog.bind(this);
    }

    componentDidMount() {
        this.getShipmentStatusLog();
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.shipment !== nextProps.shipment) {
            this.setState({shipment: nextProps.shipment}, () => {
                this.getShipmentStatusLog();
            })
        }
    }

    getShipmentStatusLog() {
        let {shipment} = this.state;
        dispatchOrderService.getShipmentStatusLog(shipment.shipmentID).then(response => {
            let shipmentStatusLog = response.data;
            this.getFontIconBasedOnStatus(shipmentStatusLog);
            this.setState({shipmentStatusLog});
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    getFontIconBasedOnStatus(shipmentStatusLog) {
        (shipmentStatusLog || []).forEach(item => {
            switch (item.statusCd) {
                case "SHI":
                    item.iconFont = 'fa-gift';
                    break;
                case "CON":
                    item.iconFont = 'fa-gift';
                    break;
                case "MAN":
                    item.iconFont = 'fa-gift';
                    break;
                case "PKD":
                    item.iconFont = 'fa-truck';
                    break;
                case "TRS":
                    item.iconFont = 'fa-truck';
                    break;
                case "OFD":
                    item.iconFont = 'fa-truck';
                    break;
                case "DLV":
                    item.iconFont = 'fa-truck';
                    break;
            }
        });
        return shipmentStatusLog;
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {shipment, shipmentStatusLog} = this.state;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    #{shipment.consignNum} Tracking Status Log
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            {(shipmentStatusLog || []).map((item, index) => {
                                return (<Row>
                                        <Table>
                                            <tbody>
                                            <tr>
                                                <td className={'align-middle'} style={{'minWidth': '200px'}}>  <span
                                                    className="fa fa-stack fa-2x">
                                                <i className={classnames("fa fa-circle fa-stack-2x text-success")}></i>
                                                <i className={classnames("fa", "fa-solid fa-stack-1x fa-inverse", item.iconFont)}></i>
                                            </span>
                                                    <i className="fa fa-vertical-bar-light"/>
                                                    {item.statusDescription}</td>
                                                <td className={'align-middle text-center'}
                                                    style={{'minWidth': '100px'}}>{item.note}</td>
                                                <td className={'align-middle text-center'}
                                                    style={{'minWidth': '100px'}}>
                                                    {changeFormatOfDateString(item.statusChangeDate, "DD/MM/YYYY", 'DD MMM YYYY')}</td>
                                                <td className={'align-middle text-center'}
                                                    style={{'minWidth': '100px'}}>{item.statusChangeTime}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                )
                            })
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}><i
                            className="fa fa-times"/>&nbsp;
                            Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}