import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";

export default function OrderEnquirySupplierDetails(props) {
    let { order } = props;
    return <div>
        <ListGroup className={"list-group-horizontal-sm"}>
            <ListGroupItem>
                <div style={{ minWidth: 150 }}>
                    <p className={"pb-1 mb-1"}><strong>Supplier</strong></p>
                    <div>
                        <Link
                            className="btn btn-primary btn-sm"
                            style={{ color: "white" }}
                            title={"Click here to see account details"}
                            to={"/supplier/#" + order.accountID}
                        >{order.accountID}</Link>
                    </div>
                    <div><h6>{order.company}</h6></div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div style={{ minWidth: 150 }}>
                    <p className={"pb-1 mb-1"}>
                        <strong>Address</strong>
                    </p>
                    <a
                        href={"http://maps.google.com/?q=" + order.shippingAdrress}
                        target="_blank">
                        <div>
                            {order.address1}
                        </div>
                        <div>
                            {order.address2}
                        </div>
                        <div>
                            {order.city}
                        </div>
                        <div>
                            {order.state}{' '}{order.postCode}
                        </div>
                    </a>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div style={{ minWidth: 150 }}>
                    <p className={"pb-1 mb-1"}>
                        <strong>Contact</strong>
                    </p>
                    {order.contactName
                        ? <p className={"pb-0"}>{order.contactName}</p>
                        : null
                    }
                    <div title={"Phone"}>
                        {
                            order.phone
                                ?
                                <a href={"tel:" + order.phone}> <i
                                    className="text-muted fa fa-phone fa-fw mr-1"
                                    aria-hidden="true" />{order.phone}</a>
                                : null
                        }
                    </div>
                    <div title={"Fax"}>
                        {
                            order.fax
                                ?
                                <a href={"fax:" + order.fax}> <i
                                    className="text-muted fa fa-fax fa-fw mr-1"
                                    aria-hidden="true" />{order.fax}</a>
                                : null
                        }
                    </div>
                    <div title={"Email"}>
                        {
                            order.email
                                ?
                                <a href={"mailto:" + order.email}> <i
                                    className="text-muted fa fa-envelope fa-fw mr-1"
                                    aria-hidden="true" />{order.email}</a>
                                : null
                        }
                    </div>
                </div>
            </ListGroupItem>
        </ListGroup>
    </div>

}