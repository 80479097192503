import React, {Component} from 'react';
import {Col, Input, Label, Row} from 'reactstrap';
import courierService from '../../services/shipment/CourierService';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import {handleErrorMessage} from '../../services/CommonService';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

export default class SearchBannerDispatchOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            couriers: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.getCouriers = this.getCouriers.bind(this);
    }

    componentDidMount() {
        this.getCouriers();
    }

    getCouriers() {
        courierService.getCouriers().then(response => {
            let couriers = response.data;
            this.setState({couriers});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    handleChange(change, key) {
        this.props.handleChange(change, key);
    }

    render() {
        let { filterRequest, type } = this.props;
        return (
            <div>
                <Row>
                    {type === "archived" && (
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="d-flex flex-column">
                                    <Label>
                                        <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date
                                    </Label>
                                    <div>
                                        <DatePicker
                                            className={"form-control"}
                                            selected={filterRequest.startDate}
                                            onChange={(date) => this.handleChange(date, "startDate")}
                                            selectsStart
                                            startDate={filterRequest.startDate}
                                            endDate={filterRequest.endDate}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            placeholderText="Start Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dropdownMode="select"
                                            withPortal
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="d-flex flex-column">
                                    <Label>
                                        <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date
                                    </Label>
                                    <div className={"text-right"}>
                                        <DatePicker
                                            className={"form-control"}
                                            selected={filterRequest.endDate}
                                            onChange={(date) => this.handleChange(date, "endDate")}
                                            selectsEnd
                                            startDate={filterRequest.startDate}
                                            endDate={filterRequest.endDate}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            placeholderText="End Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dropdownMode="select"
                                            withPortal
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Order No</Label>
                        <div className={"text-center"}>
                            <Input type={"search"}
                                   placeholder={"Search..."}
                                   name={"ordNum"}
                                   value={filterRequest.ordNum}
                                   onChange={(e) => this.props.handleChange(e.target.value, "ordNum")}/>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Label>Account</Label>
                        <SearchDebtorAcccount
                            handleAccountChange={(selectedAccountID) => this.handleChange(selectedAccountID, "accountID")}
                            selectedAccountID={filterRequest.accountID}
                            defaultAccountID={filterRequest.accountID}
                        />
                    </Col>
                    {
                        type === "overview" || type === "archived" ?

                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label>Delivery method{type==="overview" ? "(Pref.)" : null}</Label>
                                <div>
                                    <Input type="select" name="deliveryMethod" value={filterRequest.deliveryMethod}
                                        onChange={(e) => this.handleChange(e.target.value, "deliveryMethod")}>
                                        <option value="">Select</option>
                                        {this.state.couriers.map((data, index) =>
                                            <option key={index} value={data.courier}> {data.courier}</option>
                                        )}
                                    </Input>
                                </div>
                            </Col>
                            : null
                    }
                    {/*{*/}
                    {/*    type === "overview"*/}
                    {/*        ? <Col xl={2} lg={3} md={4} sm={12} xs={12}>*/}
                    {/*            <Label>Invoiced</Label>*/}
                    {/*            <div>*/}
                    {/*                <Input type="select" name="isInvoiced" value={filterRequest.isInvoiced}*/}
                    {/*                       onChange={(e) => this.handleChange(e.target.value, "isInvoiced")}>*/}
                    {/*                    <option value={""}>Select</option>*/}
                    {/*                    <option value={true}> Yes</option>*/}
                    {/*                    <option value={false}> No</option>*/}
                    {/*                </Input>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        : null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    type === "overview"*/}
                    {/*        ? <Col xl={2} lg={3} md={4} sm={12} xs={12}>*/}
                    {/*            <Label>Shipped</Label>*/}
                    {/*            <div>*/}
                    {/*                <Input type="select" name="isShipped" value={filterRequest.isShipped}*/}
                    {/*                       onChange={(e) => this.handleChange(e.target.value, "isShipped")}>*/}
                    {/*                    <option value={""}>Select</option>*/}
                    {/*                    <option value={true}> Yes</option>*/}
                    {/*                    <option value={false}> No</option>*/}
                    {/*                </Input>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        : null*/}
                    {/*}*/}
                </Row>
            </div>
        );
    }
}
