import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Row, Col, Label, FormGroup, Input, Spinner, Table,
} from 'reactstrap';
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage } from "../../services/CommonService";
import dispatchOrderService from "../../services/DispatchOrderService";
import courierService from "../../services/shipment/CourierService";


export default class ChangeCourierModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingSubmit: false,
            data: [],
            couriers: [],
            ordNum: '',
            isLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateCourier = this.updateCourier.bind(this);
        this.getCourierDetails = this.getCourierDetails.bind(this);
        this.getCouriers = this.getCouriers.bind(this);
    }

    componentDidMount() {
        let { ordNum } = this.props;
        this.setState({ ordNum }, () => {
            this.getCourierDetails();
            this.getCouriers();
        });
    }

    getCourierDetails() {
        let { ordNum, data } = this.state;
        this.setState({ isLoading: true });
        dispatchOrderService.getShippingDetails(ordNum).then(response => {
            data = response.data;
            this.setState({ data, isLoading: false });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ isLoading: false }, () => this.props.toggle(!this.props.isOpen));
        });
    }

    getCouriers() {
        courierService.getCouriers().then(response => {
            let couriers = response.data;
            this.setState({ couriers });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    handleChange(key, change) {
        let { data } = this.state;
        switch (key) {
            default:
                data[key] = change;
        }
        this.setState({ data });
    }

    updateCourier() {
        let { data } = this.state;
        let { isOpen, toggle } = this.props;
        let request = {
            ordNum: data.ordNum,
            shipDate: data.shipDate,
            consignNum: data.consignNum,
            couriers: data.couriers,
            cartons: data.cartons
        }
        this.setState({ isLoadingSubmit: true });
        dispatchOrderService.updateDispatchOrderShippingDetails(request).then(response => {
            toast.success('Updated');
            this.setState({ isLoadingSubmit: false });
            toggle(!isOpen);
            this.props.refreshAgain();
        }).catch(error => {
            toast.error({ error });
            this.setState({ isLoadingSubmit: false });
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { isLoadingSubmit, data, couriers } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Change Delivery Method
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <tbody>
                            <tr>
                                <th className="align-middle">
                                    Order Number
                                </th>
                                <td className="align-middle">
                                    <Input type="text" name="ordNum" value={data.ordNum}
                                        disabled={true}>
                                    </Input>
                                </td>
                            </tr>
                            <tr>
                                <th className="align-middle">
                                    Delivery Method
                                </th>
                                <td className="align-middle">
                                    <Input type="select"
                                        name="courier"
                                        value={data.couriers}
                                        onChange={(e) => this.handleChange("couriers", e.target.value)}>
                                        <option value="" disabled>Select courier</option>
                                        {(couriers || []).map((item, index) =>
                                            <option key={index} value={item.courier}> {item.courier}</option>
                                        )}
                                    </Input>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <div className="text-right">
                            <Button color={"primary"} size='sm' className={"mr-2"}
                                disabled={isLoadingSubmit}
                                onClick={() => this.updateCourier()}>
                                {isLoadingSubmit
                                    ? <Spinner className={"mr-2"} size={"sm"} />
                                    : <i className="fa fa-paper-plane mr-2" />
                                }
                                {isLoadingSubmit ? "Updating Courier" : "Update Courier"}
                            </Button>
                            <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}>
                                <i className="fa fa-times mr-2" />
                                Cancel
                            </Button>
                        </div>
                    </Row>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}