import React, {Component} from "react";
import {
    Modal, ModalBody, ModalHeader, ModalFooter, Button, Table, UncontrolledPopover, PopoverBody,
} from 'reactstrap';
import NumberFormat from "react-number-format";
import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../services/sales/SalesOrderProductBuilderV1Service';

export default class SalesOrderProductBuilderOrderSummaryDetail extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {order, isOpen, toggle, discountByProductCode, products} = this.props;
        let appliedDiscountProducts = products.filter(p => (!isEmpty(p.items) && p.pricing.discVal > 0));
        let appliedMarkupProducts = products.filter(p => (!isEmpty(p.items) && p.pricing.retailMarkup > 0));
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Order Summary Breakup
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <thead>
                        <th>Pricing</th>
                        <th className="text-right align-middle">Cost</th>
                        <th className="text-right align-middle">Retail Margin</th>
                        <th className="text-right align-middle">Selling Price</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Sub-total
                                {(appliedMarkupProducts || []).map(p => {
                                    return <div key={p.code}>
                                        <small>
                                            <i className="fa fa-caret-right mr-2"
                                               aria-hidden="true"/>
                                        </small>
                                        {p.name}&nbsp;
                                        @{p.pricing.retailMarkup}%
                                    </div>
                                })
                                }
                                <div className={"mt-1"}>
                                    Total
                                </div>
                            </td>
                            <td className="text-right align-middle">
                                {(appliedMarkupProducts || []).map(p => {
                                    return <div key={p.code}>
                                        <span>-</span>
                                    </div>
                                })}
                                <div className={"mt-1 border-top"}>
                                    <NumberFormat
                                        prefix={'$'}
                                        value={order.pricing.price}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}/>
                                </div>
                            </td>
                            <td className="text-right align-middle">
                                {(appliedMarkupProducts || []).map(p => {
                                    return <div key={p.code}>
                                        <NumberFormat
                                            prefix={'$'}
                                            value={p.pricing.retailMarkupValue}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </div>
                                })}
                                <div className={"border-top mt-1"}>
                                    <NumberFormat
                                        prefix={'$'}
                                        value={order.pricing.retailMarkupValue}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}/>
                                </div>
                            </td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.price + order.pricing.retailMarkupValue}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Discount
                                {/*<a href="javascript:void(0)">
                                    <i id="UncontrolledPopover"
                                       className="fa fa-info-circle ml-2"
                                       aria-hidden="true"/>
                                </a>
                                <UncontrolledPopover placement="auto"
                                                     target="UncontrolledPopover"
                                                     trigger="legacy">
                                    <PopoverBody className={"text-info"}>The
                                        discounted amount here may
                                        differ from the actual discount
                                        calculation, as some of the product's
                                        components can't be
                                        discounted.</PopoverBody>
                                </UncontrolledPopover>
                                {(appliedDiscountProducts || []).map(p => {
                                    return <div key={p.code}>
                                        <small>
                                            <i className="fa fa-caret-right mr-2"
                                               aria-hidden="true"/>
                                        </small>
                                        {p.name}&nbsp;
                                        @{discountByProductCode[p.code].discount}%
                                    </div>
                                })
                                }
                                <div className={"mt-1"}>
                                    Total
                                </div>*/}
                            </td>
                            <td className="text-right">
                               {/* <div className={"mt-4"}>
                                    {(appliedDiscountProducts || []).map(p => {
                                        return <div key={p.code}>
                                            <NumberFormat
                                                prefix={'$'}
                                                value={p.pricing.discVal}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </div>
                                    })}
                                    <div className={"border-top mt-1"}>
                                        <NumberFormat
                                            prefix={'$'}
                                            value={order.pricing.discVal}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </div>
                                </div>*/}
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.discVal}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                            <td className="text-right align-middle">
                               {/* <div className={"mt-4"}>
                                    {(appliedDiscountProducts || []).map(p => {
                                        return <div key={p.code}>
                                            <span>-</span>
                                        </div>
                                    })}
                                </div>
                                <div className={"mt-1 border-top"}>
                                    <NumberFormat
                                        prefix={'$'}
                                        value={0}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}/>
                                </div>*/}
                                <NumberFormat
                                    prefix={'$'}
                                    value={0}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.discVal}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Package & Handling Charge</td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.packagingAndHandlingCharges}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={0}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.packagingAndHandlingCharges}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>GST</td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.tax}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.retailMarkupValueTax}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                            <td className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.tax + order.pricing.retailMarkupValueTax}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <th className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.total}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </th>
                            <th className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.retailMarkupValue + order.pricing.retailMarkupValueTax}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </th>
                            <th className="text-right align-middle">
                                <NumberFormat
                                    prefix={'$'}
                                    value={order.pricing.total + order.pricing.retailMarkupValue + order.pricing.retailMarkupValueTax}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                            </th>
                        </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right align-middle">
                        <Button color={"primary"} size="sm"
                                onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2"/>
                            Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}