import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Nav, NavItem, NavLink
} from 'reactstrap';
import StockInventoryCostLandedService from '../../services/StockInventoryCostLandedService';
import StockInventoryMainUnitView from '../../components/WINOInventory/StockInventoryMainUnitView';
import {ToastContainer, toast} from 'react-toastify';
import queryString from 'query-string';
import {isEmpty} from 'lodash';
import classnames from "classnames";
import CurrencyService from "../../services/CurrencyService";
import {handleErrorMessage} from "../../services/CommonService";

export default class ManageStockInventoryCostLandedTab extends Component {

    constructor(props) {
        super(props);
         this.state = {
            stockInventoryCostLandedObj: {
                id: 0,
                freightCost: 0.00,
                supplierCost: 0.00,
                packagingCost: 0.00,
                dutyCost: 0.00,
                otherCosts: 0.00,
                dutyRate: 0.00
            },
            formErrors: {},
            saving: false,
            loading: false,
             currency:[],
             activeTab: "",
             currentCurrency:{},
             disable:false
        };

        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.calculateLandedCost = this.calculateLandedCost.bind(this);
        this.toggle = this.toggle.bind(this);
        this.changeCurrency=this.changeCurrency.bind(this);
    }
    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });

        }
    }
    changeCurrency(tab){
        this.setState({currentCurrency:tab});
        if(tab.isLocalCurrency)
        {
            this.setState({disable:false});
        }
        else{
            this.setState({disable:true})}
    }

    getCurrency(){
        CurrencyService.getCurrencyPI().then(response => {
            this.setState({currency:response.data,activeTab:response.data[0].currencyType,currentCurrency:response.data[0]});
        }).catch(error => {
            this.setState({loadingStock: false});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }
    calculateLandedCost(stockInventoryCostLandedObj) {
        return stockInventoryCostLandedObj.freightCost +
            stockInventoryCostLandedObj.supplierCost +
            stockInventoryCostLandedObj.packagingCost +
            stockInventoryCostLandedObj.dutyCost +
            stockInventoryCostLandedObj.otherCosts
    }

    componentDidMount() {
        this.getCurrency();
        this.setState({},()=>{this.refresh(this.props.prodCode)}
        );
    }
    refresh(prodCode) {
            this.setState({loading: true})
            StockInventoryCostLandedService.getStockInventory_CostLandedObj(prodCode).then(response => {
                this.setState({stockInventoryCostLandedObj:response.data, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });

    }

    handleChange(e) {
        const {name, value} = e.target;
        let {stockInventoryCostLandedObj} = this.state;
        stockInventoryCostLandedObj[name] = parseInt(value);
        this.setState({stockInventoryCostLandedObj});
    }

    validateForm(stockInventoryCostLandedObj) {
        let formErrors = {};
        let valid = true;

        if (stockInventoryCostLandedObj.freightCost === null || (stockInventoryCostLandedObj.freightCost === undefined)) {
            formErrors.freightCost = "Please enter freight cost";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.stockInventoryCostLandedObj};
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            this.setState({saving: true, formErrors: {}});

            StockInventoryCostLandedService.saveOrUpdateStockInventoryLandedCost(this.props.prodCode, req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (req.id <= 0) {
                        toast.success("Stock Landed Cost saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        toast.success("Stock Landed Cost updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    this.setState({saving: false});

                }
            }).catch(error => {
                this.setState({saving: false});
                console.log(error.data);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    render() {
        let {stockInventoryCostLandedObj, formErrors, loading, mainStockUnit,currency,activeTab,currentCurrency,disable} = this.state;
        let {prodCode}=this.props;
        return (
            <div>
                    <Card>
                    <CardHeader>
                        <h5>Landed Cost</h5>
                            <Nav tabs card>
                                {currency.map((tab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink
                                            className={classnames({ active: activeTab === tab.currencyType })}
                                            onClick={() => {
                                                this.toggle(tab.currencyType);
                                                this.changeCurrency(tab);
                                            }}>
                                            <Button style={{ textDecoration: "none" }}
                                                    size={"sm"}
                                                    color={"link"}>{tab.currencyType +" ( "+tab.currencySymbol+" )" }&nbsp;</Button>

                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                    </CardHeader>
                    <CardBody>
                        <StockInventoryMainUnitView prodCode={prodCode}/>
                        <hr/>
                        {
                            loading ?
                                <Spinner color="primary"/>
                                : <Form>
                                    <Row>
                                        <Col lg={3} md={3} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Supplier Cost</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            {currentCurrency.currencySymbol}
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        invalid={formErrors.supplierCost != null}
                                                        name="supplierCost"
                                                        type="number"
                                                        disabled={disable}
                                                        value={stockInventoryCostLandedObj.supplierCost ? parseFloat((currentCurrency.accountingExchangeRate *(stockInventoryCostLandedObj.supplierCost)).toFixed(2)):null}
                                                        onChange={this.handleChange}
                                                        placeholder="Supplier Cost ..."/>
                                                </InputGroup>
                                                <FormText color="danger">{formErrors.supplierCost}</FormText>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Packaging Cost</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            {currentCurrency.currencySymbol}
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        invalid={formErrors.packagingCost != null}
                                                        name="packagingCost"
                                                        type="number"
                                                        disabled={disable}
                                                        value={stockInventoryCostLandedObj.packagingCost ? parseFloat((currentCurrency.accountingExchangeRate *(stockInventoryCostLandedObj.packagingCost)).toFixed(2)):null}
                                                        onChange={this.handleChange}
                                                        placeholder="Packaging Cost..."/>
                                                </InputGroup>
                                                <FormText color="danger">{formErrors.packagingCost}</FormText>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Freight Cost</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            {currentCurrency.currencySymbol}
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        invalid={formErrors.freightCost != null}
                                                        name="freightCost"
                                                        type="number"
                                                        disabled={disable}
                                                        value={stockInventoryCostLandedObj.freightCost ? parseFloat((currentCurrency.accountingExchangeRate *(stockInventoryCostLandedObj.freightCost)).toFixed(2)):null}
                                                        onChange={this.handleChange}
                                                        placeholder="Freight Cost..."/>
                                                </InputGroup>
                                                <FormText color="danger">{formErrors.freightCost}</FormText>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Other Cost</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            {currentCurrency.currencySymbol}
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        invalid={formErrors.otherCosts != null}
                                                        name="otherCosts"
                                                        type="number"
                                                        disabled={disable}
                                                        value={stockInventoryCostLandedObj.otherCosts ? parseFloat((currentCurrency.accountingExchangeRate *(stockInventoryCostLandedObj.otherCosts)).toFixed(2)):null}
                                                        onChange={this.handleChange}
                                                        placeholder="Other Cost ..."/>
                                                </InputGroup>
                                                <FormText color="danger">{formErrors.otherCosts}</FormText>
                                            </FormGroup>
                                        </Col>


                                    </Row>

                                    <Row>
                                        <Col lg={3} md={3} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Duty Cost</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            {currentCurrency.currencySymbol}
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        invalid={formErrors.dutyCost != null}
                                                        name="dutyCost"
                                                        type="number"
                                                        disabled={disable}
                                                        value={stockInventoryCostLandedObj.dutyCost ? parseFloat((currentCurrency.accountingExchangeRate *(stockInventoryCostLandedObj.dutyCost)).toFixed(2)):null}
                                                        onChange={this.handleChange}
                                                        placeholder="Duty Costs..."/>
                                                </InputGroup>
                                                <FormText color="danger">{formErrors.dutyCost}</FormText>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Duty Rate</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fa fa-percent"
                                                               aria-hidden="true"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        invalid={formErrors.dutyRate != null}
                                                        name="dutyRate"
                                                        type="number"
                                                        disabled={disable}
                                                        value={stockInventoryCostLandedObj.dutyRate ? parseFloat((stockInventoryCostLandedObj.dutyRate).toFixed(2)):null}
                                                        onChange={this.handleChange}
                                                        placeholder="Duty Rate..."/>
                                                </InputGroup>
                                                <FormText color="danger">{formErrors.dutyRate}</FormText>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3} md={3} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label>Total Landed Cost</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            {currentCurrency.currencySymbol}
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        disabled={true}
                                                        invalid={formErrors.landedCost != null}
                                                        name="landedCost"
                                                        type="number"
                                                        value={stockInventoryCostLandedObj.dutyRate ? parseFloat((currentCurrency.accountingExchangeRate *(this.calculateLandedCost(stockInventoryCostLandedObj))).toFixed(2)):null}
                                                        onChange={this.handleChange}
                                                        placeholder="Landed Cost..."/>
                                                </InputGroup>
                                                <FormText color="danger">{formErrors.landedCost}</FormText>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {disable
                                        ?
                                        <Row>
                                            &nbsp;&nbsp;&nbsp;
                                            <small className={"text-muted"}>* To edit the values switch to Local Currency</small>
                                        </Row>
                                        :null
                                    }
                                </Form>
                        }
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button size={"sm"} color='primary' onClick={this.handleSubmit}
                                    disabled={this.state.saving}>
                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                              style={{color: "white"}}/> :
                                    <i className="fa fa-floppy-o mr-2"
                                       aria-hidden="true"/>}
                                Save</Button>{' '}
                            <Button size={"sm"} color='secondary' onClick={()=>this.props.history.goBack()}>
                                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                                Back</Button>{' '}</div>
                    </CardFooter>
                </Card>
            </div>
        );
    }
}
