import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../../services/CommonService';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';

import panelTrackUtil from './PanelTrackUtil';
import NumberFormat from "react-number-format";
import PanelTrackBOMModal from "../panelTrack/PanelTrackBOMModal";
import PanelTrackConsolidatedBOMModal from "../panelTrack/PanelTrackConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../custom/CustomUtil";
import {PRODUCT_BUILDER_CUSTOM_BUILD, PRODUCT_BUILDER_PANEL_TRACK_PRODCODE} from "../../../../../../store/AppConstants";


export default class PanelTrack extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = panelTrackUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = panelTrackUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                    product = panelTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_PANEL_TRACK_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if(stocksByProdCode){
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o=> o.prodCode === s.prodCode);
                        }
                        if(stock){
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);

                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
            default:
                break;
        }
        product = panelTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = panelTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemPanelTrack.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};


        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemPanelTrack.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 1200;// default
        product.items[itemIndex].configuration.width.max = 6000;// default
        product.items[itemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemPanelTrack.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 3000;
        }

        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 1000;// default
        product.items[itemIndex].configuration.width.max = 3000;// default
        product.items[itemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemPanelTrack.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }

        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemPanelTrack.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.fabricType.selected.isSelectable = true;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemPanelTrack.fabricType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
        }

        //fabricColourOffRange
        product.items[itemIndex].configuration.fabricColourOffRange = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = context.salesOrderItemPanelTrack.fabricColourOffRange;
        } else {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
        }
        product.items[itemIndex].configuration.fabricColourOffRange.formError = {isValid: true, message: "", dom: null};


        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            isSelectable : true,
            value : {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.fabricColour.options = [];
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
            product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                return {
                    optionKey: o.color,
                    optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                    ...o
                }
            });
        }
        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
            )
        });
        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
            optionIndex = -1;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.fabricColour);
            }
            if (optionIndex === -1 && !product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                    optionIndex = 0;
                }
            }
            if (optionIndex > -1) {
                product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
            }
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};


        //opening
        configurationIndex = findIndex(configurations, "attributeKey", "opening");
        product.items[itemIndex].configuration.opening = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.opening.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
            numberOfTrack: 0,
            numberOfPanel: 0,
            numberOfSpace: 0,
            panelWidth: 0,
        };
        product.items[itemIndex].configuration.opening.finalOptions = product.items[itemIndex].configuration.opening.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.opening.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.opening);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.opening.selected.value = product.items[itemIndex].configuration.opening.finalOptions[optionIndex];
        product.items[itemIndex].configuration.opening.selected.dropdownValue = product.items[itemIndex].configuration.opening.selected.value.optionKey;
        product.items[itemIndex].configuration.opening.formError = {isValid: true, message: "", dom: null,};


        //trackColour
        configurationIndex = findIndex(configurations, "attributeKey", "trackColour");
        product.items[itemIndex].configuration.trackColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.trackColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
        };
        product.items[itemIndex].configuration.trackColour.finalOptions = product.items[itemIndex].configuration.trackColour.options.filter(o => o.condition1.includes("dropdown"));
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.trackColour.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.trackColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.trackColour.selected.value = product.items[itemIndex].configuration.trackColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.trackColour.selected.dropdownValue = product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
        product.items[itemIndex].configuration.trackColour.formError = {isValid: true, message: "", dom: null,};


        //stackingDirection
        configurationIndex = findIndex(configurations, "attributeKey", "stackingDirection");
        product.items[itemIndex].configuration.stackingDirection = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.stackingDirection.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
        };
        product.items[itemIndex].configuration.stackingDirection.finalOptions = product.items[itemIndex].configuration.stackingDirection.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.stackingDirection.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.stackingDirection);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.stackingDirection.selected.value = product.items[itemIndex].configuration.stackingDirection.finalOptions[optionIndex];
        product.items[itemIndex].configuration.stackingDirection.selected.dropdownValue = product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey;
        product.items[itemIndex].configuration.stackingDirection.formError = {isValid: true, message: "", dom: null,};


        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
        };
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.mount);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};


        //bracket
        configurationIndex = findIndex(configurations, "attributeKey", "bracket");
        product.items[itemIndex].configuration.bracket = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracket.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
            bracketQty: 0
        };
        product.items[itemIndex].configuration.bracket.finalOptions = product.items[itemIndex].configuration.bracket.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bracket.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.bracket);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.bracket.selected.value = product.items[itemIndex].configuration.bracket.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bracket.selected.dropdownValue = product.items[itemIndex].configuration.bracket.selected.value.optionKey;
        product.items[itemIndex].configuration.bracket.formError = {isValid: true, message: "", dom: null,};


        //bottomRail
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRail");
        product.items[itemIndex].configuration.bottomRail = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRail.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
        };
        product.items[itemIndex].configuration.bottomRail.options = getSorted(product.items[itemIndex].configuration.bottomRail.options, 'primaryAttributeMin', true);
        product.items[itemIndex].configuration.bottomRail.finalOptions = product.items[itemIndex].configuration.bottomRail.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRail.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.bottomRail);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.bottomRail.selected.value = product.items[itemIndex].configuration.bottomRail.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomRail.selected.dropdownValue = product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomRail.formError = {isValid: true, message: "", dom: null,};

        //bottomRailColour
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailColour");
        product.items[itemIndex].configuration.bottomRailColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRailColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
        };
        product.items[itemIndex].configuration.bottomRailColour.finalOptions = product.items[itemIndex].configuration.bottomRailColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailColour.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.bottomRailColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomRailColour.formError = {isValid: true, message: "", dom: null,};


        //consumables
        configurationIndex = findIndex(configurations, "attributeKey", "consumables");
        product.items[itemIndex].configuration.consumables = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.consumables.finalOptions = product.items[itemIndex].configuration.consumables.options;
        product.items[itemIndex].configuration.consumables.selected = {
            stocks: []
        };

        //trackProfile
        configurationIndex = findIndex(configurations, "attributeKey", "trackProfile");
        product.items[itemIndex].configuration.trackProfile = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.trackProfile.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
        };
        product.items[itemIndex].configuration.trackProfile.finalOptions = product.items[itemIndex].configuration.trackProfile.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.trackProfile.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.numberOfTrack.toString());
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.trackProfile.selected.value = product.items[itemIndex].configuration.trackProfile.finalOptions[optionIndex];
        product.items[itemIndex].configuration.trackProfile.selected.dropdownValue = product.items[itemIndex].configuration.trackProfile.selected.value.optionKey;
        product.items[itemIndex].configuration.trackProfile.formError = { isValid: true, message: "", dom: null, };

        //panel
        configurationIndex = findIndex(configurations, "attributeKey", "panel");
        product.items[itemIndex].configuration.panel = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.panel.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: "",
        };
        product.items[itemIndex].configuration.panel.finalOptions = product.items[itemIndex].configuration.panel.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.panel.finalOptions, 'optionKey', context.salesOrderItemPanelTrack.numberOfPanel.toString());
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.panel.selected.value = product.items[itemIndex].configuration.panel.finalOptions[optionIndex];
        product.items[itemIndex].configuration.panel.selected.dropdownValue = product.items[itemIndex].configuration.panel.selected.value.optionKey;
        product.items[itemIndex].configuration.panel.formError = { isValid: true, message: "", dom: null, };



        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("opening", product, itemIndex, order, true);

        product = panelTrackUtil.updateRules("stackingDirection", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("stackingDirection", product, itemIndex, order, true);

        product = this.updateItemConfigurationOptions("bottomRailColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("trackProfile", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("panel", product, itemIndex, order, true);

        product = panelTrackUtil.updateRules("location", product, itemIndex, order);
        product = panelTrackUtil.updateRules("width", product, itemIndex, order);
        product = panelTrackUtil.updateRules("drop", product, itemIndex, order);
        product = panelTrackUtil.updateRules("fabricColourOffRange", product, itemIndex, order);

        product = panelTrackUtil.updateFormError("fabricColourOffRange", product, itemIndex);
        product = panelTrackUtil.updateFormError("width", product, itemIndex, order);
        product = panelTrackUtil.updateFormError("drop", product, itemIndex, order);
        product = panelTrackUtil.updateFormError("location", product, itemIndex, order);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let {order, currentUser} = this.props;

        let optionIndex = -1,
            bOMCode = "",
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = product.items[itemIndex].configuration.drop.selected.value,
            condition1;

        if (key) {
            switch (key) {
                case "fabricColour":
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        product.items[itemIndex].configuration.fabricType.selected.value.colors = getSorted(product.items[itemIndex].configuration.fabricType.selected.value.colors, "color", true);
                        product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors;
                        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.map(o => {
                            return {
                                optionKey: o.color,
                                optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                                ...o
                            }
                        }).filter(o => {
                            return (
                                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                            )
                        });
                    }
                    product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.finalOptions.filter(o => {
                        return (
                            salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                        )
                    });
                    if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                        if (optionIndex > -1) {
                            product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                        }
                    }
                    break;
                case "opening":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.opening.selected.isSelectable) {
                        product.items[itemIndex].configuration.opening.finalOptions = product.items[itemIndex].configuration.opening.options.filter(o => width >= o.primaryAttributeMin && width <= o.primaryAttributeMax);
                        if (!isEmpty(product.items[itemIndex].configuration.opening.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.opening.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.opening.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.opening.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.opening.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.opening.selected.value = product.items[itemIndex].configuration.opening.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.opening.selected.dropdownValue = product.items[itemIndex].configuration.opening.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.opening.selected.value = null;
                        product.items[itemIndex].configuration.opening.selected.dropdownValue = "";
                    }
                    break;
                case "stackingDirection":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.stackingDirection.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.stackingDirection.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.stackingDirection.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.stackingDirection.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.stackingDirection.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.stackingDirection.selected.value = product.items[itemIndex].configuration.stackingDirection.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.stackingDirection.selected.dropdownValue = product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.stackingDirection.selected.value = null;
                        product.items[itemIndex].configuration.stackingDirection.selected.dropdownValue = "";
                    }
                    break;
                case "bottomRailColour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
                    if (product.items[itemIndex].configuration.bottomRailColour.selected.isSelectable) {
                        product.items[itemIndex].configuration.bottomRailColour.finalOptions = product.items[itemIndex].configuration.bottomRailColour.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                        if (!isEmpty(product.items[itemIndex].configuration.bottomRailColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.bottomRailColour.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.bottomRailColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.bottomRailColour.selected.value = null;
                        product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = "";
                    }
                    break;
                case "basePrice":
                    bOMCode = panelTrackUtil.generateBOMCode(product, itemIndex);
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                        return x.bmCode === bOMCode;
                    });

                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= width);

                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= drop);

                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price * 1;

                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                case "trackProfile":
                    optionIndex = -1;
                    product.items[itemIndex].configuration.trackProfile.finalOptions = product.items[itemIndex].configuration.trackProfile.options;
                    if (!isEmpty(product.items[itemIndex].configuration.trackProfile.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.trackProfile.selected.value)) {
                                optionIndex = product.items[itemIndex].configuration.trackProfile.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.trackProfile.selected.value.optionKey);
                            }
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    if (product.items[itemIndex].configuration.trackProfile.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.trackProfile.selected.value = product.items[itemIndex].configuration.trackProfile.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.trackProfile.selected.dropdownValue = product.items[itemIndex].configuration.trackProfile.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.trackProfile.selected.value = null;
                        product.items[itemIndex].configuration.trackProfile.selected.dropdownValue = "";
                    }
                    break;
                case "panel":
                    optionIndex = -1;
                    product.items[itemIndex].configuration.panel.finalOptions = product.items[itemIndex].configuration.panel.options;
                    if (!isEmpty(product.items[itemIndex].configuration.panel.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.panel.selected.value)) {
                            optionIndex = product.items[itemIndex].configuration.panel.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.panel.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (product.items[itemIndex].configuration.panel.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.panel.selected.value = product.items[itemIndex].configuration.panel.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.panel.selected.dropdownValue = product.items[itemIndex].configuration.panel.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.panel.selected.value = null;
                        product.items[itemIndex].configuration.panel.selected.dropdownValue = "";
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = panelTrackUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = panelTrackUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("opening", product, itemIndex, order, true);
                product = panelTrackUtil.updateRules("stackingDirection", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("stackingDirection", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("trackProfile", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = panelTrackUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = panelTrackUtil.updateFormError("drop", product, itemIndex);
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = panelTrackUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = panelTrackUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                }
                break;
            case "fabricColour":
                product.items[itemIndex].configuration.fabricColour.selected.value = {};
                product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";

                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                product = panelTrackUtil.updateFormError("fabric", product, itemIndex);
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.value = change;
                product = panelTrackUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                break;
            case "trackColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.trackColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.trackColour.selected.value = product.items[itemIndex].configuration.trackColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.trackColour.selected.dropdownValue = product.items[itemIndex].configuration.trackColour.selected.value.optionKey;
                }
                break;
            case "bottomRailColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                }
                break;
            case "bottomRail":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRail.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRail.selected.value = product.items[itemIndex].configuration.bottomRail.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRail.selected.dropdownValue = product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("bottomRailColour", product, itemIndex, order, true);
                }
                break;
            case "opening":
                optionIndex = findIndex(product.items[itemIndex].configuration.opening.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.opening.selected.value = product.items[itemIndex].configuration.opening.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.opening.selected.dropdownValue = product.items[itemIndex].configuration.opening.selected.value.optionKey;
                    product = panelTrackUtil.updateRules("stackingDirection", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("stackingDirection", product, itemIndex, order, true);
                }
                break;
            case "stackingDirection":
                optionIndex = findIndex(product.items[itemIndex].configuration.stackingDirection.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.stackingDirection.selected.value = product.items[itemIndex].configuration.stackingDirection.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.stackingDirection.selected.dropdownValue = product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey;
                }
                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                }
                break;
            case "bracket":
                optionIndex = findIndex(product.items[itemIndex].configuration.bracket.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bracket.selected.value = product.items[itemIndex].configuration.bracket.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bracket.selected.dropdownValue = product.items[itemIndex].configuration.bracket.selected.value.optionKey;
                }
                break;
            case "trackProfile":
                optionIndex = findIndex(product.items[itemIndex].configuration.trackProfile.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.trackProfile.selected.value = product.items[itemIndex].configuration.trackProfile.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.trackProfile.selected.dropdownValue = product.items[itemIndex].configuration.trackProfile.selected.value.optionKey;
                }
                break;
            case "panel":
                optionIndex = findIndex(product.items[itemIndex].configuration.panel.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.panel.selected.value = product.items[itemIndex].configuration.panel.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.panel.selected.dropdownValue = product.items[itemIndex].configuration.panel.selected.value.optionKey;
                }
                break;
            default:
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = panelTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }


    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}} className={"text-center"}>
                                        #
                                    </th>
                                    <th style={{minWidth: 150}} className={"text-center"}>
                                        Location
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Quantity
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Width(mm)
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Drop(mm)
                                    </th>
                                    <th style={{minWidth: 250}} className={"text-center"}>
                                        Fabric
                                    </th>
                                    <th style={{minWidth: 150}} className={"text-center"}>
                                        Track Colour
                                    </th>
                                    <th style={{minWidth: 182}} className={"text-center"}>
                                        Opening
                                    </th>
                                    <th style={{minWidth: 185}} className={"text-center"}>
                                        Stacking Direction
                                    </th>
                                    <th style={{minWidth: 115}} className={"text-center"}>
                                        Mount
                                    </th>
                                    <th style={{minWidth: 125}} className={"text-center"}>
                                        Fix
                                    </th>
                                    <th style={{minWidth: 175}} className={"text-center"}>
                                        Bottom Rail
                                    </th>
                                    <th style={{ minWidth: 175 }} className={"text-center"}>
                                        Track Profile
                                    </th>
                                    <th style={{ minWidth: 175 }} className={"text-center"}>
                                        Panel
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null

                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="text" name="location"
                                                               value={item.configuration.location.selected.value}
                                                               onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                               placeholder="location"/>
                                                        {
                                                            item.configuration.location.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            item.configuration.location.formError.isWarning
                                                                ? <Badge color="warning">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                                : null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="number" name="quantity"
                                                               invalid={!item.configuration.quantity.formError.isValid}
                                                               value={item.configuration.quantity.selected.value}
                                                               min={item.configuration.quantity.min}
                                                               max={item.configuration.quantity.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="quantity"/>

                                                        {
                                                            item.configuration.quantity.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.quantity.formError.message}
                                                                </Badge>
                                                        }
                                                    </div>
                                                </td>


                                                <td className="align-middle">
                                                    <Input type="number" name="width"
                                                           invalid={!item.configuration.width.formError.isValid}
                                                           value={item.configuration.width.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="width"/>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="drop"
                                                           invalid={!item.configuration.drop.formError.isValid}
                                                           value={item.configuration.drop.selected.value}
                                                           onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="drop"/>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="select" name="fabricType"
                                                               disabled={!item.configuration.fabricType.selected.isSelectable}
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select range</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {isEmpty(item.configuration.fabricColour.finalOptions)
                                                        ? null
                                                        : <div className={"mt-2"}>
                                                            <Input type="select" name="fabricColour"
                                                                   disabled={!item.configuration.fabricColour.selected.isSelectable}
                                                                   value={item.configuration.fabricColour.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                <option value={""}>Select colour</option>
                                                                {
                                                                    (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div>
                                                    }

                                                    {
                                                        item.configuration.fabricColourOffRange.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="fabricColourOffRange"
                                                                       value={item.configuration.fabricColourOffRange.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColourOffRange", itemIndex, true)}
                                                                       placeholder="Fabric name"
                                                                       title={"Enter fabric name"}
                                                                />
                                                                {
                                                                    item.configuration.fabricColourOffRange.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.fabricColourOffRange.formError.message}
                                                                        </Badge>
                                                                }
                                                                <div>
                                                                    <Badge color="info">
                                                                        <i className="fa fa-info-circle mr-2"
                                                                           aria-hidden="true"/>
                                                                        Price On Application
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div className={"mt-2"}>
                                                        <Input type="select" name="trackColour"
                                                               value={item.configuration.trackColour.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "trackColour", itemIndex, true)}>
                                                            <option value={""} disabled>Select
                                                            </option>

                                                            {
                                                                (item.configuration.trackColour.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={"item" + itemIndex + "-option" + oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select"
                                                           name="opening"
                                                           disabled={!item.configuration.opening.selected.isSelectable}
                                                           value={item.configuration.opening.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "opening", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.opening.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        product.items[itemIndex].configuration.stackingDirection.selected.isSelectable
                                                            ? <Input type="select"
                                                                     name="stackingDirection"
                                                                     disabled={!item.configuration.stackingDirection.selected.isSelectable}
                                                                     value={item.configuration.stackingDirection.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "stackingDirection", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.stackingDirection.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : 'N/A'
                                                    }

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select"
                                                           name="mount"
                                                           disabled={!item.configuration.mount.selected.isSelectable}
                                                           value={item.configuration.mount.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select"
                                                           name="bracket"
                                                           disabled={!item.configuration.bracket.selected.isSelectable}
                                                           value={item.configuration.bracket.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bracket", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.bracket.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select"
                                                           name="bottomRail"
                                                           disabled={!item.configuration.bottomRail.selected.isSelectable}
                                                           value={item.configuration.bottomRail.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bottomRail", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.bottomRail.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>

                                                    <div className={"mt-2"}>
                                                        <Input type="select" name="bottomRailColour"
                                                               value={item.configuration.bottomRailColour.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "bottomRailColour", itemIndex, true)}>
                                                            <option value={""} disabled>Select
                                                            </option>

                                                            {
                                                                (item.configuration.bottomRailColour.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={"item" + itemIndex + "-option" + oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </td>

                                                <td className="align-middle text-center">
                                                    {/*<Input type="select"*/}
                                                    {/*       name="trackProfile"*/}
                                                    {/*       disabled={!item.configuration.trackProfile.selected.isSelectable}*/}
                                                    {/*       value={item.configuration.trackProfile.selected.dropdownValue}*/}
                                                    {/*       onChange={(e) => this.handleChange(e.target.value, "trackProfile", itemIndex, true)}>*/}
                                                    {/*    <option value={""} disabled>Select</option>*/}
                                                    {/*    {*/}
                                                    {/*        (item.configuration.trackProfile.finalOptions || []).map((o, oIndex) => {*/}
                                                    {/*            return <option*/}
                                                    {/*                key={"item" + itemIndex + "-option" + oIndex}*/}
                                                    {/*                value={o.optionKey}>{o.optionLabel}</option>*/}
                                                    {/*        })*/}
                                                    {/*    }*/}
                                                    {/*</Input>*/}
                                                    <span>{item.configuration.opening.selected.numberOfTrack} Sliding Track</span>
                                                </td>

                                                <td className="align-middle text-center">
                                                    {/*<Input type="select"*/}
                                                    {/*    name="panel"*/}
                                                    {/*    disabled={!item.configuration.panel.selected.isSelectable}*/}
                                                    {/*    value={item.configuration.panel.selected.dropdownValue}*/}
                                                    {/*    onChange={(e) => this.handleChange(e.target.value, "panel", itemIndex, true)}>*/}
                                                    {/*    <option value={""} disabled>Select</option>*/}
                                                    {/*    {*/}
                                                    {/*        (item.configuration.panel.finalOptions || []).map((o, oIndex) => {*/}
                                                    {/*            return <option*/}
                                                    {/*                key={"item" + itemIndex + "-option" + oIndex}*/}
                                                    {/*                value={o.optionKey}>{o.optionLabel}</option>*/}
                                                    {/*        })*/}
                                                    {/*    }*/}
                                                    {/*</Input>*/}
                                                    <span>{item.configuration.opening.selected.numberOfPanel}</span>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={15}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <PanelTrackBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />

                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <PanelTrackConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
