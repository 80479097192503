import React, {Component} from 'react';
import {Button, FormText, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import OrderService from "../../services/OrderService";
import {toast} from 'react-toastify';
import {changeFormatOfDateString, handleErrorMessage} from "../../services/CommonService";
import {isEmpty} from 'lodash';

export default class DebtorInvoiceNotesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingSave: false,
            ordNum: 0,
            notes: [],
            note: '',
            userdata: {},
            formErrors: {},
        };
        this.orderService = new OrderService();
        this.handleAddNotes = this.handleAddNotes.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        if (this.props.ordNum) {
            this.setState({ordNum: this.props.ordNum}, function () {
                this.refresh();
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            this.setState({
                ordNum: nextProps.ordNum,
            }, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        this.userdata = JSON.parse(localStorage.getItem('user'));

    }

    handleAddNotes(e) {
        this.setState({note: e.target.value});
    }

    validateForm() {
        let formErrors = {};
        formErrors.note = this.state.note.trim().length > 0 ? "" : "Empty note.";

        if (formErrors.note) {
            return {valid: false, formErrors: formErrors};
        }
        return {valid: true, formErrors: formErrors};
    }

    handleSave(e) {
        e.preventDefault();
        let result = this.validateForm();
        let username = this.userdata.username;
        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        this.setState({isLoadingSave: true});
        this.orderService.AddDebtorsInvoice_Notes(this.state.note, this.state.ordNum, username).then(response => {
            toast.success("Saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.refresh();
            this.setState({note: "", isLoadingSave: false}, () => {
                this.props.toggleModel();
                this.props.refresh();
            });
        }).catch(error => {
            console.log(error);
            this.setState({isLoadingSave: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {formErrors, isLoadingSave, note} = this.state;
        let {notes} = this.props;
        return (
            <Modal isOpen={this.props.isOpen}
                   size="lg"
                   scrollable={false}
                   toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    Internal Notes
                </ModalHeader>
                <ModalBody>
                    <div>
                        <FormText color="danger">{formErrors.note} </FormText>
                        <Input type="textarea" name="note" id="note"
                               onChange={this.handleAddNotes}
                               value={note}
                               placeholder="Write note here."/>
                    </div>

                    <div className="mt-2">
                        {isEmpty(notes)
                            ? null
                            : <ul style={{padding: "unset", marginLeft: 1}}>
                                {(notes || []).map((item, index) =>
                                    <li key={index}>
                                        <div className={"lead-remark-list-item"}>
                                            <div className={"lead-remark-list-item-text"}>
                                                <small className="text-muted">
                                                    <i className="fa fa-user text-muted mr-2" aria-hidden="true"/>
                                                    {item.name}
                                                </small>
                                                <p className="mb-1">{item.notes}</p>
                                                <div className="text-right">
                                                    <small className="text-muted">
                                                        <i class="fa fa-clock-o mr-2" aria-hidden="true"/>
                                                        {changeFormatOfDateString(item.dateAdded, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY hh:mm a")}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={isLoadingSave}
                        type="submit"
                        color="primary"
                        size={"sm"}
                        className={"mr-2"}
                        onClick={this.handleSave}
                    >
                        {isLoadingSave
                            ? <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                            : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>}
                        {isLoadingSave ? "Saving" : "Save"}
                    </Button>
                    <Button
                        disabled={isLoadingSave}
                        size={"sm"}
                        color="secondary"
                        onClick={this.props.toggleModel}
                    ><i className="fa fa-times mr-2" aria-hidden="true"/>
                        Cancel
                    </Button>
                </ModalFooter>

            </Modal>
        )
    }
}