import React, { Component } from 'react';
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner, Table ,Row} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import { getSorted, handleErrorMessage } from '../../services/CommonService';
import OrderService from '../../services/OrderService';

export default class ReworkSummaryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reworkData: [],
            isLoading: false,
        };
        this.orderService = new OrderService();
        this.getReworkSummaryDetails = this.getReworkSummaryDetails.bind(this);
    }

    componentDidMount() {
        this.getReworkSummaryDetails();
    }

    getReworkSummaryDetails() {
        this.setState({ isLoading: true });
        this.orderService.getReworkOrderSummaryBoard().then(response => {
            let data = response.data;
            data = getSorted(data, 'reworkOrderPercentage');
            this.setState({ reworkData: data, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { reworkData, isLoading } = this.state;
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col><h6 className={"mb-0"}>Reworks by Customer</h6></Col>
                            <Col className={"text-right"}>In last 12 weeks</Col>
                        </Row>
                    </CardHeader>
                    <div>
                        {isLoading ? <CardBody><Spinner className="primary" /></CardBody>
                            : <Table striped responsive size={"sm"}>
                                <thead>
                                    <tr>
                                        <th className="text-center">S.No.</th>
                                        <th className="text-center">AccountID</th>
                                        <th className="text-center">Company</th>
                                        <th className="text-right">Total Orders</th>
                                        <th className="text-right">Rework Orders</th>
                                        <th className="text-right">Reworks % of Orders</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(reworkData || []).map((data, index) =>
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">
                                                <Link className="btn btn-primary btn-sm"
                                                    style={{ color: "white" }}
                                                    title={"Click here to see Details"}
                                                    to={"/customer/#" + data.accountID}>
                                                    {data.accountID}</Link>
                                            </td>
                                            <td className="text-center">{data.companyName}</td>
                                            <td className="text-right">{data.totalOrders}</td>
                                            <td className="text-right">{data.reworkOrders}</td>
                                            <td className="text-right"><strong><NumberFormat value={data.reworkOrderPercentage}
                                                decimalScale={0}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={'%'} /></strong></td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Card>
                <ToastContainer />
            </Col>
        );
    }
}

