import { authHeader } from '../helpers/auth-header';
import { config } from '../helpers/config'
import axios from 'axios';
import { handleError, handleResponse } from "./UserService";

export default class PurchaseHistoryService {

    getCustomerOrderStats() {
        const requestOptions = {
            method: 'POST',
            headers: authHeader()
        };
        return fetch(config.apiUrl + 'api/dashboard/customer/invoice/order/stats', requestOptions).then(handleResponse, handleError);
    }

    getCustomerQuoteStats() {
        const requestOptions = {
            method: 'POST',
            headers: authHeader()
        };
        return fetch(config.apiUrl + 'api/dashboard/customer/invoice/quotes/stats', requestOptions).then(handleResponse, handleError);
    }

    getMonthToDateRevenue(accountId) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({accountId: accountId}),
            url: config.apiUrl + 'api/order/purchase/history/revenue/monthtodate'

        };
        return axios(request)
    }

    getYearToDateRevenue(accountId) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ accountId: accountId }),
            url: config.apiUrl + 'api/order/purchase/history/revenue/yeartodate'
        };
        return axios(request)
    }

    getLastToDateRevenue(accountId) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ accountId: accountId }),
            url: config.apiUrl + 'api/order/purchase/history/revenue/lastyeartodate'
        };
        return axios(request)
    }

    getGrowth(accountId) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ accountId: accountId }),
            url: config.apiUrl + 'api/order/purchase/growth'
        };
        return axios(request)
    }

    getMonthlyRevenue(accountId) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ accountId: accountId }),
            url: config.apiUrl + 'api/order/purchase/history/revenue/monthly'
        };
        return axios(request)
    }

    getSoldUnits(accountId, year, includeChildAccount) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ accountId: accountId, years: year, includeChildAccount: includeChildAccount}),
            url: config.apiUrl + 'api/order/purchase/history/soldUnits'
        };
        return axios(request)
    }

    getSoldValue(accountId){
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify({ accountId: accountId }),
            url: config.apiUrl + 'api/order/purchase/history/soldValue'
        };
        return axios(request)
    }

    getOpenOrders(accountId, selectedPage, pageSize) {

        const requestBody = {
            accountId: accountId,
            selectedPage: selectedPage,
            pageSize: pageSize
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/order/purchase/open'
        };

        return axios(request)
    }

    getCompletedOrders(accountId, selectedPage, pageSize) {

        const requestBody = {
            accountId: accountId,
            selectedPage: selectedPage,
            pageSize: pageSize
        };

        const request = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
            url: config.apiUrl + 'api/order/purchase/completed'
        };

        return axios(request)
    }






}