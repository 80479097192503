import React, {Component} from "react";
import {
    Col, Row, Modal, ModalBody, ModalHeader, ModalFooter,
    Button, FormGroup, Label, Input, Spinner, FormText
} from 'reactstrap';
import {toast} from 'react-toastify';
import {cloneDeep} from 'lodash';
import SearchCity from "../search/SearchCity";
import SearchState from "../search/SearchState";
import SearchPostcode from "../search/SearchPostcode";

export default class SalesOrderProductBuilderCustomerRetailDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consumerDetail: cloneDeep(this.props.order),
            formError: this.getFormErrorInstance()
        }
        ;
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    handleChange(change, key) {
        let {consumerDetail, formError} = this.state;
        if (key) {
            consumerDetail[key] = change;
            if (!formError.isValid) {
                formError = this.validateForm(consumerDetail);
            }
            this.setState({consumerDetail, formError});
        }
    }

    getFormErrorInstance() {
        return {
            retailContactName: "",
            retailPhone: "",
            retailEmail: "",
            retailAddressLine1: "",
            retailAddressLine2: "",
            retailCity: "",
            retailState: "",
            retailPostCode: "",
            isValid: true
        }
    }

    validateForm(consumerDetail) {
        let formError = this.getFormErrorInstance();

        if (consumerDetail.isRetail) {
            if (!consumerDetail.retailFirstName) {
                formError.isValid = false;
                formError.retailFirstName = "Please enter consumer first name here";
            }
            if (!consumerDetail.retailLastName) {
                formError.isValid = false;
                formError.retailLastName = "Please enter consumer last name here";
            }
            if (!consumerDetail.retailPhone) {
                formError.isValid = false;
                formError.retailPhone = "Please enter consumer contact number here";
            }
            if (!consumerDetail.retailEmail) {
                formError.isValid = false;
                formError.retailEmail = "Please enter consumer email here";
            }
            if (!consumerDetail.retailAddressLine1) {
                formError.isValid = false;
                formError.retailAddressLine1 = "Please enter address here";
            }
            if (formError.isValid && consumerDetail.retailAddressLine1.length > 50) {
                formError.isValid = false;
                formError.retailAddressLine1 = "Max 50 characters";
            }
            if (consumerDetail.retailAddressLine2 && consumerDetail.retailAddressLine2.length > 50) {
                formError.isValid = false;
                formError.retailAddressLine2 = "Max 50 characters";
            }
            if (!consumerDetail.retailCity) {
                formError.isValid = false;
                formError.retailCity = "Please enter city here";
            }
            if (!consumerDetail.retailState) {
                formError.isValid = false;
                formError.retailState = "Please enter state here";
            }
            if (!consumerDetail.retailPostCode) {
                formError.isValid = false;
                formError.retailPostCode = "Please enter postCode here";
            }
        }
        return formError;
    }

    submitForm() {
        let {consumerDetail} = this.state;

        let formError = this.validateForm(consumerDetail);
        this.setState({formError}, () => {
            if (formError.isValid) {
                if (!consumerDetail.isRetail) {
                    consumerDetail.retailContactName = "";
                    consumerDetail.retailPhone = "";
                    consumerDetail.retailEmail = "";
                    consumerDetail.retailAddressLine1 = "";
                    consumerDetail.retailAddressLine2 = "";
                    consumerDetail.retailCity = "";
                    consumerDetail.retailState = "";
                    consumerDetail.retailPostCode = "";
                }
                this.props.handleChange("retailConsumerDetail", consumerDetail);
            } else {
                toast.info("Please fill the details properly.");
            }
        });
    }

    render() {
        let {isOpen, toggle, isLoading} = this.props;
        let {consumerDetail, formError} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)} backdrop="static">
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {consumerDetail.debtorInvoiceOrdNum ? "Edit" : "Add"} Retail Order
                </ModalHeader>
                <ModalBody>
                    <Label>
                        <Button color={"link"}
                                size={"sm"}
                                onClick={() => this.handleChange(!consumerDetail.isRetail, "isRetail")}
                                disabled={!!consumerDetail.debtorInvoiceOrdNum}
                        >
                            {
                                consumerDetail.isRetail
                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                         aria-hidden="true"/>
                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                            }
                        </Button>
                        Is it a retail order?
                    </Label>
                    {consumerDetail.isRetail ?
                        <Row>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="name">Consumer First Name*</Label>
                                    <Input type="text" name="retailFirstName"
                                           value={consumerDetail.retailFirstName ? consumerDetail.retailFirstName : ''}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           placeholder="Enter consumer first name here"/>
                                    <FormText color="danger">{formError.retailFirstName}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="name">Consumer Last Name*</Label>
                                    <Input type="text" name="retailLastName"
                                           value={consumerDetail.retailLastName ? consumerDetail.retailLastName : ''}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           placeholder="Enter consumer last name here"/>
                                    <FormText color="danger">{formError.retailLastName}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="name">Consumer Contact Number*</Label>
                                    <Input type="text" name="retailPhone"
                                           value={consumerDetail.retailPhone ? consumerDetail.retailPhone : ''}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           placeholder="Enter consumer contact number here"/>
                                    <FormText color="danger">{formError.retailPhone}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="email">Consumer Email*</Label>
                                    <Input type="text" name="retailEmail"
                                           value={consumerDetail.retailEmail ? consumerDetail.retailEmail : ''}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           placeholder="Enter consumer email here"/>
                                    <FormText color="danger">{formError.retailEmail}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="address1">Address Line 1*</Label>
                                    <Input autoComplete="off" type="text" name="retailAddressLine1"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={consumerDetail.retailAddressLine1 ? consumerDetail.retailAddressLine1 : ''}
                                           placeholder="Enter company name here" id="retailAddressLine1"/>
                                    <FormText color="danger">{formError.retailAddressLine1}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="address2">Address Line 2</Label>
                                    <Input autoComplete="off" type="text" name="retailAddressLine2"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={consumerDetail.retailAddressLine2 ? consumerDetail.retailAddressLine2 : ''}
                                           placeholder="Enter address line 2 here" id="retailAddressLine2"/>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="City">City*</Label>
                                    <SearchCity
                                        handleSelection={(selectedCity) => this.handleChange(selectedCity, "retailCity")}
                                        selected={consumerDetail.retailCity || ''}
                                        defaultSelected={''}
                                        filters={{
                                            state: consumerDetail.retailState || '',
                                            deliveryData: consumerDetail.retailPostCode || ''
                                        }}
                                    />
                                    <FormText color="danger">{formError.retailCity}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="State">State*</Label>
                                    <SearchState
                                        handleSelection={(selectedState) => this.handleChange(selectedState, "retailState")}
                                        selected={consumerDetail.retailState || ''}
                                        defaultSelected={''}
                                        filters={{
                                            city: consumerDetail.retailCity || '',
                                            postcode: consumerDetail.retailPostCode || ''
                                        }}
                                    />
                                    <FormText color="danger">{formError.retailState}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                                <FormGroup>
                                    <Label for="PostCode">Postcode*</Label>
                                    <SearchPostcode
                                        handleSelection={(selectedPostCode) => this.handleChange(selectedPostCode, "retailPostCode")}
                                        selected={consumerDetail.retailPostCode || ''}
                                        defaultSelected={''}
                                        filters={{
                                            city: consumerDetail.retailCity || '',
                                            state: consumerDetail.retailState || ''
                                        }}
                                    />
                                    <FormText color="danger">{formError.retailPostCode}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        : null}

                </ModalBody>
                <ModalFooter>
                    {consumerDetail.debtorInvoiceOrdNum ?
                        <div className="text-right">
                            <Button color={"primary"} size="sm"
                                    onClick={this.submitForm}>{
                                isLoading ? <Spinner size="sm"
                                                     className={"mr-2"}
                                                     style={{color: "white"}}/>
                                    : <i className="fa fa-floppy-o mr-2"/>}
                                Save</Button>
                            <Button color={"secondary"} size="sm" className={"ml-2"}
                                    onClick={() => toggle(!isOpen)}>
                                <i className="fa fa-times mr-2"/>
                                Cancel</Button>
                        </div> :

                        <div className="text-right">
                            <Button color={"primary"} size="sm"
                                    onClick={this.submitForm}>
                                <i className="fa fa-check mr-2"/>
                                Ok</Button>
                        </div>
                    }

                </ModalFooter>
            </Modal>

        );
    }

}