import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Nav, NavItem, NavLink, Button
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import { handleErrorMessage } from '../../services/CommonService';
import ManageProductConfigurationStockFormulaModal from '../../components/modal/ManageProductConfigurationStockFormulaModal';

const formulaTypes = ["QTY", "PRICE", "WASTAGE"];

export default class ProductConfigurationStockFormula extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productConfigurationStockFormulaData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "description",
                        direction: true
                    },
                    filterRequest: {
                        description: '',
                        formula: '',
                        postDeductionFormula: '',
                        formulaType: formulaTypes[0],
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            isOpenModal: false,
            selectedID: 0,
            activeFormulaType: formulaTypes[0]

        };
        this.refreshAgain = this.refreshAgain.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        let searchParams = queryString.parse(this.props.location.search);
        this.getProductConfigurationStockFormula(this.state.productConfigurationStockFormulaData);
    }

    getProductConfigurationStockFormula(productConfigurationStockFormulaData) {
        this.setState({loading: true});

        ProductConfigurationService.getProductConfigurationStockFormulas(productConfigurationStockFormulaData.request).then(response => {
            productConfigurationStockFormulaData.response = response.data;
            this.setState({productConfigurationStockFormulaData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.formula) {
                flag = item.formula.toLowerCase().includes(searchText)
            }
            if (!flag && item.postDeductionFormula) {
                flag = item.postDeductionFormula.toLowerCase().includes(searchText)
            }

            if (!flag && item.formulaType) {
                flag = item.formulaType.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {productConfigurationStockFormulaData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (productConfigurationStockFormulaData.request.sortRequest.key === change) {
                    productConfigurationStockFormulaData.request.sortRequest.direction = !productConfigurationStockFormulaData.request.sortRequest.direction;
                } else {
                    productConfigurationStockFormulaData.request.sortRequest.key = change;
                    productConfigurationStockFormulaData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getProductConfigurationStockFormula(productConfigurationStockFormulaData);
                }
                break;
            case "pageSize":
                productConfigurationStockFormulaData.request.pageRequest[key] = parseInt(change);
                productConfigurationStockFormulaData.request.pageRequest.currentPage = 1;
                this.getProductConfigurationStockFormula(productConfigurationStockFormulaData);
                break;
            case "currentPage":
                productConfigurationStockFormulaData.request.pageRequest[key] = change;
                this.getProductConfigurationStockFormula(productConfigurationStockFormulaData);
                break;
            default:
                productConfigurationStockFormulaData.request.filterRequest[key] = change;
                productConfigurationStockFormulaData.request.pageRequest.currentPage = 1;
                this.getProductConfigurationStockFormula(productConfigurationStockFormulaData);
                this.setState({productConfigurationStockFormulaData});
        }

    }

    getProductConfigurationStockFormulaHeading({filterRequest}) {
        let productConfigurationStockFormulaHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"description"}
                           value={filterRequest.description || ''}
                           onChange={(e) => this.handleChange(e.target.value, "description")}/>
                </div>
            },
            {
                key: "formulaType",
                label: "Formula Type",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"select"}
                           name={"formulaType"}
                           value={filterRequest.formulaType || ''}
                           onChange={(e) => this.handleChange(e.target.value, "formulaType")}>
                        <option value="ALL">ALL</option>
                        <option value="QTY">QTY</option>
                        <option value="PRICE">PRICE</option>
                        <option value="WASTAGE">WASTAGE</option>
                    </Input>
                </div>
            },
            {
                key: "formula",
                label: "Formula",
                type: "text",
                colSpan: 1,
                minWidth: 250,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="formula" name="formula"
                                        value={filterRequest.formula}
                                        onChange={(e) => this.handleChange(e.target.value, "formula")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "postDeductionFormula",
                label: "Post Deduction Formula",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="postDeductionFormula" name="postDeductionFormula"
                                        value={filterRequest.postDeductionFormula}
                                        onChange={(e) => this.handleChange(e.target.value, "postDeductionFormula")}
                                        placeholder="Search..."/></div>

            },

            {
                key: "wastagePercent",
                label: "Wastage Percent",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },


            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center">
                    <Button size={"sm"} color={"primary"}
                        onClick={() => this.openModal(null)}><i className="fa fa-plus"/>&nbsp;
                    Add </Button></div>

            }];

        return productConfigurationStockFormulaHeading;
    }

    refreshAgain() { 
        this.setState({ isOpenModal: false });
        this.getProductConfigurationStockFormula(this.state.productConfigurationStockFormulaData);
    }

    toggle(tab) {
        if (this.state.activeFormulaType !== tab) {
            let {productConfigurationStockFormulaData} = this.state;
            productConfigurationStockFormulaData.request.filterRequest.formulaType = tab;
            this.setState({
                activeFormulaType: tab,
                productConfigurationStockFormulaData
            }, () => {
                this.getProductConfigurationStockFormula(productConfigurationStockFormulaData);
            });
        }
    }

    openModal(ID) {
        if (ID) {
            this.setState({ isOpenModal: true, selectedID: ID });
        } else {
            this.setState({ isOpenModal: true, selectedID: null });
        }
    }

    toggleModal(data) { 
        this.setState({ isOpenModal: data });
    }


    render() {

        let { productConfigurationStockFormulaData, loading, searchText, activeFormulaType, isOpenModal, selectedID } = this.state;
        let {pageRequest, sortRequest} = productConfigurationStockFormulaData.request;
        let productConfigurationStockFormulaHeading = this.getProductConfigurationStockFormulaHeading(productConfigurationStockFormulaData.request);
         return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/products/all/list')}>Products</BreadcrumbItem>

                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {(formulaTypes || []).map((formulaType, index) =>
                                <NavItem
                                    className={"hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({active: activeFormulaType === formulaType})}
                                        onClick={() => {
                                            this.toggle(formulaType);
                                        }}>
                                        <Button size={"sm"} color={"link"} style={{textDecoration: "none"}}>
                                            {formulaType}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {productConfigurationStockFormulaData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                   aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(productConfigurationStockFormulaHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(productConfigurationStockFormulaHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(productConfigurationStockFormulaData.response.records || []).filter((productConfigurationStockFormula) => this.searchFunction(productConfigurationStockFormula, searchText)).map((productConfigurationStockFormula, index) =>

                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td>{productConfigurationStockFormula.description}</td>
                                        <td>{productConfigurationStockFormula.formulaType}</td>
                                        <td>{productConfigurationStockFormula.formula}</td>
                                        <td>{productConfigurationStockFormula.postDeductionFormula}</td>
                                        <td>{productConfigurationStockFormula.wastagePercent}</td>
                                        <td className="text-center"><Button size={"sm"} color={"primary"}
                                            onClick={() => this.openModal(productConfigurationStockFormula.id)}>
                                            <i className="fa fa-pencil mr-2"
                                               aria-hidden="true"/>
                                            Edit</Button></td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={productConfigurationStockFormulaData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>

                {this.state.isOpenModal ?
                    <ManageProductConfigurationStockFormulaModal
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        selectedProductConfigurationStockFromulaID={selectedID}
                        refreshAgain={this.refreshAgain} /> :
                    null}
                <ToastContainer/>
            </div>
        );
    }
}
