import React, {Component} from 'react';
import {Badge, Button, Spinner, Table} from "reactstrap";
import {toast, ToastContainer} from 'react-toastify';
import {changeFormatOfDateString, handleErrorMessage} from '../../services/CommonService';
import StatementScheduledDateUpdateModal from '../../components/modal/StatementScheduledDateUpdateModal';
import quartzService from '../../services/QuartzService';
import UserService from "../../services/UserService";

export default class ScheduledJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isOpenModal: false,
            scheduledJobs: [],
            quartzJobSummary: {},
            hasSchedulerJobTriggerWritePrivlege: false
        };
        this.userService = new UserService();
        this.toggleTriggerModal = this.toggleTriggerModal.bind(this);
        this.getScheduledJobs = this.getScheduledJobs.bind(this);
        this.changeTriggerState = this.changeTriggerState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getStore = this.getStore.bind(this);
    }

    componentDidMount() {
        this.getScheduledJobs();

        let currentUser = this.userService.getLoggedInUser();
        let hasSchedulerJobTriggerWritePrivlege = this.userService.hasPrivilege(currentUser, 'scheduler-job-trigger-write');
        this.setState({hasSchedulerJobTriggerWritePrivlege});
    }

    handleChange(change, key) {
        let {loading} = this.state;
        switch (key) {
            default:

        }

    }

    getStore() {
        let {hasSchedulerJobTriggerWritePrivlege} = this.state;
        return [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{recordIndex + 1}</span>;
                }

            },
            {
                key: "jobName",
                label: "Scheduled Job",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    let result = "";
                    switch (value) {
                        case "StatementEmailQuartzService":
                            result = "Monthly Statement Email";
                            break;
                        case "DebtorQuotes_AutoReminderQuartzService":
                            result = "Quotes Auto Reminder";
                            break;
                        case "RivaOrderPullService":
                            result = "Riva Order Pull";
                            break;
                    }
                    return <span>{result}</span>;
                }

            }, {
                key: "triggerState",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <Badge color={value === "Normal" ? "success" : "danger"}>{value}</Badge>;
                }
            },

            {
                key: "nextFireTimeDateString",
                label: "Next Run Date",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "text-center align-middle",
                searchNode: null,
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className={"text-center"}>
                        {changeFormatOfDateString(record.nextFireTimeDateString, "DD/MM/YYYY HH:mm:ss", "ddd, Do MMM YYYY hh:mm A")}
                        {/* <Badge color={record.triggerState === "Normal" ? "primary" : "danger"}
                               className={classnames(" ml-2", {
                                       "hand-cursor": hasSchedulerJobTriggerWritePrivlege,
                                   }
                               )}
                               title={hasSchedulerJobTriggerWritePrivlege ? 'Update scheduled date' : ""}
                               onClick={this.openTriggerModal}>
                            <i className={classnames(" mr-2", "fa", {
                                    "fa-pencil": hasSchedulerJobTriggerWritePrivlege,
                                    "fa-clock-o": !hasSchedulerJobTriggerWritePrivlege,
                                }
                            )}
                               aria-hidden="true"
                            />
                            {changeFormatOfDateString(record.nextFireTimeDateString,"DD/MM/YYYY HH:mm:ss",  "ddd, Do MMM YYYY hh:mm A")}
                        </Badge>*/}
                    </div>;
                }
            }, {
                key: "prevFireTimeDateString",
                label: "Previous Run Date",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "text-center align-middle",
                searchNode: null,
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className={"text-center"}>
                        {changeFormatOfDateString(record.prevFireTimeDateString, "DD/MM/YYYY HH:mm:ss", "ddd, Do MMM YYYY hh:mm A")}
                    </div>;
                }
            }, {
                key: "action",
                label: "Manage",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "text-center align-middle",
                searchNode: null,
                searchNodeColSpan: 1,
                isVisible: hasSchedulerJobTriggerWritePrivlege,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className={"text-center"}>
                        <Button color={"primary"}
                                size={"sm"}
                                onClick={() => ref.toggleTriggerModal(true, record)}>
                            Edit
                        </Button>
                    </div>;
                }
            }
        ];
    }

    openEmailLogModal(selectedEmailLog) {
        this.setState({selectedEmailLog, isOpenModal: true});
    }

    toggleEmailLogModal(data) {
        this.setState({isOpenModal: data});
    }


    toggleTriggerModal(change, selectedJob) {
        selectedJob = change ? selectedJob : null;
        this.setState({isOpenTriggerModal: change, selectedJob});
    }


    getScheduledJobs() {
        this.setState({loading: true});
        quartzService.getScheduledJobs().then(response => {
            this.setState({scheduledJobs: response.data, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    changeTriggerState(triggerState, jobName) {
        if (jobName) {
            quartzService.changeTriggerState(jobName, triggerState).then(response => {
                this.setState({quartzJobSummary: response.data});
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    render() {

        let {loading, scheduledJobs, hasSchedulerJobTriggerWritePrivlege, isOpenTriggerModal, selectedJob} = this.state;
        let store = this.getStore().filter(x => x.isVisible);
        return (
            <div>
                <Table striped bordered responsive hover size='sm'>
                    <thead>
                    <tr>
                        {(store || []).map((item, index) => {
                            return (
                                <th key={index}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}>
                                    {item.label}

                                </th>
                            );
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {
                        loading
                            ? <tr>
                                <td colSpan={(store || []).length}><Spinner color={"primary"}/></td>
                            </tr>
                            : (scheduledJobs || []).map((row, rowIndex) =>
                                <tr key={rowIndex} className={"align-middle"}>
                                    {(store || []).map((column, columnIndex) => {
                                        return (
                                            <td key={columnIndex} className={column.valueClassName}>
                                                {column.render(row[column.key], row, rowIndex, scheduledJobs, this)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            )}
                    </tbody>
                </Table>

                {/*{this.state.isOpenModal ? <EmailLogModal
                    isOpen={this.state.isOpenModal}
                    toggle={this.toggleEmailLogModal}
                    emailLog={this.state.selectedEmailLog}
                /> : null}
*/}
                {this.state.isOpenTriggerModal
                    ? <StatementScheduledDateUpdateModal
                        isOpen={this.state.isOpenTriggerModal}
                        toggle={this.toggleTriggerModal}
                        refresh={this.getScheduledJobs}
                        pageLabel={""}
                        scheduledJobName={selectedJob.jobName}
                    /> : null}
                <ToastContainer/>
            </div>
        );
    }
}
