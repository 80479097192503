import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, scrollTo} from '../../../../../../services/CommonService';
import {cloneDeep, isEmpty, camelCase} from 'lodash';
import classnames from 'classnames';

import venetianUtil from './VenetianUtil';
import NumberFormat from "react-number-format";
import VenetianBOMModal from "./VenetianBOMModal";
import VenetianConsolidatedBOMModal from "./VenetianConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../custom/CustomUtil";
import {
    PRODUCT_BUILDER_CUSTOM_BUILD, PRODUCT_BUILDER_VENETIAN_PRODCODE
} from "../../../../../../store/AppConstants";

export default class Venetian extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }

    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = venetianUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = venetianUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = venetianUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    product = venetianUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_VENETIAN_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if(stocksByProdCode){
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o=> o.prodCode === s.prodCode);
                        }
                        if(stock){
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = venetianUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemVenetian.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemVenetian.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.selected = {
            value: 0,
            finishWidth: 0
        };

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemVenetian.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1800;
        }
        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {
            value: 0,
            finishDrop: 0
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemVenetian.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1800;
        }
        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {};
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemVenetian.mount);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};


        //slatType
        configurationIndex = findIndex(configurations, "attributeKey", "slatType");
        product.items[itemIndex].configuration.slatType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.slatType.selected = {};
        product.items[itemIndex].configuration.slatType.finalOptions = product.items[itemIndex].configuration.slatType.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.slatType.finalOptions, 'optionKey', context.salesOrderItemVenetian.slatType);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.slatType.selected.value = product.items[itemIndex].configuration.slatType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.slatType.selected.dropdownValue = product.items[itemIndex].configuration.slatType.selected.value.optionKey;
        product.items[itemIndex].configuration.slatType.formError = {isValid: true, message: "", dom: null,};


        //slatColour
        configurationIndex = findIndex(configurations, "attributeKey", "slatColour");
        product.items[itemIndex].configuration.slatColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.slatColour.selected = {};
        product.items[itemIndex].configuration.slatColour.finalOptions = product.items[itemIndex].configuration.slatColour.options.filter(o => o.condition1 === product.items[itemIndex].configuration.slatType.selected.value.optionKey);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.slatColour.finalOptions, 'optionKey', context.salesOrderItemVenetian.slatColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.slatColour.selected.value = product.items[itemIndex].configuration.slatColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.slatColour.selected.dropdownValue = product.items[itemIndex].configuration.slatColour.selected.value.optionKey;
        product.items[itemIndex].configuration.slatColour.formError = {isValid: true, message: "", dom: null,};


        //liftControl
        configurationIndex = findIndex(configurations, "attributeKey", "liftControl");
        product.items[itemIndex].configuration.liftControl = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.liftControl.selected = {};
        product.items[itemIndex].configuration.liftControl.finalOptions = product.items[itemIndex].configuration.liftControl.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.liftControl.finalOptions, 'optionKey', context.salesOrderItemVenetian.liftControl);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.liftControl.selected.value = product.items[itemIndex].configuration.liftControl.finalOptions[optionIndex];
        product.items[itemIndex].configuration.liftControl.selected.dropdownValue = product.items[itemIndex].configuration.liftControl.selected.value.optionKey;
        product.items[itemIndex].configuration.liftControl.formError = {isValid: true, message: "", dom: null,};


        //tiltControl
        configurationIndex = findIndex(configurations, "attributeKey", "tiltControl");
        product.items[itemIndex].configuration.tiltControl = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.tiltControl.selected = {};
        product.items[itemIndex].configuration.tiltControl.finalOptions = product.items[itemIndex].configuration.tiltControl.options.filter(o => o.condition1 === "dropdown");
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.tiltControl.finalOptions, 'optionKey', context.salesOrderItemVenetian.tiltControl);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.tiltControl.selected.value = product.items[itemIndex].configuration.tiltControl.finalOptions[optionIndex];
        product.items[itemIndex].configuration.tiltControl.selected.dropdownValue = product.items[itemIndex].configuration.tiltControl.selected.value.optionKey;
        product.items[itemIndex].configuration.tiltControl.formError = {isValid: true, message: "", dom: null,};


        //holdDownBracket
        configurationIndex = findIndex(configurations, "attributeKey", "holdDownBracket");
        product.items[itemIndex].configuration.holdDownBracket = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.holdDownBracket.selected = {};
        product.items[itemIndex].configuration.holdDownBracket.finalOptions = product.items[itemIndex].configuration.holdDownBracket.options;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.holdDownBracket.selected.value = context.salesOrderItemVenetian.holdDownBracket;

        } else {
            product.items[itemIndex].configuration.holdDownBracket.selected.value = false;
        }
        product.items[itemIndex].configuration.holdDownBracket.selected.isSelectable = false;
        product.items[itemIndex].configuration.holdDownBracket.selected.isVisible = false;

        //valance
        configurationIndex = findIndex(configurations, "attributeKey", "valance");
        product.items[itemIndex].configuration.valance = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.valance.selected = {};

        //bottomRail
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRail");
        product.items[itemIndex].configuration.bottomRail = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRail.selected = {};

        //slat
        configurationIndex = findIndex(configurations, "attributeKey", "slat");
        product.items[itemIndex].configuration.slat = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.slat.selected = {};

        //productionCalculation
        configurationIndex = findIndex(configurations, "attributeKey", "productionCalculation");
        product.items[itemIndex].configuration.productionCalculation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.productionCalculation.selected = {};

        //deduction
        product.items[itemIndex].configuration.deduction = {
            deductionRules: [],
            selected: {
                value: {
                    deduction: 0
                }
            }
        };

        product = this.updateItemConfigurationOptions("width", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("slatColour", product, itemIndex, order);

        product = venetianUtil.updateRules("quantity", product, itemIndex, order);
        product = venetianUtil.updateRules("location", product, itemIndex, order);
        product = venetianUtil.updateRules("width", product, itemIndex, order);
        product = venetianUtil.updateRules("drop", product, itemIndex, order);
        product = venetianUtil.updateRules("holdDownBracket", product, itemIndex, order);

        product = venetianUtil.updateFormError("quantity", product, itemIndex, order);
        product = venetianUtil.updateFormError("location", product, itemIndex, order);
        product = venetianUtil.updateFormError("width", product, itemIndex, order);
        product = venetianUtil.updateFormError("drop", product, itemIndex, order);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let optionIndex = -1, condition1;
        let {order} = this.props;
        if (key) {
            switch (key) {
                case "width":
                    product.items[itemIndex].configuration.width.selected.finishWidth = product.items[itemIndex].configuration.width.selected.value;
                    break;
                case "drop":
                    switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                        case "Inside":
                            product.items[itemIndex].configuration.drop.selected.finishDrop = product.items[itemIndex].configuration.drop.selected.value - 10;
                            break;
                        case "Outside":
                        default:
                            product.items[itemIndex].configuration.drop.selected.finishDrop = product.items[itemIndex].configuration.drop.selected.value;
                            break;
                    }
                    break;
                case "basePrice":
                    //bmCode
                    let bmCode = venetianUtil.generateBMCode(product, itemIndex, order);
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => x.bmCode === bmCode);
                    //widthSet
                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= product.items[itemIndex].configuration.width.selected.value);
                        //dropSet
                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= product.items[itemIndex].configuration.drop.selected.value);
                            //price
                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price;
                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                case "slatColour":
                    condition1 = product.items[itemIndex].configuration.slatType.selected.value.optionKey;
                    product.items[itemIndex].configuration.slatColour.finalOptions = product.items[itemIndex].configuration.slatColour.options.filter(o => o.condition1 === condition1);
                    optionIndex = -1;
                    if (!isEmpty(product.items[itemIndex].configuration.slatColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.slatColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.slatColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.slatColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.slatColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.slatColour.selected.value = product.items[itemIndex].configuration.slatColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.slatColour.selected.dropdownValue = product.items[itemIndex].configuration.slatColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.slatColour.selected.value = {};
                        product.items[itemIndex].configuration.slatColour.selected.dropdownValue = "";
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1, endIndex = null;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = venetianUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = venetianUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);

                product = this.updateItemConfigurationOptions("width", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                product = venetianUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);

                product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("wand", product, itemIndex, null);
                product = venetianUtil.updateFormError("drop", product, itemIndex);
                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                }
                product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);
                break;
            case "slatType":
                optionIndex = findIndex(product.items[itemIndex].configuration.slatType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.slatType.selected.value = product.items[itemIndex].configuration.slatType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.slatType.selected.dropdownValue = product.items[itemIndex].configuration.slatType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("slatColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                    product = venetianUtil.updateRules("width", product, itemIndex, order);
                    product = venetianUtil.updateRules("drop", product, itemIndex, order);
                    product = venetianUtil.updateRules("holdDownBracket", product, itemIndex, order);
                }
                break;
            case "slatColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.slatColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.slatColour.selected.value = product.items[itemIndex].configuration.slatColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.slatColour.selected.dropdownValue = product.items[itemIndex].configuration.slatColour.selected.value.optionKey;
                }
                break;
            case "tiltControl":
                optionIndex = findIndex(product.items[itemIndex].configuration.tiltControl.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.tiltControl.selected.value = product.items[itemIndex].configuration.tiltControl.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.tiltControl.selected.dropdownValue = product.items[itemIndex].configuration.tiltControl.selected.value.optionKey;
                }
                break;
            case "liftControl":
                optionIndex = findIndex(product.items[itemIndex].configuration.liftControl.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.liftControl.selected.value = product.items[itemIndex].configuration.liftControl.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.liftControl.selected.dropdownValue = product.items[itemIndex].configuration.liftControl.selected.value.optionKey;
                }
                break;
            case "holdDownBracket":
                product.items[itemIndex].configuration.holdDownBracket.selected.value = change;
                break;
            default:
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = venetianUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{minWidth: 45}}>
                                        #
                                    </th>
                                    <th className="text-center" style={{minWidth: 135}}>
                                        Location
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Quantity
                                    </th>
                                    <th className="text-center" style={{minWidth: 200}}>
                                        Type
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Width(mm)
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Drop(mm)
                                    </th>
                                    <th className="text-center" style={{minWidth: 160}}>
                                        Colour
                                    </th>
                                    <th className="text-center" style={{minWidth: 110}}>
                                        Lift Control
                                    </th>
                                    <th className="text-center" style={{minWidth: 110}}>
                                        Tilt Control
                                    </th>
                                    <th className="text-center" style={{minWidth: 225}}>
                                        Mount
                                    </th>
                                    <th className="text-center" style={{minWidth: 50}}>
                                        Unit Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 50}}>
                                        Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 120}}>
                                        Action
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 90}}>
                                                <Button color={"link"}
                                                        onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                            </th>
                                            : null
                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="text" name="location"
                                                                   value={item.configuration.location.selected.value}
                                                                   onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                                   placeholder="location"/>
                                                            {
                                                                item.configuration.location.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                            }
                                                            {
                                                                item.configuration.location.formError.isWarning
                                                                    ? <Badge color="warning">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="number" name="quantity"
                                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                                   value={item.configuration.quantity.selected.value}
                                                                   min={item.configuration.quantity.min}
                                                                   max={item.configuration.quantity.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="quantity"/>

                                                            {
                                                                item.configuration.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="slatType"
                                                           value={item.configuration.slatType.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "slatType", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.slatType.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="number" name="width"
                                                               invalid={!item.configuration.width.formError.isValid}
                                                               value={item.configuration.width.selected.value}
                                                               min={item.configuration.width.min}
                                                               max={item.configuration.width.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="width"/>
                                                    </div>
                                                    <small className="text-info">Neat window measurements only. Windoware will make deduction to suit.</small>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="number" name="drop"
                                                               invalid={!item.configuration.drop.formError.isValid}
                                                               value={item.configuration.drop.selected.value}
                                                               min={item.configuration.drop.min}
                                                               max={item.configuration.drop.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="drop"/>
                                                    </div>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="slatColour"
                                                           value={item.configuration.slatColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "slatColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.slatColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="liftControl"
                                                           value={item.configuration.liftControl.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "liftControl", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.liftControl.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="tiltControl"
                                                           value={item.configuration.tiltControl.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "tiltControl", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.tiltControl.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="mount"
                                                           value={item.configuration.mount.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {item.configuration.holdDownBracket.selected.isVisible
                                                        ?
                                                        <div className="mt-1">
                                                            <Button
                                                                size={"sm"}
                                                                className={"mr-2 mb-1"}
                                                                color={"link"}
                                                                disabled={!item.configuration.holdDownBracket.selected.isSelectable}
                                                                onClick={() => this.handleChange(!item.configuration.holdDownBracket.selected.value, "holdDownBracket", itemIndex, true)}>
                                                                <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": item.configuration.holdDownBracket.selected.value,
                                                                        "fa-square-o": !item.configuration.holdDownBracket.selected.value,
                                                                    }
                                                                )}/>
                                                            </Button>
                                                            {item.configuration.holdDownBracket.attributeLabel}
                                                        </div>
                                                        : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null

                                                }

                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={11}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <VenetianBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <VenetianConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        :
                        null

                    }

                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
