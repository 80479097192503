import React, {Component} from 'react';
import {
    Button, Spinner, Table,
    ModalBody, Modal, ModalHeader, Input, InputGroup, InputGroupAddon, InputGroupText, ModalFooter,
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import retailService from "../../services/retail/RetailService";
import UserService from '../../services/UserService';
import {handleErrorMessage, findIndex} from "../../services/CommonService";

export default class DebtorRetailPreApprovedDiscountModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountID: this.props.accountID,
            selectedRetailPreDiscount: this.props.selectedRetailPreDiscount,
            isLoadingRecords: false,
            isOpenModal: false,
            userStaffMembers: []
        };
        this.userService = new UserService();
        this.handleChange = this.handleChange.bind(this);
        this.getUserListByAccountID = this.getUserListByAccountID.bind(this);
        this.updateRetailPreDiscount = this.updateRetailPreDiscount.bind(this);
    }

    componentDidMount() {
        this.getUserListByAccountID();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.selectedRetailPreDiscount !== nextProps.selectedRetailPreDiscount) {
            this.setState({
                    accountID: nextProps.accountID,
                    selectedRetailPreDiscount: nextProps.selectedRetailPreDiscount
                },
                () => this.getUserListByAccountID(nextProps.accountID));
        }
    }

    getUserListByAccountID() {
        let {accountID, selectedRetailPreDiscount} = this.props;
        this.setState({isLoadingRecords: true});
        this.userService.getUserListByAccountID(accountID).then(response => {
            let userStaffMembers = response.data;
            userStaffMembers.forEach(user => {
                let retailPreDiscountIndex = findIndex(selectedRetailPreDiscount, 'userID', user.userId);
                if (retailPreDiscountIndex > -1) {
                    user.discount = selectedRetailPreDiscount[retailPreDiscountIndex].discount;
                }
            });
            this.setState({userStaffMembers, selectedRetailPreDiscount, isLoadingRecords: false});
        }).catch(error => {
            this.setState({isLoadingRecords: false});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key, index) {
        let {userStaffMembers, accountID} = this.state;
        if (index > -1 && userStaffMembers) {
            switch (key) {
                case "discount":
                default:
                    if (change === '' || change === null) {
                        userStaffMembers[index][key] = 0;
                    }
                    userStaffMembers[index].accountID = accountID;
                    userStaffMembers[index][key] = change;
                    this.setState({userStaffMembers});
            }
        }
    }

    updateRetailPreDiscount() {
        let {userStaffMembers, accountID} = this.state;
        let {toggle, isOpen} = this.props;
        this.setState({isLoadingRecords: true});
        userStaffMembers = userStaffMembers.filter(x => x.discount);
        retailService.savePreApprovedDiscount(userStaffMembers, accountID).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success('Saved!');
                this.setState({isLoadingRecords: false});
                toggle(!isOpen);
                this.props.refreshAgain();
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingRecords: false});
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {isLoadingRecords, userStaffMembers, selectedRetailPreDiscount} = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {selectedRetailPreDiscount.length > 0 ? "Edit" : "Add"} Pre-Approved Discount
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <Table className={"table table-sm table-bordered table-striped"}>
                            <thead>
                            <tr>
                                <th>Staff Member</th>
                                <th>Discount</th>

                            </tr>
                            </thead>
                            <tbody>
                            {(userStaffMembers || []).map((user, index) =>
                                <tr key={index}>
                                    <td><Input disabled
                                               type={"select"}
                                               id="userID"
                                               name="userID" style={{textTransform: 'capitalize'}}>
                                        <option value={""} disabled={true}>Select</option>
                                        {
                                            <option value={user.userID}>{user.firstName} {user.lastName}</option>
                                        }
                                    </Input></td>
                                    <td className={"text-right"}>
                                        <InputGroup>
                                            <Input
                                                type={"number"}
                                                placeholder={"discount"}
                                                name={"discount"}
                                                value={user.discount ? user.discount : null}
                                                onChange={(e) => this.handleChange(e.target.value, 'discount', index)}
                                            >
                                            </Input>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </td>

                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {userStaffMembers.length > 0 ?
                        <div className="text-right">
                            <Button color={"primary"} size={"sm"} onClick={() => this.updateRetailPreDiscount()}
                                    disabled={isLoadingRecords}>
                                {
                                    isLoadingRecords ? <Spinner size="sm"
                                                                className={"mr-2"}
                                                                style={{color: "white"}}/>
                                        : <i className="fa fa-floppy-o mr-2"/>
                                }
                                {selectedRetailPreDiscount.length > 0 ? "Update" : "Save"}
                            </Button>&nbsp;
                            <Button color={"secondary"} size="sm"
                                    onClick={() => toggle(!isOpen)}><i class="fa fa-times mr-2"/>Cancel</Button>
                        </div>
                        : <div className="text-right">
                            <Button color={"secondary"} size="sm"
                                    onClick={() => toggle(!isOpen)}><i class="fa fa-times mr-2"/>Close</Button>
                        </div>
                    }
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        );
    }
}