import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Table,
    Button
} from "reactstrap";
import NumberFormat from "react-number-format";
import {scrollTo} from "../../services/CommonService";

export default class SummaryTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    render() {
        let {rows, summaryOn} = this.props;
        let rowStyle = {
            backgroundColor: "",
            fontWeight: "",
            borderTop: ""

        };

        return (
            <div>
                <Card>
                    <CardHeader>
                        <h5>Outstanding Orders Summary at {summaryOn} </h5>
                    </CardHeader>

                    <CardBody>
                        <Container fluid={true}>
                            <div style={{overflow: "auto", overflowY: "hidden"}} className="table-responsive">
                                <Table bordered size={"sm"}>
                                    <thead>
                                    <tr>
                                        <th>Indoor/Outdoor</th>
                                        <th>Category</th>
                                        <th>OverDue Category</th>
                                        <th>Total Orders</th>
                                        <th>Total Units</th>
                                        <th>Order Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (rows || []).map((row, index) => {
                                            rowStyle = {
                                                backgroundColor: row.backgroundColor,
                                                fontWeight: row.label === "Total" ? "bold" : "normal",
                                                borderTop: row.label === "Total" ? "solid" : "unset",
                                            };
                                            return (<tr key={index} style={rowStyle}>
                                                {(row.isIndoorRowSpan) ?
                                                    <td rowSpan={row.indoorRowSpan} style={{
                                                        backgroundColor: "steelblue",
                                                        color: "white",
                                                    }}>{row.indoorLabel}</td> : null}
                                                {row.isProductRowSpan ?
                                                    <td rowSpan={row.productRowSpan} style={{
                                                        backgroundColor: "steelblue",
                                                        color: "white"
                                                    }}><Button onClick={() => scrollTo('#' + row.productLabel)}
                                                               color={"secondary"}>{row.productLabel}</Button>
                                                    </td> : null}
                                                <td colSpan={row.colSpan}>{row.label}</td>

                                                <td> {row.totalOrders}</td>
                                                <td> {row.totalUnits}</td>
                                                <td><NumberFormat value={Math.floor(row.totalValue)}
                                                                  displayType={'text'}
                                                                  thousandSeparator={true}
                                                                  decimalScale={2}
                                                                  fixedDecimalScale={true}
                                                                  prefix={'$'}/>
                                                </td>
                                            </tr>);
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </Container>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

