import axios from '../axios';
import axiosFileUploader from '../axiosFileUploader';

class RetailService {

    static Instance() {
        return new RetailService();
    }

    getProductMarkups(AccountID) {
        let url = 'api/debtor/retail/product/markup/list?AccountID=' + AccountID;
        return axios.get(url);
    }

    saveProductMarkups(request, AccountID) {
        return axios.post('api/debtor/retail/product/markup/create?AccountID=' + AccountID, request);
    }

    getDebtorsRetailDiscount(AccountID) {
        let url = 'api/debtor/retail/discount/list?AccountID=' + AccountID;
        return axios.get(url);
    }

    getDebtorsRetailPreference(AccountID) {
        let url = 'api/debtor/retail/other/charges?AccountID=' + AccountID;
        return axios.get(url);
    }

    RetailBoardAllowed() {
        return axios.get('api/debtor/retail/visible/bit');
    }

    uploadCompanyLogo(requestBody) {
        return axiosFileUploader.post('api/debtor/retail/logo/upload', requestBody);
    }

    saveDebtorRetailOtherCharges(request, AccountID) {
        return axios.post('api/debtor/retail/add/other/charges?AccountID=' + AccountID, request);
    }

    savePreApprovedDiscount(request, AccountID) {
        return axios.post('api/debtor/retail/preApproved/discount?AccountID=' + AccountID, request);
    }

    getDebtorsBasicDetail(accountID) {
        return axios.get('api/customer/basic/detail/logo?AccountID=' + accountID);
    }

    getUserPreApprovedDiscountByUserID(accountID, userID) {
        return axios.get('api/debtor/retail/preApproved/discount/by/userID?accountID=' + accountID + '&userID=' + userID);
    }
}

export default RetailService.Instance();