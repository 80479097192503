export const measurementUnits = [
    {
        label: "unit",
        value: "unit",
        isCutLength: false
    }, {
        label: "pair",
        value: "pair",
        isCutLength: false
    }, {
        label: "mm",
        value: "mm",
        isCutLength: true
    }, {
        label: "cm",
        value: "cm",
        isCutLength: true
    }, {
        label: "mtr",
        value: "mtr",
        isCutLength: true
    },
];

export const measurementUnitByUnit = {
    "unit": {
        label: "unit",
        value: "unit",
        isCutLength: false
    },
    "pair": {
        label: "pair",
        value: "pair",
        isCutLength: false
    },
    "mm": {
        label: "mm",
        value: "mm",
        isCutLength: true
    },
    "cm": {
        label: "cm",
        value: "cm",
        isCutLength: true
    },
    "mtr": {
        label: "mtr",
        value: "mtr",
        isCutLength: true
    }
};