import React, {Component} from 'react';
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import {toast} from "react-toastify";
import productService from '../../services/product/ProductService';
import {handleErrorMessage, removeDuplicates} from '../../services/CommonService';
import {cloneDeep} from 'lodash';

export default class ManageProductModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            selectedItem: cloneDeep(this.props.selectedItem),
            formErrors: {}
        };
    }

    handleChange(key, value) {
        let {selectedItem} = this.state;
        selectedItem[key] = value;
        this.setState({selectedItem});
    }

    validateForm(item) {
        let formErrors = {};
        let valid = true;

        if (!item.name) {
            formErrors.name = 'Please enter name';
            valid = false;
        }

        if (!item.code) {
            formErrors.code = 'Please enter code';
            valid = false;
        }

        if (!item.shortName) {
            formErrors.shortName = 'Please enter short name';
            valid = false;
        }

        if (!item.displayName) {
            formErrors.displayName = 'Please enter display name';
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    saveProduct() {

        let {selectedItem} = this.state;
        let result = this.validateForm(selectedItem);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }

        if (!selectedItem.sortIndex) {
            selectedItem.sortIndex = 0;
        }

        if (!selectedItem.parentProductID) {
            selectedItem.parentProductID = null;
        }

        this.setState({saving: true, formErrors: {}});
        if (selectedItem.visibleForAccountIDs) {
            selectedItem.visibleForAccountIDs = selectedItem.visibleForAccountIDs.trim();
            let visibleForAccountIDs = selectedItem.visibleForAccountIDs.split(/[\s,]+/);
            visibleForAccountIDs = removeDuplicates(visibleForAccountIDs);
            selectedItem.visibleForAccountIDs = visibleForAccountIDs.join(",");
        }
        productService.saveProduct(selectedItem, selectedItem.productGroupID).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({saving: false});
                toast.success("Saved successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.refreshAgain();
            }
        }).catch(error => {
            this.setState({saving: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggle, products, productGroups} = this.props;
        let {selectedItem, saving, formErrors} = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {selectedItem && selectedItem.id ? 'Edit' : 'Add'} Product
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Name*</Label>
                                <Input type="text" name="name"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem ? selectedItem.name : null}
                                       placeholder="Enter name here"/>
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="shortName">ShortName*</Label>
                                <Input type="text" name="shortName"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem ? selectedItem.shortName : null}
                                       placeholder="Enter short name here"/>
                                <FormText color="danger">{formErrors.shortName}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="code">Code*</Label>
                                <Input type="text" name="code"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem ? selectedItem.code : null}
                                       placeholder="Enter code here"/>
                                <FormText color="danger">{formErrors.code}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="displayName">Display Name*</Label>
                                <Input type="text" name="displayName"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem ? selectedItem.displayName : null}
                                       placeholder="Enter display name here"/>
                                <FormText color="danger">{formErrors.displayName}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="productGroupID">Product Group</Label>
                                <Input type="select" name="productGroupID"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem ? selectedItem.productGroupID : null}>
                                    <option value=''>Select</option>
                                    {
                                        (productGroups || []).map((productgroup, i) => (
                                            <option key={i}
                                                    value={productgroup.productGroupID}>{productgroup.productGroupName}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="parentProductID">Parent product</Label>
                                <Input type="select" name="parentProductID"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem && selectedItem.parentProductID ? selectedItem.name : null}>
                                    <option value=''>Select</option>
                                    {
                                        (products || []).map((product, i) => (
                                            <option key={i} value={product.id}>{product.name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="sortIndex">Sort Index</Label>
                                <Input type="number" name="sortIndex"
                                       placeholder={"Write sort index here"}
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem ? selectedItem.sortIndex : ''}/>
                            </FormGroup>
                        </Col>

                        <Col xl={'8'} lg={'8'} md={'8'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="visibleForAccountIDs">Account IDs</Label>
                                <Input type="textarea" name="visibleForAccountIDs"
                                       rows={12}
                                       placeholder="add comma separated accountIDs"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={selectedItem ? selectedItem.visibleForAccountIDs : null}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6} lg={6} md={'6'} sm={'12'} xs={'12'}>
                            <p>Is visible on prod environment?</p>
                            <div className='ml-2'>
                                <Label>
                                    <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("isVisibleOnProductBuilderToInternalUserOnProdEnvironment", !selectedItem.isVisibleOnProductBuilderToInternalUserOnProdEnvironment)}>
                                        {
                                            selectedItem && selectedItem.isVisibleOnProductBuilderToInternalUserOnProdEnvironment
                                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                     aria-hidden="true"/>
                                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                                        }
                                    </Button>
                                    To Internal User
                                </Label>
                            </div>

                            <div className='ml-2'>
                                <Label>
                                    <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("isVisibleOnProductBuilderToExternalCustomerOnProdEnvironment", !selectedItem.isVisibleOnProductBuilderToExternalCustomerOnProdEnvironment)}>
                                        {
                                            selectedItem && selectedItem.isVisibleOnProductBuilderToExternalCustomerOnProdEnvironment
                                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                     aria-hidden="true"/>
                                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                                        }
                                    </Button>
                                    To External Customer
                                </Label>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={'12'} xs={'12'}>
                            <p>Is visible on test environment?</p>
                            <div className='ml-2'>
                                <Label>
                                    <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("isVisibleOnProductBuilderToInternalUserOnTestEnvironment", !selectedItem.isVisibleOnProductBuilderToInternalUserOnTestEnvironment)}>
                                        {
                                            selectedItem && selectedItem.isVisibleOnProductBuilderToInternalUserOnTestEnvironment
                                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                     aria-hidden="true"/>
                                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                                        }
                                    </Button>
                                    To Internal User
                                </Label>
                            </div>
                            <div className='ml-2'>
                                <Label>
                                    <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("isVisibleOnProductBuilderToExternalCustomerOnTestEnvironment", !selectedItem.isVisibleOnProductBuilderToExternalCustomerOnTestEnvironment)}>
                                        {
                                            selectedItem && selectedItem.isVisibleOnProductBuilderToExternalCustomerOnTestEnvironment
                                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                     aria-hidden="true"/>
                                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                                        }
                                    </Button>
                                    To External Customer
                                </Label>
                            </div>

                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.saveProduct()} disabled={saving}>
                            {saving
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           style={{color: "white"}}/>
                                : <i className="fa fa-floppy-o mr-2"/>}
                            Save</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times"/>&nbsp;
                            Cancel</Button>

                    </div>
                </ModalFooter>

            </Modal>
        );
    }
}