import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Input, InputGroup, InputGroupText, InputGroupAddon, Spinner, FormText, Badge } from 'reactstrap';
import queryString from 'query-string';
import { Link as Link } from "react-router-dom";
import OrderService from "../../services/OrderService";
import { handleErrorMessage, changeFormatOfDateString } from '../../services/CommonService';
import { toast, ToastContainer } from 'react-toastify';
import UploadFiles from "../../components/attachment/UploadFiles";
import UserService from '../../services/UserService';

export default class ReworkOrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canEditReworkDetails: false,
            reworkOrder: props.reworkOrder,
            submitTried: false,
            formErrors: {},
            isLoading: false,
            reworkOrderTypes: [],
        };
        this.orderService = new OrderService();
        this.userService = new UserService();
        this.isValidate = this.isValidate.bind(this);
        this.updateReworkDetails = this.updateReworkDetails.bind(this);
    }

    componentDidMount() {
        let { currentUser } = this.props;
        let { reworkOrder } = this.state;
        this.orderService.getReworkOrderTypes().then(response => {
            this.setState({ reworkOrderTypes: response.data });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
        if (!reworkOrder.isApproved || (currentUser && this.userService.hasPrivilege(currentUser, "rework_order_write"))) {
            this.setState({ canEditReworkDetails: true });
        }
    }

    isValidate(reworkOrder) {
        let formErrors = {};
        let valid = true;
        if (!reworkOrder.reworkOrderTypeID) {
            formErrors.reworkOrderTypeID = "Reason is required";
            valid = false;
        }
        if (!reworkOrder.notes) {
            formErrors.notes = "Enter Claim Details";
            valid = false;
        }
        if (reworkOrder.discount === "" || reworkOrder.discount < 0 || reworkOrder.discount > 100) {
            formErrors.discount = "Price Waived % must be between 0 and 100";
            valid = false;
        }
        return { valid: valid, formErrors: formErrors };
    }

    handleChange(key, change) {
        let { reworkOrder, submitTried } = this.state;
        let formErrors = {};
        switch (key) {
            default:
                reworkOrder[key] = change;
        }
        if (submitTried) {
            let result = this.isValidate(reworkOrder);
            formErrors = result.formErrors;
        }

        this.setState({ reworkOrder, formErrors });
    }

    updateReworkDetails() {
        let { reworkOrder } = this.state;
        let result = this.isValidate(reworkOrder);
        this.setState({ formErrors: result.formErrors, submitTried: true });
        if (!result.valid) {
            return;
        }
        let request = {
            notes: reworkOrder.notes,
            reworkOrderTypeID: reworkOrder.reworkOrderTypeID,
            reworkOrdNum: reworkOrder.reworkOrdNum,
            discount: reworkOrder.discount,
            attachmentIDs: reworkOrder.attachmentIDs
        };
        this.setState({ isLoading: true });
        this.orderService.updateReworkOrderDetails(request).then(response => {
            let res = response.data;
            if (res) {
                this.setState({ reworkOrder: res, isLoading: false });
                toast.success("Details Updated");
                this.props.getOrder(this.props.order.ordNum, "current");
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
            this.setState({ isLoading: false });
        });
    }

    render() {
        let { isOpen, toggle, getOrder } = this.props;
        let { isLoading, reworkOrder, reworkOrderTypes, formErrors, canEditReworkDetails } = this.state;

        return (
            <div>
                <Modal isOpen={isOpen} size={"md"} scrollable={false} toggle={() => toggle(!isOpen)}>
                    <ModalHeader toggle={() => toggle(!isOpen)}>Order No. {reworkOrder.reworkOrdNum}</ModalHeader>
                    <ModalBody>
                        <div>
                            <Table responsive size={"sm"} >
                                <thead>
                                    <tr><th colSpan={2}>Original Order Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">Original Order</td>
                                        <td className="align-middle text-right">
                                            <Link
                                                style={{ color: "white" }}
                                                title={"Original Order"}
                                                className={"btn btn-sm btn-primary"}
                                                onClick={() => getOrder(reworkOrder.originalOrdNum, "current")}
                                                to={"/sales/order/view?" + queryString.stringify({ OrdNum: reworkOrder.originalOrdNum })}>
                                                {reworkOrder.originalOrdNum}
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Invoice Date</td>
                                        <td className="align-middle text-right">
                                            {changeFormatOfDateString(reworkOrder.invoiceDate, "DD/MM/YYYY", 'DD MMM YYYY')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Ship Date</td>
                                        <td className="align-middle text-right">
                                            {changeFormatOfDateString(reworkOrder.orderShipDate, "DD/MM/YYYY", 'DD MMM YYYY')}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table responsive size={"sm"} >
                                <thead>
                                    <tr><th colSpan={2}>Rework Order Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">Rework Order</td>
                                        <td className="align-middle text-right">
                                            <Link
                                                style={{ color: "white" }}
                                                title={"Rework Order"}
                                                className={"btn btn-sm btn-primary"}
                                                onClick={() => getOrder(reworkOrder.reworkOrdNum, "current")}
                                                to={"/sales/order/view?" + queryString.stringify({ OrdNum: reworkOrder.reworkOrdNum })}>
                                                {reworkOrder.reworkOrdNum}
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Rework Reason</td>
                                        <td className="align-middle text-right">
                                            <Input type={"select"} name={"reworkOrderTypeID"}
                                            value={reworkOrder.reworkOrderTypeID}
                                            className={"mb-1"}
                                            disabled={!canEditReworkDetails}
                                            onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                            {(reworkOrderTypes || []).map((reworkOrderType, rIndex) => <option value={reworkOrderType.id} key={rIndex}>{reworkOrderType.name}</option>)}
                                        </Input>
                                            <FormText color="danger">{formErrors.reworkOrderTypeID}</FormText>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Reason Description</td>
                                        <td className="align-middle text-right">
                                            {reworkOrder.reworkOrderType ? reworkOrder.reworkOrderType.description : ""}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Rework Notes</td>
                                        <td className="align-middle text-right">
                                            <Input type="text" name="notes" disabled={!canEditReworkDetails} onChange={(e) => this.handleChange(e.target.name, e.target.value)} value={reworkOrder.notes} placeholder="Rework Notes" />
                                            <FormText color="danger">{formErrors.notes}</FormText>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Claim Date</td>
                                        <td className="align-middle text-right">
                                            {changeFormatOfDateString(reworkOrder.dateCreated, "DD/MM/YYYY", 'DD MMM YYYY')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Warranty No.</td>
                                        <td className="align-middle text-right">
                                            <Badge color={"warning"}>{reworkOrder.warrantyNo}</Badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Price Waived %</td>
                                        <td className="align-middle text-right">
                                            <InputGroup>
                                                <Input name={"discount"} value={reworkOrder.discount}
                                                    type={"number"}
                                                    invalid={reworkOrder.discount < 0 || reworkOrder.discount > 100}
                                                    disabled={!canEditReworkDetails}
                                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText><i className="fa fa-percent ml-2"
                                                        aria-hidden="true" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <FormText color="danger">{formErrors.discount}</FormText>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">Attachments</td>
                                        <td className="align-middle text-right">
                                            <UploadFiles
                                                handleChange={(change) => this.handleChange("attachmentIDs", change)}
                                                attachmentIDs={reworkOrder.attachmentIDs}
                                                multiple={true}
                                                maxLength={12}
                                                isReadOnly={!canEditReworkDetails}
                                                category={"WARRANTY_CLAIM"}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="text-right">
                            {canEditReworkDetails ?
                                <Button color={"primary"}
                                    size="sm"
                                    className="ml-2"
                                    disabled={isLoading}
                                    onClick={this.updateReworkDetails}>
                                    {isLoading ? <Spinner size="sm"
                                        className={"mr-2"}
                                        color={"light"} />
                                        : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />}
                                    {isLoading ? "Updating" : "Update"}
                                </Button>
                                : null}
                            <Button color={"secondary"} size="sm" className="ml-2" disabled={isLoading} onClick={() => this.props.toggle(false)}><i className="fa fa-times mr-2" />Close</Button>
                        </div>
                    </ModalFooter>
                    <ToastContainer />
                </Modal>
            </div>
        );
    }
}