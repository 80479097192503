import React, {Component} from "react";
import {Table} from 'reactstrap';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import {getSorted} from "../../../services/CommonService";
import {stockReceiptItemStore} from "../../../store/StockReceiptEnquiryPageItemStore";

export default class StockReceiptItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortRequest: {
                key: "",
                direction: false
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(type, param, change) {
        let {sortRequest} = this.state;
        switch (type) {
            case "sortRequest":
                if (change.sorterApplicable) {
                    if (sortRequest.key === change.key) {
                        sortRequest.direction = !sortRequest.direction;
                    } else {
                        sortRequest.key = change.key;
                        sortRequest.direction = false;
                    }
                    this.setState({sortRequest});
                }
        }
    }

    render() {
        let {stockReceipt} = this.props;
        let {sortRequest} = this.state;

        let stockReceiptItems = getSorted(((stockReceipt && stockReceipt.stockReceiptItems) || []), sortRequest.key, sortRequest.direction);
        let store = stockReceiptItemStore;
        return (
            <div>
                <Table responsive striped bordered size={"sm"} hover={true}>
                    <thead >
                    <tr>
                        {(store || []).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={() => this.handleChange("sortRequest", "key", item)}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}>
                                    <div>
                                        {item.renderLabel((stockReceiptItems || []), this)}
                                        {
                                            item.sorterApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                )} aria-hidden="true"/> : null
                                        }

                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    {
                        (stockReceiptItems || []).map((item, itemIndex) => {
                            return <tbody key={itemIndex}>
                            <tr>
                                {(store || []).map((column, columnIndex) => {
                                    return (
                                        <td key={columnIndex}
                                            className={column.getValueClassName(itemIndex, stockReceiptItems, this)}>
                                            {column.render(itemIndex, stockReceiptItems, this)}
                                        </td>
                                    );
                                })}
                            </tr>
                            </tbody>
                        })
                    }
                </Table>
                <ToastContainer/>
            </div>
        );
    }
}