import axios from '../axios';
import axiosFileDownloader from "../axiosFileDownloader";
import axiosFileUploader from "../axiosFileUploader";

class ProductConfigurationService {

    static Instance() {
        return new ProductConfigurationService()
    }

    getProductFabricCombinationsV1(productID) {
        return axios.get('api/product/configuration/fabric/v1/list?productID=' + productID)
    }

    saveProductFabricCombinationV1(item) {
        if (item.id) {
            return axios.put('api/product/configuration/fabric/v1/update', item)
        } else {
            return axios.post('api/product/configuration/fabric/v1/save', item)
        }
    }

    /*Configuration Starts*/
    getProductConfiguration(id) {
        return axios.get('api/product/configuration/one?id=' + id)
    }

    getProductConfigurations(productID) {
        return axios.get('api/product/configuration/list?productID=' + productID)
    }

    saveProductConfiguration(item) {
        if (item.id) {
            return axios.put('api/product/configuration/update', item)
        } else {
            return axios.post('api/product/configuration/save', item)
        }
    }

    deleteProductConfiguration(productConfigurationID) {
        return axios.delete('api/product/configuration?productConfigurationID=' + productConfigurationID);
    }

    deleteProductConfigurationOption(productConfigurationOptionID) {
        return axios.delete('api/product/configuration/option?productConfigurationOptionID=' + productConfigurationOptionID);
    }

    deleteProductConfigurationOptionSet(productConfigurationOptionSetID) {
        return axios.delete('api/product/configuration/option/set?productConfigurationOptionSetID=' + productConfigurationOptionSetID);
    }

    deleteProductConfigurationStock(productConfigurationStockID) {
        return axios.delete('api/product/configuration/stock?productConfigurationStockID=' + productConfigurationStockID);
    }

    deleteProductConfigurationFabric(productConfigurationFabricID) {
        return axios.delete('api/product/configuration/fabric?productConfigurationFabricID=' + productConfigurationFabricID);
    }

    deleteProductConfigurationPriceMatrix(bomCode, accountID, variantType) {
        return axios.delete('api/product/configuration/pricing?bomCode=' + bomCode + '&accountID=' + accountID + '&variantType=' + variantType);
    }

    getProductConfigurationOption(id) {
        return axios.get('api/product/configuration/option/one?id=' + id)
    }

    getProductConfigurationOptions(req) {
        return axios.post('api/product/configuration/option/list', req);
    }

    saveProductConfigurationOption(item) {
        if (item.id) {
            return axios.put('api/product/configuration/option/update', item)
        } else {
            return axios.post('api/product/configuration/option/save', item)
        }
    }

    getProductConfigurationOptionSets(req) {
        return axios.post('api/product/configuration/option/set/list', req)
    }

    saveProductConfigurationOptionSet(item) {
        if (item.id) {
            return axios.put('api/product/configuration/option/set/update', item)
        } else {
            return axios.post('api/product/configuration/option/set/save', item)
        }
    }

    getProductConfigurationStockFormulas(req) {
        return axios.post('api/product/configuration/stock/formula/list', req);
    }

    getOneProductConfigurationStockFormula(productConfigurationStockFormulaID) {
        return axios.get('api/product/configuration/stock/formula/one?ProductConfigurationStockFormulaID=' + productConfigurationStockFormulaID);
    }

    saveProductConfigurationStockFormula(req) {
        return axios.post('api/product/configuration/stock/formula/save', req);
    }

    updateProductConfigurationStockFormula(req) {
        return axios.put('api/product/configuration/stock/formula/update', req);
    }

    getProductConfigurationStockQtyFormulaDropdown(req) {
        return axios.post('api/product/configuration/stock/formula/qty/dropdown', req);
    }

    getProductConfigurationStockWastageFormulaDropdown(req) {
        return axios.post('api/product/configuration/stock/formula/wastage/dropdown', req);
    }

    saveProductConfigurationStockForOptions(req, fabricColorID) {
        if (fabricColorID) {
            return axios.post('api/product/configuration/stock/save?fabricColorID=' + fabricColorID, req);
        }
        else {
            return axios.post('api/product/configuration/stock/save', req);
        }
    }

    getLayoutTypes(productID) {
        return axios.get('api/product/configuration/stock/deduction/layout?productID=' + productID);
    }

    getProductConfigurationStockDeductionList(productID, activeLayoutType) {
        return axios.get('api/product/configuration/stock/deduction/list?productID=' + productID + '&activeLayoutType=' + activeLayoutType);
    }

    getDeduction(productID) {
        return axios.get('api/product/configuration/stock/deduction/getDeduction?productID=' + productID);
    }

    saveStockDeduction(req, productID) {
        return axios.put('api/product/configuration/stock/deduction/manage/add?productID=' + productID, req);
    }

    deleteDeduction(id) {
        return axios.delete('api/product/configuration/stock/deduction/manage/delete?id=' + id);
    }

    getPriceMatrix(productID, BOMCode, AccountID, variantType) {
        return axios.get('api/product/configuration/price/matrix?productID=' + productID + '&BOMCode=' + BOMCode + '&AccountID=' + AccountID + '&VariantType=' + variantType);
    }

    getDistinctVariantsFromPriceMatrix(productID) {
        return axios.get('api/product/configuration/pricing/distinct/variants?productID=' + productID);
    }

    getDistinctBomCodesBasedOnProductId(productID, variantType) {
        return axios.get('api/product/configuration/pricing/distinct/bomcodes?productID=' + productID + '&variantType=' + variantType);
    }

    getDistinctBomCodesBasedOnProductIdWithAccount(productID, BOMCode, variantType) {
        return axios.get('api/product/configuration/pricing/distinct/bomcodes/with/Accounts?productID=' + productID + '&BOMCode=' + BOMCode + '&variantType=' + variantType);
    }

    downloadProductConfigurationPricingData(productID, accountID, variantType) {
        return axiosFileDownloader.get('api/product/configuration/pricing/data/download?productID=' + productID + '&accountID=' + accountID + '&variantType=' + variantType);
    }

    importPricingData(request, productID) {
        return axiosFileUploader.post('api/product/configuration/pricing/data/import?productID=' + productID, request);
    }

    getDistinctAccountIDsFromPriceMatrix(productID) {
        return axios.get('api/product/configuration/pricing/distinct/accounts?productID=' + productID);
    }

    importMatrixConverterData(request, xAxisLabel, yAxisLabel) {
        return axiosFileUploader.post('api/product/configuration/utility/convert/matrix/into/table?xAxisLabel=' + xAxisLabel + '&yAxisLabel=' + yAxisLabel, request);
    }
    saveProductConfigurationOptionSetList(request) {
        return axios.post('api/product/configuration/option/set/list/save', request)
    }
    saveProductConfigurationOptionList(request) {
        return axios.post('api/product/configuration/option/list/save', request)
    }
    exportProductConfigurationOptionInExcel(id) {
        return axiosFileDownloader.get('api/product/configuration/option/download/excel?productConfigurationId=' + id);
    }
    exportProductConfigurationOptionSetInExcel(id) {
        return axiosFileDownloader.get('api/product/configuration/option/set/download/excel?productConfigurationOptionId=' + id);
    }
    deleteMultiProductConfigurationOption(optionIds) {
        return axios.post('api/product/configuration/option/delete?optionIds=' + optionIds);
    }
    deleteMultiProductConfigurationOptionSet(setIds) {
        return axios.post('api/product/configuration/option/set/delete?setIds=' + setIds);
    }
}



export default ProductConfigurationService.Instance();