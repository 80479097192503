import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Button,
    Col,
    Input,

    Label,
    Row,
    Table,
    Spinner,
} from "reactstrap";

import SweetAlert from 'react-bootstrap-sweetalert';
import ProductionCapacityService from '../../services/production/ProductionCapacityService';


import {ifExist} from '../../services/CommonService';

export default class ProductionCapacity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productionLineNames: [],
            isCapacityByDateAvailable: false,
            workingProductionCapacity: {
                productionLineName: "",
                capacity: 0
            },
            defaultProductionCapacities: [],

            alert: {
                basicTitle: '',
                basicType: "default",
                showAlert: false,
                msg: "",
            },
            saving: false,

        };
        this.productionCapacityService = new ProductionCapacityService();
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
        this.closeAlert = this.closeAlert.bind(this);

    }

    componentDidMount() {
        this.productionCapacityService.getProductionLineDropDown().then(response => {
            this.setState({productionLineNames: response.data})
        });
        this.refresh();
    }


    closeAlert() {
        const alert = {...this.state.alert};
        alert.showAlert = false;
        this.setState({alert});
    }

    handleCancel() {
        this.setState({
            edit: false,
            workingProductionCapacity: {
                productionLineName: "",
                capacity: 0
            }
        });
    }

    handleEdit(productioncapacity) {
        this.setState({
            edit: true, workingProductionCapacity: {
                productionLineName: productioncapacity.productionLineName,
                capacity: productioncapacity.capacity
            },
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let production = {...this.state.workingProductionCapacity};
        production[name] = value;
        this.setState({workingProductionCapacity: production});
    }

    handleFocus(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let production = {...this.state.workingProductionCapacity};
        production[name] = value === "0" ? "" : value;
        this.setState({workingProductionCapacity: production});
    }

    handleBlur(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let production = {...this.state.workingProductionCapacity};
        production[name] = value === "" ? "0" : value;
        this.setState({workingProductionCapacity: production});
    }


    handleSubmit(event) {
        event.preventDefault();

        const requestData = {
            ProductionLineShortName: this.state.workingProductionCapacity.productionLineName,
            Capacity: this.state.workingProductionCapacity.capacity
        };
        this.setState({saving: true});
        this.productionCapacityService.saveDefaultProductionCapacity(requestData).then(res => {
            this.setState({
                edit: false,
                workingProductionCapacity: {
                    productionLineName: "",
                    capacity: 0
                },
                alert: {
                    showAlert: true, basicType: 'success', basicTitle: 'Successfully saved!', msg: "Success"
                },
                saving: false,
            });
            this.props.refreshAgain(true);
            this.refresh();
        }).catch(error => {
            console.log(error);
            this.setState({
                edit: false,
                workingProductionCapacity: {
                    productionLineName: "",
                    capacity: 0
                },
                alert: {
                    showAlert: true, basicType: 'danger', basicTitle: 'Error', msg: error.message
                },
                saving: false,
            });
        });
    }

    refresh() {
        this.setState({loading: true});
        this.productionCapacityService.getDefaultProductionCapacity().then(response => {
            this.setState({defaultProductionCapacities: response.data, loading: false});
        }).catch(error => {
            this.setState({loading: false});
        });
    }

    getProductionLineOptions(productionLineNames, defaultProductionCapacities) {
        let result = [];

        if (productionLineNames && productionLineNames.length > 0) {
            if (defaultProductionCapacities && defaultProductionCapacities.length > 0) {
                productionLineNames.map(productionLineName => {
                    if (!ifExist(defaultProductionCapacities, "productionLineName", productionLineName)) {
                        result.push(productionLineName);
                    }
                });
            } else {
                result = productionLineNames
            }
        }
        return result;
    }

    render() {
        const workingProductionCapacity = this.state.workingProductionCapacity;
        const productionCapacities = this.state.defaultProductionCapacities;
        const alert = this.state.alert;
        let productionLineOptions = [];
        if (this.state.edit) {
            productionLineOptions.push(this.state.workingProductionCapacity.productionLineName);
        } else {
            productionLineOptions = this.getProductionLineOptions(this.state.productionLineNames, this.state.defaultProductionCapacities);
        }

        let {saving} = this.state;

        return (
            <div>
                <SweetAlert show={alert.showAlert} type={alert.basicType} title={alert.basicTitle}
                            onConfirm={this.closeAlert}> {alert.msg} </SweetAlert>
                {this.state.loading ? <Spinner color="primary"/> : null}

                <Row>
                    {(productionCapacities.length > 0) ?
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Table className='table table-striped' size={'sm'}>
                                <thead>
                                <tr>
                                    <th>Production Line</th>
                                    <th>Capacity</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                        productionCapacities.map((productioncapacity, index) => {
                                            if (productioncapacity.productionLineName != "PPE") {
                                                 return <tr key={index}>
                                                     <td>{productioncapacity.productionLineName === 'COMPONENTS' ? 'W/H PICKS' : productioncapacity.productionLineName}
                                                </td>
                                                    <td>{productioncapacity.capacity}</td>
                                                    <td><Button size="sm" type="submit" color="primary"
                                                        onClick={() => this.handleEdit(productioncapacity)}>
                                                        <i className="fa fa-pencil mr-2" />Edit</Button>
                                                    </td>
                                                </tr>
                                            }
                                        }
                                    )
                                }
                                </tbody>
                            </Table>
                        </Col> : null
                    }

                    {productionLineOptions.length > 0 ? <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-absolute">
                            <CardHeader>
                                <h5> {this.state.edit ? "Update Capacity" : "Add Capacity"} </h5>
                            </CardHeader>
                            <CardBody>
                                <div className="pb-2">
                                    <Label>Production Line</Label>
                                    <Input type="select" name="productionLineName"
                                           value={workingProductionCapacity.productionLineName}
                                           onChange={this.handleChange}>
                                        <option key={"default"} value="" disabled={true}>Select</option>
                                        {
                                            productionLineOptions.map(option =>
                                                <option key={option} value={option}>{option}</option>)
                                        }
                                    </Input>
                                </div>
                                <div className="pb-2">
                                    <Label>Capacity</Label>
                                    <Input type="number" name="capacity" value={workingProductionCapacity.capacity}
                                           onChange={this.handleChange}
                                           onFocus={this.handleFocus}
                                           onBlur={this.handleBlur}
                                    />
                                </div>
                                <div className="text-right pb-2">
                                    <Button size="sm" color="secondary"
                                            onClick={this.handleCancel}
                                            hidden={!workingProductionCapacity.productionLineName.length > 0}>
                                        <i className="fa fa-times mr-2"/> Cancel</Button><span>&nbsp;&nbsp;</span>
                                    <Button size="sm" color="primary"
                                            disabled={!workingProductionCapacity.productionLineName.length > 0 || saving}
                                            onClick={this.handleSubmit}>
                                        {saving ? <Spinner size="sm"
                                                           className={"mr-2"}
                                                           style={{color: "white"}}/> :
                                            <i className="fa fa-floppy-o mr-2"/>}
                                        {this.state.edit ? "Update" : "Save"}</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col> : null}
                </Row>
            </div>
        );
    }
}

