import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    BUNNINGS_ACCOUNT_ID, PRODUCT_BUILDER_CUSTOM_BUILD, PRODUCT_BUILDER_CUSTOM_PART, PRODUCT_BUILDER_CUSTOM_PRODCODE,
    PRODUCT_BUILDER_ROLLER_BLIND_PRODCODE,
    PRODUCT_BUILDER_ROMAN_BLIND_PRODCODE,
    PACKAGING_AND_HANDLING_FEE_PRODCODE,
    LABOUR_CHARGE_PRODCODE,
    ALL_PRODCODES
} from "../../../../../../store/AppConstants";
import {
    findIndex, groupBy, groupItemBy, roundUp,
    roundUpToNearestOddNumber
} from "../../../../../../services/CommonService";
import keywayStockService from '../../../../../../services/KeywayStockService';

class CustomUtil {

    static Instance() {
        return new CustomUtil()
    }

    initItem(product, itemIndex, context, order, packagingAndHandling, discountByProductCode) {
        let {configurations} = product.builder;
        let configurationIndex;

        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //itemType
        product.items[itemIndex].configuration.itemType = {};
        product.items[itemIndex].configuration.itemType.selected = {};
        product.items[itemIndex].configuration.itemType.selected.value = context.salesOrderItemCustom.itemType;

        //location
        product.items[itemIndex].configuration.location = {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemCustom.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};

        //quantity
        product.items[itemIndex].configuration.quantity = {};
        product.items[itemIndex].configuration.quantity.min = 0;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemCustom.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        product.items[itemIndex].configuration.width = {};
        product.items[itemIndex].configuration.width.min = 0;// default
        product.items[itemIndex].configuration.width.max = 1000000;// default
        product.items[itemIndex].configuration.width.selected = {
            value: 0,
            finishWidth: 0
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemCustom.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1500;
        }
        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        product.items[itemIndex].configuration.drop = {};
        product.items[itemIndex].configuration.drop.min = 0;// default
        product.items[itemIndex].configuration.drop.max = 100000;// default
        product.items[itemIndex].configuration.drop.selected = {
            value: 0,
            finishDrop: 0
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemCustom.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }
        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //prodCode
        product.items[itemIndex].configuration.prodCode = {};
        product.items[itemIndex].configuration.prodCode.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.prodCode.selected.value = context.salesOrderItemCustom.prodCode;
        } else {
            product.items[itemIndex].configuration.prodCode.selected.value = "";
        }
        product.items[itemIndex].configuration.prodCode.formError = {isValid: true, message: "", dom: null,};

        //colour
        product.items[itemIndex].configuration.colour = {};
        product.items[itemIndex].configuration.colour.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.colour.selected.value = context.salesOrderItemCustom.colour;
        } else {
            product.items[itemIndex].configuration.colour.selected.value = "";
        }
        product.items[itemIndex].configuration.colour.formError = {isValid: true, message: "", dom: null,};

        //productCode
        product.items[itemIndex].configuration.productCode = {};
        product.items[itemIndex].configuration.productCode.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.productCode.selected.value = context.salesOrderItemCustom.productCode;
        } else {
            product.items[itemIndex].configuration.productCode.selected.value = "";
        }
        product.items[itemIndex].configuration.productCode.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //productGroupCode
        product.items[itemIndex].configuration.productGroupCode = {};
        product.items[itemIndex].configuration.productGroupCode.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.productGroupCode.selected.value = context.salesOrderItemCustom.productGroupCode;
        } else {
            product.items[itemIndex].configuration.productGroupCode.selected.value = "";
        }
        product.items[itemIndex].configuration.productGroupCode.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.model.selected.value = context.salesOrderItemCustom.model;
        } else {
            product.items[itemIndex].configuration.model.selected.value = "";
        }
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};


        //productionInstructions
        product.items[itemIndex].configuration.productionInstructions = {};
        product.items[itemIndex].configuration.productionInstructions.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.productionInstructions.selected.value = context.salesOrderItemCustom.productionInstructions;
        } else {
            product.items[itemIndex].configuration.productionInstructions.selected.value = "";
        }
        product.items[itemIndex].configuration.productionInstructions.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //productionNotes
        product.items[itemIndex].configuration.productionNotes = {};
        product.items[itemIndex].configuration.productionNotes.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.productionNotes.selected.value = context.salesOrderItemCustom.productionNotes;
        } else {
            product.items[itemIndex].configuration.productionNotes.selected.value = "";
        }
        product.items[itemIndex].configuration.productionNotes.formError = {isValid: true, message: "", dom: null,};

        //thumbnailImageAttachmentID
        product.items[itemIndex].configuration.thumbnailImageAttachmentID = {};
        product.items[itemIndex].configuration.thumbnailImageAttachmentID.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.thumbnailImageAttachmentID.selected.value = context.salesOrderItemCustom.thumbnailImageAttachmentID;
        } else {
            product.items[itemIndex].configuration.thumbnailImageAttachmentID.selected.value = "";
        }
        product.items[itemIndex].configuration.thumbnailImageAttachmentID.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //highQualityImageAttachmentID
        product.items[itemIndex].configuration.highQualityImageAttachmentID = {};
        product.items[itemIndex].configuration.highQualityImageAttachmentID.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.highQualityImageAttachmentID.selected.value = context.salesOrderItemCustom.highQualityImageAttachmentID;
        } else {
            product.items[itemIndex].configuration.highQualityImageAttachmentID.selected.value = "";
        }
        product.items[itemIndex].configuration.highQualityImageAttachmentID.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //description
        product.items[itemIndex].configuration.description = {};
        product.items[itemIndex].configuration.description.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.description.selected.value = context.salesOrderItemCustom.description;
        } else {
            product.items[itemIndex].configuration.description.selected.value = "";
        }
        product.items[itemIndex].configuration.description.formError = {isValid: true, message: "", dom: null,};

        //length
        product.items[itemIndex].configuration.length = {};
        product.items[itemIndex].configuration.length.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.length.selected.value = context.salesOrderItemCustom.length;
        } else {
            product.items[itemIndex].configuration.length.selected.value = "";
        }
        product.items[itemIndex].configuration.length.formError = {isValid: true, message: "", dom: null,};

        //dimensionUnitName
        product.items[itemIndex].configuration.dimensionUnitName = {};
        product.items[itemIndex].configuration.dimensionUnitName.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.dimensionUnitName.selected.value = context.salesOrderItemCustom.dimensionUnitName;
        } else {
            product.items[itemIndex].configuration.dimensionUnitName.selected.value = "";
        }
        product.items[itemIndex].configuration.dimensionUnitName.formError = {isValid: true, message: "", dom: null,};


        //eachUnitName
        product.items[itemIndex].configuration.eachUnitName = {};
        product.items[itemIndex].configuration.eachUnitName.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.eachUnitName.selected.value = context.salesOrderItemCustom.eachUnitName;
        } else {
            product.items[itemIndex].configuration.eachUnitName.selected.value = "";
        }
        product.items[itemIndex].configuration.eachUnitName.formError = {isValid: true, message: "", dom: null,};

        //sellUnitName
        product.items[itemIndex].configuration.sellUnitName = {};
        product.items[itemIndex].configuration.sellUnitName.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.sellUnitName.selected.value = context.salesOrderItemCustom.sellUnitName;
        } else {
            product.items[itemIndex].configuration.sellUnitName.selected.value = "";
        }
        product.items[itemIndex].configuration.sellUnitName.formError = {isValid: true, message: "", dom: null,};

        //sellUnitQty
        product.items[itemIndex].configuration.sellUnitQty = {};
        product.items[itemIndex].configuration.sellUnitQty.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.sellUnitQty.selected.value = context.salesOrderItemCustom.sellUnitQty;
        } else {
            product.items[itemIndex].configuration.sellUnitQty.selected.value = "";
        }
        product.items[itemIndex].configuration.sellUnitQty.formError = {isValid: true, message: "", dom: null,};

        //sellQtyPrice
        product.items[itemIndex].configuration.sellQtyPrice = {};
        product.items[itemIndex].configuration.sellQtyPrice.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.sellQtyPrice.selected.value = context.salesOrderItemCustom.sellQtyPrice;
        } else {
            product.items[itemIndex].configuration.sellQtyPrice.selected.value = "";
        }
        product.items[itemIndex].configuration.sellQtyPrice.formError = { isValid: true, message: "", dom: null };

        //price1
        product.items[itemIndex].configuration.price1 = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.price1.value = context.salesOrderItemCustom.price1;
        }

        if (!isEmpty(context)) {
            product.items[itemIndex].stocks = context.salesOrderItemCustom.stocks;
            if (context.salesOrderItemCustom.itemType === PRODUCT_BUILDER_CUSTOM_BUILD) {
                if (product.items[itemIndex].stocks.length > 0) {
                    let prodCodes = product.items[itemIndex].stocks.filter(s => !ALL_PRODCODES.includes(s.prodCode)).map(s => s.prodCode);
                    let stocks = [];
                    stocks.push(product.items[itemIndex].stocks[0]);
                    prodCodes.forEach((p, pIndex) => {
                        keywayStockService.getStock(p).then(response => {
                            let res = response.data.wwStock;
                            res.value = response.data.value;
                            res.label = response.data.label;
                            delete response.data.value;
                            delete response.data.label;
                            delete response.data.wwStock;
                            res.keywayStock = response.data;
                            res.keywayStock.wwStock = null;

                            product.items[itemIndex].stocks[pIndex + 1].unitPrice = res.keywayStock.eachUnitPrice;
                            product.items[itemIndex].stocks[pIndex + 1].whUnitQty = (["", "0", 0, 1, null, undefined].includes(res.keywayStock.whUnitQty) ? 0 : res.keywayStock.whUnitQty);
                            product.items[itemIndex].stocks[pIndex + 1].whUnitName = (["", "0", 0, null, undefined].includes(res.keywayStock.whUnitName) ? "N/A" : res.keywayStock.whUnitName);
                            product.items[itemIndex].stocks[pIndex + 1].keywayMeasurementUnit = product.items[itemIndex].stocks[pIndex + 1].whUnitName !== "N/A" && product.items[itemIndex].stocks[pIndex + 1].whUnitName;
                            product.items[itemIndex].stocks[pIndex + 1].keywayCalculatedQty = product.items[itemIndex].stocks[pIndex + 1].whUnitQty && parseFloat((product.items[itemIndex].stocks[pIndex + 1].swishCalculatedQty / product.items[itemIndex].stocks[pIndex + 1].whUnitQty).toFixed(4));
                            product.items[itemIndex].stocks[pIndex + 1].swishMeasurementUnit = res.keywayStock.eachUnitName;
                            product.items[itemIndex].stocks[pIndex + 1].swishCalculatedQty = parseFloat(product.items[itemIndex].stocks[pIndex + 1].swishCalculatedQty.toFixed(4));
                            product.items[itemIndex].stocks[pIndex + 1].discontinued = false;
                            product.items[itemIndex].stocks[pIndex + 1].soldOut = false;
                            product.items[itemIndex].stocks[pIndex + 1].qoh = res.qoh;
                            product.items[itemIndex].stocks[pIndex + 1].price1 = res.keywayStock.eachUnitPrice;
                            product.items[itemIndex].stocks[pIndex + 1].isNonStock = res.keywayStock.isNonStock;
                            product.items[itemIndex].stocks[pIndex + 1].searchCondition = res.keywayStock.isNonStock ? "services" : "allStocks";
                        }).catch(error => {
                            console.error(error);
                        });
                        stocks.push(product.items[itemIndex].stocks[pIndex + 1]);
                    });
                    product.items[itemIndex].stocks = stocks;
                }
            }
        } else {
            product.items[itemIndex].stocks = [];
        }

        product = this.updateFormError("location", product, itemIndex);
        product = this.updateFormError("quantity", product, itemIndex);
        product = this.updateFormError("width", product, itemIndex);
        product = this.updateFormError("drop", product, itemIndex);
        product = this.updateFormError("prodCode", product, itemIndex);
        product = this.updateFormError("productionInstructions", product, itemIndex);
        product = this.updateFormError("productionNotes", product, itemIndex);

        
        product = this.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

        return product;
    }

    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (!isEmpty(product.items[itemIndex].configuration.location.selected.value) &&
                    product.items[itemIndex].configuration.location.selected.value.length > 50) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than 50 characters",
                    };
                }
                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => ((x.configuration.location.selected.value) && (i.configuration.location.selected.value) && (x.configuration.location.selected.value === i.configuration.location.selected.value))).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < product.items[itemIndex].configuration.quantity.min)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity greater than " + product.items[itemIndex].configuration.quantity.min
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                /*if (product.items[itemIndex].configuration.itemType.selected.value === PRODUCT_BUILDER_CUSTOM_BUILD) {
                    if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                        };
                    }
                }*/
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                /*if (product.items[itemIndex].configuration.itemType.selected.value === PRODUCT_BUILDER_CUSTOM_BUILD) {
                    if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                        };
                    }
                }*/
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "prodCode":
                if (isEmpty(product.items[itemIndex].configuration.prodCode.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "ProdCode can't be empty",
                    };
                }
                product.items[itemIndex].configuration.prodCode.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.prodCode.formError.isValid
    }

    updateRules(key, product, itemIndex, order) {
        switch (key) {
            case "quantity":
                product.items[itemIndex].configuration.quantity.min = 1;
                break;
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                product.items[itemIndex].configuration.width.min = 0;
                product.items[itemIndex].configuration.width.max = 10000;
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 0;
                product.items[itemIndex].configuration.drop.max = 10000;
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let comment, stocks, label, attribute;

        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount += product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling, discountByProductCode);
        return product;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        let deduction = {};
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;
        stockItem.stockLinearWidth = (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1;

        let params = {
            width: width,
            drop: drop,
            stockLength: stockItem.length,
            stockLinearWidth: stockItem.stockLinearWidth,
            swishConversionFactor: stockItem.swishConversionFactor,
        };

        switch (attribute) {
            default:
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
        }
    }

    updateDeduction(key, product, itemIndex, order) {
        return {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        return errors;
    }


    toSalesOrderItemCustomRequest(product, itemIndex, order) {

        let salesOrderItemCustom = {
            ID: product.items[itemIndex].configuration.ID ? product.items[itemIndex].ID : null,
            salesOrderItemID: product.items[itemIndex].configuration.salesOrderItemID ? product.items[itemIndex].salesOrderItemID : null,
            salesOrderID: product.items[itemIndex].configuration.salesOrderID ? product.items[itemIndex].salesOrderID : null,
            location: product.items[itemIndex].configuration.location.selected.value,
            quantity: product.items[itemIndex].configuration.quantity.selected.value,
            width: product.items[itemIndex].configuration.width.selected.value,
            drop: product.items[itemIndex].configuration.drop.selected.value,
            stocks: product.items[itemIndex].stocks
        };
        return salesOrderItemCustom;
    }

    toProductTemplateCustomRequest(product, itemIndex, templateData) {
        let item = product.items[itemIndex];
        let customTemplateRequest = {
            accountID: product.builder.accountId,
            id: templateData.customTemplateID && templateData.customTemplateID > 0 ? templateData.customTemplateID : 0,
            isActive: templateData.isActive,
            templateName: templateData.templateName,
            description: templateData.description,
            prodCode: item.configuration.prodCode.selected.value,
            model: item.configuration.model.selected.value,
            location: item.configuration.location.selected.value,
            quantity: item.configuration.quantity.selected.value,
            width: item.configuration.width.selected.value,
            drop: item.configuration.drop.selected.value,
            productionInstructions: item.configuration.productionInstructions.selected.value,
            productionNotes: item.configuration.productionNotes.selected.value,
            documentation: templateData.documentation
        };
        return customTemplateRequest;
    }

    toProductTemplateCustomItemRequest(product, itemIndex, templateData) {
        let items = [];
        let stocks = product.items[itemIndex].stocks;
        let accountID = product.builder.accountId;
        stocks.forEach(stock => {
            let item = {
                accountID: accountID,
                customTemplateID: templateData.customTemplateID && templateData.customTemplateID > 0 ? templateData.customTemplateID : 0,
                prodCode: stock.prodCode,
                description: stock.description,
                attribute: stock.attribute,
                itemIndex: itemIndex,
                keywayCalculatedQty: stock.keywayCalculatedQty,
                swishCalculatedQty: stock.swishCalculatedQty,
                keywayMeasurementUnit: stock.keywayMeasurementUnit,
                price: stock.price,
                label: stock.label,
                width: stock.width,
                drop: stock.drop
            };
            items.push(item);
        });

        return items;
    }

    getStockFactoryInstanceFromTempate(templateItemData) {
        return {
            id: null,
            prodCode: templateItemData.prodCode,
            price: templateItemData.price,
            flatPrice: 0,
            quantityMultiplier: 1,
            qtyFormulaId: null,
            productConfigurationOptionId: null,
            productConfigurationOptionSetId: null,
            swishQuantityFormula: null,
            wastageFormula: null,
            wastagePercent: 0,
            calculatedQty: 1,
            swishCalculatedQty: templateItemData.swishCalculatedQty,
            keywayCalculatedQty: templateItemData.keywayCalculatedQty,
            stockPickSlipQty: 0,
            keywayMeasurementUnit: templateItemData.keywayMeasurementUnit,
            swishMeasurementUnit: "unit",
            keywayConversionFactor: 1,
            swishConversionFactor: 1,
            isVisibleInPartList: true,
            isDeductionApplicable: false,
            description: templateItemData.description,
            attribute: templateItemData.attribute,
            label: templateItemData.label,
            attributeRowSpan: 1,
            unitPrice: 0,
            isParentItem: false,
            isCustom: false,
            discontinued: false,
            soldOut: false,
            qoh: null,
            drop: templateItemData.drop,
            width: templateItemData.width
        }
    }

    getSalesOrderItemCustomInstanceFromTemplate(templateData) {
        return {
            itemType: "Build",
            location: templateData.location,
            quantity: templateData.quantity,
            width: templateData.width,
            drop: templateData.drop,
            prodCode: templateData.prodCode,
            colour: "",
            productCode: "",
            productGroupCode: "",
            model: templateData.model,
            productionInstructions: templateData.productionInstructions,
            productionNotes: templateData.productionNotes,
            thumbnailImageAttachmentID: null,
            highQualityImageAttachmentID: null,
            description: "",
            length: null,
            dimensionUnitName: "",
            eachUnitName: "",
            sellUnitName: "",
            sellUnitQty: 1,
            sellQtyPrice: 0,

            stocks: []
        }
    }

    getItemTypeSummary(product) {
        let itemsByItemType = groupItemBy((product.items || []), 'configuration.itemType.selected.value');
        let itemSummary = {};

        [PRODUCT_BUILDER_CUSTOM_PART, PRODUCT_BUILDER_CUSTOM_BUILD].forEach(itemType => {
            if (!itemsByItemType[itemType]) {
                itemsByItemType[itemType] = [];
            }

            itemSummary[itemType] = {
                items: itemsByItemType[itemType],
                count: itemsByItemType[itemType].length,
                price: 0,
                discVal: 0,
                priceWithoutDiscVal: 0,
            };

            itemSummary[itemType].items.forEach(item => {
                itemSummary[itemType].price += item.pricing.price;
                itemSummary[itemType].discVal += item.pricing.discVal;
                itemSummary[itemType].priceWithoutDiscVal += item.pricing.price - item.pricing.discVal;
            });
        });

        return itemSummary;
    }

    findItemIndex(product, customID) {
        return product.items.findIndex(i => i.customID === customID);
    }

    getValueOrDefault(product, itemIndex, valueType, defaultValue, key) {
        let result = defaultValue;
        switch (valueType) {
            case "value":
                if (product.items[itemIndex].configuration[key].selected.value) {
                    result = product.items[itemIndex].configuration[key].selected.value;
                }
                break;
            case "optionKey":
                if (!isEmpty(product.items[itemIndex].configuration[key].selected.value)) {
                    result = product.items[itemIndex].configuration[key].selected.value.optionKey;
                }
                break;
        }
        return result;
    }

    getSalesOrderItemCustomInstance(itemType) {
        return {
            itemType: itemType,
            location: "",
            quantity: 1,
            width: 0,
            drop: 0,
            prodCode: "",
            colour: "",
            productCode: "",
            productGroupCode: "",
            model:"",
            productionInstructions: "",
            productionNotes: "",
            thumbnailImageAttachmentID: null,
            highQualityImageAttachmentID: null,
            description: "",
            length: null,
            dimensionUnitName: "",
            eachUnitName: "",
            sellUnitName: "",
            sellUnitQty: 1,
            sellQtyPrice: 0,

            stocks: []
        }
    }

    toSalesOrderItemCustomRequest(pg, itemIndex) {
        return {
            itemType: this.getValueOrDefault(pg, itemIndex, "value", "", "itemType"),
            location: this.getValueOrDefault(pg, itemIndex, "value", "", "location"),
            quantity: this.getValueOrDefault(pg, itemIndex, "value", 0, "quantity"),
            width: this.getValueOrDefault(pg, itemIndex, "value", 0, "width"),
            drop: this.getValueOrDefault(pg, itemIndex, "value", 0, "drop"),
            prodCode: this.getValueOrDefault(pg, itemIndex, "value", "", "prodCode"),
            productCode: this.getValueOrDefault(pg, itemIndex, "value", "", "productCode"),
            productGroupCode: this.getValueOrDefault(pg, itemIndex, "value", "", "productGroupCode"),
            productionInstructions: this.getValueOrDefault(pg, itemIndex, "value", "", "productionInstructions"),
            productionNotes: this.getValueOrDefault(pg, itemIndex, "value", "", "productionNotes"),
            thumbnailImageAttachmentID: this.getValueOrDefault(pg, itemIndex, "value", null, "thumbnailImageAttachmentID"),
            highQualityImageAttachmentID: this.getValueOrDefault(pg, itemIndex, "value", null, "highQualityImageAttachmentID"),
            description: this.getValueOrDefault(pg, itemIndex, "value", "", "description"),
            length: this.getValueOrDefault(pg, itemIndex, "value", 0, "length"),
            dimensionUnitName: this.getValueOrDefault(pg, itemIndex, "value", "", "dimensionUnitName"),
            eachUnitName: this.getValueOrDefault(pg, itemIndex, "value", "", "eachUnitName"),
            sellUnitName: this.getValueOrDefault(pg, itemIndex, "value", "", "sellUnitName"),
            sellUnitQty: this.getValueOrDefault(pg, itemIndex, "value", 0, "sellUnitQty"),
            sellQtyPrice: this.getValueOrDefault(pg, itemIndex, "value", 0, "sellQtyPrice"),
            colour: this.getValueOrDefault(pg, itemIndex, "value", "", "colour"),
            model: this.getValueOrDefault(pg, itemIndex, "value", "", "model"),
            stocks: pg.items[itemIndex].stocks
        }
    }
}

export default CustomUtil.Instance();