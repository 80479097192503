import React, {Component} from 'react';
import {Col, Row, Label, Input, InputGroup, InputGroupAddon, Button} from 'reactstrap';

import {findIndex} from '../../services/CommonService';
import BranchService from '../../services/BranchService';


export default class BranchFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filter: this.props.filter, branchOptions: []};
        this.updateFilter = this.updateFilter.bind(this);
        this.branchService = new BranchService();
    }

    componentDidMount() {
        this.getBranches();
    }

    getBranches() {
        this.branchService.fetchBranches().then(response => {
            let branches = response.data;
            for(let i in branches) {
                branches[i].branchNum = branches[i].branchNum + "";
            }
            this.setState({branchOptions: branches});
        });
    }


    updateFilter(e) {

        let {filter, branchOptions} = this.state;
        filter.value = e.target.value;

        let index = findIndex(branchOptions, "branchNum", filter.value + "");
        if (index > -1) {
            filter.displayValue = branchOptions[index].shortName;

        }

        this.setState({filter}, () => {
            this.props.onChange(filter, false);
        });

    }


    render() {
        let {filter, branchOptions} = this.state;
        let branchNum = filter.value;

        return (
            <div>
                <Row className={"pb-3"}>
                    <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                        <Label>Search by Branch</Label>
                        <Input
                            className="form-control"
                            onChange={this.updateFilter}
                            type="select"
                            name="branchNum"
                            value={branchNum}
                            placeholder="Select Branch">
                            <option value={""}>All</option>
                            {(branchOptions) && branchOptions.map((option, index) =>
                                <option key={index} value={option.branchNum}>{option.shortName}</option>
                            )}
                        </Input>

                    </Col>
                </Row>
            </div>
        );
    }
}