import React, {Component} from 'react';
import {Button, Col, Row, Spinner} from 'reactstrap';
import stockReceiptService from '../../../services/stock/StockReceiptService';

import {toast, ToastContainer} from "react-toastify";
import {handleErrorMessage} from "../../../services/CommonService";
import ModalImage from "react-modal-image";
import CameraModal from "../../../components/modal/CameraModal";

export default class StockReceiptDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockReceiptID: this.props.stockReceiptID,
            stockReceiptDocuments: [],
            isLoadingDocumentList: false,
            isLoadingAddDocument: false,
            isOpenCameraModal: false,
            uploadProgress: 0
        };

        this.getStockReceiptDocuments = this.getStockReceiptDocuments.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.handleChange(this.props.stockReceiptID, "stockReceiptID");
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stockReceiptID !== this.state.stockReceiptID) {
            this.handleChange(nextProps.stockReceiptID, "stockReceiptID");
        }
    }


    handleChange(change, key) {
        let {stockReceiptID} = this.state;
        let urls = [];
        switch (key) {
            case "stockReceiptID":
                this.getStockReceiptDocuments(change);
                break;
            case "camera-modal-toggle":
                this.setState({isOpenCameraModal: change});
                break;
            case "attachments":
                (change || []).forEach(attachment => {
                    urls.push(attachment.publicUrl);
                });
                this.addStockReceiptDocuments(stockReceiptID, urls);
                break;
        }
    }

    addStockReceiptDocuments(stockReceiptID, urls) {
        if (stockReceiptID) {
            this.setState({isLoadingAddDocument: true});
            let request = {
                stockReceiptID: stockReceiptID,
                urls: urls
            };
            stockReceiptService.addStockReceiptDocuments(request).then(response => {
                let stockReceiptDocuments = response.data ? response.data : [];
                this.setState({isLoadingAddDocument: false, stockReceiptDocuments});
                this.handleChange(false, "camera-modal-toggle")
            }).catch(error => {
                this.setState({isLoadingAddDocument: false});
                toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
            })
        }
    }

    getStockReceiptDocuments(stockReceiptID) {
        if (stockReceiptID) {
            this.setState({stockReceiptID, isLoadingDocumentList: true});
            stockReceiptService.getStockReceiptDocuments(stockReceiptID).then(response => {
                let stockReceiptDocuments = response.data ? response.data : [];
                this.setState({isLoadingDocumentList: false, stockReceiptDocuments});
            }).catch(error => {
                this.setState({isLoadingDocumentList: false});
                toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
            })
        } else {
            this.setState({stockReceiptDocuments: []});
        }
    }

    render() {
        let {isOpenCameraModal, isLoadingDocumentList, isLoadingAddDocument, stockReceiptDocuments} = this.state;
        return (
            <div>
                {
                    (isLoadingDocumentList || isLoadingAddDocument)
                        ? <Spinner color={"primary"}/>
                        : null
                }
                <Row className={"center-row"}>
                    {(stockReceiptDocuments || []).map((document, documentIndex) => {
                        return <Col xl={2} lg={2} md={4} sm={12} xs={12} key={documentIndex}>
                            <ModalImage
                                className="img-thumbnail"
                                small={document.url}
                                large={document.url}
                                alt="..."
                                hideDownload={true}
                                hideZoom={true}
                                imageBackgroundColor={"#FFFFFF"}
                            />
                        </Col>
                    })}
                    <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                        <Button color={"primary"}
                                size={"sm"}
                                className={"mt-2"}
                                onClick={() => this.handleChange(true, "camera-modal-toggle")}>
                            <i className="fa fa-camera mr-2" aria-hidden="true"/>Upload supporting pictures
                        </Button>
                    </Col>
                </Row>

                <CameraModal
                    isOpen={isOpenCameraModal}
                    toggle={(change) => this.handleChange(change, "camera-modal-toggle")}
                    handleChange={(change) => this.handleChange(change, "attachments")}
                    category={"STOCK_RECEIPT"}
                />
                <ToastContainer/>
            </div>
        );
    }
}