import React from 'react';
import { Component } from 'react';
import {
    Card,
    Row,
    Col,
    CardBody,
    CardTitle,
    Label,
    Input
} from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';

const maxDate = moment().add(2, 'years').toDate();
const minDate = moment().subtract(20, 'years').toDate();

export default class OrdersTabFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        let { orders } = this.props;
        let { filterRequest } = orders.request;
        return (
            <div>
                 <Row>
                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                                <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date:
                                
                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={filterRequest.startDate}
                                        onChange={date => this.props.handleChange(date, "startDate")}
                                        selectsStart
                                        startDate={filterRequest.startDate}
                                        endDate={filterRequest.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="dd/mm/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        withPortal
                                    />
                                </div>
                    </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                                <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date:
                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={filterRequest.endDate}
                                        onChange={date => this.props.handleChange(date, "endDate")}
                                        selectsEnd
                                        startDate={filterRequest.startDate}
                                        endDate={filterRequest.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="dd/mm/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        withPortal
                                    />
                                </div>
                    </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                                Order No:
                                <Input type={"number"}
                                    placeholder={"Search..."}
                                    name={"ordNum"}
                                    value={filterRequest.ordNum || ''}
                                    onChange={(e) => this.props.handleChange(e.target.value, "ordNum")} />
                            </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                                Order Reference:
                                <Input type={"text"}
                                    placeholder={"Search..."}
                                    name={"custOrdNum"}
                                    value={filterRequest.custOrdNum || ''}
                                    onChange={(e) => this.props.handleChange(e.target.value, "custOrdNum")} />
                            </Col>
                 </Row>
            </div>
        )
    }
}