import React, {Component} from 'react';

import {Link} from "react-router-dom";
import {
    Col,
    Row,
    Card,
    CardHeader,
    CardBody,
    Badge,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText
} from "reactstrap";


import UserService from "../../../services/UserService";
import TaskService from "../../../services/crm/TaskService";
import {convertUtcDateToLocalDate} from '../../../services/CommonService';
import InfiniteScroll from 'react-infinite-scroller';

export default class AssignedTasksForMe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            tasks: [],
            selectedPage: 1,
            pageSize: 25,
            totalRecords: 0,
            user: {}
        };

        this.userService = new UserService();
        this.taskService = new TaskService();
        this.getPendingTasks = this.getPendingTasks.bind(this);
    }

    componentDidMount() {
        this.userService.getUser().then(data => {
            this.setState({user: data}, () => {
                this.getPendingTasks(false)
            })
        })
    }


    getPendingTasks(incrementPage) {
        let {loading, tasks, selectedPage, pageSize, totalRecords, user} = this.state;
        if (incrementPage) {
            selectedPage = selectedPage + 1;
        }

        let requestBody = {
            filter: {
                assignedUser: user.username,
                isClosed: "false"
            },
            sort: {
                key: "ScheduledDate",
                direction: true
            },
            page: {
                currentPage: selectedPage,
                pageSize: pageSize,
            }

        };

        this.taskService.getTasksFromPreparedRequest(requestBody).then(response => {

            if (response.data) {
                let data = response.data;
                loading = false;
                selectedPage = data.selectedPage;
                pageSize = data.pageSize;
                totalRecords = data.totalRecords;
                if (data.records && data.records.length > 0) {
                    for (let i in data.records) {
                        tasks.push(data.records[i]);
                    }
                }
                this.setState({loading, tasks, selectedPage, pageSize, totalRecords});
            }
        }).catch(error => {
            console.log(error);
        });
    }

    getAvatar(task) {
        let icon = "fa fa-phone";

        switch (task.followUpType) {
            case "PHONE":
                icon = "fa fa-phone";
                break;
            case "SMS":
                icon = "fa fa-commenting-o";
                break;
            case "EMAIL":
                icon = "fa fa-envelope-o";
                break;
            case "VISIT":
                icon = "fa fa-map-marker";
                break;
        }
        return <i className={icon} aria-hidden="true"/>
    }

    render() {
        const {tasks, totalRecords, pageSize, selectedPage} = this.state;

        if (totalRecords === 0) {
            return null;
        }

        return (
            <Col xl={3} lg={4} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>My Tasks&nbsp;<Badge pill
                                                     title={'Your total pending tasks'}>{totalRecords}</Badge>
                        <span style={{float: "right"}}>
                            <Link to="/crm/tasks"><i className={"fa fa-pencil"}/></Link>
                        </span>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <div style={{height: "auto", maxHeight: "250px", overflow: "auto"}}>
                                <InfiniteScroll
                                    threshold={50}
                                    pageStart={1}
                                    loadMore={() => this.getPendingTasks(true)}
                                    hasMore={(pageSize * selectedPage) < totalRecords}
                                    useWindow={false}
                                >
                                    <ListGroup> {tasks.map((task, index) => {
                                            return (
                                                <Link
                                                    key={index}
                                                    style={{textDecoration: "none"}}
                                                    to={{
                                                        pathname: "/crm/manageTask/" + task.id,
                                                    }}>

                                                    <ListGroupItem
                                                        title={task.description} className={"hoverableItem"}
                                                    >
                                                        <div
                                                            className={"font-weight-normal"}>{this.getAvatar(task)} &nbsp;
                                                            <span
                                                                className={"customOverflowText"}>{task.description}</span>
                                                        </div>
                                                        <span>
                                                            {(() => {
                                                                if (task.scheduledOn) {
                                                                    return (

                                                                        <small
                                                                            className="text-muted">Due
                                                                            on
                                                                            &nbsp;{convertUtcDateToLocalDate(task.scheduledOn).fromNow()}</small>
                                                                    )
                                                                }
                                                                if (task.activitylastModifiedDate) {
                                                                    return (
                                                                        <small
                                                                            className="text-muted">Last
                                                                            updated
                                                                            &nbsp;{convertUtcDateToLocalDate(task.activitylastModifiedDate).fromNow()}</small>
                                                                    )
                                                                }
                                                            })()}
                                                        </span>
                                                    </ListGroupItem>

                                                </Link>)
                                        }
                                    )}
                                    </ListGroup>
                                </InfiniteScroll>
                            </div>
                            <div className="text-right text-muted mt-2">
                                <small>-Assigned to you(Pending)</small>
                            </div>
                        </div>
                    </CardBody>
                </Card>


            </Col>
        );

    }
}