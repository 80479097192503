import React, {Component} from 'react';
import classnames from 'classnames';

import {
    CardGroup, CardTitle, CardSubtitle, CardText, Card, CardHeader, CardBody, Row, Col, Progress
} from 'reactstrap';
import NumberFormat from "react-number-format";


export default class QuoteConversionAnalysis extends Component {


    getStatsComponentForSelectedStatus(stats) {
        stats.selectedStatusCountPercent = ((stats.selectedStatusCount * 100) / stats.totalCount);
        stats.selectedStatusAmountPercent = ((stats.selectedStatusAmount * 100) / stats.totalAmount);

        return (
            <div className={"mt-2"}>
                <CardGroup>

                    <Card>
                        <CardBody>
                            <CardTitle className="lead">
                                {stats.selectedQuoteStatus}&nbsp;Quotes
                            </CardTitle>

                            <Row>
                                <Col>
                                    <div className={"text-left"}>
                                        <CardSubtitle className="lead"><NumberFormat
                                            value={stats.selectedStatusCount}
                                            displayType={'text'}
                                            thousandSeparator={true}/> /
                                            <small>
                                                <NumberFormat value={stats.totalCount}
                                                              displayType={'text'}
                                                              thousandSeparator={true}/></small>
                                        </CardSubtitle>
                                    </div>
                                </Col><Col>
                                <div className={"text-right"}>
                                    <CardSubtitle className="lead"><NumberFormat
                                        value={stats.selectedStatusCountPercent}
                                        displayType={'text'}
                                        decimalScale={1}
                                        suffix={'%'}
                                    />
                                    </CardSubtitle>
                                </div>
                            </Col>
                            </Row>
                            <Progress color={classnames({
                                "primary": stats.selectedQuoteStatus === "Active",
                                "success": stats.selectedQuoteStatus === "Completed",
                                "danger": stats.selectedQuoteStatus === "Cancelled",
                            })}
                                      value={stats.selectedStatusCount}
                                      max={stats.totalCount}/>

                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle className="lead">
                                Amount
                            </CardTitle>

                            <Row>
                                <Col>
                                    <div className={"text-left"}>
                                        <CardSubtitle className="lead"><NumberFormat
                                            value={stats.selectedStatusAmount}
                                            displayType={'text'}
                                            decimalScale={0}
                                            prefix={'$'}
                                            thousandSeparator={true}/> /
                                            <small>
                                                <NumberFormat value={stats.totalAmount}
                                                              displayType={'text'}
                                                              decimalScale={0}
                                                              prefix={'$'}
                                                              thousandSeparator={true}/>
                                            </small>
                                        </CardSubtitle>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-right"}>
                                        <CardSubtitle className="lead">
                                            <NumberFormat
                                                value={stats.selectedStatusAmountPercent}
                                                displayType={'text'}
                                                decimalScale={1}
                                                suffix={'%'}
                                            />
                                        </CardSubtitle>
                                    </div>
                                </Col>
                            </Row>
                            <Progress color={classnames({
                                "primary": stats.selectedQuoteStatus === "Active",
                                "success": stats.selectedQuoteStatus === "Completed",
                                "danger": stats.selectedQuoteStatus === "Cancelled",
                            })}
                                      value={stats.selectedStatusAmount}
                                      max={stats.totalAmount}/>

                        </CardBody>
                    </Card>
                </CardGroup>
            </div>
        );
    }

    getStatsComponentForAllStatus(stats) {
        stats.activeCountPercent = ((stats.activeCount * 100) / stats.totalCount);
        stats.completedCountPercent = ((stats.completedCount * 100) / stats.totalCount);
        stats.cancelledCountPercent = ((stats.cancelledCount * 100) / stats.totalCount);


        stats.activeAmountPercent = ((stats.activeAmount * 100) / stats.totalAmount);
        stats.completedAmountPercent = ((stats.completedAmount * 100) / stats.totalAmount);
        stats.cancelledAmountPercent = ((stats.cancelledAmount * 100) / stats.totalAmount);


        return (
            <div className={"mt-2"}>
                <CardGroup>

                    <Card>
                        <CardBody>
                            <CardTitle className="lead">
                                <span className={"text-primary"}>Active</span> + <span className={"text-success"}>Completed</span>
                                + <span className={"text-danger"}>Cancelled</span>&nbsp;Quotes
                            </CardTitle>

                            <Row>
                                <Col>
                                    <div className={"text-left"}>
                                        <CardSubtitle className="lead">
                                            (
                                            <NumberFormat
                                                className={"text-primary"}
                                                value={stats.activeCount}
                                                displayType={'text'}
                                                thousandSeparator={true}/>{'+'}
                                            <NumberFormat
                                                className={"text-success"}
                                                value={stats.completedCount}
                                                displayType={'text'}
                                                thousandSeparator={true}/>{'+'}
                                            <NumberFormat
                                                className={"text-danger"}
                                                value={stats.cancelledCount}
                                                displayType={'text'}
                                                thousandSeparator={true}/>
                                            )
                                            /
                                            <small>
                                                <NumberFormat value={stats.totalCount}
                                                              displayType={'text'}
                                                              thousandSeparator={true}/>
                                            </small>
                                        </CardSubtitle>
                                    </div>
                                </Col><Col>
                                <div className={"text-right"}>
                                    <CardSubtitle className="lead">
                                        (
                                        <NumberFormat
                                            className={"text-primary"}
                                            value={stats.activeCountPercent}
                                            displayType={'text'}
                                            decimalScale={1}
                                            suffix={'%'}
                                        />{'+'}
                                        <NumberFormat
                                            className={"text-success"}
                                            value={stats.completedCountPercent}
                                            displayType={'text'}
                                            decimalScale={1}
                                            suffix={'%'}
                                        />{'+'}
                                        <NumberFormat
                                            className={"text-danger"}
                                            value={stats.cancelledCountPercent}
                                            displayType={'text'}
                                            decimalScale={1}
                                            suffix={'%'}
                                        />) /
                                        <small>
                                            <NumberFormat
                                                value={100}
                                                displayType={'text'}
                                                decimalScale={1}
                                                suffix={'%'}
                                            />
                                        </small>
                                    </CardSubtitle>
                                </div>
                            </Col>
                            </Row>
                            <Progress multi>
                                <Progress bar color="primary" title="Active Quotes" value={stats.activeCount}
                                          max={stats.totalCount}/>
                                <Progress bar color="success" title="Completed Quotes" value={stats.completedCount}
                                          max={stats.totalCount}/>
                                <Progress bar color="danger" title="Cancelled Quotes" value={stats.cancelledCount}
                                          max={stats.totalCount}/>
                            </Progress>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle className="lead">Amount &nbsp;(
                                <NumberFormat
                                    className={"text-primary"}
                                    value={stats.activeAmount}
                                    decimalScale={0}
                                    prefix={'$'}
                                    displayType={'text'}
                                    thousandSeparator={true}/>{'+'}
                                <NumberFormat
                                    className={"text-success"}
                                    value={stats.completedAmount}
                                    decimalScale={0}
                                    prefix={'$'}
                                    displayType={'text'}
                                    thousandSeparator={true}/>{'+'}
                                <NumberFormat
                                    className={"text-danger"}
                                    value={stats.cancelledAmount}
                                    decimalScale={0}
                                    prefix={'$'}
                                    displayType={'text'}
                                    thousandSeparator={true}/>
                                )
                                /
                                <small>
                                    <NumberFormat value={stats.totalAmount}
                                                  prefix={'$'}
                                                  decimalScale={0}
                                                  displayType={'text'}
                                                  thousandSeparator={true}/>
                                </small>
                            </CardTitle>

                            <Row>
                                <Col>
                                    <div className={"text-right"}>
                                        <CardSubtitle className="lead">
                                            (
                                            <NumberFormat
                                                className={"text-primary"}
                                                value={stats.activeAmountPercent}
                                                displayType={'text'}
                                                decimalScale={1}
                                                suffix={'%'}
                                            />{'+'}
                                            <NumberFormat
                                                className={"text-success"}
                                                value={stats.completedAmountPercent}
                                                displayType={'text'}
                                                decimalScale={1}
                                                suffix={'%'}
                                            />{'+'}
                                            <NumberFormat
                                                className={"text-danger"}
                                                value={stats.cancelledAmountPercent}
                                                displayType={'text'}
                                                decimalScale={1}
                                                suffix={'%'}
                                            />) /
                                            <small>
                                                <NumberFormat
                                                    value={100}
                                                    displayType={'text'}
                                                    decimalScale={1}
                                                    suffix={'%'}
                                                />
                                            </small>
                                        </CardSubtitle>
                                    </div>
                                </Col>
                            </Row>
                            <Progress multi>
                                <Progress bar color="primary" title="Active Quotes" value={stats.activeCount}
                                          max={stats.totalCount}/>
                                <Progress bar color="success" title="Completed Quotes" value={stats.completedCount}
                                          max={stats.totalCount}/>
                                <Progress bar color="danger" title="Cancelled Quotes" value={stats.cancelledCount}
                                          max={stats.totalCount}/>
                            </Progress>
                        </CardBody>
                    </Card>
                </CardGroup>
            </div>
        );
    }

    render() {

        let {stats} = this.props;


        if (stats && stats.totalCount > 0) {

            if (stats.selectedQuoteStatus) {
                return this.getStatsComponentForSelectedStatus(stats);
            } else {
                return this.getStatsComponentForAllStatus(stats);
            }


        }
        return null;
    }

}