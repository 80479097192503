import React from 'react';
import {Component} from 'react';
import {
    Row,
    Col,
    Spinner,
    Card,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    CardHeader,
    CardBody,
    Badge,
    Input,
    Table
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import {changeFormatOfDateString, handleErrorMessage} from './../../services/CommonService';
import SearchBannerContactMaster from './SearchBannerContactMaster'
import {Link as Link} from "react-router-dom";
import contactService from '../../services/ContactService';
import UserService from '../../services/UserService';
import cloneDeep from 'lodash/cloneDeep';
import {toast, ToastContainer} from 'react-toastify';
import CustomerService from '../../services/CustomerService';
import * as FileSaver from 'file-saver';

export default class ContactsMasterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleting: false,
            deleteContactID: 0,
            hasDebtorWritePrivilege: false,
            searchText: "",
            contacts: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "accountID",
                        direction: true
                    },
                    filterRequest: {
                        AccountId: null,
                        email: null,
                        mobile: null,
                        phone: null,
                        name: null,
                        isEmailInvoice: null,
                        isEmailStatement: null,
                        isEmailMarketing: null,
                        includeParentAccountContacts: false,
                        includeChildAccountContacts: false,
                        isEmailDispatchNotice: null,
                        condition: '',
                        categoryID: 0,
                        accountStatus: null,
                        consumerType: "Debtor"
                    }
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            allow_includeParentAccountContacts: false,
            debtorCategories: [],
            contactTypes: [],
            debtorAccountStatus: [],
            downloading: false
        };

        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.getColValue = this.getColValue.bind(this);
        this.getAllcontacts = this.getAllcontacts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getStore = this.getStore.bind(this);
        this.searchFunction = this.searchFunction.bind(this);
        this.getDebtorAccountStatus = this.getDebtorAccountStatus.bind(this);
        this.refresh = this.refresh.bind(this);
        this.exportContacts = this.exportContacts.bind(this);
        this.handleSubmitContact = this.handleSubmitContact.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.fetchContactTypes = this.fetchContactTypes.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.getDebtorCategory();
        this.fetchContactTypes();
    }

    fetchContactTypes() {
        contactService.getContactTypes().then(response => {
            this.setState({contactTypes: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorAccountStatus() {
        this.customerService.getDebtorAccountStatus().then(response => {
            this.setState({debtorAccountStatus: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorCategory() {
        this.customerService.getDebtorCategory().then(response => {
            this.setState({debtorCategories: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    refresh() {
        this.getAllcontacts(this.state.contacts);
        this.getDebtorAccountStatus();

        this.userService.getLoggedInUserFromDb(false).then(userResponse => {
            let user = userResponse.data;
            if (user) {
                let {hasDebtorWritePrivilege} = this.state;
                user.menu.privileges.includes('debtor-write') ? hasDebtorWritePrivilege = true
                    : hasDebtorWritePrivilege = false;
                this.setState({hasDebtorWritePrivilege});
            }
        }, error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    getAllcontacts(contacts) {
        this.setState({loading: true});
        let request = cloneDeep(contacts.request);

        contactService.getAllcontacts(request).then(response => {
            if (response.status === 200 || response.status === "200") {
                if (response.data.records) {
                    contacts.response = response.data;
                    if (response.data.records.length > 0) {
                        this.setState({contacts, loading: false, allow_includeParentAccountContacts: response.data[0]});
                    }
                    else if (response.data.records.length === 0) {
                        this.setState({contacts, loading: false, allow_includeParentAccountContacts: false});
                    }
                }
            }
        }).catch(error => {
            this.setState({loading: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    getStore({filterRequest}) {
        return [
            {
                key: "accountID",
                label: "Customer",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null
            },
            {
                key: "company",
                label: "Company",
                type: "company",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "name",
                label: "Name",
                type: "",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null
            },
            {
                key: "email",
                label: "Email",
                type: "text",
                colSpan: 1,
                minWidth: 140,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "phone",
                label: "Phone",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "mobile",
                label: "Mobile",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "messenger",
                label: "Messenger",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "contactTypeID",
                label: "Contact Type",
                type: "contactTypeID",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "isEmailInvoice",
                label: "Email Invoice",
                type: "isEmailInvoice",
                colSpan: 1,
                minWidth: 80,
                showColumn: true,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "isEmailStatement",
                label: "Email Statement",
                type: "isEmailStatement",
                colSpan: 1,
                minWidth: 100,
                showColumn: true,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "isEmailDispatchNotice",
                label: "Email Dispatch Notice",
                type: "isEmailDispatchNotice",
                colSpan: 1,
                minWidth: 80,
                showColumn: true,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "isEmailMarketing",
                label: "Email Marketing",
                type: "isEmailMarketing",
                colSpan: 1,
                minWidth: 100,
                showColumn: true,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 100,
                showColumn: true,
                sorterApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "text-center align-middle",
                searchNode: null
            }
        ];


    }

    getColValue(value, storeItem, order, index) {
        let {hasDebtorWritePrivilege, deleting, deleteContactID, saving, savingContactID} = this.state;
        switch (storeItem.type) {
            case "date":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "number":
                return <span><NumberFormat value={value}
                                           displayType={'text'}
                                           thousandSeparator={true}/></span>;
            case "isEmailMarketing":
                return <span>
                    <Button color={"link"}
                            size={"sm"}
                            onClick={() => this.handleValueChange(!order.isEmailMarketing, 'isEmailMarketing', index)}>
                        {
                            order.isEmailMarketing
                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                     aria-hidden="true"/>
                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                        }
                    </Button></span>;
            case "isEmailStatement":
                return <span>
                      <Button color={"link"}
                              size={"sm"}
                              onClick={() => this.handleValueChange(!order.isEmailStatement, 'isEmailStatement', index)}>
                        {
                            order.isEmailStatement
                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                     aria-hidden="true"/>
                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                        }
                    </Button></span>;
            case "isEmailInvoice":
                return <span>
                    <Button color={"link"}
                            size={"sm"}
                            onClick={() => this.handleValueChange(!order.isEmailInvoice, 'isEmailInvoice', index)}>
                        {
                            order.isEmailInvoice
                                ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                     aria-hidden="true"/>
                                : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                        }
                    </Button></span>;
            case "isEmailDispatchNotice":
                return <span><Button color={"link"}
                                     size={"sm"}
                                     onClick={() => this.handleValueChange(!order.isEmailDispatchNotice, 'isEmailDispatchNotice', index)}>
                    {
                        order.isEmailDispatchNotice
                            ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                 aria-hidden="true"/>
                            : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true"/>
                    }
                </Button></span>;
            case "categoryByID":
                let result1 = null;
                if (value) {
                    let className = "";
                    switch (order.debtorCategoryID) {
                        case 1:
                            className = "btn btn-sm btn-warning";
                            break;
                        case 2:
                            className = "btn btn-sm btn-secondary";
                            break;
                        case 3:
                        default:
                            className = "btn btn-sm btn-primary";
                            break;
                    }
                    result1 = <Link
                        className={className}
                        style={{color: "white"}}
                        title={"Click here to see account details"}
                        to={"/customer/#" + value}
                    >{value}</Link>;
                }
                return <span>{result1}</span>;
            case "action":
                return <div className={"text-center"}>
                    <Button color={"primary"}
                            size={"sm"}
                            disabled={!hasDebtorWritePrivilege}
                            title={'Save Contact'}
                            onClick={() => this.handleSubmitContact(order)}>
                        {saving && savingContactID == order.contactID ?
                            <Spinner size={"sm"} color="white"/>
                            : <i className="fa fa-floppy-o" aria-hidden="true"/>
                        }
                    </Button>  &nbsp;
                    <Button color={"danger"}
                            size={"sm"}
                            disabled={!hasDebtorWritePrivilege}
                            title={'Delete Contact'}
                            onClick={() => this.deleteContact(order)}>
                        {deleting && deleteContactID == order.contactID ?
                            <Spinner size={"sm"} color="white"/>
                            : <i className="fa fa-trash" aria-hidden="true"/>
                        }
                    </Button>

                </div>;
            case "company":
                return <small> {value}</small>;
            case "text":
                return <Input type='text'
                              name={storeItem.key}
                              value={value || ''}
                              onChange={(e) => this.handleValueChange(e.target.value, storeItem.key, index)}/>
            case "contactTypeID":
                return <Input className="form-control"
                              type="select"
                              value={value}
                              onChange={(e) => this.handleValueChange(e.target.value, storeItem.key, index)}
                              name="contactTypeID"
                              placeholder="Select contact type...">
                    <option key={100} value={0}>Select contact type</option>
                    {(this.state.contactTypes || []).map((item, indexx) => <option key={indexx}
                                                                                   value={item.contactTypeID}>{item.name}</option>)}
                </Input>;
            default:
                return <span>{value}</span>
        }
    }

    handleValueChange(value, key, index) {
        let {contacts} = this.state;
        contacts.response.records[index][key] = value;
        this.setState({contacts});
    }

    handleSubmitContact(contact) {
        this.setState({saving: true, savingContactID: contact.contactID});
        contactService.saveContact(contact).then(response => {
            this.setState({saving: false, savingContactID: 0});
            toast.success("Saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({saving: false, savingContactID: 0});
        });
    }

    deleteContact(contact) {
        this.setState({deleting: true, deleteContactID: contact.contactID});
        contactService.deleteContact(contact.contactID).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.getAllcontacts(this.state.contacts);
                this.setState({deleting: false, deleteContactID: 0});
                toast.success("Deleted successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({deleting: false, deleteContactID: 0});
        });

    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.accountID.toString().includes(searchText);

            /*if (!flag && item.name) {
                flag = item.name.toString().includes(searchText)
            }*/
            if (!flag && item.firstname) {
                flag = item.firstname.toString().includes(searchText)
            }
            if (!flag && item.email) {
                flag = item.email.toString().includes(searchText)
            }
            if (!flag && item.phone) {
                flag = item.phone.toLowerCase().includes(searchText)
            }
            if (!flag && item.mobile) {
                flag = item.mobile.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {contacts} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (contacts.request.sortRequest.key === change) {
                    contacts.request.sortRequest.direction = !contacts.request.sortRequest.direction;
                } else {
                    contacts.request.sortRequest.key = change;
                    contacts.request.sortRequest.direction = false;
                }
                if (!(change === "action")) {
                    this.getAllcontacts(contacts);
                }
                break;
            case "pageSize":
                contacts.request.pageRequest[key] = change;
                this.getAllcontacts(contacts);
                break;
            case "currentPage":
                contacts.request.pageRequest[key] = change;
                this.getAllcontacts(contacts);
                break;
            default:
                contacts.request.filterRequest[key] = change;
                contacts.request.pageRequest.currentPage = 1;
                this.setState({contacts});
                this.getAllcontacts(contacts);
        }
    }

    exportContacts = () => {
        let request = cloneDeep(this.state.contacts.request);
        this.setState({downloading: true});
        contactService.generateContactSummaryExcelReport(request).then(response => {
            this.setState({downloading: false});
            FileSaver.saveAs(response.data, "Customer Contacts" + ".xlsx");
        }).catch(error => {
            this.setState({downloading: false});
            toast.error(handleErrorMessage(error));
        });
    };


    render() {
        let {
            contacts, loading, searchText, allow_includeParentAccountContacts, debtorCategories,
            contactTypes, downloading, debtorAccountStatus
        } = this.state;
        let {pageRequest, sortRequest, filterRequest} = contacts.request;
        let store = this.getStore(contacts.request);

        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <h5>Contacts Master</h5>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    </Col>
                </Row>
                <div className="mb-2">
                    <SearchBannerContactMaster
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                        debtorCategories={debtorCategories}
                        contactTypes={contactTypes}
                        debtorAccountStatus={debtorAccountStatus}
                        allow_includeParentAccountContacts={allow_includeParentAccountContacts}
                    />
                </div>
                <Card>
                    <CardBody>
                        <div>
                            <Row className={"align-items-center"}>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        {loading ?
                                            <span>
                                                <Spinner size={"sm"} color={"primary"}/>
                                            </span>
                                            :
                                            <span>
                                                Showing{' '}
                                                {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {contacts.response.totalRecords}
                                                {' '}entries
                                                &nbsp;
                                            </span>
                                        }

                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <Table hover bordered size={"sm"} striped responsive>
                                    <thead>
                                    <tr>
                                        {(store || []).filter(item => item.showColumn ? true : false).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{minWidth: item.minWidth}}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                            )} aria-hidden="true"/> : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {(contacts.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((contact, i) => {
                                        return (
                                            <tr key={i}>
                                                {(store || []).filter(item => item.showColumn ? true : false).map((storeItem, index) => {
                                                    return (
                                                        <td key={index} className={storeItem.valueClassName}>
                                                            {this.getColValue(contact[storeItem.key], storeItem, contact, i)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}

                                    </tbody>
                                </Table>

                                {!loading ?
                                    <Row>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <div className={"text-left"} style={{maxWidth: 440}}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Show</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type={"select"}
                                                        name={"pageSize"}
                                                        value={pageRequest.pageSize}
                                                        disabled={loading}
                                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                        <option value={25}>25 Rows</option>
                                                        <option value={50}>50 Rows</option>
                                                        <option value={100}>100 Rows</option>
                                                        <option value={500}>500 Rows</option>
                                                    </Input>
                                                    &nbsp;&nbsp;
                                                    <Button color={"success"}
                                                            outline={!downloading}
                                                            size={"sm"}
                                                            title={'Click here to export data'}
                                                            onClick={this.exportContacts}
                                                            disabled={downloading}>{downloading ? "Exporting" : "Export"}
                                                        {downloading
                                                            ? <Spinner size="sm"
                                                                       className={"ml-2"}
                                                                       color="light"
                                                            />
                                                            :
                                                            <i className="fa fa-file-excel-o ml-2" aria-hidden="true"/>}
                                                    </Button>
                                                </InputGroup>


                                            </div>
                                        </Col>
                                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                            <div className={"float-right"}>
                                                <Pagination
                                                    activePage={pageRequest.currentPage}
                                                    itemsCountPerPage={pageRequest.pageSize}
                                                    totalItemsCount={contacts.response.totalRecords}
                                                    pageRangeDisplayed={3}
                                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    activeClass='active'
                                                    innerClass='pagination'
                                                    activeLinkClass='active'
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    null
                                }

                            </div>
                            <ToastContainer/>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}