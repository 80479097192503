import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from 'reactstrap';
import ManagePrintersService from "../../services/ManagePrintersService";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {toast} from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';


export default class EditPrinterPropModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            printerStatuses: [],
            printer: this.props.printer,
            allPrinterJobTypes: [],
            selectedPrinterJobTypes: []
        };
        this.managePrinterService = new ManagePrintersService();
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.managePrinterService.getPrinterStatus().then(response => {
            this.setState({
                printerStatuses: response.data
            })
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        })
        this.getPrinterJobTypes();
        this.getSelectedPrinterJobTypes(this.state.printer);
    }

    getSelectedPrinterJobTypes(printer) {
        let selectedJobTypeArray = new Array();
        if (printer.suitableJobType !== undefined && printer.suitableJobType !== null && printer.suitableJobType !== "") {
            let jobTypeArray = printer.suitableJobType.split(',');

            (jobTypeArray || []).map(type => {
                let option = {
                    label: type,
                    value: type
                }
                selectedJobTypeArray.push(option);

            });
        }
        this.setState({selectedPrinterJobTypes: selectedJobTypeArray});
    }

    getPrinterJobTypes() {
        let jobTypeOptions = new Array();
        this.managePrinterService.getPrinterJobTypes().then(response => {
            response.data.forEach(type => {
                let option = {
                    label: type,
                    value: type
                }
                jobTypeOptions.push(option)
            })
            this.setState({allPrinterJobTypes: jobTypeOptions});
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.printer !== nextProps.printer) {
            this.setState({printer: nextProps.printer});
            this.getSelectedPrinterJobTypes(nextProps.printer);
        }
    }

    handleChange(value, name) {
        let {printer} = this.state;
        if (name === "printerJobTypes") {
            printer.suitableJobType = "";
            (value || []).map(val => {
                if (printer.suitableJobType === "") {
                    printer.suitableJobType = val.value;
                } else {
                    printer.suitableJobType = printer.suitableJobType + "," + val.value;
                }

            });
            this.setState({selectedPrinterJobTypes: value, printer});
        } else {
            printer[name] = value;
            this.setState({printer});
        }


    }

    render() {
        let {isOpen, toggle, handleCancel, handleSubmit, saving, formErrors} = this.props;
        let {printer} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Edit Printer </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Printer Name</Label>
                            <Input type={"text"}
                                   placeholder={"Name here .."}
                                   name={"printerName"}
                                   value={printer.printerName}
                                   onChange={(e) => this.handleChange(e.target.value, "printerName")}>
                            </Input>
                            <FormText
                                color="danger">{formErrors.printerName}</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label>Printer Status</Label>
                            <Input type={"select"}
                                   placeholder={"Select Status..."}
                                   name={"printerStatus"}
                                   value={printer.printerStatus}
                                   onChange={(e) => this.handleChange(e.target.value, "printerStatus")}>
                                {this.state.printerStatuses.map((status) =>
                                    <option key={status} value={status}>{status}</option>
                                )}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Job Type</Label>
                            <CreatableSelect
                                isMulti
                                formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                placeholder={"Add printer job type"}
                                onChange={(value) => this.handleChange(value, "printerJobTypes")}
                                value={this.state.selectedPrinterJobTypes}
                                options={this.state.allPrinterJobTypes}
                            />
                            <FormText
                                color="danger">{formErrors.suitableJobType}</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Button
                                size={"sm"}
                                color={"link"}
                                onClick={() => this.handleChange(!printer.isActive, "isActive")}>
                                {printer.isActive ?
                                    <i className="fa fa-lg fa-check-square-o mr-2" aria-hidden="true"/> :
                                    <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true"/>}
                            </Button>
                            <Label className="mb-0">Active</Label>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color="primary" size="sm" disabled={saving} onClick={() => handleSubmit(printer)}>
                            {saving ? <><Spinner size={"sm"}/> Saving </>
                                : <> Save </>
                            }
                        </Button>
                        &nbsp;
                        <Button color="secondary" size="sm" onClick={handleCancel}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}