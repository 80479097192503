import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, CardImg, CardText, Progress
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import FabricService from "../../services/FabricService";
import AttachmentService from '../../services/AttachmentService';
import FileSaver from 'file-saver';
import { handleErrorMessage } from '../../services/CommonService';

const baseUrl = window.location.origin;
class FabricAttachmentUpload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fabricId: 0,
            fabricDoc: {},
            description: "",
            loading: false,
            fabricObj: {
                fabricImageAttachment: null,
                fabricBrochureAttachment: null
            },
            requestProgressPercentage: 0,
            downloading: false,
            loadingFabric: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.attachmentService = new AttachmentService();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.fabricId !== this.state.fabricId) {
            this.setState({ fabricId: nextProps.fabricId }, () => { this.getFabric() })
        }
    }

    getFabric() {
        if (this.state.fabricId && this.state.fabricId !== 0) {
            this.setState({ loadingFabric: true })
            FabricService.GetParticularFabric(this.state.fabricId).then(response => {
                if (response.data) {
                    this.setState({ fabricObj: response.data, loadingFabric: false });
                }
            }).catch(error => {
                this.setState({ loadingFabric: false })
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(change, key) {
        let { fabricDoc } = this.state;
        switch (key) {
            default:
                fabricDoc[key] = change;
                this.setState({ fabricDoc });
                break;
        }
    }

    validateRequest(fabricDoc) {
        if (!(fabricDoc.file)) {
            toast.info("Fabric file is missing!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        if (!(fabricDoc.snapshotFile)) {
            toast.info("Fabric snapshot file is missing", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }

        return true;
    }

    handleSubmit() {
        let { fabricDoc } = this.state;

        if (this.state.fabricId === 0) {
            toast.info("Fabric Id not found", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        let isValid = this.validateRequest(fabricDoc);

        if (!isValid) {
            return;
        }


        let data = new FormData();
        data.append('fabricID', this.state.fabricId ? this.state.fabricId: 0);
        data.append('brochureFile', fabricDoc.file);
        data.append('imageFile', fabricDoc.snapshotFile);

        this.setState({ loading: true });

        FabricService.UploadFabricBrochure(data).then(response => {
            this.setState({ loading: false, fabricDoc: {} });
            this.getFabric();
            toast.success("Fabric Brochure saved successfully", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ loading: false });
        });

    }

    onDownloadFabricBrochureAttachment(fabricAttachment) {
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    this.setState({ requestProgressPercentage: percent });
                }
            }
        };
        this.setState({ downloading: true, requestProgressPercentage: 0 });
        this.attachmentService.DownloadAttachment(fabricAttachment.id, options).then(response => {
            FileSaver.saveAs(response.data, fabricAttachment.description);
            this.setState({ downloading: false, requestProgressPercentage: 0 });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            this.setState({ downloading: false, requestProgressPercentage: 0 });
        });
    }

    onDownloadFabricImageAttachment(fabricAttachment) {
        this.attachmentService.DownloadAttachment(fabricAttachment.id).then(response => {
            FileSaver.saveAs(response.data, fabricAttachment.description);
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }



    render() {
        let { loading, fabricObj, downloading, requestProgressPercentage, loadingFabric } = this.state;
        return (
            <div>

                <Card>
                    <CardHeader><h5>Upload Fabric Image/Attachment</h5></CardHeader>
                    <CardBody>
                        {loadingFabric
                            ? <Spinner color="primary" /> :
                            <Row>
                                <Col>

                                    <div>
                                        <div>Snapshot(png/jpg)</div>
                                        <Input type="file" name="snapshotFile"
                                            onChange={(e) => this.handleChange(e.target.files[0], "snapshotFile")}
                                            aria-label="File browser example" />
                                    </div>
                                    <hr />
                                    <div>
                                        <div>Main File</div>
                                        <Input type="file" name="file"
                                            onChange={(e) => this.handleChange(e.target.files[0], "file")}
                                            aria-label="File browser example" />
                                    </div>
                                    
                                </Col>
                                <Col className={"border-left"}>

                                    {

                                        (fabricObj.fabricImageAttachment != null)
                                            ? <div className="p-2 text-center">

                                                {
                                                    fabricObj.fabricImageAttachment.fileName
                                                        ?
                                                        <img
                                                            style={{
                                                                width: '150px',
                                                                height: '150px'
                                                            }}
                                                            title="Click here to download image"
                                                            onClick={() => this.onDownloadFabricImageAttachment(fabricObj.fabricImageAttachment)}
                                                            src={baseUrl + "/api/file/Generate/Fabric?FileName=" + fabricObj.fabricImageAttachment.fileName}
                                                        />
                                                        : null
                                                }


                                            </div>

                                            : null
                                    }
                                    {requestProgressPercentage > 0
                                        ? <Progress striped color="primary" value={requestProgressPercentage}
                                            className={"mt-2"} />
                                        : null
                                    }
                                </Col>
                            </Row>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color={"primary"}
                                onClick={() => this.handleSubmit()}
                                disabled={loading}>
                                {loading
                                    ? <Spinner size="sm"
                                        className={"mr-2"}
                                        style={{ color: "white" }} />
                                    : <i className="fa fa-floppy-o mr-2" />}
                                {loading ? "Saving" : "Save"}
                            </Button> &nbsp;
                            {fabricObj.fabricBrochureAttachment ?
                                <Button color="primary"
                                    outline={true}
                                    title="Click here to download fabric brochure"
                                    disabled={downloading}
                                    onClick={() => this.onDownloadFabricBrochureAttachment(fabricObj.fabricBrochureAttachment)}>
                                    {downloading
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            color={"primary"} />
                                        : <i className="fa fa-download mr-2" />
                                    }
                                    Download
                                </Button> : null}
                        </div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}

export default withRouter(FabricAttachmentUpload)