import React, {Component} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from 'reactstrap';
import productService from '../../services/product/ProductService';
import {handleErrorMessage} from '../../services/CommonService';
import {Link as Link} from "react-router-dom";
import queryString from 'query-string';
import SearchStock from "../search/SearchStock";
import {toast, ToastContainer} from "react-toastify";

export default class ProductConfigurationStockUsageSearchModal extends Component {

    constructor(props) {

        super(props);
        this.state = {
            searchText: '',
            searchdetails: [],
            isLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.getProductData = this.getProductData.bind(this);
    }
    handleChange(selected) {
        let searchText = '';
        if (selected)
            searchText = selected.prodCode;
        this.setState({ searchText }, () => {
            this.getProductData();
        });
    }

    getBreadcumbUrl(record) {
        let breadCumbDOM = null;
        switch (record.usageType) {
            case "Set":
                breadCumbDOM = <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/inventory/products/all/list?tab=product'}>Product({record.productName})</Link></BreadcrumbItem>
                        <BreadcrumbItem>

                            <Link to={"/inventory/product/configuration?" + queryString.stringify({ productID: record.productConfiguration.productId })}>Configuration({record.productConfiguration.attributeLabel})
                         </Link></BreadcrumbItem>

                        <BreadcrumbItem>
                            <Link to={"/inventory/product/configuration/option/?" + queryString.stringify({ configurationId: record.configurationOption.productConfigurationId, productID: record.productConfiguration.productId })}>Option({record.configurationOption.optionLabel})</Link>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <Link to={"/inventory/product/configuration/option/set/?" + queryString.stringify({ configurationId: record.configurationOption.productConfigurationId, configurationOptionId: record.configurationOptionSet.productConfigurationOptionId, productID: record.productConfiguration.productId })}>Set</Link></BreadcrumbItem>
                    </Breadcrumb>

                </div>;
                break;
            case "Option":
                breadCumbDOM = <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/inventory/products/all/list?tab=product'}>Product({record.productName})</Link></BreadcrumbItem>
                        <BreadcrumbItem>

                            <Link to={"/inventory/product/configuration?" + queryString.stringify({ productID: record.productConfiguration.productId })}>Configuration({record.productConfiguration.attributeLabel})
                         </Link></BreadcrumbItem>

                        <BreadcrumbItem>
                            <Link to={"/inventory/product/configuration/option/?" + queryString.stringify({ configurationId: record.configurationOption.productConfigurationId, productID: record.productConfiguration.productId })}>Option({record.configurationOption.optionLabel})</Link>
                        </BreadcrumbItem>

                    </Breadcrumb>

                </div>;
                break;
            case "Fabric":
                breadCumbDOM = <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/tools/manage/fabrics/list'}>Fabric({record.fabricColor.fabric.fabricName})</Link></BreadcrumbItem>
                        <BreadcrumbItem>

                            <Link to={"/tools/manage/fabrics/one/colors/list?" + queryString.stringify({ fabricId: record.fabricColor.fabricID })}>ColorName({record.fabricColor.color})
                         </Link></BreadcrumbItem>
                    </Breadcrumb>
                </div>;
                break;
        }
        return breadCumbDOM;
    }

    getProductData() {
        let searchdetails = [];
        this.setState({ isLoading: true });
        if (this.state.searchText) {
            productService.getProductConfigurationData(this.state.searchText).then(response => {
                searchdetails = response.data;
                this.setState({ searchdetails, isLoading: false });
            }).catch(error => {
                this.setState({ isLoading: false })
                toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            })
        }
        else {
            this.setState({ searchdetails, isLoading: false });
        }
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { searchText, searchdetails, isLoading } = this.state;
        return (
            <Modal isOpen={isOpen} size="xl" scrollable={true} toggle={() => toggle(!isOpen)} style={searchdetails.length>0 ? { height: "auto" } : { height: "60%" }} name="productConfiguration">
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Search
                </ModalHeader>
                <ModalBody>
                    <div>
                        <SearchStock
                            disabled={false}
                            handleSelection={this.handleChange}
                            selected={searchText}
                            defaultSelected={searchText}
                            filter={{ bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                        />
                    </div>
                    <hr />
                    {isLoading ? <Spinner color="primary" /> :
                        <div>

                            {(searchdetails || []).map((item, itemIndex) => {
                                return <Card className={"m-2"} key={itemIndex}>
                                    <CardBody>
                                        {this.getBreadcumbUrl(item)}
                                    </CardBody>
                                </Card>
                            }
                            )}

                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"secondary"}
                            onClick={() => toggle(!isOpen)}>
                            Close
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}