import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Badge, Button, Table, Input, Spinner} from 'reactstrap';
import FabricService from "../../services/FabricService";
import {toast, ToastContainer} from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import {isEmpty} from 'lodash';
import NumberFormat from "react-number-format";
import {Link} from "react-router-dom";
import {getDateString, handleErrorMessage} from '../../services/CommonService'


export default class FillStockTakeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fabricColors: [],
            loading: false,
            isSaving: false
        }
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        if (this.props.fabricDetails) {
            this.refresh(this.props.fabricDetails.selectedFabricId);
        }
    }

    refresh(fabricId) {
        // get fabric Colors
        if (parseInt(fabricId) > 0) {
            this.setState({loading: true});
            FabricService.fetchFabricColorsforStockTakeModal(fabricId).then(response => {
                if (response.data) {
                    this.setState({fabricColors: response.data, loading: false});
                }
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    getStore() {
        return [
            {
                key: "prodCode",
                label: "Prod Code",
                type: "ProductCode",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-left",
                labelClassName: "hoverableItem text-left",
                searchNode: null
            },
            {
                key: "color",
                label: "Color",
                type: "default",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-left",
                labelClassName: "hoverableItem text-left",
                searchNode: null
            },
            {
                key: "",
                label: "Description",
                type: "stockName",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-left",
                labelClassName: "hoverableItem text-left",
                searchNode: null
            },
            {
                key: "",
                label: "Width",
                type: "linearWidth",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem",
                searchNode: null
            },
            {
                key: "",
                label: "Length",
                type: "length",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem",
                searchNode: null
            },
            {
                key: "qoh",
                label: <div>
                    <div>WW</div>
                    <div>QOH</div>
                </div>,
                type: "qoh",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem",
                searchNode: null
            },
            {
                key: "",
                label: <div>
                    <div>KW</div>
                    <div>QOH</div>
                </div>,
                type: "stockFabric_qoh",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem",
                searchNode: null
            },
            {
                key: "",
                label: "Last Counted On",
                type: "utcDateString",
                colSpan: 1,
                minWidth: '50px',
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "enterStockTake",
                label: <div>
                    <div>Enter</div>
                    <div>Stock Take</div>
                    <div>to Adjust</div>
                </div>,
                type: "StockTake",
                colSpan: 1,
                minWidth: '60px',
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "",
                label: <div>
                    <div>WW-KW Stock</div>
                    <div>Difference</div>
                    <div>Status</div>
                </div>,
                type: "IsComplete",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                extendedColumn: false,
                searchNode: null
            },
        ];
    }

    getColValue(value, storeItem, obj, index) {
        switch (storeItem.type) {
            case "utcDateString":
                if (!isEmpty(obj.stockFabric)) {
                    return getDateString(obj.stockFabric.lastModifiedOn, 'DDMMMYY');
                }
                else {
                    return <span></span>
                }
            case "ProductCode":
                return <Link className="btn btn-sm btn-primary" style={{color: "white"}}
                             title={"Click here to see stock details"}
                             to={"/inventory/stock/enquiry?prodCode=" + value}>
                    {value}
                </Link>
            case "currency":
                return <span><NumberFormat value={Math.floor(value)}
                                           displayType={'text'}
                                           thousandSeparator={true}
                                           decimalScale={2}
                                           fixedDecimalScale={true}
                                           prefix={'$'}/></span>;
            case "number":
                return <span><NumberFormat value={value} displayType={'text'}
                                           thousandSeparator={true}/></span>;
            case "StockTake":
                return <div style={{minWidth: 90}}>
                    <Input type={"number"}
                           name={"stocktake"}
                           placeholder={"Enter actual stock count"}
                           value={obj.physicalCount}
                           onChange={(e) => this.handleChange("stocktake", e.target.value, obj)}
                    />
                </div>;
            case "IsComplete":
                let color = "warning";
                let str = "Not matching";
                let icon = "fa fa-exclamation-triangle mr-2";

                let keyway_stock_qoh = 0;
                if (!isEmpty(obj.stockFabric)) {
                    keyway_stock_qoh = obj.stockFabric.qoh;
                }
                if (parseFloat(keyway_stock_qoh) === parseFloat(obj.stock.qoh)) {
                    color = "success";
                    str = "Matching";
                    icon = "fa fa-check-circle-o mr-2";
                }
                return <div className="text-center">
                    <Badge size={"sm"} color={color}>
                        <i className={icon} aria-hidden="true"/>
                        {str}
                    </Badge>
                </div>;
            case "linearWidth":
                return <span>{obj.fabric.width}</span>;
            case "length":
                return <span>{obj.stock.length}</span>;
            case "stockName":
                return <small>{obj.stock.stockName}</small>;
            case "qoh":
                return <span className={"text-right"}>
                    <NumberFormat value={obj.stock.qoh} displayType={'text'} decimalScale={2} thousandSeparator={true} fixedDecimalScale={true}/>
                </span>;
            case "stockFabric_qoh":
                let stockFabric_qoh = 0;
                if (!isEmpty(obj.stockFabric)) {
                    stockFabric_qoh = obj.stockFabric.qoh;
                }
                return <span className={"text-right"}>
                    <NumberFormat value={stockFabric_qoh} displayType={'text'} decimalScale={2}  fixedDecimalScale={true}
                                  thousandSeparator={true}/>
                </span>;
            default:
                return <span>{value}</span>
        }
    }

    handleChange(key, change, obj) {
        let {fabricColors} = this.state;
        switch (key) {
            case "stocktake":
                const index = fabricColors.findIndex(x => x.prodCode === obj.prodCode);
                fabricColors[index].physicalCount = change;
                fabricColors[index].isChanged = true;
                this.setState({fabricColors});
                break;
            default:
                break;
        }
    }

    handleSave() {
        let data = [];
        let fabricColors = [...this.state.fabricColors];
        fabricColors.forEach(function (ele) {
            if (ele.isChanged) {
                data.push({
                    prodCode: ele.prodCode,
                    physicalCount: ele.physicalCount,
                    fabricId: ele.fabricId,
                    id: ele.id,
                    description: ele.fabric.description,
                    width: ele.fabric.width,
                });
            }
        });

        // call update backend request
        this.setState({isSaving: true});
        FabricService.updateStockTake(data).then(response => {
            if (response.status === "200" || response.status === 200) {
                this.setState({isSaving: false});
            }
            this.refresh(this.props.fabricDetails.selectedFabricId);
        }).catch(error => {
            this.setState({isSaving: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {fabricColors, loading, isSaving} = this.state;
        let {isOpen, toggle, fabricDetails} = this.props;
        let store = this.getStore();

        return (
            <Modal isOpen={isOpen} size="xl" backdrop={true} scrollable={false}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {loading
                        ? <Spinner color={"primary"} size={"sm"}/>
                        : <span>{fabricDetails.fabricName}</span>
                    }
                </ModalHeader>
                <ModalBody style={{minHeight: 300}}>
                    {loading ? <Spinner color={"primary"}/> :
                        <div>
                            <div className="text-center">
                                <div className="mt-2 text-center">
                                    <div>
                                        <Table hover bordered size={"sm"} striped responsive>
                                            <thead>
                                            <tr>
                                                {(store || []).filter(item => item.showColumn ? true : false).map((item, index) => {
                                                    return (
                                                        <th key={index}
                                                            colSpan={item.colSpan}
                                                            style={{minWidth: item.minWidth}}>
                                                            {item.label}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                            </thead>
                                            {(fabricColors.length === 0 || fabricColors.length === '0') ?
                                                <tbody>
                                                <tr>
                                                    <td colSpan="10">No Items ...</td>
                                                </tr>
                                                </tbody>
                                                :
                                                <tbody>
                                                {(fabricColors || []).map((fabricColor, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {(store || []).filter(item => item.showColumn ? true : false).map((storeItem, index) => {
                                                                return (
                                                                    <td key={index}
                                                                        className={storeItem.valueClassName}>
                                                                        {this.getColValue(fabricColor[storeItem.key], storeItem, fabricColor, index)}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            }
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color='primary' onClick={this.handleSave} disabled={isSaving}>
                            {
                                isSaving
                                    ? <Spinner size="sm" className={"mr-2"} style={{color: "white"}}/>
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>
                            }
                            Save All
                        </Button>
                        {' '}
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)} disabled={isSaving}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/> Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }

}