import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Row, Col, Input, Spinner, FormText } from 'reactstrap';
import classnames from 'classnames';



export default class CreateAddressStandardModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productID: 0,
            productid: 0,
            FormErrors: {},
            saving: false,
            loading: false
        };

    }

    validateAndSubmit(stockObj) {
        // true means invalid
        let flag = true;
        if (stockObj.layout == '') {
            flag = false;
        }
        if (stockObj.operationGroup == '') {
            flag = false;
        }
        
        
        if (flag) {
            this.props.handleSubmit(stockObj);
            
        }
    }



    


    render() {
        let { isOpen, toggle, loading, handleChange, stockObj, productID,layoutTypes } = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {stockObj.header}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div>Layout
                            <Input type={"select"}
                                placeholder={"Layout"}
                                name={"layout"}
                                value={stockObj.layout}
                                onChange={(e) => handleChange(e)}
                                disabled={stockObj.isEdit}>
                            <option value={""}>Select Layout</option>
                            {layoutTypes.map((layout, index) =>
                                <option key={index} value={layout}>{layout}</option>
                                )}
                            </Input>
                        </div>

                        <div className={"mt-2"}>
                            Operation Group
                            <Input type={"text"}
                                placeholder={"Operation Group"}
                                name={"operationGroup"}
                                value={stockObj.operationGroup}
                                onChange={(e) => handleChange(e)}>
                                
                            </Input>
                        </div>

                        <div className={"mt-2"}>
                            Blind Position
                            <Input type={"select"}
                                placeholder={"Blind Position"}
                                name={"blindPosition"}
                                value={stockObj.blindPosition}
                                onChange={(e) => handleChange(e)} >
                                <option key='0' value=''>Select Blind Position</option>
                                <option key='1' value='Left'>Left</option>
                                <option key='2' value='Right'>Right</option>
                                <option key='3' value='Centre'>Centre</option>
                            </Input>
                        </div>

                        <div className={"mt-2"}>
                            Control Side
                            <Input type={"select"}
                                placeholder={"Control Side"}
                                name={"controlSide"}
                                value={stockObj.controlSide}
                                onChange={(e) => handleChange(e)} >
                                <option key='0' value=''> Select Control Side</option>
                                <option key='1' value='Left'> Left </option>
                                <option key='2' value='Right'> Right </option>

                            </Input>
                        </div>

                        <div className={"mt-2"}>
                            Deduction
                            <Input type={"text"}
                                placeholder={"Deduction"}
                                name={"deduction"}
                                value={stockObj.deduction}
                                onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">

                        <Button color='primary' onClick={this.props.handleSubmit} disabled={this.state.saving} >

                            {this.state.saving ? <Spinner size="sm" className="mr-2"
                                style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                    aria-hidden="true" />}
                                Save</Button>{' '}
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}