import React from 'react';
import {getDistinctValues, getSorted, groupBy} from "../../../../../../services/CommonService";
import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_CURTAIN_TRACK_PART} from "../../../../../../store/AppConstants";
import curtainTrackUtil from "./CurtainTrackUtil";

const QUANTITY_MIN = 1;
const QUANTITY_MAX = 1000;

class CurtainTrackPartUtil {

    static Instance() {
        return new CurtainTrackPartUtil();
    }

    prepareConfiguration(product) {
        let optionIndex;
        product.part = { configuration: {} };
        //remove soldOut prodcode
        product.builder.stockKeyway = product.builder.stockKeyway.filter(x => product.builder.stocks.some(y => y.prodCode === x.prodCode && !y.soldOut));
        //part
        let parts = getSorted(product.builder.stockKeyway, 'sortOrder', true);
        product.part.configuration.part = {
            options: parts,
            dropdown: {
                finalOptions: [],
                selected: {
                    value: []
                }
            },
            displayList: {
                finalOptions: [],
            }
        };
        //part colour
        product.part.configuration.colour = {
            dropdown: {
                options: getDistinctValues(product.part.configuration.part.options, "colour"),
                finalOptions: [],
                selected: {
                    value: []
                }
            }
        };
        product.part.configuration.colour.dropdown.finalOptions = product.part.configuration.colour.dropdown.options.map(o => {
            return {label: o, value: o};
        });
      /*  optionIndex = product.part.configuration.colour.dropdown.finalOptions.findIndex(o => o.value.toUpperCase() === "WHITE");
        if (optionIndex > -1) {
            product.part.configuration.colour.dropdown.selected.value = [product.part.configuration.colour.dropdown.finalOptions[optionIndex]];
        }*/

        //part Types
        product.part.configuration.partType = {
            options: [],
            finalOptions: [],
            isOpen: false
        };
        product.part.configuration.partType.options = (getDistinctValues(product.part.configuration.part.options, 'partType') || []).map(o => {
            return {label: o, value: o, isOpen: false};
        });

        return product;
    }

    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "quantity":
                if ((!product.items[itemIndex].quantity.selected.value) || (product.items[itemIndex].quantity.selected.value < QUANTITY_MIN) || (product.items[itemIndex].quantity.selected.value > QUANTITY_MAX)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + QUANTITY_MIN + " and " + QUANTITY_MAX,
                    };
                }
                product.items[itemIndex].quantity.formError = formError;
                break;
            default:
                break;
        }
        product.items[itemIndex].isValid = curtainTrackUtil.isFormErrorItemValid(product, itemIndex);
        return product;
    }

    isOpenAllPartType(product, enforce) {
        let result = false;
        if (!enforce) {
            if (!isEmpty(product.part.configuration.part.dropdown.selected.value)) {
                product.part.configuration.partType.finalOptions.forEach(o => {
                    o.isOpen = true;
                });
                result = true;
            }
        }

        if (!result) {
            result = product.part.configuration.partType.finalOptions.every(x => x.isOpen);
        }
        return result;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | " + pg.items[itemIndex].itemType + " | ";

        if (!pg.items[itemIndex].quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].quantity.formError.message);
        }
        return errors;
    }

    toSalesOrderItemCurtainTrackPartRequest(pg, itemIndex, order, errors) {
        let salesOrderItemCurtainTrackPartRequest = {
            ID: pg.items[itemIndex].ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,

            itemType: pg.items[itemIndex].itemType,
            prodCode: pg.items[itemIndex].prodCode,
            description: pg.items[itemIndex].description,
            colour: pg.items[itemIndex].itemType === PRODUCT_BUILDER_CURTAIN_TRACK_PART ? pg.items[itemIndex].colour : (pg.items[itemIndex].configuration.colour.selected.value ? pg.items[itemIndex].configuration.colour.selected.value.value : ""),
            partType: pg.items[itemIndex].partType,
            eachUnitName: pg.items[itemIndex].eachUnitName,
            sellUnitName: pg.items[itemIndex].sellUnitName,
            sellUnitQty: pg.items[itemIndex].sellUnitQty,
            sellQtyPrice: pg.items[itemIndex].sellQtyPrice,
            quantity: pg.items[itemIndex].quantity.selected.value,
            partRange: pg.items[itemIndex].configuration.partRange.selected.value ? pg.items[itemIndex].configuration.partRange.selected.value.value : "",
            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemCurtainTrackPartRequest;
    }

}

export default CurtainTrackPartUtil.Instance();