import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Badge,
    Table,
    Spinner,
} from 'reactstrap';
import { toast } from 'react-toastify';
import freightService from '../../services/FreightService';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { handleErrorMessage } from '../../services/CommonService';

export default class CustomerFreights extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountId: '',
            rules: [],
            loading: {
                rulesList: false,
            },
           
        };

        this.refresh = this.refresh.bind(this);
        this.getFreightRules = this.getFreightRules.bind(this);
        
    }

    componentDidMount() {
        this.setState({ accountId: this.props.accountId }, () => { this.refresh() });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({ accountId: nextProps.accountId }, function () {
                this.refresh();
            });
        }
    }


    refresh() {
        this.getFreightRules('customer');
    }

    getFreightRules(category) {
        if (this.state.accountId) {
            let { rules, accountId, loading } = this.state;
            loading.rulesList = true;
            this.setState({ loading });
            freightService.getFreightRules(category, accountId).then(response => {
                rules = response.data;
                loading.rulesList = false;
                this.setState({ rules, loading });
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                loading.rulesList = false;
                this.setState({ loading });
            });
        }
    }

    render() {

        let { rules, loading, accountId} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        Delivery Method Rules
                        <span style={{ float: "right" }}> <Link to={"/manage/freight/rule?" + queryString.stringify({ accountId: accountId })}
                            size="xs"><i
                                className={"fa fa-pencil"} /></Link></span>
                    </CardHeader>
                    <CardBody>
                        {loading.rulesList
                            ? <Spinner color={"primary"} />
                            : <div>
                                <Table responsive={true} hover={true} bordered={true}>
                                    <thead>
                                        <tr>
                                            <td colSpan={2} className={"text-center"}>Charge Condition</td>
                                            <td colSpan={1} className={"text-center"}>Display</td>
                                            <td colSpan={1} className={"text-center"}>High(large no.)</td>
                                            <td colSpan={3} className={"text-center"}>Attribute</td>
                                            <td colSpan={2} className={"text-center"}>Charge</td>
                                            <td colSpan={2} className={"text-center"}>For Specific</td>
                                        </tr>
                                        <tr>
                                            <th>Method</th>
                                            <th>Rule</th>
                                            <th className={"text-center"}>Access</th>
                                            <th className={"text-center"}>Priority</th>
                                            <th>Name</th>
                                            <th className={"text-right"}>Min</th>
                                            <th className={"text-right"}>Max</th>
                                            <th className={"text-right"}>Metro</th>
                                            <th className={"text-right"}>Non-Metro</th>
                                            <th className={"text-right"}>PostCode</th>
                                            <th className={"text-right"}>User Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(rules && rules.length > 0) ?
                                            (rules || []).map((item, index) =>
                                        
                                             <tr key={index}>
                                                <td>{item.methodLabel}</td>
                                                <td>{item.description}</td>
                                                <td className={"text-center"}>
                                                    <Badge
                                                        color={item.isPublic ? "success" : "primary"}>{item.isPublic ? "Public" : "Selective"}</Badge>
                                                </td>
                                                <td className={"text-center"}>{item.priority}</td>
                                                <td>{item.attributeLabel}</td>
                                                <td className={"text-right"}>{item.attributeValueMin}</td>
                                                <td className={"text-right"}>{item.attributeValueMax}</td>
                                                <td className={"text-right table-success"}>${item.metroCharge}</td>
                                                <td className={"text-right table-success"}>${item.nonMetroCharge}</td>
                                                <td className={"text-right"}>{item.postcode}</td>
                                                <td className={"text-right"}>{item.userType}</td>
                                                </tr>) :
                                            <tr>
                                                <td colSpan="10" className="text-center">No items.</td>
                                            </tr>
                                        }
                                    </tbody>

                                </Table>
                            </div>
                        }
                    </CardBody>
                </Card>
            </div>);

    }
}
