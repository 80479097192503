import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import axiosFileUploader from './axiosFileUploader';

export default class StockService {

    getStockGroups() {
        return axios.get('api/stock/stock-group/list');
    }

    getStocks(request) {
        return axios.post('api/stock/dropdown', request);
    }

    getStocksByProdCode(prodCodes) {
        let requestBody = {
            soldOut: false,
            discontinued: false,
            description: "",
            prodCode: prodCodes,
        };
        return axios.post('api/stock/summary/list/byProdCodes', requestBody);
    }

    getStock(prodCode) {
        return axios.get('api/stock/one/?prodCode=' + prodCode);
    }

    getStockItems(currentPage, pageSize, searchText) {
        const requestBody = {selectedPage: currentPage, pageSize: pageSize, searchTerm: searchText};
        return axios.post('api/stock/list', requestBody);
    }

    getStockList(req) {
        return axios.post('api/stock/list/all', req);
    }

    getStockGroupList(req) {
        return axios.post('api/stock/getStockGroups', req);
    }

    getStockMasterGroup() {
        return axios.get('api/stock/stock-master-group/list');
    }

    saveStock(req) {
        return axios.post('api/stock/save', req);
    }

    updateStockIntro(req) {
        return axios.post('api/stock/one/update/intro', req);
    }

    getStockDropdownByDebtorInvoice(searchText, orderNum) {
        return axios.post('api/stock/dropdown/byDebtorInvoice?searchText=' + searchText + "&orderNum=" + orderNum);
    }

    getFabricProdCodes(searchText) {
        return axios.get('api/stock/search/fabric/stockcodes?ProdCode=' + searchText);
    }

    getStockTransactions(req) {
        return axios.post('api/stock/transaction/list', req);
    }

    exportStockTransaction(requestBody) {
        return axiosFileDownloader.post("api/stock/transaction/list/export/excel", requestBody);
    }

    getStockFabricTransactions(req) {
        return axios.post('api/stock/fabric/transactions', req);
    }

    getStockTransactionType() {
        return axios.get('api/stock/transaction/type');
    }

    getStockKeepingUnit() {
        return axios.get('api/stock/inventory/stock/keeping/unit');
    }

    getStockKeepingUnitFabricData() {
        return axios.get('api/stock/inventory/stock/keeping/unit/fabric');
    }

    exportStockGroupInExcel() {
        return axiosFileDownloader.get('api/stock/stockGroups/download/excel');
    }

    generateStockExcelReport(request, isFilter) {
        return axiosFileDownloader.post('api/stock/stocksList/download/excel?isFilter=' + isFilter, request);
    }

    getStockGroupDropdownOptions(requestBody) {
        return axios.post('api/stock/stock-group/dropdown', requestBody);
    }

    getStockMasterGroupDropdownOptions(requestBody) {
        return axios.post('api/stock/stock-master-group/dropdown', requestBody);
    }

    saveStockGroupAllDetail(request) {
        return axios.post('api/stockGroup/saveAllDetail', request);
    }

    getStockDetails(orderNum, stockGroup) {
        return axios.post('api/stock/stockDetail?stockGroup=' + stockGroup + "&orderNum=" + orderNum);
    }

    GetStockProdCode(request) {
        return axiosFileUploader.post("api/stock/invenory/stock/prodcode/upload", request);
    }

    updateStocksWithDiscontinueSoldOutByProdCodes(request) {
        return axios.post("api/stock/manage/stock/update/bulk", request);
    }

    getStockSoldUnitsYearMonthSummary(prodCode) {
        return axios.get('api/stock/one/sales/units/year-month/summary?prodCode=' + prodCode);
    }

    stockSoldUnitsMonthYearForLineChart(prodCode, years) {
        return axios.get('api/stock/one/sales/units/year-month/chart?prodCode=' + prodCode + '&years=' + years);
    }

    getStockSuppliers(prodCode) {
        return axios.get('api/stock/one/suppliers?prodCode=' + prodCode);
    }

    setAsPreferredSupplier(prodCode, accountID) {
        return axios.get('api/stock/one/suppliers/preferred/update?prodCode=' + prodCode + '&accountID=' + accountID);
    }

    getCreditorStock(prodCode, accountID) {
        return axios.get('api/stock/one/suppliers/creditor-stock/get?prodCode=' + prodCode + "&accountID=" + accountID);
    }

    updateCreditorStock(request) {
        return axios.post('api/stock/one/suppliers/creditor-stock/save', request);
    }

    getStockGroupSKUdata(accountID) {
        return axios.get('api/stock/one/stockGroup/sku?accountID=' + accountID);
    }

    getPurchaseHistory(accountID) {
        return axios.get('api/stock/one/purchases/monthly?accountID=' + accountID);
    }

    generateKeywayStockImportFile(selectedStocks) {
        return axiosFileDownloader.post('api/stock/download/keyway/excel', selectedStocks);
    }

    generateAllFilteredKeywayStockImportFile(request) {
        return axiosFileDownloader.post('api/stock/download/keyway/filter/excel', request);
    }
    getStockByNavigation(request) {
        return axios.post('api/stock/get/stock/one/navigation', request);
    }

    removeSupplierForStock(accountID, prodCode) {
        return axios.put(`api/stock/update/supplier?accountID=${accountID}&prodCode=${prodCode}`);
    }

}