import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Table} from "reactstrap";

import NumberFormat from "react-number-format";


export default function StockEnquiryPageGeneralTabPricingCard(props) {
    let {stock} = props;
    let keywayStock = (stock && stock.keywayStock) ? stock.keywayStock : {};
    return <Card>
        <CardHeader>
            <h6 className="mb-0">Pricing</h6>
        </CardHeader>
        <CardBody>
            <Table size={"sm"} className={"mb-0"}>
                <tbody>
                <tr>
                    <td/>
                    <td className="text-right">Qty</td>
                    <td className="text-right">Price</td>
                </tr>
                <tr>
                    <th>Each</th>
                    <td className="text-right">1({keywayStock.eachUnitName})</td>
                    <td className="text-right">
                        {
                            keywayStock.eachUnitPrice
                                ? <NumberFormat
                                    value={keywayStock.eachUnitPrice}
                                    prefix={'$'}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                                : "-"
                        }
                    </td>
                </tr>
                <tr>
                    <th>Sell</th>
                    <td className="text-right">
                        {keywayStock.sellUnitQty
                            ? <span>{keywayStock.sellUnitQty}({keywayStock.sellUnitName})</span>
                            : "-"}
                    </td>
                    <td className="text-right">
                        {
                            keywayStock.sellQtyPrice
                                ? <NumberFormat
                                    value={keywayStock.sellQtyPrice}
                                    prefix={'$'}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/>
                                : "-"
                        }
                    </td>
                </tr>
                <tr>
                    <th>Warehouse</th>
                    <td className="text-right">
                        {keywayStock.whUnitQty
                            ? <span>{keywayStock.whUnitQty}({keywayStock.whUnitName})</span>
                            : "-"}
                    </td>
                    <td className="text-right">
                        -
                    </td>
                </tr>
                </tbody>
            </Table>
        </CardBody>
    </Card>
}