import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Table,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Form,
    FormGroup,
    FormText,
    Label,
    Input,
    Button, TextAream, UncontrolledTooltip, Collapse, Badge
} from 'reactstrap';
import {Link as Linkk} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import {Link, DirectLink, Element, Events, animateScroll, scrollSpy, scroller} from 'react-scroll'
import CRMService from "../../services/crm/CRMService";
import LeadActivity from "../../components/crm/LeadActivity";
import LeadRemark from "../../components/crm/LeadRemark";

import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import CustomerService from "../../services/CustomerService";

import 'react-toastify/dist/ReactToastify.css';


import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce'


import {convertUtcDateToLocalDate,handleErrorMessage} from '../../services/CommonService';


export default class CRMLeadManagePage extends Component {


    constructor(props) {
        super(props);
        let leadId = 0;
        let activityID = 0;
        let accountID = "";
        if (props.location.state) {
            if (props.location.state.lead) {
                if (props.location.state.lead.id) {
                    leadId = props.location.state.lead.id;
                    activityID = props.location.state.lead.activityID;
                    accountID = props.location.state.lead.accountID;
                }
            }
        } else {
            if (props.match.params) {
                if (props.match.params.id) {
                    leadId = window.atob(props.match.params.id);
                }
            }
        }

        this.state = {
            users: [],
            userLoading: false,
            leadLoading: false,
            selectedUser: {},
            lead: {
                id: leadId,
                personName: "",
                company: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                city: "",
                state: "",
                postCode: "",
                phone: "",
                email: "",
                website: "",
                accountID: accountID,
                ownerUserID: "",
                ownerUserName: "",
                ownerUserEmail: "",
                amount: 0,
                note: "",
                status: "NEW",
                priority: "LOW",
                comment: "",
                createdDate: "",
                createdByUserID: "",
                activities: [],
                remarks: [],
                activityID: activityID
            },
            editLeadMode: false,
            formErrors: {},
            togglers: {
                toggle1: false,
                toggle2: false,
                toggle3: false,
                toggle4: false,
            }
        };

        this.crmService = new CRMService();
        this.customerService = new CustomerService();
        this.createNewLead = this.createNewLead.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLeadStatus = this.handleChangeLeadStatus.bind(this);
        this.toggleEditLeadMode = this.toggleEditLeadMode.bind(this);
        this.fetchLead = this.fetchLead.bind(this);
        this.DropLead = this.DropLead.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.loadOptions = this.loadOptions.bind(this);


    }

    componentDidMount() {
        if (this.state.lead.id) {
            this.fetchLead(this.state.lead.id, this.state.lead.activityID);
        } else {
            this.searchOwner("");
        }
        this.toggleEditLeadMode("toggle1", true);


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state.lead.id !== this.state.lead.id || nextProps.location.state.lead.activityID !== this.state.lead.activityID) {
            this.fetchLead(nextProps.location.state.lead.id, nextProps.location.state.lead.activityID);
        }
    }


    fetchLead(id, activityID) {
        this.setState({leadLoading: true});
        this.crmService.getLead(id).then(response => {
            let lead = response.data;
            if (lead) {
                if (lead.activities) {
                    for (let i in lead.activities) {
                        if (lead.activities[i].callbackOn) {
                            lead.activities[i].callbackOn = convertUtcDateToLocalDate(lead.activities[i].callbackOn);
                            if (lead.activities[i].callbackOn) {
                                lead.activities[i].callbackOn = lead.activities[i].callbackOn.toDate();
                            }
                        }
                    }
                }
                lead.activityID = activityID;
            }

            this.setState({lead: lead, leadLoading: false});
            this.searchOwner(lead.ownerUserEmail);
            //   this.setState({lead}, () => animateScroll.scrollToBottom());


        }).catch(error => {
            alert(handleErrorMessage(error));
                this.setState({leadLoading: false});
            }
        );
    }

    DropLead(id) {
        this.crmService.dropLead(id).then(response => {
            toast.info("Lead Dropped!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            let {lead} = this.state;
            lead.status = "DROP";
            this.setState({lead});
        }).catch(error => {
            alert(handleErrorMessage(error));
        });
    }

    toggleCollapse(item) {
        let togglers = this.state.togglers;
        togglers[item] = !togglers[item];

        this.setState({togglers});
    }

    toggleEditLeadMode(toggleParam, editLeadModeParam) {

        /**
         * toggle the item and edit mode.
         * @param {string} toggleParam The toggler Id , which element to toggle.
         * @param {boolean} editLeadModeParam, if true then set edit lead mode = true,
         *                                if false then toggle it.
         */


        let {togglers, editLeadMode} = this.state;
        togglers[toggleParam] = true;
        editLeadMode = editLeadModeParam ? true : !editLeadMode;

        this.setState({
            editLeadMode: editLeadMode,
            togglers: togglers
        });
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {lead} = this.state;
        lead[name] = value;
        this.setState({lead});
    }

    handleChangeLeadStatus(status) {
        let {lead} = this.state;
        lead.status = status;
        this.setState({lead});
        if (status === "DROP") {
            this.DropLead(this.state.lead.id);
        }
        // todo add action
    }


    handleCancel() {
        if (this.state.lead.id === 0) {
            this.props.history.push('/crm/leads');
        } else {
            this.setState({editLeadMode: false});
            this.fetchLead(this.state.lead.id, this.state.lead.activityID);
        }
    }


    validateForm() {
        let formErrors = {};
        formErrors.personName = this.state.lead.personName.trim().length > 50 ? "max characters 50" : "";
        formErrors.address1 = this.state.lead.address1.trim().length > 50 ? "max characters 50" : "";
        formErrors.address2 = this.state.lead.address2.trim().length > 50 ? "max characters 50" : "";
        formErrors.address3 = this.state.lead.address3.trim().length > 50 ? "max characters 50" : "";
        formErrors.address4 = this.state.lead.address4.trim().length > 50 ? "max characters 50" : "";
        formErrors.city = this.state.lead.city.trim().length > 25 ? "max characters 50" : "";
        formErrors.state = this.state.lead.state.trim().length > 25 ? "max characters 50" : "";
        formErrors.postCode = this.state.lead.postCode.trim().length > 25 ? "max characters 50" : "";
        formErrors.phone = this.state.lead.phone.trim().length > 20 ? "max characters 50" : "";
        formErrors.email = this.state.lead.email.trim().length > 50 ? "max characters 50" : "";
        formErrors.website = this.state.lead.website.trim().length > 50 ? "max characters 50" : "";
        // formErrors.AccountID = this.state.lead.AccountID.trim().length > 50 ? "max characters 50" : "";
        formErrors.note = this.state.lead.note.trim().length > 0 ? "" : "query is required";

        if (!(this.state.lead.phone || this.state.lead.email || this.state.lead.website)) {
            formErrors.phone = "at least one contact info required";
            formErrors.email = "at least one contact info required";
            formErrors.website = "at least one contact info required";
        } else {
            formErrors.phone = "";
            formErrors.email = "";
            formErrors.website = "";
        }

        if (formErrors.personName
            || formErrors.address1
            || formErrors.address2
            || formErrors.address3
            || formErrors.address4
            || formErrors.city
            || formErrors.state
            || formErrors.postCode
            || formErrors.phone
            || formErrors.email
            || formErrors.website
            || formErrors.AccountID
            || formErrors.note
        ) {
            return {valid: false, formErrors: formErrors};
        }
        return {valid: true, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();

        let result = this.validateForm();
        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            toast.info("See form error for invalid input ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        let {lead} = this.state;
        this.crmService.saveLead(lead).then(response => {
            toast.success("Lead Saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            let savedLead = response.data;
            if (savedLead) {
                if (savedLead.activities) {
                    for (let i in savedLead.activities) {
                        if (savedLead.activities[i].callbackOn) {
                            savedLead.activities[i].callbackOn = convertUtcDateToLocalDate(savedLead.activities[i].callbackOn);
                            if (savedLead.activities[i].callbackOn) {
                                savedLead.activities[i].callbackOn = savedLead.activities[i].callbackOn.toDate();
                            }
                        }
                    }
                }
            }
            this.setState({lead: savedLead, editLeadMode: false});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
        });

    }


    ownerChanged = (selectedUser) => {
        const {lead} = this.state;
        let ownerUserID = "";
        let ownerUserName = "";
        let ownerUserEmail = "";
        if (selectedUser) {
            ownerUserID = selectedUser.userId;
            ownerUserName = this.crmService.getFullName(selectedUser.firstName, selectedUser.lastName);
            ownerUserEmail = selectedUser.emailAddress;
        }
        lead.ownerUserID = ownerUserID;
        lead.ownerUserName = ownerUserName;
        lead.ownerUserEmail = ownerUserEmail;
        this.setState({
            selectedUser: selectedUser,
            lead
        });

    };

    searchOwner(inputValue, callback) {
        let self = this;
        let users = [];
        this.crmService.searchOwner(inputValue).then(response => {

            let data = response.data;

            for (let i in data) {
                let user = {
                    label: i,
                    value: data[i].userId,
                    id: data[i].userId,
                    userId: data[i].userId,
                    firstName: data[i].firstName,
                    lastName: data[i].lastName,
                    emailAddress: data[i].emailAddress
                };
                users.push(cloneDeep(user));

                if (self.state.lead.ownerUserID) {
                    if (data[i].userId === self.state.lead.ownerUserID) {
                        this.ownerChanged(user);
                    }
                }
            }
            self.setState({users});
            if (callback) {
                return callback(users)
            } else {
                return users;
            }
        }).catch(error => {
            alert(handleErrorMessage(error));
            }
        );
    };

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.searchOwner(inputValue, callback)
            } else {
                callback(this.state.users);
            }
        }
    };


    createNewLead() {
        this.setState(prevState => ({
            editLeadMode: true,
            selectedUser: "",
            formErrors: {},
            lead: {
                id: 0,
                personName: "",
                company: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                city: "",
                state: "",
                postCode: "",
                phone: "",
                email: "",
                website: "",
                accountID: "",
                ownerUserID: "",
                ownerUserName: "",
                ownerUserEmail: "",
                amount: 0,
                note: "",
                status: "NEW",
                priority: "LOW",
                comment: "",
                createdDate: "",
                createdByUserID: ""
            }
        }));

        animateScroll.scrollToTop();
        this.toggleEditLeadMode("toggle1", true);
    }

    getCustomerData(accountID) {
        let { lead } = this.state;
        lead.accountID = accountID;
        this.customerService.searchCustomer(accountID).then(response => {

            let data = response.data;
            lead.personName = data.company ? data.company : "";
            lead.company = data.company ? data.company : "";
            lead.address1 = data.address1 ? data.address1 : "";
            lead.address2 = data.address2 ? data.address2 : "";
            lead.address3 = data.address3 ? data.address3 : "";
            lead.address4 = data.address4 ? data.address4 : "";
            lead.city = data.city ? data.city : "";
            lead.state = data.state ? data.state : "";
            lead.postCode = data.postCode ? data.postCode : "";
            lead.phone = data.phone ? data.phone : "";
            lead.email = data.email ? data.email : "";
            lead.website = data.website ? data.website : "";

            this.setState({
                lead: lead, userLoading: false, leadLoading: false
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ userLoading: false, leadLoading: false });
        });
    }

    handleDebtorAccountDetails(accountId) {
        let { lead } = this.state;
        lead.accountID = accountId;
        this.setState({ lead }, () => {this.getCustomerData(accountId)});
    }


    render() {

        let {lead, editLeadMode, formErrors, leadLoading} = this.state;

        return (
            <div>
                <div>
                    <div className={"text-right mb-2"}>
                        {editLeadMode ? <Button color='secondary'
                                                className="btn btn-sm ml-1"
                                                onClick={this.handleCancel}>Cancel
                        </Button> : null
                        }

                        {lead.id !== 0 ? <Button hidden={lead.id === 0} color='primary'
                                                 className="btn btn-sm ml-1"
                                                 onClick={this.createNewLead}>
                            <i className="fa fa-plus"/>&nbsp;
                            Create new lead
                        </Button> : null
                        }

                        {editLeadMode ? <Button hidden={!editLeadMode}
                                                color='primary'
                                                className="btn btn-sm ml-1"
                                                onClick={this.handleSubmit}>
                            {lead.id === 0 ? "Save Lead" : "Update"}
                        </Button> : null
                        }


                        {(() => {
                            if (lead.id !== 0 && lead.status !== "DROP") {
                                return (
                                    <Button color='danger'
                                            onClick={() => this.DropLead(lead.id)}
                                            className="btn btn-sm ml-1"><i className="fa fa-thumbs-o-down"
                                                                           aria-hidden="true"/>&nbsp;
                                        Drop
                                    </Button>
                                )

                            }
                        })()}
                        {(() => {
                            if (lead.accountID || lead.id === 0 || lead.status === "CONVERTED") {
                                return null;
                            } else {
                                return (
                                    <Linkk className="btn btn-sm ml-1 btn btn-success" to={{
                                        pathname: '/crm/convertlead',
                                        state: {lead: lead}
                                    }}><i className="fa fa-thumbs-o-up"
                                          aria-hidden="true"/>&nbsp;Convert to new customer</Linkk>
                                )

                            }

                        })()}
                    </div>

                    {(() => {
                        if (leadLoading) {
                            return (
                                <p>Loading...</p>
                            )
                        }
                        return (
                            <div id="manageLead">
                                <div>
                                    <div>
                                        <div>
                                            {(() => {
                                                if (lead.id) {
                                                    return (<LeadActivity lead={lead} fetchLead={this.fetchLead}/>)
                                                }
                                            })()}
                                        </div>

                                        <Card style={{marginBottom: "7px"}} className="shadow-0 border">
                                            <CardHeader style={{padding: "15px"}}>
                                                <Row>
                                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                        <h4 className={"text-left"}><a
                                                            onClick={() => this.toggleCollapse("toggle1")}
                                                            href="javascript:void(0)">
                                                            {this.state.togglers.toggle1 ?
                                                                <i className="icon-arrow-circle-right"/> :
                                                                <i className="icon-arrow-circle-down"/>}
                                                            &nbsp; Lead Info </a>
                                                        </h4>

                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                        <h4 className={"text-right"}><a href="javascript:void(0)"
                                                                                        onClick={() => this.toggleEditLeadMode("toggle1", false)}>
                                                            <i className={editLeadMode ? "fa fa-pencil-square-o" : "fa fa-pencil-square"}
                                                            />
                                                        </a>
                                                        </h4>
                                                    </Col>
                                                </Row>


                                            </CardHeader>
                                            <Collapse isOpen={this.state.togglers.toggle1}>
                                                <CardBody>
                                                    <Form>
                                                        <div>
                                                            {editLeadMode ? <FormGroup>
                                                                    <Label className="col-form-label"><h6> Customer's
                                                                        Account &nbsp;{(() => {
                                                                            if (lead.accountID) {
                                                                                return (
                                                                                    <Linkk
                                                                                        className="btn btn-sm btn-primary"
                                                                                        style={{color: "white"}}
                                                                                        title={"Click here to see account details"}
                                                                                        to={"/customer/#"+ lead.accountID}>{lead.accountID}</Linkk>

                                                                                )
                                                                            }

                                                                        })()} </h6>
                                                                    </Label>

                                                                    <Row>
                                                                        <Col xl={6} lg={8} md={8} sm={12} xs={12}>
                                                                            <SearchDebtorAcccount
                                                                                handleAccountChange={this.handleDebtorAccountDetails}
                                                                                defaultAccountID={lead.accountID}
                                                                                selectedAccountID={lead.accountID}
                                                                                includeChildren={true}
                                                                                excludeClosedandInactive={false} />
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                                :
                                                                <Row>
                                                                    <Col xl={6} lg={8} md={8} sm={12} xs={12}>
                                                                        {(() => {
                                                                            if (lead.accountID) {
                                                                                return (
                                                                                    <h6> Customer
                                                                                        &nbsp;<Linkk
                                                                                            className="btn btn-sm btn-primary"
                                                                                            style={{color: "white"}}
                                                                                            title={"Click here to see account details"}
                                                                                            to={"/customer/#"+ lead.accountID}
                                                                                            >{lead.accountID}</Linkk>
                                                                                    </h6>
                                                                                )
                                                                            }

                                                                        })()}
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </div>

                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6> Lead's
                                                                            Name </h6>
                                                                        </Label>
                                                                        <div>
                                                                            <Input className="form-control"
                                                                                   onChange={this.handleChange}
                                                                                   type="text"
                                                                                   name="personName"
                                                                                   value={lead.personName}
                                                                                   placeholder="write Lead's name here...">
                                                                            </Input>
                                                                            <FormText
                                                                                color="danger">{formErrors.personName}</FormText>
                                                                        </div>
                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Lead's Name </p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={8} lg={8} md={6} sm={12}
                                                                             xs={12}>
                                                                            <div className="text-left">
                                                                                <h6>{lead.personName}</h6>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6> Email
                                                                            Address </h6>
                                                                        </Label>
                                                                        <div>
                                                                            <Input className="form-control"
                                                                                   onChange={this.handleChange}
                                                                                   type="email"
                                                                                   name="email"
                                                                                   value={lead.email}
                                                                                   placeholder="enter email here..."/>
                                                                            <FormText
                                                                                color="danger">{formErrors.email}</FormText>
                                                                        </div>
                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Email Address </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.email) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <a href={"mailto:" + lead.email}>
                                                                                                <h6><i
                                                                                                    className="text-muted fa fa-envelope-o "
                                                                                                    aria-hidden="true"/> &nbsp;{lead.email}
                                                                                                </h6></a>

                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}
                                                                    </Row>
                                                                }
                                                            </Col>


                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6> Contact
                                                                            Number </h6>
                                                                        </Label>


                                                                        <Input className="form-control"
                                                                               onChange={this.handleChange}
                                                                               type="Number"
                                                                               name="phone"
                                                                               value={lead.phone}
                                                                               placeholder="enter contact number here..."/>
                                                                        <FormText
                                                                            color="danger">{formErrors.phone}</FormText>

                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Contact Number </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.phone) {
                                                                                return (
                                                                                    <Col lg={6} md={6} sm={6}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <a href={"tel:" + lead.phone}>
                                                                                                <h6><i
                                                                                                    className=" text-muted fa fa-phone"
                                                                                                    aria-hidden="true"/> &nbsp;{lead.phone}
                                                                                                </h6></a>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}

                                                                    </Row>
                                                                }

                                                            </Col>


                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>

                                                                {editLeadMode ?

                                                                    <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            Website </h6>
                                                                        </Label>
                                                                        <div>
                                                                            <Input className="form-control"
                                                                                   onChange={this.handleChange}
                                                                                   type="url"
                                                                                   name="website"
                                                                                   value={lead.website}
                                                                                   placeholder="write website here..."/>

                                                                            <FormText
                                                                                color="danger"> {formErrors.website}</FormText>
                                                                        </div>

                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Website </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.website) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <a href={"//" + lead.website}
                                                                                               target="_blank"><h6>
                                                                                                <i
                                                                                                    className=" text-muted fa fa-internet-explorer"
                                                                                                    aria-hidden="true"/> &nbsp;{lead.website}
                                                                                            </h6></a>

                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}

                                                                    </Row>
                                                                }

                                                            </Col>


                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ?
                                                                    <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            Priority </h6>
                                                                        </Label>
                                                                        <div>
                                                                            <Input
                                                                                className="form-control"
                                                                                onChange={this.handleChange}
                                                                                type="select"
                                                                                name="priority"

                                                                                value={lead.priority}
                                                                                placeholder="write state here...">
                                                                                <option value={"LOW"}>Low</option>
                                                                                <option value={"MEDIUM"}>Medium
                                                                                </option>
                                                                                <option value={"HIGH"}>High</option>
                                                                            </Input>

                                                                            <FormText
                                                                                color="danger"> {formErrors.priority}</FormText>
                                                                        </div>
                                                                    </FormGroup> : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12}
                                                                             xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Priority </p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={8} lg={8} md={6} sm={12}
                                                                             xs={12}>
                                                                            <div className="text-left">
                                                                                <h6>{lead.priority}
                                                                                </h6>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                }

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ?
                                                                    <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            Lead's
                                                                            Owner </h6>
                                                                        </Label>


                                                                        <div>

                                                                            <AsyncSelect
                                                                                cacheOptions
                                                                                isLoading={this.state.loading}
                                                                                isClearable={false}
                                                                                defaultOptions={this.state.users}
                                                                                loadOptions={this.loadOptions}
                                                                                onChange={this.ownerChanged}
                                                                                value={this.state.selectedUser}
                                                                            />

                                                                        </div>
                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Lead's Owner </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.ownerUserEmail) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6 id="ownerUserEmail">
                                                                                                <i className="text-muted fa fa-user"
                                                                                                   aria-hidden="true"/> &nbsp;{lead.ownerUserName}
                                                                                            </h6>
                                                                                        </div>
                                                                                        <UncontrolledTooltip
                                                                                            placement="bottom"
                                                                                            target="ownerUserEmail">
                                                                                            <i className="fa fa-envelope-o "
                                                                                               aria-hidden="true"/> &nbsp;{lead.ownerUserEmail}
                                                                                        </UncontrolledTooltip>
                                                                                    </Col>)
                                                                            }
                                                                        })()}

                                                                    </Row>
                                                                }
                                                            </Col>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {(() => {
                                                                    if (lead.id !== 0) {
                                                                        if (editLeadMode) {
                                                                            if (lead.status) {
                                                                                return (<FormGroup>
                                                                                        <Label
                                                                                            className="col-form-label">
                                                                                            <h6> Status </h6>
                                                                                        </Label>
                                                                                        <div>
                                                                                            <h5><Badge color='light'
                                                                                                       pill>
                                                                                                {lead.status}</Badge>
                                                                                            </h5>


                                                                                        </div>
                                                                                    </FormGroup>
                                                                                )
                                                                            }


                                                                            /*return (<FormGroup>
                                                                                <Label className="col-form-label">
                                                                                    <h6>
                                                                                        Action </h6>
                                                                                </Label>

                                                                                <div>
                                                                                    {(() => {
                                                                                        if (lead.status !== "DROP") {
                                                                                            return (
                                                                                                <Button color='danger'
                                                                                                        className="btn"
                                                                                                        onClick={() => this.handleChangeLeadStatus("DROP")}><i
                                                                                                    className="fa fa-thumbs-o-down"
                                                                                                    aria-hidden="true"/>&nbsp;
                                                                                                    Drop</Button>)
                                                                                        }
                                                                                    })()}
                                                                                    &nbsp;&nbsp;{(() => {
                                                                                    if (lead.status !== "CONVERTED") {
                                                                                        return ( <Linkk className="btn btn-success" to={{
                                                                                            pathname: '/crm/convertlead',
                                                                                            state: {lead: lead}
                                                                                        }}><i className="fa fa-thumbs-o-up"
                                                                                              aria-hidden="true"/>&nbsp;Convert</Linkk>)
                                                                                    }
                                                                                })()}
                                                                                </div>

                                                                            </FormGroup> )*/

                                                                        } else {
                                                                            return ( <Row>
                                                                                <Col xl={4} lg={4} md={6} sm={12}
                                                                                     xs={12}>
                                                                                    <div className="text-left">
                                                                                        <p> Status </p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col xl={8} lg={8} md={6} sm={12}
                                                                                     xs={12}>
                                                                                    <div className="text-left">
                                                                                        <h6>{lead.status}
                                                                                        </h6>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>)
                                                                        }
                                                                    }
                                                                })()}
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                            </Collapse>
                                        </Card>

                                        <Card style={{marginBottom: "7px"}} className="shadow-0 border">
                                            <CardHeader style={{padding: "15px"}}>
                                                <Row>
                                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                        <h4 className={"text-left"}><a
                                                            onClick={() => this.toggleCollapse("toggle2")}
                                                            href="javascript:void(0)">
                                                            {this.state.togglers.toggle2 ?
                                                                <i className="icon-arrow-circle-right"/> :
                                                                <i className="icon-arrow-circle-down"/>}
                                                            &nbsp; Company Info</a>
                                                        </h4>
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                        <h4 className={"text-right"}><a href="javascript:void(0)"
                                                                                        onClick={() => this.toggleEditLeadMode("toggle2", false)}>
                                                            <i className={editLeadMode ? "fa fa-pencil-square-o" : "fa fa-pencil-square"}
                                                            />
                                                        </a>
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.togglers.toggle2}>
                                                <CardBody>
                                                    <Form>
                                                        {editLeadMode ?
                                                            <FormGroup>
                                                                <Label className="col-form-label"><h6> Company
                                                                    Name </h6>
                                                                </Label>
                                                                <div>
                                                                    <Input className="form-control"
                                                                           onChange={this.handleChange}
                                                                           type="text"
                                                                           name="company"
                                                                           value={lead.company}
                                                                           placeholder="write company name here..."/>
                                                                    <FormText
                                                                        color="danger"> {formErrors.company}</FormText>
                                                                </div>
                                                            </FormGroup>

                                                            : <Row>
                                                                <Col xl={2} lg={2} md={6} sm={12} xs={12}>
                                                                    <div className="text-left">
                                                                        <p> Company Name </p>
                                                                    </div>
                                                                </Col>
                                                                {(() => {
                                                                    if (lead.company) {
                                                                        return (
                                                                            <Col xl={10} lg={10} md={6} sm={12}
                                                                                 xs={12}>
                                                                                <div className="text-left">
                                                                                    <h6><i
                                                                                        className=" text-muted fa fa-building-o"
                                                                                        aria-hidden="true"/> &nbsp;{lead.company}
                                                                                    </h6>
                                                                                </div>
                                                                            </Col>)
                                                                    }
                                                                })()}
                                                            </Row>
                                                        }


                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>

                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6> Address
                                                                            Line
                                                                            1 </h6>
                                                                        </Label>
                                                                        <div><Input className="form-control"
                                                                                    onChange={this.handleChange}
                                                                                    type="text"
                                                                                    name="address1"
                                                                                    value={lead.address1}
                                                                                    placeholder="write address line 1 here..."/>
                                                                            <FormText
                                                                                color="danger"> {formErrors.address1}</FormText>
                                                                        </div>

                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Address Line 1 </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.address1) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6><i
                                                                                                className=" text-muted fa fa-map-marker"
                                                                                                aria-hidden="true"/> &nbsp;{lead.address1}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}


                                                                    </Row>
                                                                }


                                                            </Col>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6> Address
                                                                            Line
                                                                            2 </h6>
                                                                        </Label>
                                                                        <div><Input className="form-control"
                                                                                    onChange={this.handleChange}
                                                                                    type="text"
                                                                                    name="address2"
                                                                                    value={lead.address2}
                                                                                    placeholder="write address line 2 here..."/>
                                                                            <FormText
                                                                                color="danger"> {formErrors.address2}</FormText>
                                                                        </div>

                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Address Line 2 </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.address2) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6><i
                                                                                                className=" text-muted fa fa-map-marker"
                                                                                                aria-hidden="true"/> &nbsp;{lead.address2}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6> Address
                                                                            Line
                                                                            3 </h6>
                                                                        </Label>
                                                                        <div><Input className="form-control"
                                                                                    onChange={this.handleChange}
                                                                                    type="text"
                                                                                    name="address3"
                                                                                    value={lead.address3}
                                                                                    placeholder="write address line 3 here..."/>
                                                                            <FormText
                                                                                color="danger"> {formErrors.address3}</FormText>
                                                                        </div>

                                                                    </FormGroup>
                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Address Line 3 </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.address3) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6><i
                                                                                                className=" text-muted fa fa-map-marker"
                                                                                                aria-hidden="true"/> &nbsp;{lead.address3}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}
                                                                    </Row>
                                                                }
                                                            </Col>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ?
                                                                    <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            Address
                                                                            Line
                                                                            4 </h6>
                                                                        </Label>
                                                                        <div><Input className="form-control"
                                                                                    onChange={this.handleChange}
                                                                                    type="text"
                                                                                    name="address4"
                                                                                    value={lead.address4}
                                                                                    placeholder="write address line 4 here..."/>
                                                                            <FormText
                                                                                color="danger">&nbsp;{formErrors.address4}
                                                                            </FormText>
                                                                        </div>
                                                                    </FormGroup>

                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> Address Line 4 </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.address4) {
                                                                                return (
                                                                                    <Col lg={6} md={6} sm={6}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6><i
                                                                                                className=" text-muted fa fa-map-marker"
                                                                                                aria-hidden="true"/> &nbsp;{lead.address4}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            City </h6>
                                                                        </Label>
                                                                        <div><Input className="form-control"
                                                                                    onChange={this.handleChange}
                                                                                    type="text"
                                                                                    name="city"
                                                                                    value={lead.city}
                                                                                    placeholder="write city here..."/>
                                                                            <FormText
                                                                                color="danger"> {formErrors.city}</FormText>
                                                                        </div>
                                                                    </FormGroup>


                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> City </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.city) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6><i
                                                                                                className=" text-muted fa fa-map-marker"
                                                                                                aria-hidden="true"/> &nbsp;{lead.city}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}
                                                                    </Row>
                                                                }
                                                            </Col>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            State </h6>
                                                                        </Label>
                                                                        <div><Input className="form-control"
                                                                                    onChange={this.handleChange}
                                                                                    type="text"
                                                                                    name="state"
                                                                                    lg={6} md={6} sm={6} xs={12}
                                                                                    value={lead.state}
                                                                                    placeholder="write state here..."/>
                                                                            <FormText
                                                                                color="danger"> {formErrors.state}</FormText>
                                                                        </div>


                                                                    </FormGroup>

                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> State </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.state) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6><i
                                                                                                className=" text-muted fa fa-map-marker"
                                                                                                aria-hidden="true"/> &nbsp;{lead.state}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}
                                                                    </Row>
                                                                }


                                                            </Col>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ? <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            PostCode </h6>
                                                                        </Label>
                                                                        <div><Input className="form-control"
                                                                                    onChange={this.handleChange}
                                                                                    type="text"
                                                                                    name="postCode"
                                                                                    value={lead.postCode}
                                                                                    placeholder="write postCode here..."/>
                                                                            <FormText
                                                                                color="danger"> {formErrors.postCode}</FormText>
                                                                        </div>
                                                                    </FormGroup>

                                                                    : <Row>
                                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p> PostCode </p>
                                                                            </div>
                                                                        </Col>
                                                                        {(() => {
                                                                            if (lead.postCode) {
                                                                                return (
                                                                                    <Col xl={8} lg={8} md={6}
                                                                                         sm={12}
                                                                                         xs={12}>
                                                                                        <div className="text-left">
                                                                                            <h6><i
                                                                                                className=" text-muted fa fa-map-marker"
                                                                                                aria-hidden="true"/> &nbsp;{lead.postCode}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </Col>)
                                                                            }
                                                                        })()}
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                            </Collapse>

                                        </Card>

                                        <Card style={{marginBottom: "7px"}} className="shadow-0 border">
                                            <CardHeader style={{padding: "15px"}}>
                                                <Row>
                                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                        <h4 className={"text-left"}><a
                                                            onClick={() => this.toggleCollapse("toggle3")}
                                                            href="javascript:void(0)">
                                                            {this.state.togglers.toggle3 ?
                                                                <i className="icon-arrow-circle-right"/> :
                                                                <i className="icon-arrow-circle-down"/>}
                                                            &nbsp; Lead's Objective</a>
                                                        </h4>
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                        <h4 className={"text-right"}><a href="javascript:void(0)"
                                                                                        onClick={() => this.toggleEditLeadMode("toggle3", false)}>
                                                            <i className={editLeadMode ? "fa fa-pencil-square-o" : "fa fa-pencil-square"}
                                                            />
                                                        </a>
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.togglers.toggle3}>
                                                <CardBody>
                                                    <Form>
                                                        <Row>
                                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                                {editLeadMode ?

                                                                    <FormGroup>
                                                                        <Label className="col-form-label"><h6>
                                                                            Amount </h6>
                                                                        </Label>

                                                                        <div>
                                                                            <Input className="form-control"
                                                                                   onChange={this.handleChange}
                                                                                   type="Number"
                                                                                   name="amount"
                                                                                   value={lead.amount}
                                                                                   placeholder="write amount here..."/>

                                                                            <FormText
                                                                                color="danger"> {formErrors.amount}</FormText>
                                                                        </div>


                                                                    </FormGroup>
                                                                    :
                                                                    <Row>
                                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                                            <div className="text-left">
                                                                                <p className="lead">
                                                                                    Amount &nbsp;&nbsp;
                                                                                    <strong>{lead.amount}</strong>
                                                                                </p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </Col>
                                                            <Col lg={6} md={6} sm={6} xs={12}>

                                                            </Col>
                                                        </Row>

                                                        {editLeadMode ?
                                                            <FormGroup>
                                                                <Label className="col-form-label"><p> Query </p>
                                                                </Label>

                                                                <div>

                                                                    <Input className="form-control"
                                                                           onChange={this.handleChange}
                                                                           type="textarea"
                                                                           name="note"
                                                                           value={lead.note}
                                                                           placeholder="write query here..."/>
                                                                    <FormText
                                                                        color="danger"> {formErrors.note}</FormText>
                                                                </div>
                                                            </FormGroup>

                                                            : <Row className="mt-2">
                                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                                    <div className="text-left">
                                                                        <p className="lead"> Query </p>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                                    <div className="text-left">
                                                                        <p>{lead.note}</p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }

                                                        {editLeadMode ?
                                                            <FormGroup>
                                                                <Label className="col-form-label"><h6> Comment </h6>
                                                                </Label>
                                                                <div>
                                                                    <Input className="form-control"
                                                                           onChange={this.handleChange}
                                                                           type="textarea"
                                                                           name="comment"
                                                                           value={lead.comment}
                                                                           placeholder="write comment here..."/>

                                                                    <FormText
                                                                        color="danger"> {formErrors.amount}</FormText>
                                                                </div>
                                                            </FormGroup>

                                                            :
                                                            <Row className="mt-2">
                                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                                    <div className="text-left">
                                                                        <p className="lead">Comment</p>
                                                                    </div>
                                                                </Col>

                                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                                    <div className="text-left">
                                                                        <p><em>{lead.comment}</em></p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Form>

                                                </CardBody>
                                            </Collapse>

                                        </Card>

                                        <div>
                                            {(() => {
                                                if (lead.id !== 0) {
                                                    return (
                                                        <Card style={{marginBottom: "7px"}} className="shadow-0 border">
                                                            <CardHeader style={{padding: "15px"}}>
                                                                <h4 className={"text-left"}><a
                                                                    onClick={() => this.toggleCollapse("toggle4")}
                                                                    href="javascript:void(0)">
                                                                    {this.state.togglers.toggle4 ?
                                                                        <i className="icon-arrow-circle-right"/> :
                                                                        <i className="icon-arrow-circle-down"/>}
                                                                    &nbsp; Remarks</a>
                                                                </h4>
                                                            </CardHeader>
                                                            <Collapse isOpen={this.state.togglers.toggle4}>
                                                                <CardBody>
                                                                    <LeadRemark lead={lead} activity={undefined}/>
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>)
                                                }
                                            })()}
                                        </div>

                                        {editLeadMode ? <div className={"text-right mb-4"}>
                                                <Button color='primary'
                                                        onClick={this.handleSubmit}>{lead.id === 0 ? "Save Lead" : "Update"}</Button>
                                                &nbsp;
                                                <Button color='secondary' onClick={this.handleCancel}>Cancel</Button>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })()}
                </div>
                <ToastContainer/>
            </div>
        );
    }
}

