import React, {Component} from 'react';
import RoleService from "../../services/RoleService";
import classnames from 'classnames';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    ListGroup,
    NavLink,
    Row,
    Spinner
} from "reactstrap";

import {findIndex, handleErrorMessage} from '../../services/CommonService';
import { Link } from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cloneDeep from 'lodash/cloneDeep';

export default class RolePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            privileges: [],
            loading: {
                getRoleList: false,
                saveRole: false
            },
            isLoading: false
        };
        this.roleService = new RoleService();
        this.refresh = this.refresh.bind(this);
        this.getRoleList = this.getRoleList.bind(this);
        this.getAllPrivilege = this.getAllPrivilege.bind(this);
        this.collapseRole = this.collapseRole.bind(this);
        this.showMorePrivilege = this.showMorePrivilege.bind(this);
        this.selectPrivilege = this.selectPrivilege.bind(this);
        this.getNewRole = this.getNewRole.bind(this);
        this.saveRole = this.saveRole.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.getAllPrivilege();
    }

    getNewRole() {
        let {roles, privileges} = this.state;

        let index = findIndex(roles, 'roleID', 0);
        if (index === -1) {
            let role = {
                name: "",
                description: "",
                dashboards: "",
                privileges: [],
                roleID: 0,
                collapse: true,
                showMorePrivilege: false,
                processedPrivileges: []
            };

            for (let j in privileges) {
                privileges[j].selected = false;
                role.processedPrivileges.push(cloneDeep(privileges[j]));
            }
            roles.splice(0, 0, role);
            this.setState({roles});
        }

    }

    getAllPrivilege() {
        this.setState({isLoading: true});
        this.roleService.fetchAllPrivilegeList().then(response => {
            this.setState({privileges: response.data}, () => {
                this.getRoleList();
            });
        }).catch(error => {
            console.log(error);
        })
    }


    getRoleList() {
        let {loading, privileges} = this.state;
        loading.getRoleList = true;
        this.setState({loading});


        let includeFlag = false;
        let tempPrivilege = {};
        this.roleService.fetchRoleList()
            .then(response => {
                let roles = response.data;
                for (let i in roles) {
                    roles[i].collapse = false;
                    roles[i].showMorePrivilege = true;
                    roles[i].processedPrivileges = [];
                    for (let j in privileges) {
                        includeFlag = false;
                        if (roles[i].privileges) {
                            includeFlag = roles[i].privileges.includes(privileges[j].name);
                        }
                        privileges[j].selected = includeFlag;
                        roles[i].processedPrivileges.push(cloneDeep(privileges[j]));
                    }

                    roles[i].processedPrivileges.sort(function (x, y) {
                        return (x.selected === y.selected) ? 0 : x.selected ? -1 : 1;
                    })
                }
                loading.getRoleList = false;
                this.setState({roles: roles, loading, isLoading: false});
            }).catch(error => {
            loading.getRoleList = false;
            this.setState({loading, isLoading: false});
            alert(handleErrorMessage(error));
        });
    }

    collapseRole(selectedRole) {
        let roles = this.state.roles;
        let index = findIndex(roles, 'roleID', selectedRole.roleID);
        if (index > -1) {
            roles[index].collapse = !(roles[index].collapse);
            if (selectedRole.roleID === 0) {
                roles.splice(index, 1);
            }
            this.setState({roles});
        }

    }

    showMorePrivilege(selectedRole) {
        let roles = this.state.roles;
        let index = findIndex(roles, 'roleID', selectedRole.roleID);
        if (index > -1) {
            roles[index].showMorePrivilege = !(roles[index].showMorePrivilege);
        }
        this.setState({roles});
    }


    selectPrivilege(selectedRole, selectedPrivilege) {
        let roles = this.state.roles;
        let roleIndex = findIndex(roles, 'roleID', selectedRole.roleID);

        if (roleIndex > -1) {
            let privilegeIndex = findIndex(roles[roleIndex].processedPrivileges, 'id', selectedPrivilege.id);
            if (privilegeIndex > -1) {
                roles[roleIndex].processedPrivileges[privilegeIndex].selected = !(roles[roleIndex].processedPrivileges[privilegeIndex].selected);

                roles[roleIndex].processedPrivileges.sort(function (x, y) {
                    let result = (x.selected === y.selected) ? 0 : x.selected ? -1 : 1;
                    if (result === 0) {
                        result = ('' + x.name).localeCompare(y.name);
                    }
                    return result;
                });
                this.setState({roles});
            }
        }
    }


    handleRoleChange(e, selectedRole) {
        let roles = this.state.roles;
        const {name, value} = e.target;

        let index = findIndex(roles, 'roleID', selectedRole.roleID);
        if (index > -1) {
            roles[index][name] = value;
        }
        this.setState({roles});
    }

    saveRole(role) {
        let {loading} = this.state;


        let selectedPrivilegeIds = [];
        if (role && role.processedPrivileges) {
            for (let i in role.processedPrivileges) {
                if (role.processedPrivileges[i].selected) {
                    selectedPrivilegeIds.push(role.processedPrivileges[i].id);
                }
            }
        }

        let data = {
            name: role.name,
            description: role.description,
            dashboards: role.dashboards,
            privilegeIds: selectedPrivilegeIds
        };


        loading.saveRole = true;
        this.setState({loading});
        this.roleService.saveRole(data).then(response => {
            toast.success("Role saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.getRoleList();
            loading.saveRole = false;
            this.setState({loading});
        }).catch(error => {
            console.log(error);
            loading.saveRole = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }


    renderRole(role, loading) {
        return <Card>
            <CardHeader>
                <NavLink href="#" onClick={() => this.collapseRole(role)}>
                    <h6>
                        <i className={classnames("fa", {
                                "fa-pencil": !role.collapse,
                                "fa-pencil-square-o": role.collapse,
                            }
                        )}/> &nbsp;
                        {role.name}
                        <small className={"text-muted"}>&nbsp;|&nbsp;{role.description}</small>
                    </h6>
                </NavLink>
            </CardHeader>

            <Collapse isOpen={role.collapse}>
                <CardBody>
                    <div>
                        <Row>
                            <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="name">Name*</Label>
                                    <Input value={role.name}
                                           size="lg"
                                           onChange={(e) => this.handleRoleChange(e, role)}
                                           type="text" name="name" title="name"
                                           placeholder="Role's name"/>
                                </FormGroup>
                            </Col>
                            <Col xl={5} lg={6} md={6} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="name">Description</Label>
                                    <Input value={role.description}
                                           size="lg"
                                           onChange={(e) => this.handleRoleChange(e, role)}
                                           type="text" name="description" title="description"
                                           id="description"
                                           placeholder="Describe this role"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="mb-2">
                            <Label for="name">Dashboard Info Cards</Label>
                            <Input value={role.dashboards}
                                   onChange={(e) => this.handleRoleChange(e, role)}
                                   type="textarea" name="dashboards" title="dashboards"
                                   id="dashboards"
                                   placeholder="Dashboard's cards"/>
                        </div>
                        {this.renderRolePrivileges(role, loading)}
                    </div>
                </CardBody>
            </Collapse>
        </Card>;
    }

    searchFunction(item, role) {
        let flag = true;

        if (role.searchText) {
            flag = item.name.includes(role.searchText.toLowerCase())
        }

        if (flag && role.showMorePrivilege) {
            flag = item.selected;
        }

        return flag;
    }


    renderRolePrivileges(role, loading) {
        let privileges = role.processedPrivileges.filter((item) => this.searchFunction(item, role));

        return <div>
            <Row>
                <Col xl={9} lg={8} md={8} sm={6} xs={6}>
                    <p className={"lead"}>Privileges</p>
                </Col>
                <Col xl={3} lg={4} md={4} sm={6} xs={6}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fa fa-search"
                                   aria-hidden="true"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={(e) => this.handleRoleChange(e, role)} type="search" name="searchText"
                               id="searchText" placeholder="Search..."/>
                    </InputGroup>
                </Col>
            </Row>

            {
                (privileges || []).map((p, index) =>

                    <Button color="primary" size={"sm"} className={"m-1"}
                            outline={!p.selected}
                            key={index}
                            onClick={() => this.selectPrivilege(role, p)}>
                        <i className={classnames("fa", {
                                "fa-check-square-o": p.selected,
                                "fa-square-o": !p.selected,
                            }
                        )}/>&nbsp;{p.name} </Button>
                )
            }

            <Button color="secondary" size={"sm"} className={"m-1"}
                    hidden={(role.showMorePrivilege) && (privileges.length === role.processedPrivileges.length)}
                    outline={role.showMorePrivilege}
                    onClick={() => this.showMorePrivilege(role)}>
                {role.showMorePrivilege ? "Show more..." : "Show less..."}
            </Button>
            <div className={"text-right mt-2 mb-4"}>
                <Button color="success" className={"mr-1"}
                        onClick={() => this.saveRole(role)}>
                    {loading.saveRole ? <i className="fa fa-circle-o-notch fa-spin fa-fw"/> :
                        <i className="fa fa-floppy-o"/>}
                    &nbsp;
                    {loading.saveRole ? "Saving..." : "Save"}


                </Button>
                <Button color="secondary" className={"ml-1"}
                        onClick={() => this.collapseRole(role)}>
                    <i className={"fa fa-times"}/> &nbsp; Cancel
                </Button>
            </div>
        </div>
            ;
    }


    render() {
        let {roles, loading, isLoading} = this.state;

        let newRoleIndex = findIndex(roles, 'roleID', 0);


        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">roles</BreadcrumbItem>
                </Breadcrumb>

                {newRoleIndex === -1 ?
                    <Button onClick={this.getNewRole} color={"primary"} className={"mb-2"}><i
                        className={"fa fa-plus"}/>&nbsp; New </Button> : null}

                <Link color='primary' style={{ textDecoration: "none" }}
                    className="btn mb-2 ml-2 btn-primary"
                    to={{ pathname: '/user/role/list' }}>
                Try new UI for this page</Link>

                {isLoading ? <div><Spinner size={"lg"} color={"primary"}/></div> :
                    <ListGroup>
                        {(roles || []).map((role, index) =>
                            this.renderRole(role, loading)
                        )}
                    </ListGroup>
                }
                <ToastContainer/>
            </div>
        );
    }

}
