import axios from './axios';
class NotesService{
    static Instance() {
        return new NotesService();
    }
    getAllNotes(accountId) {
        return axios.get('api/customer/CustomerNotesDetails?accountId='+accountId);
    }

}
export default NotesService.Instance();