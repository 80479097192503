import React, { Component } from 'react';
import { 
    Button,
    Card,
    Nav,
    CardBody,
    CardHeader,
    NavItem,
    NavLink,
    Spinner,
    Table,
    TabContent,
    TabPane
} from "reactstrap"; 
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';
import productionLineService from "../../services/production/ProductionLineService";
import ProductionLineModal from "../../components/modal/ProductionLineModal";

export default class ProductionLinePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            production: [],
            loading: false,
            isOpenModal: false,
            selectedProductionLine: {}
        };

        this.refreshAgain = this.refreshAgain.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({ loading: true });
        productionLineService.getListProductionLines().then(response => {
            this.setState({ production: response.data, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
        });
    }


    openModal(selectedProductionLine) {
        this.setState({ 
            isOpenModal: true, 
            selectedProductionLine: selectedProductionLine
        }); 
    }
     
    toggleModal(modaldata) { 
        this.setState({ isOpenModal: modaldata }); 
    }

    refreshAgain() {
        this.setState({ isOpenModal: false });
        this.refresh();
    }

    render() {
        let { production, loading, selectedProductionLine, isOpenModal } = this.state;
        return (
            <div>  
                        {loading
                            ? <Spinner color="primary" />
                            : <Table responsive={true} striped bordered size={"sm"}>
                                <thead>
                                    <tr>
                                        <th>S.No</th> 
                                        <th>Name</th>
                                        <th>Short-name</th> 
                                        <th>Code</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(production || []).map((item, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td> 
                                            <td>{item.name}</td>
                                            <td>{item.shortName}</td>
                                            <td>{item.code}</td>
                                            <td className="text-center">
                                                <Button size={"sm"} color={"primary"}
                                                onClick={() => this.openModal(item)}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                                Edit</Button></td>
                                        </tr>
                                    })}
                                </tbody>
                    </Table>}
                {this.state.isOpenModal ?
                    <ProductionLineModal
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        refreshAgain={this.refreshAgain}
                        selectedproductionLine={selectedProductionLine}
                        productionLine={production}
                    /> : null
                }
            </div>
            )
    }
}