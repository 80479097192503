import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Row, Col, Label, FormGroup, Input, Spinner, FormText,
} from 'reactstrap';
import { toast, ToastContainer } from "react-toastify";
import classnames from 'classnames';


export default class CustomSaveTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingSubmit: this.props.isSavingTemplate,
            templateData: this.props.templateData
        };
    }


    render() {
        let { isOpen, toggle, formError } = this.props;
        let { isLoadingSubmit, templateData } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Save Template
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Name*</Label>
                                <Input type={"text"}
                                    name={"templateName"}
                                    value={templateData.templateName}
                                    onChange={(e) => this.props.handleChange(e.target.value, "templateName")} />
                                <FormText color="danger">{formError.templateName}</FormText>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Description</Label>
                                <Input type={"text"}
                                    name={"description"}
                                    value={templateData.description}
                                    onChange={(e) => this.props.handleChange(e.target.value, "description")} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <div className="text-right">
                            <Button color={"primary"} size='sm' className={"mr-2"}
                                disabled={isLoadingSubmit}
                                onClick={this.props.saveTemplate}>
                                {isLoadingSubmit
                                    ? <Spinner className={"mr-2"} size={"sm"} />
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>
                                }
                                {isLoadingSubmit ? "Saving" : "Save"}
                            </Button>
                            <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}>
                                <i className="fa fa-times mr-2" />
                                Cancel
                            </Button>
                        </div>
                    </Row>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}