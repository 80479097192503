import React, { Component } from 'react';
import {
    Breadcrumb, BreadcrumbItem, Row, Col, Input,Badge,
    Button, Table, Card, CardBody, Spinner, CardHeader, InputGroup, InputGroupAddon, InputGroupText, Progress
} from 'reactstrap';
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from 'react-toastify';
import { Link as Link } from "react-router-dom";
import FabricService from "../../services/FabricService";
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';
import DeleteFabricModal from '../../components/modal/DeleteFabricModal';
import FileSaver from 'file-saver';
import AttachmentService from '../../services/AttachmentService';
import { findIndex, handleErrorMessage } from '../../services/CommonService';
const fabricTypes = [
    {
        label: "All", value: "ALL"
    },
    {
        label: "Outdoor", value: "OUTDOOR"
    },
    {
        label: "Indoor", value: "INDOOR"
    }
];
export default class Fabric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fabricData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        fabricName: '',
                        category: '',
                        fabricType: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            search: {
                primaryText: "",
                category: "",
                subCategory: ""
            },
            fileUpload: {
                fabricId: 0,
                Color: ""
            },
            loading: false,
            showAlert: false,
            disableFabric: null,
            downloading: false
        };

        this.attachmentService = new AttachmentService();
        this.refresh = this.refresh.bind(this);
        this.getFabrics = this.getFabrics.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.printFabricSummaryInExcel = this.printFabricSummaryInExcel.bind(this);
    }

    componentDidMount() {
        let searchParams = queryString.parse(window.location.search);
        let { fabricData } = this.state;
        fabricData.request.filterRequest = { ...fabricData.request.filterRequest, ...searchParams };
        fabricData.request.pageRequest = { ...fabricData.request.pageRequest, ...searchParams };
        fabricData.request.sortRequest = { ...fabricData.request.sortRequest, ...searchParams };
        this.setState({ fabricData }, () => this.refresh());
    }

    refresh() {
        this.getFabrics(this.state.fabricData);
    }

    getFabrics(fabricData) {
        let request = cloneDeep(fabricData.request);
        this.setState({ loading: true });
        FabricService.GetFabrics(request).then(response => {
            if (response.data) {
                fabricData.response = response.data;
                this.setState({ fabricData, loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }




    toggleDeleteModal(change) {
        this.setState({ isOpenDeleteModal: change });
    }

    handleCloseModal(data) {
        this.setState({ isOpenDeleteModal: data, disableuser: null });
    }

    showAlert(item) {
        this.setState({ isOpenDeleteModal: true, disableFabric: item })
    }

    handleChange(change, key) {
        this.props.setSearchParam(key, change);
        let { fabricData } = this.state;
        switch (key) {

            case "sortKey":
                if (fabricData.request.sortRequest.key === change) {
                    fabricData.request.sortRequest.direction = !fabricData.request.sortRequest.direction;
                    this.props.setSearchParam("direction", fabricData.request.sortRequest.direction);
                } else {
                    fabricData.request.sortRequest.key = change;
                    fabricData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getFabrics(fabricData);
                }
                break;
            case "pageSize":
                fabricData.request.pageRequest[key] = change;
                fabricData.request.pageRequest.currentPage = 1;
                this.getFabrics(fabricData);
                break;
            case "currentPage":
                fabricData.request.pageRequest[key] = change;
                this.getFabrics(fabricData);
                break;


            default:
                fabricData.request.filterRequest[key] = change;
                fabricData.request.pageRequest.currentPage = 1;
                this.setState({ fabricData });
                this.getFabrics(fabricData);
        }

    }

    getFabricsHeading({ filterRequest }) {
        let fabric = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "fabricName",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 225,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="fabricName" name="fabricName"
                    value={filterRequest.fabricName}
                    onChange={(e) => this.handleChange(e.target.value, "fabricName")}
                    placeholder="Search..." /></div>

            }, {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 225,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"

            },
            {
                key: "fabricType",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"select"}
                        name={"fabricType"}
                        value={filterRequest.fabricType}
                        onChange={(e) => this.handleChange(e.target.value, "fabricType")}>
                        {fabricTypes.map((option, index) =>
                            <option key={index} value={option.value}>{option.label}</option>
                        )}
                    </Input>
                </div>

            },

            {
                key: "category",
                label: "Category",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"category"}
                        value={filterRequest.category}
                        onChange={(e) => this.handleChange(e.target.value, "category")} />
                </div>
            },
            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "table-success text-center",
                labelClassName: "align-middle text-center"
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center",

            }];

        return fabric;
    }

    onDownloadFabricBrochureAttachment(item) {
        let { fabricData } = this.state;
        let { records } = fabricData.response;
        let index = findIndex(records, 'id', item.id);
        records[index].isDownloading = true;
        this.setState({ fabricData });
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    records[index].requestProgressPercentage = percent;
                    this.setState({ fabricData });
                }
            }
        };
        records[index].requestProgressPercentage = 0;
        this.setState({ fabricData });
        this.attachmentService.DownloadAttachment(item.fabricBrochureAttachment.id, options).then(response => {
            FileSaver.saveAs(response.data, item.fabricBrochureAttachment.description);
            records[index].requestProgressPercentage = 0;
            records[index].isDownloading = false;
            this.setState({ fabricData });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            records[index].requestProgressPercentage = 0;
            records[index].isDownloading = false;
            this.setState({ fabricData });
        });
    }

    printFabricSummaryInExcel() {
        this.setState({ downloading: true });
        let request = cloneDeep(this.state.fabricData.request);
        FabricService.generateFabricSummaryInExcel(request.filterRequest).then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "FABRICS-SUMMARY" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            console.log(error.data)
        });
    }


    render() {
        let { fabricData, loading,  downloading } = this.state;
        let { pageRequest, sortRequest, filterRequest } = fabricData.request;
        let fabricHeading = this.getFabricsHeading(fabricData.request);
        return (
            <div>
                <div>
                    <div className="d-flex align-items-center justify-content-between">
                        <Col xl={4} lg={4} md={6} sm={12} xs={12} className="px-0">
                            <div className={"text-left"}>
                                {loading ? <p>Loading...</p> :
                                    <p>Showing
                                        {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                        {' '}of {fabricData.response.totalRecords}
                                        {' '}entries</p>
                                }
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className="text-right">
                                <Link color='primary' style={{ textDecoration: "none" }}
                                      className="btn btn-sm ml-1 btn btn-primary" id={"newfabric"}
                                      to={{
                                          pathname: '/tools/manage/fabrics/one', state: { FabricId: 0 }
                                      }}><i className="fa fa-plus-circle mr-2"
                                            aria-hidden="true" />&nbsp;New</Link>
                                {
                                    fabricData.response.records.length !== 0
                                        ? <Button className={'ml-1'}
                                                  outline={true}
                                                  size={"sm"}
                                                  color={"success"}
                                                  onClick={this.printFabricSummaryInExcel}
                                                  disabled={downloading}>
                                            {
                                                downloading
                                                    ? <Spinner size="sm"
                                                               className={"mr-2"}
                                                               color={"success"} />
                                                    :
                                                    <i className="fa fa-file-excel-o mr-2"
                                                       aria-hidden="true" />
                                            }
                                            {
                                                downloading
                                                    ? "Exporting"
                                                    : "Export"
                                            }
                                        </Button>
                                        : null
                                }
                            </div>
                        </Col>


                    </div>
                    <div style={{ minHeight: 250 }}>
                        <Table striped bordered responsive hover size="sm">
                            <thead>
                            <tr>
                                {(fabricHeading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true" /> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(fabricHeading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>

                            </thead>

                            <tbody>
                            {(fabricData.response.records || []).map((item, index) =>
                                <tr key={index}>
                                    <td key={index}>
                                        {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                    </td>
                                    <td>{item.fabricName}</td>
                                    <td><div dangerouslySetInnerHTML={{ __html: item.description }} /></td>
                                    <td>{item.fabricType}</td>
                                    <td>{item.category}</td>
                                    <td className="text-center">{item.soldOut ? <Badge color="warning">Sold Out</Badge> : (item.isDiscontinued ? <Badge color="danger">Discontinued</Badge> : <Badge color="success">Active</Badge>)}</td>

                                    {/*<td>{item.fabricBrochureAttachment != null ?
                                                <div className="text-center">
                                                    <Button color='primary' size="sm" outline={true} title="Click here to download fabric brochure" onClick={this.onDownloadFabricBrochureAttachment.bind(this, item)} disabled={item.isDownloading}>
                                                        {item.isDownloading
                                                            ? <Spinner size="sm"
                                                                className={"mr-2"}
                                                                color={"primary"} />
                                                            : <i className="fa fa-download mr-2" />
                                                        } Download </Button></div> : null}
                                                {item.requestProgressPercentage > 0
                                                    ? <Progress striped color="primary" value={item.requestProgressPercentage}
                                                        className={"mt-2"} />
                                                    : null
                                                }
                                            </td>*/}
                                    <td className="text-center" colSpan={1}>
                                        <div className="text-center">
                                            <Link color='primary' className="btn btn-sm ml-1 btn btn-primary"
                                                  id={`btn-${index}`}
                                                  to={{ pathname: "/tools/manage/fabrics/one", search: queryString.stringify({ fabricId: item.id }), state: filterRequest }}>
                                                <i className="fa fa-pencil mr-2"
                                                   aria-hidden="true" />Edit</Link>
                                            &nbsp;
                                            <Button color='danger' size="sm" id={`btn-${index + 7}`}
                                                    onClick={this.showAlert.bind(this, item)}>
                                                <i className="fa fa-trash" aria-hidden="true" /></Button>

                                        </div>
                                    </td>

                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={fabricData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
                <DeleteFabricModal isOpen={this.state.isOpenDeleteModal} handleRefresh={this.refresh}
                    toggle={this.toggleDeleteModal} disableFabric={this.state.disableFabric}
                    handlerCloseModal={this.handleCloseModal}
                />
                <ToastContainer />

            </div>
        );
    }
}