import React, {Component} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
    UncontrolledDropdown
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {isEmpty} from 'lodash';
import OrderService from '../../services/OrderService';
import {handleErrorMessage} from "../../services/CommonService";
import classnames from 'classnames';
import {
    isAllRowsExpanded,
    salesOrderEnquiryPageOrderItemStore
} from "../../store/sales/order/item/SalesOrderEnquiryPageOrderItemStore";
import SalesOrderCard from "../sales/orders/SalesOrderCard";
import SearchDebtorAcccount from "../search/SearchDebtorAcccount";
import UserService from "../../services/UserService";

let visibleColumnsInOrderItems = ["isSelected", "expandIcon", "prodCode", "description", "width", "drop", "qty", "price", "qtyprice", "discVal", "orderedExtendedEx"];

export default class SalesOrderCopyModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: {},
            sortRequest: {
                key: "",
                direction: false
            },
            newOrdNum: null,
            newOrder: {},
            targetAccountID: null,
            isLoadingCopyOrder: false,
            currentUser: {},
            toggler: {
                toggler1: false,
                toggler2: false,
                toggler3: false,
            }
        };

        this.orderService = new OrderService();
        this.userService = new UserService();

        this.getDebtorInvoice = this.getDebtorInvoice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleExpandRow = this.toggleExpandRow.bind(this);
        this.toggleAllRowExpanded = this.toggleAllRowExpanded.bind(this);
        this.toggleSelectedRow = this.toggleSelectedRow.bind(this);
        this.toggleAllRowSelected = this.toggleAllRowSelected.bind(this);
        this.copyOrder = this.copyOrder.bind(this);
    }


    componentDidMount() {

        let {toggler} = this.state;
        switch (this.props.purpose) {
            case "Copy":
                break;
            case "Rework":
                toggler = {
                    toggler1: false,
                    toggler2: false,
                    toggler3: true,
                };
                this.setState({toggler});
                break;
        }
        this.getDebtorInvoice(this.props.ordNum);
        let currentUser = this.userService.getLoggedInUser();
        this.setState({currentUser});


    }

    toggleExpandRow(orderItemIndex, change) {
        let {order} = this.state;
        if (order.items[orderItemIndex].isExpandable) {
            order.items[orderItemIndex].isExpanded = change;
            this.setState({order});
        }
    }

    toggleAllRowExpanded() {
        let {order} = this.state;
        let change = isAllRowsExpanded(order.items);
        order.items.forEach((item, itemIndex) => {
            if (order.items[itemIndex].isExpandable) {
                order.items[itemIndex].isExpanded = !change;
            }
        });
        this.setState({order});
    }

    toggleSelectedRow(orderItemIndex, change) {
        let {order} = this.state;

        if (order.items[orderItemIndex].itemNum === order.items[orderItemIndex].parentItemNum) {
            order.items[orderItemIndex].isSelected = change;
            order.items.forEach(item => {
                if (
                    (order.items[orderItemIndex].itemNum === item.parentItemNum)
                    || (order.items[orderItemIndex].salesOrderItemPart && item.salesOrderItemPart && order.items[orderItemIndex].salesOrderItemPart.salesOrderItemID === item.salesOrderItemPart.salesOrderItemID)) {
                    item.isSelected = change;
                }
            });
        }
        this.setState({order});
    }

    toggleAllRowSelected() {
        let {order} = this.state;
        let change = (order.items.every(row => row.isSelected));
        order.items.forEach((item, itemIndex) => {
            order.items[itemIndex].isSelected = !change;
        });
        this.setState({order});
    }

    getDebtorInvoice(ordNum) {
        this.setState({isLoadingOrder: true});
        this.orderService.getOrder(ordNum, true, null, false, "current").then(response => {
            let order = response.data;
            if (order) {
                (order.items || []).forEach(orderItem => {
                    orderItem.isExpandable = (orderItem.itemNum === orderItem.parentItemNum && (order.items || []).some(oi => (oi.parentItemNum === orderItem.itemNum && oi.itemNum !== oi.parentItemNum)));
                    orderItem.isSelected = false;
                });
                this.setState({order: order, isLoadingOrder: false});
            } else {
                this.setState({isLoadingOrder: false});
            }
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingOrder: false});
            toast.error(handleErrorMessage(error));
        });
    }

    copyOrder(action) {
        let {order, newOrdNum, newOrder, targetAccountID} = this.state;

        if (newOrdNum && newOrder && (newOrder.salesOrderJobStatusId > 45)) { //Should not be greater than Awaiting Production
            toast.info("Selected target order which you are trying to copying in is not eligible as it has status above then Awaiting Production, and it cannot be modified while copying into it.");
            return;
        }
        let request = {
            originalOrdNum: order.ordNum,
            newOrdNum: newOrdNum,
            targetAccountID: targetAccountID,
            parentItemNums: (order.items || []).filter(oi => (oi.itemNum === oi.parentItemNum && oi.isSelected)).map(oi => oi.itemNum),
            purpose: this.props.purpose,
            reworkID: this.props.reworkID
        };
        this.setState({isLoadingCopyOrder: true});
        this.orderService.copyOrder(request).then(response => {
            let ordNum = response.data;
            this.setState({isLoadingCopyOrder: false});

            switch (action) {
                case "Copy":
                    toast.success("Order copied!" + ordNum);
                    this.props.history.push("/sales/order/enquiry?OrdNum=" + ordNum);
                    this.props.history.go(0);
                    break;
                case "Copy and open in product builder":
                    toast.success("Order copied!" + ordNum);
                    this.props.history.push("/sales/order/create/product-builder/v1?orderNum=" + ordNum);
                    break;
                case "Rework":
                    this.props.handleSubmit(ordNum);
                    break;
            }

        }).catch(error => {
            console.error(error);
            this.setState({isLoadingCopyOrder: false});
            toast.error(handleErrorMessage(error));
        });
    }

    handleChange(key, change) {
        let {order, sortRequest, toggler, targetAccountID} = this.state;
        let itemIndex, isOpen;
        switch (key) {
            case "toggleItem":
                ({itemIndex, isOpen} = change);
                order.items[itemIndex].isOpen = isOpen;
                this.setState({order});
                break;
            case "newOrdNum":
                if (change !== null) {
                    targetAccountID = null;
                }
                this.setState({newOrdNum: change, targetAccountID});
                break;
            case "newOrder":
                this.setState({newOrder: change});
                break;
            case "targetAccountID":
                this.setState({targetAccountID: change});
                break;
            case "toggler1":
            case "toggler2":
            case "toggler3":
                toggler[key] = change;
                this.setState({toggler});
                break;
            case "sortRequest":
                if (change.sorterApplicable) {
                    if (sortRequest.key === change.key) {
                        sortRequest.direction = !sortRequest.direction;
                    } else {
                        sortRequest.key = change.key;
                        sortRequest.direction = false;
                    }
                    this.setState({sortRequest});
                }
        }

    }

    render() {
        let {isOpen, toggle, heading, purpose, enforceSubmit} = this.props;
        let {order, isLoadingOrder, sortRequest, newOrdNum, targetAccountID, isLoadingCopyOrder, newOrder, toggler} = this.state;
        let store = salesOrderEnquiryPageOrderItemStore.filter(x => visibleColumnsInOrderItems.includes(x.key));

        return (
            <Modal isOpen={isOpen}
                   size="xl"
                   scrollable={false}
                   toggle={() => toggle(enforceSubmit ? isOpen : !isOpen)}
                   backdrop={enforceSubmit ? "static" : true} keyboard={!enforceSubmit}>
                <ModalHeader toggle={() => toggle(enforceSubmit ? isOpen : !isOpen)}>
                    {heading}
                </ModalHeader>
                <ModalBody>
                    {isLoadingOrder
                        ? <Spinner color={"primary"}/>
                        : <div>
                            {
                                purpose !== "Rework"
                                    ? <Card>
                                        <CardHeader id="toggler1" className={"hoverableItem"}>
                                            <Button color={"link"} size={"sm"}
                                                    onClick={() => this.handleChange("toggler1", !toggler.toggler1)}>Target
                                                Order</Button>
                                        </CardHeader>
                                        <Collapse isOpen={toggler.toggler1}>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup>
                                                            <Button size={"sm"}
                                                                    color={newOrdNum === null ? "success" : "primary"}
                                                                    outline={newOrdNum !== null}
                                                                    onClick={() => this.handleChange("newOrdNum", null)}>
                                                                <i className={classnames("mr-2", "fa", "fa-fw", "fa-lg", {
                                                                    "fa fa-check": newOrdNum === null
                                                                })}/>
                                                                Create New
                                                            </Button>
                                                            <Button size={"sm"}
                                                                    color={newOrdNum !== null ? "success" : "primary"}
                                                                    outline={newOrdNum === null}
                                                                    onClick={() => this.handleChange("newOrdNum", "")}>
                                                                <i className={classnames("mr-2", "fa", "fa-fw", "fa-lg", {
                                                                    "fa fa-check": newOrdNum !== null
                                                                })}/>
                                                                Nominate Order
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            {
                                                                newOrdNum !== null
                                                                    ? <div>
                                                                        Existing OrdNum
                                                                        <Input type={"number"}
                                                                               placeholder={"Existing order no"}
                                                                               name={"newOrdNum"}
                                                                               value={newOrdNum}
                                                                               onChange={(e) => this.handleChange("newOrdNum", e.target.value)}/>
                                                                        <div className={"mt-2"}>
                                                                            <SalesOrderCard ordNum={newOrdNum}
                                                                                            handleChange={(change) => this.handleChange("newOrder", change)}/>
                                                                        </div>

                                                                        {
                                                                            (newOrdNum && newOrder && (newOrder.salesOrderJobStatusId > 45))
                                                                                ? <Alert color={"danger"} className={"mt-1"}>
                                                                                    <h6 className="alert-heading">
                                                                                        <i className="fa fa-exclamation-triangle mr-2"
                                                                                           aria-hidden="true"/>Selected target order
                                                                                        is
                                                                                        not
                                                                                        eligible!</h6>
                                                                                    As it has
                                                                                    status({newOrder.salesOrderJobStatusDescription})
                                                                                    above then Awaiting Production, and thus it
                                                                                    can't be
                                                                                    modified while copying items into it.
                                                                                </Alert>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                    : null
                            }
                            {
                                purpose !== "Rework"
                                    ? <Card className={"mt-2"}>
                                        <CardHeader id="toggler3" className={"hoverableItem"}>
                                            <Button color={"link"} size={"sm"}
                                                    onClick={() => this.handleChange("toggler2", !toggler.toggler2)}>Customer</Button>
                                        </CardHeader>
                                        <Collapse isOpen={toggler.toggler2}>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup>
                                                            <Button size={"sm"}
                                                                    disabled={newOrdNum !== null}
                                                                    color={targetAccountID === null ? "success" : "primary"}
                                                                    outline={targetAccountID !== null}
                                                                    onClick={() => this.handleChange("targetAccountID", null)}>
                                                                <i className={classnames("mr-2", "fa", "fa-fw", "fa-lg", {
                                                                    "fa fa-check": targetAccountID === null
                                                                })}/>
                                                                Original
                                                            </Button>
                                                            <Button size={"sm"}
                                                                    disabled={newOrdNum !== null}
                                                                    color={targetAccountID !== null ? "success" : "primary"}
                                                                    outline={targetAccountID === null}
                                                                    onClick={() => this.handleChange("targetAccountID", "")}>
                                                                <i className={classnames("mr-2", "fa", "fa-fw", "fa-lg", {
                                                                    "fa fa-check": targetAccountID !== null
                                                                })}/>
                                                                Nominate Customer
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        {
                                                            targetAccountID !== null
                                                                ? <div>
                                                                    Select Customer
                                                                    <SearchDebtorAcccount
                                                                        handleAccountChange={(selectedAccountID) => this.handleChange("targetAccountID", selectedAccountID)}
                                                                        selectedAccountID={targetAccountID}
                                                                        defaultAccountID={targetAccountID}
                                                                        includeChildren={true}
                                                                        excludeClosedandInactive={true}/>
                                                                </div>
                                                                : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                    : null
                            }
                            <Card className="mt-2">
                                <CardHeader id="toggler2" className={"hoverableItem"}>
                                    <Button color={"link"} size={"sm"}
                                            onClick={() => this.handleChange("toggler3", !toggler.toggler3)}>Order
                                        Items</Button>
                                </CardHeader>
                                <Collapse isOpen={toggler.toggler3}>
                                    <CardBody>
                                        <Table responsive striped bordered size={"sm"} hover={true} className={" mb-0"}>
                                            <thead className="thead-light">
                                            <tr>
                                                {(store || []).map((column, index) => {
                                                    return (
                                                        <th key={index}
                                                            onClick={() => this.handleChange("sortRequest", "key", column)}
                                                            colSpan={column.colSpan}
                                                            className={column.labelClassName}
                                                            style={{minWidth: column.minWidth}}>
                                                            {column.renderLabel((order.items || []), this)}
                                                            {
                                                                column.sorterApplicable ?
                                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                                            "fa-sort": (sortRequest.key !== column.key),
                                                                            "fa-sort-amount-asc": (sortRequest.key === column.key && sortRequest.direction),
                                                                            "fa-sort-amount-desc": (sortRequest.key === column.key && !sortRequest.direction),
                                                                        }
                                                                    )} aria-hidden="true"/> : null
                                                            }

                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                            </thead>
                                            {
                                                (order.items || []).filter(parentItem => (parentItem.parentItemNum === parentItem.itemNum))
                                                    .map((parentItem, parentItemIndex) => {
                                                        parentItemIndex = (order.items || []).findIndex(oi => oi.itemNum === parentItem.itemNum);
                                                        return <tbody key={parentItemIndex}
                                                                      style={{backgroundColor: "#b8daff"}}>
                                                        <tr>
                                                            {(store || []).map((column, columnIndex) => {
                                                                return (
                                                                    <td key={columnIndex}
                                                                        className={column.getValueClassName(parentItemIndex, order.items, this)}>
                                                                        {column.render(parentItemIndex, order.items, this, false)}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                        {
                                                            (order.items || []).filter(orderItemChild => (parentItem.isExpanded && orderItemChild.parentItemNum === parentItem.itemNum && orderItemChild.itemNum !== parentItem.itemNum))
                                                                .map((orderItemChild, orderItemChildIndex) => {
                                                                    orderItemChildIndex = (order.items || []).findIndex(oi => oi.itemNum === orderItemChild.itemNum);
                                                                    return <tr>
                                                                        {(store || []).map((column, columnIndex) => {
                                                                            return (
                                                                                <td key={columnIndex}
                                                                                    className={column.getValueClassName(orderItemChildIndex, order.items, this)}>
                                                                                    {column.render(orderItemChildIndex, order.items, this, false)}
                                                                                </td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                })
                                                        }
                                                        </tbody>
                                                    })
                                            }
                                        </Table>

                                    </CardBody>
                                </Collapse>
                            </Card>
                        </div>
                    }
                    <p className='text-info mt-1 mb-0'>
                        <i className="fa fa-info-circle mr-2"/>
                        Selected items will be copied from the original order, so if the BOM has changed since, this
                        will NOT be updated. You need to enter the order again if the BOM has changed.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        {
                            purpose === "Rework"
                                ? <Button size={"sm"}
                                          color={"primary"}
                                          onClick={() => this.copyOrder("Rework")}>
                                    {
                                        isLoadingCopyOrder
                                            ? <Spinner size="sm" className="mr-2"
                                                       style={{color: "white"}}/>
                                            : <i className="fa fa-chevron-right mr-2" aria-hidden="true"/>
                                    }
                                    {
                                        isLoadingCopyOrder
                                            ? "Saving"
                                            : "Next"
                                    }
                                </Button>
                                : <UncontrolledDropdown className={"d-inline mr-2"} disabled={isLoadingCopyOrder}>
                                    <DropdownToggle color={"primary"} className={"btn"} caret>
                                        {
                                            isLoadingCopyOrder
                                                ? <Spinner size="sm" className="mr-2"
                                                           style={{color: "white"}}/>
                                                : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>
                                        }
                                        {
                                            isLoadingCopyOrder
                                                ? "Saving"
                                                : "Save"
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.copyOrder("Copy")}>
                                            Copy
                                        </DropdownItem>
                                        <DropdownItem onClick={() => this.copyOrder("Copy and open in product builder")}>
                                            Copy and open in product builder
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                        }

                        {
                            enforceSubmit
                                ? null
                                : <Button color={"secondary"}
                                          size={"sm"}
                                          onClick={() => toggle(false)}>
                                    <i className="fa fa-times mr-2" aria-hidden="true"/>
                                    Close
                                </Button>
                        }
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}