import React from 'react';
import { Component } from 'react';
import {
    Row,
    Col,
    Spinner,
    Card,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    CardBody,
    Input,
    Table
} from 'reactstrap';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import { handleErrorMessage } from './../../services/CommonService';
import SearchBannerAddressMaster from './SearchBannerAddressMaster'
import { Link as Link } from "react-router-dom";
import addressService from '../../services/AddressService';
import UserService from '../../services/UserService';
import cloneDeep from 'lodash/cloneDeep';
import { toast, ToastContainer } from 'react-toastify';
import CustomerService from '../../services/CustomerService';
import * as FileSaver from 'file-saver';
import DeliveryAddressCreateModal from '../../components/modal/DeliveryAddressCreateModal';

export default class AddressMasterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingAddressList: false,
            isLoadingDelete: false,
            addresses: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "debtorAccountID",
                        direction: true
                    },
                    filterRequest: {
                        debtorAccountID: null,
                        email: null,
                        phone: null,
                        contactName: null,
                        condition: '',
                        company: null,
                        accountStatus: null,
                        addressID: null,
                        city: '',
                        state: '',
                        postCode: '',
                        country: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        address4: null
                    }
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            debtorAccountStatuses: [],
            isLoadingAddressListDownload: false,
            isOpenDeliveryAddressCreateModal: false,
            selectedAddress: {}
        };

        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.getAddresses = this.getAddresses.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getStore = this.getStore.bind(this);
        this.getDebtorAccountStatus = this.getDebtorAccountStatus.bind(this);
        this.refresh = this.refresh.bind(this);
        this.exportAddresses = this.exportAddresses.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.toggleAddressFormModal = this.toggleAddressFormModal.bind(this);
        this.getAddressInstance = this.getAddressInstance.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    getDebtorAccountStatus() {
        this.customerService.getDebtorAccountStatus().then(response => {
            this.setState({ debtorAccountStatuses: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    refresh() {
        this.getAddresses(this.state.addresses);
        this.getDebtorAccountStatus();

    }


    getAddresses(addresses) {
        this.setState({ isLoadingAddressList: true });
        let request = cloneDeep(addresses.request);

        addressService.getAllAddresses(request).then(response => {
            if (response.status === 200 || response.status === "200") {
                if (response.data.records) {
                    addresses.response = response.data;
                    if (response.data.records.length > 0) {
                        this.setState({
                            addresses,
                            isLoadingAddressList: false,
                        });
                    }
                    else if (response.data.records.length === 0) {
                        this.setState({ addresses, isLoadingAddressList: false });
                    }
                }
            }
        }).catch(error => {
            this.setState({ isLoadingAddressList: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    getStore({ filterRequest }) {
        let { isLoadingDelete, addresses } = this.state;
        let isAllSelected = addresses.response.records.every(x => x.isSelected);
        return [
            {
                key: "debtorAccountID",
                label: "Customer",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    let result1 = null;
                    if (value) {
                        let className = "";
                        switch (order.debtorCategoryID) {
                            case 1:
                                className = "btn btn-sm btn-warning";
                                break;
                            case 2:
                                className = "btn btn-sm btn-secondary";
                                break;
                            case 3:
                            default:
                                className = "btn btn-sm btn-primary";
                                break;
                        }
                        result1 = <Link
                            className={className}
                            style={{ color: "white" }}
                            title={"Click here to see account details"}
                            to={"/customer/#" + value}
                        >{value}</Link>;
                    }
                    return <span>{result1}</span>;
                }
            },
            {
                key: "company",
                label: "Company",
                type: "company",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <small> {value}</small>;
                }
            },
            {
                key: "contactName",
                label: "Contact Name",
                type: "",
                colSpan: 1,
                minWidth: 140,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "email",
                label: "Email",
                type: "",
                colSpan: 1,
                minWidth: 140,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "phone",
                label: "Phone",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "address1",
                label: "Address1",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "address2",
                label: "Address2",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "address3",
                label: "Address3",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "address4",
                label: "Address4",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "city",
                label: "City",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "state",
                label: "State",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "postCode",
                label: "PostCode",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "country",
                label: "Country",
                type: "",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "multiSelect",
                label: "Select Logs",
                type: 'multiSelect',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                showColumn: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left align-middle",
                searchNode: <div className={"text-center align-middle"}> <a href={"javascript:void(0)"}
                    onClick={() => this.handleAction("isAllSelected", !isAllSelected)}>
                    <i className={classnames("fa", "fa-lg", {
                        "fa-check-square-o": isAllSelected,
                        "fa-square-o": !isAllSelected,
                    }
                    )} />
                </a> </div>,
                render: function (value, storeItem, index, order, ref) {
                    return <span>
                        <a href={"javascript:void(0)"}
                            onClick={(e) => ref.handleAction("isAddressSelected", index)}>
                            <i className={classnames("fa", "fa-lg", {
                                "fa-check-square-o": storeItem.isSelected,
                                "fa-square-o": !storeItem.isSelected,
                            }
                            )} />
                        </a>
                    </span>;
                }
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 120,
                showColumn: true,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNode: <div className="text-center align-middle">
                    <Button color='primary'
                        title={"Add Address"}
                        size='sm'
                        onClick={() => this.handleAction("deliveryAddress-add", {})}>
                        <i className="fa fa-plus mr-2"
                            aria-hidden="true" />
                        Add</Button>
                </div>,
                render: function (value, storeItem, order, index, ref) {
                    return <div>
                        <Button color={"primary"}
                            className={"mr-2"}
                            size={"sm"}
                            onClick={() => ref.handleAction("deliveryAddress-edit", storeItem)}>
                            <i className={"fa fa-pencil mr-2"} />Edit
                        </Button>
                        <Button color={"danger"}
                            size={"sm"}
                            onClick={() => ref.deleteAddress(storeItem)}>
                            {isLoadingDelete ?
                                <span>
                                    <Spinner size={"sm"} color={"light"} />
                                </span>
                                :
                                <i className="fa fa-trash" />}
                        </Button>
                    </div>;
                }
            }
        ];


    }

    deleteAddress(address) {
        this.setState({ isLoadingDelete: true });
        addressService.deleteDebtorAddress(address.addressID).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.refresh(this.state.accountId);
                this.setState({ showConfirmDelete: false, isLoadingDelete: false });
                toast.success("Deleted!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    deleteSelectedAddresses() {
                let { addresses } = this.state;
                let setCommaSeparatedIds = ""
                setCommaSeparatedIds = (addresses.response.records || []).filter(x => x.isSelected).map(set => set.addressID).join(",");
                addressService.deleteDebtorAddress(setCommaSeparatedIds).then(response => {
                    if (response.status === 200 || response.status === "200") {
                        this.setState({ isLoadingDelete: false });
                        toast.success("Deleted!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.refresh(this.state.accountId);
                    }
                }).catch(error => {
                    console.log(error);
                    toast.error(handleErrorMessage(error));
                    this.setState({ isLoadingDelete: false });
                });
    }

    handleChange(change, key) {
        let { addresses } = this.state;
        switch (key) {
            case "sortKey":
                if (addresses.request.sortRequest.key === change) {
                    addresses.request.sortRequest.direction = !addresses.request.sortRequest.direction;
                } else {
                    addresses.request.sortRequest.key = change;
                    addresses.request.sortRequest.direction = false;
                }
                if (!(change === "action")) {
                    this.getAddresses(addresses);
                }
                break;
            case "pageSize":
                addresses.request.pageRequest[key] = change;
                this.getAddresses(addresses);
                break;
            case "currentPage":
                addresses.request.pageRequest[key] = change;
                this.getAddresses(addresses);
                break;
            default:
                addresses.request.filterRequest[key] = change;
                addresses.request.pageRequest.currentPage = 1;
                this.setState({ addresses });
                this.getAddresses(addresses);
        }
    }

    handleAction(key, change) {
        let { addresses } = this.state;
        switch (key) {
            case "deliveryAddress-add":
                change = this.getAddressInstance();
                change.debtorAccountID = this.state.addresses.request.filterRequest.debtorAccountID;
                this.setState({ isOpenDeliveryAddressCreateModal: true, selectedAddress: change });
                break;
            case "deliveryAddress-edit":
                let selectedAddress = {
                    addressID: change.addressID,
                    debtorAccountID: change.debtorAccountID,
                    contactName: change.contactName,
                    phone: change.phone,
                    fax: change.fax,
                    email: change.email,
                    company: change.company,
                    address1: change.address1,
                    address2: change.address2,
                    address3: change.address3,
                    address4: change.address4,
                    city: change.city,
                    state: change.state,
                    postCode: change.postCode,
                    country: change.country
                };
                this.setState({ isOpenDeliveryAddressCreateModal: true, selectedAddress });
                break;
            case "deliveryAddress-created":
                this.setState({ isOpenDeliveryAddressCreateModal: false }, () => {
                    this.getAddresses(this.state.addresses);
                });
                break;
            case "isAddressSelected":
                if (!addresses.response.records[change].isSelected) {
                    addresses.response.records[change].isSelected = true;
                } else {
                    addresses.response.records[change].isSelected = false;
                }
                this.setState({ addresses });
                break;
            case "isAllSelected":
                addresses.response.records.forEach(item => {
                    item.isSelected = change;
                })
                this.setState({ addresses });
                break;
        }
    }

    exportAddresses = () => {
        let request = cloneDeep(this.state.addresses.request);
        this.setState({ isLoadingAddressListDownload: true });
        addressService.generateAddressSummaryExcelReport(request).then(response => {
            this.setState({ isLoadingAddressListDownload: false });
            FileSaver.saveAs(response.data, "Customer Address" + ".xlsx");
        }).catch(error => {
            this.setState({ isLoadingAddressListDownload: false });
            toast.error(handleErrorMessage(error));
        });
    };


    toggleAddressFormModal(isOpen) {
        let { selectedAddress } = this.state;
        if (!isOpen) {
            selectedAddress = this.getAddressInstance();
        }
        this.setState({
            isOpenDeliveryAddressCreateModal: isOpen,
            selectedAddress
        });
    }

    getAddressInstance() {
        return {
            debtorAccountID: '',
            contactName: '',
            company: '',
            phone: '',
            fax: '',
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            city: '',
            state: '',
            postCode: '',
            country: 'AUSTRALIA'
        }
    }


    render() {
        let {
            addresses, isLoadingAddressList, selectedAddress,  isOpenDeliveryAddressCreateModal,
            isLoadingAddressListDownload, debtorAccountStatuses, isLoadingDelete,
        } = this.state;
        let { pageRequest, sortRequest, filterRequest } = addresses.request;
        let store = this.getStore(addresses.request);
        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <h5>Address Master</h5>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    </Col>
                </Row>
                <div className="mb-2">
                    <SearchBannerAddressMaster
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                        debtorAccountStatus={debtorAccountStatuses}
                    />
                </div>
                <Card>
                    <CardBody>
                        <div>

                            <div className={"d-flex justify-content-between align-items-center"}>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        {isLoadingAddressList ?
                                            <span>
                                                <Spinner size={"sm"} color={"primary"} />
                                            </span>
                                            :
                                            <span>
                                                Showing{' '}
                                                {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {addresses.response.totalRecords}
                                                {' '}entries
                                                &nbsp;
                                            </span>
                                        }

                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className={"text-right p-2"}>
                                        {addresses.response.records.some(x => x.isSelected) ?
                                            <>
                                            <Button color={"danger"}
                                                size={"md"}
                                                onClick={() => this.deleteSelectedAddresses()}>
                                                {isLoadingDelete ?
                                                    <span>
                                                        <Spinner size={"sm"} color={"light"} />
                                                    </span>
                                                    :
                                                    <i className="fa fa-trash" />}  Delete Selected
                                                </Button>
                                                </>
                                         : null}
                                    </div>
                                </Col>
                            </div>
                            <div>
                                <Table hover bordered size={"sm"} striped responsive>
                                    <thead>
                                        <tr>
                                            {(store || []).filter(item => item.showColumn ? true : false).map((item, index) => {
                                                return (
                                                    <th key={index}
                                                        onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                                        colSpan={item.colSpan}
                                                        className={item.labelClassName}
                                                        style={{ minWidth: item.minWidth }}>
                                                        {item.label}
                                                        {
                                                            item.sorterApplicable ?
                                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                                )} aria-hidden="true" /> : null
                                                        }
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            {(store || []).map((item, index) => {
                                                return (
                                                    <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                        {item.searchNode}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {(addresses.response.records || []).map((contact, i) => {
                                            return (
                                                <tr key={i}>
                                                    {(store || []).filter(item => item.showColumn ? true : false).map((storeItem, index) => {
                                                        return (
                                                            <td key={index} className={storeItem.valueClassName}>
                                                                {storeItem.render(contact[storeItem.key], contact, i, addresses, this)}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </Table>

                                {!isLoadingAddressList ?
                                    <Row>
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <div className={"text-left"} style={{ maxWidth: 440 }}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Show</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type={"select"}
                                                        name={"pageSize"}
                                                        value={pageRequest.pageSize}
                                                        disabled={isLoadingAddressList}
                                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                        <option value={25}>25 Rows</option>
                                                        <option value={50}>50 Rows</option>
                                                        <option value={100}>100 Rows</option>
                                                        <option value={500}>500 Rows</option>
                                                    </Input>
                                                    &nbsp;&nbsp;
                                                    <Button color={"success"}
                                                        outline={!isLoadingAddressListDownload}
                                                        size={"sm"}
                                                        title={'Click here to export data'}
                                                        onClick={this.exportAddresses}
                                                        disabled={isLoadingAddressListDownload}>{isLoadingAddressListDownload ? "Exporting" : "Export"}
                                                        {isLoadingAddressListDownload
                                                            ? <Spinner size="sm"
                                                                className={"ml-2"}
                                                                color="light"
                                                            />
                                                            :
                                                            <i className="fa fa-file-excel-o ml-2" aria-hidden="true" />}
                                                    </Button>
                                                </InputGroup>


                                            </div>
                                        </Col>
                                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                            <div className={"float-right"}>
                                                <Pagination
                                                    activePage={pageRequest.currentPage}
                                                    itemsCountPerPage={pageRequest.pageSize}
                                                    totalItemsCount={addresses.response.totalRecords}
                                                    pageRangeDisplayed={3}
                                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    activeClass='active'
                                                    innerClass='pagination'
                                                    activeLinkClass='active'
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    null
                                }

                            </div>
                            <ToastContainer />
                        </div>
                    </CardBody>
                </Card>
                {isOpenDeliveryAddressCreateModal
                    ? <DeliveryAddressCreateModal
                        isOpen={isOpenDeliveryAddressCreateModal}
                        toggle={this.toggleAddressFormModal}
                        address={selectedAddress}
                        onCreation={(createdAddress) => this.handleAction("deliveryAddress-created", createdAddress)}
                        usage={"Address Master Page"}
                    />
                    : null
                }


            </div>
        );
    }
}