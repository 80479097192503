import React, {Component} from 'react';
// Image fetch URL from backend REST API
const baseUrl = window.location.origin;
const prefix = baseUrl + "/api/file/generate/production/dashboard/images?FileName=";
const productionPerformanceImg = prefix + 'ProductionPerformance.png';

export default class ProductionDashboardProductionPerformance extends Component {

    componentDidMount() {
        setInterval(function () {
            let imageElement = document.getElementById("productionPerformanceImg");
            if(imageElement){
                imageElement.src = productionPerformanceImg + '&rand=' + Math.random();
            }
        }, 300000);
    }

    render() {

        return (
            <img id={"productionPerformanceImg"}
                 alt="Production Performance Chart"
                 src={productionPerformanceImg}
                 width="100%"
                 resizeMode={'cover'}/>

        );

    }

}
