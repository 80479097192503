import React, {Component} from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TextArea,
    UncontrolledTooltip
} from 'reactstrap';
import moment from 'moment';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {DirectLink, Element, scroller} from 'react-scroll'

import CRMService from "../../services/crm/CRMService";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';

import {findIndex, getDateString, handleErrorMessage} from '../../services/CommonService';
import LeadRemark from "./LeadRemark";
import {EventBus} from "../../components/events/event"
//
// import {AsyncTypeahead, Highlighter} from 'react-bootstrap-typeahead';
// import 'react-bootstrap-typeahead/css/Typeahead.css';


export default class LeadActivity extends Component {


    constructor(props) {
        super(props);

        let lead = props.lead;

        this.state = {
            lead: lead,
            editActivityMode: "",

            activity: {
                id: 0,
                leadID: lead.id,
                note: "",
                followUpType: "PHONE",
                closed: false,
                callbackOn: "",
                doNotRemind: false,
                comment: "",
                assignedUserID: "",
                assignedUserName: "",
                assignedUserEmail: ""

            },
            users: [],
            userLoading: false,
            selectedUser: {}
        };

        this.crmService = new CRMService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCallbackOn = this.handleCallbackOn.bind(this);
        this.toggleCloseActivity = this.toggleCloseActivity.bind(this);
        this.toggleCloseActivityInDb = this.toggleCloseActivityInDb.bind(this);
        this.editActivity = this.editActivity.bind(this);
        this.loadSalesPersonOptions = this.loadSalesPersonOptions.bind(this);

    }

    componentDidMount() {
        let lead = this.state.lead;
        if (lead.activityID) {
            let elementName = 'activity' + lead.activityID;
            scroller.scrollTo(elementName, {
                duration: 500,
                delay: 200,
                offset: -90,
                smooth: 'easeInOutQuart'
            })
        }
        this.searchSalesPerson("");
    }

    componentWillReceiveProps(nextProps) {
        let newLead = nextProps.lead;
        this.setState({lead: newLead}, function () {
            //  this.refresh();
            //console.log("lead updated");
        });
    }


    editActivity(activityId, activity) {

        if (activityId === "new" || activityId === "") {
            this.setState(prevState => ({
                editActivityMode: activityId,
                selectedUser: [],
                activity: {
                    id: 0,
                    leadID: prevState.lead.id,
                    note: "",
                    followUpType: "PHONE",
                    closed: false,
                    callbackOn: "",
                    doNotRemind: false,
                    comment: "",
                    assignedUserID: "",
                    assignedUserName: "",
                    assignedUserEmail: "",
                }
            }));
        } else {
            this.setState(prevState => ({
                editActivityMode: activityId,
                selectedUser: [],
                activity: {
                    id: activity.id,
                    leadID: activity.leadID,
                    note: activity.note,
                    followUpType: activity.followUpType,
                    closed: activity.closed,
                    callbackOn: activity.callbackOn,
                    doNotRemind: activity.doNotRemind,
                    comment: activity.comment,
                    assignedUserID: activity.assignedUserID,
                    assignedUserName: activity.assignedUserName,
                    assignedUserEmail: activity.assignedUserEmail,
                }
            }));
        }
    }

    toggleCloseActivity() {
        let activity = this.state.activity;
        activity.closed = !activity.closed;
        this.setState({activity: activity});
    }

    toggleCloseActivityInDb(activity) {
        let self = this;
        let fetchLead = this.props.fetchLead;

        this.crmService.toggleActivityInDb(activity.id).then(response => {
            toast.success(activity.closed ? "Activity is now Pending !" : "Activity is now Completed !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            let {lead} = self.state;
            if (lead) {
                if (lead.activities && lead.activities.length > 0) {
                    let index = findIndex(lead.activities, 'id', activity.id);
                    if (index > -1) {
                        lead.activities[index].closed = !lead.activities[index].closed;
                    }
                }
            }

            self.setState({lead: lead});
            if (lead.status === "NEW") {
                fetchLead(lead.id, 0);
            }
            EventBus.publish('update-notification', {});
        }).catch(error => {
            alert(handleErrorMessage(error));
        });
    }

    updateLeadStatus() {

    }

    handleChange(e) {
        e.preventDefault();
        console.log(e);
        const {name, value} = e.target;
        let {activity} = this.state;
        activity[name] = value;
        this.setState({activity});
    }

    handleCallbackOn(changedDate) {
        let now = new Date();
        let activity = this.state.activity;
        activity.callbackOn = changedDate;
        this.setState({activity: activity});
    }


    handleSubmit(e) {
        e.preventDefault();
        let result = this.validateForm();
        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }

        let fetchLead = this.props.fetchLead;
        let {lead, activity} = this.state;
        if (activity.callbackOn) {
            activity.callbackOn = moment.utc(activity.callbackOn);
            activity.callbackOn = activity.callbackOn.format('DD/MM/YYYY HH:mm:ss');
        }

        this.crmService.saveActivity(activity).then(response => {

            fetchLead(lead.id, 0);

            toast.success("Activity Saved", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.editActivity("", activity);
            EventBus.publish('update-notification', {});
        }).catch(error => {
            alert(handleErrorMessage(error));
        });
    }

    validateForm() {
        return {valid: true, formErrors: {}};
    }


    changedAssignedUser = (selectedUser) => {
        const {users, activity} = this.state;
        let assignedUserID = "";
        let assignedUserName = "";
        let assignedUserEmail = "";

        if (selectedUser) {
            assignedUserID = selectedUser.userId;
            assignedUserName = this.crmService.getFullName(selectedUser.firstName, selectedUser.lastName);
            assignedUserEmail = selectedUser.emailAddress;
        }

        activity.assignedUserID = assignedUserID;
        activity.assignedUserName = assignedUserName;
        activity.assignedUserEmail = assignedUserEmail;
        this.setState({
            selectedUser: selectedUser,
            activity
        });

    };

    searchSalesPerson(query, callback) {
        let self = this;
        let users = [];
        this.crmService.searchSalesPerson(query).then(response => {
            let data = response.data;

            for (let i in data) {
                let user = {
                    label: i,
                    value: data[i].userId,
                    id: data[i].userId,
                    userId: data[i].userId,
                    firstName: data[i].firstName,
                    lastName: data[i].lastName,
                    emailAddress: data[i].emailAddress
                };
                users.push(cloneDeep(user));
            }
            self.setState({users});
            if (callback) {
                return callback(users)
            } else {
                return users;
            }
        }).catch(error => {
            alert(handleErrorMessage(error));
            }
        );
    };

    loadSalesPersonOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.searchSalesPerson(inputValue, callback)
            } else {
                callback(this.state.users);
            }
        }
    };


    render() {
        let {lead, activity, editActivityMode} = this.state;

        let leadAddress = (lead.address1 ? lead.address1 + ", " : "")
            + (lead.address2 ? lead.address2 + ", " : "")
            + (lead.address3 ? lead.address3 + ", " : "")
            + (lead.address4 ? lead.address4 + ", " : "")
            + (lead.city ? lead.city + ", " : "")
            + (lead.state ? lead.state + ", " : "")
            + (lead.postCode ? lead.postCode + ", " : "");

        return (
            <div id="activities" name="activities">
                <h5 className="text-left">
                    Activities/Tasks &nbsp;&nbsp;{editActivityMode === "new" ?
                    null : <Button className="btn btn-sm pill"
                                   color="primary"
                                   onClick={() => this.editActivity("new", activity)}>
                                <span className="badge badge-pill badge-light mr-0"><i
                                    className="fa fa-plus"/></span>&nbsp;New
                    </Button>
                }</h5>
                <hr/>
                {/**************************** new Activity starts ***********************/}
                <div>
                    {/*<Row className="mb-4">
                        <Col lg={2} md={2} sm={2} xs={2}>

                        </Col>
                        <Col lg={3} md={3} sm={3} xs={3}>
                            {editActivityMode === "new" ?
                                null : <div className="text-left ml-2"><Button className="btn btn-sm"
                                                                               color="primary"
                                                                               onClick={() => this.editActivity("new", activity)}>
                                <span className="badge badge-pill badge-light mr-0"><i
                                    className="fa fa-plus"/></span>&nbsp;New
                                    Activity
                                </Button></div>
                            }
                        </Col>

                    </Row>*/}
                    {editActivityMode === "new" ?
                        <Row>
                            <Col lg={2}>
                                <div className="lead text-right">{moment(new Date()).format('DD MMM YYYY')}</div>
                                <div className="text-right">{moment(new Date()).format('hh:mm A')}</div>
                            </Col>
                            <Col lg={10} style={{
                                borderLeft: "2px solid #eeeeee"
                            }}>
                                <Card className="shadow-0 border ml-2">
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label className="col-form-label"><h6> Follow-up Type</h6>
                                                        </Label>


                                                        <Input className="form-control"
                                                               onChange={this.handleChange}
                                                               type="select"
                                                               name="followUpType"
                                                               value={activity.followUpType}
                                                               placeholder="write State here...">
                                                            <option value={"PHONE"}>PHONE</option>
                                                            <option value={"EMAIL"}>EMAIL</option>
                                                            <option value={"SMS"}>SMS</option>
                                                            <option value={"VISIT"}>VISIT</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label className="col-form-label"><h6> Due On </h6></Label>
                                                        <div className="cal-datepicker">
                                                            <div className="datepicker-here" data-language='en'>
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={activity.callbackOn}
                                                                    onChange={this.handleCallbackOn}
                                                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                                                    timeFormat="HH:mm"
                                                                    showTimeSelect
                                                                    timeIntervals={15}
                                                                    timeCaption="Time"
                                                                    placeholderText="select date"
                                                                    withPortal
                                                                />
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label className="col-form-label"><h6> Assign To</h6>
                                                        </Label>
                                                        <div>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                isClearable={false}
                                                                defaultOptions={this.state.users}
                                                                loadOptions={this.loadSalesPersonOptions}
                                                                onChange={this.changedAssignedUser}
                                                                value={this.state.selectedUser}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                {(() => {
                                                    if (activity.assignedUserEmail) {
                                                        return (<Col lg={6} md={6} sm={6} xs={12}>
                                                            <div className="text-left">
                                                                <Label className="col-form-label"><h6>&nbsp;</h6>
                                                                </Label>
                                                                <p id={"activityAssignedToUser" + activity.id}><i
                                                                    className="fa fa-user"
                                                                    aria-hidden="true"/> &nbsp;{activity.assignedUserName}
                                                                </p>
                                                            </div>
                                                            <UncontrolledTooltip
                                                                placement="left"
                                                                target={"activityAssignedToUser" + activity.id}>
                                                                <i className="fa fa-envelope-o "
                                                                   aria-hidden="true"/> &nbsp;{activity.assignedUserEmail}
                                                            </UncontrolledTooltip>
                                                        </Col>)
                                                    }
                                                })()}
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label className="col-form-label"><h6> Subject </h6></Label>
                                                        <Input className="form-control"
                                                               onChange={this.handleChange}
                                                               type="textarea"
                                                               name="note"
                                                               value={activity.note}
                                                               placeholder="write subject here..."/>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label className="col-form-label"><h6> Comment</h6></Label>
                                                        <Input className="form-control"
                                                               onChange={this.handleChange}
                                                               type="textarea"
                                                               name="comment"
                                                               value={activity.comment}
                                                               placeholder="write an additional text to support your subject..."/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className="text-left">
                                                <FormGroup>
                                                    <Label className="col-form-label"><h6> Status </h6></Label>
                                                    <div>
                                                        <ButtonGroup size="sm">
                                                            <Button
                                                                color={"primary"}
                                                                outline={activity.closed}
                                                                className="btn btn-sm"
                                                                onClick={this.toggleCloseActivity}>Pending
                                                            </Button>
                                                            <Button
                                                                color={"success"}
                                                                outline={!activity.closed}
                                                                className="btn btn-sm"
                                                                onClick={this.toggleCloseActivity}> Completed
                                                            </Button>

                                                        </ButtonGroup>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </Form>
                                    </CardBody>
                                    <CardFooter>
                                        {editActivityMode === "new" ?
                                            <div className="text-right">
                                                <Button className="btn btn-sm mb-2" color="primary"
                                                        onClick={this.handleSubmit}>{activity.id === 0 ? "Save Activity" : "Update Activity"}
                                                </Button>&nbsp;

                                                <Button className="btn btn-sm mb-2" color="secondary"
                                                        hidden={editActivityMode === ""}
                                                        onClick={() => this.editActivity("", activity)}>Cancel
                                                </Button>
                                            </div>
                                            : null
                                        }
                                    </CardFooter>
                                </Card>
                            </Col>

                        </Row> : null}
                </div>
                {/**************************** new Activity ends ***********************/}

                {
                    lead.activities.sort(function (a1, a2) {
                        return a2.id - a1.id;
                    }).map((task, index) =>
                        <Element key={index} name={"activity" + task.id}>
                            <Row>
                                <Col lg={2}>
                                    <div
                                        className="lead text-right">{getDateString(task.createdDate, 'DD MMM YYYY')}</div>
                                    <div
                                        className="text-right">{getDateString(task.createdDate, 'hh:mm A')}</div>
                                    <div
                                        className="text-right text-muted">{moment(task.createdDate).fromNow()}</div>
                                </Col>
                                <Col lg={10} style={{
                                    borderLeft: "2px solid #eeeeee"
                                }}>
                                    {editActivityMode === task.id ?
                                        <Card className="shadow-0 border ml-2">
                                            <CardBody>
                                                <Form>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={6} xs={12}>
                                                            <FormGroup>
                                                                <Label className="col-form-label"><h6> Follow-up
                                                                    Type</h6>
                                                                </Label>

                                                                <Input className="form-control"
                                                                       onChange={this.handleChange}
                                                                       type="select"
                                                                       name="followUpType"
                                                                       value={activity.followUpType}
                                                                       placeholder="write State here...">
                                                                    <option value={"PHONE"}>PHONE</option>
                                                                    <option value={"EMAIL"}>EMAIL</option>
                                                                    <option value={"SMS"}>SMS</option>
                                                                    <option value={"VISIT"}>VISIT</option>
                                                                </Input>

                                                            </FormGroup>


                                                        </Col>
                                                        <Col lg={6} md={6} sm={6} xs={12}>

                                                            <FormGroup>
                                                                <Label className="col-form-label"><h6> Due On </h6>
                                                                </Label>
                                                                <div className="cal-datepicker">

                                                                    <div className="datepicker-here" data-language='en'>
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            selected={this.state.activity.callbackOn}
                                                                            onChange={this.handleCallbackOn}
                                                                            dateFormat="dd/MM/yyyy HH:mm:ss"
                                                                            timeFormat="HH:mm"
                                                                            showTimeSelect
                                                                            timeIntervals={15}
                                                                            timeCaption="Time"
                                                                            placeholderText="select date"
                                                                            withPortal

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                    <Row>

                                                        <Col lg={6} md={6} sm={6} xs={12}>

                                                            <FormGroup>
                                                                <Label className="col-form-label"><h6>Assign To</h6>
                                                                </Label>

                                                                <div>
                                                                    <AsyncSelect
                                                                        cacheOptions
                                                                        isClearable={false}
                                                                        defaultOptions={this.state.users}
                                                                        loadOptions={this.loadSalesPersonOptions}
                                                                        onChange={this.changedAssignedUser}
                                                                        value={this.state.selectedUser}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>

                                                        {(() => {
                                                            if (task.assignedUserEmail) {
                                                                return (<Col lg={6} md={6} sm={6} xs={12}>
                                                                    <div className="text-left">
                                                                        <Label className="col-form-label">
                                                                            <h6>&nbsp;</h6></Label>
                                                                        <p id={"taskAssignedToUser" + task.id}><i
                                                                            className="fa fa-user"
                                                                            aria-hidden="true"/> &nbsp;{task.assignedUserName}
                                                                        </p>
                                                                    </div>
                                                                    <UncontrolledTooltip
                                                                        placement="left"
                                                                        target={"taskAssignedToUser" + task.id}>
                                                                        <i className="fa fa-envelope-o "
                                                                           aria-hidden="true"/> &nbsp;{task.assignedUserEmail}
                                                                    </UncontrolledTooltip>
                                                                </Col>)
                                                            }
                                                        })()}
                                                    </Row>

                                                    <Row>
                                                        <Col lg={6} md={6} sm={6} xs={12}>
                                                            <FormGroup>
                                                                <Label className="col-form-label"><h6>Subject</h6>
                                                                </Label>
                                                                <Input className="form-control"
                                                                       onChange={this.handleChange}
                                                                       type="textarea"
                                                                       name="note"
                                                                       value={activity.note}
                                                                       placeholder="write subject here..."/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg={6} md={6} sm={6} xs={12}>
                                                            <FormGroup>
                                                                <Label className="col-form-label"><h6> Comment</h6>
                                                                </Label>
                                                                <Input className="form-control"
                                                                       onChange={this.handleChange}
                                                                       type="textarea"
                                                                       name="comment"
                                                                       value={activity.comment}
                                                                       placeholder="write an additional text to support your subject..."/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </Form>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <div className="text-left mt-2">
                                                            <ButtonGroup size="sm">
                                                                <Button
                                                                    color={"primary"}
                                                                    outline={task.closed}
                                                                    className="btn btn-sm"
                                                                    onClick={() => this.toggleCloseActivityInDb(task)}>Pending
                                                                </Button>
                                                                <Button
                                                                    color={"success"}
                                                                    outline={!task.closed}
                                                                    className="btn btn-sm"
                                                                    onClick={() => this.toggleCloseActivityInDb(task)}>
                                                                    Completed
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>

                                                {editActivityMode === activity.id ? <div className="text-right">
                                                        <Button className="btn btn-sm mb-2" color="primary"
                                                                onClick={this.handleSubmit}>{activity.id === 0 ? "Save Activity" : "Update Activity"}
                                                        </Button>
                                                        &nbsp;
                                                        <Button className="btn btn-sm mb-2"
                                                                onClick={() => this.editActivity("", activity)}>Cancel
                                                        </Button>

                                                    </div>
                                                    : null
                                                }


                                            </CardFooter>
                                        </Card> : <Card className={"shadow-0 border ml-2"}>
                                            <CardBody>
                                                <div>
                                                    <div>
                                                        <Row>
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <h6><a href="javascript:void(0)"
                                                                       onClick={() => this.editActivity(task.id, task)}>
                                                                    <i className="fa fa-pencil-square-o fa-lg"
                                                                       aria-hidden="true"/>
                                                                </a>&nbsp;&nbsp;
                                                                    {task.followUpType}&nbsp;:&nbsp;&nbsp;
                                                                    {(() => {
                                                                        if (task.followUpType === "PHONE" && lead.phone) {
                                                                            return (
                                                                                <a href={"tel:" + lead.phone}>
                                                                                    <i className=" text-muted fa fa-phone"
                                                                                       aria-hidden="true"/>
                                                                                    &nbsp;{lead.phone}
                                                                                </a>
                                                                            )
                                                                        }
                                                                        if (task.followUpType === "SMS" && lead.phone) {
                                                                            return (
                                                                                <a href={"sms:" + lead.phone}>
                                                                                    <i className="text-muted fa fa-commenting-o"
                                                                                       aria-hidden="true"/>
                                                                                    &nbsp;{lead.phone}
                                                                                </a>
                                                                            )
                                                                        }
                                                                        if (task.followUpType === "EMAIL" && lead.email) {
                                                                            return (
                                                                                <a href={"mailto:" + lead.email}>
                                                                                    <i className="text-muted fa fa-envelope-o"
                                                                                       aria-hidden="true"/>
                                                                                    &nbsp;{lead.email}
                                                                                </a>
                                                                            )
                                                                        }
                                                                        if (task.followUpType === "VISIT" && leadAddress) {
                                                                            return (
                                                                                <a href={"http://maps.google.com/?q=" + leadAddress}
                                                                                   target="_blank">
                                                                                    <i className="text-muted fa fa-map-marker"
                                                                                       aria-hidden="true"/>
                                                                                    &nbsp;{leadAddress}
                                                                                </a>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </h6>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                {(() => {
                                                                    if (task.callbackOn) {
                                                                        return (<p> Due On  &nbsp;: &nbsp;
                                                                            <i className="fa fa-calendar"
                                                                               aria-hidden="true"/> &nbsp;
                                                                            {moment(task.callbackOn).format('DD/MM/YYYY HH:mm')}
                                                                        </p>)
                                                                    }
                                                                })()}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {(() => {
                                                                if (task.assignedUserEmail) {
                                                                    return (<Col lg={12} md={12} sm={6} xs={12}>
                                                                        <div className="text-left">
                                                                            <p id={"taskAssignedToUser" + task.id}>
                                                                                Assigned to  &nbsp;: &nbsp;<i
                                                                                className="fa fa-user"
                                                                                aria-hidden="true"/> &nbsp;{task.assignedUserName}
                                                                            </p>
                                                                        </div>
                                                                        <UncontrolledTooltip
                                                                            placement="left"
                                                                            target={"taskAssignedToUser" + task.id}>
                                                                            <i className="fa fa-envelope-o "
                                                                               aria-hidden="true"/> &nbsp;{task.assignedUserEmail}
                                                                        </UncontrolledTooltip>
                                                                    </Col>)
                                                                }
                                                            })()}

                                                        </Row>
                                                        <Row>
                                                            {(() => {
                                                                if (task.note) {
                                                                    return (<Col lg={12} md={12} sm={12} xs={12}>
                                                                        <p><i className="fa fa-hand-o-right"
                                                                              aria-hidden="true"/>&nbsp;{task.note}</p>
                                                                    </Col>)
                                                                }
                                                            })()}

                                                        </Row>
                                                        <Row>
                                                            {(() => {
                                                                if (task.comment) {
                                                                    return (<Col lg={12} md={12} sm={12} xs={12}>
                                                                        <p><em>{task.comment}</em></p>
                                                                    </Col>)
                                                                }
                                                            })()}
                                                        </Row>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <LeadRemark lead={lead} activity={task}/>
                                                    </Col>

                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <div className="mt-2">
                                                            <ButtonGroup size="sm">
                                                                <Button
                                                                    color={"primary"}
                                                                    outline={task.closed}
                                                                    className="btn btn-sm"
                                                                    onClick={() => this.toggleCloseActivityInDb(task)}>Pending
                                                                </Button>
                                                                <Button
                                                                    color={"success"}
                                                                    outline={!task.closed}
                                                                    className="btn btn-sm"
                                                                    onClick={() => this.toggleCloseActivityInDb(task)}>
                                                                    Completed
                                                                </Button>
                                                            </ButtonGroup>

                                                            {/*<div className="switch-sm"
                                                                 title={task.closed ? "Click here to open activity" : "Click here to close activity"}
                                                                 style={{display: "inline-block"}}>
                                                                <Label className="switch">
                                                                    <Input type="checkbox"
                                                                           onClick={() => this.toggleCloseActivityInDb(task)}
                                                                           defaultChecked={task.closed}
                                                                           name="closed"
                                                                           value={task.closed}
                                                                    />
                                                                    <span className="switch-state"/>
                                                                </Label>&nbsp;

                                                            </div>


                                                           {task.closed ? <span className={"txt-dark"}>Closed</span> :
                                                                <span className={"txt-primary"}>Pending</span>}

                                                            {(() => {
                                                                if (!task.closed) {
                                                                    return (
                                                                        <div className="ml-2" style={{
                                                                            display: "inline-block",
                                                                            borderLeft: "2px solid #eeeeee"
                                                                        }}>

                                                                        </div>)
                                                                }
                                                            })()}
                                                            */}

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    }

                                </Col>
                            </Row>
                        </Element>
                    )

                }
            </div>
        )
    }
}