import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Spinner, Table} from "reactstrap";

import NumberFormat from "react-number-format";


export default function StockEnquiryPageGeneralTabDimensionCard(props) {
    let {stock} = props;
    let keywayStock = (stock && stock.keywayStock) ? stock.keywayStock : {};

    return <Card>
        <CardHeader>
            <h6 className="mb-0">Dimension</h6>
        </CardHeader>
        <CardBody>
            <Table size={"sm"} className={"mb-0"}>
                <tbody>
                <tr>
                    <th>Length</th>
                    <td className="text-right">
                        {
                            keywayStock.length
                                ? <span>
                                    <NumberFormat
                                        value={keywayStock.length}
                                        displayType={'text'}
                                        thousandSeparator={false}/>({keywayStock.dimensionUnitName})
                                    </span>
                                : "-"
                        }
                    </td>
                </tr>
                <tr>
                    <th>Linear Width</th>
                    <td className="text-right">
                        {
                            stock.linearWidth
                                ? <span>
                                    <NumberFormat
                                        value={stock.linearWidth}
                                        displayType={'text'}
                                        thousandSeparator={false}/>({keywayStock.dimensionUnitName})
                                    </span>
                                : "-"
                        }
                    </td>
                </tr>
                <tr>
                    <th>Width</th>
                    <td className="text-right">
                        {
                            keywayStock.width
                                ? <span>
                                    <NumberFormat
                                        value={keywayStock.width}
                                        displayType={'text'}
                                        thousandSeparator={false}/>({keywayStock.dimensionUnitName})
                                   </span>
                                : "-"
                        }
                    </td>
                </tr>
                <tr>
                    <th>Height</th>
                    <td className="text-right">
                        {keywayStock.height
                            ? <span>
                                <NumberFormat
                                    value={keywayStock.height}
                                    displayType={'text'}
                                    thousandSeparator={false}/>({keywayStock.dimensionUnitName})
                                </span>
                            : "-"
                        }
                    </td>
                </tr>
                <tr>
                    <th>Weight</th>
                    <td className="text-right">
                        {keywayStock.weight
                            ? <span>
                                 <NumberFormat
                                     value={keywayStock.weight}
                                     displayType={'text'}
                                     thousandSeparator={false}/>
                                  (g)
                            </span>
                            : "-"
                        }
                    </td>
                </tr>
                </tbody>
            </Table>
        </CardBody>
    </Card>
}