import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Label, Input, UncontrolledAlert, Badge
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import OrderService from '../../services/OrderService';
import UserService from '../../services/UserService';
import { handleErrorMessage } from '../../services/CommonService';

export default class OrderStatusChangeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            salesOrderJobStatuses: this.props.salesOrderJobStatuses,
            order: this.props.order,
            hasDebtorInvoiceCreditHoldReleasePrivilege: false,
        };

        this.userService = new UserService();
        this.orderService = new OrderService();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let { hasDebtorInvoiceCreditHoldReleasePrivilege } = this.state;
        let currentUser = this.userService.getLoggedInUser();
        hasDebtorInvoiceCreditHoldReleasePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-invoice-credit-hold-release')
        this.setState({ hasDebtorInvoiceCreditHoldReleasePrivilege });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.order !== this.state.order) {
            this.setState({ order: nextProps.order, salesOrderJobStatusId: null });
        }

    }

    handleChange(value) {
        this.setState({ salesOrderJobStatusId: parseInt(value) });
    }

    handleCancel() {
        //parameters- 1.to close modal, 2. refresh o.gateway page
        this.props.handlerCloseModal(false, false);
    }

    handleSubmit() {
        let { order, salesOrderJobStatusId, hasDebtorInvoiceCreditHoldReleasePrivilege } = this.state;
        if (!salesOrderJobStatusId) {
            toast.info("Please select status");
            return;
        }

        if (order.salesOrderJobStatusDescription === 'Credit Hold' && !hasDebtorInvoiceCreditHoldReleasePrivilege) {
            toast.error("This order is currently on Credit Hold and you are not authorised to release this order, so you can't update status for this order.");
            return;
        }

        let req = {
            ordNum: order.ordNum,
            salesOrderJobStatusId: salesOrderJobStatusId,
            cancelReasonID: null,
            overrideHold: false
        };

        this.setState({ loading: true });
        this.orderService.changeOrderStatusForMultipleOrders([req]).then(res => {
            if (res.status === 200 || res.status === '200') {
                this.setState({ loading: false });
                toast.success("Order status changed successfully");
                this.props.handlerCloseModal(false, true);
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let { isOpen, toggle, salesOrderJobStatuses } = this.props;
        let { loading, order, salesOrderJobStatusId, hasDebtorInvoiceCreditHoldReleasePrivilege } = this.state;

        //statuses includes Pending and Awaiting Furth Info 
        let filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
            return stat.id === 30 || stat.id === 40;
        });
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">Order No. {order.ordNum}</h5>
                </ModalHeader>
                <ModalBody>
                    {order.salesOrderJobStatusDescription === 'Credit Hold' ?
                        <UncontrolledAlert color="warning">
                            <i className="fa fa-warning"></i>  &nbsp;We draw your kind attention to the fact that current order status is Credit Hold
                            {
                                !hasDebtorInvoiceCreditHoldReleasePrivilege ?
                                    " and you are not authorised to release this order"
                                    : null
                            }
                        </UncontrolledAlert>
                        : null}
                    Current order status: <Badge color="warning" size="sm">{order.salesOrderJobStatusDescription}</Badge>
                    <div className="mt-2 mb-2">
                        <Label>Select new order status</Label>
                        <Input type={"select"}
                            name={"salesOrderJobStatusId"}
                            value={salesOrderJobStatusId ? salesOrderJobStatusId : ""}
                            onChange={(e) => this.handleChange(e.target.value)}>
                            <option value={""} disabled>Select Status</option>
                            {(filtered_status || []).map((option, index) =>
                                <option key={index} value={option.id}>{option.statusDescription}</option>
                            )}
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"} size={"sm"} onClick={this.handleSubmit}
                            disabled={loading || (order.salesOrderJobStatusDescription === 'Credit Hold' && !hasDebtorInvoiceCreditHoldReleasePrivilege)}>
                            {loading ? <Spinner size="sm"
                                className={"mr-2"}
                                style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2" />}
                            {loading ? "Updating" : "Update"} Status</Button>&nbsp;&nbsp;
                        <Button color={"secondary"}
                            size={"sm"} onClick={() => this.handleCancel()}>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}