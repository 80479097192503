import React, {Component} from 'react';
import {Badge, Button} from 'reactstrap';

export default class AppliedSorters extends Component {

    constructor(props) {
        super(props);
        this.updateSorter = this.updateSorter.bind(this);

    }

    updateSorter() {
        let selected = {
            name: "",
            displayName: "",
            key: "",
            ascending: true,
            displayValue: ""
        };
        let reload = false;
        this.props.onChange(selected, reload);
    }


    render() {
        let selected = this.props.selected;

        if (selected.name) {
            return (
                <div>
                    <Button color="primary" className={"btn btn-sm"}
                            onClick={this.updateSorter}>
                        <strong>{selected.displayName}</strong>:&nbsp;{selected.displayValue} &nbsp;&nbsp;
                        <i className="fa fa-times" aria-hidden="true"/>
                    </Button>
                </div>
            );
        }
        return null;

    }
}
