import axios from './axios';
class StockInventoryCostProductionHistoryService {

    static Instance() {
        return new StockInventoryCostProductionHistoryService();
    }
    getStockInventoryCostProductionHistory(req) {
        return axios.post('api/stock-inventory-cost-production-history/list/all', req)
    }
}
export default StockInventoryCostProductionHistoryService.Instance();