import React, { Component } from 'react';
import Notification from './notification.component'
import { NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import RoleActivation from "../../components/user/RoleActivation";
import { Link } from "react-router-dom";
import { EventBus } from "../../components/events/event";

export default class UserMenuDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openRoleActivationModal: false
        };

        this.toggleOpenRoleActivationModal = this.toggleOpenRoleActivationModal.bind(this);
        this.logout = this.logout.bind(this);
        this.reset = this.reset.bind(this);
    }

    toggleOpenRoleActivationModal() {
        this.setState((prevState) => ({ openRoleActivationModal: !prevState.openRoleActivationModal }));
    }

    logout() {
        this.reset();
        EventBus.publish('login-logout', {});
    }

    reset() {
        localStorage.clear();
    }


    render() {
        let greetingText = "";
        if (this.props.user.firstName) {
            greetingText = this.props.user.firstName;

        }
        return (
            <div>
                <UncontrolledDropdown nav inNavbar className={"listStyleNone hoverableItem"}>
                    <DropdownToggle nav caret>
                        <span className={"text-primary"}><i className="fa fa-fw fa-user mr-1 " aria-hidden="true" /> {greetingText.toUpperCase()}</span>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag={Link} to={"/profile"} className="text-primary">
                            <i className="fa fa-fw fa-pencil mr-2" aria-hidden="true" />Edit Profile
                        </DropdownItem>
                        <DropdownItem tag={Link} to={"/user/inbox"} className="text-primary">
                            <i className="fa fa-fw fa-inbox mr-2" aria-hidden="true" />Inbox

                        </DropdownItem>
                        <DropdownItem onClick={this.toggleOpenRoleActivationModal} className="text-primary">
                            <i
                                className="fa fa-fw fa-retweet mr-2" aria-hidden="true" />Change role
                        </DropdownItem>
                        <DropdownItem tag={Link} to={"/"} className="text-primary" onClick={this.logout}>
                            <i className="fa fa-fw fa-power-off mr-2" aria-hidden="true" />
                                Logout
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <RoleActivation
                    isOpen={this.state.openRoleActivationModal}
                    toggleModel={this.toggleOpenRoleActivationModal}
                />
            </div>


        );
    }
}

