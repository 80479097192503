import axios from '../axios';

class CourierService {

    static Instance() {
        return new CourierService();
    }

    getCouriers() {
        return axios.get('api/couriers/list');
    }

    getCourier(id) {
        return axios.get('api/couriers/one?id=' + id);
    }

    getDebtorCourier(accountID) {
        return axios.get('api/couriers/one/byDebtor?accountID=' + accountID);
    }

    saveCourier(request) {
        return axios.post('api/couriers/save', request);
    }

    deleteCourier(courierID) {
        return axios.delete('api/couriers/delete?courierID=' + courierID);
    }

}

export default CourierService.Instance();