import axios from "../axios";

class PurchaseInvoiceService {

    static Instance() {
        return new PurchaseInvoiceService();
    }

    listPurchaseInvoice(req) {
        return axios.post('api/keyway/purchase/invoice/list', req);
    }

    listPurchaseInvoiceItems(req) {
        return axios.post('api/keyway/purchase/invoice/item/list', req);
    }

    getOnePurchaseInvoice(purchaseInvoiceID) {
        return axios.get('api/keyway/purchase/invoice/one/?PurchaseInvoiceID=' + purchaseInvoiceID);
    }

    saveOrUpdatePurchaseInvoice(req) {
        return axios.post('api/keyway/purchase/invoice/save', req);
    }

    getLastInvNum() {
        return axios.get('api/keyway/purchase/invoice/invNum/last')
    }

    getPurchaseInvoicesByStock(prodCode) {
        return axios.get('api/keyway/purchase/invoice/stock/list?prodCode=' + prodCode);
    }

    getMonthlyStockPurchases(stockId,years) {
        return axios.get('api/keyway/purchase/invoice/monthly-purchases/lineChart?stockId=' + stockId + '&years=' + years);
    }

    getMonthlyStockPurchasesValue(stockId, years) {
        return axios.get('api/keyway/purchase/invoice/monthly-purchases-value/lineChart?stockId=' + stockId + '&years=' + years);
    }
}

export default PurchaseInvoiceService.Instance();