import axios from '../axios';
import axiosFileDownloader from '../axiosFileDownloader';

class ProductionDescheduleService {


    static Instance() {
        return new ProductionDescheduleService();
    }

    getProductionDeschedule() {
        return axios.post('api/production/deschedule/list');
    }

    getProductionDescheduleReasons() {
        return axios.post('api/production/deschedule/reasons/list');
    }

    descheduleOrder(request) {
        return axios.post('api/production/schedule/deschedule/order', request);
    }

    scheduleOrder(request) {
        return axios.post('api/production/deschedule/schedule/order', request);
    }

    getProductionLines() {
        return axios.post('api/production/lines/list');
    }

    downlaodProductionScheduleReport(type) {
        return axiosFileDownloader.get('api/production/deschedule/report/download?type='+type);
    }

    getDebtorInvoicePartList(request) {
        return axios.post('api/production/deschedule/order/part/list', request);
    }

    getStore() {
        return [
            {
                key: "ordNum",
                label: "Order No.",
                type: "orderNumLink"
            }, {
                key: "productionLineID",
                label: "Production Line",
                type: "productionLineID"
            }, {
                key: "fabric",
                label: "Fabric",
                type: "default"
            }, {
                key: "qty",
                label: "Qty",
                type: "number"
            }, {
                key: "itemCount",
                label: "Item Count",
                type: "number"
            }, {
                key: "isMultiProductOrder",
                label: "Multi-Product",
                type: "boolean"
            }, {
                key: "deScheduleReasonId",
                label: "Reason",
                type: "deScheduleReasonId"
            }, {
                key: "followUpDate",
                label: "Follow-Up Date",
                type: "utcDateString"
            }, {
                key: "notes",
                label: "Notes",
                type: "default"
            }, {
                key: "scheduleOn",
                label: "Schedule",
                type: "scheduleOn"
            }
        ];
    }

    getOOSManagerList() {
        return axios.post('api/production/deschedule/oosManager/list');
    }
}

export default ProductionDescheduleService.Instance();