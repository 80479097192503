import axios from "./axios";
class CurrencyService{
    static Instance(){
        return new CurrencyService();
    }
    getCurrencyList(req)
    {
        return axios.post('api/currency/list',req)
    }
    getCurrency()
    {
        return axios.get('api/currency/get/all')
    }

    getCurrencyPI()
    {
        return axios.get('api/currency/get/selected')
    }

    getCurrencies(request)
    {
        return axios.post('api/currency/list/all', request);
    }

    getSelectedCurrency(currencyType)
    {
        return axios.post('api/currency/get/currency', currencyType);
    }

    deleteCurrency(currencyType)
    {
        return axios.delete('api/currency/delete?currencyType=' + currencyType);
    }

    saveCurrency(request)
    {
        return axios.post('api/currency/save', request);
    }

    getCurrencyDropdown() {
        return axios.get('api/currency/get/dropdown');
    }
}
export default CurrencyService.Instance();