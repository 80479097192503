import axios from '../axios';
import queryString from 'query-string';

class StockReceiptService {

    static Instance() {
        return new StockReceiptService();
    }

    getStockReceiptIDByNavigation(navigation, currentStockReceiptID) {
        return axios.get('api/stock/receipt/stockReceiptID?' + queryString.stringify({
            navigation: navigation,
            currentStockReceiptID: currentStockReceiptID
        }));
    }

    getStockReceipt(stockReceiptID) {
        return axios.get('api/stock/receipt/one?stockReceiptID=' + stockReceiptID);
    }

    getStockReceipts(searchText, accountID) {
        return axios.get('api/stock/receipt/list?' + queryString.stringify({
            accountID: accountID,
            searchText: searchText
        }));
    }

    getLastStockReceipt(purchaseOrdNum) {
        return axios.get('api/stock/receipt/one/last?purchaseOrdNum=' + purchaseOrdNum);
    }

    getStockReceiptDocuments(stockReceiptID) {
        return axios.get('api/stock/receipt/document/list?stockReceiptID=' + stockReceiptID,);
    }

    addStockReceiptDocuments(request) {
        return axios.post('api/stock/receipt/document/add', request);
    }

    getStatusColor(statusID){
        let color = "warning";
        switch (statusID){
            case -1:
                color = "danger";
                break;
            case 0:
                color = "warning";
                break;
            case 1:
                color = "success";
                break;
        }
        return color;
    }

    getStockReceiptList(request) {
        return axios.post('api/stock/receipt/master/list', request);
    }
}

export default StockReceiptService.Instance();