import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Input, InputGroup, InputGroupAddon, InputGroupText,
    FormText
} from 'reactstrap';
import classnames from 'classnames';

import SearchDebtorAcccount from '../search/SearchDebtorAcccount';

function FormError(props) {
    let item = props.item;
    switch (props.attribute) {
        case "accountID":
            if (!item.accountID) {
                return (<FormText color="danger">Please select Customer</FormText>)
            }
            break;
        case "productID":
            if (!item.productID) {
                return (<FormText color="danger">Please select Product</FormText>)
            }
            break;
        case "charge":
            if (!(item.charge > -1)) {
                return (<FormText color="danger">Charge must be not negative</FormText>)
            }
            break;
    }
    return null;
}

export default class PackagingHandlingChargesRuleFormModal extends Component {

    validateAndSubmit(form, ruleType) {
        let flag = true;
        if (ruleType === 'customer') {
            if (!form.accountID) {
                flag = false;
            }
        }
        if (!form.charge) {
            flag = false;
        }
        if (!form.productID) {
            flag = false;
        }
        if (flag) {
            this.props.handleSubmit(form);
        }
    }


    render() {
        let {isOpen, toggle, loading, handleChange, products, form, ruleType} = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Create
                </ModalHeader>
                <ModalBody>
                    <div>
                        {
                            ruleType === 'customer'
                                ? <div>AccountID*
                                    <div>
                                        <SearchDebtorAcccount
                                            handleAccountChange={(accountID) => handleChange(accountID, "accountID")}
                                            selectedAccountID={form.accountID}
                                            defaultAccountID={form.accountID}
                                            includeChildren={true}
                                            excludeClosedandInactive={false} />
                                    </div>
                                    <FormError attribute={"accountID"} item={form}/>
                                </div>
                                : null
                        }

                        <div className={"mt-2"}>
                            Product*
                            <Input type={"select"}
                                   placeholder={"Product"}
                                   name={"productID"}
                                   value={form.productID ? form.productID : ""}
                                   onChange={(e) => handleChange(e.target.value, "productID")}>
                                <option value={""}>Select</option>
                                {
                                    products.map((option, index) =>
                                        <option key={index} value={option.id}>{option.name}</option>
                                    )
                                }
                            </Input>
                            <FormError attribute={"productID"} item={form}/>
                        </div>

                        <div className={"mt-2"}>
                            Charges*
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input type={"text"}
                                       placeholder={"charge"}
                                       name={"charge"}
                                       value={form.charge}
                                       onChange={(e) => handleChange(e.target.value, "charge")}/>
                            </InputGroup>
                            <FormError attribute={"charge"} item={form}/>
                        </div>
                        <div className={"mt-3"}>
                            <div>
                                <span>
                                    <a href={"javascript:void(0)"}
                                       onClick={() => handleChange(!form.isActive, "isActive")}>
                                        <i className={classnames("fa", "fa-lg", {
                                                "fa-check-square-o": form.isActive,
                                                "fa-square-o": !form.isActive,
                                            }
                                        )}/>
                                    </a><span className={"ml-2"}>Active</span>
                                </span>
                                <span className={"ml-4"}>
                                    <a href={"javascript:void(0)"}
                                       onClick={() => handleChange(!form.isActive, "isActive")}>
                                        <i className={classnames("fa", "fa-lg", {
                                                "fa-check-square-o": !form.isActive,
                                                "fa-square-o": form.isActive,
                                            }
                                        )}/>
                                    </a><span className={"ml-2"}>Not-Active</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} onClick={() => this.validateAndSubmit(form, ruleType)}
                                disabled={loading}>{loading ? "Saving" : "Save"}</Button>&nbsp;&nbsp;
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}