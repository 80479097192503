import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Row, Button, Spinner, Table, Card, CardHeader, CardBody} from "reactstrap";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import StockInventoryTransactionTypeService from '../../services/StockInventoryTransactionTypeService';
import {handleErrorMessage} from "../../services/CommonService";


export default class StockInventoryTransactionTypePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StockInventoryTransactionType: [],
            loading: true,
            saving: false
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        StockInventoryTransactionTypeService.getAllStockInventoryTransactionType().then(response => {
            this.setState({ StockInventoryTransactionType: response.data })
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getHeadings() {
        let headings = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 2,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                    to={{
                        pathname: '/inventory/stockInventoryTransactionType/manage', state: { name: "" }
                    }}><i className="fa fa-plus" />&nbsp;
                            Add </Link></div>

            }
        ]
        return headings;
    }

    handleDelete(name) {
        if (window.confirm('Are you sure you want to delete it ?')) {
            StockInventoryTransactionTypeService.deleteStockInventoryTransactionType(name).then(response => {
                this.setState({ saving: false });
                toast.success("Stock inventory transaction type deleted successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.refresh();
            }).catch(error => {
                console.log(error);
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
        }
    }

    render() {
        let { StockInventoryTransactionType } = this.state;
        let headings = this.getHeadings();
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Inventory Transaction Types</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader><h5>Stock Inventory Transaction Types</h5></CardHeader>
                    <CardBody>
                        <div className="text-right m-1">
                            <Link className="btn btn-primary btn-sm"
                                to={{
                                    pathname: '/inventory/stockInventoryTransactionType/manage', state: { name: "" }
                                }}><i className="fa fa-plus" />&nbsp;
                            Add </Link>
                        </div>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(headings || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                   
                                </thead>
                                <tbody>
                                    {(StockInventoryTransactionType || []).map((item, index) =>
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                to={"/inventory/stockInventoryTransactionType/manage?" + queryString.stringify({ name: item.name })}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                                Edit</Link></td>
                                            <td className="text-center"><Button className="btn btn-sm" color="danger" onClick={() => { this.handleDelete(item.name) }}>
                                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                    style={{ color: "white" }} /> : <i className="fa fa-trash mr-2"
                                                        aria-hidden="true" />}
                                                Delete</Button>
                                                
                                                </td>
                                        </tr>
                                        )}
                                </tbody>
                                </Table>
                            </div>
                    </CardBody>
                    </Card>
            </div>
            )
    }
}