import React, {Component} from 'react';
import {
    Row, Col, FormGroup, Label, Input, Form,
    Button, FormText, Modal, ModalBody, ModalHeader, ModalFooter, Spinner
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import FaqService from "../../services/FaqService";
import {isEmpty} from 'lodash';

export default class AddFAQSuggestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CategoryTypes: ['Indoor', 'Outdoor'],
            SubCategoryTypes: [],
            IndoorTypes: ['Roller', 'Tracks', 'Venetians', 'Warranty','Motorisation'],
            OutdoorTypes: ['Ziptrack', 'Folding Arm Awnings', 'Warranty','Motorisation'],
            fileUpload: {
                primaryText: "",
                id: 0,
                secondaryText: "",
                youtubeLink: "",
                category: "",
                subCategory: "",
            },
            formErrors: {},

            isOpen: false,
            openCloseUploadModal: false,
            saving: false,
        };

        this.faqService = new FaqService();
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }


    handleChange(e) {
        const {name, value} = e.target;
        let {fileUpload, IndoorTypes, OutdoorTypes} = this.state;
        fileUpload[name] = value;
        this.setState({fileUpload});
        if (name === 'category' || name === 'CATEGORY') {
            if (value === 'Outdoor' || value.trim() === 'Outdoor') {
                this.setState({SubCategoryTypes: OutdoorTypes});
            } else {
                this.setState({SubCategoryTypes: IndoorTypes});
            }
        }
    }


    onClickHandler() {
        let model = {
            primaryText: this.state.fileUpload.primaryText,
            id: this.state.fileUpload.id,
            secondaryText: this.state.fileUpload.secondaryText,
            youtubeLink: this.state.fileUpload.youtubeLink,
            Category: this.state.fileUpload.category,
            SubCategory: this.state.fileUpload.subCategory,
            sequenceOrderNum: 0
        };
        let result = this.validateForm();
        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            toast.info("See form error for invalid input ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        this.setState({saving: true});
        this.faqService.addFAQSuggestion(model).then(response => {
            if (response.status === 200 || response.status ==='200') {
                let fileUpload = {
                    primaryText: "",
                    id: 0,
                    secondaryText: "",
                    youtubeLink: "",
                };
                let formErrors = {};
                this.setState({fileUpload, formErrors, isOpen: false, saving: false});
                this.props.toggleModal(false);
                toast.success("FAQ suggestion added successfully...", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            this.setState({saving: false});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    validateForm() {
        let formErrors = {};
        formErrors.primaryText = this.state.fileUpload.primaryText.trim().length > 0 ? "" : "Question Required";
        formErrors.secondaryText = this.state.fileUpload.secondaryText.trim().length > 0 ? "" : "Answer Required";
        formErrors.category = this.state.fileUpload.category.trim().length > 0 ? "" : "Please Select Category";
        formErrors.subCategory = this.state.fileUpload.subCategory.trim().length > 0 ? "" : "Please Select Sub Category";

        if (formErrors.primaryText
            || formErrors.secondaryText || formErrors.category || formErrors.subCategory) {
            return {valid: false, formErrors: formErrors};
        }
        return {valid: true, formErrors: formErrors};
    }

    handleCancel() {
        this.props.toggleModal(false);

    }


    render() {
        let {formErrors, saving} = this.state;
        let {isOpen, toggleModal} = this.props;

        return (
            <div>

                <Modal isOpen={isOpen} size="lg"
                       scrollable={true}
                       toggle={() => toggleModal(!isOpen)}>
                    <ModalHeader toggle={() => toggleModal(!isOpen)}>
                        Suggest an FAQ
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label>What's it all about ? (What do you want to suggest)</Label>
                                <Input
                                    type="textarea"
                                    name="primaryText"
                                    value={this.state.fileUpload.primaryText}
                                    onChange={this.handleChange}
                                    placeholder="Type your question here..."/>
                                <FormText
                                    color="danger">{formErrors.primaryText}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label>Suggest an answer</Label>
                                <Input
                                    type="textarea"
                                    name="secondaryText"
                                    value={this.state.fileUpload.secondaryText}
                                    onChange={this.handleChange}
                                    placeholder="Type your suggestion here..."/>
                                <FormText
                                    color="danger">{formErrors.secondaryText}</FormText>
                            </FormGroup>
                            <Row>

                                <Col lg={3} md={4} sm={6} xs={6}>
                                    <FormGroup>
                                        <Label>Category</Label>
                                        <Input className="form-control"
                                               type="select"
                                               value={this.state.fileUpload.category}
                                               onChange={(e) => this.handleChange(e)}
                                               name="category"
                                               placeholder="Select category here...">
                                            <option
                                                key={99}
                                                value={0}>Select Category
                                            </option>
                                            {this.state.CategoryTypes.map((item) => <option
                                                key={item}
                                                value={item}>{item}</option>)}
                                        </Input>
                                        <FormText
                                            color="danger">{formErrors.category}</FormText>
                                    </FormGroup>
                                </Col>

                                {
                                    ((!isEmpty(this.state.SubCategoryTypes)) && this.state.SubCategoryTypes.length > 0)
                                        ? <Col lg={4} md={5} sm={6} xs={6}>
                                            <FormGroup>
                                                <Label>Subcategory</Label>
                                                <Input className="form-control"
                                                       type="select"
                                                       value={this.state.fileUpload.subCategory}
                                                       onChange={(e) => this.handleChange(e)}
                                                       name="subCategory"
                                                       placeholder="Select subcategory here...">
                                                    <option
                                                        key={100}
                                                        value={0}>Select Subcategory
                                                    </option>
                                                    {this.state.SubCategoryTypes.map((item, indexx) => <option
                                                        key={indexx}
                                                        value={item}>{item}</option>)}
                                                </Input>
                                                <FormText
                                                    color="danger">{formErrors.subCategory}</FormText>
                                            </FormGroup>
                                        </Col>
                                        : null
                                }


                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <Label>Youtube Video Link</Label>
                                        <Input
                                            type="text"
                                            name="youtubeLink"
                                            value={this.state.fileUpload.youtubeLink}
                                            onChange={this.handleChange}
                                            placeholder="Paste Link here..."/>
                                        <FormText>For example - https://www.youtube.com/watch?v=something </FormText>
                                    </FormGroup>

                                </Col>
                                {
                                    this.state.fileUpload.youtubeLink
                                        ? <Col lg={6} md={6} sm={6} xs={12}>
                                            <a href={this.state.fileUpload.youtubeLink} target="_blank"><i
                                                className="fa fa-external-link mt-5" aria-hidden="true"/></a>
                                        </Col>
                                        : null
                                }

                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.onClickHandler} disabled={saving}>
                                {saving ? <Spinner size="sm" className="mr-2"
                                                   style={{color: "white"}}/> : null}
                                Save</Button>{' '}
                            <Button color="secondary" onClick={this.handleCancel}>Cancel</Button>
                        </div>
                    </ModalFooter>
                </Modal>


                <ToastContainer/>

            </div>
        );
    }
}