import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/app/app.css';
import './assets/css/app/shadow.css';
import './assets/css/app/custom.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import history from "../src/helpers/history";

class Root extends React.Component {

    render() {
        return (
            <Router >
                <Switch>
                    <App history={history} />
                </Switch>
            </Router>
        )
    }
}


ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
