import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Spinner,
    Progress
} from 'reactstrap';
import { toast } from 'react-toastify';
import FabricService from '../../services/FabricService';
import {handleErrorMessage} from "../../services/CommonService";

export default class DeleteFabricModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
        this.deleteFabric = this.deleteFabric.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
       

    }

    deleteFabric() {
        this.setState({ loading: true })
        FabricService.DeleteFabric(this.props.disableFabric.id).then(response => {
            if (response.status === '200' || response.status === 200) {
                this.setState({ loading: false });
                toast.success('Fabric deleted successfully', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.handlerCloseModal(false);
                this.props.handleRefresh();
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleCancel() {
        this.props.handlerCloseModal(false);
    }


    render() {
        let { isOpen, toggle } = this.props;
        
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                   Are you sure want to delete fabric
                </ModalHeader>
                <ModalBody>
                    <div className="text-right">
                        <Button color={"primary"} onClick={this.deleteFabric} disabled={this.state.loading}>
                            {this.state.loading
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            Delete</Button>
                        <Button color={"secondary"} className="ml-2" onClick={this.handleCancel}><i className="fa fa-times" />&nbsp;
                            Cancel</Button>

                    </div>

                </ModalBody>

            </Modal>
        )
    }
}