import React, { Component } from 'react';
import NumberFormat from "react-number-format";
import { changeFormatOfDateString, updateQtyUnitFormat } from '../../../../../../services/CommonService';
import { Button } from 'reactstrap';

export let warehousePickLabelStore = [
    {
        key: "prodCode",
        label: "Item Code",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        showColumn: "",
        valueClassName: "align-middle text-center text-nowrap",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data[this.key] ?
                <a href={"/inventory/stock/enquiry?prodCode=" + data[this.key]} target="_blank" className="btn btn-sm btn-primary" title="Click here to open its details">
                    {data[this.key]}
                </a >
                : null
        }
    },
    {
        key: "description",
        label: "Description",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        showColumn: "",
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data[this.key];
        }
    },
    {
        key: "qty",
        label: "Qty",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: false,
        showColumn: "",
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data[this.key]}
                displayType={'text'}
                decimalScale={8}
                fixedDecimalScale={false}
                thousandSeparator={true}
                suffix={(data["eachUnitName"] && data["eachUnitName"] != "0") ? updateQtyUnitFormat(data[this.key], data["eachUnitName"]) : null}
            />
        }
    },
    {
        key: "qtyOfPacks",
        label: "WH Pack Qty",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: false,
        showColumn: "",
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return (!["", "0", 0, 1, null, undefined].includes(data["whUnitQty"]) && !["", "0", 0, null, undefined].includes(data["whUnitName"])) ? (parseFloat((data["qty"] / data["whUnitQty"]).toFixed(4)) + updateQtyUnitFormat((data["qty"] / data["whUnitQty"]), data["whUnitName"]) + " of " + data["whUnitQty"] + updateQtyUnitFormat(data["whUnitQty"], data["eachUnitName"])) : "N/A";
        }
    },
    {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        showColumn: "allParts",
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data[this.key] ?
                <Button color={"link"} style={{ textDecoration: "none" }} onClick={() => componentRef.props.handleClickedItem(data)}>{data[this.key]}</Button>
                : null;
        }
    },
    {
        key: "qoh",
        label: "QOH",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: false,
        showColumn: "allParts",
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data[this.key]}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={false}
                thousandSeparator={true}
                suffix={(data["eachUnitName"] && data["eachUnitName"] != "0") ? updateQtyUnitFormat(data[this.key], data["eachUnitName"]) : null}
            />
        }
    },
    {
        key: "etaDate",
        label: "Next PO ETA",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        showColumn: "allParts",
        valueClassName: "align-middle text-center text-nowrap",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <>
                {(!data["hasPO"] && data["free"] < 0) ?
                    <NumberFormat
                        prefix="Free: -"
                        value={Math.abs(data["free"])}
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        thousandSeparator={true}
                        suffix=" | No PO ETA"
                        className="text-danger"
                    />
                    :
                    <>
                        {data["poNum"] ?
                            <a href={"/purchase/order/enquiry?ordNum=" + data["poNum"]} target="_blank" className="btn btn-sm btn-primary" title="Click here to open this PO">
                                {data["poNum"]}
                            </a>
                            : null}
                        {data[this.key] ?
                            <span className="ml-1">{changeFormatOfDateString(data[this.key], "YYYY-MM-DD hh:mm:ss", "DD MMM YYYY")}</span>
                            : null}
                    </>}
            </>
        }
    },
    {
        key: "price",
        label: "Price",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: false,
        showColumn: "partsByProductItem",
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data[this.key]}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    },
    {
        key: "discVal",
        label: "Discount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: false,
        showColumn: "partsByProductItem",
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data[this.key]}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    },
    {
        key: "orderedExtendedEx",
        label: "Total",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: false,
        showColumn: "partsByProductItem",
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data[this.key]}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    }

];