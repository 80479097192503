import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../../services/CommonService';
import {cloneDeep, isEmpty, camelCase} from 'lodash';
import classnames from 'classnames';

import projectionAwningUtil from './ProjectionAwningUtil';
import NumberFormat from "react-number-format";
import ProjectionAwningBOMModal from "./ProjectionAwningBOMModal";
import ProjectionAwningConsolidatedBOMModal from "./ProjectionAwningConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../custom/CustomUtil";
import {
    PRODUCT_BUILDER_CUSTOM_BUILD,
    PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE
} from "../../../../../../store/AppConstants";

export default class ProjectionAwning extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = projectionAwningUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = projectionAwningUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                    product = projectionAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if (stocksByProdCode) {
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                        }
                        if (stock) {
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = projectionAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = projectionAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;


        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemProjectionAwning.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};


        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {
            value: {},
            dropdownValue: ""
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemProjectionAwning.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 1200;// default
        product.items[itemIndex].configuration.width.max = 3960;// default
        product.items[itemIndex].configuration.width.selected = {
            value: {},
            dropdownValue: ""
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = context.salesOrderItemProjectionAwning.width;
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1500;
        }
        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.min = 900;// default
        product.items[itemIndex].configuration.drop.max = 2400;// default
        product.items[itemIndex].configuration.drop.selected = {
            value: {},
            dropdownValue: ""
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = context.salesOrderItemProjectionAwning.drop;
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }
        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };


        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.model);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
            product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};


        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.operation.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options.filter(o => o.condition2 === "dropdown");
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.operation);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.operation.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
            product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.operation.formError = {isValid: true, message: "", dom: null,};

        //controlSide
        configurationIndex = findIndex(configurations, "attributeKey", "controlSide");
        product.items[itemIndex].configuration.controlSide = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.controlSide.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].configuration.controlSide.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.controlSide);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
            product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.controlSide.formError = {isValid: true, message: "", dom: null,};


        //cordColour
        configurationIndex = findIndex(configurations, "attributeKey", "cordColour");
        product.items[itemIndex].configuration.cordColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.cordColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.cordColour.finalOptions = product.items[itemIndex].configuration.cordColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.cordColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.cordColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.cordColour.selected.value = product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.cordColour.selected.dropdownValue = product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.cordColour.formError = {isValid: true, message: "", dom: null,};


        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemProjectionAwning.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.fabricType.selected.isSelectable = true;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemProjectionAwning.fabricType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
        }


        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true
        };
        product.items[itemIndex].configuration.fabricColour.options = [];
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
            product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                return {
                    optionKey: o.color,
                    optionLabel: salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                    ...o
                }

            });
        }
        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
            )
        });
        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
            optionIndex = -1;
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.fabricColour);
            }
            if (optionIndex === -1) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                    optionIndex = 0;
                }
            }
            if (optionIndex > -1) {
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex])) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
            }
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};


        //fabricColourOffRange
        product.items[itemIndex].configuration.fabricColourOffRange = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = context.salesOrderItemProjectionAwning.fabricColourOffRange;
        } else {
            product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
        }
        product.items[itemIndex].configuration.fabricColourOffRange.formError = {isValid: true, message: "", dom: null};


        //valance
        configurationIndex = findIndex(configurations, "attributeKey", "valance");
        product.items[itemIndex].configuration.valance = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.valance.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.valance);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
            product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.valance.formError = {isValid: true, message: "", dom: null,};


        //bracketColour
        configurationIndex = findIndex(configurations, "attributeKey", "bracketColour");
        product.items[itemIndex].configuration.bracketColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bracketColour.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bracketColour.options = getSorted(product.items[itemIndex].configuration.bracketColour.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.bracketColour.finalOptions = product.items[itemIndex].configuration.bracketColour.options.filter(o => o.condition1 === "dropdown");
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bracketColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.bracketColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.bracketColour.selected.value = product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.bracketColour.formError = {isValid: true, message: "", dom: null,};


        //bracketColourPowdercoat
        product.items[itemIndex].configuration.bracketColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = context.salesOrderItemProjectionAwning.bracketColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.bracketColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //hooding
        configurationIndex = findIndex(configurations, "attributeKey", "hooding");
        product.items[itemIndex].configuration.hooding = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hooding.options = getSorted(product.items[itemIndex].configuration.hooding.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.hooding.finalOptions = product.items[itemIndex].configuration.hooding.options.filter(o => o.condition1 === "dropdown");
        product.items[itemIndex].configuration.hooding.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.hooding);
            if (optionIndex === -1) {
                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, 'optionKey', "Mitjavila Hood (Alum)");
            }
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.hooding.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
            product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.hooding.formError = {isValid: true, message: "", dom: null,};


        //hoodingColour
        configurationIndex = findIndex(configurations, "attributeKey", "hoodingColour");
        product.items[itemIndex].configuration.hoodingColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.hoodingColour.options = getSorted(product.items[itemIndex].configuration.hoodingColour.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.hoodingColour.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.hoodingColour.finalOptions = product.items[itemIndex].configuration.hoodingColour.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.model.selected.value.optionKey));
        product.items[itemIndex].configuration.hoodingColour.selected = {
            isSelectable: false,
            value: {},
            dropdownValue: ""
        };
        optionIndex = -1;
        if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.finalOptions)) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.hoodingColour);
                if (optionIndex === -1) {
                    optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.hooding);
                }
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        }
        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = true;
        if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.hoodingColour.formError = {isValid: true, message: "", dom: null,};


        //hoodingColourPowdercoat
        product.items[itemIndex].configuration.hoodingColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = context.salesOrderItemProjectionAwning.hoodingColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.hoodingColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.mount);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.mount.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
            product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};


        //channelType
        configurationIndex = findIndex(configurations, "attributeKey", "channelType");
        product.items[itemIndex].configuration.channelType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channelType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.channelType.finalOptions = product.items[itemIndex].configuration.channelType.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channelType.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.channelType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.channelType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.channelType.selected.value = product.items[itemIndex].configuration.channelType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.channelType.selected.dropdownValue = product.items[itemIndex].configuration.channelType.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.channelType.formError = {isValid: true, message: "", dom: null,};


        //channelColour
        configurationIndex = findIndex(configurations, "attributeKey", "channelColour");
        product.items[itemIndex].configuration.channelColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channelColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.channelColour.finalOptions = product.items[itemIndex].configuration.channelColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channelColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.channelColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.channelColour.formError = {isValid: true, message: "", dom: null,};


        //channelSize
        configurationIndex = findIndex(configurations, "attributeKey", "channelSize");
        product.items[itemIndex].configuration.channelSize = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channelSize.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.channelSize.finalOptions = product.items[itemIndex].configuration.channelSize.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channelSize.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.channelSize);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.channelSize.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.channelSize.selected.value = product.items[itemIndex].configuration.channelSize.finalOptions[optionIndex];
            product.items[itemIndex].configuration.channelSize.selected.dropdownValue = product.items[itemIndex].configuration.channelSize.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.channelSize.formError = {isValid: true, message: "", dom: null,};


        //standOutArmType
        configurationIndex = findIndex(configurations, "attributeKey", "standOutArmType");
        product.items[itemIndex].configuration.standOutArmType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.standOutArmType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.standOutArmType.finalOptions = product.items[itemIndex].configuration.standOutArmType.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.standOutArmType.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.standOutArmType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.standOutArmType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.standOutArmType.selected.value = product.items[itemIndex].configuration.standOutArmType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.standOutArmType.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmType.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.standOutArmType.formError = {isValid: true, message: "", dom: null,};


        //standOutArmColour
        configurationIndex = findIndex(configurations, "attributeKey", "standOutArmColour");
        product.items[itemIndex].configuration.standOutArmColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.standOutArmColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.standOutArmColour.finalOptions = product.items[itemIndex].configuration.standOutArmColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.standOutArmColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.standOutArmColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.standOutArmColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.standOutArmColour.selected.value = product.items[itemIndex].configuration.standOutArmColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.standOutArmColour.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.standOutArmColour.formError = {isValid: true, message: "", dom: null,};

        //standOutArmColourPowdercoat
        product.items[itemIndex].configuration.standOutArmColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.value = context.salesOrderItemProjectionAwning.standOutArmColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.standOutArmColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        //standOutArmSize
        configurationIndex = findIndex(configurations, "attributeKey", "standOutArmSize");
        product.items[itemIndex].configuration.standOutArmSize = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.standOutArmSize.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.standOutArmSize.finalOptions = product.items[itemIndex].configuration.standOutArmSize.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.standOutArmSize.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.standOutArmSize);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.standOutArmSize.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.standOutArmSize.selected.value = product.items[itemIndex].configuration.standOutArmSize.finalOptions[optionIndex];
            product.items[itemIndex].configuration.standOutArmSize.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmSize.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.standOutArmSize.formError = {isValid: true, message: "", dom: null,};


        //bottomRailType
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailType");
        product.items[itemIndex].configuration.bottomRailType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRailType.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bottomRailType.finalOptions = product.items[itemIndex].configuration.bottomRailType.options.filter(o => o.condition1 === "dropdown");
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailType.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.bottomRailType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.bottomRailType.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.bottomRailType.selected.value = product.items[itemIndex].configuration.bottomRailType.finalOptions[optionIndex];
            product.items[itemIndex].configuration.bottomRailType.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.bottomRailType.formError = {isValid: true, message: "", dom: null,};


        //bottomRailColour
        configurationIndex = findIndex(configurations, "attributeKey", "bottomRailColour");
        product.items[itemIndex].configuration.bottomRailColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomRailColour.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.bottomRailColour.finalOptions = product.items[itemIndex].configuration.bottomRailColour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailColour.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.bottomRailColour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.bottomRailColour.formError = {isValid: true, message: "", dom: null,};

        //bottomRailColourPowdercoat
        product.items[itemIndex].configuration.bottomRailColourPowdercoat = {selected: {isSelectable: false}};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = context.salesOrderItemProjectionAwning.bottomRailColourPowdercoat;
        } else {
            product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = "";
        }
        product.items[itemIndex].configuration.bottomRailColourPowdercoat.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //crankHandle
        configurationIndex = findIndex(configurations, "attributeKey", "crankHandle");
        product.items[itemIndex].configuration.crankHandle = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.crankHandle.selected = {
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.crankHandle.options = getSorted(product.items[itemIndex].configuration.crankHandle.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.crankHandle.finalOptions = product.items[itemIndex].configuration.crankHandle.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.crankHandle);
        }
        optionIndex = optionIndex > -1 ? optionIndex : findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, 'optionKey', "1600mm White");
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        if (!isEmpty(product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex])) {
            product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
            product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.crankHandle.formError = {isValid: true, message: "", dom: null,};


        //winchAndCord
        configurationIndex = findIndex(configurations, "attributeKey", "winchAndCord");
        product.items[itemIndex].configuration.winchAndCord = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.winchAndCord.finalOptions = product.items[itemIndex].configuration.winchAndCord.options;
        product.items[itemIndex].configuration.winchAndCord.selected = {
            value: false,
            dropdownValue: "",
            isSelectable: false
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.winchAndCord.selected.value = !!context.salesOrderItemProjectionAwning.winchAndCord;
            product.items[itemIndex].configuration.winchAndCord.selected.dropdownValue = context.salesOrderItemProjectionAwning.winchAndCord;
        }

        //remote
        configurationIndex = findIndex(configurations, "attributeKey", "remote");
        product.items[itemIndex].configuration.remote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.remote.finalOptions = product.items[itemIndex].configuration.remote.options;
        product.items[itemIndex].configuration.remote.selected = {
            value: false,
            dropdownValue: "",
            isSelectable: false
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.remote.selected.value = !!context.salesOrderItemProjectionAwning.remote;
            product.items[itemIndex].configuration.remote.selected.dropdownValue = context.salesOrderItemProjectionAwning.remote;
        }


        //charger
        configurationIndex = findIndex(configurations, "attributeKey", "charger");
        product.items[itemIndex].configuration.charger = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.charger.finalOptions = product.items[itemIndex].configuration.charger.options;
        product.items[itemIndex].configuration.charger.selected = {
            value: false,
            dropdownValue: "",
            isSelectable: false
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.charger.selected.value = !!context.salesOrderItemProjectionAwning.charger;
            product.items[itemIndex].configuration.charger.selected.dropdownValue = context.salesOrderItemProjectionAwning.charger;
        }

        //bridge
        configurationIndex = findIndex(configurations, "attributeKey", "bridge");
        product.items[itemIndex].configuration.bridge = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bridge.finalOptions = product.items[itemIndex].configuration.bridge.options;
        product.items[itemIndex].configuration.bridge.selected = {
            value: false,
            dropdownValue: "",
            isSelectable: false
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.bridge.selected.value = !!context.salesOrderItemProjectionAwning.bridge;
            product.items[itemIndex].configuration.bridge.selected.dropdownValue = context.salesOrderItemProjectionAwning.bridge;
        }

        //windSensor
        configurationIndex = findIndex(configurations, "attributeKey", "windSensor");
        product.items[itemIndex].configuration.windSensor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windSensor.finalOptions = product.items[itemIndex].configuration.windSensor.options;
        product.items[itemIndex].configuration.windSensor.selected = {
            value: {},
            dropdownValue: "",
            isSelectable: false
        };
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.windSensor.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.windSensor);
            if (optionIndex > -1) {
                if (!isEmpty(product.items[itemIndex].configuration.windSensor.finalOptions)) {
                    product.items[itemIndex].configuration.windSensor.selected.value = product.items[itemIndex].configuration.windSensor.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.windSensor.selected.dropdownValue = product.items[itemIndex].configuration.windSensor.selected.value.optionKey;
                }
            }
            else {
                product.items[itemIndex].configuration.windSensor.selected.value = {};
                product.items[itemIndex].configuration.windSensor.selected.dropdownValue = context.salesOrderItemProjectionAwning.windSensor;
            }
        }

        //keyway
        configurationIndex = findIndex(configurations, "attributeKey", "keyway");
        product.items[itemIndex].configuration.keyway = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.keyway.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.keyway.finalOptions = product.items[itemIndex].configuration.keyway.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.keyway.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.keyway);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.keyway.selected.value = product.items[itemIndex].configuration.keyway.finalOptions[optionIndex];
        product.items[itemIndex].configuration.keyway.selected.dropdownValue = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
        product.items[itemIndex].configuration.keyway.formError = {isValid: true, message: "", dom: null,};

        //spline
        configurationIndex = findIndex(configurations, "attributeKey", "spline");
        product.items[itemIndex].configuration.spline = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.spline.selected = {
            isSelectable: true,
            value: {},
            dropdownValue: ""
        };
        product.items[itemIndex].configuration.spline.finalOptions = product.items[itemIndex].configuration.spline.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.spline.finalOptions, 'optionKey', context.salesOrderItemProjectionAwning.spline);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.spline.selected.value = product.items[itemIndex].configuration.spline.finalOptions[optionIndex];
        product.items[itemIndex].configuration.spline.selected.dropdownValue = product.items[itemIndex].configuration.spline.selected.value.optionKey;
        product.items[itemIndex].configuration.spline.formError = {isValid: true, message: "", dom: null,};


        product = projectionAwningUtil.updateRules("location", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("width", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("drop", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
        product = projectionAwningUtil.updateRules("controlSide", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, order, true);
        product = projectionAwningUtil.updateRules("cordColour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("cordColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);

        product = this.updateItemConfigurationOptions("hooding", product, itemIndex, order, true);
        product = projectionAwningUtil.updateRules("hoodingColour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
        product = projectionAwningUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("bracketColour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, order, true);
        product = projectionAwningUtil.updateRules("bracketColourPowdercoat", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("mount", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("channelType", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("channelSize", product, itemIndex, order, false);
        product = this.updateItemConfigurationOptions("standOutArmType", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("standOutArmColour", product, itemIndex, order, true);
        product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("bottomRailType", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("bottomRailColour", product, itemIndex, order, true);
        product = projectionAwningUtil.updateRules("bottomRailColourPowdercoat", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);
        product = this.updateItemConfigurationOptions("windSensor", product, itemIndex, order, false);
        product = projectionAwningUtil.updateRules("crankHandle", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("winchAndCord", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("remote", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("charger", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("bridge", product, itemIndex, order);
        product = projectionAwningUtil.updateRules("windSensor", product, itemIndex, order);
        product = projectionAwningUtil.updateFormError("location", product, itemIndex);
        product = projectionAwningUtil.updateFormError("width", product, itemIndex);
        product = projectionAwningUtil.updateFormError("drop", product, itemIndex);
        product = projectionAwningUtil.updateFormError("fabricColourOffRange", product, itemIndex);
        product = projectionAwningUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
        product = projectionAwningUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
        product = projectionAwningUtil.updateFormError("standOutArmColourPowdercoat", product, itemIndex);
        product = projectionAwningUtil.updateFormError("bottomRailColourPowdercoat", product, itemIndex);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let {order, currentUser} = this.props;

        let optionIndex = -1,
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = product.items[itemIndex].configuration.drop.selected.value,
            condition1, condition2, condition3;

        if (key) {
            switch (key) {
                case "fabricColour":
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map(o => {
                            return {
                                optionKey: o.color,
                                optionLabel: salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                                ...o
                            }
                        });
                        if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                            // no fabric color options if no fabric range
                            product.items[itemIndex].configuration.fabricColour.finalOptions = [];
                        } else {
                            product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
                                return (
                                    salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                                )
                            });
                        }
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricColour.selected.value = null;
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                    }
                    break;
                case "operation":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options.filter(o =>
                        (o.condition2 === "dropdown")
                        && (o.condition1 ? o.condition1.includes(condition1) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.operation.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', product.items[itemIndex].configuration.operation.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.operation.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.operation.selected.value = {};
                        product.items[itemIndex].configuration.operation.selected.dropdownValue = "";
                    }
                    break;
                case "bracketColour":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.bracketColour.selected.isSelectable) {
                        condition1 = "dropdown";
                        condition2 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                        condition3 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                        product.items[itemIndex].configuration.bracketColour.finalOptions = product.items[itemIndex].configuration.bracketColour.options.filter(o =>
                            (o.condition1 ? o.condition1.includes(condition1) : true)
                            && (o.condition2 ? o.condition2.includes(condition2) : true)
                            && (o.condition3 ? o.condition3.includes(condition3) : true)
                        );
                        if (!isEmpty(product.items[itemIndex].configuration.bracketColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.bracketColour.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.bracketColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bracketColour.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex]) {
                        product.items[itemIndex].configuration.bracketColour.selected.value = product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.bracketColour.selected.value = null;
                        product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = "";
                    }
                    break;
                case "controlSide":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.controlSide.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.controlSide.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.controlSide.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.controlSide.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.controlSide.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.controlSide.selected.value = null;
                        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = "";
                    }
                    break;
                case "cordColour":
                    optionIndex = -1;
                    if (product.items[itemIndex].configuration.cordColour.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.cordColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.cordColour.selected.value)) {
                                //update selection, getting ideal selection
                                optionIndex = product.items[itemIndex].configuration.cordColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.cordColour.selected.value.optionKey);
                            }
                            //if couldn't get first item
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.cordColour.selected.value = product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.cordColour.selected.dropdownValue = product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.cordColour.selected.value = null;
                        product.items[itemIndex].configuration.cordColour.selected.dropdownValue = "";
                    }
                    break;
                case "valance":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.valance.finalOptions = product.items[itemIndex].configuration.valance.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                    if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions)) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, 'optionKey', product.items[itemIndex].configuration.valance.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.valance.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.valance.selected.value = {};
                        product.items[itemIndex].configuration.valance.selected.dropdownValue = "";
                    }
                    break;
                case "hooding":
                    optionIndex = -1;
                    condition2 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.hooding.finalOptions = product.items[itemIndex].configuration.hooding.options.filter(o =>
                        (o.condition1 === "dropdown")
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );
                    if (product.items[itemIndex].configuration.hooding.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.hooding.finalOptions)) {
                            optionIndex = -1;
                            if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, 'optionKey', product.items[itemIndex].configuration.hooding.selected.value.optionKey);
                            }
                            if (optionIndex === -1) {
                                if (!isEmpty(product.items[itemIndex].configuration.hooding.finalOptions)) {
                                    optionIndex = 0;
                                }
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.hooding.selected.value = {};
                        product.items[itemIndex].configuration.hooding.selected.dropdownValue = "";
                    }
                    break;
                case "hoodingColour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.hoodingColour.finalOptions = product.items[itemIndex].configuration.hoodingColour.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                    if (product.items[itemIndex].configuration.hoodingColour.selected.isSelectable) {
                        if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey);
                            }
                            if (optionIndex === -1) {
                                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.finalOptions)) {
                                    optionIndex = 0;
                                }
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.hoodingColour.selected.value = {};
                        product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = "";
                    }
                    break;
                case "mount":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                    if (!isEmpty(product.items[itemIndex].configuration.mount.finalOptions)) {

                        if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', product.items[itemIndex].configuration.mount.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.mount.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.mount.selected.value = {};
                        product.items[itemIndex].configuration.mount.selected.dropdownValue = "";
                    }
                    break;
                case "basePrice":
                    //bmCode
                    let bmCode = projectionAwningUtil.generateBMCode(product, itemIndex, order);
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => x.bmCode === bmCode);
                    //widthSet
                    if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode = bmCode;
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= product.items[itemIndex].configuration.width.selected.value);
                        //dropSet
                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= product.items[itemIndex].configuration.drop.selected.value);
                            //price
                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price * projectionAwningUtil.getPriceMatrixMultiplier(product, itemIndex);
                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                case "channelType":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.channelType.finalOptions = product.items[itemIndex].configuration.channelType.options.filter(o => o.condition1 ? o.condition1.includes(condition1) : true);
                    if (!isEmpty(product.items[itemIndex].configuration.channelType.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.channelType.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.channelType.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.channelType.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.channelType.selected.value = product.items[itemIndex].configuration.channelType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.channelType.selected.dropdownValue = product.items[itemIndex].configuration.channelType.selected.value.optionKey;
                        product.items[itemIndex].configuration.channelType.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.channelType.selected.value = null;
                        product.items[itemIndex].configuration.channelType.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.channelType.selected.isSelectable = false;
                    }
                    break;
                case "channelColour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.channelType.selected.value) ? "" : product.items[itemIndex].configuration.channelType.selected.value.optionKey;
                    product.items[itemIndex].configuration.channelColour.finalOptions = product.items[itemIndex].configuration.channelColour.options.filter(o =>
                        (
                            (o.condition1 ? o.condition1.includes(condition1) : true)
                            && (o.condition2 ? o.condition2.includes(condition2) : true)
                            && (drop >= o.primaryAttributeMin) && (drop <= o.primaryAttributeMax)
                        )
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.channelColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.channelColour.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.channelColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.channelColour.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                        product.items[itemIndex].configuration.channelColour.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.channelColour.selected.value = null;
                        product.items[itemIndex].configuration.channelColour.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.channelColour.selected.isSelectable = false;
                    }
                    break;
                case "channelSize":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.channelType.selected.value) ? "" : product.items[itemIndex].configuration.channelType.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.channelColour.selected.value) ? "" : product.items[itemIndex].configuration.channelColour.selected.value.optionKey;

                    product.items[itemIndex].configuration.channelSize.finalOptions = product.items[itemIndex].configuration.channelSize.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                        && (o.condition3 ? o.condition3.includes(condition3) : true)
                    );

                    if (!isEmpty(product.items[itemIndex].configuration.channelSize.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.channelSize.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.channelSize.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.channelSize.selected.value.optionKey);
                        }
                        if (isResetSelectionRequired) {
                            optionIndex = product.items[itemIndex].configuration.channelSize.finalOptions.findIndex(o => (drop >= o.primaryAttributeMin) && (drop <= o.primaryAttributeMax));
                        }

                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.channelSize.selected.value = product.items[itemIndex].configuration.channelSize.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.channelSize.selected.dropdownValue = product.items[itemIndex].configuration.channelSize.selected.value.optionKey;
                        product.items[itemIndex].configuration.channelSize.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.channelSize.selected.value = null;
                        product.items[itemIndex].configuration.channelSize.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.channelSize.selected.isSelectable = false;
                    }
                    break;
                case "standOutArmType":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product.items[itemIndex].configuration.standOutArmType.finalOptions = product.items[itemIndex].configuration.standOutArmType.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );

                    if (!isEmpty(product.items[itemIndex].configuration.standOutArmType.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.standOutArmType.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.standOutArmType.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.standOutArmType.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.standOutArmType.selected.value = product.items[itemIndex].configuration.standOutArmType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.standOutArmType.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmType.selected.value.optionKey;
                        product.items[itemIndex].configuration.standOutArmType.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.standOutArmType.selected.value = null;
                        product.items[itemIndex].configuration.standOutArmType.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.standOutArmType.selected.isSelectable = false;
                    }
                    break;
                case "standOutArmColour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.standOutArmType.selected.value) ? "" : product.items[itemIndex].configuration.standOutArmType.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.channelColour.selected.value) ? "" : product.items[itemIndex].configuration.channelColour.selected.value.optionKey;

                    product.items[itemIndex].configuration.standOutArmColour.finalOptions = product.items[itemIndex].configuration.standOutArmColour.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                        && (o.condition3 ? o.condition3.includes(condition3) : true)
                    );

                    if (!isEmpty(product.items[itemIndex].configuration.standOutArmColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.standOutArmColour.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.standOutArmColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.standOutArmColour.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.standOutArmColour.selected.value = product.items[itemIndex].configuration.standOutArmColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.standOutArmColour.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmColour.selected.value.optionKey;
                        product.items[itemIndex].configuration.standOutArmColour.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.standOutArmColour.selected.value = null;
                        product.items[itemIndex].configuration.standOutArmColour.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.standOutArmColour.selected.isSelectable = false;
                    }
                    break;
                case "standOutArmSize":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.standOutArmType.selected.value) ? "" : product.items[itemIndex].configuration.standOutArmType.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.standOutArmColour.selected.value) ? "" : product.items[itemIndex].configuration.standOutArmColour.selected.value.optionKey;

                    product.items[itemIndex].configuration.standOutArmSize.finalOptions = product.items[itemIndex].configuration.standOutArmSize.options.filter(o =>
                        o.description === "dropdown"
                        && (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                        && (o.condition3 ? o.condition3.includes(condition3) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.standOutArmSize.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.standOutArmSize.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.standOutArmSize.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.standOutArmSize.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.standOutArmSize.selected.value = product.items[itemIndex].configuration.standOutArmSize.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.standOutArmSize.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmSize.selected.value.optionKey;
                        product.items[itemIndex].configuration.standOutArmSize.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.standOutArmSize.selected.value = null;
                        product.items[itemIndex].configuration.standOutArmSize.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.standOutArmSize.selected.isSelectable = false;
                    }
                    break;
                case "bottomRailType":
                    optionIndex = -1;
                    condition1 = "dropdown";
                    condition2 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.bottomRailType.finalOptions = product.items[itemIndex].configuration.bottomRailType.options.filter(o =>
                        (o.condition1 === condition1) && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.bottomRailType.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.bottomRailType.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.bottomRailType.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.bottomRailType.selected.value = product.items[itemIndex].configuration.bottomRailType.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bottomRailType.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey;
                        product.items[itemIndex].configuration.bottomRailType.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.bottomRailType.selected.value = null;
                        product.items[itemIndex].configuration.bottomRailType.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.bottomRailType.selected.isSelectable = false;
                    }
                    break;
                case "bottomRailColour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.bottomRailColour.finalOptions = product.items[itemIndex].configuration.bottomRailColour.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.bottomRailColour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.bottomRailColour.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.bottomRailColour.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                        product.items[itemIndex].configuration.bottomRailColour.selected.isSelectable = true;
                    } else {
                        product.items[itemIndex].configuration.bottomRailColour.selected.value = null;
                        product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = "";
                        product.items[itemIndex].configuration.bottomRailColour.selected.isSelectable = false;
                    }
                    break;
                case "crankHandle":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product.items[itemIndex].configuration.crankHandle.finalOptions = product.items[itemIndex].configuration.crankHandle.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                        && (o.condition2 ? o.condition2.includes(condition2) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.crankHandle.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                            //update selection, getting ideal selection
                            optionIndex = product.items[itemIndex].configuration.crankHandle.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.crankHandle.selected.value.optionKey);
                        }
                        //if couldn't get first item
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.crankHandle.selected.value = null;
                        product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = "";
                    }
                    break;
                case "windSensor":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product.items[itemIndex].configuration.windSensor.finalOptions = product.items[itemIndex].configuration.windSensor.options.filter(o => {
                        return ((o.condition1 ? o.condition1.includes(condition1) : true )
                            && (o.condition2 ? o.condition2.includes(condition2) : true ))
                    });
                    if (!isEmpty(product.items[itemIndex].configuration.windSensor.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.windSensor.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.windSensor.finalOptions, 'optionKey', product.items[itemIndex].configuration.windSensor.selected.value.optionKey);
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.windSensor.selected.value = product.items[itemIndex].configuration.windSensor.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.windSensor.selected.dropdownValue = product.items[itemIndex].configuration.windSensor.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.windSensor.selected.value = {};
                        product.items[itemIndex].configuration.windSensor.selected.dropdownValue = "";
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = projectionAwningUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = projectionAwningUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = projectionAwningUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("channelSize", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("standOutArmColour", product, itemIndex, order, true);
                product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                product = projectionAwningUtil.updateFormError("drop", product, itemIndex);
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product = projectionAwningUtil.updateRules("width", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("drop", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("operation", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("controlSide", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("cordColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("cordColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("hooding", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("hoodingColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("bracketColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("bracketColourPowdercoat", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("mount", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("channelType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("channelSize", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("standOutArmType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("standOutArmColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomRailType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bottomRailColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("bottomRailColourPowdercoat", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("windSensor", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("winchAndCord", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("remote", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("charger", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("bridge", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("windSensor", product, itemIndex, order);

                    product = projectionAwningUtil.updateFormError("width", product, itemIndex);
                    product = projectionAwningUtil.updateFormError("drop", product, itemIndex);
                    product = projectionAwningUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
                    product = projectionAwningUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
                    product = projectionAwningUtil.updateFormError("standOutArmColourPowdercoat", product, itemIndex);
                }
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    product = projectionAwningUtil.updateRules("controlSide", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("cordColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("cordColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("valance", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("bracketColourPowdercoat", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("standOutArmType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("standOutArmColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("crankHandle", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("windSensor", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("crankHandle", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("winchAndCord", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("remote", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("charger", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("bridge", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("windSensor", product, itemIndex, order);
                    product = projectionAwningUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
                }
                break;
            case "cordColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.cordColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.cordColour.selected.value = product.items[itemIndex].configuration.cordColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.cordColour.selected.dropdownValue = product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
                }
                break;
            case "controlSide":
                optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                }
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
                    product = projectionAwningUtil.updateRules("fabricColourOffRange", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                }
                break;
            case "fabricColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.fabricColour.selected.value = {};
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                }
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.value = change;
                product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, order, true);
                product = projectionAwningUtil.updateFormError("fabricColourOffRange", product, itemIndex);
                break;
            case "valance":
                optionIndex = findIndex(product.items[itemIndex].configuration.valance.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.valance.selected.dropdownValue = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                }
                break;
            case "hooding":
                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hooding.selected.value = product.items[itemIndex].configuration.hooding.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hooding.selected.dropdownValue = product.items[itemIndex].configuration.hooding.selected.value.optionKey;

                    product = projectionAwningUtil.updateRules("hoodingColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("hoodingColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateRules("bracketColour", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("bracketColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("bracketColourPowdercoat", product, itemIndex, order);

                    product = projectionAwningUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
                    product = projectionAwningUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);

                }
                break;
            case "hoodingColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.hoodingColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    product = projectionAwningUtil.updateRules("hoodingColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
                }
                break;
            case "hoodingColourPowdercoat":
                product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = change;
                product = projectionAwningUtil.updateFormError("hoodingColourPowdercoat", product, itemIndex);
                break;
            case "bracketColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.bracketColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bracketColour.selected.value = product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;

                    product = projectionAwningUtil.updateRules("bracketColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
                }
                break;
            case "bracketColourPowdercoat":
                product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = change;
                product = projectionAwningUtil.updateFormError("bracketColourPowdercoat", product, itemIndex);
                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                }
                break;
            case "channelType":
                optionIndex = findIndex(product.items[itemIndex].configuration.channelType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channelType.selected.value = product.items[itemIndex].configuration.channelType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channelType.selected.dropdownValue = product.items[itemIndex].configuration.channelType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("channelColour", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("channelSize", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("standOutArmColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
                }
                break;
            case "channelColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.channelColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channelColour.selected.dropdownValue = product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("channelSize", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("standOutArmType", product, itemIndex, order, true);
                    product = this.updateItemConfigurationOptions("standOutArmColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateFormError("standOutArmColourPowdercoat", product, itemIndex);
                    product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
                }
                break;
            case "channelSize":
                optionIndex = findIndex(product.items[itemIndex].configuration.channelSize.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channelSize.selected.value = product.items[itemIndex].configuration.channelSize.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channelSize.selected.dropdownValue = product.items[itemIndex].configuration.channelSize.selected.value.optionKey;
                }
                break;
            case "standOutArmType":
                optionIndex = findIndex(product.items[itemIndex].configuration.standOutArmType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.standOutArmType.selected.value = product.items[itemIndex].configuration.standOutArmType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.standOutArmType.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmType.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("standOutArmColour", product, itemIndex, order, true);
                    product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateFormError("standOutArmColourPowdercoat", product, itemIndex);
                    product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
                }
                break;
            case "standOutArmColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.standOutArmColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.standOutArmColour.selected.value = product.items[itemIndex].configuration.standOutArmColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.standOutArmColour.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmColour.selected.value.optionKey;
                    product = projectionAwningUtil.updateRules("standOutArmColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateFormError("standOutArmColourPowdercoat", product, itemIndex);
                    product = this.updateItemConfigurationOptions("standOutArmSize", product, itemIndex, order, true);
                }
                break;
            case "standOutArmColourPowdercoat":
                product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.value = change;
                product = projectionAwningUtil.updateFormError("standOutArmColourPowdercoat", product, itemIndex);
                break;
            case "standOutArmSize":
                optionIndex = findIndex(product.items[itemIndex].configuration.standOutArmSize.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.standOutArmSize.selected.value = product.items[itemIndex].configuration.standOutArmSize.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.standOutArmSize.selected.dropdownValue = product.items[itemIndex].configuration.standOutArmSize.selected.value.optionKey;
                }
                break;
            case "bottomRailType":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRailType.selected.value = product.items[itemIndex].configuration.bottomRailType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRailType.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey;
                }
                break;
            case "bottomRailColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomRailColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomRailColour.selected.value = product.items[itemIndex].configuration.bottomRailColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomRailColour.selected.dropdownValue = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                    product = projectionAwningUtil.updateRules("bottomRailColourPowdercoat", product, itemIndex, order);
                    product = projectionAwningUtil.updateFormError("bottomRailColourPowdercoat", product, itemIndex);
                }
                break;
            case "bottomRailColourPowdercoat":
                product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = change;
                product = projectionAwningUtil.updateFormError("bottomRailColourPowdercoat", product, itemIndex);
                break;
            case "crankHandle":
                optionIndex = findIndex(product.items[itemIndex].configuration.crankHandle.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                }
                break;
            case "windSensor":
                optionIndex = findIndex(product.items[itemIndex].configuration.windSensor.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.windSensor.selected.value = product.items[itemIndex].configuration.windSensor.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.windSensor.selected.dropdownValue = product.items[itemIndex].configuration.windSensor.selected.value.optionKey;
                } else {
                    product.items[itemIndex].configuration.windSensor.selected.value = {};
                    product.items[itemIndex].configuration.windSensor.selected.dropdownValue = "";
                }
                break;
            case "winchAndCord":
            case "remote":
            case "charger":
            case "bridge":
                product.items[itemIndex].configuration[key].selected.value = change;
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = projectionAwningUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }


    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser} = this.props;
        let items = product.items;
        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}} className={"text-center"}>
                                        #
                                    </th>
                                    <th style={{minWidth: 150}} className={"text-center"}>
                                        Location
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Quantity
                                    </th>
                                    <th style={{minWidth: 295}} className={"text-center"}>
                                        Model
                                    </th>
                                    <th style={{minWidth: 325}} className={"text-center"}>
                                        Operation
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"text-center"}>
                                            Control/Motor
                                        </div>
                                        <div className={"text-center"}>
                                            Side
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Width(mm)
                                    </th>
                                    <th style={{minWidth: 100}} className={"text-center"}>
                                        Drop/Projection(mm)
                                    </th>
                                    <th style={{minWidth: 250}} className={"text-center"}>
                                        Fabric
                                    </th>
                                    <th style={{minWidth: 135}} className={"text-center"}>
                                        Valance
                                    </th>
                                    <th style={{minWidth: 250}} className={"text-center"}>
                                        Hooding
                                    </th>
                                    <th style={{minWidth: 170}} className={"text-center"}>
                                        Mount
                                    </th>
                                    <th style={{minWidth: 240}} className={"text-center"}>
                                        Channel/Guide
                                    </th>
                                    <th style={{minWidth: 230}} className={"text-center"}>
                                        Standout Arm
                                    </th>
                                    <th style={{minWidth: 215}} className={"text-center"}>
                                        Bottom Rail
                                    </th>
                                    <th style={{minWidth: 190}} className={"text-center"}>
                                        Crank Handle
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null

                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="text" name="location"
                                                               value={item.configuration.location.selected.value}
                                                               onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                               placeholder="location"/>
                                                        {
                                                            item.configuration.location.formError.isValid
                                                                ? null
                                                                : <Badge color="danger">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                        }
                                                        {
                                                            item.configuration.location.formError.isWarning
                                                                ? <Badge color="warning">
                                                                    {item.configuration.location.formError.message}
                                                                </Badge>
                                                                : null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="number" name="quantity"
                                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                                   value={item.configuration.quantity.selected.value}
                                                                   min={item.configuration.quantity.min}
                                                                   max={item.configuration.quantity.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="quantity"/>

                                                            {
                                                                item.configuration.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="model"
                                                           value={item.configuration.model.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "model", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="operation"
                                                           value={item.configuration.operation.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "operation", itemIndex, true)}>
                                                        <option value={""} disabled>Select operation</option>

                                                        {
                                                            (item.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.cordColour.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="cordColour"
                                                                       value={item.configuration.cordColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "cordColour", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select Cord Colour
                                                                    </option>
                                                                    {
                                                                        (item.configuration.cordColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.winchAndCord.selected.isSelectable
                                                            ? <div>
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    onClick={() => this.handleChange(!item.configuration.winchAndCord.selected.value, "winchAndCord", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.winchAndCord.selected.value,
                                                                            "fa-square-o": !item.configuration.winchAndCord.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                {item.configuration.winchAndCord.selected.dropdownValue}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.remote.selected.isSelectable
                                                            ? <div className="mt-1">
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    onClick={() => this.handleChange(!item.configuration.remote.selected.value, "remote", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.remote.selected.value,
                                                                            "fa-square-o": !item.configuration.remote.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                {item.configuration.remote.attributeLabel}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.charger.selected.isSelectable
                                                            ? <div className="mt-1">
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    onClick={() => this.handleChange(!item.configuration.charger.selected.value, "charger", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.charger.selected.value,
                                                                            "fa-square-o": !item.configuration.charger.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                {item.configuration.charger.attributeLabel}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bridge.selected.isSelectable
                                                            ? <div className="mt-1">
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    onClick={() => this.handleChange(!item.configuration.bridge.selected.value, "bridge", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.bridge.selected.value,
                                                                            "fa-square-o": !item.configuration.bridge.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                {item.configuration.bridge.attributeLabel}
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.windSensor.selected.isSelectable
                                                            ? <div className="mt-1">
                                                                {
                                                                    item.configuration.windSensor.finalOptions.map(o => {
                                                                        return <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                onClick={() => this.handleChange((o.optionKey === item.configuration.windSensor.selected.dropdownValue ? "" : o.optionKey), "windSensor", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": o.optionKey === item.configuration.windSensor.selected.dropdownValue,
                                                                                        "fa-square-o": o.optionKey !== item.configuration.windSensor.selected.dropdownValue,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {o.optionKey}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.controlSide.selected.isSelectable
                                                            ? <Input type="select" name="controlSide"
                                                                     value={item.configuration.controlSide.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "controlSide", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.controlSide.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : "N/A"
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="width"
                                                           invalid={!item.configuration.width.formError.isValid}
                                                           value={item.configuration.width.selected.value}
                                                           min={item.configuration.width.min}
                                                           max={item.configuration.width.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="width"/>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="number" name="drop"
                                                           invalid={!item.configuration.drop.formError.isValid}
                                                           value={item.configuration.drop.selected.value}
                                                           min={item.configuration.drop.min}
                                                           max={item.configuration.drop.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="drop"/>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <Input type="select" name="fabricType"
                                                               disabled={!item.configuration.fabricType.selected.isSelectable}
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select Range</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {
                                                        isEmpty(item.configuration.fabricColour.finalOptions)
                                                            ? null
                                                            : <div className={"mt-2"}>
                                                                <Input type="select" name="fabricColour"
                                                                       value={item.configuration.fabricColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                    <option value={""}>Select Colour</option>
                                                                    {
                                                                        (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                    }
                                                    {
                                                        item.configuration.fabricColourOffRange.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="fabricColourOffRange"
                                                                       value={item.configuration.fabricColourOffRange.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColourOffRange", itemIndex, true)}
                                                                       placeholder="Fabric name"
                                                                       title={"Enter fabric name"}
                                                                />
                                                                {
                                                                    item.configuration.fabricColourOffRange.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.fabricColourOffRange.formError.message}
                                                                        </Badge>
                                                                }
                                                                <div>
                                                                    <Badge color="info">
                                                                        <i className="fa fa-info-circle mr-2"
                                                                           aria-hidden="true"/>
                                                                        Price On Application
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="valance"
                                                           value={item.configuration.valance.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "valance", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.valance.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="hooding"
                                                           value={item.configuration.hooding.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "hooding", itemIndex, true)}>
                                                        <option value={""} disabled>Select hooding</option>
                                                        {
                                                            (item.configuration.hooding.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="hoodingColour"
                                                                       value={item.configuration.hoodingColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "hoodingColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select hooding colour
                                                                    </option>
                                                                    {
                                                                        (item.configuration.hoodingColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        item.configuration.hoodingColourPowdercoat.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="hoodingColourPowdercoat"
                                                                       value={item.configuration.hoodingColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "hoodingColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.hoodingColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.hoodingColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bracketColour.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="bracketColour"
                                                                       value={item.configuration.bracketColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "bracketColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select bracket colour</option>

                                                                    {
                                                                        (item.configuration.bracketColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={"item" + itemIndex + "-option" + oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null

                                                    }
                                                    {
                                                        item.configuration.bracketColourPowdercoat.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="bracketColourPowdercoat"
                                                                       value={item.configuration.bracketColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "bracketColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"

                                                                />
                                                                {
                                                                    item.configuration.bracketColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.bracketColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="mount"
                                                           value={item.configuration.mount.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.channelType.selected.isSelectable
                                                            ? <Input type="select" name="channelType"
                                                                     value={item.configuration.channelType.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "channelType", itemIndex, true)}>
                                                                <option value={""} disabled={true}>Select type</option>
                                                                {
                                                                    (item.configuration.channelType.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.channelColour.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="channelColour"
                                                                       value={item.configuration.channelColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "channelColour", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select variant</option>
                                                                    {
                                                                        (item.configuration.channelColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.channelSize.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="channelSize"
                                                                       value={item.configuration.channelSize.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "channelSize", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select size</option>
                                                                    {
                                                                        (item.configuration.channelSize.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (
                                                            !item.configuration.channelType.selected.isSelectable
                                                            && !item.configuration.channelColour.selected.isSelectable
                                                            && !item.configuration.channelSize.selected.isSelectable
                                                        )
                                                            ? <span>N/A</span>
                                                            : null
                                                    }
                                                </td>

                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.standOutArmType.selected.isSelectable
                                                            ? <div>
                                                                <Input type="select" name="standOutArmType"
                                                                       value={item.configuration.standOutArmType.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "standOutArmType", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select type</option>
                                                                    {
                                                                        (item.configuration.standOutArmType.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.standOutArmColour.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="standOutArmColour"
                                                                       value={item.configuration.standOutArmColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "standOutArmColour", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select variant</option>
                                                                    {
                                                                        (item.configuration.standOutArmColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.standOutArmColourPowdercoat.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="text" name="standOutArmColourPowdercoat"
                                                                       value={item.configuration.standOutArmColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "standOutArmColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"

                                                                />
                                                                {
                                                                    item.configuration.standOutArmColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.standOutArmColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.standOutArmSize.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="standOutArmSize"
                                                                       value={item.configuration.standOutArmSize.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "standOutArmSize", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select size</option>
                                                                    {
                                                                        (item.configuration.standOutArmSize.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (
                                                            !item.configuration.standOutArmType.selected.isSelectable
                                                            && !item.configuration.standOutArmColour.selected.isSelectable
                                                            && !item.configuration.standOutArmSize.selected.isSelectable
                                                        )
                                                            ? <span>N/A</span>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        item.configuration.bottomRailType.selected.isSelectable
                                                            ? <Input type="select" name="bottomRailType"
                                                                     value={item.configuration.bottomRailType.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "bottomRailType", itemIndex, true)}>
                                                                <option value={""} disabled={true}>Select type</option>
                                                                {
                                                                    (item.configuration.bottomRailType.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bottomRailColour.selected.isSelectable
                                                            ? <div className="mt-2">
                                                                <Input type="select" name="bottomRailColour"
                                                                       value={item.configuration.bottomRailColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "bottomRailColour", itemIndex, true)}>
                                                                    <option value={""} disabled={true}>Select colour</option>
                                                                    {
                                                                        (item.configuration.bottomRailColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.bottomRailColourPowdercoat.selected.isSelectable
                                                            ? <div>
                                                                <Input type="text" name="bottomRailColourPowdercoat"
                                                                       value={item.configuration.bottomRailColourPowdercoat.selected.value}
                                                                       onChange={(e) => this.handleChange(e.target.value, "bottomRailColourPowdercoat", itemIndex, true)}
                                                                       placeholder="Powdercoat colour"
                                                                       className={"mt-2"}
                                                                />
                                                                {
                                                                    item.configuration.bottomRailColourPowdercoat.formError.isValid
                                                                        ? null
                                                                        : <Badge color="danger">
                                                                            {item.configuration.bottomRailColourPowdercoat.formError.message}
                                                                        </Badge>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (
                                                            !item.configuration.bottomRailType.selected.isSelectable
                                                            && !item.configuration.bottomRailColour.selected.isSelectable
                                                            && !item.configuration.bottomRailColourPowdercoat.selected.isSelectable
                                                        )
                                                            ? <span>N/A</span>
                                                            : null
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        product.items[itemIndex].configuration.crankHandle.selected.isSelectable
                                                            ? <Input type="select" name="crankHandle"
                                                                     value={item.configuration.crankHandle.selected.dropdownValue}
                                                                     onChange={(e) => this.handleChange(e.target.value, "crankHandle", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.crankHandle.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : <span>N/A</span>
                                                    }
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={17}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <ProjectionAwningBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <ProjectionAwningConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
