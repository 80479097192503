import React, { Component } from 'react';
import {
    Breadcrumb, BreadcrumbItem, Row, Col, FormGroup, Label, Input, Form,
    Button, FormText, Table, Modal, ModalBody, ModalHeader, ModalFooter, Card, CardBody, CardHeader, Spinner
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import FaqService from "../../services/FaqService";
import ProductDiscountService from '../../services/product/ProductDiscountService';
import UploadFAQModal from "../../components/Order/modals/UploadFAQModal";
import { Link } from 'react-router-dom';
import AddFAQSuggestion from './AddFAQSuggestion';
import AddDocumentLink from "../../components/modal/AddDocumentLink";

export default class AddFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Faqdata: [],
            CategoryTypes: ['Indoor', 'Outdoor'],
            SubCategoryTypes: [],
            IndoorTypes: ['Roller', 'Tracks', 'Venetians', 'Warranty','Motorisation'],
            OutdoorTypes: ['Ziptrack', 'Folding Arm Awnings', 'Warranty','Motorisation'],
            fileUpload: {
                primaryText: "",
                id: 0,
                secondaryText: "",
                category: "",
                subCategory: "",
                sequenceOrderNum: 0
            },
            search: {
                primaryText: "",
                category: "",
                subCategory: ""
            },
            formErrors: {},

            Heading: '',
            isOpen: false,
            openCloseUploadModal: false,
            deleteLoader: {
                isDeleting: false,
                seletedId: 0,
            },
            saving:false,
            loading:false,
            isOpenSuggestModal:false,
            addDocumentLinkIsOpen:false
        };

        this.faqService = new FaqService();
        this.productDiscountService = new ProductDiscountService();
        this.onClickHandler = this.onClickHandler.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.refresh = this.refresh.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleUpload = this.toggleUpload.bind(this);
        this.changeUploadModalState = this.changeUploadModalState.bind(this);
        this.DeleteFaq = this.DeleteFaq.bind(this);
        this.openSuggestModal = this.openSuggestModal.bind(this);
        this.toggleSuggestModal = this.toggleSuggestModal.bind(this);
        this.openAddDocumentLink = this.openAddDocumentLink.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({ loading: true });
        this.faqService.getFaqs().then(response => {
            if (response.data) {
                this.setState({ Faqdata: response.data, loading:false });
                document.getElementById("")
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

        /*        let { fileUpload, IndoorTypes } = this.state;
                fileUpload.category = 'Indoor';
                fileUpload.subCategory = 'Roller';
                this.setState({ fileUpload, SubCategoryTypes: IndoorTypes });*/
        let { fileUpload } = this.state;
        fileUpload.category = '';
        fileUpload.subCategory = '';
        this.setState({ SubCategoryTypes: [] });
    }

    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages });
    }

    searchFunction(f, search) {
        let flag = true;
        if (flag) {
            flag = f.primaryText.toLowerCase().includes(search.primaryText.toLowerCase())
        }
        if (flag && search.category) {
            flag = f.category.toLowerCase().includes(search.category.toLowerCase())
        }
        if (flag && search.subCategory) {
            flag = f.subCategory.toLowerCase().includes(search.subCategory.toLowerCase())
        }

        return flag;

    }

    handleChange(e) {
        const { name, value } = e.target;
        let { fileUpload, IndoorTypes, OutdoorTypes } = this.state;
        fileUpload[name] = value;
        this.setState({ fileUpload });
        if (name === 'category' || name === 'CATEGORY') {
            if (value === 'Outdoor' || value.trim() === 'Outdoor') {
                this.setState({ SubCategoryTypes: OutdoorTypes });
            } else { this.setState({ SubCategoryTypes: IndoorTypes }); }
        }
    }

    handleCancel() {
        //this.props.history.push('/company/Faq');
        this.toggleModal(false, null);
    }

    onClickHandler() {
        let model = {
            primaryText: this.state.fileUpload.primaryText,
            id: this.state.fileUpload.id,
            secondaryText: this.state.fileUpload.secondaryText,
            sequenceOrderNum: this.state.fileUpload.sequenceOrderNum,
            Category: this.state.fileUpload.category,
            SubCategory: this.state.fileUpload.subCategory
        };
        let result = this.validateForm();
        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            toast.info("See form error for invalid input ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        this.setState({ saving: true });
        this.faqService.AddFAQInformation(model).then(response => {
            if (response.status === 200 || response.status === '200') {
                let fileUpload = {
                    primaryText: "",
                    id: 0,
                    secondaryText: "",
                    sequenceOrderNum: 0
                };
                let formErrors = {};
                this.setState({ fileUpload, formErrors, isOpen: false, saving: false }, () => this.refresh());
                if (model.id) {
                    toast.success("FAQ updated successfully...", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                else {
                    toast.success("FAQ added successfully...", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            }
        }).catch(error => {
            this.setState({ saving: false });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    bytesToSize(bytes) {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    validateForm() {
        let formErrors = {};
        formErrors.primaryText = this.state.fileUpload.primaryText.trim().length > 0 ? "" : "Question Required";
        formErrors.secondaryText = this.state.fileUpload.secondaryText.trim().length > 0 ? "" : "Answer Required";
        formErrors.sequenceOrderNum = parseInt(this.state.fileUpload.sequenceOrderNum) !== 0 ? "" : "Sequence of occurrence cannot be zero";
        formErrors.category = this.state.fileUpload.category.trim().length > 0 ? "" : "Please Select Category";
        formErrors.subCategory = this.state.fileUpload.subCategory.trim().length > 0 ? "" : "Please Select Sub Category";

        if (formErrors.primaryText
            || formErrors.secondaryText || formErrors.sequenceOrderNum || formErrors.category || formErrors.subCategory) {
            return { valid: false, formErrors: formErrors };
        }
        return { valid: true, formErrors: formErrors };
    }

    handleSearch(value, key, index) {
        let { search } = this.state;
        search[key] = value;
        this.setState({ search });
    }

    openSuggestModal() {
        this.setState({ isOpenSuggestModal: true });
    }

    toggleSuggestModal(data) {
        this.setState({ isOpenSuggestModal: data });
    }

    openAddDocumentLink(data){
        this.setState({ addDocumentLinkIsOpen: data });
    }

    renderFaqs(data) {
        let { search, deleteLoader,loading } = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <h5>FAQ</h5>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="text-right">
                                    <Button color="primary" size="sm" onClick={this.openSuggestModal}>Any Suggestions
                                        <i className="fa fa-question-circle ml-2" aria-hidden="true" />
                                    </Button>
                                    <Link color='primary' style={{ textDecoration: "none" }} className="btn btn-sm ml-1 btn btn-primary"
                                        to={{
                                            pathname: '/tools/faq/manage/suggestions'
                                        }}> View FAQ Suggestions</Link>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Table striped bordered responsive hover>
                                <thead>
                                    <tr>
                                        <th className={"text-left align-middle"}>S.No</th>
                                        <th className={"text-left align-middle"}>Primary Text / Question</th>
                                        <th className={"text-left align-middle"}>Category</th>
                                        <th className={"text-left align-middle"}>Sub Category</th>
                                        <th className={"text-left align-middle"}>Sequence</th>
                                        <th style={{ minWidth: 100 }}></th>
                                        <th style={{ minWidth: 120 }} className="text-center">Action</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <Input type="text" id="primaryText" name="primaryText"
                                                value={search.primaryText}
                                                onChange={(e) => this.handleSearch(e.target.value, "primaryText", -1)}
                                                placeholder="Search..." />
                                        </td>
                                        <td>
                                            <Input type="text" id="category" name="category"
                                                value={search.category}
                                                onChange={(e) => this.handleSearch(e.target.value, "category", -1)}
                                                placeholder="Search..." />
                                        </td>
                                        <td>
                                            <Input type="text" id="subCategory" name="subCategory"
                                                value={search.subCategory}
                                                onChange={(e) => this.handleSearch(e.target.value, "subCategory", -1)}
                                                placeholder="Search..." />
                                        </td>
                                        <td></td>
                                        <td>
                                            <div className="text-center">
                                                <Button color='primary' size="sm" onClick={() => this.toggleModal(true, null)}><i
                                                    className="fa fa-plus-circle mr-2" aria-hidden="true" /> New</Button>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data || []).filter((item) => this.searchFunction(item, search)).map((item, index) =>
                                        <tr key={index}>
                                            <td className={"text-center align-middle"}>{index + 1}</td>
                                            <td className={"text-left align-middle"}>{item.primaryText}</td>
                                            <td className={"text-left align-middle"}>{item.category}</td>
                                            <td className={"text-left align-middle"}>{item.subCategory}</td>
                                            <td className={"text-right align-middle"}>{item.sequenceOrderNum}</td>
                                            <td title="Edit" className="text-center align-middle">
                                                <div>
                                                    <Button color='primary' size="sm"
                                                        onClick={() => this.toggleModal(true, item)}>
                                                        <i className="fa fa-pencil mr-2" aria-hidden="true" />Edit
                                            </Button>
                                                </div>
                                            </td>
                                            <td title="Delete Faq" className="text-center align-middle">
                                                <div>
                                                    <Button color='danger' size="sm"
                                                        onClick={() => this.DeleteFaq(item.id)}>
                                                        {
                                                            (deleteLoader.isDeleting && deleteLoader.seletedId == item.id) ?
                                                                <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" /> :
                                                                <span><i className="fa fa-trash-o mr-2" />Delete</span>
                                                        }
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>}
                    </CardBody>
                </Card>
                <AddFAQSuggestion
                    isOpen={this.state.isOpenSuggestModal}
                    toggleModal={this.toggleSuggestModal} />

            </div>
        );
    }

    toggleModal(isOpen, data) {
        let { IndoorTypes, OutdoorTypes } = this.state;
        if (data == null) {
            let fileUpload = {
                primaryText: "",
                secondaryText: "",
                sequenceOrderNum: 0,
                id: 0,
                category: "",
                subCategory: "",
            };
            let formErrors = {};
            this.setState({ isOpen: isOpen, fileUpload, formErrors });
        }
        else {
            let fileUpload = {
                primaryText: data.primaryText,
                id: data.id,
                secondaryText: data.secondaryText,
                subCategory: data.subCategory,
                category: data.category,
                sequenceOrderNum: data.sequenceOrderNum
            };

            if (fileUpload.category === 'Outdoor' || fileUpload.category.trim() === 'Outdoor') {
                this.setState({ SubCategoryTypes: OutdoorTypes });
            } else { this.setState({ SubCategoryTypes: IndoorTypes }); }

            this.setState({ fileUpload: fileUpload, isOpen: isOpen });
        }
    }

    toggleUpload(Id) {
        if (Id) {
            //console.log('', Id);
            let { fileUpload } = this.state;
            fileUpload.id = Id;
            this.setState({ fileUpload }, () => this.changeUploadModalState());
        } else {
            this.changeUploadModalState();
        }
    }

    changeUploadModalState() {
        this.setState((prevState) => ({ openCloseUploadModal: !prevState.openCloseUploadModal }));
    }

    DeleteFaq(Id) {
        if (Id) {
            this.showDeleteLoader(Id);
            this.faqService.DeleteWholeFaq(Id).then(response => {
                if (response.status === 200 || response.status === '200') {
                    this.refresh();
                    this.hideDeleteLoader();
                }
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    hideDeleteLoader() {
        let { deleteLoader } = this.state;
        deleteLoader.isDeleting = false; deleteLoader.seletedId = 0;
        this.setState({ deleteLoader });
    }

    showDeleteLoader(Id) {
        let { deleteLoader } = this.state;
        deleteLoader.isDeleting = true; deleteLoader.seletedId = Id;
        this.setState({ deleteLoader });
    }

    render() {
        let { formErrors, isOpen, Heading, saving,addDocumentLinkIsOpen } = this.state;
        if (this.state.fileUpload.primaryText !== '') {
            Heading = 'Edit Frequently Asked Question'
        } else {
            Heading = 'Add Frequently Asked Question'
        }
        let Faqs = this.renderFaqs(this.state.Faqdata);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">FAQ</BreadcrumbItem>
                </Breadcrumb>


                {Faqs}

                <Modal isOpen={isOpen} size="xl"
                    scrollable={true}
                    toggle={() => this.toggleModal(!isOpen, null)}>
                    <ModalHeader toggle={() => this.toggleModal(!isOpen, null)}>
                        {Heading}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label>Primary Text</Label>
                                <Input
                                    type="textarea"
                                    name="primaryText"
                                    value={this.state.fileUpload.primaryText}
                                    onChange={this.handleChange}
                                    placeholder="Write question here..." />
                                <FormText
                                    color="danger">{formErrors.primaryText}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label>Secondary Text</Label>
                                <Input
                                    type="textarea"
                                    name="secondaryText"
                                    value={this.state.fileUpload.secondaryText}
                                    onChange={this.handleChange}
                                    placeholder="Write Answer here..." />
                                <FormText
                                    color="danger">{formErrors.secondaryText}</FormText>
                            </FormGroup>
                            <Row>

                                <Col lg={3} md={4} sm={6} xs={6}>
                                    <FormGroup>
                                        <Label>Category</Label>
                                        <Input className="form-control"
                                            type="select"
                                            value={this.state.fileUpload.category}
                                            onChange={(e) => this.handleChange(e)}
                                            name="category"
                                            placeholder="Select Category here...">
                                            <option
                                                key={99}
                                                value={0}>Select Category</option>
                                            {this.state.CategoryTypes.map((item) => <option
                                                key={item}
                                                value={item}>{item}</option>)}
                                        </Input>
                                        <FormText
                                            color="danger">{formErrors.category}</FormText>
                                    </FormGroup>
                                </Col>

                                <Col lg={4} md={5} sm={6} xs={6}>
                                    <FormGroup>
                                        <Label>Sub Category</Label>
                                        <Input className="form-control"
                                            type="select"
                                            value={this.state.fileUpload.subCategory}
                                            onChange={(e) => this.handleChange(e)}
                                            name="subCategory"
                                            placeholder="Select Category here...">
                                            <option
                                                key={100}
                                                value={0}>Select Sub Category</option>
                                            {this.state.SubCategoryTypes.map((item, indexx) => <option
                                                key={indexx}
                                                value={item}>{item}</option>)}
                                        </Input>
                                        <FormText
                                            color="danger">{formErrors.subCategory}</FormText>
                                    </FormGroup>
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={6}>
                                    <FormGroup>
                                        <Label>Sequence Num</Label>
                                        <Input
                                            type="number"
                                            name="sequenceOrderNum"
                                            value={this.state.fileUpload.sequenceOrderNum}
                                            onChange={this.handleChange}
                                            placeholder="Enter here..." />
                                        <FormText
                                            color="danger">{formErrors.sequenceOrderNum}</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            { this.state.fileUpload.id===0 ? null:
                                <Row>
                                    <Col lg={6} md={6} sm={6} xs={12}>
                                        <FormGroup>
                                            <Label>Media </Label>&nbsp;&nbsp;
                                            <br/>
                                            <Button color='primary' size="sm" onClick={this.openAddDocumentLink}>
                                                <i className="fa fa-link" aria-hidden="true"/>&nbsp;Add Link
                                            </Button>&nbsp;
                                            <small>OR</small>
                                            &nbsp;
                                            <Button color='primary' size="sm"
                                                    onClick={() => this.toggleUpload(this.state.fileUpload.id)}>
                                                <i className="fa fa-cloud-upload mr-2" aria-hidden="true"/>Upload File
                                            </Button>
                                        </FormGroup>

                                    </Col>
                                </Row>
                            }
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.onClickHandler} disabled={saving}>
                                {saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : null}
                                Save</Button>{' '}
                            <Button color="secondary" onClick={this.handleCancel}>Cancel</Button>
                        </div>
                    </ModalFooter>
                </Modal>


                <ToastContainer />

                <UploadFAQModal
                    FAQId={this.state.fileUpload.id}
                    isOpen={this.state.openCloseUploadModal}
                    toggleModel={this.toggleUpload}
                />
                <AddDocumentLink
                    FAQId={this.state.fileUpload.id}
                    isOpen={addDocumentLinkIsOpen}
                    toggleOpen={this.openAddDocumentLink}/>
            </div>
        );
    }
}