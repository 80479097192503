import React, {Component} from "react";
import {Badge, Card, CardBody, CardHeader, Spinner} from 'reactstrap';
import OrderService from "../../../services/OrderService";
import {changeFormatOfDateString} from "../../../services/CommonService";
import {isEmpty} from 'lodash';
import {Link} from "react-router-dom";

export default class SalesOrderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordNum: this.props.ordNum,
            notes: [],
        };

        this.getDebtorInvoice = this.getDebtorInvoice.bind(this);
        this.orderService = new OrderService();
    }

    componentDidMount() {
        this.getDebtorInvoice(this.state.ordNum);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            this.setState({ordNum: nextProps.ordNum}, () => {
                this.getDebtorInvoice(nextProps.ordNum);
            });
        }
    }


    getDebtorInvoice(ordNum) {
        if (ordNum) {
            this.setState({ordNum, isLoadingOrder: true});
            this.orderService.getOrder(ordNum, true, null, false, "current").then(response => {
                let order = response.data;
                this.setState({order: order, isLoadingOrder: false});
                if (this.props.handleChange) {
                    this.props.handleChange(order);
                }
            }).catch(error => {
                console.error(error);
                this.setState({isLoadingOrder: false});
            });
        }
    }


    render() {
        let {order, isLoadingOrder} = this.state;
        if (isLoadingOrder) {
            return <Spinner color={"primary"} className={"m-2"}/>
        }
        if (order) {
            return (
                <div>
                    <Card>
                        <CardBody>
                            <h5>#{order.ordNum}</h5>
                            <div>
                                <Link
                                    className="btn btn-primary btn-sm"
                                    style={{color: "white"}}
                                    title={"Click here to see account details"}
                                    to={"/customer/#" + order.accountID}
                                >{order.accountID}</Link>
                            </div>
                            <div><h6>{order.delCompany}</h6></div>
                            <Badge color="warning">
                                {order.salesOrderJobStatusDescription}
                            </Badge>
                            <div>
                                <span className="text-muted">Entered by- </span>
                                {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                <span className="text-muted">{' | '}</span>
                                {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                            </div>
                        </CardBody>
                    </Card>

                </div>
            )
        }
        return null;
    }
}