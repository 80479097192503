import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Label, Badge } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import ManageStockInventory from './ManageStockInventory';
import { Link } from "react-router-dom";

//Manage-StockInventory-Page
export default class ManageStockInventoryPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prodCode: ''
        };
    }

    componentDidMount() {
        let prodCode = '';
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            prodCode = searchParams.prodCode;
            this.setState({ prodCode });
        }
    }

    render() {
        let { prodCode } = this.state;
        return (
            <div>
                <ManageStockInventory prodCode={prodCode} history={this.props.history}/>
                <ToastContainer />
            </div>
        );
    }
}
