import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Col,
    Row,
    Table,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    InputGroupText,
    Spinner,
    Label,
    ButtonGroup
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import keywayStockService from '../../../services/KeywayStockService';
import { handleErrorMessage, changeFormatOfDateString } from '../../../services/CommonService';
import queryString from 'query-string';
import SearchStockGroupMulti from "../../../../src/components/search/SearchStockGroupMulti";
import { STOCK_QTY_FORMAT, STOCK_QTY_TYPE } from "../../../store/AppConstants";
import StockQtyFormat from "../../../components/stock/StockQtyFormat";
import moment from 'moment';
import CameraBarCodeModal from '../../../components/modal/CameraBarCodeModal';

export default class StockTakeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "lastCountedOn",
                        direction: false
                    },
                    filterRequest: {
                        prodCode: '',
                        description: '',
                        colour: '',
                        location: '',
                        selectedStockGroups: '',
                        excludeDiscontinued: -1, //0->contiune, 1->Discontiune, -1-> Both
                        excludeOffRange: -1,
                        excludeApproved: -1,
                        date: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            isUpdating: false,
            isBarcodeScannerOpen: false,
            isHiddenExtendedColumn: true
        };

        this.getStocks = this.getStocks.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.updateQuantity = this.updateQuantity.bind(this);
        this.updateLocationSearchFilter = this.updateLocationSearchFilter.bind(this);
        this.toggleScannerModal = this.toggleScannerModal.bind(this);
        this.toggleExtendedColumn = this.toggleExtendedColumn.bind(this);
    }

    componentDidMount() {
        this.getStocks(this.state.stockData);
    }

    getStocks(stockData) {
        this.setState({ loading: true });
        keywayStockService.getStockTakeList(stockData.request).then(response => {
            let data = response.data;
            (data.records || []).forEach(x => {
                x.updatedQty = 0;
                x.difference = 0;
                if (x.lastCountedQty) {
                    x.difference = x.lastCountedQty - x.qol;
                }
            });
            stockData.response.records = data.records;
            stockData.response.totalRecords = data.totalRecords;
            stockData.request.pageRequest.currentPage = data.currentPage;
            stockData.request.pageRequest.pageSize = data.pageSize;
            this.setState({ stockData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key) {
        let { stockData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change, stockData });
                break;
            case "sortKey":
                if (stockData.request.sortRequest.key === change) {
                    stockData.request.sortRequest.direction = !stockData.request.sortRequest.direction;
                } else {
                    stockData.request.sortRequest.key = change;
                    stockData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStocks(stockData);
                }
                break;
            case "pageSize":
                stockData.request.pageRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                break;
            case "currentPage":
                stockData.request.pageRequest[key] = change;
                this.getStocks(stockData);
                break;
            case "updatedQty":
                let index = change.index;
                let qty = change.qty;
                stockData.response.records[index].updatedQty = qty;
                this.setState({ stockData });
                break;
            default:
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                this.setState({ stockData });
        }

    }

    handleFilterChange(change, key) {
        let { stockData } = this.state;

        switch (key) {
            case "selectedStockGroups":
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
            case "selectedStockMasterGroups":
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
            case "selectedColour":
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
            case "excludeOffRange":
                stockData.request.filterRequest.excludeOffRange = parseInt(change);
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData }, () => {
                    this.getStocks(stockData);
                });
                break;
            case "excludeDiscontinued":
                stockData.request.filterRequest.excludeDiscontinued = parseInt(change);
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
            case "excludeApproved":
                stockData.request.filterRequest.excludeApproved = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
            default:
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
        }
    }

    getStockHeading(filterRequest) {
        let stockHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-left",
                searchNode: null,
                extendedColumn: false
            },
            {
                key: "prodCode",
                label: "Prodcode",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="prodCode" name="prodCode" value={filterRequest.prodCode}
                    onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                    placeholder="Search... " />
                </div>,
                extendedColumn: false

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="description" name="description"
                    value={filterRequest.description}
                    onChange={(e) => this.handleChange(e.target.value, "description")}
                    placeholder="Search..." /></div>,
                extendedColumn: false

            },
            {
                key: "location",
                label: "Location",
                type: "text",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="location" name="location"
                    value={filterRequest.location}
                    onChange={(e) => this.handleChange(e.target.value, "location")}
                    placeholder="Search..." />
                </div>,
                extendedColumn: false
            },
            {
                key: "qol",
                label: "QOL",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null,
                extendedColumn: false
            },
            {
                key: "lastCountedQty",
                label: "Last Counted Qty",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null,
                extendedColumn: true
            },
            {
                key: "difference",
                label: "Difference",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null,
                extendedColumn: true
            },
            {
                key: "lastCountedOn",
                label: "Last Counted On",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                extendedColumn: true
            },
            {
                key: "actualStockTake",
                label: "Enter Count",
                type: "StockTake",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                searchNode: null,
                extendedColumn: false
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                searchNode: null,
                extendedColumn: false
            }
        ];
        return stockHeading;
    }

    toggleExtendedColumn(isHiddenExtendedColumn) {
        this.setState({ isHiddenExtendedColumn });
    }

    updateQuantity(index) {
        let { stockData } = this.state;
        var data = stockData.response.records[index];
        let request = {
            locationID: data.locationID,
            prodCode: data.prodCode,
            qty: data.updatedQty,
            qolAtCount: data.qol
        }
        this.setState({ isUpdating: true });
        keywayStockService.updatedStockTake(request).then(response => {
            toast.success("Updated!");
            stockData.response.records[index].isUpdated = true;
            stockData.response.records[index].lastCountedOn = moment().format("DD/MM/YYYY");
            this.setState({ stockData, isUpdating: false }, () => this.getStocks(stockData));
        }).catch(error => {
            this.setState({ isUpdating: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    updateLocationSearchFilter(location) {
        var locationData = location.toString();
        this.handleChange(locationData, "location");
    }

    toggleScannerModal(isOpen) {
        this.setState({ isBarcodeScannerOpen: isOpen });
    }

    render() {
        let { stockData, loading, isBarcodeScannerOpen,
            isUpdating, selectedStockMasterGroups, isHiddenExtendedColumn } = this.state;
        let { pageRequest, sortRequest, filterRequest } = stockData.request;
        let stockHeading = this.getStockHeading(filterRequest);
        return (
            <div>
                <div>
                    <Row className={"mb-2 align-items-center"}>
                        <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                            Select Stock Group Parent
                            <SearchStockGroupMulti
                                handleSelection={(selectedStockGroups) => this.handleFilterChange(selectedStockGroups, "selectedStockGroups")}
                                selectedStockGroupCodes={filterRequest.selectedStockGroups}
                                defaultStockGroupCodes={""}
                                filter={{
                                    isKeywayAvailableOnly: true,
                                    stockMasterGroupCodes: selectedStockMasterGroups,
                                    stockGroupCodes: [],
                                    excludeArchived: true
                                }}
                            />
                        </Col>
                        <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <Label> Discontinued Status</Label>
                                <div>
                                    <ButtonGroup>
                                        <Button
                                            size={"sm"}
                                            onClick={() => this.handleFilterChange(1, "excludeDiscontinued")}
                                            color={"primary"}

                                            outline={filterRequest.excludeDiscontinued !== 1}>
                                            Only Discontinued
                                            {filterRequest.excludeDiscontinued === 1
                                                ? <i className="fa fa-check ml-1"
                                                    aria-hidden="true" />
                                                : null
                                            }</Button>

                                        <Button
                                            onClick={() => this.handleFilterChange(0, "excludeDiscontinued")}
                                            color={"primary"}
                                            size={"sm"}
                                            outline={filterRequest.excludeDiscontinued !== 0}>
                                            Only Continued
                                            {filterRequest.excludeDiscontinued === 0
                                                ?
                                                <i className="fa fa-check ml-1" aria-hidden="true" />
                                                : null
                                            }</Button>
                                        <Button
                                            onClick={() => this.handleFilterChange(-1, "excludeDiscontinued")}
                                            color={"primary"}
                                            size={"sm"}
                                            outline={filterRequest.excludeDiscontinued !== -1}>
                                            All
                                            {filterRequest.excludeDiscontinued === -1
                                                ?
                                                <i className="fa fa-check ml-1" aria-hidden="true" />
                                                : null
                                            }</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} md={6} sm={12} xs={12} className={"mb-2"}>
                            <div>
                                <div>
                                    <Label>Range Status</Label>
                                </div>
                                <div className="d-inline-block mr-3">
                                    <ButtonGroup>
                                        <Button
                                            size={"sm"}
                                            onClick={() => this.handleFilterChange(1, "excludeOffRange")}
                                            color={"primary"}

                                            outline={filterRequest.excludeOffRange !== 1}>
                                            Only Off Range
                                            {filterRequest.excludeOffRange === 1
                                                ? <i className="fa fa-check ml-1"
                                                    aria-hidden="true" />
                                                : null
                                            }
                                        </Button>
                                        <Button
                                            onClick={() => this.handleFilterChange(0, "excludeOffRange")}
                                            color={"primary"}
                                            size={"sm"}
                                            outline={filterRequest.excludeOffRange !== 0}>
                                            Only Range
                                            {filterRequest.excludeOffRange === 0
                                                ?
                                                <i className="fa fa-check ml-1" aria-hidden="true" />
                                                : null
                                            }
                                        </Button>
                                        <Button
                                            onClick={() => this.handleFilterChange(-1, "excludeOffRange")}
                                            color={"primary"}
                                            size={"sm"}
                                            outline={filterRequest.excludeOffRange !== -1}>
                                            All
                                            {filterRequest.excludeOffRange === -1
                                                ?
                                                <i className="fa fa-check ml-1" aria-hidden="true" />
                                                : null
                                            }
                                        </Button>
                                    </ButtonGroup>
                                    <ButtonGroup>
                                        <Button
                                            color={"primary"}
                                            size={"sm"}
                                            className={"ml-2"}
                                            outline={isHiddenExtendedColumn}
                                            onClick={() => this.toggleExtendedColumn(!isHiddenExtendedColumn)}
                                        >
                                            <i className={classnames("fa", "mr-2", {
                                                "fa-eye": isHiddenExtendedColumn,
                                                "fa-eye-slash": !isHiddenExtendedColumn,
                                            })} aria-hidden="true" />
                                            {isHiddenExtendedColumn ? "Show extended columns" : "Hide extended columns"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className={"text-left"}>
                    <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                        <div className={"text-left mr-2"}>
                            {loading ? <p>Loading...</p> :
                                <p>Showing
                                    {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {stockData.response.totalRecords}
                                    {' '}entries&nbsp;&nbsp;
                                </p>
                            }
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Button color={"primary"} size='sm' outline={true}
                            disabled={isBarcodeScannerOpen}
                            onClick={() => this.toggleScannerModal(true)}>
                            Scan Location
                        </Button>
                     </Col>
                </Row>
                <div>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                            <tr>
                                {(stockHeading || []).filter(item => isHiddenExtendedColumn ? (!item.extendedColumn) : true).map((item, index) => (
                                    <th key={index}
                                        onClick={() => this.handleChange(item.key, item.sorterApplicable ? "sortKey" : "nothing")}
                                        colSpan={item.colSpan}
                                        className={item.labelClassName}
                                        style={{ minWidth: item.minWidth }}>
                                        {item.label}
                                        {item.sorterApplicable &&
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== item.key),
                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                            })} aria-hidden="true" />
                                        }
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                {(stockHeading || []).filter(item => isHiddenExtendedColumn ? (!item.extendedColumn) : true).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {(stockData.response.records || []).map((stock, index) => {
                                    return (
                                        <tr key={index} className="text-center align-middle">
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td className="text-center align-middle">
                                                <h5>
                                                    <Link
                                                        className="btn btn-primary btn-sm"
                                                        style={{ color: "white" }}
                                                        title={"Click here to see stock enquiry"}
                                                        to={"/inventory/stock/enquiry?" + queryString.stringify({ prodCode: stock.prodCode })}
                                                    >
                                                        {stock.prodCode}
                                                    </Link>
                                                </h5>
                                            </td>
                                            <td className="align-middle" style={{ textAlign: 'left' }}>
                                                <div>{stock.description}</div>
                                                {stock.colour && <div>{"Colour: " + stock.colour}</div>}
                                                {(stock.length > 0 || stock.width > 0 || stock.height > 0) && (
                                                    <div>
                                                        {stock.length > 0 && <span className="mr-1">{"Length: " + stock.length + stock.dimensionUnitName}</span>}
                                                        {stock.width > 0 && <span className="ml-1 mr-1">{"Width: " + stock.width + stock.dimensionUnitName}</span>}
                                                        {stock.height > 0 && <span className="ml-1 mr-1">{"Height: " + stock.height + stock.dimensionUnitName}</span>}
                                                    </div>
                                                )}
                                            </td>


                                            <td className="text-center align-middle">{stock.location}</td>
                                            <td className={"text-center align-middle"}>
                                                <StockQtyFormat
                                                    eachUnitName={stock.eachUnitName}
                                                    whUnitName={stock.whUnitName}
                                                    whUnitQty={stock.whUnitQty}
                                                    qty={stock.qol}
                                                    qtyType={STOCK_QTY_TYPE.each}
                                                    format={STOCK_QTY_FORMAT.each}
                                                    emptyPlaceholderText={"-"}
                                                    isPoManage={false}
                                                />
                                            </td>
                                            {!this.state.isHiddenExtendedColumn &&
                                                <>
                                                    <td className={"text-center align-middle"}>
                                                        <StockQtyFormat
                                                            eachUnitName={stock.eachUnitName}
                                                            whUnitName={stock.whUnitName}
                                                            whUnitQty={stock.whUnitQty}
                                                            qty={stock.lastCountedQty}
                                                            qtyType={STOCK_QTY_TYPE.each}
                                                            format={STOCK_QTY_FORMAT.each}
                                                            emptyPlaceholderText={"-"}
                                                            isPoManage={false}
                                                        />
                                                    </td>
                                                    <td className={"text-center align-middle"}>
                                                        <StockQtyFormat
                                                            eachUnitName={stock.eachUnitName}
                                                            whUnitName={stock.whUnitName}
                                                            whUnitQty={stock.whUnitQty}
                                                            qty={stock.difference}
                                                            qtyType={STOCK_QTY_TYPE.each}
                                                            format={STOCK_QTY_FORMAT.each}
                                                            emptyPlaceholderText={"-"}
                                                            isPoManage={false}
                                                        />
                                                    </td>
                                                    <td className="text-center align-middle">{changeFormatOfDateString(stock.requestedDate, "DD/MM/YYYY", "DD MMM YYYY")}</td>
                                                </>
                                            }
                                            <td className="text-center align-middle">
                                                <Input
                                                    type={"number"}
                                                    name={"updatedStock"}
                                                    value={stock.updatedQty}
                                                    onChange={(e) => this.handleChange({ index: index, qty: e.target.value }, 'updatedQty')}
                                                />
                                            </td>
                                            <td className="text-center align-middle">
                                                {stock.transactionStatus === 'Pending' ?
                                                    <Button color='success'
                                                        size='sm'
                                                        onClick={() => this.updateQuantity(index)}>
                                                        {isUpdating ?
                                                            <Spinner size='sm' className='mr-2' color="success" />
                                                            : null}
                                                        Submitted
                                                    </Button>
                                                    :
                                                    <Button color='primary'
                                                        size='sm'
                                                        onClick={() => this.updateQuantity(index)}>
                                                        {isUpdating ?
                                                            <Spinner size='sm' className='mr-2' color="primary" />
                                                            : null}
                                                        Update
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    );
                            })}
                        </tbody>
                    </Table>
                </div>
                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{ maxWidth: 200 }}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>

                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={stockData.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>
                    </Col>
                </Row>
                {isBarcodeScannerOpen ?
                    <CameraBarCodeModal
                        isOpen={isBarcodeScannerOpen}
                        toggle={this.toggleScannerModal}
                        updateBarcodeData={this.updateLocationSearchFilter}
                    /> : null  
                }
                <ToastContainer />
            </div>
        );
    }
}
