import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import {
    findIndex, round, findItemHavingMaxValue
} from '../../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {DEBTOR_CATEGORY, PRODUCT_BUILDER_ZIPTRAK_EXTERNAL_PRODCODE} from "../../../../../../../store/AppConstants";

class ZiptrakExternalUtil {

    static Instance() {
        return new ZiptrakExternalUtil();
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        let isMotorised = this.isMotorised(product, itemIndex, order);
        let isPVC = this.isPVCItem(product, itemIndex, order);
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > 50) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than 50 characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    if (!isMotorised && !isPVC) {
                        formError = {
                            isValid: false,
                            message: "Max width for Spring operation is " + product.items[itemIndex].configuration.width.max + " with provided drop",
                        };
                    }
                    else {
                        formError = {
                            isValid: false,
                            message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                        };
                    }
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && product.items[itemIndex].configuration.hooding.selected.value.optionKey === "125mm Flair Pelmet" && !isEmpty(product.items[itemIndex].configuration.fabricType.selected.value) && ["Vistaweave 99", "Outlook Mode Privacy 99", "PVC 1.00mm"].includes(product.items[itemIndex].configuration.fabricType.selected.value.optionKey)) {
                        formError = {
                            isValid: false,
                            message: "Max drop of 125mm Flair Pelmet with selected fabric is " + product.items[itemIndex].configuration.drop.max + "mm",
                        };
                    }
                    else if (!isMotorised && !isPVC) {
                        formError = {
                            isValid: false,
                            message: "Max drop for Spring operation is " + product.items[itemIndex].configuration.drop.max + " with provided width",
                        };
                    }
                    else {
                        formError = {
                            isValid: false,
                            message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                        };
                    }
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "fabricColourOffRange":
                if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.fabricColourOffRange.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Fabric name can't be empty",
                    };
                }
                product.items[itemIndex].configuration.fabricColourOffRange.formError = formError;
                break;
            case "customerSupplyMotorType":
                if (product.items[itemIndex].configuration.customerSupplyMotorType.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.customerSupplyMotorType.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Customer supply motor type can't be empty",
                    };
                }
                product.items[itemIndex].configuration.customerSupplyMotorType.formError = formError;
                break;
            case "hoodingPowdercoatColour":
                if (product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Hooding powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.hoodingPowdercoatColour.formError = formError;
                break;
            case "framePowdercoatColour":
                if (product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.framePowdercoatColour.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Frame powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.framePowdercoatColour.formError = formError;
                break;
            case "framePowdercoatColourRight":
                if (product.items[itemIndex].configuration.framePowdercoatColourRight.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.framePowdercoatColourRight.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Frame powdercoat colour right can't be empty",
                    };
                }
                product.items[itemIndex].configuration.framePowdercoatColourRight.formError = formError;
                break;
            case "bracketColourPowdercoat":
                if (product.items[itemIndex].configuration.bracketColourPowdercoat.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Bracket powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.bracketColourPowdercoat.formError = formError;
                break;
            case "bottomRailDuty":
                switch (product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey) {
                    case "STD":
                    case "HD":
                        break;
                    case "XHD":
                        if (product.items[itemIndex].configuration.width.selected.value > 5100 && product.items[itemIndex].configuration.drop.selected.value > 2700) {
                            if (!this.isMotorised(product, itemIndex, order) && !isPVC) {
                                formError = {
                                    isValid: false,
                                    message: "If XHD, then over 5100mm wide x 2700mm high, it needs motorization"
                                };
                            }
                        }
                        break;
                }
                product.items[itemIndex].configuration.bottomRailDuty.formError = formError;
                break;
            case "bottomRailColourPowdercoat":
                if (product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Bottom Rail powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.bottomRailColourPowdercoat.formError = formError;
                break;
            case "backFlashingCutColour":
                if (product.items[itemIndex].configuration.backFlashingCut.selected.value &&
                    product.items[itemIndex].configuration.backFlashingCutColour.selected.isSelectable &&
                    isEmpty(product.items[itemIndex].configuration.backFlashingCutColour.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Back Flashing Cut colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.backFlashingCutColour.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.fabricColourOffRange.formError.isValid
            && product.items[itemIndex].configuration.customerSupplyMotorType.formError.isValid
            && product.items[itemIndex].configuration.hoodingPowdercoatColour.formError.isValid
            && product.items[itemIndex].configuration.framePowdercoatColour.formError.isValid
            && product.items[itemIndex].configuration.framePowdercoatColourRight.formError.isValid
            && product.items[itemIndex].configuration.bracketColourPowdercoat.formError.isValid
            && product.items[itemIndex].configuration.bottomRailDuty.formError.isValid
            && product.items[itemIndex].configuration.bottomRailColourPowdercoat.formError.isValid
            && product.items[itemIndex].configuration.backFlashingCutColour.formError.isValid;
    }


    updateRules(key, product, itemIndex, order) {
        let optionIndex, isMotorised, isPVC;
        isMotorised = this.isMotorised(product, itemIndex, order);
        isPVC = this.isPVCItem(product, itemIndex, order);
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        switch (key) {
            case "width":
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && ["125mm Flair Pelmet", "148mm Flair Pelmet"].includes(product.items[itemIndex].configuration.hooding.selected.value.optionKey)) {
                        product.items[itemIndex].configuration.width.max = 5800;
                    } else if (isMotorised) {
                        product.items[itemIndex].configuration.width.min = 800;
                        product.items[itemIndex].configuration.width.max = isPVC ? 5500 : 6000;
                    } else {
                        product.items[itemIndex].configuration.width.min = 625;
                        if (isPVC) {
                            product.items[itemIndex].configuration.width.max = isPVC ? 5500 : 6000;
                        } else {
                            let temp = product.items[itemIndex].configuration.operation.selected.value.sets.filter(set => ((set.condition1 === "matrix") && (set.condition2 === "mesh") && (drop >= set.secondaryMin) && (drop <= set.secondaryMax)));
                            product.items[itemIndex].configuration.width.max = findItemHavingMaxValue(temp, "max");
                        }
                    }
                }
                break;
            case "topWidth":
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && ["125mm Flair Pelmet", "148mm Flair Pelmet"].includes(product.items[itemIndex].configuration.hooding.selected.value.optionKey)) {
                        product.items[itemIndex].configuration.topWidth.max = 5800;
                    } else if (isMotorised) {
                        product.items[itemIndex].configuration.topWidth.min = 800;
                        product.items[itemIndex].configuration.topWidth.max = isPVC ? 5500 : 6000;
                    } else {
                        product.items[itemIndex].configuration.topWidth.min = 625;
                        if (isPVC) {
                            product.items[itemIndex].configuration.topWidth.max = isPVC ? 5500 : 6000;
                        }
                    }
                }
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 500;
                if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && product.items[itemIndex].configuration.hooding.selected.value.optionKey === "148mm Flair Pelmet") {
                    product.items[itemIndex].configuration.drop.max = 3500;
                } else if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && product.items[itemIndex].configuration.hooding.selected.value.optionKey === "125mm Flair Pelmet") {
                    if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        if (["Vistaweave 99", "Outlook Mode Privacy 99", "PVC 1.00mm"].includes(product.items[itemIndex].configuration.fabricType.selected.value.optionKey)) {
                            product.items[itemIndex].configuration.drop.max = 2100;
                        } else {
                            product.items[itemIndex].configuration.drop.max = 2700;
                        }
                    }
                } else if (isMotorised) {
                    product.items[itemIndex].configuration.drop.max = 3500;
                } else {
                    product.items[itemIndex].configuration.drop.max = 3500;
                    if (!isPVC) {
                        let temp = product.items[itemIndex].configuration.operation.selected.value.sets.filter(set => ((set.condition1 === "matrix") && (set.condition2 === "mesh") && (width >= set.min) && (width <= set.max)));
                        product.items[itemIndex].configuration.drop.max = findItemHavingMaxValue(temp, "secondaryMax");
                    }
                }
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = product.items[itemIndex].configuration.fabricType.selected.value.optionKey === "Off Range";
                product.items[itemIndex].configuration.fabricColour.selected.isSelectable = !product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable;
                break;
            case "hoodingColour":
                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                    case "No Hood":
                        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = false;
                        product.items[itemIndex].configuration.hoodingColour.selected.value = {};
                        product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = "";
                        break;
                    default:
                        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = true;
                }
                break;
            case "bracketType":
                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                    case "No Hood":
                        product.items[itemIndex].configuration.bracketType.selected.isSelectable = true;
                        if (isEmpty(product.items[itemIndex].configuration.bracketType.selected.value)) {
                            if (!isEmpty(product.items[itemIndex].configuration.bracketType.selected.value)) {
                                optionIndex = findIndex(product.items[itemIndex].configuration.bracketType.finalOptions, "optionKey", product.items[itemIndex].configuration.bracketType.selected.value.optionKey);
                            }
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                            product.items[itemIndex].configuration.bracketType.selected.value = product.items[itemIndex].configuration.bracketType.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.bracketType.selected.dropdownValue = product.items[itemIndex].configuration.bracketType.selected.value.optionKey;
                        }

                        if (isEmpty(product.items[itemIndex].configuration.bracketColour.selected.value)) {
                            if (!isEmpty(product.items[itemIndex].configuration.bracketColour.selected.value)) {
                                optionIndex = findIndex(product.items[itemIndex].configuration.bracketColour.finalOptions, "optionKey", product.items[itemIndex].configuration.bracketColour.selected.value.optionKey);
                            }
                            optionIndex = optionIndex > -1 ? optionIndex : 0;
                            product.items[itemIndex].configuration.bracketColour.selected.value = product.items[itemIndex].configuration.bracketColour.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;
                        }
                        break;
                    default:
                        product.items[itemIndex].configuration.bracketType.selected.isSelectable = false;
                        product.items[itemIndex].configuration.bracketType.selected.value = {};
                        product.items[itemIndex].configuration.bracketType.selected.dropdownValue = "";

                        product.items[itemIndex].configuration.bracketColour.selected.value = {};
                        product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = "";

                        product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = "";
                }
                break;
            case "centralLock":
                if (isMotorised) {
                    product.items[itemIndex].configuration.centralLock.selected.isSelectable = false;
                    product.items[itemIndex].configuration.centralLock.selected.value = {};
                    product.items[itemIndex].configuration.centralLock.selected.dropdownValue = "";
                } else {
                    product.items[itemIndex].configuration.centralLock.selected.isSelectable = true;
                }
                break;
            case "motorAccessories":
                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                    case "Manual/Spring":
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                        break;
                    case "Windoware RTS A/C Motor":
                    case "Windoware 4-Core A/C Motor":
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = true;
                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = true;
                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = true;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = true;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                        break;
                    case "Windoware DC Solar Motor":
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = true;
                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = true;
                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = true;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = true;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = true;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = true;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                        break;
                    case "Somfy RTS Motor":
                    case "Somfy RTS Motor Altus":
                    case "Somfy RTS Motor Maestria":
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = true;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = true;
                        break;
                    case "Motorised- Customer Supply Motor":
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;
                        product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible = false;
                        break;
                }

                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable ? product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value : false;
                product.items[itemIndex].configuration.windowareBridge.selected.value = product.items[itemIndex].configuration.windowareBridge.selected.isSelectable ? product.items[itemIndex].configuration.windowareBridge.selected.value : false;
                product.items[itemIndex].configuration.chargingCable.selected.value = product.items[itemIndex].configuration.chargingCable.selected.isSelectable ? product.items[itemIndex].configuration.chargingCable.selected.value : false;
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isSelectable ? product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value : false;
                break;
            case "customerSupplyMotorType":
                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                    case "Manual/Spring":
                    case "Windoware RTS A/C Motor":
                    case "Windoware 4-Core A/C Motor":
                    case "Windoware DC Solar Motor":
                    case "Somfy RTS Motor":
                    case "Somfy RTS Motor Altus":
                    case "Somfy RTS Motor Maestria":
                        product.items[itemIndex].configuration.customerSupplyMotorType.selected.isSelectable = false;
                        product.items[itemIndex].configuration.customerSupplyMotorType.selected.value = "";
                        break;
                    case "Motorised- Customer Supply Motor":
                        product.items[itemIndex].configuration.customerSupplyMotorType.selected.isSelectable = true;
                        break;
                }
                break;
            case "hoodingPowdercoatColour":
                product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = "";
                    }
                }
                break;
            case "framePowdercoatColour":
                product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.frameColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.frameColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
                    }
                }
                break;
            case "framePowdercoatColourRight":
                product.items[itemIndex].configuration.framePowdercoatColourRight.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.frameColourRight.selected.value)) {
                    switch (product.items[itemIndex].configuration.frameColourRight.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.framePowdercoatColourRight.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.framePowdercoatColourRight.selected.value = "";
                    }
                }
                break;
            case "bracketColourPowdercoat":
                product.items[itemIndex].configuration.bracketColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.bracketColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.bracketColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.bracketColourPowdercoat.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = "";
                    }
                }
                break;
            case "motorSide":
                if (isMotorised) {
                    product.items[itemIndex].configuration.motorSide.selected.isSelectable = true;
                } else {
                    product.items[itemIndex].configuration.motorSide.selected.value = {};
                    product.items[itemIndex].configuration.motorSide.selected.dropdownValue = "";
                    product.items[itemIndex].configuration.motorSide.selected.isSelectable = false;
                }
                break;
            case "pullDownStick":
                if (isMotorised) {
                    product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = false;
                    product.items[itemIndex].configuration.pullDownStick.selected.value = false;
                } else {
                    product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = true;
                }
                break;
            case "additionalBottomStoppers":
                if (isMotorised) {
                    product.items[itemIndex].configuration.additionalBottomStoppers.selected.isSelectable = false;
                    product.items[itemIndex].configuration.additionalBottomStoppers.selected.value = 0;
                } else {
                    product.items[itemIndex].configuration.additionalBottomStoppers.selected.isSelectable = true;
                }
                break;
            case "centralLockFaceFixStoppers":
                if (!isMotorised && (product.items[itemIndex].configuration.channels.selected.dropdownValue.includes("Face Fix") || product.items[itemIndex].configuration.channelsRight.selected.dropdownValue.includes("Face Fix"))  ) {
                    product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.isSelectable = true;
                } else {
                    product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.isSelectable = false;
                    product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.value = false;
                }
                break;
            case "insectControlBrush":
                product.items[itemIndex].configuration.insectControlBrush.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                        case "165mm Pelmet":
                        case "135mm Ziptrak Pelmet":
                            product.items[itemIndex].configuration.insectControlBrush.selected.isSelectable = true;
                            break;
                        case "No Hood":
                        case "125mm Flair Pelmet":
                        case "148mm Flair Pelmet":
                        case "175mm Pelmet":
                            product.items[itemIndex].configuration.insectControlBrush.selected.isSelectable = false;
                            product.items[itemIndex].configuration.insectControlBrush.selected.value = false;
                            break;
                    }
                }
                //product.items[itemIndex].configuration.insectControlBrush.selected.value = product.items[itemIndex].configuration.insectControlBrush.selected.isSelectable ? product.items[itemIndex].configuration.insectControlBrush.selected.isSelectable : false;
                break;
            case "bottomRailColourPowdercoat":
                product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.bottomRailColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable) {
                    product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = "";
                }
                break;
            case "backFlashingCut":
                product.items[itemIndex].configuration.backFlashingCut.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.bracketType.selected.value)) {
                    switch (product.items[itemIndex].configuration.bracketType.selected.value.optionKey) {
                        case "Cast End Brackets":
                            product.items[itemIndex].configuration.backFlashingCut.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.backFlashingCut.selected.isSelectable = false;
                            product.items[itemIndex].configuration.backFlashingCut.selected.value = false;
                            break;
                    }
                }
                else {
                    product.items[itemIndex].configuration.backFlashingCut.selected.isSelectable = false;
                    product.items[itemIndex].configuration.backFlashingCut.selected.value = false;
                }
                break;
            case "backFlashingCutColour":
                let isFlashBackSelected = product.items[itemIndex].configuration.backFlashingCut.selected.value
                if (!isFlashBackSelected) {
                    product.items[itemIndex].configuration.backFlashingCutColour.selected.isSelectable = false;
                    product.items[itemIndex].configuration.backFlashingCutColour.selected.value = {};
                    product.items[itemIndex].configuration.backFlashingCutColour.selected.dropdownValue = "";
                } else {
                    product.items[itemIndex].configuration.backFlashingCutColour.selected.isSelectable = true;
                }
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let options, optionIndex, sets, setIndex, stocks, condition1, condition2, label, attribute,
            isPVC, topWidth,
            width, drop, isMotorised, comment,
            temp, deduction, defaultOptionIndex;
        width = product.items[itemIndex].configuration.width.selected.value;
        topWidth = product.items[itemIndex].configuration.topWidth.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        isMotorised = this.isMotorised(product, itemIndex, order);

        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: PRODUCT_BUILDER_ZIPTRAK_EXTERNAL_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;


                break;
            case "operation":
                attribute = "Operation";//Need to change the operation exact value with options.
                comment = "";
                label = "";
                stocks = [];
                isPVC = this.isPVCItem(product, itemIndex, order);
                optionIndex = 0;
                if (isMotorised) {
                    label = "Motorised";
                    if (product.items[itemIndex].configuration.operation.selected.value.optionKey === "Motorised- Customer Supply Motor") {
                        comment = product.items[itemIndex].configuration.customerSupplyMotorType;
                    }
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Manual/Spring":
                        case "Windoware RTS A/C Motor":
                        case "Windoware 4-Core A/C Motor":
                        case "Windoware DC Solar Motor":
                        case "Somfy RTS Motor":
                        case "Motorised- Customer Supply Motor":
                            optionIndex = 0;
                            break;
                        case "Somfy RTS Motor Altus":
                        case "Somfy RTS Motor Maestria":
                            condition1 = "matrix";
                            temp = product.items[itemIndex].configuration.operation.selected.value.sets.find(set => ((set.condition1 === condition1) && (width >= set.min) && (width <= set.max) && (drop >= set.secondaryMin) && (drop <= set.secondaryMax)));
                            if (temp) {
                                condition1 = "core";
                                condition2 = temp.description;
                                optionIndex = product.items[itemIndex].configuration.operation.selected.value.sets.findIndex(set => set.condition1 === condition1 && set.condition2 === condition2);
                            }
                            break;
                    }
                } else {
                    label = "Manual";
                    condition1 = "matrix";
                    condition2 = isPVC ? "pvc" : "mesh";
                    let condition3 = product.items[itemIndex].configuration.bottomRailDuty.selected ? product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey : '';
                    if (condition3 == 'XHD') {
                        temp = product.items[itemIndex].configuration.operation.selected.value.sets.find(set => ((set.condition1 === condition1) && (set.condition3 === condition3) && (width >= set.min) && (width <= set.max) && (drop >= set.secondaryMin) && (drop <= set.secondaryMax)));
                        temp = temp ? temp : product.items[itemIndex].configuration.operation.selected.value.sets[product.items[itemIndex].configuration.operation.selected.value.sets.length - 1];
                        if (temp) {
                            condition1 = "core";
                            condition2 = temp.description;
                            optionIndex = product.items[itemIndex].configuration.operation.selected.value.sets.findIndex(set => set.condition1 === condition1 && set.description === condition2);
                        }
                    } else {
                        temp = product.items[itemIndex].configuration.operation.selected.value.sets.find(set => ((set.condition1 === condition1) && (set.condition2 === condition2) && (width >= set.min) && (width <= set.max) && (drop >= set.secondaryMin) && (drop <= set.secondaryMax)));
                        temp = temp ? temp : product.items[itemIndex].configuration.operation.selected.value.sets[product.items[itemIndex].configuration.operation.selected.value.sets.length - 1];
                        if (temp) {
                            condition1 = "core";
                            condition2 = temp.description;
                            optionIndex = product.items[itemIndex].configuration.operation.selected.value.sets.findIndex(set => set.condition1 === condition1 && set.description === condition2);
                        }
                    }
                }
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value.selectedSet = product.items[itemIndex].configuration.operation.selected.value.sets[optionIndex];
                    stocks = product.items[itemIndex].configuration.operation.selected.value.selectedSet.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.operation.selected.stocks = stocks;
                break;
            case "keyway":
                attribute = "Keyway";
                comment = "";
                label = product.items[itemIndex].configuration.keyway.selected.value.selectedSet.description;
                stocks = [];
                condition1 = "core";
                condition2 = product.items[itemIndex].configuration.keyway.selected.value.selectedSet.description;
                optionIndex = product.items[itemIndex].configuration.keyway.selected.value.sets.findIndex(set => set.condition1 === condition1 && set.condition2 === condition2);
                if (optionIndex > -1) {
                    stocks = product.items[itemIndex].configuration.keyway.selected.value.sets[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.keyway.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                    stocks = [{
                        id: null,
                        description: "",
                        prodCode: product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                        price: 0,
                        flatPrice: 0,
                        quantityMultiplier: 1,
                        qtyFormulaId: null,
                        productConfigurationOptionId: null,
                        productConfigurationOptionSetId: null,
                        swishQuantityFormula: product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula,
                        keywayQuantityFormula: product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula,
                        wastageFormula: product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula,
                        calculatedQty: 0,
                        stockPickSlipQty: 0,
                        keywayMeasurementUnit: "mm",
                        swishMeasurementUnit: "mtr",
                        keywayConversionFactor: 1000,
                        swishConversionFactor: 1,
                        isVisibleInPartList: true,
                        isDeductionApplicable: true
                    }];
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "splineColour":
                attribute = "Spline";
                label = product.items[itemIndex].configuration.splineColour.selected.value.optionKey;
                comment = "";
                stocks = [];
                isPVC = this.isPVCItem(product, itemIndex, order);
                condition1 = isPVC ? "pvc" : "mesh";
                optionIndex = product.items[itemIndex].configuration.splineColour.selected.value.sets.findIndex(set => set.condition1 === condition1);
                if (optionIndex > -1) {
                    stocks = product.items[itemIndex].configuration.splineColour.selected.value.sets[optionIndex].stocks;
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.splineColour.selected.stocks = stocks;
                break;
            case "hoodingColour":
                attribute = "Hooding";
                label = this.generateHoodingLabel(product, itemIndex);
                comment = "";
                stocks = [];
                isPVC = this.isPVCItem(product, itemIndex, order);
                if (product.items[itemIndex].configuration.hooding.selected.value.optionKey !== "No Hood") {
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value.sets)) {
                        sets = product.items[itemIndex].configuration.hooding.selected.value.sets;
                        setIndex = sets.findIndex(set => ((width >= set.min) && (width <= set.max)));
                        setIndex = setIndex > -1 ? setIndex : sets.length - 1;
                        (sets[setIndex].stocks || []).forEach(s => s.comment = comment);
                        stocks = [...stocks, ...sets[setIndex].stocks];
                    }
                }

                //hooding stock codes===================
                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                    if (isMotorised) {
                        condition1 = "motorised";
                    } else {
                        condition1 = "Manual/Spring";
                    }
                    //add width condition
                    optionIndex = product.items[itemIndex].configuration.hoodingColour.selected.value.sets.findIndex(set => set.condition1.includes(condition1) && ((width >= set.min) && (width <= set.max)));
                    if (optionIndex > -1) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.hoodingColour.selected.value.sets[optionIndex].stocks];
                    }
                }
                //add back-cover bracket stock code (165mm, 135mm ziptrak pelmet) 
                optionIndex = product.items[itemIndex].configuration.hoodingColour.options.findIndex(x => x.description === product.items[itemIndex].configuration.hooding.selected.value.optionKey);
                if (optionIndex > -1) {
                    setIndex = product.items[itemIndex].configuration.hoodingColour.options[optionIndex].sets.findIndex(set => ((width >= set.min) && (width <= set.max)));
                    if (setIndex > -1) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.hoodingColour.options[optionIndex].sets[setIndex].stocks];
                    }
                }
                //&& add plastic insert zone
                optionIndex = findIndex(product.items[itemIndex].configuration.hooding.options, "optionKey", "No Hood");
                if (optionIndex > -1) {
                    setIndex = product.items[itemIndex].configuration.hooding.options[optionIndex].sets.findIndex(s => this.hoodingOptionSetCondition(s, width, drop, isPVC)
                        && s.description === product.items[itemIndex].configuration.hooding.selected.value.optionKey
                        && s.setLabel === product.items[itemIndex].configuration.hooding.selected.value.optionKey);
                    if (setIndex > -1) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.hooding.options[optionIndex].sets[setIndex].stocks];
                    }

                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.hoodingColour.selected.stocks = stocks;
                break;
            case "bracketType":
                attribute = "Bracket";
                label = product.items[itemIndex].configuration.bracketType.selected.value.optionKey;
                comment = "";
                stocks = [];

                if (!isEmpty(product.items[itemIndex].configuration.bracketType.selected.value)) {
                    condition1 = product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;
                    if (isMotorised) {
                        condition2 = "motorised";
                    } else {
                        condition2 = "Manual/Spring";
                    }

                    optionIndex = product.items[itemIndex].configuration.bracketType.selected.value.sets.findIndex(set => set.condition1 === condition1 && set.condition2 === condition2);
                    if (optionIndex > -1) {
                        stocks = product.items[itemIndex].configuration.bracketType.selected.value.sets[optionIndex].stocks;
                    }
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                }
                product.items[itemIndex].configuration.bracketType.selected.stocks = stocks;
                break;
            case "channels":
                attribute = "Channels Left";
                label = product.items[itemIndex].configuration.channels.selected.value.optionKey;
                comment = "";
                stocks = [];
                deduction = this.updateDeduction("channels", product, itemIndex);
                options = (product.items[itemIndex].configuration.channels.options || []).filter(o => o.parentOptionId === product.items[itemIndex].configuration.channels.selected.value.id);
                (options || []).forEach(o => {
                    optionIndex = -1;
                    comment = o.optionKey;

                    switch (o.optionKey) {
                        case "Track":
                        case "U channel":
                            condition1 = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                            optionIndex = o.sets.findIndex(set => set.condition1 === condition1 && ((deduction.cutDrop >= set.min) && (deduction.cutDrop <= set.max)));
                            break;
                        case "Facefix Channel":

                            // stock is at two level, 1st at set level, then 2nd at option level
                            //1. set level for extra price
                            condition1 = "price";
                            sets = o.sets.filter(set => set.condition1 === condition1);
                            setIndex = (sets || []).findIndex(set => ((drop >= set.min) && (drop <= set.max)));
                            setIndex = setIndex > -1 ? setIndex : sets.length - 1;
                            (sets[setIndex].stocks || []).forEach(s => s.comment = comment);
                            stocks = [...stocks, ...sets[setIndex].stocks];

                            //2. option level stock----------
                            condition1 = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                            optionIndex = o.sets.findIndex(set => set.condition1 === condition1 && ((deduction.cutDrop >= set.min) && (deduction.cutDrop <= set.max)));
                            break;
                        case "Guide Funnel":
                        case "Top Stops":
                            condition1 = isMotorised ? "motorised" : "Manual/Spring";
                            optionIndex = o.sets.findIndex(set => set.condition1 === condition1);
                    }
                    if (optionIndex > -1) {
                        (o.sets[optionIndex].stocks || []).forEach(s => s.comment = comment);
                        stocks = [...stocks, ...o.sets[optionIndex].stocks];
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.channels.selected.stocks = stocks;
                break;
            case "channelsRight":
                attribute = "Channels Right";
                label = product.items[itemIndex].configuration.channelsRight.selected.value.optionKey;
                comment = "";
                stocks = [];
                deduction = this.updateDeduction("channels", product, itemIndex);
                options = (product.items[itemIndex].configuration.channelsRight.options || []).filter(o => o.parentOptionId === product.items[itemIndex].configuration.channelsRight.selected.value.id);
                (options || []).forEach(o => {
                    optionIndex = -1;
                    comment = o.optionKey;

                    switch (o.optionKey) {
                        case "Track":
                        case "U channel":
                            condition1 = product.items[itemIndex].configuration.frameColourRight.selected.value.optionKey;
                            optionIndex = o.sets.findIndex(set => set.condition1 === condition1 && ((deduction.cutDrop >= set.min) && (deduction.cutDrop <= set.max)));
                            break;
                        case "Facefix Channel":

                            // stock is at two level, 1st at set level, then 2nd at option level
                            //1. set level for extra price
                            condition1 = "price";
                            sets = o.sets.filter(set => set.condition1 === condition1);
                            setIndex = (sets || []).findIndex(set => ((drop >= set.min) && (drop <= set.max)));
                            setIndex = setIndex > -1 ? setIndex : sets.length - 1;
                            (sets[setIndex].stocks || []).forEach(s => s.comment = comment);
                            stocks = [...stocks, ...sets[setIndex].stocks];

                            //2. option level stock----------
                            condition1 = product.items[itemIndex].configuration.frameColourRight.selected.value.optionKey;
                            optionIndex = o.sets.findIndex(set => set.condition1 === condition1 && ((deduction.cutDrop >= set.min) && (deduction.cutDrop <= set.max)));
                            break;
                        case "Guide Funnel":
                        case "Top Stops":
                            optionIndex = -1;
                            //Guide Funnel && Top Stops should not applicable in right channel
                    }
                    if (optionIndex > -1) {
                        (o.sets[optionIndex].stocks || []).forEach(s => s.comment = comment);
                        stocks = [...stocks, ...o.sets[optionIndex].stocks];
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.channelsRight.selected.stocks = stocks;
                break;
            case "centralLock":
                attribute = "Central Lock";
                label = product.items[itemIndex].configuration.centralLock.selected.value.optionKey;
                comment = "";
                stocks = [];
                if (!isMotorised) {
                    condition1 = product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey;
                    sets = product.items[itemIndex].configuration.centralLock.selected.value.sets.filter(s => s.condition1.includes(condition1));
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                }
                product.items[itemIndex].configuration.centralLock.selected.stocks = stocks;
                break;
            case "bottomRailFinish":
                attribute = "Bottom Rail Finish";
                label = product.items[itemIndex].configuration.bottomRailFinish.selected.value.optionKey;
                comment = "";
                stocks = product.items[itemIndex].configuration.bottomRailFinish.selected.value.stocks;
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bottomRailFinish.selected.stocks = stocks;
                break;
            case "bottomRailDuty":
                attribute = "Bottom Rail";
                label = product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey == 'HD' ?
                    'Streamline' : product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey;
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                condition2 = isMotorised ? "motorised" : "Manual/Spring";
                product.items[itemIndex].configuration.bottomRailDuty.selected.value.sets.forEach(set => {
                    if ((set.condition1 ? set.condition1 === condition1 : true)) {
                        if ((set.condition2 ? set.condition2 === condition2 : true)) {
                            if (((width >= set.min) && (width <= set.max))) {
                                stocks = [...stocks, ...set.stocks];
                            }
                        }
                    }
                });

                //Upgrade charges
                switch (product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey) {
                    case "STD":
                        condition1 = "Price";
                        condition2 = "default";
                        break;
                    case "HD":
                        condition1 = "Price";
                        condition2 = "default";
                        defaultOptionIndex = product.items[itemIndex].configuration.bottomRailDuty.finalOptions.findIndex(o => o.sets.some(set => this.bottomRailDutySetCondition(set, width, drop, isPVC, isMotorised)));
                        if (defaultOptionIndex > -1) {
                            condition2 = product.items[itemIndex].configuration.bottomRailDuty.finalOptions[defaultOptionIndex].optionKey;
                        }
                        break;
                    case "XHD":
                        condition1 = "Price";
                        condition2 = "default";
                        defaultOptionIndex = product.items[itemIndex].configuration.bottomRailDuty.finalOptions.findIndex(o => o.sets.some(set => this.bottomRailDutySetCondition(set, width, drop, isPVC, isMotorised)));
                        if (defaultOptionIndex > -1) {
                            condition2 = product.items[itemIndex].configuration.bottomRailDuty.finalOptions[defaultOptionIndex].optionKey;
                        }
                        break;
                }
                setIndex = (product.items[itemIndex].configuration.bottomRailDuty.selected.value.sets || []).findIndex(set => (set.condition1 === condition1 && set.condition2 === condition2) && ((width >= set.min) && (width <= set.max)));
                if (setIndex > -1) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRailDuty.selected.value.sets[setIndex].stocks];
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bottomRailDuty.selected.stocks = stocks;
                break;
            case "flatBar":
                attribute = "Flat Bar";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.flatBar.selected.deduction = this.initDeduction();
                if (isMotorised) {
                    condition1 = "motorised";
                } else {
                    condition1 = "Manual/Spring";
                }
                options = product.items[itemIndex].configuration.flatBar.options.filter(o => o.description === condition1);

                (options || []).forEach(option => {
                    if (isEmpty(product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey)) {
                        condition1 = product.items[itemIndex].configuration.bottomRailColour.finalOptions[0].optionKey; //by default
                    } else {
                        condition1 = product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                    }
                    setIndex = option.sets.findIndex(set => set.condition1 === condition1);
                    if (setIndex > -1) {
                        stocks = option.sets[setIndex].stocks;
                    }
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.flatBar.selected.stocks = stocks;
                product.items[itemIndex].configuration.flatBar.selected.value = !isEmpty(stocks);
                break;
            case "weightBar":
                attribute = "Weight Bar";
                label = "";
                comment = "";
                stocks = [];
                condition1 = isMotorised ? "motorised" : "Manual/Spring";
                condition2 = product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey;

                optionIndex = product.items[itemIndex].configuration.weightBar.options.forEach(o => {
                    if (width >= o.primaryAttributeMin && width <= o.primaryAttributeMax) {
                        if (o.condition1 ? o.condition1 === condition1 : true) {
                            if (o.condition2 ? o.condition2.split(",").includes(condition2) : true) {
                                stocks = [...stocks, ...o.stocks];
                            }
                        }
                    }
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.weightBar.selected.stocks = stocks;
                product.items[itemIndex].configuration.weightBar.selected.value = !isEmpty(stocks);
                break;
            case "topWidth":
                attribute = "Top Width";
                label = "";
                comment = "";
                stocks = [];
                    stocks = [{
                        id: null,
                        description: "",
                        prodCode: "",
                        price: 0,
                        flatPrice: 0,
                        quantityMultiplier: 1,
                        qtyFormulaId: null,
                        productConfigurationOptionId: null,
                        productConfigurationOptionSetId: null,
                        swishQuantityFormula: null,
                        keywayQuantityFormula: null,
                        wastageFormula: null,
                        calculatedQty: 0,
                        stockPickSlipQty: 0,
                        keywayMeasurementUnit: "mm",
                        swishMeasurementUnit: "mtr",
                        keywayConversionFactor: 1000,
                        swishConversionFactor: 1,
                        isVisibleInPartList: true,
                        isDeductionApplicable: true
                    }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });

                product.items[itemIndex].configuration.topWidth.selected.stocks = stocks;
                break;
            case "windoware15ChannelRemote":
                attribute = "Windoware 15 Channel Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.windoware15ChannelRemote.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks = stocks;
                break;
            case "windowareBridge":
                attribute = "Windoware Bridge";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.windowareBridge.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.windowareBridge.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windowareBridge.selected.stocks = stocks;
                break;
            case "chargingCable":
                attribute = "Charging Cable";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.chargingCable.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.chargingCable.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.chargingCable.selected.stocks = stocks;
                break;
            case "somfy1ChannelSituoRemote":
                attribute = "Somfy 1 Channel Situo Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.stocks = stocks;
                break;
            case "pullDownStick":
                attribute = "Pull Down Stick";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.pullDownStick.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.pullDownStick.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.pullDownStick.selected.stocks = stocks;
                break;
            case "highImpactStoppers":
                attribute = "High Impact Stoppers";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.highImpactStoppers.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.highImpactStoppers.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.highImpactStoppers.selected.stocks = stocks;
                break;
            case "additionalBottomStoppers":
                attribute = "Additional Bottom Stoppers";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.additionalBottomStoppers.selected.value && product.items[itemIndex].configuration.additionalBottomStoppers.selected.value > 0) {
                    product.items[itemIndex].configuration.additionalBottomStoppers.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.additionalBottomStoppers.selected.value;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.additionalBottomStoppers.selected.stocks = stocks;
                break;
            case "removablePostKit":
                attribute = "Removable Post Kit";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.removablePostKit.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.removablePostKit.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.removablePostKit.selected.stocks = stocks;
                break;
            case "insectControlBrush":
                attribute = "Insect Control Brush";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.insectControlBrush.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.insectControlBrush.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.insectControlBrush.selected.stocks = stocks;
                break;
            case "centralLockFaceFixStoppers":
                attribute = "Central Lock Face Fix Stoppers";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.centralLockFaceFixStoppers.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.stocks = stocks;
                break;
            case "backFlashingCut":
                attribute = "Back Flashing";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.backFlashingCutColour.selected.value)) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.backFlashingCutColour.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.backFlashingCut.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {

            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, order);
            product = this.updateStocks("keyway", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("splineColour", product, itemIndex, order);
            product = this.updateStocks("hoodingColour", product, itemIndex, order);
            product = this.updateStocks("bracketType", product, itemIndex, order);
            product = this.updateStocks("channels", product, itemIndex, order);
            product = this.updateStocks("channelsRight", product, itemIndex, order);
            product = this.updateStocks("centralLock", product, itemIndex, order);
            product = this.updateStocks("bottomRailFinish", product, itemIndex, order);
            product = this.updateStocks("bottomRailDuty", product, itemIndex, order);
            product = this.updateStocks("flatBar", product, itemIndex, order);
            product = this.updateStocks("weightBar", product, itemIndex, order);
            product = this.updateStocks("windoware15ChannelRemote", product, itemIndex, order);
            product = this.updateStocks("windowareBridge", product, itemIndex, order);
            product = this.updateStocks("chargingCable", product, itemIndex, order);
            product = this.updateStocks("somfy1ChannelSituoRemote", product, itemIndex, order);
            product = this.updateStocks("pullDownStick", product, itemIndex, order);
            product = this.updateStocks("highImpactStoppers", product, itemIndex, order);
            product = this.updateStocks("additionalBottomStoppers", product, itemIndex, order);
            product = this.updateStocks("removablePostKit", product, itemIndex, order);
            product = this.updateStocks("insectControlBrush", product, itemIndex, order);
            product = this.updateStocks("centralLockFaceFixStoppers", product, itemIndex, order);
            product = this.updateStocks("backFlashingCut", product, itemIndex, order);
            product = this.updateStocks("topWidth", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.splineColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.splineColour.selected.stocks];
            }
            if (!product.items[itemIndex].configuration.isSkinOnly.selected.value) {
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.keyway.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.hoodingColour.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.bracketType.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.bracketType.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.channels.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.channels.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.channelsRight.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.channelsRight.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.centralLock.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.centralLock.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.bottomRailFinish.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRailFinish.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.bottomRailDuty.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRailDuty.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.flatBar.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.flatBar.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.weightBar.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.weightBar.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.windowareBridge.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.windowareBridge.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.chargingCable.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.chargingCable.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.pullDownStick.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.pullDownStick.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.highImpactStoppers.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.highImpactStoppers.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.additionalBottomStoppers.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.additionalBottomStoppers.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.removablePostKit.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.removablePostKit.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.insectControlBrush.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.insectControlBrush.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.backFlashingCut.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.backFlashingCut.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.backFlashingCutColour.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.backFlashingCutColour.selected.stocks];
                }
                if (!isEmpty(product.items[itemIndex].configuration.topWidth.selected.stocks)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.topWidth.selected.stocks];
                }
            }
            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    isMotorised(product, itemIndex, order) {
        let isMotorised = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation)) {
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                isMotorised = product.items[itemIndex].configuration.operation.selected.value.optionKey.includes("Motor");
            }
        }
        return isMotorised;
    }

    isPVCItem(product, itemIndex, order) {
        let isMotorised = this.isMotorised(product, itemIndex, order);
        let bmCode = isMotorised
            ? product.items[itemIndex].configuration.fabricType.selected.value.primaryBMCode
            : product.items[itemIndex].configuration.fabricType.selected.value.secondaryBMCode;
        return bmCode.includes("PVC");
    }

    keywaySetCondition(set, width, drop, isPVC, isMotorised, isSizeSpecific) {
        let condition1 = isPVC ? "pvc" : "mesh";
        let condition2 = isMotorised ? "motorised" : "Manual/Spring";

        let result = (set.condition1 === condition1)
            && (set.condition2 === condition2)
            && (isSizeSpecific
                    ? ((width >= set.min) && (width <= set.max) && (drop >= set.secondaryMin) && (drop <= set.secondaryMax))
                    : true
            );
        return result
    }

    bottomRailDutySetCondition(set, width, drop, isPVC) {
        let condition1 = isPVC ? "pvc" : "mesh";
        let condition2 = "selectionMatrix";
        if (set.condition1 === condition1 && set.condition2 === condition2) {
            if (width >= set.min) {
                if (width <= set.max) {
                    if (drop >= set.secondaryMin) {
                        if (drop <= set.secondaryMax) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
        //return (set.condition1 === condition1 || set.condition3 === condition1) && ((width >= set.min) && (width <= set.max) && (drop >= set.secondaryMin) && (drop <= set.secondaryMax));
    }

    hoodingOptionSetCondition(set, width, drop, isPVC, excludeHooding) {
        let result = false;
        let condition1 = isPVC ? "pvc" : "mesh";
        if (excludeHooding ? set.description !== excludeHooding : true) {
            if ((set.condition1 === condition1)) {
                if (((width >= set.min) && (width <= set.max))) {
                    if (((drop >= set.secondaryMin) && (drop <= set.secondaryMax))) {
                        result = true;
                    }
                }
            }
        }
        return result;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        if (params && params.trackDeductionDrop) {
            stockQtyExpression = stockQtyExpression.replaceAll("@TrackDeductionDrop", params.trackDeductionDrop);
        }
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let topWidth = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.topWidth.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor
        };


        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                let panel = 0, fabricDrop = 0, usage = 0;
                try {
                    panel = Math.ceil((width / 1000) / fabricWidth);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }
                fabricDrop = (drop + 400) / 1000;

                if (fabricDrop < fabricWidth) {
                    //rail road
                    usage = width / 1000;
                } else {
                    usage = round((panel * fabricDrop), 1);
                }


                deduction = this.updateDeduction("fabric", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;

                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Operation":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Keyway":
                deduction = this.updateDeduction("keyway", product, itemIndex);
                width = topWidth;
                params.width = width + (deduction.deductionWidth);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width + (deduction.deductionWidth);
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                break;
            case "Spline":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Hooding":
                deduction = this.updateDeduction("hooding", product, itemIndex);
                width = topWidth;
                params.width = width + (deduction.deductionWidth);
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width + (deduction.deductionWidth);
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.hoodingColour.selected.deduction = deduction;
                break;
            case "Top Width":
                deduction = this.updateDeduction("topWidth", product, itemIndex);
                params.cutWidth = deduction.cutWidth;
                stockItem.width = topWidth;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = topWidth/1000;
                stockItem.keywayCalculatedQty = topWidth;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = 1;
                stockItem.deductionQty = deduction.deductionQty;
                stockItem.deductionWidth = deduction.deductionWidth;
                stockItem.deductionDrop = deduction.deductionDrop;
                stockItem.cutWidth = deduction.cutWidth;
                stockItem.cutDrop = deduction.cutDrop;
                product.items[itemIndex].configuration.hoodingColour.selected.deduction = deduction;
                break;
            case "Bracket":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Channels Left":
            case "Channels Right":
                deduction = this.updateDeduction("channels", product, itemIndex);
                if (stockItem.comment === "Track") {
                    params.cutDrop = deduction.trackDeductionDrop;
                    stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.trackDeductionDrop : 0;
                    stockItem.trackDeductionDrop = stockItem.isDeductionApplicable ? deduction.trackDeductionDrop : 0;
                }
                else {
                    params.cutDrop = deduction.cutDrop;
                    stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                    stockItem.trackDeductionDrop = 0;
                }
                params.trackDeductionDrop = deduction.trackDeductionDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                //stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.keywayCalculatedQty = (1 * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                //quantity multiplier was not needed for keywayCalculatedQty in this case as per KWH-30, hence has hardcoded to 1
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                
                product.items[itemIndex].configuration.channels.selected.deduction = deduction;
                break;
            case "Central Lock":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Bottom Rail":
                deduction = this.updateDeduction("bottomRail", product, itemIndex);
                params.cutWidth = deduction.cutWidth;
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.bottomRailDuty.selected.deduction = deduction;
                break;
            case "Bottom Rail Finish":
                deduction = this.updateDeduction("Bottom Rail Finish", product, itemIndex);
                params.cutWidth = deduction.cutWidth;
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.bottomRailFinish.selected.deduction = deduction;
                break;
            case "Flat Bar":
                deduction = this.updateDeduction("flatBar", product, itemIndex);
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.flatBar.selected.deduction = deduction;
                break;
            case "Weight Bar":
                deduction = this.updateDeduction("weightBar", product, itemIndex);
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.weightBar.selected.deduction = deduction;
                break;
            case "Windoware 15 Channel Remote":
            case "Windoware Bridge":
            case "Charging Cable":
            case "Somfy 1 Channel Situo Remote":
            case "Pull Down Stick":
            case "High Impact Stoppers":
            case "Additional Bottom Stoppers":
            case "Removable Post Kit":
            case "Insect Control Brush":
            case "Central Lock Face Fix Stoppers":
            case "Back Flashing":
                if (attribute == "Back Flashing") {
                    width = topWidth;
                    width = width - 6;
                    result = width / 1000;
                    params.width = width;
                }
                if (attribute === "Insect Control Brush") {
                    let isPVC = this.isPVCItem(product, itemIndex, []);
                    if (!isPVC) {
                        result = 2;
                    }
                    let conversionUnitWidth = width * result;
                    result = conversionUnitWidth / 1000;
                }
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            default:
        }
    }


    updateDeduction(key, product, itemIndex, order) {
        let deduction = this.initDeduction();
        let isMotorised = false;
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let topWidth = product.items[itemIndex].configuration.topWidth.selected.value;

        switch (key) {
            case "hooding":
                width = topWidth;
                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                    case "No Hood":
                        deduction.deductionWidth = 0;
                        break;
                    case "125mm Flair Pelmet":
                    case "148mm Flair Pelmet":
                        deduction.deductionWidth = -6;//-6mm
                        break;
                    case "165mm Pelmet":
                        deduction.deductionWidth = -10;//-10mm
                        break;
                    case "175mm Pelmet":
                        deduction.deductionWidth = -8;//-8mm
                        break;
                    case "135mm Ziptrak Pelmet":
                        deduction.deductionWidth = -7;//-7mm
                        break;
                }
                deduction.deductionQty = 0;
                deduction.deductionDrop = 0;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                break;
            case "topWidth":
                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                    case "No Hood":
                        deduction.deductionWidth = 0;
                        break;
                    case "125mm Flair Pelmet":
                    case "148mm Flair Pelmet":
                        deduction.deductionWidth = -6;//-6mm
                        break;
                    case "165mm Pelmet":
                        deduction.deductionWidth = -10;//-10mm
                        break;
                    case "175mm Pelmet":
                        deduction.deductionWidth = -8;//-8mm
                        break;
                    case "135mm Ziptrak Pelmet":
                        deduction.deductionWidth = -7;//-7mm
                        break;
                }
                deduction.deductionQty = 0;
                deduction.deductionDrop = 0;
                deduction.cutWidth = topWidth + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                break;
            case "keyway":
                width = topWidth;
                deduction.deductionQty = 0;
                deduction.deductionWidth= product.items[itemIndex].configuration.operation.selected.value.optionKey === "Manual/Spring" ? - 100 : -105;
                deduction.deductionDrop = 0;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                break;
            case "fabric":
                deduction.deductionQty = 0;
                deduction.deductionWidth = product.items[itemIndex].configuration.isSkinOnly.selected.value ? -20 : -90;
                deduction.deductionDrop = 400;//+400mm
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "channels":
                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                    case "125mm Flair Pelmet":
                        deduction.deductionDrop = -120;//-120mm
                        deduction.trackDeductionDrop = drop - 115;//-115mm
                        break;
                    case "148mm Flair Pelmet":
                        deduction.deductionDrop = -143;//-143mm
                        deduction.trackDeductionDrop = drop - 138;//-138mm
                        break;
                    case "165mm Pelmet":
                        deduction.deductionDrop = -165;//-165mm
                        deduction.trackDeductionDrop = drop - 155;//-155mm
                        break;
                    case "175mm Pelmet":
                        deduction.deductionDrop = -175;//-175mm
                        deduction.trackDeductionDrop = drop - 165;//-165mm
                        break;
                    case "135mm Ziptrak Pelmet":
                        deduction.deductionDrop = -135;//-135mm
                        deduction.trackDeductionDrop = drop - 125;//-125mm
                        break;
                    default:
                        switch (product.items[itemIndex].configuration.bracketType.selected.value.optionKey) {
                            case "Cast End Brackets":
                                deduction.deductionDrop = -130;//-130mm
                                deduction.trackDeductionDrop = drop - 120;//-120mm
                                break;
                            case "165mm Pelmet Ends Only":
                                deduction.deductionDrop = -165;//-165mm
                                deduction.trackDeductionDrop = drop - 155;//-155mm
                                break;
                            case "175mm Pelmet Ends Only":
                                deduction.deductionDrop = -175;//-175mm
                                deduction.trackDeductionDrop = drop - 165;//-165mm
                                break;
                        }
                }
                deduction.deductionQty = 0;
                deduction.deductionWidth = 0;
                deduction.cutWidth = 0;
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "bottomRail":
                switch (product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey) {
                    case "XHD":
                        deduction.deductionWidth = -124; // -124mm;
                        break;
                    case "STD":
                    case "HD":
                    default:
                        deduction.deductionWidth = -124; // -124mm;
                        break;
                }
                deduction.deductionQty = 0;
                deduction.deductionDrop = 0;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                break;
            case "Bottom Rail Finish":
                switch (product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey) {
                    case "XHD":
                        deduction.deductionWidth = -90; // -90mm;
                        break;
                    case "STD":
                    case "HD":
                    default:
                        deduction.deductionWidth = -90; // -90mm;
                        break;
                }
                deduction.deductionQty = 0;
                deduction.deductionDrop = 0;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                break;
            case "flatBar":
                // only if manual
                //(Cut size of Bottom Rail - 136mm)/2 STD/HD
                //(Cut size of Bottom Rail-136mm)/2 XKD
                isMotorised = this.isMotorised(product, itemIndex, order);
                if (!isMotorised) {
                    switch (product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey) {
                        case "XHD":
                            deduction.cutWidth = (product.items[itemIndex].configuration.bottomRailDuty.selected.deduction.cutWidth - 136) / 2;
                            break;
                        case "STD":
                        case "HD":
                            deduction.cutWidth = (product.items[itemIndex].configuration.bottomRailDuty.selected.deduction.cutWidth - 136) / 2;
                            break;
                    }
                    deduction.deductionQty = 0;
                    deduction.deductionWidth = 0;
                    deduction.deductionDrop = 0;
                    deduction.cutDrop = 0;
                }
                break;
            case "weightBar":
                // only if motorised
                isMotorised = this.isMotorised(product, itemIndex, order);
                if (isMotorised) {
                    
                    deduction.deductionQty = 0;
                    deduction.deductionWidth = 0;
                    deduction.deductionDrop = 0;
                    if (width <= 3000) {
                        deduction.cutWidth = product.items[itemIndex].configuration.bottomRailDuty.selected.deduction.cutWidth - 70;//-70mm of cut width of Bottom Rail width
                    } else if (width > 3000) {
                        switch (product.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey) {
                            case "XHD":
                                 deduction.cutWidth = 2000;
                                break;
                            case "STD":
                                break;
                            case "HD":
                                deduction.cutWidth = 2800;
                                break;
                        }
                    }
                   
                    deduction.cutDrop = 0;
                }
                break;
            case "backFlashingCut":
                    deduction.deductionQty = 0;
                    deduction.deductionWidth = -6;//-6mm is deduction usage for backFlashingCut
                    deduction.deductionDrop = 0;
                    deduction.cutWidth = 0;
                    deduction.cutDrop = 0;
                break;
        }
        return deduction;
    }


    generateHoodingLabel(product, itemIndex) {
        let result = product.items[itemIndex].configuration.hooding.selected.value.optionKey;

        if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
            result = result + " " + product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
        }
        if (!isEmpty(product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value)) {
            result = result + " " + product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value;
        }
        return result;
    }

    isVisibleMotorAccessories(key, product, itemIndex) {
        let result = false;
        switch (key) {
            case "Windoware":
                result = product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible
                    || product.items[itemIndex].configuration.windowareBridge.selected.isVisible
                    || product.items[itemIndex].configuration.chargingCable.selected.isVisible
                ;
                break;
            case "Somfy":
                result = product.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.isVisible;
                break;
            default:
                result = false;
        }
        return result;
    }

    showSplittedChannelOptions(order){
        return (order.debtor && order.debtor.debtorCategoryID && order.debtor.debtorCategoryID === DEBTOR_CATEGORY.GOLD.debtorCategoryID)
    }


    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.hoodingPowdercoatColour.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.hoodingPowdercoatColour.formError.message);
        }
        if (!pg.items[itemIndex].configuration.framePowdercoatColour.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.framePowdercoatColour.formError.message);
        }
        if (!pg.items[itemIndex].configuration.framePowdercoatColourRight.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.framePowdercoatColourRight.formError.message);
        }
        if (!pg.items[itemIndex].configuration.bracketColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.bracketColourPowdercoat.formError.message);
        }
        if (!pg.items[itemIndex].configuration.bottomRailDuty.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.bottomRailDuty.formError.message);
        }
        if (!pg.items[itemIndex].configuration.bottomRailColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.bottomRailColourPowdercoat.formError.message);
        }
        if (!pg.items[itemIndex].configuration.topWidth.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.topWidth.formError.message);
        }
        return errors;
    }

    toSalesOrderItemZiptrakRequest(pg, itemIndex, order) {
        let defaultDeduction = this.updateDeduction("default", pg, itemIndex);
        let temp = {};
        if (isEmpty(pg.items[itemIndex].configuration.hoodingColour.selected.deduction)) {
            pg.items[itemIndex].configuration.hoodingColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.keyway.selected.deduction)) {
            pg.items[itemIndex].configuration.keyway.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.weightBar.selected.deduction)) {
            pg.items[itemIndex].configuration.weightBar.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.flatBar.selected.deduction)) {
            pg.items[itemIndex].configuration.flatBar.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.bottomRailDuty.selected.deduction)) {
            pg.items[itemIndex].configuration.bottomRailDuty.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.bottomRailFinish.selected.deduction)) {
            pg.items[itemIndex].configuration.bottomRailFinish.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.channels.selected.deduction)) {
            pg.items[itemIndex].configuration.channels.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.channelsRight.selected.deduction)) {
            pg.items[itemIndex].configuration.channelsRight.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.topWidth.selected.deduction)) {
            pg.items[itemIndex].configuration.topWidth.selected.deduction = defaultDeduction;
        }

        let salesOrderItemZiptrak = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: pg.items[itemIndex].configuration.location.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: pg.items[itemIndex].configuration.width.selected.value,
            drop: pg.items[itemIndex].configuration.drop.selected.value,
            fabricType: pg.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: pg.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            fabricColourOffRange: pg.items[itemIndex].configuration.fabricColourOffRange.selected.value,
            splineColour: pg.items[itemIndex].configuration.splineColour.selected.value.optionKey,
            operation: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.operation.selected.value.optionKey,
            customerSupplyMotorType: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.customerSupplyMotorType.selected.value,
            motorSide: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.motorSide.selected.value.optionKey,
            keyway: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.keyway.selected.value.selectedSet.description,
            hooding: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.hooding.selected.value.optionKey,
            hoodingColour: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.hoodingColour.selected.value.optionKey,
            topWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.topWidth.selected.value,
            hoodingPowdercoatColour: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value,
            frameColour: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.frameColour.selected.value.optionKey,
            frameColourRight: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.frameColourRight.selected.value.optionKey,
            framePowdercoatColour: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.framePowdercoatColour.selected.value,
            framePowdercoatColourRight: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.framePowdercoatColourRight.selected.value,
            bracketType: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.bracketType.selected.value.optionKey,
            bracketColour: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.bracketColour.selected.value.optionKey,
            bracketColourPowdercoat: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.bracketColourPowdercoat.selected.value,
            channels: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.channels.selected.value.optionKey,
            channelsRight: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.channelsRight.selected.value.optionKey,
            centralLock: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.centralLock.selected.value.optionKey,
            bottomRailFinish: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.bottomRailFinish.selected.value.optionKey,
            bottomRailDuty: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.bottomRailDuty.selected.value.optionKey,
            bottomRailColour: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey,
            bottomRailColourPowdercoat: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value,
            windoware15ChannelRemote: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.windoware15ChannelRemote.selected.value,
            windowareBridge: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.windowareBridge.selected.value,
            chargingCable: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.chargingCable.selected.value,
            somfy1ChannelSituoRemote: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.somfy1ChannelSituoRemote.selected.value,
            pullDownStick: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.pullDownStick.selected.value,
            highImpactStoppers: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.highImpactStoppers.selected.value,
            additionalBottomStoppers: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.additionalBottomStoppers.selected.value,
            removablePostKit: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.removablePostKit.selected.value,
            flatBar: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.flatBar.selected.value,
            weightBar: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.weightBar.selected.value,
            insectControlBrush: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.insectControlBrush.selected.value,
            centralLockFaceFixStoppers: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.centralLockFaceFixStoppers.selected.value,
            flashBackCut: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? false : pg.items[itemIndex].configuration.backFlashingCut.selected.value,
            flashBackCutColour: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? "" : pg.items[itemIndex].configuration.backFlashingCutColour.selected.value.optionKey,

            hoodingDeductionWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.hoodingColour.selected.deduction.deductionQty,
            keywayDeductionWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.keyway.selected.deduction.deductionQty,
            weightBarDeductionWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.weightBar.selected.deduction.deductionQty,
            bottomRailDutyDeductionWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.bottomRailDuty.selected.deduction.deductionQty,
            bottomRailFinishDeductionWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.bottomRailFinish.selected.deduction.deductionQty,
            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,
            channelsDeductionDrop: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.channels.selected.deduction.deductionQty,
            trackDeductionDrop: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.channels.selected.deduction.trackDeductionDrop,

            hoodingCutWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.hooding.selected.value.optionKey == "No Hood" ? 0 : pg.items[itemIndex].configuration.hoodingColour.selected.deduction.cutWidth,
            keywayCutWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.keyway.selected.deduction.cutWidth,
            weightBarCutWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.weightBar.selected.deduction.cutWidth,
            flatBarCutWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.flatBar.selected.deduction.cutWidth,
            bottomRailDutyCutWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.bottomRailDuty.selected.deduction.cutWidth,
            bottomRailFinishCutWidth: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.bottomRailFinish.selected.deduction.cutWidth,
            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,
            channelsCutDrop: pg.items[itemIndex].configuration.isSkinOnly.selected.value ? 0 : pg.items[itemIndex].configuration.channels.selected.deduction.cutDrop,
            isSkinOnly: pg.items[itemIndex].configuration.isSkinOnly.selected.value,

            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemZiptrak;
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }
}

export default ZiptrakExternalUtil.Instance();
