import React from 'react';
import { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardHeader, CardBody, Badge, Input } from 'reactstrap';
import { Tabs } from './../../store/StockTakeTabs';
import classnames from 'classnames';
import StockTakeComponent from '../../components/StockTakeComponents/StockTakeComponent';
import StockTransactions from '../../components/StockTakeComponents/StockTransactions';
import StockFabricTransaction from '../../components/StockTakeComponents/StockFabricTransaction';
import StockTakeList from '../stock/stockTakeManager/StockTakeList';
import StockTakeApprovalPage from '../stock/stockTakeManager/StockTakeApprovalPage';

const tabs = Tabs;

export default class StockTakePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[0].key,
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        ///
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        let { activeTab } = this.state;
        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <h5>Stock Take</h5>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>

                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{ textDecoration: "none" }}
                                            size={"sm"}
                                            color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='stock-take-and-submission'>
                                {
                                    activeTab === "stock-take-and-submission" ?
                                        <StockTakeList />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='stock-take-approval'>
                                {
                                    activeTab === "stock-take-approval" ?
                                        <StockTakeApprovalPage />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='stock-transaction-swish'>
                                {
                                    activeTab === "stock-transaction-swish" ?
                                        <StockTransactions />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='FABRIC'>
                                {
                                    activeTab === "FABRIC" ?
                                        <StockTakeComponent type={"FABRIC"} />
                                        : null
                                }
                            </TabPane>
                            <TabPane tabId='stock-fabric-transaction-keyway'>
                                {
                                    activeTab === "stock-fabric-transaction-keyway" ?
                                        <StockFabricTransaction />
                                        : null
                                }
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        );
    }
}