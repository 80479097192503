import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    CardFooter,
    Row,
    Col,
    Table,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Badge
} from 'reactstrap';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import NumberFormat from "react-number-format";
import CustomerService from "../../../services/CustomerService";

import PurchaseHistoryService from '../../../services/PurchaseHistoryService';

export default class PurchaseHistoryOutstandingOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountId: this.props.accountId,
            loading: true,
            openOrders: {
                orders: [],
                selectedPage: 1,
                pageSize: 25,
                totalRecords: 1,
                invTotal: 0
            }
        };

        this.customerService = new CustomerService();
        this.purchaseHistoryService = new PurchaseHistoryService();
        this.fetchOpenOrders = this.fetchOpenOrders.bind(this);
        this.getOutstandingOrdersValue = this.getOutstandingOrdersValue.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({accountId: nextProps.accountId}, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        this.fetchOpenOrders(this.state.openOrders.selectedPage);
        this.getOutstandingOrdersValue();
    }


    fetchOpenOrders(selectedPage) {
        this.setState({loading: true});

        this.purchaseHistoryService.getOpenOrders(this.state.accountId, selectedPage, this.state.openOrders.pageSize).then(res => {

            let data = res.data;
            let openOrders = this.state.openOrders;
            openOrders.orders = data.orders;
            openOrders.selectedPage = data.selectedPage;
            openOrders.pageSize = data.pageSize;
            openOrders.totalRecords = data.totalRecords;

            this.setState({openOrders: openOrders, loading: false});
            if (this.props.updateTabs) {
                this.props.updateTabs(this.props.tabId, "count", openOrders.totalRecords);
            }

        }).catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    getOutstandingOrdersValue() {
        let {accountId} = this.state;
        if (accountId) {
            this.customerService.getOutstandingOrderValue(accountId).then(response => {
                if (this.props.updateTabs) {
                    this.props.updateTabs(this.props.tabId, "value", response.data);
                }
            }).catch(error => {
                console.log(error.data);
            });
        }
    }


    render() {

        let {loading, openOrders} = this.state;
        let {orders, selectedPage, pageSize, totalRecords} = openOrders;

        if (loading) {
            return <p><em>loading...</em></p>
        }

        if (orders.length === 0) {
            return <Badge color={"light"} className={"mt-4"}>0 orders found</Badge>
        }

        return (

            <div>
                <Table responsive hover>
                    <thead>
                    <tr>
                        <th>Order Date</th>
                        <th>Order No</th>
                        <th>Cust Order No</th>
                        <th>Ref</th>
                        <th>Invoice Value</th>
                        <th>Description</th>
                        <th>Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map((order, index) =>
                        <tr key={index}>
                            <td>{order.orderDate}</td>
                            <td><Link
                                className="btn btn-sm btn-primary"
                                style={{color: "white"}}
                                title={"Click here to view order in detail"}
                                to={"/sales/order/view?" + queryString.stringify({ OrdNum: order.ordNum })}>{order.ordNum}</Link></td>
                            <td>{order.custOrdNum}</td>
                            <td>{order.jobRef}</td>
                            <td className={"table-success"}><NumberFormat value={order.invoiceValue}
                                                                          displayType={'text'}
                                                                          decimalScale={2}
                                                                          thousandSeparator={true}
                                                                          prefix={'$'}/></td>
                            <td>{order.description}</td>
                            <td>{order.notes}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                {totalRecords > pageSize ? <Row>
                    <Col md="4" sm="1"></Col>
                    <Col md="4" sm="10">
                        <Pagination
                            activePage={selectedPage}
                            itemsCountPerPage={pageSize}
                            totalItemsCount={totalRecords}
                            pageRangeDisplayed={3}
                            onChange={this.fetchOpenOrders}
                            itemClass='page-item'
                            linkClass='page-link'
                            activeClass='active'
                            innerClass='pagination'
                            activeLinkClass='active'
                        />
                    </Col>
                </Row> : null}

            </div>

        );
    }
}
