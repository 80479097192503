import React, {Component} from 'react';

export let venetianLabelStore = [
    {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "slatType",
        label: "Slat Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "slatColour",
        label: "Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }
];