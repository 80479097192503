import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Row, Col, Spinner} from 'reactstrap';
import OrderService from "../../../services/OrderService";
import {getDateObj, handleErrorMessage} from '../../../services/CommonService';
import {ToastContainer, toast} from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getDateString} from '../../../services/CommonService';

export default class UpdateRequiredDateModal extends Component {
    constructor(props) {
        super(props);
        let ordNum = props.ordNum;
        let requiredDate = getDateObj(props.selected, "DD/MM/YYYY");
        if (!requiredDate) {
            requiredDate = new Date();
        }
        this.state = {
            loading: false,
            ordNum: ordNum,
            requiredDate: requiredDate
        };
        this.orderService = new OrderService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            let requiredDate = getDateObj(nextProps.selected, "DD/MM/YYYY");
            this.setState({ordNum: nextProps.ordNum, requiredDate: requiredDate});
        }
    }

    handleChange(changedDate) {
        this.setState({requiredDate: changedDate});
    }


    handleSubmit() {
        this.setState({loading: true});
        let requiredDateString = getDateString(this.state.requiredDate, "DD/MM/YYYY");
        this.orderService.updateRequiredDate(this.props.ordNum, requiredDateString).then(response => {
            this.setState({loading: false});
            this.props.refreshOrder();
            toast.success("Updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.props.toggleModel();
        }).catch(error => {
            this.setState({loading: false});
            console.log(error.response);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {

        return (
            <Modal isOpen={this.props.isOpen} size="md" scrollable={false} toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    Update Order Required Date
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Label>Select required date<i className="fa fa-calendar ml-2" aria-hidden="true"/></Label>
                        <div>
                            <DatePicker
                                withPortal
                                className="form-control"
                                selected={this.state.requiredDate}
                                onChange={date => this.handleChange(date)}
                                dateFormat="dd MMM yyyy"
                                isClearable
                                placeholderText="Required Date"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"}
                                size={"sm"}
                                onClick={this.handleSubmit}
                                disabled={this.state.loading}>
                            {this.state.loading ?  <Spinner size={"sm"} className={"mr-2"}/> : null}
                           {this.state.loading ? "Saving" : "Save"}
                        </Button>
                        <Button className={"ml-2"}  size={"sm"} color={"secondary"} onClick={this.props.toggleModel}>Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}