import React, {Component} from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, FormGroup} from 'reactstrap';
import {toast, ToastContainer} from "react-toastify";
import {handleErrorMessage} from "../../services/CommonService";
import StockService from "../../services/StockService";

const discontinueList = [
    { label: 'No Action', value: "" },
    {label: 'Discontinue', value: "true"},
    {label: 'Not Discontinue', value: "false"}
];

const soldOutList = [
    { label: 'No Action', value: "" },
    { label: 'Sold Out', value: "true" },
    { label: 'Not Sold Out', value: "false" }
];
export default class ManageStockBulkUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedProdCodes: [],
            discontinue: '',
            soldOut: ''
        };
        this.stockService = new StockService();
        this.updateStocksWithDiscontinueSoldOutByProdCodes = this.updateStocksWithDiscontinueSoldOutByProdCodes.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let {selectedProdCodes} = this.props;
        this.setState({selectedProdCodes});
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedProdCodes !== nextProps.selectedProdCodes) {
            this.setState({selectedProdCodes: nextProps.selectedProdCodes});
        }
    }

    handleChange(key, value) {
        this.setState({[key]: value});
    }

    updateStocksWithDiscontinueSoldOutByProdCodes() {
        let { discontinue, selectedProdCodes, soldOut } = this.state;
        let request = { prodCodes: selectedProdCodes, discontinue: discontinue, soldOut:soldOut };
        this.setState({isLoading: true});
        this.stockService.updateStocksWithDiscontinueSoldOutByProdCodes(request).then(response => {
            toast.success("Successfully Updated!");
            this.setState({isLoading: false});
            this.props.toggle(false);
            this.props.refresh();
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {discontinue, isLoading, soldOut} = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Update Stock
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>  
                        <FormGroup>
                                <Label for="discontinue">Discontinue</Label>
                                <Input type="select" name="discontinue" value={discontinue}
                                       onChange={(e) => this.handleChange("discontinue", e.target.value)}>
                                    {
                                        (discontinueList || []).map((option, index) =>
                                            <option key={index} value={option.value}>{option.label}</option>
                                        )
                                    }
                                </Input>
                            </FormGroup>
                            </Col>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <FormGroup>
                                <Label for="soldOut">Sold Out</Label>
                                <Input type="select" name="soldOut" value={soldOut}
                                       onChange={(e) => this.handleChange("soldOut", e.target.value)}>
                                    {
                                        (soldOutList || []).map((option, index) =>
                                            <option key={index} value={option.value}>{option.label}</option>
                                        )
                                    }
                                </Input>
                                </FormGroup>
                            </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <div className="text-right">
                                <Button color={"primary"} size='sm' className={"mr-2"}
                                        disabled={isLoading}
                                onClick={this.updateStocksWithDiscontinueSoldOutByProdCodes}>
                                    {isLoading
                                        ? <Spinner className={"mr-2"} size={"sm"}/>
                                        : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>
                                    }
                                    {isLoading ? "Updating" : "Update"}
                                </Button>
                            <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}>
                                <i className="fa fa-times mr-2"/>
                                Cancel
                            </Button>
                        </div>
                    </Row>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}