import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import { round, roundUp, findIndex} from '../../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_ZIPTRAK_INTERNAL_PRODCODE} from "../../../../../../../store/AppConstants";

class ZiptrakInternalUtil {

    static Instance() {
        return new ZiptrakInternalUtil()
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > 50) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than 50 characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
    }


    updateRules(key, product, itemIndex, order) {
        let isMotorised, optionIndex=-1;
        isMotorised = this.isMotorised(product, itemIndex);

        switch (key) {
            case "width":
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Manual/Spring":
                            product.items[itemIndex].configuration.width.min = 500;
                            product.items[itemIndex].configuration.width.max = 3000;
                            break;
                        case "Windoware Motor DC Battery":
                            if (product.items[itemIndex].configuration.width.selected.value <= 2110) {
                                product.items[itemIndex].configuration.width.min = 575;
                            } else {
                                product.items[itemIndex].configuration.width.min = 885;
                            }
                            product.items[itemIndex].configuration.width.max = 3000;
                            break;
                        case "Windoware Motor Remote 220-240V AC WIFI":
                            product.items[itemIndex].configuration.width.min = 695;
                            product.items[itemIndex].configuration.width.max = 3000;
                            break;
                        case "Somfy Motor Sonesse 30 DC Battery RTS":
                            product.items[itemIndex].configuration.width.min = 690;
                            product.items[itemIndex].configuration.width.max = 3000;
                            break;
                        case "Somfy Motor Sonesse 40 RTS 240V AC":
                            product.items[itemIndex].configuration.width.min = 594;
                            product.items[itemIndex].configuration.width.max = 3000;
                            break;
                        default:
                            product.items[itemIndex].configuration.width.min = 500;
                            product.items[itemIndex].configuration.width.max = 3000;
                            break;
                    }
                }
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 500;
                product.items[itemIndex].configuration.drop.max = 2800;
                break;
            case "accessories":
                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                    case "Manual/Spring":
                        product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = true;
                        product.items[itemIndex].configuration.pullDownStick.selected.isVisible = true;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;

                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;

                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isVisible = false;
                        break;
                    case "Windoware Motor DC Battery":
                        product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = false;
                        product.items[itemIndex].configuration.pullDownStick.selected.isVisible = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = true;
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = true;

                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = true;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = true;

                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = true;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = true;

                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isVisible = false;
                        break;
                    case "Windoware Motor Remote 220-240V AC WIFI":
                        product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = false;
                        product.items[itemIndex].configuration.pullDownStick.selected.isVisible = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = true;
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = true;

                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = true;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = true;

                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isVisible = false;
                        break;
                    case "Somfy Motor Sonesse 30 DC Battery RTS":
                        product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = false;
                        product.items[itemIndex].configuration.pullDownStick.selected.isVisible = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;

                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;

                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable = true;
                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isVisible = true;

                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable = true;
                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isVisible = true;

                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isSelectable = true;
                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isVisible = true;
                        break;
                    case "Somfy Motor Sonesse 40 RTS 240V AC":
                        product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = false;
                        product.items[itemIndex].configuration.pullDownStick.selected.isVisible = false;

                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;

                        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
                        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;

                        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                        product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;

                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isVisible = true;

                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable = false;
                        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isVisible = true;

                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isSelectable = true;
                        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isVisible = true;
                        break;
                }
                product.items[itemIndex].configuration.pullDownStick.selected.value = product.items[itemIndex].configuration.pullDownStick.selected.isSelectable ? product.items[itemIndex].configuration.pullDownStick.selected.value : false;
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable ? product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value : false;
                product.items[itemIndex].configuration.windowareBridge.selected.value = product.items[itemIndex].configuration.windowareBridge.selected.isSelectable ? product.items[itemIndex].configuration.windowareBridge.selected.value : false;
                product.items[itemIndex].configuration.chargingCable.selected.value = product.items[itemIndex].configuration.chargingCable.selected.isSelectable ? product.items[itemIndex].configuration.chargingCable.selected.value : false;
                product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value = product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable ? product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value : false;
                product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.value = product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable ? product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.value : false;
                product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.value = product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isSelectable ? product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.value : false;
                break;
            case "controlSide":
                if (isMotorised) {
                    product.items[itemIndex].configuration.controlSide.selected.isSelectable = true;
                } else {
                    if (!isEmpty(product.items[itemIndex].configuration.controlSide.selected.value)) {
                        optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, "optionKey", product.items[itemIndex].configuration.controlSide.selected.value.optionKey);
                    }
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                    product.items[itemIndex].configuration.controlSide.selected.isSelectable = false;
                }
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let options, optionIndex, sets, setIndex, stocks, condition1, condition2, condition3, label, attribute,
            width, drop, isMotorised, comment,
            deduction;
        width = product.items[itemIndex].configuration.width.selected.value ? product.items[itemIndex].configuration.width.selected.value : 0;
        drop = product.items[itemIndex].configuration.drop.selected.value ? product.items[itemIndex].configuration.drop.selected.value : 0;
        isMotorised = this.isMotorised(product, itemIndex);

        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: PRODUCT_BUILDER_ZIPTRAK_INTERNAL_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "operation":
                attribute = "Operation";//Need to change the operation exact value with options.
                comment = "";
                label = "";
                stocks = [];

                optionIndex = -1;
                if (isMotorised) {
                    label = "Motorised";
                    if (width) {
                        sets = product.items[itemIndex].configuration.operation.selected.value.sets.filter(set => ((width >= set.min) && (width <= set.max)));
                        (sets || []).forEach(set => {
                            stocks = [...stocks, ...set.stocks];
                        });
                    }
                } else {
                    label = "Manual/Spring";
                    optionIndex = product.items[itemIndex].configuration.spring.options.findIndex(o => o.condition1.includes(product.items[itemIndex].configuration.fabricType.selected.value.optionKey));
                    if (optionIndex > -1 && width && drop) {
                        setIndex = product.items[itemIndex].configuration.spring.options[optionIndex].sets.findIndex(set => ((width >= set.min) && (width <= set.max) && ((drop >= set.secondaryMin) && (drop <= set.secondaryMax))));
                        if (setIndex > -1) {
                            comment = product.items[itemIndex].configuration.spring.options[optionIndex].sets[setIndex].setKey;
                            setIndex = product.items[itemIndex].configuration.operation.selected.value.sets.findIndex(s => s.description === comment);
                            if (setIndex > -1) {
                                stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.value.sets[setIndex].stocks];
                            }
                        }
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.operation.selected.stocks = stocks;
                break;
            case "pelmetSize":
                attribute = "Pelmet Size";
                comment = "";
                label = "";
                stocks = [];

                //get pelmet size from matrix
                optionIndex = product.items[itemIndex].configuration.pelmetSize.options.findIndex(o => o.optionKey === "matrix");
                if (width && drop && optionIndex > -1) {
                    condition1 = isMotorised ? "Motorised" : "Manual/Spring"; //for matrix condition
                    setIndex = product.items[itemIndex].configuration.pelmetSize.options[optionIndex].sets.findIndex(set => ((width >= set.min) && (width <= set.max) && (drop >= set.secondaryMin) && (drop <= set.secondaryMax) && set.condition1 === condition1));
                    if (setIndex > -1) {
                        condition1 = product.items[itemIndex].configuration.pelmetSize.options[optionIndex].sets[setIndex].setKey;

                        //get associated option in finalOptions
                        optionIndex = product.items[itemIndex].configuration.pelmetSize.finalOptions.findIndex(o => o.optionKey === condition1);
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.pelmetSize.selected.value = product.items[itemIndex].configuration.pelmetSize.finalOptions[optionIndex];
                        label = product.items[itemIndex].configuration.pelmetSize.selected.value.optionKey;

                        //find stocks
                        condition1 = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                        condition2 = isMotorised ? "Motorised" : "Manual/Spring";
                        condition3 = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                        sets = product.items[itemIndex].configuration.pelmetSize.selected.value.sets.filter(s => s.condition1.includes(condition1) && s.condition2.includes(condition2) && s.condition3.includes(condition3));
                        sets.forEach(s => {
                            stocks = [...stocks, ...s.stocks];
                        });
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.pelmetSize.selected.stocks = stocks;
                break;
            case "keyway":
                attribute = "Keyway";
                comment = "";
                label = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
                stocks = [];
                sets = product.items[itemIndex].configuration.keyway.selected.value.sets;
                sets.forEach(s => {
                    stocks = [...stocks, ...s.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.keyway.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                    stocks = [{
                        id: null,
                        description: "",
                        prodCode: product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                        price: 0,
                        flatPrice: 0,
                        quantityMultiplier: 1,
                        qtyFormulaId: null,
                        productConfigurationOptionId: null,
                        productConfigurationOptionSetId: null,
                        swishQuantityFormula: product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula,
                        keywayQuantityFormula: product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula,
                        wastageFormula: product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula,
                        calculatedQty: 0,
                        stockPickSlipQty: 0,
                        keywayMeasurementUnit: "mm",
                        swishMeasurementUnit: "mtr",
                        keywayConversionFactor: 1000,
                        swishConversionFactor: 1,
                        isVisibleInPartList: true,
                        isDeductionApplicable: true
                    }];
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "channels":
                attribute = "Channels";
                label = product.items[itemIndex].configuration.channels.selected.value.optionKey;
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                options = (product.items[itemIndex].configuration.channels.options || []).filter(o => o.parentOptionId === product.items[itemIndex].configuration.channels.selected.value.id);
                (options || []).forEach(o => {
                    comment = o.optionKey;
                    sets = o.sets.filter(set => set.condition1 === condition1);
                    (sets || []).forEach(set => {
                        (set.stocks || []).forEach(s => {
                            s.comment = comment;
                            stocks.push(s);
                        });
                    })

                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.channels.selected.stocks = stocks;
                break;
            case "bottomChannel":
                attribute = "Bottom Channel";
                label = product.items[itemIndex].configuration.bottomChannel.selected.value.optionKey;
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.bottomChannel.selected.deduction = this.updateDeduction("default", product, itemIndex);
                condition1 = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                sets = product.items[itemIndex].configuration.bottomChannel.selected.value.sets.filter(set => set.condition1.includes(condition1) && ((width >= set.min) && (width <= set.max)));
                (sets || []).forEach(set => {
                    stocks = [...stocks, ...set.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bottomChannel.selected.stocks = stocks;
                break;
            case "bottomBar":
                attribute = "Bottom Bar";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                (product.items[itemIndex].configuration.bottomBar.finalOptions || []).forEach(o => {
                    sets = o.sets.filter(set => set.condition1 === condition1);
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bottomBar.selected.stocks = stocks;
                break;
            case "weightBar":
                attribute = "Weight Bar";
                label = "";
                comment = "";
                stocks = [];
                condition1 = isMotorised ? "Motorised" : "Manual/Spring";
                (product.items[itemIndex].configuration.weightBar.finalOptions || []).forEach(o => {
                    sets = o.sets.filter(set => (set.condition1.includes(condition1) && ((width >= set.min) && (width <= set.max))));
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.weightBar.selected.stocks = stocks;
                break;
            case "splineTape":
                attribute = "Spline Tape";
                label = "";
                comment = "";
                stocks = [];
                (product.items[itemIndex].configuration.splineTape.finalOptions || []).forEach(o => {
                    o.stocks.forEach(s => {
                        s.comment = o.optionKey;
                        stocks.push(s);
                    })
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.splineTape.selected.stocks = stocks;
                break;
            case "insulationFoam":
                attribute = "Insulation Foam";
                label = "";
                comment = "";
                stocks = [];
                (product.items[itemIndex].configuration.insulationFoam.finalOptions || []).forEach(o => {
                    stocks = [...stocks, ...o.stocks];
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.insulationFoam.selected.stocks = stocks;
                break;
            case "consumables":
                attribute = "Consumables";
                label = "";
                comment = "";
                stocks = [];
                (product.items[itemIndex].configuration.consumables.finalOptions || []).forEach(o => {
                    switch (o.optionKey) {
                        case "Plastic Rivets":
                            condition1 = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                            sets = o.sets.filter(set => set.condition1 === condition1);
                            break;
                        case "Temporary Spacers":
                            condition1 = isMotorised ? "Motorised" : "Manual/Spring";
                            condition2 = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
                            sets = o.sets.filter(set => {
                                return ((set.condition1 ? set.condition1.includes(condition1) : true)
                                && (set.condition2 ? set.condition2.includes(condition2) : true))
                            });
                            break;
                        default:
                            sets = o.sets;
                    }
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.consumables.selected.stocks = stocks;
                break;
            case "windoware15ChannelRemote":
                attribute = "Windoware 15 Channel Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value) {
                    optionIndex = product.items[itemIndex].configuration.windoware15ChannelRemote.finalOptions.findIndex(o => (width >= o.primaryAttributeMin) && (width <= o.primaryAttributeMax));
                    stocks = product.items[itemIndex].configuration.windoware15ChannelRemote.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks = stocks;
                break;
            case "windowareBridge":
                attribute = "Windoware Bridge";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.windowareBridge.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.windowareBridge.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windowareBridge.selected.stocks = stocks;
                break;
            case "chargingCable":
                attribute = "Charging Cable";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.chargingCable.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.chargingCable.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.chargingCable.selected.stocks = stocks;
                break;
            case "somfy20CmExtCableForLiIonForCassetteV2":
                attribute = "Somfy 20cm Ext Cable For Li-ion For Cassette (V2)";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.stocks = stocks;
                break;
            case "somfyLiIonWirefreeChargerV2":
                attribute = "Somfy Li-ion Wirefree Charger (V2)";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.stocks = stocks;
                break;
            case "somfySituo5PureRemoteControl":
                attribute = "Somfy Situo 5 Pure Remote Control";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.somfySituo5PureRemoteControl.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.stocks = stocks;
                break;
            case "pullDownStick":
                attribute = "Pull Down Stick";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.pullDownStick.selected.value) {
                    optionIndex = 0;
                    stocks = product.items[itemIndex].configuration.pullDownStick.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.pullDownStick.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {

            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("pelmetSize", product, itemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, order);
            product = this.updateStocks("keyway", product, itemIndex, order);
            product = this.updateStocks("channels", product, itemIndex, order);
            product = this.updateStocks("bottomChannel", product, itemIndex, order);
            product = this.updateStocks("bottomBar", product, itemIndex, order);
            product = this.updateStocks("weightBar", product, itemIndex, order);
            product = this.updateStocks("splineTape", product, itemIndex, order);
            product = this.updateStocks("insulationFoam", product, itemIndex, order);
            product = this.updateStocks("consumables", product, itemIndex, order);

            product = this.updateStocks("windoware15ChannelRemote", product, itemIndex, order);
            product = this.updateStocks("windowareBridge", product, itemIndex, order);
            product = this.updateStocks("chargingCable", product, itemIndex, order);
            product = this.updateStocks("somfy20CmExtCableForLiIonForCassetteV2", product, itemIndex, order);
            product = this.updateStocks("somfyLiIonWirefreeChargerV2", product, itemIndex, order);
            product = this.updateStocks("somfySituo5PureRemoteControl", product, itemIndex, order);
            product = this.updateStocks("pullDownStick", product, itemIndex, order)
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.pelmetSize.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.pelmetSize.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.keyway.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.channels.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.channels.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bottomChannel.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomChannel.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bottomBar.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomBar.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.weightBar.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.weightBar.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.splineTape.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.splineTape.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.insulationFoam.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.insulationFoam.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.consumables.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.consumables.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.windoware15ChannelRemote.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.windowareBridge.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.windowareBridge.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.chargingCable.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.chargingCable.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.pullDownStick.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.pullDownStick.selected.stocks];
            }

            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    isMotorised(product, itemIndex) {
        let isMotorised = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation)) {
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                isMotorised = product.items[itemIndex].configuration.operation.selected.value.optionKey.toUpperCase().includes("MOTOR");
            }
        }
        return isMotorised;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1 && product.items[itemIndex].configuration.width.selected.value) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1 && product.items[itemIndex].configuration.drop.selected.value) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor
        };


        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                let panel = 0, fabricDrop = 0, usage = 0;
                try {
                    panel = round(((width / 1000) / fabricWidth), 0);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }
                fabricDrop = (drop + 300) / 1000;
                if (width > (fabricWidth / 2 * 1000)) {
                    if ((width / 1000) > 3) {
                        usage = 0;
                    } else {
                        usage = panel * fabricDrop;
                    }
                } else {
                    usage = fabricDrop / round(((fabricWidth * 1000) / width), 0)
                }
                usage = roundUp(usage, 1);
                result = usage;
                deduction = this.updateDeduction("Skin", product, itemIndex, stockItem);

                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * result) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * result) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;


                break;
            case "Operation":
            case "Pelmet Size":
            case "Keyway":
            case "Channels":
            case "Bottom Channel":
            case "Bottom Bar":
            case "Weight Bar":
            case "Spline Tape":
            case "Insulation Foam":
            case "Consumables":
            case "Windoware 15 Channel Remote":
            case "Windoware Bridge":
            case "Charging Cable":
            case "Somfy 20cm Ext Cable For Li-ion For Cassette (V2)":
            case "Somfy Li-ion Wirefree Charger (V2)":
            case "Somfy Situo 5 Pure Remote Control":
            case "Pull Down Stick":
                deduction = this.updateDeduction(attribute, product, itemIndex, stockItem);
                params.cutWidth = deduction.cutWidth;
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                if (attribute == "Weight Bar" && width > 1500) {
                    stockItem.cutWidth = 600;
                }
                break;
            default:
        }
    }


    updateDeduction(key, product, itemIndex, stockItem) {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;

        switch (key) {
            case "Skin":
                deduction.deductionQty = 0;
                deduction.deductionWidth = -58;
                deduction.deductionDrop = 200;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Pelmet Size":
                deduction.deductionQty = 0;
                deduction.deductionWidth = -9;
                deduction.deductionDrop = -9;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.pelmetSize.selected.deduction = deduction;
                break;
            case "Keyway":
                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                    case "Manual/Spring":
                        deduction.deductionWidth = -72;
                        break;
                    case "Windoware Motor DC Battery":
                        if (product.items[itemIndex].configuration.operation.selected.stocks.some(s => product.builder.stockByProdCode[s.prodCode] && product.builder.stockByProdCode[s.prodCode].description.includes("MOTOR REMOTE DC BATTERY 25MM"))) {
                            deduction.deductionWidth = -74;
                        } else {
                            deduction.deductionWidth = -72;
                        }
                        break;
                    case "Windoware Motor Remote 220-240V AC WIFI":
                        deduction.deductionWidth = -72;
                        break;
                    case "Somfy Motor Sonesse 30 DC Battery RTS":
                        deduction.deductionWidth = -72;
                        break;
                    case "Somfy Motor Sonesse 40 RTS 240V AC":
                        deduction.deductionWidth = -77;
                        break;
                    default:
                        deduction.deductionWidth = -72;
                        break;
                }
                deduction.deductionQty = 0;
                deduction.deductionDrop = 0;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                break;
            case "Channels":
                deduction.deductionQty = 0;
                deduction.deductionWidth = 0;
                deduction.deductionDrop = -79;
                deduction.cutWidth = 0;
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.channels.selected.deduction = deduction;
                break;
            case "Bottom Channel":
                deduction.deductionQty = 0;
                deduction.deductionWidth = -15;
                deduction.deductionDrop = 0;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                product.items[itemIndex].configuration.bottomChannel.selected.deduction = deduction;
                break;
            case "Spline Tape":
                if (isEmpty(product.items[itemIndex].configuration.splineTape.selected.deduction)) {
                    product.items[itemIndex].configuration.splineTape.selected.deduction = {}
                }
                switch (stockItem.comment) {
                    case "Bottom Spline":
                        deduction.deductionQty = 0;
                        deduction.deductionWidth = -84;
                        deduction.deductionDrop = 0;
                        deduction.cutWidth = width + (deduction.deductionWidth);
                        deduction.cutDrop = 0;
                        product.items[itemIndex].configuration.splineTape.selected.deduction.bottomSpline = deduction;
                        break;
                    case "Side Spline":
                        deduction.deductionQty = 0;
                        deduction.deductionWidth = 0;
                        deduction.deductionDrop = 165;
                        deduction.cutWidth = 0;
                        deduction.cutDrop = drop + (deduction.deductionDrop);
                        product.items[itemIndex].configuration.splineTape.selected.deduction.sideSpline = deduction;
                        break;
                    case "Top Spline":
                        switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                            case "Manual/Spring":
                                deduction.deductionWidth = -123;//-143;
                                break;
                            case "Windoware Motor DC Battery":
                                if (product.items[itemIndex].configuration.operation.selected.stocks.some(s => product.builder.stockByProdCode[s.prodCode] && product.builder.stockByProdCode[s.prodCode].description.includes("MOTOR REMOTE DC BATTERY 25MM"))) {
                                    deduction.deductionWidth = -74;
                                } else {
                                    deduction.deductionWidth = -72;
                                }
                                break;
                            case "Windoware Motor Remote 220-240V AC WIFI":
                                deduction.deductionWidth = -72;
                                break;
                            case "Somfy Motor Sonesse 30 DC Battery RTS":
                                deduction.deductionWidth = -72;
                                break;
                            case "Somfy Motor Sonesse 40 RTS 240V AC":
                                deduction.deductionWidth = -77;
                                break;
                            default:
                                deduction.deductionWidth = -72;
                                break;
                        }
                        deduction.deductionQty = 0;
                        deduction.deductionDrop = 0;
                        deduction.cutWidth = width + (deduction.deductionWidth);
                        deduction.cutDrop = 0;
                        product.items[itemIndex].configuration.splineTape.selected.deduction.topSpline = deduction;
                        break;
                }
                break;
            case "Bottom Bar":
                deduction.deductionQty = 0;
                deduction.deductionWidth = -83;
                deduction.deductionDrop = 0;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = 0;
                product.items[itemIndex].configuration.bottomBar.selected.deduction = deduction;
                break;
            case "Weight Bar":
                deduction.deductionQty = 0;
                if (width <= 1500) {
                    deduction.deductionWidth = (-83) + (-30); //Bottom Bar -30mm //bottom bar deductionWidth = 83
                    deduction.cutWidth = width + (deduction.deductionWidth);
                }
                else {
                    deduction.cutWidth = 1200;
                    deduction.deductionWidth = -1200; //default value is -1200mm in this case
                }
                deduction.deductionDrop = 0;
                deduction.cutDrop = 0;
                product.items[itemIndex].configuration.weightBar.selected.deduction = deduction;
                break;
        }
        return deduction;
    }

    isVisibleAccessories(key, product, itemIndex) {
        let result = false;

        switch (key) {
            case "Windoware":
                result = product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible
                    || product.items[itemIndex].configuration.windowareBridge.selected.isVisible
                    || product.items[itemIndex].configuration.chargingCable.selected.isVisible
                ;
                break;
            case "Somfy":
                result = product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isVisible
                    || product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isVisible
                    || product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isVisible;
                break;
            default:
                result = false;
        }
        return result;
    }


    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        return errors;
    }

    toSalesOrderItemZiptrakInternalRequest(pg, itemIndex, order) {
        let defaultDeduction = this.updateDeduction("default", pg, itemIndex);


        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.pelmetSize.selected.deduction)) {
            pg.items[itemIndex].configuration.pelmetSize.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.keyway.selected.deduction)) {
            pg.items[itemIndex].configuration.keyway.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.channels.selected.deduction)) {
            pg.items[itemIndex].configuration.channels.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.bottomChannel.selected.deduction)) {
            pg.items[itemIndex].configuration.bottomChannel.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.bottomBar.selected.deduction)) {
            pg.items[itemIndex].configuration.bottomBar.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.weightBar.selected.deduction)) {
            pg.items[itemIndex].configuration.weightBar.selected.deduction = defaultDeduction;
        }


        let salesOrderItemZiptrakInternal = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: pg.items[itemIndex].configuration.location.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: pg.items[itemIndex].configuration.width.selected.value,
            drop: pg.items[itemIndex].configuration.drop.selected.value,
            fabricType: pg.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: pg.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            operation: pg.items[itemIndex].configuration.operation.selected.value.optionKey,
            controlSide: pg.items[itemIndex].configuration.controlSide.selected.value.optionKey,
            keyway: pg.items[itemIndex].configuration.keyway.selected.value.optionKey,
            frameColour: pg.items[itemIndex].configuration.frameColour.selected.value.optionKey,
            channels: pg.items[itemIndex].configuration.channels.selected.value.optionKey,
            bottomChannel: pg.items[itemIndex].configuration.bottomChannel.selected.value.optionKey,
            pelmetSize: pg.items[itemIndex].configuration.pelmetSize.selected.value.optionKey,
            windoware15ChannelRemote: pg.items[itemIndex].configuration.windoware15ChannelRemote.selected.value,
            windowareBridge: pg.items[itemIndex].configuration.windowareBridge.selected.value,
            chargingCable: pg.items[itemIndex].configuration.chargingCable.selected.value,
            somfySituo5PureRemoteControl: pg.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.value,
            somfyLiIonWirefreeChargerV2: pg.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.value,
            somfy20CmExtCableForLiIonForCassetteV2: pg.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value,
            pullDownStick: pg.items[itemIndex].configuration.pullDownStick.selected.value,

            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,
            pelmetSizeDeductionWidth: pg.items[itemIndex].configuration.pelmetSize.selected.deduction.deductionWidth,
            keywayDeductionWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.deductionWidth,
            channelsDeductionDrop: pg.items[itemIndex].configuration.channels.selected.deduction.deductionDrop,
            bottomChannelDeductionWidth: pg.items[itemIndex].configuration.bottomChannel.selected.deduction.deductionWidth,
            splineTapeBottomSplineDeductionWidth: pg.items[itemIndex].configuration.splineTape.selected.deduction.bottomSpline.deductionWidth,
            splineTapeSideSplineDeductionDrop: pg.items[itemIndex].configuration.splineTape.selected.deduction.sideSpline.deductionDrop,
            splineTapeTopSplineDeductionWidth: pg.items[itemIndex].configuration.splineTape.selected.deduction.topSpline.deductionWidth,
            bottomBarDeductionWidth: pg.items[itemIndex].configuration.bottomBar.selected.deduction.deductionWidth,
            weightBarDeductionWidth: pg.items[itemIndex].configuration.weightBar.selected.deduction.deductionWidth,

            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,
            pelmetSizeDeductionCutWidth: pg.items[itemIndex].configuration.pelmetSize.selected.deduction.cutWidth,
            keywayCutWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.cutWidth,
            channelsCutDrop: pg.items[itemIndex].configuration.channels.selected.deduction.cutDrop,
            bottomChannelCutWidth: pg.items[itemIndex].configuration.bottomChannel.selected.deduction.cutWidth,
            splineTapeBottomSplineCutWidth: pg.items[itemIndex].configuration.splineTape.selected.deduction.bottomSpline.cutWidth,
            splineTapeSideSplineCutDrop: pg.items[itemIndex].configuration.splineTape.selected.deduction.sideSpline.cutDrop,
            splineTapeTopSplineCutWidth: pg.items[itemIndex].configuration.splineTape.selected.deduction.topSpline.cutWidth,
            bottomBarCutWidth: pg.items[itemIndex].configuration.bottomBar.selected.deduction.cutWidth,
            weightBarCutWidth: pg.items[itemIndex].configuration.weightBar.selected.deduction.cutWidth,

            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemZiptrakInternal;
    }
}

export default ZiptrakInternalUtil.Instance();
