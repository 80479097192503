import React, {Component} from 'react';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import CustomerService from '../../services/CustomerService';
import courierService from '../../services/shipment/CourierService';
import {toast, ToastContainer} from 'react-toastify';
import {handleErrorMessage} from '../../services/CommonService';

export default class DebtorDeliveryPreference extends Component {

    constructor(props) {
        super(props);

        this.state = {
            courier: "",
            couriers: [],
            printPackingSlip: false,
            printInvoice: false,
            printDispatchLabel: false,
            isLoadingSave: false,
            emailInvoice: false,
            emailDispatchNotice: false
        };
        this.customerService = new CustomerService();
        this.getCouriers = this.getCouriers.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.getCouriers();
        this.getDebtorPreference(this.props.accountID);
    }


    getCouriers() {
        courierService.getCouriers().then(response => {
            let couriers = response.data;
            this.setState({couriers});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getDebtorPreference(accountID) {
        if (accountID) {
            this.customerService.getDebtorPreference(accountID).then(response => {
                if (response.data) {
                    this.setState({
                        courier: response.data.courier,
                        printPackingSlip: response.data.printPackingSlip,
                        printInvoice: response.data.printInvoice,
                        printDispatchLabel: response.data.printDispatchLabel,
                        emailDispatchNotice: response.data.isEmailDispatch,
                        emailInvoice: response.data.isEmailInvoice
                    });
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error));
            });
        }
    }

    handleChange(key, value) {
        this.setState({[key]: value});
    }

    handleSubmit(e) {
        this.setState({isLoadingSave: true});
        let { courier, printPackingSlip, printInvoice,
            printDispatchLabel, emailDispatchNotice, emailInvoice } = this.state;
        let {accountID} = this.props;
        let request = {
            accountID: accountID,
            courier: courier,
            printPackingSlip: printPackingSlip,
            printInvoice: printInvoice,
            printDispatchLabel: printDispatchLabel,
            emailDispatchNotice: emailDispatchNotice,
            emailInvoice: emailInvoice
        };
        this.customerService.updateDebtorDeliveryPreference(request).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success("Delivery preference updated!");
                this.setState({isLoadingSave: false});
                this.props.refresh();
                this.props.toggleModal(false);
            }
        }).catch(error => {
            this.setState({isLoadingSave: false});
            console.log(error.data);
            toast.error(handleErrorMessage(error));
        });
    }


    render() {
        let { isLoadingSave, courier, couriers, printPackingSlip, printInvoice,
            printDispatchLabel, emailDispatchNotice, emailInvoice } = this.state;
        let {isOpen, toggleModal} = this.props;
        return (
            <Modal isOpen={isOpen}
                   size="md"
                   scrollable={false}
                   toggle={() => toggleModal(!isOpen)}>
                <ModalHeader toggle={() => toggleModal(!isOpen)}>
                    Dispatch Settings
                </ModalHeader>
                <ModalBody>
                    <div>
                        Courier
                        <Input type="select" name="courier"
                               value={courier} onChange={(e) => this.handleChange("courier", e.target.value)}>
                            <option value={0}>Select</option>
                            {(couriers || []).map((courier, index) =>
                                <option key={index} value={courier.courier}> {courier.courier}</option>
                            )}
                        </Input>
                    </div>
                    <div className={"mt-2"}>
                        Print Packing Slip
                        <Input type="select" name="printPackingSlip"
                               value={printPackingSlip}
                               onChange={(e) => this.handleChange("printPackingSlip", e.target.value)}>
                            <option value={true}> Yes</option>
                            <option value={false}> No</option>
                        </Input>
                    </div>
                    <div className={"mt-2"}>
                        Print Invoice
                        <Input type="select" name="printInvoice"
                               value={printInvoice}
                               onChange={(e) => this.handleChange("printInvoice", e.target.value)}>
                            <option value={true}> Yes</option>
                            <option value={false}> No</option>
                        </Input>
                    </div>
                    <div className={"mt-2"}>
                        Print Dispatch Label
                        <Input type="select" name="printDispatchLabel"
                               value={printDispatchLabel}
                               onChange={(e) => this.handleChange("printDispatchLabel", e.target.value)}>
                            <option value={true}> Yes</option>
                            <option value={false}> No</option>
                        </Input>
                    </div>
                    <div className={"mt-2"}>
                        Email Invoice
                        <Input type="select" name="emailInvoice"
                            value={emailInvoice}
                            onChange={(e) => this.handleChange("emailInvoice", e.target.value)}>
                            <option value={true}> Yes</option>
                            <option value={false}> No</option>
                        </Input>
                    </div>
                    <div className={"mt-2"}>
                        Email Dispatch Notice
                        <Input type="select" name="emailDispatchNotice"
                            value={emailDispatchNotice}
                            onChange={(e) => this.handleChange("emailDispatchNotice", e.target.value)}>
                            <option value={true}> Yes</option>
                            <option value={false}> No</option>
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button size={"sm"}
                                color='primary'
                                onClick={this.handleSubmit}
                                disabled={isLoadingSave}>
                            {
                                isLoadingSave
                                    ? <Spinner size="sm" className="mr-2" color={"light"}/>
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>
                            }
                            {
                                isLoadingSave
                                    ? "Saving"
                                    : "Save"
                            }
                        </Button>
                        <Button size={"sm"}
                                color={"secondary"}
                                className={"ml-2"}
                                onClick={() => toggleModal(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}