import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText,
    Alert
} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ToastContainer, toast} from 'react-toastify';
import StockInventoryService from '../../services/StockInventoryService';
import {maxDate, minDate} from '../../components/OrderGatewayComponents/OrderGatewayCommon';
import StockInventoryQuickLinks from '../../components/WINOInventory/StockInventoryQuickLinks';
import moment from 'moment';

//Manage-StockInventory
export default class ManageStockInventory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prodCode: '',
            stockInventoryObj: {
                id: 0,
                dateSoldOut: null,
                isSoldOut: false,
                isAvailable: false,
                minQOH: 0.00,
                maxQOH: 0.00,
                stockInventoryStatus: {
                    id: 0,
                    qOH: 0,
                    qOO: 0,
                    alloted: 0,
                    free: 0
                },
                stockInventoryPrice: {
                    id: 0,
                    marginRate: 0,
                    marginValue: 0,
                    price: 0
                },
                stockInventoryCost: {
                    id: 0,
                    cost: 0,
                    avgCost: 0,
                    fifoCost: 0,
                    lifoCost: 0
                }
            },
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectCheckbox = this.selectCheckbox.bind(this);
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.prodCode) {
            if (this.state.prodCode !== nextProps.prodCode) {
                this.setState({prodCode: nextProps.prodCode}, () => {
                    this.refresh()
                });
            }
        }
    }

    refresh() {
        if (this.state.prodCode) {
            this.setState({loading: true})
            StockInventoryService.getOnestockInventoryByStock(this.state.prodCode).then(response => {
                let stockInventoryObj = response.data;
                if (stockInventoryObj.dateSoldOut) {
                    let dateSoldOut = moment(stockInventoryObj.dateSoldOut, "DD/MM/YYYY");
                    stockInventoryObj.dateSoldOut = dateSoldOut._d;
                }
                if (response.data.stockInventoryStatus === null) {
                    stockInventoryObj.stockInventoryStatus = {
                        id: 0,
                        qOH: 0,
                        qOO: 0,
                        alloted: 0,
                        free: 0
                    }
                }
                if (response.data.stockInventoryPrice === null) {
                    stockInventoryObj.stockInventoryPrice = {
                        id: 0,
                        marginRate: 0,
                        marginValue: 0,
                        price: 0
                    }
                }
                if (response.data.stockInventoryCost === null) {
                    stockInventoryObj.stockInventoryCost = {
                        id: 0,
                        cost: 0,
                        avgCost: 0,
                        fifoCost: 0,
                        lifoCost: 0
                    }
                }
                this.setState({stockInventoryObj, loading: false});
                //console.log(response.data, '--------------response.data----------')
            }).catch(error => {
                this.setState({loading: false});
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    selectCheckbox(value, name) {
        let {stockInventoryObj} = this.state;
        stockInventoryObj[name] = !value;
        this.setState({stockInventoryObj});
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {stockInventoryObj} = this.state;
        stockInventoryObj[name] = value;
        this.setState({stockInventoryObj});
    }


    handledateChange(change, key) {
        let {stockInventoryObj} = this.state;
        switch (key) {
            case "dateSoldOut":
                stockInventoryObj.dateSoldOut = change;
                this.setState({stockInventoryObj});
                break;
        }
    }

    handleCancel() {
        this.props.history.push('/inventory/stocks');
    }

    validateForm(stockInventoryObj) {
        let formErrors = {};
        let valid = true;

        if (stockInventoryObj.minQOH === null || (stockInventoryObj.minQOH === undefined)) {
            formErrors.minQOH = "Please enter Min QOH";
            valid = false;
        }

        if (stockInventoryObj.maxQOH === null || (stockInventoryObj.maxQOH === undefined)) {
            formErrors.maxQOH = "Please enter Max QOH";
            valid = false;
        }
        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.stockInventoryObj};
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            this.setState({saving: true, formErrors: {}});

            StockInventoryService.saveOrUpdateStockInventory(this.state.prodCode, req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (req.id <= 0) {
                        //this.props.history.push('/inventory/stock-inventory');
                        toast.success("Stock Inventory saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        toast.success("Stock Inventory updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    this.setState({saving: false}, () => {
                        this.refresh()
                    });
                }
            }).catch(error => {
                this.setState({saving: false});
                console.log(error.data)
            });
        }
    }

    render() {
        let {stockInventoryObj, formErrors, loading, prodCode} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/stocks')}>Stock</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!prodCode ? 'Add' : 'Edit'} Inventory</BreadcrumbItem>
                </Breadcrumb>
                <Alert color="light">
                    <div className="text-right">
                        <StockInventoryQuickLinks
                            showInventoryStatus={true}
                            showInventoryPrice={true}
                            showInventoryCost={true}
                            prodCode={prodCode}
                        />
                    </div>
                </Alert>


                <Card>
                    <CardHeader>
                        <h5>Basic Stock Inventory Details</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary"/> :
                            <Form>
                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Min QOH</Label>
                                            <Input
                                                invalid={formErrors.minQOH != null}
                                                name="minQOH"
                                                type="number"
                                                value={stockInventoryObj.minQOH || ''}
                                                onChange={this.handleChange}
                                                placeholder="Min QOH ..."/>
                                            <FormText color="danger">{formErrors.minQOH}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Max QOH</Label>
                                            <Input
                                                invalid={formErrors.maxQOH != null}
                                                name="maxQOH"
                                                type="number"
                                                value={stockInventoryObj.maxQOH || ''}
                                                onChange={this.handleChange}
                                                placeholder="Max QOH ..."/>
                                            <FormText color="danger">{formErrors.maxQOH}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Date Sold Out</Label>
                                            <DatePicker
                                                name="dateSoldOut"
                                                className="form-control"
                                                selected={stockInventoryObj.dateSoldOut}
                                                onChange={date => this.handledateChange(date, "dateSoldOut")}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                placeholderText="dd/MM/yyyy"
                                                showMonthDropdown
                                                showYearDropdown
                                                maxDate={maxDate}
                                                minDate={minDate}
                                                scrollableYearDropdown
                                                dropdownMode="select"
                                                withPortal
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <div className="checkbox checkbox-secondary">
                                            <a onClick={() => this.selectCheckbox(stockInventoryObj.isAvailable, "isAvailable")}>
                                                {stockInventoryObj.isAvailable ?
                                                    <i className="fa fa-lg fa-check-square-o mr-2"
                                                       aria-hidden="true"/> :
                                                    <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true"/>}
                                            </a>
                                            <Label className="mb-0">Is Available</Label>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <a onClick={() => this.selectCheckbox(stockInventoryObj.isSoldOut, "isSoldOut")}>
                                            {stockInventoryObj.isSoldOut ?
                                                <i className="fa fa-lg fa-check-square-o mr-2" aria-hidden="true"/> :
                                                <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true"/>}
                                        </a>
                                        <Label className="mb-0">Is SoldOut</Label>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                    </Col>
                                </Row>

                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div style={{float: 'right'}}>
                            <Button color='primary' size={"sm"} onClick={this.handleSubmit}
                                    disabled={this.state.saving}>
                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                              style={{color: "white"}}/> :
                                    <i className="fa fa-floppy-o mr-2"
                                       aria-hidden="true"/>}
                                Save</Button>{' '}
                            <Button color='secondary' size={"sm"} onClick={this.handleCancel}>
                                <i className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                Back</Button>{' '}
                        </div>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}
