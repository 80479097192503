import React, { Component } from "react";
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, Table,
    Row, Col, Label, InputGroup, InputGroupAddon, InputGroupText, Input
} from 'reactstrap';
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import RoleService from '../../services/RoleService';
import { handleErrorMessage } from '../../services/CommonService';
export default class RoleUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: this.props.selectedItem,
            isLoading: false,
            user: {
                request: {
                    pageRequest: { pageSize: 50, currentPage: 1 },
                    sortRequest: { key: '', direction: false },
                    filterRequest: { name: '' }
                },
                response: {
                    records: [],
                    totalRecords: 0
                }
            },
        }

        this.roleService = new RoleService();
        this.getUserList = this.getUserList.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.getUserList();
    }
    getUserList() {
        let { selectedItem, user } = this.state;
        this.setState({ isLoading: true });
        this.roleService.getUserList(selectedItem.role.roleID, user.request).then(response => {
            user.response = response.data;
            this.setState({ user, isLoading: false });
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }
    handleChange(change, key) {
        let { user } = this.state;
        switch (key) {
            case "pageSize":
                user.request.pageRequest[key] = parseInt(change);
                user.request.pageRequest.currentPage = 1;
                this.getUserList(user);
                break;
            case "currentPage":
                user.request.pageRequest[key] = change;
                this.getUserList(user);
                break;


            default:
                user.request.filterRequest[key] = change;
                user.request.pageRequest.currentPage = 1;
                this.getUserList(user);
                this.setState({ user });
        }
    }
    render() {
        let { isOpen, toggle } = this.props;
        let { user, isLoading } = this.state;
        let { pageRequest, sortRequest } = user.request;
        let totalPage=Math.ceil(user.response.totalRecords/pageRequest.pageSize);

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Users
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Table striped bordered responsive hover size='sm'>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>

                            <tbody>
                                {(user.response.records || []).map((item, index) =>

                                    <tr key={index}>
                                        <td>{(index + 1)}</td>
                                        <td>{item.name}</td>
                                        <td>{item.emailAddress}</td>
                                    </tr>
                                )}

                            </tbody>
                        </Table>
                        {
                        (totalPage>1) ?
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={isLoading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={user.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>:null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Close</Button>

                    </div>
                </ModalFooter>

            </Modal>
        );
    }
}