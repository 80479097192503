import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    Badge,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    InputGroupText,
    Spinner,
    Label,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,ButtonGroup
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import keywayStockService from '../../services/KeywayStockService';
import * as FileSaver from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
import { handleErrorMessage, changeFormatOfDateString } from '../../services/CommonService';
import ManageKeywayStockModal from '../../components/modal/ManageKeywayStockModal';
import queryString from 'query-string';
import SearchStockGroupMulti from "../../../src/components/search/SearchStockGroupMulti";
import SearchStockMasterGroupMulti from "../../components/search/SearchStockMatserGroupMulti";
import SearchStockColourKeyway from "../../components/search/SearchStockColourKeyway";
import SearchSupplierAccount from "../../components/search/SearchCreditorAcccount";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../store/AppConstants";
import StockQtyFormat from "../../components/stock/StockQtyFormat";

export default class StocksPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "qoh",
                        direction: true
                    },
                    filterRequest: {
                        prodCode: '',
                        description: '',
                        colour: '',
                        finish: '',
                        material: '',
                        eachUnitName: '',
                        length: 0,
                        width: 0,
                        height: 0,
                        weight: 0,
                        eachUnitPrice: 0,
                        notes: '',
                        selectedStockGroups: '',
                        selectedStockMasterGroups: '',
                        selectedColour: '',
                        isRange: true,
                        excludeDiscontinued: -1, //0->contiune, 1->Discontiune, -1-> Both
                        excludeSoldOut: false,
                        excludeArchived: 0,  //0->active stock, 1->Archived Stock -1->both
                        supplierCode: '',
                        excludeBOMCodes: true,
                        excludeOffRange: -1,
                        excludeOutOfStock: 0  // 0->available & OOS, (-1) -> only OOS, 1-> available OOS
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            downloading: false,
            searchText: '',
            isOpenModal: false,
            selectedStockProdCode: '',
            selectedStockGroups: '',
            selectedStockMasterGroups: '',
            selectedColour: '',
            excludeOffRange: -1, //0->Range, 1->off range, -1->both
            excludeDiscontinued: -1,
            excludeSoldOut: false,
            excludeArchived: 0,
            excludeBOMCodes: false,
            excludeOutOfStock: 0
        };

        this.getStocks = this.getStocks.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.exportStocks = this.exportStocks.bind(this);
        this.exportBOM = this.exportBOM.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
    }

    componentDidMount() {
        this.getStocks(this.state.stockData);
    }

    getStocks(stockData) {
        this.setState({loading: true});
        keywayStockService.listAllStocks(stockData.request).then(response => {
            let data = response.data;
            (data.records || []).map((stock) => {
                if (stock.location && stock.location.startsWith(",")) {
                    stock.location = stock.location.slice(1);
                }
            });
            stockData.response = data;
            this.setState({stockData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();
            flag = item.description ? item.description.toLowerCase().includes(searchText) : false;
            if (!flag && item.prodCode) {
                flag = item.prodCode.toLowerCase().includes(searchText);
            }
            if (!flag && item.colour) {
                flag = item.colour.toLowerCase().includes(searchText);
            }
            if (!flag && item.finish) {
                flag = item.finish.toLowerCase().includes(searchText);
            }
            if (!flag && item.material) {
                flag = item.material.toLowerCase().includes(searchText);
            }
            if (!flag && item.eachUnitName) {
                flag = item.eachUnitName.toLowerCase().includes(searchText);
            }
            if (!flag && item.dimensionUnitName) {
                flag = item.dimensionUnitName.toLowerCase().includes(searchText);
            }
            if (!flag && item.weightUnitName) {
                flag = item.weightUnitName.toLowerCase().includes(searchText);
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {stockData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change, stockData});
                break;
            case "sortKey":
                if (stockData.request.sortRequest.key === change) {
                    stockData.request.sortRequest.direction = !stockData.request.sortRequest.direction;
                } else {
                    stockData.request.sortRequest.key = change;
                    stockData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStocks(stockData);
                }
                break;
            case "pageSize":
                stockData.request.pageRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                break;
            case "currentPage":
                stockData.request.pageRequest[key] = change;
                this.getStocks(stockData);
                break;


            default:
                stockData.request.filterRequest[key] = change;

                if (key === 'weight' || key === 'length') {
                    if (change === "") {
                        stockData.request.filterRequest[key] = 0;
                    }
                    else {
                        stockData.request.filterRequest[key] = parseFloat(change);
                    }
                }
                stockData.request.pageRequest.currentPage = 1;
                this.getStocks(stockData);
                this.setState({stockData});
        }

    }

    handleFilterChange(change, key) {
        let {stockData} = this.state;

        switch (key) {
            case "selectedStockGroups":
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, selectedStockGroups: change });
                this.getStocks(stockData);
                break;
            case "selectedStockMasterGroups":
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, selectedStockMasterGroups: change });
                this.getStocks(stockData);
                break;
            case "selectedColour":
                stockData.request.filterRequest[key] = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, selectedColour: change });
                this.getStocks(stockData);
                break;
            case "excludeOffRange":
                stockData.request.filterRequest.excludeOffRange = parseInt(change);
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, excludeOffRange: change }, () => { 
                    this.getStocks(stockData);
                });
                break;
            case "excludeDiscontinued":
                stockData.request.filterRequest.excludeDiscontinued = parseInt(change);
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, excludeDiscontinued: change });
                this.getStocks(stockData);
                break;
            case "excludeSoldOut":
                stockData.request.filterRequest.excludeSoldOut = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, excludeSoldOut: change });
                this.getStocks(stockData);
                break;
            case "excludeArchived":
                stockData.request.filterRequest.excludeArchived = parseInt(change);
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, excludeArchived: change });
                this.getStocks(stockData);
                break;
            case "excludeOutOfStock":
                stockData.request.filterRequest.excludeOutOfStock = parseInt(change);
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, excludeOutOfStock: change });
                this.getStocks(stockData);
                break;
            case "excludeBOMCodes":
                stockData.request.filterRequest.excludeBOMCodes = change;
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData, excludeBOMCodes: change });
                this.getStocks(stockData);
                break;
            default:
                stockData.request.pageRequest.currentPage = 1;
                this.setState({ stockData });
                this.getStocks(stockData);
                break;
        }
    }

    handleAccountChange(accountID) {
        let { stockData } = this.state;
        stockData.request.filterRequest.supplierCode = accountID;
        this.setState({ stockData });
        this.getStocks(stockData);
    }

    getStockHeading({filterRequest}) {
        let {stockData, selectedColour, selectedStockGroups, selectedStockMasterGroups} = this.state;
        let stockHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "prodCode",
                label: "Prodcode",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="prodCode" name="prodCode" value={filterRequest.prodCode}
                                        onChange={(e) => this.handleChange(e.target.value, "prodCode")}
                                        placeholder="Search... "/>
                </div>

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><Input type="search" id="description" name="description"
                                        value={filterRequest.description}
                                        onChange={(e) => this.handleChange(e.target.value, "description")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "colour",
                label: "Colour",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: <div><SearchStockColourKeyway
                    handleSelection={(selectedColour) => this.handleFilterChange(selectedColour, "selectedColour")}
                    selectedColour={selectedColour}
                    defaultColour={""}
                    filter={{
                        bmFlag: 1,
                        discontinued: null,
                        soldOut: null,
                        stockGroupCodes: selectedStockGroups,
                        stockMasterGroupCodes: selectedStockMasterGroups
                    }}
                /></div>
            },
            //{
            //    key: "finish",
            //    label: "Finish",
            //    type: "text",
            //    colSpan: 1,
            //    minWidth: 80,
            //    sorterApplicable: false,
            //    valueClassName: "text-center",
            //    labelClassName: "align-middle text-left",
            //    searchNode: <div><Input type="text" id="finish" name="finish"
            //                            value={filterRequest.finish}
            //                            onChange={(e) => this.handleChange(e.target.value, "finish")}
            //                            placeholder="Search..."/></div>
            //},
            //{
            //    key: "material",
            //    label: "Material",
            //    type: "text",
            //    colSpan: 1,
            //    minWidth: 80,
            //    sorterApplicable: false,
            //    valueClassName: "text-center",
            //    labelClassName: "align-middle text-left",
            //    searchNode: <div><Input type="text" id="material" name="material"
            //                            value={filterRequest.material}
            //                            onChange={(e) => this.handleChange(e.target.value, "material")}
            //                            placeholder="Search..."/>
            //    </div>
            //},
            {
                key: "length",
                label: "Length",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "linearWidth",
                label: "Linear Width",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle text-center",
                searchNode: null
            },
            {
                key: "supplierName",
                label: "Supplier",
                type: "text",
                colSpan: 1,
                minWidth: 180,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: <div>
                    <SearchSupplierAccount handleAccountChange={this.handleAccountChange}
                        selectedAccountID={stockData.request.filterRequest.supplierCode}
                        defaultAccountID={stockData.request.filterRequest.supplierCode}
                    />
                </div>
            },
            {
                key: "qoh",
                label: "QOH",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
            },
            {
                key: "eta",
                label: "ETA",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
            },
            {
                key: "qoo",
                label: "QOO",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
            },
            {
                key: "allocated",
                label: "Allocated",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
            },
            {
                key: "free",
                label: "Free",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
            },
            {
                key: "stockGroup",
                label: "Stock Group",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
            },
            {
                key: "location",
                label: "Location",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-left",
            },
            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-left",
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                searchNode: <div className="text-center align-middle">
                    <Button color='primary'
                            size='sm'
                            onClick={() => this.openModal('')}>
                        <i className="fa fa-plus mr-2"
                           aria-hidden="true"/>
                        Add</Button>
                </div>

            }
        ];

        return stockHeading;
    }

    openModal(ProdCode) {
        this.setState({isOpenModal: true, selectedStockProdCode: ProdCode});
    }

    toggleModal(data) {
        this.setState({isOpenModal: data});
    }

    exportBOM() {
        this.setState({ downloading: true });
        keywayStockService.generateExcelBOMReport().then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "BOM_Sheet" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    exportStocks(key) {
        let {stockData} = this.state;
        let request = cloneDeep(this.state.stockData.request);
        request.filterRequest = {
            prodCode: '',
            description: '',
            colour: '',
            finish: '',
            material: '',
            eachUnitName: '',
            length: null,
            width: null,
            height: null,
            weight: null,
            eachUnitPrice: null,
            notes: '',
            selectedStockGroups: '',
            selectedStockMasterGroups: '',
            selectedColour: '',
            isRange: null

        };
        this.setState({downloading: true});
        if (key === "All") {
            keywayStockService.generateExcelReport(request).then(response => {
                this.setState({downloading: false});
                FileSaver.saveAs(response.data, "StockReport" + ".xlsx");
            }).catch(error => {
                this.setState({downloading: false});
                toast.error(handleErrorMessage(error));
            });
        }
        else if (key === "Filter") {
            keywayStockService.generateExcelReport(stockData.request).then(response => {
                this.setState({downloading: false});
                FileSaver.saveAs(response.data, "Stocks" + ".xlsx");
            }).catch(error => {
                this.setState({downloading: false});
                toast.error(handleErrorMessage(error));
            });
        }
    }

    render() {
        let {
            stockData, loading, searchText, downloading, isOpenModal, selectedStockProdCode, selectedStockGroups,
            selectedStockMasterGroups, selectedColour, excludeOffRange, excludeDiscontinued, excludeSoldOut, excludeArchived,
            excludeBOMCodes, excludeOutOfStock
        } = this.state;
        let {pageRequest, sortRequest, filterRequest} = stockData.request;
        let stockHeading = this.getStockHeading(stockData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stocks</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Stocks</h5>
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color={"success"} size='sm' disabled={downloading}
                                            outline={true}>
                                            {downloading
                                                ? <Spinner size="sm"
                                                    className={"mr-2"}
                                                    style={{ color: "green" }} />
                                                : <i className="fa fa-download mr-2" aria-hidden="true" />}
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem title={'Click here to export all data'}
                                                          onClick={() => this.exportStocks("All")}>
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true"/> All Stocks
                                            </DropdownItem>
                                            <DropdownItem title={'Click here to export filtered data'}
                                                          onClick={() => this.exportStocks("Filter")}>
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true"/> Filtered
                                                Stocks
                                            </DropdownItem>
                                            <DropdownItem title={'Click here to export BOM data'}
                                                onClick={this.exportBOM}>
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> BOM
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown> &nbsp;
                                    <Link color='primary' style={{textDecoration: "none"}}
                                          className="btn btn-sm btn btn-primary"
                                          to={'/inventory/keyway/stock/import'}>
                                        <i className="fa fa-upload mr-2"
                                           aria-hidden="true"/>
                                        Import
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <Row className={"mb-2 align-items-center"}>
                                {/*<Col xl={3} lg={6} md={6} sm={12} xs={12}>*/}
                                {/*    Select Stock Master Group*/}
                                {/*    <SearchStockMasterGroupMulti*/}
                                {/*        handleSelection={(selectedMasterStockGroups) => this.handleFilterChange(selectedMasterStockGroups, "selectedStockMasterGroups")}*/}
                                {/*        selectedStockMasterGroupCodes={selectedStockMasterGroups}*/}
                                {/*        defaultStockMasterGroupCodes={""}*/}
                                {/*        filter={{isKeywayAvailableOnly: true, stockMasterGroupCodes: []}}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                                    Select Stock Group Parent
                                    <SearchStockGroupMulti
                                        handleSelection={(selectedStockGroups) => this.handleFilterChange(selectedStockGroups, "selectedStockGroups")}
                                        selectedStockGroupCodes={selectedStockGroups}
                                        defaultStockGroupCodes={""}
                                        filter={{
                                            isKeywayAvailableOnly: true,
                                            stockMasterGroupCodes: selectedStockMasterGroups,
                                            stockGroupCodes: [],
                                            excludeArchived: excludeArchived
                                        }}
                                    />
                                </Col>
                                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                                    <div>
                                        <Label>Stock Master Group</Label>
                                        <div>
                                            <ButtonGroup>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleFilterChange(1, "excludeArchived")}
                                                    color={"primary"}

                                                    outline={excludeArchived !== 1}>
                                                    Only Archived
                                                    {excludeArchived === 1
                                                        ? <i className="fa fa-check ml-1"
                                                            aria-hidden="true" />
                                                        : null
                                                    }</Button>

                                                <Button
                                                    onClick={() => this.handleFilterChange(0, "excludeArchived")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeArchived !== 0}>
                                                    Only Unarchived
                                                    {excludeArchived === 0
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(-1, "excludeArchived")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeArchived !== -1}>
                                                    All
                                                    {excludeArchived === -1
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                                    <div>
                                        <Label> Discontinued Status</Label>
                                        <div>
                                            <ButtonGroup>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleFilterChange(1, "excludeDiscontinued")}
                                                    color={"primary"}

                                                    outline={excludeDiscontinued !== 1}>
                                                    Only Discontinued
                                                    {excludeDiscontinued === 1
                                                        ? <i className="fa fa-check ml-1"
                                                            aria-hidden="true" />
                                                        : null
                                                    }</Button>

                                                <Button
                                                    onClick={() => this.handleFilterChange(0, "excludeDiscontinued")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeDiscontinued !== 0}>
                                                    Only Continued
                                                    {excludeDiscontinued === 0
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(-1, "excludeDiscontinued")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeDiscontinued !== -1}>
                                                    All
                                                    {excludeDiscontinued === -1
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col> 
                                <Col xl={4} lg={6} md={6} sm={12} xs={12} className={"mb-2"}>
                                    <div>
                                        <Label> Stock Level</Label>
                                        <div>
                                            <ButtonGroup>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleFilterChange(1, "excludeOutOfStock")}
                                                    color={"primary"}

                                                    outline={excludeOutOfStock !== 1}>
                                                    Only In Stock
                                                    {excludeOutOfStock === 1
                                                        ? <i className="fa fa-check ml-1"
                                                            aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(-1, "excludeOutOfStock")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeOutOfStock !== -1}>
                                                    Only OOS
                                                    {excludeOutOfStock === -1
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(0, "excludeOutOfStock")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeOutOfStock !== 0}>
                                                    All
                                                    {excludeOutOfStock === 0
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6} md={6} sm={12} xs={12} className={"mb-2"}>
                                    <div>
                                        <Label>Range Status</Label>
                                        <div>
                                            <ButtonGroup>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleFilterChange(1, "excludeOffRange")}
                                                    color={"primary"}

                                                    outline={excludeOffRange !== 1}>
                                                    Only Off Range
                                                    {excludeOffRange === 1
                                                        ? <i className="fa fa-check ml-1"
                                                            aria-hidden="true" />
                                                        : null
                                                    }</Button>

                                                <Button
                                                    onClick={() => this.handleFilterChange(0, "excludeOffRange")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeOffRange !== 0}>
                                                    Only Range
                                                    {excludeOffRange === 0
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                                <Button
                                                    onClick={() => this.handleFilterChange(-1, "excludeOffRange")}
                                                    color={"primary"}
                                                    size={"sm"}
                                                    outline={excludeOffRange !== -1}>
                                                    All
                                                    {excludeOffRange === -1
                                                        ?
                                                        <i className="fa fa-check ml-1" aria-hidden="true" />
                                                        : null
                                                    }</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockData.response.totalRecords}
                                            {' '}entries&nbsp;&nbsp;
                                        </p>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(stockHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }
                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(stockHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {(stockData.response.records || []).filter((stock) => this.searchFunction(stock, searchText)).map((stock, index) => {
                                    return <tr key={index} className="text-center align-middle">
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td className="text-center align-middle">
                                                <h5>
                                                    <Link
                                                        className="btn btn-primary btn-sm"
                                                        style={{color: "white"}}
                                                        title={"Click here to see stock enquiry"}
                                                        to={"/inventory/stock/enquiry?" + queryString.stringify({prodCode: stock.prodCode})}
                                                    >{stock.prodCode}</Link>
                                                </h5>
                                            </td>
                                            <td className="text-left align-middle">{stock.description}</td>
                                            <td className="text-center align-middle">{stock.colour}</td>
                                            <td className="text-right align-middle">{stock.length}</td>
                                            <td className="text-right align-middle">{stock.linearWidth}</td>
                                            <td className="text-left align-middle">{stock.supplierName}</td>
                                            <td className={"text-center align-middle"}>
                                                <StockQtyFormat
                                                    eachUnitName={stock.eachUnitName}
                                                    whUnitName={stock.whUnitName}
                                                    whUnitQty={stock.whUnitQty}
                                                    qty={stock.qoh}
                                                    qtyType={STOCK_QTY_TYPE.each}
                                                    format={STOCK_QTY_FORMAT.each}
                                                    emptyPlaceholderText={"-"}
                                                    isPoManage={false}
                                                />
                                            </td>
                                            <td className="text-center align-middle">{changeFormatOfDateString(stock.eta, "YYYY-MM-DD hh:mm:ss", "DD MMM YYYY")}</td>
                                            <td className={"text-center align-middle"}>
                                                <StockQtyFormat
                                                    eachUnitName={stock.eachUnitName}
                                                    whUnitName={stock.whUnitName}
                                                    whUnitQty={stock.whUnitQty}
                                                    qty={stock.qoo}
                                                    qtyType={STOCK_QTY_TYPE.each}
                                                    format={STOCK_QTY_FORMAT.each}
                                                    emptyPlaceholderText={"-"}
                                                    isPoManage={false}
                                                />
                                            </td>
                                            <td className={"text-center align-middle"}>
                                                <StockQtyFormat
                                                    eachUnitName={stock.eachUnitName}
                                                    whUnitName={stock.whUnitName}
                                                    whUnitQty={stock.whUnitQty}
                                                    qty={stock.allocated}
                                                    qtyType={STOCK_QTY_TYPE.each}
                                                    format={STOCK_QTY_FORMAT.each}
                                                    emptyPlaceholderText={"-"}
                                                    isPoManage={false}
                                                />
                                            </td>
                                            <td className={"text-center align-middle"}>
                                                <StockQtyFormat
                                                    eachUnitName={stock.eachUnitName}
                                                    whUnitName={stock.whUnitName}
                                                    whUnitQty={stock.whUnitQty}
                                                    qty={stock.free}
                                                    qtyType={STOCK_QTY_TYPE.each}
                                                    format={STOCK_QTY_FORMAT.each}
                                                    emptyPlaceholderText={"-"}
                                                    isPoManage={false}
                                                />
                                            </td>
                                            <td className="text-left align-middle">{stock.stockGroupCode}</td>
                                            <td className="text-left align-middle">{stock.location}</td>
                                            <td className={"text-center align-middle"}>
                                                {stock.discontinued  && !stock.soldOut
                                                    ? <Badge color="danger">Discontinued</Badge>
                                                    : null
                                                }
                                                {stock.soldOut
                                                    ? <Badge color="warning">Sold Out</Badge>
                                                    : null
                                                }
                                                {(!stock.discontinued && !stock.soldOut)
                                                    ? <Badge color="success">Active</Badge>
                                                    : null
                                                }
                                            </td>
                                            <td className="text-center align-middle">
                                                <Button color='primary'
                                                        size='sm'
                                                        onClick={() => this.openModal(stock.prodCode)}>
                                                    <i className="fa fa-pencil mr-2"
                                                       aria-hidden="true"/>
                                                    Edit</Button>
                                            </td>
                                        </tr>
                                    }
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>

                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>

                {this.state.isOpenModal
                    ? <ManageKeywayStockModal
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        prodCode={selectedStockProdCode}
                        refreshAgain={()=> this.getStocks(stockData)}/>
                    : null
                }
                <ToastContainer/>
            </div>
        );
    }
}
