import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";
import queryString from "query-string";
import classnames from "classnames";
import StockWarehouseLocationService from "../../../services/WINOInventory/StockWarehouseLocationService";
import {toast, ToastContainer} from "react-toastify";
import {handleErrorMessage} from "../../../services/CommonService";

export default class WarehouseQtyTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warehouseList: this.props.location.state,
            prodCode: this.props.location.state.prodCode,
            description: this.props.location.state.description,
            sourceWarehouse: "",
            destinationWarehouse: "",
            transferData: {
                fromId: null,
                toId: null,
                quantity: null,
                referenceNo: "",
                description: ""
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.transfer = this.transfer.bind(this);
    }


    componentDidMount() {
    }

    showWarehouseDetails(index) {
        if (index !== "") {
            let warehouse = this.state.warehouseList.records[index];
            let {transferData} = this.state;
            return (
                <div>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Table bordered={true}>
                            <thead>
                            <tr>
                                <th>Active</th>
                                <td><i className={classnames("ml-2", "fa", "fa-lg", {
                                    "fa-check-square-o": warehouse.isActive,
                                    "fa-square-o": !warehouse.isActive
                                })}/></td>
                            </tr>
                            <tr>
                                <th>Min Qty Allocated</th>
                                <td>{warehouse.minQtyAllocated}</td>
                            </tr>
                            <tr>
                                <th>Max Qty Allocated</th>
                                <td>{warehouse.maxQtyAllocated}</td>
                            </tr>
                            <tr>
                                <th>QOH</th>
                                <td>{warehouse.qoh}</td>
                            </tr>
                            </thead>
                        </Table>
                    </Col>
                </div>
            )
        }
    }

    handleChange(e) {
        let variableName = e.target.name;
        let variableValue = e.target.value;
        let transferData = this.state.transferData;
        switch (variableName) {
            case 'source':
                this.setState({sourceWarehouse: variableValue});
                if (variableValue !== "") {
                    transferData.fromId = this.state.warehouseList.records[variableValue].id;
                }
                break;
            case 'destination':
                this.setState({destinationWarehouse: variableValue});
                if (variableValue !== "") {
                    transferData.toId = this.state.warehouseList.records[variableValue].id;
                }
                break;
            case 'quantity':
                if (variableValue !== null && variableValue > 0) {
                    transferData.quantity = variableValue;
                }
                break;
            case 'referenceNo':
                transferData.referenceNo = variableValue;
                break;
            case 'description':
                transferData.description = variableValue;
                break;
            default:
                break;
        }
        this.setState({transferData});
    }

    transfer() {
        let transferData = this.state.transferData;
        if(transferData.quantity!==null && transferData.toId!==null && transferData.fromId!==null){
            StockWarehouseLocationService.transferStockQuantity(transferData).then(response => {
                if (response.status === 200 || response.status === '200') {
                    this.props.history.push('/inventory/stocks-manage?' + queryString.stringify({prodCode: this.state.prodCode}))
                    toast.success("Stock Transfered succesfully!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        else{
            toast.error("First fill all transfer details", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    render() {
        let {prodCode, sourceWarehouse, destinationWarehouse, description} = this.state;

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/inventory/stocks')}>Stocks</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/inventory/stocks-manage?' + queryString.stringify({prodCode: prodCode}))}>Edit</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Transfer</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <h5>Warehouse Stock Quantity Transfer </h5>
                    </CardHeader>
                    <CardBody>
                        <b>{prodCode}</b>&nbsp;|&nbsp;<b>{description}</b>
                        <hr/>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Card>
                                    <CardHeader>
                                        <h6>From :</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <small>Location : </small>
                                            <Input type={"select"} name="source"
                                                   onChange={this.handleChange}>
                                                <option value={""}>Select Warehouse</option>
                                                {(this.state.warehouseList.records || []).map((item, index) => {
                                                        return <option value={index}>{item.warehouseLocation.name}</option>
                                                    }
                                                )}
                                            </Input>
                                            <br/>
                                            {sourceWarehouse !== "" ? this.showWarehouseDetails(sourceWarehouse) : null}
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Card>
                                    <CardHeader>
                                        <h6>To :</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <small>Location : </small>
                                            <Input type={"select"} name="destination"
                                                   onChange={this.handleChange}>
                                                <option value="">Select Warehouse</option>
                                                {(this.state.warehouseList.records || []).map((item, index) => {
                                                        return <option value={index}>{item.warehouseLocation.name}</option>
                                                    }
                                                )}
                                            </Input>
                                            <br/>
                                            {destinationWarehouse !== "" ? this.showWarehouseDetails(destinationWarehouse) : null}
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br/>

                        <Row>
                            <Col xl={2} lg={2} md={2} sm={0} xs={0}>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                <Label>Quantity </Label>
                                <Input autoComplete="off" type="number" name="quantity"
                                       onChange={this.handleChange}
                                       placeholder="Enter Qty to Transfer"
                                       id="qty"/>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                <Label>Reference No.</Label>
                                <Input autoComplete="off" type="text" name="referenceNo"
                                       onChange={this.handleChange}
                                       placeholder="Enter Reference No."
                                       id="refNo"/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <Label>Description</Label>
                                <Input autoComplete="off" type="text" name="description"
                                       onChange={this.handleChange}
                                       placeholder="Enter Description"
                                       id="desc"/>
                            </Col>
                        </Row>
                        <br/>
                        <div className="text-center">
                            <Button color={"primary"} size={"sm"} onClick={this.transfer}>Transfer</Button>
                        </div>

                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>

        )

    }
}