import React, {Component} from 'react';
import {Col, Row, Label, Input, InputGroup, InputGroupAddon, Button} from 'reactstrap';

import {findIndex} from '../../services/CommonService';
import repService from '../../services/RepService';


export default class RepFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filter: this.props.filter, salesRepOptions: []};
        this.updateFilter = this.updateFilter.bind(this);
    }

    componentDidMount() {
        this.getReps();
    }

    getReps() {
        repService.fetchReps().then(response => {
            let reps = response.data;
            for (let i in reps) {
                reps[i].repCode = reps[i].repCode + "";

                if (reps[i].repCode === "0") {
                    reps[i].label = reps[i].code;
                } else {
                    reps[i].label = reps[i].name + " (" + reps[i].code + ")";
                }
            }
            this.setState({salesRepOptions: reps});
        });
    }

    updateFilter(e) {
        let {filter, salesRepOptions} = this.state;
        filter.value = e.target.value;

        let index = findIndex(salesRepOptions, "repCode", filter.value + "");
        if (index > -1) {
            filter.displayValue = salesRepOptions[index].label;
        }

        this.setState({filter}, () => {
            this.props.onChange(filter, false);
        });

    }


    render() {
        let {filter, salesRepOptions} = this.state;
        let repCode = filter.value;

        return (
            <div>
                <Row className={"pb-3"}>
                    <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                        <Label>Search by Rep</Label>
                        <Input
                            className="form-control"
                            onChange={this.updateFilter}
                            type="select"
                            name="repCode"
                            value={repCode}
                            placeholder="Select Rep">
                            <option value={""}>All Reps</option>
                            {(salesRepOptions) && salesRepOptions.map((option, index) =>
                                <option key={index}
                                        value={option.repCode}>{option.label}</option>
                            )}
                        </Input>

                    </Col>
                </Row>
            </div>
        );
    }
}