import React from 'react';
import {cloneDeep, isEmpty} from 'lodash';
import {PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents} from "../../../../../../store/AppConstants";
import curtainTrackUtil from "./CurtainTrackUtil";


const QUANTITY_MIN = 1;
const QUANTITY_MAX = 1000;

class CurtainTrackCustomUtil {

    static Instance() {
        return new CurtainTrackCustomUtil()
    }

    updateFormError(key, product, itemIndex, subItemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        let temp, maxWidth;
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    if (curtainTrackUtil.isItemTypeIsCustom(product, itemIndex)) {
                        isDuplicate = product.items.filter((x, xIndex) => curtainTrackUtil.isItemTypeIsCustom(product, xIndex) && x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                        if (isDuplicate) {
                            // if duplicate found, put error
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = true;
                            i.configuration.location.formError.message = "Duplicate location!";
                        } else {
                            // if not duplicate found, check to clear previous duplicate error message
                            if (i.configuration.location.formError.message === "Duplicate location!") {
                                i.configuration.location.formError.isValid = true;
                                i.configuration.location.formError.isWarning = false;
                                i.configuration.location.formError.message = "";
                            }
                        }
                        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex, subItemIndex);

                    }
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < QUANTITY_MIN) || (product.items[itemIndex].configuration.quantity.selected.value > QUANTITY_MAX)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + QUANTITY_MIN + " and " + QUANTITY_MAX,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value) || (product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value < product.items[itemIndex].subItems[subItemIndex].configuration.width.min) || (product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value > product.items[itemIndex].subItems[subItemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].subItems[subItemIndex].configuration.width.min + " and " + product.items[itemIndex].subItems[subItemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].subItems[subItemIndex].configuration.width.formError = formError;
                break;
            case "length":
                if (product.items[itemIndex].configuration.lengthA.selected.isSelectable) {
                    if (!product.items[itemIndex].configuration.lengthA.selected.value) {
                        formError = {
                            isValid: false,
                            message: "Expected A length between " + product.items[itemIndex].subItems[subItemIndex].configuration.width.min + " and " + product.items[itemIndex].subItems[subItemIndex].configuration.width.max
                        };
                    }
                }
                if (formError.isValid) {
                    if (product.items[itemIndex].configuration.lengthB.selected.isSelectable) {
                        if (!product.items[itemIndex].configuration.lengthB.selected.value) {
                            formError = {
                                isValid: false,
                                message: "Expected B length between " + product.items[itemIndex].subItems[subItemIndex].configuration.width.min + " and " + product.items[itemIndex].subItems[subItemIndex].configuration.width.max
                            };
                        }
                    }
                }
                if (formError.isValid) {
                    if (product.items[itemIndex].configuration.lengthC.selected.isSelectable) {
                        if (!product.items[itemIndex].configuration.lengthC.selected.value) {
                            formError = {
                                isValid: false,
                                message: "Expected C length between " + product.items[itemIndex].subItems[subItemIndex].configuration.width.min + " and " + product.items[itemIndex].subItems[subItemIndex].configuration.width.max
                            };
                        }
                    }
                }
                if (formError.isValid) {
                    temp = product.items[itemIndex].subItems.map((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
                    maxWidth = Math.max(...temp);
                    if (maxWidth
                        < (product.items[itemIndex].configuration.lengthA.selected.value
                            + product.items[itemIndex].configuration.lengthB.selected.value
                            + product.items[itemIndex].configuration.lengthC.selected.value)) {
                        formError = {
                            isValid: false,
                            message: "Length of ",
                        };
                        if (product.items[itemIndex].configuration.lengthA.selected.isSelectable) {
                            formError.message = "A";
                        }
                        if (product.items[itemIndex].configuration.lengthB.selected.isSelectable) {
                            formError.message = formError.message + "+B";
                        }
                        if (product.items[itemIndex].configuration.lengthC.selected.isSelectable) {
                            formError.message = formError.message + "+C";
                        }
                        formError.message = formError.message + " should not be more than track width " + product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value;
                    }
                }
                product.items[itemIndex].configuration.lengthA.formError = formError;
                break;
            case "drop":
                /* if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                     formError = {
                         isValid: false,
                         message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                     };
                 }*/
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "splitLength":
                if (product.items[itemIndex].configuration.splitLength.selected.isSelectable) {
                    if ((!product.items[itemIndex].configuration.splitLength.selected.value) || (product.items[itemIndex].configuration.splitLength.selected.value < product.items[itemIndex].configuration.splitLength.min) || (product.items[itemIndex].configuration.splitLength.selected.value > product.items[itemIndex].configuration.splitLength.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected split length between " + product.items[itemIndex].configuration.splitLength.min + " and " + product.items[itemIndex].configuration.splitLength.max,
                        };
                    }
                }
                product.items[itemIndex].configuration.splitLength.formError = formError;
                break;
            case "spaceBetweenTracks":
                if (product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable) {
                    if ((!product.items[itemIndex].configuration.spaceBetweenTracks.selected.value) || (product.items[itemIndex].configuration.spaceBetweenTracks.selected.value < product.items[itemIndex].configuration.spaceBetweenTracks.min) || (product.items[itemIndex].configuration.spaceBetweenTracks.selected.value > product.items[itemIndex].configuration.spaceBetweenTracks.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected value between " + product.items[itemIndex].configuration.spaceBetweenTracks.min + " and " + product.items[itemIndex].configuration.spaceBetweenTracks.max,
                        };
                    }
                }
                product.items[itemIndex].configuration.spaceBetweenTracks.formError = formError;
                break;
            case "cordSize":
                if (product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.isSelectable) {
                    if ((!product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value) || (product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value < product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.min) || (product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value > product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected cord length length between " + product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.min + " and " + product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.max,
                        };
                    }
                }
                product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError = formError;
                break;
            default:
                break;
        }
        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex, subItemIndex);
        return product;
    }

    isFormErrorItemValid(product, itemIndex, subItemIndex) {
        let result = product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.lengthA.formError.isValid
            && product.items[itemIndex].configuration.splitLength.formError.isValid
            && product.items[itemIndex].configuration.spaceBetweenTracks.formError.isValid
        ;

        if (typeof subItemIndex === 'number' && subItemIndex > -1) {
            result = result && product.items[itemIndex].subItems[subItemIndex].configuration.width.formError.isValid;
            result = result && product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.isValid;
        }
        return result;
    }

    updateRules(key, product, itemIndex, subItemIndex, order) {
        let temp, width;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                product.items[itemIndex].subItems[subItemIndex].configuration.width.min = 200;
                product.items[itemIndex].subItems[subItemIndex].configuration.width.max = 6000;
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 100;
                product.items[itemIndex].configuration.drop.max = 100000;
                break;
            case "length":
                product.items[itemIndex].configuration.lengthA.selected.isSelectable = false;
                product.items[itemIndex].configuration.lengthB.selected.isSelectable = false;
                product.items[itemIndex].configuration.lengthC.selected.isSelectable = false;

                if (!isEmpty(product.items[itemIndex].configuration.bendShape.selected.value)) {
                    switch (product.items[itemIndex].configuration.bendShape.selected.value.optionKey) {
                        case "Straight":
                            product.items[itemIndex].configuration.lengthA.selected.isSelectable = false;
                            break;
                        case "L Shape":
                            product.items[itemIndex].configuration.lengthA.selected.isSelectable = true;
                            product.items[itemIndex].configuration.lengthB.selected.isSelectable = true;
                            break;
                        case "J Shape":
                            product.items[itemIndex].configuration.lengthA.selected.isSelectable = true;
                            product.items[itemIndex].configuration.lengthB.selected.isSelectable = true;
                            break;
                        case "U Shape":
                            product.items[itemIndex].configuration.lengthA.selected.isSelectable = true;
                            product.items[itemIndex].configuration.lengthB.selected.isSelectable = true;
                            product.items[itemIndex].configuration.lengthC.selected.isSelectable = true;
                            break;
                        case "S Shape":
                            product.items[itemIndex].configuration.lengthA.selected.isSelectable = true;
                            product.items[itemIndex].configuration.lengthB.selected.isSelectable = true;
                            product.items[itemIndex].configuration.lengthC.selected.isSelectable = true;
                            break;
                    }
                }
                product.items[itemIndex].configuration.lengthA.selected.value = product.items[itemIndex].configuration.lengthA.selected.isSelectable ? product.items[itemIndex].configuration.lengthA.selected.value : 0;
                product.items[itemIndex].configuration.lengthB.selected.value = product.items[itemIndex].configuration.lengthB.selected.isSelectable ? product.items[itemIndex].configuration.lengthB.selected.value : 0;
                product.items[itemIndex].configuration.lengthC.selected.value = product.items[itemIndex].configuration.lengthC.selected.isSelectable ? product.items[itemIndex].configuration.lengthC.selected.value : 0;
                break;
            case "bendShape":
                product.items[itemIndex].configuration.bendAttachment.selected.isSelectable = false;
                product.items[itemIndex].configuration.bendShape.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.bendType.selected.value)) {
                    switch (product.items[itemIndex].configuration.bendType.selected.value.optionKey) {
                        case "Custom Bend":
                        case "Radial Bend":
                            product.items[itemIndex].configuration.bendAttachment.selected.isSelectable = true;
                            product.items[itemIndex].configuration.bendShape.selected.isSelectable = false;
                            break;
                        case "None":
                        case "90 Degree Bend":
                        default:
                            product.items[itemIndex].configuration.bendAttachment.selected.isSelectable = false;
                            product.items[itemIndex].configuration.bendShape.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.bendAttachment.selected.isSelectable) {
                    product.items[itemIndex].configuration.bendAttachment.selected.value = "";
                }
                if (!product.items[itemIndex].configuration.bendShape.selected.isSelectable) {
                    product.items[itemIndex].configuration.bendShape.selected.value = null;
                    product.items[itemIndex].configuration.bendShape.selected.dropdownValue = "";
                }
                break;
            case "numberOfBends":
                product.items[itemIndex].configuration.numberOfBends.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.bendType.selected.value)) {
                    switch (product.items[itemIndex].configuration.bendType.selected.value.optionKey) {
                        case "None":
                        case "90 Degree Bend":
                            product.items[itemIndex].configuration.numberOfBends.selected.isSelectable = false;
                            break;
                        case "Radial Bend":
                        case "Custom Bend":
                            product.items[itemIndex].configuration.numberOfBends.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.numberOfBends.selected.isSelectable) {
                    product.items[itemIndex].configuration.numberOfBends.selected.value = null;
                    product.items[itemIndex].configuration.numberOfBends.selected.dropdownValue = "";
                }
                break;
            case "operation":
                product.items[itemIndex].configuration.operation.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Styleline Track":
                        case "S2000 Track":
                            product.items[itemIndex].configuration.operation.selected.isSelectable = false;
                            break;
                        case "Motorised Track":
                            product.items[itemIndex].configuration.operation.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.operation.selected.isSelectable) {
                    product.items[itemIndex].configuration.operation.selected.value = null;
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = "";
                }
                break;
            case "splitLength":
                product.items[itemIndex].configuration.splitLength.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.split.selected.value)) {
                    switch (product.items[itemIndex].configuration.split.selected.value.optionKey) {
                        case "None":
                        case "Centre":
                            product.items[itemIndex].configuration.splitLength.selected.isSelectable = false;
                            break;
                        case "Custom":
                            product.items[itemIndex].configuration.splitLength.selected.isSelectable = true;
                            product.items[itemIndex].configuration.splitLength.min = 0;
                            temp = product.items[itemIndex].subItems.map((subItem, subItemIndex) => product.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value);
                            width = Math.max(...temp);
                            product.items[itemIndex].configuration.splitLength.max = width;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.splitLength.selected.isSelectable) {
                    product.items[itemIndex].configuration.splitLength.selected.value = 0;
                }
                break;
            case "control":
                product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Styleline Track":
                        case "S2000 Track":
                        case "Motorised Track":
                            product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.dropdownValue = "";
                }
                break;
            case "cordType":
            case "cordSizeType":
                product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.isSelectable = false;
                product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value)) {
                    switch (product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey) {
                        case "Hand Drawn":
                            product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.isSelectable = false;
                            product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.isSelectable = false;
                            break;
                        case "Cord Drawn":
                            product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.isSelectable = true;
                            product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.dropdownValue = "";
                }
                if (!product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value = null;
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.dropdownValue = "";
                }
                break;
            case "cordSize":
                product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value)) {
                    switch (product.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value.optionKey) {
                        case "Standard":
                            product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.isSelectable = false;
                            break;
                        case "Custom":
                            product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value = 0;
                }
                break;
            case "projection":
                product.items[itemIndex].configuration.projection.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.projection.finalOptions);
                if (!product.items[itemIndex].configuration.projection.selected.isSelectable) {
                    product.items[itemIndex].configuration.projection.selected.value = "";
                    product.items[itemIndex].configuration.projection.selected.dropdownValue = "";
                }
                break;
            case "runnerColour":
                product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.isSelectable = !isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.finalOptions);
                if (!product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value = "";
                    product.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.dropdownValue = "";
                }
                break;
            case "returnOption":
                product.items[itemIndex].configuration.returnOption.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Styleline Track":
                        case "S2000 Track":
                            product.items[itemIndex].configuration.returnOption.selected.isSelectable = true;
                            break;
                        case "Motorised Track":
                            product.items[itemIndex].configuration.returnOption.selected.isSelectable = false;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.returnOption.selected.isSelectable) {
                    product.items[itemIndex].configuration.returnOption.selected.value = null;
                    product.items[itemIndex].configuration.returnOption.selected.dropdownValue = "";
                }
                break;
            case"remote":
            case"charger":
            case"bridge":
                product.items[itemIndex].configuration.remote.selected.isSelectable = false;
                product.items[itemIndex].configuration.charger.selected.isSelectable = false;
                product.items[itemIndex].configuration.bridge.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Remote AC Motor":
                            product.items[itemIndex].configuration.remote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.charger.selected.isSelectable = false;
                            product.items[itemIndex].configuration.bridge.selected.isSelectable = true;
                            break;
                        case "Remote DC Battery Motor":
                            product.items[itemIndex].configuration.remote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.charger.selected.isSelectable = true;
                            product.items[itemIndex].configuration.bridge.selected.isSelectable = true;
                            break;
                        case "4-Core AC Motor":
                        case "No Motor":
                        case "Manual":
                            product.items[itemIndex].configuration.remote.selected.isSelectable = false;
                            product.items[itemIndex].configuration.charger.selected.isSelectable = false;
                            product.items[itemIndex].configuration.bridge.selected.isSelectable = false;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.remote.selected.isSelectable) {
                    product.items[itemIndex].configuration.remote.selected.value = false;
                }
                if (!product.items[itemIndex].configuration.charger.selected.isSelectable) {
                    product.items[itemIndex].configuration.charger.selected.value = false;
                }
                if (!product.items[itemIndex].configuration.bridge.selected.isSelectable) {
                    product.items[itemIndex].configuration.bridge.selected.value = false;
                }
                break;
            case "spaceBetweenTracks":
                product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.trackType.selected.value)) {
                    switch (product.items[itemIndex].configuration.trackType.selected.value.optionKey) {
                        case "Single":
                            product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable = false;
                            break;
                        case "Double":
                            if ((!product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable) && (product.items[itemIndex].configuration.spaceBetweenTracks.selected.value === 0)) {
                                product.items[itemIndex].configuration.spaceBetweenTracks.selected.value = 50;
                            }
                            if (!isEmpty(product.items[itemIndex].configuration.trackType.selected.value)) {
                                switch (product.items[itemIndex].configuration.trackType.selected.value.optionKey) {
                                    case "Single":
                                        product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable = false;
                                        break;
                                    case "Double":
                                        if ((!product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable) && (product.items[itemIndex].configuration.spaceBetweenTracks.selected.value === 0)) {
                                            product.items[itemIndex].configuration.spaceBetweenTracks.selected.value = 50;
                                        }
                                        if (!isEmpty(product.items[itemIndex].configuration.bendShape.selected.value)) {
                                            switch (product.items[itemIndex].configuration.bendShape.selected.value.optionKey) {
                                                case "Straight":
                                                    product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable = false;
                                                    break;
                                                case "L Shape":
                                                case "J Shape":
                                                case "U Shape":
                                                case "S Shape":
                                                    product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable = true;
                                                    break;
                                            }
                                        }
                                        break;
                                }
                            }
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.spaceBetweenTracks.selected.isSelectable) {
                    product.items[itemIndex].configuration.spaceBetweenTracks.selected.value = 0;
                }
                break;
            case "overlapArms":
            case "underlapArms":
                product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = false;
                product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = false;
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Styleline Track":
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value)) {
                            switch (product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey) {
                                case "Hand Drawn":
                                    if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value)) {
                                        switch (product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey) {
                                            case "Left":
                                            case "Right":
                                                product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = false;
                                                product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = true;
                                                break;
                                            case "Centre Close":
                                                product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = true;
                                                product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = true;
                                                break;
                                            case "Centre Bunch":
                                                product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = true;
                                                product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = false;
                                                break;
                                            case "Freehand":
                                            default:
                                                product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = false;
                                                product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = false;
                                                break;
                                        }
                                    }
                                    break;
                                case "Cord Drawn":
                                    product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = false;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = false;
                                    break;
                            }
                        }
                        break;
                    case "S2000 Track":
                        if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value)) {
                            switch (product.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey) {
                                case "Hand Drawn":
                                    product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = true;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = false;
                                    break;
                                case "Cord Drawn":
                                    product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = false;
                                    product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = false;
                                    break;
                            }
                        }
                        break;
                    case "Motorised Track":
                        product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable = false;
                        product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable = false;
                        break;
                }

                if (!product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value = false;
                }
                if (!product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.isSelectable) {
                    product.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value = false;
                }
                break;
        }
        return product;
    }

    isMotorised(product, itemIndex, order) {
        let result = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
            switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                case "4-Core AC Motor":
                case "Remote AC Motor":
                case "Remote DC Battery Motor":
                    result = true;
                    break;
                case "No Motor":
                case "Manual":
                    result = false;
                    break;
            }
        }
        return result;
    }

    isOneWayStack(product, itemIndex, subItemIndex) {
        let result = true;
        if (subItemIndex > -1) {
            if (!isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value)) {
                switch (product.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey) {
                    case "Left":
                    case "Right":
                        result = true;
                        break;
                    case "Freehand":
                    case "Centre Close":
                    case "Centre Bunch":
                        result = false;
                        break;
                }
            }
        }
        return result;
    }

    hasBend(product, itemIndex) {
        let result = false;
        if (!isEmpty(product.items[itemIndex].configuration.bendShape.selected.value)) {
            switch (product.items[itemIndex].configuration.bendShape.selected.value.optionKey) {
                case "Straight":
                    result = false;
                    break;
                case "L Shape":
                case "J Shape":
                case "U Shape":
                case "S Shape":
                    result = true;
                    break;
            }
        }
        if (!result) {
            if (!isEmpty(product.items[itemIndex].configuration.numberOfBends.selected.value)) {
                result = parseInt(product.items[itemIndex].configuration.numberOfBends.selected.value.optionKey) > 0;
            }
        }
        return result;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | " + pg.items[itemIndex].itemType + " | ";

        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }

        /*if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }*/
        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.splitLength.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.splitLength.formError.message);
        }
        if (!pg.items[itemIndex].configuration.spaceBetweenTracks.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.spaceBetweenTracks.formError.message);
        }
        if (!pg.items[itemIndex].configuration.lengthA.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.lengthA.formError.message);
        }

        (pg.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
            if (!pg.items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.isValid) {
                errors.push(errorMessagePrefix + pg.items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.message);
            }
        });
        return errors;
    }

    isApplicable(product, itemIndex, subItemIndex, key, isEmptyCheckRequired) {
        let result = !(product.items[itemIndex].configuration.trackOnly.selected.value && PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents.includes(key));
        if (result && isEmptyCheckRequired) {
            if (subItemIndex > -1) {
                if (isEmpty(product.items[itemIndex].subItems[subItemIndex].configuration[key].selected.value)) {
                    result = false;
                }
            } else {
                if (isEmpty(product.items[itemIndex].configuration[key].selected.value)) {
                    result = false;
                }
            }
        }
        return result;
    }

    toSalesOrderItemCurtainTrackCustomRequest(pg, itemIndex, order, errors) {
        let errorMessagePrefix = "";


        let salesOrderItemCurtainTrackPartRequest = {
            ID: pg.items[itemIndex].ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            location: pg.items[itemIndex].configuration.location.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: 0,
            drop: this.isApplicable(pg, itemIndex, -1, "drop", false) ? pg.items[itemIndex].configuration.drop.selected.value : 0,
            lengthA: this.isApplicable(pg, itemIndex, -1, "lengthA", false) ? pg.items[itemIndex].configuration.lengthA.selected.value : 0,
            lengthB: this.isApplicable(pg, itemIndex, -1, "lengthB", false) ? pg.items[itemIndex].configuration.lengthB.selected.value : 0,
            lengthC: this.isApplicable(pg, itemIndex, -1, "lengthC", false) ? pg.items[itemIndex].configuration.lengthC.selected.value : 0,
            model: this.isApplicable(pg, itemIndex, -1, "model", true) ? pg.items[itemIndex].configuration.model.selected.value.optionKey : "",
            operation: this.isApplicable(pg, itemIndex, -1, "operation", true) ? pg.items[itemIndex].configuration.operation.selected.value.optionKey : "",
            trackType: this.isApplicable(pg, itemIndex, -1, "trackType", false) ? pg.items[itemIndex].configuration.trackType.selected.value.optionKey : "",
            trackColour: this.isApplicable(pg, itemIndex, -1, "trackColour", true) ? pg.items[itemIndex].configuration.trackColour.selected.value.optionKey : "",
            trackOnly: this.isApplicable(pg, itemIndex, -1, "trackOnly", false) ? pg.items[itemIndex].configuration.trackOnly.selected.value : false,
            bendType: this.isApplicable(pg, itemIndex, -1, "bendType", true) ? pg.items[itemIndex].configuration.bendType.selected.value.optionKey : "",
            bendShape: this.isApplicable(pg, itemIndex, -1, "bendShape", true) ? pg.items[itemIndex].configuration.bendShape.selected.value.optionKey : "",
            numberOfBends: this.isApplicable(pg, itemIndex, -1, "numberOfBends", true) ? parseInt(pg.items[itemIndex].configuration.numberOfBends.selected.value.optionKey) : 0,
            bendAttachment: this.isApplicable(pg, itemIndex, -1, "bendAttachment", false) ? pg.items[itemIndex].configuration.bendAttachment.selected.value : "",
            diagram: this.isApplicable(pg, itemIndex, -1, "bendShape", true) ? pg.items[itemIndex].configuration.bendShape.selected.value.description : "",
            split: this.isApplicable(pg, itemIndex, -1, "split", true) ? pg.items[itemIndex].configuration.split.selected.value.optionKey : "",
            splitLength: this.isApplicable(pg, itemIndex, -1, "splitLength", false) ? pg.items[itemIndex].configuration.splitLength.selected.value : 0,
            bracket: this.isApplicable(pg, itemIndex, -1, "bracket", true) ? pg.items[itemIndex].configuration.bracket.selected.value.optionKey : "",
            projection: this.isApplicable(pg, itemIndex, -1, "projection", true) ? pg.items[itemIndex].configuration.projection.selected.value.optionKey : "",
            returnOption: this.isApplicable(pg, itemIndex, -1, "returnOption", true) ? pg.items[itemIndex].configuration.returnOption.selected.value.optionKey : "",
            remote: this.isApplicable(pg, itemIndex, -1, "remote", false) ? pg.items[itemIndex].configuration.remote.selected.value : false,
            charger: this.isApplicable(pg, itemIndex, -1, "charger", false) ? pg.items[itemIndex].configuration.charger.selected.value : false,
            bridge: this.isApplicable(pg, itemIndex, -1, "bridge", false) ? pg.items[itemIndex].configuration.bridge.selected.value : false,
            spaceBetweenTracks: this.isApplicable(pg, itemIndex, -1, "spaceBetweenTracks", false) ? pg.items[itemIndex].configuration.spaceBetweenTracks.selected.value : 0,
            trackSequenceMax: this.isApplicable(pg, itemIndex, -1, "trackSequenceMax", false) ? pg.items[itemIndex].configuration.trackSequenceMax.selected.value : 0,
            bracketQty: this.isApplicable(pg, itemIndex, -1, "bracketQty", false) ? pg.items[itemIndex].configuration.productionCalculation.selected.bracketQty : 0,
            subItems: []
        };

        (pg.items[itemIndex].subItems || []).forEach((subItem, subItemIndex) => {
            if (!pg.items[itemIndex].subItems[subItemIndex].isValid) {
                if (!pg.items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.isValid) {
                    errors.push(errorMessagePrefix + pg.items[itemIndex].subItems[subItemIndex].configuration.cordSize.formError.message);
                }
                if (!pg.items[itemIndex].subItems[subItemIndex].configuration.width.formError.isValid) {
                    errors.push(errorMessagePrefix + pg.items[itemIndex].subItems[subItemIndex].configuration.width.formError.message);
                }
            }

            salesOrderItemCurtainTrackPartRequest.subItems[subItemIndex] = {
                id: null,
                salesOrderItemCurtainTrackCustomID: null,
                salesOrderItemPartParentID: null,
                salesOrderID: null,
                trackSequence: this.isApplicable(pg, itemIndex, subItemIndex, "trackSequence", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.trackSequence.selected.value : 0,
                trackPosition: this.isApplicable(pg, itemIndex, subItemIndex, "trackPosition", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.trackPosition.selected.value : "",
                width: this.isApplicable(pg, itemIndex, subItemIndex, "width", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value : 0,
                fabricType: this.isApplicable(pg, itemIndex, subItemIndex, "fabricType", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.fabricType.selected.value.optionKey : "",
                fabricColour: this.isApplicable(pg, itemIndex, subItemIndex, "fabricColour", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.fabricColour.selected.value.optionKey : "",
                control: this.isApplicable(pg, itemIndex, subItemIndex, "control", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.control.selected.value.optionKey : "",
                cordType: this.isApplicable(pg, itemIndex, subItemIndex, "cordType", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.cordType.selected.value.optionKey : "",
                cordSizeType: this.isApplicable(pg, itemIndex, subItemIndex, "cordSizeType", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.cordSizeType.selected.value.optionKey : "",
                cordSize: this.isApplicable(pg, itemIndex, subItemIndex, "cordSize", false) ? parseInt(pg.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value ? pg.items[itemIndex].subItems[subItemIndex].configuration.cordSize.selected.value : 0) : 0,
                cordLength: pg.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.cordLength ? pg.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.cordLength : 0,
                stacking: this.isApplicable(pg, itemIndex, subItemIndex, "stacking", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.stacking.selected.value.optionKey : "",
                overlapArms: this.isApplicable(pg, itemIndex, subItemIndex, "overlapArms", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.overlapArms.selected.value : false,
                underlapArms: this.isApplicable(pg, itemIndex, subItemIndex, "underlapArms", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.underlapArms.selected.value : false,
                trackDeductionWidth: this.isApplicable(pg, itemIndex, subItemIndex, "trackDeductionWidth", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.width.selected.value - pg.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth : 0,
                trackCutWidth: this.isApplicable(pg, itemIndex, subItemIndex, "trackCutWidth", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.trackCutWidth : 0,
                gliderQty: this.isApplicable(pg, itemIndex, subItemIndex, "gliderQty", false) ? pg.items[itemIndex].subItems[subItemIndex].configuration.productionCalculation.selected.gliderQty : 0,
                isOneWayStack: this.isApplicable(pg, itemIndex, subItemIndex, "isOneWayStack", false) ? this.isOneWayStack(pg, itemIndex, subItemIndex) : "",
                runner: this.isApplicable(pg, itemIndex, subItemIndex, "runner", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.runner.selected.value.optionKey : "",
                runnerColour: this.isApplicable(pg, itemIndex, subItemIndex, "runnerColour", true) ? pg.items[itemIndex].subItems[subItemIndex].configuration.runnerColour.selected.value.optionKey : "",

                stocks: []
            };
            if (subItemIndex === 0) {
                salesOrderItemCurtainTrackPartRequest.subItems[subItemIndex].stocks = pg.items[itemIndex].stocks;
            }
            salesOrderItemCurtainTrackPartRequest.subItems[subItemIndex].stocks = [...salesOrderItemCurtainTrackPartRequest.subItems[subItemIndex].stocks, ...pg.items[itemIndex].subItems[subItemIndex].stocks]
        });


        return salesOrderItemCurtainTrackPartRequest;
    }


}

export default CurtainTrackCustomUtil.Instance();