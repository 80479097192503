import React, {Component} from 'react';
import {Card, CardHeader, CardBody, Spinner, Table} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import retailService from "../../services/retail/RetailService";
import {handleErrorMessage} from "../../services/CommonService";
import {Link} from 'react-router-dom';
import NumberFormat from "react-number-format";
import DebtorRetailOtherChargesModal from '../modal/DebtorRetailOtherChargesModal';

export default class DebtorsRetailOtherCharges extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountID: this.props.accountID,
            record: {},
            isLoadingRecords: false,
            isOpenModal: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, () => this.refresh());
        }
    }

    refresh() {
        let {accountID} = this.state;
        if (accountID) {
            this.setState({isLoadingRecords: true});
            retailService.getDebtorsRetailPreference(accountID).then(response => {
                let record = response.data;
                this.setState({record, isLoadingRecords: false});
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({isLoadingRecords: false});
            });
        }
    }

    openModal() {
        this.setState({isOpenModal: true});
    }

    toggleModal(data) {
        this.setState({isOpenModal: data});
    }


    render() {
        let {isLoadingRecords, record, accountID, isOpenModal} = this.state;
        return (
            <Card>
                <CardHeader>
                    <h6 className={"mb-0"}>
                        Other Charges
                        <span className={"float-right"}>
                            <Link size="xs" onClick={this.openModal}>
                                <i className={"fa fa-pencil"}/>
                            </Link>
                        </span>
                    </h6>
                </CardHeader>
                {
                    isLoadingRecords
                        ? <CardBody><Spinner color={"primary"}/></CardBody>
                        : <Table size={"sm"} responsive={true} striped={true} hover={true} bordered className={"mb-0"}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th className={"text-right"}>Charges</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Check Measure</td>
                                <td className={"text-right"}><NumberFormat
                                    value={record.checkMeasureCharge}
                                    displayType={'text'}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    prefix={'$'}/></td>
                            </tr>
                            <tr>
                                <td>Installation Internal</td>
                                <td className={"text-right"}>
                                    <NumberFormat
                                        value={record.installationInternalCharge}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={'$'}/>
                                </td>
                            </tr>
                            <tr>
                                <td>Installation External</td>
                                <td className={"text-right"}><NumberFormat
                                    value={record.installationExternalCharge}
                                    displayType={'text'}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    prefix={'$'}/></td>
                            </tr>
                            <tr>
                                <td>Access Levy Height</td>
                                <td className={"text-right"}><NumberFormat
                                    value={record.accessLevyHeightCharge}
                                    displayType={'text'}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    prefix={'$'}/></td>
                            </tr>
                            </tbody>
                        </Table>
                }
                {isOpenModal ?
                    <DebtorRetailOtherChargesModal
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        accountID={accountID}
                        refreshAgain={this.refresh}
                    />
                    : null}
                <ToastContainer/>
            </Card>

        );
    }
}