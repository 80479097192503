import React, {Component} from 'react';
import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner
} from 'reactstrap';
import CRMService from "../../services/crm/CRMService";
import {getDateString, handleErrorMessage} from '../../services/CommonService';
import {toast} from 'react-toastify';
import Pagination from "react-js-pagination";
import {cloneDeep, isEmpty} from 'lodash';
import * as FileSaver from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';
import UserService from '../../services/UserService';

export default class Notes extends Component {
    constructor(props) {
        super(props);

        let accountId = props.accountId;
        this.state = {
            note: { 
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 10
                    },
                    filterRequest: {
                        notes: ''
                    }, 
                },
                response: {
                    records: [],
                    totalRecords: 0
                }
            },
            accountId: accountId,
            loading: true,
            notes: [],
            addnotes: '',
            formErrors: {},
            userdata: [],
            savingNote: false,
            searchText: '',
            downloading: false,
            hasDebtorWritePrivilege: false
        };
        this.userService = new UserService();
        this.crmService = new CRMService();
        this.handleAddNotes = this.handleAddNotes.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({accountId: nextProps.accountId}, function () {
                this.refresh();
            });
        }
    }

    componentDidMount() {
        this.refresh();
    }

    handleChange(change, key) {
        let { note } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break; 
            case "pageSize":
                note.request.pageRequest[key] = change;
                note.request.pageRequest.currentPage = 1;
                this.refresh();
                break;
            case "currentPage":
                note.request.pageRequest[key] = change;
                this.refresh();
                break; 
            default:
                note.request.filterRequest[key] = change;
                note.request.pageRequest.currentPage = 1;
                this.refresh();
                this.setState({ note });
        } 
    }

    handleAddNotes(e) {
        this.setState({addnotes: e.target.value});
    }

    validateForm() {
        let formErrors = {};
        formErrors.note = this.state.addnotes.trim().length > 0 ? "" : "Note is required";

        if (formErrors.note) {
            return {valid: false, formErrors: formErrors};
        }
        return {valid: true, formErrors: formErrors};
    }

    refresh() {
        let { request } = this.state.note; 
        this.userdata = JSON.parse(localStorage.getItem('user'));
        let currentUser = this.userService.getLoggedInUser();
        let hasDebtorWritePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-write');
        this.setState({ hasDebtorWritePrivilege });
        if (this.state.accountId) {
            this.crmService.getCustomerNotesInformation(this.state.accountId,request).then(response => {
                this.setState({ notes: response.data })
            }).catch(error => {
                console.log(error.data)
            });
        }

    }

    handleSave(e) {
        e.preventDefault();
        let result = this.validateForm();
        let username = this.userdata.username;
        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            this.setState({ savingNote: true, formErrors: {} });
            this.crmService.AddDebtors_Notes(this.state.accountId, this.state.addnotes, username).then(response => {
                toast.success("Note added.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({ savingNote: false });
                this.refresh();
                this.state.addnotes = '';
            }).catch(error => {
                this.setState({ savingNote: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    printNotesInExcel = () => {
        let request = cloneDeep(this.state.note.request);
        let accountId = this.state.accountId;
        this.setState({ downloading: true });
        this.crmService.generateCustomerNotesExcelReport(accountId, request).then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "CustomerNotes" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            toast.error(handleErrorMessage(error));
        });

    }

    render() {
        let { note, downloading, formErrors, notes, hasDebtorWritePrivilege } = this.state;
        let { filterRequest, pageRequest } = note.request;
        return (
            <div>
                <div>
                    <Row> 
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Input type="text" id="notes" name="notes"
                                className={"mb-2"}
                                value={filterRequest.notes}
                                onChange={(e) => this.handleChange(e.target.value, "notes")}
                                placeholder="Search Notes..." />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Button className={'mb-2 float-right'} size={"sm"} color={"success"} outline={true}
                                title={'Click here to export data'}
                                onClick={this.printNotesInExcel} disabled={downloading}>
                                {downloading
                                    ? <Spinner size="sm"
                                        className={"mr-2"}
                                        style={{ color: "green" }} />
                                    : <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />}
                            Export</Button>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Alert color="secondary">
                        <p><strong>Remarks</strong>: Confidential credit and admin information not revealed to
                            customer.</p>
                    </Alert>
                    {hasDebtorWritePrivilege ?
                    <Form onSubmit={this.handleSave}>
                        <FormGroup>
                            <InputGroup>
                                <Input type="textarea" name="addnotes" id="addnotes" invalid={formErrors.note!=null}
                                       onChange={event => this.handleAddNotes(event)}
                                    value={this.state.addnotes} placeholder="write customer specific notes here." />
                                <InputGroupAddon addonType="append">
                                    <Button type="submit" color="primary" disabled={this.state.savingNote}>
                                        {this.state.savingNote ? <Spinner size="sm"
                                            style={{ color: "white" }} /> : <i className="fa fa-plus"
                                                aria-hidden="true" />}&nbsp;Add Note</Button>
                                </InputGroupAddon>
                               
                            </InputGroup>
                            <FormText color="danger">{formErrors.note}</FormText>


                        </FormGroup>

                    </Form>
                    : null}

                    <ul style={{ padding: "unset" }}>
                        {(notes.records || []).map((item, index) => {
                            return (
                                <li key={index}>

                                    <div className={"lead-remark-list-item"}>
                                        <div className={"lead-remark-list-item-text"}>
                                            <div>
                                            <span className="text-muted">
                                        <span>
                                            <i className="fa fa-user" aria-hidden="true"/>
                                            &nbsp; {item.name}
                                        </span>
                                                    <small>&nbsp;
                                                    |&nbsp;{getDateString(item.dateAdded, 'DD/MM/YYYY hh:mm:ss A')}</small>
                                            </span>
                                            </div>
                                            <span>{item.customerNotes}</span></div>
                                    </div>
                                </li>
                            );
                        })}

                    </ul>
                </div>
                <Row> 
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}> 
                        <div className={"text-left"} style={{ maxWidth: 200 }}> 
                            <InputGroup> 
                                <InputGroupAddon addonType="prepend"> 
                                    <InputGroupText>Show</InputGroupText> 
                                </InputGroupAddon> 
                                <Input 
                                    type={"select"} 
                                    name={"pageSize"} 
                                    value={pageRequest.pageSize} 
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}> 
                                    <option value={10}>10 Rows</option> 
                                    <option value={25}>25 Rows</option> 
                                    <option value={50}>50 Rows</option> 
                                    <option value={100}>100 Rows</option> 
                                    <option value={500}>500 Rows</option> 
                                </Input> 
                            </InputGroup> 
                        </div> 
                    </Col>

                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>

                        <div className={"float-right"}> 
                            <Pagination 
                                activePage={pageRequest.currentPage} 
                                itemsCountPerPage={pageRequest.pageSize} 
                                totalItemsCount={notes.totalRecords} 
                                pageRangeDisplayed={3} 
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")} 
                                itemClass='page-item' 
                                linkClass='page-link' 
                                activeClass='active' 
                                innerClass='pagination' 
                                activeLinkClass='active' 
                            /> 
                        </div> 
                    </Col> 
                </Row>
            </div>
        );
    }

}
