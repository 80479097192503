import React, { Component } from 'react';
import { Badge, Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner, Table } from 'reactstrap';
import classnames from 'classnames';
import { changeFormatOfDateString, displayECD, getDateString, findIndex,handleErrorMessage } from './../../services/CommonService';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import NumberFormat from "react-number-format";
import { toast, ToastContainer } from 'react-toastify';
import FabricService from "../../services/FabricService";
import { Link } from "react-router-dom";
import FillStockTakeModal from "../../components/modal/FillStockTakeModal";
import StockTakeComponentSearchBanner from '../../components/StockTakeComponents/StockTakeComponentSearchBanner';

export default class StockTakeComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockTakeObj: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        fabricName: '',
                        prodCode: '',
                        category: '',
                        fabricType: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: false,
            isOpenFabricColorsModal: false,
            searchText: '',
            fabricDetails: {
                selectedFabricId: 0,
                fabricName: '',
                fabricType: ''
            },
        };

        this.refresh = this.refresh.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.toggleFabricColorsModal = this.toggleFabricColorsModal.bind(this);
        this.openStockTakeModal = this.openStockTakeModal.bind(this);
        this.searchFunction = this.searchFunction.bind(this);
        this.handleStockChange = this.handleStockChange.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.getFabrics(this.state.stockTakeObj);
    }

    toggleFabricColorsModal(change) {
        this.setState({ isOpenFabricColorsModal: change });
    }

    getFabrics(stockTakeObj) {
        this.setState({ loading: true });
        let request = cloneDeep(stockTakeObj.request);
        this.setState({ loading: true });
        FabricService.fetchFabricsForStackTake(request).then(response => {
            if (response.data) {
                stockTakeObj.response = response.data;
                this.setState({ stockTakeObj, loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getColValue(value, storeItem, Obj, index) {
        switch (storeItem.type) {
            case "link":
                return <span><Link
                    onClick={() => this.onObjClick(value)}
                    className={classnames("btn", "btn-sm", {
                        "btn-primary": (this.state.highlightedObj.ordNum !== Obj.ordNum),
                        "btn-success": (this.state.highlightedObj.ordNum === Obj.ordNum)
                    })}
                    style={{ color: "white" }}
                    title={"Click here to view Obj in detail"}
                    to={"/sales/Obj/view?" + queryString.stringify({ OrdNum: value })}>
                    {value}
                </Link></span>;
            case "action":
                return <div className="text-center">
                    <Button size={"sm"} color="primary" title={"Click to fill stock-take"}
                        onClick={() => this.openStockTakeModal(Obj)}>
                        <i className="fa fa-file-o mr-2" aria-hidden="true" />
                        Manage
                    </Button>
                </div>
            case "date":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return <span><NumberFormat value={Math.floor(value)}
                    displayType={'text'}
                    decimalScale={2}
                    thousandSeparator={true} fixedDecimalScale={true}
                    prefix={'$'} /></span>;
            case "number":
                return <span><NumberFormat value={value}
                    displayType={'text'}
                    thousandSeparator={true} /></span>;
            case "delCompany":
                return <small>{value}</small>;
            case "checkbox":
                return <div className={"text-center"}>
                    <a href="javascript:void(0)" onClick={() => this.selectRecord(value, Obj.ordNum)}>
                        {value ?
                            <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                            <i className="fa fa-lg fa-square-o" aria-hidden="true" />}
                    </a></div>;
            case "ECD":
                return displayECD(value, "DD MMM YYYY");
            default:
                return <span>{value}</span>
        }
    }

    openStockTakeModal(fabricObj) {
        if (fabricObj) {
            let { fabricDetails } = this.state;
            fabricDetails.selectedFabricId = fabricObj.id;
            fabricDetails.fabricName = fabricObj.fabricName;
            fabricDetails.fabricType = fabricObj.fabricType;
            this.setState({ fabricDetails }, () => this.toggleFabricColorsModal(true));
        }
    }

    handleChange(change, key) {
        let { stockTakeObj } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (stockTakeObj.request.sortRequest.key === change) {
                    stockTakeObj.request.sortRequest.direction = !stockTakeObj.request.sortRequest.direction;
                } else {
                    stockTakeObj.request.sortRequest.key = change;
                    stockTakeObj.request.sortRequest.direction = false;
                }
                this.getFabrics(stockTakeObj);
                break;
            case "pageSize":
                stockTakeObj.request.pageRequest[key] = change;
                this.getFabrics(stockTakeObj);
                break;
            case "currentPage":
                stockTakeObj.request.pageRequest[key] = change;
                this.getFabrics(stockTakeObj);
                break;
            default:
                stockTakeObj.request.filterRequest[key] = change;
                stockTakeObj.request.pageRequest.currentPage = 1;
                this.setState({ stockTakeObj });
                this.getFabrics(stockTakeObj);
        }
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.fabricName.toLowerCase().includes(searchText);

            if (!flag && item.fabricType) {
                flag = item.fabricType.toLowerCase().includes(searchText)
            }
            if (!flag && item.category) {
                flag = item.category.toLowerCase().includes(searchText)
            }

        }
        return flag;
    }

    getStore({ filterRequest }) {
        let type = this.props.type;
        return [
            {
                key: "selected",
                label: <div className={"text-center"}><a href="javascript:void(0)" onClick={this.selectAllRecords}>
                    {this.state.allRecordsSelected ?
                        <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                        <i className="fa fa-lg fa-square-o" aria-hidden="true" />
                    }
                </a></div>,
                type: "checkbox",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "fabricName",
                label: "Fabric",
                type: "default",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "fabricType",
                label: "Fabric Type",
                type: "default",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "category",
                label: "Category",
                type: "default",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                extendedColumn: false,
                searchNode: null
            }
        ];
    }

    handleStockChange(selectedStock) {
        if (selectedStock.prodCode) {
            let { stockTakeObj } = this.state;
            stockTakeObj.request.filterRequest.prodCode = selectedStock.prodCode;
            stockTakeObj.request.pageRequest.currentPage = 1;
            this.setState({ stockTakeObj }, () => this.getFabrics(stockTakeObj));
        }
    }

    render() {
        let { stockTakeObj, loading, searchText, isOpenFabricColorsModal, fabricDetails } = this.state;
        let { pageRequest, sortRequest, filterRequest } = stockTakeObj.request;
        let store = this.getStore(stockTakeObj.request);
        let type = this.props.type;
        let totalOrdervalue = 0;

        return (
            <div>
                <div className={"mb-2"}>
                    <StockTakeComponentSearchBanner
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                        handleStockChange={this.handleStockChange}
                    />
                </div>
                <Row className={"align-items-center"}>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading ?
                                <span>
                                    <Spinner size={"sm"} color={"primary"} />
                                </span>
                                :
                                <span>
                                    Showing{' '}
                                    {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {stockTakeObj.response.totalRecords}
                                    {' '}entries
                                </span>
                            }
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <InputGroup className={"mb-2"}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-search"
                                            aria-hidden="true" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"text"}
                                    name={"searchText"}
                                    placeholder={"Search..."}
                                    value={searchText}
                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                />
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                <div>
                    <Table hover bordered size={"sm"} striped responsive>
                        <thead>
                            <tr>
                                {(store || []).filter(item => item.showColumn ? true : false).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    })
                                                    } aria-hidden="true" /> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {(stockTakeObj.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, i) => {
                                totalOrdervalue = totalOrdervalue + order.ordTotal;
                                return (
                                    <tr key={i}>
                                        {(store || []).filter(item => item.showColumn ? true : false).map((storeItem, index) => {
                                            return (
                                                <td key={index} className={storeItem.valueClassName}>
                                                    {this.getColValue(order[storeItem.key], storeItem, order, i)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>


                    {!loading ?
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockTakeObj.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                        :
                        null
                    }

                    {isOpenFabricColorsModal ?
                        <FillStockTakeModal
                            isOpen={isOpenFabricColorsModal}
                            fabricDetails={fabricDetails}
                            toggle={this.toggleFabricColorsModal}
                        />
                        : null
                    }
                </div>
                <ToastContainer />
            </div>
        );
    }
}