import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Row, Table
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Pagination from "react-js-pagination";
import { getDateString, handleErrorMessage } from "../../services/CommonService";
import StockService from "../../services/StockService";
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { maxDate, minDate } from './../OrderGatewayComponents/OrderGatewayCommon';
import SearchStock from "../search/SearchStock";

const date = new Date();

export default class StockFabricTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockFabricTransactions: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "createdDate",
                        direction: false
                    },
                    filterRequest: {
                        prodCode: '',
                        stockTransactionType: 0,
                        ordNum: 0,
                        dateStart: moment(date).subtract(90, 'day').toDate(),
                        dateEnd: date,

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            transTypes: [],
        };

        this.stockService = new StockService();
    }

    componentDidMount() {
        let { stockFabricTransactions } = this.state;
        this.getStockFabricTransaction(stockFabricTransactions);

    }

    getStockFabricTransaction(stockFabricTransactions) {
        this.setState({ loading: true });
        let request = cloneDeep(stockFabricTransactions.request);
        if (!request.filterRequest.ordNum) {
            request.filterRequest.ordNum = 0;
        }
        if (request.filterRequest.dateStart) {
            request.filterRequest.dateStart = getDateString(request.filterRequest.dateStart, "DD/MM/YYYY");
        }
        if (request.filterRequest.dateEnd) {
            request.filterRequest.dateEnd = getDateString(request.filterRequest.dateEnd, "DD/MM/YYYY");
        }

        this.stockService.getStockFabricTransactions(request).then(response => {
            stockFabricTransactions.response = response.data;
            this.setState({ stockFabricTransactions, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.name) {
                flag = item.name.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let { stockFabricTransactions } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (stockFabricTransactions.request.sortRequest.key === change) {
                    stockFabricTransactions.request.sortRequest.direction = !stockFabricTransactions.request.sortRequest.direction;
                } else {
                    stockFabricTransactions.request.sortRequest.key = change;
                    stockFabricTransactions.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockFabricTransaction(stockFabricTransactions);
                }
                break;
            case "pageSize":
                stockFabricTransactions.request.pageRequest[key] = parseInt(change);
                stockFabricTransactions.request.pageRequest.currentPage = 1;
                this.getStockFabricTransaction(stockFabricTransactions);
                break;
            case "currentPage":
                stockFabricTransactions.request.pageRequest[key] = change;
                this.getStockFabricTransaction(stockFabricTransactions);
                break;


            default:
                if (key === 'prodCode') {
                    if (stockFabricTransactions.request.filterRequest[key] !== change) {
                        stockFabricTransactions.request.filterRequest[key] = change;
                        stockFabricTransactions.request.pageRequest.currentPage = 1;
                        this.getStockFabricTransaction(stockFabricTransactions);
                        this.setState({ stockFabricTransactions: stockFabricTransactions });
                    }
                }
                else {
                    stockFabricTransactions.request.filterRequest[key] = change;
                    stockFabricTransactions.request.pageRequest.currentPage = 1;
                    this.getStockFabricTransaction(stockFabricTransactions);
                    this.setState({ stockFabricTransactions: stockFabricTransactions });
                }
        }

    }

    getStockFabricTransactionHeading({ filterRequest }) {
        let stockTransactionHeadings = [
            {
                key: "srNo",
                label: "Sr. No.",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,

            },
            {
                key: "prodCode",
                label: "Stock Code",
                type: "text",
                colSpan: 1,
                maxWidth: 150,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 2,
                searchNode: <div>
                    <SearchStock
                        handleSelection={(selectedStock) => this.handleChange(selectedStock ? selectedStock.prodCode : "", "prodCode")}
                        selected={filterRequest.prodCode}
                        defaultProdCode={filterRequest.prodCode}
                        filter={{ bmFlag: null, discontinued: null, soldOut: null, stockGroups: [] }}
                    />
                </div>

            },
            {
                key: "stockDescription",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null,
                searchNodeColSpan: 0,
            },
            {
                key: "stockTransactionType",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="select"
                    name="stockTransactionType"
                    value={filterRequest.stockTransactionType}
                    onChange={(e) => this.handleChange(e.target.value, "stockTransactionType")}
                    placeholder="Search..." >
                    <option value=''>All</option>
                    <option value='CUTJOB'>Cutjob</option>
                    <option value='ADJUSTMENT'>Adjustment</option>
                    <option value='RECEIPT'>Receipt</option>
                </Input>
                </div>

            },
            {
                key: "ordNum",
                label: "Ref. No.",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="number" id="ordNum" name="ordNum"
                    value={filterRequest.ordNum || ''}
                    onChange={(e) => this.handleChange(e.target.value, "ordNum")}
                    placeholder="Search..." /></div>
            },
            {
                key: "qty",
                label: "Quantity",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                searchNodeColSpan: 1,
                labelClassName: "hoverableItem align-middle"
            },

            {
                key: "createdDate",
                label: "Date",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <div className={"text-right mb-1"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.dateStart}
                            onChange={date => this.handleChange(date, "dateStart")}
                            selectsStart
                            startDate={filterRequest.dateStart}
                            endDate={filterRequest.dateEnd}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="Start date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                    <div className={"text-right"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.dateEnd}
                            onChange={date => this.handleChange(date, "dateEnd")}
                            selectsEnd
                            startDate={filterRequest.dateStart}
                            endDate={filterRequest.dateEnd}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="End date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>

                </div>
            }
        ];

        return stockTransactionHeadings;
    }

    render() {
        let { stockFabricTransactions, loading, searchText } = this.state;
        let { pageRequest, sortRequest } = stockFabricTransactions.request;
        let stockTransactionHeadings = this.getStockFabricTransactionHeading(stockFabricTransactions.request);
        return (
            <div>

                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading ? <p>Loading...</p> :
                                <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {stockFabricTransactions.response.totalRecords}
                                    {' '}entries</p>
                            }
                        </div>
                    </Col>

                </Row>
                <div>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                            <tr>
                                {(stockTransactionHeadings || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth, maxWidth: item.maxWidth  }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(stockTransactionHeadings || []).map((item, index) => {
                                    if (item.searchNodeColSpan > 0) {
                                        return (
                                            <td key={index} colSpan={item.searchNodeColSpan}
                                                className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    }
                                    return null;
                                })}
                            </tr>

                        </thead>

                        <tbody>
                            {(stockFabricTransactions.response.records || []).filter((stockTransaction) => this.searchFunction(stockTransaction, searchText)).map((stockTransaction, index) =>

                                <tr key={index}>
                                    <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                    <td className='text-center'><Link
                                        className="btn btn-sm btn-primary"
                                        style={{ color: "white" }}
                                        title={"Click here to see stock details"}
                                        to={"/inventory/stock/enquiry?prodCode=" + stockTransaction.prodCode}>
                                        {stockTransaction.prodCode}</Link></td>
                                    <td>{stockTransaction.stockDescription}</td>
                                    <td>{stockTransaction.stockTransactionType}</td>
                                    <td>{stockTransaction.ordNum}</td>
                                    <td className={"text-right"}>{stockTransaction.qty}</td>
                                    <td>{getDateString(stockTransaction.createdDate, 'DD MMM YYYY')}</td>
                                </tr>
                            )}

                        </tbody>
                    </Table>
                </div>
                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{ maxWidth: 200 }}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>


                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={stockFabricTransactions.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>
                <ToastContainer />
            </div>
        );
    }
}