import React, {Component} from 'react';
import {Spinner} from 'reactstrap';
import {cloneDeep, isEmpty} from 'lodash';
import {productConstant} from '../../../../../store/ProductGroups'
import RollerBlindSingle from '../../../../../components/sales/create/productBuilderKeywayVersion/products/RollerBlindSingle';
import HospitalRange from '../../../../../components/sales/create/productBuilderKeywayVersion/products/hospitalRange/HospitalRange';
import CurtainTrack from '../../../../../components/sales/create/productBuilderKeywayVersion/products/curtainTracks/CurtainTrack';
import RollerBlindDouble from '../../../../../components/sales/create/productBuilderKeywayVersion/products/RollerBlindDouble';
import ZiptrakExternal from '../../../../../components/sales/create/productBuilderKeywayVersion/products/ziptrak/external/ZiptrakExternal'; //ReactComponent
import FoldingArmAwning from '../../../../../components/sales/create/productBuilderKeywayVersion/products/FoldingArmAwning'; //ReactComponent
import VerticalIndoor from '../../../../../components/sales/create/productBuilderKeywayVersion/products/VerticalIndoor'; //ReactComponent
import ZiptrakInternal from "../../../../../components/sales/create/productBuilderKeywayVersion/products/ziptrak/internal/ZiptrakInternal";
import Widescreen from "../../../../../components/sales/create/productBuilderKeywayVersion/products/widescreen/Widescreen";
import Venetian from "../../../../../components/sales/create/productBuilderKeywayVersion/products/venetian/Venetian";
import VerticalOutdoor from "../../../../../components/sales/create/productBuilderKeywayVersion/products/verticalOutdoor/VerticalOutdoor";
import PanelTrack from "../../../../../components/sales/create/productBuilderKeywayVersion/products/panelTrack/PanelTrack";
import RomanBlind from "../../../../../components/sales/create/productBuilderKeywayVersion/products/romanBlind/RomanBlind";
import ProjectionAwning from "../../../../../components/sales/create/productBuilderKeywayVersion/products/projectionAwning/ProjectionAwning";
import SkinOnly from "../../../../../components/sales/create/productBuilderKeywayVersion/products/skinOnly/SkinOnly";
import Custom from "../../../../../components/sales/create/productBuilderKeywayVersion/products/custom/Custom";
import Quiklok from "../../../../../components/sales/create/productBuilderKeywayVersion/products/quiklok/Quiklok";


export default class SalesOrderProductBuilderNavigationProduct extends Component {

    render() {
        let {order, user, discountByProductCode, packagingAndHandling, product, products, handleOrderChange, productMarkupByProductCode} = this.props;


        if (product && product.isLoadingBuilder && !product.builder) {
            return <Spinner color={"primary"}/>;
        }

        switch (true) {
            case productConstant.ROLLER_BLINDS_SINGLE.code === product.code:
                return <RollerBlindSingle
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case  productConstant.ROLLER_BLINDS_DOUBLE.code === product.code:
                return <RollerBlindDouble
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.VERTICAL_INDOOR.code === product.code:
                return <VerticalIndoor
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.FOLDING_ARM_AWNINGS.code === product.code:
                return <FoldingArmAwning
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.HOSPITAL_RANGE.code === product.code:
                return <HospitalRange
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.CURTAIN_TRACKS.code === product.code:
                return <CurtainTrack
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.ZIPTRAK_EXTERNAL.code === product.code:
                return <ZiptrakExternal
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.ZIPTRAK_INTERNAL.code === product.code:
                return <ZiptrakInternal
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.WIDESCREEN.code === product.code:
                return <Widescreen
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.VENETIANS.code === product.code:
                return <Venetian
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.VERTICAL_OUTDOOR.code === product.code:
                return <VerticalOutdoor
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.PANEL_TRACKS.code === product.code:
                return <PanelTrack
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.ROMAN_BLINDS.code === product.code:
                return <RomanBlind
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.PROJECTION_AWNINGS.code === product.code:
                return <ProjectionAwning
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.SKIN_ONLY.code === product.code:
                return <SkinOnly
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.CUSTOM.code === product.code:
                return <Custom
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.SHP_CTS.code === product.code:
                return <Quiklok
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
        }

        return null;
    }
}