import React, {Component} from 'react';
import FileSaver from 'file-saver';
import {Button, Card, CardBody, Progress, Spinner, Table, Row, Col} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import catalogueService from "../../services/CatalogueService";
import { findIndex, getSorted, handleErrorMessage } from "../../services/CommonService";
import { Link } from "react-router-dom";

const recordType = "productOrderForms";
export default class ProductOrderForms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            isLoadingRecords: false
        };
        this.onDocumentClickSuccess = this.onDocumentClickSuccess.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    onDocumentClickSuccess(item) {
        let {records} = this.state;
        let index = findIndex(records, 'id', item.id);
        records[index].isDownloading = true;
        this.setState({records});

        const options = {
            onDownloadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    records[index].requestProgressPercentage = percent;
                    this.setState({records});
                }
            }
        };

        records[index].requestProgressPercentage = 0;
        this.setState({records});
        catalogueService.downloadAttachment(item.fileName, options, recordType).then(response => {
            FileSaver.saveAs(response.data, item.description);
            records[index].isDownloading = false;
            records[index].requestProgressPercentage = 0;
            this.setState({records});
        }).catch(error => {
            console.log(error);
            records[index].isDownloading = false;
            records[index].requestProgressPercentage = 0;
            this.setState({records});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    refresh() {
        this.setState({isLoadingRecords: true});
        catalogueService.list(recordType).then(response => {
            let records = getSorted(response.data, 'title', true);
            this.setState({records, isLoadingRecords: false});
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({isLoadingRecords: false});
        });
    }


    render() {
        let {isLoadingRecords, records} = this.state;
        return (
            <div>
                {/*<Card className={"mb-2"}>*/}
                {/*    <CardBody>*/}
                {/*        <Row>*/}
                {/*            <Col xl={10} lg={10} md={10} sm={12} xs={12}>*/}
                {/*                <h5>Warranty Claim Form</h5>*/}
                {/*                <ol>*/}
                {/*                    <li>Only one claim per order will be processed.</li>*/}
                {/*                    <li> Picture of the claim is mandatory for us to evaluate.</li>*/}
                {/*                    <li>It is in our right to disapprove any cliam request that is found incorrect or fraud.</li>*/}
                {/*                </ol> */}
                {/*            </Col>*/}
                {/*            <Col xl={2} lg={2} md={2} sm={12} xs={12}>*/}
                {/*                <Link className="btn btn-primary btn-sm"*/}
                {/*                    style={{ color: "white" }}*/}
                {/*                    to={"/rework/form/customer"}*/}
                {/*                    title={"Click Here to Fill the Form"}>*/}
                {/*                    Claim For the Warranty <i class="fa fa-arrow-right ml-3" aria-hidden="true"></i>*/}
                {/*                </Link> */}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </CardBody>*/}
                {/*</Card>*/}
                <Card>
                    <CardBody> 
                        <h5>Order Forms</h5>
                        {
                            isLoadingRecords
                                ? <Spinner color={"primary"}/>
                                : <Table size={"sm"} striped={true} hover={true} bordered>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className={"text-center"} style={{width: 200}}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(records || []).map((product, index) =>

                                        <tr>
                                            <td>{product.title}</td>
                                            <td className={"text-center"}>
                                                <div>
                                                    <Button
                                                        onClick={() => this.onDocumentClickSuccess(product)}
                                                        color={"primary"}
                                                        size={"sm"}
                                                        disabled={product.isDownloading}

                                                    >
                                                        {product.isDownloading
                                                            ? <Spinner size="sm"
                                                                       className={"mr-2"}
                                                                       style={{color: "white"}}/>
                                                            : <i className="fa fa-arrow-circle-down mr-2"
                                                                 aria-hidden="true"/>}
                                                        {product.isDownloading
                                                            ? "Downloading"
                                                            : "Download"}
                                                    </Button>
                                                    &nbsp;
                                                    {product.requestProgressPercentage > 0
                                                        ? <Progress striped color="primary"
                                                                    value={product.requestProgressPercentage}
                                                                    className={"mt-2"}/>
                                                        : null
                                                    }
                                                </div>

                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                        }
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}