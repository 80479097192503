import React, { Component } from 'react';
import NumberFormat from "react-number-format";

export let verticalIndoorLabelStore = [
    {
        key: "bmNumber",
        label: "BM#",
        colSpan: 1,
        minWidth: 60,
        sorterApplicable: true,
        valueClassName: "align-middle text-center",
        labelClassName: "align-middle text-center",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 50,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "quantity",
        label: <div>
            Qty
            <div>(unit)</div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "width",
        label: <div>
            Width
            <div>
                (mm)
            </div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "drop",
        label: <div>
            Drop
            <div>
                (mm)
            </div>
        </div>,
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "cutSize",
        label: "Cut Size",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            var size = data.productLabel.width + " x " + data.productLabel.drop;
            return size;
        }
    },
    {
        key: "bladeSize",
        label: "Blade Size",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "carrierQty",
        label: "Carrier Qty",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={0}
                thousandSeparator={true}
            />;
        }
    },
    {
        key: "fabricType",
        label: "Fabric",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel.fabricType + " " + data.productLabel.fabricColour;
        }
    }, {
        key: "fabricDimension",
        label: "Fabric Size",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            let result = [];
            if (data.productLabel.fabricHeight) {
                result.push("Height:" + data.productLabel.fabricHeight);
            }
            if (data.productLabel.fabricCutHeight) {
                result.push("Cut Height:" + data.productLabel.fabricCutHeight);
            }
            if (data.productLabel.fabricQty) {
                result.push("Qty:" + data.productLabel.fabricQty);
            }
            result = result.toString();
            result = result.replaceAll(',', ', ');
            return result;
        }
    },

    {
        key: "magnetQty",
        label: "Magnets",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },


    {
        key: "trackLength",
        label: "Track Length (mm)",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    }, {
        key: "tiltRodLength",
        label: "Tilt Rod Length (mm)",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return <NumberFormat
                value={data.productLabel[this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "wand",
        label: "Wand",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel.wand + " x " + data.productLabel.wandQty;
        }
    },


    {
        key: "headRailColour",
        label: "Headrail Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "stackingDirection",
        label: "Stacking Type",
        colSpan: 1,
        minWidth: 50,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "bottomWeightType",
        label: "Bottom Weight",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "mount",
        label: "Mount Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "distanceTube",
        label: "Distance Tube",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            let result = [];
            if (data.productLabel.distanceTube6mmQty) {
                result.push("6mm x " + data.productLabel.distanceTube6mmQty);
            }
            if (data.productLabel.distanceTube25mmQty) {
                result.push("25mm x " + data.productLabel.distanceTube25mmQty);
            }
            if (data.productLabel.distanceTube40mmQty) {
                result.push("40mm x " + data.productLabel.distanceTube40mmQty);
            }
            result = result.toString();
            result = result.replaceAll(',', ', ');
            return result;
        }
    },
    {
        key: "room",
        label: "Room",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }
];