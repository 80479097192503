import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Spinner
} from 'reactstrap';

export default class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting:this.props.deleting,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleting !== this.state.deleting) {
            this.setState({ deleting: nextProps.deleting });
        }
    }

    render() {
        let { isOpen, toggle, header, handleDelete } = this.props;
        let { deleting } = this.state;

        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {header}
                </ModalHeader>
                <ModalBody>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={handleDelete} disabled={deleting}>
                            {deleting
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            Delete</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button>

                    </div>

                </ModalBody>

            </Modal>
        )
    }
}