import React from 'react';
import {productConstant} from '../../../../../../store/ProductGroups';
import {isEmpty, cloneDeep} from 'lodash';
import {round} from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {pricingMatrixVariant} from "../../../../../../store/sales/order/productBuilder/PricingMatrixConstants";
import {BUNNINGS_ACCOUNT_ID} from "../../../../../../store/AppConstants";

class SkinOnlyUtil {

    static Instance() {
        return new SkinOnlyUtil();
    }

    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }

                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if (product.items[itemIndex].configuration.width.selected.isSelectable) {
                    if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                        };
                    }
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if (product.items[itemIndex].configuration.drop.selected.isSelectable) {
                    if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                        };
                    }
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "previousOrdNum":
                if (product.items[itemIndex].configuration.previousOrdNum.selected.isSelectable && !product.items[itemIndex].configuration.previousOrdNum.selected.value) {
                    formError = {
                        isValid: false,
                        message: "Previous Order No can't be empty",
                    };
                }
                product.items[itemIndex].configuration.previousOrdNum.formError = formError;
                break;
            case "fabricColourOffRange":
                /* if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.fabricColourOffRange.selected.value)) {
                     formError = {
                         isValid: false,
                         message: "Fabric name can't be empty",
                     };
                 }*/
                product.items[itemIndex].configuration.fabricColourOffRange.formError = formError;
                break;
            case "bottomPocketTubeDiameter":
                if (product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.isSelectable && !product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.value) {
                    formError = {
                        isValid: false,
                        message: (product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey === "Pocket Only" ? "Pocket Size" : "Bottom Pocket Tube Diameter") + " can't be empty",
                    };
                }
                product.items[itemIndex].configuration.bottomPocketTubeDiameter.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.previousOrdNum.formError.isValid
            && product.items[itemIndex].configuration.fabricColourOffRange.formError.isValid
            && product.items[itemIndex].configuration.bottomPocketTubeDiameter.formError.isValid;
    }

    updateRules(key, product, itemIndex, order) {
        let optionIndex, condition1, condition2, qty, fabricType;

        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.isSelectable = true;

                condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                switch (condition1) {
                    case "ZIPE":
                        switch (condition2) {
                            case "Ziptrak Outdoor":
                                product.items[itemIndex].configuration.width.min = 625;
                                product.items[itemIndex].configuration.width.max = 6000;
                                break;
                        }
                        break;
                    case "WID":
                        switch (condition2) {
                            case "Zip System":
                                product.items[itemIndex].configuration.width.min = 800;
                                product.items[itemIndex].configuration.width.max = 6500;
                                break;
                            case "Cable System":
                                product.items[itemIndex].configuration.width.min = 800;
                                product.items[itemIndex].configuration.width.max = 4000;
                                break;
                        }
                        break;
                    case "VOD":
                        switch (condition2) {
                            case "Straight Drop Awning (TVD)":
                                product.items[itemIndex].configuration.width.min = 900;
                                product.items[itemIndex].configuration.width.max = 4860;
                                break;
                            case "Retractable":
                            case "Multi-Stop Channel Awning":
                                product.items[itemIndex].configuration.width.min = 900;
                                product.items[itemIndex].configuration.width.max = 3600;
                                break;
                        }
                        break;
                    case "FAA":
                        switch (condition2) {
                            case "Monobloc 505":
                            case "Monobloc 520":
                            case "Semi Cassette":
                            case "Full Cassette":
                                product.items[itemIndex].configuration.width.min = 2100;
                                product.items[itemIndex].configuration.width.max = 8500;
                                break;
                        }
                        break;
                    case "VIN":
                        switch (condition2) {
                            case "Order by Blind Dimension":
                                product.items[itemIndex].configuration.width.min = 0;
                                product.items[itemIndex].configuration.width.max = 10000;
                                break;
                            case "Order by Blade Qty":
                                product.items[itemIndex].configuration.width.selected.isSelectable = false;
                                product.items[itemIndex].configuration.width.selected.value = product.items[itemIndex].configuration.quantity.selected.value;
                                product.items[itemIndex].configuration.width.min = 0;
                                product.items[itemIndex].configuration.width.max = 0;
                                break;
                        }
                        break;
                    case "PRJ":
                        switch (condition2) {
                            case "Fixed Pivot Convertible Awning":
                                product.items[itemIndex].configuration.width.min = 1200;
                                product.items[itemIndex].configuration.width.max = 3960;
                                break;
                            case "Automatic Awning":
                                product.items[itemIndex].configuration.width.min = 900;
                                product.items[itemIndex].configuration.width.max = 3600;
                                break;
                            case "Fixed Guide Awning":
                                product.items[itemIndex].configuration.width.min = 900;
                                product.items[itemIndex].configuration.width.max = 4290;
                                break;
                            case "Drop Arm Awning":
                                product.items[itemIndex].configuration.width.min = 1000;
                                product.items[itemIndex].configuration.width.max = 4860;
                                break;
                        }
                        break;
                    case "ROLS":
                        product.items[itemIndex].configuration.width.min = 200;
                        product.items[itemIndex].configuration.width.max = 3000;
                        break;
                    case "PTR":
                        product.items[itemIndex].configuration.width.min = 200;
                        product.items[itemIndex].configuration.width.max = 1000;
                        break;
                }
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.isSelectable = true;
                condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;

                switch (condition1) {
                    case "ZIPE":
                        switch (condition2) {
                            case "Ziptrak Outdoor":
                                product.items[itemIndex].configuration.drop.min = 500;
                                product.items[itemIndex].configuration.drop.max = 3600;
                                break;
                        }
                        break;
                    case "WID":
                        switch (condition2) {
                            case "Zip System":
                            case "Cable System":
                                product.items[itemIndex].configuration.drop.min = 900;
                                product.items[itemIndex].configuration.drop.max = 3400;
                                break;
                        }
                        break;
                    case "VOD":
                        switch (condition2) {
                            case "Straight Drop Awning (TVD)":
                            case "Retractable":
                            case "Multi-Stop Channel Awning":
                                product.items[itemIndex].configuration.drop.min = 1000;
                                product.items[itemIndex].configuration.drop.max = 3500;
                                break;
                        }
                        break;
                    case "FAA":
                        switch (condition2) {
                            case "Monobloc 505":
                            case "Monobloc 520":
                            case "Semi Cassette":
                            case "Full Cassette":
                                product.items[itemIndex].configuration.drop.min = 1000;
                                product.items[itemIndex].configuration.drop.max = 4500;
                                break;
                        }
                        break;
                    case "VIN":
                        switch (condition2) {
                            case "Order by Blind Dimension":
                            case "Order by Blade Qty":
                                product.items[itemIndex].configuration.drop.min = 250;
                                product.items[itemIndex].configuration.drop.max = 3820;
                                break;
                        }
                        break;
                    case "PRJ":
                        switch (condition2) {
                            case "Fixed Pivot Convertible Awning":
                                product.items[itemIndex].configuration.drop.min = 900;
                                product.items[itemIndex].configuration.drop.max = 2400;
                                break;
                            case "Automatic Awning":
                                product.items[itemIndex].configuration.drop.min = 1000;
                                product.items[itemIndex].configuration.drop.max = 2700;
                                break;
                            case "Fixed Guide Awning":
                                product.items[itemIndex].configuration.drop.min = 1200;
                                product.items[itemIndex].configuration.drop.max = 2700;
                                break;
                            case "Drop Arm Awning":
                                product.items[itemIndex].configuration.drop.min = 1200;
                                product.items[itemIndex].configuration.drop.max = 3000;
                                break;
                        }
                        break;
                    case "ROLS":
                    case "PTR":
                        product.items[itemIndex].configuration.drop.min = 400;
                        product.items[itemIndex].configuration.drop.max = 3000;
                        break;
                }
                break;
            case "previousOrdNum":
                product.items[itemIndex].configuration.previousOrdNum.selected.isSelectable = false;

                if (!isEmpty(product.items[itemIndex].configuration.purpose.selected.value)) {
                    switch (product.items[itemIndex].configuration.purpose.selected.value.optionKey) {
                        case "New Order":
                            break;
                        case "Replacement":
                        case "Rework":
                            product.items[itemIndex].configuration.previousOrdNum.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.previousOrdNum.selected.isSelectable) {
                    product.items[itemIndex].configuration.previousOrdNum.selected.value = null;
                    product.items[itemIndex].configuration.previousOrdNum.selected.dropdownValue = "";
                }
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = false;
                switch (product.items[itemIndex].configuration.category.selected.value.optionKey) {
                    case "Indoor":
                        product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = false;
                        break;
                    case "Outdoor":
                        product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = true;
                        break;
                }
                if (!product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable) {
                    product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
                }
                break;
            case "widthMeasurement":
                product.items[itemIndex].configuration.widthMeasurement.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.widthMeasurement.finalOptions);

                if (!product.items[itemIndex].configuration.widthMeasurement.selected.isSelectable) {
                    product.items[itemIndex].configuration.widthMeasurement.selected.value = null;
                    product.items[itemIndex].configuration.widthMeasurement.selected.dropdownValue = "";
                }
                break;
            case "dropMeasurement":
                product.items[itemIndex].configuration.dropMeasurement.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.dropMeasurement.finalOptions);

                if (!product.items[itemIndex].configuration.dropMeasurement.selected.isSelectable) {
                    product.items[itemIndex].configuration.dropMeasurement.selected.value = null;
                    product.items[itemIndex].configuration.dropMeasurement.selected.dropdownValue = "";
                }
                break;
            case "valance":
                product.items[itemIndex].configuration.valance.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.valance.finalOptions);

                if (!product.items[itemIndex].configuration.valance.selected.isSelectable) {
                    product.items[itemIndex].configuration.valance.selected.value = null;
                    product.items[itemIndex].configuration.valance.selected.dropdownValue = "";
                }
                break;
            case "splineTapeColour":
                product.items[itemIndex].configuration.splineTapeColour.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.splineTapeColour.finalOptions);

                if (!product.items[itemIndex].configuration.splineTapeColour.selected.isSelectable) {
                    product.items[itemIndex].configuration.splineTapeColour.selected.value = null;
                    product.items[itemIndex].configuration.splineTapeColour.selected.dropdownValue = "";
                }
                break;
            case "splineType":
                product.items[itemIndex].configuration.splineType.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.splineType.finalOptions);

                if (!product.items[itemIndex].configuration.splineType.selected.isSelectable) {
                    product.items[itemIndex].configuration.splineType.selected.value = null;
                    product.items[itemIndex].configuration.splineType.selected.dropdownValue = "";
                }
                break;
            case "bottomSpline":
                product.items[itemIndex].configuration.bottomSpline.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.bottomSpline.finalOptions);

                if (!product.items[itemIndex].configuration.bottomSpline.selected.isSelectable) {
                    product.items[itemIndex].configuration.bottomSpline.selected.value = null;
                    product.items[itemIndex].configuration.bottomSpline.selected.dropdownValue = "";
                }
                break;
            case "bottomSplineColour":
                product.items[itemIndex].configuration.bottomSplineColour.selected.isSelectable = !isEmpty(product.items[itemIndex].configuration.bottomSplineColour.finalOptions);

                if (!product.items[itemIndex].configuration.bottomSplineColour.selected.isSelectable) {
                    product.items[itemIndex].configuration.bottomSplineColour.selected.value = null;
                    product.items[itemIndex].configuration.bottomSplineColour.selected.dropdownValue = "";
                }
                break;
            case "bottomPocketTubeDiameter":
                product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.product.selected.value)) {
                    switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                        case "VOD":
                            if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.value)) {
                                switch (product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey) {
                                    case "None":
                                    case "3.6mm":
                                    case "5mm":
                                    case "6mm":
                                    case "Pocket Only":
                                        product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.isSelectable = true;
                                        break;
                                }
                            }
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.isSelectable) {
                    product.items[itemIndex].configuration.bottomPocketTubeDiameter.selected.value = "";
                }
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let setIndex, sets, stocks, label, attribute,
            width, drop, comment, temp,
            deduction, condition1, condition2, condition3, condition4, condition5, optionIndex, isPVC, model, valance;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        isPVC = this.isPVCItem(product, itemIndex, order);
        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                temp = {
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: "",
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false,
                    isParentItem: true
                };
                switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                    case productConstant.ZIPTRAK_EXTERNAL.code:
                    case productConstant.WIDESCREEN.code:
                    case productConstant.VERTICAL_OUTDOOR.code:
                    case productConstant.PROJECTION_AWNINGS.code:
                        temp.prodCode = "KW-SKINONLY-VOD";
                        break;
                    case productConstant.FOLDING_ARM_AWNINGS.code:
                        temp.prodCode = "KW-SKINONLY-FAA";
                        break;
                    case productConstant.VERTICAL_INDOOR.code:
                        temp.prodCode = "KW-SKINONLY-VIN";
                        break;
                    case productConstant.ROLLER_BLINDS_SINGLE.code:
                        temp.prodCode = "KW-SKINONLY-ROL";
                        break;
                    case productConstant.PANEL_TRACKS.code:
                        temp.prodCode = "KW-SKINONLY-PTR";
                        break;
                }
                stocks = [temp];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [{
                    id: null,
                    description: "",
                    prodCode: isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? "09770" : product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                    keywayQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                    wastageFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1000,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true,
                    isParentItem: false
                }];

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "splineTapeColour":
                attribute = "Spline Tape Colour";
                label = "";
                stocks = [];
                sets = [];
                optionIndex = product.items[itemIndex].configuration.splineTapeColour.options.findIndex(o => o.description === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                    if (!isEmpty(product.items[itemIndex].configuration.splineTapeColour.selected.value)) {
                        condition2 = product.items[itemIndex].configuration.splineTapeColour.selected.value.optionKey;
                    }
                    if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                        condition3 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    }

                    sets = product.items[itemIndex].configuration.splineTapeColour.options[optionIndex].sets.filter(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                        && (s.condition3 ? s.condition3.includes(condition3) : true)
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.splineTapeColour.selected.stocks = stocks;
                break;
            case "spline":
                attribute = "Spline";
                label = "";
                stocks = [];
                sets = [];
                optionIndex = product.items[itemIndex].configuration.spline.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;

                    switch (condition1) {
                        case "ZIPE":
                            condition4 = isPVC ? "yes pvc" : "not pvc";
                            condition5 = isEmpty(product.items[itemIndex].configuration.splineTapeColour.selected.value) ? "" : product.items[itemIndex].configuration.splineTapeColour.selected.value.optionKey;
                            sets = product.items[itemIndex].configuration.spline.options[optionIndex].sets.filter(s =>
                                (
                                    (s.condition1 ? s.condition1.includes(condition1) : true)
                                    && (s.description ? s.description.includes(condition4) : true)
                                    && (s.attribute ? s.attribute.includes(condition5) : true)
                                )
                            );
                            break;
                        case "WID":
                            model = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                            switch (model) {
                                case "Zip System":
                                    condition4 = "Heavy Duty Keyway";
                                    break;
                                case "Cable System":
                                    if (width < 4000 && drop < 4000) {
                                        condition4 = "Standard Keyway";
                                    } else {
                                        condition4 = "Heavy Duty Keyway";
                                    }
                                    break;
                            }
                            sets = product.items[itemIndex].configuration.spline.options[optionIndex].sets.filter(s =>
                                (
                                    (s.condition1 ? s.condition1.includes(condition1) : true)
                                    && (s.description ? s.description.includes(condition4) : true)
                                )
                            );
                            break;
                        case "VOD":
                            condition2 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                            sets = product.items[itemIndex].configuration.spline.options[optionIndex].sets.filter(s =>
                                (
                                    (s.condition1 ? s.condition1.includes(condition1) : true)
                                    && (s.condition2 ? s.condition2.includes(condition2) : true)
                                )
                            );
                            break;
                        case "FAA":
                            valance = isEmpty(product.items[itemIndex].configuration.valance.selected.value) ? "" : product.items[itemIndex].configuration.valance.selected.value.optionKey;
                            switch (valance) {
                                case "None":
                                    condition4 = "No Valance";
                                    break;
                                default:
                                    condition4 = "With Valance";
                                    break;
                            }
                            sets = product.items[itemIndex].configuration.spline.options[optionIndex].sets.filter(s =>
                                (
                                    (s.condition1 ? s.condition1.includes(condition1) : true)
                                    && (s.description ? s.description.includes(condition4) : true)
                                )
                            );
                            break;
                        case "VIN":
                            sets = [];
                            break;
                        case "PRJ":
                            condition2 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                            condition3 = isEmpty(product.items[itemIndex].configuration.splineType.selected.value) ? "" : product.items[itemIndex].configuration.splineType.selected.value.optionKey;
                            sets = product.items[itemIndex].configuration.spline.options[optionIndex].sets.filter(s =>
                                (
                                    (s.condition1 ? s.condition1.includes(condition1) : true)
                                    && (s.condition2 ? s.condition2.includes(condition2) : true)
                                    && (s.condition3 ? s.condition3.includes(condition3) : true)
                                )
                            );
                            break;

                    }
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.spline.selected.stocks = stocks;
                break;
            case "valance":
                attribute = "Valance";
                comment = "";
                stocks = [];
                label = "";
                if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value)) {
                    label = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.valance.selected.value.stocks;
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.valance.selected.stocks = stocks;
                break;
            case "bottomSpline":
                attribute = "";
                label = "";
                comment = "";
                stocks = [];
                switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                    case "ZIPE":
                    case "WID":
                    case "VOD":
                    case "FAA":
                    case "PRJ":
                        if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.value)) {
                            attribute = "Bottom Spline";
                            label = product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
                            stocks = product.items[itemIndex].configuration.bottomSpline.selected.value.stocks;
                        }
                        stocks.forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                        });
                        break;
                    case "VIN":
                        if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.value)) {
                            attribute = "Bottom Weight";
                            label = product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
                            condition1 = product.items[itemIndex].configuration.bladeSize.selected.value + "mm";
                            product.items[itemIndex].configuration.bottomSpline.selected.value.sets.forEach(s => {
                                if ((s.condition1 ? s.condition1.includes(condition1) : true)) {
                                    stocks = [...stocks, ...s.stocks];
                                }
                            });
                        }
                        stocks.forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                        });
                        break;
                    case "PTR":
                        if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.value)) {
                            attribute = "Bottom Rail";
                            label = product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
                            condition1 = isEmpty(product.items[itemIndex].configuration.bottomSplineColour.selected.value) ? "" : product.items[itemIndex].configuration.bottomSplineColour.selected.value.optionKey;
                            product.items[itemIndex].configuration.bottomSpline.selected.value.sets.forEach(s => {
                                if ((s.condition1 ? s.condition1.includes(condition1) : true)) {
                                    stocks = [...stocks, ...s.stocks];
                                }
                            });
                        }
                        stocks.forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                        });
                        break;
                }
                product.items[itemIndex].configuration.bottomSpline.selected.stocks = stocks;
                break;
            case "bom":
                attribute = "bom";
                label = "";
                comment = "";
                stocks = [];
                switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                    case "ZIPE":
                    case "WID":
                    case "VOD":
                    case "FAA":
                    case "PRJ":

                        break;
                    case "VIN":
                        product.items[itemIndex].configuration.bom.finalOptions.forEach(o => {
                            attribute = o.optionKey;
                            switch (o.optionKey) {
                                case "Slat Hanger":
                                    condition1 = product.items[itemIndex].configuration.product.selected.value.optionKey;
                                    condition2 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                                    condition3 = product.items[itemIndex].configuration.bladeSize.selected.value + "mm";
                                    o.sets.forEach(s => {
                                        if ((s.condition1 ? s.condition1.includes(condition1) : true)) {
                                            if ((s.condition2 ? s.condition2.includes(condition2) : true)) {
                                                if ((s.condition3 ? s.condition3.includes(condition3) : true)) {
                                                    stocks = [...stocks, ...s.stocks];
                                                }
                                            }
                                        }
                                    });
                                    break;
                                default:
                                    o.sets.forEach(s => {
                                        stocks = [...stocks, ...s.stocks];
                                    });
                                    break;
                            }
                        });
                        stocks.forEach((stockItem, stockIndex) => {
                            this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                        });
                        break;
                }
                product.items[itemIndex].configuration.bom.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("splineTapeColour", product, itemIndex, order);
            product = this.updateStocks("spline", product, itemIndex, order);
            product = this.updateStocks("valance", product, itemIndex, order);
            product = this.updateStocks("bottomSpline", product, itemIndex, order);
            product = this.updateStocks("bom", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.category.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.category.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.splineTapeColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.splineTapeColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.spline.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.spline.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.valance.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.valance.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomSpline.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bom.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bom.selected.stocks];
            }
            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor,
            carrierQty: product.items[itemIndex].configuration.bladeNumber.selected.value
        };


        switch (attribute) {
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                /*
                * width(mm) drop(mm) fabricWidth(m)
                *
                * PANEL >> panel
                * FABRIC_DROP(M) >> fabricDrop
                * USAGE(LM) >> usage
                * */
                let panel = 0, fabricDrop = 0, usage = 0;

                //Calculating PANEL
                try {
                    panel = Math.ceil((width / 1000) / fabricWidth);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }

                //Calculating FABRIC_DROP
                fabricDrop = (drop + 400) / 1000;

                //Calculating FABRIC_DROP
                if (fabricDrop > fabricWidth) {
                    usage = round((panel * fabricDrop), 1);
                } else {
                    usage = width / 1000;
                }
                deduction = this.updateDeduction("fabric", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Spline Tape Colour":
            case "Spline":
            case "Valance":
            case "Bottom Spline":
            case "bom":
            default:
                deduction = this.updateDeduction(attribute, product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
        }
    }

    updateDeduction(key, product, itemIndex, stockInventoryItem) {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let model = "";
        let valance, widthMeasurement, bottomSpline;
        switch (key) {
            case "fabric":
                switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                    case "PTR":
                        bottomSpline = isEmpty(product.items[itemIndex].configuration.bottomSpline.selected.value) ? "" : product.items[itemIndex].configuration.bottomSpline.selected.value.optionKey;
                        switch (bottomSpline) {
                            case "Standard":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 40;
                                break;
                            case "Oval":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 20;
                                break;
                            case "Flat":
                            case "Silent":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 45;
                                break;
                            case "Sewn in Lathe":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 75;
                                break;
                        }
                        break;
                }
            case "Spline":
                switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                    case "ZIPE":
                        widthMeasurement = isEmpty(product.items[itemIndex].configuration.widthMeasurement.selected.value) ? "" : product.items[itemIndex].configuration.widthMeasurement.selected.value.optionKey;
                        switch (widthMeasurement) {
                            case "Spline to Spline":
                                deduction.deductionWidth = -20;
                                deduction.deductionDrop = 400;
                                break;
                            case "Overall Make Size":
                                deduction.deductionWidth = -90;
                                deduction.deductionDrop = 400;
                                break;
                        }
                        break;
                    case "WID":
                        model = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                        switch (model) {
                            case "Zip System":
                                deduction.deductionWidth = -18;
                                deduction.deductionDrop = 400;
                                break;
                            case "Cable System":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 400;
                                break;
                        }
                        break;
                    case "VOD":
                        valance = isEmpty(product.items[itemIndex].configuration.valance.selected.value) ? "" : product.items[itemIndex].configuration.valance.selected.value.optionKey;
                        model = product.items[itemIndex].configuration.model.selected.value.optionKey;
                        switch (valance) {
                            case "None":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 400;
                                break;
                            default:
                                deduction.deductionWidth = 0;
                                if (model === "Multi-Stop Channel Awning") {
                                    deduction.deductionDrop = 0;
                                } else {
                                    deduction.deductionDrop = 650;
                                }
                                break;
                        }
                        break;
                    case "FAA":
                        valance = isEmpty(product.items[itemIndex].configuration.valance.selected.value) ? "" : product.items[itemIndex].configuration.valance.selected.value.optionKey;
                        switch (valance) {
                            case "None":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 700;
                                break;
                            default:
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 1000;
                                break;
                        }
                        break;
                    case "VIN":
                        switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                            case "Order by Blind Dimension":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 0;
                                break;
                            case "Order by Blade Qty":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 50;
                                break;
                        }
                        break;
                    case "PRJ":
                        valance = isEmpty(product.items[itemIndex].configuration.valance.selected.value) ? "" : product.items[itemIndex].configuration.valance.selected.value.optionKey;
                        switch (valance) {
                            case "None":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 400;
                                break;
                            default:
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 650;
                                break;
                        }
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Spline Tape Colour":
                switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
                    case "WID":
                        switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                            case "Zip System":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 400;
                                break;
                            case "Cable System":
                                deduction.deductionWidth = -18;
                                deduction.deductionDrop = 400;
                                break;
                        }
                        deduction.deductionWidth = 0;
                        deduction.deductionDrop = 400;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
        }
        return deduction;
    }

    isMotorised(product, itemIndex, order) {
        let isMotorised = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation)) {
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                if (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                    isMotorised = product.items[itemIndex].configuration.operation.selected.value.optionKey.includes("Motor");
                }
            }
        }
        return isMotorised;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        stockQtyExpression = stockQtyExpression.replaceAll("@CarrierQty", params.carrierQty);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    generateBMCode(product, itemIndex, order) {
        let bmCode = "";

        switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
            case productConstant.ZIPTRAK_EXTERNAL.code:
            case productConstant.ROLLER_BLINDS_SINGLE.code:
            case productConstant.PANEL_TRACKS.code:
                bmCode = product.items[itemIndex].configuration.fabricType.selected.value.primaryBMCode;
                break;
            case productConstant.WIDESCREEN.code:
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Cable System":
                        bmCode = "Cable";
                        break;
                    case "Zip System":
                        bmCode = "Zip";
                        break;
                }
                bmCode = bmCode + product.items[itemIndex].configuration.fabricType.selected.value.category;
                break;
            case productConstant.VERTICAL_OUTDOOR.code:
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                    case "Retractable":
                        bmCode = "SDA-P";
                        break;
                    case "Multi-Stop Channel Awning":
                        bmCode = "MSCA-P";
                        break;
                }
                break;
            case productConstant.FOLDING_ARM_AWNINGS.code:
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Monobloc 505":
                        bmCode = "M505";
                        break;
                    case "Monobloc 520":
                        bmCode = "M520";
                        break;
                    case "Semi Cassette":
                        bmCode = "SC1540";
                        break;
                    case "Full Cassette":
                        bmCode = "FC1540";
                        break;
                }
                bmCode = bmCode + product.items[itemIndex].configuration.fabricType.selected.value.category;
                break;
            case productConstant.VERTICAL_INDOOR.code:
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Order by Blind Dimension":
                        bmCode = product.items[itemIndex].configuration.fabricType.selected.value.primaryBMCode;
                        break;
                    case "Order by Blade Qty":
                        bmCode = "Blade Only";
                        if (order.accountID.includes(BUNNINGS_ACCOUNT_ID)) {
                            switch (product.items[itemIndex].configuration.bladeSize.selected.value) {
                                case 89:
                                    bmCode = bmCode + " Bunnings 89mm";
                                    break;
                                case 127:
                                    bmCode = bmCode + " Bunnings 127mm";
                                    break;
                            }
                        }
                        break;
                }
                break;
            case productConstant.PROJECTION_AWNINGS.code:
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Fixed Pivot Convertible Awning":
                        bmCode = "FPCA-P";
                        break;
                    case "Automatic Awning":
                        bmCode = "AA-P";
                        break;
                    case "Fixed Guide Awning":
                        bmCode = "FGA-P";
                        break;
                    case "Drop Arm Awning":
                        bmCode = "DAA-P";
                        break;
                }
                break;
        }
        return bmCode;
    }


    hasNoValance(product, itemIndex) {
        let result = true;
        if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value)) {
            switch (product.items[itemIndex].configuration.valance.selected.value.optionKey) {
                case "None":
                    result = true;
                    break;
                case "Straight":
                case "Scallop":
                    result = false;
                    break;
            }
        }
        return result
    }

    getPriceMatrixMultiplierByFabricCategory(product, itemIndex) {
        let result = 1;
        switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
            case "ZIPE":
            case "WID":
            case "FAA":
            case "VIN":
                result = 1;
                break;
            case "VOD":
            case "PRJ":
                if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                    switch (product.items[itemIndex].configuration.fabricType.selected.value.category) {
                        case "P":
                            result = 1;
                            break;
                        case "Q":
                        case "PVCA":
                            result = 1.2;
                            break;
                        case "R":
                        case "PVCB":
                            result = 1.3;
                            break;
                        case "S":
                            result = 1.4;
                            break;
                    }
                }
                break;
        }
        return result;
    }

    getPriceMatrixMultiplier(product, itemIndex) {
        let result = 1;
        switch (product.items[itemIndex].configuration.product.selected.value.optionKey) {
            case "ZIPE":
            case "WID":
            case "VOD":
            case "FAA":
            case "PRJ":
                result = 0.6;
                break;
            case "VIN":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Order by Blade Qty":
                        result = 1;
                        break;
                    case "Order by Blind Dimension":
                    default:
                        result = 0.8;
                        break;
                }
                break;
            case "ROLS":
                result = 0.75;
                break;
            case "PTR":
                result = 0.85;
                break;
        }
        return result;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.bottomPocketTubeDiameter.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.bottomPocketTubeDiameter.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fabricColourOffRange.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fabricColourOffRange.formError.message);
        }
        return errors;
    }

    getValueOrDefault(product, itemIndex, valueType, defaultValue, key) {
        let result = defaultValue;
        switch (valueType) {
            case "value":
                if (product.items[itemIndex].configuration[key].selected.value) {
                    result = product.items[itemIndex].configuration[key].selected.value;
                }
                break;
            case "optionKey":
                if (!isEmpty(product.items[itemIndex].configuration[key].selected.value)) {
                    result = product.items[itemIndex].configuration[key].selected.value.optionKey;
                }
                break;
        }
        return result;
    }

    isPVCItem(product, itemIndex, order) {
        let fabric = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;
        return fabric.toUpperCase().includes("PVC");
    }


    toSalesOrderItemSkinOnlyRequest(pg, itemIndex, order) {
        let defaultDeduction = this.updateDeduction("default", pg, itemIndex);
        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }

        let salesOrderItemSkinOnly = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: pg.items[itemIndex].configuration.set.selected.value.priceMatrix ? pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode : "",
            widthSet: ((!isEmpty(pg.items[itemIndex].configuration.set.selected.value)) && (!isEmpty(pg.items[itemIndex].configuration.set.selected.value.widthSet))) ? pg.items[itemIndex].configuration.set.selected.value.widthSet.width : 0,
            dropSet: ((!isEmpty(pg.items[itemIndex].configuration.set.selected.value)) && (!isEmpty(pg.items[itemIndex].configuration.set.selected.value.dropSet))) ? pg.items[itemIndex].configuration.set.selected.value.dropSet.drop : 0,
            location: this.getValueOrDefault(pg, itemIndex, "value", "", "location"),
            quantity: this.getValueOrDefault(pg, itemIndex, "value", 0, "quantity"),
            width: this.getValueOrDefault(pg, itemIndex, "value", 0, "width"),
            drop: this.getValueOrDefault(pg, itemIndex, "value", 0, "drop"),
            widthMeasurement: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "widthMeasurement"),
            dropMeasurement: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "dropMeasurement"),
            category: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "category"),
            product: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "product"),
            model: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "model"),
            purpose: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "purpose"),
            previousOrdNum: this.getValueOrDefault(pg, itemIndex, "value", null, "previousOrdNum"),
            fabricType: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "fabricType"),
            fabricColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "fabricColour"),
            fabricColourOffRange: this.getValueOrDefault(pg, itemIndex, "value", "", "fabricColourOffRange"),
            valance: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "valance"),
            splineTapeColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "splineTapeColour"),
            splineType: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "splineType"),
            bottomSpline: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "bottomSpline"),
            bottomSplineColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "bottomSplineColour"),
            bottomPocketTubeDiameter: this.getValueOrDefault(pg, itemIndex, "value", 0, "bottomPocketTubeDiameter"),
            bladeSize: this.getValueOrDefault(pg, itemIndex, "value", 0, "bladeSize"),
            bladeNumber: this.getValueOrDefault(pg, itemIndex, "value", 0, "bladeNumber"),

            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,

            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,
            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemSkinOnly;
    }
}

export default SkinOnlyUtil.Instance();
