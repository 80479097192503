import React, {Component} from "react";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardHeader, Nav, NavItem, NavLink, TabPane} from "reactstrap";
import {Link} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import PowdercoatingOrderPage from './PowdercoatingOrderPage';

const powdercoatingOrderTabs = [
    {label: 'Pending', key: 'pending'},
    { label: 'Complete', key: 'complete' },
    { label: 'Cancelled', key: 'cancelled' }];

export default class PowdercoatingOrderTabPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "pending"
        }
        this.toggleTab=this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        this.setState({activeTab: tab});
    }

    render() {
        let {activeTab} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Powdercoating Orders</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {powdercoatingOrderTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={{active: activeTab === tab.key}}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    {powdercoatingOrderTabs.map((tab, index) =>

                        <TabPane tabId={tab.key}>
                            {(activeTab === tab.key) ?
                                <PowdercoatingOrderPage statusType={tab.key}/>
                                : null}
                        </TabPane>
                    )}
                </Card>
                <ToastContainer/>
            </div>
        )
    }
}