import React, { Component } from 'react';
import FileSaver from 'file-saver';
import { Modal, ModalFooter, Button, ModalHeader, ModalBody, Col, CardBody, CardHeader, Card, CardFooter, Row, Table } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FaqService from '../../../services/FaqService';
import ServiceToPostFiles from '../../../services/ServiceToPostFiles/ServiceToPostFiles';

export default class UploadFAQModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Id: this.props.FAQId,
            deleteIndex: 0,
            selectedFile: null,
            selectedScreenshotFile: null,
            file: null,
            oldImages: [],
            uploadedDocName: '',
            showForm: false,
            isUploading: false,
            isDeleting: false,
            fileInput: {},
            theInputKey: ''
        }

        this.faqService = new FaqService();
        this.serviceToPostFiles = new ServiceToPostFiles();
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.getPreviosFAQsUploads = this.getPreviosFAQsUploads.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.showForm = this.showForm.bind(this);
        this.onDocumentClickSuccess = this.onDocumentClickSuccess.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ Id: nextProps.FAQId }, () => this.getPreviosFAQsUploads(nextProps.FAQId));
    }

    getPreviosFAQsUploads(FAQId) {
        let formErrors = {};
        this.setState({ formErrors, selectedFile: null, file: null, uploadedDocName: '', showForm: false });
        if (FAQId !== 0) {
            this.faqService.fetchPreviosFAQsUploads(FAQId).then(response => {
                //console.log(response, 'resss');
                if (response.data) {
                    this.setState({ oldImages: response.data, isUploading: false });
                }
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(event) {
        this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            selectedScreenshotFile: event.target.files[0]
        });
    }

    onDocumentClickSuccess(item) {
        //name = name.replace(/.pdf|.PNG|.jepg|.jpg|.png|.JPEG|.JPG|.gif/g, '');
        //name = name + ".pdf";
        this.faqService.getFaqByName(item.fileName).then(response => {
            FileSaver.saveAs(response.data, item.description);
        }).catch(error => {
            console.log(error.data)
        });
    }

    onDelete(item, index) {
        this.setState({ isDeleting: true, deleteIndex: index });
        this.faqService.DeleteFaqFiles(item.id, this.state.Id).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ isDeleting: false });
                toast.success("FAQ file removed successfully...", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.getPreviosFAQsUploads(this.state.Id);
            }
        }).catch(error => {
            console.log(error.data)
            this.setState({ isDeleting: false });
        });

    }

    onClickHandler() {
        if (this.state.selectedFile && this.state.selectedScreenshotFile) {
            this.setState({ isUploading: true });
            const data = new FormData();
            data.append('file', this.state.selectedFile);
            data.append('ScreenShotFile', this.state.selectedScreenshotFile);
            data.append('FaqId', this.state.Id);
            this.serviceToPostFiles.addFAQ(data).then(response => {
                if (response.status === 200 || response.status === '200') {
                    this.getPreviosFAQsUploads(this.state.Id);
                    toast.success("FAQ File added successfully...", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                this.setState({ isUploading: false });
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({ isUploading: false });
            });
        }
        else {
            toast.info("Please upload snap and corresponding file.", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    onChangeHandler(event) {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
            uploadedDocName: event.target.files[0].name
        });
        for (let i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size > 27262976) {
                this.setState({ fileSizeText: 'File size must be less than 26 MB' })
            } else {
                this.setState({ fileSizeText: '' })
            }
        }
    }


    renderOldUploads(data) {
        let { isDeleting, deleteIndex } = this.state;
        let baseUrl = window.location.origin;
        return (
            <div id="Old_uploads">
                <div className="btr-15 btlr-15 no-border-bottom bg-white">
                    Already uploaded Faq Files
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Snapshot</th>
                            <th>File</th>
                            <th style={{ minWidth: 120 }} className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) =>
                            <tr key={index}>
                                <td>
                                    <div className="text-left" style={{ 'cursor': 'pointer' }}
                                        title="Click to Download">
                                        {
                                            item.snapFileName
                                                ?
                                                <img title="Click to Download"
                                                    src={baseUrl + "/api/file/Generate/FAQFile?FileName=" + item.snapFileName}
                                                    style={{ maxHeight: 150 }}
                                                    onClick={() => {
                                                        this.onDocumentClickSuccess(item)
                                                    }} />
                                                : null
                                        }
                                    </div>
                                </td>
                                <td>{item.description}</td>
                                <td>
                                    <div className="text-center">
                                        <Button color={"danger"} size="sm"
                                            onClick={() => { this.onDelete(item, index) }}>
                                            {(isDeleting && index === deleteIndex) ? <span><i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" /> Deleting...  </span> :
                                                <span title="upload"><i className="fa fa-trash-o mr-2" aria-hidden="true" /> Delete</span>
                                            }
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        )}

                    </tbody>

                </Table>

            </div>
        )
    }

    showForm(flag) {
        if (flag) {
            this.setState({ showForm: flag });
        }
        else {
            this.refs.myInput.value = null;
            let randomString = Math.random().toString(36);
            this.setState({ selectedFile: null, file: null, uploadedDocName: '', selectedScreenshotFile: null, theInputKey: randomString, showForm: flag });
        }
    }

    render() {
        let { file, fileSizeText, uploadedDocName, oldImages, showForm, isUploading } = this.state;
        let oldfiles = this.renderOldUploads(oldImages);
        return (
            <Modal isOpen={this.props.isOpen} className="modal-xl" size="xl" scrollable={false}
                toggle={this.props.toggleModel} backdrop="static">
                <ModalHeader toggle={() => this.props.toggleModel(0)}>
                    Attachments
                </ModalHeader>
                <ModalBody>
                    {showForm ? <Card>
                        <CardBody>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <Card style={{ minHeight: 200 }}>
                                        <CardHeader>SnapShot</CardHeader>
                                        <CardBody>
                                            <div className="text-center">
                                                {file != null ? <img style={{ maxHeight: 150, maxWidth: 250 }} src={file} /> : <p></p>}
                                            </div>
                                            <div className="text-center">
                                                <input type="file" name="myfile_snap" key={this.state.theInputKey || ''} onChange={this.handleChange}
                                                    aria-label="File browser example" ref="myInput" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <Card style={{ minHeight: 200 }}><CardHeader>
                                        File
                                    </CardHeader>
                                        <CardBody>
                                            <div className="text-center">
                                                {uploadedDocName ? <h5>{uploadedDocName}</h5> : null}
                                            </div>
                                            <div className="text-center">
                                                <input type="file" name="myfile_doc" onChange={this.onChangeHandler}
                                                    aria-label="File browser example" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <div className={"text-right"}>
                                <Button color='primary' size="sm"
                                    onClick={this.onClickHandler}>
                                    {isUploading ? <span><i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" /> Uploading...  </span> : <span title="upload"><i className="fa fa-upload" aria-hidden="true" /> Upload</span>}
                                </Button>{' '}
                                <Button color="secondary" size="sm" onClick={() => this.showForm(false)}>Clear</Button>
                            </div>
                        </CardFooter>
                    </Card> : <Card className={"hoverableItem"} onClick={() => this.showForm(true)}>
                            <CardBody>
                                <div className="text-center">
                                    <h3><i className="fa fa-plus-circle mr-2" aria-hidden="true" /> New File
                                </h3>
                                </div>
                            </CardBody>
                        </Card>}

                    {oldImages.length > 0 ? oldfiles : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" size="sm" onClick={() => this.props.toggleModel(0)}>Close</Button>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }

}