import React, {Component} from 'react';
import {
    Button, Card, CardBody, Col, Form, FormGroup, FormText, Input,
    Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, Spinner, CardHeader
} from "reactstrap";
import contactService from "../../services/ContactService";
import CustomerService from "../../services/CustomerService";
import {toast} from "react-toastify";
import {isEmpty} from 'lodash';
import UserService from "../../services/UserService";
import ConfirmModal from "../modal/ConfirmModal";
import classnames from "classnames";
import {handleErrorMessage} from "../../services/CommonService";
import ManageDebtorTeamStaffLoginModal from '../modal/ManageDebtorTeamStaffLoginModal';
import {isRetailBoardVisible} from '../../services/CommonFunctions';

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactTypes: [],
            formData: this.initContact(),
            formError: {},
            isLoadingContacts: false,
            isLoadingSaveContact: false,
            hasSubmitTried: false
        };
        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.handleChange = this.handleChange.bind(this);
        this.initContact = this.initContact.bind(this);
        this.saveContact = this.saveContact.bind(this);
        this.getContact = this.getContact.bind(this);
        this.fetchContactTypes = this.fetchContactTypes.bind(this);
    }

    componentDidMount() {
        this.fetchContactTypes();
        this.getContact(this.props.contactID);

    }


    fetchContactTypes() {
        contactService.getContactTypes().then(response => {
            this.setState({contactTypes: response.data});
        }).catch(error => {
            console.log(error.data)
        });
    }

    getContact(contactID) {
        let {formData} = this.state;
        if (contactID) {
            contactService.getContact(contactID).then(response => {
                this.setState({formData: response.data, hasSubmitTried: false});
            }).catch(error => {
                console.log(error.data)
            });
        } else {
            formData = this.initContact();
            this.setState({formData});
        }

    }

    initContact() {
        return {
            contactID: null,
            dateCreated: "",
            accountID: this.props.accountID,
            groupTypeID: 1,
            contactTypeID: 1,
            jobTitle: '',
            firstName: '',
            middleInitial: '',
            lastName: '',
            phone: '',
            fax: '',
            afterHours: '',
            mobile: '',
            email: '',
            isPrimary: false,
            isEmailStatement: false,
            isEmailInvoice: false,
            isEmailDispatchNotice: false,
            isEmailMarketing: false,
            priority: 1,
            userID: "",
            consumerType: this.props.consumerType,
            messenger: "",
        };
    }


    saveContact() {
        let formData = this.state.formData;
        let formError = contactService.validateForm(formData);


        if (!formError.isValid) {
            formError.errors.forEach(error => {
                toast.info(error);
            });
            this.setState({formError, hasSubmitTried: true});
            return;
        }

        this.setState({isLoadingSaveContact: true});
        contactService.saveContact(formData).then(response => {
            if (response.data) {
                toast.success("Saved!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }

            this.setState({
                    isLoadingSaveContact: false,
                    hasSubmitTried: false
                }, () => {
                    this.props.toggle(false);
                    this.props.refresh();
                }
            );

        }).catch(error => {
            this.setState({isLoadingSaveContact: false});
            toast.error(handleErrorMessage(error));
            console.log(error.data)
        });
    }

    handleChange(change, key) {
        let formData = this.state.formData;

        formData[key] = change;
        this.setState({formData: formData});
    }

    render() {
        let {
            contactTypes, formError, formData,
            isLoadingSaveContact, hasSubmitTried
        } = this.state;
        let {consumerType, isOpen, debtor, toggle} = this.props;

        return ( <Modal isOpen={isOpen} size="lg" scrollable={false}>
                <ModalHeader toggle={()=> toggle(false)}>
                    {formData.contactID > 0 ? "Edit " : "Add "}Contact
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label>Contact Type</Label>
                                    <Input className="form-control"
                                           type="select"
                                           value={formData.contactTypeID}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           name="contactTypeID"
                                           placeholder="Select contact type...">
                                        <option key={100} value={0}>Select contact type</option>
                                        {(contactTypes || []).map((item, index) =>
                                            <option
                                                key={index}
                                                value={item.contactTypeID}>{item.name}</option>)}
                                    </Input>
                                    <FormText
                                        color={hasSubmitTried ? "danger" : "muted"}>{formError.contactTypeID}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label for="jobTitle">Position</Label>
                                    <Input autoComplete="off" type="text" name="jobTitle"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.jobTitle}
                                           placeholder="Enter position here."
                                           id="JobTitle"/>
                                    <FormText color={hasSubmitTried ? "danger" : "muted"}> {formError.jobTitle}</FormText>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label for="firstName">First name</Label>
                                    <Input autoComplete="off" type="text" name="firstName"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.firstName}
                                           placeholder="Enter first name here."
                                           id="FirstName"/>
                                    <FormText color={hasSubmitTried ? "danger" : "muted"}> {formError.firstName}</FormText>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label for="lastName">Last name</Label>
                                    <Input autoComplete="off" type="text" name="lastName"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.lastName}
                                           placeholder="Enter last name here."
                                           id="LastName"/>
                                    <FormText color={hasSubmitTried ? "danger" : "muted"}> {formError.lastName}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label for="phone">Phone</Label>
                                    <Input autoComplete="off" type="text" name="phone"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.phone}
                                           placeholder="Enter phone number here."
                                           id="Phone"/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label for="mobile">Mobile</Label>
                                    <Input autoComplete="off" type="text" name="mobile"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.mobile}
                                           placeholder="Enter mobile number here."
                                           id="Mobile"/>
                                    <FormText color={hasSubmitTried ? "danger" : "muted"}> {formError.mobile}</FormText>
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="email">Messenger</Label>
                                    <Input autoComplete="off" type="text" name="messenger"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.messenger} placeholder="Enter messenger here."
                                           id="Email"/>
                                    <FormText color={hasSubmitTried ? "danger" : "muted"}> {formError.messenger}</FormText>
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input autoComplete="off" type="text" name="email"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.email} placeholder="Enter email here."
                                           id="Email"/>
                                    <FormText color={hasSubmitTried ? "danger" : "muted"}> {formError.email}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label for="priority">Priority</Label>
                                    <Input autoComplete="off" type="number" name="priority"
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                           value={formData.priority}
                                           placeholder="Enter specific priority."
                                           id="priority"/>
                                    <FormText> The lower the
                                        priority the higher is
                                        the importance ,example 1 has highest priority</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            consumerType === "Debtor"
                                ? <Row>
                                    {
                                        debtor.isParent ?
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <FormGroup>
                                                    <Label className={"mb-0"}>Email statement</Label>
                                                    <div className="d-flex flex-row">
                                                        <div>
                                                            <Button color={"link"}
                                                                    onClick={() => this.handleChange(!formData.isEmailStatement, "isEmailStatement")}>
                                                                {
                                                                    formData.isEmailStatement
                                                                        ?
                                                                        <i className="fa fa-lg fa-check-square-o"
                                                                           aria-hidden="true"/>
                                                                        :
                                                                        <i className="fa fa-lg fa-square-o"
                                                                           aria-hidden="true"/>
                                                                }
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <small>If selected, registered email within this
                                                                contact will be
                                                                used
                                                                for
                                                                sending monthly statements.
                                                            </small>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            :
                                            null
                                    }
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label className={"mb-0"}>Email invoice</Label>
                                            <div className="d-flex flex-row">
                                                <div>
                                                    <Button color={"link"}
                                                            onClick={() => this.handleChange(!formData.isEmailInvoice, "isEmailInvoice")}>
                                                        {
                                                            formData.isEmailInvoice
                                                                ? <i className="fa fa-lg fa-check-square-o"
                                                                     aria-hidden="true"/>
                                                                : <i className="fa fa-lg fa-square-o"
                                                                     aria-hidden="true"/>
                                                        }
                                                    </Button>
                                                </div>
                                                <div>
                                                    <small>If selected, registered email within this contact
                                                        will be used
                                                        for sending invoices.
                                                    </small>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label className={"mb-0"}>Email dispatch notice</Label>
                                            <div className="d-flex flex-row">
                                                <div>
                                                    <Button color={"link"}
                                                            onClick={() => this.handleChange(!formData.isEmailDispatchNotice, "isEmailDispatchNotice")}>
                                                        {formData.isEmailDispatchNotice ?
                                                            <i className="fa fa-lg fa-check-square-o"
                                                               aria-hidden="true"/> :
                                                            <i className="fa fa-lg fa-square-o"
                                                               aria-hidden="true"/>}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <small>If selected, registered email within this contact
                                                        will be used
                                                        for
                                                        notifying on dispatches.
                                                    </small>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label className={"mb-0"}>Email marketing</Label>
                                            <div className="d-flex flex-row">
                                                <div>
                                                    <Button color={"link"}
                                                            onClick={() => this.handleChange(!formData.isEmailMarketing, "isEmailMarketing")}>
                                                        {formData.isEmailMarketing ?
                                                            <i className="fa fa-lg fa-check-square-o"
                                                               aria-hidden="true"/> :
                                                            <i className="fa fa-lg fa-square-o"
                                                               aria-hidden="true"/>}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <small>If selected, registered email within this contact
                                                        will be used
                                                        for sending marketing emails.
                                                    </small>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                : null
                        }

                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={isLoadingSaveContact} color="primary"
                            onClick={this.saveContact}>
                        {isLoadingSaveContact
                            ? <Spinner size={"sm"} className={"mr-2"}/>
                            : <i className="fa fa-floppy-o mr-2"
                                 aria-hidden="true"/>}
                        {isLoadingSaveContact ? "Saving" : "Save"}
                    </Button>
                    <Button disabled={isLoadingSaveContact} color="secondary"
                            className={"ml-2"}
                    onClick={() => toggle(false)}>
                    <i class="fa fa-times mr-2" aria-hidden="true"></i>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>


        );

    }
}