import axios from './axios';

class UserSelfService {
    static Instance(){
        return new UserSelfService();
    }


    updateProfile(user) {
        if (user.id) {
            return axios.put('api/user/self/update/profile', user)
        }
    }

    updatePassword(password, repassword) {
        let requestBody = {
            password: password,
            repassword: repassword,
        };
        return axios.put('api/user/self/update/password', requestBody);
    }

}


export default UserSelfService.Instance();