import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    CardFooter,
    Row,
    Col,
    Table,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Badge

} from 'reactstrap';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import NumberFormat from "react-number-format";


import PurchaseHistoryService from '../../../services/PurchaseHistoryService';
import PurchaseHistoryOutstandingOrders from './PurchaseHistoryOutstandingOrders';

export default class PurchaseHistoryOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountId: this.props.accountId,
            loading: false,
            completedOrders: {
                orders: [],
                selectedPage: 1,
                pageSize: 25,
                totalRecords: 1
            }
        };

        this.purchaseHistoryService = new PurchaseHistoryService();
        this.fetchCompletedOrders = this.fetchCompletedOrders.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({accountId: nextProps.accountId}, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        this.fetchCompletedOrders(this.state.completedOrders.selectedPage);
    }


    fetchCompletedOrders(selectedPage) {
        this.setState({loading: true});

        this.purchaseHistoryService.getCompletedOrders(this.state.accountId, selectedPage, this.state.completedOrders.pageSize).then(res => {
            let data = res.data;
            let completedOrders = this.state.completedOrders;
            completedOrders.orders = data.orders;
            completedOrders.selectedPage = data.selectedPage;
            completedOrders.pageSize = data.pageSize;
            completedOrders.totalRecords = data.totalRecords;
            if (this.props.updateTabs) {
                this.props.updateTabs(this.props.tabId, "count", completedOrders.totalRecords);
            }
            this.setState({completedOrders: completedOrders, loading: false});
        }).catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }


    render() {

        let {loading, completedOrders} = this.state;
        let {orders, selectedPage, pageSize, totalRecords} = completedOrders;

        if (loading) {
            return <p><em>loading...</em></p>
        }

        if (orders.length === 0) {
            return <Badge color={"light"} className={"mt-4"}>0 orders found</Badge>
        }

        return (

            <div>
                <Table responsive hover>
                    <thead>
                    <tr>
                        <th>Order Date</th>
                        <th>Order No</th>
                        <th>Cust Order No</th>
                        <th>Ref</th>
                        <th>Invoice Value</th>
                        <th>Delivery Date</th>
                        <th>Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map((order, index) =>
                        <tr key={index} className={order.cancelled ? "table-dark" : ""}>

                            <td>{order.orderDate}</td>
                            <td><Link
                                className="btn btn-sm btn-primary"
                                style={{color: "white"}}
                                title={"Click here to view order in detail"}
                                to={"/sales/order/view?" + queryString.stringify({ OrdNum: order.ordNum })}>{order.ordNum}</Link></td>
                            <td>{order.custOrdNum}</td>
                            <td>{order.jobRef}</td>
                            <td className={"table-success"}><NumberFormat value={order.invoiceValue}
                                                                          displayType={'text'}
                                                                          decimalScale={2}
                                                                          thousandSeparator={true}
                                                                          prefix={'$'}/></td>
                            <td>{order.deliveryDate}</td>
                            <td>{order.notes}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                {totalRecords > pageSize ? <Row>
                    <Col md="4" sm="1"></Col>
                    <Col md="4" sm="10">
                        <Pagination
                            activePage={selectedPage}
                            itemsCountPerPage={pageSize}
                            totalItemsCount={totalRecords}
                            pageRangeDisplayed={3}
                            onChange={this.fetchCompletedOrders}
                            itemClass='page-item'
                            linkClass='page-link'
                            activeClass='active'
                            innerClass='pagination'
                            activeLinkClass='active'
                        />
                    </Col>
                </Row> : null}

            </div>

        );
    }
}
