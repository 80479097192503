import axios from "../axios";

class StockWarehouseLocationService {

    static Instance() {
        return new StockWarehouseLocationService();
    }

    listStockWarehouseLocation(req) {
        return axios.post('api/stock-warehouse-location/list', req);
    }

    getOneStockWarehouseLocation(stockWarehouseLocationID) {
        return axios.get('api/stock-warehouse-location/one/?StockWarehouseLocationID=' + stockWarehouseLocationID);
    }

    saveOrUpdateStockWarehouseLocation(req) {
        return axios.post('api/stock-warehouse-location/save', req);
    }

    deleteStockWarehouseLocation(stockWarehouseLocationID) {
        return axios.delete('api/stock-warehouse-location/delete/?StockWarehouseLocationID=' + stockWarehouseLocationID);
    }

    getStockWarehouseLocationsBystock(req) {
        return axios.post('api/stock-warehouse-location/stock/list' , req);
    }

    transferStockQuantity(req){
        return axios.post('api/stock-warehouse-location/stock/transfer',req)
    }

    getStockTransferHistory(req){
        return axios.post('api/stock-warehouse-location/stock/transfer/history',req)
    }
}

export default StockWarehouseLocationService.Instance();