import React, { Component } from 'react';
import QuotesOverviewComponent from '../../../pages/sales/Quotes/QuotesOverviewComponent';
import SalesQuotesPage from '../../../pages/sales/SalesQuotesPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, CardHeader, Button, Table, Row, Col, Spinner } from 'reactstrap';
import classnames from 'classnames';

const Tabs = [
    { key: '1', label: 'Quotes OverView' },
    { key: '2', label: 'Quotes & Saved Orders' }
]
export default class SalesQuotesOverviewPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
            tabs: Tabs
        };
    }

    componentDidMount() {
        this.refresh();
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    refresh() {

    }

    render() {
        let { tabs, activeTab } = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        tag={"span"}
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{ textDecoration: "none" }}
                                            size={"sm"}
                                            color={"link"}>{tab.label}&nbsp;</Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab} className={"p-2"}>
                            <TabPane tabId="1" >
                                <QuotesOverviewComponent />
                            </TabPane>

                            <TabPane tabId="2">
                                <SalesQuotesPage />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer />
            </div >
        );
    }

}