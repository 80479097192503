import React, {Component} from 'react';
import {
    Col, Input, PopoverBody, Row, Spinner, Table, UncontrolledPopover, Button, InputGroup,
    InputGroupAddon, InputGroupText
} from 'reactstrap';
import NumberFormat from "react-number-format";
import {cloneDeep, isEmpty} from 'lodash';
import {FREIGHT_RULE_FREIGHT_OVERIDE} from "../../../../../store/AppConstants";

export default class SalesOrderProductBuilderOrderSummaryTableDefault extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {order, products, discountByProductCode, freight, handleOrderChange} = this.props;

        let subTotal, subTotalDiscountVal, totalDiscountVal, total, tax;
        subTotal = order.pricing.price;
        subTotalDiscountVal = order.pricing.discVal;
        totalDiscountVal = subTotalDiscountVal;
        tax = order.pricing.tax;
        total = order.pricing.total;
        return (
            <div>
                <p><strong>Order Summary</strong></p>
                <Table>
                    <tbody>
                    <tr>
                        <td>Sub-total</td>
                        <th className="text-right">
                            <NumberFormat
                                prefix={'$'}
                                value={subTotal}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    {
                        (order.pricing.discVal > 0)
                            ? <tr>
                                <td colSpan={2}>
                                    Discount <a
                                    href="javascript:void(0)"> <i
                                    id="UncontrolledPopover"
                                    className="fa fa-info-circle mr-2"
                                    aria-hidden="true"/></a>
                                    <UncontrolledPopover placement="auto"
                                                         target="UncontrolledPopover"
                                                         trigger="legacy">
                                        <PopoverBody className={"text-info"}>The
                                            discounted amount here may
                                            differ from the actual discount
                                            calculation, as some of the product's
                                            components can't be
                                            discounted.</PopoverBody>
                                    </UncontrolledPopover>
                                    {(products || [])
                                        .filter(p => (!isEmpty(p.items) && p.pricing.discVal > 0))
                                        .map(p => {
                                            return <Row key={p.code}>
                                                <Col xl={9} lg={9} md={9} sm={9}
                                                     xs={9}
                                                     className="text-left">
                                                    <small>
                                                        <i
                                                            className="fa fa-caret-right mr-2"
                                                            aria-hidden="true"/>
                                                    </small>
                                                    {p.name}&nbsp;@
                                                    {
                                                        p.code === 'CUST'
                                                            ? p.pricing.discount
                                                            : discountByProductCode[p.code].discount
                                                    }%
                                                </Col>
                                                <Col xl={3} lg={3} md={3} sm={3}
                                                     xs={3}
                                                     className="text-right">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={p.pricing.discVal}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </Col>
                                            </Row>
                                        })}
                                    <Row className={"mt-1"}>
                                        <Col xl={9} lg={9} md={9} sm={9} xs={9}
                                             className="text-left">
                                            Total discount
                                        </Col>
                                        <Col xl={3} lg={3} md={3} sm={3} xs={3}
                                             className="text-right border-top pt-1">
                                            <strong>
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={totalDiscountVal}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </strong>
                                        </Col>
                                    </Row>
                                </td>

                            </tr>
                            : null
                    }
                    <tr>
                            <td>Delivery Method
                            {
                                (freight.isLoadingMetroPostcodeCheck || freight.isLoadingAllRules)
                                    ? <Spinner className={"ml-2"} size={"sm"}
                                               color={"primary"}/>
                                    : null
                            }
                            <div className="mt-1">
                                <Input name="freightMethod"
                                       onChange={(e) => handleOrderChange("freightMethod", e.target.value,)}
                                       value={freight.selectedMethodOption}
                                       type={"select"}>
                                    <option value={""}>Select method
                                    </option>
                                    {(freight.methodOptions || []).map((option, index) => {
                                        return <option
                                            value={option.methodKey}
                                            key={index}>{option.methodLabel}</option>
                                    })}
                                </Input>
                            </div>
                            {
                                (freight.selectedMethodOption === FREIGHT_RULE_FREIGHT_OVERIDE)
                                    ? <div className={"mt-1"}>
                                        <Input
                                            name="freightOverrideCourierName"
                                            onChange={(e) => handleOrderChange("freightOverrideCourierName", e.target.value,)}
                                            value={freight.freightOverrideCourierName}
                                            type={"select"}>
                                            <option value={""}>Select courier company
                                            </option>
                                            {(freight.couriers || []).map((option, index) => {
                                                return <option
                                                    value={option.courier}
                                                    key={index}>{option.courier}</option>
                                            })}
                                        </Input>
                                        <div className="mt-1">
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-usd" aria-hidden="true" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    name="freightOverrideCourierCharge"
                                                    onChange={(e) => handleOrderChange("freightOverrideCourierCharge", e.target.value,)}
                                                    value={freight.freightOverrideCourierCharge === 0 ? "" : freight.freightOverrideCourierCharge}
                                                    placeholder={"Freight Charges"}
                                                    type={"number"}>
                                                </Input>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    : null
                            }
                        </td>
                        <th className="text-right align-middle">
                            <NumberFormat
                                prefix={'$'}
                                value={order.pricing.freight ? order.pricing.freight.charge : 0}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    {
                        order.pricing.packagingAndHandlingCharges
                            ? <tr>
                                <td>Packaging & Handling Charges</td>
                                <th className="text-right">
                                    <NumberFormat
                                        prefix={'$'}
                                        value={order.pricing.packagingAndHandlingCharges}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}/>
                                </th>
                            </tr>
                            : null
                    }

                    <tr>
                        <td>GST</td>
                        <th className="text-right">
                            <NumberFormat
                                prefix={'$'}
                                value={tax}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    <tr className="table-secondary">
                        <th>Order total</th>
                        <th className="text-right">
                            <NumberFormat
                                prefix={'$'}
                                value={total}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}/>
                        </th>
                    </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}