import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Row, Col, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, CardFooter, FormText, Form, Alert
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import StockInventoryStatusService from '../../services/StockInventoryStatusService';
import StockInventoryQuickLinks from '../../components/WINOInventory/StockInventoryQuickLinks';

//Manage-StockInventory-Status
export default class ManageStockInventoryStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prodCode: '',
            stockInventoryStatus: {
                id: 0,
                qoh: 0,
                qoo: 0,
                allocated: 0,
                free: 0
            },
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        let prodCode = '';
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            prodCode = searchParams.prodCode;
            this.setState({ prodCode }, () => { this.refresh() })
        }
    }


    refresh() {
        if (this.state.prodCode) {
            this.setState({ loading: true })
            StockInventoryStatusService.getOnestockInventoryStatusByStock(this.state.prodCode).then(response => {
                if (response.status === 200 || response.status === "200") {
                    let stockInventoryStatus = response.data;

                    if (response.data === null) {
                        stockInventoryStatus = {
                            id: 0,
                            qoh: 0,
                            qoo: 0,
                            allocated: 0,
                            free: 0
                        }
                    }
                    this.setState({ stockInventoryStatus, loading: false });
                }
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleCancel() {
        this.props.history.push('/inventory/stock-inventory');
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { stockInventoryStatus } = this.state;

        stockInventoryStatus[name] = value;
        this.setState({ stockInventoryStatus });
    }

    validateForm(stockInventoryStatus) {
        let formErrors = {};
        let valid = true;

        if (stockInventoryStatus.qoh === null || (stockInventoryStatus.qoh === undefined)) {
            formErrors.qoh = "Please enter QOH";
            valid = false;
        }

        if (stockInventoryStatus.qoo === null || (stockInventoryStatus.qoo === undefined)) {
            formErrors.qoo = "Please enter QOO";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.stockInventoryStatus };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });

            StockInventoryStatusService.saveOrUpdateStockInventoryStatus(this.state.prodCode, req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.stockInventoryStatus.id) {
                        toast.success("Inventory Status saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        toast.success("Inventory Status updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    this.setState({ stockInventoryStatus: response.data, saving: false });
                }
            }).catch(error => {
                this.setState({ saving: false });
                console.log(error.data);
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }



    render() {
        let { stockInventoryStatus, loading, prodCode, formErrors } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={this.props.history.goBack}>Stock Inventory Status</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!prodCode ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>
                <Alert color="light">
                    <div className="text-right">
                        <StockInventoryQuickLinks
                            showInventoryStatus={false}
                            showInventoryPrice={true}
                            showInventoryCost={true}
                            prodCode={prodCode}
                        />
                    </div>
                </Alert>
                <Card>
                    <CardHeader>
                        <h5>Current Stock Status</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>QOH<small>(Quantity On Hand)</small></Label>
                                            <Input
                                                invalid={formErrors.qoh != null}
                                                name="qoh"
                                                type="number"
                                                value={stockInventoryStatus.qoh}
                                                onChange={this.handleChange}
                                                placeholder="QOH ..." />
                                            <FormText color="danger">{formErrors.qOH}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Allocated Qty</Label>
                                            <Input
                                                invalid={formErrors.allocated != null}
                                                name="allocated"
                                                type="number"
                                                value={stockInventoryStatus.allocated}
                                                onChange={this.handleChange}
                                                placeholder="Allocated Qty..." />
                                            <FormText color="danger">{formErrors.allocated}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Free Qty<small>(QOO - Allocated Qty)</small></Label>
                                            <Input
                                                disabled={true}
                                                name="free"
                                                type="number"
                                                value={stockInventoryStatus.free} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>QOO<small>(Quantity on orders)</small></Label>
                                            <Input
                                                invalid={formErrors.qoo != null}
                                                name="qoo"
                                                type="number"
                                                value={stockInventoryStatus.qoo}
                                                onChange={this.handleChange}
                                                placeholder="QOO..." />
                                            <FormText color="danger">{formErrors.qoo}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' size={"sm"} onClick={this.handleSubmit} disabled={this.state.saving} >
                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save Inventory Status</Button>{' '}
                            <Button color='secondary' size={"sm"} onClick={this.props.history.goBack}>
                                <i className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                Back</Button>
                        </div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
