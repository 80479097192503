import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table,} from 'reactstrap';
import SalesOrderJobDetailModal from '../modal/SalesOrderJobDetailModal';
import ProductionJobOrderStatusBulkChangeModal from './ProductionJobOrderStatusBulkChangeModal';
import UserService from '../../services/UserService';
import OrderService from '../../services/OrderService';
import dispatchOrderService from '../../services/DispatchOrderService';
import {toast} from 'react-toastify';
import {handleErrorMessage} from '../../services/CommonService';

export default class DispatchOrderItemsProductDetailModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            salesOrderJobStatuses: [],
            isOpenJobStatusModal: false,
            isSalesOrderLoading: false,
            salesOrderJobs: [],
            order: this.props.order,
            isUpdateStatusAllowed: ""
        };
        this.orderService = new OrderService();
        this.userService = new UserService();
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.handleSalesOrderJob = this.handleSalesOrderJob.bind(this);
        this.toggleJobStatusModal = this.toggleJobStatusModal.bind(this);
        this.openJobStatusModal = this.openJobStatusModal.bind(this);
        this.getSalesOrderJobs = this.getSalesOrderJobs.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
    }

    componentDidMount() {
        let currentUserRole = this.userService.getCurrentUserRole();
        this.setState({isUpdateStatusAllowed: currentUserRole === "Admin"});

        this.getSalesOrderJobStatuses();
        this.getSalesOrderJobs();
    }

    componentWillReceiveProps(nextprops) {
        if (this.state.order !== nextprops.order) {
            this.setState({order: nextprops.order}, () => {
                this.getSalesOrderJobs();
            })
        }
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let salesOrderJobStatuses = response.data;
            salesOrderJobStatuses = salesOrderJobStatuses.filter(x => x.id > 90);
            this.setState({salesOrderJobStatuses})
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getSalesOrderJobs() {
        let {order} = this.state;
        dispatchOrderService.getOrderJobDetails(order.ordNum).then(response => {
            this.setState({salesOrderJobs: response.data, isSalesOrderLoading: false});
        }).catch(error => {
            this.setState({isSalesOrderLoading: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    handleSalesOrderJob(salesOrderJobRecords) {
        this.setState({salesOrderJobs: salesOrderJobRecords});
    }

    toggleJobStatusModal(data) {
        this.setState({isOpenJobStatusModal: data});
    }

    openJobStatusModal() {
        let {salesOrderJobs} = this.state;
        let selected = salesOrderJobs.filter(x => x.isSelected);
        this.setState({isOpenJobStatusModal: true, selectedRecord: selected});
    }

    refreshAgain() {
        this.setState({salesOrderJobs: []}, () => {
            this.props.toggle(!this.props.isOpen, null, true);
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {isOpenJobStatusModal, salesOrderJobs, isSalesOrderLoading, order, isUpdateStatusAllowed} = this.state;
        let {orderDetails} = order;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    #{order.ordNum}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <h6><strong>Product</strong></h6>
                        <Table striped hover size={"sm"} responsive className={"mb-0"}>
                            <caption>
                                <span className={"float-left"}>This order has {orderDetails.length} product</span>
                            </caption>
                            <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Product</th>
                                <th className="text-right">Qty</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (orderDetails || []).map((data, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.product}</td>
                                        <td className="text-right">{data.qty}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                    </div>
                    <br/>
                    {salesOrderJobs.length > 0 ?
                        <SalesOrderJobDetailModal salesOrderJobs={salesOrderJobs}
                                                  isLoading={isSalesOrderLoading}
                                                  handleSalesOrderJob={this.handleSalesOrderJob}
                                                  isUpdateStatusAllowed = {isUpdateStatusAllowed}
                        /> : null}
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        {(isUpdateStatusAllowed && salesOrderJobs.some(s => s.isSelected))
                            ? <Button color={"primary"} size="sm" onClick={this.openJobStatusModal} className={"mr-2"}>
                                <i className={"fa fa-pencil mr-2"}/>Update Status
                            </Button>
                            : null
                        }
                        <Button color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>Close</Button>
                    </div>
                </ModalFooter>
                {isOpenJobStatusModal
                    ?
                    <ProductionJobOrderStatusBulkChangeModal
                        isOpen={isOpenJobStatusModal}
                        toggle={this.toggleJobStatusModal}
                        refreshAgain={this.refreshAgain}
                        salesJobOrder={this.state.selectedRecord}
                        salesOrderJobStatuses={this.state.salesOrderJobStatuses}
                        ordNum={order.ordNum}
                    />
                    : null
                }
            </Modal>
        )
    }
}