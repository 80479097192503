import React, {Component} from 'react';
import {
    Button,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
    Table
} from 'reactstrap';
import OrderService from '../../services/OrderService';
import UploadFiles from "../../components/attachment/UploadFiles";
import {toast, ToastContainer} from 'react-toastify';
import {isEmpty} from 'lodash';
import salesOrderService from '../../services/WINOInventory/SalesOrderService';
import {handleErrorMessage} from "../../services/CommonService";
import NumberFormat from "react-number-format";

export default class OrderGatewayKeywaySalesOrderItemsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: {},
            isLoadingStatusUpdate: "",
            debtorInvoiceOrdNum: this.props.debtorInvoiceOrdNum,
            error: false,
            loading: false,
            salesOrderJobs:[],
        };

        this.refresh = this.refresh.bind(this);
        this.takeActionOnOrder = this.takeActionOnOrder.bind(this);
        this.orderService = new OrderService();
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.refresh(this.props.SalesOrderID);
    }

    takeActionOnOrder(option) {
        this.setState({isLoadingStatusUpdate: option});
        salesOrderService.updateKeywaySalesOrder(this.state.data.id, option).then(response => {

            this.setState({isLoadingStatusUpdate: ""});
            toast.success("Order updated!", {
                position: toast.POSITION.TOP_RIGHT
            });
            this.props.toggle(!this.props.isOpen, true);

        }).catch(error => {
            this.setState({isLoadingStatusUpdate: ""});
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    refresh(SalesOrderID) {
        if (SalesOrderID) {
            //get details here
            this.setState({ isLoading: true });
            salesOrderService.getKeywaySalesOrder(SalesOrderID).then(response => {
                if (response.data) {
                    this.setState({ data: response.data, isLoading: false });
                }
            }).catch(error => {
                this.setState({ isLoading: false });
                console.log(error);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }

        if (this.state.debtorInvoiceOrdNum) {
            this.getJobInfo();
        }
    }

    handleChange(DebtorInvoiceOrdNum) {
        this.setState({ debtorInvoiceOrdNum: DebtorInvoiceOrdNum });
    }

    getJobInfo() {
        this.setState({loading:true});
        salesOrderService.getSaleOrderJobInfoProductLink(this.state.debtorInvoiceOrdNum).then(response => {
            this.setState({ salesOrderJobs: response.data, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    handleClick() {
        if (this.state.debtorInvoiceOrdNum === null || this.state.debtorInvoiceOrdNum === undefined || this.state.debtorInvoiceOrdNum === '') {
            this.setState({ error: true });
            return;
        }
        this.setState({ isLoading: true, error: false });
        this.getJobInfo();
        salesOrderService.getKeywaySalesOrderByDebtorInvoiceOrdNum(this.state.debtorInvoiceOrdNum).then(response => {
            if (response.data) {
                this.setState({ data: response.data, isLoading: false });
            }
        }).catch(error => {
            this.setState({ isLoading: false, data: {} });
            console.log(error);
            {/*toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });*/}
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let { data, isLoading, loading, isLoadingStatusUpdate, error, debtorInvoiceOrdNum, salesOrderJobs} = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">Order No. {debtorInvoiceOrdNum}</h5>

                </ModalHeader>
                <ModalBody>
                    {
                        isLoading ?
                            <Spinner color={"primary"}/>
                            :
                            <div>
                                {
                                    data.notes
                                        ? <div>
                                            <h6>Notes</h6>
                                            <div>{data.notes}</div>
                                            <hr/>
                                        </div>
                                        : <div>
                                            <h6>Notes</h6>
                                            <small>No notes available.</small>
                                            <hr />
                                        </div>
                                }

                                {
                                    loading ?
                                        <Spinner color={"primary"} />
                                        :
                                        <div>
                                            {!isEmpty(salesOrderJobs) ?

                                                <Table size={"sm"} striped responsive bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr.No</th>
                                                            <th>Product Group</th>
                                                            <th>Prod Code</th>
                                                            <th>Product Description</th>
                                                            <th className="text-right">Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(salesOrderJobs || []).map((salesOrderJob, index) =>
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{salesOrderJob.productGroupName}</td>
                                                                <td>{salesOrderJob.prodCode}</td>
                                                                <td>{salesOrderJob.productDescription}</td>
                                                                <td className="text-right">
                                                                    {salesOrderJob.productionLine === "COMPONENTS" || salesOrderJob.productionLineName === "COMPONENTS" ?
                                                                        <>
                                                                            <NumberFormat value={salesOrderJob.itemCount}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                thousandSeparator={true}
                                                                                prefix={''} />
                                                                            {salesOrderJob.itemCount > 1 ? " lines" : " line"}
                                                                        </>
                                                                        :
                                                                        <NumberFormat value={salesOrderJob.qty}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            thousandSeparator={true}
                                                                            prefix={''} />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </Table>
                                                :
                                                <h5 className="text-center">Order's jobs information not available.</h5>
                                            }
                                        </div>
                                }
                            </div>
                    }
                </ModalBody>
                {/*<ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"success"}
                                disabled={isLoadingStatusUpdate === "Approved"}
                                size={"sm"} onClick={() => this.takeActionOnOrder("Approved")}>
                            {isLoadingStatusUpdate === "Approved" ?
                                <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                : <i className="fa fa-check-circle-o mr-2" aria-hidden="true"/>
                            }
                            {isLoadingStatusUpdate === "Approved" ? "Approving" : "Approve"}
                        </Button>
                        <Button color={"danger"} className={"ml-2"}
                                size={"sm"} onClick={() => this.takeActionOnOrder("Rejected")}>
                            {isLoadingStatusUpdate === "Rejected" ?
                                <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                : <i className="fa fa-ban mr-2" aria-hidden="true"/>
                            }
                            {isLoadingStatusUpdate === "Rejected" ? "Rejecting" : "Reject"}
                        </Button>
                    </div>
                </ModalFooter>*/}
                <ToastContainer/>
            </Modal>
        )
    }
}