import React, {Component, useState} from 'react';
import {Card, CardBody, CardHeader, PopoverBody, PopoverHeader, Spinner, Table, UncontrolledPopover} from "reactstrap";


import classnames from "classnames";
import DebtorDeliveryPreference from "./DebtorDeliveryPreferenceModal";

export default function CustomerPreferencePreview(props) {

    const [isOpenDeliveryMethodModal, toggleDeliveryMethodModal] = useState(false);

    let { accountID, isLoading, printStatement, emailStatement, courier, hasDebtorWritePrivilege, refreshDebtorCourier, printPackSlip,
        printInvoice, printDispatchLabel, emailInvoice, emailDispatchNotice } = props;


    return (
        <div>
            <Card>
                <CardHeader>
                    <h6 className={"mb-0"}>Freight Preferences {
                        hasDebtorWritePrivilege
                            ? <span style={{float: "right"}}>
                                <a href={"javascript:void(0)"}
                                   onClick={() => toggleDeliveryMethodModal(true)}
                                >
                            <i className="fa fa-pencil" aria-hidden="true"/>
                        </a></span>
                            : null
                    }</h6>

                </CardHeader>
                <CardBody>
                    {isLoading
                        ? <Spinner color="primary"/>
                        :
                        <div>
                            {/*<p className="mb-0"><strong>Statement options</strong></p>
                            <div>
                                <i className={classnames("fa", "mr-2", {
                                        "fa-square-o": !printStatement,
                                        "fa-check-square-o": printStatement,
                                    }
                                )}/>Print statement
                                <a href={"javascript:void(0)"}
                                   className="ml-2"
                                   id={"printStatement"}>
                                    <i className="fa fa-info-circle" aria-hidden="true"/>
                                </a>
                                <UncontrolledPopover placement="auto"
                                                     target={"printStatement"}
                                                     trigger="legacy">

                                    <PopoverBody>A paper copy of the statement will be sent to the main
                                        address on your account.</PopoverBody>
                                </UncontrolledPopover>
                            </div>

                            <div>
                                <i className={classnames("fa", "mr-2", {
                                        "fa-square-o": !emailStatement,
                                        "fa-check-square-o": emailStatement,
                                    }
                                )}/>Email statement
                                <a href={"javascript:void(0)"}
                                   className="ml-2"
                                   id={"emailStatement"}>
                                    <i className="fa fa-info-circle" aria-hidden="true"/>
                                </a>
                                <UncontrolledPopover placement="auto"
                                                     target={"emailStatement"}
                                                     trigger="legacy">
                                    <PopoverBody>A digital copy of the statement will be sent to the main
                                        email.</PopoverBody>
                                </UncontrolledPopover>
                            </div>*/}

                            {/*<br/>*/}
                            <Table size={"sm"} responsive className={"mb-0"}>
                                <tbody>
                                    <tr>
                                        <th>Delivery method</th>
                                        <td>{courier ? courier.courier : "No preference"}</td>
                                    </tr>
                                    <tr>
                                        <th>Print Pack Slip</th>
                                        <td>{printPackSlip ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <th>Print Invoice</th>
                                        <td>{printInvoice ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <th>Print Dispatch Label</th>
                                        <td>{printDispatchLabel ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <th>Email Invoice</th>
                                        <td>{emailInvoice ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <th>Email Dispatch Notice</th>
                                        <td>{emailDispatchNotice ? "Yes" : "No"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    }
                </CardBody>
            </Card>

            {
                isOpenDeliveryMethodModal
                    ? <DebtorDeliveryPreference
                        accountID={accountID}
                        isOpen={isOpenDeliveryMethodModal}
                        toggleModal={toggleDeliveryMethodModal}
                        refresh={refreshDebtorCourier}
                    />
                    : null
            }

        </div>
    )
}