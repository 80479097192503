import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Spinner, Table } from 'reactstrap';
import NumberFormat from "react-number-format";
import StockService from '../../../services/StockService';
import { toast, ToastContainer } from 'react-toastify';
import { getYearFromYearMonth } from "../../../services/CommonService";

export default class StockEnquiryPageSalesHistoryTabSoldUnitsMonthly extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stock: {},
            sales: [],
            avgThreeMonths: 0,
            avgSixMonths: 0,
            avgTwelveMonths: 0,
            showAverage: props.showAverage,
            isLoading: false
        };
        this.stockService = new StockService();
        this.getStockSoldUnitsYearMonthSummary = this.getStockSoldUnitsYearMonthSummary.bind(this);
    }

    componentDidMount() {
        if (this.props.stock && this.props.stock.prodCode) {
            let { stock } = this.state;
            if (!stock || stock.prodCode !== this.props.stock.prodCode) {
                this.setState({ stock: this.props.stock }, () => {
                    this.getStockSoldUnitsYearMonthSummary();
                })
            }
        }
    }

    getStockSoldUnitsYearMonthSummary() {
        let { sales, stock, avgThreeMonths, avgSixMonths, avgTwelveMonths, showAverage } = this.state;
        if (stock.prodCode) {
            this.setState({ isLoading: true });
            this.stockService.getStockSoldUnitsYearMonthSummary(stock.prodCode).then(response => {
                sales = response.data;
                if (showAverage) {
                    avgThreeMonths = Math.round(Math.abs(sales.slice(-3).reduce((sum, sale) => sum + sale.qty, 0)) / 3);
                    avgSixMonths = Math.round(Math.abs(sales.slice(-6).reduce((sum, sale) => sum + sale.qty, 0)) / 6);
                    avgTwelveMonths = Math.round(Math.abs(sales.reduce((sum, sale) => sum + sale.qty, 0)) / 12);
                }
                this.setState({ isLoading: false, sales: response.data, avgThreeMonths, avgSixMonths, avgTwelveMonths });
            }).catch(error => {
                this.setState({ isLoading: false });
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    render() {
        let { sales, isLoading, avgThreeMonths, avgSixMonths, avgTwelveMonths, showAverage } = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Sold Units by Month</h6>
                    </CardHeader>
                    <CardBody>
                        {
                            isLoading ? <Spinner color="primary" />
                                : <Table size={"sm"} responsive className={"mb-0"}>
                                    <tbody>
                                        {
                                            (sales || []).map((sale, saleIndex) => {
                                                return <tr key={saleIndex}>
                                                    <td className="text-left">{sale.year}</td>
                                                    <td className="text-center">{getYearFromYearMonth(sale.month, "MMM")}</td>
                                                    <th className="text-right">
                                                        <NumberFormat
                                                            value={sale.qty}
                                                            displayType={'text'}
                                                            decimalScale={1}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                            allowNegative={false} />
                                                    </th>
                                                </tr>
                                            })
                                        }
                                        {
                                            showAverage ?
                                                <>
                                                    <tr>
                                                        <th colSpan={2}>Average last 3 months</th>
                                                        <th className="text-right">
                                                            <NumberFormat
                                                                value={avgThreeMonths}
                                                                displayType={'text'}
                                                                decimalScale={1}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                allowNegative={false} />
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={2}>Average last 6 months</th>
                                                        <th className="text-right">
                                                            <NumberFormat
                                                                value={avgSixMonths}
                                                                displayType={'text'}
                                                                decimalScale={1}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                allowNegative={false} />
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={2}>Average last 12 months</th>
                                                        <th className="text-right">
                                                            <NumberFormat
                                                                value={avgTwelveMonths}
                                                                displayType={'text'}
                                                                decimalScale={1}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                allowNegative={false} />
                                                        </th>
                                                    </tr>
                                                </>
                                                : null
                                        }
                                    </tbody>
                                </Table>
                        }
                    </CardBody>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}