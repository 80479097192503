import React, {Component} from 'react';
import SalesQuoteService from "../../../services/sales/SalesQuoteService";
import UserService from "../../../services/UserService";


import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader, Input, InputGroup, InputGroupAddon, FormText, Label
} from 'reactstrap';
import {toast} from 'react-toastify';
import {handleErrorMessage} from "../../../services/CommonService";


export default class QuoteCancellationModal extends Component {


    constructor(props) {
        super(props);

        this.state = {
            cancelReasons: [],
            selected: {
                cancelReasonID: "",
                username: ""
            },
            loading: {cancel: false}
        };

        this.salesQuoteService = new SalesQuoteService();
        this.userService = new UserService();
        this.handleSelectReason = this.handleSelectReason.bind(this);
        this.cancelQuotes = this.cancelQuotes.bind(this);
        this.getUsername = this.getUsername.bind(this);

    }

    componentDidMount() {
        this.getUsername();
        this.getSalesOrderCancelReasons();
    }

    getUsername() {
        this.userService.getUser().then(user => {
            let {selected} = this.state;
            selected.username = user.username;
            this.setState({selected});
        }, error => {
            console.log(error);
        });
    }

    getSalesOrderCancelReasons() {
        this.salesQuoteService.fetchSalesOrderCancelReasons().then(response => {
            let reasons = response.data;
            this.setState({cancelReasons: reasons});
        });
    }

    handleSelectReason(e) {
        const {name, value} = e.target;

        let {selected} = this.state;
        selected[name] = value;

        this.setState({selected});
    }

    cancelQuotes() {

        let {selectedQuotes} = this.props;
        let {selected, loading} = this.state;
        if (!selectedQuotes.length > 0) {
            return;
        }

        let req = this.prepareRequestBody(selectedQuotes, selected);

        loading.cancel = true;
        this.setState({loading});

        let commonMsg =  selectedQuotes.length === 1 ? "Quote-" + selectedQuotes[0].ordNum : "All quotes";

        this.salesQuoteService.cancelQuotes(req).then(response => {
            loading.cancel = false;
            selected.cancelReasonID = "";
            this.setState({loading, selected});
            this.props.toggleModel();
            this.props.refresh(false);
            toast.success(commonMsg+ " cancelled successfully.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            loading.cancel = false;
            this.setState({loading});
            this.props.toggleModel();
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }


    prepareRequestBody(selectedQuotes, selected) {
        let req = [];
        for (let i in selectedQuotes) {
            req.push({
                ordNum: selectedQuotes[i].ordNum,
                cancelReasonID: selected.cancelReasonID,
                username: selected.username
            });
        }
        return req;
    }

    render() {

        let {selectedQuotes, isOpen} = this.props;

        let {cancelReasons, selected, loading} = this.state;


        return (


            <Modal isOpen={isOpen} className="modal-lg" size="lg"
                   scrollable={false}
                   toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    <h6>{selectedQuotes.length > 1 ? "Quotes" : "Quote"}&nbsp;({selectedQuotes.length}&nbsp;
                        selected)</h6>
                </ModalHeader>
                <ModalBody>
                    <div className={"text-center"}>
                        <h4>Please select a reason for cancelling this quote</h4>
                    </div>
                    <Label>Reason*</Label>
                    <InputGroup>
                        <Input
                            className="form-control"
                            onChange={this.handleSelectReason}
                            type="select"
                            name="cancelReasonID"
                            value={selected.cancelReasonID}
                            placeholder="write state here...">
                            <option value="" disabled={true}>Please select</option>
                            ;
                            {(cancelReasons) && cancelReasons.map((reason, index) => {
                                return <option key={index}
                                               value={reason.reasonID}>{reason.description}</option>;

                            })}
                        </Input>
                        <InputGroupAddon addonType="append">
                            <Button color={"danger"}
                                    className={"btn"}
                                    disabled={selected.cancelReasonID === "" || loading.cancel}
                                    onClick={this.cancelQuotes}>
                                {loading.cancel ? "Cancelling" : "Cancel"}&nbsp;({selectedQuotes.length}&nbsp;
                                selected)&nbsp;{selectedQuotes.length > 1 ? "Quotes" : "Quote"}
                            </Button></InputGroupAddon>
                    </InputGroup>
                    <br/>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <span>
                            <small>*You will not be able to revert this</small>
                        </span>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}