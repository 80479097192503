import React, {Component} from 'react';

import {
    Modal,
    ModalBody,
    ModalHeader,
    Table,
    Button, Spinner
} from 'reactstrap'
import UserService from "../../services/UserService";
import {EventBus} from "./../../components/events/event"
import {findIndex, handleErrorMessage} from '../../services/CommonService';

export default class RoleActivation extends Component {
    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = {
            loading: {
                roles: false
            },
            user: {},
            roles: [],
            showAccountId: false
        };

        this.refresh = this.refresh.bind(this);
        this.activateRole = this.activateRole.bind(this);

        EventBus.subscribe('update-userRoles', (event) => this.refresh())

    }


    refresh() {
        let user = this.userService.getLoggedInUser();
        let {loading} = this.state;
        loading.roles = true;
        this.setState({loading, user});

        this.userService.listUserRoles(user.userId).then(response => {
            loading.roles = false;
            let roles = response.data;
            for (let i in roles) {
                roles[i].activating = false;
            }
            this.setState({roles: roles, loading});

        }).catch(error => {
            alert(handleErrorMessage(error));
            loading.roles = false;
            this.setState({loading});
        })
    }

    componentDidMount() {
        this.refresh();
    }


    activateRole(role) {
        let {roles} = this.state;

        let index = findIndex(roles, "id", role.id);
        if (index > -1) {
            roles[index].activating = true;
            this.setState({roles});

            this.userService.setPrimaryRole(role.id).then(response => {
                this.refresh();
                EventBus.publish('login-logout', {});
                this.userService.refreshLocalStorage();
            }).catch(error => {
                roles[index].activating = false;
                this.setState({roles});
                alert(handleErrorMessage(error));
            })
        }
    }

    render() {
        let {roles, loading, showAccountId} = this.state;
        return (
            <div>

                <Modal isOpen={this.props.isOpen} size="lg"
                       scrollable={false}
                       toggle={this.props.toggleModel}>
                    <ModalHeader toggle={this.props.toggleModel}>
                        Activate Role
                    </ModalHeader>
                    <ModalBody>
                        <div className={"text-center"}>
                            <Table hover responsive size="sm">
                                <thead>
                                <tr>
                                    <th>Role Name</th>
                                    <th>Customer Account ID</th>
                                    <th>Customer Account Name</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(roles || []).map((role, index) =>
                                    <tr key={index}>
                                        <td>{role.roleName}</td>
                                        <td>{role.account || "N/A"}</td>
                                        <td>{role.company || "N/A"}</td>
                                        <td><Button outline={!role.isPrimary} color={"success"}
                                                    onClick={() => this.activateRole(role)}>
                                            {role.isPrimary ? <i className="fa fa-check fa-fw mr-2"/> : null}
                                            {role.activating ? <Spinner color={"success"} size={"sm"}/> : null}
                                            {role.activating ? "Activating" : (role.isPrimary ? "Active" : "Activate")}
                                        </Button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}