import React, {Component} from 'react';
import {Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Table} from 'reactstrap';
import {cloneDeep, isEmpty} from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import {
    PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
    PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING,
    STOCK_GROUP_HOSPITAL_RANGE_CODES
} from "../../../../../../store/AppConstants";
import hospitalRangePartUtil from "./HospitalRangePartUtil";
import NumberFormat from "react-number-format";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import SearchStock from "../../../../../search/SearchStock";

export default class HospitalRangeCustom extends Component {

    render() {
        let {product, itemSummaryByItemType, handleChange, handleItemAction, currentUser, order} = this.props;
        let customItemSummary = itemSummaryByItemType[PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM];
        let fittingItemSummary = itemSummaryByItemType[PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING];

        let price = customItemSummary.price + fittingItemSummary.price;
        let discVal = customItemSummary.discVal + fittingItemSummary.discVal;
        return (
            <div>
                <div>
                    <Table bordered responsive>
                        <thead>
                        <tr>
                            <th className="text-center" style={{width: 165}}>Location</th>
                            <th className="text-center" style={{width: 150}}>Track/Tube Colour</th>
                            <th className="text-center" style={{width: 300}}>Product</th>
                            <th className="text-center" style={{width: 160}}>Length(mm)</th>
                            <th className="text-center" style={{width: 170}}>Angle&deg;</th>
                            <th className="text-center" style={{width: 105}}>Quantity</th>
                            <th className="text-center" style={{width: 50}}>Price</th>
                            <th className="text-center" style={{width: 50}}>Discount</th>
                            <th className="text-center" style={{width: 50}}>Total</th>
                            <th className="text-center" style={{width: 120}}>Action</th>
                        </tr>
                        </thead>
                        {
                            product.custom.configuration.shape.options.map((shapeOption, shapeOptionIndex) => {
                                return <tbody key={shapeOptionIndex}>
                                <tr>
                                    <td className="align-middle" colSpan={9}>
                                        <img className="img-fluid rounded d-print-inline mr-2"
                                             src={shapeOption.description}
                                             style={{maxHeight: 125}}/>
                                        <span>{shapeOption.condition1}</span>
                                    </td>
                                    <td className="align-middle text-center">
                                        <Button
                                            color={"primary"}
                                            className={"mb-2 ml-1"}
                                            onClick={() => handleItemAction("new", -1, {
                                                prodCode: "KW-HOSPITAL",
                                                itemType: PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM,
                                                shape: shapeOption.optionKey
                                            })}>
                                            <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                                            Add
                                        </Button>
                                    </td>

                                </tr>
                                {(customItemSummary.items || []).filter(item => item.configuration.shape.selected.value.value === shapeOption.optionKey).map((item, itemIndex) => {
                                    return <React.Fragment key={itemIndex}>
                                        <tr
                                            className={classnames({"table-danger": !item.isValid})}>
                                            <td className="align-middle">
                                                <Input type='text'
                                                       placeholder={"Location"}
                                                       value={item.location.selected.value}
                                                       name='location'
                                                       onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                />
                                                {
                                                    item.location.formError.isValid
                                                        ? null
                                                        : <Badge color="danger">
                                                            {item.location.formError.message}
                                                        </Badge>
                                                }
                                                {
                                                    item.location.formError.isWarning
                                                        ? <Badge color="warning">
                                                            {item.location.formError.message}
                                                        </Badge>
                                                        : null
                                                }
                                            </td>
                                            <td className="align-middle">
                                                <Select
                                                    onChange={(selection) => handleChange(selection, "item_colour", hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                    value={item.configuration.colour.selected.value}
                                                    options={item.configuration.colour.finalOptions}
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <Select
                                                    onChange={(selection) => handleChange(selection, "item_part", hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                    value={item.configuration.partRange.selected.value}
                                                    options={item.configuration.partRange.finalOptions}
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <div>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>A</InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type='number'
                                                               placeholder={"length"}
                                                               value={item.lengthA.selected.value}
                                                               name='lengthA'
                                                               onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                               onFocus={(event) => event.target.select()}
                                                        />
                                                    </InputGroup>
                                                </div>
                                                {
                                                    item.lengthB.isSelectable
                                                        ? <div className={"mt-2"}>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>B</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type='number'
                                                                       placeholder={"length"}
                                                                       value={item.lengthB.selected.value}
                                                                       name='lengthB'
                                                                       onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                                       onFocus={(event) => event.target.select()}
                                                                />
                                                            </InputGroup>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.lengthC.isSelectable
                                                        ? <div className={"mt-2"}>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>C</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type='number'
                                                                       placeholder={"length"}
                                                                       value={item.lengthC.selected.value}
                                                                       name='lengthC'
                                                                       onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                                       onFocus={(event) => event.target.select()}
                                                                />
                                                            </InputGroup>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.lengthABC.formError.isValid
                                                        ? null
                                                        : <Badge color={"danger"}>
                                                            {item.lengthABC.formError.message}
                                                        </Badge>
                                                }
                                            </td>
                                            <td className="align-middle text-center">
                                                {
                                                    item.angle.isSelectable
                                                        ? <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>A-B</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type='number'
                                                                   placeholder={"angle"}
                                                                   value={item.angle.selected.value}
                                                                   name='angle'
                                                                   onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                                   onFocus={(event) => event.target.select()}
                                                            />
                                                        </ InputGroup>
                                                        : <span>N/A</span>
                                                }
                                                {
                                                    item.angle.formError.isValid
                                                        ? null
                                                        : <Badge color={"danger"}>
                                                            {item.angle.formError.message}
                                                        </Badge>
                                                }
                                                {
                                                    item.angle2.isSelectable
                                                        ? <div className={"mt-2"}>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>B-C</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type='number'
                                                                       placeholder={"angle2"}
                                                                       value={item.angle2.selected.value}
                                                                       name='angle2'
                                                                       onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                                       onFocus={(event) => event.target.select()}
                                                                />
                                                            </ InputGroup>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.angle2.formError.isValid
                                                        ? null
                                                        : <Badge color={"danger"}>
                                                            {item.angle2.formError.message}
                                                        </Badge>
                                                }
                                            </td>
                                            <td className="align-middle">
                                                <Input type='number'
                                                       placeholder={"quantity"}
                                                       value={item.quantity.selected.value}
                                                       name='quantity'
                                                       onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, item.customID))}
                                                       onFocus={(event) => event.target.select()}
                                                />
                                                {
                                                    item.quantity.formError.isValid
                                                        ? null
                                                        : <Badge color={"danger"}>
                                                            {item.quantity.formError.message}
                                                        </Badge>
                                                }
                                            </td>
                                            <td className="align-middle text-right">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-right">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.discVal, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-right">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price - item.pricing.discVal, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <Button color={"link"}
                                                        title={"click here to delete"}
                                                        onClick={() => handleItemAction("delete", hospitalRangePartUtil.findItemIndex(product, item.customID), null)}>
                                                    <i className="fa fa-trash-o fa-lg text-danger"
                                                       aria-hidden="true"/>
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle" colSpan={9}>
                                                <strong>
                                                    Fittings
                                                </strong>
                                            </td>
                                            <td className="align-middle text-center">
                                                <Button
                                                    color={"primary"}
                                                    className={"mb-2 ml-1"}
                                                    onClick={() => handleItemAction("new", -1, {
                                                        itemType: PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING,
                                                        parentCustomID: item.customID
                                                    })}>
                                                    <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                                                    Add
                                                </Button>
                                            </td>
                                        </tr>
                                        {
                                            (fittingItemSummary.items || [])
                                                .filter(fittingItem => fittingItem.parentCustomID === item.customID)
                                                .map((fittingItem, fittingItemIndex) => {
                                                    return <tr key={fittingItemIndex}
                                                               className={classnames({"table-danger": !fittingItem.isValid})}>
                                                        <td className="align-middle" colSpan={5}>
                                                            <SearchStock
                                                                handleSelection={(selection) => handleChange(selection, "item_prodCode", hospitalRangePartUtil.findItemIndex(product, fittingItem.customID))}
                                                                selected={fittingItem.prodCode ? fittingItem.prodCode : ""}
                                                                defaultProdCode={""}
                                                                filter={{ bmFlag: 0, discontinued: false, soldOut: false, stockGroups:STOCK_GROUP_HOSPITAL_RANGE_CODES }}
                                                                newItem={item.newItem}
                                                            />
                                                            {
                                                                fittingItem.formError.prodCode.isValid
                                                                    ? null
                                                                    : <Badge color={"danger"}>
                                                                        {fittingItem.formError.prodCode.message}
                                                                    </Badge>
                                                            }
                                                        </td>
                                                        <td className="align-middle">
                                                            <Input type='number'
                                                                   placeholder={"quantity"}
                                                                   value={fittingItem.quantity.selected.value}
                                                                   name='quantity'
                                                                   onChange={(e) => handleChange(e.target.value, e.target.name, hospitalRangePartUtil.findItemIndex(product, fittingItem.customID))}
                                                                   onFocus={(event) => event.target.select()}
                                                            />
                                                            {
                                                                fittingItem.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color={"danger"}>
                                                                        {fittingItem.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </td>
                                                        <td className="align-middle text-right">
                                                            <NumberFormat
                                                                prefix={'$'}
                                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, fittingItem.pricing.price, {})}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}/>
                                                        </td>
                                                        <td className="align-middle text-right">
                                                            <NumberFormat
                                                                prefix={'$'}
                                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, fittingItem.pricing.discVal, {})}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}/>
                                                        </td>
                                                        <td className="align-middle text-right">
                                                            <NumberFormat
                                                                prefix={'$'}
                                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, fittingItem.pricing.price - fittingItem.pricing.discVal, {})}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}/>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <Button color={"link"}
                                                                    title={"click here to delete"}
                                                                    onClick={() => handleItemAction("delete", hospitalRangePartUtil.findItemIndex(product, fittingItem.customID), null)}>
                                                                <i className="fa fa-trash-o fa-lg text-danger"
                                                                   aria-hidden="true"/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                })
                                        }

                                    </React.Fragment>
                                })}


                                </tbody>
                            })
                        }
                        {
                            price
                                ? <tbody>
                                <tr>
                                    <td className="align-middle" colSpan={6}>
                                        Total
                                    </td>
                                    <td className="align-middle text-center">
                                        <NumberFormat
                                            prefix={'$'}
                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, price, {})}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </td>
                                    <td className="align-middle text-center">
                                        <NumberFormat
                                            prefix={'$'}
                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, discVal, {})}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </td>
                                    <td className="align-middle text-center">
                                        <NumberFormat
                                            prefix={'$'}
                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, price - discVal, {})}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </td>
                                    <td/>
                                </tr>
                                </tbody>
                                : null
                        }

                    </Table>
                </div>

            </div>
        );
    }
}