import React from 'react';
import {isEmpty, cloneDeep} from 'lodash';
import {round} from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE} from "../../../../../../store/AppConstants";

const fabricOffRangeFabricTypes = ["Docril", "Docril Nautica", "Docril Stripe Designs", "Dickson Infinity", "Dickson Orchestra"];

class ProjectionAwningUtil {

    static Instance() {
        return new ProjectionAwningUtil();
    }

    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "fabricColourOffRange":
                /*if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.fabricColourOffRange.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Fabric name can't be empty",
                    };
                }*/
                product.items[itemIndex].configuration.fabricColourOffRange.formError = formError;
                break;
            case "hoodingColourPowdercoat":
                if (product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Hooding powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.hoodingColourPowdercoat.formError = formError;
                break;
            case "bracketColourPowdercoat":
                if (product.items[itemIndex].configuration.bracketColourPowdercoat.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Bracket powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.bracketColourPowdercoat.formError = formError;
                break;
            case "bottomRailColourPowdercoat":
                if (product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Bottom Rail powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.bottomRailColourPowdercoat.formError = formError;
                break;
            case "standOutArmColourPowdercoat":
                if (product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Stand out arm powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.standOutArmColourPowdercoat.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.fabricColourOffRange.formError.isValid
            && product.items[itemIndex].configuration.hoodingColourPowdercoat.formError.isValid
            && product.items[itemIndex].configuration.bracketColourPowdercoat.formError.isValid
            && product.items[itemIndex].configuration.standOutArmColourPowdercoat.formError.isValid
            && product.items[itemIndex].configuration.bottomRailColourPowdercoat.formError.isValid;
    }

    updateRules(key, product, itemIndex, order) {
        let optionIndex, isMotorised, condition1, condition2;

        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Fixed Pivot Convertible Awning":
                        product.items[itemIndex].configuration.width.min = 1200;
                        product.items[itemIndex].configuration.width.max = 3960;
                        break;
                    case "Automatic Awning":
                        product.items[itemIndex].configuration.width.min = 600;
                        product.items[itemIndex].configuration.width.max = 3600;
                        break;
                    case "Fixed Guide Awning":
                        product.items[itemIndex].configuration.width.min = 900;
                        product.items[itemIndex].configuration.width.max = 4260;
                        break;
                    case "Drop Arm Awning":
                        product.items[itemIndex].configuration.width.min = 1000;
                        product.items[itemIndex].configuration.width.max = 4860;
                        break;
                }
                break;
            case "drop":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Fixed Pivot Convertible Awning":
                        product.items[itemIndex].configuration.drop.min = 900;
                        product.items[itemIndex].configuration.drop.max = 2400;
                        break;
                    case "Automatic Awning":
                        product.items[itemIndex].configuration.drop.min = 1000;
                        product.items[itemIndex].configuration.drop.max = 2700;
                        break;
                    case "Fixed Guide Awning":
                        product.items[itemIndex].configuration.drop.min = 1200;
                        product.items[itemIndex].configuration.drop.max = 2700;
                        break;
                    case "Drop Arm Awning":
                        product.items[itemIndex].configuration.drop.min = 1200;
                        product.items[itemIndex].configuration.drop.max = 3000;
                        break;
                }
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = fabricOffRangeFabricTypes.includes(product.items[itemIndex].configuration.fabricType.selected.value.optionKey);
                if (!product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable) {
                    product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
                }
                break;
            case "controlSide":
                product.items[itemIndex].configuration.controlSide.selected.isSelectable = true;
                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Automatic Awning":
                        case "Fixed Guide Awning":
                            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                                    case "Cord & Cleat":
                                        break;
                                    case "Spring":
                                        product.items[itemIndex].configuration.controlSide.selected.isSelectable = false;
                                        break;
                                }
                            }
                            break;
                        case "Fixed Pivot Convertible Awning":
                        case "Drop Arm Awning":
                        default:
                            break;
                    }
                }
                break;
            case "hoodingColour":
                product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                        case "No Hood - Bracket Only":
                            product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = false;
                            break;
                        case "Auto Hood":
                            product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.hoodingColour.selected.isSelectable) {
                    product.items[itemIndex].configuration.hoodingColour.selected.value = null;
                    product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = "";
                }
                break;
            case "hoodingColourPowdercoat":
                product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable) {
                    product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = "";
                }
                break;
            case "bracketColour":
                product.items[itemIndex].configuration.bracketColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                        case "No Hood - Bracket Only":
                            product.items[itemIndex].configuration.bracketColour.selected.isSelectable = true;
                            break;
                        case "Auto Hood":
                            product.items[itemIndex].configuration.bracketColour.selected.isSelectable = false;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.bracketColour.selected.isSelectable) {
                    product.items[itemIndex].configuration.bracketColour.selected.value = null;
                    product.items[itemIndex].configuration.bracketColour.selected.dropdownValue = "";
                }
                break;
            case "bracketColourPowdercoat":
                product.items[itemIndex].configuration.bracketColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.bracketColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.bracketColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.bracketColourPowdercoat.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.bracketColourPowdercoat.selected.isSelectable) {
                    product.items[itemIndex].configuration.bracketColourPowdercoat.selected.value = "";
                }
                break;
            case "bottomRailColourPowdercoat":
                product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.bottomRailColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.isSelectable) {
                    product.items[itemIndex].configuration.bottomRailColourPowdercoat.selected.value = "";
                }
                break;
            case "standOutArmColourPowdercoat":
                product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.standOutArmColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.standOutArmColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.isSelectable) {
                    product.items[itemIndex].configuration.standOutArmColourPowdercoat.selected.value = "";
                }
                break;
            case "crankHandle":
                product.items[itemIndex].configuration.crankHandle.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.crankHandle.finalOptions)) {
                    product.items[itemIndex].configuration.crankHandle.selected.isSelectable = true;
                }
                if (!product.items[itemIndex].configuration.crankHandle.selected.isSelectable) {
                    product.items[itemIndex].configuration.crankHandle.selected.value = {};
                    product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = "";
                }
                break;
            case "cordColour":
                product.items[itemIndex].configuration.cordColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Fixed Pivot Convertible Awning":
                        case "Fixed Guide Awning":
                            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                                    case "Cord & Cleat":
                                    case "Spring":
                                        product.items[itemIndex].configuration.cordColour.selected.isSelectable = true;
                                        break;
                                }
                            }
                            break;
                        case "Automatic Awning":
                        case "Drop Arm Awning":
                        default:
                            product.items[itemIndex].configuration.cordColour.selected.isSelectable = false;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.cordColour.selected.isSelectable) {
                    product.items[itemIndex].configuration.cordColour.selected.value = null;
                    product.items[itemIndex].configuration.cordColour.selected.dropdownValue = "";
                }
                break;
            case "winchAndCord":
                product.items[itemIndex].configuration.winchAndCord.selected.isSelectable = false;
                product.items[itemIndex].configuration.winchAndCord.selected.dropdownValue = "";

                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Fixed Pivot Convertible Awning":
                        case "Fixed Guide Awning":
                            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                                    case "Cord & Cleat":
                                    case "Spring":
                                        product.items[itemIndex].configuration.winchAndCord.selected.isSelectable = true;
                                        break;
                                    case "Gear Box":
                                    case "AC Remote Motor":
                                    case "DC Solar Remote Motor":
                                        break;
                                }
                            }
                            break;
                        case "Automatic Awning":
                        case "Drop Arm Awning":
                        default:
                            product.items[itemIndex].configuration.winchAndCord.selected.isSelectable = false;
                            break;
                    }
                }

                if (!product.items[itemIndex].configuration.winchAndCord.selected.isSelectable) {
                    product.items[itemIndex].configuration.winchAndCord.selected.value = false;
                } else {
                    optionIndex = product.items[itemIndex].configuration.winchAndCord.options.findIndex(o => true);
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.winchAndCord.selected.dropdownValue = product.items[itemIndex].configuration.winchAndCord.options[optionIndex].optionLabel;
                    }
                }
                break;
            case "windSensor":
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Cord & Cleat":
                        case "Spring":
                        case "Gear Box":
                        case "DC Solar Remote Motor":
                            product.items[itemIndex].configuration.windSensor.selected.isSelectable = false;
                            break;
                        case "AC Remote Motor":
                        case "Somfy RTS Motor Altus":
                            product.items[itemIndex].configuration.windSensor.selected.isSelectable = true;
                    }
                }
                if (!product.items[itemIndex].configuration.windSensor.selected.isSelectable) {
                    product.items[itemIndex].configuration.windSensor.selected.value = {};
                    product.items[itemIndex].configuration.windSensor.selected.dropdownValue = "";
                }
                break;
            case"remote":
            case"charger":
            case "bridge":
                product.items[itemIndex].configuration.remote.selected.isSelectable = false;
                product.items[itemIndex].configuration.charger.selected.isSelectable = false;
                product.items[itemIndex].configuration.bridge.selected.isSelectable = false;

                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Cord & Cleat":
                        case "Spring":
                        case "Gear Box":
                        case "Somfy RTS Motor Altus":
                            break;
                        case "AC Remote Motor":
                            product.items[itemIndex].configuration.remote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.bridge.selected.isSelectable = true;
                            break;
                        case "DC Solar Remote Motor":
                            product.items[itemIndex].configuration.remote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.charger.selected.isSelectable = true;
                            product.items[itemIndex].configuration.bridge.selected.isSelectable = true;
                            break;
                    }
                }
                if (!product.items[itemIndex].configuration.remote.selected.isSelectable) {
                    product.items[itemIndex].configuration.remote.selected.value = false;
                } else {
                    optionIndex = product.items[itemIndex].configuration.remote.options.findIndex(o => true);
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.remote.selected.dropdownValue = product.items[itemIndex].configuration.remote.options[optionIndex].optionLabel;
                    }
                }
                if (!product.items[itemIndex].configuration.charger.selected.isSelectable) {
                    product.items[itemIndex].configuration.charger.selected.value = false;
                } else {
                    optionIndex = product.items[itemIndex].configuration.charger.options.findIndex(o => true);
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.charger.selected.dropdownValue = product.items[itemIndex].configuration.charger.options[optionIndex].optionLabel;
                    }
                }
                if (!product.items[itemIndex].configuration.bridge.selected.isSelectable) {
                    product.items[itemIndex].configuration.bridge.selected.value = false;
                } else {
                    optionIndex = product.items[itemIndex].configuration.bridge.options.findIndex(o => true);
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.bridge.selected.dropdownValue = product.items[itemIndex].configuration.bridge.options[optionIndex].optionLabel;
                    }
                }
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let setIndex, sets, stocks, label, attribute,
            width, drop, comment,
            deduction, condition1, condition2, condition3, condition4, optionIndex;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        let hasNoHood;
        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [{
                    id: null,
                    description: "",
                    prodCode: isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? "09770" : product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                    keywayQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                    wastageFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1000,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true
                }];

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "operation":
                attribute = "Operation";
                comment = "";
                stocks = [];

                optionIndex = product.items[itemIndex].configuration.operation.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                        condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                        label = condition2;
                        switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                            case "Cord & Cleat":
                            case "Spring":
                                condition3 = isEmpty(product.items[itemIndex].configuration.cordColour.selected.value) ? "" : product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
                                break;
                            case "Gear Box":
                                hasNoHood = this.hasNoHood(product, itemIndex);
                                if (hasNoHood) {
                                    condition3 = isEmpty(product.items[itemIndex].configuration.bracketColour.selected.value) ? "" : product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;
                                } else {
                                    condition3 = isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                                }
                                switch (condition3) {
                                    case "White":
                                    case "Smooth Cream":
                                    case "Paperbark":
                                        condition3 = "White";
                                        break;
                                    case "Woodland Grey":
                                    case "Monument":
                                    case "Black":
                                    case "Powdercoat":
                                        condition3 = "Black";
                                        break;
                                }
                                break;
                            case "AC Remote Motor":
                            case "DC Solar Remote Motor":
                            case "Somfy RTS Motor Altus":
                                break;
                        }
                    }
                    sets = product.items[itemIndex].configuration.operation.options[optionIndex].sets.filter(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                        && (s.condition3 ? s.condition3.includes(condition3) : true)
                        && (width >= s.min && width <= s.max)
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });

                    if (product.items[itemIndex].configuration.winchAndCord.selected.value) {
                        stocks = stocks.filter(s => s.prodCode !== "04296");
                    }
                }


                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.operation.selected.stocks = stocks;
                break;
            case "keyway":
                attribute = "Keyway";
                label = "";
                comment = "";
                stocks = [];

                product.items[itemIndex].configuration.keyway.selected.value = null;
                product.items[itemIndex].configuration.keyway.selected.dropdownValue = "";

                optionIndex = product.items[itemIndex].configuration.keyway.options.findIndex(o => o.optionKey === "matrix");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    setIndex = product.items[itemIndex].configuration.keyway.options[optionIndex].sets.findIndex(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                        && (width >= s.min && width <= s.max)
                    );
                    if (setIndex > -1) {
                        condition1 = product.items[itemIndex].configuration.keyway.options[optionIndex].sets[setIndex].setKey;
                        optionIndex = product.items[itemIndex].configuration.keyway.options.findIndex(o => o.optionKey === condition1);
                        if (optionIndex > -1) {
                            product.items[itemIndex].configuration.keyway.selected.value = product.items[itemIndex].configuration.keyway.options[optionIndex];
                        }
                    }
                }

                if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.value)) {
                    product.items[itemIndex].configuration.keyway.selected.value.sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.keyway.selected.stocks = stocks;
                break;
            case "bracketColour":
                attribute = "Bracket";
                label = "";
                stocks = [];
                optionIndex = product.items[itemIndex].configuration.bracketColour.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.bracketColour.selected.value) ? "" : product.items[itemIndex].configuration.bracketColour.selected.value.optionKey;
                    condition4 = isEmpty(product.items[itemIndex].configuration.hooding.selected.value) ? "" : product.items[itemIndex].configuration.hooding.selected.value.optionKey;

                    sets = product.items[itemIndex].configuration.bracketColour.options[optionIndex].sets.filter(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                        && (s.condition3 ? s.condition3.includes(condition3) : true)
                        && (s.description ? s.description.includes(condition4) : true)
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bracketColour.selected.stocks = stocks;
                break;
            case "hooding":
                attribute = "Hooding";
                label = "";
                stocks = [];
                optionIndex = product.items[itemIndex].configuration.hooding.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    condition4 = isEmpty(product.items[itemIndex].configuration.hooding.selected.value) ? "" : product.items[itemIndex].configuration.hooding.selected.value.optionKey;

                    sets = product.items[itemIndex].configuration.hooding.options[optionIndex].sets.filter(s =>
                        ((width >= s.min && width <= s.max)
                            && (s.condition1 ? s.condition1.includes(condition1) : true)
                            && (s.condition2 ? s.condition2.includes(condition2) : true)
                            && (s.condition3 ? s.condition3.includes(condition3) : true)
                            && (s.description ? s.description.includes(condition4) : true))
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.hooding.selected.stocks = stocks;
                break;
            case "bottomRailType":
                attribute = "Bottom Rail";
                label = "";
                comment = "";
                stocks = [];

                if (!isEmpty(product.items[itemIndex].configuration.bottomRailType.selected.value)) {
                    label = product.items[itemIndex].configuration.bottomRailType.selected.value.optionKey;
                }
                optionIndex = product.items[itemIndex].configuration.bottomRailType.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "N/A" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.bottomRailColour.selected.value) ? "N/A" : product.items[itemIndex].configuration.bottomRailColour.selected.value.optionKey;
                    condition4 = isEmpty(product.items[itemIndex].configuration.channelColour.selected.value) ? "N/A" : product.items[itemIndex].configuration.channelColour.selected.value.optionKey;

                    sets = product.items[itemIndex].configuration.bottomRailType.options[optionIndex].sets.filter(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                        && (s.condition3 ? s.condition3.includes(condition3) : true)
                        && (s.description ? s.description.includes(condition4) : true)
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bottomRailType.selected.stocks = stocks;
                break;
            case "channelSize":
                attribute = "Channel";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.channelType.selected.value)) {
                    label = product.items[itemIndex].configuration.channelType.selected.value.optionKey;
                }
                if (!isEmpty(product.items[itemIndex].configuration.channelSize.selected.value)) {
                    comment = product.items[itemIndex].configuration.channelSize.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.channelSize.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.channelSize.selected.stocks = stocks;
                break;
            case "standOutArmSize":
                attribute = "Stand Out Arm";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.channelType.selected.value)) {
                    label = product.items[itemIndex].configuration.channelType.selected.value.optionKey;
                }
                if (!isEmpty(product.items[itemIndex].configuration.standOutArmSize.selected.value)) {
                    comment = product.items[itemIndex].configuration.standOutArmSize.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.standOutArmSize.selected.value.stocks;
                }
                optionIndex = product.items[itemIndex].configuration.standOutArmSize.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.standOutArmColour.selected.value) ? "" : product.items[itemIndex].configuration.standOutArmColour.selected.value.optionKey;
                    sets = product.items[itemIndex].configuration.standOutArmSize.options[optionIndex].sets.filter(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.standOutArmSize.selected.stocks = stocks;
                break;
            case "remote":
                attribute = "Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.remote.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.remote.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.remote.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.remote.selected.stocks = stocks;
                break;
            case "charger":
                attribute = "Charger";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.charger.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.charger.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.charger.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.charger.selected.stocks = stocks;
                break;
            case "bridge":
                attribute = "Bridge";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.bridge.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.bridge.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.bridge.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bridge.selected.stocks = stocks;
                break;
            case "windSensor":
                attribute = "Wind Sensor";
                label = product.items[itemIndex].configuration.windSensor.selected.optionKey;
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.windSensor.selected.value)) {
                    stocks = [...stocks, ...product.items[itemIndex].configuration.windSensor.selected.value.stocks];
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windSensor.selected.stocks = stocks;
                break;
            case "crankHandle":
                attribute = "Crank Handle";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                    label = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.crankHandle.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.crankHandle.selected.stocks = stocks;
                break;
            case "winchAndCord":
                attribute = "Winch And Cord";
                label = product.items[itemIndex].configuration.winchAndCord.selected.dropdownValue;
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.winchAndCord.selected.value) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.standOutArmSize.selected.value) ? "" : product.items[itemIndex].configuration.standOutArmSize.selected.value.optionKey;

                    product.items[itemIndex].configuration.winchAndCord.finalOptions = product.items[itemIndex].configuration.winchAndCord.options;
                    product.items[itemIndex].configuration.winchAndCord.finalOptions.forEach(o => {
                        sets = o.sets.filter(s =>
                            (s.condition1 ? s.condition1.includes(condition1) : true)
                            && (s.condition2 ? s.condition2.includes(condition2) : true)
                        );
                        sets.forEach(s => {
                            stocks = [...stocks, ...s.stocks];
                        })
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.winchAndCord.selected.stocks = stocks;
                break;
            case "valance":
                attribute = "Valance";
                label = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                comment = "";
                stocks = [];
                stocks = product.items[itemIndex].configuration.valance.selected.value.stocks;
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.valance.selected.stocks = stocks;
                break;
            case "spline":
                attribute = "Spline";
                label = "";
                comment = "";
                stocks = [];
                optionIndex = product.items[itemIndex].configuration.spline.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.keyway.selected.value) ? "" : product.items[itemIndex].configuration.keyway.selected.value.optionKey;

                    sets = product.items[itemIndex].configuration.spline.options[optionIndex].sets.filter(s =>
                        (s.condition1 ? s.condition1.includes(condition1) : true)
                        && (s.condition2 ? s.condition2.includes(condition2) : true)
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.spline.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, order);
            product = this.updateStocks("keyway", product, itemIndex, order);
            product = this.updateStocks("bracketColour", product, itemIndex, order);
            product = this.updateStocks("hooding", product, itemIndex, order);
            product = this.updateStocks("bottomRailType", product, itemIndex, order);
            product = this.updateStocks("channelSize", product, itemIndex, order);
            product = this.updateStocks("standOutArmSize", product, itemIndex, order);
            product = this.updateStocks("spline", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("crankHandle", product, itemIndex, order);
            product = this.updateStocks("remote", product, itemIndex, order);
            product = this.updateStocks("charger", product, itemIndex, order);
            product = this.updateStocks("bridge", product, itemIndex, order);
            product = this.updateStocks("winchAndCord", product, itemIndex, order);
            product = this.updateStocks("valance", product, itemIndex, order);
            product = this.updateStocks("windSensor", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.keyway.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bracketColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bracketColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.hooding.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bottomRailType.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRailType.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.channelSize.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.channelSize.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.standOutArmSize.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.standOutArmSize.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.spline.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.spline.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.drop.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.drop.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.crankHandle.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.remote.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.remote.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.charger.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.charger.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bridge.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bridge.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.windSensor.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.windSensor.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.winchAndCord.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.winchAndCord.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.valance.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.valance.selected.stocks];
            }

            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor
        };


        switch (attribute) {
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                /*
                * width(mm) drop(mm) fabricWidth(m)
                *
                * PANEL >> panel
                * FABRIC_DROP(M) >> fabricDrop
                * USAGE(LM) >> usage
                * */
                let panel = 0, fabricDrop = 0, usage = 0;

                //Calculating PANEL
                try {
                    panel = Math.ceil((width / 1000) / fabricWidth);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }

                //Calculating FABRIC_DROP
                fabricDrop = (drop + 400) / 1000;

                //Calculating FABRIC_DROP
                if (fabricDrop > fabricWidth) {
                    usage = round((panel * fabricDrop), 1);
                } else {
                    usage = width / 1000;
                }
                deduction = this.updateDeduction("fabric", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Keyway":
            case "Bracket":
            case "Hooding":
            case "Valance":
            default:
                deduction = this.updateDeduction(attribute, product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
        }
    }

    updateDeduction(key, product, itemIndex, stockInventoryItem) {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        let isMotorised = this.isMotorised(product, itemIndex);
        let isManual = !isMotorised;
        let hasNoHood = this.hasNoHood(product, itemIndex);
        let hasNoValance = this.hasNoValance(product, itemIndex);
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let keyway = isEmpty(product.items[itemIndex].configuration.keyway.selected.value) ? "" : product.items[itemIndex].configuration.keyway.selected.value.optionKey;
        switch (key) {
            case "fabric":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Fixed Pivot Convertible Awning":
                        deduction.deductionWidth = hasNoHood ? -80 : -95;
                        deduction.deductionDrop = 1000;
                        break;
                    case "Automatic Awning":
                        deduction.deductionWidth = hasNoHood ? -48 : -55;
                        if (hasNoValance) {
                            deduction.deductionDrop = 700;
                        } else {
                            deduction.deductionDrop = 1000;
                        }
                        break;
                    case "Fixed Guide Awning":
                        if (isManual) {
                            deduction.deductionWidth = hasNoHood ? -75 : -69;
                            if (hasNoValance) {
                                deduction.deductionDrop = 700;
                            } else {
                                deduction.deductionDrop = 1000;
                            }
                        } else {
                            deduction.deductionWidth = hasNoHood ? -68 : -63;
                            if (hasNoValance) {
                                deduction.deductionDrop = 700;
                            } else {
                                deduction.deductionDrop = 1000;
                            }
                        }
                        break;
                    case "Drop Arm Awning":
                        if (isManual) {
                            deduction.deductionWidth = hasNoHood ? -80 : -74;
                            if (hasNoValance) {
                                deduction.deductionDrop = 700;
                            } else {
                                deduction.deductionDrop = 1000;
                            }
                        } else {
                            deduction.deductionWidth = hasNoHood ? -71 : -66;
                            if (hasNoValance) {
                                deduction.deductionDrop = 700;
                            } else {
                                deduction.deductionDrop = 1000;
                            }
                        }
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Keyway":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Fixed Pivot Convertible Awning":
                        deduction.deductionWidth = hasNoHood ? -48 : -63;
                        break;
                    case "Automatic Awning":
                        deduction.deductionWidth = hasNoHood ? -48 : -55;
                        break;
                    case "Fixed Guide Awning":
                        if (isManual) {
                            switch (keyway) {
                                case "63mm":
                                    deduction.deductionWidth = hasNoHood ? -72 : -66;
                                    break;
                                case "78mm":
                                    deduction.deductionWidth = hasNoHood ? -77 : -71;
                                    break;
                            }
                        } else {
                            deduction.deductionWidth = hasNoHood ? -68 : -63;
                        }
                        break;
                    case "Drop Arm Awning":
                        if (isManual) {
                            deduction.deductionWidth = hasNoHood ? -77 : -71;
                        } else {
                            deduction.deductionWidth = hasNoHood ? -68 : -63;
                        }
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                break;
            case "Hooding":
                deduction.deductionWidth = hasNoHood ? 0 : -3;
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.hooding.selected.deduction = deduction;
                break;
            case "Bottom Rail":
            case "Front Bar":
            case "Spline":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Fixed Pivot Convertible Awning":
                        deduction.deductionWidth = hasNoHood ? -80 : -95;
                        break;
                    case "Automatic Awning":
                        deduction.deductionWidth = hasNoHood ? -43 : -50;
                        break;
                    case "Fixed Guide Awning":
                        deduction.deductionWidth = hasNoHood ? -43 : -50;
                        break;
                    case "Drop Arm Awning":
                        if (isManual) {
                            deduction.deductionWidth = hasNoHood ? -77 : -71;
                        } else {
                            deduction.deductionWidth = hasNoHood ? -68 : -63;
                        }
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.bottomRailType.selected.deduction = deduction;
                break;
        }
        return deduction;
    }

    isMotorised(product, itemIndex, order) {
        let isMotorised = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation)) {
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                if (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                    isMotorised = product.items[itemIndex].configuration.operation.selected.value.optionKey.includes("Motor");
                }
            }
        }
        return isMotorised;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    generateBMCode(product, itemIndex) {
        let bmCode = "";
        switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
            case "Fixed Pivot Convertible Awning":
                bmCode = "FPCA-P";
                break;
            case "Automatic Awning":
                bmCode = "AA-P";
                break;
            case "Fixed Guide Awning":
                bmCode = "FGA-P";
                break;
            case "Drop Arm Awning":
                bmCode = "DAA-P";
                break;
        }
        return bmCode;
    }

    hasNoHood(product, itemIndex) {
        let result = true;
        if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                case "No Hood - Bracket Only":
                    result = true;
                    break;
                case "Auto Hood":
                default:
                    result = false;
                    break;
            }
        }
        return result
    }

    hasNoValance(product, itemIndex) {
        let result = true;
        if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value)) {
            switch (product.items[itemIndex].configuration.valance.selected.value.optionKey) {
                case "None":
                    result = true;
                    break;
                case "Straight":
                case "Scallop":
                    result = false;
                    break;
            }
        }
        return result
    }

    getPriceMatrixMultiplier(product, itemIndex) {
        let result = 1;
        if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
            switch (product.items[itemIndex].configuration.fabricType.selected.value.category) {
                case "P":
                    result = 1;
                    break;
                case "Q":
                    result = 1.2;
                    break;
                case "R":
                    result = 1.3;
                    break;
                case "S":
                    result = 1.4;
                    break;
            }
        }
        return result;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fabricColourOffRange.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fabricColourOffRange.formError.message);
        }
        if (!pg.items[itemIndex].configuration.hoodingColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.hoodingColourPowdercoat.formError.message);
        }
        if (!pg.items[itemIndex].configuration.bracketColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.bracketColourPowdercoat.formError.message);
        }
        if (!pg.items[itemIndex].configuration.standOutArmColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.standOutArmColourPowdercoat.formError.message);
        }
        if (!pg.items[itemIndex].configuration.bottomRailColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.bottomRailColourPowdercoat.formError.message);
        }

        return errors;
    }

    getValueOrDefault(product, itemIndex, valueType, defaultValue, key) {
        let result = defaultValue;
        switch (valueType) {
            case "value":
                if (product.items[itemIndex].configuration[key].selected.value) {
                    result = product.items[itemIndex].configuration[key].selected.value;
                }
                break;
            case "optionKey":
                if (!isEmpty(product.items[itemIndex].configuration[key].selected.value)) {
                    result = product.items[itemIndex].configuration[key].selected.value.optionKey;
                }
                break;
        }
        return result;
    }

    toSalesOrderItemProjectionAwningRequest(pg, itemIndex, order) {
        let defaultDeduction = this.updateDeduction("default", pg, itemIndex);
        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.keyway.selected.deduction)) {
            pg.items[itemIndex].configuration.keyway.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.hooding.selected.deduction)) {
            pg.items[itemIndex].configuration.hooding.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.bottomRailType.selected.deduction)) {
            pg.items[itemIndex].configuration.bottomRailType.selected.deduction = defaultDeduction;
        }

        let salesOrderItemProjectionAwning = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: this.getValueOrDefault(pg, itemIndex, "value", "", "location"),
            quantity: this.getValueOrDefault(pg, itemIndex, "value", 0, "quantity"),
            width: this.getValueOrDefault(pg, itemIndex, "value", 0, "width"),
            drop: this.getValueOrDefault(pg, itemIndex, "value", 0, "drop"),
            fabricType: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "fabricType"),
            fabricColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "fabricColour"),
            fabricColourOffRange: this.getValueOrDefault(pg, itemIndex, "value", "", "fabricColourOffRange"),
            model: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "model"),
            operation: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "operation"),
            keyway: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "keyway"),
            controlSide: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "controlSide"),
            cordColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "cordColour"),
            valance: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "valance"),
            bracketColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "bracketColour"),
            bracketColourPowdercoat: this.getValueOrDefault(pg, itemIndex, "value", "", "bracketColourPowdercoat"),
            hooding: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "hooding"),
            hoodingColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "hoodingColour"),
            hoodingColourPowdercoat: this.getValueOrDefault(pg, itemIndex, "value", "", "hoodingColourPowdercoat"),
            mount: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "mount"),
            channelType: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "channelType"),
            channelColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "channelColour"),
            channelSize: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "channelSize"),
            standOutArmType: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "standOutArmType"),
            standOutArmColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "standOutArmColour"),
            standOutArmColourPowdercoat: this.getValueOrDefault(pg, itemIndex, "value", "", "standOutArmColourPowdercoat"),
            standOutArmSize: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "standOutArmSize"),
            bottomRailType: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "bottomRailType"),
            bottomRailColour: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "bottomRailColour"),
            bottomRailColourPowdercoat: this.getValueOrDefault(pg, itemIndex, "value", "", "bottomRailColourPowdercoat"),
            crankHandle: this.getValueOrDefault(pg, itemIndex, "optionKey", "", "crankHandle"),
            winchAndCord: pg.items[itemIndex].configuration.winchAndCord.selected.value ? pg.items[itemIndex].configuration.winchAndCord.selected.dropdownValue : "",
            remote: pg.items[itemIndex].configuration.remote.selected.value ? pg.items[itemIndex].configuration.remote.selected.dropdownValue : "",
            charger: pg.items[itemIndex].configuration.charger.selected.value ? pg.items[itemIndex].configuration.charger.selected.dropdownValue : "",
            bridge: pg.items[itemIndex].configuration.bridge.selected.value ? pg.items[itemIndex].configuration.bridge.selected.dropdownValue : "",
            windSensor: pg.items[itemIndex].configuration.windSensor.selected.value ? pg.items[itemIndex].configuration.windSensor.selected.value.optionKey : "",
            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,
            hoodingDeductionWidth: pg.items[itemIndex].configuration.hooding.selected.deduction.deductionWidth,
            keywayDeductionWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.deductionWidth,
            bottomRailDeductionWidth: pg.items[itemIndex].configuration.bottomRailType.selected.deduction.deductionWidth,


            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,
            hoodingCutWidth: pg.items[itemIndex].configuration.hooding.selected.deduction.cutWidth,
            keywayCutWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.cutWidth,
            bottomRailCutWidth: pg.items[itemIndex].configuration.bottomRailType.selected.deduction.cutWidth,


            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemProjectionAwning;
    }
}

export default ProjectionAwningUtil.Instance();
