import React, {Component} from 'react';

// Image fetch URL from backend REST API
const baseUrl = window.location.origin;
const prefix = baseUrl + "/api/file/generate/production/dashboard/images?FileName=";
const productionPipelineImg = prefix + 'ProductionPipeline.png';


export default class ProductionDashboardProductionPipeline extends Component {

    componentDidMount() {
        setInterval(function () {
            let imageElement = document.getElementById("productionPipelineImg");
            if (imageElement) {
                imageElement.src = productionPipelineImg + '&rand=' + Math.random();
            }
        }, 300000);
    }

    render() {
        return (
            <img
                id={"productionPipelineImg"}
                alt="Production Pipeline Chart"
                src={productionPipelineImg}
                width="100%"
                resizeMode={'cover'}/>

        );

    }
}
