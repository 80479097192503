import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import queryString from 'query-string';
import WarehouseLocationTypeService from "../../services/WarehouseLocationTypeService";


export default class WarehouseLocationTypeManagePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseLocTypeID: '',
            warehouseLocTypeObj: {
                iD: 0,
                name: '',
                description: '',
            },
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        let {warehouseLocTypeID: warehouseLocTypeID} = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            warehouseLocTypeID = searchParams.warehouseLocTypeID;
            this.setState({warehouseLocTypeID: warehouseLocTypeID}, () => {
                this.refresh()
            })
        }

    }

    refresh() {
        if (this.state.warehouseLocTypeID) {
            this.setState({loading: true})

            WarehouseLocationTypeService.getOneWarehouseLocationType(this.state.warehouseLocTypeID).then(response => {
                this.setState({warehouseLocTypeObj: response.data, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {warehouseLocTypeObj} = this.state;

        warehouseLocTypeObj[name] = value;
        this.setState({warehouseLocTypeObj});
    }

    handleCancel() {
        this.props.history.push('/keyway-inventory/warehouse-location-types');
    }

    validateForm(warehouseLocTypeObj) {
        let formErrors = {};
        let valid = true;

        if (warehouseLocTypeObj.name === null || (warehouseLocTypeObj.name !== null && warehouseLocTypeObj.name.length === 0)) {
            formErrors.name = "Please enter name";
            valid = false;
        }
        if (warehouseLocTypeObj.description === null || (warehouseLocTypeObj.description !== null && warehouseLocTypeObj.description.length === 0)) {
            formErrors.name = "Please fill description";
            valid = false;
        }


        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.warehouseLocTypeObj};
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        } else {
            this.setState({saving: true, formErrors: {}});

            WarehouseLocationTypeService.saveWarehouseLocationType(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.warehouseLocTypeID) {
                        this.props.history.push('/keyway-inventory/warehouse-location-types');
                        toast.success("Warehouse Location type saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    } else {
                        toast.success("Warehouse Location type updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.refresh();


                    }

                    this.setState({saving: false});
                }

            }).catch(error => {
                this.setState({saving: false});
                console.log(error.data)
            });
        }
    }

    render() {
        let {warehouseLocTypeObj, formErrors, loading, warehouseLocTypeID} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/keyway-inventory/warehouse-location-types')}>Warehouse
                        Location Type</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!warehouseLocTypeID ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Warehouse Location Type</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary"/> :
                            <Form>
                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input
                                                invalid={formErrors.name != null}
                                                name="name"
                                                type="text"
                                                value={warehouseLocTypeObj.name || ''}
                                                onChange={this.handleChange}
                                                placeholder="write name here..."/>
                                            <FormText color="danger">{formErrors.name}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                value={this.state.warehouseLocTypeObj.description || ''}
                                                onChange={(e) => this.handleChange(e)}
                                                placeholder="write description here...">

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </Row>


                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving}>

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                              style={{color: "white"}}/> :
                                    <i className="fa fa-floppy-o mr-2"
                                       aria-hidden="true"/>}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2"/>
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}
