import React, { Component } from 'react';
import queryString from 'query-string';
import { Link as Link } from "react-router-dom";
import {
    Modal, ModalHeader, ModalFooter, ModalBody, Button, Spinner, Input, InputGroup, InputGroupAddon, InputGroupText, Card, CardBody
} from 'reactstrap';
import OrderService from '../../services/OrderService';
import {handleErrorMessage} from "../../services/CommonService";
import { toast, ToastContainer } from 'react-toastify';

export default class OrderGatewayOrderItemsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            OrderNumbers: this.props.orderNums,
            Orders: [],
            searchText: "",
            isloading: false
        };

        this.refresh = this.refresh.bind(this);
        this.orderService = new OrderService();
    }

    componentDidMount() {
        this.refresh("");
    }

    refresh(searchedOrder) {
        //get details here
        this.setState({ isloading: true });

        let ordNums = searchedOrder ? searchedOrder :  this.props.orderNums;

        if (ordNums) {
            this.orderService.getOrderDescription(ordNums).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (response.data) {
                        this.setState({ Orders: response.data, isloading: false });
                    }
                }
            }).catch(error => {
                this.setState({ isloading: false });
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        }
    }

    handleChange(value) {
        this.setState({ searchText: value }, () => {
            this.refresh(value)
        });
    }

    render() {
        let { isOpen, toggle, isloading, searchText } = this.props;
        let { Orders } = this.state;

        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Search
                </ModalHeader>
                <ModalBody>
                    <div>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search"
                                        aria-hidden="true" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                onChange={(e) => this.handleChange(e.target.value)}
                                type="search"
                                name="searchText"
                                value={searchText}
                                placeholder="Search by Order no." />
                        </InputGroup>
                    </div>
                    <hr />
                    {
                        isloading ?
                            <Spinner color={"primary"} />
                            :
                            <div>
                                {(Orders || []).map((order, orderIndex) => {
                                    return <Card className={"m-2"} key={orderIndex}>
                                        <CardBody>
                                            <span><Link
                                                className="btn btn-sm btn-primary" style={{ color: "white" }}
                                                title={"Click here to view order in detail"}
                                                to={"/sales/order/view?" + queryString.stringify({ OrdNum: order.ordnum })}>
                                                {order.ordnum}
                                            </Link></span>
                                            <hr />
                                            <div>
                                                <strong className="mr-2">
                                                    Product Description
                                        </strong>
                                        :&nbsp;{order.productDescription}
                                            </div>
                                            <div>
                                                <strong className="mr-2">
                                                    Qty
                                        </strong>
                                        :&nbsp;{order.qty}
                                            </div>
                                            <div>
                                                <strong className="mr-2">
                                                    Items Count in this order
                                        </strong>
                                        :&nbsp;{order.itemCount}
                                            </div>
                                        </CardBody>
                                    </Card>
                                })}
                            </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>
                            Close
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}