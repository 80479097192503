import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import NumberFormat from "react-number-format";

import {ToastContainer} from 'react-toastify';
import StockQtyFormat from "../../../components/stock/StockQtyFormat";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../../store/AppConstants";

export default class StockEnquiryPageSalesHistoryTabLocation extends Component {


    render() {
        let {stock} = this.props;
        let eachUnitName = ((stock && stock.keywayStock && stock.keywayStock.eachUnitName) ? stock.keywayStock.eachUnitName : "NA");
        let whUnitName = ((stock && stock.keywayStock && stock.keywayStock.whUnitName) ? stock.keywayStock.whUnitName : "NA");
        let whUnitQty = ((stock && stock.keywayStock && stock.keywayStock.whUnitQty) ? stock.keywayStock.whUnitQty : 1);

        return (
            <div>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Location</h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} responsive className={"mb-0"}>
                            <thead>
                            <tr>
                                <th className="text-left">Location</th>
                                <th className="text-center">Type</th>
                                <th className="text-right">QOH</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (stock.stockWarehouseLocations || []).map((location, locationIndex) => {
                                    return <tr key={locationIndex}>
                                        <td className="text-left">{location.locationName}</td>
                                        <td className="text-center">{location.locationType}</td>
                                        <td className="text-right">
                                            <StockQtyFormat
                                                eachUnitName={eachUnitName}
                                                whUnitName={whUnitName}
                                                whUnitQty={whUnitQty}
                                                qty={location.qoh}
                                                qtyType={STOCK_QTY_TYPE.each}
                                                format={STOCK_QTY_FORMAT.each}
                                                emptyPlaceholderText={"-"}
                                                isPoManage={false}
                                            />
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}