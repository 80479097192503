import React, {Component} from 'react';
// Image fetch URL from backend REST API
const baseUrl = window.location.origin;
const prefix = baseUrl + "/api/file/generate/production/dashboard/images?FileName=";
const productionDifotImg = prefix + 'ProductionDifot.png';


export default class ProductionDashboardProductionDifot extends Component {

    componentDidMount() {
        setInterval(function () {
            let imageElement = document.getElementById("productionDifotImg");
            if(imageElement){
                imageElement.src = productionDifotImg + '&rand=' + Math.random();
            }
        }, 300000);
    }

    render() {
        return (
            <img id={"productionDifotImg"}
                 alt="Production Difot Chart"
                 src={productionDifotImg}
                 width="100%"
                 resizeMode={'cover'}
            />
        );

    }

}

