import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter, InputGroupText,
    Button, Card, CardHeader, InputGroup, InputGroupAddon,
    Row, Col, Label, FormGroup, Input, Spinner, Table,
} from 'reactstrap';
import {
    toast, ToastContainer
} from "react-toastify";
import { handleErrorMessage } from "../../services/CommonService";
import StockQtyFormat from "../stock/StockQtyFormat";
import PropTypes from 'prop-types';
import { STOCK_QTY_TYPE, STOCK_QTY_FORMAT } from "../../store/AppConstants";
import StockService from "../../services/StockService";
import WarehouseLocationTypeService from "../../services/WarehouseLocationTypeService";
import SearchWarehouseLocationNewComponent from "../search/SearchWarehouseLocationNewComponent";

export default class TransferStockToLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoadingSubmit: false,
            stock: {},
            prodCode: '',
            stockLocations: [],
            newLocationID: '',
            isAddingLocation: false,
        };
        this.stockService = new StockService();
        this.handleChange = this.handleChange.bind(this);
        this.getSizeString = this.getSizeString.bind(this);
        this.transferStock = this.transferStock.bind(this);
        this.getLocationDropdown = this.getLocationDropdown.bind(this);
        this.getStock = this.getStock.bind(this);
        this.addNewStockToLocation = this.addNewStockToLocation.bind(this);
    }

    componentDidMount() {
        if (this.props.prodCode) {
            this.setState({ prodCode: this.props.prodCode }, () => {
                this.getStock(this.props.prodCode)
            });
        }
    }

    handleChange(key, change, index) {
        let { stockLocations } = this.state;
        switch (key) {
            case 'locationToBeTransferred':
                stockLocations[index].toLocation = change;
                this.setState({ stockLocations });
                break;
            case 'amountToBeTransferred':
                stockLocations[index].amountToBeTransferred = change;
                this.setState({ stockLocations });
                break;
            case 'newLocationID':
                this.setState({ newLocationID: change.value });
                break;
            default:
                break;
        }
    }

    getStock(prodCode) {
        let { stock } = this.state;
        if (prodCode) {
            this.stockService.getStock(prodCode).then(response => {
                stock = response.data;
                if (stock) {
                    if (stock.keywayStock) {
                        stock.keywayStock.dimensionUnitName = stock.keywayStock.dimensionUnitName ? stock.keywayStock.dimensionUnitName : "mm";
                    }
                    if (stock.stockWarehouseLocations) {
                        let stockLocations = stock.stockWarehouseLocations;
                        (stockLocations || []).forEach(location => {
                            location.toLocation = '';
                            location.amountToBeTransferred = 0;
                            location.dropDownValues = this.getLocationDropdown(stockLocations, location.locationName);
                        })
                        this.setState({ stockLocations });
                    }
                }
                this.setState({ stock });
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    getSizeString(length, width, height, dimensionUnitName) {
        let size = "";

        if (!dimensionUnitName) {
            dimensionUnitName = 'NA';
        }
        if (length) {
            size = size + " Length: " + length + " " + dimensionUnitName;
        }
        if (width) {
            size = size + " Width: " + width + " " + dimensionUnitName;
        }
        if (height) {
            size = size + " Height: " + height + " " + dimensionUnitName;
        }
        return size ? size.trim() : "";
    }

    transferStock(location) {
        let { prodCode } = this.state;
        let { toggle, refresh } = this.props;
        if (location.amountToBeTransferred > location.qoh) {
            toast.error('Entered quantity is greater than the quantity in the location.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        let request = {
            locationID: location.locationID,
            qty: location.amountToBeTransferred,
            prodCode: prodCode
        };
        this.setState({ isLoadingSubmit: true });
        WarehouseLocationTypeService.transferStockFromLocation(request, location.toLocation).then(response => {
            this.setState({ isLoadingSubmit: false });
            toast.success("Transferred!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.getStock(prodCode);
        }).catch(error => {
            this.setState({ isLoadingSubmit: false });
            toast.success(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    addNewStockToLocation() {
        let { prodCode, newLocationID } = this.state;
        let request = {
            locationID: newLocationID,
            qty: 0,
            prodCode: prodCode
        };

        this.setState({ isAddingLocation: true });

        WarehouseLocationTypeService.addStockToLocation(request)
            .then(response => {
            this.setState({ isAddingLocation: false });
            toast.success("Added!", {
            });
                this.getStock(prodCode);
            })
            .catch(error => {
            this.setState({ isAddingLocation: false });
                toast.error(handleErrorMessage(error), {
            });
        });
    }


    getLocationDropdown(stockLocations, currentLocation) {
        let locationDropdown = [];
        (stockLocations || []).forEach(location => {
            if (location.locationName != currentLocation) {
                locationDropdown.push({ label: location.locationName, value: location.locationID });
            }
        });
        return locationDropdown;
    }


    render() {
        let { isOpen, toggle, hasProcurementRole } = this.props;
        let { stock, isLoadingSubmit, stockLocations, isAddingLocation, newLocationID } = this.state;
        let keywayStock = (stock && stock.keywayStock) ? stock.keywayStock : {};

        let eachUnitName = ((stock && stock.keywayStock && stock.keywayStock.eachUnitName) ? stock.keywayStock.eachUnitName : "NA");
        let whUnitName = ((stock && stock.keywayStock && stock.keywayStock.whUnitName) ? stock.keywayStock.whUnitName : "NA");
        let whUnitQty = ((stock && stock.keywayStock && stock.keywayStock.whUnitQty) ? stock.keywayStock.whUnitQty : 1);

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen, '')}>
                <ModalHeader toggle={() => toggle(!isOpen, '')}>
                    Transfer Stock To Location
                </ModalHeader>
                <ModalBody>
                    {
                        keywayStock && keywayStock.prodCode != null ?
                            <Table borderless className={"mb-0"}>
                                <tbody>
                                    <td>
                                        <div>
                                            <small>ProdCode</small>
                                            <div>{keywayStock.prodCode}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>Description</small>
                                            <div>{keywayStock.description}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>Colour</small>
                                            <div>{keywayStock.colour}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>Size</small>
                                            <div>{this.getSizeString(keywayStock.length, keywayStock.width, keywayStock.height, keywayStock.dimensionUnitName)}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>QOH</small>
                                            <div>{stock.qoh}</div>
                                        </div>
                                    </td>
                                </tbody>
                            </Table>
                            : null
                    }
                    <Row className={"mt-2"}>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Table size={"sm"} responsive className={"ml-1 mr-1"}>
                                <thead>
                                    <tr>
                                        <th className="text-left" style={{ width: 0 }}>Location</th>
                                        <th className="text-center" style={{ width: 0 }}>Type</th>
                                        <th className="text-right" style={{ width: 140 }}>QOH</th>
                                        <th className="text-center" style={{ width: 120 }}>Transfer To</th>
                                        <th className="text-center" style={{ width: 120 }}>Qty</th>
                                        <th className="text-center" style={{ width: 80 }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (stockLocations || []).map((location, locationIndex) => {
                                            return <tr key={locationIndex}>
                                                <td className="text-left">{location.locationName}</td>
                                                <td className="text-center">{location.locationType}</td>
                                                <td className="text-right">
                                                    <StockQtyFormat
                                                        eachUnitName={eachUnitName}
                                                        whUnitName={whUnitName}
                                                        whUnitQty={whUnitQty}
                                                        qty={location.qoh}
                                                        qtyType={STOCK_QTY_TYPE.each}
                                                        format={STOCK_QTY_FORMAT.each}
                                                        emptyPlaceholderText={"-"}
                                                        isPoManage={false}
                                                    />
                                                </td>
                                                <td>
                                                    <Input type="select"
                                                        placeholder="Select"
                                                        value={location.toLocation || ''}
                                                        onChange={(e) => this.handleChange("locationToBeTransferred", e.target.value, locationIndex)}>
                                                        <option value="">Select</option>
                                                        {location.dropDownValues.map((item, index) => (
                                                            <option key={index} value={item.value}>{item.label}</option>
                                                        ))}
                                                    </Input>
                                                </td>

                                                <td className="text-center align-middle">
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>All &nbsp;
                                                                <Input addon type="checkbox"
                                                                    checked={location.qoh === location.amountToBeTransferred && location.amountToBeTransferred != 0}
                                                                    onClick={() => this.handleChange("amountToBeTransferred", location.qoh, locationIndex)} />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type={"number"}
                                                            placeholder={"Amount"}
                                                            onFocus={(event) => event.target.select()}
                                                            value={location.amountToBeTransferred}
                                                            onChange={(e) => this.handleChange("amountToBeTransferred", e.target.value, locationIndex)} />
                                                    </InputGroup>
                                                    <StockQtyFormat
                                                        eachUnitName={eachUnitName}
                                                        whUnitName={whUnitName}
                                                        whUnitQty={whUnitQty}
                                                        qty={location.amountToBeTransferred}
                                                        qtyType={STOCK_QTY_TYPE.each}
                                                        format={STOCK_QTY_FORMAT.each}
                                                        emptyPlaceholderText={""}
                                                        isPoManage={false}
                                                    />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Button color={"primary"} size='sm' className={"mr-2"}
                                                        disabled={isLoadingSubmit || hasProcurementRole || location.amountToBeTransferred === 0}
                                                        onClick={() => this.transferStock(location)}>
                                                        {isLoadingSubmit
                                                            ? <Spinner className={"mr-2"} size={"sm"} />
                                                            : null
                                                        }
                                                        {isLoadingSubmit ? "Transferring " : "Transfer"}
                                                    </Button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className={"mt-2"}>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Label><strong>Add Location</strong></Label>
                            <SearchWarehouseLocationNewComponent
                                handleSelection={(e) => this.handleChange("newLocationID", e, null)}
                                locationID={newLocationID}
                                defaultSelected={""} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <div className="text-left">
                            {newLocationID && (
                            <Button
                                className={"mr-2"}
                                color={"primary"}
                                size='sm'
                                disabled={isAddingLocation}
                                onClick={() => this.addNewStockToLocation()}>
                                {isAddingLocation ? <Spinner size="sm"
                                    className={"mr-2"}
                                    color={"white"} /> : <i className="fa fa-plus mr-2" />}
                                Add New Location
                            </Button>
                            )}
                        </div>
                        <div className="text-right">
                            <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen, '')}>
                                <i className="fa fa-times mr-2" />
                                Cancel
                            </Button>
                        </div>
                    </Row>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        );
    }
}


TransferStockToLocationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    locationID: PropTypes.string.isRequired,
    prodCode: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired
};