import React, { Component } from 'react';
import { Input, Row, Col, Label } from 'reactstrap';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import classnames from 'classnames';


const filterConditions =
    [
        { 'label': 'Search Duplicate Emails', 'value': 'Duplicate Emails' },
        { 'label': 'Search Empty Customers', 'value': 'Empty Customers' },
        { 'label': 'Search Empty Emails', 'value': 'Empty Emails' },
        { 'label': 'Search Empty Names', 'value': 'Empty Names' },
        { 'label': 'Search Empty Phones', 'value': 'Empty Phones' },
        { 'label': 'Search Empty Mobiles', 'value': 'Empty Mobiles' }
    ];
export default class SearchBannerContactMaster extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

    }

    handleChange(accountID) {
        this.props.handleChange(accountID, 'accountID');
    }

    render() {
        let { filterRequest, allow_includeParentAccountContacts, contactTypes } = this.props;
        let Conditions = filterConditions;
        return (
            <div>
                <Row>
                    <Col xl={4} lg={3} md={6} sm={12} xs={12}>
                        <Label>Customer</Label>
                        <div style={{ zIndex: '2', position: 'relative' }}>
                            <SearchDebtorAcccount
                                handleAccountChange={this.handleChange}
                                selectedAccountID={filterRequest.accountID}
                                defaultAccountID={filterRequest.accountID}
                            />
                        </div>
                    </Col>

                    <Col xl={3} lg={3} md={4} sm={12} xs={12} style={{marginTop:'25px'}}>
                        <div className="mt-2">
                            <span className="mr-2">
                                <a href={"javascript:void(0)"}
                                    disabled={allow_includeParentAccountContacts}
                                    onClick={() => this.props.handleChange(!filterRequest.includeParentAccountContacts, "includeParentAccountContacts")}>
                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                        "fa-check-square-o": filterRequest.includeParentAccountContacts,
                                        "fa-square-o": !filterRequest.includeParentAccountContacts,
                                    }
                                    )} />
                                </a>&nbsp;Include Parent Accounts
                             </span>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={12} xs={12} style={{ marginTop: '25px' }}>
                        <div className="mt-2">
                            <span className="mr-2">
                                <a href={"javascript:void(0)"}
                                    disabled={allow_includeParentAccountContacts}
                                    onClick={() => this.props.handleChange(!filterRequest.includeChildAccountContacts, "includeChildAccountContacts")}>
                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                        "fa-check-square-o": filterRequest.includeChildAccountContacts,
                                        "fa-square-o": !filterRequest.includeChildAccountContacts,
                                    }
                                    )} />
                                </a>&nbsp;Include Child Accounts
                             </span>
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                        <Label>Customer Category</Label>
                        <div>
                            <Input type={"select"}
                                   placeholder={"Search Category..."}
                                   name={"categoryID"}
                                   value={filterRequest.categoryID || ''}
                                   onChange={(e) => this.props.handleChange(e.target.value, "categoryID")}>
                                <option value="0">All</option>
                                {this.props.debtorCategories.map((category, index) =>
                                    <option key={index} value={category.debtorCategoryID}>{category.name}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Name</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"name"}
                                value={filterRequest.name}
                                onChange={(e) => this.props.handleChange(e.target.value, "name")} />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Email</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"email"}
                                value={filterRequest.email}
                                onChange={(e) => this.props.handleChange(e.target.value, "email")} />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Phone</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"phone"}
                                value={filterRequest.phone}
                                onChange={(e) => this.props.handleChange(e.target.value, "phone")} />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Mobile</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"mobile"}
                                value={filterRequest.mobile}
                                onChange={(e) => this.props.handleChange(e.target.value, "mobile")} />
                        </div>
                    </Col>
                    <Col xl={4} lg={3} md={4} sm={12} xs={12}>
                        <Label>Filter By Conditions</Label>
                        <div>
                            <Input type={"select"}
                                name={"condition"}
                                value={filterRequest.condition ? filterRequest.condition : ""}
                                onChange={(e) => this.props.handleChange(e.target.value, "condition")}>
                                <option value={null}>All</option>
                                {(Conditions || []).map((option, index) =>
                                    <option key={index} value={option.value}>{option.label}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col xl={2} lg={2} md={3} sm={6} xs={12}>
                        <div>
                            <Label>Contact Type</Label>
                            <Input className="form-control"
                                type="select"
                                value={filterRequest.contactTypeID}
                                onChange={(e) => this.props.handleChange(e.target.value, "contactTypeID")}
                                name="contactTypeID"
                                placeholder="Select contact type...">
                                <option key={100} value={0}>All</option>
                                {(contactTypes || []).map((item, indexx) => <option key={indexx}
                                    value={item.contactTypeID}>{item.name}</option>)}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={3} sm={6} xs={12}>
                        <Label>Account Status</Label>
                        <div>
                            <Input type={"select"}
                                   placeholder={"Search..."}
                                   name={"accountStatus"}
                                   value={filterRequest.accountStatus}
                            onChange={(e) => this.props.handleChange(e.target.value, "accountStatus")} >

                                <option value="">All</option>
                                {this.props.debtorAccountStatus.map((status, index) =>
                                    <option key={index} value={status.description}> {status.description} </option>
                                )}

                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Email Invoice</Label>
                        <div className={"text-center"}>
                            <Input type="select" name="isEmailInvoice" value={filterRequest.isEmailInvoice}
                                onChange={(e) => this.props.handleChange(e.target.value, "isEmailInvoice")}>
                                <option value="" >All</option>
                                <option value="True" >Yes</option>
                                <option value="False" >No</option>

                            </Input>
                        </div>
                        
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Email Statement</Label>
                        <div className={"text-center"}>
                            <Input type="select" name="isEmailStatement" value={filterRequest.isEmailStatement}
                                onChange={(e) => this.props.handleChange(e.target.value, "isEmailStatement")}>
                                <option value="" >All</option>
                                <option value="True" >Yes</option>
                                <option value="False" >No</option>

                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Email Dispatch Notice</Label>
                        <div className={"text-center"}>
                            <Input type="select" name="isEmailDispatchNotice" value={filterRequest.isEmailDispatchNotice}
                                onChange={(e) => this.props.handleChange(e.target.value, "isEmailDispatchNotice")}>
                                <option value="" >All</option>
                                <option value="True" >Yes</option>
                                <option value="False" >No</option>

                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Email Marketing</Label>
                        <div className={"text-center"}>
                            <Input type="select" name="isEmailMarketing" value={filterRequest.isEmailMarketing}
                                onChange={(e) => this.props.handleChange(e.target.value, "isEmailMarketing")}>
                                <option value="" >All</option>
                                <option value="True" >Yes</option>
                                <option value="False" >No</option>

                            </Input>
                        </div>
                    </Col>

                </Row>
            </div>
        );
    }
}