import React, { Component } from 'react';
import {
    Row, Col, FormGroup, Label, Input, FormText, Form
} from 'reactstrap';
import AddressService from '../../../services/AddressService';
import { toast } from 'react-toastify';
import {handleErrorMessage} from "../../../services/CommonService";

export default class WarehouseConatct extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseObj: this.props.warehouseObj,
            formErrors: this.props.formErrors,
            contactTypes: [],
        };
    }

    componentDidMount() {
        this.fetchContactTypes();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.warehouseObj != this.state.warehouseObj) {
            this.setState({ warehouseObj: nextProps.warehouseObj });
        }

        if (nextProps.formErrors != this.state.formErrors) {
            this.setState({ formErrors: nextProps.formErrors });
        }
    }

    fetchContactTypes() {
        AddressService.getContactTypes().then(response => {
            this.setState({ contactTypes: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        });
    }

    handleChange(change, key) {
        let { warehouseObj } = this.state;
        let { contact } = warehouseObj;
        contact[key] = change;

        this.setState({ warehouseObj });
        this.props.handleContactChange(warehouseObj);
    }

    render() {
        let { warehouseObj, formErrors, contactTypes } = this.state;
        return (
            <div>
                <Form>
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="firstName">First name</Label>
                                <Input autoComplete="off"
                                    invalid={formErrors.firstName !== undefined}
                                    type="text"
                                    name="firstName"
                                    onChange={(e) => this.handleChange(e.target.value, 'firstName')}
                                    value={warehouseObj.contact.firstName} placeholder="Enter first name here."
                                    id="FirstName" />
                                {formErrors.firstName != null &&
                                    <FormText color="danger"> {formErrors.firstName}</FormText>}
                            </FormGroup>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="lastName">Last name</Label>
                                <Input autoComplete="off" type="text" name="lastName"
                                    onChange={(e) => this.handleChange(e.target.value, 'lastName')}
                                    value={warehouseObj.contact.lastName} placeholder="Enter last name here."
                                    id="LastName" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Contact Type</Label>
                                <Input className="form-control"
                                    invalid={formErrors.contactTypeID !== undefined}
                                    type="select"
                                    value={warehouseObj.contact.contactTypeID}
                                    onChange={(e) => this.handleChange(e.target.value, 'contactTypeID')}
                                    name="contactTypeID"
                                    placeholder="Select Contact Type...">
                                    <option key={100} value={0}>Select Contact Type</option>
                                    {(contactTypes || []).map((item, indexx) => <option key={indexx}
                                        value={item.contactTypeID}>{item.name}</option>)}
                                </Input>
                                {formErrors.contactTypeID != null &&
                                    <FormText color="danger"> {formErrors.contactTypeID}</FormText>}
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="jobTitle">Position</Label>
                                <Input autoComplete="off" type="text" name="jobTitle"
                                    onChange={(e) => this.handleChange(e.target.value, 'jobTitle')}
                                    value={warehouseObj.contact.jobTitle} placeholder="Enter position here."
                                    id="JobTitle" />
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input autoComplete="off" type="number" name="phone"
                                    onChange={(e) => this.handleChange(e.target.value, 'phone')}
                                    value={warehouseObj.contact.phone} placeholder="Enter phone number here."
                                    id="Phone" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="mobile">Mobile</Label>
                                <Input autoComplete="off"
                                    invalid={formErrors.mobile !== undefined}
                                    type="number"
                                    name="mobile"
                                    onChange={(e) => this.handleChange(e.target.value, 'mobile')}
                                    value={warehouseObj.contact.mobile} placeholder="Enter mobile number here."
                                    id="Mobile" />
                                {formErrors.mobile != null > 0 &&
                                    <FormText color="danger"> {formErrors.mobile}</FormText>}
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input autoComplete="off"
                                    invalid={formErrors.email !== undefined}
                                    type="text"
                                    name="email"
                                    onChange={(e) => this.handleChange(e.target.value, 'email')}
                                    value={warehouseObj.contact.email} placeholder="Enter email here." id="Email" />
                                {formErrors.email != null > 0 &&
                                    <FormText color="danger"> {formErrors.email}</FormText>}
                            </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="fax">Fax</Label>
                                <Input autoComplete="off" type="number" name="fax"
                                    onChange={(e) => this.handleChange(e.target.value, 'fax')}
                                    value={warehouseObj.contact.fax} placeholder="Enter fax no here." id="Fax" />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }




}