import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, getSorted, scrollTo} from '../../../../../../../services/CommonService';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';

import ziptrakUtil from './ZiptrakInternalUtil';
import {pricingMatrixVariant} from '../../../../../../../store/sales/order/productBuilder/PricingMatrixConstants';
import NumberFormat from "react-number-format";
import ZiptrakInternalBOMModal from "../internal/ZiptrakInternalBOMModal";
import ZiptrakInternalConsolidatedBOMModal from "../internal/ZiptrakInternalConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../../custom/CustomUtil";
import {
    PRODUCT_BUILDER_CUSTOM_BUILD,
    PRODUCT_BUILDER_ZIPTRAK_INTERNAL_PRODCODE
} from "../../../../../../../store/AppConstants";

const baseUrl = window.location.origin;

export default class ZiptrakInternal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = ziptrakUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = ziptrakUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = product.items[itemIndex].configuration.location.selected.value;
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_ZIPTRAK_INTERNAL_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if (stocksByProdCode) {
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o => o.prodCode === s.prodCode);
                        }
                        if (stock) {
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
            default:
                break;
        }
        product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;
        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //location
        configurationIndex = findIndex(configurations, "attributeKey", "location");
        product.items[itemIndex].configuration.location = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.location.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.location.selected.value = context.salesOrderItemZiptrakInternal.location;
        } else {
            product.items[itemIndex].configuration.location.selected.value = "Location " + (itemIndex + 1);
        }
        product.items[itemIndex].configuration.location.formError = {isValid: true, message: "", dom: null,};

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemZiptrakInternal.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.min = 0;// default
        product.items[itemIndex].configuration.width.max = 0;// default
        product.items[itemIndex].configuration.width.selected = {};

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemZiptrakInternal.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1500;
        }

        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.min = 0;//default
        product.items[itemIndex].configuration.drop.max = 0;//default
        product.items[itemIndex].configuration.drop.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemZiptrakInternal.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }

        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };


        //fabric type
        configurationIndex = findIndex(configurations, "attributeKey", "fabricType");
        product.items[itemIndex].configuration.fabricType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        fabrics = getSorted(fabrics, "sortIndex", true, "fabricName", true);
        product.items[itemIndex].configuration.fabricType.options = fabrics.map(f => {
            return {
                optionKey: f.fabricName,
                optionLabel: salesOrderProductBuilderV1Service.getFabricRangeLabel(f),
                ...f
            }
        }).filter(o => {
            return (salesOrderProductBuilderV1Service.shouldFabricRangeIncluded( (!isEmpty(context) ? context.salesOrderItemZiptrakInternal.fabricType : ""), o, this.props.currentUser)
            )
        });
        product.items[itemIndex].configuration.fabricType.finalOptions = product.items[itemIndex].configuration.fabricType.options;
        product.items[itemIndex].configuration.fabricType.selected = {};
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", context.salesOrderItemZiptrakInternal.fabricType);
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;

        //fabricColour
        configurationIndex = findIndex(configurations, "attributeKey", "fabricColour");
        product.items[itemIndex].configuration.fabricColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fabricColour.selected = {
            value: {},
            dropdownValue: "",
            isSelectable : true,
        };
        product.items[itemIndex].configuration.fabricType.selected.value.colors = getSorted(product.items[itemIndex].configuration.fabricType.selected.value.colors, "color", true);
        product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors.map((o, oIndex) => {
            return {
                key: oIndex,
                optionKey: o.color,
                optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                value: o.color,
                label: (<div key={oIndex}>
                    <span>{o.color}</span>
                </div>),
                ...o
            }
        });
        product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.filter(o => {
            return (
                salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
            )
        });

        if (product.items[itemIndex].configuration.fabricColour.finalOptions.length > 0) {
            if (!isEmpty(context)) {
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.fabricColour);
            } else {
                optionIndex = 0;
            }
            optionIndex = optionIndex > -1 ? optionIndex : 0;
            product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
            product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
        }
        product.items[itemIndex].configuration.fabricColour.formError = {isValid: true, message: "", dom: null,};
        product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, context);

        //isStandardFabrication
        configurationIndex = findIndex(configurations, "attributeKey", "isStandardFabrication");
        product.items[itemIndex].configuration.isStandardFabrication = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.isStandardFabrication.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.isStandardFabrication.selected.value = context.salesOrderItemZiptrakInternal.isStandardFabrication;
        } else {
            product.items[itemIndex].configuration.isStandardFabrication.selected.value = false;
        }

        //operation
        configurationIndex = findIndex(configurations, "attributeKey", "operation");
        product.items[itemIndex].configuration.operation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.operation.selected = {};
        product.items[itemIndex].configuration.operation.finalOptions = product.items[itemIndex].configuration.operation.options;
        product.items[itemIndex].configuration.operation.finalOptions = getSorted(product.items[itemIndex].configuration.operation.finalOptions, "condition1", true);
        product.items[itemIndex].configuration.operation.finalOptions.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.operation);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
        product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;
        product.items[itemIndex].configuration.operation.formError = {isValid: true, message: "", dom: null,};


        //controlSide
        configurationIndex = findIndex(configurations, "attributeKey", "controlSide");
        product.items[itemIndex].configuration.controlSide = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.controlSide.selected = {};
        product.items[itemIndex].configuration.controlSide.finalOptions = product.items[itemIndex].configuration.controlSide.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.controlSide);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.controlSide.selected.value = optionIndex > -1 ? product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex] : {};
        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = optionIndex > -1 ? product.items[itemIndex].configuration.controlSide.selected.value.optionKey : "";
        product.items[itemIndex].configuration.controlSide.formError = {isValid: true, message: "", dom: null,};


        //keyway
        configurationIndex = findIndex(configurations, "attributeKey", "keyway");
        product.items[itemIndex].configuration.keyway = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.keyway.selected = {};
        product.items[itemIndex].configuration.keyway.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.keyway.finalOptions = product.items[itemIndex].configuration.keyway.options.filter(o => o.condition1 === "dropdown");
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.keyway.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.keyway);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.keyway.selected.value = product.items[itemIndex].configuration.keyway.finalOptions[optionIndex];
        product.items[itemIndex].configuration.keyway.selected.dropdownValue = product.items[itemIndex].configuration.keyway.selected.value.optionKey;
        product.items[itemIndex].configuration.keyway.formError = {isValid: true, message: "", dom: null,};


        //pelmetSize
        configurationIndex = findIndex(configurations, "attributeKey", "pelmetSize");
        product.items[itemIndex].configuration.pelmetSize = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.pelmetSize.selected = {};
        product.items[itemIndex].configuration.pelmetSize.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.pelmetSize.finalOptions = product.items[itemIndex].configuration.pelmetSize.options.filter(o => o.condition1 === "dropdown");
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.pelmetSize.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.pelmetSize);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.pelmetSize.selected.value = product.items[itemIndex].configuration.pelmetSize.finalOptions[optionIndex];
        product.items[itemIndex].configuration.pelmetSize.selected.dropdownValue = product.items[itemIndex].configuration.pelmetSize.selected.value.optionKey;
        product.items[itemIndex].configuration.pelmetSize.formError = {isValid: true, message: "", dom: null,};


        //frameColour
        configurationIndex = findIndex(configurations, "attributeKey", "frameColour");
        product.items[itemIndex].configuration.frameColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.frameColour.selected = {};
        product.items[itemIndex].configuration.frameColour.finalOptions = product.items[itemIndex].configuration.frameColour.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.frameColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
        product.items[itemIndex].configuration.frameColour.formError = {isValid: true, message: "", dom: null,};


        //channels
        configurationIndex = findIndex(configurations, "attributeKey", "channels");
        product.items[itemIndex].configuration.channels = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.channels.selected = {};
        product.items[itemIndex].configuration.channels.finalOptions = product.items[itemIndex].configuration.channels.options.filter(o => !o.parentOptionId);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.channels.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.channels);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.channels.selected.value = product.items[itemIndex].configuration.channels.finalOptions[optionIndex];
        product.items[itemIndex].configuration.channels.selected.dropdownValue = product.items[itemIndex].configuration.channels.selected.value.optionKey;
        product.items[itemIndex].configuration.channels.formError = {isValid: true, message: "", dom: null,};


        //bottomChannel
        configurationIndex = findIndex(configurations, "attributeKey", "bottomChannel");
        product.items[itemIndex].configuration.bottomChannel = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomChannel.selected = {};
        product.items[itemIndex].configuration.bottomChannel.finalOptions = product.items[itemIndex].configuration.bottomChannel.options.filter(o => !o.parentOptionId);
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.bottomChannel.finalOptions, 'optionKey', context.salesOrderItemZiptrakInternal.bottomChannel);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.bottomChannel.selected.value = product.items[itemIndex].configuration.bottomChannel.finalOptions[optionIndex];
        product.items[itemIndex].configuration.bottomChannel.selected.dropdownValue = product.items[itemIndex].configuration.bottomChannel.selected.value.optionKey;
        product.items[itemIndex].configuration.bottomChannel.formError = {isValid: true, message: "", dom: null,};


        //bottomBar
        configurationIndex = findIndex(configurations, "attributeKey", "bottomBar");
        product.items[itemIndex].configuration.bottomBar = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bottomBar.selected = {};
        product.items[itemIndex].configuration.bottomBar.finalOptions = product.items[itemIndex].configuration.bottomBar.options;

        //weightBar
        configurationIndex = findIndex(configurations, "attributeKey", "weightBar");
        product.items[itemIndex].configuration.weightBar = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.weightBar.selected = {};
        product.items[itemIndex].configuration.weightBar.finalOptions = product.items[itemIndex].configuration.weightBar.options;

        //splineTape
        configurationIndex = findIndex(configurations, "attributeKey", "splineTape");
        product.items[itemIndex].configuration.splineTape = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.splineTape.selected = {};
        product.items[itemIndex].configuration.splineTape.finalOptions = product.items[itemIndex].configuration.splineTape.options;

        //spring
        configurationIndex = findIndex(configurations, "attributeKey", "spring");
        product.items[itemIndex].configuration.spring = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.spring.selected = {};
        product.items[itemIndex].configuration.spring.finalOptions = product.items[itemIndex].configuration.spring.options;

        //consumables
        configurationIndex = findIndex(configurations, "attributeKey", "consumables");
        product.items[itemIndex].configuration.consumables = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.consumables.selected = {};
        product.items[itemIndex].configuration.consumables.finalOptions = product.items[itemIndex].configuration.consumables.options;


        //insulationFoam
        configurationIndex = findIndex(configurations, "attributeKey", "insulationFoam");
        product.items[itemIndex].configuration.insulationFoam = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.insulationFoam.selected = {};
        product.items[itemIndex].configuration.insulationFoam.finalOptions = product.items[itemIndex].configuration.insulationFoam.options;

        //windoware15ChannelRemote
        configurationIndex = findIndex(configurations, "attributeKey", "windoware15ChannelRemote");
        product.items[itemIndex].configuration.windoware15ChannelRemote = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windoware15ChannelRemote.finalOptions = product.items[itemIndex].configuration.windoware15ChannelRemote.options;

        product.items[itemIndex].configuration.windoware15ChannelRemote.selected = {};
        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isSelectable = false;
        product.items[itemIndex].configuration.windoware15ChannelRemote.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = context.salesOrderItemZiptrakInternal.windoware15ChannelRemote;
        } else {
            product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = false;
        }


        //windowareBridge
        configurationIndex = findIndex(configurations, "attributeKey", "windowareBridge");
        product.items[itemIndex].configuration.windowareBridge = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.windowareBridge.finalOptions = product.items[itemIndex].configuration.windowareBridge.options;
        product.items[itemIndex].configuration.windowareBridge.selected = {};
        product.items[itemIndex].configuration.windowareBridge.selected.isSelectable = false;
        product.items[itemIndex].configuration.windowareBridge.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.windowareBridge.selected.value = context.salesOrderItemZiptrakInternal.windowareBridge;
        } else {
            product.items[itemIndex].configuration.windowareBridge.selected.value = false;
        }

        //chargingCable
        configurationIndex = findIndex(configurations, "attributeKey", "chargingCable");
        product.items[itemIndex].configuration.chargingCable = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.chargingCable.finalOptions = product.items[itemIndex].configuration.chargingCable.options;
        product.items[itemIndex].configuration.chargingCable.selected = {};
        product.items[itemIndex].configuration.chargingCable.selected.isSelectable = true;
        product.items[itemIndex].configuration.chargingCable.selected.isVisible = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.chargingCable.selected.value = context.salesOrderItemZiptrakInternal.chargingCable;
        } else {
            product.items[itemIndex].configuration.chargingCable.selected.value = false;
        }


        //somfy20CmExtCableForLiIonForCassetteV2
        configurationIndex = findIndex(configurations, "attributeKey", "somfy20CmExtCableForLiIonForCassetteV2");
        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2 = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.finalOptions = product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.options;
        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected = {};
        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable = true;
        product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isVisible = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value = context.salesOrderItemZiptrakInternal.somfy20CmExtCableForLiIonForCassetteV2;
        } else {
            product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value = false;
        }

        //somfyLiIonWirefreeChargerV2
        configurationIndex = findIndex(configurations, "attributeKey", "somfyLiIonWirefreeChargerV2");
        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2 = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.finalOptions = product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.options;
        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected = {};
        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable = true;
        product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.isVisible = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.value = context.salesOrderItemZiptrakInternal.somfyLiIonWirefreeChargerV2;
        } else {
            product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.value = false;
        }

        //somfySituo5PureRemoteControl
        configurationIndex = findIndex(configurations, "attributeKey", "somfySituo5PureRemoteControl");
        product.items[itemIndex].configuration.somfySituo5PureRemoteControl = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.finalOptions = product.items[itemIndex].configuration.somfySituo5PureRemoteControl.options;
        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected = {};
        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isSelectable = false;
        product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.isVisible = false;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.value = context.salesOrderItemZiptrakInternal.somfySituo5PureRemoteControl;
        } else {
            product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.value = false;
        }

        //pullDownStick
        configurationIndex = findIndex(configurations, "attributeKey", "pullDownStick");
        product.items[itemIndex].configuration.pullDownStick = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.pullDownStick.finalOptions = product.items[itemIndex].configuration.pullDownStick.options;
        product.items[itemIndex].configuration.pullDownStick.selected = {};
        product.items[itemIndex].configuration.pullDownStick.selected.isSelectable = true;
        product.items[itemIndex].configuration.pullDownStick.selected.isVisible = true;
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.pullDownStick.selected.value = context.salesOrderItemZiptrakInternal.pullDownStick;
        } else {
            product.items[itemIndex].configuration.pullDownStick.selected.value = false;
        }


        //deduction
        product.items[itemIndex].configuration.deduction = {
            deductionRules: [],
            selected: {
                value: {
                    deduction: 0
                }
            }
        };

       // product = this.updateItemConfigurationOptions("pelmetSize", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, null);
        product = this.updateItemConfigurationOptions("bottomChannel", product, itemIndex, null);

        product = ziptrakUtil.updateRules("width", product, itemIndex, order);
        product = ziptrakUtil.updateRules("drop", product, itemIndex, order);
        product = ziptrakUtil.updateRules("accessories", product, itemIndex, order);

        product = ziptrakUtil.updateFormError("width", product, itemIndex);
        product = ziptrakUtil.updateFormError("drop", product, itemIndex);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let optionIndex = -1,
            setIndex = -1,
            width = product.items[itemIndex].configuration.width.selected.value,
            drop = product.items[itemIndex].configuration.drop.selected.value;
            let {currentUser} = this.props;
        if (key) {
            switch (key) {
                case "set":
                    let variant = pricingMatrixVariant.DEFAULT_VARIANT;
                    product.items[itemIndex].configuration.set.selected.value.priceMatrix = product.builder.priceMatrices.find(x => {
                        return x.bmCode === product.items[itemIndex].configuration.fabricType.selected.value.primaryBMCode && x.variant === variant
                    });
                    break;
                case "width":
                    if (width && !isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        product.items[itemIndex].configuration.set.selected.value.widthSet = (product.items[itemIndex].configuration.set.selected.value.priceMatrix.widths || []).find(x => x.width >= width);
                    }
                    break;
                case "drop":
                    if (drop && !isEmpty(product.items[itemIndex].configuration.set.selected.value.priceMatrix)) {
                        if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.widthSet)) {
                            product.items[itemIndex].configuration.set.selected.value.dropSet = product.items[itemIndex].configuration.set.selected.value.widthSet.drops.find(x => x.drop >= drop);

                            if (!isEmpty(product.items[itemIndex].configuration.set.selected.value.dropSet)) {
                                product.items[itemIndex].configuration.set.selected.value.price = product.items[itemIndex].configuration.set.selected.value.dropSet.price;
                            }
                            if (product.items[itemIndex].configuration.set.selected.value.widthSet && product.items[itemIndex].configuration.set.selected.value.dropSet) {
                                product.items[itemIndex].configuration.set.selected.value.optionKey = "Set " + product.items[itemIndex].configuration.set.selected.value.widthSet.width + " x " + product.items[itemIndex].configuration.set.selected.value.dropSet.drop;
                            }
                        }
                    }
                    break;
                case "controlSide":
                    if (product.items[itemIndex].configuration.controlSide.selected.isSelectable) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.controlSide.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, "optionKey", product.items[itemIndex].configuration.controlSide.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                    }
                    break;
                case "keyway":
                    //find applicable keyway from matrix
                    optionIndex = product.items[itemIndex].configuration.keyway.options.findIndex(o => o.condition1 === "matrix");
                    if (optionIndex > -1 && width && drop) {
                        if (product.items[itemIndex].configuration.operation.selected.value.optionKey === "Somfy Motor Sonesse 40 RTS 240V AC") {
                            setIndex = product.items[itemIndex].configuration.keyway.options[optionIndex].sets.findIndex(set => set.setKey === "55mm");
                        } else {
                            setIndex = product.items[itemIndex].configuration.keyway.options[optionIndex].sets.findIndex(set => ((width >= set.min) && (width <= set.max) && (drop >= set.secondaryMin) && (drop <= set.secondaryMax)));
                        }
                        if (setIndex > -1) {
                            //find that applicable keyway in final options
                            optionIndex = product.items[itemIndex].configuration.keyway.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.keyway.options[optionIndex].sets[setIndex].setKey);
                            product.items[itemIndex].configuration.keyway.selected.value = product.items[itemIndex].configuration.keyway.finalOptions[optionIndex];
                        }
                    }
                    break;
                case "fabricColour":
                    //react-select dropdown component options adding label and value ------------------------
                    product.items[itemIndex].configuration.fabricType.selected.value.colors = getSorted(product.items[itemIndex].configuration.fabricType.selected.value.colors, "color", true);
                    product.items[itemIndex].configuration.fabricColour.options = product.items[itemIndex].configuration.fabricType.selected.value.colors;
                    product.items[itemIndex].configuration.fabricColour.finalOptions = product.items[itemIndex].configuration.fabricColour.options.map((o, oIndex) => {
                        return {
                            key: oIndex,
                            optionKey: o.color,
                            optionLabel:  salesOrderProductBuilderV1Service.getFabricColorLabel(o),
                            value: o.color,
                            label: (<div key={oIndex}>
                                <span>{o.color}</span>
                            </div>),
                            ...o
                        }
                    }).filter(o => {
                        return (
                            salesOrderProductBuilderV1Service.shouldFabricColorIncluded(product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.optionKey : "", o, currentUser)
                        )
                    });
                    if (product.items[itemIndex].configuration.fabricColour.finalOptions.length > 0) {
                        optionIndex = -1;
                        if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", product.items[itemIndex].configuration.fabricColour.selected.value.optionKey);
                        }
                        optionIndex = optionIndex > -1 ? optionIndex : 0;
                        product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.fabricColour.selected.value = {};
                        product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                        console.error("No fabric colour found for the selection")
                    }
                    break;
                case "basePrice":
                    //for updating bmCode
                    product = this.updateItemConfigurationOptions("set", product, itemIndex, null);
                    //for updating width set
                    product = this.updateItemConfigurationOptions("width", product, itemIndex, null);
                    // for updating drop set and update price
                    product = this.updateItemConfigurationOptions("drop", product, itemIndex, null);
                    break;
                case "bottomChannel":
                    product.items[itemIndex].configuration.bottomChannel.finalOptions = product.items[itemIndex].configuration.bottomChannel.options.filter(o => o.condition1.includes(product.items[itemIndex].configuration.channels.selected.value.optionKey));
                    optionIndex = -1;
                    if (!isEmpty(product.items[itemIndex].configuration.bottomChannel.selected.value)) {
                        optionIndex = findIndex(product.items[itemIndex].configuration.bottomChannel.finalOptions, "optionKey", product.items[itemIndex].configuration.bottomChannel.selected.value.optionKey);
                    }
                    optionIndex = optionIndex > -1 ? optionIndex : 0;
                    product.items[itemIndex].configuration.bottomChannel.selected.value = product.items[itemIndex].configuration.bottomChannel.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomChannel.selected.dropdownValue = product.items[itemIndex].configuration.bottomChannel.selected.value.optionKey;
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.selected.value = change;
                product = ziptrakUtil.updateFormError("location", product, itemIndex);
                break;
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = ziptrakUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = change ? parseInt(change) : change;
                if (product.items[itemIndex].configuration.width.selected.value) {
                    product = this.updateItemConfigurationOptions("width", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("drop", product, itemIndex, null);
                }
                product = ziptrakUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = change ? parseInt(change) : change;
                if (product.items[itemIndex].configuration.drop.selected.value) {
                    product = this.updateItemConfigurationOptions("drop", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                }
                product = ziptrakUtil.updateFormError("drop", product, itemIndex);
                break;
            case "frameColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.frameColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.frameColour.selected.dropdownValue = product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                }
                break;
            case "operation":
                optionIndex = findIndex(product.items[itemIndex].configuration.operation.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.operation.selected.value = product.items[itemIndex].configuration.operation.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.operation.selected.dropdownValue = product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    product = ziptrakUtil.updateRules("width", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("drop", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("controlSide", product, itemIndex, order);
                    product = ziptrakUtil.updateRules("accessories", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("controlSide", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("keyway", product, itemIndex, null);
                    product = ziptrakUtil.updateFormError("width", product, itemIndex, order);
                    product = ziptrakUtil.updateFormError("drop", product, itemIndex, order);
                }
                break;
            case "controlSide":
                optionIndex = findIndex(product.items[itemIndex].configuration.controlSide.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.controlSide.selected.dropdownValue = product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                }
                break;
            case "fabricType":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricType.selected.value = product.items[itemIndex].configuration.fabricType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricType.selected.dropdownValue = product.items[itemIndex].configuration.fabricType.selected.value.optionKey;

                    product = this.updateItemConfigurationOptions("fabricColour", product, itemIndex, null);
                    product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, null);

                }
                break;
            case "fabricColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.fabricColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = product.items[itemIndex].configuration.fabricColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = product.items[itemIndex].configuration.fabricColour.selected.value.optionKey;
                }
                break;
            case "channels":
                optionIndex = findIndex(product.items[itemIndex].configuration.channels.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.channels.selected.value = product.items[itemIndex].configuration.channels.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.channels.selected.dropdownValue = product.items[itemIndex].configuration.channels.selected.value.optionKey;
                    product = this.updateItemConfigurationOptions("bottomChannel", product, itemIndex, null);
                }
                break;
            case "bottomChannel":
                optionIndex = findIndex(product.items[itemIndex].configuration.bottomChannel.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.bottomChannel.selected.value = product.items[itemIndex].configuration.bottomChannel.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.bottomChannel.selected.dropdownValue = product.items[itemIndex].configuration.bottomChannel.selected.value.optionKey;
                }
                break;
            case "windoware15ChannelRemote":
                product.items[itemIndex].configuration.windoware15ChannelRemote.selected.value = change;
                break;
            case "windowareBridge":
                product.items[itemIndex].configuration.windowareBridge.selected.value = change;
                break;
            case "chargingCable":
                product.items[itemIndex].configuration.chargingCable.selected.value = change;
                break;
            case "somfy20CmExtCableForLiIonForCassetteV2":
                product.items[itemIndex].configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value = change;
                break;
            case "somfyLiIonWirefreeChargerV2":
                product.items[itemIndex].configuration.somfyLiIonWirefreeChargerV2.selected.value = change;
                break;
            case "somfySituo5PureRemoteControl":
                product.items[itemIndex].configuration.somfySituo5PureRemoteControl.selected.value = change;
                break;
            case "pullDownStick":
                product.items[itemIndex].configuration.pullDownStick.selected.value = change;
                break;
            default:
                break;
        }

        if (isComponentUpdateRequired) {
            //immediately update component
            product = ziptrakUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }


    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser, discountByProductCode} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 45}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div>#</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 135}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Location</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Quantity</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 275}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Operation</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Width(mm)</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 100}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Drop(mm)</div>
                                        </div>
                                    </th>
                                    {/* <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Keyway</div>
                                        </div>
                                    </th>*/}
                                    <th style={{minWidth: 230}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Fabric</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 190}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Frame Colour</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 152}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Fixing Type</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 152}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Bottom Channel</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Unit Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 50}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Price</div>
                                        </div>
                                    </th>
                                    <th style={{minWidth: 125}}>
                                        <div className={"d-flex justify-content-center"}>
                                            <div className={"align-self-center"}>Action</div>
                                        </div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th style={{minWidth: 90}}>
                                                <div className={"d-flex justify-content-center"}>
                                                    <div className={"align-self-center"}>
                                                        <Button color={"link"}
                                                                onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                                    </div>
                                                </div>
                                            </th>
                                            : null

                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    {itemIndex + 1}
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="text" name="location"
                                                                   value={item.configuration.location.selected.value}
                                                                   onChange={(e) => this.handleChange(e.target.value, "location", itemIndex, true)}
                                                                   placeholder="location"/>
                                                            {
                                                                item.configuration.location.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                            }
                                                            {
                                                                item.configuration.location.formError.isWarning
                                                                    ? <Badge color="warning">
                                                                        {item.configuration.location.formError.message}
                                                                    </Badge>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div>
                                                            <Input type="number" name="quantity"
                                                                   invalid={!item.configuration.quantity.formError.isValid}
                                                                   value={item.configuration.quantity.selected.value}
                                                                   min={item.configuration.quantity.min}
                                                                   max={item.configuration.quantity.max}
                                                                   onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                   onFocus={(event) => event.target.select()}
                                                                   placeholder="quantity"/>

                                                            {
                                                                item.configuration.quantity.formError.isValid
                                                                    ? null
                                                                    : <Badge color="danger">
                                                                        {item.configuration.quantity.formError.message}
                                                                    </Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="operation"
                                                           value={item.configuration.operation.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "operation", itemIndex, true)}>
                                                        <option value={""} disabled>Select operation</option>

                                                        {
                                                            (item.configuration.operation.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>

                                                    {ziptrakUtil.isMotorised(product, itemIndex)
                                                        ? <div>
                                                            <Input className={"mt-2"} type="select" name="controlSide"
                                                                   value={item.configuration.controlSide.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "controlSide", itemIndex, true)}>
                                                                <option value={""} disabled>Select motor side</option>
                                                                {
                                                                    (item.configuration.controlSide.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            {
                                                                ziptrakUtil.isVisibleAccessories("Windoware", product, itemIndex)
                                                                    ? <div className="mt-1">
                                                                        <strong>Windoware Accessories</strong>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.windoware15ChannelRemote.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.windoware15ChannelRemote.selected.value, "windoware15ChannelRemote", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.windoware15ChannelRemote.selected.value,
                                                                                        "fa-square-o": !item.configuration.windoware15ChannelRemote.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.windoware15ChannelRemote.attributeLabel}
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.windowareBridge.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.windowareBridge.selected.value, "windowareBridge", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.windowareBridge.selected.value,
                                                                                        "fa-square-o": !item.configuration.windowareBridge.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.windowareBridge.attributeLabel}
                                                                        </div>
                                                                        {
                                                                            product.items[itemIndex].configuration.chargingCable.selected.isVisible
                                                                                ? <div>
                                                                                    <Button
                                                                                        size={"sm"}
                                                                                        className={"mr-2 mb-1"}
                                                                                        color={"link"}
                                                                                        disabled={!item.configuration.chargingCable.selected.isSelectable}
                                                                                        onClick={() => this.handleChange(!item.configuration.chargingCable.selected.value, "chargingCable", itemIndex, true)}>
                                                                                        <i className={classnames("fa", "fa-lg", {
                                                                                                "fa-check-square-o": item.configuration.chargingCable.selected.value,
                                                                                                "fa-square-o": !item.configuration.chargingCable.selected.value,
                                                                                            }
                                                                                        )}/>
                                                                                    </Button>
                                                                                    {item.configuration.chargingCable.attributeLabel}
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                ziptrakUtil.isVisibleAccessories("Somfy", product, itemIndex)
                                                                    ? <div className="mt-1">
                                                                        <strong>Somfy Accessories</strong>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value, "somfy20CmExtCableForLiIonForCassetteV2", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value,
                                                                                        "fa-square-o": !item.configuration.somfy20CmExtCableForLiIonForCassetteV2.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.somfy20CmExtCableForLiIonForCassetteV2.attributeLabel}
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.somfyLiIonWirefreeChargerV2.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.somfyLiIonWirefreeChargerV2.selected.value, "somfyLiIonWirefreeChargerV2", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.somfyLiIonWirefreeChargerV2.selected.value,
                                                                                        "fa-square-o": !item.configuration.somfyLiIonWirefreeChargerV2.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.somfyLiIonWirefreeChargerV2.attributeLabel}
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                size={"sm"}
                                                                                className={"mr-2 mb-1"}
                                                                                color={"link"}
                                                                                disabled={!item.configuration.somfySituo5PureRemoteControl.selected.isSelectable}
                                                                                onClick={() => this.handleChange(!item.configuration.somfySituo5PureRemoteControl.selected.value, "somfySituo5PureRemoteControl", itemIndex, true)}>
                                                                                <i className={classnames("fa", "fa-lg", {
                                                                                        "fa-check-square-o": item.configuration.somfySituo5PureRemoteControl.selected.value,
                                                                                        "fa-square-o": !item.configuration.somfySituo5PureRemoteControl.selected.value,
                                                                                    }
                                                                                )}/>
                                                                            </Button>
                                                                            {item.configuration.somfySituo5PureRemoteControl.attributeLabel}
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        : <div className="mt-1">
                                                            <strong>Accessories</strong>
                                                            <div>
                                                                <Button
                                                                    size={"sm"}
                                                                    className={"mr-2 mb-1"}
                                                                    color={"link"}
                                                                    disabled={!item.configuration.pullDownStick.selected.isSelectable}
                                                                    onClick={() => this.handleChange(!item.configuration.pullDownStick.selected.value, "pullDownStick", itemIndex, true)}>
                                                                    <i className={classnames("fa", "fa-lg", {
                                                                            "fa-check-square-o": item.configuration.pullDownStick.selected.value,
                                                                            "fa-square-o": !item.configuration.pullDownStick.selected.value,
                                                                        }
                                                                    )}/>
                                                                </Button>
                                                                {item.configuration.pullDownStick.attributeLabel}
                                                            </div>
                                                        </div>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="number" name="width"
                                                               invalid={!item.configuration.width.formError.isValid}
                                                               value={item.configuration.width.selected.value}
                                                               min={item.configuration.width.min}
                                                               max={item.configuration.width.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="width"/>
                                                    </div>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="number" name="drop"
                                                               invalid={!item.configuration.drop.formError.isValid}
                                                               value={item.configuration.drop.selected.value}
                                                               min={item.configuration.drop.min}
                                                               max={item.configuration.drop.max}
                                                               onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               placeholder="drop"/>
                                                    </div>
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.drop.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div className={"d-flex justify-content-between align-items-center"}>
                                                        <Input type="select" name="fabricType"
                                                               value={item.configuration.fabricType.selected.dropdownValue}
                                                               onChange={(e) => this.handleChange(e.target.value, "fabricType", itemIndex, true)}>
                                                            <option value={""} disabled>Select type</option>
                                                            {
                                                                (item.configuration.fabricType.finalOptions || []).map((o, oIndex) => {
                                                                    return <option
                                                                        key={oIndex}
                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                    {
                                                        item.configuration.fabricColour.selected.isSelectable
                                                            ? <div className={"mt-2"}>
                                                                <Input type="select" name="fabricColour"
                                                                       value={item.configuration.fabricColour.selected.dropdownValue}
                                                                       onChange={(e) => this.handleChange(e.target.value, "fabricColour", itemIndex, true)}>
                                                                    <option value={""} disabled>Select color</option>
                                                                    {
                                                                        (item.configuration.fabricColour.finalOptions || []).map((o, oIndex) => {
                                                                            return <option
                                                                                key={oIndex}
                                                                                value={o.optionKey}>{o.optionLabel}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                            : null
                                                    }
                                                </td>

                                                <td className="align-middle">
                                                    <Input type="select" name="frameColour"
                                                           value={item.configuration.frameColour.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "frameColour", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>

                                                        {
                                                            (item.configuration.frameColour.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="channels"
                                                           value={item.configuration.channels.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "channels", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.channels.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <Input type="select" name="bottomChannel"
                                                           value={item.configuration.bottomChannel.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "bottomChannel", itemIndex, true)}>
                                                        <option value={""} disabled>Select</option>
                                                        {
                                                            (item.configuration.bottomChannel.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={"item" + itemIndex + "-option" + oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, discountByProductCode)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}/>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-center">
                                                        <Button color={"link"}
                                                                title={"click here to copy item"}
                                                                onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                            <i className="fa fa-clone fa-lg"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                        <span className={"text-muted"}>|</span>
                                                        <Button color={"link"}
                                                                title={"click here to delete"}
                                                                onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                            <i className="fa fa-trash-o fa-lg text-danger"
                                                               aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ?
                                                        <td className="align-middle">
                                                            <div className="text-center">

                                                                <Button color={"link"}
                                                                        title={"click here to open BOM"}
                                                                        onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                    BOM
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        : null
                                                }
                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={11}>Total price</td>
                                            <td className="align-middle">
                                                <div className="text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price, discountByProductCode)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </div>
                                            </td>
                                            <td className="align-middle" colSpan={2}/>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <ZiptrakInternalBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <ZiptrakInternalConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        : null
                    }
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
