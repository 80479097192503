import React, {Component} from 'react';
import {Button, Col, Row, Spinner, Table} from 'reactstrap';
import Pagination from "react-js-pagination";
import {toast, ToastContainer} from 'react-toastify';
import CustomerService from "../../services/CustomerService";
import OrderService from "../../services/OrderService";
import FileSaver from 'file-saver';
import NumberFormat from "react-number-format";
import classnames from "classnames";
import SimpleEmailModal from "../../components/Order/modals/SimpleEmailModal";
import {handleErrorMessage} from "../../services/CommonService";

export default class RecentStatements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaders: {
                Loading: false
            },
            openRecentStatements: {
                selectedPage: 1,
                pageSize: 10,
                totalRecords: 0
            },
            accountId: this.props.accountId,
            debtorEmail: "",
            selectedCheckboxes_count: 0,
            totalRecords: 25,
            EmailMonth: "",
            EmailYear: "",
            allRecordsSelected: false,
            openCloseEmailModal: false,
            isPrintingPDF: false,
            isPrintingExcel: false,
            selectedId: 0,
            RecentStatements: [],
        };
        this.customerService = new CustomerService();
        this.OrderService = new OrderService();
        this.renderFinally = this.renderFinally.bind(this);
        this.selectRecord = this.selectRecord.bind(this);
        this.selectAllRecords = this.selectAllRecords.bind(this);
        this.fetchOpenOrders = this.fetchOpenOrders.bind(this);
        this.printStatement = this.printStatement.bind(this);
        this.printStatementInExcel = this.printStatementInExcel.bind(this);
        this.EmailStatement = this.EmailStatement.bind(this);
        this.toggleEmail = this.toggleEmail.bind(this);
        this.downloadSelected = this.downloadSelected.bind(this);
        this.setPrintingValue = this.setPrintingValue.bind(this);
    }

    componentDidMount() {
        let loaders = this.state.loaders;
        loaders.Loading = true;
        this.setState({
            loaders: loaders
        });
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({accountId: nextProps.accountId}, function () {
                this.refresh();
            });
        }
    }

    getCustomerRecentStatements() {
        if (this.state.accountId) {
            const requestBody = {
                selectedPage: this.state.openRecentStatements.selectedPage,
                pageSize: this.state.openRecentStatements.pageSize,
                AccountId: this.state.accountId,
            };
            let loaders = this.state.loaders;
            loaders.Loading = true;
            this.setState({
                loaders: loaders
            });
            this.customerService.getCustomer_recentStatements(requestBody).then(response => {
                //console.log(response, "res");
                let data = response.data.records;
                if (data) {
                    data.forEach(function (x) {
                        x.selected = false;
                    });
                }
                if (data) {
                    if (data.length > 0) {
                        this.setState({RecentStatements: data});
                    } else {
                        this.setState({RecentStatements: []});
                    }
                    let openRecentStatements = this.state.openRecentStatements;
                    openRecentStatements.selectedPage = response.data.currentPage;
                    openRecentStatements.pageSize = response.data.pageSize;
                    openRecentStatements.totalRecords = response.data.totalRecords;

                    let loaders = this.state.loaders;
                    loaders.Loading = false;
                    this.setState({
                        loaders: loaders
                    });
                }
            }).catch(error => {
                console.log(error.data)
            });
        }
    }


    refresh() {
        this.getCustomerRecentStatements();
        this.customerService.searchCustomer(this.state.accountId).then(response => {
            let data = response.data;
            if (data) {
                this.setState({debtorEmail: data.email});
            }
        });
    }

    printStatement(Month, Year, totalTransactions, monthname) {
        this.setPrintingValue("PDF", true, Year, Month);
        let accountId = this.state.accountId;
        let selectedMonth = Month;
        if (selectedMonth.toString().length === '1' || selectedMonth.toString().length === 1) {
            selectedMonth = '0' + selectedMonth.toString();
        }
        let yearmonth = Year.toString() + '.' + selectedMonth.toString();
        if (accountId !== null && accountId !== undefined && accountId !== "") {
            let obj = {
                Year: Year,
                AccountID: accountId,
                Month: Month
            };
            // --  Request To SSRS
            this.customerService.downloadTransactionStatement(obj).then(response => {
                FileSaver.saveAs(response.data, "STATEMENT - " + monthname + "_" + Year + ".pdf");
                toast.success("Statement printed successfully.", {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setPrintingValue("PDF", false, Year, Month);
            }).catch(error => {
                console.log(error.data);
                this.setPrintingValue("PDF", false, Year, Month);
            });
        }
    }

    printStatementInExcel(Month, Year, totalTransactions, monthname) {
        if (totalTransactions.toString() !== "0") {
            this.setPrintingValue("Excel", true, Year, Month);
            let accountId = this.state.accountId;
            let selectedMonth = Month;
            if (selectedMonth.toString().length === '1' || selectedMonth.toString().length === 1) {
                selectedMonth = '0' + selectedMonth.toString();
            }
            let yearmonth = Year.toString() + '.' + selectedMonth.toString();
            if (accountId !== null && accountId !== undefined && accountId !== "") {
                this.OrderService.GenerateDebtorStatementInExcelReport(accountId, yearmonth).then(response => {
                    FileSaver.saveAs(response.data, "STATEMENT - " + monthname + "_" + Year + ".xlsx");
                    toast.success("Statement downloaded successfully.", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setPrintingValue("Excel", false, Year, Month);
                }).catch(error => {
                    console.log(error.data);
                    this.setPrintingValue("Excel", false, Year, Month);
                });
            }
        }
        else {
            toast.info("No record found for this Month !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    EmailStatement(month, year) {
        this.setState({EmailMonth: month, EmailYear: year}, () => this.toggleEmail());
    }

    toggleEmail() {
        this.setState((prevState) => ({openCloseEmailModal: !prevState.openCloseEmailModal}));
    }

    selectRecord(transID, index) {
        let RecentStatements = [...this.state.RecentStatements];
        //RecentStatements.find(x => x.transID === transID).selected = !(RecentStatements.find(x => x.transID === transID).selected);
        RecentStatements[index].selected = !(RecentStatements[index].selected);
        let counter = 0;
        for (let i in RecentStatements) {
            if (RecentStatements[i].selected) {
                counter++;
            }
        }
        if (counter === RecentStatements.length) {
            this.setState({allRecordsSelected: true, selectedCheckboxes_count: counter});
        }
        else {
            this.setState({
                allRecordsSelected: false,
                selectedCheckboxes_count: counter
            }, () => this.renderFinally(RecentStatements));
        }
    }

    downloadSelected() {
        let RecentStatements = [...this.state.RecentStatements];
        let self = this;
        RecentStatements.forEach(function (item, index) {
            if (item.selected) {
                self.printStatement(item.month, item.year, item.totalTransactions, item.monthName);
            }
        });
        let counter = 0;
        for (let i in RecentStatements) {
            if (RecentStatements[i].selected) {
                counter++;
                break;
            }
        }
        if (counter === 0) {
            toast.info("No record selected to download !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    renderFinally(data) {
        this.setState({RecentStatements: data});
    }

    selectAllRecords() {
        let self = this;
        let RecentStatements = [...this.state.RecentStatements];
        RecentStatements.forEach(function (el) {
            el.selected = !self.state.allRecordsSelected;
        });
        let counter = 0;
        for (let i in RecentStatements) {
            if (RecentStatements[i].selected) {
                counter++;
            }
        }
        self.setState({
            RecentStatements: RecentStatements,
            allRecordsSelected: !self.state.allRecordsSelected,
            selectedCheckboxes_count: counter
        });
    }


    async fetchOpenOrders(selectedPage) {
        this.setState({loaders: {Loading: true}});
        try {
            const requestBody = {
                selectedPage: selectedPage,
                pageSize: this.state.openRecentStatements.pageSize,
                AccountId: this.state.accountId,
            };
            await this.customerService.getCustomer_recentStatements(requestBody).then(response => {
                let data = response.data.records;
                this.setState({loaders: {Loading: false}});
                if (data) {
                    data.forEach(function (x) {
                        x.selected = false;
                    });
                }
                if (data) {
                    let openRecentStatements = this.state.openRecentStatements;
                    openRecentStatements.selectedPage = response.data.currentPage;
                    openRecentStatements.pageSize = response.data.pageSize;
                    openRecentStatements.totalRecords = response.data.totalRecords;
                    if (data.length > 0) {
                        this.setState({
                            RecentStatements: data,
                            openRecentStatements: openRecentStatements,
                            allRecordsSelected: false,
                            selectedCheckboxes_count: 0
                        });
                    } else {
                        openRecentStatements.selectedPage = 1;
                        openRecentStatements.pageSize = 10;
                        openRecentStatements.totalRecords = 0;
                        this.setState({
                            RecentStatements: [],
                            openRecentStatements: openRecentStatements,
                            allRecordsSelected: false,
                            selectedCheckboxes_count: 0
                        });
                    }

                }
            }).catch(error => {
                //console.log(error.data)
                this.setState({loaders: {Loading: false}});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        } catch (error) {
            //console.log(error);
            this.setState({loaders: {Loading: false}});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    setPrintingValue(name, value, year, month) {
        let id = year + month;
        if (name === "PDF") {
            this.setState({isPrintingPDF: value, selectedId: id});
        } else {
            this.setState({isPrintingExcel: value, selectedId: id});
        }
    }

    renderCustomerRecentStatements(stats) {
        let {loaders, isPrintingPDF, isPrintingExcel, selectedId, allRecordsSelected} = this.state;
        let {selectedPage, pageSize, totalRecords} = this.state.openRecentStatements;
        return (
            <div>
                <div className="acct-title">
                    <Row className={"align-items-center"}>
                        <Col xl={7} lg={7} md={7} sm={12} xs={12}>
                            <strong>
                                Here are your recent statements for this account.
                            </strong>
                        </Col>
                        {
                            this.state.selectedCheckboxes_count > 0
                                ? <Col xl={5} lg={5} md={5} sm={12} xs={12} className="text-right">
                                    <span>{this.state.selectedCheckboxes_count + ' rows selected'}</span>
                                    <Button color={"success"} size={"sm"} className={"ml-2"}
                                            onClick={this.downloadSelected}>
                                        <i className="fa fa-download mr-1" aria-hidden="true"/> Download
                                    </Button>
                                </Col>
                                : null
                        }

                    </Row>
                </div>
                {loaders.Loading ? <Spinner size={'sm'}/> : null}
                <Table size='sm' striped bordered responsive hover>
                    <thead>
                    <tr>
                        <th className="text-center">
                            <Button color={"link"} size="sm" onClick={this.selectAllRecords}>
                                <i className={classnames("fa", "fa-lg", {
                                        "fa-check-square-o": allRecordsSelected,
                                        "fa-square-o": !allRecordsSelected,
                                    }
                                )}/>
                            </Button>
                        </th>
                        <th>Month</th>
                        <th className="text-right">Year</th>
                        <th className="text-right">Total DR</th>
                        <th className="text-right">Total CR</th>
                        <th>Total Transactions</th>
                        <th>Total Pages</th>
                        <th>Download</th>
                        <th>Email</th>
                    </tr>
                    </thead>
                    {(stats.length === 0 || stats.length === '0')
                        ? <tbody>
                        <tr>
                            <td colSpan="8">No items...</td>
                        </tr>
                        </tbody>
                        : <tbody>
                        {(stats || []).map((stat, index) =>
                            <tr key={index}>
                                <td className="text-center">
                                    <Button color={"link"} size="sm"
                                            onClick={() => this.selectRecord(stat.transID, index)}>
                                        <i className={classnames("fa", "fa-lg", {
                                                "fa-check-square-o": stat.selected,
                                                "fa-square-o": !stat.selected,
                                            }
                                        )}/>
                                    </Button>
                                </td>
                                <td>{stat.monthName}</td>
                                <td className='text-right'>{stat.year}</td>

                                <td style={{textAlign: 'right'}} className={classnames({
                                    'table-danger': stat.invTotal > 0,
                                    'table-success': stat.invTotal < 0
                                })}>
                                    <NumberFormat
                                        value={stat.invTotal}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        prefix={'$'}/>

                                </td>
                                <td style={{textAlign: 'right'}} className={classnames({
                                    'table-success': stat.recTotal > 0,
                                    'table-danger': stat.recTotal < 0
                                })}><NumberFormat value={stat.recTotal}
                                                  displayType={'text'}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                  prefix={'$'}/></td>
                                <td>(<b>{stat.totalTransactions}</b>) Transactions</td>
                                <td>({(Math.round(stat.totalTransactions / 26)) <= 1 ? <b> 1 page </b> :
                                    <b>{Math.round(stat.totalTransactions / 26)} pages</b>})
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: '2px'}} title="Download Statement of this month in pdf"
                                            color='primary' size="sm" id={`btn-${index}`}
                                            onClick={() => this.printStatement(stat.month, stat.year, stat.totalTransactions, stat.monthName)}>
                                        {(isPrintingPDF && (selectedId == stat.year + stat.month)) ?
                                            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>}
                                    </Button>
                                    {'  '}
                                    <Button style={{marginLeft: '2px'}}
                                            title="Download Statement of this month in excel" color='primary' size="sm"
                                            id={`btn-${index}`}
                                            onClick={() => this.printStatementInExcel(stat.month, stat.year, stat.totalTransactions, stat.monthName)}>
                                        {(isPrintingExcel && (selectedId == stat.year + stat.month)) ?
                                            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
                                            <i className="fa fa-file-excel-o" aria-hidden="true"></i>}
                                    </Button>
                                </td>
                                <td style={{'textAlign': 'center'}}>
                                    <Button title="Email Statement of this month" color='primary' size="sm"
                                            id={`btn-${index}`}
                                            onClick={() => this.EmailStatement(stat.monthName, stat.year)}>
                                        <i className="fa fa-envelope" aria-hidden="true"/>
                                    </Button>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    }
                </Table>
                {totalRecords > pageSize ? <Row>
                    <Col md="5" sm="1"/>
                    <Col md="4" sm="10">
                        <Pagination
                            activePage={selectedPage}
                            itemsCountPerPage={pageSize}
                            totalItemsCount={totalRecords}
                            pageRangeDisplayed={3}
                            onChange={this.fetchOpenOrders}
                            itemClass='page-item'
                            linkClass='page-link'
                            activeClass='active'
                            innerClass='pagination'
                            activeLinkClass='active'
                        />
                    </Col>
                </Row> : null}
            </div>
        );
    }

    render() {
        let {loaders} = this.state;
        let Transactions = (loaders.Loading ? <p><span><Spinner size={"sm"} color="primary"/> Loading... </span>
        </p> : this.renderCustomerRecentStatements(this.state.RecentStatements));
        return (
            <div>
                <div>
                    {Transactions}
                </div>
                {this.state.openCloseEmailModal ?
                    <SimpleEmailModal
                        AccountId={this.state.accountId}
                        emailId={this.state.debtorEmail}
                        year={this.state.EmailYear}
                        month={this.state.EmailMonth}
                        isOpen={this.state.openCloseEmailModal}
                        toggleModel={this.toggleEmail}
                    /> : null}
                <ToastContainer/>
            </div>
        );
    }
}