import React, {Component} from 'react';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import classnames from 'classnames';
import {validateEmail} from '../../services/CommonService'

export default class CustomerSignupInviteModal extends Component {


    render() {
        let {isOpen, toggle, handleChange, handleSubmit, loading, form} = this.props;
        let isValidEmail = validateEmail(form.newEmail);
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Customer Signup Invite
                </ModalHeader>
                <ModalBody>
                    <div className="mb-2">
                        Email*
                        <Input name="email"
                               invalid={!isValidEmail}
                               valid={isValidEmail}
                               type={"text"}
                               value={form.newEmail}
                               onChange={(e) => handleChange(e.target.value, "customer-signup-invite-email")}>
                        </Input>
                        {
                            isValidEmail
                                ? null
                                : <small className={"text-danger"}>Not a valid email address</small>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button
                            className={"mr-2"}
                            color={"primary"}
                            onClick={() => handleSubmit(form)}
                            disabled={loading || !(validateEmail(form.newEmail))}
                        >
                            {
                                loading
                                    ? <Spinner className={"mr-2"}
                                               color={"light"}
                                               size={"sm"}/>
                                    : <i className={classnames("fa ", "mr-2", {
                                        "fa-floppy-o": form.email !== form.newEmail,
                                        "fa-envelope-o": form.email === form.newEmail,
                                    })}
                                         aria-hidden="true"/>
                            }
                            {form.email === form.newEmail ? "Send invite" : "Update customer email and send invite"}
                        </Button>
                        <Button color={"secondary"}
                                disabled={loading}
                                onClick={() => toggle(!isOpen)}>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}