export const FACING_MODES = {
    'USER': 'user',
    'ENVIRONMENT': 'environment'
};

// Image constants
const PNG = 'png';
const JPG = 'jpg';

export const SUPPORTED_IMAGE_TYPES = [JPG, PNG];

export const IMAGE_TYPES = {
    'PNG': PNG,
    'JPG': JPG
};

export const FORMAT_TYPES = {
    [JPG]: 'image/jpeg',
    [PNG]: 'image/png'
};
