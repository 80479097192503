import React, {Component} from 'react';
import stockReceiptService from "../../services/stock/StockReceiptService";
import AsyncSelect from 'react-select/async';
import {changeFormatOfDateString, concatenateStrings} from "../../services/CommonService";
import cloneDeep from 'lodash/cloneDeep';

let newStockReceiptOption = {
    label: "New",
    value: "New"
};

export default class SearchStockReceipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: {
                label: "",
                value: ""
            },
            stockReceiptID: "",
            accountID: "",
            options: [],
        };
        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.stockReceiptID);
    }

    componentWillReceiveProps(nextProps) {
        let {stockReceiptID, selected, accountID} = this.state;

        /* if (stockReceiptID !== nextProps.defaultStockReceiptID || accountID !== nextProps.accountID) {
             //load externally selectedItem
             if (nextProps.defaultStockReceiptID || nextProps.accountID) {
                 stockReceiptID = nextProps.defaultStockReceiptID ? nextProps.defaultStockReceiptID : stockReceiptID;
                 selected = {
                     label: nextProps.defaultStockReceiptID + "",
                     value: nextProps.defaultStockReceiptID
                 };
                 accountID = nextProps.accountID;
                 this.setState({selected, stockReceiptID, accountID}, () => {
                     this.fetchOptions(stockReceiptID);
                 });
             }
         }*/

        if (stockReceiptID !== nextProps.selectedStockReceiptID || accountID !== nextProps.accountID) {
            if (!nextProps.selectedStockReceiptID) {
                stockReceiptID = "";
                selected = null;
            } else {
                stockReceiptID = nextProps.selectedStockReceiptID;
                selected = {
                    label: nextProps.selectedStockReceiptID + "",
                    value: nextProps.selectedStockReceiptID
                };
            }
            accountID = nextProps.accountID;
            this.setState({selected, stockReceiptID, accountID}, () => {
                this.fetchOptions(stockReceiptID);
            });
        }
    }

    handleSelect(selected, event) {
        let {stockReceiptID} = this.state;
        stockReceiptID = "";
        if (selected) {
            selected = cloneDeep(selected);
            selected.label = selected.value;
            stockReceiptID = selected.value;
        }
        switch (event) {
            case "selected":
                this.setState({selected, stockReceiptID});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, stockReceiptID}, () => {
                    this.props.handleChange(stockReceiptID);
                });
        }
        if (!stockReceiptID) {
            this.fetchOptions("");
        }
    };

    fetchOptions(inputValue, callback) {
        let {stockReceiptID, accountID} = this.state;
        let options = [];
        inputValue = inputValue === "New" ? "" : inputValue;
        stockReceiptService.getStockReceipts(inputValue, accountID).then(response => {
            for (let i in response.data) {
                let temp = {
                    label: <>{response.data[i].stockReceiptID}
                    <span style={{float: "right"}}>
                        {
                            concatenateStrings(
                                " ",
                                changeFormatOfDateString(response.data[i].dateCreated, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY"),
                                "Received by",
                                response.data[i].createdBy
                            )
                        }
                        </span>
                    </>,
                    value: response.data[i].stockReceiptID
                };
                options.push(temp);

                if (this.props.defaultStockReceiptID) {
                    if (response.data[i].stockReceiptID === this.props.defaultStockReceiptID) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.props.selectedStockReceiptID) {
                    if (response.data[i].stockReceiptID === this.props.selectedStockReceiptID) {
                        this.handleSelect(temp, "selected");
                    }
                }

            }
            this.setState({options});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                console.log(error.data)
            }
        );
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.options);
            }
        }
    };

    render() {
        return (
            <div>
                <AsyncSelect
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={[newStockReceiptOption, ...this.state.options]}
                    loadOptions={this.loadOptions}
                    onChange={(selection) => this.handleSelect(selection, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                    placeholder={"Search: Stock Receipt"}
                />
            </div>
        );
    }
}