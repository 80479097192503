import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody,
    Row, Col, Table,
    Button,
    Spinner, Input, Breadcrumb,
    BreadcrumbItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import FileSaver from 'file-saver';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrorMessage } from '../../services/CommonService';
import classnames from "classnames";
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';

export default class ImportPurchaseOrderItemModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImport: false,
            downloading: false,
            importResponse: {},
            isDryRun: false,
        };

        this.purchaseOrderService = new PurchaseOrderService();
        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.downloadFormat = this.downloadFormat.bind(this);
        this.importFile = this.importFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    fileChangeHandler(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    importFile() {
        let { isOpen, toggle, addImportedItems } = this.props;
        let { importResponse } = this.state;
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('isDryRun', this.state.isDryRun);
        data.append('variant', this.props.supplierCode);
        this.setState({ isImport: true });

        this.purchaseOrderService.importPurchaseOrderItemData(data).then(response => {
            if (response.status === 200 || response.status === "200") {
                importResponse = response.data.commonImportDataResponse;
                let items = response.data.orderItems;
                this.setState({ isImport: false, importResponse });
                if (importResponse.hasError) {
                    toast.error("Error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    addImportedItems(items);
                    toggle(!isOpen);
                }
            }
        }).catch(error => {
            this.setState({ isImport: false });
            console.error(handleErrorMessage(error));
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    downloadFormat() {
        this.setState({ downloading: true });
        this.purchaseOrderService.downloadPurchaseOrderItemImportTemplate().then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "PurchaseOrder-Item-Import-Format" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(key, change) {
        this.setState({ [key]: change });
    }

    render() {
        let { downloading, isImport, importResponse, isDryRun } = this.state;
        let { isOpen, toggle } = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Import Purchase Order Items
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                            <div>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input type="file"
                                            name="file"
                                            id="file"
                                            onChange={this.fileChangeHandler}
                                            aria-label="Upload document" />
                                    </Col>
                                </Row>


                                {
                                    importResponse.hasError
                                        ? <div>
                                            <hr />
                                            <Table size={"sm"} hover={true} responsive>
                                                <thead>
                                                    <tr>
                                                        <td>Row</td>
                                                        <td>Column</td>
                                                        <td>Title</td>
                                                        <td>Value</td>
                                                        <td>Validation Message</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {((importResponse.rows) || []).map(row => {
                                                        return <tr
                                                            className={row.hasError ? "table-danger" : "table-success"}>
                                                            <td>{row.rowIndex}</td>
                                                            <td>{row.columnIndex}</td>
                                                            <td>{row.inputTitle}</td>
                                                            <td>{row.inputValue}</td>
                                                            <td>{row.validationMessage}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                        : null
                                }
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"link"} size={"sm"}
                            onClick={() => this.handleChange("isDryRun", !isDryRun)}>
                            <i className={classnames("mr-2", "fa", {
                                "fa-check-square-o": isDryRun,
                                "fa-square-o": !isDryRun
                            })} />
                            Dry run
                        </Button>
                        <Button color={"primary"} size={"sm"}
                            onClick={this.importFile}
                            disabled={isImport}>
                            {isImport
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    color={"white"} />
                                : <i className="fa fa-upload mr-2" />
                            }
                            Upload
                        </Button>&nbsp;
                        <Button color={"primary"} size={"sm"}
                            outline={true}
                            onClick={() => this.downloadFormat()}
                            disabled={downloading}>
                            {downloading
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    color={"primary"} />
                                : <i className="fa fa-download mr-2" />
                            }
                            Download template
                        </Button>
                        <Button color={"secondary"} size='sm'
                            className={"ml-1"}
                            onClick={() => toggle(!isOpen)}><i
                            className="fa fa-times" />&nbsp;
                            Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }

}