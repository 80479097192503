import React, {Component} from 'react';

export let verticalIndoorLabelStore = [
    {
        key: "location",
        label: "Location",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "headRailColour",
        label: "Head Rail Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "stackingDirection",
        label: "Stacking Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "mount",
        label: "Mount Type",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "wand",
        label: "WandSize",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }, {
        key: "bottomWeightType",
        label: "Bottom Weight",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "fabricColour",
        label: "Fabric Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    },
    {
        key: "bladeSize",
        label: "Blade Size",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel[this.key];
        }
    }

];