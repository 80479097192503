import React, {Component} from 'react';
import {
    Badge,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Table
} from 'reactstrap';
import OrderService from "../../../services/OrderService";
import {toast} from 'react-toastify';
import {groupBy, handleErrorMessage} from "../../../services/CommonService";
import {cloneDeep, isEmpty} from 'lodash';
import NumberFormat from "react-number-format";
import { PRODUCT_GROUP_COMPONENT_ID, PRODUCT_GROUP_REWORK_ID } from "../../../store/ProductGroups";
import { REWORK_EXTRA_DISCOUNT_ORDER_ITEM_PRODCODE } from "../../../store/AppConstants";

export default class OrderEnquiryEditModeUpdateDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
            productGroups: [],
            isLoadingSave: false,
        };
        this.orderService = new OrderService();
        this.calculateOrderTotal = this.calculateOrderTotal.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let order = cloneDeep(this.props.order);

        order.items = order.items.filter(i => i.prodCode !== "1");
        (order.items || []).forEach(i => {
            if (!i.productGroupID) {
                if (i.prodCode === REWORK_EXTRA_DISCOUNT_ORDER_ITEM_PRODCODE) {
                    i.productGroupID = PRODUCT_GROUP_REWORK_ID;
                    i.productGroup = {
                        code: "Rework",
                        displayName: "Rework Waived Item",
                        name: "Rework Waived Item",
                        productGroupId: PRODUCT_GROUP_REWORK_ID,
                        shortName: "Rework Waived Item"
                    };
                }
                else {
                    i.productGroupID = PRODUCT_GROUP_COMPONENT_ID;
                    i.productGroup = {
                        code: "OTHER",
                        displayName: "OTHER",
                        name: "OTHER",
                        productGroupId: PRODUCT_GROUP_COMPONENT_ID,
                        shortName: "OTHER"
                    };
                }
            }
        });
        let productGroups = this.initProductGroups(order);
        order = this.calculateOrderTotal(order, productGroups);
        this.setState({order, productGroups})
    }

    initProductGroups(order) {
        let productGroups = [];
        let productGroupIndex = -1;
        let parentItemIndex = -1;
        let orderItemsByParentItemNum = groupBy(order.items, 'parentItemNum');
        for (let [parentItemNum, items] of Object.entries(orderItemsByParentItemNum)) {

            parentItemNum = parseInt(parentItemNum);
            parentItemIndex = items.findIndex(i => i.itemNum === parentItemNum);
            if (parentItemIndex > -1) {
                productGroupIndex = productGroups.findIndex(pg => pg.productGroupID === items[parentItemIndex].productGroupID);
                if (!(productGroupIndex > -1)) {
                    productGroups.push(
                        {
                            productGroupID: items[parentItemIndex].productGroupID,
                            productGroupName: items[parentItemIndex].productGroup.name,
                            parentItems: [],
                            qty: 0,
                            discount: items[parentItemIndex].discount,
                            subTotalAmount: 0,
                            totalDiscountAmount: 0,
                            totalTaxAmount: 0,
                            totalAmount: 0,
                            items: []
                        }
                    );
                    productGroupIndex = productGroups.length - 1;
                }

                items.forEach(i => {
                    productGroups[productGroupIndex].subTotalAmount += i.price * (order.invPrinted ? i.qtySupplied : i.qty);
                    productGroups[productGroupIndex].totalDiscountAmount += i.discVal;
                    productGroups[productGroupIndex].totalTaxAmount += i.salesTax;
                });
                productGroups[productGroupIndex].totalAmount = (productGroups[productGroupIndex].subTotalAmount + productGroups[productGroupIndex].totalTaxAmount - productGroups[productGroupIndex].totalDiscountAmount).toFixed(2);
                productGroups[productGroupIndex].items = [...items, ...productGroups[productGroupIndex].items];
                productGroups[productGroupIndex].qty += items[parentItemIndex].qty;
            }
        }

        return productGroups;
    }

    calculateOrderTotal(order, productGroups) {
        order.subTotalAmount = 0;
        order.totalDiscountAmount = 0;
        order.totalTaxAmount = 0;
        order.totalAmount = 0;
        productGroups.forEach(pg => {
            order.subTotalAmount += pg.subTotalAmount;
            order.totalDiscountAmount += pg.totalDiscountAmount;
            order.totalTaxAmount += pg.totalTaxAmount;
            order.totalAmount += parseFloat(pg.totalAmount);
        });
        return order;

    }

    handleDiscountChange(changedDiscount, productGroupID) {
        let {productGroups, order} = this.state;
        order.items.forEach(i => {
            if (i.productGroupID === productGroupID) {
                i.discount = changedDiscount;
                i.discVal = i.allowDiscount ? i.price * (order.invPrinted ? i.qtySupplied : i.qty) * (i.discount / 100) : 0;
                i.salesTax = ((i.price * (order.invPrinted ? i.qtySupplied : i.qty)) - i.discVal) * (i.taxRate / 100);
            }

        });
        productGroups = this.initProductGroups(order);
        order = this.calculateOrderTotal(order, productGroups);
        this.setState({order, productGroups})
    }

    handleSubmit() {

        let isValid = true;

        let request = {
            ordNum: this.state.order.ordNum,
            productDiscounts: []
        };
        this.state.productGroups.forEach(pg => {
            if (pg.discount < 0 || pg.discount > 100) {
                toast.info("Expecting " + pg.productGroupName + "'s discount % between 0 to 100");
                isValid = false;
            }
            request.productDiscounts.push({
                productGroupID: pg.productGroupID,
                discount: pg.discount,
            })
        });
        if (!isValid) {
            return;
        }
        this.setState({isLoadingSave: true});
        this.orderService.updateSalesOrderDiscount(request).then(response => {
            toast.success("Saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({isLoadingSave: false}, () => {
                this.props.toggleModel();
                this.props.refreshOrder();
            });
        }).catch(error => {
            console.log(error);
            this.setState({isLoadingSave: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {isLoadingSave, order, productGroups} = this.state;
        return (
            <Modal isOpen={this.props.isOpen}
                   size="lg"
                   scrollable={false}
                   toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    Apply Discount
                </ModalHeader>
                <ModalBody>
                    <Table responsive={true} size={"sm"} bordered={true} striped={true}>
                        <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Product name</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th style={{width: 175}}>Discount Rate</th>
                            <th className="text-right">Discount Amt.</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(productGroups || []).map((pg, pgIndex) => {
                            return <tr>
                                <td className="align-middle">{pgIndex + 1}</td>
                                <td className="align-middle">{pg.productGroupName}</td>
                                <td className="align-middle text-right">{pg.qty}</td>
                                <td className="align-middle text-right">
                                    <NumberFormat value={pg.subTotalAmount}
                                                  displayType={'text'}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  prefix={'$'}/>
                                </td>
                                <td className="align-middle text-right">
                                    <InputGroup>
                                        <Input name={"discount"} value={pg.discount}
                                            type={"number"} disabled={pg.productGroupID === PRODUCT_GROUP_REWORK_ID}
                                               invalid={pg.discount < 0 || pg.discount > 100}
                                               onChange={(e) => this.handleDiscountChange(e.target.value, pg.productGroupID)}/>
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText><i className="fa fa-percent ml-2"
                                                               aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {
                                        (pg.discount < 0 || pg.discount > 100)
                                            ? <Badge color={"danger"}>Expecting input between 0 to 100</Badge>
                                            : null
                                    }

                                </td>
                                <td className="align-middle text-right"><NumberFormat value={pg.totalDiscountAmount}
                                                                                      displayType={'text'}
                                                                                      decimalScale={2}
                                                                                      thousandSeparator={true}
                                                                                      prefix={'$'}/></td>
                            </tr>
                        })}
                        <tr>
                            <th colSpan={5} className="text-right">Sub-total</th>
                            <th className="text-right">
                                <NumberFormat value={order.subTotalAmount}
                                              displayType={'text'}
                                              decimalScale={2}
                                              thousandSeparator={true}
                                              prefix={'$'}/>
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={5} className="text-right">Discount Amt.</th>
                            <th className="text-right">
                                <NumberFormat value={order.totalDiscountAmount}
                                              displayType={'text'}
                                              decimalScale={2}
                                              thousandSeparator={true}
                                              prefix={'$'}/>
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={5} className="text-right">GST</th>
                            <th className="text-right">
                                <NumberFormat value={order.totalTaxAmount}
                                              displayType={'text'}
                                              decimalScale={2}
                                              thousandSeparator={true}
                                              prefix={'$'}/>
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={5} className="text-right">Total</th>
                            <th className="text-right">
                                <NumberFormat value={order.totalAmount}
                                              displayType={'text'}
                                              decimalScale={2}
                                              thousandSeparator={true}
                                              prefix={'$'}/>
                            </th>
                        </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={isLoadingSave || order.salesOrderJobStatusId >= 95}
                        type="submit"
                        color="primary"
                        size={"sm"}
                        className={"mr-2"}
                        onClick={this.handleSubmit}
                    >
                        {isLoadingSave
                            ? <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                            : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>}
                        {isLoadingSave ? "Saving" : "Save"}
                    </Button>
                    <Button
                        disabled={isLoadingSave}
                        size={"sm"}
                        color="secondary"
                        onClick={this.props.toggleModel}
                    ><i className="fa fa-times mr-2" aria-hidden="true"/>
                        Cancel
                    </Button>
                </ModalFooter>

            </Modal>
        )
    }
}