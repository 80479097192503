import axios from "./axios";
import queryString from 'query-string';

class QuartzService {

    static Instance() {
        return new QuartzService();
    }

    getNextScheduledDate(scheduledJobName) {
        return axios.get('api/quartz/next/scheduled-date?scheduledJobName=' + scheduledJobName);
    }

    getQuartzJobSummary(scheduledJobName) {
        return axios.get('api/quartz/job/summary?scheduledJobName=' + scheduledJobName);
    }

    getScheduledJobs() {
        return axios.get('api/quartz/job/summary/list');
    }

    changeTriggerState(scheduledJobName, triggerStateString) {
        return axios.put('api/quartz/job/trigger/state/change?' + queryString.stringify({
            scheduledJobName,
            triggerStateString
        }));
    }

    updateTrigger(scheduledJobName, cronExpression) {
        return axios.put('api/quartz/job/trigger/schedule?' + queryString.stringify({
            scheduledJobName,
            cronExpression
        }));
    }
}

export default QuartzService.Instance();