import React, { Component } from "react";
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import { toast } from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';
import KeywayStockService from '../../services/KeywayStockService';
import CreatableSelect from 'react-select/creatable';
export default class ManageStockUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStockUnit: this.props.selectedStockUnit,
            formErrors: {},
            saving: false
        }
    }

    handleChange(key, change) {
        let { selectedStockUnit } = this.state;
        selectedStockUnit[key] = change;
        this.setState({ selectedStockUnit });
    }

    validateForm(item) {
        let formErrors = {}, validValue = true;
        if (!item.name) {
            formErrors.name = "Please enter name";
            validValue = false;
        }
        if (!item.fullName) {
            formErrors.fullName= "Please enter full name";
            validValue = false;
        }
        if (!item.type) {
            formErrors.type = "Please enter unit type";
            validValue = false;
        }
        return ({
            validValue: validValue,
            formErrors: formErrors
        })
    }

    saveStockUnit() {
        let { selectedStockUnit } = this.state;
        let validateResult = this.validateForm(selectedStockUnit);
        if (!validateResult.validValue) {
            this.setState({ formErrors: validateResult.formErrors });
            return;
        }
        this.setState({ saving: true, formErrors: {} })
        KeywayStockService.saveStockUnit(selectedStockUnit).then(response => {
            this.setState({ saving: false }, () => {
                this.props.toggle(false);
                this.props.refreshAgain();
            });
            toast.success("Saved Successfully", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            this.setState({ saving: false })
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { selectedStockUnit, formErrors, saving } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {selectedStockUnit.name ? 'Edit' : 'Add'} Stock Unit
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Name*</Label>
                                <Input type="text" name="name"
                                    invalid={formErrors.name !== undefined}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedStockUnit.name}
                                    placeholder="Enter name here" />
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="fullName">Full Name*</Label>
                                <Input type="text" name="fullName"
                                    invalid={formErrors.fullName !== undefined}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedStockUnit.fullName}
                                    placeholder="Enter full name here" />
                                <FormText color="danger">{formErrors.fullName}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="type">Type</Label>
                                <Input type="text" name="type"
                                    invalid={formErrors.type !== undefined}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedStockUnit.type}
                                    placeholder="Enter unit type here" />
                                <FormText color="danger">{formErrors.type}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                  
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.saveStockUnit()} disabled={saving}>
                            {saving
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            Save</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button>

                    </div>
                </ModalFooter>

            </Modal>
        );
    }
}