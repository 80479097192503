import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabPane} from "reactstrap";
import classnames from "classnames";
import DispatchOrders from '../../components/DispatchModule/DispatchOrders';
import queryString from 'query-string';
import OrderCountToBeDispatched from '../../components/DispatchModule/OrderCount/OrderCountToBeDispatched';
import OrderCountToBeShipped from '../../components/DispatchModule/OrderCount/OrderCountToBeShipped';
import ShippedOrdersCategoryWiseCount from '../../components/DispatchModule/ShippedOrdersCategoryWiseCount';
import {ToastContainer} from 'react-toastify';

let tabs = [
    {
        index: "0",
        key: "overview",
        label: "Awaiting Dispatch"
    },
    {
        index: "1",
        key: "windowareDelivery",
        label: "Windoware Delivery"
    },
    {
        index: "2",
        key: "customerCollect",
        label: "Customer Collect"
    },
    {
        index: "3",
        key: "archived",
        label: "Shipped"
    },
];
export default class DispatchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[0].key,
            searchParams: {}
        };

    }

    componentDidMount() {
        let searchParams = queryString.parse(this.props.location.search);
        let activeTab = searchParams.activeTab ? searchParams.activeTab : tabs[0].key;
        this.setState({activeTab, searchParams});
    }

    toggle(tab) {
        this.setState({activeTab: tab});

        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("activeTab", tab);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    render() {
        let {activeTab, searchParams} = this.state;
        return (
            <div>
                <Row>
                    <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                        <OrderCountToBeDispatched activeTab={activeTab }/>
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                        <OrderCountToBeShipped activeTab={activeTab} />
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                        <ShippedOrdersCategoryWiseCount type="Total Number of Shipped Orders" />
                    </Col>
                </Row> 
                <Card className={"mt-2"}>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabPane tabId='overview'>
                            {
                                activeTab === "overview"
                                    ? <DispatchOrders type={"overview"} searchParams={searchParams}/>
                                    : null
                            }
                        </TabPane>
                        <TabPane tabId='windowareDelivery'>
                            {
                                activeTab === "windowareDelivery"
                                    ? <DispatchOrders type={"windowareDelivery"} searchParams={searchParams} />
                                    : null
                            }
                        </TabPane>
                        <TabPane tabId='customerCollect'>
                            {
                                activeTab === "customerCollect"
                                    ? <DispatchOrders type={"customerCollect"} searchParams={searchParams} />
                                    : null
                            }
                        </TabPane>
                        <TabPane tabId='archived'>
                            {
                                activeTab === "archived"
                                    ? <DispatchOrders type={"archived"} searchParams={searchParams}/>
                                    : null
                            }
                        </TabPane>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        )
    }
}