import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    Nav,
    CardBody,
    CardHeader,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import EmailLogs from '../../pages/EmailLog/EmailLogsPage';
import {Link} from "react-router-dom";
import {
    QUARTZ_SCHEDULED_JOB_DEBTOR_MONTHLY_STATEMENT,
    QUARTZ_SCHEDULED_JOB_DEBTOR_QUOTES_FOLLOW_UP
} from "../../store/AppConstants";

const schedulingJobsTabs = [
    {
        label: "All Emails",
        key: "all"
    },
    {
        label: "Monthly Statement",
        key: "monthly-statement"
    },
    {
        label: "Quote Reminder",
        key: "quote-reminder"
    }
];
export default class SchedulingJobsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'all',
        };
    }

    componentDidMount() {
    }

    toggleTab(tab) {
        this.setState({activeTab: tab});
    }

    render() {
        let {activeTab} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Email Log</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {schedulingJobsTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            {activeTab === "all"
                                ? <TabPane tabId="all">
                                    <EmailLogs emailJobType="All" pageLabel="All Sent Emails" />
                                </TabPane>
                                : null
                            }
                            {activeTab === "monthly-statement"
                                ? <TabPane tabId="monthly-statement">
                                    <EmailLogs emailJobType={QUARTZ_SCHEDULED_JOB_DEBTOR_MONTHLY_STATEMENT}
                                               pageLabel="Monthly Statement Auto Email"/>
                                </TabPane>
                                : null
                            }
                            {activeTab === "quote-reminder"
                                ? <TabPane tabId="quote-reminder">
                                    <EmailLogs emailJobType={QUARTZ_SCHEDULED_JOB_DEBTOR_QUOTES_FOLLOW_UP}
                                               pageLabel="Quotes Reminder Auto Email"/>
                                </TabPane>
                                : null
                            }
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        )
    }
}