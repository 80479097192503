import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Table } from 'reactstrap';
import { updateQtyUnitFormat } from '../../../src/services/CommonService';

export default class WarehouseLocationDetailsModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { isOpen, toggle, selectedItem, locationDetails } = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Warehouse Location Details of ProdCode {selectedItem.prodCode}
                </ModalHeader>
                <ModalBody>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                            <tr>
                                <th className={"align-middle text-center"}>S.No.</th>
                                <th className={"align-middle text-center"}>Name</th>
                                <th className={"align-middle text-center"}>Warehouse</th>
                                <th className={"align-middle text-center"}>Location Type</th>
                                <th className={"align-middle text-center"}>QOH ({updateQtyUnitFormat(null, locationDetails[0]?.eachUnitName)})</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(locationDetails || []).map((item, index) =>
                                <tr key={index}>
                                    <td className={"align-middle text-center"}>
                                        {index + 1}
                                    </td>
                                    <td className={"align-middle text-center"}>
                                        {item.locationName}
                                    </td>
                                    <td className={"align-middle text-center"}>
                                        {item.warehouseName}
                                    </td>
                                    <td className={"align-middle text-center"}>
                                        {item.locationTypeName}
                                    </td>
                                    <td className={"align-middle text-center"}>
                                        {item.qoh}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={3}>
                                </td>
                                <th className={"align-middle text-center"}>
                                    Total
                                </th>
                                <th className={"align-middle text-center"}>
                                    {selectedItem.qoh}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;Close
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}