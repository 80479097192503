import React,{ Component } from "react";
import {
    Breadcrumb,  BreadcrumbItem, Col, Row, Button,  Tooltip,  Table,Card,
    CardHeader,CardBody,CardFooter,  Input,InputGroup, InputGroupAddon, InputGroupText,Spinner, Badge, Nav, NavItem, TabContent, TabPane, NavLink
} from "reactstrap";
import PrivilegeService from "../services/PrivilegeService";
import { handleErrorMessage } from "../services/CommonService";
import { toast, ToastContainer } from "react-toastify";
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import ManagePrivilegeModal from '../components/modal/ManagePrivilegeModal';
export default class PrivilegePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            privilegeData: {
                request: {
                    pageRequest: {
                        pageSize: 50,
                        currentPage:1
                    },
                    sortRequest: {
                        key: '',
                        direction:false
                    },
                    filterRequest: {
                        name: ''
                    }
                },
                response: {
                    records: [],
                    totalRecords:0
                }
            },
            isLoading: false,
            isOpenModal: false,
            selectedItem: {}
        };
        this.getHeading = this.getHeading.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.privilegeService = new PrivilegeService();
        this.getPrivilegeData = this.getPrivilegeData.bind(this);
        this.getHeading = this.getHeading.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
    }

    componentDidMount() {
        this.getPrivilegeData();
    }
    getHeading({ filterRequest }) {
        let heading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="name" name="name" value={filterRequest.name}
                    onChange={(e) => this.handleChange(e.target.value, "name")} placeholder="Search... " />
                </div>


            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "usage",
                label: "Usage",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "groupName",
                label: "Group Name",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: null

            }];
        return heading;
    }
    getPrivilegeData() {
        this.setState({ isLoading: true });
        let { privilegeData } = this.state;
        this.privilegeService.getPrivilegeList(this.state.privilegeData.request).then(response => {
            privilegeData.response = response.data;
            this.setState({ privilegeData, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }
    openModal(item) {
        this.setState({ isOpenModal: true, selectedItem:item });
    }
    toggleModal(data) {
        this.setState({ isOpenModal: data });
    }
    refreshAgain() {
        this.setState({ isOpenModal: false });
        this.getPrivilegeData();
    }
    handleChange(change, key) {
        let { privilegeData } = this.state;
        switch (key) {
            case "sortKey":
                if (privilegeData.request.sortRequest.key === change) {
                    privilegeData.request.sortRequest.direction = !privilegeData.request.sortRequest.direction;
                } else {
                    privilegeData.request.sortRequest.key = change;
                    privilegeData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getPrivilegeData(privilegeData);
                }
                break;
            case "pageSize":
                privilegeData.request.pageRequest[key] = parseInt(change);
                privilegeData.request.pageRequest.currentPage = 1;
                this.getPrivilegeData(privilegeData);
                break;
            case "currentPage":
                privilegeData.request.pageRequest[key] = change;
                this.getPrivilegeData(privilegeData);
                break;


            default:
                privilegeData.request.filterRequest[key] = change;
                privilegeData.request.pageRequest.currentPage = 1;
                this.getPrivilegeData(privilegeData);
                this.setState({ privilegeData });
        }
        }
    render() {
        let { privilegeData, isLoading, selectedItem, isOpenModal } = this.state;
        let { pageRequest, sortRequest } = privilegeData.request;
        let heading = this.getHeading(privilegeData.request);
        return (
            <div> 
                  
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {isLoading ? <Spinner className="primary"/> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {privilegeData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(heading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(heading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(privilegeData.response.records || []).map((privilegeData, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>{privilegeData.name}</td>
                                            <td className="text-left">{privilegeData.description}</td>
                                            <td className="text-left">{privilegeData.usage}</td>
                                            <td className="text-center">{privilegeData.groupName}</td>
                                            <td className="text-center">  <Button size={"sm"} color={"primary"}
                                                onClick={() => this.openModal(privilegeData)}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                            Edit
                                        </Button></td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    {isOpenModal ?
                        <ManagePrivilegeModal
                            toggle={this.toggleModal}
                            selectedItem={selectedItem}
                            isOpen={isOpenModal}
                            refreshAgain={this.refreshAgain}
                        /> : null}
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={isLoading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={privilegeData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter> 
                <br /> 
            </div>
        );
    }
}