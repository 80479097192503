//Stock takes
export const Tabs = [
    {
        index: "0",
        key: "stock-take-and-submission",
        label: "Stock"
    },
    {
        index: "1",
        key: "stock-take-approval",
        label: "Approval"
    },
    {
        index: "2",
        key: "stock-transaction-swish",
        label: "Stock Transactions"
    },
    {
        index: "3",
        key: "FABRIC",
        label: "Fabric"
    },
    {
        index: "4",
        key: "stock-fabric-transaction-keyway",
        label: "Fabric Transactions"
    }
    
];