import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Table,Spinner
} from 'reactstrap';
import dispatchOrderService from '../../services/DispatchOrderService';
import {toast, ToastContainer} from "react-toastify";
import { handleErrorMessage } from "../../services/CommonService";

const baseUrl = window.location.origin;
export default class DispatchPickupDetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedShipmentID: this.props.selectedShipmentID,
            selectedOrdNum:this.props.selectedOrdNum,
            pickupInfo: {},
            isLoading:false,
        }
    }

    componentDidMount() {
        this.fetchPickupDetail(this.state.selectedShipmentID);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedShipmentID !== nextProps.selectedShipmentID || this.state.selectedOrdNum !== nextProps.selectedOrdNum) {
            this.setState({ selectedShipmentID: nextProps.selectedShipmentID, selectedOrdNum: nextProps.selectedOrdNum }, () => {
                this.fetchPickupDetail(nextProps.selectedShipmentID);
            })
        }
    }

    fetchPickupDetail(shipmentID) {
        this.setState({ isLoading: true });
        dispatchOrderService.fetchPickupDetail(shipmentID).then(response => {
            let pickupInfo = response.data;
            this.setState({ isLoading: false, pickupInfo });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    } 

    render() {
        let {  isOpen, toggle } = this.props;
        let { pickupInfo, selectedOrdNum, isLoading } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Pickup Detail: #{selectedOrdNum}
                </ModalHeader>
                <ModalBody>
                    {isLoading ?<Spinner color={"primary"} size={"sm"}/>:
                        <Table>
                            <tbody>
                                <tr>
                                    <th>Pickup By:</th>
                                    <td>{pickupInfo.receivedByName}</td>
                                </tr>
                                <tr>
                                    <th>Phone:</th>
                                    <td>{pickupInfo.phone}</td>
                                </tr>
                                <tr>
                                    <th>Pickup Date:</th>
                                    <td>{pickupInfo.receivedDate}</td>
                                </tr>
                                <tr>
                                    <th>Signature:</th>
                                    <td>
                                        <a href={pickupInfo.fileUrl ? pickupInfo.fileUrl : baseUrl + "/api/file/generate/pickup/sign?FileName=" + pickupInfo.fileName} target={"_blank"}
                                            download={pickupInfo.filename ? pickupInfo.filename : ""}>
                                            <img title="Click to Download"
                                                src={pickupInfo.fileUrl ? pickupInfo.fileUrl : baseUrl + "/api/file/generate/pickup/sign?FileName=" + pickupInfo.fileName}
                                                style={{ maxHeight: 150 }} />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}><i
                            className="fa fa-times" />&nbsp;
                            Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}