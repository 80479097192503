import React, {Component} from "react";
import {
    Col, Row, Modal, ModalBody, ModalHeader, ModalFooter,
    Button, FormGroup, Label, Input, FormText, InputGroup, InputGroupAddon, InputGroupText, Alert
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {cloneDeep} from 'lodash';
import retailService from '../../services/retail/RetailService';
import {handleErrorMessage, roundDown} from "../../services/CommonService";
import UserService from "../../services/UserService";
import salesOrderProductBuilderV1Service from '../../services/sales/SalesOrderProductBuilderV1Service';

export default class SalesOrderProductBuilderAdditionalPreApprovedRetailDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discount: 0,
            maximumApplicableDiscount: 0,
            preApprovedDiscount: 0,
            formError: {
                isValid: true,
                discount: {
                    isValid: true,
                    message: ""
                }
            },
            isSubmitted: false
        };
        this.userService = new UserService();
        this.getUserPreApprovedDiscountByUserID = this.getUserPreApprovedDiscountByUserID.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitDiscount = this.submitDiscount.bind(this);
    }

    componentDidMount() {
        let {order} = this.props;
        let maximumApplicableDiscount = roundDown(salesOrderProductBuilderV1Service.calculateMarkupDiscount(order.pricing), 2);
        this.setState({discount: order.retailMarkupDiscount, maximumApplicableDiscount});
        this.getUserPreApprovedDiscountByUserID();
    }

    getUserPreApprovedDiscountByUserID() {
        let {preApprovedDiscount} = this.state;
        let {order} = this.props;
        let currentUser = this.userService.getLoggedInUser();
        retailService.getUserPreApprovedDiscountByUserID(order.accountID, currentUser.userId).then(response => {
            preApprovedDiscount = response.data ? response.data.discount : 0;
            this.setState({preApprovedDiscount});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    handleChange(key, change) {
        let {formError, isSubmitted} = this.state;
        switch (key) {
            case "discount":
                if (isSubmitted) {
                    formError = this.validateForm(change);
                }
                this.setState({discount: change, formError});
                break;
            default:
                this.setState({[key]: change});
        }
    }

    validateForm(discount) {
        let {formError, maximumApplicableDiscount, preApprovedDiscount} = this.state;
        let maxDiscount = Math.min(maximumApplicableDiscount, preApprovedDiscount);
        formError = {
            discount: {
                isValid: true,
                message: ""
            }
        };

        if (discount < 0) {
            formError.discount.isValid = false;
            formError.discount.message = "Entered discount cannot be less than 0 %";
        }
        if (formError.discount.isValid && discount > maxDiscount) {
            formError.discount.isValid = false;
            formError.discount.message = "Entered discount cannot be more than " + maxDiscount + "%";
        }
        return formError;
    }

    submitDiscount() {
        let {formError, discount} = this.state;
        formError = this.validateForm(discount);
        this.setState({formError, isSubmitted: true}, () => {
            if (formError.discount.isValid) {
                this.props.handleOrderChange("retailMarkupDiscount", parseFloat(discount ? discount : 0));
            } else {
                toast.error(formError.discount.message);
            }
        })
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {formError, discount, maximumApplicableDiscount, preApprovedDiscount} = this.state;

        let maxDiscount = Math.min(maximumApplicableDiscount, preApprovedDiscount);
        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Additional Discount
                </ModalHeader>
                <ModalBody>
                    {
                        maxDiscount
                            ? <FormGroup>
                                <Label for="name">Discount*</Label>
                                <InputGroup>
                                    <Input type="number" name="discount"
                                           invalid={!formError.discount.isValid}
                                           value={discount}
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           placeholder="Enter discount here"/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                <FormText color="danger">{formError.discount.message}</FormText>
                            </FormGroup>
                            : <Alert color={"info"}>
                                Discount not allowed, please contact admin!
                            </Alert>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm"
                                className={"mr-2"}
                                onClick={this.submitDiscount}>
                            <i className="fa fa-check mr-2"/>
                            Ok</Button>
                        <Button color={"secondary"} size="sm"
                                onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2"/>
                            Cancel</Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>

        );
    }
}