import React, { Component } from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage } from "../../services/CommonService";
import { isEmpty } from "lodash";
import CreditorService from "../../services/purchase/CreditorService";

export default class CreditorCategoryStatusDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryStatusData: [],
            isLoading: false,
            accountID: "",
            totalLocal: 0,
            totalInternational: 0,
            totalActiveSuppliers: 0
        };
        this.getCreditorCategoryStatusData = this.getCreditorCategoryStatusData.bind(this);
        this.getTotal = this.getTotal.bind(this);
    }

    componentDidMount() {
        this.getCreditorCategoryStatusData();
    }

    getCreditorCategoryStatusData() {
        this.setState({ isLoading: true });
        CreditorService
            .getSupplierCategoryStatusDashboard()
            .then((response) => {
                let categoryStatusData = response.data;
                this.setState({ categoryStatusData, isLoading: false }, () => this.getTotal());
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                console.error(error);
            });
    }

    getTotal() {
        let { categoryStatusData } = this.state;
        let totalLocal = 0, totalInternational = 0, totalActiveSuppliers = 0;
        categoryStatusData.forEach(data => {
            data.total = data.local + data.international;
            totalLocal += data.local;
            totalInternational += data.international;
        });
        totalActiveSuppliers = totalLocal + totalInternational;
        this.setState({ categoryStatusData, totalLocal, totalInternational, totalActiveSuppliers });
    }

    render() {
        let { categoryStatusData, isLoading, totalLocal, totalInternational, totalActiveSuppliers } = this.state;
        if (isLoading || isEmpty(categoryStatusData)) {
            return null;
        }

        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"pl-0 pb-3"}>
                <Card>
                    <CardHeader>
                        <h6 className={"pb-0"}>Windoware Supplier</h6>
                    </CardHeader>
                    <CardBody>
                        <Table
                            responsive
                            size="sm">
                            <thead>
                                <tr>
                                    <th className={"text-left"}>Active Supplier</th>
                                    <th className={"text-right"}>Local</th>
                                    <th className={"text-right"}>International</th>
                                    <th className={"text-right"}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryStatusData &&
                                    categoryStatusData.length > 0 ? (
                                    (categoryStatusData || []).map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <th className={"text-left"}>{item.activeSupplier ? item.activeSupplier : "Unassigned"}</th>
                                                <td className={"text-right"}>{item.local}</td>
                                                <td className={"text-right"}>{item.international}</td>
                                                <th className={"text-right"}>{item.total}</th>
                                            </tr>
                                        )
                                    )
                                )
                                    :
                                    null
                                }
                                <tr>
                                    <th className={"text-left"}>Total</th>
                                    <th className={"text-right"}>{totalLocal}</th>
                                    <th className={"text-right"}>{totalInternational}</th>
                                    <th className={"text-right"}>{totalActiveSuppliers}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
