import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardHeader, Col, Input, Row, Table, Spinner} from 'reactstrap';
import NumberFormat from "react-number-format";
import {cloneDeep, isEmpty} from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import ModalImage from "react-modal-image";
import {
    DEFAULT_IMAGE_THUMBNAIL_500X250,
    DEFAULT_IMAGE_THUMBNAIL_50X50
} from "../../../../../../store/ImagePathConstants";
import {PRODUCT_BUILDER_CURTAIN_TRACK_PART} from "../../../../../../store/AppConstants";

import {
    concatenateStrings, getDistinct, getSorted,
    groupBy
} from "../../../../../../services/CommonService";
import curtainTrackPartUtil from "./CurtainTrackPartUtil";
import curtainTrackUtil from "./CurtainTrackUtil";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {v4 as uuidv4} from 'uuid';

export default class CurtainTrackPart extends Component {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.init();
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    init() {
        let {product} = this.props;
        if (!product.init_part) {
            product = curtainTrackPartUtil.prepareConfiguration(product);
            product = this.updateItemConfigurationOptions("product_part_part_dropdown", product, -1, null, false);
            product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
            product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);

            product.init_part = true;
            this.props.handleChange("product_init", product);
        }
    }

    handleItemAction(key, itemIndex, context) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let item;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product.items[itemIndex].itemType = PRODUCT_BUILDER_CURTAIN_TRACK_PART;
                product = this.updateConfiguration(product, itemIndex, context, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                break;
            case "delete":
                product.items.splice(itemIndex, 1);

                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    if (si.salesOrderItemCurtainTrackPart) {
                        item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                        product.items.push(item); //insert item
                        itemIndex = product.items.length - 1; // get item index
                        product.items[itemIndex].itemType = PRODUCT_BUILDER_CURTAIN_TRACK_PART;
                        product = this.updateConfiguration(product, itemIndex, si.salesOrderItemCurtainTrackPart, order); //update config for item
                        product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone
                        product = curtainTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                    }
                });
                product.savedItems = product.savedItems.filter(si => isEmpty(si.salesOrderItemCurtainTrackPart));
                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);

        }
        product = curtainTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {
        let part;
        product.items[itemIndex].customID = uuidv4();
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].quantity = {
            selected: {value: context.quantity ? context.quantity : 1},
            isSelectable: true,
            min: 0,
            max: 10000,
            formError: {isValid: true, message: ""}
        };

        part = context.prodCode && product.items[itemIndex].itemType === PRODUCT_BUILDER_CURTAIN_TRACK_PART
            ? product.part.configuration.part.options.find(o => o.prodCode === context.prodCode)
            : null;
        product.items[itemIndex].prodCode = part ? part.prodCode : "KW-HOSPITAL";
        product.items[itemIndex].description = part ? part.description : "";
        product.items[itemIndex].colour = part ? part.colour : "";
        product.items[itemIndex].partType = part ? part.partType : "";
        product.items[itemIndex].length = part ? part.length : 0;
        product.items[itemIndex].eachUnitName = part ? part.eachUnitName : "";
        product.items[itemIndex].dimensionUnitName = part ? part.dimensionUnitName : "";
        product.items[itemIndex].sellUnitName = part ? part.sellUnitName : "";
        product.items[itemIndex].sellUnitQty = part ? part.sellUnitQty : 0;
        product.items[itemIndex].sellQtyPrice = part ? part.sellQtyPrice : 0;
        product.items[itemIndex].unitPrice = part ? part.sellQtyPrice : 0;
        product.items[itemIndex].whUnitName = part ? part.whUnitName : "";
        product.items[itemIndex].whUnitQty = part ? part.whUnitQty : 0;
        product.items[itemIndex].swishConversionFactorQty = part ? part.swishConversionFactorQty : 1;
        product.items[itemIndex].swishConversionFactorPrice = part ? part.swishConversionFactorPrice : 1;
        product.items[itemIndex].thumbnailImageAttachmentID = part ? part.thumbnailImageAttachmentID : "";
        product.items[itemIndex].highQualityImageAttachmentID = part ? part.highQualityImageAttachmentID : "";

        //colour
        product.items[itemIndex].configuration.colour = {
            options: [],
            finalOptions: [],
            selected: {
                value: {}
            }
        };

        //part
        product.items[itemIndex].configuration.partRange = {
            options: [],
            finalOptions: [],
            selected: {
                value: []
            }
        };

        product = curtainTrackPartUtil.updateFormError("quantity", product, itemIndex);
        return product;
    }


    handleChange(change, key, itemIndex) {
        let {product, packagingAndHandling, discountByProductCode, order} = this.props;
        switch (key) {
            case "toggle_partType":
                product.part.configuration.partType.finalOptions[itemIndex].isOpen = change;
                product.part.configuration.partType.isOpen = curtainTrackPartUtil.isOpenAllPartType(product, true);
                break;
            case "toggle_partType_all":
                product.part.configuration.partType.finalOptions.forEach(o => {
                    o.isOpen = change;
                });
                product.part.configuration.partType.isOpen = curtainTrackPartUtil.isOpenAllPartType(product, true);
                break;
            case "product_colour_part_search":
                product.part.configuration.colour.dropdown.selected.value = change;
                product = this.updateItemConfigurationOptions("product_part_part_dropdown", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                break;
            case "product_part_part_dropdown_search":
                product.part.configuration.part.dropdown.selected.value = change;
                product = this.updateItemConfigurationOptions("product_part_part_displayList", product, -1, null, false);
                product = this.updateItemConfigurationOptions("product_part_type_options", product, -1, null, false);
                product.part.configuration.partType.isOpen = curtainTrackPartUtil.isOpenAllPartType(product, false);
                break;
            case "quantity":
                product.items[itemIndex].quantity.selected.value = change;
                product = curtainTrackPartUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "item_colour":
                product.items[itemIndex].configuration.colour.selected.value = change;
                product = this.updateItemConfigurationOptions("product_item_part_range_options", product, itemIndex, null, false);
                break;
            case "item_part":
                product.items[itemIndex].configuration.partRange.selected.value = change;
                product = this.updateItemConfigurationOptions("product_item_part_range_selected_parts", product, itemIndex, null, false);
                break;
        }
        product = curtainTrackUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

        this.props.handleChange(product, "product");
    }

    updateItemConfigurationOptions(key, product, itemIndex, context, isResetSelectionRequired) {
        let optionIndex = -1;
        switch (key) {
            case "product_part_part_dropdown":
                product.part.configuration.part.dropdown.finalOptions = (product.part.configuration.part.options || []).filter(x => {
                    if (!isEmpty(product.part.configuration.colour.dropdown.selected.value)) {
                        return product.part.configuration.colour.dropdown.selected.value.some(s => s.value === x.colour);
                    }
                    return true
                });
                product.part.configuration.part.dropdown.finalOptions = (product.part.configuration.part.dropdown.finalOptions || []).map(o => {
                    return {label: concatenateStrings(' | ', o.prodCode, o.description), value: o.prodCode, ...o}
                });
                product.part.configuration.part.dropdown.selected.value = [];
                break;
            case "product_part_part_displayList":
                product.part.configuration.part.displayList.finalOptions = (product.part.configuration.part.options || []).filter(item => {
                    let result = true;
                    if (result && !isEmpty(product.part.configuration.colour.dropdown.selected.value)) {
                        result = (product.part.configuration.colour.dropdown.selected.value || []).some(x => x.value === item.colour)
                    }
                    if (result && !isEmpty(product.part.configuration.part.dropdown.selected.value)) {
                        result = (product.part.configuration.part.dropdown.selected.value || []).some(x => x.value === item.prodCode)
                    }
                    if (result && !isEmpty(product.items)) {
                        result = !((product.items || []).some(x => x.itemType === PRODUCT_BUILDER_CURTAIN_TRACK_PART && x.prodCode === item.prodCode));
                    }
                    return result
                });
                break;
            case "product_part_type_options":
                let partsByPartType = groupBy(product.part.configuration.part.displayList.finalOptions, 'partType');
                product.part.configuration.partType.finalOptions = [];
                product.part.configuration.partType.options.forEach(o => {
                    if (partsByPartType[o.value]) {
                        o.parts = partsByPartType[o.value] ? partsByPartType[o.value] : [];
                        product.part.configuration.partType.finalOptions.push(o);
                    }
                });
                break;
            case "product_item_part_range_options":
                product.items[itemIndex].configuration.partRange.finalOptions = (product.items[itemIndex].configuration.partRange.options).filter(x => {
                    let result = true;
                    if (!isEmpty(product.items[itemIndex].configuration.colour.selected.value)) {
                        result = product.items[itemIndex].configuration.colour.selected.value.value === x.condition1;
                    }
                    return result
                });
                product.items[itemIndex].configuration.partRange.finalOptions = getDistinct(product.items[itemIndex].configuration.partRange.finalOptions, 'optionKey');
                (product.items[itemIndex].configuration.partRange.finalOptions || []).forEach(o => {
                    o.label = o.optionKey;
                    o.value = o.optionKey;
                });

                //trying to make ideal selection as it was before
                optionIndex = product.items[itemIndex].configuration.partRange.finalOptions.findIndex(o => o.optionKey === product.items[itemIndex].configuration.partRange.selected.value.optionKey);
                optionIndex = optionIndex > -1 ? optionIndex : 0;
                //falling back to first item as selected
                product.items[itemIndex].configuration.partRange.selected.value = product.items[itemIndex].configuration.partRange.finalOptions[optionIndex];
                product = this.updateItemConfigurationOptions("product_item_part_range_selected_parts", product, itemIndex, context, false);
                break;
            case "product_item_part_range_selected_parts":
                product.items[itemIndex].configuration.partRange.selected.parts = [];
                //find selected part range with combination of selected part name and colour
                optionIndex = product.items[itemIndex].configuration.partRange.options.findIndex(o => o.optionKey === product.items[itemIndex].configuration.partRange.selected.value.value && o.condition1 === product.items[itemIndex].configuration.colour.selected.value.value);
                if (optionIndex > -1) {
                    // find available lengths(stock items) for selection
                    let prodCodes = (product.items[itemIndex].configuration.partRange.options[optionIndex].stocks || []).map(s => s.prodCode);
                    product.items[itemIndex].configuration.partRange.selected.parts = product.part.configuration.part.options.filter(o => prodCodes.includes(o.prodCode));
                    product.items[itemIndex].configuration.partRange.selected.parts = getSorted(product.items[itemIndex].configuration.partRange.selected.parts, 'length', true);
                }
                break;
        }
        return product;
    }

    render() {
        let {product, itemSummary, currentUser, order, discountByProductCode} = this.props;
        let items = itemSummary.items;

        if (!product.init_part) {
            return <Spinner color={"primary"}/>;
        }

        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={4} lg={5} md={5} sm={12} xs={12}>
                        Select Colours
                        <Select
                            isMulti
                            onChange={(selected) => this.handleChange(selected, "product_colour_part_search", -1)}
                            value={product.part.configuration.colour.dropdown.selected.value}
                            options={product.part.configuration.colour.dropdown.finalOptions}
                        />
                    </Col>
                    <Col xl={4} lg={5} md={5} sm={12} xs={12}>
                        Select Part
                        <Select
                            isMulti
                            onChange={(selected) => this.handleChange(selected, "product_part_part_dropdown_search")}
                            value={product.part.configuration.part.dropdown.selected.value}
                            options={product.part.configuration.part.dropdown.finalOptions}
                        />

                    </Col>
                </Row>


                <Card>
                    <CardHeader className={"hoverableItem"}>
                        <i className="fa fa-th mr-2" aria-hidden="true"/>Preview Items
                    </CardHeader>
                    <div className="table-responsive">
                        <Table bordered>
                            <thead>
                            <tr>
                                <th className="text-center" style={{width: 50}}>
                                    <Button color={"primary"}
                                            size={"sm"}
                                            onClick={() => this.handleChange(!product.part.configuration.partType.isOpen, "toggle_partType_all", -1)}>
                                        <i className={classnames("fa", {
                                                "fa-plus-circle": !product.part.configuration.partType.isOpen,
                                                "fa-minus-circle": product.part.configuration.partType.isOpen,
                                            }
                                        )}/>
                                    </Button>
                                </th>
                                <th className="text-center" style={{width: 125}}>Product Code</th>
                                <th className="text-center">Photo</th>
                                <th className="text-center">Description</th>
                                <th className="text-center">Length</th>
                                <th className="text-center">Pack Qty</th>
                                <th className="text-center">Pack Qty Price</th>
                                <th className="text-center">Action</th>
                            </tr>
                            </thead>
                            {
                                ((product.part.configuration.partType.finalOptions) || []).map((o, oIndex) => {
                                    return <tbody key={oIndex}>
                                    <tr>
                                        <td className="align-middle text-center" style={{width: 50}}>
                                            <Button color={"primary"}
                                                    size={"sm"}
                                                    onClick={() => this.handleChange(!o.isOpen, "toggle_partType", oIndex)}>
                                                <i className={classnames("fa", {
                                                        "fa-plus-circle": !o.isOpen,
                                                        "fa-minus-circle": o.isOpen,
                                                    }
                                                )}/>
                                            </Button>
                                        </td>
                                        <td className="align-middle" colSpan={7}>
                                            <h6>{o.label}</h6>
                                        </td>
                                    </tr>

                                    {(o.isOpen && o.parts || []).map((p, pIndex) => {
                                        return <tr key={pIndex} id={p.prodCode}>
                                            <td className="align-middle text-center" colSpan={2}>
                                                <h5>
                                                    <Badge
                                                        color={"primary"}>{p.prodCode}</Badge>
                                                </h5>
                                            </td>
                                            <td className="align-middle text-center">
                                                <ModalImage
                                                    className="img-thumbnail"
                                                    small={p.thumbnailImageAttachmentID ? "api/file/Generate?AttachmentID=" + p.thumbnailImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_50X50}
                                                    large={p.highQualityImageAttachmentID ? "api/file/Generate?AttachmentID=" + p.highQualityImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_500X250}
                                                    alt="..."
                                                    hideDownload={true}
                                                    hideZoom={true}
                                                    imageBackgroundColor={"#FFFFFF"}
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <div>{p.description}</div>
                                                <div>Colour: {p.colour}</div>
                                            </td>
                                            <td className='align-middle text-center'>
                                                {((p.eachUnitName && p.eachUnitName.toLowerCase() === "length") || p.length !== null) ? concatenateStrings(" ", p.length, p.dimensionUnitName) : ""}
                                            </td>
                                            {/*<td className='align-middle text-center'>{p.sellUnitQty}&nbsp;{p.eachUnitName}</td>*/}
                                            <td className='align-middle text-center'>
                                                <div>
                                                    {p.sellUnitQty}&nbsp;{p.eachUnitName}{(p.sellUnitName && p.eachUnitName !== p.sellUnitName) ?
                                                    <span>&nbsp;/&nbsp;{p.sellUnitName}</span> : null}
                                                </div>
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, p.sellQtyPrice, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <Button
                                                    color={"primary"}
                                                    className={"mb-2 ml-1"}
                                                    onClick={() => this.handleItemAction("new", -1, {
                                                        ...p,
                                                        itemType: PRODUCT_BUILDER_CURTAIN_TRACK_PART
                                                    })}>
                                                    <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                                                    Add
                                                </Button>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                })
                            }

                        </Table>
                    </div>
                </Card>

                <Card className={"mt-2"}>
                    <CardHeader>
                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/> Added Items
                    </CardHeader>

                    {(items && items.length > 0) ?
                        <div className="table-responsive">
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{width: 125}}>Product Code</th>
                                    <th className="text-center">Photo</th>
                                    <th className="text-center">Description</th>
                                    <th className="text-center">Length</th>
                                    <th className="text-center">Pack Qty</th>
                                    <th className="text-center">Pack Price</th>
                                    <th className="text-center" style={{width: 125}}>Qty
                                        <div>(of Packs)</div>
                                    </th>
                                    <th className="text-center" style={{width: 95}}>Total Each Qty</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Discount</th>
                                    <th className="text-center">Total</th>
                                    <th className="text-center">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(items || []).map((item, itemIndex) => {
                                    return <tr key={itemIndex}
                                               className={classnames({"table-danger": !item.isValid})}>
                                        <td className="align-middle text-center">
                                            <h5><Badge
                                                color={"primary"}>{item.prodCode}</Badge>
                                            </h5>
                                        </td>
                                        <td className="align-middle text-center">
                                            <ModalImage
                                                className="img-thumbnail"
                                                small={item.thumbnailImageAttachmentID ? "api/file/Generate?AttachmentID=" + item.thumbnailImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_50X50}
                                                large={item.highQualityImageAttachmentID ? "api/file/Generate?AttachmentID=" + item.highQualityImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_500X250}
                                                alt="..."
                                                hideDownload={true}
                                                hideZoom={true}
                                                imageBackgroundColor={"#FFFFFF"}
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <div>{item.description}</div>
                                            <div>Colour: {item.colour}</div>
                                        </td>
                                        <td className='align-middle text-center'>
                                            {(item.eachUnitName === "length" || item.length !== null)
                                                ? <span>{item.length}{item.dimensionUnitName}</span>
                                                : null}
                                        </td>
                                        {/*<td className="align-middle text-center">{item.sellUnitQty}&nbsp;{item.eachUnitName}</td>*/}
                                        <td className='align-middle text-center'>
                                            <div>
                                                {item.sellUnitQty}&nbsp;{item.eachUnitName}{(item.sellUnitName && item.eachUnitName !== item.sellUnitName) ?
                                                <span>&nbsp;/&nbsp;{item.sellUnitName}</span> : null}
                                            </div>
                                        </td>
                                        <td className="align-middle text-center">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={item.sellQtyPrice}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-center">
                                            <Input type='number'
                                                   value={item.quantity.selected.value}
                                                   name='quantity'
                                                   onChange={(e) => this.handleChange(e.target.value, e.target.name, curtainTrackUtil.findItemIndex(product, item.customID))}
                                                   invalid={!item.quantity.formError.isValid}
                                                   min={item.quantity.min}
                                                   max={item.quantity.max}
                                                   onFocus={(event) => event.target.select()}
                                            />
                                            {
                                                (item.quantity.formError.isValid)
                                                    ? null
                                                    : <Badge color="danger">
                                                        {item.quantity.formError.message}
                                                    </Badge>
                                            }
                                        </td>
                                        <td className="align-middle text-center">
                                            <NumberFormat
                                                suffix={" " + item.eachUnitName}
                                                value={item.sellUnitQty * item.quantity.selected.value}
                                                displayType={'text'}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.discVal, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price - item.pricing.discVal, {})}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        </td>
                                        <td className="align-middle text-center">
                                            <Button color={"link"}
                                                    title={"click here to delete"}
                                                    onClick={() => this.handleItemAction("delete", curtainTrackUtil.findItemIndex(product, item.customID), null)}>
                                                <i className="fa fa-trash-o fa-lg text-danger"
                                                   aria-hidden="true"/>
                                            </Button>
                                        </td>
                                    </tr>

                                })}
                                {
                                    itemSummary.price
                                        ? <tr>
                                            <td className="align-middle" colSpan={8}>
                                                Total
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.price, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.discVal, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, itemSummary.price - itemSummary.discVal, {})}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td/>
                                        </tr>
                                        : null
                                }

                                </tbody>
                            </Table>
                        </div>
                        : <CardBody>
                            <p className={"text-info"}>
                                <i className="fa fa-meh-o mr-2" aria-hidden="true"/> No items added yet.
                            </p>
                        </CardBody>
                    }
                </Card>

            </div>
        );
    }
}