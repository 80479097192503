import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import {toast} from 'react-toastify';
import searchAddressService from '../../services/SearchAddressService';
import {handleErrorMessage} from "../../services/CommonService";

export default class SearchCountry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            selectedOption: {},
            defaultSelected: props.defaultSelected,
            country: props.selected,
            options: [],
        };

        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    componentDidMount() {
        this.fetchOptions(this.state.country || this.state.defaultSelected);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultSelected !== nextProps.defaultSelected) {
            if (nextProps.defaultSelected) {
                this.setState({
                    defaultSelected: nextProps.defaultSelected,
                    country: nextProps.defaultSelected
                }, () => {
                    this.fetchOptions(nextProps.defaultSelected);
                })
            }
        }

        if (this.state.country !== nextProps.selected) {
            if (!nextProps.selected) {
                this.setState({selectedOption: null, country: ""})
            }
            else {
                this.setState({country: nextProps.selected})
            }
            this.fetchOptions(nextProps.selected);
        }
    }

    fetchOptions(inputValue, callback) {
        let options = [];
        this.setState({loadingOptions: true});
        searchAddressService.searchCountry(inputValue).then(response => {
            (response.data || []).forEach((item) => {
                options.push({label: item.country, value: item.country})
            });
            (options || []).map((item, index) => {
                if (this.props.defaultSelected) {
                    if (item.value === this.props.defaultSelected) {
                        this.handleSuccess(item, "default");
                    }
                }
                if (this.props.selected) {
                    if (item.value === this.props.selected) {
                        this.handleSuccess(item, "selected");
                    }
                }
            })
            this.setState({options, loadingOptions: false});

            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
            this.setState({loadingOptions: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            else {
                callback(this.state.options);
            }
        }
    };

    handleSuccess(selectedCountry, key) {
        let {selectedOption, country} = this.state;
        selectedOption = selectedCountry;
        country = selectedCountry ? selectedCountry.value : "";
        switch (key) {
            case "selected":
                this.setState({selectedOption, country});
                break;
            case "default":
            case "onChange":
                this.setState({selectedOption, country});
                this.props.handleSelection(country);
        }
    };

    render() {
        let {selectedOption, loadingOptions, options} = this.state;
        return (
            <AsyncSelect
                isLoading={loadingOptions}
                isClearable={true}
                defaultOptions={options}
                loadOptions={this.prepareFetchOptions}
                onChange={(selectedCountry) => this.handleSuccess(selectedCountry, "onChange")}
                value={selectedOption}
                placeholder={"Search..."}
            />
        )
    }
}