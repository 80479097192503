import React, {Component} from 'react';
import {
    Card, CardHeader, CardBody, CardFooter,
    Table,
    Button, Badge,
    Row, Col,
    InputGroup, InputGroupText, InputGroupAddon, Input,
    Spinner,
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import addressService from '../../services/AddressService';
import {getSorted, findIndex, handleErrorMessage} from '../../services/CommonService';
import CreateAddressStandardModal from '../../components/address/CreateAddressStandardModal';
import {toast, ToastContainer} from 'react-toastify';
import {Link as Link} from "react-router-dom";

export default class AddressStandardPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addressStandard: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ID",
                        direction: false
                    },
                    filterRequest: {
                        stateID: "",
                        postcode: "",
                        isMetro: "",
                        city: "",
                        area_code: ""
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: {
                addressStandardList: false,
                workingAddressStandardSave: false,
                states: false,
            },
            states: [],
            statesDictionary: [],
            workingAddressStandard: {},
            isOpenWorkingAddressStandardModal: false,
            searchText: "",
        };

        this.refresh = this.refresh.bind(this);

        this.getStore = this.getStore.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.searchFunction = this.searchFunction.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.getStates = this.getStates.bind(this);
        this.getAddressStandardRecords = this.getAddressStandardRecords.bind(this);
        this.toggleWorkingAddressStandardModal = this.toggleWorkingAddressStandardModal.bind(this);
        this.handleWorkingAddressStandardChange = this.handleWorkingAddressStandardChange.bind(this);
        this.handleWorkingAddressStandardSubmit = this.handleWorkingAddressStandardSubmit.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        let {addressStandard} = this.state;
        this.getStates();
        this.getAddressStandardRecords(addressStandard);
    }

    toggleWorkingAddressStandardModal(change) {
        this.setState({isOpenWorkingAddressStandardModal: change});
    }

    getStates() {
        let {states, statesDictionary, loading} = this.state;
        loading.states = true;
        this.setState({loading});
        addressService.getStates().then(response => {
            if (response.data) {
                states = response.data;
            }
            loading.states = false;
            for (let i in states) {
                statesDictionary[states[i].stateID] = states[i];
            }
            states = getSorted(states, 'name', true);
            this.setState({states, loading});
        }).catch(error => {
            loading.states = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        })
    }

    getAddressStandardRecords(addressStandard) {
        let {loading,} = this.state;
        loading.addressStandardList = true;
        this.setState({loading});
        let request = cloneDeep(addressStandard.request);
        addressService.getAddressStandards(request).then(response => {
            addressStandard.response = response.data;

            loading.addressStandardList = false;
            this.setState({addressStandard, loading});
        }).catch(error => {
            loading.addressStandardList = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        })
    }


    getNewWorkingAddressStandard() {
        return {
            id: -1,
            stateID: "",
            isMetro: false
        };
    }

    handleAction(change, key) {
        let {workingAddressStandard, addressStandard} = this.state;
        let records = addressStandard.response.records;
        switch (key) {
            case "new":
                workingAddressStandard = change;
                this.setState({workingAddressStandard, isOpenWorkingAddressStandardModal: true});
                break;
            case "edit":
                let index = findIndex(records, 'id', change);
                if (index > -1) {
                    workingAddressStandard = cloneDeep(records[index]);
                    this.setState({workingAddressStandard, isOpenWorkingAddressStandardModal: true});
                }
                break;

        }
    }

    handleWorkingAddressStandardChange(change, key) {
        let {workingAddressStandard} = this.state;
        switch (key) {

            default:
                workingAddressStandard[key] = change;
                this.setState({workingAddressStandard});
        }
    }

    handleWorkingAddressStandardSubmit(workingAddressStandard) {

        let {loading, addressStandard} = this.state;
        loading.workingAddressStandardSave = true;
        this.setState({loading});

        addressService.saveAddressStandard(workingAddressStandard).then(response => {
            toast.success("Saved!");
            loading.workingAddressStandardSave = false;
            this.setState({workingAddressStandardSave: {}, loading, isOpenWorkingAddressStandardModal: false});
            this.getAddressStandardRecords(addressStandard);
        }).catch(error => {
            loading.workingAddressStandardSave = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));

        });
    }

    handleStoreChange(change, key) {
        let {addressStandard} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (addressStandard.request.sortRequest.key === change) {
                    addressStandard.request.sortRequest.direction = !addressStandard.request.sortRequest.direction;
                } else {
                    addressStandard.request.sortRequest.key = change;
                    addressStandard.request.sortRequest.direction = false;
                }
                this.getAddressStandardRecords(addressStandard);
                break;
            case "pageSize":
                addressStandard.request.pageRequest[key] = change;
                this.getAddressStandardRecords(addressStandard);
                break;
            case "currentPage":
                addressStandard.request.pageRequest[key] = change;
                this.getAddressStandardRecords(addressStandard);
                break;
            default:
                addressStandard.request.filterRequest[key] = change;
                this.setState({addressStandard});
                this.getAddressStandardRecords(addressStandard);
        }

    }

    searchFunction(item, searchText) {
        let {statesDictionary} = this.state;
        let flag = true;
        if (flag && item.id && searchText) {
            flag = item.id.toString().includes(searchText)
        }

        if (!flag && item.postcode && searchText) {
            flag = item.postcode.toLowerCase().includes(searchText.toLowerCase())
        }
        if (!flag && item.stateID && searchText) {
            flag = statesDictionary[item.stateID].name.toLowerCase().includes(searchText.toLowerCase())
        }

        return flag;
    }

    getColValue(value, storeItem) {

        let {statesDictionary} = this.state;
        switch (storeItem.type) {

            case "isMetro":
                return <div className={"text-center"}>
                    <h5><Badge color={value ? "primary" : "secondary"}>{value ? "Metro" : "Non-Metro"}</Badge></h5>
                </div>;
            case "state":
                return <span>{statesDictionary[value] ? statesDictionary[value].name : null}</span>;
            case "action":
                return <div><Button color={"primary"} size={"sm"}
                                    onClick={() => this.handleAction(value, "edit")}><i className="mr-2 fa fa-pencil"
                                                                                        aria-hidden="true"/>Edit</Button>
                </div>;
            default:
                return <span>{value}</span>
        }
    }


    getStore() {
        let {states, addressStandard} = this.state;
        let {pageRequest, sortRequest, filterRequest} = addressStandard.request;
        let store = [


            {
                key: "postcode",
                label: "Postcode",
                type: "default",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"postcode"}
                           value={filterRequest.postcode}
                           onChange={(e) => this.handleStoreChange(e.target.value, "postcode")}/>

                </div>
            },
            {
                key: "city",
                label: "City",
                type: "default",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"city"}
                           value={filterRequest.city}
                           onChange={(e) => this.handleStoreChange(e.target.value, "city")}/>
                </div>
            },
            {
                key: "isMetro",
                label: "Is Metro",
                type: "isMetro",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "text-centre",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"select"}
                           name={"isMetro"}
                           value={filterRequest.isMetro}
                           onChange={(e) => this.handleStoreChange(e.target.value, "isMetro")}>
                        <option value={""}>All</option>
                        <option value={true}>Metro</option>
                        <option value={false}>Non-metro</option>
                    </Input>
                </div>
            },
            {
                key: "stateID",
                label: "State",
                type: "state",
                colSpan: 1,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"select"}
                           name={"stateID"}
                           value={filterRequest.stateID}
                           onChange={(e) => this.handleStoreChange(e.target.value, "stateID")}>
                        <option value={""}>All</option>
                        {states.map((option, index) =>
                            <option key={index} value={option.stateID}>{option.name}</option>
                        )}
                    </Input>
                </div>
            },
            {
                key: "area_code",
                label: "Area Code",
                type: "area_code",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                style: null,
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"city"}
                           value={filterRequest.area_code}
                           onChange={(e) => this.handleStoreChange(e.target.value, "area_code")}/>
                </div>
            },
            {
                key: "id",
                label: "Action",
                type: "action",
                colSpan: 1,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                style: {minWidth: 100},
                searchNode: <div className={"text-center"}>
                    <Button color={"primary"} size={"sm"}
                            onClick={() => this.handleAction(this.getNewWorkingAddressStandard(), "new")}>
                        <i className="mr-2 fa fa-plus-circle" aria-hidden="true"/>New</Button>
                </div>
            }];

        return store;
    }


    render() {
        let {addressStandard, loading, searchText, isOpenWorkingAddressStandardModal, workingAddressStandard, states} = this.state;
        let {pageRequest, sortRequest, filterRequest} = addressStandard.request;
        let store = this.getStore();

        return (
            <div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Manage Address Standards</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader><Row>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"text-left"}>
                                {
                                    loading.addressStandardList
                                        ? <p className="mt-1 mb-0">Loading...</p>
                                        : <p className="mt-1 mb-0">Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {addressStandard.response.totalRecords}
                                            {' '}entries</p>
                                }
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-right"}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fa fa-search"
                                               aria-hidden="true"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"text"}
                                        name={"searchText"}
                                        placeholder={"Search..."}
                                        value={searchText}
                                        onChange={(e) => this.handleStoreChange(e.target.value, e.target.name)}
                                    />

                                </InputGroup>
                            </div>

                        </Col>


                    </Row></CardHeader>
                    <CardBody>
                        <div>
                            <Table hover bordered responsive={true} size={"sm"} striped={true}>
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleStoreChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={item.style}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort text-muted": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    <td/>
                                    {(store || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>
                                </thead>
                                <tbody>

                                {(addressStandard.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, index) => {
                                    return (
                                        <tr key={index}>
                                            <td key={index}>
                                                {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                            </td>
                                            {(store || []).map((storeItem, index) => {
                                                return (
                                                    <td key={index} className={storeItem.valueClassName}>
                                                        {this.getColValue(order[storeItem.key], storeItem)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>

                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter><Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-left"} style={{maxWidth: 200}}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>{loading.addressStandardList ?
                                            <Spinner size={"sm"}/> : "Show"}</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"select"}
                                        name={"pageSize"}
                                        value={pageRequest.pageSize}
                                        disabled={loading.addressStandardList}
                                        onChange={(e) => this.handleStoreChange(e.target.value, "pageSize")}>
                                        <option value={10}>10 Rows</option>
                                        <option value={25}>25 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>


                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"float-right"}>
                                <Pagination
                                    activePage={pageRequest.currentPage}
                                    itemsCountPerPage={pageRequest.pageSize}
                                    totalItemsCount={addressStandard.response.totalRecords}
                                    pageRangeDisplayed={3}
                                    onChange={(activePage) => this.handleStoreChange(activePage, "currentPage")}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'
                                />
                            </div>

                        </Col>
                    </Row></CardFooter>
                </Card>
                <CreateAddressStandardModal
                    isOpen={isOpenWorkingAddressStandardModal}
                    toggle={this.toggleWorkingAddressStandardModal}
                    form={workingAddressStandard}
                    handleChange={this.handleWorkingAddressStandardChange}
                    handleSubmit={this.handleWorkingAddressStandardSubmit}
                    states={states}
                    loading={loading.workingAddressStandardSave}
                />
                <ToastContainer/>
            </div>
        );
    }
}