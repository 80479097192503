import React, {Component} from 'react';
import {Badge, Button, Input, Table} from 'reactstrap';

import {findIndex, scrollTo} from '../../../../../../services/CommonService';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';

import quiklokUtil from './QuiklokUtil';
import NumberFormat from "react-number-format";
import QuiklokBOMModal from "./QuiklokBOMModal";
import QuiklokConsolidatedBOMModal from "./QuiklokConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import customUtil from "../custom/CustomUtil";
import {PRODUCT_BUILDER_CUSTOM_BUILD, PRODUCT_BUILDER_QUIKLOK_PRODCODE} from "../../../../../../store/AppConstants";

export default class Quiklok extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = quiklokUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item, productIndex, stocksByProdCode, property, stock;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = quiklokUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = quiklokUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    product = quiklokUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
                });
                product.savedItems = [];
                break;
            case "convertToCustom":
                productIndex = products.findIndex(p => p.name === "CUSTOM");
                stocksByProdCode = context;
                if (productIndex > -1) {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                    products[productIndex].items.push(item);

                    //prepare context
                    context = {
                        salesOrderItemCustom: customUtil.getSalesOrderItemCustomInstance(PRODUCT_BUILDER_CUSTOM_BUILD)
                    };
                    context.salesOrderItemCustom.location = "";
                    context.salesOrderItemCustom.quantity = product.items[itemIndex].configuration.quantity.selected.value;
                    context.salesOrderItemCustom.width = product.items[itemIndex].configuration.width.selected.value;
                    context.salesOrderItemCustom.drop = product.items[itemIndex].configuration.drop.selected.value;
                    context.salesOrderItemCustom.prodCode = PRODUCT_BUILDER_QUIKLOK_PRODCODE;
                    context.salesOrderItemCustom.productCode = product.code;
                    context.salesOrderItemCustom.productGroupCode = product.productGroup ? product.productGroup.code : "";
                    context.salesOrderItemCustom.productionInstructions = "";
                    context.salesOrderItemCustom.productionNotes = "";
                    context.salesOrderItemCustom.stocks = product.items[itemIndex].stocks;
                    context.salesOrderItemCustom.stocks.forEach(s => {
                        s.isCustom = false;
                        property = camelCase(s.prodCode);
                        stock = null;
                        if(stocksByProdCode){
                            stock = stocksByProdCode[property] ? stocksByProdCode[property] : (Object.values(stocksByProdCode) || []).find(o=> o.prodCode === s.prodCode);
                        }
                        if(stock){
                            s.description = stock.description;
                            s.unitPrice = stock.price1;
                        }
                    });
                    products[productIndex] = customUtil.initItem(products[productIndex], products[productIndex].items.length - 1, context, order, packagingAndHandling, discountByProductCode);
                }
                this.props.handleChange("product", products[productIndex]);

                product.items.splice(itemIndex, 1);
                this.toggleBOMModal(false, -1);
                break;
        }
        product = quiklokUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);
        product.init = true;
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;
        let {currentUser} = this.props;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemQuiklok.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.selected = {
            value: 0,
            finishWidth: 0
        };

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemQuiklok.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 1500;
        }
        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {
            value: 0,
            finishDrop: 0,
            isSelectable:true
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemQuiklok.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 1500;
        }
        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {};
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemQuiklok.model);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};


        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {};
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemQuiklok.mount);
        }
        optionIndex = optionIndex > -1 ? optionIndex : findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', "Outside/Face");
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};


        //bom
        configurationIndex = findIndex(configurations, "attributeKey", "bom");
        product.items[itemIndex].configuration.bom = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bom.selected = {};

        //productionCalculation
        configurationIndex = findIndex(configurations, "attributeKey", "productionCalculation");
        product.items[itemIndex].configuration.productionCalculation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.productionCalculation.selected = {

            fabricCutDrop: 0,
            fabricCutWidth: 0,
        };

        //deduction
        product.items[itemIndex].configuration.deduction = {
            deductionRules: [],
            selected: {
                value: {
                    deduction: 0
                }
            }
        };

        //colour
        configurationIndex = findIndex(configurations, "attributeKey", "colour");
        product.items[itemIndex].configuration.colour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.colour.selected = {};
        product.items[itemIndex].configuration.colour.finalOptions = product.items[itemIndex].configuration.colour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.colour.finalOptions, 'optionKey', context.salesOrderItemQuiklok.colour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.colour.selected.value = product.items[itemIndex].configuration.colour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.colour.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        product.items[itemIndex].configuration.colour.formError = { isValid: true, message: "", dom: null, };


        product = quiklokUtil.updateRules("quantity", product, itemIndex, order);
        product = quiklokUtil.updateRules("width", product, itemIndex, order);
        product = quiklokUtil.updateRules("drop", product, itemIndex, order);

        product = this.updateItemConfigurationOptions("model", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("colour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);

        product = quiklokUtil.updateFormError("quantity", product, itemIndex, order);
        product = quiklokUtil.updateFormError("width", product, itemIndex, order);
        product = quiklokUtil.updateFormError("drop", product, itemIndex, order);

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let optionIndex = -1,condition1="";
        let {currentUser} = this.props;
        if (key) {
            switch (key) {
                case "basePrice":
                    if (quiklokUtil.getBasePriceBasedOnWidthAndDrop(product, itemIndex)) {
                        product.items[itemIndex].configuration.set.selected.value.price = 0;
                    } else {
                        product.items[itemIndex].configuration.set.selected.value.price = 90;
                    }
                    break;
                case "model":
                    product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
                    optionIndex = -1;
                    if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', product.items[itemIndex].configuration.model.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.model.selected.value = {};
                        product.items[itemIndex].configuration.model.selected.dropdownValue = "";
                    }
                    break;
                case "drop":
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    if (condition1 === "Quiklok") {
                        product.items[itemIndex].configuration.drop.selected.isSelectable = true;
                    }
                    else {
                        product.items[itemIndex].configuration.drop.selected.isSelectable = false;
                        product.items[itemIndex].configuration.drop.selected.value = product.items[itemIndex].configuration.drop.max;//2400
                    }
                    break;
                case "mount":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.mount.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.mount.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', product.items[itemIndex].configuration.mount.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.mount.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.mount.selected.value = {};
                        product.items[itemIndex].configuration.mount.selected.dropdownValue = "";
                    }
                    break;
                case "colour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.colour.finalOptions = product.items[itemIndex].configuration.colour.options.filter(o =>
                        (o.condition1 ? o.condition1.includes(condition1) : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.colour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.colour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.colour.finalOptions, 'optionKey', product.items[itemIndex].configuration.colour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.colour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.colour.selected.value = product.items[itemIndex].configuration.colour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.colour.selected.dropdownValue = product.items[itemIndex].configuration.colour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.colour.selected.value = {};
                        product.items[itemIndex].configuration.colour.selected.dropdownValue = "";
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = quiklokUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("width", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                product = quiklokUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("chainLength", product, itemIndex, {
                    ...order,
                    isPreservePreviousSelectionRequired: false
                });
              //  product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                product = quiklokUtil.updateFormError("drop", product, itemIndex);
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                }
                product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("mount", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("colour", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
                product = quiklokUtil.updateFormError("drop", product, itemIndex);
                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                  //  product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);
                }
                break;
            case "colour":
                optionIndex = findIndex(product.items[itemIndex].configuration.colour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.colour.selected.value = product.items[itemIndex].configuration.colour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.colour.selected.dropdownValue = product.items[itemIndex].configuration.colour.selected.value.optionKey;
                }
                product = this.updateItemConfigurationOptions("basePrice", product, itemIndex, order);
            default:
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = quiklokUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let {workingBOMModal, isOpenConsolidatedBOMModal} = this.state;
        let {product, order, currentUser} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{minWidth: 45}}>
                                        #
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Quantity
                                    </th> <th className="text-center" style={{minWidth: 100}}>
                                        Model
                                    </th>
                                    <th className="text-center" style={{ minWidth: 100 }}>
                                        Colour
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        <div>Width(mm)</div>
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        <div>Drop(mm)</div>
                                    </th>
                                    <th className="text-center" style={{minWidth: 175}}>
                                        Mount
                                    </th>
                                    <th className="text-center" style={{minWidth: 50}}>
                                        Unit Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 50}}>
                                        Price
                                    </th>
                                    <th className="text-center" style={{minWidth: 120}}>
                                        Action
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 90}}>
                                                <Button color={"link"}
                                                        onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                            </th>
                                            : null
                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    <span>{itemIndex + 1}</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="number" name="quantity"
                                                           invalid={!item.configuration.quantity.formError.isValid}
                                                           value={item.configuration.quantity.selected.value}
                                                           min={item.configuration.quantity.min}
                                                           max={item.configuration.quantity.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="quantity"/>

                                                    {
                                                        item.configuration.quantity.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.quantity.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="model"
                                                           value={item.configuration.model.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "model", itemIndex, true)}>
                                                        <option value={""} disabled={true}>Select</option>
                                                        {
                                                            (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {(item.configuration.colour.finalOptions || []).length > 1 ?
                                                        <div>
                                                            <Input type="select" name="colour"
                                                                value={item.configuration.colour.selected.dropdownValue}
                                                                onChange={(e) => this.handleChange(e.target.value, "colour", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>

                                                                {
                                                                    (item.configuration.colour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div> :
                                                        <div>N/A</div>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="number" name="width"
                                                           invalid={!item.configuration.width.formError.isValid}
                                                           value={item.configuration.width.selected.value}
                                                           min={item.configuration.width.min}
                                                           max={item.configuration.width.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="width"/>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div>
                                                        <Input type="number" name="drop"
                                                            invalid={!item.configuration.drop.formError.isValid}
                                                            value={item.configuration.drop.selected.value}
                                                            min={item.configuration.drop.min}
                                                            max={item.configuration.drop.max}
                                                            onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                            onFocus={(event) => event.target.select()}
                                                            disabled={!item.configuration.drop.selected.isSelectable}
                                                            placeholder="drop" />
                                                        {
                                                            item.configuration.drop.formError.isValid
                                                                ? null
                                                                : <Badge color={"danger"}>
                                                                    {item.configuration.drop.formError.message}
                                                                </Badge>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {(item.configuration.mount.finalOptions || []).length>1 ?
                                                        <div>
                                                            <Input type="select" name="mount"
                                                                value={item.configuration.mount.selected.dropdownValue}
                                                                onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>

                                                                {
                                                                    (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div> :
                                                        <div>N/A</div>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <NumberFormat
                                                        prefix={'$'}
                                                        value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}/>
                                                </td>

                                                <td className="align-middle text-center">
                                                    <Button color={"link"}
                                                            title={"click here to copy item"}
                                                            onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                        <i className="fa fa-clone fa-lg"
                                                           aria-hidden="true"/>
                                                    </Button>
                                                    <span className={"text-muted"}>|</span>
                                                    <Button color={"link"}
                                                            title={"click here to delete"}
                                                            onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                        <i className="fa fa-trash-o fa-lg text-danger"
                                                           aria-hidden="true"/>
                                                    </Button>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle text-center">
                                                            <Button color={"link"}
                                                                    title={"click here to open BOM"}
                                                                    onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                BOM
                                                            </Button>
                                                        </td>
                                                        : null

                                                }

                                            </tr>
                                        }
                                    )
                                }
                                {
                                    items.length > 0
                                        ? <tr>
                                            <td className="align-middle" colSpan={8}>Total price</td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center" colSpan={2}/>
                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <QuiklokBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <QuiklokConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        :
                        null

                    }

                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                </div>
            </div>
        )
    }
};
