import React, {Component} from "react";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

export default class ViewDebtorNoteModal extends Component {

    render() {
        let {isOpen, toggle, selected} = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={(e) => toggle('', !isOpen)}>
                <ModalHeader toggle={(e) => toggle('', !isOpen)}>
                    Notes
                </ModalHeader>
                <ModalBody>
                    <div className="mb-2">
                        <Input
                            className="form-control"
                            type="textarea"
                            name="note" readOnly
                            value={selected}>
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} onClick={(e) => toggle('', !isOpen)}>Close</Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}