import React, {Component} from 'react';
import {
    Col, Row, Table,
   Input, InputGroup, InputGroupAddon, InputGroupText, Spinner, Badge,
    ButtonGroup,
    Label,
    Button
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import OrderService from '../../services/OrderService';
import * as FileSaver from 'file-saver';
import cloneDeep from 'lodash/cloneDeep'
import {
    handleErrorMessage,
    changeFormatOfDateString,
    getDateString
} from '../../services/CommonService';
import moment from 'moment';
import queryString from 'query-string';
import {Link as Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import OrderInformationModal from '../../components/OrderGatewayComponents/OrderInformationModal';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {maxDate, minDate} from '../../components/OrderGatewayComponents/OrderGatewayCommon';

const date = new Date();
export default class ReworkOrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reworkOrderData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ordDate",
                        direction: false
                    },
                    filterRequest: {
                        accountID: "",
                        startDate: moment(date).subtract(90, 'day').toDate(),
                        endDate: date,
                        salesOrderJobStatusId: null,
                        reworkOrdNum: '',
                        originalOrdNum: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            selectedStockAttribute: {},
            isOpenModal: false,
            loading: false,
            searchText: '',
            selectedData: {},
            downloading: false,
            loadingExport :false,
            allProductOption: {products: [], productsByID: {}},
            warrantyClaimReasons: {reasons: [], reasonByID: {}},
            highlightedOrder: {},
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            selectedOrder: {}
        };
        this.orderService = new OrderService();
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.getReworkOrderDetail = this.getReworkOrderDetail.bind(this);
        this.openJobInfoModal = this.openJobInfoModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.toggleJobInfoModal = this.toggleJobInfoModal.bind(this);
        this.exportData = this.exportData.bind(this);
    }

    componentDidMount() {
        this.getSalesOrderJobStatuses();
        this.getReworkOrderDetail(this.state.reworkOrderData);
    }

    getReworkOrderDetail(reworkOrderData) {
        let {highlightedOrder} = this.state;
        this.setState({loading: true});
        let request = cloneDeep(reworkOrderData.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.orderService.getReworkOrderList(request).then(response => {
            reworkOrderData.response = response.data;
            this.setState({reworkOrderData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let {salesOrderJobStatuses} = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({salesOrderJobStatuses})
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    exportData() {
        let { reworkOrderData } = this.state;
        let request = cloneDeep(reworkOrderData.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.setState({ loadingExport: true });
        this.orderService.exportReworkReport(request).then(response => {
            this.setState({ loadingExport: false });
            FileSaver.saveAs(response.data, "Rework Reports" + ".xlsx");
        }).catch(error => {
            this.setState({ loadingExport: false });
            toast.error(handleErrorMessage(error));
        });
    };

    getStore({filterRequest}) {
        return [
            {
                key: "ordNum",
                label: "Order No",
                type: "link",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <ButtonGroup>
                        <Link
                            className={"btn btn-sm btn-primary"}
                            style={{color: "white"}}
                            title={"Click here to view order in detail"}
                            to={"/sales/order/view?" + queryString.stringify({OrdNum: value})}>
                            {value}
                        </Link>
                    </ButtonGroup>
                }
            },
            {
                key: "originalOrdNum",
                label: "Original Order",
                type: "link",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <ButtonGroup>
                        <Link
                            className={"btn btn-sm btn-primary"}
                            style={{ color: "white" }}
                            title={"Click here to view original order in detail"}
                            to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}>
                            {value}
                        </Link>
                    </ButtonGroup>
                }
            },
            {
                key: "ordDate",
                label: "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>
                }
            },
            {
                key: "required",
                label: "Required Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>
                }
            },
            {
                key: "productionReadyDate",
                label: "ECD Date",
                type: "ECD",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>
                }
            },
            {
                key: "salesOrderJobStatusDescription",
                label: "Status",
                type: "salesOrderJobStatusId",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    let color = "info";
                    if (ref.state.salesOrderJobStatuses.statusesById[record.salesOrderJobStatusId]) {
                        if (ref.state.salesOrderJobStatuses.statusesById[record.salesOrderJobStatusId].isOnHold) {
                            color = "warning";
                        }
                        if (ref.state.salesOrderJobStatuses.statusesById[record.salesOrderJobStatusId].isCompleted) {
                            color = "success";
                        }
                    }
                    return <div className={"text-center"}>
                        <Badge color={color} id={`bdg-${index}`} className={"hand-cursor"}
                               onClick={ref.openJobInfoModal.bind(this, record, "status")}
                        >{value}</Badge>
                    </div>;
                }
            },
            {
                key: "accountID",
                label: "Account",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    let className = "";
                    switch (record.debtorCategoryID) {
                        case 1:
                            className = "btn btn-sm btn-warning";
                            break;
                        case 2:
                            className = "btn btn-sm btn-secondary";
                            break;
                        case 3:
                        default:
                            className = "btn btn-sm btn-primary";
                            break;
                    }

                    return <Link
                        className={className}
                        style={{color: "white"}}
                        title={"Click here to see account details"}
                        to={"/customer/#" + value}>{value}</Link>
                }
            },
            {
                key: "delCompany",
                label: "Company Name",
                type: "delCompany",
                colSpan: 1,
                minWidth: 170,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "custOrdNum",
                label: "Cust. Ord Ref.",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "orderDetails",
                label: "Stock Group(s)",
                type: "productDetails",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <a href='javascript:void(0)' key={index}
                              onClick={ref.openJobInfoModal.bind(this, record, "productLink")}>
                        {ref.getProductString(record.orderDetails)}
                    </a>;
                }
            },
            {
                key: "rep",
                label: "Rep",
                type: "delCompany",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "reworkTypeName",
                label: "Rework Reason ",
                type: "reworkTypeName",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "ordTotal",
                label: "Order Value",
                type: "currency",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle text-right",
                labelClassName: "text-left align-middle",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    if (!value || value < 0) {
                        value = 0;
                    }
                    return <span><NumberFormat value={value}
                                               displayType={'text'}
                                               decimalScale={2}
                                               fixedDecimalScale={true}
                                               thousandSeparator={true}
                                               prefix={'$'}/></span>
                }
            },
            {
                key: "discountValue",
                label: "Discount Value",
                type: "currency",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle text-right",
                labelClassName: "text-left align-middle",
                searchNode: null,
                render: function (value, record, data, index, ref) {
                    // Ensure value is not null or undefined
                    value = value || 0;
                    // Convert negative values to positive
                    const renderedValue = Math.abs(value);
                    return (
                        <span>
                            <NumberFormat
                                value={renderedValue}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                prefix={'$'}
                            />
                        </span>
                    );
                }
            }
        ];
    }


    getProductString(orderDetails) {
        let productsString = [];
        orderDetails.map((item) => {
            productsString.push(item.product + " (" + item.qty + ") ");
        });

        return productsString.toString();
    }

    openJobInfoModal(order, clickType) {
        let {reworkOrderData} = this.state;
        this.setState({clickType: clickType});
        if (clickType === "productLink") {
            this.setState({selectedOrder: order, isOpenJobInfoModal: true});
        }
    }

    toggleJobInfoModal(change) {
        this.setState({isOpenJobInfoModal: change});
    }

    handleCloseModal(data) {
        this.setState({isOpenJobInfoModal: data});
    }

    handleChange(change, key) {
        let {reworkOrderData} = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (reworkOrderData.request.sortRequest.key === change) {
                    reworkOrderData.request.sortRequest.direction = !reworkOrderData.request.sortRequest.direction;
                } else {
                    reworkOrderData.request.sortRequest.key = change;
                    reworkOrderData.request.sortRequest.direction = false;
                }
                if (!(change === "srNo")) {
                    this.getReworkOrderDetail(reworkOrderData);
                }
                break;
            case "pageSize":
                reworkOrderData.request.pageRequest[key] = change;
                reworkOrderData.request.pageRequest.currentPage = 1;
                this.getReworkOrderDetail(reworkOrderData);
                break;
            case "currentPage":
                reworkOrderData.request.pageRequest[key] = change;
                this.getReworkOrderDetail(reworkOrderData);
                break;
            case "salesOrderJobStatusId":
                reworkOrderData.request.filterRequest[key] = parseInt(change);
                reworkOrderData.request.pageRequest.currentPage = 1;
                searchParams.set("currentPage", reworkOrderData.request.pageRequest.currentPage);
                if (change) {
                    searchParams.set("salesOrderJobStatusId", parseInt(change));
                }
                else {
                    searchParams.delete('salesOrderJobStatusId');
                }
                this.getReworkOrderDetail(reworkOrderData);
                break;
            default:
                reworkOrderData.request.filterRequest[key] = change;
                reworkOrderData.request.pageRequest.currentPage = 1;
                searchParams.set(key, change);
                searchParams.set("currentPage", reworkOrderData.request.pageRequest.currentPage);
                this.setState({reworkOrderData});
                this.getReworkOrderDetail(reworkOrderData);
        }
    }

    openModal(data) {
        this.setState({selectedData: data, isOpenModal: true});
    }

    toggleModal(data) {
        if (data === 'orderCreated') {
            this.setState({isOpenModal: false});
            this.getReworkOrderDetail(this.state.reworkOrderData);
        }
        else {
            this.setState({isOpenModal: data});
        }
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.ordNum.toString().includes(searchText);

            if (!flag && item.ordTotal) {
                flag = item.ordTotal.toString().includes(searchText)
            }
            if (!flag && item.custOrdNum) {
                flag = item.custOrdNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.ordDate) {
                flag = getDateString(item.ordDate, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    printOrderSummaryInExcel = () => {
        let {reworkOrderData} = this.state;
        let request = cloneDeep(reworkOrderData.request);
        this.setState({downloading: true});
        this.orderService.exportAllReworkOrders(request).then(response => {
            this.setState({downloading: false});
            FileSaver.saveAs(response.data, "REWORK-ORDER-SUMMARY" + ".xlsx");
        }).catch(error => {
            this.setState({downloading: false});
            console.log(error.data)
        });
    };

    render() {
        let {reworkOrderData, loading, searchText, salesOrderJobStatuses, selectedOrder, isOpenJobInfoModal, isOpenModal, downloading, allProductOption, warrantyClaimReasons,loadingExport} = this.state;
        let {pageRequest, sortRequest, filterRequest} = reworkOrderData.request;
        let reworkHeading = this.getStore(reworkOrderData.request);
        return (
            <div>
                <div>
                    <Row>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label>Account</Label>
                            <div style={{zIndex: '2', position: 'relative'}}>
                                <SearchDebtorAcccount
                                    handleAccountChange={(accountID) => this.handleChange(accountID, "accountID")}
                                    selectedAccountID={filterRequest.accountID}
                                    defaultAccountID={filterRequest.accountID}
                                />
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label> <i className="fa fa-calendar" aria-hidden="true"/>&nbsp;Start Date </Label>
                            <div>
                                <DatePicker
                                    className="form-control form-control"
                                    selected={filterRequest.startDate}
                                    onChange={date => this.handleChange(date, "startDate")}
                                    selectsStart
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="Start Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label> <i className="fa fa-calendar" aria-hidden="true"/>&nbsp;End Date</Label>
                            <div className={"text-right"}>
                                <DatePicker
                                    className="form-control form-control"
                                    selected={filterRequest.endDate}
                                    onChange={date => this.handleChange(date, "endDate")}
                                    selectsEnd
                                    startDate={filterRequest.startDate}
                                    endDate={filterRequest.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="End Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label>Status</Label>
                            <div>
                                <Input type={"select"}
                                       name={"salesOrderJobStatusId"}
                                       value={filterRequest.salesOrderJobStatusId ? filterRequest.salesOrderJobStatusId : ""}
                                       onChange={(e) => this.handleChange(e.target.value, "salesOrderJobStatusId")}>
                                    <option value={""}>All</option>
                                    {(salesOrderJobStatuses.statuses || []).filter(x => x.id >= 5).map((option, index) =>
                                        <option key={index} value={option.id}>{option.statusDescription}</option>
                                    )}
                                </Input>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label>Rework Order No</Label>
                            <div className={"text-center"}>
                                <Input type="search"
                                    value={filterRequest.reworkOrdNum || ''}
                                    onChange={(e) => this.handleChange(e.target.value, "reworkOrdNum")}
                                    placeholder="Search">
                                </Input>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <Label>Original Order No</Label>
                            <div className={"text-center"}>
                                <Input type="search"
                                    value={filterRequest.originalOrdNum || ''}
                                    onChange={(e) => this.handleChange(e.target.value, "originalOrdNum")}
                                    placeholder="Search">
                                </Input>
                            </div>
                        </Col>
                    </Row>
                </div>
                <br/>
                <div>
                    {loading ?
                        <span className="text-right ml-3">
                         <Spinner size={"sm"} color={"primary"}/>
                        </span>
                        :
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <div className={"text-left ml-3"}>
                                <p>Showing
                                    {' '} {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {reworkOrderData.response.totalRecords}
                                    {' '}entries</p>
                            </div>
                            <div>
                                <Button
                                    size={"sm"}
                                    color={"success"}
                                    onClick={this.exportData}
                                    disabled={loadingExport}
                                    className={"mr-2"}
                                >
                                    {loadingExport ? (
                                        <Spinner color={"light"} size={"sm"} className={"mr-2"} />
                                    ) : (
                                        <i className={"fa fa-file-excel-o mr-2"} />
                                    )}
                                    Export
                                </Button>
                            </div>
                        </div>

                    }
                    <Table hover bordered size={"sm"} striped responsive>
                        <thead>
                        <tr>
                            {(reworkHeading || []).map((item, index) => {
                                return (
                                    <th key={index}
                                        onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                        colSpan={item.colSpan}
                                        className={item.labelClassName}
                                        style={{minWidth: item.minWidth}}>
                                        {item.label}
                                        {
                                            item.sorterApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                )} aria-hidden="true"/> : null
                                        }
                                    </th>
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>

                        {(reworkOrderData.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, i) => {
                             /*id = { highlightedOrder.ordNum === order.ordNum ? "Order" + highlightedOrder.ordNum : null }*/
                            return (
                                <tr key={i}>
                                    {(reworkHeading || []).map((storeItem, index) => {
                                        return (
                                            <td key={index} className={storeItem.valueClassName}>
                                                {storeItem.render(order[storeItem.key], order, reworkOrderData.response.records, i, this)}
                                            </td>
                                        );
                                    })}

                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </div>
                {!loading ?
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-left"} style={{maxWidth: 200}}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Show</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"select"}
                                        name={"pageSize"}
                                        value={pageRequest.pageSize}
                                        disabled={loading}
                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                        <option value={10}>10 Rows</option>
                                        <option value={25}>25 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>


                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"float-right"}>
                                <Pagination
                                    activePage={pageRequest.currentPage}
                                    itemsCountPerPage={pageRequest.pageSize}
                                    totalItemsCount={reworkOrderData.response.totalRecords}
                                    pageRangeDisplayed={3}
                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'
                                />
                            </div>
                        </Col>
                    </Row>
                    :
                    null
                }

                {selectedOrder ?

                    <OrderInformationModal ordNum={selectedOrder.ordNum}
                        isOpen={isOpenJobInfoModal}
                        toggle={this.toggleJobInfoModal}
                        handlerCloseModal={this.handleCloseModal}
                        clickType={this.state.clickType}
                    />

                    : null}
            </div>
        );
    }
}