export const PowdercoatColorOptions = [
    {label: "White | Dulux Pearl White", value: "White | Dulux Pearl White"},
    {label: "Ivory | Dulux Off White Satin", value: "Ivory | Dulux Off White Satin"},
    {label: "Canvas Cloth | Dulux Zeus Canvas Cloth Matt", value: "Canvas Cloth | Dulux Zeus Canvas Cloth Matt"},
    {label: "Champagne | Dulux Eternity Nickel Pearl", value: "Champagne | Dulux Eternity Nickel Pearl"},
    {label: "Bright Silver | Dulux Bright Silver Satin", value: "Bright Silver | Dulux Bright Silver Satin"},
    {label: "Electric Cow | Metropolis Bronze Pearl", value: "Electric Cow | Metropolis Bronze Pearl"},
    {label: "Pewter | Electro vintage Silver", value: "Pewter | Electro vintage Silver"},
    {label: "Black", value: "Black"},
    {label: "Classic Cream", value: "Classic Cream"},
    {label: "Paperbark", value: "Paperbark"},
    {label: "Pale Eucalypt", value: "Pale Eucalypt"},
    {label: "Woodland Grey", value: "Woodland Grey"},
    {label: "Deep Ocean", value: "Deep Ocean"},
    {label: "Cottage Green", value: "Cottage Green"},
    {label: "Manor Red", value: "Manor Red"},
    {label: "Night Sky", value: "Night Sky"},
    {label: "Surfmist", value: "Surfmist"},
    {label: "Evening Haze", value: "Evening Haze"},
    {label: "Shale Grey", value: "Shale Grey"},
    {label: "Dune", value: "Dune"},
    {label: "Cove", value: "Cove"},
    {label: "Windspray", value: "Windspray"},
    {label: "Gully", value: "Gully"},
    {label: "Mangrove", value: "Mangrove"},
    {label: "Wallaby", value: "Wallaby"},
    {label: "Jasper", value: "Jasper"},
    {label: "Basalt", value: "Basalt"},
    {label: "Ironstone", value: "Ironstone"},
    {label: "Terrain", value: "Terrain"},
    {label: "Monument", value: "Monument"}
]