import React, {Component} from 'react';
import {ButtonGroup, Button} from 'reactstrap';


export default class IsTaskClosedFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filter: this.props.filter};
        this.updateFilter = this.updateFilter.bind(this);
    }


    updateFilter(isClosed) {

        let {filter} = this.state;

        filter.value = isClosed;
        filter.displayValue = (isClosed === "true") ? "Completed" : "Pending";
        this.setState({filter});
        this.props.onChange(filter, false);
    }

    render() {
        let {filter} = this.state;


        return (
            <div>
                <ButtonGroup className={"btn-group-justified"}>
                    <Button
                        color={"primary"}
                        outline={filter.value === "true"}
                        onClick={() => this.updateFilter("false")}>
                        <i className="fa fa-exclamation-circle"
                           aria-hidden="true"/>&nbsp;
                        Pending
                    </Button>
                    <Button
                        color={"success"}
                        outline={filter.value === "false"}
                        onClick={() => this.updateFilter("true")}><i
                        className="fa fa-check" aria-hidden="true"/>&nbsp;
                        Completed
                    </Button>

                </ButtonGroup>
            </div>
        );
    }
}
