import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Button, Card, CardBody, Col, Input, Progress, Row, Spinner, Table, Tooltip} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {handleErrorMessage} from './../../services/CommonService';
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';
import FabricService from '../../services/FabricService';
import queryString from 'query-string';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FileSaver from 'file-saver';
import AttachmentService from '../../services/AttachmentService';


export default class FabricBrochureDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: {
                fabric: false,
                fabricColors: false,
                fabricColorsName: false
            },
            fabricColors: [],
            fabricId: 0,
            fabric: {
                description: '',
                careInstructions: '',
                keyFeatures: '',
                suitableFor: '',
                isDiscontinued: false
            },
            selectedFabricColor: {},
            fabricColorsName: []
        };

        this.attachmentService = new AttachmentService();
        this.onDownloadFabricBrochureAttachment = this.onDownloadFabricBrochureAttachment.bind(this);

    }

    componentDidMount() {
        let { fabricId } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            fabricId = searchParams.fabricId;
            this.setState({ fabricId });
            this.getParticularFabric(fabricId);
            this.getDistinctFabricColor(fabricId);
            this.getFabricColors(searchParams.fabricId);
        } else {
            this.props.history.push('/fabrics/info');
        }
    }

    getDistinctFabricColor(fabricId) {
        let { loading } = this.state;
        loading.fabricColorsName = true;
        this.setState({ loading });
        FabricService.ListDistinctFabricColorByFabric(fabricId).then(response => {
            if (response.data) {
                loading.fabricColorsName = false;
                this.setState({ fabricColorsName: response.data, loading });
            }
        }).catch(error => {
            loading.fabricColorsName = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getParticularFabric(fabricId) {
        let { loading } = this.state;
        loading.fabric = true;
        this.setState({ loading });
        FabricService.GetParticularFabric(fabricId).then(response => {
            if (response.data) {
                loading.fabric = false;
                this.setState({ fabric: response.data, loading });
            }
        }).catch(error => {
            loading.fabric = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getFabricColors(FabricId) {
        if (FabricId !== 0) {
            let { loading } = this.state;
            loading.fabricColors = true;
            this.setState({ loading });
            FabricService.fetchFabricColors(FabricId).then(response => {
                if (response.data) {
                    loading.fabricColors = false;
                    if (response.data.length > 0) {
                        this.setState({ selectedFabricColor: response.data[0], selectedColor: response.data[0].color });
                    }
                    else {
                        this.setState({ selectedFabricColor: {} });
                    }
                    this.setState({ fabricColors: response.data, loading });
                }
            }).catch(error => {
                loading.fabricColors = false;
                this.setState({ loading });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    onDownloadFabricBrochureAttachment() {
        let { fabric } = this.state;
        this.setState({ isDownloading: true });
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    this.setState({ requestProgressPercentage: percent });
                }
            }
        };

        this.setState({ requestProgressPercentage: 0 });
        this.attachmentService.DownloadAttachment(fabric.fabricBrochureAttachment.id, options).then(response => {
            FileSaver.saveAs(response.data, fabric.fabricBrochureAttachment.description);
            this.setState({ isDownloading: false, requestProgressPercentage: 0 });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            this.setState({ isDownloading: false, requestProgressPercentage: 0 });
        });
    }

    onFabricColorChange(selectedFabricColor) {
        this.setState({ selectedFabricColor, selectedColor: selectedFabricColor.color });
    }

    toggle = targetName => {
        if (!this.state[targetName]) {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    };

    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };

    handleChange(change) {
        this.setState({ selectedColor: change });
        let { fabricColors } = this.state;
        for (var i in fabricColors) {
            if (fabricColors[i].color === change) {
                this.setState({ selectedFabricColor: fabricColors[i] });
            }
        }
    }




    render() {
        let baseUrl = window.location.origin;
        let { fabric, loading, fabricColors, isDownloading, requestProgressPercentage, selectedFabricColor, fabricColorsName } = this.state;

        return (
            <div>
                <div className="text-center">
                    <CustomerDashboardQuickLinks size={"sm"} />
                    <hr />
                </div>

                <div className="mt-4">
                    <div className="text-left">
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="text-left">
                                    <Link to={'/fabrics/info'} className="btn btn-sm btn btn-secondary">Back</Link>
                                    <span style={{ color: '#172B4D', fontWeight: '500', fontSize: '1.0rem' }} className="ml-2 mt-2">{fabric.fabricName}</span>
                                </div>
                            </Col>

                        </Row>

                    </div>
                    <br />

                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            {loading.fabricColorsName ? null :
                                fabricColorsName.length > 0 ?
                                    <div className="text-center">
                                        <Row>
                                            <Col xl={2} lg={2} md={4} sm={12} xs={12}><div for="color" style={{ color: '#172B4D', fontWeight: '500' }}>Color</div></Col>
                                            <Col xl={8} lg={8} md={6} sm={12} xs={12}> <Input type={"select"}
                                                name={"color"}
                                                value={this.state.selectedColor}
                                                onChange={(e) => this.handleChange(e.target.value)}>
                                                {fabricColorsName.map((option, index) =>
                                                    <option key={index} value={option}>{option}</option>
                                                )}
                                            </Input>
                                            </Col>
                                        </Row>


                                    </div>
                                    : null}
                            <br />
                            <Row>
                                {selectedFabricColor.fabricColorImageAttachment != null ? <Col className="text-center">
                                    <img
                                        className="img-fluid"
                                        src={baseUrl + "/api/file/Generate/CatalogueFabricColor?FileName=" + selectedFabricColor.fabricColorImageAttachment.fileName}
                                        alt="Card image cap" />
                                </Col> : null}
                            </Row>
                            {loading.fabricColors ? <Spinner color={"primary"} /> :
                                <div className="text-center">
                                    <div className="mt-2 text-center">
                                        {fabricColors.map((fabricColor, index) =>
                                            <span key={index}>
                                                {fabricColor.fabricColorImageAttachment != null ?
                                                    <span>
                                                        < img style={{
                                                            width: '50px',
                                                            height: '50px'
                                                        }}
                                                            id={`img-${index}`}
                                                            className="hoverableItem ml-2 mt-2"
                                                            src={baseUrl + "/api/file/Generate/CatalogueFabricColor?FileName=" + fabricColor.fabricColorImageAttachment.fileName}
                                                            alt="Card image cap"
                                                            onClick={this.onFabricColorChange.bind(this, fabricColor)} />

                                                        <Tooltip placement="right" isOpen={this.isToolTipOpen(`img-${index}`)}
                                                            target={`img-${index}`} toggle={() => this.toggle(`img-${index}`)}>
                                                            {fabricColor.color}
                                                        </Tooltip>
                                                    </span> : null}
                                            </span>


                                        )}
                                    </div>
                                </div>}
                            <hr />
                            <div className="text-center">
                                {fabric.fabricBrochureAttachment != null ?
                                    <Button color='primary' size="sm" outline={true} title="Click here to download fabric brochure" onClick={this.onDownloadFabricBrochureAttachment} disabled={isDownloading}>
                                        {isDownloading
                                            ? <Spinner size="sm"
                                                className={"mr-2"}
                                                color={"primary"} />
                                            : <i className="fa fa-download mr-2" />
                                        } Download brochure </Button>
                                    : null}

                            </div>
                            {requestProgressPercentage > 0
                                ? <Progress striped color="primary" value={requestProgressPercentage}
                                    className={"mt-2"} />
                                : null
                            }

                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <Card>
                                <CardBody>
                                    {loading.fabric ? <Spinner color={"primary"} /> :
                                        <div>

                                            <div>
                                                <div className="desc-area">
                                                    <div for="description" style={{ color: '#172B4D', fontWeight: '500' }}>Description</div>
                                                    <hr />
                                                    <ReactQuill
                                                        value={fabric.description}
                                                        readOnly={true}
                                                        theme={"bubble"}
                                                        className="pl-0"
                                                    />
                                                </div>
                                                <div className="desc-area">
                                                    <div for="keyFeatures" style={{ color: '#172B4D', fontWeight: '500' }}>Key features</div>
                                                    <hr />
                                                    <ReactQuill
                                                        value={fabric.keyFeatures}
                                                        readOnly={true}
                                                        theme={"bubble"}
                                                    />
                                                </div>
                                                <div className="desc-area">
                                                    <div for="specialCare" style={{ color: '#172B4D', fontWeight: '500' }}>Care Instructions</div>
                                                    <hr />
                                                    <ReactQuill
                                                        value={fabric.careInstructions}
                                                        readOnly={true}
                                                        theme={"bubble"}
                                                    />
                                                </div>
                                                <div>
                                                    <div for="composition" style={{ color: '#172B4D', fontWeight: '500' }}>Composition</div>
                                                    <hr />
                                                    <div>{fabric.composition}</div>
                                                </div>
                                                <br />
                                                <br />
                                                <div>
                                                    <div for="technicalSpecifications" style={{ color: '#172B4D', fontWeight: '500' }}>Technical specifications</div>
                                                    <br />
                                                    <Table responsive hover size="sm">
                                                        <tbody>
                                                            <tr>
                                                                <td>Roll width</td>
                                                                <td>{fabric.width}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Roll weight</td>
                                                                <td>{fabric.weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Fabric type</td>
                                                                <td>{fabric.fabricType}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Suitable for</td>
                                                                <td className="desc-area"><ReactQuill
                                                                    value={fabric.suitableFor}
                                                                    readOnly={true}
                                                                    theme={"bubble"}
                                                                /></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>

                                                </div>

                                            </div>
                                        </div>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>

                <ToastContainer />
            </div>
        );

    }
}