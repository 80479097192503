import React, { Component } from 'react';
import { Col, Card, CardGroup, CardHeader, CardBody, Row, CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import CustomerService from "../../services/CustomerService";
import NumberFormat from "react-number-format";


export default class OutstandingAmountAndOrderCounts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            salesOrderCount: 0, quotesOrderCount: 0, totalBalanceAmt: 0.0,
            loadingAmount: false, loadingOrder: false, loadingQuotes: false
        };
        this.customerService = new CustomerService();
    }

    componentDidMount() {
        this.getOutstandingOrderCount();
        this.getOutstandingQuotesCount();
        this.getOutstandingAmount();
    }
    getOutstandingAmount = () => {
        this.setState({ loadingAmount: true })
        this.customerService.getOutstandingTotalAmount().then(balAmt => {
            this.setState({ totalBalanceAmt: balAmt.data, loadingAmount: false })
        }).catch(error => {
            this.setState({ loadingAmount: false });
            console.log(error.data)
        });
    }
    getOutstandingOrderCount = () => {
        this.setState({ loadingOrder: true });
        this.customerService.getOutstandingOrdersCount().then(orderCount => {
            this.setState({ salesOrderCount: orderCount.data, loadingOrder: false })
        }).catch(error => {
            this.setState({ loadingOrder: false });
            console.log(error.data)
        });
    }
    getOutstandingQuotesCount = () => {
        this.setState({ loadingQuotes: true })
        this.customerService.getOutstandingQuotesCount().then(quotesCount => {
            this.setState({ quotesOrderCount: quotesCount.data, loadingQuotes: false })
        }).catch(error => {
            this.setState({ loadingQuotes: false });
            console.log(error.data)
        });
    }


    render() {
        const { salesOrderCount, quotesOrderCount, totalBalanceAmt, loadingAmount, loadingOrder, loadingQuotes } = this.state;

        if(loadingAmount || loadingOrder || loadingQuotes){
            return null;
        }
        return (
            <Col xl={4} lg={4} md={6} sm={12} xs={12} className={"p-2"} >
                <Card>
                    <CardHeader>Outstanding</CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <p className="lead"> <small>Balance</small> </p>
                                <p className="lead">{loadingAmount ? <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                                    : <NumberFormat
                                        value={totalBalanceAmt}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        prefix={'$'} />
                                }</p>
                            </Col>
                            <Col>
                                <p className="lead"> <small>Sale Orders</small> </p>
                                <p className="lead">  {loadingOrder ? <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                                    : <NumberFormat
                                        value={salesOrderCount}
                                        displayType={'text'}
                                        thousandSeparator={true} />
                                }
                                </p>
                            </Col>
                            <Col>
                                <p className="lead"><small>Quotes</small></p>
                                <p className="lead">
                                    {loadingQuotes ? <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                                        : <NumberFormat
                                            value={quotesOrderCount}
                                            displayType={'text'}
                                            thousandSeparator={true} />
                                    }
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}