import React, { Component } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Row,
    Col,
    FormText,
    FormGroup,
    Label,ButtonGroup
} from 'reactstrap';
import creditorService from "../../services/purchase/CreditorService";
import { toast, ToastContainer } from 'react-toastify';
import { handleErrorMessage } from '../../services/CommonService';

export default class ManageSupplierOverviewModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountID: props.accountID,
            supplierAreaCodes: props.supplierAreaCodes,
            currencies: props.currencies,
            isLoadingSave: false,
            isLoading: false,
            formErrors: {},
            supplier: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.accountID !== this.state.accountID) {
            this.setState({ accountID: nextProps.accountID }, () => this.refresh());
        }
    }

    refresh() {
        let { accountID } = this.state;
        if (accountID && accountID !== '') {
            this.setState({ isLoading: true });

            creditorService.getCreditor(accountID).then(response => {
                let supplier = response.data;
                    this.setState({ supplier, isLoading: false });
            }).catch(error => {
                this.setState({ isLoading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(value, key) {
        let { supplier } = this.state;
        supplier[key] = value;
        this.setState({ supplier });
    }

    validateForm(supplier) {
        let formErrors = {};
        let valid = true;


        if (!supplier.areaCode) {
            formErrors.areaCode = "Please select area code";
            valid = false;
        }

        if (!supplier.currency) {
            formErrors.currency = "Please select currency";
            valid = false;
        }

        if (!supplier.purchAccGL) {
            formErrors.purchAccGL = "Please enter dissection";
            valid = false;
        }

        if (!supplier.purchCtrlAccGL) {
            formErrors.purchCtrlAccGL = "Please enter control";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.supplier };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ isLoadingSave: true, formErrors: {} });

            creditorService.saveCreditor(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Supplier updated!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.props.refresh();
                    this.setState({ isLoadingSave: false });
                }

            }).catch(error => {
                this.setState({ isLoadingSave: false });
                toast.error(handleErrorMessage(error));
            });
        }
    }


    render() {
        let { isLoadingSave, isLoading, supplier, formErrors, supplierAreaCodes, currencies } = this.state;
        let { isOpen, toggle } = this.props;
        return (
            <Modal isOpen={isOpen}
                size="lg"
                scrollable={false}
                toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Edit Overview
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <Spinner color='primary' size='sm' /> :
                        <div>
                            <div>
                                <FormGroup>
                                    <Label>AreaCode*</Label>
                                    <Input
                                        invalid={formErrors.areaCode}
                                        name="areacode"
                                        type="select"
                                        value={supplier.areaCode}
                                        onChange={(e) => this.handleChange(e.target.value, 'areaCode')}>
                                        <option value="">Select Area Code</option>
                                        {(supplierAreaCodes||[]).map((area, index) =>
                                            <option key={index}
                                                value={area.areaCode}>{area.description}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.areaCode}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Currency*</Label>
                                    <Input
                                        invalid={formErrors.currency}
                                        name="currency"
                                        type="select"
                                        value={supplier.currency}
                                        onChange={(e) => this.handleChange(e.target.value, 'currency')}>
                                        <option value="">Select Currency</option>
                                        {(currencies||[]).map((currency, index) =>
                                            <option key={index} value={currency.currencyType}>{currency.currencyType}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.currency}</FormText>
                                </FormGroup>
                            </div>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <div>
                                        <FormGroup>
                                            <Label>Charges GST</Label>
                                            <div>
                                                <ButtonGroup>
                                                    <Button
                                                        size={"sm"}
                                                        onClick={() => this.handleChange(true, "chargesGST")}
                                                        color={supplier.chargesGST ? "success" : "primary"}

                                                        outline={!supplier.chargesGST}>
                                                        Yes
                                                        {supplier.chargesGST
                                                            ? <i className="fa fa-check ml-2"
                                                                aria-hidden="true" />
                                                            : null
                                                        }</Button>
                                                    <Button
                                                        onClick={() => this.handleChange(false, "chargesGST")}
                                                        color={supplier.chargesGST ? "primary" : "danger"}
                                                        size={"sm"}
                                                        outline={supplier.chargesGST}>
                                                        No
                                                        {supplier.chargesGST
                                                            ? null
                                                            : <i className="fa fa-check ml-2" aria-hidden="true" />
                                                        }</Button>
                                                </ButtonGroup>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <div>
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <div>
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => this.handleChange(false, "inActive")}
                                                        color={supplier.inActive ? "primary" : "success"}
                                                        size={"sm"}
                                                        outline={supplier.inActive}>
                                                        Active
                                                        {supplier.inActive
                                                            ? null
                                                            : <i className="fa fa-check ml-2" aria-hidden="true" />
                                                        }</Button>
                                                    <Button
                                                        size={"sm"}
                                                        onClick={() => this.handleChange(true, "inActive")}
                                                        color={supplier.inActive ? "danger" : "primary"}

                                                        outline={!supplier.inActive}>
                                                        InActive
                                                        {supplier.inActive
                                                            ? <i className="fa fa-check ml-2"
                                                                aria-hidden="true" />
                                                            : null
                                                        }</Button>
                                                </ButtonGroup>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <div>
                                        <FormGroup>
                                            <Label>Category</Label>
                                            <div>
                                                <ButtonGroup>
                                                    <Button
                                                        size={"sm"}
                                                        onClick={() => this.handleChange("Production", "accountClass")}
                                                        color={"primary"}
                                                        outline={supplier.accountClass != 'Production'}>
                                                        Production
                                                        {supplier.accountClass == 'Production'
                                                            ? <i className="fa fa-check ml-2"
                                                                aria-hidden="true" />
                                                            : null
                                                        }</Button>
                                                    <Button
                                                        onClick={() => this.handleChange("Service", "accountClass")}
                                                        size={"sm"}
                                                        color={"primary"}
                                                        outline={supplier.accountClass != 'Service'}>
                                                        Service
                                                        {supplier.accountClass == 'Service'
                                                            ? <i className="fa fa-check ml-2" aria-hidden="true" />
                                                            : null
                                                        }</Button>
                                                </ButtonGroup>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <FormGroup>
                                    <Label>Dissection*</Label>
                                    <Input
                                        invalid={formErrors.purchAccGL}
                                        name="purchAccGL"
                                        type="text"
                                        value={supplier.purchAccGL}
                                        onChange={(e) => this.handleChange(e.target.value, 'purchAccGL')}/>
                                    <FormText color="danger">{formErrors.purchAccGL}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Control*</Label>
                                    <Input
                                        invalid={formErrors.purchCtrlAccGL}
                                        name="purchCtrlAccGL"
                                        type="text"
                                        value={supplier.purchCtrlAccGL}
                                        onChange={(e) => this.handleChange(e.target.value, 'purchCtrlAccGL')} />
                                    <FormText color="danger">{formErrors.purchCtrlAccGL}</FormText>
                                </FormGroup>
                            </div>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button size={"sm"}
                            color='primary'
                            onClick={this.handleSubmit}
                            disabled={isLoadingSave}>
                            {
                                isLoadingSave
                                    ? <Spinner size="sm" className="mr-2" color={"light"} />
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                            }
                            {
                                isLoadingSave
                                    ? "Updating"
                                    : "Update"
                            }
                        </Button>
                        <Button size={"sm"}
                            color={"secondary"}
                            className={"ml-2"}
                            onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}