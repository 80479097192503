import React, { Component } from 'react';
import { Button, Card, CardHeader, Col, Collapse, Label, Row, Table } from "reactstrap";
import NumberFormat from "react-number-format";
import { OrderEnquiryProductionModeOrderItemsProductPartListItems } from "../../../../../store/AppConstants";
import { getSorted, updateQtyUnitFormat, changeFormatOfDateString } from '../../../../../../src/services/CommonService';
import classnames from 'classnames';
import WarehouseLocationDetailsModal from '../../../../../components/modal/WarehouseLocationDetailsModal';
import warehouseService from '../../../../../services/WINOInventory/WarehouseService';
export default class OrderEnquiryProductionModeOrderItemsCustomProductPartList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows,
            order: this.props.order,
            selectedItem: {},
            activeTab: null,
            isOpen: null,
            sortRequest: {
                key: "location",
                direction: false
            },
            isOpenWarehouseLocationDetailsModal: false,
            locationDetails: []
        };
        this.handleSort = this.handleSort.bind(this);
        this.handleClickedItem = this.handleClickedItem.bind(this);
    }

    handleClickedItem(item) {
        if (item.prodCode) {
            warehouseService.getWarehouseLocationDetailsList(item.prodCode)
                .then(response => {
                    this.setState({ locationDetails: response.data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
        this.setState({ selectedItem: item, isOpenWarehouseLocationDetailsModal: true });
    }

    handleChange(change, value) {
        let { order } = this.state;
        for (let productIndex in order.products) {
            for (let partsIndex in order.products[productIndex].customParts) {
                if (order.products[productIndex].customParts[partsIndex].prodCode === change) {
                    order.products[productIndex].customParts[partsIndex].selected = !order.products[productIndex].customParts[partsIndex].selected;
                }
            }
        }
        this.setState({ order });
    }

    toggleCustomProductPartListCollapse(tabName, tabStatus) {
        let { activeTab, order } = this.state;
        activeTab = tabName;
        for (let productIndex in order.products) {
            if (order.products[productIndex].name === tabName) {
                order.products[productIndex].isOpen = tabStatus;
                this.handleSort("location", productIndex, "collapse")
            } else {
                order.products[productIndex].isOpen = !tabStatus;
                this.handleSort("location", productIndex, "collapse")
            }
        }
        this.setState({ activeTab, order });
    }

    handleSort(change, i, key) {
        let { sortRequest, order } = this.state;
        switch (key) {
            case "collapse":
                sortRequest.key = change;
                sortRequest.direction = true;
                break;
            default:
                if (sortRequest.key === change) {
                    sortRequest.direction = !sortRequest.direction;
                }
                else {
                    sortRequest.key = change;
                    sortRequest.direction = false;
                }
        }
        if (order.products.length > 0) {
            order.products[i].customParts = getSorted(order.products[i].customParts, change, sortRequest.direction);
        }
        this.setState({ order, sortRequest });
    }

    render() {
        let { activeParts, bom, partListHeading } = this.props;
        let { sortRequest, activeTab, order, selectedItem, locationDetails, isOpenWarehouseLocationDetailsModal } = this.state;

        return (
            <div>
                {(order.products || []).map((item, itemIndex) => {
                    return (item?.customParts?.length > 0) ?
                        <Card key={itemIndex} className={"mt-1 mb-2"}>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <Button
                                            size={"sm"}
                                            color={"link"}
                                            onClick={() => this.toggleCustomProductPartListCollapse(item.name, !item.isOpen)}
                                        >{item.name + " (CUSTOM)"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            {
                                item.name === activeTab && item.isOpen ?
                                    <Collapse isOpen={item.isOpen}>
                                        <Table bordered responsive size={"sm"} striped={activeParts ? false : true} className={"mb-0"}>
                                            <thead>
                                                <tr>
                                                    {
                                                        !bom && activeParts ?
                                                            <th rowSpan={2} className="align-middle text-center">Pick</th>
                                                            : null
                                                    }
                                                    {(partListHeading || []).map((item, index) => {
                                                        return (
                                                            <th key={index}
                                                                onClick={(item.sorterApplicable && !bom) ? (() => this.handleSort(item.key, itemIndex, "sortKey")) : null}
                                                                rowSpan={item.rowSpan}
                                                                colSpan={item.colSpan}
                                                                className={item.labelClassName}
                                                                style={{ minWidth: item.minWidth }}>
                                                                {item.label}
                                                                {
                                                                    item.sorterApplicable && !bom && activeParts ?
                                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                            "fa-sort": (sortRequest.key !== item.key),
                                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                        }
                                                                        )} aria-hidden="true" /> : null
                                                                }
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (item.customParts || []).filter(item => !OrderEnquiryProductionModeOrderItemsProductPartListItems.includes(item.prodCode)).map((row, rowIndex) => (
                                                        <tr key={rowIndex} bgcolor={(!row.selected && !row.isFromKeyway) ? "lightpink" : (row.selected ? "#C8C8C8" : "white")}>
                                                            {
                                                                !bom && activeParts ?
                                                                    <td>
                                                                        <div className='ml-2'>
                                                                            <Label>
                                                                                <Button color={"link"}
                                                                                    size={"sm"}
                                                                                    onClick={() => this.handleChange(row.prodCode, rowIndex)}>
                                                                                    {
                                                                                        row.selected
                                                                                            ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                                                                aria-hidden="true" />
                                                                                            : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                                                                    }
                                                                                </Button>
                                                                            </Label>
                                                                        </div>
                                                                    </td> : null
                                                            }
                                                            <td className="align-middle">
                                                                <div>
                                                                    <strong>{row.attribute}</strong>
                                                                </div>
                                                                <div>
                                                                    {row.label}
                                                                </div>
                                                            </td>
                                                            <td className="align-middle text-center text-nowrap">
                                                                {row.prodCode ?
                                                                    <a href={"/inventory/stock/enquiry?prodCode=" + row.prodCode} target="_blank" className="btn btn-sm btn-primary" title="Click here to open its details">
                                                                        {row.prodCode}
                                                                    </a>
                                                                    : null}
                                                            </td>
                                                            <td>{row.description}</td>
                                                            <td className="text-center">
                                                                <NumberFormat
                                                                    value={row.totalSwishQtyWithWastage}
                                                                    displayType={'text'}
                                                                    decimalScale={8}
                                                                    fixedDecimalScale={false}
                                                                    thousandSeparator={true}
                                                                    suffix={updateQtyUnitFormat(row.totalSwishQtyWithWastage, row.swishMeasurementUnit)}
                                                                />
                                                            </td>
                                                            <td>
                                                                {row.location ?
                                                                    <Button color={"link"} style={{ textDecoration: "none" }} onClick={() => this.handleClickedItem(row)}>{row.location}</Button>
                                                                    : null}
                                                            </td>
                                                            <td className={"text-center"}>
                                                                <NumberFormat
                                                                    value={row.qoh}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={false}
                                                                    thousandSeparator={true}
                                                                    suffix={updateQtyUnitFormat(row.qoh, row.eachUnitName)}
                                                                />
                                                            </td>
                                                            <td className="align-middle text-center text-nowrap">
                                                                <>
                                                                    {(!row.hasPO && row.free < 0) ?
                                                                        <span className="text-danger"> Free: {row.free} | No PO ETA
                                                                        </span>
                                                                        :
                                                                        <>
                                                                            {row.poNum ?
                                                                                <a href={"/purchase/order/enquiry?ordNum=" + row.poNum} target="_blank" className="btn btn-sm btn-primary" title="Click here to open this PO">
                                                                                    {row.poNum}
                                                                                </a>
                                                                                : null}
                                                                            {row.etaDate ?
                                                                                <span className="ml-1">{changeFormatOfDateString(row.etaDate, "YYYY-MM-DD hh:mm:ss", "DD MMM YYYY")}</span>
                                                                                : null}
                                                                        </>}
                                                                </>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Collapse>
                                    : null
                            }
                        </Card>
                        : null
                })}
                <WarehouseLocationDetailsModal
                    isOpen={isOpenWarehouseLocationDetailsModal}
                    toggle={() => this.setState({ isOpenWarehouseLocationDetailsModal: !isOpenWarehouseLocationDetailsModal })}
                    handleCancel={() => this.setState({ isOpenWarehouseLocationDetailsModal: false })}
                    selectedItem={selectedItem}
                    locationDetails={locationDetails}
                />
            </div>
        )
    }
}