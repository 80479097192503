import chroma from 'chroma-js';
import {validateEmail} from "../services/CommonService";

export const colourStyles = {
    control: styles => ({...styles, backgroundColor: 'white'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        if ((!data.value) || (data.value && !validateEmail(data.value))) {
            const color = chroma("#dc3545");
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                    !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        } else {
            return {
                ...styles
            }
        }
    },
    multiValue: (styles, {data}) => {

        if ((!data.value) || (data.value && !validateEmail(data.value))) {
            const color = chroma("#dc3545").alpha(0.1).css();
            return {
                ...styles,
                backgroundColor: color,
            };
        } else {
            return {
                ...styles
            }
        }
    },
    multiValueLabel: (styles, {data}) => {
        if ((!data.value) || (data.value && !validateEmail(data.value))) {
            const color = "#dc3545";
            return {
                ...styles,
                color: color,
            }
        } else {
            return {
                ...styles
            }
        }
    },
    multiValueRemove: (styles, {data}) => {

        if ((!data.value) || (data.value && !validateEmail(data.value))) {
            const color = "#dc3545";
            return {
                ...styles,
                color: color,
                ':hover': {
                    backgroundColor: color,
                    color: 'white',
                },
            }
        } else {
            return {
                ...styles
            }
        }
    },
};