import React, {Component} from 'react';
import {Card, CardHeader, Table} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import CustomerService from "../../../services/CustomerService";
import {groupBy, getFinancialMonthList, getFinancialYear} from "../../../services/CommonService";
import FinancialYearDropDown from '../../FinancialYearDropDown';
import moment from 'moment';

export default class PurchasedProductsByMonth extends Component {

    constructor(props) {
        super(props);
        let currentFY = getFinancialYear(moment(new Date()));
        this.state = {
            accountID: this.props.accountID,
            productMonthlyData: [],
            monthList: [],
            selectedYear: currentFY,
            isLoading: false,
            includeChildAccount: this.props.includeChildAccount ? this.props.includeChildAccount : false
        };
        this.customerService = new CustomerService();
        this.getMonthlyPurchasedProductsData = this.getMonthlyPurchasedProductsData.bind(this);
        this.yearChange = this.yearChange.bind(this);
    };


    componentDidMount() {
        if (this.state.accountID && this.state.selectedYear) {
            let monthList = getFinancialMonthList(this.state.selectedYear);
            this.setState({monthList}, () => {
                this.getMonthlyPurchasedProductsData();
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.includeChildAccount !== nextProps.includeChildAccount) {
            this.setState({accountID: nextProps.accountID, includeChildAccount: nextProps.includeChildAccount}, () => {
                this.getMonthlyPurchasedProductsData();
            });
        }
    }


    getMonthlyPurchasedProductsData() {
        let productMonthlyData = [];
        this.setState({isLoading: true});
        this.customerService.CustomerMonthlyPurchasedProducts(this.state.accountID, this.state.selectedYear, this.state.includeChildAccount).then(response => {
            if (response.data) {
                let productWiseData = groupBy(response.data, 'shortName');
                for (let key in productWiseData) {
                    productMonthlyData.push({'productName': key, 'data': productWiseData[key]})
                }
                this.setState({productMonthlyData, isLoading: false});
            }
        }).catch(error => {
            this.setState({isLoading: false});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    yearChange(selectedYear) {
        if (selectedYear && selectedYear !== this.state.selectedYear) {
            let monthList = getFinancialMonthList(selectedYear);
            this.setState({selectedYear: selectedYear, monthList}, () => {
                this.getMonthlyPurchasedProductsData();
            });
        }
    };


    render() {
        let {productMonthlyData, monthList, selectedYear} = this.state;
        let row = {};
        return (
            <div>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Purchased Units</h6>
                    </CardHeader>
                    <Table striped bordered responsive hover size='sm' className={"mb-0"} style={{minHeight: 200}}>
                        <thead>
                        <tr>
                            <th style={{minWidth: 190}}>
                                <FinancialYearDropDown handleChange={this.yearChange}
                                                       defaultSelectedYear={selectedYear}
                                                       selectedYear={selectedYear}
                                />
                            </th>
                            {(monthList || []).map((item, index) => {
                                return <th key={index}
                                           className={"text-center"}>
                                    {item.shortName} {item.year}
                                </th>
                            })}
                            <th className={"text-right"}>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (productMonthlyData || []).map((productData, index) =>
                            <tr key={index}>
                                <th>{productData.productName}</th>
                                {(monthList || []).map((month, index) => {
                                        row = (productData.data || []).find(x => x.monthName === month.fullName);
                                        return <td key={index} className="text-right">
                                            {row ? row.totalUnits : 0}
                                        </td>
                                    }
                                )}
                                <th className="text-right">
                                    {
                                        (productData.data || [])
                                            .map(x => x.totalUnits)
                                            .reduce((prev, curr) => prev + curr, 0)
                                    }
                                </th>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}
