import React, {Component} from 'react';
import {Col, Row, Label, Input, InputGroup, InputGroupAddon, Button} from 'reactstrap';

export default class OrderNoFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filter: this.props.filter};
        this.updateFilter = this.updateFilter.bind(this);
    }

    updateFilter(e) {

        let {filter} = this.state;
        filter.value = e.target.value;
        filter.displayValue = filter.value;

        this.setState({filter}, () => {
            this.props.onChange(filter, false);
        });
    }


    render() {
        let orderNo = this.state.filter.value;

        return (
            <div>
                <Row className={"pb-3"}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Label>Search by {this.state.filter.displayName}</Label>
                        <Input type="number" className="form-control" name="orderNo"
                               value={orderNo}
                               placeholder={"Write " + this.state.filter.displayName + " here."}
                               onChange={this.updateFilter} autoComplete="off"/>
                    </Col>
                </Row>
            </div>
        );
    }
}
