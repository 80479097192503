import React, {Component} from 'react';
import {Badge, Card, CardBody, Col, Spinner, Table, Button} from 'reactstrap';
import {
    displayECD,
    changeFormatOfDateString,
    handleErrorMessage
} from './../../services/CommonService';
import {Link} from "react-router-dom";
import UserService from './../../services/UserService';
import SalesOrderService from './../../services/sales/SalesOrderService';
import SalesQuoteService from './../../services/sales/SalesQuoteService';
import OrderService from './../../services/OrderService';
import CustomerService from './../../services/CustomerService';
import repService from './../../services/RepService';
import CustomerDashboardQuickLinks from './CustomerDashboard/CustomerDashboardQuickLinks';
import queryString from 'query-string';
import {toast, ToastContainer} from 'react-toastify';
import SalesCustomerOrderService from '../../services/sales/SalesCustomerOrderService';
import SalesOrderItemProductDetailModal from '../modal/SalesOrderItemProductDetailModal';
import CustomerCurrentOrder from '../debtor/CustomerCurrentOrder';

export default class CustomerDashboard extends Component {

    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.orderService = new OrderService();
        this.SalesOrderService = new SalesOrderService();
        this.SalesQuoteService = new SalesQuoteService();
        this.state = {
            loading: {
                roles: false,
                salesRep: false,
                salesRepList: false,
            },
            user: {},
            roles: [],
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            LastTenRecentlySubmittedOrders: [],
            LastTenUnsubmittedQuotes: [],
            loadingRecentlySubmittedOrders: false,
            loadingUnsubmittedQuotes: false,
            accountID: "",
            debtor: {},
            salesRep: {},
            selectedOrder: {},
            isOpenProductGroupModal: false,
            loadingAmount: false,
            isOpenChangeSalesRep: false,
            ordersRequest: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 10
                    },
                    sortRequest: {
                        key: "ordNum",
                        direction: false
                    },
                    filterRequest: {
                        accountID: "",

                    },
                },
            },
        };

        this.refresh = this.refresh.bind(this);
        this.GetLastTenUnsubmittedQuotes = this.GetLastTenUnsubmittedQuotes.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.BadgeColors = this.BadgeColors.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.renderStatuswithBadge = this.renderStatuswithBadge.bind(this);
        this.getProductString = this.getProductString.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        let accountID = this.userService.getLoggedInUserAccountID();
        let currentUser = this.userService.getLoggedInUser();
        let {ordersRequest} = this.state;
        ordersRequest.request.filterRequest.accountID = accountID;
        this.setState({accountID, ordersRequest, currentUser}, () => {
            this.refresh(accountID);
        });
    }

    refresh(accountID) {
        this.getSalesOrderJobStatuses();
    }

    getData() {
        this.GetLastTenRecentlySubmittedOrders();
        this.GetLastTenUnsubmittedQuotes();
    }

    getReps() {
        let {loading} = this.state;
        loading.salesRepList = true;
        this.setState({loading});
        repService.fetchReps().then(response => {
            loading.salesRepList = false;
            this.setState({salesReps: response.data, loading});
        }).catch(error => {
            loading.salesRepList = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        });
    }

    GetLastTenRecentlySubmittedOrders = () => {
        this.setState({loadingRecentlySubmittedOrders: true});
        let {request} = this.state.ordersRequest;
        SalesCustomerOrderService.getAllCustomerOrders(request, "SUBMITTED", false).then(response => {
            this.setState({
                LastTenRecentlySubmittedOrders: response.data.records,
                loadingRecentlySubmittedOrders: false
            });
        }).catch(error => {
            this.setState({loadingAmount: false, loadingRecentlySubmittedOrders: false});
            console.log(error.data)
        });
    };

    GetLastTenUnsubmittedQuotes() {
        this.setState({loadingUnsubmittedQuotes: true});
        let {request} = this.state.ordersRequest;
        SalesCustomerOrderService.getAllCustomerOrders(request, "QUOTE", false).then(response => {
            this.setState({LastTenUnsubmittedQuotes: response.data.records, loadingUnsubmittedQuotes: false});
        }).catch(error => {
            this.setState({loadingAmount: false, loadingUnsubmittedQuotes: false});
            console.log(error.data)
        });
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let {salesOrderJobStatuses} = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({salesOrderJobStatuses}, () => this.getData());
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    openProductGroupModal(order) {
        this.setState({selectedOrder: order, isOpenProductGroupModal: true});
    }

    toggleModal(change) {
        this.setState({isOpenProductGroupModal: change});
    }

    handleChange(change, key) {
        switch (key) {
            case "salesRep":
                break;
            default:
                this.setState({[key]: change});
                break;
        }
    }

    BadgeColors() {
        return "light";
    }

    renderStatuswithBadge(salesOrderJobStatusId) {
        let color = "light";
        if (this.state.salesOrderJobStatuses.statusesById[salesOrderJobStatusId]) {
            switch (this.state.salesOrderJobStatuses.statusesById[salesOrderJobStatusId].statusCode) {
                case "SUB":
                    color = "light";
                    break;
                case "REV":
                    color = "info";
                    break;
                case "CAN":
                    color = "danger";
                    break;
                case "PEN":
                    color = "warning";
                    break;
                default :
                    color = "info";
            }
            return <div className={"text-center"}><Badge
                color={color}>{this.state.salesOrderJobStatuses.statusesById[salesOrderJobStatusId].statusDescription}</Badge>
            </div>;
        }
        return null;
    }

    getProductString(orderDetails) {
        let productsString = [];
        orderDetails.map((item) => {
            //productsString.push(item.product);
            productsString.push(item.product + " (" + item.qty + ") ");
        });

        return productsString.toString();
    }

    render() {
        let {
            LastTenRecentlySubmittedOrders, LastTenUnsubmittedQuotes, loadingRecentlySubmittedOrders, loadingUnsubmittedQuotes,
            salesRep, selectedOrder, currentUser
        } = this.state;
        let role = (currentUser && currentUser.menu) ? currentUser.menu.role : null;
        return (<Col>
                <div>
                    <div>
                        <CustomerDashboardQuickLinks size={"lg"}/>
                    </div>
                    <hr/>

                    {/*removed customer phone,email,address and Pie chart*/}

                    <div>
                        {
                            (role !== "ExternalSalesRep") ?
                                <CustomerCurrentOrder
                                    loadingRecentlySubmittedOrders={loadingRecentlySubmittedOrders}
                                    LastTenRecentlySubmittedOrders={LastTenRecentlySubmittedOrders}
                                    openProductGroupModal={this.openProductGroupModal}
                                    renderStatuswithBadge={this.renderStatuswithBadge}
                                    getProductString={this.getProductString}
                                />
                                : null
                        }

                        <Card className={"my-2"}>
                            <CardBody>
                                <div>
                                    <h5>Quotes</h5>
                                    {loadingUnsubmittedQuotes ?
                                        <Spinner color={"primary"}/>
                                        :
                                        <div>
                                            <Table responsive hover size={"sm"} striped bordered>
                                                <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Order No.</th>
                                                    <th>Order Reference</th>
                                                    <th>Products</th>
                                                    <th className={"text-center"}>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(LastTenUnsubmittedQuotes || []).map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{changeFormatOfDateString(item.ordDate, "DD/MM/YYYY", 'DD MMM YYYY')}</td>
                                                        <td>
                                                            <Link
                                                                className="btn btn-sm btn-primary"
                                                                style={{color: "white"}}
                                                                title={"Click here to view order in detail"}
                                                                to={"/sales/order/view?" + queryString.stringify({OrdNum: item.ordNum})}>{item.ordNum}</Link>
                                                        </td>
                                                        <td>{item.custOrdNum}
                                                            {item.isRetail ?
                                                                <Badge color={"danger"}>Retail</Badge> : null}</td>
                                                        <td>
                                                            <Button className="btn btn-sm" color="link"
                                                                    onClick={this.openProductGroupModal.bind(this, item)}>{this.getProductString(item.orderDetails)}
                                                            </Button>
                                                        </td>
                                                        <td className={"text-center"}>{this.renderStatuswithBadge(item.salesOrderJobStatusId)}
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </div>
                            </CardBody>
                        </Card>

                    </div>
                </div>
                <SalesOrderItemProductDetailModal
                    order={selectedOrder}
                    isOpen={this.state.isOpenProductGroupModal}
                    toggle={this.toggleModal}/>
                <ToastContainer/>
            </Col>
        );
    }


}