import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row,  Table, Card, CardHeader, CardBody, CardFooter, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import RepService from '../../services/RepService';
import {handleErrorMessage} from "../../services/CommonService";

export default class SalesRepPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            salesRepData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        repCode: 0,
                        code: '',
                        area_Descrip: '',
                        state: '',
                        name: '',
                        email: '',
                        
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            
        };

        
    }

    componentDidMount() {
        this.getSalesRep(this.state.salesRepData);
    }

    getSalesRep(salesRepData) {
        this.setState({ loading: true });

        RepService.getRepList(salesRepData.request).then(response => {
            salesRepData.response = response.data;
            this.setState({ salesRepData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.code.toLowerCase().includes(searchText);

            if (!flag && item.area_Descrip) {
                flag = item.area_Descrip.toLowerCase().includes(searchText)
            }

            if (!flag && item.state) {
                flag = item.state.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.name) {
                flag = item.name.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.email) {
                flag = item.email.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.repCode) {
                flag = item.repCode.toString().toLowerCase().includes(searchText)
            }


        }
        return flag;
    }

    handleChange(change, key) {
        let { salesRepData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (salesRepData.request.sortRequest.key === change) {
                    salesRepData.request.sortRequest.direction = !salesRepData.request.sortRequest.direction;
                } else {
                    salesRepData.request.sortRequest.key = change;
                    salesRepData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getSalesRep(salesRepData);
                }
                break;
            case "pageSize":
                salesRepData.request.pageRequest[key] = change;
                salesRepData.request.pageRequest.currentPage = 1;
                this.getSalesRep(salesRepData);
                break;
            case "currentPage":
                salesRepData.request.pageRequest[key] = change;
                this.getSalesRep(salesRepData);
                break;


            default:
                salesRepData.request.filterRequest[key] = change;

                if (key === 'repCode') {
                    if (change === "") {
                        salesRepData.request.filterRequest[key] = 0;
                    }
                    else {
                        salesRepData.request.filterRequest[key] = parseInt(change);
                    }
                }
                salesRepData.request.pageRequest.currentPage = 1;
                this.getSalesRep(salesRepData);
                this.setState({ salesRepData });
        }

    }

    getSalesRepHeading({ filterRequest }) {
        let SalesRepHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "code",
                label: "Code",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="code" name="code"
                    value={filterRequest.code || ''}
                    onChange={(e) => this.handleChange(e.target.value, "code")}
                    placeholder="Search..." /></div>

            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="name" name="name"
                    value={filterRequest.name}
                    onChange={(e) => this.handleChange(e.target.value, "name")}
                    placeholder="Search..." /></div>

            },

            {
                key: "email",
                label: "Email",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"email"}
                        value={filterRequest.email || ''}
                        onChange={(e) => this.handleChange(e.target.value, "email")} />
                </div>
            },
            {
                key: "areaDescription",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                            name={"area_Descrip"}
                            value={filterRequest.area_Descrip || ''}
                            onChange={(e) => this.handleChange(e.target.value, "area_Descrip")} />
                  </div>
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                    to={{
                        pathname: '/rep/one', state: { repCode: "" }
                    }}><i className="fa fa-plus" />&nbsp;
                            Add </Link></div>

            }];

        return SalesRepHeading;
    }


    render() {

        let { salesRepData, loading, searchText } = this.state;
        let { pageRequest, sortRequest } = salesRepData.request;
        let salesRepHeading = this.getSalesRepHeading(salesRepData.request);
        return (
            <div>
                
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Reps</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Reps</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {salesRepData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(salesRepHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(salesRepHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(salesRepData.response.records || []).filter((rep) => this.searchFunction(rep, searchText)).map((rep, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>{rep.code}</td>
                                            <td>{rep.name}</td>
                                            <td>{rep.email}</td>
                                            <td>{rep.area_Descrip}</td>
                                            <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                to={"/rep/one?" + queryString.stringify({ repCode: rep.repCode })}>
                                                <i className="fa fa-pencil mr-2"
                                                    aria-hidden="true" />
                                                Edit</Link></td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={salesRepData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
