import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Label, Input, Spinner } from 'reactstrap';
import SalesQuoteService from "../../../services/sales/SalesQuoteService";
import { ToastContainer, toast } from 'react-toastify';
import { findID ,handleErrorMessage} from '../../../services/CommonService';
import "react-datepicker/dist/react-datepicker.css";

export default class CancelQuoteStatusModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            reasonID: 0,
            cancelReasonsOptions: [],
            loadingCancelledQuote: this.props.loadingCancelledQuote,
        };
        this.salesQuoteService = new SalesQuoteService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getCancelReasonsOptions();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCancelledQuote !== nextProps.loadingCancelledQuote) {
            this.setState({ loadingCancelledQuote: nextProps.loadingCancelledQuote });
        }
    }

    getCancelReasonsOptions() {
        this.setState({ loading: true });
        this.salesQuoteService.fetchSalesOrderCancelReasons().then(response => {
            this.setState({ cancelReasonsOptions: response.data, loading: false });
        }).catch(error => {
            console.log(error.response);
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(event) {
        //let { cancelReasonsOptions } = this.state;
        const target = event.target;
        const value = target.value;
        //let index = findID(cancelReasonsOptions, "description", value, "reasonID");
        this.setState({ reasonID: value });
    }


    handleSubmit() {
        if (!this.state.reasonID) {
            toast.info("Please select a cancellation reason.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        this.props.handleChange(this.state.reasonID);
    }

    render() {

        return (
            <Modal isOpen={this.props.isOpen} size="md" scrollable={false} toggle={this.props.toggleModel}>
                <ModalHeader toggle={this.props.toggleModel}>
                    Update Quote status
                </ModalHeader>
                <ModalBody>
                    <div className="mt-2 mb-2">
                        <Label>Please select a reason for Cancelling the Quote</Label>
                        <Input
                            className="form-control"
                            onChange={this.handleChange}
                            type="select"
                            name="reasonID"
                            value={this.state.reasonID ? this.state.reasonID : ""}
                            placeholder="Select Cancel Reason">
                            {
                                /*{this.state.warehouseStatusOptions.map((option, index) =>
                                    <option key={index} value={option}>{option}</option>
                                )}*/
                            }
                            <option value={""}>Select</option>
                            {this.state.cancelReasonsOptions.map((option, index) =>
                                <option key={index} value={option.reasonID}>{option.description}</option>
                            )}
                        </Input>
                    </div>
                    <div className="text-right">
                        <Button color={"primary"} onClick={this.handleSubmit}
                            disabled={this.state.loadingCancelledQuote}>
                            {this.state.loadingCancelledQuote ? <Spinner size="sm"
                                className={"mr-2"}
                                style={{ color: "white" }} /> : null}
                            {this.state.loadingCancelledQuote ? "Saving" : "Save"}</Button>&nbsp;&nbsp;
                        <Button color={"secondary"} onClick={this.props.toggleModel}>Cancel</Button>
                    </div>
                </ModalBody>
                <ToastContainer />
            </Modal>
        )
    }
}