import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import axiosFileUploader from './axiosFileUploader';

export default class KeywayNonStockService {

    listAllNonStocks(req) {
        return axios.post('api/Keyway/nonStock/list', req);
    }

    generateKeywayNonStockImportFormat() {
        return axiosFileDownloader.get('api/Keyway/nonStock/template/export');
    }

    importKeywayNonStockData(request) {
        return axiosFileUploader.post('api/Keyway/nonStock/import', request, {
            timeout: 600000
        });
    }

    generateExcelReport(request) {
        return axiosFileDownloader.post('api/Keyway/nonStock/generate/summary/excel', request);
    }
}