import React, {Component} from "react";
import {toast} from "react-toastify";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import {handleErrorMessage} from "../../services/CommonService";
import powdercoatingService from "../../services/PowdercoatingService";
import PurchaseOrderService from '../../services/WINOInventory/PurchaseOrderService';

export default class ManagePowdercoatingPurchaseOrderStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedPurchaseOrderNum: this.props.selectedPurchaseOrderNum ? this.props.selectedPurchaseOrderNum:'',
            selectedPurchaseStatusID: this.props.selectedPurchaseStatus!=='' ? this.props.selectedPurchaseStatus:'',
            purchaseOrderStatus:[]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getPurchaseOrderStatus = this.getPurchaseOrderStatus.bind(this);
    }

    componentDidMount() {
        this.getPurchaseOrderStatus();
    }

    componentWillReceiveProps(nextProps) {
        let { selectedPurchaseOrderNum, selectedPurchaseStatusID } = this.state;
        if (selectedPurchaseOrderNum !== nextProps.selectedPurchaseOrderNum || selectedPurchaseStatusID !== nextProps.selectedPurchaseStatus) {
            this.setState({ selectedPurchaseOrderNum: nextProps.selectedPurchaseOrderNum, selectedPurchaseStatusID: nextProps.selectedPurchaseStatus });
        }
    }

    getPurchaseOrderStatus() {
        PurchaseOrderService.listPurchaseOrderStatus().then(response => {
            this.setState({ purchaseOrderStatus: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }

    handleChange(change) {
        this.setState({ selectedPurchaseStatusID: parseInt(change) });
    }

    handleSubmit() {
        let { selectedPurchaseOrderNum, selectedPurchaseStatusID} = this.state;
        this.setState({ isLoading: true });
        powdercoatingService.updatePowdercoatingPurchaseOrderStatus(selectedPurchaseOrderNum, selectedPurchaseStatusID).then(response => {
            toast.success("Status Updated!");
            this.props.toggle(false);
            this.props.refreshAgain();
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { isLoading, selectedPurchaseOrderNum, selectedPurchaseStatusID, purchaseOrderStatus } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">#{selectedPurchaseOrderNum}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="mt-2 mb-2">
                        <Label>Select Status</Label>
                        <Input type={"select"}
                            name={"statusID"}
                            value={selectedPurchaseStatusID!=='' ? selectedPurchaseStatusID : ""}
                            onChange={(e) => this.handleChange(e.target.value)}>
                            <option value={""} disabled>Select Status</option>
                            {(purchaseOrderStatus || []).map((option, index) =>
                                <option key={index} value={option.id}>{option.description}</option>
                            )}
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"} size={"sm"} onClick={this.handleSubmit}
                            disabled={isLoading}>
                            {isLoading ?
                                <Spinner size="sm" className={"mr-2"} style={{ color: "white" }} /> :
                                <i className="fa fa-floppy-o mr-2" aria-hidden="true" />}
                            {isLoading ? "Updating" : "Update"} Status</Button>&nbsp;&nbsp;
                        <Button color={"secondary"}
                            size={"sm"} onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}