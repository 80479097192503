import React, {Component} from 'react';
import {
    Badge, Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Spinner,
    Table, UncontrolledButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem
} from 'reactstrap';
import moment from 'moment'
import {Link} from "react-router-dom";
import {changeFormatOfDateString, getDateString, handleErrorMessage} from '../../../services/CommonService';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import {toast, ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import SalesOrderService from '../../../services/sales/SalesOrderService';
import * as FileSaver from 'file-saver';
import OrderService from '../../../services/OrderService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchDebtorAcccount from "../../search/SearchDebtorAcccount";
import NumberFormat from "react-number-format";
import cloneDeep from 'lodash/cloneDeep';
import StockQtyFormat from "../../stock/StockQtyFormat";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../../store/AppConstants";

export const maxDate = new Date();
export const minDate = moment().subtract(1, 'years').toDate();

export default class SalesOrderSwish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTable: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ordNum",
                        direction: false
                    },
                    filterRequest: {
                        ordNum: null,
                        startDate: minDate,
                        endDate: maxDate,
                        accountID: "",
                        salesOrderJobStatusID: "",
                        outstanding: true,
                        cancelled: false,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            prodCode: this.props.prodCode,
            statuses: [],
            downloading: false
        };
        this.salesOrderService = new SalesOrderService();
        this.orderService = new OrderService();
        this.handleChange = this.handleChange.bind(this);
        this.getSalesOrderJobStatus = this.getSalesOrderJobStatus.bind(this);
        this.exportSalesOrder = this.exportSalesOrder.bind(this);
    }

    componentDidMount() {
        this.getDebtorInvoiceItemData(this.state.dataTable, this.state.prodCode);
        this.getSalesOrderJobStatus();
    }

    componentWillReceiveProps(nextprops) {
        if (this.state.prodCode !== nextprops.prodCode) {
            this.setState({prodCode: nextprops.prodCode}, function () {
                this.getDebtorInvoiceItemData(this.state.dataTable, this.state.prodCode);
            });
        }
    }

    getDebtorInvoiceItemData(dataTable, prodCode) {
        this.setState({loading: true});
        if (prodCode) {
            let request = cloneDeep(dataTable.request);
            if (request.filterRequest.startDate) {
                request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
            }
            if (request.filterRequest.endDate) {
                request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
            }
            request.filterRequest.prodCode = prodCode;

            this.orderService.getDebtorInvoiceItems(request).then(response => {
                dataTable.response = response.data;
                this.setState({dataTable, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }

    }

    getStore({filterRequest}) {
        let store = [
            {
                key: "dateCreated",
                label: "Order Date.",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "DD/MM/YYYY", 'DD  MMM YYYY')}</span>;
                }
            },
            {
                key: "ordNum",
                label: "Order No.",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <Link
                        to={"/sales/order/view?" + queryString.stringify({OrdNum: value})}
                        className="btn btn-sm btn-primary"
                        title={"Click Here to see the Order Details"}
                        style={{cursor: "pointer"}}>
                        {value}
                    </Link>;
                }
            },
            {
                key: "accountID",
                label: "AccountID",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return  <Link
                        className={"btn btn-sm btn-primary"}
                        title={"Click here to see account details"}
                        to={"/customer/#" + value}
                    >{value}</Link>;
                }

            }, {
                key: "company",
                label: "Company",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <span> {value}</span>;
                }

            },
            {
                key: "price",
                label: "Price",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <NumberFormat value={value}
                                         displayType={'text'}
                                         decimalScale={2}
                                         thousandSeparator={true}
                                         fixedDecimalScale={true}
                                         prefix={'$'}/>;
                }

            }, {
                key: "qty",
                label: "Qty",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    let eachUnitName = ((record.stock && record.stock.keywayStock && record.stock.keywayStock.eachUnitName) ? record.stock.keywayStock.eachUnitName : "NA");
                    let whUnitName = ((record.stock && record.stock.keywayStock && record.stock.keywayStock.whUnitName) ? record.stock.keywayStock.whUnitName : "NA");
                    let whUnitQty = ((record.stock && record.stock.keywayStock && record.stock.keywayStock.whUnitQty) ? record.stock.keywayStock.whUnitQty : 1);

                    return <StockQtyFormat
                        eachUnitName={eachUnitName}
                        whUnitName={whUnitName}
                        whUnitQty={whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            }, {
                key: "salesOrderJobStatusDescription",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    let colour = "";
                    switch (value){
                        case "Cancelled":
                            colour = "danger";
                            break;
                        default:
                            colour = "primary";
                            break;
                    }
                    return <Badge color={colour}> {value}</Badge>;
                }
            },
        ];

        return store;
    }

    getSalesOrderJobStatus() {
        let {statuses} = this.state;
        this.orderService.getOrderStatuses().then(response => {
            this.setState({statuses: response.data})
        }).catch(error => {
            this.setState({loading: false});
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key) {
        let {dataTable} = this.state;
        switch (key) {
            case "sortKey":
                if (dataTable.request.sortRequest.key === change) {
                    dataTable.request.sortRequest.direction = !dataTable.request.sortRequest.direction;
                } else {
                    dataTable.request.sortRequest.key = change;
                    dataTable.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getDebtorInvoiceItemData(dataTable, this.state.prodCode);
                }
                break;
            case "pageSize":
                dataTable.request.pageRequest[key] = change;
                dataTable.request.pageRequest.currentPage = 1;
                this.getDebtorInvoiceItemData(dataTable, this.state.prodCode);
                break;
            case "currentPage":
                dataTable.request.pageRequest[key] = change;
                this.getDebtorInvoiceItemData(dataTable, this.state.prodCode);
                break;
            default:
                dataTable.request.filterRequest[key] = change;
                switch (key) {
                    case 'ordNum':
                        if (!change) {
                            dataTable.request.filterRequest[key] = null;
                        }
                        break;
                    case 'outstanding':
                        dataTable.request.filterRequest.cancelled = false;
                        break;
                    case 'cancelled':
                        dataTable.request.filterRequest.outstanding = false;
                        break;
                }
                dataTable.request.pageRequest.currentPage = 1;
                this.getDebtorInvoiceItemData(dataTable, this.state.prodCode);
                this.setState({dataTable});
        }

    }

    exportSalesOrder(key) {
        let { dataTable } = this.state;
        let request = cloneDeep(this.state.dataTable.request);
        request.filterRequest = {
            prodCode: this.state.prodCode,
            ordNum: null,
            startDate: "",
            endDate: "",
            accountID: "",
            salesOrderJobStatusID: "",
            outstanding: false,
            cancelled: false
        };
        this.setState({ downloading: true });
        if (key == "All") {
            this.orderService.generateSalesOrderExcelReport(request).then(response => {
                this.setState({ downloading: false });
                FileSaver.saveAs(response.data, "SalesOrderReport" + ".xlsx");
            }).catch(error => {
                this.setState({ downloading: false });
                toast.error(handleErrorMessage(error));
            });
        }
        else if (key == "Filter") {
            dataTable.request.filterRequest.prodCode = this.state.prodCode;
            this.orderService.generateSalesOrderExcelReport(dataTable.request).then(response => {
                this.setState({ downloading: false });
                FileSaver.saveAs(response.data, "SalesOrder" + ".xlsx");
            }).catch(error => {
                this.setState({ downloading: false });
                toast.error(handleErrorMessage(error));
            });
        }
    }

    render() {
        let { dataTable, loading, downloading } = this.state;
        let {sortRequest, pageRequest, filterRequest} = dataTable.request;
        let store = this.getStore(dataTable.request);
        console.log('DataTable Request', dataTable);
        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar mr-2" aria-hidden="true"/>Start Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.startDate}
                                onChange={date => this.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar mr-2" aria-hidden="true"/>End Date</Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.endDate}
                                onChange={date => this.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Order No.</Label>
                        <div className={"text-center"}>
                            <Input type="search" id="ordNum" name="ordNum"
                                    value={filterRequest.ordNum || ''}
                                    onChange={(e) => this.handleChange(e.target.value, "ordNum")}
                                   placeholder="Search...">

                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Status</Label>
                        <div className={"text-center"}>
                            <Input type="select" id="salesOrderJobStatusID" name="salesOrderJobStatusID"
                                   value={filterRequest.salesOrderJobStatusID || ''}
                                   onChange={(e) => this.handleChange(e.target.value, "salesOrderJobStatusID")}
                                   placeholder="Search...">
                                <option value={""}>Select</option>
                                {this.state.statuses.map((option, index) =>
                                    <option key={index} value={option.id}>{option.statusDescription}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Customer</Label>
                        <div style={{zIndex: '2', position: 'relative'}}>
                            <SearchDebtorAcccount
                                handleAccountChange={(selected) => this.handleChange(selected, "accountID")}
                                defaultAccountID={filterRequest.accountID ? filterRequest.accountID : ""}
                                selectedAccountID={filterRequest.accountID ? filterRequest.accountID : ""}
                                includeChildren={true}
                                includeClosed={true}
                                includeInactive={true}
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <div className={"mt-1"}>
                            <Button color={"link"}
                                    size={"sm"}
                                    onClick={() => this.handleChange(!filterRequest.outstanding, "outstanding")}>
                                <i className={classnames("fa", "fa-lg", {
                                        "fa-check-square-o": filterRequest.outstanding,
                                        "fa-square-o": !filterRequest.outstanding,
                                    }
                                )}/>
                            </Button>
                            Outstanding
                        </div>
                        <div className={"mt-1"}>
                            <Button color={"link"}
                                    size={"sm"}
                                    onClick={() => this.handleChange(!filterRequest.cancelled, "cancelled")}>
                                <i className={classnames("fa", "fa-lg", {
                                        "fa-check-square-o": filterRequest.cancelled,
                                        "fa-square-o": !filterRequest.cancelled,
                                    }
                                )}/>
                            </Button>
                            Cancelled
                        </div>
                    </Col>
                </Row>

                <Row className={"mt-3 mb-2"}>
                    <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                        {loading ? <Spinner color='primary' size='sm' /> :
                            <p className={"mb-1"}>Showing{' '}
                                {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                {' '}of {dataTable.response.totalRecords}
                                {' '}entries</p>}
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle caret color={"success"} size='sm' disabled={downloading}
                                    outline={true}>
                                    {downloading
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            style={{ color: "green" }} />
                                        : <i className="fa fa-download mr-2" aria-hidden="true" />}
                                    Export
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem title={'Click here to export all data'}
                                        onClick={() => this.exportSalesOrder("All")}>
                                        <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> All Sales Order
                                    </DropdownItem>
                                    <DropdownItem title={'Click here to export filtered data'}
                                        onClick={() => this.exportSalesOrder("Filter")}>
                                        <i className="fa fa-file-excel-o mr-2" aria-hidden="true" /> Filtered
                                        Sales Order
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </Col>
                </Row>

                 
                <Table striped bordered responsive hover size='sm' className={"mb-1 mt-1"}>
                    <thead>
                    <tr>
                        {(store || []).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}>
                                    {item.label}
                                    {
                                        item.sorterApplicable ?
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (sortRequest.key !== item.key),
                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                }
                                            )} aria-hidden="true"/> : null
                                    }
                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {(dataTable.response.records || []).map((record, i) => {
                        return (
                            <tr key={i}>
                                {(store || []).map((item, index) => {
                                    return (
                                        <td key={index} className={item.valueClassName}>
                                            {item.render(record[item.key], record, i, dataTable.response.records, this)}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {
                        ((dataTable.response.records || []).length === 0)
                            ? <tr>
                                <td colSpan={12}>No orders found...</td>
                            </tr>
                            : null
                    }
                    </tbody>
                </Table>

                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{maxWidth: 200}}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>


                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={dataTable.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination  mb-0'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>
                <ToastContainer/>
            </div>
        );
    }
}