import React, {Component} from 'react';

import {Link as Link} from "react-router-dom";

export default class ComingSoonPage extends Component {

    render() {
        return (
            <div>
                <div className={"center-window"}>
                    <h1 className="display-4">Coming Soon
                        <span>
                            <i className="fa fa-cog fa-spin fa-fw"/>
                            <span className="sr-only">Loading...</span>
                        </span>
                    </h1>
                    <Link className={"btn btn-primary btn-lg"} to={"/"}>Go to Home</Link>
                </div>
            </div>
        );
    }
}