import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    Badge,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    InputGroupText,
    Spinner,
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import currencyService from '../../services/CurrencyService';
import { handleErrorMessage, changeFormatOfDateString } from '../../services/CommonService';
import ManageCurrencyModal from '../../components/modal/ManageCurrencyModal';
export default class GLCurrencyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currencies: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        currencyType: '',
                        description: '',
                        isLocal: false,
                        salesGLAccount: '',
                        bankGLAccount: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            isOpenModal: false,
            selectedCurrency: ''
        };

        this.getCurrencies = this.getCurrencies.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.getCurrencies();
    }

    getCurrencies() {
        let { currencies } = this.state;
        this.setState({ loading: true });
        currencyService.getCurrencies(currencies.request).then(response => {
            currencies.response = response.data;
            this.setState({currencies, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    handleChange(change, key) {
        let { currencies } = this.state;
        switch (key) {
            case "sortKey":
                if (currencies.request.sortRequest.key === change) {
                    currencies.request.sortRequest.direction = !currencies.request.sortRequest.direction;
                } else {
                    currencies.request.sortRequest.key = change;
                    currencies.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.setState({ currencies }, () => {
                        this.getCurrencies()
                    });
                }
                break;
            case "pageSize":
                currencies.request.pageRequest[key] = change;
                currencies.request.pageRequest.currentPage = 1;
                this.setState({ currencies }, () => {
                    this.getCurrencies()
                });
                break;
            case "currentPage":
                currencies.request.pageRequest[key] = change;
                this.setState({ currencies }, () => {
                    this.getCurrencies()
                });
                break;


            default:
                currencies.request.filterRequest[key] = change;
                currencies.request.pageRequest.currentPage = 1;
                this.setState({ currencies }, () => {
                    this.getCurrencies()
                });
        }

    }

    getStore({filterRequest}) {
        let openModal = this.openModal;
        let deleteCurrency = this.deleteCurrency;
        let store = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{recordIndex + 1}</span>
                }
            },
            {
                key: "currencyType",
                label: "Currency",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="currencyType" name="currencyType" value={filterRequest.currencyType}
                                        onChange={(e) => this.handleChange(e.target.value, "currencyType")}
                                        placeholder="Search... "/>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="description" name="description"
                                        value={filterRequest.description}
                                        onChange={(e) => this.handleChange(e.target.value, "description")}
                    placeholder="Search..." /></div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "salesGLAccount",
                label: "Sales GL Account",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="salesGLAccount" name="salesGLAccount" value={filterRequest.salesGLAccount}
                    onChange={(e) => this.handleChange(e.target.value, "salesGLAccount")}
                    placeholder="Search... " /></div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "bankGLAccount",
                label: "Bank GL Account",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="bankGLAccount" name="bankGLAccount" value={filterRequest.bankGLAccount}
                    onChange={(e) => this.handleChange(e.target.value, "bankGLAccount")}
                    placeholder="Search... " /></div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "accountingExchangeRate",
                label: "Currency Rate",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>
                        <NumberFormat value={value}
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            prefix={"$"} />
                    </span>
                }
            },
            {
                key: "accountingRateUpdated",
                label: "Currency Rate Update Date",
                type: 'date',
                colSpan: 1,
                minWidth: 50,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{changeFormatOfDateString(value, 'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY HH:mm')}</span>
                }
            },
            {
                key: "costingExchangeRate",
                label: "Costing Exchange Rate",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>
                        <NumberFormat value={value}
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            prefix={"$"} />
                    </span>
                }
            },
            {
                key: "costingRateUpdated",
                label: "Costing Rate Update Date",
                type: 'date',
                colSpan: 1,
                minWidth: 50,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{changeFormatOfDateString(value, 'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY HH:mm')}</span>
                }
            },
            {
                key: "currencySymbol",
                label: "Currency Symbol",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div className="text-center align-middle">
                    <Button color='primary'
                        size='sm'
                        onClick = {() => openModal('')}
                    >
                        <i className="fa fa-plus mr-2"
                           aria-hidden="true"/>
                        Add</Button>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className="text-center align-middle">
                        <Button color='primary'
                            size='sm'
                            onClick={() => openModal(record)}
                        >
                            <i className="fa fa-pencil"
                                aria-hidden="true" />
                        </Button>
                        <Button color='danger' className='ml-1'
                            size='sm'
                            onClick={() => deleteCurrency(record.currencyType)}
                        >
                            <i className="fa fa-trash"
                                aria-hidden="true" />
                            </Button>
                    </div>
                }

            }
        ];

        return store;
    }

    openModal(currency) {
        this.setState({isOpenModal: true, selectedCurrency: currency});
    }

    deleteCurrency(currencyType) {
        currencyService.deleteCurrency(currencyType).then(response => {
            toast.success("Deleted!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ deleting: false }, () => { this.getCurrencies()});
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleModal(data) {
        this.setState({isOpenModal: data});
    }

    render() {
        let {
            currencies, loading, isOpenModal, selectedCurrency
        } = this.state;
        let store = this.getStore(currencies.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Currency</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Currency</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {currencies.response.totalRecords > currencies.request.pageRequest.pageSize ?
                          <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((currencies.request.pageRequest.currentPage - 1) * currencies.request.pageRequest.pageSize) + 1}
                                            {' '}to {((currencies.request.pageRequest.currentPage) * currencies.request.pageRequest.pageSize)}
                                            {' '}of {currencies.response.totalRecords}
                                            {' '}entries&nbsp;&nbsp;
                                        </p>
                                    }
                                </div>
                            </Col>
                          </Row>
                            : null}
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(store || []).map((column, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={(e) => column.sorterApplicable && this.handleChange(column.key, "sortKey")}
                                                    colSpan={column.colSpan}
                                                    className={column.labelClassName}
                                                    style={{ minWidth: column.minWidth, width: column.width }}>
                                                    {column.label}
                                                    {
                                                        column.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (currencies.request.sortRequest.key !== column.key),
                                                                "fa-sort-amount-asc": (currencies.request.sortRequest.key === column.key && currencies.request.sortRequest.direction),
                                                                "fa-sort-amount-desc": (currencies.request.sortRequest.key === column.key && !currencies.request.sortRequest.direction)
                                                            })} aria-hidden="true" /> : null
                                                    }
                                                </th>
                                            )
                                        })}
                                    </tr>
                                    <tr>

                                        {(store || []).map((column, index) => {
                                            return (
                                                <td key={index} colSpan={column.colSpan}
                                                    className={column.valueClassName}>{column.searchNode}</td>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading
                                            ? <tr>
                                                <td colSpan={(store || []).length}><Spinner color={"primary"} /></td>
                                            </tr>
                                            : (currencies.response.records || []).map((row, rowIndex) =>
                                                <tr key={rowIndex} className={"align-middle"}>
                                                    {(store || []).map((column, columnIndex) => {
                                                        return (
                                                            <td key={columnIndex} className={column.valueClassName}>
                                                                {column.render(row[column.key], row, rowIndex, currencies, this)}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            )}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    {currencies.response.totalRecords > currencies.request.pageRequest.pageSize ?
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={currencies.request.pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>

                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={currencies.request.pageRequest.currentPage}
                                        itemsCountPerPage={currencies.request.pageRequest.pageSize}
                                        totalItemsCount={currencies.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter> 
                    : null}
                </Card>

                {isOpenModal
                    ? <ManageCurrencyModal
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        selectedCurrency={selectedCurrency}
                        refreshAgain={this.getCurrencies} />
                    : null
                }
                <ToastContainer/>
            </div>
        );
    }
}
