import React from 'react';

import {isEmpty, cloneDeep} from 'lodash';
import {
    findItemHavingMaxValue, isBunningsCustomer, isHaymanAndNoosaCustomer,
    round
} from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {
    BUNNINGS_ACCOUNT_ID, HAYMAN_AND_NOOSA_ACCOUNT_ID,
    PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE
} from "../../../../../../store/AppConstants";

class FoldingArmAwningUtil {

    static Instance() {
        return new FoldingArmAwningUtil()
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "fabric":
                if (!product.items[itemIndex].configuration.isFrameOnly.selected.value) {
                    if (isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                        formError = {
                            isValid: false,
                            message: "Fabric range must be selected",
                        };
                    }
                    if (formError.isValid && (isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) && !product.items[itemIndex].configuration.fabricColourOffRange.selected.value)) {
                        formError = {
                            isValid: false,
                            message: isEmpty(product.items[itemIndex].configuration.fabricColour.finalOptions) ? "Fabric off range colour can't be empty" : "Either Colour should be selected from the Colour dropdown list or Off Range Colour should be entered",
                        };
                    }
                }
                product.items[itemIndex].configuration.fabricColourOffRange.formError = formError;
                break;
            case "hoodingPowdercoatColour":
                if (product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Hooding powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.hoodingPowdercoatColour.formError = formError;
                break;
            case "frameColour":
                if (product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.framePowdercoatColour.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Frame powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.framePowdercoatColour.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.fabricColourOffRange.formError.isValid
            && product.items[itemIndex].configuration.hoodingPowdercoatColour.formError.isValid
            && product.items[itemIndex].configuration.framePowdercoatColour.formError.isValid;
    }


    updateRules(key, product, itemIndex, order) {
        let optionIndex, isMotorised, condition1, condition2;
        isMotorised = this.isMotorised(product, itemIndex, order);

        switch (key) {
            case "fabric":
                product.items[itemIndex].configuration.fabricType.selected.isSelectable = !product.items[itemIndex].configuration.isFrameOnly.selected.value;
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = false;
                if (product.items[itemIndex].configuration.type.selected.value.optionKey !== PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE) {
                    product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = true;
                }
                if (!product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable) {
                    product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
                    product.items[itemIndex].configuration.fabricColourOffRange.selected.dropdownValue = "";
                }

                if (product.items[itemIndex].configuration.fabricColourOffRange.selected.value) {
                    product.items[itemIndex].configuration.fabricColour.selected.value = {};
                    product.items[itemIndex].configuration.fabricColour.selected.dropdownValue = "";
                }
                break;
            case "hoodingColour":
                product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                        case "None":
                            product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = false;
                            break;
                        case "Mitjavila Hood (Alum)":
                        case "Windoware Hood (Steel)":
                            product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = true;
                            break;
                    }
                }
                break;
            case "hoodingPowdercoatColour":
                product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value = "";
                    }
                }
                break;
            case "frameColour":
                product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.frameColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.frameColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
                    }
                }
                break;
            case "crankHandle":
                // disable if motorised
                if (isMotorised && ["Monobloc 505", "Semi Cassette", "Full Cassette"].includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                    product.items[itemIndex].configuration.crankHandle.selected.isSelectable = false;
                    product.items[itemIndex].configuration.crankHandle.selected.value = {};
                    product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = "";
                } else {
                    product.items[itemIndex].configuration.crankHandle.selected.isSelectable = true;
                    optionIndex = 0;
                    if (isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                        if (product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex]) {
                            product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.finalOptions[optionIndex];
                            product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;

                        }
                    }
                }
                break;
            case "accessories":
                product.items[itemIndex].configuration.rafterBrackets10Degree.selected.isSelectable = true;
                product.items[itemIndex].configuration.rafterBrackets22Degree.selected.isSelectable = true;
                product.items[itemIndex].configuration.rafterBrackets30Degree.selected.isSelectable = true;
                product.items[itemIndex].configuration.ceilingMountBrackets.selected.isSelectable = "Monobloc 505,Monobloc 520".includes(product.items[itemIndex].configuration.type.selected.value.optionKey);
                product.items[itemIndex].configuration.topFixBrackets.selected.isSelectable = "Semi Cassette,Full Cassette".includes(product.items[itemIndex].configuration.type.selected.value.optionKey);
                product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.isSelectable = "Semi Cassette,Full Cassette".includes(product.items[itemIndex].configuration.type.selected.value.optionKey);

                if (!product.items[itemIndex].configuration.ceilingMountBrackets.selected.isSelectable) {
                    product.items[itemIndex].configuration.ceilingMountBrackets.selected.value = 0;
                }
                if (!product.items[itemIndex].configuration.topFixBrackets.selected.isSelectable) {
                    product.items[itemIndex].configuration.topFixBrackets.selected.value = 0;
                }
                if (!product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.isSelectable) {
                    product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value = 0;
                }
                product.items[itemIndex].configuration.accessories.finalOptions = product.items[itemIndex].configuration.accessories.options.filter(o => product.items[itemIndex].configuration[o.key].selected.isSelectable);
                break;

            case "motor-accessories":
                product.items[itemIndex].configuration.remoteChannel15.selected.isSelectable = false;
                product.items[itemIndex].configuration.vibrationSensor.selected.isSelectable = false;
                product.items[itemIndex].configuration.windSensor.selected.isSelectable = false;

                product.items[itemIndex].configuration.remoteChannel15.selected.dropdownValue = "";
                product.items[itemIndex].configuration.vibrationSensor.selected.dropdownValue = "";

                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Windoware A/C Motor":
                        case "Motor RTS AC Cassette Alpha":
                            product.items[itemIndex].configuration.remoteChannel15.selected.isSelectable = true;
                            product.items[itemIndex].configuration.vibrationSensor.selected.isSelectable = true;
                            product.items[itemIndex].configuration.windSensor.selected.isSelectable = true;
                            break;
                        case "Somfy Altus 60 RTS":
                            product.items[itemIndex].configuration.remoteChannel15.selected.isSelectable = true;
                            product.items[itemIndex].configuration.vibrationSensor.selected.isSelectable = true;
                            product.items[itemIndex].configuration.windSensor.selected.isSelectable = true;
                            break;
                        case "Manual":
                        default:
                            product.items[itemIndex].configuration.remoteChannel15.selected.isSelectable = false;
                            product.items[itemIndex].configuration.vibrationSensor.selected.isSelectable = false;
                            product.items[itemIndex].configuration.windSensor.selected.isSelectable = false;
                            break;
                    }
                }

                if (!product.items[itemIndex].configuration.remoteChannel15.selected.isSelectable) {
                    product.items[itemIndex].configuration.remoteChannel15.selected.value = false;
                } else {
                    condition1 = isEmpty(product.items[itemIndex].configuration.type.selected.value) ? "" : product.items[itemIndex].configuration.type.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    optionIndex = product.items[itemIndex].configuration.remoteChannel15.options.findIndex(o => (o.condition1 ? o.condition1.includes(condition1) : true) && (o.condition2 ? o.condition2.includes(condition2) : true));
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.remoteChannel15.selected.dropdownValue = product.items[itemIndex].configuration.remoteChannel15.options[optionIndex].optionLabel;
                    }
                }

                if (!product.items[itemIndex].configuration.vibrationSensor.selected.isSelectable) {
                    product.items[itemIndex].configuration.vibrationSensor.selected.value = false;
                } else {
                    condition1 = isEmpty(product.items[itemIndex].configuration.type.selected.value) ? "" : product.items[itemIndex].configuration.type.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    optionIndex = product.items[itemIndex].configuration.vibrationSensor.options.findIndex(o => (o.condition1 ? o.condition1.includes(condition1) : true) && (o.condition2 ? o.condition2.includes(condition2) : true));
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.vibrationSensor.selected.dropdownValue = product.items[itemIndex].configuration.vibrationSensor.options[optionIndex].optionLabel;
                    }
                }

                if (!product.items[itemIndex].configuration.windSensor.selected.isSelectable) {
                    product.items[itemIndex].configuration.windSensor.selected.value = {};
                    product.items[itemIndex].configuration.windSensor.selected.dropdownValue ="";
                }
                break;
            case "isFrameOnly":
                product.items[itemIndex].configuration.isFrameOnly.selected.isSelectable = false;
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        product.items[itemIndex].configuration.isFrameOnly.selected.isSelectable = true;
                        break;
                    case "Semi Cassette":
                    case "Full Cassette":
                        product.items[itemIndex].configuration.isFrameOnly.selected.isSelectable = false;
                        break;
                }
                if (!product.items[itemIndex].configuration.isFrameOnly.selected.isSelectable) {
                    product.items[itemIndex].configuration.isFrameOnly.selected.value = false;
                }
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let setIndex, stocks, label, attribute,
            width, drop, comment, optionIndex,
            deduction, condition1, condition2, condition3, sets;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = parseInt(product.items[itemIndex].configuration.drop.selected.value.optionKey);

        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: "KW-FOLDARM",
                    price: product.items[itemIndex].configuration.type.selected.value.optionKey === PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE ? 0 : product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];

                optionIndex = product.items[itemIndex].configuration.width.options.findIndex(o => o.optionKey === "bom");
                if (optionIndex > -1) {
                    condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                    condition2 = "default";
                    if (isBunningsCustomer(order.accountID)) {
                        condition2 = BUNNINGS_ACCOUNT_ID;
                    }
                    if (isHaymanAndNoosaCustomer(order.accountID)) {
                        condition2 = HAYMAN_AND_NOOSA_ACCOUNT_ID;
                    }
                    condition3 = product.items[itemIndex].configuration.width.selected.value + "";
                    sets = product.items[itemIndex].configuration.width.options[optionIndex].sets.filter(s =>
                        (
                            s.condition1.includes(condition1)
                            && s.condition2.includes(condition2)
                            && s.setKey === condition3
                        )
                    );
                    (sets || []).forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "operation":
                attribute = "Operation";//Need to change the operation exact value with options.
                comment = "";
                label = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                stocks = [];
                product.items[itemIndex].configuration.operation.selected.value.sets.forEach(s => {
                    if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                        if (drop >= s.min && drop <= s.max && width >= s.secondaryMin && width <= s.secondaryMax) {
                            stocks = [...stocks, ...s.stocks];
                        }
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.operation.selected.stocks = stocks;
                break;
            case "fabricColour":
                if (!product.items[itemIndex].configuration.isFrameOnly.selected.value) {
                    attribute = "Skin";
                    comment = "";
                    label = "";
                    stocks = [{
                        id: null,
                        description: "",
                        prodCode: isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? "09770" : product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                        price: 0,
                        flatPrice: 0,
                        quantityMultiplier: 1,
                        qtyFormulaId: null,
                        productConfigurationOptionId: null,
                        productConfigurationOptionSetId: null,
                        swishQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                        keywayQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                        wastageFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                        calculatedQty: 0,
                        stockPickSlipQty: 0,
                        keywayMeasurementUnit: "mm",
                        swishMeasurementUnit: "mtr",
                        keywayConversionFactor: 1000,
                        swishConversionFactor: 1,
                        isVisibleInPartList: true,
                        isDeductionApplicable: true
                    }];

                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                    product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                }
                break;
            case "hooding":
                attribute = "Hooding";
                label = isEmpty(product.items[itemIndex].configuration.hooding.selected.value) ? "" : product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                    comment = product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    product.items[itemIndex].configuration.hoodingColour.selected.value.sets.forEach(s => {
                        if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                            if (width >= s.min && width <= s.max) {
                                stocks = [...stocks, ...s.stocks];
                            }
                        }
                    });
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.hoodingColour.selected.stocks = stocks;
                break;
            case "frontBar":
                attribute = "Front Bar";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.frontBar.finalOptions.forEach(o => {
                    switch (o.optionKey) {
                        case "Bar":
                            o.sets.forEach(s => {
                                if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                                    if (width >= s.min && width <= s.max) {
                                        stocks = [...stocks, ...s.stocks];
                                    }
                                }
                            });
                            break;
                        case "Clamp":
                            o.sets.forEach(s => {
                                if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                                    if (s.condition2.includes(product.items[itemIndex].configuration.controlSide.selected.value.optionKey)) {
                                        if (width >= s.min && width <= s.max && drop >= s.secondaryMin && drop <= s.secondaryMax) {
                                            stocks = [...stocks, ...s.stocks];
                                        }
                                    }
                                }
                            });
                            break;
                        case "End Cap":
                            o.sets.forEach(s => {
                                if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                                    if (s.condition2.includes(product.items[itemIndex].configuration.frameColour.selected.value.optionKey)) {
                                        stocks = [...stocks, ...s.stocks];
                                    }
                                }
                            });
                            break;
                        case "Joiner":
                            o.sets.forEach(s => {
                                if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                                    if (width >= s.min && width <= s.max) {
                                        stocks = [...stocks, ...s.stocks];
                                    }
                                }
                            });
                            break;
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.frontBar.selected.stocks = stocks;
                break;
            case "backBar":
                attribute = "Back Bar";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.backBar.finalOptions.forEach(o => {
                    if (o.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                        o.sets.forEach(s => {
                            if (width >= s.min && width <= s.max) {
                                stocks = [...stocks, ...s.stocks];
                            }
                        })
                    }
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.backBar.selected.stocks = stocks;
                break;
            case "keyway":
                attribute = "Keyway";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.keyway.finalOptions.forEach(o => {
                    switch (o.optionKey) {
                        case "Keyway":
                            condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                            o.sets.forEach(s => {
                                if (width >= s.min && width <= s.max && (s.condition1 ? s.condition1.includes(condition1) : true)) {
                                    stocks = [...stocks, ...s.stocks];
                                }
                            });
                            break;
                        case "Idler":
                            condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                            o.sets.forEach(s => {
                                if (s.condition1.includes(condition1)) {
                                    stocks = [...stocks, ...s.stocks];
                                }
                            });
                            break;
                        case "Joiner":
                            condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                            o.sets.forEach(s => {
                                if (s.condition1.includes(condition1)) {
                                    if (width >= s.min && width <= s.max) {
                                        stocks = [...stocks, ...s.stocks];
                                    }
                                }
                            });
                            break;
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.keyway.selected.stocks = stocks;
                break;
            case "drop":
                attribute = "Arm";
                label = product.items[itemIndex].configuration.drop.selected.value.optionKey + "mm";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.type.selected.value.optionKey;
                if (condition1 !== "Coolum - Semi Cassette") {
                    setIndex = product.items[itemIndex].configuration.drop.selected.value.sets.findIndex(s => width >= s.min && width <= s.max);
                    if (setIndex > -1) {
                        stocks = product.items[itemIndex].configuration.drop.selected.value.sets[setIndex].stocks;
                    }
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                    });
                }
                product.items[itemIndex].configuration.drop.selected.stocks = stocks;
                break;
            case "frame":
                attribute = "Frame";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.frame.finalOptions.forEach(o => {
                    o.sets.forEach(s => {
                        if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                            if (width >= s.min && width <= s.max && drop >= s.secondaryMin && drop <= s.secondaryMax) {
                                stocks = [...stocks, ...s.stocks];
                            }
                        }
                    });
                });

                product.items[itemIndex].configuration.frameColour.selected.value.sets.forEach(s => {
                    if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                        if (width >= s.min && width <= s.max) {
                            stocks = [...stocks, ...s.stocks];
                        }
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.frame.selected.stocks = stocks;
                break;
            case "crankHandle":
                attribute = "Crank Handle";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                    label = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.crankHandle.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.crankHandle.selected.stocks = stocks;
                break;
            case "brackets":
                attribute = "Brackets";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.brackets.finalOptions.forEach(o => {
                    o.sets.forEach(s => {
                        if (s.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                            if (width >= s.min && width <= s.max && drop >= s.secondaryMin && drop <= s.secondaryMax) {
                                stocks = [...stocks, ...s.stocks];
                            }
                        }
                    });
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.brackets.selected.stocks = stocks;
                break;
            case "rafterBrackets10Degree":
                attribute = "Rafter Brackets 10Degree";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.rafterBrackets10Degree.selected.value && product.items[itemIndex].configuration.rafterBrackets10Degree.selected.value > 0) {
                    product.items[itemIndex].configuration.rafterBrackets10Degree.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.rafterBrackets10Degree.selected.value;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.rafterBrackets10Degree.selected.stocks = stocks;
                break;
            case "rafterBrackets22Degree":
                attribute = "Rafter Brackets 22Degree";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.rafterBrackets22Degree.selected.value && product.items[itemIndex].configuration.rafterBrackets22Degree.selected.value > 0) {
                    product.items[itemIndex].configuration.rafterBrackets22Degree.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.rafterBrackets22Degree.selected.value;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.rafterBrackets22Degree.selected.stocks = stocks;
                break;
            case "rafterBrackets30Degree":
                attribute = "Rafter Brackets 30Degree";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.rafterBrackets30Degree.selected.value && product.items[itemIndex].configuration.rafterBrackets30Degree.selected.value > 0) {
                    product.items[itemIndex].configuration.rafterBrackets30Degree.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.rafterBrackets30Degree.selected.value;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.rafterBrackets30Degree.selected.stocks = stocks;
                break;
            case "ceilingMountBrackets":
                attribute = "Ceiling Mount Brackets";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.ceilingMountBrackets.selected.value && product.items[itemIndex].configuration.ceilingMountBrackets.selected.value > 0) {
                    product.items[itemIndex].configuration.ceilingMountBrackets.finalOptions.forEach(o => {
                        if (o.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                            if (drop >= o.primaryAttributeMin && drop <= o.primaryAttributeMax && width >= o.secondaryAttributeMin && width <= o.secondaryAttributeMax) {
                                stocks = [...stocks, ...o.stocks];
                            }
                        }
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.ceilingMountBrackets.selected.value;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.ceilingMountBrackets.selected.stocks = stocks;
                break;
            case "topFixBrackets":
                attribute = "Top Fix Brackets";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.topFixBrackets.selected.value && product.items[itemIndex].configuration.topFixBrackets.selected.value > 0) {
                    product.items[itemIndex].configuration.topFixBrackets.finalOptions.forEach(o => {
                        if (o.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                            if (width >= o.primaryAttributeMin && width <= o.primaryAttributeMax) {
                                stocks = [...stocks, ...o.stocks];
                            }
                        }
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.topFixBrackets.selected.value;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.topFixBrackets.selected.stocks = stocks;

                // product.items[itemIndex].configuration.topFixBrackets.selected.value = findItemHavingMaxValue(stocks, 'quantityMultiplier');
                break;
            case "cassetteBackBarBrackets":
                attribute = "Cassette Back Bar Brackets";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value && product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value > 0) {
                    product.items[itemIndex].configuration.cassetteBackBarBrackets.finalOptions.forEach(o => {
                        if (o.condition1.includes(product.items[itemIndex].configuration.type.selected.value.optionKey)) {
                            if (width >= o.primaryAttributeMin && width <= o.primaryAttributeMax) {
                                stocks = [...stocks, ...o.stocks];
                            }
                        }
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.stocks = stocks;
                break;
            case "remoteChannel15":
                attribute = "Remote Channel15";
                label = product.items[itemIndex].configuration.remoteChannel15.selected.dropdownValue;
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.remoteChannel15.selected.value) {
                    condition1 = isEmpty(product.items[itemIndex].configuration.type.selected.value) ? "" : product.items[itemIndex].configuration.type.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    product.items[itemIndex].configuration.remoteChannel15.finalOptions = product.items[itemIndex].configuration.remoteChannel15.options.filter(o => (o.condition1 ? o.condition1.includes(condition1) : true) && (o.condition2 ? o.condition2.includes(condition2) : true));
                    product.items[itemIndex].configuration.remoteChannel15.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.remoteChannel15.selected.stocks = stocks;
                break;
            case "vibrationSensor":
                attribute = "Vibration Sensor";
                label = product.items[itemIndex].configuration.vibrationSensor.selected.dropdownValue;
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.vibrationSensor.selected.value) {
                    condition1 = isEmpty(product.items[itemIndex].configuration.type.selected.value) ? "" : product.items[itemIndex].configuration.type.selected.value.optionKey;
                    condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;

                    product.items[itemIndex].configuration.vibrationSensor.finalOptions = product.items[itemIndex].configuration.vibrationSensor.options.filter(o => (o.condition1 ? o.condition1.includes(condition1) : true) && (o.condition2 ? o.condition2.includes(condition2) : true));
                    product.items[itemIndex].configuration.vibrationSensor.finalOptions.forEach(o => {
                        stocks = [...stocks, ...o.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.vibrationSensor.selected.stocks = stocks;
                break;
            case "windSensor":
                attribute = "Wind Sensor";
                label = product.items[itemIndex].configuration.windSensor.selected.optionKey;
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.windSensor.selected.value)) {
                    product.items[itemIndex].configuration.windSensor.finalOptions.forEach(o => {
                        if (o.optionKey === product.items[itemIndex].configuration.windSensor.selected.value.optionKey) {
                            stocks = [...stocks, ...o.stocks];
                        }
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.windSensor.selected.stocks = stocks;
                break;
            case "valance":
                attribute = "Valance";
                label = product.items[itemIndex].configuration.valance.selected.value.optionKey;
                comment = "";
                stocks = [];

                deduction = this.updateDeduction("Valance", product, itemIndex);
                product.items[itemIndex].configuration.valance.selected.deduction = deduction;

                if (product.items[itemIndex].configuration.type.selected.value.optionKey !== PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE) {
                    stocks = product.items[itemIndex].configuration.valance.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.valance.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, order);

            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("frontBar", product, itemIndex, order);
            product = this.updateStocks("backBar", product, itemIndex, order);
            product = this.updateStocks("keyway", product, itemIndex, order);
            product = this.updateStocks("drop", product, itemIndex, order);
            product = this.updateStocks("frame", product, itemIndex, order);
            product = this.updateStocks("crankHandle", product, itemIndex, order);
            product = this.updateStocks("hooding", product, itemIndex, order);
            product = this.updateStocks("brackets", product, itemIndex, order);
            product = this.updateStocks("rafterBrackets10Degree", product, itemIndex, order);
            product = this.updateStocks("rafterBrackets22Degree", product, itemIndex, order);
            product = this.updateStocks("rafterBrackets30Degree", product, itemIndex, order);
            product = this.updateStocks("ceilingMountBrackets", product, itemIndex, order);
            product = this.updateStocks("topFixBrackets", product, itemIndex, order);
            product = this.updateStocks("cassetteBackBarBrackets", product, itemIndex, order);
            product = this.updateStocks("remoteChannel15", product, itemIndex, order);
            product = this.updateStocks("vibrationSensor", product, itemIndex, order);
            product = this.updateStocks("windSensor", product, itemIndex, order);
            product = this.updateStocks("valance", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.frontBar.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.frontBar.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.backBar.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.backBar.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.keyway.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.drop.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.drop.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.crankHandle.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.frame.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.frame.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.hoodingColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.brackets.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.brackets.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.rafterBrackets10Degree.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.rafterBrackets10Degree.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.rafterBrackets22Degree.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.rafterBrackets22Degree.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.rafterBrackets30Degree.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.rafterBrackets30Degree.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.ceilingMountBrackets.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.ceilingMountBrackets.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.topFixBrackets.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.topFixBrackets.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.cassetteBackBarBrackets.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.remoteChannel15.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.remoteChannel15.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.vibrationSensor.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.vibrationSensor.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.windSensor.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.windSensor.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.valance.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.valance.selected.stocks];
            }

            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? parseInt(product.items[itemIndex].configuration.drop.selected.value.optionKey) : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor
        };


        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                /*
                * width(mm) drop(mm) fabricWidth(m)
                *
                * PANEL >> panel
                * FABRIC_DROP(M) >> fabricDrop
                * USAGE(LM) >> usage
                * */
                let panel = 0, fabricDrop = 0, usage = 0;

                //Calculating PANEL
                try {
                    panel = Math.ceil((width / 1000) / fabricWidth);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }

                //Calculating FABRIC_DROP
                fabricDrop = (drop + 400) / 1000;

                //Calculating FABRIC_DROP
                if (fabricDrop > fabricWidth) {
                    usage = round((panel * fabricDrop), 1);
                } else {
                    usage = width / 1000;
                }
                deduction = this.updateDeduction("fabric", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Operation":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Back Bar":
                deduction = this.updateDeduction("Back Bar", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.backBar.selected.deduction = deduction;
                break;
            case "Front Bar":
                deduction = this.updateDeduction("Front Bar", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.frontBar.selected.deduction = deduction;
                break;
            case "Keyway":
                deduction = this.updateDeduction("Keyway", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                break;
            case "Frame":
                switch (true) {
                    case stockInventoryItem.description.includes("CASSETTE BODY"):
                        deduction = this.updateDeduction("CASSETTE BODY", product, itemIndex);
                        product.items[itemIndex].configuration.casetteBody.selected.deduction = deduction;
                        break;
                    case stockInventoryItem.description.includes("PVC LINER"):
                        deduction = this.updateDeduction("PVC LINER", product, itemIndex);
                        product.items[itemIndex].configuration.pvcLinear.selected.deduction = deduction;
                        break;
                    case stockInventoryItem.description.includes("CASSETTE BOTTOM COVER"):
                        deduction = this.updateDeduction("CASSETTE BOTTOM COVER", product, itemIndex);
                        product.items[itemIndex].configuration.casetteBottomCover.selected.deduction = deduction;
                        break;
                    default:
                        deduction = this.updateDeduction("Frame", product, itemIndex);
                        product.items[itemIndex].configuration.frame.selected.deduction = deduction;
                        break;
                }

                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;

                break;
            case "Hooding":
                deduction = this.updateDeduction("Hooding", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.hooding.selected.deduction = deduction;
                break;
            case "Valance":
                deduction = this.updateDeduction("Valance", product, itemIndex);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.valance.selected.deduction = deduction;
                break;
            case "Arm":
            case "Brackets":
            case "Rafter Brackets 10Degree":
            case "Rafter Brackets 22Degree":
            case "Rafter Brackets 30Degree":
            case "Ceiling Mount Brackets":
            case "Top Fix Brackets":
            case "Remote Channel15":
            case "Vibration Sensor":
            default:
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
        }
    }

    updateDeduction(key, product, itemIndex, stockInventoryItem) {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        let isMotorised = this.isMotorised(product, itemIndex);
        let hasHooding = this.hasHooding(product, itemIndex);
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = parseInt(product.items[itemIndex].configuration.drop.selected.value.optionKey);

        switch (key) {
            case "fabric":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        if (hasHooding && product.items[itemIndex].configuration.mount.selected.value.optionKey === "Inside/Reveal") {
                            deduction.deductionWidth = -150;
                        } else {
                            deduction.deductionWidth = -105;
                        }
                        break;
                    case "Semi Cassette":
                        deduction.deductionWidth = !isMotorised ? -125 : -110;
                        break;
                    case "Full Cassette":
                        deduction.deductionWidth = !isMotorised ? -125 : -110;
                        break;
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = -130;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "Valance":
                switch (product.items[itemIndex].configuration.valance.selected.value.optionKey) {
                    case "None":
                        deduction.deductionDrop = 700; //+700
                        break;
                    case "Scallop":
                    case "Square":
                    case "Straight":
                    default:
                        deduction.deductionDrop = 1000; //+1000
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "Keyway":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        if (hasHooding && product.items[itemIndex].configuration.mount.selected.value.optionKey === "Inside/Reveal") {
                            deduction.deductionWidth = -145;
                        } else {
                            deduction.deductionWidth = -100;
                        }
                        break;
                    case "Semi Cassette":
                        deduction.deductionWidth = !isMotorised ? -121 : -105;
                        break;
                    case "Full Cassette":
                        deduction.deductionWidth = !isMotorised ? -121 : -105;
                        break;
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "Front Bar":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        if (hasHooding && product.items[itemIndex].configuration.mount.selected.value.optionKey === "Inside/Reveal") {
                            deduction.deductionWidth = -145;
                        } else {
                            deduction.deductionWidth = -100;
                        }
                        break;
                    case "Semi Cassette":
                        deduction.deductionWidth = !isMotorised ? -111 : -100;
                        break;
                    case "Full Cassette":
                        deduction.deductionWidth = !isMotorised ? -111 : -70;
                        break;
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "Back Bar":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        if (hasHooding && product.items[itemIndex].configuration.mount.selected.value.optionKey === "Inside/Reveal") {
                            deduction.deductionWidth = -40;
                        } else {
                            deduction.deductionWidth = 0; //NIL
                        }
                        break;
                    case "Semi Cassette":
                        deduction.deductionWidth = !isMotorised ? -106 : -60;
                        break;
                    case "Full Cassette":
                        deduction.deductionWidth = !isMotorised ? -106 : -60;
                        break;
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "Hooding":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        if (hasHooding) {
                            switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                                case "Outside/Face":
                                    deduction.deductionWidth = 40;
                                    break;
                                case "Inside/Reveal":
                                    deduction.deductionWidth = -5;
                                    break;
                            }
                        }
                        break;
                    case "Semi Cassette":
                    case "Full Cassette":
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "CASSETTE BODY":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        deduction.deductionWidth = 0;
                        break;
                    case "Semi Cassette":
                        deduction.deductionWidth = !isMotorised ? -93 : -50;
                        break;
                    case "Full Cassette":
                        deduction.deductionWidth = !isMotorised ? -93 : -50;
                        break;
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "PVC LINER":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        deduction.deductionWidth = 0;
                        break;
                    case "Semi Cassette":
                        deduction.deductionWidth = !isMotorised ? -98 : -55;
                        break;
                    case "Full Cassette":
                        deduction.deductionWidth = !isMotorised ? -98 : -55;
                        break;
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
            case "CASSETTE BOTTOM COVER":
                switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
                    case "Monobloc 505":
                    case "Monobloc 520":
                        deduction.deductionWidth = 0;
                        break;
                    case "Semi Cassette":
                        deduction.deductionWidth = !isMotorised ? -111 : -75;
                        break;
                    case "Full Cassette":
                        deduction.deductionWidth = !isMotorised ? -111 : -75;
                        break;
                    case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                        deduction.deductionWidth = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                break;
        }
        return deduction;
    }

    isMotorised(product, itemIndex, order) {
        let isMotorised = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation)) {
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                    case "Windoware A/C Motor":
                    case "Motor RTS AC Cassette Alpha":
                    case "Somfy Altus 60 RTS":
                        isMotorised = true;
                        break;
                    case "Manual":
                    default:
                        isMotorised = false;
                        break;
                }
            }
        }
        return isMotorised;
    }


    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    generatePriceMatrixBOMCode(product, itemIndex) {
        let bmCode = "";
        switch (product.items[itemIndex].configuration.type.selected.value.optionKey) {
            case "Monobloc 505":
                bmCode = "M505";
                break;
            case "Monobloc 520":
                bmCode = "M520";
                break;
            case "Semi Cassette":
                bmCode = "SC1540";
                break;
            case "Full Cassette":
                bmCode = "FC1540";
                break;
            case PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE:
                bmCode = "";
                break;
        }
        if (product.items[itemIndex].configuration.isFrameOnly.selected.value) {
            bmCode = bmCode + "F";
        } else {
            bmCode = bmCode + product.items[itemIndex].configuration.fabricType.selected.value.category;
        }
        return bmCode;
    }

    hasHooding(product, itemIndex) {
        let result = false;
        if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
            result = product.items[itemIndex].configuration.hooding.selected.value.optionKey !== "No Hood";
        }
        return result
    }

    isCOOLUM_SEMI_CASSETTEModel(product, itemIndex) {
        return product.items[itemIndex].configuration.type.selected.value.optionKey === PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fabricColourOffRange.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fabricColourOffRange.formError.message);
        }
        if (!pg.items[itemIndex].configuration.hoodingPowdercoatColour.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.hoodingPowdercoatColour.formError.message);
        }
        if (!pg.items[itemIndex].configuration.framePowdercoatColour.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.framePowdercoatColour.formError.message);
        }
        return errors;
    }

    toSalesOrderItemFoldingArmAwningRequest(pg, itemIndex, order) {
        let defaultDeduction = this.updateDeduction("default", pg, itemIndex);

        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.valance.selected.deduction)) {
            pg.items[itemIndex].configuration.valance.selected.deduction = defaultDeduction;
        }
        if (this.isCOOLUM_SEMI_CASSETTEModel(pg, itemIndex) || isEmpty(pg.items[itemIndex].configuration.hooding.selected.deduction)) {
            pg.items[itemIndex].configuration.hooding.selected.deduction = defaultDeduction;
        }
        if (this.isCOOLUM_SEMI_CASSETTEModel(pg, itemIndex) || isEmpty(pg.items[itemIndex].configuration.frontBar.selected.deduction)) {
            pg.items[itemIndex].configuration.frontBar.selected.deduction = defaultDeduction;
        }
        if (this.isCOOLUM_SEMI_CASSETTEModel(pg, itemIndex) || isEmpty(pg.items[itemIndex].configuration.backBar.selected.deduction)) {
            pg.items[itemIndex].configuration.backBar.selected.deduction = defaultDeduction;
        }
        if (this.isCOOLUM_SEMI_CASSETTEModel(pg, itemIndex) || isEmpty(pg.items[itemIndex].configuration.keyway.selected.deduction)) {
            pg.items[itemIndex].configuration.keyway.selected.deduction = defaultDeduction;
        }
        if (this.isCOOLUM_SEMI_CASSETTEModel(pg, itemIndex) || isEmpty(pg.items[itemIndex].configuration.pvcLinear.selected.deduction)) {
            pg.items[itemIndex].configuration.pvcLinear.selected.deduction = defaultDeduction;
        }
        if (this.isCOOLUM_SEMI_CASSETTEModel(pg, itemIndex) || isEmpty(pg.items[itemIndex].configuration.casetteBody.selected.deduction)) {
            pg.items[itemIndex].configuration.casetteBody.selected.deduction = defaultDeduction;
        }
        if (this.isCOOLUM_SEMI_CASSETTEModel(pg, itemIndex) || isEmpty(pg.items[itemIndex].configuration.casetteBottomCover.selected.deduction)) {
            pg.items[itemIndex].configuration.casetteBottomCover.selected.deduction = defaultDeduction;
        }


        let salesOrderItemFoldingArmAwning = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: pg.items[itemIndex].configuration.type.selected.value.optionKey === PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE ? "Coolum" : pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: pg.items[itemIndex].configuration.width.selected.value,
            drop: parseInt(pg.items[itemIndex].configuration.drop.selected.value.optionKey),
            type: pg.items[itemIndex].configuration.type.selected.value.optionKey,
            operation: pg.items[itemIndex].configuration.operation.selected.value.optionKey,
            controlSide: pg.items[itemIndex].configuration.controlSide.selected.value.optionKey,
            fabricType: pg.items[itemIndex].configuration.isFrameOnly.selected.value ? "" : pg.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: pg.items[itemIndex].configuration.isFrameOnly.selected.value ? "" : isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.value) ? "" : pg.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            fabricColourOffRange: pg.items[itemIndex].configuration.isFrameOnly.selected.value ? "" : pg.items[itemIndex].configuration.fabricColourOffRange.selected.value,
            isFrameOnly: pg.items[itemIndex].configuration.isFrameOnly.selected.value,
            frameColour: pg.items[itemIndex].configuration.frameColour.selected.value.optionKey,
            framePowdercoatColour: pg.items[itemIndex].configuration.framePowdercoatColour.selected.value,
            hooding: isEmpty(pg.items[itemIndex].configuration.hooding.selected.value) ? "" : pg.items[itemIndex].configuration.hooding.selected.value.optionKey,
            hoodingColour: isEmpty(pg.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : pg.items[itemIndex].configuration.hoodingColour.selected.value.optionKey,
            hoodingPowdercoatColour: pg.items[itemIndex].configuration.hoodingPowdercoatColour.selected.value,
            valance: pg.items[itemIndex].configuration.valance.selected.value.optionKey,
            mount: pg.items[itemIndex].configuration.mount.selected.value.optionKey,
            crankHandle: pg.items[itemIndex].configuration.crankHandle.selected.value.optionKey,
            rafterBrackets10Degree: pg.items[itemIndex].configuration.rafterBrackets10Degree.selected.value ? pg.items[itemIndex].configuration.rafterBrackets10Degree.selected.value : 0,
            rafterBrackets22Degree: pg.items[itemIndex].configuration.rafterBrackets22Degree.selected.value ? pg.items[itemIndex].configuration.rafterBrackets22Degree.selected.value : 0,
            rafterBrackets30Degree: pg.items[itemIndex].configuration.rafterBrackets30Degree.selected.value ? pg.items[itemIndex].configuration.rafterBrackets30Degree.selected.value : 0,
            ceilingMountBrackets: pg.items[itemIndex].configuration.ceilingMountBrackets.selected.value ? pg.items[itemIndex].configuration.ceilingMountBrackets.selected.value : 0,
            topFixBrackets: pg.items[itemIndex].configuration.topFixBrackets.selected.value ? pg.items[itemIndex].configuration.topFixBrackets.selected.value : 0,
            cassetteBackBarBrackets: pg.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value ? pg.items[itemIndex].configuration.cassetteBackBarBrackets.selected.value : 0,
            remoteChannel15: pg.items[itemIndex].configuration.remoteChannel15.selected.value ? pg.items[itemIndex].configuration.remoteChannel15.selected.dropdownValue : "",
            vibrationSensor: pg.items[itemIndex].configuration.vibrationSensor.selected.value ? pg.items[itemIndex].configuration.vibrationSensor.selected.dropdownValue : "",
            windSensor: pg.items[itemIndex].configuration.windSensor.selected.value ? pg.items[itemIndex].configuration.windSensor.selected.value.optionKey : "",

            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,
            hoodingDeductionWidth: pg.items[itemIndex].configuration.hooding.selected.deduction.deductionWidth,
            hoodingDeductionDrop: pg.items[itemIndex].configuration.hooding.selected.deduction.deductionDrop,
            valanceDeductionWidth: pg.items[itemIndex].configuration.valance.selected.deduction.deductionWidth,
            valanceDeductionDrop: pg.items[itemIndex].configuration.valance.selected.deduction.deductionDrop,
            frontBarDeductionWidth: pg.items[itemIndex].configuration.frontBar.selected.deduction.deductionWidth,
            frontBarDeductionDrop: pg.items[itemIndex].configuration.frontBar.selected.deduction.deductionDrop,
            backBarDeductionWidth: pg.items[itemIndex].configuration.backBar.selected.deduction.deductionWidth,
            backBarDeductionDrop: pg.items[itemIndex].configuration.backBar.selected.deduction.deductionDrop,
            keywayDeductionWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.deductionWidth,
            keywayDeductionDrop: pg.items[itemIndex].configuration.keyway.selected.deduction.deductionDrop,
            pvcLinearDeductionWidth: pg.items[itemIndex].configuration.pvcLinear.selected.deduction.deductionWidth,
            pvcLinearDeductionDrop: pg.items[itemIndex].configuration.pvcLinear.selected.deduction.deductionDrop,
            casetteBodyDeductionWidth: pg.items[itemIndex].configuration.casetteBody.selected.deduction.deductionWidth,
            casetteBodyDeductionDrop: pg.items[itemIndex].configuration.casetteBody.selected.deduction.deductionDrop,
            casetteBottomCoverDeductionWidth: pg.items[itemIndex].configuration.casetteBottomCover.selected.deduction.deductionWidth,
            casetteBottomCoverDeductionDrop: pg.items[itemIndex].configuration.casetteBottomCover.selected.deduction.deductionDrop,

            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,
            hoodingCutWidth: pg.items[itemIndex].configuration.hooding.selected.deduction.cutWidth,
            hoodingCutDrop: pg.items[itemIndex].configuration.hooding.selected.deduction.cutDrop,
            valanceCutWidth: pg.items[itemIndex].configuration.valance.selected.deduction.cutWidth,
            valanceCutDrop: pg.items[itemIndex].configuration.valance.selected.deduction.cutDrop,
            frontBarCutWidth: pg.items[itemIndex].configuration.frontBar.selected.deduction.cutWidth,
            frontBarCutDrop: pg.items[itemIndex].configuration.frontBar.selected.deduction.cutDrop,
            backBarCutWidth: pg.items[itemIndex].configuration.backBar.selected.deduction.cutWidth,
            backBarCutDrop: pg.items[itemIndex].configuration.backBar.selected.deduction.cutDrop,
            keywayCutWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.cutWidth,
            keywayCutDrop: pg.items[itemIndex].configuration.keyway.selected.deduction.cutDrop,
            pvcLinearCutWidth: pg.items[itemIndex].configuration.pvcLinear.selected.deduction.cutWidth,
            pvcLinearCutDrop: pg.items[itemIndex].configuration.pvcLinear.selected.deduction.cutDrop,
            casetteBodyCutWidth: pg.items[itemIndex].configuration.casetteBody.selected.deduction.cutWidth,
            casetteBodyCutDrop: pg.items[itemIndex].configuration.casetteBody.selected.deduction.cutDrop,
            casetteBottomCoverCutWidth: pg.items[itemIndex].configuration.casetteBottomCover.selected.deduction.cutWidth,
            casetteBottomCoverCutDrop: pg.items[itemIndex].configuration.casetteBottomCover.selected.deduction.cutDrop,

            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemFoldingArmAwning;
    }
}

export default FoldingArmAwningUtil.Instance();