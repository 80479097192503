import React, {Component} from 'react';
import {Card, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText, Table} from "reactstrap";
import {toast} from "react-toastify";
import UserService from "../../services/UserService";
import { getLocalDateStringFromUtcDateString, getDateString} from "../../services/CommonService";
import NotesService from "../../services/NotesService";
import {getSorted,handleErrorMessage} from "../../services/CommonService";
import {isEmpty} from 'lodash';

export default class NotesCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: "",
            notesData: [],
            searchText: ""
        }
        this.userService = new UserService();
    }

    componentDidMount() {
        if (!isEmpty(this.props.accountId)) {
            this.setState({accountId: this.props.accountId}, () => {
                this.refresh(this.props.accountId);
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountId !== nextProps.accountId) {
            this.setState({accountId: nextProps.accountId}, function () {
                this.refresh(nextProps.accountId);
            });
        }
    }

    refresh(accountId) {
        this.fetchNotes(accountId);
    }

    handleSearchTextChange(change, key) {
        this.setState({[key]: change});
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            flag = false;
            searchText = searchText.toLowerCase();
            if (!flag && item.dateAdded) {
                flag = getDateString(item.dateAdded, "DD MMM YYYY").toLowerCase().includes(searchText)
            }
            if (!flag && item.notes) {
                flag = item.notes.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    fetchNotes(accountId) {
        NotesService.getAllNotes(accountId).then(response => {
            response.data = getSorted(response.data, 'oid', false);
            this.setState({notesData: response.data});

        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error.data)
        });
    }

    render() {
        let {notesData, searchText} = this.state;
        return (
            <div>
                <Card>
                    <CardBody>
                        <p><strong>NOTES</strong></p>
                        <div className="scroller">
                            <Table size={"sm"} striped={true} hover={true} bordered>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 150}}>Date</th>
                                    <th>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-search"
                                                       aria-hidden="true"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                onChange={(e) => this.handleSearchTextChange(e.target.value, e.target.name)}
                                                type="search" name="searchText"
                                                id="searchText"
                                                placeholder="Search..."/>
                                        </InputGroup>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (notesData || []).filter((item) => this.searchFunction(item, searchText)).map((item, index) => {
                                        return <tr key={index}>
                                            <td>{getDateString(item.dateAdded, "DD MMM YYYY")}</td>
                                                <td>{item.notes}</td>
                                            </tr>
                                        }
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>

            </div>
        );
    }
}