import React, {Component} from "react";
import CustomerService from "../../../services/CustomerService";
import {
    compare,
    getFinancialMonthList,
    getFinancialYear,
    getSorted,
    groupBy,
    handleErrorMessage
} from "../../../services/CommonService";
import {toast, ToastContainer} from 'react-toastify';
import {
    Card,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table
} from "reactstrap";
import NumberFormat from "react-number-format";
import moment from 'moment';

let options = [];
for (let i = 3; i <= 10; i++) {
    options.push({label: i, value: i});
}

export default class CustomerSalesMonthlyPerformanceByFY extends Component {
    constructor(props) {
        super(props);
        const monthList = getFinancialMonthList(getFinancialYear(moment(new Date())));
        this.state = {
            accountID: this.props.accountID,
            customerYearlySales: [],
            monthList: monthList,
            isLoading: false,
            selected: options[0].value,
            includeChildAccount: this.props.includeChildAccount ? this.props.includeChildAccount : false
        };
        this.customerService = new CustomerService();
        this.getCustomerSalesYearly = this.getCustomerSalesYearly.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCustomerSalesYearly();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.includeChildAccount !== nextProps.includeChildAccount) {
            this.setState({accountID: nextProps.accountID, includeChildAccount: nextProps.includeChildAccount}, () => {
                this.getCustomerSalesYearly();
            });
        }
    }

    getCustomerSalesYearly() {
        let customerYearlySales = [];
        this.setState({isLoading: true});
        this.customerService.getCustomerSalesByFY(this.props.accountID, this.state.selected, this.state.includeChildAccount).then(response => {
            let yearlySales = groupBy(response.data, 'financialYear');
            for (let key in yearlySales) {
                let totalSale = 0;
                yearlySales[key].forEach(ys => {
                    totalSale += ys.sumTotal;
                });
                customerYearlySales.push({'financialYear': key, 'data': yearlySales[key], 'totalAmount': totalSale,});
            }
            customerYearlySales = getSorted(customerYearlySales, 'financialYear', false);
            (customerYearlySales || []).map((year, index) => {
                if (index !== (customerYearlySales.length - 1)) {
                    customerYearlySales[index].saleCompare = compare(customerYearlySales[index], customerYearlySales[index + 1], 'totalAmount', true);
                }
            });
            this.setState({isLoading: false, customerYearlySales});
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
        })
    }

    handleChange(change) {
        if (change && change !== this.state.selected) {
            this.setState({selected: change}, () => {
                this.getCustomerSalesYearly();
            })
        }
    }

    render() {
        let {customerYearlySales, isLoading, monthList, selected} = this.state;
        return (
            <Card>
                <CardHeader>
                    <Row className={"align-items-center"}>
                        <Col xl={9} lg={9} md={6}>
                            <h6 className={"mb-0"}>Monthly Total Performance</h6>
                        </Col>
                        <Col xl={3} lg={3} md={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Last</InputGroupText>
                                </InputGroupAddon>
                                <Input type="select" value={selected}
                                       placeholder={"Select last no. of Year"}
                                       onChange={(selection) => this.handleChange(selection.target.value)}>
                                    {(options || []).map((item) => <option
                                        key={item.value}
                                        value={item.value}>{item.label}</option>)}
                                </Input>
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>Years</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </CardHeader>
                <Table striped bordered responsive hover size='sm' className={"mb-0"}>
                    <thead>
                    <tr>
                        <th style={{minWidth: 120}}>Financial Year</th>
                        {(monthList || []).map((item, index) => {
                            return (<th key={index}
                                        className={"text-center"}>
                                {item.shortName}
                            </th>);
                        })}
                        <th className={"text-right"}>Total Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? <tr>
                            <td colSpan={14}><Spinner className="primary"/></td>
                        </tr> :
                        (customerYearlySales || []).map((yearData, index) => <tr key={index}>
                                <th className="text-center">{yearData.financialYear}</th>
                                {(monthList || []).map((item, index) => {
                                    return (<td key={index} className="text-right"><NumberFormat
                                        value={yearData.data.find(x => x.month === item.monthNumber) ? yearData.data.find(x => x.month === item.monthNumber).sumTotal : 0}
                                        decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={"$"}/>
                                    </td>);
                                })}
                                <th className="text-right">
                                    {index !== customerYearlySales.length - 1
                                        ? <span className={"text-left"} style={{float: "left"}}>
                                                            {yearData.saleCompare === 1
                                                                ?
                                                                <i style={{color: "#42ba96"}}
                                                                   className={"fa fa-caret-up"}/>
                                                                : (yearData.saleCompare === -1) ?
                                                                    < i style={{color: "red"}}
                                                                        className={"fa fa-caret-down"}/> : null}
                                                        </span>
                                        : null}

                                    <NumberFormat value={yearData.totalAmount}
                                                  decimalScale={0}
                                                  displayType={'text'}
                                                  prefix={'$'}
                                                  thousandSeparator={true}/>

                                </th>
                            </tr>
                        )}

                    </tbody>
                </Table>
                <ToastContainer/>
            </Card>

        )
    }
}