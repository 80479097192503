import React, {Component} from 'react';
import ManagePrintersService from "../../services/ManagePrintersService";
import {Badge, Button, Card, CardBody, Spinner, Table} from "reactstrap";
import classnames from "classnames";
import {toast, ToastContainer} from "react-toastify";
import EditPrinterPropModal from "../../components/modal/EditPrinterPropModal";
import {handleErrorMessage} from "../../services/CommonService";

export default class PrintersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printersList: [],
            selectedPrinter: {},
            loading: false,
            saving: false,
            isOpenModal: false,
            formErrors: {}
        };
        this.managePrintersService = new ManagePrintersService();
        this.toggle = this.toggle.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({loading: true});
        this.managePrintersService.getAllPrintersList().then(response => {
                this.setState({printersList: response.data, loading: false});
            }
        ).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({loading: false});
            }
        )

    }

    handleSubmit(selectedPrinter) {
        this.setState({saving: true});
        let result = this.validateForm(selectedPrinter);
        if (!result.valid) {
            this.setState({formErrors: result.formErrors, saving: false});
            toast.info("See form error for invalid input ", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        this.managePrintersService.savePrinterChange(selectedPrinter).then(response => {
                this.handleCancel();
            }
        ).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({saving: false})
            }
        )
    }

    handleCancel() {
        this.setState({
            selectedPrinter: {}, loading: false, isOpenModal: false, formErrors: {}, saving: false
        });
        this.refresh();
    }

    openModal(printer) {
        this.setState({selectedPrinter: printer, isOpenModal: true})
    }

    toggle(val) {
        this.setState({isOpenModal: val, formErrors: {}});
        this.refresh();
    }

    validateForm(selectedPrinter) {
        let formErrors = {};
        formErrors.printerName = selectedPrinter.printerName.trim().length > 0 ? "" : "Enter Printer Name";
        if (formErrors.printerName || formErrors.suitableJobType) {
            return {valid: false, formErrors: formErrors};
        }
        return {valid: true, formErrors: formErrors};
    }

    getJobTypeArray(jobTypeString) {
        return jobTypeString.split(',');
    }

    render() {
        let {printersList, loading} = this.state;
        return (
            <div>
                <div>
                    {loading
                        ? <Spinner size={"sm"} color={"primary"}/>
                        : <Table bordered responsive size={"sm"} striped={true}>
                            <thead>
                            <th>Sr. No</th>
                            <th>Printer Name</th>
                            <th>Job Type</th>
                            <th className={"text-center"}>Active</th>
                            <th className={"text-center"}>Printer Status</th>
                            <th className={"text-center"}>Action</th>
                            </thead>
                            <tbody>
                            {
                                printersList.map((printer, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td> {printer.printerName} </td>
                                        <td> {(printer.suitableJobType !== null && printer.suitableJobType !== "") ?
                                            this.getJobTypeArray(printer.suitableJobType).map(type => (
                                                <Badge size="sm" color="secondary"
                                                       className={"mr-2"}> {type} </Badge>
                                            ))
                                            : null} </td>
                                        <td className={"text-center"}><i
                                            className={classnames("ml-2", "fa", "fa-lg", {
                                                "fa-check-square-o": printer.isActive,
                                                "fa-square-o": !printer.isActive
                                            })}/></td>
                                        <td className={"text-center"}>
                                            {
                                                printer.printerStatus === "ON" ?
                                                    <Badge color="success">{printer.printerStatus}</Badge>
                                                    :
                                                    <Badge color="danger">{printer.printerStatus}</Badge>
                                            }
                                        </td>
                                        <td className={"text-center"}>
                                            <Button color={"primary"} size={"sm"}
                                                    onClick={() => this.openModal(printer)}>
                                                <i className="fa fa-pencil-square-o"
                                                   aria-hidden="true"/> Edit</Button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    }
                </div>
                <ToastContainer/>
                <EditPrinterPropModal
                    isOpen={this.state.isOpenModal}
                    toggle={this.toggle}
                    handleCancel={this.handleCancel}
                    handleSubmit={this.handleSubmit}
                    saving={this.state.saving}
                    printer={this.state.selectedPrinter}
                    handleChange={this.handleChange}
                    formErrors={this.state.formErrors}

                />
            </div>
        )
    }
}