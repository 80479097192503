import React, { Component } from 'react';
import { Button, Spinner, Input, Card, CardHeader, CardBody, Table, Breadcrumb, BreadcrumbItem, FormText, Badge } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import classnames from 'classnames';
import queryString from 'query-string';
import contactService from '../../services/ContactService';
import {handleErrorMessage} from "../../services/CommonService";


export default class ManageContactsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountID: '',
            contacts: [],
            contactTypes: [],
            loading: false,
            formErrors: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.getContacts = this.getContacts.bind(this);
        this.fetchContactTypes = this.fetchContactTypes.bind(this);

    }

    componentDidMount() {
        let { accountID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            accountID = searchParams.accountID;
            this.setState({ accountID });
        }
        this.getContacts(accountID);
        this.fetchContactTypes();
    }

    fetchContactTypes() {
        contactService.getContactTypes().then(response => {
            this.setState({ contactTypes: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getContacts(accountID) {
        this.setState({ loading: true });
        contactService.getContacts(accountID, "Debtor").then(response => {
            this.setState({ contacts: response.data, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    addNewItem() {
        let { contacts, accountID } = this.state;
        let item = {
            contactID: null,
            dateCreated: "",
            accountID: accountID,
            groupTypeID: 1,
            contactTypeID: 1,
            jobTitle: '',
            firstName: '',
            middleInitial: '',
            lastName: '',
            phone: '',
            fax: '',
            afterHours: '',
            mobile: '',
            email: '',
            isPrimary: false,
            isEmailStatement: false,
            isEmailInvoice: false,
            isEmailDispatchNotice: false,
            isEmailMarketing: false,
            priority: 1,
            userID: "",
            consumerType: "Debtor",
            messenger: "",
        };
        contacts.push(item);
        this.setState({ contacts });
    }

    handleChange(value, key, index) {
        let { contacts } = this.state;
        contacts[index][key] = value;
        this.setState({ contacts });
    }

    saveContact(item, index) {
        let { contacts, accountID, formErrors } = this.state;
        let formError = contactService.validateForm(item);

        if (!formError.isValid) {
            formErrors[index] = formError;
            formError.errors.forEach(error=>{
                toast.info(error);
            });
            this.setState({ formErrors });
            return;
        }
        contacts[index].saving = true;
        this.setState({ contacts, formErrors: [] });

        contactService.saveContact(item).then(response => {
            contacts[index].saving = false;
            this.setState({ contacts });
            this.getContacts(accountID);

            toast.success("Contact saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            contacts[index].saving = false;
            this.setState({ contacts });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }


    render() {
        let { contacts, loading,contactTypes, formErrors } = this.state;

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/customers/accounts')}>Customers List</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Contacts</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <h5 className="mb-0">Manage Contacts</h5>
                    </CardHeader>
                    <CardBody>

                        {loading
                            ? <Spinner color="primary" />
                            : <Table responsive={true} striped bordered size={'sm'}>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th style={{ minWidth: '200px' }} >First Name</th>
                                        <th style={{ minWidth: '200px' }} >Middle Name</th>
                                        <th style={{ minWidth: '200px' }} >Last Name </th>
                                        <th style={{ minWidth: '200px' }} >Email</th>
                                        <th style={{ minWidth: '200px' }} >Phone</th>
                                        <th style={{ minWidth: '200px' }} >Mobile</th>
                                        <th style={{ minWidth: '200px' }} >Fax</th>
                                        <th style={{ minWidth: '200px' }} >Contact Type</th>
                                        <th style={{ minWidth: '200px' }} >Position</th>
                                        <th className={"text-center"}>Primary</th>
                                        <th style={{ minWidth: '150px' }} className={"text-center"}>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {(contacts || []).map((item, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Input
                                                    invalid={formErrors[index] ? formErrors[index].firstName != null : false}
                                                    type={"text"}
                                                    name={"firstName"}
                                                    placeholder={"write first name here"}
                                                    value={item.firstName || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                                <FormText color="danger">{formErrors[index] ? formErrors[index].firstName : null}</FormText>
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"middleInitial"}
                                                    placeholder={"write middle name here"}
                                                    value={item.middleInitial || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"lastName"}
                                                    placeholder={"write last name here"}
                                                    value={item.lastName || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input type={"text"}
                                                    name={"email"}
                                                    placeholder={"write email here"}
                                                    value={item.email || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input type={"text"}
                                                    name={"phone"}
                                                    placeholder={"write phone number here"}
                                                    value={item.phone || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input type={"text"}
                                                    name={"mobile"}
                                                    placeholder={"write mobile no here"}
                                                    value={item.mobile || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input type={"text"}
                                                    name={"fax"}
                                                    placeholder={"write fax no here"}
                                                    value={item.fax || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    invalid={formErrors[index] ? formErrors[index].contactTypeID != null : false}
                                                    type="select"
                                                    value={item.contactTypeID}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                    name="contactTypeID"
                                                    placeholder="Select Contact Type...">
                                                    <option value={0}>Select Contact Type</option>
                                                    {(contactTypes || []).map((type, indexx) => <option key={indexx}
                                                        value={type.contactTypeID}>{type.name}</option>)}
                                                </Input>
                                                <FormText color="danger">{formErrors[index] ? formErrors[index].contactTypeID : null}</FormText>
                                            </td>
                                            <td>
                                                <Input autoComplete="off" type="text" name="jobTitle"
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                    value={item.jobTitle || ''} placeholder="Enter position here."
                                                />
                                            </td>
                                            <td>
                                                <Input autoComplete="off" type="text" name="messenger"
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                    value={item.messenger || ''} placeholder="Enter messenger here."
                                                />
                                            </td>
                                            <td className={"text-center"}>
                                                <a href={"javascript:void(0)"}
                                                    onClick={() => this.handleChange(!item.isPrimary, "isPrimary", index)}>
                                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                                        "fa-check-square-o": item.isPrimary,
                                                        "fa-square-o": !item.isPrimary,
                                                    }
                                                    )} />
                                                </a>
                                            </td>
                                            <td className={"text-center"}>
                                                <Button color={"primary"}
                                                    size={"sm"}
                                                    className={"mr-2"}
                                                    disabled={item.saving}
                                                    onClick={() => this.saveContact(item, index)}>
                                                    {item.saving ?

                                                        <Spinner size="sm" className="mr-2"
                                                            style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />}
                                                    {item.contactID ? "Update" : "Save"}
                                                </Button>


                                            </td>
                                        </tr>
                                    })}
                                </tbody>

                            </Table>}
                        <br />
                        <Button onClick={this.addNewItem}>
                            <i className="fa fa-plus mr-2" />
                            Add</Button>
                    </CardBody>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}