import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Spinner, Table} from "reactstrap";
import {handleErrorMessage} from '../../services/CommonService';
import {toast} from 'react-toastify';
import dispatchOrderService from '../../services/DispatchOrderService';
import {EventBus} from '../events/event';
import {
    SHIPMENT_METHOD_CUSTOMER_COLLECT,
    SHIPMENT_METHOD_OWN_TRUCK,
    SHIPMENT_METHOD_WINDOWARE_DELIVERY
} from "../../store/AppConstants";

export default class ShippedOrdersCategoryWiseCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            couriers: [],
            totalOrderCount: 0
        };

        this.getOrderAndCount = this.getOrderAndCount.bind(this);
        EventBus.subscribe("order-shipped", () => this.getOrderAndCount())
    }

    componentDidMount() {
        this.getOrderAndCount();
    }

    getOrderAndCount() {
        let {totalOrderCount, couriers} = this.state;
        this.setState({isLoading: true});
        dispatchOrderService.getOrderCountShippedCategory().then(response => {

            couriers = [{courier: "Freight", orderCount: 0}];
            totalOrderCount = 0;
            if (response.data) {
                (response.data || []).forEach(record => {
                    switch (record.courier) {
                        case SHIPMENT_METHOD_OWN_TRUCK:
                        case SHIPMENT_METHOD_CUSTOMER_COLLECT:
                        case SHIPMENT_METHOD_WINDOWARE_DELIVERY:
                            couriers.push(record);
                            break;
                        default:
                            couriers[0].orderCount += record.orderCount;
                    }
                    totalOrderCount += record.orderCount;
                });
            }

            this.setState({couriers, totalOrderCount, isLoading: false})
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error));
        });
    }


    render() {
        let {isLoading, couriers, totalOrderCount} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Dispatch Performance</h6>
                    </CardHeader>
                    <CardBody>
                        {isLoading
                            ? < Spinner color={"primary"}/>
                            : <Table responsive size={"sm"} className={"mb-0"}>
                                <tbody>
                                {(couriers || []).map((c, index) => {
                                    return (
                                        <tr>
                                            <td>{c.courier}</td>
                                            <td className={"text-right"}>
                                                <strong><h6>{c.orderCount}</h6></strong>
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td>Total Orders</td>
                                    <td className={"text-right"}><h6>{totalOrderCount}</h6></td>
                                </tr>
                                </tbody>
                            </Table>
                        }
                    </CardBody>

                </Card>
            </div>
        );
    }
}