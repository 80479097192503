import React, { Component } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    FormText,
    FormGroup,
    Label
} from 'reactstrap';
import creditorService from "../../services/purchase/CreditorService";
import { toast, ToastContainer } from 'react-toastify';
import { handleErrorMessage } from '../../services/CommonService';

export default class ManageSupplierPaymentDetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountID: props.accountID,
            supplierPaymentMethod: props.supplierPaymentMethod,
            gstTypes: props.gstTypes,
            isLoadingSave: false,
            isLoading: false,
            formErrors: {},
            supplier: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.accountID !== this.state.accountID) {
            this.setState({ accountID: nextProps.accountID }, () => this.refresh());
        }
    }

    refresh() {
        let { accountID } = this.state;
        if (accountID && accountID !== '') {
            this.setState({ isLoading: true });

            creditorService.getSupplierByAccount(accountID).then(response => {
                let supplier = response.data;
                this.setState({ supplier, isLoading: false });
            }).catch(error => {
                this.setState({ isLoading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(value, key) {
        let { supplier } = this.state;
        supplier[key] = value;
        this.setState({ supplier });
    }

    validateForm(supplier) {
        let formErrors = {};
        let valid = true;


        if (!supplier.abn) {
            formErrors.abn = "Please enter ABN";
            valid = false;
        }

        if (!supplier.paymentMethodID) {
            formErrors.paymentMethodID = "Please select payment method";
            valid = false;
        }

        if (!supplier.gsT_Type) {
            formErrors.gsT_Type = "Please enter type";
            valid = false;
        }
        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.supplier };
        let result = this.validateForm(req);
        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ isLoadingSave: true, formErrors: {} });
            creditorService.saveCreditor(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Supplier updated!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({ isLoadingSave: false });
                    this.props.toggle(false);
                    this.props.refresh();
                }
            }).catch(error => {
                this.setState({ isLoadingSave: false });
                toast.error(handleErrorMessage(error));
            });
        }
    }


    render() {
        let { isLoadingSave, isLoading, supplier, formErrors, supplierPaymentMethod, gstTypes } = this.state;
        let { isOpen, toggle } = this.props;
        return (
            <Modal isOpen={isOpen}
                size="md"
                scrollable={false}
                toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Edit Payment Detail
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <Spinner color='primary' size='sm' /> :
                        <div>
                            <h6>Payment Detail</h6>
                            <div>
                                <FormGroup>
                                    <Label>Payment Method*</Label>
                                    <Input
                                        invalid={formErrors.paymentMethodID}
                                        name="paymentMethodID"
                                        type="select"
                                        value={supplier.paymentMethodID}
                                        onChange={(e) => this.handleChange(e.target.value, 'paymentMethodID')}>
                                        <option value="">Select Payment Method</option>
                                        {(supplierPaymentMethod || []).map((payment, index) =>
                                            <option key={index}
                                                value={payment.paymentMethodID}>{payment.description}</option>
                                        )}
                                    </Input>
                                    <FormText color="danger">{formErrors.paymentMethod}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Account Name</Label>
                                    <Input
                                        invalid={formErrors.bankAcctName}
                                        name="accountName"
                                        type="text"
                                        value={supplier.bankAcctName}
                                        onChange={(e) => this.handleChange(e.target.value, 'bankAcctName')}/>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Bank Name</Label>
                                    <Input
                                        invalid={formErrors.bankName}
                                        name="bankName"
                                        type="text"
                                        value={supplier.bankName}
                                        onChange={(e) => this.handleChange(e.target.value, 'bankName')} />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Bank Suburb</Label>
                                    <Input
                                        invalid={formErrors.bankSuburb}
                                        name="bankSuburb"
                                        type="text"
                                        value={supplier.bankSuburb}
                                        onChange={(e) => this.handleChange(e.target.value, 'bankSuburb')} />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>BSB</Label>
                                    <Input
                                        invalid={formErrors.bankBSB}
                                        name="bankBSB"
                                        type="text"
                                        value={supplier.bankBSB}
                                        onChange={(e) => this.handleChange(e.target.value, 'bankBSB')} />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>Account No.</Label>
                                    <Input
                                        invalid={formErrors.bankAcctNum}
                                        name="bankAcctNum"
                                        type="text"
                                        value={supplier.bankAcctNum}
                                        onChange={(e) => this.handleChange(e.target.value, 'bankAcctNum')} />
                                </FormGroup>
                            </div>
                            <hr/>
                            <h6>GST</h6>
                            <div>
                                <FormGroup>
                                    <Label>Type*</Label>
                                    <Input
                                        invalid={formErrors.gsT_Type}
                                        name="gsT_Type"
                                        type="select"
                                        value={supplier.gsT_Type}
                                        onChange={(e) => this.handleChange(e.target.value, 'gsT_Type')}>
                                        <option value="">Select GST Type</option>
                                        {(gstTypes || []).map((type, index) => 
                                            <option key={index}
                                                value={type.key}>{type.label}</option>
                                            )}                                    </Input>
                                    <FormText color="danger">{formErrors.gsT_Type}</FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label>ABN*</Label>
                                    <Input
                                        invalid={formErrors.abn}
                                        name="abn"
                                        type="text"
                                        value={supplier.abn}
                                        onChange={(e) => this.handleChange(e.target.value, 'abn')} />
                                    <FormText color="danger">{formErrors.abn}</FormText>
                                </FormGroup>
                            </div>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button size={"sm"}
                            color='primary'
                            onClick={(e) => this.handleSubmit(e)}
                            disabled={isLoadingSave}>
                            {
                                isLoadingSave
                                    ? <Spinner size="sm" className="mr-2" color={"light"} />
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                            }
                            {
                                isLoadingSave
                                    ? "Updating"
                                    : "Update"
                            }
                        </Button>
                        <Button size={"sm"}
                            color={"secondary"}
                            className={"ml-2"}
                            onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}