import React, {Component} from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Card, CardBody, CardHeader, FormText,
    Row, Col, Label, FormGroup, Input, Spinner, Table,
    InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import {
    toast, ToastContainer
} from "react-toastify";
import PropTypes from 'prop-types';
import SearchStock from "../search/SearchStock";
import StockService from "../../services/StockService";
import StockEnquiryPageSalesHistoryTabLocation from "../../pages/stock/stockEnquiry/StockEnquiryPageSalesHistoryTabLocation";
import WarehouseLocationTypeService from "../../services/WarehouseLocationTypeService";
import { handleErrorMessage } from "../../services/CommonService";
import NumberFormat from "react-number-format";


export default class AddStockToLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoadingSubmit: false,
            stock: [],
            unallocatedStock: '',
            selectedLocationID: '',
            prodCode: '',
            qtyToAdd: '',
            hasError: ''
        };
        this.stockService = new StockService();
        this.handleChange = this.handleChange.bind(this);
        this.getSizeString = this.getSizeString.bind(this);
        this.getStockAllocatedToLocation = this.getStockAllocatedToLocation.bind(this);
    }

    handleChange(change, key) {
        let { unallocatedStock } = this.state;
        switch (key) {
            case "prodCode":
                if (change) {
                    this.getStock(change);
                } else {
                    this.setState({ stock: [] });
                }
                break;
            case "qtyToAdd":
                let message = '';
                if (change !== '' && change > unallocatedStock) {
                    message = 'Quantity cannot be greater than unallocated stock';
                    this.setState({ hasError: message });
                }
                else {
                    this.setState({ hasError: message });
                }
                this.setState({ qtyToAdd: change });
            default:
                break;
        }
    }

    getStock(prodCode) {
        let { stock } = this.state;
        if (prodCode) {
            this.stockService.getStock(prodCode).then(response => {
                stock = response.data;
                if (stock) {
                    if (stock.keywayStock) {
                        stock.keywayStock.dimensionUnitName = stock.keywayStock.dimensionUnitName ? stock.keywayStock.dimensionUnitName : "mm";
                    }
                }
                this.setState({ stock }, () => this.getStockAllocatedToLocation(stock.stockWarehouseLocations, stock.qoh));
            }).catch(error => {
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    getStockAllocatedToLocation(locations, totalQoh) {
        let { unallocatedStock } = this.state;
        const allocatedStock = (locations || []).reduce((acc, location) => acc + location.qoh, 0);
        unallocatedStock = parseFloat((totalQoh - allocatedStock).toFixed(2));
        this.setState({ unallocatedStock });
    }

    getSizeString(length, width, height, dimensionUnitName) {
        let size = "";

        if (!dimensionUnitName) {
            dimensionUnitName = 'NA';
        }
        if (length) {
            size = size + " Length: " + length + " " + dimensionUnitName;
        }
        if (width) {
            size = size + " Width: " + width + " " + dimensionUnitName;
        }
        if (height) {
            size = size + " Height: " + height + " " + dimensionUnitName;
        }
        return size ? size.trim() : "";
    }

    addStock() {
        let { stock, qtyToAdd } = this.state;
        let { locationID, toggle, refresh } = this.props;
        let request = {
            locationID: locationID,
            qty: qtyToAdd,
            prodCode: stock.prodCode
        };
        this.setState({ isLoadingSubmit: true });
        WarehouseLocationTypeService.addStockToLocation(request).then(response => {
            this.setState({ isLoadingSubmit: false });
            toast.success("Added!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            toggle(false, '');
            refresh();
        }).catch(error => {
            this.setState({ isLoadingSubmit: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { stock, unallocatedStock, isLoadingSubmit, hasError, qtyToAdd } = this.state;
        let keywayStock = (stock && stock.keywayStock) ? stock.keywayStock : {};
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen, '')}>
                <ModalHeader toggle={() => toggle(!isOpen, '')}>
                    Add Stock To Location
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Label className={"mr-2"}>
                                Select ProdCode :
                            </Label>
                            <SearchStock
                                handleSelection={(selectedStock) => this.handleChange(selectedStock ? selectedStock.prodCode : "", "prodCode")}
                                selected={stock.prodCode ? stock.prodCode : ""}
                                defaultProdCode={""}
                                filter={{
                                    bmFlag: null,
                                    excludeDiscontinued: null,
                                    excludeSoldOut: null,
                                    stockGroupCodes: '',
                                    stockMasterGroupCodes: [],
                                    colour: "",
                                    excludeOffRange: null,
                                    includeFabric: null,
                                    excludeArchived: null,
                                    excludeOutOfStock: null,
                                }}
                            />
                        </Col>
                    </Row>
                    {
                        keywayStock && keywayStock.prodCode != null ?
                        <Table borderless className={"mb-0"}>
                                <tbody>
                                    <td>
                                        <div>
                                            <small>ProdCode</small>
                                            <div>{keywayStock.prodCode}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>Description</small>
                                            <div>{keywayStock.description}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>Colour</small>
                                            <div>{keywayStock.colour}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>Size</small>
                                            <div>{this.getSizeString(keywayStock.length, keywayStock.width, keywayStock.height, keywayStock.dimensionUnitName)}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <small>QOH</small>
                                            <div>
                                                <NumberFormat
                                                    value={stock.qoh}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={false}
                                                    thousandSeparator={true}
                                                    allowNegative={true} />
                                            </div>
                                        </div>
                                    </td>
                                </tbody>
                        </Table>
                        : null
                    }
                    {
                        keywayStock && keywayStock.prodCode != null ?
                            <Row className={"mt-2"}>
                                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                    <StockEnquiryPageSalesHistoryTabLocation stock={stock} />
                                </Col>
                                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h6 className={"mb-0"}>Stock without Location</h6>
                                        </CardHeader>
                                        <CardBody>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Table size={"sm"} responsive className={"mb-0"}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Quantity</th>
                                                            <td>
                                                                <NumberFormat
                                                                    value={unallocatedStock}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={false}
                                                                    thousandSeparator={true}
                                                                    allowNegative={true} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                    <div className={"mt-2 align-middle"}>
                                        <tr>
                                            <small>Quantity to Add: </small>
                                            <td className="align-middle">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>All &nbsp;
                                                            <Input addon type="checkbox"
                                                                   checked={qtyToAdd === unallocatedStock}
                                                                   onClick={() => this.handleChange(unallocatedStock, "qtyToAdd")} />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="search" name="qtyToAdd" value={qtyToAdd}
                                                           onChange={(e) => this.handleChange(e.target.value, "qtyToAdd")}>
                                                    </Input>
                                                </InputGroup>
                                            </td>
                                        </tr>
                                        <FormText color="danger">{hasError}</FormText>
                                    </div>
                                </Col>
                        </Row>
                        : null
                    }
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <div className="text-right">
                            {keywayStock && keywayStock.prodCode != null ?
                                <Button color={"primary"} size='sm' className={"mr-2"}
                                    disabled={hasError != '' || isLoadingSubmit}
                                    onClick={() => this.addStock()}>
                                    {isLoadingSubmit
                                        ? <Spinner className={"mr-2"} size={"sm"} />
                                        : <i className="fa fa-plus mr-2" />
                                    }
                                    {isLoadingSubmit ? "Adding " : "Add "}stock to Location
                                </Button> : null}
                            <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen, '')}>
                                <i className="fa fa-times mr-2" />
                                Cancel
                            </Button>
                        </div>
                    </Row>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}

AddStockToLocationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    locationID: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired
};