import React, { Component } from 'react';
import { Card, Container } from 'reactstrap';

export default class MaintenancePage extends Component {
    render() {
        return (
            <Container className="d-flex justify-content-center mt-5">
                <Card style={{ width: "75%" }} className="p-3 container rounded">
                    <h3>KEYWAY site is currently unavailable due to a price update that is being applied over the weekend. Site is expected to be available at 20:00 (AEST) on Sunday 21 April 2024.
                        <span><i className="fa fa-cogs fa-spin fa-fw" /></span>
                        <br /><br />Thank you for your understanding.<br /><br />Windoware Team
                    </h3>
                </Card>
            </Container>
        );
    }
}