import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input, Button, BreadcrumbItem, Breadcrumb, Spinner, FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import StockUnitTypeService from '../../services/StockUnitTypeService';
import { handleErrorMessage } from '../../services/CommonService';


export default class ManageStockUnitType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockUnitTypeID: '',
            stockUnitTypeObj: {
                iD: 0,
                name: '',
                description: '',
                measureUnit: '',
                measureUnitType: '',
                conversionFactor:0,
            },
            formErrors: {},
            saving: false,
            loading: false
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        let { stockUnitTypeID } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            stockUnitTypeID = searchParams.stockUnitTypeID;
            this.setState({ stockUnitTypeID }, () => { this.refresh() })
        }

    }

    refresh() {
        if (this.state.stockUnitTypeID) {
            this.setState({ loading: true })

            StockUnitTypeService.getOneStockUnitType(this.state.stockUnitTypeID).then(response => {
                this.setState({ stockUnitTypeObj: response.data, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { stockUnitTypeObj } = this.state;

        stockUnitTypeObj[name] = value;
        this.setState({ stockUnitTypeObj });
    }

    handleCancel() {
        this.props.history.push('/inventory/stock-unit-type');
    }

    validateForm(stockUnitTypeObj) {
        let formErrors = {};
        let valid = true;

        if (stockUnitTypeObj.name === null || (stockUnitTypeObj.name !== null && stockUnitTypeObj.name.length === 0)) {
            formErrors.name = "Please enter name";
            valid = false;
        }

        if (stockUnitTypeObj.measureUnit === null || (stockUnitTypeObj.measureUnit !== null && stockUnitTypeObj.measureUnit.length === 0)) {
            formErrors.measureUnit = "Please enter measure unit";
            valid = false;
        }

        if (stockUnitTypeObj.measureUnitType === null || (stockUnitTypeObj.measureUnitType !== null && stockUnitTypeObj.measureUnitType.length === 0)) {
            formErrors.measureUnitType = "Please enter measure unit type";
            valid = false;
        }

        if (stockUnitTypeObj.conversionFactor === null || stockUnitTypeObj.conversionFactor === '') {
            formErrors.conversionFactor = "Please enter conversion factor";
            valid = false;
        }


        return { valid: valid, formErrors: formErrors };
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.stockUnitTypeObj };
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }
        else {
            this.setState({ saving: true, formErrors: {} });

            StockUnitTypeService.saveOrUpdateStockUnitType(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.stockUnitTypeID) {
                        this.props.history.push('/inventory/stock-unit-type');
                        toast.success("Stock unit type saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    else {
                        toast.success("Stock unit type updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.refresh();

                    }

                    this.setState({ saving: false });
                }

            }).catch(error => {
                this.setState({ saving: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    render() {
        let { stockUnitTypeObj, formErrors, loading, stockUnitTypeID } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/stock-unit-type')}>Stock Unit Type</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!stockUnitTypeID ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <h5>Manage Stock Unit Type</h5>
                    </CardHeader>
                    <CardBody>
                        {loading ? <Spinner color="primary" /> :
                            <Form>
                                <Row>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input
                                                invalid={formErrors.name !== undefined}
                                                name="name"
                                                type="text"
                                                value={stockUnitTypeObj.name || ''}
                                                onChange={this.handleChange}
                                                placeholder="write name here..." />
                                            <FormText color="danger">{formErrors.name}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Measure Unit</Label>
                                            <Input
                                                invalid={formErrors.measureUnit !== undefined}
                                                name="measureUnit"
                                                type="text"
                                                value={stockUnitTypeObj.measureUnit || ''}
                                                placeholder="write measure unit here..."
                                                onChange={this.handleChange} />

                                            <FormText color="danger">{formErrors.measureUnit}</FormText>
                                        </FormGroup>
                                    </Col>


                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Measure Unit Type</Label>
                                            <Input
                                                invalid={formErrors.measureUnitType !== undefined}
                                                name="measureUnitType"
                                                type="text"
                                                value={stockUnitTypeObj.measureUnitType || ''}
                                                placeholder="write measure unit type here..."
                                                onChange={this.handleChange}>
                                            </Input>
                                            <FormText color="danger">{formErrors.measureUnitType}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Conversion Factor Main Qty</Label>
                                            <Input
                                                invalid={formErrors.conversionFactor!== undefined}
                                                name="conversionFactor"
                                                type="number"
                                                value={stockUnitTypeObj.conversionFactor || ''}
                                                placeholder="write conversion factor here..."
                                                onChange={this.handleChange} />
                                            <FormText color="danger">{formErrors.conversionFactor}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                value={this.state.stockUnitTypeObj.description || ''}
                                                onChange={(e) => this.handleChange(e)}
                                                placeholder="write description here...">

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </Row>


                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' onClick={this.handleSubmit} disabled={this.state.saving} >

                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                    style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2"
                                        aria-hidden="true" />}
                                Save</Button>{' '}
                            <Button color='secondary' onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" />
                                Cancel</Button>{' '}</div>
                    </CardFooter>
                </Card>
                <ToastContainer />
            </div>
        );
    }
}
