import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import WarehouseLocationTypeService from "../../services/WarehouseLocationTypeService";
import {handleErrorMessage} from "../../services/CommonService";

export default class WarehouseLocationTypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            warehouseLocTypeData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        description: '',
                        name: '',

                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',

        };
    }

    componentDidMount() {
        this.getWarehouseLocTypes(this.state.warehouseLocTypeData);
    }

    getWarehouseLocTypes(warehouseLocTypeData) {
        this.setState({loading: true});
        WarehouseLocationTypeService.getAllWarehouseLocationTypes(warehouseLocTypeData.request).then(response => {
            warehouseLocTypeData.response = response.data;
            this.setState({warehouseLocTypeData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.description.toLowerCase().includes(searchText);

            if (!flag && item.name) {
                flag = item.name.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleChange(change, key) {
        let {warehouseLocTypeData: warehouseLocTypeData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (warehouseLocTypeData.request.sortRequest.key === change) {
                    warehouseLocTypeData.request.sortRequest.direction = !warehouseLocTypeData.request.sortRequest.direction;
                } else {
                    warehouseLocTypeData.request.sortRequest.key = change;
                    warehouseLocTypeData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getWarehouseLocTypes(warehouseLocTypeData);
                }
                break;
            case "pageSize":
                warehouseLocTypeData.request.pageRequest[key] = parseInt(change);
                warehouseLocTypeData.request.pageRequest.currentPage = 1;
                this.getWarehouseLocTypes(warehouseLocTypeData);
                break;
            case "currentPage":
                warehouseLocTypeData.request.pageRequest[key] = change;
                this.getWarehouseLocTypes(warehouseLocTypeData);
                break;


            default:
                warehouseLocTypeData.request.filterRequest[key] = change;
                warehouseLocTypeData.request.pageRequest.currentPage = 1;
                this.getWarehouseLocTypes(warehouseLocTypeData);
                this.setState({warehouseLocTypeData});
        }

    }

    getWarehouseLocTypeHeading({filterRequest}) {
        let warehouseLocTypeHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",

            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 250,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="name" name="name"
                                        value={filterRequest.name}
                                        onChange={(e) => this.handleChange(e.target.value, "name")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="description" name="description"
                                        value={filterRequest.description}
                                        onChange={(e) => this.handleChange(e.target.value, "description")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                                                               to={{
                                                                   pathname: '/keyway-inventory/warehouse-location-types/manage',
                                                                   state: {warehouseLocTypeID: ""}
                                                               }}><i className="fa fa-plus"/>&nbsp;
                    Add </Link></div>

            }];

        return warehouseLocTypeHeading;
    }


    render() {

        let {warehouseLocTypeData, loading, searchText} = this.state;
        let {pageRequest, sortRequest} = warehouseLocTypeData.request;
        let warehouseLocTypeHeading = this.getWarehouseLocTypeHeading(warehouseLocTypeData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Warehouse Location Type</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader><h5>Warehouse Location Type</h5></CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {warehouseLocTypeData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                   aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(warehouseLocTypeHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(warehouseLocTypeHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(warehouseLocTypeData.response.records || []).filter((warehouseLocType) => this.searchFunction(warehouseLocType, searchText)).map((warehouseLocType, index) =>

                                    <tr key={index}>
                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td>{warehouseLocType.name}</td>
                                        <td>{warehouseLocType.description}</td>
                                        <td className="text-center"><Link className="btn btn-sm btn-primary"
                                                                          to={"/keyway-inventory/warehouse-location-types/manage?" + queryString.stringify({warehouseLocTypeID: warehouseLocType.id})}>
                                            <i className="fa fa-pencil mr-2"
                                               aria-hidden="true"/>
                                            Edit</Link></td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={warehouseLocTypeData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}
