import axios from './axios';

class StockInventoryCostProductionService {

    static Instance() {
        return new StockInventoryCostProductionService();
    }

    getStockInventory_CostProduction(prodCode) {
        return axios.get('api/stock-inventory-cost/stockproductioncost?ProdCode=' + prodCode);
    }

    saveOrUpdateStockInventoryProductionCost(prodCode, req) {
        return axios.post('api/stock-inventory-cost/save/productioncost?prodCode=' + prodCode, req);
    }
}

export default StockInventoryCostProductionService.Instance();