import React, { Component } from 'react';
import {
    Button, FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Table,
} from 'reactstrap';
import { toast } from 'react-toastify';
import SearchDebtorAcccount from '../search/SearchDebtorAcccount';
import ProductDiscountService from '../../services/product/ProductDiscountService';
import CustomerService from '../../services/CustomerService';
import {handleErrorMessage} from "../../services/CommonService";


const productCodesToRemoved = ['CTS','PWD','PPE','ROLS','ROLD'];
export default class ProductDiscountFormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discountList: [],
            accountID: "",
            loadingDiscountLIst: false,
            loadingSave: false

        }

        this.customerService = new CustomerService();
        this.productDiscountService = new ProductDiscountService();
        this.triggerAllUpdate=this.triggerAllUpdate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.form.accountID != this.state.accountID) {
            this.setState({ accountID: nextProps.form.accountID }, () => { this.getDiscount() });
        }
    }


    getDiscount() {
        if (this.state.accountID) {
            this.setState({ loadingDiscountList: true, discountList: [] });
            this.customerService.getDiscount(this.state.accountID).then(response => {
                if (response && response.data) {
                    let discountList = response.data;
                    discountList = discountList.filter(d => !productCodesToRemoved.includes(d.productCode));
                    this.setState({ discountList, loadingDiscountList: false });
                }
            }).catch(error => {
                this.setState({ loadingDiscountList: false });
                toast.error(handleErrorMessage(error));
            });
        }
        else {
            this.setState({ discountList: [] });
        }
    }

    handleChange(change, key, index) {
        if (index > -1) {
            let discountList = this.state.discountList;
            discountList[index][key] = change;
            this.setState({ discountList });
        }
    }

    handleSubmit(item, index) {
        this.setState({ loadingDiscountSave: index });
        this.productDiscountService.saveDiscount(item).then(response => {
            this.props.handleSubmit();
            let discountList = this.state.discountList;
            if (response.data) {
                discountList[index] = response.data;
            }
            toast.success("Saved!");
            this.setState({ discountList, loadingDiscountSave: false });
        }).catch(error => {
            this.setState({ loadingDiscountSave: false });
            toast.error(handleErrorMessage(error));

        })
    }


    triggerAllUpdate(){
        let{discountList}=this.state;
        this.setState({loadingSave: true});
        let UpdateDiscountList = [];
        (discountList || []).map(item => {
            if(!(item.id===0 && item.discount===0))
            {
                UpdateDiscountList.push(item);
            }
        });
        this.productDiscountService.AddUpdateDiscount(UpdateDiscountList).then(response => {
            this.setState({loadingSave: false});
            this.props.handleSubmit();
            this.getDiscount();
            toast.success("Saved!");
        }).catch(error => {
            this.setState({loadingSave: false});
            toast.error(handleErrorMessage(error));
        })
    }

    render() {
        let { isOpen, toggle, loading, products, form, handleChange } = this.props;
        let { loadingDiscountList, discountList, accountID, loadingDiscountSave, loadingSave } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {form.id ? "Edit" : "New"}
                </ModalHeader>
                <ModalBody>
                    <div style={{ minHeight: 300 }}>
                        <div>
                            AccountID
                            <div>
                                <SearchDebtorAcccount
                                    disabled={form.id ? true : false}
                                    handleAccountChange={(accountID) => handleChange(accountID, "accountID")}
                                    selectedAccountID={form.accountID}
                                    defaultAccountID={form.accountID}
                                    includeChildren={true}
                                    excludeClosedandInactive={false} />
                            </div>
                        </div>
                        <br />

                        <div>
                            <Table striped bordered={true} responsive={true} size={"sm"}>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Discount</th>
                                        {//<th>Action </th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadingDiscountList ?
                                        <tr>
                                            <td colSpan={3}><Spinner color="primary" /></td>
                                        </tr>
                                        :
                                        (discountList || []).map((item, index) =>
                                            <tr key={index}>
                                                <td>
                                                    <Input disabled
                                                        type={"select"}
                                                        id="productGroupID" name="productGroupID"
                                                        invalid={(!item.productGroupID)}
                                                        placeholder={"Product"}
                                                        value={item.productGroupID}
                                                        onChange={(e) => this.handleChange(e.target.value, "productGroupID", index)}>
                                                        <option value={""} disabled={true}>Select</option>
                                                        {
                                                            products.map((option, index) =>
                                                                <option key={index} value={option.productGroupId}>{option.name}</option>
                                                            )
                                                        }

                                                    </Input>
                                                    {
                                                        ((!item.productGroupID))
                                                            ? <FormText color={"danger"}>Product Required</FormText>
                                                            : null
                                                    }

                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <Input type={"number"}
                                                            invalid={item.discount < 0 || item.discount > 100}
                                                            placeholder={"discount"}
                                                            name={"discount"}
                                                            value={item.discount}
                                                            onChange={(e) => this.handleChange(e.target.value, "discount", index)} />
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>%</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {
                                                        (item.discount < 0 || item.discount > 100)
                                                            ? <FormText color={"danger"}>Discount should be between 0% to 100%</FormText>
                                                            : null
                                                    }
                                                </td>
                                                {/*<td className="text-center">
                                                    <Button color={"primary"} size={"sm"}
                                                        disabled={index === loadingDiscountSave}
                                                        disabled={(loadingDiscountSave === index || ((!item.productGroupID) || item.discount < 0 || item.discount > 100))}
                                                        onClick={() => this.handleSubmit(item, index)}><i
                                                            className="fa fa-floppy-o mr-2" />
                                                        {loadingDiscountSave === index ?
                                                            <Spinner size="sm"
                                                                style={{ color: "white" }} /> : (item.id ? "Update" : "Save")}
                                                    </Button>
                                                </td>
                                                */}
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </Table>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size={"sm"} onClick={()=>this.triggerAllUpdate()} disabled={loadingSave}>
                            {loadingSave ? <Spinner color={"light"} size="sm" className={"mr-2"} /> :
                                <i className="fa fa-floppy-o mr-2" />}
                            Save
                        </Button>&nbsp;
                        <Button disabled={loading} color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>
                            Close
                        </Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}