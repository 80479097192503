import axios from './axios';

class StockInventoryCostLandedService {

    static Instance() {
        return new StockInventoryCostLandedService();
    }

    getStockInventory_CostLandedObj(prodCode) {
        return axios.get('api/stock-inventory-cost/stocklandedcost?ProdCode=' + prodCode);
    }

    saveOrUpdateStockInventoryLandedCost(prodCode,req) {
        return axios.post('api/stock-inventory-cost/save/landedcost?prodCode=' + prodCode, req);
    }

}

export default StockInventoryCostLandedService.Instance();