import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Table,
    Input,
    Col,
    Row,
    Label
} from 'reactstrap';
import classnames from 'classnames';
import SearchDebtorAcccount from './search/SearchDebtorAcccount';
import CustomerService from "../services/CustomerService";
import StockService from '../services/StockService';
import SweetAlert from 'react-bootstrap-sweetalert';
import cloneDeep from 'lodash/cloneDeep';

export default class DiscountTable extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
            masterGroup: "",
            group: "",
            showMasterGroups: false,
            showGroups: false,
            showGroupName: false,
            showSaveDiscount: false,
            accountID: "",
            discount: "",
            showSuccess: false,
            showError: false,
            showAlert: false,
            basicTitle: '',
            basicType: "default",
            loading: {
                saveDiscount: false,
            },
            discountsForMasterGroups: {},
            isInheritedFromParent: false,
            isDefault: false,
            msg: "",
        };
        this.customerService = new CustomerService();
        this.stockService = new StockService();
        this.handleMasterGroup = this.handleMasterGroup.bind(this);
        this.handleGroup = this.handleGroup.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveDiscount = this.handleSaveDiscount.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleCancelGroup = this.handleCancelGroup.bind(this);
        this.handleCancelDiscount = this.handleCancelDiscount.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        this.customerService.getDiscountForMasterGroups(null).then(data => {
            if (this._isMounted) {
                this.setState({
                    discountsForMasterGroups: data, showMasterGroups: true
                });
            }
        })

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }

        if (tab == 2) {
            this.setState({showMasterGroups: false, showSaveDiscount: false, showGroups: false})
        }
        if (tab == 1) {
            this.customerService.getDiscountForMasterGroups(null).then(data => {

                this.setState({
                    discountsForMasterGroups: data,
                });
            })
            this.setState({showMasterGroups: true, showSaveDiscount: false, showGroups: false})
        }
    }

    handleDebtorAccountDetails(accountId) {

        if (accountId) {

            this.setState({
                accountID: accountId, showGroups: false, showSaveDiscount: false, showMasterGroups: false
            });

            this.customerService.getDiscountForMasterGroups(accountId).then(data => {

                this.setState({
                    discountsForMasterGroups: data, showMasterGroups: true,
                });
            })

        }else{
            this.setState({
                accountID: "", showGroups: false, showSaveDiscount: false, showMasterGroups: false
            });

        }
    }

    handleMasterGroup(masterGroup, discountsForMasterGroups, activeTab) {
        this.setState({masterGroup: masterGroup, isInheritedFromParent: false, isDefault: false})

        if (activeTab == '1') {
            var discount = discountsForMasterGroups[masterGroup].defaultDiscount != 0 ? discountsForMasterGroups[masterGroup].defaultDiscount : "0";
            this.setState({
                showGroups: true,
                showSaveDiscount: true,
                group: null,
                accountID: null,
                discount: discount,
                showGroupName: false
            })
        }
        else if (activeTab == '2') {
            var discount = discountsForMasterGroups[masterGroup].discount != null ? discountsForMasterGroups[masterGroup].discount : discountsForMasterGroups[masterGroup].defaultDiscount;
            this.setState({
                showGroups: true,
                showSaveDiscount: true,
                discount: discount == 0 ? "0" : discount,
                group: null,
                showGroupName: false
            })
        }

    }

    closeAlert() {
        let loading = this.state.loading;
        loading.saveDiscount = false;
        this.setState({showAlert: false, loading: loading})
        this.refresh();
    }


    refresh() {
        if (this.state.activeTab == "1") {
            this.customerService.getDiscountForMasterGroups(null).then(data => {

                this.setState({
                    discountsForMasterGroups: data,
                });
            })
        }
        if (this.state.activeTab == "2") {
            this.customerService.getDiscountForMasterGroups(this.state.accountID).then(data => {

                this.setState({
                    discountsForMasterGroups: data,
                });
            })
        }

    }

    handleGroup(group, discountsForMasterGroups, masterGroup, activeTab) {

        if (activeTab == "1") {
            var discount = discountsForMasterGroups[masterGroup].groups[group].defaultDiscount != 0 ? discountsForMasterGroups[masterGroup].groups[group].defaultDiscount : "0";
            if (discount == 0) {
                this.setState({isInheritedFromParent: true, isDefault: true})
                discount = discountsForMasterGroups[masterGroup].defaultDiscount != 0 ? discountsForMasterGroups[masterGroup].defaultDiscount : "0";
            }
            else {
                this.setState({isInheritedFromParent: false, isDefault: false})
            }
            this.setState({group: group, discount: discount, showGroupName: true})
        }
        else if (activeTab == '2') {
            var discount = discountsForMasterGroups[masterGroup].groups[group].discount == null ? discountsForMasterGroups[masterGroup].groups[group].defaultDiscount : discountsForMasterGroups[masterGroup].groups[group].discount;
            if (discount == 0) {
                this.setState({isInheritedFromParent: true, isDefault: true})
                discount = discountsForMasterGroups[masterGroup].discount == null ? discountsForMasterGroups[masterGroup].defaultDiscount : discountsForMasterGroups[masterGroup].discount;
            }
            else {
                this.setState({isInheritedFromParent: false, isDefault: false})
            }
            this.setState({group: group, discount: discount == 0 ? "0" : discount, showGroupName: true})
        }

        this.setState({showSaveDiscount: true})

    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({discount: value})
    }


    handleSaveDiscount(loading) {

        let loadingCopy = loading;
        loadingCopy.saveDiscount = true
        this.setState({loading: loadingCopy})

        const discount = {
            StockMasterGroupName: this.state.masterGroup,
            StockGroupName: this.state.group,
            Discount: this.state.discount,
            AccountId: this.state.accountID,
        }

        this.customerService.createDiscount(discount).then(reason => {
            this.setState({showAlert: true, basicType: 'success', basicTitle: 'Successfully Saved!', msg: "Success"});
            this.refresh();
        }, error => {
            this.setState({showAlert: true, basicType: 'danger', basicTitle: 'Error', msg: error.Message});
        });


    }

    handleCancelGroup() {
        this.setState({group: null, showGroupName: false})
        var discountsForMasterGroupsCopy = cloneDeep(this.state.discountsForMasterGroups);

        if (this.state.activeTab == "1") {
            var discount = discountsForMasterGroupsCopy[this.state.masterGroup].defaultDiscount != 0 ? discountsForMasterGroupsCopy[this.state.masterGroup].defaultDiscount : "0";
            this.setState({discount: discount, isInheritedFromParent: false, isDefault: false})
        }
        else if (this.state.activeTab == "2") {
            var discount = discountsForMasterGroupsCopy[this.state.masterGroup].discount != null ? discountsForMasterGroupsCopy[this.state.masterGroup].discount : discountsForMasterGroupsCopy[this.state.masterGroup].defaultDiscount;
            this.setState({discount: discount == 0 ? "0" : discount, isInheritedFromParent: false, isDefault: false})
        }

    }

    handleCancelDiscount() {
        this.setState({
            showSaveDiscount: false
        })
    }

    renderMasterGroups(handleMasterGroup, discountsForMasterGroups, activeTab, isInheritedFromParent) {
        return (
            <div style={{cursor: "pointer"}}>
                <Card className="card-absolute">
                    <CardHeader>
                        <h5>Master Groups</h5>
                    </CardHeader>
                    <CardBody>
                        <Table size="sm" striped bordered hover>
                            <tbody>
                            {
                                Object.keys(discountsForMasterGroups).map((masterGroup, val) => (
                                    <tr key={val} hidden={masterGroup.toUpperCase() === "ARCHIVED"}>
                                        <td onClick={handleMasterGroup.bind(this, masterGroup, discountsForMasterGroups, activeTab, isInheritedFromParent)}>
                                            <h6 className="mb-0"><a href="javascript:void(0);"
                                                                    size="sm"> {masterGroup.toUpperCase()}</a></h6>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderGroups(handleGroup, discountsForMasterGroups, masterGroup, activeTab) {
        return (
            <div style={{cursor: "pointer"}}>
                <Card className="card-absolute">
                    <CardHeader>
                        <h5>Groups</h5>
                    </CardHeader>
                    <CardBody>
                        <Table size="sm" striped bordered hover>
                            <tbody>
                            {
                                Object.keys(discountsForMasterGroups[masterGroup].groups).map((group, val) => (
                                    <tr key={val}>
                                        <td onClick={handleGroup.bind(this, group, discountsForMasterGroups, masterGroup, activeTab)}>
                                            <h6 className="mb-0"><a href="javascript:void(0);"
                                                                    size="sm">  {group.toUpperCase()}</a></h6>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderShowGroupName(handleCancelGroup, group) {
        return (
            <div className="pb-2">
                <Label>Group</Label> <span className="float-right" onClick={handleCancelGroup}><i
                className="icon-close"></i></span>
                <Input text="" value={group.toUpperCase() || ''} disabled/>
            </div>
        );
    }

    renderSaveDiscount(group, mastergroup, handleSaveDiscount, handleChange, accountID, loading, discount, handleCancelGroup, showGroup, handleCancelDiscount, isInheritedFromParent, isDefault) {
        let showGroupName = showGroup ? this.renderShowGroupName(handleCancelGroup, group) : null;
        return (
            <div style={{cursor: "pointer"}}>
                <Card className="card-absolute">
                    <CardHeader>
                        <h5>{accountID}</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="pb-2">
                            <Label>MasterGroup</Label>
                            <Input text="" value={mastergroup.toUpperCase() || ''} disabled/>
                        </div>
                        {showGroupName}
                        <div className="pb-2">
                            <Label><span>{isDefault == true ? "Default " : null}</span>Discount <span
                                className="txt-danger"> {isInheritedFromParent == true ? "(Inherited From " + mastergroup.toUpperCase() + ")" : null}</span></Label>
                            <Input type="number" value={discount || ''} onChange={handleChange}/>
                        </div>
                        <div className=" pb-2 text-right">
                            <Button size="sm" className="btn btn-primary center-block" color="secondary"
                                    onClick={handleCancelDiscount}>Cancel</Button><span>&nbsp;</span>
                            <Button size="sm" className="btn btn-primary center-block" color="primary"
                                    onClick={handleSaveDiscount.bind(this, loading)}>{loading.saveDiscount ? "Saving" : "Save"}</Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderSaveDiscountDefault(mastergroup, handleSaveDiscount, handleChange, loading, discount, showGroup, handleCancelGroup, group, handleCancelDiscount, isInheritedFromParent, isDefault) {
        let showGroupName = showGroup ? this.renderShowGroupName(handleCancelGroup, group) : null;
        return (
            <div style={{cursor: "pointer"}}>
                <Card className="card-absolute">
                    <CardHeader>
                        <h5>Discount</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="pb-2">
                            <Label>MasterGroup</Label>
                            <Input text="" value={mastergroup.toUpperCase() || ''} disabled/>
                        </div>
                        {showGroupName}
                        <div className="pb-2">
                            <Label><span>{isDefault == true ? "Default" : null}</span> Discount <span
                                className="txt-danger">{isInheritedFromParent == true ? "(Inherited From " + mastergroup.toUpperCase() + ")" : null}</span></Label>
                            <Input type="number" value={discount || ''} onChange={handleChange}/>
                        </div>
                        <div className=" pb-2 text-right">
                            <Button size="sm" className="btn btn-primary center-block" color="secondary"
                                    onClick={handleCancelDiscount}>Cancel</Button><span>&nbsp;</span>
                            <Button size="sm" className="btn btn-primary center-block" color="primary"
                                    onClick={handleSaveDiscount.bind(this, loading)}>{loading.saveDiscount ? "Saving" : "Save"}</Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }


    render() {
        let masterGroups = this.state.showMasterGroups ? this.renderMasterGroups(this.handleMasterGroup, this.state.discountsForMasterGroups, this.state.activeTab) : null;
        let groups = this.state.showGroups ? this.renderGroups(this.handleGroup, this.state.discountsForMasterGroups, this.state.masterGroup, this.state.activeTab) : null;
        let saveDiscount = this.state.showSaveDiscount ? this.renderSaveDiscount(this.state.group, this.state.masterGroup, this.handleSaveDiscount, this.handleChange, this.state.accountID, this.state.loading, this.state.discount, this.handleCancelGroup, this.state.showGroupName, this.handleCancelDiscount, this.state.isInheritedFromParent, this.state.isDefault) : null;
        let saveDiscountDefault = this.state.showSaveDiscount ? this.renderSaveDiscountDefault(this.state.masterGroup, this.handleSaveDiscount, this.handleChange, this.state.loading, this.state.discount, this.state.showGroupName, this.handleCancelGroup, this.state.group, this.handleCancelDiscount, this.state.isInheritedFromParent, this.state.isDefault) : null;
        return (
            <div>
                <SweetAlert show={this.state.showAlert} type={this.state.basicType} title={this.state.basicTitle}
                            onConfirm={this.closeAlert}> {this.state.msg} </SweetAlert>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '1'})}
                            onClick={() => {
                                this.toggle('1');
                            }}
                        >
                            Default
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '2'})}
                            onClick={() => {
                                this.toggle('2');
                            }}
                        >
                            User
                        </NavLink>
                    </NavItem>
                </Nav>
                <br/>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                {masterGroups}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                {groups}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                {saveDiscountDefault}
                            </Col>
                        </Row>

                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <SearchDebtorAcccount handleAccountChange={this.handleDebtorAccountDetails}
                                    defaultAccountID={this.state.accountID}
                                    includeChildren={true}
                                    excludeClosedandInactive={false} />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                {masterGroups}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                {groups}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                {saveDiscount}
                            </Col>
                        </Row>

                    </TabPane>
                </TabContent>
            </div>
        );
    }

}