import axios from '../axios';
import axiosFileDownloader from '../axiosFileDownloader';
import axiosFileUploader from '../axiosFileUploader';

export default class ProductDiscountService {

    fetchDiscount(request) {
        return axios.post('api/discount/list/product', request)
    }

    fetchDefaultDiscount() {
        return axios.get('api/discount/list/product/default')
    }

    fetchCustomerDiscount(accountID) {
        return axios.get('api/discount/list/product/customer?accountID=' + accountID)
    }

    saveDiscount(data) {
        if (data.id > 0) {
            return this.updateDiscount(data);
        } else {
            delete data.products;
            return this.createDiscount(data);
        }
    }

    createDiscount(requestBody) {
        return axios.post("api/discount/product/create", requestBody)
    }

    updateDiscount(requestBody) {
        return axios.put("api/discount/product/update", requestBody)
    }

    AddUpdateDiscount(ListRequest){
        return axios.put("api/discount/product/Add&Update",ListRequest)
    }

    deleteDiscount(id) {
        return axios.delete("api/discount/product/delete?ID=" + id)
    }

    importProductDiscount(request) {
        return axiosFileUploader.post("api/discount/product/import", request);
    }

    generateProductDiscountImportFormat() {
        return axiosFileDownloader.get('api/discount/product/import/format/generate');
    }

    generateProdcutDiscountExcelReport(request) {
        return axiosFileDownloader.post('api/discount/list/product/excel', request);
    }
}