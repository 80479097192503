import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Label,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table
} from "reactstrap";
import {toast, ToastContainer} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import creditorService from '../../services/purchase/CreditorService';
import {findIndex, getDateString, handleErrorMessage} from '../../services/CommonService';
import SearchCreditorAcccount from "../../components/search/SearchCreditorAcccount";
import NumberFormat from "react-number-format";
import UserService from '../../services/UserService';
import CreditorCategoryStatusDashboard from '../../components/Creditor/CreditorCategoryStatusDashboard';

export default class SwishCreditorPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creditorData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "accountID",
                        direction: true
                    },
                    filterRequest: {
                        accountID: "",
                        company: "",
                        city: "",
                        state: "",
                        category: "Both",
                        status: "Active",
                        area: "Local"
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            hasCreditorWritePrivilege: false
        };
        this.userService = new UserService();
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.getCreditors = this.getCreditors.bind(this);
        this.getPrivilege = this.getPrivilege.bind(this);
    }

    componentDidMount() {
        this.getCreditors(this.state.creditorData);
    }

    getPrivilege() {
        let currentUser = this.userService.getLoggedInUser();
        let hasCreditorWritePrivilege = this.userService.hasPrivilege(currentUser, "creditors-write");
        this.setState({ hasCreditorWritePrivilege });
    }

    getCreditors(creditorData) {
        this.setState({loading: true});
        let req = {...this.state.creditorData.request};
        creditorService.listCreditor(req).then(response => {
            creditorData.response = response.data;
            this.setState({creditorData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        this.getPrivilege();
    }


    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.accountID.toLowerCase().includes(searchText);

            if (!flag && item.company) {
                flag = item.company.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    handleAccountChange(supplier) {
        let {creditorData} = this.state;
        creditorData.request.filterRequest.accountID = supplier.accountID === null ? '' : supplier.accountID;
        creditorData.request.pageRequest.currentPage = 1;
        this.getCreditors(creditorData);
        this.setState({creditorData});
    }

    handleChange(change, key) {
        let {creditorData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (creditorData.request.sortRequest.key === change) {
                    creditorData.request.sortRequest.direction = !creditorData.request.sortRequest.direction;
                } else {
                    creditorData.request.sortRequest.key = change;
                    creditorData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getCreditors(creditorData);
                }
                break;
            case "pageSize":
                creditorData.request.pageRequest[key] = parseInt(change);
                creditorData.request.pageRequest.currentPage = 1;
                this.getCreditors(creditorData);
                break;
            case "currentPage":
                creditorData.request.pageRequest[key] = change;
                this.getCreditors(creditorData);
                break;


            default:
                if (key == "category" && change == "Both") {
                    if (creditorData.request.filterRequest.category == "Both") {
                        change = "Production";
                    }
                }
                creditorData.request.filterRequest[key] = change;
                creditorData.request.pageRequest.currentPage = 1;
                this.getCreditors(creditorData);
                this.setState({creditorData});
        }

    }


    getCreditorsHeading({filterRequest}) {
        let creditorsHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "accountID",
                label: "Account #",
                type: "text",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    {<SearchCreditorAcccount
                        handleAccountChange={(selectedSupplier) => this.handleChange(selectedSupplier , 'accountID')}
                        selectedAccountID={filterRequest.accountID}
                        defaultAccountID={filterRequest.accountID}
                        includeChildren={true}
                        excludeClosedandInactive={false}/>
                    }
                </div>

            },
            {
                key: "company",
                label: "Company",
                type: "text",
                colSpan: 1,
                minWidth: 205,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"company"}
                           value={filterRequest.company || ''}
                           onChange={(e) => this.handleChange(e.target.value, "company")}/>
                </div>

            },
            {
                key: "areaCode",
                label: "Area",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle",
            },
            {
                key: "inActive",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle",
            },
            {
                key: "accountClass",
                label: "Category",
                type: "text",
                colSpan: 1,
                minWidth: 180,
                sorterApplicable: false,
                valueClassName: "align-middle text-right",
                labelClassName: "align-middle",
            },
            {
                key: "currentFYAmount",
                label: "Current FY",
                type: "text",
                colSpan: 1,
                minWidth: 140,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-left",
            },
            {
                key: "lastFYAmount",
                label: "Last FY",
                type: "text",
                colSpan: 1,
                minWidth: 140,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-left",
            },
            {
                key: "skuTotal",
                label: "No. of SKU",
                type: "text",
                colSpan: 1,
                minWidth: 140,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-left",
            }
        ];

        return creditorsHeading;
    }


    render() {

        let { creditorData, loading, searchText, hasCreditorWritePrivilege } = this.state;
        let {pageRequest, sortRequest, filterRequest } = creditorData.request;
        let creditorsHeading = this.getCreditorsHeading(creditorData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Suppliers</BreadcrumbItem>
                </Breadcrumb>
                <CreditorCategoryStatusDashboard />
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Suppliers</h5>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    {hasCreditorWritePrivilege ?
                                        <Link size={"sm"}
                                            className={"btn btn-sm btn-primary mr-2"}
                                            to={"/creditors/manage/one"}>
                                            <i class="fa fa-user-plus mr-2" aria-hidden="true"></i>
                                            Create New Account
                                        </Link> : null}
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className={"mb-4"}>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <div>
                                    <Label>Category</Label>
                                    <div>
                                        <ButtonGroup>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("Production", "category")}
                                                color={"primary"}
                                                outline={filterRequest.category != "Production"}>
                                                Only Production
                                                {filterRequest.category == "Production"
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("Service", "category")}
                                                color={"primary"}
                                                outline={filterRequest.category != "Service"}>
                                                Only Service
                                                {filterRequest.category == "Service"
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("", "category")}
                                                color={"primary"}
                                                outline={filterRequest.category != ""}>
                                                All
                                                {filterRequest.category == ""
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    <br />
                                    <span className="mr-2">
                                        <a href={"javascript:void(0)"}
                                            onClick={() => this.handleChange("Both", "category")}>
                                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                                "fa-check-square-o": filterRequest.category == "Both",
                                                "fa-square-o": filterRequest.category != "Both",
                                            }
                                            )} />
                                        </a>&nbsp;Show Windoware Supplier Only
                                    </span>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mb-2"}>
                                <div>
                                    <Label>Supplier Status</Label>
                                    <div>
                                        <ButtonGroup>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("Active", "status")}
                                                color={"primary"}
                                                outline={filterRequest.status != "Active"}>
                                                Only Active
                                                {filterRequest.status == "Active"
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("Inactive", "status")}
                                                color={"primary"}
                                                outline={filterRequest.status != "Inactive"}>
                                                Only InActive
                                                {filterRequest.status == "Inactive"
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("", "status")}
                                                color={"primary"}
                                                outline={filterRequest.status != ""}>
                                                All
                                                {filterRequest.status == ""
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mb-2"}>
                                <div>
                                    <Label>Area</Label>
                                    <div>
                                        <ButtonGroup>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("Local", "area")}
                                                color={"primary"}
                                                outline={filterRequest.area != "Local"}>
                                                Only Local
                                                {filterRequest.area == "Local"
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("International", "area")}
                                                color={"primary"}
                                                outline={filterRequest.area != "International"}>
                                                Only International
                                                {filterRequest.area == "International"
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                onClick={() => this.handleChange("", "area")}
                                                color={"primary"}
                                                outline={filterRequest.area != ""}>
                                                All
                                                {filterRequest.area == ""
                                                    ? <i className="fa fa-check ml-1"
                                                        aria-hidden="true" />
                                                    : null
                                                }
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {creditorData.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                   aria-hidden="true"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />

                                    </InputGroup>
                                </div>

                            </Col>


                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                <tr>
                                    {(creditorsHeading || []).map((item, index) => {
                                        return (
                                            <th key={index}
                                                onClick={item.sorterApplicable ? () => this.handleChange(item.key, "sortKey") : null}
                                                colSpan={item.colSpan}
                                                className={item.labelClassName}
                                                style={{minWidth: item.minWidth}}>
                                                {item.label}
                                                {
                                                    item.sorterApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                        )} aria-hidden="true"/> : null
                                                }

                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {(creditorsHeading || []).map((item, index) => {
                                        return (
                                            <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    })}
                                </tr>

                                </thead>

                                <tbody>
                                {(creditorData.response.records || []).filter((creditor) => this.searchFunction(creditor, searchText)).map((creditor, index) =>

                                    <tr key={index}>
                                        <td className="align-middle">{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                        <td className="align-middle text-center">
                                            <Link className="btn btn-primary btn-sm"
                                                  to={
                                                      "/creditor/enquiry?" + queryString.stringify({accountID: creditor.accountID})}>
                                                {creditor.accountID}
                                            </Link>
                                        </td>
                                        <td>{creditor.company}</td>
                                        <td>
                                            {
                                                creditor.areaCode === 1
                                                    ?
                                                    "Local"
                                                    :
                                                    null
                                            }
                                            {creditor.areaCode === 2
                                                ?
                                                "International"
                                                :
                                                null
                                            }
                                        </td>
                                        <td>
                                            {
                                                creditor.inActive
                                                    ?
                                                    <Badge color={"secondary"}>In-Active</Badge>
                                                    :
                                                    <Badge color={"primary"}>Active</Badge>
                                            }
                                        </td>
                                        <td>{creditor.accountClass}</td>
                                        <td className="text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={creditor.currentFYAmount}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true} />
                                        </td>
                                        <td className="text-right">
                                            <NumberFormat
                                                prefix={'$'}
                                                value={creditor.lastFYAmount}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true} />
                                        </td>
                                        <td className="text-right">
                                            <NumberFormat
                                                value={creditor.skuTotal}
                                                displayType={'text'}
                                                decimalScale={0}
                                                thousandSeparator={false} />
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={creditorData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}
