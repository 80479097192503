import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import WarehouseLocationService from '../../services/WINOInventory/WarehouseLocationService';
import {handleErrorMessage} from "../../services/CommonService";


export default class SearchWINOWarehouseLocation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouselocation: props.selected ? props.selected : props.defaultSelected,
            warehouseLocations: [],
            loading: { warehouseLocations: false }
        }

        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        let warehouselocation = this.props.selected ? this.props.selected : this.props.defaultSelected;
        let description = "";
        if (warehouselocation) {
            description = warehouselocation.name;
        }
        this.fetchOptions(description);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.warehouseLocation && this.state.warehouselocation) {
            if (nextProps.warehouseLocation.name !== this.state.warehouselocation.name) {
                //load externally selectedItem
                this.fetchOptions(nextProps.warehouseLocation.name);
            }
        }
    }


    fetchOptions(inputValue, callback) {
        let warehouseLocations = [];
        let { warehouselocation, loading } = this.state;

        loading.warehouseLocation = true;
        this.setState({ loading });
        WarehouseLocationService.getWarehouseLocationSummary(inputValue).then(response => {

            let data = response.data;
            for (let i in data) {
                warehouseLocations.push({
                    label: data[i].name + " | " + (data[i].code ? data[i].code : ""),
                    value: data[i].name,
                    id: data[i].id,
                    data: data[i]
                });
                if (warehouselocation) {
                    if (warehouseLocations[i].data.name === warehouselocation.name) {
                        this.handleChange(warehouseLocations[i]); // for default purpose
                    }
                }
            }
            loading.warehouseLocations = false;
            this.setState({ warehouseLocations, loading });
            if (callback) {
                return callback(warehouseLocations)
            } else {
                return warehouseLocations;
            }
        }).catch(error => {
            console.log(error);
            loading.warehouseLocations = false;
            this.setState({ loading });
                alert(handleErrorMessage(error));
        }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            if (inputValue && inputValue.length <= 1) {
                return this.fetchOptions("", callback)
            }
            callback(this.state.warehouseLocations);

        }
    };

    handleChange(change) {
        if (change) {
            WarehouseLocationService.getWarehouseLocationSummary(change.value).then(response => {
                this.setState({ warehouselocation: change });
                this.props.handleSelection(change.data)
            }).catch(error => {
                console.log(error);
                alert(handleErrorMessage(error));
            })
        } else {
            this.setState({ warehouselocation: change });
            this.fetchOptions();
            this.props.handleSelection(change)
        }

    };


    render() {
        let { warehouselocation, loading, warehouseLocations } = this.state;
        return (
            <AsyncSelect
                cacheOptions
                isLoading={loading.warehouseLocations}
                isClearable={true}
                defaultOptions={warehouseLocations}
                loadOptions={this.prepareFetchOptions}
                onChange={this.handleChange}
                value={warehouselocation}
                placeholder={"Search..."}
            />
        )
    }
}