import React, { Component } from 'react';
import { Modal, ModalBody, Button, Row, Col, Spinner } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

export default class ConfirmDescheduledOrderModal extends Component {
    render() {
        let { isOpen, toggle, handleSubmit, loading, primaryMessage, submitColor, icon } = this.props;
        return (
            <Modal isOpen={isOpen} backdrop="static" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalBody>
                    <div className="p-4 text-center">
                        {loading ? <Spinner color={"primary"} /> : icon ? <i className={icon} aria-hidden="true" /> : null}
                        {primaryMessage ? <div>{ReactHtmlParser(primaryMessage)}</div> : null}
                    </div>
                    <Row>
                        <Col>
                            <div className="text-center">
                                <Button color={submitColor} disabled={loading}
                                    onClick={() => handleSubmit()}>
                                    Ok
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}