import React, {Component} from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Spinner, Table} from 'reactstrap';
import {getDateString, handleErrorMessage, changeFormatOfDateString} from '../../services/CommonService';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import {ToastContainer, toast} from 'react-toastify';
import StockService from '../../services/StockService';
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {Link} from "react-router-dom";
import * as queryString from "query-string";
import StockQtyFormat from "./StockQtyFormat";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../store/AppConstants";

export const maxDate = new Date();
export const minDate = moment().subtract(20, 'years').toDate();
const stockTransactionTypes = [
    "Invoice",
    "Receipt",
    "Adjustment",
    "Stock Take",
    "Cut Job",
];
export default class StockTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTable: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "dateAdded",
                        direction: false
                    },
                    filterRequest: {
                        referenceNumber: '',
                        transactionType: '',
                        startDate: moment().subtract(1, 'years').toDate(),
                        endDate: maxDate
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            prodCode: this.props.prodCode,
            material: this.props.material,
        };
        this.stockService = new StockService();
        this.exportData = this.exportData.bind(this);
        this.getStockTransactionData = this.getStockTransactionData.bind(this);
        this.getSalesOrderSwishHeading = this.getSalesOrderSwishHeading.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getStockTransactionData(this.state.dataTable, this.state.prodCode);
    }

    componentWillReceiveProps(nextprops) {
        if (this.state.prodCode !== nextprops.prodCode) {
            this.setState({prodCode: nextprops.prodCode}, function () {
                this.getStockTransactionData(this.state.dataTable, this.state.prodCode);
            });
        }
    }


    getStockTransactionData(dataTable, prodCode) {
        this.setState({loading: true});
        if (prodCode) {
            let request = cloneDeep(dataTable.request);
            if (request.filterRequest.startDate) {
                request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
            }
            if (request.filterRequest.endDate) {
                request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
            }
            request.filterRequest.prodCode = prodCode;

            this.stockService.getStockTransactions(request).then(response => {
                dataTable.response = response.data;
                this.setState({dataTable, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }

    }

    exportData() {
        let {dataTable, prodCode} = this.state;
        let request = cloneDeep(dataTable.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        request.filterRequest.prodCode = prodCode;
        this.setState({loadingExport: true});
        this.stockService.exportStockTransaction(request).then(response => {
            this.setState({loadingExport: false});
            FileSaver.saveAs(response.data, "Stock Transactions" + ".xlsx");
        }).catch(error => {
            this.setState({loadingExport: false});
            toast.error(handleErrorMessage(error));
        });
    };


    getSalesOrderSwishHeading({ filterRequest }) {
        let store = [
            {
                key: "dateAdded",
                label: "Date",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "DD/MM/YYYY", 'DD  MMM YYYY')}</span>;
                }
            },
            {
                key: "transactionType",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "referenceNumber",
                label: "Ref",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    let result;
                    switch (record.transactionType) {
                        case "Invoice":
                            result = (
                                <Link
                                    className="btn btn-primary btn-sm"
                                    style={{ color: "white" }}
                                    title={"Click here to view orders in detail"}
                                    to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}
                                >
                                    {value}
                                </Link>
                            );
                            break;
                        case "Cut Job":
                            result = (
                                <Link
                                    className="btn btn-primary btn-sm"
                                    style={{ color: "white" }}
                                    title={"Click here to view orders in detail"}
                                    to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}
                                >
                                    {value}
                                </Link>
                            );
                            break;
                    }
                    return result;
                }


            },
            {
                key: "qty",
                label: "Qty",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            },
            {
                key: "bomAllocation",
                label: "Allocation",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: this.state.material.includes('FABRIC'),
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    switch (record.transactionType) {
                        case "Cut Job":
                            return <StockQtyFormat
                                eachUnitName={record.eachUnitName}
                                whUnitName={record.whUnitName}
                                whUnitQty={record.whUnitQty}
                                qty={value}
                                qtyType={STOCK_QTY_TYPE.each}
                                format={STOCK_QTY_FORMAT.each}
                                emptyPlaceholderText={"-"}
                                isPoManage={false}
                            />
                        default:
                            return '-';
                    }
                }
            },

            {
                key: "balance",
                label: "Balance",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {

                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            },
            {
                key: "price",
                label: "Price",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    return <NumberFormat value={value}
                                         displayType={'text'}
                                         decimalScale={2}
                                         thousandSeparator={true}
                                         fixedDecimalScale={false}
                                         prefix={'$'}/>;
                }
            },
            {
                key: "cost",
                label: "Cost",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    return <NumberFormat value={value}
                                         displayType={'text'}
                                         decimalScale={2}
                                         thousandSeparator={true}
                                         fixedDecimalScale={false}
                                         prefix={'$'}/>;
                }
            },

            {
                key: "userID",
                label: "User",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-left",
                labelClassName: "hoverableItem align-middle",
                render: function (value, record, order, index, ref) {
                    return <span> {value}</span>;
                }
            },
            {
                key: "notes",
                label: "Notes",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-left",
                labelClassName: "align-middle",
                render: function (value, record, order, index, ref) {
                    return <span> {value}</span>;
                }
            }
        ];

        return store.filter((item) => item.showColumn);
    }


    handleChange(change, key) {
        let {dataTable} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (dataTable.request.sortRequest.key === change) {
                    dataTable.request.sortRequest.direction = !dataTable.request.sortRequest.direction;
                } else {
                    dataTable.request.sortRequest.key = change;
                    dataTable.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getStockTransactionData(dataTable, this.state.prodCode);
                }
                break;
            case "pageSize":
                dataTable.request.pageRequest[key] = change;
                dataTable.request.pageRequest.currentPage = 1;
                this.getStockTransactionData(dataTable, this.state.prodCode);
                break;
            case "currentPage":
                dataTable.request.pageRequest[key] = change;
                this.getStockTransactionData(dataTable, this.state.prodCode);
                break;
            default:
                dataTable.request.filterRequest[key] = change;
                dataTable.request.pageRequest.currentPage = 1;
                this.getStockTransactionData(dataTable, this.state.prodCode);
                this.setState({dataTable});
        }

    }

    render() {
        let {dataTable, loading, loadingExport} = this.state;
        let { sortRequest, pageRequest, filterRequest } = dataTable.request;
        let store = this.getSalesOrderSwishHeading(dataTable.request);

        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true"/>&nbsp;Start Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.startDate}
                                onChange={date => this.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>


                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.endDate}
                                onChange={date => this.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate} // Minimum date should be the selected start date
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>

                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Type</Label>
                        <div className={"text-center"}>
                            <Input type="select" id="transactionType" name="transactionType"
                                   value={filterRequest.transactionType || ''}
                                   onChange={(e) => this.handleChange(e.target.value, "transactionType")}
                                   placeholder="Search...">
                                <option value="">Select</option>
                                {
                                    stockTransactionTypes.map(stt => {
                                        return <option value={stt}>{stt}</option>
                                    })
                                }
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Ref.</Label>
                        <div className={"text-center"}>
                            <Input type="search" id="referenceNumber" name="referenceNumber"
                                   value={filterRequest.referenceNumber || ''}
                                   onChange={(e) => this.handleChange(e.target.value, "referenceNumber")}
                                   placeholder="Search...">

                            </Input>
                        </div>
                    </Col>
                </Row>

                <div className={"d-flex justify-content-between align-items-center"}>
                    {loading ? (
                        <Spinner color='primary' size='sm' />
                    ) : (
                        <p className={"mb-1"}>
                            Showing{' '}
                            {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                            {' '}of {dataTable.response.totalRecords}
                            {' '}entries
                        </p>
                    )}
                    <Button
                        size={"sm"}
                        color={"success"}
                        onClick={this.exportData}
                        disabled={loadingExport}
                        className={"mr-2"}
                    >
                        {loadingExport ? (
                            <Spinner color={"light"} size={"sm"} className={"mr-2"} />
                        ) : (
                            <i className={"fa fa-file-excel-o mr-2"} />
                        )}
                        Export
                    </Button>
                  
                </div>




                <Table striped bordered responsive hover size='sm'>
                    <thead>
                    <tr>
                        {(store || []).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}>
                                    {item.label}
                                    {
                                        item.sorterApplicable ?
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (sortRequest.key !== item.key),
                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                }
                                            )} aria-hidden="true"/> : null
                                    }
                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {(dataTable.response.records || []).map((record, i) => {
                        return (
                            <tr key={i}>
                                {(store || []).map((item, index) => {
                                    return (
                                        <td key={index} className={item.valueClassName}>
                                            {item.render(record[item.key], record, i, dataTable.response.records, this)}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>

                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{maxWidth: 200}}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={dataTable.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>
                <ToastContainer/>
            </div>
        );
    }
}