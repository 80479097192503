import React, {Component} from 'react';
import {
    Modal, ModalBody, ModalHeader, ModalFooter,
    Button,
    Label, Input, Spinner
} from 'reactstrap';
import ProductionScheduleService from '../../services/production/ProductionScheduleService';
import {toast, ToastContainer} from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import {handleErrorMessage} from '../../services/CommonService';

export default class ProductionDescheduleNotesUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salesOrder: {},
        }
    }

    componentDidMount() {
        this.setState({salesOrder: cloneDeep(this.props.salesOrder)});
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.salesOrder !== nextProps.salesOrder) {
            this.setState({salesOrder: cloneDeep(nextProps.salesOrder)});
        }
    }

    handleChange(key, value) {
        let {salesOrder} = this.state;
        salesOrder[key] = value;
        this.setState({salesOrder});
    }

    updateProductionDescheduleNotes(salesOrder) {
        let req = {
            salesOrderJobID: salesOrder.salesOrderJobID,
            notes: salesOrder.productionDescheduleNotes,
        };

        salesOrder.isUpdatingProductionDescheduleNotes = true;
        this.setState({salesOrder});

        ProductionScheduleService.updateProductionDescheduleNotes(req).then(response => {
            salesOrder.productionDescheduleNotes = response.data.notes;
            salesOrder.isUpdatingProductionDescheduleNotes = false;
            this.setState({salesOrder});
            this.props.handleProductionDescheduleNotes(salesOrder);
            toast.success("Saved");
        }).catch(error => {
            salesOrder.isUpdatingProductionDescheduleNotes = false;
            this.setState({salesOrder});
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {salesOrder} = this.state;

        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Order No. {salesOrder.ordNum}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Label>Notes</Label>
                        <Input
                            type={"textarea"}
                            name={"productionDescheduleNotes"}
                            value={salesOrder.productionDescheduleNotes}
                            onChange={(e) => this.handleChange("productionDescheduleNotes", e.target.value)}
                        />

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={'primary'}
                                size='sm'
                                className={"mr-2"}
                                disabled={salesOrder.isUpdatingProductionDescheduleNotes}
                                onClick={() => this.updateProductionDescheduleNotes(salesOrder)}>
                            {salesOrder.isUpdatingProductionDescheduleNotes
                                ? <Spinner className={"mr-2"} color={"light"} size={"sm"}/>
                                : <i className='fa fa-floppy-o mr-2'/>
                            }
                            {salesOrder.isUpdatingProductionDescheduleNotes
                                ? "Saving"
                                : "Save"
                            }
                        </Button>
                        <Button color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>
                            <i className='fa fa-times mr-2'/>Close</Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}