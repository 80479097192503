import React, {Component} from 'react';
import {
    Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody, Spinner
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import StockService from "../../services/StockService";
import { handleErrorMessage, searchWordInString} from "../../services/CommonService";
import NumberFormat from "react-number-format";

export default class StockKeepingUnits extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockKeepingUnitData: [],
            totalDataOfStockKeepingUnit: {},
            isLoading: false,
        };
        this.stockService = new StockService();

    }

    componentDidMount() {
        this.getStockKeepingData();
    }

    getStockKeepingData() {
        let { stockKeepingUnitData } = this.state;
        //let arr = ["ROLLER BLIND", "VERTICAL INDOOR", "VENETIAN", "PANEL TRACK",
        //    "ZIPTRAK EXTERIOR", "ZIPTRAK INTERIOR", "VERTICAL OUTDOOR", "FOLDING ARM AWNINGS", "HOSPITAL RANGE", "CURTAIN TRACKS", "ROMAN BLIND","WIDESCREEN",
        //    "FABRIC OUTDOOR", "FABRIC VERT INDOOR", "FABRIC INDOOR", "CONSUMABLES", "MISCELLANNEOUS", "SHP CUT TO SIZE", "AWNING PATIO","DEFAULT"];
        let total = 0;
        this.setState({isLoading: true});
        this.stockService.getStockKeepingUnit().then(response => {
            //let data = response.data;
            //for (let i = 0; i < arr.length; i++) {
            //    let obj = data.reduce((acc, groupName) => {
            //        if (groupName.stockGroupName===arr[i]) {
            //            acc.range = groupName.range;
            //            acc.outofStock = groupName.outofStock;
            //            acc.rangeDiscontinue = groupName.rangeDiscontinue;
            //            acc.offRange = groupName.offRange
            //        }
            //        return acc;
            //    }, {
            //        stockGroupName: arr[i],
            //        range: 0,
            //        outofStock: 0,
            //        rangeDiscontinue: 0,
            //        offRange: 0
            //    });
            //    stockKeepingUnitData.push(obj);
            //}
            // this.getStockKeepingUnitFabricData();
            stockKeepingUnitData = response.data;
            this.calculatingRowData(stockKeepingUnitData);
            this.setState({ stockKeepingUnitData, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    getStockKeepingUnitFabricData() {
        let { stockKeepingUnitData } = this.state;
        this.stockService.getStockKeepingUnitFabricData().then(response => {
            let data = response.data;
            (data || []).forEach((item, index) => {
                stockKeepingUnitData.push(item);
            })
            this.calculatingRowData(stockKeepingUnitData);
            this.setState({ stockKeepingUnitData });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    calculatingRowData(stockKeepingUnitData) {
        let totalDataOfStockKeepingUnit = {}, totalRange = 0, totalRangeOutofStock = 0, totalOffRange = 0, totalRangeDiscontinue = 0, totalIndex = 0, total = 0;
        let data = stockKeepingUnitData;
        data.forEach(totalValuesData => {
            totalRange += totalValuesData.range;
            totalRangeOutofStock += totalValuesData.outofStock;
            totalRangeDiscontinue += totalValuesData.rangeDiscontinue;
            totalOffRange += totalValuesData.offRange;
            total += totalValuesData.range  + totalValuesData.offRange;
        });
        totalDataOfStockKeepingUnit = { totalRange: totalRange, totalRangeOutofStock: totalRangeOutofStock, totalRangeDiscontinue: totalRangeDiscontinue,  totalOffRange: totalOffRange, totalIndex: data.length, total: total };
        this.setState({ totalDataOfStockKeepingUnit: totalDataOfStockKeepingUnit });

    }

    getStockKeepingUnitHeading() {
        let stockHeading = [
            {
                key: "products",
                label: "Parent Stock Group",
                type: "text",
                colSpan: 1,
                rowSpan: 2,
                minWidth: 300,
                sorterApplicable: true,
                labelClassName: "align-middle text-left"
            },
            {
                key: "rangeParentTab",
                label: "Range",
                type: "text",
                colSpan: 3,
                rowSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                labelClassName: "align-middle text-center"
            },
            {
                key: "offRangeParentTab",
                label: "Off Range",
                type: "text",
                rowSpan: 1,
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                labelClassName: "align-middle text-center"
            },
            {
                key: "total",
                label: "Total",
                type: "text",
                rowSpan: 2,
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                labelClassName: "text-right align-middle"
            }
        ];

        return stockHeading;
    }


    render() {
        let { stockKeepingUnitData, totalDataOfStockKeepingUnit, isLoading} = this.state;
        let stockKeepingUnitHeading = this.getStockKeepingUnitHeading();
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Overview</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className={"text-left"}>
                                    <h5>Stock Overview</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {
                            isLoading ?
                                < Spinner color={"primary"} />
                                :
                                <Table striped bordered responsive hover size='sm'>
                                    <thead>
                                        <tr>
                                            {(stockKeepingUnitHeading || []).map((item, index) => {
                                                return (
                                                    <th key={index}
                                                        colSpan={item.colSpan}
                                                        rowSpan={item.rowSpan}
                                                        className={item.labelClassName}
                                                        style={{ minWidth: item.minWidth }}>
                                                        {item.label}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <th className="align-middle text-right" colSpan="1">Total</th>
                                            <th className="align-middle text-right" colSpan="1">Out of Stock</th>
                                            <th className="align-middle text-right" colSpan="1">Discontinued</th>
                                            <th className="align-middle text-right" colSpan="1">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(stockKeepingUnitData || []).map((sku, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{sku.stockGroupName}</td>
                                                    <td className={"text-right"}>
                                                        {
                                                            sku.range
                                                                ? <NumberFormat
                                                                    value={sku.range}
                                                                    displayType={'text'}
                                                                    decimalScale={0}
                                                                    thousandSeparator={true}
                                                                />
                                                                : '-'
                                                        }
                                                    </td>
                                                    <td className={"text-right"}>
                                                        {
                                                            sku.outofStock
                                                                ? <NumberFormat
                                                                    value={sku.outofStock}
                                                                    displayType={'text'}
                                                                    decimalScale={0}
                                                                    thousandSeparator={true}
                                                                />
                                                                : '-'
                                                        }
                                                    </td>
                                                    <td className={"text-right"}>
                                                        {
                                                            sku.rangeDiscontinue
                                                                ? <NumberFormat
                                                                    value={sku.rangeDiscontinue}
                                                                    displayType={'text'}
                                                                    decimalScale={0}
                                                                    thousandSeparator={true}
                                                                />
                                                                : '-'
                                                        }
                                                    </td>
                                                    <td className={"text-right"}>
                                                        {
                                                            sku.offRange
                                                                ? <NumberFormat
                                                                    value={sku.offRange}
                                                                    displayType={'text'}
                                                                    decimalScale={0}
                                                                    thousandSeparator={true}
                                                                />
                                                                : '-'
                                                        }
                                                    </td>
                                                    <th className={"text-right"}>
                                                        {
                                                            (sku.range + sku.offRange)
                                                                ? <NumberFormat
                                                                    value={sku.range + sku.offRange}
                                                                    displayType={'text'}
                                                                    decimalScale={0}
                                                                    thousandSeparator={true}
                                                                />
                                                                : '-'
                                                        }
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td><b>Total</b></td>
                                            <th className={"text-right"}>
                                                {
                                                    totalDataOfStockKeepingUnit.totalRange
                                                        ? <NumberFormat
                                                            value={totalDataOfStockKeepingUnit.totalRange}
                                                            displayType={'text'}
                                                            decimalScale={0}
                                                            thousandSeparator={true}
                                                        />
                                                        : '-'
                                                }
                                            </th>
                                            <th className={"text-right"}>
                                                {
                                                    totalDataOfStockKeepingUnit.totalRangeOutofStock
                                                        ? <NumberFormat
                                                            value={totalDataOfStockKeepingUnit.totalRangeOutofStock}
                                                            displayType={'text'}
                                                            decimalScale={0}
                                                            thousandSeparator={true}
                                                        />
                                                        : '-'
                                                }
                                            </th>
                                            <th className={"text-right"}>
                                                {
                                                    totalDataOfStockKeepingUnit.totalRangeDiscontinue
                                                        ? <NumberFormat
                                                            value={totalDataOfStockKeepingUnit.totalRangeDiscontinue}
                                                            displayType={'text'}
                                                            decimalScale={0}
                                                            thousandSeparator={true}
                                                        />
                                                        : '-'
                                                }
                                            </th>
                                            <th className={"text-right"}>
                                                {
                                                    totalDataOfStockKeepingUnit.totalOffRange
                                                        ? <NumberFormat
                                                            value={totalDataOfStockKeepingUnit.totalOffRange}
                                                            displayType={'text'}
                                                            decimalScale={0}
                                                            thousandSeparator={true}
                                                        />
                                                        : '-'
                                                }
                                            </th>
                                            <th className={"text-right"}>
                                                {
                                                    totalDataOfStockKeepingUnit.total
                                                        ? <NumberFormat
                                                            value={totalDataOfStockKeepingUnit.total}
                                                            displayType={'text'}
                                                            decimalScale={0}
                                                            thousandSeparator={true}
                                                        />
                                                        : '-'
                                                }
                                            </th>
                                        </tr>
                                    </tbody>
                                </Table>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

}