import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';

import StockManageService from '../../services/StockManageService';
import { toast } from 'react-toastify';
import {handleErrorMessage} from "../../services/CommonService";

export default class SearchWINOnlineStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stocks: [],
            prodCode: props.defaultProdCode,
            selected: {},
            loading: { stocks: false },
        }

        this.stockManageService = new StockManageService();
        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.prodCode);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedProdCode) {
            if (nextProps.selectedProdCode !== this.state.prodCode) {
                //load externally selectedItem
                this.fetchOptions(nextProps.selectedProdCode);
            }
        }
    }


    fetchOptions(inputValue, callback) {
        let stocks = [];
        let { loading} = this.state;

        loading.stocks = true;
        this.setState({ loading });
        this.stockManageService.getStocks(inputValue).then(response => {

            let data = response.data;
            for (let i in data) {
                stocks.push({
                    label: data[i].prodCode + " | " + (data[i].description ? data[i].description : ""),
                    value: data[i].prodCode,
                    id: data[i].id,
                    data: data[i]
                });

                if (this.props.defaultProdCode) {
                    if (stocks[i].data.prodCode === this.props.defaultProdCode) {
                        this.handleChange(stocks[i]); // for default purpose
                    }
                }
            }
            loading.stocks = false;
            this.setState({stocks, loading});
            if (callback) {
                return callback(stocks)
            } else {
                return stocks;
            }
        }).catch(error => {
                loading.stocks = false;
                this.setState({loading});
                toast.error(handleErrorMessage(error));
            }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            if (inputValue && inputValue.length <= 1) {
                return this.fetchOptions("", callback)
            }
            callback(this.state.stocks);

        }
    };

    handleChange(change) {
        this.setState({ selected: change });
        if (change) {
            this.setState({ selected: change, prodCode: change.value });
            this.props.handleSelection(change.value)
        } else {
            this.setState({ selected: change, prodCode:"" });
            this.fetchOptions();
            this.props.handleSelection(change)
        }

    };


    render() {
        let { loading, stocks, selected } = this.state;
        return (
            <AsyncSelect
                cacheOptions
                isDisabled={this.props.isDisabled}
                isLoading={loading.stocks}
                isClearable={true}
                defaultOptions={stocks}
                loadOptions={this.prepareFetchOptions}
                onChange={this.handleChange}
                value={selected}
                placeholder={"Search..."}
            />
        )
    }
}