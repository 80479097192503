import axios from "./axios";

class PowdercoatingService {

    static Instance() {
        return new PowdercoatingService();
    }

    getPowdercoatingOrders(request, statusType) {
        return axios.post("api/sales/order/production/job/powdercoat/purchase/order/form/list?statusType=" + statusType, request)
    }

    getPowdercoatingOrderByPurchaseOrdNum(purchaseOrdNum) {
        return axios.get("api/sales/order/production/job/powdercoat/purchase/order/by/ordNum?PurchaseOrderNum=" + purchaseOrdNum);
    }

    updatePowdercoatingPurchaseOrderStatus(purchaseOrdNum, Status) {
        return axios.post("api/sales/order/production/job/powdercoat/update/purchase/order/status?PurchaseOrderNum=" + purchaseOrdNum + "&Status=" + Status);
    }

    fetchPowdercoatingOrderStatus(purchaseOrdNum) {
        return axios.get("api/sales/order/production/job/powdercoat/purchase/order/status/by/ordNum?PurchaseOrderNum=" + purchaseOrdNum);
    }

    getDateAfterScheduleOrderQuick(request) {
        return axios.post("api/sales/order/production/job/powdercoat/schedule/order/quick/date", request);
    }
}

export default PowdercoatingService.Instance()