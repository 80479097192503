import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Row,
    Spinner, Table,
    BreadcrumbItem,
    Breadcrumb
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import FileSaver from 'file-saver';
import classnames from "classnames";
import freightService from '../../services/FreightService';
import { handleErrorMessage } from '../../services/CommonService';


export default class FreightRuleImportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDryRun: false,
            isLoadingImport: false,
            isLoadingDownload: false,
            importResponse: {}
        };

        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.downloadFreightRuleTemplate = this.downloadFreightRuleTemplate.bind(this);
        this.importFreightRules = this.importFreightRules.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(key, change) {
        this.setState({ [key]: change });
    }

    fileChangeHandler(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    importFreightRules() {
        if (!this.state.selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('isDryRun', this.state.isDryRun);
        this.setState({ isLoadingImport: true });
        freightService.importFrieghtRule(data).then(response => {
            this.setState({ isLoadingImport: false, importResponse: response.data });
            if (response.data.hasError) {
                toast.error("Error!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            } else {
                toast.success("Success!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }).catch(error => {
            this.setState({ isLoadingImport: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    downloadFreightRuleTemplate() {
        this.setState({ isLoadingDownload: true });

        freightService.generateFreightRuleTemplate().then(response => {
            this.setState({ isLoadingDownload: false });
            FileSaver.saveAs(response.data , "FreightRuleImportTemplate.xlsx");
        }).catch(error => {
            this.setState({ isLoadingDownload: false });
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let { isLoadingDownload, isLoadingImport, isDryRun, importResponse } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/manage/freight/rule')}>Freight Rule
                    </BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Import</BreadcrumbItem>
                </Breadcrumb>
            <Card>
                <CardHeader>
                    <h5>Import freight rules</h5>
                </CardHeader>
                <CardBody>
                    <div>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Input type="file"
                                    name="file"
                                    id="file"
                                    onChange={this.fileChangeHandler}
                                    aria-label="Upload document" />
                            </Col>
                        </Row>
                        <div className="text-right">
                            <Button color={"link"} size={"sm"}
                                onClick={() => this.handleChange("isDryRun", !isDryRun)}>
                                <i className={classnames("mr-2", "fa", {
                                    "fa-check-square-o": isDryRun,
                                    "fa-square-o": !isDryRun
                                })} />
                                            Dry run
                                        </Button>
                            <Button color={"primary"} size={"sm"}
                                    onClick={() => this.importFreightRules()}
                                    disabled={isLoadingImport}>
                                    {isLoadingImport
                                    ? <Spinner size="sm"
                                        className={"mr-2"}
                                        color={"white"} />
                                    : <i className="fa fa-upload mr-2" />
                                }
                                            Upload</Button>&nbsp;
                                        <Button color={"primary"} size={"sm"}
                                outline={true}
                                onClick={() => this.downloadFreightRuleTemplate()}
                                    disabled={isLoadingDownload}>{isLoadingDownload
                                    ? <Spinner size="sm"
                                        className={"mr-2"}
                                        color={"primary"} />
                                    : <i className="fa fa-download mr-2" />
                                }Download template</Button>
                        </div>

                        {
                            importResponse.hasError
                                ? <div>
                                    <hr />
                                    <Table size={"sm"} hover={true} responsive>
                                        <thead>
                                            <tr>
                                                <td>Row</td>
                                                <td>Column</td>
                                                <td>Title</td>
                                                <td>Value</td>
                                                <td>Validation Message</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {((importResponse.rows) || []).map((row ,index)=> {
                                                return <tr key={index}
                                                    className={row.hasError ? "table-danger" : "table-success"}>
                                                    <td>{row.rowIndex}</td>
                                                    <td>{row.columnIndex}</td>
                                                    <td>{row.inputTitle}</td>
                                                    <td>{row.inputValue}</td>
                                                    <td>{row.validationMessage}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                : null
                        }

                    </div>

                </CardBody>
            </Card>
            <br />
            <ToastContainer />
        </div>
        )
    }
}