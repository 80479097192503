import React, {Component} from "react";
import { toast } from "react-toastify";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Col, FormGroup, FormText } from 'reactstrap';
import { handleErrorMessage } from "../../services/CommonService";
import cloneDeep from 'lodash/cloneDeep';
import GLCurrencyPage from '../../pages/stock/GLCurrencyPage';
import currencyService from '../../services/CurrencyService';

const initialCurrencyValues = {
    currencyType: '',
    description: '',
    salesGLAccount: '',
    bankGLAccount: '',
    accountingExchangeRate: '',
    costingExchangeRate: '',
    currencySymbol: ''
};
export default class ManageCurrencyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            selectedCurrency: cloneDeep(initialCurrencyValues),
            formErrors: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.saveCurrency = this.saveCurrency.bind(this);
    }

    componentDidMount() {
        let { selectedCurrency } = this.state;
        if (this.props.selectedCurrency) {
            selectedCurrency = cloneDeep(this.props.selectedCurrency);
        }
        this.setState({ selectedCurrency });
    }

    validateForm(item) {
        let formErrors = {};
        let valid = true;

        if (!item.currencyType) {
            formErrors.currencyType = 'Please enter Currency Type';
            valid = false;
        }

        if (!item.description) {
            formErrors.description = 'Please enter Description';
            valid = false;
        }

        if (!item.salesGLAccount) {
            formErrors.salesGLAccount = 'Please enter Sales Account';
            valid = false;
        }

        if (!item.bankGLAccount) {
            formErrors.bankGLAccount = 'Please enter Bank Account';
            valid = false;
        }

        if (!item.accountingExchangeRate) {
            formErrors.accountingExchangeRate = 'Please enter Accounting Exchange Rate';
            valid = false;
        }

        if (!item.costingExchangeRate) {
            formErrors.costingExchangeRate = 'Please enter Costing Exchange Rate';
            valid = false;
        }

        if (!item.currencySymbol) {
            formErrors.currencySymbol = 'Please enter Currency Symbol';
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    handleChange(key, value) {
        let { selectedCurrency } = this.state;
        selectedCurrency[key] = value;
        this.setState({ selectedCurrency });
    }


    saveCurrency() {

        let { selectedCurrency } = this.state;
        let { toggle, isOpen, refreshAgain } = this.props;
        let result = this.validateForm(selectedCurrency);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }

        selectedCurrency.accountingExchangeRate = parseFloat(selectedCurrency.accountingExchangeRate).toFixed(4);

        selectedCurrency.costingExchangeRate = parseFloat(selectedCurrency.costingExchangeRate).toFixed(4);

        this.setState({ saving: true, formErrors: {} });
        currencyService.saveCurrency(selectedCurrency).then(response => {
            if (response.status === 200 || response.status === "200") {
                this.setState({ saving: false });
                toggle(!isOpen);
                toast.success("Saved successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                refreshAgain();
            }
        }).catch(error => {
            this.setState({ saving: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { selectedCurrency, saving, formErrors } = this.state;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    {selectedCurrency && selectedCurrency.currencyType ? 'Edit' : 'Add'} Currency
                </ModalHeader>
                <ModalBody>
                            <FormGroup>
                                <Label for="currencyType">Currency Type*</Label>
                                <Input type="text" name="currencyType"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedCurrency ? selectedCurrency.currencyType : null}
                                    placeholder="Enter Currency Type here" />
                                <FormText color="danger">{formErrors.currencyType}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">Description*</Label>
                                <Input type="text" name="description"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedCurrency ? selectedCurrency.description : null}
                                    placeholder="Enter Description here" />
                                <FormText color="danger">{formErrors.description}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="salesGLAccount">Sales Account*</Label>
                                <Input type="text" name="salesGLAccount"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedCurrency ? selectedCurrency.salesGLAccount : null}
                                    placeholder="Enter Sales Account here" />
                                <FormText color="danger">{formErrors.salesGLAccount}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="bankGLAccount">Bank Account*</Label>
                                <Input type="text" name="bankGLAccount"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedCurrency ? selectedCurrency.bankGLAccount : null}
                                    placeholder="Enter Bank Account here" />
                                <FormText color="danger">{formErrors.bankGLAccount}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="accountingExchangeRate">Accounting Exchange Rate*</Label>
                                <Input type="text" name="accountingExchangeRate"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedCurrency ? selectedCurrency.accountingExchangeRate : null}
                                    placeholder="Enter Accounting Exchange Rate here" />
                                <FormText color="danger">{formErrors.accountingExchangeRate}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="costingExchangeRate">Costing Exchange Rate*</Label>
                                <Input type="text" name="costingExchangeRate"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedCurrency ? selectedCurrency.costingExchangeRate : null}
                                    placeholder="Enter Costing Exchange Rate here" />
                                <FormText color="danger">{formErrors.costingExchangeRate}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="currencySymbol">Currency Symbol*</Label>
                                <Input type="text" name="currencySymbol"
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    value={selectedCurrency ? selectedCurrency.currencySymbol : null}
                                    placeholder="Enter Currency Symbol here" />
                                <FormText color="danger">{formErrors.currencySymbol}</FormText>
                            </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.saveCurrency()} disabled={saving}>
                            {saving
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />}
                            {selectedCurrency && selectedCurrency.currencyType ? 'Update' : 'Add'}</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times" />&nbsp;
                            Cancel</Button>

                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}