import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    CardFooter,
    FormText,
    Form,
    Alert,
    InputGroup,
    InputGroupText,
    InputGroupAddon, Nav, NavItem, NavLink
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import queryString from 'query-string';
import StockInventoryMainUnitView from '../../components/WINOInventory/StockInventoryMainUnitView';
import StockInventoryPriceService from '../../services/StockInventoryPriceService';
import StockInventoryQuickLinks from '../../components/WINOInventory/StockInventoryQuickLinks';
import CurrencyService from "../../services/CurrencyService";
import classnames from "classnames";

export default class ManageStockInventoryPrice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prodCode: '',
            stockInventoryPrice: {
                id: 0,
                marginRate: 0,
                marginValue: 0,
                price: 0
            },
            formErrors: {},
            saving: false,
            loading: false,
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }
    componentDidMount() {
        let prodCode = '';
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            prodCode = searchParams.prodCode;
            this.setState({prodCode}, () => {
                this.refresh()
            })
        }
    }
    refresh() {
        if (this.state.prodCode) {
            this.setState({loading: true})
            StockInventoryPriceService.getOnestockInventoryPriceByStock(this.state.prodCode).then(response => {
                if (response.status === 200 || response.status === "200") {
                    let stockInventoryPrice = response.data;
                    if (response.data === null) {
                        stockInventoryPrice = {
                            id: 0,
                            marginRate: 0,
                            marginValue: 0,
                            price: 0
                        }
                    }
                    this.setState({stockInventoryPrice, loading: false});
                }
                console.log(response.data, '--------------response.data Price----------')
            }).catch(error => {
                this.setState({loading: false});
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {stockInventoryPrice} = this.state;

        stockInventoryPrice[name] = value;
        this.setState({stockInventoryPrice});
        //this.props.handleChange(stockInventoryPrice, false);
    }

    validateForm(stockInventoryPrice) {
        let formErrors = {};
        let valid = true;

        if (stockInventoryPrice.marginRate === null || (stockInventoryPrice.marginRate === undefined)) {
            formErrors.marginRate = "Please enter marginRate";
            valid = false;
        }

        if (stockInventoryPrice.marginValue === null || (stockInventoryPrice.marginValue === undefined)) {
            formErrors.marginValue = "Please enter marginValue";
            valid = false;
        }

        if (stockInventoryPrice.price === null || (stockInventoryPrice.price === undefined)) {
            formErrors.price = "Please enter price";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.stockInventoryPrice};
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            this.setState({saving: true, formErrors: {}});
            StockInventoryPriceService.saveOrUpdateStockInventoryPrice(this.state.prodCode, req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    //console.log(response, '----------InventoryPrice--------------');
                    if (!this.state.stockInventoryPrice.id) {
                        toast.success("Stock Inventory Price saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        toast.success("Stock Inventory Price updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    this.setState({saving: false, stockInventoryPrice: response.data});
                }
            }).catch(error => {
                this.setState({saving: false});
                console.log(error.data);
            });
        }
    }

    handleCancel() {
        this.props.history.push('/inventory/stock-inventory');
    }

    render() {
        let {stockInventoryPrice, loading, prodCode, formErrors} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={this.props.history.goBack}>Stock Inventory Price</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{!prodCode ? 'Add' : 'Edit'}</BreadcrumbItem>
                </Breadcrumb>
                <Alert color="light">
                    <div className="text-right">
                        <StockInventoryQuickLinks
                            showInventoryStatus={true}
                            showInventoryPrice={false}
                            showInventoryCost={true}
                            prodCode={prodCode}
                        />
                    </div>
                </Alert>
                <Card>
                    <CardHeader>
                        <h5>Stock Price</h5>
                    </CardHeader>
                    <CardBody>
                        <StockInventoryMainUnitView prodCode={prodCode}/>
                        <hr/>

                        {loading ? <Spinner color="primary"/> :
                            <Form>
                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>

                                            <Label>Margin Rate</Label><InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-percent"
                                                       aria-hidden="true"/>
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                invalid={formErrors.marginRate != null}
                                                name="marginRate"
                                                type="number"
                                                value={parseFloat((stockInventoryPrice.marginRate).toFixed(2))}
                                                onChange={this.handleChange}
                                                placeholder="MarginRate"/>
                                        </InputGroup>
                                            <FormText color="danger">{formErrors.marginRate}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Margin Value</Label><InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-usd" aria-hidden="true"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                invalid={formErrors.marginValue != null}
                                                name="marginValue"
                                                type="number"
                                                value={parseFloat(stockInventoryPrice.marginValue.toFixed(2))}
                                                onChange={this.handleChange}
                                                placeholder="Margin Value"/>
                                        </InputGroup>
                                            <FormText color="danger">{formErrors.marginValue}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Price</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-usd" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    invalid={formErrors.price != null}
                                                    name="price"
                                                    type="number"
                                                    value={parseFloat(stockInventoryPrice.price.toFixed(2))}
                                                    onChange={this.handleChange}
                                                    placeholder="Price"/>
                                            </InputGroup>
                                            <FormText color="danger">{formErrors.price}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button color='primary' size={"sm"} onClick={this.handleSubmit}
                                    disabled={this.state.saving}>
                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                              style={{color: "white"}}/> :
                                    <i className="fa fa-floppy-o mr-2"
                                       aria-hidden="true"/>}
                                Save Inventory Price</Button>{' '}
                            <Button color='secondary' size={"sm"} onClick={this.props.history.goBack}>
                                <i className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                Back</Button>
                        </div>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}