import React, {Component} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Row,
    Spinner,
    Table,Input,Badge
} from 'reactstrap';
import { removeDuplicates, handleErrorMessage } from "../../services/CommonService";
import {Link} from "react-router-dom";
import ManageStockBulkUpdateModal from '../../components/modal/ManageStockBulkUpdateModal';
import classnames from 'classnames';
import queryString from 'query-string';
import importExportService from '../../services/ImportExportService';
import { toast, ToastContainer } from 'react-toastify';
import FileSaver from 'file-saver';
import StockService from '../../services/StockService';

export default class ManageInventoryStockBulkProdCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            stockProdCodeList: [],
            allRecordsSelected: false,
            isOpenStockDiscontinueSoldOutModal: false,
            selectedType: '',
            selectedProdCodes: [],
            showProdCodeTable: false,
            isLoadingImport: false,
            isLoadingDownload: false,
            importResponse: {
                hasError: false,
                rows: []
            },
            selectedCheckboxCount:0
        };
        this.stockService = new StockService();
        this.handleChange = this.handleChange.bind(this);
        this.toggleStockDiscontinueSoldOutModal = this.toggleStockDiscontinueSoldOutModal.bind(this);
        this.openStockDiscontinueSoldOutModal = this.openStockDiscontinueSoldOutModal.bind(this);
        this.getStockProdcode = this.getStockProdcode.bind(this);
        this.selectAllRecords = this.selectAllRecords.bind(this);
        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.uploadStockProdcode = this.uploadStockProdcode.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
    }

    openStockDiscontinueSoldOutModal() {
        let {stockProdCodeList, selectedProdCodes} = this.state;
        (stockProdCodeList || []).forEach((item, index) => {
            if (item.selected) {
                selectedProdCodes.push(item.prodCode);
            }
        });
        selectedProdCodes = removeDuplicates(selectedProdCodes);
        this.setState({isOpenStockDiscontinueSoldOutModal: true, selectedProdCodes});
    }

    toggleStockDiscontinueSoldOutModal(change) {
        this.setState({isOpenStockDiscontinueSoldOutModal: change});
    }

    selectAllRecords() {
        let {stockProdCodeList, allRecordsSelected} = this.state;
        (stockProdCodeList || []).forEach((item, index) => {
            item.selected = !allRecordsSelected;
        });
        let counter = (stockProdCodeList || []).filter(x => x.selected).length;
        this.setState({ stockProdCodeList, allRecordsSelected: !allRecordsSelected, selectedCheckboxCount: counter });
    }

    handleChange(key, value, index) {
        let { stockProdCodeList, allRecordsSelected } = this.state;
        let counter = 0;
        switch (key) {
            case "selected":
                stockProdCodeList[index][key] = value;
              counter = (stockProdCodeList || []).filter(x => x.selected).length;
                if (counter === stockProdCodeList.length) {
                    allRecordsSelected = true;
                }
                else {
                    allRecordsSelected = false;
                }
                break;
        }
        this.setState({ stockProdCodeList, allRecordsSelected, selectedCheckboxCount: counter });
    }

    fileChangeHandler(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }


    uploadStockProdcode() {
        let { selectedFile } = this.state;
        this.setState({ isLoadingImport: true });
        if (!selectedFile) {
            toast.error("Please choose file to import!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isLoadingImport: false });
            return false;
        }
        const data = new FormData();
        data.append('file', selectedFile);
        this.stockService.GetStockProdCode(data).then(response => {
            let data = response.data;
            this.setState({ isLoadingImport: false });
            if (data.commonImportData && data.commonImportData.HasError) {
                toast.error("Error!", { position: toast.POSITION.BOTTOM_CENTER });
                this.setState({ importResponse: data.commonImportData });
                return false;
            }
            this.getStockProdcode(data.stockBulkProdCodes);
        }).catch(error => {
            this.setState({ isLoadingImport: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    getStockProdcode(data) {
        let { showProdCodeTable, stockProdCodeList } = this.state;
        if (data) {
            stockProdCodeList = data;
            showProdCodeTable= true;
        }
        this.setState({ stockProdCodeList, showProdCodeTable }, () => {
            this.selectAllRecords();
        });
    }

    downloadTemplate() {
        this.setState({ isLoadingDownload: true });
        importExportService.generateImportFormat('api/stock/invenory/stock/prodcode/template/download').then(response => {
            this.setState({ isLoadingDownload: false });
            FileSaver.saveAs(response.data, "StockProdCodeImportTemplate.xlsx");
        }).catch(error => {
            this.setState({ isLoadingDownload: false });
            toast.error(handleErrorMessage(error));
        });
    }

    getStore(stockProdCodeList) {
        return [
            {
                key: "selected",
                label: <div className={"text-center"}><a href="javascript:void(0)" onClick={this.selectAllRecords}>
                    {this.state.allRecordsSelected ?
                        <i className="fa fa-lg fa-check-square-o" aria-hidden="true"/> :
                        <i className="fa fa-lg fa-square-o" aria-hidden="true"/>
                    }
                </a></div>,
                type: 'checkbox',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                width: '20px',
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className={"text-center"}>
                        <a href={"javascript:void(0)"}
                           onClick={() => ref.handleChange('selected', !value, recordIndex)}>
                            <i className={classnames("fa", "fa-lg", {
                                    "fa-check-square-o": value,
                                    "fa-square-o": !value,
                                }
                            )}/>
                        </a>
                    </div>;
                }
            },
            {
                key: "prodCode",
                label: "ProdCode",
                type: 'prodCode',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                width: '80px',
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <Link className="btn btn-primary btn-sm"
                                 style={{color: "white"}}
                                 title={"Click here to see stock enquiry"}
                                 to={"/inventory/stock/enquiry?" + queryString.stringify({prodCode: value})}
                    >{value}</Link>
                }
            },
            {
                key: "description",
                label: "Description",
                type: 'description',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                width: '300px',
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "productCodes",
                label: "Product Name",
                type: 'productCodes',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                width: '150px',
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "status",
                label: "Status",
                type: 'status',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left align-middle",
                searchNodeColSpan: 1,
                width: '100px',
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className={"text-center"}>
                        {record.discontinued ? <Badge color="danger" className={"mr-2"}>Discontinued</Badge>
                            : null
                        }
                        {record.soldOut ? <Badge color="warning">Sold Out</Badge>
                            : null
                        }
                    </div>;
                }
            },
        ]
    }

    render() {
        let { isLoading, isLoadingImport, selectedCheckboxCount,importResponse,isLoadingDownload, stockProdCodeList, showProdCodeTable, selectedProdCodes, selectedType, isOpenStockDiscontinueSoldOutModal } = this.state;
        let store = this.getStore(stockProdCodeList);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/stock/list')}>Stock</BreadcrumbItem>
                    <BreadcrumbItem active>Bulk Update</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                <h5> Bulk Update</h5>
                            </Col>
                            <Col xl='8' lg='8' md='8' sm='12' xs='12' className={"text-right"}>
                                {showProdCodeTable && selectedCheckboxCount>0 ?
                                    <Button color={"primary"}
                                            size={"sm"} className={"mr-2"}
                                            onClick={() => this.openStockDiscontinueSoldOutModal()}>
                                        Choose Action
                                    </Button>
                                    : null}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {!showProdCodeTable ?
                            <div>
                                <p><i className="fa fa-info-circle mr-2" aria-hidden="true"/>
                                    Please upload the file to process the stock prodcode's. </p>
                                    <Row>
                                        <Col>
                                            <Input type="file"
                                                name="file"
                                                id="file"
                                                onChange={this.fileChangeHandler}
                                                aria-label="Upload document" />
                                        </Col>
                                    </Row>
                                    <br />
                                    {
                                        importResponse.hasError
                                            ? <div>
                                                <hr />
                                                <Table size={"sm"} hover={true} responsive>
                                                    <thead>
                                                        <tr>
                                                            <td>Row</td>
                                                            <td>Column</td>
                                                            <td>Title</td>
                                                            <td>Value</td>
                                                            <td>Validation Message</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {((importResponse.rows) || []).map(row => {
                                                            return <tr
                                                                className={row.hasError ? "table-danger" : "table-success"}>
                                                                <td>{row.rowIndex}</td>
                                                                <td>{row.columnIndex}</td>
                                                                <td>{row.inputTitle}</td>
                                                                <td>{row.inputValue}</td>
                                                                <td>{row.validationMessage}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : null
                                    }
                            </div>
                            :
                            <div>
                                <Table responsive={true} striped bordered size={"sm"}>
                                    <thead>
                                    <tr>
                                        {(store || []).map((column, index) => {
                                            return (
                                                <th key={index}
                                                    colSpan={column.colSpan}
                                                    className={column.labelClassName}
                                                    style={{minWidth: column.minWidth, width: column.width}}>
                                                    {column.label}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        isLoading
                                            ? <tr>
                                                <td colSpan={(store || []).length + 1}><Spinner color={"primary"}/></td>
                                            </tr>
                                            : (stockProdCodeList || []).map((row, rowIndex) =>
                                                <tr key={rowIndex} className={"align-middle"}>
                                                    {(store || []).map((column, columnIndex) => {
                                                        return (
                                                            <td key={columnIndex} className={column.valueClassName}>
                                                                {column.render(row[column.key], row, rowIndex, stockProdCodeList, this)}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </CardBody>
                    <CardFooter>
                        {!showProdCodeTable ?
                            <div className={"text-right"}>
                                <Button color={"primary"} size={"sm"}
                                    className={"mr-2"}
                                    onClick={this.uploadStockProdcode}
                                    disabled={isLoadingImport}>
                                    {isLoadingImport
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            color={"white"} />
                                        : <i className="fa fa-upload mr-2" />
                                    }
                                    Upload</Button>
                                <Button color={"primary"} size={"sm"}
                                    outline={true}
                                    onClick={this.downloadTemplate}
                                    disabled={isLoadingDownload}>{isLoadingDownload
                                        ? <Spinner size="sm"
                                            className={"mr-2"}
                                            color={"primary"} />
                                        : <i className="fa fa-download mr-2" />
                                    }Download template</Button>
                            </div>
                            :null
                            }
                    </CardFooter>
                </Card>
                {
                    isOpenStockDiscontinueSoldOutModal ?
                        <ManageStockBulkUpdateModal
                            isOpen={isOpenStockDiscontinueSoldOutModal}
                            toggle={this.toggleStockDiscontinueSoldOutModal}
                            selectedProdCodes={selectedProdCodes}
                            refresh={this.uploadStockProdcode}
                        />
                        : null
                }
                <ToastContainer/>
            </div>
        );
    }

}