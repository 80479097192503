import React,{ Component } from "react";
import { Button, ButtonGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import PurchaseOrderEnquiry from '../../services/purchase/PurchaseOrderService';
import { handleErrorMessage } from '../../services/CommonService';

export default class SearchPurchaseOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ordNum:0
        }
        this.purchaseOrderEnquiry = new PurchaseOrderEnquiry();
        this.handleChange = this.handleChange.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.firstOrder = this.firstOrder.bind(this);
        this.lastOrder = this.lastOrder.bind(this);
        this.currentLastOrder = this.currentLastOrder.bind(this);
        this.currentNextOrder = this.currentNextOrder.bind(this);
    }
    handleChange(e) {
        this.setState({ ordNum: e.target.value });
    }
    enterPressed(e) {
        let keyCode = e.keyCode || e.which
        if (keyCode === 13) {
            this.props.getPurchaseOrder(this.state.ordNum);
        }
    }
    componentDidMount() {
        if (this.props.ordNum) {
            this.setState({ ordNum: this.props.ordNum }, () => {
                this.props.getPurchaseOrder(this.state.ordNum);
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            this.setState({ ordNum: nextProps.ordNum }, () => {
                this.props.getPurchaseOrder(this.state.ordNum);
            })
        }
    }

    firstOrder() {
        this.setState({ isLoadingOrdNum: true });
        this.purchaseOrderEnquiry.getOrderNoByStatus('first').then(response => {
            this.setState({ isLoadingOrdNum: false, ordNum: response.data }, () => {
                this.props.getPurchaseOrder(this.state.ordNum)
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER })
        })
    }

    lastOrder() {
        this.setState({ isLoadingOrdNum: true });
        this.purchaseOrderEnquiry.getOrderNoByStatus('last').then(response => {
            this.setState({ isLoadingOrdNum: false, ordNum: response.data }, () => {
                this.props.getPurchaseOrder(this.state.ordNum)
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER })
        })
    }
    currentNextOrder() {
        this.setState({ isLoadingOrdNum: true });
        this.purchaseOrderEnquiry.getCurrentNextOrder(this.state.ordNum).then(response => {
            this.setState({ isLoadingOrdNum: false, ordNum: response.data }, () => {
                this.props.getPurchaseOrder(this.state.ordNum)
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER })
        })
    }
    currentLastOrder() {
        this.setState({ isLoadingOrdNum: true });
        this.purchaseOrderEnquiry.getCurrentLastOrder(this.state.ordNum).then(response => {
            this.setState({ isLoadingOrdNum: false, ordNum: response.data }, () => {
                this.props.getPurchaseOrder(this.state.ordNum)
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER })
        })
    }
    render() {
        let { ordNum, isLoadingOrdNum } = this.state;
        let { isLoadingOrder, getPurchaseOrder } = this.props;
        isLoadingOrdNum = isLoadingOrdNum || this.props.isLoadingOrdNum;
        return (
            <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <Button size={"sm"} color={"secondary"} className={"mr-2"}
                            onClick={this.props.history.goBack}>
                            <i className="fa fa-fw fa-chevron-left mr-2" aria-hidden="true" />
                            Back
                        </Button>
                    </InputGroupAddon>
                    <Input type={"number"} name="orderNumber" id="orderNumber"
                        onChange={event => this.handleChange(event)} onKeyPress={this.enterPressed}
                        value={ordNum ? ordNum : ''}
                        placeholder={isLoadingOrdNum ? "Searching..." : "Enter order number."} />
                    <InputGroupAddon addonType="append">
                        <ButtonGroup>

                            <Button
                                title={"click here to search"}
                                color={"primary"}
                                disabled={isLoadingOrder || isLoadingOrdNum}
                                onClick={() => getPurchaseOrder(ordNum)}>
                                {isLoadingOrder || isLoadingOrdNum
                                    ? <i className="fa fa-fw fa-circle-o-notch fa-spin mr-2" aria-hidden="true" />
                                    : <i className="fa fa-fw fa-search mr-2" aria-hidden="true" />
                                }
                                Search
                            </Button>
                            <Button
                                title={"First"}
                                color={"secondary"}
                                disabled={isLoadingOrder || isLoadingOrdNum}
                                onClick={this.firstOrder}>
                                <i className="fa fa-fw fa-angle-double-left" aria-hidden="true" />
                            </Button>
                            <Button
                                title={"Previous"}
                                color={"secondary"}
                                disabled={ordNum === '' || isLoadingOrder || isLoadingOrdNum}
                                onClick={this.currentLastOrder}>
                                <i className="fa fa-fw fa-angle-left" aria-hidden="true" />
                            </Button>
                            <Button
                                title={"Next"}
                                color={"secondary"}
                                disabled={ordNum === '' || isLoadingOrder || isLoadingOrdNum}
                                onClick={this.currentNextOrder}>
                                <i className="fa fa-fw fa-angle-right" aria-hidden="true" />
                            </Button>
                            <Button
                                title={"Last"}
                                color={"secondary"}
                                disabled={isLoadingOrder || isLoadingOrdNum}
                                onClick={this.lastOrder}>
                                <i className="fa fa-fw fa-angle-double-right" aria-hidden="true" />
                            </Button>
                           
                        </ButtonGroup>
                    </InputGroupAddon>
                </InputGroup>
                <ToastContainer />
            </div>
        )
    }
}