import React, {Component} from 'react';
import {
    Popover, PopoverHeader, PopoverBody, Spinner, Button
} from 'reactstrap';

import StockService from '../../services/StockService';
import {handleErrorMessage} from "../../services/CommonService";

export default class StockPopover extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stock: {},
            loading: false,
            isOpen: false,
            activeProdCode: ""
        };
        this.stockService = new StockService();
        this.getStock = this.getStock.bind(this);
    }

    componentDidMount() {
        this.getStock(this.props.prodCode);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.prodCode !== this.state.activeProdCode) {
            this.getStock(nextProps.prodCode);
        }
    }

    getStock(prodCode) {
        if (prodCode) {
            this.setState({loading: true, stock: {}, activeProdCode: prodCode});
            this.stockService.getStock(prodCode).then(response => {
                if (response.data) {
                    this.setState({stock: response.data, loading: false});
                } else {
                    this.setState({stock: {name: prodCode, description: "Not found"}, loading: false});
                }

            }).catch(error => {
                this.setState({stock: {name: "Error!", description: handleErrorMessage(error)}, loading: false});
            })
        } else {
            this.setState({stock: {}, activeProdCode: prodCode});
        }
    }

    render() {
        let {stock, loading} = this.state;
        let {isOpen, toggle, target, prodCode} = this.props;

        if (!isOpen) {
            return null;
        }
        return (
            <Popover placement="top" isOpen={isOpen ? true : false} target={'#' + target}
                     toggle={(isOpen) => toggle(isOpen)}>
                {/*{loading
                    ? <PopoverHeader>{prodCode} <Button className={"float-right"} onClick={() => toggle(isOpen)} close /></PopoverHeader>
                    : <PopoverHeader>{stock.prodCode} <Button className={"float-right"} onClick={() => toggle(isOpen)} close /></PopoverHeader>
                }*/}
                {loading
                    ? <Spinner color={"primary"} className={"m-2"}/>
                    : <PopoverBody>
                        <div>
                            {stock.description}
                        </div>
                        <div className={"text-right"}>
                            <Button color={"link"} size={"sm"} style={{textDecoration: "none"}} onClick={() => toggle(isOpen)}>
                                <i className="fa fa-times-circle mr-2"
                                    aria-hidden="true"/>close
                            </Button>
                        </div>
                    </PopoverBody>
                }
            </Popover>
        );
    }
}