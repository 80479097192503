import React, {Component} from 'react';
import {
    Card, CardHeader, CardBody,
    ButtonGroup, Badge, Button, Col,
    Input, InputGroup, InputGroupAddon,
    InputGroupText, Row, Spinner, Table,
    TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import OrderService from '../../services/OrderService';
import SalesQuoteService from '../../services/sales/SalesQuoteService';
import RepService from '../../services/RepService';
import BranchService from '../../services/BranchService';
import classnames from 'classnames';
import {
    findIndex,
    changeFormatOfDateString,
    displayECD,
    getDateString,
    isAllSelected,
    validateEmail,
    getDateObj,
    handleErrorMessage
} from './../../services/CommonService';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import {Link as Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import SearchBannerOutstandingQuotes from './SearchBannerOutstandingQuotes';
import EmailFollowUp from "../../components/modal/EmailFollowUp";
import CancelQuoteStatusModal from "../../components/Order/modals/CancelQuoteStatusModal";
import QuoteConvertToPickingSlipModal from "../../components/sales/quotes/QuoteConvertToPickingSlipModal";
import {toast, ToastContainer} from 'react-toastify';
import moment from 'moment';
import FileSaver from 'file-saver';
import DatePicker from "react-datepicker";
import quartzService from '../../services/QuartzService';
import {QUARTZ_SCHEDULED_JOB_DEBTOR_QUOTES_FOLLOW_UP} from "../../store/AppConstants";
import {convertUTCDateToLocalDate, getProductString} from "../../services/CommonService";

const minDate = new Date();

const date = new Date();
const tabs = [
    {
        index: "0",
        key: "Active",
        label: "Current"
    },
    {
        index: "1",
        key: "Cancelled",
        label: "Cancelled"
    }
];

function ChangeFollowUpDateDate(props) {

    return (
        <div>
            <DatePicker
                className={props.cssClassName}
                selected={props.selected}
                onChange={date => props.handleChange(date)}
                dateFormat="ddMMMyy"
                placeholderText="Select"
                minDate={minDate}
                withPortal
            />
        </div>
    );
}

export default class DebtorsOutstandingQuotes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quotes: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ordNum",
                        direction: false
                    },
                    filterRequest: {
                        statusID: null,
                        ordNum: null,
                        branch: null,
                        custOrdNum: "",
                        accountID: "",
                        startDate: moment(date).subtract(90, 'day').toDate(),
                        endDate: date,
                        company: "",
                        repCode: 0,
                        salesOrderJobStatusId: null,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            branches: [],
            reps: [],
            salesquotesStatuses: {
                statuses: [],
                statusesById: {}
            },
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            loading: false,
            searchText: "",
            ordernumbers: "",
            client_Emails: "",
            allRecordsSelected: false,
            selectedCheckboxes_count: 0,
            isOpenComposeMessageModal: false,
            openConvertToPickingSlipQuoteModal: false,
            istoggleQuotesCancelReasonsModal: false,
            downloading: false,
            activeTab: tabs[0].key,
            loadingCancelledQuote: false,
            emailSending: false,
            quartzJobSummary: {}
        };

        this.orderservice = new OrderService();
        this.branchService = new BranchService();
        this.salesQuoteService = new SalesQuoteService();
        this.refresh = this.refresh.bind(this);
        this.btnclicked = this.btnclicked.bind(this);
        this.getquotes = this.getquotes.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectRecord = this.selectRecord.bind(this);
        this.selectAllRecords = this.selectAllRecords.bind(this);
        this.getStatuses = this.getStatuses.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.getReps = this.getReps.bind(this);
        this.toggleComposeMessageModal = this.toggleComposeMessageModal.bind(this);
        this.toggleQuotesCancelReasonsModal = this.toggleQuotesCancelReasonsModal.bind(this);
        this.toggleOpenConvertToPickingSlipQuoteModal = this.toggleOpenConvertToPickingSlipQuoteModal.bind(this);
        this.handleEmailFollowUpSubmit = this.handleEmailFollowUpSubmit.bind(this);
        this.handleQuotesCancelation = this.handleQuotesCancelation.bind(this);
        this.printDebtorOutstandingQuotesInExcel = this.printDebtorOutstandingQuotesInExcel.bind(this);
        this.getQuartzJobSummary = this.getQuartzJobSummary.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.getQuartzJobSummary();
        //this.getStatuses();
        //this.getSalesOrderJobStatuses();
    }

    refresh() {
        this.getBranches();
        this.getReps();
    }

    // Old Statuses
    getStatuses() {
        this.salesQuoteService.fetchQuotesStatuses().then(response => {
            let {salesquotesStatuses} = this.state;
            salesquotesStatuses.statuses = response.data;
            salesquotesStatuses.statuses.forEach(status => {
                salesquotesStatuses.statusesById[status.statusID] = status;
            });
            this.setState({salesquotesStatuses});
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    // new Statuses
    getSalesOrderJobStatuses() {
        this.orderservice.getOrderStatuses().then(response => {
            let {salesOrderJobStatuses, quotes} = this.state;
            let {filterRequest} = quotes.request;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            // default status set to be Quotes
            let index = findIndex(salesOrderJobStatuses.statuses, "statusCode", "QUOTE");
            if (index !== -1) {
                filterRequest.salesOrderJobStatusId = salesOrderJobStatuses.statuses[index].id;
            }
            this.setState({salesOrderJobStatuses, quotes}, () => this.getquotes(quotes));
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getBranches() {
        let {quotes} = this.state;

        this.branchService.fetchBranches().then(response => {
            let branches = response.data;

            // default branch set to be BRISBANE
            let index = findIndex(branches, "shortName", "BRISBANE");
            if (index !== -1) {
                quotes.request.filterRequest.branch = branches[index].branchNum;
            }
            this.setState({branches, quotes}, () => this.getSalesOrderJobStatuses());
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getReps() {
        RepService.fetchReps().then(res => {
            this.setState({reps: res.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    getquotes(quotes) {
        let {type, ordernumbers} = this.state;
        this.setState({loading: true});

        let request = cloneDeep(quotes.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.salesQuoteService.fetchOutstandingsQuotes(request, this.state.activeTab).then(response => {
            quotes.response = response.data;
            quotes.response.records.map(record => {
                record.lastfollowUpDateObject = record.lastFollowUpDate ? getDateObj(record.lastFollowUpDate, "DD/MM/YYYY") : null;
                record.estimatedReqdDateObject = record.estimatedReqdDate ? getDateObj(record.estimatedReqdDate, "DD/MM/YYYY") : null;
            });
            this.setState({quotes, loading: false, ordernumbers: ordernumbers, type: this.props.type});
        }).catch(error => {
            quotes.response.records = [];
            quotes.response.totalRecords = 0;
            this.setState({loading: false, quotes});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    selectRecord(val, OrdNum) {
        let {quotes} = this.state;
        //quotes.response.records.filter(x => x.ordNum == OrdNum).selected = !val;
        const elementsIndex = quotes.response.records.findIndex(x => x.ordNum == OrdNum);
        quotes.response.records[elementsIndex].selected = !val;
        let counter = 0;
        for (let i in quotes.response.records) {
            if (quotes.response.records[i].selected) {
                counter++;
            }
        }
        if (counter === quotes.response.records.length) {
            this.setState({allRecordsSelected: true, selectedCheckboxes_count: counter, quotes});
        }
        else {
            this.setState({allRecordsSelected: false, selectedCheckboxes_count: counter, quotes});
        }
    }

    selectAllRecords() {
        let self = this;
        let {quotes} = this.state;
        quotes.response.records.forEach(function (el) {
            el.selected = !self.state.allRecordsSelected;
        });
        let counter = 0;
        for (let i in quotes.response.records) {
            if (quotes.response.records[i].selected) {
                counter++;
            }
        }
        self.setState({quotes, allRecordsSelected: !self.state.allRecordsSelected, selectedCheckboxes_count: counter});
    }

    getColValue(value, storeItem, order, index) {
        switch (storeItem.type) {
            case "link":
                return <span><Link
                    className="btn btn-sm btn-primary" style={{color: "white"}}
                    title={"Click here to view order in detail"}
                    to={"/sales/order/view?" + queryString.stringify({OrdNum: value})}>
                    {value}
                </Link></span>;
            case "date":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return <span><NumberFormat value={Math.floor(value)}
                                           displayType={'text'}
                                           thousandSeparator={true}
                                           prefix={'$'}/></span>;
            case "number":
                return <span><NumberFormat value={value}
                                           displayType={'text'}
                                           thousandSeparator={true}/></span>;
            case "Company":
                return <small>{value}</small>;
            case "statusByID":
                let result = "";
                switch (order.statusID) {
                    case 0:
                        result = <Badge size="sm" color="primary">{order.status}</Badge>;
                        break;
                    case -1:
                        result = <Badge size="sm" color="danger">{order.status}</Badge>;
                        break;
                    case 5:
                        result = <Badge size="sm" color="success">{order.status}</Badge>;
                        break;
                }
                return <div className={"text-center"}>
                    {result}
                </div>;

            case "checkbox":
                return <div className={"text-center"}>
                    <a href={"javascript:void(0)"} onClick={() => this.selectRecord(value, order.ordNum)}>
                        <i className={classnames("fa", "fa-lg", {
                                "fa-check-square-o": value,
                                "fa-square-o": !value,
                            }
                        )}/>

                    </a>
                </div>;
            case "categoryByID":
                switch (order.debtorCategoryID) {
                    case 1:
                        return <Link
                            className="btn btn-sm btn-warning"
                            title={"Click here to see account details"}
                            to={"/customer/#" + value}
                        >{value}</Link>;
                        break;
                    case 2:
                        return <Link
                            className="btn  btn-sm btn-secondary"
                            title={"Click here to see account details"}
                            to={"/customer/#" + value}
                        >{value}</Link>;
                        break;
                    case 3:
                    default:
                        return <Link
                            className="btn  btn-sm btn-primary"
                            title={"Click here to see account details"}
                            to={"/customer/#" + value}
                        >{value}</Link>;
                        break;
                }
                return value;
            case "ECD":
                return displayECD(value, "DD MMM YYYY");
            case "email":
                if (value) {
                    if (validateEmail(value)) {
                        return <a href={"mailto:" + value}>{value}</a>;
                    }
                    return <span>{value}</span>
                }
                break;
            case "lastFollowUpDate":
                return <div style={{minWidth: 90}}>
                    {this.state.activeTab === 'Cancelled' ?
                        <span>{changeFormatOfDateString(order.lastfollowUpDateObject, "DD/MM/YYYY", "DD MMM YYYY")}</span> :

                        <ChangeFollowUpDateDate selected={order.lastfollowUpDateObject}
                                                cssClassName={"form-control form-control-sm schedule-datepicker"}
                                                handleChange={(selectedDateObj) => this.handleDateChange("lastFollowUpDate", getDateString(selectedDateObj, "DD/MM/YYYY"), order)}
                        />
                    }
                </div>;
            case "estimatedReqdDate":
                return <div style={{minWidth: 90}}>
                    {this.state.activeTab === 'Cancelled' ?
                        <span>{changeFormatOfDateString(order.estimatedReqdDateObject, "DD/MM/YYYY", "DD MMM YYYY")}</span> :
                        <ChangeFollowUpDateDate selected={order.estimatedReqdDateObject}
                                                cssClassName={"form-control form-control-sm schedule-datepicker"}
                                                handleChange={(selectedDateObj) => this.handleDateChange("estimatedReqdDate", getDateString(selectedDateObj, "DD/MM/YYYY"), order)}
                        />
                    }
                </div>;
            case "actionForQuotes":
                return <div className={"text-center"}>
                    <ButtonGroup>
                        {order.createdFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                            ? <Link className="btn btn-sm btn-info"
                                    to={"/sales/order/create/product-builder/v1?orderNum=" + order.ordNum}>
                                Edit
                            </Link>
                            : null
                        }
                    </ButtonGroup>
                </div>;
            case "rep":
                return <small>{value}</small>;
            case "productDetails":
                return <small>{getProductString(order.orderDetails)}</small>;
            default:
                return <span>{value}</span>
        }
    }


    handleDateChange(change, key, order) {
        let {quotes} = this.state;
        const elementsIndex = quotes.response.records.findIndex(x => x.ordNum === order.ordNum);
        let req = {
            ordNum: order.ordNum,
            [change]: key,
        };

        this.salesQuoteService.saveQuoteDetails(req).then(res => {
            quotes.response.records[elementsIndex].lastfollowUpDateObject = res.data.lastFollowUpDate ? getDateObj(res.data.lastFollowUpDate, "DD/MM/YYYY") : null;
            quotes.response.records[elementsIndex].estimatedReqdDateObject = res.data.estimatedReqdDate ? getDateObj(res.data.estimatedReqdDate, "DD/MM/YYYY") : null;
            this.setState({quotes});
            let message = "Followup date updated successfully";
            if (change === 'estimatedReqdDate') {
                message = "Estimated required date updated successfully";
            }
            toast.success(message);
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });

    }

    handleChange(change, key) {
        let {quotes} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (quotes.request.sortRequest.key === change) {
                    quotes.request.sortRequest.direction = !quotes.request.sortRequest.direction;
                } else {
                    quotes.request.sortRequest.key = change;
                    quotes.request.sortRequest.direction = false;
                }
                this.getquotes(quotes);
                break;
            case "pageSize":
                quotes.request.pageRequest[key] = change;
                this.getquotes(quotes);
                break;
            case "currentPage":
                quotes.request.pageRequest[key] = change;
                this.getquotes(quotes);
                break;
            case "consignNum":
                quotes.request.filterRequest[key] = change;
                this.getquotes(quotes);
                break;
            case "salesOrderJobStatusId":
                quotes.request.filterRequest[key] = parseInt(change);
                this.getquotes(quotes);
                break;
            case "branch":
                if (change === 'All') {
                    change = null;
                }
                quotes.request.filterRequest[key] = parseInt(change);
                this.getquotes(quotes);
                break;
            default:
                quotes.request.filterRequest[key] = change;
                if (key === "repCode") {
                    if (change === 'ALL') {
                        quotes.request.filterRequest[key] = 0
                    }
                }
                this.setState({quotes});
                this.getquotes(quotes);
        }
        //this.props.handleFilterFromParent(quotes.request.filterRequest);
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.ordNum.toString().includes(searchText);

            if (!flag && item.invTotal) {
                flag = item.invTotal.toString().includes(searchText)
            }
            if (!flag && item.custOrdNum) {
                flag = item.custOrdNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.accountID) {
                flag = item.accountID.toLowerCase().includes(searchText)
            }
            if (!flag && item.internalNotes) {
                flag = item.internalNotes.toLowerCase().includes(searchText)
            }
            if (!flag && item.ordDate) {
                flag = getDateString(item.ordDate, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.company) {
                flag = item.company.toLowerCase().includes(searchText)
            }
            if (!flag && item.rep) {
                flag = item.rep.toLowerCase().includes(searchText)
            }
            if (!flag && item.email) {
                flag = item.email.toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    getStore({filterRequest}) {
        return [
            {
                key: "selected",
                label: <div className={"text-center"}>
                    <a href={"javascript:void(0)"}
                       onClick={this.selectAllRecords}>
                        <i className={classnames("fa", "fa-lg", {
                                "fa-check-square-o": this.state.allRecordsSelected,
                                "fa-square-o": !this.state.allRecordsSelected,
                            }
                        )}/>

                    </a></div>,
                type: "checkbox",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "ordNum",
                label: "Quote No",
                type: "link",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "accountID",
                label: "Account",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "company",
                label: "Company Name",
                type: "Company",
                colSpan: 1,
                minWidth: 170,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "ordDateString",
                label: "Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "custOrdNum",
                label: "Cust.Order #",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: " align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "invTotal",
                label: "Amount",
                type: "currency",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: " align-middle text-right",
                labelClassName: "text-right align-middle",
                searchNode: null
            },
            {
                key: "rep",
                label: "Rep",
                type: "rep",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            }, {
                key: "orderDetails",
                label: "Stock Group(s)",
                type: "productDetails",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "email",
                label: "Email",
                type: "email",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "lastFollowUpDate",
                label: "Last Follow Up Date",
                type: "lastFollowUpDate",
                colSpan: 1,
                minWidth: '50px',
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "estimatedReqdDate",
                label: "Estimated Required Date",
                type: "estimatedReqdDate",
                colSpan: 1,
                minWidth: '50px',
                sorterApplicable: true,
                showColumn: this.state.activeTab !== 'Cancelled',
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "cancelledDate",
                label: "Cancelled Date",
                type: "date",
                colSpan: 1,
                minWidth: '50px',
                sorterApplicable: true,
                showColumn: this.state.activeTab === 'Cancelled',
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "cancelReason",
                label: "Cancellation Reason",
                type: "default",
                colSpan: 1,
                minWidth: '50px',
                sorterApplicable: false,
                showColumn: this.state.activeTab === 'Cancelled',
                valueClassName: "align-middle",
                labelClassName: " align-middle",
                searchNode: null
            },
            {
                key: "",
                label: "Action",
                type: "actionForQuotes",
                colSpan: 1,
                minWidth: '50px',
                sorterApplicable: false,
                showColumn: this.state.activeTab !== 'Cancelled',
                valueClassName: "text-center align-middle",
                labelClassName: " align-middle",
                searchNode: null
            }
        ];
    }

    toggleComposeMessageModal(change) {
        this.setState({isOpenComposeMessageModal: change});
    }

    toggleQuotesCancelReasonsModal() {
        this.setState((prevState) => ({istoggleQuotesCancelReasonsModal: !prevState.istoggleQuotesCancelReasonsModal}));
    }

    toggleOpenConvertToPickingSlipQuoteModal() {
        this.setState((prevState) => ({openConvertToPickingSlipQuoteModal: !prevState.openConvertToPickingSlipQuoteModal}));
    }

    handleEmailFollowUpSubmit(msgody) {
        this.setState({emailSending: true});
        this.salesQuoteService.SendEmailFollowUpsForOutstandingsQuotes(msgody).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.setState({emailSending: false});
                this.toggleComposeMessageModal(false);
                toast.success("Email sent!");
                this.getquotes(this.state.quotes);
            }
        }).catch(error => {
            console.log(error);
            this.setState({emailSending: false});
            toast.error(handleErrorMessage(error));
        });
    }


    handleQuotesCancelation(CancelReasonId) {
        let {quotes} = this.state;
        this.setState({loadingCancelledQuote: true});
        this.salesQuoteService.cancelOutstandingsQuotes(CancelReasonId, this.state.ordernumbers).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.setState({loadingCancelledQuote: false});
                this.toggleQuotesCancelReasonsModal();
                toast.success("Quotes cancelled successfully!")
                this.getquotes(quotes);
            }
        }).catch(error => {
            this.setState({loadingCancelledQuote: false});
            toast.error(handleErrorMessage(error));
        });
    }

    btnclicked(btnName) {
        let counter = 0;
        let Emails = '';
        let {quotes, ordernumbers} = this.state;
        let OrdersArr = quotes.response.records;
        ordernumbers = '';
        for (let i in OrdersArr) {
            if (OrdersArr[i].selected) {
                counter++;
                if (OrdersArr[i].email) {
                    Emails += OrdersArr[i].email + ","
                }
                ordernumbers += OrdersArr[i].ordNum + ","
            }
        }

        // removing last extra comma
        Emails = Emails.replace(/,\s*$/, "");
        ordernumbers = ordernumbers.replace(/,\s*$/, "");

        this.setState({client_Emails: Emails, ordernumbers});

        if (counter === 0) {
            toast.info("No record selected !", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            switch (btnName) {
                case "EmailFollowUp":
                    this.toggleComposeMessageModal(true);
                    break;
                case "ConvertToPickSlip":
                    this.toggleOpenConvertToPickingSlipQuoteModal();
                    break;
                case "Cancel":
                    this.toggleQuotesCancelReasonsModal();
                    break;
            }
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                this.getquotes(this.state.quotes);
            });
        }
    }

    renderButtons(selectedQuotes) {
        if (selectedQuotes > 0) {
            return (
                <div className="text-right">
                    <div>
                        <ButtonGroup size="sm">
                            <Button color={"warning"} size={"sm"} onClick={() => this.btnclicked("EmailFollowUp")}>
                                <i className="fa fa-envelope mr-2" aria-hidden="true"/>{' '}Email Follow Up
                            </Button>
                            <Button onClick={() => this.btnclicked("ConvertToPickSlip")}
                                    color={"primary"} size={"sm"}>
                                <i className="fa fa-cart-arrow-down mr-2" aria-hidden="true"/>{' '}Convert To Order
                            </Button>
                            {this.state.activeTab !== 'Cancelled' ?
                                <Button onClick={() => this.btnclicked("Cancel")} color={"danger"} size={"sm"}>
                                    <i className="fa fa-ban mr-2" aria-hidden="true"/>{' '}Cancel Selected
                                </Button> : null}
                        </ButtonGroup>
                    </div>
                    <br/>
                </div>
            );
        }
    }

    printDebtorOutstandingQuotesInExcel() {
        this.setState({downloading: true});
        let request = cloneDeep(this.state.quotes.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.salesQuoteService.generateDebtorOutstandingQuotesSummaryInExcel(request, this.state.activeTab).then(response => {
            this.setState({downloading: false});
            FileSaver.saveAs(response.data, "DEBTOR-OUTSTANDING-QUOTES-SUMMARY" + ".xlsx");
        }).catch(error => {
            this.setState({downloading: false});
            toast.error(handleErrorMessage(error));
        });
    }

    getQuartzJobSummary() {
        quartzService.getQuartzJobSummary(QUARTZ_SCHEDULED_JOB_DEBTOR_QUOTES_FOLLOW_UP).then(response => {
            this.setState({quartzJobSummary: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let {quotes, loading, searchText, salesquotesStatuses, isOpenComposeMessageModal, emailSending, downloading, loadingCancelledQuote, activeTab, istoggleQuotesCancelReasonsModal, openConvertToPickingSlipQuoteModal, client_Emails, reps, salesOrderJobStatuses, ordernumbers, quartzJobSummary} = this.state;
        let {pageRequest, sortRequest, filterRequest} = quotes.request;
        let store = this.getStore(quotes.request);
        let totalOrdervalue = 0;

        let selectedQuotes = [];
        let allQuotesSelectedFlag = false;
        if (quotes.response.records) {
            allQuotesSelectedFlag = isAllSelected(quotes.response.records, "selected");
            selectedQuotes = quotes.response.records.filter((r) => {
                return r.selected
            });
        }

        return (
            <div>
                <Row className={"mb-1"}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h5 className="mb-3">
                            Manage Quotes
                        </h5>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>

                        </div>
                    </Col>
                </Row>


                <div className="mb-2">
                    <SearchBannerOutstandingQuotes
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                        salesquotesStatuses={salesquotesStatuses}
                        salesOrderJobStatuses={salesOrderJobStatuses}
                        branches={this.state.branches}
                        reps={reps}
                    />
                </div>

                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            {
                                tabs.map((tab, index) => (
                                    (tab.key === activeTab ?
                                        <TabPane tabId={tab.key} key={index}>
                                            <Row>
                                                {selectedQuotes.length > 0 ?
                                                    <Col>
                                                        <Badge color="light"> {selectedQuotes.length} quotes
                                                            selected</Badge>
                                                    </Col> : null}
                                                <Col>
                                                    {this.renderButtons(selectedQuotes.length)}
                                                </Col>
                                            </Row>
                                            <Row className={"align-items-center"}>
                                                <Col xl={10} lg={10} md={6} sm={12} xs={12}>
                                                    <div className={"text-left"}>
                                                        {loading
                                                            ? <Spinner size={"sm"} color={"primary"}/>
                                                            : <span>Showing{' '}
                                                                {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                                {' '}of {quotes.response.totalRecords}
                                                                {' '}entries
                                                            </span>
                                                        }
                                                        <Button className={'ml-2'}
                                                                outline={true}
                                                                size={"sm"}
                                                                color={"success"}
                                                                onClick={this.printDebtorOutstandingQuotesInExcel}
                                                                disabled={downloading}>
                                                            {
                                                                downloading
                                                                    ? <Spinner size="sm"
                                                                               className={"mr-2"}
                                                                               color={"success"}/>
                                                                    :
                                                                    <i className="fa fa-file-excel-o mr-2"
                                                                       aria-hidden="true"/>
                                                            }
                                                            {
                                                                downloading
                                                                    ? "Exporting"
                                                                    : "Export"
                                                            }
                                                        </Button>
                                                        <Badge
                                                            className={"ml-2"}
                                                            color={quartzJobSummary.triggerState === "Normal" ? "primary" : "danger"}
                                                        >
                                                            <strong>Auto Follow Up Emails Next Scheduled Date: </strong>
                                                            {convertUTCDateToLocalDate(quartzJobSummary.nextFireTimeDateString, "DD/MM/YYYY HH:mm")}
                                                        </Badge>
                                                        <Badge className={"ml-2"}
                                                               color={quartzJobSummary.triggerState === "Normal" ? "info" : "danger"}>
                                                            <i className='fa fa-history mr-2'/>
                                                            <strong>Previous email run date: </strong>
                                                            {convertUTCDateToLocalDate(quartzJobSummary.prevFireTimeDateString, "DD/MM/YYYY HH:mm")}
                                                        </Badge>


                                                    </div>

                                                </Col>
                                                <Col xl={2} lg={2} md={6} sm={12} xs={12}>
                                                    <div className={"text-right"}>
                                                        <InputGroup className={"mb-2"}>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fa fa-search"
                                                                       aria-hidden="true"/>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                type={"text"}
                                                                name={"searchText"}
                                                                placeholder={"Search..."}
                                                                value={searchText}
                                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div>
                                                <Table hover bordered size={"sm"} striped responsive>
                                                    <thead>
                                                    <tr>
                                                        {(store || []).filter(item => !!item.showColumn).map((item, index) => {
                                                            return (
                                                                <th key={index}
                                                                    onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                                                    colSpan={item.colSpan}
                                                                    className={item.labelClassName}
                                                                    style={{minWidth: item.minWidth}}>
                                                                    {item.label}
                                                                    {
                                                                        item.sorterApplicable ?
                                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                                }
                                                                            )} aria-hidden="true"/> : null
                                                                    }
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>

                                                    </thead>
                                                    {!loading ?
                                                        <tbody>

                                                        {(quotes.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, i) => {
                                                            totalOrdervalue = totalOrdervalue + order.ordTotal;
                                                            return (
                                                                <tr key={i}>
                                                                    {/* <td key={index}>
                                            {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                        </td>*/}
                                                                    {(store || []).filter(item => item.showColumn ? true : false).map((storeItem, index) => {
                                                                        return (
                                                                            <td key={index}
                                                                                className={storeItem.valueClassName}>
                                                                                {this.getColValue(order[storeItem.key], storeItem, order, i)}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            );
                                                        })}

                                                        </tbody>
                                                        : null}
                                                </Table>

                                                {!loading ?
                                                    <Row>
                                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                            <div className={"text-left"} style={{maxWidth: 200}}>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>Show</InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        type={"select"}
                                                                        name={"pageSize"}
                                                                        value={pageRequest.pageSize}
                                                                        disabled={loading}
                                                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                                        <option value={10}>10 Rows</option>
                                                                        <option value={25}>25 Rows</option>
                                                                        <option value={50}>50 Rows</option>
                                                                        <option value={100}>100 Rows</option>
                                                                        <option value={500}>500 Rows</option>
                                                                    </Input>
                                                                </InputGroup>


                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                                            <div className={"float-right"}>
                                                                <Pagination
                                                                    activePage={pageRequest.currentPage}
                                                                    itemsCountPerPage={pageRequest.pageSize}
                                                                    totalItemsCount={quotes.response.totalRecords}
                                                                    pageRangeDisplayed={3}
                                                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                                                    itemClass='page-item'
                                                                    linkClass='page-link'
                                                                    activeClass='active'
                                                                    innerClass='pagination'
                                                                    activeLinkClass='active'
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </TabPane> : null)
                                ))}
                        </TabContent>

                        {isOpenComposeMessageModal ?
                            <EmailFollowUp
                                handleChange={this.handleEmailFollowUpSubmit}
                                isOpen={isOpenComposeMessageModal}
                                emails={client_Emails}
                                ordernumbers={ordernumbers}
                                toggle={this.toggleComposeMessageModal}
                                emailSending={emailSending}
                            />
                            : null
                        }

                        {istoggleQuotesCancelReasonsModal ?
                            <CancelQuoteStatusModal
                                handleChange={this.handleQuotesCancelation}
                                isOpen={this.state.istoggleQuotesCancelReasonsModal}
                                toggleModel={this.toggleQuotesCancelReasonsModal}
                                loadingCancelledQuote={loadingCancelledQuote}
                            />
                            : null
                        }

                        {openConvertToPickingSlipQuoteModal ?
                            <QuoteConvertToPickingSlipModal
                                selectedQuotes={selectedQuotes}
                                isOpen={openConvertToPickingSlipQuoteModal}
                                toggleModel={this.toggleOpenConvertToPickingSlipQuoteModal}
                                refresh={this.refresh}
                            />
                            : null
                        }
                    </CardBody>
                </Card>


                <ToastContainer/>
            </div>
        );
    }
}