import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    Table,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    CardFooter
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import CustomerService from "../../services/CustomerService";
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import { getLocalDateStringFromUtcDateString, getDateString, handleErrorMessage } from '../../services/CommonService';
import DebtorOutstandingBalanceByDayCards from '../../components/dashboard/CustomerDashboard/DebtorOutstandingBalance';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';

export default class ViewDebtorReceipts extends Component {
    constructor(props) {
        super(props);

        let accountID = "";
        if (props.location.hash) {
            accountID = props.location.hash.replace('#', '');
        }
        this.state = {
            accountID: accountID,
            loading:false,
            loadingDebtor:false,
            debtor: {},

            debtorReceiptData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        date: null,
                        recDate: null,
                        bank: '',
                        bsb: '',
                        amount: 0,
                        discount: 0,
                        period: 0,
                        accountID: accountID,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            searchText:'',
        };
        this.customerService = new CustomerService();
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
        this.refresh = this.refresh.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        let { debtorReceiptData } = this.state;
        if (this.state.accountID !== nextProps.location.hash) {
            //received accountId in props
            if (nextProps.location.hash) {
                let accountID = nextProps.location.hash.replace('#', '');
                debtorReceiptData.request.filterRequest.accountID = accountID;
                this.setState({ debtorReceiptData, accountID });
                this.refresh(debtorReceiptData);
            }
        }
    }


    componentDidMount() {
        let { debtorReceiptData, accountID } = this.state;
        if (this.state.accountID) {
            //received accountId in props
            debtorReceiptData.request.filterRequest.accountID = this.state.accountID;
            this.setState({ debtorReceiptData });
            this.refresh(debtorReceiptData);
        } else {
            // Default, trying to get first accountId from db and then refreshing with the same
            this.customerService.searchFirstDebtorAccountCompany("").then(response => {
                if (response.data) {
                    for (let i in response.data) {
                        debtorReceiptData.request.filterRequest.accountID = response.data[i].accountId;
                        this.setState({ debtorReceiptData, accountID: response.data[i].accountId });
                        this.refresh(debtorReceiptData);
                        break;
                    }
                }
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleAccountChange(accountID) {
        let { debtorReceiptData } = this.state;
        if (debtorReceiptData.request.filterRequest.accountID !== accountID) {
            debtorReceiptData.request.filterRequest.accountID = accountID;
            this.refresh(debtorReceiptData);
        }
        this.setState({ accountID, debtorReceiptData });

        
    }

    handleDebtorAccountDetails(accountID) {
        let debtor = { accountID: "" };
        if (accountID) {
            this.setState({ loadingDebtor: true });
            this.customerService.searchCustomer(accountID).then(response => {
                debtor = response.data;
                if (debtor) {
                    debtor.debtorAddress = (debtor.address1 ? debtor.address1 + ", " : "")
                        + (debtor.address2 ? debtor.address2 + ", " : "")
                        + (debtor.address3 ? debtor.address3 + ", " : "")
                        + (debtor.address4 ? debtor.address4 + ", " : "")
                        + (debtor.city ? debtor.city + ", " : "")
                        + (debtor.state ? debtor.state + ", " : "")
                        + (debtor.postCode ? debtor.postCode : "");

                    this.setState({
                        debtor: debtor,
                        loadingDebtor: false
                    });
                }
            });
        } else {
            this.setState({
                accountID: debtor.accountID,
                debtor: debtor,
                loadingDebtor: false
            });
        }
    }

    refresh(debtorReceiptData) {
        this.handleDebtorAccountDetails(debtorReceiptData.request.filterRequest.accountID);
        this.getDebtorReceipts(debtorReceiptData);
    }

    getDebtorReceipts(debtorReceiptData) {
        let request = cloneDeep(debtorReceiptData.request);
        if (request.filterRequest.accountID) {
            if (request.filterRequest.date) {
                request.filterRequest.date = getDateString(request.filterRequest.date, "DD/MM/YYYY");
            }
            if (request.filterRequest.recDate) {
                request.filterRequest.recDate = getDateString(request.filterRequest.recDate, "DD/MM/YYYY");
            }

            this.setState({ loading: true });
            this.customerService.getDebtorReceipts(request).then(response => {
                if (response.data) {
                    debtorReceiptData.response = response.data;
                    this.setState({ debtorReceiptData, loading: false });
                }
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.bank.toLowerCase().includes(searchText);

            if (!flag && item.bSB) {
                flag = item.bSB.toLowerCase().includes(searchText)
            }
            if (!flag && item.chequeNum) {
                flag = item.chequeNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.date) {
                flag = getLocalDateStringFromUtcDateString(item.date, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.recDate) {
                flag = getLocalDateStringFromUtcDateString(item.recDate, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }

            if (!flag && item.amount) {
                flag = item.amount.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.period) {
                flag = item.period.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.discount) {
                flag = item.discount.toString().toLowerCase().includes(searchText)
            }
            

        }
        return flag;
    }

    handleChange(change, key) {
        let { debtorReceiptData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (debtorReceiptData.request.sortRequest.key === change) {
                    debtorReceiptData.request.sortRequest.direction = !debtorReceiptData.request.sortRequest.direction;
                } else {
                    debtorReceiptData.request.sortRequest.key = change;
                    debtorReceiptData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getDebtorReceipts(debtorReceiptData);
                }
                break;
            case "pageSize":
                debtorReceiptData.request.pageRequest[key] = change;
                debtorReceiptData.request.pageRequest.currentPage = 1;
                this.getDebtorReceipts(debtorReceiptData);
                break;
            case "currentPage":
                debtorReceiptData.request.pageRequest[key] = change;
                this.getDebtorReceipts(debtorReceiptData);
                break;


            default:
                debtorReceiptData.request.filterRequest[key] = change;

                if (key === 'amount' || key === 'discount' || key ==='period') {
                    if (change === "") {
                        debtorReceiptData.request.filterRequest[key] = 0;
                    }
                    else {
                        debtorReceiptData.request.filterRequest[key] = parseInt(change);
                    }
                }
                debtorReceiptData.request.pageRequest.currentPage = 1;
                this.getDebtorReceipts(debtorReceiptData);
                this.setState({ debtorReceiptData });
        }

    }

    getReceiptsHeading({ filterRequest }) {
        let receiptHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",


            },
            {
                key: "date",
                label: "Date",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <DatePicker
                        className="form-control form-control-sm"
                        selected={filterRequest.date}
                        onChange={date => this.handleChange(date, "date")}
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        isClearable
                        placeholderText="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        dropdownMode="select"
                    />
                   </div>

            },
            {
                key: "bank",
                label: "Bank",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type="text" id="bank" name="bank"
                    value={filterRequest.bank}
                    onChange={(e) => this.handleChange(e.target.value, "bank")}
                    placeholder="Search..." /></div>

            },

            {
                key: "bsb",
                label: "BSB",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"bsb"}
                        value={filterRequest.bsb || ''}
                        onChange={(e) => this.handleChange(e.target.value, "bsb")} />
                </div>
            },
            {
                key: "chequeNum",
                label: "DD C/Cards",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"chequeNum"}
                        value={filterRequest.chequeNum || ''}
                        onChange={(e) => this.handleChange(e.target.value, "chequeNum")} />
                </div>
            },
            {
                key: "amount",
                label: "Amount",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"number"}
                        placeholder={"Search..."}
                        name={"amount"}
                        value={filterRequest.amount || ''}
                        onChange={(e) => this.handleChange(e.target.value, "amount")} />
                </div>
            },
            {
                key: "period",
                label: "Period",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"number"}
                        placeholder={"Search..."}
                        name={"period"}
                        value={filterRequest.period || ''}
                        onChange={(e) => this.handleChange(e.target.value, "period")} />
                </div>
            },
            {
                key: "discount",
                label: "Discount",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"number"}
                        placeholder={"Search..."}
                        name={"discount"}
                        value={filterRequest.discount || ''}
                        onChange={(e) => this.handleChange(e.target.value, "discount")} />
                </div>
            },
            {
                key: "recDate",
                label: "Rec Date",
                type: "text",
                colSpan: 1,
                minWidth: "130px",
                sorterApplicable: true,
                valueClassName: "text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode:null
            }];

        return receiptHeading;
    }

    render() {
        const { accountID, debtorReceiptData, loading, debtor, searchText, loadingDebtor } = this.state;
        let { pageRequest, sortRequest, filterRequest } = debtorReceiptData.request;
        let receipts = this.getReceiptsHeading(debtorReceiptData.request);

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/customer')}>Customer</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Receipts</BreadcrumbItem>
                </Breadcrumb>

                <Row>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        <h6>Search Customer:</h6>
                        <SearchDebtorAcccount handleAccountChange={this.handleAccountChange}
                            selectedAccountID={accountID}
                            defaultAccountID={accountID}
                            includeChildren={true}
                            excludeClosedandInactive={false} />
                        <br />
                    </Col>
                </Row>

                <br />
                {debtorReceiptData.request.filterRequest.accountID ?
                    <div>
                        {loadingDebtor ? <Spinner color="primary" /> :
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Card>
                                        <CardHeader>Customer Details</CardHeader>
                                        <CardBody>
                                            {accountID ? <div><Link
                                                className="btn btn-primary"
                                                style={{ color: "white" }}
                                                title={"Click here to see account details"}
                                                to={"/customer/#" + accountID}
                                            >{accountID}</Link></div> : null}
                                            <br />
                                            {debtor.company ? < div > <strong>Company:</strong> &nbsp;&nbsp;{debtor.company}</div> : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                                {debtor.bankName ?
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card>
                                            <CardHeader>Bank Details</CardHeader>
                                            <CardBody>
                                                {debtor.bankName ? <div><strong>Bank Name:</strong>&nbsp;&nbsp;{debtor.bankName}</div> : null}
                                                {debtor.bankSuburb ? <div><strong>Bank Suburb:</strong>&nbsp;&nbsp;{debtor.bankSuburb}</div> : null}
                                                {debtor.bankBSB ? <div><strong>BSB:</strong>&nbsp;&nbsp;{debtor.bankBSB}</div> : null}
                                            </CardBody>
                                        </Card>
                                    </Col> : null}
                                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Card>
                                        <CardHeader>Address</CardHeader>
                                        <CardBody>
                                            {debtor.debtorAddress ? <a
                                                href={"http://maps.google.com/?q=" + debtor.debtorAddress}
                                                target="_blank">
                                                <i className="text-muted fa fa-map-marker"
                                                    aria-hidden="true" />
                                        &nbsp;{debtor.debtorAddress}
                                            </a> : null}
                                            {debtor.phone ? <div><strong>Phone:</strong>&nbsp;&nbsp;{debtor.phone}</div> : null}
                                            {debtor.fax ? <div><strong>Fax:</strong>&nbsp;&nbsp;{debtor.fax}</div> : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>}
                    </div>
                    : null}
                <br />
                {debtorReceiptData.request.filterRequest.accountID ?
                    <Card>

                        <CardBody>
                            <div className="mt-2 mb-4">
                                <DebtorOutstandingBalanceByDayCards debtor={debtor} />
                            </div>
                            <Row>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        {loading ? <p>Loading...</p> :
                                            <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {debtorReceiptData.response.totalRecords}
                                                {' '}entries</p>
                                        }
                                    </div>
                                </Col>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-right"}>
                                        <InputGroup className={"mb-2"}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-search"
                                                        aria-hidden="true" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"text"}
                                                name={"searchText"}
                                                placeholder={"Search..."}
                                                value={searchText}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                            />

                                        </InputGroup>
                                    </div>

                                </Col>


                            </Row>
                            <div>
                                <Table striped bordered responsive hover size="sm">
                                    <thead>
                                        <tr>
                                            {(receipts || []).map((item, index) => {
                                                return (
                                                    <th key={index}
                                                        onClick={() => this.handleChange(item.key, "sortKey")}
                                                        colSpan={item.colSpan}
                                                        className={item.labelClassName}
                                                        style={{ minWidth: item.minWidth }}>
                                                        {item.label}
                                                        {
                                                            item.sorterApplicable ?
                                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                                )} aria-hidden="true" /> : null
                                                        }

                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            {(receipts || []).map((item, index) => {
                                                return (
                                                    <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                        {item.searchNode}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    {(debtorReceiptData.response.totalRecords === 0 || debtorReceiptData.response.totalRecords === '0') ?
                                        <tbody><tr><td colSpan="9">No items...</td></tr></tbody> :
                                        <tbody>
                                            {(debtorReceiptData.response.records || []).filter((receipt) => this.searchFunction(receipt, searchText)).map((receipt, index) =>
                                                <tr key={index}>
                                                    <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                                    <td>{getLocalDateStringFromUtcDateString(receipt.date, 'DD/MM/YYYY')}</td>
                                                    <td>{receipt.bank}</td>
                                                    <td>{receipt.bSB}</td>
                                                    <td>{receipt.chequeNum}</td>
                                                    <td>{receipt.amount}</td>
                                                    <td>{receipt.period}</td>
                                                    <td>{receipt.discount}</td>
                                                    <td>{getLocalDateStringFromUtcDateString(receipt.recDate, 'DD/MM/YYYY')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    }
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <Row>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"} style={{ maxWidth: 200 }}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Show</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"select"}
                                                name={"pageSize"}
                                                value={pageRequest.pageSize}
                                                disabled={loading}
                                                onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                                <option value={50}>50 Rows</option>
                                                <option value={100}>100 Rows</option>
                                                <option value={500}>500 Rows</option>
                                            </Input>
                                        </InputGroup>


                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"float-right"}>
                                        <Pagination
                                            activePage={pageRequest.currentPage}
                                            itemsCountPerPage={pageRequest.pageSize}
                                            totalItemsCount={debtorReceiptData.response.totalRecords}
                                            pageRangeDisplayed={3}
                                            onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                            innerClass='pagination'
                                            activeLinkClass='active'
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </CardFooter>
                    </Card> : null}
                <ToastContainer/>
            </div>
        )
    };
}
