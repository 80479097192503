import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table} from 'reactstrap';
import {toast} from 'react-toastify';
import PurchaseOrderService from '../../../services/WINOInventory/PurchaseOrderService';
import cloneDeep from 'lodash/cloneDeep';
import NumberFormat from "react-number-format";

export default class PurchaseOrderItemModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            allItemsChecked: false,
            supplierID: 0,
            items: [],
        };

        this.getPurchaseOrderItemsBySupplierId = this.getPurchaseOrderItemsBySupplierId.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.supplierID !== this.state.supplierID) {
            this.setState({ supplierID: nextProps.supplierID }, () => { this.getPurchaseOrderItemsBySupplierId() });
        }
    }

    getPurchaseOrderItemsBySupplierId() {
        let { supplierID } = this.state;
        if (supplierID) {
            this.setState({ loading: true });
            PurchaseOrderService.listPurchaseOrderItemsBySupplierId(supplierID).then(res => {
                this.setState({ items: res.data, loading: false });
            }).catch(error => {
                this.setState({ loading: false });
                toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    selectAllCheckbox(value) {
        let { items } = this.state;
        items.forEach(item => {
            item.checked = value;
        })
        this.setState({ items, allItemsChecked: value});

    }

    selectCheckbox(value,index) {
        let { items } = this.state;
        items[index].checked = !value;

        this.setState({ items });
    }

    handleSubmit() {
        let { items } = this.state;
        this.props.handleSubmit(cloneDeep(items));
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { items, loading, allItemsChecked} = this.state;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                   Purchase Order Items
                </ModalHeader>
                <ModalBody>
                    <Table responsive bordered hoverable striped size="sm">
                        <thead>
                            <tr>
                                <th>
                                    <a href={"javascript:void(0)"}
                                        onClick={() => this.selectAllCheckbox(!allItemsChecked)}>
                                        {allItemsChecked ?
                                            <i className="fa fa-lg fa-check-square-o mr-2"
                                                aria-hidden="true" /> :
                                            <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true" />}
                                    </a>
                                </th>
                                <th>Item code</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Cost</th>
                            </tr>
                        </thead>
                        {loading ? <Spinner color={"primary"} /> :
                            <tbody>
                                {(items && items.length > 0) ?
                                (items || []).map((item, index) =>
                                    <tr key={index}>
                                        <td>
                                            <a href={"javascript:void(0)"}
                                                onClick={() => this.selectCheckbox(item.checked, index)}>
                                                {item.checked ?
                                                    <i className="fa fa-lg fa-check-square-o mr-2"
                                                        aria-hidden="true" /> :
                                                    <i className="fa fa-lg fa-square-o mr-2" aria-hidden="true" />}
                                            </a>
                                        </td>
                                        <td>{item.stock.prodCode}</td>
                                        <td>{item.description}</td>
                                        <td><NumberFormat
                                            value={item.qty}
                                            displayType={'text'}
                                            decimalScale={4}
                                            thousandSeparator={true} /></td>
                                        <td><NumberFormat
                                            prefix={'$'}
                                            value={item.cost}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true} /></td>
                                    </tr>):
                                    <tr>
                                        <td colSpan="5" className="text-center">No Items.</td>
                                    </tr>
                                   }
                            </tbody>
                        }
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button className="btn btn-sm btn-primary" color="primary" onClick={this.handleSubmit}>
                             Ok
                        </Button>
                        <Button className="btn btn-sm btn-secondary ml-2" color="secondary" onClick={() => toggle(false)}>
                            <i className="fa fa-times mr-2"></i> Cancel
                        </Button>
                    </div>
                </ModalFooter>

            </Modal>
        )
    }
}