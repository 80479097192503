import React, {Component} from 'react';
import {
    Badge, Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Spinner,
    Table
} from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';
import {Link} from "react-router-dom";
import moment from 'moment';
import {changeFormatOfDateString, getDateString, handleErrorMessage} from '../../services/CommonService';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import Pagination from "react-js-pagination";
import SearchCreditorAcccount from "../../components/search/SearchCreditorAcccount";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import * as FileSaver from 'file-saver';
import StockQtyFormat from "../../components/stock/StockQtyFormat";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../store/AppConstants";
export const maxDate = moment().add(2, 'years').toDate();
export const minDate = moment().subtract(20, 'years').toDate();

export default class PurchaseOrderPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTable: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "receivedDate",
                        direction: false
                    },
                    filterRequest: {
                        ordNum: null,
                        statusID: null,
                        supplierCode: '',
                        outstanding: false,
                        cancelled: false,
                        isPurchaseHistory: true,
                        startDate: "",
                        endDate: "",
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: false,
            loadingExport: false,
            prodCode: this.props.prodCode,
        };

        this.purchaseOrderService = new PurchaseOrderService();
        this.getPurchaseOrderItemData = this.getPurchaseOrderItemData.bind(this);
        this.refresh = this.refresh.bind(this);
        this.exportData = this.exportData.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.refresh(this.state.dataTable);

    }

    componentWillReceiveProps(nextprops) {
        if (this.state.prodCode !== nextprops.prodCode) {
            this.setState({prodCode: nextprops.prodCode}, function () {
                this.refresh(this.state.dataTable);
            });
        }
    }

    refresh(dataTable) {
        this.getPurchaseOrderItemData(dataTable, this.state.prodCode);
    }

    getPurchaseOrderItemData(dataTable, prodCode) {
        this.setState({loading: true});
        if (this.state.prodCode) {
            let request = cloneDeep(dataTable.request);
            if (request.filterRequest.startDate) {
                request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
            }
            if (request.filterRequest.endDate) {
                request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
            }
            this.purchaseOrderService.getPurchaseOrderItemHistoryByProdCode(request, prodCode).then(response => {
                dataTable.response = response.data;
                this.setState({dataTable, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    exportData() {
        let {dataTable, prodCode} = this.state;
        let request = cloneDeep(dataTable.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.setState({loadingExport: true});
        this.purchaseOrderService.exportPurchaseOrderItemHistoryByProdCode(request, prodCode).then(response => {
            this.setState({loadingExport: false});
            FileSaver.saveAs(response.data, "Purchase History" + ".xlsx");
        }).catch(error => {
            this.setState({loadingExport: false});
            toast.error(handleErrorMessage(error));
        });
    };

    getStore() {
        let store = [
            {
                key: "dateAdded",
                label: "PO Date",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "DD/MM/YYYY", 'DD  MMM YYYY')}</span>;
                }
            },
            {
                key: "supplierCode",
                label: "Supplier Code",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <Link className="btn btn-primary btn-sm"
                                 to={"/creditor/enquiry?" + queryString.stringify({accountID: value})}>
                        {value}
                    </Link>;
                }
            },
            {
                key: "supplier",
                label: "Supplier",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <small>{value}</small>;
                }
            },
            {
                key: "ordNum",
                label: "Ordnum",
                type: "text",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <Link to={"/purchase/order/enquiry?" + queryString.stringify({ordNum: value})}
                                 className="btn btn-sm btn-primary"
                                 title={"Click Here to see the Order Details"}
                                 style={{cursor: "pointer"}}>
                        {value}
                    </Link>;
                }

            },
            {
                key: "qty",
                label: "Qty",
                type: "text",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, order, index, ref) {
                    return <StockQtyFormat
                        eachUnitName={record.eachUnitName}
                        whUnitName={record.whUnitName}
                        whUnitQty={record.whUnitQty}
                        qty={value}
                        qtyType={STOCK_QTY_TYPE.each}
                        format={STOCK_QTY_FORMAT.each}
                        emptyPlaceholderText={"-"}
                        isPoManage={false}
                    />
                }
            },

             {
                 key: "currency",
                 label: "Supplier Currency",
                 type: "text",
                 colSpan: 1,
                 minWidth: 75,
                 sorterApplicable: true,
                 valueClassName: "text-center align-middle",
                 labelClassName: "hoverableItem align-middle",
                 searchNode: null,
                 render: function (value, storeItem, order, index, ref) {
                     return <span>{value}</span>;
                 }
             },

             {
                 key: "cost",
                 label: "Cost in Supplier Currency",
                 type: "text",
                 colSpan: 1,
                 minWidth: 70,
                 sorterApplicable: true,
                 valueClassName: "text-right align-middle",
                 labelClassName: "hoverableItem align-middle",
                 searchNode: null,
                 render: function (value, storeItem, order, index, ref) {
                     return <NumberFormat value={value}
                                          displayType={'text'}
                                          decimalScale={2}
                                          thousandSeparator={true}
                                          fixedDecimalScale={true}
                                          prefix={storeItem.currencySymbol}/>;
                 }
             }, {
                 key: "localCost",
                 label: <div>Cost
                     <div>in AUD</div>
                 </div>,
                 type: "text",
                 colSpan: 1,
                 minWidth: 65,
                 sorterApplicable: true,
                 valueClassName: "text-right align-middle",
                 labelClassName: "hoverableItem align-middle",
                 searchNode: null,
                 render: function (value, storeItem, order, index, ref) {
                     return <NumberFormat value={value}
                                          displayType={'text'}
                                          decimalScale={2}
                                          thousandSeparator={true}
                                          fixedDecimalScale={true}
                                          prefix={'$'}/>;
                 }
             }, {
                 key: "landedCost",
                 label: "Landed Cost in AUD",
                 type: "text",
                 colSpan: 1,
                 minWidth: 60,
                 sorterApplicable: true,
                 valueClassName: "text-right align-middle",
                 labelClassName: "hoverableItem align-middle",
                 searchNode: null,
                 render: function (value, storeItem, order, index, ref) {
                     return <NumberFormat value={value}
                                          displayType={'text'}
                                          decimalScale={2}
                                          thousandSeparator={true}
                                          fixedDecimalScale={true}
                                          prefix={'$'}/>;
                 }
             },{
                key: "receivedDate",
                label: "Received Date",
                type: "text",
                colSpan: 1,
                minWidth: 105,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle  text-right",
                searchNode: null,
                render: function (value, storeItem, order, index, ref) {
                    return <span> {changeFormatOfDateString(value, "DD/MM/YYYY", 'DD  MMM YYYY')}</span>;
                }
            },

        ];

        return store;
    }


    handleChange(change, key) {
        let {dataTable} = this.state;
        switch (key) {
            case "sortKey":
                if (change.sorterApplicable) {
                    if (dataTable.request.sortRequest.key === change.key) {
                        dataTable.request.sortRequest.direction = !dataTable.request.sortRequest.direction;
                    } else {
                        dataTable.request.sortRequest.key = change.key;
                        dataTable.request.sortRequest.direction = false;
                    }
                    if (!(change === "srNo")) {

                        this.refresh(dataTable);
                    }
                }
                break;
            case "pageSize":
                dataTable.request.pageRequest[key] = change;
                dataTable.request.pageRequest.currentPage = 1;
                this.refresh(dataTable);
                break;
            case "currentPage":
                dataTable.request.pageRequest[key] = change;
                this.refresh(dataTable);
                break;
            case "ordNum":
            default:
                dataTable.request.filterRequest[key] = change;
                switch (key) {
                    case 'ordNum':
                        if (change === "") {
                            dataTable.request.filterRequest[key] = null;
                        }
                        break;
                    case 'outstanding':
                        dataTable.request.filterRequest.cancelled = false;
                        break;
                    case 'cancelled':
                        dataTable.request.filterRequest.outstanding = false;
                        break;
                }

                dataTable.request.pageRequest.currentPage = 1;
                this.refresh(dataTable);
        }
    }

    render() {
        let {dataTable, loading,loadingExport} = this.state;
        let {pageRequest, sortRequest, filterRequest} = dataTable.request;
        let store = this.getStore();
        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true"/>&nbsp;Start Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.startDate}
                                onChange={date => this.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true"/>&nbsp;End Date</Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.endDate}
                                onChange={date => this.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Ordnum</Label>
                        <div className={"text-center"}>
                            <Input type="number" id="ordNum" name="ordNum"
                                   value={filterRequest.ordNum || ''}
                                   onChange={(e) => this.handleChange(e.target.value, "ordNum")}
                                   placeholder="Search...">

                            </Input>
                        </div>
                    </Col>

                    <Col xl={6} lg={6} md={4} sm={12} xs={12}>
                        <Label>Supplier</Label>
                        <div style={{zIndex: '2', position: 'relative'}}>
                            <SearchCreditorAcccount
                                handleAccountChange={(selected) => this.handleChange(selected, "supplierCode")}
                                defaultAccountID={filterRequest.supplierCode ? filterRequest.supplierCode : ""}
                                selectedAccountID={filterRequest.supplierCode ? filterRequest.supplierCode : ""}/>
                        </div>
                    </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center">
                    <p className={"mb-1"}>Showing{' '}
                        {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                        {' '}of {dataTable.response.totalRecords}
                        {' '}entries
                    </p>
                    <Button
                        size={"sm"}
                        color={"success"}
                        onClick={this.exportData}
                        disabled={loadingExport}
                        className={"ml-2"}
                    >
                        {loadingExport ? (
                            <Spinner color={"light"} size={"sm"} className={"mr-2"} />
                        ) : (
                            <i className={"fa fa-file-excel-o mr-2"} />
                        )}
                        Export
                    </Button>
                </div>

                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}
                         xs={12}>

                        <Table striped bordered responsive hover size='sm' className={"mb-1"}>
                            <thead>
                            <tr>
                                {(store || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{minWidth: item.minWidth}}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true"/> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>

                            {(dataTable.response.records || []).map((record, i) => {
                                return (
                                    <tr key={i}>
                                        {(store || []).map((storeItem, index) => {
                                            return (
                                                <td key={index} className={storeItem.valueClassName}>
                                                    {storeItem.render(record[storeItem.key], record, i, dataTable.response.records, this)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {
                                ((dataTable.response.records || []).length === 0)
                                    ? <tr>
                                        <td colSpan={12}>No orders found...</td>
                                    </tr>
                                    : null
                            }
                            </tbody>
                        </Table>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={dataTable.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination mb-0'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}