import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label, Modal,
    Row,
    Spinner,
    Table
} from "reactstrap";

import ProductionCapacityService from '../../services/production/ProductionCapacityService';
import {toast} from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getDateString, getSorted,handleErrorMessage} from '../../services/CommonService';

export default class ProductionCapacityDayWise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            weeksDays: [],
            startDate: new Date(),
            endDate: null,
            capacity: 0,
            saving: false,
            productionLinesList:[],
            selectedProductionLineIds:[],
            allSelected:false
        };
        this.productionCapacityService = new ProductionCapacityService();
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        let weeksDays = [];
        let weekArray = moment.weekdays();

        weekArray.forEach((week, index) => {
            if (week === "Saturday") {
                weeksDays.push({day: week, weekDayIndex: index, checked: true, displaySortIndex: 1});
            }
            else if (week === "Sunday") {
                weeksDays.push({ day: week, weekDayIndex: index, checked: true, displaySortIndex: 2 });
            }
            else {
                weeksDays.push({day: week, weekDayIndex: index, checked: false, displaySortIndex: 0});
            }
        });
        weeksDays = getSorted(weeksDays, 'displaySortIndex', true);
        this.setState({weeksDays});

        this.productionCapacityService.getProductionLineList().then(response => {
            let data = response.data;
            data = getSorted(data, "shortName", true);
            this.setState({productionLinesList: data});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    setDate(date, key) {
        this.setState({[key]: date});
    }

    handleChange(value, key) {
        this.setState({[key]: parseInt(value)});
    }

    selectRecord(index, value) {
        let {weeksDays} = this.state;
        weeksDays[index].checked = !value;

        this.setState({weeksDays});
    }


    handleSubmit() {
        let {startDate, endDate, capacity, weeksDays,selectedProductionLineIds} = this.state;

        if (!startDate || !endDate) {
            toast.info("Please select date range");
            return;
        }

        if (isNaN(capacity)) {
            toast.info("Please enter capacity");
            return;
        }
        if(selectedProductionLineIds.length === 0 )
        {
            toast.info("Please choose Production lines");
            return;
        }

        //getting the selected weeksDays
        let selectedWeeksDays = weeksDays.filter(week => week.checked);
        let selectedWeekDaysIndexes = [];
        selectedWeeksDays.forEach(w => {
            selectedWeekDaysIndexes.push(w.weekDayIndex);
        });

        let currDate = moment(startDate).startOf('day');
        let lastDate = moment(endDate).startOf('day');

        let datesForCapacityToBeSaved = [];

        while (currDate.diff(lastDate) <= 0) {
            //check if currDate's week day included in selected week days
            if (selectedWeekDaysIndexes.includes(currDate.weekday())) {
                datesForCapacityToBeSaved.push(getDateString(currDate, "DD/MM/YYYY"));
            }
            currDate.add(1, 'days');
        }

        let req = {
            productionDates: datesForCapacityToBeSaved,
            capacity: capacity,
            ProductionLineIds: this.state.selectedProductionLineIds,
        };

        this.setState({saving: true});
        this.productionCapacityService.saveProductionCapacityDayWise(req).then(res => {
            if (res.status === 200 || res.status === '200') {
                toast.success("Saved!");
                this.setState({saving: false});
                this.props.refreshAgain(true);
                this.setState({selectedProductionLineIds:[],allSelected:false});
            }
        }).catch(error => {
            this.setState({saving: false});
            toast.error(handleErrorMessage(error));
        });
    }

    selectProductionLine(Id){
        if(this.state.selectedProductionLineIds.includes(Id))
        { var array = [...this.state.selectedProductionLineIds];
            var index = array.indexOf(Id)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({selectedProductionLineIds: array,allSelected:false});
            }
        }
        else {
            this.state.selectedProductionLineIds.push(Id);
            this.setState({
                selectedProductionLineIds: this.state.selectedProductionLineIds
            });
        }
    }

    selectAllProductionLine(){
        if(this.state.allSelected)
        {
            this.setState({selectedProductionLineIds:[],allSelected:false});
        }
        else{
            {(this.state.productionLinesList || []).map((option,index)=>{
                    if(!this.state.selectedProductionLineIds.includes(option.productionLineId)) {
                        this.state.selectedProductionLineIds.push(option.productionLineId);
                        this.setState({
                            selectedProductionLineIds: this.state.selectedProductionLineIds
                        })
                    }
                }
            )}
            this.setState({allSelected:true});
        }
    }

    render() {
        let {weeksDays, endDate, startDate, capacity, saving,productionLinesList,selectedProductionLineIds,allSelected} = this.state;
        return (
            <div>
                <Row>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Card >
                            <CardBody>
                                <p className="mb-1"><strong>Select Production Line</strong></p>
                                <div>
                                    <Button color={"link"}
                                            className={"mr-2"}
                                            onClick={() => this.selectAllProductionLine()}>
                                        {allSelected?
                                            <i className="fa fa-lg fa-check-square-o" aria-hidden="true"/> :
                                            <i className="fa fa-lg fa-square-o" aria-hidden="true"/>}
                                    </Button>Select All
                                </div>
                                {(productionLinesList || []).map((option, index) => {
                                    if (option.shortName !== "PPE") {
                                        return <div>
                                            <Button color={"link"}
                                                className={"mr-2"}
                                                onClick={() => this.selectProductionLine(option.productionLineId)}
                                            >
                                                {selectedProductionLineIds.includes(option.productionLineId) ?
                                                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                                                    <i className="fa fa-lg fa-square-o" aria-hidden="true" />}
                                            </Button>
                                            {option.shortName === 'COMPONENTS' ? 'W/H PICKS' : option.shortName}
                                        </div>
                                    }
                                }   
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Card>
                            <CardBody>
                                <p className="mb-1"><strong>Select weekdays</strong></p>
                                <p>Select weekdays for which you want to change the capacity</p>
                                <Table responsive size="sm" className={"mb-0"}>
                                    <tbody>
                                    {weeksDays.map((week, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Button color={"link"}
                                                        className={"mr-2"}
                                                        onClick={() => this.selectRecord(index, week.checked)}>
                                                    {week.checked ?
                                                        <i className="fa fa-lg fa-check-square-o" aria-hidden="true"/> :
                                                        <i className="fa fa-lg fa-square-o" aria-hidden="true"/>}
                                                </Button>{week.day}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Card>
                            <CardBody>
                                <p className="mb-1"><strong>Select date range</strong></p>
                                <p>Capacity will be updated for selected weekdays fallen in this selected date range</p>
                                <FormGroup>
                                    <Label>
                                        <i className="fa fa-calendar mr-2" aria-hidden="true"/>From</Label>
                                    <DatePicker
                                        className="form-control"
                                        selected={startDate}
                                        onChange={date => this.setDate(date, "startDate")}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="dd/mm/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        withPortal
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        <i className="fa fa-calendar mr-2" aria-hidden="true"/>To
                                    </Label>
                                    <DatePicker
                                        className="form-control"
                                        selected={endDate}
                                        onChange={date => this.setDate(date, "endDate")}
                                        selectsEnd
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="dd/mm/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        minDate={startDate}
                                        scrollableYearDropdown
                                        withPortal
                                    />
                                </FormGroup>
                            </CardBody>
                        </Card>


                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Card>
                            <CardBody>
                                <p className="mb-1"><strong>Enter capacity</strong></p>
                                <p>Entered capacity will be applied for all production lines</p>

                                <FormGroup>
                                    <Label>Capacity</Label>
                                    <Input type={"number"}
                                           name={"capacity"}
                                           value={capacity}
                                           placeholder={'Enter capacity here'}
                                           onChange={(e) => this.handleChange(e.target.value, 'capacity')}/>
                                </FormGroup>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <div className={"text-center mt-2"}>
                    <Button color={"primary"} onClick={this.handleSubmit}
                            disabled={saving}>
                        {saving
                            ? <Spinner size="sm"
                                       className={"mr-2"}
                                       color={"light"}/>
                            : <i className="fa fa-floppy-o mr-2"/>}
                        {saving ? "Saving" : "Save"} </Button>
                </div>
            </div>
        );
    }
}

