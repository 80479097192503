import axios from "./axios";
import axiosFileDownloader from "./axiosFileDownloader";

class EmailLogService {

    static Instance() {
        return new EmailLogService();
    }

    getEmailLogs(req) {
        return axios.post('api/email-log/list', req);
    }

    generateLogsInExcelReport(requestBody) {
        return axiosFileDownloader.post('api/email-log/generate/excel', requestBody);
    }
}

export default EmailLogService.Instance();