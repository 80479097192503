import React, {Component} from 'react';
import {Spinner} from 'reactstrap';
import StockService from './../../../services/StockService';
import {
    getColors,
    getDistinctValues,
    getFinancialMonthList,
    getFinancialYear,
    handleErrorMessage,
    numberWithThousandSeparator
} from './../../../services/CommonService';
import {toast, ToastContainer} from 'react-toastify';
import {Line} from 'react-chartjs-2';
import FinancialYearMultipleDropdown from '../../../components/FinancialYearMultipleDropdown';
import moment from 'moment';
import {cloneDeep, isEmpty, camelCase} from 'lodash';

const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
        duration: 0
    },
    hover: {
        animationDuration: 0
    },
    responsiveAnimationDuration: 0,
    legend: {
        position: 'top'
    },
    scales: {
        xAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true
            }, scaleLabel: {
                display: true,
                labelString: "Month"
            }
        }],
        yAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true,
                callback(value) {
                    //return <NumberFormat value={value} displayType={'text'} decimalScale={0} thousandSeparator={true} />
                    return Number(value).toLocaleString('en')
                }
            }, scaleLabel: {
                display: true,
                labelString: "Sold Qty"
            }
        }]
    },

    plugins: {
        datalabels: {
            display: 'auto',
            anchor: 'end',
            clamp: true,
            align: 'end',
            offset: 3,
            backgroundColor: function (context) {
                return context.dataset.backgroundColor;
            },
            color: 'white',
            font: {
                weight: 'bold'
            },
            formatter: function (value, data) {
                value = numberWithThousandSeparator(value);
                return value;
            }
        }
    }
};

export default class StockEnquiryPageSalesHistoryTabSoldUnitsByMonthComparison extends Component {

    constructor(props) {
        super(props);
        let currentFY = getFinancialYear(moment(new Date()));
        let previousFY = getFinancialYear(moment(new Date().setFullYear(new Date().getFullYear() - 1)));
        this.state = {
            prodCode: this.props.prodCode,
            selectedYear:
                [{'label': currentFY, 'value': currentFY}, {
                    'label': previousFY,
                    'value': previousFY
                }]
            ,
            isLoading: false,
            chartData: null
        };
        this.stockService = new StockService();
        this.refresh = this.refresh.bind(this);
        this.getSalesSummary = this.getSalesSummary.bind(this);
        this.yearChange = this.yearChange.bind(this);
        this.getChartMonthLabel = this.getChartMonthLabel.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.prodCode !== nextProps.prodCode) {
            this.setState({prodCode: nextProps.prodCode}, () => {
                this.refresh();
            });
        }
    }

    refresh() {
        let years = getDistinctValues(this.state.selectedYear, 'value');
        let commaSeparatedYears = years.join();
        this.getSalesSummary(commaSeparatedYears);
    }

    getChartMonthLabel() {
        let chartMonthLabel = [];
        let currentFY = getFinancialYear(moment(new Date()));
        let months = getFinancialMonthList(currentFY);
        (months || []).forEach(m => {
            chartMonthLabel.push(m.shortName);
        });
        return chartMonthLabel;
    }

    getSalesSummary(commaSeparatedYears) {
        let {prodCode} = this.state;
        if (commaSeparatedYears) {
            this.setState({isLoading: true});
            this.stockService.stockSoldUnitsMonthYearForLineChart(prodCode, commaSeparatedYears).then(response => {
                if (response.data) {
                    let colors = getColors();
                    let chartMonthLabel = this.getChartMonthLabel();
                    let chartData = {
                        labels: chartMonthLabel,
                        datasets: []
                    };
                    let yearIndex = 0;
                    for (let year in response.data) {
                        let temp = {
                            label: year,
                            data: [],
                            fill: false,
                            borderColor: colors[yearIndex],
                            backgroundColor: colors[yearIndex]
                        };
                        let months = getFinancialMonthList(year);
                        (months || []).map(m => {
                            let index = response.data[year].findIndex(x => x.month === m.monthNumber);
                            if (index > -1) {
                                temp.data.push(Math.abs(Math.round(response.data[year][index].qty)));
                            }
                            else {
                                temp.data.push(Math.round(0));
                            }
                        });
                        chartData.datasets.push(temp);
                        yearIndex++;
                    }
                    this.setState({chartData, isLoading: false});
                }
            }).catch(error => {
                this.setState({isLoading: false});
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        }

    }

    yearChange = (selectedYear) => {
        let commaSeparatedYears = '';
        if (selectedYear) {
            for (let i = 0; i < selectedYear.length; i++) {
                commaSeparatedYears += selectedYear[i].value + ","
            }
            commaSeparatedYears = commaSeparatedYears.slice(0, -1);
        } else {
            selectedYear = {};
        }
        this.setState({selectedYear}, () => {
            this.getSalesSummary(commaSeparatedYears);
        });
    };

    render() {
        let {chartData, selectedYear, isLoading} = this.state;
        return (

            <div>
                <div className={"d-flex justify-content-between mb-2"}>
                    <div className={"flex-fill align-self-center"}><h6>Sales Trends</h6></div>
                    <div className={"flex-fill align-self-center"}>
                        <FinancialYearMultipleDropdown handleChange={this.yearChange}
                                                       selectedYear={selectedYear}
                        />
                    </div>
                </div>
                {isLoading
                    ? <Spinner className="primary"/>
                    : (
                        (chartData && !isEmpty(chartData))
                            ? <Line data={chartData} options={chartOptions}/>
                            : null
                    )
                }
                <ToastContainer/>
            </div>

        );
    }
}