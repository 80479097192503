import React, {Component} from 'react';
import {Spinner} from 'reactstrap';
import CustomerService from './../../../services/CustomerService';
import {
    getColors,
    getDistinctValues,
    getFinancialMonthList,
    getFinancialYear,
    handleErrorMessage,
    numberWithThousandSeparator
} from './../../../services/CommonService';
import {toast, ToastContainer} from 'react-toastify';
import {Line} from 'react-chartjs-2';
import FinancialYearMultipleDropdown from '../../FinancialYearMultipleDropdown';
import moment from 'moment';

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        duration: 0
    },
    hover: {
        animationDuration: 0
    },
    responsiveAnimationDuration: 0,
    legend: {
        position: 'top'
    },
    scales: {
        xAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true
            }, scaleLabel: {
                display: true,
                labelString: "Month"
            }
        }],
        yAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true,
                callback(value) {
                    //return <NumberFormat value={value} displayType={'text'} decimalScale={0} thousandSeparator={true} />
                    return Number(value).toLocaleString('en')
                }
            }, scaleLabel: {
                display: true,
                labelString: "Spend"
            }
        }]
    },

    plugins: {
        datalabels: {
            display: 'auto',
            anchor: 'end',
            clamp: true,
            align: 'end',
            offset: 3,
            backgroundColor: function (context) {
                return context.dataset.backgroundColor;
            },
            color: 'white',
            font: {
                weight: 'bold'
            },
            formatter: function (value, data) {
                value = numberWithThousandSeparator(value);
                return "$" + value;
            }
        }
    }
};

export default class SalesByMonthComparison extends Component {

    constructor(props) {
        super(props);
        let currentFY = getFinancialYear(moment(new Date()));
        let previousFY = getFinancialYear(moment(new Date().setFullYear(new Date().getFullYear() - 1)));
        this.state = {
            accountID: this.props.accountID,
            selectedYear:
                [{'label': currentFY, 'value': currentFY}, {
                    'label': previousFY,
                    'value': previousFY
                }]
            ,
            commaSeparatedYears: '',
            isLoading: false,
            chartMonthLabel: [],
            includeChildAccount: this.props.includeChildAccount ? this.props.includeChildAccount : false
        };
        this.customerService = new CustomerService();
        this.getSalesSummary = this.getSalesSummary.bind(this);
        this.yearChange = this.yearChange.bind(this);
        this.getChartMonthLabel = this.getChartMonthLabel.bind(this);
    }

    componentDidMount() {
        this.getChartMonthLabel();
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.includeChildAccount !== nextProps.includeChildAccount) {
            this.setState({ accountID: nextProps.accountID, includeChildAccount: nextProps.includeChildAccount }, () => {
                this.refresh();
            });
        }
    }

    refresh() {
        let {commaSeparatedYears} = this.state;

        let years = getDistinctValues(this.state.selectedYear, 'value');
        commaSeparatedYears = years.join();
        this.setState({commaSeparatedYears}, () => {
            this.getSalesSummary(commaSeparatedYears);
        });
    }

    getChartMonthLabel() {
        let chartMonthLabel = [];
        let currentFY = getFinancialYear(moment(new Date()));
        let months = getFinancialMonthList(currentFY);
        (months || []).forEach(m => {
            chartMonthLabel.push(m.shortName);
        });
        this.setState({chartMonthLabel});

    }

    getSalesSummary(years) {
        if (years) {
            this.setState({isLoading: true});
            years = years.toString();
            this.customerService.CustomerMonthlySalesbyYearLineChart(this.state.accountID, years, this.state.includeChildAccount).then(response => {
                if (response.data) {
                    let colors = getColors();
                    let chartData = {
                        labels: this.state.chartMonthLabel,
                        datasets: []
                    };
                    let yearIndex = 0;
                    for (let year in response.data) {
                        let temp = {
                            label: year,
                            data: [],
                            fill: false,
                            borderColor: colors[yearIndex],
                            backgroundColor: colors[yearIndex]
                        };
                        let months = getFinancialMonthList(year);
                        (months || []).map(m => {
                            let index = response.data[year].findIndex(x => x.month === m.monthNumber);
                            if (index > -1) {
                                temp.data.push(Math.round(response.data[year][index].sumTotal));
                            }
                            else {
                                temp.data.push(Math.round(0));
                            }
                        });
                        chartData.datasets.push(temp);
                        yearIndex++;
                    }
                    this.setState({chartData, isLoading: false});
                }
            }).catch(error => {
                this.setState({isLoading: false});
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        }

    }

    yearChange = (selectedYear) => {
        if (selectedYear) {
            let {commaSeparatedYears} = this.state;
            commaSeparatedYears = '';
            for (let i = 0; i < selectedYear.length; i++) {
                commaSeparatedYears += selectedYear[i].value + ","
            }
            commaSeparatedYears = commaSeparatedYears.slice(0, -1);
            this.setState({commaSeparatedYears: commaSeparatedYears, selectedYear: selectedYear}, () => {
                this.getSalesSummary(commaSeparatedYears);
            });
        } else {
            this.setState({selectedYear: {}});
        }
    };

    render() {
        let {chartData, selectedYear, isLoading} = this.state;
        if (!chartData) {
            return null;
        }

        return (

            <div>
                <div className={"d-flex justify-content-between mb-2"}>
                    <div className={"flex-fill align-self-center"}><h6>Purchase Trends</h6></div>
                    <div className={"flex-fill align-self-center"}>
                        <FinancialYearMultipleDropdown handleChange={this.yearChange}
                                                       selectedYear={selectedYear}
                        />
                    </div>
                </div>
                {isLoading ? <Spinner className="primary"/>
                    :
                    <Line data={chartData} options={chartOptions}/>
                }
                <ToastContainer/>
            </div>

        );
    }
}