import React, { Component } from 'react';
import { Input, Row, Col, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { maxDate, minDate } from './OrderGatewayCommon';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import CustomerService from '../../services/CustomerService';
import courierService from '../../services/shipment/CourierService';
import { toast } from 'react-toastify';
import {handleErrorMessage} from "../../services/CommonService";
import OrderService from '../../services/OrderService';
import SearchImportSalesOrderStatusMulti from '../search/SearchImportSalesOrderStatusMulti';
import SearchJobRef from '../search/SearchJobRef';
import SearchCustOrdNum from '../search/SearchCustOrdNum';
import SearchImportSourceOrderID from '../search/SearchImportSourceOrderID';

export default class SearchBannerGatewayOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            couriers: [],
            importSalesOrderSources: [],
            importSalesOrderStatuses: [],
            selectedImportSalesOrderSource: "",
            selectedImportSourceOrderID: "",
            selectedImportSalesOrderStatuses: "",
            selectedJobRef: "",
            selectedCustOrdNum: "",
        };
        this.getImportSalesOrderSources = this.getImportSalesOrderSources.bind(this);
        this.getCouriers = this.getCouriers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.customerService = new CustomerService();
        this.orderService = new OrderService();
    }

    componentDidMount() {
        let { type } = this.props;
        if (type) {
            switch (type) {
                case 'IMP':
                    this.getImportSalesOrderSources();
                    break;
                case 'DISP':
                    this.getCouriers();
                    break;
                default:
                    break;
            }
        }
    }

    getImportSalesOrderSources() {
        this.orderService.getImportSalesOrderSources().then(response => {
            let importSalesOrderSources = response.data;
            this.setState({ importSalesOrderSources });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCouriers() {
        courierService.getCouriers().then(response => {
            let couriers = response.data;
            this.setState({ couriers });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    returnListofStatuses(salesOrderJobStatuses, type) {
        let filtered_status = [];
        switch (type) {
            case "PEN":
                filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
                    return stat.id >= 30 && stat.id < 44;
                });
                break;
            case "QUOTE":
                filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
                    return stat.id == 10;
                });
                break;
            case "IMP":
                filtered_status = salesOrderJobStatuses.statuses;
                break;
            case "SUB":
                filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
                    return stat.id >= 5 && stat.id <= 6;
                });
                break;
            case "PROD":
                filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
                    return stat.id >= 45 && stat.id < 89;
                });
                break;
            case "DISP":
                filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
                    return stat.id >= 90;
                });
                break;
            case "CAN":
                filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
                    return stat.id == 1;
                });
                break;
        }
        return filtered_status;
    }

    handleChange(accountID) {
        this.props.handleChange(accountID, 'accountID');
    }

    handleFilterChange(value, key) {
        let { handleChange } = this.props;
        switch (key) {
            case 'importSalesOrderSourceID':
                this.setState({ selectedImportSalesOrderSource: value });
                break;
            case 'importSourceOrderID':
                this.setState({ selectedImportSourceOrderID: value });
                break;
            case 'importSalesOrderStatuses':
                this.setState({ selectedImportSalesOrderStatuses: value });
                break;
            case 'jobRef':
                this.setState({ selectedJobRef: value });
                break;
            case 'custOrdNum':
                this.setState({ selectedCustOrdNum: value });
                break;
            default:
                break;
        }
        handleChange(value, key);
    }

    render() {
        let { filterRequest, salesOrderJobStatuses, type, branches, downloading, reps, handleChange } = this.props;
        let { importSalesOrderSources, selectedImportSalesOrderSource, selectedImportSourceOrderID, selectedImportSalesOrderStatuses, selectedJobRef, selectedCustOrdNum, couriers } = this.state;
        let filtered_status = this.returnListofStatuses(salesOrderJobStatuses, type);
        return (
            <div>
                <Row>
                    {/*<Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Order No</Label>
                        <div className={"text-center"}>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"ordNum"}
                                value={filterRequest.ordNum}
                                onChange={(e) => handleChange(e.target.value, "ordNum")} />
                        </div>
                    </Col>*/}
                    {(type === 'IMP') ?
                        <>
                            <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                                <Label>Import Source</Label>
                                <Input type={"select"}
                                    name={"importSalesOrderSourceID"}
                                    value={selectedImportSalesOrderSource || ""}
                                    onChange={(e) => this.handleFilterChange(e.target.value, "importSalesOrderSourceID")}>
                                    <option value={""}>All</option>
                                    {importSalesOrderSources.map((option, index) =>
                                        <option key={index} value={option.id}>{option.sourceShortName}</option>
                                    )}
                                </Input>
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                                <Label>Import Source Order ID</Label>
                                <SearchImportSourceOrderID
                                    handleImportSourceOrderIDChange={(importSourceOrderID) => this.handleFilterChange(importSourceOrderID, "importSourceOrderID")}
                                    selectedImportSourceOrderID={selectedImportSourceOrderID}
                                    defaultImportSourceOrderID={filterRequest.ImportSourceOrderID}
                                />
                            </Col>
                            <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                                <Label>Job Ref.</Label>
                                <SearchJobRef
                                    handleJobRefChange={(jobRef) => this.handleFilterChange(jobRef, "jobRef")}
                                    selectedJobRef={selectedJobRef}
                                    defaultJobRef={filterRequest.JobRef}
                                />
                            </Col>
                            <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                                <Label>Cust. Ord. Ref.</Label>
                                <SearchCustOrdNum
                                    handleCustOrdNumChange={(custOrdNum) => this.handleFilterChange(custOrdNum, "custOrdNum")}
                                    selectedCustOrdNum={selectedCustOrdNum}
                                    defaultCustOrdNum={filterRequest.custOrdNum}
                                />
                            </Col>
                        </>
                        : null
                    }
                    {(type !== 'IMP') ?
                        <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                            <Label>Account</Label>
                            <div style={{ zIndex: '2', position: 'relative' }}>
                                {/*<Input type={"text"}
                                placeholder={"Search..."}
                                name={"accountID"}
                                value={filterRequest.accountID}
                                onChange={(e) => handleChange(e.target.value, "accountID")} />*/}
                                <SearchDebtorAcccount
                                    handleAccountChange={this.handleChange}
                                    selectedAccountID={filterRequest.accountID}
                                    defaultAccountID={filterRequest.accountID}
                                />
                            </div>
                        </Col>
                        : null
                    }
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.startDate}
                                onChange={date => handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control"
                                selected={filterRequest.endDate}
                                onChange={date => handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    {(type !== 'IMP') ?
                        <>
                            <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                                <Label>Status</Label>
                                <div>
                                    <Input type={"select"}
                                        name={"salesOrderJobStatusId"}
                                        value={filterRequest.salesOrderJobStatusId ? filterRequest.salesOrderJobStatusId : ""}
                                        onChange={(e) => handleChange(e.target.value, "salesOrderJobStatusId")}>
                                        <option value={""}>All</option>
                                        {(filtered_status || []).map((option, index) =>
                                            <option key={index} value={option.id}>{option.statusDescription}</option>
                                        )}
                                    </Input>
                                </div>
                            </Col>
                            <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                                <Label>Branch</Label>
                                <div>
                                    <Input type={"select"}
                                        name={"branch"}
                                        value={filterRequest.branch}
                                        onChange={(e) => handleChange(e.target.value, "branch")}>
                                        <option value={"All"}>All</option>
                                        {(branches || []).map((option, index) =>
                                            <option key={index} value={option.branchNum}>{option.shortName}</option>
                                        )}
                                    </Input>
                                </div>
                            </Col>
                        </>
                        :
                        <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                            <Label>Import Status</Label>
                            <SearchImportSalesOrderStatusMulti
                                handleSelection={(importSalesOrderStatuses) => this.handleFilterChange(importSalesOrderStatuses, "importSalesOrderStatuses")}
                                selectedImportSalesOrderStatuses={selectedImportSalesOrderStatuses}
                                defaultImportSalesOrderStatuses={""}
                                filter={{
                                    importSalesOrderStatuses: []
                                }}
                            />
                        </Col>
                    }
                    {(!['IMP', 'DISP'].includes(type)) ?
                        <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                            <Label>Rep</Label>
                            <div>
                                <Input type={"select"}
                                    placeholder={"Search RepCode..."}
                                    name={"repCode"}
                                    value={filterRequest.repCode || ''}
                                    onChange={(e) => handleChange(e.target.value, "repCode")} >
                                    <option value="ALL">All</option>
                                    {reps.map((rep, index) =>
                                        <option key={index} value={rep.repCode}>{rep.name}</option>
                                    )}
                                </Input>
                            </div>
                        </Col>
                        : null
                    }
                    {(type === 'DISP') ?
                        <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                            <Label>Delivery Method</Label>
                            <Input type={"select"}
                                name={"courier"}
                                value={filterRequest.courier}
                                onChange={(e) => handleChange(e.target.value, "courier")}>
                                <option value={"All"}>All</option>
                                {couriers.map((data, index) =>
                                    <option key={index} value={data.courier}> {data.courier}</option>
                                )}
                            </Input>
                        </Col>
                        : null
                    }
                </Row>

            </div>
        );
    }
}