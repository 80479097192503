import React, {Component} from 'react';
import { ToastContainer } from 'react-toastify';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Row, FormGroup, Label, Col, Input, BreadcrumbItem, Breadcrumb,
    Spinner, FormText, Badge, Card, CardHeader
} from 'reactstrap';
import CustomerDashboardQuickLinks from '../../components/dashboard/CustomerDashboard/CustomerDashboardQuickLinks';
import ProductBrochures from './ProductBrochures';
import ProductOrderForms from './ProductOrderForms';
import FAQPageList from '../../pages/faq/FAQPageList';
import { Link } from "react-router-dom";

export default class ProductUploads extends Component {

    render() {
        return (
            <div>
                <div className="text-center">
                    <CustomerDashboardQuickLinks size={"sm"}/>
                    <hr/>
                </div>
                <div> 
                    <ProductOrderForms/>
                    <br/>
                    <ProductBrochures />
                    <br />
                    <FAQPageList />
                </div>
                <ToastContainer/>
            </div>
        );
    }
}