import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_VERTICAL_INDOOR_PRODCODE} from "../../../../../../store/AppConstants";

class VerticalIndoorUtil {

    static Instance() {
        return new VerticalIndoorUtil();
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < product.items[itemIndex].configuration.quantity.min) || (product.items[itemIndex].configuration.quantity.selected.value > product.items[itemIndex].configuration.quantity.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                    };
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;


            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid;
    }

    updateRules(key, product, itemIndex, order) {
        switch (key) {
            case "quantity":
                product.items[itemIndex].configuration.quantity.min = 1;
                product.items[itemIndex].configuration.quantity.max = 10000;
                break;
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                product.items[itemIndex].configuration.width.min = 389;
                product.items[itemIndex].configuration.width.max = 5800;
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 250;
                product.items[itemIndex].configuration.drop.max = 3820;
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let optionIndex, temp, condition1, condition2, condition3, label, attribute,
            width, comment, stocks, setIndex, bladeSize;
        width = product.items[itemIndex].configuration.width.selected.value;

        switch (key) {
            case "set":
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    prodCode: PRODUCT_BUILDER_VERTICAL_INDOOR_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false,
                    description: "default"
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";

                stocks = [{
                    id: null,
                    prodCode: product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                    keywayQuantityFormula: product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                    wastageFormula: product.items[itemIndex].configuration.fabricColour.selected.value ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 0.001,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true,
                    description: "fabric"
                }];

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "headRailColour":
                attribute = "Head Rail";
                label = product.items[itemIndex].configuration.headRailColour.selected.value.optionKey;
                comment = "";
                stocks = [];
                stocks = product.items[itemIndex].configuration.headRailColour.selected.value.stocks;
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.headRailColour.selected.stocks = stocks;
                break;
            case "wand":
                attribute = "Wand Size";
                label = product.items[itemIndex].configuration.wand.selected.value.optionKey;
                comment = "";
                stocks = [];
                let wandQty = 1;
                switch (product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey) {
                    case "Centre Bunch":
                    case "Centre Open":
                        wandQty = 2;
                        break;
                    case "Left":
                    case "Right":
                        wandQty = 1;
                        break;
                }

                product.items[itemIndex].configuration.wand.selected.value.wandQty = wandQty;
                stocks = product.items[itemIndex].configuration.wand.selected.value.stocks;
                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = wandQty;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.wand.selected.stocks = stocks;
                break;
            case "brackets":
                attribute = "Brackets";
                label = product.items[itemIndex].configuration.brackets.selected.value.optionKey;
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.brackets.selected.value = {};
                // must must
                condition1 = "Must";
                condition2 = product.items[itemIndex].configuration.headRailColour.selected.value.optionKey;
                optionIndex = product.items[itemIndex].configuration.brackets.finalOptions.findIndex(o => o.condition1 === condition1 && o.condition2 === condition2);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.brackets.selected.value = product.items[itemIndex].configuration.brackets.finalOptions[optionIndex];
                    stocks = [...stocks, ...product.items[itemIndex].configuration.brackets.selected.value.stocks];
                }

                // Outside only
                if (product.items[itemIndex].configuration.mount.selected.value.optionKey === "Outside") {
                    condition1 = "Outside";
                    condition2 = product.items[itemIndex].configuration.headRailColour.selected.value.optionKey;
                    condition3 = product.items[itemIndex].configuration.bladeSize.selected.value.optionKey;
                    optionIndex = product.items[itemIndex].configuration.brackets.finalOptions.findIndex(o => o.condition1 === condition1 && o.condition2 === condition2 && o.condition3 === condition3);
                    if (optionIndex > -1) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.brackets.finalOptions[optionIndex].stocks];
                    }


                    condition1 = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                    condition2 = order.accountID.includes("01500") ? "Bunnings" : "default";
                    condition3 = condition2;
                    optionIndex = product.items[itemIndex].configuration.brackets.finalOptions.findIndex(o => o.condition1 === condition1 && o.condition2 === condition2 && o.condition3 === condition3);
                    if (optionIndex > -1) {
                        stocks = [...stocks, ...product.items[itemIndex].configuration.brackets.finalOptions[optionIndex].stocks];
                    }
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.brackets.selected.stocks = stocks;
                break;
            case "extensionBrackets":
                attribute = "Extension Brackets";
                label = "";
                comment = "";
                stocks = [];

                if (product.items[itemIndex].configuration.extensionBrackets.selected.value) {
                    condition1 = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                }
                optionIndex = product.items[itemIndex].configuration.extensionBrackets.finalOptions.findIndex(o => o.condition1 === condition1);
                if (optionIndex > -1) {
                    stocks = product.items[itemIndex].configuration.extensionBrackets.finalOptions[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.extensionBrackets.selected.stocks = stocks;
                break;
            case "magnet":
                attribute = "Magnets";
                label = "";
                comment = "";
                stocks = [];

                optionIndex = product.items[itemIndex].configuration.magnet.finalOptions.findIndex(o => (width >= o.primaryAttributeMin) && (width <= o.primaryAttributeMax));
                optionIndex = optionIndex > -1 ? optionIndex : product.items[itemIndex].configuration.magnet.finalOptions.length - 1;
                product.items[itemIndex].configuration.magnet.selected.value = product.items[itemIndex].configuration.magnet.finalOptions[optionIndex];

                product.items[itemIndex].configuration.magnet.selected.value.magnetQty = 0;
                condition1 = product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey;
                setIndex = (product.items[itemIndex].configuration.magnet.selected.value.sets || []).findIndex(s => s.condition1 === condition1);
                if (setIndex > -1) {
                    product.items[itemIndex].configuration.magnet.selected.value.selectedSet = product.items[itemIndex].configuration.magnet.selected.value.sets[setIndex];
                    stocks = product.items[itemIndex].configuration.magnet.selected.value.selectedSet.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    product.items[itemIndex].configuration.magnet.selected.value.magnetQty = stockItem.quantityMultiplier;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.magnet.selected.stocks = stocks;
                break;
            case "carrier":
                attribute = "Carrier";
                label = "";
                comment = "";
                stocks = [];
                let carrierQty = 0;
                let bladeRailSpace = 78;
                bladeSize = 89;
                switch (product.items[itemIndex].configuration.bladeSize.selected.value.optionKey) {
                    case "89mm":
                        bladeRailSpace = 78;
                        bladeSize = 89;
                        break;
                    case "127mm":
                        bladeRailSpace = 114.5;
                        bladeSize = 127;
                        break;

                }

                let W = product.items[itemIndex].configuration.headRailColour.selected.value.trackLength;// rail cut length
                let B = bladeRailSpace;//Blade rail space
                let S = bladeSize;// Blade size
                let C = carrierQty; //Carriers Quantity | Output

                switch (product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey) {
                    case "Left":
                    case "Right":
                    case "Centre Bunch":
                        C = Math.ceil((W - (S - B)) / B);
                        break;
                    case "Centre Open":
                        C = Math.ceil(((W - (S - B)) / 2) / B) * 2;
                        break;
                    default:
                        C = -1;
                }
                carrierQty = C;
                condition1 = product.items[itemIndex].configuration.bladeSize.selected.value.optionKey;
                optionIndex = product.items[itemIndex].configuration.carrier.finalOptions.findIndex(o => o.condition1 === condition1);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.carrier.selected.value = product.items[itemIndex].configuration.carrier.finalOptions[optionIndex];
                    stocks = product.items[itemIndex].configuration.carrier.selected.value.stocks;
                    product.items[itemIndex].configuration.carrier.selected.value.carrierQty = carrierQty;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.carrier.selected.stocks = stocks;
                break;
            case "lockNuts":
                attribute = "Lock Nuts";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey;
                switch (condition1) {
                    case "Centre Bunch":
                        condition2 = product.items[itemIndex].configuration.carrier.selected.value.carrierQty % 2 === 0 ? "Even Carrier Qty" : "Odd Carrier Qty";
                        break;
                    case "Centre Open":
                    case "Left":
                    case "Right":
                        condition2 = "default";
                }
                optionIndex = product.items[itemIndex].configuration.lockNuts.finalOptions.findIndex(o => o.condition1 === condition1 && o.condition2 === condition2);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.lockNuts.selected.value = product.items[itemIndex].configuration.lockNuts.finalOptions[optionIndex];
                    stocks = product.items[itemIndex].configuration.lockNuts.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    product.items[itemIndex].configuration.lockNuts.selected.value.lockNutsQty = stockItem.quantityMultiplier;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.lockNuts.selected.stocks = stocks;
                break;
            case "distanceTube":
                attribute = "Distance Tube";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.distanceTube.selected.value = {};
                bladeSize = product.items[itemIndex].configuration.bladeSize.selected.value.optionKey;

                product.items[itemIndex].configuration.distanceTube.selected.value.length6mm = 0;
                product.items[itemIndex].configuration.distanceTube.selected.value.length40mm = 0;
                product.items[itemIndex].configuration.distanceTube.selected.value.length25mm = 0;

                switch (product.items[itemIndex].configuration.stackingDirection.selected.value.optionKey) {
                    case "Centre Bunch":
                        product.items[itemIndex].configuration.distanceTube.selected.value.length6mm = (bladeSize === "127mm" && width <= 1800) ? 2 : 0;
                        product.items[itemIndex].configuration.distanceTube.selected.value.length40mm = 0;
                        product.items[itemIndex].configuration.distanceTube.selected.value.length25mm = 0;
                        break;
                    case "Centre Open":
                        product.items[itemIndex].configuration.distanceTube.selected.value.length6mm = 0;
                        product.items[itemIndex].configuration.distanceTube.selected.value.length40mm = bladeSize === "127mm" ? 2 : 0;
                        product.items[itemIndex].configuration.distanceTube.selected.value.length25mm = bladeSize === "89mm" ? 2 : 0;
                        break;
                    case "Left":
                    case "Right":
                        product.items[itemIndex].configuration.distanceTube.selected.value.length6mm = (bladeSize === "127mm" && width <= 1800) ? 1 : 0;
                        product.items[itemIndex].configuration.distanceTube.selected.value.length40mm = bladeSize === "127mm" ? 1 : 0;
                        product.items[itemIndex].configuration.distanceTube.selected.value.length25mm = bladeSize === "89mm" ? 1 : 0;
                        break;
                }
                if (product.items[itemIndex].configuration.distanceTube.selected.value.length6mm > 0) {
                    optionIndex = product.items[itemIndex].configuration.distanceTube.finalOptions.findIndex(o => o.optionKey === "6mm");
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.distanceTube.finalOptions[optionIndex].stocks.forEach(stockItem => {
                            temp = cloneDeep(stockItem);
                            temp.quantityMultiplier = temp.quantityMultiplier * product.items[itemIndex].configuration.distanceTube.selected.value.length6mm;
                            stocks.push(temp);
                        });
                    }
                }
                if (product.items[itemIndex].configuration.distanceTube.selected.value.length25mm > 0) {
                    optionIndex = product.items[itemIndex].configuration.distanceTube.finalOptions.findIndex(o => o.optionKey === "25mm");
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.distanceTube.finalOptions[optionIndex].stocks.forEach(stockItem => {
                            temp = cloneDeep(stockItem);
                            temp.quantityMultiplier = temp.quantityMultiplier * product.items[itemIndex].configuration.distanceTube.selected.value.length25mm;
                            stocks.push(temp);
                        });
                    }
                }
                if (product.items[itemIndex].configuration.distanceTube.selected.value.length40mm > 0) {
                    optionIndex = product.items[itemIndex].configuration.distanceTube.finalOptions.findIndex(o => o.optionKey === "40mm");
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.distanceTube.finalOptions[optionIndex].stocks.forEach(stockItem => {
                            temp = cloneDeep(stockItem);
                            temp.quantityMultiplier = temp.quantityMultiplier * product.items[itemIndex].configuration.distanceTube.selected.value.length40mm;
                            stocks.push(temp)
                        });
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.distanceTube.selected.stocks = stocks;
                break;
            case "bottomWeightType":
                attribute = "Bottom Weight Type";
                label = product.items[itemIndex].configuration.bottomWeightType.selected.value.optionKey;
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.bladeSize.selected.value.optionKey;
                optionIndex = product.items[itemIndex].configuration.bottomWeightType.selected.value.sets.findIndex(s => s.condition1 === condition1);
                if (optionIndex > -1) {
                    stocks = product.items[itemIndex].configuration.bottomWeightType.selected.value.sets[optionIndex].stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.bottomWeightType.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("headRailColour", product, itemIndex, order);
            product = this.updateStocks("wand", product, itemIndex, order);
            product = this.updateStocks("brackets", product, itemIndex, order);
            product = this.updateStocks("extensionBrackets", product, itemIndex, order);
            product = this.updateStocks("magnet", product, itemIndex, order);
            product = this.updateStocks("carrier", product, itemIndex, order);
            product = this.updateStocks("lockNuts", product, itemIndex, order);
            product = this.updateStocks("distanceTube", product, itemIndex, order);
            product = this.updateStocks("bottomWeightType", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.wand.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.wand.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.headRailColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.headRailColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.brackets.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.brackets.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.extensionBrackets.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.extensionBrackets.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.magnet.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.magnet.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.carrier.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.carrier.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.lockNuts.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.lockNuts.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.distanceTube.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.distanceTube.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bottomWeightType.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomWeightType.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }

            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling, discountByProductCode);
        return product;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        stockQtyExpression = stockQtyExpression.replaceAll("@CarrierQty", params.carrierQty);
        stockQtyExpression = stockQtyExpression.replaceAll("@TrackLength", params.trackLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@TiltRodLength", params.tiltRodLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@WandQty", params.wandQty);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        let deduction = {};
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;

        let params = {
            width: width,
            drop: drop,
            stockLength: stockItem.length,
            swishConversionFactor: stockItem.swishConversionFactor,
            trackLength: product.items[itemIndex].configuration.headRailColour.selected.value ? product.items[itemIndex].configuration.headRailColour.selected.value.trackLength : 0,
            tiltRodLength: product.items[itemIndex].configuration.headRailColour.selected.value ? product.items[itemIndex].configuration.headRailColour.selected.value.tiltRodLength : 0,
            carrierQty: product.items[itemIndex].configuration.carrier.selected.value ? product.items[itemIndex].configuration.carrier.selected.value.carrierQty : 0,
            wandQty: product.items[itemIndex].configuration.wand.selected.value ? product.items[itemIndex].configuration.wand.selected.value.wandQty : 0,
        };

        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                deduction = this.updateDeduction("fabric", product, itemIndex);
                result = params.carrierQty * deduction.cutDrop;
                product.items[itemIndex].configuration.fabricColour.selected.value.fabricQty = result;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;

                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = result * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = result * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = product.items[itemIndex].configuration.fabricColour.selected.value.fabricCutHeight;
                break;
            case "Head Rail":
                //05152 is tilt rod prodCode
                deduction = this.updateDeduction(stockItem.prodCode === "05152" ? "Tilt Rod Length" : "Track Length", product, itemIndex);
                params.trackLength = product.items[itemIndex].configuration.headRailColour.selected.value ? product.items[itemIndex].configuration.headRailColour.selected.value.trackLength : 0;
                params.tiltRodLength = product.items[itemIndex].configuration.headRailColour.selected.value ? product.items[itemIndex].configuration.headRailColour.selected.value.tiltRodLength : 0;

                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
            case "Bottom Weight Type":
            case "Wand Size":
            case "Brackets":
            case "Extension Brackets":
            case "Magnets":
            case "Carrier":
            case "Lock Nuts":
            case "Distance Tube":
            default:
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
        }
    }

    updateDeduction(key, product, itemIndex, order) {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;

        switch (key) {
            case "fabric":
                /*
                * Drop                      = customer requested drop
                * Allowance (deduction) 	= 50 mm
                * Fold height                 = 85 mm(65mm bottom + 20mm top respectively)
                *
                * Fabric height = Drop - Allowance
                * Fabric cut height = Drop +  Fold height
                * Fabric Qty = Carrier Qty * fabric cut height
                */
                let foldHeight = 85;
                deduction.deductionQty = 0;
                deduction.deductionWidth = 0;
                deduction.deductionDrop = -50;
                switch (product.items[itemIndex].configuration.bladeSize.selected.value.optionKey) {
                    case "89mm":
                        deduction.cutWidth = 89;
                        break;
                    case "127mm":
                        deduction.cutWidth = 127;
                        break;
                }
                product.items[itemIndex].configuration.fabricColour.selected.value.width = deduction.cutWidth;
                product.items[itemIndex].configuration.fabricColour.selected.value.fabricHeight = drop + deduction.deductionDrop;
                product.items[itemIndex].configuration.fabricColour.selected.value.fabricCutHeight = product.items[itemIndex].configuration.fabricColour.selected.value.fabricHeight + foldHeight;
                product.items[itemIndex].configuration.fabricColour.selected.value.fabricCutWidth = width;
                product.items[itemIndex].configuration.fabricColour.selected.value.fabricCutDrop = product.items[itemIndex].configuration.extensionBrackets.selected.value ? drop - 5 : drop;

                deduction.cutDrop = product.items[itemIndex].configuration.fabricColour.selected.value.fabricCutHeight;
                break;
            case "Track Length":
                deduction.deductionQty = 0;
                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                    case "Inside":
                        deduction.deductionWidth = -14;
                        deduction.deductionDrop = 0;
                        break;
                    case "Outside":
                        deduction.deductionWidth = -4;
                        deduction.deductionDrop = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.headRailColour.selected.value.trackLength = deduction.cutWidth;
                break;
            case "Tilt Rod Length":
                deduction.deductionQty = 0;
                switch (product.items[itemIndex].configuration.mount.selected.value.optionKey) {
                    case "Inside":
                        deduction.deductionWidth = -17;
                        deduction.deductionDrop = 0;
                        break;
                    case "Outside":
                        deduction.deductionWidth = -7;
                        deduction.deductionDrop = 0;
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.headRailColour.selected.value.tiltRodLength = deduction.cutWidth;
        }
        return deduction;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        return errors;
    }

    toSalesOrderItemVerticalIndoorRequest(pg, itemIndex, order) {
        let temp = {};
        let salesOrderItemVerticalIndoor = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: pg.items[itemIndex].configuration.location.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: pg.items[itemIndex].configuration.width.selected.value,
            drop: pg.items[itemIndex].configuration.drop.selected.value,
            fabricType: pg.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: pg.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            headRailColour: pg.items[itemIndex].configuration.headRailColour.selected.value.optionKey,
            bottomWeightType: pg.items[itemIndex].configuration.bottomWeightType.selected.value.optionKey,
            mount: pg.items[itemIndex].configuration.mount.selected.value.optionKey,
            bladeSize: pg.items[itemIndex].configuration.bladeSize.selected.value.optionKey,
            stackingDirection: pg.items[itemIndex].configuration.stackingDirection.selected.value.optionKey,
            wand: pg.items[itemIndex].configuration.wand.selected.value.optionKey,
            brackets: pg.items[itemIndex].configuration.brackets.selected.value.optionKey,
            extensionBrackets: pg.items[itemIndex].configuration.extensionBrackets.selected.value,
            wandQty: pg.items[itemIndex].configuration.wand.selected.value.wandQty,
            magnetQty: pg.items[itemIndex].configuration.magnet.selected.value.magnetQty,
            carrierQty: pg.items[itemIndex].configuration.carrier.selected.value.carrierQty,
            lockNutsQty: pg.items[itemIndex].configuration.lockNuts.selected.value.lockNutsQty,
            trackLength: pg.items[itemIndex].configuration.headRailColour.selected.value.trackLength,
            tiltRodLength: pg.items[itemIndex].configuration.headRailColour.selected.value.tiltRodLength,
            fabricWidth: pg.items[itemIndex].configuration.fabricColour.selected.value.width,
            fabricHeight: pg.items[itemIndex].configuration.fabricColour.selected.value.fabricHeight,
            fabricCutHeight: pg.items[itemIndex].configuration.fabricColour.selected.value.fabricCutHeight,
            fabricQty: pg.items[itemIndex].configuration.fabricColour.selected.value.fabricQty,
            distanceTube6mmQty: pg.items[itemIndex].configuration.distanceTube.selected.value.length6mm,
            distanceTube25mmQty: pg.items[itemIndex].configuration.distanceTube.selected.value.length25mm,
            distanceTube40mmQty: pg.items[itemIndex].configuration.distanceTube.selected.value.length40mm,
            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.value.fabricCutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.value.fabricCutDrop,
            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemVerticalIndoor;
    }
}

export default VerticalIndoorUtil.Instance();