import React, { Component } from 'react';
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText,
    Row, Col, Label, FormText, Table
} from 'reactstrap';
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage, getSorted } from '../../services/CommonService';
import retailService from '../../services/retail/RetailService';
import productService from "../../services/product/ProductService";
import ProductDiscountService from "../../services/product/ProductDiscountService";

export default class DebtorRetailOtherChargesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: this.props.accountID,
            record: [],
            isLoadingRecords: false,
        };
    }

    componentDidMount() {
        this.refresh(this.props.accountID);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({ accountID: nextProps.accountID }, () => this.refresh(nextProps.accountID));
        }
    }

    refresh(accountID) {
        this.setState({ isLoadingRecords: true });
        retailService.getDebtorsRetailPreference(accountID).then(response => {
            let record = response.data;
            this.setState({ record, isLoadingRecords: false });
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isLoadingRecords: false });
        });
    }

    handleChange(change, key) {
        let { record, accountID } = this.state;
        if (record) {
            if (change === null || change === '') {
                record[key] = 0;
            } else {
                record[key] = change;
            }
            record.accountID = accountID;
        }
        this.setState({ record });
    }

    triggerAllUpdate() {
        let { record, accountID } = this.state;
        let { toggle, isOpen } = this.props;
        this.setState({ isLoadingRecords: true });
        retailService.saveDebtorRetailOtherCharges(record, accountID).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success('Saved!');
                this.setState({ isLoadingRecords: false });
                toggle(!isOpen);
                this.props.refreshAgain();
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ isLoadingRecords: false });
        });
    }


    render() {
        let { isOpen, toggle } = this.props;
        let { record, isLoadingRecords } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Edit
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <Table className={"table table-sm table-bordered table-striped"}>
                            <thead>
                                <th>Name</th>
                                <th>Charge</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Check Measure</th>
                                    <td>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"number"}
                                                value={record.checkMeasureCharge ? record.checkMeasureCharge : null}
                                                placeholder={"Charges"}
                                                onChange={(e) => this.handleChange(e.target.value, "checkMeasureCharge")}>
                                            </Input>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Installation Internal</th>
                                    <td>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"number"}
                                                value={record.installationInternalCharge ? record.installationInternalCharge : null}
                                                placeholder={"Charges"}
                                                onChange={(e) => this.handleChange(e.target.value, "installationInternalCharge")}>
                                            </Input>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Installation External</th>
                                    <td>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"number"}
                                                value={record.installationExternalCharge ? record.installationExternalCharge : null}
                                                placeholder={"Charges"}
                                                onChange={(e) => this.handleChange(e.target.value, "installationExternalCharge")}>
                                            </Input>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Access levy Height</th>
                                    <td>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"number"}
                                                value={record.accessLevyHeightCharge ? record.accessLevyHeightCharge : null}
                                                placeholder={"Charges"}
                                                onChange={(e) => this.handleChange(e.target.value, "accessLevyHeightCharge")}>
                                            </Input>
                                        </InputGroup>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size={"sm"} onClick={() => this.triggerAllUpdate()}
                            disabled={isLoadingRecords}>
                            {
                                isLoadingRecords ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                    : <i className="fa fa-floppy-o mr-2" />
                            }
                            Update
                        </Button>&nbsp;
                        <Button color={"secondary"} size="sm"
                            onClick={() => toggle(!isOpen)}><i class="fa fa-times"></i>&nbsp; Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}