import React, { Component } from 'react';
import { Col, Row, Label, Input, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { maxDate, minDate } from './../../components/OrderGatewayComponents/OrderGatewayCommon';
import classnames from 'classnames';

export default class SalesAllCustomerOrdersFilter extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let { filterRequest, salesOrderJobStatuses } = this.props;
        return (
            <div>
                <Row className={"pb-3"}>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                        <div>
                            <DatePicker
                                className="form-control form-control-sm"
                                selected={filterRequest.startDate}
                                onChange={date => this.props.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                        <div>
                            <DatePicker
                                className="form-control form-control-sm"
                                selected={filterRequest.endDate}
                                onChange={date => this.props.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End date"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={maxDate}
                                minDate={minDate}
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Order No</Label>
                        <div>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"orderNum"}
                                value={filterRequest.orderNum || ''}
                                className="form-control form-control-sm"
                                onChange={(e) => this.props.handleChange(e.target.value, "orderNum")} />
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>CustOrdNum</Label>
                        <div>
                            <Input type={"text"}
                                placeholder={"Search..."}
                                name={"custOrdNum"}
                                value={filterRequest.custOrdNum || ''}
                                className="form-control form-control-sm"
                                onChange={(e) => this.props.handleChange(e.target.value, "custOrdNum")} />
                        </div>

                    </Col>

                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Label>Status</Label>
                        <div>
                            <Input type={"select"}
                                className="form-control form-control-sm"
                                name={"salesOrderJobStatusId"}
                                value={filterRequest.salesOrderJobStatusId ? filterRequest.salesOrderJobStatusId : ""}
                                onChange={(e) => this.props.handleChange(e.target.value, "salesOrderJobStatusId")}>
                                <option value={""}>All</option>
                                {(salesOrderJobStatuses.statuses || []).map((option, index) =>
                                    <option key={index} value={option.id}>{option.statusDescription}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={12} xs={12}>
                        <Button color={"primary"}
                            size={"sm"}
                            style={{ marginTop:'30px' }}
                            outline={!filterRequest.isExcludeCancelledOrder}
                            title={!filterRequest.isExcludeCancelledOrder ? 'Show Cancelled Orders' : 'Hide Cancelled Orders'}
                            onClick={() => this.props.toggleCancelledOrder(!filterRequest.isExcludeCancelledOrder)}>
                            <i className={classnames("fa", "mr-2", {
                                "fa-eye": !filterRequest.isExcludeCancelledOrder,
                                "fa-eye-slash": filterRequest.isExcludeCancelledOrder,
                            })} aria-hidden="true" />
                                    Cancelled Orders
                                </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}