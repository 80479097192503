import axios from '../axios';

class CreditorService {

    static Instance() {
        return new CreditorService();
    }

    fetchCreditorDropdownOptions(req) {
        return axios.post('api/windoware/creditor/dropdown', req);
    }

    getOneCreditor(accountID) {
        return axios.get('api/windoware/creditor/one?accountID=' + accountID);
    }

    searchFirstSupplierAccountCompany() {
        return axios.get('api/windoware/creditor/first/account');
    }

    getSupplierByAccount(accountID) {
        return axios.get('api/windoware/creditor/details/by/accountID?accountID=' + accountID);
    }

    getCreditorAreaCode() {
        return axios.get('api/windoware/creditor/areacode/list');
    }
    getSupplierPaymentMethodList() {
        return axios.get('api/windoware/creditor/payment/method');
    }
    getSupplierTermsMethodList() {
        return axios.get('api/windoware/creditor/terms/method');
    }
    getSupplierContact(accountID) {
        return axios.get('api/windoware/creditor/contact/detail?accountID=' + accountID);
    }

    deleteSupplierContact(contactID) {
        return axios.delete('api/windoware/creditor/contact/delete?contactID=' + contactID);
    }
    getSupplierQuotesList(accountID) {
        return axios.get('api/windoware/creditor/quote/details?accountID=' + accountID);
    }

    listCreditor(req){
        return axios.post('api/creditor/windowareDb/list',req);
    }

    getCreditor(accountID) {
        return axios.get('api/windoware/creditor/details/by/accountID?AccountID=' + accountID);
    }

    saveCreditor(req){
        return axios.post('api/creditor/windowareDb/save',req)
    }

    getCreditorSummary(searchText) {
        let requestBody = {
            accountID: searchText
        };
        return axios.post('api/creditor/windowareDb/summary/list',requestBody)
    }
    getSuppliers() {
        return axios.get('api/creditor/supplier');
    }

    getSupplierPriceDetails(prodCode) {
        return axios.get('api/windoware/creditor/quote/price/details?prodCode=' + prodCode);
    }

    getSupplierCategoryStatusDashboard() {
        return axios.get('api/creditor/windowareDb/category/status/dashboard');
    }

    generateNewAccountID() {
        return axios.get('api/windoware/creditor/get/new/accountId');
    }
}

export default CreditorService.Instance();