import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { Link as Link } from "react-router-dom";

export default class UnAuthorized extends Component {

    render() {
        return (
            <div>
                <div className={"center-window"}>
                    <h1 className="display-4">ACCESS TO THIS RESOURCE IS DENIED.
                    </h1>
                    <div>
                        <Link className={"btn btn-primary btn-lg"} style={{ float: "left" }} to={"/"}>Go to Home</Link>
                        <span style={{ float: "left" }}>
                            <h1><i className="fa fa-ban fa-fw" />
                            </h1>
                        </span>    
                    </div>
                </div>
            </div>
        );
    }
}