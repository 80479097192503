import React, {Component} from 'react';
import {Alert, Badge, Col, Row, Spinner} from 'reactstrap';
import PurchaseOrderService from '../../../services/purchase/PurchaseOrderService';
import stockReceiptService from '../../../services/stock/StockReceiptService';
import SearchPurchaseOrderOption from '../../../components/search/SearchPurchaseOrderOption';
import StockReceivePurchaseOrderItems from './StockReceivePurchaseOrderItems';
import {toast, ToastContainer} from "react-toastify";
import {
    changeFormatOfDateString,
    getCommonAddressString,
    handleErrorMessage,
    isNumeric
} from "../../../services/CommonService";
import StockReceiptDocuments from "./StockReceiptDocuments";
import SearchStockReceipt from "../../../components/search/SearchStockReceipt";
import {Link} from "react-router-dom";
import * as queryString from "query-string";

export default class StockReceivePurchaseOrderEnquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordNum: null,
            order: null,
            stockReceipt: null,
            isLoadingOrder: false,
        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.getPurchaseOrder = this.getPurchaseOrder.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getLastStockReceipt = this.getLastStockReceipt.bind(this);
        this.getStockReceipt = this.getStockReceipt.bind(this);
    }

    componentDidMount() {
        this.handleChange(this.props.ordNum, "ordNum");
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ordNum !== this.state.ordNum) {
            this.handleChange(nextProps.ordNum, "ordNum");
        }
    }

    handleChange(change, key) {
        let {ordNum, order,} = this.state;
        switch (key) {
            case "ordNum":
                ordNum = change;
                this.setState({ordNum}, () => {
                    this.getPurchaseOrder(ordNum);
                    this.getLastStockReceipt(ordNum);
                });
                break;
            case "stockReceiptID":
                if (change && isNumeric(change)) {
                    this.getStockReceipt(change);

                } else {
                    this.setState({stockReceipt: null});
                }
                break;
            case "orderStatus":
                order.orderStatus = change.orderStatus;
                order.statusID = change.statusID;
                this.setState({order});
                break;
        }
    }

    getPurchaseOrder(ordNum) {
        if (ordNum) {
            this.setState({ordNum, isLoadingOrder: true});
            this.purchaseOrderService.getOrder(ordNum).then(response => {
                let order = response.data;
                order.shippingAdrress = getCommonAddressString(order);
                this.setState({isLoadingOrder: false, order});
            }).catch(error => {
                this.setState({isLoadingOrder: false});
                toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
            })
        } else {
            this.setState({order: null});
        }
    }

    getLastStockReceipt(purchaseOrdNum) {
        let stockReceipt;
        if (purchaseOrdNum) {
            stockReceiptService.getLastStockReceipt(purchaseOrdNum).then(response => {
                let stockReceipt = response.data;
                this.setState({stockReceipt});
            }).catch(error => {
                toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
            })
        } else {
            this.setState({stockReceipt: null});
        }

    }

    getStockReceipt(stockReceiptID) {
        stockReceiptService.getStockReceipt(stockReceiptID).then(response => {
            let stockReceipt = response.data;
            this.setState({stockReceipt});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
        })
    }

    render() {
        let {ordNum, isLoadingOrder, order, stockReceipt} = this.state;
        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                        <div>
                            Select Purchase Order
                            <SearchPurchaseOrderOption
                                handleChange={(selectedOrdNum) => this.props.handleChange(selectedOrdNum, "ordNum")}
                                defaultOrdNum={ordNum}
                                selectedOrdNum={ordNum}
                            />
                        </div>

                        {
                            ordNum
                                ? <div className={"mt-2"}>
                                    Select Stock Receipt
                                    <SearchStockReceipt
                                        handleChange={(selectedStockReceiptID) => this.handleChange(selectedStockReceiptID, "stockReceiptID")}
                                        selectedStockReceiptID={stockReceipt ? stockReceipt.stockReceiptID : "New"}
                                        accountID={order ? order.accountID : ""}
                                    />
                                </div>
                                : null
                        }


                    </Col>

                    <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                        {
                            order
                                ? <Row className={"mt-1"}>

                                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                        <small>PO Date</small>
                                        <p className={"mb-0"}>{changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY")}</p>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                        <small>PO Status</small>
                                        <p className={"mb-0"}>
                                            <Badge
                                                color={this.purchaseOrderService.getPurchaseOrderStatusColor(order.orderStatus)}>
                                                {order.orderStatus}
                                            </Badge>
                                        </p>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                        <small>PO Items</small>
                                        <Link
                                            title={"Click here to see stock receipt"}
                                            to={"/purchase/order/enquiry?" + queryString.stringify({ordNum: order.ordNum})}>
                                            <h6 className={"mb-0"}>
                                                {(order.orderItems || []).length}
                                            </h6>
                                        </Link>


                                    </Col>
                                </Row>
                                : null
                        }
                        <hr/>
                        {
                            stockReceipt
                                ? <Row>
                                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                        <small>Receipt Date</small>
                                        <p className={"mb-0"}>{changeFormatOfDateString(stockReceipt.dateCreated
                                            , "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY")}</p>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                        <small>Receipt Updated By</small>
                                        <p className={"mb-0"}>
                                            {stockReceipt.ammendedBy}
                                        </p>
                                    </Col>
                                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                                        <small>Receipt Items</small>
                                        <Link
                                            title={"Click here to see stock receipt"}
                                            to={"/stock/receipt/enquiry?" + queryString.stringify({stockReceiptID: stockReceipt.stockReceiptID})}>
                                            <h6 className={"mb-0"}>
                                                {(stockReceipt.stockReceiptItems || []).length}
                                            </h6>
                                        </Link>
                                    </Col>
                                </Row>
                                : null
                        }
                    </Col>
                </Row>
                <div className={"mt-2"}>
                    <div>
                        <StockReceivePurchaseOrderItems
                            ordNum={ordNum}
                            stockReceiptID={stockReceipt ? stockReceipt.stockReceiptID : null}
                            handleChange={this.handleChange}
                        />
                        {
                            ( stockReceipt && stockReceipt.stockReceiptID)
                                ? <div>
                                    <hr/>
                                    <StockReceiptDocuments stockReceiptID={stockReceipt.stockReceiptID}/>
                                </div>
                                : null
                        }
                    </div>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}