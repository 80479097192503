import React, { Component } from 'react';
import { Badge, Button, Card, Col, Row, Spinner } from 'reactstrap';
import OrderService from '../../services/OrderService';
import { SummaryType } from './../../store/OrderGatewayTabs';
import { Debtors_Categories } from './../../store/DebtorInvoiceStatus';
import { getDateString } from './../../services/CommonService';
import classnames from 'classnames';
import {handleErrorMessage} from "../../services/CommonService";


export default class DebtorCategoryBucket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categorySummary: [],
            isloading: true,
            Blue: true,
            Gold: true,
            Silver: true,
            Bunnings: true,
            All: false,
        };

        this.refresh = this.refresh.bind(this);
        this.orderService = new OrderService();
        this.emptySelections = this.emptySelections.bind(this);
        this.getParticularOrdersOfcategory = this.getParticularOrdersOfcategory.bind(this);
        this.clearSelections = this.clearSelections.bind(this);
        this.renderCategoryButtons = this.renderCategoryButtons.bind(this);
    }

    emptySelections(categoryName) {
        this.setState({
            Blue: true,
            Gold: true,
            Silver: true,
            Bunnings: true,
            All: true
        }, () => this.getParticularOrdersOfcategory(categoryName));
    }

    getParticularOrdersOfcategory(categoryName) {
        if (categoryName) {
            switch (categoryName) {
                case "Blue":
                    this.setState({ Blue: false });
                    break;
                case "Gold":
                    this.setState({ Gold: false });
                    break;
                case "Silver":
                    this.setState({ Silver: false });
                    break;
                case "Bunnings":
                    this.setState({ Bunnings: false });
                    break;
                case "All":
                    this.setState({ All: false });
                    break;
                default:
                    break;
            }
            //get debtor_category_Id
            let debtor_category_Id = Debtors_Categories.find(ct => ct.label === categoryName).value;
            this.props.handleChange(debtor_category_Id, categoryName);
        }
    }

    renderCategoryButtons(item) {
        switch (item.name) {
            case "Gold":
                return (
                    <Card
                        className={classnames("p-1", "  hoverableItem", { "border border-primary": !this.state.Gold })}>
                        <Button size={"sm"} color="link"
                            onClick={() => this.emptySelections(item.name)} title="Click to filter">
                            {item.name} <Badge color="danger" className={"ml-1"}>{item.totalCount}</Badge>
                        </Button>
                    </Card>
                );
                break;
            case "Silver":
                return (
                    <Card
                        className={classnames("p-1", "  hoverableItem", { "border border-primary": !this.state.Silver })}>
                        <Button size={"sm"} color="link"
                            onClick={() => this.emptySelections(item.name)} title="Click to filter">
                            {item.name} <Badge color="danger" className={"ml-1"}>{item.totalCount}</Badge>
                        </Button>
                    </Card>
                );
                break;
            case "Blue":
                return (
                    <Card
                        className={classnames("p-1", "  hoverableItem", { "border border-primary": !this.state.Blue })}>
                        <Button size={"sm"} color="link"
                            onClick={() => this.emptySelections(item.name)} title="Click to filter">
                            {item.name} <Badge color="danger" className={"ml-1"}>{item.totalCount}</Badge>
                        </Button>
                    </Card>
                );
                break;
            case "Bunnings":
                return (
                    <Card className={classnames("p-1", "  hoverableItem", { "border border-primary": !this.state.Bunnings })}>
                        <Button size={"sm"} color="link" onClick={() => this.emptySelections(item.name)} title="Click to filter">
                            {item.name} <Badge color="danger" className={"ml-1"}>{item.totalCount}</Badge>
                        </Button>
                    </Card>
                );
                break;
            default:
                return null;
                break;
        }
    }

    clearSelections() {
        this.emptySelections('');
        this.props.clearChange();
    }

    componentDidMount() {
        this.refresh();
        Debtors_Categories.push({ label: "Bunnings", value: "01500" });
    }

    refresh() {
        //get details here
        this.setState({ isloading: true });
        let request = {
            'orderType': this.props.type,
            'option': SummaryType[1].label,
            'startDate': getDateString(this.props.startDate, "DD/MM/YYYY"),
            'endDate': getDateString(this.props.endDate, "DD/MM/YYYY")
        }
        this.orderService.getSubmittedOrdersDebtorSummary(request).then(response => {
            if (response.data) {
                this.setState({ categorySummary: response.data, isloading: false });
            }
        }).catch(error => {
            this.setState({ isloading: false });
            console.log(error);
            alert(handleErrorMessage(error));
        });
    }

    render() {
        let { isloading, categorySummary } = this.state;
        let categoryExceptBunnings = categorySummary.filter(c => c.name !== "Bunnings");
        if (isloading) {
            return <Spinner color={"primary"} size={"sm"} />;
        }
        let total = 0;
        for (let k = 0; k < categoryExceptBunnings.length; k++) {
            total = total + categoryExceptBunnings[k].totalCount;
        }
        return (
            <div>
                <Row>
                    <Col key={'20'} xl={2} lg={2} md={2} sm={4} xs={6} className="p-1">
                        <Card
                            className={classnames("p-1", "  hoverableItem", { "border border-primary": !this.state.All })}>
                            <Button size={"sm"} color="link" onClick={this.clearSelections}>
                                All <Badge color="danger" className={"ml-1"}>{total}</Badge>
                            </Button>
                        </Card>
                    </Col>
                    {(categorySummary || []).map((item, key) =>
                        <Col key={key} xl={2} lg={2} md={3} sm={6} xs={8} className={"p-1"}>
                            {this.renderCategoryButtons(item)}
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}