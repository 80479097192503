import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import StockUnitTypeService from '../../services/StockUnitTypeService';


export default class SearchStockUnitType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stockUnitType: props.defaultSelected,
            stockUnitTypes: [],
            loading: { stockUnitTypes: false }
        }
            

        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        let stockUnitType = this.state.stockUnitType;
        let description = "";
        if (stockUnitType) {
            description = stockUnitType.name;
        }
        this.fetchOptions(description);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stockUnitType) {
            if (nextProps.stockUnitType.description !== this.state.stockUnitType.description) {
                //load externally selectedItem
                this.fetchOptions(nextProps.stockUnitType.name);
            }
        }
    }


    fetchOptions(inputValue, callback) {
        let stockUnitTypes = [];
        let { stockUnitType, loading } = this.state;

        loading.stockUnitTypes = true;
        this.setState({ loading });
        
        StockUnitTypeService.getStockUnitTypeSummary(inputValue).then(response => {

            let data = response.data;
            for (let i in data) {
                stockUnitTypes.push({
                    label: data[i].name + " | " + (data[i].description ? data[i].description : ""),
                    value: data[i].name,
                    id: data[i].id,
                    data: data[i]
                });
                if (stockUnitType && stockUnitType.name) {
                    if (stockUnitTypes[i].data.id === stockUnitType.id) {
                        this.handleChange(stockUnitTypes[i]); // for default purpose
                    }
                }
            }
            loading.stockUnitTypes = false;
            this.setState({ stockUnitTypes, loading });
            if (callback) {
                return callback(stockUnitTypes)
            } else {
                return stockUnitTypes;
            }
        }).catch(error => {
            loading.stockUnitTypes = false;
            this.setState({ loading });
            console.log(error.message);
        }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            if (inputValue && inputValue.length <= 1) {
                return this.fetchOptions("", callback)
            }
            callback(this.state.stockUnitTypes);

        }
    };

    handleChange(change) {
        if (change) {
            StockUnitTypeService.getStockUnitTypeSummary(change.value).then(response => {
                this.setState({ stockUnitType: change });
                this.props.handleSelection(response.data[0])
            }).catch(error => {
                console.log(error.message);
            })
        } else {
            this.setState({ stockUnitType: change }, () => {this.fetchOptions()});
            this.props.handleSelection(change)
        }

    };


    render() {
        let { stockUnitType, loading, stockUnitTypes } = this.state;
        return (
            <AsyncSelect
                cacheOptions
                isDisabled={this.props.isDisabled}
                isLoading={loading.stockUnitTypes}
                isClearable={true}
                defaultOptions={stockUnitTypes}
                loadOptions={this.prepareFetchOptions}
                onChange={this.handleChange}
                value={stockUnitType}
                placeholder={"Search..."}
            />
        )
    }
}