import axiosFileDownloader from './axiosFileDownloader';
import axiosFileUploader from './axiosFileUploader';
import axios from './axios';

export default class AttachmentService {

    getparticularAttachments(Ids) {
        return axios.post("api/attachment/list/particular?AttachmentIds=" + Ids);
    }

    DownloadAttachment(id, options) {
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.get('api/file/Generate?AttachmentID=' + id, options);
        } else {
            return axiosFileDownloader.get('api/file/Generate?AttachmentID=' + id);
        }
    }

    getAttachmentWithDescription(description) {
        return axios.get('api/file/get/attachment/description?description=' + description)
    }

    getAttachmentListWithDescription(description) {
        return axios.get('api/file/get/list/attachment/description?description=' + description)
    }

    uploadAttachments(requestBody, onUploadProgress) {
        let config = {
            onUploadProgress:onUploadProgress
        };
        return axiosFileUploader.post("api/attachment/upload", requestBody, config);
    }

    deleteAttachment(ID) {
        return axios.delete('/api/attachment/delete?attachmentID=' + ID);
    }

}