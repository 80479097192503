import React, {Component} from 'react';
import {Link} from "react-router-dom";
import queryString from 'query-string';
import classnames from 'classnames';
import NumberFormat from "react-number-format";
import InfiniteScroll from 'react-infinite-scroller';
import {getLocalDateStringFromUtcDateString, isAllSelected} from '../../../services/CommonService';


import {
    Table, Badge, Button, UncontrolledPopover, PopoverHeader, PopoverBody
} from 'reactstrap';


export default class ReviewOrderTable extends Component {


    constructor(props) {
        super(props);

    }


    render() {

        let {orders} = this.props;

        return (
            <div>

                <Table hover={true} responsive={true}>
                    <thead>

                    <tr>

                        <th>Order#</th>
                        <th>AccountId</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Customer Order#</th>
                        <th>Amount</th>
                        <th>Rep</th>
                        <th>Entered By</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(orders || []).map((record, index) => {
                        return (
                            this.renderQuoteRow(index, record)
                        );
                    })}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderQuoteRow(index, record) {


        return <tr key={index}>

            <td><Link
                className="btn btn-sm btn-primary"
                style={{color: "white"}}
                title={"Click here to view order in detail"}
                to={"/sales/order/view?" + queryString.stringify({ OrdNum: record.ordNum })}>{record.ordNum}</Link></td>
            <td><Link
                className="btn btn-sm btn-primary"
                style={{color: "white"}}
                title={"Click here to see account details"}
                to={"/customer/#" + record.accountID}>{record.accountID}</Link></td>
            <td>{record.company}</td>
            <td>{getLocalDateStringFromUtcDateString(record.ordDate, 'DD/MM/YYYY')}</td>
            <td>{record.custOrdNum}</td>
            <td className={"table-success"}><NumberFormat value={record.ordTotal}
                                                          displayType={'text'}
                                                          decimalScale={2}
                                                          fixedDecimalScale={true}
                                                          thousandSeparator={true}
                                                          prefix={'$'}/></td>
            <td>({record.repCode})&nbsp;{record.repName}</td>

            <td>{record.enteredName}</td>

        </tr>;
    }
}