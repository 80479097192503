import React, { Component } from 'react';
import SalesQuoteService from "../../services/sales/SalesQuoteService";
import Sorter from "../../components/sorters/Sorter";
import Filter from "../../components/filters/Filter";
import QuoteTable from "../../components/sales/quotes/QuoteTable";
import CancelQuoteStatusModal from "../../components/Order/modals/CancelQuoteStatusModal";
import QuoteFollowUpEmailList from "../../components/sales/quotes/QuoteFollowUpEmailList";
import QuoteCancellationModal from "../../components/sales/quotes/QuoteCancellationModal";
import QuoteConvertToPickingSlipModal from "../../components/sales/quotes/QuoteConvertToPickingSlipModal";
import QuoteConversionAnalysis from "../../components/sales/quotes/QuoteConversionAnalysis";
import { isAllSelected, convertUtcDateToLocalDate, findIndex, handleErrorMessage } from '../../services/CommonService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileSaver from 'file-saver';
import { Link as Link } from "react-router-dom";
import {
    Card, CardBody,
    ButtonGroup, Button,
    Badge,
    Collapse, Row, Col
} from 'reactstrap';
import moment from 'moment';

let startDateString = moment().startOf('month').format('DD/MM/YYYY');
let endDateString = moment().endOf('month').format('DD/MM/YYYY');
let startEndDate = startDateString + "," + endDateString;

let allFilters = [{
    name: "OrderNo",
    displayName: "Quote No",
    value: "",
    defaultValue: "",
    displayValue: "",
    valid: true,
    sortIndex: 10
}, {
    name: "Customer",
    displayName: "Customer",
    value: "",
    defaultValue: "",
    displayValue: "",
    valid: true,
    sortIndex: 20
}, {
    name: "CreatedDateBetween",
    displayName: "Created Date Between",
    value: startEndDate,
    defaultValue: "",
    displayValue: startEndDate,
    valid: true,
    sortIndex: 30
}, {
    name: "SalesOrderQuoteStatus",
    displayName: "Quote Status",
    value: "0",
    defaultValue: "",
    displayValue: "Active",
    valid: true,
    sortIndex: 40
}, {
    name: "Rep",
    displayName: "Rep",
    value: "",
    defaultValue: "",
    displayValue: "All",
    valid: true,
    sortIndex: 60
}, {
    name: "Branch",
    displayName: "Branch",
    value: "",
    defaultValue: "",
    displayValue: "All",
    valid: true,
    sortIndex: 70
}
];

const allSorters = [
    {
        name: "CreatedDate",
        displayName: "Created Date",
        valid: true,
        sortIndex: 10
    },
    {
        name: "OrderNum",
        displayName: "Quote No",
        valid: true,
        sortIndex: 20
    },
    {
        name: "InvoiceAmount",
        displayName: "Invoice Amount",
        valid: true,
        sortIndex: 30
    }
];


export default class SalesQuotesPage extends Component {


    constructor(props) {
        super(props);

        let defaultAccountId = "";
        if (props.location) {
            if (props.location.state) {
                if (props.location.state.accountId) {
                    defaultAccountId = props.location.state.accountId;
                }
            }
        }
        let index = findIndex(allFilters, "name", "Customer");
        if (index > -1) {
            allFilters[index].defaultValue = defaultAccountId;
            allFilters[index].value = defaultAccountId;
            allFilters[index].displayValue = defaultAccountId;
        }


        this.state = {
            accountId: defaultAccountId,
            istoggleQuotesCancelReasonsModal: false,
            loadingCancelledQuote: false,
            selectedOrder: {},
            filters: allFilters,
            sorter: allSorters,
            openFollowUpQuoteModal: false,
            openCancellationQuoteModal: false,
            openConvertToPickingSlipQuoteModal: false,
            quotes: {
                filter: {
                    accountId: defaultAccountId,
                    statusID: 0,
                    orderNum: "",
                    query: "",
                    createdDateBetween: "",
                    repCode: "",
                    branchNum: "",
                    selectedPage: 1,
                    pageSize: 25,
                    totalRecords: 0,
                    toggle: false
                },
                sort: {
                    key: "",
                    ascending: true,
                    toggle: false
                },
                records: [],
                stats: {},
                loading: false,
            }

        };

        this.salesQuoteService = new SalesQuoteService();
        this.getSalesOrderQuotes = this.getSalesOrderQuotes.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateSorters = this.updateSorters.bind(this);
        this.toggleFilterCollapse = this.toggleFilterCollapse.bind(this);
        this.toggleSorterCollapse = this.toggleSorterCollapse.bind(this);
        this.toggleOpenFollowUpQuoteModal = this.toggleOpenFollowUpQuoteModal.bind(this);
        this.toggleOpenCancellationQuoteModal = this.toggleOpenCancellationQuoteModal.bind(this);
        this.toggleOpenConvertToPickingSlipQuoteModal = this.toggleOpenConvertToPickingSlipQuoteModal.bind(this);
        this.selectQuote = this.selectQuote.bind(this);
        this.exportQuotes = this.exportQuotes.bind(this);
        this.toggleQuotesCancelReasonsModal = this.toggleQuotesCancelReasonsModal.bind(this);
        this.handleQuotesCancelation = this.handleQuotesCancelation.bind(this);
    }


    componentDidMount() {
        let { quotes, filters } = this.state;
        quotes.filter.accountId = this.props.accountId;
            let index = findIndex(filters, "name", "Customer");
            if (index > -1) {
                filters[index].defaultValue = this.props.accountId;
                filters[index].value = this.props.accountId;
                filters[index].displayValue = this.props.accountId;
            }
        this.setState({ accountId: this.props.accountId, quotes, filters }, function () {
                this.refresh();
            });

    }

    componentWillReceiveProps(nextProps) {
        let { quotes, filters } = this.state;
        if (this.state.accountId !== nextProps.accountId) {
            quotes.filter.accountId = nextProps.accountId;
            let index = findIndex(filters, "name", "Customer");
            if (index > -1) {
                filters[index].defaultValue = nextProps.accountId;
                filters[index].value = nextProps.accountId;
                filters[index].displayValue = nextProps.accountId;
            }
            this.setState({ accountId: nextProps.accountId, quotes, filters }, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        this.getSalesOrderQuotes(false);
    }

    toggleFilterCollapse() {
        let quotes = this.state.quotes;
        quotes.sort.toggle = false;

        quotes.filter.toggle = !quotes.filter.toggle;
        this.setState({ quotes });
    }

    toggleSorterCollapse() {
        let quotes = this.state.quotes;
        quotes.filter.toggle = false;

        quotes.sort.toggle = !quotes.sort.toggle;
        this.setState({ quotes });
    }

    toggleOpenFollowUpQuoteModal() {
        this.setState((prevState) => ({ openFollowUpQuoteModal: !prevState.openFollowUpQuoteModal }));
    }

    toggleOpenCancellationQuoteModal() {
        this.setState((prevState) => ({ openCancellationQuoteModal: !prevState.openCancellationQuoteModal }));
    }

    

    toggleOpenConvertToPickingSlipQuoteModal() {
        this.setState((prevState) => ({ openConvertToPickingSlipQuoteModal: !prevState.openConvertToPickingSlipQuoteModal }));
    }

    getSelectedQuotesStatus(filters) {
        let index = findIndex(filters, "name", "SalesOrderQuoteStatus");
        let result = "";
        if (index > -1) {
            result = filters[index].displayValue;
        }
        return result;
    }


    getSalesOrderQuotes(loadMore) {
        let { quotes, filters } = this.state;

        if (quotes.loading) {
            let self = this;
            setTimeout(function () {
                self.getSalesOrderQuotes(loadMore);
            }, 2000);
        } else {
            quotes.loading = true;
            this.setState({ quotes });

            quotes = this.extractFilterValues(filters, quotes);
            let selectedQuoteStatus = this.getSelectedQuotesStatus(filters);

            this.salesQuoteService.fetchSalesOrderQuotes(quotes, loadMore).then(response => {
                quotes.loading = false;

                let data = response.data;
                quotes.filter.selectedPage = data.selectedPage;
                quotes.filter.pageSize = data.pageSize;
                quotes.filter.totalRecords = data.totalRecords;

                quotes.stats = data.statusResponse;
                if (quotes.stats) {
                    quotes.stats.selectedQuoteStatus = selectedQuoteStatus;
                }

                if (!loadMore) {
                    quotes.records = [];
                }

                if (data.records) {
                    for (let i in data.records) {
                        data.records[i].selected = false;
                        if (data.records[i].required) {
                            data.records[i].required = convertUtcDateToLocalDate(data.records[i].required);
                            if (data.records[i].required) {
                                data.records[i].required = data.records[i].required.toDate();
                            }
                        }
                        quotes.records.push(data.records[i]);
                    }
                }

                this.setState({ quotes });
            }).catch(error => {
                console.log(error);
            });
        }

    }

    extractFilterValues(filters, quotes) {
        for (let i in filters) {
            switch (filters[i].name) {
                case "CreatedDateBetween":
                    quotes.filter.createdDateBetween = filters[i].value;
                    break;
                case "Customer":
                    quotes.filter.accountId = filters[i].value;
                    break;
                case "OrderNo":
                    quotes.filter.orderNum = filters[i].value;
                    break;
                case "Keyword":
                    quotes.filter.query = filters[i].value;
                    break;
                case "SalesOrderQuoteStatus":
                    quotes.filter.statusID = filters[i].value;
                    break;
                case "Rep":
                    quotes.filter.repCode = filters[i].value;
                    break;
                case "Branch":
                    quotes.filter.branchNum = filters[i].value;
                    break;
            }
        }

        return quotes;
    }

    updateFilters(filters, applyFilterFlag) {
        if (applyFilterFlag) {
            this.setState({ filters }, () => {
                this.getSalesOrderQuotes(false);
            });
        } else {
            this.setState({ filters });
        }
    }

    updateSorters(selectedSorter, reload) {
        let quotes = this.state.quotes;
        quotes.sort.key = selectedSorter.key;
        quotes.sort.ascending = selectedSorter.ascending;
        if (reload) {
            this.setState({ quotes }, () => {
                this.getSalesOrderQuotes(false);
            });
        } else {
            this.setState({ quotes });
        }
    }

    selectQuote(index, flag) {
        let { quotes } = this.state;
        if (index > -1) {
            quotes.records[index].selected = !quotes.records[index].selected;
        } else {
            for (let i in quotes.records) {
                quotes.records[i].selected = flag;
            }
        }
        this.setState({ quotes });
    }

    exportQuotes() {
        let { quotes, filters, downloading } = this.state;
        if (!downloading) {
            quotes = this.extractFilterValues(filters, quotes);

            this.setState({ downloading: true });
            this.salesQuoteService.exportQuotes(quotes).then(response => {
                this.setState({ downloading: false });
                FileSaver.saveAs(response.data, "sales-quotes.csv");

            }).catch(error => {
                this.setState({ downloading: false });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleQuotesCancelation(CancelReasonId) {
        let { selectedOrder } = this.state;
        this.setState({ loadingCancelledQuote: true });
        this.salesQuoteService.cancelSelfOutstandingsQuote(CancelReasonId, selectedOrder.ordNum).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.setState({ loadingCancelledQuote: false });
                this.toggleQuotesCancelReasonsModal(selectedOrder);
                toast.success("Quote cancelled successfully!")
            }
        }).catch(error => {
            this.setState({ loadingCancelledQuote: false });
            toast.error(handleErrorMessage(error));
        });
    }

    toggleQuotesCancelReasonsModal(Ord) {
        this.setState((prevState) => ({
            istoggleQuotesCancelReasonsModal: !prevState.istoggleQuotesCancelReasonsModal,
            selectedOrder: Ord
        }));
    }

    render() {
        let { quotes, filters, downloading, istoggleQuotesCancelReasonsModal, loadingCancelledQuote } = this.state;

        let selectedQuotes = [];
        let allQuotesSelectedFlag = false;
        if (quotes.records) {
            allQuotesSelectedFlag = isAllSelected(quotes.records, "selected");
            selectedQuotes = quotes.records.filter((r) => {
                return r.selected
            });
        }


        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h5>Sale Quotes&nbsp;
                            <Link className={"btn btn-primary btn-sm"} to="/sales/order/create/product-builder/v1"><i
                                className="fa fa-plus"
                                aria-hidden="true" />&nbsp;New
                                quote</Link>

                        </h5>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={" text-right mb-1"}>
                            <div><Badge color="light"
                                pill>{quotes.loading ? <i className="fa fa-circle-o-notch fa-spin"
                                    aria-hidden="true" /> : quotes.filter.totalRecords}</Badge>Items
                                found&nbsp;
                                <ButtonGroup>
                                    <Button
                                        outline={!quotes.filter.toggle}
                                        color={"primary"}
                                        className={"btn btn-sm"}
                                        title={"Filter"}
                                        onClick={this.toggleFilterCollapse}>
                                        <i className="fa fa-filter" />
                                    </Button>
                                    <Button outline={!quotes.sort.toggle}
                                        color={"primary"}
                                        className={"btn btn-sm"}
                                        title={"Sort"}
                                        onClick={this.toggleSorterCollapse}>
                                        <i className="fa fa-sort" />
                                    </Button>
                                    <Button title={"Export"} outline={!downloading} className={"btn btn-sm"}
                                        color={"success"}
                                        onClick={this.exportQuotes}>
                                        {downloading ? <i className="fa fa-circle-o-notch fa-spin"
                                            aria-hidden="true" /> :
                                            <i className="fa fa-file-excel-o" aria-hidden="true" />}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Collapse isOpen={quotes.filter.toggle}>
                    <Filter filters={filters}
                        loading={quotes.loading}
                        onChange={this.updateFilters}
                        applyFilters={this.getSalesOrderQuotes} />
                </Collapse>

                <Collapse isOpen={quotes.sort.toggle}>
                    <Sorter sorters={allSorters}
                        loading={quotes.loading}
                        onChange={this.updateSorters}
                        applySorters={this.getSalesOrderQuotes}
                    />
                </Collapse>

                <QuoteConversionAnalysis stats={quotes.stats} />


                {quotes.filter.totalRecords > 0 ?

                    <Card>
                        <CardBody>
                            {selectedQuotes.length > 0 ?

                                <Row>
                                    <Col>
                                        <div className={"text-left"}> {selectedQuotes.length} selected</div>
                                    </Col>
                                    <Col>
                                        {/*<div className={"text-right"}>

                                            <UncontrolledDropdown className={"d-inline "}>
                                                <DropdownToggle color={"primary"} className={"btn"} caret>
                                                    More
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem><Button color={"primary"} size={"sm"}
                                                        onClick={this.toggleOpenFollowUpQuoteModal}
                                                        className={"btn"}>Email
                                                        Follow-up</Button></DropdownItem>
                                                    <DropdownItem><Button color={"success"}
                                                        size={"sm"}
                                                        title={"click here to convert selected quotes into picking slip"}
                                                        onClick={this.toggleOpenConvertToPickingSlipQuoteModal}>
                                                        Convert to
                                                        picking
                                                        slip</Button></DropdownItem>
                                                    <DropdownItem><Button color={"danger"} size={"sm"}
                                                        title={"click here to cancel selected quotes"}
                                                        onClick={this.toggleOpenCancellationQuoteModal}>Cancel
                                                        quotes</Button></DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            &nbsp;&nbsp;
                                            <Button color={"secondary"}
                                                className={"d-inline "}
                                                onClick={() => this.selectQuote(-1, false)}><i
                                                    className="fa fa-times"
                                                    aria-hidden="true" />&nbsp;
                                                clear
                                            </Button>
                                        </div>*/}
                                    </Col>
                                </Row>
                                : null
                            }

                            <QuoteTable quotes={quotes}
                                selectQuote={this.selectQuote}
                                toggleCancel={this.toggleQuotesCancelReasonsModal}
                                loadMore={this.getSalesOrderQuotes} />
                        </CardBody>
                    </Card>
                    : null}

                <QuoteFollowUpEmailList selectedQuotes={selectedQuotes}
                    isOpen={this.state.openFollowUpQuoteModal}
                    toggleModel={this.toggleOpenFollowUpQuoteModal} />

                <QuoteCancellationModal selectedQuotes={selectedQuotes}
                    isOpen={this.state.openCancellationQuoteModal}
                    toggleModel={this.toggleOpenCancellationQuoteModal}
                    refresh={this.getSalesOrderQuotes}
                />

                <QuoteConvertToPickingSlipModal selectedQuotes={selectedQuotes}
                    isOpen={this.state.openConvertToPickingSlipQuoteModal}
                    toggleModel={this.toggleOpenConvertToPickingSlipQuoteModal}
                    refresh={this.getSalesOrderQuotes}
                />

                {
                    istoggleQuotesCancelReasonsModal ?
                        <CancelQuoteStatusModal
                            handleChange={this.handleQuotesCancelation}
                            isOpen={this.state.istoggleQuotesCancelReasonsModal}
                            toggleModel={this.toggleQuotesCancelReasonsModal}
                            loadingCancelledQuote={loadingCancelledQuote}
                        />
                        : null
                }
                <ToastContainer />
            </div>
        );
    }

}