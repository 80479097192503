import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Nav, NavItem, NavLink
} from 'reactstrap';
import StockInventoryCostProductionService from '../../services/StockInventoryCostProductionService';
import "react-datepicker/dist/react-datepicker.css";
import {ToastContainer, toast} from 'react-toastify';
import queryString from 'query-string';
import StockInventoryMainUnitView from '../../components/WINOInventory/StockInventoryMainUnitView';
import CurrencyService from "../../services/CurrencyService";
import classnames from "classnames";
import {handleErrorMessage} from "../../services/CommonService";

export default class ManageStockInventoryCostProductionTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stockInventoryCostProductionObj: {
                id: 0,
                freightCost: 0.00,
                materialCost: 0.00,
                labourCost: 0.00,
                otherCosts: 0.00,
                productionCost: 0.00
            },
            formErrors: {},
            saving: false,
            loading: false,
        };
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.calculateProductionCost = this.calculateProductionCost.bind(this);
    }

    componentDidMount() {
        this.setState({},()=>{this.refresh(this.props.prodCode)});
    }

    refresh() {
            this.setState({loading: true})
            StockInventoryCostProductionService.getStockInventory_CostProduction(this.props.prodCode).then(response => {
                this.setState({stockInventoryCostProductionObj: response.data, loading: false});
            }).catch(error => {
                this.setState({loading: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
    }

    calculateProductionCost(stockInventoryCostProductionObj) {
        return stockInventoryCostProductionObj.freightCost +
            stockInventoryCostProductionObj.materialCost +
            stockInventoryCostProductionObj.labourCost +
            stockInventoryCostProductionObj.otherCosts;
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {stockInventoryCostProductionObj} = this.state;
        stockInventoryCostProductionObj[name] = parseInt(value);
        this.setState({stockInventoryCostProductionObj});
    }

    validateForm(stockInventoryCostProductionObj) {
        let formErrors = {};
        let valid = true;

        if (stockInventoryCostProductionObj.productionCost === null || (stockInventoryCostProductionObj.productionCost === undefined)) {
            formErrors.productionCost = "Please enter production cost";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.stockInventoryCostProductionObj};
        let result = this.validateForm(req);

        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        else {
            this.setState({saving: true, formErrors: {}});

            StockInventoryCostProductionService.saveOrUpdateStockInventoryProductionCost(this.props.prodCode, req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    if (!this.state.stockInventoryID) {
                        //this.props.history.push('/inventory/stock-inventory');
                        toast.success("Production Cost saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else {
                        toast.success("Production Cost updated successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    this.setState({saving: false, stockInventoryID: response.data.id}, () => {
                        this.refresh()
                    });
                }
            }).catch(error => {
                this.setState({saving: false});
                console.log(error.data);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    render() {
        let {stockInventoryCostProductionObj, formErrors, loading} = this.state;
        //console.log("selected={stockInventoryCostProductionObj.dateSoldOut}", stockInventoryCostProductionObj.dateSoldOut);
        let {prodCode}=this.props;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <h5>Production Cost</h5>
                    </CardHeader>
                    <CardBody>
                        <StockInventoryMainUnitView prodCode={prodCode}/>
                        <hr/>
                        {loading ? <Spinner color="primary"/> :
                            <Form>
                                <Row>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Freight Cost</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-usd" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    invalid={formErrors.freightCost != null}
                                                    name="freightCost"
                                                    type="number"
                                                    value={stockInventoryCostProductionObj.freightCost ? parseFloat(stockInventoryCostProductionObj.freightCost.toFixed(2)):null}
                                                    onChange={this.handleChange}
                                                    placeholder="Freight Cost..."/>
                                            </InputGroup>
                                            <FormText color="danger">{formErrors.freightCost}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Material Cost</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-usd" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    invalid={formErrors.materialCost != null}
                                                    name="materialCost"
                                                    type="number"
                                                    value={stockInventoryCostProductionObj.materialCost ? parseFloat(stockInventoryCostProductionObj.materialCost.toFixed(2)):null}
                                                    onChange={this.handleChange}
                                                    placeholder="Material Cost ..."/>
                                            </InputGroup>
                                            <FormText color="danger">{formErrors.materialCost}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Labour Cost</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-usd" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    invalid={formErrors.labourCost != null}
                                                    name="labourCost"
                                                    type="number"
                                                    value={stockInventoryCostProductionObj.labourCost ? parseFloat(stockInventoryCostProductionObj.labourCost.toFixed(2)):null}
                                                    onChange={this.handleChange}
                                                    placeholder="Labour Cost..."/>
                                            </InputGroup>
                                            <FormText color="danger">{formErrors.labourCost}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Other Cost</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-usd" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    invalid={formErrors.otherCosts != null}
                                                    name="otherCosts"
                                                    type="number"
                                                    value={stockInventoryCostProductionObj.otherCosts ? parseFloat(stockInventoryCostProductionObj.otherCosts.toFixed(2)):null}
                                                    onChange={this.handleChange}
                                                    placeholder="Other Cost..."/>
                                            </InputGroup>
                                            <FormText color="danger">{formErrors.otherCosts}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={3} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Total Production Cost</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-usd" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    disabled={true}
                                                    invalid={formErrors.productionCost != null}
                                                    name="productionCost"
                                                    type="number"
                                                    value={stockInventoryCostProductionObj.otherCosts ?parseFloat(this.calculateProductionCost(stockInventoryCostProductionObj).toFixed(2)): null}
                                                    onChange={this.handleChange}
                                                    placeholder="Production Cost ..."/>
                                            </InputGroup>
                                            <FormText color="danger">{formErrors.productionCost}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            <Button size={"sm"} color='primary' onClick={this.handleSubmit}
                                    disabled={this.state.saving}>
                                {this.state.saving ? <Spinner size="sm" className="mr-2"
                                                              style={{color: "white"}}/> :
                                    <i className="fa fa-floppy-o mr-2"
                                       aria-hidden="true"/>}
                                Save</Button>{' '}
                            <Button size={"sm"} color='secondary' onClick={()=>this.props.history.goBack()}>
                                <i className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                Back</Button>{' '}</div>
                    </CardFooter>
                </Card>
            </div>
        );
    }
}
