import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import ImportProductDiscount from "./ImportProductDiscount";
import ProductAllDiscount from "./ProductAllDiscount";
import {cloneDeep, isEmpty} from 'lodash';
import { ToastContainer } from 'react-toastify';

const tabs = [{key: "discount-list", label: "All Discount"}, {key: "import", label: "Import"}];

export default class ProductDiscountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "discount-list",
            accountId: new URLSearchParams(this.props.location.search).get("accountId")
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        this.setState({activeTab: tab});
    }

    render() {
        let { activeTab } = this.state;
        return (
            <div>
                    <Card>
                        <CardHeader>
                            <Nav tabs card>
                                {tabs.map((tab, index) =>
                                    <NavItem className={"hoverableItem"} key={index}>
                                        <NavLink
                                            className={classnames({ active: activeTab === tab.key })}
                                            onClick={() => {
                                                this.toggle(tab.key);
                                            }}>
                                            <Button style={{ textDecoration: "none" }}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;</Button>

                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="discount-list">
                                    <ProductAllDiscount accountID={this.state.accountId} />
                                </TabPane>
                                <TabPane tabId="import">
                                    <div>
                                        <ImportProductDiscount />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                <ToastContainer/>
            </div>
        )
    }
}