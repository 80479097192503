import React, { Component } from 'react';
import { Link } from "react-router-dom";
import queryString from 'query-string';
import classnames from 'classnames';
import NumberFormat from "react-number-format";
import InfiniteScroll from 'react-infinite-scroller';
import { getLocalDateStringFromUtcDateString, isAllSelected } from '../../../services/CommonService';


import {
    Table, Badge, Button, UncontrolledPopover, PopoverHeader, PopoverBody
} from 'reactstrap';


export default class OrderTable extends Component {

    getCSSClass(record) {
        let returnValue = "light";
        switch (record.salesOrderJobStatusDescription) {
            case "Cancelled":
                returnValue = "danger";
                break;
            case "Completed":
            case "Invoiced":
            case "Paid":
                returnValue = "success";
                break;
        }
        return returnValue;
    }


    render() {

        let { orders } = this.props;
        const allQuotesSelected = isAllSelected(orders.records, "selected");

        return (
            <div>

                <Table hover={true} responsive={true} size={"sm"}>
                    <thead>

                        <tr>
                            <th style={{ cursor: 'pointer' }}
                                onClick={() => this.props.selectOrder(-1, !allQuotesSelected)}>
                                <a>{allQuotesSelected ?
                                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                                    <i className="fa fa-lg fa-square-o" aria-hidden="true" />}
                                </a>
                            </th>
                            <th>Order#</th>
                            <th>AccountId</th>
                            <th>Customer</th>
                            <th>Date</th>
                            <th>Customer Order#</th>
                            <th>Amount</th>
                            <th>Rep</th>
                            <th>Email</th>
                            <th>Entered By</th>
                            <th>Status</th>
                            <th>Internal Notes</th>
                        </tr>
                    </thead>
                    <InfiniteScroll
                        element={"tbody"}
                        initialLoad={false}
                        loader={<tr key={"loadingItem"}>
                            <td colSpan={12} className={"text-center"}><Button
                                size={"md"}
                                block={true}
                                disabled={orders.loading}
                                color={"primary"}
                                onClick={() => this.props.loadMore(true)}>{orders.loading ? "Loading next " : "Load next "}{orders.filter.pageSize}&nbsp;
                                orders...</Button></td>
                        </tr>}
                        pageStart={1}
                        loadMore={() => this.props.loadMore(true)}
                        hasMore={(orders.filter.pageSize * orders.filter.selectedPage) < orders.filter.totalRecords}
                        useWindow={true}
                    >
                        {(orders.records) && orders.records.map((record, index) => {
                            return (
                                this.renderOrderRow(index, record)
                            );
                        })}
                    </InfiniteScroll>
                </Table>
            </div>
        );
    }

    renderOrderRow(index, record) {
        let statusClass = this.getCSSClass(record);
        let emailString = "mailto:"
            + record.customerEmail
            + "?subject=Windoware | orders-" + record.ordNum + " Follow-up" +
            "&body=AccountId-" + record.accountID + "%0A" + record.company + "%0A%0A%0A"
            + "Thanks,%0A"
            + "Team Windoware%0A"
            + "© 2019 Windoware Pty Ltd";


        return <tr key={index} className={classnames({ 'table-primary': record.selected })}>
            <td style={{ cursor: 'pointer' }} onClick={() => this.props.selectOrder(index)}>
                <a>{record.selected ?
                    <i className="fa fa-lg fa-check-square-o" aria-hidden="true" /> :
                    <i className="fa fa-lg fa-square-o" aria-hidden="true" />}
                </a>
            </td>
            <td><Link
                className="btn btn-primary"
                style={{ color: "white" }}
                title={"Click here to view orders in detail"}
                to={"/sales/order/view?" + queryString.stringify({ OrdNum: record.ordNum })}>{record.ordNum}</Link></td>
            <td><Link
                className="btn btn-primary"
                style={{ color: "white" }}
                title={"Click here to see account details"}
                to={"/customer/#" + record.accountID}>{record.accountID}</Link></td>
            <td><small>{record.company}</small></td>
            <td>{getLocalDateStringFromUtcDateString(record.ordDate, 'DD/MM/YYYY')}</td>
            <td>{record.custOrdNum}</td>
            <td className={"table-success"}><NumberFormat value={record.invTotal}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'} /></td>
            <td><small>{record.rep}</small></td>
            <td>{(() => {
                if (record.customerEmail) {
                    return (<span>
                        <i className="fa fa-envelope-o"
                            aria-hidden="true" />&nbsp;
                        <a href={emailString}> {record.customerEmail}</a>
                    </span>
                    );
                }
            })
                ()}
            </td>
            <td>{record.enteredByUser ? record.enteredByUser.firstName : record.enteredBy}</td>
            <td><Badge color={statusClass}>{record.salesOrderJobStatusDescription}</Badge></td>
            <td>
                {(() => {
                    if (record.notes && record.notes.length > 0) {
                        return (<div>
                            <a title={"click here to see notes"}
                                style={{ cursor: 'pointer' }}
                                id={"notes-" + index}>
                                <i className="fa fa-ellipsis-h fa-lg" aria-hidden="true" />
                            </a>
                            <UncontrolledPopover target={"notes-" + index}>
                                <PopoverHeader>orders No.{record.ordNum}&nbsp;-notes</PopoverHeader>
                                <PopoverBody>
                                    <ol>
                                        {(record.notes) && record.notes.map((note, index) => {
                                            return (<li key={index}>{note}<hr /></li>);
                                        })}
                                    </ol>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                        );
                    }
                })
                    ()}
            </td>
        </tr>;
    }
}