import React, {Component} from 'react';
import {Card, CardHeader, CardBody, Spinner, Table, Button, CardText} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {Link} from 'react-router-dom';
import retailService from "../../services/retail/RetailService";
import {handleErrorMessage} from "../../services/CommonService";
import NumberFormat from "react-number-format";
import DebtorRetailAdminProductMarkupFormModal from "../modal/DebtorRetailAdminProductMarkupFormModal";
import { productToRemovedForRetail } from "../../store/ProductToRemovedForRetail";

export default class ProductMarkups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountID: this.props.accountID,
            records: [],
            isLoadingRecords: false,
            isOpenRetailProductMarkupModal: false,
        };
        this.toggleRetailProductMarkupModal = this.toggleRetailProductMarkupModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.openRetailProductMarkupModal = this.openRetailProductMarkupModal.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, () => this.refresh());
        }
    }

    refresh() {
        let {accountID} = this.state;
        if (accountID) {
            this.setState({isLoadingRecords: true});
            retailService.getProductMarkups(accountID).then(response => {
                let records = response.data;
                records = records.filter(x => !productToRemovedForRetail.filter(p => p.Code === x.product.code).length && x.markup);
                this.setState({records, isLoadingRecords: false});
            }).catch(error => {
                toast.info(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({isLoadingRecords: false});
            });
        }
    }

    openRetailProductMarkupModal() {
        this.setState({isOpenRetailProductMarkupModal: true});
    }

    toggleRetailProductMarkupModal(data) {
        this.setState({isOpenRetailProductMarkupModal: data});
    }

    render() {
        let {isLoadingRecords, records, isOpenRetailProductMarkupModal, accountID} = this.state;
        return (
            <Card>
                <CardHeader>
                    <h6 className={"mb-0"}>
                        Product Markups
                        <span className={"float-right"}>
                            <Link size="xs" onClick={this.openRetailProductMarkupModal}>
                                    {records.length > 0 ? <i className={"fa fa-pencil"}/> : null}
                            </Link>
                        </span>
                    </h6>
                </CardHeader>

                {
                    isLoadingRecords
                        ? <CardBody><Spinner color={"primary"}/></CardBody>
                        : <div>
                            {records.length > 0
                                ? <Table size={"sm"} striped={true} hover={true} bordered responsive className={"mb-0"}>
                                    <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th className={"text-right"}>Markup</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(records || []).map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.product.name}</td>
                                            <td className={"text-right"}>
                                                <NumberFormat
                                                    value={item.markup}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'}/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                : <CardBody>
                                    <div className="text-center">
                                        <img src={"/img/logo/productMarkup_logo.png"} className="img-fluid pl-4 pr-4"/>
                                        <CardText>No Markup to show!</CardText>
                                        <Button size={"sm"} color={"primary"} onClick={this.openRetailProductMarkupModal}>
                                            <i className="fa fa-plus mr-2" aria-hidden="true"/>Add now
                                        </Button>
                                    </div>
                                </CardBody>
                            }
                        </div>
                }

                {isOpenRetailProductMarkupModal ?
                    <DebtorRetailAdminProductMarkupFormModal
                        isOpen={isOpenRetailProductMarkupModal}
                        toggle={this.toggleRetailProductMarkupModal}
                        selectedProductMarkup={records}
                        accountID={accountID}
                        refreshAgain={this.refresh}
                    /> : null}
                <ToastContainer/>
            </Card>
        );
    }
}