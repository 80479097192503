import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { Bar } from 'react-chartjs-2';
import CustomerService from "../../../services/CustomerService";
import FinancialYearDropDown from '../../FinancialYearDropDown';
import {
    getColors,
    getFinancialMonthList,
    getFinancialYear,
    numberWithThousandSeparator
} from "../../../services/CommonService";
import moment from 'moment';

export default class SalesByMonthChart extends Component {

    constructor(props) {
        super(props);

        let currentFY = getFinancialYear(moment(new Date()));
        this.state = {
            accountID: this.props.accountID,
            selectedYear: currentFY,
            saleValue: {
                chartData: {
                    labels: [],
                    datasets: []
                }
            },
            isLoading: false,
            includeChildAccount: this.props.includeChildAccount ? this.props.includeChildAccount : false
        };
        this.customerService = new CustomerService();
        this.getMonthlyData = this.getMonthlyData.bind(this);
        this.yearChange = this.yearChange.bind(this);
    };


    componentDidMount() {
        if (this.state.accountID) {
            this.getMonthlyData();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID || this.state.includeChildAccount !== nextProps.includeChildAccount) {
            this.setState({ accountID: nextProps.accountID, includeChildAccount: nextProps.includeChildAccount }, () => {
                this.getMonthlyData();
            });
        }
    }


    getMonthlyData() {
        let { saleValue, includeChildAccount } = this.state;
        if (this.state.accountID) {
            this.setState({ isLoading: true });
            this.customerService.CustomerMonthlySalesbyYear(this.state.accountID, this.state.selectedYear, includeChildAccount).then(response => {
                if (response.data) {
                    let dataset = {
                        label: [],
                        data: [],
                        color: []
                    };

                    let colors = getColors();
                    let months = getFinancialMonthList(this.state.selectedYear);
                    (months || []).map(m => {
                        if (response.data.find(x => x.month === m.monthNumber)) {
                            dataset.data.push(Math.round(response.data.find(x => x.month === m.monthNumber).sumTotal));
                            dataset.label.push(m.shortName + " " + response.data.find(x => x.month === m.monthNumber).year);
                            dataset.color.push(colors[0]);
                        }
                        else {
                            dataset.data.push(Math.round(0));
                            dataset.label.push(m.shortName + " " + m.year);
                            dataset.color.push(colors[0]);
                        }
                    });
                    saleValue = {
                        chartData: {
                            labels: dataset.label,
                            datasets: [{
                                label: dataset.label,
                                data: dataset.data,
                                backgroundColor: dataset.color
                            }]
                        }
                    };
                    this.setState({ saleValue, isLoading: false })
                }
            }).catch(error => {
                this.setState({ isLoading: false });
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
        else {
            toast.info("Account not found...!!!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    yearChange(selectedYear) {
        if (selectedYear && selectedYear !== this.state.selectedYear) {
            this.setState({ selectedYear: selectedYear }, () => {
                this.getMonthlyData();
            });
        }
    };


    render() {
        const { selectedYear, options, isLoading } = this.state;

        return (
            <div>
                <div>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"flex-fill align-self-center"}><h6>Purchase History</h6></div>
                        <div className={"flex-fill align-self-center"}>
                            <FinancialYearDropDown handleChange={this.yearChange}
                                defaultSelectedYear={selectedYear}
                                selectedYear={selectedYear}
                            />
                        </div>
                    </div>
                    <br />
                    {isLoading ? <Spinner className="primary" />
                        :
                        <Bar data={this.state.saleValue.chartData} options={{
                            title: {
                                display: false,
                                text: ''
                            },
                            legend: {
                                display: false
                            }, scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        callback(value) {
                                            return Number(value).toLocaleString('en')
                                        }
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Spend'
                                    }
                                }],
                                xAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Month'
                                    }
                                }]
                            },
                            tooltips: {
                                callbacks: {
                                    title: function (tooltipItem, chartData) {
                                        return "Monthly Sale"
                                    },
                                    label: function (tooltipItems, data) {
                                        return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + " : $" + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                                    }
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: 'auto',
                                    anchor: 'end',
                                    clamp: true,
                                    align: 'end',
                                    offset: 2,
                                    backgroundColor: function (context) {
                                        return context.dataset.backgroundColor;
                                    },
                                    color: 'white',
                                    formatter: function (value, data) {
                                        value = numberWithThousandSeparator(value);
                                        return "$" + value;
                                    }
                                }
                            }
                        }} />
                    }
                </div>
                <ToastContainer />
            </div>
        );
    }
}
