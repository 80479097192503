import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Badge,
} from "reactstrap";
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import PurchaseOrderService from '../../../services/WINOInventory/PurchaseOrderService';
import { getDateString, getLocalDateStringFromUtcDateString,handleErrorMessage } from '../../../services/CommonService';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchWINOCreditor from '../../../components/search/SearchCreditorAcccount';
import cloneDeep from 'lodash/cloneDeep';
import SearchWINOnlineStock from '../../../components/search/SearchWINOnlineStock';
import StockEnquiryService from '../../../services/WINOInventory/StockEnquiryService';

const maxDate = moment().add(2, 'years').toDate();
const minDate = moment().subtract(20, 'years').toDate();

export default class PurchaseOrderByStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            purchaseOrderData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        fromOrderDate: null,
                        toOrderDate: null,
                        ref: '',
                        supplierID: 0,
                        supplier: {},
                        purchaseOrderStatusDescription: '',
                        currency: '',
                        orderNumber: '',
                        stock: {},
                        stockProdCode: this.props.prodCode,
                        accountID: '',
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            searchText: '',
            purchaseOrderStatuses: [],
            activeTab: '1',
        };

        this.getPurchaseOrderStatuses = this.getPurchaseOrderStatuses.bind(this);
        this.getPurchaseOrders = this.getPurchaseOrders.bind(this);
        this.getStock = this.getStock.bind(this);
    }

    componentDidMount() {
        if (!this.props.prodCode) {
            this.getPurchaseOrders(this.state.purchaseOrderData);
            this.getPurchaseOrderStatuses();
        }
    }

    componentWillReceiveProps(nextProps) {
        let { purchaseOrderData } = this.state;
        if (purchaseOrderData.request.filterRequest.stockProdCode !== nextProps.prodCode) {
            purchaseOrderData.request.filterRequest.stockProdCode = nextProps.prodCode;
            this.setState({ purchaseOrderData });
            this.getStock(nextProps.prodCode);
            this.getPurchaseOrders(purchaseOrderData);
        }
    }

    getStock(prodCode) {
        let { purchaseOrderData } = this.state;
        StockEnquiryService.getStock(prodCode).then(response => {
            purchaseOrderData.request.filterRequest.stock = response.data
            this.setState({ purchaseOrderData });
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    getPurchaseOrderStatuses() {
        PurchaseOrderService.listPurchaseOrderStatus().then(res => {
            this.setState({ purchaseOrderStatuses: res.data })
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getPurchaseOrders(purchaseOrderData) {
        this.setState({ loading: true });
        let request = cloneDeep(purchaseOrderData.request);
        if (request.filterRequest.fromOrderDate) {
            request.filterRequest.fromOrderDate = getDateString(request.filterRequest.fromOrderDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.toOrderDate) {
            request.filterRequest.toOrderDate = getDateString(request.filterRequest.toOrderDate, "DD/MM/YYYY");
        }

        request.filterRequest.supplierID = request.filterRequest.supplier ? request.filterRequest.supplier.id : 0;

        PurchaseOrderService.listPurchaseOrderItems(request).then(response => {
            purchaseOrderData.response = response.data;
            this.setState({ purchaseOrderData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.purchaseOrderID.toString().toLowerCase().includes(searchText);

            if (!flag && item.orderDate) {
                flag = item.orderDate.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.stock.prodCode) {
                flag = item.stock.prodCode.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.etaDate) {
                flag = item.etaDate.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.etdDate) {
                flag = item.etdDate.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.crdDate) {
                flag = item.crdDate.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.supplier) {
                flag = item.supplier.company.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.currency) {
                flag = item.currency.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.ref) {
                flag = item.toString().toLowerCase().includes(searchText)
            }

            if (!flag && item.purchaseOrderStatus) {
                flag = item.purchaseOrderStatus.description.toString().toLowerCase().includes(searchText)
            }


        }
        return flag;
    }

    handleChange(change, key) {
        let { purchaseOrderData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (purchaseOrderData.request.sortRequest.key === change) {
                    purchaseOrderData.request.sortRequest.direction = !purchaseOrderData.request.sortRequest.direction;
                } else {
                    purchaseOrderData.request.sortRequest.key = change;
                    purchaseOrderData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getPurchaseOrders(purchaseOrderData);
                }
                break;
            case "pageSize":
                purchaseOrderData.request.pageRequest[key] = parseInt(change);
                purchaseOrderData.request.pageRequest.currentPage = 1;
                this.getPurchaseOrders(purchaseOrderData);
                break;
            case "currentPage":
                purchaseOrderData.request.pageRequest[key] = change;
                this.getPurchaseOrders(purchaseOrderData);
                break;


            default:
                purchaseOrderData.request.filterRequest[key] = change;
                purchaseOrderData.request.pageRequest.currentPage = 1;
                this.getPurchaseOrders(purchaseOrderData);
                this.setState({ purchaseOrderData });
        }

    }

    getPurchaseOrderHeading({ filterRequest }) {
        let purchaseOrderHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
            {
                key: "orderNumber",
                label: "Order No.",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"orderNumber"}
                        value={filterRequest.orderNumber || ''}
                        onChange={(e) => this.handleChange(e.target.value, "orderNumber")} />
                </div>
            },
            {
                key: "orderDate",
                label: "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: '120px',
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <div className={"text-right"}>
                                <DatePicker
                                    className="form-control form-control-sm mb-1"
                                    selected={filterRequest.fromOrderDate}
                                    onChange={date => this.handleChange(date, "fromOrderDate")}
                                    selectsStart
                                    startDate={filterRequest.fromOrderDate}
                                    endDate={filterRequest.toOrderDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="Start date"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                    
                            <div className={"text-right"}>
                                <DatePicker
                                    className="form-control form-control-sm"
                                    selected={filterRequest.toOrderDate}
                                    onChange={date => this.handleChange(date, "toOrderDate")}
                                    selectsEnd
                                    startDate={filterRequest.fromOrderDate}
                                    endDate={filterRequest.toOrderDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="End date"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    withPortal
                                />
                            </div>
                </div>
            },
            {
                key: "stockProdCode",
                label: "Stock",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchWINOnlineStock
                        handleSelection={(selectedProdCode) => this.handleChange(selectedProdCode, 'stockProdCode')}
                        selectedProdCode={filterRequest.stockProdCode}
                        defaultProdCode={filterRequest.stockProdCode} />

                </div>
            },

            {
                key: "stockQuantity",
                label: "Quantity",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "supplier",
                label: "Supplier",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <SearchWINOCreditor
                        handleAccountChange={(selectedSupplier) => this.handleChange(selectedSupplier ? selectedSupplier.accountID : '', 'accountID')}
                        selectedAccountID={filterRequest.accountID}
                        defaultAccountID={filterRequest.accountID} />
                </div>
            },
            {
                key: "purchaseOrderStatus",
                label: "Purchase Order Status",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"select"}
                        name={"purchaseOrderStatusDescription"}
                        value={filterRequest.purchaseOrderStatusDescription || ''}
                        onChange={(e) => this.handleChange(e.target.value, "purchaseOrderStatusDescription")}>
                        <option value="All">All</option>
                        {this.state.purchaseOrderStatuses.map((purchaseOrderStatus, index) =>
                            <option key={index}
                                value={purchaseOrderStatus.description}>{purchaseOrderStatus.description}</option>
                        )}
                    </Input>
                </div>
            },
            {
                key: "ETADate",
                label: "ETA Date",
                type: "date",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "ETDDate",
                label: "ETD Date",
                type: "date",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "currency",
                label: "Currency",
                type: "text",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"currency"}
                        value={filterRequest.currency || ''}
                        onChange={(e) => this.handleChange(e.target.value, "currency")} />
                </div>
            }];

        return purchaseOrderHeading;
    }

    getStatusBadgeColor(status) {
        let color = "light";

        switch (status) {
            case "Completed":
                color = "success";
                break;
            case "Cancelled":
                color = "danger";
                break;
            default:
                color = "light";
                break;
        }

        return color;
    }


    render() {

        let { purchaseOrderData, loading, searchText } = this.state;
        let { pageRequest, sortRequest } = purchaseOrderData.request;
        let purchaseOrderHeading = this.getPurchaseOrderHeading(purchaseOrderData.request);
        return (
            <div>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading ? <p>Loading...</p> :
                                <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {purchaseOrderData.response.totalRecords}
                                    {' '}entries</p>
                            }
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <InputGroup className={"mb-2"}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-search"
                                            aria-hidden="true" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"text"}
                                    name={"searchText"}
                                    placeholder={"Search..."}
                                    value={searchText}
                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                />

                            </InputGroup>
                        </div>

                    </Col>


                </Row>
                <div>
                    <Table striped bordered responsive hover size='sm'>
                        <thead>
                            <tr>
                                {(purchaseOrderHeading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(purchaseOrderHeading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>

                        </thead>

                        <tbody>
                            {(purchaseOrderData.response.records || []).filter((purchaseOrderItem) => this.searchFunction(purchaseOrderItem, searchText)).map((purchaseOrderItem, index) =>

                                <tr key={index}>
                                    <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                    <td>
                                        <Link className="btn btn-sm btn-primary" title={"Click here to view order in detail"}
                                            to={"/keyway-inventory/purchase-order/manage/one?" + queryString.stringify({ purchaseOrderID: purchaseOrderItem.purchaseOrderID })}>{purchaseOrderItem.purchaseOrderID}</Link>
                                    </td>
                                    <td>{purchaseOrderItem.orderDate}</td>
                                    <td><Link
                                        className="btn btn-sm btn-primary"
                                        style={{ color: "white" }}
                                        title={"Click here to see stock details"}
                                        to={"/keyway-inventory/stock/enquiry?prodCode=" +  purchaseOrderItem.stock.prodCode }>
                                        {purchaseOrderItem.stock.prodCode}</Link></td>
                                    <td className="text-right">{purchaseOrderItem.qty}</td>
                                    <td><small>{purchaseOrderItem.supplier.company}</small></td>
                                    <td className="text-center">
                                        <Badge size="sm" color={this.getStatusBadgeColor(purchaseOrderItem.purchaseOrderStatus.description)}>
                                            {purchaseOrderItem.purchaseOrderStatus.description}
                                        </Badge>
                                    </td>
                                    <td>{purchaseOrderItem.etaDate}</td>
                                    <td>{purchaseOrderItem.etdDate}</td>
                                    <td>{purchaseOrderItem.currency}</td>
                                </tr>
                            )}

                        </tbody>
                    </Table>
                </div>
                <br />
                <Row>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{ maxWidth: 200 }}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Show</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>


                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={purchaseOrderData.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>
            </div>
        );
    }
}
