import React, {Component} from 'react';
import {Link} from "react-router-dom";
import dashboardService from './../../../services/DashboardService';
import {getMomentObj, getDateString, getStartEndDateFromRangeText, getColors} from './../../../services/CommonService';
import {
    Row, Col, Card, CardHeader, CardBody, Spinner,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown
} from "reactstrap";
import {Line} from 'react-chartjs-2';
import moment from 'moment';
import DatePicker from "react-datepicker";
import cloneDeep from 'lodash/cloneDeep';

const dateRangeOptions = ["This week", "Last week", "This month", "Last month", "This quarter", "Last quarter"];
const maxDate = new Date();
const minDate = moment().subtract(6, 'months').toDate();
const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        duration: 0
    },
    hover: {
        animationDuration: 0
    },
    responsiveAnimationDuration: 0,
    legend: {
        position: 'top'
    },
    scales: {
        xAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true
            }, scaleLabel: {
                display: true,
                labelString: "Date"
            }
        }],
        yAxes: [{
            stacked: false,
            ticks: {
                beginAtZero: true
            }, scaleLabel: {
                display: true,
                labelString: "Order Qty"
            }
        }]
    },

    plugins: {
        datalabels: {
            display: 'auto',
            anchor: 'end',
            clamp: true,
            align: 'end',
            offset: 3,
            backgroundColor: function (context) {
                return context.dataset.backgroundColor;
            },
            color: 'white',
            font: {
                weight: 'bold'
            }
        }
    }

};


export default class DailySaleSummary extends Component {

    constructor(props) {
        super(props);
        let dateRange = getStartEndDateFromRangeText("This month");
        this.state = {
            loading: false, chartData: undefined,
            filter: {
                startDate: dateRange.startDate, endDate: dateRange.endDate,
            },
            selectedCustomDateRange: ""
        };
        this.getSummary = this.getSummary.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let {filter} = this.state;
        this.getSummary(filter);
    }

    handleChange(change, key) {
        let {filter} = this.state;
        switch (key) {
            case "dateRangeText":
                let dateRange = getStartEndDateFromRangeText(change);
                filter.startDate = cloneDeep(dateRange.startDate);
                filter.endDate = cloneDeep(dateRange.endDate);

                this.setState({filter, selectedCustomDateRange: change});
                this.getSummary(filter);
                break;

            default:
                filter[key] = change;
                this.setState({filter});
                this.getSummary(filter);
        }

    }

    getSummary(filter) {
        this.setState({loading: true});
        let request = {
            startDate: "",
            endDate: ""
        };

        if (filter.startDate) {
            request.startDate = getDateString(filter.startDate, "DD/MM/YYYY");
        }
        if (filter.endDate) {
            request.endDate = getDateString(filter.endDate, "DD/MM/YYYY");
        }
        let self = this;
        dashboardService.getDailySale(request).then(response => {
            if (response.data) {
                let data = response.data;
                let labels = [];
                let colors = getColors();
                let curtainTracks = {
                    label: "Curtain Tracks",
                    data: [],
                    fill: false,
                    borderColor: colors[0],
                    backgroundColor: colors[0]
                };
                let alumAndVenetian = {
                    label: "Alum & Venetian",
                    data: [],
                    fill: false,
                    borderColor: colors[1],
                    backgroundColor: colors[1]
                };
                let vertOutAndZiptrak = {
                    label: "VertOut & Ziptrak",
                    data: [],
                    fill: false,
                    borderColor: colors[2],
                    backgroundColor: colors[2]
                };
                let verticalIndoor = {
                    label: "Vertical Indoor",
                    data: [],
                    fill: false,
                    borderColor: colors[3],
                    backgroundColor: colors[3]
                };
                let rollerAndRoman = {
                    label: "Roller & Roman",
                    data: [],
                    fill: false,
                    borderColor: colors[4],
                    backgroundColor: colors[4]
                };
                let faw = {
                    label: "FAA",
                    data: [],
                    fill: false,
                    borderColor: colors[5],
                    backgroundColor: colors[5]
                };
                let panelTracks = {
                    label: "Panel Tracks",
                    data: [],
                    fill: false,
                    borderColor: colors[6],
                    backgroundColor: colors[6]
                };
                let bsamp = {label: "BSAM", data: [], fill: false, borderColor: colors[7], backgroundColor: colors[7]};
                let components = {
                    label: "Components",
                    data: [],
                    fill: false,
                    borderColor: colors[8],
                    backgroundColor: colors[8]
                };


                for (let i in data) {
                    data[i].ordMomentDate = getMomentObj(data[i].ordDateString, 'DD/MM/YYYY');
                    labels.push(data[i].ordDateString);
                    curtainTracks.data.push(data[i].productionLines.curtainTracks);
                    alumAndVenetian.data.push(data[i].productionLines.alumAndVenetian);
                    vertOutAndZiptrak.data.push(data[i].productionLines.vertOutAndZiptrak);
                    verticalIndoor.data.push(data[i].productionLines.verticalIndoor);
                    rollerAndRoman.data.push(data[i].productionLines.rollerAndRoman);
                    faw.data.push(data[i].productionLines.faw);
                    panelTracks.data.push(data[i].productionLines.panelTracks);
                    bsamp.data.push(data[i].productionLines.bsamp);
                    components.data.push(data[i].productionLines.components);
                }

                let chartData = {
                    labels: labels,
                    datasets: [curtainTracks, alumAndVenetian, vertOutAndZiptrak, verticalIndoor, rollerAndRoman, faw, panelTracks, bsamp]
                };

                this.setState({chartData, loading: false});
            } else {
                this.setState({loading: false});
            }
        }).catch(error => {
            console.log(error);
            this.setState({loading: false});
        })
    }

    render() {
        let {chartData, loading, filter, selectedCustomDateRange} = this.state;
        if (!chartData) {
            return null;
        }
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Sold Products</h6>
                    </CardHeader>
                    <CardBody>
                        <div className={"text-right mb-2"}>
                            <div className={"text-right"}>
                                <span>Start Date:</span>
                                <DatePicker
                                    className="form-control"
                                    selected={filter.startDate}
                                    onChange={date => this.handleChange(date, "startDate")}
                                    selectsStart
                                    startDate={filter.startDate}
                                    endDate={filter.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/MM/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                                <span className={"ml-3"}>End Date:</span>
                                <DatePicker
                                    className="form-control"
                                    selected={filter.endDate}
                                    onChange={date => this.handleChange(date, "endDate")}
                                    selectsEnd
                                    startDate={filter.startDate}
                                    endDate={filter.endDate}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    placeholderText="dd/MM/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color="primary"
                                                    className={"ml-2"}
                                                    style={{top: -3}}
                                    >
                                        {loading
                                            ? <Spinner size={"sm"}
                                                       style={{color: "white"}}/>
                                            : selectedCustomDateRange ? selectedCustomDateRange : 'More '
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            dateRangeOptions.map(option =>
                                                <DropdownItem
                                                    style={{cursor: 'pointer'}}
                                                    key={option}
                                                    onClick={() => this.handleChange(option, "dateRangeText")}>
                                                    {option}&nbsp;{(selectedCustomDateRange === option) ?
                                                    <i className="fa fa-check text-success"/> : null}
                                                </DropdownItem>
                                            )
                                        }
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>

                        </div>
                        <div style={{minHeight: 350}}>
                            <Line data={chartData} options={chartOptions}/>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}