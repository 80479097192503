import React, {Component} from "react";
import {
    Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, Nav, NavItem, NavLink,
    TabPane
} from "reactstrap";
import {Link} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import FabricColor from "../../pages/fabric/FabricColor";
import Fabric from "../fabric/Fabric";
import queryString from 'query-string';

const fabricsTabs = [
    {label: 'Fabric List', key: 'fabricList'},
    {label: 'Fabric Range Setting', key: 'fabricRange'}];

export default class FabricsTabPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "fabricList"
        };
        this.toggleTab = this.toggleTab.bind(this);
        this.setSearchParam = this.setSearchParam.bind(this);
    }

    componentDidMount() {       
        let { activeTab } = this.state;
        let searchParams = queryString.parse(window.location.search);
        activeTab = searchParams.activeTab ? searchParams.activeTab : "fabricList";
        this.setState({ activeTab });
        this.setSearchParam("activeTab", activeTab);
    }

    setSearchParam(key, value) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }

    clearSearchParam() {
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.pushState({ path: newurl }, '', newurl);
    }

    toggleTab(activeTab) {
        this.setState({ activeTab });
        this.clearSearchParam();
        this.setSearchParam("activeTab", activeTab);
    }

    render() {
        let {activeTab} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem
                        active>Fabric List</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {fabricsTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={{active: activeTab === tab.key}}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        {(() => {
                            switch (activeTab) {
                                case "fabricList":
                                    return <FabricColor search="" setSearchParam={this.setSearchParam} />;
                                case "fabricRange":
                                    return <Fabric search="" setSearchParam={this.setSearchParam} />;
                            }
                        })()}
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        )
    }
}