import axios from './axios';
class StockLandedCostHistoryService {

    static Instance() {
        return new StockLandedCostHistoryService();
    }
    getStockLandedCostHistory(req)
    {
        return axios.post('api/stock-landed-cost-history/list',req)
    }
}
export default StockLandedCostHistoryService.Instance();