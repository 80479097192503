import axiosFileUploader from './axiosFileUploader';
import axiosFileDownloader from "./axiosFileDownloader";

class ImportExportService {

    static Instance() {
        return new ImportExportService();
    }

    generateImportFormat(endpoint) {
        return axiosFileDownloader.get(endpoint);
    }

    EmailStockPricingSheet(endpoint) {
        return axiosFileUploader.get(endpoint);
    }

    importData(endPoint, request) {
        return axiosFileUploader.post(endPoint, request);
    }

    importStockInventory(endPoint, request) {
        return axiosFileUploader.post(endPoint, request);
    }
}

export default ImportExportService.Instance();