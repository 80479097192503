import React, {Component} from 'react';
import {Col, Row, Label} from 'reactstrap';

import SearchUser from '../search/SearchUser';

export default class AssignedUserFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, filter: this.props.filter};
        this.updateFilter = this.updateFilter.bind(this);
    }


    updateFilter(username) {

        let {filter} = this.state;

        filter.value = username;
        filter.displayValue = filter.value;
        this.setState({filter});
        this.props.onChange(filter, false);
    }


    render() {
        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Label>Search by Assigned User</Label>
                        <div>
                            <SearchUser
                                onChange={this.updateFilter}
                                defaultValue={this.state.filter.value}/>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
