import React, {Component} from "react";
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import {toast} from "react-toastify";
import {handleErrorMessage} from '../../services/CommonService';
import UserService from "../../services/UserService";
import ResetPasswordModal from "../../pages/auth/ResetPasswordModal";
import RoleList from "../user/RoleList";

export default class ManageDebtorTeamStaffLoginModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDebtorContact: this.props.selectedDebtorContact,
            user: {},
            isLoading: false,
            saving: false,
            formErrors: {},
            isOpenResetPasswordModel: false,
            clearResetPasswordModel: false,
            isPasswordLoading: false

        };
        this.userService = new UserService();
        this.handleChange = this.handleChange.bind(this);
        this.getUserDetail = this.getUserDetail.bind(this);
        this.manageUser = this.manageUser.bind(this);
        this.toggleClearResetPasswordModal = this.toggleClearResetPasswordModal.bind(this);
        this.toggleOpenResetPasswordModal = this.toggleOpenResetPasswordModal.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.setUserDetailFromContact = this.setUserDetailFromContact.bind(this);
    }

    componentDidMount() {
        let {selectedDebtorContact} = this.state;
        if (selectedDebtorContact.userID) {
            this.getUserDetail();
        }
        else {
            this.setUserDetailFromContact(selectedDebtorContact);
        }
    }

    toggleOpenResetPasswordModal(change) {
        let {clearResetPasswordModel} = this.state;
        if (!change) {
            clearResetPasswordModel = true;
        }
        this.setState({isOpenResetPasswordModel: change, clearResetPasswordModel});
    }

    setUserDetailFromContact(selectedDebtorContact) {
        let user = {
            firstName: selectedDebtorContact.firstName,
            lastName: selectedDebtorContact.lastName,
            email: selectedDebtorContact.email,
            phoneNumber: selectedDebtorContact.phone ? selectedDebtorContact.phone : (selectedDebtorContact.mobile ? selectedDebtorContact.mobile : '')
        };
        this.setState({user});
    }

    getUserDetail() {
        let {selectedDebtorContact} = this.state;
        this.setState({isLoading: true});
        this.userService.getUserDetailByUserID(selectedDebtorContact.userID).then(response => {
            let user = response.data;
            user.email = user.emailAddress;
            user.roleID = user.roles[0].roleId;
            this.setState({user: user, isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    handleChange(key, change) {
        let {user} = this.state;
        user[key] = change;
        this.setState({user});
    }

    toggleClearResetPasswordModal(change) {
        this.setState({clearResetPasswordModel: change});
    }

    updatePassword(password, repassword) {
        let {user, selectedDebtorContact} = this.state;
        this.setState({isPasswordLoading: true});
        this.userService.updatePasswordByAdmin(password, repassword, user.email).then(response => {
            toast.success("Password updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });

            let dbuser = response.data;
            if (dbuser) {
                user = {
                    id: dbuser.userId,
                    firstname: dbuser.firstName,
                    lastname: dbuser.lastName,
                    email: dbuser.emailAddress,
                    password: '',
                    userCategory: dbuser.userCategory,
                    lastPasswordUpdatedDate: dbuser.lastPasswordUpdatedDate
                };
            }
            this.setState({isPasswordLoading: false, user});
            this.toggleOpenResetPasswordModal(false);
            this.props.toggle(false);
            this.props.refreshHandle(selectedDebtorContact.accountID);
        }).catch(error => {
            this.setState({isPasswordLoading: false});
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    validateForm(user) {
        let formErrors = {};
        let valid = true;
        if (!user.firstName) {
            formErrors.firstName = "Please enter first name";
            valid = false;
        }
        if (!user.lastName) {
            formErrors.lastName = "Please enter last name";
            valid = false;
        }
        if (!user.email) {
            formErrors.email = "Please enter email";
            valid = false;
        }
        if (!user.userId && !user.password) {
            formErrors.password = "Please enter password";
            valid = false;
        }
        if (!user.roleID) {
            formErrors.roleID = "Please select role";
            valid = false;
        }

        return {valid: valid, formErrors: formErrors};
    }

    manageUser() {
        let {user, selectedDebtorContact} = this.state;
        let result = this.validateForm(user);
        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return false;
        }
        this.setState({saving: true});
        user.accountID = selectedDebtorContact.accountID;
        user.contactID = selectedDebtorContact.contactID;
        user.userCategory = "WW_DEBTOR";
        this.userService.saveDebtorTeamUser(user).then(response => {
            this.setState({saving: false});
            toast.success("Saved!");
            this.props.toggle(false);
            this.props.refreshHandle(selectedDebtorContact.accountID);
        }).catch(error => {
            this.setState({saving: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    render() {
        let {isOpen, toggle} = this.props;
        let {user, formErrors, saving, isLoading, isOpenResetPasswordModel, isPasswordLoading, clearResetPasswordModel} = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>

                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {user.userId ? "Edit" : "Add"} User
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <Spinner color={"primary"}/> :

                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="firstName">First name*</Label>
                                    <Input autoComplete="off" type="text" name="firstName"
                                           onChange={(ec) => this.handleChange(ec.target.name, ec.target.value)}
                                           value={user.firstName} placeholder="Enter first name here."/>
                                    <FormText color={"danger"}> {formErrors.firstName}</FormText>
                                </FormGroup>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="lastName">Last name*</Label>
                                    <Input autoComplete="off" type="text" name="lastName"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={user.lastName} placeholder="Enter last name here."/>
                                    <FormText color={"danger"}> {formErrors.lastName}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="email">Email*</Label>
                                    <Input autoComplete="off" type="text" name="email"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={user.email} placeholder="Enter email here."/>
                                    <FormText color={"danger"}> {formErrors.email}</FormText>
                                </FormGroup>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="phoneNumber">Phone</Label>
                                    <Input autoComplete="off" type="text" name="phoneNumber"
                                           onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                           value={user.phoneNumber} placeholder="Enter phone here."/>
                                </FormGroup>
                            </Col>
                            {
                                !user.userId ?
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="password">Password*</Label>
                                            <Input autoComplete="off" type="password" name="password"
                                                   onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                                   value={user.password} placeholder="Enter password here."/>
                                            <FormText color={"danger"}> {formErrors.password}</FormText>
                                        </FormGroup>
                                    </Col>
                                    : null
                            }

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label for="roleID">Role*</Label>
                                    <RoleList selectedrole={user.roleID} isExternal={true}
                                              roleChangeHandler={(e) => this.handleChange("roleID", e)}/>
                                    <FormText color={"danger"}>{formErrors.roleID}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>

                    <div className="text-right">
                        {user.userId ?
                            <Button size={"sm"} color={"primary"} title={"Change Password"} className={"mr-2"}
                                    onClick={() => this.toggleOpenResetPasswordModal(true)}>
                                <i className="fa fa-pencil mr-2"/> Change Password</Button> : null
                        }
                        <Button color={"primary"} size={"sm"} title={"AddUser"}
                                onClick={this.manageUser} disabled={saving}>
                            {saving ? <Spinner color="white" size="sm" className={"mr-2"}/> :
                                <i className="fa fa-floppy-o mr-2"/>}
                            {user.userId ? "Update" : "Save"}

                        </Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2"/>
                            Cancel</Button>
                    </div>
                </ModalFooter>
                {isOpenResetPasswordModel ?
                    <ResetPasswordModal
                        user={user}
                        isOpen={isOpenResetPasswordModel}
                        toggleModel={this.toggleOpenResetPasswordModal}
                        toggleClear={this.toggleClearResetPasswordModal}
                        updatePassword={this.updatePassword}
                        loading={isPasswordLoading}
                        clear={clearResetPasswordModel}
                    /> : null}
            </Modal>
        )
    }
}