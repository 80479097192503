import React, {Component} from "react";
import {List, arrayMove} from 'react-movable';
import {toast} from "react-toastify";
import {
    Spinner, Modal, ModalBody, ModalHeader, ModalFooter,
    Button, Table, Badge
} from 'reactstrap';
import {changeFormatOfDateString, handleErrorMessage} from "../../services/CommonService";
import productionScheduleService from '../../services/production/ProductionScheduleService';

export default class ManageProductionScheduleJobPriorityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: props.jobs,
            isLoading: false
        };
        this.updateProductionScheduleJobPriority = this.updateProductionScheduleJobPriority.bind(this);
    }

    setItems(jobs, oldIndex, newIndex) {
        jobs = arrayMove(jobs, oldIndex, newIndex);
        this.setState({jobs});
    }

    updateProductionScheduleJobPriority() {
        let {jobs} = this.state;
        this.setState({isLoading: true});
        let scheduleOrderList = [];
        (jobs || []).forEach((job, index) => {
            scheduleOrderList.push({salesOrderJobID: job.salesOrderJobID, sequence: index + 1})
        });
        productionScheduleService.updateProductionScheduleJobPriority(scheduleOrderList).then(response => {
            toast.success("Job's priority updated");
            this.props.toggle(false);
            this.props.handleAfterSubmit();
            this.setState({isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER})
        });

    }

    render() {
        let {jobs, isLoading,} = this.state;
        let {isOpen, toggle} = this.props;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Change Job Priority
                </ModalHeader>
                <ModalBody>
                    <List
                        values={jobs}
                        onChange={({oldIndex, newIndex}) =>
                            this.setItems(jobs, oldIndex, newIndex)
                        }
                        renderList={({children, props, isDragged}) => (
                            <Table
                                size={"sm"}
                                style={{
                                    cursor: isDragged ? 'grabbing' : undefined
                                }}
                            >
                                <thead>
                                <tr>
                                    <th className="text-center">OrdNum</th>
                                    <th>Description</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Product</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">W.H. Status</th>
                                    <th className="text-center">Schedule On</th>
                                </tr>
                                </thead>
                                <tbody {...props}>{children}</tbody>
                            </Table>
                        )}
                        renderItem={({value, props, isDragged, isSelected}) => {
                            const row = (
                                <tr
                                    {...props}
                                    style={{
                                        ...props.style,
                                        cursor: isDragged ? 'grabbing' : 'grab',
                                        backgroundColor: isDragged || isSelected ? '#ee8676' : '#fafafa'
                                    }}
                                >
                                    <td className="text-center">{value.ordNum}</td>
                                    <td>{value.fabric}</td>
                                    <td className="text-center">{value.qty}</td>
                                    <td className="text-center">{value.product}</td>
                                    <td className="text-center">{value.whStatus}</td>
                                    <td className="text-center"><Badge
                                        color={"light"}>{value.salesOrderJobStatusCode}</Badge></td>
                                    <td className="text-center">{changeFormatOfDateString(value.scheduleOn, "DD/MM/YYYY", 'DDMMMYY')}</td>
                                </tr>
                            );
                            return isDragged ? (
                                <Table
                                    size={"sm"} style={{...props.style, zIndex: 5000}}>
                                    <tbody>{row}</tbody>
                                </Table>
                            ) : (
                                row
                            );
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={this.updateProductionScheduleJobPriority}>
                            {isLoading
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           style={{color: "white"}}/>
                                : <i className="fa fa-floppy-o mr-2"/>}Save</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times"/>&nbsp;
                            Cancel</Button>

                    </div>
                </ModalFooter>

            </Modal>
        );
    }
}